import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { CompanyDetailType } from "./companyTypes";
import companyApis from "./companyApis";
import { CommonTypes } from "../types/commonTypes";

// TODO: ADD THIS SERVICE FUNCTION IN SERVICE CLASS
export const getCompanyDetail = (): Promise<AxiosResponse<CompanyDetailType>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: AxiosResponse<CompanyDetailType> = await restApiService.get("company");
      resolve(response);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

class CompanyService {
  getCompanyCurrencies = async (currencyCodes: CommonTypes.ListCurrencyCodeItem) => {
    try {
      const result = await companyApis.getCompanyCurrencyCode();
      const companyCurrencyCodeOnlyArray = result.map((c) => c.value);
      return currencyCodes.filter((c) => c.value && companyCurrencyCodeOnlyArray.includes(c.value));
    } catch (error) {
      throw error;
    }
  };
}

const companyService = new CompanyService();
export default companyService;
