import React, { useEffect, useState } from "react";
import RestApi from "../../../../providers/restApi";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { formattedAmount } from "../../../../services/general/helpers";
import useIsMounted from "../../../common/hooks/useIsMounted";
const restApiService: RestApi = new RestApi();
interface IInvoice {
  id: number;
  date: string;
  number: string;
  status: string;
  purchase_order: { number: string };
  amount: number;
  currency_code: string;
  balance_outstanding: number;
  payment_method: { payment_type: string };
}

type propType = {
  filter: { purchaser_id: string | undefined; limit: number };
};

const InvoiceTable = ({ filter }: propType) => {
  const [invoices, setInvoices] = useState<Array<IInvoice>>([]);
  const isMounted = useIsMounted();

  const fetchInvoices = async () => {
    try {
      const response = await restApiService.get("vendor_invoices", filter);
      if (isMounted) {
        setInvoices(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted) {
      fetchInvoices();
    }
  }, []);

  return (
    <div>
      {invoices ? (
        <Table striped bordered hover className="mb-0">
          <thead>
            <tr>
              <th className="primaryFontColor">Number</th>
              <th className="primaryFontColor">Status</th>
              <th className="primaryFontColor">PO Number</th>
              <th className="primaryFontColor">Date</th>
              <th className="primaryFontColor">Amount</th>
              <th className="primaryFontColor">Outstanding Balance</th>
              <th className="primaryFontColor">Payment Method</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => {
              return (
                <tr key={invoice.id}>
                  <td>
                    <Link
                      className="primaryLinkColor"
                      to={"/ar/invoices/" + invoice.id}
                      data-toggle="tooltip"
                      title="Invoice"
                    >
                      {invoice.number}
                    </Link>
                  </td>
                  <td>{invoice.status}</td>
                  <td>{invoice.purchase_order && invoice.purchase_order.number}</td>
                  <td>{invoice.date}</td>
                  <td>{formattedAmount(invoice.amount?.toString(), invoice.currency_code)}</td>
                  <td>{formattedAmount(invoice.balance_outstanding?.toString(), invoice.currency_code)}</td>
                  <td>{invoice.payment_method && invoice.payment_method.payment_type}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <h5 className="text-center mt-3 mb-3">Loading...</h5>
      )}
    </div>
  );
};

export default InvoiceTable;
