import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavTabs from "pages/admin/purchaseOrders/nav";
import { useLocation } from "react-router";
import GridFilterDropdown from "components/datagrid/gridFilterDropdown";
import ToggleFilterButton from "components/datagrid/buttons/toggleFilterButton";
import styles from "components/admin/purchaseOrders/purchaseOrders.module.css";
import { GridReadyEvent } from "ag-grid-community";
import ClientDataGrid from "components/common/dataGrid/clientDataGrid/clientDataGrid";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import useIsMounted from "components/common/hooks/useIsMounted";
import { PoChangeOrderType } from "services/admin/changeOrders/poChangeOrderApprovalsType";
import ChangeOrderApis from "services/admin/changeOrders/changeOrderApis";
import { getPoChangeOrderHeaders } from "./listCOGridHeaders";
import { useTypedSelector } from "reducers";

const ListChangeOrders = () => {
  const location = useLocation();
  const isMounted = useIsMounted();
  const queryParams = new URLSearchParams(location.search);

  // TODO: implement filter by filter id
  const filterID = queryParams.get("filterID");

  const [gridApi, setGridApi] = useState<GridReadyEvent["api"]>();
  const [gridColumnApi, setGridColumnApi] = useState<GridReadyEvent["columnApi"]>();
  const { showFilters, updateShowFilters } = useShowFilterState("showCoApprovalFitlers");
  const [poChangeOrderList, setPoChangeOrderList] = useState<PoChangeOrderType.ListType>([]);
  const currentUser = useTypedSelector((state) => state.user);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getPoChangeOrderList = async () => {
    try {
      const result = await ChangeOrderApis.getList();
      if (isMounted.current) {
        setPoChangeOrderList(result);
      }
    } catch (error) {}
  };

  const poChangeOrderGridHeaders = useMemo(() => getPoChangeOrderHeaders({ getPoChangeOrderList, currentUser }), []);

  useEffect(() => {
    getPoChangeOrderList();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <NavTabs activePageName="Change Orders" />
        </Col>
      </Row>
      <Row>
        <Col>
          <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} options={{}} />
          <ToggleFilterButton
            classes="float-right"
            clickCallback={() => {
              updateShowFilters(!showFilters);
              gridApi?.refreshHeader();
            }}
          />
        </Col>
      </Row>

      <Row className={styles.gridHeight + " mt-3 px-3"}>
        <ClientDataGrid
          columnDefs={poChangeOrderGridHeaders}
          gridReady={gridReady}
          rowData={poChangeOrderList}
          defaultColDef={{ floatingFilter: showFilters }}
          gridStorageName={"changeOrders"}
        />
      </Row>
    </Container>
  );
};

export default ListChangeOrders;
