import TooltipRenderQues from "components/toolTip/tooltipRenderQues";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { required } from "../../../forms/inputFields";
import { PaymentMethodValidatorSvc } from "../paymentMethodValidatorSvc";
import SgdBankCode from "./sgdBankCodes";

const BankCodeValidator = ({
  modelName,
  currencyCode,
  ibanNumber,
  label,
  noValidate,
  notRequired,
}: {
  modelName?: string;
  currencyCode?: string;
  ibanNumber?: string | number;
  label?: string;
  noValidate?: boolean;
  notRequired?: boolean;
}) => {
  const [validationMessage, setValidationMessage] = useState("");
  const { t } = useTranslation();
  const isFieldRequired = notRequired ? false : true;
  const [showSGDBankCodes, setShowSGDBankCodes] = useState(false);

  const validateBankCode = async (value: string | number) => {
    if (value && !noValidate) {
      try {
        const result = await PaymentMethodValidatorSvc.ValidateBicCode(value, ibanNumber);
        if (result.validation) {
          setValidationMessage(result.validation);
        } else {
          setValidationMessage(result.errors[0]?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Field
        name={`${modelName}.bankCode`}
        component={RenderField}
        type="text"
        required={isFieldRequired}
        validate={isFieldRequired ? [required] : []}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateBankCode(e.target.value)}
        tooltip={
          currencyCode === "SGD" ? (
            <span onClick={() => setShowSGDBankCodes(true)}>
              <TooltipRenderQues title="Click the icon to view the list of available bank codes." />
            </span>
          ) : null
        }
        label={label ? label : t("common.paymentMethod.bankCode/Bic")}
      />
      {validationMessage && (
        <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
      )}
      {currencyCode && (
        <SgdBankCode
          showModal={showSGDBankCodes}
          currencyCode={currencyCode}
          onClose={() => setShowSGDBankCodes(false)}
        />
      )}
    </>
  );
};

export default BankCodeValidator;
