import React from "react";
import { ReportPageNameType } from "services/admin/reports/reportTypes";
import TabNavigation from "../../../navigation/tabNavigation";

//NOTE: add only those route which we want to show on tabNavigations
const reportRoutes = [
  {
    pageName: "Reports",
    path: "/reports/list",
    authorization: {
      permissions: ["listReports"],
    },
  },
  {
    pageName: "Data Extracts",
    path: "/reports/data_extracts",
    authorization: {
      permissions: ["listDataExtracts"],
    },
    subPages: [
      {
        pageName: "Card & Expense",
        path: "/reports/data_extracts/card_expense",
        authorization: {
          permissions: ["listExpenses", "listVCards"],
        },
      },
      {
        pageName: "Invoices",
        path: "/reports/data_extracts/invoice",
        authorization: {
          permissions: ["listInvoices"],
          I: "invoice",
          a: "_allow_data_extract",
        },
      },
    ],
  },
  {
    pageName: "File Outbound",
    path: "/reports/file_outbound/list",
    authorization: {
      permissions: ["listReports"],
    },
  },
];

type NavPropsType = {
  activePageName: ReportPageNameType | string;
};
const NavTabs = ({ activePageName }: NavPropsType) => {
  return <TabNavigation activePageName={activePageName} navigationTab={reportRoutes} />;
};

export default NavTabs;
