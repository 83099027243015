import React, { useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { useLocation } from "react-router";
import styles from "./footer.module.css";
import CustomModal from "../../modals/customModal";
import PreviewComponent from "../attachmentPreview/previewComponent";
import { docsNames } from "assets/docs/docsName";
const Footer = () => {
  const [showLicense, setShowLicense] = useState<boolean>(false);
  const location = useLocation();

  const toggleLincenseModal = () => {
    setShowLicense((prev) => !prev);
  };

  return (
    <React.Fragment>
      {showLicense && (
        <CustomModal
          show={showLicense}
          onHide={() => toggleLincenseModal()}
          size="lg"
          body={
            <div className={"d-flex flex-column align-items-center " + styles.h90}>
              <object height={"100%"} width={"100%"} data={docsNames.footerDoc} aria-label="pdf" />
              <Button variant="secondary" className="mt-1" onClick={toggleLincenseModal}>
                Close
              </Button>
            </div>
          }
        />
      )}
      <Nav className={"justify-content-center main-footer " + styles.footerList}>
        <Nav.Item>
          <Nav.Link href="https://www.corpay.com/privacy-policy" target="_blank">
            Privacy Policy
          </Nav.Link>
        </Nav.Item>
        {/* <span className={styles.pipeSymbol}>|</span>
        <Nav.Item>
          <Nav.Link href="https://www.corpay.com/corpay-complete-tos" target="_blank" className="">
            Terms of Use
          </Nav.Link>
        </Nav.Item> */}

        {location.pathname == "/ap/settings" && (
          <>
            <span className={styles.pipeSymbol}>|</span>
            <Nav.Item>
              <Nav.Link as={"button"} onClick={toggleLincenseModal} className={styles.button}>
                Third-Party Licenses
              </Nav.Link>
            </Nav.Item>
          </>
        )}
      </Nav>
    </React.Fragment>
  );
};

export default Footer;
