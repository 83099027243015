import React, { useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import "./form.style.css";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CreateNotification, NotificationType } from "../../services/general/notifications";
import RestApi from "../../providers/restApi";
import { passwordPattern } from "../../services/validations/reduxFormValidation";

const UpdatePasswordForm = (props) => {
  const { t } = useTranslation();
  let [validated, setValidated] = useState(false);
  let [matching, setMatching] = useState(true);
  let [updatingToCurrent, setUpdatingToCurrent] = useState(true);
  let [passwords, setPasswords] = useState({ currentPassword: "", password: "", confirmPassword: "" });

  const resetForm = () => {
    setPasswords({ currentPassword: "", password: "", confirmPassword: "" });
    setMatching(true);
    setUpdatingToCurrent(true);
    setValidated(false);
  };

  const formSubmit = (event) => {
    let valid = event.currentTarget.checkValidity();
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();
    if (passwords.currentPassword === passwords.password) {
      setUpdatingToCurrent(false);
      setValidated(false);
      return;
    } else {
      setUpdatingToCurrent(true);
    }
    if (passwords.password !== passwords.confirmPassword) {
      setMatching(false);
    } else {
      setMatching(true);
    }
    if (!valid) {
      return;
    }

    new RestApi()
      .patch("users/" + props.user.id, null, {
        user: {
          current_password: passwords.currentPassword,
          password: passwords.password,
          confirm_password: passwords.confirmPassword,
        },
      })
      .then((result) => {
        CreateNotification(t("success"), t("profilePage.passwordChanged"), NotificationType.success);
        resetForm();
      })
      .catch((error) => {
        console.error("Error saving company info: ", error);
        CreateNotification(t("error"), t("errors.genericSupport"), NotificationType.warning);
      });
  };

  return (
    <Form noValidate validated={validated} onSubmit={formSubmit}>
      <Row>
        <Col sm>
          <Form.Group controlId="currentPassword">
            <Form.Label>{t("contact.currentPassword")}</Form.Label>
            <Form.Control
              type="password"
              value={passwords.currentPassword}
              placeholder={t("contact.currentPassword")}
              name="password"
              onChange={(event) => {
                setPasswords({ ...passwords, currentPassword: event.target.value });
              }}
              required
            />
            <Form.Control.Feedback type="invalid">{t("validation.password")}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>{t("contact.password")}</Form.Label>
            <Form.Control
              type="password"
              value={passwords.password}
              placeholder={t("contact.password")}
              name="password"
              onChange={(event) => {
                setPasswords({ ...passwords, password: event.target.value });
              }}
              required
              pattern={passwordPattern}
            />
            <Form.Control.Feedback type="invalid">{t("validation.password")}</Form.Control.Feedback>
          </Form.Group>
          {!updatingToCurrent && <div className="text-danger">{t("validation.cannotUpdateToCurrentPassword")}</div>}
          <Form.Group controlId="passwordConfirm">
            <Form.Label>{t("contact.passwordConfirm")}</Form.Label>
            <Form.Control
              type="password"
              value={passwords.confirmPassword}
              placeholder={t("contact.passwordConfirm")}
              name="passwordConfirm"
              onChange={(event) => {
                setPasswords({ ...passwords, confirmPassword: event.target.value });
              }}
              required
              pattern={passwordPattern}
            />
            <Form.Control.Feedback type="invalid">{t("validation.password")}</Form.Control.Feedback>
          </Form.Group>
          {!matching && <div className="text-danger">{t("validation.matchingPassword")}</div>}
        </Col>
        <Col sm>
          <Form.Label>{t("validation.passwordCriteria.header")}</Form.Label>
          <p>{t("validation.passwordCriteria.minimum")}</p>
          <p className="mt-2">{t("validation.passwordCriteria.contain")}</p>
          <ul>
            <li className="secondaryFontColor smallFont">{t("validation.passwordCriteria.uppercase")}</li>
            <li className="secondaryFontColor smallFont">{t("validation.passwordCriteria.lowercase")}</li>
            <li className="secondaryFontColor smallFont">{t("validation.passwordCriteria.digit")}</li>
            <li className="secondaryFontColor smallFont">{t("validation.passwordCriteria.special")}</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <button className="btn btn-primary" type="submit">
            {t("submit")}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordForm);
