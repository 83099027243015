import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { required } from "../../../../forms/inputFields";
import { AccountNumberValidator } from "../../accountNumberValidator";

const TwInr = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.ifsc_code`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.ifscCode")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <AccountNumberValidator modelName={`${modelName}`} />
      </Col>
    </>
  );
};

export default TwInr;
