import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { getListOptionsType } from "services/common/types/common.type";
import { RebatesTypes } from "./rebatesTypes";

export class RebatesApis {
  static readonly REBATES_ENDPOINT = "rebates/card_rebates";

  static async getRebateList({ filter, cache }: getListOptionsType = {}): Promise<RebatesTypes.RebateItem[]> {
    try {
      const response: AxiosResponse<RebatesTypes.RebateItem[]> = await restApiService.get(
        `${this.REBATES_ENDPOINT}`,
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
