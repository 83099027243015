import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise";
import React, { useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoicesNavsTabs from "../nav";
import AdvanceEmailSearchBar from "../../../../components/admin/invoices/email/advanceSearch";
import EmailGridView from "../../../../components/admin/invoices/email/emailGridView";
import { useDispatch, useSelector } from "react-redux";
import {
  emailViewSetter,
  getEmailStats,
  getEmailTags,
  setParamEmailId,
  showAdvanceSearchSetter,
} from "../../../../reducers/admin/emailReducer";
import ErrorBoundary from "../../../../components/common/errorBoundary/errorBoundary";
import { AgGridReact } from "ag-grid-react";
import { useParams } from "react-router";
import { RootState } from "../../../../reducers";
import EmailListView from "../../../../components/admin/invoices/email/emailListView";
import { GRID_VIEW, LIST_VIEW } from "../../../../services/admin/invoices/emailSvc";

const EmailList = () => {
  const dispatch = useDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const { id } = useParams<{ id: string }>();
  const emailView = useSelector((state: RootState) => state.emailRed.emailView);
  const gridRefreshKey = useSelector((state: RootState) => state.emailRed.gridRefreshKey);
  const showAdvanceSearch = useSelector((state: RootState) => state.emailRed.showAdvanceSearch);

  const initializeEmail = () => {
    dispatch(getEmailStats());
    dispatch(getEmailTags());

    if (id) {
      dispatch(setParamEmailId(id));
    }
  };

  useEffect(() => {
    initializeEmail();
  }, []);

  const changeView = () => {
    if (emailView === GRID_VIEW) {
      dispatch(emailViewSetter(LIST_VIEW));
    } else {
      dispatch(emailViewSetter(GRID_VIEW));
    }
  };

  const changeShowAdvanceSearch = () => {
    dispatch(showAdvanceSearchSetter(!showAdvanceSearch));
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Row>
            <Col md="10">
              {/* Navigation Tabs */}
              <ErrorBoundary>
                <InvoicesNavsTabs activePageName="Emails" />
              </ErrorBoundary>
            </Col>

            {/* Toogle Advance Search */}
            <Col md={"2"} className="mt-2">
              <div className={"toggleView "}>
                <p className={"cursorPointer"} onClick={() => changeView()}>
                  {emailView === GRID_VIEW ? "Grid View" : "List View"}
                </p>
              </div>
              <button
                className="toggleFilter"
                data-toggle="tooltip"
                title="Advance Search"
                onClick={() => changeShowAdvanceSearch()}
              >
                <i className="icon filter-funnel"></i>
              </button>
            </Col>
          </Row>

          {showAdvanceSearch && (
            <Row>
              <Col md="12">
                <ErrorBoundary>
                  <AdvanceEmailSearchBar gridRef={gridRef} />
                </ErrorBoundary>
              </Col>
            </Row>
          )}

          <Row>
            <Col md="12">
              <ErrorBoundary>
                {emailView === GRID_VIEW && <EmailGridView key={gridRefreshKey} gridRef={gridRef} />}
                {emailView === LIST_VIEW && <EmailListView key={gridRefreshKey} gridRef={gridRef} />}
              </ErrorBoundary>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default EmailList;
