import React, { Component } from "react";
import RestApi from "../../../providers/restApi";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import Panel from "../../../components/panel/panel";
import { Link, withRouter } from "react-router-dom";
import PlaidForm from "../../../components/paymentMethods/ach/plaid/plaidForm.js";

class VCardOnboardingPlaid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();

    this.backURL = "/ap/onboarding/vcards";
    this.item_id = "plaid_account_verified";
  }

  componentDidMount() {
    if (!this.props.onboaringState.listTemplate) {
      //cant continue as the template is not defined, go to our back url
      this.props.history.push(this.backURL);
    }
  }

  plaidAccountChose = (account) => {
    this.restApiService
      .post("plaid/set_onboarding_account", null, { account_id: account.plaid_account_id })
      .then((result) => {
        if (result.status == 200) {
          this.setIntegrationSetting();
        }
      });
  };

  setIntegrationSetting = () => {
    //post the item_id to api
    this.restApiService.get("virtual_cards/onboarding/add_bank", null, {}).then((result) => {
      if (result.status == 200) {
        //change the redux state to reflect this item is completed
        this.props.onboaringState.listTemplate.items.forEach((element) => {
          if (element.id == this.item_id) {
            element.completed = true;
          }
        });
        this.props.setListTemplateItems(this.props.onboaringState.listTemplate.items);
        //go back to main checklist page
        this.props.history.push(this.backURL);
      }
    });
  };

  render() {
    return (
      <Container className={"pt-4 pb-4"}>
        <Row style={{ minHeight: "150px" }}>
          <Col>
            <Panel cardClasses={"animated fadeIn"}>
              <Row>
                <Col className="">
                  <h1>Bank Verification</h1>
                </Col>
              </Row>
              <Row>
                <Col className="">
                  <PlaidForm vcardOnboarding={true} accountChose={this.plaidAccountChose} />
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    onboaringState: state.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setListTemplateItems: (payload) => {
      dispatch({ type: actionType.SET_ONBOARDING_LIST_TEMPLATE_ITEMS, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(VCardOnboardingPlaid)));
