exports.MapLanguageToAPI = (language) => {
  switch (language) {
    case "en":
      return "translation_en";
    case "zh":
      return "translation_cn";
    case "es":
      return "translation_es";
    default:
      return "translation_en";
  }
};
exports.MapAPIToLanguage = (language) => {
  switch (language) {
    case "translation_en":
      return "en";
    case "translation_cn":
      return "zh";
    case "translation_es":
      return "es";
    default:
      return "en";
  }
};
