import React, { ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { Field } from "redux-form";
import { PaymentFieldType } from "../types";
import { RenderSelect } from "../../../forms/bootstrapFields";
import { CHANGE_ACCOUNT_TYPE } from "../../../../actions/actionTypes";

type AccountTypePropsType = PaymentFieldType & {
  options: {
    label: string;
    value: string;
  }[];
};

const AccountType = ({ validations, required, label, options }: AccountTypePropsType) => {
  const dispatch = useDispatch();
  const changeAccountType: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({ type: CHANGE_ACCOUNT_TYPE, payload: e.target.value });
  };

  return (
    <Field
      name="account_type"
      label={label}
      component={RenderSelect}
      options={options}
      required={required}
      validate={validations}
      onChange={changeAccountType}
    />
  );
};

export default AccountType;
