import moment from "moment";
import { translate } from "../../../services/general/translation";
import { formattedAmount } from "../../../services/general/helpers";
const vendorCreditMemoHeader = [
  {
    field: "selectAll",
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    maxWidth: 40,
    minWidth: 40,
    menuTabs: [],

    // sortable: true ,
    // filter: true,
  },
  {
    field: "id",
    headerName: "ID",
    hide: true,
    headerValueGetter: function () {
      return translate("id");
    },
  },
  {
    field: "payment_number",
    headerName: "Payment Number",
    cellRenderer: "childCellRouting",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    width: 300,
    headerValueGetter: function () {
      return translate("payment_number");
    },
  },
  {
    field: "purchaser.name",
    headerName: "Customer",
    cellRenderer: "childCellRouting",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("customer");
    },
  },
  {
    field: "payment_date",
    headerName: "Date",
    filter: "agDateColumnFilter",
    minWidth: 197,
    filterParams: {
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
        var dateAsString = moment(cellValue).format("DD/MM/YYYY");
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");

        var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    },
    cellRendererSelector: function (params: any) {
      return;
    },
    headerValueGetter: function () {
      return translate("date");
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    cellRenderer: (params: any) => formattedAmount(params.data?.amount, params.data?.currency_code),
    minWidth: 120,
    filter: "agNumberColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("amount_only");
    },
  },
  {
    field: "status",
    headerName: "Status",
    filter: "agTextColumnFilter",
  },
  {
    field: "reference_number",
    headerName: "Ref. Number",
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    width: 230,
    headerValueGetter: function () {
      return translate("reference_number");
    },
  },
  {
    field: "",
    headerName: "Actions",
    cellRenderer: "childMessageRenderer",
    sortable: false,
    floatingFilter: false,
    resizable: false,
    width: 250,
    maxWidth: 250,
    cellRendererSelector: function (params: any) {
      return;
    },
    hide: false,
    cellRendererParams: {
      viewLink: "/ar/credit_memos/",
    },
    headerValueGetter: function () {
      return translate("actions");
    },
  },
];

export default vendorCreditMemoHeader;
