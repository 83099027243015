import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { IDType } from "../../../../../services/common/types/common.type";
import { RenderField, RenderSelect } from "../../../../forms/bootstrapFields";
import { required } from "../../../../../services/validations/reduxFormValidation";
import InstitutionNumber from "../../institutionNumber";
import TransitNumber from "../../transitNumber";
import { AccountNumberValidator } from "../../accountNumberValidator";

const TwCad = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const [institutionNumber, setInstitutionNumber] = useState<IDType>();
  const [transitNumber, setTransitNumber] = useState<IDType>();

  return (
    <>
      <Col md="4">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="4">
        <InstitutionNumber modelName={`${modelName}`} callBack={(value) => setInstitutionNumber(value)} />
      </Col>
      <Col md="4">
        <TransitNumber
          modelName={`${modelName}`}
          institutionNumber={institutionNumber}
          callBack={(value: IDType) => setTransitNumber(value)}
        />
      </Col>
      <Col sm="4">
        <Field
          name={`${modelName}.account_type`}
          component={RenderSelect}
          options={[
            { value: null, label: "" },
            { value: "checking", label: "Checking" },
            { value: "savings", label: "Savings" },
          ]}
          FieldClassName="formField"
          placeholder={"Search/Select"}
          label={t("common.paymentMethod.accountType")}
        />
      </Col>
      <Col md="4">
        <AccountNumberValidator
          modelName={`${modelName}`}
          accountNumberType="canadian-account-number"
          transitNumber={transitNumber}
          institutionNumber={institutionNumber}
          minLen={7}
          maxLength={12}
          label={t("common.paymentMethod.accountNumberLabel")}
        />
      </Col>
    </>
  );
};

export default TwCad;
