import React, { Component } from "react";
import { Container } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import virtualCardHeader from "../../../components/datagrid/girdHeaders/virtualCardsGridHeader";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-theme-balham.css";
import { calculateGridHeight } from "../../../services/grid/gridHeight";
import { SideBarConfig, ColumnConfig, DateFormatter } from "../../../services/common/gridService";
import ChildCellRouting from "../../../components/datagrid/childCellRouting.jsx";
import RandomChildCellRouting from "../../../components/datagrid/randomCellChildRouting";
import CurrencyCellRenderer from "../../../components/datagrid/currencyCellRenderer";
import ChildMessageRenderer from "../../../components/datagrid/childMessageRenderer.jsx";
import VirtualCardCellRenderer from "../../../components/datagrid/virtualCardCellRenderer.jsx";
// import CustomStatsToolPanel from "../../../components/datagrid/customStatsToolPanel.jsx";
import CustomStatToolPanel from "../../../components/common/dataGrid/customStatToolPanel";
import FloatingFilterComponent from "../../../components/datagrid/floatingFilterComponent.jsx";
import { Row, Col } from "react-bootstrap";
import ToggleFilterButton from "../../../components/datagrid/buttons/toggleFilterButton";

export default class VPVirtualCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    //since we are using dynamic grid height we need to set it when the component is done loading
    this.setState({ gridHeight: calculateGridHeight(this.gridElement.getBoundingClientRect().y) + "px" });
    this.getVCards();
  }

  gridReady = (params) => {
    this.setState({ gridApi: params.api });
  };

  getVCards = () => {
    this.restApiService
      .get("vendor_virtual_cards")
      .then((result) => {
        if (result.data) {
          this.setState({ virtualCards: result.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // The API is expecting column names that are different than the ones it sends on fetch
  attachFilters = (request, params) => {
    if (request.filterModel) {
      for (let key in request.filterModel) {
        switch (key) {
          case "number":
            params["invoice_number"] = request.filterModel[key].filter;
            break;
          case "status":
            params["invoice_statuses"] = request.filterModel[key].filter;
            break;
          case "purchase_order.number":
            params["purchase_order_number"] = request.filterModel[key].filter;
            break;
          case "date":
            DateFormatter(request, params, key, "date_after", "date_before");
            break;
          default:
        }
      }
    }
  };

  render() {
    return (
      <Container fluid={true} className={"pt-4 pb-4"}>
        <Row>
          <Col>
            <ToggleFilterButton
              classes="float-right"
              clickCallback={() => {
                this.setState({ showingFilter: !this.state.showingFilter }, () => {
                  this.state.gridApi.refreshHeader();
                });
              }}
            />
          </Col>
        </Row>

        <hr className="mt-4 mb-4" />

        <div
          ref={(gridElement) => {
            this.gridElement = gridElement;
          }}
          style={{ height: this.state.gridHeight, width: "100%" }}
          className="ag-theme-fresh animated fadeIn"
        >
          <AgGridReact
            columnDefs={virtualCardHeader}
            defaultColDef={{
              resizable: true,
              floatingFilter: this.state.showingFilter,
            }}
            rowData={this.state.virtualCards}
            onGridReady={this.gridReady}
            rowHeight={ColumnConfig.rowHeight}
            floatingFiltersHeight={ColumnConfig.floatingFiltersHeight}
            components={{
              childMessageRenderer: ChildMessageRenderer,
              childCellRouting: ChildCellRouting,
              randomChildCellRouting: RandomChildCellRouting,
              customStatsToolPanel: CustomStatToolPanel,
              floatingFilterComponent: FloatingFilterComponent,
              CurrencyCellRenderer: CurrencyCellRenderer,
              VirtualCardCellRenderer: VirtualCardCellRenderer,
            }}
            sideBar={SideBarConfig}
          />
        </div>
      </Container>
    );
  }
}
