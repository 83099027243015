import { ColDef, GridApi, HeaderValueGetterParams } from "ag-grid-community";
import React from "react";
import { setAllowedFilters } from "services/common/gridService";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { IUser } from "services/common/user/userTypes";
import { translate } from "services/general/translation";

export const FIELD_NAME_MAP: FieldFilterMapType = {
  comdata_account_id: {
    contains: "account_codes",
  },
  month: {
    equals: "month",
    inRange: "",
    lessThanOrEqual: "month_before",
    greaterThanOrEqual: "month_after",
  },
  year: {
    equals: "year",
    inRange: "",
    lessThanOrEqual: "year_before",
    greaterThanOrEqual: "year_after",
  },
};

function getYearOptions() {
  const currentYear = new Date().getFullYear();
  const yearsAgo = Array.from({ length: 10 }, (_, index) => currentYear - index);
  const yearOptions = yearsAgo.map((year) => ({ label: year.toString(), value: year.toString() }));
  return yearOptions;
}

export const getRebatesHeaders = ({
  gridApi,
  currentUser,
}: {
  gridApi?: GridApi;
  currentUser: IUser;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  const columnDefs: ColDef[] = [
    {
      headerName: "Select",
      field: "select",
      filter: false,
      headerCheckboxSelection: true,
      headerValueGetter: function (params: HeaderValueGetterParams) {
        return "";
      },
      resizable: false,
      pinned: true,
      lockPinned: true,
      checkboxSelection: true,
      minWidth: 35,
      width: 35,
      maxWidth: 35,
    },
    {
      field: "comdata_account_id",
      headerName: "Account Code",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("rebatesAccountCode");
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: "month",
      headerName: "Month",
      sortable: true,
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: false,
        options: [
          { label: "January", value: "1" },
          { label: "February", value: "2" },
          { label: "March", value: "3" },
          { label: "April", value: "4" },
          { label: "May", value: "5" },
          { label: "June", value: "6" },
          { label: "July", value: "7" },
          { label: "August", value: "8" },
          { label: "September", value: "9" },
          { label: "October", value: "10" },
          { label: "November", value: "11" },
          { label: "December", value: "12" },
        ],
      },
      headerValueGetter: function () {
        return translate("rebatesMonth");
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: "year",
      headerName: "Year",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: false,
        options: getYearOptions(),
      },
      headerValueGetter: function () {
        return translate("rebatesYear");
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: "url",
      headerName: "Document",
      filter: false,
      cellRenderer: (params: any) =>
        params?.value ? (
          <a className="link" href={params.value}>
            Rebate
          </a>
        ) : (
          <div></div>
        ),
      headerValueGetter: function () {
        return translate("rebatesUrl");
      },
      flex: 1,
      minWidth: 115,
    },
  ];

  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return {
    columnDefs: columnDefs,
    defaultOrder,
  };
};
