import React, { useState, useCallback } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import style from "./FileUploader.module.css";
import Dropzone from "react-dropzone";

/**
 * Renders a file uploader component with optional custom button and upload functionality.
 *
 * @param {{customButton?: ReactNode, showUploadBtn: boolean, uploadAttachments: (files)=>void, buttonText?: string, multiple?: boolean}} props - The custom button component to be rendered.
 * @return {ReactNode} The rendered file uploader component.
 */
// kept multiple true by as don't want to break existing code which is using it

const FileUploader = ({ customButton = null, showUploadBtn, uploadAttachments, buttonText = "", multiple = true }) => {
  const [showUploaderModal, setUploaderModalFlag] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const setFiles = useCallback((acceptedFiles) => {
    setAttachments((prevState) => [...prevState, ...acceptedFiles]);
  }, []);

  const removeFile = (file) => {
    setAttachments(attachments.filter((attachment) => attachment.name !== file.name));
  };

  const submitSelectedDocument = async () => {
    closeUploader();
    await uploadAttachments(attachments);
    setAttachments([]);
  };

  const openUploader = () => {
    setUploaderModalFlag(true);
  };

  const closeUploader = () => {
    setUploaderModalFlag(false);
  };

  return (
    <div className="btn-file-upload">
      {showUploadBtn && !customButton && (
        <button
          type="button"
          className={"btn-secondary btn-panel"}
          onClick={() => {
            openUploader();
          }}
        >
          <i className={"icon icon-upload"}></i> {buttonText || "Upload"}
        </button>
      )}

      {showUploadBtn && customButton && <span onClick={openUploader}>{customButton}</span>}

      {!showUploadBtn && (
        <button
          type="button"
          className={"btn-primary btn-add-file"}
          onClick={() => {
            openUploader();
          }}
        >
          <i className={"icon icon-add-file"}></i>
        </button>
      )}
      {showUploaderModal && (
        <Modal size={"lg"} show={showUploaderModal} onHide={() => closeUploader}>
          <Modal.Header>
            <Modal.Title className={style.modalHeaderTitle}>
              <Row>
                <Col sm={11}>
                  {multiple && <>Upload files</>}
                  {!multiple && <>Upload file</>}
                </Col>
                <Col sm={1}>
                  <a onClick={() => closeUploader()}>
                    <i className="icon icon-close-modal"></i>
                  </a>
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={style.fileUploadContainer}>
              <Dropzone onDrop={setFiles} multiple={multiple}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ className: "dropzone" })} className="text-center">
                      <Row>
                        <Col sm className="d-flex justify-content-center">
                          <i className="iconUploader icon-upload-attachment"></i>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm className="d-flex justify-content-center">
                          <p className={`${style.dropFileLabel} font-weight-bold`}>
                            {multiple && <>Drag and drop files here</>}
                            {!multiple && <>Drag and drop a file here</>}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm className="d-flex justify-content-center">
                          <p className={`${style.dropFileLabel} font-weight-bold`}>or</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm className="d-flex justify-content-center mt-2">
                          <input {...getInputProps()} />
                          <button
                            className={`btn btn-secondary ${style.bgButton}`}
                            type="button"
                            style={{ padding: "6px 25px" }}
                          >
                            {multiple && <>Browse files</>}
                            {!multiple && <>Browse</>}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {attachments &&
              attachments.length > 0 &&
              attachments.map((file, index) => (
                <Row className="mt-2" key={index}>
                  <Col sm={11}>{file.name}</Col>
                  <Col sm={1} className={style.btnRemoveFile}>
                    <a onClick={() => removeFile(file)}>X</a>
                  </Col>
                </Row>
              ))}
          </Modal.Body>
          <Modal.Footer className={style.modalFooter}>
            <button
              className="btn btn-primary"
              type="button"
              onClick={submitSelectedDocument}
              disabled={!attachments || attachments.length == 0}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default FileUploader;
