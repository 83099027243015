//@ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import {
  change,
  Field,
  FieldArray,
  FormAction,
  formValueSelector,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import RestApi from "providers/restApi";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { MetadataTemplate } from "pages/admin/administration/metadata/metadataTemplate";
import MetadataConfigurationPicker from "components/admin/pickers/reduxFormPickers/metadataConfigurationPicker/metadataConfigurationPicker";
import { MetadataConfigurationTypes } from "components/metadata/metadataConfigurationEdit/metadataConfigurationUpsert";
import { RenderSelect } from "components/forms/bootstrapFields";
import { Form, FormGroup } from "react-bootstrap";
import GenericSelect from "components/admin/pickers/inputs/genericSelect";
import _, { cloneDeep, clone } from "lodash";

const restApi = new RestApi();

interface MetadataTemplateEditProps {
  metadataTemplate: MetadataTemplate;
  refreshCallback: any;
}

type Props = MetadataTemplateEditProps & InjectedFormProps<{}, MetadataTemplateEditProps>;

const MetadataConfigurationPickers = ({ fields }) => (
  <div>
    {fields.map((link, index) => (
      <div key={index}>
        <Form.Group className="d-flex align-items-end mb-0" style={{ minWidth: "200px" }}>
          <div className="mr-2 flex-grow-1">
            <Form.Label className={index == 0 ? "" : "d-none"}>Field {link.metadata_configuration_id}</Form.Label>
            <Field
              className=""
              component={MetadataConfigurationPicker}
              name={`${link}.metadata_configuration_id`}
              required={true}
              props={{
                isMulti: false,
                modal: true,
              }}
            ></Field>
          </div>

          <div className="mr-2">
            <Form.Label className={index == 0 ? "" : "d-none"}>Required</Form.Label>
            <Field
              name={`${link}.required`}
              component={RenderSelect}
              label={""}
              type="text"
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              required={true}
              className={`mr-2`}
            />
          </div>

          <div style={{ width: "105px" }}>
            <Form.Label className={index == 0 ? "" : "d-none"}>Position</Form.Label>
            <Field
              name={`${link}.position`}
              component="input"
              type="number"
              placeholder="Position"
              className="form-control mb-3"
              normalize={(value) => (value ? Number(value) : null)}
            />
          </div>

          <div>
            <button
              type="button"
              onClick={() => {
                fields.splice(index, 1);
              }}
              className="mb-3"
            >
              Delete
            </button>
          </div>
        </Form.Group>
      </div>
    ))}

    <button type="button" className="btn btn-secondary" onClick={() => fields.push({ required: false })}>
      Add Field
    </button>
  </div>
);

const MetadataTemplateUpsert: React.FC<Props> = ({
  metadataTemplate,
  initialValues,
  refreshCallback,
  handleSubmit,
}) => {
  const [savedLinks, setSavedLinked] = useState(initialValues.metadata_configuration_template_links_attributes || []);

  const onSubmit = async (formValues: any) => {
    //add in any links we deleted
    let newValues = cloneDeep(formValues);

    if (savedLinks && savedLinks.length > 0) {
      savedLinks.forEach((link) => {
        if (!newValues.metadata_configuration_template_links_attributes.find((newLink) => newLink.id === link.id)) {
          newValues.metadata_configuration_template_links_attributes.push({ ...link, _destroy: true });
        }
      });
    }

    //edit or update
    if (newValues.id) {
      await restApi
        .patch("metadata_templates/" + newValues.id, null, { metadata_template: newValues })
        .then((result) => {
          CreateNotification("Success", "Metadata Template updated succesfully", NotificationType.success);
          if (refreshCallback) {
            refreshCallback();
          }
        })
        .catch((error) => {
          console.error(error);
          CreateNotification("Error", "Error updating template: " + error, NotificationType.danger);
          return null;
        });
    } else {
      await restApi
        .post("metadata_templates", null, { metadata_template: newValues })
        .then((result) => {
          CreateNotification("Success", "Metadata Template created succesfully", NotificationType.success);
          if (refreshCallback) {
            refreshCallback();
          }
        })
        .catch((error) => {
          console.error(error);
          CreateNotification("Error", "Error creating template: " + error, NotificationType.danger);
          return null;
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className=" mb-2">
      <div>
        <Form.Group className="d-flex">
          <div className="flex-grow-1 mr-2" style={{ minWidth: "100px" }}>
            <Form.Label>Name</Form.Label>
            <Field name="name" component="input" type="text" placeholder="Template Name" className="form-control" />
          </div>

          <div className="flex-grow-1 mr-2" style={{ minWidth: "100px" }}>
            <Form.Label>Status</Form.Label>
            <Field name="status" component="select" className="form-control">
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </Field>
          </div>

          <div className="flex-grow-1">
            <Form.Label>Modules</Form.Label>
            <Field
              name="modules"
              options={MetadataConfigurationTypes}
              component={GenericSelect}
              isMulti={true}
              placeholder="Modules"
            />
          </div>
        </Form.Group>
      </div>

      <FieldArray name="metadata_configuration_template_links_attributes" component={MetadataConfigurationPickers} />

      <div className="d-flex justify-content-end mt-3">
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </div>
    </form>
  );
};

export default reduxForm<{}, MetadataTemplateEditProps>({
  form: "metadataTemplateForm",
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  destroyOnUnmount: true,
})(MetadataTemplateUpsert);
function dispatch(arg0: FormAction) {
  throw new Error("Function not implemented.");
}
