import { RenderCheckBox } from "components/forms/bootstrapFields";
import TooltipRender from "components/toolTip/tooltipRender";
import { isBoolean } from "lodash";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { capitalizeEachWord } from "services/general/helpers";
import { WombatSettingType } from "wombatifier/services/wombat.types";
interface WombatSettingPropsType {
  settings: WombatSettingType;
}
export const WombatSettings = ({ settings }: WombatSettingPropsType) => {
  const { t, i18n } = useTranslation();
  const mappedSettings = Object.entries(settings);
  const formatFieldLabel = (setting_name: string) => {
    let translationPresent = i18n.exists(`settings.integrations.${setting_name}`);
    let tooltipTranslation = i18n.exists(`settings.integrations.tooltips.${setting_name}`) ? (
      <TooltipRender title={t(`settings.integrations.tooltips.${setting_name}`)} className="icon-20 icon-info-blue" />
    ) : null;
    return translationPresent ? (
      <span style={{ verticalAlign: "middle", display: "inline-flex", alignItems: "center" }}>
        {t(`settings.integrations.${setting_name}`)} {tooltipTranslation}
      </span>
    ) : (
      setting_name
    );
  };
  return (
    <>
      <Container className="px-0">
        {mappedSettings.map(([destination_type, configuration]) => {
          const mappedConfiguration = Object.entries(configuration);
          if (
            mappedConfiguration.length === 0 ||
            !mappedConfiguration.some(([setting_name, value]) => isBoolean(value))
          ) {
            return <></>;
          }
          return (
            <Row className="mx-0 pb-3">
              <Col md={12}>
                <label>{capitalizeEachWord(destination_type, "_", " ")} Settings</label>
              </Col>
              <Col>
                <Card className="shadow-sm p-3 d-flex flex-row flex-wrap">
                  {mappedConfiguration.map(([setting_name, value]) => {
                    if (!isBoolean(value)) {
                      return <></>;
                    }
                    return (
                      <Field
                        name={`form.wombat_settings.${destination_type}.${setting_name}`}
                        component={RenderCheckBox}
                        label={formatFieldLabel(setting_name)}
                        labelClassName="font-weight-bold"
                        className="py-1 px-2"
                      />
                    );
                  })}
                </Card>
              </Col>
            </Row>
          );
        })}
      </Container>
    </>
  );
};
