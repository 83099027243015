import React from "react";
import FormExpenseItem from "./formExpenseItem";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { CreateNotification, NotificationType } from "services/general/notifications";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { useHistory, useParams } from "react-router";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { SubmissionError, change } from "redux-form";
import { createCompleteError } from "services/general/reduxFormSvc";
import { ExpenseConstants } from "services/admin/expenses/expenseSvc";
import { useDispatch } from "react-redux";
import _ from "lodash";

const EditExpenseItem = () => {
  const { id: expenseItemId } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const checkStatus = (
    expenseItem: ExpensesTypes.ExpenseItemFormDataType,
    expenseForm: ExpensesTypes.ExpenseFormDataType,
  ) => {
    if (!expenseForm.is_draft) {
      expenseItem.status = "NEW";
    } else {
      expenseItem.status = "DRAFT";
    }
  };

  const matchBaseAndTargetConversion = (expenseItem: ExpensesTypes.ExpenseItemFormDataType) => {
    expenseItem.base_currency_amount = expenseItem.amount;
    expenseItem.base_currency_tax = expenseItem.tax;
    if (expenseItem.total !== null) expenseItem.base_currency_total = expenseItem.total;
  };

  const onSubmit = async (expenseItemsFormData: ExpensesTypes.ExpenseFormDataType) => {
    // As for Edit expense item there will only one expense item
    if (Array.isArray(expenseItemsFormData.expenses) && expenseItemsFormData.expenses[0]) {
      const expenseItem = expenseItemsFormData.expenses[0];
      if (!expenseItem.description) {
        expenseItem.description = "";
      }
      if (!expenseItem.department_id) {
        expenseItem.department_id = "";
      }
      checkStatus(expenseItem, expenseItemsFormData);

      if (expenseItem && expenseItem.amount === 0) {
        CreateNotification("Not Allowed", "Total Amount should not be zero", NotificationType.danger);
        return;
      }

      // This ensures that attachment is not getting submitted with the PATCH request, making it more efficient.
      const attachments = expenseItemCommonSvc.moveAssetsAttributes(expenseItem);

      if (expenseItem.currency_code === expenseItem.base_currency_code) {
        matchBaseAndTargetConversion(expenseItem);
      }

      //tell our metadata directive to serialize the metadata into metadata_link_attributes
      //   $scope.$emit("metadata_serialize", null);

      try {
        const updateExpenseItemRes = await ExpensesApis.updateExpenseItem({
          id: expenseItemId,
          payload: { expense_item: expenseItem },
        });
        if (updateExpenseItemRes) {
          CreateNotification(
            "Updated",
            "Expense Item Number " + updateExpenseItemRes.number + " updated",
            NotificationType.success,
          );
          //if expense was created upload any attachment
          if (Array.isArray(attachments) && attachments.length > 0 && updateExpenseItemRes.id) {
            try {
              const attachmentUpdatedRes = await expenseItemCommonSvc.uploadAttachments({
                attachments: attachments,
                expenseId: updateExpenseItemRes.id,
              });
              if (attachmentUpdatedRes) {
                history.push("/ap/expenses/" + updateExpenseItemRes.id);
              }
            } catch (error) {
              throw error;
            }
          } else {
            history.push("/ap/expenses/" + updateExpenseItemRes.id);
          }
        }
      } catch (error) {
        dispatch(change(ExpenseConstants.EXPENSE_ITEM_FORM, "is_submit_disabled", false));
        if (
          typeof error === "object" &&
          error !== null &&
          "response" in error &&
          typeof error.response === "object" &&
          error.response !== null &&
          "status" in error.response &&
          error?.response?.status
        ) {
          const { response } = error;
          if (response.status === 422) {
            if (
              typeof response === "object" &&
              response != null &&
              "data" in response &&
              _.isPlainObject(response.data)
            ) {
              const completeErrorObj = createCompleteError(response.data);
              const formError: any = {};
              formError[`expenses[${0}]`] = completeErrorObj;

              if (typeof response.data === "object") {
                const responseData: any = response.data;
                for (const key in responseData) {
                  CreateNotification("Invalid Data", `${key} ${responseData[key][0]}`, NotificationType.danger);
                }
              }
              throw new SubmissionError(formError);
            }
            if ("data" in response && typeof response.data === "object" && response.data !== null) {
              if ("assets.asset_file_file_name" in response.data && response.data["assets.asset_file_file_name"]) {
                expenseItem.error = "Invalid Attachment";
                if (Array.isArray(response.data["assets.asset_file_file_name"])) {
                  CreateNotification(
                    "Invalid Attachment",
                    response.data["assets.asset_file_file_name"][0],
                    NotificationType.danger,
                  );
                }
              }

              if ("base_currency_total" in response.data && response.data["base_currency_total"]) {
                dispatch(
                  change(ExpenseConstants.EXPENSE_ITEM_FORM, `expenses[${0}].error`, "Currency Conversion error"),
                );
                if (Array.isArray(response.data["base_currency_total"])) {
                  CreateNotification(
                    "Invalid Attachment",
                    response.data["base_currency_total"][0],
                    NotificationType.danger,
                  );
                }
              }

              if ("currency_code" in response.data && response.data["currency_code"]) {
                dispatch(change(ExpenseConstants.EXPENSE_ITEM_FORM, `expenses[${0}].error`, "Currency Code error"));
                if (Array.isArray(response.data["currency_code"])) {
                  CreateNotification("Invalid Attachment", response.data["currency_code"][0], NotificationType.danger);
                }
              }
            }
          } else {
            dispatch(
              change(
                ExpenseConstants.EXPENSE_ITEM_FORM,
                `expenses[${0}].error`,
                "Error Saving Item. Please try again later or reach out to support",
              ),
            );
          }
        }
      }
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  return <FormExpenseItem onSubmit={onSubmit} onCancel={onCancel} isEdit />;
};

export default EditExpenseItem;
