import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { CHANGE_CAMBRIDGE_FIELD, CHANGE_CAMBRIDGE_REGULATORY_FIELD } from "../../../../../actions/actionTypes";
import { restApiService } from "../../../../../providers/restApi";
import { useTypedSelector } from "../../../../../reducers";
import { getCurrencyCodeFromCountryCode } from "../../../../app.svc.Lookup";
import PaymentCountryPicker from "../../../../pickers/paymentCountryPicker";
import CurrencyPicker from "../../../pickers/reduxPicker/currencyPicker/currencyPicker";
import GenericSelectPicker from "../../../pickers/reduxPicker/genericSelectPicker/genericSelectPicker";
import CambridgeRuleInput from "./cambridgeRuleInput";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { CambridgeRequiredType } from "../../../../common/managePaymentMethod/types";
import { AxiosResponse } from "axios";

const CambridgeFields = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [savedPMHash, setSavedPMHash] = useState("");
  const [fields, setFields] = useState<CambridgeRequiredType | null>(null);
  const [loading, setLoading] = useState(false);
  const isEdit = props.paymentMethodRed.id ? true : false;
  const [editDataShown, setEditDataShown] = useState(false);

  const country = useTypedSelector((state) => state.paymentMethodRed.country);
  const currency_code = useTypedSelector((state) => state.paymentMethodRed.currency_code);
  const cambridge = useTypedSelector((state) => state.paymentMethodRed.cambridge);

  const updateCambridgeField = (id: string, value: string) => {
    dispatch({ type: CHANGE_CAMBRIDGE_FIELD, payload: { id: id, value: value } });
  };

  const updateCambridgeRegulatoryField = (id: string, value: string) => {
    dispatch({ type: CHANGE_CAMBRIDGE_REGULATORY_FIELD, payload: { id: id, value: value } });
  };

  const genericCallback = (rule: { id: string }, event: { target: { value: string } }, value: string) => {
    if (event) {
      updateCambridgeField(rule.id, event.target.value);
    } else {
      updateCambridgeField(rule.id, value);
    }
  };

  const genericCallbackRegulatory = (rule: { id: string }, event: { target: { value: string } }, value: string) => {
    if (event) {
      updateCambridgeRegulatoryField(rule.id, event.target.value);
    } else {
      updateCambridgeRegulatoryField(rule.id, value);
    }
  };

  const countryCallback = (value: any) => {
    props.countryPickerCallback(value);
    autoPopulate(value);
  };

  //when someone selects destination country we should auto select the bank country and currency
  const autoPopulate = (value: { value: any }) => {
    //auto select bank country
    bankCountryCallback(value);
    let currencyCode = getCurrencyCodeFromCountryCode(value.value);
    if (currencyCode) {
      props.currencyPickerCallback({ value: currencyCode });
    }
  };

  const bankCountryCallback = (value: { value: string }) => {
    updateCambridgeField("bank_country", value.value);
    updateCambridgeField("bankCountry", value.value);
  };

  const classificationChanged = (value: { value: string }) => {
    updateCambridgeField("classification", value.value.charAt(0).toUpperCase() + value.value.slice(1));
    updateCambridgeField("setup_classification", value.value);
  };

  const paymentMethodChanged = (value: { value: string }) => {
    updateCambridgeField("payment_method", value.value);
  };

  useEffect(() => {
    fetchFields();
  }, [currency_code, country, cambridge?.payment_method, cambridge?.classification]);

  // update currency code when ever distination company country
  useEffect(() => {
    //autopopulate on start
    if (!cambridge || !cambridge.bank_country) {
      autoPopulate({ value: country });
    }
  }, [country]);

  // We want to fetch fields automatically but we do NOT want to keep fetching them everytime the component updates.
  // We should only update when we have valid data to send to the API AND when we haven't done particular request yet
  const canFetchFields = () => {
    return Boolean(
      !loading &&
        country &&
        currency_code &&
        cambridge &&
        cambridge.bankCountry &&
        cambridge.payment_method &&
        cambridge.classification &&
        (getFieldHash() !== savedPMHash || !savedPMHash),
    );
  };

  // Fetch the required fields from the API
  const fetchFields = async () => {
    if (!canFetchFields()) {
      return;
    }
    setLoading(true);
    let params = {
      destination_country: country,
      bank_country: cambridge.bank_country,
      currency: currency_code,
      payment_method: cambridge.payment_method,
      classification: cambridge.classification,
      type: "cambridge",
    };
    try {
      const res: AxiosResponse<{ response: CambridgeRequiredType }> = await restApiService.get(
        "payment_methods/required_fields",
        params,
      );
      setSavedPMHash(getFieldHash());
      if (res?.data?.response) {
        // for updating ui for showing feilds
        setFields(res.data.response);

        // while editing pm for 1st time, we don't want to override feilds value with default values
        setEditDataShown(true);
        if (!isEdit || (isEdit && editDataShown)) {
          // using response directly as field will update in next render
          // set all redux fields equal to the default values we get from cambridge
          _.isArray(res.data.response?.rules) &&
            res.data.response?.rules.forEach((rule) => {
              if (rule.defaultValue && rule.id === "classification") {
                updateCambridgeField(rule.id, rule.defaultValue.charAt(0).toUpperCase() + rule.defaultValue.slice(1));
              } else if (rule.defaultValue) {
                if (rule.id === "preferredMethod" || rule.id === "paymentMethods") {
                  updateCambridgeField(rule.id, cambridge.payment_method);
                } else {
                  // normal default rule
                  updateCambridgeField(rule.id, rule.defaultValue);
                }
              }
            });
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // A 'hash' function to have a unique value for fields we have already fetched
  const getFieldHash = () => {
    return (
      "" +
      country +
      currency_code +
      cambridge.bank_country +
      cambridge.payment_method +
      cambridge.classification +
      cambridge.purchaser_id
    );
  };

  return (
    <Row>
      <Col sm={12}>
        <h4>
          Synced Id:{" "}
          {cambridge && cambridge.beneficiaryId ? (
            <span className={"App-link"}>{cambridge.beneficiaryId}</span>
          ) : (
            "not synced"
          )}
        </h4>
      </Col>
      <Col>
        <Row>
          <PaymentCountryPicker
            required={true}
            countryPickerCallback={countryCallback}
            label={"Destination Country"}
            selected={{ value: country }}
          />
          <PaymentCountryPicker
            required={true}
            countryPickerCallback={bankCountryCallback}
            label={"Bank Country"}
            selected={{
              value: _.isObject(cambridge?.bank_country) ? cambridge?.bank_country?.code2 : cambridge?.bankCountry,
            }}
          />
          <CurrencyPicker {...props} selected={{ value: currency_code }} />
        </Row>
        <Row>
          <Form.Group as={Col} md="4">
            <GenericSelectPicker
              callback={classificationChanged}
              id={cambridge ? cambridge.setup_classification : null}
              required={true}
              label={"Classification"}
              options={[
                { id: "business", label: "Business" },
                { id: "individual", label: "Individual" },
              ]}
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <GenericSelectPicker
              callback={paymentMethodChanged}
              id={cambridge ? cambridge.payment_method : null}
              required={true}
              label={"Payment Method"}
              options={[
                { id: "W", label: "Wire" },
                { id: "E", label: "iACH" },
              ]}
            />
            {fields?.payment_method_error && <h4 className="text-danger">Payment Method Type Not Supported</h4>}
          </Form.Group>
        </Row>

        <Row>
          {loading && (
            <Col className="mt-2 mb-2">
              <strong>{t("loading.fields")}...</strong>
            </Col>
          )}
          {!loading &&
            cambridge &&
            _.isArray(fields?.rules) &&
            !fields?.payment_method_error &&
            fields?.rules.map((rule: { id: string }) => {
              return (
                <CambridgeRuleInput rule={rule} callback={genericCallback} key={rule.id} id={cambridge[rule.id]} />
              );
            })}
          {/* Regulatory rules */}
          {!loading &&
            cambridge &&
            _.isArray(fields?.regulatoryRules) &&
            !!fields?.regulatoryRules?.length &&
            fields?.regulatoryRules?.length > 0 && (
              <>
                <Col sm={12}>
                  <h4>Regulatory Rules:</h4>
                </Col>

                {fields?.regulatoryRules.map((rule: { id: string }) => {
                  return (
                    <CambridgeRuleInput
                      rule={rule}
                      callback={genericCallbackRegulatory}
                      key={rule.id}
                      id={(cambridge && cambridge["regulatoryRules"] && cambridge["regulatoryRules"][rule.id]) ?? ""}
                    />
                  );
                })}
              </>
            )}
        </Row>
      </Col>
    </Row>
  );
};

export default CambridgeFields;
