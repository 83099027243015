import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import usePermission from "components/common/hooks/usePermission";
import TabNavigator from "components/common/tabNavigator/tabNavigator";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import FormSubmissionErrorModal from "components/modals/formSubmissionErrorModal/formSubmissionErrorModal";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useTypedSelector } from "reducers";
import { InjectedFormProps, getFormSubmitErrors, getFormSyncErrors, reduxForm } from "redux-form";
import CustomLabelsApi from "services/admin/customLabels/customLabelsApi";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { NavbarTabType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";
import InvoiceExpenseLineSection from "./invoiceExpenseLineSection";
import InvoiceHeaderLineSection from "./invoiceHeaderLineSection";
import InvoiceItemLineSection from "./invoiceItemLineSection";

export const formName = "InvoiceForm";
export const INVOICE_FORM_NAME = "InvoiceForm";

// We map what fields go to what pages so the error modal knows how to group
const pages = [
  {
    label: "Main Tab",
  },
  {
    label: "Line Items Tab",
    fields: ["invoice_items_attributes"],
  },
  {
    label: "Accounts Tab",
    fields: ["debit_entries_attributes", "credit_entries_attributes"],
  },
];

type InvoiceFormPropsTypes = {
  isAddForm?: boolean;
  returnTo?: string;
  wantToCopy?: boolean;
  onSubmit: (invoice: InvoiceType.InvoiceDetailType) => void;
  mergeCopiedInvoice?: (copiedInvoice: InvoiceType.InvoiceDetailType) => void;
  onCancel: () => void;
  savedInvoice?: InvoiceType.InvoiceDetailType;
  compareWithInvoiceOriginalAmount?: any;
  formSyncErrors?: any;
  formSubmissionErrors?: any;
  dispatch: any;
};

interface InvoiceReduxFormProps
  extends InvoiceFormPropsTypes,
    InjectedFormProps<InvoiceType.InvoiceDetailType, InvoiceFormPropsTypes> {}

const FormInvoice = ({
  handleSubmit,
  isAddForm,
  onCancel,
  savedInvoice,
  wantToCopy,
  mergeCopiedInvoice,
  valid,
  formSyncErrors,
  formSubmissionErrors,
  submitFailed,
  invalid,
  dispatch,
}: InvoiceReduxFormProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>("main");
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { hasUserPermission } = usePermission();
  const { createConfirmModal } = useConfirmModal();
  const invoiceId: any = savedInvoice?.id;

  const [customLabels, setCustomLabels] = useState<{}>([]);
  const [loadingCustomLabels, setIsLoadingCustomLabels] = useState<boolean>(true);
  const fetchCustomLabels = async () => {
    try {
      const response = await CustomLabelsApi.index({ filter: { status: "ACTIVE", hashify: true } });
      setCustomLabels(response);
      setIsLoadingCustomLabels(false);
    } catch (error) {
      setIsLoadingCustomLabels(false);
    }
  };

  useEffect(() => {
    fetchCustomLabels();
  }, []);

  const tabData: NavbarTabType = [
    { activeTab: "main", label: "Main" },
    { activeTab: "lineItems", label: "Line Items", hide: !currentUser.company.allow_invoice_items },
    { activeTab: "accounts", label: "Accounts", hide: invoiceCommonSvc.hideAccountTab("All", currentUser) },
  ];

  const changeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const allowInvoiceDelete = () => {
    // legacy roles                                 // new roles
    return (
      (hasUserPermission("allowDeleteDraftInvoice") || hasUserPermission("deleteInvoices")) &&
      savedInvoice?.status === "DRAFT"
    );
  };

  const onDeleteConfirm = async () => {
    if (invoiceId) {
      const response = await InvoiceApis.deleteInvoice(invoiceId);
      CreateNotification("Deleted", `Invoice number ${savedInvoice?.number}`, NotificationType.success);
    }
  };

  const destroyInvoice = () => {
    createConfirmModal({
      title: t("confirm"),
      body: `Are you sure you want to delete #${savedInvoice?.number} invoice.`,
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: onDeleteConfirm,
    });
  };

  return (
    <Form noValidate onSubmit={handleSubmit} className="InvoiceForm" autoComplete="off">
      {!loadingCustomLabels && (
        <>
          <Row>
            <Col>
              <ErrorBoundary>
                <TabNavigator activeTab={activeTab} handleTabChange={changeTab} tabData={tabData} />
              </ErrorBoundary>
            </Col>
          </Row>
          <Row className="px-mt-25">
            <Col>
              <ErrorBoundary>
                <div style={{ display: activeTab === "main" ? "block" : "none" }}>
                  <InvoiceHeaderLineSection
                    isAddForm={isAddForm}
                    valid={valid}
                    savedInvoice={savedInvoice}
                    wantToCopy={wantToCopy}
                    mergeCopiedInvoice={mergeCopiedInvoice}
                    customLabels={customLabels}
                  />
                </div>
              </ErrorBoundary>
              <ErrorBoundary>
                {currentUser?.company?.allow_invoice_items && (
                  <div style={{ display: activeTab === "lineItems" ? "block" : "none" }}>
                    {<InvoiceItemLineSection valid={valid} customLabels={customLabels} />}
                  </div>
                )}
              </ErrorBoundary>
              <ErrorBoundary>
                <div style={{ display: activeTab === "accounts" ? "block" : "none" }}>
                  {<InvoiceExpenseLineSection valid={valid} customLabels={customLabels} />}
                </div>
              </ErrorBoundary>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="btn btn-secondary" className="px-mr-16" onClick={onCancel} id="invoice_cancel_btn">
                {t("cancel")}
              </Button>
              <Button variant="btn btn-primary" type="submit" id="invoice_submit_btn">
                {t("submit")}
              </Button>
              {savedInvoice && allowInvoiceDelete() && (
                <Button variant="btn btn-danger" onClick={() => destroyInvoice()} id="invoice_delete_btn">
                  Delete
                </Button>
              )}
            </Col>
          </Row>

          <FormSubmissionErrorModal
            fieldPageMapping={pages}
            formErrors={formSyncErrors}
            submitErrors={formSubmissionErrors}
            formName={formName}
            show={
              submitFailed &&
              ((invalid && Object.keys(formSyncErrors).length > 0) || Object.keys(formSubmissionErrors).length > 0)
            }
            dispatch={dispatch}
          />
        </>
      )}
    </Form>
  );
};

const mapStateToProps = (state: any) => ({
  formSyncErrors: getFormSyncErrors(formName)(state),
  formSubmissionErrors: getFormSubmitErrors(formName)(state),
});

const ConnectedFormInvoice = connect(mapStateToProps)(
  reduxForm<InvoiceType.InvoiceDetailType, InvoiceFormPropsTypes>({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
    shouldError: () => true,
    validate: (formData: InvoiceType.InvoiceDetailType): any => {
      const errors: any = {};
      const validateEndDate = (startDate?: string, endDate?: string) => {
        if (startDate && endDate && new Date(endDate) <= new Date(startDate)) {
          return false;
        }
        return true;
      };

      if (!validateEndDate(formData?.service_start_date, formData?.service_end_date)) {
        errors.service_end_date = "Service End Date should be greater than Service Start Date";
      }

      return errors;
    },
  })(FormInvoice),
);

export default ConnectedFormInvoice;
