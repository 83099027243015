import { RenderCheckBox } from "components/forms/bootstrapFields";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { INTEGRATION_FORM_NAME } from "reducers/admin/integrationsReducer";
import { change, Field, FieldArray, FieldArrayFieldsProps, getFormValues } from "redux-form";
import IntegrationApis from "services/admin/integration/integrationApis";
import { IntegrationTypes } from "services/admin/integration/integrationTypes";
import { IUser } from "services/common/user/userTypes";
import { companyDateTimeFormat } from "services/general/dateSvc";
import styles from "../../integrations.module.css";

type JobsColumnType = {
  fields: FieldArrayFieldsProps<IntegrationTypes.IntegrationJobType>;
  integrationJob: { inactiveAllJobs: boolean; jobs: IntegrationTypes.IntegrationJobsType };
};

type SubJobsColumnType = {
  fields: FieldArrayFieldsProps<IntegrationTypes.SubJobType>;
  integrationJob: { inactiveAllJobs: boolean; jobs: IntegrationTypes.IntegrationJobsType };
  jobIndex: number;
  job: IntegrationTypes.IntegrationJobType;
};

const SubJobsColumn = ({ fields, integrationJob, jobIndex, job }: SubJobsColumnType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleSubJobActive = (obj: IntegrationTypes.SubJobType, value: boolean) => {
    obj.isActive = value;
    if (value && !job.isActive) {
      job.isActive = true;
      job.sub_jobs = job.sub_jobs.map((subJob) => (subJob.id === obj.id ? obj : subJob));
      dispatch(change(INTEGRATION_FORM_NAME, `jobsForm.jobs[${jobIndex}]`, job));
    }
    if (value && integrationJob?.inactiveAllJobs) {
      dispatch(change(INTEGRATION_FORM_NAME, `jobsForm.inactiveAllJobs`, false));
    }
  };
  return (
    <>
      {fields &&
        fields.length > 0 &&
        fields.map((subJob, index: number) => (
          <Row className={` ${index % 2 === 1 ? styles.oddRow : ""} my-1  `} key={index}>
            <Col xs="5" className={`my-1 pl-5 d-flex flex-row ${styles.subJobText}`}>
              <span className="pr-1">
                {String.fromCharCode(index + 97)}.{}
              </span>
              {fields.get(index).display_name}
            </Col>
            <Col xs="3" className={`my-1 ${styles.subJobText}`}>
              {fields.get(index).display_schedule}
            </Col>
            <Col xs="2" className={`my-1 ms-3 ${styles.subJobText} ${styles.lastRun}`}>
              {companyDateTimeFormat(fields.get(index).last_synced_at, currentUser)}
            </Col>
            <Col xs="1" className={styles.jobsActiveTab}>
              <Field
                name={`${subJob}.isActive`}
                component={RenderCheckBox}
                onChange={(value: any) => handleSubJobActive(fields.get(index), value)}
              />
            </Col>
          </Row>
        ))}
    </>
  );
};

const JobsColumn = ({ fields, integrationJob }: JobsColumnType) => {
  const dispatch = useDispatch();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const handleJobActive = (obj: IntegrationTypes.IntegrationJobType, value: any, index: number) => {
    obj.isActive = value;
    if (_.isArray(obj?.sub_jobs) && obj.sub_jobs.length > 0) {
      obj.sub_jobs = obj.sub_jobs.map((obj) => ({ ...obj, isActive: value }));
      dispatch(change(INTEGRATION_FORM_NAME, `jobsForm.jobs[${index}]`, obj));
    }
    if (value && integrationJob?.inactiveAllJobs) {
      dispatch(change(INTEGRATION_FORM_NAME, `jobsForm.inactiveAllJobs`, false));
    }
  };
  return (
    <div className="py-2">
      {fields &&
        fields.length > 0 &&
        fields.map((job, index: number) => (
          <Fragment key={index}>
            <Row className={`${styles.rowBorder} `}>
              <Col xs="5" className="my-1  d-flex flex-row">
                <span className="pr-1">{index + 1}. </span>
                {fields.get(index).display_name}
              </Col>
              <Col xs="3" className="my-1">
                {fields.get(index).display_schedule}
              </Col>
              <Col xs="2" className={`my-1 ms-3 ${styles.lastRun}`}>
                {companyDateTimeFormat(fields.get(index).last_synced_at, currentUser)}
              </Col>
              <Col xs="1" className={styles.jobsActiveTab}>
                <Field
                  name={`${job}.isActive`}
                  component={RenderCheckBox}
                  onChange={(value: any) => handleJobActive(fields.get(index), value, index)}
                />
              </Col>
            </Row>
            <FieldArray
              name={`${job}.sub_jobs`}
              component={SubJobsColumn}
              integrationJob={integrationJob}
              job={fields.get(index)}
              jobIndex={index}
            />
          </Fragment>
        ))}
    </div>
  );
};

const IntegrationJobs = () => {
  const integrationForm: any = useTypedSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const dispatch = useDispatch();

  const getIntegration = async () => {
    try {
      if (integrationForm.form.id) {
        const result = await IntegrationApis.getIntegrationJobs(integrationForm.form.id);
        dispatch(change(INTEGRATION_FORM_NAME, "jobsForm.jobs", result));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAllJobsInactive = (value: any) => {
    integrationForm.jobsForm.inactiveAllJobs = value;
    if (_.isArray(integrationForm?.jobsForm?.jobs) && integrationForm?.jobsForm?.jobs.length > 0 && value) {
      let jobs = integrationForm.jobsForm.jobs.map((job: IntegrationTypes.IntegrationJobType) => {
        let obj = job;
        obj.isActive = false;
        obj.sub_jobs = obj.sub_jobs.map((obj) => ({ ...obj, isActive: false }));
        return obj;
      });
      dispatch(change(INTEGRATION_FORM_NAME, "jobsForm.jobs", jobs));
    }
  };

  useEffect(() => {
    getIntegration();
    return () => {
      dispatch(change(INTEGRATION_FORM_NAME, "jobsForm.jobs", []));
    };
  }, []);
  return (
    <>
      {integrationForm?.jobsForm?.jobs &&
        _.isArray(integrationForm?.jobsForm?.jobs) &&
        integrationForm?.jobsForm?.jobs.length > 0 && (
          <>
            <hr className="mt-2 mb-1" />
            <Col lg="12" className="mt-3">
              <Row>
                <Col sm="12" className="d-flex">
                  <h5 className={"mr-2 " + styles.jobsTableTitle}>Scheduled Jobs</h5>
                </Col>
              </Row>
              <Container className={"mt-3 " + styles.jobsTable}>
                <Row className={styles.jobsTableHeader}>
                  <Col xs="5" className="my-1 ">
                    Name
                  </Col>
                  <Col xs="2" className={`my-1 ms-3  ${styles.schedule}`}>
                    Schedule
                  </Col>
                  <Col xs="2" className={styles.lastrunheading + " my-1  text-center ms-5"}>
                    Last Run
                  </Col>
                  <Col xs="1" className={styles.jobsActiveTabHeader + " my-1"}>
                    Active
                  </Col>
                </Row>
                <FieldArray name="jobsForm.jobs" component={JobsColumn} integrationJob={integrationForm?.jobsForm} />
              </Container>
            </Col>
          </>
        )}
    </>
  );
};

export default IntegrationJobs;
