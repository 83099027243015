import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomerPicker from "../../../../components/vp/pickers/reduxPicker/customerPicker/customerPicker";

export type pickCustomerModalProps = {
  show: boolean;
  closeCallback: () => void;
  title: string;
  saveCallback: (customer: any) => void;
};

const PickCustomerModal = ({ show, closeCallback, title, saveCallback }: pickCustomerModalProps) => {
  let [customer, setCustomer] = useState();
  return (
    <Modal show={show} onHide={closeCallback}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CustomerPicker callback={setCustomer} hideLabel={true} />
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-secondary" onClick={closeCallback}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            saveCallback(customer);
          }}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PickCustomerModal;
