import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { GrAttachment } from "react-icons/gr";

class DraftInboxCellRenderer extends React.Component {
  render() {
    //console.log(this.props)
    //just return since no data, this should never happen if the getRows function is working correctly
    //   { moment(data.received_at).format("MMMM")}
    if (!this.props.data) {
      return <h1>empty</h1>;
    }

    let data = this.props.data;
    return (
      <Container fluid={true}>
        <Row>
          <Col xs={1} className={"p-0 align-self-center"}>
            {data.status === "Processed" ? <div className={"circle"}></div> : null}
            {data.status === "Failed" ? <h4 style={{ color: "#FF5C35" }}>!</h4> : null}
          </Col>
          <Col xs={11}>
            <Row>
              <Col xs={10} className={"pl-0"}>
                <h5 className={"email-message mb-0"}>{data.from.email}</h5>
              </Col>
              <Col xs={2}>
                {data.attachments_count ? (
                  <a>
                    <GrAttachment />
                  </a>
                ) : null}
              </Col>

              <Col xs={8} className={"pl-0"}>
                <h5 className={"email-message"}>{data.subject}</h5>
              </Col>
              <Col xs={4}>
                <p className={"float-right text-right"}>
                  {moment().diff(data.received_at, "hours") < 24
                    ? moment(data.received_at).format("LT")
                    : moment(data.received_at).format("ll")}
                </p>
              </Col>

              <Col xs={12} className={"pl-0"}>
                <p className={"email-message"}>{data.body_s}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(DraftInboxCellRenderer);
