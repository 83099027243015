import { useCallback, useEffect, useState } from "react";
import { MetadataTemplateApis } from "wombatifier/services/metadata/metadataTemplateApis";

export const Metadatable = ({ modules }: { modules: string | string[] }) => {
  const [isMetadataAvailable, setIsMetadataAvailable] = useState<boolean>(false);
  const getIsAvailable = useCallback(async () => {
    try {
      const res = await MetadataTemplateApis.list({ filter: { module: modules } });
      setIsMetadataAvailable(res.length > 0);
    } catch (err) {
      setIsMetadataAvailable(false);
    }
  }, []);

  useEffect(() => {
    getIsAvailable();
  }, []);

  const handleMetadataForm = useCallback((formValues: { [key: string]: any }) => {
    delete formValues.metadataForm;
    const metadataLinks = formValues.metadata_links_attributes || [];
    formValues.metadata_links_attributes = metadataLinks.filter(
      (link: any) => !!((link.value || link.metadata_field_id) && link.metadata_configuration_id),
    );
  }, []);

  return {
    isMetadataAvailable,
    handleMetadataForm,
  };
};
