import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { RootState, useTypedSelector } from "reducers";
import { InjectedFormProps, reduxForm } from "redux-form";
import { CardPaymentsSvc } from "services/admin/cards/cardPaymentsSvc";
import styles from "./cardPayMyAccount.module.css";
import { CardPayMyAccountReview } from "./cardPayMyAccountReview";
import { CardPayMyAccountSetup } from "./cardPayMyAccountSetup";
import { CardPayMyAccountSubmitted } from "./cardPayMyAccountSubmitted";

interface CardPayMyAccountPropsType {
  close: () => void;
}

const PayMyAccountForm = ({
  close,
  valid,
  handleSubmit,
}: InjectedFormProps<{}, CardPayMyAccountPropsType> & CardPayMyAccountPropsType) => {
  const currentStep = useTypedSelector((state: RootState) => CardPaymentsSvc.getCurrentStep(state));
  const RenderedStep = useMemo(() => {
    let currentComponent: JSX.Element = <></>;
    switch (currentStep) {
      case "setup":
        currentComponent = <CardPayMyAccountSetup valid={valid} close={close} />;
        break;
      case "review":
        currentComponent = <CardPayMyAccountReview handleSubmit={handleSubmit} close={close} />;
        break;
      case "submitted":
        currentComponent = <CardPayMyAccountSubmitted close={close} />;
        break;
    }
    return currentComponent;
  }, [currentStep, valid]);

  return (
    <Modal
      show={true}
      size="lg"
      centered
      className={styles.mainStyle}
      contentClassName={styles.modalRound}
      dialogClassName={styles.printableDialog}
    >
      {RenderedStep}
    </Modal>
  );
};

export const CardPayMyAccountForm = reduxForm<{}, CardPayMyAccountPropsType>({
  destroyOnUnmount: true,
  form: CardPaymentsSvc.FORM_NAME,
  touchOnChange: true,
  initialValues: {
    payMyAccount: {
      form: {},
      step: "setup",
    },
  },
})(PayMyAccountForm);
