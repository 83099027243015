import { restApiService } from "providers/restApi";
import React from "react";

type AngularRedirectPropsType = {
  to: string;
  children?: React.ReactNode;
};

/**
 * This component is used to render link
 *  for redirecting to angular url
 */

const AngularRedirect = ({ to, children }: AngularRedirectPropsType) => {
  return (
    <a className="link" href={restApiService.angularBaseURL() + to}>
      {children}
    </a>
  );
};

export default AngularRedirect;
