import React, { ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { Field } from "redux-form";
import { PaymentFieldType } from "../types";
import { RenderField } from "../../../forms/bootstrapFields";
import { CHANGE_PAYMENT_METHOD_ADDRESSLINE } from "../../../../actions/actionTypes";

type AddressLinePropsType = PaymentFieldType;

const Address1 = ({ validations, required, label }: AddressLinePropsType) => {
  const dispatch = useDispatch();

  const changeAddressLine: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({ type: CHANGE_PAYMENT_METHOD_ADDRESSLINE, payload: e.target.value });
  };

  return (
    <Field
      name="address1"
      label={label}
      component={RenderField}
      required={required}
      validate={validations}
      onChange={changeAddressLine}
    />
  );
};

export default Address1;
