import { AxiosResponse } from "axios";
import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import useIsMounted from "components/common/hooks/useIsMounted";
import { Mandatory } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Control, Controller, RegisterOptions, useFormContext } from "react-hook-form";
import Select from "react-select";
import commonService from "services/common/commonSvc";
import { IDType } from "services/common/types/common.type";
import styles from "../../../../forms/hookFormFields.module.css";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";

type TLocationPicker = {
  id?: string;
  name: string;
  location_id?: number;
  control?: Control;
  rules?: RegisterOptions;
  required?: boolean;
  callBack?: (selected: TSelected) => void;
  modelData?: string;
  label?: ReactNode;
  containerClassName?: string;
};

type TSelected = {
  value: IDType;
  label: string | number;
  id: string;
};

type TLocationPickerProps = {
  has_rebates?: boolean;
  id?: number | string;
  is_location_map_to_department?: boolean;
  is_location_map_to_project?: boolean;
  is_tax_map_to_location?: boolean;
  name?: string;
  label?: string;
  po_number?: string | null;
  status?: string;
  value?: number | string;
};

const LocationPicker = ({
  id,
  name,
  required,
  modelData: modelDataStr,
  containerClassName,
  label,
  ...otherProps
}: TLocationPicker) => {
  const [options, setOptions] = useState<TLocationPickerProps[]>([]);
  const isMounted = useIsMounted();
  const [inactivePopulated, setInactivePopulated] = useState(false);
  const { control, getValues, setValue } = useFormContext();
  //Temp solution until we migrate picker as per id
  const [modelData] = useState(modelDataStr ? modelDataStr + "." : "");
  const locationId = useGetValue(modelData + "location_id");

  const sortedOptions = useMemo(() => {
    if (!options || options.length === 0) return [];
    const sorted = _.sortBy(options, ["name"]);
    return required ? sorted : [{ name: "-- Select Location --", id: "" }, ...sorted];
  }, [options, required]);

  const initLocationObj = (obj: TLocationPickerProps | null) => {
    if (_.isPlainObject(obj)) {
      setValue(name, obj);
    }
  };

  const isInactive = (status: string) => {
    return status === "INACTIVE";
  };

  const getInactiveLocation = async () => {
    try {
      const response: AxiosResponse = await restApiService.get("locations/" + locationId);
      if (_.isPlainObject(response?.data)) {
        const inactiveObj = response?.data;
        if (inactiveObj) {
          let obj = {
            ...inactiveObj,
            value: inactiveObj.id,
            label: (
              <span key={inactiveObj.id}>
                {inactiveObj.name}{" "}
                {isInactive(inactiveObj.status) && (
                  <span key={inactiveObj.id} className="inactiveOption">
                    {" "}
                    (INACTIVE)
                  </span>
                )}
              </span>
            ),
          };
          setInactivePopulated(true);
          initLocationObj(obj);
        }
      } else {
        initLocationObj(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mergeInactive = async (options: TLocationPickerProps[]) => {
    // Filter options to find the one with the specified location_id
    const matchingOption = options?.find((option) => option.id === locationId);

    if (matchingOption) {
      // Initialize location object if a matching option is found
      initLocationObj(matchingOption);
    } else if (!inactivePopulated && locationId) {
      // If no match is found and the specified location is not already inactive, retrieve the inactive location
      await getInactiveLocation();
    }
  };

  const getLocations = useCallback(async () => {
    const params: Record<string, any> = { status: "ACTIVE" };

    try {
      const result = await restApiService.get("locations.lk", params, null, true, null, true);
      if (Array.isArray(result.data)) {
        const options = [...result.data];
        mergeInactive(options);
        if (isMounted.current) {
          setOptions(options);
        }
      }
    } catch {}
  }, [isMounted, locationId]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  return (
    <Form.Group className={`${containerClassName ?? ""}`}>
      {label && (
        <label className="pickerLabel">
          {label} <Mandatory required={required} />
        </label>
      )}

      <Controller
        control={control}
        name={name}
        rules={required ? { required: { value: true, message: "This field is required" } } : undefined}
        render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
          <>
            <Select
              {...field}
              isClearable={!required}
              value={value ?? null}
              getOptionLabel={(option) => option.name || ""}
              getOptionValue={(option) => option.id?.toLocaleString() || ""}
              className="pickerSelectedOption"
              options={sortedOptions}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={(selected) => {
                onChange(selected);
                setValue(modelData + "location_id", selected?.id || null);
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  ...commonService.getInvalidStyle(Boolean(error?.message)),
                }),
              }}
            />
            {error?.message && <PickerErrorBlock error={error.message} />}
          </>
        )}
      />
    </Form.Group>
  );
};

export default LocationPicker;
