import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import React, { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { WrappedFieldProps } from "redux-form";
import { onBlurSingle, compare } from "services/general/helpers";
import { Mandatory } from "wombatifier/components/pickers/mandatory";
import _ from "lodash";
import commonService from "services/common/commonSvc";
import { restApiService } from "providers/restApi";
import useIsMounted from "components/common/hooks/useIsMounted";
import { useDispatch } from "react-redux";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { IDType } from "services/common/types/common.type";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";

export interface ExpenseReportPickerPropsType extends WrappedFieldProps {
  modelData?: Record<string, any>;
  label?: string;
  tooltip?: string;
  required?: boolean;
  instanceId?: string;
  callBack?: (value: string) => void;
  callBackObj?: (value: any) => void;
  formName?: string;
  modelDataString?: string; // local of js object where gone save changes
  isClearable?: boolean;
}

export type TExpenseReportOptions = {
  id?: IDType;
  number?: string;
  status?: string;
  amount?: number;
  date?: string;
  vendor_name?: null;
  description?: string;
  balance_outstanding?: number;
  invoice_type?: string;
  currency_code?: string;
  name?: null;
  currency_symbol?: string;
  policy?: TPolicy;
  employee?: TEmployee;
}[];

export type TEmployee = {
  first_name?: string;
};

export type TPolicy = {
  name?: string;
};

// this component is specific to expense item form only
const ExpenseReportPicker = (props: ExpenseReportPickerPropsType) => {
  const {
    input,
    meta: { error, touched },
    instanceId,
    label,
    modelData,
    callBack,
    required,
    tooltip,
    callBackObj,
    formName,
    modelDataString,
    isClearable,
  } = props;
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const [expenseReports, setExpenseReports] = useState<any[]>([]);

  const findSelected = () => {
    let selectedValue = null; //set to null because pickers will have blank default value instead of "select... placeholder"
    const obj = _.find(expenseReports, (er: any) => er?.value === input.value);
    if (obj) selectedValue = obj;
    return selectedValue;
  };

  const handleCallBack = function ({ selected, isClear }: { selected?: any; isClear?: boolean }) {
    if (callBack) {
      if (!isClear) {
        callBack(selected.id);
      } else {
        callBack("");
      }
    }
  };

  const setValue = (selected: any) => {
    if (_.isPlainObject(selected)) {
      input.onChange(selected.id);
      handleCallBack({ selected });
    } else {
      input.onChange("");
      handleCallBack({ selected, isClear: true });
    }
  };

  const initExpenseReportObj = function (obj: Record<string, any>, isDefault: boolean) {
    if (isDefault) {
      setValue(obj);
    }
  };

  const parseForSelect = (options: any[]) => {
    return options
      .map((option) => {
        return {
          value: option.id,
          label: option.number,
          raw: option,
          ...option,
        };
      })
      .sort(compare);
  };

  const mergeInactive = async (parsedOptions: TExpenseReportOptions) => {
    // check if input.value is present in expenseReport options
    // if no then merge inactive or data missing due to cache
    if (input.value) {
      const expenseReport = parsedOptions.find((er) => er.id === input.value);
      if (!expenseReport) {
        try {
          const inactiveER = await ExpenseReportApis.getDetail(input.value);
          if (Array.isArray(parsedOptions)) {
            parsedOptions.push(inactiveER);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const getExpenseReport = async () => {
    try {
      const obj: Record<string, any> = {};
      obj.policy_id = modelData?.policy_id;
      obj.currency_code = modelData?.currency_code;
      obj.unsubmitted = true;
      obj.employee_id = modelData?.employee_id ? modelData.employee_id : currentUser?.contact?.id;
      obj.reimbursable = modelData?.reimbursable ? modelData?.reimbursable : false;
      if (currentUser?.company?.expense?.show_subsidiary && modelData?.subsidiary_id) {
        obj.subsidiary_id = modelData?.subsidiary_id;
      }

      const result = await restApiService.get("expense_reports.lk", obj, null, true, null, true);
      if (Array.isArray(result.data)) {
        const parsedOptions = parseForSelect(result.data);
        mergeInactive(parsedOptions);
        parsedOptions.unshift({ label: "New Expense Report", value: "new", id: "new", number: "New Expense Report" });
        if (modelData?.expense_report) {
          const er = {
            id: modelData.expense_report.id,
            number: modelData.expense_report.number,
            label: modelData.expense_report.number,
            value: modelData?.expense_report.id,
          };
          initExpenseReportObj(er, true);
          // to show selected in picker option should be present in erOptionslist
          parsedOptions.push(er);
        } else if (modelData?.link_to_expense_report) {
          const expenseReport = parsedOptions.find((er) => er.id === modelData.link_to_expense_report);
          initExpenseReportObj({ id: expenseReport.id, number: expenseReport.number }, true);
        } else if (currentUser.company?.expense_item?.default_new_expense_report) {
          initExpenseReportObj(
            { id: "new", number: "New Expense Report", value: "new", label: "New Expense Report" },
            true,
          );
        }
        if (isMounted.current) {
          setExpenseReports(parsedOptions);
        }
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  useEffect(() => {
    getExpenseReport();
  }, [
    modelData?.reimbursable,
    modelData?.employee_id,
    modelData?.subsidiary_id,
    modelData?.policy_id,
    modelData?.currency_code,
  ]);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label}
            <Mandatory required={required} />
            {tooltip}
          </Form.Label>
        )}
        <Select
          {...input}
          value={findSelected()}
          onChange={(selected) => setValue(selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          formatOptionLabel={(option, formatOptionLabelMeta) => {
            return (
              <>
                <small>
                  <div className="highlight-option">{option?.number}</div>
                  {option.id !== "new" && formatOptionLabelMeta.context === "menu" && (
                    <div>
                      <span className="highlight-option">Employee: </span>
                      <span>
                        {option?.employee?.first_name} {option?.employee?.last_name}
                      </span>
                      <br />
                      <span className="highlight-option">Policy: </span>
                      <span>{option?.policy?.name}</span>
                      <br />
                      <span className="highlight-option">Currency: </span>
                      <span>{option?.currency_code}</span>
                      <br />
                      <span className="highlight-option">Status: </span>
                      <span>{option?.status}</span>
                    </div>
                  )}
                </small>
              </>
            );
          }}
          instanceId={instanceId}
          options={expenseReports}
          isClearable={isClearable}
        />
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default ExpenseReportPicker;
