import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../../vendors.module.css";
import { CompanyType } from "services/common/user/userTypes";
import VendorDocumentLocalPreview from "components/common/vendorDocumentPreview/vendorDocumentLocalPreview";
import { DocumentRequirementsType } from "services/vp/purchaser/purchaserTypes";
import { IDType } from "services/common/types/common.type";

type PreviewableDocumentType = {
  asset_file?: File;
  file_name?: string;
  documentRequested: DocumentRequirementsType;
  id?: IDType;
  company_id?: IDType;
  created_at?: string;
  document_type?: string;
  documents?: Document[];
  isUpdated?: boolean;
  required?: boolean;
  signatureValue?: string;
  signReference?: string;
  updated_at?: string;
  file?: File;
  w8PageData?: string;
  w8BenPageData?: string;
  w9PageData?: string;
  diversityFormData?: Record<string, any>;
};

type DocumentsRequestedInfoType = {
  assetsAttributes: any;
  handleEdit: (step: number) => void;
  currentUserCompany?: CompanyType;
};

const DocumentsRequestedInfo = ({ assetsAttributes, handleEdit, currentUserCompany }: DocumentsRequestedInfoType) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);

  const [isDocumentPreviewModalOpen, setIsDocumentPreviewModalOpen] = useState(false);
  const [previewableDocument, setPreviewableDocument] = useState<PreviewableDocumentType>();

  const showPreview = (document: PreviewableDocumentType) => {
    setPreviewableDocument(document);
    setIsDocumentPreviewModalOpen(true);
  };

  const isNvpCustomer = currentUserCompany?.accepted_payment_methods?.corpay;
  return (
    <>
      {isDocumentPreviewModalOpen && previewableDocument && (
        <VendorDocumentLocalPreview
          previewableDocument={previewableDocument}
          onHide={() => setIsDocumentPreviewModalOpen(false)}
        />
      )}

      <Row>
        <Col md="12">
          <span>
            <i className={`icon icon-add-document ${styles.detailPageIconWidth}`}></i>
            <span className={styles.title}>{t("admin.pages.vendor.documentsRequested")}</span>
            <div className="float-right">
              <Button
                variant="secondary"
                className={`px-mr-10 ${styles.btnEdit}`}
                onClick={() => handleEdit(isNvpCustomer ? 5 : 6)}
              >
                <i className="icon icon-edit px-m-0"></i>
                {t("admin.pages.vendor.edit")}
              </Button>
              <span className={`float-right ${styles.iconToggle}`}>
                <i
                  className={toggle ? "icon icon-arrow-up" : "icon icon-arrow-down"}
                  onClick={() => setToggle(!toggle)}
                ></i>
              </span>
            </div>
          </span>
        </Col>
      </Row>
      {toggle && (
        <Row className="px-mt-25">
          <Col>
            {assetsAttributes &&
              assetsAttributes.length > 0 &&
              assetsAttributes.map((asset: any, index: number) => {
                return (
                  <div
                    key={asset.id ? asset.id : "asset" + index}
                    role="button"
                    className={styles.requestedDocumentsSectionBtn}
                    onClick={() => showPreview(asset)}
                  >
                    {asset.document_type ? asset.document_type : asset.file_name}
                  </div>
                );
              })}
          </Col>
        </Row>
      )}
    </>
  );
};

export default DocumentsRequestedInfo;
