import RequestPO from "components/admin/purchaseOrders/poRequest/addRequestPo";
import EditRequestPo from "components/admin/purchaseOrders/poRequest/editRequestPo";
import React from "react";
import { useParams } from "react-router";

const RequestPOForm = () => {
  const { id } = useParams<{ id: string }>();
  return id ? <EditRequestPo /> : <RequestPO />;
};

export default RequestPOForm;
