import React from "react";
import { CardsDisputesFormState } from "../../../../../services/admin/cards/disputes/cardDisputes.types";
import { getFormValues } from "redux-form";
import { Row, Table } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../../reducers";
import { Utility } from "services/admin/cards/cardDisputesSvc";
import Style from "./dispute.module.css";

export const DisputeSingleItemTable: React.FC = () => {
  const purchaseDetail = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue.purchase || ({} as { [key: string]: any });
  }, shallowEqual);

  const { t } = useTranslation();

  const t2 = (key: string) => {
    return t(`components.admin.disputes.common_table_headers.${key}`);
  };

  return (
    <>
      <div className={`${Style.single_item_row_container}`}>
        <Table bordered responsive size="lg">
          <thead>
            <tr className={`${Style.table_header}`}>
              <th>{t2("name")}</th>
              <th style={{ whiteSpace: "nowrap" }}>{t2("date")}</th>
              <th style={{ whiteSpace: "nowrap" }}>{t2("posted")}</th>
              <th>{t2("purchase_no")}</th>
              <th style={{ whiteSpace: "nowrap" }}>{t2("posted_amount")}</th>
              <th>{t2("approval")}</th>
              <th>{t2("mcc")}</th>
            </tr>
          </thead>
          <tbody>
            <tr className={`${Style.padded_cells}`}>
              <td>{purchaseDetail.merchant}</td>
              <td>{Utility.formatDate(purchaseDetail.transaction_date)}</td>
              <td>{Utility.formatDate(purchaseDetail.posted_date)}</td>
              <td>{purchaseDetail.number}</td>
              <td>{(purchaseDetail.amount || 0).toFixed(2)}</td>
              <td>{purchaseDetail.approval_code}</td>
              <td>{purchaseDetail.mcc_description}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};
