import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { onBlur, parseForSelect } from "services/general/helpers";
import { restApiService } from "providers/restApi";
import { CreateNotification, NotificationType } from "services/general/notifications";
import _ from "lodash";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "components/common/pickers/reduxFormPickers/select/helpers";
import { ContactPickerPropsType } from ".";

type ManagersType = {
  label: any;
  value: number;
};

type MultiContactPickerPropType = ContactPickerPropsType;

const MultiContactPicker = ({
  label,
  input,
  meta,
  tooltip,
  params,
  labelClassName,
  required,
  instanceId = "managers-selector",
  placeholder,
  disabled,
  isMulti,
  callBack,
  callBackObj,
  originalValue,
  needSelectedManagers,
}: MultiContactPickerPropType) => {
  const mounted = useRef(false);
  const [managers, setManagers] = useState<ManagersType[]>([]);
  const [selected, setSelected] = useState<ManagersType[]>([]);

  const getManagers = useCallback(async () => {
    try {
      const apiParams = { contact_type: "COMPANY", by_active: true, ...params };
      const result = await restApiService.get("contacts.lk", apiParams, null, true, null, true);
      const value = parseForSelect(result.data);
      if (mounted.current) setManagers(value);
      return value;
    } catch (error) {
      console.error(error);
      CreateNotification("Error", "Problem loading managers.", NotificationType.danger);
      return [];
    }
  }, [params]);

  const findSelected = async (input: any, options: ManagersType[]) => {
    if (_.isArray(input.value) && input.value.length > 0) {
      const selectedValues = await Promise.all(
        input.value.map(async (id: number) => {
          if (id) {
            const obj = _.find(options, (option) => option.value === id);
            if (obj) {
              return obj;
            } else {
              // Fetch inactive contact from the API and enable them to be displayed in the manager dropdown.
              try {
                const result = await restApiService.get("contacts/" + id, null, null, true, null, true);
                const inactiveContact = {
                  value: result.data.id,
                  label: (
                    <>
                      {result.data.name} - <small>({result.data.status})</small>
                    </>
                  ),
                };
                return inactiveContact;
              } catch (error) {
                console.error(error);
                CreateNotification("Error", "Problem loading inactive location.", NotificationType.danger);
              }
            }
          }
        }),
      );

      return selectedValues;
    }
    return [];
  };

  const fetchManagerData = async () => {
    try {
      const managersResult = await getManagers();
      const selectedResult = await findSelected(input, managersResult);
      setSelected(selectedResult);
    } catch (error) {
      console.error(error);
      CreateNotification("Error", "Problem loading data.", NotificationType.danger);
    }
  };

  useEffect(() => {
    fetchManagerData();
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [input]);

  const onChangeManager = (input: any, newValue: any) => {
    if (!_.isArray(input.value)) {
      input.value = [];
    }
    if (needSelectedManagers && callBackObj && newValue) {
      callBackObj(newValue);
    }

    if (input.value) {
      const removedItem = input.value.filter(
        (prevId: number) => !newValue.some((newItem: ManagersType) => newItem.value === prevId),
      );
      if (callBack) {
        callBack(removedItem[0]);
      }
      input.onChange(newValue.map((item: ManagersType) => item.value));
    }
  };

  const pickerProps = {
    ...input,
    required,
    placeholder,
    options: managers,
    isClearable: true,
    isDisabled: disabled,
    instanceId: instanceId,
  };

  return (
    <Form.Group>
      {label && (
        <Form.Label className={labelClassName}>
          {label ?? ""}
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...pickerProps}
        {...(originalValue !== undefined
          ? { components: { Input: (props) => <PickerComparisonField {...props} originalValue={originalValue} /> } }
          : {})}
        menuPlacement="auto"
        menuPosition="fixed"
        isClearable={!needSelectedManagers}
        value={selected}
        isMulti={true as any}
        onChange={(value) => onChangeManager(input, value)}
        onBlur={() => onBlur(input, input?.value)}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
          }),
        }}
        classNamePrefix="select"
      />
      {meta?.error && meta.touched && <div className="pickerError">{meta.error}</div>}
    </Form.Group>
  );
};

export default MultiContactPicker;
