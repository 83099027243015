import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { required } from "../../../forms/inputFields";
import { charOnly, minLength, number, numberOnly } from "../../../../services/validations/reduxFormValidation";
import { AccountNumberValidator } from "../accountNumberValidator";
import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import BankAddressLine from "../bankAddressLine";
import _ from "lodash";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";
const min1 = minLength(1);

const NzEftPaymentMethod = ({ modelName, modelData }: { modelName?: string; modelData?: any }) => {
  const { t } = useTranslation();
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);

  return (
    <>
      <Col md="4">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          required
          maxLength={20}
          validate={[required, charOnly]}
        />
      </Col>
      <Col md="4">
        <AccountNumberValidator
          modelName={`${modelName}`}
          maxLength={8}
          accountNumberType="australian-account-number"
          numOnly={true}
          label={t("common.paymentMethod.accountNumberLabel")}
        />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
        />
      </Col>
      <Col md="3">
        <Field
          name={`${modelName}.bankCode`}
          component={RenderField}
          type="text"
          required
          maxLength={2}
          validateNumberOnly={true}
          validate={[required, numberOnly]}
          label={t("common.paymentMethod.bankCode")}
        />
      </Col>
      <Col md="3">
        <Field
          name={`${modelName}.branchCode`}
          component={RenderField}
          type="text"
          required
          maxLength={4}
          validateNumberOnly={true}
          validate={[required, numberOnly]}
          label={t("common.paymentMethod.branchCode")}
        />
      </Col>
      <Col md="3">
        <Field
          name={`${modelName}.suffix`}
          component={RenderField}
          type="text"
          required
          validateNumberOnly={true}
          maxLength={4}
          validate={[required, min1, numberOnly]}
          label={t("common.paymentMethod.suffix")}
        />
      </Col>
      <Col sm="3">
        <Field
          name={`${modelName}.currency_code`}
          component={CurrencyCodePicker}
          FieldClassName="formField"
          placeholder={"Search/Select"}
          label={t("common.paymentMethod.currencyCode")}
          defaultValue="NZD"
          validate={[required]}
          required
          currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
        />
      </Col>
      <BankAddressLine modelName={`${modelName}`} defaultCountry="NZL" isCountryRequired={true} />
    </>
  );
};

export default NzEftPaymentMethod;
