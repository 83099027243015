import React, { useContext, useEffect, useState, createContext } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import Panel from "../../common/panel/panel";
import { IPaymentMethod, IPaymentMethodList } from "../../../services/common/types/paymentMethods.types";
import _ from "lodash";
import { useTypedSelector } from "../../../reducers";
import { IUser } from "../../../services/common/user/userTypes";
import { restApiService } from "../../../providers/restApi";
import { AxiosResponse } from "axios";
import CustomModal from "../../modals/customModal";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";
import PaymentMethods from "../../paymentMethods/paymentMethods";
import { useDispatch } from "react-redux";
import { SubmissionError, initialize } from "redux-form";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { useTranslation } from "react-i18next";
import { createCompleteError } from "../../../services/general/reduxFormSvc";
import useConfirmModal from "../../modals/confirmModal/useConfirmModalHook";
import { EDIT_PAYMENT_METHOD } from "../../../actions/actionTypes";
import {
  MANAGE_PAYMENT_METHOD_FORM,
  mapPaymentMethodPayloadForm,
  mapReduxFormToPaymentMethodPayload,
} from "../../../services/common/paymentMethod.svc";
import { INITIAL_STATE } from "../../../reducers/paymentMethodReducer";
import { ManagePaymentMethodFormDataType } from "../../common/managePaymentMethod/types";
import style from "./managePaymentMethod.module.css";
import paymentTypeService from "../../../services/common/paymentMethod/paymentTypeSvc";

type ProgramType = {
  currency_code: string;
  program_id: string;
};

type SectionType = {
  section?: "userProfile";
};

export type ManagePaymentMethodPropType = {
  methodableType: string;
  methodableId: number;
  allowEdit: boolean;
  allowAdd: boolean;
  allowDelete: boolean;
} & SectionType;

const getPrimaryPaymentmethod = ({
  paymentMethod,
  allPaymentMethods,
}: {
  paymentMethod: any;
  allPaymentMethods: IPaymentMethodList[];
}): IPaymentMethodList | undefined => {
  const otherPrimaryPaymentMethod = allPaymentMethods.find(
    (pm) => pm.is_primary === true && pm.id !== paymentMethod.id,
  );
  return otherPrimaryPaymentMethod;
};

// Context is good for sharing data which don't change | read only data like props
const ManagePaymentMethodContext = createContext<SectionType | null>(null);
export const useManagePaymentMethod = () => {
  const value = useContext(ManagePaymentMethodContext);
  return value;
};

const ManagePaymentMethod = ({
  methodableId,
  methodableType,
  allowEdit,
  allowAdd,
  allowDelete,
  section,
}: ManagePaymentMethodPropType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();
  const [allPaymentMethods, setAllPaymentMethods] = useState<IPaymentMethodList[]>([]);
  const currentUser = useTypedSelector<IUser>((state) => state.user);
  const [programList, setProgramList] = useState<ProgramType[]>([]);

  const [showPaymentMethodForm, setShowPaymentMethodForm] = useState<boolean>(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  // action dispatcher for editing payment Method
  const editPaymentMethodDispatcher = (paymentMethod: IPaymentMethod) => {
    // form normal redux form mapping performed in reducer so sending unmapped data
    paymentMethod.address_attributes = paymentMethod.address ? paymentMethod.address : paymentMethod.address_attributes;
    dispatch({ type: EDIT_PAYMENT_METHOD, payload: paymentMethod });

    //for redux form we need data to mapped
    const mappedPaymentMethod: ManagePaymentMethodFormDataType = mapPaymentMethodPayloadForm(paymentMethod);
    dispatch(initialize(MANAGE_PAYMENT_METHOD_FORM, mappedPaymentMethod));
  };

  const togglePaymentMethodForm = () => {
    setShowPaymentMethodForm((prev) => !prev);
  };

  const closePaymentMethodForm = () => {
    setShowPaymentMethodForm(false);
  };

  const addPaymentMethod = () => {
    dispatch(initialize(MANAGE_PAYMENT_METHOD_FORM, INITIAL_STATE));
    togglePaymentMethodForm();
  };

  const getPrograms = async () => {
    try {
      const response: AxiosResponse<ProgramType[]> = await restApiService.get(
        "companies/" + currentUser.company.id + "/get_programs",
      );
      setProgramList(response.data);
    } catch (error) {}
  };

  const getPaymentMethods = async () => {
    try {
      const response: AxiosResponse<IPaymentMethodList[]> = await restApiService.get(`payment_methods/methodable`, {
        payment_methodable_type: methodableType,
        payment_methodable_id: methodableId,
      });
      setAllPaymentMethods(response.data);
    } catch (error) {}
  };

  const updateOtherPaymentMethodPrimaryFlag = async (paymentMethod: IPaymentMethod) => {
    if (!paymentMethod.is_primary) {
      return;
    }
    try {
      // Note: getPrimaryPaymentmethod return array of all payment method where it have updated is_primary for related payment methods
      const updatablePaymentMethod = getPrimaryPaymentmethod({
        paymentMethod,
        allPaymentMethods,
      });
      if (updatablePaymentMethod) {
        await restApiService.patch(`payment_methods/${updatablePaymentMethod.id}`, null, {
          payment_method: {
            ...updatablePaymentMethod,
            is_primary: false,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePrimary = async (paymentMethod: IPaymentMethod, isPrimary: boolean) => {
    try {
      const response = await restApiService.patch(`payment_methods/${paymentMethod.id}`, null, {
        payment_method: { ...paymentMethod, is_primary: isPrimary },
      });
      await updateOtherPaymentMethodPrimaryFlag(response.data);
      await getPaymentMethods();
    } catch (error) {}
  };

  const setToPrimaryPaymentMethodCallBack = async (paymentMethod: IPaymentMethod) => {
    updatePrimary(paymentMethod, true);
  };

  const setToPrimaryPaymentMethod = (paymentMethod: IPaymentMethodList) => {
    createConfirmModal({
      title: t("components.admin.managePaymentMethod.primaryPaymentMethod"),
      body: t("components.admin.managePaymentMethod.setPrimaryPaymentMethodConfirm", { id: paymentMethod.id }),
      callBackData: paymentMethod,
      saveCallBack: setToPrimaryPaymentMethodCallBack,
      cancelCallBack: null,
    });
  };

  const setNotPrimaryPaymentMethodCallback = async (paymentMethod: IPaymentMethod) => {
    updatePrimary(paymentMethod, false);
  };

  const setToNotPrimaryPaymentMethod = async (paymentMethod: IPaymentMethodList) => {
    createConfirmModal({
      title: t("components.admin.managePaymentMethod.primaryPaymentMethod"),
      body: t("components.admin.managePaymentMethod.markNotPrimaryPaymentMethodConfirm", { id: paymentMethod.id }),
      callBackData: paymentMethod,
      saveCallBack: setNotPrimaryPaymentMethodCallback,
      cancelCallBack: null,
    });
  };

  const editPaymentMethod = async (id: number) => {
    try {
      const response: AxiosResponse<IPaymentMethod> = await restApiService.get(`payment_methods/${id}`);
      // set to form first
      editPaymentMethodDispatcher(response.data);
      // open form
      togglePaymentMethodForm();
    } catch (error) {}
  };

  const deleteConfirmCallBack = async (paymentMethod: IPaymentMethod) => {
    try {
      const response = await restApiService.delete(`payment_methods/${paymentMethod.id}`);
      if (response) {
        CreateNotification(
          t("success"),
          t("components.admin.managePaymentMethod.deleted", {
            id: paymentMethod.id,
          }),
          NotificationType.success,
        );
        await getPaymentMethods();
      }
    } catch (error) {}
  };

  const deletePaymentMethod = async (paymentMethod: IPaymentMethodList) => {
    createConfirmModal({
      title: t("components.admin.managePaymentMethod.delete"),
      body: t("components.admin.managePaymentMethod.deleteConfirm", {
        id: paymentMethod.id,
      }),
      callBackData: paymentMethod,
      saveCallBack: deleteConfirmCallBack,
      cancelCallBack: null,
    });
  };

  const getProgramByProgramId = (programId: string) => {
    return programList.find((program) => program.program_id === programId);
  };

  const canShowCreditCardColumn = () => {
    if (_.isArray(allPaymentMethods) && allPaymentMethods.length > 0) {
      return allPaymentMethods.some((method) => paymentTypeService.isCreditCard(method.payment_type));
    }
    return false;
  };

  const canShowCambridgeColumn = () => {
    if (_.isArray(allPaymentMethods) && allPaymentMethods.length > 0) {
      return allPaymentMethods.some((method) => paymentTypeService.isCambridge(method.payment_type));
    }
    return false;
  };

  useEffect(() => {
    getPrograms();
    getPaymentMethods();
  }, []);

  const onSubmitCallback = async (paymentMethodFormData: ManagePaymentMethodFormDataType) => {
    // \/ this payload is  mapped for company api
    // using same service for both vendor payment methods and manage payment methods
    const paymentMethodFormDataMaped = mapReduxFormToPaymentMethodPayload(paymentMethodFormData);

    const paymentMethodPayloadData = paymentMethodFormDataMaped.company.payment_methods_attributes[0];
    // so getting ^ mapped part required for payment_methods api
    // later we will remove company api for CRUD of payment method

    try {
      if (!isSubmitDisabled) {
        setSubmitDisabled(true);

        let response;

        // in add mode for form, when form get submitted,
        // once payment method created, will not call post api
        if (paymentMethodPayloadData && !paymentMethodPayloadData.id) {
          response = await restApiService.post("payment_methods", null, {
            payment_method: {
              ...paymentMethodPayloadData,
              payment_methodable_type: methodableType,
              payment_methodable_id: methodableId,
            },
          });
          CreateNotification(
            t("success"),
            t("components.admin.managePaymentMethod.created", { id: response.data.id }),
            NotificationType.success,
          );
        }

        // will prevent creation of multiple pm if once create and click multiple
        if (paymentMethodPayloadData && paymentMethodPayloadData.id) {
          response = await restApiService.patch(`payment_methods/${paymentMethodPayloadData.id}`, null, {
            payment_method: { ...paymentMethodPayloadData },
          });
          CreateNotification(
            t("success"),
            t("components.admin.managePaymentMethod.updated", { id: response.data.id }),
            NotificationType.success,
          );
        }

        if (response.data) {
          // remove other primary flag
          await updateOtherPaymentMethodPrimaryFlag(response.data);

          // refetch payments to get newly added payement payment
          await getPaymentMethods();
          closePaymentMethodForm();

          //close payment Method form

          // sync cambrige payment method with source
          if (paymentTypeService.isCambridge(response?.data?.payment_type)) {
            await syncWithSource(response.data.id);
            // refetch payments to so that cambridge Id populate in table
            await getPaymentMethods();
          }
        }

        setSubmitDisabled(false);
      }
    } catch (error: any) {
      setSubmitDisabled(false);
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          throw new SubmissionError(completeErrorObj);
        }
      }
    }
  };

  const syncWithSource = async (pmId: number) => {
    try {
      await restApiService.post("payment_methods/" + pmId + "/sync_with_source");
      CreateNotification(t("success"), t("components.admin.managePaymentMethod.syncSuccess"), NotificationType.success);
    } catch (error: any) {
      if (error?.response && error.response.data) {
        CreateNotification(t("error"), t("components.admin.managePaymentMethod.syncErr"), NotificationType.danger);
      }
    }
  };

  return (
    <ManagePaymentMethodContext.Provider value={{ section }}>
      {showPaymentMethodForm && (
        <CustomModal
          show={showPaymentMethodForm}
          onHide={togglePaymentMethodForm}
          header={<>Add {methodableType} Payment Method</>}
          body={
            <>
              <Row className="w-100">
                <Col xl="12">
                  <ErrorBoundary>
                    {/* TODO: temprary solution will change it later */}
                    {/* _form.tsx ManagePaymentReduxForm should be imported here and remove PaymentMethods */}
                    {/* <ManagePaymentMethod /> */}
                    <PaymentMethods
                      purchasers={[]}
                      onHide={togglePaymentMethodForm}
                      isManagePaymentMethod
                      onPaymentSubmitCallback={onSubmitCallback}
                    />
                  </ErrorBoundary>
                </Col>
              </Row>
            </>
          }
          size="xl"
        />
      )}
      <Panel
        header={
          <div className="d-flex justify-content-between">
            <div>
              <i className="icon icon-bank m-0"></i> PAYMENT METHODS
            </div>
            <div>
              <Button size="sm" onClick={addPaymentMethod}>
                <i className="icon icon-plus m-0 mt-1 mx-2" aria-hidden="true"></i> Add Method
              </Button>
            </div>
          </div>
        }
      >
        <Table striped responsive className={style.managePaymentMethodTable}>
          <thead>
            <tr>
              <th>Payment Method</th>
              <th>Status</th>
              <th>Bank Name</th>
              <th>Account Name</th>
              {canShowCreditCardColumn() && <th>Card Payment Recipients</th>}
              <th>Account No.</th>
              <th>Routing No.</th>
              {canShowCambridgeColumn() && <th>Cambridge Id</th>}
              <th>Account Type</th>
              <th>Program ID</th>
              <th>External ID</th>

              <th className={style.actionsColumn}></th>
            </tr>
          </thead>
          <tbody>
            {_.isArray(allPaymentMethods) &&
              _.orderBy(allPaymentMethods, ["id"], ["desc"]).map((paymentMethod) => {
                return (
                  <tr key={paymentMethod.id}>
                    <td>{paymentMethod.payment_type}</td>

                    <td>
                      {!paymentMethod.is_payoneer && <span>{paymentMethod.status}</span>}
                      {paymentMethod.is_payoneer && !paymentMethod.error_list && (
                        <span>
                          {paymentMethod.payoneer_status}
                          {paymentMethod.payoneer_status === "PENDING" && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                              data-toggle="tooltip"
                              title="Payoneer Account is under review and should be processed in the next 2-3 hours"
                              className="btn btn-xs"
                            >
                              <i className="icon icon-system-info m-0"></i>
                            </a>
                          )}
                          <br />
                          {!paymentMethod.is_payoneer_active && <small>({paymentMethod.payoneer_response_desc})</small>}
                        </span>
                      )}
                      {paymentMethod.error_list && paymentMethod.error_list.url && (
                        <span className="text-danger">
                          <a target="_blank" rel="noreferrer" href={paymentMethod.error_list.url}>
                            Information Required <br />
                            <small>Click to add additional information</small>
                          </a>
                        </span>
                      )}
                    </td>

                    <td>{paymentMethod.bank_name ? paymentMethod.bank_name : "--"}</td>

                    <td>{paymentMethod.account_name}</td>

                    {canShowCreditCardColumn() && (
                      <td>
                        {_.isArray(paymentMethod.subscriber_emails) &&
                          paymentMethod.subscriber_emails.map((subscriberEmail) => {
                            return <span key={subscriberEmail}>{subscriberEmail}</span>;
                          })}
                      </td>
                    )}

                    <td>{paymentMethod.account_number !== "****" && <>{paymentMethod.account_number}</>}</td>

                    <td>{paymentMethod.routing}</td>

                    {canShowCambridgeColumn() && <td>{paymentMethod?.cambridge?.beneficiaryId}</td>}

                    {paymentMethod.source === "Company" && (
                      <td>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          title={`
                          Account Holder Name   : ${paymentMethod.account_name}
                          Contact First Name    : ${paymentMethod.billing_first_name}
                          Contact Last Name     : ${paymentMethod.billing_last_name}
                          Billing Address       : ${paymentMethod.billing_address}
                          Bank Name             : ${paymentMethod.bank_name}
                          Routing #             : ${paymentMethod.routing}
                          Payment Type          : ${paymentMethod.payment_type}
                          Account Type          : ${paymentMethod.account_type}
                          Bank Address          : ${paymentMethod.bank_address}
                          Memo                  : ${paymentMethod.memo}
                          Currency Code         : ${paymentMethod.currency_code}
                        `}
                          data-toggle="tooltip"
                        >
                          <i className="icon icon-system-info m-0" />
                          (shared from vendor)
                        </a>
                      </td>
                    )}

                    <td>{paymentMethod.account_type}</td>

                    <td>
                      {paymentMethod.program_id}
                      {paymentMethod.program_id && (
                        <span>- {getProgramByProgramId(paymentMethod?.program_id)?.currency_code}</span>
                      )}
                    </td>

                    <td>{paymentMethod.account_external_id}</td>

                    {paymentMethod.source !== "Company" && (
                      <td>
                        <div className="d-flex">
                          {!paymentTypeService.isCreditCard(paymentMethod.payment_type) && (
                            <>
                              {!paymentMethod.is_primary && (
                                <Button
                                  size="sm"
                                  className={`bg-transparent border-0 m-0 ${paymentMethod.status !== "ACTIVE" && "cursor-not-allowed"}`}
                                  disabled={paymentMethod.status !== "ACTIVE"}
                                  onClick={() => setToPrimaryPaymentMethod(paymentMethod)}
                                >
                                  <i className="icon icon-star" data-toggle="tooltip" />
                                </Button>
                              )}
                              {paymentMethod.is_primary && (
                                <Button
                                  size="sm"
                                  className="bg-transparent border-0 m-0"
                                  onClick={() => setToNotPrimaryPaymentMethod(paymentMethod)}
                                >
                                  <i
                                    className="icon icon-star-active"
                                    data-toggle="tooltip"
                                    title="Default Payment method"
                                  />
                                </Button>
                              )}
                            </>
                          )}

                          {allowEdit && (
                            <Button
                              size="sm"
                              data-toggle="tooltip"
                              title="Edit"
                              className="bg-transparent border-0 m-0"
                              onClick={() => editPaymentMethod(paymentMethod.id)}
                            >
                              <i role="button" className="icon icon-edit m-0 mx-1" />
                            </Button>
                          )}

                          {allowDelete && (
                            <Button
                              size="sm"
                              data-toggle="tooltip"
                              className="bg-transparent border-0"
                              onClick={() => deletePaymentMethod(paymentMethod)}
                            >
                              <i className="icon icon-delete m-0 mx-1" />
                            </Button>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}

            {_.isArray(allPaymentMethods) && allPaymentMethods.length < 1 && (
              <tr>
                <td colSpan={12} className="text-center">
                  There are no payment methods
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Panel>
    </ManagePaymentMethodContext.Provider>
  );
};

export default ManagePaymentMethod;
