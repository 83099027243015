import { AxiosResponse } from "axios";
import { RecurringInvoiceListType, RecurringInvoiceDetailType } from "./recurringInvoiceTypes";
import { restApiService } from "../../../../providers/restApi";

class RecurringInvoiceApis {
  static async getRecurringInvoiceList() {
    try {
      const response: AxiosResponse<RecurringInvoiceListType[]> = await restApiService.get("recurrings");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getRecurringInvoiceDetail(id: string | number) {
    try {
      const response: AxiosResponse<RecurringInvoiceDetailType> = await restApiService.get("recurrings/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async editRecurringInvoiceDetail(id: number, data: any) {
    try {
      const response: AxiosResponse<RecurringInvoiceDetailType> = await restApiService.patch(
        "recurrings/" + id,
        null,
        data,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async deleteRecurringInvoice(id: number) {
    try {
      const response: AxiosResponse<RecurringInvoiceDetailType> = await restApiService.delete("recurrings/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default RecurringInvoiceApis;
