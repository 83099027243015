import React, { memo } from "react";

type PreviewComponentPropType = {
  filePath: string;
  fileName: string;
  previewSize?: PreviewSize;
};

export type PreviewSize = {
  height?: number | string;
  width?: number | string;
};

const PreviewComponent = ({ filePath, fileName, previewSize }: PreviewComponentPropType) => {
  // convert 70vh into pixels and use it
  // as 70vh is ideal height for preview
  const previewHeight = window.innerHeight * 0.7;
  previewSize = previewSize ?? { height: previewHeight, width: "100%" };
  const getFileExtension = (fileName: string) => {
    return fileName.slice(fileName.lastIndexOf(".") + 1);
  };

  const fileExtension = getFileExtension(fileName).toLocaleLowerCase();

  const getPreviewElement = (fileExtension: string) => {
    switch (fileExtension) {
      case "png":
      case "gif":
      case "bmp":
      case "jpg":
      case "jpeg":
        return <object data={filePath} type="image/png" aria-label="image" />;
      case "pdf":
        return (
          <object
            width={previewSize?.width}
            height={previewSize?.height}
            data={filePath}
            type="application/pdf"
            aria-label="pdf"
          />
        );
      case "xlsx":
      case "xls":
        return (
          <iframe
            width={previewSize?.width}
            height={previewSize?.height}
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`}
            title="Excel Preview"
          />
        );
      case "docx":
        return (
          <iframe
            width={previewSize?.width}
            height={previewSize?.height}
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`}
            title="Word Preview"
          />
        );
      default:
        return (
          <div>
            <div>Unsupported file type</div>
            <div>Please Download File</div>
          </div>
        );
    }
  };

  return (
    <div
      style={{ height: previewSize.height, width: previewSize.width, overflow: "hidden" }}
      className="d-flex justify-content-center"
    >
      {getPreviewElement(fileExtension)}
    </div>
  );
};

export default memo(PreviewComponent);
