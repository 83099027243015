import { AxiosResponse } from "axios";
import { IDType, getListOptionsType } from "../../../common/types/common.type";
import { ReceiptsTypes } from "./receiptsType";
import { restApiService } from "../../../../providers/restApi";

class ReceiptsApis {
  static async getReceiptList({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<{
        meta: ReceiptsTypes.ListMetaData;
        data: ReceiptsTypes.List;
      }> = await restApiService.get("inbound_receipts", filter, null, true, null, cache);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getReceipt(id: IDType) {
    try {
      const response: AxiosResponse<ReceiptsTypes.Details> = await restApiService.get("inbound_receipts/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteReceipt(id: IDType) {
    try {
      const response: AxiosResponse<ReceiptsTypes.Details> = await restApiService.delete("inbound_receipts/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async addReceipt(receiptPayload: ReceiptsTypes.AddPayload) {
    try {
      const response: AxiosResponse<ReceiptsTypes.Details> = await restApiService.post(
        "inbound_receipts",
        null,
        receiptPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async editReceipt(id: IDType, receiptPayload: ReceiptsTypes.EditPayload) {
    try {
      const response: AxiosResponse<ReceiptsTypes.Details> = await restApiService.patch(
        "inbound_receipts/" + id,
        null,
        receiptPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static unlinkReceipt = async (id: IDType, contact_id: IDType) => {
    let receiptPayload: ReceiptsTypes.EditPayload = {
      documentable_type: "Contact",
      documentable_id: contact_id,
      status: "PENDING_OCR",
    };
    return await ReceiptsApis.editReceipt(id, receiptPayload);
  };
}

export default ReceiptsApis;
