// TODO: remove these file when payment method is completely migrated in reduxFrom
import React, { ChangeEvent, useEffect } from "react";
import ManagePaymentReduxForm, { RemoteSubmitManagePaymentMethod } from "./_form";
import TransferwiseForm from "../../paymentMethods/transferwise/transferwiseForm";
import WireForm from "../../paymentMethods/wire/wireForm";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import EftCanadaFormFields from "../../paymentMethods/eftCanada/eftCanadaFormFields";
import EftNewZealandFormFields from "../../paymentMethods/eftNewZealand/eftNewZealandFormFields";
import CommonPaymentAddress from "../../paymentMethods/fieldComponents/addressComponents";
import { IntermediaryBank } from "../../paymentMethods/wire/wireCurrencyFields";
import EftAusPayNetFormFields from "../../paymentMethods/EftAustralianPayNet/eftAustralianPayNet";
import { useTranslation } from "react-i18next";
import PayoneerHeader from "./templates/payoneer/payoneerHeader";
import PayoneerFields from "./templates/payoneer/payoneerFields";
import CambridgeFields from "./templates/cambrige/cambridgeFields";
import { useDispatch } from "react-redux";
import { CHANGE_PAYMENT_STATUS } from "../../../actions/actionTypes";
import paymentTypeService from "../../../services/common/paymentMethod/paymentTypeSvc";

const PaymentMethodBootstrapForm = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showOrHideAddress = (type: string) => {
    if (
      type === "" ||
      paymentTypeService.isAch(type) ||
      paymentTypeService.isCheck(type) ||
      paymentTypeService.isCambridge(type) ||
      paymentTypeService.isCreditCard(type) ||
      paymentTypeService.isUkEft(type) ||
      paymentTypeService.isJanpanEft(type) ||
      paymentTypeService.isExteranl(type) ||
      paymentTypeService.isSepa(type) ||
      paymentTypeService.isSwiftWire(type) ||
      paymentTypeService.isFedWire(type)
    ) {
      return false;
    } else {
      return true;
    }
  };

  // remove primary if status is not active
  const changeIsPrimary = () => {
    if (props.paymentMethodRed?.status !== "ACTIVE") {
      props.changeIsPrimary(false);
    }
  };

  const changePaymentStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: CHANGE_PAYMENT_STATUS, payload: e.target.value });
  };

  const usingReduxForm = (paymentType: string) => {
    return (
      paymentTypeService.isAch(paymentType) ||
      paymentTypeService.isCheck(paymentType) ||
      paymentTypeService.isFedWire(paymentType) ||
      paymentTypeService.isUkEft(paymentType) ||
      paymentTypeService.isJanpanEft(paymentType) ||
      paymentTypeService.isSepa(paymentType) ||
      paymentTypeService.isExteranl(paymentType) ||
      paymentTypeService.isSwiftWire(paymentType) ||
      paymentTypeService.isCreditCard(paymentType)
    );
  };

  useEffect(() => {
    changeIsPrimary();
  }, [props.paymentMethodRed?.status]);

  return (
    <>
      <ManagePaymentReduxForm onSubmit={props.onPaymentSubmitCallback} />

      {paymentTypeService.isTransfterwise(props.payment_type) && (
        <TransferwiseForm {...props} defaultCurrency={props.defaultCurrency} />
      )}

      {paymentTypeService.isWire(props.payment_type) && <WireForm {...props} defaultCurrency={props.defaultCurrency} />}

      {paymentTypeService.isPayoneer(props.payment_type) &&
        !(props.paymentMethodRed.properties && props.paymentMethodRed.properties.branded) && (
          <Row>
            <Col>
              <PayoneerHeader {...props} />
              <PayoneerFields />
            </Col>
          </Row>
        )}

      {paymentTypeService.isCambridge(props.payment_type) && <CambridgeFields {...props} />}

      {paymentTypeService.isCanEft(props.payment_type) && <EftCanadaFormFields {...props} />}

      {paymentTypeService.isNzEft(props.payment_type) && <EftNewZealandFormFields {...props} />}

      {paymentTypeService.isAusPayNet(props.payment_type) && <EftAusPayNetFormFields {...props} />}

      {showOrHideAddress(props.payment_type) && <CommonPaymentAddress {...props} />}

      {paymentTypeService.isWire(props.payment_type) && <IntermediaryBank {...props} />}

      {!usingReduxForm(props.payment_type) && (
        <>
          <Row>
            <Form.Group as={Col} md="4">
              <Form.Label>Status:</Form.Label>
              <Form.Control as={"select"} value={props.paymentMethodRed.status} onChange={changePaymentStatus}>
                <option value=""></option>
                <option value="ACTIVE">ACTIVE</option>
                <option value="INACTIVE">INACTIVE</option>
              </Form.Control>
            </Form.Group>
          </Row>

          <Form.Group as={Col} md="12" controlId="defaultCheckbox" className={"text-right"}>
            <Form.Check
              type="checkbox"
              label=" Default for future invoice payment method"
              checked={props.paymentMethodRed.is_primary}
              onChange={(e) => {
                props.changeIsPrimary(e.target.checked);
              }}
              disabled={props.paymentMethodRed?.status !== "ACTIVE"}
            />
          </Form.Group>
        </>
      )}

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          {t("cancel")}
        </Button>

        {props.showSubmitButton() &&
          (usingReduxForm(props.payment_type) ? (
            // showing remote submit buttons for only those payment
            // method are completed implemented in redux-form
            // trigger submit for redux form
            <RemoteSubmitManagePaymentMethod />
          ) : (
            // trigger submit for bootstrap form
            <Button type="submit">{props.t("submit")}</Button>
          ))}
      </Modal.Footer>
    </>
  );
};

export default PaymentMethodBootstrapForm;
