import { translate } from "../../../services/general/translation";

const vendorContactsGridHeader = [
  {
    field: "selectAll",
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    maxWidth: 40,
    minWidth: 40,
    menuTabs: [],
  },
  {
    field: "id",
    headerName: "ID",
    hide: true,
    headerValueGetter: function () {
      return translate("id");
    },
  },
  {
    field: "external_id",
    headerName: "External ID",
    hide: true,
    headerValueGetter: function () {
      return translate("externalId");
    },
  },
  {
    field: "contact_id",
    headerName: "Contact ID",
    filter: "agTextColumnFilter",
    cellRendererParams: {
      viewLink: "/ar/contacts",
      authorization: {
        I: "read",
        a: "Contacts",
      },
    },
    cellRenderer: "randomChildCellRouting",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("contactId");
    },
  },
  {
    field: "first_name",
    headerName: "First Name",
    minWidth: 120,
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("first_name");
    },
  },
  {
    field: "last_name",
    headerName: "Last Name",
    minWidth: 120,
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("last_name");
    },
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 200,
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("email");
    },
  },
  {
    field: "status",
    headerName: "Status",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    floatingFilterComponent: "floatingFilterComponent",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    headerValueGetter: function () {
      return translate("status");
    },
  },
  {
    field: "roles_str",
    headerName: "Roles",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return "Roles";
    },
  },
  {
    field: "source",
    headerName: "Source",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    hide: true,
    headerValueGetter: function () {
      return translate("source");
    },
  },
  {
    field: "Actions",
    cellRenderer: "childMessageRenderer",
    sortable: false,
    resizable: false,
    width: 80,
    maxWidth: 200,
    cellRendererParams: {
      viewLink: "/ar/contacts/",
      viewAuthorization: { I: "read", a: "Contacts" },
      editLink: "/ar/contacts/",
      editAuthorization: { I: "edit", a: "Contacts" },
    },
    cellRendererSelector: function (params: { data: { id: any } }) {
      return params.data && !params.data.id;
    },
    headerValueGetter: function () {
      return translate("actions");
    },
  },
];
export default vendorContactsGridHeader;
