import { useTypedSelector } from "reducers";
import { formValueSelector } from "redux-form";

const useFormValue = <T,>(formName: string | undefined, selectionString?: string | undefined): T | undefined => {
  // this custom hook will help to select and individual value or object of values
  // by  just passing it's string formLocationString for ex , expense_item[0].amount <-- single value | expense_item[0].policy <-- object

  const selector = formName && formValueSelector(formName);
  const sectionValue: T | undefined = useTypedSelector(
    (state) => selectionString && selector && selector(state, selectionString),
  );
  return sectionValue;
};

export default useFormValue;
