export const appUrls = {
  contact: {
    url: "contacts/",
  },
  invoice: {
    url: "invoices/",
  },
  invoice_approval: {
    url: "invoices_approval/",
  },
  expenseitem: {
    url: "expenses/",
  },
  expensereport: {
    url: "expense_report/",
  },
  expensereport_approval: {
    url: "expenses_approval/",
  },
  payment: {
    url: "payments/",
  },
  payment_approval: {
    url: "payments/",
  },
  creditmemo: {
    url: "vendor_credits/",
  },
  creditmemo_approval: {
    url: "credit_memos_approval/",
  },
  paymentrun: {
    url: "payment_runs/",
  },
  paymentrun_approval: {
    url: "payment_runs_approval/",
  },
  purchaseorder: {
    url: "purchase_orders/",
  },
  purchaseorder_approval: {
    url: "po_approvals/",
  },
  vendor: {
    url: "vendors/",
  },
  vendor_approval: {
    url: "vendor_approvals/",
  },
  virtualcard: {
    url: "vcards/",
  },
  virtualcard_approval: {
    url: "vcard_approvals/",
  },
  purchase: {
    url: "purchase/",
  },
  purchase_approval: {
    url: "purchase/approvals/",
  },
};

export const appListPageUrls = {
  virtualcard: {
    url: "vcards",
  },
  payment: {
    url: "payments",
  },
};
