import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { IDType, getListOptionsType } from "../../common/types/common.type";
import {
  ContactPayloadType,
  VendoListType,
  VendorDetailType,
  VendorPortalStatsType,
  VendorSearchType,
  VendorTrackerListType,
} from "./vendorTypes";
export class VendorApis {
  static async getVendorList({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse = await restApiService.get("vendors/", filter, null, true, null, cache);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getVendor(id: string) {
    try {
      const response: AxiosResponse<VendorDetailType> = await restApiService.get("vendors/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async searchMasterCompaniesVendor(searchTerm: VendorSearchType) {
    try {
      const response: AxiosResponse<VendoListType[]> = await restApiService.get("master_companies", { q: searchTerm });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getNewVendor() {
    try {
      const response: AxiosResponse<VendoListType> = await restApiService.get("vendors/new");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async addVendor(vendorPayload: any) {
    try {
      const response: AxiosResponse<VendorDetailType> = await restApiService.post("vendors", null, vendorPayload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async addContact(contactPayload: ContactPayloadType) {
    try {
      const response: AxiosResponse<VendorDetailType> = await restApiService.post("contacts", null, contactPayload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async addVendorPaymentMethod(vendorPaymentMethod: any, vendorId: IDType) {
    try {
      const response: AxiosResponse<VendorDetailType> = await restApiService.patch(
        "vendors/" + vendorId,
        null,
        vendorPaymentMethod,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async uploadDocuments(documentPayload: any, vendorId: IDType) {
    try {
      const response: AxiosResponse<VendorDetailType> = await restApiService.patch(
        "vendors/" + vendorId,
        null,
        documentPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async editVendor(id: string, vendoryPayload: VendorDetailType) {
    try {
      const response: AxiosResponse<VendorDetailType> = await restApiService.patch(
        "vendors/" + id,
        null,
        vendoryPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteVendor(id?: string | number) {
    try {
      const response: AxiosResponse<VendorDetailType> = await restApiService.delete("vendors/" + id);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async resendCompleteProfileMessage(messagePayload: { messages: { id: number }[] }) {
    try {
      const response: AxiosResponse = await restApiService.post(
        "vendors/resend_complete_profile_message",
        null,
        messagePayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getVendorTrackerList({ filter, cache }: any = {}) {
    try {
      const response: AxiosResponse<{ data: VendorTrackerListType[]; meta: { count: number } }> =
        await restApiService.get("vendors/tracker", filter, null, true, null, false);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getVendorPortalStats() {
    try {
      const response: AxiosResponse<VendorPortalStatsType> = await restApiService.get("vendors/tracker_stats");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async refreshCache() {
    try {
      const response: AxiosResponse = await restApiService.get("vendors/refresh_cache");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
