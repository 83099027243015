import React from "react";
import { Form } from "react-bootstrap";
import { approval_workflow_triggers } from "../../../../../lookups/approvalWorkFlowLookups";
import { WrappedFieldProps } from "redux-form";

interface TriggerPickerPropsType extends WrappedFieldProps {
  className?: string;
  label?: string;
  multiple?: boolean;
}

const TriggerPicker = ({ input, className, label, meta: { touched, error }, multiple }: TriggerPickerPropsType) => {
  return (
    <Form.Group className="w-100">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        as="select"
        multiple={multiple}
        {...input}
        isInvalid={touched && !!error}
        isValid={touched && !error}
      >
        {approval_workflow_triggers.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default TriggerPicker;
