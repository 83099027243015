export const AchValidation = [
  {
    country_currency: "USA-USD",
    validations: [
      {
        field: "account_name",
        validate: {
          required: true,
          minlength: "0",
        },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "account_number",
        validate: {
          required: true,
          minlength: "0",
        },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },

      {
        field: "account_type",
        validate: {
          required: true,
          minlength: "1",
        },
        label: "Account Type",
        validMessage: "Success",
        errorMessage: "Sorry! Select any one Account Type  is required",
      },
      {
        field: "routing",
        validate: {
          required: true,
        },
        label: "Routing",
        validMessage: "Success",
        errorMessage: "Sorry! Select any one Account Type  is required",
      },
      {
        field: "bank_name",
        validate: {
          required: true,
          minlength: "1",
        },
        label: "Bank Name",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Name  is required",
      },
      {
        field: "delivery_method",
        validate: {
          required: true,
        },
        label: "Delivery Method",
        validMessage: "Success",
        errorMessage: "Sorry! Delivery Method is required",
      },
    ],
  },
];
