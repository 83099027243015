import _ from "lodash";
import { vendorAcceptedPaymentMethodsKeyLabel } from "../../../components/app.svc.Lookup";
import { IPaymentMethod } from "../../common/types/paymentMethods.types";
import { purchaserHasAnyAcceptedPaymentMethods } from "../../paymentMethods/paymentMethodLinking";
import { acceptedPaymentMethodsType, IPurchaser } from "./../types/purchasers.types";

export const returnAcceptedPaymentMethodLabel = (paymentType: string) => {
  return (
    vendorAcceptedPaymentMethodsKeyLabel.find(
      (pt: { key: string; value: string; label: string }) => pt.key === paymentType,
    )?.label ?? ""
  );
};

export const returnPaymentMethodLabelFromValue = (paymentType: string) => {
  return (
    vendorAcceptedPaymentMethodsKeyLabel.find(
      (pt: { key: string; value: string; label: string }) => pt.value === paymentType,
    )?.label ?? ""
  );
};

export const returnAcceptedPaymentMethodType = (paymentMethodKV: [key: string, value: boolean]) => {
  // returning original value for keys which are stored in paymentMethods
  const [key] = paymentMethodKV;
  return vendorAcceptedPaymentMethodsKeyLabel.find((pt) => pt.key === key)?.value ?? "";
};

export const getAcceptedPaymentMethodTypes = (acceptedPaymentMethods: acceptedPaymentMethodsType) => {
  const acceptedPaymentMethodsValues = Object.entries(acceptedPaymentMethods)
    .filter((paymentMethodKV) => paymentMethodKV[1])
    .map((paymentMethodKV) => {
      return returnAcceptedPaymentMethodType(paymentMethodKV);
    })
    .filter((value) => value);

  return acceptedPaymentMethodsValues;
};

//return array of value of paymentTypes
export const getPaymentTypes = (paymentMethods: IPaymentMethod[]): string[] => {
  if (_.isArray(paymentMethods)) {
    const paymentTypes = paymentMethods.map((pm) => pm.payment_type);
    return paymentTypes;
  }
  return [];
};

// SUDO CODE
// loop on purchasers
//  first check whether purchaser is satisfied for payment method
//      if yes then don't do anything
//      else
//      make new array of unsatisfied purchasers
//      now find all accepted_payment_methods
//      and show it to the vendor

// get common payment types if present b/w accepted_payment_methods and existingPaymentMethods
export const getCommonPaymentTypesPresent = (
  acceptedPaymentMethods: acceptedPaymentMethodsType,
  existingPaymentMethods: IPaymentMethod[],
) => {
  const acceptedPaymentTypes = getAcceptedPaymentMethodTypes(acceptedPaymentMethods);
  const existingPaymentTypes = (_.isArray(existingPaymentMethods) && getPaymentTypes(existingPaymentMethods)) || [];
  const commonPaymentTypes = _.intersection(existingPaymentTypes, acceptedPaymentTypes);
  return commonPaymentTypes;
};

// takes single puchaser and check whether it is satisfied
export const isPurchasersSatisfied = (purchaser: IPurchaser, existingPaymentMethods: IPaymentMethod[]) => {
  // if purchasers do not accept any payment method, then already satisfied
  if (!purchaserHasAnyAcceptedPaymentMethods(purchaser)) {
    return true;
  } else {
    const commonPaymentTypes = getCommonPaymentTypesPresent(purchaser.accepted_payment_methods, existingPaymentMethods);
    // purchaser is satisfied
    if (_.isArray(commonPaymentTypes) && commonPaymentTypes.length) {
      return true;
    }
    // purchaser is not satisfied
    return false;
  }
};

// takes array of purchasers check whether all of them are satisfied
export const isAllPurchasersSatisfied = (purchasers: IPurchaser[], existingPaymentMethods: IPaymentMethod[]) => {
  if (_.isArray(purchasers) && purchasers.length && existingPaymentMethods) {
    return purchasers.every((purchaser) => isPurchasersSatisfied(purchaser, existingPaymentMethods));
  }
  return true;
};

// getting paymentMethods which are at least to be added for satisfying all pruchaser payment method requiredment
export const getRequiredPaymentMethod = (purchasers: IPurchaser[], paymentMethods: IPaymentMethod[]) => {
  const puchasersRequiringPaymentMethods = purchasers.filter(
    (purchaser) => !isPurchasersSatisfied(purchaser, paymentMethods),
  );
  let listOfAcceptedPaymentMethods: string[] = [];
  if (_.isArray(puchasersRequiringPaymentMethods) && puchasersRequiringPaymentMethods.length) {
    listOfAcceptedPaymentMethods = puchasersRequiringPaymentMethods.flatMap((purchaser) => {
      return getAcceptedPaymentMethodTypes(purchaser.accepted_payment_methods);
    });
  }

  // remove dulplicates
  listOfAcceptedPaymentMethods = _.uniq(listOfAcceptedPaymentMethods);

  return listOfAcceptedPaymentMethods;
};
