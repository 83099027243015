import _, { isArray, isPlainObject, isUndefined } from "lodash";
import CurrencyFormat from "react-currency-format";
import ReactDOMServer from "react-dom/server";
import { currencySymbolRenderer } from "../common/currencySymbolRendererService";
// redux form pickers helpers

export const isValidDate = (dateString) => {
  return !isNaN(Date.parse(dateString));
};

export const compare = (a, b) => {
  if (_.isString(a.label) && _.isString(b.label)) {
    return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : b.label.toLowerCase() > a.label.toLowerCase() ? -1 : 0;
  } else {
    return 0;
  }
};

//  rename to findselected only
export const findSelectedMultiple = (input, options) => {
  let selectedValues = [];
  if (typeof (input.value === String)) {
    options.forEach((option) => {
      if (input.value === option.value) {
        selectedValues.push(option);
      }
    });
  }

  if (isPlainObject(input.value)) {
    selectedValues = [input.value];
  }

  if (isArray(input.value)) {
    input.value.forEach((element) => {
      let obj;
      if (isPlainObject(element)) {
        obj = _.find(options, (option) => option.value === element.value);
      } else {
        obj = _.find(options, (option) => option.value === element);
      }
      if (obj) selectedValues.push(obj);
    });
  }

  return selectedValues;
};

export const getValuesFromOptions = (options) => {
  const values = options.map((option, index) => {
    return option.value;
  });
  return values;
};

export const onBlur = (input, options) => {
  if (!options || !options.length) {
    input.onBlur([]);
    return;
  }
  input.onBlur(options);
};

export const onChange = (input, options) => {
  if (!options) {
    input.onChange([]);
    return;
  }
  const values = getValuesFromOptions(options);
  input.onChange(values);
};

export const parseForSelect = (options) => {
  return options
    .map((option) => {
      return {
        value: option.id,
        label: option.name,
        raw: option,
        ...option,
      };
    })
    .sort(compare);
};

export const parseForSelectCustom = (options, labelField = "name") => {
  return options
    .map((option) => {
      return {
        value: option.id,
        label: option[labelField],
      };
    })
    .sort(compare);
};

export const capitalizeEachWord = (str, inDelimiter = "_", outDelimiter = " ") => {
  return str
    .split(inDelimiter)
    .map((word) => [word.charAt(0).toUpperCase(), word.slice(1).toLowerCase()].join(""))
    .join(outDelimiter);
};

export const parseForLabelSelect = (options) => {
  return options
    .map((option) => {
      return {
        value: option.name,
        label: option.label,
      };
    })
    .sort(compare);
};

export const findSelectedSingle = (input, options) => {
  let selectedValues = null; //set to null because pickers will have blank default value instead of "select... placeholder"
  const obj = _.find(options, (option) => option.value === input.value);
  if (obj) selectedValues = obj;
  return selectedValues ? selectedValues : null;
};

export const onBlurSingle = (input, option) => {
  if (!option) {
    input.onBlur("");
    return;
  }
  input.onBlur(option);
};

export const onChangeSingle = (input, option) => {
  if (!option) {
    input.onChange("");
    return;
  }
  input.onChange(option.value);
};

/**
 * Formats the amount value as a string returns as string instead of jsx.
 * @returns {string} A react element containing the formatted amount.
 */
export const getStringFormattedAmount = (value = "", currencyCode = "", decimal = 2, fixedDecimal = false) => {
  return ReactDOMServer.renderToStaticMarkup(formattedAmount(value, currencyCode, decimal, fixedDecimal))?.replace(
    /<\/?span[^>]*>/g,
    "",
  );
};

/**
 * Formats the amount value as a string with two decimal places.
 * @param {value: number|string, currencyCode: string} obj - The value to format, which can be a number, a string representation of a number, or null.
 * @returns {ReactElement} A react element containing the formatted amount.
 */
export const twoDecimalFormattedAmount = ({ value = "", currencyCode = "" }) => {
  return formattedAmount(value, currencyCode, 2, true);
};

//this function return a formatted amount text
export const formattedAmount = (value = "", currencyCode = "", decimal = 2, fixedDecimal = false) => {
  //value : number(56666.67),  displayType : 'text'
  //decimal is digit at decimal place default is 2,

  //this is to not have value null,above initial value as '' allows value to be null
  if (!value) {
    value = "0";
  }

  let symbolSign = currencyCode ? currencySymbolRenderer(currencyCode) : "";
  return (
    <CurrencyFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      prefix={symbolSign}
      fixedDecimalScale={fixedDecimal}
      decimalScale={decimal}
    />
  );
};

export const isDefined = (value) => {
  return !isUndefined(value);
};

export const isJSONParsableArray = (value) => {
  try {
    return isArray(JSON.parse(value));
  } catch (err) {
    return false;
  }
};

export const getMatchedNumber = (value) => {
  return Number((`${value}`.match(/\d+(\.\d+)?/g) || [""])[0]);
};

export const getNeutralDate = (dateObject) => {
  return new Date(dateObject.getTime() + dateObject.getTimezoneOffset() * 60000); // 60000 is to convert to milliseconds
};

export const getDateModified = (dateObject, days) => {
  const dayOffset = 24 * 60 * 60 * 1000; // in milliseconds
  return new Date(dateObject.getTime() + dayOffset * days);
};

export const extractFileFromURI = async (url) => {
  const binary = await fetch(url);
  const blob = await binary.blob();
  return new File([blob], url.name);
};

// this function normalizes amount strings ($1002.45) into floats for proper grid sort comparison
export const normalizeAmountValue = (value) => {
  // remove currency symbols and whitespace
  value = value.replace(/[^0-9,.-]/g, "").trim();

  // detect if the value is in European format (comma as decimal separator)
  const isEuropeanFormat = value.includes(",") && value.lastIndexOf(",") > value.lastIndexOf(".");

  if (isEuropeanFormat) {
    // replace periods with empty string (thousand separators)
    value = value.replace(/\./g, "");
    // replace comma with period (decimal separator)
    value = value.replace(/,/g, ".");
  } else {
    // replace commas with empty string (thousand separators)
    value = value.replace(/,/g, "");
  }

  // convert to float and return
  return parseFloat(value);
};
