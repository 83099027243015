import React from "react";
import { GrAttachment } from "react-icons/gr";
import styles from "./email.module.css";

export const getLinkImage = (image: any, showImage: any) => {
  let data = null;
  if (!showImage) {
    data = (
      <div key={image.key}>
        <a href={image.asset_expiring_url} download target="_blank" rel="noreferrer">
          <h5
            className={
              "pl-2 " + image.status === "success"
                ? styles.otherColor
                : image.status === "failed"
                  ? styles.failedColor
                  : styles.otherColor
            }
          >
            <GrAttachment className={"pr-1"} />
            {image.filename}
          </h5>
        </a>
      </div>
    );
  } else {
    if (image.content_type === "application/pdf") {
      data = (
        <div className={"col-4 text-center"} key={image.key}>
          <iframe className={"w-100"} src={image.asset_expiring_url} key={image.key} />
          <a
            className={"d-block mx-2 ellipsisText"}
            href={image.asset_expiring_url}
            download
            target="_blank"
            style={{ color: "#FF5C35" }} rel="noreferrer"
          >
            {image.filename}
          </a>
        </div>
      );
    } else {
      data = (
        <div className={"col-3"} key={image.key}>
          <div className={styles.AttachmentName + " text-center"} title={image.filename}>
            {image.filename && image.filename.substring(0, 15)}
          </div>
          <img
            className={"w-100 imageAttachment " + styles.thumbnail}
            src={image.asset_expiring_url}
            alt={image.filename}
          />
        </div>
      );
    }
  }
  return data;
};
