import i18next from "i18next";

export const getAccountsData = (modelData) => {
  let account_link_objects = [];
  if (modelData) {
    if (modelData.accrual_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.accrualDebitAccounts"),
        account_group: "accrual_debit_accounts_attributes",
        account_group_name: "ACCRUAL_DEBIT_GROUPS",
        account_links: modelData.accrual_debit_accounts,
      });
    }
    if (modelData.accrual_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.accrualCreditAccounts"),
        account_group: "accrual_credit_accounts_attributes",
        account_group_name: "ACCRUAL_CREDIT_GROUPS",
        account_links: modelData.accrual_credit_accounts,
      });
    }
    if (modelData.invoice_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.invoiceDebitAccounts"),
        account_group: "invoice_debit_accounts_attributes",
        account_group_name: "INVOICE_DEBIT_GROUPS",
        account_links: modelData.invoice_debit_accounts,
      });
    }
    if (modelData.invoice_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.invoiceCreditAccounts"),
        account_group: "invoice_credit_accounts_attributes",
        account_group_name: "INVOICE_CREDIT_GROUPS",
        account_links: modelData.invoice_credit_accounts,
      });
    }
    if (modelData.payment_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.paymentDebitAccounts"),
        account_group: "payment_debit_accounts_attributes",
        account_links: modelData.payment_debit_accounts,
      });
    }
    if (modelData.payment_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.paymentCreditAccounts"),
        account_group: "payment_credit_accounts_attributes",
        account_links: modelData.payment_credit_accounts,
      });
    }

    if (modelData.discount_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.discountDebitAccounts"),
        account_group: "discount_debit_accounts_attributes",
        account_links: modelData.discount_debit_accounts,
      });
    }
    if (modelData.discount_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.discountCreditAccounts"),
        account_group: "discount_credit_accounts_attributes",
        account_links: modelData.discount_credit_accounts,
      });
    }

    if (modelData.purchase_order_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.purchaseOrderDebitAccounts"),
        account_group: "purchase_order_debit_accounts_attributes",
        account_links: modelData.purchase_order_debit_accounts,
      });
    }
    if (modelData.purchase_order_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.purchaseOrderCreditAccounts"),
        account_group: "purchase_order_credit_accounts_attributes",
        account_links: modelData.purchase_order_credit_accounts,
      });
    }
    if (modelData.virtual_card_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.virtualCardDebitAccounts"),
        account_group: "virtual_card_debit_accounts_attributes",
        account_links: modelData.virtual_card_debit_accounts,
      });
    }
    if (modelData.virtual_card_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.virtualCardCreditAccounts"),
        account_group: "virtual_card_credit_accounts_attributes",
        account_links: modelData.virtual_card_credit_accounts,
      });
    }
    if (modelData.payment_postpaid_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.virtualCardPaymentPostpaidCreditAccounts"),
        account_group: "payment_postpaid_credit_accounts_attributes",
        account_links: modelData.payment_postpaid_credit_accounts,
      });
    }
    if (modelData.payment_postpaid_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.virtualCardPaymentPostpaidDebitAccounts"),
        account_group: "payment_postpaid_debit_accounts_attributes",
        account_links: modelData.payment_postpaid_debit_accounts,
      });
    }
    if (modelData.payment_prepaid_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.virtualCardPaymentPrepaidDebitAccounts"),
        account_group: "payment_prepaid_debit_accounts_attributes",
        account_links: modelData.payment_prepaid_debit_accounts,
      });
    }
    if (modelData.payment_prepaid_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.virtualCardPaymentPrepaidCreditAccounts"),
        account_group: "payment_prepaid_credit_accounts_attributes",
        account_links: modelData.payment_prepaid_credit_accounts,
      });
    }
    if (modelData.expense_report_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.expenseReportDebitAccounts"),
        account_group: "expense_report_debit_accounts_attributes",
        account_links: modelData.expense_report_debit_accounts,
      });
    }
    if (modelData.expense_report_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.expenseReportCreditAccounts"),
        account_group: "expense_report_credit_accounts_attributes",
        account_links: modelData.expense_report_credit_accounts,
      });
    }
    if (modelData.expense_report_nonreimbursable_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.nonReimbursableExpenseReportDebitAccounts"),
        account_group: "expense_report_nonreimbursable_debit_accounts_attributes",
        account_links: modelData.expense_report_nonreimbursable_debit_accounts,
      });
    }
    if (modelData.expense_report_nonreimbursable_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.nonReimbursableExpenseReportCreditAccounts"),
        account_group: "expense_report_nonreimbursable_credit_accounts_attributes",
        account_links: modelData.expense_report_nonreimbursable_credit_accounts,
      });
    }
    if (modelData.product_item_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.productItemDebitAccounts"),
        account_group: "product_item_debit_accounts_attributes",
        account_links: modelData.product_item_debit_accounts,
      });
    }
    if (modelData.vendor_credit_debit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.vendorCreditDebitAccounts"),
        account_group: "vendor_credit_debit_accounts_attributes",
        account_links: modelData.vendor_credit_debit_accounts,
      });
    }
    if (modelData.vendor_credit_credit_accounts) {
      account_link_objects.push({
        label: i18next.t("components.admin.manageDefaultAccount.vendorCreditCreditAccounts"),
        account_group: "vendor_credit_credit_accounts_attributes",
        account_links: modelData.vendor_credit_credit_accounts,
      });
    }
    return account_link_objects;
  }
};

export const getDepartmentparams = (accountObj, currentUser) => {
  let params = {};
  if (currentUser && currentUser.company && currentUser.company.has_account_structures) {
    params = { account_structure: true };
    if (accountObj) {
      params.account_id = accountObj.account_id;
    }
  }
  return params;
};

export const getInterCompanyParams = (accountObj, current_user) => {
  let params = {};
  if (accountObj) {
    params.account = accountObj.account_id;
  }
  return params;
};
export const getTotalDropDown = (currentUser) => {
  var total = 0;
  if (currentUser) {
    total += currentUser.company.has_locations ? 1 : 0;
    total += currentUser.company.has_departments ? 1 : 0;
    total += currentUser.company.has_business_units ? 1 : 0;
    total += currentUser.company.has_inter_companies ? 1 : 0;
  }
  return total;
};
