import PaymentTermPicker from "components/admin/pickers/reactHookFormPickers/paymentTerms";
import { PaymentPickerType } from "components/admin/pickers/reactHookFormPickers/paymentTerms/paymentTermsTypes";
import React from "react";
import { Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";

const UploadQueuePaymentTerm = () => {
  const { setValue } = useFormContext<InvoiceType.InvoiceDetailType>();
  const invoiceCommonHookSvc = useInvoiceCommonSvc();

  const onPaymentTermChange = (term: PaymentPickerType.TPaymentTermsProps | null) => {
    if (term) {
      setValue("term", term as InvoiceType.TermType);
      setValue("term_id", term.id as number);
      invoiceCommonHookSvc.updateDueDate();
    }
  };

  const onPaymentTermChangeFull = (
    term: PaymentPickerType.TPaymentTermsProps | PaymentPickerType.TPaymentTermsProps[] | null,
  ) => {
    if (term && !Array.isArray(term)) {
      onPaymentTermChange(term);
    }
  };

  // TODO: need add logic to update due date and discount date and discount amount
  // if term is update from outside

  return (
    <>
      <Col md="6" className="mb-3">
        <PaymentTermPicker
          name="term_id"
          objectName="term"
          callBackFullObject={onPaymentTermChangeFull}
          label={"admin.pages.invoice.paymentTerms"}
          required
        />
      </Col>
    </>
  );
};

export default UploadQueuePaymentTerm;
