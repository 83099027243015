import React, { useEffect, useState } from "react";
import { Col, Form, FormControl, Row } from "react-bootstrap";

type UseDiversityTrackigFormPropsType = {
  initialState?: Record<string, any>;
  isDisabled?: boolean;
};

const useDiversityTrackigForm = ({ initialState, isDisabled }: UseDiversityTrackigFormPropsType = {}) => {
  const [mwbe, setMwbe] = useState<string>("");
  const [DALLAS_COUNTY, setDALLAS_COUNTY] = useState(false);
  const [MWBE_CERTIFYING_AGENCY, setMWBE_CERTIFYING_AGENCY] = useState("");
  const [MWBE_CERTIFICATION_NUMBER, setMWBE_CERTIFICATION_NUMBER] = useState("");
  const [EXPIRATION_DATE, setEXPIRATION_DATE] = useState("");
  const [NAICS, setNAICS] = useState("");

  const getDiversityTrackingFormData = () => {
    return { mwbe, DALLAS_COUNTY, MWBE_CERTIFYING_AGENCY, MWBE_CERTIFICATION_NUMBER, EXPIRATION_DATE, NAICS };
  };

  const initializeDiversityTrackingForm = () => {
    setMwbe(initialState?.mwbe ?? "");
    setDALLAS_COUNTY(initialState?.DALLAS_COUNTY ?? false);
    setMWBE_CERTIFYING_AGENCY(initialState?.MWBE_CERTIFYING_AGENCY ?? "");
    setMWBE_CERTIFICATION_NUMBER(initialState?.MWBE_CERTIFICATION_NUMBER ?? "");
    setEXPIRATION_DATE(initialState?.EXPIRATION_DATE ?? "");
    setNAICS(initialState?.NAICS ?? "");
  };

  useEffect(() => {
    initializeDiversityTrackingForm();
  }, [initialState]);

  const diversityTrackingForm = (
    <>
      <Row>
        <Col className="d-flex justify-content-center mb-3">
          <h3>Diversity Tracking Form</h3>
        </Col>
      </Row>
      <Row>
        <Col md="6" sm="6">
          <Form.Group>
            <Form.Label className="d-block" htmlFor="MWBE">
              MWBE
            </Form.Label>
            <FormControl
              as={"select"}
              onChange={(e) => setMwbe(e.target.value)}
              value={mwbe}
              name="MWBE"
              required
              isValid={Boolean(mwbe)}
              isInvalid={!Boolean(mwbe)}
              disabled={isDisabled}
            >
              <option value="">Choose</option>
              <option value="XNON=other than M/WBE">XNON=other than M/WBE</option>
              <option value="NFP=Nonprofit Organization">NFP=Nonprofit Organization</option>
              <option value="EE=Employee">EE=Employee</option>
              <option value="F=Foreign Vendor">F=Foreign Vendor</option>
              <option value="B=African American">B=African American</option>
              <option value="H=Hispanic">H=Hispanic</option>
              <option value="I=Asian Indian">I=Asian Indian</option>
              <option value="N=Native American">N=Native American</option>
              <option value="P=Asian Pacific">P=Asian Pacific</option>
              <option value="W=Women">W=Women</option>
            </FormControl>
          </Form.Group>
        </Col>

        <Col md="6" sm="6">
          <Form.Group>
            <Form.Label className="mr-2" htmlFor="DALLAS_COUNTY">
              Dallas County
            </Form.Label>
            <Form.Check
              name="DALLAS_COUNTY"
              id="DALLAS_COUNTY"
              checked={DALLAS_COUNTY}
              onChange={(e) => setDALLAS_COUNTY(e.target.checked)}
              type="checkbox"
              disabled={isDisabled}
            />
          </Form.Group>
        </Col>

        <Col md="6" sm="6">
          <Form.Group>
            <Form.Label className="d-block" htmlFor="MWBE_CERTIFYING_AGENCY">
              MWBE Certifying Agency
            </Form.Label>
            <FormControl
              name="MWBE_CERTIFYING_AGENCY"
              value={MWBE_CERTIFYING_AGENCY}
              as="select"
              onChange={(e) => setMWBE_CERTIFYING_AGENCY(e.target.value)}
              disabled={isDisabled}
            >
              <option value="">Choose</option>
              <option value="Federal government">Federal government</option>
              <option value="Local government">Local government</option>
              <option value="State government">State government</option>
              <option value="DFW Minority Supplier Development Council (DFWMSDC)">
                DFW Minority Supplier Development Council (DFWMSDC)
              </option>
              <option value="National Gay & Lesbian Chamber of Commerce (NGLOCC)">
                National Gay & Lesbian Chamber of Commerce (NGLOCC)
              </option>
              <option value="National Minority Supplier Development Council (NMSDC)">
                National Minority Supplier Development Council (NMSDC)
              </option>
              <option value="North Central Texas Regional Certification Agency (NCTRCA)">
                North Central Texas Regional Certification Agency (NCTRCA)
              </option>
              <option value="Women's Business Enterprise National Council (WBENC)">
                Women's Business Enterprise National Council (WBENC)
              </option>
              <option value="Other">Other</option>
            </FormControl>
          </Form.Group>
        </Col>

        <Col md="6" sm="6">
          <Form.Group>
            <Form.Label className="d-block" htmlFor="MWBE_CERTIFICATION_NUMBER" style={{ textTransform: "capitalize" }}>
              MWBE Certification Number
            </Form.Label>
            <FormControl
              as={"input"}
              name="MWBE_CERTIFICATION_NUMBER"
              value={MWBE_CERTIFICATION_NUMBER}
              onChange={(e) => setMWBE_CERTIFICATION_NUMBER(e.target.value)}
              type="text"
              required={Boolean(MWBE_CERTIFYING_AGENCY)}
              isValid={
                (Boolean(MWBE_CERTIFYING_AGENCY) && Boolean(MWBE_CERTIFICATION_NUMBER)) ||
                (!Boolean(MWBE_CERTIFYING_AGENCY) && !Boolean(MWBE_CERTIFICATION_NUMBER))
              }
              isInvalid={Boolean(MWBE_CERTIFYING_AGENCY) && !Boolean(MWBE_CERTIFICATION_NUMBER)}
              disabled={isDisabled}
            />
            <FormControl.Feedback type="invalid">Certification Number is Required</FormControl.Feedback>
          </Form.Group>
        </Col>

        <Col md="6" sm="6">
          <Form.Group>
            <Form.Label className="d-block" htmlFor="EXPIRATION_DATE">
              Expiration Date
            </Form.Label>
            <FormControl
              className=""
              name="EXPIRATION_DATE"
              value={EXPIRATION_DATE}
              onChange={(e) => setEXPIRATION_DATE(e.target.value)}
              as="input"
              type="date"
              required={Boolean(MWBE_CERTIFYING_AGENCY)}
              isValid={
                (Boolean(MWBE_CERTIFYING_AGENCY) && Boolean(EXPIRATION_DATE)) ||
                (!Boolean(MWBE_CERTIFYING_AGENCY) && !Boolean(EXPIRATION_DATE))
              }
              isInvalid={Boolean(MWBE_CERTIFYING_AGENCY) && !Boolean(EXPIRATION_DATE)}
              disabled={isDisabled}
            />
            <FormControl.Feedback type="invalid">Expiration Date is Required</FormControl.Feedback>
          </Form.Group>
        </Col>
        <Col md="6" sm="6">
          <Form.Group>
            <Form.Label className="d-block" htmlFor="NAICS">
              NAICS Code
            </Form.Label>
            <FormControl
              name="NAICS"
              as="input"
              type="text"
              value={NAICS}
              onChange={(e) => setNAICS(e.target.value)}
              disabled={isDisabled}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );

  return {
    diversityTrackingForm,
    getDiversityTrackingFormData,
  };
};

export default useDiversityTrackigForm;
