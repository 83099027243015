import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { restApiService } from "../../../../../providers/restApi";
import Styles from "../override.module.css";
import {
  onBlurSingle,
  onChangeSingle,
  findSelectedMultiple,
  findSelectedSingle,
  onChange,
  onBlur,
} from "../../../../../services/general/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "../../../../common/pickers/reduxFormPickers/select/helpers";
import { AxiosResponse } from "axios";
import { WrappedFieldProps } from "redux-form";
import AsyncSelect from "react-select/async";
import { MenuPosition } from "react-select";

export interface ProductItemPickerPropsType extends WrappedFieldProps {
  className?: string;
  disabled: boolean;
  labelClassName?: string;
  label?: string;
  isMulti: boolean;
  tooltip?: string;
  required: boolean;
  menuPosition?: MenuPosition;
  inputId?: string;
  placeholder?: string;
  originalValue: any;
  transparent?: boolean;
  instanceId?: string;
  onSelectCallback?: (value: { [key: string]: any }) => null;
  hideCustomStyles?: boolean;
}

export type ProductOptionsType = {
  id: number;
  price: number;
  name: string;
  display_name: string;
  value: number;
  invoice_debit_accounts: { account_id: number }[];
};

export type ProductSearchOptionsType = {
  active: boolean;
  product_name: string;
  limit: number;
  [key: string]: any;
};

export type ProductListedType = {
  value: number;
  label: string;
  price?: number;
  invoice_debit_accounts?: { account_id: number }[];
};

const DEFAULT_PARAMS: ProductSearchOptionsType = {
  active: true,
  product_name: "",
  limit: 100,
};

/** Product Item Picker will always single picker and it's value will product Item object not id */
const ProductItemPicker = ({
  className,
  disabled,
  labelClassName,
  label,
  input,
  meta: { touched, error },
  isMulti,
  tooltip,
  required,
  menuPosition,
  inputId,
  placeholder = "search/select",
  transparent,
  originalValue,
  onSelectCallback,
  instanceId = "productItem-selector",
  hideCustomStyles,
}: ProductItemPickerPropsType) => {
  const optionsRef = useRef<ProductListedType[]>([]);
  const [originalLabel, setOriginalLabel] = useState<string>(""); // only set this for comparison purpose to currently selected
  const [selected, setSelected] = useState<ProductListedType>(); // set this for currently selected or existing input
  const [multSelected, setMultSelected] = useState<Array<ProductListedType>>([]); // set this for multiple selected

  const getCustomConfig = useMemo(() => {
    let componentConfig: { [key: string]: any } = {};
    if (originalValue !== undefined) {
      componentConfig.Input = (props: any) => <PickerComparisonField {...props} originalValue={originalLabel} />;
    }
    if (transparent) {
      componentConfig.DropdownIndicator = () => null;
      componentConfig.IndicatorSeparator = () => null;
    }
    return componentConfig;
  }, [!!originalValue, originalLabel, transparent]);

  const getProductItems = useCallback(async (params: ProductSearchOptionsType) => {
    const result: AxiosResponse<ProductOptionsType[]> = await restApiService.get(
      "product_items.lk",
      params,
      null,
      true,
      null,
      true,
    );
    if (result && result.data) {
      let parsedData = result.data.map((p) => ({
        value: p.id,
        price: p.price,
        label: p.display_name,
        invoice_debit_accounts: p.invoice_debit_accounts,
      }));
      optionsRef.current = parsedData;
      return parsedData;
    }
  }, []);

  const getProduct = useCallback(async (id: number) => {
    const result: AxiosResponse<ProductOptionsType> = await restApiService.get(
      `product_items/${id}`,
      null,
      null,
      true,
      null,
      true,
    );
    if (result && result.data) {
      return result.data;
    }
  }, []);

  const setOriginalProductName = useCallback(async (id: number) => {
    const product = await getProduct(id);
    if (product) {
      setOriginalLabel(product.display_name);
    }
  }, []);

  const setSingleProductSelected = useCallback(async (id: number) => {
    const selectedProduct = await getProduct(id);
    if (selectedProduct) {
      setSelected({ value: selectedProduct.id, label: selectedProduct.display_name });
      onSelectCallback && onSelectCallback(selectedProduct);
    }
  }, []);

  const setMultipleProductSelected = useCallback(async (ids: Array<number>) => {
    let selectedProducts: Array<{ value: number; label: string; price: number }> = [];
    let product: ProductOptionsType | undefined;
    ids.forEach(async (id) => {
      product = await getProduct(id);
      if (product) {
        selectedProducts.push({ value: product.id, label: product.display_name, price: product.price });
      }
    });
    setMultSelected(selectedProducts);
  }, []);

  // used to find option when user search
  const loadOptions = async (inputValue: string = "", callback: any) => {
    const getParams = {
      ...DEFAULT_PARAMS,
      product_name: inputValue,
    };
    return callback(await getProductItems(getParams));
  };

  useEffect(() => {
    if (isMulti) {
      input.value && input.value.length && setMultipleProductSelected(input.value);
    } else {
      input.value && setSingleProductSelected(input.value);
    }
  }, []);

  useEffect(() => {
    if (optionsRef.current.length > 0) {
      if (isMulti) {
        input.value && input.value.length && setMultSelected(findSelectedMultiple(input, optionsRef.current));
      } else {
        const product = findSelectedSingle(input, optionsRef.current);
        input.value && product && setSelected(product);
        onSelectCallback && product && onSelectCallback(product);
      }
    }
  }, [input.value]);

  useEffect(() => {
    if (originalValue) {
      setOriginalProductName(originalValue);
    }
  }, [originalValue]);

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label className={labelClassName}>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <AsyncSelect
        required={required}
        components={getCustomConfig}
        value={isMulti ? multSelected : selected}
        placeholder={placeholder}
        cacheOptions
        loadOptions={loadOptions}
        instanceId={instanceId}
        onChange={isMulti ? (value) => onChange(input, value) : (value) => onChangeSingle(input, value)}
        onBlur={isMulti ? () => onBlur(input, input.value) : () => onBlurSingle(input, input.value)}
        menuPosition={menuPosition}
        isMulti={isMulti}
        styles={
          !hideCustomStyles
            ? {
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "transparent",
                  ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
                  ...(transparent ? { backgroundColor: "transparent", border: "none" } : {}),
                }),
              }
            : {}
        }
        isDisabled={disabled}
      />
      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default ProductItemPicker;
