import React from "react";
import { Dropdown } from "react-bootstrap";
import styles from "../expenseItem/expenseItem.module.css";

type BulkActionDropdownPropsType = {
  children: React.ReactNode;
  topClassName?: string;
  isDisabled?: boolean;
};

const AddToReportDropdown = ({ children, topClassName, isDisabled }: BulkActionDropdownPropsType) => {
  return (
    <div className={"gridDropdown d-inline-block " + topClassName}>
      <Dropdown className={isDisabled ? "addToReportDisabledDropdown" : "addToReportDropdown"}>
        <Dropdown.Toggle id="dropdown-autoclose-true" disabled={isDisabled} className={styles.expenseButton}>
          Add to Report
        </Dropdown.Toggle>

        <Dropdown.Menu>{children}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AddToReportDropdown;
