import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { change, FormSubmitHandler } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import ExpenseSplitTable from "../components/expenseSplitTable";
import SplitExpenseCoding from "../components/splitExpenseCoding";
import AllocationsFields from "./allocationsFields";
import style from "./expenseItem.module.css";
import { useExpenseItemFormContext } from "./expenseItemFormSectionContext";
import { useFormValue } from "./formExpenseItem";

/**
 *
 * @returns wrapper for expense coding fields
 */
const ExpenseAllocations = () => {
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const ctx = useExpenseItemFormContext();
  // make selection as much as possible fine grain
  const expenseReportDebitAccountsAttributes = useFormValue<ExpensesTypes.ExpenseDebitAccounts>(
    ctx?.formName,
    ctx?.sectionPrefix + ".expense_report_debit_accounts_attributes",
  );
  const dispatch = useDispatch();
  const currencyCode = useFormValue<string>(ctx?.formName, ctx?.sectionPrefix + ".currency_code");
  const usingSplit = useFormValue<boolean>(ctx?.formName, ctx?.sectionPrefix + ".using_split");
  const expenseItem = useFormValue<ExpensesTypes.ExpenseItemFormDataType>(ctx?.formName, ctx?.sectionPrefix);
  const validExpenseReportDebitAccountsAttributes =
    expenseReportDebitAccountsAttributes &&
    expenseItemCommonSvc.getValidAttributes(expenseReportDebitAccountsAttributes);
  const [isSplitCodingOpen, setIsSplitCodingOpen] = useState(false);
  const [modalDebitAccountsAttributes, setModalDebitAccountAttributes] = useState<ExpensesTypes.ExpenseDebitAccounts>(
    [],
  );
  const validModalDebitAccountAttributes =
    modalDebitAccountsAttributes && expenseItemCommonSvc.getValidAttributes(modalDebitAccountsAttributes);
  const zeroThModalDebitAccountAttribute =
    Array.isArray(validModalDebitAccountAttributes) && validModalDebitAccountAttributes[0];
  const [isExpenseDebitAccountCopied, setIsExpenseDebitAccountCopied] = useState(false);

  const subsidiaryId = useFormValue<number | string>(ctx?.formName, ctx?.sectionPrefix + ".subsidiary_id");
  const employeeId = useFormValue<number | string>(ctx?.formName, ctx?.sectionPrefix + ".employee_id");
  const policyId = useFormValue<number | string>(ctx?.formName, ctx?.sectionPrefix + ".policy_id");
  const baseCurrencyTotal = useFormValue<number | string>(ctx?.formName, ctx?.sectionPrefix + ".base_currency_total");
  const total = useFormValue<number | string>(ctx?.formName, ctx?.sectionPrefix + ".total");
  const baseCurrencyCode = useFormValue<string>(ctx?.formName, ctx?.sectionPrefix + ".base_currency_code");

  const isSubsidiaryCheck = useCallback(() => {
    return expenseItemCommonSvc.showSubsidiary(currentUser) ? subsidiaryId : true;
  }, [currentUser, subsidiaryId]);

  const isConversionActive = expenseItem && expenseItemCommonSvc.isConversionActive(expenseItem);
  const isSplitCrucialFieldsAdded = isSubsidiaryCheck() && employeeId && policyId && total;

  const initializeModalFirstDebitAccount = () => {
    // after coping, if there is valid debitAccounts updated zeroth
    if (!usingSplit && zeroThModalDebitAccountAttribute) {
      let newFirstDebitAccountAttribute: ExpensesTypes.ExpenseDebitAccount = { ...zeroThModalDebitAccountAttribute };
      if (Number(total) > 0) {
        // if total is defined and greater then zero, assign total to amount and 100% to percentage
        newFirstDebitAccountAttribute.amount = Number(total);
        newFirstDebitAccountAttribute.base_currency_amount = Number(baseCurrencyTotal);
        newFirstDebitAccountAttribute.percentage = 100;
      } else {
        // else need to initialize it with zero amount and percentage to 0%
        newFirstDebitAccountAttribute.amount = 0;
        newFirstDebitAccountAttribute.base_currency_amount = 0;
        newFirstDebitAccountAttribute.percentage = 0;
      }

      const updatedZerothModalDebitAccountsAttributes = modalDebitAccountsAttributes.map((debitAccount, index) => {
        if (index === 0) {
          return {
            ...debitAccount,
            ...newFirstDebitAccountAttribute,
          };
        }
        return debitAccount;
      });
      // need to update zeroTh index
      setModalDebitAccountAttributes(updatedZerothModalDebitAccountsAttributes);
    } else if (usingSplit && validModalDebitAccountAttributes?.length === 0) {
      let newDebitAccountAttribute: ExpensesTypes.ExpenseDebitAccount;
      if (Number(total) > 0) {
        // if total is defined and greater then zero, assign total to amount and 100% to percentage
        newDebitAccountAttribute = {
          amount: Number(total),
          base_currency_amount: Number(baseCurrencyTotal),
          percentage: 100,
        };
      } else {
        // else need to initialize it with zero amount and percentage to 0%
        newDebitAccountAttribute = { amount: 0, percentage: 0 };
      }

      expenseItem &&
        expenseItemCommonSvc.inheritDefaultValueFromEmployee({
          modelData: newDebitAccountAttribute,
          expenseItem,
        });

      // need to push new expenseDebitAccount
      setModalDebitAccountAttributes((prev) => [...modalDebitAccountsAttributes, newDebitAccountAttribute]);
    }
    setIsSplitCodingOpen(true);
  };

  const inheritAllocationFromExpenseItemHeader = () => {
    if (expenseItem) {
      const expenseDebitAccount: ExpensesTypes.ExpenseDebitAccount =
        expenseItemCommonSvc.inheritAllocationFromExpenseItemHeader({ expenseItem });

      // Keep old destroyed entries, if any, so that they can be removed on the server
      setModalDebitAccountAttributes(
        expenseReportDebitAccountsAttributes
          ? [expenseDebitAccount, ...expenseReportDebitAccountsAttributes]
          : [expenseDebitAccount],
      );
      setIsExpenseDebitAccountCopied(true);
    }
  };

  const copyExpenseDebitAccountToModalDebitAccount = () => {
    if (usingSplit) {
      if (expenseReportDebitAccountsAttributes) {
        // for consistent sequencing
        const expenseReportDebitAccountsAttributesSorted = _.sortBy(
          expenseReportDebitAccountsAttributes,
          ["id"],
          ["asc"],
        );
        setModalDebitAccountAttributes(expenseReportDebitAccountsAttributesSorted);
      }
      setIsExpenseDebitAccountCopied(true);
    } else {
      // inherit allocation fields from header to first line of modal_debit_accounts_attributes
      inheritAllocationFromExpenseItemHeader();
    }
  };

  useEffect(() => {
    if (isExpenseDebitAccountCopied) {
      initializeModalFirstDebitAccount();
    }
  }, [isExpenseDebitAccountCopied]);

  const openSplitCoding = () => {
    copyExpenseDebitAccountToModalDebitAccount();
  };

  const closeSplitCoding = () => {
    setIsExpenseDebitAccountCopied(false);
    setIsSplitCodingOpen(false);
  };

  const onSplitExpense = () => {
    openSplitCoding();
  };

  const onSaveSplitExpense: FormSubmitHandler<ExpensesTypes.SplitExpenseCodingFormData, any, any> = ({
    remaining_amount,
    remaining_percentage,
    expense_report_debit_accounts_attributes,
  }) => {
    // logic to update modal_debit_account_attributes to expense_debit_account_attributes
    if (ctx) {
      if (expenseItem) {
        // updating metadata_links so that it would show metadata_links on rerender
        expenseItem.metadata_links = expenseItem?.metadata_links_attributes;
      }

      dispatch(
        change(ctx.formName, ctx.sectionPrefix, {
          ...expenseItem,
          using_split: true,
          remaining_amount,
          remaining_percentage,
          expense_report_debit_accounts_attributes:
            // When a user clears a metadata text value, the metadata object does not have a "value" property.
            // With no value property, the value does not get cleared when the backend call is made.
            // Creating a null value property clears the value.
            expense_report_debit_accounts_attributes?.map((debitAccountLine) => {
              if (Array.isArray(debitAccountLine.metadata_links_attributes)) {
                debitAccountLine.metadata_links_attributes = debitAccountLine.metadata_links_attributes.map(
                  (metadataLink: any) => {
                    if (!metadataLink.hasOwnProperty("value")) {
                      return { ...metadataLink, value: null };
                    }
                    return metadataLink;
                  },
                );
                debitAccountLine.metadata_links = debitAccountLine.metadata_links_attributes;
              }
              return debitAccountLine;
            }),
        }),
      );
    }
    closeSplitCoding();
  };

  const syncTotalToSplitExpenseAmount = () => {
    const updatedExpenseReportDebitAccountsAttributes = expenseReportDebitAccountsAttributes?.map(
      (erDebitAccountItem) => {
        const amount = Number(erDebitAccountItem.amount ?? "0");
        const baseCurrencyAmount = Number(erDebitAccountItem.base_currency_amount ?? "0");
        const applicableAmount = isConversionActive ? baseCurrencyAmount : amount;
        const applicableTotal = isConversionActive ? baseCurrencyTotal : total;

        // calculate percentage from amount and total
        const percentage = commonService.calculatePercentageFromAmount({
          amount: applicableAmount,
          total: Number(applicableTotal),
        });
        return { ...erDebitAccountItem, percentage, percent: percentage };
      },
    );

    let sumOfAmountDebitAttributes =
      expenseReportDebitAccountsAttributes && expenseItemCommonSvc.getSumOfAmount(expenseReportDebitAccountsAttributes);
    sumOfAmountDebitAttributes = adminCommonSvc.roundUpAmount(Number(sumOfAmountDebitAttributes), null, currentUser);

    let sumOfBaseCurrencyAmountDebitAttributes =
      expenseReportDebitAccountsAttributes &&
      expenseItemCommonSvc.getSumOfBaseCurrencyAmount(expenseReportDebitAccountsAttributes);
    sumOfBaseCurrencyAmountDebitAttributes = adminCommonSvc.roundUpAmount(
      Number(sumOfBaseCurrencyAmountDebitAttributes),
      null,
      currentUser,
    );

    // check if sum of all split total is equal to total if not conversion policy else should equal base_currency_policy
    const isTotalFulfilledBySplit = isConversionActive
      ? Number(sumOfBaseCurrencyAmountDebitAttributes) === Number(expenseItem.base_currency_total)
      : Number(expenseItem?.total) === Number(sumOfAmountDebitAttributes);

    if (ctx) {
      dispatch(
        change(ctx.formName, ctx.sectionPrefix, {
          ...expenseItem,
          is_split_100_percent: isTotalFulfilledBySplit,
          expense_report_debit_accounts_attributes: updatedExpenseReportDebitAccountsAttributes,
        }),
      );
    }
  };

  useEffect(() => {
    if (currentUser.company.expense_item?.enable_split_expenses && expenseItem?.amount && expenseItem?.using_split) {
      // sync split expense items amount according to percentage to Total if total changes
      syncTotalToSplitExpenseAmount();
    }
  }, [total, usingSplit, isSplitCodingOpen]);

  const entering = (e: { children: HTMLDivElement[] }) => {
    e.children[0].style.borderTopColor = "#656579CC";
    e.children[0].style.display = "none";
    e.children[1].style.backgroundColor = "#656579CC";
    e.children[1].style.maxWidth = "301px";
    e.children[1].style.width = "301px";
    e.children[1].style.padding = "16px";
    e.children[1].style.position = "relative";
    e.children[1].style.right = "118px";
  };

  if (ctx === undefined) return null;
  return (
    <Row>
      {isSplitCodingOpen && expenseItem && (
        <SplitExpenseCoding
          initialValues={{
            expenseItem,
            total: Number(total),
            subsidiary_id: expenseItem?.subsidiary_id,
            policy_id: expenseItem?.policy_id,
            currency_code: currencyCode,
            base_currency_code: baseCurrencyCode,
            base_currency_total: Number(baseCurrencyTotal),
            currency_rate: expenseItem?.currency_rate,
            expense_report_debit_accounts_attributes:
              expenseItemCommonSvc.prepareExpenseReportDebitAccountsForSplitCodingForm({
                modalDebitAccountsAttributes,
                expenseItem,
              }),
            currency_conversion_active: isConversionActive,
            subsidiary: expenseItem?.subsidiary,
            policy: expenseItem?.policy,
          }}
          showSplitExpense={isSplitCodingOpen}
          onSubmit={onSaveSplitExpense}
          cancelCallback={closeSplitCoding}
        />
      )}
      <Col lg="12">
        <Row>
          <Col>
            {!usingSplit ? (
              <Row>
                <Col md={{ offset: 4, span: 8 }}>
                  <hr className={style.separatorBetween} />
                  <Row className={style.mb24}>
                    <Col lg={"12"}>Expense Allocation</Col>
                  </Row>
                  <AllocationsFields />
                  <Row>
                    <Col>
                      {currentUser.company.expense_item?.enable_split_expenses &&
                        expenseItem?.item_type === "SINGLE_EXPENSE" && (
                          <>
                            {isSplitCrucialFieldsAdded && (
                              <Button variant="secondary" className={style.splitExpenseBtn} onClick={onSplitExpense}>
                                Split Expense
                              </Button>
                            )}
                            {!isSplitCrucialFieldsAdded && (
                              <OverlayTrigger
                                placement={"top"}
                                onEntering={(e: any) => entering(e)}
                                overlay={
                                  <Tooltip className={style.expenseBarToolTip} id="button-tooltip-3">
                                    Required fields {!employeeId && <span>Employee</span>}{" "}
                                    {!isSubsidiaryCheck() && <span>Subsidiary</span>} {!policyId && <span>Policy</span>}{" "}
                                    {!total && <span>Total</span>} must be filled in before expense can be split
                                  </Tooltip>
                                }
                              >
                                <Button variant="secondary" className={style.splitExpenseBtn}>
                                  Split Expense
                                </Button>
                              </OverlayTrigger>
                            )}
                          </>
                        )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <>
                <hr className={style.separatorBetween} />
                <ExpenseSplitTable
                  isForm
                  expenseItem={{
                    ...expenseItem,
                    expense_report_debit_accounts: validExpenseReportDebitAccountsAttributes,
                  }}
                />
                <Row className={style.mtr24}>
                  <Col>
                    {isSplitCrucialFieldsAdded && (
                      <Button variant="secondary" className={style.splitExpenseBtn} onClick={onSplitExpense}>
                        Edit Split
                      </Button>
                    )}
                    {!isSplitCrucialFieldsAdded && (
                      <OverlayTrigger
                        placement={"top"}
                        onEntering={(e: any) => entering(e)}
                        overlay={
                          <Tooltip className={style.expenseBarToolTip} id="button-tooltip-3">
                            Required fields {!employeeId && <span>Employee</span>}{" "}
                            {!isSubsidiaryCheck() && <span>Subsidiary</span>} {!policyId && <span>Policy</span>}{" "}
                            {!total && <span>Total</span>} must be filled in before expense can be split
                          </Tooltip>
                        }
                      >
                        <Button variant="secondary" className={style.splitExpenseBtn}>
                          Edit Split
                        </Button>
                      </OverlayTrigger>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ExpenseAllocations;
