import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./subsidiaries.module.css";
import { Field, FieldArray, Form, InjectedFormProps, formValueSelector, reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";
import { RenderField, RenderSelect } from "../../forms/bootstrapFields";
import { required } from "../../../services/validations/reduxFormValidation";
import { useHistory } from "react-router";
import { SubsidiaryDetailType } from "../../../services/admin/subsidiaries/types";
import CountryPicker from "components/admin/pickers/reduxFormPickers/countryPicker";
import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";
import SubsidiaryLocationRenderer from "./subsidiaryLocationRenderer";
import SubsidiaryDepartmentRenderer from "./subsidiaryDepartmentRenderer";
import { concurrentRequestsService } from "services/general/concurrentRequests";
import { getContactParams } from "../../pickers/reduxFormPicker/contactPicker";
import { useTypedSelector } from "reducers";
import useAdminCompanyCurrencyCode from "../hooks/useAdminCompanyCurrencyCode";
import _ from "lodash";
import SubsidiaryLinkRenderer from "./subsidiaryLinkRenderer";
import ProjectPicker from "../pickers/reduxFormPickers/projectPicker";
import BusinessUnitPicker from "components/pickers/reduxFormPicker/businessUnitPicker";
import { useSelector } from "react-redux";
import MetadataFieldPicker from "../pickers/reduxFormPickers/metadataPicker/metadataPicker";
import { MetadataConfiguration } from "pages/admin/administration/metadata/metadataConfiguration";
import { restApiService } from "providers/restApi";

const formName = "SubsidiaryForm";

type SubsidiaryFormPropsTypes = {
  isAddForm: boolean;
  returnTo?: string;
  onSubmit: (subsidiary: SubsidiaryDetailType) => void;
};

interface SubsidiaryReduxFormProps
  extends SubsidiaryFormPropsTypes,
    InjectedFormProps<SubsidiaryDetailType, SubsidiaryFormPropsTypes> {}

const FormSubsidiary = ({ handleSubmit, isAddForm, returnTo }: SubsidiaryReduxFormProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dataCached, setDataCached] = useState<boolean>(false);
  const currentUser = useTypedSelector((state) => state.user);
  const { companyCurrencies } = useAdminCompanyCurrencyCode();

  const selector = formValueSelector(formName);

  const metadataConfigurations = useSelector((state) => selector(state, "metadata_configurations"));

  useEffect(() => {
    const requests = [
      {
        endpoint: "departments.lk",
        getParams: { status: "ACTIVE" },
        cache: true,
      },
      {
        endpoint: "locations.lk",
        getParams: { status: "ACTIVE" },
        cache: true,
      },
      {
        endpoint: "contacts.lk",
        getParams: getContactParams("USERS"),
        cache: true,
      },
    ];

    concurrentRequestsService.get(requests).finally(() => {
      setDataCached(true);
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit} className={styles.formFontSize}>
      <Container fluid={true} className="mt-4 px-pl-15">
        <Row className={styles.subsidiaryFormLabel}>
          <Col sm="6" className="px-p-0">
            <div>{isAddForm ? t("subsidiary.add") : t("subsidiary.edit")}</div>
          </Col>
          <Col sm="6" className="text-right px-pr-0">
            <Button variant=" btn-secondary" onClick={() => history?.push(returnTo ? returnTo : "/ap/subsidiaries")}>
              {t("cancel")}
            </Button>
            <Button variant="btn btn-primary ml-1" type="submit">
              {t("submit")}
            </Button>
          </Col>
        </Row>
        <Row className="mt-24 px-mt-40">
          <Col sm="4">
            <Field
              name="name"
              component={RenderField}
              validate={[required]}
              type="text"
              placeholder={t("subsidiary.name")}
              label={t("subsidiary.name")}
              className={`w-100`}
              FieldClassName={styles.formFontSize}
              required
            />
          </Col>
          <Col sm="4">
            <Field
              name="currency_code"
              component={CurrencyCodePicker}
              currencyCodeOptions={_.isArray(companyCurrencies) ? companyCurrencies : []}
              label={t("subsidiary.currencyCode")}
              required={true}
              validate={[required]}
            />
          </Col>
          <Col sm="4">
            <Field
              name="status"
              component={RenderSelect}
              validate={[required]}
              FieldClassName="formField"
              options={[
                { label: "Select", value: "" },
                { label: "Active", value: "ACTIVE" },
                { label: "Closed", value: "CLOSED" },
              ]}
              placeholder={"Search/Select"}
              label={t("subsidiary.status")}
              required
            />
          </Col>
          <Col sm="4">
            <Field
              name="sub_type"
              component={RenderField}
              type="text"
              placeholder={t("subsidiary.subType")}
              label={t("subsidiary.subType")}
              className={`w-100`}
              FieldClassName={styles.formFontSize}
            />
          </Col>
          <Col sm="4">
            <Field
              name="country"
              component={CountryPicker}
              colSize={12}
              className={`w-100`}
              label={t("subsidiary.country")}
              isClearable={true}
            />
          </Col>
          <Col sm="4">
            <Field
              name="external_id"
              component={RenderField}
              className={`w-100`}
              label={t("subsidiary.externalId")}
              meta={{ touched: false }}
            />
          </Col>
        </Row>

        {/* LINK LOCATION SECTION */}
        {/* USE THE GENERIC COMPONENT FROM NOW ON*/}
        {currentUser?.company?.has_locations && (
          <ErrorBoundary>
            <Row className="px-mt-25">
              <Col>
                <FieldArray
                  key={`subsidiaryLocationLink`}
                  name="subsidiary_manager_location_links_attributes"
                  component={SubsidiaryLocationRenderer}
                  dataCached={dataCached}
                />
              </Col>
            </Row>
          </ErrorBoundary>
        )}

        {/* LINK DEPARTMENT SECTION */}
        {/* USE THE GENERIC COMPONENT FROM NOW ON*/}
        {currentUser?.company?.has_departments && (
          <ErrorBoundary>
            <Row className="px-mt-25">
              <Col>
                <FieldArray
                  key={`subsidiaryDepartmentLink`}
                  name="subsidiary_links_attributes"
                  component={SubsidiaryDepartmentRenderer}
                  dataCached={dataCached}
                />
              </Col>
            </Row>
          </ErrorBoundary>
        )}

        <ErrorBoundary>
          <Row className="px-mt-25">
            <Col>
              <FieldArray
                name="subsidiary_link_project"
                title={t("subsidiary.linkProject")}
                pickerName={t("subsidiary.project")}
                noDataText={t("subsidiary.noProject")}
                // DONT TRANSLATE THIS
                model={"Project"}
                picker={ProjectPicker}
                component={SubsidiaryLinkRenderer}
                dataCached={true}
              />
            </Col>
          </Row>
        </ErrorBoundary>

        <ErrorBoundary>
          <Row className="px-mt-25">
            <Col>
              <FieldArray
                name="subsidiary_link_business_unit"
                title={t("subsidiary.linkBusinessUnit")}
                pickerName={t("subsidiary.businessUnit")}
                noDataText={t("subsidiary.noBusinessUnit")}
                // DONT TRANSLATE THIS
                model={"BusinessUnit"}
                picker={BusinessUnitPicker}
                component={SubsidiaryLinkRenderer}
                dataCached={true}
              />
            </Col>
          </Row>
        </ErrorBoundary>

        {metadataConfigurations && metadataConfigurations.length > 0 && (
          <div className="mt-3">
            <h4 className="mb-1">Metadata</h4>
            {metadataConfigurations.map((metadataConfiguration: MetadataConfiguration) => {
              if (metadataConfiguration.input_type == "multi-select" || metadataConfiguration.input_type == "select") {
                return (
                  <ErrorBoundary key={metadataConfiguration.id}>
                    <Row className="px-mt-25">
                      <Col>
                        <FieldArray
                          name={"subsidiary_link_" + metadataConfiguration.field_id}
                          title={metadataConfiguration.name}
                          pickerName={metadataConfiguration.name}
                          noDataText={"No " + metadataConfiguration.name}
                          model={"MetadataField"}
                          picker={MetadataFieldPicker}
                          component={SubsidiaryLinkRenderer}
                          dataCached={true}
                          pickerProps={{ metadataConfiguration: metadataConfiguration, name: "", isMulti: false }}
                        />
                      </Col>
                    </Row>
                  </ErrorBoundary>
                );
              }
            })}
          </div>
        )}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </Container>
    </Form>
  );
};

const ConnectedFormSubsidiary = reduxForm<SubsidiaryDetailType, SubsidiaryFormPropsTypes>({
  form: formName,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FormSubsidiary);

export default ConnectedFormSubsidiary;
