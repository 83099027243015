import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderDatePicker } from "../../../../../forms/bootstrapFields";
import { renderField } from "../../../../../forms/fields";
import { required } from "services/validations/reduxFormValidation";
import { RequiredFieldIcon } from "./requiredFieldIcon";
import { useTranslation } from "react-i18next";

export const NotProcessedDispute = () => {
  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.option.not_processed.${key}`);
  };

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col xs={4} style={{ display: "flex", alignItems: "center" }}>
          {t2("question")}
        </Col>
        <Col>
          <Row>
            <Field
              name="form.options.DATE_CREDIT_ISSUED"
              component={RenderDatePicker}
              type="date"
              maxDate={new Date()}
              validate={[required]}
            ></Field>
            <RequiredFieldIcon></RequiredFieldIcon>
          </Row>
        </Col>
      </Row>
      <Row style={{ width: "100%", marginBottom: "16px" }}>
        <Col xs={4} style={{ display: "flex", alignItems: "center" }}>
          {t2("cancellation")}
        </Col>
        <Col xs={3}>
          <Row>
            <Field name="form.options.CANCELLATION_NUMBER" component={renderField}></Field>
          </Row>
        </Col>
      </Row>
      <Row style={{ fontWeight: "bold" }}>
        <Col>
          <div>{t2("line_1")}</div>
          <div>{t2("line_2")}</div>
          <div>{t2("line_3")}</div>
        </Col>
      </Row>
    </>
  );
};
