import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";

const TokenBased = () => {
  return (
    <>
      <Col lg="6">
        <Field
          name="form.application_id"
          label="Element ID"
          placeholder="Application ID"
          component={RenderField}
          type="text"
        />
      </Col>
      <Col lg="12">
        <Field
          name="form.key"
          label="Application Key"
          placeholder="Application Key"
          component={RenderField}
          type="password"
        />
      </Col>
    </>
  );
};

export default TokenBased;
