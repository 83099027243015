import React, { memo, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import AccrualForm from "./form";
import _ from "lodash";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import RestApi from "../../../providers/restApi";

const restApiService = new RestApi();

const submitAttachments = (attachments, accrualId, history, SetFormBtnsDisabled) => {
  var formData = new FormData();
  attachments.map((attachment, key) => {
    if (!attachment.id) {
      formData.append("accrual[assets_attributes[" + key + "]asset_file]", attachment);
    }
  });

  restApiService
    .patch("vendor_accruals/" + accrualId, null, formData)
    .then((result) => {
      CreateNotification("Success", "Attachment uploaded successfully", "success");
      history.push("/ar/accruals");
    })
    .catch((error) => {
      console.error("Error saving company info: ", error);
      SetFormBtnsDisabled(false);
    });
};

const submitAccrual = (values, history, SetFormBtnsDisabled) => {
  SetFormBtnsDisabled(true);
  restApiService
    .post("vendor_accruals", null, { accrual: values })
    .then((result) => {
      if (result.data.id) {
        if (_.isArray(values.attachments) && values.attachments.length > 0) {
          submitAttachments(values.attachments, result.data.id, history, SetFormBtnsDisabled);
        } else {
          history.push("/ar/accruals");
        }
        CreateNotification("Added", "Accrual number " + result.data.number + " added", NotificationType.success);
      }
    })
    .catch((error) => {
      CreateNotification("Validation Error", "Please enter valid data", NotificationType.danger);
      SetFormBtnsDisabled(false);
    });
};

const SubmitAccrual = (props) => {
  const [formBtnsDisabled, SetFormBtnsDisabled] = useState(false);

  const handleSubmit = (values) => {
    if (_.isPlainObject(values)) {
      submitAccrual(values, props.history, SetFormBtnsDisabled);
    }
  };

  return <AccrualForm {...props} onSubmit={handleSubmit} isDisableFormBtn={formBtnsDisabled} />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    accrual: getFormValues("AccrualForm")(state) || {},
  };
};

export default withTranslation()(connect(mapStateToProps)(SubmitAccrual));
