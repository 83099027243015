import React, { ReactNode, useEffect, useState } from "react";
import MultipleCurrencyCodePicker from "./multipleCurrencyCodePicker";
import SingleCurrencyCodePicker from "./singleCurrencyCodePicker";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { IUser } from "services/common/user/userTypes";
import _ from "lodash";
import { selectAppCurrencyCode } from "reducers/common/appCommonStatesSlice";
import policyCommonSvc from "services/admin/policy/policyCommonSvc";
import useIsMounted from "components/common/hooks/useIsMounted";
import companyService from "services/common/company/companySvc";
import commonService from "services/common/commonSvc";
import { FieldRenderProps } from "react-final-form";

export type CurrencyCodeOptionType = {
  value?: string;
  label?: string;
  name?: string;
  symbol?: string;
  iso_code?: string;
  code?: string;
};
export interface CurrencyCodePickerPropsType extends FieldRenderProps<any, HTMLElement, any> {
  label?: React.ReactNode;
  tooltip?: React.ReactNode;
  disabled?: boolean;
  placeHolder?: string;
  isMulti?: boolean;
  required?: boolean;
  instanceId?: string;
  callBack?: (selectedCurrency: CurrencyCodeOptionType | readonly CurrencyCodeOptionType[]) => void;
  className?: string;
  defaultValue: string; // whenever we get default value it only show that as option in list
  showCurrencyWithSymbol: boolean;
  parentDataObj?: any;
  notClearAble?: boolean;
  modelData?: Record<string, any>;
  modelName?: string;
  currencyCodeOptions?: CurrencyCodeOptionType[]; // Made this optional to avoid conflict
}

const CurrencyCodePicker = (props: CurrencyCodePickerPropsType) => {
  const { isMulti, modelName, modelData, currencyCodeOptions: propCurrencyCodeOptions, ...restProps } = props;

  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const appCurrencies = useTypedSelector(selectAppCurrencyCode);
  const mounted = useIsMounted();
  const [currencyCodeOptions, setCurrencyCodeOptions] = useState<CurrencyCodeOptionType[]>([]);

  const loadCurrenciesBySection = (currencyCodes: CurrencyCodeOptionType[]) => {
    if (modelName === "policy") {
      currencyCodes = policyCommonSvc.getPolicyCurrencyCodes(modelData, currencyCodes);
    } else if (modelName === "expense_item") {
      currencyCodes = modelData?.is_expense_call_from_po_detail_page
        ? appCurrencies.filter((gbCurrencyCode) => gbCurrencyCode.value === modelData?.currency_code) // taking only po currency_code
        : policyCommonSvc.getPolicyCurrencyCodes(modelData?.policy, currencyCodes);
    } else if (
      (modelName === "vendor" || modelName === "contact") &&
      Array.isArray(currencyCodes) &&
      currencyCodes.length > 0
    ) {
      _.forEach(currencyCodes, function (currency) {
        currency.code = currency.value;
      });
    }
    return currencyCodes;
  };

  const getPolicyMileageRatesCurrencies = (currencyCodes: CurrencyCodeOptionType[]) => {
    if (Array.isArray(modelData?.policy?.mileage_rates) && modelData?.policy.mileage_rates.length > 0) {
      let currencies: CurrencyCodeOptionType[] = appCurrencies.filter((globalCurrencyCode) => {
        return (
          Array.isArray(modelData?.policy?.mileage_rates) &&
          modelData?.policy?.mileage_rates.some(
            (mileageRate: any) => mileageRate.currency_code === globalCurrencyCode.value,
          )
        );
      });
      currencyCodes = currencies;
    }
    return currencyCodes;
  };

  const getCompanyCurrencyCodes = async () => {
    try {
      let currencyCodes = await companyService.getCompanyCurrencies(appCurrencies);
      currencyCodes = loadCurrenciesBySection(currencyCodes);
      return currencyCodes;
    } catch (error) {
      commonService.handleError(error);
    }
  };

  const loadCurrencies = async () => {
    let currencyCodes: CurrencyCodeOptionType[] = [];
    if (currentUser.company.global?.allow_only_company_currency_codes) {
      let companyCurrencyCode = await getCompanyCurrencyCodes();
      if (companyCurrencyCode) {
        currencyCodes = companyCurrencyCode;
      }
    } else if (
      modelData?.item_type === "MILEAGE" ||
      (_.isPlainObject(modelData?.selected_type) && modelData?.selected_type?.value === "MILEAGE")
    ) {
      currencyCodes = getPolicyMileageRatesCurrencies(currencyCodes);
    } else {
      currencyCodes = appCurrencies;
      currencyCodes = loadCurrenciesBySection(currencyCodes);
    }
    if (mounted.current) {
      setCurrencyCodeOptions(currencyCodes);
    }
  };

  useEffect(() => {
    loadCurrencies();
  }, [appCurrencies]);

  return isMulti ? (
    <MultipleCurrencyCodePicker currencyCodeOptions={currencyCodeOptions} {...restProps} />
  ) : (
    <SingleCurrencyCodePicker currencyCodeOptions={currencyCodeOptions} {...restProps} />
  );
};

export default CurrencyCodePicker;
