import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { compare, findSelectedSingle, onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import { IDType } from "../../../../../services/common/types/common.type";

type PaymentTermPickerPropsType = {
  className?: string;
  label?: string;
  isMulti?: boolean;
  tooltip?: string;
  required?: boolean;
  placeholder?: string;
  input?: any;
  instanceId?: string;
  callBack?: (selected: selectedTypes) => void;
};

type PaymentTermOptionsType = {
  code?: string;
  company_id?: number;
  days_within_due_date?: number;
  description?: string;
  disc_amount?: number;
  disc_days?: number;
  disc_grace_days?: number;
  disc_rate?: number;
  disc_upto_month?: number;
  due_days?: number;
  due_upto_month?: number;
  external_id?: string;
  id?: number;
  is_disc_month_date?: boolean;
  is_discount_on_subtotal?: boolean;
  is_due_month_date?: boolean;
  name?: string;
  status?: string;
  value?: number;
};

type selectedTypes = {
  value: IDType;
  label: string;
  isSelected?: boolean;
};

const PaymentTermPicker = ({
  label,
  input,
  required,
  tooltip,
  instanceId = "payment-term-picker",
  callBack,
}: PaymentTermPickerPropsType) => {
  const [paymentTerms, setPaymentTerms] = useState<PaymentTermOptionsType[]>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onChange = (input: any, selected: selectedTypes) => {
    onChangeSingle(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  const parseForSelect = (options: any) => {
    return options
      .map((option: PaymentTermOptionsType) => {
        return {
          value: option.id,
          label: option.name,
          discRate: option.disc_rate,
          discDays: option.disc_days,
          ...option,
        };
      })
      .sort(compare);
  };

  const getPaymentTerms = useCallback(async () => {
    const result: AxiosResponse<PaymentTermOptionsType[]> = await restApiService.get(
      "terms",
      { ACTIVE: "YES" },
      null,
      true,
      null,
      true,
    );
    if (result && result.data) {
      setPaymentTerms(parseForSelect(result.data));
    }
  }, []);

  const customFilter = (option: any, searchText: string) => {
    if (
      option.data?.code?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data?.description?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getPaymentTerms();
  }, []);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={false}
          value={findSelectedSingle(input, paymentTerms)}
          onChange={(selected: selectedTypes) => onChange(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={paymentTerms}
          onMenuOpen={() => setIsDropdownOpen(true)}
          onMenuClose={() => setIsDropdownOpen(false)}
          filterOption={customFilter}
          instanceId={instanceId}
          getOptionLabel={(option: any) => {
            return (
              <>
                {option.code} {option.is_discount_on_subtotal && <small>(On Subtotal)</small>}
                {option.status.toLowerCase() === "inactive" && <small>(INACTIVE)</small>}
                {isDropdownOpen && (
                  <>
                    {(option.discRate || option.discDays) && (
                      <>
                        <br />
                        {option.discRate ? "Disc Rate: " + option.discRate + " " : ""}
                        {option.discDays ? "Disc Days: " + option.discDays : ""}
                      </>
                    )}
                    {option.description && (
                      <>
                        <br />
                        Description: {option.description}
                      </>
                    )}
                  </>
                )}
              </>
            );
          }}
        ></Select>
      </Form.Group>
    </>
  );
};

export default PaymentTermPicker;
