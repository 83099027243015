const style4 = `.w-8{
    background-color: #eeeeee;
}

#main-w8-form{
    position: relative;
    zoom: 1.5;
    width: 608px;
    margin: 0 auto;
}   

.pc2 {
    position: absolute;
    border: 0;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}


.w8-form-input-text {
    all: unset;
    background-color: #c4e6ff;
    height: 11.2px;
    position: absolute;
    font-size: 10px !important;
}

.absolute{
    position: absolute;
}

.form_12_image_position{
    position: absolute;
    left: 4px;
    bottom: -20px;
}

#input_1 {
    top: 240px;
    left: 36.8px;
    width: 336px;
}

#input_2 {
    top: 240px;
    left: 376px;
    width: 200px;
}

#input_3 {
    top: 264px;
    left: 36.8px;
    width: 539.2px;
}

#input_4 {
    top: 288px;
    left: 36.8px;
    width: 401.6px;
}

#input_5 {
    top: 288px;
    left: 440px;
    width: 136px;
}


#input_6 {
    top: 312px;
    left: 36.8px;
    width: 539.2px;
}

#input_7 {
    top: 336px;
    left: 36.8px;
    width: 401.6px;
}

#input_8 {
    top: 336px;
    left: 440px;
    width: 136px;
}


#input_9 {
    top: 359.84px;
    left: 36.8px;
    width: 539.2px;
}

#input_10 {
    top: 384px;
    left: 36.8px;
    width: 250.4px;
}

#input_11 {
    top: 384px;
    left: 289.6px;
    width: 286.4px;
}

#input_13 {
    top: 408px;
    left: 36.8px;
    width: 250.4px;
}

#input_14 {
    top: 408px;
    left: 289.6px;
    width: 286.4px;
}

#input_12{
    top: 365px;
    left: 563px;
}

#input_15 {
    height: 9.6px;
    top: 433.6px;
    left: 240px;
    width: 200px;
}

#input_16 {
    top: 468px;
    left: 64px;
    width: 112px;
}

#input_17{
    top: 468px;
    left: 352px;
    width: 22.4px;
}

#input_18 {
    top: 480px;
    left: 64px;
    width: 505.6px;
}

#input_19 {
    height: 9.6px;
    top: 505.6px;
    left: 64px;
    width: 512px;
}

#input_20 {
    top: 676.8px;
    left: 108.8px;
}

#input_21 {
    top: 697.6px;
    left: 108.8px;
    width: 315.2px;
}

#input_22 {
    top: 708.8px;
    left: 432px;
    width: 144px;
}

#input_23 {
    top: 732.8px;
    left: 108.8px;
    width: 315.2px;
}

.hideDiv{
    display: none;
}

img{
    display: inline !important;
  }

`;
export default style4;
