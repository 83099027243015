import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonGroup, Col, Container, Dropdown, Row } from "react-bootstrap";
import NavTabs from "../nav";
import GridFilterDropdown from "components/datagrid/gridFilterDropdown";
import {
  ColumnApi,
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
  IServerSideSelectionState,
  PaginationChangedEvent,
} from "ag-grid-community";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import { useTypedSelector } from "reducers";
import ToggleFilterButton from "components/datagrid/buttons/toggleFilterButton";
import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import { processFilterModel, processSortModel, saveDefaultOrder } from "services/common/gridService";
import { FIELD_NAME_MAP, getExpenseReportsListHeaders } from "./listExpenseReportHeaders";
import ExportDataButton from "components/common/dataGrid/exportDataButton";
import ImportDataButton from "components/common/dataGrid/importDataButton";
import { useTranslation } from "react-i18next";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import BulkAction from "./bulkActions";
import gridService from "services/grid/gridSvc";
import style from "./expenseReports.module.css";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import expenseReportSvc from "services/admin/expenses/expenseReport/expenseReportSvc";
import { useHistory, useLocation } from "react-router";
import moment from "moment";

const GRID_STORAGE_NAME = "listExpenseReports";
const paginationOptions = [25, 50, 100];
type RouteFilterType = {
  [key: string]: string | undefined;
  subsidiaryId?: string;
  expenseType?: string;
  subsidiaryName?: string;
  status?: string;
  date_before?: string;
  date_after?: string;
};

const ListExpenseReports = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const { showFilters, updateShowFilters } = useShowFilterState("listExpenseReportsFilter", true);
  const currentUser = useTypedSelector((state) => state.user);
  const checkBoxRef: any = useRef(null);
  const { t } = useTranslation();
  const [filterBtn, setFilterBtn] = useState<"UNSUBMITTED" | "PENDING_APPROVAL" | "ALL_REPORTS">();
  const [filterStatus, setFilterStatus] = useState<string | null>(null);
  const hasRouteFilterRef = useRef(false);
  const routeFilterRef = useRef<RouteFilterType>({});
  const [stats, setStats] = useState<ExpenseReportTypes.statusCountResponse>();
  const location = useLocation();
  const history = useHistory();
  const applyTabFilter = (gridApi: GridApi) => {
    if (filterBtn === "ALL_REPORTS") {
      if (filterStatus) {
        switch (filterStatus) {
          case "5":
            setFilterStatus(null);
            statusFilterHandler(gridApi, "5");
            break;
          case "6":
            setFilterStatus(null);
            statusFilterHandler(gridApi, "6");
            break;
          case "12":
            setFilterStatus(null);
            statusFilterHandler(gridApi, "12");
            break;
          case "7":
            setFilterStatus(null);
            statusFilterHandler(gridApi, "7");
            break;
          case "72":
            setFilterStatus(null);
            statusFilterHandler(gridApi, "72");
            break;
          default:
            break;
        }
      } else {
        statusFilterHandler(gridApi, "1");
      }
    } else if (filterBtn === "PENDING_APPROVAL") {
      pendingApproveHandler(gridApi);
    } else if (filterBtn === "UNSUBMITTED") {
      unSubmittedFilterHandler(gridApi);
    }
  };

  const gridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    applyTabFilter(params.api);
    //tell the grid that we are using a custom data source
    params.api.setGridOption("serverSideDatasource", { getRows });
  }, []);

  const getParams = (params: IServerSideGetRowsParams) => {
    // this is created for sorting as key for sorting and key for table coloumn data is defferent
    const sortKeyRemapps = {
      "employee.name": "employee_name",
      purchase_order_number: "purchase_order_list_str",
      "subsidiary.name": "subsidiary_name",
      currency_code: "currency.iso_code",
    };

    let sortObject: any = {};
    if (params?.request?.sortModel?.length > 0) {
      sortObject = processSortModel(params.request.sortModel, sortKeyRemapps);
    } else {
      sortObject = { id: { direction: "desc", priority: 0 }, number: { direction: "desc", priority: 1 } };
    }
    let paramsObj: any = {
      sort: sortObject,
    };
    if (params?.request?.endRow !== undefined && params?.request?.startRow !== undefined) {
      paramsObj.items = params?.request?.endRow - params?.request?.startRow; //grid will pass in the first index and last index that needs to be fetched
      paramsObj.page = 1 + params?.request?.startRow / params.api.paginationGetPageSize();
    }

    if (params?.request?.filterModel) {
      const filters: any = processFilterModel(params.request.filterModel, FIELD_NAME_MAP);
      expenseReportSvc.getStatusParams({ filters });
      // Filters need to send with params we are passign to expense report list API
      paramsObj = { ...paramsObj, ...filters };
    }
    return paramsObj;
  };

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = async (params: IServerSideGetRowsParams) => {
    try {
      const result = await ExpenseReportApis.getPaginatedList({ filter: getParams(params) });
      params.success({
        rowData: expenseReportSvc.decorateExpenseReport({ response: result.data }),
        rowCount: result.meta.count,
      });
    } catch (error) {
      console.log(error);
      params.fail();
    }
  };

  const fltrOutstanding = () => {
    if (gridApi) {
      gridApi?.setFilterModel(null);
      const balanceOutstandingFltr = gridApi.getFilterInstance("balance_outstanding");
      if (balanceOutstandingFltr) {
        balanceOutstandingFltr.setModel(gridService.getGreaterThanOrEqualNumberFilterModel(0.01));
      }
      const statusFilter = gridApi.getFilterInstance("status");
      if (statusFilter) {
        statusFilter.setModel(gridService.getContainTextFilterModal("10"));
      }
      gridService.setColsVisiable({ gridApi, gridColumnApi, columnNames: ["balance_outstanding"] });
      gridApi.onFilterChanged();
    }
  };

  const fltrNoVendor = () => {
    if (gridApi) {
      gridApi?.setFilterModel(null);
      const employeeFltrIntance = gridApi.getFilterInstance("employee.id");
      if (employeeFltrIntance) {
        employeeFltrIntance.setModel(gridService.getBlankTextFilterModel());
      }
      const statusFilter = gridApi.getFilterInstance("status");
      if (statusFilter) {
        statusFilter.setModel(gridService.getContainTextFilterModal("11"));
      }
      gridService.setColsVisiable({ gridApi, gridColumnApi, columnNames: ["employee.id"] });
      gridApi.onFilterChanged();
    }
  };

  const fltrStatusAll = (gridApi: GridApi) => {
    if (gridApi) {
      gridApi?.setFilterModel(null);
      const statusFilter = gridApi.getFilterInstance("status");
      if (statusFilter) {
        statusFilter.setModel(gridService.getContainTextFilterModal("1"));
      }
      gridApi.onFilterChanged();
    }
  };

  const fltrStatusNew = (gridApi: GridApi) => {
    gridApi?.setFilterModel(null);
    const statusFilter = gridApi.getFilterInstance("status");
    if (statusFilter) {
      statusFilter.setModel(gridService.getContainTextFilterModal("2"));
    }
    gridApi.onFilterChanged();
  };

  const unSubmittedFilterHandler = (gridApi: GridApi) => {
    gridApi?.setFilterModel(null);
    if (gridApi) {
      const statusFilter = gridApi.getFilterInstance("status");
      if (statusFilter) {
        statusFilter.setModel(gridService.getContainTextFilterModal("2"));
      }
      if (routeFilterRef?.current?.subsidiaryName) {
        const subsidiaryFilter = gridApi.getFilterInstance("subsidiary.name");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(gridService.getContainTextFilterModal(routeFilterRef?.current?.subsidiaryName));
        }
      }
      if (routeFilterRef?.current?.expenseType) {
        const subsidiaryFilter = gridApi.getFilterInstance("reimbursable");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(
            gridService.getContainTextFilterModal(
              routeFilterRef?.current?.expenseType === "all" ? "" : routeFilterRef?.current?.expenseType,
            ),
          );
        }
      }
      gridApi.onFilterChanged();
    }
  };

  const fltrStatusPending = (gridApi: GridApi) => {
    gridApi?.setFilterModel(null);
    const statusFilter = gridApi.getFilterInstance("status");
    if (statusFilter) {
      statusFilter.setModel(gridService.getContainTextFilterModal("3"));
    }
    gridApi.onFilterChanged();
  };
  const pendingApproveHandler = (gridApi: GridApi) => {
    gridApi?.setFilterModel(null);
    if (gridApi) {
      const statusFilter = gridApi.getFilterInstance("status");
      if (statusFilter) {
        statusFilter.setModel(gridService.getContainTextFilterModal("3"));
      }
      if (routeFilterRef?.current?.subsidiaryName) {
        const subsidiaryFilter = gridApi.getFilterInstance("subsidiary.name");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(gridService.getContainTextFilterModal(routeFilterRef?.current?.subsidiaryName));
        }
      }
      if (routeFilterRef?.current?.expenseType) {
        const subsidiaryFilter = gridApi.getFilterInstance("reimbursable");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(
            gridService.getContainTextFilterModal(
              routeFilterRef?.current?.expenseType === "all" ? "" : routeFilterRef?.current?.expenseType,
            ),
          );
        }
      }
      gridApi.onFilterChanged();
    }
  };
  const commonFilterStatus = (gridApi: GridApi, status: string) => {
    gridApi?.setFilterModel(null);

    if (gridApi) {
      const statusFilter = gridApi.getFilterInstance("status");

      if (statusFilter) {
        statusFilter.setModel(gridService.getContainTextFilterModal(status));
      }
      gridApi.onFilterChanged();
    }
  };
  const statusFilterHandler = (gridApi: GridApi, status: string) => {
    gridApi?.setFilterModel(null);

    if (gridApi) {
      if (status !== "72") {
        const statusFilter = gridApi.getFilterInstance("status");
        if (statusFilter) {
          statusFilter.setModel(gridService.getContainTextFilterModal(status));
        }
      }
      if (routeFilterRef?.current?.subsidiaryName) {
        const subsidiaryFilter = gridApi.getFilterInstance("subsidiary.name");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(gridService.getContainTextFilterModal(routeFilterRef?.current?.subsidiaryName));
        }
      }
      if (routeFilterRef?.current?.expenseType) {
        const subsidiaryFilter = gridApi.getFilterInstance("reimbursable");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(
            gridService.getContainTextFilterModal(
              routeFilterRef?.current?.expenseType === "all" ? "" : routeFilterRef?.current?.expenseType,
            ),
          );
        }
      }
      if (routeFilterRef?.current?.expenseType) {
        const subsidiaryFilter = gridApi.getFilterInstance("reimbursable");
        if (subsidiaryFilter) {
          subsidiaryFilter.setModel(
            gridService.getContainTextFilterModal(
              routeFilterRef?.current?.expenseType === "all" ? "" : routeFilterRef?.current?.expenseType,
            ),
          );
        }
      }
      if (routeFilterRef?.current?.dateAfter && routeFilterRef?.current?.dateBefore) {
        const dateFilter = gridApi.getFilterInstance("date");
        if (dateFilter) {
          const startDate = moment(routeFilterRef?.current?.dateAfter, "MM/DD/YYYY").format("YYYY-MM-DD HH:mm:ss");
          const endDate = moment(routeFilterRef?.current?.dateBefore, "MM/DD/YYYY").format("YYYY-MM-DD HH:mm:ss");
          dateFilter.setModel(gridService.getInRangeStringFilterModel(startDate, endDate));
        }
      }
      gridApi.onFilterChanged();
    }
  };

  const gridHeaders = useMemo(
    () =>
      getExpenseReportsListHeaders({
        gridApi,
        currentUser,
      }),
    [gridApi, currentUser],
  );

  const getExpenseReportStats = async () => {
    try {
      const result = await ExpenseReportApis.getStats();
      setStats(result);
    } catch (error) {}
  };

  useEffect(() => {
    const searchParams: URLSearchParams = new URLSearchParams(location.search);
    let filterObj: RouteFilterType = {};
    searchParams.forEach((value, key) => {
      filterObj[key] = value;
    });
    let routeFilter = filterObj?.status;
    if (gridApi) {
      if (gridService.getServerSideSelectedRows(gridApi).length) {
        gridApi.deselectAll();
      }
      applyTabFilter(gridApi);
    }
    if (searchParams) {
      hasRouteFilterRef.current = true;
      setParamsWithRoute(filterObj);
    }
  }, [gridApi, filterBtn]);

  const setParamsWithRoute = ({ status, subsidiaryName, expenseType, date_before, date_after }: RouteFilterType) => {
    if (subsidiaryName) {
      routeFilterRef.current.subsidiaryName = subsidiaryName;
    }
    if (expenseType) {
      routeFilterRef.current.expenseType = expenseType;
    }
    if (date_before) {
      routeFilterRef.current.dateBefore = date_before;
    }
    if (date_after) {
      routeFilterRef.current.dateAfter = date_after;
    }
    if (status) {
      let filterBtn: "UNSUBMITTED" | "PENDING_APPROVAL" | "ALL_REPORTS" = "UNSUBMITTED";
      switch (status) {
        case "requireApproval":
        case "pending":
          filterBtn = "PENDING_APPROVAL";
          break;
        case "open":
          filterBtn = "ALL_REPORTS";
          setFilterStatus("5");
          break;
        case "paid":
          filterBtn = "ALL_REPORTS";
          setFilterStatus("6");
          break;
        case "unpaid":
          filterBtn = "ALL_REPORTS";
          setFilterStatus("12");
          break;
        case "closed":
          filterBtn = "ALL_REPORTS";
          setFilterStatus("7");
          break;
        case "posted":
          filterBtn = "ALL_REPORTS";
          routeFilterRef.current.expenseType = "true";
          setFilterStatus("72");
          break;
        case "monthly_spend":
          filterBtn = "ALL_REPORTS";
          break;
        default:
          break;
      }

      if (filterBtn) {
        setFilterBtn((state) => filterBtn);
        history.replace({
          state: {},
        });
      }
    }
  };

  useEffect(() => {
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
    getExpenseReportStats();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      filter: true,
      floatingFilter: showFilters,
      filterParams: {
        suppressAndOrCondition: true,
      },
    };
  }, [showFilters]);

  const onPaginationChange = useCallback((e: PaginationChangedEvent) => {
    // ref: https://ag-grid.com/react-data-grid/row-selection
    const serverSideSelectionState = e.api.getServerSideSelectionState() as IServerSideSelectionState | null;
    if (
      serverSideSelectionState?.selectAll ||
      (serverSideSelectionState?.toggledNodes?.length && serverSideSelectionState?.toggledNodes?.length > 0)
    ) {
      e.api.deselectAll();
    }
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="mt-4">
          <Col>
            <NavTabs activePageName="Expense Reports" />
          </Col>
        </Row>
        <Row>
          <Col className={style.expenseReportHeaderMenu}>
            <div className={style.headerMenuItem}>
              {gridApi && <BulkAction gridApi={gridApi} />}
              <GridFilterDropdown
                gridApi={gridApi}
                gridColumnApi={gridColumnApi}
                gridStorageName={GRID_STORAGE_NAME}
                options={{
                  month: { dateName: "date" },
                  year: { dateName: "date" },
                  quarter: { dateName: "date" },
                  lastQuarter: { dateName: "date" },
                }}
                customFilter={
                  <>
                    <Dropdown.Item onClick={() => fltrNoVendor()}>No Vendor Specified</Dropdown.Item>
                    <Dropdown.Item onClick={() => fltrOutstanding()}>Outstanding</Dropdown.Item>
                  </>
                }
              />
            </div>

            <div className={style.headerMenuItem + "  d-flex justify-content-center"}>
              <ButtonGroup className={style.expenseReportStatusBtn}>
                <Button
                  variant="secondary"
                  onClick={() => setFilterBtn("UNSUBMITTED")}
                  className={`${style.btnDefault} ${filterBtn === "UNSUBMITTED" ? style.active : ""}`}
                >
                  Unsubmitted
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setFilterBtn("PENDING_APPROVAL")}
                  className={`${style.btnDefault} ${filterBtn === "PENDING_APPROVAL" ? style.active : ""}`}
                >
                  Pending Approval
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setFilterBtn("ALL_REPORTS")}
                  className={`${style.btnDefault} ${filterBtn === "ALL_REPORTS" ? style.active : ""}`}
                >
                  All Reports
                </Button>
              </ButtonGroup>
            </div>

            <div className={style.headerMenuItem + " d-flex justify-content-end"}>
              {gridApi && (
                <div className="mt-1">
                  <ExportDataButton
                    title={t("admin.pages.expenseItem.exportExpenses")}
                    csvName="expense_reports"
                    gridApi={gridApi}
                  />
                </div>
              )}
              <ImportDataButton title={t("admin.pages.expenseItem.importExpenseItems")} />
              <ToggleFilterButton
                clickCallback={() => {
                  updateShowFilters(!showFilters);
                }}
              />
            </div>
          </Col>
        </Row>

        <Row className="px-3 mt-3" style={{ height: "68vh" }}>
          <ServerSideDataGrid
            columnDefs={gridHeaders.columnDefs}
            defaultColDef={defaultColDef}
            onPaginationChanged={onPaginationChange}
            gridReady={gridReady}
            rowSelection="multiple"
            gridStorageName={GRID_STORAGE_NAME}
            domLayout="normal"
            paginationSize={25}
            paginationOptions={paginationOptions}
            pagination
          />
        </Row>
      </Container>
    </>
  );
};

export default ListExpenseReports;
