import React, { useEffect, useState } from "react";
import style from "./dashboard.module.css";
import { Col, Row } from "react-bootstrap";
import PaymentMethodStep1 from "./paymentMethodStep1";
import LinkPaymentMethodStep2 from "./linkPaymentMethodStep2";
import DocumentRequireStep3 from "./documentRequireStep3";
import AddContactStep5 from "./addContactStep5";
import CompanyDetailsStep4 from "./companyDetailsStep4";
import CustomModal from "../../../modals/customModal";
import { useTypedSelector } from "../../../../reducers";
import {
  getGuidePurchasers,
  getSetupProgress,
  resetVendorSetupGuide,
  setShowGuide,
} from "../../../../reducers/vp/vendorSetUpGuideReducer";
import { useDispatch } from "react-redux";

const GuideModal = () => {
  const dispatch = useDispatch();
  const showGuide = useTypedSelector((state) => state.vendorSetupGuideRed.showGuide);
  const guideStep = useTypedSelector((state) => state.vendorSetupGuideRed.guideStep);

  useEffect(() => {
    return () => {
      // reseting guide state
      dispatch(resetVendorSetupGuide());

      // need to refect purchanser as required for calculating profile completdness
      dispatch(getGuidePurchasers());
      // when ever user close guide modal regardless it will get updated status of profile completeness
      dispatch(getSetupProgress());
    };
  }, []);

  return (
    <CustomModal
      dailog={false}
      header={" "}
      show={showGuide}
      body={
        <>
          <Row>
            <Col xl="12">
              {guideStep === 1 && <PaymentMethodStep1 />}
              {guideStep === 2 && <LinkPaymentMethodStep2 />}
              {guideStep === 3 && <DocumentRequireStep3 />}
              {guideStep === 4 && <CompanyDetailsStep4 />}
              {guideStep === 5 && <AddContactStep5 />}
            </Col>
          </Row>
        </>
      }
      contentClassName={`${style.modalContent}`}
      modalBodyClass={`${style.welcomeModal}`}
      onHide={() => dispatch(setShowGuide(false))}
      size={"xl"}
    />
  );
};

export default GuideModal;
