import React, { useCallback, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectReceiptDetail, selectLinkedExpenseDetail, setLinkedExpeseDetails } from "reducers/admin/receiptsReducer";
import ExpensesApis from "services/admin/expenses/expensesApis";
import style from "./receipts.module.css";
import { companyDateFormat } from "services/general/dateSvc";
import _ from "lodash";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";

const ExpenseReceiptInfo = () => {
  const dispatch = useDispatch();
  const currentUser = useTypedSelector((state) => state.user);
  const receiptDetail = useTypedSelector(selectReceiptDetail);
  const linkedExpenseDetail = useTypedSelector(selectLinkedExpenseDetail);

  const getReceiptLinkedCardDetails = useCallback(async () => {
    try {
      if (receiptDetail?.documentable_id) {
        const result = await ExpensesApis.getExpense(receiptDetail?.documentable_id);
        dispatch(setLinkedExpeseDetails(result));
      }
    } catch (error) {}
  }, [dispatch, receiptDetail?.documentable_id]);

  useEffect(() => {
    getReceiptLinkedCardDetails();
  }, [getReceiptLinkedCardDetails]);

  return (
    <Row>
      <Col>
        <Row>
          <Col className={style.generalInfo}>
            <dl className="dl-horizontal">
              {linkedExpenseDetail?.employee?.id && (
                <div className={style.di}>
                  <dt>Employee</dt>
                  <dd>
                    {linkedExpenseDetail?.employee.first_name} {linkedExpenseDetail?.employee.last_name}
                  </dd>
                </div>
              )}

              {linkedExpenseDetail?.vendor_name && (
                <div className={style.di}>
                  {linkedExpenseDetail?.vendor_name && <dt>Merchant</dt>}
                  {linkedExpenseDetail?.vendor_name && <dd>{linkedExpenseDetail?.vendor_name}</dd>}
                </div>
              )}

              {linkedExpenseDetail?.date && (
                <div className={style.di}>
                  <dt>Transaction Date</dt>
                  <dd>{companyDateFormat(linkedExpenseDetail?.date, currentUser)}</dd>
                </div>
              )}

              {Boolean(linkedExpenseDetail?.base_currency_amount) && (
                <div className={style.di}>
                  <dt>Total Amount (including tax)</dt>
                  <dd>
                    {currencySymbolRenderer(linkedExpenseDetail?.base_currency_code) +
                    linkedExpenseDetail?.base_currency_total
                      ? linkedExpenseDetail?.base_currency_total?.toFixed(2)
                      : null}
                  </dd>
                </div>
              )}

              {Boolean(linkedExpenseDetail?.base_currency_tax) && (
                <div className={style.di}>
                  <dt>Tax Amount</dt>
                  <dd>
                    {currencySymbolRenderer(linkedExpenseDetail?.base_currency_code) +
                    linkedExpenseDetail?.base_currency_tax
                      ? linkedExpenseDetail?.base_currency_tax?.toFixed(2)
                      : null}
                  </dd>
                </div>
              )}

              {linkedExpenseDetail?.currency_code && (
                <div className={style.di}>
                  <dt>Currency</dt>
                  <dd>{linkedExpenseDetail?.currency_code}</dd>
                </div>
              )}

              {linkedExpenseDetail?.item_type && (
                <div className={style.di}>
                  <dt>Item Type</dt>
                  <dd>{linkedExpenseDetail?.item_type}</dd>
                </div>
              )}

              {linkedExpenseDetail?.policy?.name && (
                <div className={style.di}>
                  <dt>Policy</dt>
                  <dd>{linkedExpenseDetail?.policy?.name}</dd>
                </div>
              )}

              {linkedExpenseDetail?.subsidiary && (
                <div className={style.di}>
                  <dt>Subsidiary</dt>
                  <dd>{linkedExpenseDetail?.subsidiary?.name}</dd>
                </div>
              )}

              {linkedExpenseDetail?.department && (
                <div className={style.di}>
                  <dt>Department</dt>
                  <dd>{linkedExpenseDetail?.department?.name}</dd>
                </div>
              )}

              {linkedExpenseDetail?.category?.name && (
                <div className={style.di}>
                  <dt>Category</dt>
                  <dd>{linkedExpenseDetail?.category?.name}</dd>
                </div>
              )}

              {linkedExpenseDetail?.submitter?.name && (
                <div className={style.di}>
                  <dt>Submitter</dt>
                  <dd>{linkedExpenseDetail?.submitter?.name}</dd>
                </div>
              )}

              {linkedExpenseDetail?.submit_date && (
                <div className={style.di}>
                  <dt>Submitted On Date</dt>
                  <dd>{companyDateFormat(linkedExpenseDetail?.submit_date, currentUser)}</dd>
                </div>
              )}

              {Boolean(linkedExpenseDetail?.tax) && (
                <div className={style.di}>
                  <dt>Tax</dt>
                  <dd>
                    {currencySymbolRenderer(linkedExpenseDetail?.currency_code) + linkedExpenseDetail?.tax
                      ? linkedExpenseDetail?.tax?.toFixed(2)
                      : null}
                  </dd>
                </div>
              )}

              {linkedExpenseDetail?.tax_code?.id && (
                <div className={style.di}>
                  <dt>Tax Code</dt>
                  <dd>
                    {linkedExpenseDetail?.tax_code.name} {linkedExpenseDetail?.tax_code.rate}%
                  </dd>
                </div>
              )}

              {linkedExpenseDetail?.total && (
                <div className={style.di}>
                  <dt>Total</dt>
                  <dd>
                    {currencySymbolRenderer(linkedExpenseDetail.currency_code) + linkedExpenseDetail?.total
                      ? linkedExpenseDetail?.total?.toFixed(2)
                      : null}
                  </dd>
                </div>
              )}

              {linkedExpenseDetail?.purchase_order && (
                <div className={style.di}>
                  <dt>PO#s</dt>
                  <dd className="link">{linkedExpenseDetail?.purchase_order.number}</dd>
                </div>
              )}

              {linkedExpenseDetail?.description && (
                <div className={style.di}>
                  <dt>Description</dt>
                  <dd>{linkedExpenseDetail?.description}</dd>
                </div>
              )}

              {linkedExpenseDetail?.business_unit && (
                <div className={style.di}>
                  <dt>Business Unit</dt>
                  <dd>{linkedExpenseDetail?.business_unit.name}</dd>
                </div>
              )}

              {linkedExpenseDetail?.location && (
                <div className={style.di}>
                  <dt>Location</dt>
                  <dd>{linkedExpenseDetail?.location.name}</dd>
                </div>
              )}

              {linkedExpenseDetail?.project?.id && (
                <div className={style.di}>
                  <dt>Project</dt>
                  <dd>{linkedExpenseDetail?.project.name}</dd>
                </div>
              )}

              {Boolean(linkedExpenseDetail?.category?.account) && (
                <div className={style.di}>
                  <dt>GL Impact</dt>
                  <dd>
                    {linkedExpenseDetail?.category?.account?.number} - {linkedExpenseDetail?.category?.account?.name}
                  </dd>
                </div>
              )}

              {linkedExpenseDetail?.submitter?.department_name && (
                <div className={style.di}>
                  <dt>Submitter Dept.</dt>
                  <dd>{linkedExpenseDetail?.submitter.department_name}</dd>
                </div>
              )}

              <div className={style.di}>
                <dt>File Name</dt>
                <dd>
                  {(_.isArray(linkedExpenseDetail?.assets) &&
                    linkedExpenseDetail?.assets[0] &&
                    linkedExpenseDetail?.assets[0]?.asset_file_file_name) ??
                    "N/A"}
                </dd>
              </div>
            </dl>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className={`d-flex justify-content-start ${style.checkBoxGroup}`}>
            <Form.Check label="Billable" checked={linkedExpenseDetail?.billable ?? false} readOnly className="mr-4" />
            {/* need some advise on this from some of the react guys */}
            <Form.Check label="Not-reimbursable" checked={linkedExpenseDetail?.reimbursable ?? false} readOnly />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ExpenseReceiptInfo;
