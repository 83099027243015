import { AxiosResponse } from "axios";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import usePermission from "components/common/hooks/usePermission";
import { StepType } from "components/common/stepper";
import _, { cloneDeep } from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTypedSelector } from "reducers";
import { getVendorDocumentLinks } from "reducers/vp/companyProfileReducer";
import { change } from "redux-form";
import { formattedCustomFields } from "services/admin/vendors/vendorSvc";
import { ContactObjType, CurrencyType, VendorDetailType, VendorType } from "services/admin/vendors/vendorTypes";
import { VendorApis } from "services/admin/vendors/vensorApis";
import documentService from "services/common/documents/documentSvc";
import { DocumentTypes } from "services/common/documents/documentTypes";
import vendorDocumentLinkService from "services/common/documentVendorLinks/vendorDocumentLinkSvc";
import paymentTypeService from "services/common/paymentMethod/paymentTypeSvc";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { Metadatable } from "wombatifier/components/metadata_field/metadatable";
import { MetadataTemplateApis } from "wombatifier/services/metadata/metadataTemplateApis";
import FormVendor from "./form";

const AddVendor = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const [initialFormValues, setInitialFormValues] = useState<{ [key: string]: any }>({});
  const [isVendorLoad, setVendorLoad] = useState(false);
  const [showPaymentMethodSection] = useState({
    isNvpCustomer: currentUser?.company?.accepted_payment_methods?.corpay,
    isSkipped: false,
  });
  const [showAdditionalCodingStepper, setShowAdditionalCodingStepper] = useState(false);
  const { hasUserPermission } = usePermission();
  const { isMetadataAvailable, handleMetadataForm } = Metadatable({ modules: "Vendor" });

  const handleAddVendor = (master?: VendorType) => {
    if (!master) return;
    const { id, name, country, url, address1, address2, address3, zipcode, email, city, state } = master;
    const obj: VendorType = {
      master_id: id || undefined,
      name: name,
      address: {
        country,
        address1,
        address2,
        address3,
        zipcode,
        ...(city && { city }), // Add city if it exists
        ...(state && { state }), // Add state if it exists
      },
      url,
      email,
    };
    setInitialFormValues((prevValues) => ({ ...prevValues, ...obj }));
  };

  const checkIsUsingMetadata = useCallback(async () => {
    try {
      const res = await MetadataTemplateApis.list();
      const codingFieldExist = res.some((item) => item.status === "ACTIVE" && item.modules.includes("Vendor"));
      setShowAdditionalCodingStepper(codingFieldExist);
    } catch (err) {
      setShowAdditionalCodingStepper(false);
    }
  }, []);

  let stepperStepsData: StepType[] = [
    { value: "vendorDetails", label: "Vendor Details", stepCount: 1, completed: false },
    { value: "vendorAdditionalDetails", label: "Additional Details", stepCount: 2, completed: false },
    { value: "vendorAdditionalCodingFields", label: "Additional Coding Fields", stepCount: 3, completed: false },
    {
      value: "vendorContact",
      label: "Add Vendor Contact",
      stepCount: !showAdditionalCodingStepper ? 3 : 4,
      completed: false,
    },
    {
      value: "documentsRequested",
      label: "Documents Requested",
      stepCount:
        !showPaymentMethodSection.isNvpCustomer || showPaymentMethodSection.isSkipped || showAdditionalCodingStepper
          ? 6
          : 5,
      completed: false,
    },
    {
      value: "finalReview",
      label: "Final Review",
      stepCount:
        !showPaymentMethodSection.isNvpCustomer || showPaymentMethodSection.isSkipped || showAdditionalCodingStepper
          ? 7
          : 6,
      completed: false,
    },
  ];

  if (!showAdditionalCodingStepper) {
    stepperStepsData = stepperStepsData.filter((step) => step.label !== "Additional Coding Fields");
  }

  if (!showPaymentMethodSection.isNvpCustomer) {
    stepperStepsData.push({
      value: "paymentMethod",
      label: "Add Payment Method",
      stepCount: showAdditionalCodingStepper ? 5 : 4,
      completed: false,
    });
  }

  const stepperJson = stepperStepsData
    .slice()
    .sort((a: StepType, b: StepType) => (a?.stepCount || 0) - (b?.stepCount || 0));

  const getDefaultSubsidiary = async () => {
    try {
      const companyResponse: AxiosResponse = await restApiService.get("company");
      return companyResponse.data?.subsidiary;
    } catch (error) {
      console.log(error);
    }
  };

  const getNewVendor = async () => {
    try {
      const getResponse = await VendorApis.getNewVendor();

      if (!getResponse) {
        return; // Exit early if the response is falsy
      }

      // Set the vendor_type and address_type unconditionally
      getResponse.vendor_type = "COMPANY";
      getResponse.address_type = "Billing";

      if (currentUser?.company?.vendor?.po_not_required?.show_selected) {
        getResponse.po_not_required = true;
      }

      // Assigning default subsidiary and currencies
      const defaultSubsidiary = await getDefaultSubsidiary();

      if (defaultSubsidiary) {
        getResponse.subsidiary_id = defaultSubsidiary.id;
        getResponse.subsidiary = defaultSubsidiary.name;

        if (defaultSubsidiary.currency_code) {
          getResponse.currency_code = defaultSubsidiary.currency_code;
          getResponse.additional_currency_codes = [
            { label: defaultSubsidiary.currency_code, value: defaultSubsidiary.currency_code, primary: true },
          ];
        }
        dispatch(change("VendorForm", "subsidiary", defaultSubsidiary.name));
      } else {
        //  If the default subsidiary is unavailable, we use currency codes from the new 'vendor' API
        //  to show currency selected.
        if (_.isArray(getResponse?.currency_codes) && getResponse?.currency_codes.length > 0) {
          getResponse.additional_currency_codes = [
            {
              label: getResponse?.currency_codes[0].code,
              value: getResponse.currency_codes[0].code,
              primary: getResponse.currency_codes[0].primary,
            },
          ];
        }
      }

      setVendorLoad(!!getResponse.vendor_id);

      if (hasUserPermission("requestVendors")) {
        getResponse.status = "PENDING";
      }

      setInitialFormValues(getResponse);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNewVendor();
    checkIsUsingMetadata();
  }, []);

  const handleSetPrimaryCurrency = (vendorProp: any) => {
    return vendorProp.additional_currency_codes?.map((currency: CurrencyType) => ({
      ...currency,
      primary: vendorProp.currency_code === currency.value,
    }));
  };

  const manipulateVendorPaymentMethodData = (vendorProp: VendorDetailType) => {
    vendorProp.payment_methods_attributes = vendorProp.payment_methods_attributes.map(
      (paymentMethodsAttribute: any) => {
        if (paymentTypeService.isCambridge(paymentMethodsAttribute.payment_type)) {
          const { cambridge, payment_type, is_primary } = paymentMethodsAttribute;
          const {
            accountHolderName,
            accountNumber,
            bankAddressLine1,
            bankCity,
            bankCountry,
            bankName,
            swiftBicCode,
            destinationCountry,
            routingCode,
            accountHolderCountry,
          } = cambridge;

          return {
            account_name: accountHolderName,
            account_number: accountNumber,
            bank_name: bankName,
            bic: swiftBicCode,
            currency_code: paymentMethodsAttribute.currency_code,
            routing: routingCode,
            payment_type,
            is_primary,
            status: "ACTIVE",
            address_attributes: {
              address1: bankAddressLine1,
              city: bankCity,
              country: bankCountry?.code2,
            },
            cambridge: {
              ...cambridge,
              destinationCountry: destinationCountry?.code2,
              bank_country: bankCountry,
              bankCountry: bankCountry?.code2,
              accountHolderCountry: accountHolderCountry?.code2,
            },
          };
        } else if (paymentTypeService.isPayoneer(paymentMethodsAttribute.payment_type)) {
          paymentMethodsAttribute.program_id = paymentMethodsAttribute.programId;
          return paymentMethodsAttribute;
        } else {
          // If the payment_type is not "cambridge" or "payoneer", return the original object
          return paymentMethodsAttribute;
        }
      },
    );
  };

  const manipulateVendorData = (vendorProp: VendorDetailType) => {
    vendorProp.name = vendorProp.name?.trim();

    const additionalCurrencyCodes = handleSetPrimaryCurrency(vendorProp);

    vendorProp.currency_codes = additionalCurrencyCodes.map((currency: CurrencyType) => ({
      code: currency.value,
      label: currency.value,
      primary: currency.primary,
    }));

    if (_.isArray(vendorProp.payment_methods_attributes) && vendorProp.payment_methods_attributes.length > 0) {
      manipulateVendorPaymentMethodData(vendorProp);
    }
    if (vendorProp?.custom_fields) {
      vendorProp.custom_fields = formattedCustomFields(vendorProp.custom_fields);
    }
  };

  const createNewContact = async (contact: ContactObjType, vendorId: IDType) => {
    const contactPayload = {
      contact: {
        first_name: contact.first_name,
        last_name: contact.last_name,
        email: contact.email,
        status: "ACTIVE",
        vendor_id: vendorId,
        phone: contact.phone,
      },
    };
    try {
      await VendorApis.addContact(contactPayload);
    } catch (error: any) {
      const { response } = error;
      if (response.status === 422) {
        CreateNotification("Validation Error", "Please enter valid data", NotificationType.danger);
      }
    }
  };

  const uploadAttachments = async (assetsAttributes: any, vendorId: IDType) => {
    for (const asset of assetsAttributes) {
      if (asset.document_type) {
        try {
          let document: DocumentTypes.AddResponse | undefined = await documentService.onSubmitDocumentUploadModal({
            ...asset,
            documentableId: vendorId,
            documentableType: "Vendor",
          });

          if (vendorId && document && document.id) {
            await vendorDocumentLinkService.linkDocumentWithVendorDocumentRequirement(
              {
                document,
                documentRequirement: asset.documentRequested,
                vendorId: vendorId,
              },
              "document_vendor_links",
            );
            await dispatch(getVendorDocumentLinks());
          }
        } catch (error) {
          CreateNotification("Error", { error }, NotificationType.danger);
        }
      }
    }
    try {
      const formData = new FormData();
      assetsAttributes.forEach((asset: any, key: number) => {
        if (!asset.document_type) {
          formData.append(`vendor[assets_attributes[${key}][asset_file]]`, asset.asset_file);
          formData.append(`vendor[assets_attributes[${key}][file_name]]`, asset.file_name);
        }
      });
      await VendorApis.uploadDocuments(formData, vendorId);
    } catch (error: any) {
      const { response } = error;
      if (response.status === 422) {
        CreateNotification("Document Upload Failed", "Please enter valid data", NotificationType.danger);
      }
    }
  };

  const handleSubmit = async (vendorProp: any) => {
    // We have two sections where the submit button is used: 'Add Payment Method' and 'Final Review'.
    // The isCompletedVendorForm becomes true when the user clicks the submit button from the 'Final Review' section.
    // If isCompletedVendorForm is true, it indicates that the form has completed all steps and has been successfully submitted.
    // If isCompletedVendorForm is undefined, it means the button was clicked in the 'Add Payment Method' section,
    // and it's used to validate Payment Method form values but is not ready to submit yet. However, the user can proceed to the next step.
    let formValues = cloneDeep(vendorProp);
    handleMetadataForm(formValues);

    if (!formValues.isCompletedVendorForm) {
      dispatch(change("VendorForm", "moveToNextStep", true));
      return false;
    }

    if (formValues.skipPaymentMethodStep) {
      delete formValues.payment_methods_attributes;
    }

    if (formValues.skipVendorContactStep) {
      delete formValues.contact;
    }
    let assetsAttributes = [];
    await manipulateVendorData(formValues);

    if (_.isArray(formValues.assets_attributes) && formValues.assets_attributes.length > 0) {
      assetsAttributes = formValues.assets_attributes;
      delete formValues.assets_attributes;
    }

    const payload = {
      vendor: formValues,
    };

    try {
      const response = await VendorApis.addVendor(payload);
      if (response?.id) {
        CreateNotification("Success", "Vendor Successfully Generated: " + response.name, NotificationType.success);
        if (_.isObject(formValues.contact)) {
          await createNewContact(formValues.contact, response.id);
        }

        if (_.isArray(assetsAttributes) && assetsAttributes.length > 0) {
          await uploadAttachments(assetsAttributes, response.id);
        }
        await history.push("/ap/vendors");
      }
    } catch (error: any) {
      const { response } = error;
      if (response && response.status === 422 && response.data?.name) {
        const errorMessage = ` ${formValues?.name} ${response.data.name[0]}`;
        CreateNotification("Not Allowed", errorMessage, NotificationType.danger);
      } else {
        console.log("response: ", response);
        CreateNotification("Validation Error", "Please enter valid data", NotificationType.danger);
      }
    }
  };
  return (
    <ErrorBoundary>
      {isVendorLoad && (
        <FormVendor
          stepperStepsData={stepperJson}
          onSubmit={handleSubmit}
          showSearchVendor={true}
          currentUser={currentUser}
          initialValues={initialFormValues}
          combineMasterDetailWithVendor={handleAddVendor}
          showPaymentMethodSection={showPaymentMethodSection}
          showAdditionalCodingSection={isMetadataAvailable}
          showAdditionalCodingField={showAdditionalCodingStepper}
        />
      )}
    </ErrorBoundary>
  );
};
export default AddVendor;
