import moment from "moment";
// Added to set the relative time  rounding functionality of moment library
export const setMomentRelativeTime = () => {
  moment.relativeTimeRounding(Math.floor);
  moment.relativeTimeThreshold("s", 60);
  moment.relativeTimeThreshold("m", 60);
  moment.relativeTimeThreshold("h", 24);
  moment.relativeTimeThreshold("d", 31);
  moment.relativeTimeThreshold("M", 12);
};
// Using this we can compare the difference between other date/time with time now
export const checkDateFromNow = (date) => {
  setMomentRelativeTime();
  return moment(date).fromNow();
};

export const formatDateSvc = (filterLocalDateAtMidnight, cellValue) => {
  let dateAsString = moment(cellValue).format("DD/MM/YYYY");
  if (dateAsString == null) return -1;
  let dateParts = dateAsString.split("/");
  let cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }
  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
};

export const getYearAndMonthOptions = (yearsBefore, yearsAfter, valueFormat, required, emptyOption) => {
  const currentYear = moment().year();

  const startYear = currentYear;
  const endYear = currentYear + yearsAfter;

  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  const months = moment.monthsShort();
  const options = years.flatMap((year) => {
    return months.map((month, monthIndex) => {
      const label = `${month} ${year}`;
      return {
        label: label,
        value: moment(label, "MMM YYYY").format(valueFormat),
      };
    });
  });

  if (!required) {
    options.unshift({
      label: emptyOption,
      value: "",
    });
  }
  return options;
};
