import React from "react";
import { translate } from "../../../../services/general/translation";
import moment from "moment";
import { formattedAmount } from "../../../../services/general/helpers";
import OnOrOffDropdown from "./on&offDropdown";
import { formatDateSvc } from "../../../../services/date/date";
import TooltipRender from "../../../toolTip/tooltipRender";
import { RecurringInvoiceListType } from "../../../../services/admin/invoices/recurringInvoice/recurringInvoiceTypes";
import { companyDateFormat } from "../../../../services/general/dateSvc";
import { IUser } from "../../../../services/common/user/userTypes";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";
type DeleteRecurringInvoiceType = (recurringInvoice: RecurringInvoiceListType) => void;

const getAmount = (params: any) => {
  return (
    currencySymbolRenderer(params?.data?.object_properties?.currency_code) + params?.data?.object_properties?.amount
  );
};

const stringSortingComparator = (valueA: string, valueB: string) => {
  const a = valueA ? valueA.toLowerCase() : "";
  const b = valueB ? valueB.toLowerCase() : "";
  if (a === b) return 0;
  return a > b ? 1 : -1;
};

const currencySortingComparator = (valueA: String, valueB: string) => {
  let currencySymbolRegix = /[^0-9\.-]+/g;
  let valueAWithoutSymbol = Number(valueA.replace(currencySymbolRegix, ""));
  let valueBWithoutSymbol = Number(valueB.replace(currencySymbolRegix, ""));
  return valueAWithoutSymbol - valueBWithoutSymbol;
};

const currencyComparison = (operator: string, filterValue1: any, cellValue: any, regix: RegExp) => {
  let newCellValue = Number(cellValue.replace(regix, ""));
  let newFilterValue1 = Number(filterValue1);

  if (isNaN(filterValue1)) {
    const cellCurrencySymbol = cellValue.match(regix);
    const filterValueCurrencySymbol = filterValue1.match(regix);
    const filterValueWithoutSymbol = Number(filterValue1.replace(regix, ""));

    if (cellCurrencySymbol[0] === filterValueCurrencySymbol[0]) {
      return operator === "lessThan"
        ? filterValueWithoutSymbol > newCellValue
        : filterValueWithoutSymbol < newCellValue;
    }
  } else {
    return operator === "lessThan" ? newFilterValue1 > newCellValue : newFilterValue1 < newCellValue;
  }
};

const amountFilterOprations = (type: string) => {
  let currencySymbolRegix = /[^0-9\.-]+/g;
  return ([filterValue1, filterValue2]: any, cellValue: any) => {
    let newCellValue = Number(cellValue.replace(currencySymbolRegix, ""));
    let newFilterValue1 = Number(filterValue1);
    let newFilterValue2 = Number(filterValue2);
    let cellValueWithoutSymbol = cellValue.replace(currencySymbolRegix, "");

    if (type == "equal") {
      return isNaN(filterValue1) ? filterValue1 == cellValue : filterValue1 == cellValueWithoutSymbol;
    }

    if (type === "lessThan" || type === "greaterThan") {
      return currencyComparison(type, filterValue1, cellValue, currencySymbolRegix);
    }
    if (type === "inRange") {
      if (isNaN(filterValue1) && isNaN(filterValue2)) {
        const cellCurrencySymbol = cellValue.match(currencySymbolRegix, "");
        const filterValue1CurrencySymbol = filterValue1.match(currencySymbolRegix, "");
        const filterValue2CurrencySymbol = filterValue2.match(currencySymbolRegix, "");
        const filterValue1WithoutSymbol = Number(filterValue1.replace(currencySymbolRegix, ""));
        const filterValue2WithoutSymbol = Number(filterValue2.replace(currencySymbolRegix, ""));
        return (
          cellCurrencySymbol[0] === filterValue1CurrencySymbol[0] &&
          cellCurrencySymbol[0] === filterValue2CurrencySymbol[0] &&
          filterValue1WithoutSymbol <= newCellValue &&
          newCellValue <= filterValue2WithoutSymbol
        );
      } else {
        return newFilterValue1 <= newCellValue && newCellValue <= newFilterValue2;
      }
    }
  };
};

const recurringInvoiceHeader = (
  deleteRecurringInvoice: DeleteRecurringInvoiceType,
  currentUser: IUser,
  length: number,
) => {
  const isGridFull = length * 47 >= window.innerHeight * 0.7; //.70vh is agGrid default height for "auto" domLayout option
  return [
    {
      field: "schedule.is_active",
      cellRenderer: (params: any) => {
        let dropUp = false;
        if (params.node.rowIndex === length - 1 && isGridFull) {
          // if last row and grid is full
          dropUp = true;
        }
        return <OnOrOffDropdown params={params} dropUp={dropUp} />;
      },
      filter: "agNumberColumnFilter",
      cellStyle: { overflow: "visible" },
      maxWidth: 150,
      filterParams: {
        filterOptions: [
          {
            displayKey: "All",
            displayName: "All",
            predicate: ([filterValue]: any, cellValue: any) => true,
            numberOfInputs: 0,
          },
          {
            displayKey: "true",
            displayName: "On",
            predicate: ([filterValue]: any, cellValue: any) => cellValue,
            numberOfInputs: 0,
          },
          {
            displayKey: "false",
            displayName: "Off",
            predicate: ([filterValue]: any, cellValue: any) => !cellValue,
            numberOfInputs: 0,
          },
        ],
        suppressAndOrCondition: true,
      },
      headerComponent: (params: any) => (
        <>
          {translate("on&off")}
          <TooltipRender
            title="Use the dropdown menu to turn recurring invoices on or off. Any
    invoices that already exist will still be available to view
    but future invoices will not be generated from this object."
            className="icon icon-info-blue mt-3 mx-1"
            placement="top"
          />
        </>
      ),
    },
    {
      field: "id",
      headerName: "ID",
      hide: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("id");
      },
    },
    {
      field: "vendor.name",
      headerName: "Vendor",
      cellRenderer: "childCellRouting",
      cellRendererParams: {
        redirectToAngular: true,
      },
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("vendor");
      },
      comparator: stringSortingComparator,
    },
    {
      field: "vendor_location.name",
      headerName: "Vendor Address",
      filter: "agTextColumnFilter",
      sortable: true,
      hide: true,
      comparator: stringSortingComparator,
    },
    {
      field: "name",
      filter: "agTextColumnFilter",
      cellRenderer: "childCellRouting",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("name");
      },
      comparator: stringSortingComparator,
    },
    {
      field: "schedule.interval",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("frequency");
      },
    },
    {
      field: "invoices_count",
      filter: "agNumberColumnFilter",
      headerName: "# Invoices Created",
      sortable: true,
      cellRenderer: "CurrencyCellRenderer",
      headerValueGetter: function () {
        return translate("invoices_count");
      },
    },
    {
      field: "schedule.next_runtime",
      filter: "agDateColumnFilter",
      cellRenderer: (data: any) => companyDateFormat(data.value, currentUser),
      filterParams: {
        filterOptions: [
          {
            displayKey: "equals",
            displayName: "Equals",
            predicate: ([filterValue]: Date[], cellValue: Date) => {
              if (!cellValue) {
                return false;
              }
              let newCellValue = moment(cellValue).startOf("day");
              let newFilterValue = moment(filterValue).startOf("day");
              return moment(newCellValue).isSame(newFilterValue);
            },
          },
          {
            displayKey: "betweenInclusive",
            displayName: "Between (Inclusive)",
            predicate: ([fv1, fv2]: any, cellValue: any) => {
              if (!cellValue) {
                return false;
              }
              let newCellValue = moment(cellValue).endOf("day");
              let start = moment(fv1);
              let end = moment(fv2).endOf("day");
              let result = newCellValue.isSameOrAfter(start) && newCellValue.isSameOrBefore(end);
              return result;
            },
            numberOfInputs: 2,
          },
          {
            displayKey: "dateBefore",
            displayName: "Date Before",
            predicate: ([filterValue]: any, cellValue: any) => {
              if (!cellValue) {
                return false;
              }
              let newCellValue = moment(cellValue);
              let newFilterValue = moment(filterValue);
              return newCellValue < newFilterValue;
            },
          },
          {
            displayKey: "dateAfter",
            displayName: "Date After",
            predicate: ([filterValue]: any, cellValue: any) => {
              if (!cellValue) {
                return false;
              }
              let newCellValue = moment(cellValue).endOf("day");
              let newFilterValue = moment(filterValue).endOf("day");
              return newCellValue > newFilterValue;
            },
          },
        ],
        comparator: function (filterLocalDateAtMidnight: Date, cellValue: string) {
          return formatDateSvc(filterLocalDateAtMidnight, companyDateFormat(cellValue, currentUser));
        },
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("next_creation_date");
      },
    },
    {
      field: "schedule.end_date",
      filter: "agDateColumnFilter",
      cellRenderer: (data: any) => companyDateFormat(data.value, currentUser),
      filterParams: {
        comparator: function (filterLocalDateAtMidnight: Date, cellValue: string) {
          return formatDateSvc(filterLocalDateAtMidnight, cellValue);
        },
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("end_date");
      },
    },
    {
      field: "object_properties.amount",
      headerName: "Amount",
      comparator: currencySortingComparator,
      cellRenderer: (params: any) => formattedAmount(params.value, params.data?.object_properties?.currency_code),
      filter: "agTextColumnFilter",
      filterParams: {
        allowedCharPattern: "\\d.$",
        filterOptions: [
          {
            displayKey: "contains",
            displayName: "Contains",
            predicate: ([filterValue]: any, cellValue: any) => cellValue.toString().includes(filterValue.toString()),
          },
          {
            displayKey: "equals",
            displayName: "Equal",
            predicate: amountFilterOprations("equal"),
          },
          {
            displayKey: "lessThan",
            displayName: "Less Than",
            predicate: amountFilterOprations("lessThan"),
          },
          {
            displayKey: "greaterThan",
            displayName: "Greater Than",
            predicate: amountFilterOprations("greaterThan"),
          },
          {
            displayKey: "inRange",
            displayName: "In Range",
            predicate: amountFilterOprations("inRange"),
            numberOfInputs: 2,
          },
        ],
        suppressAndOrCondition: true,
      },
      valueGetter: getAmount,
      headerValueGetter: function () {
        return translate("amount_only");
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      cellRenderer: "childMessageRenderer",
      sortable: false,
      floatingFilter: false,
      resizable: false,
      width: 150,
      maxWidth: 150,
      cellRendererSelector: function (params: any) {
        return;
      },
      cellRendererParams: {
        viewLink: "/ap/recurring_invoices/",
        angularEditLink: "recurring_invoices/",
        editAuthorization: {
          I: "edit",
          a: "Invoices",
        },
        allowDelete: true,
        deleteCallback: deleteRecurringInvoice,
        deleteAuthorization: {
          I: "delete",
          a: "Invoices",
        },
      },
      headerValueGetter: function () {
        return translate("actions");
      },
    },
  ];
};
export default recurringInvoiceHeader;
