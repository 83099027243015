import React from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import {
  emailDetailSetter,
  refetchEmail,
  searchStateResetter,
  searchStateSetter,
  setIsSearching,
  showDetailPageSetter,
} from "../../../../reducers/admin/emailReducer";
import { searchStateType } from "../../../../services/admin/invoices/emailSvc";
import { AgGridReact } from "ag-grid-react";

export type advanceEmailSearchType = {
  gridRef: React.RefObject<AgGridReact>;
};

const AdvanceEmailSearchBar = ({ gridRef }: advanceEmailSearchType) => {
  const dispatch = useDispatch();
  const searchState: searchStateType = useSelector((state: RootState) => state.emailRed.searchState);

  const resetEmailDetailPage = () => {
    dispatch(showDetailPageSetter(false));
    dispatch(emailDetailSetter(null));
  };

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(setIsSearching(true));
    resetEmailDetailPage();
    dispatch(refetchEmail());
  };

  const clearSearch = () => {
    dispatch(searchStateResetter());
    dispatch(setIsSearching(false));
    resetEmailDetailPage();
    dispatch(refetchEmail());
  };

  const handleSearchChange = (e: any) => {
    const { name, value }: { name: string; value: string } = e.target;
    const newSearchState = { ...searchState };
    newSearchState[name] = value;
    dispatch(searchStateSetter(newSearchState));
  };

  const emailFilters = [
    { label: "Subject", name: "subject", type: "text" },
    { label: "Body", name: "body", type: "text" },
    { label: "From", name: "from", type: "text" },
    { label: "Message Id", name: "message_id", type: "text" },
  ];

  const emailStatusList = [
    { label: "Select Status", value: "" },
    { label: "Processed", value: "Processed" },
    { label: "New", value: "New" },
    { label: "Failed", value: "Failed" },
    { label: "Completed", value: "Completed" },
    { label: "Sent", value: "Sent" },
    { label: "Deleted", value: "Deleted" },
  ];

  return (
    <div className="border-box">
      <Form onSubmit={submit} className={"mt-3 mb-3 px-3 w-100"}>
        <div className="d-flex flex-wrap">
          <div className="mr-2">
            <label className={"emailLabel"}>Start Date</label>
            <br />
            <Form.Control
              type="date"
              name="start_date"
              onChange={(e) => handleSearchChange(e)}
              value={searchState.start_date}
            />
          </div>

          <div className="mr-2">
            <label className={"emailLabel"}>End Date</label>
            <br />
            <Form.Control
              type="date"
              name="end_date"
              onChange={(e) => handleSearchChange(e)}
              value={searchState.end_date}
            />
          </div>

          <div className="mr-2">
            <label className={"emailLabel"}>Status</label>
            <br />
            <Form.Control
              as={"select"}
              name="status"
              value={searchState.status}
              onChange={(e) => handleSearchChange(e)}
              className={"email picker"}
            >
              {emailStatusList.map((obj, index) => {
                return (
                  <option key={obj.value} value={obj.value}>
                    {obj.label}
                  </option>
                );
              })}
            </Form.Control>
          </div>

          {emailFilters.map((item, index) => {
            return (
              <div key={item.name} className="mr-2">
                <label className={"emailLabel"}>{item.label}</label>
                <br />
                <Form.Control
                  as={"input"}
                  name={item.name}
                  value={searchState[item.name]}
                  type={item.type}
                  className={"email"}
                  onChange={(e) => handleSearchChange(e)}
                />
              </div>
            );
          })}

          <div className="d-flex mr-2 pt-4">
            <Button onClick={() => clearSearch()} className="mr-1">
              Clear
            </Button>
            <Button type="submit">Search</Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AdvanceEmailSearchBar;
