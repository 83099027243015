import React from "react";
import { shallowEqual } from "react-redux";
import { useTypedSelector } from "reducers";
import { INTEGRATION_FORM_NAME } from "reducers/admin/integrationsReducer";
import { getFormValues } from "redux-form";
import { WombatSettings } from "wombatifier/components/wombatifier/wombatSettings";

const IntegrationFileIntegrationIngest = () => {
  const wombatSettings = useTypedSelector((state) => {
    const integrationState = getFormValues(INTEGRATION_FORM_NAME)(state);
    return integrationState?.form?.wombat_settings || [];
  }, shallowEqual);
  return (
    <>
      <WombatSettings settings={wombatSettings} />
    </>
  );
};

export { IntegrationFileIntegrationIngest };
