import Panel from "components/common/panel/panel";
import PdfPreviewViewer from "components/common/pdf/pdfPreviewViewer";
import PDFThumbnailCarousel from "components/common/pdf/pdfThumbnailCarousel";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import { PDFDocument } from "pdf-lib";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { FaRegSquareMinus, FaWindowRestore } from "react-icons/fa6";
import { CreateNotification, NotificationType } from "services/general/notifications";
import styles from "./fileUploadSection.module.css";
import { usePdfRenderingContext } from "./pdfRenderingContext";
import { useQueueManagementContext } from "./queueManagementContext";
import { useUiStateContext } from "./uiStateContext";
import {
  createExtractedFileAsync,
  deleteAndAttachFileToFormDataAsync,
  getNewPdfFromSelectedPages,
  handleError,
  stopEventPropagation,
} from "./uploadQueueService";

type FileUploadSectionProps = {
  saveAsyncCallback: (notify: boolean) => Promise<void>;
};

const FileUploadSection: React.FC<FileUploadSectionProps> = ({ saveAsyncCallback }) => {
  const { t } = useTranslation();
  const [showThumbnails, setShowThumbnails] = useState(true);
  const {
    filteredInvoices,
    selectedQueueInvoice,
    updateSelectedInvoiceAsync,
    refetchCurrentInvoiceQuery,
    isFetching,
    refetchInvoiceList,
  } = useQueueManagementContext();
  const { pageNumber, setPageNumber, numPages, setNumPages, pdfFileToRender, invalidateCurrentInvoicePdfCache } =
    usePdfRenderingContext();
  const { checkboxValues, setIsOperationRunning, setCheckboxValues, isOperationRunning } = useUiStateContext();
  const { createConfirmModal } = useConfirmModal();
  const { pdfDocumentToRender, isMainDocumentLoaded, setIsMainDocumentLoaded, resetPdfContext } =
    usePdfRenderingContext();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  const onPageLoadSuccess = (): void => {
    setIsMainDocumentLoaded(true);
  };

  const updateInvoiceAsync = useCallback(
    async (id: number | undefined): Promise<void> => {
      if (!id) return undefined;

      resetPdfContext();

      await updateSelectedInvoiceAsync(id);
    },
    [resetPdfContext, updateSelectedInvoiceAsync],
  );

  const downloadPdf = useCallback(
    async (event?: React.MouseEvent) => {
      stopEventPropagation(event);

      let link;
      let objectUrl;

      try {
        if (!pdfDocumentToRender) return;

        const pdfBytes = await pdfDocumentToRender.save();
        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        link = document.createElement("a");
        objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = selectedQueueInvoice?.filename ?? "invoice.pdf";
        document.body.appendChild(link);

        link.click();
      } catch (err) {
        console.error(err);
        CreateNotification(t("error"), "Error downloading PDF", NotificationType.danger);
      } finally {
        if (link) {
          link.remove();
        }
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
        }
      }
    },
    [selectedQueueInvoice, pdfDocumentToRender, t],
  );

  const printPdfFromUrl = useCallback(
    async (url: string, revoke: boolean = false) => {
      try {
        const printWindow = window.open(url);

        if (printWindow === null) {
          return;
        }

        printWindow.onload = () => {
          printWindow.print();
        };
      } catch (err) {
        CreateNotification(t("error"), "Error printing PDF", NotificationType.danger);
      } finally {
        if (revoke) {
          URL.revokeObjectURL(url);
        }
      }
    },
    [t],
  );

  const showSplitDeletePdfButtons = useMemo((): boolean => {
    if (!numPages) return false;
    return numPages > 1;
  }, [numPages]);

  const showAddPdfButton = useMemo((): boolean => {
    if (!numPages) return false;
    return numPages > 0;
  }, [numPages]);

  const mappedCheckboxes = useMemo(() => {
    return Array.from(checkboxValues.entries())
      .filter(([_, should]) => should)
      .map(([pageNumber]) => pageNumber);
  }, [checkboxValues]);

  const isAnyCheckboxChecked = useMemo(() => {
    return checkboxValues && Array.from(checkboxValues.values()).some((val) => val);
  }, [checkboxValues]);

  const printPdf = useCallback(
    async (event?: React.MouseEvent) => {
      stopEventPropagation(event);

      if (!selectedQueueInvoice || !selectedQueueInvoice?.url || !pdfDocumentToRender) {
        return;
      }
      if (mappedCheckboxes && mappedCheckboxes.length === 0) {
        printPdfFromUrl(selectedQueueInvoice.url);
      } else {
        const { newPdfFromSelectedPages } = await getNewPdfFromSelectedPages(mappedCheckboxes, pdfDocumentToRender);
        const blob = new Blob([newPdfFromSelectedPages], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        printPdfFromUrl(url, true);
      }
    },
    [mappedCheckboxes, pdfDocumentToRender, printPdfFromUrl, selectedQueueInvoice],
  );

  const addFileAsync = useCallback(
    async (fileToAdd: File) => {
      if (!fileToAdd || !selectedQueueInvoice || !selectedQueueInvoice.url) return;

      setIsOperationRunning(true);

      try {
        if (!pdfDocumentToRender) return;

        await saveAsyncCallback(false);

        const pdfBytes = await fileToAdd.arrayBuffer();
        const pdfDocToAdd = await PDFDocument.load(pdfBytes);
        const actualNumPages = pdfDocToAdd.getPageCount();
        const pageNumbers = Array.from({ length: actualNumPages }, (_, index) => index);
        const copiedPages = await pdfDocumentToRender.copyPages(pdfDocToAdd, pageNumbers);

        for (const page of copiedPages) {
          pdfDocumentToRender.addPage(page);
        }

        const modifiedPdfBytes = await pdfDocumentToRender.save();

        await deleteAndAttachFileToFormDataAsync(
          refetchCurrentInvoiceQuery,
          invalidateCurrentInvoicePdfCache,
          selectedQueueInvoice.invoice,
          modifiedPdfBytes,
          selectedQueueInvoice.filename!,
          t,
          false,
        );

        updateInvoiceAsync(selectedQueueInvoice.invoice.id);

        CreateNotification(
          t("admin.pages.uploadQueue.saved"),
          t("admin.pages.uploadQueue.confirm", { invNumber: selectedQueueInvoice.invoice.number }),
          NotificationType.success,
        );
      } catch (error) {
        handleError(error, t, "Error modifying PDF file");
        throw error;
      } finally {
        setIsOperationRunning(false);
      }
    },
    [
      invalidateCurrentInvoicePdfCache,
      pdfDocumentToRender,
      refetchCurrentInvoiceQuery,
      saveAsyncCallback,
      selectedQueueInvoice,
      setIsOperationRunning,
      t,
      updateInvoiceAsync,
    ],
  );

  const deletePageAsync = useCallback(
    async (event: any) => {
      stopEventPropagation(event);

      if (!isAnyCheckboxChecked || !selectedQueueInvoice || !selectedQueueInvoice.url || !selectedQueueInvoice.invoice)
        return;

      setIsOperationRunning(true);

      try {
        if (!pdfDocumentToRender) return;

        await saveAsyncCallback(false);

        const pages = mappedCheckboxes.sort((a, b) => b - a);

        if (pages.length === numPages) {
          CreateNotification(t("warning"), "Cannot delete all invoices.", NotificationType.warning);
          return;
        }

        pages.forEach((pageNumber) => {
          pdfDocumentToRender.removePage(pageNumber - 1);
        });

        const modifiedPdfBytes = await pdfDocumentToRender.save();

        await deleteAndAttachFileToFormDataAsync(
          refetchCurrentInvoiceQuery,
          invalidateCurrentInvoicePdfCache,
          selectedQueueInvoice.invoice,
          modifiedPdfBytes,
          selectedQueueInvoice.filename!,
          t,
        );
        updateInvoiceAsync(selectedQueueInvoice.invoice.id);
      } catch (error) {
        handleError(error, t, "Error modifying PDF file");
        throw error;
      } finally {
        setIsOperationRunning(false);
      }
    },
    [
      isAnyCheckboxChecked,
      selectedQueueInvoice,
      setIsOperationRunning,
      pdfDocumentToRender,
      saveAsyncCallback,
      mappedCheckboxes,
      numPages,
      refetchCurrentInvoiceQuery,
      invalidateCurrentInvoicePdfCache,
      t,
      updateInvoiceAsync,
    ],
  );

  const splitPageAsync = useCallback(
    async (event: any) => {
      stopEventPropagation(event);

      if (!isAnyCheckboxChecked || !selectedQueueInvoice || !selectedQueueInvoice.url || !selectedQueueInvoice.invoice)
        return;

      setIsOperationRunning(true);
      try {
        if (!pdfDocumentToRender) return;

        await saveAsyncCallback(false);

        const { newPdfFromSelectedPages, pages } = await getNewPdfFromSelectedPages(
          mappedCheckboxes,
          pdfDocumentToRender,
        );
        const filename = `split_${selectedQueueInvoice.filename}`;
        await createExtractedFileAsync(newPdfFromSelectedPages, filename, t);

        const remainingPdf = await PDFDocument.create();
        const totalPages = pdfDocumentToRender.getPageCount();

        for (let i = 0; i < totalPages; i++) {
          if (!pages.includes(i + 1)) {
            const [page] = await remainingPdf.copyPages(pdfDocumentToRender, [i]);
            remainingPdf.addPage(page);
          }
        }

        const remainingPdfBytes = await remainingPdf.save();
        await deleteAndAttachFileToFormDataAsync(
          refetchCurrentInvoiceQuery,
          invalidateCurrentInvoicePdfCache,
          selectedQueueInvoice.invoice,
          remainingPdfBytes,
          "original_" + selectedQueueInvoice.filename,
          t,
        );
      } catch (error) {
        handleError(error, t, "Error modifying PDF file");
        throw error;
      } finally {
        setIsOperationRunning(false);
        refetchInvoiceList();
      }
    },
    [
      invalidateCurrentInvoicePdfCache,
      isAnyCheckboxChecked,
      mappedCheckboxes,
      pdfDocumentToRender,
      refetchCurrentInvoiceQuery,
      saveAsyncCallback,
      selectedQueueInvoice,
      setIsOperationRunning,
      refetchInvoiceList,
      t,
    ],
  );

  const onHandleDeleteClick = useCallback(
    (event: any) => {
      stopEventPropagation(event);

      if (!isAnyCheckboxChecked) {
        return;
      }

      createConfirmModal({
        title: "Confirm Delete",
        body: "Are you sure you want to delete this page?",
        saveCallBack: () => deletePageAsync(event),
        cancelCallBack: () => {},
        confirmButtonLabel: "Delete",
        cancelButtonLabel: "Cancel",
      });
    },
    [deletePageAsync, createConfirmModal, isAnyCheckboxChecked],
  );

  const onHandleSplitClick = useCallback(
    (event: any) => {
      stopEventPropagation(event);

      if (!isAnyCheckboxChecked) {
        return;
      }

      createConfirmModal({
        title: "Confirm Split Page",
        body: "Are you sure you want to split the page?",
        saveCallBack: () => splitPageAsync(event),
        cancelCallBack: () => {},
        confirmButtonLabel: "Split",
        cancelButtonLabel: "Cancel",
      });
    },
    [createConfirmModal, isAnyCheckboxChecked, splitPageAsync],
  );

  const spinerElement = useMemo(
    () => (
      <div className={styles.spinnerContainer}>
        <Spinner animation="grow" className={styles.customSpinner} />
      </div>
    ),
    [],
  );

  const loadingSpinner = useMemo(() => {
    return !isMainDocumentLoaded && selectedQueueInvoice?.file ? spinerElement : <></>;
  }, [isMainDocumentLoaded, selectedQueueInvoice?.file, spinerElement]);

  const panelLabel = useMemo(() => {
    if (!selectedQueueInvoice) return "";

    if (!selectedQueueInvoice?.filename) return selectedQueueInvoice?.label;

    const name = `${selectedQueueInvoice?.label} » ${selectedQueueInvoice?.filename}`;
    if (!isOperationRunning) return name;

    return (
      <span>
        <Spinner animation="border" size="sm" variant="secondary" /> {name}
      </span>
    );
  }, [isOperationRunning, selectedQueueInvoice]);

  const showElems = useMemo(() => {
    return isMainDocumentLoaded && !selectedQueueInvoice?.isBeingOcred;
  }, [isMainDocumentLoaded, selectedQueueInvoice?.isBeingOcred]);

  const showNoDisplayMsg = useMemo(() => {
    return !filteredInvoices || filteredInvoices.length === 0;
  }, [filteredInvoices]);

  // *********** Dropzone  ***********
  const dropzoneRef = useRef(null);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    noDrag: true,
    maxFiles: 1,
    onDrop: (acceptedFiles, rejectedFiles, event) => {
      stopEventPropagation(event);

      if (acceptedFiles.length === 0) return;

      const file = acceptedFiles[0];
      addFileAsync(file);
    },
  });

  const handleOpen = useCallback(
    (event: any) => {
      stopEventPropagation(event);
      open();
    },
    [open],
  );

  const handleThumbnailsVisibility = useCallback((event: any, value: boolean) => {
    stopEventPropagation(event);
    setShowThumbnails(value);
  }, []);

  const handleShowThumbnails = useCallback(
    (event: any) => handleThumbnailsVisibility(event, true),
    [handleThumbnailsVisibility],
  );

  const handleHideThumbnails = useCallback(
    (event: any) => handleThumbnailsVisibility(event, false),
    [handleThumbnailsVisibility],
  );

  const thumbnailsElements = useMemo((): JSX.Element => {
    if (!showElems || !showThumbnails) return <></>;

    const disableSplitandDeleteBtns = !(showSplitDeletePdfButtons && isAnyCheckboxChecked);

    return (
      <Row className="justify-content-center">
        <Col xs={12} className={styles.thumbnails}>
          <PDFThumbnailCarousel
            isMainDocumentLoaded={isMainDocumentLoaded}
            onClickCurrentPage={setPageNumber}
            handleOpen={handleOpen}
            onHandleSplitClick={onHandleSplitClick}
            onHandleDeleteClick={onHandleDeleteClick}
            disabledAdd={!showAddPdfButton}
            disabledSplit={disableSplitandDeleteBtns}
            disabledDelete={disableSplitandDeleteBtns}
            pdfFileToRender={pdfFileToRender}
            checkboxValues={checkboxValues}
            setCheckboxValues={setCheckboxValues}
            isSubmiting={isOperationRunning || isFetching}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </Col>
      </Row>
    );
  }, [
    checkboxValues,
    handleOpen,
    isAnyCheckboxChecked,
    isFetching,
    isMainDocumentLoaded,
    isOperationRunning,
    onHandleDeleteClick,
    onHandleSplitClick,
    pageNumber,
    pdfFileToRender,
    setCheckboxValues,
    setPageNumber,
    showAddPdfButton,
    showElems,
    showSplitDeletePdfButtons,
    showThumbnails,
  ]);

  return (
    <>
      <div {...getRootProps({ style: { display: "none" } })} ref={dropzoneRef}>
        <input {...getInputProps()} />
      </div>
      <Panel
        cardClass={styles.mainPanel}
        cardBodyClass="p-0"
        header={
          <Row>
            <Col sm={12} className={styles.labelContainer}>
              <span className={styles.textLabel}>{panelLabel}</span>
              {panelLabel && !isOperationRunning && !isFetching && (
                <span className={styles.buttonGroup}>
                  {!showThumbnails && (
                    <FaWindowRestore
                      onClick={handleShowThumbnails}
                      className={styles.arrow}
                      title="Restore Thumbnails"
                    />
                  )}
                  {showThumbnails && (
                    <FaRegSquareMinus
                      onClick={handleHideThumbnails}
                      className={styles.arrow}
                      title="Minimize Thumbnails"
                    />
                  )}
                </span>
              )}
            </Col>
          </Row>
        }
      >
        {thumbnailsElements}
        {loadingSpinner}
        {showNoDisplayMsg && <span className={styles.noDisplay}>{t("admin.pages.uploadQueue.noDisplay")}</span>}
        {selectedQueueInvoice?.noAssets && (
          <span className={styles.noDisplay}>{t("admin.pages.uploadQueue.noAssets")}</span>
        )}
        <Row className={styles.pdfDocument}>
          <Col sm={12} className="p-0 border-0">
            <PdfPreviewViewer
              file={pdfFileToRender}
              numPages={numPages}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              pageNumber={pageNumber}
              onPageLoadSuccess={onPageLoadSuccess}
              spinnerElement={spinerElement}
              printCallback={printPdf}
              downloadCallback={downloadPdf}
              setPageNumber={setPageNumber}
              isSubmiting={isOperationRunning || isFetching}
            />
          </Col>
        </Row>
      </Panel>
    </>
  );
};

export default FileUploadSection;
