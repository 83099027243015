import React from "react";
import { useDispatch } from "react-redux";
import { Field, getFormValues } from "redux-form";
import { ManagePaymentMethodFormDataType, PaymentFieldType } from "../types";
import { CHANGE_PAYMENT_METHOD_STATE } from "../../../../actions/actionTypes";
import StatePicker from "../../../common/pickers/reduxPickers/statePicker";
import { useTypedSelector } from "../../../../reducers";
import { MANAGE_PAYMENT_METHOD_FORM } from "../../../../services/common/paymentMethod.svc";

type AccountTypePropsType = PaymentFieldType;

const State = ({ validations, required, label }: AccountTypePropsType) => {
  const dispatch = useDispatch();
  const formData = useTypedSelector(
    (state) => getFormValues(MANAGE_PAYMENT_METHOD_FORM)(state) as ManagePaymentMethodFormDataType,
  );

  const countryCode = formData?.country;

  const changeState = <T extends { target: { value: string } }>(e: T) => {
    if (e.target) {
      dispatch({ type: CHANGE_PAYMENT_METHOD_STATE, payload: e.target.value });
    } else if (e) {
      dispatch({ type: CHANGE_PAYMENT_METHOD_STATE, payload: e });
    }
  };

  return (
    <Field
      name="state"
      label={label}
      countryCode={countryCode}
      component={StatePicker}
      required={required}
      validate={validations}
      onChange={changeState}
    />
  );
};

export default State;
