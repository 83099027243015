import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { getValuesFromOptions, parseForSelect } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { DepartmentPickerPropsTypes, DepartmentsListOptionsType } from ".";

interface MultiDepartmentPickerPropTypes extends DepartmentPickerPropsTypes {}

const MultiDepartmentPicker = ({
  label,
  input,
  labelClassName,
  meta: { touched, error },
  className,
  disabled,
  instanceId = "department-selector",
  placeholder,
  required,
  tooltip,
}: MultiDepartmentPickerPropTypes) => {
  const [selectedValues, setSelectedValues] = useState<DepartmentsListOptionsType[]>([]);
  const [departments, setDepartments] = useState<DepartmentsListOptionsType[]>();

  const mounted = useIsMounted();

  const findSelectedMultiple = async (options: DepartmentsListOptionsType[]) => {
    const selectedValuesPromises = input.value.map(async (val: string) => {
      const obj = options.find((option) => option.value === val);
      if (obj) {
        return obj;
      } else {
        try {
          const result = await restApiService.get(`departments/${val}`, null, null, true, null, true);
          return {
            value: result.data.id,
            label: (
              <>
                {result.data.name} - <small style={{ fontSize: "10px" }}>({result.data.status})</small>
              </>
            ),
          };
        } catch (error) {
          CreateNotification("Error", "Problem loading inactive department.", NotificationType.danger);
          return null; // using filter boolean due this null
        }
      }
    });

    const resolvedSelectedValues = (await Promise.all(selectedValuesPromises)).filter(Boolean);
    if (mounted.current) {
      setSelectedValues(resolvedSelectedValues);
    }
  };

  const getDepartments = useCallback(async () => {
    const apiParams: any = { status: "ACTIVE" };

    try {
      const result = await restApiService.get("departments.lk", apiParams, null, true, null, true);
      const value = parseForSelect(result.data);
      if (mounted.current) setDepartments(value);
      return value;
    } catch (error) {}
  }, []);

  useEffect(() => {
    // fetching departement first
    // due to logic if we don't find department in active department list then we consider that Dpartment being Inactive, fetch inactive department.
    // so nesseosry to have department in active list fetched
    getDepartments().then((res) => {
      findSelectedMultiple(res);
    });
  }, [input.value]);

  const onBlur = () => {
    if (!input.value || !input.value.length) {
      input.onBlur([]);
      return;
    }
    input.onBlur(input.value);
  };

  const onChange = (options: any) => {
    if (!options) {
      input.onChange([]);
      return;
    }
    const values = getValuesFromOptions(options);
    input.onChange(values);
  };

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label className={labelClassName}>
            {label}
            {tooltip}
          </Form.Label>
        )}
        <Select
          {...input}
          required={false}
          value={selectedValues}
          placeholder={placeholder}
          onChange={(value) => onChange(value)}
          onBlur={() => onBlur()}
          options={departments ?? []}
          isMulti={true}
          isClearable
          classNamePrefix="select"
          isDisabled={disabled}
          instanceId={instanceId}
        />
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default MultiDepartmentPicker;
