import React, { useCallback, useState } from "react";
import { ReactNode } from "react";
import { WrappedFieldProps } from "redux-form";
import MultiVendorPicker from "./multiPicker";
import SingleVendorPicker from "./singlePicker";

export interface VendorPickerPropsType extends WrappedFieldProps {
  isMulti?: boolean;
  label?: string;
  labelClassName?: string;
  tooltip?: string;
  params?: Record<string, any>;
  required?: boolean;
  instanceId?: string;
  placeholder?: string;
  disabled?: boolean;
  originalValue?: ReactNode;
  callBack?: (contactId: number) => void;
  loadOptions?: any;
  excludeStatuses?: string;
  poNotRequired?: string;
  modelData?: any;
  options?: any;
  modelName?: string;
  isPoRequired?: boolean;
}

const VendorPicker = (props: VendorPickerPropsType) => {
  const commonProps = {
    label: props.label,
    input: props.input,
    meta: props.meta,
    tooltip: props.tooltip,
    params: props.params,
    required: props.required,
    placeholder: props.placeholder || "search/select",
    disabled: props.disabled || false,
    originalValue: props.originalValue,
    callBack: props.callBack,
    excludeStatuses: props.excludeStatuses,
    modelData: props.modelData,
    modelName: props.modelName,
    poNotRequired: props.poNotRequired,
    instanceId: props.instanceId,
  };

  return (
    <>
      {props.isMulti ? (
        <MultiVendorPicker {...commonProps} />
      ) : (
        <SingleVendorPicker {...commonProps} callBack={props.callBack} />
      )}
    </>
  );
};

export default VendorPicker;
