import React from "react";
import TabNavigation from "../../../navigation/tabNavigation";

//NOTE: add only those route which we want to show on tabNavigations
export const poApprovalModulesRoutes = [
  {
    path: "",
    pageName: "Purchase Orders",
    href: "po_approvals",
  },
  {
    path: "/ap/po_co_approvals",
    pageName: "Change Orders",
  },
];

type NavPropsType = {
  activePageName: string;
};
const NavTabs = ({ activePageName }: NavPropsType) => {
  return <TabNavigation activePageName={activePageName} navigationTab={poApprovalModulesRoutes} />;
};

export default NavTabs;
