import React from "react";
import { Badge } from "react-bootstrap";

export type emailTagsPropsType = {
  tags: Array<any>;
  status: string;
  removeTag: (tag: any) => void;
};

export const EmailTags = (props: emailTagsPropsType) => {
  const tags = props.tags ? props.tags : [];
  return (
    <div>
      {tags.map((tag, i) => (
        <Badge key={i} className={props.status ? "processedTag" : "tag"}>
          {tag}
          {props.removeTag && (
            <button onClick={() => props.removeTag(tag)} className={"bg-transparent border-0"}>
              x
            </button>
          )}
        </Badge>
      ))}
    </div>
  );
};

export default EmailTags;
