import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderCheckBox } from "../../../forms/bootstrapFields";

const AccountDisalbledField = () => {
  const { t } = useTranslation();
  return (
    <Field
      name="user.is_disabled"
      label={t("admin.pages.users.accountDisable")}
      component={RenderCheckBox}
      type="checkbox"
    />
  );
};

export default AccountDisalbledField;
