import React, { useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { Mandatory } from "components/forms/bootstrapFields";
import Select from "react-select";
import { findSelectedSingle, onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import { countries } from "../../../../app.svc.Lookup";
import _ from "lodash";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { FieldRenderProps } from "react-final-form";

export type CountryOptionType = {
  flag?: string;
  label?: string;
  value?: string;
};

type CountryPickerPropsType = FieldRenderProps<string, HTMLElement> & {
  label?: string;
  tooltip?: string;
  required?: boolean;
  placeholder?: string;
  callBack?: (selectedCountry: string) => void;
  defaultValue?: string;
  disabled?: boolean;
  countriesList?: CountryOptionType[];
  isCheckPaymentMethod?: boolean;
};

type ValueProps = {
  label: string;
  name: string;
  value: any;
};

const filterOption = (option: FilterOptionOption<any>, searchValue: string) => {
  const loweredSearch = searchValue.toLowerCase();
  return (
    option.data.name.toLowerCase().includes(loweredSearch) || option.data.value.toLowerCase().includes(loweredSearch)
  );
};

const CountryPicker = ({
  label,
  input,
  tooltip,
  required,
  placeholder,
  defaultValue,
  disabled,
  callBack,
  countriesList,
}: CountryPickerPropsType) => {
  const getCountiesWithFlag = () => {
    const countriesData = _.isArray(countriesList) && countriesList.length > 0 ? countriesList : countries;

    return countriesData.map((country: CountryOptionType) => {
      let flag = null;
      if (country.flag) {
        flag = (
          <img
            src={require(`../../../../../assets/flags/${country.flag.toLowerCase()}.svg`)}
            height="30px"
            width="30px"
            style={{ padding: 5 }}
            alt="flag"
          />
        );
      }
      return {
        value: country.value, // using item.label to search country by name
        name: country.label,
        label: (
          <div className="d-flex">
            <div>{flag}</div>
            <div>{country.label}</div>
          </div>
        ),
      };
    });
  };

  const onChangeCountry = (value: ValueProps) => {
    onChangeSingle(input, value);
    if (callBack) {
      callBack(value.value);
    }
  };

  const getDefaultCountry = () => {
    if (defaultValue !== undefined) {
      input.onChange(defaultValue);
    }
  };

  useEffect(() => {
    getDefaultCountry();
  }, [defaultValue]);

  const countriesData = useMemo(
    () =>
      required
        ? getCountiesWithFlag()
        : [{ label: " -- Select Country -- ", name: "", value: "" }, ...getCountiesWithFlag()],
    [required],
  );

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        value={findSelectedSingle(input, countriesData)}
        placeholder={placeholder}
        onChange={(value) => onChangeCountry(value)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={countriesData}
        required={required ? required : false}
        isDisabled={disabled}
        classNamePrefix="select"
        filterOption={filterOption}
        menuPlacement="auto"
        minMenuHeight={200}
      />
    </Form.Group>
  );
};

export default CountryPicker;
