import React from "react";
import { Col, Row } from "react-bootstrap";
import { required } from "../../../../../services/validations/reduxFormValidation";
import { useTypedSelector } from "../../../../../reducers";
import { getFormValues } from "redux-form";
import BankRoutingSample from "../../../../common/bankingRoutingSample/bankRoutingSample";
import style from "./../../managePaymentMethod.module.css";
import PaymentType from "../../fields/paymentType";
import MailType from "../../fields/mailType";
import AccountName from "../../../../common/managePaymentMethod/fields/accountName";
import Address1 from "../../../../common/managePaymentMethod/fields/address1";
import Address2 from "../../../../common/managePaymentMethod/fields/address2";
import CountryCode from "../../../../common/managePaymentMethod/fields/countryCode";
import City from "../../../../common/managePaymentMethod/fields/city";
import State from "../../../../common/managePaymentMethod/fields/state";
import ZipCode from "../../../../common/managePaymentMethod/fields/zipCode";
import { MANAGE_PAYMENT_METHOD_FORM } from "../../../../../services/common/paymentMethod.svc";
import { ManagePaymentMethodFormDataType } from "../../../../common/managePaymentMethod/types";
import Status from "../../../../common/managePaymentMethod/fields/status";
import Primary from "../../../../common/managePaymentMethod/fields/primary";

const CheckBankAccount = () => {
  const formData = useTypedSelector(
    (state) => getFormValues(MANAGE_PAYMENT_METHOD_FORM)(state) as ManagePaymentMethodFormDataType | null,
  );
  return (
    <>
      <Row>
        <Col md="4">
          <PaymentType label="Payment Type:" required validations={[required]} />
        </Col>
        <Col md="4">
          <MailType label="Mail Type" required validations={[required]} />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <AccountName label={"Mail Check Out To:"} tooltip={<BankRoutingSample />} required validations={[required]} />
        </Col>

        <Col md="4">
          <Address1 label={"Address 1:"} required validations={[required]} />
        </Col>
        <Col md="4">
          <Address2 label={"Address 2:"} />
        </Col>

        <Col md="3">
          <CountryCode label="Country:" required validations={[required]} disabled />
        </Col>
        <Col md="3">
          <City label="City:" required validations={[required]} />
        </Col>
        <Col md="3">
          <State label="State/Province:" required validations={[required]} />
        </Col>
        <Col md="3">
          <ZipCode label="Postal Code:" required validations={[required]} />
        </Col>
      </Row>
      {formData?.mail_type === "ups_next_day_air" && (
        <Row>
          <Col className={style.checkNote}>
            <strong>Note:</strong>
            <ul>
              <li>
                Overnight checks cannot be sent at US addresses containing PO box references and any Non-US addresses.
              </li>
              <li>Overnight checks will be charged at $30/check.</li>
            </ul>
          </Col>
        </Row>
      )}
      <Row>
        <Col md="4">
          <Status label="Status:" />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex justify-content-end">
          <Primary label="Default for future invoice payment method" />
        </Col>
      </Row>
    </>
  );
};

export default CheckBankAccount;
