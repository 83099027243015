import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { IDType, getListOptionsType } from "services/common/types/common.type";
import { PurchasesTypes } from "./purchasesType";

class PurchasesApis {
  static async getPurchasesList({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<PurchasesTypes.List> = await restApiService.get(
        "purchases",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getPurchase(id: IDType) {
    try {
      const response: AxiosResponse<PurchasesTypes.Details> = await restApiService.get("purchases/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static getList = async (params: any) => {
    try {
      const response: AxiosResponse<Array<PurchasesTypes.Details>> = await restApiService.get(
        "purchases",
        params,
        null,
        true,
        null,
        true,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export default PurchasesApis;
