import React, { Component } from "react";
import RestApi from "../../../providers/restApi";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import Panel from "../../../components/panel/panel";
import { Link, withRouter } from "react-router-dom";

class VCardOnboardingIntegrations extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();

    this.backURL = "/ap/onboarding/vcards";
    this.item_id = "connected_accounting_software";
  }

  componentDidMount() {
    if (!this.props.onboaringState.listTemplate) {
      //cant continue as the template is not defined, go to our back url
      this.props.history.push(this.backURL);
    }
  }

  qb_generate_link = () => {
    this.restApiService.get("integration_settings/qb_oauth_details").then((response) => {
      var response = response.data;
      // hijack redirect_url so it leads back to onboarding instead of the default behavior.
      response.redirect_uri = "https://portal.accrualify.com/ap/onboarding/vcards/connect_accounting_software";
      var url =
        response.oauth_url +
        "?response_type=code&scope=com.intuit.quickbooks.accounting+openid" +
        "&client_id=" +
        response.client_id +
        "&redirect_uri=" +
        response.redirect_uri;

      return url;
    });
  };

  accepted = () => {
    //post the item_id to api
    this.restApiService
      .post("virtual_cards/onboarding/checklist/item", null, {
        item_id: this.item_id,
        value: true,
      })
      .then((result) => {
        if (result.status == 200) {
          //change the redux state to reflect this item is completed
          this.props.onboaringState.listTemplate.items.forEach((element) => {
            if (element.id == this.item_id) {
              element.completed = true;
            }
          });
          this.props.setListTemplateItems(this.props.onboaringState.listTemplate.items);
          //go back to main checklist page
          this.props.history.push(this.backURL);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <Container className={"pt-4 pb-4"}>
        <Row>
          <Col>
            <Panel cardClasses={"animated fadeIn"}>
              <Row>
                <Col className="">
                  <Link to={this.backURL}>
                    <h5 className="text-right">X cancel</h5>
                  </Link>
                  <h1>Connect to QuickBooks</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p></p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={6}>
                  <button className="btn btn-primary btn-block" type="button" onClick={this.qb_generate_link}>
                    I Accept
                  </button>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    onboaringState: state.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setListTemplateItems: (payload) => {
      dispatch({ type: actionType.SET_ONBOARDING_LIST_TEMPLATE_ITEMS, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(VCardOnboardingIntegrations)));
