import React, { useCallback, useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import restApi from "../../../../providers/restApi";
import Select from "react-select";
import Styles from "../override.module.css";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../services/general/helpers";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "../../../common/pickers/reduxFormPickers/select/helpers";
import SubsidiaryApis from "../../../../services/admin/subsidiaries/subsidiaryApis";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const SubsidiaryPicker = (props) => {
  let {
    className,
    labelClassName,
    tooltip,
    label,
    input,
    meta: { touched, error, warning },
    isMulti,
    required,
    placeholder = "search/select",
    disabled = false,
    originalValue,
    filter,
  } = props;

  const [subsidiaries, setSubsidiaries] = useState([]);
  const [originalLabel, setOriginalLabel] = useState(null);
  const mounted = useRef(false);

  const addInactiveSubsidiary = async (data, inputValue) => {
    try {
      if (!data.some((item) => item.id === inputValue)) {
        const sub = await SubsidiaryApis.getSubsidiary(String(inputValue));
        sub.name += ` (${sub?.status ? sub.status : ""})`;
        return parseForSelect([...data, sub]);
      }
    } catch (error) {
      console.log("Error during add inactive subsidiary", error);
    }
    return parseForSelect(data);
  };

  const restApiService = new restApi();
  const getSubsideries = useCallback(async () => {
    try {
      const result = await restApiService.get("subsidiaries", filter, null, true, null, true);
      if (mounted.current) {
        const updatedSubsidiaries = input.value
          ? await addInactiveSubsidiary(result.data, input.value)
          : parseForSelect(result.data);

        setSubsidiaries(updatedSubsidiaries);
      }
    } catch (error) {
      console.log("Error during get subsidiary call", error);
    }
  }, [restApiService]);

  useEffect(() => {
    getSubsideries();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (originalValue && subsidiaries.length > 0) {
      setOriginalLabel(findSelectedSingle({ value: originalValue }, subsidiaries)?.label);
    }
  }, [subsidiaries, originalValue]);

  useEffect(() => {
    if (input.value && !subsidiaries.some((item) => item.value === input.value)) {
      getSubsideries();
    }
  }, [input.value]);

  return (
    <>
      <Form.Group className={Styles.select}>
        {label && (
          <Form.Label className={labelClassName}>
            {" "}
            {label ?? ""}
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          {...(originalValue !== undefined
            ? { components: { Input: (props) => <PickerComparisonField {...props} originalValue={originalLabel} /> } }
            : {})}
          value={isMulti ? findSelectedMultiple(input, subsidiaries) : findSelectedSingle(input, subsidiaries)}
          placeholder={placeholder}
          onChange={(value) => (isMulti ? onChange(input, value) : onChangeSingle(input, value))}
          onBlur={() => (isMulti ? onBlur(input, input.value) : onBlurSingle(input, input.value))}
          options={subsidiaries}
          isMulti={isMulti}
          isClearable
          classNamePrefix="select"
          isDisabled={disabled}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
            }),
          }}
        />
        {error && touched && <div style={{ fontSize: "80%", color: "red", padding: "2px" }}>{error}</div>}
      </Form.Group>
    </>
  );
};

export default SubsidiaryPicker;
