import React, { ChangeEventHandler } from "react";
import { Field } from "redux-form";
import { RenderSelect } from "../../../forms/bootstrapFields";
import { useDispatch } from "react-redux";
import { CHANGE_MAIL_TYPE } from "../../../../actions/actionTypes";
import { PaymentFieldType } from "../../../common/managePaymentMethod/types";

type MailTypePropsType = PaymentFieldType;

const MailType = ({ validations, required, label }: MailTypePropsType) => {
  const dispatch = useDispatch();
  const changeMailType: ChangeEventHandler<HTMLSelectElement> = (e) => {
    dispatch({ type: CHANGE_MAIL_TYPE, payload: e.target.value });
  };

  return (
    <Field
      name="mail_type"
      label={label}
      component={RenderSelect}
      options={[
        { label: "", value: "" },
        { label: "Normal", value: "usps_first_class" },
        { label: "Expedited - extra fee will apply", value: "ups_next_day_air" },
      ]}
      required={required}
      validate={validations}
      onChange={changeMailType}
    />
  );
};

export default MailType;
