import React from "react";
import { ObjectPropertiesType } from "../../../../../services/admin/invoices/recurringInvoice/recurringInvoiceTypes";
import Panel from "../../../../common/panel/panel";
import { Table } from "react-bootstrap";
import _ from "lodash";
import { formattedAmount } from "../../../../../services/general/helpers";
import { useTypedSelector } from "../../../../../reducers";
import { IUser } from "../../../../../services/common/user/userTypes";

type GlImpactPropsType = {
  invoice?: ObjectPropertiesType;
};

const GlImpact = ({ invoice }: GlImpactPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);

  return (
    <Panel>
      <Table className="mb-1 text-left" responsive striped bordered>
        <thead>
          <tr>
            <th>Account Type</th>
            <th>Account</th>
            {currentUser?.company?.is_tax_to_invoice_expenses_line && <th>Tax</th>}
            <th>Amount</th>
            <th>Department</th>
            <th>Business Unit</th>
            <th>Location</th>
            {currentUser?.company?.has_projects && <th>Project</th>}
            {currentUser?.company?.has_categories && <th>Category</th>}
            <th>memo</th>
          </tr>
        </thead>
        <tbody>
          {(!invoice ||
            !invoice.account_transaction_attributes ||
            (!invoice.account_transaction_attributes.debit_entries_attributes &&
              !invoice.account_transaction_attributes.credit_entries_attributes) ||
            (invoice &&
              _.isArray(invoice.account_transaction_attributes.debit_entries_attributes) &&
              invoice.account_transaction_attributes.debit_entries_attributes.length < 1 &&
              _.isArray(invoice.account_transaction_attributes.credit_entries_attributes) &&
              invoice.account_transaction_attributes.credit_entries_attributes.length < 1)) && (
            <tr>
              <td colSpan={10} className="text-center">
                No Data Found
              </td>
            </tr>
          )}
          {invoice &&
            invoice.account_transaction_attributes &&
            _.isArray(invoice.account_transaction_attributes.debit_entries_attributes) &&
            invoice.account_transaction_attributes.debit_entries_attributes.map((invoice_debit) => (
              <tr key={invoice_debit.id}>
                <td>Debit Account</td>
                <td>
                  {invoice_debit.account_number ? invoice_debit.account_number + "-" : ""}
                  {invoice_debit.account_name}
                </td>
                {currentUser?.company?.is_tax_to_invoice_expenses_line && (
                  <td>{formattedAmount(invoice_debit.tax?.toString(), invoice.currency_code)}</td>
                )}
                <td>{formattedAmount(invoice_debit.amount?.toString(), invoice.currency_code)}</td>
                <td>{invoice_debit.department_name}</td>
                <td>{invoice_debit.business_unit_name}</td>
                <td>{invoice_debit.location_name}</td>
                {currentUser?.company?.has_projects && <td>{invoice_debit.project_name}</td>}
                {currentUser?.company?.has_categories && <td>{invoice_debit.category_name}</td>}
                <td>{invoice_debit.memo}</td>
              </tr>
            ))}
          {invoice &&
            invoice.account_transaction_attributes &&
            _.isArray(invoice.account_transaction_attributes.credit_entries_attributes) &&
            invoice.account_transaction_attributes.credit_entries_attributes.map((invoice_credit) => (
              <tr key={invoice_credit.id}>
                <td>Credit Account</td>
                <td>
                  {invoice_credit.account_number ? invoice_credit.account_number + "-" : ""}
                  {invoice_credit.account_name}
                </td>
                {currentUser?.company?.is_tax_to_invoice_expenses_line && <td></td>}
                <td>{formattedAmount(invoice_credit.amount?.toString(), invoice.currency_code)}</td>
                <td>{invoice_credit.department_name}</td>
                <td>{invoice_credit.business_unit_name}</td>
                <td>{invoice_credit.location_name}</td>
                {currentUser?.company?.has_projects && <td></td>}
                {currentUser?.company?.has_categories && <td>{invoice_credit.category_name}</td>}
                <td>{invoice_credit.memo}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Panel>
  );
};

export default GlImpact;
