import { AxiosResponse } from "axios";
import useShowSelectMenu from "components/admin/hooks/useShowSelectMenu";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { WrappedFieldProps } from "redux-form";
import { onBlurSingle } from "services/general/helpers";
import PickerErrorBlock from "../pickerErrorBlock";

type CustomTemplatePickerPropsType = {
  modelName?: string;
  modelDataName?: string;
  isRealTimeSearch?: any;
  required?: boolean;
  label?: ReactNode;
  tooltip?: ReactNode;
  placeholder?: ReactNode;
  disabled?: boolean;
  input?: any;
  instanceId?: string;
  parentDivId?: string;
  parentDivVariance?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  menuAutoFixed?: boolean;
} & WrappedFieldProps;

const CustomTemplatePicker = ({
  modelName,
  isRealTimeSearch,
  required,
  label,
  tooltip,
  input,
  placeholder,
  disabled,
  modelDataName,
  instanceId,
  parentDivId,
  parentDivVariance,
  menuAutoFixed,
  meta: { touched, error },
}: CustomTemplatePickerPropsType) => {
  const [searchData, setSearchData] = useState<unknown>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useShowSelectMenu({
    setIsMenuOpen,
    selectRef,
    parentDivId,
    variance: parentDivVariance,
  });

  const getSearchData = async (searchValue?: string | number) => {
    let params: { status: string; name?: string | number } = { status: "ACTIVE" };
    if (isRealTimeSearch === "yes" && searchValue) {
      params = { status: "ACTIVE", name: searchValue };
    }
    const response: AxiosResponse = await restApiService.get(modelName, params, null, true, null, true);
    if (response?.data && response.data.length > 0) {
      const manupilatedResponse = response.data.map((item: any) => ({ label: item.name, value: item.id, ...item }));
      if (required) {
        setSearchData(manupilatedResponse);
      } else {
        // To add a blank option at the top
        const dataWithBlankOption: unknown = [{ label: " -- Select --", value: null }, ...manupilatedResponse];
        setSearchData(dataWithBlankOption);
      }
    }
  };

  const handleInputChange = (value: string, { action }: { action: string }) => {
    if (action === "input-change") {
      getSearchData(value);
    }
  };

  const selectOption = (input: any, options: any) => {
    const obj = _.find(options, (option) => option?.value === input?.value?.value);

    if (isRealTimeSearch === "yes" && !obj && input.value !== "") {
      return input.value;
    }
    return obj;
  };

  const onChangeOption = (input: any, value: any) => {
    if (!value) {
      input.onChange("");
      return;
    }
    input.onChange(value);
  };

  useEffect(() => {
    if (isRealTimeSearch !== "yes") {
      getSearchData();
    }
  }, []);

  return (
    <Form.Group ref={selectRef}>
      <Select
        {...input}
        required={required}
        {...(menuAutoFixed ? { menuPlacement: "auto", menuPosition: "fixed" } : {})}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        value={selectOption(input, searchData)}
        placeholder={placeholder && isRealTimeSearch ? placeholder : "-- Search --"}
        onChange={(value) => onChangeOption(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        onInputChange={handleInputChange}
        options={searchData}
        isClearable={!required}
        isDisabled={disabled}
        instanceId={instanceId}
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default CustomTemplatePicker;
