import DataExtractList from "./dataExtracts/list";
import DataExtractSchedules from "./dataExtracts/list/schedules";
import StandardReportList from "./standardReports/list";
import StandardReportSchedules from "./standardReports/list/schedules";
import FileOutboundList from "./fileOutbound/list";
import FileOutboundSchedules from "./fileOutbound/list/schedules";

export const dataExtractRoutes = [
  {
    path: "/reports/list",
    exact: true,
    name: "Reports",
    component: StandardReportList,
    authorization: {
      I: "list",
      a: "Reports",
    },
  },
  {
    path: "/reports/schedules",
    exact: true,
    name: "Schedules",
    component: StandardReportSchedules,
    authorization: {
      I: "list",
      a: "Reports",
    },
  },
  {
    path: "/reports/data_extracts/card_expense",
    exact: true,
    name: "Data Extracts - Card & Expense",
    component: () => DataExtractList("Card & Expense"),
    authorization: {
      I: "list",
      a: "DataExtracts",
    },
  },
  {
    path: "/reports/data_extracts/invoice",
    exact: true,
    name: "Data Extracts - Invoices",
    component: () =>
      DataExtractList("Invoice", {
        I: "invoice",
        a: "_allow_data_extract",
        permissions: ["listInvoices"],
      }),
  },
  {
    path: "/reports/data_extracts/card_expense/schedules",
    exact: true,
    name: "Data Extracts Schedules",
    component: () => DataExtractSchedules("Card & Expense"),
    authorization: {
      I: "list",
      a: "DataExtracts",
    },
  },
  {
    path: "/reports/data_extracts/invoice/schedules",
    exact: true,
    name: "Data Extracts - Invoice Schedules",
    component: () =>
      DataExtractList("Invoice", {
        I: "invoice",
        a: "_allow_data_extract",
        permissions: ["listInvoices"],
      }),
  },
  {
    path: "/reports/file_outbound/list",
    exact: true,
    name: "File Outbound Reports",
    component: FileOutboundList,
    authorization: {
      permissions: ["listReports"],
    },
  },
  {
    path: "/reports/file_outbound/schedules",
    exact: true,
    name: "File Outbound Schedules",
    component: FileOutboundSchedules,
    authorization: {
      permissions: ["listReports"],
    },
  },
];
