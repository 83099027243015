import { AxiosResponse } from "axios";
import { AppDispatch, RootState } from "..";
import { SET_CONTACT } from "../../actions/actionTypes";
import { restApiService } from "../../providers/restApi";
import { IUser } from "../../services/common/user/userTypes";
import { IContact } from "../../services/common/contact/contactTypes";

// INITIAL_STATE
const initialState = {
  contact: null as IContact | null,
};

const userProfileReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): typeof initialState => {
  switch (type) {
    case SET_CONTACT:
      return { ...state, contact: payload };
    default:
      return state;
  }
};

// action dispatchers
export const setContact = (contact: IContact) => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch({ type: SET_CONTACT, payload: contact });
};

export const getContact = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const contact: IUser["contact"] = getState().user.contact;
  try {
    const response: AxiosResponse<IContact> = await restApiService.get("contacts/" + contact.id);
    dispatch(setContact(response.data));
  } catch (error) {}
};

export const updateContact = () => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(getContact());
};

export default userProfileReducer;
