import TooltipRender from "components/toolTip/tooltipRender";
import { restApiService } from "providers/restApi";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { ApprovalType } from "services/admin/approvals/approvalsType";
import { companyDateFormat } from "services/general/dateSvc";

type ApprovalDetailPropsType = {
  approval: ApprovalType.ApprovalType;
  getApprovalStatusLabel: (arg: string) => string;
};
const ApproverDetail = ({ approval, getApprovalStatusLabel }: ApprovalDetailPropsType) => {
  const currentUser = useTypedSelector((state) => state.user);

  return (
    <Row className="generalInfo">
      <Col md="12">
        <dl className="dl-horizontal mb-30">
          <dt>Approver</dt>
          <dd>{approval.name}</dd>
          <dd className="lh-1">
            <a href={`mailto:${approval.email}`} className="link" target="_blank" rel="noreferrer">
              {approval.email}
            </a>
          </dd>
          {approval?.submitter && (
            <dd className="mt-5 approved-by">
              <i>
                (approved by{" "}
                <a
                  className="link"
                  href={restApiService.angularBaseURL + `/contacts/${approval?.submitter?.contact_id}`}
                >
                  {approval?.submitter?.name}
                </a>
                )
              </i>
            </dd>
          )}
        </dl>

        <dl className="dl-horizontal mb-30">
          <dt>Date</dt>
          <dd>
            {companyDateFormat(approval.approved_at, currentUser) ||
              companyDateFormat(approval.updated_at, currentUser) ||
              companyDateFormat(approval.created_at, currentUser)}
          </dd>
          <dt>Status</dt>
          <dd className="text-capitalize tooltip-full-width">
            {!!approval.aasm_state && getApprovalStatusLabel(approval.aasm_state)}
            {approval?.message && (
              <TooltipRender
                title={`Email ${approval?.message?.status?.toLowerCase()} on ${companyDateFormat(
                  approval?.message?.updated_at,
                  currentUser,
                )}`}
                placement="top"
                className="icon icon-questionmark-white mt-1"
              />
            )}
          </dd>
        </dl>

        <dl className="dl-horizontal">
          {!approval.requestor_note && !approval.approver_note && (
            <>
              <dt>Notes</dt>
              <dd className="color-grey">
                <i>No notes available.</i>
              </dd>
            </>
          )}

          {approval.approver_note && <dd>{approval.approver_note}</dd>}

          {approval.approver_note && approval?.submitter?.updated_at && approval?.submitter?.updated_at && (
            <dd className="color-grey lh-1 mb-10" ng-if="">
              <i>
                {approval?.submitter?.name} on {companyDateFormat(approval?.submitter?.updated_at, currentUser)}
              </i>
            </dd>
          )}

          {approval.requestor_note && (
            <>
              <dd>{approval.requestor_note}</dd>
              <dd className="color-grey lh-1 mb-10">
                <i>Requestor on {companyDateFormat(approval.created_at, currentUser)}</i>
              </dd>
            </>
          )}
        </dl>
      </Col>
    </Row>
  );
};

export default ApproverDetail;
