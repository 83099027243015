import React from "react";
import ContactsOptionsFilter from "../reportFilters/contactsDropdown";
import DateFilter from "../reportFilters/date";
import DropdownFilter from "../reportFilters/dropdown";
import InputFilter from "../reportFilters/input";

const ExpenseTransactionCoding = ({}) => {
  const transaction_type = [
    { value: "", label: "ALL" },
    { value: true, label: "Reimbursable" },
    { value: false, label: "Non-Reimbursable" },
  ];

  const coding_type = [
    { value: "", label: "ALL" },
    { value: true, label: "Coded" },
    { value: false, label: "Uncoded" },
  ];

  const expense_status_options = [
    { value: "", label: "ALL" },
    { value: "DRAFT", label: "DRAFT" },
    { value: "NEW", label: "NEW" },
    { value: "PENDING", label: "PENDING APPROVAL" },
    { value: "APPROVED", label: "APPROVED" },
    { value: "REJECTED", label: "REJECTED" },
    { value: "PAID", label: "PAID" },
  ];

  return (
    <div>
      <DateFilter name={"Start Date"} code={"between_created_at.start_date"} options={[]} isRequired />
      <DateFilter name={"End Date"} code={"between_created_at.end_date"} options={[]} isRequired />
      <ContactsOptionsFilter name={"Employee Name"} code={"employee_id"} options={[]} />
      <DropdownFilter name={"Transaction Type"} code={"reimbursable"} options={transaction_type} />
      <DropdownFilter name={"Coding Type"} code={"coding_type"} options={coding_type} />
      <DropdownFilter name={"Expense Status"} code={"expense_status"} options={expense_status_options} />
      <InputFilter name={"Company"} code={"company"} options={[]} />
      <InputFilter name={"GL Account"} code={"gl_account"} options={[]} />
      <InputFilter name={"Job"} code={"job"} options={[]} />
      <InputFilter name={"Phase"} code={"phase"} options={[]} />
      <InputFilter name={"Cost Type"} code={"cost_type"} options={[]} />
    </div>
  );
};

export default ExpenseTransactionCoding;
