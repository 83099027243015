/*eslint-disable eqeqeq*/
/*eslint-disable jsx-a11y/anchor-is-valid*/
/*eslint-disable no-useless-concat*/
import React, { Component } from "react";
import "./datagrid.style.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import _ from "lodash";
import { Can } from "../../services/authorization/authorization";
import { restApiService } from "../../providers/restApi";
import AngularRedirect from "components/common/tabNavigator/angularRedirect";

class ChildMessageRenderer extends Component {
  constructor(props) {
    super(props);
    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }
  invokeParentMethod() {}
  render() {
    let actionButtons = this.props.colDef.cellRendererParams;
    return (
      <div className="d-flex align-items-center jusity-content-center">
        {/* View Button */}
        {actionButtons &&
          actionButtons.viewLink &&
          (actionButtons.viewAuthorization ? (
            <Can I={actionButtons.viewAuthorization.I} a={actionButtons.viewAuthorization.a}>
              <Link to={actionButtons.viewLink + this.props.data.id}>
                <button type="button" className="actionButtons view" data-toggle="tooltip" title="View"></button>
              </Link>
            </Can>
          ) : (
            <Link to={actionButtons.viewLink + this.props.data.id}>
              <button type="button" className="actionButtons view" data-toggle="tooltip" title="View"></button>
            </Link>
          ))}

        {actionButtons && actionButtons.viewDetailsLink && (
          <Can I={actionButtons.viewAuthorization.I} a={actionButtons.viewAuthorization.a}>
            <a onClick={() => (window.location = restApiService.angularBaseURL() + "vendors/" + this.props.data.id)}>
              {" "}
              <i className="icon actionButtons view"></i>{" "}
            </a>
          </Can>
        )}

        {actionButtons && actionButtons.editVendorLink && (
          <Can I={actionButtons.editAuthorization.I} a={actionButtons.editAuthorization.a}>
            <AngularRedirect to={"vendors/" + this.props.data.id + "?flag=edit"}>
              <i className="icon actionButtons editIcon"></i>
            </AngularRedirect>
          </Can>
        )}

        {/* Add Invoice Button */}
        {_.isObject(this.props.data) &&
          this.props.data.status == "OPEN" &&
          actionButtons &&
          actionButtons.submitInvoiceLink && (
            <Link to={{ pathname: actionButtons.submitInvoiceLink, state: { po: this.props.data } }}>
              <button
                type="button"
                className="actionButtons icon-add-invoice"
                data-toggle="tooltip"
                title="Submit Invoice"
              ></button>
            </Link>
          )}

        {/* Edit Button */}
        {actionButtons &&
          actionButtons.editLink &&
          !actionButtons.editFromLink &&
          (actionButtons.editAuthorization ? (
            <Can I={actionButtons.editAuthorization.I} a={actionButtons.editAuthorization.a}>
              <Link to={actionButtons.editLink + this.props.data.id + "/edit"}>
                <button type="button" className="actionButtons editIcon" data-toggle="tooltip" title="Edit"></button>
              </Link>
            </Can>
          ) : (
            <Link to={actionButtons.editLink + this.props.data.id + "/edit"}>
              <button type="button" className="actionButtons editIcon" data-toggle="tooltip" title="Edit"></button>
            </Link>
          ))}

        {/* Edit from link */}
        {actionButtons && actionButtons.editLink && actionButtons.editFromLink && (
          <Link to={actionButtons.editLink + this.props.data.id + "/edit"}>
            <button className="routingCellButton">{this.props.value}</button>
          </Link>
        )}

        {/* angular Edit Link */}
        {actionButtons &&
          actionButtons.angularEditLink &&
          !actionButtons.angularEditFromLink &&
          (actionButtons.editAuthorization ? (
            <Can I={actionButtons.editAuthorization.I} a={actionButtons.editAuthorization.a}>
              <a href={restApiService.angularBaseURL() + actionButtons.angularEditLink + this.props.data.id + "/edit"}>
                <button
                  type="button"
                  className="actionButtons icon-edit mb-1"
                  data-toggle="tooltip"
                  title="Edit"
                ></button>
              </a>
            </Can>
          ) : (
            <a href={restApiService.angularBaseURL() + actionButtons.angularEditLink + this.props.data.id + "/edit"}>
              <button
                type="button"
                className="actionButtons icon-edit mb-1"
                data-toggle="tooltip"
                title="Edit"
              ></button>
            </a>
          ))}

        {/* Message Button */}
        {actionButtons && actionButtons.messageLink && (
          <Link to={actionButtons.messageLink}>
            <button type="button" className="actionButtons message" data-toggle="tooltip" title="Message"></button>
          </Link>
        )}
        {actionButtons &&
          actionButtons.makePrimaryCallback &&
          _.isObject(this.props.data) &&
          !this.props.data.is_default && (
            <button
              type="button"
              className="actionButtons icon-star bs-20"
              data-toggle="tooltip"
              title="Make Primary"
              onClick={() => actionButtons.makePrimaryCallback(this.props.data)}
            ></button>
          )}
        {actionButtons &&
          actionButtons.removePrimaryCallback &&
          _.isObject(this.props.data) &&
          this.props.data.is_default && (
            <button
              type="button"
              className="actionButtons icon-star-active icon-button bs-20"
              data-toggle="tooltip"
              title="Remove Primary"
              onClick={() => actionButtons.removePrimaryCallback(this.props.data)}
            ></button>
          )}
        {actionButtons && actionButtons.editCallback && _.isObject(this.props.data) && actionButtons.allowEdit && (
          <button
            type="button"
            className="actionButtons icon-edit"
            data-toggle="tooltip"
            title="Edit Address"
            onClick={() => actionButtons.editCallback(this.props.data.id)}
          ></button>
        )}
        {/* Message Button without link*/}
        {actionButtons && actionButtons.showMessage && (
          <a>
            {" "}
            {/*empty anchor tag to match css of other buttons with Link*/}
            <button
              type="button"
              className="actionButtons message"
              onClick={() => this.messageCallback(this.props.data.id)}
              data-toggle="tooltip"
              title="Message"
            ></button>
          </a>
        )}

        {/* Mail Button */}
        {actionButtons && actionButtons.mailLink && (
          <Link to={actionButtons.mailLink}>
            <button type="button" className="actionButtons icon-envelope" data-toggle="tooltip" title="Mail"></button>
          </Link>
        )}

        {/* Delete Button */}
        {actionButtons &&
          actionButtons.deleteCallback &&
          (actionButtons.deleteAuthorization ? (
            <Can I={actionButtons.deleteAuthorization.I} a={actionButtons.deleteAuthorization.a}>
              <button
                type="button"
                className="actionButtons delete"
                data-toggle="tooltip"
                title="Delete"
                onClick={() => actionButtons.deleteCallback(this.props.data)}
              ></button>
            </Can>
          ) : (
            <button
              type="button"
              className="actionButtons delete"
              data-toggle="tooltip"
              title="Delete"
              onClick={() => actionButtons.deleteCallback(this.props.data)}
            ></button>
          ))}
        {
          <div>
            {actionButtons &&
              actionButtons.submitAccrualLink &&
              (_.isPlainObject(this.props.data) &&
              _.isPlainObject(this.props.data.accrual) &&
              this.props.data.accrual.id ? (
                <Link to={actionButtons.viewAccrualLink + this.props.data.accrual.id}>
                  <span className="routingCellButton">{this.props.data.accrual.number}</span>
                </Link>
              ) : (
                <Link to={{ pathname: actionButtons.submitAccrualLink, state: { accrualRequest: this.props.data } }}>
                  <button type="button" className=" btn-primary btnAddXs" data-toggle="tooltip" title="Submit Accrual">
                    <i className="btn-icon icon-add-btn"></i>
                  </button>
                </Link>
              ))}
          </div>
        }
        {this.props.dataType == "invoice_request" ? (
          <div>
            <button style={{ height: 35 }} className="btn btn-primary" onClick={() => this.navigateToInvoice()}>
              Submit Invoices
            </button>
          </div>
        ) : null}
      </div>
    );
  }
  messageCallback = (id) => {
    this.props.colDef.cellRendererParams.callBack(id);
  };
  navigateTo = () => {
    const link = "submit_accrual" + "/" + this.props.data.id;
    this.props.history.push(link);
  };
  navigateToAccruals = () => {
    const link = "ar/accruals" + "/" + this.props.data.accrual.id;
    this.props.history.push(link);
  };
  navigateToInvoice = () => {
    const link = "ar/submit_invoice" + "/" + this.props.data.id;
    this.props.history.push(link);
  };
}

export default ChildMessageRenderer;
