import React, { useState, useEffect } from "react";
import { Field, BaseFieldProps, WrappedFieldProps } from "redux-form";
import Select from "react-select"; // Ensure you have react-select or an equivalent installed
import { restApiService } from "providers/restApi";
import AsyncSelect from "react-select/async";
import { MetadataConfiguration } from "pages/admin/administration/metadata/metadataConfiguration";
import { Form } from "react-bootstrap";
import Styles from "../override.module.css";

// Component props
interface MetadataConfigurationPickerProps extends BaseFieldProps {
  name: string; // Name of the field to be used by redux-form Field
  isMulti: boolean; // Determines if the select is multi-select
  metadataConfiguration: MetadataConfiguration;
  modal?: boolean;
}

// Custom Input component to be used with redux-form Field
const MetadataConfigurationPicker: React.FC<WrappedFieldProps & MetadataConfigurationPickerProps> = ({
  input,
  metadataConfiguration,
  isMulti,
  modal,
}) => {
  const [initialValue, setInitialValue] = useState<any>(null);

  useEffect(() => {
    if (initialValue) return;
    const fetchInitialValues = async () => {
      if (!input || !input.value) {
        return;
      }
      // Check if input.value is an array and handle it accordingly
      const valueIsArray = Array.isArray(input.value);
      const ids = valueIsArray ? input.value : [input.value];
      try {
        const promises = ids.map((id: any) => restApiService.get("metadata_configurations/" + id));
        const responses = await Promise.all(promises);
        const initialValues = responses.map((response) => ({
          value: response.data.id,
          label: response.data.name,
        }));

        setInitialValue(isMulti ? initialValues : initialValues[0] || null);
      } catch (error) {
        console.error("Error fetching configurations:", error);
      }
    };

    fetchInitialValues();
  }, [input.value, isMulti]);

  const fetchMetadataConfigurations = async (inputValue: string) => {
    try {
      let sort = { name: { direction: "asc", priority: 0 } };
      let response = await restApiService.get("metadata_configurations", { name: inputValue, sort: sort, size: 30 });
      return response.data.map((item: { id: any; name: any }) => ({
        value: item.id,
        label: item.name,
      }));
    } catch (error) {
      console.error("Error fetching metadata configurations:", error);
      return [];
    }
  };

  const handleLoadOptions = (inputValue: string) => fetchMetadataConfigurations(inputValue);

  const handleChange = (selectedOption: any) => {
    input.onChange(isMulti ? selectedOption.map((option: { value: any }) => option.value) : selectedOption?.value);
    console.log(selectedOption);
    setInitialValue(selectedOption);
  };

  return (
    <Form.Group className={Styles.select}>
      <AsyncSelect
        cacheOptions
        defaultOptions
        loadOptions={handleLoadOptions}
        onChange={handleChange}
        onBlur={() => input.onBlur(input.value)}
        isMulti={isMulti}
        // isSearchable={true}
        menuPortalTarget={modal ? null : document.querySelector("body")}
        classNamePrefix="select"
        value={initialValue}
      />
    </Form.Group>
  );
};

export default MetadataConfigurationPicker;
