import React, { useEffect, useState } from "react";
import Panel from "components/common/panel/panel";
import styles from "./dashboard.module.css";
import { Col, Row, Table } from "react-bootstrap";
import { restApiService } from "providers/restApi";
import _ from "lodash";
import { formattedAmount } from "services/general/helpers";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";
import { getUrlLocator } from "services/common/urlHelperSvc";
import { companyDateFormat } from "services/general/dateSvc";

const UnprocessedVirtualCards = () => {
  const [unprocessedVcards, setUnprocessedVcards] = useState<any>([]);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const currentUserCompany = currentUser?.company;

  const getUnprocessedVirtualCards = async () => {
    try {
      const response = await restApiService.get("virtual_cards/get_unprocessed_virtual_cards");
      if (_.isArray(response.data) && response.data.length > 0) {
        // Limit the number of records to 6
        const limitedRecords = response.data.slice(0, 6);
        setUnprocessedVcards(limitedRecords);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUnprocessedVirtualCards();
  }, []);

  return (
    <div>
      <Panel
        cardClass={styles.panelWrapper}
        cardBodyClass={styles.panelUvp}
        header={
          <div className={styles.panelTitle}>
            <i className={`icon icon-inactive-card px-mt-3 px-mr-10`}></i>Unprocessed Virtual Cards
          </div>
        }
        cardHeaderClass={styles.noPadding}
      >
        <Row>
          <Col>
            <Table className={`mb-1 border ${styles.table}`} size="sm" responsive>
              <thead>
                <tr className={styles.tableHeading}>
                  <td>Card No.</td>
                  <td>Vendor Name</td>
                  <td>Amount</td>
                  <td>Issue Date</td>
                  <td>Payment No.</td>
                </tr>
              </thead>
              <tbody>
                {unprocessedVcards?.map((card: any, index: number) => (
                  <tr key={index} className={styles.unprocessedVcards}>
                    <td>
                      <a
                        href={restApiService.angularBaseURL() + getUrlLocator("virtualCard", card.id)}
                        className="link"
                      >
                        ****{card.last4}
                      </a>
                    </td>
                    <td>
                      <a
                        href={restApiService.angularBaseURL() + getUrlLocator("vendor", card?.vendor?.id)}
                        className="link"
                      >
                        {card?.vendor?.name}
                      </a>
                    </td>
                    <td>{formattedAmount(card.amount, currentUserCompany?.currency?.iso_code)}</td>
                    <td>{companyDateFormat(card.issue_date, currentUser)}</td>
                    <td>
                      <a
                        href={restApiService.angularBaseURL() + getUrlLocator("payment", card?.payment?.id)}
                        className="link"
                      >
                        {card?.payment?.payment_number}
                      </a>
                    </td>
                  </tr>
                ))}

                {unprocessedVcards.length > 5 && (
                  <tr className={styles.unprocessedVcards}>
                    <td colSpan={5}>
                      <a
                        href={
                          restApiService.angularBaseURL() +
                          getUrlLocator("payment", undefined, "unprocessed_vcard=true")
                        }
                        className="link"
                      >
                        VIEW ALL CARDS
                      </a>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Panel>
    </div>
  );
};

export default UnprocessedVirtualCards;
