import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./modal.module.css";

type SuccessModalPropsType = {
  show: boolean;
  body: ReactNode;
  closeCallback: () => void;
};

const SuccessModal = ({ show, closeCallback, body }: SuccessModalPropsType) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={closeCallback}>
      <Modal.Header closeButton style={{ borderBottom: "none" }}></Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <i className={"icon-success-star " + styles.iconSuccess} />
          <h2>{t("success")}!</h2>
          <p className="mt-4">{body}</p>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-center" style={{ borderTop: "none" }}>
        <Button variant="primary" className={styles.w85} onClick={closeCallback}>
          {t("ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;
