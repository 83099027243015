import React, { useCallback, useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import restApi from "../../../../providers/restApi";
import Select from "react-select";
import Styles from "../override.module.css";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
} from "../../../../services/general/helpers";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */
const AddressPicker = (props) => {
  let {
    className,
    label,
    tooltip,
    input,
    meta: { touched, error, warning },
    isMulti,
  } = props;

  const [addresses, setAddresses] = useState([]);
  const mounted = useRef(false);

  const restApiService = new restApi();

  const getAddresses = useCallback(async () => {
    const result = await restApiService.get(
      "addresses",
      {
        active: true,
        addressable_type: "Company",
      },
      null,
      true,
      null,
      true,
    );

    if (mounted.current) setAddresses(parseForSelect(result.data));
  }, [restApiService]);

  useEffect(() => {
    getAddresses();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const parseForSelect = (arr) => {
    const options = arr.map((ele) => {
      return { value: ele.id, ...ele };
    });
    return options;
  };

  const customFilter = (option, searchText) => {
    if (
      (option.data.label ? option.data.label.toLowerCase().includes(searchText.toLowerCase()) : false) ||
      (option.data.city ? option.data.city.toLowerCase().includes(searchText.toLowerCase()) : false) ||
      (option.data.zipcode ? option.data.zipcode.toLowerCase().includes(searchText.toLowerCase()) : false)
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (addresses.length)
    return (
      <>
        <Form.Group className={Styles.select}>
          {label && (
            <Form.Label>
              {" "}
              {label ?? ""}
              {tooltip ?? ""}
            </Form.Label>
          )}
          <Select
            {...input}
            required
            value={isMulti ? findSelectedMultiple(input, addresses) : findSelectedSingle(input, addresses)}
            placeholder="search/select"
            onChange={(value) => (isMulti ? onChange(input, value) : onChangeSingle(input, value))}
            onBlur={() => (isMulti ? onBlur(input, input.value) : onBlurSingle(input, input.value))}
            options={addresses}
            getOptionLabel={(option) => {
              return (
                <small>
                  <strong>
                    {option.label}
                    {option.addressable_type === "Location" || option.addressable_type === "Project"
                      ? " - " + option.addressable_type
                      : ""}
                  </strong>
                  <br />
                  {option.id && (
                    <span>
                      <strong>City:</strong> {option.city || ""},<strong>Zipcode:</strong> {option.zipcode || ""},
                      <strong>Country:</strong> {option.country || ""}
                    </span>
                  )}
                </small>
              );
            }}
            getOptionValue={(option) => `${option["id"]}`}
            filterOption={customFilter}
            isMulti={isMulti}
            isClearable
            classNamePrefix="select"
          />
          {error && touched && (
            <div style={{ fontSize: "80%", color: "red", padding: "2px" }}> addresses Required... </div>
          )}
        </Form.Group>
      </>
    );
  else return <div></div>;
};

export default AddressPicker;
