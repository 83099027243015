import React, { useEffect, useState } from "react";
import useIsMounted from "components/common/hooks/useIsMounted";
import usePermission from "components/common/hooks/usePermission";
import { BreadcrumbTrail } from "components/navigation/navigationLinks";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { SubsidiaryDetailType, SubsidiaryLinkAccountType } from "services/admin/subsidiaries/types";
import PageTitle from "components/page/pageTitle";
import TabNavigator from "components/common/tabNavigator/tabNavigator";
import { NavbarTabType } from "services/common/types/common.type";
import styles from "./subsidiaries.module.css";
import Addresses from "../addresses";
import ActivityLog from "../activityLog/activityLog";
import GeneralInfo from "./generalInfo";
import _ from "lodash";
import SubsidiaryApis from "services/admin/subsidiaries/subsidiaryApis";
import LinkAccounts from "./linkAccounts";
import { manipulateSubsidiaryLinks } from "services/admin/subsidiaries/subsidiarySvc";

type AccountsTypes = {
  label: string;
  account_links: SubsidiaryLinkAccountType[];
};

const DetailSubsidiary = () => {
  const { id } = useParams<{ id: string }>(); // Access the id parameter from the URL
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { hasUserPermission } = usePermission();
  const history = useHistory();

  const [subsidiary, setSubsidiary] = useState<SubsidiaryDetailType>();
  const [activeTab, setActiveTab] = useState<string>("generalInfo");

  const accountTypes = [
    { label: "Payment Debit Account", val: "payment_debit_accounts" },
    { label: "Payment Credit Account", val: "payment_credit_accounts" },
    { label: "Expense Report Debit Account", val: "expense_report_debit_accounts" },
    { label: "Expense Report Credit Account", val: "expense_report_credit_accounts" },
    { label: "Nonreimbursable Expense Report Debit Account", val: "expense_report_nonreimbursable_debit_accounts" },
    { label: "Nonreimbursable Expense Report Credit Account", val: "expense_report_nonreimbursable_credit_accounts" },
    { label: "Virtual Card Debit Account", val: "virtual_card_debit_accounts" },
    { label: "Virtual Card Credit Account", val: "virtual_card_credit_accounts" },
    { label: "Invoice Debit Account", val: "invoice_debit_accounts" },
    { label: "Invoice Credit Account", val: "invoice_credit_accounts" },
    { label: "Purchase Order Debit Account", val: "purchase_order_debit_accounts" },
    { label: "Purchase Order Credit Account", val: "purchase_order_credit_accounts" },
    { label: "Credit Memo Debit Account", val: "vendor_credit_debit_accounts" },
    { label: "Credit Memo Credit Account", val: "vendor_credit_credit_accounts" },
  ];

  const tabData: NavbarTabType = [
    { activeTab: "generalInfo", label: "General Info" },
    { activeTab: "addresses", label: "Addresses" },
  ];

  const editSubsidiary = () => {
    history.push(`/ap/subsidiaries/${subsidiary?.id}/edit`, { isFromDetailPage: true });
  };

  const changeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const accounts: AccountsTypes[] = accountTypes.map((accountType) => {
    return {
      label: accountType.label,
      account_links: (subsidiary as any)?.[accountType.val],
    };
  });

  const getSubsidiary = async () => {
    try {
      const response = await SubsidiaryApis.getSubsidiary(id);
      await manipulateSubsidiaryLinks(response);
      if (isMounted) {
        setSubsidiary(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted) {
      getSubsidiary();
    }
  }, [id]);

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      {subsidiary?.id && (
        <>
          <BreadcrumbTrail to={"/ap/subsidiaries"} pageName={"Subsidiary"} title={"Subsidiary Details"} />
          <Row className="mt-3">
            <Col md={10} className="d-flex align-items-center">
              <PageTitle title={subsidiary.name} />
            </Col>
            <Col md={2} className="pl-0">
              <Button className="float-right px-3" onClick={() => editSubsidiary()}>
                <i className="icon icon-edit-button mr-2" />
                Edit Subsidiary
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <hr className="full-border" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TabNavigator activeTab={activeTab} handleTabChange={changeTab} tabData={tabData} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {activeTab === "addresses" ? (
                <div className={styles.marginTop20}>
                  <Addresses
                    addressableId={subsidiary?.id}
                    addressableType="Subsidiary"
                    allowEdit={hasUserPermission("editSubsidiaries")}
                    allowDelete={hasUserPermission("deleteSubsidiaries")}
                  />
                </div>
              ) : (
                <div>{subsidiary?.id && <GeneralInfo subsidiary={subsidiary} />}</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <LinkAccounts accounts={accounts} modelData={subsidiary} handleClose={getSubsidiary} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ActivityLog modelMethod="subsidiaries" modelActionId={subsidiary.id} />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default DetailSubsidiary;
