import { GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { AppDispatch, RootState } from "..";
import {
  SET_EMAIL_STATS,
  SET_EMAIL_TAGS,
  SET_CURRENT_EMAIL_TAB,
  SET_EMAIL_PAGINATION_DATA,
  SET_EMAIL_PAGINATION_SIZE,
  SET_EMAIL_CURRENT_PAGE,
  SET_EMAIL_SEARCH_STATES,
  SET_EMAIL_IS_SEARCHING,
  SET_EMAIL_PARAMS_EMAIL_ID,
  SET_EMAIL_VIEW,
  SET_SHOW_ADVANCE_SEARCH,
  SET_NEXT_PREVIOUS_INDEX,
  SET_EMAIL_GRID_API,
  SET_EMAILS_LOADING,
  SET_EMAIL_DETAIL_LOADING,
  SET_EMAIL_DETAILS,
  SET_SHOW_DETAIL_PAGE,
  SET_SELELCTED_EMAILS,
  RESET_COMPLETE_EMAIL_PAGE,
  SET_GRID_REFRESH_KEY,
} from "../../actions/actionTypes";
import RestApi from "../../providers/restApi";
import { getPaginationData, paginationDataType } from "../../services/common/gridService";
import { getStatusParams } from "../../services/admin/invoices/emailSvc";
import {
  GRID_VIEW,
  IEmail,
  LIST_VIEW,
  getSearchParams,
  markAsCompletedPromise,
  nextPreviousIndexType,
  parseSearchParams,
  reprocessEmailPromise,
  searchStateType,
  selectFirstEmail,
  selectEmailAtIndex,
} from "../../services/admin/invoices/emailSvc";
import { CreateNotification } from "../../services/general/notifications";
import { emailStatus } from "../../services/email/email.svc";
import _ from "lodash";

const restApiService = new RestApi();
// INITIAL_STATE
const initialState = {
  // gridApi
  // NOTE: we can not use these grid api for caluation of pagination as these gridapi don't updated
  // for every change in gridapi
  gridApi: null as GridApi | null,
  gridRefreshKey: 1,

  // loader grid
  loading: false,

  // loader email detial
  emailDetailLoading: false,

  // Email detail Object
  showDetailPage: false,
  emailDetails: null as IEmail | null,

  emailView: localStorage.getItem("showEmailView") ?? GRID_VIEW,
  showAdvanceSearch: true,
  paramsEmailId: "",

  // advanceSearch
  isSearching: false,

  searchState: {
    start_date: "",
    end_date: "",
    status: "",
    subject: "",
    body: "",
    from: "",
    message_id: "",
  } as searchStateType,

  emailStats: {
    Completed: null,
    Failed: null,
    New: null,
    Processed: null,
    Sent: null,
    total: null,
  },

  tags: [] as string[],
  currentEmailTab: "Inbox",

  nextPreviousIndex: {
    nextRowIndex: null,
    previousRowIndex: null,
    activeRowIndex: null,
  } as nextPreviousIndexType,

  // not used in aggrid but custom pagiantion
  paginationData: {} as paginationDataType,
  paginationSize: localStorage.getItem("email_pagination_size") ?? 100,
  currentPage: 1,

  // selected emails
  selectedEmails: [] as any[],
};

const emailReducer = (state = initialState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case SET_SHOW_ADVANCE_SEARCH:
      return { ...state, showAdvanceSearch: payload };
    case SET_EMAIL_VIEW:
      localStorage.setItem("showEmailView", payload);
      return { ...state, emailView: payload };
    case SET_EMAIL_STATS:
      return { ...state, emailStats: payload };
    case SET_EMAIL_TAGS:
      return { ...state, tags: payload };
    case SET_CURRENT_EMAIL_TAB:
      return { ...state, currentEmailTab: payload };
    case SET_EMAIL_PAGINATION_DATA:
      return { ...state, paginationData: payload };
    case SET_EMAIL_PAGINATION_SIZE:
      localStorage.setItem("email_pagination_size", payload);
      return { ...state, paginationSize: payload };
    case SET_EMAIL_CURRENT_PAGE:
      return { ...state, currentPage: payload };
    case SET_EMAIL_SEARCH_STATES:
      return { ...state, searchState: payload };
    case SET_EMAIL_IS_SEARCHING:
      return { ...state, isSearching: payload };
    case SET_EMAIL_PARAMS_EMAIL_ID:
      return { ...state, paramsEmailId: payload };
    case SET_EMAIL_GRID_API:
      return { ...state, gridApi: payload };
    case SET_NEXT_PREVIOUS_INDEX:
      return { ...state, nextPreviousIndex: payload };
    case SET_EMAILS_LOADING:
      return { ...state, loading: payload };
    case SET_EMAIL_DETAIL_LOADING:
      return { ...state, emailDetailLoading: payload };
    case SET_EMAIL_DETAILS:
      return { ...state, emailDetails: payload };
    case SET_SHOW_DETAIL_PAGE:
      return { ...state, showDetailPage: payload };
    case SET_SELELCTED_EMAILS:
      return { ...state, selectedEmails: payload };
    case SET_GRID_REFRESH_KEY:
      const { gridRefreshKey } = state;
      return { ...state, gridRefreshKey: gridRefreshKey + 1 };
    case RESET_COMPLETE_EMAIL_PAGE:
      // not resetting grid api as it is required for refetching emails
      const { gridApi } = state;
      return { ...state, ...initialState, gridApi };
    default:
      return state;
  }
};

export default emailReducer;

// action dispatchers
export const gridApiSetter = (gridApi: GridApi) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_EMAIL_GRID_API, payload: gridApi });
};

export const getEmailStats = () => async (dispatch: AppDispatch) => {
  try {
    const response = await restApiService.get("inbound_emails/stats_elastic");
    dispatch({ type: SET_EMAIL_STATS, payload: response.data });
  } catch (error) {
    console.log(error);
  }
};

export const getEmailTags = () => async (dispatch: AppDispatch) => {
  try {
    const response = await restApiService.get("inbound_emails/tags");
    dispatch({ type: SET_EMAIL_TAGS, payload: response?.data?.tags });
  } catch (error) {
    console.log(error);
  }
};

export const setCurrentEmailTab = (value: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: SET_CURRENT_EMAIL_TAB, payload: value });
  } catch (error) {
    console.log(error);
  }
};

export const searchStateSetter = (searchState: searchStateType) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_EMAIL_SEARCH_STATES, payload: searchState });
};

export const searchStateResetter = () => (dispatch: AppDispatch) => {
  dispatch({
    type: SET_EMAIL_SEARCH_STATES,
    payload: initialState.searchState,
  });
};

export const paginationDataSetter = (paginationData: paginationDataType) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_EMAIL_PAGINATION_DATA, payload: paginationData });
};

export const paginationSizeSetter = (paginationSize: number) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_EMAIL_PAGINATION_SIZE, payload: paginationSize });
};

export const currentPageSetter = (pageNumber: number) => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch({ type: SET_EMAIL_CURRENT_PAGE, payload: pageNumber });
};

export const setIsSearching = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_EMAIL_IS_SEARCHING, payload: value });
};

export const setParamEmailId = (id: string) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_EMAIL_PARAMS_EMAIL_ID, payload: id });
};

export const resetParamEmailId = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const paramsEmailId = getState().emailRed.paramsEmailId;
  if (paramsEmailId) {
    dispatch({ type: SET_EMAIL_PARAMS_EMAIL_ID, payload: "" });
  }
};

export const emailViewSetter = (view: string) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_EMAIL_VIEW, payload: view });
};

export const showAdvanceSearchSetter = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_SHOW_ADVANCE_SEARCH, payload: value });
};

export const nextPreviousIndexSetter = (nextPreviousIndex: nextPreviousIndexType) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_NEXT_PREVIOUS_INDEX, payload: nextPreviousIndex });
};

export const emailsLoadingSetter = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_EMAILS_LOADING, payload: value });
};

export const emailDetailLoaingSetter = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_EMAIL_DETAIL_LOADING, payload: value });
};

export const emailDetailSetter = (emailDetail: IEmail | null) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_EMAIL_DETAILS, payload: emailDetail });
};

export const showDetailPageSetter = (value: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_SHOW_DETAIL_PAGE, payload: value });
};

// these reset our email page  to initial state
export const resetCompleteEmailPage = () => (dispatch: AppDispatch) => {
  dispatch({ type: RESET_COMPLETE_EMAIL_PAGE });
};

export const refreshGrid = () => (dispatch: AppDispatch) => {
  dispatch({ type: SET_GRID_REFRESH_KEY });
};

// setting selected rows for grid
export const selectedEmailSetter = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const gridApi = getState().emailRed.gridApi;
  const selectedRows = gridApi?.getSelectedRows();
  if (gridApi) {
    dispatch({ type: SET_SELELCTED_EMAILS, payload: selectedRows });
  }
};

// update pagiantion stats, currentPageNumber
// no cycle as we are not updateing rowData
export const onPaginationChange =
  ({ gridApi }: { gridApi: GridApi }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (gridApi) {
      dispatch(paginationDataSetter(getPaginationData(gridApi)));
      const newCurrentPage = gridApi.paginationGetCurrentPage() + 1;
      if (newCurrentPage) dispatch(currentPageSetter(newCurrentPage));
    }
  };

// update PaginationData when new data is loaded in grid
export const onGridDataChange =
  ({ gridApi }: { gridApi: GridApi }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (gridApi) {
      dispatch(paginationDataSetter(getPaginationData(gridApi)));
    }
  };

export const onPaginationSizeChange = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const gridApi = getState().emailRed.gridApi;
  if (gridApi) {
    dispatch(refetchEmail());
  }
};

export const onRowDataChanged = (gridApi: GridApi) => (dispatch: AppDispatch) => {
  dispatch(gridApiSetter(gridApi));
};

// On Change current Email tab
export const onChangeCurrentEmailTab = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const currentEmailTab = getState().emailRed.currentEmailTab;
  const searchStates = getState().emailRed.searchState;
  const showDetailPage = getState().emailRed.showDetailPage;
  const emailDetails = getState().emailRed.emailDetails;
  const selectedEmails = getState().emailRed.selectedEmails;
  const gridApi: GridApi = getState().emailRed.gridApi;

  // remove selected emails
  if (_.isArray(selectedEmails) && selectedEmails.length) {
    gridApi.deselectAll();
  }
  dispatch(refetchEmail());

  // if we are showing email detail page
  // while change email tabs should not be showen
  if (showDetailPage) {
    dispatch(showDetailPageSetter(false));
  }
  // also email detail shold get null
  if (emailDetails) {
    dispatch(emailDetailSetter(null));
  }

  // update filter state to current email tab
  if (emailStatus.includes(currentEmailTab)) {
    dispatch(searchStateSetter({ ...searchStates, status: currentEmailTab }));
  } else {
    dispatch(searchStateSetter({ ...searchStates, status: "" }));
  }
};

// on GridReady
export const onGridReady = (params: GridReadyEvent) => (dispatch: AppDispatch) => {
  // store grid api in redux store to reuse it every other place
  // not using gridRef as it is not working properly
  dispatch(gridApiSetter(params.api));
  params.api.addEventListener("rowDataChanged", () => dispatch(onRowDataChanged(params.api)));

  params.api.setServerSideDatasource({
    getRows: (params) => dispatch(getRows(params)),
  });
};

// for refetching emails
export const refetchEmail = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const gridApi: GridApi = getState().emailRed.gridApi;
  if (gridApi) {
    gridApi.setServerSideDatasource({
      getRows: (params: any) => dispatch(getRows(params)),
    });
  }
};

export const onDefaultInvoiceEmail =
  (response: any, params: IServerSideGetRowsParams) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const emailId = getState()?.emailRed?.paramsEmailId;

    if (_.isArray(response?.data?.data) && response.data.data?.filter((mail: any) => mail.id === emailId).length) {
      params.success({
        rowData: response.data.data,
        rowCount: response.data.meta.count,
      });
      // now we have find index of email in list and click that
      // also have to select email at that index
      const emailIndex = response.data.data.findIndex((mail: any) => mail.id === emailId);
      if (emailIndex || emailIndex === 0) {
        dispatch(gridItemClicked({ data: response.data.data[emailIndex] }));
        selectEmailAtIndex({ gridApi: params.api, indexToBeSelected: emailIndex });
      }
    } else {
      try {
        const emailDetailResponse = await restApiService.get("inbound_emails/" + emailId);
        params.success({
          rowData: [emailDetailResponse.data, ...response.data.data],
          rowCount: response.data.meta.count + 1,
        });
        // as we have added to emaila at index zero
        // we have to select first email
        selectFirstEmail({ gridApi: params.api });

        // next previous
        dispatch(getNextPreviousIndex());

        // click on first email also
        dispatch(gridItemClicked({ data: emailDetailResponse.data }));
      } catch (error) {
        console.log(error);
      }

      // next previous
      dispatch(getNextPreviousIndex());
    }
  };

export const getRows =
  (params: IServerSideGetRowsParams) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const currentEmailTab = getState().emailRed.currentEmailTab;
    const isSearching = getState().emailRed.isSearching;
    const searchState = getState().emailRed.searchState;
    const emailId = getState().emailRed.paramsEmailId;
    const searchParams = parseSearchParams(searchState);
    const emailView = getState().emailRed.emailView;

    // Start showing loader for emails grid
    dispatch(emailsLoadingSetter(true));

    try {
      let response;
      if (isSearching) {
        response = await restApiService.get("inbound_emails/elastic/", {
          ...getSearchParams(currentEmailTab), // <- email tab have less priority if status is specified
          ...searchParams,
          page: params.api.paginationGetCurrentPage() + 1,
          items: params.api.paginationGetPageSize(),
        });
      }

      if (!isSearching) {
        response = await restApiService.get(`inbound_emails/elastic`, {
          ...getStatusParams(currentEmailTab),
          page: params.api.paginationGetCurrentPage() + 1,
          items: params.api.paginationGetPageSize(),
        });
      }
      if (response) {
        // Stop showing loader for emails grid
        dispatch(emailsLoadingSetter(false));

        if (emailId) {
          dispatch(onDefaultInvoiceEmail(response, params));
        } else if (emailView === GRID_VIEW) {
          dispatch(onGridSuccess(response, params));
        } else if (emailView === LIST_VIEW) {
          dispatch(onListSuccess(response, params));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

// when email grid is displayed to user
export const onGridSuccess =
  (response: any, params: IServerSideGetRowsParams) => (dispatch: AppDispatch, getState: () => RootState) => {
    params.success({
      rowData: response.data.data,
      rowCount: response.data.meta.count,
    });

    // only for gridview we have to select first email
    selectFirstEmail({ gridApi: params.api });

    // important for next and previous feature
    dispatch(getNextPreviousIndex());

    // click on first email also
    dispatch(gridItemClicked({ data: response.data.data[0] }));
  };

// when email list is displayed to user
export const onListSuccess =
  (response: any, params: IServerSideGetRowsParams) => (dispatch: AppDispatch, getState: () => RootState) => {
    params.success({
      rowData: response.data.data,
      rowCount: response.data.meta.count,
    });
  };

export const getNextPreviousIndex = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const gridApi = getState().emailRed.gridApi;
  if (!gridApi) {
    return;
  }

  const selectedNodes = gridApi.getSelectedNodes();
  if (!selectedNodes || selectedNodes.length < 1) {
    return;
  }

  const activeRowIndex = selectedNodes[0]?.rowIndex;
  const nextPreviousIndex = {
    nextRowIndex: activeRowIndex < gridApi.getDisplayedRowCount() - 1 ? activeRowIndex + 1 : null,
    previousRowIndex: activeRowIndex - 1,
    activeRowIndex: activeRowIndex,
  };
  dispatch(nextPreviousIndexSetter(nextPreviousIndex));
};

export const fetchEmailDetails = (id: string | number) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    dispatch(emailDetailLoaingSetter(true));
    const response = await restApiService.get("inbound_emails/" + id);
    dispatch(emailDetailLoaingSetter(false));
    dispatch(emailDetailSetter(response.data));
  } catch (error) {
    dispatch(emailDetailLoaingSetter(false));
    console.log(error);
  }
};

export const updateEmailDetailsToProcessed =
  (id: string | number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(emailDetailLoaingSetter(true));
      const { data } = await restApiService.get("inbound_emails/" + id);
      data.status = "Processed";
      dispatch(emailDetailLoaingSetter(false));
      dispatch(emailDetailSetter(data));
    } catch (error) {
      dispatch(emailDetailLoaingSetter(false));
      console.log(error);
    }
  };

export const gridItemClicked = (row: { data: { id: string } }) => (dispatch: AppDispatch) => {
  dispatch(showDetailPageSetter(true));
  dispatch(getNextPreviousIndex());
  dispatch(fetchEmailDetails(row.data.id));
};

export const updateRowData = (id: string | number) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const gridApi = getState().emailRed.gridApi;
    const rowNode = gridApi.getRowNode(id);
    const result = await restApiService.get("inbound_emails/" + id);
    if (_.isPlainObject(result?.data) && rowNode) {
      result.data.attachments_count = result.data.attachments ? result.data.attachments.length : 0;
      rowNode.setData(result.data);
      gridApi.redrawRows({ rowNode: [rowNode] });
    }
  } catch (error) {
    console.log(error);
  }
};

export const selectNextPreviousEmail = (type: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const nextPreviousIndex = getState().emailRed.nextPreviousIndex;

  const gridApi = getState().emailRed.gridApi;
  const emailRowIndex: number | null =
    type === "next" ? nextPreviousIndex.nextRowIndex : nextPreviousIndex.previousRowIndex;

  gridApi.forEachNode(function (node: any, index: number) {
    if (node.rowIndex === emailRowIndex) {
      node.setSelected(true, true);
    }
  });

  dispatch(getNextPreviousIndex());
  dispatch(gridItemClicked(gridApi.getSelectedNodes()[0]));
};

export const markAsCompletedAndNext = (email: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    await markAsCompletedPromise(email.id);
    await dispatch(updateRowData(email.id));

    // get stats after updating status of email
    await dispatch(getEmailStats());

    dispatch(selectNextPreviousEmail("next"));
  } catch (error) {
    console.log(error);
  }
};

export const markAsCompleted = () => (dispatch: AppDispatch, getState: () => RootState) => {
  return new Promise<void>((resolve, reject) => {
    const gridApi = getState().emailRed.gridApi;
    const selectedEmail = gridApi.getSelectedNodes();

    const promises = selectedEmail.map(async (rowNode: any) => {
      try {
        // mark as complete
        const response = await markAsCompletedPromise(rowNode.data.id);

        // update grid row
        await dispatch(updateRowData(rowNode.data.id));

        return response;
      } catch (error) {
        reject(error);
      }
    });

    Promise.all(promises)
      .then(() => {
        // get stats after updating status of email
        dispatch(getEmailStats());
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const refreshEamil = (id: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(fetchEmailDetails(id));
};

export const removeEmailTag =
  ({ emailId, tags }: { emailId: string; tags: string[] }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const data = {
      inbound_email: {
        tag: tags,
      },
    };
    try {
      const response = await restApiService.post("inbound_emails/" + emailId + "/delete_tag", null, data);
      CreateNotification(response.data.id, "Tags removed successfully", "success");
      dispatch(refreshEamil(response.data.id));
    } catch (error) {
      console.log(error);
    }
  };

export const unlinkEmailInvoice =
  ({ emailID, data }: { emailID: string; data: any }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const response = await restApiService.patch("inbound_emails/" + emailID, null, data);
      CreateNotification(response.data.id, "Status updated successfully", "success");
    } catch (error) {
      console.log(error);
    }
  };

export const reprocessEmail = () => (dispatch: AppDispatch, getState: () => RootState) => {
  return new Promise<void>((resolve, reject) => {
    const gridApi = getState().emailRed.gridApi;
    const selectedEmail = gridApi.getSelectedNodes();

    const promises = selectedEmail.map(async (rowNode: any) => {
      try {
        // reprocess email
        const response = await reprocessEmailPromise(rowNode.data.id);

        // update grid row
        const { data } = await restApiService.get("inbound_emails/" + rowNode.data.id);
        data.attachments_count = data.attachments ? data.attachments.length : 0;

        // something response of give status new for fraction of second
        // updating it to processed
        data.status = "Processed";
        rowNode.setData(data);
        gridApi.redrawRows({ rowNode: [rowNode] });

        return response;
      } catch (error) {
        reject(error);
      }
    });

    Promise.all(promises)
      .then(() => {
        // get stats after updating status of email
        dispatch(getEmailStats());

        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// refresh emailPage
export const refreshEmailPage = () => async (dispatch: AppDispatch) => {
  await dispatch(resetCompleteEmailPage());

  // getTags
  await dispatch(getEmailStats());
  await dispatch(getEmailTags());

  // now refetch emails
  await dispatch(refetchEmail());
};

export const applyTagsToEmail = (tags: string[]) => (dispatch: AppDispatch, getState: () => RootState) => {
  return new Promise<void>((resolve, reject) => {
    const gridApi = getState().emailRed.gridApi;
    const selectedEmail = gridApi.getSelectedNodes();

    const promises = selectedEmail.map(async (rowNode: any) => {
      try {
        // update tags email
        const data = {
          inbound_email: {
            tags: tags,
          },
        };
        const response = await restApiService.patch("/inbound_emails/" + rowNode.data.id, null, data);

        // update grid row
        dispatch(updateRowData(rowNode.data.id));

        return response;
      } catch (error) {
        reject(error);
      }
    });

    Promise.all(promises)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};
