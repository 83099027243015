import React from "react";
import config from "../config";
import { CreateNotification, NotificationType } from "../services/general/notifications";
import { SetCookie } from "../services/general/cookies";
import { setup } from "axios-cache-adapter";
import { APP_EVENTS, UNPAUSE_AXIOS, AXIOS_PROCEED, APP_EMITTER } from "./appEmitter";

const BASE_URL = config.apiURL;

let angularURL = config.appURL;
if (window.location.origin.includes("complete-portal-qa.corpay.com")) {
  angularURL = "https://complete-qa.corpay.com/";
} else if (window.location.origin.includes("complete-portal.corpay.com")) {
  angularURL = "https://complete.corpay.com/";
}
export const angularBaseURL = angularURL;

const axios = require("axios");
export const salesForceUrl = config.salesForceUrl;
export const salesForceUrlRedirectUrl = config.salesForceUrlRedirectUrl;
export const salesForceOrgId = config.salesForceOrgId;

// Create `axios` instance with pre-configured `axios-cache-adapter` attached to it
const api = setup({
  // `axios` options
  baseURL: "",

  // `axios-cache-adapter` options
  cache: {
    // data will be cashed for 15min
    maxAge: 15 * 60 * 1000,
    exclude: {
      // api with query string will be cached
      query: false,
    },
  },
});

export default class RestApi {
  /** @type {number} */ // JavaScript JSDoc
  static companyId = null;

  static handleUnauthorized = () => {
    if (
      ["/login", "/logout", "/reset-password", "/portal/oauth_process", "/portal/xero_oauth_process"].includes(
        window.location.pathname,
      ) ||
      ["/ar/virtual-card-view", "/ar/purchase-order-view"].some((path) => window.location.pathname.includes(path))
    ) {
    } else {
      let redirectLocation = config.loginURL;
      if (window.location.origin.includes("complete-portal-qa.corpay.com")) {
        redirectLocation = "https://complete-qa.corpay.com/login";
      } else if (window.location.origin.includes("complete-portal.corpay.com")) {
        redirectLocation = "https://complete.corpay.com/login";
      }
      window.location = redirectLocation;
    }
  };

  //helper function to get complete header, will return with Bearer token if authorization is true
  getHeader = (authorized = true) => {
    const companyId = RestApi.companyId;
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (companyId) {
      header["Company-Id-Nonce"] = companyId;
    }

    let token = localStorage.getItem("Token");
    if (authorized && token) {
      header.Authorization = "Bearer " + token;
    }
    return header;
  };

  request = async (verb, endpoint, getParams, body, authorized = true, responseType = null, cache = false) => {
    if (!AXIOS_PROCEED()) {
      // if app is not done completing mainPage.jsx logic, we PAUSE all requests until checkAuthentication emits its completion
      await new Promise((resolve) => {
        APP_EMITTER.on(APP_EVENTS.AXIOS_RESOLVE, () => resolve()); // resolve once event is received
      });
      APP_EMITTER.removeListener(APP_EVENTS.AXIOS_RESOLVE); // remove the event listener;
    }
    let request = {
      url: BASE_URL + endpoint,
      method: verb,
      headers: this.getHeader(authorized),
    };
    if (responseType) {
      request.responseType = responseType;
    }
    if (getParams) {
      request.params = getParams;
    }
    if (body) {
      request.data = body;
    }

    if (!cache)
      return axios(request).catch(async (error) => {
        this.handleError(error, request);
      });
    if (cache)
      return api(request).catch(async (error) => {
        this.handleError(error, request);
      });
  };

  get = async (endpoint, getParams, body, authorized = true, responseType, cache = false, signal) => {
    // Create a new CancelToken source for this request
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const promise = this.request("GET", endpoint, getParams, body, authorized, responseType, cache, source.token);

    // Cancel the request if TanStack Query signals to abort
    signal?.addEventListener("abort", () => {
      source.cancel("Query was cancelled by TanStack Query");
    });

    return promise;
  };

  post = async (endpoint, getParams, body, authorized = true) => {
    return this.request("POST", endpoint, getParams, body, authorized);
  };

  patch = async (endpoint, getParams, body, authorized = true) => {
    return this.request("PATCH", endpoint, getParams, body, authorized);
  };

  put = async (endpoint, getParams, body, authorized = true) => {
    return this.request("PUT", endpoint, getParams, body, authorized);
  };

  delete = async (endpoint, getParams, body, authorized = true) => {
    return this.request("DELETE", endpoint, getParams, body, authorized);
  };

  handleError = (error, request) => {
    if (error.response && error.response.status === 401 && !request.url.includes("oauth/token")) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // For 401 status we redirect to login
      // This is only a temporary solution
      localStorage.removeItem("userDetails");
      localStorage.removeItem("Token");
      RestApi.handleUnauthorized();
    } else if (
      error.response.status === 422 ||
      error.response.status === 404 ||
      error.response.status === 400 ||
      error.response.status === 403
    ) {
      //will be handle at the form level, except :base
      var errormsg = "";
      for (const key in error.response.data) {
        if (key === "base") {
          errormsg += error.response.data[key] + " ,";
          CreateNotification("Data Issue:", errormsg, NotificationType.danger);
        }
      }
    } else if (error.response.status === -1 || error.response.status === 0) {
      // Do not show an error if we purposefully cancelled the request
      // Beware of funny IF NOT operator here...
      if (
        !(
          error.response.config &&
          error.response.config.timeout &&
          error.response.config.timeout.$$state &&
          error.response.config.timeout.$$state.value === "CancelRestangularRequest"
        )
      ) {
        CreateNotification("Error Details:", "Unable to connect", NotificationType.danger);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
      CreateNotification("Error", "Unknown error occured. Please contact support", NotificationType.danger);
    } else {
      CreateNotification(
        "error",
        "Oops... Looks like something went wrong! If the problem persists feel free to contact us. In the meantime, try refreshing.",
        NotificationType.danger,
      );
    }
    //throw the error again so consumers of this function can handle errors individually as well
    throw error;
  };

  postWithMultipleImages = async (endpoint, formData) => {
    const companyId = RestApi.companyId;
    const TARGET_URL = BASE_URL + endpoint;

    let header = {
      Authorization: "Bearer " + localStorage.getItem("Token"),
      "Content-Type": "multipart/form-data",
    };

    if (companyId) {
      header["Company-Id-Nonce"] = companyId;
    }

    return await fetch(TARGET_URL, {
      method: "POST",
      headers: header,
      body: JSON.stringify(formData),
    })
      .then(this.authCheck)
      .then((response) => {
        return response.json();
      });
  };

  //Since this function modifies the local storage im leaving as seperate function
  updateUserDetail = async () => {
    const TARGET_URL = BASE_URL + "user";
    return fetch(TARGET_URL, {
      method: "GET",
      headers: this.getHeader(),
    })
      .then(this.authCheck)
      .then((response) => {
        return this.storeUserDetail(response);
      });
  };

  //middleware that will check if a response is 401. If it is 401 it will redirect to login page
  authCheck = async (response) => {
    if (response.status === 401 && !response.url.includes("oauth/token")) {
      // This is only a temporary solution
      //localStorage.removeItem('userDetails')
      //window.location = config.logoutURL;
    } else {
      return response;
    }
  };

  storeUserDetail = async (data) => {
    let userDetail = await data.json();
    localStorage.setItem("userDetails", JSON.stringify(userDetail));
    return userDetail;
  };

  formWithImage = async (endpoint, formData, verb, throwDetailedError = false) => {
    let token = "Bearer " + localStorage.getItem("Token");
    const TARGET_URL = BASE_URL + endpoint;
    const companyId = RestApi.companyId;

    let header = {
      Authorization: token,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };

    if (companyId) {
      header["Company-Id-Nonce"] = companyId;
    }

    if (throwDetailedError) {
      try {
        const response = await axios({
          url: TARGET_URL,
          method: verb,
          headers: header,
          data: formData,
        });
        return response;
      } catch (error) {
        if (error.response) {
          throw error.response;
        } else {
          throw new Error(error.message);
        }
      }
    } else {
      // If an error is thrown here it will be a simple text string.
      return axios({
        url: TARGET_URL,
        method: verb,
        headers: header,
        data: formData,
      });
    }
  };

  angularBaseURL = () => {
    return angularBaseURL;
  };
  makeAngularURLWithId = (endpoint, id) => {
    return this.angularBaseURL() + endpoint + id;
  };
}

// rather than creating a new instance every time use this
export const restApiService = new RestApi();
