import React, { useState } from "react";
import styles from "./dashboard.module.css";
import { Col, Row } from "react-bootstrap";
import ExpenseCategory from "./expenseCategory";
import PolicyViolation from "./policyViolation";
import { InjectedFormProps, change, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import {
  getCurrentMonth,
  getCurrentQuarter,
  getLastMonth,
  getPreviousQuarter,
  getPreviousYear,
  getYTD,
} from "services/general/dateSvc";
import { CreateNotification, NotificationType } from "services/general/notifications";
import MonthlySpends from "./monthlySpend";
import TopTenSpendByMerchant from "./topTenSpendByMerchant";
import PostedDateCardProgramFilter from "./postedDateCardProgramFilter";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";

type formDataType = {
  card_program_id?: number;
  posted_date: { value: string; label: string };
  posted_filter_data?: { monthName?: string; startDate: string; endDate: string; selectedOption: string };
  posted_date_custom: { startDate: string; endDate: string; selectedOption: string };
};

const ExpenseCategorySpend = ({ handleSubmit }: InjectedFormProps<formDataType>) => {
  const [showCustom, setShowCustom] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const dispatch = useDispatch();

  const handlePostedDate = ({ value, label }: { value: string; label: string }) => {
    let filterObj: { startDate?: string; endDate?: string; selectedOption?: string } = {};
    setShowCustom(false);
    switch (value) {
      case "current_month":
        filterObj = getCurrentMonth();
        break;
      case "last_month":
        filterObj = getLastMonth();
        break;
      case "current_quarter":
        filterObj = getCurrentQuarter();
        break;
      case "last_quarter":
        filterObj = getPreviousQuarter();
        break;
      case "ytd":
        filterObj = getYTD();
        break;
      case "last_year":
        filterObj = getPreviousYear();
        break;
      case "custom":
        setShowCustom(true);
        dispatch(change("expenseCategoryForm", "posted_date_custom", {}));
        break;
      default:
    }
    if (label) {
      setDateRange(label);
    }
    if (filterObj?.startDate) {
      filterObj.selectedOption = value;
      dispatch(change("expenseCategoryForm", "posted_filter_data", filterObj));
    } else {
      dispatch(change("expenseCategoryForm", "posted_filter_data", {}));
    }
  };

  const validateEndDate = (startDate?: string, endDate?: string) => {
    if (startDate && endDate && new Date(endDate) <= new Date(startDate)) {
      return false;
    }
    return true;
  };

  const handelCustomDate = (data: formDataType) => {
    const startDate = data?.posted_date_custom?.startDate;
    const endDate = data?.posted_date_custom?.endDate;
    if (!validateEndDate(data?.posted_date_custom?.startDate, data?.posted_date_custom?.endDate)) {
      CreateNotification("Date not valid", "End date should be greater that start date", NotificationType.warning);
      return;
    } else {
      dispatch(
        change("expenseCategoryForm", "posted_filter_data", { ...data.posted_date_custom, selectedOption: "custom" }),
      );
    }
    if (startDate && endDate) {
      setDateRange(`${startDate} - ${endDate}`);
    } else if (startDate) {
      setDateRange(`Records from ${startDate}`);
    } else if (endDate) {
      setDateRange(`Records till ${endDate}`);
    }
  };
  const submitCustomDate = handleSubmit(handelCustomDate);

  return (
    <div className="mb-3">
      <PostedDateCardProgramFilter
        handlePostedDate={handlePostedDate}
        showCustom={showCustom}
        submitCustomDate={submitCustomDate}
      />
      <Row>
        <Col lg={6}>
          <Row>
            <Col md={12}>
              <MonthlySpends dateRange={dateRange} />
            </Col>
            {currentUser.is_admin && (
              <Col md={12}>
                <TopTenSpendByMerchant />
              </Col>
            )}
          </Row>
        </Col>
        <Col lg={6}>
          <Row>
            <Col md={12}>
              <ExpenseCategory dateRange={dateRange} />
            </Col>
            {currentUser.is_admin && (
              <Col md={12}>
                <PolicyViolation dateRange={dateRange} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const ExpenseCategorySpends = reduxForm<formDataType>({
  form: "expenseCategoryForm",
})(ExpenseCategorySpend);

export default ExpenseCategorySpends;
