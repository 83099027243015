import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { compare, findSelectedSingle, onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import { states as stateCountryMapping } from "../../../../app.svc.Lookup";
import styles from "./statePicker.module.css";

type InputType = {
  value: string;
  onChange?: (value: any) => void;
  onBlur?: (event: any) => void;
};

type ValueProps = {
  label: string;
  name: string;
  value: any;
};

type StatePickerPropsType = {
  label?: string;
  tooltip?: string;
  required?: boolean;
  placeholder?: string;
  countryCode?: string;
  disabled?: boolean;
  callBack?: (selectedCountry: string) => void;
  input: InputType;
  meta: {
    touched?: boolean;
    error?: string;
  };
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const StatePicker = ({
  label,
  input,
  meta: { touched, error },
  tooltip,
  required,
  disabled,
  placeholder,
  countryCode,
  callBack,
}: StatePickerPropsType) => {
  const [states, setStates] = useState([]);

  const fetchStateOptions = useCallback(() => {
    let fetchedStates: any = [];
    stateCountryMapping
      .map((stateCountryMap) => {
        if (stateCountryMap.value === countryCode) {
          fetchedStates = stateCountryMap.state_list;
        }
      })
      .sort(compare);
    setStates(fetchedStates);
  }, [countryCode]);

  useEffect(() => {
    if (countryCode !== undefined) {
      fetchStateOptions();
    }
  }, [countryCode]);

  const onChangeState = (input: InputType, value: ValueProps) => {
    onChangeSingle(input, value);
    if (callBack) {
      callBack(value.value);
    }
  };
  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      {states && states.length > 0 ? (
        <Select
          {...input}
          required={required}
          value={findSelectedSingle(input, states)}
          placeholder={placeholder}
          onChange={(value) => onChangeState(input, value)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={states}
          isClearable
          classNamePrefix="select"
          menuPlacement="auto"
          minMenuHeight={200}
        />
      ) : (
        <Form.Control
          {...input}
          as="input"
          required={required}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => {
            if (e && input.onChange) {
              input.onChange(e);
            }
          }}
        />
      )}
      {error && touched && <div className={styles.pickerError}>{error}</div>}
    </Form.Group>
  );
};

export default StatePicker;
