import React from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";

const JSONModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.closeCallback}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col>
            <pre>{props.body && JSON.stringify(props.body, null, 2)}</pre>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-primary" onClick={props.closeCallback}>
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default JSONModal;
