// Payment Methods By Vendor Section
import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.css";
import Panel from "components/common/panel/panel";
import DoughnutChart from "components/common/pickers/reduxFormPickers/chartPicker/doughnutPicker";
import { restApiService } from "providers/restApi";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";

type ChartDataPropsType = {
  legands: string[];
  tooltipLabels?: string[];
  count: number[];
  arcData: {};
  backgroundColor: string[];
};
const PaymentMethodsByVendor = () => {
  const CurrentUser: IUser = useTypedSelector((state) => state.user);
  const [chartData, setChartData] = useState<ChartDataPropsType | null>(null);
  const [vendorCount, setVendorCount] = useState<number>(0);

  var totalVendorCount = 0;

  const capitalizePaymentTypesName = (key: string) => {
    if (key === "ach") {
      return "ACH";
    } else if (key === "credit_card" && CurrentUser.company.is_nvp_credit_model) {
      return "Mastercard";
    } else {
      return key.charAt(0).toUpperCase() + key.slice(1).replace(/_./g, (match) => " " + match.charAt(1).toUpperCase());
    }
  };

  // This function manipulates payment method type, capitalizes them, calculates percentages
  // and display percent with payment method type count
  const manipulatePaymentTypesName = (key: string, paymentMethodsCount: Record<string, number>): string => {
    const formattedKey = capitalizePaymentTypesName(key);
    let percentage = ((paymentMethodsCount[key] / totalVendorCount) * 100).toFixed(2);
    return `${formattedKey} ${percentage}%/${paymentMethodsCount[key]}`;
  };

  const calculateChartData = async (paymentMethodsCount: Record<string, number>) =>
    Object.keys(paymentMethodsCount).map((key) => manipulatePaymentTypesName(key, paymentMethodsCount));

  const getTooltipLabels = async (paymentMethodsCount: Record<string, number>) =>
    Object.keys(paymentMethodsCount).map(
      (key) => `${((paymentMethodsCount[key] / totalVendorCount) * 100).toFixed(2)}%/${paymentMethodsCount[key]}`,
    );

  const getDataCountPercent = async (paymentMethodsCount: Record<string, number>) => {
    return Object.values(paymentMethodsCount).map((count: any) => ((count / totalVendorCount) * 100).toFixed(2));
  };

  const calculateTotalVendorCount = (paymentMethodsCount: Record<string, number>) => {
    totalVendorCount = Object.values(paymentMethodsCount).reduce((sum, count) => sum + count, 0);
    setVendorCount(totalVendorCount);
  };

  // formating Chart data to show Payment type name with color box in doughnut chart tooltip
  const formatChartData = (paymentMethodsCount: Record<string, number>) => {
    return Object.entries(paymentMethodsCount).map(([label, count]) => ({
      label: capitalizePaymentTypesName(label),
      count,
    }));
  };

  const getPaymentMethodsByVendorCount = async () => {
    try {
      const response = await restApiService.get("vendors/default_payment_methods_count");
      let chartData = response.data;
      await calculateTotalVendorCount(chartData);

      const legands = await calculateChartData(chartData);
      const tooltipLabels = await getTooltipLabels(chartData);
      const count = await getDataCountPercent(chartData);
      const transformedData = await formatChartData(chartData);

      const obj: ChartDataPropsType = {
        legands,
        arcData: transformedData,
        tooltipLabels: tooltipLabels,
        count: count.map((c) => parseFloat(c)),
        backgroundColor: [
          "rgba(1, 97, 121, 0.6)",
          "rgba(190, 21, 73, 0.6)",
          "rgba(255, 177, 0, 0.6)",
          "rgba(255, 121, 63, 0.6)",
          "rgba(28, 155, 2, 0.6)",
          "rgba(41, 128, 185, 0.6)",
          "rgba(229, 142, 38, 0.6)",
          "rgba(0, 184, 148, 0.6)",
          "rgba(139, 195, 74, 0.6)",
          "rgba(156, 39, 176, 0.6)",
        ],
      };
      setChartData(obj);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPaymentMethodsByVendorCount();
  }, []);

  return (
    <Panel
      cardClass={styles.panelWrapperDoughnutChart}
      cardBodyClass={styles.panelUvp}
      header={
        <div className={styles.panelTitle}>
          <i className={`icon icon-money-stack px-mt-3 px-mr-10`}></i>Payment Methods By Vendor
        </div>
      }
      cardHeaderClass={styles.noPadding}
    >
      {chartData?.count !== undefined && vendorCount > 0 && (
        <div style={{ marginTop: "20px" }} className="chart-container">
          <DoughnutChart heading="Total Vendors" countValue={vendorCount} data={chartData} />
        </div>
      )}
    </Panel>
  );
};

export default PaymentMethodsByVendor;
