import { AxiosResponse } from "axios";
import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useTypedSelector } from "reducers";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { compare } from "services/general/helpers";
import { DepartmentPickerPropsTypes } from "../departmentPicker";
import { BudgetListOptionsType } from "./singleBudgetPicker";

interface MultiDepartmentPickerPropTypes extends DepartmentPickerPropsTypes {
  callBack?: (budget: any) => void;
  input: any;
}

const MultipleBudgetPicker = ({
  label,
  input,
  labelClassName,
  meta: { touched, error },
  className,
  disabled,
  instanceId = "multi-budget-selector",
  placeholder,
  required,
  tooltip,
  modelData,
  parentObjData,
  callBack,
}: MultiDepartmentPickerPropTypes) => {
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [budgets, setBudgets] = useState<BudgetListOptionsType[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const mounted = useIsMounted();

  const parseBudgetForSelect = (options: BudgetListOptionsType[]) => {
    return options
      .map((option: BudgetListOptionsType) => {
        return {
          value: option.id,
          label: option?.budget?.label,
          code: option?.budget?.code,
          ...option,
        };
      })
      .sort(compare);
  };

  const allowBudgetMultiCurrency = () => {
    return currentUser?.company?.budget?.allow_multi_currency_budget;
  };

  const allowBudgetFilter = () => {
    return currentUser?.company?.budget?.show_field_at === "line_level";
  };

  const findBudgetItem = (budget: BudgetListOptionsType, section?: string) => {
    if (section === "link") {
      return _.find(modelData?.budget_item_links, { budget_item_id: budget.id });
    } else {
      return _.find(budgets, { id: budget.id });
    }
  };

  const getUniqueList = (budgets: BudgetListOptionsType[]) => {
    let uniqueList: BudgetListOptionsType[] = [];
    budgets?.forEach((budget) => {
      if (!findBudgetItem(budget, "link")) {
        uniqueList.push(budget);
      }
    });
    return uniqueList;
  };

  const onBlur = () => {
    if (!input.value || !input.value.length) {
      input.onBlur([]);
      return;
    }
    input.onBlur(input.value);
  };

  const onChange = (input: any, selectedOptions: any) => {
    const budget_item_links = selectedOptions?.map((option: BudgetListOptionsType) => {
      const selectedRecord = modelData?.budget_item_links?.find((link: any) => link.budget_item_id === option.id);
      return selectedRecord ? selectedRecord : { budget_item_id: option.id, budget_item: option };
    });

    let updatedBudgetItemLinks = [...budget_item_links];
    if (input.value) {
      const removedOptions = input.value?.filter(
        (option: BudgetListOptionsType) =>
          !selectedOptions.some((selected: any) => selected.id === option.budget_item_id),
      );
      removedOptions?.forEach((option: BudgetListOptionsType) => (option._destroy = 1));

      updatedBudgetItemLinks = [...updatedBudgetItemLinks, ...removedOptions];
    }

    if (callBack) {
      callBack(updatedBudgetItemLinks);
    }

    setSelectedValues(selectedOptions);
  };

  const getBudgets = useCallback(async () => {
    let apiParams: any = { active: true };

    if (modelData?.account_id && allowBudgetFilter()) {
      apiParams.account_id = modelData?.account_id;
    }

    if (modelData?.department_id) {
      apiParams.department_id = modelData?.department_id;
    }

    if (parentObjData?.currency_code && !allowBudgetMultiCurrency()) {
      apiParams.currency_code = parentObjData?.currency_code;
    }

    if (parentObjData?.vendor_id && allowBudgetMultiCurrency()) {
      apiParams.vendor_id = parentObjData?.vendor_id;
    }

    const result: AxiosResponse<BudgetListOptionsType[]> = await restApiService.get(
      "budgets/budget_items",
      apiParams,
      null,
      true,
      null,
      true,
    );
    if (result && result.data) {
      let budgetItemList = getUniqueList(result.data);

      let budgetList = parseBudgetForSelect(budgetItemList);
      if (mounted.current) setBudgets(budgetList);
    }
  }, [modelData?.account_id, modelData?.department_id, parentObjData?.currency_code, parentObjData?.vendor_id]);

  const initBudgetItemLinks = () => {
    let itemLinks: any = [];
    if (modelData?.budget_item_links && modelData?.budget_item_links.length > 0) {
      modelData?.budget_item_links.forEach((link: any) => {
        if (link._destroy !== 1) {
          let obj = link.budget_item ? link.budget_item : link;
          if (link.budget) {
            obj.budget = link.budget;
            obj.end_date = obj.end_date ? obj.end_date : link.budget.end_date;
          }
          itemLinks.push(obj);
          let budgetList = parseBudgetForSelect(itemLinks);
          setSelectedValues(budgetList);
        }
      });
    }
  };

  useEffect(() => {
    if (modelData?.department_id) {
      getBudgets();
    }
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [modelData?.account_id, modelData?.department_id, parentObjData?.currency_code, parentObjData?.vendor_id]);

  useEffect(() => {
    initBudgetItemLinks();
  }, []);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label className={labelClassName}>
            {label}
            {tooltip}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={selectedValues}
          placeholder={placeholder}
          onChange={(value) => onChange(input, value)}
          onBlur={() => onBlur()}
          options={budgets}
          isMulti
          isClearable={false}
          classNamePrefix="select"
          isDisabled={disabled}
          instanceId={instanceId}
          onMenuOpen={() => setIsDropdownOpen(true)}
          onMenuClose={() => setIsDropdownOpen(false)}
          getOptionLabel={(option: any) => {
            const currencySymbol = currencySymbolRenderer(option.currency_code);
            return (
              <small>
                <>
                  {!isDropdownOpen && <span> {`${option.code}, ${currencySymbol}${option.amount}`}</span>}
                  {isDropdownOpen && (
                    <>
                      <span>{`${option.label} / Code: ${option.code}`}</span>
                      <br />
                      <span>
                        {`Amount: ${currencySymbol}${option.amount}`} / {` Balance: ${currencySymbol}${option.balance}`}
                      </span>
                      <br />
                      <span>
                        {`Effective Date: ${companyDateFormat(option.effective_date, currentUser)}`} /{" "}
                        {`End Date: ${companyDateFormat(option.end_date, currentUser)}`}
                      </span>
                    </>
                  )}
                </>
              </small>
            );
          }}
          filterOption={(option: any, searchText) => {
            return (
              option?.data?.label?.toLowerCase().includes(searchText.toLowerCase()) ||
              option?.data?.code?.toLowerCase().includes(searchText.toLowerCase())
            );
          }}
        />
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default MultipleBudgetPicker;
