import { AppDispatch } from "..";
import { IPurchaser } from "../../services/vp/types/purchasers.types";
import {
  SET_VENDOR_GUIDE_STEP,
  SET_GUIDE_COMPANY_INFO,
  SET_GUIDE_PURCHASERS,
  SET_GUIDE_SETUP_PROGRESS,
  SET_SHOW_WELCOME,
  SET_SHOW_GUIDE,
  RESET_VENDOR_SETUP_GUIDE,
  SET_VENDOR_DOCUMENT_LINKS,
  ON_LOG_OUT,
} from "../../actions/actionTypes";
import { vendorDocumentRequirementType } from "../../services/vp/types/documents.type";
import { restApiService } from "../../providers/restApi";
import { AxiosResponse } from "axios";
import { companyInfoType } from "../../services/vp/types/company.types";

export const VENDOR_PORTAL_STEPPER_SHOWN = "vendorPortalStepperShown";

export type setupProgressType = {
  bank_info: boolean;
  company_detail: boolean;
  contact_info: boolean;
  has_w8ben: boolean;
  has_w9: boolean;
  required_documents_completed: boolean;
};

// INITIAL_STATE
const initialState = {
  showWelcome: false,
  showGuide: false,

  guideStep: 1,

  setupProgress: {
    bank_info: false,
    company_detail: false,
    contact_info: false,
    has_w8ben: false,
    has_w9: false,
    required_documents_completed: false,
  } as unknown as setupProgressType,

  purchasers: [] as IPurchaser[],
  companyInfo: {} as companyInfoType,
  vendorDocumentLinks: [] as vendorDocumentRequirementType[],
};
const vendorSetupGuideReducer = (state = initialState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case SET_SHOW_WELCOME:
      return { ...state, showWelcome: payload };
    case SET_SHOW_GUIDE:
      return { ...state, showGuide: payload };
    case SET_VENDOR_GUIDE_STEP:
      return { ...state, guideStep: payload };
    case SET_GUIDE_SETUP_PROGRESS:
      return { ...state, setupProgress: payload };
    case SET_GUIDE_PURCHASERS:
      return { ...state, purchasers: payload };
    case SET_GUIDE_COMPANY_INFO:
      return { ...state, companyInfo: payload };
    case SET_VENDOR_DOCUMENT_LINKS:
      return { ...state, vendorDocumentLinks: payload };
    case ON_LOG_OUT:
      localStorage.setItem(VENDOR_PORTAL_STEPPER_SHOWN, "false");
      return state;
    case RESET_VENDOR_SETUP_GUIDE:
      const { setupProgress, purchasers } = state; // resetting purchasers cauasing flicker
      return { ...initialState, setupProgress, purchasers };
    default:
      return state;
  }
};

export default vendorSetupGuideReducer;

// action dispatchers
export const setVendorGuideStep = (stepNumber: number) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_VENDOR_GUIDE_STEP, payload: stepNumber });
};

export const getSetupProgress =
  (onSuccess?: (response: AxiosResponse<setupProgressType>) => void, onError?: (error: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse<setupProgressType> = await restApiService.get(
        "vendor_invoices/dashboard_profile_progress",
      );
      dispatch(setGuideSetupProgress(response.data));
      if (onSuccess) {
        onSuccess(response);
      }
      return response;
    } catch (error) {
      if (onError) {
        onError(error);
      }
      console.log(error);
    }
  };

export const getGuidePurchasers =
  (onSuccess?: (response: AxiosResponse<IPurchaser[]>) => void, onError?: (error: any) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse<IPurchaser[]> = await restApiService.get("purchasers");
      dispatch(setGuidePurchasers(response.data));
      if (onSuccess) {
        onSuccess(response);
      }
      return response;
    } catch (error) {
      if (onError) {
        onError(error);
      }
      console.log(error);
    }
  };

export const setGuideSetupProgress = (setupProgress: setupProgressType) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_GUIDE_SETUP_PROGRESS, payload: setupProgress });
};

export const setGuidePurchasers = (purchasers: any) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_GUIDE_PURCHASERS, payload: purchasers });
};

export const setGuideCompanyInfo = (companyInfo: companyInfoType) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_GUIDE_COMPANY_INFO, payload: companyInfo });
};

export const setShowWelcome = (showWelcome: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_SHOW_WELCOME, payload: showWelcome });
};

export const setShowGuide = (showGuide: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: SET_SHOW_GUIDE, payload: showGuide });
};

// reset guide
export const resetVendorSetupGuide = () => (dispatch: AppDispatch) => {
  dispatch({ type: RESET_VENDOR_SETUP_GUIDE });
};

export const setVendorDocumentLinks =
  (vendorDocumentLinks: vendorDocumentRequirementType[]) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_VENDOR_DOCUMENT_LINKS, payload: vendorDocumentLinks });
  };
