import React, { Component } from "react";
import { Spinner, Row, Col, Container } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import RestApi from "../../../../providers/restApi";
import PageTitle from "../../../../components/page/pageTitle";
import ObjectStatus from "../../../../components/objects/objectStatus";
import Panel from "../../../../components/panel/panel";
import { BreadcrumbTrail } from "../../../../components/navigation/navigationLinks";

class ShowFraudAudit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.fetchFraudAudit();
  }

  fetchFraudAudit = async () => {
    this.setState({ loading: true });
    this.restApiService
      .get("fraud_audits/" + this.props.match.params.id)
      .then(async (val) => {
        this.setState({ loading: false, fraudAudit: val.data });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  getObjectLinkRenderer = (fraudAudit) => {
    if (!fraudAudit) {
      return;
    }

    let link = fraudAudit.vendor ? "/vendors/" + fraudAudit.vendor.id : null;
    let name = fraudAudit.object_type;

    return (
      <a className="" href={this.restApiService.angularBaseURL() + link}>
        {name}
      </a>
    );
  };

  render() {
    let fraudAudit = this.state.fraudAudit || {};
    return (
      <div>
        {this.state.loading && <Spinner animation="border" size="sm" variant="info" />}

        {/* loaded */}
        {!this.state.loading && (
          <Container fluid={true} className={"pt-4 pb-4"}>
            <Row>
              <Col>
                <BreadcrumbTrail to={"/ap/fraud_audits"} pageName={"Fraud Audits"} title={"Fraud Audit Details"} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex align-items-center">
                <PageTitle title={fraudAudit && fraudAudit.id} />
                <ObjectStatus status={fraudAudit && fraudAudit.status} />
              </Col>
            </Row>
            <Row>
              <Col>
                <hr className="full-border" />
              </Col>
            </Row>

            <Panel>
              <Row>
                <Col sm className="pr-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">ID</dt>
                    <dd className="col-6 col-sm-7 detailValue">{fraudAudit.id}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">Result</dt>
                    <dd className="col-6 col-sm-7 detailValue">{fraudAudit.status}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">Action</dt>
                    <dd className="col-6 col-sm-7 detailValue">{fraudAudit.action}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">Object</dt>
                    <dd className="col-6 col-sm-7 detailValue">{this.getObjectLinkRenderer(fraudAudit)}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">Object Id</dt>
                    <dd className="col-6 col-sm-7 detailValue">{fraudAudit && fraudAudit.object_id}</dd>
                    {/* <dd className="col-6 col-sm-7 detailValue">
                      <Link className="primaryLinkColor" variant="primary" to={"/ar/purchasers/" + (invoice.purchaser && invoice.purchaser.id)}>
                        {invoice.purchaser && invoice.purchaser.name}
                      </Link>
                    </dd> */}
                  </dl>
                </Col>
                <Col sm className="pr-sm-0 pl-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">Vendor</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {fraudAudit && fraudAudit.vendor && fraudAudit.vendor.name}
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">Compare</dt>
                    <dd className="col-6 col-sm-7 detailValue">{fraudAudit.data && fraudAudit.data.compare}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">Compare To</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {fraudAudit.data && fraudAudit.data.compare_to && JSON.stringify(fraudAudit.data.compare_to)}
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">Match</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {fraudAudit &&
                        fraudAudit.result &&
                        fraudAudit.result.match &&
                        JSON.stringify(fraudAudit.result.match)}
                    </dd>
                  </dl>
                </Col>

                <Col sm className="pr-sm-0 pl-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">Created At</dt>
                    <dd className="col-6 col-sm-7 detailValue">{fraudAudit && fraudAudit.created_at}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">Updated At</dt>
                    <dd className="col-6 col-sm-7 detailValue">{fraudAudit && fraudAudit.updated_at}</dd>
                  </dl>
                </Col>
              </Row>
            </Panel>
          </Container>
        )}
        {/* End loaded */}
      </div>
    );
  }
}

export default withTranslation()(ShowFraudAudit);
