import React from "react";
import { withRouter } from "react-router-dom";

class CurrencyCellRenderer extends React.Component {
  render() {
    return <div>{this.props.data.currency.symbol + this.props.value}</div>;
  }
}

export default withRouter(CurrencyCellRenderer);
