import RestApi from "../../providers/restApi";

const restApiService: RestApi = new RestApi();

const addVendorAdminRole = (contactId: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restApiService.post("contacts/" + contactId + "/system_roles/vendor_admin", null, {
        id: "vendor_admin",
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

const removeVendorAdminRole = (contactId: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restApiService.delete("contacts/" + contactId + "/system_roles/vendor_admin", null, {
        id: "vendor_admin",
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const addOrRemoveVendorAdmin = (vendorAdmin: boolean, startedAsVendorAdmin: boolean, contactId: string) => {
  if (vendorAdmin && !startedAsVendorAdmin) {
    return addVendorAdminRole(contactId);
  } else if (!vendorAdmin && startedAsVendorAdmin) {
    return removeVendorAdminRole(contactId);
  }
};
