import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { WrappedFieldInputProps, WrappedFieldProps } from "redux-form";
import { findSelectedSingle } from "services/general/helpers";
import styles from "../override.module.css";

type IntegrationSettingOptionType = {
  id?: number;
  application_type?: string;
  description?: string;
  system_name?: string;
  status?: string;
  value?: number;
};

export interface IntegrationSettingsType extends WrappedFieldProps {
  labelClassName?: string;
  label?: string;
  isClearable?: boolean;
}

const formatOptions = (settings: IntegrationSettingOptionType[]) => {
  return settings?.map((setting) => {
    return {
      value: setting.id,
      ...setting,
    };
  });
};
const filterOption = (option: { data: IntegrationSettingOptionType }, input: string): boolean => {
  const { id, system_name, status } = option.data;
  const inputValue = input.toLowerCase();

  const matchesId = id?.toString().includes(inputValue) || false;
  const matchesSystemName = system_name?.toLowerCase().includes(inputValue) || false;
  const matchesStatus = status?.toLocaleLowerCase().includes(inputValue) || false;

  return matchesId || matchesSystemName || matchesStatus;
};

const onBlurSingle = (input: WrappedFieldInputProps, option: IntegrationSettingOptionType) => {
  if (!option) {
    input.onBlur(null);
    return;
  }
  input.onBlur(option);
};

const onChangeSingle = (input: WrappedFieldInputProps, option: SingleValue<IntegrationSettingOptionType>) => {
  if (!option) {
    input.onChange(null);
    return;
  }
  input.onChange(option.value);
};

const IntegrationSettings = ({
  input,
  labelClassName,
  label,
  isClearable,
  meta: { touched, error, warning },
}: IntegrationSettingsType) => {
  const [integrationSettings, setIntegrationSettings] = useState<IntegrationSettingOptionType[]>([]);

  const getIntegrationSetting = async () => {
    try {
      const response: AxiosResponse<IntegrationSettingOptionType[]> = await restApiService.get(
        "integration_settings",
        null,
        null,
        true,
        null,
        true,
      );
      const data = response.data;
      setIntegrationSettings(formatOptions(data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIntegrationSetting();
  }, []);

  return (
    <>
      <Form.Group className={styles.select}>
        {label && <Form.Label className={labelClassName}> {label ?? ""}</Form.Label>}
        <Select
          {...input}
          value={findSelectedSingle(input, integrationSettings)}
          options={integrationSettings}
          onChange={(value) => {
            onChangeSingle(input, value);
          }}
          onBlur={() => onBlurSingle(input, input.value)}
          formatOptionLabel={(option: IntegrationSettingOptionType, context: any) =>
            context.context == "menu" ? (
              <>
                <small className="selectChoices">
                  <strong>Id:</strong> <span>{option.id}</span>
                  <br />
                  <strong>System name:</strong> <span>{option.system_name}</span>
                  <br />
                  <strong>Status:</strong> <span>{option.status}</span>
                </small>
              </>
            ) : (
              <span>
                {option.id} {option.system_name}
              </span>
            )
          }
          getOptionValue={(option: IntegrationSettingOptionType) => `${option.id}`}
          filterOption={filterOption}
          isClearable={isClearable}
        />

        {error && touched && <div className="pickerError">{error}</div>}
      </Form.Group>
    </>
  );
};

export default IntegrationSettings;
