import paymentTypeService from "../../../services/common/paymentMethod/paymentTypeSvc";
import { translate } from "../../../services/general/translation";
const vendorPurchasersHeader = [
  {
    field: "selectAll",
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    maxWidth: 40,
    minWidth: 40,
    menuTabs: [],
  },
  {
    field: "id",
    headerName: "Customer ID",
    // hide:true,

    // sortable: true ,
    // filter: true,
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,

      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("customerId");
    },
  },
  {
    field: "external_id",
    headerName: "External ID",
    hide: true,
    headerValueGetter: function () {
      return translate("externalId");
    },
  },

  {
    field: "name",
    headerName: "Customer",
    // headerCheckboxSelection: true,
    // headerCheckboxSelectionFilteredOnly: true,
    // checkboxSelection: true,
    cellRenderer: "childCellRouting",

    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,

      suppressAndOrCondition: true,
    },
    // cellStyle: {
    //     color: '#FF5C35',
    //     fontFamily: 'Lato',
    //     fontSize: 14,
    //     fontWeight: 'bold'
    // }
    headerValueGetter: function () {
      return translate("customer");
    },
  },
  {
    field: "associated_contacts",
    headerName: "Assigned Contact",
    cellRenderer: "ContactArrayRenderer",
  },
  {
    field: "linked_payment_method",
    headerName: "Linked Payment Method",
    valueGetter: (params) => {
      const data = params?.data;
      if (
        data &&
        ((data.linked_payment_methods && data.linked_payment_methods.length > 0) ||
          (data.has_only_corpay_nvp_integration && data.vendor_default_payment_method.length > 0))
      ) {
        let pm = params.data.has_only_corpay_nvp_integration
          ? params.data.vendor_default_payment_method
          : params.data.vendor_linked_payment_methods[0];
        if (pm == undefined) return "none";
        let payment_type = pm.payment_type;
        if (params.data.has_only_corpay_nvp_integration && pm.payment_type == "credit_card") {
          payment_type = "Mastercard";
        } else if (pm.payment_type == "credit_card") {
          payment_type = "Virtual Card";
        }
        let labelArray = [pm.bank_name, pm.account_name, payment_type, pm.account_type, pm.account_number];
        return labelArray.filter((i) => i != undefined && i != labelArray.last).join("-");
      } else {
        return "none";
      }
    },
  },
  {
    // field:'',
    field: "Actions",
    cellRenderer: "childMessageRenderer",
    sortable: false,
    resizable: false,
    width: 250,
    maxWidth: 250,
    // pinned: 'right',
    cellRendererSelector: function (params) {
      return;
    },
    headerValueGetter: function () {
      return translate("actions");
    },
  },
];
export default vendorPurchasersHeader;
