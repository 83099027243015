import AccrualJournalEntryListing from "./reportFilterGroups/accrual_journal_entry_listing";
import AccrualListing from "./reportFilterGroups/accrual_listing";
import AccrualRollforward from "./reportFilterGroups/accrual_rollforward";
import AccruedItemListing from "./reportFilterGroups/accrued_item_listing";
import AlertsAndNotifications from "./reportFilterGroups/alerts_and_notifications";
import AuditReport from "./reportFilterGroups/audit_report";
import BridgeBankPositivePay from "./reportFilterGroups/bridge_bank_positive_pay";
import BudgetDetailedListing from "./reportFilterGroups/budget_detailed_listing";
import BudgetListing from "./reportFilterGroups/budget_listing";
import CardListing from "./reportFilterGroups/card_listing";
import CardSpendAndInterchange from "./reportFilterGroups/card_spend_and_interchange";
import CardholderUsage from "./reportFilterGroups/cardholder_usage";
import CatalogueItemListing from "./reportFilterGroups/catalogue_item_listing";
import CommentListing from "./reportFilterGroups/comment_listing";
import CompanyListing from "./reportFilterGroups/company_listing";
import ContactListing from "./reportFilterGroups/contact_listing";
import CreditMemoListing from "./reportFilterGroups/credit_memo_listing";
import CustomFilters from "./reportFilterGroups/custom";
import DocumentListing from "./reportFilterGroups/document_listing";
import ExpenseApproverDetail from "./reportFilterGroups/expense_approver_detail";
import ExpenseItemListing from "./reportFilterGroups/expense_item_listing";
import ExpenseReportDetailedListing from "./reportFilterGroups/expense_report_detailed_listing";
import ExpenseReportListing from "./reportFilterGroups/expense_report_listing";
import ExpenseTransactionCoding from "./reportFilterGroups/expense_transaction_coding";
import ExpenseTransactionDetail from "./reportFilterGroups/expense_transaction_detail";
import ExpenseUserInfo from "./reportFilterGroups/expense_user_info";
import FirstRepublicBankPositivePay from "./reportFilterGroups/first_republic_bank_positive_pay";
import GpCcTransactionsListing from "./reportFilterGroups/gp_cc_transactions_listing";
import GpReimTransactionsListing from "./reportFilterGroups/gp_reim_transactions_listing";
import HistoricalFxRatesListing from "./reportFilterGroups/historical_fx_rates_listing";
import InboundEmailRecon from "./reportFilterGroups/inbound_email_recon";
import InvoiceApAging from "./reportFilterGroups/invoice_ap_aging";
import InvoiceDetailedListing from "./reportFilterGroups/invoice_detailed_listing";
import InvoiceItemListing from "./reportFilterGroups/invoice_item_listing";
import InvoiceListing from "./reportFilterGroups/invoice_listing";
import InvoiceOcrProcessing from "./reportFilterGroups/invoice_ocr_processing";
import LineItemAggregateListing from "./reportFilterGroups/line_item_aggregate_listing";
import LineItemMatchListing from "./reportFilterGroups/line_item_match_listing";
import MasterInvoiceListing from "./reportFilterGroups/master_invoice_listing";
import MissingGmailListing from "./reportFilterGroups/missing_gmail_listing";
import NvoicepayVendorListing from "./reportFilterGroups/nvoicepay_vendor_listing";
import OpenPoListing from "./reportFilterGroups/open_po_listing";
import OutstandingCheckListing from "./reportFilterGroups/outstanding_check_listing";
import PaymentListing from "./reportFilterGroups/payment_listing";
import PaymentMethodAudit from "./reportFilterGroups/payment_method_audit";
import PaymentMethodListing from "./reportFilterGroups/payment_method_listing";
import PaymentRefundListing from "./reportFilterGroups/payment_refund_listing";
import PaymentReissueListing from "./reportFilterGroups/payment_reissue_listing";
import PaymentRun from "./reportFilterGroups/payment_run";
import PoExpense from "./reportFilterGroups/po_expense";
import PoItemListing from "./reportFilterGroups/po_item_listing";
import PoListing from "./reportFilterGroups/po_listing";
import PositivePay from "./reportFilterGroups/positive_pay";
import ReceiptImage from "./reportFilterGroups/receipt_image";
import ReceiptListing from "./reportFilterGroups/receipt_listing";
import SpendByVendorListing from "./reportFilterGroups/spend_by_vendor_listing";
import StripeFxXb from "./reportFilterGroups/stripe_fx_xb";
import SvbCdfStatement from "./reportFilterGroups/svb_cdf_statement";
import SvbListing from "./reportFilterGroups/svb_listing";
import SyncErrorListing from "./reportFilterGroups/sync_error_listing";
import TransactionApprovalsListing from "./reportFilterGroups/transaction_approvals_listing";
import TransactionsSyncError from "./reportFilterGroups/transactions_sync_error";
import UnprocessedNvpTransaction from "./reportFilterGroups/unprocessed_nvp_transactions";
import UsBankCheckListing from "./reportFilterGroups/us_bank_check_listing";
import UserListing from "./reportFilterGroups/user_listing";
import VcardPurchaseListing from "./reportFilterGroups/vcard_purchase_listing";
import VendorCompletenessListing from "./reportFilterGroups/vendor_completeness_listing";
import VendorContactListing from "./reportFilterGroups/vendor_contact_listing";
import VendorListing from "./reportFilterGroups/vendor_listing";
import VirtualCardSpendByMerchantListing from "./reportFilterGroups/virtual_card_spend_by_merchant_listing";
import VirtualCardTransactionsListing from "./reportFilterGroups/virtual_card_transactions_listing";
import VisaSBalanceDetail from "./reportFilterGroups/visa_s_balance_detail";
import VisaSTransactionsListing from "./reportFilterGroups/visa_s_transactions_listing";

type ReportTemplateFiltersType = {
  [key: string]: any;
};

const reportTemplateFilters: ReportTemplateFiltersType = {
  custom: CustomFilters,
  alerts_and_notification: AlertsAndNotifications,
  gp_cc_transactions_listing: GpCcTransactionsListing,
  gp_reim_transactions_listing: GpReimTransactionsListing,
  accrual_listing: AccrualListing,
  accrual_rollforward: AccrualRollforward,
  accrued_item_listing: AccruedItemListing,
  accrual_journal_entry_listing: AccrualJournalEntryListing,
  audit_report: AuditReport,
  bridge_bank_positive_pay: BridgeBankPositivePay,
  budget_detailed_listing: BudgetDetailedListing,
  budget_listing: BudgetListing,
  card_listing: CardListing,
  card_spend_and_interchange: CardSpendAndInterchange,
  catalogue_item_listing: CatalogueItemListing,
  comment_listing: CommentListing,
  company_listing: CompanyListing,
  contact_listing: ContactListing,
  credit_memo_listing: CreditMemoListing,
  expense_item_listing: ExpenseItemListing,
  expense_report_detailed_listing: ExpenseReportDetailedListing,
  expense_report_listing: ExpenseReportListing,
  first_republic_bank_positive_pay: FirstRepublicBankPositivePay,
  historical_fx_rates_listing: HistoricalFxRatesListing,
  inbound_email_recon: InboundEmailRecon,
  invoice_ap_aging: InvoiceApAging,
  invoice_detailed_listing: InvoiceDetailedListing,
  invoice_item_listing: InvoiceItemListing,
  invoice_listing: InvoiceListing,
  invoice_ocr_processing: InvoiceOcrProcessing,
  line_item_aggregate_listing: LineItemAggregateListing,
  line_item_match_listing: LineItemMatchListing,
  master_invoice_listing: MasterInvoiceListing,
  missing_gmail_listing: MissingGmailListing,
  nvoicepay_vendor_lisitng: NvoicepayVendorListing,
  open_po_listing: OpenPoListing,
  outstanding_check_listing: OutstandingCheckListing,
  payment_listing: PaymentListing,
  payment_method_audit: PaymentMethodAudit,
  payment_method_listing: PaymentMethodListing,
  payment_refund_listing: PaymentRefundListing,
  payment_reissue_listing: PaymentReissueListing,
  payment_run: PaymentRun,
  po_expense: PoExpense,
  po_item_listing: PoItemListing,
  po_listing: PoListing,
  positive_pay: PositivePay,
  spend_by_vendor_listing: SpendByVendorListing,
  stripe_fx_xb: StripeFxXb,
  svb_cdf_statement: SvbCdfStatement,
  svb_listing: SvbListing,
  sync_error_listing: SyncErrorListing,
  transaction_approvals_listing: TransactionApprovalsListing,
  unprocessed_nvp_transactions: UnprocessedNvpTransaction,
  us_bank_check_listing: UsBankCheckListing,
  user_listing: UserListing,
  vcard_purchase_listing: VcardPurchaseListing,
  vendor_completeness_listing: VendorCompletenessListing,
  vendor_contact_listing: VendorContactListing,
  vendor_listing: VendorListing,
  virtual_card_spend_by_merchant_listing: VirtualCardSpendByMerchantListing,
  virtual_card_transactions_listing: VirtualCardTransactionsListing,
  visa_s_balance_detail: VisaSBalanceDetail,
  visa_s_transactions_listing: VisaSTransactionsListing,
  cardholder_usage: CardholderUsage,
  receipt_listing: ReceiptListing,
  transactions_sync_error: TransactionsSyncError,
  document_listing: DocumentListing,
  expense_transaction_detail: ExpenseTransactionDetail,
  expense_user_information: ExpenseUserInfo,
  expense_approver_detail: ExpenseApproverDetail,
  expense_transaction_coding: ExpenseTransactionCoding,
  receipt_image: ReceiptImage,
};

export default reportTemplateFilters;
