import { MetadataConfiguration } from "pages/admin/administration/metadata/metadataConfiguration";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { BaseFieldProps, WrappedFieldProps } from "redux-form";
import Styles from "../override.module.css";

// Component props
interface MetadataPickerProps extends BaseFieldProps {
  name: string; // Name of the field to be used by redux-form Field
  isMulti: boolean; // Determines if the select is multi-select
  metadataConfiguration: MetadataConfiguration;
  callBack?: (metadata: any) => void;
  className?: any;
  appendOnParent?: boolean;
}

// Custom Input component to be used with redux-form Field
const MetadataFieldPicker: React.FC<WrappedFieldProps & MetadataPickerProps> = ({
  input,
  metadataConfiguration,
  isMulti,
  callBack,
  className,
  appendOnParent,
}) => {
  const [initialValue, setInitialValue] = useState<any>(null);

  // if there is a value already set by api we need to search for it in case its not auto populated on the dropdown
  useEffect(() => {
    if (initialValue) return;
    const fetchInitialValues = async () => {
      if (!input || !input.value) {
        return;
      }
      // Check if input.value is an array and handle it accordingly
      const valueIsArray = Array.isArray(input.value);
      const ids = valueIsArray
        ? input.value.map((val: any) => val.value)
        : [input.value && input.value.value ? input.value.value : input.value];
      try {
        const promises = ids.map((id: any) => restApiService.get("metadata_fields", { id: id }));
        const responses = await Promise.all(promises);
        const initialValues = responses
          .map((response, index) => {
            response = response.data?.data ? response.data.data : response.data;
            return {
              value: ids[index],
              label: response.find((field: any) => field.id === ids[index])?.name,
              metadata_configuration_id: metadataConfiguration?.id,
            };
          })
          .filter((initVal) => initVal.label); // Ensure we only keep valid responses
        setInitialValue(isMulti ? initialValues : initialValues[0] || null);
      } catch (error) {
        console.error("Error fetching initial metadata fields:", error);
      }
    };
    fetchInitialValues();
  }, [input?.value, metadataConfiguration?.id, isMulti]);

  const fetchMetadataFields = async (inputValue: string) => {
    try {
      let sort = { name: { direction: "asc", priority: 0 } };
      let response = await restApiService.get("metadata_fields", {
        field_id: metadataConfiguration?.field_id,
        name: inputValue,
        sort: sort,
        size: 30,
      });
      response = response.data?.data ? response.data.data : response.data;
      return response.map((field: any) => ({
        value: field.id,
        label: field.name,
        metadata_configuration_id: metadataConfiguration?.id,
      }));
    } catch (error) {
      console.error("Error fetching metadata fields:", error);
      return [];
    } finally {
    }
  };

  const handleLoadOptions = (inputValue: string) => fetchMetadataFields(inputValue);

  const handleChange = (selectedOption: any) => {
    input?.onChange(selectedOption);
    if (callBack) {
      callBack(selectedOption);
    }
    setInitialValue(selectedOption);
  };

  return (
    <Form.Group className={Styles.select}>
      <AsyncSelect
        cacheOptions
        defaultOptions
        loadOptions={handleLoadOptions}
        onChange={handleChange}
        onBlur={() => input?.onBlur(input?.value)}
        isMulti={isMulti}
        isSearchable={true}
        menuPortalTarget={appendOnParent ? undefined : document.querySelector("body")}
        classNamePrefix="select"
        className={className}
        value={initialValue}
      />
    </Form.Group>
  );
};

export default MetadataFieldPicker;
