import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { CALENDAR_EVENT_FORM } from "../../../reducers/admin/calendarEventerReducer";
import { required } from "../../../services/validations/reduxFormValidation";
import { RenderField, RenderSelect, RenderTextArea, RenderDatePicker } from "../../forms/bootstrapFields";

let CalendarEventForm = ({ handleSubmit }) => {
  const { t } = useTranslation();
  return (
    <Form id={CALENDAR_EVENT_FORM} noValidate onSubmit={(value) => handleSubmit(value)}>
      <Row>
        <Col xs="6">
          <Field
            label={t("components.admin.calendarEvent.date")}
            name="form.calendar_event.date"
            component={RenderDatePicker}
            required={true}
            validate={[required]}
          />
        </Col>
        <Col xs="6">
          <Field
            name="form.calendar_event.calendar_event_type"
            label={t("components.admin.calendarEvent.calendarEventType")}
            component={RenderSelect}
            options={[
              { label: "", value: "" },
              { label: "CLOSING DATE", value: "CLOSING_DATE" },
              { label: "CLOSING PERIOD DATE", value: "CLOSING_PERIOD_DATE" },
            ]}
            required
            validate={[required]}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Field
            label={t("components.admin.calendarEvent.type")}
            type={"text"}
            name="form.calendar_event.title"
            component={RenderField}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Field
            label={t("components.admin.calendarEvent.description")}
            type={"text"}
            name="form.calendar_event.description"
            component={RenderTextArea}
          />
        </Col>
      </Row>
    </Form>
  );
};

CalendarEventForm = reduxForm({
  form: CALENDAR_EVENT_FORM, // a unique identifier for this form
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(CalendarEventForm);

CalendarEventForm = connect((state) => ({
  initialValues: state.calendarEventerRed.calendarEventForm,
}))(CalendarEventForm);

export default CalendarEventForm;
