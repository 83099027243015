import React from "react";
import { useTranslation } from "react-i18next";
import { Field, WrappedFieldArrayProps, change, getFormValues } from "redux-form";
import { useTypedSelector } from "../../../../reducers";
import { USER_FORM, UserFormDataType } from "../formUser";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Button, Col, Form, Row } from "react-bootstrap";
import SubsidiaryPicker from "../../../pickers/reduxFormPicker/subsidiaryPicker";

interface RenderSubsidiaryLinksProps extends WrappedFieldArrayProps<any> {}

const RenderSubsideryLinks = ({ fields }: RenderSubsidiaryLinksProps) => {
  const { t } = useTranslation();
  const formData = useTypedSelector((state) => getFormValues(USER_FORM)(state) as UserFormDataType | null);
  const dispatch = useDispatch();

  const onDeleteSubsidiary = (deleteIndex: number) => {
    if (_.isArray(formData?.user.contact.subsidiary_links)) {
      const destoyedSubsidiary = formData?.user.contact.subsidiary_links.map((subsidiaryLink, index) => {
        if (index === deleteIndex) {
          subsidiaryLink._destroy = 1;
        }
        return subsidiaryLink;
      });
      dispatch(change(USER_FORM, "user.contact.subsidiary_links", destoyedSubsidiary));
    }
  };

  return (
    <>
      <Row>
        <Col sm="12">
          <Form.Label>{t("admin.pages.users.additionalContactSubsidiary")}</Form.Label>
        </Col>
      </Row>
      {fields.map((field, index) => {
        const fieldValues = fields.get(index);
        if (fieldValues._destroy) return null;
        return (
          <Row key={field}>
            <Col sm="10">
              <Field name={`${field}.subsidiary_id`} component={SubsidiaryPicker} filter={{ active: true }} />
            </Col>
            <Col>
              <Button size="sm" className="bg-transparent border-0 m-0" onClick={() => onDeleteSubsidiary(index)}>
                <i className="icon icon-delete m-0" />
              </Button>
            </Col>
          </Row>
        );
      })}
      <Row>
        <Col sm="12">
          <Button size="sm" className="px-5" onClick={() => fields.push({})}>
            <i className="icon icon-plus mt-1" /> {t("admin.pages.users.addSubsidiary")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default RenderSubsideryLinks;
