import React, { Component } from "react";
import { Container } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import vendorInvoicesHeader from "../../../components/datagrid/girdHeaders/vendorInvoicesHeader";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-theme-balham.css";
import { calculateGridHeight } from "../../../services/grid/gridHeight";
import { SideBarConfig, ColumnConfig, DateFormatter } from "../../../services/common/gridService";
import ChildCellRouting from "../../../components/datagrid/childCellRouting.jsx";
import RandomChildCellRouting from "../../../components/datagrid/randomCellChildRouting";
import ChildMessageRenderer from "../../../components/datagrid/childMessageRenderer.jsx";
// import CustomStatsToolPanel from "../../../components/datagrid/customStatsToolPanel.jsx";
import CustomStatToolPanel from "../../../components/common/dataGrid/customStatToolPanel";
import FloatingFilterComponent from "../../../components/datagrid/floatingFilterComponent.jsx";
import { Link } from "react-router-dom";
import { Button, Row, Col, ButtonGroup } from "react-bootstrap";
import ToggleFilterButton from "../../../components/datagrid/buttons/toggleFilterButton";
import GridFilterDropdown from "../../../components/datagrid/gridFilterDropdown";
import { withTranslation } from "react-i18next";

class VPInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor_invoices: [],
      showingFilter: true,
      gridApi: null,
      gridColumnApi: null,
      dataType: "vendor_invoices",
    };
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    //since we are using dynamic grid height we need to set it when the component is done loading
    this.setState({ gridHeight: calculateGridHeight(this.gridElement.getBoundingClientRect().y) + "px" });
    window.addEventListener("beforeunload", this.saveState);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.gridApi !== this.state?.gridApi) {
      this.getPreviouseState();
    }
  }

  componentWillUnmount() {
    this.saveState();
    window.removeEventListener("beforeunload", this.saveState);
  }

  saveState = () => {
    let savedState = {};
    let fd = {
      colState:
        typeof this.state?.gridColumnApi?.getColumnState === "function" && this.state?.gridColumnApi?.getColumnState(),
      groupState:
        typeof this.state?.gridColumnApi?.getColumnState === "function" &&
        this.state?.gridColumnApi?.getColumnGroupState(),
      sortState:
        typeof this.state?.gridColumnApi?.getColumnState === "function" && this.state?.gridColumnApi?.getColumnState(),
      filterState:
        typeof this.state?.gridColumnApi?.getColumnState === "function" && this.state?.gridApi?.getFilterModel(),
    };
    savedState[this.state.dataType] = fd;
    localStorage.setItem(this.state?.dataType, JSON.stringify(savedState));
  };

  gridReady = (params) => {
    this.setState({ gridApi: params.api, gridColumnApi: params.columnApi });

    // restore previous filter store to the grid
    this.getPreviouseState();
    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: this.getRows });
  };

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  getRows = (params) => {
    //parameters to send to the api
    let getParams = {
      //grid will pass in the first index and last index that needs to be fetched
      items: params.request.endRow - params.request.startRow,
      //default page size is 100 items
      page: 1 + params.request.startRow / 100,
    };

    this.attachFilters(params.request, getParams);
    this.sorting(params.request, getParams);

    this.restApiService
      .get("vendor_invoices", getParams)
      .then((result) => {
        if (result.data) {
          //we pass in the data we got from the API and the total row count so the grid knows how big to make the scrollbar
          params.success({ rowData: result.data.data, rowCount: result.data.meta.count });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // The API is expecting column names that are different than the ones it sends on fetch
  attachFilters = (request, params) => {
    if (request.filterModel) {
      for (let key in request.filterModel) {
        switch (key) {
          case "number":
            params["invoice_number"] = request.filterModel[key].filter;
            break;
          case "status":
            params["invoice_statuses"] = request.filterModel[key].filter;
            break;
          case "purchase_order.number":
            params["purchase_order_number"] = request.filterModel[key].filter;
            break;
          case "payment_list_str":
            params["payment_number"] = request.filterModel[key].filter;
            break;
          case "date":
            DateFormatter(request, params, key, "date_after", "date_before");
            break;
          default:
        }
      }
    }
  };
  sorting = (request, params) => {
    if (request.sortModel) {
      const sortObject = {};
      params.sort = sortObject;
      request.sortModel.forEach((key) => {
        switch (key.colId) {
          case "number":
            return (sortObject["number"] = { direction: key.sort, priority: 0 });
          default:
            return sortObject;
        }
      });
    }
  };

  getPreviouseState() {
    let getState = JSON.parse(localStorage.getItem(this.state.dataType));
    if (getState) {
      this.state?.gridColumnApi?.applyColumnState(getState[this?.state?.dataType].sortState);
      this.state?.gridColumnApi?.applyColumnState(getState[this?.state?.dataType].colState);
      this.state?.gridColumnApi?.setColumnGroupState(getState[this?.state?.dataType].groupState);
      if (this.props.location?.state?.payment_number) {
        getState[this?.state?.dataType].filterState["payment_list_str"] = {
          type: "contains",
          filterType: "text",
          filter: this.props.location.state.payment_number,
        };
      }
      this.state?.gridApi?.setFilterModel(getState[this.state?.dataType].filterState);
    }

    if (!getState && this.props.location?.state?.payment_number) {
      const paymentFilteInstance = this.state.gridApi.getFilterInstance("payment_list_str");
      paymentFilteInstance.setModel({
        type: "contains",
        filterType: "text",
        filter: this.props.location.state.payment_number,
      });
    }
  }

  render() {
    return (
      <Container fluid={true} className={"pt-4 pb-4"}>
        <Row>
          <Col>
            <GridFilterDropdown
              gridApi={this.state.gridApi}
              gridColumnApi={this.state.gridColumnApi}
              options={{
                month: { dateName: "date" },
              }}
            />
            <Link to="submit_invoice">
              <button className="btn btn-primary ml-1">
                <i className="btn-icon icon-add-btn"></i>
                {this.props.t("breadcrumbs.invoiceSubmit")}
              </button>
            </Link>
            <ToggleFilterButton
              classes="float-right"
              clickCallback={() => {
                this.setState(
                  {
                    showingFilter: !this.state.showingFilter,
                    defaultColDef: { floatingFilter: !this.state.floatingFilter },
                  },
                  () => {
                    this.state.gridApi.refreshHeader();
                  },
                );
              }}
            />
          </Col>
        </Row>
        <hr className="mt-4 mb-4" />
        <div
          ref={(gridElement) => {
            this.gridElement = gridElement;
          }}
          style={{ height: this.state.gridHeight, width: "100%" }}
          className="ag-theme-fresh animated fadeIn"
        >
          <AgGridReact
            columnDefs={vendorInvoicesHeader}
            defaultColDef={{
              resizable: true,
              floatingFilter: this.state.showingFilter,
            }}
            rowModelType="serverSide"
            onGridReady={this.gridReady}
            suppressServerSideInfiniteScroll={false}
            rowHeight={ColumnConfig.rowHeight}
            floatingFiltersHeight={ColumnConfig.floatingFiltersHeight}
            components={{
              childMessageRenderer: ChildMessageRenderer,
              childCellRouting: ChildCellRouting,
              randomChildCellRouting: RandomChildCellRouting,
              customStatsToolPanel: CustomStatToolPanel,
              floatingFilterComponent: FloatingFilterComponent,
            }}
            sideBar={SideBarConfig}
          />
        </div>
      </Container>
    );
  }
}
export default withTranslation()(VPInvoices);
