import { AxiosResponse } from "axios";
import { PurchaserListType, PurchaserDetailType } from "./purchaserTypes";
import { restApiService } from "../../../providers/restApi";

class PurchaserApis {
  static async getPurchaserList(filter?: any) {
    try {
      const response: AxiosResponse<PurchaserListType[]> = await restApiService.get("purchasers", null, filter);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getPurchaserDetail(id: number) {
    try {
      const response: AxiosResponse<PurchaserDetailType[]> = await restApiService.get("purchasers" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
export default PurchaserApis;
