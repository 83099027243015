import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import restApi from "../../../../providers/restApi";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../services/general/helpers";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "../../../common/pickers/reduxFormPickers/select/helpers";
import { Mandatory } from "../../../forms/bootstrapFields";
import Styles from "../override.module.css";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */
const AccountPicker = ({
  className,
  labelClassName,
  label,
  input,
  meta: { touched, error, warning },
  isMulti,
  accountGroupName,
  required,
  params,
  isClearable,
  placeholder = "search/select",
  disabled,
  originalValue,
}) => {
  const [accounts, setAccounts] = useState([]);
  const [originalLabel, setOriginalLabel] = useState(null);
  const mounted = useRef(false);

  const restApiService = useMemo(() => new restApi(), []);
  const getAccounts = useCallback(async () => {
    let getParams = { status: "ACTIVE" };
    if (accountGroupName) getParams.account_group = accountGroupName;
    if (params) getParams = { ...getParams, ...params };
    const result = await restApiService.get("accounts.lk", getParams, null, true, null, true);
    if (result?.data?.data) {
      const value = parseForSelect(result.data.data);
      if (mounted.current) setAccounts(value);
    }
  }, [accountGroupName, restApiService, params]);

  useEffect(() => {
    getAccounts();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [getAccounts]);

  useEffect(() => {
    if (originalValue && accounts.length > 0) {
      setOriginalLabel(findSelectedSingle({ value: originalValue }, accounts)?.label);
    }
  }, [accounts, originalValue]);

  return (
    <>
      {isMulti && (
        <Form.Group className={Styles.select}>
          {label && (
            <Form.Label className={labelClassName}>
              {label}
              <Mandatory required={required} />
            </Form.Label>
          )}
          <Select
            {...input}
            required={required}
            value={findSelectedMultiple(input, accounts)}
            placeholder={placeholder}
            onChange={(value) => onChange(input, value)}
            onBlur={() => onBlur(input, input.value)}
            options={accounts}
            isMulti={isMulti}
            isClearable={isClearable}
            classNamePrefix="select"
            isDisabled={disabled}
            getOptionLabel={(option) =>
              `${option.number || ""} - ${option.name || ""} - ${option.account_type_name || ""}`
            }
          />
          {error && touched && (
            <div style={{ fontSize: "80%", color: "red", padding: "2px" }}>
              {" "}
              {error ? error : "Account Required... "}
            </div>
          )}
        </Form.Group>
      )}

      {!isMulti && (
        <Form.Group className={Styles.select}>
          {label && (
            <Form.Label className={labelClassName}>
              {label}
              <Mandatory required={required} />
            </Form.Label>
          )}
          <Select
            {...input}
            required={required}
            {...(originalValue !== undefined
              ? { components: { Input: (props) => <PickerComparisonField {...props} originalValue={originalLabel} /> } }
              : {})}
            value={findSelectedMultiple(input, accounts)}
            placeholder={placeholder}
            onChange={(value) => onChangeSingle(input, value)}
            onBlur={() => onBlurSingle(input, input.value)}
            options={accounts}
            isMulti={isMulti}
            isClearable={isClearable}
            classNamePrefix="select"
            isDisabled={disabled}
            getOptionLabel={(option) =>
              `${option.number || ""} - ${option.name || ""} - ${option.account_type_name || ""}`
            }
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
              }),
            }}
          />
          {error && touched && (
            <div style={{ fontSize: "80%", color: "red", padding: "2px" }}>
              {" "}
              {error ? error : "Account Required... "}
            </div>
          )}
        </Form.Group>
      )}
    </>
  );
};

export default AccountPicker;
