import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_COUNTRY_CURRENCY_CODE } from "../../../../actions/actionTypes";
import { Field, change, getFormValues } from "redux-form";
import { ManagePaymentMethodFormDataType, PaymentFieldType } from "../types";
import CountryCurrencyCodePicker from "../../../common/pickers/reduxFormPickers/countryCurrencyCodePicker";
import { CountryCurrencyCodeOptionType } from "../../../common/pickers/reduxFormPickers/countryCurrencyCodePicker";
import { MANAGE_PAYMENT_METHOD_FORM } from "../../../../services/common/paymentMethod.svc";
import { useTypedSelector } from "../../../../reducers";

type PaymentCountryCurrenyCodePropsType = PaymentFieldType & { paymentType?: string };

const CountryPaymentCurrencyCode = ({
  validations,
  required,
  label,
  paymentType,
}: PaymentCountryCurrenyCodePropsType) => {
  const dispatch = useDispatch();
  const formData = useTypedSelector(
    (state) =>
      getFormValues(MANAGE_PAYMENT_METHOD_FORM)(state) as
        | (ManagePaymentMethodFormDataType & { country_currency_code: CountryCurrencyCodeOptionType })
        | null,
  );

  const onCountryCurrecyChange: any = (countryCurrencyCode: CountryCurrencyCodeOptionType) => {
    dispatch({ type: CHANGE_COUNTRY_CURRENCY_CODE, payload: countryCurrencyCode });

    // also update redux form
    dispatch(change(MANAGE_PAYMENT_METHOD_FORM, "country", countryCurrencyCode.country_code));
    dispatch(change(MANAGE_PAYMENT_METHOD_FORM, "currency_code", countryCurrencyCode.currency_code));
  };
  const initializeCountryCurrencyCode: any = () => {
    dispatch(
      change(MANAGE_PAYMENT_METHOD_FORM, "country_currency_code", {
        country_code: formData?.country,
        currency_code: formData?.currency_code,
      }),
    );
  };

  useEffect(() => {
    initializeCountryCurrencyCode();
  }, []);

  return (
    <Field
      name="country_currency_code"
      label={label}
      component={CountryCurrencyCodePicker}
      required={required}
      paymentType={paymentType}
      validate={validations}
      onChange={onCountryCurrecyChange}
      code={"code"}
      returnObject
    />
  );
};

export default CountryPaymentCurrencyCode;
