import { Form } from "react-bootstrap";
import React from "react";
import { InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import companySettingStyle from "../../../companySettingTab.module.css";

const PORequestAttachment = (props) => {
  const current_user = useSelector((state) => state.user);
  const {
    input,
    type,
    label,
    tooltip,
    meta: { touched, error },
  } = props;

  return (
    <div>
      <Form.Group>
        <Form.Label>
          {label ?? ""}
          {tooltip ?? ""}
        </Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text className={companySettingStyle.defaultWorkflowStatusTxt}>
            {current_user.company.currency.symbol}
          </InputGroup.Text>
          <Form.Control
            as="input"
            type={type ?? "text"}
            {...input}
            isInvalid={touched && !!error}
            isValid={touched && !error}
          />
        </InputGroup>
      </Form.Group>
    </div>
  );
};

export default PORequestAttachment;
