import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";

const QbTokenBased = () => {
  return (
    <>
      <Col lg="6">
        <Field
          name="form.application_id"
          class="form-control"
          label="Profile ID (ie. 106)"
          placeholder="Client ID"
          component={RenderField}
          type="text"
        />
      </Col>
      <Col lg="6">
        <Field name="form.code" label="Code" placeholder="Code" readOnly={true} component={RenderField} type="text" />
      </Col>
    </>
  );
};

export default QbTokenBased;
