class ChatMessage {
  constructor(message, userName, date, id, mine) {
    this.message = message;
    this.userName = userName;
    this.date = date;
    this.id = id;
    this.mine = mine;
  }
}

export default ChatMessage;
