import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import creditMemoHeader from "./creditMemoHeader";
import { CreditMemoListType } from "../../../services/vp/creditMemo/creditMemoTypes";
import RestApi from "../../../providers/restApi";
import _ from "lodash";
import { GridReadyEvent } from "ag-grid-community";
import GridFilterDropdown from "../../datagrid/gridFilterDropdown";
import ToggleFilterButton from "../../datagrid/buttons/toggleFilterButton";
import ClientDataGrid from "../../common/dataGrid/clientDataGrid/clientDataGrid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CreditMemoApis from "../../../services/vp/creditMemo/creditMemoApis";
import useIsMounted from "../../common/hooks/useIsMounted";
import style from "./creditMemo.module.css";

let initialRender: boolean = true;

const ListCreditMemo = () => {
  const gridStorageName = "vendor_vendor_credits";
  const [vendorCredits, setVendorCredits] = useState<Array<CreditMemoListType>>([]);
  const [gridApi, setGridApi] = useState<GridReadyEvent["api"]>();
  const [gridColumnApi, setGridColumnApi] = useState<GridReadyEvent["columnApi"]>();
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const getCreditMemos = async () => {
    try {
      const result = await CreditMemoApis.getCreditMemoList();
      if (result && isMounted.current) {
        setVendorCredits(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const gridReady = (params: GridReadyEvent) => {
    if (isMounted.current) {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
      getCreditMemos();
    }
  };

  useEffect(() => {
    if (initialRender) {
      initialRender = false;
      return;
    }
    if (!_.isEmpty(gridApi)) {
      gridApi.refreshHeader();
    }
  }, [showFilter]);

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <Row>
        <Col>
          <GridFilterDropdown
            gridApi={gridApi}
            gridColumnApi={gridColumnApi}
            options={{
              month: { dateName: "payment_date" },
            }}
          />
          <Link to="submit_credit_memo">
            <button className="btn btn-primary mx-3">
              <i className="btn-icon icon-add-btn"></i>
              {t("creditMemoEditPage.submitCreditMemo")}
            </button>
          </Link>
          <ToggleFilterButton
            classes="float-right"
            clickCallback={() => {
              setShowFilter((prev) => !prev);
            }}
          />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />
      <Row className={style["h-73"]}>
        <ClientDataGrid
          columnDefs={creditMemoHeader}
          gridReady={gridReady}
          rowData={vendorCredits}
          defaultColDef={{ floatingFilter: showFilter }}
          gridStorageName={gridStorageName}
        />
      </Row>
    </Container>
  );
};

export default ListCreditMemo;
