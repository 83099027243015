import { Mandatory } from "components/forms/input/mandatory";
import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

// Custom wrapper for react-select to be used with Redux Form
const GenericSelect = ({
  input,
  options,
  isMulti,
  placeholder,
  className,
  label,
  isRequired,
  meta: { touched, error, warning },
}: {
  input: any;
  options: any;
  isMulti: any;
  placeholder: any;
  className: any;
  label: string;
  isRequired: boolean;
  touched: any;
  error: any;
  warning: any;
  meta: { touched: any; error: any; warning: any };
}) => {
  const isError = touched && error;
  const handleChange = (selectedOptions: { map: (arg0: (option: any) => any) => any; value: any }) => {
    // need to handle both multi and single select scenarios
    input.onChange(
      isMulti ? selectedOptions.map((option) => option.value) : selectedOptions ? selectedOptions.value : null,
    );
  };

  const handleBlur = () => {
    // Redux Form tracks 'touched' state on blur
    input.onBlur(input.value);
  };

  // Format options for react-select based on the input.value
  const value = isMulti
    ? options.filter((option: { value: any }) => input.value.includes(option.value))
    : options.find((option: { value: any }) => option.value === input.value);

  return (
    <Form.Group className={`${className}`}>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={isRequired} />
        </Form.Label>
      )}
      <Select
        {...input}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base, state) => ({
            ...base,
            borderColor: isError ? "#dc3545" : state.isFocused ? "#80bdff" : base.borderColor,
            boxShadow: state.isFocused
              ? isError
                ? "0 0 0 0.2rem rgba(220, 53, 69, 0.25)"
                : "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
              : base.boxShadow,
            "&:hover": {
              borderColor: isError ? "#dc3545" : state.isFocused ? "#80bdff" : base.borderColor,
            },
          }),
        }}
        className={className}
        isClearable
      />
      {isError && <div style={{ color: "#dc3545", fontSize: "90%", marginTop: "0.25rem" }}>{error}</div>}
      {touched && warning && <div style={{ color: "#ffc107", fontSize: "90%", marginTop: "0.25rem" }}>{warning}</div>}
    </Form.Group>
  );
};

export default GenericSelect;
