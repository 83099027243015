import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import style from "./customDropzone.module.css";
import { Button } from "react-bootstrap";

type CustomDropzonePropType = {
  onFileUpload: (files: File[]) => void;
  accept?: string | string[];
  maxFiles?: number;
  maxSize?: number;
  customDropzoneClass?: string;
  customDropzoneBody?: ReactNode;
  disabled?: boolean;
};

const CustomDropzone = ({
  onFileUpload,
  accept = "",
  maxFiles = 1, // override the prop for increaseing number of files
  maxSize = Infinity, // is validating from backend
  customDropzoneClass,
  customDropzoneBody, // override default dropzone
  disabled,
}: CustomDropzonePropType) => {
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        // Handle invalid file types or other errors here
        console.error("Invalid files:", fileRejections);
      }

      const validFiles = acceptedFiles.slice(0, maxFiles);
      setFiles(validFiles);
      onFileUpload(validFiles);
    },
    [maxFiles, onFileUpload],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled: disabled,
    onDrop,
    accept,
    maxSize,
    multiple: maxFiles > 1,
  });

  const isInvalidFile = useMemo(() => isDragReject || files.length > maxFiles, [isDragReject, files, maxFiles]);

  return (
    <div
      {...getRootProps()}
      className={`
        ${customDropzoneClass ? customDropzoneClass : style.defaultDropzoneClass} 
        ${isInvalidFile ? style.invalid : ""}
      `}
    >
      <div className="d-flex flex-column align-items-center justify-content-center mb-auto mt-auto">
        <div>
          <input {...getInputProps()} />
        </div>
        {customDropzoneBody ? (
          <>{customDropzoneBody}</>
        ) : (
          <>
            <div>
              <i className={`${style.icon50} icon-upload-to-cloud`} />
            </div>
            <div>
              <h4>{isDragActive ? <>Drop the files here ...</> : <>Drag and drop files here </>}</h4>
            </div>
            {!isDragActive && (
              <div>
                <h4> or </h4>
              </div>
            )}
            <Button>Browse File</Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomDropzone;
