import { Mandatory } from "components/forms/input/mandatory";
import React, { CSSProperties, useState } from "react";
import { Form } from "react-bootstrap";

// on/off swtich component to be used with Redux Form
const GenericSwitch = ({
  input,
  options,
  className,
  label,
  isRequired,
}: {
  input: any;
  options: any;
  className: any;
  label: string;
  isRequired: boolean;
}) => {
  const [checked, setChecked] = useState(input.value === options[0].value ? true : false);

  const handleChange = () => {
    setChecked(!checked);
    input.onChange(checked ? options[1].value : options[0].value);
  };

  const toggleSwitchStyle: CSSProperties = {
    width: 50,
    height: 25,
    backgroundColor: checked ? "#77DD76" : "#FF6962", // green/red
    borderRadius: 15,
    marginTop: 8,
    position: "relative",
    cursor: "pointer",
    transition: "background-color 0.3s, transform 0.3s",
  };

  const toggleSwitchHandleStyle: CSSProperties = {
    width: 25,
    height: 25,
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    position: "absolute",
    top: 0,
    left: checked ? (toggleSwitchStyle.width as number) - 22 : -1,
    transition: "left 0.3s",
    border: "1px solid grey",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
  };

  return (
    <Form.Group className={`${className}`}>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={isRequired} />
        </Form.Label>
      )}
      <div style={toggleSwitchStyle} onClick={handleChange}>
        <div style={toggleSwitchHandleStyle}></div>
      </div>
    </Form.Group>
  );
};

export default GenericSwitch;
