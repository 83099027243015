import React, { useEffect, useState } from "react";
import { VendorApis } from "../../../../services/admin/vendors/vensorApis";
import { VendoListType, VendorSearchType } from "../../../../services/admin/vendors/vendorTypes";
import { Button, Col, Row } from "react-bootstrap";
import _ from "lodash";
import styles from "../vendors.module.css";

type VendorSearchResultType = {
  searchTerms: VendorSearchType;
  handleAddVendorCallBack: (master: any) => void;
};

const VendorSearchResult = ({ searchTerms, handleAddVendorCallBack }: VendorSearchResultType) => {
  const [masters, setMasters] = useState<VendoListType[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);

  const getMasterCompanies = async (vendorSearch: VendorSearchType) => {
    try {
      const response = await VendorApis.searchMasterCompaniesVendor(vendorSearch);
      if (response) {
        setMasters(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMasterCompanies(searchTerms);
  }, [searchTerms]);

  const handleShowMoreClick = () => {
    setShowAll(true);
  };

  const displayedMasters = showAll ? masters : masters.slice(0, 5); // Display either all or first five

  return (
    <div className={styles.masterVendorSection}>
      {_.isArray(displayedMasters) && displayedMasters.length === 0 && (
        <p className={styles.masterVendorValue}>No vendors found</p>
      )}

      {_.isArray(displayedMasters) &&
        displayedMasters.length > 0 &&
        displayedMasters.map((master, key) => {
          return (
            <div className={styles.masterVendorRow} key={key}>
              <Row>
                <Col md={6}>
                  <i className="icon icon-card"></i>
                  <p className={styles.masterName}>{master.name}</p>
                  <p className={styles.masterVendorValue}>{master.email}</p>
                  {master.admin_email && (
                    <p className={styles.masterVendorValue}>{master.admin_email} (Master Admin)</p>
                  )}
                </Col>
                <Col md={2}>
                  <p className={styles.masterVendorLable}>Master Id</p>
                  <p className={styles.masterVendorValue}>{master.id}</p>
                </Col>
                <Col md={2}>
                  <p className={styles.masterVendorLable}>Purchaser Count</p>
                  <p className={styles.masterVendorValue}>{master.purchaser_count}</p>
                </Col>
                <Col md={2}>
                  <Button variant="secondary" className="float-right" onClick={() => handleAddVendorCallBack(master)}>
                    Select
                  </Button>
                </Col>
              </Row>
            </div>
          );
        })}

      {masters.length > 5 && (
        <>
          <Row>
            <Col className="displayCenter">
              <p className={styles.masterVendorValue}>
                You've viewed {showAll ? masters.length : 5} of {masters.length} profiles
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="displayCenter">
              {!showAll && (
                <a className={styles.showMore} onClick={handleShowMoreClick}>
                  Show More
                </a>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default VendorSearchResult;
