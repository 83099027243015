import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import { VENDOR_CONTACT_FORM } from "../../../reducers/vp/vendorContactFormReducer";
import { RootState } from "../../../reducers";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RenderCheckBox, RenderField, RenderSelect } from "../../forms/bootstrapFields";
import { required, noWhiteSpaceOnly, email } from "../../../services/validations/reduxFormValidation";

export type contactFormData = {
  form: {
    contact: {
      id?: number;
      first_name: string;
      last_name: string;
      title: string;
      phone: string;
      email: string;
      status: string;
      vendor_admin: boolean;
    };
  };
};

type vendorContactFormProps = {
  contactForm: ReactNode;
  vendorAdmin?: ReactNode;
  onSubmit: (formData: contactFormData) => void;
  footerButtons: ReactNode;
};

type vendorContactStateProps = {
  initialValues: contactFormData;
};

export const ContactForm = () => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col sm="4">
        <Field
          name="form.contact.first_name"
          id="form.contact.last_name"
          type="text"
          label={t("vp.vendorContact.firstName")}
          placeholder={t("vp.vendorContact.enterFirstName")}
          component={RenderField}
          validate={[required, noWhiteSpaceOnly]}
          required
        />
      </Col>

      <Col sm="4">
        <Field
          name="form.contact.last_name"
          id="form.contact.last_name"
          type="text"
          label={t("vp.vendorContact.lastName")}
          placeholder={t("vp.vendorContact.enterLastName")}
          component={RenderField}
          validate={[required, noWhiteSpaceOnly]}
          required
        />
      </Col>

      <Col sm="4">
        <Field
          name="form.contact.title"
          id="form.contact.title"
          type="text"
          label={t("vp.vendorContact.title")}
          placeholder={t("vp.vendorContact.enterTitle")}
          component={RenderField}
        />
      </Col>

      <Col sm="4">
        <Field
          name="form.contact.phone"
          id="form.contact.phone"
          type="text"
          label={t("vp.vendorContact.phone")}
          placeholder={t("vp.vendorContact.enterPhone")}
          component={RenderField}
        />
      </Col>

      <Col sm="4">
        <Field
          name="form.contact.email"
          id="form.contact.email"
          type="text"
          label={t("vp.vendorContact.email")}
          placeholder={t("vp.vendorContact.enterEmail")}
          component={RenderField}
          validate={[required, email]}
          required
        />
      </Col>

      <Col sm="4">
        <Field
          name="form.contact.status"
          type="text"
          label={t("vp.vendorContact.status")}
          id="form.contact.last_name"
          component={RenderSelect}
          validate={[required]}
          required
          options={[{}, { label: "Active", value: "ACTIVE" }, { label: "Inactive", value: "INACTIVE" }]}
        />
      </Col>
    </Row>
  );
};

export const VendorAdmin = () => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col sm="4">
        <Field
          name="form.contact.vendor_admin"
          type="checkbox"
          label={t("vp.vendorContact.vendorAdmin")}
          id="form.contact.vendor_admin"
          component={RenderCheckBox}
        />
      </Col>
    </Row>
  );
};

const VendorContactForm: React.FC<
  vendorContactFormProps & InjectedFormProps<contactFormData, vendorContactFormProps>
> = ({ contactForm, vendorAdmin, handleSubmit, footerButtons }) => {
  return (
    <Form noValidate id="vendorContactForm" className="px-2" onSubmit={handleSubmit}>
      {contactForm}
      {vendorAdmin}
      {footerButtons}
    </Form>
  );
};

const mapStateToProps = (state: RootState): vendorContactStateProps => {
  return {
    initialValues: state.vendorContactFormRed.vendorContactFrom,
  };
};

const ConnectVendorContactFrom = connect(mapStateToProps)(
  reduxForm<contactFormData, vendorContactFormProps>({
    form: VENDOR_CONTACT_FORM,
    enableReinitialize: true,
    touchOnChange: false,
    touchOnBlur: false,
  })(VendorContactForm),
);

export default ConnectVendorContactFrom;
