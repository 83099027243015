import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import FormCreditMemo, { FormCreditMemoDataType } from "./_form";
import _ from "lodash";
import { createCompleteError } from "../../../services/general/reduxFormSvc";
import { SubmissionError } from "redux-form";
import CreditMemoApis from "../../../services/vp/creditMemo/creditMemoApis";

const AddCreditMemo = () => {
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [showAttachmentError, setShowAttachmentError] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const setAttachmentError = (showError: boolean) => {
    setShowAttachmentError(showError);
  };

  const submitAttachments = async (assets: File[], id: number) => {
    var formData = new FormData();
    assets.map((asset, key) => {
      if (asset) {
        formData.append("payment[assets_attributes[" + key + "]asset_file]", asset);
      }
    });
    try {
      const result = await CreditMemoApis.editCreditMemo(id, formData);
      if (result) {
        CreateNotification("Success", "Attachment uploaded successfully", "success");
        history.push("/ar/credit_memos");
      }
    } catch (error) {
      CreateNotification("Upload Error", "Failed to Upload Attachmentss", NotificationType.danger);
      console.error("Error saving company info: ", error);
    }
  };

  const handleSubmit = async (creditMemo: FormCreditMemoDataType) => {
    if (!_.isObject(creditMemo.form)) {
      return;
    }

    if (!creditMemo.form.assets || creditMemo.form.assets.length < 1) {
      setShowAttachmentError(true);
      return;
    }

    setSubmitDisabled(true);
    let obj = {
      payment: { ...creditMemo.form, status: "NEW", payment_type: "CREDIT" },
    };

    try {
      const result = await CreditMemoApis.addCreditMemo(obj);
      if (result.id) {
        if (_.isArray(creditMemo.form.assets) && creditMemo.form.assets.length > 0) {
          submitAttachments(creditMemo.form.assets, result.id);
        } else {
          history.push("/ar/credit_memos");
        }
        CreateNotification(t("success"), t("creditMemoEditPage.saved"), NotificationType.success);
      }
    } catch (error: any) {
      setSubmitDisabled(false);
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data)) {
          if (response.data["payment_number"]) {
            response.data["number"] = response.data["payment_number"];
          }

          const completeErrorObj = createCompleteError(response.data);
          throw new SubmissionError({
            form: { ...completeErrorObj },
          });
        }
      } else {
        CreateNotification("Validation Error", "Please enter valid data", NotificationType.danger);
      }
    }
  };

  return (
    <FormCreditMemo
      isDisableFormBtn={submitDisabled}
      showAttachmentError={showAttachmentError}
      setAttachmentError={setAttachmentError}
      onSubmit={handleSubmit}
    />
  );
};

export default AddCreditMemo;
