import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { contactType } from "./verifyDataTable";
import ContactPicker from "../../../vp/pickers/reduxPicker/contactPicker/contactPicker";

type rejectContactDetailModalProps = {
  saveCallback: (contact: any) => void;
  show: boolean;
  closeCallback: () => void;
  contact: contactType;
};

const RejectContactDetailModal = ({ saveCallback, show, closeCallback, contact }: rejectContactDetailModalProps) => {
  const [selectedContact, setSelectedContact] = useState<{ value: any }>();

  const handleContactPick = (contact: React.SetStateAction<{ value: any } | undefined>) => {
    setSelectedContact(contact);
  };

  const handleSave = () => {
    saveCallback(selectedContact);
  };

  return (
    <Modal show={show} onHide={closeCallback} dialogClassName="bigModal">
      <Modal.Header closeButton>
        <Modal.Title>Push Correct Data</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Let's let your customer know what the appropriate data for the below should be. We will update your customer's
          records and notify them of the change.
        </p>
        <Table striped bordered hover className="mb-0 mt-3">
          <thead>
            <tr>
              <th className="primaryFontColor">Data Type</th>
              <th className="primaryFontColor">Customer</th>
              <th className="primaryFontColor">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Contact</td>
              <td>{contact && contact.company && contact.company.name}</td>
              <td style={{ width: "300px" }}>
                <ContactPicker callback={handleContactPick} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeCallback}>
          Cancel
        </Button>
        <Button disabled={!selectedContact || !selectedContact.value} onClick={handleSave}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectContactDetailModal;
