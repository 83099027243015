// const BASE_URL ="http://api-qa.accrualify.com/"
//
// const HEADER ={ Accept: 'application/json',
//     'Content-Type': 'application/json'}

const initialState = {
  pending: false,
  products: [],
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PRODUCTS_PENDING":
      return {
        ...state,
        pending: true,
      };
    case "FETCH_PRODUCTS_SUCCESS":
      return {
        ...state,
        pending: false,
        products: action.payload,
      };
    case "FETCH_PRODUCTS_ERROR":
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
};
