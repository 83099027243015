import React from "react";
import changeOrderService from "services/admin/changeOrders/changeOrderSvc";
import style from "./objectChanges.module.css";
import { ShowFields } from "components/common/showFields/showFields";

type ChangedPropertyIdPropType<NEW_ITEM_TYPE, OLD_ITEM_TYPE> = {
  // <- generic typing
  newItem: NEW_ITEM_TYPE;
  oldItem?: OLD_ITEM_TYPE;
  property: string;
  formatter?: (object: { [key: string]: any }) => string;
  endpoint: string;
  value?: string;
};

const ChangedPropertyId = <
  NEW_ITEM_TYPE extends { [index: string]: any },
  OLD_ITEM_TYPE extends { [index: string]: any },
>({
  // <- this { [index: string]: any } is specifying the myObject will always contain key of type string so that not get type error
  endpoint,
  formatter,
  newItem,
  oldItem,
  property,
  value,
}: ChangedPropertyIdPropType<NEW_ITEM_TYPE, OLD_ITEM_TYPE>) => {
  const isUpdate = changeOrderService.fieldIsUpdated(newItem, oldItem, property);

  return (
    <td className={`${isUpdate && style.updatedItem}`}>
      {value && <ShowFields value={value} className={isUpdate && style.previousValue} />}
      {isUpdate && newItem[property] && (
        <ShowFields endpoint={endpoint} id={newItem[property] as number} formatter={formatter} />
      )}
    </td>
  );
};

export default ChangedPropertyId;
