import { isNumber, isString } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import adminCommonSvc from "services/admin/commonSvc";
import { getDateFormat } from "../../services/general/dateSvc";
import "./form.style.css";

export const Mandatory = ({ required }) => {
  if (required) {
    return (
      <span className="required-asterisk" style={{ color: "red" }}>
        *
      </span>
    );
  } else {
    return null;
  }
};

const RenderField = ({
  className,
  FieldClassName,
  labelClassName = null,
  min = null,
  max = null,
  input,
  id,
  type,
  label,
  tooltip,
  placeholder,
  autoComplete,
  meta: { touched, error, warning },
  required,
  validateNumberOnly,
  maxLength,
  pattern,
  disabled = false,
}) => {
  const isError = touched && error;

  // This function, handleChangeInput, is responsible for handling user input in an input field.
  // It validates the input to ensure it is a number and clears the input field if it is not a number,
  // but only if both 'validateNumberOnly' and 'isError' props are set to true.
  const handleChangeInput = (e) => {
    const newValue = e.target.value;
    let isNotaNumber = isNaN(newValue); // Check if the 'newValue' is a number or not
    if (validateNumberOnly && isError && isNotaNumber) {
      input.onChange(""); // Clear the input field if there is an error, validateNumberOnly is true and input is not a Number
    } else {
      input.onChange(e);
    }
  };

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className={labelClassName}>
          {label}
          <Mandatory required={required} />
          {/* */}
          {tooltip ?? ""}
        </Form.Label>
      )}

      {/* {tooltip ?? ""} */}
      <Form.Control
        {...input}
        as="input"
        className={FieldClassName}
        type={type ?? "text"}
        autoComplete={autoComplete}
        placeholder={placeholder}
        isInvalid={touched && !!error}
        maxLength={maxLength}
        pattern={pattern ? pattern : null}
        //required={required ?? null}
        disabled={disabled}
        onChange={handleChangeInput}
        min={min}
        max={max}
        id={id}
      />
      {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};

const RenderRadioGroup = ({
  className,
  FieldClassName,
  inputClassName = null,
  labelClassName = null,
  initialCheckedIndex = null,
  group = [],
  input,
  label,
  tooltip,
  meta: { error, touched },
  required,
  labelPosition = "right",
  orientation = "vertical",
  disabled = false,
}) => {
  const [checkedGroup, setCheckedGroup] = useState([]);

  function handleOnClick(v, i) {
    let checkRadioGroup = group.map(() => false);
    checkRadioGroup[i] = true;
    setCheckedGroup(checkRadioGroup);
    input.onChange(v);
  }

  const renderRadioItem = (item, index) => {
    return (
      <>
        {labelPosition === "left" && (
          <label
            htmlFor={id(index, input.name)}
            style={{ fontFamily: "GT-America", marginRight: "8px", fontSize: "16px", fontWeight: "normal" }}
          >
            <span dangerouslySetInnerHTML={{ __html: item.label }}></span>
          </label>
        )}
        <input
          type="radio"
          checked={checkedGroup[index] || false}
          id={id(index, input.name)}
          key={index}
          style={{ marginTop: "2px" }}
          onClick={() => {
            handleOnClick(item.value, index);
          }}
          onChange={() => {}}
        ></input>
        {labelPosition === "right" && (
          <label
            htmlFor={id(index, input.name)}
            style={{
              fontFamily: "GT-America",
              marginLeft: "8px",
              marginBottom: "0",
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: item.label }}></span>
          </label>
        )}
      </>
    );
  };

  useEffect(() => {
    let checkRadioGroup = Array(group.length).fill(false);
    if (isNumber(initialCheckedIndex) && initialCheckedIndex < group.length) {
      checkRadioGroup[initialCheckedIndex] = true;
    }
    setCheckedGroup(checkRadioGroup);
  }, []);

  // If multiple radio groups exist on the same page, ensure that the buttons in one group have unique ID's compared to the other group.
  // A safe assumption is that the name of the field will be unique between radio button groups.
  const id = (id, suffix) => {
    return "cb_" + id + "_" + suffix;
  };

  if (group.length === 0) {
    return null;
  }

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className={labelClassName}>
          {label}
          <Mandatory required={required} />
          {/* */}
          {tooltip && (
            <span title={tooltip} className="float-right">
              <i className="icon-sm icon-questionmark"></i>{" "}
            </span>
          )}
        </Form.Label>
      )}
      <Row>
        {orientation === "horizontal" &&
          group.map((b, index) => (
            <div key={index}>
              <Col style={{ flexGrow: "0" }}>{renderRadioItem(b, index)}</Col>
            </div>
          ))}
        {orientation === "vertical" && (
          <Col>
            {group.map((b, index) => (
              <Row key={index} style={{ marginBottom: "16px" }}>
                <Col style={{ display: "flex", alignItems: "center" }}>{renderRadioItem(b, index)}</Col>
              </Row>
            ))}
          </Col>
        )}
      </Row>
      {error && touched && <div style={{ color: "red" }}>{error}</div>}
    </Form.Group>
  );
};

const ComparisonField = ({
  className,
  inputClassName = null,
  labelClassName = null,
  border = true,
  min = null,
  max = null,
  input,
  type,
  label,
  tooltip,
  placeholder,
  autoComplete,
  meta: { touched, error },
  required,
  maxLength,
  pattern,
  disabled = false,
  transparentDisabled = true,
  prefix = "",
  handleOnBlur = undefined,
  originalValue,
  handleOnChange = undefined,
}) => {
  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className={labelClassName}>
          {label}
          <Mandatory required={required} />
        </Form.Label>
      )}
      {tooltip ?? ""}
      <Row
        className={`m-0 rounded ${border ? "border" : ""} ${originalValue !== undefined ? "comparisonContainer" : ""}`}
      >
        {originalValue ? (
          <>
            <Col
              className="p-0 pl-2 text-center d-flex flex-row align-items-center strikeThroughText"
              style={{ maxWidth: "fit-content" }}
            >
              {prefix}
              {originalValue}
            </Col>
            <Col className="p-0 d-flex align-items-center" style={{ maxWidth: "fit-content" }}>
              {"->"}
            </Col>
          </>
        ) : null}
        <Col
          className={`p-0 d-flex align-items-center ${prefix ? "pl-2" : ""} ${transparentDisabled ? "transparentDisabledBg" : ""}`}
        >
          <span>{prefix}</span>
          <Form.Control
            {...input}
            as="input"
            type={type ?? "text"}
            autoComplete={autoComplete}
            placeholder={placeholder}
            isInvalid={touched && !!error}
            isValid={touched && !error}
            maxLength={maxLength}
            pattern={pattern ? pattern : null}
            required={required ?? null}
            disabled={disabled}
            className={[`border-0 ${prefix ? "p-0" : ""} ${originalValue ? "p-0 comparisonBg" : ""}`, inputClassName]}
            min={min}
            max={max}
            onBlur={handleOnBlur ? () => handleOnBlur(input) : input.onBlur}
            onChange={handleOnChange ? (e) => handleOnChange(e, input.onChange) : input.onChange}
          />
        </Col>
      </Row>

      {touched && error && (
        <Form.Control.Feedback className="d-inline" type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

const RenderFieldNumber = (props) => {
  const {
    input,
    label,
    id,
    tooltip,
    min,
    max,
    disabled,
    step,
    className,
    defaultValue,
    meta: { touched, error },
    required,
    readOnly,
    showAsteriskWithoutLabel,
  } = props;

  input.value = input.value === "" ? undefined : Number(input.value);
  if (defaultValue) {
    input.value = Number(defaultValue);
  }

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
        </Form.Label>
      )}
      {!label && showAsteriskWithoutLabel && required && (
        <div className="d-flex justify-content-end">
          <Mandatory required={required} />
        </div>
      )}
      {tooltip ?? ""}
      <Form.Control
        {...input}
        as="input"
        type={"number"}
        min={min ?? ""}
        max={max ?? ""}
        step={step ?? ""}
        // Note: The following line is intentionally commented out.
        // When uncommented, it show a green tick and green box on valid input.
        // which is not preferable for the current use case.
        // isValid={touched && !error}
        isValid={false}
        isInvalid={touched && !!error}
        defaultValue={defaultValue}
        className={className ?? ""}
        disabled={disabled}
        required={required ?? null}
        id={id}
        readOnly={readOnly}
      />
      {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};

const RenderTextArea = ({
  className,
  labelClassName,
  input,
  id,
  maxLength,
  label,
  meta: { touched, error, warning },
  required,
  rows,
  style,
  placeholder,
  disabled,
}) => {
  return (
    <Form.Group>
      {label && (
        <Form.Label className={labelClassName}>
          {label}
          <Mandatory required={required} />
        </Form.Label>
      )}
      <Form.Control
        as="textarea"
        {...input}
        style={style}
        isInvalid={touched && !!error}
        isValid={touched && !error}
        rows={rows ? rows : 3}
        className={className ? className : ""}
        placeholder={placeholder ? placeholder : ""}
        required={false}
        maxLength={maxLength}
        disabled={disabled}
        id={id}
      />
      {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};

const RenderSelect = ({
  className,
  FieldClassName,
  labelClassName,
  input,
  id,
  options,
  type,
  label,
  required,
  multiple,
  disabled,
  tooltip,
  meta: { touched, error, warning },
}) => {
  const handleOnChange = (e) => (type === "boolean" ? input.onChange(e.target.value === "true") : input.onChange(e));
  const handleOnBlur = (e) => (type === "boolean" ? input.onChange(e.target.value === "true") : input.onChange(e));

  return (
    <Form.Group className={`w-100 ${className ?? ""}`}>
      {label && (
        <Form.Label className={labelClassName}>
          {label}
          <Mandatory required={required ? required : false} />
        </Form.Label>
      )}
      {tooltip ?? ""}
      <Form.Control
        as="select"
        multiple={multiple}
        disabled={disabled}
        {...input}
        isInvalid={touched && !!error}
        isValid={touched && !error}
        required={required ?? null}
        className={FieldClassName}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        id={id}
      >
        {Array.isArray(options) &&
          options.map((option, index) => {
            if (option._destroy) {
              return null;
            }
            return (
              <option key={index} value={option.value} disabled={option.disabled} title={option.tooltip}>
                {option.label}
              </option>
            );
          })}
      </Form.Control>
      {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};

const RenderCheckBox = ({
  className,
  input,
  id,
  tooltip,
  label,
  meta: { touched, error, warning },
  required,
  disabled,
  defaultValue,
  isChecked,
  storeStringValue,
}) => {
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    input.onChange(isChecked);
  };

  const handleCheckedEvent = () => {
    if (isChecked !== undefined) {
      return isChecked;
    } else {
      return input.value === "" && defaultValue ? defaultValue : input.value;
    }
  };

  return (
    <Form.Group className={"mb-5 d-flex  " + (className ? className : "")}>
      <Form.Check
        {...input}
        label={label ?? ""}
        className={"mr-1"}
        required={required ?? null}
        checked={handleCheckedEvent()}
        // checked={input.value === "" && defaultValue ? defaultValue : input.value}
        disabled={disabled}
        onChange={handleCheckboxChange}
        id={id}
        type="checkbox"
      />
      {tooltip ?? ""}
      {touched && error && (
        <Form.Control.Feedback className="d-inline" type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export const RenderRadioButton = ({
  className,
  input,
  tooltip,
  label,
  meta: { touched, error, warning },
  required,
  disabled,
  defaultValue,
  options, // Include options for radio buttons
}) => {
  return (
    <Form.Group className={"mb-5 d-flex " + (className ? className : "")}>
      {options.map((option, index) => (
        <Form.Check
          key={index}
          {...input}
          label={option.label}
          className={"mr-3"} // Adjust styling for radio buttons
          required={required ?? null}
          checked={input.value === option.value}
          disabled={disabled}
          type="radio"
          id={option.value}
          value={option.value}
        />
      ))}
      {tooltip ?? ""}
      {touched && error && (
        <Form.Control.Feedback className="d-inline" type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

/**
 * @deprecated The component should not be used
 * use new one accrualify-reactjs/src/components/admin/pickers/reduxFormPickers/datePicker/datePicker.tsx
 */
const RenderDatePicker = ({
  input: { value, onChange },
  labelClassName,
  label,
  tooltip,
  meta: { touched, error },
  required,
  valueRef,
  disabled,
  maxDate,
  minDate,
  callBack,
  isClearable,
  id,
}) => {
  const currentUser = useSelector((state) => state.user);
  const [inputValue, setInputValue] = useState("");
  const dateFormat = getDateFormat(currentUser);

  const handleDateChange = useCallback(
    (date) => {
      if (valueRef) {
        valueRef.current = date;
      }
      onChange(date);
      setInputValue(date ? adminCommonSvc.companyFormatedDate(date, currentUser) : "");
      callBack && callBack(date);
    },
    [onChange, valueRef, callBack, currentUser],
  );

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    // dateFormat tends to return as a string such as "mm/dd/yyyy" or "dd/mm/yyyy"
    const maxLength = dateFormat.length;

    // Truncate the input if it exceeds the max length
    const truncatedValue = newValue.slice(0, maxLength);

    setInputValue(truncatedValue);

    // Try to parse the input as a date
    const parsedDate = adminCommonSvc.getDateObjFromDateStr(truncatedValue);
    if (parsedDate && !isNaN(parsedDate.getTime())) {
      onChange(parsedDate);
      if (valueRef) {
        valueRef.current = parsedDate;
      }
      callBack && callBack(parsedDate);
    } else {
      onChange(truncatedValue);
      if (valueRef) {
        valueRef.current = truncatedValue;
      }
      callBack && callBack(truncatedValue);
    }
  };

  useEffect(() => {
    if (value instanceof Date) {
      setInputValue(adminCommonSvc.companyFormatedDate(value, currentUser));
    } else if (typeof value === "string") {
      setInputValue(value);
    } else {
      setInputValue("");
    }
  }, [value, currentUser]);

  const getSelectDate = useCallback(() => {
    if (value instanceof Date) {
      return value;
    } else if (isString(value) && isString(dateFormat)) {
      return adminCommonSvc.getDateObjFromKnowDateFormate(value, dateFormat.toUpperCase());
    }
    return null;
  }, [dateFormat, value]);

  return (
    <Form.Group>
      {label && (
        <Form.Label className={labelClassName}>
          {label}
          {required && <span className="required-asterisk">*</span>}
          {tooltip}
        </Form.Label>
      )}
      <Row className="m-0 p-0 d-flex flex-row flex-nowrap">
        <DatePicker
          selected={!value ? null : getSelectDate()}
          onChange={handleDateChange}
          dateFormat={getDateFormat(currentUser)}
          placeholderText={getDateFormat(currentUser, true)}
          className={touched && error ? "form-control requiredField" : "form-control"}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          isClearable={isClearable}
          popperProps={{ positionFixed: true }}
          id={id}
          autoComplete="off"
          onKeyDown={(e) => {
            const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
            if (!/[\d/.-]/.test(e.key) && !allowedKeys.includes(e.key) && !(e.metaKey || e.ctrlKey)) {
              e.preventDefault();
            }
          }}
          customInput={
            <Form.Control
              value={inputValue}
              onChange={handleInputChange}
              isInvalid={touched && !!error}
              maxLength={getDateFormat(currentUser).length}
            />
          }
        />
      </Row>
      {touched && error && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export const RenderSwitch = ({
  id,
  className,
  input,
  tooltip,
  label,
  meta: { touched, error, warning },
  required,
  disabled,
}) => {
  return (
    <Form.Group className={className ? className : ""}>
      <Form.Switch
        id={id ?? "defaultSwitchId"}
        type="switch"
        checked={input.checked}
        onChange={input.onChange}
        onBlur={input.onBlur}
        label={label ?? null}
        required={required ?? null}
        disabled={disabled}
      />
      {tooltip ?? ""}
      {touched && error && (
        <Form.Control.Feedback className="d-inline" type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

const RenderInputGroupField = ({
  inputGroupText,
  FieldClassName,
  labelClassName = null,
  min = null,
  max = null,
  input,
  type,
  label,
  tooltip,
  placeholder,
  autoComplete,
  meta: { touched, error, warning },
  required,
  validateNumberOnly,
  maxLength,
  pattern,
  defaultValue,
  disabled = false,
  id,
}) => {
  // This function, handleChangeInput, is responsible for handling user input in an input field.
  // It validates the input to ensure it is a number and clears the input field if it is not a number,
  // but only if both 'validateNumberOnly' and 'isError' props are set to true.
  const handleChangeInput = (e) => {
    const isError = touched && error;

    const newValue = e.target.value;
    let isNotaNumber = isNaN(newValue); // Check if the 'newValue' is a number or not
    if (validateNumberOnly && isError && isNotaNumber) {
      input.onChange(""); // Clear the input field if there is an error, validateNumberOnly is true and input is not a Number
    } else {
      input.onChange(e);
    }
  };

  if (defaultValue) {
    input.value = Number(defaultValue);
  }

  return (
    <div>
      <Form.Group>
        {label && (
          <Form.Label className={labelClassName}>
            {label}
            <Mandatory required={required} />
            {tooltip && (
              <span title={tooltip} className="float-right">
                <i className="icon-sm icon-questionmark"></i>{" "}
              </span>
            )}
          </Form.Label>
        )}
        <InputGroup className="mb-3">
          <InputGroup.Text>{inputGroupText}</InputGroup.Text>
          <Form.Control
            {...input}
            as="input"
            id={id}
            className={FieldClassName}
            type={type ?? "text"}
            autoComplete={autoComplete}
            placeholder={placeholder}
            isInvalid={touched && !!error}
            isValid={false}
            maxLength={maxLength}
            defaultValue={defaultValue}
            pattern={pattern ? pattern : null}
            // required={required ?? null}
            disabled={disabled}
            onChange={handleChangeInput}
            min={min}
            max={max}
          />
        </InputGroup>
      </Form.Group>
    </div>
  );
};

export {
  ComparisonField,
  RenderCheckBox,
  RenderDatePicker,
  RenderField,
  RenderFieldNumber,
  RenderInputGroupField,
  RenderRadioGroup,
  RenderSelect,
  RenderTextArea,
};
