import React, { useState } from "react";
import { checkHostName, checkName, checkNameWithEmail } from "../../../../services/admin/invoices/emailSvc";
import RestApi from "../../../../providers/restApi";
import { Accordion, Col, Modal, Row } from "react-bootstrap";
import { checkDateFromNow } from "../../../../services/date/date";
import { FaCaretDown } from "react-icons/fa";
import moment from "moment";
import EmailDataLinks from "./emailDataLinks";
import UnlinkEmail from "./unlinkEmail";
import { getLinkImage } from "./getLinkImage";
import _ from "lodash";

export type threadReferencesPropsType = {
  email: any;
  //   patchRequest: any;
};

const restApiService = new RestApi();

const ThreadReferences = (props: threadReferencesPropsType) => {
  let email = props.email;
  if (email.thread_references) {
    email.thread_references.map(
      (thread_reference: any, i: number) => (thread_reference.extract_from = JSON.parse(thread_reference.from)),
    );
  }
  const [message, setMessage] = useState<any>({});
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const getMessage = (id: string) => {
    setMessage({});
    let url = "inbound_emails/" + id;

    restApiService
      .get(url)
      .then((result) => {
        setMessage(result.data);
      })
      .catch((err) => {});
  };
  return (
    <div>
      <Accordion className={"emailThreadReferences"}>
        {email.thread_references.map((thread_reference: any, i: number) => (
          // as zero index is considered falsy so using toggle keyword to make it trusy
          <div key={i}>
            <Accordion.Toggle
              eventKey={i + "toggle"}
              className={"bg-white w-100 border-0 text-left p-0"}
              onClick={() => getMessage(thread_reference.id)}
            >
              <div className={"d-flex"}>
                <p className={"flex-grow-1 text-dark"}>
                  <b>{checkNameWithEmail(thread_reference.extract_from)}</b>
                </p>
                <p className={"text-dark"}>{checkDateFromNow(thread_reference.received_at)}</p>
              </div>
              <div>
                <p className={"threadEmailDropdown"}>
                  to me <FaCaretDown />
                </p>
                <div className={"showThreadEmailDetail"}>
                  <div className={"row"}>
                    <h5 className={"col-3 text-right"}>Subject:</h5>
                    <h5 className={"col-9"}>{thread_reference.subject}</h5>
                  </div>
                  <div className={"row"}>
                    <h5 className={"col-3 text-right"}>From:</h5>
                    <h5 className={"col-9"}>{checkNameWithEmail(thread_reference.extract_from)}</h5>
                  </div>
                  <div className={"row"}>
                    <h5 className={"col-3 text-right"}>To:</h5>
                    <div className={"col-9"}>
                      {thread_reference.to
                        ? thread_reference.to.map((emailTo: any, index: number) => <h5 key={index}>{emailTo.email}</h5>)
                        : ""}
                    </div>
                  </div>
                  {_.isArray(thread_reference.cc) && thread_reference.cc.length ? (
                    <div className={"row"}>
                      <h5 className={"col-3 text-right"}>Cc:</h5>
                      <div className={"col-9"}>
                        <div className={"d-flex align-items-start"}>
                          <h5 className={"w-25"}>Cc:</h5>
                          <div>
                            {thread_reference.cc.map((cc: any, index: number) => (
                              <h5>{cc.email}</h5>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={"row"}>
                    <h5 className={"col-3 text-right"}>Date:</h5>
                    <h5 className={"col-9"}>{moment(thread_reference.received_at).format("MMM Do YYYY, h:mm a")}</h5>
                  </div>
                  <div className={"row"}>
                    <h5 className={"col-3 text-right"}>Status:</h5>
                    <h5 className={"col-9"}>{thread_reference.status}</h5>
                  </div>
                  <div className={"row"}>
                    <h5 className={"col-3 text-right"}>Host:</h5>
                    <h5 className={"col-9"}>{checkHostName(thread_reference.extract_from)}</h5>
                  </div>
                  <div className={"row"}>
                    <h5 className={"col-3 text-right"}>Name:</h5>
                    <h5 className={"col-9"}>{checkName(thread_reference.extract_from)}</h5>
                  </div>
                  <div className={"row"}>
                    <h5 className={"col-3 text-right"}>Id:</h5>
                    <h5 className={"col-9"}>{thread_reference.id}</h5>
                  </div>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={i + "toggle"}>
              <div>
                <div className={"row"}>
                  <h5 className={"col-2"}>Subject:</h5>
                  <h5 className={"col-10"}>{message.subject}</h5>
                </div>
                <div className={"row"}>
                  <h5 className={"col-2"}>From:</h5>
                  <h5 className={"col-10"}>{checkNameWithEmail(message.from)}</h5>
                </div>
                <div className={"row"}>
                  <h5 className={"col-2"}>To:</h5>
                  <div className={"col-10"}>
                    {_.isArray(message.to) &&
                      message.to.map((emailTo: any, index: number) => <h5 key={index}>{emailTo.email}</h5>)}
                  </div>
                </div>
                {_.isArray(message?.cc) && message.cc.length ? (
                  <div className={"row"}>
                    <h5 className={"col-2"}>Cc:</h5>
                    <div className={"col-10"}>
                      <div className={"d-flex align-items-start"}>
                        <h5 className={"w-25"}>Cc:</h5>
                        <div>
                          {message.cc.map((cc: any, index: number) => (
                            <h5>{message.email}</h5>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className={"row"}>
                  <h5 className={"col-2"}>Date:</h5>
                  <h5 className={"col-10"}>{moment(message.received_at).format("MMM Do YYYY, h:mm a")}</h5>
                </div>
                <div className={"row"}>
                  <h5 className={"col-2"}>Status:</h5>
                  <h5 className={"col-10"}>{message.status}</h5>
                </div>
                <div className={"row"}>
                  <h5 className={"col-2"}>Host:</h5>
                  <h5 className={"col-10"}>{checkHostName(message.from)}</h5>
                </div>
                <div className={"row"}>
                  <h5 className={"col-2"}>Name:</h5>
                  <h5 className={"col-10"}>{checkName(message.from)}</h5>
                </div>
                <div className={"row"}>
                  <h5 className={"col-2"}>Id:</h5>
                  <h5 className={"col-10"}>{message.id}</h5>
                </div>
                <div className={"row"}>
                  {_.isArray(message.data) && message.data.length ? (
                    <div className={"col-12 attachment px-0"}>
                      <div className={"ltHeader"}>
                        <Row className={"m-0 py-2"}>
                          <Col xs={8} className={"d-flex"}>
                            <h5 className={"text-white"}>Linked to Invoice(s): </h5>
                            <div>
                              <EmailDataLinks data={message.data} email={message} />
                            </div>
                          </Col>
                          <Col xs={4} className={"d-flex"}>
                            <button
                              disabled={!message.data || !message.data.length}
                              className="unlinkInvoice cursorPointer ml-auto d-flex"
                              onClick={handleShow}
                            >
                              <i className={"mr-1 icon-unlink-white"} style={{ width: 15 }} />
                              <h5 className={"text-white"}>Unlink Invoice(s)</h5>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <Modal show={show} onHide={handleClose}>
                  <UnlinkEmail
                    emailDetail={message}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    // patchRequest={props.patchRequest}
                  />
                </Modal>
                <div className={"row bg-white py-2 attachment"}>
                  <div className={"col-12"}>
                    {message && message.html && <div dangerouslySetInnerHTML={{ __html: message.html }} />}
                  </div>
                </div>
                {message.attachments && (
                  <div className={"row bg-white py-4 attachment border-top-0"}>
                    <div className={"col-12 "}>
                      {_.isArray(message.attachments) &&
                        message.attachments.map((attachment: any, i: number) => getLinkImage(attachment, true))}
                    </div>
                  </div>
                )}
              </div>
            </Accordion.Collapse>
            <div style={{ height: 10, background: "#F7F7F7", margin: "0 -15px" }}></div>
          </div>
        ))}
      </Accordion>
    </div>
  );
};

export default ThreadReferences;
