import { AppDispatch } from "reducers";
import {
  CardDisputesFormsType,
  CardsDisputesFormState,
  DisputeKey,
  ResponseSummary,
  ResponseSummaryType,
  PaymentResponse,
} from "./disputes/cardDisputes.types";
import { change, untouch } from "redux-form";
import { cloneDeep } from "lodash";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { restApiService } from "providers/restApi";
import { Dispatch } from "redux";
import i18n from "../../../i18n";
import moment from "moment";
import PurchasesApis from "../purchases/purchasesApis";
import { PurchasesTypes } from "../purchases/purchasesType";

// Each string value is a "magic number" that specifies the "reasonCode" value when making API calls.
export enum DISPUTE_KEYS_INDEX {
  DUPLICATE = "1",
  PAID_BY_OTHER = "2",
  NOT_RECEIVED = "3",
  INCORRECT_AMOUNT = "4",
  NOT_AUTHORIZED = "5",
  NOT_AUTHORIZED_STOLEN = "51",
  NOT_AUTHORIZED_CUSTOMER_HAD_POSSESSION = "52",
  NOT_AUTHORIZED_CUSTOMER_MISUSED = "53",
  QUALITY = "6",
  NOT_PROCESSED = "7",
  OTHER = "8",
}

export enum DISPUTE_STATE {
  SELECT_DISPUTE = "1",
  EDIT_CONTACT = "2",
  ENTER_VALUES = "3",
  SUMMARY = "4",
}

class CardDisputeService {
  static JSON_PATH: string = "components.admin.disputes.option.";

  static disputePath = (key: string): string => {
    return i18n.t(CardDisputeService.JSON_PATH + key);
  };

  static DISPUTE_OPTIONS: DisputeKey[] = [
    {
      key: DISPUTE_KEYS_INDEX.DUPLICATE,
      shortDesc: "duplicate",
      label: CardDisputeService.disputePath("duplicate.option_text"),
    },
    {
      key: DISPUTE_KEYS_INDEX.PAID_BY_OTHER,
      shortDesc: "paid by other",
      label: CardDisputeService.disputePath("paid_other.option_text"),
    },
    {
      key: DISPUTE_KEYS_INDEX.NOT_RECEIVED,
      shortDesc: "not received",
      label: CardDisputeService.disputePath("not_received.option_text"),
    },
    {
      key: DISPUTE_KEYS_INDEX.INCORRECT_AMOUNT,
      shortDesc: "incorrect amount",
      label: CardDisputeService.disputePath("incorrect_amount.option_text"),
    },
    {
      key: DISPUTE_KEYS_INDEX.NOT_AUTHORIZED,
      shortDesc: "not authorized",
      label: CardDisputeService.disputePath("not_authorized.option_text"),
    },
    {
      key: DISPUTE_KEYS_INDEX.QUALITY,
      shortDesc: "quality",
      label: CardDisputeService.disputePath("quality.option_text"),
    },
    {
      key: DISPUTE_KEYS_INDEX.NOT_PROCESSED,
      shortDesc: "not processed",
      label: CardDisputeService.disputePath("not_processed.option_text"),
    },
    {
      key: DISPUTE_KEYS_INDEX.OTHER,
      shortDesc: "other",
      label: CardDisputeService.disputePath("other.option_text"),
    },
  ];

  static DISPUTE_OPTIONS_GROUP = this.DISPUTE_OPTIONS.map((disputeOption) => {
    return { label: disputeOption.label, value: disputeOption.key };
  });

  static getDisputeOption(s: string): DisputeKey {
    const key = CardDisputeService.DISPUTE_OPTIONS.find((disputeOption) => disputeOption.key === s);
    if (!key) {
      throw new Error("Illegal option access");
    }
    return key;
  }

  static getTranslatedText = (key: string): string => {
    return this.DISPUTE_OPTIONS.find((disputeOption) => disputeOption.key === key)?.label || "";
  };

  static clearOptions = (dispatch: AppDispatch) => {
    dispatch(change("CardDisputes", "form.options", {}));
  };

  static FORM_FIELD_NAMES: string[] = [
    "form.options.ATTEMPT_TO_RESOLVE",
    "form.options.ATTEMPT_TO_RETURN",
    "form.options.CARDHOLDER_NOT_PARTICIPATE_ON_TRANSACTION",
    "form.options.CARDHOLDER_PARTICIPATED_ON_TRANSACTION",
    "form.options.CORRECT_AMOUNT_AUTHORIZED",
    "form.options.DATE_CREDIT_ISSUED",
    "form.options.DESCRIBE_THE_ITEM_NOT_RECEIVED",
    "form.options.DESCRIPTION",
    "form.options.DETAILS_OF_PURCHASE",
    "form.options.EXPECTED_DELIVERY_DATE",
    "form.options.FEEDBACK_FROM_MERCHANT",
    "form.options.MERCHANT_CONTACT_DATE",
    "form.options.OPTION_1_DATE_CARDHOLDER_NOT_PARTICIPATE_ON_TRANSACTIONS",
    "form.options.OPTION_1_DATE_POLICE_NOTIFIED",
    "form.options.OPTION_1_DESCRIBE_CIRCUMSTANCES",
    "form.options.OPTION_1_NEITHER_AUTHORIZED_USED",
    "form.options.OPTION_1_POLICE_NOTIFIED",
    "form.options.OPTION_1_STOLEN_DATE",
    "form.options.OPTION_1_STOLEN_PERSON_COMMENTS",
    "form.options.OPTION_2_NEITHER_AUTHORIZED_USED",
    "form.options.OPTION_3_DATE_OF_TERMINATION",
    "form.options.OPTION_3_EMPLOYEE_TERMINATED",
    "form.options.REASON_DESCRIPTION",
    "form.options.TYPE_OF_TRANSACTION",
  ];

  // This will clear all validation errors on the 7 "last step" components but not
  // the "second step" customer info values.
  static untouchDynamicFormValues = (dispatch: AppDispatch) => {
    dispatch(untouch("CardDisputes", ...this.FORM_FIELD_NAMES));
  };
}

class CardDisputeValidations {
  static validateDuplicate = (formValues: CardDisputesFormsType): string | undefined => {
    if (formValues.options?.ARN === undefined) {
      return CardDisputeService.disputePath("duplicate.validation_error");
    }
  };

  static validateNotAuthorized = (formValues: CardDisputesFormsType): string | undefined => {
    if (!formValues?.fraud_dispute_additional_info?.reasonCodeSubcategory) {
      return CardDisputeService.disputePath("not_authorized.must_choose");
    }
  };
}

class CardDisputeCleanupValues {
  static clean = (formValues: CardDisputesFormsType) => {
    // Convert all date objects to "YYYY-MM-DD" strings.
    if (formValues?.options) {
      Utility.convertDateObjectsToString(formValues.options);
      Utility.convertBooleanToYN(formValues.options);
    }

    // Fraud option #1 has a checkbox that only writes its value to the form if checked.
    // if it was not checked, write the missing "unchecked" value to the form.

    if (typeof formValues?.options?.OPTION_1_STOLEN_DATE !== "undefined") {
      formValues.options.OPTION_1_POLICE_NOTIFIED = formValues.options?.OPTION_1_POLICE_NOTIFIED || "N";
      formValues.options.OPTION_1_NEITHER_AUTHORIZED_USED = formValues.options?.OPTION_1_NEITHER_AUTHORIZED_USED || "N";
    }
  };
}

class Utility {
  // Convert "YYYY-MM-DD" to "MM/DD/YYYY"
  static formatDate = (date: string): string => {
    if (!date || date.length !== 10) {
      return date;
    }

    // strip out any leading zeroes for the day and month
    const day = parseInt(date.substring(5, 7)).toString();
    const month = parseInt(date.substring(8, 10)).toString();

    return day + "/" + month + "/" + date.substring(0, 4);
  };

  // User date selections via the calendar control return a date object; the API calls
  // require dates in the string format "YYYY-MM-DD"
  static convertDateObjectsToString = (obj: Record<string, any>): void => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] instanceof Date) {
          obj[key] = moment(obj[key]).format("MM/DD/YYYY");
        } else if (typeof obj[key] === "object") {
          // If the property is an object, recursively check its properties
          Utility.convertDateObjectsToString(obj[key]);
        }
      }
    }
  };

  static convertBooleanToYN = (obj: Record<string, any>): void => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "boolean") {
          obj[key] = obj[key] ? "Y" : "N";
        } else if (typeof obj[key] === "object") {
          // If the property is an object, recursively check its properties
          Utility.convertBooleanToYN(obj[key]);
        }
      }
    }
  };

  static returnToAngular = (currentState: CardsDisputesFormState) => {
    window.location.href = `${restApiService.angularBaseURL()}/purchase/${currentState.purchase?.id}`;
  };
}

class CardDisputeServiceSubmit {
  static findFormErrors = (form: CardDisputesFormsType) => {
    const currentDispute = form.dispute;
    var errors: string | undefined = "";

    switch (currentDispute) {
      case DISPUTE_KEYS_INDEX.DUPLICATE:
        errors = CardDisputeValidations.validateDuplicate(form);
        break;
      case DISPUTE_KEYS_INDEX.NOT_AUTHORIZED:
        errors = CardDisputeValidations.validateNotAuthorized(form);
        break;
    }

    return errors;
  };

  static submitDisputes = async (
    purchaseIDs: number[],
    request: any,
    state: CardsDisputesFormState,
    goToNextStep: (nextStep: string) => void,
    dispatch: AppDispatch,
  ) => {
    const responseSummary: ResponseSummary[] = [];

    const promises = purchaseIDs.map(async (purchaseID) => {
      try {
        await restApiService.patch(`purchases/${purchaseID}`, null, request, true);
        const reloadedPurchase = (await PurchasesApis.getPurchase(purchaseID)) as PurchasesTypes.Details;

        if (reloadedPurchase.disputes) {
          const paymentResponse: PaymentResponse = {
            disputes: {
              pdf_url: reloadedPurchase.disputes.pdf_url,
              reason: [],
            },
          };

          if (reloadedPurchase.disputes.status === "FAILED") {
            const details = Object.entries(reloadedPurchase.disputes.disputes_log)
              .map(([key, value]) => `<div style="padding-left: 24px" key="${key}">${key}: ${value}</div>`)
              .join("");

            responseSummary.push({
              id: purchaseID,
              dispute_successfully_created: false,
              details: details,
              query_response: paymentResponse,
            });
          } else {
            responseSummary.push({
              id: purchaseID,
              dispute_successfully_created: true,
              details: reloadedPurchase.disputes.status,
              query_response: paymentResponse,
            });
          }
        } else {
          // purchase object does not have disputes
          responseSummary.push({
            id: purchaseID,
            dispute_successfully_created: false,
            details: `Failed to create dispute`,
            query_response: { disputes: { pdf_url: "", reason: [] } },
          });

          return { purchaseID, error: "Failed to create dispute" };
        }
        return null; // No error
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "Unknown error";
        responseSummary.push({
          id: purchaseID,
          dispute_successfully_created: false,
          details: errorMessage,
          query_response: undefined,
        });

        return { purchaseID, error: errorMessage };
      }
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      console.log(error);
      CreateNotification("Error", "Please reach out to support", NotificationType.danger);
    }

    // The first purchaseID in the passed in array is the "original" purchase; any other
    // purchaseID's were added as extras in the fraud page.  The "original" purchase should
    // be the default displayed on the summary page.  Due to the promise.all() call it
    // cannot be assumed that the first element in the responseSummary array is the response
    // to the first purchase ID patch call.
    const firstID = responseSummary.find((e) => e.id === purchaseIDs[0] && e.dispute_successfully_created);

    const summary: ResponseSummaryType = {
      transactionIDToShowPDF: firstID ? firstID.id : -1,
      responseSummary: responseSummary,
    };

    dispatch(change("CardDisputes", "responseSummary", summary));

    goToNextStep(DISPUTE_STATE.SUMMARY);
  };

  static submit = async (
    values: CardsDisputesFormState,
    dispatch: Dispatch,
    goToNextStep: (nextStep: string) => void,
  ) => {
    let formValue: CardDisputesFormsType = cloneDeep(values).form;

    var errors = this.findFormErrors(formValue);

    if (errors) {
      CreateNotification("Errors", errors, "danger");
      return;
    }

    if (!values?.purchase?.id) {
      return;
    }

    if (!values.processing) {
      dispatch(change("CardDisputes", "processing", true));
    }

    formValue.options = formValue.options || [];

    CardDisputeCleanupValues.clean(formValue);

    var purchaseIDs: number[] = [values.purchase.id];

    var optionArray = [];
    for (const [key, value] of Object.entries(formValue.options)) {
      optionArray.push({ key: key, value: value });
    }

    var request = {
      purchase: {
        disputes: {
          address: formValue.address,
          city: formValue.city,
          contact_name: formValue.contact_name,
          email_address: formValue.email_address,
          fax_number: "",
          phone_number: formValue.phone_number,
          reason: optionArray,
          reason_code: formValue.fraud_dispute_additional_info?.reasonCodeSubcategory || formValue.dispute,
          state: formValue.state,
          status: "NEW",
          zip_code: formValue.zip_code,
        },
      },
    };

    // The fraud page allows the user to select multiple additional purchases for any of
    // the three categories.
    if (
      formValue.dispute === DISPUTE_KEYS_INDEX.NOT_AUTHORIZED &&
      formValue.fraud_dispute_additional_info?.additionalPurchaseObjectIDs?.length
    ) {
      purchaseIDs = [...purchaseIDs, ...formValue.fraud_dispute_additional_info.additionalPurchaseObjectIDs];
    }

    this.submitDisputes(purchaseIDs, request, values, goToNextStep, dispatch);
  };
}

export { CardDisputeService, CardDisputeValidations, CardDisputeCleanupValues, Utility, CardDisputeServiceSubmit };
