import VendorContactPicker from "components/admin/vendors/formSections/vendorContactPicker";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { Field, change, getFormValues } from "redux-form";
import { VendorType } from "services/admin/vendors/vendorTypes";
import { CompanyType, IUser } from "services/common/user/userTypes";
import { email as emailValidation } from "services/validations/reduxFormValidation";
import { RenderField } from "../../../forms/bootstrapFields";
import { required } from "../../../forms/inputFields";
import styles from "../vendors.module.css";

type AddVendorContactType = {
  handleNext: (step: number) => void;
  handlePrevious: (step: number) => void;
  handleCancel: () => void;
  currentUserCompany?: CompanyType;
  valid?: boolean;
};

type Contact = {
  raw: {
    id: number;
    name: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
  };
};

const AddVendorContact = ({
  handleNext,
  handlePrevious,
  handleCancel,
  valid,
  currentUserCompany,
}: AddVendorContactType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const vendor: VendorType = useTypedSelector((state) => getFormValues("VendorForm")(state));
  const [checkContact, setCheckContact] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const onContactChange = (fields: Contact) => {
    setDisabled(fields ? true : false);
    const details = {
      first_name: fields?.raw?.first_name,
      last_name: fields?.raw?.last_name,
      phone: fields?.raw?.phone,
      email: fields?.raw?.email,
    };
    dispatch(change("VendorForm", "contact", details));
  };

  const contactExist = async () => {
    if (vendor.master_id) {
      try {
        const result = await restApiService.get(`companies/${vendor.master_id}/vendor_contacts`);
        if (result.data.length > 0) {
          setCheckContact(true);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    contactExist();
    if (vendor.upsertCtrl) {
      setDisabled(true);
    }
  }, []);

  const handleContactFormNext = () => {
    dispatch(change("VendorForm", "skipPaymentMethodStep", false));
    handleNext(4);
  };
  const handleSkip = () => {
    dispatch(change("VendorForm", "contact", {}));
    handleNext(4);
    dispatch(change("VendorForm", "skipVendorContactStep", true));
  };

  useEffect(() => {
    const { first_name, last_name, phone, email } = vendor.contact || {};
    if (first_name || last_name || phone || email) {
      setIsFormTouched(true);
    } else {
      setIsFormTouched(false);
    }
  }, [vendor.contact]);

  return (
    <>
      <Row className="px-mb-25">
        <Col md="12">
          <span className={styles.vendorDetails}>
            <i className={`icon icon-vendor ${styles.iconVendor}`}></i>
            {t("admin.pages.vendor.addVendorContact")}
          </span>
          <br />
          <span>{t("admin.pages.vendor.contactNote")}</span>
          <span>{t("admin.pages.vendor.learnMore")}</span>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <p className={styles.contactTitle}>{t("admin.pages.vendor.contact")}</p>
        </Col>
        {checkContact && (
          <Col md="12" className={styles.vendorFormField}>
            <Field
              name="upsertCtrl.contact_id"
              component={VendorContactPicker}
              type="text"
              label={t("admin.pages.vendor.chooseExistingContact")}
              callBack={onContactChange}
            />
          </Col>
        )}
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="contact.first_name"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.first_name")}
            className={`w-100`}
            validate={[required]}
            disabled={disabled}
            required
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="contact.last_name"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.last_name")}
            className={`w-100`}
            validate={[required]}
            disabled={disabled}
            required
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="contact.phone"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.phone")}
            className={`w-100`}
            disabled={disabled}
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="contact.email"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.contactEmail")}
            className={`w-100`}
            validate={[required, emailValidation]}
            disabled={disabled}
            required
          />
        </Col>
      </Row>
      <Row className="px-mt-25">
        <Col md={4}>
          <Button variant="secondary" className="px-mr-10" onClick={() => handlePrevious(4)}>
            {t("admin.pages.vendor.back")}
          </Button>
        </Col>
        <Col md={{ span: 4, offset: 4 }} className={styles.btnColumn}>
          <Button variant="secondary" className="px-mr-10" onClick={() => handleCancel()}>
            {t("admin.pages.vendor.cancel")}
          </Button>
          <>
            {currentUser?.company?.vendor?.skip_vendor_contact_for_add_vendor && (
              <Button
                variant="secondary"
                className="px-mr-10"
                onClick={() => handleSkip()}
                title="Use this button to skip adding a vendor contact. You can always do that later using the “Add Contact” button in the Vendor module"
                disabled={isFormTouched}
              >
                {t("admin.pages.vendor.skip")}
              </Button>
            )}
            <Button
              variant="primary"
              className={styles.btnNext}
              onClick={() => handleContactFormNext()}
              disabled={!valid}
              title={`${!valid ? "To continue, complete all required fields" : ""}`}
            >
              {t("admin.pages.vendor.next")}
            </Button>
          </>
        </Col>
      </Row>
    </>
  );
};

export default AddVendorContact;
