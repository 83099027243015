import React, { useMemo } from "react";
import {
  RenderRadioGroup,
  RenderDatePicker,
  RenderTextArea,
  RenderCheckBox,
} from "../../../../../forms/bootstrapFields";
import { Col, Row, Container } from "react-bootstrap";
import { Field, getFormValues } from "redux-form";
import { required, noWhiteSpaceOnly } from "services/validations/reduxFormValidation";
import { RequiredFieldIcon } from "./requiredFieldIcon";
import { useTranslation } from "react-i18next";
import Style from "../dispute.module.css";
import { useTypedSelector } from "reducers";
import { CardsDisputesFormState } from "../../../../../../services/admin/cards/disputes/cardDisputes.types";

export const NotAuthorizedReason51 = () => {
  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.option.not_authorized.${key}`);
  };

  const yes_no = [
    { label: t2("yes"), value: "Y" },
    { label: t2("no"), value: "N" },
  ];

  const isPoliceNotified = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue?.form?.options?.OPTION_1_POLICE_NOTIFIED;
  });

  const isPoliceValidation = useMemo(() => (isPoliceNotified === "Y" ? [required] : []), [isPoliceNotified]);

  return (
    <>
      <Row>
        <Col sm={{ offset: 2 }}>
          <Row>
            <table className={`${Style.table_with_padded_rows}`}>
              <tbody>
                <tr>
                  <td>{t2("option_1.what_date")}</td>
                  <td colSpan={2}>
                    <div className={`${Style.no_form_margin_bottom}`}>
                      <Field
                        name="form.options.OPTION_1_STOLEN_DATE"
                        validate={[required]}
                        component={RenderDatePicker}
                        type="date"
                        maxDate={new Date()}
                      ></Field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{t2("option_1.last_trans_date")}</td>
                  <td colSpan={2}>
                    <div className={`${Style.no_form_margin_bottom}`}>
                      <Field
                        name="form.options.OPTION_1_DATE_CARDHOLDER_NOT_PARTICIPATE_ON_TRANSACTIONS"
                        validate={[required]}
                        component={RenderDatePicker}
                        type="date"
                        maxDate={new Date()}
                      ></Field>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Container>
                      <Row>
                        {t2("option_1.police")}
                        <RequiredFieldIcon></RequiredFieldIcon>
                        <div style={{ width: "200px", marginLeft: "16px" }}>
                          <Field
                            name="form.options.OPTION_1_POLICE_NOTIFIED"
                            orientation="horizontal"
                            component={RenderRadioGroup}
                            group={yes_no}
                            required={true}
                            validate={[required]}
                          ></Field>
                        </div>
                      </Row>
                    </Container>
                  </td>
                  <td>
                    <Field
                      name="form.options.OPTION_1_DATE_POLICE_NOTIFIED"
                      component={RenderDatePicker}
                      type="date"
                      required={isPoliceNotified}
                      validate={isPoliceValidation}
                      maxDate={new Date()}
                    ></Field>
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
          <Row>
            <Col md={10} className="px-0">
              <Field
                label={t2("option_1.explain") + " "}
                required={true}
                name="form.options.OPTION_1_DESCRIBE_CIRCUMSTANCES"
                component={RenderTextArea}
                validate={[required, noWhiteSpaceOnly]}
                className={`${Style.fixed_textarea}`}
              ></Field>
            </Col>
          </Row>
          <Row>
            <Col md={10} className="px-0">
              <Field
                label={t2("option_1.suspect_known") + " "}
                required={true}
                name="form.options.OPTION_1_STOLEN_PERSON_COMMENTS"
                component={RenderTextArea}
                className={`${Style.fixed_textarea}`}
              ></Field>
            </Col>
          </Row>
          <Row>{t2("option_1.must_check")}</Row>
          <Field
            name="form.options.OPTION_1_NEITHER_AUTHORIZED_USED"
            component={RenderCheckBox}
            type="checkbox"
            label={t2("never_used")}
          />
        </Col>
      </Row>
    </>
  );
};
