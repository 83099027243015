import React, { Component } from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";
// import EditUploadedInvoice from "./uploaded/editUploadedInvoice"
import InvoiceForm from "./invoiceForm";
import { SubmissionError, getFormValues } from "redux-form";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import RestApi from "../../../providers/restApi";

import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { createConfirmModal } from "../../../reducers/confirmModalReducer";
import { createCompleteError } from "../../../services/general/reduxFormSvc";

class InvoiceSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableFormBtn: false,
      loaded: true,
    };
    this.restApiService = new RestApi();
  }

  // help to filter only selected lines to send for submission.
  submitSelectedRecords = (data) => {
    if (data.length > 0) {
      return data.filter((obj) => obj.isAllow);
    }
  };

  setItemLines = (invoiceObj) => {
    invoiceObj.poItems = this.submitSelectedRecords(invoiceObj.poItems);
    invoiceObj.invoice_items_attributes = invoiceObj.poItems;
  };

  setExpenseLines = (invoiceObj) => {
    invoiceObj.poExpenses = this.submitSelectedRecords(invoiceObj.poExpenses);
    invoiceObj.account_transaction_attributes = {};
    invoiceObj.account_transaction_attributes.date = invoiceObj.date;
    invoiceObj.account_transaction_attributes.amount = invoiceObj.amount;
    invoiceObj.account_transaction_attributes.debit_entries_attributes = invoiceObj.poExpenses;
  };

  submitAttachments = (attachments, invoiceId) => {
    var formData = new FormData();
    attachments.map((attachment, key) => {
      if (!attachment.id) {
        formData.append("invoice[assets_attributes[" + key + "]asset_file]", attachment);
      }
    });

    this.restApiService
      .patch("vendor_invoices/" + invoiceId, null, formData)
      .then((result) => {
        CreateNotification("Success", "Attachment uploaded successfully", "success");
        this.props.history.push("/ar/invoices");
      })
      .catch((error) => {
        console.error("Error saving company info: ", error);
      });
  };

  isPoLinesLinkedToInvoice = (invoiceObj) => {
    let allowToSubmit = true;
    // isAmountDisable flag always return true whenever po lines are getting selected
    if (invoiceObj.purchase_order_id && !invoiceObj.isAmountDisable) {
      CreateNotification("Not Allowed", "At least one po item/expense should be linked to invoice.", "warning");
      allowToSubmit = false;
    }
    return allowToSubmit;
  };

  isSubmittedWithValidAmount = (amount) => {
    let allowToSubmit = true;
    if (amount <= 0) {
      CreateNotification("Not Allowed", "Please enter valid amount to submit.", "warning");
      allowToSubmit = false;
    }
    return allowToSubmit;
  };

  handleSubmit = async (invoiceObj) => {
    if (_.isObject(invoiceObj)) {
      if (!this.isPoLinesLinkedToInvoice(invoiceObj)) {
        return false;
      }
      if (!this.isSubmittedWithValidAmount(invoiceObj.amount)) {
        return false;
      }
      this.setState({ disableFormBtn: true });
      invoiceObj.amount = invoiceObj.amount ? parseFloat(invoiceObj.amount) : undefined;

      // it send po items with invoice.invoice_items_attributes
      if (_.isArray(invoiceObj.poItems) && invoiceObj.poItems.length > 0) {
        this.setItemLines(invoiceObj);
      }

      // it send po expenses with account_transaction_attributes.debit_entries_attributes
      if (_.isArray(invoiceObj.poExpenses) && invoiceObj.poExpenses.length > 0) {
        this.setExpenseLines(invoiceObj);
      }

      if (invoiceObj.purchase_order_id) {
        invoiceObj.invoice_purchase_order_links_attributes = [];
        invoiceObj.invoice_purchase_order_links_attributes.push({ purchase_order_id: invoiceObj.purchase_order_id });
      }

      try {
        const response = await this.restApiService.post("vendor_invoices", null, { invoice: invoiceObj });
        if (response.data.id) {
          if (_.isArray(invoiceObj.attachments) && invoiceObj.attachments.length > 0) {
            this.submitAttachments(invoiceObj.attachments, response.data.id);
          } else {
            this.props.history.push("/ar/invoices");
          }
          CreateNotification("Added", "Invoice number " + response.data.number + " added", NotificationType.success);
        }
      } catch (error) {
        this.setState({ disableFormBtn: false });
        const { response } = error;
        if (response.status === 422) {
          if (_.isPlainObject(response.data)) {
            const completeErrorObj = createCompleteError(response.data);
            throw new SubmissionError({
              ...completeErrorObj,
            });
          }
        } else {
          CreateNotification("Validation Error", "Please enter valid data", NotificationType.danger);
        }
      }
    }
  };

  render() {
    return (
      <InvoiceForm
        {...this.props}
        totalAmount={this.state.totalAmount}
        isAmountDisable={this.state.isAmountDisable}
        isDisableFormBtn={this.state.disableFormBtn}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    invoice: getFormValues("InvoiceForm")(state) || {},
    InvoiceForm: state.submitInvoicesReducers.InvoiceForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInvoiceDetails: (payload) => {
      dispatch({ type: actionType.UPDATE_INVOICE_DETAILS, payload: payload });
    },
    createConfirmModal: (payload) => {
      dispatch(createConfirmModal(payload));
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InvoiceSubmit));
