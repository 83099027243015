import React from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import InvoiceCreditLineSection from "./subSections/creditLineSection";
import InvoiceDebitLineSection from "./subSections/debitLineSection";

const UploadQueueExpenseLineSection = () => {
  const { getValues } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const invId = getValues("id");
  return (
    <>
      {invId && (
        <>
          {!currentUser?.company?.invoice?.hide_debit_account && (
            <Row>
              <Col md="12">
                <InvoiceDebitLineSection />
              </Col>
            </Row>
          )}
          {!currentUser.company.invoice_hide_credit_account && (
            <Row>
              <Col md="12">
                <InvoiceCreditLineSection />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default UploadQueueExpenseLineSection;
