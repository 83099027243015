import React, { useEffect, useCallback } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import NavTabs from "../nav";
import TabNavigator from "../../../common/tabNavigator/tabNavigator";
import ListPendingReceipts from "./listPendingReceipts";
import ListMatchedReceipts from "./listMatchedReceipts";
import { useHistory, useLocation } from "react-router-dom";
import receiptService from "services/admin/expenses/receipts/receiptsSvc";

const ListReceipts = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");
  const receiptActiveTab = tab ?? receiptService.listTabs.queue;

  const onActiveTabChange = useCallback(
    (activeTab: string) => {
      history.push(`/ap/inbound_receipts?tab=${activeTab}`);
    },
    [history],
  );

  useEffect(() => {
    if (!tab) {
      //if url is not present with tab then setting url with tab
      onActiveTabChange(receiptActiveTab);
    }
  }, [onActiveTabChange, receiptActiveTab, tab]);

  const receiptsTabs = [
    {
      activeTab: receiptService.listTabs.queue,
      label: "Receipt Queue",
    },
    {
      activeTab: receiptService.listTabs.matched,
      label: "Matched Receipt",
    },
  ];

  return (
    <Container fluid>
      <Row className="mt-4">
        <Col>
          <NavTabs activePageName="Receipt" />
        </Col>
      </Row>

      <Card className="p-3">
        <Row>
          <Col>
            <TabNavigator
              activeTab={receiptActiveTab}
              handleTabChange={(activeTab: string) => {
                onActiveTabChange(activeTab);
              }}
              tabData={receiptsTabs}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            {receiptActiveTab === receiptService.listTabs.queue && <ListPendingReceipts />}
            {receiptActiveTab === receiptService.listTabs.matched && <ListMatchedReceipts />}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default ListReceipts;
