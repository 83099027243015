import React, { useEffect, useState } from "react";
import DataGrid from "./../../../components/datagrid/datagrid";
import RestApi from "./../../../providers/restApi";
import vendorPurchasersHeader from "./../../../components/datagrid/girdHeaders/vendorPurchasersHeader";
import useIsMounted from "../../../components/common/hooks/useIsMounted";

const restApiService: RestApi = new RestApi();

const CustomersList = () => {
  const [purchasers, setPurchasers] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  const gridStyle: React.CSSProperties = {
    width: "100%",
  };

  const getPurchers = async () => {
    try {
      setLoading(true);
      const response = await restApiService.get("purchasers");
      if (isMounted) {
        setPurchasers(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isMounted) {
      getPurchers();
    }
    return () => {};
  }, []);

  return (
    <div style={{ padding: "1%" }}>
      <DataGrid
        gridStyle={gridStyle}
        rowData={purchasers}
        columnDefs={vendorPurchasersHeader}
        dataType={"customers"}
        autoHeight={true}
        loading={loading}
        actionButtons={{
          viewLink: "/ar/purchasers/",
        }}
      />
    </div>
  );
};

export default CustomersList;
