import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { FaDownload, FaRegFolder } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import { searchStateResetter, setCurrentEmailTab } from "../../../../reducers/admin/emailReducer";
import _ from "lodash";

const EmailTabs = () => {
  const dispatch = useDispatch();
  const currentEmailTab = useSelector((state: RootState) => state.emailRed.currentEmailTab);
  const stats = useSelector((state: RootState) => state.emailRed.emailStats);
  const tagsList = useSelector((state: RootState) => state.emailRed.tags);

  const onChangeCurrentEmailTab = (currentTab: string) => {
    // remove grid filter if any
    dispatch(searchStateResetter());

    // change email tab
    dispatch(setCurrentEmailTab(currentTab));
  };

  return (
    <Row>
      <Col md="12">
        <Tab.Container id="left-tabs-example" activeKey={currentEmailTab}>
          <Nav variant="pills" className="flex-column email-sidebar h-100 w-100 ">
            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("Inbox");
                }}
                eventKey="Inbox"
                className={"secondaryFontColor tabLink"}
              >
                <FaDownload /> Inbox
                {stats.total && <span className="label float-right label-inbox">{stats?.total}</span>}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("New");
                }}
                eventKey="New"
                className={"secondaryFontColor tabLink"}
              >
                <FaRegFolder /> New
                {stats.New && (
                  <span className="label float-right label-new" style={{ height: 15 }}>
                    {stats.New}
                  </span>
                )}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("Failed");
                }}
                eventKey="Failed"
                className={"secondaryFontColor tabLink"}
              >
                <FaRegFolder /> Failed
                {stats.Failed && <span className="label float-right label-failed">{stats.Failed}</span>}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("Processed");
                }}
                eventKey="Processed"
                className={"secondaryFontColor tabLink"}
              >
                <FaRegFolder /> Processed
                {stats.Processed && <span className="label float-right label-new">{stats.Processed}</span>}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("Completed");
                }}
                eventKey="Completed"
                className={"secondaryFontColor tabLink"}
              >
                <FaRegFolder /> Completed
                {stats.Completed && <span className="label float-right label-new">{stats.Completed}</span>}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("Sent");
                }}
                eventKey="Sent"
                className={"secondaryFontColor tabLink"}
              >
                <FaRegFolder /> Sent
                {stats.Sent && <span className="label float-right label-new">{stats.Sent}</span>}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("No Attachment");
                }}
                eventKey="No Attachment"
                className={"secondaryFontColor tabLink"}
              >
                <FaRegFolder /> No Attachment
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("SPAM");
                }}
                eventKey="SPAM"
                className={"secondaryFontColor tabLink"}
              >
                <FaRegFolder /> SPAM
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("Communication");
                }}
                eventKey="Communication"
                className={"secondaryFontColor tabLink"}
              >
                <FaRegFolder /> Communication{" "}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("On Hold");
                }}
                eventKey="On Hold"
                className={"secondaryFontColor tabLink"}
              >
                <FaRegFolder /> On Hold{" "}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className={"tabItem"}>
              <Nav.Link
                onClick={() => {
                  onChangeCurrentEmailTab("Duplicate");
                }}
                eventKey="Duplicate"
                className={"secondaryFontColor tabLink"}
              >
                <FaRegFolder /> Duplicate{" "}
              </Nav.Link>
            </Nav.Item>

            {_.isArray(tagsList) &&
              Boolean(tagsList.length) &&
              tagsList.map((item: { label: string }) => {
                return (
                  <Nav.Item key={item.label} className={"tabItem"}>
                    <Nav.Link
                      onClick={() => {
                        onChangeCurrentEmailTab(item.label);
                      }}
                      eventKey={item.label}
                      className={"secondaryFontColor tabLink"}
                    >
                      <FaRegFolder /> {item.label}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
          </Nav>
        </Tab.Container>
      </Col>
    </Row>
  );
};

export default EmailTabs;
