import { Form } from "react-bootstrap";
import Select from "react-select";
import React, { useCallback, useEffect, useRef, useState } from "react";
import restApi from "../../../../providers/restApi";
import { isArray, isObject, isPlainObject } from "lodash";
import Styles from "../override.module.css";
import { findSelectedSingle, onBlurSingle, onChangeSingle, parseForSelect } from "../../../../services/general/helpers";
import { Mandatory } from "../../../forms/bootstrapFields";

const restApiService = new restApi();

const getParams = (params) => {
  let obj = { status: "ACTIVE", account_structure: true };
  if (params) {
    obj = { ...obj, ...params };
  }
  return obj;
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const InterCompaniesPicker = (props) => {
  let {
    className,
    label,
    input,
    meta: { touched, error, warning },
    params,
    required,
    isMulti,
  } = props;

  const [interCompanies, setInterCompanies] = useState([]);
  const mounted = useRef(false);

  const getInterCompanies = useCallback(async () => {
    const result = await restApiService.get("inter_companies.lk", getParams(params), null, true, null, true);
    const value = parseForSelect(result.data);
    if (mounted.current) setInterCompanies(value);
  }, [params]);

  useEffect(() => {
    getInterCompanies();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [getInterCompanies]);

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label>
          {label ?? null}
          <Mandatory required={required} />
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        value={findSelectedSingle(input, interCompanies)}
        placeholder="search/select"
        onChange={(value) => onChangeSingle(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={interCompanies}
        isMulti={isMulti}
        isClearable
        classNamePrefix="select"
      />
      {error && touched && (
        <div style={{ fontSize: "80%", color: "red", padding: "2px" }}>
          {" "}
          {error ? error : "Inter-Companies Required... "}
        </div>
      )}
    </Form.Group>
  );
};

export default InterCompaniesPicker;
