import AddExpenses from "./addExpenses";
import AddMain from "./addMain";
import AddPOItems from "./addPOItems";
const ChangeOrderRoutes = [
  {
    path: "/ap/change_orders/purchase_orders/:po_id/add",
    exact: true,
    name: "Change Orders Add",
    component: AddMain,
    authorization: {
      I: "list",
      a: "ChangeOrders",
    },
  },
  {
    path: "/ap/change_orders/purchase_orders/:po_id/add/po_items",
    exact: true,
    name: "Change Orders Add PO Items",
    component: AddPOItems,
    authorization: {
      I: "list",
      a: "ChangeOrders",
    },
  },
  {
    path: "/ap/change_orders/purchase_orders/:po_id/add/expenses",
    exact: true,
    name: "Change Orders Add Expenses",
    component: AddExpenses,
    authorization: {
      I: "list",
      a: "ChangeOrders",
    },
  },
];

export default ChangeOrderRoutes;
