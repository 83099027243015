import { AxiosResponse } from "axios";
import useFormValue from "components/admin/hooks/useFormValue";
import { Mandatory } from "components/forms/bootstrapFields";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { arrayPush, FieldArray, FieldArrayFieldsProps } from "redux-form";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { ExpenseConstants } from "services/admin/expenses/expenseSvc";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import DebitAccountLine from "./debitAccountLine";
import style from "./splitExpenseCoding.module.css";

type ExpenseDebitAccountsTableBodyPropsType = {
  fields: FieldArrayFieldsProps<ExpensesTypes.ExpenseDebitAccount>;
  // total: number | string | null;
  // currencyCode: string | undefined;
  hasCustomFields: boolean;
};

const ExpenseDebitAccountsTableBody = ({ fields, hasCustomFields }: ExpenseDebitAccountsTableBodyPropsType) => {
  return (
    <>
      {fields.map((field, index: number) => {
        return <DebitAccountLine key={index} field={field} index={index} hasCustomFields={hasCustomFields} />;
      })}
    </>
  );
};

const ManageExpenseDebitAccountsSplits = () => {
  const currentUser = useTypedSelector<IUser>(selectCurrentUser);
  const dispatch = useDispatch();
  const remainingAmount = useFormValue<number>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "remaining_amount");
  const remainingAmountPercentage = useFormValue<number>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    "remaining_percentage",
  );
  const targetCurrencyRemainingAmount = useFormValue<number>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    "target_currency_remaining_amount",
  );
  const subsidiaryId = useFormValue<IDType>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "subsidiary_id");
  const subsidiary = useFormValue<ExpensesTypes.Subsidiary>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "subsidiary");
  const policyId = useFormValue<IDType>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "policy_id");
  const policy = useFormValue<ExpensesTypes.Policy>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "policy");
  const isCurrencyConversionActive = useFormValue<boolean>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    "currency_conversion_active",
  );
  const expenseItem = useFormValue<ExpensesTypes.ExpenseItemFormDataType>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    `expenseItem`,
  );

  const [hasCustomFields, setHasCustomFields] = useState(false);
  const checkHasCustomFields = async () => {
    try {
      const response: AxiosResponse = await restApiService.get(
        "custom_field_metadata",
        { model: "ExpenseItem" },
        null,
        true,
        null,
        true,
      );
      if (response.data) {
        const hasCustomFields = Array.isArray(response.data) && response.data.length > 0;
        setHasCustomFields(hasCustomFields);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkHasCustomFields();
  }, []);

  const addDebitAccountAttribute = () => {
    const newDebitAccount: ExpensesTypes.ExpenseDebitAccount = {
      percentage: Number(remainingAmountPercentage) > 0 ? Number(remainingAmountPercentage) : 0,
      policy_id: policyId,
      subsidiary_id: subsidiaryId,
      subsidiary: subsidiary,
      policy: policy,
    };

    expenseItem &&
      expenseItemCommonSvc.inheritDefaultValueFromEmployee({
        modelData: newDebitAccount,
        expenseItem,
      });

    if (isCurrencyConversionActive) {
      newDebitAccount.base_currency_amount = Number(remainingAmount) > 0 ? Number(remainingAmount) : "";
      newDebitAccount.amount = Number(targetCurrencyRemainingAmount) > 0 ? Number(targetCurrencyRemainingAmount) : "";
    } else {
      newDebitAccount.amount = Number(remainingAmount) > 0 ? Number(remainingAmount) : "";
    }

    // Prevent the 'percentage' value from being set to '0' if the 'amount' value is empty
    if (newDebitAccount.amount === "") {
      newDebitAccount.percentage = "";
    }

    dispatch(
      arrayPush(
        ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
        "expense_report_debit_accounts_attributes",
        newDebitAccount,
      ),
    );
  };

  return (
    <div className={style.mb24}>
      <div id="Split-Coding" className={style.expenseAllocationTableWrapper}>
        <Table className={style.expenseAllocationTable + " text-left"} width="100%">
          <thead>
            <tr>
              <th></th>
              {currentUser.company.has_categories && (
                <th>
                  Category
                  <Mandatory required />
                </th>
              )}
              {currentUser.company.has_departments && currentUser.company.expense_item?.show_department && (
                <th>
                  Department
                  <Mandatory required={currentUser.company.expense_item?.required_department} />
                </th>
              )}
              {currentUser.company.has_locations && currentUser.company.expense_item?.show_location && (
                <th>
                  Location
                  <Mandatory required={currentUser.company.expense_item?.required_department} />
                </th>
              )}
              {currentUser.company.has_business_units && currentUser.company.expense_item?.show_business_unit && (
                <th>
                  Business Unit
                  <Mandatory required={currentUser.company.expense_item?.required_business_unit} />
                </th>
              )}
              {currentUser.company.has_projects && currentUser.company.expense_item?.show_project && (
                <th>
                  Project
                  <Mandatory required={currentUser.company.expense_item?.project_required} />
                </th>
              )}
              {(hasCustomFields || currentUser.company.has_expense_item_metadata) && <th>Custom Fields</th>}
              <th className={style.amountCol}>
                Amount
                <Mandatory required />
              </th>
              <th className={style.percentageCol}>
                Percentage
                <Mandatory required />
              </th>
            </tr>
          </thead>
          <tbody className={style.expenseAllocationTableBody}>
            <FieldArray
              name="expense_report_debit_accounts_attributes"
              component={ExpenseDebitAccountsTableBody}
              hasCustomFields={hasCustomFields}
            />
          </tbody>
        </Table>
      </div>
      <div className={"d-flex justify-content-center " + style.addSplitBtnWrapperDiv}>
        {/* {!disableAddSplit && ( */}
        <button
          type="button"
          className={style.addSplitBtn}
          onClick={() => addDebitAccountAttribute()}
          // disabled={disableAddSplit}
        >
          <i className={"icon icon-add-blue mt-1 " + style.addSplitIcon} />
          <span className={style.blueAddSplitText}>Add Split</span>
        </button>
        {/* )} */}

        {/* {disableAddSplit && (
            <TooltipRender title="No Remaining Amount to Split" containerClassName={style.expenseBarToolTip} onEntering={commonService.enteringNormal}>
              <button
                type="button"
                className={style.addSplitBtn + " border-none bg-transparent"}
                onClick={() => addDebitAccountAttribute()}
                disabled={disableAddSplit}
              >
                <i className="icon  icon-add-blue mt-1"></i>
                Add Split
              </button>
            </TooltipRender>
          )} */}
      </div>
    </div>
  );
};

export default ManageExpenseDebitAccountsSplits;
