import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { selectReceiptDetail, setReceiptDetails, setShowReceiptLinker } from "reducers/admin/receiptsReducer";
import _ from "lodash";
import ComparativePreview from "./comparativePreview";
import style from "./receipts.module.css";
import { useTranslation } from "react-i18next";
import { companyDateFormat, format } from "services/general/dateSvc";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";
import ReceiptsApis from "services/admin/expenses/receipts/receiptsApis";
import { useDispatch } from "react-redux";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import useIsMounted from "components/common/hooks/useIsMounted";
import LoadingBox from "components/common/loaders/loadingBox";
import { restApiService } from "providers/restApi";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { getPrimaryCurrencyCodeOfUser } from "reducers/userReducers";
import { formattedAmount } from "services/general/helpers";

const ExpenseLinker = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [expenses, setexpenses] = useState<ExpensesTypes.List>();
  const [selectedExpesense, setSelectedExpensese] = useState<ExpensesTypes.ListItem>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //  using lodash utility for grouping by date
  // sort to sort the expense in desending order by date
  const expensesGroupedByPostedDate = _(expenses)
    .sortBy((expenses) => expenses?.submit_date)
    .reverse()
    .groupBy((expense) => expense.submit_date)
    .value();

  const receiptDetails = useTypedSelector(selectReceiptDetail);
  const currentUser = useTypedSelector((state) => state.user);
  const userPrimaryCurrency = useTypedSelector(getPrimaryCurrencyCodeOfUser);
  const receiptCurrencyCode = receiptDetails?.form_data?.currency_code || userPrimaryCurrency;

  const getExpenses = async () => {
    try {
      setIsLoading(true);
      const result = await ExpensesApis.getExpensesList({ filter: { statuses: ["NEW"], is_expense_item: true } });
      if (isMounted.current) {
        setexpenses(result);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const leftSectionData: {
    header: string;
    imageUrl?: string;
    dataItems: { key: string; value?: any }[];
  } = {
    header: t("admin.pages.receipts.uploadReceiptSummary"),
    imageUrl:
      (_.isArray(receiptDetails?.assets_attributes) && receiptDetails?.assets_attributes[0]?.asset_expiring_url) || "",
    dataItems: [
      { key: t("admin.pages.receipts.merchantName"), value: receiptDetails?.form_data?.merchant ?? "N/A" },
      {
        key: t("admin.pages.receipts.transactionDate"),
        value: companyDateFormat(receiptDetails?.form_data?.date, currentUser) ?? "N/A",
      },
      {
        key: t("admin.pages.receipts.totalAmount"),
        value: formattedAmount(String(receiptDetails?.form_data?.total), receiptCurrencyCode, 2, true) ?? "N/A",
      },
      { key: t("admin.pages.receipts.note"), value: receiptDetails?.note ?? "N/A" },
    ],
  };

  const rightSectionData: {
    header: string;
    imageUrl?: string;
    dataItems: { key: string; value?: any }[];
  } = {
    header: t("admin.pages.receipts.selectedExpense"),
    dataItems: [
      { key: t("admin.pages.receipts.merchantName"), value: selectedExpesense?.vendor_name ?? "N/A" },
      {
        key: t("admin.pages.receipts.transactionDate"),
        value: companyDateFormat(selectedExpesense?.submit_date, currentUser) ?? "N/A",
      },
      {
        key: t("admin.pages.receipts.totalAmount"),
        value:
          (selectedExpesense?.amount &&
            formattedAmount(String(selectedExpesense?.total), selectedExpesense?.currency_code, 2, true)) ??
          "N/A",
      },
      { key: t("admin.pages.receipts.note"), value: selectedExpesense?.description ?? "N/A" },
    ],
  };

  const onSuccessFullLink = async () => {
    // refetch reciept details
    try {
      if (receiptDetails?.id) {
        const result = await ReceiptsApis.getReceipt(receiptDetails.id);
        dispatch(setReceiptDetails(result));

        // close receipt linker modal
        dispatch(setShowReceiptLinker(false));
      }
    } catch (error) {
      throw error;
    }
  };

  const linkExpenseWithReceipt = async () => {
    try {
      if (receiptDetails?.id) {
        setIsSubmitting(true);
        const updatePayload = {
          documentable_type: "InvoiceItem",
          documentable_id: selectedExpesense?.id,
          status: "MATCHED",
        };
        const result = await ReceiptsApis.editReceipt(receiptDetails?.id, updatePayload);
        CreateNotification(t("success"), t("admin.pages.receipts.successReceiptLinked"), NotificationType.success);
        await onSuccessFullLink();
        setIsSubmitting(false);
      }
    } catch (error) {
      CreateNotification(t("error"), t("admin.pages.receipts.errorReceiptLinked"), NotificationType.danger);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    getExpenses();
  }, []);

  return (
    <Row>
      <Col>
        <ComparativePreview leftSectionData={leftSectionData} rightSectionData={rightSectionData} />

        <Row className="my-3">
          <Col className={style.transactionsContainer}>
            <LoadingBox isLoading={isLoading}>
              {Object.entries(expensesGroupedByPostedDate).map((groupEntery) => {
                const [postedDate, expensesOnPostedDate] = groupEntery;
                return (
                  <Fragment key={postedDate}>
                    <Row className={style.transactionHead + " px-0"}>
                      <Col>
                        {t("admin.pages.receipts.postedOn")}{" "}
                        {format(postedDate, currentUser.company.tz, "MMM DD, YYYY")}
                      </Col>
                    </Row>
                    {expensesOnPostedDate?.map((expense) => (
                      <Row key={expense?.id} className={style.transactionRow + " px-0 pr-3"}>
                        <Col md="1">
                          <Form.Check
                            onChange={() => setSelectedExpensese(expense)}
                            type="radio"
                            name="selectedTransaction"
                          />
                        </Col>

                        <Col md="3" className={style.transactionDate + " text-break"}>
                          {format(expense.submit_date, currentUser.company.tz, "MMM DD, YYYY")}
                        </Col>

                        <Col md="3" className={style.vendorName + " text-break"}>
                          {expense.vendor_name?.toUpperCase()}
                        </Col>

                        <Col md="3" className={style.vendorName + " text-break"}>
                          {t("admin.pages.receipts.expenseNo")} {expense.number}
                        </Col>
                        <Col md="2" className={style.transactionAmount + " text-break"}>
                          {expense?.amount &&
                          expense.currency_code &&
                          currencySymbolRenderer(expense.currency_code) + expense.total
                            ? expense.total?.toFixed(2)
                            : null}
                        </Col>
                      </Row>
                    ))}
                  </Fragment>
                );
              })}
            </LoadingBox>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end align-items-end">
            <a href={restApiService.angularBaseURL() + `expense?receiptID=${receiptDetails?.id}`}>
              <Button variant="secondary" className={"mr-2"}>
                {t("admin.pages.receipts.createExpenseItem")}
              </Button>
            </a>
            <Button
              disabled={!selectedExpesense || isSubmitting}
              onClick={linkExpenseWithReceipt}
              className={style.linkButton}
            >
              {isSubmitting && <Spinner size="sm" className="mr-2" animation="border" />}
              {t("admin.pages.receipts.link")}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ExpenseLinker;
