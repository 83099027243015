import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Field, change } from "redux-form";
import { RenderField, RenderSelect } from "../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import BankAddressLine from "../bankAddressLine";
import BankAccountNumbers from "../bankAccountNumbers";
import BankRoutingNumber from "../../pickers/reduxPickers/bankRoutingNumber";
import { useDispatch } from "react-redux";
import { required } from "../../../../services/validations/reduxFormValidation";

type BankAddressType = {
  address?: string;
  city?: string;
  state?: string;
  zipcode?: number;
};

export type MandatoryFieldsListType = (fieldNames: string[]) => void;

const AchPaymentMethod = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const [defaultBankName, setDefaultBankName] = useState("");
  const dispatch = useDispatch();

  // Currently designed for Vendor use only; need to refine logic for broader reuse.
  const updateBankName = (bankName: string) => {
    dispatch(change("VendorForm", `${modelName}.bank_name`, bankName));
    setDefaultBankName(bankName);
  };
  // Currently designed for Vendor use only; need to refine logic for broader reuse.
  const updateBankAddress = (bankAddress: BankAddressType) => {
    dispatch(change("VendorForm", `${modelName}.address_attributes.address1`, bankAddress.address));
    dispatch(change("VendorForm", `${modelName}.address_attributes.city`, bankAddress.city));
    dispatch(change("VendorForm", `${modelName}.address_attributes.state`, bankAddress.state));
    dispatch(change("VendorForm", `${modelName}.address_attributes.zipcode`, bankAddress.zipcode));
  };

  return (
    <>
      <Col sm="6">
        <Field
          name={`${modelName}.account_type`}
          component={RenderSelect}
          options={[
            { value: null, label: "" },
            { value: "checking", label: "Checking" },
            { value: "savings", label: "Savings" },
          ]}
          FieldClassName="formField"
          placeholder={"Search/Select"}
          label={t("common.paymentMethod.accountType")}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          required
          validate={[required]}
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.account_nick_type`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountNickName")}
          className={`w-100`}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.delivery_method`}
          component={RenderSelect}
          FieldClassName="formField"
          options={[
            { value: null, label: "-- Select --" },
            { value: "same-day", label: "Same Day" },
          ]}
          placeholder={"Search/Select"}
          label={t("common.paymentMethod.deliveryMethod")}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.routing`}
          component={BankRoutingNumber}
          type="text"
          label={t("common.paymentMethod.bankRoutingNumber")}
          className={`w-100`}
          bankNameCallBack={updateBankName}
          required
          validate={[required]}
          bankCompleteAddressCallBack={updateBankAddress}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
          className={`w-100`}
          required
          validate={[required]}
          readOnly={defaultBankName}
        />
      </Col>
      <BankAccountNumbers modelName={`${modelName}`} /> {/* Render Bank Account and Confirm Account Number fields */}
      <BankAddressLine modelName={`${modelName}`} hideCountry={true} defaultCountry="USA" />
    </>
  );
};

export default AchPaymentMethod;
