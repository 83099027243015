import TooltipRender from "components/toolTip/tooltipRender";
import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { RootState, useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import {
  arrayPush,
  FormName,
  FormSection,
  formValueSelector,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { ExpenseConstants } from "services/admin/expenses/expenseSvc";
import commonService from "services/common/commonSvc";
import { IDType } from "services/common/types/common.type";
import AddExpenseItemFormSection from "./addExpenseItemFormSection";
import EditExpenseItemFormSection from "./editExpenseItemFormSection";
import style from "./expenseItem.module.css";

type ExpenseExtraFormDataType = {
  expenseReport?: ExpenseReportTypes.Details;
  receiptID?: IDType;
  onCancel?: () => void;
  isEdit?: boolean;
};

type ExpenseItemFormPropsType = InjectedFormProps<ExpensesTypes.ExpenseFormDataType, ExpenseExtraFormDataType> &
  ExpenseExtraFormDataType;

export type FormExpenseItemType = ExpensesTypes.ExpenseFormDataType | null;

export const formExpenseItemFormData =
  (formName: string) =>
  (state: RootState): FormExpenseItemType => {
    return getFormValues(formName)(state) as FormExpenseItemType;
  };

export const useFormValue = <T,>(formName: string | undefined, selectionString?: string | undefined): T | undefined => {
  // this custom hook will help to select and individual value or object of values
  // by  just passing it's string formLocationString for ex , expense_item[0].amount <-- single value | expense_item[0].policy <-- object

  const selector = formName && formValueSelector(formName);
  const sectionValue: T | undefined = useTypedSelector(
    (state) => selectionString && selector && selector(state, selectionString),
  );
  return sectionValue;
};

const FormExpenseItemFormComponent = ({
  form,
  handleSubmit,
  onCancel,
  isEdit,
  ...restProps
}: ExpenseItemFormPropsType) => {
  const dispatch = useDispatch();
  const expenseFormData = useTypedSelector(formExpenseItemFormData(form));
  const hideDraftBtn = expenseFormData?.hideDraftBtn;
  const submitDisabled = expenseFormData?.is_submit_disabled;
  const expenses = expenseFormData?.expenses;
  const currentUser = useTypedSelector(selectCurrentUser);

  const isAllExpenseSplitValid =
    !expenseItemCommonSvc.isSplitEnable(currentUser) ||
    expenses?.every((expense) => expense && expense.is_split_100_percent === true);
  const isSubmitDisable = submitDisabled || !isAllExpenseSplitValid;

  const addExpenseItem = () => {
    dispatch(arrayPush(form, "expenses", {}));
  };

  const setDraftStatus = () => {
    if (expenseFormData) {
      expenseFormData.is_draft = true;
    }
  };

  const initializeForm = () => {
    // initializing form with one expense item
    dispatch(
      arrayPush(form, "expenses", {
        assets_attributes: [],
        expense_report_debit_accounts_attributes: [],
      }),
    );
  };

  useEffect(() => {
    initializeForm();
  }, []);

  if (!isEdit) {
    // Add Expense item logic
    return (
      <Container className="my-4" fluid>
        <Row>
          <Col md="12">
            <Row>
              <Col md="5">
                <h3 className="d-flex">
                  Create a New Expense Item
                  <TooltipRender
                    className={"icon-tooltips " + style.iconToolTip}
                    title="Complete this form to create a new Expense Item. In order to fully process this expense for payment or reimbursement, it must be added to an Expense Report and submitted for approval."
                    placement="right"
                  />
                </h3>
              </Col>
            </Row>

            <Form noValidate name={form} onSubmit={handleSubmit}>
              <Row>
                <Col lg="12" className="text-right">
                  <Button variant="secondary" className="mr-2" onClick={onCancel}>
                    Cancel
                  </Button>
                  {!hideDraftBtn && (
                    <Button variant="secondary" className="mr-2" type="submit" onClick={setDraftStatus}>
                      Save Draft
                    </Button>
                  )}

                  {!isAllExpenseSplitValid ? (
                    <TooltipRender
                      containerClassName={style.expenseBarToolTip}
                      onEntering={commonService.enteringNormal}
                      title={"Expense splits must equal 100%"}
                    >
                      <Button variant="primary" type="submit" disabled={isSubmitDisable}>
                        {" "}
                        Create Expense Item{" "}
                      </Button>
                    </TooltipRender>
                  ) : (
                    <Button variant="primary" type="submit" disabled={isSubmitDisable}>
                      {" "}
                      Create Expense Item{" "}
                    </Button>
                  )}
                </Col>
              </Row>

              {!restProps.expenseReport && <hr className={style.separator} />}
              {Array.isArray(expenses) &&
                expenses.map((expense, index) => {
                  return (
                    <Row key={index} className="mb-3">
                      <Col md="12">
                        <FormSection name={`expenses[${index}]`}>
                          <FormName
                            children={(props: any) => (
                              <AddExpenseItemFormSection
                                {...props}
                                index={index}
                                expenseReport={restProps.expenseReport}
                              />
                            )}
                          />
                        </FormSection>
                      </Col>
                    </Row>
                  );
                })}

              <Row className="mt-3">
                <Col md="4" lg="4">
                  {!restProps.expenseReport && !restProps.receiptID && (
                    <Button variant="primary" onClick={addExpenseItem}>
                      <i className="icon icon-plus" />
                      Add Expense
                    </Button>
                  )}
                </Col>
                <Col md="8" lg="8" className="text-right btn-mr-16">
                  <Button variant="secondary" className="mr-2" onClick={onCancel}>
                    Cancel
                  </Button>
                  {!hideDraftBtn && (
                    <Button variant="secondary" className="mr-2" type="submit" onClick={setDraftStatus}>
                      Save Draft
                    </Button>
                  )}
                  {!isAllExpenseSplitValid ? (
                    <TooltipRender
                      containerClassName={style.expenseBarToolTip}
                      onEntering={commonService.enteringNormal}
                      title={"Expense splits must equal 100%"}
                    >
                      <Button variant="primary" type="submit" disabled={isSubmitDisable}>
                        {" "}
                        Create Expense Item{" "}
                      </Button>
                    </TooltipRender>
                  ) : (
                    <Button variant="primary" type="submit" disabled={isSubmitDisable}>
                      {" "}
                      Create Expense Item{" "}
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  } else {
    // Edit expense item
    return (
      <Container fluid>
        <Row className="mt-3">
          <Col md="12">
            <Row>
              <Col md="5">
                <h3 className="d-flex">Edit Expense Item</h3>
              </Col>
            </Row>

            <Form noValidate name={form} onSubmit={handleSubmit}>
              <Row>
                <Col lg="12" className="text-right">
                  <Button variant="secondary" className="mr-2" onClick={onCancel}>
                    Cancel
                  </Button>
                  {!hideDraftBtn && (
                    <Button variant="secondary" className="mr-2" type="submit" onClick={setDraftStatus}>
                      Save Draft
                    </Button>
                  )}
                  {!isAllExpenseSplitValid ? (
                    <TooltipRender
                      containerClassName={style.expenseBarToolTip}
                      onEntering={commonService.enteringNormal}
                      title={"Expense splits must equal 100%"}
                    >
                      <Button variant="primary" type="submit" disabled={isSubmitDisable}>
                        Save
                      </Button>
                    </TooltipRender>
                  ) : (
                    <Button variant="primary" type="submit" disabled={isSubmitDisable}>
                      Save
                    </Button>
                  )}
                </Col>
              </Row>

              {!restProps.expenseReport && <hr className={style.separator} />}
              {Array.isArray(expenses) &&
                expenses.map((expense, index) => {
                  if (index === 0) {
                    return (
                      <Row key={index} className="mb-3">
                        <Col md="12">
                          <FormSection name={`expenses[${index}]`}>
                            <FormName
                              children={(props: any) => (
                                <EditExpenseItemFormSection
                                  {...props}
                                  index={index}
                                  expenseReport={restProps.expenseReport}
                                />
                              )}
                            />
                          </FormSection>
                        </Col>
                      </Row>
                    );
                  } else {
                    // should only show one expense item at zeroth position
                    return null;
                  }
                })}

              <Row className="mt-3">
                <Col md="12" lg="12" className="text-right btn-mr-16">
                  <Button variant="secondary" className="mr-2" onClick={onCancel}>
                    Cancel
                  </Button>
                  {!hideDraftBtn && (
                    <Button variant="secondary" className="mr-2" type="submit" onClick={setDraftStatus}>
                      Save Draft
                    </Button>
                  )}
                  {!isAllExpenseSplitValid ? (
                    <TooltipRender
                      containerClassName={style.expenseBarToolTip}
                      onEntering={commonService.enteringNormal}
                      title={"Expense splits must equal 100%"}
                    >
                      <Button variant="primary" type="submit" disabled={isSubmitDisable}>
                        Save
                      </Button>
                    </TooltipRender>
                  ) : (
                    <Button variant="primary" type="submit" disabled={isSubmitDisable}>
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
};

const FormExpenseItem = reduxForm<ExpensesTypes.ExpenseFormDataType, ExpenseExtraFormDataType>({
  form: ExpenseConstants.EXPENSE_ITEM_FORM,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
  shouldValidate: () => true,
})(FormExpenseItemFormComponent);

export default FormExpenseItem;
