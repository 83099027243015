import _ from "lodash";
import { SET_ADDRESS_FORM, SET_ADDRESSES, SET_ADDRESSABLE_TYPE, SET_ADDRESSABLE_ID } from "../../actions/actionTypes";
import RestApi from "../../providers/restApi";
import { CreateNotification, NotificationType } from "../../services/general/notifications";
import i18next from "i18next";

export const ADDRESS_FORM = "addressForm";

const restApiService = new RestApi();

// INITIAL_STATE
const initialState = {
  // address form
  addressForm: {
    form: {},
  },
  addresses: [],
  addressableId: null,
  addressableType: null,
};

const addressSectionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ADDRESS_FORM:
      return { ...state, addressForm: { form: payload } };
    case SET_ADDRESSES:
      return { ...state, addresses: payload };
    case SET_ADDRESSABLE_ID:
      return { ...state, addressableId: payload };
    case SET_ADDRESSABLE_TYPE:
      return { ...state, addressableType: payload };
    default:
      return state;
  }
};

export default addressSectionReducer;

// action dispatchers
export const setAddressForm = (formData) => (dispatch, getState) => {
  dispatch({ type: SET_ADDRESS_FORM, payload: formData });
};

export const setAddresses = (addresses) => (dispatch, getState) => {
  dispatch({ type: SET_ADDRESSES, payload: addresses });
};

export const setAddressableId = (addressableId) => async (dispatch, getState) => {
  dispatch({ type: SET_ADDRESSABLE_ID, payload: addressableId });
};

export const setAddressableType = (addressableType) => async (dispatch, getState) => {
  dispatch({ type: SET_ADDRESSABLE_TYPE, payload: addressableType });
};

export const onAddressUnmount = () => (dispatch, getState) => {
  dispatch(setAddressForm(initialState.addressForm.form));
};

export const loadAddresses = (addressableType, addressableId) => async (dispatch, getState) => {
  if (!addressableId || !addressableId) return;
  const response = await restApiService.get("addresses", {
    addressable_type: addressableType,
    addressable_id: addressableId,
  });
  dispatch(setAddresses(response.data));
};

export const setAddressableTypeId =
  ({ addressableType, addressableId }) =>
  (dispatch, getState) => {
    dispatch(setAddressableId(addressableId));
    dispatch(setAddressableType(addressableType));
  };

// these function will remove default from other address if present any
export const removeDefaultAddress = (address) => async (dispatch, getState) => {
  const addresses = getState().addressSectionRed.addresses;
  try {
    // Now remove other is_default flag
    // these will find address with same address_type and is_default true
    const prevouseDefaultAddress =
      _.isArray(addresses) &&
      addresses.find((addr) => addr.is_default && addr.address_type === address.address_type && addr.id !== address.id);
    if (prevouseDefaultAddress) {
      const response = await restApiService.patch("addresses/" + prevouseDefaultAddress.id, null, {
        is_default: false,
      });

      dispatch(
        setAddresses(
          addresses.map((addr) => {
            if (addr.id === response.data.id) {
              return response.data;
            }
            return addr;
          }),
        ),
      );
    }
  } catch (error) {
    console.log(error);
  }
};

// remove defualt from address passed
export const removeDefaultFromAddress = (address) => async (dispatch, getState) => {
  const addresses = getState().addressSectionRed.addresses;
  try {
    // Now remove other is_default flag
    // these will find address with same address_type and is_default true

    const response = await restApiService.patch("addresses/" + address.id, null, { is_default: false });

    dispatch(
      setAddresses(
        addresses.map((addr) => {
          if (addr.id === response.data.id) {
            return response.data;
          }
          return addr;
        }),
      ),
    );
    CreateNotification(
      i18next.t("success"),
      i18next.t("components.admin.addressable.updatedSuccess", { id: address.id }),
      NotificationType.success,
    );
  } catch (error) {
    console.log(error);
  }
};

export const setDefaultAddress = (address) => async (dispatch, getState) => {
  const addresses = getState().addressSectionRed.addresses;
  try {
    const response = await restApiService.patch("addresses/" + address.id, null, { is_default: true });

    dispatch(
      setAddresses(
        addresses.map((addr) => {
          if (addr.id === response.data.id) {
            return response.data;
          }
          return addr;
        }),
      ),
    );

    CreateNotification(
      i18next.t("success"),
      i18next.t("components.admin.addressable.updatedSuccess", { id: address.id }),
      NotificationType.success,
    );

    dispatch(removeDefaultAddress(address));
  } catch (error) {
    console.log(error);
  }
};
