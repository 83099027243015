import CurrencySymbolRenderer from "components/admin/commonUsed/currencySymbolRenderer";
import AccountPicker from "components/admin/pickers/reactHookFormPickers/accountPicker";
import BusinessUnitPicker from "components/admin/pickers/reactHookFormPickers/businessUnitPicker";
import CategoryPicker from "components/admin/pickers/reactHookFormPickers/categoryPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import Panel from "components/common/panel/panel";
import { InputField, TextAreaField } from "components/forms/hookFormFields";
import _ from "lodash";
import React, { useMemo } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import { IUser } from "services/common/user/userTypes";
import styles from "../inlineFieldsSection.module.css";

const InvoiceCreditLineSection = () => {
  const { control, getValues, setValue, trigger } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const currencyCode = getValues("currency_code");

  const { fields, append, update } = useFieldArray<any>({
    control,
    name: "credit_entries_attributes",
    keyName: "_id" as "id",
  });

  const creditEntries = fields as unknown as InvoiceType.TInvoiceExpenseLineEntry[];
  const invoiceCommonSvc = useInvoiceCommonSvc();

  // Calculate the number of columns dynamically
  const dynamicColumnCount = useMemo(() => {
    let columnCount = 5; // Actions, Account, Percent, Memo, Amount (static columns)
    if (currentUser.company.has_departments && !currentUser.company.invoice?.expenses?.department?.is_hide)
      columnCount += 1; // Department
    if (currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location) columnCount += 1; // Location
    if (currentUser.company.has_business_units && !currentUser.company.invoice_account_hide_business_unit)
      columnCount += 1; // Business Unit
    if (
      currentUser.company.has_categories &&
      (!currentUser.company.invoice || (currentUser.company.invoice && !currentUser.company.invoice.hide_categories))
    ) {
      columnCount += 1; // Category
    }
    return columnCount;
  }, [currentUser]);

  const addNewRow = () => {
    let obj = { allowEdit: true };
    append(obj);
  };

  const allowEditRow = async (entry: InvoiceType.TInvoiceExpenseLineEntry, index: number) => {
    let isValidate = true;
    if (entry.allowEdit) {
      isValidate = await trigger(`credit_entries_attributes.${index}`);
    }
    if (isValidate) {
      const updatedItem = {
        ...getValues(`credit_entries_attributes.${index}`),
        id: entry.id,
        allowEdit: !entry.allowEdit,
      };
      setValue(`credit_entries_attributes.${index}`, updatedItem);
      update(index, updatedItem);
    }
  };

  // const destroyExpense = (entry: InvoiceType.TInvoiceExpenseLineEntries, index: number) => {
  //   let updatedEntry = { ...entry, _destroy: 1 };
  //   let updatedItem = { ...getValues(`credit_entries_attributes.${index}`), _destroy: 1 };

  //   setValue(`credit_entries_attributes.${index}`, updatedItem);
  //   update(index, updatedItem);
  // };

  return (
    <Panel
      header={
        <span>
          <i className="icon px-m-0 icon-bank"></i>Credit Entries
        </span>
      }
    >
      <Row>
        <Col sm="12" className="overflow-auto">
          <Table bordered size="sm">
            <thead>
              <tr className={styles.tableHead}>
                <td>Actions</td>
                <td>Account</td>
                <td>Amount</td>
                <td>Memo</td>

                {currentUser.company.has_departments && !currentUser.company.invoice?.expenses?.department?.is_hide && (
                  <td>Department</td>
                )}
                {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                  <td>Location</td>
                )}
                {currentUser.company.has_business_units && !currentUser.company.invoice_account_hide_business_unit && (
                  <td>Business Unit</td>
                )}
                {currentUser.company.has_categories &&
                  (!currentUser.company.invoice ||
                    (currentUser.company.invoice && !currentUser.company.invoice.hide_categories)) && <td>Category</td>}
              </tr>
            </thead>
            <tbody>
              {creditEntries.map((entry, index) => {
                return (
                  _.isPlainObject(entry) &&
                  entry._destroy !== 1 && (
                    <tr key={entry._id || index} className={styles.tableBody + " uploadQueueItemLineRow"}>
                      <td className={styles.actions}>
                        <div className={styles.actions}>
                          <i
                            className={`icon ${entry.allowEdit ? "icon-true-tick" : "editIcon"} `}
                            onClick={() => {
                              allowEditRow(entry, index);
                            }}
                          ></i>

                          {/* <i className="icon delete" onClick={() => destroyExpense(entry, index)}></i> */}
                        </div>
                      </td>
                      <td className="px-pt-10">
                        {entry.allowEdit ? (
                          <AccountPicker
                            name={`credit_entries_attributes.${index}.account_id`}
                            accountGroupName="INVOICE_CREDIT_GROUPS"
                            modelData={`credit_entries_attributes.${index}`}
                            required={true}
                            parentObj={""}
                          />
                        ) : (entry.account_number ? entry.account_number : "") + " - " + entry.account_name ? (
                          entry.account_name
                        ) : (
                          ""
                        )}
                      </td>

                      <td>
                        {entry.allowEdit ? (
                          <InputField name={`credit_entries_attributes.${index}.amount`} type="number" />
                        ) : (
                          <CurrencySymbolRenderer name="currency_code" amount={Number(entry.amount) || 0} />
                        )}
                      </td>

                      <td>
                        {entry.allowEdit ? (
                          <TextAreaField name={`credit_entries_attributes.${index}.memo`} />
                        ) : (
                          entry.memo
                        )}
                      </td>

                      {currentUser.company.has_departments &&
                        !currentUser.company.invoice?.expenses?.department?.is_hide && (
                          <td>
                            {" "}
                            {entry.allowEdit ? (
                              <DepartmentPicker
                                name={`credit_entries_attributes.${index}.department_id`}
                                modelData={`credit_entries_attributes.${index}`}
                                // parentObj={""} //header
                              />
                            ) : (
                              (entry.department?.name ?? entry.department_name)
                            )}
                          </td>
                        )}
                      {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                        <td>
                          {entry.allowEdit ? (
                            <LocationPicker
                              name={`credit_entries_attributes.${index}.location`}
                              modelData={`credit_entries_attributes.${index}`}
                              containerClassName={styles.projectMinWidth}
                            />
                          ) : (
                            (entry.location?.name ?? entry.location_name)
                          )}
                        </td>
                      )}
                      {currentUser.company.has_business_units &&
                        !currentUser.company.invoice_account_hide_business_unit && (
                          <td>
                            {entry.allowEdit ? (
                              <BusinessUnitPicker
                                name={`credit_entries_attributes.${index}.business_unit`}
                                modelData={`credit_entries_attributes.${index}`}
                                containerClassName={styles.projectMinWidth}
                              />
                            ) : (
                              (entry.business_unit?.name ?? entry.business_unit_name)
                            )}
                          </td>
                        )}

                      {currentUser.company.has_categories &&
                        (!currentUser.company.invoice ||
                          (currentUser.company.invoice && !currentUser.company.invoice.hide_categories)) && (
                          <td>
                            {entry.allowEdit ? (
                              <CategoryPicker
                                name={`credit_entries_attributes.${index}.category_id`}
                                modelData={`credit_entries_attributes.${index}`}
                                containerClassName={styles.projectMinWidth}
                              />
                            ) : (
                              (entry.category?.name ?? entry.category_name)
                            )}
                          </td>
                        )}
                    </tr>
                  )
                );
              })}
              {creditEntries.length < 1 && (
                <tr>
                  <td colSpan={dynamicColumnCount} className={styles.noRecords}>
                    Record not found!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {/* Not allowing to add credit entry */}
      {/* <Row>
        <Col>
          <Button variant="light" className={styles.btnAddNewEntry} onClick={() => addNewRow()}>
            <i className="icon icon-add-black"></i>Add Credit Line
          </Button>
        </Col>
      </Row> */}
    </Panel>
  );
};

export default InvoiceCreditLineSection;
