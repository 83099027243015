import _ from "lodash";
import { useCallback, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { change, Field, FieldArray, getFormValues } from "redux-form";
import DepartmentPicker from "../../../../../../components/admin/pickers/reduxFormPickers/departmentPicker";
import { time_zones } from "../../../../../../components/app.svc.Lookup";
import FileTypePicker from "../../../../../../components/common/pickers/reduxFormPickers/fileTypePicker";
import { RenderCheckBox, RenderField, RenderSelect } from "../../../../../../components/forms/bootstrapFields";
import LocationPicker from "../../../../../../components/pickers/reduxFormPicker/locationPicker";
import SubsidiaryPicker from "../../../../../../components/pickers/reduxFormPicker/subsidiaryPicker";
import TooltipRender from "../../../../../../components/toolTip/tooltipRender";
import { INTEGRATION_FORM_NAME } from "../../../../../../reducers/admin/integrationsReducer";
import { required, email as validateEmail } from "../../../../../../services/validations/reduxFormValidation";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";

const InboundEmail = ({ fields }) => {
  return (
    <>
      {fields.map((doc_aliase, index) => {
        return (
          <Row key={index}>
            <Col sm="6">
              <Field
                component={RenderField}
                name={`${doc_aliase}`}
                placeholder="Inbound To Email"
                validate={[validateEmail, required]}
                id={`integration_settings.doc_aliases[${index}]`}
              />
            </Col>
            <Col sm="1" className="mt-2">
              <i
                className="icon icon-delete"
                onClick={() => {
                  fields.remove(index);
                }}
              ></i>
            </Col>
          </Row>
        );
      })}
      <Row className="m-0">
        <Col lg="3" className="p-0">
          <Button onClick={() => fields.push("")}>+ Add More</Button>
        </Col>
      </Row>
    </>
  );
};

const IntegrationInvoiceAutomation = () => {
  const dispatch = useDispatch();

  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const currentUser = useSelector((state) => state.user);

  const assignDocAliases = useCallback(
    (doc_aliases) => {
      if (!_.isArray(doc_aliases) || (_.isArray(doc_aliases) && !doc_aliases.length > 0)) {
        // update form data with dispatch change from redux form
        dispatch(change(INTEGRATION_FORM_NAME, "form.doc_aliases", [""]));
      }
    },
    [dispatch],
  );

  const setParsersAiMl = useCallback(
    (value) => {
      dispatch(change(INTEGRATION_FORM_NAME, "form.parsers.ai_ml", value));
    },
    [dispatch],
  );

  useEffect(() => {
    assignDocAliases(form.doc_aliases);
    setParsersAiMl(true);
  }, []);

  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <Col lg="12">
          <h4>AI API Credential</h4>
          <hr className="mt-0" />
        </Col>
        <Col lg="6">
          <Field
            name="form.account"
            label={"Username(ml.ocr@accrualify.com)"}
            component={RenderField}
            placeholder="Username"
            type="text"
            validate={[required]}
            required
          />
        </Col>

        <Col lg="6">
          <Field
            name="form.password"
            label={"Password(optional)"}
            component={RenderField}
            placeholder="Password"
            type="password"
          />
        </Col>

        <Col lg="6">
          <Field
            name="form.email"
            label={"Inbound To Email (ie. test@invoices-prod.accrualify.com, test@corpaycomplete.com)"}
            component={RenderField}
            placeholder="Inbound To Email"
            type="email"
            validate={[required]}
            id="integration_settings.email"
            required
          />
        </Col>
      </Row>

      <Row className="m-0">
        <Col lg="12">
          <label>Additional Inbound Emails</label>
          <span className="color-red">*</span>
          {/* don't show doc_aliases until get initialized */}
          {_.isArray(form.doc_aliases) && <FieldArray name="form.doc_aliases" component={InboundEmail} />}
        </Col>
      </Row>
      <Row className="m-0 mt-2">
        <Col lg="12">
          <h4>Default</h4>
          <hr className="mt-0" />
        </Col>
        <Col lg="6">
          <Field
            name="form.doc_type"
            component={RenderSelect}
            type="select"
            label="Doc Type"
            options={[
              { label: "invoice", value: "invoice" },
              { label: "expense receipt", value: "receipt" },
            ]}
            id="doc_type"
          />
        </Col>

        <Col lg="6">
          <Field
            name="form.doc_time_zone"
            label={"Received_at Stamp Time Zone:(default: US/Pacific )"}
            component={RenderSelect}
            options={time_zones}
            type="select"
          />
        </Col>

        <Col lg="6">
          <Field
            name="form.doc_invoice_status"
            id="doc_invoice_status"
            component={RenderSelect}
            label="Status"
            type="select"
            options={[
              { label: "", value: "" },
              { label: "NEW", value: "NEW" },
              { label: "DRAFT", value: "DRAFT" },
            ]}
          ></Field>
        </Col>

        <Col lg="6">
          <Field
            name="form.doc_cm_status"
            id="doc_cm_status"
            component={RenderSelect}
            label="CM Status"
            type="select"
            options={[
              { label: "", value: "-- select --" },
              { label: "NEW", value: "NEW" },
              { label: "DRAFT", value: "DRAFT" },
            ]}
          ></Field>
        </Col>

        {currentUser.company.has_subsidiaries && (
          <Col lg="6">
            <Field name="form.doc_subsidiary_id" component={SubsidiaryPicker} label="Subsidiary"></Field>
          </Col>
        )}

        {currentUser.company.has_locations && (
          <Col lg="6">
            <Field name="form.doc_location_id" component={LocationPicker} label="Location"></Field>
          </Col>
        )}

        {currentUser.company.has_departments && (
          <Col lg="6">
            <Field name="form.doc_department_id" component={DepartmentPicker} label="Department"></Field>
          </Col>
        )}

        <Col lg="6">
          <Row className="m-0">
            <Field
              name="form.doc_inline_attachment"
              component={RenderCheckBox}
              type={"checkbox"}
              label="Inline Attachment"
              tooltip={
                <TooltipRender
                  title="create invoice using text email body when there is no attachment for global setting or can also add '[inline_attachment=true] in subject to each email"
                  className="icon icon-mask"
                  placement="top"
                />
              }
              className="mr-2"
            ></Field>

            <Field
              name="form.doc_url_attachment"
              component={RenderCheckBox}
              type={"checkbox"}
              label="URL Links Attachment"
              tooltip={
                <TooltipRender
                  title="extract urls in email body to download for processing"
                  className="icon icon-mask"
                  placement="top"
                />
              }
            ></Field>

            <Field
              name="form.doc_split_pdf_attachment"
              component={RenderCheckBox}
              type={"checkbox"}
              label="Split pdf attachments on blank page"
              tooltip={
                <TooltipRender
                  title="this will split a pdf into two invoices if a blank page is placed in between"
                  className="icon icon-mask"
                  placement="top"
                />
              }
            ></Field>
            <Field
              name="form.doc_map_subject_to_reference_number"
              component={RenderCheckBox}
              type={"checkbox"}
              label="Map Email Subject to reference number"
              tooltip={
                <TooltipRender
                  title="this will take the subject from the email and put in the reference number of the invoice"
                  className="icon icon-mask"
                  placement="top"
                />
              }
            ></Field>
          </Row>
        </Col>
      </Row>
      <Row className="m-0">
        <Col sm="12">
          <h4>File Types</h4>
          <hr className="mt-0" />
        </Col>

        <Col lg="6">
          <Field
            name="form.doc_ignore_file_exts"
            component={FileTypePicker}
            label="Exclude certain file types:"
            templateUrl="multiple"
            isMulti={true}
          />
        </Col>
      </Row>

      <Row className="m-0">
        <Col sm="12">
          <h4>Parsers</h4>
          <hr className="mt-0" />
        </Col>

        <Col lg="6">
          <Row className="m-0">
            <Field
              name="form.parsers.ai_ml"
              component={RenderCheckBox}
              type={"checkbox"}
              label="Accrualify ML"
              className="mr-2"
            />

            <Field
              name="form.parsers.ai_template"
              component={RenderCheckBox}
              type={"checkbox"}
              label="Accrualify Template"
              className="mr-2"
            />

            <Field
              name="form.parsers.google"
              component={RenderCheckBox}
              type={"checkbox"}
              label="Google"
              className="mr-2"
            />

            <Field
              name="form.parsers.amazon"
              component={RenderCheckBox}
              type={"checkbox"}
              label="Amazon"
              className="mr-2"
            />

            <Field
              name="form.parsers.chatGPT"
              component={RenderCheckBox}
              type={"checkbox"}
              label="chatGPT"
              className="mr-2"
            />

            <Field
              name="form.parsers.claude"
              component={RenderCheckBox}
              type={"checkbox"}
              label="Claude"
              className="mr-2"
            />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export { IntegrationInvoiceAutomation };
