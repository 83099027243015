import moment from "moment";
import { formattedAmount } from "../../../services/general/helpers";
import { translate } from "../../../services/general/translation";
const vendorInvoicesHeader = [
  {
    field: "id",
    headerName: "ID",
    sortable: true,
    hide: true,
    headerValueGetter: function () {
      return translate("id");
    },
  },
  {
    field: "external_id",
    headerName: "External ID",
    hide: true,
    headerValueGetter: function () {
      return translate("externalId");
    },
  },
  {
    field: "number",
    headerName: "Number",
    sortable: true,
    // headerCheckboxSelection: true,
    // headerCheckboxSelectionFilteredOnly: true,
    // checkboxSelection: true,
    filter: "agTextColumnFilter",
    cellRenderer: "childCellRouting",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,

      suppressAndOrCondition: true,
    },
    cellStyle: {
      color: "#FF5C35",
      fontFamily: "acre-regular",
      fontSize: 14,
      fontWeight: "bold",
    },
    headerValueGetter: function () {
      return translate("number");
    },
  },
  {
    field: "purchase_order.number",
    headerName: "PO Number",
    filter: "agTextColumnFilter",
    cellRenderer: "randomChildCellRouting",
    cellStyle: {
      color: "#FF5C35",
      fontFamily: "acre-regular",
      fontSize: 14,
      fontWeight: "bold",
    },
    cellRendererParams: {
      viewLink: "/ar/purchase_orders/",
    },
    headerValueGetter: function () {
      return translate("poNumber");
    },
  },
  {
    field: "purchaser.name",
    headerName: "Customer",
    cellRenderer: "childCellRouting",
    cellStyle: {
      color: "#FF5C35",
      fontFamily: "acre-regular",
      fontSize: 14,
      fontWeight: "bold",
    },
    headerValueGetter: function () {
      return translate("customer");
    },
  },
  {
    field: "date",
    headerName: "Date",
    filter: "agDateColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        // var dateForm = new Date(cellValue)
        // console.log(dateForm)

        // var dateAsString = cellValue.replace(/-/g, "/"); //changing date format /g for global
        //
        // console.log(dateAsString)
        var dateAsString = moment(cellValue).format("DD/MM/YYYY");
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        console.log(dateParts);
        var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
        console.log(cellDate);
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    },
    headerValueGetter: function () {
      return translate("date");
    },
  },
  {
    field: "due_date",
    headerName: "Due Date",
    hide: true,
    headerValueGetter: function () {
      return translate("due_date");
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    cellRenderer: (params) => formattedAmount(params.data?.amount, params.data?.currency_code),
    width: 120,
    headerValueGetter: function () {
      return translate("amount_only");
    },
  },
  {
    field: "payment_list_str",
    headerName: "Payments",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: ["contains"],
    },
    headerValueGetter: function () {
      return translate("payments");
    },
  },
  {
    field: "payments_sum",
    headerName: "Payments Total",
    cellRenderer: (params) => formattedAmount(params.data?.payments_sum, params.data?.currency_code),
    hide: true,
    headerValueGetter: function () {
      return translate("payment_total");
    },
  },
  {
    field: "balance_outstanding",
    headerName: "Outstanding Balance",
    cellRenderer: (params) => formattedAmount(params.data?.balance_outstanding, params.data?.currency_code),
    hide: true,
    headerValueGetter: function () {
      return translate("outstanding");
    },
  },
  {
    field: "status",
    headerName: "Status",
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      options: [
        {
          label: "ALL",
          value: "",
        },
        {
          label: "NEW",
          value: "NEW",
        },
        {
          label: "PENDING",
          value: "PENDING",
        },
        {
          label: "PAID",
          value: "PAID",
        },
      ],

      suppressAndOrCondition: true,
    },
    floatingFilterComponent: "floatingFilterComponent",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    headerValueGetter: function () {
      return translate("status");
    },
  },
  {
    // field: '',
    field: "Actions",
    cellRenderer: "childMessageRenderer",
    sortable: false,
    resizable: false,
    width: 250,
    maxWidth: 250,
    cellRendererParams: {
      viewLink: "/ar/invoices/",
      //editLink: '/ar/invoices/'
    },
    // pinned: 'right',
    cellRendererSelector: function (params) {
      return;
    },
    headerValueGetter: function () {
      return translate("actions");
    },
  },
];

export default vendorInvoicesHeader;
