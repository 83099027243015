import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import style from "./AttachmentPreviewer.module.css";
import _ from "lodash";
import CustomModal from "../../modals/customModal";
import RestApi from "../../../providers/restApi";
import PreviewComponent from "./previewComponent";

type AttachmentPreviewerPropsType = {
  file: {
    name?: string;
    url?: string;
    asset_expiring_url?: string;
    asset_file_file_name?: string;
    linkLable?: string;
    href?: string;
    number?: string;
    preview?: string;
  };
  isVisible: boolean;
  closePreviewer: () => void;
  contentClassName?: string;
};

const AttachmentPreviewer = ({ closePreviewer, file, isVisible, contentClassName }: AttachmentPreviewerPropsType) => {
  const restApiService = new RestApi();
  const fileName =
    _.isObject(file) && file.url ? file.url : file.asset_expiring_url ? file.asset_expiring_url : file.preview;
  // const checkURL = (url: string) => {
  //   return url.match(/\.(jpeg|jpg|gif|png|pdf)$/) != null;
  // };

  // useEffect(() => {
  //   if (_.isPlainObject(file) && file.name) {
  //     // should wait until file downloads and
  //     // close popUP if it's not pdf or image
  //     if (!checkURL(file.name)) {
  //       const timer = setTimeout(() => {
  //         closePreviewer();
  //       }, 1000);
  //       return () => clearTimeout(timer);
  //     }
  //   }
  // }, []);

  return (
    <div>
      <CustomModal
        show={isVisible}
        onHide={() => closePreviewer()}
        size={"xl"}
        header={"File Attachment Preview"}
        contentClassName={contentClassName}
        body={
          <>
            {/* TODO: fix it remove this link code from should come as react node  */}
            {_.isPlainObject(file) && file.href && (
              <Row className="text-right mb-3">
                <Col md={{ span: 4, offset: 8 }} className="text-right">
                  <span>{file.linkLable}</span>
                  <a href={restApiService.angularBaseURL() + file.href} className={style.link} target="_blank" rel="noreferrer">
                    {file.number}
                  </a>
                </Col>
              </Row>
            )}

            {/* TODO: fix it should not use two different assest url to preview to document should have one prop for url and name of file  */}
            {file.asset_expiring_url && file.asset_file_file_name && (
              <PreviewComponent fileName={file.asset_file_file_name} filePath={file.asset_expiring_url} />
            )}

            {file.url && file.name && <PreviewComponent fileName={file.name} filePath={file.url} />}
            {file.name && file.preview && <PreviewComponent fileName={file.name} filePath={file.preview} />}
          </>
        }
        footer={
          <>
            <Button variant="secondary" onClick={closePreviewer}>
              Close
            </Button>
            <a href={fileName} rel="noreferrer" target="_blank" download={file.preview ? file.name : ""}>
              <Button variant="primary">Download</Button>
            </a>
          </>
        }
      />
    </div>
  );
};

export default AttachmentPreviewer;
