const INITIAL_STATE = {
  w9FormSignUp: "",
  signature_value: "",
  signatureW9Form: { sign_reference: "", signature_value: "" },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_SIGN_W9_FORM":
      return {
        ...state,
        // w9FormSignUp:action.payload,
        signatureW9Form: action.payload,
      };

    default:
      return state;
  }
};
