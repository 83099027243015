import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";

type Props = {};

const AccountPickerLookup = (props: any) => {
  return (
    <div>
      <InputGroup className="mb-3 d-flex">
        <FormControl
          placeholder="Recipient's username"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
        />
        <InputGroup.Append>
          <i className="icon icon-searchIcon"></i>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};

export default AccountPickerLookup;
