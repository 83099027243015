import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Can } from "services/authorization/authorization";
import { useTypedSelector } from "../../../reducers";
import { setCompany } from "../../../reducers/vp/companyProfileReducer";
import companyApis from "../../../services/common/company/companyApis";
import useIsMounted from "../../common/hooks/useIsMounted";
import DetailsCompany from "../company/detailsCompany";
import DocumentPanel from "./documentRequirements/documentPanel";

const DetailsCompanyProfile = () => {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const company = useTypedSelector((state) => state.vpCompanyProfileReducer.company);

  const getCompany = async () => {
    try {
      const result = await companyApis.getCompanyDetail();
      if (isMounted.current) {
        dispatch(setCompany(result));
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <Container fluid>
      <Can I={"read"} a={"Company"}>
        <Row>
          <Col>
            <DetailsCompany company={company} />
          </Col>
        </Row>
      </Can>
      <Can I={"edit"} a={"Company"}>
        <Row className="my-3">
          <Col>
            <DocumentPanel />
          </Col>
        </Row>
      </Can>
    </Container>
  );
};

export default DetailsCompanyProfile;
