import { Col, Form } from "react-bootstrap";
import React, { useState } from "react";
import "../paymentMethods.style.css";
import {
  AccountHolder,
  AccountNumber,
  AccountType,
  BankCode,
  BankName,
  BranchCode,
  BikCode,
  VoCode,
  InnTaxId,
  IBAN,
  AccountHolder3,
  AccountNumber3,
  BusinessNumber3,
  TwIBAN,
  IFSC,
  TransitNumber,
  CardNumber,
  BICCode,
  TwBsbCode3,
  PhoneNumber,
  CPF,
  TwInstNumber,
  ConfirmAccountNumber,
  TwSwiftCode,
  TwBicCode,
} from "../fieldComponents/fieldsComponent";

function selectField(fillteredPayment, fieldName) {
  return fillteredPayment[0].validations.find((item) => item.field === fieldName);
}
export const TwEuro = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
      </Form.Row>
    </span>
  );
};
export const TwAud = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder3 {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwBsbCode3 {...props} field_valid={selectField(props.fillteredPayment, "bsb_code")} />
        <AccountNumber3 {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BusinessNumber3 {...props} field_valid={selectField(props.fillteredPayment, "business_number")} />
      </Form.Row>
    </span>
  );
};
export const TwInr = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <IFSC {...props} field_valid={selectField(props.fillteredPayment, "ifsc_code")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const TwIls = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <IBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
      </Form.Row>
    </span>
  );
};
export const TwDkk = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <IBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
      </Form.Row>
    </span>
  );
};

export const TwBrl = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <BranchCode {...props} field_valid={selectField(props.fillteredPayment, "branchCode")} />
      </Form.Row>
      <Form.Row>
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <AccountType {...props} field_valid={selectField(props.fillteredPayment, "account_type")} />
        <PhoneNumber {...props} field_valid={selectField(props.fillteredPayment, "phoneNumber")} />
      </Form.Row>
      <Form.Row>
        <CPF {...props} field_valid={selectField(props.fillteredPayment, "cpf")} />
      </Form.Row>
    </span>
  );
};
export const TwCad = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwInstNumber {...props} field_valid={selectField(props.fillteredPayment, "institution_number")} />
        <TransitNumber {...props} field_valid={selectField(props.fillteredPayment, "transit_number")} />
      </Form.Row>

      <Form.Row>
        <AccountType {...props} field_valid={selectField(props.fillteredPayment, "account_type")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const TwCny = (props) => {
  return (
    <Form.Row>
      <CardNumber {...props} field_valid={selectField(props.fillteredPayment, "cardNumber")} />
    </Form.Row>
  );
};

export const TwJpy = (props) => {
  return (
    <span>
      <Form.Row>
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <BranchCode {...props} field_valid={selectField(props.fillteredPayment, "branchCode")} />
        <AccountType {...props} field_valid={selectField(props.fillteredPayment, "account_type")} />
      </Form.Row>

      <Form.Row>
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        {props.paymentMethodRed.id ? null : <ConfirmAccountNumber {...props} />}
      </Form.Row>
    </span>
  );
};
export const TwMyr = (props) => {
  return (
    <span>
      <Form.Row>
        <TwSwiftCode {...props} field_valid={selectField(props.fillteredPayment, "swiftCode")} />

        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const TwNzd = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};

export const TwNok = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <TwBicCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
      </Form.Row>
    </span>
  );
};
export const TwPhp = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};

export const TwSgd = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};

export const TwSek = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <TwBicCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
      </Form.Row>
    </span>
  );
};
export const TwChf = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <TwBicCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
      </Form.Row>
    </span>
  );
};
export const TwAed = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
      </Form.Row>
    </span>
  );
};
export const TwGbp = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
      </Form.Row>
    </span>
  );
};
export const TwKes = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const TwZmw = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <BranchCode {...props} field_valid={selectField(props.fillteredPayment, "branchCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const TwNgn = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const TwGhs = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <BranchCode {...props} field_valid={selectField(props.fillteredPayment, "branchCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};

export const TwPkr = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <IBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
      </Form.Row>
    </span>
  );
};

export const TwIdr = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const TwZar = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <PhoneNumber {...props} field_valid={selectField(props.fillteredPayment, "phoneNumber")} />
      </Form.Row>
    </span>
  );
};
export const TwKrw = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const TwLkr = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BranchCode {...props} field_valid={selectField(props.fillteredPayment, "branchCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};

export const TwRub = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <BikCode {...props} field_valid={selectField(props.fillteredPayment, "bik_code")} />
        <VoCode {...props} field_valid={selectField(props.fillteredPayment, "vo_code")} />
        <InnTaxId {...props} field_valid={selectField(props.fillteredPayment, "inn_tax_id")} />
      </Form.Row>
    </span>
  );
};

export const TwHkd = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
      </Form.Row>
    </span>
  );
};

export const TwUsd = (props) => {
  return (
    <>
      {props.payment_type == "international" && (props.currency_code == "USD" || props.defaultCurrency == "USD") && (
        <Form.Group as={Col} controlId="pay_by_swift_code">
          <Form.Check
            type="checkbox"
            label="Pay By Swift Code:"
            checked={props.paymentMethodRed.pay_by_swift_code}
            onChange={(e) => {
              props.changePayBySwiftCode(e.target.checked);
            }}
          />
        </Form.Group>
      )}
      {props.pay_by_swift_code && (
        <span>
          <Form.Row>
            <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
            <TwSwiftCode {...props} field_valid={selectField(props.fillteredPayment, "swiftCode")} />
            <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
          </Form.Row>
        </span>
      )}
    </>
  );
};

export const TwThb = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
