import React from "react";
import { Col, Row } from "react-bootstrap";
import { required } from "../../../../../services/validations/reduxFormValidation";
import PaymentType from "../../fields/paymentType";
import AccountName from "../../../../common/managePaymentMethod/fields/accountName";
import BicCode from "../../../../common/managePaymentMethod/fields/bicCode";
import BankName from "../../../../common/managePaymentMethod/fields/bankName";
import AccountNumber from "../../../../common/managePaymentMethod/fields/accountNumber";
import CurrencyCode from "../../../../common/managePaymentMethod/fields/currencyCode";
import PurposeCode from "../../../../common/managePaymentMethod/fields/purposeCode";
import Address1 from "../../../../common/managePaymentMethod/fields/address1";
import City from "../../../../common/managePaymentMethod/fields/city";
import State from "../../../../common/managePaymentMethod/fields/state";
import ZipCode from "../../../../common/managePaymentMethod/fields/zipCode";
import Status from "../../../../common/managePaymentMethod/fields/status";
import Primary from "../../../../common/managePaymentMethod/fields/primary";
import _ from "lodash";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

const SwiftWire = () => {
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);
  return (
    <>
      <Row>
        <Col md="4">
          <PaymentType label="Payment Type:" required validations={[required]} />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <AccountName label={"Account Holder Name:"} required validations={[required]} />
        </Col>
        <Col md="6">
          <BicCode label="Beneficiary's Bank Swift Code (or BIC):" required validations={[required]} />
        </Col>
        <Col md="6">
          <BankName label={"Bank Name: "} required validations={[required]} />
        </Col>
        <Col md="6">
          <AccountNumber label="Bank Account number (account number or iban):" required validations={[required]} />
        </Col>
        <Col md="6">
          <CurrencyCode
            label="Currency Code:"
            required
            validations={[required]}
            currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
          />
        </Col>

        <Col md="12">
          <PurposeCode
            label={
              <label>
                Optional Remit Instruction for Payment: <br />
                <small>
                  {" "}
                  (short desc or payment code - rent, salary, medical expenses, office expenses, pay bill, etc.
                  Recommends '/' as line separator){" "}
                </small>
              </label>
            }
          />
        </Col>

        <Col md="12">
          <Address1 label={"Bank Address line:"} />
        </Col>
        <Col md="4">
          <City label="City: " />
        </Col>
        <Col md="4">
          <State label="State/Province: " />
        </Col>
        <Col md="4">
          <ZipCode label="Zip Code/Postal Code: " />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Status label="Status:" />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex justify-content-end">
          <Primary label="Default for future invoice payment method" />
        </Col>
      </Row>
    </>
  );
};

export default SwiftWire;
