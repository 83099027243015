import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
//import { fetchTemplates, saveTemplate } from './redux/actions';
//import MetadataTemplateModal from './MetadataTemplateModal';
import { MetadataConfiguration } from "./metadataConfiguration";
import RestApi from "providers/restApi";
import { Button, Col, Container, Row } from "react-bootstrap";
import NavTabs from "../nav";
import Panel from "components/panel/panel";
import TabNavigation from "components/navigation/tabNavigation";
import CustomModal from "components/modals/customModal";
import MetadataTemplateUpsert from "components/metadata/metadataTemplateEdit/metadataTemplateUpsert";
import styles from "./metadataTemplate.module.css";
import MetadataConfigurationNavbar from "./metadataConfigurationNavbar";
import { ColDef } from "ag-grid-community";

export interface MetadataTemplate {
  id?: number | null;
  name?: string;
  status?: string;
  metadata_configuration_template_links?: MetadataConfigurationTemplateLink[];
  modules?: string[];
  created_at?: Date | null;
  updated_at?: Date | null;
}

export interface MetadataConfigurationTemplateLink {
  id?: number;
  metadata_configuration_id?: number;
  metadata_template_id?: number;
}

const MetadataTemplatePage = () => {
  const dispatch = useDispatch();
  //const templates = useSelector(state => state.metadataTemplates);
  const [showModal, setShowModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({});

  const [metadataTemplates, setMetadataTemplates] = useState<MetadataTemplate[]>([]);

  const getTemplates = useCallback(async () => {
    const restApi = new RestApi();
    const apiResult = await restApi.get("metadata_templates");

    const configurations: MetadataTemplate[] = apiResult.data;

    setMetadataTemplates(configurations);
  }, []);

  useEffect(() => {
    getTemplates();
  }, []);

  const handleAddClick = () => {
    setCurrentTemplate({ name: "Default" }); // Clear current template for a new one
    setShowModal(true);
  };

  const handleEditClick = (template: any) => {
    setCurrentTemplate(template);
    setShowModal(true);
  };

  const handleSave = (template: any) => {
    //dispatch(saveTemplate(template));
    setShowModal(false);
    getTemplates();
  };

  const StatusCellRenderer = ({ value }: { value: any }) => (
    <span className={`${styles.statusPill} ${styles[value.toLowerCase()]}`}>
      {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
    </span>
  );

  const columns: ColDef<any>[] = [
    { headerName: "Name", field: "name" },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: StatusCellRenderer,
    },
    {
      headerName: "Modules",
      field: "modules",
      flex: 1,
      resizable: true,
      cellRenderer: (params: { value: any[] }) => <span>{params.value.join(", ")}</span>,
    },
    {
      headerName: "Created At",
      field: "created_at",
      cellRenderer: (params: any) => <span>{new Date(params.value).toLocaleDateString()}</span>,
    },
    { headerName: "Hierarchy", field: "hierarchy_string", resizable: true, flex: 1 },
    {
      headerName: "Actions",
      cellRenderer: (params: any) => (
        <button className="actionButtons icon-edit" onClick={() => handleEditClick(params.data)}></button>
      ),
    },
  ];

  return (
    <Container fluid={true}>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"Form Configuration"} />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Panel>
        <div className={"d-flex"}>
          <div className="flex-grow-1">
            <MetadataConfigurationNavbar />

            <button onClick={handleAddClick} className="btn btn-primary mt-2 mb-3">
              Add Template
            </button>
            <div className="ag-theme-fresh" style={{ height: 500, width: "100%" }}>
              <AgGridReact
                columnDefs={columns}
                rowData={metadataTemplates}
                //rowModelType='serverSide'
                //onGridReady={this.gridReady}
                rowHeight={65}
              />
              {/* {showModal && (
                  <MetadataTemplateModal
                    initialValues={currentTemplate}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                  />
                )} */}
            </div>
          </div>
        </div>
      </Panel>

      <CustomModal
        show={showModal}
        size="lg"
        onHide={() => handleSave(null)}
        header={"Add Coding Template"}
        body={
          currentTemplate && (
            <MetadataTemplateUpsert
              initialValues={currentTemplate}
              metadataTemplate={currentTemplate}
              refreshCallback={handleSave}
            ></MetadataTemplateUpsert>
          )
        }
        footer={
          <Row>
            <Col xs="12">
              {/* <Button className="mr-3" variant="secondary" onClick={handleSave}>
                Cancel
              </Button> */}
              {/* <Button type="submit" form={CALENDAR_EVENT_FORM}>
                {t("components.admin.calendarEvent.createCalendarEvent")}
              </Button> */}
            </Col>
          </Row>
        }
      ></CustomModal>
    </Container>
  );
};

export default MetadataTemplatePage;
