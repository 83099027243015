import { Store } from "react-notifications-component";
import ToastNotification from "../../components/common/toast-notification/toastNotification";

//Export the types so people know what type is valid
const NotificationType = {
  success: "success",
  danger: "danger",
  info: "info",
  default: "default",
  warning: "warning",
};
//creates the actual notification
const CreateNotification = (title, message, type) =>
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    content: (props) => <ToastNotification type={type} {...props} />,
    container: "top-right",
    animationIn: ["animated", "slideInRight", "faster"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 10000,
      onScreen: false,
      showIcon: false,
    },
    // slidingExit: {
    //   duration: 2,
    //   timingFunction: 'ease-out',
    //   delay: 0
    // },
    width: 300,
  });

const notificetionMessage = (endpoint, number, type) => {
  //let title = "";
  let message = "";

  switch (endpoint) {
    case "vendor_invoices":
      //title = "Invoice Submitted"
      message = "Invoice number " + number + " submitted successfully";
      break;

    case "contacts":
      //title = "Added"
      message = "Contact ID " + number + " added";
      break;

    case "api_Data_Issue_Error":
      //title = "Data Issue:"
      message = number;
      break;

    default:
      return;
  }
  CreateNotification(endpoint, message, type);
};

export { notificetionMessage, CreateNotification, NotificationType };

// https://github.com/teodosii/react-notifications-component/
