import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import { debounce } from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import { parseForSelect } from "services/general/helpers";
import { VendorPickerType } from "./vendorPickerTypes";

const SingleVendorPicker = ({
  id,
  required,
  instanceId = "vendor-picker",
  placeholder = "search/select",
  name,
  poNotRequired,
  modelName,
  excludeStatuses,
  callBack,
  modelData,
  label,
}: VendorPickerType.TSingleVendorPickerProp) => {
  const { control, setValue, getValues } = useFormContext();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const fetchVendors = async (inputValue: string) => {
    if (!inputValue) {
      return [];
    }

    const getParams: VendorPickerType.TParamsProps = {
      VENDOR_NAME: inputValue,
      VENDOR_LIMIT: 100,
    };

    getParams.PO_NOT_REQUIRED =
      modelName === "purchase_order" && currentUser?.company?.purchase_order?.show_po_required_vendors ? false : "";

    if (excludeStatuses === "INACTIVE") {
      getParams.status = "EXCLUDE_INACTIVE"; // Excluding INACTIVE and REJECTED
    } else if (excludeStatuses) {
      getParams.exclude_statuses = excludeStatuses; // Excluding all the statuses which are sending to directive
    } else {
      getParams.status = "ACTIVE"; // It will return only active vendors if there is no status mentioned to exclude
    }

    if (currentUser.company?.po_request_allow_vendor_on_subsidiary) {
      let dd = modelData ? `${modelData}.subsidiary_id` : "subsidiary_id";
      let subsidiaryId = getValues(dd);
      getParams.subsidiary_id = subsidiaryId;
    }

    try {
      const result = await restApiService.get("vendors.lk", getParams, null, true, null, true);
      return parseForSelect(result.data);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleVendorChange = (
    onChange: (value: VendorPickerType.TVendorOptions | null) => void,
    selectedOption: VendorPickerType.TVendorOptions,
  ) => {
    onChange(selectedOption);

    const fieldName = modelData ? `${modelData}.vendor_id` : "vendor_id";
    setValue(fieldName, selectedOption.id);

    if (callBack) {
      callBack(selectedOption);
    }
  };

  const loadOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      fetchVendors(inputValue).then(callback);
    }, 300), // 300ms debounce delay
    [],
  );

  return (
    <>
      {label && (
        <Form.Label className="pickerLabel">
          {label}
          <Mandatory required={required} />
          <span style={{ fontSize: "11px" }}>{poNotRequired ? " No PO Required" : " (PO Required)"}</span>
        </Form.Label>
      )}
      <Controller
        name={name}
        control={control}
        rules={required ? { required: { value: true, message: "This field is required" } } : undefined}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <>
            <AsyncSelect
              id={id}
              ref={ref}
              value={value ?? null}
              cacheOptions
              getOptionLabel={(option) => (option.name ? option.name : option.vendor_id + " - " + option.label)}
              getOptionValue={(option) => option.id}
              loadOptions={loadOptions}
              className="pickerSelectedOption"
              onChange={(selectedOption) => handleVendorChange(onChange, selectedOption)}
              onBlur={onBlur}
              defaultOptions
              instanceId={instanceId}
              placeholder={placeholder}
            />
            {error && <PickerErrorBlock error={error.message || ""} />}
          </>
        )}
      />
    </>
  );
};

export default SingleVendorPicker;
