import { GridApi } from "ag-grid-community";
import usePermission from "components/common/hooks/usePermission";
import _ from "lodash";
import moment from "moment";
import { restApiService } from "providers/restApi";
import React, { useRef, useState } from "react";
import { Dropdown, Tooltip } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import expenseService from "services/admin/expenses/expenseSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { CreateNotification, NotificationType } from "services/general/notifications";
import AddToExistingER from "./addToExistingER";
import AddToReportDropdown from "./addToReportDropdown";
import gridService from "services/grid/gridSvc";

type AddToReportPropsType = {
  gridApi?: GridApi;
  isDisabled?: boolean;
};

const AddToReport = ({ gridApi, isDisabled }: AddToReportPropsType) => {
  const currentUser = useTypedSelector((state) => state.user);
  const { hasUserPermission } = usePermission();
  const finishCountRef = useRef(0);
  const hasSendProfileReminderPermission =
    hasUserPermission("submitExpenseReports") || hasUserPermission("addExpenseReports");
  const [addToExisting, setAddToExisting] = useState(false);

  const closeAddToExistingEr = () => {
    if (addToExisting) {
      setAddToExisting(false);
    }
  };

  //Checks if the list of items are ok to create reports on
  const getExpenseItemIDs = (items: ExpensesTypes.List, failedItems: string[]) => {
    if (_.isArray(items) && items.length > 0) {
      let expItems: number[] = [];

      items.forEach((item) => {
        if (!item.expense_report_id && item.status != "DRAFT" && item.id) {
          expItems.push(Number(item.id));
        } else {
          failedItems.push(String(item.number));
        }
      });
      return expItems;
    }
  };
  const generateExpenseReport = (
    expenseGroupItemHash: { [key: string]: ExpensesTypes.List },
    queuedReports: ExpenseReportTypes.AddExpenseReport[],
    failedItems: string[],
  ) => {
    for (const hashKey in expenseGroupItemHash) {
      let expenseItemIds = getExpenseItemIDs(expenseGroupItemHash[hashKey], failedItems);

      if (_.isArray(expenseItemIds) && expenseItemIds.length > 0) {
        let obj: ExpenseReportTypes.AddExpenseReport = {
          item_type: "EXPENSE",
          date: moment(new Date()).format("YYYY-MM-DD"),
          status: "NEW",
          policy_id: expenseGroupItemHash[hashKey][0].policy_id,
          expense_item_ids: expenseItemIds,
        };
        if (expenseGroupItemHash[hashKey][0].subsidiary_id) {
          obj.subsidiary_id = expenseGroupItemHash[hashKey][0].subsidiary_id;
        }
        queuedReports.push(obj);
      }
    }
  };

  const createBulkExpenseReport = () => {
    if (gridApi) {
      const selectedRows: ExpensesTypes.List = gridService.getServerSideSelectedRows(gridApi);
      if (selectedRows.length > 0) {
        if (expenseService.isPolicyViolationExist(selectedRows, currentUser)) {
          closeAddToExistingEr();
          return false;
        }
        let queuedReports: ExpenseReportTypes.AddExpenseReport[] = [];
        let failedItems: string[] = [];
        let hash: { [key: string]: ExpensesTypes.List } = {};
        let promptAddPaymentMethodWarning = false;
        selectedRows.forEach((expenseItem) => {
          let key =
            expenseItem.currency_code +
            "_" +
            expenseItem.employee_id +
            "_" +
            expenseItem.policy_id +
            "_" +
            expenseItem.subsidiary_id +
            "_" +
            !!expenseItem.reimbursable +
            "_" +
            expenseItem.location_id;
          if (!hash[key]) {
            hash[key] = [];
          }
          hash[key].push(expenseItem);
        });

        generateExpenseReport(hash, queuedReports, failedItems);
        if (failedItems.length > 0) {
          CreateNotification(
            "Error while Generating Report",
            "Selected Expense Items " + failedItems.join(",") + " should be NEW and not linked to any Expense Report",
            NotificationType.danger,
          );
          closeAddToExistingEr();
        } else {
          finishCountRef.current = 0;
          let totalQueuedReport = queuedReports.length;
          queuedReports.forEach((report) => {
            synReport(report, totalQueuedReport, promptAddPaymentMethodWarning);
          });
        }
      } else {
        CreateNotification(
          "No Expense Item Selected",
          "Please select at least one expense item.",
          NotificationType.danger,
        );
      }
    }
  };

  const synReport = async (
    report: ExpenseReportTypes.AddExpenseReport,
    totalQueuedReport: number,
    promptAddPaymentMethodWarning: boolean,
  ) => {
    try {
      const response = await ExpenseReportApis.addExpenseReport({ expense_report: report });
      if (response.status_message) {
        setTimeout(() => {
          CreateNotification(response.status_message, response.status, NotificationType.success);
        }, 7500);
      }
      if (response) {
        CreateNotification("Added", "Expense Report Number " + response.number + " added", NotificationType.success);
        if (triggerAddPaymentMethodWarning(promptAddPaymentMethodWarning)) {
          promptAddPaymentMethodWarning = true;
        }
        reportFinishedSyncing(response, totalQueuedReport, promptAddPaymentMethodWarning);
      }
    } catch (err) {
      closeAddToExistingEr();
    }
  };

  //callback for each report when its done syncing
  const reportFinishedSyncing = (
    newExpenseReport: ExpenseReportTypes.Details,
    totalQueuedReport: number,
    promptAddPaymentMethodWarning: boolean,
  ) => {
    finishCountRef.current++;
    if (finishCountRef.current >= totalQueuedReport) {
      if (totalQueuedReport === 1) {
        window.location.href = restApiService.angularBaseURL() + "/expense_report/" + newExpenseReport.id;
      } else {
        if (
          promptAddPaymentMethodWarning &&
          !newExpenseReport?.employee?.has_payment_method &&
          newExpenseReport?.reimbursable
        ) {
          CreateNotification(
            "Payment Method Required",
            "You must enter a payment method before you can submit your reimbursable expense report. Please add a payment method to your user profile.",
            NotificationType.warning,
          );
        }
        // All items have finished syncing, refresh the grid
        if (gridApi) {
          gridApi.refreshServerSide();
          // if it's used by addtoexistingEr bulk action
          closeAddToExistingEr();
        }
      }
    }
  };

  const triggerAddPaymentMethodWarning = (promptAddPaymentMethodWarning: boolean) => {
    return (
      currentUser.module_settings.includes("expenses_active") &&
      currentUser.module_settings.includes("payments_active") &&
      !promptAddPaymentMethodWarning
    );
  };

  return (
    <>
      <AddToReportDropdown isDisabled={isDisabled}>
        {hasSendProfileReminderPermission && (
          <Dropdown.Item className="smallFont" onClick={createBulkExpenseReport}>
            Create Expense Report
          </Dropdown.Item>
        )}

        {hasSendProfileReminderPermission && gridApi && (
          <>
            {addToExisting && (
              <AddToExistingER
                gridApi={gridApi}
                createBulkExpenseReport={createBulkExpenseReport}
                closeCallback={() => setAddToExisting(false)}
              />
            )}
            <Dropdown.Item className="smallFont" onClick={() => setAddToExisting(true)}>
              Add to Existing Expense Report
            </Dropdown.Item>
          </>
        )}
      </AddToReportDropdown>
    </>
  );
};

export default AddToReport;
