import React from "react";
import { default as Select, default as StateManagedSelect } from "react-select";

/**
 * this is wrapper component for react select,
 * functionality is same as react select
 *  Advantages:
 *   1. any customization that project need for react select can be done in wrapper component, and it will reflect every where
 *   2. reduce direct dependency on react select (third party library)
 */

const ReactSelect: StateManagedSelect = (props) => {
  return <Select {...props} />;
};

export default ReactSelect;
