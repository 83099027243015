import { ColumnApi, GridApi, IServerSideSelectionState } from "ag-grid-community";

export enum FilterType {
  AG_TEXT_COLUMN_FILTER = "agTextColumnFilter",
  AG_NUMBER_COLUMN_FILTER = "agNumberColumnFilter",
  AG_DATE_COLUMN_FILTER = "agDateColumnFilter",
  AG_SET_COLUMN_FILTER = "agSetColumnFilter",
  AG_MULTI_COLUMN_FILTER = "agMultiColumnFilter",
}

class GridService {
  removeRowFromGrid = (gridApi: GridApi, rowId: string) => {
    // need to tell ag-Grid to refresh the view
    gridApi.applyServerSideTransaction({
      remove: [{ id: rowId }], // Specify the row to remove by ID
    });
    gridApi.refreshServerSide(); // to refresh the grid's view
  };

  setColsVisiable = ({
    gridColumnApi,
    gridApi,
    columnNames,
  }: {
    gridColumnApi?: ColumnApi;
    gridApi?: GridApi;
    columnNames: string[];
  }) => {
    // Get the current column state
    const columnState = gridApi?.getColumnState();
    // Find the column you want to make visible
    columnNames.forEach((columnName) => {
      const columnToMakeVisible = columnState?.find((column) => column.colId === columnName);
      if (columnToMakeVisible) {
        columnToMakeVisible.hide = false;
      }
    });

    // Set the column to visible
    if (columnState) {
      // Update the column state
      gridApi?.applyColumnState({ state: columnState });

      // Redraw the grid
      gridApi?.redrawRows();
    }
  };

  /**
    Text filter opitons
    Contains	contains	Yes
    Does not contain	notContains	Yes
    Equals	equals	Yes
    Does not equal	notEqual	Yes
    Begins with	startsWith	Yes
    Ends with	endsWith	Yes
    Blank	blank	Yes
    Not blank	notBlank	Yes
  */
  getContainTextFilterModal = (value: string) => {
    return {
      filterType: "text",
      type: "contains",
      filter: value,
    };
  };

  getNotContainsTextFilterModel = (value: string) => {
    return {
      filterType: "text",
      type: "notContains",
      filter: value,
    };
  };

  getEqualsTextFilterModel = (value: string) => {
    return {
      filterType: "text",
      type: "equals",
      filter: value,
    };
  };

  getNotEqualTextFilterModel = (value: string) => {
    return {
      filterType: "text",
      type: "notEqual",
      filter: value,
    };
  };

  getStartsWithTextFilterModel = (value: string) => {
    return {
      filterType: "text",
      type: "startsWith",
      filter: value,
    };
  };

  getEndsWithTextFilterModel = (value: string) => {
    return {
      filterType: "text",
      type: "endsWith",
      filter: value,
    };
  };

  getBlankTextFilterModel = () => {
    return {
      filterType: "text",
      type: "blank",
      filter: null,
    };
  };

  getNotBlankTextFilterModel = () => {
    return {
      filterType: "text",
      type: "notBlank",
      filter: null,
    };
  };

  getEqualsTextFilterModal = (value: string) => {
    return {
      filterType: "text",
      type: "equals",
      filter: value,
    };
  };

  /*
    Number filter options
    Equals	equals	Yes
    Does not equal	notEqual	Yes
    Greater than	greaterThan	Yes
    Greater than or equal to	greaterThanOrEqual	Yes
    Less than	lessThan	Yes
    Less than or equal to	lessThanOrEqual	Yes
    Between	inRange	Yes
    Blank	blank	Yes
    Not blank	notBlank	Yes
  */

  getEqualsNumberFilterModel = (value: number) => {
    return {
      filterType: "number",
      type: "equals",
      filter: value,
    };
  };

  getNotEqualNumberFilterModel = (value: number) => {
    return {
      filterType: "number",
      type: "notEqual",
      filter: value,
    };
  };

  getGreaterThanNumberFilterModel = (value: number) => {
    return {
      filterType: "number",
      type: "greaterThan",
      filter: value,
    };
  };

  getGreaterThanOrEqualNumberFilterModel = (value: number) => {
    return {
      filterType: "number",
      type: "greaterThanOrEqual",
      filter: value,
    };
  };

  getLessThanNumberFilterModel = (value: number) => {
    return {
      filterType: "number",
      type: "lessThan",
      filter: value,
    };
  };

  getLessThanOrEqualNumberFilterModel = (value: number) => {
    return {
      filterType: "number",
      type: "lessThanOrEqual",
      filter: value,
    };
  };

  getInRangeNumberFilterModel = (fromValue: number, toValue: number) => {
    return {
      filterType: "number",
      type: "inRange",
      filter: fromValue,
      filterTo: toValue,
    };
  };
  getInRangeStringFilterModel = (fromValue: string, toValue: string) => {
    //This function only works fir fromValue and toValue is in "YYYY-MM-DD HH:mm:ss" format
    return {
      filterType: "date",
      type: "inRange",
      dateFrom: fromValue,
      dateTo: toValue,
    };
  };

  getBlankNumberFilterModel = () => {
    return {
      filterType: "number",
      type: "blank",
      filter: null,
    };
  };

  getNotBlankNumberFilterModel = () => {
    return {
      filterType: "number",
      type: "notBlank",
      filter: null,
    };
  };

  invoiceAll = [
    {
      value: 1,
      label: "ALL",
    },
    {
      value: 2,
      label: "NEW",
      cellValues: ["NEW"],
    },
    {
      value: 3,
      label: "PENDING APPROVAL",
      cellValues: ["PENDING"],
    },
    {
      value: 4,
      label: "APPROVED",
      cellValues: ["APPROVED"],
    },
    {
      value: 5,
      label: "OPEN",
      cellValues: ["OPEN", "OPEN (PENDING)", "OPEN (SCHEDULED)", "OPEN (APPROVED)"],
    },
    {
      value: 6,
      label: "PAID",
      cellValues: ["PAID", "PAID (PROCESSING)"],
    },
    {
      value: 7,
      label: "CLOSED",
      cellValues: ["CLOSED"],
    },
    {
      value: 8,
      label: "REJECTED",
      cellValues: ["REJECTED"],
    },
    {
      value: 9,
      label: "PAYABLE",
      cellValues: ["OPEN", "APPROVED", "OPEN (PENDING)", "OPEN (SCHEDULED)", "OPEN (APPROVED)"],
    },
    {
      value: 10,
      label: "ACTIVE",
      cellValues: ["OPEN", "APPROVED", "OPEN (PENDING)", "OPEN (SCHEDULED)", "OPEN (APPROVED)"],
    },
    {
      value: 11,
      label: "DRAFT",
      cellValues: ["DRAFT"],
    },
    {
      value: 12,
      label: "UNPAID",
      cellValues: ["OPEN", "APPROVED", "NEW", "PENDING", "OPEN (PENDING)", "OPEN (SCHEDULED)", "OPEN (APPROVED)"],
    },
  ];

  expenseReportStatusDefinition = [
    { label: "New", value: "Newly created, needs to be reviewed and submitted for approval" },
    { label: "Pending Approval", value: "Submitted for and awaiting approval" },
    { label: "Approved", value: "All approvals completed, awaiting payment and reconciliation" },
    { label: "Rejected", value: "Rejected during approval, no action needed" },
    { label: "Paid", value: "Fully processed, expense reimbursement paid to requestor" },
    { label: "Closed", value: "Fully processed, paid and reconciled" },
  ];

  getServerSideSelectedRows = (gridApi: GridApi) => {
    if (gridApi) {
      const selectionState = gridApi.getServerSideSelectionState() as IServerSideSelectionState | null;
      if (selectionState && selectionState.selectAll) {
        let selectedRows: any[] = [];
        gridApi.forEachNode((rowNode, index) => {
          if (rowNode.isSelected()) {
            selectedRows.push(rowNode.data);
          }
        });
        return selectedRows;
      } else {
        return gridApi.getSelectedRows();
      }
    }
    return [];
  };
}

const gridService = new GridService();
export default gridService;
