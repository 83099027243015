import { SET_MANAGEACCOUNT_FORM, SET_MANAGEACCOUNT_MODAL_OPEN, SET_MODAL_MOUNT } from "../../actions/actionTypes";
export const MANAGEACCOUNT_FORM_NAME = "manageAccountForm";

const initialState = {
  componentMountMode: false,
  manageAccountForm: {
    form: {
      accounts: [{}],
    },
  },
  isModalOpen: false,
};
const manageAccountFormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MANAGEACCOUNT_FORM:
      return { ...state, manageAccountForm: { form: { accounts: payload } } };
    case SET_MANAGEACCOUNT_MODAL_OPEN:
      return { ...state, isModalOpen: payload };
    case SET_MODAL_MOUNT:
      return { ...state, componentMountMode: payload };
    default:
      return state;
  }
};
export default manageAccountFormReducer;
export const setManageAccountForm = (formData) => (dispatch, getState) => {
  dispatch({ type: SET_MANAGEACCOUNT_FORM, payload: formData });
};
export const setAccountsModalOpen = (value) => (dispatch, getState) => {
  dispatch({ type: SET_MANAGEACCOUNT_MODAL_OPEN, payload: value });
};
export const setComponentMountMode = (value) => (dispatch, getState) => {
  dispatch({ type: SET_MODAL_MOUNT, payload: value });
};
export const onHideManageAccountModal = () => (dispatch, getState) => {
  dispatch(setAccountsModalOpen(false));
  dispatch(setManageAccountForm(initialState.manageAccountForm.form.accounts));
  dispatch(setComponentMountMode(false));
};
