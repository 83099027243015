import React from "react";
import { Col, Row } from "react-bootstrap";
import { required } from "../../../../../services/validations/reduxFormValidation";
import PaymentType from "../../fields/paymentType";
import AccountType from "../../../../common/managePaymentMethod/fields/accountType";
import Address1 from "../../../../common/managePaymentMethod/fields/address1";
import City from "../../../../common/managePaymentMethod/fields/city";
import State from "../../../../common/managePaymentMethod/fields/state";
import ZipCode from "../../../../common/managePaymentMethod/fields/zipCode";
import Status from "../../../../common/managePaymentMethod/fields/status";
import Primary from "../../../../common/managePaymentMethod/fields/primary";

const ExternalPayment = () => {
  return (
    <>
      <Row>
        <Col md="4">
          <PaymentType label="Payment Type:" required validations={[required]} />
        </Col>
        <Col md="4">
          <AccountType
            label="Account Type:"
            required
            validations={[required]}
            options={[
              { label: "-- select --", value: "" },
              { label: "Checking", value: "checking" },
              { label: "Saving", value: "saving" },
              { label: "Wire", value: "wire" },
              { label: "Credit Card", value: "credit_card" },
              { label: "Other", value: "other" },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Address1 label={"Bank Address line:"} />
        </Col>
        <Col md="3">
          <City label="City:" />
        </Col>
        <Col md="3">
          <State label="State/Province:" />
        </Col>
        <Col md="3">
          <ZipCode label="Postal Code:" />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Status label="Status:" />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex justify-content-end">
          <Primary label="Default for future invoice payment method" />
        </Col>
      </Row>
    </>
  );
};

export default ExternalPayment;
