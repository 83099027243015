import { IDType } from "../../../services/common/types/common.type";

const TRANSFERWISE_URL = "https://api.transferwise.com/v1";

export class PaymentMethodValidatorSvc {
  static ValidateAccountNumber = async (urlParams: string, accountNumberType?: string) => {
    const url = `${TRANSFERWISE_URL}/validators/${accountNumberType}${urlParams}`;
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  static ValidateIban = async (value: IDType) => {
    const url = `${TRANSFERWISE_URL}/validators/iban?iban=${value}`;
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  static ValidateInstitutionNumber = async (value: IDType) => {
    const url = `${TRANSFERWISE_URL}/validators/canadian-institution-number?institutionNumber=${value}`;
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  static ValidateTransitionNumber = async (value: IDType, institutionNumber?: IDType) => {
    const url = `${TRANSFERWISE_URL}/validators/canadian-transit-number?transitNumber=${value}&institutionNumber=${institutionNumber}`;
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  static ValidateBsbNumber = async (value: IDType) => {
    const url = `${TRANSFERWISE_URL}/validators/bsb-code?bsbCode=${value}`;
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  static ValidateBicCode = async (bic: IDType, iban?: IDType) => {
    const url = `${TRANSFERWISE_URL}/validators/bic?iban=${iban}&bic=${bic}`;
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  static ValidateBrazilCpf = async (value: string | number) => {
    const url = `${TRANSFERWISE_URL}/validators/brazil-cpf?cpf=${value}`;
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  static ValidateBranchCode = async (code: string | number) => {
    const url = `${TRANSFERWISE_URL}/validators/brazil-branch-code?branchCode=${code}`;
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  static ValidateIfscCode = async (code: string | number) => {
    const url = `${TRANSFERWISE_URL}/validators/ifsc-code?ifscCode=${code}`;
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  static ValidateSortCodeAccountNumber = async (code: string | number) => {
    const url = `${TRANSFERWISE_URL}/validators/sort-code?sortCode=${code}`;
    try {
      const response = await fetch(url, {
        method: "GET",
      });
      return await response.json();
    } catch (error) {
      throw error;
    }
  };
}
