import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { IDType, getListOptionsType } from "services/common/types/common.type";
import { ActivityType } from "./activityType";

class ActivityApis {
  static getActivitiesByObjectName = async (objectName: string, objectId: IDType) => {
    try {
      const response: AxiosResponse<ActivityType.List> = await restApiService.get(
        objectName + "/" + objectId + "/activities",
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static getActivities = async ({ filter, cache }: getListOptionsType = {}) => {
    try {
      const response: AxiosResponse<ActivityType.PaginatedList> = await restApiService.get(
        "activities",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static addActivity = async (payload: ActivityType.AddPayload) => {
    try {
      const response: AxiosResponse<any> = await restApiService.post("activities", null, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export default ActivityApis;
