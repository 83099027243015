import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { getListOptionsType } from "../types/common.type";
import { VendorDocumentLinkTypes } from "./vendorDocumentLinkTypes";

class VendorDocumentLinkApis {
  //list
  static async getVendorDocumentLinks({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<Array<VendorDocumentLinkTypes.ListItem>> = await restApiService.get(
        "vendor_document_links",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //detail
  static async getVendorDocumentLink(id: number) {
    try {
      const response: AxiosResponse<any> = await restApiService.get("vendor_document_links/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // add
  static async createVendorDocumentLink(data: VendorDocumentLinkTypes.Add, endPoint: string) {
    try {
      const response: AxiosResponse<VendorDocumentLinkTypes.AddResponse> = await restApiService.post(
        endPoint,
        null,
        data,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // edit
  static async editVendorDocumentLink(id: number, data: any) {
    try {
      const response: AxiosResponse<any> = await restApiService.patch("vendor_document_links/" + id, null, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // delete
  static async deleteVendorDocumentLink(id: number | string) {
    try {
      // api is returning nothing for success, return true
      const response: AxiosResponse<true> = await restApiService.delete("vendor_document_links/" + id);
      return true;
    } catch (error) {
      throw error;
    }
  }
}

export default VendorDocumentLinkApis;
