import { Field } from "redux-form";
import { Col, Row } from "react-bootstrap";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import { RenderCheckBox, RenderField } from "../../../../../../components/forms/bootstrapFields";

const CorpayCORPAY = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <Col lg="6">
          <label>Provider Settings</label>
        </Col>
      </Row>

      <Row className="m-0">
        <Col lg="4">
          <Field
            name="form.provider_settings.is_multi_bank_account"
            label="Multi Bank Account Locations"
            component={RenderCheckBox}
            id="multi_bank_accounts"
          />
        </Col>

        <Col lg="8">
          <Field
            name="form.provider_settings.corpay_company_name"
            label="Organization Name"
            component={RenderField}
            id="nvoicepay_company_name"
          />
        </Col>
      </Row>
    </Col>
  );
};
export { CorpayCORPAY };
