import React from "react";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import { minLength, required } from "../../../../../services/validations/reduxFormValidation";
import { RenderField } from "../../../../forms/bootstrapFields";

const TwCny = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const min16 = minLength(16);
  return (
    <Col md="6">
      <Field
        name={`${modelName}.cardNumber`}
        component={RenderField}
        label={t("common.paymentMethod.unionPayCardNumber")}
        className={`w-100`}
        required
        maxlength="19"
        validate={[required, min16]}
      />
    </Col>
  );
};

export default TwCny;
