import { AxiosResponse } from "axios";
import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Control, Controller, RegisterOptions, useFormContext } from "react-hook-form";
import Select from "react-select";
import { IDType } from "services/common/types/common.type";
type TCustomNotesPicker = {
  name: string;
  label?: string;
  custom_note_id?: number;
  control?: Control;
  rules?: RegisterOptions;
  required?: boolean;
  callBack?: (selected: TSelected) => void;
};

type TSelected = {
  value: IDType;
  label: string | number;
  id: string;
};

type TCustomNotesObj = {
  id?: number | string;
  value?: number | string;
  label?: string;
  name?: string;
  status?: string;
  external_id?: string;
  company_id?: number;
  description?: string;
  created_at?: string;
  updated_at?: string;
};

const CustomNotesPicker = ({ name, label = "Custom Note", required, ...otherProps }: TCustomNotesPicker) => {
  const [options, setOptions] = useState<TCustomNotesObj[]>([]);
  const isMounted = useIsMounted();
  const [inactivePopulated, setInactivePopulated] = useState(false);
  const { control, getValues, setValue } = useFormContext();
  const customNoteId = getValues("custom_note_id");

  const sortedOptions = useMemo(() => {
    if (!options || options.length === 0) return [];
    const sorted = _.sortBy(options, ["name"]);
    return required ? sorted : [{ name: "-- Select Custom Note --", id: "" }, ...sorted];
  }, [options, required]);

  const initCustomNoteObj = (obj: TCustomNotesObj | null) => {
    if (_.isPlainObject(obj)) {
      setValue(name, obj);
    }
  };

  const isInactive = (status: string) => {
    return status === "INACTIVE";
  };

  const getInactiveCustomNote = async () => {
    try {
      const response: AxiosResponse = await restApiService.get("custom_notes/" + customNoteId, { status: "ACTIVE" });
      if (_.isPlainObject(response?.data)) {
        const inactiveObj = response?.data;
        if (inactiveObj) {
          let obj = {
            ...inactiveObj,
            value: inactiveObj.id,
            label: (
              <span key={inactiveObj.id}>
                {inactiveObj.name}{" "}
                {isInactive(inactiveObj.status) && (
                  <span key={inactiveObj.id} className="inactiveOption">
                    {" "}
                    (INACTIVE)
                  </span>
                )}
              </span>
            ),
          };
          setInactivePopulated(true);
          initCustomNoteObj(obj);
        }
      } else {
        initCustomNoteObj(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mergeInactive = async (options: TCustomNotesObj[]) => {
    // Filter options to find the one with the specified customNoteId
    const matchingOption = options?.find((option) => option.id === customNoteId);

    if (matchingOption) {
      // Initialize custom note object if a matching option is found
      initCustomNoteObj(matchingOption);
    } else if (!inactivePopulated && customNoteId) {
      // If no match is found and the specified custom note is not already inactive, retrieve the inactive custom note
      await getInactiveCustomNote();
    }
  };

  const getCustomNotes = useCallback(async () => {
    try {
      const result: AxiosResponse = await restApiService.get(
        `custom_notes`,
        { status: "ACTIVE" },
        null,
        true,
        null,
        true,
      );
      if (Array.isArray(result.data)) {
        const options = [...result.data];
        mergeInactive(options);
        if (isMounted.current) {
          setOptions(options);
        }
      }
    } catch {}
  }, [isMounted]);

  useEffect(() => {
    getCustomNotes();
  }, [getCustomNotes]);

  return (
    <>
      <label className="pickerLabel">
        {" "}
        {label} <Mandatory required={required} />{" "}
      </label>
      <Controller
        control={control}
        name={name}
        rules={{ required: required ? "This field is required" : false }}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <>
            <Select
              {...field}
              isClearable={!required}
              value={value ?? null}
              getOptionLabel={(option) => option.name || ""}
              getOptionValue={(option) => option.id?.toLocaleString() || ""}
              className="pickerSelectedOption"
              options={sortedOptions}
              required={required}
              onChange={(selected) => {
                onChange(selected);
                setValue("custom_note_id", selected?.id || null);
              }}
            />
            {fieldState.error && <PickerErrorBlock error={fieldState.error.message || ""} />}
          </>
        )}
      />
    </>
  );
};

export default CustomNotesPicker;
