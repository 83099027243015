import { Col, Form } from "react-bootstrap";
import React, { useState } from "react";
import RestApi from "../../../providers/restApi";
import PaymentCountryPicker from "../../pickers/paymentCountryPicker";

export const AccountHolder = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md={props.span ? props.span : "4"}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
        <span className={"sub-title"}>(Full Name or Corp Name)</span>
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        name={"account_name"}
        disabled={props.paymentMethodRed.account_name ? props.disabled : false}
        value={props.paymentMethodRed.account_name}
        onChange={(e) => {
          props.changeAccountName(e.target.value, data.allValidations);
          props.checkValidations(e.target.value, data.allValidations, "account_name");
        }}
        isInvalid={props.paymentValidationsReducers.validateAccountName === false}
        isValid={props.paymentValidationsReducers.validateAccountName === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const AccountHolder3 = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="3">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
        <span className={"sub-title"}>(Full Name or Corp Name)</span>
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        name={"account_name"}
        value={props.paymentMethodRed.account_name}
        onChange={(e) => {
          props.changeAccountName(e.target.value, data.allValidations);
          props.checkValidations(e.target.value, data.allValidations, "account_name");
        }}
        isInvalid={props.paymentValidationsReducers.validateAccountName === false}
        isValid={props.paymentValidationsReducers.validateAccountName === true}
        disabled={props.disabled}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const AccountNumber = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });
  return (
    <Form.Group as={Col} md={props.span ? props.span : "4"}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        name={"account_number"}
        value={props.paymentMethodRed.account_number}
        onChange={(e) => {
          props.changeAccountNumber(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "account_number");
        }}
        isInvalid={props.paymentValidationsReducers.validateAccountNumber === false}
        isValid={props.paymentValidationsReducers.validateAccountNumber === true}
        disabled={props.disabled}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const AccountNumber3 = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="3">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        value={props.paymentMethodRed.account_number}
        onChange={(e) => {
          props.changeAccountNumber(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "account_number");
        }}
        disabled={props.disabled}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const ConfirmAccountNumber = (props) => {
  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        Confirm Account Number:<span className={"color-red"}>*</span>
      </Form.Label>
      <Form.Control
        required
        type="text"
        value={props.paymentMethodRed.paymentConfirmAccountNumber}
        onChange={(e) => {
          props.changeConfirmAccountNumber(e.target.value, props.paymentMethodRed.account_number);
        }}
        isInvalid={props.paymentValidationsReducers.validateConfirmAccountNumber === false}
        isValid={props.paymentValidationsReducers.validateConfirmAccountNumber === true}
        disabled={props.disabled}
      />
      {/* checking explicitly validity, rather than depending on bootstrap form validation */}
      {props.paymentValidationsReducers.validateConfirmAccountNumber === true && (
        <Form.Control.Feedback type="valid">Success</Form.Control.Feedback>
      )}
      <Form.Control.Feedback type="invalid">
        Sorry!!!Confirm Account Number field should match with Account Number field.
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export const AccountType = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        as="select"
        onChange={(e) => {
          props.changeAccountType(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "account_type");
        }}
        value={props.paymentMethodRed.account_type}
        isInvalid={props.paymentValidationsReducers.validateAccountType === false}
        isValid={props.paymentValidationsReducers.validateAccountType === true}
        disabled={props.disabled}
      >
        <option value={""}></option>
        <option value={"savings"}>Savings</option>
        <option value={"checking"}>Checking</option>
      </Form.Control>
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const BankRouting = (props) => {
  const [data] = useState({ required: props?.field_valid?.validate?.required, allValidations: props?.field_valid });
  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data?.allValidations?.label || "Bank Routing"} #:<span className={"color-red"}>*</span>
      </Form.Label>
      <Form.Control
        required
        type="text"
        name="bank_routng"
        value={props.paymentMethodRed.routing}
        onChange={(e) => {
          if (!e.target.value.match(/^[0-9]*$/)) return;
          props.changeRouting(e.target.value);
          ChangeBankRouting(props, e.target.value);
        }}
        isInvalid={props.paymentValidationsReducers.validateRouting === false}
        isValid={props.paymentValidationsReducers.validateRouting === true}
        disabled={props.disabled}
      />
      {props.paymentValidationsReducers.validateRouting === true && (
        <Form.Control.Feedback type="valid">
          {props.paymentValidationsReducers.validationRoutingMsg}
        </Form.Control.Feedback>
      )}
      <Form.Control.Feedback type="invalid">
        {props.paymentValidationsReducers.validationRoutingMsg
          ? props.paymentValidationsReducers.validationRoutingMsg
          : "Sorry! Bank Routing is required"}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
function ChangeBankRouting(props, value) {
  if (value.length < 9) {
    var payload = {
      status: false,
      message: "Min-length: routing number should be at least of 9 number",
    };
    props.changeRoutingMsg(payload);
    props.changeBankName("");
    props.checkValidations("", props.bankNameValidationObj, "bank_name");
    return;
  }
  new RestApi()
    .get("bank_routing_numbers/" + value)
    .then((result) => {
      if (result && result.data) {
        var payload = {
          status: true,
          message: "Found: " + result.data.name,
        };
        props.changeRoutingMsg(payload);
        props.changeBankName(result.data.name);
        props.checkValidations(result.data.name, props.bankNameValidationObj, "bank_name");
      }
    })
    .catch((err) => {
      console.log(err);
      var payload = {
        status: false,
        message: "Warning: routing number not found in our database",
      };
      props.changeRoutingMsg(payload);
      props.changeBankName("");
      props.checkValidations("", props.bankNameValidationObj, "bank_name");
    });
}

export const BankName = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md={props.span ? props.span : "4"}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_name}
        value={props.paymentMethodRed.bank_name}
        onChange={(e) => {
          props.changeBankName(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "bank_name");
        }}
        isInvalid={props.paymentValidationsReducers.validateBankName === false}
        isValid={props.paymentValidationsReducers.validateBankName === true}
        disabled={props.paymentMethodRed.bank_name ? props.disabled : false}
      />
      {props.paymentValidationsReducers.validateBankName === true && (
        <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      )}
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const BankCode = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md={props.span ? props.span : "4"}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        value={props.paymentMethodRed.bankCode}
        onChange={(e) => {
          props.changeBankCode(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "bankCode");
        }}
        isInvalid={props.paymentValidationsReducers.validateBankCode === false}
        isValid={props.paymentValidationsReducers.validateBankCode === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const BikCode = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>{data.allValidations.label}</Form.Label>
      <Form.Control
        minLength={props.field_valid.validate.minlength}
        type="text"
        value={props.paymentMethodRed.bik_code}
        isInvalid={props.paymentValidationsReducers.validateBikCode === false}
        isValid={props.paymentValidationsReducers.validateBikCode === true}
        onChange={(e) => props.changeBikCode(e.target.value)}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const VoCode = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>{data.allValidations.label}</Form.Label>
      <Form.Control
        minLength={props.field_valid.validate.minlength}
        type="text"
        value={props.paymentMethodRed.vo_code}
        isInvalid={props.paymentValidationsReducers.validateBikCode === false}
        isValid={props.paymentValidationsReducers.validateBikCode === true}
        onChange={(e) => props.changeVoCode(e.target.value)}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const InnTaxId = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>{data.allValidations.label}</Form.Label>
      <Form.Control
        minLength={props.field_valid.validate.minlength}
        type="text"
        value={props.paymentMethodRed.inn_tax_id}
        isInvalid={props.paymentValidationsReducers.validateInnTaxCode === false}
        isValid={props.paymentValidationsReducers.validateInnTaxCode === true}
        onChange={(e) => props.changeInnTaxId(e.target.value)}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const TwSwiftCode = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        value={props.paymentMethodRed.swiftCode}
        onChange={(e) => {
          props.changeSwiftCode(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "swiftCode");
        }}
        isInvalid={props.paymentValidationsReducers.validateSwiftCode === false}
        isValid={props.paymentValidationsReducers.validateSwiftCode === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const BranchCode = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md={props.span ? props.span : "4"}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        value={props.paymentMethodRed.branchCode}
        onChange={(e) => {
          props.changeBranchCode(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "branchCode");
        }}
        isInvalid={props.paymentValidationsReducers.validateBranchCode === false}
        isValid={props.paymentValidationsReducers.validateBranchCode === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const SwiftCode = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });
  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        value={props.paymentMethodRed.bic}
        onChange={(e) => {
          props.changeBicCode(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "bic");
        }}
        isInvalid={props.paymentValidationsReducers.validateBicCode === false}
        isValid={props.paymentValidationsReducers.validateBicCode === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const IBAN = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });
  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        value={props.paymentMethodRed.iban}
        // defaultValue={props.account_number}
        onChange={(e) => {
          props.changeIBAN(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "iban");
        }}
        isInvalid={props.paymentValidationsReducers.validateIbanCode === false}
        isValid={props.paymentValidationsReducers.validateIbanCode === true}
      />

      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const IFSC = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.ifsc_code}
        onChange={(e) => {
          props.changeIFSC(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "ifsc_code");
        }}
        isInvalid={props.paymentValidationsReducers.validateIFSC === false}
        isValid={props.paymentValidationsReducers.validateIFSC === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const BusinessNumber3 = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md={props.span ? props.span : "4"}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        value={props.paymentMethodRed.paymentBusinessNumber}
        onChange={(e) => {
          props.changeBusinessNumber(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "business_number");
        }}
        isInvalid={props.paymentValidationsReducers.validateBusinessNumber === false}
        isValid={props.paymentValidationsReducers.validateBusinessNumber === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const BsbCode = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md={props.span ? props.span : "4"}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.bsb_code}
        onChange={(e) => {
          props.changeBSB(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "bsb_code");
        }}
        isInvalid={props.paymentValidationsReducers.validateBSB === false}
        isValid={props.paymentValidationsReducers.validateBSB === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const BsbCode4 = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.bsb_code}
        onChange={(e) => {
          props.changeBSB(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "bsb_code");
        }}
        isInvalid={props.paymentValidationsReducers.validateBSB === false}
        isValid={props.paymentValidationsReducers.validateBSB === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const InstitutionNumber = (props) => {
  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        Institution No:<span className={"color-red"}>*</span>
      </Form.Label>
      <Form.Control
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.institution_number}
        onChange={(event) => props.changeInstitution(event.target.value)}
      />
      <Form.Control.Feedback>This field is required!</Form.Control.Feedback>
    </Form.Group>
  );
};
export const TransitNumber = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.transit_number}
        onChange={(e) => {
          props.changeTransitNumber(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "transit_number");
        }}
        isInvalid={props.paymentValidationsReducers.validateTransit === false}
        isValid={props.paymentValidationsReducers.validateTransit === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const InstitutionNo = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        value={props.paymentMethodRed.institution_number}
        onChange={(e) => {
          props.changeInstitutionNo(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "institution_number");
        }}
        isInvalid={props.paymentValidationsReducers.validateInstitution === false}
        isValid={props.paymentValidationsReducers.validateInstitution === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const CardNumber = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_name}
        value={props.paymentMethodRed.cardNumber}
        onChange={(e) => {
          props.changeCardNumber(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "cardNumber");
        }}
        isInvalid={props.paymentValidationsReducers.validateCardNumber === false}
        isValid={props.paymentValidationsReducers.validateCardNumber === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const BICCode = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });
  return (
    <Form.Group as={Col} md={props.span ? props.span : "4"}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.bic}
        onChange={(event) => props.changeBicCode(event.target.value)}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const CNPJ = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.account_tax_number}
        onChange={(e) => {
          props.changeCNPJ(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "account_tax_number");
        }}
        isInvalid={props.paymentValidationsReducers.validateAccountTaxNumber === false}
        isValid={props.paymentValidationsReducers.validateAccountTaxNumber === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const PAN = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.account_tax_number}
        onChange={(e) => {
          props.changePan(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "account_tax_number");
        }}
        isInvalid={props.paymentValidationsReducers.validateAccountTaxNumber === false}
        isValid={props.paymentValidationsReducers.validateAccountTaxNumber === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const StoreFront = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.storefront_url}
        onChange={(e) => {
          props.changeStoreFrontUrl(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "storefront_url");
        }}
        isInvalid={props.paymentValidationsReducers.validateAccountTaxNumber === false}
        isValid={props.paymentValidationsReducers.validateAccountTaxNumber === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>{" "}
    </Form.Group>
  );
};
export const UnionAccount = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        UnionPay Card Number/Account Number:
        <span className={"color-red"}>*</span>
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.account_number}
        onChange={(e) => {
          props.changeAccountNumber(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "account_number");
        }}
        isInvalid={props.paymentValidationsReducers.validateAccountNumber === false}
        isValid={props.paymentValidationsReducers.validateAccountNumber === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const SortCode = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.sort_code}
        onChange={(e) => {
          props.changeSortCode(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "sort_code");
        }}
        isInvalid={props.paymentValidationsReducers.validateSortCode === false}
        isValid={props.paymentValidationsReducers.validateSortCode === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const AccountNameEnglish = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.account_name_english}
        onChange={(e) => {
          props.changeAccountNameEnglish(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "account_name_english");
        }}
        isInvalid={props.paymentValidationsReducers.validateAccountNameEnglish === false}
        isValid={props.paymentValidationsReducers.validateAccountNameEnglish === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const IdNumber = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.id_number}
        onChange={(e) => {
          props.changeIdNumber(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "id_number");
        }}
        isInvalid={props.paymentValidationsReducers.validateIDNumber === false}
        isValid={props.paymentValidationsReducers.validateIDNumber === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const Suffix = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.suffix}
        onChange={(e) => {
          props.changeSuffix(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "suffix");
        }}
        isInvalid={props.paymentValidationsReducers.validateSuffix === false}
        isValid={props.paymentValidationsReducers.validateSuffix === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const PhoneNumber = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:<span className={"color-red"}>*</span>
      </Form.Label>
      <Form.Control
        required
        type="text"
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.paymentPhone}
        onChange={(e) => {
          props.changePhone(e.target.value, data.allValidations);
          props.checkValidations(e.target.value, data.allValidations, "phoneNumber");
        }}
        isInvalid={props.paymentValidationsReducers.validatePhoneNumber === false}
        isValid={props.paymentValidationsReducers.validatePhoneNumber === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const IncoporationNumber = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.incorporation_number}
        onChange={(e) => {
          props.changeIncorporationNumber(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "incorporation_number");
        }}
        isInvalid={props.paymentValidationsReducers.validateIncorporationNumber === false}
        isValid={props.paymentValidationsReducers.validateIncorporationNumber === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};
export const CPF = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.cpf}
        onChange={(e) => {
          props.changeCpf(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "cpf");
        }}
        isInvalid={props.paymentValidationsReducers.validateCpf === false}
        isValid={props.paymentValidationsReducers.validateCpf === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const TwIBAN = (props) => {
  return (
    <Form.Group as={Col} md={props.span ? props.span : "4"}>
      <Form.Label>
        IBAN:<span className={"color-red"}>*</span>
      </Form.Label>
      <Form.Control
        required
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.iban}
        // onChange={e =>{props.changeIBAN(e.target.value)}}
        onChange={(e) => {
          props.changeIBAN(e.target.value);
          ChangeTwIban(props, e.target.value);
        }}
        isInvalid={props.paymentValidationsReducers.validateTwIbanCode === false}
        isValid={props.paymentValidationsReducers.validateTwIbanCode === true}
      />
      <Form.Control.Feedback type="valid">
        {props.paymentValidationsReducers.validationTwIBANMessage}
      </Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">
        {props.paymentValidationsReducers.validationTwIBANMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
function ChangeTwIban(props, value) {
  let TARGET_URL = "https://api.transferwise.com/v1/validators/iban?iban=";
  fetch(TARGET_URL + value, {
    method: "GET",
  }).then((response) => {
    response.json().then((result) => {
      if (result.validation) {
        props.changeTwIbanValidation({
          status: true,
          message: "Success",
        });
      } else {
        props.changeTwIbanValidation({
          status: false,
          message: result.errors[0].message,
        });
      }
    });
  });
}

export const TwBsbCode3 = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md={props.span ? props.span : "4"}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.bsb_code}
        onChange={(e) => {
          props.changeBSB(e.target.value);
          ChangeTwBsb(props, e.target.value);
        }}
        isInvalid={props.paymentValidationsReducers.validateTwBSBCode === false}
        isValid={props.paymentValidationsReducers.validateTwBSBCode === true}
      />
      <Form.Control.Feedback type="valid">
        {props.paymentValidationsReducers.validationTwBSBMessage}
      </Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">
        {props.paymentValidationsReducers.validationTwBSBMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
function ChangeTwBsb(props, value) {
  let TARGET_URL = "https://api.transferwise.com/v1/validators/bsb-code?bsbCode=";
  fetch(TARGET_URL + value, {
    method: "GET",
  }).then((response) => {
    response.json().then((result) => {
      if (result.validation) {
        props.changeTwBSBValidation({
          status: true,
          message: "Success",
        });
      } else {
        props.changeTwBSBValidation({
          status: false,
          message: result.errors[0].message,
        });
      }
    });
  });
}
export const TwInstNumber = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.institution_number}
        onChange={(e) => {
          props.changeInstitution(e.target.value);
          ChangeTwInstitute(props, e.target.value);
        }}
        isInvalid={props.paymentValidationsReducers.validateInstitution === false}
        isValid={props.paymentValidationsReducers.validateInstitution === true}
      />
      <Form.Control.Feedback type="valid">
        {props.paymentValidationsReducers.validationTwInstMessage}
      </Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">
        {props.paymentValidationsReducers.validationTwInstMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
function ChangeTwInstitute(props, value) {
  let TARGET_URL = "https://api.transferwise.com/v1/validators/canadian-institution-number?institutionNumber=";
  fetch(TARGET_URL + value, {
    method: "GET",
  }).then((response) => {
    response.json().then((result) => {
      if (result.validation) {
        props.changeTwIntituteValidation({
          status: true,
          message: "Success",
        });
      } else {
        props.changeTwIntituteValidation({
          status: false,
          message: result.errors[0].message,
        });
      }
    });
  });
}
export const TwBicCode = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });

  return (
    <Form.Group as={Col} md="4">
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        minLength={props.field_valid.validate.minlength}
        maxLength={props.field_valid.validate.maxlength}
        type="text"
        // defaultValue={props.account_number}
        value={props.paymentMethodRed.bic}
        onChange={(e) => {
          props.changeBicCode(e.target.value);
          ChangeTwBicCode(props, e.target.value);
        }}
        isInvalid={props.paymentValidationsReducers.validateTwBicCode === false}
        isValid={props.paymentValidationsReducers.validateTwBicCode === true}
      />
      <Form.Control.Feedback type="valid">
        {props.paymentValidationsReducers.validationTwBicCodeMessage}
      </Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">
        {props.paymentValidationsReducers.validationTwBicCodeMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
function ChangeTwBicCode(props, value) {
  let TARGET_URL =
    "https://api.transferwise.com/v1/validators/bic?iban=" + props.paymentMethodRed.iban + "&bic=" + value;
  fetch(TARGET_URL + value, {
    method: "GET",
  }).then((response) => {
    response.json().then((result) => {
      if (result.validation) {
        props.changeTwBicCode({
          status: true,
          message: "Success",
        });
      } else {
        if (result.errors && result.errors[1]) {
          props.changeTwBicCode({
            status: false,
            message: result.errors[1].message,
          });
        }
      }
    });
  });
}

export const IntermediaryBankName = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });
  return (
    <Form.Group as={Col}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        value={props.paymentMethodRed.intrmyBic}
        onChange={(e) => {
          props.changeIntrmyBic(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "intrmy_name");
        }}
        isInvalid={props.paymentValidationsReducers.validateIntrmyName === false}
        isValid={props.paymentValidationsReducers.validateIntrmyName === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const IntermediaryCountry = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });
  return (
    <Form.Group as={Col}>
      <PaymentCountryPicker
        countryPickerCallback={(e) => {
          props.changeIntrmyCountry(e.value);
          props.checkValidations(e.value, data.allValidations, "intrmy_country");
        }}
        label={"Intermediary Bank Country:"}
        colSize={"12"}
        selected={{ value: props.paymentMethodRed.intrmyCountry }}
      />

      {props.paymentValidationsReducers.validateIntrmyCountry === false && (
        <div style={{ color: "green" }}>{data.allValidations.errorMessage}</div>
      )}

      {props.paymentValidationsReducers.validateIntrmyCountry === false && (
        <div style={{ color: "red" }}>{data.allValidations.errorMessage}</div>
      )}
    </Form.Group>
  );
};

export const IntermediaryBankAccountNumber = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });
  return (
    <Form.Group as={Col}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        value={props.paymentMethodRed.intrmyName}
        onChange={(e) => {
          props.changeIntrmyName(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "intrmy_number");
        }}
        isInvalid={props.paymentValidationsReducers.validateIntrmyNumber === false}
        isValid={props.paymentValidationsReducers.validateIntrmyNumber === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const IntermediaryBankBIC = (props) => {
  const [data] = useState({ required: props.field_valid.validate.required, allValidations: props.field_valid });
  return (
    <Form.Group as={Col}>
      <Form.Label>
        {data.allValidations.label}:{data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        required={data.required}
        value={props.paymentMethodRed.intrmyNumber}
        onChange={(e) => {
          props.changeIntrmyNumber(e.target.value);
          props.checkValidations(e.target.value, data.allValidations, "intrmy_bic");
        }}
        isInvalid={props.paymentValidationsReducers.validateIntrmyBic === false}
        isValid={props.paymentValidationsReducers.validateIntrmyBic === true}
      />
      <Form.Control.Feedback type="valid">{data.allValidations.validMessage}</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{data.allValidations.errorMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export const OptionalRemitInstruction = (props) => {
  const [data] = useState({
    required: props.field_valid.validate.required,
    allValidations: props.field_valid,
    ...props.field_valid,
  });
  return (
    <Form.Group as={Col}>
      <Form.Label>
        {data.label ? data.label : "Optional Remit Instruction for Payment:"} <br />
        {data.required ? <span className={"color-red"}>*</span> : null}
        <small>
          {data.label2
            ? data.label2
            : `(short desc or payment code - rent, salary, medical expenses, office expenses, pay bill, etc. Recommends '/' as line separator)`}
        </small>
      </Form.Label>
      <Form.Control
        type="text"
        name="purpose_code"
        maxLength="136"
        value={props.paymentMethodRed.purpose_code}
        onChange={(e) => props.changePurposeCode(e.target.value)}
      />
      <Form.Control.Feedback type="valid">Success</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">Error</Form.Control.Feedback>
    </Form.Group>
  );
};

export const DeliveryMethod = (props) => {
  const [data] = useState({
    required: props.field_valid.validate.required,
    allValidations: props.field_valid,
    ...props.field_valid,
  });
  return (
    <Form.Group as={Col}>
      <Form.Label>
        {data.label ? data.label : "Delivery Metho"}
        {data.required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
      <Form.Control
        type="input"
        as={"select"}
        name="delivery_method"
        value={props.paymentMethodRed.delivery_method}
        onChange={(e) => props.changeDeliveryMethod(e.target.value)}
      >
        <option value="">-- select -- </option>
        <option value="same-day">Same Day</option>
      </Form.Control>
      <Form.Control.Feedback type="valid">Success</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">Error</Form.Control.Feedback>
    </Form.Group>
  );
};
