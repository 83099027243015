import React, { useEffect } from "react";

type TUseShowSelectMenuProps = {
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectRef: React.MutableRefObject<HTMLDivElement | null>;
  parentDivId?: string;
  variance?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
};

const useShowSelectMenu = ({ setIsMenuOpen, selectRef, parentDivId, variance }: TUseShowSelectMenuProps) => {
  useEffect(() => {
    if (parentDivId) {
      const parentDiv = document.getElementById(parentDivId);

      const handleScroll = () => {
        if (selectRef.current && parentDiv) {
          const selectRect = selectRef.current.getBoundingClientRect();
          const parentRect = parentDiv.getBoundingClientRect();

          // Check if the select menu is out of the parent div's visible area
          if (
            selectRect.left < parentRect.left + (variance?.left ? Number(variance.left) : 0) ||
            selectRect.right > parentRect.right + (variance?.right ? Number(variance.right) : 0) ||
            selectRect.bottom > parentRect.bottom + (variance?.bottom ? Number(variance.bottom) : 0) ||
            selectRect.top < parentRect.top + (variance?.top ? Number(variance.top) : 0)
          ) {
            setIsMenuOpen(false); // Close the menu if out of view
          }
        }
      };

      if (parentDiv) {
        parentDiv.addEventListener("scroll", handleScroll);
      }

      // Clean up event listener on component unmount
      return () => {
        if (parentDiv) {
          parentDiv.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [parentDivId, selectRef, setIsMenuOpen, variance?.bottom, variance?.left, variance?.right, variance?.top]);
};

export default useShowSelectMenu;
