import React, { ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_ACCOUNT_NUMBER } from "../../../../actions/actionTypes";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { PaymentFieldType } from "../types";

type AccountNumberPropsType = PaymentFieldType;

const AccountNumber = ({ validations, required, label }: AccountNumberPropsType) => {
  const dispatch = useDispatch();

  const changeAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({ type: CHANGE_ACCOUNT_NUMBER, payload: e.target.value });
  };

  return (
    <Field
      name="account_number"
      label={label}
      component={RenderField}
      required={required}
      validate={validations}
      onChange={changeAccountNumber}
    />
  );
};

export default AccountNumber;
