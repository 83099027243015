import React, { useCallback, useEffect, useState } from "react";
import { Col, Card, Row } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import { RootState, useTypedSelector } from "reducers";
import { FieldArray } from "redux-form";

import { WombatSvc } from "wombatifier/services/wombatSvc";
import { WombatFunctionFields } from "wombatifier/components/wombatifier/wombatFunctionFields";

const MENU_SIZE = "650px";

const slideLeftStyle = {
  transitionProperty: "margin-right, opacity",
  transitionDuration: "0.25s",
  marginRight: `-${MENU_SIZE}`,
  opacity: "1",
};

const BASE_MENU_STYLE = {
  flex: 0,
  minWidth: MENU_SIZE,
  maxWidth: MENU_SIZE,
  ...slideLeftStyle,
};

export const WombatFunctionsMenu = ({ onWombatFunctionChange }: { onWombatFunctionChange: () => void }) => {
  const { index, wombat_field } = useTypedSelector(
    (state: RootState) => WombatSvc.getSelectedWombatField(state),
    shallowEqual,
  );
  const [transitionStyle, setTransitionStyle] = useState<{ marginRight: string } | {}>({});
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    WombatSvc.setSelectedIndex(undefined, dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (index && wombat_field) {
      setTransitionStyle({ ...transitionStyle, opacity: 0 });
      setTimeout(() => setTransitionStyle({ marginRight: 0 }), 125);
    } else {
      setTransitionStyle({});
    }
  }, [index]);

  return index && wombat_field ? (
    <Col className="px-0 pl-2 h-100" style={{ ...BASE_MENU_STYLE, ...transitionStyle }}>
      <Card className="mx-0 rounded h-100">
        <Row className="mx-0 px-2 py-2 mb-2 bg-light d-flex justify-content-center align-items-center">
          <span className="font-weight-bold">{wombat_field.value_mapped_from}</span>
          <button
            type="button"
            className="icon icon-close position-absolute"
            style={{ top: 0, right: 0, backgroundPosition: "center" }}
            onClick={handleClose}
          ></button>
        </Row>
        <Row className="mx-0 px-2" style={{ overflowY: "scroll" }}>
          <FieldArray
            name={`form.config.wombat_fields[${index}].value_functions`}
            component={WombatFunctionFields}
            f_index={index}
            onWombatFunctionChange={onWombatFunctionChange}
          />
        </Row>
      </Card>
    </Col>
  ) : null;
};
