import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import { WireValidations } from "../validations/wirePaymentValidations";
import {
  WireAed,
  WireAud,
  WireAmd,
  WireBrl,
  WireCad,
  WireChf,
  WireCny,
  WireDkk,
  WireEuro,
  WireGbp,
  WireInr,
  WireIls,
  WireJpy,
  WireMyr,
  WireNok,
  WireNzd,
  WirePhp,
  WireSek,
  WireSgd,
  WireKes,
  WireZmw,
  WireNgn,
  WireGhs,
  WirePkr,
  WireZar,
  WireIdr,
  WireKrw,
  WireLkr,
  WireUsd,
  WireRub,
  WireVnd,
  WireClp,
  WireHkd,
  WireMxn,
  WirePen,
  WireThb,
  WireUah,
} from "./wireCurrencyFields";

class WireForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Row>
        <Col>{this.getCurrencyForm(this.props.currency_code)}</Col>
      </Row>
    );
  }

  getCurrencyForm(currencyCode) {
    switch (currencyCode) {
      case "EUR":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "AUD":
        return <WireAud {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "AMD":
        return <WireAmd {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "BRL":
        return <WireBrl {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "CAD":
        return <WireCad {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "CNY":
        return <WireCny {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "DKK":
        return <WireDkk {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "INR":
        return <WireInr {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "ILS":
        return <WireIls {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "JPY":
        return <WireJpy {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "MYR":
        return <WireMyr {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "GBP":
        return <WireGbp {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "AED":
        return <WireAed {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "CHF":
        return <WireChf {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "SEK":
        return <WireSek {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "SGD":
        return <WireSgd {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "PHP":
        return <WirePhp {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "NOK":
        return <WireNok {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "NZD":
        return <WireNzd {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "KES":
        return <WireKes {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "ZMW":
        return <WireZmw {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "NGN":
        return <WireNgn {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "GHS":
        return <WireGhs {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "PKR":
        return <WirePkr {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "ZAR":
        return <WireZar {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "IDR":
        return <WireIdr {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "KRW":
        return <WireKrw {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "LKR":
        return <WireLkr {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "ALL":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "TRY":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "XAF":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "BGN":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "RON":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "SAR":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "AZN":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "BYN":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "HRK":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "EGP":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "EEK":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "LVL":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "RSD":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "PLN":
        return <WireEuro {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "RUB":
        return <WireRub {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "VND":
        return <WireVnd {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "CLP":
        return <WireClp {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "HKD":
        return <WireHkd {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "MXN":
        return <WireMxn {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "PEN":
        return <WirePen {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "THB":
        return <WireThb {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "UAH":
        return <WireUah {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      case "USD":
        return <WireUsd {...this.props} fillteredPayment={this.filterFieldWire(currencyCode)} />;
      default:
        return null;
    }
  }

  // Load "EUR" currency form because all these currencies has same fields.
  getCommonCurrencyForm(currencyCode) {
    const found = [
      "TRY",
      "AED",
      "XAF",
      "BGN",
      "RON",
      "ALL",
      "SAR",
      "AZN",
      "BYN",
      "HRK",
      "EGP",
      "EEK",
      "LVL",
      "RSD",
      "PLN",
    ].find((currency) => currency === currencyCode);
    if (found) {
      return "EUR";
    } else {
      return currencyCode;
    }
  }

  filterFieldWire(currencyCode) {
    currencyCode = this.getCommonCurrencyForm(currencyCode);
    return WireValidations.filter(function (item) {
      return item.currency === currencyCode;
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    purchaser_id: state.paymentMethodRed.purchaser_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePurchaser: (payload) => {
      dispatch({ type: actionType.CHANGE_PURCHASER, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WireForm));
