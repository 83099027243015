import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "react-select";
import commonService from "services/common/commonSvc";
import { IDType } from "services/common/types/common.type";
import ReactSelect from "../wrapperReactSelect/reactSelect";
import { AbstractListPickerTypes } from "./abstractListPickerTypes";

// by default should sort options by name can be override from parent if need any complex sorting fn
export const compare = <T extends { name?: string }>(a: T, b: T): number => {
  if (_.isString(a.name) && _.isString(b.name)) {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
  } else {
    return 0;
  }
};

const AbstractListPicker = <T extends { name?: string; id: IDType }>({
  name,
  objectPropertyKey,
  params,
  fetchUrl,
  instanceId,
  valuePropertyName = "id",
  labelPropertyName = "name",
  label,
  labelClassName,
  sortCompareFn = compare,
  required = false,
  disabled = false,
  mergeInactive,
  placeholder = "",
  containerClassName = "",
  getResponseData,
  filterRecords,
  formatOptionLabel,
  callBack,
  menuPosition = "fixed",
  menuPlacement = "bottom",
  emptyOptionLabel = "-- Select --",
  validate,
  onMenuOpen,
  onMenuClose,
  showInactiveAsOption = true,
  cacheOption = true,
}: AbstractListPickerTypes.IAbstractListPickerProps<T>) => {
  const [displayOption, setDispayOption] = useState<T[]>([]);
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const currentId = useGetValue(name);
  const [selected, setSelected] = useState<AbstractListPickerTypes.TPickerValue<T>>(null);
  const [isMergingInactive, setIsMergingInactive] = useState(false);
  const oldOption = useRef<any>(null);

  const getOptionValue = useCallback((option) => option?.[valuePropertyName], [valuePropertyName]);
  const getOptionLabel = useCallback((option) => option?.[labelPropertyName], [labelPropertyName]);

  // this function will act as single source of update for picker value
  const setPickerValue = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<T>) => {
      setSelected(selected);
      setValue(name, selected ? selected.id : null);
      if (objectPropertyKey) setValue(objectPropertyKey, selected);
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack, name, objectPropertyKey, setValue],
  );

  const {
    data: fetchedOptions,
    isFetching: isFetchingOptions,
    isError: isErrorOption,
  } = useQuery<T[]>({
    queryKey: [name, fetchUrl, params, getResponseData, cacheOption],
    queryFn: async ({ signal }) => {
      const response: AxiosResponse<T[]> = await restApiService.get(
        fetchUrl,
        params,
        null,
        true,
        null,
        cacheOption,
        signal,
      );
      const data = getResponseData ? getResponseData(response) : response.data;
      return Array.isArray(data) ? data : [];
    },
  });

  const options = useMemo<T[]>(() => {
    let finalOptions = fetchedOptions || [];

    // if external filtering is required
    if (filterRecords) {
      finalOptions = filterRecords(finalOptions);
    }

    if (!required) {
      const emptyOption = {
        [valuePropertyName]: null,
        [labelPropertyName]: emptyOptionLabel,
      } as any;
      finalOptions = [emptyOption, ...finalOptions];
    }

    if (sortCompareFn) {
      finalOptions = finalOptions.sort(sortCompareFn);
    }
    return finalOptions;
  }, [emptyOptionLabel, fetchedOptions, filterRecords, labelPropertyName, required, sortCompareFn, valuePropertyName]);

  const isSelectedIsSteal = useMemo(() => {
    let selectedIsSteal = false;
    if ((selected && !currentId) || (!selected && currentId)) {
      // if id removed then selected is steal or
      // if selected empty and currentId value is present
      selectedIsSteal = true;
    } else if (selected && currentId && currentId !== selected?.id) {
      // if selected and currentId both are present but id don't matched to selected.id
      selectedIsSteal = true;
      //if option is change we need to run mergeInactive
    } else if (oldOption.current !== options) {
      selectedIsSteal = true;
    }
    return selectedIsSteal;
  }, [currentId, options, selected]);

  const getFormatOptionLabel = useCallback(
    (data, context) => {
      return formatOptionLabel ? (
        formatOptionLabel(data, context)
      ) : (
        <>
          {getOptionLabel(data)}
          {typeof data?.status === "string" && data.status.toLowerCase() === "inactive" && (
            <small className="inactiveOption">({data?.status})</small>
          )}
        </>
      );
    },
    [formatOptionLabel, getOptionLabel],
  );

  const handleSelectChange = useCallback(
    (selected: OnChangeValue<T, false>) => {
      setPickerValue(selected);
    },
    [setPickerValue],
  );

  const setDefaultValue = useCallback(async () => {
    if (isSelectedIsSteal) {
      let optionChanged = false;
      if (options !== oldOption.current) {
        optionChanged = true;
      }
      const selectedFound = options.find((op) => op.id === currentId);
      if (selectedFound) {
        setPickerValue(selectedFound ? selectedFound : null);
      } else if (mergeInactive) {
        setIsMergingInactive(true);
        // if want to merge inactive or not this logic is complex so should be managed at parent component
        const { inactiveOption } = await mergeInactive(currentId, options);
        if (inactiveOption && showInactiveAsOption) {
          options.push(inactiveOption);
          optionChanged = true;
        }
        setPickerValue(inactiveOption);
        setIsMergingInactive(false);
      } else {
        // at last make make the selected value as null
        setPickerValue(null);
      }
      if (optionChanged) {
        setDispayOption(options);
      }
    }
    oldOption.current = options;
  }, [currentId, isSelectedIsSteal, mergeInactive, options, setPickerValue, showInactiveAsOption]);

  useEffect(() => {
    if (!isFetchingOptions) {
      setDefaultValue();
    }
  }, [currentId, isFetchingOptions, options, setDefaultValue]);

  return (
    <Form.Group className={containerClassName}>
      {label && (
        <Form.Label className={"pickerLabel " + labelClassName}>
          {label} <Mandatory required={required} />
        </Form.Label>
      )}
      <Controller
        name={name}
        rules={{ required: required ? t("validations.required") : undefined, validate }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <>
              <ReactSelect
                id={instanceId} // The id to set on the SelectContainer component.
                instanceId={instanceId} // Define an id prefix for the select components
                value={selected}
                isLoading={isFetchingOptions || isMergingInactive}
                options={displayOption}
                isClearable
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={(selected) => handleSelectChange(selected)}
                isDisabled={disabled}
                placeholder={placeholder}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                formatOptionLabel={getFormatOptionLabel}
                menuPosition={menuPosition}
                menuPlacement={menuPlacement}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    ...commonService.getInvalidStyle(Boolean(error?.message)),
                  }),
                }}
              />
              {error?.message && <PickerErrorBlock error={error.message} />}
              {isErrorOption && <PickerErrorBlock error={t("components.common.pickers.loadingOptionsError")} />}
            </>
          );
        }}
      />
    </Form.Group>
  );
};
export default memo(AbstractListPicker) as typeof AbstractListPicker;
