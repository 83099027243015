import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderSelect } from "../../../../../../components/forms/bootstrapFields";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import IntegrationEdi from "../../components/integrationComponents/integrationEdi";
import IntegrationSftp from "../../components/integrationComponents/integrationSftp";
import { application_types } from "../../../../../../lookups/integrationsLookups";

const APPLICATION_TYPE = application_types.find((app_type) => app_type.value === "FILE_INTEGRATION");
const INBOUND_REPORT_SYSTEM_OPTIONS = [{ value: "", label: "Please select an ERP" }];
INBOUND_REPORT_SYSTEM_OPTIONS.push(
  ...APPLICATION_TYPE.system_names.map((name) => ({ value: name, label: name.split("_").join(" ") })),
);

const IntegrationDataExtract = () => {
  return (
    <>
      <Col lg="12 mt-3">
        <h4>Inbound Settings</h4>
        <hr />
      </Col>

      <Col lg="12" className="p-0">
        <Row className="m-0">
          <Col>
            <Field
              name="form.inbound_report_system_names"
              label="Inbound Report ERP System(s)"
              component={RenderSelect}
              options={INBOUND_REPORT_SYSTEM_OPTIONS}
              multiple
            />
          </Col>
        </Row>
      </Col>

      <Col lg="12" className="p-0">
        <Row className="m-0">
          <AuthenticationMethod />
        </Row>
        <Row className="m-0">
          <IntegrationSftp sftpSublabel={"upload daily white list checks to bank"} />
        </Row>
      </Col>
    </>
  );
};

export { IntegrationDataExtract };
