import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Field, change } from "redux-form";
import { international_payment_countries } from "../../../app.svc.Lookup";
import { required } from "../../../forms/inputFields";
import CountryCurrencyCodePicker, {
  CountryCurrencyCodeOptionType,
} from "../../pickers/reduxFormPickers/countryCurrencyCodePicker";
import BankAddressLine from "../bankAddressLine";
import TwAed from "./transferwise/aed";
import TwAud from "./transferwise/aud";
import TwBrl from "./transferwise/brl";
import TwCad from "./transferwise/cad";
import TwChf from "./transferwise/chf";
import TwCny from "./transferwise/cny";
import TwDkk from "./transferwise/dkk";
import TwEur from "./transferwise/eur";
import TwGbp from "./transferwise/gbp";
import TwGhs from "./transferwise/ghs";
import TwHkd from "./transferwise/hkd";
import TwIdr from "./transferwise/idr";
import TwIls from "./transferwise/ils";
import TwInr from "./transferwise/inr";
import TwJpy from "./transferwise/jpy";
import TwKes from "./transferwise/kes";
import TwKrw from "./transferwise/krw";
import TwKyd from "./transferwise/kyd";
import TwLkr from "./transferwise/lkr";
import TwMyr from "./transferwise/myr";
import TwNgn from "./transferwise/ngn";
import TwNok from "./transferwise/nok";
import TwNzd from "./transferwise/nzd";
import TwPhp from "./transferwise/php";
import TwPkr from "./transferwise/pkr";
import TwSek from "./transferwise/sek";
import TwSgd from "./transferwise/sgd";
import TwThb from "./transferwise/thb";
import TwUsd from "./transferwise/usd";
import TwZar from "./transferwise/zar";
import TwZmw from "./transferwise/zmw";

const InternationalTWPaymentMethod = ({ modelName, modelData }: { modelName?: string; modelData?: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currencyCode, setCurrencyCode] = useState<string>();
  const [country, setCountry] = useState<string>();

  const changeCurrencyCode = (selected: CountryCurrencyCodeOptionType) => {
    setCurrencyCode(selected?.currency_code?.toLowerCase());
    const filteredCountry = international_payment_countries.find((value) => {
      return value.code2 === selected.country_code;
    });
    if (filteredCountry) {
      setCountry(filteredCountry.code);
      dispatch(change("VendorForm", `${modelName}.country`, filteredCountry.code));
      dispatch(change("VendorForm", `${modelName}.address_attributes.country`, filteredCountry.code));
      dispatch(change("VendorForm", `${modelName}.currency_code`, filteredCountry.currency_code));
    }
  };

  const componentMapping: any = {
    eur: TwEur,
    aud: TwAud,
    brl: TwBrl,
    usd: TwUsd,
    cad: TwCad,
    kyd: TwKyd,
    cny: TwCny,
    dkk: TwDkk,
    chf: TwChf,
    hkd: TwHkd,
    idr: TwIdr,
    sek: TwSek,
    lkr: TwLkr,
    krw: TwKrw,
    zar: TwZar,
    php: TwPhp,
    pkr: TwPkr,
    ghs: TwGhs,
    ngn: TwNgn,
    nok: TwNok,
    nzd: TwNzd,
    sgd: TwSgd,
    myr: TwMyr,
    kes: TwKes,
    jpy: TwJpy,
    inr: TwInr,
    ils: TwIls,
    thb: TwThb,
    aed: TwAed,
    gbp: TwGbp,
    zmw: TwZmw,
  };

  const renderCurrencyComponent = (currencyCode: string) => {
    const ComponentToRender = componentMapping[currencyCode];
    return (
      ComponentToRender && <ComponentToRender modelName={`${modelName}`} currencyCode={`${modelData.currency_code}`} />
    );
  };

  useEffect(() => {
    if (modelData.currency_code) {
      setCurrencyCode(modelData.currency_code.toLowerCase());
    }
  }, [modelData, modelData.currency_code]);

  return (
    <>
      <Col md={currencyCode && ["eur", "cad", "myr", "jpy", "ils", "aed"].includes(currencyCode) ? 4 : 6}>
        <Field
          name={`${modelName}.address_attributes.currencyObj`}
          component={CountryCurrencyCodePicker}
          returnObject
          FieldClassName="formField"
          placeholder={"Search/Select"}
          required
          onChange={(selected: any) => changeCurrencyCode(selected)}
          validate={[required]}
          label={t("common.paymentMethod.countryAccountResides")}
        />
      </Col>
      {/* Load a component dynamically based on the selected currency. */}
      {currencyCode && renderCurrencyComponent(currencyCode)}
      <BankAddressLine modelName={`${modelName}`} hideCountry={true} defaultCountry={country} isRequired={true} />
    </>
  );
};

export default InternationalTWPaymentMethod;
