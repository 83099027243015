import React from "react";
import { DATA_EXTRACT_OPTIONS } from "services/admin/reports/reportSvc";
import ListReports from "../standardReports/listReports";
import { DataExtractModuleTypes } from "services/admin/reports/reportTypes";

interface ListDataExtractsProps {
  module?: DataExtractModuleTypes;
}

const ListDataExtracts: React.FC<ListDataExtractsProps> = ({ module }) => (
  <ListReports
    dataExtractModule={module}
    classification="DataExtract"
    createLabel="Data Extract"
    filterName="showDataExtractFilters"
    options={DATA_EXTRACT_OPTIONS}
  />
);

export default ListDataExtracts;
