import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { PDFDocument } from "pdf-lib";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { QueueInvoiceDataType } from "./invoiceQueue.type";
import { useQueueManagementContext } from "./queueManagementContext";
import { getFile, handleError } from "./uploadQueueService";

type PdfRenderingContextType = {
  pdfDocumentToRender: PDFDocument | undefined;
  pdfFileToRender: File | undefined;
  loadPdfFile: (pdfUrl: string) => Promise<ArrayBuffer | undefined>;
  isMainDocumentLoaded: boolean;
  setIsMainDocumentLoaded: Dispatch<SetStateAction<boolean>>;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  numPages: number | undefined;
  setNumPages: Dispatch<SetStateAction<number | undefined>>;
  resetPdfContext: () => void;
  invalidateCurrentInvoicePdfCache: () => void;
};

const QUERY_KEY = "loadInvoicePdf";
const PdfRenderingContext = createContext<PdfRenderingContextType | undefined>(undefined);

export const PdfRenderingProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { selectedQueueInvoice } = useQueueManagementContext();
  const [pdfDocumentToRender, setPdfDocumentToRender] = useState<PDFDocument | undefined>(undefined);
  const [pdfFileToRender, setPdfFileToRender] = useState<File | undefined>(undefined);
  const [isMainDocumentLoaded, setIsMainDocumentLoaded] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>();
  const queryClient = useQueryClient();

  const getFilePath = useCallback((invoice: QueueInvoiceDataType | undefined) => {
    if (!invoice) return "";
    try {
      const urlObj = new URL(invoice.url!);
      return `${urlObj.origin}${urlObj.pathname}`;
    } catch (error) {}
    return "";
  }, []);

  const filePath = useMemo(() => getFilePath(selectedQueueInvoice), [getFilePath, selectedQueueInvoice]);

  const invalidateCurrentInvoicePdfCache = useCallback(() => {
    queryClient.removeQueries([QUERY_KEY, selectedQueueInvoice?.invoice?.id]);
  }, [queryClient, selectedQueueInvoice?.invoice?.id]);

  const resetPdfContext = useCallback(() => {
    setPdfDocumentToRender(undefined);
    setPdfFileToRender(undefined);
    setIsMainDocumentLoaded(false);
    setPageNumber(1);
    setNumPages(undefined);
  }, []);

  const loadPdfFile = useCallback(async (pdfUrl: string): Promise<ArrayBuffer | undefined> => {
    if (!pdfUrl) return undefined;
    const response = await axios.get(pdfUrl, { responseType: "arraybuffer" });
    return response.data;
  }, []);

  const { data: pdfBuffer, error } = useQuery(
    [QUERY_KEY, filePath, selectedQueueInvoice?.invoice?.id],
    () => loadPdfFile(selectedQueueInvoice!.url!),
    {
      enabled: !!selectedQueueInvoice?.url,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (error) {
      console.error("Error loading PDF file:", error);
      handleError(error, t);
    }
  }, [error, t]);

  useEffect(() => {
    const processPdfBuffer = async () => {
      if (!pdfBuffer || !selectedQueueInvoice) return;
      try {
        const file = getFile(new Uint8Array(pdfBuffer), selectedQueueInvoice.filename!);
        setPdfFileToRender(file);
        const pdfDoc = await PDFDocument.load(pdfBuffer);
        if (pdfDoc) {
          setPdfDocumentToRender(pdfDoc);
          setIsMainDocumentLoaded(true);
        }
      } catch (e) {
        console.error("Error processing PDF file:", e);
        handleError(e, t);
      }
    };
    processPdfBuffer();
  }, [pdfBuffer, selectedQueueInvoice, t]);

  useEffect(() => {
    if (!selectedQueueInvoice) {
      resetPdfContext();
    }
  }, [resetPdfContext, selectedQueueInvoice]);

  const contextValue = useMemo(
    () => ({
      pdfDocumentToRender,
      pdfFileToRender,
      loadPdfFile,
      isMainDocumentLoaded,
      setIsMainDocumentLoaded,
      pageNumber,
      setPageNumber,
      numPages,
      setNumPages,
      resetPdfContext,
      invalidateCurrentInvoicePdfCache,
    }),
    [
      pdfDocumentToRender,
      pdfFileToRender,
      loadPdfFile,
      isMainDocumentLoaded,
      pageNumber,
      numPages,
      resetPdfContext,
      invalidateCurrentInvoicePdfCache,
    ],
  );

  return <PdfRenderingContext.Provider value={contextValue}>{children}</PdfRenderingContext.Provider>;
};

export const usePdfRenderingContext = (): PdfRenderingContextType => {
  const context = useContext(PdfRenderingContext);
  if (!context) {
    throw new Error("usePdfRenderingContext must be used within a PdfRenderingProvider");
  }
  return context;
};
