import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../components/forms/bootstrapFields";
import restApi from "../../../../providers/restApi";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../services/general/helpers";
import Styles from "../override.module.css";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */
const CardProgramPicker = ({
  label,
  input,
  meta: { touched, error },
  isMulti,
  tooltip,
  required,
  templateUrl,
  filterByActive,
  filterByGhost,
}) => {
  const [options, setOptions] = useState([]);
  const mounted = useRef(false);

  const restApiService = useMemo(() => new restApi(), []);

  const getOptions = useCallback(async () => {
    const getParams = {};
    if (filterByActive) getParams.by_active = true;
    if (filterByGhost) getParams.by_comdata_ghost = true;

    const result = await restApiService.get("card_programs", getParams, null, true, null, true);
    const value = parseForSelect(result.data);
    if (mounted.current) setOptions(value);
  }, [restApiService, filterByActive, filterByGhost]);

  useEffect(() => {
    getOptions();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [getOptions]);

  if (options.length) {
    return (
      <Form.Group className={Styles.select}>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required
          value={isMulti ? findSelectedMultiple(input, options) : findSelectedSingle(input, options)}
          placeholder="search/select"
          onChange={isMulti ? (value) => onChange(input, value) : (value) => onChangeSingle(input, value)}
          onBlur={isMulti ? () => onBlur(input, input.value) : () => onBlurSingle(input, input.value)}
          options={options}
          isMulti={isMulti ? true : false}
          isClearable
          classNamePrefix="select"
        />
        {error && touched && (
          <div
            style={{
              fontSize: "80%",
              color: "red",
              padding: "2px",
            }}
          >
            {" "}
            Card Program Required...{" "}
          </div>
        )}
      </Form.Group>
    );
  } else {
    return null;
  }
};

export default memo(CardProgramPicker);
