import ListSchedules from "components/admin/reports/schedules/listSchedules";
import React from "react";
import { FILE_OUTBOUND_OPTIONS } from "services/admin/reports/reportSvc";

const Schedules = () => (
  <ListSchedules
    options={FILE_OUTBOUND_OPTIONS}
    classification="FileOutbound"
    createLabel="File Outbound"
    scheduleServiceName="FILE_OUTBOUND"
  />
);
export default Schedules;
