import React, { ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_CONFIRM_ACCOUNT_NUMBER } from "../../../../actions/actionTypes";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { PaymentFieldType } from "../types";

type ConfirmAccountPropsType = PaymentFieldType;

const ConfirmAccountNumber = ({ validations, required, label }: ConfirmAccountPropsType) => {
  const dispatch = useDispatch();

  const changeConfirmAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({ type: CHANGE_CONFIRM_ACCOUNT_NUMBER, payload: e.target.value });
  };

  return (
    <Field
      name="paymentConfirmAccountNumber"
      label={label}
      component={RenderField}
      required={required}
      validate={validations}
      onChange={changeConfirmAccountNumber}
    />
  );
};

export default ConfirmAccountNumber;
