import { Field } from "redux-form";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";

const NonUsBankCredential = () => {
  return (
    <>
      <Col lg="6">
        <Field
          id="email"
          label="Email"
          name="form.email"
          placeholder="Email"
          component={RenderField}
          autocomplete="off"
        />
      </Col>

      <Col lg="6">
        <Field
          id="account"
          label="Account/Username"
          name="form.account"
          placeholder="Account"
          component={RenderField}
          autocomplete="off"
        />
      </Col>
      <Col lg="6">
        <Field
          id="password"
          label="Password"
          type="password"
          name="form.password"
          placeholder="Password"
          component={RenderField}
          autocomplete="new-password"
        />
      </Col>
      <Col lg="6">
        <Field id="role" label="Role" name="form.role" placeholder="Role" component={RenderField} />
      </Col>
    </>
  );
};

export default NonUsBankCredential;
