import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CreditSummary from "components/admin/payments/dashboard/creditSummary";
import UnprocessedVirtualCards from "components/admin/payments/dashboard/unprocessedVirtualCards";
import PaymentMethodsByVendor from "components/admin/payments/dashboard/paymentMethodsByVendor";
import EstimatedDates from "components/admin/payments/dashboard/estimatedDebitDates";
import DashboardTabs from "components/admin/dashboard/dashboardTabs";
import usePermission from "components/common/hooks/usePermission";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import { useLocation } from "react-router";
import PaymentsTabs from "components/admin/payments/paymentTabs";

const PaymentsDashboard = () => {
  const { hasUserRole } = usePermission();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const location = useLocation();

  return (
    <Container fluid>
      {location.pathname === "/ap/payments/dashboard" && <PaymentsTabs />}
      {location.pathname === "/dashboard/payments" && <DashboardTabs />}
      <Row>
        {currentUser?.company?.is_nvp_credit_model && (
          <Col md={6}>
            <CreditSummary />
          </Col>
        )}
        <Col md={6}>
          <UnprocessedVirtualCards />
        </Col>
        {currentUser.company.is_nvp_customer && hasUserRole("admin_payment") && (
          <Col md={12}>
            <EstimatedDates />
          </Col>
        )}
        <Col md={6}>
          <PaymentMethodsByVendor />
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentsDashboard;
