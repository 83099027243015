import React, { useState } from "react";
import { RenderField } from "../../../../forms/bootstrapFields";
import { PaymentMethodValidatorSvc } from "../../paymentMethodValidatorSvc";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { required } from "../../../../forms/inputFields";
import { AccountNumberValidator } from "../../accountNumberValidator";
import BicValidator from "../../bicValidator";
import PurposeCode from "./purposeCode";

const WireInr = ({ modelName }: { modelName: string }) => {
  const [validationMessage, setValidationMessage] = useState("");
  const { t } = useTranslation();

  const validateIfscCode = async (value: string | number) => {
    if (value) {
      try {
        const result = await PaymentMethodValidatorSvc.ValidateIfscCode(value);
        if (result.validation) {
          setValidationMessage(result.validation);
        } else {
          setValidationMessage(result.errors[0]?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <AccountNumberValidator
          modelName={`${modelName}`}
          minLen={5}
          maxLength={20}
          accountNumberType="australian-account-number"
          numOnly={true}
        />
      </Col>
      <Col md="6">
        <BicValidator modelName={`${modelName}`} noValidate={true} />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.ifsc_code`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.ifscCode")}
          min={11}
          maxLength={11}
          className={`w-100`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateIfscCode(e.target.value)}
          required
          validate={[required]}
        />
        {validationMessage && (
          <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
        )}
      </Col>
      <Col md="12">
        <PurposeCode isPurposeOfPayment={true} />
      </Col>
    </>
  );
};

export default WireInr;
