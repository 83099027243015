import React, { Component } from "react";
import { Container, Row, Col, Modal, ModalFooter, Button, ModalBody } from "react-bootstrap";
import Panel from "../../../components/panel/panel";
import RestApi from "../../../providers/restApi";
import { BreadcrumbTrail } from "../../../components/navigation/navigationLinks";
import PageTitle from "../../../components/page/pageTitle";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { GetSpacedCardNumber } from "../../../services/virtualCards/virtualCardsService";
import { Link } from "react-router-dom";
import SingleVirtualCard from "../../../components/virtualCards/singleVirtualCard";
import Loader from "../../../components/spinners/loader";
import TextButton from "../../../components/buttons/textButton";

class VirtualCardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      full_card_number: "",
      full_cvc: "",
      showFullVCardNumber: false,
      isOpenCardNumberModal: false,
      cardNumberModalUrl: "",
    };
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.restApiService
      .get("vendor_virtual_cards/" + this.props.match.params.id)
      .then((result) => {
        this.setState({ card: result.data, loaded: true });
      })
      .catch((error) => {
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning);
        this.setState({ loaded: true });
      });
  }

  getCurrencySymbol() {
    return this.state.card && this.state.card.currency ? this.state.card.currency.symbol : "";
  }

  toggleShowCardNumber() {
    const issuer = this.state.card.issuer;
    if (issuer === "Comdata") {
      this.setState({ isOpenCardNumberModal: true }, () => {
        this.restApiService
          .get(`vendor_virtual_cards/${this.props.match.params.id}/jwe`)
          .then(({ data }) => {
            this.setState({ cardNumberModalUrl: `${data.url}?m=true&t=${data.jwe}` });
          })
          .catch((err) =>
            CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning),
          );
      });
    } else {
      this.setState({ showFullVCardNumber: !this.state.showFullVCardNumber }, () => {
        if (this.state.showFullVCardNumber && !this.state.full_card_number) {
          this.restApiService
            .get(`vendor_virtual_cards/${this.props.match.params.id}/show_full_card_number`)
            .then((result) => {
              const { full_card_number } = result.data;
              this.setState({
                full_card_number: GetSpacedCardNumber(full_card_number?.card_number),
                full_cvc: full_card_number?.cvc,
              });
            })
            .catch((error) => {
              CreateNotification(
                this.props.t("error"),
                this.props.t("errors.genericSupport"),
                NotificationType.warning,
              );
            });
        }
      });
    }
  }

  render() {
    let card = this.state.card ? this.state.card : {};
    const { showFullVCardNumber, full_card_number, full_cvc } = this.state;
    const isShowNumber = showFullVCardNumber && full_card_number;
    return (
      <Container fluid={true} className={"pt-4 pb-4"}>
        <BreadcrumbTrail to={"/ar/virtual_cards"} pageName={"Virtual Cards"} title={"vCard Details"} />
        <Loader loaded={this.state.loaded}>
          <Row className="mt-3">
            <Col>
              <PageTitle title={`Card Number ${isShowNumber ? full_card_number : card.card_number_masked}`} />
              <TextButton onClick={() => this.toggleShowCardNumber()}>
                {isShowNumber ? `Hide Number` : `Show Number`}
              </TextButton>
            </Col>
          </Row>
          <hr className="fullBorder" />

          <Row className="mt-3">
            <Col>
              <Row>
                <Col sm className="pr-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{"Issue Date"}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{card.issue_date}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{"Exp Date"}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{card.expiry}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{"CVC"}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{isShowNumber ? full_cvc : "***"}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.customer")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      <Link
                        className="primaryLinkColor"
                        variant="primary"
                        to={"/ar/purchasers/" + (card.company && card.company.id)}
                      >
                        {card.company && card.company.name}
                      </Link>
                    </dd>
                  </dl>
                </Col>
                <Col sm className="pr-sm-0 pl-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{"Issued Amount"}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{this.getCurrencySymbol() + card.amount}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{"Remaining Balance"}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {card?.properties?.nvp_vcard_available_balance != null
                        ? this.getCurrencySymbol() + card.properties.nvp_vcard_available_balance
                        : this.getCurrencySymbol() + card.remaining_amount}
                    </dd>
                  </dl>
                </Col>
                <Col sm className="pl-sm-0">
                  <SingleVirtualCard
                    card={card}
                    card_number_shown={isShowNumber ? full_card_number : card.card_number_masked}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Panel panelContent={{ heading: "Card Settings" }}>
                <Row>
                  <Col sm>
                    <h5>Card Balance</h5>
                    <p>
                      {card.card_balance_type} {this.getCurrencySymbol() + card.amount}
                    </p>
                  </Col>
                  <Col sm>
                    <h5>Uses Authorized</h5>
                    <p>{card.uses_authorized}</p>
                  </Col>
                  <Col sm>
                    <h5>Start Date</h5>
                    <p>{card.start_date}</p>
                  </Col>
                  <Col sm>
                    <h5>End Date</h5>
                    <p>{card.end_date}</p>
                  </Col>
                  {card.uses_authorized !== 1 && (
                    <Col sm>
                      <h5>Minimum Transaction</h5>
                      <p>{card.per_transaction_min ? this.getCurrencySymbol() + card.per_transaction_min : ""}</p>
                    </Col>
                  )}
                </Row>

                {card.uses_authorized !== 1 && (
                  <Row className="mt-4">
                    <Col sm>
                      <h5>Maximum Transaction</h5>
                      <p>{this.getCurrencySymbol() + card.per_transaction_max}</p>
                    </Col>
                  </Row>
                )}
              </Panel>
            </Col>
          </Row>
          <Modal
            dialogClassName="signature-modal"
            show={this.state.isOpenCardNumberModal && this.state.cardNumberModalUrl}
            onHide={() => this.setState({ isOpenCardNumberModal: false })}
          >
            <ModalBody>
              <iframe
                src={this.state.cardNumberModalUrl}
                width="100%"
                height="525px"
                style={{ border: "none" }}
              ></iframe>
            </ModalBody>
            <ModalFooter>
              <Button variant="secondary" onClick={() => this.setState({ isOpenCardNumberModal: false })}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Loader>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    payment: state.payment,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPayment: (payload) => {
      dispatch({ type: actionType.SET_PAYMENT, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VirtualCardDetails));
