import React, { ReactNode, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select, { MultiValue, SingleValue } from "react-select";
import { restApiService } from "../../../../../providers/restApi";
import { useTranslation } from "react-i18next";
import { Mandatory } from "../../../../forms/bootstrapFields";
import _ from "lodash";
import { getValuesFromOptions } from "../../../../../services/general/helpers";

export type ContactLK = {
  id: number;
  name: string;
};

export type ContactPickerProps = {
  label?: string;
  filter: any;
  callback: (values: Array<number> | number) => void;
  required: boolean;
  tooltip: ReactNode;
  placeholder: string;
  isMulti: boolean;
  isClearable: boolean;
  menuPosition?: any;
  inputId?: string;
  error?: string;
  selectedContact: Array<number> | number;
};

type optionType = { value: number; label: string; raw: ContactLK };

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const ContactPicker = ({
  label,
  filter,
  callback,
  required,
  placeholder,
  tooltip,
  isMulti,
  isClearable,
  menuPosition,
  inputId,
  error,
  selectedContact,
}: ContactPickerProps) => {
  const [contactOptions, setContactOptions] = useState<Array<optionType>>([]);
  const [loaded, setLoaded] = useState(false);
  const { t } = useTranslation();

  const getContacts = async () => {
    try {
      const response = await restApiService.get("contacts.lk", filter, null, true, null, true);
      setLoaded(true);
      if (response.data) {
        let options = parseContacts(response.data);
        setContactOptions(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContacts();
  }, []);

  const parseContacts = (response: ContactLK[]): Array<optionType> => {
    const returnOptions = response.map((contact: ContactLK) => {
      return {
        value: contact.id,
        label: contact.name,
        raw: contact,
      };
    });
    return returnOptions;
  };

  const findSelectedSingle = (): optionType | null | undefined => {
    if (!selectedContact) {
      return null;
    }
    return contactOptions.find((option) => {
      if (option.value === selectedContact) {
        return option;
      }
      return null;
    });
  };

  const findSelectedMultiple = (): optionType[] | null => {
    const selectedOptions: optionType[] = [];
    if (!selectedContact || (_.isArray(selectedContact) && !selectedContact.length)) {
      return null;
    }
    if (_.isArray(selectedContact)) {
      selectedContact.forEach((element: number) => {
        const obj = _.find(contactOptions, (option) => option.value === element);
        if (obj) {
          selectedOptions.push(obj);
        }
      });
    }
    return selectedOptions.length ? selectedOptions : null;
  };

  const onChangeSingle = (selectedOption: SingleValue<optionType>) => {
    if (callback && selectedOption) {
      callback(selectedOption.value);
    }
  };

  const onChangeMultiple = (selectedOptions: MultiValue<optionType>) => {
    if (_.isArray(selectedOptions)) {
      const options = getValuesFromOptions(selectedOptions);
      if (callback) {
        callback(options);
      }
    }
  };

  if (!loaded) {
    return null;
  }

  if (isMulti) {
    return (
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? null}
            <Mandatory required={required} />
            {tooltip ?? null}
          </Form.Label>
        )}
        <Select
          required={required}
          value={isMulti ? findSelectedMultiple() : findSelectedSingle()}
          onChange={(selectedOption) => {
            onChangeMultiple(selectedOption);
          }}
          isMulti={true}
          placeholder={placeholder ?? t("forms.selectContact")}
          options={contactOptions}
          isClearable={isClearable}
          menuPosition={menuPosition ? menuPosition : null}
          inputId={inputId ? inputId : "contact-selector"}
        />
        {error && <div className="error">{error}</div>}
      </Form.Group>
    );
  }

  if (!isMulti) {
    return (
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? null}
            <Mandatory required={required} />
            {tooltip ?? null}
          </Form.Label>
        )}
        <Select
          required={required}
          value={findSelectedSingle()}
          onChange={(selectedOption) => {
            onChangeSingle(selectedOption);
          }}
          isMulti={false}
          placeholder={placeholder ?? t("forms.selectContact")}
          options={contactOptions}
          isClearable={isClearable}
          menuPosition={menuPosition ? menuPosition : null}
          inputId={inputId ? inputId : "contact-selector"}
        />
        {error && <div className="error">{error}</div>}
      </Form.Group>
    );
  }
};

export default ContactPicker;
