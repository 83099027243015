import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { SubsidiaryDetailType, SubsidiaryLinkedPaymentMethodsType } from "../../../services/admin/subsidiaries/types";
import styles from "./subsidiaries.module.css";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import SubsidiaryLinksRenderPanel from "./subsidiaryLinksRenderPanel";

type GeneralInfoType = {
  subsidiary?: SubsidiaryDetailType;
};

const GeneralInfo = ({ subsidiary }: GeneralInfoType) => {
  const { t } = useTranslation();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  return (
    <div className="generalInfo">
      <Row>
        <Col md="6">
          {subsidiary && (
            <dl className="dlHorizontal">
              <dt>Name:</dt> <dd>{subsidiary.name}</dd>
              {subsidiary?.country && (
                <>
                  <dt>Country:</dt>
                  <dd>{subsidiary.country}</dd>
                </>
              )}
              <dt>Currency Code:</dt>
              <dd>{subsidiary.currency_code}</dd>
              <dt>Currency Symbol:</dt>
              <dd>{subsidiary.currency?.symbol}</dd>
              <dt>$1.00 USD:</dt>
              <dd>
                {`${subsidiary.currency?.symbol}
                                ${
                                  typeof subsidiary.currency_usd_exchange_rate === "number"
                                    ? subsidiary.currency_usd_exchange_rate.toFixed(3)
                                    : ""
                                }
                                ${subsidiary.currency_code}`}{" "}
                (last market exchange rate)
              </dd>
            </dl>
          )}
        </Col>
        <Col md="6">
          <dl className="dl-horizontal">
            <dt>Status:</dt> <dd>{subsidiary?.status}</dd>
            {subsidiary?.sub_type && (
              <>
                <dt>Sub Type:</dt>
                <dd>{subsidiary.sub_type}</dd>
              </>
            )}
            {subsidiary?.external_id && (
              <>
                <dt>External Id:</dt>
                <dd>{subsidiary.external_id}</dd>
              </>
            )}
            {subsidiary?.location && (
              <>
                <dt>Location:</dt>
                <dd>{subsidiary.location?.name}</dd>
              </>
            )}
            {subsidiary?.po_number && (
              <>
                <dt>Format Settings:</dt>
                <dd>{subsidiary.po_number}</dd>
              </>
            )}
          </dl>
        </Col>
      </Row>
      <Row className="px-mt-10">
        <Col md="12">
          <Table bordered size="sm" className={styles.subsidiaryGeneralInfoTable}>
            <thead>
              <tr>
                <th className={styles.tableHead + " col-1"}>ID</th>
                <th className={styles.tableHead + " col-2"}>Payment Method</th>
                <th className={styles.tableHead + " col-2"}>Payment Type</th>
              </tr>
            </thead>
            <tbody>
              {_.isArray(subsidiary?.payment_methods) &&
                subsidiary?.payment_methods.map((paymentMethod: SubsidiaryLinkedPaymentMethodsType) => (
                  <tr key={paymentMethod.id}>
                    <td className={styles.tableData}>{paymentMethod.id}</td>
                    <td className={styles.tableData}>{`${paymentMethod.name} ${
                      paymentMethod.account_name ? paymentMethod.account_name : ""
                    } `}</td>
                    <td className={styles.tableData}>{`${paymentMethod.payment_type} ${
                      paymentMethod.account_number
                        ? `:${paymentMethod.account_number}`
                        : paymentMethod.iban
                          ? `:${paymentMethod.iban}`
                          : ""
                    } `}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <ErrorBoundary>
        {currentUser?.company?.has_locations && (
          <SubsidiaryLinksRenderPanel
            modelData={subsidiary?.subsidiary_manager_location_links}
            modelName="Location"
            title={t("title.location")}
            isDetailPage={true}
          />
        )}

        {currentUser?.company?.has_departments && (
          <SubsidiaryLinksRenderPanel
            modelData={subsidiary?.subsidiary_manager_department_links}
            modelName="Department"
            title={t("title.department")}
            isDetailPage={true}
          />
        )}

        {currentUser?.company?.has_projects && (
          <SubsidiaryLinksRenderPanel
            modelData={subsidiary?.subsidiary_manager_project_links}
            modelName="Project"
            title={t("title.project")}
            isDetailPage={true}
          />
        )}

        {currentUser?.company?.has_business_units && (
          <SubsidiaryLinksRenderPanel
            modelData={subsidiary?.subsidiary_manager_business_unit_links}
            modelName="Business Unit"
            title={t("title.businessUnit")}
            isDetailPage={true}
          />
        )}

        {Array.isArray(subsidiary?.metadata_configurations) &&
          (subsidiary?.metadata_configurations?.length ?? 0) > 0 && (
            <SubsidiaryLinksRenderPanel
              modelData={subsidiary?.subsidiary_manager_metadata_links}
              modelName="Metadata"
              title="Metadata"
              isDetailPage={true}
            />
          )}
      </ErrorBoundary>
    </div>
  );
};

export default GeneralInfo;
