import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { required } from "../../../../forms/inputFields";
import { useTranslation } from "react-i18next";
import BankCodeValidator from "../../bankCodeValidator";
import { AccountNumberValidator } from "../../accountNumberValidator";
import BicValidator from "../../bicValidator";
import PurposeCode from "./purposeCode";

const WireSgd = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <BankCodeValidator modelName={`${modelName}`} label={t("common.paymentMethod.bankCode")} noValidate={true} />
      </Col>
      <Col md="6">
        <AccountNumberValidator
          modelName={`${modelName}`}
          maxLength={20}
          accountNumberType="brazil-account-number"
          label={t("common.paymentMethod.accountNumberLabel")}
        />
      </Col>
      <Col md="6">
        <BicValidator modelName={`${modelName}`} />
      </Col>

      <Col md="6">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="12">
        <PurposeCode />
      </Col>
    </>
  );
};

export default WireSgd;
