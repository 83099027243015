import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import { Field, FieldArray } from "redux-form";
import { ComparisonField, RenderField, RenderTextArea } from "../../../forms/bootstrapFields";
import {
  ExpenseFieldPropsType,
  ExpenseFieldsPropsType,
} from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrders.types";
import { AppDispatch, useTypedSelector } from "../../../../reducers/index";
import { minValue, required, noWhiteSpaceOnly } from "../../../../services/validations/reduxFormValidation";
import AccountPicker from "../../../pickers/reduxFormPicker/accountPicker";
import CategoryPicker from "../../../pickers/reduxFormPicker/categoryPicker";
import LocationPicker from "../../../pickers/reduxFormPicker/locationPicker";
import SubsidiaryPicker from "../../../pickers/reduxFormPicker/subsidiaryPicker";
import BudgetPicker from "components/admin/purchaseOrders/changeOrders/budgetPicker";
import BusinessUnitPicker from "../../../pickers/reduxFormPicker/businessUnitPicker";
import GrantPicker from "../../pickers/reduxFormPickers/grantPicker";
import ProjectPicker from "../../pickers/reduxFormPickers/projectPicker";
import SponsorshipPicker from "../../pickers/reduxFormPickers/sponsorshipPicker";
import Styles from "../../../../pages/admin/purchaseOrders/changeOrders/purchaseOrders.module.css";
import {
  ChangeOrderExpenseService,
  ChangeOrdersPOFormService,
} from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrdersForm";
import { ExpenseFieldItemIcons } from "./fieldItemIcons";
import { ExpensesFooter } from "./expensesFooter";
import AddChangeOrder from "./addForm";
import EditChangeOrder from "./editForm";
import changeOrderSvc from "../../../../services/admin/changeOrders/changeOrderSvc";
import { CompanyType } from "../../../../services/common/user/userTypes";
import SecondaryCategoryPicker from "components/admin/pickers/reduxFormPickers/secondaryCategoryPicker";
import ForSubsidiaryPicker from "components/admin/pickers/reduxFormPickers/forSubsidiaryPicker";

const { cardContainer } = Styles;

const isGreaterThanZero = minValue(0);
const ExpenseField = ({ index, item, formServiceRef, editIndexRef, comparison }: ExpenseFieldPropsType) => {
  const [editMode, setEditMode] = useState(false);
  const company: CompanyType = useTypedSelector((state) => state.user?.company, shallowEqual);
  const itemState = useTypedSelector((state) => formServiceRef.current.getExpenseState(state, index), shallowEqual);
  const currencyCode = useTypedSelector((state) => formServiceRef.current.getCurrencyCode(state));
  const { fieldService } = formServiceRef.current;
  const [locked, setLocked] = useState<boolean>(false);
  const status = useTypedSelector((state) => formServiceRef.current.getStatus(state));

  const handleEnableEdit = (): void => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  const handleDeleteItem = (): void => {
    if (itemState && itemState.id) {
      fieldService.updateDestroyField(true, index);
    } else {
      fieldService.handleDeleteField(index);
    }
  };

  const handleRecoverItem = (): void => {
    fieldService.updateDestroyField(false, index);
  };

  const handleDuplicateItem = (): void => {
    if (itemState) {
      editIndexRef.current = fieldService.duplicateField(itemState);
    }
  };

  useEffect(() => {
    if (itemState?.billed_amount || itemState?.received_amount) {
      setLocked(true);
    } else if (editIndexRef?.current === index) {
      setEditMode(true);
    }
  }, []);

  return (
    <Card className={`shadow-sm ${cardContainer}`}>
      <Row className="m-0 justify-content-between d-flex">
        {!editMode ? (
          <Col>
            <Row className="m-0 py-3 justify-content-between">
              <Col className="p-0" md={7}>
                <Field
                  inputClassName="border-0"
                  disabled={true}
                  name={`${item}.account_id`}
                  label="Account"
                  labelClassName="font-weight-bold"
                  placeholder="-- Select Account --"
                  component={AccountPicker}
                  validate={locked ? [] : [required]}
                />
              </Col>
              <Col className="p-0 px-2" md={5}>
                <Field
                  inputClassName="border-0"
                  disabled={true}
                  name={`${item}.amount`}
                  label="Amount"
                  labelClassName="font-weight-bold"
                  type="number"
                  component={ComparisonField}
                  min={0}
                  validate={locked ? [] : [required, isGreaterThanZero]}
                  prefix={fieldService.currencySymbol}
                />
              </Col>
            </Row>
          </Col>
        ) : (
          <Col>
            <Row className="m-0 py-3 font-weight-bold">
              <Col className="p-0 d-flex flex-row align-items-center">DEBIT ACCOUNTS</Col>
              {editMode && (
                <ExpenseFieldItemIcons
                  isDestroyed={itemState?._destroy}
                  editMode={editMode}
                  handleRecoverItem={handleRecoverItem}
                  handleEnableEdit={handleEnableEdit}
                  handleDeleteItem={handleDeleteItem}
                  handleDuplicateItem={handleDuplicateItem}
                  readOnly={Boolean(changeOrderSvc.disableEdits(status))}
                />
              )}
            </Row>
            <Row className="m-0">
              <Col className="p-0" md={6}>
                <Field
                  name={`${item}.account_id`}
                  label="Account"
                  labelClassName="font-weight-bold"
                  placeholder="-- Select Account --"
                  component={AccountPicker}
                  validate={locked ? [] : [required]}
                  required={true}
                  originalValue={comparison && itemState ? itemState.account_id_original : undefined}
                  disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                />
              </Col>
              <Col className="p-0 px-2" md={4}>
                <Field
                  name={`${item}.amount`}
                  label="Amount"
                  labelClassName="font-weight-bold"
                  type="number"
                  component={ComparisonField}
                  originalValue={comparison && itemState ? itemState.amount_original : undefined}
                  min={0}
                  validate={locked ? [] : [required, isGreaterThanZero]}
                  prefix={fieldService.currencySymbol}
                  disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                />
              </Col>
            </Row>
            <Row className="m-0 justify-content-between">
              {!company.purchase_order?.expenses?.location?.is_hide && (
                <Col className="p-0" md={3}>
                  <Field
                    name={`${item}.location_id`}
                    label="Location"
                    labelClassName="font-weight-bold"
                    placeholder="-- Select Location --"
                    component={LocationPicker}
                    originalValue={comparison && itemState ? itemState.location_id_original : undefined}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
              )}
              {
                <Col className="p-0 px-2" md={3}>
                  <Field
                    name={`${item}.budget_id`}
                    label="Budget"
                    labelClassName="font-weight-bold"
                    placeholder="-- Select Budget --"
                    component={BudgetPicker}
                    account_id={itemState?.account_id}
                    currency_code={currencyCode}
                    originalValue={comparison && itemState ? itemState.budget_id_original : undefined}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
              }
              {!company.purchase_order?.expenses?.business_unit?.is_hide && (
                <Col className="p-0" md={3}>
                  <Field
                    name={`${item}.business_unit_id`}
                    label="Business Unit"
                    labelClassName="font-weight-bold"
                    placeholder="-- Select Business Unit --"
                    component={BusinessUnitPicker}
                    originalValue={comparison && itemState ? itemState.business_unit_original : undefined}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
              )}
              <Col className="p-0 pl-2" md={3}>
                <Field
                  name={`${item}.category_id`}
                  label="Category"
                  labelClassName="font-weight-bold"
                  placeholder="-- Select Category --"
                  component={CategoryPicker}
                  originalValue={comparison && itemState ? itemState.category_id_original : undefined}
                  disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                />
              </Col>
            </Row>
            <Row className="m-0">
              {company.has_grants && (
                <Col className="p-0" md={3}>
                  <Field
                    name={`${item}.grant_id`}
                    label="Grant"
                    labelClassName="font-weight-bold"
                    placeholder="-- Select Grant --"
                    component={GrantPicker}
                    originalValue={comparison && itemState ? itemState.grant_id_original : undefined}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
              )}
              {company.has_sponsorships && (
                <Col className="p-0 px-2" md={3}>
                  <Field
                    name={`${item}.sponsorship_id`}
                    label="Sponsorship"
                    labelClassName="font-weight-bold"
                    placeholder="Select Sponsorship"
                    component={SponsorshipPicker}
                    originalValue={comparison && itemState ? itemState.sponsorship_id_original : undefined}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
              )}
            </Row>
            <Row className="m-0 justify-content-between">
              {
                <Col className="p-0" md={3}>
                  <Field
                    name={`${item}.for_location_id`}
                    label="For Location"
                    labelClassName="font-weight-bold"
                    placeholder="-- Select Location --"
                    component={LocationPicker}
                    originalValue={comparison && itemState ? itemState.for_location_id_original : undefined}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
              }
              {company?.has_for_subsidiaries && (
                <Col className="p-0 px-2" md={3}>
                  <Field
                    name={`${item}.for_subsidiary_id`}
                    label="For Subsidiary"
                    labelClassName="font-weight-bold"
                    placeholder="-- Select Subsidiary --"
                    component={ForSubsidiaryPicker}
                    originalValue={comparison && itemState ? itemState.for_subsidiary_id_original : undefined}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
              )}
              {company.has_secondary_categories && (
                <Col className="p-0 pl-2" md={3}>
                  <Field
                    name={`${item}.secondary_category_id`}
                    label="Secondary Category"
                    labelClassName="font-weight-bold"
                    placeholder="-- Select Category --"
                    component={SecondaryCategoryPicker}
                    originalValue={comparison && itemState ? itemState.secondary_category_id_original : undefined}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
              )}
              {!company.purchase_order?.expenses?.project?.is_hide && (
                <Col className="p-0 pl-2" md={3}>
                  <Field
                    name={`${item}.project_id`}
                    label="Project"
                    labelClassName="font-weight-bold"
                    placeholder="-- Select Project --"
                    component={ProjectPicker}
                    originalValue={comparison && itemState ? itemState.project_id_original : undefined}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
              )}
            </Row>
            <Row className="m-0 justify-content-between">
              <Col className="p-0">
                <Field
                  name={`${item}.memo`}
                  label="Memo"
                  labelClassName="font-weight-bold"
                  required={true}
                  rows={3}
                  component={RenderTextArea}
                  validate={locked ? [] : [required, noWhiteSpaceOnly]}
                  disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                />
              </Col>
            </Row>
          </Col>
        )}
        {!editMode && (
          <ExpenseFieldItemIcons
            isDestroyed={itemState?._destroy}
            editMode={editMode}
            handleRecoverItem={handleRecoverItem}
            handleEnableEdit={handleEnableEdit}
            handleDeleteItem={handleDeleteItem}
            handleDuplicateItem={handleDuplicateItem}
            readOnly={Boolean(changeOrderSvc.disableEdits(status))}
          />
        )}
      </Row>
    </Card>
  );
};

export const ExpenseFields = ({ fields, mode }: ExpenseFieldsPropsType) => {
  const dispatch: AppDispatch = useDispatch();
  const formServiceRef = useRef<ChangeOrdersPOFormService<ChangeOrderExpenseService>>(
    new ChangeOrdersPOFormService(mode, dispatch, ChangeOrderExpenseService),
  );
  const editIndexRef = useRef<number | undefined>();
  const fieldService = formServiceRef.current.fieldService;
  fieldService.fields = fields;
  return (
    <Container fluid>
      {fields.map((item, index) => {
        return (
          <ExpenseField
            item={item}
            index={index}
            key={fieldService.getFieldId(index)}
            editIndexRef={editIndexRef}
            formServiceRef={formServiceRef}
            comparison={mode === "edit"}
          />
        );
      })}
      <ExpensesFooter formServiceRef={formServiceRef} editIndexRef={editIndexRef} />
    </Container>
  );
};

export const EditExpenseFieldsForm = () => {
  return (
    <EditChangeOrder>
      <FieldArray name="object_changes.invoice_debit_accounts_attributes" component={ExpenseFields} mode="edit" />
    </EditChangeOrder>
  );
};

export const AddExpenseFieldsForm = () => {
  return (
    <AddChangeOrder>
      <FieldArray name="object_changes.invoice_debit_accounts_attributes" component={ExpenseFields} mode="create" />
    </AddChangeOrder>
  );
};
