import React, { Component } from "react";
import RestApi from "../../../providers/restApi";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { mapCompanyInfoToPayload } from "../../../services/vp/company/companyInfoSvc";
import { Button, Col, Container, Row } from "react-bootstrap";
import Panel from "../../panel/panel";
import Loader from "../../spinners/loader";
import CompanyInfoForm from "./companyInfoForm";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class EditCompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      loading: true,
      name: "",
      email: "",
      phone: "",
      country: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      ein: "",
      masked_ssn: "",
      is_1099_eligible: false,
    };
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.restApiService
      .get("company")
      .then((result) => {
        //using only part of the response to not have a nested state object
        let address = result.data.address || {};
        this.setState({
          name: result.data.name,
          email: result.data.email,
          phone: result.data.phone,
          country: address.country,
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          state: address.state,
          zipcode: address.zipcode,
          addressId: address.id,
          ein: result.data.ein,
          masked_ssn: result.data.masked_ssn,
          is_1099_eligible: result.data.is_1099_eligible,
          loading: false,
        });
      })
      .catch((err) => {
        console.error("Error fetching company info: ", err);
        CreateNotification("Error", "Please reach out to support", NotificationType.warning);
        this.setState({
          loading: false,
        });
      });
  }

  formSubmit = (formData) => {
    const body = mapCompanyInfoToPayload(formData);

    this.restApiService
      .patch("company", null, { company: body })
      .then((result) => {
        CreateNotification(this.props.t("success"), this.props.t("companyInfoPage.saved"), NotificationType.success);
        this.props.history.push("/ar/settings");
      })
      .catch((error) => {
        console.error("Error saving company info: ", error);
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.danger);
      });
  };

  //generic onChange event so we dont need one for every field
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  // generic will not work with countryPicker so created these
  onCountryChange = (country) => this.setState({ country: country.value });

  onStateChange = (state) => this.setState({ state: state });

  render() {
    return (
      <Container fluid={true} className={"pt-4 pb-4"}>
        <Panel
          frontIcon={<i className="icon-edit-css editIcon"></i>}
          panelContent={{ heading: this.props.t("edit.company") }}
          cardStyle={{ minHeight: "150px" }}
        >
          <Loader loaded={!this.state.loading} top={"70%"}>
            <Row>
              <Col>
                <CompanyInfoForm formData={this.state} formSubmit={this.formSubmit} />
                <Row>
                  <Col className="text-right">
                    <Link to={"/ar/settings"}>
                      <Button variant="secondary" className="mr-2">
                        {this.props.t("cancel")}
                      </Button>
                    </Link>
                    <Button form="companyInfoForm" type="submit">
                      {this.props.t("submit")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Loader>
        </Panel>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(EditCompanyInfo));
