import CustomModal from "components/modals/customModal";
import React, { useEffect, useState } from "react";
import { IDType } from "services/common/types/common.type";
import FormExpenseReport, { ExpenseReportFormDataPropsType } from "./formExpenseReport";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { GridApi } from "ag-grid-community";

type EditExpenseReportPropsTypes = {
  id: IDType;
  show: boolean;
  setShow: (arg: boolean) => void;
  gridApi: GridApi;
};

const EditExpenseReport = ({ id, show, setShow, gridApi }: EditExpenseReportPropsTypes) => {
  const { t } = useTranslation();
  const [expenseReportDetail, setExpenseReportDetail] = useState<ExpenseReportTypes.Details>();

  const getExpenseReportDetail = async () => {
    try {
      const result = await ExpenseReportApis.getDetail(id);
      setExpenseReportDetail(result);
    } catch (error) {
      console.log(error);
    }
  };

  const submit = async (expenseReportFormData: ExpenseReportFormDataPropsType) => {
    const editPayload: any = {};
    editPayload.policy_id = expenseReportFormData.expenseReport.policy_id;
    editPayload.subsidiary_id = expenseReportFormData.expenseReport.subsidiary_id;
    editPayload.status = expenseReportFormData.expenseReport.status;
    editPayload.location_id = expenseReportFormData.expenseReport.location_id;
    editPayload.number = expenseReportFormData.expenseReport.number;
    editPayload.employee_id = expenseReportFormData.expenseReport.employee_id;
    editPayload.department_id = expenseReportFormData.expenseReport.department_id;
    editPayload.business_unit_id = expenseReportFormData.expenseReport.business_unit_id;
    editPayload.posting_period = expenseReportFormData.expenseReport.posting_period;
    editPayload.posting_period_name = expenseReportFormData.expenseReport.posting_period_name;
    editPayload.date = expenseReportFormData.expenseReport.date;
    editPayload.description = expenseReportFormData.expenseReport.description;
    editPayload.force_push = expenseReportFormData.expenseReport.force_push;
    editPayload.no_push = expenseReportFormData.expenseReport.no_push;
    editPayload.external_id = expenseReportFormData.expenseReport.external_id;
    editPayload.external_id_changed_flag = expenseReportFormData.expenseReport.external_id_changed_flag;
    editPayload.metadata = expenseReportFormData.expenseReport.metadata;
    try {
      const result = await ExpenseReportApis.editExpenseReport(id, { expense_report: editPayload });
      CreateNotification("Updated", `Expense Report Number ${result.number} updated.`, NotificationType.success);
      gridApi.refreshServerSide();
      setShow(false);
    } catch (error) {
      CreateNotification(
        "Error",
        "Could not save Expense Report. Please make sure all fields are correct and there are no policy violations.",
        NotificationType.danger,
      );
    }
  };

  useEffect(() => {
    getExpenseReportDetail();
  }, []);

  return (
    <CustomModal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      header={<h1>Edit Expense Report</h1>}
      body={
        <ErrorBoundary>
          <FormExpenseReport
            initialValues={{ expenseReport: expenseReportDetail }}
            onSubmit={submit}
            onCancel={() => setShow(false)}
          />
        </ErrorBoundary>
      }
      modalBodyClass="px-p-20"
    />
  );
};

export default EditExpenseReport;
