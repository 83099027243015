import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { companyInfoType } from "../../../services/vp/types/company.types";
import StatePicker from "../../pickers/statePicker";
import CountryPicker from "../../vp/pickers/reduxPicker/countryPicker/countryPicker";

export type companyInfoFormPropsType = {
  formData: companyInfoType;
  formSubmit: (formData: companyInfoType) => void;
};

const CompanyInfoForm = ({ formData, formSubmit }: companyInfoFormPropsType) => {
  const { t } = useTranslation();

  const [validated, setValidated] = useState(false);
  const [name, setName] = useState(formData?.name ?? "");
  const [email, setEmail] = useState(formData?.email ?? "");
  const [phone, setPhone] = useState(formData?.phone ?? "");
  const [country, setCountry] = useState(formData?.country ?? "");
  const [addressId, setAddressId] = useState(formData?.addressId ?? "");
  const [address1, setAddress1] = useState(formData?.address1 ?? "");
  const [address2, setAddress2] = useState(formData?.address2 ?? "");
  const [city, setCity] = useState(formData?.city ?? "");
  const [state, setState] = useState(formData?.state ?? "");
  const [zipcode, setZipcode] = useState(formData?.zipcode ?? "");
  const [ein, setEin] = useState(formData?.ein ?? "");
  const [masked_ssn, setMaskedSsn] = useState(formData?.masked_ssn ?? "");
  const [is_1099_eligible, setIs1099Eligible] = useState(formData?.is_1099_eligible ?? false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const valid = e.currentTarget.checkValidity();
    setValidated(true);
    e.preventDefault();
    e.stopPropagation();

    if (!valid) {
      return;
    }
    formSubmit({
      name,
      email,
      phone,
      country,
      addressId,
      address1,
      address2,
      city,
      state,
      zipcode,
      ein,
      masked_ssn,
      is_1099_eligible,
    });
  };

  const updateCompanyInfoInForm = () => {
    setName(formData?.name ?? "");
    setEmail(formData?.email ?? "");
    setPhone(formData?.phone ?? "");
    setCountry(formData?.country ?? "");
    setAddressId(formData?.addressId ?? "");
    setAddress1(formData?.address1 ?? "");
    setAddress2(formData?.address2 ?? "");
    setCity(formData?.city ?? "");
    setState(formData?.state ?? "");
    setZipcode(formData?.zipcode ?? "");
    setEin(formData?.ein ?? "");
    setMaskedSsn(formData?.masked_ssn ?? "");
    setIs1099Eligible(formData?.is_1099_eligible ?? false);
  };

  // if companyInfo changes in parent should also change in child
  useEffect(() => {
    updateCompanyInfoInForm();
  }, [formData]);

  return (
    <Form noValidate id="companyInfoForm" validated={validated} onSubmit={(e) => onSubmit(e)}>
      <Row>
        <Col sm>
          <Form.Group controlId="name">
            <Form.Label>{t("address.companyName")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Company Name"
              name="name"
              value={name ? name : ""}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">{t("validation.name")}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm>
          <Form.Group controlId="arEmail">
            <Form.Label>{t("address.email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter AR Email"
              name="email"
              value={email ? email : ""}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">{t("validation.email")}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm>
          <Form.Group controlId="phone">
            <Form.Label>{t("address.phone")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Phone Number"
              name="phone"
              value={phone ? phone : ""}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <CountryPicker
            required={true}
            country={country ? country : ""}
            selected={country ? { value: country } : { value: "" }}
            colSize={"0"}
            countryPickerCallback={(country: { value: string }) => setCountry(country.value)}
          />
        </Col>
        <Col sm>
          <Form.Group controlId="address1">
            <Form.Label>{t("address.address1")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address 1"
              name="address1"
              value={address1 ? address1 : ""}
              onChange={(e) => setAddress1(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm>
          <Form.Group controlId="address2">
            <Form.Label>{t("address.address2")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address 2"
              name="address2"
              value={address2 ? address2 : ""}
              onChange={(e) => setAddress2(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <Form.Group controlId="city">
            <Form.Label>{t("address.city")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter City"
              name="city"
              value={city ? city : ""}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm>
          <Form.Group controlId="state">
            <StatePicker
              label={t("address.state")}
              required={true}
              countryCode={country}
              value={state ? state : ""}
              callback={(state: string) => setState(state)}
            />
          </Form.Group>
        </Col>
        <Col sm>
          <Form.Group controlId="postalCode">
            <Form.Label>{t("address.zipcode")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Postal Code"
              name="zipcode"
              value={zipcode ? zipcode : ""}
              onChange={(e) => setZipcode(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group controlId="ein">
            <Form.Label>{"EIN/VAT/Tax ID (Must be in format xx-xxxxxxx)"}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter EIN"
              name="ein"
              value={ein ? ein : ""}
              onChange={(e) => setEin(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group controlId="masked_ssn">
            <Form.Label>{"SSN (Must be in format xxx-xx-xxxx)"}</Form.Label>

            <Form.Control
              type="text"
              placeholder="SSN"
              name="masked_ssn"
              value={masked_ssn ? masked_ssn : ""}
              onChange={(e) => setMaskedSsn(e.target.value)}
            />
          </Form.Group>
          <p className="text-danger">
            We do not recommend loading your SSN but rather an EIN. This data will be shared with your customer.
          </p>
        </Col>
        <Col sm={4} className="d-flex align-items-center">
          <Form.Group controlId="1099" className="mb-0">
            <Form.Check
              checked={is_1099_eligible ?? false}
              type="checkbox"
              label={t("details.1099Eligible")}
              onChange={(e) => setIs1099Eligible(e.target.checked)}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default CompanyInfoForm;
