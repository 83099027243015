import { createSlice } from "@reduxjs/toolkit";
import { MetadataTemplate } from "pages/admin/administration/metadata/metadataTemplate";

const initialState: MetadataTemplate = {
  name: "",
  status: "ACTIVE",
  metadata_configuration_template_links: [],
  modules: [],
};

const metadataTemplateSlice = createSlice({
  name: "metadataTemplateForm",
  initialState,
  reducers: {
    setConfiguration(state, action) {
      state = action.payload;
    },
    resetConfiguration(state) {
      state = initialState;
    },
    // You can add more reducers as needed
  },
});

export const { setConfiguration, resetConfiguration } = metadataTemplateSlice.actions;
export default metadataTemplateSlice.reducer;
