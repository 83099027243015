import Activities from "components/admin/activities/activities";
import ActivityLog from "components/admin/activityLog/activityLog";
import useMessagePanel from "components/admin/messagePanel/useMessagePanel";
import AttachmentCarousel from "components/common/attachmentCarousel/attachmentCarousel";
import Breadcrumb from "components/common/navigations/breadcrumb";
import ObjectStatusWithIcon from "components/common/objects/statusObjectWithIcon";
import { PolicyViolationSummary } from "components/common/policyViolationSummary/policyViolationSummary";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import CustomModal from "components/modals/customModal";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { Can } from "services/authorization/authorization";
import { IAttachment, IDType } from "services/common/types/common.type";
import { CreateNotification, NotificationType } from "services/general/notifications";
import CurrencyExchange from "../components/currencyExchange";
import ExpenseSplitTable from "../components/expenseSplitTable";
import style from "./expenseItem.module.css";
import ExpenseStatusPanel from "./expenseStatusPanel";

const ExpenseItem = () => {
  const EXPENSE_ITEM_QUERY_STRING_KEY: string = "searchAPIFilters";

  type SearchAPIFilterType = {
    sort: any;
    items: number;
    page: number;
    expense_report_has_data: number;
  };

  const { t } = useTranslation();
  const t2 = (key: string, obj?: any) => t(`admin.pages.expenseItemDetails.${key}`, obj);

  const { createConfirmModal } = useConfirmModal();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [expenseDetails, setExpenseDetails] = useState<ExpensesTypes.Details>();
  const [hasViolations, setHasViolations] = useState<boolean>(false);
  const [showUploadingErrorModal, setShowUploadingErrorModal] = useState<boolean>(false);
  const [uploadingErrorModalText, setUploadingErrorModalText] = useState<string[]>([]);

  // Begin pagination states
  const [expenseID, setExpenseID] = useState<IDType>();
  const [canShowNavigationLinks, setCanShowNavigationLinks] = useState<boolean>(false);
  const [urlToPreviousExpenseItemPage, setUrlToPreviousExpenseItemPage] = useState<string>("");
  const [urlToNextExpenseItemPage, setUrlToNextExpenseItemPage] = useState<string>("");
  // end pagination states

  const messagePanel = useMessagePanel();

  useEffect(() => {
    if (expenseID) {
      const getExpense = async () => {
        if (expenseID) {
          try {
            let response = await ExpensesApis.getExpense(expenseID);
            setExpenseDetails(response);

            const hasViolations: boolean =
              (response.policy_violations && Object.values(response.policy_violations).some((v) => v === true)) ||
              false;
            setHasViolations(hasViolations);
          } catch (error) {
            CreateNotification(
              t2("notification.getExpenseFailed.header"),
              t2("notification.getExpenseFailed.label"),
              NotificationType.danger,
            );
            console.log(error);
          }
        }
      };
      getExpense();
    }
  }, [expenseID]);

  const buildExpenseItemDetailURL = (
    expenseID: IDType | undefined,
    apiFilterParameters?: SearchAPIFilterType,
  ): string => {
    const queryString = apiFilterParameters
      ? JSON.stringify(apiFilterParameters)
      : searchParams.get(EXPENSE_ITEM_QUERY_STRING_KEY);
    return queryString
      ? `${expenseID}?${EXPENSE_ITEM_QUERY_STRING_KEY}=${encodeURIComponent(queryString || "")}`
      : `${expenseID}`;
  };

  const setURLToPreviousExpenseItem = async (
    expenseItemList: ExpensesTypes.ListItem[],
    indexOfCurrentExpenseItem: number,
    currentSearchAPIFilterValues: any,
  ) => {
    if (indexOfCurrentExpenseItem === 0) {
      if (currentSearchAPIFilterValues.page === 1) {
        // On the first expense item on the first page of data-- there's no previous item before this one.
        setUrlToPreviousExpenseItemPage("");
      } else {
        // On the first expense item in the list; query the previous page of data
        const previousPageAPIValues: SearchAPIFilterType = {
          ...currentSearchAPIFilterValues,
          page: currentSearchAPIFilterValues.page - 1,
        };
        try {
          const result = await ExpensesApis.getPaginatedExpenseList({ filter: previousPageAPIValues });
          // Set the previous page as the last one in the newly obtained list to give the user the illusion that they moved "back" one expense item
          const urlToPreviousExpenseItem = buildExpenseItemDetailURL(
            result.data[result.data.length - 1].id,
            previousPageAPIValues,
          );
          setUrlToPreviousExpenseItemPage(urlToPreviousExpenseItem);
        } catch (error) {
          throw error;
        }
      }
    } else {
      const expenseIdOfPreviousPage = expenseItemList[indexOfCurrentExpenseItem - 1].id;
      const urlToPreviousExpenseItem = buildExpenseItemDetailURL(expenseIdOfPreviousPage);
      setUrlToPreviousExpenseItemPage(urlToPreviousExpenseItem);
    }
  };

  const setURLToNextExpenseItem = async (
    expenseItemList: ExpensesTypes.ListItem[],
    indexOfCurrentExpenseItem: number,
    currentSearchAPIFilterValues: any,
  ) => {
    const currentItemIsLastInPage = indexOfCurrentExpenseItem === expenseItemList.length - 1;

    if (currentItemIsLastInPage) {
      // Check to see if there is a "next" page.
      const nextPageAPIValues: SearchAPIFilterType = {
        ...currentSearchAPIFilterValues,
        page: currentSearchAPIFilterValues.page + 1,
      };
      try {
        const result = await ExpensesApis.getPaginatedExpenseList({ filter: nextPageAPIValues });
        if (result.data.length === 0) {
          // End of line, there is no next page
          setUrlToNextExpenseItemPage("");
        } else {
          const urlToNextExpenseItem = buildExpenseItemDetailURL(result.data[0].id, nextPageAPIValues);
          setUrlToNextExpenseItemPage(urlToNextExpenseItem);
        }
      } catch (error) {
        throw error;
      }
    } else {
      const expenseIdOfNextPage = expenseItemList[indexOfCurrentExpenseItem + 1].id;
      const urlToNextExpenseItem = buildExpenseItemDetailURL(expenseIdOfNextPage);
      setUrlToNextExpenseItemPage(urlToNextExpenseItem);
    }
  };

  const init = async () => {
    const pathSegments = window.location.href.split("/");
    const lastPathSegment = pathSegments[pathSegments.length - 1];
    const currentExpenseId = parseInt(lastPathSegment, 10);
    setExpenseID(currentExpenseId);

    const encodedData = searchParams.get(EXPENSE_ITEM_QUERY_STRING_KEY);
    setCanShowNavigationLinks(encodedData !== null);

    if (searchParams.get("flag") === "msg") {
      messagePanel.openMessagePanel({
        messageObjectId: currentExpenseId,
        messageObjectName: "expense_items",
      });
    }

    if (encodedData) {
      const unencodedData = decodeURIComponent(encodedData);
      const currentSearchAPIFilterValues = JSON.parse(unencodedData);

      try {
        const response = await ExpensesApis.getPaginatedExpenseList({
          filter: currentSearchAPIFilterValues as SearchAPIFilterType,
        });
        const expenseItemList = response.data;
        const indexOfCurrentExpenseItem = expenseItemList.findIndex((e) => e.id === currentExpenseId);
        setURLToPreviousExpenseItem(expenseItemList, indexOfCurrentExpenseItem, currentSearchAPIFilterValues);
        setURLToNextExpenseItem(expenseItemList, indexOfCurrentExpenseItem, currentSearchAPIFilterValues);
      } catch (error) {
        throw error;
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  const onAttachmentUpload = async (files: File[]) => {
    const formData = new FormData();
    formData.append("expense_item[assets_attributes][0][asset_file]", files[0]);
    formData.append("expense_item[assets_attributes][0][asset_file_file_name]", files[0].name);
    try {
      if (expenseID) {
        const details = await ExpensesApis.patchAttachaments({
          id: expenseID,
          formData: formData,
          throwDetailedError: true,
        });
        setExpenseDetails(details);
        CreateNotification(
          t2("notification.uploadSuccess.header"),
          t2("notification.uploadSuccess.label"),
          NotificationType.success,
        );
      }
    } catch (error: any) {
      if (error.data) {
        var errorTextArray: string[] = [];
        for (var key in error.data) {
          if (error.data.hasOwnProperty(key)) {
            errorTextArray.push(error.data[key]);
          }
        }
        setUploadingErrorModalText(errorTextArray);
        setShowUploadingErrorModal(true);
      } else {
        CreateNotification(
          t2("notification.uploadFailed.header"),
          t2("notification.uploadFailed.label"),
          NotificationType.danger,
        );
      }
    }
  };

  const onDeleteConfirm = async (attachment: IAttachment) => {
    try {
      if (attachment.id) {
        const formData = new FormData();
        formData.append("id", attachment.id.toString());
        formData.append("_destroy", "1");
        const details = await ExpensesApis.deleteAttachment({
          id: expenseID || "",
          asset_id: attachment.id,
        });
        CreateNotification(
          t2("notification.deleteSuccess.title"),
          t2("notification.deleteSuccess.body"),
          NotificationType.success,
        );
        setExpenseDetails(details);
      }
    } catch (error) {
      CreateNotification(
        t2("notification.deleteFailed.header"),
        t2("notification.deleteFailed.label"),
        NotificationType.danger,
      );
      console.log(error);
    }
  };

  const deleteAttachment = (attachment: IAttachment) => {
    createConfirmModal({
      title: t2("notification.deleteConfirmationDialog.title"),
      body: t2("notification.deleteConfirmationDialog.body"),
      confirmButtonLabel: t2("notification.deleteConfirmationDialog.deleteButton"),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: () => onDeleteConfirm(attachment),
    });
  };

  const previousItemNavLink = () => {
    return (
      <>
        {urlToPreviousExpenseItemPage.length > 0 && (
          <Row
            className={style.customLinkButton}
            onClick={() => {
              window.location.href = urlToPreviousExpenseItemPage;
            }}
          >
            <div className="icon icon-arrow-left-blue"></div>
            <div style={{ marginTop: "-2px" }}>{t2("previous")}</div>
          </Row>
        )}
        {urlToPreviousExpenseItemPage.length === 0 && (
          <Row className={style.customLinkButtonDisabled}>
            <div className="icon icon-arrow-left-grey"></div>
            <div style={{ marginTop: "-2px" }}>{t2("previous")}</div>
          </Row>
        )}
      </>
    );
  };

  const nextItemNavLink = () => {
    return (
      <>
        {urlToNextExpenseItemPage.length > 0 && (
          <Row
            className={style.customLinkButton}
            style={{ float: "right" }}
            onClick={() => {
              window.location.href = urlToNextExpenseItemPage;
            }}
          >
            <div style={{ marginTop: "-7px" }}>{t2("next")}</div>
            <div className="icon icon-arrow-right-blue"></div>
          </Row>
        )}
        {urlToNextExpenseItemPage.length === 0 && (
          <Row className={style.customLinkButtonDisabled} style={{ float: "right" }}>
            <div style={{ marginTop: "-4px" }}>{t2("next")}</div>
            <div className="icon icon-arrow-right-grey"></div>
          </Row>
        )}
      </>
    );
  };
  return (
    <>
      <Container fluid>
        <Row className={style.head}>
          <Col>
            {canShowNavigationLinks && (
              <Row style={{ marginBottom: "8px" }}>
                <Col sm="6" style={{ paddingLeft: "18px" }}>
                  {previousItemNavLink()}
                </Col>
                <Col sm="6">{nextItemNavLink()}</Col>
              </Row>
            )}
            <Row>
              <Col className="mb-0">
                <Breadcrumb
                  breadcrumbItems={[{ name: t2("expenses"), link: `/ap/expenses` }]}
                  trailLabel={t2("label")}
                />
              </Col>
            </Row>
            {expenseDetails?.status && (
              <>
                <Row>
                  <Col xs="auto" className={style.expenseItemID + " d-flex align-items-center"}>
                    {expenseDetails.number}
                  </Col>
                  <Col xs="auto">
                    <ObjectStatusWithIcon statusText={expenseDetails.status_label}></ObjectStatusWithIcon>
                  </Col>
                  <Col>
                    <div style={{ float: "right" }}>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        style={{ marginRight: "24px" }}
                        onClick={() =>
                          expenseID &&
                          messagePanel.openMessagePanel({
                            messageObjectId: expenseID,
                            messageObjectName: "expense_items",
                          })
                        }
                      >
                        <i className="icon icon-open-chat" style={{ marginRight: "5px" }}></i>
                        Message
                      </button>
                      <Link to={`/ap/expense/${expenseID}`}>
                        <Button variant="primary" type="button">
                          <i className="icon icon-edit-white" style={{ marginRight: "5px" }}></i>
                          {t2("edit")}
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        {expenseDetails &&
          _.isArray(expenseDetails.violations_messages) &&
          expenseDetails.violations_messages.length > 0 && (
            <Row className={style.violationPanel}>
              <PolicyViolationSummary expenseDetails={expenseDetails}></PolicyViolationSummary>
            </Row>
          )}
        <Row className={style.body}>
          <Col md="4" className="flex-grow">
            <Row style={{ height: "100%" }}>
              {expenseDetails && <ExpenseStatusPanel expenseDetails={expenseDetails} />}
            </Row>
          </Col>
          <Col md="8" className="flex-grow">
            <Can I="editattachment" a="ExpenseItems">
              <Card className={style.expenseItemCard}>
                <AttachmentCarousel
                  attachments={expenseDetails?.assets || []}
                  onDeleteAttachmentCallback={deleteAttachment}
                  onUploadRequestedCallback={onAttachmentUpload}
                ></AttachmentCarousel>
              </Card>
            </Can>
          </Col>
        </Row>

        {expenseDetails && expenseItemCommonSvc.isConversionActive(expenseDetails) && (
          <Row>
            <Col className={style.currencyContainer}>
              {expenseDetails && <CurrencyExchange expenseItem={expenseDetails} />}
            </Col>
          </Row>
        )}

        <ExpenseSplitTable expenseItem={expenseDetails} showInAccordion={true} accordionStartsOpen={true} />

        <ActivityLog modelMethod="expense_items" modelActionId={`${expenseID}`} />

        {expenseID && <Activities modelName="InvoiceItem" modelId={expenseID} />}
      </Container>

      {showUploadingErrorModal && (
        <CustomModal
          show={showUploadingErrorModal}
          onHide={() => setShowUploadingErrorModal(false)}
          header={<>{t2("notification.UploadFailedWithDescription.header")}</>}
          body={
            <>
              {t2("notification.UploadFailedWithDescription.labelFirstPart")}
              <div className={style.uploadFailedModalBodyText}>
                <ul>
                  {uploadingErrorModalText.map((errorText) => (
                    <li key={errorText}>{errorText}</li>
                  ))}
                </ul>
              </div>
              {t2("notification.UploadFailedWithDescription.labelSecondPart")}
            </>
          }
          size="lg"
          backdrop={true}
          footer={
            <>
              <Button variant="secondary" onClick={() => setShowUploadingErrorModal(false)}>
                {t("close")}
              </Button>
            </>
          }
        ></CustomModal>
      )}
    </>
  );
};

export default ExpenseItem;
