import Select from "react-select";
import { Form } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { states as stateCountryMapping } from "../../../../app.svc.Lookup";
import { Mandatory } from "../../../../forms/bootstrapFields";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
} from "../../../../../services/general/helpers";
import { useTranslation } from "react-i18next";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const StatePicker = ({
  input,
  label,
  required,
  disabled,
  placeholder,
  isMulti,
  tooltip,
  meta: { touched, error, warning },
  countryCode,
}) => {
  const [states, setStates] = useState([]);
  const { t } = useTranslation();

  const fetchStateOptions = useCallback(() => {
    let fetchedStates = [];
    stateCountryMapping.forEach((stateCountryMap) => {
      if (stateCountryMap.value === countryCode) {
        fetchedStates = stateCountryMap.state_list;
      }
    });
    setStates(fetchedStates);
  }, [countryCode]);

  useEffect(() => {
    // as courtryCodeProps change we want to fetch new states
    fetchStateOptions();
  }, [countryCode, fetchStateOptions]);

  useEffect(() => {
    // as states are fetch then we have to check for selected state
    if (isMulti) {
      findSelectedMultiple(input, states);
    } else {
      findSelectedSingle(input, states);
    }
  }, [input, isMulti, states]);

  return (
    <>
      {states && states.length > 0 ? (
        <>
          {isMulti && (
            <Form.Group>
              {label && (
                <Form.Label>
                  {label}
                  <Mandatory required={required} />
                </Form.Label>
              )}
              {tooltip}
              <Select
                {...input}
                required={required}
                value={findSelectedMultiple(input, states)}
                placeholder={t("searchSelect")}
                onChange={(value) => onChange(input, value)}
                onBlur={() => onBlur(input, input.value)}
                options={states}
                isMulti={isMulti}
                isClearable
                classNamePrefix="select"
                disabled={disabled}
              />
              {error && touched && <span className="color-error">{error}</span>}
            </Form.Group>
          )}

          {!isMulti && (
            <Form.Group>
              {label && (
                <Form.Label>
                  {label}
                  <Mandatory required={required} />
                </Form.Label>
              )}
              {tooltip}
              <Select
                {...input}
                required={required}
                value={findSelectedSingle(input, states)}
                placeholder={t("searchSelect")}
                onChange={(value) => onChangeSingle(input, value)}
                onBlur={() => onBlurSingle(input, input.value)}
                options={states}
                isMulti={isMulti}
                isClearable
                classNamePrefix="select"
                disabled={disabled}
              />
              {error && touched && <span className="color-error">{error}</span>}
            </Form.Group>
          )}
        </>
      ) : (
        <Form.Group>
          {label && (
            <Form.Label>
              {label}
              <Mandatory required={required} />
            </Form.Label>
          )}
          {tooltip ?? ""}
          <Form.Control
            as="input"
            type={"text"}
            placeholder={placeholder}
            {...input}
            isInvalid={touched && !!error}
            isValid={touched && !error}
            disabled={disabled}
            required={required}
          />
          {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
        </Form.Group>
      )}
    </>
  );
};

export default StatePicker;
