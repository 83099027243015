// imports
import { SET_SAML_CONFIGURATION_FORM, SET_SUBMITION_ERROR } from "../../actions/actionTypes";
import RestApi from "../../providers/restApi";
import { CreateNotification, NotificationType } from "../../services/general/notifications";

export const SAML_CONFIGURATION_FORM = "SamlConfigurationForm";
const restApiService = new RestApi();

// initial state
const initialState = {
  samlConfigurationForm: {
    company: {},
    user: {},
  },
  submitionError: null,
};

// reducer
const samlConfigurationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SAML_CONFIGURATION_FORM:
      return { ...state, samlConfigurationForm: payload };
    // Do something here based on the different types of actions
    case SET_SUBMITION_ERROR:
      return { ...state, submitionError: payload };
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
};
export default samlConfigurationReducer;

// action creators
export const setSamlConfigurationForm = (formData) => (dispatch, getState) => {
  dispatch({ type: SET_SAML_CONFIGURATION_FORM, payload: formData });
};

export const setSubmitionError = (error) => (dispatch, getState) => {
  dispatch({ type: SET_SUBMITION_ERROR, payload: error });
};

export const getSamlConfiguarionForm = () => async (dispatch, getState) => {
  try {
    const response = await restApiService.get("company/saml_configuration");
    const company = {};
    company.saml_config = response.data;
    const user = { current_password: "" };
    dispatch(setSamlConfigurationForm({ company, user }));
  } catch (error) {
    console.log("error", error);
  }
};

export const onSubmitConfigurationForm = (configurationFormData) => async (dispatch, getState) => {
  const currentUser = getState().user;
  // make sure and empty password will not be passed to the backend
  if (configurationFormData.user.current_password == "") {
    configurationFormData.user.current_password = undefined;
  }
  configurationFormData.company.saml_config.password_login_enabled =
    !configurationFormData.company.saml_config.direct_login_disabled;
  // checkbox defaults to false when not touched by the user.
  if (!configurationFormData.company.saml_config.direct_login_disabled == null) {
    configurationFormData.company.saml_config.direct_login_disabled = false;
  }
  try {
    const response = await restApiService.patch("company/" + currentUser.company.id + "/saml_configuration", null, {
      company: configurationFormData.company,
      current_password: configurationFormData.user.current_password,
    });
    CreateNotification("Updated", "SAML Configured", NotificationType.success);
  } catch (error) {
    dispatch(setSubmitionError(true));
    console.log(error);
  }
};
