import React, { useEffect, useState } from "react";
import { FieldArray } from "redux-form";
import { Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./manageDefaultAccount.module.css";
import SingleAccountForm from "./form/SingleAccountForm";

const FormPerAccount = React.memo(({ fields, accountGroupName, meta: { error, submitFailed } }) => {
  const [fieldsLength, setFieldsLength] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setFieldsLength(fields.length);
    if (fields.length < 1) {
      addAccount();
    }
  }, []);

  const addAccount = () => {
    fields.push({});
    setFieldsLength((prev) => prev + 1);
  };

  const accountRemoved = () => {
    setFieldsLength((prev) => prev - 1);
  };

  return (
    <Row>
      {fields.map(
        (field, index) =>
          fields.get(index)._destroy !== 1 && (
            <SingleAccountForm
              key={index}
              fields={fields}
              accountGroupName={accountGroupName}
              field={field}
              index={index}
              actualFieldLength={fieldsLength}
              accountRemoved={accountRemoved}
            />
          ),
      )}
      <Button size={"xs"} className={styles.button} type="button" onClick={() => addAccount()}>
        + {t("components.admin.manageDefaultAccount.addAccount")}
      </Button>
    </Row>
  );
});

const AccountsRenderForm = (props) => {
  return (
    <React.Fragment>
      {props.fields.map((name, index) => (
        <div className="mt-4" key={index}>
          <h5 className={styles.h5}>{props.fields.get(index).label}</h5>
          <FieldArray
            name={`${name}.account_links`}
            component={FormPerAccount}
            accountGroupName={
              props.fields && props.fields.get(index).account_group_name
                ? props.fields.get(index).account_group_name
                : null
            }
            rerenderOnEveryChange
          />
          {index + 1 !== props.fields.length && <hr className={styles.divider} />}
        </div>
      ))}
    </React.Fragment>
  );
};
export default AccountsRenderForm;
