import { Field } from "redux-form";
import React from "react";
import { Col } from "react-bootstrap";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";

const NonUsBankNotPayoneerCredential = () => {
  return (
    <>
      <Col lg="6">
        <Field
          id="application_id"
          label="Application ID/Profile ID"
          name="form.application_id"
          placeholder="Application ID"
          component={RenderField}
        />
      </Col>
    </>
  );
};

export default NonUsBankNotPayoneerCredential;
