import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import { RootState, useTypedSelector } from "reducers";
import { change, Field, FieldArray, WrappedFieldArrayProps } from "redux-form";

import {
  WombatFormType,
  WombatFunctionPropsConfigType,
  WombatFunctionsGroupType,
  WombatFunctionSourceType,
  WombatFunctionType,
} from "wombatifier/services/wombat.types";
import { WombatSvc, WOMBAT_FUNCTION_OPTIONS } from "wombatifier/services/wombatSvc";
import { WombatInput } from "wombatifier/components/pickers/wombatInput";
import { WombatSelect } from "wombatifier/components/pickers/wombatSelect";

const WOMBAT_FUNCTION_BUTTON_STYLE = { fontSize: 14 };

interface WombatFunctionFieldsPropsType extends WrappedFieldArrayProps<WombatFunctionType> {
  f_index: number;
  addLabel?: string;
  deleteLabel?: string;
  preConditionNestLevel: number;
  onWombatFunctionChange: () => void;
}

interface WombatFunctionsGroupFieldsPropsType extends WrappedFieldArrayProps<WombatFunctionsGroupType> {
  g_index: number;
  addLabel?: string;
  deleteLabel?: string;
  preConditionNestLevel: number;
  onWombatFunctionChange: () => void;
}

interface WombatFunctionFieldItemPropsType {
  field_name: string;
  w_index: number;
  fn_index: number;
  deleteLabel: string;
  preConditionNestLevel: number;
  deleteWombatFunction: (fn_index: number) => void;
  onWombatFunctionChange: () => void;
}

interface WombatFunctionsGroupFieldItemPropsType {
  field_name: string;
  g_index: number;
  gr_index: number;
  deleteLabel: string;
  preConditionNestLevel: number;
  deleteWombatFunctionsGroup: (g_index: number) => void;
  onWombatFunctionChange: () => void;
}

interface WombatFunctionParamCustomFieldPropsType {
  field_name: string;
  label?: string;
  p_index: number;
  parameter_type?: string;
  hideValueMappedFrom?: boolean;
  hideValue?: boolean;
  onWombatFunctionChange: () => void;
}

interface WombatFunctionParamSourceFieldPropsType {
  field_name: string;
  className?: string;
  p_index: number;
  parameter_type?: string;
  hideValueMappedFrom?: boolean;
  hideValue?: boolean;
  onWombatFunctionChange: () => void;
}

export const WombatFunctionParamSourceField = ({
  field_name,
  p_index,
  className,
  parameter_type,
  hideValueMappedFrom = true,
  hideValue = true,
  onWombatFunctionChange,
}: WombatFunctionParamSourceFieldPropsType) => {
  const wombatFormDataRef = useRef<WombatFormType>();
  const dispatch = useDispatch();
  const isMounting = useRef<boolean>(true);

  const handleSetSourceType = useCallback(
    (value: WombatFunctionSourceType) => {
      dispatch(change(WombatSvc.FORM_NAME, `${field_name}.source_types[${p_index}]`, value));
    },
    [dispatch],
  );

  const handleClearOtherSourceType = useCallback(
    (field: "value_mapped_from" | "value") => {
      dispatch(change(WombatSvc.FORM_NAME, `${field_name}.parameters[${p_index}].${field}`, ""));
      dispatch(change(WombatSvc.FORM_NAME, `${field_name}.parameters[${p_index}].parameter_type`, parameter_type));
    },
    [dispatch, parameter_type],
  );

  const sourceType = useTypedSelector((state: RootState) =>
    WombatSvc.getWombatFunctionSourceType(state, field_name, p_index),
  );
  const parameterValues = useTypedSelector(
    (state: RootState) => WombatSvc.getWombatFunctionParameterValues(state, field_name, p_index),
    shallowEqual,
  );
  const hasAllParameterValues = useTypedSelector((state: RootState) =>
    WombatSvc.getWombatFunctionHasAllParameterValues(state, field_name),
  );
  const chosenMappingFromSelection = useTypedSelector(
    (state: RootState) => WombatSvc.getWombatMappingFromOptions(state),
    shallowEqual,
  );

  useTypedSelector((state: RootState) => WombatSvc.setReferenceWombatFormData(state, wombatFormDataRef), shallowEqual);

  useEffect(() => {
    if (sourceType === "value_mapped_from") {
      handleClearOtherSourceType("value");
    } else if (sourceType === "value") {
      handleClearOtherSourceType("value_mapped_from");
    }
  }, [sourceType]);

  useEffect(() => {
    if (!isMounting.current && hasAllParameterValues && (parameterValues[0] || parameterValues[1])) {
      onWombatFunctionChange();
    }
  }, [...parameterValues]);

  useEffect(() => {
    isMounting.current = false;
  }, []);

  return (
    <Container className={className ?? "mx-0 px-0"}>
      <Row className="mx-0 pt-2">
        <Col className="px-0 d-flex align-items-center pr-2 text-nowrap font-italic" style={{ flex: 0 }}>
          Source
        </Col>
        <Col className="px-0 d-flex align-items-center justify-content-end">
          {!hideValueMappedFrom ? (
            <Button
              type="button"
              style={WOMBAT_FUNCTION_BUTTON_STYLE}
              className={`font-weight-bold m-0 py-1 px-2 border-0 text-white ${sourceType === "value_mapped_from" || hideValue ? "bg-primary" : "bg-secondary"}`}
              variant="none"
              onClick={() => handleSetSourceType("value_mapped_from")}
            >
              Existing Column
            </Button>
          ) : null}
          {!hideValue ? (
            <Button
              type="button"
              style={WOMBAT_FUNCTION_BUTTON_STYLE}
              className={`ml-2 font-weight-bold m-0 py-1 px-2 border-0 text-white ${sourceType === "value" || hideValueMappedFrom ? "bg-primary" : "bg-secondary"}`}
              variant="none"
              onClick={() => handleSetSourceType("value")}
            >
              Hard Coded Value
            </Button>
          ) : null}
        </Col>
      </Row>
      {sourceType === "value_mapped_from" || hideValue ? (
        <Row className="mx-0 pt-2">
          <Col className="px-0 d-flex align-items-center pr-2 text-nowrap" style={{ flex: 0 }}>
            Column Name
          </Col>
          <Col className="px-0">
            <Field
              name={`${field_name}.parameters[${p_index}].value_mapped_from`}
              className="mb-0"
              component={WombatSelect}
              options={chosenMappingFromSelection}
              placeholder="Select Field"
              floating={true}
              isSearchable={true}
            />
          </Col>
        </Row>
      ) : sourceType === "value" || hideValueMappedFrom ? (
        <Row className="mx-0 pt-2">
          <Col className="px-0 d-flex align-items-center pr-2 text-nowrap" style={{ flex: 0 }}>
            Value
          </Col>
          <Col className="px-0">
            <Field name={`${field_name}.parameters[${p_index}].value`} className="mb-0" component={WombatInput} />
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

export const WombatFunctionParamCustomField = ({
  field_name,
  label,
  p_index,
  parameter_type,
  hideValueMappedFrom = false,
  hideValue = false,
  onWombatFunctionChange,
}: WombatFunctionParamCustomFieldPropsType) => {
  return (
    <Card className="px-2 py-0 pb-2 mb-2">
      {label && (
        <Row className="mx-0 pt-2">
          <Col className="px-0 d-flex align-items-center pr-2 text-nowrap" style={{ flex: 0 }}>
            {label}
          </Col>
        </Row>
      )}
      <WombatFunctionParamSourceField
        parameter_type={parameter_type}
        field_name={field_name}
        p_index={p_index}
        hideValueMappedFrom={hideValueMappedFrom}
        hideValue={hideValue}
        onWombatFunctionChange={onWombatFunctionChange}
      />
    </Card>
  );
};

export const WombatFunctionFieldItem = ({
  field_name,
  fn_index,
  deleteLabel,
  deleteWombatFunction,
  onWombatFunctionChange,
  preConditionNestLevel,
}: WombatFunctionFieldItemPropsType) => {
  const wombatFunctionName = useTypedSelector((state: RootState) => WombatSvc.getWombatFunctionName(state, field_name));
  const isMountedRef = useRef<boolean>(true);

  const dispatch = useDispatch();

  const handleSetParametersType = useCallback(
    (parameters: WombatFunctionPropsConfigType) => {
      dispatch(
        change(
          WombatSvc.FORM_NAME,
          `${field_name}.parameters`,
          parameters.map((item) => ({ parameter_type: item.parameter_type })),
        ),
      );
    },
    [dispatch],
  );

  const handleClearParameters = useCallback(() => {
    dispatch(change(WombatSvc.FORM_NAME, `${field_name}.parameters`, []));
  }, [dispatch]);

  const wombatFunctionPropsConfig = useMemo(() => {
    let propsConfig: WombatFunctionPropsConfigType = [];
    switch (wombatFunctionName) {
      case "exists":
        propsConfig = [{ type: "source", p_index: 0, parameter_type: "mapped_from" }];
        break;
      case "round":
      case "get_value":
      case "boolean_result":
        propsConfig = [{ type: "source", p_index: 0, parameter_type: "value" }];
        break;
      case "regex_extract":
      case "regex_match":
        propsConfig = [
          { type: "custom", p_index: 0, label: "Regex Pattern", parameter_type: "regex" },
          { type: "custom", p_index: 1, label: "Source String", parameter_type: "value" },
        ];
        break;
      // below only require value parameter_type since back-end supplies regex pattern
      case "is_email":
      case "is_url":
        propsConfig = [{ type: "custom", p_index: 0, parameter_type: "value" }];
        break;
      case "concat":
        propsConfig = [
          { type: "custom", p_index: 0, label: "First Value" },
          { type: "custom", p_index: 1, label: "Second Value" },
          { type: "custom", p_index: 2, label: "Third Value" },
        ];
        break;
      case "equals":
      case "not_equals":
        propsConfig = [
          { type: "custom", p_index: 0, label: "First Value", parameter_type: "value1" },
          { type: "custom", p_index: 1, label: "Second Value", parameter_type: "value2" },
        ];
        break;
      case "equals_any":
        propsConfig = [
          { type: "custom", p_index: 0, label: "Value", parameter_type: "value1" },
          { type: "custom", p_index: 1, label: "List of Values", parameter_type: "value2" },
        ];
        break;
      case "index_of":
        propsConfig = [
          { type: "custom", p_index: 0, label: "Source", parameter_type: "value" },
          { type: "custom", p_index: 1, label: "Search For", parameter_type: "search_for" },
        ];
        break;
      case "replace":
        propsConfig = [
          { type: "custom", p_index: 0, label: "Source", parameter_type: "value" },
          { type: "custom", p_index: 1, label: "Replace", parameter_type: "replace" },
          { type: "custom", p_index: 2, label: "Replace With", parameter_type: "replace_with" },
        ];
        break;
      case "set_value":
        propsConfig = [
          { type: "custom", p_index: 0, label: "Mapped To", parameter_type: "mapped_to", hideValueMappedFrom: true },
          { type: "custom", p_index: 1, label: "New Value", parameter_type: "new_value" },
        ];
        break;
      case "substring":
        propsConfig = [
          { type: "custom", p_index: 0, label: "Index From", parameter_type: "index_from" },
          { type: "custom", p_index: 1, label: "Index From Plus", parameter_type: "index_from_plus" },
          { type: "custom", p_index: 2, label: "Index To", parameter_type: "index_to" },
        ];
        break;
    }
    return propsConfig;
  }, [wombatFunctionName]);

  useEffect(() => {
    wombatFunctionName && !isMountedRef.current && handleSetParametersType(wombatFunctionPropsConfig);
  }, [wombatFunctionName]);

  useEffect(() => {
    isMountedRef.current = false;
  }, []);

  return (
    <Card className={`shadow-sm p-3 mb-3`}>
      <Row className="mx-0 pt-2 pb-2">
        <Col className="px-0 d-flex align-items-center pr-2" style={{ flex: 0 }}>
          Functions
        </Col>
        <Col className="px-0 d-flex">
          <Field
            name={`${field_name}.function_type`}
            component={WombatSelect}
            options={WOMBAT_FUNCTION_OPTIONS}
            className="w-100 mb-0"
            indicator={false}
            floating={true}
            onChangeCallback={handleClearParameters}
            isSearchable={true}
          />
        </Col>
      </Row>
      {wombatFunctionPropsConfig.map((config) => {
        const { label, p_index, parameter_type, hideValueMappedFrom, hideValue } = config;
        return config.type === "source" ? (
          <WombatFunctionParamSourceField
            key={`${field_name}${p_index}-${wombatFunctionName}`}
            field_name={field_name}
            p_index={p_index}
            parameter_type={parameter_type}
            hideValueMappedFrom={hideValueMappedFrom}
            hideValue={hideValue}
            onWombatFunctionChange={onWombatFunctionChange}
          />
        ) : config.type === "custom" ? (
          <WombatFunctionParamCustomField
            key={`${field_name}${p_index}-${wombatFunctionName}`}
            field_name={field_name}
            p_index={p_index}
            label={label}
            parameter_type={parameter_type}
            hideValueMappedFrom={hideValueMappedFrom}
            hideValue={hideValue}
            onWombatFunctionChange={onWombatFunctionChange}
          />
        ) : null;
      })}
      {preConditionNestLevel < 1 ? (
        <>
          <Row className="mx-0 pt-2">
            <Col className="px-0 d-flex align-items-center text-nowrap font-weight-bold" style={{ flex: 0 }}>
              Precondition Functions
            </Col>
          </Row>
          <Card className="p-3 mb-2">
            <FieldArray
              name={`${field_name}.precondition_functions`}
              component={WombatFunctionsGroupFields}
              g_index={fn_index}
              preConditionNestLevel={preConditionNestLevel + 1}
              onWombatFunctionChange={onWombatFunctionChange}
            />
          </Card>
        </>
      ) : null}

      <Row className="mx-0 pt-4">
        <Col className="px-0">
          <Button
            type="button"
            className="px-3 py-2"
            style={{ backgroundColor: "purple" }}
            onClick={() => deleteWombatFunction(fn_index)}
          >
            {deleteLabel}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export const WombatFunctionFields = ({
  fields,
  f_index,
  addLabel = "+ Function",
  deleteLabel = "- Function",
  onWombatFunctionChange,
  preConditionNestLevel = 0,
}: WombatFunctionFieldsPropsType) => {
  const addWombatFunction = useCallback(() => {
    fields.push({ function_type: "", source_types: [], parameters: [] });
  }, [f_index]);

  const deleteWombatFunction = useCallback(
    (fn_index: number) => {
      fields.remove(fn_index);
      onWombatFunctionChange();
    },
    [f_index],
  );

  return (
    <Container className="px-1">
      {fields.map((field_name, fn_index) => {
        return (
          <WombatFunctionFieldItem
            key={`${f_index}${fn_index}`}
            w_index={f_index}
            fn_index={fn_index}
            field_name={field_name}
            deleteWombatFunction={deleteWombatFunction}
            deleteLabel={deleteLabel}
            onWombatFunctionChange={onWombatFunctionChange}
            preConditionNestLevel={preConditionNestLevel}
          />
        );
      })}
      <Row className="mx-0 pt-2">
        <Col className="px-0 d-flex justify-content-end">
          <Button type="button" className="px-3 py-2 bg-info" onClick={addWombatFunction}>
            {addLabel}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export const WombatFunctionsGroupFieldItem = ({
  field_name,
  g_index,
  gr_index,
  deleteLabel,
  deleteWombatFunctionsGroup,
  onWombatFunctionChange,
  preConditionNestLevel,
}: WombatFunctionsGroupFieldItemPropsType) => {
  return (
    <Card className="shadow p-3 mb-3">
      <FieldArray
        name={`${field_name}.functions`}
        component={WombatFunctionFields}
        f_index={g_index}
        onWombatFunctionChange={onWombatFunctionChange}
        preConditionNestLevel={preConditionNestLevel}
      />
      <hr />
      <Row className="mx-0">
        <Col className="px-0">
          <Button type="button" className="bg-danger px-3 py-2" onClick={() => deleteWombatFunctionsGroup(gr_index)}>
            {deleteLabel}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export const WombatFunctionsGroupFields = ({
  fields,
  g_index,
  deleteLabel = "- Function Group",
  onWombatFunctionChange,
  preConditionNestLevel,
}: WombatFunctionsGroupFieldsPropsType) => {
  const addWombatFunctionsGroup = useCallback(() => {
    fields.push({ functions: [{ function_type: "", source_types: [], parameters: [] }], operator_type: "AND" });
  }, []);

  const deleteWombatFunctionsGroup = useCallback((g_index: number) => {
    fields.remove(g_index);
    onWombatFunctionChange();
  }, []);

  return (
    <Container className="px-1">
      {fields.map((field_name, gr_index) => {
        return (
          <WombatFunctionsGroupFieldItem
            key={`${g_index}${gr_index}`}
            g_index={g_index}
            gr_index={gr_index}
            field_name={field_name}
            deleteWombatFunctionsGroup={deleteWombatFunctionsGroup}
            deleteLabel={deleteLabel}
            onWombatFunctionChange={onWombatFunctionChange}
            preConditionNestLevel={preConditionNestLevel}
          />
        );
      })}
      <Row className="mx-0 pt-2">
        <Col className="px-0 d-flex justify-content-end">
          <Button type="button" className="ml-2 px-3 py-2 bg-primary" onClick={addWombatFunctionsGroup}>
            + Function Group
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
