import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderSelect } from "../../../forms/bootstrapFields";

const Status = ({ label, isStatusRequired, validations }) => {
  const { t } = useTranslation();
  return (
    <Col md="6">
      <Field
        id="payment_method.status"
        name="form.status"
        component={RenderSelect}
        options={[
          {
            value: "",
            label: "",
          },
          {
            value: "ACTIVE",
            label: t("active"),
          },
          {
            value: "INACTIVE",
            label: t("inactive"),
          },
        ]}
        type="select"
        label={label}
        required={isStatusRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default Status;
