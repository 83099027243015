import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import { international_payment_countries } from "../app.svc.Lookup";
import { withTranslation } from "react-i18next";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */

class PaymentCountryPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: international_payment_countries
        .map((item, i) => ({
          value: item.name + item?.currency_code, //using item.name so we can search, updating code so that react select have uniqu value and don't multiple items selected
          code: item.code,
          code2: item.code2,
          label: (
            <div>
              <img
                src={require("../../assets/flags/" + item.flag)}
                height="30px"
                width="30px"
                style={{ padding: 5 }}
                alt="flag"
              />
              ({item?.currency_code}){"  "}
              {item.name}
            </div>
          ),
        }))
        .filter((value) => Boolean(value) === true),
    };
  }

  findDefault = () => {
    let found = {};
    // if(this.props.code2){debugger}
    this.state.countries.forEach((country) => {
      if (country.code === this.props.country || country.code2 === this.props.country) {
        found = country;
      }
    });
    return found;
  };

  findSelected() {
    // if(this.props.code2){debugger}
    if (!this.state.countries || !this.props.selected) {
      return null;
    }

    return this.state.countries.find((option) => {
      if (option.code === this.props.selected.value || option.code2 === this.props.selected.value) {
        return option;
      }
      return null;
    });
  }

  render() {
    return (
      <Form.Group as={Col} md={this.props.colSize || "4"}>
        <Form.Label style={{ textTransform: "capitalize" }}>
          {this.props.label || this.props.t("country")}:{this.props.required && <span className={"color-red"}>*</span>}
        </Form.Label>
        <Select
          value={this.findSelected()}
          defaultValue={this.findDefault()}
          styles={{
            valueContainer: (base) => ({
              ...base,
              height: 40,
              minHeight: 40,
            }),
          }}
          placeholder={"search/select"}
          options={this.state.countries}
          onChange={(e) => {
            if (this.props.countryPickerCallback) {
              this.props.countryPickerCallback({ value: this.props.code2 ? e.code2 : e.code });
            }
          }}
        />
        <Form.Control.Feedback type="invalid">{this.props.t("validation.country")}</Form.Control.Feedback>
      </Form.Group>
    );
  }
}

export default withTranslation()(PaymentCountryPicker);
