import ListSchedules from "components/admin/reports/schedules/listSchedules";
import React from "react";
import { STANDARD_REPORT_OPTIONS } from "services/admin/reports/reportSvc";

const Schedules = () => (
  <ListSchedules
    options={STANDARD_REPORT_OPTIONS}
    classification="Standard"
    createLabel="Report"
    scheduleServiceName="REPORT"
  />
);
export default Schedules;
