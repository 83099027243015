// metadataConfigurationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { MetadataConfiguration } from "pages/admin/administration/metadata/metadataConfiguration";

// interface MetadataConfigurationState {
//     id: number,
//     field_id: string,
//     name: string,
//     object_type: string,
//     input_type: string,
//     created_at: Date,
//     updated_at: Date
// }

const initialState: MetadataConfiguration = {
  field_id: "",
  name: "",
  object_type: "",
  input_type: "",
};

const metadataConfigurationSlice = createSlice({
  name: "metadataConfigurationForm",
  initialState,
  reducers: {
    setConfiguration(state, action) {
      //console.log(state, action)
      state = action.payload;
    },
    resetConfiguration(state) {
      state = initialState;
    },
    // You can add more reducers as needed
  },
});

export const { setConfiguration, resetConfiguration } = metadataConfigurationSlice.actions;
export default metadataConfigurationSlice.reducer;
