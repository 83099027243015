import { useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { change, Field } from "redux-form";
import { RenderCheckBox } from "../../../forms/bootstrapFields";
import { COMPANY_PAYMENT_METHOD_FORM_NAME } from "../../../../reducers/admin/companyPaymentMethodFormReducer";

const AccountPayoutCheckBoxes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setIsAccountPayout = useCallback(
    (value) => {
      dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "form.is_ach_payout", value));
    },
    [dispatch],
  );

  useEffect(() => {
    // set is_account_payout to true asa components mount
    setIsAccountPayout(true);

    // set is_account_payout to false asa components unmount
    return () => {
      setIsAccountPayout(false);
    };
  }, [setIsAccountPayout]);

  return (
    <Col md="12">
      <Row>
        <Col md="3">
          <Field
            id="form.is_ach_payout"
            name="form.is_ach_payout"
            component={RenderCheckBox}
            type="checkbox"
            label={t("companySettings.paymentMethodsSettings.isAccountPayout")}
          />
        </Col>
        <Col md="3">
          <Field
            id="form.is_expense_report_payout"
            name="form.is_expense_report_payout"
            component={RenderCheckBox}
            type="checkbox"
            label={t("companySettings.paymentMethodsSettings.isExpenseReport")}
          />
        </Col>
        <Col md="3">
          <Field
            id="form.is_primary"
            name="form.is_primary"
            component={RenderCheckBox}
            type="checkbox"
            label={t("companySettings.paymentMethodsSettings.isDefault")}
          />
        </Col>
        <Col md="3">
          <Field
            id="form.is_verified"
            name="form.is_verified"
            component={RenderCheckBox}
            type="checkbox"
            label={t("companySettings.paymentMethodsSettings.isVerified")}
            disabled
          />
        </Col>
      </Row>
    </Col>
  );
};

export default AccountPayoutCheckBoxes;
