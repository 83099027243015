import React, { useState } from "react";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { minLength, required } from "../../../../services/validations/reduxFormValidation";
import { useTranslation } from "react-i18next";
import { PaymentMethodValidatorSvc } from "../paymentMethodValidatorSvc";
import { IDType } from "../../../../services/common/types/common.type";

const min6 = minLength(6);

const BsbCode = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState("");
  const [istouched, setIsTouched] = useState(false);

  const validateBsbNumber = async (value: IDType) => {
    const stringValue = String(value);
    setIsTouched(stringValue.length > 0 ? true : false);
    if (stringValue.length === 6) {
      try {
        const data = await PaymentMethodValidatorSvc.ValidateBsbNumber(value);
        if (data?.validation) {
          setValidationMessage(data.validation);
        } else {
          setValidationMessage(data?.errors[0]?.message);
        }
      } catch (error) {
        throw error;
      }
    }
  };
  return (
    <>
      <Field
        name={`${modelName}.bsb_code`}
        component={RenderField}
        tooltip="The Bank State Branch code (BSB) identifies the bank and branch holding a bank account in Australia. It’s 6 digits long and there’s no need to include the dash in the middle."
        type="text"
        maxLength={6}
        label={t("common.paymentMethod.bsbCode")}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => validateBsbNumber(e.target.value)}
        required
        validate={[required, min6]}
      />
      {validationMessage && istouched && (
        <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
      )}
    </>
  );
};

export default BsbCode;
