import { RenderCheckBox, RenderSelect } from "../../../../../../components/forms/bootstrapFields";
import { CompanyDefaultsTypes } from "../../settingsTypes";

export const draftInvoiceSettingsHeaderLevel: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.draft_invoice.hide_description",
    tooltip: {
      show: true,
      title: "The Description field in the header level will be hidden.",
      placement: "top",
    },
    label: "Hide Description",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj: any) => {
      return true;
    },
    onChangeCallBack: (onChangeObj: any) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.draft_invoice.hide_prior_period",
    tooltip: {
      show: true,
      title: "The Prior Period field in the header level will be hidden.",
      placement: "top",
    },
    label: "Hide Prior Period",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj: any) => {
      return isShowObj?.company_default?.invoice_show_prior_period;
    },
    onChangeCallBack: (onChangeObj: any) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
];

export const draftInvoiceSettingsItemLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.draft_invoice.items.hide_description",
    tooltip: {
      show: true,
      title: "Description field at the line level will be hidden",
      placement: "top",
    },
    label: "Hide Description",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj: any) => {
      return true;
    },
    onChangeCallBack: (onChangeObj: any) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
];

export const draftInvoiceSettingsExpenseLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.draft_invoice.expenses.hide_memo",
    tooltip: {
      show: true,
      title: "Memo field at the expense level will be hidden",
      placement: "top",
    },
    label: "Hide Memo",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj: any) => {
      return true;
    },
    onChangeCallBack: (onChangeObj: any) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
];

type OptionType = {
  label: string;
  value: number;
};

type PriorityOptionsType = OptionType[];

export const draftInvoiceSettingsRecommendations = (
  priorityOptions: PriorityOptionsType = [],
): CompanyDefaultsTypes.CommonSettingFieldType[] => {
  return [
    {
      name: "company_default.draft_invoice.recommendations.vendor_default",
      tooltip: {
        show: true,
        title: "This option uses vendor default accounts as the recommended accounts",
        placement: "top",
      },
      label: "Vendor Defaults",
      type: "drop-down",
      component: RenderSelect,
      isShow: (isShowObj: any) => {
        return true;
      },
      options: priorityOptions,
      onChangeCallBack: (onChangeObj: any) => {},
      col: 1,
      validation: [],
      require: false,
      class: "",
    },
    {
      name: "company_default.draft_invoice.recommendations.item_default",
      tooltip: {
        show: true,
        title: "This option uses previous invoice item accounts as the recommended accounts",
        placement: "top",
      },
      label: "Item Line Defaults",
      type: "drop-down",
      component: RenderSelect,
      isShow: (isShowObj: any) => {
        return true;
      },
      options: priorityOptions,
      onChangeCallBack: (onChangeObj: any) => {},
      col: 1,
      validation: [],
      require: false,
      class: "",
    },
    {
      name: "company_default.draft_invoice.recommendations.expense_default",
      tooltip: {
        show: true,
        title: "This option uses previous expense accounts as the recommended accounts",
        placement: "top",
      },
      label: "Expense Line Defaults",
      type: "drop-down",
      component: RenderSelect,
      isShow: (isShowObj: any) => {
        return true;
      },
      options: priorityOptions,
      onChangeCallBack: (onChangeObj: any) => {},
      col: 1,
      validation: [],
      require: false,
      class: "",
    },
  ];
};
