import React from "react";
import DropdownFilter from "../reportFilters/dropdown";
import DateFilter from "../reportFilters/date";
import { shallowEqual, useDispatch } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import { Row, Col } from "react-bootstrap";
import { ComparisonField, RenderCheckBox, RenderFieldNumber } from "../../../../forms/bootstrapFields";
import SubsidiaryPicker from "components/pickers/reduxFormPicker/subsidiaryPicker";
import { CompanyType } from "../../../../../services/common/user/userTypes";
import { useTypedSelector } from "../../../../../reducers";

const GpCcTransactionsListing = ({}) => {
  const company: CompanyType = useTypedSelector((state) => state.user?.company, shallowEqual);
  const expense_status_options = [
    { value: "", label: "ALL" },
    { value: "NEW", label: "NEW" },
    { value: "PENDING", label: "PENDING" },
    { value: "OPEN", label: "OPEN" },
    { value: "REJECTED", label: "REJECTED" },
    { value: "PAID", label: "PAID" },
  ];
  return (
    <div>
      <DateFilter name={"From Posting Date"} code={"start_date"} options={[]} isRequired />
      <DateFilter name={"To Posting Date"} code={"end_date"} options={[]} isRequired />
      <DropdownFilter name={"Expense Status"} code={"status"} options={expense_status_options} />
      {company.has_subsidiaries && (
        <Row className="m-0">
          <Col>
            <Field
              name="properties.filters.subsidiary_ids"
              label="Subsidiary"
              labelClassName="font-weight-bold"
              component={SubsidiaryPicker}
              placeholder="All"
              isMulti
              filter={{ active: true }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default GpCcTransactionsListing;
