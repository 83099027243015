import React, { useEffect, useState } from "react";
import RestApi from "../../../../providers/restApi";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { formattedAmount } from "../../../../services/general/helpers";
import useIsMounted from "../../../common/hooks/useIsMounted";

const restApiService: RestApi = new RestApi();

interface IPurchaseOrder {
  id: number;
  number: string;
  status: string;
  date: string;
  amount: number;
  currency: { iso_code: string };
  open_balance: number;
}

type propsTypes = {
  filter: {
    purchaser_id: string;
    limit: number;
  };
};

const PurchaseOrderTable = ({ filter }: propsTypes) => {
  const [purchaseOrders, setPurchaseOrders] = useState<Array<IPurchaseOrder>>([]);
  const isMounted = useIsMounted();

  const fetchPurchaseOrders = async () => {
    try {
      const response = await restApiService.get("vendor_purchase_orders", filter);
      if (isMounted) {
        setPurchaseOrders(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted) {
      fetchPurchaseOrders();
    }
  }, []);

  return (
    <div>
      {purchaseOrders ? (
        <Table striped bordered hover className="mb-0">
          <thead>
            <tr>
              <th className="primaryFontColor">Number</th>
              <th className="primaryFontColor">Status</th>
              <th className="primaryFontColor">Date</th>
              <th className="primaryFontColor">Gross Amount</th>
              <th className="primaryFontColor">Outstanding Balance</th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders.map((purchase_order) => {
              return (
                <tr key={purchase_order.id}>
                  <td>
                    <Link
                      className="primaryLinkColor"
                      to={"/ar/purchase_orders/" + purchase_order.id}
                      data-toggle="tooltip"
                      title="Purchase Order"
                    >
                      {purchase_order.number}
                    </Link>
                  </td>
                  <td>{purchase_order.status}</td>
                  <td>{purchase_order.date}</td>
                  <td>{formattedAmount(purchase_order.amount?.toString(), purchase_order.currency?.iso_code)}</td>
                  <td>{formattedAmount(purchase_order.open_balance?.toString(), purchase_order.currency?.iso_code)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <h5 className="text-center mt-3 mb-3">Loading...</h5>
      )}
    </div>
  );
};

export default PurchaseOrderTable;
