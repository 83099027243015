import React from "react";
import DropdownFilter from "../reportFilters/dropdown";

const ContactListing = ({ scheduled }: { scheduled?: boolean }) => {
  const contact_type_options = [
    { value: "", label: "ALL" },
    { value: "VENDOR", label: "VENDOR" },
    { value: "COMPANY", label: "COMPANY" },
  ];

  return (
    <div>
      <DropdownFilter name={"Contact Type"} code={"contact_type"} options={contact_type_options} />
    </div>
  );
};

export default ContactListing;
