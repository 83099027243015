import React, { Fragment, useState } from "react";
import style from "../companyProfile.module.css";
import _ from "lodash";
import { PurchaserListType } from "../../../../services/vp/purchaser/purchaserTypes";
import DocumentRequirement from "./documentRequirement";
import { useTypedSelector } from "../../../../reducers";
import AdditionalDocument from "./additionalDocument";
import VendorDocumentUploadModal from "../../../common/vendorDocumentUploadModal/vendorDocumentUploadModal";
import documentService from "../../../../services/common/documents/documentSvc";
import VendorDocumentLinkApis from "../../../../services/common/documentVendorLinks/vendorDocumentLinkApis";
import { useDispatch } from "react-redux";
import { getVendorDocumentLinks } from "../../../../reducers/vp/companyProfileReducer";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";

type purchaserRowPropsType = {
  purchaser: PurchaserListType;
};
const PurchaserRow = ({ purchaser }: purchaserRowPropsType) => {
  const dispatch = useDispatch();
  const vendorDocumentLinks = useTypedSelector((state) => state.vpCompanyProfileReducer.vendorDocumentLinks);
  const company = useTypedSelector((state) => state.vpCompanyProfileReducer.company);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const vendorDocumentLinksForPurchaser = vendorDocumentLinks.filter(
    (link) => link.vendor_id === purchaser?.vendor_detail?.id,
  );

  const closeUploadModal = () => {
    setShowUploadModal(false);
  };

  const onSubmitDocumentUploadModal = async (data: {
    file?: File | undefined;
    w8PageData?: string | undefined;
    w8BenPageData?: string | undefined;
    w9PageData?: string | undefined;
    diversityFormData?: Record<string, any> | undefined;
    signReference?: string;
    signatureValue?: string;
  }) => {
    try {
      setIsSubmitting(true);
      if (company?.id) {
        const document = await documentService.onSubmitDocumentUploadModal({
          ...data,
          documentableId: company?.id,
          documentableType: "Company",
        });

        if (document && purchaser.vendor_detail?.id) {
          await VendorDocumentLinkApis.createVendorDocumentLink(
            {
              document_vendor_link: {
                document_id: document?.id,
                vendor_id: purchaser.vendor_detail?.id,
              },
            },
            "vendor_document_links",
          );

          // refresh to update bubbles
          await dispatch(getVendorDocumentLinks());

          closeUploadModal();
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      let errorObj: any = error;
      if (_.isArray(errorObj.response?.data["assets.asset_file"])) {
        CreateNotification("Error", errorObj.response?.data["assets.asset_file"].toString(), NotificationType.danger);
      } else {
        CreateNotification("Error", "Document not uploaded", NotificationType.danger);
      }
      setIsSubmitting(false);
      closeUploadModal();
    }
  };

  return (
    <Fragment key={purchaser.id}>
      {showUploadModal && (
        <VendorDocumentUploadModal
          showUploadModal={showUploadModal}
          closeUploadModal={closeUploadModal}
          submitCallback={onSubmitDocumentUploadModal}
          isSubmitting={isSubmitting}
        />
      )}

      <div className="d-flex">
        <div className={`mr-4 ${style.w150}`}>{purchaser.name}</div>
        <div>
          <div className="mb-3">
            Requested Documents
            <div className="d-flex">
              <div className="d-flex flex-wrap">
                {_.isArray(purchaser?.document_requirements) &&
                  purchaser.document_requirements.map((requirement) => {
                    return (
                      <DocumentRequirement
                        key={requirement.id}
                        purchaser={purchaser}
                        documentRequirement={requirement}
                      />
                    );
                  })}
                {(!purchaser.document_requirements ||
                  (_.isArray(purchaser.document_requirements) && purchaser.document_requirements.length === 0)) && (
                  <p>No Required Documents</p>
                )}
              </div>
            </div>
          </div>
          <div>
            Other Documents
            <div className="d-flex flex-wrap">
              <div>
                <button
                  type="button"
                  className={style.bubbleInitial}
                  onClick={() => {
                    setShowUploadModal((prev) => true);
                  }}
                >
                  + Add
                </button>
              </div>
              <div className="d-flex flex-wrap">
                {_.isArray(vendorDocumentLinksForPurchaser) &&
                  vendorDocumentLinksForPurchaser.map((link) => {
                    return <AdditionalDocument key={link.id} documentLink={link} />;
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </Fragment>
  );
};

export default PurchaserRow;
