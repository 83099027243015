import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import EmailTags from "./emailTags";
import EmailStatus from "./emailStatus";
import EmailHeading from "./emailHeading";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import CollapseEmail from "./collapseEmail";
import EmailDataLinks from "./emailDataLinks";
import { AttachmentInvoiceLink } from "./getLinkedAttachments";
import CollapsePanel from "../../../panel/collapsePanel";
import EmailLog from "./emailLog";
import DropButton from "../../../common/buttons/dropButton";
import { getLinkImage } from "./getLinkImage";
import {
  markAsCompletedAndNext,
  refreshEamil,
  removeEmailTag,
  resetParamEmailId,
  updateRowData,
} from "../../../../reducers/admin/emailReducer";
import RestApi from "../../../../providers/restApi";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import ThreadReferences from "./threadReferences";
import UnlinkEmail from "./unlinkEmail";
import ForwardEmail from "./forwardEmail";
import _ from "lodash";
import style from "./email.module.css";
import SerialAttachments from "../../../common/serialAttachments/serialAttachments";
import { IAttachment } from "../../../../services/common/types/common.type";
import HTMLRenderer from "../../../common/htmlRender/htmlRender";
import usePermission from "../../../common/hooks/usePermission";

const restApiService = new RestApi();

const EmailDetails = () => {
  const email = useSelector((state: RootState) => state.emailRed.emailDetails);
  const dispatch = useDispatch();

  const [moreDetail, setMoreDetail] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showForwordEmail, setShowForwordEmail] = useState(false);
  const [mailType, setMailType] = useState("");
  const [isReplyAll, setIsReplyAll] = useState(false);
  const [previewedAttachment, setPreviewedAttachment] = useState<IAttachment>();
  const { hasUserPermission } = usePermission();

  // no access to 'reply'/'reply all'/'forward' if user does not have 'editInvoiceEmails' permission
  const buttonDisabled = hasUserPermission("editInvoiceEmails") ? false : true;

  const toggleShowLinkModal = () => {
    setShowLinkModal(!showLinkModal);
  };

  const toggleShowForwordEmail = () => {
    setShowForwordEmail(!showForwordEmail);
  };

  const parseUploadFiles = async (files: File[]) => {
    const parsedFiles = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    const formData = new FormData();
    let index = 0;
    parsedFiles.forEach((file) => {
      formData.append("[inbound_email][attachments_attributes][" + index + "][filename]", file.name);
      formData.append("[inbound_email][attachments_attributes][" + index + "][asset_file]", file);
      formData.append("[inbound_email][attachments_attributes][" + index + "][uploaded]", "true");
    });
    formData.append("inbound_email[to]", email.to);

    try {
      const response = await restApiService.formWithImage("inbound_emails/" + email.id, formData, "patch");
      await dispatch(updateRowData(response.data.id)); // updating grid row
      await dispatch(refreshEamil(response.data.id)); // refreshing email details
      CreateNotification("Success", "Attachment Added Successfully", NotificationType.success);
    } catch (error) {
      console.log(error);
    }
  };

  const removeTag = (tag: string[]) => {
    dispatch(removeEmailTag({ emailId: email.id, tags: tag }));
  };

  const replyEmail = () => {
    setMailType("reply");
    toggleShowForwordEmail();
  };

  const replyAllEmail = () => {
    setMailType("replyAll");
    setIsReplyAll(true);
    toggleShowForwordEmail();
  };

  const forwardEmail = () => {
    setMailType("forward");
    toggleShowForwordEmail();
  };

  const performClean = () => {
    setShowForwordEmail(false);
    setMoreDetail(false);
    setShowLinkModal(false);
    setMailType("");
    setIsReplyAll(false);
  };

  useEffect(() => {
    performClean();

    // as using paramsEmailId only one time if id is present in url to show details of that specific email
    // then it's not required ,so removed once email is shown
    // NOTE: removing from redux store will not remove id from url which was okay.
    // this will work due to in getRows function we check if (paramsEmailId), once updated paramsEmailId to falsy
    // other code code will work properly.
    dispatch(resetParamEmailId());
    return () => {
      performClean();
    };
    // watching email change to run block as
    // detail page is not get unmounted
  }, [email]);

  return (
    <div>
      {/* if show forward flag is not active showing email details page and vice versa */}
      {!showForwordEmail ? (
        <div className={"p-0 emailDetailPage emailListView"}>
          <div>
            <header className={"showEmailHeader"}>
              {email.status !== "Completed" && (
                <Row className={"m-0 py-2 " + style.markCompleteBackgroud}>
                  <Col xs={12} className={"d-flex"}>
                    <div
                      className={"d-flex py-2 " + (buttonDisabled ? "cursorNotAllowed" : "cursorPointer")}
                      onClick={() => !buttonDisabled && dispatch(markAsCompletedAndNext(email))}
                    >
                      <i className="icon markCompleteIcon" />
                      <h5 className={"ml-2 mb-0 " + style.markCompleted}>Mark as complete and next</h5>
                    </div>
                  </Col>
                </Row>
              )}

              <Row className={"m-0 py-2 bg-white " + style.emailContactDetailTopBoarder}>
                <Col xs={8} className={"d-flex"}>
                  <i className="icon chatIcon" />
                  <p className={"ml-2"}>{email.logs.length} Comments</p>
                </Col>
                <Col xs={4} className={"d-flex justify-content-end"}>
                  {buttonDisabled ? null : (
                    <>
                      <i
                        className={"pr-2 mx-2 cursorPointer icon replyIcon"}
                        role="button"
                        onClick={() => replyEmail()}
                        data-toggle="tooltip"
                        title="Reply Email"
                      />
                      <i
                        className={"pr-2 mx-2 cursorPointer icon replyAllIcon"}
                        role="button"
                        onClick={() => replyAllEmail()}
                        data-toggle="tooltip"
                        title="Reply to all Email"
                      />
                      <i
                        className={"pr-2 cursorPointer icon forwardIcon"}
                        role="button"
                        onClick={() => {
                          forwardEmail();
                        }}
                        data-toggle="tooltip"
                        title="Forward Email"
                      />
                    </>
                  )}
                </Col>
              </Row>
            </header>
            {_.isArray(email?.thread_references) && !!email.thread_references.length && (
              <Col xs={12} className={"bg-white py-2 attachment border-top-0"}>
                <ThreadReferences email={email} />
              </Col>
            )}
            <section className={"ltSection bg-white"}>
              <Row className={"m-0 py-2"}>
                <Col xs={9} className={"d-flex"}>
                  {email?.subject}
                  <EmailTags status="" tags={email?.tags} removeTag={(tags) => removeTag(tags)} />
                </Col>
                <EmailStatus email={email} />
                <Col xs={9} className={"pr-0 py-2"}>
                  <EmailHeading email={email} />
                  <div onClick={() => setMoreDetail(!moreDetail)}>
                    {moreDetail ? (
                      <p className={style.emailContactDetail}>
                        hide detail <FaCaretUp />
                      </p>
                    ) : (
                      <p className={style.emailContactDetail}>
                        more detail <FaCaretDown />
                      </p>
                    )}
                  </div>
                  <CollapseEmail moreDetail={moreDetail} email={email} />
                </Col>
                <Col xs={12} className={"d-flex"}>
                  {email && _.isArray(email.data) && !!email.data.length && (
                    <div className={"col-12 attachment px-0"}>
                      <div className={"ltHeader"}>
                        <Row className={"m-0 py-2"}>
                          <Col xs={7} className={"d-flex"}>
                            <h5 className={"text-white"}>Linked to Invoice(s): </h5>
                            <div>
                              <EmailDataLinks data={email.data} email={email} />
                            </div>
                          </Col>
                          <Col xs={5} className={"d-flex"}>
                            <button
                              disabled={!email.data || !email.data.length || buttonDisabled}
                              className={
                                "unlinkInvoice ml-auto d-flex " +
                                (buttonDisabled ? "cursorNotAllowed" : "cursorPointer")
                              }
                              onClick={toggleShowLinkModal}
                            >
                              <i className={"icon icon-unlink-white"} />
                              <h5 className={"text-white"}>Unlink Invoice(s)</h5>
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xs={12} className={"py-3 emailBody"} style={{ width: "50vw" }}>
                  {email?.html ? <HTMLRenderer html={email.html} /> : <HTMLRenderer html={email.html} />}
                </Col>

                {_.isArray(email.attachments) && email.attachments.length > 0 ? (
                  <SerialAttachments
                    useModal
                    previewAccessor
                    attachments={email.attachments}
                    customComponent={<AttachmentInvoiceLink attachment={previewedAttachment} data={email.data} />}
                    onAttachmentChangeCallback={(selectedAttachment: IAttachment) => {
                      setPreviewedAttachment(selectedAttachment);
                    }}
                  />
                ) : (
                  <div>
                    <h5 className="pl-2">No Attachments</h5>
                  </div>
                )}

                <Col xs={12}>
                  {!buttonDisabled && (
                    <div className={"py-3 attachment d-flex flex-wrap"}>
                      <DropButton onDrop={parseUploadFiles} hideIcon={true} />
                    </div>
                  )}
                  <div className={"py-1 new_attachment d-flex flex-wrap"}>
                    {/*<h5>New Attachments:</h5>*/}
                    {_.isArray(email.attachments) ? (
                      email.attachments.map((attachment: any, i: number) => (
                        <div key={attachment?.key}>
                          {attachment?.uploaded && getLinkImage(attachment, false)}
                          <br />
                        </div>
                      ))
                    ) : (
                      <div>
                        <h5 className={"pl-2"}>No attachments</h5>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12} className={"py-3"}>
                  <CollapsePanel heading="LOG" cardStyle={{ borderBottom: "2px solid #76777A" }} open={true}>
                    <EmailLog logs={email.logs} />
                  </CollapsePanel>
                </Col>
              </Row>
            </section>
          </div>
          <Modal show={showLinkModal} onHide={toggleShowLinkModal} size={"lg"}>
            <UnlinkEmail emailDetail={email} handleClose={toggleShowLinkModal} handleShow={toggleShowLinkModal} />
          </Modal>
        </div>
      ) : (
        <div className={"forwardEmail"}>
          <ForwardEmail
            mailType={mailType}
            email={email}
            closeForwordEmail={toggleShowForwordEmail}
            height={330}
            isReplyAll={isReplyAll}
          />
        </div>
      )}
    </div>
  );
};

export default EmailDetails;
