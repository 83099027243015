import _ from "lodash";
import React from "react";
import { Row } from "react-bootstrap";
import Style from "./workFlow.module.css";

const StaticWorkFlowListView = ({ workFlowSteps, workFlowNames }) => {
  return (
    <Row>
      {workFlowNames.map((workFlowName, index) => {
        return (
          <div className={`m-4 w-100 ${Style.workflowStepContainer}`}>
            <div>
              <div className="p-3 font-weight-bold" style={{ border: "1px solid #cccccc" }}>
                Workflow: {workFlowName.workflow_name_label}
              </div>
              {workFlowSteps
                .filter((a) => a.workflow_name == workFlowName.workflow_name)
                .map((workFlowStep, index) => {
                  return (
                    <div key={index} className={`${Style.workFlowListViewGridContainer} m-0`}>
                      <div className="p-3 font-weight-bold">{workFlowStep.priority}</div>
                      <div className="m-3 font-weight-bold">{workFlowStep.value.label}</div>
                      <div></div>
                      <div className="m-3">
                        <table className="smallFont ">
                          <tbody>
                            <tr>
                              <th>Approver (s)</th>
                              <td>
                                {workFlowStep.value &&
                                  workFlowStep.value.approver_type &&
                                  workFlowStep.value.approver_type === "TEAM" &&
                                  _.isArray(workFlowStep.approver_team) &&
                                  workFlowStep.approver_team.map((val, index) => (
                                    <span key={index}>
                                      {val}
                                      {index !== workFlowStep.approver_team.length - 1 && ","}
                                    </span>
                                  ))}
                                {workFlowStep.value &&
                                  workFlowStep.value.approver_type &&
                                  workFlowStep.value.approver_type === "USER" &&
                                  workFlowStep.approver_user &&
                                  workFlowStep.approver_user.id}
                              </td>
                            </tr>

                            <tr>
                              <th style={{ verticalAlign: "text-top" }}> Trigger (s) </th>
                              <td>
                                {workFlowStep &&
                                  workFlowStep.value &&
                                  _.isArray(workFlowStep.value.triggers) &&
                                  workFlowStep.value.triggers.map((trigger, index) => {
                                    return (
                                      <div key={index}>
                                        {index + 1} :{" "}
                                        {trigger.key && (
                                          <>
                                            {" "}
                                            key {"==>"} {trigger.key}
                                          </>
                                        )}
                                        ,{"  "}
                                        {trigger.operator && (
                                          <>
                                            operator {"==>"} {trigger.operator}
                                          </>
                                        )}
                                        ,{"  "}
                                        {trigger.value && (
                                          <>
                                            value {"==>"}{" "}
                                            {_.isArray(trigger.value)
                                              ? trigger.value.map((val, index) => <div key={index}>{val}, </div>)
                                              : trigger.value}
                                          </>
                                        )}
                                      </div>
                                    );
                                  })}
                              </td>
                            </tr>

                            <tr>
                              <th> Custom Trigger (s)</th>
                              <td>
                                {workFlowStep &&
                                  workFlowStep?.action_code &&
                                  workFlowStep?.value?.triggers &&
                                  Object.keys(workFlowStep.action_code).map((custom_trigger) => {
                                    return (
                                      <div>
                                        {workFlowStep.value.triggers.some(
                                          (trigger) => trigger.value === custom_trigger,
                                        ) && (
                                          <>
                                            {" "}
                                            {custom_trigger} {"==>"} {workFlowStep.action_code[custom_trigger]}
                                          </>
                                        )}
                                      </div>
                                    );
                                  })}
                              </td>
                            </tr>

                            <tr>
                              <th> Email Notification</th>
                              <td>{workFlowStep.email_notification ? "Yes" : "No"}</td>
                            </tr>

                            <tr>
                              <th> Last Step Group</th>
                              <td>{workFlowStep.is_last ? "Yes" : "No"}</td>
                            </tr>

                            <tr>
                              <th> Status</th>
                              <td>{workFlowStep.status}</td>
                            </tr>

                            <tr>
                              <th> Description</th>
                              <td>{workFlowStep.description}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </Row>
  );
};
export default StaticWorkFlowListView;
