import { RenderField } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const CambrideUserId = ({ label, col, isUserIdRequired, validations }) => {
  return (
    <Col md={col ? col : "6"}>
      <Field
        id="form.cambridge.user_id"
        name="form.cambridge.user_id"
        component={RenderField}
        type="text"
        label={label}
        required={isUserIdRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default CambrideUserId;
