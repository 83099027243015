import _ from "lodash";
import React, { memo } from "react";
import { DepartmentPickerType } from "./departmentPickerTypes";
import SingleDepartmentPicker from "./singlePicker";

const DepartmentPicker = (props: DepartmentPickerType.TDepartmentPickerProps) => {
  const parentObj = !_.isNil(props.parentObj) ? (props.parentObj === "" ? "" : props.parentObj + ".") : null;
  const modelData = !_.isNil(props.modelData) ? (props.modelData === "" ? "" : props.modelData + ".") : null;
  return (
    <>
      {props.isMulti ? (
        // <MultiDepartmentPicker/>         // TODO: Implement Multi Department Picker
        <></>
      ) : (
        <SingleDepartmentPicker {...props} parentObj={parentObj} modelData={modelData} />
      )}
    </>
  );
};

export default memo(DepartmentPicker);
