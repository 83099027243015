import React from "react";
import { Button, Card, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { SAML_CONFIGURATION_FORM } from "../../../reducers/admin/samlConfigurationReducer";
import { required } from "../../../services/validations/reduxFormValidation";
import { RenderCheckBox, RenderField, RenderTextArea } from "../../forms/bootstrapFields";

let SamlConfigurationForm = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const submitionError = useSelector((state) => state.samlConfigurationRed.submitionError);
  return (
    <Card className="h-100 panelContainer mt-15">
      <Card.Header className="d-flex">
        <i className="icon icon-password" />
        {t("companySettings.samlConfiguration.samlConfiguration")}
      </Card.Header>

      <Card.Body>
        <Form noValidate onSubmit={(formValues) => handleSubmit(formValues)}>
          <Col md="12">
            <p> {t("companySettings.samlConfiguration.enterConfiguration")}</p>
            {submitionError && (
              <span className="error-txt-color">{t("companySettings.samlConfiguration.errorConfiguration")}</span>
            )}
          </Col>
          <Col md="12">
            <Field
              name="user.current_password"
              label={t("companySettings.samlConfiguration.currentPassword")}
              component={RenderField}
              id="user-current_password"
              type="password"
              validate={[required]}
              required
            />
          </Col>
          <Col md="12">
            <Field
              label={t("companySettings.samlConfiguration.idpEntityID")}
              component={RenderField}
              name="company.saml_config.idp_entity_id"
              id="company-idp_entity_id"
              validate={[required]}
              required
            />
          </Col>
          <Col md="12">
            <Field
              component={RenderField}
              label={t("companySettings.samlConfiguration.idpTargetUrl")}
              name="company.saml_config.idp_sso_target_url"
              id="company-idp_sso_target_url"
              validate={[required]}
              required
            />
          </Col>
          <Col>
            <Field
              label={t("companySettings.samlConfiguration.idpCertificate")}
              name="company.saml_config.idp_cert"
              component={RenderTextArea}
              type="textarea"
              rows="10"
              id="company-idp_cert"
            ></Field>
          </Col>
          <Col md={12}>
            <Field
              label={t("companySettings.samlConfiguration.oktaACSUrl")}
              name="company.saml_config.okta_acs_url"
              component={RenderField}
              disabled
              meta={{ touched: false }}
              id="company-acs_url"
            ></Field>
          </Col>
          <Col md={12}>
            <Field
              label={t("companySettings.samlConfiguration.oktaAudienceUrl")}
              name="company.saml_config.okta_audience_url"
              component={RenderField}
              disabled
              meta={{ touched: false }}
              id="company-audience_url"
            ></Field>
          </Col>
          <Col>
            <Field
              label={t("companySettings.samlConfiguration.directLoginDisabled")}
              name="company.saml_config.direct_login_disabled"
              component={RenderCheckBox}
              type="checkbox"
              id="company-password_login_enabled"
              value="false"
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <Button id="sso_submit_btn" type="submit">
              {t("save")}
            </Button>
          </Col>
        </Form>
      </Card.Body>
    </Card>
  );
};

SamlConfigurationForm = reduxForm({
  form: SAML_CONFIGURATION_FORM, // a unique identifier for this form
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(SamlConfigurationForm);

SamlConfigurationForm = connect((state) => ({
  initialValues: state.samlConfigurationRed.samlConfigurationForm,
}))(SamlConfigurationForm);

export default SamlConfigurationForm;
