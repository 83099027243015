import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import RestApi from "../../../../../providers/restApi";
import _ from "lodash";
import { WrappedFieldProps } from "redux-form";
import { useTypedSelector } from "reducers";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
const restApiService = new RestApi();

interface RcnPickerPropsType extends WrappedFieldProps {
  className?: string;
  label?: ReactNode;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  tooltip?: ReactNode;
}

const RcnPicker = ({
  className,
  disabled,
  input,
  label,
  multiple,
  required,
  tooltip,
  meta: { touched, error },
}: RcnPickerPropsType) => {
  const [options, setOptions] = useState<{ rcn_id: string; rcn_alias: string }[]>([]);
  const current_user = useTypedSelector((state) => state.user);

  const getRcnList = useCallback(async () => {
    try {
      if (current_user.company && current_user.company.id) {
        const res = await restApiService.get(
          `companies/${current_user.company.id}/get_rcn_list`,
          null,
          null,
          true,
          null,
          true,
        );
        if (
          res &&
          res.data &&
          _.isArray(res.data) &&
          _.isPlainObject(res.data[0]) &&
          res.data[0].hasOwnProperty("rcn_alias") &&
          res.data[0].hasOwnProperty("rcn_id")
        ) {
          setOptions(res.data);
        } else {
          setOptions([]);
        }
      }
    } catch (error) {}
  }, [current_user.company && current_user.company.id]);

  useEffect(() => {
    getRcnList();
  }, [getRcnList]);

  return (
    <Form.Group className="w-100">
      {label && <Form.Label>{label}</Form.Label>}
      {tooltip ?? ""}
      <Form.Control
        as="select"
        multiple={multiple}
        disabled={disabled}
        {...input}
        isInvalid={touched && !!error}
        isValid={touched && !error}
      >
        {options.map((option, index) => (
          <option key={index} value={option.rcn_id}>
            {option.rcn_alias}
          </option>
        ))}{" "}
      </Form.Control>
      {touched && error && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default RcnPicker;
