import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Field, getFormValues } from "redux-form";
import { RenderCheckBox } from "../../../../../../components/forms/bootstrapFields";
import { INTEGRATION_FORM_NAME } from "../../../../../../reducers/admin/integrationsReducer";
import { showPullPushSetting } from "../../../../../../services/integrationCommonSvc/integrationCommonSvc";

const allowAllFieldsErp = ["Accrualify Integration", "Accrualify Internal Service", "VISA", "Payoneer", "Acumatica"];

// eslint-disable-next-line no-sparse-arrays
const transactionSettings = [
  {
    name: "form.sync_vendor",
    label: "Vendor",
    system_names: [
      "Netsuite",
      "Quickbook",
      "Xero",
      "Intacct",
      "SAP On Premise",
      "Quickbooks Enterprise",
      "Spectrum",
      "Sage300C",
      "Deltek Vantagepoint",
      "Blackbaud",
      ...allowAllFieldsErp,
    ],
    application_types: [],
  },
  {
    name: "form.sync_vendor_employee",
    label: "Employee Vendor",
    system_names: [],
    application_types: [],
  },
  {
    name: "form.sync_contact",
    label: "Contact",
    system_names: ["Netsuite", "Quickbook", "Intacct", "Sage300C", ...allowAllFieldsErp],
    application_types: [],
  },

  {
    name: "form.sync_contact_employee",
    label: "Employee Contact Exp. Reimbursement",
    system_names: ["Netsuite", "Quickbook", "Intacct", "Sage300C", ...allowAllFieldsErp],
    application_types: [],
  },

  {
    name: "form.sync_purchase_order",
    label: "Purchase Order",
    system_names: [
      "Netsuite",
      "SAP On Premise",
      "SAP Cloud",
      "Quickbooks Enterprise",
      "Intacct",
      "Sage300C",
      "Quickbook",
      "Blackbaud",
      ...allowAllFieldsErp,
    ],
    application_types: [],
  },

  {
    name: "form.sync_invoice",
    label: "Invoice",
    system_names: [],
    application_types: [],
  },
  {
    name: "form.sync_expense_report",
    label: "Expense Report",
    system_names: [],
    application_types: [],
  },
  {
    name: "form.sync_accrual_entry",
    label: "Accrual",
    system_names: ["Netsuite", "Quickbook", "Xero", "Quickbooks Enterprise", "Sage300C", ...allowAllFieldsErp],
    application_types: [],
  },
  {
    name: "form.sync_payment",
    label: "Payment",
    system_names: [
      "Netsuite",
      "Quickbook",
      "Xero",
      "Intacct",
      "Quickbooks Enterprise",
      "Spectrum",
      "Sage300C",
      "Deltek Vantagepoint",
      "Blackbaud",
      ...allowAllFieldsErp,
    ],
    application_types: [],
  },
  {
    name: "form.sync_invoice_purchase",
    label: "Purchase",
    system_names: ["Expensify", ...allowAllFieldsErp],
    application_types: [],
  },
  {
    name: "form.sync_receipt",
    label: "Item Receipts",
    system_names: ["Netsuite", "Intacct", "Quickbooks Enterprise", "Sage300C", ...allowAllFieldsErp],
    application_types: [],
  },
  {
    name: "form.sync_purchase",
    label: "Card Purchase",
    system_names: [
      "Netsuite",
      "Quickbook",
      "Xero",
      "Intacct",
      "Quickbooks Enterprise",
      "Sage300C",
      "Blackbaud",
      ...allowAllFieldsErp,
    ],
    application_types: [],
  },
  {
    name: "form.sync_bank_card_statement",
    label: "Card Statement",
    system_names: [
      "Netsuite",
      "Quickbook",
      "Xero",
      "Quickbooks Enterprise",
      "Sage300C",
      "Blackbaud",
      ...allowAllFieldsErp,
    ],
    application_types: [],
  },
];

const TransactionSettings = () => {
  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));

  return (
    <Col lg="12">
      <Row>
        <Col lg="12" className="d-flex">
          <Form.Label className="mr-2">AI Sync Transaction Settings (Push to ERP):</Form.Label>
        </Col>
      </Row>
      <Row className="m-0">
        <Col md="12">
          <Field
            id="test_mode"
            component={RenderCheckBox}
            placeholder="Test Mode"
            name="form.test_mode"
            type="checkbox"
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: " Test Mode <small>(Only manual force push allowed)</small>",
                }}
              />
            }
          />
        </Col>
        {transactionSettings.map((setting, index) => {
          if (
            showPullPushSetting(
              form.system_name,
              form.application_type,
              setting.system_names,
              setting.application_types,
            )
          ) {
            return (
              <Col md="4" key={index}>
                <Field name={setting.name} component={RenderCheckBox} label={setting.label} type="checkbox" />
              </Col>
            );
          } else {
            return null;
          }
        })}
      </Row>
    </Col>
  );
};

export default TransactionSettings;
