import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "components/common/navigations/breadcrumb";
import { useHistory, useLocation } from "react-router";
import styles from "./payExpense.module.css";
import Panel from "components/common/panel/panel";
import { ColumnApi, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { useTypedSelector } from "reducers";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import { getPayExpenseReviewItemHeader } from "./paymentReviewItemHeader";
import { useDispatch, useSelector } from "react-redux";
import { change, getFormValues } from "redux-form";
import ClientDataGrid from "components/common/dataGrid/clientDataGrid/clientDataGrid";
import moment from "moment";
import { getCompanyDateFormat } from "services/general/dateSvc";
import CustomModal from "components/modals/customModal";
import BulkApprovalForm from "./bulkApprovalForm";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { saveDefaultOrder } from "services/common/gridService";
import { payReimbursementType } from "../../../../services/admin/expenses/payReimbursementType";
import payReimbursementSvc from "./payExpneseService";

const REVIEW_STATS_KEY: Record<string, string> = {
  totalEmployees: "Total Employees",
  totalDiscount: "Total Discount",
  totalExpensed: "Total Expenses",
  creationDate: "Creation Date",
  totalAmount: "Total Amount",
};

let REVIEW_STATS: Record<string, string | number> = {
  totalEmployees: 0,
  totalDiscount: 0,
  totalExpensed: 0,
  creationDate: "01/01/2024",
  totalAmount: 0,
};

const GRID_STORAGE_NAME = "ai.grid.payExpenseItemReview";

const PaymentRunReview = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const [reviewStats, setReviewStats] = useState(REVIEW_STATS);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [payout, setPayout] = useState<Record<string, payReimbursementType.payoutType>>({});
  const [errorMessage, setErrorMessage] = useState("");
  const [invoices, setInvoices] = useState<payReimbursementType.bulkInvoice[]>([]);
  const { showFilters, updateShowFilters } = useShowFilterState("listExpensesItemsFilter", true);
  const currentUser = useTypedSelector((state) => state.user);
  const checkBoxRef: any = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const payExpenseReviewForm: payReimbursementType.payExpenseReviewFormType = useSelector((state) =>
    getFormValues("payExpenseReviewForm")(state),
  );
  const selectedPayItems: payReimbursementType.payObject[] = payExpenseReviewForm?.selectedRows
    ? payExpenseReviewForm?.selectedRows
    : [];
  useEffect(() => {}, [location?.state]);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.setFilterModel(null);
    const filterInstance = params.api.getFilterInstance("expense_report.number");
    if (filterInstance) {
      filterInstance.setModel({
        filterType: "text",
        type: "blank",
      });
    }
    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: getRows });
  };
  const getRows = async (params: IServerSideGetRowsParams) => {
    try {
      if (selectedPayItems) {
        const result = selectedPayItems;
        params.success({
          rowData: payReimbursementSvc.decorateExpenseReport({ response: result }),
          rowCount: result.length,
        });
      }
    } catch (error) {
      params.fail();
    }
  };

  const gridHeaders = getPayExpenseReviewItemHeader({
    gridApi,
    gridColumnApi,
    currentUser,
    checkBoxRef,
  });

  const getReviewStats = () => {
    let stats = {
      totalEmployees: 0,
      totalDiscount: 0,
      totalExpensed: 0,
      creationDate: "01/01/2024",
      totalAmount: 0,
    };
    const employeeSet = new Set();

    selectedPayItems &&
      selectedPayItems.forEach((item) => {
        stats.totalExpensed++;
        if (item.is_discount_applicable) {
          stats.totalDiscount = stats.totalDiscount + item.amount_disc;
        }
        employeeSet.add(item.employee.id);
        stats.totalAmount += item.pay_amount;
      });
    stats.totalEmployees = employeeSet?.size ? employeeSet.size : 0;
    stats.creationDate = moment().format(getCompanyDateFormat(currentUser));

    setReviewStats(stats);
  };

  const routeBackToGrid = () => {
    history.push("/ap/pay_expense");
  };

  const cancelPaymentRun = () => {
    dispatch(change("payExpenseReviewForm", "selectedRows", []));
    routeBackToGrid();
  };

  useEffect(() => {
    if (selectedPayItems.length) {
      saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
    } else {
      routeBackToGrid();
    }
  }, []);

  useEffect(() => {
    if (selectedPayItems.length) {
      getReviewStats();
    } else {
      routeBackToGrid();
    }
  }, [selectedPayItems]);

  const bulkApprovalHandler = () => {
    if (gridApi) {
      gridApi.selectAll();
      let selectedRows: payReimbursementType.payObject[] = gridApi?.getSelectedRows();
      if (selectedRows.length < 1) {
        CreateNotification("No Invoice Selected", "Please select at least one Expense Report", NotificationType.danger);
        return;
      }

      let keepGoing = true;
      let newInvoices: payReimbursementType.bulkInvoice[] = [];
      let newPayout: Record<string, payReimbursementType.payoutType> = {};

      selectedRows.forEach((invoice) => {
        let is_pay_bill = true;
        if (keepGoing) {
          if (!invoice.is_validate_pay_now) {
            setErrorMessage(
              "All Selected Expenses Report(s): 1. status should be APPROVED or OPEN 2. has payment info 3. balance_outstanding > $0.00",
            );
            keepGoing = false;
            return;
          }

          if (!currentUser.company.has_payment_workflow && invoice.payment_method.payment_type === "international") {
            setErrorMessage("International Payment(s) can not be initiated without having payment workflow.");
            keepGoing = false;
            return;
          }

          const obj: payReimbursementType.bulkInvoice = {
            id: invoice.id,
          };
          if (is_pay_bill) {
            obj.amount = Number(invoice.pay_amount);
            obj.payment_submission_window = invoice.payment_submission_window;
          }

          newInvoices.push(obj);

          const paymentType = invoice.payment_method.payment_type.toLowerCase();
          const paymentTypeDisplay = `${invoice.payment_method.payment_type.toLowerCase()}_${invoice.currency_code}`;
          if (newPayout[paymentType]) {
            newPayout[paymentType].totalInvoice++;
            newPayout[paymentType].totalAmt += invoice.pay_amount;
            newPayout[paymentType].paymentTypeDisplay = paymentTypeDisplay;
            newPayout[paymentType].currencySymbol = invoice?.currency?.symbol;
            if (invoice.is_discount_applicable) {
              newPayout[paymentType].discountAmt += invoice.amount_disc;
            }
          } else {
            const discountAmt = invoice.is_discount_applicable ? invoice.amount_disc : 0;
            newPayout[paymentType] = {
              paymentMethod: "",
              successInvoiceCount: 0,
              totalAmt: invoice.pay_amount,
              successAmt: 0,
              discountAmt: discountAmt,
              totalInvoice: 1,
              currencyCode: invoice?.currency?.symbol,
              paymentTypeDisplay: paymentTypeDisplay,
              currencySymbol: invoice?.currency?.symbol,
            };
          }
        }
      });

      if (!keepGoing) return;
      console.log(newInvoices, "newInvoices");
      console.log(newPayout, "newPayout");

      setInvoices(newInvoices);
      setPayout(newPayout);
      setShowModal(true);
    }
    setShowModal(true);
  };

  const modalCloseHandler = () => {
    setShowModal(false);
    cancelPaymentRun();
  };

  return (
    <>
      <CustomModal
        show={showModal}
        onHide={() => setShowModal(false)}
        header={"Bulk Expense Report Run"}
        headerClass={"px-4"}
        size="lg"
        modalBodyClass={`${styles.approvalModalContent}`}
        contentClassName={`${styles.approvalModalContent}`}
        body={
          <BulkApprovalForm
            fromPage={"paymentReview"}
            payout={payout}
            invoices={invoices}
            onClose={modalCloseHandler}
          />
        }
        hideCloseButton={true}
      />
      <Container fluid>
        <Row>
          <Col className="mb-0">
            <Breadcrumb
              breadcrumbItems={[{ name: "Pay Expenses", link: `/ap/pay_expense` }]}
              trailLabel={"New Payment Run Details"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={`${styles.paymentReviewHeader} ${styles.paymentReviewTitle}`}>Review New Payment Run</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr className={`full-border`} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Panel cardClass={styles.payPanelWrapper} cardBodyClass={styles.payPanelBody}>
              <Row>
                {Object.keys(reviewStats).map((item) => (
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <div className={`${styles.reviewStatsContainer}`}>
                          <Col>{`${REVIEW_STATS_KEY[item]}`}</Col>
                          <Col>{`${reviewStats[item]}`}</Col>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Panel>
          </Col>
        </Row>
        <Row className={`px-3 mt-3 ${styles.reviewGrid}`}>
          <ClientDataGrid
            columnDefs={gridHeaders.columnDefs}
            defaultColDef={{
              resizable: true,
              filter: true,
              floatingFilter: showFilters,
            }}
            gridReady={gridReady}
            gridStorageName={GRID_STORAGE_NAME}
            domLayout="normal"
            rowData={selectedPayItems}
            pagination={false}
            cacheBlockSize={50}
            maxBlocksInCache={1}
          />
        </Row>
        <Row className={`${styles.reviewButtonsContainer}`}>
          <Col md={6}>
            <Button id="address_cancel_btn" variant="secondary" onClick={cancelPaymentRun} className="mx-2">
              Cancel Payment Run
            </Button>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <Button
              onClick={routeBackToGrid}
              className={`${styles.reviewButtonsAddMore}`}
              type="submit"
              id="address_submit_btn"
            >
              Add More Expense Report
            </Button>
            <Button onClick={bulkApprovalHandler} type="submit" id="address_submit_btn">
              Submit for Approval
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentRunReview;
