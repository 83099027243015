import React, { useEffect, useRef, useState } from "react";
import Panel from "../../common/panel/panel";
import styles from "./activities.module.css";
import { IDType } from "services/common/types/common.type";
import ActivityApis from "services/admin/activity/activityApis";
import useIsMounted from "components/common/hooks/useIsMounted";
import { ActivityType } from "services/admin/activity/activityType";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { formatToTz } from "services/general/dateSvc";
import { IUser } from "services/common/user/userTypes";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import CustomModal from "components/modals/customModal";
import style from "./activities.module.css";

type ActivitiesPropsType = {
  modelName: string;
  modelId: IDType;
};

const Activities = ({ modelId, modelName }: ActivitiesPropsType) => {
  const [collapse, setCollapse] = useState(true);
  const isMounted = useIsMounted();
  const [activitiesData, setActivitiesData] = useState<ActivityType.List>([]);
  const commentActivitiesData = activitiesData.filter((activity) => activity.activity_type === "COMMENT");
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const { t } = useTranslation();

  // this function is helper on the top of translation function (t) so don't have write components.admin.activities. multiple times
  const translateByLabel = (key: string) => t(`components.admin.activities.${key}`);
  const [showDetailActivityOpen, setShowDetailActivityOpen] = useState(false);
  const [showDetailActivityData, setShowDetailActivityData] = useState<ActivityType.ListItem>();
  const firstRender = useRef(true);

  const getActivities = async () => {
    try {
      const result = await ActivityApis.getActivities({
        filter: {
          activitable_type: modelName,
          activitable_id: modelId,
        },
      });
      if (isMounted.current) {
        let activitiesData = result.data;
        activitiesData = activitiesData.map((val) => {
          val.created_at = formatToTz(val.created_at, currentUser.company.tz, currentUser);
          if (val?.summary?.indexOf("SYSTEM-ERROR:") !== -1 && !currentUser.is_root) {
            val.summary = "";
          }
          return val;
        });

        const lastActivity = activitiesData[activitiesData.length - 1];
        if (
          Array.isArray(activitiesData) &&
          activitiesData.length > 0 &&
          String(lastActivity.status).toLowerCase() === "failed" &&
          firstRender.current
        ) {
          setCollapse(false);
          firstRender.current = false;
        }

        setActivitiesData(result.data);
      }
    } catch (error) {}
  };

  const checkLastRecordFailed = (first: boolean, activity: ActivityType.ListItem | undefined): boolean => {
    if (activity && first && activity?.status?.toLowerCase() === "failed") {
      return true;
    }
    return false;
  };

  const handleCollapse = () => {
    setCollapse((prev) => !prev);
    getActivities();
  };

  const showDetailActivity = (detailActivityData: ActivityType.ListItem) => {
    setShowDetailActivityData(detailActivityData);
    setShowDetailActivityOpen(true);
  };

  useEffect(() => {
    getActivities();
  }, []);

  return (
    <>
      {showDetailActivityOpen && (
        <CustomModal
          show={showDetailActivityOpen}
          onHide={() => setShowDetailActivityOpen(false)}
          header={<>{translateByLabel("details")}</>}
          body={
            <FormGroup>
              <Form.Label>{translateByLabel("description")}:</Form.Label>
              <br />
              <p className={style.detailModalDescription}>{showDetailActivityData?.description}</p>
              {showDetailActivityData?.raw_data && (
                <p className={style.detailModalDescription}>{showDetailActivityData?.raw_data}</p>
              )}
            </FormGroup>
          }
          size="lg"
          backdrop={true}
        ></CustomModal>
      )}

      <Panel
        header={
          <div className={styles.panelTitle + " d-flex justify-content-between"}>
            <div>
              <i className={`icon icon-integration-red`}></i> {translateByLabel("activities").toUpperCase()}
            </div>
            <div>
              <button
                className={`${collapse ? "icon-arrow-down" : "icon-arrow-up"} actionButtons`}
                onClick={() => handleCollapse()}
              ></button>
            </div>
          </div>
        }
        hideCardBody={collapse}
      >
        <Row>
          {commentActivitiesData.length > 1 && (
            <Col xl="12">
              <ul className={style.listStyleNone}>
                {commentActivitiesData.map((activity) => {
                  return (
                    <li key={activity.id}>
                      <div className={style.my6}>
                        <div className={style.profile}>
                          <img
                            className="rounded-circle"
                            src={activity.handle_avatar || require("./../../../assets/common.png")}
                            alt="User"
                          />
                        </div>
                        <div>
                          <h5>
                            {activity?.handle}
                            <small>{activity.created_at}</small>
                          </h5>
                          <p>{activity?.description}</p>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Col>
          )}
          {_.chain(activitiesData)
            .orderBy((activity) => activity.id)
            .reverse()
            .value()
            .filter((activity) => activity.activity_type !== "COMMENT")
            .map((activity, index) => {
              return (
                <Col xs="12" key={activity.id} className={"generalInfo " + style.activities + " " + style.marginTop6}>
                  <Row className={`${checkLastRecordFailed(index === 0, activity) ? style.colorRed : ""}`}>
                    {activity.summary?.length && activity.summary?.length > 1 && (
                      <Col xs="5" className={style.marginTop6}>
                        <dl className="dl-horizontal">
                          <dt>{translateByLabel("name")}</dt>
                          <dd>{activity.name}</dd>
                          <dt>{translateByLabel("date")}</dt>
                          <dd>{activity.created_at}</dd>
                          <dt>{translateByLabel("status")}</dt>
                          <dd>{activity.status}</dd>
                        </dl>
                      </Col>
                    )}
                    {activity.summary?.length && activity.summary?.length > 1 && (
                      <Col xs="5" className={style.summary}>
                        <dl>
                          <dt>{translateByLabel("summary")}</dt>
                          <dd className={style.preWrap}>
                            {activity.raw_data ? activity.description : activity.summary}
                          </dd>
                        </dl>
                      </Col>
                    )}
                    {activity?.summary?.length && activity?.summary?.length > 1 && (
                      <Col xs="1" className={style.marginTop6}>
                        <button
                          data-toggle="tooltip"
                          data-rowindex="0"
                          title="View"
                          onClick={() => showDetailActivity(activity)}
                          className="actionButtons icon-view"
                        ></button>
                      </Col>
                    )}
                  </Row>
                </Col>
              );
            })}

          {(!activitiesData || activitiesData.length < 1) && (
            <Col xs="12" className="text-center" ng-if="">
              {translateByLabel("noActivity")}
            </Col>
          )}
        </Row>
      </Panel>
    </>
  );
};

export default Activities;
