import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { selectReceiptDetail, setReceiptDetails, setShowReceiptLinker } from "reducers/admin/receiptsReducer";
import _ from "lodash";
import ComparativePreview from "./comparativePreview";
import style from "./receipts.module.css";
import { useTranslation } from "react-i18next";
import PurchasesApis from "services/admin/purchases/purchasesApis";
import { PurchasesTypes } from "services/admin/purchases/purchasesType";
import { companyDateFormat, format } from "services/general/dateSvc";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";
import ReceiptsApis from "services/admin/expenses/receipts/receiptsApis";
import { useDispatch } from "react-redux";
import LoadingBox from "components/common/loaders/loadingBox";
import useIsMounted from "components/common/hooks/useIsMounted";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { getPrimaryCurrencyCodeOfUser } from "reducers/userReducers";
import { formattedAmount } from "services/general/helpers";

const PurchaseLinker = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [purchases, setPurchases] = useState<PurchasesTypes.List>();
  const [selectedPurchase, setSelectedPurchase] = useState<PurchasesTypes.ListItem>();

  const sortDesendingByDatePurchases = _(purchases)
    .sortBy((purchase) => purchase?.transaction_date)
    .reverse()
    .value();

  const pendingPurchases = sortDesendingByDatePurchases?.filter((purchase) => purchase.status === "PENDING");
  const otherPurchases = sortDesendingByDatePurchases?.filter((purchase) => purchase.status !== "PENDING");
  const purchasesGroupedByPostedDate = _.groupBy(otherPurchases, (purchase) => purchase.transaction_date);

  const receiptDetails = useTypedSelector(selectReceiptDetail);
  const currentUser = useTypedSelector((state) => state.user);
  const userPrimaryCurrency = useTypedSelector(getPrimaryCurrencyCodeOfUser);
  const receiptCurrencyCode = receiptDetails?.form_data?.currency_code || userPrimaryCurrency;

  const getPurchases = async () => {
    try {
      setIsLoading(true);
      const result = await PurchasesApis.getPurchasesList({ filter: { status: ["NEW", "PENDING"] } });
      if (isMounted.current) {
        setPurchases(result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const leftSectionData: {
    header: string;
    imageUrl?: string;
    dataItems: { key: string; value?: any }[];
  } = {
    header: t("admin.pages.receipts.uploadReceiptSummary"),
    imageUrl:
      (_.isArray(receiptDetails?.assets_attributes) && receiptDetails?.assets_attributes[0]?.asset_expiring_url) || "",
    dataItems: [
      { key: t("admin.pages.receipts.merchantName"), value: receiptDetails?.form_data?.merchant ?? "N/A" },
      {
        key: t("admin.pages.receipts.transactionDate"),
        value: companyDateFormat(receiptDetails?.form_data?.date, currentUser) ?? "N/A",
      },
      {
        key: t("admin.pages.receipts.totalAmount"),
        value: formattedAmount(String(receiptDetails?.form_data?.total), receiptCurrencyCode, 2, true) ?? "N/A",
      },
      { key: t("admin.pages.receipts.note"), value: receiptDetails?.note ?? "N/A" },
    ],
  };

  const rightSectionData: {
    header: string;
    imageUrl?: string;
    dataItems: { key: string; value?: any }[];
  } = {
    header: t("admin.pages.receipts.selectedPurchase"),
    dataItems: [
      { key: t("admin.pages.receipts.merchantName"), value: selectedPurchase?.merchant ?? "N/A" },
      {
        key: t("admin.pages.receipts.transactionDate"),
        value: companyDateFormat(selectedPurchase?.transaction_date, currentUser) ?? "N/A",
      },
      {
        key: t("admin.pages.receipts.totalAmount"),
        value: formattedAmount(String(selectedPurchase?.amount), selectedPurchase?.currency_code, 2, true) ?? "N/A",
      },
      { key: t("admin.pages.receipts.note"), value: selectedPurchase?.memo ?? "N/A" },
    ],
  };

  const onSuccessFullLink = async () => {
    // refetch reciept details
    try {
      if (receiptDetails?.id) {
        const result = await ReceiptsApis.getReceipt(receiptDetails.id);
        dispatch(setReceiptDetails(result));

        // close receipt linker modal
        dispatch(setShowReceiptLinker(false));
      }
    } catch (error) {
      throw error;
    }
  };

  const linkPurchaseWithReceipt = async () => {
    try {
      if (receiptDetails?.id) {
        setIsSubmitting(true);
        const updatePayload = {
          documentable_type: "Purchase",
          documentable_id: selectedPurchase?.id,
          status: "MATCHED",
        };
        const result = await ReceiptsApis.editReceipt(receiptDetails?.id, updatePayload);
        CreateNotification(t("success"), t("admin.pages.receipts.successReceiptLinked"), NotificationType.success);
        await onSuccessFullLink();
        setIsSubmitting(false);
      }
    } catch (error) {
      CreateNotification(t("error"), t("admin.pages.receipts.errorReceiptLinked"), NotificationType.danger);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    getPurchases();
  }, []);

  return (
    <Row>
      <Col>
        <ComparativePreview leftSectionData={leftSectionData} rightSectionData={rightSectionData} />

        <Row className="my-3">
          <Col className={style.transactionsContainer}>
            <LoadingBox isLoading={isLoading}>
              <Row className={style.transactionHead + " px-0"}>
                <Col>
                  <i
                    style={{ height: 20, width: 20 }}
                    className="d-block float-left icon-time-clock-schedual mt-1 mr-2"
                  ></i>
                  {t("admin.pages.receipts.pendingTrans")}
                </Col>
              </Row>
              {pendingPurchases?.map((purchase) => (
                <Row key={purchase.id} className={style.transactionRow + " " + style.pending + " px-0 pr-3"}>
                  <Col md="1">
                    <Form.Check
                      onChange={() => setSelectedPurchase(purchase)}
                      type="radio"
                      name="selectedTransaction"
                    />
                  </Col>

                  <Col md="3" className={style.transactionDate}>
                    {format(purchase.transaction_date, currentUser.company.tz, "MMM DD, YYYY")}
                  </Col>

                  <Col md="6" className={style.vendorName}>
                    {purchase.merchant?.toUpperCase()}
                  </Col>
                  <Col md="2" className={style.transactionAmount}>
                    {purchase.amount &&
                      purchase.currency_code &&
                      formattedAmount(String(purchase.amount), purchase.currency_code, 2, true)}
                  </Col>
                </Row>
              ))}

              {Object.entries(purchasesGroupedByPostedDate).map((groupEntery) => {
                const [postedDate, purchasesOnPostedDate] = groupEntery;
                return (
                  <Fragment key={postedDate}>
                    <Row className={style.transactionHead + " px-0"}>
                      <Col>
                        {t("admin.pages.receipts.postedOn")}{" "}
                        {format(postedDate, currentUser.company.tz, "MMM DD, YYYY")}
                      </Col>
                    </Row>
                    {purchasesOnPostedDate?.map((purchase: PurchasesTypes.ListItem) => (
                      <Row key={purchase.id} className={style.transactionRow + " " + style.pending + " px-0 pr-3"}>
                        <Col md="1">
                          <Form.Check
                            onChange={() => setSelectedPurchase(purchase)}
                            type="radio"
                            name="selectedTransaction"
                          />
                        </Col>

                        <Col md="3" className={style.transactionDate + " text-break"}>
                          {format(purchase.transaction_date, currentUser.company.tz, "MMM DD, YYYY")}
                        </Col>

                        <Col md="6" className={style.vendorName + " text-break"}>
                          {purchase.merchant?.toUpperCase()}
                        </Col>
                        <Col md="2" className={style.transactionAmount + " text-break"}>
                          {purchase.amount &&
                            purchase.currency_code &&
                            currencySymbolRenderer(purchase.currency_code) + purchase.amount}
                        </Col>
                      </Row>
                    ))}
                  </Fragment>
                );
              })}
            </LoadingBox>
          </Col>
        </Row>

        <Row>
          <Col>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip id="button-tooltip-2">{t("admin.pages.receipts.transactionToolTip")}</Tooltip>}
            >
              {({ ref, ...triggerHandler }) => (
                <span {...triggerHandler} ref={ref} className={style.unableToLocate} role="button">
                  {t("admin.pages.receipts.unableToLocateTrans")}
                </span>
              )}
            </OverlayTrigger>
          </Col>

          <Col className="d-flex justify-content-end align-items-end">
            <Button
              disabled={!selectedPurchase || isSubmitting}
              onClick={linkPurchaseWithReceipt}
              className={style.linkButton}
            >
              {isSubmitting && <Spinner size="sm" className="mr-2" animation="border" />}
              {t("admin.pages.receipts.link")}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PurchaseLinker;
