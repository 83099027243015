import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";

class PromptApis {
  static async getPromptList() {
    try {
      const response: AxiosResponse<any> = await restApiService.get("prompts");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async addPrompt(payload: any) {
    try {
      const response: AxiosResponse<any> = await restApiService.post("prompts", null, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async editPrompt(id: string | number, payload: any) {
    try {
      const response: AxiosResponse<any> = await restApiService.patch("prompts/" + id, null, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deletePrompt(id?: string | number) {
    try {
      const response: AxiosResponse<any> = await restApiService.delete("prompts/" + id);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default PromptApis;
