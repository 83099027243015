import React from "react";
import { GridApi } from "ag-grid-community";
import BulkActionDropdown from "components/common/dataGrid/bulkActionDropdown";
import _ from "lodash";
import { Dropdown } from "react-bootstrap";
import { Can } from "services/authorization/authorization";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import ApprovalsApis from "services/admin/approvals/approvalsApis";
import { isDefined } from "services/general/helpers";
import { ApprovalType } from "services/admin/approvals/approvalsType";
import { isBindingName } from "typescript";
import gridService from "services/grid/gridSvc";

interface BulkActionsPropsType {
  gridApi: GridApi;
}

const BulkActions = ({ gridApi }: BulkActionsPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const submitExpenseReport = async (expenseReport: ExpenseReportTypes.ListItem) => {
    try {
      if (expenseReport.id) {
        const result = await ExpenseReportApis.submitExpenseReport(expenseReport.id);
        CreateNotification("Success", `Expense reports submitted`, NotificationType.success);
      }
    } catch (error) {
      CreateNotification("Error", "Error while submitting Expense Report", NotificationType.danger);
    }
  };

  const openExpenseReport = async (expenseReport: ExpenseReportTypes.ListItem) => {
    try {
      if (expenseReport.id) {
        const result = await ExpenseReportApis.editExpenseReport(expenseReport.id, {
          ...expenseReport,
          status: "OPEN",
        });
        CreateNotification("Success", `Expense reports submitted`, NotificationType.success);
      }
    } catch (error) {
      CreateNotification("Error", "Error while submitting Expense Report", NotificationType.danger);
    }
  };

  const bulkSubmitExpenseReports = () => {
    if (gridApi) {
      // get selected rows
      const selectedRows: ExpenseReportTypes.List = gridService.getServerSideSelectedRows(gridApi);
      if (selectedRows.length < 1) {
        return CreateNotification(
          "No Expense Reports Selected",
          "Please select at least one expense report",
          NotificationType.warning,
        );
      }
      // create three array nonNewOrDraftERs, policyViolationERs and missingDescriptionERs
      const nonNewOrDraftERs: ExpenseReportTypes.List = [];
      const policyViolationERs: ExpenseReportTypes.List = [];
      const missingDescriptionERs: ExpenseReportTypes.List = [];

      if (_.isArray(selectedRows)) {
        selectedRows.forEach((expenseReport) => {
          if (expenseReport.status !== "NEW" && expenseReport.status !== "DRAFT") {
            // iterate throught all selected row and check if expense report with non (New|Draft) and push in nonNewOrDraftERs
            nonNewOrDraftERs.push(expenseReport);
          } else if (
            _.isArray(expenseReport.violations_messages) &&
            expenseReport.violations_messages.length > 0 &&
            currentUser.company.expense?.enforce_policy_violations
          ) {
            // if status of ER in DRAFT or NEW status, in same iteration find expense report with policy voilation and put in policyViolationERs if enforce policy violation is true
            policyViolationERs.push(expenseReport);
          } else if (expenseReport?.policy?.enforce_er_description && !expenseReport.description) {
            missingDescriptionERs.push(expenseReport);
          }
        });
      }

      // if any of array have any record then show respective error for ER and abort the function
      if (nonNewOrDraftERs.length || policyViolationERs.length || missingDescriptionERs.length) {
        if (nonNewOrDraftERs.length > 0) {
          CreateNotification(
            "Expense Report Submission Failed",
            "Only expense reports in NEW or DRAFT status can be submitted.",
            NotificationType.danger,
          );
        }
        if (policyViolationERs.length > 0 && currentUser.company.expense?.enforce_policy_violations) {
          CreateNotification(
            "Expense Report Submission Failed",
            "Expense reports with policy violations cannot be submitted for approval. Please fix the violations and try again.",
            NotificationType.danger,
          );
        }
        if (missingDescriptionERs.length > 0) {
          CreateNotification(
            "Expense Report Submission Failed",
            "Expense report description is missing. Please add description and try again.",
            NotificationType.danger,
          );
        }
      } else {
        // else proceed for Submitting ERs.
        const promises: Promise<any>[] = [];
        if (currentUser.company.has_expense_report_workflow) {
          selectedRows.forEach((expenseReport) => {
            promises.push(submitExpenseReport(expenseReport));
          });
        } else {
          selectedRows.forEach((expenseReport) => {
            promises.push(openExpenseReport(expenseReport));
          });
        }

        Promise.allSettled(promises).then((arg) => {
          // refresh grid api
          gridApi.refreshServerSide();
        });
      }
    }
  };

  const is_approval_pending = (approval: ApprovalType.ApprovalType) => {
    if (approval == null || _.isUndefined(approval.aasm_state)) return false;
    if (approval.aasm_state == "sent") return true;
    if (approval.aasm_state == "viewed") return true;
    return false;
  };

  const sendApprovalReminder = async (approval: ApprovalType.ApprovalType, expenseReportNumber: string | undefined) => {
    try {
      if (approval.id) {
        const result = await ApprovalsApis.sendApprovalReminder(approval.id);
      }
    } catch (error) {
      CreateNotification(
        "Error",
        "Approval reminder for Invoice " +
          expenseReportNumber +
          " was not sent. Check if this Invoice has pending approvers.",
        NotificationType.danger,
      );
    }
  };

  const bulkSendApprovalReminder = () => {
    if (gridApi) {
      // get selected rows
      const selectedRows: ExpenseReportTypes.List = gridService.getServerSideSelectedRows(gridApi);
      if (selectedRows.length < 1) {
        return CreateNotification(
          "No Expense Reports Selected",
          "Please select at least one expense report",
          NotificationType.warning,
        );
      }
      // create fail array
      const fail: ExpenseReportTypes.List = [];

      selectedRows.forEach(async (expenseReport) => {
        const approval = isDefined(expenseReport) ? expenseReport.next_actionable_approver : undefined;
        if (approval && is_approval_pending(approval)) {
          sendApprovalReminder(approval, expenseReport.number);
        } else {
          fail.push(expenseReport);
        }
      });
      gridApi.refreshServerSide();
      if (fail.length) {
        fail.forEach((expenseReport) => {
          CreateNotification(
            "Error",
            "Approval reminder for Invoice " +
              expenseReport.number +
              " was not sent. Check if this Invoice has pending approvers.",
            NotificationType.danger,
          );
        });
      }
    }
  };

  return (
    <BulkActionDropdown topClassName="px-mr-16">
      <Can I="add" a="ExpenseReports" permissions={["addExpenseReports", "editExpenseReports"]}>
        <Dropdown.Item className="smallFont" onClick={() => bulkSubmitExpenseReports()}>
          Submit Expense Report
        </Dropdown.Item>
        <Dropdown.Item className="smallFont" onClick={() => bulkSendApprovalReminder()}>
          <i className="icon-dropdown-list icon-enevelope-o-grey" /> Send Approval Reminder
        </Dropdown.Item>
      </Can>
    </BulkActionDropdown>
  );
};

export default BulkActions;
