import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import styles from "../../vendors.module.css";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { change } from "redux-form";

type PaymentMethodInfoType = {
  paymentMethodsAttributes: any;
  handleEdit: (step: number) => void;
};

const PaymentMethodInfo = ({ paymentMethodsAttributes, handleEdit }: PaymentMethodInfoType) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();

  const changeToCamalCase = (key: string) => {
    if (key) {
      return key.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    }
  };

  const manipulatePaymentMethod = (data: any) => {
    const processData = { ...data };
    if (processData.address_attributes) {
      let obj = {
        address1: processData?.address_attributes?.address1,
        city: processData.address_attributes.city,
        country: processData.address_attributes.country,
        state: processData.address_attributes.state,
        zipcode: processData.address_attributes.zipcode,
      };
      processData.address_attributes = obj;
    }

    if (processData.cambridge) {
      let obj = {
        accountHolderAddress1: processData.cambridge.accountHolderAddress1,
        accountHolderCountry: processData.cambridge.accountHolderCountry?.name,
        accountHolderName: processData.cambridge.accountHolderName,
        accountNumber: processData.cambridge.accountNumber,
        bankAddressLine1: processData.cambridge.bankAddressLine1,
        bankCity: processData.cambridge.bankCity,
        bankCountry: processData.cambridge.bankCountry?.name,
        bankCurrency: processData.cambridge.bankCurrency,
        bankName: processData.cambridge.bankName,
        classification: processData.cambridge.setup_classification,
        destinationCountry: processData.cambridge.destinationCountry?.name,
        paymentMethods: processData.cambridge.paymentMethods === "W" ? "Wire" : "iACH",
        preferredMethod: processData.cambridge.preferredMethod,
        routingCode: processData.cambridge.routingCode,
        setup_classification: processData.cambridge.setup_classification,
        swiftBicCode: processData.cambridge.swiftBicCode,
        regulatoryRules: processData.cambridge.regulatoryRules?.field?.id,
      };
      processData.cambridge = obj;
    }
    return processData;
  };

  const handleEditPaymentMethod = () => {
    dispatch(change("VendorForm", "skipPaymentMethodStep", false));
    handleEdit(5);
  };

  return (
    <>
      <Row>
        <Col md="12">
          <i className={`icon icon-payments ${styles.detailPageIconWidth}`}></i>
          <span className={styles.title}>{t("admin.pages.vendor.addPaymentMethod")}</span>
          <div className="float-right">
            <Button
              variant="secondary"
              className={`px-mr-10 ${styles.btnEdit}`}
              onClick={() => handleEditPaymentMethod()}
            >
              <i className="icon icon-edit px-m-0"></i>
              {t("admin.pages.vendor.edit")}
            </Button>
            <span className={`float-right ${styles.iconToggle}`}>
              <i
                className={toggle ? "icon icon-arrow-up" : "icon icon-arrow-down"}
                onClick={() => setToggle(!toggle)}
              ></i>
            </span>
          </div>
        </Col>
      </Row>
      {toggle && _.isArray(paymentMethodsAttributes) && paymentMethodsAttributes.length > 0 && (
        <>
          {Object.entries(paymentMethodsAttributes).map(([record, field]) => {
            const manipulatedPaymentMethod = manipulatePaymentMethod(field);
            const objectKeys = Object.keys(manipulatedPaymentMethod);
            return (
              <>
                <Row key={record}>
                  {objectKeys.map((key) => {
                    const value = manipulatedPaymentMethod[key];
                    if (typeof value !== "object") {
                      return (
                        <Col md={4} key={key}>
                          <label className={styles.pmInfoLabel}>
                            {t(`common.paymentMethod.${changeToCamalCase(key)}`)}:
                          </label>
                          <br />
                          <p>{value || null}</p>
                        </Col>
                      );
                    }
                    return null; // Omit non-object values
                  })}
                </Row>

                {objectKeys.map((key, index) => {
                  const value = manipulatedPaymentMethod[key];
                  if (typeof value === "object") {
                    return (
                      <Row key={`${record}-${key}`}>
                        <Col md={12} key={key}>
                          <label className={`${styles.pmInfoHeading} px-mt-25`}></label>
                          <br />
                          <Row key={`${record}-${key}-child-${key}`}>
                            {Object.entries(value).map(([subKey, subValue]) => {
                              if (typeof subValue !== "object") {
                                return (
                                  <Col md={4} key={`${subValue}-${key}-${index}`}>
                                    <label className={styles.pmInfoLabel}>
                                      {t(`common.paymentMethod.${changeToCamalCase(subKey)}`)}:
                                    </label>
                                    <br />
                                    <p className={styles.pmInfoValue} key={subKey}>
                                      {subValue || null}
                                    </p>
                                  </Col>
                                );
                              }

                              if (typeof subValue === "object") {
                                return (
                                  <Col md={4} key={`${subValue}-${key}-obj-${index}`}>
                                    <label className={styles.pmInfoLabel}>
                                      {t(`common.paymentMethod.${changeToCamalCase(subKey)}`)}:
                                    </label>
                                    <br />

                                    {Object.entries(value).map(([subChildKey, subChildValue]) => {
                                      <p className={styles.pmInfoValue} key={subChildKey}>
                                        {subChildValue || null}
                                      </p>;
                                    })}
                                  </Col>
                                );
                              }
                              return null; // Omit non-object values
                            })}
                          </Row>
                        </Col>
                      </Row>
                    );
                  }
                  return null;
                })}
              </>
            );
          })}
        </>
      )}
    </>
  );
};

export default PaymentMethodInfo;
