import React, { useState } from "react";
import { Row, Col, Form, Container, Button, Spinner } from "react-bootstrap";
import { change, Field, getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { BreadcrumbTrail } from "../../navigation/navigationLinks";
import { RootState, useTypedSelector } from "../../../reducers";
import { useTranslation } from "react-i18next";
import CustomerPicker from "../pickers/reduxFormPicker/customerPicker";
import style from "./creditMemo.module.css";
import InvoicePicker from "../pickers/reduxFormPicker/invoicePicker";
import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import { renderDatePicker, renderField, renderTextareaField } from "../../forms/fields";
import FileUploader from "../../common/fileUploader/fileUploader";
import _ from "lodash";
import { required } from "../../../services/validations/reduxFormValidation";
import Panel from "../../common/panel/panel";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

const VENDOR_CREDIT_MEMO_FORM = "vendorCreditMemoForm";

export type FormCreditMemoDataType = {
  form: {
    id?: number;
    assets: Array<File>;
    purchaser_id: string;
    vendor_credit_source_invoice_links?: number[];
    vendor_credit_source_invoice_links_attributes?: { invoice_id: number }[];
    currency_code: string;
    number: string;
    amount: string;
    payment_date: string;
    description: string;
  };
};

export type FormCreditMemoPropsType = {
  onSubmit: (formData: FormCreditMemoDataType) => void;
  isDisableFormBtn?: boolean;
  showAttachmentError?: boolean;
  setAttachmentError?: (value: boolean) => void;
};

interface ReduxFormCreditMemoPropsType
  extends FormCreditMemoPropsType,
    InjectedFormProps<FormCreditMemoDataType, FormCreditMemoPropsType> {}

const FormCreditMemo = (props: ReduxFormCreditMemoPropsType) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { handleSubmit, isDisableFormBtn, showAttachmentError, setAttachmentError } = props;
  const formValues: any = useSelector((state) => getFormValues(VENDOR_CREDIT_MEMO_FORM)(state));
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);

  const handleLinkInvoices = (values: any) => {
    if (_.isArray(values) && values.length > 0) {
      let array: { invoice_id: number }[] = [];
      array = values.map((value: number) => ({ invoice_id: value }));
      dispatch(change(VENDOR_CREDIT_MEMO_FORM, "form.vendor_credit_source_invoice_links_attributes", array));
    }
  };

  const uploadAttachments = (files: File[]) => {
    if (setAttachmentError && showAttachmentError) {
      setAttachmentError(false);
    }
    let parsedFiles = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    dispatch(change(VENDOR_CREDIT_MEMO_FORM, "form.assets", parsedFiles));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className={style.pageHeaderSection}>
        <Container fluid={true} className={"pt-4 pb-4 "}>
          <BreadcrumbTrail to={"/ar/credit_memos"} pageName={t("breadcrumbs.creditMemos")} />
          <Row>
            <Col sm="6">
              <h1>{t("creditMemoEditPage.submitCreditMemo")}</h1>
            </Col>
            <Col sm="6" className="text-right">
              <Button variant="secondary" disabled={isDisableFormBtn} onClick={() => history.push("/ar/credit_memos")}>
                {t("cancel")}
              </Button>
              <Button variant="primary ml-1" type="submit" disabled={isDisableFormBtn}>
                {isDisableFormBtn && (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                )}{" "}
                {t("submit")}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid={true} className="mt-4">
        <Row>
          <Col sm="4">
            <Field
              name="form.purchaser_id"
              label={t("details.customer")}
              component={CustomerPicker}
              isRequired={true}
              validate={[required]}
            />
          </Col>
          <Col sm="4">
            <Field
              name="form.vendor_credit_source_invoice_links"
              label={t("details.invoiceNumber")}
              component={InvoicePicker}
              purchaserId={formValues?.form?.purchaser_id}
              filter={{ status: "OUTSTANDING" }}
              onChange={handleLinkInvoices}
              isMulti={true}
              required={false}
            />
          </Col>
          <Col sm="4">
            <Field
              name="form.currency_code"
              component={CurrencyCodePicker}
              label={t("details.currencyCode")}
              currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
              required={true}
              validate={[required]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <Field
              name="form.number"
              type="text"
              component={renderField}
              label={t("details.creditMemoNumber")}
              isRequired={true}
              validate={[required]}
            />
          </Col>
          <Col sm="4">
            <Field
              name="form.amount"
              type="number"
              component={renderField}
              required={true}
              label={t("details.amount")}
              isRequired={true}
              validate={[required]}
            />
          </Col>
          <Col sm="4">
            <Field
              name="form.payment_date"
              type="number"
              component={renderDatePicker}
              label={t("details.creditMemoDate")}
              isRequired={true}
              validate={[required]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="8" className="mt-2">
            <Field
              name="form.description"
              label={t("details.additionalDetails")}
              component={renderTextareaField}
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mt-5">
            <div className="attachmentPanel">
              <Panel
                header={
                  <div className="flex-row-reverse">
                    <h3 className={"panel-heading align-self-center mb-0"}>
                      <i className="icon icon-documents"></i>
                      {t("details.documents")} <span className="color-red">*</span>
                    </h3>

                    <FileUploader showUploadBtn uploadAttachments={uploadAttachments} />
                  </div>
                }
              >
                {" "}
                <div>
                  {formValues?.form && _.isArray(formValues.form.assets) && formValues.form.assets.length > 0
                    ? formValues.form.assets.map((asset: File, index: number) => (
                        <span key={index} className={style.fileName}>
                          {asset && asset.name}
                        </span>
                      ))
                    : showAttachmentError && <span className="color-red">This field is required</span>}
                </div>
              </Panel>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

const ConnectFormCreditMemo = reduxForm<FormCreditMemoDataType, FormCreditMemoPropsType>({
  form: VENDOR_CREDIT_MEMO_FORM,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
  keepDirtyOnReinitialize: true,
})(FormCreditMemo);

export default ConnectFormCreditMemo;
