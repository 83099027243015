import React from "react";
import { ReportTemplateOptionType } from "../../../../services/admin/reports/templates/reportTemplateType";
import reportTemplateFilters from "./reportTemplateFilters";
import { AddReportPageModeType } from "services/admin/reports/reportTypes";

const ReportTemplateFilters = ({
  reportTemplateChosen,
  scheduled,
}: {
  reportTemplateChosen: ReportTemplateOptionType | undefined;
  scheduled?: boolean;
}) => {
  const renderReportTemplateFilters = (reportTemplateChosen: ReportTemplateOptionType | undefined) => {
    if (reportTemplateChosen && reportTemplateFilters[reportTemplateChosen.code] !== undefined) {
      const FiltersTagName = reportTemplateFilters[reportTemplateChosen.code];

      return <FiltersTagName reportTemplateChosen={reportTemplateChosen} scheduled={scheduled} />;
    }
    return <div></div>;
  };
  const renderReportTemplateTitle = () => {
    if (reportTemplateChosen) {
      return `${reportTemplateChosen.label} Filters`;
    } else {
      return "";
    }
  };

  return (
    { reportTemplateChosen } && (
      <>
        <h4>{renderReportTemplateTitle()}</h4>
        <br />
        {renderReportTemplateFilters(reportTemplateChosen)}
      </>
    )
  );
};

export default ReportTemplateFilters;
