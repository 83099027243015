import React, { useEffect } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import Panel from "../../../common/panel/panel";
import { useTranslation } from "react-i18next";
import { RenderField, RenderSelect } from "../../../forms/bootstrapFields";
import { useTypedSelector } from "../../../../reducers";
import {
  isValidBirthday,
  maxLength,
  minLength,
  noNegativeNumber,
  number,
} from "../../../../services/validations/reduxFormValidation";
import style from "./preferencesForm.module.css";
import { shallowEqual } from "react-redux";
import { IUser } from "services/common/user/userTypes";
import UserSvc from "services/common/user/userSvc";

export const USER_PREFERENCES_SETTINGS_FORM = "USER_PREFERENCES_SETTINGS_FORM";

export type PreferenceFormDataType = {
  contact: {
    delegate_id: number | null | undefined;
    language: string | null | undefined;
    delegate_start_date: string | null | undefined;
    delegate_end_date: string | null | undefined;
    phone: string | null | undefined;
  };
  security?: {
    security_1?: Date;
    security_2?: number;
  };
};

type PreferencePropType = {
  cardBodyClass?: string;
};
interface PreferenceFormPropType
  extends PreferencePropType,
    InjectedFormProps<PreferenceFormDataType, { cardBodyClass: string }> {}

const maxLength4 = maxLength(4);
const minLength4 = minLength(4);

const Perferences = ({ handleSubmit, cardBodyClass }: PreferenceFormPropType) => {
  const { t } = useTranslation();
  const currentContact = useTypedSelector((state) => state.userProfileRed.contact, shallowEqual);
  const currentUser = useTypedSelector((state) => state.user as IUser, shallowEqual);

  return (
    <Form name={USER_PREFERENCES_SETTINGS_FORM} noValidate onSubmit={handleSubmit}>
      <Panel
        header={
          <Row>
            <Col className="mt-2">
              <i className="icon icon-preferences m-0 mx-2"></i> {t("components.admin.preferencesForm.title")}
            </Col>
            <Col>
              <Row className="d-flex justify-content-end align-items-center">
                <Button size="sm" className="mr-3" type="submit">
                  {t("save")}
                </Button>
              </Row>
            </Col>
          </Row>
        }
        cardBodyClass={cardBodyClass}
      >
        <Row>
          <Col md="6">
            <Field
              id={"contact.language"}
              name={"contact.language"}
              component={RenderSelect}
              options={[
                { label: "English", value: "translation_en" },
                { label: "简体中文", value: "translation_cn" },
                // TODO: enable spanish on complete migration of admin portal
                // { label: "Spanish", value: "translation_es },
              ]}
              label={t("components.admin.preferencesForm.languagePreference")}
            />
          </Col>
          <Col md="6">
            <Field
              id={"contact.phone"}
              name={"contact.phone"}
              type="tel"
              component={RenderField}
              label={t("components.admin.preferencesForm.phoneNo")}
              validate={[number, noNegativeNumber]}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {currentContact?.currency_codes_str && (
              <Row className="row">
                <Col sm="12">
                  <Form.Label> {t("components.admin.preferencesForm.currencyCode")}</Form.Label>
                  <span>{currentContact.currency_codes_str}</span>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="6">
            <Field
              id={"security.security_1"}
              name={"security.security_1"}
              component={RenderField}
              label={t("components.admin.preferencesForm.security_1")}
              maxLength={5}
              validate={[isValidBirthday]}
            />
          </Col>
          {UserSvc.isAdminOrInternalUser(currentUser) && currentUser.company?.address?.country === "USA" ? (
            <Col md="6">
              <Field
                id={"security.security_2"}
                name={"security.security_2"}
                component={RenderField}
                label={t("components.admin.preferencesForm.security_2")}
                validate={[number, maxLength4, minLength4]}
                maxLength={4}
              />
            </Col>
          ) : null}
        </Row>
      </Panel>
    </Form>
  );
};

const PerferencesForm = reduxForm<PreferenceFormDataType, { cardBodyClass: string }>({
  form: USER_PREFERENCES_SETTINGS_FORM,
  enableReinitialize: true,
  touchOnChange: true,
  touchOnBlur: false,
})(Perferences);

export default PerferencesForm;
