import React from "react";
import SingleVendorPicker from "./SinglePicker";
import { VendorPickerType } from "./vendorPickerTypes";

type TVendorPicker = {
  id?: string;
  label?: string;
  isMulti?: boolean;
  name: string;
  required?: boolean;
  excludeStatuses?: string;
  modelData?: string;
  callBack?: (selected: VendorPickerType.TVendorOptions) => void;
};

const VendorPicker = (props: TVendorPicker) => {
  return (
    <>
      {props.isMulti ? (
        // <MultiVendorPicker/>         // TODO: Implement Multi Vendor Picker
        <></>
      ) : (
        <SingleVendorPicker {...props} />
      )}
    </>
  );
};

export default VendorPicker;
