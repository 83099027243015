import styles from "../templates.module.css";
import { ReportTemplateDetailType } from "../../../../../services/admin/reports/templates/reportTemplateType";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useMemo, useRef } from "react";
import { Field } from "redux-form";
import { RenderDatePicker } from "../../../../forms/bootstrapFields";
import { afterDate, beforeDate, required } from "../../../../../services/validations/reduxFormValidation";
import { ReportFilterType } from "../../../../../services/admin/reports/reportTypes";

export const StandardFilter = ({
  filters,
  label,
  startLabel,
  endLabel,
}: {
  filters: ReportFilterType | undefined;
  reportTemplate?: ReportTemplateDetailType;
  label?: string;
  startLabel?: string;
  endLabel?: string;
}) => {
  const startDateRef = useRef<Date>();
  const endDateRef = useRef<Date>();

  useEffect(() => {
    if (filters?.start_date) {
      startDateRef.current = filters?.start_date;
    }
  }, [filters?.start_date]);

  useEffect(() => {
    if (filters?.end_date) {
      endDateRef.current = filters?.end_date;
    }
  }, [filters?.end_date]);

  const beforeDateValidation = useMemo(() => beforeDate(endDateRef), []);
  const afterDateValidation = useMemo(() => afterDate(startDateRef), []);

  return (
    <>
      <Row className={`mx-0 font-weight-bold pb-2 ${styles.filterLabel}`}>{label}</Row>
      <Row className="m-0">
        <Col md={6} className="pr-1">
          <Field
            name="properties.filters.start_date"
            label={startLabel ? startLabel : "From Posting Date"}
            labelClassName="font-weight-bold"
            component={RenderDatePicker}
            transparentDisabled={false}
            validate={[beforeDateValidation, required]}
            valueRef={startDateRef}
          />
        </Col>
        <Col md={6} className="px-0 pl-1">
          <Field
            name="properties.filters.end_date"
            label={endLabel ? endLabel : "To Posting Date"}
            labelClassName="font-weight-bold"
            component={RenderDatePicker}
            transparentDisabled={false}
            validate={[afterDateValidation, required]}
            valueRef={endDateRef}
          />
        </Col>
      </Row>
    </>
  );
};
