import _ from "lodash";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { change, Field, getFormValues } from "redux-form";
import { COMPANY_PAYMENT_METHOD_FORM_NAME } from "../../../../reducers/admin/companyPaymentMethodFormReducer";
import AccountPicker from "../../../pickers/reduxFormPicker/accountPicker";
import BusinessUnitPicker from "../../../pickers/reduxFormPicker/businessUnitPicker";
import DepartmentPicker from "../../../admin/pickers/reduxFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reduxFormPickers/locationPicker";

const DebitCreditFields = () => {
  const currentUser = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { form } = useSelector((state) => getFormValues(COMPANY_PAYMENT_METHOD_FORM_NAME)(state));
  const onChangeDebitAcc = (value) => {
    if (!value) {
      dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "form.payment_debit_accounts_attributes[0]._destroy", 1));
    } else {
      if (
        form &&
        _.isArray(form.payment_debit_accounts_attributes) &&
        form.payment_debit_accounts_attributes[0]?._destroy
      ) {
        //here just removed _destroied property as reduxform will hangle on change for us
        delete form.payment_debit_accounts_attributes[0]._destroy;
        dispatch(
          change(
            COMPANY_PAYMENT_METHOD_FORM_NAME,
            "form.payment_debit_accounts_attributes[0]",
            form.payment_debit_accounts_attributes[0],
          ),
        );
      }
    }
  };

  const onChangeCreditAcc = (value) => {
    if (!value) {
      dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "form.payment_credit_accounts_attributes[0]._destroy", 1));
    } else {
      if (
        form &&
        _.isArray(form.payment_credit_accounts_attributes) &&
        form.payment_credit_accounts_attributes[0]?._destroy
      ) {
        //here just removed _destroied property as reduxform will hangle on change for us
        delete form.payment_credit_accounts_attributes[0]._destroy;
        dispatch(
          change(
            COMPANY_PAYMENT_METHOD_FORM_NAME,
            "form.payment_credit_accounts_attributes[0]",
            form.payment_credit_accounts_attributes[0],
          ),
        );
      }
    }
  };

  return (
    <Col md="12">
      <Row>
        <Col md="12">
          <Form.Label style={{ fontSize: "16px" }}>
            {t("companySettings.paymentMethodsSettings.paymentDebit")}
          </Form.Label>
          <hr style={{ margin: "0 0 10px 0", borderTop: "1px solid #e5e5e5" }} />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Field
            id="form.payment_debit_accounts_attributes[0].account_id"
            name="form.payment_debit_accounts_attributes[0].account_id"
            component={AccountPicker}
            isClearable={true}
            onChange={(value) => onChangeDebitAcc(value)}
            label={t("companySettings.paymentMethodsSettings.paymentDebitAccount")}
          />
        </Col>
        <Col md="4">
          <Field
            id="form.payment_debit_accounts_attributes[0].department_id"
            name="form.payment_debit_accounts_attributes[0].department_id"
            component={DepartmentPicker}
            onChange={(value) => onChangeDebitAcc(value)}
            label={t("companySettings.paymentMethodsSettings.departmentDebitAccount")}
          />
        </Col>
        <Col md="4">
          <Field
            id="form.payment_debit_accounts_attributes[0].business_unit_id"
            name="form.payment_debit_accounts_attributes[0].business_unit_id"
            component={BusinessUnitPicker}
            onChange={(value) => onChangeDebitAcc(value)}
            label={t("companySettings.paymentMethodsSettings.businessUnit")}
          />
        </Col>
        {currentUser?.company?.has_locations && (
          <Col md="4">
            <Field
              instanceId="form.payment_debit_accounts_attributes[0]-location_id"
              name="form.payment_debit_accounts_attributes[0].location_id"
              label="Location"
              component={LocationPicker}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md="12">
          <Form.Label style={{ fontSize: "16px" }}>
            {t("companySettings.paymentMethodsSettings.paymentCredit")}
          </Form.Label>
          <hr style={{ margin: "0 0 10px 0", borderTop: "1px solid #e5e5e5" }} />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Field
            id="form.payment_credit_accounts_attributes[0].account_id"
            name="form.payment_credit_accounts_attributes[0].account_id"
            component={AccountPicker}
            isClearable={true}
            onChange={(value) => onChangeCreditAcc(value)}
            label={t("companySettings.paymentMethodsSettings.paymentCreditAccount")}
          />
        </Col>
        <Col md="4">
          <Field
            id="form.payment_credit_accounts_attributes[0].department_id"
            name="form.payment_credit_accounts_attributes[0].department_id"
            component={DepartmentPicker}
            onChange={(value) => onChangeCreditAcc(value)}
            label={t("companySettings.paymentMethodsSettings.departmentCreditAccount")}
          />
        </Col>
        <Col md="4">
          <Field
            id="form.payment_credit_accounts_attributes[0].business_unit_id"
            name="form.payment_credit_accounts_attributes[0].business_unit_id"
            component={BusinessUnitPicker}
            onChange={(value) => onChangeCreditAcc(value)}
            label={t("companySettings.paymentMethodsSettings.businessUnit")}
          />
        </Col>
        {currentUser?.company?.has_locations && (
          <Col md="4">
            <Field
              instanceId="form.payment_credit_accounts_attributes[0]-location_id"
              name="form.payment_credit_accounts_attributes[0].location_id"
              label="Location"
              component={LocationPicker}
            />
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default DebitCreditFields;
