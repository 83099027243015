import React from "react";
import { FileTypePickerPropsType } from ".";
import { file_types } from "components/app.svc.Lookup";
import { Form } from "react-bootstrap";
import { Mandatory } from "components/forms/bootstrapFields";
import Select from "react-select";
import PickerErrorBlock from "../../pickerErrorBlock";
import { findSelectedMultiple, onBlur, onChange } from "services/general/helpers";

interface MultipleFileTypePickerPropsType extends FileTypePickerPropsType {
  fileTypes: { value: string; label: string }[];
}

const MultipleFileTypePicker = ({
  className,
  input,
  meta: { error, touched },
  inputId,
  label,
  tooltip,
  required,
  fileTypes,
}: MultipleFileTypePickerPropsType) => {
  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        value={findSelectedMultiple(input, fileTypes)}
        placeholder="search/select"
        onChange={(value) => onChange(input, value)}
        onBlur={() => onBlur(input, input.value)}
        inputId={inputId ?? "multipleFileTypePicker"}
        options={fileTypes}
        isMulti
        isClearable
        classNamePrefix="select"
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default MultipleFileTypePicker;
