import React, { useCallback, useEffect, useMemo, useState } from "react";
import { restApiService } from "../../../providers/restApi";
import { Form } from "react-bootstrap";

interface ShowFieldsPropsType {
  endpoint?: string;
  id?: number;
  fields?: string[];
  label?: string;
  className?: string;
  labelClassName?: string;
  formatter?: (object: { [key: string]: any }) => string;
  value?: string;
}

const DEFAULT_FORMATTER = (fields: string[]) => (object: { [key: string]: any }) => {
  const resultString: Array<any> = fields.map((f) => `${object[f] ? object[f] : ""}`);
  return resultString.join(" ");
};

export const ShowFields = ({
  endpoint,
  id,
  fields,
  className = "",
  formatter,
  label,
  labelClassName,
  value,
}: ShowFieldsPropsType) => {
  const [displayedFields, setDisplayedFields] = useState<string>();

  const defaultFormatter = useMemo(() => {
    return fields ? DEFAULT_FORMATTER(fields) : undefined;
  }, [fields]);

  const stringFormatter = formatter ?? defaultFormatter;

  const getFields = useCallback(async () => {
    if (!endpoint || !id || !stringFormatter) return;

    try {
      const res = await restApiService.get(`${endpoint}/${id}`, null, null, true, null, true);
      if (res && res.data) {
        setDisplayedFields(stringFormatter(res.data));
      }
    } catch (e) {
      console.log(e);
    }
  }, [endpoint, id, stringFormatter]);

  useEffect(() => {
    if (id) {
      getFields();
    }
  }, [getFields, id]);

  useEffect(() => {
    if (value) {
      setDisplayedFields(value);
    }
  }, [value]);

  return (
    <Form.Group>
      {label && <Form.Label className={labelClassName}>{label}</Form.Label>}

      <div className={className}>{displayedFields}</div>
    </Form.Group>
  );
};
