import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field, change } from "redux-form";
import { required } from "../../../forms/inputFields";
import _ from "lodash";
import CountryCurrencyCodePicker from "../../pickers/reduxFormPickers/countryCurrencyCodePicker";
import { Mandatory, RenderField, RenderSelect } from "../../../forms/bootstrapFields";
import { restApiService } from "../../../../providers/restApi";
import useIsMounted from "../../hooks/useIsMounted";
import { international_payment_countries } from "../../../app.svc.Lookup";
import { useDispatch } from "react-redux";
import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";
import { NotificationType, CreateNotification } from "../../../../services/general/notifications";

type CambridgePaymentMethodType = {
  modelName: string;
};

const CambridgePaymentMethod = ({ modelName }: CambridgePaymentMethodType) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [defaultDestinationCountry, setDefaultDestinationCountry] = useState<string>();
  const [defaultCurrency, setDefaultCurrency] = useState<string>();
  const [requiredFields, setRequiredFields] = useState([]);
  const [regulatoryRules, setRegulatoryRules] = useState([]);
  const [paymentMethodError, setPaymentMethodError] = useState<string>();
  const [defaultDestinationCountryOption, setDefaultDestinationCountryOption] = useState({});
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [cambridgeObj, setCambridgeObj] = useState({
    destination_country: "",
    currency: "",
    bank_country: "",
    payment_method: "",
    classification: "",
    type: "cambridge",
  });
  const [ibanField, setIbanField] = useState("");
  const [ibanValidationLoading, setIbanValidationLoading] = useState(false);
  const [ibanError, setIbanError] = useState("");

  const handleChangeDestinationCountry = (obj: any) => {
    setDefaultCurrency(obj.currency_code);
    setDefaultDestinationCountry(obj);
    setCambridgeObj((prev) => ({
      ...prev,
      destination_country: obj.country_code,
      bank_country: obj.country_code,
      currency: obj.currency_code,
    }));
  };

  const getRequiredFields = async () => {
    setIsLoading(true);
    try {
      const result = await restApiService.get("payment_methods/required_fields", cambridgeObj);
      setIsLoading(false);
      const CambridgeRules = result.data?.response?.rules;
      if (result.data?.response?.payment_method_error) {
        setPaymentMethodError(result.data?.response?.payment_method_error);
      } else {
        setPaymentMethodError("");
      }
      if (result.data?.response?.rules) {
        setRequiredFields(CambridgeRules);
        manipulateCambridgeRules(CambridgeRules);
      }
      setRegulatoryRules(result?.data?.response?.regulatoryRules);
    } catch (error) {
      throw error;
    }
  };

  const manipulateCambridgeRules = (CambridgeRules: any) => {
    const formattedCambridgeRules: any = {};
    CambridgeRules.map((rule: any) => {
      if (rule.defaultValue) {
        if (rule.id === "classification") {
          //Cambridge mix and matches classification uppercase lowercase so we have to manually uppercase the first letter
          let formattedValue = rule.defaultValue.charAt(0).toUpperCase() + rule.defaultValue.slice(1);
          formattedCambridgeRules[rule.id] = formattedValue;
          dispatch(change("VendorForm", `${modelName}.cambridge[${[rule.id]}]`, formattedValue));
        } else if (checkIfCountry(rule)) {
          //need to find our country object from lookupsvc
          let countryObject = international_payment_countries.find(function (c) {
            return c.code2 === rule.defaultValue;
          });
          if (countryObject) {
            formattedCambridgeRules[rule.id] = countryObject;
            if (formattedCambridgeRules["destinationCountry"]) {
              let updatedCountryObject = {
                ...countryObject,
                label: `${countryObject?.currency_code + " - " + countryObject.name}`,
                flagImage: require(`../../../../assets/flags/${countryObject.flag}`),
              };
              setDefaultDestinationCountryOption(updatedCountryObject); // Check here
              dispatch(change("VendorForm", `${modelName}.cambridge[${[rule.id]}]`, updatedCountryObject));
            }
          }
          if (formattedCambridgeRules["bankCountry"]) {
            formattedCambridgeRules["bankCurrency"] = countryObject;
          }
        } else {
          //for preferred method and payment methods fields we set the default of whatever the user has already chosen, be that iACH or Wire.
          //we do this because we will disable these fields as they are confusing for a user. The user doesnt understand to put the letter W or E in a field so we default it for them
          if (rule.id === "preferredMethod" || rule.id === "paymentMethods") {
            dispatch(change("VendorForm", `${modelName}.cambridge["paymentMethods"]`, cambridgeObj.payment_method));
            dispatch(change("VendorForm", `${modelName}.cambridge["preferredMethod"]`, cambridgeObj.payment_method));
          } else {
            //normal default rule
            formattedCambridgeRules[rule.id] = rule.defaultValue;
          }
        }
        return formattedCambridgeRules;
      }
    });
  };

  const setThisIban = (iban: string) => {
    setIbanField(iban);
  };

  const validateIban = async (regex: string, errorMessage: string) => {
    const myRegex = new RegExp(regex);
    if (!myRegex.test(ibanField)) {
      setIbanError(errorMessage);
    } else {
      setIbanError("");
      setIbanValidationLoading(true);
      try {
        const result = await restApiService.get("payment_methods/validate_iban", { iban: ibanField });
        const data = result.data;
        setIbanValidationLoading(false);
        dispatch(change("VendorForm", `${modelName}.cambridge["bankName"]`, data.bankName));
        dispatch(change("VendorForm", `${modelName}.cambridge["bankAddressLine1"]`, data.bankAddress));
        dispatch(change("VendorForm", `${modelName}.cambridge["bankCity"]`, data.bankCity));
        dispatch(change("VendorForm", `${modelName}.cambridge["swiftBicCode"]`, data.swiftBIC));
        dispatch(change("VendorForm", `${modelName}.cambridge["routingCode"]`, data.routingNumber));
        if (data.validationMessages.length > 0 && data.validationMessages[0].message != "Valid IBAN Structure") {
          setIbanError(data.validationMessages[0].message);
        }
      } catch (error) {
        CreateNotification("IBAN Search Error: ", `${error}`, NotificationType.danger);
        return;
      }
    }
  };

  const showOptions = (field: any) => {
    return field?.valueSet.map((item: any) => ({
      label: item["text"],
      value: item["id"],
    }));
  };

  const checkIfCountry = (field: any) => {
    return ["accountHolderCountry", "bankCountry", "destinationCountry"].includes(field?.id);
  };

  //some fields we dont want the user to mess with
  const checkIfDisabled = (rule: any) => {
    if (!rule) {
      return;
    }
    return ["preferredMethod", "paymentMethods"].includes(rule.id);
  };

  useEffect(() => {
    if (
      !cambridgeObj ||
      !cambridgeObj?.destination_country ||
      !cambridgeObj.bank_country ||
      !cambridgeObj.currency ||
      !cambridgeObj.payment_method ||
      !cambridgeObj.classification
    ) {
      return;
    }
    if (isMounted.current) {
      getRequiredFields();
    }
  }, [cambridgeObj]);

  return (
    <>
      <Row>
        <Col md="4">
          <Field
            name={`${modelName}.cambridge.destination_country`}
            component={CountryCurrencyCodePicker}
            label={t("common.paymentMethod.destinationCountry")}
            className="w-100"
            returnObject
            onChange={(obj: React.ChangeEvent<HTMLInputElement>) => handleChangeDestinationCountry(obj)}
            required
            code="code"
            validate={[required]}
          />
        </Col>
        <Col md="4">
          <Field
            name={`${modelName}.cambridge.bank_country`}
            component={CountryCurrencyCodePicker}
            label={t("common.paymentMethod.bankCountry")}
            className="w-100"
            defaultCountryOption={defaultDestinationCountry}
            returnObject
          />
        </Col>
        <Col sm="4">
          <Field
            name={`${modelName}.currency_code`}
            component={CurrencyCodePicker}
            FieldClassName="formField"
            placeholder={"Search/Select"}
            label={t("common.paymentMethod.currencyCode")}
            currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
            defaultValue={defaultCurrency}
            validate={[required]}
            required
          />
        </Col>
        <Col md="4">
          <Field
            name={`${modelName}.cambridge.setup_classification`}
            component={RenderSelect}
            FieldClassName="formField"
            options={[
              { value: null, label: "-- Select --" },
              { value: "business", label: "Business" },
              { value: "individual", label: "Individual" },
            ]}
            validate={[required]}
            required
            placeholder={"Search/Select"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCambridgeObj((prev) => ({ ...prev, classification: e.target.value }))
            }
            label={t("common.paymentMethod.classification")}
          />
        </Col>
        <Col md="4">
          <Field
            name={`${modelName}.cambridge.payment_method`}
            component={RenderSelect}
            FieldClassName="formField"
            options={[
              { value: null, label: "-- Select --" },
              { value: "W", label: "Wire" },
              { value: "E", label: "iACH" },
            ]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCambridgeObj((prev) => ({ ...prev, payment_method: e.target.value }))
            }
            validate={[required]}
            required
            placeholder={"Search/Select"}
            label={t("common.paymentMethod.paymentMethod")}
          />
        </Col>
        <Col md="4"></Col>
      </Row>
      <Row>
        {isLoading && (
          <Col md="4">
            <span>
              <Spinner animation="border" variant="secondary" size="sm" /> Loading required fields...
            </span>
          </Col>
        )}

        {!isLoading && !paymentMethodError && _.isArray(requiredFields) && requiredFields.length > 0 && (
          <>
            {requiredFields.map((field: any, index) => (
              <React.Fragment key={index}>
                {index <= requiredFields.length && (
                  <Col md="4">
                    <label>{field.label || field.id}</label>{" "}
                    {field.isRequired === "true" && <Mandatory required={field.isRequired === "true"} />}
                    {checkIfCountry(field) && (
                      <Field
                        name={`${modelName}.cambridge[${field.id}]`}
                        component={CountryCurrencyCodePicker}
                        className="w-100"
                        returnObject
                        required
                        code="code"
                        defaultCountryOption={defaultDestinationCountry}
                      />
                    )}
                    {!checkIfCountry(field) && field.valueSet.length === 0 && (
                      <>
                        <Field
                          name={`${modelName}.cambridge[${field.id}]`}
                          component={RenderField}
                          type="text"
                          required={field.isRequired === "true"}
                          disabled={checkIfDisabled(field)}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            field.label == "IBAN Number" && setThisIban(e.target.value)
                          }
                          // pattern={field.regEx && field.regEx.length > 0 ? field.regEx : ''}
                        />
                      </>
                    )}
                    {field.label === "IBAN Number" && (
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => validateIban(field.regEx, field.errorMessage)}
                      >
                        Search
                      </button>
                    )}
                    {field.label === "IBAN Number" && ibanError && (
                      <span style={{ color: "#a94442" }} className="px-m-10">
                        {ibanError}
                      </span>
                    )}
                    {field.valueSet.length > 0 && (
                      <Field
                        name={`${modelName}.cambridge[${field.id}]`}
                        component={RenderSelect}
                        FieldClassName="formField"
                        required={field.isRequired === "true"}
                        disabled={checkIfDisabled(field)}
                        options={showOptions(field)}
                      />
                    )}
                  </Col>
                )}
              </React.Fragment>
            ))}
          </>
        )}
        <br />
        {!isLoading && paymentMethodError && (
          <span style={{ color: "#a94442" }} className="px-m-10">
            {paymentMethodError}
          </span>
        )}
      </Row>
      <Row>
        {ibanValidationLoading && (
          <Col md="4">
            <span>
              <Spinner animation="border" variant="secondary" size="sm" /> Validating IBAN...
            </span>
          </Col>
        )}
      </Row>

      {!isLoading && !paymentMethodError && _.isArray(regulatoryRules) && regulatoryRules.length > 0 && (
        <>
          <Row>
            <>
              <Col md={12}>
                <p className="subTitle">Regulatory Rules</p>
              </Col>

              {regulatoryRules.map((field: any, index) => (
                <React.Fragment key={index}>
                  {index < regulatoryRules.length && (
                    <Col>
                      <label>{field.label || field.id}</label>
                      {field?.valueSet?.length === 0 && (
                        <Field
                          name={`${modelName}.cambridge.regulatoryRules[field.id]`}
                          component={RenderField}
                          FieldClassName="formField"
                          // required={field.isRequired ==='true'}
                          disabled={checkIfDisabled(field)}
                          // pattern="{{field.regEx && field.regEx.length > 0 ? field.regEx : ''}}"
                          // options={showOptions(field)}
                        />
                      )}
                      {field?.valueSet?.length > 0 && (
                        <Field
                          name={`${modelName}.cambridge.regulatoryRules[field.id]`}
                          component={RenderSelect}
                          FieldClassName="formField"
                          // required={field.isRequired ==='true'}
                          // disabled={checkIfDisabled(field)}
                          // pattern="{{field.regEx && field.regEx.length > 0 ? field.regEx : ''}}"
                          options={showOptions(field)}
                        />
                      )}
                    </Col>
                  )}
                </React.Fragment>
              ))}
            </>
          </Row>
        </>
      )}
    </>
  );
};

export default CambridgePaymentMethod;
