import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AbstractListPicker from "../abstractListPicker";
import styles from "./paymentTerms.module.css";
import { PaymentPickerType } from "./paymentTermsTypes";

type TPaymentTermPickerProps = {
  id?: string;
  name: string;
  objectName?: string;
  idPropertyKey?: string;
  required?: boolean;
  activeOnly?: boolean;
  items?: number;
  multiple?: boolean;
  label?: string;
  showInactiveStatus?: boolean;
  callBackFullObject?: (
    selected: PaymentPickerType.TPaymentTermsProps | PaymentPickerType.TPaymentTermsProps[] | null,
  ) => void;
};

const PaymentTermPicker2 = ({
  id,
  name,
  objectName = "terms",
  label,
  multiple = false,
  required = false,
  activeOnly = true,
  showInactiveStatus = true,
  callBackFullObject,
}: TPaymentTermPickerProps) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onMenuOpen = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const formatOptionLabel = useCallback(
    (option: PaymentPickerType.TPaymentTermsProps) => {
      if (!option.id) {
        return <div>{option.name}</div>;
      }

      return (
        <div className={styles.optionLabel}>
          <div className={styles.mainInfo}>
            <span className={`${styles.code} ${isDropdownOpen ? styles.codeBold : ""}`}>{option.code}</span>
            {option.is_discount_on_subtotal && <span>({t("components.common.pickers.paymentTerms.subtotal")})</span>}
          </div>
          {isDropdownOpen && (option.disc_rate || option.disc_days) && (
            <div className={styles.discountInfo}>
              {option.disc_rate && (
                <span className={styles.discountRate}>
                  {t("components.common.pickers.paymentTerms.discountRate")} {option.disc_rate}
                </span>
              )}
              {option.disc_days && (
                <span>
                  {t("components.common.pickers.paymentTerms.discountDays")} {option.disc_days}
                </span>
              )}
            </div>
          )}
        </div>
      );
    },
    [isDropdownOpen, t],
  );

  const params = useMemo(() => {
    return { ACTIVE: activeOnly ? "YES" : "NO" };
  }, [activeOnly]);

  return (
    <AbstractListPicker<PaymentPickerType.TPaymentTermsProps>
      id={id}
      fetchUrl="terms"
      name={name}
      objectName={objectName}
      label={label}
      params={params}
      required={required}
      multiple={multiple}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      formatOptionLabel={formatOptionLabel}
      callBackFullObject={callBackFullObject}
      showInactiveStatus={showInactiveStatus}
    />
  );
};

export default memo(PaymentTermPicker2);
