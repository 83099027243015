import React, { useEffect, useState } from "react";
import { BreadcrumbTrail } from "../../../navigation/navigationLinks";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../../common/hooks/useIsMounted";
import { Col, Container, Row } from "react-bootstrap";
import PageTitle from "../../../page/pageTitle";
import EditButton from "../../../buttons/editButton";
import RecurringInvoiceApis from "../../../../services/admin/invoices/recurringInvoice/recurringInvoiceApis";
import { RecurringInvoiceDetailType } from "../../../../services/admin/invoices/recurringInvoice/recurringInvoiceTypes";
import styles from "./recurringInvoice.module.css";
import TabNavigator from "../../../common/tabNavigator/tabNavigator";
import GeneralInfo from "./tabs/generalInfo";
import LineItems from "./tabs/lineItems";
import Expenses from "./tabs/expenses";
import GlImpact from "./tabs/glImapact";
import PaymentMethod from "./tabs/paymentMethod";
import ListInvoices from "./listInvoices";
import CollapsePanel from "../../../panel/collapsePanel";
import _ from "lodash";
import { AssetType, IDType, NavbarTabType } from "../../../../services/common/types/common.type";
import PicturePreview from "../../../widgets/fileUpload/picturePreview";
import { Can } from "../../../../services/authorization/authorization";
import ErrorBoundary from "../../../common/errorBoundary/errorBoundary";
import AttachmentSection from "../../../common/attachmentSection/attachmentSection";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { restApiService } from "../../../../providers/restApi";
import ActivityLog from "../../activityLog/activityLog";
import MetaDataShow from "components/metadata/metadataShow/metadataShow";

type FileDetails = {
  path?: string;
  lastModified?: number;
  lastModifiedDate?: string;
  name?: string;
  size?: number;
  type?: string;
  id?: IDType;
  webkitRelativePath?: string;
};

const tabData: NavbarTabType = [
  { activeTab: "generalInfo", label: "Invoice Info" },
  { activeTab: "lineItems", label: "Line Items" },
  { activeTab: "expenses", label: "Expenses" },
  { activeTab: "glImpact", label: "GL Impact" },
  { activeTab: "paymentMethod", label: "Payment Method" },
];

const DetailRecurringInvoice = () => {
  const [activeTab, setActiveTab] = useState<string>("generalInfo");
  const [recurringInvoice, setRecurringInvoice] = useState<RecurringInvoiceDetailType>();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [assets, setAssets] = useState<FileDetails[]>([]);

  const getRecurringInvoice = async () => {
    try {
      let response = await RecurringInvoiceApis.getRecurringInvoiceDetail(id);
      if (isMounted.current) {
        setRecurringInvoice(response);
        setAssets(response.assets_attributes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      getRecurringInvoice();
    }
  }, []);

  const submitAttachments = async (attachments: FileDetails[], invoiceId: string) => {
    var formData = new FormData();
    attachments.map((attachment: any, key: any) => {
      if (!attachment.id) {
        formData.append("recurring[assets_attributes[" + key + "]asset_file]", attachment);
      }
    });
    const response = await restApiService.patch("recurrings/" + id, null, formData);
    setAssets(response.data?.assets_attributes);
    CreateNotification(
      t("components.common.attachmentSections.attached"),
      t("components.common.attachmentSections.fileAttached"),
      NotificationType.info,
    );
  };

  const onUploadAttachment = (files: FileDetails[]) => {
    const assetsAttributes = [...assets, ...files];
    submitAttachments(assetsAttributes, id);
  };

  const onDeleteAttachment = async (assetId: IDType) => {
    const patchData = { recurring: { assets_attributes: { id: assetId, _destroy: 1 } } };
    const response = await restApiService.patch("recurrings/" + id, null, patchData);
    setAssets(response.data?.assets_attributes);
    CreateNotification(
      t("components.common.attachmentSections.removed"),
      t("components.common.attachmentSections.fileRemoved"),
      NotificationType.info,
    );
  };

  const changeTab = (tab: string) => {
    setActiveTab(tab);
  };
  return (
    <>
      {/* ---------header------------- */}
      <Container fluid={true} className={`pt-4 pb-2 ${styles.header}`}>
        <Row className="my-2 mx-0">
          <BreadcrumbTrail to="/ap/recurring_invoices" pageName="Recurring Invoice" title="Recurring Invoices" />
        </Row>
        <Row className="mt-3">
          <Col md={12} className="d-flex align-items-center justify-content-between">
            <PageTitle
              title={"Recurring - " + (recurringInvoice?.name ? recurringInvoice.name : "")}
              iconClass="icon icon-recurring mr-1 mt-2"
            />
            <div>
              {/* <ChatBox
                modelDataNumber={recurringInvoice?.name}
                modelDataId={recurringInvoice?.id ? recurringInvoice.id : 0}
                modelDataType="recurrings"
              /> */}
              <Can I="edit" a="Invoices">
                <EditButton buttonClasses="mx-2" text={t("edit.invoice")} href={"recurring_invoices/" + id + "/edit"} />
              </Can>
            </div>
          </Col>
        </Row>
      </Container>

      {/* ------body----------- */}
      <Container fluid={true} className="pb-4 pt-2 pl-2">
        <Row>
          <Col md="12">
            <TabNavigator activeTab={activeTab} handleTabChange={changeTab} tabData={tabData} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="12">
            {activeTab === "generalInfo" && <GeneralInfo recurringInvoice={recurringInvoice} />}
            {activeTab === "lineItems" && <LineItems invoice={recurringInvoice?.object_properties} />}
            {activeTab === "expenses" && <Expenses invoice={recurringInvoice?.object_properties} />}
            {activeTab === "glImpact" && <GlImpact invoice={recurringInvoice?.object_properties} />}
            {activeTab === "paymentMethod" && <PaymentMethod invoice={recurringInvoice?.object_properties} />}
          </Col>
        </Row>

        {recurringInvoice && recurringInvoice.metadata && (
          <Row>
            <Col lg="12">
              <MetaDataShow model={recurringInvoice} />
            </Col>
          </Row>
        )}

        <Row className="mt-1">
          <Col lg="12">
            <ListInvoices invoices={recurringInvoice?.invoices || []} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorBoundary>
              <AttachmentSection
                title={
                  <>
                    <i className="icon m-0 document" />
                    {t("documents").toUpperCase()}
                  </>
                }
                attachments={assets}
                onAttachmentUpload={(files: FileDetails[]) => onUploadAttachment(files)}
                onAttachmentDelete={(id: IDType) => onDeleteAttachment(id)}
                allowDelete
                allowAdd
              />
            </ErrorBoundary>
          </Col>
        </Row>
        <ErrorBoundary>
          <Row>
            <Col md={12}>
              <ActivityLog modelMethod="recurrings" modelActionId={id} />
            </Col>
          </Row>
        </ErrorBoundary>
      </Container>
    </>
  );
};
export default DetailRecurringInvoice;
