import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "animate.css";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, useHistory, useLocation } from "react-router-dom";
import "./App.css";
import "./app_icons.css";
import config from "./config";
import MainPage from "./mainPage";
import { removeUserDetails } from "./reducers/userReducers";
import { oktaService } from "./services/okta/oktaSvc";
import "./styles/print.styles.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const loading = () => {
  return null;
};

const oktaAuth = new OktaAuth({
  issuer: config.oktaIssuer,
  clientId: config.oktaClientId,
  scopes: ["openid", "profile", "email", "offline_access"],
  redirectUri: config.oktaRedirectUri,
});

oktaService.oktaAuth = oktaAuth;
oktaAuth.start();

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const dispatch = useDispatch();

  const redirectToLoginApp = () => {
    oktaAuth.tokenManager.clear();
    dispatch(removeUserDetails());
    let path = location.pathname == "/login" ? "" : location.pathname;
    window.location = config.oktaLoginURL + path;
  };

  const onAuthRequired = useCallback(async () => {
    console.log("on auth required...");
    await oktaService.shouldLogin(redirectToLoginApp);
  }, []);

  useEffect(() => {
    oktaAuth.tokenManager.on("renewed", oktaService.handleRenewal);
    oktaAuth.tokenManager.on("expired", oktaService.handleExpired);
    return () => {
      oktaAuth.tokenManager.off("renewed", oktaService.handleRenewal);
      oktaAuth.tokenManager.off("expired", oktaService.handleExpired);
      oktaAuth.stop();
    };
  }, []);

  return (
    <React.Suspense fallback={loading()}>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
            {/* We need to include like this since we cant get the login state as it needs to be parent of <Security> */}
            <MainPage oktaAuth={oktaAuth} />
          </Security>
        </Switch>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.Suspense>
  );
};

export default App;
