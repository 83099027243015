import React from "react";
import { Col, Row } from "react-bootstrap";
import style from "./receipts.module.css";
import CollapsePanel from "../../../panel/collapsePanel";
import { ReceiptsTypes } from "../../../../services/admin/expenses/receipts/receiptsType";
import receiptService from "../../../../services/admin/expenses/receipts/receiptsSvc";

type ReceiptStatusPanelPropType = {
  receiptDetails: ReceiptsTypes.Details;
};

const ReceiptStatusPanel = ({ receiptDetails }: ReceiptStatusPanelPropType) => {
  return (
    <Col md="12">
      {receiptDetails?.status &&
        [
          // only showing this ReceiptStatusPanel for given status
          receiptService.status.matchInProgress,
          receiptService.status.processing,
          receiptService.status.actionRequired,

          // for matched status it's will be hidden
        ].includes(receiptDetails?.status) && (
          <Row>
            <Col md="12">
              {receiptDetails?.status === receiptService.status.processing && (
                <CollapsePanel
                  iconClass={"icon mt-0 mr-2 icon-blue-oval-exclamation"}
                  heading={"Processing"}
                  open={true}
                >
                  Receipt is being processed
                </CollapsePanel>
              )}

              {receiptDetails?.status === receiptService.status.matchInProgress && (
                <CollapsePanel
                  iconClass={"icon mt-0 mr-2 icon-green-oval-exclamation"}
                  heading={"Match in Progress"}
                  open={true}
                >
                  Card transactions may take a few days to appear. Once they do, the system will attempt to match them
                  with uploaded receipts. In the meantime, you may manually link an existing card transaction or create
                  an expense item to link.
                </CollapsePanel>
              )}

              {receiptDetails?.status === receiptService.status.actionRequired && (
                <CollapsePanel
                  iconClass={"icon mr-2 icon-red-oval-exclamation"}
                  heading={"Action Required"}
                  open={true}
                >
                  The system was unable to match this receipt. Review the receipt so you can:
                  <ul className={style.actionStep}>
                    <li>Create a new expense item to link</li>
                    <li>Link it to an existing expense or card transactions</li>
                    <li>Deleting the receipt</li>
                  </ul>
                </CollapsePanel>
              )}
            </Col>
          </Row>
        )}
    </Col>
  );
};

export default ReceiptStatusPanel;
