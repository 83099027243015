import useCollapsible from "components/common/hooks/useCollapsible";
import Panel from "components/common/panel/panel";
import { DataTable } from "components/common/wrapperComponents";
import React, { useState } from "react";
import ApprovalRow from "./approvalRow";
import style from "./approval.module.css";
import { restApiService } from "providers/restApi";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { useTypedSelector } from "reducers";
import _ from "lodash";
import CustomModal from "components/modals/customModal";
import RequestApprovalForm from "./requestApprovalForm";
import { IDType } from "services/common/types/common.type";
import PreviewApprovalSteps from "./previewApprovalSteps";

type ApprovalPropsType = {
  allowAccess: boolean;
  endpoint: string;
  modalData: object;
  modelName: string;
  refreshApprovable: () => void;
  approvableType: string;
};

const Approval = ({
  allowAccess,
  endpoint,
  refreshApprovable,
  modalData,
  modelName,
  approvableType,
}: ApprovalPropsType) => {
  const isApprovalsPresent = "approvals" in modalData && _.isArray(modalData.approvals) && modalData.approvals.length;
  const { createConfirmModal } = useConfirmModal();
  const { collapsed, collapseHandlerUi } = useCollapsible({ defaultCollapse: !isApprovalsPresent });
  const currentUser = useTypedSelector((state) => state.user);
  const [requestApprovalModalOpen, setRequestApprovalModalOpen] = useState<boolean>(false);
  const [previewApprovalModalOpen, setPreviewApprovalModalOpen] = useState(false);

  const resetApproval = async () => {
    try {
      // here we not using Api service
      // enpoint is dynamic and can change as per module
      if ("id" in modalData) {
        const result = await restApiService.post(`${endpoint}/${modalData.id}/reset_approvals`);
        refreshApprovable();
      }
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        const { response } = error;
        if (typeof response === "object" && response !== null && "status" in response) {
          CreateNotification(
            "Failed with error code " + response.status,
            "Could not start approval workflow. Please double check the data and try again.",
            NotificationType.danger,
          );
        }
      }
    }
  };

  const confirmResetApproval = () => {
    createConfirmModal({
      title: "Confirm",
      body: "Are you sure you want to delete and resetart the approval workflow?",
      callBackData: null,
      saveCallBack: resetApproval,
      cancelCallBack: null,
    });
  };

  const startApproval = async () => {
    try {
      // here we not using Api service
      // enpoint is dynamic and can change as per module
      if ("id" in modalData) {
        const result = await restApiService.post(`${endpoint}/${modalData.id}/reset_approvals`);
        refreshApprovable();
        CreateNotification("Start", "Approval Start", NotificationType.success);
      }
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        const { response } = error;
        if (typeof response === "object" && response !== null && "status" in response) {
          CreateNotification(
            "Failed with error code " + response.status,
            "Could not start approval workflow. Please double check the data and try again.",
            NotificationType.danger,
          );
        }
      }
    }
  };

  const confirmStartApproval = () => {
    createConfirmModal({
      title: "Confirm",
      body: "Are you sure you want to start the approval workflow?",
      callBackData: null,
      saveCallBack: startApproval,
      cancelCallBack: null,
    });
  };

  const checkOnlyCorpayNvpWorkflowResettable = function () {
    if (
      currentUser.company.accepted_payment_methods.corpay &&
      currentUser.company.multiple_accepted_payment_methods &&
      "status" in modalData
    ) {
      return modalData.status !== "NEW" && modalData.status !== "SUBMITTED";
    } else {
      return true;
    }
  };

  const deleteApprovalWorkflow = async () => {
    try {
      if ("id" in modalData) {
        const response = await restApiService.post(`${endpoint}/${modalData.id}/delete_approvals`);
        refreshApprovable();
        CreateNotification("Deleted", "Approvals Deleted", "success");
      }
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        if (typeof error.response === "object" && error.response !== null && "status" in error.response) {
          CreateNotification(
            "Failed with error code " + error.response.status,
            "Could not start approval workflow. Please double check the data and try again.",
            "warning",
          );
        }
      }
    }
  };

  const confirmDeleteAllApproval = () => {
    createConfirmModal({
      title: "Confirm",
      body: "Are you sure you want to delete the approval workflow?",
      callBackData: null,
      saveCallBack: deleteApprovalWorkflow,
      cancelCallBack: null,
    });
  };

  return (
    <>
      {requestApprovalModalOpen && (
        <CustomModal
          show={requestApprovalModalOpen}
          size="lg"
          onHide={() => setRequestApprovalModalOpen(false)}
          header={<h3>Request Approval</h3>}
          body={
            <>
              {"id" in modalData && (
                <RequestApprovalForm
                  approvableType={approvableType}
                  approvableId={modalData.id as IDType}
                  closeRequestApproval={() => setRequestApprovalModalOpen(false)}
                  refreshApprovable={refreshApprovable}
                />
              )}
            </>
          }
        />
      )}

      {previewApprovalModalOpen && (
        <CustomModal
          show={previewApprovalModalOpen}
          size="lg"
          onHide={() => setPreviewApprovalModalOpen(false)}
          header={<h3>Workflow Preview</h3>}
          body={
            <>
              {"id" in modalData && <PreviewApprovalSteps endpoint={endpoint} approvableId={modalData.id as IDType} />}
            </>
          }
        />
      )}

      <Panel
        header={
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <i className="icon icon-approval-workflow mr-1"></i>
              APPROVAL WORKFLOW
            </div>
            <div className="d-flex">
              {!(!isApprovalsPresent || !allowAccess) && (
                <button
                  className={"btn " + style.approvalActionBtn}
                  onClick={() => confirmResetApproval()}
                  id="start_approval_workflow_restart"
                >
                  <i className="icon icon-sync-primary mt-1"></i>
                  Restart
                </button>
              )}

              {!(!checkOnlyCorpayNvpWorkflowResettable() || isApprovalsPresent || !allowAccess) && (
                <button
                  className={"btn " + style.approvalActionBtn}
                  onClick={() => confirmStartApproval()}
                  id="start_approval_workflow_button"
                >
                  <i className="icon icon-play-primary"></i>
                  Start Approval
                </button>
              )}

              {checkOnlyCorpayNvpWorkflowResettable() && allowAccess && (
                <button
                  id="request_approval_button"
                  className={"btn " + style.approvalActionBtn}
                  onClick={() => {
                    setRequestApprovalModalOpen(true);
                  }}
                >
                  <i className="icon icon-person-add-primary mt-1 mr-1" /> Request Approval
                </button>
              )}

              {allowAccess && (
                <button
                  className={"btn " + style.approvalActionBtn}
                  id="start_approval_workflow_button"
                  onClick={() => {
                    setPreviewApprovalModalOpen(true);
                  }}
                >
                  <i className="icon icon-search-primary"></i>Preview Workflow
                </button>
              )}

              {!(!isApprovalsPresent || !allowAccess) && (
                <button
                  className={"btn " + style.approvalActionBtn}
                  onClick={confirmDeleteAllApproval}
                  id="start_approval_workflow_delete"
                >
                  <i className="icon icon-delete"></i>
                  Delete Approval
                </button>
              )}
              <div className="my-auto mx-2">{collapseHandlerUi}</div>
            </div>
          </div>
        }
        hideCardBody={collapsed}
      >
        <DataTable striped={false} hover={false} className={"text-center " + style.approvalTable}>
          <thead>
            <tr>
              <th>Approval Steps</th>
              <th>Last Updated Date</th>
              <th>Approved Date</th>
              <th>Rejected Date</th>
              <th>Approvers</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {"approvals" in modalData &&
              _.isArray(modalData.approvals) &&
              modalData.approvals.map((approval) => {
                return (
                  <ApprovalRow
                    key={approval.id}
                    modalData={modalData}
                    endpoint={endpoint}
                    approval={approval}
                    allowAccess={allowAccess}
                    refreshApprovable={refreshApprovable}
                    modelName={modelName}
                  />
                );
              })}
          </tbody>
        </DataTable>
      </Panel>
    </>
  );
};

export default Approval;
