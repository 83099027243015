import React, { useEffect } from "react";
import { restApiService } from "../../../providers/restApi";

const AdminDashboard = () => {
  useEffect(() => {
    // Note: while developing it redirect to qa, so comment it, but don't commit it
    window.location.replace(restApiService.angularBaseURL() + "dashboard");
  }, []);
  return null;
};

export default AdminDashboard;
