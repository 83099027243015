import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";
import { RenderField } from "../../../forms/bootstrapFields";
import { minLength, required, charOnly } from "../../../../services/validations/reduxFormValidation";
import BsbCode from "../bsbCode";
import { AccountNumberValidator } from "../accountNumberValidator";
import BankAddressLine from "../bankAddressLine";
import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import _ from "lodash";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

const min8 = minLength(8);
const min9 = minLength(9);

const AusPayNetPaymentMethod = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);

  return (
    <>
      <Col md="4">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          required
          validate={[required, min8, charOnly]}
        />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
          required
          validate={[required]}
        />
      </Col>
      <Col md="4">
        <BsbCode modelName={`${modelName}`} />
      </Col>
      <Col md="4">
        <AccountNumberValidator
          modelName={`${modelName}`}
          minLen={4}
          maxLength={9}
          accountNumberType="australian-account-number"
          numOnly={true}
        />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.business_number`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.businessNumber")}
          tooltip="Australian business identification number issued by Australian Business Register, could be one of ACN, ABN or ARBN."
          maxLength={14}
          validate={[min9]}
        />
      </Col>
      <Col sm="4">
        <Field
          name={`${modelName}.currency_code`}
          component={CurrencyCodePicker}
          FieldClassName="formField"
          placeholder={"Search/Select"}
          label={t("common.paymentMethod.currencyCode")}
          currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
          defaultValue={"AUD"}
        />
      </Col>
      <BankAddressLine modelName={`${modelName}`} defaultCountry="AUS" isCountryRequired={true} />
    </>
  );
};

export default AusPayNetPaymentMethod;
