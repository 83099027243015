import { RenderSelect } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { required } from "../../../../services/validations/reduxFormValidation";
import { useTranslation } from "react-i18next";

const AccountType = () => {
  const { t } = useTranslation();
  return (
    <Col md="6">
      <Field
        id="form.account_type"
        name="form.account_type"
        component={RenderSelect}
        options={[
          {
            value: "",
            label: t("companySettings.paymentMethodsSettings.selectAccountType"),
          },
          {
            value: "checking",
            label: t("companySettings.paymentMethodsSettings.checking"),
          },
          {
            value: "savings",
            label: t("companySettings.paymentMethodsSettings.saving"),
          },
        ]}
        type="select"
        label={t("companySettings.paymentMethodsSettings.accountType")}
        required
        validate={[required]}
      />
    </Col>
  );
};

export default AccountType;
