import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { change, SubmissionError } from "redux-form";
import {
  setCompanyPaymentMethodModalOpen,
  onHideCompanyPaymentMethodModal,
  COMPANY_PAYMENT_METHOD_FORM_NAME,
  setIsLoading,
} from "../../../reducers/admin/companyPaymentMethodFormReducer";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { createCompleteError } from "../../../services/general/reduxFormSvc";
import PaymentForm from "./form";
import RestApi from "../../../providers/restApi";
import { useTypedSelector } from "../../../reducers";
import {
  paymentMethodCompanyLinkParseFromAPI,
  paymentMethodCompanyLinkParseToAPI,
} from "../../../services/paymentMethods/paymentMethodCompanyLinks";

const restApiService = new RestApi();

const shouldUpdateCompanyData = (paymentType) => {
  // update array if any new payment Method will contian tranctionCode
  const formWithTransactionCode = ["ach", "can-eft", "aus-pay-net", "nz-eft", "sg-eft"];
  return formWithTransactionCode.includes(paymentType);
};

const Edit = ({ id, onUpdateCallBack }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onHide = useCallback(() => {
    dispatch(onHideCompanyPaymentMethodModal());
  }, []);

  const closeModal = () => {
    onHide();
  };

  const currentUser = useTypedSelector((state) => state.user);

  // it will fetch payment method using id
  const fetchPaymentMethodById = useCallback(async () => {
    dispatch(setIsLoading(true));
    const response = await restApiService.get(`payment_methods/${id}`);
    if (response.data) {
      dispatch(setIsLoading(false));
      paymentMethodCompanyLinkParseFromAPI(response.data);
      dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "form", response.data));

      // initialize form attribute
      if (response.data.payment_debit_accounts) {
        dispatch(
          change(
            COMPANY_PAYMENT_METHOD_FORM_NAME,
            "form.payment_debit_accounts_attributes",
            response.data.payment_debit_accounts,
          ),
        );
      }
      if (response.data.payment_credit_accounts) {
        dispatch(
          change(
            COMPANY_PAYMENT_METHOD_FORM_NAME,
            "form.payment_credit_accounts_attributes",
            response.data.payment_credit_accounts,
          ),
        );
      }
    }
  }, [dispatch, id]);

  const submit = async (paymentMethodFormData) => {
    try {
      if (paymentMethodFormData.form && paymentMethodFormData.form.id) {
        paymentMethodCompanyLinkParseToAPI(paymentMethodFormData.form, currentUser.company.id);
        const response = await restApiService.patch(`payment_methods/${paymentMethodFormData.form.id}`, null, {
          payment_method: { ...paymentMethodFormData.form },
        });
        CreateNotification(
          t("success"),
          t("companySettings.paymentMethodsSettings.paymentMethodUpdated", {
            id: paymentMethodFormData.form.id,
          }),
          NotificationType.success,
        );

        // these callback will let update is_primary for other payment
        // when we set these paymentMethod as primay then others with related type
        // shold set to non primary
        if (onUpdateCallBack) {
          onUpdateCallBack({
            isPrimarySet: response.data.is_primary,
            paymentMethod: response.data,
          });
        }

        closeModal();
      }

      // also update company default
      if (
        paymentMethodFormData.company_default &&
        paymentMethodFormData.form &&
        shouldUpdateCompanyData(paymentMethodFormData.form.payment_type)
      ) {
        const response = await restApiService.patch("company/default", null, {
          company_default: paymentMethodFormData.company_default,
        });
        CreateNotification(
          t("success"),
          t("companySettings.paymentMethodsSettings.companyDefaultUpdated"),
          NotificationType.success,
        );
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          throw new SubmissionError({
            form: completeErrorObj,
          });
        }
      }
    }
  };

  const getCompanyDefalt = useCallback(async () => {
    const response = await restApiService.get("company/default");
    dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "company_default", response.data));
  }, []);

  const initializePaymentForm = useCallback(() => {
    //fetching payment from id for editing
    fetchPaymentMethodById();
    getCompanyDefalt();

    // opening Modal
    dispatch(setCompanyPaymentMethodModalOpen(true));
  }, []);

  useEffect(() => {
    initializePaymentForm();
  }, []);

  return <PaymentForm onSubmit={submit} onHide={onHide} isEdit={true} />;
};

export default Edit;
