import _ from "lodash";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Panel from "../panel/panel";
import AttachmentPreviewer from "../attachmentPreview/AttachmentPreviewer";
import FileUploader from "../fileUploader/fileUploader";
import useCollapsible from "../hooks/useCollapsible";
import defaultAttachment from "../../../assets/default_attachment.png";

const AttachmentSection = ({
  attachments = [],
  onAttachmentDelete,
  onAttachmentUpload,
  allowDelete,
  allowAdd,
  title,
  collapsible = false,
}) => {
  const [showPreviewer, setShowPreviewer] = useState(false);
  const [previewFile, setPrevFile] = useState(null);
  const { t } = useTranslation();
  const { collapseHandlerUi, collapsed } = useCollapsible({ defaultCollapse: false });

  const showPrevie = (file) => {
    setPrevFile(file);
    setShowPreviewer(true);
  };

  return (
    <>
      {showPreviewer && (
        <AttachmentPreviewer
          file={previewFile}
          isVisible={showPreviewer}
          closePreviewer={() => setShowPreviewer(false)}
        />
      )}
      <Row>
        <Col xs="12">
          <Panel
            header={
              <div className="d-flex justify-content-between">
                <div className="align-self-center">
                  {title ?? (
                    <>
                      <i className="icon m-0 icon-attachments" />
                      {t("components.common.attachmentSections.attachment")}
                    </>
                  )}
                </div>
                {allowAdd && (
                  <Row>
                    <div className="mx-2">
                      <FileUploader showUploadBtn uploadAttachments={onAttachmentUpload} />
                    </div>
                    {collapsible && <div className="mx-4 mt-3">{collapseHandlerUi}</div>}
                  </Row>
                )}
              </div>
            }
            hideCardBody={collapsible && collapsed}
          >
            <Row>
              {_.isArray(attachments) &&
                attachments.map((attachment, index) => {
                  const imageUrl = attachment.asset_expiring_url || defaultAttachment;
                  return (
                    <div
                      key={attachment?.id ? attachment?.id : index}
                      className="show-image"
                      style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundRepeat: "no-repeat",
                        backgroundClip: "content-box",
                        backgroundSize: "100% 100%",
                        border: "1px solid #000",
                        width: 145,
                        height: 145,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        showPrevie(attachment);
                      }}
                    >
                      <span className="file-name" title={attachment.asset_file_file_name}>
                        {attachment.asset_file_file_name}
                      </span>
                      <a
                        href={attachment.asset_expiring_url}
                        target="_blank"
                        download
                        className="download btn btn-primary btn-sm"
                        rel="noreferrer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i className="icon-sm icon-download" />
                      </a>
                      {allowDelete && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          className="btn btn-primary btn-sm delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            onAttachmentDelete(attachment.id, index);
                          }}
                        >
                          <i className="icon-sm icon-delete-white" role={"button"} />
                        </a>
                      )}
                    </div>
                  );
                })}
            </Row>
          </Panel>
        </Col>
      </Row>
    </>
  );
};

export default AttachmentSection;
