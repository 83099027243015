import { ColumnApi, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import React, { useCallback, useEffect, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { RootState, useTypedSelector } from "reducers";
import styles from "./dashboard.module.css";
import { getParams, saveDefaultOrder } from "services/common/gridService";
import { IUser } from "services/common/user/userTypes";
import { getCardPaymentActivitiesHeaders } from "./listCardPaymentActivitiesHeaders";
import { InputSelect } from "components/admin/pickers/inputs/inputSelect";
import { CardPaymentsApis } from "services/admin/cards/payments/cardPaymentsApis";

const GRID_STORAGE_NAME = "listCardPaymentActivities";

interface ListCardPaymentActivitiesPropsType {
  setShowPaymentID: (value: number) => void;
  accountDisplayOptions: { value: string; label: string }[];
  gridApiRef: { current: GridApi | undefined };
}

const BASE_PARAMS = { payment_types: ["CARD"] };

export const ListCardPaymentActivities = ({
  gridApiRef,
  setShowPaymentID,
  accountDisplayOptions = [],
}: ListCardPaymentActivitiesPropsType) => {
  const columnApiRef = useRef<ColumnApi>();
  const gridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    columnApiRef.current = params.columnApi;
    params.api.setServerSideDatasource({ getRows: getRows });
  }, []);
  const comdataAccountCodeChosen = useRef<string>("");
  const currentUser = useTypedSelector((state: RootState) => state.user, shallowEqual) as IUser | undefined;

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = useCallback(async (params: IServerSideGetRowsParams) => {
    let filterParams = {
      ...getParams(params),
      ...BASE_PARAMS,
      comdata_account_code: comdataAccountCodeChosen.current,
    };
    filterParams["sort"] = { ...{ created_at: { priority: 1, direction: "desc" }, ...filterParams["sort"] } };
    const res = await CardPaymentsApis.list({ filter: filterParams });
    try {
      params.success({
        rowData: res.data,
        rowCount: res.meta.count,
      });
    } catch (error) {
      params.fail();
    }
  }, []);

  const handleAccountDisplayChange = (input: { label: string; value: string }) => {
    comdataAccountCodeChosen.current = input?.value ?? "";
    gridApiRef.current?.refreshServerSide();
  };

  const gridHeaders = getCardPaymentActivitiesHeaders({
    currentUser,
    setShowPaymentID,
  });

  useEffect(() => {
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
  }, []);

  return (
    <>
      <Row className="mx-0 w-100 pb-2">
        <Col className="px-0"></Col>

        <Col md={5} className="px-0 d-flex align-items-center justify-content-center">
          <Form.Label className={`m-0 pr-2 text-nowrap ${styles.paymentActivityFilterLabel}`}>
            Account Display
          </Form.Label>
          <InputSelect
            className="m-0 w-100"
            floating
            options={accountDisplayOptions}
            onChange={handleAccountDisplayChange}
            placeholder="Payments for all active accounts"
          />
        </Col>

        <Col className="px-0"></Col>
      </Row>
      <Row className="mx-0 w-100" style={{ height: "500px" }}>
        <ServerSideDataGrid
          columnDefs={gridHeaders.columnDefs}
          defaultColDef={{
            resizable: true,
            filter: true,
            floatingFilter: false,
            suppressMovable: true,
          }}
          hideSideBar
          gridReady={gridReady}
          rowSelection="multiple"
          gridStorageName={GRID_STORAGE_NAME}
          domLayout="normal"
          paginationSize={25}
          paginationOptions={[25, 50, 100]}
          pagination
        />
      </Row>
    </>
  );
};
