import React, { useState } from "react";
import RestApi from "./../../../providers/restApi";
import vendorContactsGridHeader from "../../../components/vp/contacts/vendorContactsGridHeader";
import { Container, Row, Col, Button } from "react-bootstrap";
import VerifyDataTable from "./../../../components/vp/contacts/verifyDataTable/verifyDataTable";
import { Link } from "react-router-dom";
import ToggleFilterButton from "./../../../components/datagrid/buttons/toggleFilterButton";
import GridFilterDropdown from "./../../../components/datagrid/gridFilterDropdown";
import { Can } from "./../../../services/authorization/authorization";
import ClientDataGrid from "./../../../components/common/dataGrid/clientDataGrid/clientDataGrid";
import { GridReadyEvent } from "ag-grid-community";

const restApiService: RestApi = new RestApi();

const VendorContactsList = () => {
  const gridStorageName = "vendorContacts";
  const [gridApi, setGridApi] = useState<GridReadyEvent["api"]>();
  const [gridColumnApi, setGridColumnApi] = useState<GridReadyEvent["columnApi"]>();
  const [vendorContacts, setVendorContacts] = useState<Array<any>>([]);
  const [showFilter, setShowFitler] = useState<boolean>(false);

  const getContacts = () => {
    restApiService
      .get("contacts")
      .then((result) => {
        setVendorContacts(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    getContacts();
  };

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <Row className={"mb-4"}>
        <Col className="zIndex-1">
          <VerifyDataTable callback={getContacts} />
        </Col>
      </Row>

      <Row>
        <Col>
          <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} options={{}} />
          <ToggleFilterButton
            classes="float-right"
            clickCallback={() => {
              setShowFitler((prev) => !prev);
              gridApi?.refreshHeader();
            }}
          />

          <Link to="/ar/contacts/add">
            <Can I="add" a="Contacts">
              <Button variant="primary ml-2">
                <i className="btn-icon icon-add-btn"></i>
                Add Contact
              </Button>
            </Can>
          </Link>
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Row style={{ height: "70vh" }}>
        <ClientDataGrid
          columnDefs={vendorContactsGridHeader}
          gridReady={gridReady}
          rowData={vendorContacts}
          defaultColDef={{ floatingFilter: showFilter }}
          gridStorageName={gridStorageName}
        />
      </Row>
    </Container>
  );
};

export default VendorContactsList;
