import React, { useEffect, useState } from "react";
import { restApiService } from "../../../../providers/restApi";
import { useTypedSelector } from "../../../../reducers";
import { IUser } from "../../../../services/common/user/userTypes";
import NoficationSettingsRedux, {
  NOTIFICATION_SETTINGS_FORM,
  NoficationSettingFormDataType,
} from "./notificationSettingForm";
import { AxiosResponse } from "axios";
import { NotificationSettingType } from "./notificationSettings.type";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { change } from "redux-form";
import { useTranslation } from "react-i18next";
import { updateCurrentUser } from "../../../../reducers/userReducers";
import { useDispatch } from "react-redux";

const NotificationSettings = () => {
  const currentContact = useTypedSelector<IUser["contact"]>((state) => state.user.contact);
  const [notificationSettings, setNotificationSettings] = useState<Record<string, NotificationSettingType>>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Fetch notifications settings for this user
  const getNotificationsDetails = async () => {
    try {
      const { data }: AxiosResponse<{ notifications: Record<string, NotificationSettingType> }> =
        await restApiService.get(`contacts/${currentContact.id}/notifications`);
      setNotificationSettings(data.notifications);
    } catch (error) {
      console.log(error);
    }
  };

  // notifications setting fields is not present on
  // contact so updating contact for using it on redux form as inital state redux form
  const contactWithNotification = {
    real_time_notification_email: currentContact.real_time_notification_email,
    email_aggregation: currentContact.email_aggregation,
    notification_settings: notificationSettings,
  };

  const validateEmailNotificationValues = (
    notificationSettingsFormData: NoficationSettingFormDataType,
  ): NoficationSettingFormDataType => {
    if (!notificationSettingsFormData.email_aggregation && !notificationSettingsFormData.real_time_notification_email) {
      CreateNotification(
        t("components.admin.notificationSettingForm.emailNotification"),
        t("components.admin.notificationSettingForm.oneNotificationActive"),
        NotificationType.info,
      );
      notificationSettingsFormData.real_time_notification_email = true;
      // also update form showing to user
      change(NOTIFICATION_SETTINGS_FORM, "real_time_notification_email", true);
    }
    return notificationSettingsFormData;
  };

  const onNotificationSettingsFormSubmit = async (notificationSettingsFormData: NoficationSettingFormDataType) => {
    try {
      notificationSettingsFormData = validateEmailNotificationValues(notificationSettingsFormData);
      const response = await restApiService.patch("contacts/" + currentContact.id, null, {
        contact: notificationSettingsFormData,
      });
      CreateNotification(
        t("components.admin.notificationSettingForm.notifications"),
        t("components.admin.notificationSettingForm.notificationSettingsSaved"),
        NotificationType.success,
      );

      //now update user
      dispatch(updateCurrentUser());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNotificationsDetails();
  }, []);

  // until notification_settings data is not get fetched showing nothing
  if (!notificationSettings) {
    return null;
  }

  return (
    <NoficationSettingsRedux onSubmit={onNotificationSettingsFormSubmit} initialValues={contactWithNotification} />
  );
};

export default NotificationSettings;
