import CurrencySymbolRenderer from "components/admin/commonUsed/currencySymbolRenderer";
import Panel from "components/common/panel/panel";
import React, { useMemo } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import { IUser } from "services/common/user/userTypes";
import styles from "../inlineFieldsSection.module.css";
import InvoiceItemLine from "./invoiceItemLine";

const InvoiceItemLineSection = () => {
  const { control } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const { fields, append, update } = useFieldArray<InvoiceType.TInvoiceItem>({
    control,
    name: "invoice_items_attributes",
    keyName: "_id" as "id",
  });

  const invoiceItems = fields as unknown as InvoiceType.TInvoiceItem[];
  const invoiceCommonSvc = useInvoiceCommonSvc();

  // Calculate the number of columns dynamically
  const dynamicColumnCount = useMemo(() => {
    let columnCount = 9; // Actions, PO Line#, PO#, Item, Item Description, Qty, Unit Price, Account, Total Price (static columns)
    if (currentUser.company.has_taxes) columnCount += 2; // Tax Code, Tax
    if (currentUser.company.has_units) columnCount += 1; // Unit
    if (currentUser.company.allow_edit_invoice_item_subtotal) columnCount += 1; // Sub Amount
    if (currentUser.company.has_departments && !currentUser.company.invoice?.items?.department?.is_hide)
      columnCount += 1; // Department
    if (currentUser.company.has_locations && !currentUser.company.invoice_item_hide_location) columnCount += 1; // Location
    if (currentUser.company.has_business_units && !currentUser.company.invoice_item_hide_business_unit)
      columnCount += 1; // Business Unit
    if (currentUser.company.has_projects) {
      columnCount += 1; // Project
    }
    return columnCount;
  }, [currentUser]);

  const addNewRow = () => {
    let obj = { allowEdit: true, total: 0 };
    append(obj);
  };

  // const calculateItemsTotal = () => {
  //   const items = isItemsExist();
  //   return items.reduce((total, item) => total + (item.total || 0), 0);
  // };

  return (
    <Panel
      header={
        <span>
          <i className="icon px-m-0 icon-line-items"></i>Line Items
        </span>
      }
    >
      <Row>
        <Col sm="12" className="overflow-auto custom-overflow uploadQueueLines">
          <Table bordered size="sm">
            <thead>
              <tr className={styles.tableHead}>
                <td>Actions</td>
                <td>PO Line#</td>
                <td>PO#</td>
                <td>Item</td>
                <td>Item Description</td>
                {currentUser.company.has_taxes && <td>Tax Code</td>}
                {currentUser.company.has_units && <td>Unit</td>}
                <td>Qty</td>
                <td>Unit Price</td>
                {currentUser.company.allow_edit_invoice_item_subtotal && <td>Sub Amount</td>}
                {currentUser.company.has_taxes && <td>Tax</td>}
                <td>Account</td>
                {currentUser.company.has_departments && !currentUser.company.invoice?.items?.department?.is_hide && (
                  <td>Department</td>
                )}
                {currentUser.company.has_locations && !currentUser.company.invoice_item_hide_location && (
                  <td>Location</td>
                )}
                {currentUser.company.has_business_units && !currentUser.company.invoice_item_hide_business_unit && (
                  <td>Business Unit</td>
                )}
                {currentUser.company.has_projects && <td>Project</td>}
                <td>Total Price</td>
              </tr>
            </thead>
            <tbody>
              {invoiceItems.map((item, index) => (
                <InvoiceItemLine key={index} item={item} index={index} update={update} append={append} />
              ))}
              {invoiceItems.length < 1 && (
                <tr>
                  <td colSpan={dynamicColumnCount} className={styles.noRecords}>
                    Record not found!
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr className={styles.tableFooter}>
                <td colSpan={dynamicColumnCount - 1}>Total</td>
                <td className="text-right">
                  <CurrencySymbolRenderer name="currency_code" amount={invoiceCommonSvc.getItemsTotal()} />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="light" className={styles.btnAddNewEntry} onClick={() => addNewRow()}>
            <i className="icon icon-add-black"></i>Add Line Item
          </Button>
        </Col>
      </Row>
    </Panel>
  );
};

export default InvoiceItemLineSection;
