import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Button, Form, Image as BootImage, Row, Col, Spinner } from "react-bootstrap";
import style from "./photoPicker.module.css";
import { Ifile } from "../../../services/common/types/common.type";

type ProfilePhotoPickerPropsType = {
  disabled?: boolean;
  sourceUrl: string;
  onChangeImageCallBack: (file: File) => void;
  onCancelImageCallBack: () => void;
  onSaveImageCallBack: (saveSuccessCallback: () => void) => void;
  loading?: boolean;
};

const ProfilePhotoPicker = ({
  disabled = false,
  sourceUrl,
  onChangeImageCallBack = (file: File) => {},
  onCancelImageCallBack = () => {},
  onSaveImageCallBack = (saveSuccessCallback = () => {}) => {},
  loading,
}: ProfilePhotoPickerPropsType) => {
  const fileInputRef = useRef<any>(null);
  const [imageUrl, setImageUrl] = useState<string>(sourceUrl);
  const [newImage, setNewImage] = useState<string | null>();

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setNewImage(URL.createObjectURL(e.target.files[0]));
    onChangeImageCallBack(e.target.files[0]);
  };

  const onCancelImage = () => {
    setNewImage(null);
    onCancelImageCallBack();
  };

  const openFileDailoag = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    setImageUrl(sourceUrl);
  }, [sourceUrl]);

  const saveSuccessCallback = () => {
    setNewImage(null);
  };

  return (
    <Row>
      <Col md="12">
        <Row className="d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center profilePhotoWrapper border-dashed">
            {loading && (
              <Spinner
                className={style.spinner}
                animation="border"
                role="status"
                aria-hidden="true"
                variant="dark"
              ></Spinner>
            )}

            <BootImage
              src={newImage || imageUrl || require("../../../assets/images/common.png")}
              onError={({ currentTarget }) => {
                currentTarget.src = require("../../../assets/images/common.png");
              }}
              alt={"profileimage"}
            />
          </div>

          <Form.Control
            ref={fileInputRef}
            id="file-upload"
            accept="image/png, image/jpeg, image/jpg"
            className="d-none"
            type="file"
            onChange={handleImageChange}
            onClick={(event: any) => {
              event.target.value = null;
            }}
          />
        </Row>
        <Row className="d-flex justify-content-center align-items-center mt-2">
          {!newImage && (
            <Button
              id="file-upload"
              variant="secondary"
              onClick={openFileDailoag}
              disabled={disabled}
              className={style.profilePickerButton}
            >
              <i className="icon pointer icon-camera m-0" />
            </Button>
          )}

          {newImage && (
            <>
              <Button
                variant="secondary"
                onClick={onCancelImage}
                disabled={disabled || loading}
                className={"m-2 btn-icon-notext-offset " + (disabled || loading ? "not-allowed" : "")}
              >
                <i className={"icon icon-remove-avtar m-0 " + (disabled || loading ? "not-allowed" : "pointer")} />
              </Button>
              <Button
                onClick={() => onSaveImageCallBack(saveSuccessCallback)}
                variant="secondary"
                disabled={disabled || loading}
                className={"m-2 btn-icon-notext-offset " + (disabled || loading ? "not-allowed" : "")}
              >
                <i className={"icon icon-upload-avtar m-0 " + (disabled || loading ? "not-allowed" : "pointer")} />
              </Button>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ProfilePhotoPicker;
