import React, { ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { Field } from "redux-form";
import { PaymentFieldType } from "../types";
import { RenderField } from "../../../forms/bootstrapFields";
import { CHANGE_PAYMENT_METHOD_ADDRESS_2 } from "../../../../actions/actionTypes";

type Address2PropsType = PaymentFieldType;

const Address2 = ({ validations, required, label }: Address2PropsType) => {
  const dispatch = useDispatch();

  const changeAddress2: ChangeEventHandler<HTMLSelectElement> = (e) => {
    dispatch({ type: CHANGE_PAYMENT_METHOD_ADDRESS_2, payload: e.target.value });
  };

  return (
    <Field
      name="address2"
      label={label}
      component={RenderField}
      required={required}
      validate={validations}
      onChange={changeAddress2}
    />
  );
};

export default Address2;
