import React, { useState } from "react";
import { CardsDisputesFormState } from "../../../../../../services/admin/cards/disputes/cardDisputes.types";
import { Col, Row, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { dateStringWithoutTimezone } from "../../../../../../services/general/dateSvc";
import { getFormValues, change } from "redux-form";
import { RequiredFieldIcon } from "./requiredFieldIcon";
import { shallowEqual, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../../../reducers";
import Style from "./../dispute.module.css";

export const DuplicateDispute: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const purchaseDetail = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue.purchase;
  }, shallowEqual);

  const [selectedRadio, setSelectedRadio] = useState<number | null>(null);

  const handleRadioChange = (index: number, transactionId: string) => {
    setSelectedRadio(index);
    dispatch(change("CardDisputes", "form.options.ARN", transactionId));
  };

  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.option.duplicate.${key}`);
  };
  const t3 = (key: string) => {
    return t(`components.admin.disputes.common_table_headers.${key}`);
  };

  return (
    <>
      {(purchaseDetail?.duplicate_purchases?.length || 0) === 0 ? (
        <div className="text-danger">{t2("no_duplicates_error")}</div>
      ) : (
        <Col>
          <Row>
            {t2("description")}
            <RequiredFieldIcon></RequiredFieldIcon>
          </Row>
          <br />
          <Row>
            <div className={`${Style.table_container}`}>
              <Table bordered responsive striped size="lg">
                <thead>
                  <tr className={`${Style.table_header}`}>
                    <th>{t3("duplicate")}</th>
                    <th>{t3("posted")}</th>
                    <th>{t3("purchase_no")}</th>
                    <th>{t3("posted_amount")}</th>
                    <th>{t3("approval")}</th>
                    <th>{t3("mcc")}</th>
                    <th>{t3("name")}</th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseDetail?.duplicate_purchases?.map((item, index) => (
                    <tr key={index} style={{ whiteSpace: "nowrap" }}>
                      <td>
                        <input
                          type="radio"
                          name="duplicate_transaction"
                          onChange={() => handleRadioChange(index, item.arn)}
                        />
                      </td>
                      <td>{dateStringWithoutTimezone(item.transaction_date)}</td>
                      <td>{item.number}</td>
                      <td>{(item.amount || 0).toFixed(2)}</td>
                      <td>{item.approval_code}</td>
                      <td>{item.category}</td>
                      <td>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="merchant_tooltip">{item.merchant}</Tooltip>}
                        >
                          <div className={`${Style.ellipsis_merchant_name}`}>{item.merchant}</div>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Row>
          <br />
          <Row>{t2("reason_desc")}</Row>
        </Col>
      )}
    </>
  );
};

export const validate = () => {};
