import React from "react";
import DateFilter from "../reportFilters/date";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";
import InputFilter from "../reportFilters/input";

const BudgetDetailedListing = ({ scheduled }: { scheduled?: boolean }) => {
  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    } else {
      return (
        <>
          <DateFilter name={"Start Date"} code={"start_date"} options={[]} isRequired />
          <DateFilter name={"End Date"} code={"end_date"} options={[]} isRequired />
        </>
      );
    }
  };

  return (
    <div>
      {renderDateFilter()}
      <InputFilter name={"Department Name"} code={"department_name"} options={[]} />
      <InputFilter name={"Budget Code"} code={"code"} options={[]} />
    </div>
  );
};

export default BudgetDetailedListing;
