export const fedWireValidation = [
  {
    currency: "USD",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
      {
        field: "account_type",
        validate: { required: true, minlength: "0" },
        label: "Account Type",
        validMessage: "Success",
        errorMessage: "Sorry! Select any one Account Type",
      },
      {
        field: "routing",
        validate: { required: true, minlength: "9" },
        label: "Fedwire Routing",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Routing is required",
      },

      {
        field: "bank_name",
        validate: { required: true },
        label: "Bank Name",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Name is required",
      },
    ],
  },
];
