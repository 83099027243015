import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { PaymentTypes } from "./paymentsType";

class PaymentsApis {
  static async getEstimatedDebitDateList() {
    try {
      const response: AxiosResponse<PaymentTypes.ListsEstimateDebitDates> = await restApiService.get(
        "payments/estimated_debit_dates_summary",
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default PaymentsApis;
