import React from "react";
import { Col, Row } from "react-bootstrap";
import { required } from "../../../../../services/validations/reduxFormValidation";
import { useTypedSelector } from "../../../../../reducers";
import { getFormValues } from "redux-form";
import { MANAGE_PAYMENT_METHOD_FORM } from "../../../../../services/common/paymentMethod.svc";
import PaymentType from "../../fields/paymentType";
import CountryPaymentCurrencyCode from "../../../../common/managePaymentMethod/fields/countryCurrencyCode";
import SepaEur from "./eur";
import Address1 from "../../../../common/managePaymentMethod/fields/address1";
import City from "../../../../common/managePaymentMethod/fields/city";
import State from "../../../../common/managePaymentMethod/fields/state";
import ZipCode from "../../../../common/managePaymentMethod/fields/zipCode";
import Status from "../../../../common/managePaymentMethod/fields/status";
import { ManagePaymentMethodFormDataType } from "../../../../common/managePaymentMethod/types";
import Primary from "../../../../common/managePaymentMethod/fields/primary";

const SepaInternational = () => {
  const formData = useTypedSelector(
    (state) => getFormValues(MANAGE_PAYMENT_METHOD_FORM)(state) as ManagePaymentMethodFormDataType | null,
  );

  return (
    <>
      <Row>
        <Col md="4">
          <PaymentType label="Payment Type:" required validations={[required]} />
        </Col>
        <Col md="4">
          <CountryPaymentCurrencyCode label="Country Resides: " required validations={[required]} />
        </Col>
      </Row>
      {formData?.currency_code === "EUR" && <SepaEur />}
      <Row>
        <Col md="12">
          <Address1 label={"Bank Address line:"} />
        </Col>
        <Col md="4">
          <City label="City: " />
        </Col>
        <Col md="4">
          <State label="State/Province: " />
        </Col>
        <Col md="4">
          <ZipCode label="Zip Code/Postal Code: " />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Status label={"Status:"} />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex justify-content-end">
          <Primary label="Default for future invoice payment method" />
        </Col>
      </Row>
    </>
  );
};

export default SepaInternational;
