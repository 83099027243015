import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import PaymentMethods from "../../../paymentMethods/paymentMethods";
import StepsBar from "./stepsBar";
import ErrorBoundary from "../../../common/errorBoundary/errorBoundary";
import _ from "lodash";
import { useTypedSelector } from "../../../../reducers";
import { useDispatch } from "react-redux";
import { setVendorGuideStep } from "../../../../reducers/vp/vendorSetUpGuideReducer";
import { useTranslation } from "react-i18next";
import { IPurchaser } from "../../../../services/vp/types/purchasers.types";
import { getCompanyDetail } from "../../../../services/common/company/companySvc";
import { CompanyDetailType } from "../../../../services/common/company/companyTypes";
import { IPaymentMethod, paymentFormPayloadType } from "../../../../services/common/types/paymentMethods.types";
import {
  getRequiredPaymentMethod,
  isAllPurchasersSatisfied,
  returnPaymentMethodLabelFromValue,
} from "../../../../services/vp/payment/paymentMethodGuideSvc";
import style from "./dashboard.module.css";
import LoadingBox from "../../../common/loaders/loadingBox";

const PaymentMethodStep1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [company, setCompany] = useState<CompanyDetailType>();
  const purchasers: IPurchaser[] = useTypedSelector((state) => state.vendorSetupGuideRed.purchasers);
  const paymentMethods: IPaymentMethod[] = useMemo(() => company?.payment_methods ?? [], [company]);
  const [newAddedPaymentTypes, setNewAddedPaymentTypes] = useState<string[]>([]);
  const requiredPaymentMethods: string[] = useMemo(
    () => getRequiredPaymentMethod(purchasers, paymentMethods),
    [purchasers, paymentMethods],
  );
  const allPuchasersSatisfied: boolean = useMemo(
    () => isAllPurchasersSatisfied(purchasers, paymentMethods),
    [purchasers, paymentMethods],
  );
  const [rerenderPaymentMethodForm, setRerenderPaymentMethodForm] = useState(0);

  // Check  if all purchasers are satisfied
  // move to next step
  const checkPaymentInformation = () => {
    const onlyCorpayNVPPurchasers = purchasers.every((purchaser) => purchaser.has_only_corpay_nvp_integration === true);

    if (onlyCorpayNVPPurchasers || allPuchasersSatisfied) {
      // move to the next step
      dispatch(setVendorGuideStep(2));
    }
  };

  const toStep2 = () => {
    dispatch(setVendorGuideStep(2));
  };

  const resetPaymentMethodForm = async () => {
    // dispatch action for resettting payment method resetPaymentMethodForm
    dispatch({ type: "FULL_CLEAR_PAYMENT_METHOD" });
    // also render pm form
    setRerenderPaymentMethodForm((prev) => prev + 1);
    // check required payment methods
  };

  const paymentMethodSuccessCallback = async (paymentMethodPayload: paymentFormPayloadType) => {
    // adding new added payment types
    setNewAddedPaymentTypes([
      ...newAddedPaymentTypes,
      paymentMethodPayload?.company?.payment_methods_attributes[0].payment_type,
    ]);

    // after adding payment method successfully should reset pm form
    resetPaymentMethodForm();

    try {
      // update local company
      const response = await getCompanyDetail();
      setCompany((prev) => {
        return { ...prev, ...response.data };
      });
    } catch (error) {}
  };

  const initPaymentMethodStep = async () => {
    checkPaymentInformation();
  };

  const getCompany = async () => {
    try {
      const response = await getCompanyDetail();
      setCompany(response.data);
      setLoaded(true);
    } catch (error) {}
  };

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (loaded) {
      initPaymentMethodStep();
    }
  }, [loaded]);

  useEffect(() => {
    if (company) {
      if (!allPuchasersSatisfied) {
        resetPaymentMethodForm();
      }
    }
  }, [company]);

  return (
    <LoadingBox isLoading={!loaded}>
      <Row>
        <Col xl="12" className="d-flex flex-column justify-content-center align-items-center px-5">
          <Row>
            <Col className="my-4">
              <StepsBar />
            </Col>
          </Row>
          <Row>
            <h3>{t("vp.dashboard.vendorGuide.addPaymentMethod")}</h3>
          </Row>
          <Row className="my-3 mx-0">
            <p>{t("vp.dashboard.vendorGuide.pleaseAddAtLeast")}</p>
          </Row>

          <Row className="w-100">
            <Col xl="12">
              <ErrorBoundary>
                {_.isArray(purchasers) && purchasers.length > 0 && (
                  <PaymentMethods
                    key={rerenderPaymentMethodForm}
                    purchasers={purchasers}
                    isGuide
                    guideCallback={paymentMethodSuccessCallback}
                  />
                )}
              </ErrorBoundary>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <ErrorBoundary>
          <Col xl="12" className="px-5">
            <Row className="m-0 my-2">
              <Col xs="8">
                {newAddedPaymentTypes.length === 0 && (
                  <span className={style.paymentMethodRequiredText}>
                    {t("vp.dashboard.vendorGuide.provideYourPaymentMethods")}
                  </span>
                )}

                {newAddedPaymentTypes.length > 0 &&
                  _.isArray(requiredPaymentMethods) &&
                  requiredPaymentMethods.length > 0 && (
                    <span className={style.paymentMethodRequiredText}>{t("vp.dashboard.vendorGuide.almostThere")}</span>
                  )}
              </Col>
              <Col xs="4" />
            </Row>

            <Row className="m-0 my-2">
              <Col xs="8">
                <Row>
                  {newAddedPaymentTypes.map((method, index) => {
                    return (
                      <Col key={index} xs="12">
                        <span className="mx-3">
                          <i className="icon m-0 icon-oval-check mr-1" /> {returnPaymentMethodLabelFromValue(method)}
                        </span>
                      </Col>
                    );
                  })}
                </Row>
                <Row>
                  {requiredPaymentMethods.map((method, index) => {
                    return (
                      <Col key={index} xs="6">
                        <span className="mx-3">
                          <i className="icon m-0 icon-cross-oval mr-1" /> {returnPaymentMethodLabelFromValue(method)}
                        </span>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col xs="4" />
            </Row>
          </Col>
        </ErrorBoundary>
      </Row>
      <Row>
        {/* used for guide */}
        <Col xl="12">
          <Row className="mt-3 d-flex justify-content-center">
            <Button disabled={!allPuchasersSatisfied} onClick={() => toStep2()}>
              {t("next")}
            </Button>
          </Row>
        </Col>
      </Row>
    </LoadingBox>
  );
};

export default PaymentMethodStep1;
