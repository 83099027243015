import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { CompanyCurrencyCodesType, CompanyDetailType } from "./companyTypes";

class companyApis {
  static async getCompanyDetail() {
    try {
      const response: AxiosResponse<CompanyDetailType> = await restApiService.get("company");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async editCompanyDetail(data: any) {
    try {
      const response: AxiosResponse<CompanyDetailType> = await restApiService.patch("company", null, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getCompanyCurrencyCode() {
    try {
      const response: AxiosResponse<CompanyCurrencyCodesType> = await restApiService.get(
        "company/currency_codes",
        null,
        null,
        true,
        null,
        true,
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
export default companyApis;
