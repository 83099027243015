import { AxiosResponse } from "axios";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { CommonApis } from "services/admin/commonApis";
import { CommonType } from "services/admin/commonTypes";
import { AbstractListPickerTypes } from "../abstractListPicker/abstractListPickerTypes";
import { AccountPickerTypes } from "./accountPickerTypes";

function useAccountPickerHook(
  name: string,
  modelData?: string | null,
  parentObj?: string | null,
  accountGroupName?: string,
): [
  params: Record<string, any>,
  mergeInactive: AbstractListPickerTypes.TMergeInactiveFn<AccountPickerTypes.TAccountPickerOption>,
  getResponseData: (
    response: AxiosResponse<AccountPickerTypes.TAccountPickerOption[]>,
  ) => AccountPickerTypes.TAccountPickerOption[],
  formatOptionLabel: (data: AccountPickerTypes.TAccountPickerOption) => React.ReactNode,
] {
  const currentUser = useTypedSelector((state) => state.user);
  const { getValues, setValue, control } = useFormContext();

  // handle any conditional logic for watchable value in memo
  const categoryIdFieldName = useMemo((): string => {
    const field = "category_id";
    if (_.isNil(modelData)) return "";
    return modelData + field;
  }, [modelData]);

  const projectIdFieldName = useMemo((): string => {
    const field = "project_id";
    if (_.isNil(modelData)) return "";
    return modelData + field;
  }, [modelData]);

  const parentSubsidiaryIdFieldName = useMemo((): string => {
    const field = "subsidiary_id";
    if (_.isNil(parentObj)) return "";
    return parentObj + field;
  }, [parentObj]);

  const [categoryId, projectId, parentSubsidiaryId] = useWatch({
    name: [categoryIdFieldName, projectIdFieldName, parentSubsidiaryIdFieldName],
  });

  const mergeInactive: AbstractListPickerTypes.TMergeInactiveFn<AccountPickerTypes.TAccountPickerOption> = useCallback(
    async (currentId, accounts) => {
      let inactiveOption = null;
      if (currentId) {
        const account = Array.isArray(accounts) ? accounts.filter((account) => account?.id === currentId) : [];
        const onlyShowMappedAccount = !_.isNil(modelData) && getValues(modelData + "only_show_mapped_account");
        if (account.length === 0 && onlyShowMappedAccount) {
          setValue(modelData + "only_show_mapped_account", false);
          inactiveOption = null; // returning inactive options to child so that should set selected to null TODO: test mapping with accounts pickers
        } else if (account.length === 0) {
          try {
            const response: any = await CommonApis.getDetails<CommonType.TAccount>("accounts", currentId);
            inactiveOption = response;
          } catch (error) {
            inactiveOption = null;
          }
        } else {
          inactiveOption = account[0];
        }
      }
      return { inactiveOption };
    },
    [getValues, modelData, setValue],
  );
  const getParentObjParams = useCallback(
    (apiParams: any) => {
      if (currentUser.company.has_account_structures) {
        apiParams.account_structure = true;
        apiParams.subsidiary_id = parentSubsidiaryId;
      }
    },
    [currentUser?.company?.has_account_structures, parentSubsidiaryId],
  );

  const getModelDataParams = useCallback(
    (apiParams: any) => {
      const [category, project] = getValues(
        ["category", "project"].map((parentDependencyName) => modelData + parentDependencyName),
      );

      if (_.isPlainObject(project)) {
        if (project.project_type_id) {
          apiParams.project_type_id = project.project_type_id;
          setValue(modelData + "only_show_mapped_account", true);
        }
        if (project.has_accounts) {
          apiParams.project_id = projectId;
        }
      }

      if (_.isPlainObject(category) && category.is_category_map_to_account) {
        apiParams.category_id = categoryId;
      }
    },
    [categoryId, getValues, modelData, projectId, setValue],
  );

  const params = useMemo(() => {
    let apiParams: Record<string, any> = { status: "ACTIVE" };

    if (!_.isNil(parentObj)) {
      getParentObjParams(apiParams);
    }
    if (!_.isNil(modelData)) {
      getModelDataParams(apiParams);
    }
    if (accountGroupName && !apiParams.project_type_id) {
      apiParams.account_group = accountGroupName;
    }

    return apiParams;
  }, [parentObj, modelData, accountGroupName, getParentObjParams, getModelDataParams]);

  const formatOptionLabel = useCallback((option: AccountPickerTypes.TAccountPickerOption) => {
    return `${option.number ? option.number : ""} - ${option.name} ${option.account_type_name ? "-" + option.account_type_name : ""}`;
  }, []);

  const getResponseData = useCallback(
    (response) => {
      if (
        (params.project_id || params.category_id) &&
        Array.isArray(response?.data?.data) &&
        response?.data?.data.length == 1
      ) {
        setValue(name, response.data.data[0].id);
      }

      return response?.data?.data;
    },
    [name, params.category_id, params.project_id, setValue],
  );
  return [params, mergeInactive, getResponseData, formatOptionLabel];
}

export default useAccountPickerHook;
