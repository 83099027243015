import React from "react";
import Panel from "components/common/panel/panel";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { useTranslation } from "react-i18next";
import useCollapsible from "components/common/hooks/useCollapsible";
import { Col, Row } from "react-bootstrap";
import style from "./payExpenseModal.module.css";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";

type CurrencyExchangePropType = {
  expenseItem: ExpensesTypes.Details;
};

const CurrencyExchange = ({ expenseItem }: CurrencyExchangePropType) => {
  const { collapseHandlerUi, collapsed } = useCollapsible({ defaultCollapse: true });
  const { t } = useTranslation();
  // this function is helper on the top of translation function (t) so don't have write components.admin.activities. multiple times
  const translateByLabel = (key: string) => t(`components.admin.currencyExchange.${key}`);

  return (
    <Panel
      header={
        <div className={" d-flex justify-content-between"}>
          <div>
            <i className={`icon icon-policy-violation ${style.mr4}`}></i>{" "}
            {translateByLabel("currencyExchange").toUpperCase()}
          </div>
          {collapseHandlerUi}
        </div>
      }
      hideCardBody={collapsed}
    >
      <Row className={style.borderBottomGrey}>
        <Col xs={3}>
          <dl>
            <dt>{translateByLabel("originalCurrencyTotal")}</dt>
            {expenseItem.base_currency_tax && <dt>{translateByLabel("originalCurrencyTax")}</dt>}
            <dt>{translateByLabel("exchangeRate")}</dt>
          </dl>
        </Col>
        <Col xs={3}>
          <dl>
            <dd>
              {expenseItem?.base_currency_total} {currencySymbolRenderer(expenseItem.base_currency_code)}
            </dd>
            {expenseItem.base_currency_tax && (
              <dd>
                {expenseItem.base_currency_tax}
                {currencySymbolRenderer(expenseItem.base_currency_code)}
              </dd>
            )}
            <dd>
              {expenseItem.currency_rate} {expenseItem?.currency_code}/{expenseItem.base_currency_code}
            </dd>
          </dl>
        </Col>
      </Row>
    </Panel>
  );
};

export default CurrencyExchange;
