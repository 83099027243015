import _ from "lodash";
import { change } from "redux-form";
import PaymentRunGroupingPicker from "../../../../../../components/admin/pickers/reduxFormPickers/paymentRunGroupingPicker";
import CurrencyCodePicker from "../../../../../../components/common/pickers/reduxFormPickers/currencyCodePicker";
import {
  RenderCheckBox,
  RenderField,
  RenderFieldNumber,
  RenderSelect,
} from "../../../../../../components/forms/bootstrapFields";
import AccountPicker from "../../../../../../components/pickers/reduxFormPicker/accountPicker";
import AddressPicker from "../../../../../../components/pickers/reduxFormPicker/addressPicker";
import SubsidiaryPicker from "../../../../../../components/pickers/reduxFormPicker/subsidiaryPicker";
import TeamsPicker from "../../../../../../components/pickers/reduxFormPicker/teamPicker";
import TermsPicker from "../../../../../../components/pickers/reduxFormPicker/termsPicker/termsPicker";
import matching_variances from "../../../../../../lookups/matchingVariancesLookup";
import { required } from "../../../../../../services/validations/reduxFormValidation";
import { CompanyDefaultsTypes } from "../../settingsTypes";
import FunctionalCurrencyPicker from "./component/functionalCurrencyPicker";

export const generalSettings: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.global.allow_only_company_currency_codes",
    tooltip: {
      show: true,
      title:
        "It will allow user to select and show only specific currencies rather than showing all currency list in dropdown.",
      placement: "top",
    },
    label: "Allow Only Company Currency Code:",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.company_currency_codes",
    tooltip: {
      show: false,
    },
    label: "",
    type: "react-select",
    component: CurrencyCodePicker,
    isMulti: true,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.global?.allow_only_company_currency_codes;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
    customProps: (customPropsObj: any) => {
      return {
        currencyCodeOptions: _.isArray(customPropsObj.appCurrencyCodes) ? customPropsObj.appCurrencyCodes : [],
      };
    },
  },
  {
    name: "company_default.foreign_currency_support",
    tooltip: {
      show: true,
      title: "Foreign Currency Support",
      placement: "top",
    },
    label: "Foreign currency support",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.crypto_currency_support",
    tooltip: {
      show: true,
      title: "Crypto Currency Support",
      placement: "top",
    },
    label: "Crypto currency support",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company.currency_code",
    tooltip: {
      show: true,
      title: "To use Functional Currency for company",
      placement: "top",
    },
    label: "Functional Currency:",
    type: "react-select",
    component: FunctionalCurrencyPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.time_zone",
    tooltip: {
      show: true,
      title: "To set company level time zone",
      placement: "top",
    },
    label: "Company Timezone:",
    type: "select",
    component: RenderSelect,
    options: [
      { label: "", value: "" },
      { label: "Australia/Eucla", value: "Australia/Eucla" },
      { label: "Australia", value: "Australia/Darwin" },
      { label: "Australia/Brisbane", value: "Australia/Brisbane" },
      { label: "Australia/Adelaide", value: "Australia/Adelaide" },
      { label: "Australia/Sydney", value: "Australia/Sydney" },
      { label: "Australia/Sydney", value: "Australia/Sydney" },
      { label: "US/Pacific", value: "US/Pacific" },
      { label: "US/Mountain", value: "US/Mountain" },
      { label: "US/Central", value: "US/Central" },
      { label: "US/Eastern", value: "US/Eastern" },
      { label: "Europe/Zurich", value: "Europe/Zurich" },
      { label: "Europe/London", value: "Europe/London" },
      { label: "Asia/Kolkata", value: "Asia/Kolkata" },
      { label: "Asia/Jakarta", value: "Asia/Jakarta" },
      { label: "Asia/Jayapura", value: "Asia/Jayapura" },
      { label: "Asia/Singapore", value: "Asia/Singapore" },
      { label: "China/Beijing", value: "PRC" },
    ],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.bearer_of_payment_fee",
    tooltip: {
      show: true,
      title: "Bearer of Payment Fee(s)",
      placement: "top",
    },
    label: "Bearer of Payment Fee(s)",
    type: "select",
    component: RenderSelect,
    options: [
      { label: "", value: "" },
      { label: "Vendor/Supplier", value: "Vendor" },
      { label: "Purchaser", value: "Purchaser" },
    ],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.auto_start_workflow_date",
    tooltip: {
      show: true,
      title: "Bearer of Payment Fee(s)",
      placement: "top",
    },
    label: "Auto Start Invoice Workflow:",
    type: "select",
    component: RenderSelect,
    options: [
      { value: "", label: "" },
      { value: "current_day", label: "Same Day" },
      { value: "second_day", label: "1 Business Days" },
      { value: "third_day", label: "2 Business Days" },
      { value: "five_day", label: "5 Business Days" },
      { value: "ten_day", label: "10 Business Days" },
      { value: "no_start", label: "Do Not Start" },
    ],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.default_term",
    tooltip: {
      show: true,
      title: "Default Payment Terms",
      placement: "top",
    },
    label: "Default Payment Terms",
    type: "select",
    component: TermsPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },

  {
    name: "company_default.payment_remittance_email",
    tooltip: {
      show: true,
      title: "Payment Remittance E-mail (accounting@accrualify.com)",
      placement: "top",
    },
    label: "Payment Remittance E-mail",
    type: "text",
    component: RenderField,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_exception_email",
    tooltip: {
      show: true,
      title: "Expense Notification Exception E-mail",
      placement: "top",
    },
    label: "Expense Notification Exception E-mail: ",
    type: "text",
    component: RenderField,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.display_error_msg_limit",
    tooltip: {
      show: true,
      title: "It is used to set limit of Integration error msg. default limit is 3 days",
      placement: "top",
    },
    label: "Integration Error MSG Display Limit",
    type: "select",
    component: RenderSelect,
    options: [
      { label: "Default (3 Days)", value: "3" },
      { label: "7 Days", value: "7" },
      { label: "15 Days", value: "15" },
      { label: "30 Days", value: "30" },
      { label: "Always", value: "all" },
    ],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.group_payment_runs",
    tooltip: {
      show: true,
      title: "Group Payment Runs by specified criteria",
      placement: "top",
    },
    label: "Group Payment Runs:",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.payment_run_groupings",
    tooltip: {
      show: false,
    },
    label: "",
    type: "react-select",
    component: PaymentRunGroupingPicker,
    isMulti: true,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.global?.group_payment_runs;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.hide_legacy_user_roles",
    tooltip: {
      show: true,
      title:
        "Hides Legacy User Roles from the 'Add Role' dropdown, preventing user admins from assigning Legacy Roles. Turn on if your company is using the New Modular Roles. This will not delete or modify any existing user roles.",
      placement: "top",
    },
    label: "Hide Legacy User Roles",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.hide_new_user_roles",
    tooltip: {
      show: true,
      title:
        "Hides the New Modular User Roles from the 'Add Role' dropdown, preventing user admins from assigning the New Modular Roles. Turn on if your company is using the Legacy Roles. This will not delete or modify any existing user roles.",
      placement: "top",
    },
    label: "Hide New User Roles",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company.subsidiary_id",
    tooltip: {
      show: true,
      title: "To select default subsidiary for company",
      placement: "top",
    },
    label: "Subsidiary default:",
    type: "react-select",
    component: SubsidiaryPicker,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.company.has_subsidiaries;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [required],
    require: true,
    class: "",
  },
  {
    name: "company_default.payment_submission_window",
    tooltip: {
      show: true,
      title:
        `Auto: Payment date will be calculated based on Payment Method Type and Invoice Due Date\n` +
        `Current Date: Payment date will be set to today's date\n` +
        `Invoice Due Date: Payment date will be set to Invoice due date\n` +
        `3 Days Before Invoice Due Date: Payment Date will be set to three days before Invoice due date`,
      placement: "top",
    },
    label: "Payment Scheduling Window:",
    type: "select",
    component: RenderSelect,
    options: [
      { label: "", value: "" },
      { label: "Auto", value: "auto" },
      { label: "Current Date", value: "current_date" },
      { label: "Invoice Due Date", value: "due_date" },
      {
        label: "3 Days Before Invoice Due Date",
        value: "due_date_3_days",
      },
    ],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.matching_variance",
    tooltip: {
      show: true,
      title: "Variance in percentage will be used while matching item and expense lines",
      placement: "top",
    },
    label: "Matching Variance (%):",
    type: "select",
    component: RenderSelect,
    options: [{ label: "Select", value: "null" }, ...matching_variances],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.global.matching_variance_amount",
    tooltip: {
      show: true,
      title:
        "Variance Amount will be used while matching item lines and We will consider transaction level currency for the variance amount",
      placement: "top",
    },
    label: "Matching Variance Amount:",
    type: "number",
    component: RenderField,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.ap_team_id",
    tooltip: {
      show: true,
      title: "Notify by email to the selected team if invoice got rejected.",
      placement: "top",
    },
    label: "Default AP Team:",
    type: "react-select",
    component: TeamsPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_threshold_for_po",
    tooltip: {
      show: true,
      title: "Expense Amount Limit Without PO",
      placement: "top",
    },
    label: "Expense Amount Limit Without PO:",
    type: "number",
    component: RenderField,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.pct_threshold_for_po",
    tooltip: {
      show: true,
      title: "Percent threshold PO overage allow",
      placement: "top",
    },
    label: " Percent threshold PO overage allowed:",
    type: "number",
    component: RenderField,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.default_shipping_address_id",
    tooltip: {
      show: true,
      title: "Display Default Shipping Address",
      placement: "top",
    },
    label: "Default Shipping Address",
    type: "react-select",
    component: AddressPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.language_preference",
    tooltip: {
      show: false,
    },
    label: "Language Preference",
    type: "select",
    component: RenderSelect,
    options: [
      { label: "", value: "" },
      { label: "English", value: "translation_en" },
      {
        label: "简体中文",
        value: "translation_cn",
      },
    ],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.company_date_format",
    tooltip: {
      show: false,
    },
    label: "Select Date Format",
    type: "react-select",
    component: RenderSelect,
    options: [
      { label: "", value: "" },
      { label: "MM/dd/yyyy", value: "MM/dd/yyyy" },
      { label: "dd/MM/yyyy", value: "dd/MM/yyyy" },
    ],

    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company.number",
    tooltip: {
      show: true,
      title: "Company Number determines the File Integration SFTP Folder. Please contact support to modify",
      placement: "top",
    },
    label: "Company Number",
    type: "text",
    component: RenderField,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
    customProps: { disabled: true },
  },
  {
    name: "company.is_nvp_customer",
    tooltip: {
      show: true,
      title: "Is NVP Customer. Affects handling of payments. Please contact support to modify",
      placement: "top",
    },
    label: "Is NVP Customer",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.is_root;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
    customProps: { disabled: true },
  },
  {
    name: "company.has_osmos",
    tooltip: {
      show: true,
      title: "Uses osmos for file integration mappings. Please contact support to modify",
      placement: "top",
    },
    label: "Has Osmos Mapper Integration",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.is_root;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
    customProps: { disabled: true },
  },
  {
    name: "company.is_paymerang",
    tooltip: {
      show: true,
      title: "Has paymerang integrations",
      placement: "top",
    },
    label: "Has Paymerang Integrations. Please contact support to modify",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.is_root;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
    customProps: { disabled: true },
  },
  {
    name: "company_default.two_way_matching",
    tooltip: {
      show: true,
      title: "The invoice and PO will be matched at the header level.",
      placement: "top",
    },
    label: "Basic Two-Way Matching",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.advance_two_way_matching",
    tooltip: {
      show: true,
      title: "The invoice and PO will be matched at the line item level.",
      placement: "top",
    },
    label: "Advanced Two-Way Matching",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },

  {
    name: "company_default.three_way_matching",
    tooltip: {
      show: true,
      title: "Three-Way Matching",
      placement: "top",
    },
    label: "Three-Way Matching",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.invoice.inherit_only_received_lines", false));
      dispatch(change(formName, "company_default.global.allow_expense_line_in_threeway_match", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.allow_expense_line_in_threeway_match",
    tooltip: {
      show: true,
      title: "We will consider expense lines while Three Way matching once it turned on",
      placement: "top",
    },
    label: "Consider expense lines while three-Way Matching",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.three_way_matching;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.match_based_on_quantity",
    tooltip: {
      show: true,
      title: "PO balance will be based on unbilled line item quantity",
      placement: "top",
    },
    label: "Match Based On Quantity",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.invoice.prevent_unmatched_invoices",
    tooltip: {
      show: true,
      title: "Invoices can not be submitted until matched",
      placement: "top",
    },
    label: "Invoices Require Matching",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.amortization_scheduled",
    tooltip: {
      show: true,
      title: "Amortization Scheduled",
      placement: "top",
    },
    label: "Amortization Scheduled",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.advance_receiving",
    tooltip: {
      show: true,
      title: "Advanced Receiving",
      placement: "top",
    },
    label: "Advanced Receiving",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.advance_auto_schedule",
    tooltip: {
      show: true,
      title: "AI system will auto generate accrual campaigns based on historical data",
      placement: "top",
    },
    label: "Advanced Auto Scheduling",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.disabled_freetext_item",
    tooltip: {
      show: true,
      title: "System will not allow freeText item for PO's and Invoices, once selected",
      placement: "top",
    },
    label: "Disable FreeText Item",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.send_vendor_complete_profile_email",
    tooltip: {
      show: true,
      title: "Vendor Profile Incomplete Email",
      placement: "top",
    },
    label: "Vendor Profile Incomplete Email",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.enable_to_change_po_item_line_expense_account",
    tooltip: {
      show: true,
      title: "It will allow to change PO Item Line expense account once Selected",
      placement: "top",
    },
    label: "Enable To Change PO Item Line Expense Account",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.auto_close_expense_po",
    tooltip: {
      show: true,
      title: "PO associated with expense will be auto closed if expense is approved",
      placement: "top",
    },
    label: "Auto Close Expense PO",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.show_departments_by_subsidiary",
    tooltip: {
      show: true,
      title: "System will show departments by selected subsidiary.",
      placement: "top",
    },
    label: "Show department by subsidiary",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.show_locations_by_subsidiary",
    tooltip: {
      show: true,
      title: "System will show locations by selected subsidiary.",
      placement: "top",
    },
    label: "Show location by subsidiary",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.show_items_by_subsidiary",
    tooltip: {
      show: true,
      title: "System will show items by selected subsidiary.",
      placement: "top",
    },
    label: "Show items by subsidiary",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.enable_to_enter_tax_amount",
    tooltip: {
      show: true,
      title: "It will allow to enter the tax amount for PO and Invoice items and on invoice expenses once Selected",
      placement: "top",
    },
    label: "Enable To Enter Tax Amount",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.claim_function_required",
    tooltip: {
      show: true,
      title: "Approvers can claim POs and review later",
      placement: "top",
    },
    label: "Claim PO",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.enable_to_enter_wh_tax_amount",
    tooltip: {
      show: true,
      title: "When selected, you can enter/change the WH tax amount in Invoices and Credit Memo section.",
      placement: "top",
    },
    label: "Enable To Enter WH Tax Amount",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.auto_restart_approval_workflow_on_amount_or_department_change",
    tooltip: {
      show: true,
      title:
        "Approval Workflows on Invoices, Purchase Orders, and Credit Memos will be restarted if the amount or department is updated",
      placement: "top",
    },
    label: "Auto Restart Approval Workflow on Amount or Department Change",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.allow_to_filter_projects_by_location",
    tooltip: {
      show: true,
      title:
        "Allow to filter projects by linked location, it will applicable to all modules which has project and location.",
      placement: "top",
    },
    label: "Allow to filter projects by location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.global.notify_users_required_on_messages",
    tooltip: {
      show: true,
      title: "When sending a message the user must select at least one user to be notified.",
      placement: "top",
    },
    label: "Notify Users Required on Messages",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.po_request_allow_vendor_on_subsidiary",
    tooltip: {
      show: true,
      title:
        "After selecting a Subsidiary when in the Invoices or PO section, restrict the list of Vendors to Vendors who are linked to the selected Subsidiary. If a Subsidiary is not selected, the User can choose from all Vendors.",
      placement: "top",
    },
    label: "Allow Vendor From Subsidiary",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_only_company_terms",
    tooltip: {
      show: true,
      title: "Only Company level terms visible if selected",
      placement: "top",
    },
    label: "Allow Only Company Terms",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.is_root;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.static_workflow_template",
    tooltip: {
      show: true,
      title: "Static workflow template is fixed and pre-defined",
      placement: "top",
    },
    label: "Use Static Workflow Template",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.is_root;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_only_inherited_subsidiary_and_fields_from_vendor",
    tooltip: {
      show: true,
      title:
        "Subsidiary will be inherited from the Vendor level, and the field will be frozen on POs, Invoices, Payment, and Credit Memos.",
      placement: "top",
    },
    label: "Only use vendor level subsidiary",
    type: "checkbox",
    component: RenderCheckBox,

    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.is_root;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.use_vendor_level_currencies",
    tooltip: {
      show: true,
      title:
        "Currency will be inherited from the Vendor level, and populate only Vendor level currencies on POs, Invoices, Payments, and Credit Memos.",
      placement: "top",
    },
    label: " Only use vendor level currencies",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.hide_account_type_name",
    tooltip: {
      show: true,
      title: "Hide Account type name from account drop down list once selected.",
      placement: "top",
    },
    label: "Hide Account Type from Dropdown list ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.not_allow_invoice_from_vendor_portal",
    tooltip: {
      show: true,
      title: "Vendors will not be able to submit invoices from the Vendor Portal once selected.",
      placement: "top",
    },
    type: "checkbox",
    label: "Disable Invoice submission from Vendor Portal",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.show_vendor_id_and_name_in_search_list",
    tooltip: {
      show: true,
      title: "Allow searching Vendors by name and vendor_id",
      placement: "top",
    },
    label: "Show Vendor ID and Name in Vendor Search List",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.enable_bill_distribution_exclude",
    tooltip: {
      show: true,
      title: "Allow to show Bill Distribution exclude Checkbox on Invoice Debit line.",
      placement: "top",
    },
    label: "Allow Bill Distribution exclude Checkbox",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_for_business_unit",
    tooltip: {
      show: true,
      title: "Show Business Units field on Invoice's, PO's, and Credit Memo's expense line and items line",
      placement: "top",
    },
    label: "Allow For Business Unit",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_for_location",
    tooltip: {
      show: true,
      title: "Show Location field on Invoice's, PO's and Credit Memo's expense line and items line",
      placement: "top",
    },
    label: " Allow For Location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_for_project",
    tooltip: {
      show: true,
      title: "Show Project field on Invoice's, PO's and Credit Memo's expense line and items line",
      placement: "top",
    },
    label: "Allow For Project",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.restrict_user_projects",
    tooltip: {
      show: true,
      title: "Only show projects that the user is assigned to",
      placement: "top",
    },
    label: "Restrict project selection by user assignment",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.show_subsidiary_mapped_taxes",
    tooltip: {
      show: true,
      title:
        "Allow to show only subsidiary level taxes on invoice's, PO's and Credit Memo's expense line and items line",
      placement: "top",
    },
    label: "Allow to display only Subsidiary Level Taxes",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.readonly_business_unit_to_all",
    tooltip: {
      show: true,
      title: "Business unit field will be set as read only from all sections once selected",
      placement: "top",
    },
    label: "Business Unit as Read-Only for All Sections",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.auto_create_receipt_pdf",
    tooltip: {
      show: true,
      title: "Automatically create a pdf of purchases and expenses",
      placement: "top",
    },
    label: "Auto Create Pdf of Purchases and Expenses",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.apply_tax_on_line_and_store_header_level",
    tooltip: {
      show: true,
      title: "Tax will allow to apply on line level but store at header level",
      placement: "top",
    },
    label: "Tax apply on line level but store at header level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.credit_memo.readonly_header_level_tax", false));
    },
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.global.tax_expense_account_id",
    tooltip: {
      show: true,
      title: "Tax will allow to apply on line level but store at header level",
      placement: "top",
    },
    label: "Tax Expenses Account",
    type: "react-select",
    component: AccountPicker,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.global?.apply_tax_on_line_and_store_header_level;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [required],
    require: undefined,
    class: "",
    isClearable: true,
  },
  {
    name: "company_default.global.allow_rebate_at_items_line",
    tooltip: {
      show: true,
      title: "Allow rebate at PO, Invoice and Credit Memo items line.",
      placement: "top",
    },
    label: "Allow rebate at items line",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.company?.has_rebates;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.global.allow_rebate_at_expenses_line",
    tooltip: {
      show: true,
      title: "Allow rebate at PO, Invoice and Credit Memo expenses line.",
      placement: "top",
    },
    label: "Allow rebate at expenses line",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.company?.has_rebates;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.global.decimal_limit_to_unit_price",
    tooltip: {
      show: true,
      title: "Ability to limit Unit Price decimal places and default would be 8",
      placement: "top",
    },
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    component: RenderFieldNumber,
    label: "Limit Unit Price decimal places: ",
    type: "number",
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
];
