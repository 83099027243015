import React from "react";
import { Form } from "react-bootstrap";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Select from "react-select";
import { compare, findSelectedSingle, onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import { taxFormTypes } from "../../../../app.svc.Lookup";
import _ from "lodash";

export type TaxFormTypeSelectedType = {
  label: string;
  name: string;
  prefix: string;
  value: string;
};

type TaxFormType1099PropsType = {
  label?: string;
  tooltip?: string;
  required?: boolean;
  placeholder?: string;
  callBack?: (selected: TaxFormTypeSelectedType) => void;
  input: InputType;
  meta: {
    touched?: boolean;
    error?: string;
  };
};

type InputType = {
  value: number;
  onChange: (values: number) => void;
  onBlur: (values: number) => void;
};

type TaxFormType1099JsonType = {
  value: string;
  name: string;
  prefix: string;
};

const parseTaxFormTypes = (taxFormTypes: TaxFormType1099JsonType[]) => {
  return taxFormTypes
    .map((option: TaxFormType1099JsonType) => {
      return {
        value: option.value,
        label: manageLabel(option),
        name: option.name,
        prefix: option.prefix,
      };
    })
    .sort(compare);
};

const manageLabel = ({ value, name, prefix }: TaxFormType1099JsonType) => {
  return `${prefix} ${value} -- ${name}`;
};

const findSelected = (input: InputType) => {
  const obj = findSelectedSingle(input, taxFormTypes);
  const selectedValues = obj ? { value: obj?.value, label: manageLabel(obj), prefix: obj?.prefix } : null;
  return selectedValues;
};

const TaxFormType1099 = ({
  label,
  input,
  meta: { touched, error },
  tooltip,
  callBack,
  required,
  placeholder = "search/select",
}: TaxFormType1099PropsType) => {
  const taxForm1099Types = parseTaxFormTypes(taxFormTypes);

  const changeTaxFormType = (input: InputType, selected: any) => {
    onChangeSingle(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        value={findSelected(input)}
        placeholder={placeholder}
        onChange={(selected) => changeTaxFormType(input, selected)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={taxForm1099Types}
        isClearable
      />
      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default TaxFormType1099;
