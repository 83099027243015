import moment from "moment";
import { translate } from "../../../services/general/translation";

const prePaymentsHeader = [
  {
    field: "selectAll",
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    maxWidth: 40,
    minWidth: 40,
    menuTabs: [],
  },
  {
    field: "id",
    headerName: "ID",
    filter: "agTextColumnFilter",
    hide: true,
    headerValueGetter: function () {
      return translate("id");
    },
  },
  {
    field: "external_id",
    headerName: "External ID",
    filter: "agTextColumnFilter",
    hide: true,
    headerValueGetter: function () {
      return translate("externalId");
    },
  },
  {
    field: "payment_number",
    headerName: "Payment Number",
    cellRenderer: "childCellRouting",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
      headerValueGetter: function () {
        return translate("payment_number");
      },
    },
    width: 300,
  },
  {
    field: "vendor.name",
    headerName: "Vendor",
    cellRenderer: "childCellRouting",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("vendor");
    },
  },
  {
    field: "payment_date",
    headerName: "Date",
    filter: "agDateColumnFilter",
    minWidth: 197,
    filterParams: {
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = moment(cellValue).format("DD/MM/YYYY");
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    },
    cellRendererSelector: function (params) {
      return;
    },
    headerValueGetter: function () {
      return translate("date");
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    minWidth: 120,
    // filter: 'agNumberColumnFilter',
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("amount_only");
    },
  },
  {
    field: "status",
    headerName: "Status",
    filter: "agTextColumnFilter",
    headerValueGetter: function () {
      return translate("status");
    },
  },
  {
    field: "payment_type",
    headerName: "Payment Type",
    filter: "agTextColumnFilter",
    width: 250,
    hide: true,
    headerValueGetter: function () {
      return translate("payment_type");
    },
  },
  {
    field: "bank_name",
    headerName: "Orig. Bank Name",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    width: 300,
    hide: true,
    headerValueGetter: function () {
      return translate("org_bank_name");
    },
  },
  {
    field: "reference_number",
    headerName: "Ref. Number",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    width: 230,
    hide: true,
    headerValueGetter: function () {
      return translate("reference_number");
    },
  },
  {
    field: "invoices",
    headerName: "Invoices",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    hide: true,
    headerValueGetter: function () {
      return translate("invoices");
    },
  },
  {
    field: "prepaid_purchase_orders",
    headerName: "Purchase Orders",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    hide: true,
    width: 230,
    headerValueGetter: function () {
      return translate("purchaseOrders");
    },
  },
  {
    // field: '',
    field: "",
    cellRenderer: "childMessageRenderer",
    sortable: false,
    resizable: false,
    width: 230,
    maxWidth: 230,
    cellRendererParams: {
      viewLink: "/ap/payments/pre_payments/",
    },
    cellRendererSelector: function (params) {
      if (params.node.rowPinned) {
        return {
          component: () => null,
        };
      } else {
        // rows that are not pinned don't use any cell renderer
        return undefined;
      }
    },
    headerValueGetter: function () {
      return translate("actions");
    },
  },
];

export default prePaymentsHeader;
