import { change, Field, FieldArray, getFormValues } from "redux-form";
import React, { useCallback, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { INTEGRATION_FORM_NAME } from "../../../../../../../reducers/admin/integrationsReducer";

const RcnId = ({ fields }) => {
  return (
    <>
      {fields.map((rcn_id, index) => {
        return (
          <Row key={index} className="m-0">
            <Col sm="5" className="pl-0">
              <Field component={RenderField} name={`${rcn_id}.rcn_id`} id={`integration_form_rcnid_${index}`} />
            </Col>
            <Col sm="5">
              <Field component={RenderField} name={`${rcn_id}.rcn_alias`} id={`integration_form_rcnid_${index}`} />
            </Col>
            <Col sm="2">
              <i
                style={{ cursor: "pointer" }}
                className="icon icon-delete mt-2"
                onClick={() => {
                  fields.remove(index);
                }}
              ></i>
            </Col>
          </Row>
        );
      })}
      <Row className="m-0 mb-2">
        <Col lg="3" className="pl-0">
          <Button onClick={() => fields.push({ rcn_id: "", rcn_alias: "" })}>+ Add More</Button>
        </Col>
      </Row>
    </>
  );
};

const VirtualCardCredential = () => {
  const dispatch = useDispatch();
  const { form } = useSelector((state) => getFormValues("credentialsForm")(state));
  const assignRcnIds = useCallback(
    (ids) => {
      if (!_.isArray(ids) || (_.isArray(ids) && !(ids.length > 0))) {
        // update form data with dispatch change from redux form
        dispatch(change(INTEGRATION_FORM_NAME, "form.rcn_ids", [""]));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    assignRcnIds(form.rcn_ids);
  }, []);

  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <Col lg="12">
          <label>RCN ID - RCN Alias</label>
        </Col>
      </Row>
      <Row className="m-0">
        {_.isArray(form.rcn_ids) && (
          <Col lg="12">
            <FieldArray name="form.rcn_ids" component={RcnId} />
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default VirtualCardCredential;
