import { useState } from "react";
import Add from "./add";
import Edit from "./edit";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";
import { useSelector } from "react-redux";
import { ADD, EDIT, setComponentMountMode } from "../../../reducers/admin/companyPaymentMethodFormReducer";
import { useDispatch } from "react-redux";

const useCompanyPaymentMethod = ({ saveEditCallBack }) => {
  const componentMountMode = useSelector((state) => state.companyPaymentMethodFormRed.componentMountMode);
  const [editId, setEditId] = useState(null);
  const dispatch = useDispatch();

  return {
    companyPaymentMethod: (
      <>
        {/* dynamic component mount for company payment method */}
        <ErrorBoundary>
          {componentMountMode === ADD && <Add onCreateCallBack={saveEditCallBack} />}

          {componentMountMode === EDIT && <Edit id={editId} onUpdateCallBack={saveEditCallBack} />}
        </ErrorBoundary>
      </>
    ),
    editPaymentMethod: (id) => {
      setEditId(id);
      dispatch(setComponentMountMode(EDIT));
    },
    addPaymentMethod: () => {
      dispatch(setComponentMountMode(ADD));
    },
  };
};

export default useCompanyPaymentMethod;
