import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RestApi from "../../../../providers/restApi";
import AddressForm from "./form";
import {
  setAddressForm,
  onAddressUnmount,
  setAddresses,
  removeDefaultAddress,
} from "../../../../reducers/common/addressSectionReducer";
import useIsMounted from "../../../common/hooks/useIsMounted";
import CustomModal from "../../../modals/customModal";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { useTranslation } from "react-i18next";

const restApiService = new RestApi();

const EditAddress = ({ addressId, editModalOpen, setEditModalOpen, closeCallback }) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const addresses = useSelector((state) => state.addressSectionRed.addresses);
  const { t } = useTranslation();

  const onSubmit = async (formData) => {
    const address = formData?.form;
    try {
      const response = await restApiService.patch("addresses/" + address.id, null, { address });

      dispatch(
        setAddresses(
          addresses.map((addr) => {
            if (addr.id === address.id) {
              return response.data;
            }
            return addr;
          }),
        ),
      );
      CreateNotification("success", `${response.data.id} updated successfully`, NotificationType.success);

      // remove other is_default flags
      if (response?.data?.is_default) {
        dispatch(removeDefaultAddress(response.data));
      }

      dispatch(onAddressUnmount());
      onHide();
    } catch (error) {
      console.log(error);
    }
  };

  const onHide = () => {
    setEditModalOpen(false);
    closeCallback();
  };

  const openAddressForm = (formData) => {
    setEditModalOpen(true);
    dispatch(setAddressForm(formData));
  };

  const initializeForm = async () => {
    try {
      const response = await restApiService.get("addresses/" + addressId);
      if (isMounted) {
        openAddressForm(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initializeForm();
    return () => {
      dispatch(onAddressUnmount());
    };
  }, []);

  return (
    <CustomModal
      size={"lg"}
      show={editModalOpen}
      onHide={onHide}
      header={t("components.admin.addressable.editAddress")}
      headerClass={"px-4"}
      body={<AddressForm onSubmit={onSubmit} onHide={onHide} />}
    />
  );
};

export default EditAddress;
