import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { DocumentsRequiredType } from "./documentsType";
import { getListOptionsType } from "services/common/types/common.type";

class DocumentsApis {
  static getRequiredDocumentsList = async ({ filter, cache }: getListOptionsType = {}) => {
    try {
      const response: AxiosResponse<DocumentsRequiredType[]> = await restApiService.get(
        "document_requirements",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
export default DocumentsApis;
