import AccountPicker from "components/admin/pickers/reduxFormPickers/accountPicker";
import React, { Fragment, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Field, FieldArrayFieldsProps, change } from "redux-form";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import styles from "./invoices.module.css";
import TaxCodePicker from "components/admin/pickers/reduxFormPickers/taxCodePicker";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";
import adminCommonSvc from "services/admin/commonSvc";
import { RenderInputGroupField } from "components/forms/bootstrapFields";
import useAdminCompanyCurrencyCode from "components/admin/hooks/useAdminCompanyCurrencyCode";
import DepartmentPicker from "components/admin/pickers/reduxFormPickers/departmentPicker";
import locationPicker from "components/admin/pickers/reduxFormPickers/locationPicker";
import ProjectPicker, {
  ProjectOptionsType,
} from "components/admin/pickers/reduxFormPickers/projectPicker/projectPicker";
import { required } from "services/validations/reduxFormValidation";
import { roundUpAmount } from "services/vp/services/roundUpAmount";
import BusinessUnitPicker from "components/admin/pickers/reduxFormPickers/businessUnitPicker";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { CustomLabelSvc } from "services/admin/customLabels/customLabelsSvc";

type ManageUseTaxOnSubTotalType = {
  _destroy?: number;
  is_department_required?: number;
  is_location_required?: number;
  is_project_required?: number;
};

type ManageUseTaxOnSubTotalPropsType = {
  fields: FieldArrayFieldsProps<ManageUseTaxOnSubTotalType>;
  invoice: InvoiceType.InvoiceDetailType;
  customLabels?: { [key: string]: string[] };
};

const ManageUseTaxOnSubTotal = ({ fields, invoice, customLabels }: ManageUseTaxOnSubTotalPropsType) => {
  const [hideEntry, setHideEntry] = useState<number[]>([]);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { companyCurrencies } = useAdminCompanyCurrencyCode();
  const dispatch = useDispatch();
  const formName = "InvoiceForm";
  const { t } = useTranslation();

  const applyUseTaxOnSubtotal = (fields: FieldArrayFieldsProps<ManageUseTaxOnSubTotalType>) => {
    fields?.push({});
  };

  const calculateSubtotalTaxAmount = (entry: any, taxCode: any, index: number) => {
    if (taxCode) {
      entry.tax_code = taxCode;
      entry.tax_id = taxCode.id;
      invoiceCommonSvc.calculateSubtotalTax(invoice, entry, currentUser);
    }

    dispatch(change(formName, `tax_debit_entries_attributes[${index}]`, entry));
  };

  const removeUsedTaxEntry = (entry: any, index: number) => {
    entry._destroy = 1;
    setHideEntry([...hideEntry, index]);
    dispatch(change(formName, `tax_debit_entries_attributes[${index}]._destroy`, 1));
  };

  const calculateUseTaxOnSubtotalAmount = (entry: any, value: boolean) => {
    invoice.is_subtotal_charges_in_usetax = value;
    invoiceCommonSvc.calculateSubtotalTax(invoice, entry, currentUser);
  };

  const changeProject = (entry: any, project: ProjectOptionsType, index: number) => {
    entry.project_id = project.project_id;
    entry.project_name = project.label;
    entry.project = project;
    dispatch(change(formName, `tax_debit_entries_attributes[${index}].project_id`, entry.project_id));
  };

  return (
    <>
      {invoiceCommonSvc.isSubtotalUsedTaxExits(invoice) && (
        <>
          <Row className="px-mt-15">
            <Col>
              <span>Use Tax On SubTotal</span>
              <hr className={`${styles.separator} px-mt-5`} />
            </Col>
          </Row>
          <Row className="invoiceItemLine">
            <Col>
              {fields &&
                fields.length > 0 &&
                fields.map((useTaxSubtotal: any, index) => {
                  if (!hideEntry.includes(index) && fields.get(index)._destroy !== 1) {
                    return (
                      <Fragment key={index}>
                        <Row>
                          <Col sm={4}>
                            <Field
                              instanceId={`use-tax-${index}-account`}
                              component={AccountPicker}
                              name={`${useTaxSubtotal}.account_id`}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.expenseItems.useTaxAccount"),
                              )}
                              required={true}
                              validate={[required]}
                              placeHolder="Search..."
                            />
                          </Col>
                          <Col sm={4} className="p-0">
                            <Field
                              instanceId={`use-tax-${index}-tax`}
                              name={`${useTaxSubtotal}.tax_id`}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.taxCode"),
                              )}
                              component={TaxCodePicker}
                              isClearableDisable={true}
                              isUseTax="invoice.is_account_used_tax"
                              parentObj={invoice}
                              subsidiary_id={invoice?.subsidiary_id}
                              required={invoiceCommonSvc.isInvoiceLineLevelTaxRequired(invoice, currentUser)}
                              validate={
                                invoiceCommonSvc.isInvoiceLineLevelTaxRequired(invoice, currentUser) ? [required] : []
                              }
                              callBack={(taxCode: any) => calculateSubtotalTaxAmount(fields.get(index), taxCode, index)}
                            />
                          </Col>
                          <Col sm={3} className="px-pr-0">
                            <Field
                              id={`use-tax-${index}-amount`}
                              name={`${useTaxSubtotal}.amount`}
                              type="number"
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.expenseItems.useTax"),
                              )}
                              inputGroupText={adminCommonSvc.getSymbolFromIsoCode(
                                invoice?.currency?.iso_code,
                                companyCurrencies,
                              )}
                              component={RenderInputGroupField}
                            />
                          </Col>
                          <Col sm={1} className="d-flex align-items-center justify-content-end">
                            <Button
                              onClick={() => removeUsedTaxEntry(fields.get(index), index)}
                              className="bg-transparent border-0 m-0 p-0 px-pl-15"
                            >
                              <i className="icon icon-delete m-0" />
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          {currentUser?.company?.has_departments &&
                            !currentUser?.company?.invoice_use_tax_subtotal_hide_department && (
                              <Col sm={4}>
                                <Field
                                  instanceId={`use-tax-${index}-department`}
                                  name={`${useTaxSubtotal}.department_id`}
                                  component={DepartmentPicker}
                                  label={CustomLabelSvc.setCustomLabel(
                                    customLabels,
                                    t("admin.pages.invoice.invoiceItems.department"),
                                  )}
                                  modelData={fields.get(index)}
                                  parentObjData={invoice}
                                  required={fields.get(index).is_department_required}
                                  validate={fields.get(index).is_department_required ? [required] : []}
                                  menuPosition="fixed"
                                />
                              </Col>
                            )}
                          {currentUser?.company?.has_locations &&
                            !currentUser?.company?.invoice_use_tax_subtotal_hide_location && (
                              <Col sm={4}>
                                <Field
                                  instanceId={`use-tax-${index}-location`}
                                  name={`${useTaxSubtotal}.location_id`}
                                  component={locationPicker}
                                  subsidiaryId={invoice.subsidiary_id}
                                  label={CustomLabelSvc.setCustomLabel(
                                    customLabels,
                                    t("admin.pages.invoice.invoiceItems.location"),
                                  )}
                                  modelData={fields.get(index)}
                                  parentObjData={invoice}
                                  callBack={(location: any) =>
                                    dispatch(change(formName, `${useTaxSubtotal}.location`, location))
                                  }
                                  required={
                                    fields.get(index).is_location_required ||
                                    currentUser?.company?.invoice_item_location_required
                                  }
                                  validate={
                                    fields.get(index).is_location_required ||
                                    currentUser?.company?.invoice_item_location_required
                                      ? [required]
                                      : []
                                  }
                                />
                              </Col>
                            )}

                          {currentUser?.company?.has_projects && (
                            <Col sm={4}>
                              <Field
                                instanceId={`use-tax-${index}-business-unit`}
                                name={`${useTaxSubtotal}.business_unit_id`}
                                label={CustomLabelSvc.setCustomLabel(
                                  customLabels,
                                  t("admin.pages.invoice.invoiceItems.businessUnit"),
                                )}
                                component={BusinessUnitPicker}
                                parentObjData={invoice}
                                bySubsidiary={currentUser?.company?.invoice?.show_business_unit_by_subsidiary}
                                modelData={fields.get(index)}
                                callBack={(businessUnit: any) =>
                                  dispatch(change(formName, `${useTaxSubtotal}.business_unit`, businessUnit))
                                }
                                disabled={currentUser?.company?.readonly_business_unit_to_all}
                              />
                            </Col>
                          )}

                          {currentUser?.company?.has_projects &&
                            !currentUser?.company?.invoice_use_tax_subtotal_hide_project && (
                              <Col sm={4}>
                                <Field
                                  instanceId={`use-tax-${index}-project`}
                                  name={`${useTaxSubtotal}.project`}
                                  label={CustomLabelSvc.setCustomLabel(
                                    customLabels,
                                    t("admin.pages.invoice.invoiceItems.project"),
                                  )}
                                  component={ProjectPicker}
                                  callBack={(selected: ProjectOptionsType) =>
                                    changeProject(fields.get(index), selected, index)
                                  }
                                  menuPosition="fixed"
                                />
                              </Col>
                            )}
                        </Row>
                        {invoice.is_used_tax && (
                          <Row>
                            <Col sm={6}>
                              <span>
                                <Field
                                  id={`use-tax-${index}-is-subtotal-charges-in-use-tax`}
                                  name="is_subtotal_charges_in_usetax"
                                  component="input"
                                  type="checkbox"
                                  onChange={(e: any) =>
                                    calculateUseTaxOnSubtotalAmount(fields.get(index), e.target.checked)
                                  }
                                />
                                <span className="px-ml-5"> Include Subtotal charges in Use Tax </span>
                              </span>
                            </Col>
                          </Row>
                        )}

                        <Row className="px-mt-15">
                          <Col>
                            <Button
                              variant="btn btn-primary"
                              onClick={() => removeUsedTaxEntry(fields.get(index), index)}
                            >
                              {" "}
                              Remove{" "}
                            </Button>
                          </Col>
                        </Row>
                      </Fragment>
                    );
                  }
                })}
            </Col>
          </Row>
        </>
      )}
      {!invoiceCommonSvc.isSubtotalUsedTaxExits(invoice) && (
        <Row>
          <Col>
            <Button variant="btn btn-primary" onClick={() => applyUseTaxOnSubtotal(fields)}>
              <i className="btn-icon icon-add-btn"></i> Apply Use Tax On Subtotal
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ManageUseTaxOnSubTotal;
