import React from "react";
import { RecurringInvoiceDetailType } from "../../../../../services/admin/invoices/recurringInvoice/recurringInvoiceTypes";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { restApiService } from "../../../../../providers/restApi";
import styles from "../recurringInvoice.module.css";
import { formattedAmount } from "../../../../../services/general/helpers";
import { getUrlLocator } from "../../../../../services/common/urlHelperSvc";
import { companyDateFormat } from "../../../../../services/general/dateSvc";
import { useTypedSelector } from "../../../../../reducers";
import { IUser } from "../../../../../services/common/user/userTypes";
import _ from "lodash";

type GeneralInfoPropsType = {
  recurringInvoice?: RecurringInvoiceDetailType;
};

const GeneralInfo = ({ recurringInvoice }: GeneralInfoPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const { t } = useTranslation();
  return (
    <Row className={`mx-2 ${styles.general}`}>
      <Col lg="4">
        <dl className="row">
          <dt className="col-6 detailKey text-sm-right">Vendor</dt>
          <dd className="col-6 detailValue">
            {recurringInvoice?.vendor?.name && (
              <a
                href={restApiService.angularBaseURL() + getUrlLocator("vendor", recurringInvoice?.vendor?.id)}
                className="link"
              >
                {recurringInvoice.vendor.name}
              </a>
            )}
          </dd>
          <dt className="col-6 detailKey text-sm-right">Vendor ID</dt>
          <dd className="col-6 detailValue">{recurringInvoice?.vendor?.vendor_id}</dd>
          {/* <dt className="col-6 detailKey text-sm-right">Invoice Date</dt>
          <dd className="col-6 detailValue">
            {companyDateFormat(recurringInvoice?.object_properties?.date, currentUser)}
          </dd> */}
          <dt className="col-6 detailKey text-sm-right">Subsidiary</dt>
          <dd className="col-6 detailValue">{recurringInvoice?.object_properties?.subsidiary?.name}</dd>
          {recurringInvoice?.object_properties?.department && (
            <>
              <dt className="col-6 detailKey text-sm-right">Department</dt>
              <dd className="col-6 detailValue">{recurringInvoice.object_properties?.department.name}</dd>
            </>
          )}
          {recurringInvoice?.object_properties?.business_unit && (
            <>
              <dt className="col-6 detailKey text-sm-right">Business Unit</dt>
              <dd className="col-6 detailValue">{recurringInvoice.object_properties?.business_unit.name}</dd>
            </>
          )}
          {recurringInvoice?.object_properties?.location && (
            <>
              <dt className="col-6 detailKey text-sm-right">Location</dt>
              <dd className="col-6 detailValue">{recurringInvoice.object_properties.location.name}</dd>
            </>
          )}
          {recurringInvoice?.object_properties?.purchase_order_links &&
            _.isArray(recurringInvoice.object_properties.purchase_order_links) &&
            recurringInvoice.object_properties.purchase_order_links.length > 0 && (
              <>
                <dt className="col-6 detailKey text-sm-right">Purchase Orders</dt>
                <dd className="col-6 detailValue">
                  <div className="d-flex flex-wrap">
                    {recurringInvoice.object_properties.purchase_order_links.map((purchase_order_link, index) => (
                      <React.Fragment key={index}>
                        <a
                          href={
                            restApiService.angularBaseURL() +
                            getUrlLocator("purchaseorder", purchase_order_link.purchase_order.id)
                          }
                          className="link"
                        >
                          {purchase_order_link.purchase_order.number}
                        </a>
                        {index + 1 !== recurringInvoice.object_properties.purchase_order_links.length && (
                          <span>,&nbsp;</span>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </dd>
              </>
            )}
        </dl>
      </Col>
      <Col lg="4">
        <dl className="row">
          <dt className="col-6 detailKey text-sm-right">Amount</dt>
          <dd className="col-6 detailValue">
            {formattedAmount(
              recurringInvoice?.object_properties?.amount?.toString(),
              recurringInvoice?.object_properties?.currency_code,
            )}
          </dd>
          <dt className="col-6 detailKey text-sm-right">Currency Code</dt>
          <dd className="col-6 detailValue">{recurringInvoice?.object_properties?.currency_code}</dd>
          <dt className="col-6 detailKey text-sm-right">Payment Method Type</dt>
          <dd className="col-6 detailValue">{recurringInvoice?.object_properties?.payment_method?.payment_type}</dd>
          <dt className="col-6 detailKey text-sm-right">Payment Terms</dt>
          <dd className="col-6 detailValue">{recurringInvoice?.object_properties?.term?.code}</dd>
          <dt className="col-6 detailKey text-sm-right">Submit Date</dt>
          <dd className="col-6 detailValue">
            {companyDateFormat(recurringInvoice?.object_properties?.submit_date, currentUser)}
          </dd>
          <dt className="col-6 detailKey text-sm-right">Submitter</dt>
          {
            <dd className="col-6 detailValue">
              {recurringInvoice?.submitter && (
                <span>
                  {recurringInvoice?.submitter?.name} - {recurringInvoice?.submitter?.email} <i> at </i>
                  {companyDateFormat(recurringInvoice?.submitter?.date, currentUser)}
                </span>
              )}
            </dd>
          }
          <dt className="col-6 detailKey text-sm-right">Submitter Type</dt>
          <dd className="col-6 detailValue">{recurringInvoice?.submitter?.submitter_type || "n/a"}</dd>
          <dt className="col-6 detailKey text-sm-right">Requestor</dt>
          <dd className="col-6 detailValue">
            {recurringInvoice?.object_properties?.requestor && (
              <a
                href={
                  restApiService.angularBaseURL() +
                  getUrlLocator("contact", recurringInvoice.object_properties.requestor.id)
                }
                className="link"
              >
                {recurringInvoice.object_properties.requestor.first_name}{" "}
                {recurringInvoice.object_properties.requestor.last_name}
              </a>
            )}
          </dd>
        </dl>
      </Col>
      <Col lg="4">
        <dl className="row">
          <dt className="col-6 detailKey text-sm-right">Frequency</dt>
          <dd className="col-6 detailValue">{recurringInvoice?.schedule?.interval}</dd>
          <dt className="col-6 detailKey text-sm-right">Start Date</dt>
          <dd className="col-6 detailValue">
            {companyDateFormat(recurringInvoice?.schedule?.start_date, currentUser)}
          </dd>
          <dt className="col-6 detailKey text-sm-right">End Date</dt>
          <dd className="col-6 detailValue">{companyDateFormat(recurringInvoice?.schedule?.end_date, currentUser)}</dd>
          <dt className="col-6 detailKey text-sm-right">Next Invoice Date</dt>
          <dd className="col-6 detailValue">
            {companyDateFormat(recurringInvoice?.schedule?.next_runtime, currentUser)}
          </dd>
          {recurringInvoice?.object_properties?.vendor_location?.name && (
            <>
              <dt className="col-6 detailKey text-sm-right">Location Name</dt>
              <dd className="col-6 detailValue">{recurringInvoice?.object_properties?.vendor_location?.name}</dd>
            </>
          )}
        </dl>
      </Col>
    </Row>
  );
};

export default GeneralInfo;
