import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import { WrappedFieldProps } from "redux-form";
import useIsMounted from "components/common/hooks/useIsMounted";

interface SubtotalTemplatePickerPropsType extends WrappedFieldProps {
  label?: ReactNode;
  input: any;
  required?: boolean;
  tooltip?: string;
  disabled?: boolean;
  floating?: boolean;
  callBack?: (subTotalTemplate?: SubtotalTemplateObjType) => void;
  instanceId?: string;
}

export type SubtotalTemplateObjType = {
  account?: any;
  account_id?: number;
  external_id?: number | string;
  id?: number | string;
  description?: string;
  name?: string;
  status?: string;
  label?: string;
};

const SubtotalTemplatePicker = ({
  label,
  required,
  input,
  floating,
  tooltip,
  disabled,
  callBack,
  instanceId = "subtotal-template",
}: SubtotalTemplatePickerPropsType) => {
  const [subtotalTemplates, setSubtotalTemplates] = useState<SubtotalTemplateObjType[]>([]);
  const isMounted = useIsMounted();
  const [selected, setSelected] = useState<SubtotalTemplateObjType>();

  const parseForSelect = (options: SubtotalTemplateObjType[]) => {
    return options.map((option: SubtotalTemplateObjType) => {
      return {
        ...option,
        value: option.id,
        label: option.name,
        status: option.status,
      };
    });
  };

  const getSubtotalTemplates = useCallback(async () => {
    const result: AxiosResponse = await restApiService.get(
      `subtotal_templates`,
      { status: "ACTIVE" },
      null,
      true,
      null,
      true,
    );
    if (result?.data) {
      let list = parseForSelect(result?.data);
      if (isMounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Subtotal Template --",
              id: undefined,
              value: undefined,
              status: undefined,
            },
            ...list,
          ];
        }
        setSubtotalTemplates(list);
      }
      return list;
    }
  }, []);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        const result = await restApiService.get("subtotal_templates/" + input.value);
        const inactiveTemplate = {
          value: result.data.id,
          label: (
            <>
              {result.data.name} - <small style={{ fontSize: "10px" }}>({result.data.status})</small>
            </>
          ),
        };
        selectedValues = inactiveTemplate;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  const onChangeSubtotalTemplates = (input: any, selected: any) => {
    onChangeSingle(input, selected);
    setSelected(selected);
    if (callBack) {
      callBack(selected);
    }
  };

  useEffect(() => {
    getSubtotalTemplates().then((res) => {
      findSelected(input, res).then((response: SubtotalTemplateObjType) => {
        setSelected(response);
      });
    });
  }, []);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={selected}
          onChange={(selected) => onChangeSubtotalTemplates(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={subtotalTemplates}
          isClearable={!required}
          disable={disabled}
          getOptionLabel={(option: SubtotalTemplateObjType) => {
            return (
              <>
                <span className="formField">{option.label}</span>
                {option.status === "INACTIVE" && <small style={{ fontSize: "10px" }}> -({option.status})</small>}
              </>
            );
          }}
          filterOption={(option: any, searchText) =>
            option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
          }
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
            }),
            ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
          }}
          {...(floating ? { menuPortalTarget: document.body } : {})}
          instanceId={instanceId}
        />
      </Form.Group>
    </>
  );
};

export default SubtotalTemplatePicker;
