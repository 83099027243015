import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NavTabs from "../navigation/nav";
import GridFilterDropdown from "../../../datagrid/gridFilterDropdown";
import ToggleFilterButton from "../../../datagrid/buttons/toggleFilterButton";
import styles from "./schedules.module.css";
import { GridReadyEvent } from "ag-grid-community";
import ClientDataGrid from "../../../common/dataGrid/clientDataGrid/clientDataGrid";
import useShowFilterState from "../../../common/hooks/useShowFilterState";

import { useTypedSelector } from "reducers";
import { ListSectionNav } from "../navigation/nav.list";
import {
  ScheduleParamsType,
  ScheduleServiceNamesType,
  SchedulesFormType,
  SchedulesListType,
} from "services/admin/reports/schedules/schedulesType";
import { SchedulesApis } from "services/admin/reports/schedules/schedulesApis";
import { getSchedulesHeaders } from "./listSchedulesHeaders";
import AddReportForm from "../standardReports/addReportForm";
import {
  DataExtractModuleTypes,
  ReportCategoryOptionsType,
  ReportClassificationType,
} from "services/admin/reports/reportTypes";
import { getReportStorageName, getSectionRoutes } from "services/admin/reports/reportSvc";
import { ReportTemplateOptionsType } from "services/admin/reports/templates/reportTemplateType";
import { ReportSvc } from "services/admin/reports/reportSvc";
import { ReportFormType } from "services/admin/reports/reportTypes";

const BASE_PARAMS = { schedulable_type: "ReportTemplate" };
interface ListSchedulesPropsType {
  classification: ReportClassificationType;
  options: ReportCategoryOptionsType;
  createLabel: string;
  scheduleServiceName: ScheduleServiceNamesType;
  dataExtractModule?: DataExtractModuleTypes;
}

const ListSchedules = ({
  classification,
  options,
  createLabel,
  scheduleServiceName,
  dataExtractModule,
}: ListSchedulesPropsType) => {
  const currentUser = useTypedSelector((state) => state.user);
  const [gridApi, setGridApi] = useState<GridReadyEvent["api"]>();
  const [gridColumnApi, setGridColumnApi] = useState<GridReadyEvent["columnApi"]>();
  const [schedulesList, setSchedulesList] = useState<SchedulesListType>([]);
  const [addVisible, setAddVisible] = useState<boolean>(false);
  const { showFilters, updateShowFilters } = useShowFilterState("showDataExtractScheduleFilters");
  const [editItem, setEditItem] = useState<ReportFormType | null>(null);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getSchedulesList = useCallback(async () => {
    const base_params: ScheduleParamsType = { ...BASE_PARAMS, report_classification: classification };
    try {
      const result = await SchedulesApis.getList(false, base_params);
      result && setSchedulesList(result);
    } catch (error) {}
  }, []);

  const showAddRecurring = useCallback(() => {
    setAddVisible(true);
  }, []);

  const hideAddDataExtract = useCallback(() => {
    setAddVisible(false);
  }, []);

  const showEditRecurring = useCallback((schedule: SchedulesFormType) => {
    if (!schedule.schedulable || !schedule.schedulable_id) {
      return;
    }
    const selectedReportTemplates: ReportTemplateOptionsType = [
      {
        value: schedule.schedulable_id,
        label: schedule.schedulable.name.replace("Listing", "Extract") ?? "",
        code: schedule.schedulable.code,
      },
    ];
    const recipients_original: string = schedule.recipients ? schedule.recipients.join(",") : "";
    let parameters = schedule.parameters ?? {};
    if (parameters?.properties?.filters) {
      parameters.properties.filters.transaction_classifications_chosen = ReportSvc.getTransactionClassificationNames(
        parameters.properties.filters,
      );
      parameters.properties.filters.subsidiary_ids_original = parameters.properties.filters.subsidiary_ids
        ? parameters.properties.filters.subsidiary_ids.split(",").map((s: string) => Number(s))
        : [];
      parameters.properties.filters.include_external_id_has_data =
        parameters.properties.filters.include_external_id_has_data === "1";
    }
    setEditItem({ id: null, schedule, ...parameters, recipients_original, report_templates: selectedReportTemplates });
  }, []);

  const hideEditRecurring = useCallback(() => {
    setEditItem(null);
  }, []);

  const schedulesGridHeaders = useMemo(() => getSchedulesHeaders(getSchedulesList, showEditRecurring, currentUser), []);

  useEffect(() => {
    getSchedulesList();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <NavTabs activePageName={getReportStorageName(classification, dataExtractModule)} />
        </Col>
      </Row>
      <Row>
        {createLabel === "Data Extract" && (
          <Col md="12" className="pb-2">
            <h2 className={styles.pageTitle}>{getReportStorageName(classification, dataExtractModule)}</h2>
          </Col>
        )}
      </Row>
      <Row>
        <Col className="px-0 pl-3" md={4}>
          <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} options={{}} />
          <Button className={`ml-3 ${styles.createButton}`} onClick={showAddRecurring}>
            {`Schedule Recurring ${createLabel}`}
          </Button>
        </Col>
        <Col className="px-0" md={4}>
          <ListSectionNav sectionRoutes={getSectionRoutes(classification, dataExtractModule)} />
        </Col>
        <Col className="px-0" md={4}>
          <ToggleFilterButton
            classes="float-right"
            clickCallback={() => {
              updateShowFilters(!showFilters);
              gridApi?.refreshHeader();
            }}
          />
        </Col>
      </Row>

      <Row className={styles.gridHeight + " mt-3 px-3"}>
        <ClientDataGrid
          columnDefs={schedulesGridHeaders}
          gridReady={gridReady}
          rowData={schedulesList}
          defaultColDef={{ floatingFilter: showFilters }}
          gridStorageName={getReportStorageName(classification, dataExtractModule, true)}
        />
      </Row>
      {addVisible && (
        <AddReportForm
          scheduled
          scheduleServiceName={scheduleServiceName}
          formLabel={createLabel}
          classification={classification}
          options={options}
          close={hideAddDataExtract}
          initialValues={{
            export_format: "csv",
            export_destination: "download",
            recipients_original: "",
            properties: {
              mark_records_exported: true,
              filters: {
                transaction_classifications_chosen: ["All"],
                subsidiary_ids_original: [],
                include_external_id_has_data: false,
              },
            },
            schedule: {
              id: null,
              interval: "DAILY",
              hour_of_day: 0,
              day_of_week: 1,
              day_of_month: 1,
            },
          }}
        />
      )}
      {editItem && (
        <AddReportForm
          scheduled
          scheduleServiceName={scheduleServiceName}
          formLabel={createLabel}
          classification={classification}
          options={options}
          close={hideEditRecurring}
          initialValues={editItem}
          editing={true}
        />
      )}
    </Container>
  );
};

export default ListSchedules;
