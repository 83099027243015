import CustomModal from "components/modals/customModal";
import { restApiService } from "providers/restApi";
import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type VendorReportCreatedModalPropsType = {
  show: boolean;
  onHide: () => void;
};

const VendorReportCreatedModal = ({ show, onHide }: VendorReportCreatedModalPropsType) => {
  const { t } = useTranslation();
  return (
    <CustomModal
      show={show}
      onHide={onHide}
      header={t("admin.pages.vendor.reportCreated")}
      body={
        <p>
          {t("admin.pages.vendor.reportCreatedNote")}
          <a className="link" href={restApiService.angularBaseURL() + "reports/list"}>
            {" "}
            Reports page{" "}
          </a>
          {t("admin.pages.vendor.downloadIt")}
        </p>
      }
      size="lg"
      footer={
        <>
          <Button variant="secondary" onClick={onHide}>
            {t("close")}
          </Button>
        </>
      }
    />
  );
};

export default VendorReportCreatedModal;
