import { Mandatory } from "components/forms/bootstrapFields";
import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import { Button, ButtonGroup, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { WrappedFieldProps } from "redux-form";
import { getDateFormat } from "services/general/dateSvc";
import ReactDatePicker from "react-datepicker";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { IUser } from "services/common/user/userTypes";
import adminCommonSvc from "services/admin/commonSvc";
import style from "./datePicker.module.css";
import moment from "moment";

interface DatePickerPropsType extends WrappedFieldProps {
  className?: string;
  label?: ReactNode;
  tooltip?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  id?: string;
  inCompanyDateFormat?: boolean;
}

const DATE_TODAY = moment().format("MM/DD/YYYY");

const DatePicker = ({
  className,
  input,
  id = "react-date-picker",
  label,
  tooltip,
  meta: { touched, error, warning },
  required,
  disabled,
  maxDate,
  minDate,
  inCompanyDateFormat,
}: DatePickerPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const datePickerRef = useRef<any>();

  const getSelectDate = useCallback((): Date | null => {
    return adminCommonSvc.getDateObjFromDateStr(input.value);
  }, [input.value]);

  const onDateChange = (value: Date | null) => {
    // before update date on form convert it to company date string instead of js Date object
    const dateStr = value ? moment(value, "MM/DD/YYYY").format("MM/DD/YYYY") : "";
    const dateStrFormated = inCompanyDateFormat
      ? adminCommonSvc.companyFormatedDate(dateStr, currentUser)
      : value
        ? moment(value).format("YYYY-MM-DD")
        : null;
    input.onChange(dateStrFormated);
  };

  const getDateValue = useCallback((): string => {
    return adminCommonSvc.companyFormatedDate(input.value, currentUser);
  }, [currentUser, input.value]);

  // Function to set the date to today
  const setToday = () => {
    const dateStr = DATE_TODAY;
    const dateStrFormated = inCompanyDateFormat
      ? adminCommonSvc.companyFormatedDate(dateStr, currentUser)
      : moment().format("YYYY-MM-DD");
    input.onChange(dateStrFormated);
    datePickerRef?.current?.setOpen(false); // Close the DatePicker
  };

  const clearDate = () => {
    input.onChange(null);
    datePickerRef?.current?.setOpen(false); // Close the DatePicker
  };

  const isTodayDisabled = useMemo(() => {
    let today = moment();
    if (!maxDate && minDate) {
      return today.isBefore(minDate, "day");
    } else if (!minDate && maxDate) {
      return today.isAfter(maxDate, "day");
    } else if (minDate && maxDate) {
      if (today.isSameOrAfter(minDate, "day") && today.isSameOrBefore(maxDate, "day")) {
        return false;
      } else {
        return true;
      }
    }
  }, [minDate, maxDate]);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
          {tooltip}
        </Form.Label>
      )}
      <Row className="m-0 p-0 d-flex flex-row flex-nowrap">
        <ReactDatePicker
          {...input}
          ref={datePickerRef}
          // required={required}
          onChange={onDateChange}
          // onBlur={onDateChange} // TODO: work on edit date text feild handling logic
          selected={getSelectDate()}
          // dateFormat={String(getDateFormat(currentUser).toUpperCase())}
          placeholderText={String(getDateFormat(currentUser, true))}
          value={getDateValue()}
          className={`${touched && error ? "requiredField" : "w-100"}  icon-calender`}
          disabled={disabled}
          minDate={minDate ? new Date(minDate) : null}
          maxDate={maxDate ? new Date(maxDate) : null}
          // The 'popperProps: positionFixed' configuration ensures that the DatePicker calendar popper is displayer above its parent,
          // preventing any cut-off appearance. It ensuring the full visibility of the calendar
          popperProps={{ positionFixed: true }}
          id={id}
        >
          <div>
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <div className={`mb-2 ${style.buttonGrp}`}>
                  <Button variant="secondary" className={style.btnSm} onClick={setToday} disabled={isTodayDisabled}>
                    Today
                  </Button>
                  <Button className={style.btnSm} onClick={clearDate}>
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </ReactDatePicker>
        {!label && required && (
          <div className="ml-2">
            <Mandatory required={required}></Mandatory>
          </div>
        )}
      </Row>
      {touched && error && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default DatePicker;
