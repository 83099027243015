import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "./../vendorDocumentUploadModal.module.css";
import { VDocumentUploadContext } from "../vendorDocumentUploadContext";

const DocumentRequirementForms = () => {
  const context = useContext(VDocumentUploadContext);

  useEffect(() => {
    return () => {
      context?.setSelectedFrom(undefined);
    };
  }, []);

  return (
    <>
      {/* {context?.selectedFrom && (
        <Row className="d-flex justify-content-end px-5 py-4">
          <button onClick={() => context.setSelectedFrom(undefined)} className="icon icon-arrow-left-blue border-0"></button>
        </Row>
      )} */}

      {!context?.selectedFrom && (
        <Row>
          <Col>
            <Row>
              <Col className="d-flex justify-content-center">
                <div className={style.formsHeadingNote}>
                  <p>
                    Rather than uploading a file, you can click on the corresponding form below and fill it out directly
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-3"></Row>
          </Col>
        </Row>
      )}

      <div>
        {context?.selectedFrom === "w9" && context?.W9Form}
        {context?.selectedFrom === "w8" && context?.W8Form}
        {context?.selectedFrom === "w8bene" && <Container>{context.W8BenEForm}</Container>}
        {context?.selectedFrom === "diversity" && context.diversityTrackingForm}

        {!context?.selectedFrom && (
          <>
            {(context?.documentRequirement?.document_type?.toLocaleLowerCase().includes("w9") ||
              !context?.documentRequirement) && (
              <>
                <div
                  className={`d-flex justify-content-start`}
                  role="button"
                  onClick={() => context?.setSelectedFrom("w9")}
                >
                  <div className="d-flex align-items-center">
                    <i className={`${style.icon50} icon-form`} />
                    W9
                  </div>
                </div>
                <hr className={style.fullBorder} />
              </>
            )}

            {
              // show w8ben form if name of document requirement is include w8
              ((context?.documentRequirement?.document_type?.toLocaleLowerCase().includes("w8") &&
                !context?.documentRequirement?.document_type?.toLocaleLowerCase().includes("bene") &&
                !context?.documentRequirement?.document_type?.toLocaleLowerCase().includes("ben-e")) ||
                !context?.documentRequirement) && (
                <>
                  <div
                    className={`d-flex justify-content-start`}
                    role="button"
                    onClick={() => context?.setSelectedFrom("w8")}
                  >
                    <div className="d-flex align-items-center">
                      <i className={`${style.icon50} icon-form`} />
                      W8Ben
                    </div>
                  </div>
                  <hr className={style.fullBorder} />
                </>
              )
            }

            {((context?.documentRequirement?.document_type?.toLocaleLowerCase().includes("w8") &&
              (context?.documentRequirement?.document_type?.toLocaleLowerCase().includes("ben-e") ||
                context?.documentRequirement?.document_type?.toLocaleLowerCase().includes("bene"))) ||
              !context?.documentRequirement) && (
              <>
                <div
                  className={`d-flex justify-content-start`}
                  role="button"
                  onClick={() => context?.setSelectedFrom("w8bene")}
                >
                  <div className="d-flex align-items-center">
                    <i className={`${style.icon50} icon-form`} />
                    W8BenE
                  </div>
                </div>
                <hr className={style.fullBorder} />
              </>
            )}

            {(context?.documentRequirement?.document_type?.toLocaleLowerCase().includes("diversity") ||
              !context?.documentRequirement) && (
              <>
                <div
                  className={`d-flex justify-content-start`}
                  role="button"
                  onClick={() => context?.setSelectedFrom("diversity")}
                >
                  <div className="d-flex align-items-center">
                    <i className={`${style.icon50} icon-form`} />
                    Diversity Tracking
                  </div>
                </div>
                <hr className={style.fullBorder} />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DocumentRequirementForms;
