import useIsMounted from "components/common/hooks/useIsMounted";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import { MenuPlacement, MultiValue } from "react-select";
import AsyncSelect from "react-select/async";
import { WrappedFieldProps } from "redux-form";
import { IDType } from "services/common/types/common.type";
import { compare, onBlur, onChange, parseForSelect } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";

interface AsyncMultiContactPickerPropsType extends WrappedFieldProps {
  contactType?: string;
  enableTeamMerge?: boolean;
  onChangeCallBack?: (option: ContactOptionType[]) => void;
  menuPlacement?: MenuPlacement;
}

export type ContactOptionType = {
  value?: number;
  label?: string;
  raw?: RawType;
};

export type RawType = {
  id?: number;
  name?: string;
  email?: null | string;
  external_id?: null | string;
  is_team?: boolean;
};

type ArrayOfObjType<Type> = Array<Type & { id?: IDType; name?: string; email: string }>;

const optimizedLoadOptions = async (inputValue: any) => {
  // 1.using chunking to load options
  // 2. break inputValue array in to chunk of 100
  // cashing will work only if data is added remove from last for other case it will make api calls

  if (_.isArray(inputValue)) {
    const chunks = [];
    for (let i = 0; i < inputValue.length; i += 100) {
      chunks.push(inputValue.slice(i, i + 100));
    }

    const promises = chunks.map((chunk) => {
      return restApiService.get("contact.lk", { id: chunk, items: 100 }, null, true, null, true);
    });

    const vendorChunks = await Promise.all(promises);

    // reduce all vendorChunks in on array
    const results = vendorChunks.reduce((acc, curr) => {
      return [...acc, ...curr.data];
    }, []);

    const options = parseForSelect(results);
    return options;
  }
  return [];
};

const AsyncMultiContactPicker = ({
  input,
  contactType,
  enableTeamMerge,
  onChangeCallBack,
  menuPlacement,
  meta: { error, touched },
}: AsyncMultiContactPickerPropsType) => {
  const [selected, setSelected] = useState<ContactOptionType[]>([]);
  const selectedContactIds = _.isArray(input.value) ? input.value.map((v) => v.value) : [];
  const [loading, setLoading] = useState(false);
  const mounted = useIsMounted();

  // initialilly it will load all the label for ids (input.values)
  const findSelected = useCallback(async () => {
    try {
      if (_.isArray(input.value) && !input.value.length) return setSelected([]);
      setLoading(true);
      const contacts = await optimizedLoadOptions(input.value);
      if (mounted.current) setSelected(contacts);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    findSelected();
  }, []);

  const onAddContact = (contact: ContactOptionType) => {
    setSelected([...selected, contact]);
  };

  const removeContact = (removed: ContactOptionType[]) => {
    if (_.isArray(removed) && removed.length === 1) {
      // remove one
      setSelected(selected.filter((selectedContact) => selectedContact.value !== removed[0].value));
    } else if (_.isArray(removed) && removed.length > 1) {
      // remove all
      setSelected([]);
    }
  };

  const onChangeContacts = (contacts: MultiValue<ContactOptionType>) => {
    // added
    const added = contacts.filter((contact) =>
      _.isArray(selectedContactIds) ? !selectedContactIds.includes(contact.value) : true,
    );
    if (_.isArray(added) && added.length > 0) {
      onAddContact(added[0]);
    }

    // removed
    const removed = selected.filter((selectedContact: ContactOptionType) => {
      const contactIds = contacts.map((contact) => contact.value);
      return !contactIds.includes(selectedContact.value);
    });
    if (_.isArray(removed) && removed.length > 0) {
      removeContact(removed);
    }

    // this is updating the value in redux form
    onChange(input, contacts);
    if (onChangeCallBack) {
      onChangeCallBack(contacts as ContactOptionType[]);
    }
  };

  const parseForSelect = <T,>(options: ArrayOfObjType<T>) => {
    return options
      .map((option) => {
        return {
          value: option.id,
          label: option.name,
          raw: option,
        };
      })
      .sort(compare);
  };

  const getContactAndTeamPromise = async (params: any) => {
    try {
      const contactResponse = await restApiService.get("contacts.lk", params, null, true, null, true);
      const teamResponse = await restApiService.get("teams.lk", null, null, true, null, true);

      if (_.isArray(contactResponse.data) && _.isArray(teamResponse.data)) {
        teamResponse.data = teamResponse.data.map((team: any) => {
          team.is_team = true;
          return team;
        });
        return [...parseForSelect(contactResponse.data), ...parseForSelect(teamResponse.data)];
      }
    } catch (error) {
      console.log(error);
      CreateNotification("Error", "Service Error", NotificationType.danger);
    }
  };

  const getOnlyContactPromise = async (params: any) => {
    try {
      const contactResponse = await restApiService.get("contacts.lk", params, null, true, null, true);
      if (_.isArray(contactResponse.data)) {
        return parseForSelect(contactResponse.data);
      }
    } catch (error) {
      console.log(error);
      CreateNotification("Error", "Service Error", NotificationType.danger);
    }
  };

  const getFilteredContactsLite = async (nameOrEmail: string) => {
    try {
      const params: {
        status: string;
        contact_type?: string;
        name_or_email?: string;
      } = { status: "ACTIVE" };
      if (contactType) {
        params.contact_type = contactType;
      }
      params.name_or_email = nameOrEmail;

      if (enableTeamMerge) {
        return await getContactAndTeamPromise(params);
      } else {
        return await getOnlyContactPromise(params);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // used to find option when user search
  const loadOptions: any = async (inputValue: string, callback: any) => {
    try {
      callback(await getFilteredContactsLite(inputValue));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AsyncSelect
        value={selected}
        placeholder="Search/select"
        cacheOptions
        loadOptions={loadOptions}
        formatOptionLabel={(option, context) => {
          return (
            <small>
              <strong>{option?.raw?.name}</strong>
              {Boolean(option?.raw?.email) && context.context === "menu" && (
                <strong>
                  <br />
                  {option?.raw?.email}
                </strong>
              )}
            </small>
          );
        }}
        onChange={(value) => onChangeContacts(value)}
        onBlur={() => onBlur(input, input.value)}
        menuPlacement={menuPlacement}
        defaultOptions
        isMulti={true}
        isLoading={loading}
      />
      {error && touched && <p className={"text-danger"}>{error}</p>}
    </>
  );
};

export default AsyncMultiContactPicker;
