import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import adminCommonSvc from "services/admin/commonSvc";
import PoCommonSvc from "services/admin/purchaseOrders/poCommonSvc";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import AsyncSingleListPicker from "../abstractListPicker/asyncSingleListPicker";
import { ProductItemPickerType } from "./productItemPickerTypes";

const ProductItemPicker = ({
  name,
  required,
  callBack,
  containerClassName,
  disabled,
  instanceId,
  labelClassName,
  menuPosition,
  menuPlacement,
  objectPropertyKey,
  label,
  modelData,
  parentObj,
  placeholder = "search / enter new item",
  tooltip,
  validate,
  modelName,
  externalOptionComponent,
}: ProductItemPickerType.TProuductItemPickerProps) => {
  const { getValues } = useFormContext();
  const { parentObj: parentObjField, modelData: modelDataField } = commonService.modifyParentModalDataRefStr({
    parentObj,
    modelData,
  });
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const poCommonSvc = PoCommonSvc.getInstance(currentUser);

  const handleSelection = useCallback(
    (selected: ProductItemPickerType.TProductItemOption | undefined | null) => {
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack],
  );

  const isDataRestrictBySubsidiary = useCallback(() => {
    const parentObjData = getValues(!_.isNil(parentObjField) ? parentObjField : "");
    if (parentObjData?.is_request_PO) {
      return (
        currentUser.company?.po_request_data_restrict_by_subsidiary ||
        adminCommonSvc.showItemsBySubsidiary(parentObjData, currentUser)
      );
    }
    return adminCommonSvc.showItemsBySubsidiary(parentObjData, currentUser);
  }, [currentUser, getValues, parentObjField]);

  const getPurchaseOrderParamsLine = useCallback(
    (params: Record<string, any>) => {
      const purchaseOrder = getValues(!_.isNil(parentObjField) ? parentObjField : "");
      const poItem = getValues(!_.isNil(modelDataField) ? modelDataField : "");

      if (isDataRestrictBySubsidiary()) {
        params.subsidiary_id = purchaseOrder?.subsidiary_id;
      }
      poCommonSvc.setItemSubtype(purchaseOrder, poItem, params);
      if (purchaseOrder.sub_type) {
        params.po_subtype = purchaseOrder.sub_type;
      }
      if (purchaseOrder?.vendor?.has_product_items) {
        params.vendor_id = purchaseOrder.vendor.id;
      }
    },
    [getValues, isDataRestrictBySubsidiary, modelDataField, parentObjField, poCommonSvc],
  );

  const getPurchaseOrderParams = useCallback(
    (params: Record<string, any>) => {
      const purchaseOrder = getValues(!_.isNil(parentObjField) ? parentObjField : "");
      const poItem = getValues(!_.isNil(modelDataField) ? modelDataField : "");

      if (isDataRestrictBySubsidiary()) {
        params.subsidiary_id = purchaseOrder?.subsidiary_id;
      }
      poCommonSvc.setItemSubtype(purchaseOrder, poItem, params);
    },
    [getValues, isDataRestrictBySubsidiary, modelDataField, parentObjField, poCommonSvc],
  );

  const getUploadQueueParams = useCallback(
    (params: Record<string, any>) => {
      if (isDataRestrictBySubsidiary()) {
        const invoice = getValues(!_.isNil(parentObjField) ? parentObjField : "");
        params.subsidiary_id = invoice.subsidiary_id;
      }
    },
    [getValues, isDataRestrictBySubsidiary, parentObjField],
  );

  const params: Record<string, any> = useMemo(() => {
    const params: Record<string, any> = { active: true, limit: 100 };
    if (modelName === "purchase_order") {
      getPurchaseOrderParams(params);
    } else if (modelName === "purchase_order_line") {
      getPurchaseOrderParamsLine(params);
    } else if (modelName === "upload_queue") {
      getUploadQueueParams(params);
    }

    return params;
  }, [getPurchaseOrderParams, getPurchaseOrderParamsLine, getUploadQueueParams, modelName]);

  return (
    <AsyncSingleListPicker
      instanceId={instanceId}
      name={name}
      required={required}
      objectPropertyKey={objectPropertyKey ?? (!_.isNil(modelDataField) ? modelDataField + "product_item" : "")}
      fetchUrl="product_items.lk"
      searchParamName="product_name"
      cache
      callBack={handleSelection}
      containerClassName={containerClassName}
      disabled={disabled}
      externalOptionComponent={externalOptionComponent}
      label={label}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      tooltip={tooltip}
      validate={validate}
      placeholder={placeholder}
      params={params}
      labelClassName={labelClassName}
    />
  );
};

export default ProductItemPicker;
