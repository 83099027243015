import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import { currency_codes } from "../../../../app.svc.Lookup";
import { withTranslation } from "react-i18next";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * reduxform and reduxformpickers.
 */
class CurrencyPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currencies: currency_codes.map((item, i) => ({
        value: item.value,
        symbol: item.symbol,
        label: item.symbol + " " + item.name,
      })),
    };
  }

  findDefault = () => {
    let found = null;
    this.state.currencies.forEach((currency) => {
      if (currency.value === this.props.currency_code) {
        found = currency;
      }
    });
    return found;
  };

  findSelected() {
    if (!this.state.currencies || !this.props.selected) {
      return null;
    }
    return this.state.currencies.find((option) => {
      if (option.value === this.props.selected.value) {
        return option;
      }
      return null;
    });
  }

  render() {
    return (
      <Form.Group
        as={Col}
        md={this.props.colSize ? this.props.colSize : 4}
        className={this.props.color ? this.props.color : "color-red"}
      >
        <Form.Label style={{ textTransform: "capitalize" }}>
          {this.props.t("currency")}: {!this.props.hide_required_sign && <span className={"color-red"}>*</span>}
        </Form.Label>
        <Select
          value={this.findSelected()}
          defaultValue={this.findDefault()}
          menuIsOpen={this.props.showDropdownOptions}
          styles={{
            valueContainer: (base) => ({
              ...base,
              height: 40,
              minHeight: 40,
            }),
          }}
          placeholder={"select/search the list"}
          options={this.state.currencies}
          onChange={(e) => {
            if (this.props.currencyPickerCallback) {
              this.props.currencyPickerCallback(e);
            }
          }}
          isDisabled={this.props.disabled}
        />
        {this.props.valid === false && <div style={{ fontSize: "80%", color: "#dc3545" }}>Currency required</div>}
      </Form.Group>
    );
  }
}

export default withTranslation()(CurrencyPicker);
