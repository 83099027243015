import React, { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm, FieldArray, getFormValues, initialize } from "redux-form";
import { Col, Form, Row } from "react-bootstrap";
import Style from "./workFlow.module.css";
import { RenderField } from "../forms/bootstrapFields";
import { setActiveLabel } from "../../reducers/approvalSettingsReducers";
import _ from "lodash";
import { useDispatch } from "react-redux";

let ApprovalForm = (props) => {
  const { workFlowStep, handleSubmit } = props;
  const dispatch = useDispatch();
  const state = useSelector((state) => state.approvalSettingsReducer);
  const workFlowSteps = useSelector((state) => state.approvalSettingsReducer.workFlowSteps);
  const activeStepIndex = useSelector((state) => state.approvalSettingsReducer.activeStepIndex);
  const staticWorkFlows = useSelector((state) => state.approvalSettingsReducer.staticWorkFlows);

  // as workflow step is getting destroyed on mount,
  // so check if any step is active , if yes the set workflowstep
  // on if workflow step is not avaliable
  const checkActiveWorkFlowStep = useCallback(() => {
    if (_.isArray(workFlowSteps) && workFlowSteps.length > 0 && !(workFlowStep && !_.isEmpty(workFlowStep))) {
      dispatch(initialize("testTriggerForm", workFlowSteps[activeStepIndex]));
    }
  }, [activeStepIndex, dispatch, workFlowStep, workFlowSteps]);

  const testText = (workflow_type) => {
    if (!workflow_type) return;
    const result = workflow_type.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const workflowTypeTextFormat = (workflow_type) => {
    const result = workflow_type.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  useEffect(() => {
    checkActiveWorkFlowStep();
  }, []);

  if (workFlowStep && !_.isEmpty(workFlowStep)) {
    const triggerNames = workFlowStep.value.triggers ? workFlowStep.value.triggers.map((trigger) => trigger.key) : [];

    return (
      <Form onSubmit={handleSubmit}>
        <Row className="mx-4 fontSizeNormal secondaryDarkBlueFontColor">
          <Col md={6} style={{ paddingLeft: 0 }}>
            <Col>
              <Form.Group style={{ marginBottom: 0 }}>
                <div style={{ paddingTop: 3 }}>
                  <Field
                    name="approvableID"
                    component={RenderField}
                    type="number"
                    placeholder={"Test" + testText(workFlowStep.workflow_type) + " ID"}
                    className={`${Style.customInput}  w-100`}
                  />
                </div>
              </Form.Group>
            </Col>
          </Col>
          <Col md={2}>
            <button className="btn btn-primary" type="submit">
              Test Trigger
            </button>
          </Col>
          <div style={{ paddingTop: 10 }}>
            {state.willTrigger == true && <h5 style={{ color: "#1C9B02" }}>Will Trigger</h5>}
            {state.willTrigger == false && <h5 style={{ color: "#C40125" }}>Will Not Trigger</h5>}
            {state.willTrigger == 404 && (
              <h5 style={{ color: "#C40125" }}>No {workflowTypeTextFormat(state.workFlowType)} found</h5>
            )}
          </div>
        </Row>
      </Form>
    );
  } else return <></>;
};

// The order of the decoration does not matter.
// Decorate with redux-form
ApprovalForm = reduxForm({
  form: "testTriggerForm", // a unique identifier for this form
})(ApprovalForm);

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeLabel: (label) => dispatch(setActiveLabel(label)),
  };
};

ApprovalForm = connect(null, mapDispatchToProps)(ApprovalForm);

ApprovalForm = connect((state, props) => ({
  onSubmit: props.onSubmit,
  workFlowStep: getFormValues("workFlowApprovalForm")(state),
}))(ApprovalForm);

export default ApprovalForm;
