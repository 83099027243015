import { AxiosResponse } from "axios";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { restApiService } from "../../../providers/restApi";
import { getListOptionsType } from "../../common/types/common.type";
import { ChangeOrderObjectOptionsType } from "./changeOrders/purchaseOrders.types";
import { PurchaseOrderType } from "./purchaseOrderType";

class PurchaseOrdersApis {
  static async getNewPurchaseOrder() {
    try {
      const response: AxiosResponse<any> = await restApiService.get("purchase_orders/new");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getPurchaseOrderList({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<{
        meta: PurchaseOrderType.ListMetaDataType;
        data: PurchaseOrderType.ListType[];
      }> = await restApiService.get("purchase_orders", filter, null, true, null, cache);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getPurchaseOrder(id: number | string) {
    try {
      const response: AxiosResponse<PurchaseOrderType.DetailType> = await restApiService.get("purchase_orders/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deletePurchaseOrder(id: number) {
    try {
      const response: AxiosResponse<PurchaseOrderType.DetailType> = await restApiService.delete(
        "purchase_orders/" + id,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async uploadPOAttachment(purchaseOrder: any, po_id: any) {
    try {
      const formData = new FormData();
      purchaseOrder.assets_attributes.forEach((asset: any, key: number) => {
        if (!asset.document_type) {
          formData.append(`invoice[assets_attributes[${key}][asset_file]]`, asset);
          formData.append(`invoice[assets_attributes[${key}][file_name]]`, asset.name);
        }
      });
      const response = await restApiService.patch(`/po_requests/${po_id}`, formData);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status == 422) {
        const errrorMessage = error.response.data["assets.asset_file_file_name"]
          ? error.response.data["assets.asset_file_file_name"][0]
          : "error";

        CreateNotification("Invalid Attachment", errrorMessage, NotificationType.danger);
      } else {
        console.log("error", error);
      }
    }
  }

  // static async addPurchaseOrder(invoicePayload: PurchaseOrderType.AddPurchaseOrderPayloadType) {

  static async addPurchaseOrder(payload: PurchaseOrderType.AddPurchaseOrderPayloadType) {
    try {
      const response: AxiosResponse<PurchaseOrderType.DetailType> = await restApiService.post(
        "purchase_orders",
        null,
        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async editPurchaseOrder(id: string, payload: PurchaseOrderType.EditPurchaseOrderPayloadType) {
    try {
      const response: AxiosResponse<PurchaseOrderType.DetailType> = await restApiService.patch(
        "purchase_orders/" + id,
        null,
        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //TODO: have to fix this apis
  // out of pattern
  static getPODetails = async (id: number) => {
    try {
      const res: AxiosResponse<ChangeOrderObjectOptionsType> = await restApiService.get(`purchase_orders/${id}`);
      if (res && res.data) {
        //TODO: should not have any of the business logic here
        res.data.po_items_attributes = res.data.po_items;
        res.data.invoice_debit_accounts_attributes = res.data.invoice_debit_accounts;
        delete res.data.invoice_debit_accounts;
        delete res.data.po_items;
        return res.data;
      }
      return null;
    } catch (err) {
      // TODO: throw error
      return null;
    }
  };
}

export default PurchaseOrdersApis;
