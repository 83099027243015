import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { withTranslation } from "react-i18next";
import * as actionType from "../../../../../actions/actionTypes";
import RestApi from "../../../../../providers/restApi";
import { BreadcrumbTrail } from "../../../../navigation/navigationLinks";
import TabNavigation from "../../../../navigation/tabNavigation";
import PicturePreview from "../../../../widgets/fileUpload/picturePreview";
import ConfirmationModal from "../../../../common/modals/confirmation";
import { CreateNotification, NotificationType } from "../../../../../services/general/notifications";
import Loader from "../../../../spinners/loader";
import InvoicesNavsTabs from "../../../../../pages/admin/invoice/nav";

class ProcessPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetsValid: true,
      files: [],
      loaded: false,
      showKeepInvoiceShellModal: false,
      validated: false,
    };
    this.onDrop = this.parseUploadFiles;
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.props.clearInvoice();
    this.setState({ loaded: true });
  }

  onSaveCallback = () => {
    this.props.clearInvoice();
    this.setState({ showKeepInvoiceShellModal: false, loaded: true });
    window.location.href = `${this.restApiService.angularBaseURL()}/invoices_draft_inbox`;
  };

  onCloseCallback = () => {
    this.restApiService.delete(`invoices/${this.props.invoice.id}`, {}, {});
    this.props.clearInvoice();
    this.setState({ showKeepInvoiceShellModal: false, loaded: true });
  };

  isNewFile = (files, newFile) => {
    let isNew = true;
    files.map((currentFile) => {
      if (currentFile.name === newFile.name) {
        isNew = false;
      }
    });
    return isNew;
  };

  parseUploadFiles = (files) => {
    let newFiles = [...this.state.files];
    files.map((file) => {
      if (this.isNewFile(newFiles, file) && this.isPdfFile(file)) {
        let newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        newFiles.push(newFile);
      }
    });
    this.setState({ files: newFiles });
  };

  fileDeleted = (targetFile) => {
    let initialFiles = [...this.state.files];
    //note this is a FILTER function
    let newFiles = initialFiles.filter((file) => {
      //this file has an id which means it has been saved before. Find it in redux and modify it
      if (file.id != null && file.id === targetFile.id) {
        let newInvoiceAssets = [...this.props.invoice.assets];
        //need to loop over our files to find the matching one
        newInvoiceAssets.forEach((element) => {
          if (element.id === targetFile.id) {
            element._destroy = true;
          }
        });
        //modify redux with our new state
        this.props.setAssets(newInvoiceAssets);
        //dont include this asset anymore in the components state so it will be removed from view
        return false;
      } else if (file.name === targetFile.name) {
        //if the file names match then we can remove this from the components state
        return false;
      }
      //if nothing matches then this file was not deleted and should stay in view
      return true;
    });
    this.setState({ files: newFiles });
  };

  checkIfAssetsValid = () => {
    return this.state.files && this.state.files.length > 0;
  };

  attachFile = (formdata, file) => {
    const { invoice } = this.props;
    let index = 0;
    if (invoice.assets && invoice.assets.length > 0) {
      invoice.assets.map((asset) => {
        if (asset._destroy) {
          formdata.append("invoice[assets_attributes[" + index + "]id]", asset.id);
          formdata.append("invoice[assets_attributes[" + index + "]_destroy]", asset._destroy);
          index++;
        }
      });
    }
    if (file.id) {
      return;
    }
    formdata.append("invoice[assets_attributes[" + index + "]asset_file]", file);
    index++;
  };

  isPdfFile = (file) => {
    var splitFilename = file.name.split(".");
    return splitFilename[splitFilename.length - 1] === "pdf";
  };

  initializeFormdata = () => {
    const { invoice } = this.props;
    const formdata = new FormData();
    formdata.append("invoice[number]", invoice.number);
    formdata.append("invoice[date]", invoice.date);
    formdata.append("invoice[status]", invoice.status);
    formdata.append("invoice[process_pdf]", true);
    return formdata;
  };

  createInvoiceFromFile = (file) => {
    this.restApiService
      .get("invoices/new")
      .then((val) => {
        if (val.data) {
          this.props.setInvoice(val.data);
          this.props.setInvoiceStatus("DRAFT");
          //we need to use form data for this instead of raw JSON because we are attaching PDF files
          const formdata = this.initializeFormdata();
          this.attachFile(formdata, file);
          this.restApiService
            .formWithImage("invoices", formdata, "post")
            .then((val) => {
              if (val && (val.status === 200 || val.status === 201)) {
                this.props.setInvoice(val.data);
                this.restApiService
                  .post(`invoices/${val.data.id}/extract_data_for_asset`, {}, {})
                  .then((val) => {
                    this.setState({ showconfirmInvoiceProcessed: true });
                    if (val && (val.status === 200 || val.status === 201)) {
                      CreateNotification(
                        this.props.t("success"),
                        this.props.t("processInvoicePdf.success"),
                        NotificationType.success,
                      );
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    let errorString = "";
                    if (err.response && err.response.data) {
                      for (const [key, value] of Object.entries(err.response.data)) {
                        errorString += key + ": " + value + "\n";
                      }
                    } else {
                      errorString = this.props.t("errors.genericSupport");
                    }
                    CreateNotification(this.props.t("error"), errorString, NotificationType.danger);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              let errorString = "";
              if (err.response && err.response.data) {
                for (const [key, value] of Object.entries(err.response.data)) {
                  errorString += key + ": " + value + "\n";
                }
              } else {
                errorString = this.props.t("errors.genericSupport");
              }
              CreateNotification(this.props.t("error"), errorString, NotificationType.danger);
            });
        }
      })
      .catch((error) => {
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning);
      });
  };

  submit = (event) => {
    let valid = event.currentTarget.checkValidity();
    let assetsValid = this.checkIfAssetsValid();
    if (!valid || !assetsValid) {
      return;
    }
    this.setState({ validated: true });
    event.preventDefault();
    event.stopPropagation();

    //we need to use form data for this instead of raw JSON because we are attaching PDF files
    this.state.files.map((file) => {
      this.createInvoiceFromFile(file);
    });
  };

  render() {
    let submitDisabled;
    if (this.state.files.length <= 0) {
      submitDisabled = true;
    } else {
      submitDisabled = false;
    }
    return (
      <Container fluid={true} className={"pt-4 pb-4"}>
        <InvoicesNavsTabs activePageName="Draft" />
        {this.state.showKeepInvoiceShellModal && (
          <ConfirmationModal
            show={this.state.showKeepInvoiceShellModal}
            title={"Keep Invoice Shell"}
            body={
              "Extraction of invoice details failed. Would you like to keep the draft invoices with assets attached?"
            }
            saveCallback={this.onSaveCallback}
            closeCallback={this.onCloseCallback}
            confirmText={"Yes"}
            cancelText={"No"}
          />
        )}
        <BreadcrumbTrail
          to={"/ar/invoices"}
          pageName={this.props.t("breadcrumbs.invoices")}
          title={this.props.t("breadcrumbs.processInvoice")}
        />
        <Loader loaded={this.state.loaded}>
          <Form noValidate validated={this.state.validated} onSubmit={this.submit}>
            <Row>
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section
                    className={
                      "container " + (this.state.assetsValid ? "uploadFileContainer" : "uploadFileContainerNotValid")
                    }
                    style={{ minHeight: "125px", backgroundColor: "white" }}
                  >
                    <div {...getRootProps({ className: "dropzone" })} className="text-center">
                      <input {...getInputProps()} />
                      <h4 className="text-center">{this.props.t("forms.invoiceUpload")}</h4>
                      <button type="button" className="btn btn-primary mt-2">
                        {"Upload Invoice PDF File(s)"}
                      </button>
                      {!this.state.assetsValid && (
                        <div style={{ fontSize: "80%", color: "#dc3545", display: "block" }} className="mt-2">
                          {this.props.t("validation.attachment")}
                        </div>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <Col xs={12} className="mb-2">
                {this.state.files.map((file) => {
                  return <PicturePreview key={file.name} file={file} deleteCallback={this.fileDeleted} />;
                })}
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                <button className="btn btn-primary" type="submit" disabled={submitDisabled}>
                  {this.props.t("submit")}
                </button>
              </Col>
            </Row>
          </Form>
        </Loader>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    invoice: state.invoice,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setInvoice: (payload) => {
      dispatch({ type: actionType.SET_INVOICE, payload: payload });
    },
    clearInvoice: () => {
      dispatch({ type: actionType.CLEAR_INVOICE });
    },
    setPaymentMethod: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_PAYMENT_METHOD, payload: payload });
    },
    setPurchaser: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_PURCHASER, payload: payload });
    },
    setServicePeriodStart: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_SERVICE_PERIOD_START, payload: payload });
    },
    setServicePeriodEnd: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_SERVICE_PERIOD_END, payload: payload });
    },
    setInvoiceNumber: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_NUMBER, payload: payload });
    },
    setInvoiceAmount: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_AMOUNT, payload: payload });
    },
    setInvoiceDate: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_DATE, payload: payload });
    },
    setInvoiceDescription: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_DESCRIPTION, payload: payload });
    },
    setInvoiceStatus: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_STATUS, payload: payload });
    },
    setPO: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_PO, payload: payload });
    },
    setAssets: (payload) => {
      dispatch({ type: actionType.SET_INVOICE_ASSETS, payload: payload });
    },
    currencyPickerCallback: (payload) => {
      dispatch({ type: actionType.SET_CURRENCY, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProcessPdf));
