import React, { memo } from "react";
import MultiPurchaseOrderPicker from "./MultiPicker";
import { PurchaseOrderPickerType } from "./purchaseOrderPickerTypes";

const PurchaseOrderPicker = (props: PurchaseOrderPickerType.TPurchaseOrderPickerPropsType) => {
  return (
    <>
      {
        props.isMulti ? <MultiPurchaseOrderPicker {...props} /> : null
        // <SinglePurchaseOrderPicker {...props}/>
      }
    </>
  );
};

export default memo(PurchaseOrderPicker);
