import React, { useState, useEffect } from "react";
import InputDropdownFilter from "./inputDropdown";
import { restApiService } from "providers/restApi";
import { AxiosResponse } from "axios";
import { Spinner } from "react-bootstrap";

const UserDropdownFilter = ({
  name,
  code,
  options,
  isRequired,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  isRequired?: boolean;
}) => {
  const [userOptions, setUserOptions] = useState<{ label: string; value: string }[]>([]);

  const loadUserOptions = async () => {
    try {
      const response: AxiosResponse<any> = await restApiService.get("users");
      const users: { label: string; value: string }[] = [];
      users.push({ label: "ALL", value: "" });
      response.data.forEach((user: { name: string; id: string }) => {
        users.push({ label: user.name, value: user.id });
      });
      setUserOptions(users);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    loadUserOptions();
  }, []);

  const renderDropdown = () => {
    if (userOptions.length > 1) {
      return <InputDropdownFilter name={name} code={code} options={userOptions} isRequired={isRequired} />;
    } else {
      return (
        <div>
          <h5>Loading Users Filter...</h5>
          <Spinner animation="border" variant="info"></Spinner>
        </div>
      );
    }
  };

  return <InputDropdownFilter name={name} code={code} options={userOptions} isRequired={isRequired} />;
};

export default UserDropdownFilter;
