import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import RestApi from "../../../../providers/restApi";
import { useTranslation } from "react-i18next";
import Panel from "../../../panel/panel";
import RejectContactDetailModal from "./rejectContactDetailModal";
import AcceptContactDetailModal from "./acceptContactDetailModal";
import PickCustomerModal from "./pickCustomerModal";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { ApprovePendingContact } from "../../../../services/contacts/contactService";
import { AiFillWarning } from "react-icons/ai";
import { FiCheckSquare } from "react-icons/fi";
import { Link } from "react-router-dom";
import CollapsePanel from "../../../panel/collapsePanel";
import { contactType } from "./verifyDataTable";

const restApiService: RestApi = new RestApi();

export type propTypes = {
  contact: { id: number; email: string };
};

const AssignedContacts = ({ contact }: propTypes) => {
  const [loaded, setLoaded] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [contacts, setContacts] = useState<any>([]);
  const [selectedContact, setSelectedContact] = useState<contactType>();
  const { t } = useTranslation();

  const getContacts = async () => {
    try {
      const response = await restApiService.get("vendor_contacts", {
        email: contact.email,
      });
      if (response.status === 200) {
        setLoaded(true);
        setContacts(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const approve = async (data: any, rejectedContact: any, sendWelcomeEmail: boolean | null | undefined = null) => {
    try {
      const response = await ApprovePendingContact(data, rejectedContact);
      await getContacts();
      if (response.status === 200) {
        CreateNotification("Success", "Contact Verified", NotificationType.success);
        if (sendWelcomeEmail) {
          const emailResponse = await restApiService.post(
            "contacts/" + response.data.linked_contact_id + "/vendor_portal_send_welcome",
          );
          if (emailResponse.status === 200) {
            CreateNotification("Success", "Welcome Email Sent", NotificationType.success);
          }
        }
      }
    } catch (error) {
      console.log(error);
      CreateNotification("Error", "Unknown error occured. Please contact support", NotificationType.danger);
    }
  };

  const deny = (contact: any) => {
    setShowRejectModal(true);
    setSelectedContact(contact);
  };

  const accept = (contact: any) => {
    setShowAcceptModal(true);
    setSelectedContact(contact);
  };

  const customerPicked = (customer: any) => {
    let add = true;
    if (contacts && contacts.length > 0) {
      //dont want to add another contact if its already assigned
      contacts.forEach((element: any) => {
        if (element.company.id === customer.value) {
          CreateNotification("Error", "Already Assigned", NotificationType.danger);
          add = false;
        }
      });
    }

    if (add) {
      addNewCustomerContact(customer.raw);
      setShowCustomerModal(false);
    }
  };

  const addNewCustomerContact = async (customer: { id: number }) => {
    try {
      const response = await restApiService.post("vendor_contacts", null, {
        contact_id: contact.id,
        company_id: customer.id,
      });
      if (response.status === 201) {
        CreateNotification("Success", "Purchaser Assigned", NotificationType.success);
        getContacts();
      }
    } catch (error) {}
  };

  useEffect(() => {
    getContacts();
    return () => {};
  }, []);

  if (!loaded) {
    return null;
  }
  return (
    <CollapsePanel
      open={true}
      heading={"Assigned Contacts"}
      cardBodyStyle={{ padding: "0px" }}
      leftBtn={t("details.confirmed")}
      onClick={() => {
        setShowCustomerModal(true);
      }}
    >
      <Panel cardBodyStyle={{ padding: 0 }} cardClasses={"animated fadeIn"}>
        <Table striped className="mb-0">
          <thead>
            <tr>
              <th className="primaryFontColor font-weight-bold">{t("details.confirmed")}</th>
              <th className="primaryFontColor font-weight-bold">{t("details.customerId")}</th>
              <th className="primaryFontColor font-weight-bold">{t("details.customer")}</th>
              <th className="primaryFontColor font-weight-bold">{t("details.customerContact")}</th>
              <th className="primaryFontColor font-weight-bold"></th>
            </tr>
          </thead>
          <tbody>
            {contacts &&
              contacts.map((data: any) => {
                return (
                  <tr key={data.id}>
                    <td className="text-center">
                      {data.status === "ACTIVE" ? (
                        <FiCheckSquare className={"acceptedFontColor"} style={{ fontSize: "28px" }} />
                      ) : (
                        <AiFillWarning className={"primaryLinkColor"} style={{ fontSize: "28px" }} />
                      )}
                    </td>
                    <td>
                      {data.company && (
                        <Link className="primaryLinkColor" to={"/ar/purchasers/" + data.company.id}>
                          {data.company.id}
                        </Link>
                      )}
                    </td>
                    <td>{data.company && data.company.name}</td>
                    <td>{data.company && data.company.email}</td>
                    <td>
                      {data.status === "ACTIVE" ? null : (
                        <div>
                          <button
                            type="button"
                            className="circleIconButtons approveCircleIcon"
                            onClick={() => {
                              accept(data);
                            }}
                          ></button>
                          <button
                            type="button"
                            className="circleIconButtons rejectCircleIcon ml-1"
                            onClick={() => {
                              deny(data);
                            }}
                          ></button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        {showRejectModal && (
          <RejectContactDetailModal
            contact={selectedContact!}
            saveCallback={(contact: { raw: any }) => {
              approve(selectedContact!, contact.raw);
            }}
            show={showRejectModal}
            closeCallback={() => {
              setShowRejectModal(false);
            }}
          />
        )}

        {showAcceptModal && (
          <AcceptContactDetailModal
            contact={selectedContact}
            saveCallback={(contact: { raw: any }, sendWelcomeEmail: any) => {
              approve(selectedContact!, contact.raw, sendWelcomeEmail);
            }}
            show={showAcceptModal}
            closeCallback={() => {
              setShowAcceptModal(false);
            }}
          />
        )}

        <PickCustomerModal
          title={t("details.pickCustomer")}
          saveCallback={customerPicked}
          show={showCustomerModal}
          closeCallback={() => {
            setShowCustomerModal(false);
          }}
        />
      </Panel>
    </CollapsePanel>
  );
};

export default AssignedContacts;
