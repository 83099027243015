import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Field, getFormValues } from "redux-form";
import { RenderRadioGroup, RenderDatePicker } from "components/forms/bootstrapFields";
import { required } from "services/validations/reduxFormValidation";
import { RequiredFieldIcon } from "./requiredFieldIcon";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import Style from "../dispute.module.css";
import { CardsDisputesFormState } from "../../../../../../services/admin/cards/disputes/cardDisputes.types";

export const NotAuthorizedReason53 = () => {
  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.option.not_authorized.${key}`);
  };
  const yes_no2 = [
    { label: t2("yes"), value: "Y" },
    { label: t2("no"), value: "N" },
  ];

  const isEmployeeTerminated = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue?.form?.options?.OPTION_3_EMPLOYEE_TERMINATED;
  });

  const isEmployeeTerminatedValidation = useMemo(
    () => (isEmployeeTerminated === "Y" ? [required] : []),
    [isEmployeeTerminated],
  );

  return (
    <>
      <Row>
        <Col sm={{ offset: 2 }}>
          <Row>
            {t2("option_3.terminated")}
            <RequiredFieldIcon></RequiredFieldIcon>
            <div style={{ width: "200px", marginLeft: "16px" }}>
              <Field
                name="form.options.OPTION_3_EMPLOYEE_TERMINATED"
                orientation="horizontal"
                component={RenderRadioGroup}
                group={yes_no2}
                required={true}
                validate={[required]}
              ></Field>
            </div>
          </Row>
          <Row style={{ alignItems: "center" }}>
            {t2("option_3.if_terminated")}
            &nbsp;&nbsp;
            <div className={`${Style.no_form_margin_bottom}`}>
              <Field
                name="form.options.OPTION_3_DATE_OF_TERMINATION"
                component={RenderDatePicker}
                maxDate={new Date()}
                required={isEmployeeTerminated}
                validate={isEmployeeTerminatedValidation}
              ></Field>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};
