import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import style from "./attachmentCarousel.module.css";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

/**
 * Renders a file uploader component
 *
 * @param {{uploadAttachments: (files)=>void}} props - The custom button component to be rendered.
 * @return {ReactNode} The rendered file uploader component.
 */

const AttachmentCarouselFileUploader = ({ uploadAttachments }) => {
  const [attachments, setAttachments] = useState([]);

  const setFiles = (acceptedFiles) => {
    setAttachments((prevState) => [...prevState, ...acceptedFiles]);
  };

  useEffect(() => {
    if (attachments.length) {
      uploadAttachments(attachments);
    }
  }, [attachments]);

  const { t } = useTranslation();

  return (
    <div className={style.fileUploadContainer}>
      <Dropzone onDrop={setFiles} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: "dropzone" })} className="text-center">
              <Row>
                <Col sm className="d-flex justify-content-center">
                  <i className="iconUploader icon-upload-attachment"></i>
                </Col>
              </Row>
              <Row>
                <Col sm className="d-flex justify-content-center">
                  <p className={`${style.dropFileLabel} font-weight-bold`}>{t("serialAttachments.dropHere")}</p>
                </Col>
              </Row>
              <Row>
                <Col sm className="d-flex justify-content-center">
                  <p className={`${style.dropFileLabel} font-weight-bold`}>{t("serialAttachments.or")}</p>
                </Col>
              </Row>
              <Row>
                <Col sm className="d-flex justify-content-center mt-2">
                  <input {...getInputProps()} />
                  <button
                    className={`btn btn-secondary ${style.bgButton}`}
                    type="button"
                    style={{ padding: "6px 25px" }}
                  >
                    {t("serialAttachments.browse")}
                  </button>
                </Col>
              </Row>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default AttachmentCarouselFileUploader;
