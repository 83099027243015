import React from "react";
import { Col } from "react-bootstrap";
import BankCodeValidator from "../../bankCodeValidator";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { required } from "../../../../forms/inputFields";
import AccountType from "../../../managePaymentMethod/fields/accountType";
import BankAccountNumbers from "../../bankAccountNumbers";
import BicValidator from "../../bicValidator";
import PurposeCode from "./purposeCode";

const WireJpy = ({ modelName }: { modelName: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col md="6">
        <BankCodeValidator modelName={`${modelName}`} label={t("common.paymentMethod.bankCode")} noValidate={true} />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.branchCode`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.branchCode")}
          className={`w-100`}
          required
          maxLength={6}
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <AccountType
          label="Account Type:"
          required
          validations={[required]}
          options={[
            { label: "Checking", value: "checking" },
            { label: "Saving", value: "saving" },
          ]}
        />
      </Col>
      <BankAccountNumbers modelName={`${modelName}`} col={4} />{" "}
      {/* Render Bank Account and Confirm Account Number fields */}
      <Col md="4">
        <BicValidator modelName={`${modelName}`} />
      </Col>
      <Col md="12">
        <PurposeCode />
      </Col>
    </>
  );
};

export default WireJpy;
