import { RenderField } from "components/forms/bootstrapFields";
import React, { useCallback, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { INTEGRATION_FORM_NAME, setIntegrationForm } from "reducers/admin/integrationsReducer";
import { Field, getFormValues } from "redux-form";
import { fetchIntegrationProfile } from "services/integrationCommonSvc/integrationCommonSvc";
import { required } from "services/validations/reduxFormValidation";

const ALLOW_ACCOUNT = ["Acumatica", "Intacct", "Netsuite", "Spectrum", "Sage300C", "Deltek Vantagepoint"];
const ALLOW_PASSWORD = ["Acumatica", "Netsuite", "Deltek Vantagepoint"];
const ALLOW_APPLICATION_ID = ["Netsuite", "Deltek Vantagepoint"];
const ALLOW_CONSUMER_TOKEN = ["Acumatica", "Intacct", "Netsuite", "Spectrum", "Deltek Vantagepoint"];
const ALLOW_CONSUMER_SECRET_TOKEN = ["Acumatica", "Intacct", "Netsuite", "Deltek Vantagepoint"];
const ALLOW_ACCESS_TOKEN = ["Intacct", "Netsuite", "Spectrum", "Sage300C"];
const ALLOW_ACCESS_SECRET_TOKEN = ["Intacct", "Netsuite", "Spectrum", "Sage300C"];
const ALLOW_NO_OF_SEATS = ["Netsuite"];
const ALLOW_URL = ["Acumatica", "Spectrum", "Sage300C", "Deltek Vantagepoint"];
const ALLOW_ENDPOINT = ["Acumatica", "Spectrum"];
const ALLOW_TIME_ZONE = ["Netsuite"];
const ALLOW_DEFAULT_CREDIT_ACCOUNT = ["Netsuite", "Intacct"];
const ALLOW_FILE_PATH = ["Quickbooks Enterprise"];
const ALLOW_VERSION = ["Quickbooks Enterprise"];

const ManualAuthentication = () => {
  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const isAllowAccount = ALLOW_ACCOUNT.includes(form.system_name);
  const isAllowPassowrd = ALLOW_PASSWORD.includes(form.system_name);
  const isAllowApplicationId = ALLOW_APPLICATION_ID.includes(form.system_name);
  const isAllowConsumerToken = ALLOW_CONSUMER_TOKEN.includes(form.system_name);
  const isAllowConsumerSecretToken = ALLOW_CONSUMER_SECRET_TOKEN.includes(form.system_name);
  const isAllowAccessToken = ALLOW_ACCESS_TOKEN.includes(form.system_name);
  const isAllowAccessSecretToken = ALLOW_ACCESS_SECRET_TOKEN.includes(form.system_name);
  const isAllowNoOfSeats = ALLOW_NO_OF_SEATS.includes(form.system_name);
  const isAllowUrl = ALLOW_URL.includes(form.system_name);
  const isAllowEndpoint = ALLOW_ENDPOINT.includes(form.system_name);
  const isAllowTimeZone = ALLOW_TIME_ZONE.includes(form.system_name);
  const isAllowDefaultCreditAccount = ALLOW_DEFAULT_CREDIT_ACCOUNT.includes(form.system_name);
  const isAllowFilePath = ALLOW_FILE_PATH.includes(form.system_name);
  const isAllowVersion = ALLOW_VERSION.includes(form.system_name);

  const dispatch = useDispatch();
  const company = useSelector((state) => state.integrationsRed.company);

  const seintegrationProfileDetail = useCallback(async () => {
    const integrationProfileDetail = await fetchIntegrationProfile(form.id);
    if (integrationProfileDetail) {
      form.system_credentials = integrationProfileDetail;
      dispatch(setIntegrationForm({ form: form, company: company }));
    }
  }, []);

  useEffect(() => {
    if (form?.id) {
      seintegrationProfileDetail();
    }
  }, []);

  return (
    <>
      {isAllowAccount && (
        <Col lg="6">
          <Field
            name="form.system_credentials.account"
            label="Account"
            placeholder="Account"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}

      {isAllowPassowrd && (
        <Col lg="6">
          <Field
            name="form.system_credentials.password"
            label="Password"
            placeholder="Password"
            component={RenderField}
            validate={[required]}
            type="password"
          />
        </Col>
      )}

      {isAllowApplicationId && (
        <Col lg="6">
          <Field
            name="form.system_credentials.application_id"
            label="Application Id"
            placeholder="Application Id"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}

      {isAllowConsumerToken && (
        <Col lg="6">
          <Field
            name="form.system_credentials.consumer_token"
            label="Consumer Key"
            placeholder="Consumer Key"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}

      {isAllowConsumerSecretToken && (
        <Col lg="6">
          <Field
            name="form.system_credentials.consumer_secret_token"
            label="Consumer Secret Key"
            placeholder="Consumer Secret Key"
            component={RenderField}
            validate={[required]}
            type="password"
          />
        </Col>
      )}

      {isAllowAccessToken && (
        <Col lg="6">
          <Field
            name="form.system_credentials.access_token"
            label="Access Token"
            placeholder="Access Token"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}

      {isAllowAccessSecretToken && (
        <Col lg="6">
          <Field
            name="form.system_credentials.access_secret_token"
            label="Access Secret Token"
            placeholder="Access Secret Token"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}

      {isAllowNoOfSeats && (
        <Col lg="6">
          <Field
            name="form.system_credentials.no_of_seats"
            label="No Of Seats"
            placeholder="No Of Seats"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}

      {isAllowUrl && (
        <Col lg="6">
          <Field
            name="form.system_credentials.url"
            label="Url"
            placeholder="Url"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}

      {isAllowEndpoint && (
        <Col lg="6">
          <Field
            name="form.system_credentials.endpoint"
            label="Endpoint"
            placeholder="Endpoint"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}
      {isAllowTimeZone && (
        <Col lg="6">
          <Field
            name="form.system_credentials.time_zone"
            label="Time Zone"
            placeholder="time_zone"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}
      {isAllowDefaultCreditAccount && (
        <Col lg="6">
          <Field
            name="form.system_credentials.default_credit_account"
            label="Default Credit Account"
            placeholder="default_credit_account"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}

      {isAllowFilePath && (
        <Col lg="6">
          <Field
            name="form.system_credentials.file_path"
            label="File Path"
            placeholder="file_path"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}
      {isAllowVersion && (
        <Col lg="6">
          <Field
            name="form.system_credentials.version"
            label="Version"
            placeholder="version"
            component={RenderField}
            validate={[required]}
            type="text"
          />
        </Col>
      )}
    </>
  );
};

export default ManualAuthentication;
