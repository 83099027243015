import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { Field, FieldArray, change, getFormValues } from "redux-form";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import ManageInvoiceDebitEntries from "./manageInvoiceExpenseLines/debitLines";
import ManageInvoiceCreditEntries from "./manageInvoiceExpenseLines/creditLines";
import { useDispatch } from "react-redux";
// import { getInvoiceItemsTotal } from "./invoiceItemLineSection";
import { formattedAmount, isDefined } from "services/general/helpers";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import styles from "./invoices.module.css";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { IUser } from "services/common/user/userTypes";
import ManageUseTaxOnSubTotal from "./manageUseTaxOnSubTotal";
import { RenderCheckBox } from "components/forms/bootstrapFields";
import { CreateNotification, NotificationType } from "services/general/notifications";
import adminCommonSvc from "services/admin/commonSvc";
import { CustomLabelSvc } from "services/admin/customLabels/customLabelsSvc";
import { useTranslation } from "react-i18next";

type InvoiceExpenseLineSectionPropsType = {
  valid?: boolean;
  customLabels?: { [key: string]: string[] };
};

const InvoiceExpenseLineSection = ({ valid, customLabels }: InvoiceExpenseLineSectionPropsType) => {
  const formName = "InvoiceForm";
  const dispatch = useDispatch();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const invoice: any = useTypedSelector((state) => getFormValues("InvoiceForm")(state));
  const defaultDecimal = adminCommonSvc.getDefaultDecimal(currentUser);
  const isDebitExist = () => invoiceCommonSvc.isDebitExist(invoice?.debit_entries_attributes);
  const { t } = useTranslation();

  const [isUsedTaxEnable, setUsedTaxEnable] = useState(
    invoice?.is_account_used_tax ? invoice?.is_account_used_tax : false,
  );

  const [submitWithInvoiceAccounts, setSubmitWithInvoiceAccounts] = useState(false);
  const [isUsingSpecificAccount, setUsingSpecificAccount] = useState(false);

  const handleSubmitWithAccountsToggle = (flag: boolean) => {
    setSubmitWithInvoiceAccounts(flag);
    setUsingSpecificAccount(flag);
    dispatch(change(formName, "submitWithAccounts", flag));
  };

  useEffect(() => {
    if (invoice?.submitWithAccounts) {
      setSubmitWithInvoiceAccounts(true);
    }
  }, [invoice?.submitWithAccounts]);

  useEffect(() => {
    if (isDebitExist().length > 0) {
      invoiceCommonSvc.getInvoiceTotalAmount(invoice, currentUser);
    }
  }, [isDebitExist().length]);

  const allowUseDefaultForEditForm = (
    isItemExist: boolean,
    hideForAccountTab?: boolean,
    accountTransactionId?: number,
  ): boolean => {
    return !(isItemExist || accountTransactionId || hideForAccountTab);
  };

  const allowUseDefaultForAddForm = (isItemExist: boolean, hideForAccountTab?: boolean): boolean => {
    return !(isItemExist || hideForAccountTab);
  };

  const allowUseDefault = (invoice: InvoiceType.InvoiceDetailType, currentUser: IUser): boolean => {
    let isItemExist = invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0;
    let hideForAccountTab = invoiceCommonSvc.hideAccountTab("debit accounts", currentUser);
    let accountTransactionId = invoice?.account_transaction?.id;
    if (invoice?.id) {
      return allowUseDefaultForEditForm(isItemExist, hideForAccountTab, accountTransactionId);
    } else {
      return allowUseDefaultForAddForm(isItemExist, hideForAccountTab);
    }
  };

  //clear tax code if select/unselect use tax
  const clearExpensesTaxCode = (isAccountUsedTax: boolean) => {
    if (invoice.is_used_tax && isAccountUsedTax) {
      CreateNotification(
        "Warning",
        "Unselect use tax from item line to use for expense line.",
        NotificationType.warning,
      );
      invoice.is_account_used_tax = false;
      setUsedTaxEnable(false);
      return false;
    }
    invoice?.debit_entries_attributes?.forEach((debitEntry: any) => {
      if (debitEntry._destroy != 1 && !invoiceCommonSvc.isItemDebitLine(debitEntry)) {
        debitEntry.tax_id = null;
        debitEntry.tax_code = null;
        debitEntry.tax = 0;
        debitEntry.amount = debitEntry.sub_amount;
        debitEntry.isUsedTaxEntry = true;
      }
    });
    setUsedTaxEnable(isAccountUsedTax);
  };

  const allowSubmitWithAccount = () => {
    let isItemExist = invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0;
    if (isItemExist) {
      dispatch(change(formName, "submitWithAccounts", true));
    }
  };

  useEffect(() => {
    allowSubmitWithAccount();
  }, [invoice?.invoice_items_attributes?.length > 0]);

  return (
    <ErrorBoundary>
      {allowUseDefault(invoice, currentUser) && (
        <Row className="px-mt-25">
          <Col sm={6}>
            <span>
              <Field
                name="submitWithInvoiceAccounts"
                component="input"
                type="radio"
                checked={!submitWithInvoiceAccounts}
                onChange={() => handleSubmitWithAccountsToggle(false)}
              />
              <span className="px-ml-5"> Use default accounts </span>
            </span>
            <span className="px-ml-20">
              <Field
                name="submitWithInvoiceAccounts"
                component="input"
                type="radio"
                checked={submitWithInvoiceAccounts}
                onChange={() => handleSubmitWithAccountsToggle(true)}
              />
              <span className="px-ml-5"> Use specific accounts</span>
            </span>
          </Col>
        </Row>
      )}
      {currentUser?.company?.enable_used_tax && (
        <Row className="px-mt-15">
          <Col sm={4} className={`${styles.useTaxLabel}`}>
            <Field
              id="allowed_account_use_tax"
              name="is_account_used_tax"
              component={RenderCheckBox}
              type="checkbox"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.invoiceItems.applyUseTax"))}
              isChecked={isUsedTaxEnable}
              onChange={(value: any) => clearExpensesTaxCode(value)}
            />
          </Col>
          {isUsedTaxEnable && invoiceCommonSvc.getAccountsTotal(invoice) != 0 && (
            <Col sm={8} className={`${styles.LabelFontSize14}`}>
              <span>Use Tax Total: </span>
              {formattedAmount(
                (invoiceCommonSvc.getUseTaxTotal(invoice) || 0).toString(),
                invoice?.currency_code,
                defaultDecimal,
                true,
              )}
            </Col>
          )}
        </Row>
      )}
      <Row className={`${styles.invoiceLinesStats} px-mt-15 mr-10`}>
        {invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes).length > 0 && (
          <Col>
            <span>Invoice Item Expense Total: </span>
            {formattedAmount(
              (invoiceCommonSvc.getInvoiceItemsTotal(invoice, currentUser) || "").toString(),
              invoice?.currency_code,
              defaultDecimal,
              true,
            )}
          </Col>
        )}

        {invoiceCommonSvc.getAccountsTotal(invoice) !== 0 && (
          <Col>
            <span>Expense Account Total: </span>
            {formattedAmount(
              (invoiceCommonSvc.getAccountsTotal(invoice) || "").toString(),
              invoice?.currency_code,
              defaultDecimal,
              true,
            )}
          </Col>
        )}

        {isDefined(invoice?.amount) && invoice?.amount !== 0 && (
          <Col>
            <span>Total Invoice Amount: </span>
            {formattedAmount((invoice?.amount || "").toString(), invoice?.currency_code, defaultDecimal, true)}
          </Col>
        )}
      </Row>
      {submitWithInvoiceAccounts && (
        <>
          {invoice?.submitWithAccounts && !currentUser?.company?.invoice?.hide_debit_account && (
            <Row className="px-mt-15">
              <Col>
                <FieldArray
                  key={`invoiceExpenseDebitLines`}
                  name="debit_entries_attributes"
                  component={ManageInvoiceDebitEntries}
                  submitWithAccounts={submitWithInvoiceAccounts}
                  isUsingSpecificAccount={isUsingSpecificAccount}
                  invoice={invoice}
                  customLabels={customLabels}
                />
              </Col>
            </Row>
          )}
          {isUsedTaxEnable &&
            invoice?.itemsObj?.invoiceAccountUseTaxTotal == 0 &&
            invoiceCommonSvc.getAcccountUseTaxTotal(invoice) === 0 && (
              <Row className="px-mt-25">
                <Col>
                  <FieldArray
                    key={`useTaxOnSubTotal`}
                    name="tax_debit_entries_attributes"
                    component={ManageUseTaxOnSubTotal}
                    invoice={invoice}
                    customLabels={customLabels}
                  />
                </Col>
              </Row>
            )}
        </>
      )}
      {!currentUser?.company?.invoice_hide_credit_account && (
        <Row className="px-mt-25">
          <Col>
            <FieldArray
              key={`invoiceExpenseCreditLines`}
              name="credit_entries_attributes"
              submitWithAccounts={submitWithInvoiceAccounts}
              component={ManageInvoiceCreditEntries}
              invoice={invoice}
              customLabels={customLabels}
            />
          </Col>
        </Row>
      )}
    </ErrorBoundary>
  );
};

export default InvoiceExpenseLineSection;
