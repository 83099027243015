import React, { useMemo } from "react";
import changeOrderService from "services/admin/changeOrders/changeOrderSvc";
import style from "./objectChanges.module.css";

type ChangedPropertyPropType<NEW_ITEM_TYPE, OLD_ITEM_TYPE> = {
  // <- generic typing
  newItem: NEW_ITEM_TYPE;
  oldItem?: OLD_ITEM_TYPE;
  property: string;
  formatter?: (value: any) => any;
};

const ChangedProperty = <
  NEW_ITEM_TYPE extends { [index: string]: any },
  OLD_ITEM_TYPE extends { [index: string]: any },
>({
  // <- this { [index: string]: any } is specifying the myObject will always contain key of type string so that not get type error
  newItem,
  oldItem,
  property,
  formatter,
}: ChangedPropertyPropType<NEW_ITEM_TYPE, OLD_ITEM_TYPE>) => {
  const defaultFormatter = useMemo(() => formatter || ((value: any) => value), [formatter]);

  const isUpdate = changeOrderService.fieldIsUpdated(newItem, oldItem, property);
  return (
    <td className={`${isUpdate && style.updatedItem}`}>
      {isUpdate && (
        <>
          {oldItem && oldItem[property] && (
            <div className={style.previousValue}>{defaultFormatter(oldItem[property])}</div>
          )}
          <div>{defaultFormatter(newItem[property])}</div>
        </>
      )}
      {!isUpdate && <div>{oldItem && defaultFormatter(oldItem[property])}</div>}
    </td>
  );
};

export default ChangedProperty;
