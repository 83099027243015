import React, { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm, FieldArray, getFormValues, initialize, formValueSelector } from "redux-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import Style from "./workFlow.module.css";
import { action_types, approver_types, workflow_designation } from "../../lookups/approvalWorkFlowLookups";
import ContactPicker from "../pickers/reduxFormPicker/contactPicker";
import TeamsPicker from "../pickers/reduxFormPicker/teamPicker";
import { RenderTrigger } from "./renderTrigger";
import { RenderField, RenderSelect, RenderTextArea } from "../forms/bootstrapFields";
import { setActiveLabel, setActiveStaticWorkFlow } from "../../reducers/approvalSettingsReducers";
import _ from "lodash";
import { noWhiteSpaceOnly, required } from "../../services/validations/reduxFormValidation";
import { useDispatch } from "react-redux";
import MetadataConfigurationPicker from "../admin/pickers/reduxFormPickers/metadataConfigurationPicker/metadataConfigurationPicker";
import { MetadataConfiguration } from "pages/admin/administration/metadata/metadataConfiguration";
import { AbilityContext } from "services/authorization/authorization";
import { useAbility } from "@casl/react";

let ApprovalForm = (props) => {
  const ability = useAbility(AbilityContext);
  const { workFlowStep, handleSubmit, onChangeLabel, onChangeWorkFlowName } = props;
  const dispatch = useDispatch();
  const workFlowSteps = useSelector((state) => state.approvalSettingsReducer.workFlowSteps);
  const staticWorkFlows = useSelector((state) => state.approvalSettingsReducer.staticWorkFlows);
  const activeStepIndex = useSelector((state) => state.approvalSettingsReducer.activeStepIndex);
  const activeTierIndex = useSelector((state) => state.approvalSettingsReducer.activeTierIndex);
  const isStatic = useSelector((state) => state.approvalSettingsReducer.isStatic);

  // as workflow step is getting destroyed on mount,
  // so check if any step is active , if yes the set workflowstep
  // on if workflow step is not avaliable
  const checkActiveWorkFlowStep = useCallback(() => {
    if (!isStatic && workFlowStep && _.isArray(workFlowSteps) && workFlowSteps.length > 0) {
      dispatch(initialize("workFlowApprovalForm", workFlowSteps[activeStepIndex]));
    } else if (isStatic && _.isArray(staticWorkFlows) && staticWorkFlows.length > 0 && activeStepIndex >= 0) {
      dispatch(initialize("workFlowApprovalForm", staticWorkFlows[activeTierIndex]));
    }
  }, [activeStepIndex, dispatch, workFlowStep, workFlowSteps]);

  useEffect(() => {
    checkActiveWorkFlowStep();
  }, []);

  if (workFlowStep && !_.isEmpty(workFlowStep)) {
    const triggerNames = workFlowStep.value.triggers ? workFlowStep.value.triggers.map((trigger) => trigger.key) : [];

    return (
      <Form onSubmit={handleSubmit}>
        {isStatic && (
          <Row className="mx-4 fontSizeNormal secondaryDarkBlueFontColor">
            <Col md={6}>
              <Form.Group>
                <div className="mt-2">
                  <Field
                    name="value.workflow_name_label"
                    component={RenderField}
                    validate={[required]}
                    type="text"
                    placeholder="Workflow Name"
                    label={"Workflow Name"}
                    className={`${Style.customInput}  w-100`}
                    onChange={(e) => onChangeWorkFlowName(e.target.value)}
                    required
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row className="mx-4 fontSizeNormal secondaryDarkBlueFontColor">
          <Col md={6}>
            <Form.Group>
              <div className="mt-2">
                <Field
                  name="value.label"
                  component={RenderField}
                  validate={[required, noWhiteSpaceOnly]}
                  type="text"
                  placeholder="Label"
                  label={"Label"}
                  className={`${Style.customInput}  w-100`}
                  onChange={(e) => onChangeLabel(e.target.value)}
                  required
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <div className="mt-2">
                <Field
                  name="status"
                  component={RenderSelect}
                  validate={[required]}
                  className={`${Style.customInput}  w-100`}
                  options={[
                    { label: "ACTIVE", value: "ACTIVE" },
                    { label: "INACTIVE", value: "INACTIVE" },
                  ]}
                  label={"Status"}
                  required
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mx-4">
          <Col md={6}>
            <Form.Group>
              <div className="secondaryDarkBlueFontColor">
                <Field
                  name="value.designation"
                  component={RenderSelect}
                  className={`${Style.customInput}`}
                  options={workflow_designation}
                  label={"Designation"}
                />
              </div>
            </Form.Group>
            {workFlowStep.value.designation !== "ACTION" && (
              <div>
                <Form.Group>
                  <div className="secondaryDarkBlueFontColor">
                    <Field
                      name="value.approver_type"
                      component={RenderSelect}
                      className={`${Style.customInput}`}
                      options={approver_types}
                      label={"Approver(s)"}
                    />
                  </div>
                </Form.Group>
                {workFlowStep.value.approver_type === "CUSTOM_APPROVERS" && (
                  <Form.Group>
                    <Field
                      name={`value.approver_description`}
                      className={Style.Custom__Select}
                      component={RenderTextArea}
                      placeholder={"Custom Approvers Description"}
                      isMulti={false}
                      rows={1.5}
                    ></Field>
                  </Form.Group>
                )}
                {workFlowStep.value.approver_type === "CUSTOM_APPROVERS" &&
                  workFlowStep.action_code &&
                  workFlowStep.action_code["approvers"] && (
                    <h5 className="pl-2 success-text">Custom Approvers Implemented</h5>
                  )}
                {workFlowStep.value.approver_type === "CUSTOM_APPROVERS" &&
                  workFlowStep.action_code &&
                  !workFlowStep.action_code["approvers"] && <h5 className="pl-2">Custom Approvers Not Implemented</h5>}
                {workFlowStep.value.approver_type === "TEAM" && (
                  <Form.Group>
                    <Field
                      name="value.team_member"
                      component={TeamsPicker}
                      validate={[required]}
                      required
                      className="my-2 w-100"
                      isMulti={true}
                    ></Field>
                  </Form.Group>
                )}
                {workFlowStep.value.approver_type === "TEAM" &&
                  workFlowStep?.value?.team_member?.includes("Custom Field Team Header Level Manager") && (
                    <Form.Group>
                      <div className="secondaryDarkBlueFontColor">
                        <Form.Label>Custom Header Level Field(s)</Form.Label>
                        <Field
                          component={MetadataConfigurationPicker}
                          name="value.custom_field_header_level_ids"
                          validate={[required]}
                          required={true}
                          props={{
                            isMulti: true,
                          }}
                        ></Field>
                      </div>
                    </Form.Group>
                  )}
                {workFlowStep.value.approver_type === "TEAM" &&
                  workFlowStep?.value?.team_member?.includes("Custom Field Team Line Level Manager") && (
                    <Form.Group>
                      <div className="secondaryDarkBlueFontColor">
                        <Form.Label>Custom Line Level Field(s)</Form.Label>
                        <Field
                          component={MetadataConfigurationPicker}
                          name="value.custom_field_line_level_ids"
                          validate={[required]}
                          required={true}
                          props={{
                            isMulti: true,
                          }}
                        ></Field>
                      </div>
                    </Form.Group>
                  )}
                <div>
                  <Row className="mx-0">
                    {workFlowStep.value.approver_type === "TEAM" &&
                      workFlowStep?.value?.team_member?.includes("Custom Field Team Header Level Manager") && (
                        <Form.Group>
                          <Form.Label className="pr-2">
                            <div>
                              <Field
                                component="input"
                                name="value.custom_field_header_manager_level"
                                type="number"
                                min="1"
                                validate={[required]}
                                required={true}
                                style={{ width: 60 }}
                              ></Field>
                              {"   "} Custom Field Manager Header Level
                            </div>
                          </Form.Label>
                        </Form.Group>
                      )}
                    {workFlowStep.value.approver_type === "TEAM" &&
                      workFlowStep?.value?.team_member?.includes("Custom Field Team Line Level Manager") && (
                        <Form.Group>
                          <Form.Label className="pr-2">
                            <div>
                              <Field
                                component="input"
                                name="value.custom_field_line_manager_level"
                                type="number"
                                min="1"
                                validate={[required]}
                                required={true}
                                style={{ width: 60 }}
                              ></Field>
                              {"   "} Custom Field Manager Line Level
                            </div>
                          </Form.Label>
                        </Form.Group>
                      )}
                    {workFlowStep.value.approver_type === "TEAM" &&
                      workFlowStep?.value?.team_member?.includes("Specified Department Manager") && (
                        <Form.Group>
                          <Form.Label className="pr-2">
                            <div>
                              <Field
                                component="input"
                                name="value.department_manager_level"
                                type="number"
                                min="1"
                                validate={[required]}
                                required={true}
                                style={{ width: 60 }}
                              ></Field>
                              {"   "} Department Header Manager Level
                            </div>
                          </Form.Label>
                        </Form.Group>
                      )}
                    {workFlowStep.value.approver_type === "TEAM" &&
                      workFlowStep?.value?.team_member?.includes("Specified Department Line Level Manager") && (
                        <Form.Group>
                          <Form.Label className="pr-2">
                            <div>
                              <Field
                                component="input"
                                name="value.department_line_manager_level"
                                type="number"
                                min="1"
                                validate={[required]}
                                required={true}
                                style={{ width: 60 }}
                              ></Field>
                              {"   "} Department Line Level Manager Level
                            </div>
                          </Form.Label>
                        </Form.Group>
                      )}
                    {workFlowStep.value.approver_type === "TEAM" &&
                      workFlowStep?.value?.team_member?.includes("Specified Location Manager") && (
                        <Form.Group>
                          <Form.Label className="pr-2">
                            <div>
                              <Field
                                component="input"
                                name="value.location_manager_level"
                                type="number"
                                min="1"
                                validate={[required]}
                                required={true}
                                style={{ width: 60 }}
                              ></Field>
                              {"   "} Location Header Manager Level
                            </div>
                          </Form.Label>
                        </Form.Group>
                      )}
                    {workFlowStep.value.approver_type === "TEAM" &&
                      workFlowStep?.value?.team_member?.includes("Specified Location Line Level Manager") && (
                        <Form.Group>
                          <Form.Label className="pr-2">
                            <div>
                              <Field
                                component="input"
                                name="value.location_line_manager_level"
                                type="number"
                                min="1"
                                validate={[required]}
                                required={true}
                                style={{ width: 60 }}
                              ></Field>
                              {"   "} Location Line Level Manager Level
                            </div>
                          </Form.Label>
                        </Form.Group>
                      )}
                    {workFlowStep.value.approver_type === "TEAM" &&
                      workFlowStep?.value?.team_member?.includes("Specified Business Unit Header Level Manager") && (
                        <Form.Group>
                          <Form.Label className="pr-2">
                            <div>
                              <Field
                                component="input"
                                name="value.business_unit_header_manager_level"
                                type="number"
                                min="1"
                                validate={[required]}
                                required={true}
                                style={{ width: 60 }}
                              ></Field>
                              {"   "} Business Unit Manager Header Level
                            </div>
                          </Form.Label>
                        </Form.Group>
                      )}
                    {workFlowStep.value.approver_type === "TEAM" &&
                      workFlowStep?.value?.team_member?.includes("Specified Business Unit Line Level Manager") && (
                        <Form.Group>
                          <Form.Label className="pr-2">
                            <div>
                              <Field
                                component="input"
                                name="value.business_unit_line_manager_level"
                                type="number"
                                min="1"
                                validate={[required]}
                                required={true}
                                style={{ width: 60 }}
                              ></Field>
                              {"   "} Business Unit Manager Line Level
                            </div>
                          </Form.Label>
                        </Form.Group>
                      )}
                    {workFlowStep.value.approver_type === "TEAM" &&
                      workFlowStep?.value?.team_member?.includes("Specified Project Line Level Manager") && (
                        <Form.Group>
                          <Form.Label className="pr-2">
                            <div>
                              <Field
                                component="input"
                                name="value.project_line_manager_level"
                                type="number"
                                min="1"
                                validate={[required]}
                                required={true}
                                style={{ width: 60 }}
                              ></Field>
                              {"   "} Project Manager Line Level
                            </div>
                          </Form.Label>
                        </Form.Group>
                      )}
                  </Row>
                </div>
                <Row className="mx-0">
                  {(workFlowStep.value.approver_type === "TEAM" ||
                    workFlowStep.value.approver_type === "TRIGGER_DEFAULT") && (
                    <Form.Group>
                      <Form.Label>
                        <Field component="select" name="value.any_member_count">
                          <option value="">All</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Field>
                        {"   "} Team member(s) must approve
                      </Form.Label>
                    </Form.Group>
                  )}
                  {workFlowStep.value.approver_type === "TEAM" && (
                    <Form.Group style={{ paddingLeft: 15, paddingTop: 6 }}>
                      <Form.Label>
                        <Field component="input" type="checkbox" name="parallel_team"></Field>
                        {"   "} Parallel Team
                      </Form.Label>
                    </Form.Group>
                  )}
                </Row>
                {workFlowStep.value.approver_type === "USER" && (
                  <Form.Group>
                    <Field
                      name="value.contact_approver_id"
                      component={ContactPicker}
                      contactType={"USERS"}
                      validate={[required]}
                      required={true}
                    ></Field>
                  </Form.Group>
                )}
              </div>
            )}
          </Col>
          {workFlowStep.value.designation == "ACTION" && (
            <Col md={6}>
              <Form.Group>
                <div className="secondaryDarkBlueFontColor">
                  <Field
                    name="value.action"
                    component={RenderSelect}
                    className={`${Style.customInput}`}
                    options={action_types}
                    label={"Action"}
                  />
                </div>
              </Form.Group>
            </Col>
          )}
          {workFlowStep.value.designation != "ACTION" && (
            <Col md={6} className="my-4">
              <Form.Group className="d-flex flex-column m-2 justify-content-start">
                <Form.Label>
                  <Field component="input" type="checkbox" name="skip_duplicate_approver" />
                  {"   "} Skip Duplicate Approvers
                </Form.Label>
                <Form.Label>
                  <Field component="input" type="checkbox" name="value.require_distinct_approval" />
                  {"   "} Require Distinct Approvals
                </Form.Label>
                <Form.Label>
                  <Field type="checkbox" component="input" name="email_notification" />
                  {"    "} Email Notification
                </Form.Label>
                <Form.Label>
                  <Field type="checkbox" component="input" name="email_reminder" />
                  {"     "} Email Reminder
                </Form.Label>
              </Form.Group>

              {workFlowStep.value.approver_type === "TEAM" && (
                <Form.Group className="m-2">
                  <Form.Label>
                    {" "}
                    <Field component="input" type="checkbox" name="ignore_approval_limit" />
                    {"     "}
                    Ignore Approval Limits
                  </Form.Label>
                </Form.Group>
              )}
            </Col>
          )}
        </Row>
        <Row className="mx-4">
          <FieldArray
            name="value.triggers"
            triggerNames={triggerNames}
            approvalType={workFlowStep.workflow_type}
            component={RenderTrigger}
            className={Style.Custom__Select}
            workFlowStep={workFlowStep}
            rerenderOnEveryChange
          ></FieldArray>
        </Row>
        <Row className="mx-4 my-4">
          <Col md={12} className="secondaryDarkBlueFontColor">
            <Form.Group className="w-100">
              <Field
                component={RenderTextArea}
                validate={[required]}
                name="description"
                style={{ width: "inherit" }}
                className="mt-2"
                label={"Description"}
                required={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="row mx-4 my-4">
          <Col md={12}>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={ability.can("edit", "Administrations") ? false : true}
            >
              Save
            </button>
          </Col>
        </div>
      </Form>
    );
  } else return <></>;
};

// The order of the decoration does not matter.
// Decorate with redux-form
ApprovalForm = reduxForm({
  form: "workFlowApprovalForm", // a unique identifier for this form
})(ApprovalForm);

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeLabel: (label) => dispatch(setActiveLabel(label)),
    onChangeWorkFlowName: (name) => dispatch(setActiveStaticWorkFlow(name, false)),
  };
};

ApprovalForm = connect(null, mapDispatchToProps)(ApprovalForm);

ApprovalForm = connect((state, props) => ({
  onSubmit: props.onSubmit,
  workFlowStep: getFormValues("workFlowApprovalForm")(state),
}))(ApprovalForm);

export default ApprovalForm;
