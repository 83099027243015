import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { findSelectedSingle, onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";
import PickerErrorBlock from "../../pickerErrorBlock";
import { FileTypePickerPropsType } from ".";

export interface SingleFileTypePickerPropsType extends FileTypePickerPropsType {
  fileTypes: { value: string; label: string }[];
}

const SingleFileTypePicker = ({
  className,
  label,
  input,
  meta: { touched, error, warning },
  isMulti,
  tooltip,
  required,
  fileTypes,
}: SingleFileTypePickerPropsType) => {
  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
          {tooltip}
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        value={findSelectedSingle(input, fileTypes)}
        placeholder="search/select"
        onChange={(value) => onChangeSingle(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={fileTypes}
        isMulti={false}
        isClearable
        classNamePrefix="select"
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default SingleFileTypePicker;
