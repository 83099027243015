import React, { useEffect, useRef, useState } from "react";
import restApi from "../../../../providers/restApi";
import AsyncSelect from "react-select/async";
import Styles from "../override.module.css";
import { Form } from "react-bootstrap";
import { compare } from "../../../../services/general/helpers";
import { useCallback } from "react";
import { Mandatory } from "components/forms/bootstrapFields";
import { useTypedSelector } from "reducers";
import _ from "lodash";

const restApiService = new restApi();

/** Product Item Picker will always single picker and it's value will product Item object not id */
/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const ProductItemPicker = (props) => {
  let {
    className,
    required,
    label,
    input,
    meta: { touched, error, warning },
    isMulti,
    placeholder,
    callBackString,
    apiParams,
    onBlurCallback,
    onInitialCallback,
    onChangeCallback,
    instanceId,
    disabledFreeText,
  } = props;

  const mounted = useRef(false);
  const [selected, setSelected] = useState({});
  const [inputVal, setInputVal] = useState();

  // parseForSelect was different from others pickers so seprate function here
  const parseForSelect = (options) => {
    return options
      .map((option) => {
        return {
          value: option,
          label: option.name,
        };
      })
      .sort(compare);
  };

  const onBlurSingle = (input, option) => {
    if (!option) {
      input.onBlur({});
      return;
    }
    input.onBlur(option);
  };

  const onChangeSingle = (input, option) => {
    if (!option) {
      input.onChange({});
      return;
    }
    input.onChange(option.value);
    if (onChangeCallback) {
      onChangeCallback(option.value);
    }
  };

  const getProductItems = async (getParams) => {
    const result = await restApiService.get("product_items.lk", getParams, null, true, null, true);
    const value = parseForSelect(result.data);
    return value;
  };

  // used to find option when user search
  const loadOptions = async (inputValue, callback) => {
    const getParams = {
      ...apiParams,
      active: true,
      product_name: inputValue,
      limit: 100,
    };
    if (inputValue) {
      let res = await getProductItems(getParams);
      callback(res);
    }
  };

  const findSelected = useCallback(() => {
    setSelected({ label: input.value.display_name, value: input.value });
  }, [input]);

  useEffect(() => {
    mounted.current = true;
    findSelected();

    return () => {
      mounted.current = false;
    };
  }, [findSelected, input.value]);

  useEffect(() => {
    if (onInitialCallback) {
      onInitialCallback();
    }
  }, []);

  const onBlurEvent = () => {
    onBlurSingle(input, input.value);

    if (onBlurCallback) {
      onBlurCallback(input);
      if (disabledFreeText) {
        setInputVal();
      }
    }
  };

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
        </Form.Label>
      )}
      <AsyncSelect
        {...input}
        value={selected}
        placeholder={placeholder ? placeholder : "Search/select item"}
        cacheOptions
        loadOptions={loadOptions}
        onChange={(value) => onChangeSingle(input, value)}
        onBlur={() => onBlurEvent(input, input.value)}
        defaultOptions
        required={required}
        isMulti={isMulti}
        instanceId={instanceId || "product-item-picker"}
        inputValue={inputVal}
        onInputChange={(inputValue, action) => {
          if (callBackString) {
            if (action.action === "input-change" || action.action === "set-value") {
              setInputVal(inputValue);
            }
            if (action.action === "input-change") {
              callBackString(inputValue);
            }
          } else {
            setInputVal(inputValue);
          }
        }}
      />
      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default ProductItemPicker;
