import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";
import { minLength, required } from "../../../../../services/validations/reduxFormValidation";
import { RenderField } from "../../../../forms/bootstrapFields";
import BankCodeValidator from "../../bankCodeValidator";
import { AccountNumberValidator } from "../../accountNumberValidator";

const TwZar = ({ modelName }: { modelName?: string }) => {
  const min7 = minLength(7);
  const { t } = useTranslation();
  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="4">
        <BankCodeValidator modelName={`${modelName}`} notRequired={true} />
      </Col>
      <Col md="4">
        <AccountNumberValidator
          modelName={`${modelName}`}
          label={t("common.paymentMethod.accountNumberLabel")}
          notRequired={true}
        />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.phoneNumber`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.recipientPhoneNumber")}
          className={`w-100`}
          maxLength={20}
          validate={[min7]}
        />
      </Col>
    </>
  );
};

export default TwZar;
