import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import style from "./gridStyles.module.css";
import { getPaginationData } from "../../../services/common/gridService";
import { GridApi } from "ag-grid-community";

type CustomStatusPanelRowsStatusPropsType = {
  api: GridApi;
};

export const CustomStatusPanelRowsStatus = (props: CustomStatusPanelRowsStatusPropsType) => {
  const [paginationData, setPaginationData] = useState(getPaginationData(props.api));

  const updateStatusBar = (params: any) => {
    if (!props.api.isDestroyed()) {
      setPaginationData(getPaginationData(params.api));
    }
  };

  useEffect(() => {
    if (!props.api.isDestroyed()) {
      props.api.addEventListener("modelUpdated", updateStatusBar);
      props.api.addEventListener("paginationChanged", updateStatusBar);
    }

    // Remember to remove the event listener when the component is destroyed
    return () => {
      if (!props.api.isDestroyed()) {
        props.api.removeEventListener("modelUpdated", updateStatusBar);
        props.api.removeEventListener("paginationChanged", updateStatusBar);
      }
    };
  }, []);

  return (
    <Row className="m-0 m-2">
      {paginationData?.firstRow} to {paginationData?.lastRow} from {paginationData?.totalRows}
    </Row>
  );
};

type CustomStatusPaginationNavigationPropsType = {
  api: GridApi;
  paginationOptions?: number[];
};

export const CustomStatusPaginationNavigation = (props: CustomStatusPaginationNavigationPropsType) => {
  const [paginationData, setPaginationData] = useState(getPaginationData(props.api));
  const [currentPage, setCurrentPage] = useState(1);

  const onFirst = () => {
    props.api?.paginationGoToFirstPage();
  };

  const onNext = () => {
    props.api?.paginationGoToNextPage();
  };

  const onPrevious = () => {
    props.api?.paginationGoToPreviousPage();
  };

  const onLast = () => {
    props.api?.paginationGoToLastPage();
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPageNumber = parseInt(e.target.value);
    props.api?.paginationGoToPage(newPageNumber - 1);
    setCurrentPage(newPageNumber);
  };

  const onPageSizeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const pageSize = Number(e.target.value);
    props.api.updateGridOptions({ paginationPageSize: pageSize, cacheBlockSize: pageSize });
  };

  const updateStatusBar = (params: any) => {
    if (!props.api.isDestroyed()) {
      setPaginationData(getPaginationData(params.api));
      const currentPageNumber = params.api.paginationGetCurrentPage() + 1;
      setCurrentPage(currentPageNumber);
    }
  };

  useEffect(() => {
    if (!props.api.isDestroyed()) {
      props.api.addEventListener("modelUpdated", updateStatusBar);
      props.api.addEventListener("paginationChanged", updateStatusBar);
    }

    // Remember to remove the event listener when the component is destroyed
    return () => {
      if (!props.api.isDestroyed()) {
        props.api.removeEventListener("modelUpdated", updateStatusBar);
        props.api.removeEventListener("paginationChanged", updateStatusBar);
      }
    };
  }, []);

  return (
    <Col className="py-2">
      <Button size="sm" variant="secondary" className={"mr-1 "} onClick={() => onFirst()}>
        <i className={"icon-arrow-left-start " + style.iconSm} />
      </Button>
      <Button size="sm" variant="secondary" className="mr-1" onClick={() => onPrevious()}>
        <i className={"icon-arrow-left " + style.iconSm} />
      </Button>
      <input
        type="number"
        className={"ml-3 mr-1 " + style.smallInput}
        value={currentPage}
        onChange={handlePageInputChange}
        min="1"
        max={paginationData?.totalPages}
      />
      / {paginationData?.totalPages}
      <Button size="sm" variant="secondary" className="mx-1 ml-3" onClick={() => onNext()}>
        <i className={"icon-arrow-right " + style.iconSm} />
      </Button>
      <Button size="sm" variant="secondary" className="mr-1" onClick={() => onLast()}>
        <i className={"icon-arrow-right-end " + style.iconSm} />
      </Button>
      <select
        value={props.api.paginationGetPageSize()}
        onChange={(e) => onPageSizeChanged(e)}
        className={"mx-2 mr-2 " + style.smallSelect}
        name="pagination-size"
      >
        {props?.paginationOptions?.map((size: number) => {
          return (
            <option key={size} value={size}>
              {size}
            </option>
          );
        })}
      </select>
      items per page
    </Col>
  );
};
