import React, { useCallback, useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import styles from "./cardPayMyAccount.module.css";
import { shallowEqual } from "react-redux";
import { CardPaymentsSvc } from "services/admin/cards/cardPaymentsSvc";
import { RootState, useTypedSelector } from "reducers";
import { formattedAmount } from "services/general/helpers";
import { companyDateFormat } from "services/general/dateSvc";
import { IUser } from "services/common/user/userTypes";

interface CardPayMyAccountSubmittedPropsType {
  close: () => void;
}

export const CardPayMyAccountSubmitted = ({ close }: CardPayMyAccountSubmittedPropsType) => {
  const currentUser = useTypedSelector((state: RootState) => state.user, shallowEqual) as IUser | undefined;
  const currentStatement = useTypedSelector(
    (state: RootState) => CardPaymentsSvc.getCurrentStatement(state),
    shallowEqual,
  );
  const { account_code, currency_code } = currentStatement;
  const currentPaymentAmount = useTypedSelector((state: RootState) => CardPaymentsSvc.getCurrentPaymentAmount(state));
  const currentPaymentSource = useTypedSelector((state: RootState) => CardPaymentsSvc.getCurrentPaymentSource(state));
  const currentPaymentDate = useTypedSelector((state: RootState) => CardPaymentsSvc.getCurrentPaymentDate(state));
  const createdCardProgramTransaction = useTypedSelector(
    (state: RootState) => CardPaymentsSvc.getCreatedCardProgramTransaction(state),
    shallowEqual,
  );

  const handleSetPrint = useCallback(() => {
    const element = document.querySelector(`.${styles.mainStyle}`);
    element?.classList.add(styles.printableModal);
    window.print();
  }, []);

  const handleRemovePrint = useCallback(() => {
    const element = document.querySelector(`.${styles.mainStyle}`);
    element?.classList.remove(styles.printableModal);
  }, []);

  useEffect(() => {
    window.addEventListener("afterprint", handleRemovePrint);
    return () => {
      window.removeEventListener("afterprint", handleRemovePrint);
    };
  }, []);

  return (
    <>
      <Modal.Header>
        <Container className="px-3">
          <Row className={`mx-0 justify-content-between align-items-center w-100 ${styles.modalHeader}`}>
            <Col className="px-0">Payment Request Submitted</Col>
            <Col className="px-0" style={{ flex: 0 }}>
              <i onClick={close} className={`${styles.modalClose} icon-close`}></i>
            </Col>
          </Row>
          <Row className={`mx-0 ${styles.modalSubheader}`}>
            <Col className="px-0 pr-2 text-nowrap" style={{ flex: 0 }}>
              <label>Account Code:</label>
            </Col>
            <Col className="px-0">
              <label>{account_code ?? "N/A"}</label>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Row className={`mx-0 ${styles.section} px-3`}>
          <span>
            Thank you - the payment below has been submitted. Please&nbsp;
            <a className="text-info" onClick={handleSetPrint}>
              print this page
            </a>
            &nbsp;or make note of the Reference IDs.
          </span>
        </Row>
        <Row className="mx-0 pt-4 px-5 pb-5">
          <Container className={`p-0 border rounded ${styles.section}`}>
            <Row className={`mx-0 px-3 py-1 border-bottom`}>
              <Col className={`px-0 text-nowrap`} md={3}>
                <span>Reference ID:</span>
              </Col>
              <Col className={`px-0`}>
                {createdCardProgramTransaction?.payment?.reference_number ||
                  createdCardProgramTransaction?.payment?.payment_number}
              </Col>
            </Row>

            <Row className={`mx-0 px-3 py-1 border-bottom`}>
              <Col className={`px-0 text-nowrap`} md={3}>
                <span>Account Code:</span>
              </Col>
              <Col className={`px-0`}>{account_code ?? "N/A"}</Col>
            </Row>
            <Row className={`mx-0 px-3 py-1 border-bottom`}>
              <Col className={`px-0 text-nowrap`} md={3}>
                <span>Payment Status:</span>
              </Col>
              <Col className={`px-0`}>Payment Submitted</Col>
            </Row>
            <Row className={`mx-0 px-3 py-1 border-bottom`}>
              <Col className={`px-0 text-nowrap`} md={3}>
                <span>Payment Amount:</span>
              </Col>
              <Col className={`px-0 ${styles.blackText}`}>
                {formattedAmount(`${currentPaymentAmount}`, currency_code, 2, true)}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 py-1 border-bottom`}>
              <Col className={`px-0 text-nowrap`} md={3}>
                <span>Payment Date:</span>
              </Col>
              <Col className={`px-0`}>{companyDateFormat(currentPaymentDate, currentUser)}</Col>
            </Row>
            <Row className={`mx-0 px-3 py-1 border-bottom`}>
              <Col className={`px-0 text-nowrap`} md={3}>
                <span>Customer Name:</span>
              </Col>
              <Col
                className={`px-0`}
              >{`${currentUser?.contact?.first_name ?? ""} ${currentUser?.contact?.last_name}`}</Col>
            </Row>
            <Row className={`mx-0 px-3 py-1`}>
              <Col className={`px-0 text-nowrap`} md={3}>
                <span>Payment Method:</span>
              </Col>
              <Col className={`px-0`}>{currentPaymentSource}</Col>
            </Row>
          </Container>
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-0 pt-5">
        <Row className="mx-0 justify-content-end">
          <Button variant="primary" className={`py-1 ${styles.footerButton}`} onClick={close}>
            Close
          </Button>
        </Row>
      </Modal.Footer>
    </>
  );
};
