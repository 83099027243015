import {
  SET_COMPANY_PAYMENT_METHOD_FORM,
  SET_COMPANY_PAYMENT_METHOD_MODAL_OPEN,
  SET_COMPANY_PAYMENT_METHODS,
  SET_COMPONENT_MOUNT_MODE,
  SET_IS_LOADING,
} from "../../actions/actionTypes";

// Contstants
export const COMPANY_PAYMENT_METHOD_FORM_NAME = "companyPaymentMethodForm";
export const ADD = "ADD";
export const EDIT = "EDIT";

// INITIAL_STATE
const initialState = {
  // companyPaymentMethodForm
  companyPaymentMethodForm: {
    form: {
      payment_type: "",
    },
  },

  // app states
  isModalOpen: false,
  isLoading: false,
  componentMountMode: null,
  paymentMethods: [],
};

const companyPaymentMethodFormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COMPANY_PAYMENT_METHOD_FORM:
      return { ...state, companyPaymentMethodForm: { form: payload } };
    case SET_COMPANY_PAYMENT_METHOD_MODAL_OPEN:
      return { ...state, isModalOpen: payload };
    case SET_COMPANY_PAYMENT_METHODS:
      return { ...state, paymentMethods: payload };
    case SET_COMPONENT_MOUNT_MODE:
      return { ...state, componentMountMode: payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: payload };
    default:
      return state;
  }
};

export default companyPaymentMethodFormReducer;

// action dispatchers
export const setCompanyPaymentMethodForm = (formData) => (dispatch, getState) => {
  dispatch({ type: SET_COMPANY_PAYMENT_METHOD_FORM, payload: formData });
};

export const setCompanyPaymentMethodModalOpen = (value) => (dispatch, getState) => {
  dispatch({ type: SET_COMPANY_PAYMENT_METHOD_MODAL_OPEN, payload: value });
};

export const setComponentMountMode = (value) => (dispatch, getState) => {
  dispatch({ type: SET_COMPONENT_MOUNT_MODE, payload: value });
};

export const onHideCompanyPaymentMethodModal = () => (dispatch, getState) => {
  dispatch(setCompanyPaymentMethodModalOpen(false));
  dispatch(setCompanyPaymentMethodForm(initialState.companyPaymentMethodForm.form));
  dispatch(setComponentMountMode(null));
};

export const setCompanyPaymentMethods = (value) => (dispatch, getState) => {
  dispatch({ type: SET_COMPANY_PAYMENT_METHODS, payload: value });
};

export const setIsLoading = (value) => (dispatch, getState) => {
  dispatch({ type: SET_IS_LOADING, payload: value });
};
