import { Col, Row } from "react-bootstrap";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import IntegrationCanEft from "../../components/integrationComponents/integrationCanEft";
import IntegrationEdi from "../../components/integrationComponents/integrationEdi";
import IntegrationSftp from "../../components/integrationComponents/integrationSftp";
import { Field } from "redux-form";
import { RenderCheckBox } from "../../../../../../components/forms/bootstrapFields";

const IntegrationCanEftPayment = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <IntegrationCanEft />
      </Row>
      <Row className="m-0">
        <IntegrationSftp />
      </Row>
      <Row className="m-0">
        <IntegrationEdi />
      </Row>
      <Row className="m-0">
        <Col lg="6">
          <Field
            name="form.hide_payment_method_on_vendor_portal"
            label="Hide in Vendor Portal"
            component={RenderCheckBox}
            type="checkbox"
            id="hide_payment_method_on_vendor_portal"
          />
        </Col>
      </Row>
    </Col>
  );
};

export { IntegrationCanEftPayment };
