import ReceiptList from "./receipts/list";
import ReceiptDetails from "./receipts/details";
import ExpenseList from "./expenseItem/list";
import ExpenseReportList from "./expenseReports/list";
import ExpenseDashboardPage from "./dashboard";
import PayExpenseList from "components/admin/expensese/payExpense/PayExpensesList";
import PaymentRunReview from "components/admin/expensese/payExpense/paymentRunReview";
import AddExpenseItem from "./expenseItem/add";
import EditExpenseItem from "./expenseItem/edit";
import ShowExpenseItem from "components/admin/expensese/expenseItem/expenseItem";
import EditExpenseSplit from "./expenseItem/editExpenseSplitCoding";

const expensesRoutes = [
  {
    path: "/ap/expense_dashboard",
    exact: true,
    component: ExpenseDashboardPage,
    authorization: {
      I: "list",
      a: "ExpenseItems",
    },
  },
  {
    path: "/ap/dashboard/expense",
    exact: true,
    component: ExpenseDashboardPage,
    authorization: {
      I: "list",
      a: "ExpenseItems",
    },
  },
  {
    path: "/ap/expenses",
    exact: true,
    component: ExpenseList,
    authorization: {
      I: "list",
      a: "ExpenseItems",
    },
  },
  {
    path: "/ap/expenses/:id",
    exact: true,
    component: ShowExpenseItem,
    authorization: {
      I: "list",
      a: "ExpenseItems",
    },
  },
  {
    path: "/ap/expense",
    exact: true,
    component: AddExpenseItem,
    permissions: ["addExpenseItems", "submitExpenseItems"],
  },
  {
    path: "/ap/expense/split/:id",
    exact: true,
    component: EditExpenseSplit,
    permissions: ["addExpenseItems", "submitExpenseItems"],
  },
  {
    path: "/ap/expense/:id",
    exact: true,
    component: EditExpenseItem,
    authorization: {
      I: "edit",
      a: "ExpenseItems",
    },
  },
  {
    path: "/ap/inbound_receipts",
    exact: true,
    component: ReceiptList,
    authorization: {
      I: "receipts",
      a: "_active",
    },
  },
  {
    path: "/ap/inbound_receipts/:id",
    exact: true,
    component: ReceiptDetails,
    authorization: {
      I: "receipts",
      a: "_active",
    },
  },
  {
    path: "/ap/expense_reports",
    exact: true,
    component: ExpenseReportList,
    authorization: {
      I: "list",
      a: "ExpenseReports",
    },
  },
  {
    path: "/ap/pay_expense",
    exact: true,
    component: PayExpenseList,
  },
  {
    path: "/ap/expenses_review_payment_run",
    exact: true,
    component: PaymentRunReview,
  },
];

export default expensesRoutes;
