import React, { useEffect } from "react";
import ExpenseReportSummary from "components/admin/expensese/dashbord/expenseReportSummary";
import ExpenseSummary from "components/admin/expensese/dashbord/expenseSummary";
import NavTabs from "components/admin/expensese/nav";
import SubsidiaryPicker from "components/admin/pickers/reduxFormPickers/subsidiaryPicker";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Field, reduxForm, change, WrappedFieldInputProps } from "redux-form";
import { onBlurSingle } from "services/general/helpers";
import styles from "./dashboard.module.css";
import DashboardTabs from "components/admin/dashboard/dashboardTabs";
import { useLocation } from "react-router";
import ExpenseCategorySpends from "./expenseCategorySpend";
import TooltipRender from "components/toolTip/tooltipRender";
import { useTranslation } from "react-i18next";

const ExpenseDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const cardProgramOptions: { value: string; label: string }[] = [
    { value: "all", label: "All" },
    { value: "true", label: "Reimbursable" },
    { value: "false", label: "Nonreimbursable" },
  ];
  const defaultOption = cardProgramOptions[0];

  useEffect(() => {
    dispatch(change("expenseDashboardForm", "expense_type", defaultOption));
  }, []);
  return (
    <>
      <Container fluid id="pdf-content">
        <Row className={location.pathname === "/ap/dashboard/expense" ? "" : "mt-4"}>
          <Col>
            {location.pathname !== "/ap/dashboard/expense" ? <NavTabs activePageName="Dashboard" /> : <DashboardTabs />}
          </Col>
        </Row>
        <Row className={styles.customRow}>
          <div className={`${styles.mainFilterContainer}`}>
            <div className={`${styles.subsidiaryFilterContainer}`}>
              <div className={`${styles.subsidiaryFilterLabel}`}>
                <span className={`${styles.filterLabel} ${styles.subsidiaryView}`}>
                  {t("admin.pages.expenseItem.labels.subsidiaryView")}
                </span>
              </div>
              <div className={`${styles.subsidiaryFilterSelect}`}>
                <Field
                  name="subsidiary_id"
                  id="expense.subsidiary_id"
                  component={SubsidiaryPicker}
                  className={`${styles.expenseTypeFilter}`}
                  callBack={(prop: { name: string }) => {
                    dispatch(change("expenseDashboardForm", "subsidiaryName", prop?.name ? prop.name : undefined));
                  }}
                  isClearable={true}
                />
              </div>
            </div>
            <div className={`${styles.expenseTypeFilterContainer}`}>
              <div className={`${styles.expenseTypeFilterLabel}`}>
                <div className={`${styles.filterLabel}`}>
                  {t("admin.pages.expenseItem.labels.filterAllDatabyExpenseType")}
                </div>
              </div>
              <div className={`${styles.expenseTypeFilterSelect}`}>
                <Field
                  name="expense_type"
                  id="expense.expense_type"
                  component={({ input }: { input: WrappedFieldInputProps }) => {
                    return (
                      <Select
                        {...input}
                        placeholder="ALL"
                        onBlur={() => onBlurSingle(input, input.value)}
                        options={cardProgramOptions}
                      />
                    );
                  }}
                  onChange={(event, newValue) =>
                    newValue?.value == "false" ? dispatch(change("expenseCategoryForm", "card_program_id", null)) : null
                  }
                  required
                  className={`${styles.expenseTypeFilter}`}
                />
              </div>
              <div>
                <div className={`${styles.exportIcon}`}>
                  <TooltipRender placement="top" title="Export dashboard as PDF">
                    <i
                      onClick={() => {
                        window.print();
                      }}
                      className={`icon ${styles.iconHeading} icon-import px-mt-3 px-mr-3 btn`}
                    ></i>
                  </TooltipRender>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col className={`${styles.dashboardTopPanel}`} xl={5}>
            <ExpenseSummary />
          </Col>
          <Col className={`${styles.dashboardTopPanel}`} xl={7}>
            <ExpenseReportSummary />
          </Col>
          <Col xl={12} className={`${styles.bottomModuleContainer}`} md={12}>
            <ExpenseCategorySpends />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default reduxForm({
  form: "expenseDashboardForm", // Unique name for your form
})(ExpenseDashboard);
