import { useQuery } from "@tanstack/react-query";
import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { CommonApis } from "services/admin/commonApis";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import ReactSelect from "../wrapperReactSelect/reactSelect";
import { AddressPickerTypes } from "./addressPickerType";

const ShippingAddressPicker = ({
  name,
  label,
  required,
  modelData,
  parentObj,
  instanceId,
  menuPlacement,
  menuPosition,
  allowMappingAddress,
  showNewFormatAddress,
  extraAddressableId,
  extraAddressableType,
  hideAddressUser,
  objectPropertyKey,
  validate,
  isPoRequest,
  // hasShippingAddress,
  callBack,
}: AddressPickerTypes.TShippingAddressPickerProps) => {
  const { control, setValue } = useFormContext();
  const [selected, setSelected] = useState(null);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const addressId = useGetValue(name);
  const subsidiaryId = useGetValue(`${modelData}.subsidiary_id`);

  const { t } = useTranslation();

  const getOptionValue = useCallback((option) => option?.id, []); //TODO: check here
  const getOptionLabel = useCallback((option) => option?.label, []); //TODO: check here

  const hasShippingAddress = useWatch({
    control,
    name: `${modelData}.subsidiary.has_shipping_address`,
  });

  const formatOptions = useCallback(
    (address: AddressPickerTypes.TShippingAddressPickerOption, context: any) => {
      return (
        <div>
          {context?.context !== "menu" ? (
            <span>{address.label}</span>
          ) : (
            <small>
              <span>
                {address.label}
                {(address.addressable_type === "Location" || address.addressable_type === "Project") &&
                  ` - ${address.addressable_type}`}
                {showNewFormatAddress && address.address1 && (
                  <strong>
                    <br />
                    {address.address1}
                    <br />
                  </strong>
                )}
                {showNewFormatAddress && address.address2 && (
                  <strong>
                    {address.address2}
                    <br />
                  </strong>
                )}
                {showNewFormatAddress && address.address3 && <strong>{address.address3}</strong>}
                {address.id && (
                  <>
                    <br />
                    {address.city && (
                      <>
                        <strong> City: </strong>
                        {address.city}
                      </>
                    )}
                    {showNewFormatAddress && address.state && (
                      <>
                        <strong> State: </strong>
                        {address.state}
                      </>
                    )}
                    {address.zipcode && (
                      <>
                        <strong> Zipcode: </strong>
                        {address.zipcode}
                      </>
                    )}
                    {address.country && (
                      <>
                        {showNewFormatAddress && <br />}
                        <strong> Country: </strong>
                        {address.country}
                      </>
                    )}
                  </>
                )}
              </span>
            </small>
          )}
        </div>
      );
    },
    [showNewFormatAddress],
  );

  const params = useMemo(() => {
    let apiParams: Record<string, any> = {};
    apiParams.active = true;

    if (hasShippingAddress) {
      apiParams.subsidiary_id = subsidiaryId;
    } else {
      apiParams.addressable_type = "Company";
    }

    return apiParams;
  }, [hasShippingAddress, subsidiaryId]);

  const contactAddressParams = useMemo(() => {
    let apiParams: Record<string, any> = {};
    apiParams.active = true;
    apiParams.addressable_type = "Contact";
    apiParams.addressable_id = currentUser.contact.id;

    return apiParams;
  }, [currentUser.contact.id]);

  const {
    data: activeAddresses,
    isError: isErrorInactive,
    isSuccess: isActiveAddressesFetched,
  } = useQuery({
    queryKey: ["addresses", params],
    queryFn: async ({ signal }) => {
      const response = await restApiService.get(`addresses`, params, null, true, null, false, signal);
      return response.data;
    },
  });

  const { data: contactShippingAddress, isError: isInactiveContactAddressError } = useQuery({
    queryKey: ["addresses", contactAddressParams],
    queryFn: async ({ signal }) => {
      const response = await restApiService.get(`addresses`, contactAddressParams, null, true, null, false, signal);
      return response.data;
    },
    enabled:
      !!extraAddressableType &&
      !!extraAddressableId &&
      ((isPoRequest && hideAddressUser) || !isPoRequest) &&
      isActiveAddressesFetched,
  });

  const addresses: any = useMemo(() => {
    const shippingAddresses = activeAddresses || [];

    if (contactShippingAddress) {
      const mergedAddresses = [...contactShippingAddress, ...shippingAddresses];

      // Remove duplicates by 'id'
      return _.uniqBy(mergedAddresses, "id");
    }
    return shippingAddresses;
  }, [activeAddresses, contactShippingAddress]);

  const setPickerValue = useCallback(
    (selected) => {
      setSelected(selected);
      setValue(name, selected ? selected.id : null);
      if (objectPropertyKey) setValue(objectPropertyKey, selected);
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack, name, objectPropertyKey, setValue],
  );

  const handleSelectChange = useCallback(
    (selected) => {
      setPickerValue(selected);
    },
    [setPickerValue],
  );

  const setDefaultValue = useCallback(async () => {
    const address = Array.isArray(addresses) ? addresses.filter((address) => address?.id === addressId) : [];

    if (address.length === 0) {
      const inactiveOption = await CommonApis.getAddress(addressId);
      setPickerValue(inactiveOption ? inactiveOption : null);
    } else {
      setPickerValue(address ? address : null);
    }
  }, [addressId, addresses, setPickerValue]);

  useEffect(() => {
    if (addressId > 0 && isActiveAddressesFetched) {
      setDefaultValue();
    }
  }, [addressId, isActiveAddressesFetched, setDefaultValue]);

  return (
    <Form.Group>
      {label && (
        <Form.Label className={"pickerLabel"}>
          {label} <Mandatory required={required} />
        </Form.Label>
      )}
      <Controller
        name={name}
        rules={{ required: required ? t("validations.required") : undefined, validate }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <>
              <ReactSelect
                id={instanceId}
                instanceId={instanceId}
                value={selected}
                options={addresses}
                isClearable
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={(selected) => handleSelectChange(selected)}
                placeholder="-- Select Address --"
                formatOptionLabel={formatOptions}
                menuPosition={menuPosition}
                menuPlacement={menuPlacement}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    ...commonService.getInvalidStyle(Boolean(error?.message)),
                  }),
                }}
              />
              {error?.message && <PickerErrorBlock error={error.message} />}
              {isErrorInactive && <PickerErrorBlock error={t("components.common.pickers.loadingOptionsError")} />}
              {isInactiveContactAddressError && (
                <PickerErrorBlock error={t("components.common.pickers.loadingOptionsError")} />
              )}
            </>
          );
        }}
      />
    </Form.Group>
  );
};

export default memo(ShippingAddressPicker);
