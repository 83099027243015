import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { change, getFormValues } from "redux-form";
import RestApi from "../../../../../../providers/restApi";
import { INTEGRATION_FORM_NAME, setOAuthProcessing } from "../../../../../../reducers/admin/integrationsReducer";
import {
  addIntegrationRecord,
  fetchIntegrationDetail,
  getXeroUrl,
  updateIntegrationRecord,
} from "../../../../../../services/integrationCommonSvc/integrationCommonSvc";
import useConfirmModal from "../../../../../../components/modals/confirmModal/useConfirmModalHook";
import { Button, Spinner } from "react-bootstrap";
import { CreateNotification, NotificationType } from "../../../../../../services/general/notifications";

const ConnectToXero = () => {
  const { createConfirmModal } = useConfirmModal();
  const oAuthProcessing = useSelector((state) => state.integrationsRed.oAuthProcessing);
  const integrationSettings = useSelector((state) => state.integrationsRed.integrationSettings);
  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const dispatch = useDispatch();
  const restApiService = new RestApi();

  let objWindow;
  let checkOauthIntervalId;

  const errorOccured = (error) => {
    // TODO:  serverside validation
    CreateNotification(
      "Xero",
      "Due to some error not able to open Xero connect, Pls try again.",
      NotificationType.danger,
    );
    console.log("error", error);
    dispatch(setOAuthProcessing(false));
  };

  const getXeroIntegration = function (integrationSettings) {
    let obj = {};
    _.isArray(integrationSettings) &&
      integrationSettings.forEach((val) => {
        if (
          val.system_name === "Xero" &&
          val.application_type === "AUTHENTICATION" &&
          val.authentication_method === "XERO_TOKEN_BASED" &&
          val.status === "ACTIVE"
        ) {
          obj = val;
        }
      });
    return obj;
  };

  const beforeSubmit = async (obj) => {
    if (obj.error) throw obj.error;

    dispatch(change(INTEGRATION_FORM_NAME, "form.code", obj.code));
    // dispatch(change(INTEGRATION_FORM_NAME, "form.realmId", obj.realmId));
    dispatch(change(INTEGRATION_FORM_NAME, "form.status", "ACTIVE"));

    // TODO: FIX IT FOR FORM DATA. find better way to update value in redux and use it immideately
    form.code = obj.code;
    // form.realmId = obj.realmId;
    form.status = "ACTIVE";

    return new Promise(async (resolve, reject) => {
      const integrationObj = getXeroIntegration(integrationSettings);
      if (_.isPlainObject(integrationObj) && integrationObj.id) {
        integrationObj.code = obj.code;
        integrationObj.status = "ACTIVE";
        try {
          const integrationSettings = await updateIntegrationRecord(integrationObj);
          resolve(integrationSettings);
        } catch (error) {
          errorOccured(error);
        }
      } else {
        try {
          // generate new connection
          const integrationSettings = await addIntegrationRecord(form);
          resolve(integrationSettings);
        } catch (error) {
          reject(error);
        }
      }
    });
  };

  //to oauth process for Quickbook
  const getXeroAccessToken = async (oAuthObj) => {
    try {
      const integrationSettings = await beforeSubmit(oAuthObj);
      const obj = getXeroIntegration(integrationSettings);
      if (_.isObject(obj) && obj.id) {
        // TODO: FIND BETTER WAY TO SET FORM DATA AND USE IT IMEDIATELY
        dispatch(change(INTEGRATION_FORM_NAME, "form.id", obj.id));
        form.id = obj.id;
        const res = await restApiService.get("integration_settings/" + obj.id + "/get_authorization_code");
        // now get updated integrations and add it to localstate
        const integrationDetail = await fetchIntegrationDetail(obj.id);
        dispatch(change(INTEGRATION_FORM_NAME, "form", integrationDetail));
        dispatch(setOAuthProcessing(false));
        if (res) {
          CreateNotification("Connected to Xero", "Successfully connected to Xero.", NotificationType.success);
        }
      }
    } catch (error) {
      errorOccured(error);
    }
  };

  const checkOauthResponse = function () {
    if (_.isObject(objWindow) && objWindow.closed) {
      const oauthProcess = JSON.parse(localStorage.getItem("oauth_process"));
      if (_.isObject(oauthProcess)) {
        clearInterval(checkOauthIntervalId);
        getXeroAccessToken(oauthProcess.paramsObj);
      }
    }
  };

  const openPopupWindow = async () => {
    dispatch(setOAuthProcessing(true));
    try {
      const url = await getXeroUrl();
      if (url) {
        objWindow = window.open(url, "Xero", "width=500,height=600, left=300, top=200");
        checkOauthIntervalId = setInterval(checkOauthResponse, 500);
      }
    } catch (error) {
      errorOccured(error);
    }
  };

  const isXeroConnected = () => {
    return (
      form.system_name === "Xero" &&
      form.application_type === "AUTHENTICATION" &&
      form.authentication_method === "XERO_TOKEN_BASED" &&
      form.refresh_token &&
      form.key
    );
  };

  const disconnectXeroAccount = async () => {
    try {
      const response = await restApiService.get("integration_settings/" + form.id + "/disconnect_authorization");
      if (response.data) {
        CreateNotification("Disconnect from ", "Account has been disconnected from Xero.", NotificationType.success);

        // now get updated integrations and add it to localstate
        const integrationDetail = await fetchIntegrationDetail(form.id);
        dispatch(change(INTEGRATION_FORM_NAME, "form", integrationDetail));
        dispatch(setOAuthProcessing(false));
      }
    } catch (error) {
      errorOccured(error);
    }
  };

  return (
    <>
      {oAuthProcessing && <Spinner animation="border" variant="info" id="refresh_cache_spin" className="mt-3" />}
      {!isXeroConnected() && (
        <Button
          variant="secondary"
          className="m-2"
          onClick={() => openPopupWindow()}
          style={{ color: "#40a4e7", fontWeight: 800 }}
        >
          <i className="icon icon-xero" /> Connect to Xero
        </Button>
      )}

      {isXeroConnected() && (
        <Button
          onClick={() =>
            createConfirmModal({
              title: "Disconnect from Xero",
              body: "Are you sure, you want to Disconnect from Xero?",
              saveCallBack: disconnectXeroAccount,
            })
          }
          variant="primary"
          className="m-2"
        >
          Disconnect from Xero
        </Button>
      )}
    </>
  );
};

export default ConnectToXero;
