import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import CategoryApis from "services/admin/category/CategoryApis";
import commonService from "services/common/commonSvc";
import { AbstractListPickerTypes } from "../abstractListPicker/abstractListPickerTypes";
import AbstractListPicker from "../abstractListPicker/index2";
import { CategoryPickerTypes } from "./categoryPickerType";

const SinglePicker = ({
  name,
  label,
  required,
  modelData,
  parentObj,
  objectPropertyKey,
  callBack,
  containerClassName,
  disabled,
  instanceId,
  labelClassName,
  menuPlacement,
  menuPosition,
  placeholder,
  tooltip,
  validate,
}: CategoryPickerTypes.TSingleCategoryPickerProps) => {
  const { getValues, setValue } = useFormContext();

  // handle any conditional logic for watchable value in memo
  const departmentIdFieldName = useMemo((): string => {
    const field = "department_id";
    if (_.isNil(modelData)) return "";
    return modelData + field;
  }, [modelData]);

  const policyIdFieldName = useMemo((): string => {
    const field = "policy_id";
    if (_.isNil(modelData)) return "";
    return modelData + field;
  }, [modelData]);

  const parentDepartmentIdFieldName = useMemo((): string => {
    const field = "department";
    if (_.isNil(parentObj)) return "";
    return parentObj + field;
  }, [parentObj]);

  const [departmentId, policyId, parentDepartmentId] = useWatch({
    name: [departmentIdFieldName, policyIdFieldName, parentDepartmentIdFieldName],
  });

  const mergeInactive = useCallback(
    async (currentId, categories) => {
      let inactiveOption = null;
      if (currentId) {
        const category = Array.isArray(categories) ? categories.filter((category) => category?.id === currentId) : [];
        const onlyShowMappedCategory = getValues(modelData + "only_show_mapped_category");
        if (category.length === 0 && onlyShowMappedCategory) {
          setValue(modelData + "only_show_mapped_category", false);
          inactiveOption = null; // returning inactive options to child so that should set selected to null TODO: test mapping with accounts pickers
        } else if (category.length === 0) {
          try {
            const response = await CategoryApis.getCategory(currentId);
            inactiveOption = response;
          } catch (error) {
            commonService.handleError(error);
          }
        } else {
          inactiveOption = category[0];
        }
      }
      return { inactiveOption };
    },
    [getValues, modelData, setValue],
  );

  const getModelDataParams = useCallback(
    (apiParams: Record<string, any>) => {
      const [departmentHasCategories] = getValues(
        ["department.has_categories"].map((modelDependencyName) => modelData + modelDependencyName),
      );
      if (departmentHasCategories) {
        apiParams.department_id = departmentId;
        setValue(modelData + "only_show_mapped_category", true);
      }
      if (policyId) {
        apiParams.policy_id = policyId;
      }
    },
    [departmentId, getValues, modelData, policyId, setValue],
  );

  const getParentObjParams = useCallback(
    (apiParams: Record<string, any>) => {
      const parentDepartmentHasCategories = getValues(parentObj + "department.has_categories");
      if (parentDepartmentHasCategories) {
        apiParams.department_id = parentDepartmentId;
        setValue(modelData + "only_show_mapped_category", true);
      }
    },
    [getValues, modelData, parentDepartmentId, parentObj, setValue],
  );

  // test required on po
  const params = useMemo(() => {
    let apiParams: Record<string, any> = {};
    apiParams.status = "ACTIVE";
    if (!_.isNil(modelData)) {
      getModelDataParams(apiParams);
    }
    if (!_.isNil(parentObj)) {
      getParentObjParams(apiParams);
    }
    return apiParams;
  }, [modelData, parentObj, getModelDataParams, getParentObjParams]);

  const handlePickerSelection = (
    selected: AbstractListPickerTypes.TPickerValue<CategoryPickerTypes.TCategoryPickerOption>,
  ) => {
    // this is done intensionally to add layer of abstraction
    if (callBack) {
      callBack(selected);
    }
  };

  // example code for using formatOptionLabel
  // const formatOptionLabel = useCallback((option: CategoryPickerTypes.TCategoryPickerOption) => {
  //   return <>{`${option.name}.${option.status}`}</>;
  // }, []);

  return (
    <AbstractListPicker<CategoryPickerTypes.TCategoryPickerOption>
      name={name}
      label={label}
      required={required}
      mergeInactive={mergeInactive}
      params={params}
      objectPropertyKey={objectPropertyKey ?? (modelData ? `${modelData}category` : null)}
      fetchUrl="categories.lk"
      // formatOptionLabel={formatOptionLabel}
      callBack={handlePickerSelection}
      validate={validate}
      instanceId={instanceId}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      labelClassName={labelClassName}
      disabled={disabled}
      containerClassName={containerClassName}
      placeholder={placeholder}
      tooltip={tooltip}
    />
  );
};

export default SinglePicker;
