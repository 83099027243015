import React from "react";

/**
 * Depricating this picker
 * use from Component/commmon/pickers/pickerErrorBlock.tsx
 */
/**
 * @deprecated This component is deprecated.
 * Use the picker from Component/common/pickers/pickerErrorBlock.tsx instead.
 */

const PickerErrorBlock = ({ error, customErrorClass }: { error: string; customErrorClass?: string }) => {
  return <div className={customErrorClass ?? "pickerErrorBlock"}>{error}</div>;
};

export default PickerErrorBlock;
