import React from "react";
import { Col, Row } from "react-bootstrap";
import style from "./receipts.module.css";
import Thumbnail from "components/common/thumbnail/thumbnail";
import _ from "lodash";

type ComparativePreviewPropType = {
  leftSectionData: {
    header: string;
    imageUrl?: string | undefined;
    dataItems: {
      key: string;
      value?: string;
    }[];
  };

  rightSectionData: {
    header: string;
    imageUrl?: string | undefined;
    dataItems: {
      key: string;
      value?: string;
    }[];
  };
};
const ComparativePreview = ({ leftSectionData, rightSectionData }: ComparativePreviewPropType) => {
  return (
    <Row>
      <Col className={style.comparativePreview}>
        <Row className={style.container}>
          <Col md={7} className={style.leftSection}>
            <Row>
              <Col className={style.heading + " " + style.dataItem}>{leftSectionData.header}</Col>
            </Row>
            <Row>
              {leftSectionData?.imageUrl && (
                <Col md="4">
                  <Thumbnail imageUrl={leftSectionData.imageUrl} previewSize={{ width: 102, height: 128 }} />
                </Col>
              )}
              <Col md="8">
                {_.isArray(leftSectionData.dataItems) &&
                  leftSectionData.dataItems.map((dataItem) => (
                    <Row className="my-1">
                      <Col className={style.dataItem + " " + style.key} md="7">
                        {dataItem.key}
                      </Col>
                      <Col className={style.dataItem} md="5">
                        {dataItem.value}
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          </Col>
          <div className={style.separator} />
          <Col md={5} className={style.leftSection}>
            <Row>
              <Col className={style.heading + " " + style.dataItem}>{rightSectionData.header}</Col>
            </Row>
            <Row>
              {rightSectionData?.imageUrl && (
                <Col>
                  <Thumbnail imageUrl={rightSectionData.imageUrl} previewSize={{ width: 102, height: 128 }} />
                </Col>
              )}
              <Col>
                {_.isArray(rightSectionData.dataItems) &&
                  rightSectionData.dataItems.map((dataItem) => (
                    <Row className="my-1">
                      <Col className={style.dataItem + " " + style.key} md="6">
                        {dataItem.key}
                      </Col>
                      <Col className={style.dataItem} md="6">
                        {dataItem.value}
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ComparativePreview;
