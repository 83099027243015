import { useQuery } from "@tanstack/react-query";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useTypedSelector } from "reducers";
import { WrappedFieldProps } from "redux-form";
import { IUser } from "services/common/user/userTypes";
import { restApiService } from "../../../../../providers/restApi";
import { compare, onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";

const getAccounts = async (params: any, signal: AbortSignal | undefined): Promise<AccountObjType[]> => {
  const res = await restApiService.get("accounts.lk", params, null, true, null, false, signal);

  return res.data?.data;
};

const parseForSelect = (options?: AccountObjType[]) => {
  return (
    options
      ?.map((option: AccountObjType) => {
        return {
          ...option,
          value: option.id,
          label: `${option.number ? option.number : ""} - ${option.name} ${option.account_type_name ? "-" + option.account_type_name : ""}`,
        };
      })
      .sort(compare) ?? []
  );
};

interface AccountPickerPropsType extends WrappedFieldProps {
  label?: ReactNode;
  input: any;
  required?: boolean;
  tooltip?: string;
  disabled?: boolean;
  floating?: boolean;
  placeHolder?: string;
  modelData?: any;
  parentObjData?: any;
  accountGroupName?: string;
  callBack?: (account: AccountObjType | null) => void;
  instanceId?: string;
}

export type AccountObjType = {
  id?: number | string;
  number?: string | number;
  name?: string;
  status?: string;
  label?: string;
  account_type_name?: string;
  has_budget_links?: boolean;
  is_department_required?: boolean;
  is_grant_required?: boolean;
  is_hide_project?: boolean;
  is_location_required?: boolean;
  is_project_required?: boolean;
  is_warehouse_required?: boolean;
  is_business_unit_required?: boolean;
  is_inter_company_required?: boolean;
};

const AccountPicker = ({
  label,
  input,
  required,
  tooltip,
  disabled,
  floating = false,
  placeHolder,
  callBack,
  modelData,
  parentObjData,
  accountGroupName,
  meta: { touched, error, warning },
  instanceId = "account-picker",
}: AccountPickerPropsType) => {
  const [selected, setSelected] = useState<AccountObjType | null>(null);
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const params: any = { status: "ACTIVE" };

  if (currentUser?.company?.has_account_structures && parentObjData) {
    params.account_structure = true;
    params.subsidiary_id = params?.parentData?.subsidiary_id;
  }

  if (accountGroupName) {
    params.account_group = accountGroupName;
  }

  if (modelData?.is_category_map_to_account) {
    params.category_id = modelData.category_id;
  }

  if (modelData?.project?.has_accounts) {
    params.project_id = modelData.project.id;
  }

  const {
    data: accountOptions,
    isFetching: isLoading,
    isError,
  } = useQuery({
    queryKey: ["accountOptions", params],
    queryFn: ({ signal }) => getAccounts(params, signal),
  });

  const shouldFetchInactive = useMemo(() => {
    if (!input.value || isLoading) return false;
    return !accountOptions?.some((opt) => opt.id === input.value);
  }, [accountOptions, input.value, isLoading]);

  const {
    data: inactiveOption,
    isFetching: isLoadingInactive,
    isError: isErrorInactive,
  } = useQuery({
    queryKey: ["accountOptions", input.value],
    queryFn: async ({ signal }) => {
      const response = await restApiService.get(`accounts/${input.value}`, null, null, true, null, false, signal);
      return response.data;
    },
    enabled: shouldFetchInactive,
  });

  const accounts: AccountObjType[] = useMemo(
    () => [
      ...(!required
        ? [
            {
              label: "-- Select Account --",
              value: "",
            },
          ]
        : []),
      ...parseForSelect([...(inactiveOption ? [inactiveOption] : []), ...(accountOptions || [])]),
    ],
    [accountOptions, inactiveOption, required],
  );

  const onChangeAccount = (input: any, selected: AccountObjType | null) => {
    setSelected(selected);
    onChangeSingle(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  useEffect(() => {
    // if options are not loaded yet, or if the selected value is already set, do nothing
    if (isLoading || (shouldFetchInactive && isLoadingInactive) || !!selected) {
      return;
    }

    const option = accounts.find((item) => item.id === input.value) ?? null;
    setSelected(option);

    if (callBack) {
      callBack(option);
    }
  }, [input.value, isLoading, isLoadingInactive, accounts, selected, shouldFetchInactive]);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        isLoading={isLoading || isLoadingInactive}
        //required={required}
        value={selected}
        onChange={(selected: AccountObjType | null) => onChangeAccount(input, selected)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={accounts}
        isClearable={!required}
        placeholder={placeHolder}
        isDisabled={disabled || isLoading || isLoadingInactive}
        getOptionLabel={(option: AccountObjType) => (
          <>
            <span className="formField">{option.label}</span>
            {option.status === "INACTIVE" && <span style={{ fontSize: "10px" }}>({option.status})</span>}
          </>
        )}
        filterOption={(option: any, searchText) =>
          option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
        }
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
          }),
          ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
        }}
        {...(floating ? { menuPortalTarget: document.body } : {})}
        instanceId={instanceId}
      />
      {error && touched && <div className="pickerError">{error}</div>}
      {(isError || isErrorInactive) && <div className="pickerError">Error loading options!</div>}
    </Form.Group>
  );
};

export default AccountPicker;
