import React from "react";
import { Col } from "react-bootstrap";
import style from "./email.module.css";

export type emailStatusPropsType = {
  email: any;
};

const EmailStatus = ({ email }: emailStatusPropsType) => {
  return (
    <Col xs={3} className={"pl-0"}>
      {email.status === "Processed" && (
        <div className={"d-flex float-right"}>
          <div className={"circle mt-3"} />
          <p className={"mt-1 ml-1 " + style.emailProcessed}>{email.status}</p>
        </div>
      )}
      {email.status === "Failed" && <h4 className={style.emailFailed}>! {email.status}</h4>}
      {email.status !== "Processed" && email.status !== "Failed" && (
        <h5 className={"text-right " + style.otherStatus}>{email.status}</h5>
      )}
    </Col>
  );
};

export default EmailStatus;
