import ClientDataGrid from "components/common/dataGrid/clientDataGrid/clientDataGrid";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import React, { useCallback, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SubsidiaryLinkType } from "services/admin/subsidiaries/types";
import { translate } from "services/general/translation";
import BusinessUnitPicker from "../pickers/reduxFormPickers/businessUnitPicker";
import DepartmentPicker from "../pickers/reduxFormPickers/departmentPicker";
import LocationPicker from "../pickers/reduxFormPickers/locationPicker";
import MetadataFieldPicker from "../pickers/reduxFormPickers/metadataPicker/metadataPicker";
import ProjectPicker from "../pickers/reduxFormPickers/projectPicker/projectPicker";
import EditSubsidiaryLink from "./editSubsidiaryLink";

type SubsidiaryLinksRendererType = {
  title: string;
  gridStorageName: string;
  sectionModelData?: any;
  links?: any;
  newEntries?: any;
  isCollapse?: boolean;
  form?: any;
  isDetailPage?: boolean;
  fieldId?: string | null;
  linkableType?: string;
};

type SubsidiaryLinks = {
  project_id?: number;
  location_id?: number;
  department_id?: number;
  business_unit_id?: number;
  field?: field;
  label?: string;
  level?: number;
  manager_id?: number[];
  linkable_id?: number;
  linkable_type?: string;
  managers?: managers[];
  metadata_field_id?: string;
  metadata_field_type?: string;
};
type field = {
  id?: number;
  name?: string;
};
type managers = {
  id: number;
  name: string;
};

const getArrayName = (title: string) => {
  if (title?.toLowerCase() === "location") {
    return "subsidiary_location_links_attributes";
  } else if (title?.toLowerCase() === "department") {
    return "subsidiary_links_attributes";
  } else if (title?.toLowerCase() === "business unit") {
    return "subsidiary_business_unit_attributes";
  } else if (title?.toLowerCase() === "project") {
    return "subsidiary_project_attributes";
  } else {
    return "subsidiary_metadata_attributes";
  }
};
const filterRowData = (sortedData: SubsidiaryLinks[], linkableType?: string, fieldId?: string | null) => {
  if (sortedData?.length > 0) {
    let updatedData = [...sortedData];
    let subsidiaryLinks = updatedData?.filter((row: any) => row._destroy !== 1);
    if (linkableType === "Metadata" && subsidiaryLinks.length > 0) {
      return subsidiaryLinks?.filter((link: any) => {
        let metadataFieldType = link.metadata_field_type?.replace(/\s/g, "")?.toLowerCase();
        let updatedFieldId = fieldId?.replace(/\s/g, "")?.toLowerCase();
        return fieldId && metadataFieldType === updatedFieldId;
      });
    } else {
      return subsidiaryLinks;
    }
  }
};

const SubsidiaryLinksRenderer = ({
  title,
  gridStorageName,
  sectionModelData,
  links,
  newEntries,
  isCollapse,
  form,
  isDetailPage,
  fieldId,
  linkableType,
}: SubsidiaryLinksRendererType) => {
  const [showModal, setShowModal] = useState(false);
  const [linkData, setLinkData] = useState<any>({});
  const [columnDefs, setColumnDefs] = useState<any>([]);
  const [subsidiaryLink, setSubsidiaryLink] = useState<any>([]);

  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();

  const formatHeaderName = (str?: string) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const editLink = (link: SubsidiaryLinkType) => {
    link.level = link.level === undefined ? null : Number(link.level) || null;
    setSubsidiaryLink((prevSortedData: any) => {
      const linkIndex = prevSortedData?.findIndex((linkData: any) => linkData === link);
      link.linkIndex = linkIndex;
      if (link.linkable_type === "BusinessUnit") {
        link.business_unit_id = link?.field?.id;
      } else if (link.linkable_type === "Project") {
        link.label = link?.field?.name;
      } else if (link.linkable_type === "MetadataField") {
        link.metadata_field_Linkable_id = link.linkable_id;
      }
      link.manager_id = link.managers?.map((manager: any) => manager.id);
      setLinkData(link);
      setShowModal(true);
      return prevSortedData;
    });
  };

  const onDeleteConfirm = useCallback(
    (link) => {
      setSubsidiaryLink((prevSortedData: any) => {
        const updatedModelData = prevSortedData?.map((linkData: any) =>
          linkData === link ? { ...linkData, _destroy: 1 } : linkData,
        );
        form?.change(getArrayName(title), [...updatedModelData]);
        return updatedModelData;
      });
    },
    [form, title],
  );

  const closeModal = () => setShowModal(false);
  const deleteLink = (field: any) => {
    createConfirmModal({
      title: t("subsidiary.deleteDialog.header"),
      body: t("subsidiary.deleteDialog.content"),
      confirmButtonLabel: t("subsidiary.deleteDialog.deleteButtonLabel"),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: () => onDeleteConfirm(field),
    });
  };

  const updateLink = (link: any) => {
    link.level = isNaN(link.level) ? null : Number(link.level) || null;

    link.field = link?.field?.field ? link.field?.field : link.field;
    setSubsidiaryLink((prevSortedData: any) => {
      const modelDataAfterUpdate = prevSortedData?.map((item: any, index: number) =>
        index === link.linkIndex ? link : item,
      );
      const updatedModelData = modelDataAfterUpdate;
      form?.change(getArrayName(title), [...updatedModelData]);
      return updatedModelData;
    });
  };

  useEffect(() => {
    setSubsidiaryLink(links);
  }, [links]);

  const gridColumns = [
    {
      field: "field.name",
      headerName: formatHeaderName(title),
      sortable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
    },
    {
      field: "managers",
      headerName: "Managers",
      sortable: false,
      cellRenderer: (params: any) => params?.value,
      filter: "agTextColumnFilter",
      valueGetter: (params: any) => {
        return (
          params?.data?.managers
            ?.filter((manager: any) => manager?._destroy !== 1)
            ?.map((manager: any) => manager?.name)
            .join(", ") || ""
        );
      },
      filterParams: {
        suppressAndOrCondition: true,
      },
    },
    {
      field: "level",
      headerName: "Level",
      sortable: false,
      filter: "agTextColumnFilter",
      valueGetter: (params: any) => Number(params.data.level) || null,
      filterParams: {
        suppressAndOrCondition: true,
      },
    },
    {
      field: "Actions",
      cellRenderer: (params: any) => (
        <>
          <button
            type="button"
            className="actionButtons editIcon"
            data-toggle="tooltip"
            title="Edit"
            onClick={() => editLink(params?.data)}
          ></button>
          <button
            type="button"
            className="actionButtons delete"
            data-toggle="tooltip"
            title="Delete"
            onClick={() => deleteLink(params?.data)}
          ></button>
        </>
      ),
      sortable: false,
      resizable: false,
      filter: false,
      maxWidth: 100,
      headerValueGetter: () => translate("actions"),
    },
  ];

  useEffect(() => {
    setColumnDefs(gridColumns.filter((gridColumn) => (isDetailPage ? gridColumn.field !== "Actions" : true)));
    setSubsidiaryLink(sectionModelData);
  }, [isDetailPage]);

  const renderEditSubsidiaryLink = () => {
    const modelMap: Record<string, any> = {
      Location: { picker: LocationPicker, pickerId: "location_id" },
      Department: { picker: DepartmentPicker, pickerId: "department_id" },
      "Business Unit": { picker: BusinessUnitPicker, pickerId: "business_unit_id" },
      Project: { picker: ProjectPicker, pickerId: "project_id" },
    };

    const subsidiaryLinkModal = modelMap[title];
    return (
      <>
        {subsidiaryLinkModal ? (
          <EditSubsidiaryLink
            title={title}
            showModal={showModal}
            closeModal={closeModal}
            modelName={title}
            picker={subsidiaryLinkModal.picker}
            pickerId={subsidiaryLinkModal.pickerId}
            modelData={linkData}
            arraName={getArrayName(title)}
            updateModelData={updateLink}
          />
        ) : (
          <>
            {linkData && linkData.metadata_field_id && (
              <EditSubsidiaryLink
                title={title}
                showModal={showModal}
                closeModal={closeModal}
                modelName={title}
                picker={MetadataFieldPicker}
                pickerId="metadata_field_Linkable_id"
                modelData={linkData}
                arraName={getArrayName(title)}
                updateModelData={updateLink}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      {isCollapse && (
        <Row className="subsidiaryLinkFilter">
          <ClientDataGrid
            columnDefs={columnDefs}
            domLayout="normal"
            suppressMenuHide={true}
            suppressMovableColumns={true}
            rowData={filterRowData(subsidiaryLink, linkableType, fieldId) || []}
            defaultColDef={{
              resizable: true,
              flex: 1,
              filter: true,
              floatingFilter: true,
            }}
            gridStorageName={gridStorageName}
            hideSideBar={true}
            pagination={true}
            paginationPageSize={10}
          />
        </Row>
      )}

      {showModal && renderEditSubsidiaryLink()}
    </>
  );
};

export default SubsidiaryLinksRenderer;
