import React, { useContext, useEffect } from "react";
import CustomDropzone from "../../dropzone/customDropzone";
import style from "./../vendorDocumentUploadModal.module.css";
import { Col, Row } from "react-bootstrap";
import { VDocumentUploadContext } from "../vendorDocumentUploadContext";

type UploadPropsType = {};
const Upload = ({}: UploadPropsType) => {
  const context = useContext(VDocumentUploadContext);

  useEffect(() => {
    return () => {
      context?.setFile(undefined);
    };
  }, []);

  return (
    <>
      {!context?.file && <CustomDropzone onFileUpload={(files) => context?.setFile(files[0])} />}
      <Row>
        <Col className="d-flex justify-content-center">
          {context?.file && (
            <div className={style.filePreview + " d-flex align-items-center "}>
              <i className="icon icon-documents-blue ml-2 mr-1 my-auto" />
              <div className={style.name}>
                {context?.documentRequirement?.document_type && `${context?.documentRequirement?.document_type} -`}{" "}
                {context?.file.name}
              </div>
              <button
                className={`icon-cross float-right mx-2 border-0 mt-2 ${style.crossIcon}`}
                onClick={() => context?.setFile(undefined)}
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Upload;
