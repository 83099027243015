import RestApi from "../../providers/restApi";
import { CreateNotification, NotificationType } from "../general/notifications";

const restApiService = new RestApi();

export const reprocessEmailPromise = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restApiService.post("inbound_emails/" + id + "/reprocess");
      CreateNotification("Success", `${id} Email reprocess successfully`, NotificationType.success);
      resolve(response);
    } catch (error) {
      reject(error);
      CreateNotification("Error", error.message, NotificationType.error);
    }
  });
};

export const markAsCompletedPromise = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = {
        inbound_email: {
          status: "Completed",
        },
      };
      const response = await restApiService.patch("inbound_emails/" + id, null, data);
      CreateNotification("Success", `${id} Email stutus updated successfully`, NotificationType.success);
      resolve(response);
    } catch (error) {
      reject(error);
      CreateNotification("Error", error.message, NotificationType.error);
    }
  });
};

export const emailStatus = ["Processed", "New", "Failed", "Completed", "Sent", "Deleted"];

export const refreshForStatus = ({ currentPage, refreshPage }) => {
  if (emailStatus.includes(currentPage)) {
    refreshPage();
  }
};

export const updateToProcessed = async ({ id, gridRef, setLoading }) => {
  setLoading(true);
  const rowNode = gridRef.current.api.getRowNode(id);
  const { data } = await restApiService.get("inbound_emails/" + id);
  data.attachments_count = data.attachments ? data.attachments.length : 0;
  data.status = "Processed";
  rowNode.setData(data);
  gridRef.current.api.redrawRows({ rowNode: [rowNode] });
  setLoading(false);
};
