import React, { useState } from "react";
import { Button, Row, Form, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import usePermission from "../../../../components/common/hooks/usePermission";
import { RenderField } from "../../../../components/forms/bootstrapFields";
import StatePicker from "../../../../components/common/pickers/reduxPickers/statePicker";
import { COMPANY_PROFILE_FORM_NAME } from "../../../../reducers/admin/companyProfileFormReducer";
import { required } from "../../../../services/validations/reduxFormValidation";
import CountryPicker from "../../../../components/common/pickers/reduxPickers/countryPicker";
import RestApi from "../../../../providers/restApi";
import ProfilePhotoPicker from "../../../../components/common/profilePhotoPicker/profilePhotoPicker";
import { useHistory } from "react-router";
import { updateCurrentUser } from "../../../../reducers/userReducers";

const restApiService = new RestApi();

let CompanyProfileForm = ({ handleSubmit }) => {
  const [loadingImage, setLoadingImage] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { hasUserPermission } = usePermission();
  const companyProfileForm = useSelector((state) => getFormValues(COMPANY_PROFILE_FORM_NAME)(state));
  const user = useSelector((state) => state.user);
  const history = useHistory();

  const profileFormData = new FormData();

  const onChangeProfilePhoto = (file) => {
    profileFormData.append("company[logo_attributes][asset_file]", file);
    profileFormData.append("company[logo_attributes][file_name]", file.name);
    profileFormData.append("company[logo_attributes][document_type]", "LOGO");
  };

  const onCancelProfilePhoto = () => {
    profileFormData.delete("company[logo_attributes][asset_file]");
    profileFormData.delete("company[logo_attributes][file_name]");
    profileFormData.delete("company[logo_attributes][document_type]");
  };

  const onSaveProfilePhoto = (saveSuccessCallback) => {
    setLoadingImage(true);
    restApiService
      .formWithImage("company", profileFormData, "patch")
      .then((response) => {
        dispatch(updateCurrentUser());
        setLoadingImage(false);
        if (saveSuccessCallback) {
          saveSuccessCallback();
        }
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };

  return (
    <Form noValidate id={COMPANY_PROFILE_FORM_NAME} onSubmit={handleSubmit}>
      <Row className="my-3">
        <Col md="4">
          <ProfilePhotoPicker
            sourceUrl={user?.company?.logo_url}
            disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
            onChangeImageCallBack={onChangeProfilePhoto}
            onCancelImageCallBack={onCancelProfilePhoto}
            onSaveImageCallBack={onSaveProfilePhoto}
            loading={loadingImage}
          />
        </Col>
        <Col md="8" className="mt-5">
          <h1>{t("companyProfile.companyProfile")}</h1>
          <h4 className="subTitle">{t("companyProfile.companyInfoRequestText")}</h4>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field
            component={RenderField}
            type="text"
            name="form.name"
            label={t("companyProfile.companyName")}
            validate={[required]}
            required
            disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
          />
          <Field
            component={RenderField}
            type="text"
            name="form.address.address1"
            label={t("companyProfile.address1")}
            disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
          />
          <Field
            component={RenderField}
            type="text"
            name="form.address.address2"
            label={t("companyProfile.address2")}
            disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
          />
          <Row>
            <Col md="6">
              <Field
                component={RenderField}
                type="text"
                name="form.address.city"
                label={t("companyProfile.city")}
                disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
              />
            </Col>
            <Col md="6">
              <Field
                component={StatePicker}
                type="text"
                name="form.address.state"
                label={t("companyProfile.state")}
                countryCode={companyProfileForm?.form?.address?.country}
                disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
              />
            </Col>
            <Col md="6">
              <Field
                component={RenderField}
                type="text"
                name="form.address.zipcode"
                label={t("companyProfile.zipcode")}
                disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
              />
            </Col>
            <Col md="6">
              <Field
                component={CountryPicker}
                type="text"
                name="form.address.country"
                label={t("companyProfile.country")}
                colSize="12"
                disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={6} md={6}>
          <Field
            component={RenderField}
            type="email"
            name="form.address.email"
            label={t("companyProfile.email")}
            validate={[required]}
            required
            disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
          />

          <Field
            component={RenderField}
            type="tel"
            name="form.address.phone"
            label={t("companyProfile.phone")}
            pattern={"[0-9]+"}
            disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
          />

          <Field
            component={RenderField}
            type="text"
            name="form.url"
            placeholder="website url"
            label={t("companyProfile.companyWebsite")}
            disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
          />

          <Field
            component={RenderField}
            type="text"
            name="form.ein"
            label={t("companyProfile.ein")}
            disabled={!hasUserPermission("addSettings") && !hasUserPermission("addcompanyProfile")}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" xs="12" className="d-flex justify-content-end">
          <Button className="m-1" variant="secondary" onClick={() => history.goBack()}>
            {t("cancel")}
          </Button>
          <Button className="m-1" type="submit">
            {t("companySettings.paymentMethodsSettings.save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

CompanyProfileForm = reduxForm({
  form: COMPANY_PROFILE_FORM_NAME, // a unique identifier for this form
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(CompanyProfileForm);

CompanyProfileForm = connect((state, props) => ({
  initialValues: state.companyProfileFormRed.companyProfileForm,
}))(CompanyProfileForm);

export default CompanyProfileForm;
