import _ from "lodash";
import { ReceiptsTypes } from "./receiptsType";
import { IDType } from "services/common/types/common.type";
import ReceiptsApis from "./receiptsApis";

class ReceiptService {
  listTabs = {
    queue: "queue",
    matched: "matched",
  };

  status = {
    processing: "PENDING_OCR",
    matchInProgress: "PENDING_MATCH",
    actionRequired: "ACTION_REQUIRED",
    matched: "MATCHED",
  };

  getLegibleRecieptType = (documentableType: string) => {
    switch (documentableType) {
      case "InvoiceItem":
      case "ExpenseItem":
        return "Expense";
      case "Purchase":
      case "BankCardTransaction":
        return "Card";
      default:
        break;
    }
  };

  getLegibleRecieptStatus = (receipt: ReceiptsTypes.ListItem): string => {
    let translatedStatus = "";
    switch (receipt.status) {
      case this.status.processing:
        translatedStatus = "Processing";
        break;
      case this.status.matchInProgress:
        translatedStatus = "Match in Progress";
        break;
      case this.status.actionRequired:
        translatedStatus = "Action Required";
        break;
      default:
        break;
    }
    return translatedStatus;
  };

  /**
   * Retrieves the merchant name from a receipt based on its documentable type.
   * If the documentable type is "Expense", it returns the vendor name.
   * If the documentable type is "Card", it returns the merchant's name.
   */
  getMerchantName = (receipt: ReceiptsTypes.ListItem) => {
    if (receipt.documentable_type) {
      const legibleReceiptType = this.getLegibleRecieptType(receipt.documentable_type);
      switch (legibleReceiptType) {
        case "Expense":
          return receipt.documentable?.vendor_name;
        case "Card":
          return (receipt.documentable?.merchant || receipt.documentable?.vendor_name) ?? "";
        default:
          return null;
      }
    }
  };

  isNotMatched = (status: string) => {
    return [
      // when ml model is running on attachment to exact data
      receiptService.status.processing,

      // when job to find match for receipt is running
      receiptService.status.matchInProgress,

      // when job to find match for receipt is failed
      receiptService.status.actionRequired,
    ].includes(status);
  };

  isExpenseItemMatch(status?: string, documentableType?: string) {
    return status === this.status.matched && (documentableType === "InvoiceItem" || documentableType === "ExpenseItem");
  }

  isPurchaseItemMatch(status?: string, documentableType?: string) {
    return status === this.status.matched && documentableType === "Purchase";
  }

  isBankCardTransactionItemMatch(status?: string, documentableType?: string) {
    return status === this.status.matched && documentableType === "BankCardTransaction";
  }

  getStatusClassLabel = (status?: string, documentableType?: string) => {
    if (status === this.status.processing) {
      return { className: "processing", label: "Processing" };
    } else if (status === this.status.matchInProgress) {
      return { className: "matchInProgress", label: "Match in Progress" };
    } else if (this.isExpenseItemMatch(status, documentableType)) {
      return { className: "expense", label: "Expense" };
    } else if (this.isPurchaseItemMatch(status, documentableType)) {
      return { className: "card", label: "Card" };
    } else if (status === this.status.actionRequired) {
      return { className: "actionRequired", label: "Action Required" };
    }
    return { className: "", label: "" };
  };

  linkReceipt = async ({
    receiptId,
    documentableId,
    documentableType,
  }: {
    receiptId: IDType;
    documentableId: IDType;
    documentableType: string;
  }) => {
    try {
      const updatePayload = {
        documentable_type: documentableType,
        documentable_id: documentableId,
        status: receiptService.status.matched,
      };
      const result = await ReceiptsApis.editReceipt(receiptId, updatePayload);
      return result;
    } catch (error) {
      throw error;
    }
  };
}

const receiptService = new ReceiptService();
export default receiptService;
