import React from "react";
import DropdownFilter from "../reportFilters/dropdown";
import DateFilter from "../reportFilters/date";
import CheckboxFilter from "../reportFilters/checkbox";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";

const SyncErrorListing = ({ scheduled }: { scheduled?: boolean }) => {
  const activitable_type_filter_options = [
    { value: "", label: "ALL" },
    { value: "ExpenseReport", label: "EXPENSE REPORT" },
    { value: "Invoice", label: "INVOICE" },
    { value: "PurchaseOrder", label: "PURCHASE ORDER" },
    { value: "Payment", label: "PAYMENT" },
    { value: "Purchase", label: "PURCHASE" },
    { value: "Vendor", label: "VENDOR" },
    { value: "Receipt", label: "RECEIPT" },
  ];

  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    } else {
      return (
        <>
          <DateFilter name={"Start Date"} code={"start_date"} options={[]} isRequired />
          <DateFilter name={"End Date"} code={"end_date"} options={[]} isRequired />
        </>
      );
    }
  };

  return (
    <div>
      {renderDateFilter()}
      <DropdownFilter name={"Sync Object Type"} code={"activitable_type"} options={activitable_type_filter_options} />
      <CheckboxFilter name={"Only Show Latest Sync Error"} code={"last_error_only"} options={[]} />
    </div>
  );
};

export default SyncErrorListing;
