import PurchaseOrderList from "./list";
import PurchaseOrderDetails from "./details";

const vpPurchaseOrderRoutes = [
  {
    path: "/ar/purchase_orders",
    exact: true,
    name: "PurchaseOrders",
    component: PurchaseOrderList,
  },
  {
    path: "/ar/purchase_orders/:id",
    exact: true,
    name: "purchaseOrderDetails",
    component: PurchaseOrderDetails,
  },
];

export { vpPurchaseOrderRoutes };
