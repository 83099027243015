import React, { useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import AccountEntry from "./accountEntry";

type Props = any;

const AccountsTabEntriesPanel = ({ accountEntryList, showAccountEntry, isPoRequest, modelData, modelName }: Props) => {
  const { label, accountEntries } = accountEntryList;
  const { control, getValues, setValue, trigger, formState } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  //TODO-SANKET : DEFINE TYpe
  const { fields, append, update, replace } = useFieldArray<any>({
    control,
    name: accountEntries,
    keyName: "_id" as "id",
  });

  const showCategoryAtFirst = useMemo(
    () => isPoRequest && currentUser.company.has_categories && !currentUser?.company?.purchase_order?.hide_categories,
    [currentUser.company.has_categories, currentUser.company?.purchase_order?.hide_categories, isPoRequest],
  );

  useEffect(() => {
    if (fields.length < 1) {
      replace([{}]);
    }
  }, [fields.length, formState.defaultValues, replace]);

  return (
    <Row>
      <Col md="12">
        {fields.map(
          (accountEntry: any, index) =>
            showAccountEntry(label, accountEntry) && (
              <AccountEntry
                key={index}
                index={index}
                label={label}
                showCategoryAtFirst={showCategoryAtFirst}
                accountEntries={accountEntries}
                modelData={modelData}
                modelName={modelName}
                isPoRequest={isPoRequest}
              />
            ),
        )}
      </Col>
    </Row>
  );
};

export default AccountsTabEntriesPanel;
