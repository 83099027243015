import { Col, Row } from "react-bootstrap";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";

export const SvbAchPayment = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
    </Col>
  );
};
