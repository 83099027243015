import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";
import { WrappedFieldProps } from "redux-form";
interface ApPaymentTypePickerPropsType extends WrappedFieldProps {
  label?: string;
  input: any;
  required?: boolean;
  tooltip?: string;
  instanceId?: string;
}

type ApPaymentTypeObjType = {
  name?: string;
  status?: string;
  external_id?: string;
  value?: number | string;
  label?: string;
};

const ApPaymentTypePicker = ({
  label,
  input,
  required,
  tooltip,
  instanceId = "payment-type-picker",
}: ApPaymentTypePickerPropsType) => {
  const [ApPaymentTypes, setApPaymentTypes] = useState<ApPaymentTypeObjType[]>([]);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const currentUserCompanyId = currentUser?.company?.id;
  const mounted = useRef(false);
  const [selected, setSelected] = useState<ApPaymentTypeObjType>();
  const [dataList, setDataList] = useState<ApPaymentTypeObjType[]>([]);

  const parseForSelect = (options: ApPaymentTypeObjType[]) => {
    return options.map((option: ApPaymentTypeObjType) => {
      return {
        value: option.external_id,
        label: option.name,
        status: option.status,
      };
    });
  };

  const filterActiveRecord = (dateList: ApPaymentTypeObjType[]) => {
    return dateList.filter((list) => list.status === "ACTIVE");
  };

  const getPostingPeriods = useCallback(async () => {
    const result: AxiosResponse = await restApiService.get(
      `companies/${currentUserCompanyId}/custom_data_list?model=ApPaymentType`,
      null,
      null,
      true,
      null,
      true,
    );
    if (result?.data?.properties?.data_list) {
      const propertiesDateList = result.data.properties.data_list;
      setDataList(propertiesDateList);
      const apPaymentTypeList = filterActiveRecord(propertiesDateList);
      let list = parseForSelect(apPaymentTypeList);
      if (mounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select AP Payment Type --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setApPaymentTypes(list);
      }
      return list;
    }
  }, []);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        const inactiveApPaymentType = _.find(dataList, (option) => option.external_id === input.value);
        selectedValues = inactiveApPaymentType;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    getPostingPeriods().then((res) => {
      findSelected(input, res).then((response: ApPaymentTypeObjType) => {
        setSelected(response);
      });
    });
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [input]);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={selected}
          isClearable={!required}
          onChange={(selected) => onChangeSingle(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={ApPaymentTypes}
          instanceId={instanceId} // don't change or remove used in automation testing
          getOptionLabel={(option: ApPaymentTypeObjType) => {
            return (
              <small>
                {option.label ? option.label : option.name}
                {option.status === "INACTIVE" ? (
                  <span style={{ fontSize: "10px" }}> - ({option.status})</span>
                ) : (
                  <span> ({option.status})</span>
                )}
              </small>
            );
          }}
        ></Select>
      </Form.Group>
    </>
  );
};

export default ApPaymentTypePicker;
