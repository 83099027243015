import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../vendors.module.css";
import { FieldArray, change, getFormValues } from "redux-form";
import ErrorBoundary from "../../../common/errorBoundary/errorBoundary";
import VendorPaymentMethod from "./paymentMethod";
import { VendorType } from "../../../../services/admin/vendors/vendorTypes";
import { useTypedSelector } from "../../../../reducers";
import { useDispatch } from "react-redux";
import { CompanyType } from "../../../../services/common/user/userTypes";

export type AddPaymentMethodsPropsType = {
  handleNext: (step: number) => void;
  handlePrevious: (step: number) => void;
  handleCancel: () => void;
  currentUserCompany?: CompanyType;
  valid?: boolean;
};

const AddPaymentMethod = ({
  handleNext,
  handlePrevious,
  handleCancel,
  currentUserCompany,
  valid,
}: AddPaymentMethodsPropsType) => {
  const vendor: VendorType = useTypedSelector((state) => getFormValues("VendorForm")(state));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSkip = () => {
    dispatch(change("VendorForm", `payment_methods_attributes`, []));
    handleNext(5);
    dispatch(change("VendorForm", "skipPaymentMethodStep", true));
  };

  useEffect(() => {
    if (vendor.moveToNextStep) {
      handleNext(5);
      dispatch(change("VendorForm", "moveToNextStep", false));
    }
  }, [vendor?.moveToNextStep]);

  return (
    <>
      <Row className="px-mb-25">
        <Col md="12">
          <span className={styles.vendorDetails}>
            <i className={`icon icon-payments ${styles.iconVendor}`}></i>
            {t("admin.pages.vendor.addPaymentMethod")}
          </span>
          <br />
          <span>{t("admin.pages.vendor.contactNote")}</span>
        </Col>
      </Row>
      <ErrorBoundary>
        <Row className="px-mt-25">
          <Col>
            <FieldArray
              key={`vendorPaymentMethodAttributes`}
              name="payment_methods_attributes"
              component={VendorPaymentMethod}
              sectionName="vendor"
              currentUserCompany={currentUserCompany}
            />
          </Col>
        </Row>
      </ErrorBoundary>
      <Row className="px-mt-25">
        <Col md={4}>
          <Button variant="secondary" className="px-mr-10" onClick={() => handlePrevious(5)}>
            {t("admin.pages.vendor.back")}
          </Button>
        </Col>
        <Col md={{ span: 4, offset: 4 }} className={styles.btnColumn}>
          <Button variant="secondary" className="px-mr-10" onClick={() => handleCancel()}>
            {t("admin.pages.vendor.cancel")}
          </Button>
          <>
            <Button variant="secondary" className="px-mr-10" onClick={() => handleSkip()}>
              {t("admin.pages.vendor.skip")}
            </Button>
            <Button
              variant="primary"
              className={styles.btnNext}
              onClick={() => handleNext(5)}
              disabled={!valid}
              title={`${!valid ? "To continue, complete all required fields" : ""}`}
            >
              Next
            </Button>
          </>
        </Col>
      </Row>
    </>
  );
};

export default AddPaymentMethod;
