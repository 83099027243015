import React from "react";

// these select all is used to select all in datagrid
// for SSRM for ag-grid, selectAll ,toggleSelectAll, will be used form withSelectAll hoc
const SelectAll = ({ selectAll, toggleSelectAll }) => {
  return (
    <div className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${selectAll && "ag-checked"}`}>
      <input
        type="checkbox"
        checked={selectAll}
        onChange={() => toggleSelectAll()}
        className="ag-input-field-input ag-checkbox-input"
      />
    </div>
  );
};

export default SelectAll;
