import moment from "moment";
import { formattedAmount } from "../../../services/general/helpers";
import { translate } from "../../../services/general/translation";

const vendorPaymentsHeader = [
  {
    field: "selectAll",
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    maxWidth: 40,
    minWidth: 40,
    menuTabs: [],

    // sortable: true ,
    // filter: true,
  },
  {
    field: "id",
    headerName: "ID",
    hide: true,
    headerValueGetter: function () {
      return translate("id");
    },
  },
  {
    field: "external_id",
    headerName: "External ID",
    hide: true,
    headerValueGetter: function () {
      return translate("externalId");
    },
  },
  {
    field: "payment_number",
    headerName: "Payment Number",
    // headerCheckboxSelection: true,
    // headerCheckboxSelectionFilteredOnly: true,
    // checkboxSelection: true,
    cellRenderer: "childCellRouting",
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
      headerValueGetter: function () {
        return translate("payment_number");
      },
    },
    width: 300,
    // aggregationType: uiGridConstants.aggregationTypes.count,
    // footerCellTemplate: '<div class="ui-grid-cell-contents">\count:\ {{col.getAggregationValue() | number }}</div>'
  },
  {
    field: "purchaser.name",
    headerName: "Customer",
    cellRenderer: "childCellRouting",
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("customer");
    },
  },
  {
    field: "payment_date",
    headerName: "Date",
    filter: "agDateColumnFilter",
    minWidth: 197,
    filterParams: {
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        console.log(filterLocalDateAtMidnight);
        console.log(cellValue);

        // var dateForm = new Date(cellValue)
        // console.log(dateForm)

        // var dateAsString = cellValue.replace(/-/g, "/"); //changing date format /g for global
        //
        // console.log(dateAsString)
        var dateAsString = moment(cellValue).format("DD/MM/YYYY");
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        console.log(dateParts);
        var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
        console.log(cellDate);
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    },
    cellRendererSelector: function (params) {
      return;
    },
    headerValueGetter: function () {
      return translate("date");
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    cellRenderer: (params) => formattedAmount(params.data?.amount, params.data?.currency_code),
    minWidth: 120,
    filter: "agNumberColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("amount_only");
    },
  },
  {
    field: "status",
    headerName: "Status",
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      options: [
        {
          label: "ALL",
          value: "",
        },
        {
          label: "NEW",
          value: "NEW",
        },
        {
          label: "PENDING",
          value: "PENDING",
        },
        {
          label: "PAID",
          value: "PAID",
        },
        {
          label: "CLEARED",
          value: "CLEARED",
        },
      ],

      suppressAndOrCondition: true,
    },
    floatingFilterComponent: "floatingFilterComponent",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    headerValueGetter: function () {
      return translate("status");
    },
  },
  {
    field: "payment_type",
    headerName: "Payment Type",
    filter: "agTextColumnFilter",
    width: 250,
    hide: true,
    headerValueGetter: function () {
      return translate("payment_type");
    },
  },
  {
    field: "virtual_card.card_number_masked",
    headerName: "Virtual Card",
    filter: "agTextColumnFilter",
    cellRenderer: "childCellRouting",
    width: 250,
    hide: true,
    headerValueGetter: function () {
      return translate("comdata_vcard_numbers");
    },
  },
  {
    field: "bank_name",
    headerName: "Orig. Bank Name",
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    width: 300,
    hide: true,
    headerValueGetter: function () {
      return translate("org_bank_name");
    },
  },
  {
    field: "reference_number",
    headerName: "Ref. Number",
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    width: 230,
    hide: true,
    headerValueGetter: function () {
      return translate("reference_number");
    },
  },
  {
    field: "invoices",
    headerName: "Invoices",
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    hide: true,
    headerValueGetter: function () {
      return translate("invoices");
    },
  },
  // {
  //     field: '',
  //     // 'field':'Actions',
  //     cellRenderer: 'childMessageRenderer',
  //     sortable: false,
  //     floatingFilter: false,
  //     resizable: false,
  //     width: 250,
  //     maxWidth: 250,
  //     // pinned: 'right',
  //     cellRendererSelector:function(params) {return},
  //
  // }
];

export default vendorPaymentsHeader;
