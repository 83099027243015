import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const PopoverRender = (props) => {
  const { id, title, content, className, trigger, rootClose, placement, popoverClassName } = props;
  return (
    <>
      <OverlayTrigger
        trigger={trigger}
        placement={placement}
        rootClose={rootClose}
        overlay={
          <Popover id={id} className={popoverClassName}>
            <Popover.Title as="h3">{title}</Popover.Title>
            <Popover.Content>{content}</Popover.Content>
          </Popover>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <span {...triggerHandler}>
            <i className={className} ref={ref} />
          </span>
        )}
      </OverlayTrigger>{" "}
    </>
  );
};

export default PopoverRender;
