import React, { memo } from "react";
import { change, Field, FieldArray } from "redux-form";
import { Table } from "react-bootstrap";
import Panel from "../../../panel/panel";
import { RenderCheckBox } from "../../../forms/bootstrapFields";
import ItemLinesRenderer from "./itemLinesRenderer/itemLinesRenderer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
const InvoiceFormName = "InvoiceForm";

const ItemLine = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectAll = (value) => {
    props.poItems.map((item) => {
      item.isAllow = value;
    });
    dispatch(change(InvoiceFormName, "poItems", props.poItems));
  };
  return (
    <Panel
      panelContent={{
        heading: t("details.lineItems"),
        iconClass: "icon icon-line-items",
      }}
      cardBodyStyle={{ padding: "0px" }}
    >
      <Table hover size="sm" className="list-table">
        <thead>
          <tr>
            <th>
              <Field name="selectAllItems" component={RenderCheckBox} onChange={(e) => selectAll(e)} />
            </th>
            <th>{t("details.itemName")}</th>
            <th>{t("items.qty")}</th>
            <th>{t("details.received")}</th>
            <th>{t("items.unitPrice")}</th>
            <th>{t("details.tax")}</th>
            <th>{t("details.billedQty")}</th>
            <th>{t("details.totalAmount")}</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray name="poItems" component={ItemLinesRenderer} currencyCode={props.currencyCode} />
        </tbody>
      </Table>
    </Panel>
  );
};

export default memo(ItemLine);
