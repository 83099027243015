import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Select from "react-select";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import { onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import { WrappedFieldProps } from "redux-form";
import useIsMounted from "components/common/hooks/useIsMounted";
import _ from "lodash";

interface SecondaryCategoryPickerPropsType extends WrappedFieldProps {
  label?: ReactNode;
  input: any;
  required?: boolean;
  filter?: { [key: string]: any };
  isMulti?: boolean;
  floating?: boolean;
  tooltip?: ReactNode;
  callBack?: (arg: any) => void;
  instanceId?: string;
}

const SecondaryCategoryPicker = ({
  label,
  filter = { status: "ACTIVE" },
  isMulti,
  tooltip,
  input,
  floating = false,
  required,
  callBack,
  instanceId = "secondary-category-picker",
}: SecondaryCategoryPickerPropsType) => {
  const [secondaryCategories, setSecondaryCategores] = useState([]);
  const [selected, setSelected] = useState();
  const isMounted = useIsMounted();

  const parseForSelect = (options: any) => {
    return options.map((option: any) => {
      return {
        value: option.id,
        label: option.name,
        status: option.status,
      };
    });
  };

  const getSecondaryCategories = useCallback(async () => {
    const result: AxiosResponse<[]> = await restApiService.get("secondary_categories", filter, null, true, null, true);
    if (result?.data) {
      let list = parseForSelect(result?.data);
      if (isMounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Secondary Category --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setSecondaryCategores(list);
      }
      return list;
    }
  }, []);

  const onChangeSecondaryCategory = (input: any, selected: any) => {
    onChangeSingle(input, selected);
    setSelected(selected);
    if (callBack) {
      callBack(selected);
    }
  };

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        const result = await restApiService.get("secondary_categories/" + input.value);
        const inactiveSecondaryCategories = {
          value: result.data.id,
          label: (
            <>
              {result.data.name} - <small style={{ fontSize: "10px" }}>({result.data.status})</small>
            </>
          ),
        };
        selectedValues = inactiveSecondaryCategories;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    getSecondaryCategories().then((res) => {
      findSelected(input, res).then((response) => {
        setSelected(response);
      });
    });
  }, []);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
          {tooltip}
        </Form.Label>
      )}
      <Select
        {...input}
        isMulti={isMulti ? true : false}
        required={required}
        options={secondaryCategories}
        value={selected}
        isClearable={true}
        onChange={(selected) => onChangeSecondaryCategory(input, selected)}
        onBlur={() => onBlurSingle(input, input.value)}
        getOptionLabel={(option: any) => {
          return (
            <>
              <span className="formField">{option.label}</span>
              {option.status === "INACTIVE" && <small style={{ fontSize: "10px" }}> - ({option.status})</small>}
            </>
          );
        }}
        filterOption={(option: any, searchText) =>
          option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
        }
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
          }),
          ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
        }}
        {...(floating ? { menuPortalTarget: document.body } : {})}
        instanceId={instanceId}
      />
    </Form.Group>
  );
};

export default SecondaryCategoryPicker;
