import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import NotificationsTabs from "../../../components/admin/notifications/notificationsTabs";
import NotificationsRenderer from "../../../components/admin/notifications/notificationsRenderer";
import { getUrlLocator } from "../../../services/common/urlHelperSvc";
import styles from "./notification.module.css";

const getMonth = (values) => {
  return values.reduce((acc, obj) => {
    const date = moment(obj.created_at);
    if (acc && date.month() === moment(values[0].created_at).month()) {
      return moment(date.month() + 1, "M").format("MMM");
    }
  }, true);
};

// Group Array by Week for Organized Record Keeping
const groupByWeek = (notifications) => {
  return _.chain(notifications)
    .groupBy("weekGroup")
    .map((value, key) => ({
      weekGroup: key,
      rows: value,
      month: getMonth(value),
    }))
    .value();
};

// Monthly Record Classification for Streamlined Data Manage
const groupByMonth = (notifications) => {
  return _.chain(notifications)
    .groupBy("monthGroup")
    .map((value, key) => ({
      monthGroup: key,
      items: groupByWeek(notifications, value),
    }))
    .value();
};

const isNotificationSectionInfoAvailable = (notification) => {
  return (
    _.isObject(notification.properties) &&
    _.isObject(notification.properties.app) &&
    _.isObject(notification.properties.app.object)
  );
};

const getNotificationId = (notification) => {
  let notificationId;

  if (isNotificationSectionInfoAvailable(notification)) {
    notificationId = notification.properties.app.object.is_approval
      ? notification.properties.app.object.id
      : notification.properties.app.object.approvable_id;
  }
  return notificationId;
};

const getObjectType = (notification) => {
  let objectType;
  if (isNotificationSectionInfoAvailable(notification)) {
    if (notification.properties.app.object.object_type === "InvoiceItem") {
      return "expenseitem";
    }
    // is_approval's value is store in properties hence it will always be string type.
    objectType =
      notification.properties.app.object.is_approval == "true"
        ? notification.properties.app.object.object_type + "_approval"
        : notification.properties.app.object.object_type;
  }
  return objectType;
};

const ShowNotifications = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [tab, setTab] = useState("notificationList");
  const [count, setCount] = useState(0);
  const [receivedNotifications, setReceivedNotifications] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const restApiService = new RestApi();

  // turns DateTime into a more readable date
  // ex: "2022-04-17T16:31:24.652-07:00" -> "Apr 17th, 2022 04:31PM"
  // set monthGroup and weekGroup variables which help for grouping
  // generates urlLocator for notification and message href.
  // ex: "invoices_approval/121778"
  const decorateData = async (notifications) => {
    notifications.map((notification) => {
      notification.formatted_created_at = moment(notification.created_at).format("MMM DD, YYYY hh:mmA");
      notification.monthGroup = moment(notification.created_at).format("MMMM YYYY");
      notification.weekGroup =
        moment(notification.created_at).startOf("isoweek").format("MMM DD") +
        " - " +
        moment(notification.created_at).endOf("isoweek").format("MMM DD");
      notification.urlLocator = `${getUrlLocator(getObjectType(notification), getNotificationId(notification))}${tab === "messageList" ? "?flag=msg" : ""}`;
    });
    setNotificationData(groupByMonth(notifications));
  };

  const getNotificationDetails = async (tab) => {
    let route;
    let params = { items: 50, page: pageCount };

    if (tab === "notificationList") {
      route = "messages/index_app_notifications";
    }
    if (tab === "messageList") {
      route = "messages/index_notification_messages";
    }
    if (tab === "newsList") {
      route = "messages/index_news_notifications";
    }

    restApiService.get(route, params).then((response) => {
      if (_.isArray(response.data)) {
        decorateData(response.data);
      }
    });
  };

  const toggleShowMoreNotificationsBtn = (tab, count) => {
    if (
      tab === "notificationList" &&
      count.new_notifications >= 50 &&
      receivedNotifications <= count.new_notifications
    ) {
      return true;
    }
    if (tab === "messageList" && count.new_messages >= 50 && receivedNotifications <= count.new_messages) {
      return true;
    }
  };

  const getNotificationCount = () => {
    restApiService.get("messages/unread_notifications_count").then((response) => {
      if (_.isPlainObject(response.data)) {
        setCount(response.data);
      }
    });
  };

  const refreshNotifications = () => {
    invokeNotificationApis(tab);
  };

  const handleMarkAllAsRead = (currentTab) => {
    let route;
    if (currentTab === "notificationList") {
      route = "messages/mark_notifications_as_read";
    }
    if (currentTab === "messageList") {
      route = "messages/mark_messages_as_read";
    }
    if (currentTab === "newsList") {
      route = "messages/mark_news_as_read";
    }

    restApiService.post(route).then((val) => {
      invokeNotificationApis(tab);
    });
  };

  // Refreshing tab state from NotificationsTabs component and triggering API request.
  const handleTabChange = (selectedTab) => {
    setPageCount(1); // Reset page count to start from page 1
    setReceivedNotifications(0);
    setTab(selectedTab);
  };

  const invokeNotificationApis = (tab) => {
    getNotificationCount();
    getNotificationDetails(tab);
  };

  const handleShowMoreBtn = (tab) => {
    getNotificationDetails(tab);
    setPageCount((prevPageCount) => prevPageCount + 1);
    setReceivedNotifications((prevReceivedNotifications) => prevReceivedNotifications + 50);
  };

  useEffect(() => {
    invokeNotificationApis(tab);
  }, [tab]);

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <Row className="m-0">
        <Col xs={10}>
          <NotificationsTabs count={count} handleTabChange={handleTabChange} />
        </Col>
        <Col xs={2} className="p-0">
          <Button variant="secondary" className={styles.btnAllRead} onClick={() => handleMarkAllAsRead(tab)}>
            Mark all as read
          </Button>
        </Col>
      </Row>
      {_.isArray(notificationData) &&
        notificationData.length > 0 &&
        notificationData.map((notification, index) => {
          return <NotificationsRenderer key={index} {...notification} refreshNotifications={refreshNotifications} />;
        })}
      {toggleShowMoreNotificationsBtn(tab, count) && (
        <Row>
          <Col xs={4}></Col>
          <Col xs={{ span: 4, offset: 1 }} className="text-center">
            <Button variant="primary" className={styles.btnShowMore} onClick={() => handleShowMoreBtn(tab)}>
              Show More
            </Button>
          </Col>
          <Col xs={4}></Col>
        </Row>
      )}
    </Container>
  );
};

export default ShowNotifications;
