const INITIAL_STATE = {
  EditForm: {},
  selectedPerDiem: {},
  item_type: "",
  tax: "",
  assets: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_ITEM_TYPE":
      return {
        ...state,
        item_type: action.payload.value,
      };
    case "CALCULATE_EXCHANGE_TAX":
      return {
        ...state,
        tax: action.payload,
      };
    case "UPDATE_EXPENSE_DETAILS":
      return {
        ...state,
        EditForm: action.payload,
      };
    case "UPDATE_PER_DIEM":
      return {
        ...state,
        selectedPerDiem: action.payload,
      };
    case "SET_EXPENSE_ITEM_ASSETS":
      return {
        ...state,
        assets: action.payload,
      };
    default:
      return state;
  }
};
