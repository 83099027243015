import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import restApi from "../../../../providers/restApi";
import Select from "react-select";
import Styles from "../override.module.css";
import { useTranslation } from "react-i18next";
import { findSelectedSingle, onBlurSingle, onChangeSingle, parseForSelect } from "../../../../services/general/helpers";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "../../../common/pickers/reduxFormPickers/select/helpers";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */
export default function CategoryPicker({
  label,
  labelClassName,
  input,
  meta: { touched, error, warning },
  inputId,
  errorMessage = "Error occurred picking Category",
  required = false,
  placeholder = "search/select",
  disabled = false,
  originalValue,
}) {
  const [categories, setCategories] = useState([]);
  const [originalLabel, setOriginalLabel] = useState(null);
  const { t } = useTranslation();

  const restApiService = new restApi();

  const getCategories = useCallback(async () => {
    const result = await restApiService.get("categories.lk", {
      status: "ACTIVE",
    });
    const value = parseForSelect(result.data);
    setCategories(value);
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (originalValue && categories.length > 0) {
      setOriginalLabel(findSelectedSingle({ value: originalValue }, categories)?.label);
    }
  }, [categories, originalValue]);

  return (
    <>
      <Form.Group className={Styles.select}>
        {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
        <Select
          {...input}
          inputId={inputId}
          required={required}
          {...(originalValue !== undefined
            ? { components: { Input: (props) => <PickerComparisonField {...props} originalValue={originalLabel} /> } }
            : {})}
          value={findSelectedSingle(input, categories)}
          placeholder={t(placeholder)}
          onChange={(value) => onChangeSingle(input, value)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={categories}
          isMulti={false}
          isClearable
          classNamePrefix="select"
          isDisabled={disabled}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
            }),
          }}
        />
        {error && touched && <div style={{ fontSize: "80%", color: "red", padding: "2px" }}>{t({ errorMessage })}</div>}
      </Form.Group>
    </>
  );
}
