import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { restApiService } from "../../../../../providers/restApi";
import { useTypedSelector } from "../../../../../reducers";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import CurrencyPicker from "../../../pickers/reduxPicker/currencyPicker/currencyPicker";
import PaymentCountryPicker from "../../../../pickers/paymentCountryPicker";
import { CHANGE_PAYEE_TYPE, CHANGE_PROGRAM_ID } from "../../../../../actions/actionTypes";

type ProgramType = {
  currency_code: string;
  program_id: string;
};

const PayoneerHeader = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [programList, setProgramList] = useState<Array<ProgramType>>([]);
  const user = useTypedSelector((state) => state.user);
  const paymentMethodRed = useTypedSelector((state) => state.paymentMethodRed);
  const currencyCode = useTypedSelector((state) => state.paymentMethodRed.currency_code);

  const getPrograms = async () => {
    try {
      const response = await restApiService.get("companies/" + user.company.id + "/get_programs");
      setProgramList(response.data);
    } catch (error) {}
  };

  const setProgramIdByCurrency = (currencyCode: any) => {
    // iterate over programlist and  program.currency_code to currency_code if matched update that
    // program.program_id to paymentMethodRed.program_id
    _.isArray(programList) &&
      programList.forEach((program: any) => {
        if (program.currency_code === currencyCode) {
          changeProgramId(program.program_id);
        }
      });
  };

  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    setProgramIdByCurrency(currencyCode);
  }, [currencyCode]);

  const changeProgramId = (programId: string) => {
    dispatch({ type: CHANGE_PROGRAM_ID, payload: programId });
  };

  const changePayeeType = (payeeType: string) => {
    dispatch({ type: CHANGE_PAYEE_TYPE, payload: payeeType });
  };

  return (
    <Row>
      <PaymentCountryPicker
        {...props}
        required={true}
        selected={paymentMethodRed && { value: paymentMethodRed.country }}
      />

      <CurrencyPicker {...props} selected={paymentMethodRed && paymentMethodRed.currency_code} />

      <Form.Group as={Col}>
        <Form.Label>
          Program ID
          <span className={"color-red"}>*</span>
        </Form.Label>
        <Form.Control
          type="input"
          as={"select"}
          name="program_id"
          value={paymentMethodRed && paymentMethodRed.program_id}
          onChange={(e) => changeProgramId(e.target.value)}
          required
        >
          <option value="">-- select --</option>
          {_.isArray(programList) &&
            programList.map((program) => (
              <option key={program.program_id} value={program.program_id}>
                {program.program_id} - {program.currency_code}
              </option>
            ))}
        </Form.Control>
        <Form.Control.Feedback type="valid">Success</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">Error</Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} md="4">
        <Form.Label>
          {t("payoneer.payeeType")}:<span className={"color-red"}>*</span>
        </Form.Label>
        <Form.Control
          required
          as="select"
          value={paymentMethodRed && paymentMethodRed.payee_type}
          onChange={(e) => changePayeeType(e.target.value)}
        >
          <option value={"COMPANY"}>{t("company")}</option>
          <option value={"INDIVIDUAL"}>{t("individual")}</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">{t("validation.payeeType")}</Form.Control.Feedback>
      </Form.Group>
    </Row>
  );
};

export default PayoneerHeader;
