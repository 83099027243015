import { restApiService } from "providers/restApi";
import { IDType } from "services/common/types/common.type";
import { PolicyTypes } from "./policyTypes";
import { AxiosResponse } from "axios";

class PolicyApis {
  static getPolicy = async ({ policyId }: { policyId: IDType; cache?: boolean }) => {
    try {
      const response: AxiosResponse<PolicyTypes.Details> = await restApiService.get(
        "policies/" + policyId,
        null,
        null,
        true,
        null,
        true,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export default PolicyApis;
