import React from "react";
import DateFilter from "../reportFilters/date";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";
import DropdownFilter from "../reportFilters/dropdown";

const CommentListing = ({ scheduled }: { scheduled?: boolean }) => {
  const comment_type_options = [
    { value: "", label: "ALL" },
    { value: "Accrual", label: "Accrual" },
    { value: "AccrualBlast", label: "AccrualBlast" },
    { value: "AccrualRequest", label: "AccrualRequest" },
    { value: "BankCardStatementchase", label: "BankCardStatement" },
    { value: "Company", label: "Company" },
    { value: "Contact", label: "Contact" },
    { value: "Expense", label: "Expense" },
    { value: "ExpenseReport", label: "ExpenseReport" },
    { value: "InternalIntegrationPayment", label: "InternalIntegrationPayment" },
    { value: "IntegrationEvent", label: "IntegrationEvent" },
    { value: "Invoice", label: "Invoice" },
    { value: "InvoiceItem", label: "InvoiceItem" },
    { value: "Payment", label: "Payment" },
    { value: "PaymentRun", label: "PaymentRun" },
    { value: "ProductItem", label: "ProductItem" },
    { value: "Purchase", label: "Purchase" },
    { value: "PurchaseOrder", label: "PurchaseOrder" },
    { value: "Receipt", label: "Receipt" },
    { value: "Report", label: "Report" },
    { value: "User", label: "User" },
    { value: "Vendor", label: "Vendor" },
    { value: "VirtualCard", label: "VirtualCard" },
  ];

  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    } else {
      return (
        <>
          <DateFilter name={"Start Date"} code={"start_date"} options={[]} isRequired />
          <DateFilter name={"End Date"} code={"end_date"} options={[]} isRequired />
        </>
      );
    }
  };

  return (
    <div>
      {renderDateFilter()}
      <DropdownFilter name={"Comment Item Type"} code={"activitable_type"} options={comment_type_options} />
    </div>
  );
};

export default CommentListing;
