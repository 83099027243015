import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Field, change } from "redux-form";
import CountryCurrencyCodePicker from "../../pickers/reduxFormPickers/countryCurrencyCodePicker";
import { useTranslation } from "react-i18next";
import { numberOnly, required } from "../../../../services/validations/reduxFormValidation";
import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import { RenderField, RenderSelect } from "../../../forms/bootstrapFields";
import BankAddressLine from "../bankAddressLine";
import { useDispatch } from "react-redux";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../providers/restApi";
import { CompanyType } from "../../../../services/common/user/userTypes";
import _ from "lodash";
import { international_payment_countries } from "../../../app.svc.Lookup";
import useIsMounted from "../../hooks/useIsMounted";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

type ProgramType = {
  currency_code?: string | null;
  program_id?: string | null;
  label?: string | null;
};

type PayoneerPaymentMethodType = {
  currentUserCompany?: CompanyType;
  modelName?: string;
  modelData?: any;
};

const PayoneerPaymentMethod = ({ modelName, currentUserCompany, modelData }: PayoneerPaymentMethodType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [programList, setProgramList] = useState<ProgramType[]>();
  const [country, setCountry] = useState<string>();
  const [requiredFields, setRequiredFields] = useState<any>();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);

  const [payoneerObj, setPayoneerObj] = useState({
    currency: "",
    country: "",
    program: "",
    purchaser_id: currentUserCompany?.id,
    type: "payoneer",
  });

  const fetchRequiredPayoneerFields = async () => {
    setIsLoading(true);
    try {
      const result = await restApiService.get("payment_methods/required_fields", payoneerObj);
      if (result.data) {
        setRequiredFields(result.data.response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    if (!payoneerObj || !payoneerObj?.currency || !payoneerObj.country || !payoneerObj.program) {
      return;
    }

    if (isMounted.current) {
      fetchRequiredPayoneerFields();
    }
  }, [payoneerObj, isMounted.current]);

  const handleChangeCountryObject = (countryCurrencyCode: any) => {
    let selectedCountryCode: any = international_payment_countries.find(
      (country) => country.code2 === countryCurrencyCode?.country_code,
    )?.code;
    dispatch(change("VendorForm", `${modelName}.address_attributes.country`, selectedCountryCode));
    setCountry(selectedCountryCode);
    setPayoneerObj((prev) => ({ ...prev, country: selectedCountryCode }));
  };

  const getPrograms = async () => {
    try {
      const response: AxiosResponse<ProgramType[]> = await restApiService.get(
        "companies/" + currentUserCompany?.id + "/get_programs",
      );
      if (_.isArray(response.data) && response.data.length > 0) {
        const transformedProgramList = response.data.map((program) => ({
          program_id: program.program_id,
          currency_code: program.currency_code,
          label: program.program_id + " - " + program.currency_code,
        }));

        // Add a blank entry
        transformedProgramList.unshift({
          program_id: "",
          currency_code: "",
          label: "",
        });

        setProgramList(transformedProgramList);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const mapField = (payoneerField: string) => {
    switch (payoneerField) {
      case "bank_code":
        return "bankCode";
      case "swift":
        return "bic";
      case "branch_code":
        return "branchCode";
      case "routing_number":
        return "routing";
      case "bank_number":
        return "ifsc_code";
      case "bsb":
        return "bsb_code";
      default:
        return payoneerField;
    }
  };

  const changeProgramId = (label: string) => {
    if (_.isArray(programList) && programList.length > 0) {
      const selectedProgram = programList.find((program) => program.label === label);

      const newProgramId = selectedProgram ? selectedProgram.program_id : "";
      if (newProgramId) {
        setPayoneerObj((prev) => ({ ...prev, program: newProgramId }));
        dispatch(change("VendorForm", `${modelName}.programId`, newProgramId));
      }
    }
  };

  const changeCurrency = (currencyCode: string) => {
    setPayoneerObj((prev) => ({ ...prev, currency: currencyCode }));
    const selectedProgram: any = programList?.find((program) => program.currency_code === currencyCode);
    if (selectedProgram) {
      const transformedSelectedProgram = {
        label: selectedProgram.program_id + " - " + selectedProgram.currency_code,
        program_id: selectedProgram.program_id,
        currency_code: selectedProgram.currency_code,
      };
      dispatch(change("VendorForm", `${modelName}.program_id`, transformedSelectedProgram.label));
      changeProgramId(transformedSelectedProgram.label);
    }
  };

  useEffect(() => {
    getPrograms();
  }, []);

  return (
    <>
      <Row>
        <Col md="4">
          <Field
            name={`${modelName}.address_attributes.country_object`}
            component={CountryCurrencyCodePicker}
            label={t("common.paymentMethod.countryAccountResides")}
            className={`w-100`}
            returnObject
            onChange={(obj: React.ChangeEvent<HTMLInputElement>) => handleChangeCountryObject(obj)}
            required
            validate={[required]}
          />
        </Col>

        <Col sm="4">
          <Field
            name={`${modelName}.currency_code`}
            component={CurrencyCodePicker}
            FieldClassName="formField"
            placeholder={"Search/Select"}
            label={t("common.paymentMethod.currencyCode")}
            validate={[required]}
            required
            onChange={(currencyCode: any) => changeCurrency(currencyCode)}
            currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
          />
        </Col>

        <Col md="4">
          <Field
            name={`${modelName}.program_id`}
            component={RenderSelect}
            FieldClassName="formField"
            options={programList ?? []}
            validate={[required]}
            required
            placeholder={"Search/Select"}
            label={t("common.paymentMethod.programId")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeProgramId(e.target.value)}
          />
        </Col>

        <Col md="4">
          <Field
            name={`${modelName}.payee_type`}
            component={RenderSelect}
            FieldClassName="formField"
            options={[
              { value: null, label: "-- Select --" },
              { value: "COMPANY", label: "Company" },
              { value: "INDIVIDUAL", label: "Individual" },
            ]}
            validate={[required]}
            required
            placeholder={"Search/Select"}
            label={t("common.paymentMethod.payeeType")}
          />
        </Col>

        <Col md="4">
          <Field
            name={`${modelName}.contact_first_name`}
            component={RenderField}
            type="text"
            label={t("common.paymentMethod.contactFirstName")}
            required={true}
            validate={[required]}
          />
        </Col>
        <Col md="4">
          <Field
            name={`${modelName}.contact_last_name`}
            component={RenderField}
            type="text"
            label={t("common.paymentMethod.contactLastName")}
            required={true}
            validate={[required]}
          />
        </Col>
        <Col md="4">
          <Field
            name={`${modelName}.contact_email`}
            component={RenderField}
            type="text"
            label={t("common.paymentMethod.contactEmail")}
            required={true}
            validate={[required]}
          />
        </Col>
        <Col md="4">
          <Field
            name={`${modelName}.phoneNumber`}
            component={RenderField}
            type="text"
            label={t("common.paymentMethod.contactPhone")}
            required={true}
            validate={[required, numberOnly]}
          />
        </Col>

        {!isLoading && requiredFields && requiredFields.code === 0 && (
          <>
            {requiredFields.payout_method.details &&
              Object.entries(requiredFields.payout_method.details).map(([fieldId, fieldData]: [string, any]) => (
                <React.Fragment key={fieldId}>
                  {fieldData.field_type === "Text" && (
                    <Col md="4" key={fieldId}>
                      {fieldData.label && (
                        <Field
                          name={`${modelName}.${mapField(fieldId)}`}
                          component={RenderField}
                          type="text"
                          label={fieldData.label}
                          required={fieldData.required === "True"}
                          validate={fieldData.required === "True" ? [required] : []}
                        />
                      )}
                    </Col>
                  )}
                </React.Fragment>
              ))}
          </>
        )}
      </Row>

      {isLoading && (
        <Row>
          <Col>
            <Spinner size="sm" animation="border" variant="info" />
          </Col>
        </Row>
      )}

      {!isLoading && requiredFields && requiredFields.code !== 0 && (
        <Row className="px-mt-10 px-mb-10">
          <Col>{<strong className="errorTxtColor">Error fetching fields: {requiredFields.description}</strong>}</Col>
        </Row>
      )}

      <Row>
        <BankAddressLine
          modelName={`${modelName}`}
          hideCountry={true}
          defaultCountry={country}
          isRequired={true}
          isStateRequired={true}
        />
      </Row>
    </>
  );
};

export default PayoneerPaymentMethod;
