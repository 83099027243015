import { change } from "redux-form";
import { RenderCheckBox } from "../../../../../../components/forms/bootstrapFields";
import { CompanyDefaultsTypes } from "../../settingsTypes";
import i18n from "i18next";

export const vendorCreditSettingsHeaderLevel: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.vendor_credit_allow_tax_to_account",
    tooltip: {
      show: true,
      title: "Allowing tax for vendor credit accounts once Selected",
      placement: "top",
    },
    label: "Enable Tax to vendor credit accounts",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.term_required",
    tooltip: {
      show: true,
      title: "Payment terms will be required field at vendor credit form",
      placement: "top",
    },
    label: "Payment Terms Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.posting_period_required",
    tooltip: {
      show: true,
      title: "Posting period will be required field at vendor credit form",
      placement: "top",
    },
    label: "Posting Period Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.credit_memo.show_vendor_id",
    tooltip: {
      show: true,
      title: "Allow Credit Memo to Show Vendor ID, Address and Payment Method Type on Credit Memo inbox section",
      placement: "top",
    },
    label: "Show Vendor ID, Address and Payment Method Type on Credit Memo Inbox",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.credit_memo.readonly_header_level_tax",
    tooltip: {
      show: true,
      title: "Taxes which are stored at header level will be non-editable, once selected.",
      placement: "top",
    },
    label: "Disable header level taxes field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.global?.apply_tax_on_line_and_store_header_level;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.payment.show_department",
    tooltip: {
      show: true,
      title: "Department field at the header level will be visible on vendor credit.",
      placement: "top",
    },
    label: "Show header level department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.is_tax_code_required_on_line_level",
    tooltip: {
      show: true,
      title: "If enable, the tax code field will become a mandatory requirement for vendor credit line level.",
      placement: "top",
    },
    label: "Tax Code Required on Line-level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.due_date_required",
    tooltip: {
      show: true,
      title: "Due Date will be required field at vendor credit form",
      placement: "top",
    },
    label: "Due Date Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.readonly_posting_period",
    tooltip: {
      show: true,
      title: "Posting period will be readonly field at vendor credit form",
      placement: "top",
    },
    label: "Posting period readonly",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user.company.has_posting_period;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.payment.department.is_required",
    tooltip: {
      show: true,
      title: "Department is required on vendor credit",
      placement: "top",
    },
    label: "Department Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.payment?.show_department;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.auto_apply_vendor_credits",
    tooltip: {
      show: true,
      title:
        "Automatically applies available Credit Memos to Invoices in a Payment Run, and allows access to the 'Apply Credit and Pay' module in the Pay Invoices tab.",
      placement: "top",
    },
    label: "Auto-apply Credit Memos in Payment Runs",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo_workflow_name_required",
    tooltip: {
      show: true,
      title: "Workflow Name is required",
      placement: "top",
    },
    label: " Workflow Name Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.lines_edit_mode",
    tooltip: {
      show: true,
      title: "Item, debit and credit lines will open in edit mode once enabled",
      placement: "top",
    },
    label: "Enable editing of lines in inbox, draft and for approval sections",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
];

export const vendorCreditSettingsItemLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.vendor_credit_item_location_required",
    tooltip: {
      show: true,
      title: "Item level location will be required field at vendor credit form",
      placement: "top",
    },
    label: "Item level location required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.items.project.is_required",
    tooltip: {
      show: true,
      title: "Item level project will be required field at vendor credit form",
      placement: "top",
    },
    label: "Project required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.show_employee_items_and_expenses",
    tooltip: {
      show: true,
      title: "Allow to show employee field on Credit Memo item and expense line level once selected.",
      placement: "top",
    },
    label: "Show employee on Credit Memo line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.items.department.is_required",
    tooltip: {
      show: true,
      title: "Department required on item line.",
      placement: "top",
    },
    label: "Department Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
];

export const vendorCreditSettingsExpenseLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.vendor_credit_expense_location_required",
    tooltip: {
      show: true,
      title: "Expense level location will be required field at vendor credit form",
      placement: "top",
    },
    label: "Location required for credit accounts",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.expenses.project.is_required",
    tooltip: {
      show: true,
      title: "Expense level project will be required field at vendor credit form",
      placement: "top",
    },
    label: "Project required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.expenses.show_item_list",
    tooltip: {
      show: true,
      title: "Show Items list on expense line.",
      placement: "top",
    },
    label: "Show Items list on expense line.",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.expenses.department.is_required",
    tooltip: {
      show: true,
      title: "Department required on expense line.",
      placement: "top",
    },
    label: "Department required for credit accounts",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
];
export const vendorCreditSettingsDebitLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.credit_memo.hide_debit_account",
    tooltip: {
      show: true,
      title:
        "Hide Debit account and it will inherit debit account from vendor or subsidiary or company setting and other data will be inherit from first item line or account line.",
      placement: "top",
    },
    label: "Hide debit account",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.credit_memo.debit_line.inherit_header_location_to_debit_line", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.credit_memo.debit_line.inherit_header_location_to_debit_line",
    tooltip: {
      show: true,
      title: "Inherit header level location field to the debit line level on the vendor credit form",
      placement: "top",
    },
    label: "Inherit Location from header to debit line",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default?.credit_memo?.hide_debit_account;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
];
export const vendorCreditSettingApproval: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.credit_memo.disable_rejection_for_cm_approvals",
    tooltip: {
      show: true,
      title: i18n.t("companySettings.cmSettings.disableCmApprovalRejectionTooltip"),
      placement: "top",
    },
    label: i18n.t("companySettings.cmSettings.disableCmApprovalRejection"),
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
];
