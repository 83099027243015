import { stopEventPropagation } from "components/admin/invoices/uploadQueue/uploadQueueService";
import React, { memo, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaAngleLeft,
  FaAngleRight,
  FaArrowsLeftRightToLine,
  FaDownload,
  FaMinus,
  FaPlus,
  FaPrint,
  FaRotateLeft,
  FaRotateRight,
} from "react-icons/fa6";
import { Document, Page, pdfjs } from "react-pdf";
import styles from "./pdfPreviewViewer.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: `//unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

const zeroXyPoint = { x: 0, y: 0 };

type TPdfPreviewViewerProps = {
  file: File | undefined;
  printCallback?: (event: any) => void;
  downloadCallback?: (event: any) => void;
  numPages: number | undefined;
  onDocumentLoadSuccess: (params: { numPages: number }) => void;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  onPageLoadSuccess: () => void;
  spinnerElement?: JSX.Element;
  initialSize?: number;
  increaseSize?: number;
  minScale?: number;
  maxScale?: number;
  isSubmiting: boolean;
};

const PdfPreviewViewer: React.FC<TPdfPreviewViewerProps> = ({
  file,
  printCallback,
  downloadCallback,
  numPages,
  onDocumentLoadSuccess,
  pageNumber,
  setPageNumber,
  onPageLoadSuccess,
  spinnerElement,
  initialSize = 1,
  increaseSize = 0.05,
  minScale = 0.4,
  maxScale = 3,
  isSubmiting,
}) => {
  const { t } = useTranslation();
  const [scale, setScale] = useState(initialSize);
  const [scaleInput, setScaleInput] = useState(Math.round(initialSize * 100).toString());
  const [isScaleInputInvalid, setIsScaleInputInvalid] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [showToolbar, setShowToolbar] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState(zeroXyPoint);
  const [dragOffset, setDragOffset] = useState(zeroXyPoint);
  const dragRef = useRef<HTMLDivElement>(null);

  const reset = useCallback(() => {
    setRotation(0);
    setScale(initialSize);
    setDragStart(zeroXyPoint);
    setDragOffset(zeroXyPoint);
  }, [initialSize]);

  useEffect(() => {
    setShowToolbar(false);
    reset();
  }, [file, initialSize, reset]);

  useEffect(() => {
    setScaleInput(Math.round(scale * 100).toString());
    setIsScaleInputInvalid(false);
  }, [scale]);

  const spinner = useMemo(() => {
    return spinnerElement ?? <></>;
  }, [spinnerElement]);

  const zoomIn = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      stopEventPropagation(event);
      setDragStart(zeroXyPoint);
      setDragOffset(zeroXyPoint);
      setScale((prevScale) => Math.min(prevScale + increaseSize, maxScale));
    },
    [increaseSize, maxScale],
  );

  const zoomOut = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      stopEventPropagation(event);
      setDragStart(zeroXyPoint);
      setDragOffset(zeroXyPoint);
      setScale((prevScale) => Math.max(prevScale - increaseSize, minScale));
    },
    [increaseSize, minScale],
  );

  const rotatePDF = useCallback((event: MouseEvent<HTMLButtonElement>, offset: number) => {
    stopEventPropagation(event);
    setRotation((prevRotation) => (prevRotation + offset) % 360);
  }, []);

  const rotatePDFRight = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      rotatePDF(event, 90);
    },
    [rotatePDF],
  );

  const rotatePDFLeft = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      rotatePDF(event, -90);
    },
    [rotatePDF],
  );

  const fitWindow = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      stopEventPropagation(event);
      reset();
    },
    [reset],
  );

  const goToPreviousPage = useCallback(
    (event: any) => {
      stopEventPropagation(event);
      if (!numPages) return;
      if (pageNumber <= 1) return;
      setPageNumber(pageNumber - 1);
    },
    [numPages, pageNumber, setPageNumber],
  );

  const goToNextPage = useCallback(
    (event: any) => {
      stopEventPropagation(event);
      if (!numPages) return;
      if (pageNumber >= numPages) return;
      setPageNumber(pageNumber + 1);
    },
    [numPages, pageNumber, setPageNumber],
  );

  const onPageLoadSuccessHandler = useCallback(() => {
    setShowToolbar(true);
    onPageLoadSuccess();
  }, [onPageLoadSuccess]);

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      setIsDragging(true);
      setDragStart({ x: event.clientX - dragOffset.x, y: event.clientY - dragOffset.y });
    },
    [dragOffset],
  );

  const onMouseMove = useCallback(
    (event: React.MouseEvent) => {
      if (isDragging) {
        setDragOffset({ x: event.clientX - dragStart.x, y: event.clientY - dragStart.y });
      }
    },
    [isDragging, dragStart],
  );

  const onMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (dragRef.current) {
      const dragContainer = dragRef.current;
      dragContainer.addEventListener("mousedown", onMouseDown as any);
      dragContainer.addEventListener("mousemove", onMouseMove as any);
      dragContainer.addEventListener("mouseup", onMouseUp);
      dragContainer.addEventListener("mouseleave", onMouseUp);

      return () => {
        dragContainer.removeEventListener("mousedown", onMouseDown as any);
        dragContainer.removeEventListener("mousemove", onMouseMove as any);
        dragContainer.removeEventListener("mouseup", onMouseUp);
        dragContainer.removeEventListener("mouseleave", onMouseUp);
      };
    }
  }, [isDragging, dragStart, onMouseDown, onMouseMove]);

  const onScaleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setScaleInput(value);
      setIsScaleInputInvalid(false);
    }
  }, []);

  const onScaleInputBlur = useCallback(() => {
    if (scaleInput === "") {
      setIsScaleInputInvalid(true);
      return;
    }
    const inputValue = parseInt(scaleInput, 10);
    if (isNaN(inputValue)) {
      setIsScaleInputInvalid(true);
    } else {
      const newScale = inputValue / 100;
      if (newScale < minScale || newScale > maxScale) {
        setIsScaleInputInvalid(true);
      } else {
        setIsScaleInputInvalid(false);
        setScale(newScale);
      }
    }
  }, [scaleInput, minScale, maxScale]);

  const onScaleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onScaleInputBlur();
      }
    },
    [onScaleInputBlur],
  );

  const toolbar = useMemo(() => {
    return (
      showToolbar &&
      file && (
        <div className={styles.toolbar}>
          <div className={styles.leftGroup}>
            <button
              className={styles.toolbarButton}
              onClick={goToPreviousPage}
              aria-label={t("components.common.pdfPreview.toolbar.previousPage")}
              title={t("components.common.pdfPreview.toolbar.previousPage")}
              disabled={pageNumber <= 1}
            >
              <FaAngleLeft />
            </button>
            <span className={styles.pagesDisplay}>
              {t("components.common.pdfPreview.toolbar.page")} {pageNumber} / {numPages}
            </span>
            <button
              className={styles.toolbarButton}
              onClick={goToNextPage}
              aria-label={t("components.common.pdfPreview.toolbar.nextPage")}
              title={t("components.common.pdfPreview.toolbar.nextPage")}
              disabled={!!numPages && pageNumber >= numPages}
            >
              <FaAngleRight />
            </button>
          </div>
          <div className={styles.centerGroup}>
            <button
              onClick={zoomOut}
              className={styles.toolbarButton}
              aria-label={t("components.common.pdfPreview.toolbar.zoomOut")}
              disabled={scale <= minScale}
              title={t("components.common.pdfPreview.toolbar.zoomOut")}
            >
              <FaMinus />
            </button>
            <div className={styles.scaleInputContainer}>
              <input
                type="text"
                className={`${styles.scaleDisplay} ${isScaleInputInvalid ? styles.invalidInput : ""}`}
                value={scaleInput}
                onChange={onScaleInputChange}
                onBlur={onScaleInputBlur}
                onKeyDown={onScaleInputKeyDown}
              />
              <span className={styles.percentSymbol}>%</span>
            </div>
            <button
              onClick={zoomIn}
              className={styles.toolbarButton}
              aria-label={t("components.common.pdfPreview.toolbar.zoomIn")}
              disabled={scale >= maxScale}
              title={t("components.common.pdfPreview.toolbar.zoomIn")}
            >
              <FaPlus />
            </button>
            <span className={styles.separator}></span>
            <button
              className={styles.toolbarButton}
              onClick={fitWindow}
              aria-label={t("components.common.pdfPreview.toolbar.fitWindow")}
              title={t("components.common.pdfPreview.toolbar.fitWindow")}
            >
              <FaArrowsLeftRightToLine />
            </button>
            <button
              className={styles.toolbarButton}
              onClick={rotatePDFLeft}
              title={t("components.common.pdfPreview.toolbar.rotateLeft")}
              aria-label={t("components.common.pdfPreview.toolbar.rotateLeft")}
            >
              <FaRotateLeft />
            </button>
            <button
              className={styles.toolbarButton}
              onClick={rotatePDFRight}
              title={t("components.common.pdfPreview.toolbar.rotateRight")}
              aria-label={t("components.common.pdfPreview.toolbar.rotateRight")}
            >
              <FaRotateRight />
            </button>
          </div>
          <div className={styles.rightGroup}>
            {printCallback && (
              <button
                className={styles.toolbarButton}
                onClick={printCallback}
                title={t("components.common.pdfPreview.toolbar.print")}
                aria-label={t("components.common.pdfPreview.toolbar.print")}
              >
                <FaPrint />
              </button>
            )}
            {downloadCallback && (
              <button
                className={styles.toolbarButton}
                onClick={downloadCallback}
                title={t("components.common.pdfPreview.toolbar.download")}
                aria-label={t("components.common.pdfPreview.toolbar.download")}
              >
                <FaDownload />
              </button>
            )}
          </div>
        </div>
      )
    );
  }, [
    downloadCallback,
    file,
    fitWindow,
    goToNextPage,
    goToPreviousPage,
    isScaleInputInvalid,
    maxScale,
    minScale,
    numPages,
    onScaleInputBlur,
    onScaleInputChange,
    onScaleInputKeyDown,
    pageNumber,
    printCallback,
    rotatePDFLeft,
    rotatePDFRight,
    scale,
    scaleInput,
    showToolbar,
    t,
    zoomIn,
    zoomOut,
  ]);

  const componentMainClassName = isSubmiting ? `${styles.pdfViewer} ${styles.blurred}` : styles.pdfViewer;

  return (
    <div>
      {toolbar}
      <div className={styles.documentContainer}>
        <div
          className={styles.documentDraggable}
          ref={dragRef}
          style={{ transform: `translate(${dragOffset.x}px, ${dragOffset.y}px)` }}
        >
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            loading={spinner}
            className={`${componentMainClassName} ${styles.document}`}
            error={<></>}
            noData={<></>}
          >
            <Page
              scale={scale}
              onLoadSuccess={onPageLoadSuccessHandler}
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              loading={spinner}
              error={<></>}
              noData={<></>}
              className={styles.page}
              rotate={rotation}
            />
          </Document>
        </div>
      </div>
    </div>
  );
};

export default memo(PdfPreviewViewer);
