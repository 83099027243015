import React from "react";
import { InjectedFormProps, reduxForm, submit } from "redux-form";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { MANAGE_PAYMENT_METHOD_FORM, validateSortCode } from "../../../services/common/paymentMethod.svc";
import AdminPaymentFormTemplate from "./templates/adminPaymentFormTemplate";
import { ManagePaymentMethodFormDataType } from "../../common/managePaymentMethod/types";

interface ManagePaymentMethodReduxFormPropsType extends InjectedFormProps<ManagePaymentMethodFormDataType> {}

const validate = (values: any) => {
  const errors = {
    paymentConfirmAccountNumber: "",
  };
  // add from errors
  if (!values.id) {
    if (values.account_number !== values.paymentConfirmAccountNumber) {
      errors.paymentConfirmAccountNumber = "Confirm account number should match";
    }
  }
  return errors;
};

const asyncValidate = async (values: any) => {
  if (values?.tax_account_number) {
    try {
      const response = await validateSortCode(values.sort_code);
    } catch (error: any) {
      console.log(error);
      throw { sort_code: "sortCode has not passed validation." };
    }
  }
};

const ManagePaymentForm = () => {
  return (
    <>
      <AdminPaymentFormTemplate />
    </>
  );
};

const ManagePaymentReduxForm = reduxForm<ManagePaymentMethodFormDataType>({
  form: MANAGE_PAYMENT_METHOD_FORM,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
  validate: validate,
  asyncValidate: asyncValidate,
  asyncChangeFields: ["sort_code"],
})(ManagePaymentForm);

// const ManagePaymentReduxFormConnected = connect((state: RootState, props) => ({
// initialValues: state.paymentMethodRed
// }))(ManagePaymentReduxForm);

export default ManagePaymentReduxForm;

export const RemoteSubmitManagePaymentMethod = () => {
  const dispatch = useDispatch();
  const submitForm = () => {
    dispatch(submit(MANAGE_PAYMENT_METHOD_FORM));
  };
  return <Button onClick={submitForm}>Submit</Button>;
};
