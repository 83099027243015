import React, { useCallback, useState, useEffect } from "react";
import Style from "./workFlow.module.css";
import {
  setActiveStepIndex,
  setActiveStaticWorkFlow,
  setStaticTiers,
  setActiveTierIndex,
} from "../../reducers/approvalSettingsReducers";
import { useDispatch, useSelector } from "react-redux";
import { destroy, initialize } from "redux-form";
import _ from "lodash";
import { Link } from "react-router-dom";

const DragAndDropStaticWorkflows = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.approvalSettingsReducer);

  const onChangeActiveStaticWorkflow = useCallback((name, step) => {
    dispatch(setActiveStepIndex(step));
    dispatch(setActiveTierIndex(0));
    dispatch(setActiveStaticWorkFlow(name, true));
    dispatch(setStaticTiers(state.staticWorkFlows.filter((a) => a.workflow_name == name.workflow_name)));
  });

  const onActiveChange = (item, step) => {
    dispatch(destroy("workFlowApprovalForm"));
    dispatch(destroy("testTriggerForm"));
    setTimeout(() => {
      dispatch(
        initialize(
          "workFlowApprovalForm",
          state.staticWorkFlows.find((x) => x.workflow_name == item.workflow_name),
        ),
      );
      dispatch(
        initialize(
          "testTriggerForm",
          state.staticWorkFlows.find((x) => x.workflow_name == item.workflow_name),
        ),
      );
    }, 300);
    onChangeActiveStaticWorkflow(item, step);
  };

  const getFirstTier = (item) => {
    const tiers = state.staticWorkFlows.filter((workFlow) => workFlow.workflow_name == item.workflow_name);
    return tiers.length > 0 ? tiers[0] : null;
  };

  return (
    <div>
      {state.staticWorkFlowNames?.length > 0 &&
        state.staticWorkFlows?.length > 0 &&
        state.staticWorkFlowNames.map((item, index) => (
          <div
            key={index}
            draggable={state.isLastStepSaved ? true : false}
            className={`
            ${item.status === "INACTIVE" ? Style.inactiveWorkflow : ""}
            ${Style.workflowStep} d-flex align-content-center  ${
              state.activeStepIndex === index ? Style.activeWorkFlowStep : null
            } `}
            onClick={() => onActiveChange(item, index)}
          >
            <div
              className={`${Style.staticWorkflowHandle} py-4`}
              style={{
                visibility: state.activeStepIndex === index ? "visible" : "hidden",
              }}
            ></div>

            <div className={Style.gridContainer}>
              <div className="font-weight-bold m-3"></div>
              {state.staticTiers && state.staticTiers.length ? (
                <Link to={`/ap/static_workflows/${getFirstTier(item) ? getFirstTier(item).id : "new_approval"}`}>
                  <div className="m-3">{item.workflow_name_label}</div>
                </Link>
              ) : null}
            </div>
          </div>
        ))}
    </div>
  );
};

export default DragAndDropStaticWorkflows;
