import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Panel from "../../../../components/panel/panel";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import styles from "./companyPaymentMethod.module.css";
import { paymentTypes } from "../../../../components/app.svc.Lookup";
import ErrorBoundary from "../../../../components/common/errorBoundary/errorBoundary";
import RestApi from "../../../../providers/restApi";
import useConfirmModal from "../../../../components/modals/confirmModal/useConfirmModalHook";
import useCompanyPaymentMethod from "../../../../components/admin/companyPaymentMethod/useCompanyPaymentMethod";
import usePermission from "../../../../components/common/hooks/usePermission";
import { isAccountPayout, isCheckPayout, isInternationalType } from "../../../../services/common/paymentMethod.svc";
import SettingsTabs from "../settingsTabs";
import settingsStyles from "./../settings.module.css";
import { Link } from "react-router-dom";
import ObjectStatus from "../../../../components/common/objects/newStatusObject";

const restApiService = new RestApi();

const showPaymentTypeLabel = (paymentType) => {
  return paymentTypes.find((pt) => pt.value === paymentType)?.label;
};

const isPaymentTypeExist = (paymentType) => {
  return paymentTypes.find((pt) => pt.value === paymentType) ? true : false;
};

// these function will iterator through all payment_method
// and return only related payment_method
// as there is three payment payout type
// account_payout, check_payout, internaltional_payout
const getPrimaryPaymentmethod = ({ paymentMethod, paymentMethods }) => {
  if (isAccountPayout(paymentMethod)) {
    const updatetablePaymentMethod = paymentMethods.find(
      (pm) => isAccountPayout(pm) && pm.is_primary && pm.id !== paymentMethod.id,
    );
    return { updatetablePaymentMethod };
  } else if (isCheckPayout(paymentMethod)) {
    const updatetablePaymentMethod = paymentMethods.find(
      (pm) => isCheckPayout(pm) && pm.is_primary && pm.id !== paymentMethod.id,
    );
    return { updatetablePaymentMethod };
  } else if (isInternationalType(paymentMethod)) {
    const updatetablePaymentMethod = paymentMethods.find(
      (pm) => isInternationalType(pm) && pm.is_primary && pm.id !== paymentMethod.id,
    );
    return { updatetablePaymentMethod };
  }
};

const PaymentTableRow = ({ paymentMethod, setToPrimaryPaymentMethod, editPaymentMethod, deletePaymentMethod }) => {
  return (
    <tr key={paymentMethod.id}>
      <td>{paymentMethod.id}</td>
      <td>{paymentMethod.payment_type ? showPaymentTypeLabel(paymentMethod.payment_type) : null}</td>
      <td>{paymentMethod.label ? paymentMethod.label : null}</td>
      <td>{paymentMethod.bank_name ? paymentMethod.bank_name : null}</td>
      <td>
        {paymentMethod.billing_first_name ? paymentMethod.billing_first_name : null}{" "}
        {paymentMethod.billing_last_name ? paymentMethod.billing_last_name : null}
      </td>
      <td>{paymentMethod.account_number ? paymentMethod.account_number : null}</td>
      <td>
        <span className={styles.accountHolder}>{paymentMethod.account_name ? paymentMethod.account_name : null}</span>
      </td>
      <td className={styles.currencyCode}>
        <span className={styles.currencyCode}>{paymentMethod.currency_code ? paymentMethod.currency_code : null}</span>
      </td>
      <td>
        <ObjectStatus status={paymentMethod.status} />
      </td>
      <td className="h-100 w-100 ">
        <span className="d-flex justify-content-center align-items-center">
          {paymentMethod.is_primary ? (
            <i role={"button"} className="ml-2 icon icon-star-active icon-button" />
          ) : (
            <i
              role={"button"}
              className="ml-2 icon icon-star"
              onClick={() => setToPrimaryPaymentMethod(paymentMethod)}
            />
          )}
          {isPaymentTypeExist(paymentMethod?.payment_type) && (
            <i role={"button"} className="ml-2 icon icon-edit " onClick={() => editPaymentMethod(paymentMethod.id)} />
          )}
        </span>
      </td>
    </tr>
  );
};

/**Function component Started here */
const CompanyPaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { createConfirmModal } = useConfirmModal();
  const { hasUserPermission } = usePermission();
  const { t } = useTranslation();

  const accountPayouts = paymentMethods.filter((pm) => isAccountPayout(pm));
  const checkPayouts = paymentMethods.filter((pm) => isCheckPayout(pm));
  const internationalPayouts = paymentMethods.filter((pm) => isInternationalType(pm));
  const virtualCardPayouts = paymentMethods.filter((pm) => pm.payment_type == "credit_card");

  const fetch = useCallback(async () => {
    try {
      const response = await restApiService.get("payment_methods");
      if (response.data) {
        setPaymentMethods(response.data);
      }
    } catch (error) {}
  }, [setPaymentMethods]);

  useEffect(() => {
    //Redirect user if they do not have the right permissions. This stops users from accessing this page by directly going to the '.../ap/settings/payment_methods'
    if (!hasUserPermission("listPaymentMethods") && !hasUserPermission("companyPaymentMethod")) {
      window.location.replace(restApiService.angularBaseURL() + "dashboard");
    }
    fetch();
  }, []);

  const updatePaymentMethod = (paymentMethod) => {
    setPaymentMethods((prevState) =>
      prevState.map((pm) =>
        pm.id === paymentMethod.id ? { ...pm, is_primary: paymentMethod.is_primary, ...paymentMethod } : pm,
      ),
    );
  };

  const addEditSaveCallBack = ({ isPrimarySet, paymentMethod }) => {
    // check if payment method is created or updated
    if (paymentMethods.find((pm) => pm.id === paymentMethod.id)) {
      // if updated updated local state for payamentMethod
      updatePaymentMethod(paymentMethod);
    } else {
      // if created, append new to paymentMethods
      setPaymentMethods((prevState) => [...prevState, paymentMethod]);
    }

    // if is_primary flog for payment method is set true also update other payment Methods is_primary field
    if (isPrimarySet) {
      updateOtherPaymentMethodPrimaryFlag(paymentMethod);
    }
  };

  const { addPaymentMethod, companyPaymentMethod, editPaymentMethod } = useCompanyPaymentMethod({
    saveEditCallBack: addEditSaveCallBack,
  });

  const deleteConfirmCallBack = useCallback(async (paymentMethod) => {
    try {
      const response = await restApiService.delete(`payment_methods/${paymentMethod.id}`);
      if (response) {
        CreateNotification(
          t("success"),
          t("companySettings.paymentMethodsSettings.delete", {
            id: paymentMethod.id,
          }),
          NotificationType.success,
        );
        setPaymentMethods((prevState) => prevState.filter((pm) => pm.id !== paymentMethod.id));
      }
    } catch (error) {}
  }, []);

  const deletePaymentMethod = useCallback(async (paymentMethod) => {
    createConfirmModal({
      title: t("companySettings.paymentMethodsSettings.delete"),
      body: t("companySettings.paymentMethodsSettings.deleteConfirm", {
        id: paymentMethod.id,
      }),
      callBackData: paymentMethod,
      saveCallBack: deleteConfirmCallBack,
    });
  }, []);

  const setToPrimaryPaymentMethod = (paymentMethod) => {
    createConfirmModal({
      title: t("companySettings.paymentMethodsSettings.primaryPaymentMethod"),
      body: t("companySettings.paymentMethodsSettings.setPrimaryPaymentMethodConfirm", {
        id: paymentMethod.id,
      }),
      callBackData: paymentMethod,
      saveCallBack: setToPrimaryPaymentMethodCallBack,
    });
  };

  const setToPrimaryPaymentMethodCallBack = async (paymentMethod) => {
    try {
      const response = await restApiService.patch(`payment_methods/${paymentMethod.id}`, null, {
        payment_method: { id: paymentMethod.id, ...paymentMethod, is_primary: true },
      });
      updatePaymentMethod(response.data);
      updateOtherPaymentMethodPrimaryFlag(response.data);
    } catch (error) {}
  };

  const updateOtherPaymentMethodPrimaryFlag = async (paymentMethod) => {
    try {
      // Note: getPrimaryPaymentmethod return array of all payment method where it have updated is_primary for related payment methods
      const { updatetablePaymentMethod } = getPrimaryPaymentmethod({
        paymentMethod,
        paymentMethods,
      });
      if (updatetablePaymentMethod) {
        const response = await restApiService.patch(`payment_methods/${updatetablePaymentMethod.id}`, null, {
          payment_method: {
            id: updatetablePaymentMethod.id,
            ...updatetablePaymentMethod,
            is_primary: false,
          },
        });
        updatePaymentMethod(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container fluid={true}>
        <Row className={settingsStyles.navBarMargin}>
          <SettingsTabs
            breadcrumb={
              <span className={settingsStyles.landingHeader}>
                <Link to={"/ap/settings"}> Settings</Link>
              </span>
            }
          />
        </Row>
        {companyPaymentMethod}
        <ErrorBoundary>
          <Row className="row">
            <Col lg="12" className="mt-3">
              <Panel>
                <Card.Header
                  className={
                    styles.cardHeader + " collapseHeader d-flex justify-content-center align-items-center pt-0 pb-2"
                  }
                >
                  <p className={"mb-0 align-self-center mr-auto fw-800"}>
                    <i className="icon icon-bank"></i>{" "}
                    {t("companySettings.paymentMethodsSettings.paymentMethods").toUpperCase()}
                  </p>

                  <Button
                    variant="secondary"
                    className="d-flex justify-content-center align-items-center font-weight-bold"
                    onClick={() => addPaymentMethod()}
                  >
                    <i className="icon icon-plus-black"></i> {t("companySettings.paymentMethodsSettings.paymentMethod")}
                  </Button>
                </Card.Header>
                <div className={styles.tableWrapper}>
                  <table className={styles.table + " " + styles.tableStriped + " " + styles.fixed}>
                    <thead>
                      <tr>
                        <th>{t("companySettings.paymentMethodsSettings.id")}</th>
                        <th>{t("companySettings.paymentMethodsSettings.paymentType")}</th>
                        <th>{t("companySettings.paymentMethodsSettings.label")}</th>
                        <th>{t("companySettings.paymentMethodsSettings.bankName")}</th>
                        <th>{t("companySettings.paymentMethodsSettings.contactName")}</th>
                        <th>{t("companySettings.paymentMethodsSettings.account")}</th>
                        <th>{t("companySettings.paymentMethodsSettings.accountHolder")}</th>
                        <th>{t("companySettings.paymentMethodsSettings.currency")}</th>
                        <th>{t("companySettings.paymentMethodsSettings.status")}</th>
                        <th>{t("companySettings.paymentMethodsSettings.actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.isArray(accountPayouts) && accountPayouts.length > 0 && (
                        <tr>
                          <td className={`py-3 ${styles.subSection}`} colSpan="8">
                            {t("companySettings.paymentMethodsSettings.accountPayouts")}
                          </td>
                        </tr>
                      )}
                      {_.isArray(accountPayouts) &&
                        _.orderBy(accountPayouts, ["id"], ["desc"]).map((paymentMethod) => (
                          <PaymentTableRow
                            key={paymentMethod.id}
                            paymentMethod={paymentMethod}
                            deletePaymentMethod={deletePaymentMethod}
                            editPaymentMethod={editPaymentMethod}
                            setToPrimaryPaymentMethod={setToPrimaryPaymentMethod}
                          />
                        ))}
                      {_.isArray(checkPayouts) && checkPayouts.length > 0 && (
                        <tr>
                          <td className={`py-3 ${styles.subSection}`} colSpan="8">
                            {t("companySettings.paymentMethodsSettings.checkPayouts")}
                          </td>
                        </tr>
                      )}
                      {_.isArray(checkPayouts) &&
                        _.orderBy(checkPayouts, ["id"], ["desc"]).map((paymentMethod) => (
                          <PaymentTableRow
                            key={paymentMethod.id}
                            paymentMethod={paymentMethod}
                            deletePaymentMethod={deletePaymentMethod}
                            editPaymentMethod={editPaymentMethod}
                            setToPrimaryPaymentMethod={setToPrimaryPaymentMethod}
                          />
                        ))}
                      {_.isArray(virtualCardPayouts) && virtualCardPayouts.length > 0 && (
                        <tr>
                          <td className={`py-3 ${styles.subSection}`} colSpan="8">
                            {t("companySettings.paymentMethodsSettings.virtualCardPayouts")}
                          </td>
                        </tr>
                      )}
                      {_.isArray(virtualCardPayouts) &&
                        _.orderBy(virtualCardPayouts, ["id"], ["desc"]).map((paymentMethod) => (
                          <PaymentTableRow
                            key={paymentMethod.id}
                            paymentMethod={paymentMethod}
                            deletePaymentMethod={deletePaymentMethod}
                            editPaymentMethod={editPaymentMethod}
                            setToPrimaryPaymentMethod={setToPrimaryPaymentMethod}
                          />
                        ))}
                      {_.isArray(internationalPayouts) && internationalPayouts.length > 0 && (
                        <tr>
                          <td className={`py-3 ${styles.subSection}`} colSpan="8">
                            {t("companySettings.paymentMethodsSettings.internationalPayouts")}
                          </td>
                        </tr>
                      )}
                      {_.isArray(internationalPayouts) &&
                        _.orderBy(internationalPayouts, ["id"], ["desc"]).map((paymentMethod) => (
                          <PaymentTableRow
                            key={paymentMethod.id}
                            paymentMethod={paymentMethod}
                            deletePaymentMethod={deletePaymentMethod}
                            editPaymentMethod={editPaymentMethod}
                            setToPrimaryPaymentMethod={setToPrimaryPaymentMethod}
                          />
                        ))}
                      {_.isArray(paymentMethods) && paymentMethods.length < 1 && (
                        <tr>
                          <td colSpan="8">{t("companySettings.paymentMethodsSettings.noPaymentMethods")}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Panel>
            </Col>
          </Row>
        </ErrorBoundary>
      </Container>
    </>
  );
};

export default CompanyPaymentMethods;
