import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { getListOptionsType } from "services/common/types/common.type";
import { extractFileFromURI } from "services/general/helpers";
import { WombatFormType, WombatType } from "wombatifier/services/wombat.types";

export class WombatApis {
  static ENDPOINT = "wombats";
  static async list({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<WombatType[]> = await restApiService.get(
        this.ENDPOINT,
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async inbounds({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<WombatType[]> = await restApiService.get(
        `${this.ENDPOINT}/inbounds`,
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async outbounds({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<WombatType[]> = await restApiService.get(
        `${this.ENDPOINT}/outbounds`,
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async inboundsSchema({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<string[]> = await restApiService.get(
        `${this.ENDPOINT}/inbounds_schema`,
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async outboundsSchema({ filter, cache = true }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<string[]> = await restApiService.get(
        `${this.ENDPOINT}/outbounds_schema`,
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async inboundDestinationTypes({ filter, cache = true }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<string[]> = await restApiService.get(
        `${this.ENDPOINT}/inbound_destination_types`,
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async outboundDestinationTypes({ filter, cache = true }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<string[]> = await restApiService.get(
        `${this.ENDPOINT}/outbound_destination_types`,
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async get(id: string) {
    try {
      const response: AxiosResponse<WombatType> = await restApiService.get(`${this.ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async duplicate(id: string) {
    try {
      const response: AxiosResponse<WombatType> = await restApiService.post(`${this.ENDPOINT}/${id}/duplicate`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async csvify_xml(tempFileUri: string): Promise<string[][] | undefined> {
    try {
      let formData = new FormData();
      const file = await extractFileFromURI(tempFileUri);
      formData.append(`wombat[asset_attributes][asset_file]`, file);
      const res = await restApiService.post(`${this.ENDPOINT}/csvify_xml`, null, formData);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async delete(id: number) {
    try {
      const response: AxiosResponse<WombatType> = await restApiService.delete(`${this.ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async create(payload: WombatFormType) {
    try {
      const response: AxiosResponse<WombatType> = await restApiService.post(this.ENDPOINT, null, { wombat: payload });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async patch(id: string, payload: WombatFormType) {
    try {
      const response: AxiosResponse<WombatType> = await restApiService.patch(`${this.ENDPOINT}/${id}`, null, {
        wombat: payload,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async wombatify(payload: WombatFormType, wombatifyRows: any[]) {
    try {
      const response: AxiosResponse<any[]> = await restApiService.post(`${this.ENDPOINT}/wombatify`, null, {
        wombat: { ...payload, data: wombatifyRows },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
