import React, { useState, useEffect } from "react";
import InputDropdownFilter from "./inputDropdown";
import { restApiService } from "providers/restApi";
import { AxiosResponse } from "axios";
import { Spinner } from "react-bootstrap";

const CategoriesOptionsFilter = ({
  name,
  code,
  options,
  isRequired,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  isRequired?: boolean;
}) => {
  const [categoryOptions, setCategoryOptions] = useState<{ label: string; value: string }[]>([
    { label: "ALL", value: "" },
  ]);

  const loadCategoriesOptions = async () => {
    try {
      const response: AxiosResponse<any> = await restApiService.get("categories");
      const categories: { label: string; value: string }[] = [];
      categories.push({ label: "ALL", value: "" });
      response.data.forEach((category: { name: string; id: string }) => {
        categories.push({ label: category.name, value: category.id });
      });
      setCategoryOptions(categories);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    loadCategoriesOptions();
  }, []);

  const renderDropdown = () => {
    if (categoryOptions.length > 1) {
      return <InputDropdownFilter name={name} code={code} options={categoryOptions} isRequired={isRequired} />;
    } else {
      return (
        <div>
          <h5>Loading Categories Filter...</h5>
          <Spinner animation="border" variant="info"></Spinner>
        </div>
      );
    }
  };

  return <>{renderDropdown()}</>;
};

export default CategoriesOptionsFilter;
