import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Styles from "../../../../pages/admin/purchaseOrders/changeOrders/purchaseOrders.module.css";

const { bgPositionCenter, maxWidthExpenseIcons, maxWidthPoItemIcons } = Styles;

interface FieldItemIconsPropsType {
  isDestroyed: boolean | undefined;
  editMode: boolean;
  readOnly?: boolean;
  handleRecoverItem: () => void;
  handleEnableEdit: () => void;
  handleDeleteItem: () => void;
  handleDuplicateItem: () => void;
}
export const POFieldItemIcons = ({
  isDestroyed,
  editMode,
  handleRecoverItem,
  handleEnableEdit,
  handleDeleteItem,
  handleDuplicateItem,
  readOnly,
}: FieldItemIconsPropsType) => {
  return isDestroyed ? (
    <Col className={`${editMode ? "p-0" : "pt-3"} ${maxWidthPoItemIcons}`}>
      <Row className="m-0 justify-content-end pt-2">
        <Button disabled variant="outline-danger" className="py-1 px-2 font-weight-bold">
          Deleted
        </Button>
      </Row>
      <Row className="m-0 justify-content-end">
        <Button variant="outline-info" className="py-1 px-2 font-weight-bold" onClick={handleRecoverItem}>
          Undo
        </Button>
      </Row>
    </Col>
  ) : (
    <Col className={`${editMode ? "p-0" : "pt-3"} ${maxWidthPoItemIcons}`}>
      <Row className="m-0 justify-content-end">
        <i className={`icon editIcon ${bgPositionCenter}`} onClick={handleEnableEdit} />
        {!readOnly && <i className={`icon duplicate ${bgPositionCenter}`} onClick={handleDuplicateItem} />}
        {!readOnly && <i className={`icon delete ${bgPositionCenter}`} onClick={handleDeleteItem} />}
      </Row>
    </Col>
  );
};

export const ExpenseFieldItemIcons = ({
  isDestroyed,
  editMode,
  handleRecoverItem,
  handleEnableEdit,
  handleDeleteItem,
  handleDuplicateItem,
  readOnly,
}: FieldItemIconsPropsType) => {
  return isDestroyed ? (
    <Col className={`${maxWidthExpenseIcons} ${editMode ? "px-0" : ""}`}>
      <Row className={`m-0 justify-content-end ${!editMode ? "pt-3" : ""}`}>
        <Button disabled variant="outline-danger" className="p-0 font-weight-bold">
          Deleted
        </Button>
      </Row>
      <Row className="m-0 pt-2 justify-content-end">
        <Button variant="outline-info" className="py-1 px-2 font-weight-bold" onClick={handleRecoverItem}>
          Undo
        </Button>
      </Row>
    </Col>
  ) : (
    <Col className={`${maxWidthExpenseIcons} ${editMode ? "px-0" : ""}`}>
      <Row className={`m-0 justify-content-end ${!editMode ? "pt-3" : ""}`}>
        <i className={`icon editIcon ${bgPositionCenter}`} onClick={handleEnableEdit} />
        {!readOnly && <i className={`icon duplicate ${bgPositionCenter}`} onClick={handleDuplicateItem} />}
        {!readOnly && <i className={`icon delete ${bgPositionCenter}`} onClick={handleDeleteItem} />}
      </Row>
    </Col>
  );
};
