import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { renderField } from "../../../forms/fields";
import { VendorSearchType, VendorType } from "../../../../services/admin/vendors/vendorTypes";
import ErrorBoundary from "../../../common/errorBoundary/errorBoundary";
import VendorSearchResult from "./vendorSearchResult";
import { useTranslation } from "react-i18next";
import styles from "../vendors.module.css";
import { RenderField } from "components/forms/bootstrapFields";
import { email } from "services/validations/reduxFormValidation";
import { CompanyType } from "../../../../services/common/user/userTypes";
import { required } from "../../../../services/validations/reduxFormValidation";
type VendorSearcherType = {
  handleAddVendor: (master: any) => void;
  currentUserCompany?: CompanyType;
};

const VendorSearcher = ({ handleAddVendor, currentUserCompany }: VendorSearcherType) => {
  const { t } = useTranslation();

  const [callVendorSearch, setCallVendorSearch] = useState(false);
  const [vendorType, setVendorType] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<VendorSearchType>({
    name: null,
    ein: null,
    domain: null,
    is_purchaser: false,
  });

  const handleAddVendorCallBack = (master: VendorType) => {
    handleAddVendor(master);
  };

  useEffect(() => {
    // Check if at least one field has a value
    const hasValue = searchTerms.name !== null || searchTerms.ein !== null || searchTerms.domain !== null;
    if (hasValue) {
      const timer = setTimeout(() => {
        setCallVendorSearch(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setCallVendorSearch(false);
    }
  }, [searchTerms]);
  return (
    <form>
      <Row>
        <Col sm="12">
          <p className={styles.searchVendor}>{t("admin.pages.vendor.searchVendor")}</p>
          <p className={styles.searchVendorTagLine}>{t("admin.pages.vendor.searchVendorTagLine")}</p>
        </Col>
      </Row>
      <Row className="px-mb-10">
        {vendorType !== "EMPLOYEE" && (
          <Col sm="6">
            <Field
              name="name"
              component={RenderField}
              type="text"
              label={t("admin.pages.vendor.vendorName")}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setSearchTerms((prevProps) => ({ ...prevProps, name: event?.target?.value }))
              }
              className={`w-100`}
              FieldClassName={styles.formFontSize}
            />
          </Col>
        )}
        <Col sm="6">
          <label>{t("admin.pages.vendor.vendorType")}</label>
          <Field
            name="vendor_type"
            required
            className={`w-100`}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setVendorType(event?.target?.value)}
            component="select"
          >
            <option value="COMPANY">COMPANY</option>
            <option value="EMPLOYEE">EMPLOYEE</option>
            <option value="INTERNAL">INTERNAL</option>
          </Field>
        </Col>
      </Row>
      {vendorType !== "EMPLOYEE" && (
        <Row>
          <Col sm="6">
            <Field
              name="ein"
              component={RenderField}
              type="text"
              label={t("admin.pages.vendor.vatId")}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setSearchTerms((prevProps) => ({ ...prevProps, ein: event?.target?.value }))
              }
              className={`w-100`}
              FieldClassName={styles.formFontSize}
            />
          </Col>
          <Col sm="6">
            <Field
              name="domain"
              component={RenderField}
              type="text"
              label={t("admin.pages.vendor.email")}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setSearchTerms((prevProps) => ({ ...prevProps, domain: event?.target?.value }))
              }
              className={`w-100`}
              FieldClassName={styles.formFontSize}
              validate={[email]}
            />
          </Col>
        </Row>
      )}
      <ErrorBoundary>
        {vendorType !== "EMPLOYEE" && callVendorSearch && (
          <Row>
            <Col sm="12">
              <VendorSearchResult searchTerms={searchTerms} handleAddVendorCallBack={handleAddVendorCallBack} />
            </Col>
          </Row>
        )}
      </ErrorBoundary>
    </form>
  );
};

export default VendorSearcher;
