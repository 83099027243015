import React, { Component } from "react";
import { Button, Row, Col, Container, Table, Form, InputGroup, Navbar } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import RestApi from "../../../providers/restApi";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import EditForm from "./editForm";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { Field, formValueSelector, getFormValues } from "redux-form";
import { currency_codes } from "../../../components/app.svc.Lookup";
import { roundUpAmount } from "../../../services/vp/services/roundUpAmount";
import Loader from "../../../components/spinners/loader";

class draftExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      expense_item: {},
      employees: [],
      policies: [],
      policy_id: "",
      categories: [],
      currency_codes: [],
      taxes: [],
      pos: [],
      files: [],
      date: "",
      amount: null,
      total: null,
      description: "",
      vendor_name: "",
      item_type: "",
      per_diems: [],
      selectedPerDiem: {},
    };
    this.onDrop = this.parseUploadFiles;
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.restApiService
      .get("expense_items/" + this.props.match.params.id)
      .then((val) => {
        this.setState({
          loaded: true,
          expense_item: val.data,
          currency_codes: this.getPolicyCurrencyCode(val.data.policy),
        });
        if (val.data) {
          this.props.updateExpenseDetails(val.data);
          this.parseFilesFromAPI();
          if (this.isConversionAvailable()) {
            this.getCurrencyRate(val.data.base_currency_code);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.danger);
      });

    this.restApiService
      .get("contacts.lk", { by_active: true, contact_type: "COMPANY" })
      .then((result) => {
        if (result.data) {
          this.setState({
            employees: result.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.restApiService
      .get("policies.lk", { status: "ACTIVE" })
      .then((result) => {
        if (result.data) {
          this.setState({
            policies: result.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.ExpenseItem.policy_id != this.props.ExpenseItem.policy_id) {
      this.getCategoriesByPolicy();
      this.getTaxesByPolicy();
    }

    this.getEmployeeByPolicy(prevProps);

    if (this.props.ExpenseItem.tax_id !== prevProps.ExpenseItem.tax_id) {
      this.props.ExpenseItem.tax_code = this.state.taxes.find((tax) => tax.id == this.props.ExpenseItem.tax_id);
      this.calculateBaseCurrencyTax();
      this.calculateExchangeTax();
    }

    // Calculate tax while changing total or tax code
    if (
      (this.props.ExpenseItem.tax_id && this.props.ExpenseItem.tax_id !== prevProps.ExpenseItem.tax_id) ||
      (this.props.ExpenseItem.total && this.props.ExpenseItem.total !== prevProps.ExpenseItem.total)
    ) {
      this.calculateExpenseTax();
    }

    if (this.props.ExpenseItem.per_diem_id && prevProps.ExpenseItem.per_diem_id != this.props.ExpenseItem.per_diem_id) {
      this.updateCategory();
    }

    if (
      this.props.ExpenseItem.base_currency_tax &&
      this.props.ExpenseItem.base_currency_tax !== prevProps.ExpenseItem.base_currency_tax
    ) {
      this.calculateExchangeTax();
    }

    if (
      this.isConversionAvailable() &&
      this.props.ExpenseItem.base_currency_total &&
      this.props.ExpenseItem.base_currency_total != prevProps.ExpenseItem.base_currency_total
    ) {
      this.calculateExchangeTotal();
    }

    if (this.props.ExpenseItem.tax && this.props.ExpenseItem.tax != prevProps.ExpenseItem.tax) {
      this.calculateExpenseAmount();
    }

    if (
      this.props.ExpenseItem.item_type === "MILEAGE" &&
      (this.props.ExpenseItem.distance != prevProps.ExpenseItem.distance ||
        this.props.ExpenseItem.distance_unit != prevProps.ExpenseItem.distance_unit)
    ) {
      this.calculateDistanceAmount();
    }

    if (
      this.props.ExpenseItem.item_type === "PER_DIEM" &&
      this.props.ExpenseItem.policy_id != prevProps.ExpenseItem.policy_id
    ) {
      this.getPerDiemByPolicy();
    }
    if (
      this.props.ExpenseItem.item_type === "PER_DIEM" &&
      (this.props.ExpenseItem.per_diem_id != prevProps.ExpenseItem.per_diem_id ||
        this.props.ExpenseItem.qty != prevProps.ExpenseItem.qty)
    ) {
      this.calculatePerDiemAmount();
    }
  }

  updateCategory = () => {
    if (this.state.per_diems && this.state.per_diems.length > 0) {
      let selectedPerDiem = this.state.per_diems.find((perDiem) => perDiem.id == this.props.ExpenseItem.per_diem_id);
      if (selectedPerDiem) {
        this.setState({
          selectedPerDiem: selectedPerDiem,
        });
        this.props.updateExpenseDetails({ ...this.props.ExpenseItem, category_id: selectedPerDiem.category_id });
      }
    }
  };

  isConversionAvailable = () => {
    return (
      this.props.ExpenseItem && this.props.ExpenseItem.policy && this.props.ExpenseItem.policy.convert_to_base_currency
    );
  };

  calculateExchangeTax = () => {
    if (this.isConversionAvailable() && this.props.ExpenseItem.currency_exchange) {
      this.props.ExpenseItem.tax = roundUpAmount(
        this.props.ExpenseItem.base_currency_tax * this.props.ExpenseItem.currency_exchange.rate,
        2,
      );
      this.props.ExpenseItem.amount = roundUpAmount(this.props.ExpenseItem.total - this.props.ExpenseItem.tax, 2);
      this.calculateExchangeAmount();

      this.props.updateExpenseDetails({ ...this.props.ExpenseItem });
    }
  };

  calculateBaseCurrencyTax = () => {
    if (this.isConversionAvailable() && this.props.ExpenseItem.currency_exchange && this.props.ExpenseItem.tax_code) {
      this.props.ExpenseItem.base_currency_tax = roundUpAmount(
        (this.props.ExpenseItem.base_currency_total * this.props.ExpenseItem.tax_code.rate) /
          (100 + this.props.ExpenseItem.tax_code.rate),
        2,
      );
      this.props.updateExpenseDetails({ ...this.props.ExpenseItem });
    }
  };

  calculateExchangeTotal = () => {
    if (this.props.EditForm.currency_exchange) {
      this.props.ExpenseItem.total = parseFloat(
        roundUpAmount(this.props.ExpenseItem.base_currency_total * this.props.ExpenseItem.currency_exchange.rate, 2),
      );
      this.props.updateExpenseDetails({ ...this.props.ExpenseItem });
    }
  };

  calculateExchangeAmount = () => {
    let tax = this.props.ExpenseItem.base_currency_tax > 0 ? this.props.ExpenseItem.base_currency_tax : 0;
    let total = this.props.ExpenseItem.base_currency_total ? this.props.ExpenseItem.base_currency_total : 0;
    this.props.ExpenseItem.base_currency_amount = roundUpAmount(total - tax, 2);
  };

  calculatePerDiemAmount = () => {
    let perDiemAmount = 0;
    if (this.state.per_diems && this.state.per_diems.length > 0) {
      let selectedPerDiem = this.state.per_diems.find((per_diem) => per_diem.id == this.props.ExpenseItem.per_diem_id);
      if (selectedPerDiem) {
        let unit_price = selectedPerDiem.unit_price ? selectedPerDiem.unit_price : 0;
        this.props.ExpenseItem.amount = roundUpAmount(parseFloat(unit_price) * this.props.ExpenseItem.qty, 2);
        this.props.ExpenseItem.currency_code = selectedPerDiem.currency_code;
        this.props.ExpenseItem.unit_price = selectedPerDiem.unit_price;
        this.props.ExpenseItem.category_id = selectedPerDiem.category_id;
        this.props.updateExpenseDetails({ ...this.props.ExpenseItem });
      }
    }
  };

  calculateDistanceAmount = () => {
    let distanceAmount = 0;
    if (this.props.ExpenseItem.distance_unit === "mi") {
      this.props.ExpenseItem.amount = roundUpAmount(
        parseFloat(this.props.ExpenseItem.distance) * parseFloat(this.props.ExpenseItem.policy.rate_per_mi),
        2,
      );
    } else if (this.props.ExpenseItem.distance_unit === "km") {
      this.props.ExpenseItem.amount = roundUpAmount(
        parseFloat(this.props.ExpenseItem.distance) * parseFloat(this.props.ExpenseItem.policy.rate_per_km),
        2,
      );
    }
    this.props.updateExpenseDetails({ ...this.props.ExpenseItem });
  };

  calculateExpenseTax = () => {
    if (this.props.ExpenseItem.total && this.props.ExpenseItem.tax_code) {
      this.props.ExpenseItem.tax = roundUpAmount(
        (this.props.ExpenseItem.total * this.props.ExpenseItem.tax_code.rate) /
          (100 + this.props.ExpenseItem.tax_code.rate),
        2,
      );
      this.calculateExpenseAmount();
    }
  };

  calculateExpenseAmount = () => {
    let tax = this.props.ExpenseItem.tax > 0 ? this.props.ExpenseItem.tax : 0;
    let total = this.props.ExpenseItem.total ? this.props.ExpenseItem.total : 0;
    this.props.ExpenseItem.amount = parseFloat(total) - parseFloat(tax);
    this.props.updateExpenseDetails({ ...this.props.ExpenseItem });
  };

  findCurrencies = (policy, currency) => {
    return policy.currency_attributes.find((currencyCode) => currencyCode.label === currency.value);
  };

  getPolicyCurrencyCode = (policy) => {
    if (policy && !policy.currency_attributes) {
      policy.currency_attributes =
        policy.currency_setting === "allow" ? policy.allowed_currencies : policy.excluded_currencies;
    }
    if (policy && policy.currency_setting === "allow" && policy.currency_attributes) {
      return currency_codes.filter((currency) => this.findCurrencies(policy, currency));
    } else if (policy && policy.currency_setting === "exclude" && policy.currency_attributes) {
      return currency_codes.filter((currency) => this.findCurrencies(policy, currency));
    } else {
      return currency_codes;
    }
  };

  mergeSelectedPolicyResponse = (policyId) => {
    this.restApiService.get("policies/" + policyId).then((val) => {
      this.setState({
        currency_codes: this.getPolicyCurrencyCode(val.data),
      });
      this.props.ExpenseItem.policy = val.data;
      this.props.ExpenseItem.currency_code = val.data.currency_code;
      this.props.updateExpenseDetails({ ...this.props.ExpenseItem });
    });
  };

  getCurrencyRate = (currency_code) => {
    let param = { from_currency: currency_code, to_currency: this.props.ExpenseItem.currency_code };
    this.restApiService.get("company/exchange_rate", param).then((val) => {
      this.props.ExpenseItem.currency_exchange = val.data;
      this.props.updateExpenseDetails({ ...this.props.ExpenseItem });
    });
  };

  getPerDiemByPolicy = () => {
    let policyId = this.props.ExpenseItem.policy_id;
    this.restApiService.get("per_diems", { policy_id: policyId }).then((val) => {
      this.setState({
        per_diems: val.data,
      });
    });
  };

  getEmployeeByPolicy = (prevProps) => {
    let employeeId = this.props.ExpenseItem.employee_id;
    if (employeeId && prevProps.ExpenseItem.employee_id != employeeId) {
      let param = { by_open: true, requestor_id: employeeId };
      this.restApiService.get("purchase_orders", param).then((val) => {
        this.setState({
          pos: val.data.data,
        });
      });
    }
  };

  getTaxesByPolicy = () => {
    this.restApiService.get("taxes.lk", { policy_id: this.props.ExpenseItem.policy_id }).then((val) => {
      this.setState({
        taxes: val.data,
      });
    });
  };

  getCategoriesByPolicy = () => {
    this.restApiService.get("categories", { policy_id: this.props.ExpenseItem.policy_id }).then((val) => {
      this.setState({
        categories: val.data,
      });
    });
  };

  render() {
    return (
      <Container fluid={true} className={"pt-4 pb-4"} style={{ background: "#F7F7F7" }}>
        <Loader loaded={this.state.loaded}>
          <Row>
            <Col xs={12}>
              <EditForm
                {...this.props}
                state={this.state}
                onSubmit={this.handleSubmit}
                onDrop={this.onDrop}
                changePolicyDependentFields={this.changePolicyDependentFields}
                onFileDelete={this.fileDeleted}
                getCurrencyRate={this.getCurrencyRate}
              />
            </Col>
          </Row>
        </Loader>
      </Container>
    );
  }

  changePolicyDependentFields = (policyId) => {
    this.mergeSelectedPolicyResponse(policyId);
  };

  //create file objects out of the assets already on the Expense item
  parseFilesFromAPI = () => {
    if (this.props.EditForm.assets && this.props.EditForm.assets.length > 0) {
      let parsedFiles = [];
      this.props.EditForm.assets.forEach((asset) => {
        //notice how we also use the asset id so we can delete if needed later on
        parsedFiles.push({
          name: asset.asset_file_file_name,
          preview: asset.asset_expiring_thumb_url,
          id: asset.id,
          url: asset.asset_expiring_url,
        });
      });
      this.setState({ files: parsedFiles });
    }
  };

  //attach the files to the form data. Will attach any files that need to be destroyed also
  attachFiles = (item, formdata) => {
    let index = 0;

    if (item.assets && item.assets.length > 0) {
      item.assets.forEach((asset) => {
        if (asset._destroy) {
          formdata.append("expense_item[assets_attributes[" + index + "]id]", asset.id);
          formdata.append("expense_item[assets_attributes[" + index + "]_destroy]", asset._destroy);
          index++;
        }
      });
    }
    if (this.state.files && this.state.files.length > 0) {
      this.state.files.forEach((file) => {
        if (file.id) {
          return;
        }
        formdata.append("expense_item[assets_attributes[" + index + "]asset_file]", file);
        index++;
      });
    }
  };

  //when a user drops a file on the upload space or clicks the upload button
  parseUploadFiles = (files) => {
    if (this.state.files && this.state.files.length < 1) {
      let parsedFiles = files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      //make sure we dont overwrite any assets we already have so we copy it
      let currentFiles = Object.assign([], this.state.files);
      currentFiles = currentFiles.concat(parsedFiles);
      this.setState({ files: currentFiles });
    } else {
      CreateNotification("Not Allowed", "Only one receipt is allowed per Expense Item", NotificationType.warning);
    }
  };

  fileDeleted = (targetFile) => {
    let newFileArray = Object.assign([], this.state.files);
    //note this is a FILTER function
    newFileArray = newFileArray.filter((file) => {
      //this file has an id which means it has been saved before. Find it in redux and modify it
      if (file.id != null && file.id === targetFile.id) {
        let newInvoiceAssets = Object.assign([], this.props.EditForm.assets);
        //need to loop over our files to find the matching one
        newInvoiceAssets.forEach((element) => {
          if (element.id === targetFile.id) {
            element._destroy = true;
          }
        });
        //modify redux with our new state
        this.props.setAssets(newInvoiceAssets);
        //dont include this asset anymore in the components state so it will be removed from view
        return false;
      } else if (file.name === targetFile.name) {
        //if the file names match then we can remove this from the components state
        return false;
      }
      //if nothing matches then this file was not deleted and should stay in view
      return true;
    });
    this.setState({ files: newFileArray });
  };

  submitMileageForm = (item) => {
    let obj = {};
    obj.id = item.id;
    obj.number = item.number;
    obj.date = item.date;
    obj.policy_id = item.policy_id;
    obj.employee_id = item.employee_id;
    obj.category_id = item.category_id;
    obj.item_type = item.item_type;
    obj.description = item.description;
    obj.purchase_order_id = item.purchase_order_id;
    obj.amount = item.amount;
    obj.distance_unit = item.distance_unit;
    obj.distance = item.distance;
    return obj;
  };

  submitPerDiemForm = (item) => {
    let obj = {};
    obj.id = item.id;
    obj.number = item.number;
    obj.amount = item.amount;
    obj.date = item.date;
    obj.policy_id = item.policy_id;
    obj.employee_id = item.employee_id;
    obj.category_id = item.category_id;
    obj.item_type = item.item_type;
    obj.description = item.description;
    obj.purchase_order_id = item.purchase_order_id;
    obj.qty = item.qty;
    obj.per_diem_id = item.per_diem_id;
    obj.currency_code = item.currency_code;
    return obj;
  };

  submitSingleExpenseForm = (item) => {
    let obj = {};
    obj.id = item.id;
    obj.number = item.number;
    obj.amount = parseFloat(item.total - item.tax);
    obj.tax = item.tax;
    obj.total = parseFloat(item.total);
    obj.base_currency_amount = item.base_currency_amount;
    obj.base_currency_code = item.base_currency_code;
    obj.base_currency_tax = item.base_currency_tax;
    obj.base_currency_total = item.base_currency_total;
    obj.item_type = item.item_type;
    obj.vendor_name = item.vendor_name;
    obj.date = item.date;
    obj.currency_code = item.currency_code;
    obj.policy_id = item.policy_id;
    obj.employee_id = item.employee_id;
    obj.category_id = item.category_id;
    obj.tax_id = item.tax_id;
    obj.description = item.description;
    obj.purchase_order_id = item.purchase_order_id;
    return obj;
  };

  submitAttachment = (formdata) => {
    this.restApiService
      .patch("expense_items/" + this.props.EditForm.id, null, formdata)
      .then((result) => {
        CreateNotification("Success", "Attachment uploaded successfully", "success");
      })
      .catch((error) => {
        console.error("Error saving company info: ", error);
      });
  };

  handleSubmit = (item) => {
    //we need to use form data for this instead of raw JSON because we are attaching a picture
    let formdata = new FormData();
    let expense_item = {};
    if (item.item_type === "MILEAGE") {
      expense_item = this.submitMileageForm(item);
    } else if (item.item_type === "SINGLE_EXPENSE") {
      expense_item = this.submitSingleExpenseForm(item);
      this.attachFiles(item, formdata);
    } else if (item.item_type === "PER_DIEM") {
      expense_item = this.submitPerDiemForm(item);
      this.attachFiles(item, formdata);
    }

    this.restApiService
      .patch("expense_items/" + this.props.EditForm.id, null, { expense_item: expense_item })
      .then((result) => {
        CreateNotification("Success", "Expense Item Updated", NotificationType.success);
        //Restricting empty formdata to submit
        if (!!formdata.entries().next().value) {
          this.submitAttachment(formdata);
        }
      })
      .catch((error) => {
        console.error("Error saving company info: ", error);
      });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    ExpenseItem: getFormValues("EditForm")(state) || {},
    EditForm: state.editDraftExpenseItemRed.EditForm,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateExpenseDetails: (payload) => {
      dispatch({ type: actionType.UPDATE_EXPENSE_DETAILS, payload: payload });
    },
    setAssets: (payload) => {
      dispatch({ type: actionType.SET_EXPENSE_ITEM_ASSETS, payload: payload });
    },
    changeItemType: (payload) => {
      dispatch({ type: actionType.CHANGE_ITEM_TYPE, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(draftExpense));
