import React from "react";
import { Form } from "react-bootstrap";
import { Mandatory } from "wombatifier/components/pickers/mandatory";

interface WombatInputPropsType {
  id: string;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  defaultValue?: any;
  onChange?: (value: any) => void;
  input?: any;
  meta: { touched: boolean; error: string; warning: string };
  min?: number;
  max?: number;
  maxLength?: number;
  pattern?: string;
  type?: "text" | "number";
  autoComplete?: boolean;
  numberOfLines?: number;
}

export const WombatInput = ({
  id,
  className,
  inputClassName,
  labelClassName,
  placeholder,
  label,
  isRequired = false,
  isDisabled = false,
  defaultValue,
  onChange,
  input,
  meta: { touched, error },
  min,
  max,
  maxLength,
  pattern,
  type,
  autoComplete,
  numberOfLines,
}: WombatInputPropsType) => {
  return (
    <Form.Group className={className ?? ""} id={id}>
      {label && (
        <Form.Label className={labelClassName ?? ""}>
          {label}
          <Mandatory required={isRequired} />
        </Form.Label>
      )}
      <Form.Control
        {...input}
        as={numberOfLines ? "textarea" : "input"}
        type={type ?? "text"}
        autoComplete={autoComplete}
        placeholder={placeholder}
        isInvalid={!!error} // using just error bc 'touched' seems to only be set after form submission... weird
        isValid={touched && !error}
        maxLength={maxLength}
        pattern={pattern ? pattern : null}
        {...(defaultValue ? { defaultValue } : {})}
        required={isRequired}
        disabled={isDisabled}
        onChange={onChange ? onChange : input.onChange}
        min={min}
        max={max}
        className={inputClassName}
      />
      {touched && error && (
        <Form.Control.Feedback className="d-inline-block" type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
