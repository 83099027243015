import React, { useState } from "react";
import styles from "./dashboard.module.css";
import Panel from "components/common/panel/panel";
import { Col, Row } from "react-bootstrap";
import { formattedAmount } from "services/general/helpers";
import { dashboardColorSettings } from "./expenseDashboardColorMap";
import "./expense.css";
import _ from "lodash";
type DataType = {
  category_name: string;
  currency: { symbol: string; iso_code: string };
  expenses_count: number;
  total_spent: number;
  refId?: string;
};

type ExpenseCategoryTableType = {
  categoryData: DataType[];
  colorArray: dashboardColorSettings[];
  totalSpend: number;
  totalExpense: number;
  highLightRow: number | undefined;
  categorySortHandler: (data: DataType[]) => void;
  setHighlightRow: (value: number | undefined) => void;
};

const ExpenseCategoryTable = ({
  categoryData,
  colorArray,
  totalSpend,
  totalExpense,
  highLightRow,
  categorySortHandler,
  setHighlightRow,
}: ExpenseCategoryTableType) => {
  const [spendSort, setSpendSort] = useState("descending");
  const [expenseSort, setExpenseSort] = useState("ascending");
  const [categorySort, setCategorySort] = useState("ascending");
  const getOpacity = (index: number) => {
    if (highLightRow !== undefined && highLightRow !== index) {
      return "30%";
    }
    return "100%";
  };

  const spendSortHandler = () => {
    if (highLightRow === undefined) {
      if (spendSort === "ascending") {
        setSpendSort("descending");
        handleDataSort("total_spent", "asc");
      } else {
        setSpendSort("ascending");
        handleDataSort("total_spent", "desc");
      }
    }
  };

  const expenseSortHandler = () => {
    if (highLightRow === undefined) {
      if (expenseSort === "ascending") {
        setExpenseSort("descending");
        handleDataSort("expenses_count", "asc");
      } else {
        setExpenseSort("ascending");
        handleDataSort("expenses_count", "desc");
      }
    }
  };

  const categoryNameSortHandler = () => {
    if (highLightRow === undefined) {
      if (categorySort === "ascending") {
        setCategorySort("descending");
        handleDataSort("category_name", "asc");
      } else {
        setCategorySort("ascending");
        handleDataSort("category_name", "desc");
      }
    }
  };

  const handleDataSort = (keyName: string, order: boolean | "asc" | "desc" = "asc") => {
    if (keyName) {
      categorySortHandler(_.orderBy(categoryData, [keyName], [order]));
    }
  };

  const getColor = (index: number): string => {
    return colorArray[index % colorArray.length].color;
  };

  return (
    <Panel
      cardClass={styles.categoryPanelWrapper}
      cardBodyClass={styles.expenseTable}
      cardFooterClass={styles.panelFooter}
      wrapperClass={styles.tableWrapperClass}
    >
      <Row className={`${styles.categoryHeader}`}>
        <Col
          sm={4}
          onClick={categoryNameSortHandler}
          style={{ cursor: highLightRow === undefined ? "pointer" : "default" }}
        >
          Category
        </Col>
        <Col
          sm={4}
          onClick={expenseSortHandler}
          style={{ cursor: highLightRow === undefined ? "pointer" : "default" }}
          className={`${styles.textCenter}`}
        >
          # of Expenses
        </Col>
        <Col
          sm={4}
          onClick={spendSortHandler}
          style={{ cursor: highLightRow === undefined ? "pointer" : "default" }}
          className={`${styles.textRight}`}
        >
          Total Spent
        </Col>
      </Row>
      <div className={`${styles.categoryTableBodyContainer}`}>
        {categoryData?.length &&
          categoryData.map((category: DataType, index: number) => {
            const opacity = getOpacity(index);
            let hasBorderColor = false;
            if (highLightRow !== undefined && index === highLightRow) {
              hasBorderColor = true;
            }
            return (
              <Row
                id={`${category.refId}`}
                onClick={() => {
                  if (highLightRow !== undefined) {
                    setHighlightRow(undefined);
                  } else {
                    setHighlightRow(index);
                  }
                }}
                style={{
                  opacity: `${opacity}`,
                  border: hasBorderColor ? `2px solid ${getColor(index)}` : "1px solid #F3F3F3",
                }}
                key={`${index}`}
                className={`${styles.categoryTableRowItemContainer}`}
              >
                <Col sm={4} className={`${styles.categoryTableFirstColItem}`}>
                  <div className={`${styles.categoriesName}`} style={{ background: `${getColor(index)}` }} />
                  {category.category_name}
                </Col>
                <Col sm={4} className={`${styles.textCenter}`}>{`${category.expenses_count}`}</Col>
                <Col sm={4} className={`${styles.textRight}`}>
                  {formattedAmount(`${category.total_spent}`, category.currency.symbol, 2, true)}
                </Col>
              </Row>
            );
          })}
      </div>
      <Row className={`${styles.categoryFooter}`}>
        <Col sm={4} className={`${styles.textRight}`}>
          Total
        </Col>
        <Col sm={4} className={`${styles.textCenter}`}>
          {" "}
          {totalExpense}
        </Col>
        <Col sm={4} className={`${styles.textRight}`}>
          {formattedAmount(`${totalSpend}`, categoryData[0]?.currency?.iso_code, 2, true)}
        </Col>
      </Row>
    </Panel>
  );
};

export default ExpenseCategoryTable;
