import React, { useCallback, useEffect, useState, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import { Col, Container, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import TabNavigation from "../../../components/navigation/tabNavigation";
import { ColumnConfig, SideBarConfig } from "../../../services/common/gridService";
import ChildMessageRenderer from "../../../components/datagrid/childMessageRenderer";
import childCellRouting from "../../../components/datagrid/childCellRouting";
// import CustomStatsToolPanel from "../../../components/datagrid/customStatsToolPanel";
import CustomStatToolPanel from "../../../components/common/dataGrid/customStatToolPanel";
import RandomCellChildRouting from "../../../components/datagrid/randomCellChildRouting";
import vendorAllAccruals from "../../../components/datagrid/girdHeaders/vendorAllAccruals";
import FloatingFilterComponent from "../../../components/datagrid/floatingFilterComponent";
import restApi from "../../../providers/restApi";
import { calculateGridHeight } from "../../../services/grid/gridHeight";
import _ from "lodash";

const restApiService = new restApi();

const AccrualsList = (props) => {
  const [gridHeight, setGridHeight] = useState("");
  const [gridParams, setGridParams] = useState({ gridApi: {}, gridColumnApi: {} });
  const handleSetGridHeight = (gridElement) => {
    if (gridElement !== null) {
      setGridHeight(calculateGridHeight(gridElement.getBoundingClientRect().y) + "px");
    }
  };

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = (params) => {
    //parameters to send to the api
    let apiParams = {
      //grid will pass in the first index and last index that needs to be fetched
      items: params.request.endRow - params.request.startRow,
      //default page size is 100 items
      page: 1 + params.request.startRow / 100,
    };

    getAccrualRequests(apiParams, params);
  };

  const getAccrualRequests = useCallback((apiParams, params) => {
    restApiService
      .get("vendor_accruals")
      .then((response) => {
        //we pass in the data we got from the API and the total row count so the grid knows how big to make the scrollbar
        params.success({ rowData: response.data, rowCount: response.data.length });
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const gridReady = (params) => {
    setGridParams({ gridApi: params.api, gridColumnApi: params.columnApi });
    // //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: getRows });
  };

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <Row>
        <Col>
          <TabNavigation
            navigationTab={[
              { path: "/ar/accrual_requests", pageName: props.t("breadcrumbs.requests"), isActive: "" },
              { path: "/ar/accruals", pageName: props.t("breadcrumbs.allAccruals"), isActive: "active" },
            ]}
          />
        </Col>
      </Row>
      <div
        ref={(gridElement) => handleSetGridHeight(gridElement)}
        style={{ height: gridHeight, width: "100%" }}
        className="ag-theme-fresh animated fadeIn"
      >
        <AgGridReact
          columnDefs={vendorAllAccruals}
          defaultColDef={{
            resizable: true,
          }}
          rowModelType="serverSide"
          onGridReady={gridReady}
          suppressServerSideInfiniteScroll={false}
          rowHeight={ColumnConfig.rowHeight}
          floatingFiltersHeight={ColumnConfig.floatingFiltersHeight}
          components={{
            childMessageRenderer: ChildMessageRenderer,
            childCellRouting: childCellRouting,
            randomChildCellRouting: RandomCellChildRouting,
            customStatsToolPanel: CustomStatToolPanel,
            floatingFilterComponent: FloatingFilterComponent,
          }}
          sideBar={SideBarConfig}
        />
      </div>
    </Container>
  );
};

export default withTranslation()(memo(AccrualsList));
