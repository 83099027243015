import { RenderTextArea } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import companyPaymentMethodStyle from "../companyPaymentMethod.module.css";
import { useTranslation } from "react-i18next";

const BankAddress = ({ col, isBankAddressRequired, label, validations }) => {
  return (
    <Col md={col ? col : "6"}>
      <Field
        id="form.bank_address"
        name="form.bank_address"
        component={RenderTextArea}
        type="text-area"
        rows={4}
        className={companyPaymentMethodStyle.bankAddress}
        label={label}
        required={isBankAddressRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default BankAddress;
