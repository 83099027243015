import React, { useRef, useCallback, useState, useEffect } from "react";
import Style from "./workFlow.module.css";
import { setActiveTierIndex, setStaticTiers } from "../../reducers/approvalSettingsReducers";
import { useDispatch, useSelector } from "react-redux";
import { CreateNotification, NotificationType } from "../../services/general/notifications";
import RestApi from "../../providers/restApi";
import { destroy, initialize } from "redux-form";
import _ from "lodash";
import { Link } from "react-router-dom";

const restApiService = new RestApi();

const DragAndDropTiers = () => {
  const dispatch = useDispatch();
  const draggingItem = useRef();
  const dragOverItem = useRef();
  const state = useSelector((state) => state.approvalSettingsReducer);
  const [position, setPosition] = useState(-1);
  const [step, setStep] = useState(state.activeTierIndex);
  const [tier, setTier] = useState();

  const changeStaticTiers = (newTiers) => {
    dispatch(setStaticTiers(newTiers));
  };

  // const onChangeActiveTierIndex = useCallback(() => {
  //   dispatch(setActiveTierIndex(step));
  // }, [step]);

  useEffect(() => {
    dispatch(setActiveTierIndex(step));
  }, [step]);

  const handleDragStart = (e, position) => {
    draggingItem.current = position;
  };

  useEffect(() => {
    dragOverItem.current = position;
    const listCopy = [...state.staticTiers];
    if (listCopy.length == 0 || !draggingItem.current) {
      return;
    }

    const draggingItemContent = listCopy[draggingItem.current];
    listCopy.splice(draggingItem.current, 1);
    listCopy.splice(dragOverItem.current, 0, draggingItemContent);

    draggingItem.current = dragOverItem.current;
    dragOverItem.current = null;

    const reArrengedList = listCopy.map((listItem, index) => {
      listItem.priority = index + 1;
      return listItem;
    });

    changeStaticTiers(reArrengedList);
    setStep(position);
    setTier(draggingItemContent);
  }, [position]);

  useEffect(() => {
    if (step < 0 || !tier) return;
    dispatch(destroy("workFlowApprovalForm"));
    dispatch(destroy("testTriggerForm"));
    setTimeout(() => {
      dispatch(initialize("workFlowApprovalForm", tier));
      dispatch(initialize("testTriggerForm", tier));
    }, 300);
    // onChangeApprovalFormData(item);
    // onChangeActiveTierIndex();
  }, [step, tier]);

  const onDragEnd = async () => {
    /**
     *  Update Backend priority for all workflow
     */
    var priorities = [];
    state.staticTiers.forEach((tier) => {
      priorities.push({ id: tier.id, priority: tier.priority });
    });
    try {
      restApiService.patch(
        `approval_workflows/reorder`,
        {},
        {
          priorities: priorities,
          workflow_type: state.workFlowType,
        },
      );
    } catch (error) {
      console.log(error.message);
      CreateNotification("Approval Workflow ", `${error.message}`, NotificationType.danger);
    }
  };
  return (
    <div>
      {Boolean(state.staticTiers?.length) &&
        _.sortBy(state.staticTiers, "priority").map((item, index) => (
          <div
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => setPosition(index)}
            onDragEnd={onDragEnd}
            key={item.id}
            draggable={state.isLastStepSaved ? true : false}
            className={`
            ${item.status === "INACTIVE" ? Style.inactiveWorkflow : ""}
            ${Style.workflowStep} d-flex align-content-center  ${
              state.activeTierIndex === index ? Style.activeWorkFlowStep : null
            } `}
            onClick={() => {
              setStep(index);
              setTier(item);
            }}
          >
            <div
              className={`${Style.dragHandle} py-4`}
              style={{
                visibility: state.activeTierIndex === index ? "visible" : "hidden",
              }}
            >
              <div className={Style.dot}></div>
              <div className={Style.dot}></div>
              <div className={Style.dot}></div>
              <div className={Style.dot}></div>
              <div className={Style.dot}></div>
              <div className={Style.dot}></div>
            </div>

            <div className={Style.gridContainer}>
              <div className="font-weight-bold m-3"></div>
              <Link to={`/ap/static_workflows/${item.id ? item.id : "new_approval"}`}>
                <div className={`m-3 ${Style.noWrap}`}>{item.value.label}</div>
              </Link>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DragAndDropTiers;
