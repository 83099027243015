import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";

const UsBankCompanyId = ({ label, isUsBankIdRequired, validations }) => {
  const { t } = useTranslation();
  return (
    <Col md="12">
      <Field
        id="form.us_bank_id"
        name="form.us_bank_id"
        component={RenderField}
        type="text"
        label={label}
        required={isUsBankIdRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default UsBankCompanyId;
