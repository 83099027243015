import React from "react";
import { Col, Row } from "react-bootstrap";
import { required } from "../../../../forms/inputFields";
import AccountName from "../../../../common/managePaymentMethod/fields/accountName";
import AccountNumber from "../../../../common/managePaymentMethod/fields/accountNumber";
import BicCode from "../../../../common/managePaymentMethod/fields/bicCode";
import BankName from "../../../../common/managePaymentMethod/fields/bankName";

const SepaEur = () => {
  return (
    <Row>
      <Col md="4">
        <AccountName label={"Account Holder:(Full Name/Corp Name)"} required validations={[required]} />
      </Col>
      <Col md="4">
        <AccountNumber label="IBAN:" required validations={[required]} />
      </Col>
      <Col md="4">
        <BicCode label="Bank Code (BIC/SWIFT):" required validations={[required]} />
      </Col>
      <Col md="4">
        <BankName label="Bank Name:" required validations={[required]} />
      </Col>
    </Row>
  );
};

export default SepaEur;
