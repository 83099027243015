import React, { useEffect, useRef, useState } from "react";
import { AssetType } from "services/common/types/common.type";
import { Button, Modal } from "react-bootstrap";
import style from "./AttachmentPreviewer.module.css";
import SerialAttachments from "components/common/serialAttachments/serialAttachments";
import _ from "lodash";
import { CreateNotification, NotificationType } from "services/general/notifications";

interface AssetPreviewProps {
  getAssetFiles: () => Promise<AssetType[] | undefined>;
}

const AssetPreview = ({ getAssetFiles }: AssetPreviewProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [assetFiles, setAssetFiles] = useState<AssetType[]>();

  const onAssetPreviewClick = async () => {
    try {
      const assetFiles = await getAssetFiles();
      setAssetFiles(assetFiles);
      setIsOpen(true);
    } catch (error) {
      CreateNotification("Error", "Unable to fetch assest files", NotificationType.danger);
    }
  };

  const onHide = () => {
    setIsOpen(false);
  };
  return (
    <>
      {isOpen && assetFiles && (
        <AssetPreviewModal show={isOpen} onHide={() => onHide()} buttonRef={buttonRef} assetFiles={assetFiles} />
      )}
      <button
        type="button"
        className="actionButtons icon-document-action"
        data-toggle="tooltip"
        title="Asset Preview"
        onClick={() => onAssetPreviewClick()}
        ref={buttonRef}
      ></button>
    </>
  );
};

export default AssetPreview;

const AssetPreviewModal = ({
  show,
  onHide,
  buttonRef,
  assetFiles,
}: {
  show: boolean;
  onHide: () => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
  assetFiles: AssetType[];
}) => {
  const onMount = () => {
    const buttonPosition = buttonRef.current?.getBoundingClientRect().top;
    const modal: HTMLDivElement | null = document.querySelector(".assestPreviewModal");
    if (modal) {
      modal.style.top = `${buttonPosition}px`;
      modal.style.position = "absolute";
      modal.style.right = "0px";
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Modal
      size={"lg"}
      dialogClassName="assestPreviewModal"
      show={show}
      onHide={() => onHide()}
      backdrop={false}
      scrollable={false}
    >
      <Modal.Header className={style.assetsPreviewModalHeader} closeButton></Modal.Header>
      <Modal.Body className={style.assetsPreviewModalBody}>
        {_.isArray(assetFiles) && assetFiles.length > 0 ? (
          <SerialAttachments attachments={assetFiles} />
        ) : (
          <div className={style.noAttachamentFoundBg}>Attachment not found!</div>
        )}
      </Modal.Body>
      <Modal.Footer className={style.assetsPreviewModalFooter + " d-flex justify-content-center"}>
        <Button variant="secondary" type="button" className="btn btn-secondary" onClick={() => onHide()}>
          Close Preview
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
