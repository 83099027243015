//  @ts-nocheck
import _, { remove } from "lodash";
import React, { useMemo, useState } from "react";
import PaymentAccountPicker from "../../pickers/paymentAccountPicker";
import { Link } from "react-router-dom";
import { LinkPaymentMethod, UnlinkPaymentMethod } from "../../../services/paymentMethods/paymentMethodLinking";
import useConfirmModal from "../../modals/confirmModal/useConfirmModalHook";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { IDType } from "../../../services/common/types/common.type";

type PaymentMethodLinkUnlinkRowPropsType = {
  purchaser: any;
  companyPaymentMethods: any;
  getPurchasers: () => void;
};

const PaymentMethodLinkUnlinkRow = ({
  purchaser,
  companyPaymentMethods,
  getPurchasers,
}: PaymentMethodLinkUnlinkRowPropsType) => {
  const [isLoading, setLoading] = useState(false);
  const { createConfirmModal } = useConfirmModal();
  const vendorCreatedLinkedPaymentMethod = useMemo(() => {
    if (_.isArray(purchaser?.linked_payment_methods) && _.isArray(companyPaymentMethods)) {
      // only want vendorCreatedPayamentMethods links
      // so filtering by company.payment_methods
      const vendorCreatedLinkedPaymentMethod =
        purchaser.linked_payment_methods.filter((linkedPaymentMethod: any) =>
          companyPaymentMethods.map((pm) => pm.id).includes(linkedPaymentMethod.id),
        ) || [];

      return vendorCreatedLinkedPaymentMethod;
    }
  }, [companyPaymentMethods, purchaser.linked_payment_methods]);
  const vendorCreatedLinkedPaymentMethodIds = vendorCreatedLinkedPaymentMethod.map((pm) => pm.id);

  //From the list of accepted payment methods get 1 string to represent it to the user
  const getAcceptedPaymentMethodsString = (purchaser: any) => {
    if (purchaser && purchaser.accepted_payment_methods) {
      let finalStrings = [];
      if (purchaser.accepted_payment_methods.ach) {
        finalStrings.push("ACH-(US)");
      }
      if (purchaser.accepted_payment_methods.corpay_nvp) {
        finalStrings.push("Corpay");
      }
      if (purchaser.accepted_payment_methods.cambridge) {
        finalStrings.push("International (Cambridge)");
      }
      if (purchaser.accepted_payment_methods.can_eft) {
        finalStrings.push("EFT (Canada)");
      }
      if (purchaser.accepted_payment_methods.credit_card) {
        finalStrings.push("Credit Card");
      }
      if (purchaser.accepted_payment_methods.payoneer) {
        finalStrings.push("International (Pay)");
      }
      if (purchaser.accepted_payment_methods.transferwise) {
        finalStrings.push("International (TW)");
      }
      if (purchaser.accepted_payment_methods.wire) {
        finalStrings.push("International Cross-border - AI/Wire");
      }
      if (purchaser.accepted_payment_methods.fed_wire) {
        finalStrings.push("Domestic/Fedwire - Wire");
      }
      if (finalStrings.length > 0) {
        return finalStrings.join(", ");
      }
    }
    return "None";
  };

  //Returns true if the purchaser has ANY accepted payment methods
  const purchaserHasAnyAcceptedPaymentMethods = (purchaser: any) => {
    let hasAny = false;
    for (const [key, value] of Object.entries(purchaser.accepted_payment_methods)) {
      if (value) {
        hasAny = true;
      }
    }
    return hasAny;
  };

  const onUnlinkPaymentMethod = (paymentMethodId: IDType) => {
    createConfirmModal({
      title: "Unlink Payment Method",
      body: `Do you really want to Unlink paymethod ${paymentMethodId} from purchaser ${purchaser.name}`,
      callBackData: paymentMethodId,
      saveCallBack: onUnlinkPaymentMethodConfirm,
      cancelCallBack: null,
    });
  };

  const onUnlinkPaymentMethodConfirm = async (paymentMethodId: IDType) => {
    try {
      setLoading(true);
      await UnlinkPaymentMethod(purchaser.vendor_detail.id, paymentMethodId);
      setLoading(false);
      getPurchasers && getPurchasers();
      CreateNotification("Success", "Payment Account Unlinked Succesfully", NotificationType.success);
    } catch (error) {}
  };

  const onLinkPaymentMethod = async (paymentMethodId: IDType) => {
    createConfirmModal({
      title: "Link Payment Method",
      body: `Do you really want to Link paymethod ${paymentMethodId} to purchaser ${purchaser.name}`,
      callBackData: paymentMethodId,
      saveCallBack: onLinkPaymentMethodConfirm,
      cancelCallBack: null,
    });
  };

  const onLinkPaymentMethodConfirm = async (paymentMethodId: IDType) => {
    try {
      setLoading(true);
      await LinkPaymentMethod(purchaser.vendor_detail.id, paymentMethodId);
      setLoading(false);
      getPurchasers && getPurchasers();
      CreateNotification("Success", "Payment Account Linked Succesfully", NotificationType.success);
    } catch (error) {}
  };

  const onLinkedPaymentMethodChange = (paymentMethodsIds: any[]) => {
    // removed
    const removed = vendorCreatedLinkedPaymentMethodIds.filter(
      (linkedPaymentMethodId: IDType) => !paymentMethodsIds.includes(linkedPaymentMethodId),
    );
    if (_.isArray(removed) && remove.length > 0) {
      onUnlinkPaymentMethod(removed[0]);
    }

    // added
    const added = paymentMethodsIds.filter(
      (linkedPaymentMethodId) => !vendorCreatedLinkedPaymentMethodIds.includes(linkedPaymentMethodId),
    );
    if (_.isArray(added) && added.length > 0) {
      onLinkPaymentMethod(added[0]);
    }
  };

  return (
    <tr>
      <td>
        <Link className="primaryLinkColor" to={"/ar/purchasers/" + purchaser.id}>
          {purchaser.name}
        </Link>
      </td>
      <td>{getAcceptedPaymentMethodsString(purchaser)}</td>
      {purchaserHasAnyAcceptedPaymentMethods(purchaser) ? (
        <td
          style={{
            minWidth: "300px",
            maxWidth: "300px",
          }}
        >
          <PaymentAccountPicker
            fixed
            isMulti
            hideLabel
            hideAddNewButton
            isLoading={isLoading}
            isNvpOnlyPurchaser={purchaser.has_only_corpay_nvp_integration}
            vendorDefaultPaymentMethod={purchaser.vendor_default_payment_method}
            menuPosition={"fixed"}
            id={vendorCreatedLinkedPaymentMethodIds}
            limitPaymentMethods={purchaser.accepted_payment_methods}
            callback={(pms: any) => _.isArray(pms) && onLinkedPaymentMethodChange(pms.map((pm) => pm.value))}
          />
        </td>
      ) : (
        <td
          style={{
            minWidth: "300px",
            maxWidth: "300px",
          }}
        >
          Not accepting payment methods at this time
        </td>
      )}
    </tr>
  );
};

export default PaymentMethodLinkUnlinkRow;
