import { createStore, compose } from "redux";
import rootReducers from "../reducers";
import middleware from "./middleware";

const enhancers = ((typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose)(
  middleware,
);

export default function configureStore(initialState) {
  return createStore(rootReducers, {}, enhancers);
}
