import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import RestApi from "../../providers/restApi";
import { withRouter } from "react-router-dom";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */
class PaymentAccountPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethods: [],
    };
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    //fetch payment methods
    if (this.props.limitToLinkedPurchaser) {
      this.restApiService
        .get("vendors/" + this.props.limitToLinkedPurchaser.vendor_detail.id + "/vendor_linked_payment_methods")
        .then((result) => {
          let options = this.parsePaymentMethods(result.data);
          this.setState({
            paymentMethods: result.data,
            selectOptions: options,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.restApiService
        .get("company")
        .then((result) => {
          if (result.data && result.data.payment_methods) {
            let options = this.parsePaymentMethods(result.data.payment_methods);
            this.setState({
              paymentMethods: result.data.payment_methods,
              selectOptions: options,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  //From our list of payment methods parse them into something react-select can understand
  parsePaymentMethods(paymentMethods) {
    let options = [];
    paymentMethods.forEach((pm) => {
      if (this.props.limitPaymentMethods) {
        if (pm.payment_type === "ach" && !this.props.limitPaymentMethods.ach) {
          return;
        } else if (pm.payment_type === "international" && !this.props.limitPaymentMethods.transferwise) {
          return;
        } else if (pm.payment_type === "credit_card" && !this.props.limitPaymentMethods.credit_card) {
          return;
        } else if (pm.payment_type === "payoneer" && !this.props.limitPaymentMethods.payoneer) {
          return;
        } else if (pm.payment_type === "wire" && !this.props.limitPaymentMethods.wire) {
          return;
        } else if (pm.payment_type === "fed-wire" && !this.props.limitPaymentMethods.fed_wire) {
          return;
        } else if (pm.payment_type === "can-eft" && !this.props.limitPaymentMethods.can_eft) {
          return;
        } else if (pm.payment_type === "uk-eft" && !this.props.limitPaymentMethods.uk_eft) {
          return;
        } else if (pm.payment_type === "aus-pay-net" && !this.props.limitPaymentMethods.aus_pay_net) {
          return;
        } else if (pm.payment_type === "nz-eft" && !this.props.limitPaymentMethods.nz_eft) {
          return;
        } else if (pm.payment_type === "japan-eft" && !this.props.limitPaymentMethods.japan_eft) {
          return;
        } else if (pm.payment_type === "nz-eft" && !this.props.limitPaymentMethods.sg_eft) {
          return;
        } else if (pm.payment_type === "sepa" && !this.props.limitPaymentMethods.sepa) {
          return;
        } else if (pm.payment_type === "cambridge" && !this.props.limitPaymentMethods.cambridge) {
          return;
        } else if (pm.payment_type === "swift-wire" && !this.props.limitPaymentMethods.swift_wire) {
          return;
        } else if (pm.payment_type === "external" && !this.props.limitPaymentMethods.external) {
          return;
        }
      }

      options.push({
        value: pm.id,
        label: pm.name + "-" + pm.account_type + "-" + pm.account_number,
        raw: pm,
      });
    });

    //add in link to add new if not hidden
    if (!this.props.hideAddNewButton) {
      options.push({
        value: "BANK_SETTINGS_LINK",
        label: "+ Add New",
        color: "#FF5C35",
      });
    }

    return options;
  }

  //Find the option that matches the passed in props.id
  findSelected() {
    if (this.props.isNvpOnlyPurchaser) {
      let labelArray = [
        this.props.vendorDefaultPaymentMethod.account_name,
        this.props.vendorDefaultPaymentMethod.payment_type == "credit_card"
          ? "Mastercard"
          : this.props.vendorDefaultPaymentMethod.payment_type,
        this.props.vendorDefaultPaymentMethod.account_number,
      ];
      return {
        value: this.props.vendorDefaultPaymentMethod.id,
        label: labelArray.filter((i) => i != undefined && i != labelArray.last).join("-"),
        raw: this.props.vendorDefaultPaymentMethod,
      };
    }
    if (!this.state.selectOptions) {
      return null;
    }

    //slightly different logic if this is a multi select
    if (this.props.isMulti) {
      let options = [];
      this.state.selectOptions.forEach((option) => {
        if (this.props.id && this.props.id.includes(option.value)) {
          options.push(option);
        }
      });
      return options.length > 0 ? options : null;
    } else {
      return this.state.selectOptions.find((option) => {
        if (option.value === this.props.id) {
          return option;
        }
        return null;
      });
    }
  }

  render() {
    return (
      <Form.Group className={"w-100" + (this.props.fixed ? " mb-0" : "")}>
        {this.props.hideLabel !== true && (
          <Form.Label style={{ textTransform: "capitalize" }}>{this.props.t("details.paymentAccount")}</Form.Label>
        )}
        <Select
          required={true}
          isDisabled={this.props.isNvpOnlyPurchaser}
          value={this.findSelected()}
          isMulti={this.props.isNvpOnlyPurchaser ? false : this.props.isMulti}
          placeholder={this.props.t("forms.selectPaymentAccount")}
          options={this.state.selectOptions}
          getOptionLabel={(option) => {
            return <div className="word-break">{option.label}</div>;
          }}
          menuPortalTarget={this.props.fixed ? document.body : null}
          menuPosition={this.props.menuPosition ?? "absolute"}
          onChange={(e) => {
            // It's an array on the Banking Information page but the Add new button is disabled
            if (e && e.value == "BANK_SETTINGS_LINK") {
              this.props.history.push({ pathname: "/ar/banking_information", state: { addPaymentFormOpen: true } });
            } else if (this.props.callback) {
              this.props.callback(e);
            }
          }}
          styles={{
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                color: data.color,
              };
            },
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          isLoading={this.props.isLoading}
        />
      </Form.Group>
    );
  }
}

export default withRouter(withTranslation()(PaymentAccountPicker));
