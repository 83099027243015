import { AxiosResponse } from "axios";
import useShowSelectMenu from "components/admin/hooks/useShowSelectMenu";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useTypedSelector } from "reducers";
import { WrappedFieldProps } from "redux-form";
import { CommonTypes } from "services/common/types/commonTypes";
import { IUser } from "services/common/user/userTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { restApiService } from "../../../../../providers/restApi";
import { compare, isDefined } from "../../../../../services/general/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";

interface BusinessUnitPickerPropsType extends WrappedFieldProps, CommonTypes.TSelectMenu {
  label?: string;
  input: any;
  required?: boolean;
  tooltip?: string;
  disabled?: boolean;
  floating?: boolean;
  parentObjData?: any;
  modelData?: any;
  className?: any;
  bySubsidiary?: boolean;
  instanceId?: string;
  callBack?: (businessUnit: BusinessUnitObjType | null) => void;
  isClearable?: boolean;
  showAsteriskWithoutLabel?: boolean;
}

export type BusinessUnitObjType = {
  id?: number | string;
  name?: string;
  status?: string;
  label?: string;
  has_departments?: boolean;
  has_locations?: boolean;
  has_subsidiaries?: boolean;
};

const BusinessUnitPicker = ({
  label,
  input,
  required,
  tooltip,
  disabled,
  parentObjData,
  callBack,
  modelData,
  bySubsidiary,
  instanceId = "business-unit-picker",
  className,
  floating = false,
  meta: { error, touched },
  isClearable,
  showAsteriskWithoutLabel,
  parentDivId,
  parentDivVariance,
}: BusinessUnitPickerPropsType) => {
  const [businessUnits, setBusinessUnits] = useState<BusinessUnitObjType[]>([]);
  const mounted = useRef(false);
  const [selected, setSelected] = useState<BusinessUnitObjType | null>();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useShowSelectMenu({
    setIsMenuOpen,
    selectRef,
    parentDivId,
    variance: parentDivVariance,
  });

  const parseForSelect = (options: BusinessUnitObjType[]) => {
    return options
      .map((option: BusinessUnitObjType) => {
        return {
          value: option.id,
          label: option.name,
          status: option.status,
          ...option,
        };
      })
      .sort(compare);
  };

  const getBusinessUnits = useCallback(async () => {
    let apiParams: any = { status: "ACTIVE" };

    if (bySubsidiary && parentObjData?.subsidiary_id) {
      apiParams.subsidiary_id = parentObjData?.subsidiary_id;
    }

    if (currentUser?.company?.has_account_structures && modelData) {
      apiParams.account_id = modelData.account_id;
    }

    if (modelData?.is_product_item_link_to_business_unit) {
      apiParams.product_item_id = modelData.product_item_id;
      modelData.only_show_mapped_business_unit = true;
    }

    // 'has_account_structures' becomes true if account_structures is uploaded
    // In this case, Business Unit Picker will override all previous parameters
    // and send has_account_structures's parameters to business_units.lk API.
    if (currentUser?.company?.has_account_structures) {
      apiParams = { account_structure: true, status: "ACTIVE" };
      if (modelData) {
        apiParams.account_id = modelData.account_id;
        modelData.only_show_mapped_business_unit = true;
      }
      if (parentObjData) {
        apiParams.subsidiary_id = parentObjData.subsidiary_id;
      }
    }

    const result: AxiosResponse = await restApiService.get(`business_units.lk`, apiParams, null, true, null, true);
    if (result?.data) {
      let list = parseForSelect(result?.data);
      if (mounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Business Unit --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setBusinessUnits(list);
      }
      return list;
    }
  }, [parentObjData, modelData]);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const businessUnit = await options.filter((option: any) => option.value === input.value);

      if (businessUnit.length === 0 && modelData?.only_show_mapped_business_unit) {
        modelData.only_show_mapped_business_unit = false;
        selectedValues = null;
      } else if (businessUnit.length === 0) {
        // Fetch inactive businessUnit from the API and enable them to be appear in the businessUnit dropdown.
        try {
          const result = await restApiService.get("business_units/" + input.value);
          const inactiveBusinessUnit = {
            value: result.data.id,
            label: result.data.name,
          };
          selectedValues = inactiveBusinessUnit;
        } catch (error) {
          console.log(error);
          CreateNotification("Error", "Problem loading inactive department.", NotificationType.danger);
        }
      } else {
        selectedValues = businessUnit[0];
      }
      return selectedValues ? selectedValues : null;
    }
  };

  const onChangeBusinessUnit = (selected: any) => {
    if (selected && (selected?.value || selected?.id)) {
      input.onChange(selected.value || selected.id);
      setSelected(selected);
      if (callBack) {
        callBack(selected);
      }
    } else {
      input.onChange(null);
      setSelected(null);
      if (callBack) {
        callBack(null);
      }
    }
  };

  const checkIsClearable = useCallback(() => {
    if (required) {
      return false;
    } else if (isDefined(isClearable)) {
      return isClearable;
    } else if (_.isUndefined(isClearable)) {
      return false;
    }
  }, [isClearable, required]);

  useEffect(() => {
    getBusinessUnits().then((res) => {
      findSelected(input, res).then((response: BusinessUnitObjType) => {
        setSelected(response);
      });
    });
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [parentObjData, modelData, input?.value]);

  return (
    <>
      <Form.Group ref={selectRef}>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        {!label && showAsteriskWithoutLabel && required && (
          <div className="d-flex justify-content-end">
            <Mandatory required={required} />
          </div>
        )}
        <Select
          {...input}
          menuPlacement="auto"
          menuPosition="fixed"
          menuIsOpen={isMenuOpen}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          required={required}
          value={selected}
          onChange={(selected) => onChangeBusinessUnit(selected)}
          onBlur={null}
          options={businessUnits}
          isClearable={checkIsClearable()}
          disable={disabled}
          instanceId={instanceId}
          className={className}
          getOptionLabel={(option: BusinessUnitObjType) => {
            return (
              <>
                <span className="formField">{option.label}</span>
                {option.status === "INACTIVE" && <small>{option.status}</small>}
              </>
            );
          }}
          filterOption={(option: any, searchText) =>
            option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
          }
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
            }),
            ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
          }}
          {...(floating ? { menuPortalTarget: document.body } : {})}
        ></Select>
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default BusinessUnitPicker;
