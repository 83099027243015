import React from "react";
import { Form } from "react-bootstrap";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Select from "react-select";
import { compare, findSelectedSingle, onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import { taxForms } from "../../../../app.svc.Lookup";

type TaxForm1090Type = {
  external_id: string;
  type: string;
  name: string;
};

type InputType = {
  value: number;
  onChange: (values: number) => void;
  onBlur: (values: number) => void;
};

type TaxFormType1099PropsType = {
  label?: string;
  tooltip?: string;
  required?: boolean;
  taxFormTypeExternalId?: string;
  placeholder?: string;
  input: InputType;
  meta: {
    touched?: boolean;
    error?: string;
  };
  callBack?: (selected: { value: string | number; label: string }) => void;
};

const manageLabel = ({ external_id, name }: TaxForm1090Type) => {
  return `${external_id} -- ${name}`;
};

const parseTaxForm1099 = (taxForm: TaxForm1090Type[]) => {
  return taxForm
    .map((option: TaxForm1090Type) => {
      return {
        value: option.external_id,
        label: manageLabel(option),
      };
    })
    .sort(compare);
};

const TaxForm1099 = ({
  label,
  input,
  meta: { touched, error },
  tooltip,
  required,
  taxFormTypeExternalId,
  placeholder = "search/select",
  callBack,
}: TaxFormType1099PropsType) => {
  // Filter tax forms based on taxFormTypeExternalId
  const filteredTaxForms = parseTaxForm1099(taxForms.filter((taxForm) => taxForm.type === taxFormTypeExternalId));

  const onChange = (input: InputType, selected: { value: string | number; label: string }) => {
    onChangeSingle(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={taxFormTypeExternalId ? true : false} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        value={findSelectedSingle(input, filteredTaxForms)}
        placeholder={placeholder}
        onChange={(selected) => onChange(input, selected)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={filteredTaxForms}
        isClearable
      />
      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default TaxForm1099;
