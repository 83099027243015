import React from "react";
import style from "./objectChanges.module.css";
import { Table } from "react-bootstrap";
import changeOrderService from "../../../services/admin/changeOrders/changeOrderSvc";
import { PurchaseOrderType } from "../../../services/admin/purchaseOrders/purchaseOrderType";
import { PoChangeOrderType } from "../../../services/admin/changeOrders/poChangeOrderApprovalsType";
import _ from "lodash";
import ChangedPropertyId from "./changedPropertyId";
import ChangedProperty from "./changedProperty";
import { useTypedSelector } from "reducers";

type ExpenseLineTableProps = {
  invoiceDebitAccounts: PurchaseOrderType.InvoiceDebitAccountsType[];
  changeOrderInvoiceDebitAccounts: PoChangeOrderType.InvoiceDebitAccountsAttributeType[];
};

const RenderExpenseRow = ({
  className,
  index,
  newItem,
  oldItem,
}: {
  newItem: PoChangeOrderType.InvoiceDebitAccountsAttributeType;
  oldItem: PoChangeOrderType.InvoiceDebitAccountsAttributeType | undefined;
  index: number;
  className: string;
}) => {
  const currentUser = useTypedSelector((state) => state.user);
  const fullOldItem = oldItem as any;

  const getAccountInfo = (item: any): string | undefined => {
    if (!item) return undefined;
    const { account_number, account_name } = item;

    return [account_number, account_name].filter(Boolean).join(" - ");
  };

  return (
    <tr key={index} className={className}>
      <td>{index + 1}</td>
      <ChangedPropertyId
        endpoint="accounts"
        newItem={newItem}
        oldItem={oldItem}
        property="account_id"
        formatter={(obj) => `${obj.number} - ${obj.name}`}
        value={getAccountInfo(fullOldItem)}
      />

      <ChangedProperty newItem={newItem} oldItem={oldItem} property="amount" />

      {currentUser.company.allow_for_location && (
        <ChangedPropertyId
          endpoint="locations"
          newItem={newItem}
          oldItem={oldItem}
          property="location_id"
          formatter={(obj) => obj.name}
          value={fullOldItem?.location_name}
        />
      )}

      <ChangedPropertyId
        endpoint="budgets"
        newItem={newItem}
        oldItem={oldItem}
        property="budget_id"
        formatter={(obj) => obj.label}
        value={fullOldItem?.budget_name} //TODO: Check if budget_name exists
      />

      {currentUser.company.has_categories && (
        <ChangedPropertyId
          endpoint="categories"
          newItem={newItem}
          oldItem={oldItem}
          property="category_id"
          formatter={(obj) => obj.name}
          value={fullOldItem?.category_name}
        />
      )}

      {currentUser.company.has_secondary_categories && (
        <ChangedPropertyId
          endpoint="secondary_categories"
          newItem={newItem}
          oldItem={oldItem}
          property="secondary_category_id"
          formatter={(obj) => obj.name}
          value={fullOldItem?.secondary_category_name}
        />
      )}

      {currentUser.company.has_sponsorships && (
        <ChangedPropertyId
          endpoint="sponsorships"
          newItem={newItem}
          oldItem={oldItem}
          property="sponsorship_id"
          formatter={(obj) => obj.name}
          value={fullOldItem?.sponsorship_name}
        />
      )}

      {currentUser.company.has_for_subsidiaries && (
        <ChangedPropertyId
          endpoint="for_subsidiaries"
          newItem={newItem}
          oldItem={oldItem}
          property="for_subsidiary_id"
          formatter={(obj) => obj.name}
          value={fullOldItem?.for_subsidiary_name}
        />
      )}

      {currentUser.company.has_projects && (
        <ChangedPropertyId
          endpoint="projects"
          newItem={newItem}
          oldItem={oldItem}
          property="project_id"
          formatter={(obj) => obj.name}
          value={fullOldItem?.project_name}
        />
      )}

      <ChangedProperty newItem={newItem} oldItem={oldItem} property="memo" />
    </tr>
  );
};

const ExpenseLineTable = ({ invoiceDebitAccounts, changeOrderInvoiceDebitAccounts }: ExpenseLineTableProps) => {
  const differentiatedPoDebitLines = changeOrderService.getDifferentiatedCOObjects<
    PoChangeOrderType.InvoiceDebitAccountsAttributeType,
    PurchaseOrderType.InvoiceDebitAccountsType[],
    PoChangeOrderType.InvoiceDebitAccountsAttributeType,
    PoChangeOrderType.InvoiceDebitAccountsAttributeType[]
  >(invoiceDebitAccounts, changeOrderInvoiceDebitAccounts);
  const currentUser = useTypedSelector((state) => state.user);

  return (
    <Table bordered responsive className={`text-left m-0 ${style.thBgColor}`}>
      <thead>
        <tr>
          <th>#</th>
          <th>Account</th>
          <th>Amount</th>
          {currentUser.company.allow_for_location && <th>Location</th>}
          <th>Budget</th>
          {currentUser.company.has_categories && <th>Category</th>}
          {currentUser.company.has_secondary_categories && <th>Secondary Category</th>}
          {currentUser.company.has_sponsorships && <th>Sponsorship</th>}
          {currentUser.company.has_for_subsidiaries && <th>For Subsidiary</th>}
          {currentUser.company.has_projects && <th>Project</th>}
          <th>Memo</th>
        </tr>
      </thead>
      <tbody>
        {_.isArray(differentiatedPoDebitLines) &&
          differentiatedPoDebitLines?.map((differentiatedPoDebitLine, index) => {
            const { change_type, newItem, oldItem } = differentiatedPoDebitLine;
            switch (change_type) {
              case "ADDED":
                return (
                  <RenderExpenseRow newItem={newItem} oldItem={oldItem} index={index} className={style.updatedItem} />
                );
              case "DELETED":
                return (
                  <RenderExpenseRow newItem={newItem} oldItem={oldItem} index={index} className={style.deletedItem} />
                );
              default:
                return <RenderExpenseRow newItem={newItem} oldItem={oldItem} index={index} className={""} />;
            }
          })}
      </tbody>
    </Table>
  );
};

export default ExpenseLineTable;
