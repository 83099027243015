import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import RestApi from "./../../../providers/restApi";
import { BreadcrumbTrail } from "./../../../components/navigation/navigationLinks";
import PageTitle from "./../../../components/page/pageTitle";
import { CreateNotification, NotificationType } from "./../../../services/general/notifications";
import EditButton from "./../../../components/buttons/editButton";
import AssignedContacts from "./../../../components/vp/contacts/verifyDataTable/assignedContacts";
import { useParams } from "react-router";
import useIsMounted from "./../../../components/common/hooks/useIsMounted";
import { useTranslation } from "react-i18next";

const restApiService: RestApi = new RestApi();

type propsType = {};

interface IContact {
  id: number;
  name: string;
  status: string;
  user: { email?: string; last_sign_in_at?: string; is_disabled?: boolean } | null;
  contact_id: number;
  title: string;
  email: string;
  phone: string;
  system_roles_str: string;
}

const VendorContactDetails = (props: propsType) => {
  const [contact, setContact] = useState<IContact>({
    id: 0,
    name: "",
    status: "",
    user: {},
    contact_id: 0,
    title: "",
    email: "",
    phone: "",
    system_roles_str: "",
  });

  const { id } = useParams<{ id: string }>();
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const fetchContact = async () => {
    try {
      const response = await restApiService.get("contacts/" + id);
      if (isMounted) {
        setContact(response.data);
      }
    } catch (error) {
      CreateNotification(t("error"), t("errors.genericSupport"), NotificationType.warning);
    }
  };

  const inviteToPortal = async () => {
    try {
      const response: any = await restApiService.post("contacts/" + contact?.id + "/vendor_portal_send_welcome");
      if (response.status === 200) {
        CreateNotification("Success", "Welcome Email Sent", NotificationType.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted) {
      fetchContact();
    }
  }, []);

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <BreadcrumbTrail to={"/ar/contacts"} pageName={"Users"} title={"Users Details"} />
      <Row className="mt-3 mb-4">
        <Col className="d-flex align-items-center">
          <PageTitle title={contact.name} status={contact.status} />

          <div className="ml-auto">
            {(!contact.user || !contact.user?.last_sign_in_at) && (
              <Button
                className="ml-2"
                variant="primary"
                type="button"
                onClick={() => {
                  inviteToPortal();
                }}
              >
                {t("contact.invPortal")}{" "}
              </Button>
            )}
            <EditButton buttonClasses={"ml-2"} text={"Edit User"} link={"/ar/contacts/" + contact.id + "/edit"} />
          </div>
        </Col>
      </Row>
      <hr className="full-border" />
      <Row className=" py-4">
        <Col sm="4" className="pr-sm-0 pt-4">
          <dl className="row">
            <dt className="col-6 col-sm-5 detailKey text-sm-right">{"User ID"}</dt>
            <dd className="col-6 col-sm-7 detailValue">{contact.contact_id}</dd>

            <dt className="col-6 col-sm-5 detailKey text-sm-right">{"Title"}</dt>
            <dd className="col-6 col-sm-7 detailValue">{contact.title}</dd>

            <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.email")}</dt>
            <dd className="col-6 col-sm-7 detailValue">{contact.email}</dd>

            <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.phone")}</dt>
            <dd className="col-6 col-sm-7 detailValue">{contact.phone}</dd>
          </dl>
        </Col>
        <Col sm="4" className="pl-sm-0 pt-4">
          <dl className="row">
            <dt className="col-6 col-sm-5 detailKey text-sm-right">{"Portal Access"}</dt>
            <dd className="col-6 col-sm-7 detailValue">{contact.user ? "true" : "false"}</dd>

            <dt className="col-6 col-sm-5 detailKey text-sm-right">{"User Login"}</dt>
            <dd className="col-6 col-sm-7 detailValue">{contact.user && contact.user?.email}</dd>

            <dt className="col-6 col-sm-5 detailKey text-sm-right">{"Last IP"}</dt>
            <dd className="col-6 col-sm-7 detailValue">{}</dd>

            <dt className="col-6 col-sm-5 detailKey text-sm-right">{"Last Login"}</dt>
            <dd className="col-6 col-sm-7 detailValue">{contact.user && contact.user?.last_sign_in_at}</dd>

            <dt className="col-6 col-sm-5 detailKey text-sm-right">{"Login Disabled"}</dt>
            <dd className="col-6 col-sm-7 detailValue">
              {contact.user && contact.user?.is_disabled ? "true" : "false"}
            </dd>

            <dt className="col-6 col-sm-5 detailKey text-sm-right">{"Admin"}</dt>
            <dd className="col-6 col-sm-7 detailValue">
              {contact.system_roles_str && contact.system_roles_str.includes("vendor_admin") ? "true" : "false"}
            </dd>
          </dl>
        </Col>
        <Col sm="4">
          <div className={"avatar"}>
            <img
              className={"w-100"}
              alt="account"
              src={require("./../../../assets/icons/settings/ic_account_circle.svg").default}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>{contact && contact.email && <AssignedContacts contact={contact} />}</Col>
      </Row>
    </Container>
  );
};

export default VendorContactDetails;
