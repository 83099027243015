import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import styles from "../../vendors.module.css";
import { useTranslation } from "react-i18next";

type VendorContactInfoType = {
  contact: any;
  handleEdit: (step: number) => void;
};

const VendorContactInfo = ({ contact, handleEdit }: VendorContactInfoType) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Row>
        <Col md="12">
          <i className={`icon icon-vendor ${styles.detailPageIconWidth}`}></i>
          <span className={styles.title}>{t("admin.pages.vendor.addVendorContact")}</span>
          <div className="float-right">
            <Button variant="secondary" className={`px-mr-10 ${styles.btnEdit}`} onClick={() => handleEdit(4)}>
              <i className="icon icon-edit px-m-0"></i>
              {t("admin.pages.vendor.edit")}
            </Button>
            <span className={`float-right ${styles.iconToggle}`}>
              <i
                className={toggle ? "icon icon-arrow-up" : "icon icon-arrow-down"}
                onClick={() => setToggle(!toggle)}
              ></i>
            </span>
          </div>
        </Col>
      </Row>
      {toggle && (
        <Row className={`px-mt-25 ${styles.vendorInfoSection}`}>
          <Col md="3">
            <label>{t("admin.pages.vendor.first_name")}</label>
            <span>{contact?.first_name || "-"}</span>
          </Col>
          <Col md="3">
            <label>{t("admin.pages.vendor.last_name")}</label>
            <span>{contact?.last_name || "-"}</span>
          </Col>
          <Col md="3">
            <label>{t("admin.pages.vendor.phone")}</label>
            <span>{contact?.phone || "-"}</span>
          </Col>
          <Col md="3">
            <label>{t("admin.pages.vendor.contactEmail")}</label>
            <span className={styles.breakWord}>{contact?.email || "-"}</span>
          </Col>
        </Row>
      )}
    </>
  );
};

export default VendorContactInfo;
