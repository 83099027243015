import moment from "moment";
import { formattedAmount } from "../../../services/general/helpers";
import { translate } from "../../../services/general/translation";

const vendorAllAccruals = [
  {
    field: "selectAll",
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    maxWidth: 40,
    minWidth: 40,
    menuTabs: [],
    // sortable: true ,
    // filter: true,
  },
  {
    field: "id",
    headerName: "ID",
    hide: true,
    headerValueGetter: function () {
      return translate("id");
    },
  },
  {
    field: "external_id",
    headerName: "External ID",
    hide: true,
    headerValueGetter: function () {
      return translate("externalId");
    },
  },
  {
    field: "no_push",
    headerName: "Flagged Not To Push",
    hide: true,
    headerValueGetter: function () {
      return translate("no_push");
    },
  },
  {
    field: "version_seq",
    headerName: "Version Seq",
    // displayName:'grid.purchase_order.version_seq',
    hide: true,
    headerValueGetter: function () {
      return translate("version_seq");
    },
  },
  {
    field: "number",
    headerName: "Accrual #",
    // headerCheckboxSelection: true,
    // headerCheckboxSelectionFilteredOnly: true,
    // checkboxSelection: true,
    filter: "agTextColumnFilter",
    cellRenderer: "childCellRouting",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    cellStyle: {
      color: "#FF5C35",
      fontFamily: "acre-regular",
      fontSize: 14,
      fontWeight: "bold",
    },
    headerValueGetter: function () {
      return translate("accrual_number");
    },
  },
  {
    field: "purchaser.name",
    headerName: "Customer",
    filter: "agTextColumnFilter",
    cellRenderer: "childCellRouting",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    cellStyle: {
      color: "#FF5C35",
      fontFamily: "acre-regular",
      fontSize: 14,
      fontWeight: "bold",
    },
    headerValueGetter: function () {
      return translate("customer");
    },
  },
  {
    field: "purchase_order.number",
    headerName: "PO Number",
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("poNumber");
    },
    // hide:true,
  },
  {
    field: "date",
    headerName: "Date",
    filter: "agDateColumnFilter",
    cellRenderer: (data) => {
      return moment(data.value).format("DD/MM/YYYY");
    },
    minWidth: 197,
    filterParams: {
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        // var dateForm = new Date(cellValue)
        // console.log(dateForm)

        // var dateAsString = cellValue.replace(/-/g, "/"); //changing date format /g for global
        //
        // console.log(dateAsString)
        var dateAsString = moment(cellValue).format("DD/MM/YYYY");
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    },
    headerValueGetter: function () {
      return translate("date");
    },
  },
  {
    field: "service_date",
    headerName: "Service Period",
    filter: "agDateColumnFilter",
    hide: true,
    headerValueGetter: function () {
      return translate("service_period");
    },
  },
  {
    field: "start_date",
    headerName: "Date",
    filter: "agDateColumnFilter",
    hide: true,
    headerValueGetter: function () {
      return translate("start_date");
    },
  },
  {
    field: "currency.iso_code",
    headerName: "Currency Code",
    filter: "agDateColumnFilter",
    headerValueGetter: function () {
      return translate("currency_code");
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    cellRenderer: (params) => formattedAmount(params.data?.amount, params.data?.currency?.iso_code),
    // displayName:'Gross Amount',
    filter: "agNumberColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("amount");
    },
    // hide:true
  },
  {
    field: "balance",
    headerName: "Balance",
    cellRenderer: (params) => formattedAmount(params.data?.balance, params.data?.currency?.iso_code),
    filter: "agNumberColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("balance");
    },
    // hide:true
  },
  {
    field: "purchase_order.amount",
    headerName: "Gross PO Balance",
    cellRenderer: (params) =>
      formattedAmount(params.data?.purchase_order?.amount, params.data?.purchase_order?.currency_code),
    filter: "agNumberColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    hide: true,
    headerValueGetter: function () {
      return translate("grossPoBal");
    },
  },
  {
    field: "purchase_order.open_balance",
    headerName: "Open PO Balance",
    cellRenderer: (params) =>
      formattedAmount(params.data?.purchase_order?.open_balance, params.data?.purchase_order?.currency_code),
    filter: "agNumberColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    hide: true,
    headerValueGetter: function () {
      return translate("openPoBal");
    },
  },
  {
    field: "purchase_order.invoice_balance",
    headerName: "Invoice",
    filter: "agNumberColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    hide: true,
    headerValueGetter: function () {
      return translate("invoice");
    },
  },
  {
    headerName: "Accrual Request",
    hide: true,
    headerValueGetter: function () {
      return translate("accrual_request");
    },
  },
  {
    headerName: "Campaign",
    hide: true,
    headerValueGetter: function () {
      return translate("campaign");
    },
  },
  {
    field: "status",
    headerName: "Status",
    filter: "agTextColumnFilter",
    // hide:true
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    floatingFilterComponent: "floatingFilterComponent",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    headerValueGetter: function () {
      return translate("status");
    },
  },
  {
    field: "accrual_type",
    headerName: "Type",
    filter: "agTextColumnFilter",
    hide: true,
    headerValueGetter: function () {
      return translate("type");
    },
  },
  // {
  //     field: 'accrual.number',
  //     headerName: 'Accrual',
  //     filter: "agTextColumnFilter",
  //
  // },
  {
    field: "auto_reverse",
    headerName: "auto_reverse",
    filter: "agTextColumnFilter",
    hide: true,
    headerValueGetter: function () {
      return translate("auto_reverse");
    },
  },
  {
    headerName: "Subsidiary",
    field: "subsidiary.name",
    hide: true,
    headerValueGetter: function () {
      return translate("subsidiary");
    },
  },
  {
    field: "description",
    headerName: "Description",
    hide: true,
    headerValueGetter: function () {
      return translate("description");
    },
  },
  {
    headerName: "PO Department",
    field: "purchase_order.department",
    hide: true,
    headerValueGetter: function () {
      return translate("po_department");
    },
  },
  {
    field: "invoices",
    headerName: "Invoices",
    hide: true,
    headerValueGetter: function () {
      return translate("invoices");
    },
  },
  {
    headerName: "Submitter",
    field: "submitter.name",
    hide: true,
    headerValueGetter: function () {
      return translate("submitter");
    },
  },
  {
    headerName: "Submitter Type",
    field: "submitter.submitter_type",
    hide: true,
    headerValueGetter: function () {
      return translate("submitter_type");
    },
  },
  {
    field: "is_system_error",
    headerName: "System Note",
    hide: true,
    headerValueGetter: function () {
      return translate("system_notes");
    },
  },
  {
    // field: '',
    field: "Actions",
    cellRenderer: "childMessageRenderer",
    sortable: false,
    resizable: false,
    width: 140,
    maxWidth: 140,
    cellRendererParams: {
      viewLink: "/ar/accruals/",
      showMessage: false, // disabled until implement message feature
    },
    // pinned: 'right',
    cellRendererSelector: function (params) {
      return params.data && !params.data.id;
    },
    headerValueGetter: function () {
      return translate("actions");
    },
  },
];
export default vendorAllAccruals;
