import React from "react";
import { Row, NavItem } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { Can } from "services/authorization/authorization";
import styles from "./nav.module.css";
import { DataExtractModuleTypes } from "services/admin/reports/reportTypes";
interface ListSectionNavType {
  sectionRoutes: {
    pageName: string;
    href: string;
    className?: string;
    authorization?: {
      I?: string;
      a?: string;
      permissions?: string[];
    };
  }[];
}

// helper function to convert 'camelCase' and 'Camel & Case' to 'snake_case'
const toSnakeCase = (str: string) => {
  str = str
    .replace(/&| /g, "") // remove & and spaces
    .replace(/(?<!^)[A-Z]/g, (letter) => `_${letter}`); // convert camelCase to snake_case
  return str;
};

export const dataExtractSectionRoutes = (dataExtractModuleType?: DataExtractModuleTypes) => {
  return [
    {
      pageName: "Existing Data Extracts",
      href: dataExtractModuleType
        ? `/reports/data_extracts/${toSnakeCase(dataExtractModuleType).toLowerCase()}`
        : "/reports/data_extracts",
      className: styles.listSectionButtonLeft,
      authorization: {
        permissions: ["listDataExtracts"],
      },
    },
    {
      pageName: "Recurring Data Extracts",
      href: dataExtractModuleType
        ? `/reports/data_extracts/${toSnakeCase(dataExtractModuleType).toLowerCase()}/schedules`
        : "/reports/data_extracts/schedules",
      className:
        dataExtractModuleType === "Invoice" ? styles.inactiveListSectionButtonRight : styles.listSectionButtonRight,
      authorization: {
        permissions: ["listDataExtracts"],
      },
    },
  ];
};

export const standardReportSectionRoutes = [
  {
    pageName: "Existing Reports",
    href: "/reports/list",
    className: styles.listSectionButtonLeft,
    authorization: {
      permissions: ["listReports"],
    },
  },
  {
    pageName: "Recurring Reports",
    href: "/reports/schedules",
    className: styles.listSectionButtonRight,
    authorization: {
      permissions: ["listReports"],
    },
  },
];

export const fileOutboundSectionRoutes = [
  {
    pageName: "Existing File Outbound",
    href: "/reports/file_outbound/list",
    className: styles.listSectionButtonLeft,
    authorization: {
      permissions: ["listReports"],
    },
  },
  {
    pageName: "Recurring File Outbound",
    href: "/reports/file_outbound/schedules",
    className: styles.listSectionButtonRight,
    authorization: {
      permissions: ["listReports"],
    },
  },
];

export const ListSectionNav = ({ sectionRoutes }: ListSectionNavType) => {
  const location = useLocation();
  return (
    <Row className="h-100 mx-0 justify-content-center align-items-center">
      {sectionRoutes.map((route) => {
        const activeClassName = route.href === location.pathname ? styles.listSectionActive : "";
        return (
          <Can
            key={route.pageName}
            I={route.authorization?.I}
            a={route.authorization?.a}
            permissions={route.authorization?.permissions}
          >
            <NavLink
              key={route.href}
              to={route.href}
              className={`px-3 py-2 d-flex align-items-center justify-content-center ${styles.listSectionButton} ${route.className} ${activeClassName}`}
            >
              <NavItem>{route.pageName}</NavItem>
            </NavLink>
          </Can>
        );
      })}
    </Row>
  );
};
