/*eslint-disable no-unused-vars*/
import React from "react";
import { Field } from "redux-form";
import DatePicker from "react-datepicker";
import "../../pages/pages.style.css";
import "./form.style.css";
import moment from "moment";

import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { Mandatory } from "./bootstrapFields";

/**
 * @deprecated The method should not be used, use {@link RenderField} instead
 */
const renderField = ({
  value,
  className,
  type,
  label,
  input,
  disabled,
  isRequired,
  meta: { touched, error, warning },
}) => (
  <div className={className}>
    <div className={"w-100"}>
      {label && (
        <>
          <label>{label}</label>
          <Mandatory required={isRequired ? isRequired : false} />
          <br />
        </>
      )}
      <input {...input} type={type} className={"w-100"} disabled={disabled} required={isRequired} />
      {/*{touched && error && <span>{error}</span>}*/}
      {touched &&
        ((error && <span style={{ color: "#a94442" }}>{error}</span>) ||
          (warning && <span style={{ color: "#a94442" }}>{warning}</span>))}
    </div>
  </div>
);

/**
 * @deprecated The method should not be used
 */
const emailInput = ({ value, className, type, label, input, meta: { touched, error, warning } }) => (
  <div className={className}>
    <label className={"emailLabel"}>{label}</label>
    <br />
    <input {...input} type={type} className={"email"} />
  </div>
);

/**
 * @deprecated The method should not be used
 */
const renderSelectField = ({ value, obj, name, className, type, label, input, meta: { touched, error, warning } }) => (
  <div className={"w-100 reduxForm"}>
    <label>{label}</label>
    <br />

    <Field name={name} component="select">
      <option value="">Select a color...</option>
      {obj.map((list) => (
        <option value={list.id} key={list.id}>
          {list.value}
        </option>
      ))}
    </Field>

    {touched &&
      ((error && <span style={{ color: "#a94442" }}>{error}</span>) ||
        (warning && <span style={{ color: "#a94442" }}>{warning}</span>))}
  </div>
);

/**
 * @deprecated The method should not be used
 */
const renderTextareaField = ({ value, className, type, label, input, style, meta: { touched, error, warning } }) => (
  <div className={className}>
    <div className={"w-100"}>
      <label>{label}</label>
      <br />
      <textarea {...input} className={"w-100"} style={style} />

      {touched &&
        ((error && <span style={{ color: "#a94442" }}>{error}</span>) ||
          (warning && <span style={{ color: "#a94442" }}>{warning}</span>))}
    </div>
  </div>
);

/**
 * @deprecated The method should not be used
 */
const renderFileField = ({ className, fileClass, type, label, input, meta: { touched, error, warning } }) => (
  <div className={className}>
    <div className={"w-100 " + fileClass}>
      {/*<label>{label}</label><br/>*/}

      <div className={"btn btn-primary"}>
        <Field className={"w-100"} name="uploadFile" component="file">
          <p className={"text-white"}>
            {label}
            <div className="file-upload-wrapper upload-file">
              <input type="file" id="input-file-now" multiple className="file-upload" />
            </div>
          </p>
        </Field>
      </div>

      {touched &&
        ((error && <span style={{ color: "#a94442" }}>{error}</span>) ||
          (warning && <span style={{ color: "#a94442" }}>{warning}</span>))}
    </div>
  </div>
);

/**
 * @deprecated The method should not be used
 */
const renderDatePicker = ({
  input: { onChange, value },
  className,
  isRequired,
  label,
  meta: { touched, error, warning },
}) => {
  return (
    <>
      <div className={className + " d-flex align-items-end"}>
        <div className={"w-100"}>
          <label>{label}</label>
          {label && isRequired && <span className="custom-error-msg">*</span>}

          <br />
          <DatePicker
            required={isRequired}
            onChange={onChange}
            // selected must use moment(value).toDate() or risk the date being offset by 1. Known bug with react-datepicker
            // https://github.com/Hacker0x01/react-datepicker/issues/1018#issuecomment-461963696
            selected={!value ? null : moment(value).toDate()}
            // onChange={(date)=> {dt =date}}
            dateFormat="MM/dd/yyyy"
            placeholderText={"MM/dd/yyyy"}
            style={{ cursor: "pointer" }}
            value={!value ? null : new Date(value)}
            // valueField="value"
            className={touched && error ? "requiredField" : "w-100 icon-calender"}
          />
        </div>
      </div>
    </>
  );
};

/**
 * @deprecated The method should not be used
 */
const Customer = (props) => {
  return (
    <div className="col-md-4">
      <label>Customer</label>
      <div className={"reduxForm"}>
        <Field className={"w-100"} name="purchaser_id" component="select" onChange={props.getPOList}>
          <option>select/search a customer in the list...</option>
          {props.purchaserList.map((list) => (
            <option value={list.id}>{list.name}</option>
          ))}
        </Field>
      </div>
    </div>
  );
};

/**
 * @deprecated The method should not be used
 */
const PONumber = (props) => {
  return (
    <div className="col-md-4">
      <label>PO Number</label>
      <div className={"reduxForm"}>
        <Field className={"w-100"} name="purchase_order_id" component="select">
          <option>select/search a PO in the list or leave blank</option>
          {props.POList.map((poList) => (
            <option value={poList.id}>
              {poList.number} Customer: {poList.purchaser.name} amount: {poList.amount} Balance: {poList.open_balance}{" "}
              Date: {poList.date} Status: {poList.status} Description: {poList.description}
            </option>
          ))}
        </Field>
      </div>
    </div>
  );
};

/**
 * @deprecated The method should not be used
 */
const PaymentAccounts = (props) => {
  return (
    <div className="col-md-4">
      <label>{props.label}</label>
      <div className={"reduxForm"}>
        <Field
          className={"w-100 "}
          name="purchase_order_id"
          component="select"
          onChange={(e) => props.changePaymentAccount(e.target.value)}
        >
          {props.paymentLists.map((item) => (
            <option value={item.id}>
              {item.payment_type} {item.account_number}
            </option>
          ))}
          <option value="new">+ New Payment Account</option>
        </Field>
      </div>
    </div>
  );
};

/**
 * @deprecated The method should not be used
 */
const renderDateTimePicker = ({ input: { onChange, value }, showTime }) => (
  <DateTimePicker
    // onChange={onChange}
    format="DD MMM YYYY"
    // time={showTime}
    value={!value ? null : new Date(value)}
  />
);

export {
  renderField,
  Customer,
  PaymentAccounts,
  PONumber,
  renderDatePicker,
  renderDateTimePicker,
  renderTextareaField,
  renderFileField,
  emailInput,
};
