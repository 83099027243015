import { createSlice } from "@reduxjs/toolkit";

interface BulkFileUploadState {
  document_parser: string;
  document_type: string;
  uploadedFile: File | null;
}

const initialState: BulkFileUploadState = {
  document_parser: "custom",
  document_type: "INVOICE",
  uploadedFile: null,
};

const fileUploadSlice = createSlice({
  name: "bulkUploadForm",
  initialState,
  reducers: {
    setUploadedFile: (state, action) => {
      state.uploadedFile = action.payload;
    },
  },
});

export const { setUploadedFile } = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
