import React, { ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { Field } from "redux-form";
import { PaymentFieldType } from "../types";
import { RenderField } from "../../../forms/bootstrapFields";
import { CHANGE_BANK_NAME } from "../../../../actions/actionTypes";

type BankNamePropsType = PaymentFieldType;

const BankName = ({ validations, required, label }: BankNamePropsType) => {
  const dispatch = useDispatch();
  const changeBankName: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({ type: CHANGE_BANK_NAME, payload: e.target.value });
  };

  return (
    <Field
      name="bank_name"
      label={label}
      component={RenderField}
      required={required}
      validate={validations}
      onChange={changeBankName}
    />
  );
};

export default BankName;
