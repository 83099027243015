import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { IDType, getListOptionsType } from "../types/common.type";
import { DocumentTypes } from "./documentTypes";

class DocumentApis {
  static async getAll({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<Array<DocumentTypes.ListItem[]>> = await restApiService.get(
        "documents",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getListPaginated({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse = await restApiService.get("documents/", filter, null, true, null, cache);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getOne(id: IDType) {
    try {
      const response: AxiosResponse<DocumentTypes.Details> = await restApiService.get("documents/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async create(data: DocumentTypes.Add) {
    try {
      const response: AxiosResponse<DocumentTypes.AddResponse> = await restApiService.post("documents", null, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async edit(id: number, data: DocumentTypes.Edit) {
    try {
      const response: AxiosResponse<unknown> = await restApiService.patch("documents/" + id, null, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async editFormData(id: IDType, data: FormData) {
    try {
      const response: AxiosResponse<DocumentTypes.AddResponse> = await restApiService.formWithImage(
        "documents/" + id,
        data,
        "patch",
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async delete(id: IDType) {
    try {
      const response: AxiosResponse<any> = await restApiService.delete("documents/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default DocumentApis;
