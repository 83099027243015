import SubsidiaryPicker from "components/admin/pickers/reduxFormPickers/subsidiaryPicker";
import ContactPicker from "components/admin/pickers/reduxFormPickers/contactPicker";
import { RenderCheckBox, RenderDatePicker, RenderSelect, RenderTextArea } from "components/forms/bootstrapFields";
import PolicyPicker from "components/pickers/reduxFormPicker/policyPicker";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Field, InjectedFormProps, getFormValues, reduxForm, change } from "redux-form";
import expenseReportSvc from "services/admin/expenses/expenseReport/expenseReportSvc";
import { required } from "services/validations/reduxFormValidation";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { Can } from "services/authorization/authorization";
import locationPicker from "components/admin/pickers/reduxFormPickers/locationPicker";
import BusinessUnitPicker from "components/pickers/reduxFormPicker/businessUnitPicker";
import DepartmentPicker from "components/admin/pickers/reduxFormPickers/departmentPicker";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import { useTranslation } from "react-i18next";
import RenderExternalIdField from "components/admin/renderExternalId/renderExternalId";
import { useDispatch } from "react-redux";

export const EXPENSE_REPORT_FORM = "expenseReport";

export interface ExpenseReportFormDataPropsType {
  expenseReport: ExpenseReportTypes.Details;
}
export interface ExpenseReportFormExtraPropsType {
  onCancel: () => void;
}

interface ExpenseReportFormPropsType
  extends ExpenseReportFormExtraPropsType,
    InjectedFormProps<ExpenseReportFormDataPropsType, ExpenseReportFormExtraPropsType> {}

const ExpenseReportFormComponent = ({ handleSubmit, initialValues, onCancel }: ExpenseReportFormPropsType) => {
  const currentUser = useTypedSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const formData: ExpenseReportFormDataPropsType = useTypedSelector((state) =>
    getFormValues(EXPENSE_REPORT_FORM)(state),
  ) as ExpenseReportFormDataPropsType;
  const { t } = useTranslation();

  return (
    <Form name={EXPENSE_REPORT_FORM} noValidate onSubmit={handleSubmit}>
      <Row>
        {initialValues?.expenseReport?.id && (
          <Col md="6">
            <Field
              name="expenseReport.policy_id"
              id="expenseReport.policy_id"
              label={"Policy"}
              component={PolicyPicker}
              disabled={initialValues?.expenseReport?.id}
              validate={[required]}
              required
            />
          </Col>
        )}

        {initialValues?.expenseReport?.id && (
          <Col md="6">
            <Field
              name="expenseReport.employee_id"
              id="expenseReport.employee_id"
              label={"Employee"}
              component={ContactPicker}
              disabled={initialValues?.expenseReport?.id}
            />
          </Col>
        )}

        {currentUser.company?.has_subsidiaries && initialValues?.expenseReport?.id && (
          <Col md="6">
            <Field
              name="expenseReport.subsidiary_id"
              id="expenseReport.subsidiary_id"
              label={"Subsidiary"}
              component={SubsidiaryPicker}
              required
              validate={[required]}
            />
          </Col>
        )}

        <Can I="editstatus" a={"ExpenseReports"}>
          <Col md="6">
            <Field
              name="expenseReport.status"
              id="expenseReport.status"
              label={"Status"}
              component={RenderSelect}
              options={expenseReportSvc.expenseReportStatus}
            />
          </Col>
        </Can>

        <Col md="6">
          <Field name="expenseReport.date" id="expense_date" label={"Date"} component={RenderDatePicker} />
        </Col>

        {currentUser?.company?.has_locations && initialValues?.expenseReport?.id && (
          <Col md="6">
            <Field
              name="expenseReport.location_id"
              id="expense.location_id"
              label={"Location"}
              component={locationPicker}
            />
          </Col>
        )}

        {currentUser?.company?.has_business_units &&
          !currentUser?.company?.expense.hide_business_unit &&
          initialValues?.expenseReport?.id && (
            <Col md="6">
              <Field
                name="expenseReport.business_unit_id"
                id="expense_business_unit_id"
                label={"Business Unit"}
                component={BusinessUnitPicker}
              />
            </Col>
          )}

        {currentUser?.company?.has_departments && initialValues?.expenseReport?.id && (
          <Col md="6">
            <Field
              name="expenseReport.department_id"
              id="expense_department_id"
              label={"Department"}
              component={DepartmentPicker}
            />
          </Col>
        )}

        <Col md="6">
          <Field
            name="expenseReport.description"
            id="expense_description"
            label={"Description"}
            component={RenderTextArea}
            required={initialValues?.expenseReport?.policy?.enforce_er_description}
            validate={initialValues?.expenseReport?.policy?.enforce_er_description ? [required] : []}
          />
        </Col>

        <Col md="6">
          <RenderExternalIdField
            showSyncOn={
              formData?.expenseReport?.external_id &&
              currentUser.company.has_integration &&
              currentUser.company.integration.sync_settings.sync_expense_report
            }
            showSyncOff={Boolean(
              formData?.expenseReport?.external_id &&
                (!currentUser?.company?.has_integration ||
                  !currentUser?.company?.integration?.sync_settings?.sync_expense_report),
            )}
            formName={EXPENSE_REPORT_FORM}
            modalObjFieldName="expenseReport"
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Field
            name="expenseReport.no_push"
            type="checkbox"
            id="expense_no_push"
            label={"Do Not push to ERP"}
            component={RenderCheckBox}
            onChange={(value: any) => {
              if (value === true) {
                dispatch(change(EXPENSE_REPORT_FORM, "expenseReport.force_push", false));
              }
            }}
          />
        </Col>

        {currentUser?.company?.has_integration &&
          currentUser?.company?.integration?.sync_settings?.sync_expense_report && (
            <Col md="6">
              <Field
                name="expenseReport.force_push"
                type="checkbox"
                id="expense_force_push"
                label="Force Sync with ERP"
                component={RenderCheckBox}
              />
            </Col>
          )}
      </Row>
      <Row>
        <Col md="12" className="d-flex justify-content-end">
          <Button variant="secondary" className="px-mr-16" onClick={onCancel}>
            {t("cancel")}
          </Button>
          <Button type="submit">{t("update")}</Button>
        </Col>
      </Row>
    </Form>
  );
};

const FormExpenseReport = reduxForm<ExpenseReportFormDataPropsType, ExpenseReportFormExtraPropsType>({
  form: EXPENSE_REPORT_FORM,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(ExpenseReportFormComponent);

export default FormExpenseReport;
