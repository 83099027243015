import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Field, InjectedFormProps, getFormValues, reduxForm } from "redux-form";
import { required } from "services/validations/reduxFormValidation";
import { RenderFieldNumber } from "components/forms/bootstrapFields";
import styles from "./payExpenseModal.module.css";
import { useTypedSelector } from "reducers";

export const PAY_EXPENSE_FORM_NAME = "messageReplayFormName";
export interface PayExpenseFormDataPropsType {
  pay_amount: number;
  payment_date: string | undefined;
  currency: string | undefined;
}

export interface PayExpenseFormExtraPropsType {}

interface PayExpenseFormPropsType
  extends PayExpenseFormExtraPropsType,
    InjectedFormProps<PayExpenseFormDataPropsType, PayExpenseFormExtraPropsType> {}

const PayExpenseFormComponent = ({ handleSubmit }: PayExpenseFormPropsType) => {
  const payExpenseFormState = useTypedSelector((state) =>
    getFormValues(PAY_EXPENSE_FORM_NAME)(state),
  ) as PayExpenseFormDataPropsType | null;

  return (
    <Form name={PAY_EXPENSE_FORM_NAME} id={PAY_EXPENSE_FORM_NAME} noValidate onSubmit={handleSubmit}>
      <Row>
        <Col md="5" className={`d-flex px-mb-15`}>
          <span>Payment Amount</span>
        </Col>
      </Row>
      <Row>
        <Col md="5" className="d-flex">
          <div className={styles.inputGroupAddon}>{payExpenseFormState?.currency}</div>
          <Field
            name="pay_amount"
            id="pay_amount"
            placeholder="Payment Amount"
            component={RenderFieldNumber}
            validate={[required]}
            required
          />
        </Col>
      </Row>
    </Form>
  );
};
const PayExpenseForm = reduxForm<PayExpenseFormDataPropsType, PayExpenseFormExtraPropsType>({
  form: PAY_EXPENSE_FORM_NAME,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(PayExpenseFormComponent);
export default PayExpenseForm;
