import React from "react";
import DropdownFilter from "../reportFilters/dropdown";
import DateFilter from "../reportFilters/date";
import InputFilter from "../reportFilters/input";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";

const PaymentRun = ({ scheduled }: { scheduled?: boolean }) => {
  const payment_run_status_options = [
    { value: "", label: "ALL" },
    { value: "SUBMITTED", label: "SUBMITTED" },
    { value: "NEW", label: "NEW" },
    { value: "PENDING", label: "PENDING" },
    { value: "APPROVED", label: "APPROVED" },
    { value: "OPEN", label: "OPEN" },
    { value: "CLOSED", label: "CLOSED" },
    { value: "REJECTED", label: "REJECTED" },
  ];

  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    } else {
      return (
        <>
          <DateFilter name={"Start Date"} code={"start_date"} options={[]} isRequired />
          <DateFilter name={"End Date"} code={"end_date"} options={[]} isRequired />
        </>
      );
    }
  };

  return (
    <div>
      {renderDateFilter()}
      <InputFilter name={"Batch Number"} code={"batch_number"} options={[]} />
      <DropdownFilter name={"Status"} code={"status"} options={payment_run_status_options} />
    </div>
  );
};

export default PaymentRun;
