import _ from "lodash";

class ChangeOrderService {
  /**
   *
   * @param oldItems
   * @param newItems
   * @returns
   *
   *  oldItems -> this are from purchase order itself so it's type is different than new Item
   *  newItems ->  this are from change order.object_change
   *
   *  OI <- old item type,
   *  OIA <-  old item array type
   *
   *  NI <- new item type
   *  NIA <- new item array type
   *
   *  passing this type as generics so the when function return the value it should have proper type what we define in generic
   */
  getDifferentiatedCOObjects = <OI, OIA, NI, NIA>(
    oldItems: OIA | undefined,
    newItems: NIA | undefined,
  ):
    | {
        newItem: NI;
        change_type: string;
        oldItem?: OI;
      }[]
    | undefined => {
    if (_.isArray(oldItems) && _.isArray(newItems)) {
      const differentiatedItems = newItems.map((newItem) => {
        const oldItem = oldItems.find((oldItem) => oldItem.id === newItem.id);
        // check if item is destroyed
        if (newItem._destroy) {
          return { newItem, oldItem, change_type: "DELETED" };
        }
        // check if item is newadded, if item is present in new item and not in old items
        else if (!newItem.id) {
          return { newItem, change_type: "ADDED" };
        } else {
          // if only edit then find then change properties
          return { newItem, oldItem, change_type: "EDITED" };
        }
      });
      return differentiatedItems;
    }
  };

  fieldIsUpdated = (newItem: any, oldItem: any, field: string) => {
    if (!oldItem) return true;
    return newItem && !newItem._destroy && newItem[field] && newItem[field] !== oldItem[field];
  };

  disableEdits = (status: string | null) => {
    return status && ["PENDING"].indexOf(status) === -1;
  };
}

const changeOrderService = new ChangeOrderService();
export default changeOrderService;
