import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { CreateNotification, NotificationType } from "../../services/general/notifications";
import { withTranslation } from "react-i18next";
import ChatMessage from "./chatMessage";

import styles from "./chat.module.css";

class ChatBubble extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    let flexDirection = this.props.message.mine ? "align-items-end" : "align-items-start";
    let color = this.props.message.mine ? "purpleColor" : styles.theirColor;
    return (
      <div className={"d-flex flex-column mb-3 " + flexDirection}>
        <p className={`${styles.name}`}>{this.props.message.userName}</p>
        <p className={`${styles.date} text-right `}>{new Date(this.props.message.date).toDateString()}</p>
        <div className="d-flex flex-column">
          <p className={`${color} ${styles.bubbleText} mb-0`}>{this.props.message.message}</p>

          {/* <p className={`${styles.date} text-right `}>{new Date(this.props.message.date).toDateString()}</p> */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ChatBubble);
