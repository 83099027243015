import { RenderCheckBox } from "../../../../../../components/forms/bootstrapFields";
import { CompanyDefaultsTypes } from "../../settingsTypes";

const paymentSettings: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.payment.allow_fx_rate",
    tooltip: {
      show: true,
      title: "It is used to show FX Rate field on payment once active.",
      placement: "top",
    },
    label: "To Show FX Rate Field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.international_payment_allow_market_quote",
    tooltip: {
      show: true,
      title:
        "Allow market rate quote at the time of payment where confirmation is not required, such as bulk approval/payment ",
      placement: "top",
    },
    label: "International Payment - Allows Market Quote",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.payment.allow_usd_amount",
    tooltip: {
      show: true,
      title: "It is used to show USD Amount field on payment once active.",
      placement: "top",
    },
    label: "To Show USD Amount Field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.payment.process_file_without_vendor_address",
    tooltip: {
      show: true,
      title: "This setting allows payment files to process without a vendor address for existing vendors.",
      placement: "top",
    },
    label: "Process Payment Files Submitted Without a Vendor Address",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
];

export default paymentSettings;
