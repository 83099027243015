import i18n from "i18next";

export class CustomLabelSvc {
  // i18n.t is the translation function
  // translated strings are sourced from the /localization/translations/english.json file
  static listOfStandardFields = [
    { value: i18n.t("admin.pages.invoice.name"), label: i18n.t("admin.pages.invoice.name") },
    { value: i18n.t("admin.pages.invoice.amount"), label: i18n.t("admin.pages.invoice.amount") },
    { value: i18n.t("admin.pages.invoice.budget"), label: i18n.t("admin.pages.invoice.budget") },
    { value: i18n.t("admin.pages.invoice.businessUnit"), label: i18n.t("admin.pages.invoice.businessUnit") },
    { value: i18n.t("admin.pages.invoice.currencyCode"), label: i18n.t("admin.pages.invoice.currencyCode") },
    { value: i18n.t("admin.pages.invoice.description"), label: i18n.t("admin.pages.invoice.description") },
    { value: i18n.t("admin.pages.invoice.department"), label: i18n.t("admin.pages.invoice.department") },
    { value: i18n.t("admin.pages.users.externalId"), label: i18n.t("admin.pages.users.externalId") },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.itemName"),
      label: i18n.t("admin.pages.invoice.invoiceItems.itemName"),
    },
    { value: i18n.t("admin.pages.invoice.location"), label: i18n.t("admin.pages.invoice.location") },
    { value: i18n.t("admin.pages.invoice.number"), label: i18n.t("admin.pages.invoice.number") },
    { value: i18n.t("admin.pages.invoice.paymentTerms"), label: i18n.t("admin.pages.invoice.paymentTerms") },
    { value: i18n.t("admin.pages.invoice.postingPeriod"), label: i18n.t("admin.pages.invoice.postingPeriod") },
    { value: i18n.t("admin.pages.invoice.serviceStartDate"), label: i18n.t("admin.pages.invoice.serviceStartDate") },
    { value: i18n.t("admin.pages.invoice.serviceEndDate"), label: i18n.t("admin.pages.invoice.serviceEndDate") },
    { value: i18n.t("admin.pages.invoice.status"), label: i18n.t("admin.pages.invoice.status") },
    { value: i18n.t("admin.pages.invoice.subsidiary"), label: i18n.t("admin.pages.invoice.subsidiary") },
    { value: i18n.t("admin.pages.invoice.frequency"), label: i18n.t("admin.pages.invoice.frequency") },
    { value: i18n.t("admin.pages.invoice.priorPeriod"), label: i18n.t("admin.pages.invoice.priorPeriod") },
    { value: i18n.t("admin.pages.invoice.apPaymentType"), label: i18n.t("admin.pages.invoice.apPaymentType") },
    {
      value: i18n.t("admin.pages.invoice.customNote"),
      label: i18n.t("admin.pages.invoice.customNote"),
    },
    {
      value: i18n.t("admin.pages.invoice.default_payment_method_type_payment_type"),
      label: i18n.t("admin.pages.invoice.default_payment_method_type_payment_type"),
    },
    {
      value: i18n.t("admin.pages.invoice.dueDate"),
      label: i18n.t("admin.pages.invoice.dueDate"),
    },
    {
      value: i18n.t("admin.pages.invoice.submitDate"),
      label: i18n.t("admin.pages.invoice.submitDate"),
    },
    {
      value: i18n.t("admin.pages.invoice.discountExpireDate"),
      label: i18n.t("admin.pages.invoice.discountExpireDate"),
    },
    {
      value: i18n.t("admin.pages.invoice.referenceNumber"),
      label: i18n.t("admin.pages.invoice.referenceNumber"),
    },
    {
      value: i18n.t("admin.pages.invoice.postedDate"),
      label: i18n.t("admin.pages.invoice.postedDate"),
    },
    {
      value: i18n.t("admin.pages.invoice.fiscalPeriod"),
      label: i18n.t("admin.pages.invoice.fiscalPeriod"),
    },
    {
      value: i18n.t("admin.pages.invoice.customerAccountNumber"),
      label: i18n.t("admin.pages.invoice.customerAccountNumber"),
    },
    {
      value: i18n.t("admin.pages.expenseItem.labels.taxCode"),
      label: i18n.t("admin.pages.expenseItem.labels.taxCode"),
    },
    {
      value: i18n.t("admin.pages.invoice.billDistributionSchedule"),
      label: i18n.t("admin.pages.invoice.billDistributionSchedule"),
    },
    {
      value: i18n.t("admin.pages.invoice.companyRequestor"),
      label: i18n.t("admin.pages.invoice.companyRequestor"),
    },
    {
      value: i18n.t("admin.pages.invoice.externalPaymentDate"),
      label: i18n.t("admin.pages.invoice.externalPaymentDate"),
    },
    {
      value: i18n.t("admin.pages.invoice.externalPaymentMemo"),
      label: i18n.t("admin.pages.invoice.externalPaymentMemo"),
    },
    {
      value: i18n.t("admin.pages.invoice.attachments"),
      label: i18n.t("admin.pages.invoice.attachments"),
    },
    {
      value: i18n.t("admin.pages.invoice.attachedFiles"),
      label: i18n.t("admin.pages.invoice.attachedFiles"),
    },
    {
      value: i18n.t("admin.pages.invoice.amountsAre"),
      label: i18n.t("admin.pages.invoice.amountsAre"),
    },
    { value: i18n.t("admin.pages.invoice.discountAmount"), label: i18n.t("admin.pages.invoice.discountAmount") },
    { value: i18n.t("admin.pages.invoice.invoiceDate"), label: i18n.t("admin.pages.invoice.invoiceDate") },
    { value: i18n.t("admin.pages.invoice.purchaseOrder"), label: i18n.t("admin.pages.invoice.purchaseOrder") },
    { value: i18n.t("admin.pages.purchaseOrder.newPoNumber"), label: i18n.t("admin.pages.purchaseOrder.newPoNumber") },
    { value: i18n.t("admin.pages.purchaseOrder.poSubtype"), label: i18n.t("admin.pages.purchaseOrder.poSubtype") },
    { value: i18n.t("admin.pages.purchaseOrder.poType"), label: i18n.t("admin.pages.purchaseOrder.poType") },
    {
      value: i18n.t("admin.pages.purchaseOrder.purchaseOrderType"),
      label: i18n.t("admin.pages.purchaseOrder.purchaseOrderType"),
    },
    {
      value: i18n.t("admin.pages.purchaseOrder.vendorSupplier"),
      label: i18n.t("admin.pages.purchaseOrder.vendorSupplier"),
    },
    { value: i18n.t("admin.pages.invoice.vendor"), label: i18n.t("admin.pages.invoice.vendor") },
    { value: i18n.t("admin.pages.invoice.vendorLocation"), label: i18n.t("admin.pages.invoice.vendorLocation") },
    { value: i18n.t("admin.pages.vendor.vendorId"), label: i18n.t("admin.pages.vendor.vendorId") },
    { value: i18n.t("admin.pages.vendor.email"), label: i18n.t("admin.pages.vendor.email") },
    { value: i18n.t("admin.pages.vendor.legalName"), label: i18n.t("admin.pages.vendor.legalName") },
    { value: i18n.t("admin.pages.vendor.masterId"), label: i18n.t("admin.pages.vendor.masterId") },
    { value: i18n.t("admin.pages.vendor.phone"), label: i18n.t("admin.pages.vendor.phone") },
    { value: i18n.t("admin.pages.vendor.vendorDetails"), label: i18n.t("admin.pages.vendor.vendorDetails") },
    { value: i18n.t("admin.pages.vendor.vendorPhone"), label: i18n.t("admin.pages.vendor.vendorPhone") },
    { value: i18n.t("admin.pages.vendor.vendorType"), label: i18n.t("admin.pages.vendor.vendorType") },
    { value: i18n.t("admin.pages.vendor.website"), label: i18n.t("admin.pages.vendor.website") },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.applyUseTax"),
      label: i18n.t("admin.pages.invoice.invoiceItems.applyUseTax"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.taxTotal"),
      label: i18n.t("admin.pages.invoice.invoiceItems.taxTotal"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.expTotal"),
      label: i18n.t("admin.pages.invoice.invoiceItems.expTotal"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.accTotal"),
      label: i18n.t("admin.pages.invoice.invoiceItems.accTotal"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.invAmount"),
      label: i18n.t("admin.pages.invoice.invoiceItems.invAmount"),
    },
    { value: i18n.t("admin.pages.invoice.invoiceItems.unit"), label: i18n.t("admin.pages.invoice.invoiceItems.unit") },
    { value: i18n.t("admin.pages.invoice.invoiceItems.qty"), label: i18n.t("admin.pages.invoice.invoiceItems.qty") },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.unitPrice"),
      label: i18n.t("admin.pages.invoice.invoiceItems.unitPrice"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.subTotal"),
      label: i18n.t("admin.pages.invoice.invoiceItems.subTotal"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.subAmount"),
      label: i18n.t("admin.pages.invoice.invoiceItems.subAmount"),
    },
    { value: i18n.t("admin.pages.invoice.invoiceItems.tax"), label: i18n.t("admin.pages.invoice.invoiceItems.tax") },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.total"),
      label: i18n.t("admin.pages.invoice.invoiceItems.total"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.whTaxCode"),
      label: i18n.t("admin.pages.invoice.invoiceItems.whTaxCode"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.whTaxAmount"),
      label: i18n.t("admin.pages.invoice.invoiceItems.whTaxAmount"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.rebate"),
      label: i18n.t("admin.pages.invoice.invoiceItems.rebate"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.rebateAmount"),
      label: i18n.t("admin.pages.invoice.invoiceItems.rebateAmount"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.category"),
      label: i18n.t("admin.pages.invoice.invoiceItems.category"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.grant"),
      label: i18n.t("admin.pages.invoice.invoiceItems.grant"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.forBusinessUnit"),
      label: i18n.t("admin.pages.invoice.invoiceItems.forBusinessUnit"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.forLocation"),
      label: i18n.t("admin.pages.invoice.invoiceItems.forLocation"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.forSubsidiary"),
      label: i18n.t("admin.pages.invoice.invoiceItems.forSubsidiary"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.forProject"),
      label: i18n.t("admin.pages.invoice.invoiceItems.forProject"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.secondaryCategory"),
      label: i18n.t("admin.pages.invoice.invoiceItems.secondaryCategory"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.territories"),
      label: i18n.t("admin.pages.invoice.invoiceItems.territories"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.customer"),
      label: i18n.t("admin.pages.invoice.invoiceItems.customer"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.employee"),
      label: i18n.t("admin.pages.invoice.invoiceItems.employee"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.type"),
      label: i18n.t("admin.pages.invoice.invoiceItems.type"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.companyEvent"),
      label: i18n.t("admin.pages.invoice.invoiceItems.companyEvent"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.vineyardCode"),
      label: i18n.t("admin.pages.invoice.invoiceItems.vineyardCode"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.eventCode"),
      label: i18n.t("admin.pages.invoice.invoiceItems.eventCode"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.1099Eligible"),
      label: i18n.t("admin.pages.invoice.invoiceItems.1099Eligible"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.vendorPartNumber"),
      label: i18n.t("admin.pages.invoice.invoiceItems.vendorPartNumber"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.warehouse"),
      label: i18n.t("admin.pages.invoice.invoiceItems.warehouse"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.interCompany"),
      label: i18n.t("admin.pages.invoice.invoiceItems.interCompany"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.incSubtotalChargesUseTax"),
      label: i18n.t("admin.pages.invoice.invoiceItems.incSubtotalChargesUseTax"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.dayOfWeek"),
      label: i18n.t("admin.pages.invoice.invoiceItems.dayOfWeek"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.dayOfMonth"),
      label: i18n.t("admin.pages.invoice.invoiceItems.dayOfMonth"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.account"),
      label: i18n.t("admin.pages.invoice.invoiceItems.account"),
    },
    {
      value: i18n.t("admin.pages.invoice.invoiceItems.project"),
      label: i18n.t("admin.pages.invoice.invoiceItems.project"),
    },
    {
      value: i18n.t("admin.pages.invoice.expenseItems.sponsorship"),
      label: i18n.t("admin.pages.invoice.expenseItems.sponsorship"),
    },
    {
      value: i18n.t("admin.pages.invoice.expenseItems.productItem"),
      label: i18n.t("admin.pages.invoice.expenseItems.productItem"),
    },
    {
      value: i18n.t("admin.pages.invoice.expenseItems.counterParty"),
      label: i18n.t("admin.pages.invoice.expenseItems.counterParty"),
    },
    {
      value: i18n.t("admin.pages.invoice.expenseItems.useTaxAccount"),
      label: i18n.t("admin.pages.invoice.expenseItems.useTaxAccount"),
    },
    {
      value: i18n.t("admin.pages.invoice.expenseItems.useTax"),
      label: i18n.t("admin.pages.invoice.expenseItems.useTax"),
    },
    {
      value: i18n.t("admin.pages.invoice.expenseItems.percent"),
      label: i18n.t("admin.pages.invoice.expenseItems.percent"),
    },
    {
      value: i18n.t("admin.pages.invoice.expenseItems.memo"),
      label: i18n.t("admin.pages.invoice.expenseItems.memo"),
    },
    {
      value: i18n.t("admin.pages.invoice.accounts.useDefault"),
      label: i18n.t("admin.pages.invoice.accounts.useDefault"),
    },
    {
      value: i18n.t("admin.pages.invoice.accounts.useSpecific"),
      label: i18n.t("admin.pages.invoice.accounts.useSpecific"),
    },
    {
      value: i18n.t("admin.pages.invoice.accounts.accrualMatch"),
      label: i18n.t("admin.pages.invoice.accounts.accrualMatch"),
    },
    {
      value: i18n.t("admin.pages.invoice.accounts.billDistExc"),
      label: i18n.t("admin.pages.invoice.accounts.billDistExc"),
    },
    {
      value: i18n.t("admin.pages.invoice.accounts.useTaxOnSubT"),
      label: i18n.t("admin.pages.invoice.accounts.useTaxOnSubT"),
    },
    {
      value: i18n.t("admin.pages.invoice.accounts.applyUseTaxOnSubT"),
      label: i18n.t("admin.pages.invoice.accounts.applyUseTaxOnSubT"),
    },
    {
      value: i18n.t("details.serviceDate"),
      label: i18n.t("details.serviceDate"),
    },
    // add more fields here as needed
  ].sort((a, b) => a.label.localeCompare(b.label));

  // function for setting custom label in <Field> tags on front end forms
  // *** IMPORTANT : please update the translation file if field label does not have one and use that ***
  // use example for label: label = {CustomLabelsApi.setCustomLabel(customLabels, t("admin.pages.invoice.amount"))}
  // use example for plural label: lable = {CustomLabelsApi.setCustomLabel(customLabels, t("admin.pages.invoice.budgets"), true)}
  // use example for required (feature-flagged): required = {CustomLabelsApi.setCustomLabel(customLabels, t("admin.pages.invoice.reference_number"), currentUser?.company?.invoice?.is_required_reference_number)}
  static setCustomLabel = (
    customLabels: { [key: string]: string[] } | undefined,
    fieldName: string,
    pluralizedFieldName?: string,
    alternativeFieldName?: string,
    required?: boolean,
  ) => {
    const customLabel = customLabels?.[fieldName];
    const label = customLabel ? customLabel?.[0] : alternativeFieldName ? alternativeFieldName : fieldName;
    const pluralizedLabel = customLabel ? customLabel?.[1] : pluralizedFieldName;
    const customLabelRequired = customLabel?.[2];

    if (required !== undefined) {
      return customLabel ? customLabelRequired : required;
    } else {
      return pluralizedFieldName ? pluralizedLabel : label;
    }
  };
}
