import { AppDispatch, RootState } from "..";
import { SET_COMPANY, SET_PURCHASERS, SET_VENDOR_DOCUMENT_LINKS } from "../../actions/actionTypes";
import { CompanyDetailType } from "../../services/common/company/companyTypes";
import { VendorDocumentLinkTypes } from "../../services/common/documentVendorLinks/vendorDocumentLinkTypes";
import PurchaserApis from "../../services/vp/purchaser/purchaserApis";
import VendorDocumentLinkApis from "../../services/common/documentVendorLinks/vendorDocumentLinkApis";
import { PurchaserListType } from "../../services/vp/purchaser/purchaserTypes";

const initialState: {
  company: CompanyDetailType | undefined;
  purchasers: PurchaserListType[];
  vendorDocumentLinks: VendorDocumentLinkTypes.ListItem[];
} = {
  company: undefined,
  purchasers: [],
  vendorDocumentLinks: [],
};

const vpCompanyProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_COMPANY:
      return { ...state, company: action.payload as CompanyDetailType };
    case SET_PURCHASERS:
      return { ...state, purchasers: action.payload as PurchaserListType[] };
    case SET_VENDOR_DOCUMENT_LINKS:
      return { ...state, vendorDocumentLinks: action.payload as VendorDocumentLinkTypes.ListItem[] };
    default:
      return state;
  }
};

export default vpCompanyProfileReducer;

// action dispatchers
export const setCompany = (company: CompanyDetailType) => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch({ type: SET_COMPANY, payload: company });
};

export const setPurchasers =
  (purchasers: PurchaserListType[]) => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: SET_PURCHASERS, payload: purchasers });
  };

export const setVendorDocumentLink =
  (vendorDocumentLinks: VendorDocumentLinkTypes.ListItem[]) => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch({ type: SET_VENDOR_DOCUMENT_LINKS, payload: vendorDocumentLinks });
  };

export const getPurchasers = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const result = await PurchaserApis.getPurchaserList();
    dispatch(setPurchasers(result));
  } catch (error) {}
};

export const getVendorDocumentLinks = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const result = await VendorDocumentLinkApis.getVendorDocumentLinks();
    dispatch(setVendorDocumentLink(result));
  } catch (error) {}
};
