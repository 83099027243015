export const ContactValidations = [
  {
    field: "contact_first_name",
    validate: { required: true, minlength: "0" },
    label: "Contact First Name",
    validMessage: "Success",
    errorMessage: "Sorry! First Name  is required",
  },
  {
    field: "contact_last_name",
    validate: { required: true, minlength: "0" },
    label: "Contact Last Name",
    validMessage: "Success",
    errorMessage: "Sorry! Last Name  is required",
  },
  {
    field: "contact_email",
    validate: {
      required: true,
      minlength: "0",
      pattern:
        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    },
    label: "Contact Email",
    validMessage: "Success",
    errorMessage: "Sorry! Email  is required, example: 'abc@xyz.com' ",
  },
  {
    field: "phoneNumber",
    validate: { required: true, minlength: "0" },
    label: "Contact Phone",
    validMessage: "Success",
    errorMessage: "Sorry! Phone Number  is required",
  },
];

export const AddressValidations = [
  {
    paymentType: "ach",
    validations: [
      { field: "address1", validate: { required: false }, label: "Bank Address Line", validMessage: "Success" },
      { field: "city", validate: { required: false }, label: "City", validMessage: "Success" },
      { field: "state", validate: { required: false }, label: "State/Province", validMessage: "Success" },
      { field: "zipcode", validate: { required: false }, label: "Zip Code/Postal Code", validMessage: "Success" },
    ],
  },
  {
    paymentType: "international",
    validations: [
      {
        field: "address1",
        validate: { required: true, minlength: "0" },
        label: "Bank Address Line",
        validMessage: "Success",
        errorMessage: "Sorry! Address Line  is required",
      },
      {
        field: "city",
        validate: { required: true, minlength: "0" },
        label: "City",
        validMessage: "Success",
        errorMessage: "Sorry! City  is required",
      },
      {
        field: "state",
        validate: { required: false },
        label: "State/Province",
        validMessage: "Success",
        errorMessage: "Sorry! State/Province  is required",
      },
      {
        field: "zipcode",
        validate: { required: true, minlength: "0" },
        label: "Zip Code/Postal Code",
        validMessage: "Success",
        errorMessage: "Sorry! Zip Code/Postal Code  is required",
      },
    ],
  },
  {
    paymentType: "payoneer",
    validations: [
      {
        field: "address1",
        validate: { required: true, minlength: "0" },
        label: "Bank Address Line ",
        validMessage: "Success",
        errorMessage: "Sorry! Address Line  is required",
      },
      {
        field: "city",
        validate: { required: true, minlength: "0" },
        label: "City",
        validMessage: "Success",
        errorMessage: "Sorry! City  is required",
      },
      {
        field: "state",
        validate: { required: true, minlength: "0" },
        label: "State/Province ",
        validMessage: "Success",
        errorMessage: "Sorry! State/Province  is required",
      },
      {
        field: "zipcode",
        validate: { required: true, minlength: "0" },
        label: "Zip Code/Postal Code",
        validMessage: "Success",
        errorMessage: "Sorry! Zip Code/Postal Code  is required",
      },
    ],
  },
  {
    paymentType: "wire",
    validations: [
      {
        field: "address1",
        validate: { required: false, minlength: "0" },
        label: "Bank Address Line",
        validMessage: "Success",
        errorMessage: "Sorry! Address Line  is required",
      },
      {
        field: "city",
        validate: { required: false, minlength: "0" },
        label: "City",
        validMessage: "Success",
        errorMessage: "Sorry! City  is required",
      },
      {
        field: "state",
        validate: { required: false },
        label: "State/Province",
        validMessage: "Success",
        errorMessage: "Sorry! State/Province  is required",
      },
      {
        field: "zipcode",
        validate: { required: false, minlength: "0" },
        label: "Zip Code/Postal Code",
        validMessage: "Success",
        errorMessage: "Sorry! Zip Code/Postal Code  is required",
      },
    ],
  },
  {
    paymentType: "can-eft",
    validations: [
      { field: "address1", validate: { required: false }, label: "Bank Address Line", validMessage: "Success" },
      { field: "city", validate: { required: false }, label: "City", validMessage: "Success" },
      { field: "state", validate: { required: false }, label: "State/Province", validMessage: "Success" },
      { field: "zipcode", validate: { required: false }, label: "Zip Code/Postal Code", validMessage: "Success" },
    ],
  },
  {
    paymentType: "nz-eft",
    validations: [
      { field: "address1", validate: { required: false }, label: "Bank Address Line", validMessage: "Success" },
      { field: "city", validate: { required: false }, label: "City", validMessage: "Success" },
      { field: "state", validate: { required: false }, label: "State/Province", validMessage: "Success" },
      { field: "zipcode", validate: { required: false }, label: "Zip Code/Postal Code", validMessage: "Success" },
    ],
  },
  {
    paymentType: "aus-pay-net",
    validations: [
      { field: "address1", validate: { required: false }, label: "Bank Address Line", validMessage: "Success" },
      { field: "city", validate: { required: false }, label: "City", validMessage: "Success" },
      { field: "state", validate: { required: false }, label: "State/Province", validMessage: "Success" },
      { field: "zipcode", validate: { required: false }, label: "Zip Code/Postal Code", validMessage: "Success" },
    ],
  },
  {
    paymentType: "fed-wire",
    validations: [
      { field: "address1", validate: { required: false }, label: "Bank Address Line", validMessage: "Success" },
      { field: "city", validate: { required: false }, label: "City", validMessage: "Success" },
      { field: "state", validate: { required: false }, label: "State/Province", validMessage: "Success" },
      { field: "zipcode", validate: { required: false }, label: "Zip Code/Postal Code", validMessage: "Success" },
    ],
  },
  {
    paymentType: "sg-eft",
    validations: [
      { field: "address1", validate: { required: false }, label: "Bank Address Line", validMessage: "Success" },
      { field: "city", validate: { required: false }, label: "City", validMessage: "Success" },
      { field: "state", validate: { required: false }, label: "State/Province", validMessage: "Success" },
      { field: "zipcode", validate: { required: false }, label: "Zip Code/Postal Code", validMessage: "Success" },
    ],
  },
];
