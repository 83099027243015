import { AxiosResponse } from "axios";
import { CustomLabel } from "pages/admin/administration/customLabels/customLabels";
import { getListOptionsType } from "services/common/types/common.type";
import { restApiService } from "../../../providers/restApi";

class CustomLabelsApi {
  static async index({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<CustomLabel[]> = await restApiService.get(
        "custom_labels",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async upsert(customLabel: CustomLabel) {
    if (customLabel.id && customLabel.id > 0) {
      try {
        const response: AxiosResponse<CustomLabel> = await restApiService.patch(
          `custom_labels/${customLabel.id}`,
          null,
          customLabel,
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    } else {
      try {
        const response: AxiosResponse<CustomLabel> = await restApiService.post("custom_labels", null, customLabel);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }

  static async bulkUpsert(customLabels: CustomLabel[]) {
    if (customLabels && customLabels.length > 0) {
      try {
        const params = { custom_label: { custom_labels_attributes: customLabels } };
        const response: AxiosResponse<CustomLabel[]> = await restApiService.put(
          "custom_labels/bulk_upsert",
          null,
          params,
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }

  static async delete(customLabel: CustomLabel) {
    try {
      const response: AxiosResponse<CustomLabel> = await restApiService.delete(`custom_labels/${customLabel.id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default CustomLabelsApi;
