import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { WrappedFieldProps, change } from "redux-form";
import { CategoryTypes } from "services/admin/category/CategoryTypes";
import commonService from "services/common/commonSvc";
import { IDType } from "services/common/types/common.type";
import { onBlurSingle, parseForSelect } from "services/general/helpers";
import { Mandatory } from "wombatifier/components/pickers/mandatory";
import Styles from "./../../pickers/reduxFormPickers/override.module.css";
import { CommonTypes } from "./../../../../services/common/types/commonTypes";
import useShowSelectMenu from "components/admin/hooks/useShowSelectMenu";
import { useRef } from "react";

export interface ExpenseItemCategoryPickerPropsType extends WrappedFieldProps, CommonTypes.TSelectMenu {
  modelData?: any;
  parentObjData?: any;
  className?: string;
  labelClassName?: string;
  label?: string;
  tooltip?: string;
  required?: boolean;
  instanceId?: string;
  placeholder?: string;
  disabled?: boolean;
  onSelectCallback?: (value: string) => void;
  callBackObj?: (value: CategoryTypes.Item) => void;
  formName?: string;
  formLocation?: string; // local of js object where gone save changes
  policyId?: IDType;
  showAsteriskWithoutLabel?: boolean;
}

// this component is specific to expense item form only
const ExpenseItemCategoryPicker = (props: ExpenseItemCategoryPickerPropsType) => {
  const isMounted = useIsMounted();
  const [options, setOptions] = useState([]);
  const [inactiveOptions, setInactiveOptions] = useState<any[]>([]);
  const {
    input,
    meta: { error, touched },
    className,
    disabled,
    instanceId = "ExpenseItemCategoryPicker",
    label,
    labelClassName,
    modelData,
    onSelectCallback,
    parentObjData,
    placeholder,
    required,
    tooltip,
    policyId,
    callBackObj,
    formName,
    formLocation,
    showAsteriskWithoutLabel,
    menuAutoFixed,
    parentDivId,
    parentDivVariance,
  } = props;
  const dispatch = useDispatch();
  const [inactivePopulated, setInactivePopulated] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useShowSelectMenu({
    setIsMenuOpen,
    selectRef,
    parentDivId,
    variance: parentDivVariance,
  });

  const findSelected = () => {
    let selectedValues = null; //set to null because pickers will have blank default value instead of "select... placeholder"
    let obj = _.find(options, (option: any) => option?.value === input.value);

    if (!obj) {
      // check if record is present is inactive
      obj = _.find(inactiveOptions, (option: any) => option?.value === input.value);
    }
    if (obj) selectedValues = obj;
    return selectedValues ? selectedValues : null;
  };

  const setAccount = function (category: any) {
    if (category && Array.isArray(category.accounts) && category.accounts[0]) {
      return category.accounts[0].id;
    }
    return null;
  };

  const initCategoryObj = (obj: any) => {
    if (_.isPlainObject(modelData) && _.isPlainObject(obj)) {
      if (formName && formLocation) {
        dispatch(change(formName, formLocation + ".category", obj));
      }
      input.onChange(obj?.id);
    }
  };

  const setValue = (selected: any) => {
    if (selected && selected.id > 0) {
      input.onChange(selected.value);
      if (_.isPlainObject(modelData) && formName && formLocation) {
        dispatch(change(formName, formLocation + "category", selected));
        dispatch(change(formName, formLocation + "account_id", setAccount(selected)));
      }
    } else {
      input.onChange("");
    }
  };

  // Merge inactive expense category into list
  const mergeInactiveCategory = async (categories: any[]) => {
    if (input.value > 0) {
      const filteredCategories = categories.filter((category: any) => category.id === input.value);
      if (filteredCategories.length === 0) {
        if (!inactivePopulated) {
          // inactive value will be only populate for first time
          try {
            const response = await restApiService.get(`categories/${input.value}`);
            if (response.data && response.data.status === "INACTIVE") {
              const inactiveCategory = response?.data;
              if (inactiveCategory) {
                setInactiveOptions([
                  {
                    ...inactiveCategory,
                    label: inactiveCategory.name + " (INACTIVE)",
                    value: inactiveCategory.id,
                  },
                ]);
                setInactivePopulated(true);
                initCategoryObj(response.data);
              }
            } else {
              initCategoryObj(null);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          initCategoryObj(null);
          setValue({ value: "" });
        }
      } else {
        initCategoryObj(filteredCategories[0]);
      }
    }
  };

  const getExpenseCategories = async () => {
    const getParamObj: Record<string, any> = {};
    if (modelData?.policy_id) {
      getParamObj.policy_id = modelData?.policy_id;
    }
    if (policyId) {
      getParamObj.policy_id = policyId;
    }
    try {
      const result = await restApiService.get("categories", getParamObj, null, true, null, true);
      if (Array.isArray(result.data)) {
        let parsedOptions = parseForSelect(result.data);
        if (!required) {
          parsedOptions = [{ label: "-- Select Category --", value: "" }, ...parsedOptions];
        }
        mergeInactiveCategory(parsedOptions);
        if (isMounted.current) {
          setOptions(parsedOptions);
        }
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  const onChange = (selected: any) => {
    input.onChange(selected.value);
    if (callBackObj) {
      callBackObj(selected);
    }
  };

  useEffect(() => {
    getExpenseCategories();
  }, [modelData?.policy_id, policyId]);

  return (
    <Form.Group className={Styles.select} ref={selectRef}>
      {label && (
        <Form.Label className={labelClassName}>
          {label}
          <Mandatory required={required} />
          {tooltip}
        </Form.Label>
      )}
      {!label && showAsteriskWithoutLabel && required && (
        <div className="d-flex justify-content-end">
          <Mandatory required={required} />
        </div>
      )}
      <Select
        {...(menuAutoFixed ? { menuPlacement: "auto", menuPosition: "fixed" } : {})}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        {...input}
        value={findSelected()}
        onChange={(selected) => onChange(selected)}
        onBlur={() => onBlurSingle(input, input.value)}
        instanceId={instanceId}
        options={options}
        classNamePrefix="select"
        menuPlacement="auto"
        menuPosition="fixed"
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default memo(ExpenseItemCategoryPicker);
