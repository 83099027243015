import React from "react";
import { useDispatch } from "react-redux";
import { Field, change } from "redux-form";
import { PaymentFieldType } from "../types";
import { CHANGE_BANK_NAME, CHANGE_PAYMENT_BANK_ADDRESS, CHANGE_ROUTING } from "../../../../actions/actionTypes";
import BankRoutingNumber from "../../../common/pickers/reduxPickers/bankRoutingNumber";
import BankRoutingSample from "../../../common/bankingRoutingSample/bankRoutingSample";
import { MANAGE_PAYMENT_METHOD_FORM } from "../../../../services/common/paymentMethod.svc";

type BankRoutingPropsType = PaymentFieldType;

const BankRouting = ({ validations, required, label }: BankRoutingPropsType) => {
  const dispatch = useDispatch();
  const changeRouting: any = (value: string) => {
    dispatch({ type: CHANGE_ROUTING, payload: value });
  };

  const bankNameCallBack = (bankName: string) => {
    dispatch({ type: CHANGE_BANK_NAME, payload: bankName });
    dispatch(change(MANAGE_PAYMENT_METHOD_FORM, "bank_name", bankName));
  };

  const bankAddressCallBack = (bankAddress: string) => {
    dispatch({ type: CHANGE_PAYMENT_BANK_ADDRESS, payload: bankAddress });
    dispatch(change(MANAGE_PAYMENT_METHOD_FORM, "bank_address", bankAddress));
  };

  return (
    <Field
      name="routing"
      label={label}
      component={BankRoutingNumber}
      tooltip={<BankRoutingSample />}
      required={required}
      validate={validations}
      bankNameCallBack={bankNameCallBack}
      bankAddressCallBack={bankAddressCallBack}
      onChange={changeRouting}
    />
  );
};

export default BankRouting;
