import React, { ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { Field, reset } from "redux-form";
import { useTypedSelector } from "../../../../reducers";
import { PaymentFieldType } from "../../../common/managePaymentMethod/types";
import { CHANGE_PAYMENT_TYPE, CLEAR_PAYMENT_METHOD } from "../../../../actions/actionTypes";
import { RenderSelect } from "../../../forms/bootstrapFields";
import { compare } from "../../../../services/general/helpers";
import { MANAGE_PAYMENT_METHOD_FORM } from "../../../../services/common/paymentMethod.svc";
import { IUser } from "../../../../services/common/user/userTypes";
type PaymentTypePropsType = PaymentFieldType;

const PaymentType = ({ validations, label, required, modalData, callBack }: PaymentTypePropsType) => {
  const dispatch = useDispatch();
  const acceptedPaymentMethods = useTypedSelector<IUser["company"]["accepted_payment_methods"]>(
    (state) => state.user.company.accepted_payment_methods,
  );
  let paymentTypeOptions: { label: string; value: string }[] = [
    { label: "", value: "" },
    { label: "Check - Bank Account", value: "check" },
    { label: "External Payment", value: "external" },
  ];

  const resetPaymentMethod = () => {
    dispatch(reset(MANAGE_PAYMENT_METHOD_FORM));
    dispatch({ type: CLEAR_PAYMENT_METHOD });
  };

  if (acceptedPaymentMethods.ach) {
    paymentTypeOptions.push({ label: "ACH - Bank Account (USA)", value: "ach" });
  }
  if (acceptedPaymentMethods.credit_card) {
    paymentTypeOptions.push({ label: "Credit Card", value: "credit_card" });
  }
  if (acceptedPaymentMethods.fed_wire) {
    paymentTypeOptions.push({ label: "Domestic/Fedwire - Wire", value: "fed-wire" });
  }
  if (acceptedPaymentMethods.can_eft) {
    paymentTypeOptions.push({ label: "EFT- Bank Account (CANADA)", value: "can-eft" });
  }
  if (acceptedPaymentMethods.uk_eft) {
    paymentTypeOptions.push({ label: "EFT- Bank Account (UK Domestic)", value: "uk-eft" });
  }
  if (acceptedPaymentMethods.aus_pay_net) {
    paymentTypeOptions.push({ label: "EFT- Australian PayNet", value: "aus-pay-net" });
  }
  if (acceptedPaymentMethods.nz_eft) {
    paymentTypeOptions.push({ label: "EFT- Bank Account (New Zealand)", value: "nz-eft" });
  }
  if (acceptedPaymentMethods.japan_eft) {
    paymentTypeOptions.push({ label: "EFT- Japanese Local Payment", value: "japan-eft" });
  }
  if (acceptedPaymentMethods.transferwise) {
    paymentTypeOptions.push({ label: "International Bank Account - TW", value: "international" });
  }
  if (acceptedPaymentMethods.payoneer) {
    paymentTypeOptions.push({ label: "International Bank Account - Pay", value: "payoneer" });
  }
  if (acceptedPaymentMethods.wire) {
    paymentTypeOptions.push({ label: "International Cross-border - AI/Wire", value: "wire" });
  }
  if (acceptedPaymentMethods.sepa) {
    paymentTypeOptions.push({ label: "International - SEPA", value: "sepa" });
  }
  if (acceptedPaymentMethods.cambridge) {
    paymentTypeOptions.push({ label: "International - Cambridge", value: "cambridge" });
  }
  if (acceptedPaymentMethods.swift_wire) {
    paymentTypeOptions.push({ label: "Swift - Wire", value: "swift-wire" });
  }
  if (acceptedPaymentMethods.sg_eft) {
    paymentTypeOptions.push({ label: "EFT- Bank Account (Singapore)", value: "sg-eft" });
  }

  // sorting paymentTypeOptions alphabetically
  paymentTypeOptions = paymentTypeOptions.sort(compare);

  const changePaymentType = (payload: string) => {
    dispatch({ type: CHANGE_PAYMENT_TYPE, payload: payload });
  };

  const onPaymentTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    resetPaymentMethod();
    changePaymentType(e.target.value);
    if (callBack) {
      callBack(e.target.value);
    }
  };

  return (
    <Field
      name={modalData ? `${modalData}.payment_type` : "payment_type"}
      label={label}
      component={RenderSelect}
      options={paymentTypeOptions}
      required={required}
      onChange={onPaymentTypeChange}
      validate={validations}
    />
  );
};

export default PaymentType;
