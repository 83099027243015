import React, { useEffect, useState } from "react";
import styles from "../vendors.module.css";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../../../common/errorBoundary/errorBoundary";
import VendorDocumentUploadModal from "../../../common/vendorDocumentUploadModal/vendorDocumentUploadModal";
import { useDispatch } from "react-redux";
import { change, getFormValues } from "redux-form";
import { restApiService } from "../../../../providers/restApi";
import { DocumentRequirementsType } from "../../../../services/vp/purchaser/purchaserTypes";
import _ from "lodash";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { CompanyType } from "services/common/user/userTypes";
import { VendorType } from "services/admin/vendors/vendorTypes";
import { useTypedSelector } from "reducers";
import { IDType } from "services/common/types/common.type";
import VendorDocumentLocalPreview from "components/common/vendorDocumentPreview/vendorDocumentLocalPreview";

type DocumentsRequestedPropsType = {
  handleNext: (step: number) => void;
  handlePrevious: (step: number) => void;
  handleCancel: () => void;
  currentUserCompany?: CompanyType;
  valid?: boolean;
};

type PreviewableDocumentType = {
  asset_file?: File;
  file_name?: string;
  documentRequested: DocumentRequirementsType;
  id?: IDType;
  company_id?: IDType;
  created_at?: string;
  document_type?: string;
  documents?: Document[];
  isUpdated?: boolean;
  required?: boolean;
  signatureValue?: string;
  signReference?: string;
  updated_at?: string;
  file?: File;
  w8PageData?: string;
  w8BenPageData?: string;
  w9PageData?: string;
  diversityFormData?: Record<string, any>;
};

const DocumentsRequested = ({
  handleNext,
  handlePrevious,
  handleCancel,
  currentUserCompany,
  valid,
}: DocumentsRequestedPropsType) => {
  const { t } = useTranslation();
  const [isValidatedRequiredFields, setValidatedRequiredFields] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [documentRequested, setDocumentRequested] = useState<DocumentRequirementsType>();

  const [isDocumentPreviewModalOpen, setIsDocumentPreviewModalOpen] = useState(false);
  const [previewableDocument, setPreviewableDocument] = useState<PreviewableDocumentType>();

  // Separate state for requested and uploaded documents
  const [requestedDocuments, setRequestedDocuments] = useState<DocumentRequirementsType[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<any[]>([]);

  const isNvpCustomer = currentUserCompany?.accepted_payment_methods?.corpay;
  const vendor: VendorType = useTypedSelector((state) => getFormValues("VendorForm")(state));

  const onSubmitDocumentUploadModal = async (data: {
    file?: File | undefined;
    w8PageData?: string | undefined;
    w8BenPageData?: string | undefined;
    w9PageData?: string | undefined;
    diversityFormData?: Record<string, any> | undefined;
    signReference?: string;
    signatureValue?: string;
  }) => {
    // When a requested document is successfully uploaded, it should be moved
    // from the "Requested Documents" section to the "Uploaded Documents" section.
    if (documentRequested) {
      // Update the visibility of requested documents by marking the requested document as uploaded.
      const updatedDocuments = requestedDocuments.map((document) => {
        if (document.document_type === documentRequested.document_type) {
          // Set the 'isUploaded' flag to true to hide it from the Requested Documents section.
          return {
            ...document,
            isUploaded: true,
          };
        }
        // If the document type doesn't match, leave it as is.
        return document;
      });
      // Update the Requested Documents state with the modified visibility.
      setRequestedDocuments(updatedDocuments);
      const updatedDocumentRequested = { documentRequested: documentRequested, ...documentRequested, ...data };
      // Add the uploaded document to the Uploaded Documents section.
      setUploadedDocuments([...uploadedDocuments, updatedDocumentRequested]);
    }

    if (data?.file) {
      let asset = {
        asset_file: data?.file,
        file_name: data?.file?.name,
      };
      setUploadedDocuments([...uploadedDocuments, asset]);
    }
    closeUploadModal();
  };

  const showPreview = (document: PreviewableDocumentType) => {
    setPreviewableDocument(document);
    setIsDocumentPreviewModalOpen(true);
  };

  useEffect(() => {
    if (_.isArray(vendor.assets_attributes) && vendor.assets_attributes.length > 0) {
      setUploadedDocuments(vendor.assets_attributes);
    }
  }, []);

  const closeUploadModal = () => {
    setShowUploadModal(false);
  };

  const saveDocuments = () => {
    dispatch(change("VendorForm", "assets_attributes", uploadedDocuments));
    handleNext(isNvpCustomer ? 5 : 6);
  };

  const getRequestedDocumentsList = async () => {
    try {
      const response = await restApiService.get("document_requirements");
      const documentList = response.data?.map((list: any) => {
        list.isUpdated = false;
        return list;
      });

      setRequestedDocuments(documentList);
    } catch (error) {
      CreateNotification("Error", { error }, NotificationType.danger);
    }
  };

  const openUploader = (document: any) => {
    setShowUploadModal(true);
    setDocumentRequested(document);
  };

  const handleBackBtnClick = () => {
    dispatch(change("VendorForm", "skipPaymentMethodStep", false));
    handlePrevious(!isNvpCustomer ? 6 : 5);
  };

  useEffect(() => {
    getRequestedDocumentsList();
  }, []);

  return (
    <>
      {isDocumentPreviewModalOpen && previewableDocument && (
        <VendorDocumentLocalPreview
          previewableDocument={previewableDocument}
          onHide={() => setIsDocumentPreviewModalOpen(false)}
        />
      )}

      <Row className="px-mb-25">
        <Col md="12">
          <span className={styles.vendorDetails}>
            <i className={`icon icon-add-document ${styles.iconVendor}`}></i>
            {t("admin.pages.vendor.documentsRequested")}
          </span>
          <br />
          <span className="px-mt-10">{t("admin.pages.vendor.documentsRequestedNote")}</span>
        </Col>
      </Row>
      <ErrorBoundary>
        <div className={styles.documentsContainer}>
          <Row>
            <Col md="12">
              {showUploadModal && (
                <VendorDocumentUploadModal
                  showUploadModal={showUploadModal}
                  closeUploadModal={closeUploadModal}
                  submitCallback={onSubmitDocumentUploadModal}
                  documentRequirement={documentRequested}
                  showTemplateSection
                  isSubmitting={isSubmitting}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md="6" className={styles.requestedDocumentsSection}>
              <Row>
                <Col md="12">
                  <label className="px-mb-25">Requested Documents</label>
                  <>
                    {_.isArray(requestedDocuments) &&
                      requestedDocuments.length > 0 &&
                      requestedDocuments.map((document) => {
                        return (
                          !document.isUploaded && (
                            <div
                              key={document.id}
                              role="button"
                              className={styles.requestedDocumentsSectionBtn}
                              onClick={() => openUploader(document)}
                            >
                              {document.document_type}
                            </div>
                          )
                        );
                      })}
                    <br />
                    <label className="px-mt-25 px-mb-10">Other Documents</label>
                    <div role="button" className={styles.requestedDocumentsSectionBtn} onClick={() => openUploader({})}>
                      Other Documents
                    </div>
                  </>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <Col md="12">
                  <label className="px-mb-25">Uploaded Documents</label>
                  {uploadedDocuments &&
                    uploadedDocuments.length > 0 &&
                    uploadedDocuments.map((document: any, index: number) => {
                      return (
                        <div
                          key={document.id ? document.id : "asset" + index}
                          className={styles.requestedDocumentsSectionBtn}
                          role="button"
                          onClick={() => showPreview(document)}
                        >
                          {document.document_type ? document.document_type : document.file_name}
                        </div>
                      );
                    })}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </ErrorBoundary>
      <Row className="px-mt-25">
        <Col md={4}>
          <Button variant="secondary" className="px-mr-10" onClick={() => handleBackBtnClick()}>
            {t("admin.pages.vendor.back")}
          </Button>
        </Col>
        <Col md={{ span: 4, offset: 4 }} className={styles.btnColumn}>
          <Button variant="secondary" className="px-mr-10" onClick={() => handleCancel()}>
            {t("admin.pages.vendor.cancel")}
          </Button>
          <>
            {/* <Button variant="secondary" className="px-mr-10" disabled={isValidatedRequiredFields}>
              {t("admin.pages.vendor.skip")}
            </Button> */}
            <Button
              variant="primary"
              className={styles.btnNext}
              onClick={() => saveDocuments()}
              disabled={isValidatedRequiredFields}
              title={`${isValidatedRequiredFields ? "To continue, complete all required fields" : ""}`}
            >
              {t("admin.pages.vendor.next")}
            </Button>
          </>
        </Col>
      </Row>
    </>
  );
};

export default DocumentsRequested;
