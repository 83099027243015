import _ from "lodash";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import style from "./policyViolationSummary.module.css";

interface PolicyViolationSummaryProps {
  expenseDetails: ExpensesTypes.Details;
}

export const PolicyViolationSummary: React.FC<PolicyViolationSummaryProps> = ({ expenseDetails }) => {
  const { t } = useTranslation();
  const t2 = (key: string, options: any = undefined) => {
    return t(`policyViolationSummaryComponent.${key}`, options);
  };

  return (
    <Card className={style.policyViolationCard}>
      <Row>
        <Col className={"panel-heading"}>
          <i className={"icon icon-policy-violation " + style.titleIconPadded}></i>
          {t2("title")}
        </Col>
      </Row>
      {_.isArray(expenseDetails?.violations_messages) && expenseDetails?.violations_messages.length > 0 && (
        <>
          <hr className={style.violationHorizontalRule}></hr>
          {expenseDetails?.violations_messages.map((violationMessage, index) => (
            <Row key={index}>
              <Col className={style.policyViolationRow}>
                <i className={"icon-20 icon-violation-warning bs-20 " + style.iconPadded}></i>
                {violationMessage}
              </Col>
            </Row>
          ))}
        </>
      )}
    </Card>
  );
};
