import {
  dataExtractSectionRoutes,
  fileOutboundSectionRoutes,
  standardReportSectionRoutes,
} from "components/admin/reports/navigation/nav.list";
import { intersection, isArray, mergeWith, uniq } from "lodash";
import { getFormValues } from "redux-form";
import { companyDateFormat } from "services/general/dateSvc";
import { isValidDate } from "services/general/helpers";
import { RootState } from "../../../reducers";
import {
  DataExtractModuleTypes,
  ReportClassificationType,
  ReportDetailType,
  ReportFilterType,
  ReportFormType,
  ReportPageNameType,
  TransactionClassificationNamesType,
  TransactionClassificationsType,
} from "./reportTypes";
import { IntervalsTypes } from "./schedules/schedulesType";

export const DATA_EXTRACT_OPTIONS = [
  { value: "Card & Expense", label: "Card & Expense", permissions: ["listExpenses", "listVCards"] },
  { value: "Invoice", label: "Invoice", permissions: ["listInvoices"] },
  //{value: 'payment', label: 'PAYMENT'}
];

export const FILE_OUTBOUND_OPTIONS = [
  { value: "Invoice", label: "Outbound Invoice" },
  { value: "Vendor", label: "Outbound Vendor" },
];

export const STANDARD_REPORT_OPTIONS = [
  { value: "General", label: "General", permissions: [] },
  { value: "Vendor", label: "Vendor", permissions: ["listVendors"] },
  { value: "PO & Invoice", label: "PO & Invoice", permissions: ["listPurchaseOrders", "listInvoices"] },
  { value: "Card & Expense", label: "Card & Expense", permissions: ["listExpenses", "listVCards"] },
  { value: "Payments", label: "Payments", permissions: ["listPayments"] },
  { value: "Accruals", label: "Accruals", permissions: ["listAccruals"] },
  { value: "Budgets", label: "Budgets", permissions: ["listBudgets"] },
  { value: "Custom", label: "Custom", permissions: [] },
];

// assumption expense_item and expense_report status will remain the same
export const TRANSACTION_CLASSIFICATION_VALUES = {
  All: null,
  New: {
    by_expense_status: ["NEW"], //non-reimb
    by_expense_report_status: ["NEW"], //reimb
  },
  Pending: {
    by_expense_status: ["PENDING"], //non-reimb
    by_expense_report_status: ["PENDING"], //reimb
  },
  Open: {
    by_expense_status: ["OPEN"], //non-reimb
    by_expense_report_status: ["OPEN"], //reimb
  },
  Rejected: {
    by_expense_status: ["REJECTED"], //non-reimb
    by_expense_report_status: ["REJECTED"], //reimb
  },
  Paid: {
    by_expense_status: ["PAID"], //non-reimb
    by_expense_report_status: ["PAID"], //reimb
  },
};

export const TRANSACTION_CLASSIFICATION_OPTIONS = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "New",
    value: "New",
  },
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Open",
    value: "Open",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },
  {
    label: "Paid",
    value: "Paid",
  },
];

export const OUTBOUND_FILTERS_BY_MODEL: { [key: string]: any } = {
  bank_card_transaction: {
    subsidiary: { enabled: false },
    location: { enabled: false },
    date: { enabled: true, labelFrom: "From Posting Date", labelTo: "To Posting Date" },
  },
  vendor: {
    subsidiary: { enabled: true, field: "subsidiary_id" },
    location: { enabled: false },
    date: { enabled: true },
  },
  invoice: {
    subsidiary: { enabled: true, field: "subsidiary_ids_original" },
    location: { enabled: true, field: "location_ids_original" },
    date: { enabled: true },
  },
  invoice_payment_link: {
    subsidiary: { enabled: true, field: "subsidiary_ids_original" },
    location: { enabled: true, field: "location_ids_original" },
    date: {
      enabled: true,
      labelFrom: "From Posting Date",
      labelTo: "To Posting Date",
      paymerang: {
        settlement: {
          fieldFrom: "start_settlement_date",
          labelFrom: "From Settlement Date",
          fieldTo: "end_settlement_date",
          labelTo: "To Settlement Date",
        },
      },
    },
  },
};

export const getReportStorageName = (
  classification: ReportClassificationType,
  dataExtractModule?: DataExtractModuleTypes,
  isSchedule: boolean = false,
): string => {
  let gridStorageName: "" | ReportPageNameType = "";
  const suffix = isSchedule ? " Schedules" : "";
  switch (classification) {
    case "DataExtract":
      if (dataExtractModule) {
        if (dataExtractModule === "Card & Expense") {
          gridStorageName = "Data Extracts - Card & Expense";
        } else if (dataExtractModule === "Invoice") {
          gridStorageName = "Data Extracts - Invoices";
        }
      } else {
        gridStorageName = "Data Extracts";
      }
      break;
    case "FileOutbound":
      gridStorageName = "File Outbound";
      break;
    case "Standard":
    default:
      gridStorageName = "Reports";
      break;
  }
  return `${gridStorageName}${suffix}`;
};

export const getSectionRoutes = (
  classification: ReportClassificationType,
  dataExtractModule?: DataExtractModuleTypes | undefined,
) => {
  switch (classification) {
    case "FileOutbound":
      return fileOutboundSectionRoutes;
    case "DataExtract":
      return dataExtractSectionRoutes(dataExtractModule);
    case "Standard":
    default:
      return standardReportSectionRoutes;
  }
};

export class ReportSvc {
  static formName: string = "ReportForm";

  static usedFilterFormat =
    (currentUser: any) =>
    (params: any): string => {
      const filterObj = params?.data?.properties?.filters;
      const filterStrings: string[] = [];
      let formattedValue: any;
      if (filterObj) {
        Object.keys(filterObj).forEach((key) => {
          formattedValue = filterObj[key];
          if (isValidDate(formattedValue) && currentUser) {
            formattedValue = companyDateFormat(formattedValue, currentUser);
          }
          filterStrings.push(`${key.replace("_", " ")}: ${formattedValue}`);
        });
      }

      return filterStrings.join(", ");
    };

  // dataExtract formValue getters
  static getReportFormValues = (state: RootState): ReportFormType => {
    const formValues = getFormValues(this.formName)(state) as ReportFormType;
    return formValues;
  };
  // dataExtract end

  // schedules formValue getters
  static getScheduleInterval = (state: RootState): IntervalsTypes | undefined => {
    const formValues = getFormValues(this.formName)(state) as ReportFormType;
    return formValues?.schedule?.interval;
  };

  static getEndOfMonth = (state: RootState): boolean | undefined => {
    const formValues = getFormValues(this.formName)(state) as ReportFormType;
    return formValues?.schedule?.end_of_month;
  };
  // schedules end

  static jsonifyRecipients = (recipientsString: string) => {
    let recipients: string[] = [];
    recipientsString.split(",").forEach((email) => {
      recipients.push(email.trim());
    });
    return recipients;
  };

  static getBaseTransactionClassificationFilters = (): TransactionClassificationsType => {
    return {
      by_expense_status: undefined,
      by_expense_report_status: undefined,
      by_purchase_status: undefined,
    };
  };

  static getTransactionClassificationNames = (filters: ReportFilterType) => {
    let classificationsNames: string[] = [];
    let currentByFilter: { [key: string]: string[] } | any;
    let hasClassification: boolean;
    Object.keys(TRANSACTION_CLASSIFICATION_VALUES).forEach((key: string) => {
      currentByFilter = TRANSACTION_CLASSIFICATION_VALUES[key as TransactionClassificationNamesType];
      if (!currentByFilter) {
        return;
      }
      hasClassification = Object.keys(currentByFilter).every(
        (by_filter_name) =>
          filters[by_filter_name] && intersection(filters[by_filter_name], currentByFilter[by_filter_name]).length > 0,
      );
      hasClassification && classificationsNames.push(key);
    });
    return classificationsNames;
  };

  static buildTransactionClassificationFilters = (
    transactionClassificationsChosen: TransactionClassificationNamesType[],
  ) => {
    function statusMerger(objValue: string[], srcValue: string[]) {
      if (isArray(objValue)) {
        return uniq(objValue.concat(srcValue));
      }
    }
    let transactionClassificationObjects: Array<TransactionClassificationsType | null> = [];
    transactionClassificationsChosen.forEach((item: TransactionClassificationNamesType) => {
      transactionClassificationObjects.push(TRANSACTION_CLASSIFICATION_VALUES[item]);
    });
    return mergeWith({}, ...transactionClassificationObjects, statusMerger) as TransactionClassificationsType;
  };

  static setReportParams = (formValues: ReportDetailType) => {
    formValues.recipients = formValues.recipients_original
      ? this.jsonifyRecipients(formValues.recipients_original)
      : [];
    delete formValues.recipients_original;
    if (formValues?.properties?.filters) {
      let subsidiaryStringified = formValues.properties.filters.subsidiary_ids_original
        ? formValues.properties.filters.subsidiary_ids_original.map((val: number) => `${val}`).join(",")
        : "";
      formValues.properties.filters.subsidiary_ids = subsidiaryStringified;
      delete formValues.properties.filters.subsidiary_ids_original;

      let locationStringified = formValues.properties.filters.location_ids_original
        ? formValues.properties.filters.location_ids_original.map((val: number) => `${val}`).join(",")
        : "";
      formValues.properties.filters.location_ids = locationStringified;
      delete formValues.properties.filters.location_ids_original;

      const mergedClassifications = this.buildTransactionClassificationFilters(
        formValues.properties.filters.transaction_classifications_chosen ?? [],
      );
      delete formValues.properties.filters.transaction_classifications_chosen;
      formValues.properties.filters = {
        ...formValues.properties.filters,
        ...this.getBaseTransactionClassificationFilters(),
        ...mergedClassifications,
      };
      formValues.properties.filters.include_external_id_has_data = formValues.properties.filters
        .include_external_id_has_data
        ? "1"
        : false;
    }
  };
}
