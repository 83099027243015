import React, { Component } from "react";
import RestApi from "../../../providers/restApi";
import { Container, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import Panel from "../../../components/panel/panel";
import SingleVirtualCard from "../../../components/virtualCards/singleVirtualCard";
import ChecklistList from "../../../components/widgets/checklist/checklistList";
import styles from "./vcardOnboarding.module.css";

class VCardOnboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();

    this.listTemplate = {
      name: "vcardOnboarding",
      url: "virtual_cards/onboarding/checklist",
      items: [
        {
          title: "Agree to Terms of Service",
          link: "/ap/onboarding/vcards/tos",
          id: "agreed_to_tos",
        },
        {
          title: "Create Virtual Card Account",
          link: "/ap/onboarding/vcards/account",
          id: "stripe_account_created",
          dependsOn: "agreed_to_tos",
        },
        {
          title: "Connect a Bank Account",
          link: "/ap/onboarding/vcards/bank_account",
          id: "plaid_account_verified",
          dependsOn: "agreed_to_tos",
        },
        {
          title: "(Coming Soon!) Connect Accounting Software",
          id: "connected_accounting_software",
          dependsOn: "agreed_to_tos",
        },
        {
          title: "Define Virtual Card Settings",
          link: "/ap/onboarding/vcards/settings",
          id: "settings_defined",
          dependsOn: "agreed_to_tos",
        },
        {
          title: "Add Funds to Account",
          link: "/ap/onboarding/vcards/add_funds",
          id: "funds_added",
          dependsOn: "all",
        },
      ],
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Container fluid={true} className={"pt-4 pb-4"} style={{ maxWidth: "1300px" }}>
        <Row>
          <Col sm={7}>
            <Panel cardBodyStyle={{ padding: 0 }} cardClasses={"animated fadeIn"}>
              <Row>
                <Col className="ml-4 mt-4" style={{ padding: "25px" }}>
                  <h1 className={`${styles.landingPageTitle} mb-0`}>GET READY TO EXPERIENCE</h1>
                  <h1 className={styles.landingPageBigTitle}>Virtual Cards</h1>
                  <p className={`${styles.landingPageBottomText} pr-4 mb-0`}>
                    {" "}
                    Start today with the prepaid card program in few easy steps. Be sure to have the company bank
                    account and tax Id information handy.
                  </p>
                  <h1 className={`${styles.landingPageTitle} mt-4`}>
                    Email <span style={{ color: "#201751" }}>demo@accrualify.com</span> for details
                  </h1>
                  <Row>
                    <Col>
                      <img
                        src={require("../../../assets/virtualCards/corpay-card.png")}
                        className={styles.landingPageImg}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
          </Col>
          {/* <Col sm={5}>
            <ChecklistList listTemplate={this.listTemplate}></ChecklistList>
          </Col> */}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // w9FormSignUp: state.taxFormsReducer.w9FormSignUp,
    contactForm: state.addContactForm.contactForm,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ////////////////////methods related to main payment component/////////////////////////////////
    updateContactForm: (payload) => {
      // dispatch({type: actionType.UPDATE_SIGN_W9_FORM, payload: payload});
      dispatch({ type: actionType.UPDATE_CONTACT_FORM, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VCardOnboarding));
