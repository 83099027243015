import { RenderField } from "components/forms/bootstrapFields";
import IntegrationSettings from "components/pickers/reduxFormPicker/integrationSettingPicker";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import {
  COMPANY_PAYMENT_METHOD_FORM_NAME,
  onHideCompanyPaymentMethodModal,
} from "../../../reducers/admin/companyPaymentMethodFormReducer";
import LoadingBox from "../../common/loaders/loadingBox";
import CustomModal from "../../modals/customModal";
import SubsidiaryPicker from "../../pickers/reduxFormPicker/subsidiaryPicker";
import Styles from "./companyPaymentMethod.module.css";
import Ach from "./templates/ach";
import AusPayNet from "./templates/ausPayNet";
import Cambridge from "./templates/cambridge";
import CanEft from "./templates/canEft";
import Check from "./templates/check";
import FedWire from "./templates/fedWire";
import InternationalTw from "./templates/internationalTw";
import NzEft from "./templates/nzEft";
import Payoneer from "./templates/payoneer";
import Sepa from "./templates/sepa";
import SgEft from "./templates/sgEft";
import UkEft from "./templates/ukEft";
import VirtualCard from "./templates/virtualCard";
import Wire from "./templates/wire";

const paymentTemplates = {
  ach: Ach,
  "can-eft": CanEft,
  "aus-pay-net": AusPayNet,
  "nz-eft": NzEft,
  check: Check,
  international: InternationalTw,
  payoneer: Payoneer,
  cambridge: Cambridge,
  wire: Wire,
  "fed-wire": FedWire,
  sepa: Sepa,
  "uk-eft": UkEft,
  "sg-eft": SgEft,
  credit_card: VirtualCard,
};

let PaymentForm = ({ handleSubmit, onHide, isEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { form } = useSelector((state) => getFormValues(COMPANY_PAYMENT_METHOD_FORM_NAME)(state));
  const isLoading = useSelector((state) => state.companyPaymentMethodFormRed.isLoading);
  const isModalOpen = useSelector((state) => state.companyPaymentMethodFormRed.isModalOpen);

  const PaymentTemplate = paymentTemplates[form.payment_type] ? paymentTemplates[form.payment_type] : null;

  const cancel = () => {
    dispatch(onHideCompanyPaymentMethodModal());
  };

  return (
    <CustomModal
      show={isModalOpen}
      onHide={onHide}
      header={
        isEdit
          ? t("companySettings.paymentMethodsSettings.editPaymentMethod")
          : t("companySettings.paymentMethodsSettings.addPaymentMethod")
      }
      body={
        <Form noValidate id={COMPANY_PAYMENT_METHOD_FORM_NAME} onSubmit={handleSubmit}>
          <Row className={`m-0 ${Styles.formMinHeight}`}>
            <LoadingBox isLoading={isLoading}>
              <Col md="6">
                <Field
                  name={"form.label"}
                  component={RenderField}
                  label={t("companySettings.paymentMethodsSettings.label")}
                />
              </Col>

              {PaymentTemplate && <PaymentTemplate />}

              <Col md="6">
                <Form.Group>
                  <Form.Label>Subsidiary Links</Form.Label>
                  <Field
                    name={`form.subsidiary_ids`}
                    // className={}
                    component={SubsidiaryPicker}
                    // validate={}
                    isMulti={true}
                  />
                </Form.Group>
              </Col>

              <Col md="6">
                <Field
                  name={`form.integration_setting_id`}
                  label={"Integration Settings"}
                  component={IntegrationSettings}
                  isClearable={true}
                />
              </Col>
            </LoadingBox>
          </Row>
        </Form>
      }
      footer={
        <>
          <Button variant="secondary" onClick={() => cancel()}>
            {t("cancel")}
          </Button>
          <Button type="submit" form={COMPANY_PAYMENT_METHOD_FORM_NAME}>
            {t("companySettings.paymentMethodsSettings.save")}
          </Button>
        </>
      }
      size={"lg"}
    />
  );
};

PaymentForm = reduxForm({
  form: COMPANY_PAYMENT_METHOD_FORM_NAME, // a unique identifier for this form
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(PaymentForm);

PaymentForm = connect((state, props) => ({
  initialValues: state.companyPaymentMethodFormRed.companyPaymentMethodForm,
}))(PaymentForm);

export default PaymentForm;
