import { RenderField } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const BankName = ({ col, label, isBankNameRequired, validations }) => {
  return (
    <Col md={col ? col : "6"}>
      <Field
        id="form.bank_name"
        name="form.bank_name"
        component={RenderField}
        label={label}
        type="text"
        required={isBankNameRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default BankName;
