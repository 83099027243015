export const workflow_types = [
  { value: "PurchaseOrder", label: "Purchase Order" },
  { value: "Payment", label: "Payment" },
  { value: "Invoice", label: "Invoice" },
  { value: "ExpenseReport", label: "Expense Report" },
  { value: "PaymentRun", label: "Payment Run" },
  { value: "Vendor", label: "Vendor" },
  { value: "VirtualCard", label: "Card" },
  { value: "CreditMemo", label: "Credit Memo" },
  { value: "Purchase", label: "Purchase" },
  { value: "ChangeRequestPurchaseOrder", label: "Change Request: PO" },
];
export const static_workflow_types = [
  { value: "PurchaseOrder", label: "Purchase Order" },
  { value: "Payment", label: "Payment (Not Implemented)" },
  { value: "Invoice", label: "Invoice" },
  { value: "ExpenseReport", label: "Expense Report" },
  { value: "PaymentRun", label: "Payment Run (Not Implemented)" },
  { value: "Vendor", label: "Vendor (Not Implemented)" },
  { value: "VirtualCard", label: "Virtual Card (Not Implemented)" },
  { value: "CreditMemo", label: "Credit Memo" },
];
export const approval_workflow_triggers = [
  { value: "DEFAULT", label: "DEFAULT" },
  { value: "APPROVER_AMOUNT", label: "APPROVER AMOUNT" },
  { value: "AMOUNT", label: "AMOUNT" },
  { value: "SUBSIDIARY", label: "SUBSIDIARY" },
  { value: "DEPARTMENT", label: "DEPARTMENT" },
  { value: "LOCATION", label: "LOCATION" },
  { value: "VENDOR", label: "VENDOR" },
  { value: "ACCOUNT", label: "ACCOUNT" },
  { value: "PROJECT", label: "PROJECT" },
  { value: "INVOICE_PO", label: "PURCHASE ORDER" },
  { value: "BUSINESS_UNIT", label: "BUSINESS UNIT" },
  { value: "SUBMITTER", label: "SUBMITTER/REQUESTOR" },
  { value: "LINE_DEPARTMENT", label: "LINE DEPARTMENT" },
  { value: "LINE_LOCATION", label: "LINE LOCATION" },
  { value: "LINE_BUSINESS_UNIT", label: "LINE BUSINESS_UNIT" },
  { value: "LINE_PROJECT", label: "LINE PROJECT" },
  { value: "VENDOR_CLASSIFICATION", label: "VENDOR CLASSIFICATION" },
  { value: "CUSTOM_QUESTION", label: "CUSTOM QUESTION" },
  { value: "CUSTOM_TRIGGER", label: "CUSTOM TRIGGER" },
  { value: "CUSTOM_FIELD", label: "CUSTOM FIELD" },
];

export const approver_types = [
  { value: "USER", label: "USER" },
  { value: "TEAM", label: "TEAM" },
  { value: "CUSTOM_APPROVERS", label: "CUSTOM APPROVERS" },
  { value: "TRIGGER_DEFAULT", label: "TRIGGER DEFAULT" },
];

export const workflow_designation = [
  { value: "APPROVAL", label: "APPROVAL" },
  { value: "ACTION", label: "ACTION" },
];

export const action_types = [
  { value: "APPROVE", label: "APPROVE" },
  { value: "CUSTOM_ACTION", label: "CUSTOM ACTION" },
];

export const team = [
  { value: "Department Head", label: "Department Head (virtual)" },
  { value: "Vendor Account Manager", label: "Vendor Account Manager(virtual)" },
  {
    value: "Department Head of Vendor Account Manager",
    label: "Department Head of Vendor Account Manager (virtual)",
  },
  {
    value: "Vendor Receiver Manager",
    label: "Vendor Receiver Manager (virtual)",
  },
  {
    value: "VP of Vendor Account Manager",
    label: "VP of Vendor Account Manager (virtual)",
  },
  {
    value: "President of Vendor Account Manager",
    label: "President of Vendor Account Manager (virtual)",
  },
  { value: "PO Requestor", label: "PO Requestor (virtual)" },
  {
    value: "Manager of PO Requestor",
    label: "Manager of PO Requestor (virtual)",
  },
  {
    value: "VP of PO Requestor Manager",
    label: "VP of PO Requestor Manager (virtual)",
  },
  {
    value: "President of PO Requestor VP",
    label: "President of PO Requestor VP (virtual)",
  },
  { value: "Invoice Requestor", label: "Invoice Requestor (virtual)" },
  { value: "Credit Memo Requestor", label: "Credit Memo Requestor (virtual)" },
  {
    value: "Manager of Invoice Requestor",
    label: "Manager of Invoice Requestor (virtual)",
  },
  { value: "Manager of Employee", label: "Manager of Employee (virtual)" },
  {
    value: "Dept L1 Manager",
    label: "L1 Manager of Subsidiary/Department (virtual)",
  },
  {
    value: "Dept L2 Manager",
    label: "L2 Manager of Subsidiary/Department Manager (virtual)",
  },
  {
    value: "Dept L3 Manager",
    label: "L3 Manager of Subsidiary/Department Manager (virtual)",
  },
  {
    value: "Dept L4 Manager",
    label: "L4 Manager of Subsidiary/Department Manager (virtual)",
  },
  {
    value: "Specified Department Manager",
    label: "Specified Department Header Level Manager (virtual)",
  },
  {
    value: "Specified Location Manager",
    label: "Specified Location Header Level Manager (virtual)",
  },
  {
    value: "Specified Department Line Level Manager",
    label: "Specified Department Line Level Manager (virtual)",
  },
  {
    value: "Specified Location Line Level Manager",
    label: "Specified Location Line Level Manager (virtual)",
  },
  {
    value: "Specified Business Unit Header Level Manager",
    label: "Specified Business Unit Header Level Manager (virtual)",
  },
  {
    value: "Specified Business Unit Line Level Manager",
    label: "Specified Business Unit Line Level Manager (virtual)",
  },
  {
    value: "Specified Project Line Level Manager",
    label: "Specified Project Line Level Manager (virtual)",
  },
  {
    value: "Custom Field Team Header Level Manager",
    label: "Custom Field Team Level Header Manager (virtual)",
  },
  {
    value: "Custom Field Team Line Level Manager",
    label: "Custom Field Team Line Level Manager (virtual)",
  },
  {
    value: "Requestor L1 Manager",
    label: "Requestor L1 Manager (virtual)",
  },
  {
    value: "Requestor L2 Manager",
    label: "Requestor L2 Manager (virtual)",
  },
  {
    value: "Requestor L3 Manager",
    label: "Requestor L3 Manager (virtual)",
  },
  {
    value: "Requestor L4 Manager",
    label: "Requestor L4 Manager (virtual)",
  },
  {
    value: "Requestor L5 Manager",
    label: "Requestor L5 Manager (virtual)",
  },
  {
    value: "Requestor L6 Manager",
    label: "Requestor L6 Manager (virtual)",
  },
  {
    value: "Requestor L7 Manager",
    label: "Requestor L7 Manager (virtual)",
  },
  {
    value: "Requestor L8 Manager",
    label: "Requestor L8 Manager (virtual)",
  },
  {
    value: "Requestor L9 Manager",
    label: "Requestor L9 Manager (virtual)",
  },
  {
    value: "Requestor L10 Manager",
    label: "Requestor L10 Manager (virtual)",
  },
  {
    value: "Location Manager",
    label: "Location Manager (virtual)",
  },
];

export default {};
