import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import style from "./customDropzone.module.css";

type PrimaryDropzoneBodyPropType = {
  disabled?: boolean;
};

const PrimaryDropzoneBody = ({ disabled }: PrimaryDropzoneBodyPropType) => {
  return (
    <div className="text-center pb-4">
      <Row>
        <Col sm className="d-flex justify-content-center">
          <i className="iconUploader icon-upload-attachment"></i>
        </Col>
      </Row>
      <Row>
        <Col sm className="d-flex justify-content-center">
          <p className={`${style.dropFileLabel} font-weight-bold`}>Drag and drop files here</p>
        </Col>
      </Row>
      <Row>
        <Col sm className="d-flex justify-content-center">
          <p className={`${style.dropFileLabel} font-weight-bold`}>or</p>
        </Col>
      </Row>
      <Row>
        <Col sm className="d-flex justify-content-center mt-2">
          <Button variant="secondary" disabled={disabled} style={{ padding: "6px 25px" }}>
            Browse Files
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PrimaryDropzoneBody;
