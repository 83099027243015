import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import StepsBar from "./stepsBar";
import PaymentMethodLinker from "../../../widgets/paymentMethodLinks/paymentMethodLinker";
import { useTypedSelector } from "../../../../reducers";
import { IPurchaser } from "../../../../services/vp/types/purchasers.types";
import { isPaymentMethodLinkingRequired } from "../../../../services/paymentMethods/paymentMethodLinking";
import { useDispatch } from "react-redux";
import {
  getGuidePurchasers,
  getSetupProgress,
  setVendorGuideStep,
} from "../../../../reducers/vp/vendorSetUpGuideReducer";
import { useTranslation } from "react-i18next";

const LinkPaymentMethodStep2 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const purchasers: IPurchaser[] = useTypedSelector((state) => state.vendorSetupGuideRed.purchasers);

  const toStep3 = () => {
    dispatch(setVendorGuideStep(3));
  };

  // success callback for successful linking of payment methods
  const successCallback = () => {
    // call setuplProgress
    dispatch(getSetupProgress());
    // also upladete purchasers array
    dispatch(getGuidePurchasers());
    toStep3();
  };

  // check if linking is required ro not
  const checkPaymentMethodLinkingRequired = () => {
    const linkingRequired = isPaymentMethodLinkingRequired(purchasers);
    // else move to next step
    if (!linkingRequired) {
      toStep3();
    }
  };

  useEffect(() => {
    checkPaymentMethodLinkingRequired();
  }, []);

  return (
    <Row>
      <Col className="d-flex flex-column justify-content-center align-items-center px-5">
        <Row>
          <Col className="my-4">
            <StepsBar step1 step2 />
          </Col>
        </Row>
        <Row>
          <h3>{t("vp.dashboard.vendorGuide.paymentMethodSuccessFullyAdded")}</h3>
        </Row>
        <Row className="my-3 mx-0 ">
          <p> {t("vp.dashboard.vendorGuide.pmSuccessText")} </p>
        </Row>
        <Row>
          <PaymentMethodLinker successCallback={successCallback} />
        </Row>
      </Col>
    </Row>
  );
};

export default LinkPaymentMethodStep2;
