import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import RestApi from "providers/restApi";

/**
 * Depricating this picker
 * use from Component/commmon/reduxPicker/ContactPicker/index.tsx
 */

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */
class ContactPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.restApiService
      .get("contacts")
      .then((result) => {
        if (result.data) {
          let options = this.parseContacts(result.data);
          this.setState({
            selectOptions: options,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  parseContacts(result) {
    let returnObj = result.map((contact) => {
      return {
        value: contact.id,
        label: contact.name,
        raw: contact,
      };
    });
    returnObj.unshift({ value: null, label: "Select..." });
    return returnObj;
  }

  //Find the option that matches the passed in props.id
  findSelected() {
    if (!this.state.selectOptions) {
      return null;
    }
    return this.state.selectOptions.find((option) => {
      if (option.value === this.props.id) {
        return option;
      }
      return null;
    });
  }

  render() {
    return (
      <Form.Group>
        <Select
          required
          value={this.findSelected()}
          placeholder={this.props.t("forms.selectCustomer")}
          options={this.state.selectOptions}
          onChange={(e) => {
            if (this.props.callback) {
              this.props.callback(e);
            }
          }}
        />
      </Form.Group>
    );
  }
}

export default withTranslation()(ContactPicker);
