import React, { useState } from "react";
import UploadModal from "./uploadModal";
import { DocumentRequirementsType } from "../../../services/vp/purchaser/purchaserTypes";
import useW8Form from "../hooks/useW8Form/useW8Form";
import { VDocumentUploadContext, VDocumentUploadType } from "./vendorDocumentUploadContext";
import useW8BenEForm from "../hooks/useW8BenEForm/useW8BenEForm";
import useW9Form from "../hooks/useW9Form/useW9";
import useDiversityTrackigForm from "../hooks/useDiversityTrackingForm/useDiversityTrackigForm";

type VendorDocumentUploadModalPropType = {
  showUploadModal: boolean;
  closeUploadModal: () => void;
  showTemplateSection?: boolean;
  documentRequirement?: DocumentRequirementsType;
  isSubmitting: boolean;
  submitCallback: ({
    file,
    w8BenPageData,
    w8PageData,
    w9PageData,
    diversityFormData,
    signReference,
    signatureValue,
  }: {
    file?: File | undefined;
    w8PageData?: string | undefined;
    w8BenPageData?: string | undefined;
    w9PageData?: string | undefined;
    diversityFormData?: Record<string, any> | undefined;
    signReference?: string;
    signatureValue?: string;
  }) => void;
};

const VendorDocumentUploadModal = ({
  closeUploadModal,
  showUploadModal,
  documentRequirement,
  submitCallback,
  isSubmitting,
  showTemplateSection,
}: VendorDocumentUploadModalPropType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [selectedFrom, setSelectedFrom] = useState<"w9" | "w8" | "w8bene" | "diversity">();
  const [file, setFile] = useState<File>();
  const [activeTab, setActiveTab] = useState("upload");

  const { W8Form, getW8FormData, getW8Signature } = useW8Form();
  const { W8BenEForm, getW8BenEFormData, getW8BenESignature } = useW8BenEForm();
  const { W9Form, getW9FormData, getW9Signature } = useW9Form();
  const { diversityTrackingForm, getDiversityTrackingFormData } = useDiversityTrackigForm();

  const w8SubmitCallback = () => {
    const data = getW8FormData();
    const signature = getW8Signature();
    data &&
      signature &&
      submitCallback({
        w8PageData: data,
        signReference: signature.sign_reference,
        signatureValue: signature.signature_value,
      });
  };

  const w8BenESubmitCallback = () => {
    const data = getW8BenEFormData();
    const signature = getW8BenESignature();
    data &&
      signature &&
      submitCallback({
        w8BenPageData: data,
        signReference: signature.sign_reference,
        signatureValue: signature.signature_value,
      });
  };

  const w9SubmitCallback = () => {
    const data = getW9FormData();
    const signature = getW9Signature();
    data &&
      signature &&
      submitCallback({
        w9PageData: data,
        signReference: signature.sign_reference,
        signatureValue: signature.signature_value,
      });
  };

  // using submit call back argument to send
  // file uploading attachment
  // pageData: for w8, w8BenE, w9
  // formData: for diversity tracking json data
  const submitUploadModal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    let valid = form.checkValidity();
    if (!valid) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    setIsValidated(true);

    if (file) {
      submitCallback({ file: file });
    } else if (selectedFrom === "w8") {
      w8SubmitCallback();
    } else if (selectedFrom === "w8bene") {
      w8BenESubmitCallback();
    } else if (selectedFrom === "w9") {
      w9SubmitCallback();
    } else if (selectedFrom === "diversity" && getDiversityTrackingFormData()) {
      submitCallback({ diversityFormData: getDiversityTrackingFormData() });
    }
  };

  const contextValues: VDocumentUploadType = {
    isValidated,
    setIsValidated,
    activeTab,
    setActiveTab,
    closeUploadModal,
    showUploadModal,
    documentRequirement,
    selectedFrom,
    setSelectedFrom,
    W8Form,
    getW8FormData,
    W8BenEForm,
    getW8BenEFormData,
    W9Form,
    getW9FormData,
    diversityTrackingForm,
    getDiversityTrackingFormData,
    file,
    setFile,
    submitUploadModal,
    isSubmitting,
    showTemplateSection,
  };

  return (
    <VDocumentUploadContext.Provider value={contextValues}>
      <UploadModal />
    </VDocumentUploadContext.Provider>
  );
};

export default VendorDocumentUploadModal;
