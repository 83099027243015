import { RenderField } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const BranchCode = ({ label, validations, isBranchCodeRequired, pattern }) => {
  return (
    <Col md="6">
      <Field
        id="form.branchCode"
        name="form.branchCode"
        component={RenderField}
        label={label}
        type="text"
        required={isBranchCodeRequired}
        validate={validations ? validations : []}
        pattern={pattern}
      />
    </Col>
  );
};

export default BranchCode;
