import {
  AdvancedFilterModel,
  ColDef,
  ColGroupDef,
  ColumnState,
  FilterModel,
  GetContextMenuItemsParams,
  GridApi,
  IServerSideGetRowsParams,
  MenuItemDef,
  SideBarDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { isJSONParsableArray } from "services/general/helpers";
import {
  CombinedFilterType,
  FieldFilterMapType,
  gridFilterType,
  gridSortModelType,
  gridStorageStateType,
} from "./types/grid.type";

function sortColumnsAlphabetically(columns: any) {
  return columns.sort((a: any, b: any) => {
    if (a.displayName < b.displayName) return -1;
    if (a.displayName > b.displayName) return 1;
    return 0;
  });
}

export const SideBarConfig: SideBarDef = {
  toolPanels: [
    {
      id: "customStats",
      labelDefault: "Actions",
      labelKey: "Tools",
      iconKey: "columns",
      toolPanel: "customStatsToolPanel",
      width: 200,
    },
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivotMode: true,
        suppressSyncLayoutWithGrid: true,
        suppressColumnMove: true,
        columnToolPanelHeader: sortColumnsAlphabetically,
      },
    },
  ],
  defaultToolPanel: "",
};

export const ColumnConfig = {
  rowHeight: 40,
  floatingFiltersHeight: 40,
  headerHeight: 40,
};

// In order to remove context menu (which opens in right click) for action column
//This is needed so that we can allow to redirect actions icon anchor tag to new page by right click
export const getContextMenuItems = (
  params: GetContextMenuItemsParams,
  skipContextMenuCols: string[] = [],
): (string | MenuItemDef)[] => {
  //In order ignore ts warning
  let params1: any = params;
  if (
    (params1?.column?.colId && params1.column.colId == "Actions") ||
    (params1?.column?.colId && skipContextMenuCols.includes(params1?.column?.colId))
  ) {
    return [];
  } else {
    return ["copy", "copyWithHeaders", "copyWithGroupHeaders", "export"];
  }
};

// Adds on to the params object a formatted date object ready for API
export const DateFormatter = (request: any, params: any, key: any, apiNameAfter: any, apiNameBefore: any) => {
  if (request.filterModel[key].type === "equals") {
    params[apiNameAfter] = request.filterModel[key].dateFrom;
    //we set before date also as accrualify does not support equals date
    params[apiNameBefore] = request.filterModel[key].dateFrom;
  } else if (request.filterModel[key].type === "greaterThan") {
    params[apiNameAfter] = request.filterModel[key].dateFrom;
  } else if (request.filterModel[key].type === "lessThan") {
    params[apiNameBefore] = request.filterModel[key].dateFrom;
  } else if (request.filterModel[key].type === "inRange") {
    params[apiNameAfter] = request.filterModel[key].dateFrom;
    params[apiNameBefore] = request.filterModel[key].dateTo;
  }
};

// Adds greater than less than format to params
export const lessThanGreaterThanFormatter = (
  request: any,
  params: any,
  apiNameAfter: string,
  apiNameBefore: string,
) => {
  switch (request.type) {
    case "equals":
      params[apiNameAfter] = request.filter;
      params[apiNameBefore] = request.filter;
      break;
    case "greaterThan":
      params[apiNameAfter] = request.filter;
      break;
    case "lessThan":
      params[apiNameBefore] = request.filter;
      break;
    case "inRange":
      params[apiNameAfter] = request.filter;
      params[apiNameBefore] = request.filterTo;
      break;
  }
};

// will return firstRow, lastRow, totalRows, totalPages
export const getPaginationData = (gridApi: GridApi) => {
  let pageSize = 0,
    currentPage = 0,
    firstRow = 0,
    totalRows = 0,
    lastRow = 0,
    totalPages = 0;
  if (!gridApi.isDestroyed()) {
    pageSize = gridApi?.paginationGetPageSize();
    currentPage = gridApi?.paginationGetCurrentPage();

    // Calculate the first and last row numbers
    firstRow = currentPage * pageSize + 1;
    lastRow =
      (currentPage + 1) * pageSize > gridApi.paginationGetRowCount()
        ? gridApi.paginationGetRowCount()
        : (currentPage + 1) * pageSize;

    totalRows = gridApi.paginationGetRowCount();
    totalPages = gridApi?.paginationGetTotalPages();
  }

  return {
    firstRow,
    lastRow,
    totalRows,
    totalPages,
  };
};

export type paginationDataType = ReturnType<typeof getPaginationData>;

// storing grid state to localstorage
export const saveState = ({
  gridRef,
  gridStorageName,
}: {
  gridRef: React.RefObject<AgGridReact>;
  gridStorageName: string;
}) => {
  if (gridRef.current?.api && gridRef.current.columnApi) {
    const savedState: gridStorageStateType = {
      // colState: gridRef.current?.api.getColumnState(),
      colState: getDefaultOrCurrentColumnState(gridRef.current.api, gridStorageName),
      groupState: gridRef.current?.api.getColumnGroupState(),
      filterState: gridRef.current?.api.getFilterModel(),
    };

    localStorage.setItem(gridStorageName, JSON.stringify(savedState));
  }
};

export const getDefaultOrCurrentColumnState = (gridApi: GridApi, gridStorageName: string, reset: boolean = false) => {
  let colState = gridApi.getColumnState();
  const defaultOrder: string[] = JSON.parse(localStorage.getItem(`${gridStorageName}DefaultOrder`) ?? "[]");
  if ((!localStorage.getItem(gridStorageName) || reset) && defaultOrder.length) {
    colState = getDefaultSortedColumnState(defaultOrder, colState);
    gridApi.applyColumnState({ state: colState, applyOrder: true });
  }
  return colState;
};

export const getDefaultSortedColumnState = (defaultOrder: string[], columnState: ColumnState[]): ColumnState[] => {
  const mappedColumnState: { [key: string]: ColumnState } = {};
  columnState.forEach((state) => {
    mappedColumnState[state.colId] = state;
  });
  return defaultOrder.map((colId) => mappedColumnState[colId]).filter((v) => v);
};

export const saveDefaultOrder = (gridStorageName: string, defaultOrder: string[]) => {
  localStorage.setItem(`${gridStorageName}DefaultOrder`, JSON.stringify(defaultOrder));
};

// check if previous state have same column as current if not then reset chase and show default order
export const isColumnsUpdated = ({
  oldState,
  newState,
  gridStorageName,
}: {
  oldState: gridStorageStateType | null;
  newState: (ColDef | ColGroupDef)[] | undefined;
  gridStorageName: string;
}) => {
  const oldColLength = oldState?.colState?.length;
  const newColLength = newState?.length;

  if (!oldColLength) {
    return null;
  } else if (oldColLength && !newColLength) {
    return oldState;
  } else if (oldColLength === newColLength) {
    return oldState;
  } else if (oldColLength && newColLength && oldColLength !== newColLength) {
    return null;
  }
  return null;
};

// restore previous filter store to the grid
// this name is confusing, we are not only applying previous state, but complete grid state
export const getPreviousFilterStates = ({
  gridRef,
  gridStorageName,
}: {
  gridRef: React.RefObject<AgGridReact>;
  gridStorageName: string;
}) => {
  let getState: gridStorageStateType | null = JSON.parse(localStorage.getItem(gridStorageName) ?? "{}");
  const newState = gridRef.current?.api?.getColumnDefs();
  getState = isColumnsUpdated({ oldState: getState, newState, gridStorageName });

  if (!_.isEmpty(getState)) {
    if (getState && getState?.colState && _.isArray(getState.colState)) {
      gridRef.current?.api?.applyColumnState({
        state: getState?.colState,
        applyOrder: true,
      });
    }
    if (getState?.groupState && _.isArray(getState?.groupState)) {
      gridRef.current?.api?.setColumnGroupState(getState?.groupState);
    }
    if (getState?.filterState && _.isPlainObject(getState?.filterState)) {
      gridRef.current?.api?.setFilterModel(getState?.filterState);
    }
  }
};

const applyRangeFilterOption = (filter: gridFilterType, fieldMap: CombinedFilterType | undefined) => {
  let addToPayload: { [key: string]: string | undefined } = {};
  if (!fieldMap) {
    return {};
  }
  if (fieldMap.lessThanOrEqual) {
    addToPayload[fieldMap.lessThanOrEqual] = filter.filterTo || filter.dateTo;
  }
  if (fieldMap.lessThan) {
    addToPayload[fieldMap.lessThan] = filter.filterTo || filter.dateTo;
  }
  if (fieldMap.greaterThanOrEqual) {
    addToPayload[fieldMap.greaterThanOrEqual] = filter.filter || filter.dateFrom;
  }
  if (fieldMap.greaterThan) {
    addToPayload[fieldMap.greaterThan] = filter.filter || filter.dateFrom;
  }
  return addToPayload;
};

export const applyFilterOptions = (
  name: string,
  filter: gridFilterType,
  filterFieldsMap: FieldFilterMapType,
  filterPayload: { [key: string]: string | undefined },
) => {
  let fieldMap: CombinedFilterType | undefined = filterFieldsMap[name];
  let filterValue: string | undefined;
  switch (filter.type) {
    case "inRange":
      filterPayload = Object.assign(filterPayload, applyRangeFilterOption(filter, fieldMap));
      return;
    case "both":
      filterValue = "";
      break;
    case "blank":
      filterValue = "0";
      break;
    case "notBlank":
      filterValue = "1";
      break;
  }
  // filter.dateFrom is used as single value for BOTH greater/less than for datetime fields
  filterValue = filterValue || filter.filter || filter.dateFrom;
  if (!filterValue) {
    return;
  }
  // Attribute name we use would be determined by the following in priority:
  // FieldMap[filter.type] > fieldMap.default > name
  const paramKey = (fieldMap && (fieldMap[filter.type] || fieldMap.default)) || name;
  if (isJSONParsableArray(filterValue)) {
    filterPayload[paramKey] = JSON.parse(filterValue);
  } else {
    filterPayload[paramKey] = filterValue;
  }
};

/**
 * Converts the filterModel object into a key-value format for filtering vendor list page.
 * @param {Object} filterModel - The filterModel object containing filter settings.
 * @param {Object} filterModel - The fieldMap containing field names mapped to back-end params for different query types
 * @returns {Object} - An object with filters in the key: value format.
 */
export const processFilterModel = (
  filterModel: FilterModel | AdvancedFilterModel | any,
  filterFieldMap: FieldFilterMapType,
): object => {
  let filterPayload: { [key: string]: string | undefined } = {};
  for (const name in filterModel) {
    const filter = filterModel[name];
    if (!filter) {
      continue;
    }
    applyFilterOptions(name, filter, filterFieldMap, filterPayload);
  }
  return filterPayload;
};

export const setAllowedFilters = (columnDefs: ColDef[], FIELD_NAME_MAP: FieldFilterMapType) => {
  columnDefs.forEach((col: any) => {
    if (!col.filterParams) {
      col.filterParams = {};
    }
    if (!FIELD_NAME_MAP[col.field]) {
      // if not specified in field_name_map, set default filterOption 'equals'
      col.filterParams.filterOptions = ["equals"];
      col.filterParams.suppressAndOrCondition = true;
      return;
    }
    const allowedFilters = Object.keys(FIELD_NAME_MAP[col.field]);
    if (col.filterParams) {
      col.filterParams.filterOptions = allowedFilters;
    } else {
      col.filterParams = {
        filterOptions: allowedFilters,
      };
    }
  });
};

export const processSortModel = (sortModel: gridSortModelType[], fieldMapName: { [name: string]: string }) => {
  let sortObject: { [colId: string]: { direction: string; priority: number } } = {};
  sortModel.forEach(({ colId, sort }: { colId: string; sort: string }, index: number) => {
    if (fieldMapName[colId]) {
      sortObject[fieldMapName[colId]] = { direction: sort, priority: index };
    } else {
      sortObject[colId] = { direction: sort, priority: index };
    }
  });
  return sortObject;
};

export const getParams = (
  params: IServerSideGetRowsParams,
  FIELD_NAME_MAP: { [key: string]: any } | undefined = undefined,
) => {
  let sortObject: any = {};
  if (params?.request?.sortModel?.length) {
    params.request.sortModel.forEach(({ colId, sort }: { colId: string; sort: string }, index: number) => {
      sortObject[colId] = { direction: sort, priority: index };
    });
  } else {
    sortObject = { id: { direction: "desc", priority: 0 } };
  }
  let paramsObj: any = {
    sort: sortObject,
  };
  if (params?.request?.endRow !== undefined && params?.request?.startRow !== undefined) {
    paramsObj.items = params?.request?.endRow - params?.request?.startRow; //grid will pass in the first index and last index that needs to be fetched
    paramsObj.page = 1 + params?.request?.startRow / params.api.paginationGetPageSize();
  }

  if (params?.request?.filterModel && FIELD_NAME_MAP) {
    let filters = processFilterModel(params.request.filterModel, FIELD_NAME_MAP);
    paramsObj = { ...paramsObj, ...filters };
  }
  return paramsObj;
};

/**
 * This function is taking grid raw sortModel and raw filter model
 * and processing it to make usable for service apis
 */

export const getFilterSortParams = ({
  sortModel,
  filterModel,
  FIELD_NAME_MAP,
}: {
  sortModel: Record<string, any>;
  filterModel: Record<string, any>;
  FIELD_NAME_MAP: Record<string, any>;
}) => {
  let sortObject: any = {};
  if (sortModel?.length) {
    sortModel.forEach(({ colId, sort }: { colId: string; sort: string }, index: number) => {
      sortObject[colId] = { direction: sort, priority: index };
    });
  } else {
    sortObject = { id: { direction: "desc", priority: 0 } };
  }
  let paramsObj: any = {
    sort: sortObject,
  };

  if (filterModel) {
    let filters = processFilterModel(filterModel, FIELD_NAME_MAP);
    paramsObj = { ...paramsObj, ...filters };
  }
  return paramsObj;
};
