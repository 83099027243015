import React from "react";
import DropdownFilter from "../reportFilters/dropdown";
import DateFilter from "../reportFilters/date";
import { shallowEqual } from "react-redux";
import { CompanyType } from "../../../../../services/common/user/userTypes";
import { useTypedSelector } from "../../../../../reducers";

import ScheduledDateRange from "../reportFilters/scheduledDateRange";

const InvoiceItemListing = ({ scheduled }: { scheduled?: boolean }) => {
  const date_type_options = [
    { value: "", label: "" },
    { value: "invoice", label: "INVOICE DATE" },
    { value: "submit", label: "SUBMIT DATE" },
  ];

  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    } else {
      return (
        <>
          <DateFilter name={"Start Date"} code={"start_date"} options={[]} isRequired />
          <DateFilter name={"End Date"} code={"end_date"} options={[]} isRequired />
        </>
      );
    }
  };

  return (
    <div>
      <DropdownFilter name={"Date Type"} code={"date_type"} options={date_type_options} />
      {renderDateFilter()}
    </div>
  );
};

export default InvoiceItemListing;
