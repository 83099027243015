import CustomModal from "components/modals/customModal";
import moment from "moment";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import { IUser } from "services/common/user/userTypes";
import styles from "./payExpenseModal.module.css";
import FormPayExpense, { PAY_EXPENSE_FORM_NAME, PayExpenseFormDataPropsType } from "./formPayExpense";
import { getFormValues } from "redux-form";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import { CreateNotification, NotificationType } from "services/general/notifications";

type PayExpenseModalPropsType = {
  showPayExpenseModal: boolean;
  onHidePayExpenseModal: () => void;
  selectedExpenseReport: ExpenseReportTypes.ListItem;
};

const PayExpenseModal = ({
  showPayExpenseModal,
  onHidePayExpenseModal,
  selectedExpenseReport,
}: PayExpenseModalPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const minimumDate = moment();
  const paymentSubmissionWindow = selectedExpenseReport.payment_submission_window;
  const showPaymentWorkflow = currentUser.company.has_payment_workflow;
  const payExpenseFormState = useTypedSelector((state) =>
    getFormValues(PAY_EXPENSE_FORM_NAME)(state),
  ) as PayExpenseFormDataPropsType | null;

  let payAmount = 0;

  if (selectedExpenseReport.balance_outstanding) {
    payAmount = selectedExpenseReport.balance_outstanding;
  }
  if (selectedExpenseReport.pay_amount) {
    payAmount = selectedExpenseReport.pay_amount;
  }

  const expenseReportPayment = {
    pay_amount: payAmount,
    payment_date: paymentSubmissionWindow,
    currency: selectedExpenseReport.currency?.symbol,
  };

  const isPayAmountMatch =
    payExpenseFormState?.pay_amount &&
    selectedExpenseReport?.pay_amount &&
    payExpenseFormState.pay_amount > selectedExpenseReport.pay_amount;

  const submit = async (payExpenseFormData: PayExpenseFormDataPropsType) => {
    try {
      const payloadObj: any = {};
      payloadObj.amount = payExpenseFormData.pay_amount;
      payloadObj.payment_date = payExpenseFormData.payment_date ? payExpenseFormData.payment_date : undefined;
      let payment_date_entered = moment(payloadObj.payment_date);
      if (minimumDate.isBefore(payment_date_entered)) {
        payloadObj.is_scheduled = true;
      }
      if (selectedExpenseReport.payment_method_from_id) {
        payloadObj.payment_method_from_id = selectedExpenseReport.payment_method_from_id;
      }

      if (selectedExpenseReport.id) {
        const result = await ExpenseReportApis.payNow({
          id: selectedExpenseReport.id,
          payload: payloadObj,
        });
        CreateNotification(
          "Payment Submitted",
          `Payment against Expense Report number ${selectedExpenseReport.number} Submitted Successfully`,
          NotificationType.success,
        );
        onHidePayExpenseModal();
      }
    } catch (error) {
      console.log(error);
      CreateNotification("Error", "Something went wrong", NotificationType.danger);
    }
  };

  return (
    <CustomModal
      header={
        <Container>
          <Row>
            <Col md="12">
              <div className={"text-capitalize " + styles.H1}>
                Confirm Expense for Payment {showPaymentWorkflow && <span className="mr-1">Approval</span>}#
                {selectedExpenseReport.number}
              </div>
            </Col>
          </Row>
        </Container>
      }
      show={showPayExpenseModal}
      onHide={onHidePayExpenseModal}
      size="lg"
      body={
        <Container>
          <FormPayExpense initialValues={expenseReportPayment} onSubmit={submit} />
          {isPayAmountMatch && (
            <Row className="mt-3">
              <Col md="12">
                <label className={styles.textWarning}>
                  Warning : Total payment amount is greater than Expense outstanding amount.
                </label>
              </Col>
            </Row>
          )}
          {showPaymentWorkflow && (
            <Row>
              <Col md="12">
                <label className="small-text mr-1">
                  {" "}
                  <strong>Note</strong>:{" "}
                </label>
                <small>Payment approval workflow detected. </small>
                <small>This expense will be submitted for payment approval. </small>
                <small>It will be released for payment at the final approval date.</small>
              </Col>
            </Row>
          )}
          {!showPaymentWorkflow && (
            <div className="row mt-12">
              <div className="col-md-12">
                <label className="small-text mr-1">
                  <strong>Note</strong>:{" "}
                </label>
                <small> No payment approval workflow detected. </small>
                <small>It will be released for payment either immediately.</small>
              </div>
            </div>
          )}
        </Container>
      }
      footer={
        <Container>
          <Row>
            <Col md="12" className="d-flex justify-content-end">
              <Button variant="secondary" className="px-mr-16" onClick={onHidePayExpenseModal}>
                Cancel
              </Button>
              <Button type="submit" form={PAY_EXPENSE_FORM_NAME}>
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      }
    />
  );
};

export default PayExpenseModal;
