import { Fragment } from "react";
import { Tooltip } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function TooltipRenderQues(props) {
  const { title, placement, className } = props;
  // lineBreakComponent helps split tooltip into multiple lines
  const lineBreakComponent = title.split("\n").length > 0 ? <br></br> : null;
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id="button-tooltip-2">
          {title.split("\n").map((item) => {
            return (
              <Fragment key={item}>
                {item}
                {lineBreakComponent}
              </Fragment>
            );
          })}
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <span {...triggerHandler} className={className}>
          <Image
            width={"20px"}
            ref={ref}
            roundedCircle
            src={require("./../../assets/icons/actions/questionmark.png")}
          />
        </span>
      )}
    </OverlayTrigger>
  );
}

export default TooltipRenderQues;
