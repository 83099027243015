import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field, FieldArrayFieldsProps, change, getFormValues } from "redux-form";
import PaymentType from "../../managePaymentMethod/fields/paymentType";
import { RenderSelect } from "../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import AchPaymentMethod from "../../../common/paymentMethodTemplates/templates/ach";
import CheckPaymentMethod from "../../../common/paymentMethodTemplates/templates/check";
import FedWirePaymentMethod from "../../../common/paymentMethodTemplates/templates/fedWire";
import CanEftPaymentMethod from "../../../common/paymentMethodTemplates/templates/canEft";
import { required } from "../../../../services/validations/reduxFormValidation";
import AusPayNetPaymentMethod from "../../../common/paymentMethodTemplates/templates/ausPayNet";
import NzEftPaymentMethod from "../../../common/paymentMethodTemplates/templates/nzEft";
import ExternalPaymentMethod from "../../../common/paymentMethodTemplates/templates/external";
import SepaPaymentMethod from "../../../common/paymentMethodTemplates/templates/sepa";
import CambridgePaymentMethod from "../../../common/paymentMethodTemplates/templates/cambridge";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../providers/restApi";
import { IDType } from "../../../../services/common/types/common.type";
import InternationalTWPaymentMethod from "../../../common/paymentMethodTemplates/templates/internationalTW";
import { useDispatch } from "react-redux";
import AiWirePaymentMethod from "../../../common/paymentMethodTemplates/templates/aiWire";
import JapanEftPaymentMethod from "../../../common/paymentMethodTemplates/templates/japanEft";
import SgEftPaymentMethod from "../../../common/paymentMethodTemplates/templates/sgEft";
import UkEftPaymentMethod from "../../../common/paymentMethodTemplates/templates/ukEft";
import PayoneerPaymentMethod from "../../../common/paymentMethodTemplates/templates/payoneer";
import { CompanyType } from "../../../../services/common/user/userTypes";
import paymentTypeService from "../../../../services/common/paymentMethod/paymentTypeSvc";
import { useTypedSelector } from "reducers";
import { VendorType } from "services/admin/vendors/vendorTypes";
import _ from "lodash";

type VendorPaymentMethodRendererType = {
  fields: FieldArrayFieldsProps<any>;
  sectionName: string;
  currentUserCompany?: CompanyType;
};

type IntegrationSettingsType = {
  application_type?: string;
  authentication_method?: string;
  description?: string;
  email?: string;
  id?: IDType;
  status?: string;
  system_name?: string;
};

const VendorPaymentMethod = ({ fields, currentUserCompany }: VendorPaymentMethodRendererType) => {
  const { t } = useTranslation();
  const [paymentType, setPaymentType] = useState<string>();
  const [integrationSettings, setIntegrationSettings] = useState<IntegrationSettingsType[]>([]);
  const dispatch = useDispatch();
  const vendor: VendorType = useTypedSelector((state) => getFormValues("VendorForm")(state));

  const handleAddPaymentMethod = () => {
    const paymentMethodsAttributes = vendor?.payment_methods_attributes;
    if (!paymentMethodsAttributes || (paymentMethodsAttributes && paymentMethodsAttributes.length === 0)) {
      fields?.push({
        payment_type: "ach",
        account_type: "checking",
        is_primary: true,
      });
      handleChangePaymentType("ach");
    }
  };

  const getIntegrationSettings = useCallback(async () => {
    try {
      const result: AxiosResponse<any[]> = await restApiService.get(
        "integration_settings",
        null,
        null,
        true,
        null,
        true,
      );
      if (result && result.data) {
        setIntegrationSettings(result.data);
        let cambridgeCount = 0;
        result.data.map((setting) => {
          if (setting.system_name === "Cambridge" && setting.status === "ACTIVE") {
            cambridgeCount += 1;
          }
        });
        const multiCambridgeIntegration = cambridgeCount > 1;
      }
    } catch (error) {
      console.log("Error getting integration settings:", error);
    }
  }, []);

  const handleChangePaymentType = (paymentType: string, field?: any) => {
    setPaymentType(paymentType);
    dispatch(change("VendorForm", `${field}`, { is_primary: true }));
  };

  useEffect(() => {
    handleAddPaymentMethod();
    getIntegrationSettings();
  }, [getIntegrationSettings]);

  useEffect(() => {
    if (_.isArray(vendor?.payment_methods_attributes) && vendor?.payment_methods_attributes.length > 0) {
      setPaymentType(vendor?.payment_methods_attributes[0].payment_type);
    }
  }, [vendor?.payment_methods_attributes]);
  return (
    <>
      {fields && fields?.length > 0 && (
        <div>
          {fields.map((field, index) => (
            <div key={index}>
              <Row>
                <Col sm="6">
                  <Field
                    name={`${field}.payment_type`}
                    component={PaymentType}
                    type="text"
                    modalData={`${field}`}
                    required
                    callBack={(paymentType: string) => handleChangePaymentType(paymentType, `${field}`)}
                    label={t("common.paymentMethod.paymentType")}
                    validate={[required]}
                  />
                </Col>
                <Col sm="6">
                  <Field
                    name={`${field}.status`}
                    component={RenderSelect}
                    options={[
                      { value: null, label: "" },
                      { value: "ACTIVE", label: "ACTIVE" },
                      { value: "INACTIVE", label: "INACTIVE" },
                    ]}
                    FieldClassName="formField"
                    placeholder={"Search/Select"}
                    label={t("common.paymentMethod.status")}
                  />
                </Col>
                {paymentType && (
                  <>
                    {paymentTypeService.isAch(paymentType) && <AchPaymentMethod modelName={`${field}`} />}
                    {paymentTypeService.isCheck(paymentType) && <CheckPaymentMethod modelName={`${field}`} />}
                    {paymentTypeService.isFedWire(paymentType) && (
                      <FedWirePaymentMethod modelName={`${field}`} modelData={vendor?.payment_methods_attributes[0]} />
                    )}
                    {paymentTypeService.isCanEft(paymentType) && <CanEftPaymentMethod modelName={`${field}`} />}
                    {paymentTypeService.isAusPayNet(paymentType) && <AusPayNetPaymentMethod modelName={`${field}`} />}
                    {paymentTypeService.isNzEft(paymentType) && <NzEftPaymentMethod modelName={`${field}`} />}
                    {paymentTypeService.isExteranl(paymentType) && <ExternalPaymentMethod modelName={`${field}`} />}
                    {paymentTypeService.isSepa(paymentType) && (
                      <SepaPaymentMethod modelName={`${field}`} modelData={vendor?.payment_methods_attributes[0]} />
                    )}
                    {paymentTypeService.isTransfterwise(paymentType) && (
                      <InternationalTWPaymentMethod
                        modelName={`${field}`}
                        modelData={vendor?.payment_methods_attributes[0]}
                      />
                    )}
                    {paymentTypeService.isWire(paymentType) && (
                      <AiWirePaymentMethod modelName={`${field}`} modelData={vendor?.payment_methods_attributes[0]} />
                    )}
                    {paymentTypeService.isJanpanEft(paymentType) && <JapanEftPaymentMethod modelName={`${field}`} />}
                    {paymentTypeService.isUkEft(paymentType) && <UkEftPaymentMethod modelName={`${field}`} />}
                    {paymentTypeService.isSgEFT(paymentType) && <SgEftPaymentMethod modelName={`${field}`} />}
                  </>
                )}
              </Row>
              {paymentType && paymentTypeService.isCambridge(paymentType) && (
                <Row>
                  <Col>
                    <CambridgePaymentMethod modelName={`${field}`} />
                  </Col>
                </Row>
              )}
              {paymentType && paymentTypeService.isPayoneer(paymentType) && (
                <Row>
                  <Col>
                    <PayoneerPaymentMethod
                      modelName={`${field}`}
                      currentUserCompany={currentUserCompany}
                      modelData={vendor?.payment_methods_attributes[0]}
                    />
                  </Col>
                </Row>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default VendorPaymentMethod;
