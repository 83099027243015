import React, { useCallback, useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import { RootState, useTypedSelector } from "reducers";
import { Field } from "redux-form";

import { WombatSelect } from "wombatifier/components/pickers/wombatSelect";
import { WombatSvc, WOMBAT_FUNCTION_NAME_MAP } from "wombatifier/services/wombatSvc";

const WOMBAT_FUNCTION_BUTTON_STYLE = { fontSize: 11, height: "fit-content" };
const WOMBAT_FUNCTION_CARD_STYLE = { height: 35, overflowY: "scroll" };

export const WombatMappingTool = ({
  column: {
    colDef: { index, mapToFromHashRef, mapFromToHashRef, mapToOrderRef },
  },
}: {
  column: {
    colDef: { index: number; mapToFromHashRef: any; mapFromToHashRef: any; mapToOrderRef: any };
  };
}) => {
  const chosenWombatFunctions = useTypedSelector(
    (state: RootState) => WombatSvc.getWombatChosenFunctionNames(state, index),
    shallowEqual,
  );
  const availableMappingToOptions = useTypedSelector(
    (state: RootState) => WombatSvc.getAvailableMappingToOptions(state),
    shallowEqual,
  );
  const mappingToOptions = useTypedSelector((state: RootState) => WombatSvc.getMappingToOptions(state), shallowEqual);
  const currentMapTo = useTypedSelector((state: RootState) => WombatSvc.getWombatMapTo(state, index));
  const currentMapFrom = useTypedSelector((state: RootState) => WombatSvc.getWombatMapFrom(state, index));
  const chosenWorkflowType = useTypedSelector((state: RootState) => WombatSvc.getWombatWorkflowType(state));
  const dispatch = useDispatch();
  const setSelectedIndex = useCallback(() => {
    WombatSvc.setSelectedIndex(index, dispatch);
  }, [dispatch]);

  useEffect(() => {
    const originalMapTo = mapFromToHashRef.current[currentMapFrom];
    delete mapToFromHashRef.current[originalMapTo];
    if (!currentMapTo) {
      WombatSvc.updateMapTo(index, currentMapFrom, dispatch);
      mapFromToHashRef.current[currentMapFrom] = currentMapFrom;
      mapToFromHashRef.current[currentMapFrom] = currentMapFrom;
      mapToOrderRef.current[index] = currentMapFrom;
    } else {
      mapFromToHashRef.current[currentMapFrom] = currentMapTo;
      mapToFromHashRef.current[currentMapTo] = currentMapFrom;
      mapToOrderRef.current[index] = currentMapTo;
    }
  }, [currentMapTo]);

  const dropdownField = WombatSvc.getDropdownField(chosenWorkflowType);
  return (
    <Container className="p-0 d-flex flex-column h-100">
      <Row className="mx-0 py-2">
        <Col className="px-0 d-flex align-items-center pr-2" style={{ flex: 0 }}>
          Mapped To
        </Col>
        <Col className="px-0">
          {mappingToOptions?.length ? (
            <Field
              name={`form.config.wombat_fields[${index}].${dropdownField}`}
              className="mb-0"
              component={WombatSelect}
              options={availableMappingToOptions}
              sourceOptions={mappingToOptions}
              placeholder="Select Field"
              floating={true}
              indicator={false}
              isClearable={true}
              isSearchable={true}
              wrapSelected={true}
            />
          ) : null}
        </Col>
      </Row>
      <Row className="mx-0 pt-2">
        <Col className="px-0 d-flex align-items-center">Chosen Functions</Col>
        <Col className="px-0" style={{ flex: 0 }}>
          <button type="button" className="icon icon-edit border-0" onClick={setSelectedIndex}></button>
        </Col>
      </Row>
      {chosenWombatFunctions.length > 0 && (
        <Row className="mx-0 pt-1">
          <Card className="px-1 py-1 w-100 d-flex flex-row flex-wrap" style={WOMBAT_FUNCTION_CARD_STYLE as {}}>
            {chosenWombatFunctions.map((function_name, f_index) => {
              return (
                <Button
                  key={`${index}-${f_index}`}
                  type="button"
                  className="font-weight-bold m-0 px-0 py-1 px-1 mr-1 mb-1 border-0"
                  style={WOMBAT_FUNCTION_BUTTON_STYLE}
                  onClick={setSelectedIndex}
                  variant="info"
                >
                  {WOMBAT_FUNCTION_NAME_MAP[function_name ?? ""]}
                </Button>
              );
            })}
          </Card>
        </Row>
      )}
    </Container>
  );
};
