// This component is utilized to confirm the accuracy of an account number by cross-referencing it
// with a confirm account number to ensure they match and are suitable for use.
// Not using any validation here.

import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import { required } from "../../../../services/validations/reduxFormValidation";

const BankAccountNumbers = ({ modelName, col }: { modelName?: string; col?: number }) => {
  const { t } = useTranslation();
  const [accountNumber, setAccountNumber] = useState();
  const [matchError, setMatchError] = useState(false);
  const [fieldTouched, setFieldTouched] = useState(false);

  const validateAccountMatch = (value: string) => {
    setFieldTouched(!!value);
    setMatchError(accountNumber === value);
  };

  return (
    <>
      <Col md={col ? col : 6}>
        <Field
          name={`${modelName}.account_number`}
          component={RenderField}
          required
          validate={[required]}
          type="text"
          onBlur={({ target }: any) => setAccountNumber(target.value)}
          label={t("common.paymentMethod.accountNumberLabel")}
          className={`w-100`}
        />
      </Col>
      <Col md={col ? col : 6}>
        <Field
          name={`${modelName}.confirm_account_number`}
          component={RenderField}
          required
          validate={[required]}
          type="text"
          onChange={({ target }: any) => validateAccountMatch(target.value)}
          label={t("common.paymentMethod.confirmBankAccount")}
          className={`w-100`}
        />
        {fieldTouched && !matchError && <p className="errorTxtColor">Your bank account number doesn't match</p>}
      </Col>
    </>
  );
};

export default BankAccountNumbers;
