import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./companyAccounts.module.css";
import { Can } from "../../../../services/authorization/authorization";
import _ from "lodash";
import Panel from "../../../../components/panel/panel";
import { Card, Button, Row, Col, Table, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCompany } from "../../../../reducers/admin/companyAccountsReducer";
import ManageDefaultAccounts from "../../../../components/admin/manageDefaultAccounts/manageDefaultAccounts";
import { setComponentMountMode } from "../../../../reducers/admin/manageAccountFormReducer";
import ErrorBoundary from "../../../../components/common/errorBoundary/errorBoundary";
import SettingsTabs from "../settingsTabs";
import settingsStyles from "./../settings.module.css";
import { Link } from "react-router-dom";

const CompanyAccounts = () => {
  const [showEditAccountModal, setShowEditAccountModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const company = useSelector((state) => state.companyAccountsRed.company);
  const accounts = useSelector((state) => state.companyAccountsRed.accounts);
  const currentUser = useSelector((state) => state.user);

  const getCompanyData = () => {
    dispatch(getCompany());
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  const editAccounts = () => {
    mountAccountlinker();
    dispatch(setComponentMountMode(true));
  };

  const mountAccountlinker = () => {
    setShowEditAccountModal(true);
  };

  const UnmountAccountlinker = () => {
    setShowEditAccountModal(false);
  };

  const updateAndUnmount = () => {
    UnmountAccountlinker();
    getCompanyData();
  };

  return (
    <>
      {showEditAccountModal && (
        <ManageDefaultAccounts
          saveEditCallback={updateAndUnmount}
          modelData={company}
          modelName={"company"}
          hide={UnmountAccountlinker}
        />
      )}
      <ErrorBoundary>
        <Container fluid className={`fontSizeNormal`}>
          <Row className={settingsStyles.navBarMargin}>
            <SettingsTabs
              breadcrumb={
                <span className={settingsStyles.landingHeader}>
                  <Link to={"/ap/settings"}> Settings</Link>
                </span>
              }
            />
          </Row>
          <Row className="row">
            <Col lg="12" className="mt-3">
              <Panel>
                <Card.Header
                  className={
                    styles.cardHeader + " collapseHeader d-flex justify-content-center align-items-center pt-0 pb-2"
                  }
                >
                  <p className={"mb-0 align-self-center mr-auto fw-800"}>
                    <i className="icon icon-gl"></i> {t("companySettings.accountSettings.accounts").toUpperCase()}
                  </p>
                  <Can I="edit" a="Settings">
                    <Button
                      variant="secondary"
                      size="sm"
                      className="d-flex justify-content-center align-items-center font-weight-bold"
                      onClick={() => editAccounts(company)}
                    >
                      {" "}
                      <i className="icon icon-edit"></i>Edit
                    </Button>
                  </Can>
                </Card.Header>
                <div className={styles.tableWrapper}>
                  <Table responsive className={styles.table + " " + styles.tableStriped + " text-left"}>
                    <thead>
                      <tr>
                        <th>{t("companySettings.accountSettings.accountType")}</th>
                        <th>{t("companySettings.accountSettings.account")}</th>
                        <th>{t("companySettings.accountSettings.percentage")}</th>
                        {currentUser && currentUser.company.has_departments && (
                          <th>{t("companySettings.accountSettings.department")}</th>
                        )}
                        {currentUser && currentUser.company.has_business_units && (
                          <th>{t("companySettings.accountSettings.businessUnit")}</th>
                        )}
                        {currentUser && currentUser.company.has_locations && (
                          <th>{t("companySettings.accountSettings.location")}</th>
                        )}
                        {currentUser && currentUser.company.has_inter_companies && (
                          <th>{t("companySettings.accountSettings.interCompany")}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {_.isArray(accounts) && accounts.length > 0 ? (
                        accounts.map((account) =>
                          _.isArray(account.account_links)
                            ? account.account_links.map((accountData) => (
                                <tr key={accountData.id}>
                                  <td>{account.label}</td>
                                  <td>{accountData.account_name ? accountData.account_name : null}</td>
                                  <td>{accountData.percent ? accountData.percent : null}</td>
                                  {currentUser && currentUser.company.has_departments && (
                                    <td>{accountData.department_name ? accountData.department_name : null}</td>
                                  )}
                                  {currentUser && currentUser.company.has_business_units && (
                                    <td>{accountData.business_unit_name ? accountData.business_unit_name : null}</td>
                                  )}
                                  {currentUser && currentUser.company.has_locations && (
                                    <td>{accountData.location_name ? accountData.location_name : null}</td>
                                  )}
                                  {currentUser && currentUser.company.has_inter_companies && (
                                    <td>{accountData.inter_company_name ? accountData.inter_company_name : null}</td>
                                  )}
                                </tr>
                              ))
                            : null,
                        )
                      ) : (
                        <tr>
                          <td className="text-center" colSpan="7">
                            {t("companySettings.accountSettings.NoAccountsAvailable")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Panel>
            </Col>
          </Row>
        </Container>
      </ErrorBoundary>
    </>
  );
};
export default CompanyAccounts;
