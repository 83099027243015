import { useCallback, useEffect, useState } from "react";
import { CardPaymentsApis } from "services/admin/cards/payments/cardPaymentsApis";
import { CreateNotification, NotificationType } from "services/general/notifications";

export const LastCardPaymentable = ({
  accountCode,
  amount,
  paymentDateAfter,
  paymentDateBefore,
}: {
  accountCode: string;
  amount?: number;
  paymentDateAfter?: Date;
  paymentDateBefore?: Date;
}) => {
  const [lastCardPayment, setLastCardPayment] = useState<any>();
  const [sumCardPaymentsByPeriod, setSumCardPaymentsByPeriod] = useState<number | undefined>();

  const createPayload = useCallback(() => {
    return {
      payment_types: ["CARD"],
      comdata_account_code: accountCode,
      ...(amount ? { amount_greater_than: amount, amount_less_than: amount } : {}),
      ...(paymentDateAfter ? { payment_date_after: paymentDateAfter } : {}),
      ...(paymentDateBefore ? { payment_date_before: paymentDateBefore } : {}),
      sort: { payment_date: { priority: 0, direction: "desc" }, created_at: { priority: 1, direction: "desc" } },
    };
  }, []);

  const getLastCardPayment = useCallback(async () => {
    let filterParams: { [key: string]: any } = createPayload();
    filterParams.page = 1;
    filterParams.items = 1;
    try {
      const res = await CardPaymentsApis.list({ filter: filterParams });
      if (res?.data?.length && res.data.length == 1) {
        setLastCardPayment(res.data[0]);
      }
    } catch (err) {
      CreateNotification(
        "Card Payment",
        `Failed to retrieve last card payment. Please reach out to support`,
        NotificationType.danger,
      );
    }
  }, []);

  const handleSumCardPayments = useCallback(async () => {
    const paginatedCount: number = 100;
    let currentCount: number = paginatedCount;
    let currentPage: number = 1;
    let filterParams: { [key: string]: any } = createPayload();
    let currentSum = 0;
    while (currentCount === paginatedCount) {
      filterParams.items = currentCount;
      filterParams.page = currentPage;
      try {
        const res = await CardPaymentsApis.list({ filter: filterParams });
        currentCount = res?.data?.length || 0;
        if (currentCount > 0) {
          res.data.forEach((payment: any) => {
            currentSum += payment.amount || payment.amount_in_functional;
          });
        }
      } catch (err) {
        currentCount = 0;
        CreateNotification(
          "Card Payment",
          `Failed to retrieve card payments. Please reach out to support`,
          NotificationType.danger,
        );
        break;
      }
      currentPage++;
    }
    setSumCardPaymentsByPeriod(currentSum);
  }, []);

  useEffect(() => {
    getLastCardPayment();
    handleSumCardPayments();
  }, []);

  return { lastCardPayment: lastCardPayment, sumCardPaymentsByPeriod: sumCardPaymentsByPeriod };
};
