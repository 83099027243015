import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { Col, Container, Modal, Row, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { reduxForm, InjectedFormProps } from "redux-form";
import TabNavigation from "../../../navigation/tabNavigation";
import { restApiService } from "../../../../providers/restApi";
import { useTypedSelector } from "../../../../reducers";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { getFormDifference } from "../../../../services/general/reduxFormSvc";
import { ChangeOrdersPOFormService } from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrdersForm";
import {
  ChangeOrderOptionsType,
  FormComponentCustomPropsType,
} from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrders.types";
import Styles from "../../../../pages/admin/purchaseOrders/changeOrders/purchaseOrders.module.css";
import ChangeOrderApis from "../../../../services/admin/changeOrders/changeOrderApis";
import changeOrderSvc from "../../../../services/admin/changeOrders/changeOrderSvc";

const { modalHeaderDatesMsg, modalHeaderDescription, modalHeaderTitle, bgButton } = Styles;

interface EditChangeOrderLocationState {
  isFromDetailPage?: boolean;
}

const purchaseOrdersForm = new ChangeOrdersPOFormService("edit");

let EditChangeOrder = ({
  children,
  initialize,
  handleSubmit,
}: InjectedFormProps<ChangeOrderOptionsType, FormComponentCustomPropsType> & FormComponentCustomPropsType) => {
  const { id } = useParams<{ id: string }>();
  const po_id = useTypedSelector((state) => purchaseOrdersForm.getRequestableId(state));
  const currentChangeOrderId = useTypedSelector((state) => purchaseOrdersForm.getCurrentCOId(state));
  const status = useTypedSelector((state) => purchaseOrdersForm.getStatus(state));
  const submitAllowed = useTypedSelector((state) => purchaseOrdersForm.getCanEdit(state));
  const history = useHistory<EditChangeOrderLocationState>();

  const tabRoutes = useMemo(
    () => [
      {
        path: `/ap/change_orders/${id}/edit`,
        pageName: "Main",
        isActive: "",
      },
      {
        path: `/ap/change_orders/${id}/edit/po_items`,
        pageName: "PO Items",
        isActive: "",
      },
      {
        path: `/ap/change_orders/${id}/edit/expenses`,
        pageName: "Expenses",
        isActive: "",
      },
    ],
    [id, po_id],
  );

  const getChangeOrderDetails = useCallback(async (id) => {
    let changeOrderDetails = await ChangeOrderApis.getChangeOrderPOMerged(id);
    if (changeOrderDetails) {
      changeOrderDetails.assets_attributes = changeOrderDetails.assets_attributes || [];
      initialize(changeOrderDetails);
    }
  }, []);

  const submitEdit = useCallback(
    async (values: ChangeOrderOptionsType) => {
      let formValues = _.cloneDeep(values);
      const formInitialValues = formValues.initialValues;
      delete formValues.initialValues;
      delete formValues.change_requestable;
      delete formValues.requestor;
      delete formValues.assets_attributes;
      const difference = getFormDifference(formValues, formInitialValues, false) as ChangeOrderOptionsType;
      ChangeOrdersPOFormService.filterPayload(difference.object_changes);
      if (Object.keys(difference).length === 0) {
        CreateNotification("Edit Change Order", "No Changes Made.", NotificationType.danger);
        return false;
      }
      const res = await restApiService.patch(`change_requests/${id}`, null, { change_request: difference });
      if (res && !res.error) {
        CreateNotification("Edit Change Order", `Change Order - ${res.data.number} Edited.`, NotificationType.success);
        setTimeout(() => {
          if (history.location?.state?.isFromDetailPage) {
            history.goBack();
          } else {
            history.push("/ap/po_co_approvals");
          }
        }, 1000);
      }
    },
    [po_id],
  );

  useEffect(() => {
    if (id && currentChangeOrderId?.toString() !== id) {
      getChangeOrderDetails(id);
    }
  }, [id, currentChangeOrderId]);

  useEffect(() => {
    if (status === "OPEN" && po_id) {
      window.location.assign(restApiService.makeAngularURLWithId("purchase_orders/", po_id));
    }
  }, [status, po_id]);

  return (
    <Modal show={true} size="xl" centered>
      <Modal.Header className="pb-0">
        <Container fluid>
          <Row>
            <Col className={modalHeaderDatesMsg}>Edit start and end dates of this purchase order</Col>
          </Row>
          <Row className={modalHeaderTitle}>
            <Col className="p-0" style={{ maxWidth: "fit-content" }}>
              Change Order Details
            </Col>
            <Col className="p-0 px-3" style={{ maxWidth: "fit-content" }}>
              {status}
            </Col>
          </Row>
          <Row className={modalHeaderDescription}>
            Change orders allow for editing multiple fields, including line items and expenses, as well as adjusting
            start and end dates.
          </Row>
          <Row>
            <TabNavigation navigationTab={tabRoutes} />
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>{children}</Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={() => history.goBack()}>
          {submitAllowed ? "Cancel" : "Back"}
        </Button>
        {submitAllowed ? (
          <Button
            className={bgButton}
            type="button"
            onClick={handleSubmit(submitEdit)}
            disabled={Boolean(changeOrderSvc.disableEdits(status))}
          >
            Submit
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

const Edit = reduxForm<ChangeOrderOptionsType, FormComponentCustomPropsType>({
  touchOnChange: true,
  touchOnBlur: false,
  destroyOnUnmount: false,
  form: ChangeOrdersPOFormService.EDIT_CHANGE_ORDER_PO_FORM_NAME,
})(EditChangeOrder);

export default Edit;
