export const TwValidations = [
  {
    currency: "EUR",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0", maxlength: "40", pattern: "^[a-zA-Z0-9& ]+$" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
    ],
  },
  {
    currency: "AUD",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bsb_code",
        validate: { required: true, minlength: "0", maxlength: "6" },
        label: "BSB Code",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "account_number",
        validate: { required: true, minlength: "4", maxlength: "9" },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
      {
        field: "business_number",
        validate: { required: true, minlength: "9", maxlength: "14" },
        label: "Business Number",
        validMessage: "Success",
        errorMessage: "Sorry! Business Number  is required",
      },
    ],
  },
  {
    currency: "BRL",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bankCode",
        validate: { required: true, minlength: "0" },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "branchCode",
        validate: { required: true, minlength: "4", maxlength: "6" },
        label: "Branch Code",
        validMessage: "Success",
        errorMessage: "Sorry! Branch Code  is required",
      },
      {
        field: "account_number",
        validate: { required: true, minlength: "2", maxlength: "12" },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
      {
        field: "account_type",
        validate: { required: true, minlength: "0" },
        label: "Account Type",
        validMessage: "Success",
        errorMessage: "Sorry! Select any one Account Type",
      },
      {
        field: "phoneNumber",
        validate: { required: true, minlength: "7", maxlength: "20" },
        label: "Receipient's phone number",
        validMessage: "Success",
        errorMessage: "Sorry! Phone Number  is required",
      },
      {
        field: "cpf",
        validate: { required: true, minlength: "0" },
        label: "Tax Registration Number (CPF)",
        validMessage: "Success",
        errorMessage: "Sorry! CPF  is required",
      },
    ],
  },
  {
    currency: "DKK",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "iban",
        validate: { required: true, minlength: "2" },
        label: "IBAN",
        validMessage: "Success",
        errorMessage: "Sorry! IBAN  is required",
      },
      {
        field: "bic",
        validate: { required: true },
        label: "Bank code (BIC/SWIFT):",
        validMessage: "Success",
        errorMessage: "Sorry! Bank code (BIC/SWIFT)  is required",
      },
    ],
  },
  {
    currency: "CAD",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "institution_number",
        validate: { required: true, minlength: "3", maxlength: "3" },
        label: "Institute Number",
        validMessage: "Success",
        errorMessage: "Sorry! Institute Number  is required",
      },
      {
        field: "transit_number",
        validate: { required: true, minlength: "5", maxlength: "5" },
        label: "Transit Number",
        validMessage: "Success",
        errorMessage: "Sorry! Transit Number  is required",
      },
      {
        field: "account_type",
        validate: { required: true, minlength: "0" },
        label: "Account Type",
        validMessage: "Success",
        errorMessage: "Sorry! Select any one Account Type",
      },
      {
        field: "account_number",
        validate: { required: true, minlength: "7", maxlength: "12" },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "CNY",
    validations: [
      {
        field: "cardNumber",
        validate: { required: true, minlength: "16", maxlength: "19" },
        label: "Union Pay Card Number",
        validMessage: "Success",
        errorMessage: "Sorry! Card Number  is required",
      },
    ],
  },
  {
    currency: "ILS",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "iban",
        validate: { required: true, minlength: "22", maxlength: "28" },
        label: "IBAN",
        validMessage: "Success",
        errorMessage: "Sorry! IBAN  is required",
      },
    ],
  },
  {
    currency: "INR",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "account_number",
        validate: { required: true, minlength: "5", maxlength: "20" },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
      {
        field: "ifsc_code",
        validate: { required: true, minlength: "11", maxlength: "11" },
        label: "IFSC Code",
        validMessage: "Success",
        errorMessage: "Sorry! IFSC Code is required",
      },
    ],
  },
  {
    currency: "JPY",
    validations: [
      {
        field: "bankCode",
        validate: { required: true, minlength: "0" },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "branchCode",
        validate: { required: true, minlength: "4", maxlength: "6" },
        label: "Branch Code",
        validMessage: "Success",
        errorMessage: "Sorry! Branch Code  is required",
      },
      {
        field: "account_type",
        validate: { required: true, minlength: "0" },
        label: "Account Type",
        validMessage: "Success",
        errorMessage: "Sorry! Select any one Account Type",
      },
      {
        field: "account_number",
        validate: { required: true, minlength: "2", maxlength: "7" },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "MYR",
    validations: [
      {
        field: "swiftCode",
        validate: { required: true, minlength: "0" },
        label: "Beneficiary's Bank Swift Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Swift Code is required",
      },
      {
        field: "account_number",
        validate: { required: true, minlength: "6", maxlength: "20" },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "GBP",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
    ],
  },
  {
    currency: "AED",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
    ],
  },
  {
    currency: "CHF",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bic",
        validate: { required: true },
        label: "Bank code (BIC/SWIFT)",
        validMessage: "Success",
        errorMessage: "Sorry! SWIFT/BIC Code  is required",
      },
    ],
  },
  {
    currency: "SEK",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bic",
        validate: { required: true },
        label: "Bank code (BIC/SWIFT)",
        validMessage: "Success",
        errorMessage: "Sorry! SWIFT/BIC Code  is required",
      },
    ],
  },
  {
    currency: "SGD",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bankCode",
        validate: { required: true, minlength: "0", maxlength: "9" },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "account_number",
        validate: { required: true, minlength: "7", maxlength: "20" },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "PHP",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bankCode",
        validate: { required: true, minlength: "0", maxlength: "9" },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "account_number",
        validate: { required: true, minlength: "7", maxlength: "20" },
        label: "Account Number (PHP only)",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "NOK",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bic",
        validate: { required: true },
        label: "Bank code (BIC/SWIFT)",
        validMessage: "Success",
        errorMessage: "Sorry! SWIFT/BIC Code  is required",
      },
    ],
  },
  {
    currency: "NZD",
    validations: [
      {
        field: "account_number",
        validate: { required: true, minlength: "15", maxlength: "22" },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "KES",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bankCode",
        validate: { required: true },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "ZMW",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bankCode",
        validate: { required: true },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "branchCode",
        validate: { required: true },
        label: "Branch Code",
        validMessage: "Success",
        errorMessage: "Sorry! Branch Code  is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "NGN",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bankCode",
        validate: { required: true },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "GHS",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bankCode",
        validate: { required: true },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "branchCode",
        validate: { required: true },
        label: "Branch Code",
        validMessage: "Success",
        errorMessage: "Sorry! Branch Code  is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "PKR",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "iban",
        validate: { required: true, minlength: "14", maxlength: "42" },
        label: "IBAN",
        validMessage: "Success",
        errorMessage: "Sorry! IBAN  is required",
      },
      {
        field: "bic",
        validate: { required: true, minlength: "8", maxlength: "11" },
        label: "SWIFT/BIC Code",
        validMessage: "Success",
        errorMessage: "Sorry! SWIFT/BIC Code  is required",
      },
    ],
  },
  {
    currency: "ZAR",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bic",
        validate: { required: true },
        label: "Bank code (BIC/SWIFT)",
        validMessage: "Success",
        errorMessage: "Sorry! Bank code (BIC/SWIFT)  is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account number ",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
      {
        field: "phoneNumber",
        validate: { required: true, minlength: "7", maxlength: "20" },
        label: "Receipient's phone number",
        validMessage: "Success",
        errorMessage: "Sorry! Phone Number  is required",
      },
    ],
  },
  {
    currency: "IDR",
    validations: [
      {
        field: "account_name",
        validate: { required: true },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bankCode",
        validate: { required: true },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account number (IDR accounts only)",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "KRW",
    validations: [
      {
        field: "account_name",
        validate: { required: true },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bankCode",
        validate: { required: true },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account number (KRW accounts only)",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "LKR",
    validations: [
      {
        field: "account_name",
        validate: { required: true },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "bic",
        validate: { required: true },
        label: "Bank code (BIC/SWIFT)",
        validMessage: "Success",
        errorMessage: "Sorry! SWIFT/BIC Code  is required",
      },
      {
        field: "branchCode",
        validate: { required: true },
        label: "Branch Code",
        validMessage: "Success",
        errorMessage: "Sorry! Branch Code  is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "USD",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "swiftCode",
        validate: { required: true, minlength: "0" },
        label: "Beneficiary's Bank Swift Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Swift Code is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
    ],
  },
  {
    currency: "HKD",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "0" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "account_number",
        validate: { required: true },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
      {
        field: "bankCode",
        validate: { required: true },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
    ],
  },
  {
    currency: "THB",
    validations: [
      {
        field: "account_name",
        validate: { required: true, minlength: "2", maxlength: "140" },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "account_number",
        validate: { required: true, minlength: "5", maxlength: "17" },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
      {
        field: "bankCode",
        validate: { required: true },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
    ],
  },
];
