import CustomDropzone from "components/common/dropzone/customDropzone";
import PrimaryDropzoneBody from "components/common/dropzone/primaryDropzoneBody";
import CustomModal from "components/modals/customModal";
import React, { FormEventHandler, memo, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./receipts.module.css";
import _ from "lodash";
import PreviewComponent from "components/common/attachmentPreview/previewComponent";
import { Mandatory } from "components/forms/bootstrapFields";
import ReceiptsApis from "services/admin/expenses/receipts/receiptsApis";
import { ReceiptsTypes } from "services/admin/expenses/receipts/receiptsType";
import { useTypedSelector } from "reducers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { restApiService } from "providers/restApi";

const PreviewAsset = memo(({ receiptFile }: { receiptFile?: File }) => {
  return (
    <>
      {receiptFile && (
        <PreviewComponent
          previewSize={{ height: 450, width: "100%" }}
          fileName={receiptFile.name}
          filePath={URL.createObjectURL(receiptFile)}
        />
      )}
    </>
  );
});

type AddReceiptPropsType = {
  show: boolean;
  onHide: () => void;
  addReceiptSuccessfullCallback?: () => void;
};

const AddReceipt = ({ show, onHide, addReceiptSuccessfullCallback }: AddReceiptPropsType) => {
  const { t } = useTranslation();
  const currentUser = useTypedSelector((state) => state.user);
  const [showUploader, setShowUploader] = useState(true);
  const [receiptFile, setReceiptFile] = useState<File>();
  const [receiptNote, setReceiptNote] = useState("");
  const trimmedReceiptNote = receiptNote.trim();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [touched, setTouched] = useState(false);

  const onReceiptSelectDone = () => {
    // hide file uploading screen
    setShowUploader(false);
  };

  const uploadAttachament = async (receipt: ReceiptsTypes.Details) => {
    const formData = new FormData();
    if (receiptFile) {
      formData.append("inbound_receipt[assets_attributes][0][asset_file]", receiptFile);
      formData.append("inbound_receipt[assets_attributes][0][asset_file_file_name]", receiptFile?.name);
    }
    try {
      const result = await restApiService.formWithImage(`inbound_receipts/${receipt.id}`, formData, "PATCH");
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmitReceipt: FormEventHandler = async (e) => {
    const form = e.currentTarget as HTMLFormElement;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      return;
    }
    try {
      setIsSubmitting(true);
      const addReceiptPayload: ReceiptsTypes.AddPayload = {
        name: receiptFile?.name,
        document_type: "RECEIPT",
        documentable_type: "Contact",
        documentable_id: currentUser.contact.id,
        note: receiptNote,
      };
      const result = await ReceiptsApis.addReceipt(addReceiptPayload);
      await uploadAttachament(result);
      CreateNotification(t("success"), t("admin.pages.receipts.successfullyUploaded"), NotificationType.success);
      setIsSubmitting(false);
      onHide();
      if (addReceiptSuccessfullCallback) {
        addReceiptSuccessfullCallback();
      }
    } catch (error) {
      console.log("error", error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {show && (
        <CustomModal
          show={show}
          onHide={onHide}
          header={t("admin.pages.receipts.addReceipt")}
          size="lg"
          contentClassName={style.modalContentClass}
          centered
          body={
            <>
              {showUploader && (
                <Row>
                  <Col>
                    <Row className="my-2">
                      <Col>
                        <CustomDropzone
                          maxFiles={1}
                          onFileUpload={(files) => _.isArray(files) && files[0] && setReceiptFile(files[0])}
                          customDropzoneBody={<PrimaryDropzoneBody />}
                          customDropzoneClass={style.customDropzoneClass}
                        />
                      </Col>
                    </Row>

                    {receiptFile && (
                      <Row className="my-4">
                        <Col sm={11}>{receiptFile.name}</Col>
                        <Col sm={1} className="d-flex justify-content-end">
                          <button
                            className="actionButtons icon-close"
                            onClick={() => setReceiptFile(undefined)}
                          ></button>
                        </Col>
                      </Row>
                    )}

                    <Row className="mt-4">
                      <Col className="d-flex justify-content-end">
                        <Button variant="secondary" className="mr-2" onClick={onHide}>
                          {t("cancel")}
                        </Button>
                        <Button disabled={!receiptFile} onClick={onReceiptSelectDone}>
                          {t("next")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              {!showUploader && (
                <Row>
                  <Col md="12">
                    <Form action="" noValidate onSubmit={onSubmitReceipt}>
                      <Row>
                        <Col>{t("admin.pages.receipts.provideDescription")}</Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md="7" className="px-5">
                          <PreviewAsset receiptFile={receiptFile} />
                        </Col>
                        <Col md="5" className="d-flex flex-column justify-content-between">
                          <Row>
                            <Col>
                              <Form.Group className="pr-4">
                                <Form.Label>
                                  {t("admin.pages.receipts.addReceiptNote")}
                                  <Mandatory required />
                                </Form.Label>
                                <Form.Control
                                  as={"textarea"}
                                  id="receiptNote"
                                  value={receiptNote}
                                  required
                                  onChange={(e) => {
                                    setTouched(true);
                                    setReceiptNote(e.target.value);
                                  }}
                                  rows={6}
                                  className={style.receiptNoteControl}
                                  placeholder={t("admin.pages.receipts.placeHolder")}
                                  isInvalid={touched && !receiptNote}
                                />
                                {touched && (
                                  <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col className="d-flex justify-content-end">
                          <Button variant="secondary" onClick={onHide} className="mr-2 px-5">
                            {t("cancel")}
                          </Button>
                          <Button
                            disabled={isSubmitting || !trimmedReceiptNote}
                            type="submit"
                            className={(!trimmedReceiptNote ? style.receiptUploadDoneDisabled : "") + " mr-2 px-5"}
                          >
                            {isSubmitting && <Spinner size="sm" animation="border" className="mr-2 " />}
                            {t("done")}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default AddReceipt;
