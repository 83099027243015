import React, { useCallback, useEffect, useRef, useState } from "react";
import { ContactPickerPropsType } from ".";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { onBlurSingle, onChangeSingle, parseForSelect } from "services/general/helpers";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "components/common/pickers/reduxFormPickers/select/helpers";
import { restApiService } from "providers/restApi";
import { CreateNotification, NotificationType } from "services/general/notifications";
import _ from "lodash";
import { Mandatory } from "components/forms/input/mandatory";

type ManagersType = {
  label: string;
  value: number;
};

type SingleContactPickerPropType = ContactPickerPropsType;

const SingleContactPicker = ({
  label,
  input,
  meta,
  tooltip,
  params,
  required,
  instanceId = "manager-selector",
  placeholder,
  disabled,
  originalValue,
  floating,
  callBackObj,
  callBack,
}: SingleContactPickerPropType) => {
  const [managers, setManagers] = useState<ManagersType[]>();
  const [contacts, setContacts] = useState<any>();
  const [selected, setSelected] = useState<any>();
  const mounted = useRef(false);

  const pickerProps = {
    ...input,
    required,
    placeholder,
    options: managers,
    isDisabled: disabled,
    instanceId: instanceId,
  };

  const getManagers = useCallback(async () => {
    const apiParams = { contact_type: "COMPANY", by_active: true, ...params };
    try {
      const result = await restApiService.get("contacts.lk", apiParams, null, true, null, true);
      if (mounted.current) {
        setContacts(result.data);
      }
      let list = parseForSelect(result.data);
      if (mounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Contact --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setManagers(list);
      }

      return list;
    } catch (error) {}
  }, [params]);

  const findSelected = async (input: { value: number | string }, options: { value: number; label: string }[]) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        // Fetch inactive contact from the API and enable them to be displayed in the manager dropdown.
        try {
          const result = await restApiService.get("contacts/" + input.value, null, null, true, null, true); // Catching 'contacts' api to reduce redundand calling
          const inactiveManager = {
            value: result.data.id,
            label: (
              <>
                {result.data.name} - <small style={{ fontSize: "10px" }}>({result.data.status})</small>
              </>
            ),
          };
          selectedValues = inactiveManager;
        } catch (error) {
          console.log(error);
          CreateNotification("Error", "Problem loading inactive manager.", NotificationType.danger);
        }
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    if (!input) {
      return;
    }
    getManagers().then((res) => {
      findSelected(input, res).then((response) => {
        if (mounted.current) {
          setSelected(response);
        }
      });
    });
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [input?.value]);

  const onChangeContact = (input: any, value: any) => {
    onChangeSingle(input, value);
    setSelected(value);
    if (callBackObj) {
      // when we whant to return contact object
      let selectedContact = contacts?.find((contact: any) => contact?.id === value?.value);
      callBackObj(selectedContact);
    }
    if (callBack) {
      callBack(value);
    }
  };

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...pickerProps}
          {...(originalValue !== undefined
            ? {
                components: {
                  Input: (props) => <PickerComparisonField {...props} originalValue={originalValue} />,
                },
              }
            : {})}
          menuPlacement="auto"
          menuPosition="fixed"
          value={selected}
          isClearable={!required}
          required={false}
          onChange={(value) => onChangeContact(input, value)}
          onBlur={() => onBlurSingle(input, input?.value)}
          classNamePrefix="select"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
            }),
            ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
          }}
          {...(floating ? { menuPortalTarget: document.body } : {})}
        />
        {meta?.error && meta.touched && <div className="pickerError">{meta.error}</div>}
      </Form.Group>
    </>
  );
};

export default SingleContactPicker;
