import React from "react";
import styles from "./tabNavigator.module.css";
import BudgeTabTemplate from "./budgeTabTemplate";

type TabNavigatorTypes = {
  activeTab: string;
  handleTabChange: (tab: string) => void;
  tabData: {
    activeTab: string;
    label: string;
    hide?: boolean;
  }[];
  template?: "BudgeTabTemplate";
};

const TabNavigator = ({ activeTab, handleTabChange, tabData, template }: TabNavigatorTypes) => {
  if (template === "BudgeTabTemplate") {
    return <BudgeTabTemplate activeTab={activeTab} handleTabChange={handleTabChange} tabData={tabData} />;
  } else {
    return (
      <ul className={styles.navbar}>
        {tabData.map((tab) => {
          if (tab.hide) {
            return null;
          }

          return (
            <li key={tab.activeTab} className={styles.navTab}>
              <a
                className={`${styles.tab} ${activeTab === tab.activeTab ? styles.active : ""}`}
                onClick={() => handleTabChange(tab.activeTab)}
              >
                {tab.label}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
};

export default TabNavigator;
