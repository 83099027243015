import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { noWhiteSpaceOnly, required } from "services/validations/reduxFormValidation";
import { RenderDatePicker, RenderTextArea } from "../../../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import Style from "../dispute.module.css";

export const PaidByOtherDispute = () => {
  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.option.paid_other.${key}`);
  };

  return (
    <Col>
      <Row>
        <Col md={10} className="px-0">
          <Field
            label={t2("description")}
            name="form.options.DESCRIPTION"
            validate={[required, noWhiteSpaceOnly]}
            component={RenderTextArea}
            required
            className={`${Style.fixed_textarea}`}
          />
        </Col>
      </Row>
      <br />
      <Row>{t2("note")}</Row>
      <Row>{t2("note_2")}</Row>
      <br />
      <Row>{t2("instruction")}</Row>
      <br />
      <Row style={{ display: "flex", alignItems: "center" }}>
        {t2("contacted")}
        <div style={{ height: "35px", paddingLeft: "10px" }}>
          <Field
            name="form.options.MERCHANT_CONTACT_DATE"
            component={RenderDatePicker}
            type="date"
            maxDate={new Date()}
          ></Field>
        </div>
      </Row>
      <br />
      <Row>
        <Col md={10} className="px-0">
          <Field
            label={t2("explanation")}
            name="form.options.REASON_DESCRIPTION"
            component={RenderTextArea}
            className={`${Style.fixed_textarea}`}
          />
        </Col>
      </Row>
    </Col>
  );
};
