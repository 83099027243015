import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { ColDef, ColumnApi, GridApi } from "ag-grid-community";
import { sortBy } from "lodash";

import SelectAll from "components/common/dataGrid/selectAllFunctional";
import { translate } from "services/general/translation";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { setAllowedFilters } from "services/common/gridService";
import { companyDateFormat } from "services/general/dateSvc";
import { Can } from "services/authorization/authorization";

import { WombatType } from "wombatifier/services/wombat.types";
import { WombatApis } from "wombatifier/services/wombatApis";

export const FIELD_NAME_MAP: FieldFilterMapType = {
  po_not_required: { equals: "PO_NOT_REQUIRED" },
  email: { contains: "email" },
  name: {
    contains: "name_contains",
    equals: "name",
  },
  description: {
    contains: "description_contains",
    equals: "description",
  },
  schema_version: {
    equals: "schema_version",
  },
  source_type: {
    contains: "source_type_contains",
    equals: "source_type",
  },
  workflow_type: {
    equals: "workflow_type",
  },
  destination_type: {
    equals: "destination_type",
  },
  created_at: {
    inRange: "",
    lessThanOrEqual: "created_at_before",
    greaterThanOrEqual: "created_at_after",
  },
};

export const getWombatHeaders = ({
  gridApi,
  currentUser,
  deleteWombat,
  gridColumnApi,
  checkBoxRef,
}: {
  gridApi?: GridApi;
  currentUser: any;
  gridColumnApi?: ColumnApi;
  checkBoxRef: any;
  deleteWombat: (wombat: WombatType) => void;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs = [
    {
      headerName: "Select",
      field: "select",
      filter: false,
      headerComponent: () => {
        if (gridApi && gridColumnApi) {
          return <SelectAll gridApi={gridApi} columnApi={gridColumnApi} ref={checkBoxRef} />;
        } else {
          return null;
        }
      },
      // headerValueGetter: function() {
      //   return;
      // },
      resizable: false,
      checkboxSelection: true,
      minWidth: 35,
    },
    {
      field: "id",
      sortable: true,
      hide: true,
      filter: false,
      headerValueGetter: function () {
        return translate("ID");
      },
      minWidth: 100,
    },
    {
      field: "company_id",
      sortable: true,
      hide: false,
      filter: false,
      headerValueGetter: function () {
        return "Global";
      },
      cellRenderer: (params: { data: any }) => {
        return <span>{params.data?.company_id ? "No" : "Yes"}</span>;
      },
      minWidth: 100,
    },
    {
      field: "name",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("name");
      },
      minWidth: 100,
    },
    {
      field: "description",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("description");
      },
      minWidth: 100,
    },
    {
      field: "workflow_type",
      sortable: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      filter: "agTextColumnFilter",
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "CSV/XLSX", value: "file_integration_ingest_csv" },
          { label: "OUTBOUND", value: "file_integration_outbound" },
          { label: "XML", value: "file_integration_ingest_xml" },
        ],
      },
      headerValueGetter: function () {
        return "Workflow Type";
      },
      minWidth: 100,
    },
    {
      field: "schema_version",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return "Schema Version";
      },
      minWidth: 100,
    },
    {
      field: "source_type",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return "Source Type";
      },
      minWidth: 100,
    },
    {
      field: "destination_type",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return "Destination Type";
      },
      minWidth: 100,
    },
    {
      field: "created_at",
      sortable: true,
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return "Created At";
      },
      minWidth: 100,
    },
    {
      field: "ZActions",
      cellRenderer: (params: any) => {
        return <Actions data={params?.data} deleteWombat={deleteWombat} />;
      },
      sortable: false,
      resizable: false,
      maxWidth: 200,
      headerValueGetter: function () {
        return translate("actions");
      },
    },
  ];
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return {
    columnDefs: sortBy(columnDefs, (col) => typeof col?.headerValueGetter === "function" && col.headerValueGetter()),
    defaultOrder,
  };
};

const Actions = ({ data, deleteWombat }: { data: WombatType; deleteWombat: (wombat: WombatType) => void }) => {
  const isGlobal = !data.company_id;
  const history = useHistory();
  const duplicate = useCallback(
    async (id: string) => {
      try {
        const res = await WombatApis.duplicate(id);
        history.push(`/ap/wombatifier/${res.id}/edit`);
      } catch (err) {}
    },
    [data.id],
  );

  return (
    <>
      {
        <Can I="import" a="BulkOperations2Mapper">
          <button
            type="button"
            className="actionButtons icon-add-blue mt-1"
            data-toggle="tooltip"
            title="Duplicate"
            onClick={() => duplicate(`${data.id}`)}
          />
        </Can>
      }
      {!isGlobal ? (
        <Can I="import" a="BulkOperations2Mapper">
          <Link to={`/ap/wombatifier/${data.id}/edit`}>
            <button type="button" className="actionButtons icon-edit mb-1" data-toggle="tooltip" title="View"></button>
          </Link>
        </Can>
      ) : null}

      {!isGlobal ? (
        <Can I="import" a="BulkOperations2Mapper">
          <button
            type="button"
            className="actionButtons delete mt-1"
            data-toggle="tooltip"
            title="Delete"
            onClick={() => deleteWombat(data)}
          ></button>
        </Can>
      ) : null}
    </>
  );
};
