import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import { WrappedFieldProps } from "redux-form";

interface BillDistributionSchedulePickerPropsType extends WrappedFieldProps {
  label?: string;
  input: any;
  required?: boolean;
  tooltip?: string;
  params?: any;
  disabled?: boolean;
  instanceId?: string;
}

type BillDistributionScheduleObjType = {
  id?: number | string;
  name?: string;
  status?: string;
  label?: string;
  value?: number | string;
  external_id?: string;
  description?: string;
};

const BillDistributionSchedulePicker = ({
  label,
  input,
  required,
  tooltip,
  params,
  disabled,
  instanceId = "bill-distribution-schedule-picker",
}: BillDistributionSchedulePickerPropsType) => {
  const [billDistributionSchedules, setBillDistributionSchedules] = useState<BillDistributionScheduleObjType[]>([]);
  const mounted = useRef(false);
  const [selected, setSelected] = useState<BillDistributionScheduleObjType>();

  const parseForSelect = (options: BillDistributionScheduleObjType[]) => {
    return options.map((option: BillDistributionScheduleObjType) => {
      return {
        value: option.id,
        label: option.name,
        status: option.status,
      };
    });
  };

  const getBillDistributionSchedule = useCallback(async () => {
    const apiParams = { status: "ACTIVE", ...params };
    const result: AxiosResponse = await restApiService.get(
      `bill_distribution_schedules`,
      apiParams,
      null,
      true,
      null,
      true,
    );
    if (result?.data) {
      let list = parseForSelect(result?.data);
      if (mounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Bill Distribution Schedule --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setBillDistributionSchedules(list);
      }
      return list;
    }
  }, []);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = options.find((option: BillDistributionScheduleObjType) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        const result = await restApiService.get("bill_distribution_schedules/" + input.value);
        const inactiveBillDistributionSchedules = {
          value: result.data.id,
          label: (
            <>
              {result.data.name}
              {result.data.status === "INACTIVE" && (
                <small style={{ fontSize: "10px" }}> -({result.data.status})</small>
              )}
            </>
          ),
        };
        selectedValues = inactiveBillDistributionSchedules;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    getBillDistributionSchedule().then((res) => {
      findSelected(input, res).then((response: BillDistributionScheduleObjType) => {
        setSelected(response);
      });
    });
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const onChange = (input: any, selected: any) => {
    onChangeSingle(input, selected);
    setSelected(selected);
  };

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={selected}
          isClearable={!required}
          onChange={(selected) => onChange(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={billDistributionSchedules}
          disable={disabled}
          instanceId={instanceId}
          getOptionLabel={(option: BillDistributionScheduleObjType) => {
            return (
              <>
                <span className="formField">{option.label}</span>
                {option.status === "INACTIVE" && <small>{option.status}</small>}
              </>
            );
          }}
          filterOption={(option: any, searchText) =>
            option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
          }
        ></Select>
      </Form.Group>
    </>
  );
};

export default BillDistributionSchedulePicker;
