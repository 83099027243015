import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderCheckBox, RenderField } from "../../../../../../components/forms/bootstrapFields";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";

const IntegrationServiceCorpay = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <Col lg="6">
          <label>Provider Settings</label>
        </Col>
      </Row>

      <Row className="m-0">
        <Col lg="4">
          <Field
            name="form.provider_settings.corpay_company_id"
            label="Company ID"
            component={RenderField}
            id="nvoicepay_company_id"
          />
        </Col>

        <Col lg="8">
          <Field
            name="form.provider_settings.url"
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: "<label>Detail URL Link</label> <small>https://app.bankprovider.com/detail?bach_id= </small>",
                }}
              />
            }
            component={RenderField}
            id="provider_setting_url"
          />
        </Col>

        <Col lg="4">
          <Field
            name="form.provider_settings.is_multi_bank_account"
            label="Multi Bank Account Locations"
            component={RenderCheckBox}
            id="multi_bank_accounts"
          />
        </Col>
      </Row>
    </Col>
  );
};

export { IntegrationServiceCorpay };
