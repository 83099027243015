import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { ReceiptsTypes } from "../../services/admin/expenses/receipts/receiptsType";
import { RootState } from "..";
import { PurchasesTypes } from "services/admin/purchases/purchasesType";
import { BankCardTransactionsType } from "services/admin/bankCardTransactions/bankCardTransactionsType";

type ReceiptReducerState = {
  receiptDetail: null | ReceiptsTypes.Details;
  linkedExpenseDetail: null | ExpensesTypes.Details;
  linkedPurchaseDetail: null | PurchasesTypes.Details;
  linkedBctDetail: null | any;
  showReciptLinker: boolean;
};

const initialState: ReceiptReducerState = {
  receiptDetail: null,
  linkedExpenseDetail: null,
  linkedPurchaseDetail: null,
  linkedBctDetail: null,
  showReciptLinker: false,
};

const receiptSlice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    setReceiptDetails: (state, action: PayloadAction<ReceiptsTypes.Details>) => {
      state.receiptDetail = action.payload;
    },
    setLinkedExpeseDetails: (state, action: PayloadAction<ExpensesTypes.Details>) => {
      state.linkedExpenseDetail = action.payload;
    },
    setLinkedPurchaseDetails: (state, action: PayloadAction<PurchasesTypes.Details>) => {
      state.linkedPurchaseDetail = action.payload;
    },
    setLinkedBctDetails: (state, action: PayloadAction<BankCardTransactionsType.Details>) => {
      state.linkedBctDetail = action.payload;
    },
    setShowReceiptLinker: (state, action: PayloadAction<boolean>) => {
      state.showReciptLinker = action.payload;
    },
    resetReceipt: () => initialState,
  },
});

// Actions
export const {
  setReceiptDetails,
  setLinkedExpeseDetails,
  setLinkedPurchaseDetails,
  setLinkedBctDetails,
  setShowReceiptLinker,
  resetReceipt,
} = receiptSlice.actions;

// Selectors
export const selectReceiptDetail = (state: RootState) => state.receiptRed.receiptDetail;
export const selectLinkedExpenseDetail = (state: RootState) => state.receiptRed.linkedExpenseDetail;
export const selectLinkedPurchaseDetail = (state: RootState) => state.receiptRed.linkedPurchaseDetail;
export const selectLinkedBctDetail = (state: RootState) => state.receiptRed.linkedBctDetail;
export const selectShowReceiptLinker = (state: RootState) => state.receiptRed.showReciptLinker;

export default receiptSlice.reducer;
