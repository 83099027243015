import useFormValue from "components/admin/hooks/useFormValue";
import BusinessUnitPicker, { BusinessUnitObjType } from "components/admin/pickers/reduxFormPickers/businessUnitPicker";
import CustomFieldPicker from "components/admin/pickers/reduxFormPickers/customFieldPicker";
import DepartmentPicker from "components/admin/pickers/reduxFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reduxFormPickers/locationPicker";
import ProjectPicker, {
  ProjectOptionsType,
} from "components/admin/pickers/reduxFormPickers/projectPicker/projectPicker";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import _ from "lodash";
import React, { ChangeEvent, memo, useCallback } from "react";
import { Col, InputGroup, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SingleValue } from "react-select";
import { AppDispatch, RootState, useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { change, Field, getFormValues, WrappedFieldProps } from "redux-form";
import { CategoryTypes } from "services/admin/category/CategoryTypes";
import adminCommonSvc from "services/admin/commonSvc";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { ExpenseConstants } from "services/admin/expenses/expenseSvc";
import commonService from "services/common/commonSvc";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";
import { IUser } from "services/common/user/userTypes";
import { canNotBeZero, required } from "services/validations/reduxFormValidation";
import { MetadataFieldSelector } from "wombatifier/components/metadata_field/selector";
import ExpenseItemCategoryPicker from "../components/expenseItemCategoryPicker";
import style from "./splitExpenseCoding.module.css";

interface RenderFieldNumberProps extends WrappedFieldProps {
  currencySymbol: string;
  className?: string;
}

const RenderFieldAmount: React.FC<RenderFieldNumberProps> = memo(
  ({ input, meta, currencySymbol, className, ...props }) => {
    return (
      <Col>
        <Row>
          <Col className={style.inputAdornmentWrapper}>
            <Row>
              <Col xs="auto" className={style.amountSymbol}>
                {currencySymbol}
              </Col>
              <Col className="p-0">
                <input {...input} {...props} type="number" className={style.amountInput} />
              </Col>
            </Row>
          </Col>
        </Row>
        {meta.touched && meta.error && (
          <Row className="px-0">
            <Col className={style.validationErrorText}>{meta.error}</Col>
          </Row>
        )}
      </Col>
    );
  },
);

const RenderFieldPercent: React.FC<RenderFieldNumberProps> = memo(
  ({ input, meta, currencySymbol, className, ...props }) => {
    return (
      <Col>
        <Row>
          <Col className={style.inputAdornmentWrapper}>
            <Row>
              <Col className="p-0">
                <input {...input} {...props} type="number" className={style.amountInput} />
              </Col>
              <Col xs="auto" className={style.percentSymbol}>
                %
              </Col>
            </Row>
          </Col>
        </Row>
        {meta.touched && meta.error && (
          <Row className="px-0">
            <Col className={style.validationErrorText}>{meta.error}</Col>
          </Row>
        )}
      </Col>
    );
  },
);

const updateDebitAccountsAction =
  (newExpenseDebitAccountAttribute: ExpensesTypes.ExpenseDebitAccount, index: number) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(
      change(
        ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
        `expense_report_debit_accounts_attributes[${index}]`,
        newExpenseDebitAccountAttribute,
      ),
    );
  };

const deleteExpenseItemAction = (index: number) => (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const fs = getFormValues(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM)(
    state,
  ) as ExpensesTypes.SplitExpenseCodingFormData;
  const currentDebitAccountItem = fs.expense_report_debit_accounts_attributes[index];
  const newExpenseDebitAccountAttribute = { ...currentDebitAccountItem, _destroy: 1 };
  dispatch(updateDebitAccountsAction(newExpenseDebitAccountAttribute, index));
};

const getCurrentDebitAccount = (state: RootState, index: number): ExpensesTypes.ExpenseDebitAccount | undefined => {
  const fs = getFormValues(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM)(
    state,
  ) as ExpensesTypes.SplitExpenseCodingFormData;
  return fs.expense_report_debit_accounts_attributes[index];
};

const getExpenseReportDebitAccountsAttributes = (state: RootState): ExpensesTypes.ExpenseDebitAccounts | undefined => {
  const fs = getFormValues(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM)(
    state,
  ) as ExpensesTypes.SplitExpenseCodingFormData;
  return fs.expense_report_debit_accounts_attributes;
};

const getCurrencyRate = (state: RootState): number | undefined => {
  const fs = getFormValues(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM)(
    state,
  ) as ExpensesTypes.SplitExpenseCodingFormData;
  return fs.currency_rate;
};

const getBaseCurrencyTotal = (state: RootState): number | undefined => {
  const fs = getFormValues(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM)(
    state,
  ) as ExpensesTypes.SplitExpenseCodingFormData;
  return fs.base_currency_total;
};

const getTotal = (state: RootState): number | string | undefined => {
  const fs = getFormValues(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM)(
    state,
  ) as ExpensesTypes.SplitExpenseCodingFormData;
  return fs.total;
};

const getCurrencyConversionActive = (state: RootState): boolean | undefined => {
  const fs = getFormValues(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM)(
    state,
  ) as ExpensesTypes.SplitExpenseCodingFormData;
  return fs.currency_conversion_active;
};

const onChangeAmountAction =
  (e: any, index: number, total: number | string | null) => (dispatch: AppDispatch, getState: () => RootState) => {
    const currentUser = getState().user;
    const currentDebitAccountItem = getCurrentDebitAccount(getState(), index);
    let amount: string | number = e.target.value;
    amount = adminCommonSvc.roundUpAmount(Number(amount), null, currentUser);
    let percentage: string | number = (amount / Number(total)) * 100;
    percentage = commonService.roundOfPercentage({ percentage });

    if (amount >= 0 && percentage >= 0) {
      if (amount === 0) {
        amount = "";
        percentage = "";
      }
      const newExpenseDebitAccountAttribute = {
        ...currentDebitAccountItem,
        amount,
        percentage,
      };
      dispatch(updateDebitAccountsAction(newExpenseDebitAccountAttribute, index));
    } else if (currentDebitAccountItem) {
      dispatch(updateDebitAccountsAction(currentDebitAccountItem, index));
    }
  };

const onChangeBaseCurrencyAmountAction =
  (e: any, index: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const currentUser = getState().user;
    const currentDebitAccountItem = getCurrentDebitAccount(getState(), index);

    const total = getTotal(getState());
    const currencyRate = getCurrencyRate(getState());
    const baseCurrencyTotal = getBaseCurrencyTotal(getState());

    let baseCurrencyAmount: string | number = e.target.value;
    baseCurrencyAmount = adminCommonSvc.roundUpAmount(Number(baseCurrencyAmount), null, currentUser);

    const isCurrencyConversionActive = getCurrencyConversionActive(getState());
    const expenseReportDebitAccountsAttributes = getExpenseReportDebitAccountsAttributes(getState());

    let amount = 0;
    if (currencyRate) {
      amount = baseCurrencyAmount * currencyRate;
      amount = adminCommonSvc.roundUpAmount(Number(amount), null, currentUser);
    }

    if (expenseReportDebitAccountsAttributes && isCurrencyConversionActive && baseCurrencyTotal && total) {
      const convertedAmount = expenseItemCommonSvc.getConvertedAmount({
        expenseReportDebitAccountsAttributes,
        index,
        amount,
        currentUser,
        total: Number(total),
        baseCurrencyTotal,
      });
      if (convertedAmount) {
        amount = Number(convertedAmount);
      }
    }

    let percentage = (baseCurrencyAmount / Number(baseCurrencyTotal)) * 100;
    percentage = commonService.roundOfPercentage({ percentage });

    if (baseCurrencyAmount >= 0 && percentage >= 0) {
      const newExpenseDebitAccountAttribute: ExpensesTypes.ExpenseDebitAccount = {
        ...currentDebitAccountItem,
        amount,
        base_currency_amount: baseCurrencyAmount,
        percentage,
        percent: percentage,
      };
      dispatch(updateDebitAccountsAction(newExpenseDebitAccountAttribute, index));
    } else if (currentDebitAccountItem) {
      dispatch(updateDebitAccountsAction(currentDebitAccountItem, index));
    }
  };

const onChangePercentageAction =
  (e: ChangeEvent<HTMLInputElement>, index: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const currentUser = getState().user;
    const inputValueIsBlank = e.target.value.trim() === "";

    let percentage: number = commonService.roundOfPercentage({
      percentage: Number(e.target.value ?? "0"),
    });
    const total = getTotal(getState());

    let amount: number | string = 0;
    let baseCurrencyAmount: number | string = 0;

    const isCurrencyConversionActive = getCurrencyConversionActive(getState());
    if (isCurrencyConversionActive) {
      const currencyRate = getCurrencyRate(getState());
      const baseCurrencyTotal = getBaseCurrencyTotal(getState());
      const expenseReportDebitAccountsAttributes = getExpenseReportDebitAccountsAttributes(getState());

      baseCurrencyAmount = (Number(percentage) / 100) * Number(baseCurrencyTotal);
      baseCurrencyAmount = adminCommonSvc.roundUpAmount(Number(baseCurrencyAmount), null, currentUser);

      if (currencyRate) {
        amount = baseCurrencyAmount * currencyRate;
        amount = adminCommonSvc.roundUpAmount(Number(amount), null, currentUser);
      }

      if (expenseReportDebitAccountsAttributes && baseCurrencyTotal && total) {
        let convertedAmount = expenseItemCommonSvc.getConvertedAmount({
          expenseReportDebitAccountsAttributes,
          index,
          amount,
          currentUser,
          total: Number(total),
          baseCurrencyTotal,
        });
        if (convertedAmount) {
          amount = Number(convertedAmount);
        }
      }
    } else {
      amount = commonService.calculateAmountFromPercentage({
        total: Number(total),
        percentage,
        currentUser,
      });
    }

    const currentDebitAccountItem = getCurrentDebitAccount(getState(), index);
    if (amount >= 0 && percentage >= 0) {
      if (inputValueIsBlank) {
        amount = "";
        baseCurrencyAmount = "";
      }
      const newExpenseDebitAccountAttribute: ExpensesTypes.ExpenseDebitAccount = {
        ...currentDebitAccountItem,
        amount,
        base_currency_amount: baseCurrencyAmount,
        percentage,
      };
      dispatch(updateDebitAccountsAction(newExpenseDebitAccountAttribute, index));
    } else if (currentDebitAccountItem) {
      dispatch(updateDebitAccountsAction(currentDebitAccountItem, index));
    }
  };

const onChangeCategoryAction =
  (category: CategoryTypes.Item, index: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const currentDebitAccountItem = getCurrentDebitAccount(getState(), index);
    // TODO: remove before merge
    // TODO: remove once backend fix it
    const account = Array.isArray(category.accounts) && category.accounts.find((acc, index) => index === 0);
    const updatedExpenseDebitAccount: ExpensesTypes.ExpenseDebitAccount = {
      ...currentDebitAccountItem,
      category_id: category.id,
      category: category,
      category_name: category.name,
      account_id: account ? account.id : null,
      account_name: account ? account.name : null,
    };
    dispatch(updateDebitAccountsAction(updatedExpenseDebitAccount, index));
  };

const onChangeProjectAction =
  (project: SingleValue<void | ProjectOptionsType>, index: number) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const currentDebitAccountItem = getCurrentDebitAccount(getState(), index);
    if (_.isPlainObject(project)) {
      const updatedExpenseDebitAccount: ExpensesTypes.ExpenseDebitAccount = {
        ...currentDebitAccountItem,
        project_id: project?.id,
        project: project || null,
        project_name: project?.name,
      };
      dispatch(updateDebitAccountsAction(updatedExpenseDebitAccount, index));
    } else if (!project) {
      const updatedExpenseDebitAccount: ExpensesTypes.ExpenseDebitAccount = {
        ...currentDebitAccountItem,
        project_id: "",
        project: null,
        project_name: null,
      };
      dispatch(updateDebitAccountsAction(updatedExpenseDebitAccount, index));
    }
  };

const onDepartmentChangeAction =
  (department: any, index: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const currentDebitAccountItem = getCurrentDebitAccount(getState(), index);
    const updatedExpenseDebitAccount: ExpensesTypes.ExpenseDebitAccount = {
      ...currentDebitAccountItem,
      department_id: department?.id ?? null,
      department: department ?? null,
      department_name: department?.name ?? null,
    };
    dispatch(updateDebitAccountsAction(updatedExpenseDebitAccount, index));
  };

const onLocationChangeAction = (location: any, index: number) => (dispatch: AppDispatch, getState: () => RootState) => {
  const currentDebitAccountItem = getCurrentDebitAccount(getState(), index);
  const updatedExpenseDebitAccount: ExpensesTypes.ExpenseDebitAccount = {
    ...currentDebitAccountItem,
    location_id: location?.id ?? null,
    location: location ?? null,
    location_name: location?.name ?? null,
  };
  dispatch(updateDebitAccountsAction(updatedExpenseDebitAccount, index));
};

const onBusinessUnitChangeAction =
  (businessUnit: BusinessUnitObjType, index: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const currentDebitAccountItem = getCurrentDebitAccount(getState(), index);
    const updatedExpenseDebitAccount: ExpensesTypes.ExpenseDebitAccount = {
      ...currentDebitAccountItem,
      business_unit_id: businessUnit?.id ?? null,
      business_unit: businessUnit ?? null,
      business_unit_name: businessUnit?.name ?? null,
    };
    dispatch(updateDebitAccountsAction(updatedExpenseDebitAccount, index));
  };

const DebitAccountLine = ({
  field,
  index,
  // total,
  // currencyCode,
  hasCustomFields,
}: {
  index: number;
  field: string;
  // total: number | string | null;
  // currencyCode: string | undefined;
  hasCustomFields: boolean;
}) => {
  const currentUser = useTypedSelector<IUser>(selectCurrentUser);
  const dispatch = useDispatch();
  const isDeleted = useFormValue<ExpensesTypes.ExpenseDebitAccounts>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    `expense_report_debit_accounts_attributes[${index}]._destroy`,
  );

  const currentDebitAccountItem = useFormValue<ExpensesTypes.ExpenseDebitAccounts>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    `expense_report_debit_accounts_attributes[${index}]`,
  );

  const isCurrencyConversionActive = useFormValue(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    "currency_conversion_active",
  );

  const baseCurrencyTotal = useFormValue<number | string>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    "base_currency_total",
  );
  const total = useFormValue<number | string>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "total");
  const applicableTotal = isCurrencyConversionActive ? baseCurrencyTotal : total;

  const baseCurrencyCode = useFormValue<string>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "base_currency_code");
  const currencyCode = useFormValue<string>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "currency_code");
  const applicableCurrencyCode = isCurrencyConversionActive ? baseCurrencyCode : currencyCode;

  const deleteExpenseItem = () => {
    dispatch(deleteExpenseItemAction(index));
  };

  const onChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(onChangeAmountAction(e, index, applicableTotal!));
  };

  const onChangeBaseCurrencyAmount = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(onChangeBaseCurrencyAmountAction(e, index));
  };

  const onChangePercentage = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(onChangePercentageAction(e, index));
  };

  const onChangeCategory = (category: CategoryTypes.Item) => {
    dispatch(onChangeCategoryAction(category, index));
  };

  const onChangeDepartment = (department: any) => {
    dispatch(onDepartmentChangeAction(department, index));
  };

  const onChangeLocation = (location: any) => {
    dispatch(onLocationChangeAction(location, index));
  };

  const onChangeBusinessUnit = (businessUnit: BusinessUnitObjType) => {
    dispatch(onBusinessUnitChangeAction(businessUnit, index));
  };

  const onChangeProject: (project: SingleValue<void | ProjectOptionsType>) => void = (project) => {
    dispatch(onChangeProjectAction(project, index));
  };

  const normalizeAmount = useCallback(() => {
    return (value: number, previousValue: number) => {
      return value === 0 ? "" : value.toString().replace(/^(\d*\.\d{2})\d*$/, "$1");
    };
  }, []);

  const parentDivRestrictorProps = {
    parentDivId: "Split-Coding",
    parentDivVariance: { right: Number(-250) },
  };

  if (isDeleted) {
    return null;
  }

  return (
    <tr key={index}>
      <td>
        <button
          type="button"
          className="icon icon-delete bg-transparent border-none mt-2"
          onClick={deleteExpenseItem}
        ></button>
      </td>

      {currentUser.company.has_categories && (
        <td>
          <Field
            name={`${field}.category_id`}
            component={ExpenseItemCategoryPicker}
            required
            validate={[required]}
            modelData={currentDebitAccountItem}
            callBackObj={(category: CategoryTypes.Item) => onChangeCategory(category)}
            {...parentDivRestrictorProps}
          />
        </td>
      )}

      {currentUser.company.has_departments && currentUser.company?.expense_item?.show_department && (
        <td>
          <Field
            name={`${field}.department_id`}
            component={DepartmentPicker}
            placeholder="Select"
            modelData={currentDebitAccountItem}
            parentObjData={currentDebitAccountItem}
            callBack={(department: any) => onChangeDepartment(department)}
            validate={currentUser.company.expense_item?.required_department ? [required] : []}
            required={currentUser.company.expense_item?.required_department}
            {...parentDivRestrictorProps}
          />
        </td>
      )}

      {currentUser.company.has_locations && currentUser.company.expense_item?.show_location && (
        <td>
          <Field
            name={`${field}.location_id`}
            component={LocationPicker}
            parentObjData={currentDebitAccountItem}
            placeholder="Select"
            callBack={(location: any) => onChangeLocation(location)}
            modelData={currentDebitAccountItem}
            required={currentUser.company.expense_item?.location_required}
            validate={currentUser.company.expense_item?.location_required ? [required] : []}
            {...parentDivRestrictorProps}
          />
        </td>
      )}

      {currentUser.company?.has_business_units && currentUser.company?.expense_item?.show_business_unit && (
        <td>
          <Field
            name={`${field}.business_unit_id`}
            component={BusinessUnitPicker}
            modelData={currentDebitAccountItem}
            parentObjData={currentDebitAccountItem}
            callBack={onChangeBusinessUnit}
            validate={currentUser.company.expense_item?.required_business_unit ? [required] : []}
            required={currentUser.company.expense_item?.required_business_unit}
            {...parentDivRestrictorProps}
          />
        </td>
      )}

      {currentUser.company.has_projects && currentUser.company.expense_item?.show_project && (
        <td>
          <Field
            name={`${field}.project`}
            component={ProjectPicker}
            placeholder="Select"
            callBack={onChangeProject}
            menuPosition="fixed"
            modelData={currentDebitAccountItem}
            validate={currentUser.company.expense_item?.project_required ? [required] : []}
            required={currentUser.company.expense_item?.project_required}
            {...parentDivRestrictorProps}
          />
        </td>
      )}

      {(hasCustomFields || currentUser.company.has_expense_item_metadata) && (
        <td className={style.customFields + " customFields"}>
          <div className="d-flex">
            <div>
              <ErrorBoundary>
                <CustomFieldPicker
                  inline
                  modelName="ExpenseItem"
                  formFieldName={`expense_report_debit_accounts_attributes[${index}].custom_fields`}
                  formName={ExpenseConstants.SPLIT_EXPENSE_CODING_FORM}
                  modelDataFieldName="expense_report_debit_accounts_attributes"
                  modelData={currentDebitAccountItem}
                  parentObjData={currentDebitAccountItem}
                  menuAutoFixed
                  parentDivId="Split-Coding"
                  parentDivVariance={{ right: Number(-250) }}
                />
              </ErrorBoundary>
            </div>

            <div className={style.dInline}>
              <ErrorBoundary>
                <MetadataFieldSelector
                  inline
                  formName={ExpenseConstants.SPLIT_EXPENSE_CODING_FORM}
                  formSlice={field}
                  modules="ExpenseItem"
                  parentDivId="Split-Coding"
                  parentDivVariance={{ right: Number(-250) }}
                />
              </ErrorBoundary>
            </div>
          </div>
        </td>
      )}

      <td className={style.amountCol}>
        <InputGroup className="mb-3 d-flex flex-nowrap">
          {isCurrencyConversionActive ? (
            <>
              <Field
                name={`${field}.base_currency_amount`}
                onChange={onChangeBaseCurrencyAmount}
                component={RenderFieldAmount}
                min={0}
                normalize={normalizeAmount()}
                required
                currencySymbol={currencySymbolRenderer(applicableCurrencyCode)}
              />
            </>
          ) : (
            <Field
              name={`${field}.amount`}
              onChange={onChangeAmount}
              component={RenderFieldAmount}
              min={0}
              normalize={normalizeAmount()}
              required
              currencySymbol={currencySymbolRenderer(applicableCurrencyCode)}
            />
          )}
        </InputGroup>
      </td>

      <td className={style.percentageCol}>
        <InputGroup className="mb-3 d-flex flex-nowrap">
          <Field
            name={`${field}.percentage`}
            onChange={onChangePercentage}
            component={RenderFieldPercent}
            validate={[required, canNotBeZero]}
            normalize={normalizeAmount()}
            min={0}
            max={100}
            required
          />
        </InputGroup>
      </td>
    </tr>
  );
};

export default memo(DebitAccountLine);
