import { Form } from "react-bootstrap";
import Select from "react-select";
import { countries } from "../../../../app.svc.Lookup";
import { useTranslation } from "react-i18next";
import { Mandatory } from "../../../../forms/bootstrapFields";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
} from "../../../../../services/general/helpers";
import { useEffect, useState } from "react";

const filterOption = (option, searchValue) => {
  const loweredSearch = searchValue.toLowerCase();
  return (
    option.data.name.toLowerCase().includes(loweredSearch) || option.data.value.toLowerCase().includes(loweredSearch)
  );
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
// TODO: move this folder in reduxFormPicker
const CountryPicker = ({
  label,
  input,
  meta: { touched, error, warning },
  isMulti,
  className,
  tooltip,
  required,
  inputId,
  disabled,
}) => {
  const { t } = useTranslation();
  const [displayCountries, setDisplayCountries] = useState([]);

  useEffect(() => {
    setDisplayCountries(
      countries.map((item, i) => {
        let flag = null;
        if (item.flag) {
          //label key contains the name of the country
          flag = (
            <img
              src={require(`../../../../../assets/flags/${item.flag.toLowerCase()}.svg`)}
              height="30px"
              width="30px"
              style={{ padding: 5 }}
              alt="flag"
            />
          );
        }
        return {
          value: item.value, // using item.label to search country by name
          name: item.label,
          label: (
            <div>
              {flag}&nbsp;&nbsp;{item.label}
            </div>
          ),
        };
      }),
    );
  }, []);

  return (
    <>
      {isMulti && (
        <Form.Group>
          {label && (
            <Form.Label>
              {label}
              <Mandatory required={required} />
            </Form.Label>
          )}
          {tooltip}
          <Select
            {...input}
            required={required}
            value={findSelectedMultiple(input, displayCountries)}
            placeholder={t("searchSelect")}
            onChange={(value) => onChange(input, value)}
            onBlur={() => onBlur(input.value)}
            options={displayCountries}
            isMulti={isMulti}
            isClearable
            classNamePrefix="select"
            inputId={inputId ? inputId : "country-selector"}
            isDisabled={disabled}
          />
          {error && touched && <span className="color-error">{error}</span>}
        </Form.Group>
      )}

      {!isMulti && (
        <Form.Group>
          {label && <Form.Label>{label}</Form.Label>}
          {tooltip}
          <Select
            {...input}
            required={required}
            value={findSelectedSingle(input, displayCountries)}
            placeholder={t("searchSelect")}
            onChange={(value) => onChangeSingle(input, value)}
            onBlur={() => onBlurSingle(input, input.value)}
            options={displayCountries}
            isMulti={isMulti}
            isClearable
            classNamePrefix="select"
            filterOption={filterOption}
            inputId={inputId ? inputId : "country-selector"}
            isDisabled={disabled}
          />
          {error && touched && <span className="color-error">{error}</span>}
        </Form.Group>
      )}
    </>
  );
};

export default CountryPicker;
