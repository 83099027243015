import FileUploader from "components/common/fileUploader/fileUploader";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import PoCommonSvc from "services/admin/purchaseOrders/poCommonSvc";
import { IUser } from "services/common/user/userTypes";

const Attachments = () => {
  const { getValues, setValue, register, formState, trigger } = useFormContext();
  const { errors } = formState as any;
  const attachments = useWatch({
    name: "purchase_order.assets_attributes",
    defaultValue: [],
  });
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const poCommonSvc = PoCommonSvc.getInstance(currentUser);
  const purchaseOrder = getValues("purchase_order");

  const uploadAttachments = (files: File[]) => {
    const currentAttachments = getValues("purchase_order.assets_attributes") || [];
    const updateAttachments = [...currentAttachments, ...files];

    setValue("purchase_order.assets_attributes", updateAttachments);
    trigger("purchase_order.assets_attributes");
  };

  const validateAttachments = () => {
    if (purchaseOrder.punchout_id) {
      return true;
    }
    if (
      (currentUser.company?.po_request_is_attachment_required && purchaseOrder?.assets_attributes?.length < 1) ||
      poCommonSvc.isAttachmentRequiredBasedOnAmount(purchaseOrder, currentUser)
    ) {
      return "Attachments are required";
    }
    return true;
  };

  register("purchase_order.assets_attributes", {
    validate: validateAttachments,
  });
  const removeAttachment = (index: number) => {
    const currentAttachments = attachments;
    const updateAttachments = currentAttachments.filter((el: any, i: number) => i !== index);
    setValue("purchase_order.assets_attributes", updateAttachments);
    trigger("purchase_order.assets_attributes");
  };

  const isAttachmentRequired = (po: any) => {
    if (po.punchout_id) {
      return false;
    }
    let attachmentRequired = false;
    if (
      currentUser?.company?.po_request_is_attachment_required ||
      poCommonSvc.isAttachmentRequiredBasedOnAmount(po, currentUser)
    ) {
      attachmentRequired = true;
    }
    return attachmentRequired;
  };
  const getAttachmentMsg = (po: any) => {
    let errMsg;
    if (isAttachmentRequired(po)) {
      errMsg = "(Required)";
    } else {
      errMsg = "(Optional)";
    }
    return errMsg;
  };

  return (
    <>
      <Row>
        <Col md="6">
          <div className="d-flex align-items-center">
            <FileUploader
              customButton={<Button variant="btn btn-secondary">Add Attachments</Button>}
              showUploadBtn
              uploadAttachments={(files: File[]) => uploadAttachments(files)}
              multiple={false}
            />
            <label> {getAttachmentMsg(purchaseOrder)}</label>
          </div>

          {errors?.purchase_order?.assets_attributes && (
            <p className="text-danger">
              {"Kindly attach listed attachments." || errors.purchase_order.assets_attributes.message}
            </p>
          )}

          {!currentUser?.company?.po_request_is_attachment_required && (
            <small>
              "(For example - Copy of Contract for review, Approved Quotations, Purchase Contract Agreement, Purchase
              Order etc)"
            </small>
          )}
          {currentUser.company.po_request_is_attachment_required && (
            <ol>
              <li>Copy of Contract for review</li>
              <li>Security Questionnaire</li>
              <li>If no formal contract, link to online terms and services</li>
              <li>If available, copy of last years contract</li>
              <li>Quotation in the required attachment section.</li>
            </ol>
          )}
        </Col>
        {purchaseOrder?.assets_attributes?.length > 0 || purchaseOrder?.assets?.length > 0 ? (
          <Col md="6">
            {purchaseOrder?.assets_attributes ? (
              <ul>
                {purchaseOrder.assets_attributes?.map((el: any, index: number) => (
                  <li key={index}>
                    <a className="Info link">{el.name || ""}</a>
                    <a>
                      <span onClick={() => removeAttachment(index)}>X</span>
                    </a>
                  </li>
                ))}
              </ul>
            ) : null}

            {purchaseOrder?.assets_attributes ? (
              <ul>{purchaseOrder?.assets?.map((el: any, index: number) => <li key={index}>{el.name || ""}</li>)}</ul>
            ) : null}
          </Col>
        ) : null}
      </Row>
      <div className="clearfix"></div>
    </>
  );
};

export default Attachments;
