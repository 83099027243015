import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import RestApi from "../../../../providers/restApi";
import { parseForSelect } from "../../../../services/general/helpers";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const TermsPicker = (props) => {
  const {
    className,
    input,
    type,
    label,
    required,
    multiple,
    disabled,
    tooltip,
    meta: { touched, error, warning },
  } = props;

  const restApiService = new RestApi();

  const [options, setOptions] = useState([]);

  const getTerms = async () => {
    try {
      const res = await restApiService.get("terms.lk", { ACTIVE: "YES" }, null, true, null, true);

      if (res.data) {
        setOptions(res.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTerms();
    return () => {};
  }, []);

  return (
    <Form.Group className="w-100">
      {label && <Form.Label>{label}</Form.Label>}
      {tooltip ?? ""}
      <Form.Control
        as="select"
        multiple={multiple}
        disabled={disabled}
        {...input}
        isInvalid={touched && !!error}
        isValid={touched && !error}
      >
        {options.map((option, index) => (
          <option key={index} value={option.code}>
            {option.code}
          </option>
        ))}{" "}
      </Form.Control>
      {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};

export default TermsPicker;
