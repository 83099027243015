import React, { memo } from "react";
import commonService from "services/common/commonSvc";
import { CategoryPickerTypes } from "./categoryPickerType";
import SinglePicker from "./singlePicker";

const CategoryPicker = (props: CategoryPickerTypes.TCategoryPickerProps) => {
  const { parentObj, modelData } = commonService.modifyParentModalDataRefStr(props);
  return (
    <>
      {props.isMulti ? (
        // TODO: Implement Multi Category Picker
        <></>
      ) : (
        <SinglePicker {...props} parentObj={parentObj} modelData={modelData} />
      )}
    </>
  );
};

export default memo(CategoryPicker);
