import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { required } from "../../../../../services/validations/reduxFormValidation";
import PaymentType from "../../fields/paymentType";
import Address1 from "../../../../common/managePaymentMethod/fields/address1";
import City from "../../../../common/managePaymentMethod/fields/city";
import State from "../../../../common/managePaymentMethod/fields/state";
import ZipCode from "../../../../common/managePaymentMethod/fields/zipCode";
import Status from "../../../../common/managePaymentMethod/fields/status";
import Primary from "../../../../common/managePaymentMethod/fields/primary";
import { useDispatch } from "react-redux";
import { MANAGE_PAYMENT_METHOD_FORM } from "../../../../../services/common/paymentMethod.svc";
import { change } from "redux-form";

const JapanEft = () => {
  const dispatch = useDispatch();

  //set country code to japan
  const setCountryCodeJapan = () => {
    dispatch(change(MANAGE_PAYMENT_METHOD_FORM, "country", "JP"));
  };

  useEffect(() => {
    setCountryCodeJapan();
  }, []);

  return (
    <>
      <Row>
        <Col md="4">
          <PaymentType label="Payment Type:" required validations={[required]} />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Address1 label={"Bank Address line:"} />
        </Col>
        <Col md="4">
          <City label="City" />
        </Col>
        <Col md="4">
          <State label="State/Province" />
        </Col>
        <Col md="4">
          <ZipCode label="Zip Code/Postal Code: " />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Status label={"Status:"} />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex justify-content-end">
          <Primary label="Default for future invoice payment method" />
        </Col>
      </Row>
    </>
  );
};

export default JapanEft;
