import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import CurrencyCodePicker from "../../../common/pickers/reduxFormPickers/currencyCodePicker";
import _ from "lodash";
import useAdminCompanyCurrencyCode from "../../hooks/useAdminCompanyCurrencyCode";

// this is component is used to picker currency code in payment method from
const CurrencyCode = ({ col, isCurrencyRequired, label, validations, isMulti }) => {
  const { companyCurrencies } = useAdminCompanyCurrencyCode();

  return (
    <Col md={col ? col : "6"}>
      <Field
        id="form.currency_code"
        name="form.currency_code"
        component={CurrencyCodePicker}
        label={label}
        required={isCurrencyRequired}
        validate={validations ? validations : []}
        isMulti={isMulti}
        currencyCodeOptions={_.isArray(companyCurrencies) ? companyCurrencies : []}
      />
    </Col>
  );
};

export default CurrencyCode;
