import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import ReceiptsApis from "../../../../services/admin/expenses/receipts/receiptsApis";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import style from "./receipts.module.css";
import receiptService from "../../../../services/admin/expenses/receipts/receiptsSvc";
import useIsMounted from "../../../common/hooks/useIsMounted";
import Panel from "../../../common/panel/panel";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../reducers";
import {
  resetReceipt,
  selectLinkedBctDetail,
  selectLinkedExpenseDetail,
  selectLinkedPurchaseDetail,
  selectReceiptDetail,
  selectShowReceiptLinker,
  setLinkedBctDetails,
  setLinkedExpeseDetails,
  setLinkedPurchaseDetails,
  setReceiptDetails,
  setShowReceiptLinker,
} from "../../../../reducers/admin/receiptsReducer";
import ReceiptStatusPanel from "./receiptStatusPanel";
import { ReceiptsTypes } from "../../../../services/admin/expenses/receipts/receiptsType";
import OcrReceiptInfo from "./ocrReceiptInfo";
import Breadcrumb from "components/common/navigations/breadcrumb";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import { CreateNotification, NotificationType } from "services/general/notifications";
import PurchaseReceiptInfo from "./purchaseReceiptInfo";
import ExpenseReceiptInfo from "./expenseReceiptInfo";
import { EditReceiptDetail } from "./editReceiptDetail";
import { useTranslation } from "react-i18next";
import ReceiptLinker from "./receiptLinker";
import { restApiService } from "providers/restApi";
import SerialAttachments from "components/common/serialAttachments/serialAttachments";
import FileUploader from "components/common/fileUploader/fileUploader";
import { selectCurrentUser } from "reducers/userReducers";
import ExpensesApis from "services/admin/expenses/expensesApis";
import PurchasesApis from "services/admin/purchases/purchasesApis";
import BankCardTransactionReceiptInfo from "./bankCardTransactionReceiptInfo";
import BankCardTransactionApis from "services/admin/bankCardTransactions/bankCardTransactionsApis";
import { Link } from "react-router-dom";

const DetailsReceipts = () => {
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const history = useHistory();
  const { createConfirmModal } = useConfirmModal();

  const { id: receiptId } = useParams<{ id: string }>();

  const receiptDetails = useTypedSelector(selectReceiptDetail);
  const linkedPurchaseDetail = useTypedSelector(selectLinkedPurchaseDetail);
  const linkedExpenseDetail = useTypedSelector(selectLinkedExpenseDetail);
  const linkedBctDetail = useTypedSelector(selectLinkedBctDetail);
  const showReceiptLinkerModal = useTypedSelector(selectShowReceiptLinker);
  const currentUser = useTypedSelector(selectCurrentUser);

  const { t } = useTranslation();

  const isNotMatched = receiptDetails?.status && receiptService.isNotMatched(receiptDetails?.status);
  const isPurchaseItemMatch = receiptService.isPurchaseItemMatch(
    receiptDetails?.status,
    receiptDetails?.documentable_type,
  );
  const isExpenseItemMatch = receiptService.isExpenseItemMatch(
    receiptDetails?.status,
    receiptDetails?.documentable_type,
  );

  const isBankCardTransactionItemMatch = receiptService.isBankCardTransactionItemMatch(
    receiptDetails?.status,
    receiptDetails?.documentable_type,
  );

  const breadcrumbRedirectTab = isNotMatched ? receiptService.listTabs.queue : receiptService.listTabs.matched;

  const getPreviewAssets = () => {
    if (isNotMatched) {
      return receiptDetails?.assets_attributes;
    } else if (isExpenseItemMatch) {
      return linkedExpenseDetail?.assets;
    } else if (isPurchaseItemMatch) {
      return linkedPurchaseDetail?.assets;
    } else if (isBankCardTransactionItemMatch) {
      return linkedBctDetail?.assets;
    }
  };

  const previewAssets = useMemo(getPreviewAssets, [
    isExpenseItemMatch,
    isNotMatched,
    isPurchaseItemMatch,
    linkedExpenseDetail?.assets,
    linkedPurchaseDetail?.assets,
    linkedBctDetail?.assets,
    receiptDetails?.assets_attributes,
  ]);

  const getReceiptDetails = async () => {
    try {
      let result = await ReceiptsApis.getReceipt(receiptId);
      if (isMounted.current && result) {
        dispatch(setReceiptDetails(result));
      }
    } catch (error) {}
  };

  const deleteReceipt = async (receiptDetails: ReceiptsTypes.Details) => {
    if (!receiptDetails?.id) {
      return;
    }
    if (receiptDetails.documentable_type === "Contact") {
      try {
        await ReceiptsApis.deleteReceipt(receiptDetails.id);
        history.push("/ap/inbound_receipts");
        CreateNotification("Success", "Receipt Deleted", NotificationType.success);
      } catch (error) {}
    } else {
      try {
        await ReceiptsApis.unlinkReceipt(receiptDetails.id, currentUser.contact.id);
        await ReceiptsApis.deleteReceipt(receiptDetails.id);
        history.push("/ap/inbound_receipts");
        CreateNotification("Success", "Receipt Deleted", NotificationType.success);
      } catch (error) {}
    }
  };

  const unlinkReceipt = async (receiptDetails: ReceiptsTypes.Details) => {
    if (receiptDetails?.id) {
      try {
        await ReceiptsApis.unlinkReceipt(receiptDetails.id, currentUser.contact.id);
        history.push("/ap/inbound_receipts");
        CreateNotification("Success", "Receipt Unlinked", NotificationType.success);
      } catch (error) {}
    }
  };

  const confirmDelete = () => {
    if (receiptDetails?.id) {
      createConfirmModal({
        title: t("admin.pages.receipts.deleteDialog.header"),
        body: t("admin.pages.receipts.deleteDialog.content"),
        confirmButtonLabel: t("admin.pages.expenseItem.deleteButtonLabel"),
        callBackData: receiptDetails,
        saveCallBack: deleteReceipt,
        cancelCallBack: null,
      });
    }
  };

  const confirmUnlink = () => {
    if (receiptDetails?.id) {
      createConfirmModal({
        title: "Confirm",
        body: `Are you sure, you want to unlink receipt ${receiptDetails.id}`,
        callBackData: receiptDetails,
        saveCallBack: unlinkReceipt,
        cancelCallBack: null,
      });
    }
  };

  const createNewReceipt = async (file: File) => {
    try {
      const result = await ReceiptsApis.addReceipt({
        name: file.name,
        document_type: "RECEIPT",
        documentable_id: currentUser.contact.id,
        documentable_type: "Contact",
        note: "Supporting Document",
        status: receiptService.status.matched,
      });
      return result;
    } catch (error) {
      throw error;
    }
  };

  const uploadAttachmentToNewReceipt = async (
    file: File,
    newReceipt: ReceiptsTypes.Details,
  ): Promise<ReceiptsTypes.Details> => {
    try {
      const formData = new FormData();
      formData.append("inbound_receipt[assets_attributes][0][asset_file]", file);
      formData.append("inbound_receipt[assets_attributes][0][asset_file_file_name]", file.name);
      const response = await restApiService.formWithImage(`inbound_receipts/${newReceipt.id}`, formData, "PATCH");
      return response.data;
    } catch (error) {
      // as upload attachment failed, delete newReceipt
      if (newReceipt.id) await ReceiptsApis.deleteReceipt(newReceipt.id);
      throw error;
    }
  };

  const linkReceiptToExpenseOrPurchase = async (newReceipt: ReceiptsTypes.Details) => {
    try {
      if (newReceipt.id) {
        if (isExpenseItemMatch && linkedExpenseDetail?.id) {
          const result = await receiptService.linkReceipt({
            receiptId: newReceipt.id,
            documentableId: linkedExpenseDetail?.id,
            documentableType: "InvoiceItem",
          });

          // now fetch expesne to update state
          if (linkedExpenseDetail?.id) {
            const expenseDetail = await ExpensesApis.getExpense(linkedExpenseDetail.id);
            dispatch(setLinkedExpeseDetails(expenseDetail));
          }

          return result;
        } else if (isPurchaseItemMatch && linkedPurchaseDetail?.id) {
          const result = await receiptService.linkReceipt({
            receiptId: newReceipt.id,
            documentableId: linkedPurchaseDetail?.id,
            documentableType: "Purchase",
          });

          // now fetch purchase to update state
          if (linkedPurchaseDetail.id) {
            const purchaseDetail = await PurchasesApis.getPurchase(linkedPurchaseDetail.id);
            dispatch(setLinkedPurchaseDetails(purchaseDetail));
          }

          return result;
        } else if (isBankCardTransactionItemMatch && linkedBctDetail?.id) {
          const result = await receiptService.linkReceipt({
            receiptId: newReceipt.id,
            documentableId: linkedBctDetail?.id,
            documentableType: "BankCardTransaction",
          });

          // now fetch bank card transaction to update state
          if (linkedBctDetail.id) {
            const bctDetail = await BankCardTransactionApis.getDetail(linkedBctDetail.id);
            dispatch(setLinkedBctDetails(bctDetail));
          }

          return result;
        }
      }
    } catch (error) {
      // as linking failed, delete newReceipt
      if (newReceipt.id) await ReceiptsApis.deleteReceipt(newReceipt.id);
      throw error;
    }
  };

  const onAttachmentUpload = async (files: File[]) => {
    try {
      if (files[0]) {
        // create new receipt
        const newReceipt = await createNewReceipt(files[0]);

        // upload the attachment file for receipt
        await uploadAttachmentToNewReceipt(files[0], newReceipt);

        // link the receipt with purchase or card by updating documentable_id of receipt to id of purchase or expense
        await linkReceiptToExpenseOrPurchase(newReceipt);
        CreateNotification("Success", "Uploaded Attachment Successfully", NotificationType.success);
      }
    } catch (error) {
      CreateNotification("Failed", "Unable to Upload Attachment", NotificationType.danger);
    }
  };

  const closeEditAndRefresh = () => {
    setEditMode(false);
    getReceiptDetails();
  };

  const openReceiptLinker = () => {
    dispatch(setShowReceiptLinker(true));
  };

  const onComponentMount = () => {
    getReceiptDetails();
  };

  const onComponentUnmount = () => {
    // perform cleanup remove all state values
    dispatch(resetReceipt());
  };

  useEffect(() => {
    onComponentMount();
    return () => {
      onComponentUnmount();
    };
  }, []);

  // getting valid status name and status class to display as value and display value are different
  const statusClassLabel = receiptService.getStatusClassLabel(
    receiptDetails?.status,
    receiptDetails?.documentable_type,
  );

  return (
    <>
      {/* receipt linker modal */}
      {showReceiptLinkerModal && <ReceiptLinker />}

      <Container fluid>
        <div className={style.head}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Breadcrumb
                    breadcrumbItems={[{ name: "Receipts", link: `/ap/inbound_receipts?tab=${breadcrumbRedirectTab}` }]}
                    trailLabel="Receipt Details"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-start jsutify-content-center">
                  <div className={style.merchantName}>
                    {isNotMatched && <>{receiptDetails?.form_data?.merchant ?? "N/A"} </>}
                    {isPurchaseItemMatch && <>{linkedPurchaseDetail?.merchant ?? "N/A"} </>}
                    {isExpenseItemMatch && <>{linkedExpenseDetail?.vendor_name ?? "N/A"} </>}
                  </div>

                  <div className="my-auto">
                    {receiptDetails?.status && receiptDetails.documentable_type && (
                      <div className={`${style.pill} ${style[statusClassLabel?.className]} ml-2`}>
                        {statusClassLabel.label.toUpperCase()}
                      </div>
                    )}
                  </div>
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  {!isEditMode && (
                    <Button variant="secondary" className="mr-2" onClick={() => setEditMode(true)}>
                      <i className="icon icon-edit-black"></i>
                      Edit
                    </Button>
                  )}
                  {isNotMatched && (
                    <>
                      <Link to={`/ap/expense?receiptID=${receiptDetails?.id}`} className="mr-2">
                        <Button variant="secondary">
                          <i
                            style={{ height: 12, width: 12 }}
                            className="d-block float-left icon-add-black mr-2 mt-1"
                          ></i>{" "}
                          Create Expense Item
                        </Button>
                      </Link>

                      <Button variant="secondary" className="mr-2" onClick={openReceiptLinker}>
                        <i style={{ height: 16, width: 16 }} className="d-block float-left icon-link-black mr-2 m-1" />
                        {t("admin.pages.receipts.linkReceipt")}
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Row className={style.body}>
          <Col md="7" className="mt-4">
            <Row className={style.attahcmentPreview}>
              <Col>
                {previewAssets && _.isArray(previewAssets) ? (
                  <SerialAttachments attachments={previewAssets} />
                ) : (
                  <Row className={style.noAttachment}>
                    <Col className={style.text}>Attachment Not found</Col>
                  </Row>
                )}
              </Col>
            </Row>

            {/* additional attachment is only available for matched receipts */}
            {!isNotMatched && (
              <Row className="my-2">
                <Col>
                  <FileUploader
                    customButton={
                      <Button variant="secondary" className={style.uploadAttachmentBtn}>
                        {t("admin.pages.receipts.uploadAdditionalAttachments")}
                      </Button>
                    }
                    showUploadBtn
                    uploadAttachments={(files: File[]) => onAttachmentUpload(files)}
                    multiple={false}
                  />
                </Col>
              </Row>
            )}
          </Col>

          <Col md="5">
            <Row>{receiptDetails && <ReceiptStatusPanel receiptDetails={receiptDetails} />}</Row>
            <Row>
              <Col>
                {
                  // as there is not data for processing state from ocr and card or expense, therefor hide the section
                  receiptDetails?.status !== receiptService.status.processing && !isEditMode && (
                    <Panel cardClass="px-2 py-1">
                      <Row>
                        <Col className={style.detailHead}>Receipt Details</Col>
                      </Row>

                      {isNotMatched && (
                        //until not matched show data extracted by orc
                        <OcrReceiptInfo />
                      )}

                      {isPurchaseItemMatch && <PurchaseReceiptInfo />}

                      {isExpenseItemMatch && <ExpenseReceiptInfo />}

                      {isBankCardTransactionItemMatch && <BankCardTransactionReceiptInfo />}
                      <>
                        <Row>
                          <Col className={style.detailKey + " mb-1"}>Notes</Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Control
                              as="textarea"
                              value={receiptDetails?.note ?? ""}
                              rows={3}
                              readOnly
                            ></Form.Control>
                          </Col>
                        </Row>
                      </>
                    </Panel>
                  )
                }
                {isEditMode && (
                  <Panel cardClass="px-2 py-1">
                    <EditReceiptDetail closeEditAndRefresh={closeEditAndRefresh} />
                  </Panel>
                )}
              </Col>
            </Row>
            <Row>
              {linkedExpenseDetail != null && (
                <Col className="d-flex mt-15">
                  <Button onClick={confirmUnlink} variant="secondary">
                    Unlink Receipt
                  </Button>
                </Col>
              )}
              <Col className="d-flex justify-content-end mt-15">
                <Button onClick={confirmDelete} variant="secondary">
                  <i className={style.icon + " icon-delete-secondary "} />
                  Delete Receipt
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailsReceipts;
