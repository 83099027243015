import React from "react";
import "./datagrid.style.css";
import { withRouter } from "react-router-dom";
import RestApi from "../../providers/restApi";

class ChildCellRouting extends React.Component {
  render() {
    return (
      <>
        {this.props.value && (
          <button className="routingCellButton" onClick={() => this.onView()}>
            {this.props.value}
          </button>
        )}
      </>
    );
  }
  onView = () => {
    var headerName = this.props.colDef.headerName;
    if (this.props.data.hasOwnProperty("purchaser")) {
      switch (headerName) {
        case "Customer":
          return this.navigateTo("/ar/purchasers", this.props.data.purchaser.id);
        case "Vendor":
          return this.redirectToAngular("vendors/", this.props.data.vendor.id);
        case "Virtual Card":
          return this.navigateTo("/ar/virtual_cards", this.props.data.virtual_card.id);
        // case 'noQuick':return  this.resetFilter()
        default:
          return this.navigateDetail();
      }
    } else if (this.props?.colDef?.cellRendererParams?.redirectToAngular) {
      switch (headerName) {
        case "Vendor":
          if (this.props.data?.hasOwnProperty("vendor")) {
            return this.redirectToAngular("vendors/", this.props.data.vendor.id);
          }
          break;
        case "Purchase Order":
          if (this.props.data?.hasOwnProperty("purchase_order")) {
            return this.redirectToAngular("purchase_orders/", this.props.data.purchase_order.id);
          }
          break;
        case "Expense Report":
          if (this.props.data?.hasOwnProperty("expense_report")) {
            return this.redirectToAngular("expense_report/", this.props.data.expense_report.id);
          }
          break;
        default:
          break;
      }
    } else {
      this.navigateDetail();
    }
  };
  navigateDetail = () => {
    const link = this.props.match.path + "/" + this.props.data.id;
    this.props.history.push(link);
  };
  navigateTo = (endpoint, id) => {
    if (id) {
      const link = endpoint + "/" + id;
      this.props.history.push(link);
    }
  };
  redirectToAngular(endpoint, id) {
    window.location = new RestApi().makeAngularURLWithId(endpoint, id);
  }
}

export default withRouter(ChildCellRouting);
