import { ColumnApi, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import NavTabs from "pages/admin/administration/nav";
import RestApi, { restApiService } from "providers/restApi";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import Panel from "components/common/panel/panel";
import { FIELD_NAME_MAP, getBulkUploadsHeaders } from "components/datagrid/girdHeaders/bulkUploadsHeader";
import TabNavigation from "components/navigation/tabNavigation";
import { getParams, saveDefaultOrder } from "services/common/gridService";

import { AxiosResponse } from "axios";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import ToggleFilterButton from "components/datagrid/buttons/toggleFilterButton";
import GridFilterDropdown from "components/datagrid/gridFilterDropdown";
import { sortBy } from "lodash";
import { useTypedSelector } from "reducers";
import DocumentApis from "services/common/documents/documentApis";
import styles from "./bulkUpload.module.css";
import {
  DocumentableTypes,
  DocumentFields,
  ObjectCreationResults,
  ObjectCreationResultsLineObjects,
  ParsingResults,
  Wombat,
} from "./bulkUploads.models";

const GRID_STORAGE_NAME = "listBulkUpload";
const FIXED_PARAMS = {
  [DocumentFields.DocumentableType]: DocumentableTypes.Company,
};
let WOMBAT_SOURCE_HASH: { [key: string]: Wombat } = {};

let BulkUploadsFilesGrid = (props: any) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const { showFilters, updateShowFilters } = useShowFilterState("listBulkUploadFilter", true);
  const currentUser = useTypedSelector((state) => state.user);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: getRows });
  };

  const getRows = async (params: IServerSideGetRowsParams) => {
    try {
      const result = await DocumentApis.getListPaginated({
        filter: { ...getParams(params, FIELD_NAME_MAP), ...FIXED_PARAMS },
      });
      params.success({
        rowData: result.data.map((document: any) => {
          if (document?.form_data?.object_creation_results?.results) {
            document.form_data.object_creation_results.results = sortBy(
              document.form_data.object_creation_results.results,
              (row) => row.row_number,
            );
          }
          return document;
        }),
        rowCount: result.meta.count,
      });
    } catch (error) {
      params.fail();
    }
  };

  useEffect(() => {
    getWombatHashPrms().then((result) => {
      WOMBAT_SOURCE_HASH = result;
    }); // assumes this will be set before the user can expand any rows
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
  }, []);

  const gridHeaders = getBulkUploadsHeaders({ currentUser });

  return (
    <Container fluid={true}>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"Bulk Operations 2.0"} />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Panel>
        <TabNavigation
          navigationTab={[
            {
              path: "/ap/bulk_uploads/add",
              pageName: "Upload",
              isActive: "",
            },
            {
              path: "/ap/bulk_uploads",
              pageName: "All Uploads",
              isActive: "active",
            },
          ]}
        />
        <Row className="px-2">
          <Col md="12" className="px-0 d-flex justify-content-between">
            <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} gridStorageName={GRID_STORAGE_NAME} />
            <ToggleFilterButton
              clickCallback={() => {
                updateShowFilters(!showFilters);
              }}
            />
          </Col>
        </Row>
        <Row style={{ height: "70vh" }} className="p-2">
          <ServerSideDataGrid
            columnDefs={gridHeaders.columnDefs}
            defaultColDef={{
              resizable: true,
              filter: true,
              floatingFilter: showFilters,
            }}
            gridReady={gridReady}
            gridStorageName={GRID_STORAGE_NAME}
            domLayout="normal"
            paginationSize={25}
            paginationOptions={[25, 50, 100]}
            pagination
            masterDetail={true}
            detailRowAutoHeight={true}
            detailCellRenderer={RowExpandedRenderer}
          />
        </Row>
      </Panel>
    </Container>
  );
};

const RowExpandedRenderer = (row: any) => {
  const documentObject = row.data;
  const documentFormData = documentObject.form_data;
  const parsingResults: ParsingResults = documentFormData?.parsing_results;
  const objectCreationResults: ObjectCreationResults = documentFormData?.object_creation_results;
  const creationResultArray: ObjectCreationResultsLineObjects[] = objectCreationResults?.results
    ? Object.values(objectCreationResults.results)
    : [];
  let message: ReactElement = <span></span>;
  let showResults = false;

  let redirectModuleUrl = "";
  const wombat = WOMBAT_SOURCE_HASH[documentObject.document_type];
  let docTypeCheck = wombat ? wombat.destination_type.toUpperCase() : documentObject.document_type;
  const angularBaseUrl = new RestApi().angularBaseURL();
  switch (docTypeCheck) {
    case "NVP_PAYMENT_FILE":
    case "PAYMENT_FILE":
    case "NVP_INVOICE":
    case "INVOICE":
      redirectModuleUrl = `${new RestApi().angularBaseURL()}invoices_all`;
      break;
    case "NVP_VENDOR":
    case "VENDOR":
      redirectModuleUrl = "vendors";
      break;
    case "USER":
      redirectModuleUrl = `${new RestApi().angularBaseURL()}users`;
      break;
    case "CATEGORY":
      redirectModuleUrl = `${new RestApi().angularBaseURL()}categories`;
      break;
    default:
      break;
  }

  switch (documentObject.status) {
    case "COMPLETED":
      message = (
        <span>
          This document has <span className="text-success">processed</span> successfully
        </span>
      );
      showResults = true;
      break;
    case "COMPLETED_WITH_ERRORS":
      message = (
        <span>
          This document has processed but some lines were not able to be imported. Please review the errors below and
          submit a supplemental file
        </span>
      );
      showResults = true;
      break;
    case "NEW":
      message = (
        <span>
          This document is <span className="text-success">submitted</span> and will be imported shortly.
        </span>
      );
      break;
    case "PARSING":
      message = (
        <span>
          This document is <span className="text-success">parsing</span> and will finish shortly.
        </span>
      );
      break;
    default:
      if (documentObject.status?.endsWith("FAILED")) {
        message = <></>;
        showResults = true;
      }
      break;
  }

  return (
    <div style={{ minHeight: "100px" }} className="ml-3 pt-2 d-flex justify-content-center flex-column">
      <p className={`${styles.gridStatusMessage}`}>{message}</p>
      {showResults && documentFormData && (
        <div className="mt-2">
          {redirectModuleUrl ? (
            <div className="mb-2">
              <a
                target="_blank"
                href={redirectModuleUrl && `${redirectModuleUrl}?source_document_id=${documentObject.id}`}
                rel="noreferrer"
              >
                View Created Records
              </a>
              <hr className={`${styles.errorBlock}`}></hr>
            </div>
          ) : null}
          {!!parsingResults && (
            <div>
              <label className={`${styles.statusLabel}`}>Total Records Parsed: </label>{" "}
              {parsingResults.total_records || 0}
            </div>
          )}
          {parsingResults?.failed_records && (
            <div>
              <label className={`${styles.statusLabel}`}>Failed to Parse Count: </label>{" "}
              {parsingResults.failed_records || 0}
            </div>
          )}
          <div>
            <label className={`${styles.statusLabel}`}>Total Creation Events Count: </label>{" "}
            {objectCreationResults.total_count || 0}
          </div>
          <div>
            <label className={`${styles.statusLabel}`}>Created Count: </label>{" "}
            {objectCreationResults.created_count || 0}
          </div>
          {!!objectCreationResults.error_count && (
            <div>
              <label className={`${styles.statusLabel}`}>Failure Count: </label>{" "}
              {objectCreationResults.error_count || 0}
            </div>
          )}
          {!!objectCreationResults.found_count && (
            <div>
              <label className={`${styles.statusLabel}`}>Existed Count: </label>{" "}
              {objectCreationResults.found_count || 0}
            </div>
          )}
          {!!objectCreationResults.linked_count && (
            <div>
              <label className={`${styles.statusLabel}`}>Linked Count: </label>{" "}
              {objectCreationResults.linked_count || 0}
            </div>
          )}

          {creationResultArray.length && (
            <div className="mt-2 pr-3">
              {/*creationResultArray.length > 100 && (
                <p className={`${styles.statusLabel} text-warning`}>Warning: only the first 50 creation events will be shown</p>
              )*/}

              {/* Convert this to a nested ag-grid */}
              <div className="table-responsive" style={{ height: 400 }}>
                <table className="table bg-white">
                  <thead className="bg-light position-sticky" style={{ top: -1 }}>
                    <tr>
                      <th>Row #</th>
                      <th>Entity Type</th>
                      <th>Entity Number</th>
                      <th>Message</th>
                      <th>Entity ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creationResultArray.map((row) => {
                      // Each row has a potential to have a created a different entity type
                      let entityHref = "";
                      if (row.entity_id) {
                        switch (row.entity_type) {
                          case "Vendor":
                            entityHref = `${angularBaseUrl}vendors/${row.entity_id}`;
                            break;
                          case "Receipt":
                            entityHref = `${angularBaseUrl}receipt/${row.entity_id}`;
                            break;
                          case "Invoice":
                          case "Payment File":
                            entityHref = `${angularBaseUrl}invoices/${row.entity_id}`;
                            break;
                          case "ExpenseReport":
                            entityHref = `/ap/expenses/${row.entity_id}`;
                            break;
                          case "PurchaseOrder":
                            entityHref = `${angularBaseUrl}purchase_orders/${row.entity_id}`;
                            break;
                          case "Subsidiary":
                            entityHref = `${angularBaseUrl}subsidiaries/${row.entity_id}`;
                            break;
                        }
                      }

                      return (
                        <tr key={`${row.entity_type}-${row.row_number}`}>
                          <td>{row.row_number}</td>
                          <td>{row.entity_type}</td>
                          <td>{row.entity_number}</td>
                          <td>{row.message}</td>
                          <td>
                            {row.entity_id ? (
                              entityHref ? (
                                <a target="_blank" href={entityHref} rel="noreferrer">
                                  {row.entity_id}
                                </a>
                              ) : (
                                row.entity_id
                              )
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const getWombatHashPrms = async () => {
  const response: AxiosResponse = await restApiService.get(
    "wombats",
    { workflow_types: ["file_integration_ingest_csv", "file_integration_ingest_xml"] },
    null,
    true,
    null,
    true,
  );
  let result: { [key: string]: Wombat } = {};
  if (response && response.data) {
    response.data.forEach((wombat: Wombat) => {
      result[wombat.source_type] = wombat;
    });
  }
  return result;
};

export default BulkUploadsFilesGrid;
