import React from "react";
import { translate } from "../../../../services/general/translation";
import { companyDateFormat } from "services/general/dateSvc";
import styles from "./schedules.module.css";
import { Row, Col } from "react-bootstrap";
import { useCallback } from "react";
import { SchedulesApis } from "services/admin/reports/schedules/schedulesApis";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { SchedulesFormType } from "services/admin/reports/schedules/schedulesType";

type ActionsType = {
  data: SchedulesFormType;
  callback: () => Promise<void>;
  setEditItem: (schedule: SchedulesFormType) => void;
};

const Actions = ({ data, callback, setEditItem }: ActionsType) => {
  const handleDelete = useCallback(async () => {
    try {
      const res = await SchedulesApis.delete(data.id);
      if (res) {
        await callback();
        CreateNotification("Recurring Data Extract", `Data Extract - ${data.name} Deleted.`, NotificationType.success);
      }
    } catch (err) {}
  }, []);

  return (
    <>
      <Row className="m-0 align-items-center position-relative">
        <Col className="p-0 mx-1">
          <button type="button" className={styles.deleteIcon} onClick={() => setEditItem(data)} data-toggle="tooltip">
            <i className="icon-sm icon-edit" />
          </button>
        </Col>
        <Col className="p-0 mx-1">
          <button type="button" className={styles.deleteIcon} onClick={handleDelete} data-toggle="tooltip">
            <i className="icon-sm icon-delete" />
          </button>
        </Col>
      </Row>
    </>
  );
};

export const getSchedulesHeaders = (
  callback: () => Promise<void>,
  setEditItem: (schedule: SchedulesFormType) => void,
  currentUser: any,
) => {
  return [
    {
      field: "id",
      headerName: "ID",
      filter: "agTextColumnFilter",
      sortable: true,
      headerValueGetter: function () {
        return translate("id");
      },
    },
    {
      field: "name",
      headerName: "Name",
      filter: "agTextColumnFilter",
      sortable: true,
      headerValueGetter: function () {
        return translate("name");
      },
    },
    {
      field: "schedulable.name",
      headerName: "Template Type",
      filter: "agTextColumnFilter",
      headerValueGetter: function () {
        return translate("schedule_name");
      },
    },
    {
      field: "interval",
      headerName: "Frequency",
      filter: "agTextColumnFilter",
      headerValueGetter: function () {
        return translate("frequency");
      },
    },
    {
      field: "created_at",
      headerName: "Creation Date",
      filter: "agTextColumnFilter",
      sortable: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("schedule_creation_date");
      },
    },
    {
      field: "is_active",
      sortable: true,
      headerName: "Status",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("status");
      },
      cellRenderer: (params: any) => {
        return params.value ? "ACTIVE" : "INACTIVE";
      },
    },
    {
      field: "ZActions",
      sortable: false,
      resizable: false,
      filter: false,
      cellRenderer: (params: any) => {
        return <Actions data={params?.data} callback={callback} setEditItem={setEditItem} />;
      },
      headerValueGetter: function () {
        return translate("actions");
      },
      minWidth: 120,
    },
  ];
};
