import _ from "lodash";
import { translate } from "../../../../services/general/translation";
import styles from "./addresses.module.css";
import CustomModal from "components/modals/customModal";
import { useState } from "react";
import { Table } from "react-bootstrap";

const addressesHeader = (cellRendererParams) => {
  let columnDefs = [
    {
      field: "id",
      headerName: "ID",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        return (
          <div role="button" onClick={() => cellRendererParams.editCallback(params.value)} className="link">
            {params.value}
          </div>
        );
      },
      headerValueGetter: function () {
        return translate("id");
      },
      floatingFilter: true,
      minWidth: 100,
    },
    {
      field: "external_id",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "External ID",
      floatingFilter: true,
      hide: true,
      minWidth: 100,
    },
    {
      field: "address_type",
      headerName: "Type",
      minWidth: 100,
    },
    {
      field: "label",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "Label",
      floatingFilter: true,
      minWidth: 100,
    },
    {
      field: "address1",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "Address 1",
      floatingFilter: true,
      minWidth: 100,
    },
    {
      field: "address2",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "Address 2",
      floatingFilter: true,
      minWidth: 100,
    },
    {
      field: "address3",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "Address 3",
      floatingFilter: true,
      minWidth: 100,
    },
    {
      field: "city",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "City",
      floatingFilter: true,
      minWidth: 120,
    },
    {
      field: "state",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "State",
      floatingFilter: true,
      minWidth: 120,
    },
    {
      field: "zipcode",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "Zipcode",
      floatingFilter: true,
      minWidth: 125,
    },
    {
      field: "country",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "Country",
      floatingFilter: true,
      minWidth: 125,
      hide: true,
    },
    {
      field: "email",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerName: "Email",
      floatingFilter: true,
      minWidth: 100,
      hide: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      hide: true,
    },
    {
      field: "is_default",
      headerName: "Is Default",
      minWidth: 100,
      hide: true,
    },
    {
      field: "punchout_details",
      headerName: "Punchout Details",
      valueGetter: (params) => {
        if (params?.data?.punchout_ids) {
          return JSON.stringify(params.data.punchout_ids);
        }
      },
      cellRenderer: function PunchoutDetails(params) {
        const [showMore, setShowMore] = useState(false);
        if (_.isArray(params?.data?.punchout_ids) && _.isPlainObject(params?.data?.punchout_ids[0])) {
          const punchout = params.data.punchout_ids[0];
          const punchoutIds = params.data.punchout_ids;

          return (
            <>
              {showMore && (
                <CustomModal
                  show={showMore}
                  header="Punchout Details"
                  body={
                    <>
                      <Table responsive striped className="text-center">
                        <thead>
                          <tr>
                            <th>Provider</th>
                            <th>Id</th>
                          </tr>
                        </thead>
                        <tbody>
                          {punchoutIds?.map((punchout) => {
                            return (
                              <tr>
                                <td>{punchout.provider_name}</td>
                                <td>{punchout.id}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  }
                  onHide={() => setShowMore(false)}
                />
              )}
              <span>
                {punchout.id ? punchout.provider_name + "-" + punchout.id : punchout.provider_name}
                <span>
                  {punchoutIds.length > 1 && (
                    <button onClick={() => setShowMore(true)} className={`link ${styles.showMoreBtn}`}>
                      See More
                    </button>
                  )}
                </span>
              </span>
            </>
          );
        }
        return null;
      },
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: "childMessageRenderer",
      sortable: false,
      resizable: false,
      minWidth: 230,
      maxWidth: 230,
      cellRendererParams: cellRendererParams,
      cellRendererSelector: function (params) {
        if (params.node.rowPinned) {
          return {
            component: () => null,
          };
        } else {
          // rows that are not pinned don't use any cell renderer
          return undefined;
        }
      },
      floatingFilter: false,
    },
  ];

  // remove punchout col if integration is not present
  if (!cellRendererParams?.currentUser?.company?.has_punchout_integration) {
    columnDefs = columnDefs.filter((colDef) => colDef.field !== "punchout_details");
  }

  const defaultOrder = columnDefs.map((col) => col.field);
  const sortedColDefs = _.sortBy(columnDefs, (col) => col?.headerName);
  return { columnDefs: sortedColDefs, defaultOrder };
};
export default addressesHeader;
