import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import adminCommonSvc from "services/admin/commonSvc";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { IUser } from "services/common/user/userTypes";
import styles from "./payExpense.module.css";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";

const AccountCashBalance = () => {
  const [collapse, setCollapse] = useState(false);
  const [today, setToday] = useState<string>();
  const [balanceData, setBalanceData] = useState<ExpensesTypes.accountBalance[]>();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const stdDate = (d: Date): string => {
    const stringDate = `${d}`;
    let date;
    const now = moment(d);
    date = adminCommonSvc.companyFormatedDate(moment(stringDate).format("YYYY-MM-DD").toString(), currentUser);
    const timeString = now.format("h:mm A");
    return `${date} ${timeString}`;
  };

  const getBalanceProgram = async () => {
    if (currentUser?.company?.id) {
      const data = await ExpensesApis.getAccountCashBalance(currentUser.company.id);
      setBalanceData(data);
      setCollapse(true);
    }
  };

  useEffect(() => {
    getBalanceProgram();
    setToday(stdDate(new Date()));
  }, []);

  return (
    <>
      {currentUser?.company?.has_payoneer_integration ? (
        <div className={`panel panel-default plain_panel ${styles.accountBalanceContainer}`}>
          <div className={`panel-body ${styles.accountBalanceTableContainer}`}>
            <div className={`${styles.accountBalanceHeaderContainer}`}>
              <span className={`${styles.accountBalanceHeader}`}>Connected Accounts Cash Balance</span>
              <button
                onClick={() => {
                  setCollapse((prevState) => !prevState);
                }}
                className={`actionButtons ${collapse ? "icon-caret-up-grey" : "icon-caret-down-grey"}`}
              ></button>
            </div>
            <div className={`${styles.accountBalanceTable}`}>
              {collapse && (
                <Table striped bordered hover responsive className="animated fadeInDown">
                  <tbody className={`${styles.accountTableBody}`}>
                    {balanceData?.length &&
                      balanceData.map((program, index) => {
                        return (
                          <tr key={`${index}`}>
                            <td>
                              <i
                                className={`icon-table-pay-expense icon-pay-payoneer ${styles.accountBalanceIcon}`}
                              ></i>
                            </td>
                            <td>{`${program.program_id} - ${program.currency_code}`}</td>
                            <td>{`${currencySymbolRenderer(program.currency_code)}${program.balance}`}</td>
                            <td>{`Balance accurate as of ${today}`}</td>
                            <td>
                              <i
                                className={`icon-table-pay-expense icon-sync ${styles.accountBalanceReload}`}
                                onClick={getBalanceProgram}
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AccountCashBalance;
