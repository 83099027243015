import Select from "react-select";
import { Form } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { states } from "../app.svc.Lookup";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */
const SelectState = ({
  hideLabel,
  label,
  required,
  disabled,
  findSelected,
  options,
  placeholder,
  callback,
  valid,
  errorMessage,
  validMessage,
}) => (
  <Form.Group>
    {hideLabel !== true && (
      <Form.Label style={{ textTransform: "capitalize" }}>
        {label} {required && <span className={"color-red"}>*</span>}
      </Form.Label>
    )}
    <Select
      required={required}
      isDisabled={disabled}
      value={findSelected()}
      placeholder={placeholder || "Choose one"}
      options={options}
      onChange={(e) => {
        if (callback) {
          callback(e.value);
        }
      }}
    />
    {valid === false && <div style={{ fontSize: "80%", color: "red" }}>{errorMessage}</div>}
    {valid === true && <div style={{ fontSize: "80%", color: "green" }}>{validMessage}</div>}
  </Form.Group>
);

const TextState = ({
  hideLabel,
  label,
  placeholder,
  value,
  callback,
  required,
  disabled,
  valid,
  validMessage,
  errorMessage,
}) => (
  <Form.Group>
    {hideLabel !== true && (
      <Form.Label>
        {label}:{required ? <span className={"color-red"}>*</span> : null}
      </Form.Label>
    )}
    <Form.Control
      required={required}
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        callback(e.target.value);
      }}
      isInvalid={valid === false}
      isValid={valid === true}
      disabled={disabled}
    />
    <Form.Control.Feedback type="valid">{validMessage}</Form.Control.Feedback>
    <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
  </Form.Group>
);

const StatePicker = ({
  label = "",
  required = false,
  disabled = false,
  placeholder = "",
  callback,
  hideLabel = false,
  valid = null,
  value = "",
  validMessage = "",
  errorMessage = "",
  countryCode = "",
}) => {
  const [options, setOptions] = useState([]);

  //Find the option that matches
  const findSelected = useCallback(() => {
    if (options.length > 0) {
      const selectedOptions = options.find((option) => option.value === value);
      if (selectedOptions) {
        return selectedOptions;
      } else {
        return { label: "", value: "" };
      }
    } else {
      return { label: "", value: "" };
    }
  }, [options, value]);

  const fetchStateOptions = useCallback(() => {
    let fetchedOptions = [];
    states.forEach((state) => {
      if (state.value === countryCode) {
        fetchedOptions = state.state_list;
      }
    });
    setOptions(fetchedOptions);
  }, [countryCode]);

  useEffect(() => {
    // as courtryProps change we want to fetch new options
    fetchStateOptions();
  }, [countryCode, fetchStateOptions]);

  useEffect(() => {
    // as options are fetch then we have to check for select options
    findSelected();
  }, [options, findSelected]);

  return (
    <>
      {options && options.length > 0 ? (
        <SelectState
          key={"state-select"}
          label={label}
          hideLabel={hideLabel}
          findSelected={findSelected}
          callback={callback}
          options={options}
          required={required}
          disabled={disabled}
          valid={valid}
          errorMessage={errorMessage}
          placeholder={placeholder}
          validMessage={validMessage}
        />
      ) : (
        <TextState
          key={"state"}
          hideLabel={hideLabel}
          label={label}
          value={value}
          callback={callback}
          required={required}
          disabled={disabled}
          valid={valid}
          validMessage={validMessage}
          errorMessage={errorMessage}
          placeholder={placeholder}
        />
      )}
    </>
  );
};

export default StatePicker;
