import React, { useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectLinkedBctDetail, selectReceiptDetail, setLinkedBctDetails } from "reducers/admin/receiptsReducer";
import style from "./receipts.module.css";
import { companyDateFormat } from "services/general/dateSvc";
import _ from "lodash";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";
import BankCardTransactionApis from "services/admin/bankCardTransactions/bankCardTransactionsApis";

const BankCardTransactionReceiptInfo = () => {
  const dispatch = useDispatch();
  const currentUser = useTypedSelector((state) => state.user);
  const receiptDetail = useTypedSelector(selectReceiptDetail);
  const linkedBctDetail = useTypedSelector(selectLinkedBctDetail);

  const getReceiptLinkedBctDetails = useCallback(async () => {
    try {
      if (receiptDetail?.documentable_id) {
        const result = (await BankCardTransactionApis.getDetail(receiptDetail.documentable_id)) as any;
        dispatch(setLinkedBctDetails(result));
      }
    } catch (error) {}
  }, [dispatch, receiptDetail?.documentable_id]);

  useEffect(() => {
    getReceiptLinkedBctDetails();
  }, [getReceiptLinkedBctDetails]);

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className={style.generalInfo}>
              <dl className="dl-horizontal">
                <div className={style.di}>
                  <dt>Merchant</dt>
                  <dd>{linkedBctDetail?.vendor_name ?? "N/A"}</dd>
                </div>

                <div className={style.di}>
                  <dt>Transaction Date</dt>
                  <dd>{companyDateFormat(linkedBctDetail?.payment_request_date, currentUser) ?? "N/A"}</dd>
                </div>

                <div className={style.di}>
                  <dt>Transaction Amount</dt>
                  <dd>
                    {(linkedBctDetail?.currency_code &&
                    currencySymbolRenderer(linkedBctDetail?.currency_code) + linkedBctDetail?.payment_gross_amount
                      ? linkedBctDetail?.payment_gross_amount?.toFixed(2)
                      : null) ?? "N/A"}
                  </dd>
                </div>

                <div className={style.di}>
                  <dt>File Name</dt>
                  <dd>
                    {(_.isArray(linkedBctDetail?.assets) &&
                      linkedBctDetail?.assets[0] &&
                      linkedBctDetail?.assets[0]?.asset_file_file_name) ??
                      "N/A"}
                  </dd>
                </div>
              </dl>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BankCardTransactionReceiptInfo;
