import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomLabelsApi from "./customLabelsApi";
import { CustomLabelSvc } from "./customLabelsSvc";

export type TCustomLabelsPropsType = { customLabels?: { [key: string]: string[] } };

function useCustomLabel() {
  const { t } = useTranslation();
  const params = useMemo(() => ({ filter: { status: "ACTIVE", hashify: true } }), []);

  const {
    data: customLabels,
    isFetched: isFetchedCustomLabels,
    isError: isCustomLabelsError,
  } = useQuery<any>({
    queryKey: ["customLabels", params],
    queryFn: async () => {
      return await CustomLabelsApi.index(params);
    },
  });

  const getCustomLabel = useCallback(
    (key: string) => CustomLabelSvc.setCustomLabel(customLabels as any, t(key)) as string,
    [customLabels, t],
  );

  return { getCustomLabel, isFetchedCustomLabels, isCustomLabelsError };
}

export default useCustomLabel;
