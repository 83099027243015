import useIsMounted from "components/common/hooks/useIsMounted";
import TooltipRender from "components/toolTip/tooltipRender";
import _ from "lodash";
import moment from "moment";
import { restApiService } from "providers/restApi";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { setMessageObjectId, setMessageObjectName, setMessagePanelOpen } from "reducers/admin/messageSlice";
import { selectCurrentUser } from "reducers/userReducers";
import { change, untouch } from "redux-form";
import ActivityApis from "services/admin/activity/activityApis";
import { ActivityType } from "services/admin/activity/activityType";
import { IDType } from "services/common/types/common.type";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { ContactOptionType } from "../pickers/reduxFormPickers/contactPicker/asyncMultiPicker";
import MessageReplayForm, { MESSAGE_REPLAY_FORM_NAME, MessageReplayFormDataPropsType } from "./formMessageReplay";
import styles from "./messagePanel.module.css";

interface MessagePanelContentPropsType {
  onClose: (arg: boolean) => void;
  objectName: string;
  objectId: IDType;
}

// MessagePanel
const MessagePanelContent = ({ onClose, objectName, objectId }: MessagePanelContentPropsType) => {
  const [currentTab, setCurrentTab] = useState<"internal_chat" | "vendor_chat">("internal_chat");
  const currentUser = useTypedSelector(selectCurrentUser);
  const [activities, setActivities] = useState<ActivityType.List>([]);
  const dispatch = useDispatch();

  const notify_users_required =
    currentTab === "internal_chat" && currentUser?.company?.global?.notify_users_required_on_messages;

  const isActivityPublic = currentTab === "vendor_chat";
  const currentTabActivities = activities.filter((activity) => {
    if (currentTab === "internal_chat") {
      return !activity.public;
    } else {
      return activity.public;
    }
  });

  const [parentObject, setParentObject] = useState<{
    current?: {
      id?: string;
      number?: string;
      name?: string;
      last4?: string;
    };
  }>({});

  const getActivitableType = () => {
    if (objectName === "purchase_orders") {
      return "PurchaseOrder";
    } else if (objectName === "accrual_requests") {
      return "AccrualRequest";
    } else if (objectName === "virtual_cards") {
      return "VirtualCard";
    } else if (objectName === "payment_runs") {
      return "PaymentRun";
    } else {
      return (objectName.charAt(0).toUpperCase() + objectName.slice(1)).replace(/xes$/, "xs").replace(/s$/, "");
    }
  };

  const activitableType = getActivitableType();
  const [contacts, setContacts] = useState<any[]>([]);
  const mounted = useIsMounted();

  const getParsedActivitableType = function (type: string) {
    switch (type) {
      case "Expense_item":
        return "ExpenseItem";
      case "ExpenseReport":
        return "Invoice";
      case "Inbound_receipt":
        return "Document";
      default:
        return type;
    }
  };

  const getActivities = async (activitableType: string, id: IDType) => {
    try {
      if (getParsedActivitableType(activitableType) === "Invoice") {
        const result = await ActivityApis.getActivitiesByObjectName("invoices", +objectId);
        if (result && mounted.current) {
          setActivities(result);
        }
      } else if (getParsedActivitableType(activitableType) === "PurchaseOrder") {
        const result = await ActivityApis.getActivitiesByObjectName("purchase_orders", +objectId);
        if (result && mounted.current) {
          setActivities(result);
        }
      } else if (getParsedActivitableType(activitableType) === "Payment") {
        const result = await ActivityApis.getActivitiesByObjectName("payments", +objectId);
        if (result && mounted.current) {
          setActivities(result);
        }
      } else {
        const result = await ActivityApis.getActivities({
          filter: {
            activitable_type: getParsedActivitableType(activitableType),
            activitable_id: objectId,
            sort: { created_at: { priority: 0, direction: "asc" } },
          },
        });
        if (result && _.isArray(result.data) && mounted.current) {
          setActivities(result.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getContactsApproval = async (modelName: string, id: IDType) => {
    getActivities(activitableType, id);
    try {
      const response = await restApiService.get(modelName + `/${id}`);
      if (mounted.current && response.data) {
        setParentObject((prev) => {
          return { ...prev, current: response.data };
        });
        const contacts = [];
        if (response?.data?.requestor?.email) {
          contacts.push({
            name: response.data.requestor.name,
            email: response.data.requestor.email,
            role: "requestor",
          });
        }
        if (_.isArray(response.data.approval)) {
          response.data.approval.forEach((approval: any) => {
            contacts.push({
              name: approval.name,
              email: approval.email,
              role: "approver",
            });
          });
        }
        if (contacts.length < 1) {
          contacts.push({
            name: currentUser?.name,
            email: currentUser?.email,
            role: currentUser?.role,
          });
        }
        setContacts(contacts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleMessagePanelGeneral = () => {
    getContactsApproval(objectName, objectId);
  };

  const verifyNotifyContacts = (contactsOptions: ContactOptionType[]) => {
    let contacts = contactsOptions.map((opt) => opt.raw);
    const contactAttr: any = [];
    contacts.forEach((contact) => {
      if (_.isPlainObject(contact)) {
        if (contact?.is_team) {
          delete contact.id;
          delete contact.is_team;
        }
        contactAttr.push(contact);
      }
    });
    return contactAttr;
  };

  const submitCommentPanel = async (messageFormData: MessageReplayFormDataPropsType) => {
    try {
      const commentPayload = {
        activity: {
          activity_type: "COMMENT",
          activitable_id: parentObject?.current?.id,
          activitable_type: getParsedActivitableType(activitableType),
          public: isActivityPublic,
          notify_contacts:
            messageFormData.activity?.notify_contacts &&
            verifyNotifyContacts(messageFormData.activity?.notify_contacts),
          description: messageFormData.activity?.description,
        },
      };
      const result: any = await ActivityApis.addActivity(commentPayload);
      CreateNotification("Added", "Comment Added", NotificationType.success);
      if (result) {
        const newActivity: any = result;
        newActivity.user_id = currentUser.id;
        newActivity.handle = currentUser.name;
        newActivity.handle_avatar = currentUser.avatar ? currentUser.avatar.asset_expiring_url || undefined : undefined;
        newActivity.public = isActivityPublic;
        setActivities((prev) => [...prev, newActivity]);
        dispatch(change(MESSAGE_REPLAY_FORM_NAME, "activity.description", "", false, false));
        dispatch(untouch(MESSAGE_REPLAY_FORM_NAME, ...["activity.description"]));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const performCleanUp = () => {
    dispatch(setMessageObjectName(null));
    dispatch(setMessageObjectId(null));
  };

  useEffect(() => {
    if (mounted.current) {
      toggleMessagePanelGeneral();
    }
    return () => {
      performCleanUp();
    };
  }, []);

  return (
    <div className={styles.messagePanel}>
      <Row className={styles.header}>
        <Col md={11}>
          <h3 className={styles.colorDarkBlue}>
            {parentObject.current?.number || parentObject.current?.name || parentObject.current?.last4} Message
          </h3>
        </Col>
        <Col md={1} className="pl-0">
          <i className="icon icon-close mt-2" onClick={() => onClose(false)}></i>
        </Col>
      </Row>
      <Row className={styles.nav}>
        <Col md="12" lg="12" className="p-0">
          <div className={`${styles.navTabsSubtabs} m-2`}>
            <ul className={`nav ${styles.navTabs}`}>
              <li
                role="presentation"
                onClick={() => setCurrentTab("internal_chat")}
                className={`${currentTab === "internal_chat" && styles.active}`}
              >
                <a>Internal Chat</a>
              </li>
              <li
                role="presentation"
                onClick={() => setCurrentTab("vendor_chat")}
                className={`${currentTab === "vendor_chat" && styles.active}`}
              >
                <a>Vendor Chat</a>{" "}
                <TooltipRender
                  className="icon px-ml-4 float-right message-panel-qmark icon-qmark"
                  title={
                    <div className="w-100">
                      <span>Public Chat.</span>
                      <span>This type of chat makes the conversation visible to the Vendor.</span>
                    </div>
                  }
                  placement="top"
                />
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className={styles.body}>
        <Col md="12">
          {currentTabActivities.map((activity) => (
            <Fragment key={activity.id}>
              {activity.activity_type === "COMMENT" && (
                <Row key={activity.id}>
                  {activity.user_id === currentUser.id && (
                    <Col>
                      <Row>
                        <Col className={styles.messageSenderName} md="12">
                          You
                        </Col>
                      </Row>
                      <Row>
                        <Col md="10" className="pr-0">
                          <div className={styles.messageDescriptionSent}>{activity.description}</div>
                        </Col>
                        <Col md="2">
                          <img className={styles.avatar} src={activity.handle_avatar} alt="sender" />
                        </Col>
                      </Row>
                      <Row className={styles.small}>
                        <Col md={{ span: 10 }}>
                          <Row>
                            <Col md="12">
                              <div className="d-flex justify-content-end">
                                {moment(activity.created_at).format("MMM DD, YYYY hh:mm:ss A")}
                              </div>
                            </Col>
                          </Row>
                          {activity.user_id === currentUser.id &&
                            _.isArray(activity?.notify_contacts) &&
                            activity?.notify_contacts.length > 0 && (
                              <Row>
                                <Col className="d-flex justify-content-end" md="12">
                                  Notified Users: {activity?.notify_contacts?.map((contact) => contact.name).join(", ")}
                                </Col>
                              </Row>
                            )}
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {activity.user_id !== currentUser.id && (
                    <Col>
                      <Row>
                        <Col className={styles.messageSenderName} md={{ offset: 2, span: 10 }}>
                          {activity.handle}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <img className={styles.avatar} src={activity.handle_avatar} alt="sender" />
                        </Col>
                        <Col md="10" className="">
                          <div className={styles.messageDescriptionReceive}>{activity.description}</div>
                        </Col>
                      </Row>
                      <Row className={styles.small}>
                        <Col>
                          <div className="d-flex justify-content-end">
                            {moment(activity.created_at).format("MMM DD, YYYY hh:mm:ss A")}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              )}
            </Fragment>
          ))}
        </Col>
      </Row>
      <Row style={styles.footer}>
        <Col className="pt-4">
          <MessageReplayForm onSubmit={submitCommentPanel} notifyRequired={notify_users_required} />
        </Col>
      </Row>
    </div>
  );
};

const MessagePanel = () => {
  const messagePanelOpen = useTypedSelector((state) => state.adminMessagePanelRed.isMessagePanelOpen);
  const messageObjectId = useTypedSelector((state) => state.adminMessagePanelRed.messageObjectId);
  const messageObjectName = useTypedSelector((state) => state.adminMessagePanelRed.messageObjectName);
  const dispatch = useDispatch();

  return (
    <>
      {messagePanelOpen &&
        messageObjectName &&
        messageObjectId &&
        createPortal(
          <MessagePanelContent
            objectName={messageObjectName}
            objectId={messageObjectId}
            onClose={() => dispatch(setMessagePanelOpen(false))}
          />,
          document.body,
        )}
    </>
  );
};

export default MessagePanel;
