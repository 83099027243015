import { AxiosResponse } from "axios";
import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
type TAddressPicker = {
  id?: string;
  label?: string;
  name: string;
  addressableType?: string;
  modelName?: string;
  vendorId?: number;
  required?: boolean;
};

const AddressPicker = ({
  id,
  name,
  addressableType,
  modelName,
  required,
  label = "Vendor Address",
}: TAddressPicker) => {
  const { control, getValues, setValue } = useFormContext();
  const [options, setOptions] = useState<any[]>([]);
  const [inactivePopulated, setInactivePopulated] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const vendorId = getValues("vendor_id");
  const addressId = getValues("address_id");
  const isMounted = useIsMounted();

  const sortedOptions = useMemo(() => {
    if (!options || options.length === 0) return [];
    const sorted = _.sortBy(options, ["address1"]);
    return required ? sorted : [{ label: "-- Select Address --", id: "" }, ...sorted];
  }, [options, required]);

  const initAddressObj = (obj: any) => {
    if (_.isPlainObject(obj)) {
      setValue(name, obj);
    }
  };

  const isInactive = (status: string) => {
    return status === "INACTIVE";
  };

  const getInactiveAddress = async () => {
    try {
      const response: AxiosResponse = await restApiService.get("addresses/" + addressId);
      if (_.isPlainObject(response?.data)) {
        const inactiveObj = response?.data;
        if (inactiveObj) {
          let obj = {
            ...inactiveObj,
            value: inactiveObj.id,
            label: (
              <span key={inactiveObj.id}>
                {inactiveObj.name}{" "}
                {isInactive(inactiveObj.status) && (
                  <span key={inactiveObj.id} className="inactiveOption">
                    {" "}
                    (INACTIVE)
                  </span>
                )}
              </span>
            ),
          };
          setInactivePopulated(true);
          initAddressObj(obj);
        }
      } else {
        initAddressObj(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mergeInactive = async (options: any[]) => {
    // Filter options to find the one with the specified address_id
    const matchingOption = options?.find((option) => option.id === addressId);
    if (matchingOption) {
      // Initialize address object if a matching option is found
      initAddressObj(matchingOption);
    } else if (!inactivePopulated && addressId) {
      // If no match is found and the specified address is not already inactive, retrieve the inactive address
      await getInactiveAddress();
    }
  };

  const getAddresses = useCallback(async () => {
    if (vendorId) {
      const params: Record<string, any> = { status: "ACTIVE" };

      if (vendorId && (modelName === "invoice" || modelName === "credit_memo")) {
        params.vendor = vendorId;
      }

      const result = await restApiService.get("addresses", params, null, true, null, true);
      if (Array.isArray(result.data)) {
        const options = vendorId ? [...result.data] : [];
        mergeInactive(options);
        if (isMounted.current) {
          setOptions(options);
        }
      }
    } else {
      if (isMounted.current) {
        setOptions([]);
      }
    }
  }, [isMounted, modelName, vendorId]);

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  return (
    <>
      <label className="pickerLabel">
        {" "}
        {label} <Mandatory required={required} />{" "}
      </label>
      <Controller
        control={control}
        name={name}
        rules={{ required: required ? "This field is required" : false }}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <>
            <Select
              {...field}
              isClearable={!required}
              value={value ?? null}
              getOptionLabel={(option: { label: any; address1: any }) =>
                option.address1 ? option.address1 : option.label
              }
              getOptionValue={(option: any) => {
                if (!option) return "";
                if (value?.external_id && value.external_id === option.external_id) {
                  return option.external_id;
                }

                const valueString = `${value?.address1 || ""} ${value?.city || ""} ${value?.zipcode || ""}`;
                const optionString = `${option?.address1 || ""} ${option?.city || ""} ${option?.zipcode || ""}`;

                if (valueString === optionString) {
                  return option.address1;
                }
                return option.id;
              }}
              options={sortedOptions}
              required={required}
              onChange={(selected: any) => {
                if (selected) {
                  selected.id = null; //TODO: Tempatorily adding this fix, need to find better way to do this.
                }
                onChange(selected);
              }}
              onMenuOpen={() => setIsDropdownOpen(true)}
              onMenuClose={() => setIsDropdownOpen(false)}
              formatOptionLabel={(option: any) => (
                <div className="pickerSelectedOption">
                  {option.id === "" ? option.label : option.address1}
                  <br />
                  {isDropdownOpen && (
                    <>
                      {option.address1 && <span>{option.address1}</span>}
                      {option.address2 && <span>{option.address2}, </span>}
                      {option.address3 && <span>{option.address3}, </span>}
                      {option.city} {option.state} {option.zipcode} {option.country}
                    </>
                  )}
                </div>
              )}
            />
            {fieldState.error && <PickerErrorBlock error={fieldState.error.message || ""} />}
          </>
        )}
      />
    </>
  );
};

export default AddressPicker;
