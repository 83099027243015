import { REMOVE_EXPENSES_COUNT, SET_EXPENSES_COUNT } from "../../actions/actionTypes";
import { expensesCountType } from "../../services/admin/expenses/expenseReport/expenseReportType";
import { AppDispatch, RootState } from "..";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";

const initialState: expensesCountType = {
  unsubmitted_expense_item_count: 0,
  unsubmitted_expense_report_count: 0,
  total_unsubmitted_expense_items_and_reports: 0,
};

const expensesCountReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): typeof initialState => {
  switch (type) {
    case SET_EXPENSES_COUNT:
      return payload;
    case REMOVE_EXPENSES_COUNT:
      return initialState;
    default:
      return state;
  }
};

// action dispatchers
export const setExpensesCount = (stats: expensesCountType) => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch({ type: SET_EXPENSES_COUNT, payload: stats });
};

export const getUnsubmittedExpensesCount = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const response = await ExpenseReportApis.getUnsubmittedExpensesCount();
    if (response) {
      dispatch(setExpensesCount(response));
    }
  } catch (error) {}
};

export default expensesCountReducer;
