import { NavTabs } from "components/admin/cards/navTabs";
import { CardPaymentDashboard } from "components/admin/cards/payments/dashboard";
import React from "react";
import { Row } from "react-bootstrap";

const Dashboard = () => {
  return (
    <>
      <Row style={{ margin: "10px 25px" }}>
        <NavTabs activePageName="Payments" />
      </Row>
      <CardPaymentDashboard />
    </>
  );
};

export default Dashboard;
