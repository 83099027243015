import { AxiosResponse } from "axios";
import { restApiService } from "../../../../providers/restApi";
import { ReportTemplateDetailType, ReportTemplatesParamType, ReportTemplatesType } from "./reportTemplateType";

export class ReportTemplateApis {
  static getList = async (params?: ReportTemplatesParamType) => {
    try {
      const response: AxiosResponse<ReportTemplatesType> = await restApiService.get(
        "report_templates",
        params,
        null,
        true,
        null,
        true,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  static getDetail = async (id: number) => {
    try {
      const response: AxiosResponse<ReportTemplateDetailType> = await restApiService.get(
        `report_templates/${id}`,
        null,
        null,
        true,
        null,
        true,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
