import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { CreditMemoDetailType, CreditMemoListType, CreditMemoPayloadType } from "./creditMemoTypes";

class CreditMemoApis {
  static async getCreditMemoList() {
    try {
      const response: AxiosResponse<CreditMemoListType[]> = await restApiService.get("vendor_vendor_credits");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getCreditMemo(id: number | string) {
    try {
      const response: AxiosResponse<CreditMemoDetailType> = await restApiService.get("vendor_vendor_credits/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async addCreditMemo(data: CreditMemoPayloadType) {
    try {
      const response: AxiosResponse<CreditMemoDetailType> = await restApiService.post(
        "vendor_vendor_credits/",
        null,
        data,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async editCreditMemo(id: number, data: FormData | CreditMemoPayloadType) {
    try {
      const response: AxiosResponse<CreditMemoDetailType> = await restApiService.patch(
        "vendor_vendor_credits/" + id,
        null,
        data,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
export default CreditMemoApis;
