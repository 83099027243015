import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Field, getFormValues } from "redux-form";
import { RenderCheckBox } from "../../../../../../components/forms/bootstrapFields";
import TooltipRenderQues from "../../../../../../components/toolTip/tooltipRenderQues";
import { INTEGRATION_FORM_NAME } from "../../../../../../reducers/admin/integrationsReducer";
import { showPullPushSetting } from "../../../../../../services/integrationCommonSvc/integrationCommonSvc";

const nonTransactionSettingsPull = [
  {
    name: "form.sync_product_item_pull",
    label: "Product Item",
    system_names: [],
    application_types: ["AI", "AI2"],
  },
  {
    name: "form.sync_product_item_increment",
    label: "Product Item Incremental (10 mins)",
    system_names: [],
    application_types: ["AI"],
  },
  {
    name: "form.sync_category",
    label: "Category",
    system_names: [
      "Accrualify Integration",
      "Accrualify Internal Service",
      "Cambridge",
      "Expensify",
      "Forte",
      // "Intacct", don't show intacct
      "LOB Check",
      "Netsuite",
      "Corpay",
      "Punchout",
      "Quickbooks Enterprise",
      "Quickbook",
      "SAP Cloud",
      "SAP On Premise",
      "VISA_S",
      "Spectrum",
      "SVB ACH",
      "SVB Master Card",
      "Transferwise",
      "US Bank",
      "VISA",
      "Xero",
      "Sage300C",
    ],
    application_types: ["AI", "AI2"],
  },
  {
    name: "form.sync_account",
    label: "COA",
    system_names: [],
    application_types: [],
  },
  {
    name: "form.sync_department",
    label: "Department",
    system_names: [],
    application_types: [],
  },
  {
    name: "form.sync_location",
    label: "Location",
    system_names: [],
    application_types: [],
  },
  {
    name: "form.sync_business_unit",
    label: "Business Unit",
    system_names: [],
    application_types: [],
  },
  {
    name: "form.sync_term",
    label: "Term",
    system_names: [],
    application_types: [],
  },
  {
    name: "form.sync_project",
    label: "Project",
    system_names: [
      "Acumatica",
      "Intacct",
      "Quickbooks Enterprise",
      "Netsuite",
      "Quickbook",
      "Spectrum",
      "Sage300C",
      "Blackbaud",
    ],
    application_types: ["AI", "AI2"],
  },
  {
    name: "form.sync_project_daily_increment",
    label: "Project Incremental (24 hrs)",
    system_names: [
      "Accrualify Integration",
      "Intacct",
      "Quickbooks Enterprise",
      "Netsuite",
      "Quickbook",
      "Spectrum",
      "Sage300C",
    ],
    application_types: ["AI"],
  },
  {
    name: "form.sync_project_increment",
    label: "Project Incremental (10 mins)",
    system_names: ["Intacct", "Quickbooks Enterprise", "Netsuite", "Quickbook", "Spectrum", "Sage300C"],
    application_types: ["AI"],
  },
  {
    name: "form.sync_subsidiary",
    label: "Subsidiary",
    system_names: ["Intacct", "Netsuite", "Sage300C"],
    application_types: ["AI", "AI2"],
  },
  {
    name: "form.sync_grant",
    label: "Grant",
    system_names: ["Intacct"],
    application_types: ["AI", "AI2"],
  },
  {
    name: "form.sync_warehouse",
    label: "Warehouse",
    system_names: ["Intacct", "Acumatica"],
    application_types: ["AI", "AI2"],
  },
  {
    name: "form.sync_bill_distribution_schedule",
    label: "Bill Distribution Schedule",
    system_names: ["Netsuite", "Sage300C"],
    application_types: ["AI"],
  },
  {
    name: "form.sync_units",
    label: "Units",
    system_names: ["Netsuite", "Sage300C"],
    application_types: ["AI", "AI2"],
  },
  {
    name: "form.sync_custom_field.equipments",
    label: "Equipments (custom)",
    system_names: ["Spectrum"],
    application_types: ["AI"],
  },
  {
    name: "form.sync_custom_field.cost_categories",
    label: " Cost Categories (custom)",
    system_names: ["Spectrum"],
    application_types: ["AI"],
  },
];

const NonTransactionSettings = () => {
  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));

  return (
    <Col lg="12">
      <Row className="m-0">
        <Col lg="12" className="p-0 d-flex">
          {form.application_type === "AI2" && (
            <Field
              name="form.allow_non_txn_pull_by_si2"
              label="Allow to pull Non Transactions by SI 2.0 jobs"
              component={RenderCheckBox}
              tooltip={<TooltipRenderQues title={"Allow to pull Non Transactions by SI 2.0 jobs"} />}
              id="non_txn_pull"
              type="checkbox"
            />
          )}
        </Col>
      </Row>
      <Row className="m-0">
        <Col lg="12" className="p-0 d-flex">
          <Form.Label className="mr-2">AI Pull from ERP Non-transactional Settings:</Form.Label>
          <Field name="form.action_refresh_metadata" component={RenderCheckBox} label={"Pull Now"} type="checkbox" />
        </Col>
      </Row>

      <Row className="m-0">
        {nonTransactionSettingsPull.map((setting, index) => {
          if (
            showPullPushSetting(
              form.system_name,
              form.application_type,
              setting.system_names,
              setting.application_types,
            )
          ) {
            return (
              <Col md="4" key={index}>
                <Field name={setting.name} component={RenderCheckBox} label={setting.label} type="checkbox" />
              </Col>
            );
          } else {
            return null;
          }
        })}
      </Row>
    </Col>
  );
};

export default NonTransactionSettings;
