import React, { Component } from "react";
var md5 = require("md5");

export default class Sign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frm_sign: "",
    };
  }
  render() {
    let signCss = ` .signatgure-modal {
                    background-color: #FFFFFF !important;
                    width: 600px !important;`;
    return (
      <div>
        <style dangerouslySetInnerHTML={{ __html: signCss }} />

        <form id="signature-form" name="sign.form" noValidate="novalidate" onSubmit={this.submit} ng-submit="submit()">
          <div className="modal-header">
            <h3 className="modal-title">{this.props.label}</h3>
          </div>
          <div className="modal-body" style={{ textAlign: "center" }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Type Name Here:</label>
                  <input type="text" required onChange={(event) => this.setState({ frm_sign: event.target.value })} />
                </div>
              </div>
            </div>
            <div className="row" style={{ fontFamily: "fantasy", fontSize: "17" }}>
              <div className="col-lg-12">
                <div className="form-group">
                  <textarea
                    readOnly
                    style={{ height: 100, width: 250, textAlign: "center", paddingTop: 30 }}
                    value={this.state.frm_sign}
                  >
                    {this.state.frm_sign}
                  </textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button className="btn btn-secondary" ng-click="cancel()" onClick={this.props.onHide}>
              Cancel
            </button>
            <input type="submit" form="signature-form" className="btn btn-primary" value="Use Signature" />
          </div>
        </form>
      </div>
    );
  }

  submit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.frm_sign) {
      var dt = new Date();
      var actual_sign = this.state.frm_sign + dt;
      let sign_reference = md5(actual_sign);
      console.log(sign_reference);
      let sign_Content = { sign_reference: sign_reference, signature_value: this.state.frm_sign };
      // this.props.updateSignUpW9Form(sign_reference)
      this.props.updateSignUpW9Form(sign_Content);
      this.props.onHide();
    }
  };
}
