import React, { useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import styles from "./payExpense.module.css";
import { useTranslation } from "react-i18next";
import { payReimbursementType } from "../../../../services/admin/expenses/payReimbursementType";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import _ from "lodash";
import { useTypedSelector } from "reducers";

type failType = {
  msg?: { base?: string };
};
type feedbackType = {
  fail: failType[];
  employeeHash: Record<number, number>;
  count: number;
  totalEmployee: number;
};

type BulkApprovalFormType = {
  payout: Record<string, payReimbursementType.payoutType>;
  onClose: (payload: boolean) => void;
  invoices: payReimbursementType.bulkInvoice[];
  fromPage?: string;
};

const FEEDBACK: feedbackType = { fail: [], count: 0, totalEmployee: 0, employeeHash: {} };

const BulkApprovalForm = ({ payout, onClose, invoices, fromPage }: BulkApprovalFormType) => {
  const [successDiv, setSuccessDiv] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);
  const [totalProceedAmount, setTotalProceedAmount] = useState<number>(0);
  const [confirmSubmit, setConfirmSubmit] = useState<boolean>(false);
  const [confirmMsg, setConfirmMsg] = useState<boolean>(false);
  const [feedBack, setFeedBack] = useState(FEEDBACK);
  const [messageQueuedCounter, setMessageQueuedCounter] = useState<number>(0);
  const paymentMethods = Object.keys(payout).sort();
  const { t } = useTranslation();
  const currentUser = useTypedSelector((state) => state.user);

  const checkFeedback = (totalRequest = 0, feedBackPointer: feedbackType, success: boolean) => {
    const feedPointer = feedBackPointer;
    feedPointer.count++;
    setFeedBack(feedPointer);
    if (totalRequest === feedPointer.count) {
      bulkPaymentFeedback(feedPointer, success);
    }
  };

  const bulkPaymentFeedback = (feedBackPointer: feedbackType, success: boolean) => {
    setSuccessDiv(success ? true : false);
  };

  const submitInBulk = async () => {
    if (confirmSubmit) {
      setConfirmMsg(false);
      let processedPayment = 0;
      let message_queued_counter = 0;
      let message_recipient_count = invoices.length;
      let feedbackPointer: feedbackType = _.cloneDeep(FEEDBACK);
      let success = false;
      let responseLength: number = 0;

      try {
        let result: payReimbursementType.bulkReceiptType[];
        if (fromPage === "paymentReview") {
          result = await ExpenseReportApis.createPaymentRun({ expenses: [...invoices] });
        } else {
          result = await ExpenseReportApis.bulkPayApproval({ expense_reports: [...invoices] });
        }
        responseLength = result?.length;
        result.forEach((item) => {
          processedPayment += item.payment?.amount ? item.payment?.amount : 0;
          payout[item.payment_type].successAmt += item.payment?.amount ? item.payment?.amount : 0;
          payout[item.payment_type].successInvoiceCount += item?.invoices.length ? item?.invoices.length : 0;
          message_queued_counter += item?.invoices?.length ? item.invoices.length : 0;
          if (message_queued_counter >= message_recipient_count) {
            setSuccessFlag(() => true);
            success = true;
          }
          feedbackPointer.totalEmployee++;
          setFeedBack(feedbackPointer);
          setMessageQueuedCounter(message_queued_counter);
          checkFeedback(responseLength, feedbackPointer, success);
        });
        setTotalProceedAmount(processedPayment);
      } catch (error: any) {
        console.log(error);
        let obj: { msg?: { base: string } } = {};
        obj.msg = error?.response?.data;
        feedbackPointer.fail.push(obj);
        checkFeedback(responseLength, feedbackPointer, success);
      }
    } else {
      setConfirmMsg(true);
    }
  };

  return (
    <>
      <div className={`${styles.approvalFromContainer}`}>
        {successDiv && feedBack?.totalEmployee > 0 ? (
          <div className={`${styles.bulkSuccessMsg}`}>
            <div className={`alert alert-success ${styles.bulkSuccessMsgText}`}>
              <i
                className={`icon icon-close mt-2 ${styles.bulkSuccessMsgIcon}`}
                onClick={() => {
                  setSuccessDiv((prevState) => !prevState);
                }}
              ></i>
              <strong>Congratulations</strong> - Your payments are being processed for{" "}
              {`${currentUser?.company?.currency?.symbol ? currentUser?.company?.currency?.symbol : ""}${totalProceedAmount ? totalProceedAmount : ""}`}
              :
            </div>
            <div className={`table-responsive ${styles.bulkSuccessMsgTable}`}>
              <Table striped className="table bordered-table">
                <tbody>
                  <tr>
                    <td className={`${styles.bulkSuccessMsgTD}`}>{messageQueuedCounter} # of Expenses Report</td>
                  </tr>
                  <tr>
                    <td className={`${styles.bulkSuccessMsgTD}`}>{feedBack?.totalEmployee} # of Employee</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        ) : null}

        {messageQueuedCounter && invoices && messageQueuedCounter > 0 && messageQueuedCounter < invoices?.length ? (
          <div className={`alert alert-danger ${styles.bulkFailMsg}`}>
            <strong>Note!</strong> Your payment submission has started ..... {messageQueuedCounter} / {invoices?.length}
          </div>
        ) : null}

        {feedBack.fail.length > 0 ? (
          <div className={`alert alert-danger ${styles.bulkFailMsg}`}>
            <strong>
              (<strong>{invoices.length - messageQueuedCounter}</strong>) payments have failed to process.
            </strong>
            <ul>
              {feedBack?.fail.length &&
                feedBack.fail.map((message) => {
                  if (message?.msg?.base) {
                    return <li>{message.msg.base}</li>;
                  }
                  return null;
                })}
            </ul>
          </div>
        ) : null}
        <Row className={`${styles.approvalModalRows}`}>
          <Col>Number of Expense Report Selected:</Col>
          <Col>
            {paymentMethods.map((item, index) => (
              <Row key={`${index}`} className={`${styles.approvalModalRows} ${styles.selectedreportRow}`}>
                <span>{`${payout[item].totalInvoice}(${payout[item].paymentTypeDisplay})`}</span>
              </Row>
            ))}
          </Col>
        </Row>
        <Row className={`${styles.approvalModalRows}`}>
          <Col>Payment Date:</Col>
          <Col>date(s) entered</Col>
        </Row>
        <Row className={`${styles.approvalModalRows}`}>
          <Col>Total Batch Amount:</Col>
          <Col>
            {paymentMethods.map((item, index) => (
              <Row key={`${index}`} className={`${styles.approvalModalRows} ${styles.selectedreportRow}`}>
                <span>{`${payout[item]?.currencySymbol ? payout[item].currencySymbol : ""}${payout[item].totalAmt} (${payout[item].paymentTypeDisplay})`}</span>
              </Row>
            ))}
          </Col>
        </Row>
        <Row className={`${styles.approvalModalRows}`}>
          <Col>
            <label className={`${styles.textInfo}`}> {`Note: Payment Approval Workflow is Detected!`}</label>
            <small>
              The selected expenses report(s) will be submitted for payment approval. After the payment is approved by
              the final approver, it will be released for payment on the later of the final approval date or a future
              scheduled payment date.
            </small>
          </Col>
        </Row>

        <Row>
          <Col>
            <label className={`${styles.textConfirm}`}> {`Confirm Transaction:`}</label>
            <Form.Check
              label={" I Confirm these expenses report payments are authorized and accurate."}
              onChange={(value) => setConfirmSubmit(value.target.checked)}
            />
            {!confirmSubmit && confirmMsg && (
              <div className={`${styles.bulkFailMsgText}`}>
                <small>Field is required.</small>
              </div>
            )}
          </Col>
          <Row></Row>
        </Row>
        <Row className={`${styles.approvalModalRows}`}>
          <Col className="d-flex justify-content-end">
            <Button id="address_cancel_btn" variant="secondary" onClick={() => onClose(false)} className="mx-2">
              Close
            </Button>
            {!successFlag && (
              <Button
                onClick={() => {
                  submitInBulk();
                }}
                type="submit"
                id="address_submit_btn"
                disabled={messageQueuedCounter > 1}
              >
                {t("submit")}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BulkApprovalForm;
