import React, { useCallback, useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import restApi from "../../../../providers/restApi";
import Select from "react-select";
import Styles from "../override.module.css";
import {
  compare,
  findSelectedMultiple,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../services/general/helpers";
import { team } from "../../../../lookups/approvalWorkFlowLookups";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const TeamsPicker = (props) => {
  let {
    className,
    tooltip,
    label,
    input,
    meta: { error, touched, warning },
    isMulti,
  } = props;

  const [teams, setTeams] = useState([]);
  const mounted = useRef(false);

  const restApiService = new restApi();

  const getTeams = useCallback(async () => {
    const result = await restApiService.get("teams", null, null, true, null, true);
    const value = parseForSelect(result.data);
    if (mounted.current) setTeams([...value, ...team].sort(compare));
  }, [restApiService]);

  useEffect(() => {
    getTeams();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  if (teams.length)
    return (
      <>
        {!isMulti && (
          <Form.Group className={Styles.select}>
            {label && (
              <Form.Label>
                {" "}
                {label ?? ""}
                {tooltip ?? ""}
              </Form.Label>
            )}
            <Select
              {...input}
              value={findSelectedMultiple(input, teams)}
              placeholder="search/select"
              onChange={(value) => onChangeSingle(input, value)}
              onBlur={() => onBlurSingle(input, input.value)}
              options={teams}
              isMulti={isMulti}
              isClearable
              classNamePrefix="select"
            />
            {error && touched && <div style={{ fontSize: "80%", color: "red", padding: "2px" }}>Team Required...</div>}
          </Form.Group>
        )}
        {isMulti && (
          <Form.Group className={Styles.select}>
            <Select
              {...input}
              value={findSelectedMultiple(input, teams)}
              placeholder="search/select"
              onChange={(value) => onChange(input, value)}
              onBlur={() => onBlur(input, input.value)}
              options={teams}
              isMulti={isMulti}
              isClearable
              classNamePrefix="select"
            />
            {error && touched && (
              <div style={{ fontSize: "80%", color: "red", padding: "2px" }}> Team Required... </div>
            )}
          </Form.Group>
        )}
      </>
    );
  else return <div></div>;
};

export default TeamsPicker;
