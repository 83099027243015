import React from "react";
import DropdownFilter from "../reportFilters/dropdown";
import DateFilter from "../reportFilters/date";
import CheckboxFilter from "../reportFilters/checkbox";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";

const TransactionApprovalsListing = ({ scheduled }: { scheduled?: boolean }) => {
  const approvable_types_options = [
    { value: "", label: "ALL" },
    { value: "PurchaseOrder", label: "PURCHASE ORDER" },
    { value: "Invoice", label: "INVOICE" },
    { value: "Payment", label: "PAYMENT" },
    { value: "Vendor", label: "VENDOR" },
    { value: "VirtualCard", label: "CORPORATE CARD" },
    { value: "ExpenseReport", label: "EXPENSE REIMBURSEMENT" },
    { value: "PaymentRun", label: "PAYMENT RUN" },
  ];

  const aasm_state_options = [
    { value: "", label: "all" },
    { value: "new", label: "new" },
    { value: "sent", label: "sent" },
    { value: "viewed", label: "viewed" },
    { value: "approved", label: "approved" },
    { value: "rejected", label: "rejected" },
  ];

  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    } else {
      return (
        <>
          <DateFilter name={"Start Date"} code={"start_date"} options={[]} isRequired />
          <DateFilter name={"End Date"} code={"end_date"} options={[]} isRequired />
        </>
      );
    }
  };

  return (
    <div>
      {renderDateFilter()}
      <DropdownFilter name={"Approval Type"} code={"approvable_type"} options={approvable_types_options} />
      <DropdownFilter name={"Approval Status"} code={"aasm_state"} options={aasm_state_options} />
    </div>
  );
};

export default TransactionApprovalsListing;
