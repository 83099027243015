import Add from "./add";
import Edit from "./edit";

export const adminUsersRoute = [
  {
    path: "/ap/add_user",
    exact: true,
    component: Add,
    authorization: {
      I: "add",
      a: "Users",
    },
  },
  {
    path: "/ap/edit_user/:id(\\d+)",
    exact: true,
    component: Edit,
    authorization: {
      I: "edit",
      a: "Users",
    },
  },
];
