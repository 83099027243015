import React from "react";
import { Field } from "redux-form";
import { Col, Form, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useSelector, useDispatch } from "react-redux";
import TooltipRenderQues from "../../../../../../components/toolTip/tooltipRenderQues";
import { generalSettings } from "./generalSettings";
import PopoverRender from "../../../../../../components/popovers/popoverRender";
import { RenderTextArea } from "../../../../../../components/forms/bootstrapFields";
import CommonSettingField from "../../components/commonSettingField";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

const GeneralSettings = () => {
  const formName = "companyDefaultForm";
  const dispatch = useDispatch();

  const isShowObj = {};
  const company_default = useSelector((state) => state.form.companyDefaultForm.values.company_default);
  const company = useSelector((state) => state.form.companyDefaultForm.values.company);

  const getNote = function (data) {
    var txt = data ? data : "Sample note 1<br/> Sample note 2<br/> Sample note 3";

    if (txt) {
      const notes = txt.split("<br/>");

      var tag = "<ul>";
      notes.forEach(function (obj, key) {
        tag += "<li>" + obj + "</li>";
      });
      tag += "</ul>";
      return tag;
    }
  };

  const current_user = useSelector((state) => state.user);
  isShowObj.company_default = company_default;
  isShowObj.company = company;
  isShowObj.current_user = current_user;
  const onChangeObj = {};
  onChangeObj.formName = formName;
  onChangeObj.dispatch = dispatch;
  onChangeObj.company_default = company_default;
  onChangeObj.company = company;

  const customPropsObj = {};
  const appCurrencyCodes = useSelector(selectAppCurrecyCode);
  customPropsObj.appCurrencyCodes = appCurrencyCodes;

  return (
    <Container fluid={true} className={"mt-3"}>
      <Row>
        <Col md="6" className="pl-0">
          {/* coloum no. 1 */}
          {generalSettings
            .filter((setting) => setting.col === 1)
            .map((setting, index) => (
              <CommonSettingField
                key={index}
                setting={setting}
                isShowObj={isShowObj}
                onChangeObj={onChangeObj}
                customPropsObj={customPropsObj}
              />
            ))}
        </Col>
        <Col md="6">
          {/* coloum no. 2 */}
          {generalSettings
            .filter((setting) => setting.col === 2)
            .map((setting, index) => (
              <CommonSettingField
                key={index}
                setting={setting}
                isShowObj={isShowObj}
                onChangeObj={onChangeObj}
                customPropsObj={customPropsObj}
              />
            ))}
        </Col>
      </Row>
      <Row>
        <Col lg={"12"} className="pl-0 m-0">
          <Row className="m-0 justify-content-between">
            <Form.Label>
              Notes{" "}
              <TooltipRenderQues
                title="Notes will be listed on PO Print. Please use <br/> to break the line and show with bullet points"
                placement="top"
              ></TooltipRenderQues>
            </Form.Label>
            <PopoverRender
              placement={"top"}
              id="notes_popover"
              trigger="click"
              title="Notes Preview"
              className="icon icon-view"
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: getNote(company_default?.default_po_print_notes),
                  }}
                />
              }
            />
          </Row>
          <Field id="po_notes" name="company_default.default_po_print_notes" component={RenderTextArea} rows="5" />
        </Col>
      </Row>
    </Container>
  );
};

export default GeneralSettings;
