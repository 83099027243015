import React from "react";
import { Table } from "react-bootstrap";
import style from "./table.module.css";

type SecondaryTablePropType = React.ComponentProps<typeof Table> & {
  bordered?: boolean;
  striped?: boolean;
  hover?: boolean;
  textLeft?: boolean;
};

export const SecondaryTable = ({ bordered, hover, striped, textLeft, className, ...rest }: SecondaryTablePropType) => {
  return (
    <Table
      {...rest}
      bordered={bordered}
      striped={striped}
      hover={hover}
      responsive
      className={`mb-0 ${Boolean(textLeft) && "text-left"} ` + style.secondaryTable + " " + className}
    />
  );
};
