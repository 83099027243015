import { ColDef, ColumnApi, GridApi, HeaderValueGetterParams } from "ag-grid-community";
import SelectAll from "components/common/dataGrid/selectAllFunctional";
import TooltipRender from "components/toolTip/tooltipRender";
import _, { sortBy } from "lodash";
import React from "react";
import { setAllowedFilters } from "services/common/gridService";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { IUser } from "services/common/user/userTypes";
import { companyDateTimeFormat } from "services/general/dateSvc";
import { translate } from "services/general/translation";
import UploadedDocuments from "./uploadedDocuments";
import styles from "../vendors.module.css";

export const FIELD_NAME_MAP: FieldFilterMapType = {
  vendor_id: {
    contains: "vendor_id_contains",
    equals: "vendor_id",
  },
  name: {
    contains: "name",
    blank: "name_has_data",
    notBlank: "name_has_data",
  },
  "profile_completeness.contact_info": {
    equals: "has_contact_info",
    default: "has_contact_info",
  },
  "profile_completeness.bank_info": {
    equals: "has_bank_info",
    default: "has_bank_info",
  },
  "profile_completeness.company_detail": {
    default: "has_company_detail",
  },
  "contact.welcome_invite_date": {
    default: "has_last_invite_sent",
  },
  "contact.last_sign_in_at": {
    inRange: "",
    lessThanOrEqual: "before_last_signed_in",
    greaterThanOrEqual: "after_last_signed_in",
  },
  uploaded_required_documents: {
    default: "has_required_documents",
  },
};

export const getVendorsHeaders = ({
  gridApi,
  gridColumnApi,
  checkBoxRef,
  currentUser,
}: {
  gridApi?: GridApi;
  gridColumnApi?: ColumnApi;
  checkBoxRef: any;
  currentUser: IUser;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs: ColDef[] = [
    {
      headerName: "Select",
      field: "select",
      filter: false,
      headerComponent: () => {
        if (gridApi && gridColumnApi) {
          return <SelectAll gridApi={gridApi} columnApi={gridColumnApi} ref={checkBoxRef} />;
        } else {
          return null;
        }
      },
      // headerValueGetter: function() {
      //   return null;
      // },
      resizable: false,
      checkboxSelection: true,
      minWidth: 35,
    },
    {
      field: "vendor_id",
      headerName: "Vendor ID",
      filter: "agTextColumnFilter",
      cellRendererParams: {
        viewLink: "vendors",
        authorization: {
          I: "read",
          a: "Vendors",
        },
      },
      cellRenderer: "randomChildCellRouting",
      headerValueGetter: function () {
        return translate("vendor_id");
      },
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRendererParams: {
        viewLink: "vendors",
        authorization: {
          I: "read",
          a: "Vendors",
        },
      },
      cellRenderer: "randomChildCellRouting",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("name");
      },
      minWidth: 180,
    },
    {
      field: "status",
      headerName: "Status",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "ACTIVE", value: "ACTIVE" },
          { label: "INACTIVE", value: "INACTIVE" },
          { label: "NEW", value: "NEW" },
          { label: "PENDING", value: "PENDING" },
          { label: "REJECTED", value: "REJECTED" },
        ],
      },
      headerValueGetter: function () {
        return translate("status");
      },
      minWidth: 80,
    },
    {
      field: "profile_completeness.contact_info",
      headerName: "Has Contact Info",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellClass: ["d-flex", "justify-content-center"],
      cellRenderer: (params: any) => <i className={`icon-20 ${params.value ? "icon-approve" : "icon-reject"}`} />,
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Yes", value: "1" },
          { label: "No", value: "2" },
        ],
      },
      headerValueGetter: function () {
        return translate("hasContactInfo");
      },
      headerClass: styles.agGridHeaderColor,
      headerComponent: function () {
        return (
          <>
            {translate("hasContactInfo")}
            <TooltipRender
              title="The vendor has successfully uploaded their necessary company information into the Vendor Portal"
              className="icon-20 icon-info-blue mt-1 ml-1"
              placement="top"
            />
          </>
        );
      },
      minWidth: 121,
    },
    {
      field: "profile_completeness.bank_info",
      headerName: "Has Bank Info",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellClass: ["d-flex", "justify-content-center"],
      cellRenderer: (params: any) => <i className={`icon-20 ${params.value ? "icon-approve" : "icon-reject"}`} />,
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Yes", value: "1" },
          { label: "No", value: "2" },
        ],
      },
      headerValueGetter: function () {
        return translate("hasBankInfo");
      },
      headerClass: styles.agGridHeaderColor,
      headerComponent: function () {
        return (
          <>
            {translate("hasBankInfo")}
            <TooltipRender
              title="The vendor has successfully uploaded their necessary banking information into the Vendor Portal"
              className="icon-20 icon-info-blue mt-1 ml-1"
              placement="top"
            />
          </>
        );
      },
      minWidth: 105,
    },
    {
      field: "profile_completeness.company_detail",
      headerName: "Has Company Info",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellClass: ["d-flex", "justify-content-center"],
      cellRenderer: (params: any) => <i className={`icon-20 ${params.value ? "icon-approve" : "icon-reject"}`} />,
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Yes", value: "1" },
          { label: "No", value: "2" },
        ],
      },
      headerValueGetter: function () {
        return translate("hasCompanyDetails");
      },
      headerClass: styles.agGridHeaderColor,
      headerComponent: function () {
        return (
          <>
            {translate("hasCompanyDetails")}
            <TooltipRender
              title="The vendor has successfully uploaded their necessary company details into the Vendor Portal"
              className="icon-20 icon-info-blue mt-1 ml-1"
              placement="top"
            />
          </>
        );
      },
      minWidth: 146,
    },
    {
      field: "contact.welcome_invite_date",
      headerName: "last_invite_sent",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => companyDateTimeFormat(params.value, currentUser),
      headerValueGetter: function () {
        return translate("lastInviteSent");
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Yes", value: "1" },
          { label: "No", value: "2" },
        ],
      },
      headerClass: styles.agGridHeaderColor,
      headerComponent: function () {
        return (
          <>
            {translate("lastInviteSent")}
            <TooltipRender
              title="The timestamp shown is when the most recent Vendor Portal invite was sent out to the vendor"
              className="icon-20 icon-info-blue mt-1 ml-1"
              placement="top"
            />
          </>
        );
      },
      minWidth: 150,
    },
    {
      field: "contact.last_sign_in_at",
      headerName: "last_signed_in",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => companyDateTimeFormat(params.value, currentUser),
      headerValueGetter: function () {
        return translate("lastSignedIn");
      },
      headerClass: styles.agGridHeaderColor,
      headerComponent: function () {
        return (
          <>
            {translate("lastSignedIn")}
            <TooltipRender
              title="The timestamp shown is the vendor’s most recent login into the Vendor Portal"
              className="icon-20 icon-info-blue mt-1 ml-1"
              placement="top"
            />
          </>
        );
      },
      minWidth: 150,
    },
    {
      field: "uploaded_required_documents",
      headerName: "required_documents",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellClass: ["d-flex", "justify-content-center"],
      cellRenderer: (params: any) => (
        <>
          {params.value}/{params.data.total_required_documents}
        </>
      ),
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Yes", value: "1" },
          { label: "No", value: "2" },
        ],
      },
      headerValueGetter: function () {
        return translate("requiredDocuments");
      },
      headerClass: "whiteSpaceNormal",
      headerComponent: function () {
        return (
          <>
            <span className={"d-flex flex-row " + styles.agGridHeaderColor}>
              {translate("requiredDocuments")}
              <TooltipRender
                title="The amount of required documents the vendor has uploaded, out of how many are available"
                className="icon-20 icon-info-blue px-mt-10 ml-1"
                placement="top"
              />
            </span>
          </>
        );
      },
      minWidth: 100,
    },
    {
      field: "uploaded Doucments",
      sortable: false,
      resizable: false,
      filter: false,
      cellClass: ["d-flex", "justify-content-center"],
      cellRenderer: (params: any) => <UploadedDocuments id={params.data.id} name={params.data.name} />,
      headerValueGetter: function () {
        return translate("uploadedDocuments");
      },
      headerClass: "whiteSpaceNormal",
      headerComponent: function () {
        return (
          <>
            <span className={"d-flex flex-row " + styles.agGridHeaderColor}>
              {translate("uploadedDocuments")}
              <TooltipRender
                title="Select the ‘view’ button below to see the required documents that the vendor has uploaded"
                className="icon-20 icon-info-blue px-mt-10 ml-1"
                placement="top"
                containerClassName="d-inline-block"
              />
            </span>
          </>
        );
      },
      minWidth: 100,
    },
  ];
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  const obj: any = {}; // TODO: fix it
  return {
    columnDefs: sortBy(columnDefs, (col) => typeof col?.headerValueGetter == "function" && col.headerValueGetter(obj)),
    defaultOrder,
  };
};
