import { useFormContext, useWatch } from "react-hook-form";

// gives the latest value of
const useGetValue = (name: string) => {
  const { getValues, control } = useFormContext();
  const watchValue = useWatch({
    control,
    name: name,
  });

  return getValues(name);
};

export default useGetValue;
