import React from "react";
import { Col, Row } from "react-bootstrap";
import style from "./receipts.module.css";
import { useTypedSelector } from "reducers";
import { selectReceiptDetail } from "reducers/admin/receiptsReducer";
import { getPrimaryCurrencyCodeOfUser } from "reducers/userReducers";
import { formattedAmount, isDefined } from "services/general/helpers";
import { companyDateFormat } from "services/general/dateSvc";

const OcrReceiptInfo = () => {
  const receiptDetail = useTypedSelector(selectReceiptDetail);
  const currencyCode = useTypedSelector(getPrimaryCurrencyCodeOfUser);
  const currentUser = useTypedSelector((state) => state.user);

  return (
    <Row>
      <Col>
        <Row className="my-2">
          <Col className={style.detailKey}>Merchant</Col>
          <Col>{receiptDetail?.form_data?.merchant ?? "N/A"}</Col>
        </Row>
        <Row className="my-2">
          <Col className={style.detailKey}>Transaction Date</Col>
          <Col>
            {(isDefined(receiptDetail?.form_data?.date) &&
              companyDateFormat(receiptDetail?.form_data?.date, currentUser, true)) ||
              "N/A"}
          </Col>
        </Row>
        <Row className="my-2">
          <Col className={style.detailKey}>Subtotal Amount</Col>
          <Col>
            <div className={style.currencyInputField}>
              {isDefined(receiptDetail?.form_data?.sub_total) &&
                formattedAmount(String(receiptDetail?.form_data?.sub_total), currencyCode, 2, true)}
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col className={style.detailKey}>Tax Amount</Col>
          <Col>
            <div className={style.currencyInputField}>
              {isDefined(receiptDetail?.form_data?.tax) &&
                formattedAmount(String(receiptDetail?.form_data?.tax), currencyCode, 2, true)}
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col className={style.detailKey}>Purchase Amount</Col>
          <Col>
            <div className={style.currencyInputField}>
              {isDefined(receiptDetail?.form_data?.total) &&
                formattedAmount(String(receiptDetail?.form_data?.total), currencyCode, 2, true)}
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col className={style.detailKey} md="6">
            File Name
          </Col>
          <Col md="6">{receiptDetail?.name ?? "N/A"}</Col>
        </Row>
      </Col>
    </Row>
  );
};
export default OcrReceiptInfo;
