import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";

type confirmationModalPropsType = {
  title: ReactNode;
  show: boolean;
  saveCallback: () => void;
  closeCallback?: () => void;
  confirmText?: string;
  cancelText?: ReactNode;
  body?: ReactNode;
};

const ConfirmationModal = ({
  show,
  saveCallback,
  closeCallback,
  title,
  body,
  cancelText,
  confirmText,
}: confirmationModalPropsType) => {
  return (
    <Modal show={show} onHide={closeCallback}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeCallback}>
          {cancelText ? cancelText : "Cancel"}
        </Button>
        <Button variant="primary" onClick={saveCallback}>
          {confirmText ? confirmText : "Ok"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
