import DatePicker from "components/admin/pickers/reduxFormPickers/datePicker/datePicker";
import ExpenseItemPerDiemPicker, {
  PerDiemItem,
} from "components/admin/pickers/reduxFormPickers/expenseItemPerDiemPicker";
import { RenderCheckBox, RenderField, RenderFieldNumber } from "components/forms/bootstrapFields";
import React, { ChangeEvent } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { change, Field } from "redux-form";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { IUser } from "services/common/user/userTypes";
import { required } from "services/validations/reduxFormValidation";
import style from "./expenseItem.module.css";
import { useExpenseItemFormContext } from "./expenseItemFormSectionContext";
import { formExpenseItemFormData } from "./formExpenseItem";

const PerDiemForm = () => {
  const dispatch = useDispatch();
  const ctx = useExpenseItemFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  // const expenseItem = useFormValue<ExpensesTypes.ExpenseItemFormDataType | null | undefined>(
  //   ctx?.formName,
  //   ctx?.sectionPrefix
  // );
  const expenseFormData = useTypedSelector(formExpenseItemFormData(ctx?.formName ?? ""));
  const expenses = expenseFormData?.expenses;
  const expenseItem = expenses && ctx && expenses[ctx.index];

  const onQtyChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (expenseItem) {
      expenseItem.qty = Number(e.target.value);
      expenseItemCommonSvc.calculatePerDiemAmount(expenseItem, currentUser);
    }
  };

  const calculatePerDiemAmount = (perDiem: PerDiemItem) => {
    if (expenseItem && ctx) {
      expenseItem.per_diem = perDiem;
      expenseItem.per_diem_id = perDiem.id;
      expenseItemCommonSvc.calculatePerDiemAmount(expenseItem, currentUser);
      dispatch(change(ctx.formName, ctx.sectionPrefix, expenseItem));
    }
  };

  return (
    <>
      <Row>
        <Col lg="3">
          <Field label="Quantity" name="qty" type="number" component={RenderFieldNumber} onChange={onQtyChange} />
        </Col>

        <Col lg="9">
          <Row>
            <Col lg="9">
              <Field
                label="Per Diem Item"
                name="per_diem_id"
                type="number"
                component={ExpenseItemPerDiemPicker}
                formName={ctx?.formName}
                formLocation={ctx?.sectionPrefix}
                modelData={expenseItem}
                callBackObj={calculatePerDiemAmount}
                required={currentUser.company?.expense_item?.require_per_diem_item}
                validate={currentUser.company?.expense_item?.require_per_diem_item ? [required] : []}
              />
            </Col>
            <Col lg="3">
              <span className={style.perDiemRate}>
                at {expenseItem?.per_diem?.currency_code} {expenseItem?.per_diem?.unit_price} each{" "}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <Field id="expense_date" label="Date" name="date" component={DatePicker} validate={[required]} required />
        </Col>
        <Col lg="4">
          <Field
            id="total"
            label="Total Amount"
            name="amount"
            component={RenderFieldNumber}
            validate={[required]}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col lg="10">
          <Field
            id="expense_description"
            label="Description"
            name="description"
            type="text"
            component={RenderField}
            required={
              currentUser.company.expense_item?.required_description ||
              expenseItem?.policy_violations?.missing_description_by_category ||
              expenseItem?.policy_violations?.missing_description_by_policy
            }
            validate={currentUser.company?.expense_item?.required_description ? [required] : []}
          />
        </Col>
        <Col lg="2" className={style.mt40}>
          {currentUser.company?.expense_item?.show_billable && (
            <>
              <Field
                id="billable_expense"
                label="Billable"
                name="billable"
                type="checkbox"
                component={RenderCheckBox}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default PerDiemForm;
