import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Form, InjectedFormProps, change, reduxForm } from "redux-form";
import { MetadataFieldSelector } from "wombatifier/components/metadata_field/selector";
import { VendorDetailType, VendorType } from "../../../services/admin/vendors/vendorTypes";
import { IUser } from "../../../services/common/user/userTypes";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";
import Stepper, { StepType } from "../../common/stepper";
import CustomModal from "../../modals/customModal";
import CustomModalWithSidebar from "../../modals/customModalWithSidebar";
import AddPaymentMethod from "./formSections/addPaymentMethod";
import AddVendorAdditionalDetails from "./formSections/addVendorAdditionalDetails";
import AddVendorContact from "./formSections/addVendorContact";
import AddVendorDetails from "./formSections/addVendorDetails";
import DocumentsRequested from "./formSections/documentsRequested";
import FinalReview from "./formSections/finalReview";
import VendorSearcher from "./formSections/vendorSearcher";
import styles from "./vendors.module.css";
const formName = "VendorForm";

type VendorFormPropsTypes = {
  returnTo?: string;
  showSearchVendor?: boolean;
  combineMasterDetailWithVendor: (master: VendorType) => void;
  onSubmit: (vendor: VendorDetailType) => void;
  currentUser: IUser;
  stepperStepsData: StepType[];
  showPaymentMethodSection: { isNvpCustomer?: boolean; isSkipped?: boolean };
  showAdditionalCodingSection: boolean;
  showAdditionalCodingField: boolean;
};
interface VendorReduxFormProps
  extends VendorFormPropsTypes,
    InjectedFormProps<VendorDetailType, VendorFormPropsTypes> {}

const FormVendor = ({
  handleSubmit,
  showSearchVendor,
  combineMasterDetailWithVendor,
  currentUser,
  stepperStepsData,
  showPaymentMethodSection,
  showAdditionalCodingSection,
  showAdditionalCodingField,
  valid,
}: VendorReduxFormProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showVendorSearcher, setShowVendorSearcher] = useState(showSearchVendor);
  const [showModal, setShowModal] = useState(true);
  const [step, setStep] = useState(1);
  const [currentStep, setCurrentStep] = useState("vendorDetails");
  const [stepperSteps, setStepperSteps] = useState(stepperStepsData);
  const dispatch = useDispatch();

  const closeModal = () => {
    setShowModal(false);
    history?.push("/ap/vendors");
  };

  const handleCreateVendor = (data: any) => {
    combineMasterDetailWithVendor(data);
    setShowVendorSearcher(false);
    setStep(1);
    handleActiveStep(1);
  };

  const handleEdit = (step: number) => {
    setShowVendorSearcher(false);
    setStep(step);
    handleActiveStep(step);
  };

  const handleCreateNewVendor = () => {
    setShowVendorSearcher(false);
    setStep(1);
  };

  const handlePrevious = (step: number) => {
    if (step == 4 && !showAdditionalCodingField) {
      setStep(step - 2);
      handleActiveStep(step - 2);
    } else {
      setStep(step - 1);
      handleActiveStep(step - 1);
    }
  };

  const handleNext = (step: number) => {
    if (step === 3 || step === 2) {
      dispatch(change("VendorForm", "skipVendorContactStep", false));
    }
    // Create a copy of the stepperSteps array
    const updatedSteps = [...stepperSteps];
    // Find the step that corresponds to the current step and set completed to true
    const currentStepIndex = step - 1;
    if (currentStepIndex >= 0 && currentStepIndex < updatedSteps.length) {
      updatedSteps[currentStepIndex].completed = true;
    }
    setStepperSteps(updatedSteps);
    if (step == 2 && !showAdditionalCodingField) {
      setStep(step + 2);
      updatedSteps[step].completed = true;
      handleActiveStep(step + 2);
    } else {
      setStep(step + 1);
      handleActiveStep(step + 1);
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleStepChange = (step?: number) => {
    if (step) {
      setStep(step);
      if (!showAdditionalCodingField && (step === 3 || step === 4)) {
        setStep(step + 1);
      }
      // In Step 4, we handle the Payment method. The 'skipPaymentMethodStep' flag is crucial; it should always be set to false for this step.
      // If, for some reason, 'skipPaymentMethodStep' is set to true, the Payment method section will be hidden from the form.
      if (step === 4) {
        dispatch(change("VendorForm", "skipPaymentMethodStep", false));
      }
    }
  };

  const handleActiveStep = (activeStep: number) => {
    switch (activeStep) {
      case 1:
        setCurrentStep("vendorDetails");
        break;
      case 2:
        setCurrentStep("vendorAdditionalDetails");
        break;
      case 3:
        showAdditionalCodingSection ? setCurrentStep("vendorAdditionalCodingFields") : setCurrentStep("vendorContact");
        break;
      case 4:
        setCurrentStep("vendorContact");
        break;
      case 5:
        !showPaymentMethodSection.isNvpCustomer
          ? setCurrentStep("paymentMethod")
          : setCurrentStep("documentsRequested");
        break;
      case 6:
        !showPaymentMethodSection.isNvpCustomer ? setCurrentStep("documentsRequested") : setCurrentStep("finalReview");
        break;
      case 7:
        setCurrentStep("finalReview");
        break;
      default:
        setCurrentStep("vendorDetails");
        break;
    }
  };
  return (
    <>
      {showVendorSearcher && (
        <CustomModal
          size="xl"
          headerClass={styles.modelSpacing}
          modalBodyClass={styles.modelSpacing}
          headerTitleClass={styles.modalHeader}
          contentClassName={styles.modelStyle}
          header={showVendorSearcher ? t("admin.pages.vendor.addVendor") : t("admin.pages.vendor.createNewVendor")}
          body={
            <ErrorBoundary>
              <VendorSearcher handleAddVendor={handleCreateVendor} currentUserCompany={currentUser?.company} />
            </ErrorBoundary>
          }
          footer={
            <Row>
              <Col md="12">
                <p>Can’t find your vendor? Click ‘Create New Vendor’</p>
                <Button
                  variant="secondary"
                  className={`${styles.createNewVendor} float-right mt-15`}
                  onClick={() => handleCreateNewVendor()}
                >
                  Create New Vendor
                </Button>
              </Col>
            </Row>
          }
          show={showModal}
          onHide={closeModal}
        />
      )}

      {!showVendorSearcher && (
        <CustomModalWithSidebar
          size="xl"
          headerClass={styles.modelSpacing}
          modalBodyClass={styles.modelSpacing}
          headerTitleClass={styles.modalHeader}
          contentClassName={styles.modelStyle}
          header={step === 7 ? t("admin.pages.vendor.finalReview") : t("admin.pages.vendor.createNewVendor")}
          modalSidebar={<Stepper steps={stepperSteps} activeStep={currentStep} handleStepChange={handleStepChange} />}
          body={
            <Form onSubmit={handleSubmit}>
              <div style={{ display: step === 1 ? "block" : "none" }}>
                {
                  <ErrorBoundary>
                    <AddVendorDetails
                      currentUserCompany={currentUser?.company}
                      handleNext={handleNext}
                      handleCancel={handleCancel}
                      valid={valid}
                    />
                  </ErrorBoundary>
                }
              </div>

              {step === 2 ? (
                <div style={{ display: "block" }}>
                  <ErrorBoundary>
                    <AddVendorAdditionalDetails
                      currentUserCompany={currentUser?.company}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                      handleCancel={handleCancel}
                      valid={valid}
                    />
                  </ErrorBoundary>
                </div>
              ) : null}

              {step === 3 ? (
                <div className={styles.additionalCodingStep}>
                  <ErrorBoundary>
                    <MetadataFieldSelector formName={formName} modules="Vendor" />
                    <Row>
                      <Col md={4}>
                        <Button variant="secondary" className="px-mr-10" onClick={() => handlePrevious(3)}>
                          {t("admin.pages.vendor.back")}
                        </Button>
                      </Col>
                      <Col md={{ span: 4, offset: 4 }} className={styles.btnColumn}>
                        <Button variant="secondary" className="px-mr-10" onClick={() => handleCancel()}>
                          {t("admin.pages.vendor.cancel")}
                        </Button>
                        <Button
                          variant="primary"
                          className={styles.btnNext}
                          onClick={() => handleNext(3)}
                          disabled={!valid}
                          title={`${!valid ? "To continue, complete all required fields" : ""}`}
                        >
                          {t("admin.pages.vendor.next")}
                        </Button>
                      </Col>
                    </Row>
                  </ErrorBoundary>
                </div>
              ) : null}

              {step === 4 ? (
                <div style={{ display: "block" }}>
                  <ErrorBoundary>
                    <AddVendorContact
                      currentUserCompany={currentUser?.company}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                      handleCancel={handleCancel}
                      valid={valid}
                    />
                  </ErrorBoundary>
                </div>
              ) : null}

              {!showPaymentMethodSection.isNvpCustomer && step === 5 ? (
                <div style={{ display: "block" }}>
                  <ErrorBoundary>
                    <AddPaymentMethod
                      currentUserCompany={currentUser?.company}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                      handleCancel={handleCancel}
                      valid={valid}
                    />
                  </ErrorBoundary>
                </div>
              ) : null}

              {(!showPaymentMethodSection.isNvpCustomer && step === 6) ||
              (showPaymentMethodSection.isNvpCustomer && step === 5) ? (
                <div style={{ display: "block" }}>
                  <ErrorBoundary>
                    <DocumentsRequested
                      currentUserCompany={currentUser?.company}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                      handleCancel={handleCancel}
                      valid={valid}
                    />
                  </ErrorBoundary>
                </div>
              ) : null}

              {(!showPaymentMethodSection.isNvpCustomer && step === 7) ||
              (showPaymentMethodSection.isNvpCustomer && step === 6) ? (
                <div style={{ display: "block" }}>
                  <ErrorBoundary>
                    <FinalReview
                      currentUserCompany={currentUser?.company}
                      handleCancel={handleCancel}
                      handleEditVendorInfo={handleEdit}
                      showAdditionalCodingField={showAdditionalCodingField}
                      // valid={valid}
                    />
                  </ErrorBoundary>
                </div>
              ) : null}
            </Form>
          }
          show={showModal}
          onHide={closeModal}
        />
      )}
    </>
  );
};

const ConnectedFormVendor = reduxForm<VendorDetailType, VendorFormPropsTypes>({
  form: formName,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  touchOnBlur: true,
})(FormVendor);

export default ConnectedFormVendor;
