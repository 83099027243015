import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { formattedAmount, onBlur, onBlurSingle } from "services/general/helpers";
import { Form } from "react-bootstrap";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import { PurchaseOrderOptionType, PurchaseOrderPickerPropsType } from ".";
import _ from "lodash";
import { companyDateFormat } from "services/general/dateSvc";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import { InvoiceType } from "services/admin/invoices/invoiceType";

const parsePoLinksForSelect = (purchaseOrderLinks: InvoiceType.PurchaseOrderLinksType[]) => {
  return purchaseOrderLinks
    .filter((purchaseOrderLink: InvoiceType.PurchaseOrderLinksType) => purchaseOrderLink._destroy != 1)
    .map((purchaseOrderLink: InvoiceType.PurchaseOrderLinksType) => ({
      value: purchaseOrderLink.purchase_order_id,
      label: purchaseOrderLink.purchase_order.number,
      ...purchaseOrderLink.purchase_order,
    }));
};

const MultiPoPicker = ({
  label,
  input,
  meta: { touched, error },
  tooltip,
  required,
  inputId,
  placeholder,
  disabled,
  callBack,
  loadOptions,
  deleteCallBack,
  modelData,
  instanceId,
}: PurchaseOrderPickerPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const [selected, setSelected] = useState<PurchaseOrderOptionType[]>([]);

  const onAddPo = (po: PurchaseOrderOptionType) => {
    setSelected((prev) => [...prev, po]);
    if (callBack) {
      callBack(po);
    }
  };

  const removePoCallBack = (removedPos: PurchaseOrderOptionType) => {
    if (deleteCallBack) {
      deleteCallBack(removedPos);
    }
  };

  const removePo = (removed: PurchaseOrderOptionType[]) => {
    if (_.isArray(removed) && removed.length === 1) {
      // remove one
      removePoCallBack(removed[0]);
      setSelected((prev) => prev.filter((selectedPo) => selectedPo.value !== removed[0].value));
    } else if (_.isArray(removed) && removed.length > 1) {
      // remove all
      selected.forEach((po) => removePoCallBack(po));
      setSelected([]);
    }
  };

  const onChangePo = (pos: PurchaseOrderOptionType[]) => {
    const added = pos.filter((po) =>
      _.isArray(selected) && selected.length > 0
        ? !selected.some((currentSelectedPo) => currentSelectedPo.id == po.id)
        : true,
    );

    if (_.isArray(added) && added.length > 0) {
      onAddPo(added[0]);
    }

    const removed = selected.filter((selectedPo) => !pos.some((po) => selectedPo.id == po.id));

    if (_.isArray(removed) && removed.length > 0) {
      removePo(removed);
    }

    input.onChange(pos);
  };

  useEffect(() => {
    if (
      _.isPlainObject(modelData) &&
      _.isArray(modelData.invoice_purchase_order_links_attributes) &&
      modelData.invoice_purchase_order_links_attributes.length > 0
    ) {
      setSelected(parsePoLinksForSelect(modelData.invoice_purchase_order_links_attributes));
    }
  }, [modelData?.invoice_purchase_order_links_attributes]);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <AsyncSelect
        value={selected}
        isMulti={true}
        isClearable={false}
        loadOptions={loadOptions}
        formatOptionLabel={(purchaseOrder, context) =>
          context.context == "menu" ? (
            <small className="selectChoices">
              <strong>
                {purchaseOrder.number} - {purchaseOrder.vendor_name || "(Type: " + purchaseOrder.po_type + ")"}
              </strong>
              <br />
              <strong>Amount:</strong>{" "}
              {formattedAmount(purchaseOrder.amount?.toString(), purchaseOrder.currency?.iso_code)} /{" "}
              <strong>Balance:</strong>{" "}
              {formattedAmount(purchaseOrder.open_balance?.toString(), purchaseOrder.currency?.iso_code)} <br />
              <strong>Date:</strong> {companyDateFormat(purchaseOrder.date, currentUser, true)} <strong>Status:</strong>{" "}
              {purchaseOrder.status} <br />
              <strong>Description:</strong> {purchaseOrder.description}
            </small>
          ) : (
            purchaseOrder.label
          )
        }
        onChange={(value: any) => onChangePo(value)}
        onBlur={() => onBlur(input, input.value)}
        placeholder={placeholder}
        defaultOptions
        instanceId={instanceId || "multi-po-picker"}
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default MultiPoPicker;
