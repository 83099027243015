import { ConsolidateErrorModalTypes } from "components/admin/consolidateError/consolidateErrorModalTypes";
import _ from "lodash";
import moment from "moment";
import adminCommonSvc from "services/admin/commonSvc";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { IUser } from "./user/userTypes";

export function setSavedState(state: any) {
  let stateParams = {
    colState: state.gridColumnApi.getColumnState(),
    groupState: state.gridColumnApi.getColumnGroupState(),
    sortState: state.gridColumnApi.getColumnState(),
    filterState: state.gridApi.getFilterModel(),
  };
  return stateParams;
}

export function normalizeDates(date: any) {
  return date ? moment(date).format("YYYY-MM-DD") : null;
}

class CommonService {
  getDueDateClass = (dueDate?: string) => {
    const dueDateLowerCase = dueDate?.toLowerCase() ?? "";
    switch (dueDateLowerCase) {
      case "past due":
        return "text-danger";
      case "due soon":
        return "text-warning";
      case "due today":
        return "text-primary";
      case "paid":
        return "text-success";
      default:
        return "";
    }
  };

  handleError = (error: any) => {
    if (
      error?.response?.status === 422 ||
      error?.response?.status === 404 ||
      error?.response?.status === 400 ||
      error?.response?.status === 403
    ) {
      //will be handle at the form level, except :base
      var errormsg = "";
      for (const key in error?.response?.data) {
        if (key === "base") {
          errormsg += error.response.data[key] + " ,";
          CreateNotification("Data Issue:", errormsg, NotificationType.danger);
        }
      }
    }
  };

  getFormatCurrencyAmount = (number: number, decimalPrecision: number = 2): string => {
    if (number == 0) {
      return "0";
    } else {
      // hundreds
      if (number <= 999) {
        return number.toFixed(decimalPrecision);
      }
      // thousands
      else if (number >= 1000 && number <= 999999) {
        return (number / 1000).toFixed(decimalPrecision) + "K";
      }
      // millions
      else if (number >= 1000000 && number <= 999999999) {
        return (number / 1000000).toFixed(decimalPrecision) + "M";
      }
      // billions
      else if (number >= 1000000000 && number <= 999999999999) {
        return (number / 1000000000).toFixed(decimalPrecision) + "B";
      }
      // trillions
      else if (number >= 1000000000000 && number <= 999999999999999) {
        return (number / 1000000000000).toFixed(decimalPrecision) + "T";
      } else {
        return number.toFixed(2);
      }
    }
  };

  getFormattedPercentage = ({
    percentage,
    fixedAt = 2,
  }: {
    percentage: string | number | undefined;
    fixedAt?: number;
  }): string => {
    if (typeof percentage === "number") {
      return percentage.toFixed(fixedAt);
    } else {
      let per = Number(percentage);
      if (isNaN(per)) {
        per = 0;
        return per.toFixed(fixedAt);
      } else {
        return per.toFixed(fixedAt);
      }
    }
  };

  // decimal 2
  // Input:
  // 10
  // 1.7777777
  // 9.1

  // Output:
  // 10
  // 1.78
  // 9.1
  roundOfPercentage = ({ percentage, decimal = 2 }: { percentage: number; decimal?: number }) => {
    // 52.73783793 ==> 52.74, decimal = 2
    return Math.round(percentage * Math.pow(10, decimal)) / Math.pow(10, decimal);
  };

  calculateAmountFromPercentage = ({
    percentage,
    total,
    currentUser,
  }: {
    percentage: number;
    total: number;
    currentUser: IUser;
  }) => {
    let amount = (percentage / 100) * total;
    amount = adminCommonSvc.roundUpAmount(amount, null, currentUser);
    return amount;
  };

  calculatePercentageFromAmount = ({ amount, total }: { amount: number; total: number }) => {
    let percentage = (amount / total) * 100;
    percentage = this.roundOfPercentage({ percentage });
    return percentage;
  };

  entering = (e: { children: HTMLDivElement[] }) => {
    e.children[0].style.borderTopColor = "#656579CC";
    e.children[0].style.display = "none";
    e.children[1].style.backgroundColor = "#656579CC";
    e.children[1].style.maxWidth = "301px";
    e.children[1].style.width = "301px";
    e.children[1].style.padding = "16px";
    e.children[1].style.position = "relative";
    e.children[1].style.right = "118px";
  };

  enteringNormal = (e: { children: HTMLDivElement[] }) => {
    e.children[0].style.borderTopColor = "#656579CC";
    e.children[0].style.display = "none";
    e.children[1].style.backgroundColor = "#656579CC";
    e.children[1].style.maxWidth = "301px";
    e.children[1].style.width = "301px";
    e.children[1].style.padding = "16px";
    e.children[1].style.position = "relative";
    e.children[1].style.right = "10px";
  };

  normalizedTwoDecimal = (value: any, previousValue?: any) => {
    const normalizePercent = this.roundOfPercentage({ percentage: value });
    return normalizePercent;
  };

  normalizeNotToNegative = (value: any, previousValue?: any) => {
    if (Number(value) < 0) {
      return previousValue;
    }
    return value;
  };

  normalizePercent = (value: number, previousValue: number) => {
    const nfValue1 = this.normalizeNotToNegative(value, previousValue);
    const nfValue2 = this.normalizedTwoDecimal(nfValue1, previousValue);
    return nfValue2;
  };

  getInvalidStyle = (invalid: boolean) => {
    return invalid
      ? {
          borderColor: "#dc3545",
          boxShadow: "none",
          "&:hover": {
            borderColor: "#dc3545",
          },
        }
      : {};
  };

  getErrorsFromObject = ({
    errors,
    errorsObject,
    containerId,
    nameLabelMapOverride,
  }: {
    errors: any;
    errorsObject: any;
    containerId: string;
    nameLabelMapOverride: Record<string, string>;
  }) => {
    if (!errorsObject) {
      return;
    }

    const keys = Object.keys(errorsObject);
    keys.forEach((key) => {
      // object inside object is error Object
      if (!_.isArray(errorsObject[key])) {
        if (this.isHookFormErrorStructure(errorsObject[key])) {
          this.generateErrorObjectForConsolidateErrors({
            errors,
            hookFormErrorObject: errorsObject[key],
            containerId,
            key,
            nameLabelMapOverride,
          });
        } else {
          this.getErrorsFromObject({ errors, errorsObject: errorsObject[key], containerId, nameLabelMapOverride });
        }
      } else if (_.isArray(errorsObject[key])) {
        // array inside object is errors from fields array
        const errorsArray = errorsObject[key];
        this.getErrorFromArray({ errors, errorsArray, containerId, nameLabelMapOverride });
      }
    });
  };

  getErrorFromArray = ({
    errors,
    errorsArray,
    containerId,
    nameLabelMapOverride,
  }: {
    errors: any;
    errorsArray: any[];
    containerId: string;
    nameLabelMapOverride: Record<string, string>;
  }) => {
    errorsArray.forEach((fieldObject: any) => {
      // object inside fieldsArray is not final Error object,
      // this are the errors from fields array item
      // therefor using getErrorsFromObject
      if (!_.isArray(fieldObject)) {
        this.getErrorsFromObject({ errors, errorsObject: fieldObject, containerId, nameLabelMapOverride });
      } else if (_.isArray(fieldObject)) {
        this.getErrorFromArray({ errors, errorsArray, containerId, nameLabelMapOverride });
      }
    });
  };

  isHookFormErrorStructure = (errorObject: Object) => {
    return (
      errorObject &&
      errorObject.hasOwnProperty("message") &&
      errorObject.hasOwnProperty("message") &&
      errorObject.hasOwnProperty("ref")
    );
  };

  generateErrorObjectForConsolidateErrors = ({
    errors,
    hookFormErrorObject,
    containerId,
    key,
    nameLabelMapOverride,
  }: {
    errors: any;
    hookFormErrorObject: Record<string, any> | null;
    containerId: string;
    key: string;
    nameLabelMapOverride: Record<string, string>;
  }) => {
    let nameLabelMap = { ...this.nameLabelMap };
    if (nameLabelMapOverride && _.isPlainObject(nameLabelMapOverride)) {
      nameLabelMap = { ...this.nameLabelMap, ...nameLabelMapOverride };
    }
    // this errors object for this key in main container
    const error: ConsolidateErrorModalTypes.Error = {
      container_id: containerId,
      label: nameLabelMap[key] ?? key,
      errorMessage: (hookFormErrorObject && hookFormErrorObject?.message) ?? "Error",
    };
    errors.push(error);
  };

  getErrorsAtHeader = ({
    errors,
    formErrors,
    fieldContainerMap,
    containerId,
    nameLabelMapOverride,
  }: {
    errors: any;
    formErrors: any;
    fieldContainerMap: any;
    containerId: string;
    nameLabelMapOverride: Record<string, string>;
  }) => {
    const keys = Object.keys(formErrors);
    keys.forEach((key) => {
      // check if key is not present in fieldContainerMap
      if (!fieldContainerMap[key]) {
        if (!_.isArray(formErrors[key])) {
          if (this.isHookFormErrorStructure(formErrors[key])) {
            this.generateErrorObjectForConsolidateErrors({
              errors,
              hookFormErrorObject: formErrors[key],
              containerId,
              key: key,
              nameLabelMapOverride,
            });
          } else {
            this.getErrorsFromObject({ errors, errorsObject: formErrors[key], containerId, nameLabelMapOverride });
          }
        } else {
          const errorsArray = formErrors[key];
          this.getErrorFromArray({ errors, errorsArray, containerId: containerId, nameLabelMapOverride });
        }
      }
    });
  };

  getErrorForContainers = ({
    container,
    formErrors,
    fieldContainerMap,
    nameLabelMapOverride,
  }: {
    container: ConsolidateErrorModalTypes.TConsolidateErrorContainer;
    formErrors: any;
    fieldContainerMap: any;
    nameLabelMapOverride: Record<string, string>;
  }) => {
    const keys = Object.keys(formErrors);
    keys.forEach((key) => {
      // check if key is not present in fieldContainerMap
      if (fieldContainerMap[key]) {
        const currentFieldContainerKey = fieldContainerMap[key];
        if (currentFieldContainerKey === container.container_id) {
          if (!_.isArray(formErrors[key])) {
            if (this.isHookFormErrorStructure(formErrors[key])) {
              this.generateErrorObjectForConsolidateErrors({
                errors: container.errors,
                hookFormErrorObject: formErrors[key],
                containerId: container.container_id,
                key: key,
                nameLabelMapOverride,
              });
            } else {
              this.getErrorsFromObject({
                errors: container.errors,
                errorsObject: formErrors[key],
                containerId: container.container_id,
                nameLabelMapOverride,
              });
            }
          } else {
            const errorsArray = formErrors[key];
            this.getErrorFromArray({
              errors: container.errors,
              errorsArray,
              containerId: container.container_id,
              nameLabelMapOverride,
            });
          }
        }
      }
    });
  };

  getErrorsForMainContainer = ({
    container,
    formErrors,
    fieldContainerMap,
    nameLabelMapOverride,
  }: {
    container: ConsolidateErrorModalTypes.TConsolidateErrorContainer;
    formErrors: any;
    fieldContainerMap: any;
    nameLabelMapOverride: Record<string, string>;
  }) => {
    this.getErrorsAtHeader({
      errors: container.errors,
      formErrors,
      fieldContainerMap,
      containerId: container.container_id,
      nameLabelMapOverride,
    });
  };

  getReactHookFormConsolidateErrors = ({
    containers,
    formErrors,
    fieldContainerMap,
    nameLabelMapOverride, // we have name to label mapping in svc, use this param if want to override any of them
  }: {
    containers: ConsolidateErrorModalTypes.TConsolidateErrorContainer[];
    formErrors: any;
    fieldContainerMap: any;
    nameLabelMapOverride: Record<string, string>;
  }) => {
    // empty errors every time
    containers.forEach((container) => (container.errors = []));

    containers.forEach((container) => {
      if (container.isMain) {
        this.getErrorsForMainContainer({ container, formErrors, fieldContainerMap, nameLabelMapOverride });
      } else {
        this.getErrorForContainers({ container, formErrors, fieldContainerMap, nameLabelMapOverride });
      }
    });
    return containers;
  };

  // those field which are not mapped in testFieldsContainerMap
  // will be to main container directly else in the specified container
  // testFieldsContainerMap = {
  //   invoice_item_attributes: "line_items",
  // };

  nameLabelMap: {
    [key: string]: string;
  } = {
    department_id: "Department",
    department: "Department",
    project_id: "Project",
    project: "Project",
    invoice_number: "Invoice Number",
    status: "Status",
    subsidiary: "Subsidiary",
    date: "Invoice Date",
    vendor: "Vendor",
    address: "Address",
    vendor_id: "Vendor ID",
    default_payment_method_type_payment_type: "Vendor Payment Method Type",
    amount_disc: "Discount Amount",
    location: "Location",
    location_id: "Location",
    business_unit: "Business Unit",
    custom_note: "Custom Note",
    currency_code: "Currency Code",
    due_date: "Due Date",
    submit_date: "Submit Date",
    amount_disc_date: "Discount Expire Date",
    service_start_date: "Service Start Date",
    service_end_date: "Service End Date",
    reference_number: "Reference Number",
    posted_date: "Posted Date",
    fiscal_period: "Fiscal Period",
    customer_account_number: "Customer Account Number",
    description: "Description",
    term: "Payment Terms",
    number: "Number",
    tax_id: "Tax",
    tax: "Tax",
    memo: "Memo",
    product_item: "Item",
    product_item_id: "Item",
    unit_id: "Unit",
    qty: "Qty",
    unit_price: "Unit Price",
    amount: "Amount",
    account_id: "Account",
    account: "Account",
    total: "Total Price",
    sub_amount: "Sub Amount",
  };

  modifyParentModalDataRefStr = <T extends { parentObj?: string | null; modelData?: string | null }>(props: T) => {
    const parentObj = !_.isNil(props.parentObj) ? (props.parentObj === "" ? "" : props.parentObj + ".") : null;
    const modelData = !_.isNil(props.modelData) ? (props.modelData === "" ? "" : props.modelData + ".") : null;
    return { parentObj, modelData };
  };
}

const commonService = new CommonService();
export default commonService;
