import React from "react";
import { useDispatch } from "react-redux";
import { setMessageObjectId, setMessagePanelOpen, setMessageObjectName } from "reducers/admin/messageSlice";
import { IDType } from "services/common/types/common.type";

const useMessagePanel = () => {
  const dispatch = useDispatch();
  const openMessagePanel = ({
    messageObjectName,
    messageObjectId,
  }: {
    messageObjectName: string;
    messageObjectId: IDType;
  }) => {
    dispatch(setMessagePanelOpen(true));
    dispatch(setMessageObjectName(messageObjectName));
    dispatch(setMessageObjectId(messageObjectId));
  };

  return {
    openMessagePanel,
  };
};
export default useMessagePanel;
