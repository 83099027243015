import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Styles from "../override.module.css";
import { findSelectedMultiple, onBlurSingle, onChangeSingle, parseForSelect } from "services/general/helpers";
import Select, { SingleValue } from "react-select";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { restApiService } from "providers/restApi";
import useIsMounted from "components/common/hooks/useIsMounted";
import { PolicyPickerProps } from "./index";
import { PolicyTypes } from "services/admin/policy/policyTypes";
import { Mandatory } from "components/forms/bootstrapFields";

const SinglePicker = (props: PolicyPickerProps) => {
  let {
    className,
    label,
    input,
    meta: { touched, error, warning },
    disabled,
    modelData,
    modelName,
    callBack,
    callBackObj,
    tooltip,
    required,
  } = props;

  const mounted = useIsMounted();
  const [policies, setPolicies] = useState<PolicyTypes.PolicyLKList>([]);

  const getPolicies = useCallback(async () => {
    const params: Record<string, any> = {
      status: "ACTIVE",
    };
    if (modelData?.subsidiary_id && modelName === "expense") {
      params.subsidiary_id = modelData?.subsidiary_id;
    }
    const result = await restApiService.get("policies.lk", params, null, true, null, true);
    const value = parseForSelect(result.data);
    if (!required && Array.isArray(value)) {
      value.unshift({ id: "", name: "-- Select Policy --", label: "-- Select Policy --", value: "" });
    }
    if (mounted.current) setPolicies(value);
  }, [modelData?.subsidiary_id, modelName, mounted]);

  useEffect(() => {
    getPolicies();
  }, []);

  useEffect(() => {
    if (modelName === "expense" && modelData?.subsidiary_id) {
      getPolicies();
    }
  }, [getPolicies, modelData?.subsidiary_id, modelName]);

  const onChange = (value: any) => {
    // onChangeSingle(input, value);
    if (callBack) {
      //id
      callBack(value.value);
    }
    if (callBackObj) {
      // obj
      callBackObj(value);
    }
  };

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
          {tooltip}
        </Form.Label>
      )}
      <Select
        {...input}
        required
        value={findSelectedMultiple(input, policies)}
        placeholder="search/select"
        onChange={(selected) => onChange(selected)}
        onBlur={() => onBlurSingle(input, input.value)}
        formatOptionLabel={(option: any, ctx: any): any => {
          return (
            <>
              {ctx.context === "value" ? (
                <div>{option.label}</div>
              ) : (
                <>
                  <div>{option.label}</div>
                  {option.policy_type && <div>Policy Type: {option.policy_type}</div>}
                </>
              )}
            </>
          );
        }}
        options={policies}
        isDisabled={disabled}
        isMulti={false}
        isClearable={false}
        classNamePrefix="select"
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default SinglePicker;
