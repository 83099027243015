import { useCallback } from "react";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import { formattedAmount } from "services/general/helpers";
import useGetValue from "../hooks/hookFormHooks/useGetValue";

/**
 * CurrencySymbolRenderer displays a currency symbol based on the provided currency
 * It adjusts the number of decimal places based on the user's company settings.
 *
 * Props:
 * - name (string): The name of the currency field in the form.
 * - amount (number): The amount to format.
 * - decimal (number | undefined): Optional decimal places; defaults based on company settings.
 *
 * @returns {string} The currency symbol.
 */

const CurrencySymbolRenderer = ({ name, amount, decimal }: { name: string; amount: number; decimal?: number }) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const getDefaultDecimal = useCallback(() => {
    return currentUser.company.has_any_integration ? 2 : 4;
  }, [currentUser]);

  const currency = useGetValue(name);

  let symbol = formattedAmount((amount || 0).toString(), currency, decimal ? decimal : getDefaultDecimal(), true);
  return symbol;
};

export default CurrencySymbolRenderer;
