import CustomModal from "components/modals/customModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectShowReceiptLinker, setShowReceiptLinker } from "reducers/admin/receiptsReducer";
import style from "./receipts.module.css";
import PurchaseLinker from "./purchaseLinker";
import ExpenseLinker from "./expenseLinker";
import { Col, Container, Row } from "react-bootstrap";

const ReceiptLinker = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showReceiptLinker = useTypedSelector(selectShowReceiptLinker);

  // using local state here
  // if required then can be shifted to redux later
  const [linkingTransactionType, setLinkingTransactionType] = useState<"purchase" | "expense">();

  const closeReceiptLinker = () => {
    dispatch(setShowReceiptLinker(false));
  };

  return (
    <CustomModal
      show={showReceiptLinker}
      header={<strong>{t("admin.pages.receipts.linkReceipt")}</strong>}
      onHide={closeReceiptLinker}
      size="lg"
      body={
        <Container className={style.receiptLinkerModal}>
          <Row className={style.body}>
            <Col>
              {/* until either transaction or expense is not select show options to select them */}
              {!linkingTransactionType && (
                <div className={style.optionSelectionContainer}>
                  <div className={style.titleQuestion}>{t("admin.pages.receipts.typeOfTransaction")}</div>

                  <div className={style.optionContainer}>
                    <button className={style.option} onClick={() => setLinkingTransactionType("purchase")}>
                      <div className={"d-flex justify-content-center align-items-center"}>
                        <i style={{ width: 58, height: 65 }} className="d-block  icon-virtual-card" />
                      </div>
                      <div>{t("admin.pages.receipts.transaction")}</div>
                    </button>

                    <button className={style.option} onClick={() => setLinkingTransactionType("expense")}>
                      <div className={"d-flex justify-content-center align-items-center"}>
                        <i style={{ width: 73, height: 73 }} className="d-block  icon-expenses" />
                      </div>
                      <div>{t("admin.pages.receipts.expense")}</div>
                    </button>
                  </div>
                </div>
              )}

              {linkingTransactionType === "purchase" && <PurchaseLinker />}

              {linkingTransactionType === "expense" && <ExpenseLinker />}
            </Col>
          </Row>
        </Container>
      }
    />
  );
};

export default ReceiptLinker;
