import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import React, { memo, useCallback, useMemo } from "react";
import { IDType } from "services/common/types/common.type";
import { CommonTypes } from "services/common/types/commonTypes";
import AbstractListPicker from "../abstractListPicker/index2";

type TVendorLocationPickerOption = {
  id: IDType;
  name: string;
  number?: string | number;
  status?: string;
  description?: string;
  external_id?: string;
  addresses: {
    address1?: string;
    address2?: string;
    address3?: string;
    address_type?: string;
    city?: string;
    country?: string;
    id: IDType;
    is_default?: boolean;
    label?: string;
    state?: string;
    status?: string;
    zipcode?: number;
  }[];
};

type TVendorLocationPickerProps = {} & CommonTypes.TPickerCommonAttributes<TVendorLocationPickerOption>;

const VendorLocationPicker = ({
  name,
  label,
  required,
  modelData,
  parentObj,
  menuPlacement,
  menuPosition,
  objectPropertyKey,
}: TVendorLocationPickerProps) => {
  const vendorId = useGetValue(`${modelData}.vendor_id`);

  const params = useMemo(() => {
    return { vendorId: vendorId || undefined };
  }, [vendorId]);

  const formatOptionLabel = useCallback((option: TVendorLocationPickerOption, context: any) => {
    const address = Array.isArray(option.addresses) && option.addresses.length > 0 ? option.addresses[0] : null;
    return (
      <div>
        {context?.context !== "menu" ? (
          <span>{option.name}</span>
        ) : (
          address && (
            <small>
              {
                <span>
                  {option.name} - Address Type: {address.address_type}
                </span>
              }
              <br />
              {address.address1}
              {address.address2 && (
                <>
                  <br />
                  {address.address2}
                </>
              )}
              {address.address3 && (
                <>
                  <br />
                  {address.address3}
                </>
              )}
              {address.city && (
                <>
                  <br />
                  {address.city},{" "}
                </>
              )}
              {address.state && <span>{address.state}, </span>}
              {address.zipcode && <span>{address.zipcode}, </span>}
              {address.country && <span>{address.country}</span>}
            </small>
          )
        )}
      </div>
    );
  }, []);

  return (
    <AbstractListPicker<TVendorLocationPickerOption>
      name={name}
      label={label}
      required={required}
      params={params}
      fetchUrl={vendorId ? `vendors/${vendorId}/vendor_locations` : ""}
      formatOptionLabel={formatOptionLabel}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      containerClassName="pickerLabel"
      placeholder="-- Select Vendor Location --"
    />
  );
};

export default memo(VendorLocationPicker);
