import React, { useEffect } from "react";
import WelcomeModal from "./welcomeModal";
import GuideModal from "./guideModal";
import { useDispatch } from "react-redux";
import {
  setShowWelcome,
  resetVendorSetupGuide,
  setupProgressType,
  setShowGuide,
  VENDOR_PORTAL_STEPPER_SHOWN,
} from "../../../../reducers/vp/vendorSetUpGuideReducer";
import { useTypedSelector } from "../../../../reducers";
import { isPaymentMethodLinkingRequired } from "../../../../services/paymentMethods/paymentMethodLinking";
import { IPurchaser } from "../../../../services/vp/types/purchasers.types";

const Welcome = () => {
  const dispatch = useDispatch();
  const showWelcome = useTypedSelector((state) => state.vendorSetupGuideRed.showWelcome);
  const showGuide = useTypedSelector((state) => state.vendorSetupGuideRed.showGuide);
  const isFirstLogin = useTypedSelector((state) => state.user.is_first_login);
  const shownForCurrentLogIn = localStorage.getItem(VENDOR_PORTAL_STEPPER_SHOWN) === "true" ? true : false;
  const setupProgress: setupProgressType = useTypedSelector((state) => state.vendorSetupGuideRed.setupProgress);
  const purchasers: IPurchaser[] = useTypedSelector((state) => state.vendorSetupGuideRed.purchasers);

  // will not show the guide if profile is completely
  const isProfileCompleted = () => {
    const linkingRequired = isPaymentMethodLinkingRequired(purchasers);
    const { bank_info, company_detail, contact_info, required_documents_completed } = setupProgress;
    if (bank_info && company_detail && contact_info && required_documents_completed && !linkingRequired) {
      return true;
    }
    return false;
  };

  const setShownForCurrentLogIn = (value: boolean) => {
    if (value) {
      localStorage.setItem(VENDOR_PORTAL_STEPPER_SHOWN, "true");
    } else {
      localStorage.setItem(VENDOR_PORTAL_STEPPER_SHOWN, "false");
    }
  };

  const checKShouldShowWelcomeOrGuide = () => {
    // if profile is completed, guide is not required && automatically popup guide only once for login
    if (!isProfileCompleted() && !shownForCurrentLogIn) {
      if (isFirstLogin) {
        // show welcome when first login
        dispatch(setShowWelcome(true));
      } else {
        // else show guide modal always
        dispatch(setShowGuide(true));
      }
      setShownForCurrentLogIn(true);
    }
  };

  // initialize guide
  useEffect(() => {
    checKShouldShowWelcomeOrGuide();

    return () => {
      // reseting guide state
      dispatch(resetVendorSetupGuide());
    };
  }, []);

  return (
    <>
      {showWelcome && <WelcomeModal />}
      {showGuide && <GuideModal />}
    </>
  );
};

export default Welcome;
