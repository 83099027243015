import React from "react";
import { Dropdown } from "react-bootstrap";

// children should be like
// <Dropdown.Item onClick={sendInvite} >Send Invites</Dropdown.Item>

type BulkActionDropdownPropsType = {
  children: React.ReactNode;
  topClassName?: string;
  menuClass?: string;
};

const BulkActionDropdown = ({ children, topClassName, menuClass }: BulkActionDropdownPropsType) => {
  return (
    <div className={"gridDropdown d-inline-block " + topClassName}>
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-autoclose-true">
          <i className="icon-g icon-tasks"></i>
          Bulk Action
        </Dropdown.Toggle>

        <Dropdown.Menu className={`${menuClass}`}>{children}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default BulkActionDropdown;
