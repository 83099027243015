import React, { useState } from "react";
import { ButtonGroup, Dropdown, SplitButton } from "react-bootstrap";
import RecurringInvoiceApis from "../../../../services/admin/invoices/recurringInvoice/recurringInvoiceApis";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { useTranslation } from "react-i18next";
import styles from "./recurringInvoice.module.css";
type Props = { params: any; dropUp?: boolean };

const BoolDropdown = ({ params, dropUp }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(params?.data?.schedule?.is_active);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSelect = async (value: string | null) => {
    let bool: boolean = value === "true";
    if (bool !== isActive) {
      setDisabled(true);
      try {
        let obj = {
          recurring: {
            schedule_attributes: {
              id: params.data?.schedule?.id,
              is_active: bool,
              service_name: "RECURRING",
            },
            id: params.data.id,
          },
        };
        const response = await RecurringInvoiceApis.editRecurringInvoiceDetail(params.data.id, obj);
        if (response) {
          setIsActive(bool);
          if (!params.data.schedule) {
            params.node.setData({
              ...params.data,
              schedule: {
                is_active: bool,
              },
            });
          } else {
            params.node.setDataValue("schedule.is_active", bool);
          }
          params.api.refreshClientSideRowModel("filter");
          CreateNotification(
            "Success",
            "Successfully turned " +
              (bool ? "on" : "off") +
              " Recurring Invoice" +
              (params?.data?.name ? ` - ${params.data.name}` : ""),
            NotificationType.success,
          );
          setDisabled(false);
        }
      } catch (error) {
        CreateNotification(
          "Error",
          "Failed to update  Recurring Invoice" + (params?.data?.name ? ` - ${params.data.name}` : ""),
          NotificationType.danger,
        );
        console.log(error);
        setDisabled(false);
      }
    }
  };

  return (
    <div className={"splitButton dropdownWidth-65 " + styles.dropdown}>
      <SplitButton
        as={ButtonGroup}
        id={`dropdown-split-variants-secondary`}
        size="sm"
        variant="secondary"
        title={isActive ? "On" : "Off"}
        disabled={disabled}
        onSelect={handleSelect}
        menuAlign={{ sm: "left" }}
        className={dropUp ? "dropup" : ""}
      >
        <Dropdown.Item bsPrefix="dropdown-item fontSizeNormal" as="button" active={isActive} eventKey="true">
          On
        </Dropdown.Item>
        <Dropdown.Item eventKey="false" bsPrefix="dropdown-item fontSizeNormal" active={!isActive} as="button">
          Off
        </Dropdown.Item>
      </SplitButton>
    </div>
  );
};

export default BoolDropdown;
