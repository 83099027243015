import { ReportTemplateCodeTypeMap, ReportTemplateModuleType, ReportTemplateOptionsType } from "./reportTemplateType";

export class ReportTemplateSvc {
  static EXTRACT_CODE_MAP: ReportTemplateCodeTypeMap = {
    Expense: ["gp_reim_transactions_listing", "gp_cc_transactions_listing"],
    Invoice: ["invoice_data_extract"],
    Payment: [],
  };
  static filterModuleType = (
    templates: ReportTemplateOptionsType,
    moduleType: ReportTemplateModuleType,
  ): ReportTemplateOptionsType => {
    return templates.filter((template) => template.module_type === moduleType);
  };
}
