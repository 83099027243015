import React from "react";
import { Field } from "redux-form";
import { noWhiteSpaceOnly, required } from "services/validations/reduxFormValidation";
import { RenderDatePicker, RenderTextArea, RenderRadioGroup } from "../../../../../forms/bootstrapFields";
import { RequiredFieldText } from "./requiredFieldIcon";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Style from "../dispute.module.css";

export const NotReceivedDispute = () => {
  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.option.not_received.${key}`);
  };

  const yes_no = [
    { label: t2("yes"), value: "Y" },
    { label: t2("no"), value: "N" },
  ];

  return (
    <Col>
      <Row>
        <RequiredFieldText text={t2("question")}></RequiredFieldText>
        <div style={{ width: "40px" }}></div>
        <Field
          name="form.options.CARDHOLDER_PARTICIPATED_ON_TRANSACTION"
          orientation="horizontal"
          component={RenderRadioGroup}
          group={yes_no}
          required={true}
          validate={[required]}
        ></Field>
      </Row>
      <Row>
        <Col md={10} className="px-0">
          <Field
            label={t2("describe") + " "}
            required={true}
            name="form.options.DESCRIBE_THE_ITEM_NOT_RECEIVED"
            component={RenderTextArea}
            validate={[required, noWhiteSpaceOnly]}
            className={`${Style.fixed_textarea}`}
          ></Field>
        </Col>
      </Row>
      <Row style={{ display: "flex", alignItems: "center" }}>
        <RequiredFieldText text={t2("last_date")}></RequiredFieldText>
        <div style={{ height: "35px", paddingLeft: "10px" }}>
          <Field
            name="form.options.EXPECTED_DELIVERY_DATE"
            component={RenderDatePicker}
            validate={[required]}
            type="date"
            maxDate={new Date()}
          ></Field>
        </div>
      </Row>
      <br />
      <Row>
        <Col md={10} className="px-0">
          <Field
            label={t2("contact_merchant")}
            required={true}
            name="form.options.FEEDBACK_FROM_MERCHANT"
            component={RenderTextArea}
            validate={[required, noWhiteSpaceOnly]}
            className={`${Style.fixed_textarea}`}
          ></Field>
        </Col>
      </Row>
    </Col>
  );
};
