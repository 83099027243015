import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { AccountHolder, AccountNumber, BankName, BsbCode, BusinessNumber3 } from "../fieldComponents/fieldsComponent";
import { withTranslation } from "react-i18next";
import CurrencyPicker from "../../vp/pickers/reduxPicker/currencyPicker/currencyPicker";
import { EftAusPayNetValidation } from "../validations/eftAusPayNetValidation";

const selectField = (fillteredPayment, fieldName) => {
  return fillteredPayment[0].validations.find((item) => item.field === fieldName);
};

const EftAusPayNetFormFields = (props) => {
  const setAusEftIninitialState = () => {
    if (!props.paymentMethodRed.id) {
      // set country
      props.countryPickerCallback({ value: "AUS" });
      // should be aud payment type
      props.currencyPickerCallback({ value: "AUD" });
    }
  };

  useEffect(() => {
    setAusEftIninitialState();
  }, []);

  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(EftAusPayNetValidation, "account_name")} />
        <AccountNumber {...props} field_valid={selectField(EftAusPayNetValidation, "account_number")} />
        <BankName {...props} field_valid={selectField(EftAusPayNetValidation, "bank_name")} />
      </Form.Row>
      <Form.Row>
        <BusinessNumber3 {...props} field_valid={selectField(EftAusPayNetValidation, "business_number")} />
        <BsbCode {...props} field_valid={selectField(EftAusPayNetValidation, "bsb_code")} />
        <CurrencyPicker {...props} selected={{ value: "AUD" }} showDropdownOptions={false} />
      </Form.Row>
    </span>
  );
};
export default withTranslation()(EftAusPayNetFormFields);
