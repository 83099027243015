import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoicesNavsTabs from "../../../../pages/admin/invoice/nav";
import { restApiService } from "../../../../providers/restApi";
import ClientDataGrid from "../../../common/dataGrid/clientDataGrid/clientDataGrid";
import { GridReadyEvent, RowClassRules } from "ag-grid-community";
import RecurringInvoiceApis from "../../../../services/admin/invoices/recurringInvoice/recurringInvoiceApis";
import { RecurringInvoiceListType } from "../../../../services/admin/invoices/recurringInvoice/recurringInvoiceTypes";
import GridFilterDropdown from "../../../datagrid/gridFilterDropdown";
import ToggleFilterButton from "../../../datagrid/buttons/toggleFilterButton";
import _ from "lodash";
import styles from "./recurringInvoice.module.css";
import recurringInvoiceGridHeader from "./headerRecurringInvoice";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { useTranslation } from "react-i18next";
import useConfirmModal from "../../../modals/confirmModal/useConfirmModalHook";
import { useTypedSelector } from "../../../../reducers";
import { Can } from "../../../../services/authorization/authorization";
import { IUser } from "../../../../services/common/user/userTypes";

const ListRecurringInvoice = () => {
  const [recurringInvoices, setRecurringInvoices] = useState<RecurringInvoiceListType[]>([]);
  const [gridApi, setGridApi] = useState<GridReadyEvent["api"]>();
  const [gridColumnApi, setGridColumnApi] = useState<GridReadyEvent["columnApi"]>();
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { createConfirmModal } = useConfirmModal();

  const { t } = useTranslation();

  const getRecurringInvoices = async () => {
    try {
      const result = await RecurringInvoiceApis.getRecurringInvoiceList();
      if (result) {
        setRecurringInvoices(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteConfirmCallBack = async (recurringInvoice: RecurringInvoiceListType) => {
    try {
      await RecurringInvoiceApis.deleteRecurringInvoice(recurringInvoice.id);
      await getRecurringInvoices();
      CreateNotification(
        t("success"),
        "Recurring Invoice - " + recurringInvoice.name + " Successfully deleted",
        NotificationType.success,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRecurringInvoice = (recurringInvoice: RecurringInvoiceListType) => {
    createConfirmModal({
      title: "Confirm",
      body: `Are you sure, you want to delete Recurring Invoice - ${recurringInvoice.name}?`,
      saveCallBack: deleteConfirmCallBack,
      cancelCallBack: null,
      callBackData: recurringInvoice,
    });
  };

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const rowClassRules = useMemo<RowClassRules>(() => {
    return {
      // row style function
      "grey-row": (params) => {
        return params?.data && (!params.data.schedule || !params.data.schedule.is_active);
      },
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(gridApi)) {
      gridApi.refreshHeader();
    }
  }, [showFilter]);

  useEffect(() => {
    getRecurringInvoices();
  }, []);

  return (
    <Container fluid className={styles.backgroundColor}>
      <Row>
        <Col md="12" className="removeHPseudoClass">
          <InvoicesNavsTabs activePageName="Recurring Invoices" />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {" "}
          {_.isArray(recurringInvoices) && recurringInvoices.length > 0 && (
            <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} />
          )}
          <Can I="add" a="Invoices">
            <a
              className={`btn btn-primary mx-3 ${styles.addButton}`}
              href={restApiService.angularBaseURL() + "recurring_invoices/new"}
            >
              <i className={"icon icon-plus-new " + styles.iconSize}></i>Add
            </a>
          </Can>
          {_.isArray(recurringInvoices) && recurringInvoices.length > 0 && (
            <ToggleFilterButton
              classes="float-right"
              clickCallback={() => {
                setShowFilter((prev) => !prev);
              }}
            />
          )}
        </Col>
      </Row>
      <Row className={`gridWhite gridWithBorder gridZIndex ${styles["grid"]}`}>
        {((_.isArray(recurringInvoices) && recurringInvoices.length > 0) || !recurringInvoices) && (
          <ClientDataGrid
            rowHeight={47}
            gridStorageName="recurring_invoices"
            columnDefs={recurringInvoiceGridHeader(
              (recurringInvoice: RecurringInvoiceListType) => deleteRecurringInvoice(recurringInvoice),
              currentUser,
              recurringInvoices.length,
            )}
            gridReady={gridReady}
            rowClassRules={rowClassRules}
            defaultColDef={{ floatingFilter: showFilter }}
            rowData={recurringInvoices ? recurringInvoices : []}
          />
        )}
        {_.isArray(recurringInvoices) && recurringInvoices.length < 1 && (
          <h3 className={`px-4 mx-4 text-center ${styles["sub-header"]}`}>
            Currently, there are no recurring invoices to display. Select the “+Add” button above to begin creating your
            first recurring invoice
          </h3>
        )}
      </Row>
    </Container>
  );
};

export default ListRecurringInvoice;
