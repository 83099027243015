import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { WrappedFieldProps } from "redux-form";
import { ReportTemplateApis } from "../../../../../services/admin/reports/templates/reportTemplateApis";
import { ReportTemplateSvc } from "../../../../../services/admin/reports/templates/reportTemplateSvc";
import {
  ReportTemplateModuleType,
  ReportTemplateOptionType,
  ReportTemplateOptionsType,
  ReportTemplatesType,
} from "../../../../../services/admin/reports/templates/reportTemplateType";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Styles from "../override.module.css";

interface ReportTemplatePickerPropsType extends WrappedFieldProps {
  labelClassName?: string;
  label?: string;
  tooltip?: string;
  required: boolean;
  classification: "Standard" | "DataExtract";
  moduleType?: ReportTemplateModuleType;
}

const ReportTemplateCheckboxes = ({
  labelClassName,
  label,
  input,
  meta: { touched, error },
  tooltip,
  required,
  classification = "Standard",
  moduleType,
}: ReportTemplatePickerPropsType) => {
  const dataRef = useRef<ReportTemplateOptionsType>([]);
  const [reportTemplates, setReportTemplates] = useState<ReportTemplateOptionsType>([]);
  const checkedValues = useRef<Array<ReportTemplateOptionType | null>>([]);

  const parseForSelect = useCallback((reportTemplates: ReportTemplatesType) => {
    let parsedData = reportTemplates.map((reportTemplate) => ({
      value: reportTemplate.id,
      label: reportTemplate.name,
      code: reportTemplate.code,
      module_type: reportTemplate.module_type,
    }));
    return parsedData;
  }, []);

  const handleFiltered = useCallback((templates: ReportTemplateOptionsType, moduleType?: ReportTemplateModuleType) => {
    if (moduleType) {
      setReportTemplates(_.cloneDeep(ReportTemplateSvc.filterModuleType(templates, moduleType)));
    } else {
      setReportTemplates(_.cloneDeep(templates));
    }
    checkedValues.current = [];
  }, []);

  const getReportTemplates = useCallback(async () => {
    let result: ReportTemplatesType = (await ReportTemplateApis.getList({ classification })) as ReportTemplatesType;
    if (result && result.length > 0) {
      // filter out invoice data extract export choice
      result = result.filter((report) => report.module_type !== "Invoice" && report.classification === "DataExtract");
      dataRef.current = parseForSelect(result) as ReportTemplateOptionsType;
      handleFiltered(dataRef.current, moduleType);
    }
    checkedValues.current = [];
  }, [classification]);

  const handleCheckClick = useCallback(
    (index: number, template: ReportTemplateOptionType) => () => {
      let newCheckedValues: Array<ReportTemplateOptionType | null> = [...checkedValues.current];
      if (newCheckedValues[index]) {
        newCheckedValues[index] = null;
      } else {
        newCheckedValues[index] = template;
      }
      checkedValues.current = newCheckedValues;
      input.onChange(_.cloneDeep(newCheckedValues.filter((obj) => !!obj)));
    },
    [],
  );

  useEffect(() => {
    if (moduleType && dataRef.current.length) {
      handleFiltered(dataRef.current, moduleType);
    }
  }, [moduleType]);

  useEffect(() => {
    getReportTemplates();
  }, []);

  let mappedIds: { [key: number]: true } = {};
  if (input.value) {
    input.value.forEach((item: ReportTemplateOptionType) => {
      mappedIds[item.value] = true;
    });
  }

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label className={labelClassName}>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      {reportTemplates.map((rt, index) => {
        return (
          <Row key={rt.value} className="mx-0">
            <Col className="px-0" style={{ flex: 0 }}>
              <input onChange={handleCheckClick(index, rt)} type="checkbox" checked={!!mappedIds[rt.value]}></input>
            </Col>
            <Col>{rt.label} (File + Receipt Image Zip)</Col>
          </Row>
        );
      })}
      {error && touched && <div className={Styles.pickerError}>{error}</div>}
    </Form.Group>
  );
};

export default ReportTemplateCheckboxes;
