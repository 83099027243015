import { useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomModal from "../../modals/customModal";

const BankRoutingSample = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const openModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  return (
    <>
      {show && (
        <CustomModal
          show={show}
          onHide={() => closeModal()}
          size={"md"}
          header={t("contactInformation.contactInfo")}
          body={
            <Row>
              <Col md="12">
                <div>
                  <strong> {t("contactInformation.accountHolder")} </strong>
                  {t("contactInformation.accountHolderInstructions")}
                </div>
                <div>
                  <strong> {t("contactInformation.billingName")} </strong>
                  {t("contactInformation.billingNameInstruction")}
                </div>
                <div className="mt-4">
                  <h4>{t("contactInformation.bankAccountRoutingExample")}</h4>
                  <hr />
                  <Image width={"100%"} src={require("./img/sample-check.png")} />
                </div>
              </Col>
            </Row>
          }
          footer={
            <Row className="w-100">
              <Col md="12" className="d-flex justify-content-center align-items-center">
                <Button variant={"secondary"} onClick={() => closeModal()}>
                  {t("close")}
                </Button>
              </Col>
            </Row>
          }
        />
      )}

      <span role={"button"} onClick={() => openModal()}>
        <Image width={"23px"} roundedCircle src={require("./../../../assets/icons/actions/questionmark.png")} />
      </span>
    </>
  );
};

export default BankRoutingSample;
