import React from "react";
import { Field } from "redux-form";
import { RenderCheckBox } from "../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";

const ForceSyncField = () => {
  const { t } = useTranslation();
  return (
    <Field
      name="user.force_push"
      label={t("admin.pages.users.forceSyncWithErp")}
      component={RenderCheckBox}
      type="checkbox"
    />
  );
};

export default ForceSyncField;
