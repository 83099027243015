import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderCheckBox, RenderSelect } from "../../../../../../components/forms/bootstrapFields";

const AddendaRecords = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <Col lg="6">
          <Field
            component={RenderSelect}
            name="form.provider_settings.nacha_addenda"
            label="Optional WEB Addenda records (Verify with the bank)"
            id="nacha_addenda"
            options={[
              { value: null, label: "Not Used" },
              { value: "invoice_description", label: "Invoice Description" },
              { value: "invoice_number", label: "Invoice Number" },
              { value: "payment_memo", label: "Payment Memo" },
              { value: "payment_batch_number", label: "Payment Batch Number" },
            ]}
          />
        </Col>

        <Col lg="12">
          <Row className="m-0">
            <Field
              component={RenderCheckBox}
              name="form.provider_settings.sameday_effective_date"
              label="Same Day Effective Date Processing"
              id="same_day_effective_date"
              type="checkbox"
            />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default AddendaRecords;
