import _ from "lodash";
import {
  SET_ACTIVE_REQUIRED,
  SET_ACTIVE_STEP_INDEX,
  SET_ACTIVE_STEP_LABEL,
  SET_ACTIVE_TIER_LABEL,
  SET_APPROVAL_FORM_DATA,
  SET_APPROVAL_WORKFLOW_STEPS,
  SET_LAST_STEP_SAVED_FLAG,
  SET_SHOW_ADD_BUTTON_TOOLTIP,
  SET_VIEW,
  SET_WORKFLOW_TYPE,
  SET_IS_INACTIVE,
  SET_STATIC_WORKFLOWS,
  SET_STATIC_WORKFLOW_NAMES,
  SET_STATIC_WORKFLOW_NAMES_ALL,
  SET_ACTIVE_STATIC_WORKFLOW,
  SET_STATIC_TIERS,
  SET_ACTIVE_TIER_INDEX,
  SET_STATIC,
  SET_ORIGINAL_WORKFLOW_NAME,
  SET_WILL_TRIGGER,
} from "../actions/actionTypes";

const GRIDVIEW = "GRIDVIEW";

const initialState = {
  isLastStepSaved: true,
  showAddButtonTooltip: false,
  workFlowSteps: [],
  staticWorkFlows: [],
  staticWorkFlowNames: [],
  staticWorkFlowNamesAll: [],
  originalWorkFlowName: {
    name: "",
    index: -1,
  },
  staticTiers: [],
  view: GRIDVIEW,
  workFlowType: "PurchaseOrder",
  isStatic: false,
  activeRequired: true,
  activeStepIndex: 0,
  isInactive: false,
  activeTierIndex: 0,
  activeStaticWorkFlow: "",
  willTrigger: null,
  approvalFormData: {
    priority: 1,
    workflow_type: "",
    value: {
      label: "1",
      approver_type: "USER",
      any_member: false,
      any_member_count: 0,
      team_member: [],
      triggers: [],
    },
    skip_duplicate_approver: false,
    ignore_approval_limit: false,
    email_notification: false,
    email_reminder: false,
    parallel_team: false,
  },
};

const approvalSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPROVAL_WORKFLOW_STEPS:
      return { ...state, workFlowSteps: action.payload };
    case SET_VIEW:
      return { ...state, view: action.payload };
    case SET_WORKFLOW_TYPE:
      return { ...state, workFlowType: action.payload };
    case SET_ACTIVE_REQUIRED:
      return { ...state, activeRequired: action.payload };
    case SET_ACTIVE_STEP_INDEX:
      return { ...state, activeStepIndex: action.payload };
    case SET_APPROVAL_FORM_DATA:
      return { ...state, approvalFormData: action.payload };
    case SET_ACTIVE_STEP_LABEL:
      return { ...state, workFlowSteps: action.payload };
    case SET_ACTIVE_TIER_LABEL:
      return { ...state, staticTiers: action.payload };
    case SET_LAST_STEP_SAVED_FLAG:
      return { ...state, isLastStepSaved: action.payload };
    case SET_SHOW_ADD_BUTTON_TOOLTIP:
      return { ...state, showAddButtonTooltip: action.payload };
    case SET_IS_INACTIVE:
      return { ...state, isInactive: action.payload };
    case SET_STATIC_WORKFLOWS:
      return { ...state, staticWorkFlows: action.payload };
    case SET_STATIC_WORKFLOW_NAMES:
      return { ...state, staticWorkFlowNames: action.payload };
    case SET_STATIC_WORKFLOW_NAMES_ALL:
      return { ...state, staticWorkFlowNamesAll: action.payload };
    case SET_ACTIVE_STATIC_WORKFLOW:
      return { ...state, activeStaticWorkFlow: action.payload };
    case SET_STATIC_TIERS:
      return { ...state, staticTiers: action.payload };
    case SET_ACTIVE_TIER_INDEX:
      return { ...state, activeTierIndex: action.payload };
    case SET_STATIC:
      return { ...state, isStatic: action.payload };
    case SET_ORIGINAL_WORKFLOW_NAME:
      return { ...state, originalWorkFlowName: action.payload };
    case SET_WILL_TRIGGER:
      return { ...state, willTrigger: action.payload };
    default:
      return state;
  }
};

export default approvalSettingsReducer;

// ACTIOINS //

export const setWorkFlowSteps = (workFlowSteps) => (dispatch) => {
  dispatch({ type: SET_APPROVAL_WORKFLOW_STEPS, payload: workFlowSteps });
};

export const setView = (view) => (dispatch) => {
  dispatch({ type: SET_VIEW, payload: view });
};

export const setApprovalFormData = (workFlowStep) => (dispatch) => {
  dispatch({ type: SET_APPROVAL_FORM_DATA, payload: workFlowStep });
};

export const setActiveStepIndex = (step) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: step });
};

export const setWorkFlowActiveStatus = (status) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_REQUIRED, payload: status });
};

export const setWorkFlowType = (workFlowType) => (dispatch) => {
  dispatch({ type: SET_WORKFLOW_TYPE, payload: workFlowType });
};

export const setStaticWorkFlows = (staticWorkFlows) => (dispatch) => {
  dispatch({ type: SET_STATIC_WORKFLOWS, payload: staticWorkFlows });
};

export const setStaticWorkFlowNames = (staticWorkFlowNames) => (dispatch) => {
  dispatch({ type: SET_STATIC_WORKFLOW_NAMES, payload: staticWorkFlowNames });
};

export const setAllStaticWorkFlowNames = (staticWorkFlowNames) => (dispatch) => {
  dispatch({ type: SET_STATIC_WORKFLOW_NAMES_ALL, payload: staticWorkFlowNames });
};

export const setActiveStaticWorkFlow = (workFlowName, switchStep) => (dispatch, getState) => {
  // Logic to ensure that no two workflow names in different static workflows are the same
  const { staticWorkFlowNamesAll, staticWorkFlowNames, activeStepIndex, originalWorkFlowName, activeStaticWorkFlow } =
    getState().approvalSettingsReducer;
  if (!switchStep) {
    const index = staticWorkFlowNamesAll.findIndex(
      (name) => name.workflow_name_label === activeStaticWorkFlow.workflow_name_label,
    );
    if (!originalWorkFlowName.name) {
      originalWorkFlowName.name = activeStaticWorkFlow.workflow_name_label;
      originalWorkFlowName.index = index;
    }
    if (index >= 0) {
      staticWorkFlowNamesAll[originalWorkFlowName.index].workflow_name_label = workFlowName;
      workFlowName = {
        workflow_name: staticWorkFlowNamesAll[originalWorkFlowName.index].workflow_name,
        workflow_name_label: workFlowName,
      };

      dispatch({ type: SET_STATIC_WORKFLOW_NAMES_ALL, payload: staticWorkFlowNamesAll });
    }
  } else {
    if (originalWorkFlowName.name && originalWorkFlowName.index >= 0) {
      staticWorkFlowNamesAll[originalWorkFlowName.index].workflow_name_label = originalWorkFlowName.name;
    }
    originalWorkFlowName.name = "";
    originalWorkFlowName.index = -1;
  }
  dispatch({ type: SET_ACTIVE_STATIC_WORKFLOW, payload: workFlowName });
};

export const setActiveLabel = (label) => (dispatch, getState) => {
  // Change the label of the current step/tier as the user types
  const { workFlowSteps, activeStepIndex, isStatic, staticTiers, activeTierIndex } = getState().approvalSettingsReducer;
  if (!isStatic) {
    const newWorkFlowSteps = workFlowSteps.filter((workFlowStep, index) => {
      if (index === activeStepIndex) {
        workFlowStep.value.label = label;
        return workFlowStep;
      } else {
        return workFlowStep;
      }
    });

    dispatch({ type: SET_ACTIVE_STEP_LABEL, payload: newWorkFlowSteps });
  } else {
    const newStaticTiers = staticTiers.filter((staticTier, index) => {
      if (index === activeTierIndex) {
        staticTier.value.label = label;
        return staticTier;
      } else {
        return staticTier;
      }
    });

    dispatch({ type: SET_ACTIVE_TIER_LABEL, payload: newStaticTiers });
  }
};

export const setLastStepSaveFlag = (value) => (dispatch) => {
  dispatch({ type: SET_LAST_STEP_SAVED_FLAG, payload: value });
};

export const setShowAddButtonTooltip = (value) => (dispatch) => {
  dispatch({ type: SET_SHOW_ADD_BUTTON_TOOLTIP, payload: value });
};

export const setActiveTierIndex = (value) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_TIER_INDEX, payload: value });
};

export const setStatic = (value) => (dispatch) => {
  dispatch({ type: SET_STATIC, payload: value });
};

export const setStaticTiers = (value) => (dispatch) => {
  dispatch({ type: SET_STATIC_TIERS, payload: value });
};

export const setActiveStepById = (paramId) => (dispatch, getState) => {
  const { workFlowSteps } = getState().approvalSettingsReducer;
  const activeStepIndex = _.findIndex(workFlowSteps, (step) => step.id === paramId);
  dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: activeStepIndex });
};

export const setIsInactive = (value) => (dispatch) => {
  dispatch({ type: SET_IS_INACTIVE, payload: value });
};

export const setActiveWorkFlowById = (paramId, paramWorkFlows) => (dispatch, getState) => {
  const { workFlowSteps } = getState().approvalSettingsReducer;
  const { staticWorkFlows } = getState().approvalSettingsReducer;
  const activeStepIndex = _.findIndex(staticWorkFlows, (step) => step.id === paramId);
  dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: activeStepIndex });
};

export const setWillTrigger = (value) => (dispatch) => {
  dispatch({ type: SET_WILL_TRIGGER, payload: value });
};
