import React, { ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { Field } from "redux-form";
import { PaymentFieldType } from "../types";
import { RenderField } from "../../../forms/bootstrapFields";
import { CHANGE_ACCOUNT_TAX_NUMBER } from "../../../../actions/actionTypes";

type TaxAccountNumberPropsType = PaymentFieldType;

const TaxAccountNumber = ({ validations, required, label }: TaxAccountNumberPropsType) => {
  const dispatch = useDispatch();

  const changeTaxAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({ type: CHANGE_ACCOUNT_TAX_NUMBER, payload: e.target.value });
  };

  return (
    <Field
      name="sort_code"
      label={label}
      component={RenderField}
      required={required}
      validate={validations}
      onChange={changeTaxAccountNumber}
    />
  );
};

export default TaxAccountNumber;
