import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Form, Button, InputGroup, OverlayTrigger, Tooltip, Container } from "react-bootstrap";
import { companyDateFormat } from "services/general/dateSvc";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { formattedAmount } from "services/general/helpers";
import { ReceiptsTypes } from "services/admin/expenses/receipts/receiptsType";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import CustomModal from "components/modals/customModal";
import ExpensesApis from "services/admin/expenses/expensesApis";
import Panel from "components/common/panel/panel";
import ReceiptsApis from "services/admin/expenses/receipts/receiptsApis";
import styles from "./expenseItem.module.css";

interface ExpenseReceiptListProps {
  expenseItem: ExpensesTypes.ExpenseItemFormDataType | undefined;
  setShowReceiptList: (show: boolean) => void;
  receiptAttachedCallback: () => void;
}

const ExpenseReceiptList: React.FC<ExpenseReceiptListProps> = ({
  expenseItem,
  setShowReceiptList,
  receiptAttachedCallback,
}) => {
  const [receiptList, setReceiptList] = useState<ReceiptsTypes.ListItem[]>([]);
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});
  const [visibleFilteredItems, setVisibleFilteredItems] = useState<{ [key: number]: boolean }>({});
  const [enableDoneButton, setEnableDoneButton] = useState<boolean>(false);
  const [filterCompanyNameText, setFilterCompanyNameText] = useState<string>("");
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [zoomDialogTitle, setZoomDialogTitle] = useState<string>("");

  const clearFilterList = useRef<{ [key: string]: boolean }[]>([]);
  const currentUser = useTypedSelector((state) => state.user);

  const { t } = useTranslation();
  const t2 = (key: string, options: any = undefined) => {
    return t(`components.admin.expenseReceiptList.${key}`, options);
  };

  const getReceipts = async () => {
    const BASE_PARAMS = {
      document_type: "RECEIPT",
      status: ["PENDING_OCR", "PENDING_MATCH", "ACTION_REQUIRED"],
      documentable_type: "Contact",
    };

    var result = await ReceiptsApis.getReceiptList({ filter: BASE_PARAMS });

    setReceiptList(result.data);

    const initialCheckedValues = result.data.map((receipt) => ({ [String(receipt.id)]: false }));
    setCheckedItems(Object.assign({}, ...initialCheckedValues));

    clearFilterList.current = result.data.map((receipt) => ({ [String(receipt.id)]: true }));
    setVisibleFilteredItems(Object.assign({}, ...clearFilterList.current));
  };

  const handleCheckboxChange = (id: number) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const attachReceipts = async () => {
    if (expenseItem && expenseItem.id) {
      // Obtain all the user-selected receipt ids
      let selectedReceiptIds = Object.keys(checkedItems)
        .filter((key) => checkedItems[Number(key)])
        .map(Number);

      // Store the count of newly attached receipts for user notification on success
      const newReceiptCount = selectedReceiptIds.length;

      // Add any existing linked documents to the array for the PATCH that is about to occur, otherwise any previous attachments will be lost.
      if (expenseItem.assets && Array.isArray(expenseItem.assets)) {
        expenseItem.assets.forEach((asset) => {
          if (asset.linked_document_id) {
            selectedReceiptIds.push(asset.linked_document_id);
          }
        });
      }

      await ExpensesApis.addExpenseItemDocuments({ id: expenseItem.id.toString(), document_ids: selectedReceiptIds });

      // All newly attached receipts need their **notes** to be appended to the parent expense item's **description**, separated by the pipe character
      let receiptNotes: string[] = [];

      if (expenseItem.description && expenseItem.description.length > 0) {
        receiptNotes.push(expenseItem.description);
      }

      receiptList
        .filter((receipt) => receipt.id !== undefined && selectedReceiptIds.includes(Number(receipt.id)))
        .forEach((receipt) => {
          if (typeof receipt.note === "string" && (receipt.note as string).length > 0) {
            receiptNotes.push(receipt.note);
          }
        });

      // On existing multiline descriptions, replace newlines with pipes.  Sometimes they sneak in.
      expenseItem.description = receiptNotes.join(" | ").replace(/\n/g, " | ");

      await ExpensesApis.updateExpenseItem({
        id: expenseItem.id.toString(),
        payload: { expense_item: expenseItem },
      });

      CreateNotification(
        t("success"),
        newReceiptCount > 1 ? t2("successNotificationPlural", { count: newReceiptCount }) : t2("successNotification"),
        NotificationType.success,
      );

      setShowReceiptList(false);

      if (receiptAttachedCallback) {
        receiptAttachedCallback();
      }
    }
  };

  const handleFilterKeyPress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCompanyNameText(event.target.value);
    const MerchantNameFilterValue = event.target.value.trim().toLowerCase();
    const receipts = receiptList;

    if (MerchantNameFilterValue.length === 0) {
      setVisibleFilteredItems(Object.assign({}, ...clearFilterList.current));
    } else {
      const filteredReceipts = receipts.map((receipt) => {
        return {
          [String(receipt.id)]:
            receipt.documentable?.merchant?.toLowerCase().includes(MerchantNameFilterValue) ||
            checkedItems[Number(receipt.id)], // Receipts that are checked should always be visible
        };
      });
      setVisibleFilteredItems(Object.assign({}, ...filteredReceipts));
    }
  };

  const displayImageZoomed = (merchantName: string, imageUrl: string) => {
    setZoomDialogTitle(merchantName);
    setSelectedImage(imageUrl);
    setShow(true);
  };

  const hideImageZoomed = () => {
    setShow(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    const count = Object.values(checkedItems).filter((value) => value).length;
    setEnableDoneButton(count > 0);
  }, [checkedItems]);

  useEffect(() => {
    getReceipts();
  }, []);

  const listContent = () => {
    return (
      <>
        <Col>
          <Row>{t2("headerLine")}</Row>
        </Col>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder={t2("search")}
            value={filterCompanyNameText}
            className={styles.searchBar}
            onChange={handleFilterKeyPress}
          />
        </InputGroup>
        <Col>
          <Row className={styles.expenseReceiptListListContainer}>
            {receiptList.map((receipt, index) => (
              <Container key={receipt.id}>
                {receipt.id !== undefined && visibleFilteredItems[Number(receipt.id)] ? (
                  <Panel
                    wrapperClass={styles.receiptRowContainerPanel}
                    cardBodyClass={styles.receiptRowContainerPanelBody}
                  >
                    <Row>
                      <Col xs="auto" className="pl-4 pr-2 d-flex align-items-center">
                        <Form.Check
                          type="checkbox"
                          id={`checkbox-${receipt.id}`}
                          checked={checkedItems[Number(receipt?.id)]}
                          onChange={() => handleCheckboxChange(Number(receipt.id))}
                        />
                      </Col>
                      <Col xs="2" className="pr-0 pl-0 d-flex align-items-center">
                        <object
                          className={styles.expenseReceiptListThumbnail}
                          key={receipt.assets_attributes?.[0]?.asset_file_file_name}
                          data={receipt.assets_attributes?.[0]?.asset_expiring_url}
                          type="image/png"
                          aria-label="image"
                          onClick={(event) => {
                            displayImageZoomed(
                              receipt.documentable?.merchant || t2("notScanned"),
                              receipt.assets_attributes?.[0]?.asset_expiring_url || "",
                            );
                          }}
                        />
                      </Col>
                      <Col xs="9" className="pl-0">
                        <Row className={`${styles.expenseReceiptListTopRow} pl-0`}>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${receipt.documentable?.merchant}`}>
                                {receipt.documentable?.merchant || t2("notScanned")}
                              </Tooltip>
                            }
                          >
                            <Col xs="7">{receipt.documentable?.merchant || t2("notScanned")}</Col>
                          </OverlayTrigger>
                          <Col xs="3">{companyDateFormat(receipt.created_at, currentUser)}</Col>
                          <Col xs="2">
                            {receipt.documentable?.amount
                              ? formattedAmount(
                                  receipt.documentable?.amount.toString(),
                                  receipt.documentable.currency_code,
                                  2,
                                  true,
                                )
                              : t2("notScanned")}
                          </Col>
                        </Row>
                        <Row className={styles.expenseReceiptListSecondRow}>
                          <Col>
                            {t2("note")}
                            {receipt.note}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Panel>
                ) : (
                  <></>
                )}
              </Container>
            ))}
          </Row>
        </Col>
        <Row className={`justify-content-end ${styles.expenseReceiptListFooter}`}>
          <Col xs="auto">
            <Button variant="secondary" onClick={() => setShowReceiptList(false)}>
              {t("cancel")}
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="primary" onClick={attachReceipts} disabled={!enableDoneButton}>
              {t("done")}
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      {listContent()}
      <CustomModal
        size="lg"
        show={show}
        onHide={hideImageZoomed}
        header={zoomDialogTitle}
        headerTitleClass={styles.zoomedHeader}
        body={<>{selectedImage && <img src={selectedImage} alt="Full Size Object" className={styles.zoomedImage} />}</>}
      ></CustomModal>
    </div>
  );
};

export default ExpenseReceiptList;
