import currency_codes from "../../lookups/currencyCodeLookup";

export function currencySymbolRenderer(code) {
  let currencyCodes = currency_codes;
  let symbol = "$";
  currencyCodes.map((currencyObj) => {
    if (currencyObj.value === code) {
      symbol = currencyObj.symbol;
    }
  });
  return symbol;
}
