import React, { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { Mandatory } from "components/forms/bootstrapFields";
import { onBlurSingle, onChangeSingle } from "services/general/helpers";
import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import _ from "lodash";
import { WrappedFieldProps } from "redux-form";

interface CustomNotesPickerPropsType extends WrappedFieldProps {
  label?: string;
  input: any;
  required?: boolean;
  tooltip?: string;
  placeholder?: string;
  disabled?: boolean;
  instanceId?: string;
}

type CustomNotesObjType = {
  id?: number | string;
  value?: number | string;
  name?: string;
  status?: string;
  label?: string;
  company_id?: number;
  description?: string;
  external_id?: string;
};

const CustomNotesPicker = ({
  label,
  input,
  required,
  tooltip,
  placeholder,
  disabled,
  instanceId = "custom-notes-picker",
}: CustomNotesPickerPropsType) => {
  const [customNotes, setCustomNotes] = useState<CustomNotesObjType[]>([]);
  const mounted = useRef(false);
  const [selected, setSelected] = useState<CustomNotesObjType>();

  const parseForSelect = (options: CustomNotesObjType[]) => {
    return options.map((option: CustomNotesObjType) => {
      return {
        value: option.id,
        label: option.name,
        status: option.status,
      };
    });
  };

  const getCustomNotes = useCallback(async () => {
    const result: AxiosResponse = await restApiService.get(
      `custom_notes`,
      { status: "ACTIVE" },
      null,
      true,
      null,
      true,
    );
    if (result?.data) {
      let list = parseForSelect(result?.data);
      if (mounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Custom Note --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setCustomNotes(list);
      }
      return list;
    }
  }, []);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        try {
          const result = await restApiService.get("custom_notes/" + input.value);
          const inactiveCustomNotes = {
            value: result.data.id,
            label: (
              <>
                {result.data.name}
                {result.data.status === "INACTIVE" && (
                  <small style={{ fontSize: "10px" }}> -({result.data.status})</small>
                )}
              </>
            ),
          };
          selectedValues = inactiveCustomNotes;
        } catch (error) {
          console.log(error);
        }
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    getCustomNotes().then((res) => {
      findSelected(input, res).then((response: CustomNotesObjType) => {
        setSelected(response);
      });
    });
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [input]);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          placeholder={placeholder}
          required={required}
          value={selected}
          isClearable={!required}
          onChange={(selected) => onChangeSingle(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={customNotes}
          disable={disabled}
          instanceId={instanceId}
          getOptionLabel={(option: CustomNotesObjType) => {
            return (
              <>
                <span className="formField">{option.label}</span>
                {option.status === "INACTIVE" && <small>{option.status}</small>}
              </>
            );
          }}
          filterOption={(option: any, searchText) =>
            option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
          }
        ></Select>
      </Form.Group>
    </>
  );
};

export default CustomNotesPicker;
