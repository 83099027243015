import { GridReadyEvent } from "ag-grid-community";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { getReportStorageName, getSectionRoutes } from "services/admin/reports/reportSvc";
import { ReportApis } from "../../../../services/admin/reports/reportApis";
import {
  DataExtractModuleTypes,
  ReportCategoryOptionsType,
  ReportClassificationType,
  ReportType,
} from "../../../../services/admin/reports/reportTypes";
import ClientDataGrid from "../../../common/dataGrid/clientDataGrid/clientDataGrid";
import useShowFilterState from "../../../common/hooks/useShowFilterState";
import ToggleFilterButton from "../../../datagrid/buttons/toggleFilterButton";
import GridFilterDropdown from "../../../datagrid/gridFilterDropdown";
import NavTabs from "../navigation/nav";
import { ListSectionNav } from "../navigation/nav.list";
import AddReportForm from "./addReportForm";
import { getReportsHeaders } from "./listReportsHeaders";
import styles from "./reports.module.css";
import { ReportTemplatesType } from "services/admin/reports/templates/reportTemplateType";
import { ReportTemplateApis } from "services/admin/reports/templates/reportTemplateApis";
import { shallowEqual } from "react-redux";
import { CompanyType } from "services/common/user/userTypes";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

interface ListReportsPropsType {
  dataExtractModule?: DataExtractModuleTypes;
  classification: ReportClassificationType;
  createLabel: string;
  filterName: string;
  options?: ReportCategoryOptionsType;
}

const ListReports = ({ dataExtractModule, classification, createLabel, filterName, options }: ListReportsPropsType) => {
  const company: CompanyType = useTypedSelector((state) => state.user?.company, shallowEqual);
  const currentUser = useTypedSelector((state) => state.user);
  const [gridApi, setGridApi] = useState<GridReadyEvent["api"]>();
  const [gridColumnApi, setGridColumnApi] = useState<GridReadyEvent["columnApi"]>();
  const [reportsList, setReportsList] = useState<ReportType>([]);
  const [addVisible, setAddVisible] = useState<boolean>(false);
  const { showFilters, updateShowFilters } = useShowFilterState(filterName);
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | null>(null);

  const filteredReportsList = useMemo(() => {
    if (date) {
      return reportsList.filter((report: any) => {
        if (report.started) {
          const reportDate = new Date(report.created_at);
          return reportDate.getMonth() === date.getMonth() && reportDate.getFullYear() === date.getFullYear();
        }
        return false; // Exclude the report if report.started is falsy
      });
    } else {
      return reportsList; // If no date is selected, show all reports
    }
  }, [reportsList, date]);

  const onDateChanged = (selectedDate: Date | null) => {
    setDate(selectedDate);
  };

  const isInvoiceDataExtract = () => {
    return classification === "DataExtract" && dataExtractModule === "Invoice";
  };

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getReportsList = async (dataExtractModule?: DataExtractModuleTypes) => {
    try {
      const params = { classification };
      let result = await ReportApis.getList(params);
      if (result) {
        if (dataExtractModule) {
          result = result.filter((report: any) => {
            return dataExtractModule === report.report_template.module_type;
          });
        }
        setReportsList(result);
      }
      // result && setReportsList(result);
    } catch (error) {}
  };

  const getNewReportsList = async (dataExtractModule?: DataExtractModuleTypes) => {
    try {
      const params = { classification };
      let result = await ReportApis.getList(params, false);
      if (result) {
        if (dataExtractModule) {
          result = result.filter((report: any) => {
            return dataExtractModule === report.report_template.module_type;
          });
        }
        setReportsList(result);
      }
      // result && setReportsList(result);
    } catch (error) {}
  };

  const showAddForm = useCallback(() => {
    setAddVisible(true);
  }, []);

  const hideAddForm = useCallback(() => {
    setAddVisible(false);
  }, []);

  const reportsGridHeaders = getReportsHeaders(
    () => getNewReportsList(dataExtractModule),
    currentUser,
    dataExtractModule,
  );

  const getReportStatusByCode = (code: string) => {
    let reportStatus;
    switch (code) {
      case "visa_s_balance_detail":
      case "visa_s_transactions_listing":
        reportStatus = "NEW";
        break;
      case "payment_reissue_listing":
      case "payment_refund_listing":
      case "outstanding_check_listing":
      case "unprocessed_nvp_transactions":
        reportStatus = "PROCESSING";
        break;
      default:
        reportStatus = "PENDING";
        break;
    }
    return reportStatus;
  };

  const createReport = useCallback(async () => {
    const res: ReportTemplatesType = (await ReportTemplateApis.getList({
      classification,
      module_type: dataExtractModule,
    })) as ReportTemplatesType;
    if (res && res.length > 0) {
      const reportTemplate: any = res[0];
      let status = getReportStatusByCode(reportTemplate.code);
      const payload = {
        id: 0,
        report_template_id: reportTemplate.id,
        name: reportTemplate.name,
        export_format: reportTemplate.export_format,
        company_id: company?.id,
        status: status,
      };
      // refresh grid upon creation
      await ReportApis.create(payload).then(() => getNewReportsList(dataExtractModule));
    }
  }, []);

  useEffect(() => {
    getReportsList(dataExtractModule);
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <NavTabs activePageName={getReportStorageName(classification, dataExtractModule)} />
        </Col>
      </Row>
      <Row>
        {createLabel === "Data Extract" && (
          <Col md="12" className="pb-2">
            <h2 className={styles.pageTitle}>{getReportStorageName(classification, dataExtractModule)}</h2>
          </Col>
        )}
      </Row>
      <Row>
        <Col className="px-0 pl-3" md={4}>
          <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} options={{}} />
          <Button
            className={`ml-3 ${styles.createButton}`}
            onClick={isInvoiceDataExtract() ? createReport : showAddForm}
          >
            {`Create New ${createLabel}`}
          </Button>
        </Col>
        <Col className="px-0" md={4}>
          <ListSectionNav sectionRoutes={getSectionRoutes(classification, dataExtractModule)} />
        </Col>
        <Col className="px-0 d-flex justify-content-end" md={4}>
          <ReactDatePicker
            wrapperClassName={styles.datePickerWrapper}
            dateFormat={"MM/yyyy"}
            showMonthYearPicker
            className="form-control"
            selected={date}
            onChange={onDateChanged}
            isClearable={true}
            placeholderText={t("selectMonthYear")}
            popperProps={{ positionFixed: true }}
          />
          <ToggleFilterButton
            classes="mt-2 ml-2"
            clickCallback={() => {
              updateShowFilters(!showFilters);
              gridApi?.refreshHeader();
            }}
          />
        </Col>
      </Row>

      <Row className={styles.gridHeight + " mt-3 px-3"}>
        <ClientDataGrid
          columnDefs={reportsGridHeaders}
          gridReady={gridReady}
          rowData={filteredReportsList ? filteredReportsList : reportsList}
          defaultColDef={{ floatingFilter: showFilters }}
          gridStorageName={getReportStorageName(classification, dataExtractModule)}
        />
      </Row>
      {addVisible && (
        <AddReportForm
          classification={classification}
          formLabel={createLabel}
          options={options}
          close={hideAddForm}
          dataExtractModule={dataExtractModule}
          initialValues={{
            export_format: "csv",
            export_destination: "download",
            recipients_original: "",
            properties: {
              mark_records_exported: false,
              filters: {
                transaction_classifications_chosen: ["All"],
                subsidiary_ids_original: [],
                include_external_id_has_data: false,
              },
            },
          }}
          refreshGrid={() => getNewReportsList(dataExtractModule)}
        />
      )}
    </Container>
  );
};

export default ListReports;
