import React from "react";
import { Field } from "redux-form";
import TooltipRenderQues from "../../../../../components/toolTip/tooltipRenderQues";
import { CompanyDefaultsTypes } from "../settingsTypes";

type CommonSettingFieldProps = {
  setting: CompanyDefaultsTypes.CommonSettingFieldType;
  onChangeObj: any;
  isShowObj: boolean;
  customPropsObj?: any;
};

const CommonSettingField = ({ setting, onChangeObj, isShowObj, customPropsObj = {} }: CommonSettingFieldProps) => {
  let customProps: any = {};
  if (setting?.customProps) {
    if (typeof setting.customProps === "function") {
      customProps = setting.customProps(customPropsObj);
    } else if (typeof setting.customProps === "object") {
      customProps = setting.customProps;
    }
  }

  const conditionalAtt = { ...customProps };
  if (setting.type && setting.type === "number") {
    // these parse value from redux-form string to number
    conditionalAtt.parse = (value: string) => Number(value);
  }

  return setting.isShow(isShowObj) ? (
    <Field
      component={setting.component}
      type={setting.type}
      name={setting.name}
      label={setting.label}
      tooltip={
        setting.tooltip.show ? (
          <TooltipRenderQues title={setting.tooltip.title} placement={setting.tooltip.placement} />
        ) : null
      }
      options={setting.options ? setting.options : []}
      required={setting.require}
      onChange={(e: any) => setting.onChangeCallBack(onChangeObj, e)}
      validate={setting.validation ? setting.validation : []}
      className={setting.class}
      isMulti={setting.isMulti ?? false}
      isClearable={setting.isClearable ?? false}
      {...conditionalAtt}
    />
  ) : null;
};

export default CommonSettingField;
