import { Col, Row } from "react-bootstrap";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import NonTransactionPushSettings from "../../components/integrationComponents/nonTransactionPushSettings";
import NonTransactionSettings from "../../components/integrationComponents/nonTransactionSettings";
import TransactionSettings from "../../components/integrationComponents/transactionSettings";
import IntegrationJobs from "../../components/integrationComponents/integrationJobs";

export const QuickbookAI2 = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>

      <Row className="m-0">
        <TransactionSettings />
      </Row>
      <Row className="m-0">
        <NonTransactionSettings />
      </Row>
      <Row className="m-0">
        <NonTransactionPushSettings />
      </Row>
      <Row className="m-0">
        <IntegrationJobs />
      </Row>
    </Col>
  );
};
