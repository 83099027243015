import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import StatePicker from "../../pickers/reduxFormPickers/statePicker";
import CountryPicker, { CountryOptionType } from "../../pickers/reduxFormPickers/countryPicker";
import { required } from "../../../../services/validations/reduxFormValidation";

const BankAddressLine = ({
  modelName,
  hideAddressLine,
  hideCountry,
  defaultCountry,
  isStateRequired,
  isCountryDisable,
  isCountryRequired,
  isRequired,
  isCheckPaymentMethod,
  countriesList,
}: {
  modelName: string;
  hideAddressLine?: boolean;
  hideCountry?: boolean;
  defaultCountry?: string;
  isCountryDisable?: boolean;
  isStateRequired?: boolean;
  isCountryRequired?: boolean;
  isRequired?: boolean;
  isCheckPaymentMethod?: boolean;
  countriesList?: CountryOptionType[];
}) => {
  const { t } = useTranslation();
  const colSize = hideCountry ? 4 : 3;

  const [selectedCountry, setSelectedCountry] = useState<string>();

  return (
    <>
      {!hideAddressLine && (
        <Col md="12">
          <Field
            name={`${modelName}.address_attributes.address1`}
            component={RenderField}
            type="text"
            label={t("common.paymentMethod.bankAddressLine")}
            required={isRequired}
            validate={isRequired ? [required] : []}
          />
        </Col>
      )}
      {!hideCountry && (
        <Col md="3">
          <Field
            name={`${modelName}.address_attributes.country`}
            component={CountryPicker}
            label={t("common.paymentMethod.country")}
            className={`w-100`}
            callBack={(selected: string) => setSelectedCountry(selected)}
            defaultValue={defaultCountry ? defaultCountry : ""}
            required={isCountryRequired || isRequired}
            validate={isCountryRequired || isRequired ? [required] : []}
            isCheckPaymentMethod={isCheckPaymentMethod}
            countriesList={countriesList}
            disabled={isCountryDisable}
          />
        </Col>
      )}
      <Col md={colSize}>
        <Field
          name={`${modelName}.address_attributes.state`}
          component={StatePicker}
          label={t("common.paymentMethod.state")}
          className={`w-100`}
          required={isStateRequired}
          validate={isStateRequired ? [required] : []}
          countryCode={selectedCountry ? selectedCountry : defaultCountry ? defaultCountry : false}
        />
      </Col>
      <Col md={colSize}>
        <Field
          name={`${modelName}.address_attributes.city`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.city")}
          required={isRequired}
          validate={isRequired ? [required] : []}
        />
      </Col>
      <Col md={colSize}>
        <Field
          name={`${modelName}.address_attributes.zipcode`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.zipcode")}
          required={isRequired}
          validate={isRequired ? [required] : []}
        />
      </Col>
    </>
  );
};

export default BankAddressLine;
