import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import i18next from "i18next";
import _ from "lodash";
import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Field, FieldArray, InjectedFormProps, change, getFormValues, reduxForm } from "redux-form";
import CustomLabelsApi from "services/admin/customLabels/customLabelsApi";
import { CustomLabelSvc } from "services/admin/customLabels/customLabelsSvc";
import UserSvc from "services/common/user/userSvc";
import { useTypedSelector } from "../../../reducers";
import { DelegationType, LocationType } from "../../../services/common/contact/contactTypes";
import { IUser } from "../../../services/common/user/userTypes";
import {
  isValidBirthday,
  maxLength,
  minLength,
  number,
  required,
} from "../../../services/validations/reduxFormValidation";
import { RenderCheckBox, RenderField, RenderFieldNumber, RenderSelect } from "../../forms/bootstrapFields";
import useConfirmModal from "../../modals/confirmModal/useConfirmModalHook";
import BusinessUnitPicker from "../../pickers/reduxFormPicker/businessUnitPicker";
import ContactPicker from "../../pickers/reduxFormPicker/contactPicker";
import PolicyPicker from "../../pickers/reduxFormPicker/policyPicker";
import SubsidiaryPicker from "../../pickers/reduxFormPicker/subsidiaryPicker";
import TooltipRender from "../../toolTip/tooltipRender";
import useAdminCompanyCurrencyCode from "../hooks/useAdminCompanyCurrencyCode";
import LocationPicker from "../pickers/reduxFormPickers/locationPicker/locationPicker";
import ProjectPicker from "../pickers/reduxFormPickers/projectPicker/projectPicker";
import RenderDepartmentLinks from "./formComponents/renderDepartmentLinks";
import RenderSubsideryLinks from "./formComponents/renderSubsidiaryLinks";

type ContactProjectLinksType = {
  id?: number;
  value?: number;
  label?: string;
  project_id?: number;
  is_default?: boolean;
  _destroy?: 1;
}[];

const maxLength4 = maxLength(4);
const minLength4 = minLength(4);

export type UserFormDataType = {
  securityVisible?: boolean;
  user: {
    id?: number | string;
    email?: string;
    is_disabled?: boolean;
    no_push?: boolean;
    force_push?: boolean;
    security_1?: string;
    security_2?: string;
    contact: {
      delegator_links_attributes?: DelegationType[];
      id?: string | string;
      first_name?: string;
      last_name?: string;
      phone?: string;
      title?: string;
      manager_id?: number;
      amount_limit?: number;
      business_unit_id?: number;
      contact_location_links_attributes?: LocationType[];
      policy_id?: number;
      contact_id?: string | number;
      department_id?: number;
      external_id?: string;
      contact_type?: string;
      subsidiary_id?: number;
      department_links?: {
        id?: number;
        value?: number;
        label?: string;
        department_id?: number;
        _destroy?: 1;
      }[];
      subsidiary_links?: {
        id?: number;
        value?: number;
        label?: string;
        subsidiary_id?: number;
        default?: boolean;
        _destroy?: 1;
      }[];
      project?: number;
      project_links?: ContactProjectLinksType;
      additional_currency_codes?: {
        code?: string;
        value?: string;
        label?: string;
        primary?: boolean;
      }[];
      currency_code?: string;
    };
  };
};

export const USER_FORM = "USER_FORM";

export type UserFormExtraPropsTypes = {
  delegationsFields?: ReactNode;
  forceSyncField?: ReactNode;
  accountDisalbledField?: ReactNode;
};
interface UserFormPropsType
  extends UserFormExtraPropsTypes,
    InjectedFormProps<UserFormDataType, UserFormExtraPropsTypes> {}

const UserFormComponent = ({
  handleSubmit,
  delegationsFields,
  forceSyncField,
  accountDisalbledField,
}: UserFormPropsType) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [disableExternalId, setDisableExternalId] = useState(true);
  const formData = useTypedSelector((state) => getFormValues(USER_FORM)(state) as UserFormDataType);
  const currentUser = useTypedSelector((state) => state.user as IUser);
  const { createConfirmModal } = useConfirmModal();
  const { companyCurrencies } = useAdminCompanyCurrencyCode();

  const onExternalIdEdit = () => {
    createConfirmModal({
      title: t("admin.pages.users.confirmEditExternalId"),
      body: t("admin.pages.users.confirmText"),
      saveCallBack: () => {
        setDisableExternalId(false);
        dispatch(change(USER_FORM, "user.contact.external_id_changed_flag", true));
      },
      callBackData: null,
      cancelCallBack: null,
    });
  };

  const [customLabels, setCustomLabels] = useState<{}>([]);
  const [loadingCustomLabels, setIsLoadingCustomLabels] = useState<boolean>(true);
  const fetchCustomLabels = async () => {
    try {
      const response = await CustomLabelsApi.index({ filter: { status: "ACTIVE", hashify: true } });
      setCustomLabels(response);
      setIsLoadingCustomLabels(false);
    } catch (error) {
      setIsLoadingCustomLabels(false);
    }
  };

  useEffect(() => {
    fetchCustomLabels();
  }, []);

  const onDefualtCurrencyChange = (value: string) => {
    if (_.isArray(formData?.user?.contact?.additional_currency_codes)) {
      const updatedCurrencyCodes = formData?.user?.contact?.additional_currency_codes?.map((currencyCode) => {
        const newCurrencyCode = { ...currencyCode, code: currencyCode.value, primary: false };
        if (currencyCode?.value === value) {
          newCurrencyCode.primary = true;
        }
        return newCurrencyCode;
      });
      dispatch(change(USER_FORM, "user.contact.additional_currency_codes", updatedCurrencyCodes));
      return value;
    }
  };

  const onChangeCurrencyCode: any = (
    currencyCodes: { value: string; label: string; name: string; symbol: string }[],
  ) => {
    if (_.isArray(currencyCodes)) {
      const updatedCurrencyCodes = currencyCodes.map((currencyCode) => {
        const newCurrencyCode = { ...currencyCode, code: currencyCode.value };
        return newCurrencyCode;
      });
      return updatedCurrencyCodes;
    }
  };

  const onChangeDefaultProject = (e: ChangeEvent<HTMLSelectElement>) => {
    if (_.isArray(formData?.user?.contact?.project_links)) {
      const updatedProjectLinks = formData?.user?.contact?.project_links?.map((projectLink: any) => {
        if (projectLink.value === Number(e.target.value)) {
          projectLink.is_default = true;
        } else {
          projectLink.is_default = false;
        }
        return projectLink;
      });
      dispatch(change(USER_FORM, "user.contact.project_links", updatedProjectLinks));
    }
  };

  /**
   * @param projectsLinks
   * this function will remove projectIds from select project as want to create link
   *
   * it will not remove id from those project with are already linked
   * if selected project is null or empty array then mark all distroyed
   *  TODO: remove this logic, it temporary should be done from project picker
   */
  const removeProjectIds = (newContactProjectLinks: ContactProjectLinksType | null) => {
    const contactProjectLinks = formData?.user?.contact?.project_links;
    let projectWithoutProjectId;
    if (
      ((_.isArray(newContactProjectLinks) && newContactProjectLinks?.length < 1) || !newContactProjectLinks) &&
      _.isArray(contactProjectLinks)
    ) {
      projectWithoutProjectId = contactProjectLinks.map((contactProjectLink) => {
        contactProjectLink._destroy = 1;
        return contactProjectLink;
      });
    } else if (_.isArray(newContactProjectLinks)) {
      projectWithoutProjectId = newContactProjectLinks.map((newContactProjectLink) => {
        const isProjectLink =
          _.isArray(contactProjectLinks) &&
          contactProjectLinks.find((contactProjectLink) => contactProjectLink.id === newContactProjectLink.id);
        if (!isProjectLink) {
          delete newContactProjectLink.id;
        }
        return newContactProjectLink;
      });
    }
    return projectWithoutProjectId ? projectWithoutProjectId : [];
  };

    /**
   * @param locationLinks
   * this function will remove locationIds from select location as want to create link
   *
   * it will not remove id from those location with are already linked
   * if selected project is null or empty array then mark all destroyed
   *  TODO: remove this logic, it temporary should be done from location picker
   */
  const removeLocationIds = (newContactLocationLinks: LocationType[] | null) => {
    const contactLocationLinks = formData?.user?.contact?.contact_location_links_attributes;
    let locationWithoutLocationId;
    if (
      ((_.isArray(newContactLocationLinks) && newContactLocationLinks?.length < 1) || !newContactLocationLinks) &&
      _.isArray(contactLocationLinks)
    ) {
      locationWithoutLocationId = contactLocationLinks.map((contactLocationLink) => {
        contactLocationLink._destroy = 1;
        return contactLocationLink;
      });
    } else if (_.isArray(newContactLocationLinks)) {
      locationWithoutLocationId = newContactLocationLinks.map((newContactLocationLink) => {
        const isLocationLink =
          _.isArray(contactLocationLinks) &&
          contactLocationLinks.find((contactLocationLink) => contactLocationLink.id === newContactLocationLink.id);
        if (!isLocationLink) {
          delete newContactLocationLink.id;
        }
        return newContactLocationLink;
      });
    }
    return locationWithoutLocationId ? locationWithoutLocationId : [];
  };

  const onChangeNoPush = () => {
    dispatch(change(USER_FORM, "user.force_push", false));
  };

  return (
    <Form noValidate name={USER_FORM} onSubmit={handleSubmit}>
      <Row>
        <Col sm="6">
          <Field
            name="user.contact.first_name"
            id="user.contact.first_name"
            label={t("admin.pages.users.firstName")}
            component={RenderField}
            required
            validate={[required]}
          />
        </Col>

        <Col sm="6">
          <Field
            name="user.contact.last_name"
            id="user.contact.last_name"
            label={t("admin.pages.users.lastName")}
            component={RenderField}
            required
            validate={[required]}
          />
        </Col>

        <Col sm="6">
          <Field
            name="user.email"
            id="user.email"
            label={t("admin.pages.users.email")}
            component={RenderField}
            required
            validate={[required]}
          />
        </Col>

        <Col sm="6">
          <Field
            name="user.contact.phone"
            id="user.contact.phone"
            label={t("admin.pages.users.phone")}
            component={RenderField}
            validate={[number]}
          />
        </Col>

        <Col sm="6">
          <Field
            name="user.contact.title"
            id="user.contact.title"
            label={t("admin.pages.users.title")}
            component={RenderField}
          />
        </Col>

        <Col sm="6">
          <Field
            name="user.contact.manager_id"
            id="user.contact.manager_id"
            label={t("admin.pages.users.employeeManager")}
            component={ContactPicker}
            contactType="USERS"
            isClearable
          />
        </Col>

        <Col sm="6">
          <Field
            name="user.contact.amount_limit"
            id="user.contact.amount_limit"
            label={t("admin.pages.users.amountApproval")}
            component={RenderFieldNumber}
          />
        </Col>

        <Col sm="6">
          <Field
            name="user.contact.business_unit_id"
            id="user.contact.business_unit_id"
            label={t("admin.pages.users.businessUnit")}
            component={BusinessUnitPicker}
          />
        </Col>

        <Col sm="6">
          <Field
            name="user.contact.contact_location_links_attributes"
            placeholder="search location"
            label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.users.location"))}
            normalize={removeLocationIds}
            component={LocationPicker}
            isMulti={true}
          />
        </Col>

        <Col sm="6">
          <Field name="user.contact.policy_id" id="user.contact.policy_id" label="Policy" component={PolicyPicker} />
        </Col>

        <Col sm="6">
          <Field
            name="user.contact.contact_id"
            id="user.contact.contact_id"
            label={t("admin.pages.users.contactId")}
            component={RenderField}
          />
        </Col>
        {UserSvc.isAdminOrInternalUser(currentUser) && formData?.securityVisible ? (
          <>
            <Col md="6">
              <Field
                id={"user.security_1"}
                name={"user.security_1"}
                component={RenderField}
                label={t("components.admin.preferencesForm.security_1")}
                maxLength={5}
                validate={[isValidBirthday]}
              />
            </Col>
            {currentUser.company?.address?.country === "USA" ? (
              <Col md="6">
                <Field
                  id={"user.security_2"}
                  name={"user.security_2"}
                  component={RenderField}
                  label={t("components.admin.preferencesForm.security_2")}
                  validate={[number, maxLength4, minLength4]}
                  maxLength={4}
                />
              </Col>
            ) : null}
          </>
        ) : null}
        <Col sm="6">
          <Field
            name="user.contact.external_id"
            label={
              <>
                {t("admin.pages.users.externalId")}
                {formData?.user?.contact?.external_id && currentUser?.company?.has_integration && (
                  <Button size="sm">
                    <TooltipRender title={t("admin.pages.users.syncOn")} className="icon-sm icon-link-white" />
                  </Button>
                )}
                {formData?.user?.contact?.external_id && !currentUser?.company?.has_integration && (
                  <Button size="sm">
                    <TooltipRender title={t("admin.pages.users.syncOff")} className="icon-sm icon-link-white" />
                  </Button>
                )}
                {disableExternalId && (
                  <Button variant="secondary" size="sm" className="mx-2" onClick={onExternalIdEdit}>
                    <i className="icon icon-edit-black m-0" />
                  </Button>
                )}
              </>
            }
            component={RenderField}
            disabled={disableExternalId}
            id="user_contact_external_id"
          />
        </Col>

        <Col sm="6">
          <FieldArray name="user.contact.department_links" component={RenderDepartmentLinks} />
        </Col>

        <Col sm="12">
          <Row>
            {currentUser.company.has_subsidiaries && (
              <Col sm="6">
                <Field
                  name="user.contact.subsidiary_id"
                  label={t("admin.pages.users.primaryContactSubsidiary")}
                  component={SubsidiaryPicker}
                  filter={{ active: true }}
                />
                <FieldArray name="user.contact.subsidiary_links" component={RenderSubsideryLinks} />
              </Col>
            )}

            <Col sm="6">
              <Field
                name="user.contact.project_links"
                label={t("admin.pages.users.project")}
                normalize={removeProjectIds}
                isMulti
                component={ProjectPicker}
              />
              {_.isArray(formData?.user?.contact?.project_links) &&
                formData?.user?.contact?.project_links.filter((project) => !project._destroy).length > 0 && (
                  <Field
                    name="user.contact.project"
                    label={t("admin.pages.users.defaultProject")}
                    options={[{}, ...formData?.user?.contact?.project_links]}
                    component={RenderSelect}
                    onChange={onChangeDefaultProject}
                  />
                )}
            </Col>
          </Row>
        </Col>

        <Col sm="6">
          <Field
            name="user.contact.additional_currency_codes"
            label={t("admin.pages.users.currencyCode")}
            currencyCodeOptions={_.isArray(companyCurrencies) ? companyCurrencies : []}
            isMulti
            component={CurrencyCodePicker}
            normalize={(data: any) => onChangeCurrencyCode(data)}
          />
          {_.isArray(formData?.user?.contact?.additional_currency_codes) &&
            formData?.user?.contact?.additional_currency_codes?.length > 0 && (
              <Field
                name="user.contact.currency_code"
                label={t("admin.pages.users.defaultCurrencyCode")}
                component={RenderSelect}
                normalize={(data: any) => onDefualtCurrencyChange(data)}
                options={[{}, ...formData?.user?.contact?.additional_currency_codes]}
              />
            )}
        </Col>
        <Col sm="12">
          <Row>
            {accountDisalbledField && <Col sm="2">{accountDisalbledField}</Col>}
            <Col sm="2">
              <Field
                name="user.no_push"
                label={t("admin.pages.users.noPush")}
                component={RenderCheckBox}
                onChange={onChangeNoPush}
                type="checkbox"
              />
            </Col>

            {forceSyncField &&
              currentUser?.company?.has_integration &&
              currentUser?.company?.integration?.sync_settings &&
              (currentUser?.company?.integration?.sync_settings?.sync_contact_employee ||
                currentUser?.company.integration?.sync_settings?.sync_contact) &&
              !formData?.user?.no_push && <Col sm="2">{forceSyncField}</Col>}
          </Row>
        </Col>
      </Row>
      {delegationsFields}

      <Row>
        <Col sm="12" className="d-flex justify-content-end">
          <Button variant="secondary" onClick={() => history.goBack()}>
            {t("cancel")}
          </Button>

          <Button type="submit" className="mx-2">
            {t("submit")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const UserForm = reduxForm<UserFormDataType, UserFormExtraPropsTypes>({
  form: USER_FORM,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  touchOnChange: true,
  touchOnBlur: false,
  validate: (formData: UserFormDataType): any => {
    const errors = {
      user: {
        contact: {
          delegator_links_attributes: [] as any[],
        },
      },
    };
    const validateEndDate = (startDate: string, endDate: string) => {
      if (startDate && endDate && new Date(endDate) <= new Date(startDate)) {
        return false;
      }
      return true;
    };

    if (_.isArray(formData?.user?.contact?.delegator_links_attributes)) {
      errors.user.contact.delegator_links_attributes = formData.user.contact?.delegator_links_attributes.map(
        (delegation: any) => {
          const isEndDateValid = validateEndDate(delegation.start_date, delegation.end_date);
          const delegationError: any = {};
          if (!isEndDateValid) {
            delegationError.end_date = i18next.t("admin.pages.users.endDateValidation");
          }

          return delegationError;
        },
      );
    }

    return errors;
  },
})(UserFormComponent);

export default UserForm;
