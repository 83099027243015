import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";

const CeTokenBased = () => {
  return (
    <>
      <Col lg="6">
        <Field
          name="form.application_id"
          label="Element ID"
          placeholder="Application ID"
          component={RenderField}
          type="text"
        />
      </Col>
      <Col lg="6">
        <Field
          name="form.element_token"
          label="Element Token"
          placeholder="Element Token"
          component={RenderField}
          type="text"
        />
      </Col>
    </>
  );
};

export default CeTokenBased;
