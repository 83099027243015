import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.css";
import Panel from "components/common/panel/panel";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formattedAmount } from "services/general/helpers";
import { useTypedSelector } from "reducers";
import { getFormValues } from "redux-form";
import ExpensesApis from "services/admin/expenses/expensesApis";
import moment from "moment";
import { IUser } from "services/common/user/userTypes";
import { getDateFormat } from "services/general/dateSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import _ from "lodash";
import { useTranslation } from "react-i18next";

type expenseDashboardFormType = {
  subsidiary_id?: number;
  expense_type?: { value: string; label: string };
  subsidiaryName?: string;
};

type expenseCategoryFormDataType = {
  card_program_id?: number;
  posted_date?: { value: string; label: string };
  posted_filter_data?: { monthName?: string; startDate: string; endDate: string; selectedOption?: string };
  posted_date_custom?: { startDate: string; endDate: string; selectedOption: string };
};

const TopTenSpendByMerchant = () => {
  const { t } = useTranslation();
  const [topSpendByMerchantData, setTopSpendByMerchantData] = useState<ExpensesTypes.TopTenMerchantSpendListType>([]);
  const expenseCategoryForm: expenseCategoryFormDataType = useTypedSelector((state) =>
    getFormValues("expenseCategoryForm")(state),
  );
  const expenseDashboardForm: expenseDashboardFormType = useTypedSelector((state) =>
    getFormValues("expenseDashboardForm")(state),
  );
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const subsidiaryId = expenseDashboardForm?.subsidiary_id;
  const expenseType = expenseDashboardForm?.expense_type;
  const subsidiaryName = expenseDashboardForm?.subsidiaryName;

  const getParams = () => {
    let paramsObj: any = {};
    if (subsidiaryId) {
      paramsObj.subsidiary_id = subsidiaryId;
    }
    if (expenseType?.value && expenseType.value !== "all") {
      paramsObj.reimbursable = expenseType?.value;
    }

    if (expenseCategoryForm?.posted_filter_data?.startDate) {
      paramsObj.date_after =
        expenseCategoryForm?.posted_filter_data?.selectedOption === "custom"
          ? moment(
              expenseCategoryForm?.posted_filter_data?.startDate,
              String(getDateFormat(currentUser).toUpperCase()),
            ).format("YYYY-MM-DD HH:mm:ss")
          : expenseCategoryForm.posted_filter_data.startDate;
    }

    if (expenseCategoryForm?.posted_filter_data?.endDate) {
      paramsObj.date_before =
        expenseCategoryForm?.posted_filter_data?.selectedOption === "custom"
          ? moment(
              expenseCategoryForm?.posted_filter_data?.endDate,
              String(getDateFormat(currentUser).toUpperCase()),
            ).format("YYYY-MM-DD HH:mm:ss")
          : expenseCategoryForm.posted_filter_data.endDate;
    }

    return paramsObj;
  };

  const getTopSpendByMerchant = async () => {
    const response: ExpensesTypes.TopTenMerchantSpendListType = await ExpensesApis.getTopTenSpendByMerchant({
      filter: getParams(),
    });
    if (_.isArray(response) && response.length > 1) {
      response.sort((a, b) => {
        return b.total_spent - a.total_spent;
      });
    }
    setTopSpendByMerchantData(response);
  };

  useEffect(() => {
    if (expenseCategoryForm?.posted_filter_data !== undefined) {
      getTopSpendByMerchant();
    }
  }, [expenseCategoryForm?.posted_filter_data, subsidiaryId, expenseType]);

  const getLinkSearchParams = (merchantSpend: any) => {
    let urlSearchParams = "?filterId=1&status=all";
    if (subsidiaryName) {
      urlSearchParams += "&subsidiaryName=" + subsidiaryName;
    }
    if (expenseType?.value && expenseType.value !== "all") {
      urlSearchParams += "&expenseType=" + expenseType.value;
    }

    if (expenseCategoryForm?.posted_filter_data?.startDate) {
      urlSearchParams +=
        "&dateAfter=" +
        (expenseCategoryForm?.posted_filter_data?.selectedOption === "custom"
          ? moment(
              expenseCategoryForm?.posted_filter_data?.startDate,
              String(getDateFormat(currentUser).toUpperCase()),
            ).format("YYYY-MM-DD HH:mm:ss")
          : expenseCategoryForm.posted_filter_data.startDate);
    }

    if (expenseCategoryForm?.posted_filter_data?.endDate) {
      urlSearchParams +=
        "&dateBefore=" +
        (expenseCategoryForm?.posted_filter_data?.selectedOption === "custom"
          ? moment(
              expenseCategoryForm?.posted_filter_data?.endDate,
              String(getDateFormat(currentUser).toUpperCase()),
            ).format("YYYY-MM-DD HH:mm:ss")
          : expenseCategoryForm.posted_filter_data.endDate);
    }

    if (merchantSpend?.merchant_name) {
      urlSearchParams += "&merchantName=" + merchantSpend?.merchant_name;
    }

    return urlSearchParams;
  };

  return (
    <div>
      <Panel
        cardClass={styles.topMerchantContainerPanelWrapper + " topMerchantContainerPanel"}
        header={
          <div>
            <i className={`icon ${styles.iconHeading} icon-green-spend px-mt-3 px-mr-3`}></i>
            {t("admin.pages.expenseItem.labels.top10SpendByMerchant")}
          </div>
        }
        cardHeaderClass={styles.expensePanelHeader}
        cardBodyClass={styles.topMerchantBody}
      >
        {topSpendByMerchantData?.length ? (
          <>
            {topSpendByMerchantData.map((merchantSpend: ExpensesTypes.MerchantSpendType, index: number) => (
              <Link
                key={index}
                to={{ pathname: "/ap/expenses", search: getLinkSearchParams(merchantSpend) }}
                className={`expenseRouteLink`}
              >
                <Row className={`${styles.topMerchantLink} ${styles.expenseSummaryItems}`}>
                  <Col sm="7" className="d-flex flex-row justify-content-start">
                    <div className={styles.topMerchantAvatar}>
                      {merchantSpend.merchant_name?.trimStart()?.charAt(0)}
                    </div>
                    <div className="d-flex flex-column ml-2 w-100">
                      <p className={styles.topMerchantName}>{merchantSpend.merchant_name}</p>
                      <p>
                        {merchantSpend.expenses_count} expense{merchantSpend.expenses_count > 1 && "s"}
                      </p>
                    </div>
                  </Col>
                  <Col sm="5" className={"text-right"}>
                    <span className={`mb-0 ml-auto ${styles.topSpendByMerchantSpend}`}>
                      {formattedAmount(merchantSpend.total_spent.toString(), merchantSpend.currency?.iso_code, 2, true)}
                    </span>
                  </Col>
                </Row>
              </Link>
            ))}
          </>
        ) : (
          <Row className="p-4 justify-content-center align-items-center h-100">
            {/* TODO:  Not confirmed for empty record */}
            No Merchant Found
          </Row>
        )}
      </Panel>
    </div>
  );
};

export default TopTenSpendByMerchant;
