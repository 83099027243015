import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import React, { createContext, Dispatch, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { QueueInvoiceDataType } from "./invoiceQueue.type";
import { createQueueInvoiceWithDetails, handleError, queueInvoicesFilter } from "./uploadQueueService";

type QueueManagementContextType = {
  selectedQueueInvoice: QueueInvoiceDataType | undefined;
  filteredInvoices: QueueInvoiceDataType[];
  setSelectedQueueInvoice: Dispatch<QueueInvoiceDataType | undefined>;
  addNewQueueInvoices: Dispatch<QueueInvoiceDataType[] | undefined>;
  refetchInvoiceList: () => void;
  updateSelectedInvoiceAsync: (id: number | undefined) => Promise<void>;
  isFetchingInvoices: boolean;
  isFetching: boolean;
  searchTerm: string;
  setSearchTerm: Dispatch<string>;
  refetchCurrentInvoiceQuery: () => void;
};

const QueueManagementContext = createContext<QueueManagementContextType | undefined>(undefined);

const QUERY_KEY = "uploadQueueGetInvoicesQuery";
const QUERY_DETAILS_KEY = "uploadQueueGetInvoiceDetailsQuery";

export const QueueManagementProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [selectedQueueInvoice, setSelectedQueueInvoice] = useState<QueueInvoiceDataType | undefined>(undefined);
  const [queueInvoices, setQueueInvoices] = useState<QueueInvoiceDataType[] | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const queryClient = useQueryClient();

  const refetchCurrentInvoiceQuery = useCallback(() => {
    queryClient.refetchQueries([QUERY_DETAILS_KEY, selectedQueueInvoice?.invoice?.id]);
  }, [queryClient, selectedQueueInvoice]);

  const filter = useMemo(() => queueInvoicesFilter, []);

  const filteredInvoices = useMemo(() => {
    if (!queueInvoices) return [];
    return queueInvoices.filter((invoice) => invoice.label.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [queueInvoices, searchTerm]);

  // *** Fetch Invoices ***
  const selectUploadQueueData = useCallback((uploadQueueResponseData) => {
    const invoiceList = uploadQueueResponseData?.data || [];

    const queueInvoiceList: QueueInvoiceDataType[] = invoiceList.map((invoice: any) => ({
      invoice: { id: invoice.id },
      label: invoice.number?.toString() ?? "",
      isLocal: false,
      filename: invoice.filename ?? "",
    }));

    return {
      queueInvoiceList,
      firstQueueInvoice: queueInvoiceList[0] || undefined,
    };
  }, []);

  const {
    data: { queueInvoiceList, firstQueueInvoice } = {},
    error: invoiceListError,
    isFetching: isFetchingInvoices,
    refetch: refetchInvoiceList,
  } = useQuery([QUERY_KEY, filter], () => InvoiceApis.getInvoiceList({ filter }), {
    select: selectUploadQueueData,
  });

  useEffect(() => {
    setQueueInvoices(queueInvoiceList);
    setSelectedQueueInvoice(firstQueueInvoice);
  }, [queueInvoiceList, firstQueueInvoice]);

  useEffect(() => {
    if (invoiceListError) {
      handleError(invoiceListError, t);
    }
  }, [invoiceListError, t]);

  // *** Fetch Invoice Details ***
  const fetchInvoiceDetails = useCallback(async (id: number): Promise<InvoiceType.InvoiceDetailType> => {
    const invoice = await InvoiceApis.getInvoice(id);
    const response = await InvoiceApis.getInvoiceItems(id.toString());
    invoice.invoice_items_attributes =
      _.isArray(response.invoice_items) && response.invoice_items.length > 0 ? response.invoice_items : [];
    invoice.debit_entries_attributes = invoice.debit_entries;
    invoice.credit_entries_attributes = invoice.credit_entries;

    if (_.isArray(invoice.credit_entries_attributes) && invoice.credit_entries_attributes.length > 0) {
      invoice.credit_entries_attributes.forEach((creditEntry) => {
        if (invoice?.amount) {
          creditEntry.percent = Math.round((creditEntry.amount / invoice.amount) * 100);
        }
      });
    }

    return invoice;
  }, []);

  const updateSelectedInvoiceAsync = useCallback(
    async (id: number | undefined) => {
      if (!id) return;

      const details = await fetchInvoiceDetails(id);
      if (!details) return;

      setSelectedQueueInvoice((prevSelected) => {
        if (!prevSelected || prevSelected.invoice.id !== id) return prevSelected;
        const updatedInvoice = createQueueInvoiceWithDetails(prevSelected, details);
        if (!_.isEqual(prevSelected, updatedInvoice)) {
          return updatedInvoice;
        }
        return prevSelected;
      });
    },
    [fetchInvoiceDetails],
  );

  const {
    data: invoiceDetails,
    isFetching: isFetchingInvoiceDetails,
    error: detailsError,
  } = useQuery(
    [QUERY_DETAILS_KEY, selectedQueueInvoice?.invoice?.id],
    () => fetchInvoiceDetails(selectedQueueInvoice?.invoice?.id!),
    {
      enabled: !!selectedQueueInvoice?.invoice?.id,
    },
  );

  useEffect(() => {
    if (invoiceDetails && selectedQueueInvoice && selectedQueueInvoice?.invoice?.id === invoiceDetails.id) {
      const updatedInvoice = createQueueInvoiceWithDetails(selectedQueueInvoice, invoiceDetails);

      if (!_.isEqual(selectedQueueInvoice, updatedInvoice)) {
        setSelectedQueueInvoice(updatedInvoice);
      }
    }
  }, [invoiceDetails, selectedQueueInvoice]);

  useEffect(() => {
    if (detailsError) {
      handleError(detailsError, t);
    }
  }, [detailsError, t]);

  const addNewQueueInvoices = useCallback((newInvoices: QueueInvoiceDataType[] | undefined) => {
    setSelectedQueueInvoice(undefined);
    setQueueInvoices((prevQueueInvoices = []) => [...(newInvoices || []), ...prevQueueInvoices]);
  }, []);

  const isFetching = isFetchingInvoices || isFetchingInvoiceDetails;

  const contextValue = useMemo(
    () => ({
      selectedQueueInvoice,
      filteredInvoices,
      setSelectedQueueInvoice,
      addNewQueueInvoices,
      refetchInvoiceList,
      updateSelectedInvoiceAsync,
      isFetching,
      searchTerm,
      setSearchTerm,
      isFetchingInvoices,
      refetchCurrentInvoiceQuery,
    }),
    [
      addNewQueueInvoices,
      isFetching,
      isFetchingInvoices,
      filteredInvoices,
      refetchInvoiceList,
      searchTerm,
      selectedQueueInvoice,
      updateSelectedInvoiceAsync,
      refetchCurrentInvoiceQuery,
    ],
  );

  return <QueueManagementContext.Provider value={contextValue}>{children}</QueueManagementContext.Provider>;
};

export const useQueueManagementContext = (): QueueManagementContextType => {
  const context = useContext(QueueManagementContext);
  if (!context) {
    throw new Error("useQueueManagementContext must be used within a QueueManagementProvider");
  }
  return context;
};
