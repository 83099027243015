import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import AmortizationTemplate from "../amortizationTemplate";
import DatePicker from "../datePicker/datePicker";
import { required } from "services/validations/reduxFormValidation";

type AmortizationSchedulePropsType = {
  id?: string;
  amortizationId?: string;
  amortizationStartDate?: string;
  amortizationEndDate?: string;
  amortizationScheduleName?: any;
  startDate?: string;
  endDate?: string;
};

const AmortizationSchedule = ({
  id,
  amortizationId,
  amortizationStartDate,
  amortizationEndDate,
  amortizationScheduleName,
  startDate,
  endDate,
}: AmortizationSchedulePropsType) => {
  const isTemplateRequired = startDate || endDate ? true : false;
  return (
    <Row>
      <Col>
        <Field
          name={amortizationId}
          label="Amortization Template"
          component={AmortizationTemplate}
          required={isTemplateRequired}
          validate={isTemplateRequired ? [required] : null}
        />
      </Col>
      {amortizationScheduleName && (
        <Col>
          <label>Amortization Schedule</label>
          <span>{amortizationScheduleName}</span>
        </Col>
      )}
      <Col>
        <Field
          id="amortizationStartDate"
          name={amortizationStartDate}
          label="Amortization Start Date"
          component={DatePicker}
          maxDate={endDate}
        />
      </Col>
      <Col>
        <Field
          id="amortizationEndDate"
          name={amortizationEndDate}
          label="Amortization End Date"
          component={DatePicker}
          minDate={startDate}
        />
      </Col>
    </Row>
  );
};

export default AmortizationSchedule;
