import React, { useCallback } from "react";
import { currencySymbolRenderer } from "../../../../services/common/currencySymbolRendererService";
import { translate } from "../../../../services/general/translation";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import useConfirmModal from "../../../modals/confirmModal/useConfirmModalHook";
import { PoChangeOrderType } from "../../../../services/admin/changeOrders/poChangeOrderApprovalsType";
import ChangeOrderApis from "../../../../services/admin/changeOrders/changeOrderApis";
import { companyDateFormat, dateStringWithoutTimezone } from "services/general/dateSvc";
import { Can } from "services/authorization/authorization";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ColDef } from "ag-grid-community";

const getGrossAmount = (params: any) => {
  return currencySymbolRenderer(params?.data?.currency_code) + params?.data?.amount;
};

const Actions = ({
  data,
  getPoChangeOrderList,
}: {
  data: PoChangeOrderType.DetailType;
  getPoChangeOrderList: () => void;
}) => {
  const { createConfirmModal } = useConfirmModal();
  const { t } = useTranslation();
  const isPending = data?.status === "PENDING";

  const deleteConfirmCallBack = async () => {
    try {
      await ChangeOrderApis.delete(data.id as number);
      getPoChangeOrderList();
      CreateNotification(t("success"), `Deleted Change Order ${data.number} successfully`, NotificationType.success);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteChangeOrder = useCallback(async () => {
    createConfirmModal({
      title: "Confirm",
      body: `Are you sure about deleting the "${data.number}" change order?`,
      saveCallBack: deleteConfirmCallBack,
      cancelCallBack: null,
      callBackData: data,
    });
  }, []);

  return (
    <>
      {isPending && (
        <Can I="read" a="PurchaseOrders">
          <Link to={"/ap/po_co_detail/" + data.id}>
            <button type="button" className="actionButtons view" data-toggle="tooltip" title="View"></button>
          </Link>
        </Can>
      )}

      {isPending && (
        <Can I="add" a="PurchaseOrders">
          <Link to={`/ap/change_orders/${data?.id}/edit`}>
            <button type="button" className="actionButtons icon-edit" data-toggle="tooltip" title="View"></button>
          </Link>
        </Can>
      )}

      {isPending && (
        <Can I="delete" a="PurchaseOrders">
          <button
            type="button"
            className="actionButtons delete mt-1"
            data-toggle="tooltip"
            title="Delete"
            onClick={deleteChangeOrder}
          ></button>
        </Can>
      )}
    </>
  );
};

export const getPoChangeOrderHeaders = ({
  getPoChangeOrderList,
  currentUser,
}: {
  getPoChangeOrderList: () => void;
  currentUser: any;
}): ColDef[] => {
  return [
    {
      field: "id",
      headerName: "ID",
      filter: "agTextColumnFilter",
      headerValueGetter: function () {
        return translate("id");
      },
      cellRenderer: (params: any) => {
        const isPending = params?.data?.status === "PENDING";
        if (isPending)
          return (
            <Link className="link" to={"/ap/po_co_detail/" + params?.data?.id}>
              {params?.data?.id}
            </Link>
          );
        return params?.data?.id;
      },
    },
    {
      field: "number",
      headerName: "Number",
      filter: "agTextColumnFilter",
      headerValueGetter: function () {
        return translate("number");
      },
    },
    {
      field: "vendor.name",
      headerName: "Vendor",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("vendor");
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
          const dateAsString = cellValue;
          if (dateAsString == null) return -1;

          const [datePart] = dateAsString.split("T");
          if (datePart) {
            const [year, month, day] = datePart.split("-");

            const cellDateValue = `${month}/${day}/${year}`;
            const filterDateValue = dateStringWithoutTimezone(filterLocalDateAtMidnight);

            if (cellDateValue === filterDateValue) {
              return 0;
            } else {
              return -1;
            }
          } else {
            return -1;
          }
        },
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("created_at");
      },
    },
    {
      headerName: "Gross Amount",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("gross_amount");
      },
      valueGetter: getGrossAmount,
    },
    {
      field: "requestor.name",
      headerName: "Requestor",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("requestor");
      },
    },
    {
      field: "budget_label",
      headerName: "Budget",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("budget");
      },
    },
    {
      field: "status",
      headerName: "Status",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("status");
      },
    },
    {
      field: "Actions",
      cellRenderer: (params: any) => {
        return <Actions data={params?.data} getPoChangeOrderList={getPoChangeOrderList} />;
      },
      sortable: false,
      resizable: false,
      maxWidth: 200,
      // cellRendererSelector: function(params: { data: { id: number } }) {
      //   return params.data && !params.data.id;
      // },
      headerValueGetter: function () {
        return translate("actions");
      },
    },
  ];
};
