import { AxiosResponse } from "axios";
import AddressPicker from "components/admin/pickers/reactHookFormPickers/addressPicker";
import BusinessUnitPicker from "components/admin/pickers/reactHookFormPickers/businessUnitPicker";
import CurrencyCodePicker from "components/admin/pickers/reactHookFormPickers/currencyCodePicker";
import CustomNotesPicker from "components/admin/pickers/reactHookFormPickers/customNotesPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import FiscalPeriodPicker from "components/admin/pickers/reactHookFormPickers/fiscalPeriodPicker";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import SubsidiaryPicker from "components/admin/pickers/reactHookFormPickers/subsidiaryPicker";
import VendorPicker from "components/admin/pickers/reactHookFormPickers/vendorPicker";
import { VendorPickerType } from "components/admin/pickers/reactHookFormPickers/vendorPicker/vendorPickerTypes";
import { DatePickerField, InputField, Select, TextAreaField } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import { IUser } from "services/common/user/userTypes";
import styles from "./invoiceHeaderLineSection.module.css";
import { useQueueManagementContext } from "./queueManagementContext";
import ManagePurchaseOrder from "./subSections/managePurchaseOrder";
import UploadQueueAmount from "./subSections/uploadQueueAmount";
import UploadQueueDate from "./subSections/uploadQueueDate";
import UploadQueuePaymentTerm from "./subSections/uploadQueuePaymentTerm";
import { useUiStateContext } from "./uiStateContext";

const InvoiceHeaderLineSection = () => {
  const { getValues, setValue, trigger } = useFormContext<InvoiceType.InvoiceDetailType>();
  const { selectedQueueInvoice, isFetching } = useQueueManagementContext();
  const { isOperationRunning } = useUiStateContext();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const watchedVendor = useWatch({ name: "vendor" });
  const invoiceCommonHookSvc = useInvoiceCommonSvc();
  const { getCustomLabel } = useCustomLabel();

  const shouldBlur = isOperationRunning || isFetching || (selectedQueueInvoice && selectedQueueInvoice.isBeingOcred);

  const invoiceFormClass = shouldBlur ? styles.formBlur : styles.form;

  const invoice = getValues();

  const statusOptions = useMemo(
    () => [
      { label: "New", value: "NEW" },
      { label: "Pending", value: "PENDING" },
      { label: "Open", value: "OPEN" },
    ],
    [],
  );

  const handleVendorChange = async (selectedVendor: VendorPickerType.TVendorOptions) => {
    try {
      const response: AxiosResponse = await restApiService.get("vendors/" + selectedVendor.id);
      const responseId = response?.data?.id;
      if (responseId) {
        const vendor = response.data;

        if (vendor?.term) {
          setValue("term", vendor.term);
          setValue("term_id", vendor.term?.id);
        }

        if (vendor?.vendor_id) {
          let updatedVendor = {
            ...selectedVendor,
            vendor_id: vendor.vendor_id,
            po_not_required: vendor.po_not_required,
            is_1099_eligible: vendor.is_1099_eligible,
            has_vendor_locations: vendor.has_vendor_locations,
          };
          setValue("vendor", updatedVendor);
          setValue("vendorVendorId", updatedVendor.vendor_id);
          setValue("vendor.vendor_id", updatedVendor.vendor_id);
        }

        const vendorsSubsidiary = _.find(vendor.subsidiaries, { id: invoice.subsidiary_id });
        if (!vendorsSubsidiary) {
          setValue("subsidiary", vendor.subsidiary);
          setValue("subsidiary_id", vendor.subsidiary?.id);
        }

        // change to update due date and discount date as per term from vendor
        invoiceCommonHookSvc.updateDueDate();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  // watch for changes for service_start_date < service_end_date validation
  const serviceStartDate = useWatch({ name: "service_start_date" });

  const isServiceEndDateValid = () => {
    const serviceEndDate = getValues("service_end_date");
    if (serviceStartDate && serviceEndDate) {
      const formattedServiceStartDate = new Date(serviceStartDate).toISOString();
      const formattedServiceEndDate = new Date(serviceEndDate).toISOString();
      return formattedServiceEndDate > formattedServiceStartDate
        ? true
        : "Service End Date should be greater than Service Start Date";
    } else {
      return true;
    }
  };

  useEffect(() => {
    // trigger validation for service_end_date field when service_start_date changes
    trigger("service_end_date");
  }, [serviceStartDate, trigger]);

  return (
    <Row className={invoiceFormClass}>
      <Col md="6" className="mb-3">
        <InputField id="invoice_number" name="number" label={getCustomLabel("admin.pages.invoice.number")} required />
      </Col>
      <Col md="6" className="mb-3">
        <Select
          id="invoice_status"
          name="status"
          label={getCustomLabel("admin.pages.invoice.status")}
          options={statusOptions}
        />
      </Col>
      {currentUser.company.has_subsidiaries && (
        <Col md="6" className="mb-3">
          <SubsidiaryPicker name="subsidiary_id" required label={"admin.pages.invoice.subsidiary"} />
        </Col>
      )}
      <UploadQueueDate />
      <Col md="12" className="mb-3">
        <>
          <div className="mb-3">
            <VendorPicker
              name="vendor"
              required
              label={getCustomLabel("admin.pages.invoice.vendor")}
              excludeStatuses="INACTIVE"
              callBack={(selectedVendor) => handleVendorChange(selectedVendor)}
            />
          </div>
          {watchedVendor?.vendor_id && !currentUser.company.invoice?.hide_vendor_address && (
            <AddressPicker
              name="address"
              addressableType="Vendor"
              modelName="invoice"
              label={getCustomLabel("admin.pages.invoice.vendorAddress")}
            />
          )}
        </>
      </Col>
      {currentUser.company.invoice_show_vendor_id && watchedVendor?.vendor_id && (
        <Col md="12" className="mb-3">
          <InputField
            id="invoice_vendor_vendor_id"
            name="vendor.vendor_id"
            label={getCustomLabel("admin.pages.vendor.vendorId")}
            disabled
          />
        </Col>
      )}
      <Col md="12">
        <ManagePurchaseOrder hideMultipleVendorExpensesCorpCardCheckbox={true} />
      </Col>
      {currentUser.company.invoice_show_vendor_id && invoice?.default_payment_method && (
        <Col md="6" className="mb-3">
          <InputField
            id="vendor_payment_method_type"
            name="default_payment_method_type_payment_type"
            label={getCustomLabel("admin.pages.invoice.default_payment_method_type_payment_type")}
            disabled
          />
        </Col>
      )}
      <UploadQueueAmount />
      {!currentUser.company.invoice_hide_discount && (
        <Col md="6" className="mb-3">
          <InputField
            id="invoice_discount_amount"
            name="amount_disc"
            label={getCustomLabel("admin.pages.invoice.discountAmount")}
            type="number"
          />
        </Col>
      )}
      {currentUser.company.has_locations && !currentUser.company.invoice_hide_location && (
        <Col md="6" className="mb-3">
          <LocationPicker
            name="location"
            label={getCustomLabel("admin.pages.invoice.location")}
            required={currentUser.company.invoice?.is_required_location}
          />
        </Col>
      )}
      {currentUser.company.has_business_units && !currentUser.company?.invoice?.hide_business_unit && (
        <Col md="6" className="mb-3">
          <BusinessUnitPicker
            name="business_unit"
            label={getCustomLabel("admin.pages.invoice.businessUnit")}
            disabled={currentUser.company.readonly_business_unit_to_all}
          />
        </Col>
      )}
      {currentUser.company?.has_departments && !currentUser.company?.invoice_hide_department && (
        <Col md="6" className="mb-3">
          <DepartmentPicker
            name="department_id"
            required={currentUser?.company?.invoice_department_required}
            label={getCustomLabel("admin.pages.invoice.department")}
          />
        </Col>
      )}
      {currentUser.company.has_custom_notes && (
        <Col md="6" className="mb-3">
          <CustomNotesPicker name="custom_note" label={getCustomLabel("admin.pages.invoice.customNote")} />
        </Col>
      )}
      <UploadQueuePaymentTerm />
      <Col md="6" className="mb-3">
        <CurrencyCodePicker name="currency_code" label={"admin.pages.invoice.currencyCode"} showSymbol />
      </Col>
      {/* TODO: Temporary commenting, will add fix later  */}
      {/* <Col md="6" className="mb-3">
        <BillDistributionSchedulePicker
          name="bill_distribution_schedule"
          bill_distribution_schedule_id={invoice.bill_distribution_schedule_id!}
          control={control}
        />
      </Col>
      {currentUser.company.has_posting_period && (
        <Col md="6" className="mb-3">
          <PostingPeriodPicker name="posting_period" />
        </Col>
      )}
      */}
      <Col md="6" className="mb-3">
        <DatePickerField
          id="invoice_due_date"
          name="due_date"
          label={getCustomLabel("admin.pages.invoice.dueDate")}
          required
        />
      </Col>
      <Col md="6" className="mb-3">
        <DatePickerField
          id="invoice_submit_date"
          name="submit_date"
          label={getCustomLabel("admin.pages.invoice.submitDate")}
        />
      </Col>
      {!currentUser.company.invoice_hide_discount && (
        <Col md="6" className="mb-3">
          <DatePickerField
            id="invoice_discount_expire_date"
            name="amount_disc_date"
            label={getCustomLabel("admin.pages.invoice.discountExpireDate")}
          />
        </Col>
      )}
      {!currentUser.company?.invoice_hide_service_start_date && (
        <>
          <Col md="6" className="mb-3">
            <DatePickerField
              id="invoice_start_date"
              name="service_start_date"
              label={getCustomLabel("admin.pages.invoice.serviceStartDate")}
              required={currentUser.company.invoice_service_start_date_required}
            />
          </Col>
          <Col md="6" className="mb-3">
            <DatePickerField
              id="invoice_end_date"
              name="service_end_date"
              label={getCustomLabel("admin.pages.invoice.serviceEndDate")}
              required={currentUser.company.invoice_service_end_date_required}
              validate={true}
              validationFunction={isServiceEndDateValid}
            />
          </Col>
        </>
      )}
      {!currentUser.company.invoice_hide_reference_number && (
        <Col md="6" className="mb-3">
          <InputField
            id="invoice_reference_number"
            name="reference_number"
            label={getCustomLabel("admin.pages.invoice.referenceNumber")}
            required={currentUser.company.invoice?.is_required_reference_number}
          />
        </Col>
      )}
      {currentUser.company.invoice?.show_posted_date && (
        <Col md="6" className="mb-3">
          <DatePickerField
            id="invoice_posted_date"
            name="posted_date"
            label={getCustomLabel("admin.pages.invoice.postedDate")}
            required
          />
        </Col>
      )}
      {currentUser.company.invoice?.show_fiscal_period && (
        <Col md="6" className="mb-3">
          <FiscalPeriodPicker
            name="fiscal_period"
            label={getCustomLabel("admin.pages.invoice.fiscalPeriod")}
            required={currentUser?.company?.invoice?.is_required_fiscal_period}
          />
        </Col>
      )}
      {currentUser.company.invoice.show_customer_account_number && (
        <Col md="6" className="mb-3">
          <InputField
            id="customer_account_number"
            name="customer_account_number"
            label={getCustomLabel("admin.pages.invoice.customerAccountNumber")}
          />
        </Col>
      )}
      <Col md="6" className="mb-3">
        <TextAreaField
          id="invoice_description"
          name="description"
          rows={2}
          label={getCustomLabel("admin.pages.invoice.description")}
          required={currentUser.company.invoice?.is_required_description}
        />
      </Col>
      {/* TODO-remove after development done 
      <HookFormDevHelperTools control={control}/>
      */}
    </Row>
  );
};

export default InvoiceHeaderLineSection;
