import CustomerDetails from "./details";
import CustomersList from "./list";

export const customersRoutes = [
  {
    path: "/ar/purchasers",
    exact: true,
    name: "Customers",
    component: CustomersList,
    // authorization: {
    //   I: "",
    //   a: "",
    // },
  },
  {
    path: "/ar/purchasers/:id",
    exact: true,
    name: "CustomerDetail",
    component: CustomerDetails,
    // authorization: {
    //   I: "",
    //   a: "",
    // },
  },
];
