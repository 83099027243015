import React from "react";
import { Field } from "redux-form";
import { Row, Col } from "react-bootstrap";
import { RenderSelect } from "components/forms/bootstrapFields";
import { required } from "../../../../../services/validations/reduxFormValidation";

const DropdownFilter = ({
  name,
  code,
  options,
  isRequired,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  isRequired?: boolean;
}) => {
  const shouldRequire = () => {
    if (isRequired) {
      return [required];
    } else {
      return undefined;
    }
  };

  return (
    <Row className="mx-0">
      <Col className="px-0">
        <Field
          name={`properties.filters.${code}`}
          options={options}
          label={name}
          labelClassName={`font-weight-bold`}
          placeholder="Select one"
          component={RenderSelect}
          validate={shouldRequire()}
        />
      </Col>
    </Row>
  );
};

export default DropdownFilter;
