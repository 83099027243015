import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Panel from "../../../common/panel/panel";
import InvoiceSubmit from "../../../buttons/invoice_submit";
import "./dashboard.style.css";
import { ActionableItemsDataType, DashboardSummary, ProfileDataType } from "../../../../pages/vp/dashboard/types";
import { useTranslation } from "react-i18next";
import InvoiceProgressBar from "../../../charts/invoiceProgressBar";
import { useDispatch } from "react-redux";
import { setShowGuide } from "../../../../reducers/vp/vendorSetUpGuideReducer";
import { Link } from "react-router-dom";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

type ContentPropsTypes = {
  content: DashboardSummary;
};

type ActionableItemsPropsTypes = {
  content: ActionableItemsDataType[];
};

type ProfileContentTypes = {
  bank_info: boolean;
  company_detail: boolean;
  contact_info: boolean;
  has_w8ben: boolean;
  has_w9: boolean;
  required_documents_completed: boolean;
};

type ProfilePropsTypes = {
  content: ProfileContentTypes;
  profileData: ProfileDataType[];
  setupProgressLoaded: boolean;
  isPurchasersLoaded: boolean;
  isLinkingRequired: boolean;
};

const Profile = ({
  content,
  profileData,
  setupProgressLoaded,
  isPurchasersLoaded,
  isLinkingRequired,
}: ProfilePropsTypes) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const calculateProfileCompletenessPercentage = ({
    bank_info,
    company_detail,
    contact_info,
    required_documents_completed,
  }: ProfileContentTypes) => {
    let percent = 0;
    if (bank_info && !isLinkingRequired) percent += 25;
    if (company_detail) percent += 25;
    if (required_documents_completed) percent += 25;
    if (contact_info) percent += 25;
    return percent;
  };

  const profileCompletenessPercentage = calculateProfileCompletenessPercentage(content);

  const onCompleteProfileClicked = () => {
    dispatch(setShowGuide(true));
  };

  return (
    <Col lg="9">
      {setupProgressLoaded && isPurchasersLoaded && (
        <div className={"DashboardProfile"}>
          {content && (
            <Card className={"h-100 panelContainer"}>
              <Card.Header>
                <Row>
                  <Col xl="12" className="d-flex justify-content-between align-items-center">
                    <div className="panelTitle">
                      {t("dashboardPage.profileCompleteness", {
                        percentage: setupProgressLoaded ? profileCompletenessPercentage : null,
                      })}
                    </div>
                    {profileCompletenessPercentage !== 100 && (
                      <Button onClick={() => onCompleteProfileClicked()}>Complete Profile</Button>
                    )}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  {profileData.map((item, index) => (
                    <Col sm="6" lg="3" key={index}>
                      <div className={"d-flex flex-row justify-content-center"}>
                        {item.condition ? (
                          <AiOutlineCheckCircle className="dashboardPageCheckCircle"></AiOutlineCheckCircle>
                        ) : (
                          <AiOutlineCloseCircle className="profileCompletenessChecks text-danger"></AiOutlineCloseCircle>
                        )}
                        <Link to={item.link} className={"m-0 pl-2 text-secondary"}>
                          {t(item.label)}
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          )}
        </div>
      )}
    </Col>
  );
};

const Invoice = () => {
  return (
    <Col lg="3">
      <Panel>
        <div className={"h-100 InvoiceSubmit"}>
          <InvoiceSubmit />
        </div>
      </Panel>
    </Col>
  );
};

const InvoiceAging = ({ content }: ContentPropsTypes) => {
  const { t } = useTranslation();
  return (
    <Col>
      <Panel header={<div className="panelTitle">{"Invoice Aging"}</div>}>
        <div className="d-flex">
          <div className="p-2 d-flex align-items-end mb-2">
            <div className="dashboardPageAllCustomersTitle">{t("dashboardPage.allCustomers")}</div>
          </div>
          <div className="ml-auto p-2 d-flex justify-content-start">
            <p className={"m-0"}>
              {t("dashboardPage.totalDue")}:{" "}
              <span className="invoiceAgingTotalDueLabel">{content.overdue_total_amount}</span>{" "}
            </p>
          </div>
        </div>
        <InvoiceProgressBar ProgressBarObj={content} />
      </Panel>
    </Col>
  );
};

const NeedHelp = () => {
  const { t } = useTranslation();
  return (
    <Col md="5" className={"mt-sm-4 mt-md-0"}>
      <Panel header={<div className="panelTitle">{t("dashboardPage.needHelp")}</div>}>
        <Row className="mt-4">
          <Col xs={12} className="text-center mt-2 mt-sm-4">
            <a
              href="https://accrualify.zendesk.com/hc/en-us/categories/115000590547-Vendor-Portal"
              target="_blank"
              rel="noopener noreferrer"
              title="Refer Knowledge Base Articles"
            >
              <h4 className="primaryLinkColor">{t("dashboardPage.knowledgeLink")}</h4>
            </a>
          </Col>
          <Col xs={12} className="text-center">
            <a
              href="https://accrualify.zendesk.com/hc/en-us/requests/new"
              target="_blank"
              rel="noopener noreferrer"
              title="Contact Support Team"
            >
              <h4 className="primaryLinkColor">{t("dashboardPage.supportLink")}</h4>
            </a>
          </Col>
        </Row>
      </Panel>
    </Col>
  );
};

const AvgPayment = ({ content }: ContentPropsTypes) => {
  const { t } = useTranslation();
  return (
    <Col md="4" className={"mt-sm-4 mt-md-0"}>
      <Panel header={<div className="panelTitle">{t("dashboardPage.avgPaymentLength")}</div>}>
        <div className={"text-center h-100"}>
          <div className={"dashboardAvgPaymentLengthCount mb-0"}>{content.avg_payment_length}</div>
          <div className={"dashboardAvgPaymentLengthTxt mb-0"}>{t("dashboardPage.daysOnAverage")}</div>
        </div>
      </Panel>
    </Col>
  );
};

const Upgrade = () => {
  return (
    <Col md="3">
      <Panel header={<div className="panelTitle">Upgrade</div>}>
        <h5 className={"text-center"}>
          Access more insights and better manage your accounts receivable by upgrading today.
        </h5>
        <div className={"d-flex justify-content-center"}>
          <button type="button" className="btn btn-primary px-4 py-3 mt-4">
            <div className={"upgradeBtn text-white mb-0"}>Upgrade Now</div>
          </button>
        </div>
      </Panel>
    </Col>
  );
};

const ActionableItems = ({ content }: ActionableItemsPropsTypes) => {
  return (
    <Col md="5" className={"mt-sm-4"}>
      <Panel header={<div className="panelTitle">Actionable Items</div>}>
        {content.map((data, index) => (
          <div
            key={data.item}
            className={"d-flex align-items-center pl-2 pr-4 border-bottom border-white"}
            style={{ background: data.background, height: 54 }}
          >
            <p className="m-0" style={{ color: data.fontColor }}>
              {data.item}
            </p>
            <p
              className="mb-0 ml-auto badge badge-pill text-light actionableItemsbadgePill"
              style={{ background: data.fontColor }}
            >
              {data.weight}
            </p>
          </div>
        ))}
      </Panel>
    </Col>
  );
};

// Intentionally Comment out, not using anywhere but keep to for reference.
/* const Paid = content => {
    return (
      <Col md="5" className={"mt-sm-4 mt-md-0"}>
        <Panel header={<div className="panelTitle">Paid</div>}>
          {content.map((data, index) => (
            <div className={"row"}>
              <Col xs={7} md={6}>
                <p>{data.Customers}</p>
              </Col>
              <Col xs={5} md={6}>
                <OverlayTrigger overlay={<Tooltip>${data.Income}K</Tooltip>}>
                  <ProgressBar
                    children={""}
                    style={{ height: "25px" }}
                    className={"rounded-0 bg-transparent"}
                    variant="success ml-auto"
                    now={data.Income}
                    label={`$${data.Income}K`}
                  />
                </OverlayTrigger>
              </Col>
              {<div className={"line"}><LineChart/></div>}
            </div>
          ))}
        </Panel>
      </Col>
    );
};
const PaymentTrendsChart = () => {
    return (
      <Col md="7">
        <Panel>
            <LineChart/>
        </Panel>
      </Col>
    );
};
const ChecksInTransit = content => {
    <Col>
      <Panel header={<div className="panelTitle">Checks in Transit</div>}>
        <Table striped bordered hover>
            <thead>
                <tr>
                <th>Check #</th>
                <th>Issue Date</th>
                <th>Amount</th>
                <th>Invoice(s)</th>
                <th>Customer</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {content.map((data, index) => (
                <tr>
                    <td>{data.Check}</td>
                    <td>{data.IssueDate}</td>
                    <td>{data.Amount}</td>
                    <td>{data.Invoice}</td>
                    <td>{data.Customer}</td>
                    <td></td>
                </tr>
                ))}
            </tbody>
            </Table>
      </Panel>
    </Col>
*/
export { Profile, Invoice, InvoiceAging, NeedHelp, AvgPayment, Upgrade, ActionableItems };
