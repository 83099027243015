import React, { useState } from "react";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { required } from "../../../forms/inputFields";
import { useTranslation } from "react-i18next";
import { PaymentMethodValidatorSvc } from "../paymentMethodValidatorSvc";

const BicValidator = ({
  modelName,
  ibanNumber,
  label,
  noValidate,
  notRequired,
}: {
  modelName?: string;
  ibanNumber?: string | number;
  label?: string;
  noValidate?: boolean;
  notRequired?: boolean;
}) => {
  const [validationMessage, setValidationMessage] = useState("");
  const { t } = useTranslation();
  const isFieldRequired = notRequired ? false : true;

  const validateBic = async (value: string | number) => {
    if (value && !noValidate) {
      try {
        const result = await PaymentMethodValidatorSvc.ValidateBicCode(value, ibanNumber);
        if (result.validation) {
          setValidationMessage(result.validation);
        } else {
          setValidationMessage(result.errors[0]?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Field
        name={`${modelName}.bic`}
        component={RenderField}
        type="text"
        required={isFieldRequired}
        validate={isFieldRequired ? [required] : []}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateBic(e.target.value)}
        tooltip={t("common.paymentMethod.bankCodeTooltip")}
        label={label ? label : t("common.paymentMethod.bankCode/Bic")}
      />
      {validationMessage && (
        <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
      )}
    </>
  );
};
export default BicValidator;
