import useAdminCompanyCurrencyCode from "components/admin/hooks/useAdminCompanyCurrencyCode";
import AccountPicker, { AccountObjType } from "components/admin/pickers/reduxFormPickers/accountPicker";
import AmortizationSchedule from "components/admin/pickers/reduxFormPickers/amortizationSchedule";
import BudgetPicker from "components/admin/pickers/reduxFormPickers/budgetPicker";
import BusinessUnitPicker from "components/admin/pickers/reduxFormPickers/businessUnitPicker";
import CategoryPicker, { CategoriesObjType } from "components/admin/pickers/reduxFormPickers/categoryPicker";
import ContactPicker from "components/admin/pickers/reduxFormPickers/contactPicker";
import CustomDataListPicker from "components/admin/pickers/reduxFormPickers/customDataListPicker";
import CustomFieldPicker from "components/admin/pickers/reduxFormPickers/customFieldPicker";
import DepartmentPicker from "components/admin/pickers/reduxFormPickers/departmentPicker";
import EventCodePicker from "components/admin/pickers/reduxFormPickers/eventCodePicker";
import ForSubsidiaryPicker from "components/admin/pickers/reduxFormPickers/forSubsidiaryPicker";
import GrantPicker from "components/admin/pickers/reduxFormPickers/grantPicker";
import InterCompanyPicker from "components/admin/pickers/reduxFormPickers/interCompanyPicker";
import LocationPicker from "components/admin/pickers/reduxFormPickers/locationPicker";
import ProductItemPicker from "components/admin/pickers/reduxFormPickers/productItemPickeer";
import ProjectPicker, {
  ProjectOptionsType,
} from "components/admin/pickers/reduxFormPickers/projectPicker/projectPicker";
import RebatePicker from "components/admin/pickers/reduxFormPickers/rebatePicker";
import SecondaryCategoryPicker from "components/admin/pickers/reduxFormPickers/secondaryCategoryPicker";
import SponsorshipPicker from "components/admin/pickers/reduxFormPickers/sponsorshipPicker";
import TaxCodePicker from "components/admin/pickers/reduxFormPickers/taxCodePicker";
import WhTaxPicker from "components/admin/pickers/reduxFormPickers/whTaxPicker";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import Panel from "components/common/panel/panel";
import {
  RenderCheckBox,
  RenderFieldNumber,
  RenderInputGroupField,
  RenderTextArea,
} from "components/forms/bootstrapFields";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { Field, FieldArrayFieldsProps, change } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import { CustomLabelSvc } from "services/admin/customLabels/customLabelsSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { required } from "services/validations/reduxFormValidation";
import { roundUpAmount } from "services/vp/services/roundUpAmount";
import { MetadataFieldSelector } from "wombatifier/components/metadata_field/selector";
import { MetadataTemplateApis } from "wombatifier/services/metadata/metadataTemplateApis";
import { INVOICE_FORM_NAME } from "../form";
import styles from "../invoices.module.css";

export type ManageInvoiceExpensesType = {
  rebate_id?: null | IDType;
  tax_code?: { [key: string]: any; rate?: number };
  actual_sub_amount?: number;
  rebate_amount?: number | null;
  rebate?: { [key: string]: any };
  sub_amount?: number;
  eligible_1099: any;
  amortization_schedule_name?: ReactNode;
  product_item?: any;
  tax_id?: number;
  unit_id?: number;
  qty?: number;
  unit_price?: number;
  amount?: number;
  tax?: number;
  business_unit_id?: number;
  department_id?: number;
  location_id?: number;
  budget_id?: number;
  account_id?: number;
  is_business_unit_required?: boolean;
  is_grant_required?: boolean;
  is_inter_company_required?: boolean;
  percent?: number;
  is_department_required?: boolean;
  is_location_required?: boolean;
  is_project_required?: boolean;
  is_warehouse_required?: boolean;
  start_date?: string;
  end_date?: string;
};

type ManageInvoiceDebitEntriesPropsType = {
  fields: FieldArrayFieldsProps<ManageInvoiceExpensesType>;
  submitWithAccounts?: boolean;
  isUsingSpecificAccount?: boolean;
  invoice?: any;
  customLabels?: { [key: string]: string[] };
};

const ManageInvoiceDebitEntries = ({
  fields,
  invoice,
  submitWithAccounts,
  isUsingSpecificAccount,
  customLabels,
}: ManageInvoiceDebitEntriesPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const [hideDebitEntry, setHideDebitEntry] = useState<number[]>([]);
  const [focusedIndex, setFocusedIndex] = useState<any>(null);
  const { companyCurrencies } = useAdminCompanyCurrencyCode();
  const dispatch = useDispatch();
  const formName = "InvoiceForm";
  const [showAdditionalCodingSection, setShowAdditionalCodingSection] = useState(false);
  const { t } = useTranslation();

  const checkIsUsingMetadata = useCallback(async () => {
    try {
      const res = await MetadataTemplateApis.list();
      // we can specify to check for 'AccountEntry' module within this list if needed
      setShowAdditionalCodingSection(res.length > 0);
    } catch (err) {
      setShowAdditionalCodingSection(false);
    }
  }, []);

  useEffect(() => {
    checkIsUsingMetadata();
  }, []);

  useEffect(() => {
    if (!(fields.length > 0) && isUsingSpecificAccount) {
      let obj: any = {};
      invoiceCommonSvc.assign1099Eligible(invoice, obj, currentUser);
      invoiceCommonSvc.inheritHeaderToExpenseLine(invoice, obj, currentUser);
      fields.push(obj);
    }
  }, [isUsingSpecificAccount]);

  useEffect(() => {
    if (focusedIndex != null) {
      let debitEntry = fields.get(focusedIndex);
      let debitEntryAmount = debitEntry.amount ? debitEntry.amount : 0;
      debitEntry.percent = Number(adminCommonSvc.roundUp((debitEntryAmount / invoice.amount) * 100));
      dispatch(change(formName, `${fields.get(focusedIndex)}.percent`, debitEntry.percent));
    }
  }, [focusedIndex, invoice.amount, fields.get(focusedIndex)?.amount]);

  const addDebitEntry = () => {
    let obj: any = {};
    invoiceCommonSvc.assign1099Eligible(invoice, obj, currentUser);
    invoiceCommonSvc.inheritHeaderToExpenseLine(invoice, obj, currentUser);
    fields?.push(obj);
  };

  const deleteLine = (debitEntry: any, index: number) => {
    debitEntry._destroy = 1;

    if (invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser)) {
      invoiceCommonSvc.calculateHeaderLevelTax(invoice);
    }
    setHideDebitEntry([...hideDebitEntry, index]);
    dispatch(change(formName, `debit_entries_attributes[${index}]`, debitEntry));
  };

  const isTaxDisabled = (entry: any, invoice: InvoiceType.InvoiceDetailType, currentUser: IUser) => {
    if (invoice?.is_account_used_tax && invoiceCommonSvc.isSubtotalUsedTaxExits(invoice)) {
      return true;
    } else if (
      invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser) &&
      invoiceCommonSvc.allowDisableTaxByAmountsAre(invoice.amounts_are)
    ) {
      return true;
    }
    return false;
  };

  const duplicateLine = (debitEntry: any) => {
    const duplicateRow = invoiceCommonSvc.getDuplicateRow(debitEntry, "account");
    // AdminCommonSvc.removeIdFromBudgetItemLinks(invoiceItem);
    fields.push(duplicateRow);
  };

  const changeDebitEntryAmount = (debitEntry: any, debitEntryAmount: number, index: number) => {
    if (!debitEntryAmount) {
      return;
    }

    const invoiceTotal = Number(invoice.amount) + Number(debitEntryAmount);
    dispatch(change(formName, `amount`, invoiceTotal));
    setFocusedIndex(index);
  };

  const updateDebitEntryAmount = (debitEntry: any, percent: number, index: number) => {
    let amt = parseFloat(invoice.amount ? invoice.amount : 0);
    debitEntry.amount = Number(roundUpAmount((amt * percent) / 100, 4));
    dispatch(change(formName, `${fields.get(focusedIndex)}.amount`, debitEntry.amount));
    // TODO: Implement update_discount_from_account feature
  };

  const handleCalculateExpenseLineAmount = (debitEntry: any, whTaxAmount: number, index: number) => {
    debitEntry.wh_tax_amount = whTaxAmount;
    calculateExpenseLineAmount(debitEntry);
  };

  const calculateExpenseLineAmount = (debitEntry: any) => {
    invoiceCommonSvc.calculateExpenseLineAmount(invoice, debitEntry, currentUser);

    if (invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser)) {
      invoiceCommonSvc.calculateHeaderLevelTax(invoice);
    }
  };

  const changeProject = (debitEntry: any, project: ProjectOptionsType, index: number) => {
    debitEntry.project_id = project.project_id;
    debitEntry.project_name = project.label;
    debitEntry.project = project;
    dispatch(change(formName, `debit_entries_attributes[${index}].project_id`, project.project_id));
  };

  const calculationExpenseLineTax = (debitEntry: any, index?: number, isRebateCalculated: boolean = false) => {
    if (!invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser)) {
      let amount = debitEntry?.tax_code?.rate > 0 ? (debitEntry.sub_amount * debitEntry.tax_code.rate) / 100 : 0;
      debitEntry.tax = Number(roundUpAmount(amount));
    }

    if (!isRebateCalculated) {
      debitEntry = invoiceCommonSvc.calculateExpenseLineRebate(debitEntry, currentUser);
    }
    debitEntry = invoiceCommonSvc.calculateWhTax(debitEntry, currentUser);
    calculateExpenseLineAmount(debitEntry);

    dispatch(change(formName, `debit_entries_attributes[${index}]`, debitEntry));
  };

  const changeForProject = (debitEntry: any, project: ProjectOptionsType, index: number) => {
    debitEntry.for_project_id = project.project_id;
    debitEntry.for_project_name = project.label;
    debitEntry.for_project = project;
    dispatch(change(formName, `debit_entries_attributes[${index}].for_project_id`, project.project_id));
  };

  const calculateTax = (debitEntry: any, index: number) => {
    debitEntry.qty = Number(debitEntry.qty ? debitEntry.qty : 0);
    debitEntry.qty = roundUpAmount(debitEntry.qty, 5);
    debitEntry.amount = invoiceCommonSvc.calculateAmount(debitEntry);
    calculationExpenseLineTax(debitEntry, index);
  };

  const calculateTaxUpdate = function (taxCode: any, debitEntry: any, index: number) {
    debitEntry.tax_code = taxCode;
    debitEntry.tax_id = taxCode.id;
    calculateTax(debitEntry, index);
  };

  const updateWhTax = function (whTaxCode: any, debitEntry: any, index: number) {
    debitEntry.wh_tax_code = whTaxCode;
    debitEntry.wh_tax_id = whTaxCode.id;

    if (!invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser)) {
      let amount = debitEntry?.tax_code?.rate > 0 ? (debitEntry?.sub_amount * debitEntry?.tax_code?.rate) / 100 : 0;
      debitEntry.tax = roundUpAmount(amount);
    }
    // calculate_expense_line_rebate(debitEntry);
    invoiceCommonSvc.calculateExpenseLineWhTax(debitEntry, currentUser);

    calculateExpenseLineAmount(debitEntry);
    dispatch(change(formName, `debit_entries_attributes[${index}]`, debitEntry));
  };

  const changeDebitEntryTax = (debitEntry: any, tax: string, index: number) => {
    debitEntry.tax = parseFloat(tax) || 0;
    invoiceCommonSvc.calculateExpenseLineAmount(invoice, fields.get(index), currentUser);
  };

  const changeDebitEntrySubAmount = (debitEntry: any, subAmount: string, index: number) => {
    debitEntry.sub_amount = parseFloat(subAmount) || 0;
    calculationExpenseLineTax(debitEntry, index);
  };

  const updateDebitAccount = (debitEntry: any, account: any, index: number) => {
    debitEntry.account_id = account?.id;
    debitEntry.account_name = account?.name;
    debitEntry.account_number = account?.number;
    debitEntry.account = account;
    invoiceCommonSvc.mapAccountRequiredFields(account, currentUser, debitEntry);
    dispatch(change(formName, `debit_entries_attributes[${index}]`, debitEntry));
    if (_.isArray(invoice?.debit_entries_attributes)) {
      invoice.debit_entries_attributes[index] = debitEntry;
      const discountAmount = adminCommonSvc.calculateDiscountAmt(invoice, currentUser);
      dispatch(change("InvoiceForm", "amount_disc", discountAmount));
    }
  };

  const showDebitEntry = (debitEntry: any): boolean => invoiceCommonSvc.showDebitEntry(debitEntry);

  const onChangeCategory = (debitEntry: any, category: CategoriesObjType, index: number) => {
    debitEntry.category = category;
    debitEntry.category_id = category.id;
    debitEntry.is_category_map_to_account = category.is_category_map_to_account;
    debitEntry.only_show_mapped_account = true;
    dispatch(change(formName, `debit_entries_attributes[${index}]`, debitEntry));
  };

  const calculateRebate = (accountEntry: ManageInvoiceExpensesType, index: number) => {
    accountEntry = invoiceCommonSvc.calculateExpenseLineRebate(accountEntry, currentUser);
    invoiceCommonSvc.updateSubAmountWithRebate(accountEntry);
    calculateExpensesTax(accountEntry.tax, accountEntry, index, true); //true - it means calculate_expenses_tax calling from calculate_rebate
  };

  const calculateExpensesTax = (
    tax: any,
    accountObj: ManageInvoiceExpensesType,
    index: number,
    isRebateCalculated?: boolean,
  ) => {
    if (!invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser)) {
      let amount =
        _.isPlainObject(accountObj.tax_code) && accountObj?.tax_code?.rate && accountObj?.tax_code?.rate > 0
          ? accountObj?.sub_amount &&
            accountObj?.tax_code?.rate &&
            (accountObj.sub_amount * accountObj.tax_code.rate) / 100
          : 0;

      accountObj.tax =
        amount && parseFloat(adminCommonSvc.roundUpAmount(amount, null, currentUser) as unknown as string);
    }

    if (!isRebateCalculated) {
      accountObj = invoiceCommonSvc.calculateExpenseLineRebate(accountObj, currentUser);
    }
    accountObj = invoiceCommonSvc.calculateExpenseLineWhTax(accountObj, currentUser);
    calculateExpenseLineAmount(accountObj);
    dispatch(change(formName, `debit_entries_attributes[${index}]`, accountObj));
  };

  const updateRebateAmount = (accountObject: ManageInvoiceExpensesType, index: number) => {
    invoiceCommonSvc.updateSubAmountWithRebate(accountObject);
    calculateExpenseLineAmount(accountObject);
  };

  return (
    <>
      <Row className="invoiceDebitEntryLine">
        <Col>
          {fields &&
            fields.length > 0 &&
            fields.map((debitEntry: any, index) => {
              if (!hideDebitEntry.includes(index) && showDebitEntry(fields.get(index))) {
                return (
                  <Panel
                    key={index}
                    cardBodyClass={styles.invoiceLineCardBody}
                    wrapperClass={styles.wrapperClass}
                    header={
                      <Row>
                        <Col sm={11} className={styles.panelTitle}>
                          DEBIT ACCOUNTS
                        </Col>
                        <Col sm={1} className="px-pl-0">
                          <Button
                            onClick={() => duplicateLine(fields.get(index))}
                            className="bg-transparent border-0 m-0 p-0 justify-content-end"
                          >
                            <i className="icon duplicate m-0" />
                          </Button>
                          <Button
                            onClick={() => deleteLine(fields.get(index), index)}
                            className="bg-transparent border-0 m-0 p-0 px-pl-15"
                          >
                            <i className="icon icon-delete m-0" />
                          </Button>
                        </Col>
                      </Row>
                    }
                  >
                    <Row>
                      <Col>
                        <Field
                          instanceId={`debit-line-${index}-account`}
                          component={AccountPicker}
                          name={`${debitEntry}.account_id`}
                          label={CustomLabelSvc.setCustomLabel(
                            customLabels,
                            t("admin.pages.invoice.invoiceItems.account"),
                          )}
                          modelData={fields.get(index)}
                          parentObjData={invoice}
                          accountGroupName="INVOICE_DEBIT_GROUPS"
                          required={fields.get(index).amount ? true : false}
                          validate={fields.get(index).amount ? [required] : []}
                          placeHolder="Search and Select... "
                          callBack={(account: AccountObjType) => updateDebitAccount(fields.get(index), account, index)}
                        />
                      </Col>
                      {invoiceCommonSvc.isTaxEnableToExpenses(currentUser) && (
                        <>
                          {!invoice.is_account_used_tax && (
                            <Col className="p-0">
                              <Field
                                instanceId={`debit-line-${index}-tax-code`}
                                name={`${debitEntry}.tax_id`}
                                label={CustomLabelSvc.setCustomLabel(
                                  customLabels,
                                  t("admin.pages.invoice.invoiceItems.taxCode"),
                                )}
                                component={TaxCodePicker}
                                isClearableDisable={true}
                                parentObj={invoice}
                                modelData={fields.get(index)}
                                subsidiary_id={invoice?.subsidiary_id}
                                required={invoiceCommonSvc.isInvoiceLineLevelTaxRequired(invoice, currentUser)}
                                validate={
                                  invoiceCommonSvc.isInvoiceLineLevelTaxRequired(invoice, currentUser) ? [required] : []
                                }
                                callBack={(taxCode: any) => calculateTaxUpdate(taxCode, fields.get(index), index)}
                                disabled={isTaxDisabled(fields.get(index), invoice, currentUser)}
                              />
                            </Col>
                          )}

                          {invoice.is_account_used_tax && (
                            <Col className="p-0">
                              <Field
                                instanceId={`debit-line-${index}-tax-code`}
                                name={`${debitEntry}.tax_id`}
                                label={CustomLabelSvc.setCustomLabel(
                                  customLabels,
                                  t("admin.pages.invoice.invoiceItems.taxCode"),
                                )}
                                component={TaxCodePicker}
                                isClearableDisable={true}
                                parentObj={invoice}
                                modelData={fields.get(index)}
                                subsidiary_id={invoice?.subsidiary_id}
                                isUseTax={invoice.is_account_used_tax ?? null}
                                required={invoiceCommonSvc.isInvoiceLineLevelTaxRequired(invoice, currentUser)}
                                validate={
                                  invoiceCommonSvc.isInvoiceLineLevelTaxRequired(invoice, currentUser) ? [required] : []
                                }
                                callBack={(taxCode: any) => calculateTaxUpdate(taxCode, fields.get(index), index)}
                                disabled={isTaxDisabled(fields.get(index), invoice, currentUser)}
                              />
                            </Col>
                          )}

                          {!invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser) && (
                            <Col sm={2}>
                              <Field
                                id={`debit-line-${index}-tax`}
                                name={`${debitEntry}.tax`}
                                type="number"
                                label={CustomLabelSvc.setCustomLabel(
                                  customLabels,
                                  t("admin.pages.invoice.invoiceItems.tax"),
                                )}
                                component={RenderFieldNumber}
                                onChange={(e: any) => changeDebitEntryTax(fields.get(index), e.target.value, index)}
                                disabled={!currentUser.company.enable_to_enter_tax_amount}
                              />
                            </Col>
                          )}
                        </>
                      )}

                      <Col sm={2}>
                        <Field
                          id={`debit-line-${index}-sub-amount`}
                          name={`${debitEntry}.sub_amount`}
                          component={RenderInputGroupField}
                          type="text"
                          inputGroupText={adminCommonSvc.getSymbolFromIsoCode(
                            invoice?.currency_code,
                            companyCurrencies,
                          )}
                          label={CustomLabelSvc.setCustomLabel(
                            customLabels,
                            t("admin.pages.invoice.invoiceItems.subAmount"),
                          )}
                          onChange={(e: any) => changeDebitEntrySubAmount(fields.get(index), e.target.value, index)}
                          required={fields.get(index).account_id ? true : false}
                          validate={fields.get(index).account_id ? [required] : []}
                        />
                      </Col>

                      <Col sm={2}>
                        <Field
                          id={`debit-line-${index}-amount`}
                          name={`${debitEntry}.amount`}
                          component={RenderInputGroupField}
                          type="text"
                          inputGroupText={adminCommonSvc.getSymbolFromIsoCode(
                            invoice?.currency_code,
                            companyCurrencies,
                          )}
                          label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.amount"))}
                          disabled={invoiceCommonSvc.enabledExpenseTaxOrRebate(currentUser)}
                          onChange={(e: any) => changeDebitEntryAmount(fields.get(index), e.target.value, index)}
                          required={fields.get(index).account_id ? true : false}
                          validate={fields.get(index).account_id ? [required] : []}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {currentUser?.company?.has_wh_taxes && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-wh-tax-code`}
                            component={WhTaxPicker}
                            name={`${debitEntry}.wh_tax_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.whTaxCode"),
                            )}
                            callBack={(whTaxCode: any) => updateWhTax(whTaxCode, fields.get(index), index)}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_wh_taxes && (
                        <Col sm={4}>
                          <Field
                            id={`debit-line-${index}-wh-tax-amout`}
                            name={`${debitEntry}.wh_tax_amount`}
                            type="number"
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.whTaxAmount"),
                            )}
                            component={RenderFieldNumber}
                            disabled={!currentUser?.company?.global?.enable_to_enter_wh_tax_amount}
                            onChange={(e: any) =>
                              handleCalculateExpenseLineAmount(fields.get(index), e.target.value, index)
                            }
                          />
                        </Col>
                      )}

                      {invoiceCommonSvc.allowRebateExpensesLine(currentUser) && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-rebate`}
                            name={`${debitEntry}.rebate_id`}
                            type="number"
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.rebate"),
                            )}
                            component={RebatePicker}
                            formName={formName}
                            modelData={fields.get(index)}
                            modelDataFormField={`${debitEntry}`}
                            callBack={(obj: { a: any; b: ManageInvoiceExpensesType }) => calculateRebate(obj.b, index)}
                          />
                        </Col>
                      )}

                      {invoiceCommonSvc.allowRebateExpensesLine(currentUser) && (
                        <Col sm={4}>
                          <Field
                            id={`debit-line-${index}-rebate-amount`}
                            name={`${debitEntry}.rebate_amount`}
                            type="number"
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.rebateAmount"),
                            )}
                            component={RenderFieldNumber}
                            placeholder="Rebate Amount"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const debitLine = fields.get(index);
                              debitLine.rebate_amount = parseFloat(e.target.value);
                              updateRebateAmount(debitLine, index);
                            }}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_departments &&
                        !currentUser?.company?.invoice?.expenses?.department?.is_hide && (
                          <Col sm={4}>
                            <Field
                              instanceId={`debit-line-${index}-dipartment`}
                              component={DepartmentPicker}
                              name={`${debitEntry}.department_id`}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.department"),
                              )}
                              modelData={fields.get(index)}
                              parentObjData={invoice}
                              required={
                                fields.get(index).is_department_required ||
                                currentUser?.company?.invoice?.expenses?.department?.is_required
                              }
                              validate={
                                fields.get(index).is_department_required ||
                                currentUser?.company?.invoice?.expenses?.department?.is_required
                                  ? [required]
                                  : []
                              }
                              menuPosition="fixed"
                            />
                          </Col>
                        )}
                      {currentUser?.company?.has_locations && !currentUser?.company?.invoice_account_hide_location && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-location`}
                            name={`${debitEntry}.location_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.location"),
                            )}
                            component={LocationPicker}
                            modelData={fields.get(index)}
                            parentObjData={invoice}
                            callBack={(location: any) => dispatch(change(formName, `${debitEntry}.location`, location))}
                            required={
                              fields.get(index).is_location_required ||
                              currentUser?.company?.invoice_expense_location_required
                            }
                            validate={
                              fields.get(index).is_location_required ||
                              currentUser?.company?.invoice_expense_location_required
                                ? [required]
                                : []
                            }
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_business_units &&
                        !currentUser?.company?.invoice_account_hide_business_unit && (
                          <Col sm={4}>
                            <Field
                              instanceId={`debit-line-${index}-business-unit`}
                              name={`${debitEntry}.business_unit_id`}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.businessUnit"),
                              )}
                              component={BusinessUnitPicker}
                              parentObjData={invoice}
                              modelData={fields.get(index)}
                              callBack={(businessUnit: any) =>
                                dispatch(change(formName, `${debitEntry}.business_unit`, businessUnit))
                              }
                              bySubsidiary={currentUser?.company?.invoice?.show_business_unit_by_subsidiary}
                              disabled={currentUser?.company?.readonly_business_unit_to_all}
                              required={
                                fields.get(index).is_business_unit_required ||
                                currentUser?.company?.invoice?.expenses?.business_unit?.is_required
                              }
                              validate={
                                fields.get(index).is_business_unit_required ||
                                currentUser?.company?.invoice?.expenses?.business_unit?.is_required
                                  ? [required]
                                  : []
                              }
                              floating={true}
                            />
                          </Col>
                        )}
                      {currentUser?.company?.has_inter_companies && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-inter-company`}
                            name={`${debitEntry}.inter_company_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.interCompany"),
                            )}
                            component={InterCompanyPicker}
                            required={fields.get(index).is_inter_company_required}
                            validate={fields.get(index).is_inter_company_required ? [required] : []}
                            params={{ account_id: `${debitEntry}.account_id`, subsidiary_id: invoice?.subsidiary_id }}
                          />
                        </Col>
                      )}
                      {adminCommonSvc.isBudgetVisibleAtLineLevel(currentUser) &&
                        !currentUser?.company?.budget?.allow_to_select_budget_lines && (
                          <Col sm={4}>
                            <Field
                              instanceId={`debit-line-${index}-budget`}
                              id={`account_entry_budgetId_${index}`}
                              name={`${debitEntry}.budget_id`}
                              component={BudgetPicker}
                              placeholder="Select..."
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.budget"),
                              )}
                              parentObjData={invoice}
                              modelData={fields.get(index)}
                            />
                          </Col>
                        )}

                      {adminCommonSvc.isBudgetVisibleAtLineLevel(currentUser) &&
                        currentUser?.company?.budget?.allow_to_select_budget_lines && (
                          <Col sm={4}>
                            <Field
                              instanceId={`debit-line-${index}-budget`}
                              name={`${debitEntry}.budget_item_links`}
                              component={BudgetPicker}
                              placeholder="Select..."
                              isMulti={true}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.budget"),
                                t("admin.pages.invoice.invoiceItems.budgets"),
                              )}
                              parentObjData={invoice}
                              modelData={fields.get(index)}
                              callBack={(selectedBudgets: any) =>
                                dispatch(
                                  change(formName, `${debitEntry}.budget_item_links_attributes`, selectedBudgets),
                                )
                              }
                            />
                          </Col>
                        )}

                      {currentUser?.company?.has_categories &&
                        (!currentUser?.company?.invoice || !currentUser?.company?.invoice?.hide_categories) && (
                          <Col sm={4}>
                            <Field
                              instanceId={`debit-line-${index}-category`}
                              name={`${debitEntry}.category_id`}
                              label={CustomLabelSvc.setCustomLabel(
                                customLabels,
                                t("admin.pages.invoice.invoiceItems.category"),
                              )}
                              modelData={fields.get(index)}
                              placeholder="Select..."
                              component={CategoryPicker}
                              required={currentUser?.company?.invoice?.expenses?.category?.is_required}
                              validate={
                                currentUser?.company?.invoice?.expenses?.category?.is_required ? [required] : []
                              }
                              callBack={(category: CategoriesObjType) =>
                                onChangeCategory(fields.get(index), category, index)
                              }
                            />
                          </Col>
                        )}
                      {currentUser?.company?.has_grants && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-grant`}
                            name={`${debitEntry}.grant_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.grant"),
                            )}
                            placeholder="Select..."
                            component={GrantPicker}
                            required={fields.get(index).is_grant_required}
                            validate={fields.get(index).is_grant_required ? [required] : []}
                            floating={true}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_event_codes && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-event-code`}
                            name={`${debitEntry}.event_code_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.eventCode"),
                            )}
                            placeholder="Select..."
                            component={EventCodePicker}
                            floating={true}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_sponsorships && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-sponsorship`}
                            name={`${debitEntry}.sponsorship_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.expenseItems.sponsorship"),
                            )}
                            placeholder="Select..."
                            component={SponsorshipPicker}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_business_units && currentUser?.company?.allow_for_business_unit && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-for-business-unit`}
                            name={`${debitEntry}.for_business_unit_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.forBusinessUnit"),
                            )}
                            placeholder="Select..."
                            component={BusinessUnitPicker}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.allow_for_location && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-for-location`}
                            id={`account_entry_for_location_id_${index}`}
                            name={`${debitEntry}.for_location_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.forLocation"),
                            )}
                            placeholder="Select..."
                            component={LocationPicker}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_for_subsidiaries && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-for-subsidiary`}
                            name={`${debitEntry}.for_subsidiary_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.forSubsidiary"),
                            )}
                            placeholder="Select..."
                            component={ForSubsidiaryPicker}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_secondary_categories && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-secondary-category`}
                            name={`${debitEntry}.secondary_category_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.secondaryCategory"),
                            )}
                            component={SecondaryCategoryPicker}
                            placeholder="Select..."
                            floating={true}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_territories && (
                        <Col sm="4">
                          <Field
                            instanceId={`debit-line-${index}-territories`}
                            name={`${debitEntry}.territories_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.territories"),
                            )}
                            modelName={"Territories"}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_customers && (
                        <Col sm="4">
                          <Field
                            instanceId={`debit-line-${index}-customer`}
                            name={`${debitEntry}.customer_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.customer"),
                            )}
                            modelName={"Customer"}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_custom_employee && (
                        <Col sm="4">
                          <Field
                            instanceId={`debit-line-${index}-custom-employee`}
                            name={`${debitEntry}.custom_employee_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.employee"),
                            )}
                            modelName={"CustomEmployee"}
                            floating={true}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_custom_types && (
                        <Col sm="4">
                          <Field
                            instanceId={`debit-line-${index}-type`}
                            name={`${debitEntry}.custom_type_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.type"),
                            )}
                            modelName={"CustomType"}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_company_events && (
                        <Col sm="4">
                          <Field
                            instanceId={`debit-line-${index}-company-event`}
                            name={`${debitEntry}.company_event_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.companyEvent"),
                            )}
                            modelName={"CompanyEvent"}
                            floating={true}
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_vineyard_codes && (
                        <Col sm="4">
                          <Field
                            instanceId={`debit-line-${index}-vineyard-code`}
                            name={`${debitEntry}.vineyard_code_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.vineyardCode"),
                            )}
                            modelName={"VineyardCode"}
                            floating={true}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_counterparties && (
                        <Col sm="4">
                          <Field
                            instanceId={`debit-line-${index}-counter-party`}
                            name={`${debitEntry}.counterparty_external_id`}
                            component={CustomDataListPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.expenseItems.counterParty"),
                            )}
                            modelName={"Counterparty"}
                            floating={true}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.show_employee_field_invoice_item_expense && (
                        <Col sm="4" className={styles.vendorFormField}>
                          <Field
                            instanceId={`debit-line-${index}-employee`}
                            name={`${debitEntry}.employee_id`}
                            component={ContactPicker}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.employee"),
                            )}
                            floating={true}
                          />
                        </Col>
                      )}
                      {currentUser?.company?.has_projects && currentUser?.company?.allow_for_project && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-for-project`}
                            name={`${debitEntry}.for_project`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.forProject"),
                            )}
                            component={ProjectPicker}
                            callBack={(selected: ProjectOptionsType) =>
                              changeForProject(fields.get(index), selected, index)
                            }
                            isForProject={true}
                            modelData={fields.get(index)}
                            menuPosition="fixed"
                          />
                        </Col>
                      )}

                      {currentUser?.company?.has_projects && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-project`}
                            name={`${debitEntry}.project`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.project"),
                            )}
                            component={ProjectPicker}
                            callBack={(selected: ProjectOptionsType) =>
                              changeProject(fields.get(index), selected, index)
                            }
                            required={invoiceCommonSvc.isProjectRequired(fields.get(index), currentUser)}
                            validate={
                              invoiceCommonSvc.isProjectRequired(fields.get(index), currentUser) ? [required] : []
                            }
                            menuPosition="fixed"
                            // TODO: Implemente feature invoiceCommonSvc.inherit_linked_data_by_project(project, account_entry)
                          />
                        </Col>
                      )}
                      {currentUser?.company?.invoice?.expenses?.show_item_list && (
                        <Col sm={4}>
                          <Field
                            instanceId={`debit-line-${index}-product-item`}
                            name={`${debitEntry}.item_external_id`}
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.expenseItems.productItem"),
                            )}
                            placeholder="Search..."
                            component={ProductItemPicker}
                            apiParams={{
                              subsidiary_id: adminCommonSvc.showItemsBySubsidiary(invoice, currentUser)
                                ? invoice?.subsidiary_id
                                : null,
                            }}
                            menuPosition="fixed"
                          />
                        </Col>
                      )}
                      {currentUser?.company?.amortization_scheduled && (
                        <Col sm={12}>
                          <AmortizationSchedule
                            id="account_entry_authorisation_schedule"
                            amortizationId={`${debitEntry}.amortization_id`}
                            startDate={fields.get(index).start_date}
                            endDate={fields.get(index).end_date}
                            amortizationStartDate={`${debitEntry}.start_date`}
                            amortizationEndDate={`${debitEntry}.end_date`}
                            amortizationScheduleName={
                              fields.get(index).amortization_schedule_name !== null
                                ? fields.get(index).amortization_schedule_name
                                : null
                            }
                          />
                        </Col>
                      )}

                      {invoiceCommonSvc.show1099Eligible(invoice, currentUser) && (
                        <Col sm={12}>
                          <Field
                            id={`debit-line-${index}-1099-eligible`}
                            name="eligible_1099"
                            component={RenderCheckBox}
                            defaultValue={fields.get(index).eligible_1099}
                            onChange={(value: any) => (fields.get(index).eligible_1099 = value)}
                            type="checkbox"
                            label={CustomLabelSvc.setCustomLabel(
                              customLabels,
                              t("admin.pages.invoice.invoiceItems.1099Eligible"),
                            )}
                          />
                        </Col>
                      )}

                      <Col sm={12}>
                        <Field
                          id={`debit-line-${index}-memo`}
                          name={`${debitEntry}.memo`}
                          label={CustomLabelSvc.setCustomLabel(
                            customLabels,
                            t("admin.pages.invoice.expenseItems.memo"),
                          )}
                          rows={2}
                          component={RenderTextArea}
                          required={currentUser?.company?.invoice?.expenses?.memo?.is_required}
                          validate={currentUser?.company?.invoice?.expenses?.memo?.is_required ? [required] : []}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <CustomFieldPicker
                          formFieldName={`${debitEntry}.custom_fields`}
                          formName={INVOICE_FORM_NAME}
                          parentObjData={invoice}
                          modelData={fields.get(index)}
                          modelName="InvoiceExpenses"
                          modelDataFieldName={`${debitEntry}`}
                        />
                      </Col>
                    </Row>
                    {showAdditionalCodingSection && (
                      <Row>
                        <ErrorBoundary>
                          <MetadataFieldSelector
                            formName={formName}
                            formSlice={`${debitEntry}`}
                            modules="AccountEntry"
                          />
                        </ErrorBoundary>
                      </Row>
                    )}
                  </Panel>
                );
              }
            })}
        </Col>
      </Row>
      <Row className="px-mt-15">
        <Col>
          <Button variant="btn btn-primary" onClick={() => addDebitEntry()}>
            <i className="btn-icon icon-add-btn"></i> Debit Accounts
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ManageInvoiceDebitEntries;
