import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

class TabSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
    };
  }

  componentDidMount() {}

  tabClicked = (tab, index) => {
    this.setState({ activeTabIndex: index });
    if (tab.callback) {
      tab.callback(tab);
    }
  };

  render() {
    return (
      <div className="mt-4">
        {this.props.tabs.map((tab, index) => {
          let activeCSS = index == this.state.activeTabIndex ? "tabSwitchButtonActive" : "";
          return (
            <button
              type="button"
              key={tab.name}
              className={"tabSwitchButton " + activeCSS}
              onClick={() => {
                this.tabClicked(tab, index);
              }}
            >
              {tab.name}
            </button>
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(TabSwitcher);
