import React, { ChangeEventHandler, forwardRef, useImperativeHandle, useRef, useState } from "react";

const CustomSelectFilter = forwardRef<any, any>((props, ref) => {
  const [selectedValue, setSelectedValue] = useState("");

  const selectRef = useRef<HTMLSelectElement>(null);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => ({
    onParentModelChanged(parentModel: any) {
      if (selectRef.current) {
        selectRef.current.value = parentModel ? parentModel.filter : "";
      }
      setSelectedValue(parentModel ? parentModel.filter : "");
    },
  }));

  const onSelectionChanged: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value;
    setSelectedValue(value);

    props.parentFilterInstance((instance: any) => {
      if (value === "") {
        // Remove the filter
        instance?.onFloatingFilterChanged?.("contains", null);
      } else {
        instance?.onFloatingFilterChanged?.("contains", value);
      }
    });
  };

  return (
    <div className="ag-floating-filter-input ag-input-wrapper">
      <select
        ref={selectRef}
        className="ag-input-field-input ag-text-field-input"
        value={selectedValue}
        onChange={onSelectionChanged}
      >
        <option value=""></option>
        {Array.isArray(props.options) &&
          props.options.map((option: any) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
});

export default CustomSelectFilter;
