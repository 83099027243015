import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import RestApi from "../../../../providers/restApi";
import { addUserDetails } from "../../../../reducers/userReducers";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";

import _ from "lodash";
import { setCompanyDefault } from "reducers/common/appCommonStatesSlice";
import { Can } from "../../../../services/authorization/authorization";
import { normalizeDates } from "../../../../services/common/commonSvc";
import CompanySettingTabCss from "./companySettingTab.module.css";
import BudgetSetting from "./tabs/budgetSettings";
import DraftInvoiceSetting from "./tabs/draftInvoiceSettings";
import ExpenseSetting from "./tabs/expenseSettings";
import GeneralSetting from "./tabs/generalSettings";
import InvoiceSetting from "./tabs/invoiceSettings";
import PaymentSetting from "./tabs/paymentsSettings";
import PORequestSetting from "./tabs/poRequestSettings";
import POSetting from "./tabs/poSettings";
import ProductItemSetting from "./tabs/productItemSettings";
import ReceiptSetting from "./tabs/receiptSettings";
import VCardSetting from "./tabs/vCardSettings";
import VendorCreditSetting from "./tabs/vendorCreditSettings";
import VendorSetting from "./tabs/vendorSettings";

const restApiService = new RestApi();

let CompanyDefaultForm = (props) => {
  const [currentTab, setCurrentTab] = useState("generalSettingList");
  const formData = useSelector((state) =>
    state.form.companyDefaultForm ? state.form.companyDefaultForm.values : null,
  );
  const { handleSubmit } = props;
  const dispatch = useDispatch();

  if (formData) {
    return (
      <Row className="m-0">
        <Col className="p-0 pt-0">
          {/* Tabs */}
          <Row>
            <div className={`${CompanySettingTabCss.navTabsSubtabs} m-2`}>
              <ul className={`nav ${CompanySettingTabCss.navTabs}`}>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("generalSettingList")}
                  className={`${currentTab === "generalSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>General</a>
                </li>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("poRequestSettingList")}
                  className={`${currentTab === "poRequestSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>PO Request</a>
                </li>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("poSettingList")}
                  className={`${currentTab === "poSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>Purchase Order</a>
                </li>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("invoiceSettingList")}
                  className={`${currentTab === "invoiceSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>Invoice</a>
                </li>
                <Can I={"role"} a={"Service"}>
                  <li
                    role="presentation"
                    onClick={() => setCurrentTab("draftInvoiceSettingList")}
                    className={`${currentTab === "draftInvoiceSettingList" && CompanySettingTabCss.active}`}
                  >
                    <a>Draft Invoice</a>
                  </li>
                </Can>
                {/** Should approval Setting list */}
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("budgetSettingList")}
                  className={`${currentTab === "budgetSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>Budget</a>
                </li>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("paymentSettingList")}
                  className={`${currentTab === "paymentSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>Payment</a>
                </li>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("vendorSettingList")}
                  className={`${currentTab === "vendorSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>Vendor</a>
                </li>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("vendorCreditSettingList")}
                  className={`${currentTab === "vendorCreditSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>Credit Memo</a>
                </li>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("productItemSettingList")}
                  className={`${currentTab === "productItemSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>Product Item</a>
                </li>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("vCardSettingList")}
                  className={`${currentTab === "vCardSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>Corporate Card</a>
                </li>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("expenseSettingList")}
                  className={`${currentTab === "expenseSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>Expense</a>
                </li>
                <li
                  role="presentation"
                  onClick={() => setCurrentTab("receiptSettingList")}
                  className={`${currentTab === "receiptSettingList" && CompanySettingTabCss.active}`}
                >
                  <a>Receipt</a>
                </li>
              </ul>
            </div>
          </Row>

          <Form noValidate onSubmit={(value) => handleSubmit(value, dispatch)}>
            {/*<div className={settingStyle.settingBodySection}>*/}
            {/*  <label>Welcome</label>*/}
            {/*</div>*/}
            <div className={CompanySettingTabCss.settingBodySection}>
              <Row className={"m-0 p-0"}>
                {currentTab === "generalSettingList" && <GeneralSetting {...props} />}
                {currentTab === "poRequestSettingList" && <PORequestSetting {...props} />}
                {currentTab === "poSettingList" && <POSetting {...props} />}
                {currentTab === "invoiceSettingList" && <InvoiceSetting {...props} />}
                {currentTab === "draftInvoiceSettingList" && <DraftInvoiceSetting {...props} />}
                {currentTab === "budgetSettingList" && <BudgetSetting {...props} />}
                {currentTab === "paymentSettingList" && <PaymentSetting {...props} />}
                {currentTab === "vendorSettingList" && <VendorSetting {...props} />}
                {currentTab === "vendorCreditSettingList" && <VendorCreditSetting {...props} />}
                {currentTab === "productItemSettingList" && <ProductItemSetting {...props} />}
                {currentTab === "vCardSettingList" && <VCardSetting {...props} />}
                {currentTab === "expenseSettingList" && <ExpenseSetting {...props} />}
                {currentTab === "receiptSettingList" && <ReceiptSetting {...props} />}
              </Row>
            </div>

            <Row className="m-0">
              <Col lg={12} className="text-right p-0">
                <Button type="submit" id="company_default_submit_button" className="m-3">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  } else return null;
};

CompanyDefaultForm = reduxForm({
  form: "companyDefaultForm", // a unique identifier for this form
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: async (formData, dispatch) => {
    let obj = {};
    obj.allow_invoice_items = formData.company.allow_invoice_items;
    obj.currency_code = formData.company.currency_code;
    obj.subsidiary_id = formData.company.subsidiary_id;

    if (_.isPlainObject(formData.company_default.invoice) && formData.company_default.invoice.set_prior_period_range) {
      formData.company_default.invoice.prior_period_range_start_date = normalizeDates(
        formData.company_default.invoice.prior_period_range_start_date,
      );
      formData.company_default.invoice.prior_period_range_end_date = normalizeDates(
        formData.company_default.invoice.prior_period_range_end_date,
      );
    }
    try {
      const companyPromise = restApiService.patch("company", null, obj);
      const companyDefaultPromise = restApiService.patch("company/default", null, {
        company_default: formData.company_default,
      });
      const res = await Promise.all([companyPromise, companyDefaultPromise]);

      if (res[0].data && res[1].data) {
        CreateNotification("Success", "Changes Saved Successfully", NotificationType.success);

        // Now update current user
        const userResponse = await restApiService.get("user");
        if (userResponse) dispatch(addUserDetails(userResponse.data));

        // also update company default
        const companyDefaultResponse = await restApiService.get("company/default");
        if (companyDefaultResponse) dispatch(setCompanyDefault(companyDefaultResponse.data));
      }
    } catch (error) {
      console.log("🚀 ~ file: form.jsx ~ line 217 ~ onSubmit: ~ error", error);
    }
  },
})(CompanyDefaultForm);

CompanyDefaultForm = connect((state) => ({
  initialValues: state.companyDefaultRed.companyDefault,
}))(CompanyDefaultForm);

export default withTranslation()(CompanyDefaultForm);
