import React, { useCallback, useEffect, useRef, useState } from "react";
import restApi from "../../../../../providers/restApi";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Styles from "../../../../pickers/reduxFormPicker/override.module.css";
import { compare } from "../../../../../services/general/helpers";
import _, { isArray, isPlainObject } from "lodash";
import { useTranslation } from "react-i18next";
const restApiService = new restApi();

const PurchaseOrderPicker = (props) => {
  const { t } = useTranslation();
  let {
    className,
    label,
    tooltip,
    input,
    purchaserId,
    callback,
    isMulti,
    meta: { touched, error, warning },
  } = props;
  const [poList, setPoList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const mounted = useRef(false);

  const getVendorPoParams = useCallback(() => {
    return { purchaser_id: purchaserId, status: "OPEN" };
  }, [purchaserId]);

  const getVendorPos = useCallback(async () => {
    const result = await restApiService.get("vendor_purchase_orders", getVendorPoParams());
    const value = parseForSelect(result.data);
    if (value.length > 0) {
      setSelectedValues({});
    }
    if (mounted.current) setPoList(value);
  }, [getVendorPoParams]);

  const parseForSelect = (pos) => {
    return pos
      .map((po) => {
        return {
          value: po.id,
          label: po.number,
        };
      })
      .sort(compare);
  };

  useEffect(() => {
    setSelectedValues("");
    if (purchaserId) {
      getVendorPos();
    }
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [purchaserId]);

  const onBlur = (option) => {
    if (!option) {
      setIsError(true);
      input.onBlur(null);
      return;
    }
    setIsError(false);
    input.onBlur(option);
  };

  const findSelected = () => {
    if (_.isObject(input) && input.value > 0 && _.isArray(poList) && poList.length > 0) {
      let selected = _.find(poList, (po) => po.value === input.value);
      return selected;
    } else {
      return selectedValues;
    }
  };

  const setPoObj = (option) => {
    if (callback) {
      callback(option);
    }
    if (!option) {
      setSelectedValues(option);
      input.onChange("");
      return;
    }
    input.onChange(option.value);
    setSelectedValues(option);
  };

  return (
    <Form.Group className={Styles.select}>
      <label>{label}</label>
      <Select
        {...input}
        value={findSelected()}
        placeholder="search and select"
        isClearable
        onChange={(value) => setPoObj(value)}
        onBlur={() => onBlur(input.value)}
        options={poList}
      />
    </Form.Group>
  );
};

export default PurchaseOrderPicker;
