import CountryPicker from "components/admin/pickers/reduxFormPickers/countryPicker";
import usePermission from "components/common/hooks/usePermission";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Field, change, getFormValues } from "redux-form";
import { useTypedSelector } from "../../../../reducers";
import { VendorType } from "../../../../services/admin/vendors/vendorTypes";
import { CompanyType } from "../../../../services/common/user/userTypes";
import {
  email as emailValidation,
  phoneNumberOnly,
  required,
} from "../../../../services/validations/reduxFormValidation";
import StatePicker from "../../../common/pickers/reduxFormPickers/statePicker";
import { RenderField, RenderSelect } from "../../../forms/bootstrapFields";
import VendorClassification from "../../pickers/reduxFormPickers/vendorClassification/vendorClassification";
import styles from "../vendors.module.css";

type AddVendorDetailsPropsType = {
  handleNext: (step: number) => void;
  handleCancel: () => void;
  currentUserCompany?: CompanyType;
  valid?: boolean;
};

const AddVendorDetails = ({ handleNext, handleCancel, currentUserCompany, valid }: AddVendorDetailsPropsType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vendor: VendorType = useTypedSelector((state) => getFormValues("VendorForm")(state));
  const defaultCountry = vendor?.country ? vendor.country : "";
  const [countryCode, setCountryCode] = useState<string>(defaultCountry);
  const { hasUserPermission } = usePermission();

  return (
    <>
      <Row className="px-mb-25">
        <Col md="12">
          <span className={styles.vendorDetails}>
            <i className={`icon icon-vendors ${styles.iconVendor}`}></i>
            {t("admin.pages.vendor.vendorDetails")}
          </span>
        </Col>
      </Row>
      <Row>
        {vendor?.vendor_type && vendor.vendor_type !== "EMPLOYEE" && (
          <Col md="6" className={styles.vendorFormField}>
            <Field
              name="name"
              component={RenderField}
              type="text"
              label={t("admin.pages.vendor.vendorName")}
              className={`w-100`}
              required
              validate={[required]}
            />
          </Col>
        )}
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="legal_name"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.legalName")}
            className={`w-100`}
          />
        </Col>
        {vendor?.vendor_type && vendor.vendor_type === "EMPLOYEE" && (
          <>
            <Col md="6" className={styles.vendorFormField}>
              <Field
                name="first_name"
                component={RenderField}
                type="text"
                label={t("admin.pages.vendor.first_name")}
                className={`w-100`}
                required
                validate={[required]}
              />
            </Col>
            <Col md="6" className={styles.vendorFormField}>
              <Field
                name="last_name"
                component={RenderField}
                type="text"
                label={t("admin.pages.vendor.last_name")}
                className={`w-100`}
                required
                validate={[required]}
              />
            </Col>
          </>
        )}
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="vendor_id"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.vendorId")}
            className={`w-100`}
            required
            validate={[required]}
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="master_id"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.masterId")}
            className={`w-100`}
            disabled={vendor.master_id ? true : false}
          />
        </Col>
        {currentUserCompany?.has_vendor_classifications && (
          <Col md="6" className={styles.vendorFormField}>
            <Field
              name="vendor_classification_id"
              component={VendorClassification}
              label={t("admin.pages.vendor.vendorSubType")}
              required={
                currentUserCompany?.has_vendor_classifications && currentUserCompany?.vendor?.subtype?.is_required
              }
              validate={
                currentUserCompany?.has_vendor_classifications && currentUserCompany?.vendor?.subtype?.is_required
                  ? [required]
                  : []
              }
              callBack={(selected: { value: number; label: string }) =>
                dispatch(change("VendorForm", "vendorClassification", selected.label))
              }
            />
          </Col>
        )}
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="vendor_type"
            component={RenderSelect}
            FieldClassName="formField"
            options={[
              { label: "COMPANY", value: "COMPANY" },
              { label: "EMPLOYEE", value: "EMPLOYEE" },
              { label: "INTERNAL", value: "INTERNAL" },
            ]}
            placeholder={"Search/Select"}
            label={t("admin.pages.vendor.vendorType")}
            required={currentUserCompany?.vendor?.vendor_type?.is_required}
            validate={currentUserCompany?.vendor?.vendor_type?.is_required ? [required] : []}
          />
        </Col>
        {hasUserPermission("addVendors") && (
          <Col md="6" className={styles.vendorFormField}>
            <Field
              name="status"
              component={RenderSelect}
              validate={[required]}
              FieldClassName="formField"
              options={[
                { label: "PENDING", value: "PENDING" },
                { label: "ACTIVE", value: "ACTIVE" },
                { label: "INACTIVE", value: "INACTIVE" },
                { label: "NEW", value: "NEW" },
                { label: "REJECTED", value: "REJECTED" },
              ]}
              placeholder={"Search/Select"}
              label={t("admin.pages.vendor.status")}
              required
            />
          </Col>
        )}
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="phone"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.phone")}
            className={`w-100`}
            validate={[phoneNumberOnly]}
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="email"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.email")}
            className={`w-100`}
            required={currentUserCompany?.vendor?.email?.is_required}
            validate={currentUserCompany?.vendor?.email?.is_required ? [required, emailValidation] : [emailValidation]}
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="url"
            component={RenderField}
            type="text"
            label={[t("admin.pages.vendor.website"), t("admin.pages.vendor.websiteNote")]}
            className={`w-100`}
          />
        </Col>

        <Col md="4" className={styles.vendorFormField}>
          <Field
            name="address_type"
            component={RenderSelect}
            FieldClassName="formField"
            options={[
              { label: "Billing", value: "Billing" },
              { label: "Shipping", value: "Shipping" },
              { label: "Mailing", value: "Mailing" },
            ]}
            label={t("admin.pages.vendor.addressType")}
            className={`w-100`}
          />
        </Col>
        <Col md="4" className={styles.vendorFormField}>
          <Field
            name="attention"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.attention")}
            className={`w-100`}
          />
        </Col>
        <Col md="4" className={styles.vendorFormField}>
          <Field
            name="label"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.addressLabel")}
            className={`w-100`}
          />
        </Col>
        <Col md="12" className={styles.vendorFormField}>
          <Field
            name="address1"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.address1")}
            className={`w-100`}
            required={currentUserCompany?.vendor?.address?.is_required}
            validate={currentUserCompany?.vendor?.address?.is_required ? [required] : []}
          />
        </Col>
        <Col md="5" className={styles.vendorFormField}>
          <Field
            name="country"
            component={CountryPicker}
            callBack={(code: string) => setCountryCode(code)}
            label={t("admin.pages.vendor.country")}
            className={`w-100`}
            required={currentUserCompany?.vendor?.address?.is_required}
            validate={currentUserCompany?.vendor?.address?.is_required ? [required] : []}
          />
        </Col>
        <Col md="3" className={styles.vendorFormField}>
          <Field
            name="state"
            component={StatePicker}
            label={t("admin.pages.vendor.state")}
            className={`w-100`}
            countryCode={countryCode}
          />
        </Col>
        <Col md="2" className={styles.vendorFormField}>
          <Field
            name="city"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.city")}
            className={`w-100`}
            required={currentUserCompany?.vendor?.address?.is_required}
            validate={currentUserCompany?.vendor?.address?.is_required ? [required] : []}
          />
        </Col>
        <Col md="2" className={styles.vendorFormField}>
          <Field
            name="zipcode"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.zipCode")}
            className={`w-100`}
            required={currentUserCompany?.vendor?.address?.is_required}
            validate={currentUserCompany?.vendor?.address?.is_required ? [required] : []}
          />
        </Col>
      </Row>
      <Row className="px-mt-25">
        <Col md={{ span: 4, offset: 8 }} className={styles.btnColumn}>
          <Button variant="secondary" className="px-mr-10" onClick={() => handleCancel()}>
            {t("admin.pages.vendor.cancel")}
          </Button>
          <>
            {/* <Button 
              variant="secondary" 
              className="px-mr-10"
              disabled={isValidatedRequiredFields}>
              {t("admin.pages.vendor.skip")}
            </Button> */}
            <Button
              variant="primary"
              className={styles.btnNext}
              onClick={() => handleNext(1)}
              disabled={!valid}
              title={`${!valid ? "To continue, complete all required fields" : ""}`}
            >
              {t("admin.pages.vendor.next")}
            </Button>
          </>
        </Col>
      </Row>
    </>
  );
};

export default AddVendorDetails;
