import { GridApi } from "ag-grid-community";
import TooltipRender from "components/toolTip/tooltipRender";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { RootState, useTypedSelector } from "reducers";
import { StatementAccountType } from "services/admin/cards/payments/cardPayments.types";
import { CardPaymentsApis } from "services/admin/cards/payments/cardPaymentsApis";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { formattedAmount } from "services/general/helpers";
import { CardPaymentDetails } from "./cardPaymentDetails";
import { CardPayMyAccountForm } from "./cardPayMyAccount/cardPayMyAccountForm";
import styles from "./dashboard.module.css";
import { ListCardPaymentActivities } from "./listCardPaymentActivities";
import { ListCardPaymentMethods } from "./listCardPaymentMethods";

interface CardStatementPropsType {
  setStatementToPay: (value: StatementAccountType) => void;
  statement: StatementAccountType;
  currentUser?: IUser;
}
const CardStatement = ({ setStatementToPay, statement, currentUser }: CardStatementPropsType) => {
  const { id, account_code, credit_limit, due_date, statement_balance, currency_code } = statement;
  const [currentBalance, setCurrentBalance] = useState<number | undefined>();
  const getCurrentBalance = async () => {
    try {
      const res = await CardPaymentsApis.balanceSummary(id);
      setCurrentBalance(
        res.available_credit_inquiry_response.array_of_available_credit_inquiry_records.available_credit_inquiry_records
          .account_balance,
      );
    } catch (err) {}
  };

  useEffect(() => {
    getCurrentBalance();
  }, []);

  return (
    <>
      <Row className="mx-0 px-3 pt-2 pb-2">
        <Card className={`w-100 h-100 ${styles.accountCard}`}>
          <Row className={`mx-0 px-3 pb-2 align-items-center justify-content-between ${styles.sectionHeader}`}>
            <Col className="px-0">
              <span>Account Code: {account_code ?? "N/A"}</span>
            </Col>
            <Col className="px-0" style={{ flex: 0 }}>
              {statement.eligible_for_add_funds && (
                <div
                  onClick={() => setStatementToPay(statement)}
                  className={`border rounded btn py-1 px-3 ${styles.payAccountButton}`}
                >
                  Pay Account
                </div>
              )}
            </Col>
          </Row>
          <Row className="mx-0 px-3 py-2">
            <Table className={`${styles.accountTable} table-borderless mb-0`} responsive>
              <tbody>
                <tr>
                  <td className="col-4">
                    <Row className="mx-0">
                      <Col className={`px-0 ${styles.accountTableLastCol}`}>
                        <Row className="mx-0">
                          {currentBalance ? formattedAmount(`${currentBalance}`, currency_code, 2, true) : "N/A"}
                        </Row>
                        <Row className="mx-0 align-items-center">
                          <p className="pr-1">Current Balance</p>
                          <TooltipRender
                            placement="left"
                            title="Pay this to pay off your current balance. Pending transactions, fees, and interest charges may post and require additional payment."
                            className={`icon-info-blue ${styles.tooltip}`}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </td>
                  <td>
                    <Col className="px-0">
                      <Row className="mx-0">{companyDateFormat(due_date, currentUser)}</Row>
                      <Row className="mx-0">
                        <p>Payment Due Date</p>
                      </Row>
                    </Col>
                  </td>
                  <td>
                    <Col className="px-0">
                      <Row className="mx-0">
                        {credit_limit ? formattedAmount(`${credit_limit}`, currency_code, 2, true) : "N/A"}
                      </Row>
                      <Row className="mx-0">
                        <p>Credit Limit</p>
                      </Row>
                    </Col>
                  </td>
                  <td>
                    <Row className="mx-0 d-flex justify-content-end">
                      <Col className={`px-0 ${styles.accountTableLastCol}`}>
                        <Row className="mx-0">
                          {statement_balance ? formattedAmount(`${statement_balance}`, currency_code, 2, true) : "N/A"}
                        </Row>
                        <Row className="mx-0 align-items-center">
                          <p className="pr-1">Statement Balance</p>
                          <TooltipRender
                            placement="left"
                            title="Pay this every month by the due date to help avoid paying interest."
                            className={`icon-info-blue ${styles.tooltip}`}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Card>
      </Row>
    </>
  );
};

export const CardPaymentDashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [showPaymentID, setShowPaymentID] = useState<number | undefined>();
  const [statementToPay, setStatementToPay] = useState<StatementAccountType>();
  const [cardStatements, setCardStatements] = useState<StatementAccountType[]>([]);
  const [accountDisplayOptions, setAccountDisplayOptions] = useState<{ value: string; label: string }[]>([]);
  const currentUser = useTypedSelector((state: RootState) => state.user, shallowEqual) as IUser | undefined;
  const paymentActivitiesGridapiRef = useRef<GridApi>();
  const isMounting = useRef<boolean>(false);

  const getCardStatements = useCallback(async () => {
    try {
      const res = await CardPaymentsApis.statementAccounts();
      return res.statement_accounts.filter((item) => !!item && item.card_program_funding_type === "Credit");
    } catch (err) {
      return [];
    }
  }, []);

  const handlePayStatementRedirect = useCallback((statements: StatementAccountType[]) => {
    const accountCode = searchParams.get("accountCode");
    const action = searchParams.get("action");
    if (action === "pay" && accountCode) {
      setStatementToPay(statements.find((statement) => statement.account_code === accountCode));
      history.replace({ pathname: location.pathname, search: "" });
    }
  }, []);

  const onDashboardMount = useCallback(async () => {
    const statementAccounts = await getCardStatements();
    handlePayStatementRedirect(statementAccounts);
    setCardStatements(statementAccounts);
    setAccountDisplayOptions(
      statementAccounts.map((statement) => ({ label: statement.account_code, value: statement.account_code })),
    );
    isMounting.current = false;
  }, []);

  useEffect(() => {
    isMounting.current = true;
    onDashboardMount();
  }, []);

  useEffect(() => {
    if (!isMounting.current && !statementToPay) {
      paymentActivitiesGridapiRef.current?.refreshServerSide();
    }
  }, [!!statementToPay]);

  return (
    <Container className={`p-3 ${styles.mainStyle}`}>
      <Row className="mx-0 pb-3">
        <Card className="w-100 h-100 py-3">
          <Row className="mx-0 px-3">
            <span className={`${styles.sectionTitle}`}>Pay My Account</span>
          </Row>
          <Row className="border-bottom mx-0 py-2 mb-2"></Row>
          {cardStatements.map((statement) => (
            <CardStatement
              key={statement.account_code}
              statement={statement}
              setStatementToPay={setStatementToPay}
              currentUser={currentUser}
            />
          ))}
        </Card>
      </Row>

      <Row className="mx-0 pb-3">
        <Card className="w-100 h-100 py-3">
          <Row className={`mx-0 px-3 align-items-center justify-content-between ${styles.sectionHeader}`}>
            <Col className="px-0">
              <span className={`${styles.sectionTitle}`}>Payment Method</span>
            </Col>
            <Col className="px-0" style={{ flex: 0 }}>
              {/*
                    <Button variant = "secondary" className = "py-1 px-3 d-flex flex-row align-items-center">
                        <i className = {`icon-add-maroon ${styles.tooltip}`}></i>
                        <span className = "pl-2">Payment Method</span>
                    </Button>           
                */}
            </Col>
          </Row>
          <Row className="border-bottom mx-0 py-2 mb-2"></Row>

          <Row className="mx-0 px-3 py-2">
            <ListCardPaymentMethods />
          </Row>
        </Card>
      </Row>

      <Row className="mx-0 pb-3">
        <Card className="w-100 h-100 py-3">
          <Row className="mx-0 px-3">
            <span className={`${styles.sectionTitle}`}>Payment Activity</span>
          </Row>
          <Row className={`mx-0 px-3 ${styles.sectionHeader}`}>
            <p>
              View recent payment activity. If you have more than one account, you may view activity for individual
              accounts by using the dropdown.
            </p>
          </Row>
          <Row className="border-bottom mx-0 py-1"></Row>

          <Row className="mx-0 px-3 py-2">
            <ListCardPaymentActivities
              setShowPaymentID={setShowPaymentID}
              accountDisplayOptions={accountDisplayOptions}
              gridApiRef={paymentActivitiesGridapiRef}
            />
          </Row>
        </Card>
      </Row>

      {showPaymentID && <CardPaymentDetails paymentID={showPaymentID} close={() => setShowPaymentID(undefined)} />}
      {statementToPay && (
        <CardPayMyAccountForm
          initialValues={{
            payMyAccount: {
              step: "setup",
              statement: statementToPay,
              paymentAccounts: statementToPay.statement_payment_accounts,
              createdCardProgramTransaction: undefined,
              form: { card_program_id: statementToPay.card_program_id },
            },
          }}
          close={() => setStatementToPay(undefined)}
        />
      )}
    </Container>
  );
};
