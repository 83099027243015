import { RenderField, RenderTextArea } from "components/forms/bootstrapFields";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Field, Form, InjectedFormProps, getFormValues, reduxForm } from "redux-form";
import { required } from "services/validations/reduxFormValidation";
import styles from "./prompts.module.css";
import { useTypedSelector } from "reducers";
import { PromptsPropsType } from "./managePrompts";

const formName = "ManagePromptsForm";

type PromptsFormPropsTypes = {
  onSubmit: (prompt: PromptsPropsType) => void;
};

interface PromptReduxFormProps
  extends PromptsFormPropsTypes,
    InjectedFormProps<PromptsPropsType, PromptsFormPropsTypes> {}

const FormPrompt = ({ handleSubmit }: PromptReduxFormProps) => {
  const prompt: PromptsPropsType = useTypedSelector((state) => getFormValues(formName)(state));

  return (
    <Form onSubmit={handleSubmit}>
      <Container fluid={true} className={styles.addPanel}>
        <Row>
          <Col sm="3">
            <Field
              name="name"
              component={RenderField}
              validate={[required]}
              type="text"
              label="Name"
              className={`w-100`}
              required
            />
          </Col>
          <Col sm="4">
            <Field name="content" label="Description" rows={1} component={RenderTextArea} />
          </Col>
          <Col sm="2">
            <Button variant="primary" type="submit" className={styles.btnAdd}>
              <i className={`btn-icon icon-add-btn ${styles.addIcon}`}></i>
              {prompt?.id ? "Update" : "Add"}
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

const ConnectedFormPrompts = reduxForm<PromptsPropsType, PromptsFormPropsTypes>({
  form: formName,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(FormPrompt);

export default ConnectedFormPrompts;
