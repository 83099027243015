import React from "react";
import { Field, reduxForm } from "redux-form";
import { Row, Col } from "react-bootstrap";
import { ReportFormType } from "../../../../../services/admin/reports/reportTypes";
import { ReportSvc } from "../../../../../services/admin/reports/reportSvc";
import { RenderCheckBox } from "../../../../forms/bootstrapFields";

const CheckboxFilter = ({
  name,
  code,
  options,
  required,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  required?: boolean;
}) => {
  const isRequired = () => {
    if (required) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Row className="mx-0 py-1">
      <Col className="p-0">
        <Field
          name={`properties.filters.${code}`}
          label={`${name}`}
          component={RenderCheckBox}
          required={isRequired()}
        />
      </Col>
    </Row>
  );
};

export default CheckboxFilter;
