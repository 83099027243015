import i18next from "i18next";
import _ from "lodash";
import RestApi from "../../providers/restApi";
import { setSftpValidationLoading, setSftpValidationResponse } from "../../reducers/admin/integrationsReducer";
import accessToken from "../accessToken/accessTokenService";
import { CreateNotification, NotificationType } from "../general/notifications";

const restApiService = new RestApi();

export const oAuthDetails = async (system_name) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restApiService.get("integration_settings/" + system_name + "/oauth_details");
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

// https://appcenter.intuit.com/connect/oauth2?response_type=code&scope=com.intuit.quickbooks.accounting+openid&client_id=Q098POKVdo6o7JQFU8c6jXA6JI684axlr5GC82n0Xl0twODWmt&redirect_uri=localhost:9001/portal/oauth_process&state=acc8d71df2135ed028a361d999d4b375363002ada81dc8031e2c170cf2d274b6
export const getQbUrl = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await oAuthDetails("QB");
      let url =
        data.oauth_url +
        "?response_type=code&scope=" +
        data.scope +
        "&client_id=" +
        data.client_id +
        "&redirect_uri=" +
        // "http://localhost:9001/portal/oauth_process" +
        data.redirect_uri +
        "&state=" +
        accessToken.get();

      resolve(url);
    } catch (error) {
      reject(error);
    }
  });
};

// https://login.xero.com/identity/connect/authorize?response_type=code&client_id=YOURCLIENTID&redirect_uri=YOURREDIRECTURI&scope=openid profile email accounting.transactions&state=123
export const getXeroUrl = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await oAuthDetails("Xero");
      let url =
        data.oauth_url +
        "?response_type=code&scope=" +
        data.scope +
        "&client_id=" +
        data.client_id +
        "&redirect_uri=" +
        data.redirect_uri +
        "&state=" +
        accessToken.get();
      resolve(url);
    } catch (error) {
      reject(error);
    }
  });
};

// getBlackbaudUrl
export const getBlackbaudUrl = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await oAuthDetails("Blackbaud");
      let url =
        data.oauth_url +
        "?response_type=code&scope=" +
        data.scope +
        "&client_id=" +
        data.client_id +
        "&redirect_uri=" +
        data.redirect_uri +
        "&state=" +
        accessToken.get();
      resolve(url);
    } catch (error) {
      reject(error);
    }
  });
};

// get new integration records
export const getIntegrationSettings = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restApiService.get("integration_settings");
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchIntegrationDetail = async (id) => {
  try {
    const response = await restApiService.get("integration_settings/" + id);
    return response.data;
  } catch (error) {
    CreateNotification(i18next.t("error"), error.message, NotificationType.danger);
  }
};

export const fetchIntegrationProfile = async (id) => {
  try {
    const response = await restApiService.get("integration_settings/" + id + "/profile_detail");
    return response.data;
  } catch (error) {
    CreateNotification(i18next.t("error"), error.message, NotificationType.danger);
  }
};

export const updateIntegrationRecord = (integration_record) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restApiService.patch("integration_settings/" + integration_record.id, null, {
        integration_setting: { ...integration_record },
      });
      const integrationSettings = await getIntegrationSettings();
      resolve(integrationSettings);
    } catch (error) {
      reject(error);
    }
  });
};

export const addIntegrationRecord = (integration_record) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restApiService.post("integration_settings", null, {
        integration_setting: { ...integration_record },
      });
      const integrationSettings = await getIntegrationSettings();
      resolve(integrationSettings);
    } catch (error) {
      reject(error);
    }
  });
};

export const showInvoiceAutomationField = function (form) {
  return (
    _.isPlainObject(form) && form.application_type === "INVOICE_AUTOMATION" && form.authentication_method === "None"
  );
};

export const canShowPunchoutTestButton = function (form) {
  if (form.id) {
    return true;
  }
  return false;
};

export const showPullPushSetting = function (systemName, applicationType, allowedSystemNames, allowedApplicationTypes) {
  let allowForSystemName = false;
  let allowForApplicationType = false;

  if (!allowedSystemNames) {
    allowForSystemName = true;
  }
  if (allowedSystemNames && !_.isArray(allowedSystemNames)) {
    allowForSystemName = true;
  }
  if (allowedSystemNames && _.isArray(allowedSystemNames) && !allowedSystemNames.length) {
    allowForSystemName = true;
  }

  if (
    allowedSystemNames &&
    _.isArray(allowedSystemNames) &&
    allowedSystemNames.length > 0 &&
    allowedSystemNames.includes(systemName)
  ) {
    allowForSystemName = true;
  }

  if (!allowedApplicationTypes) {
    allowForApplicationType = true;
  }
  if (allowedApplicationTypes && !_.isArray(allowedApplicationTypes)) {
    allowForApplicationType = true;
  }
  if (allowedApplicationTypes && _.isArray(allowedApplicationTypes) && !allowedApplicationTypes.length) {
    allowForApplicationType = true;
  }

  if (
    allowedApplicationTypes &&
    _.isArray(allowedApplicationTypes) &&
    allowedApplicationTypes.length > 0 &&
    allowedApplicationTypes.includes(applicationType)
  ) {
    allowForApplicationType = true;
  }

  return allowForSystemName && allowForApplicationType;
};

export const checkSFTPValidation = (integrationData, dispatch) => {
  return new Promise(async (resolve, reject) => {
    if (integrationData && integrationData.id) {
      try {
        dispatch(setSftpValidationLoading(true));
        const response = await restApiService.get(
          "integration_settings/" + integrationData.id + "/sftp_connectivity_validation",
        );
        if (response.data && response.data.results) {
          dispatch(setSftpValidationLoading(false));
          CreateNotification("SFTP Validation", response.data.results, NotificationType.success);
          dispatch(
            setSftpValidationResponse({
              status: "success",
              message: response.data.results,
            }),
          );
          resolve(response);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.base) {
          CreateNotification("SFTP Validation", error.response.data.base, NotificationType.warning);
          dispatch(setSftpValidationLoading(false));
          dispatch(
            setSftpValidationResponse({
              status: "error",
              message: error.response.data.base,
            }),
          );
          reject(error);
        }
      }
    }
  });
};
