import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import restApi from "../../../../../providers/restApi";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Styles from "../../../../pickers/reduxFormPicker/override.module.css";
import { compare } from "../../../../../services/general/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";
import useIsMounted from "../../../../common/hooks/useIsMounted";
import _, { isArray, isPlainObject } from "lodash";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
} from "../../../../../services/general/helpers";
import { useTranslation } from "react-i18next";
const restApiService = new restApi();

type VendorInvoicesLkType = {
  id: number;
  number: string;
  date: string;
  due_date: string;
  amount: number;
  currency_code: string;
  status: string;
};
type InvoicePickerPropsType = {
  className?: string;
  filter: any;
  label?: string;
  required: boolean;
  input: {
    value: number | number[];
    onChange: (values: Array<number> | number) => void;
    onBlur: (values: Array<number> | number) => void;
  };
  tooltip?: ReactNode;
  isMulti?: boolean;
  purchaserId: number;
  callback: (values: Array<number> | number) => void;
  meta: {
    touched: boolean;
    error: string;
  };
};

const InvoicePicker = ({
  purchaserId,
  isMulti,
  filter,
  label,
  tooltip,
  required,
  input,
  meta: { touched, error },
}: InvoicePickerPropsType) => {
  const [invoiceList, setInvoiceList] = useState<Array<{ label: string; value: number }>>([]);
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const parseForSelect = (invoices: Array<VendorInvoicesLkType>) => {
    return invoices
      .map((invoice) => {
        return {
          value: invoice.id,
          label: invoice.number,
        };
      })
      .sort(compare);
  };

  const getVendorInvoices = async () => {
    let obj = { purchaser_id: purchaserId, ...filter };
    try {
      const response = await restApiService.get("vendor_invoices.lk", obj);
      const value = parseForSelect(response.data);
      if (isMounted) setInvoiceList(value);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (purchaserId && isMounted) {
      getVendorInvoices();
    }
  }, [purchaserId]);
  return (
    <>
      {isMulti && (
        <Form.Group>
          {label && (
            <label>
              {label}
              <Mandatory required={required} />
            </label>
          )}
          {tooltip}
          <Select
            {...input}
            required={required}
            value={findSelectedMultiple(input, invoiceList)}
            placeholder={t("search and select")}
            onChange={(value) => onChange(input, value)}
            onBlur={(value) => onBlur(input, input.value)}
            isClearable={false}
            options={invoiceList}
            isMulti={isMulti}
            classNamePrefix="select"
          />
          {error && touched && <span className="color-error">{error}</span>}
        </Form.Group>
      )}

      {!isMulti && (
        <Form.Group>
          {label && <label>{label}</label>}
          {tooltip}
          <Select
            {...input}
            required={required}
            value={findSelectedSingle(input, invoiceList)}
            placeholder={t("search and select")}
            onChange={(value) => onChangeSingle(input, value)}
            onBlur={() => onBlurSingle(input, input.value)}
            options={invoiceList}
            isMulti={isMulti}
            isClearable
            classNamePrefix="select"
          />
          {error && touched && <span className="color-error">{error}</span>}
        </Form.Group>
      )}
    </>
  );
};

export default InvoicePicker;
