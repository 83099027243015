import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import async from "react-select/dist/declarations/src/async/index";
import { VendorApis } from "../../../services/admin/vendors/vensorApis";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";
import FormVendor from "./form";
import { VendorType } from "../../../services/admin/vendors/vendorTypes";

const EditVendor = () => {
  const { id } = useParams<{ id: string }>();
  const [initialFormValues, setInitialFormValues] = useState({});
  const [loadVendorForm, setLoadVendorForm] = useState(false);

  const getVendorDetails = async () => {
    try {
      const response = await VendorApis.getVendor(id);
      setLoadVendorForm(true);
      if (response) {
        setInitialFormValues(response);
      }
    } catch (error) {
      console.error("Error occurred while fetching vendor data:", error);
    }
  };

  useEffect(() => {
    getVendorDetails();
  }, [id]);

  const handleAddVendor = (master?: VendorType) => {
    console.log("master: ", master);
  };

  const handleSubmit = async (vendorProp: any) => {
    console.log("vendorProp: ", vendorProp);
  };

  return (
    <ErrorBoundary>
      {/* {loadVendorForm && 
            <FormVendor
            onSubmit={handleSubmit}
            showSearchVendor={false}
            initialValues={initialFormValues}
            combineMasterDetailWithVendor={handleAddVendor}
            />
        }    */}
    </ErrorBoundary>
  );
};

export default EditVendor;
