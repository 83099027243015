import Panel from "components/common/panel/panel";
import { InputField } from "components/forms/hookFormFields";
import _ from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { IUser } from "services/common/user/userTypes";
import { AbstractListPickerTypes } from "../pickers/reactHookFormPickers/abstractListPicker/abstractListPickerTypes";
import AccountPicker from "../pickers/reactHookFormPickers/accountPicker";
import CategoryPicker from "../pickers/reactHookFormPickers/categoryPicker";
import ProjectPicker from "../pickers/reactHookFormPickers/projectPicker";
import { ProjectPickerTypes } from "../pickers/reactHookFormPickers/projectPicker/projectPickerTypes";
import TaxCodePicker from "../pickers/reactHookFormPickers/taxCodePicker";

const AccountEntry = ({
  index,
  label,
  showCategoryAtFirst,
  accountEntries,
  modelData: modelDataFieldName,
  modelName,
  isPoRequest,
}: {
  index: number;
  label: string;
  showCategoryAtFirst: boolean;
  accountEntries: any;
  modelData: string;
  modelName: string;
  isPoRequest: boolean;
}) => {
  const { getValues, setValue } = useFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const isHideProject = useWatch({ name: `${accountEntries}.${index}.is_hide_project` });
  const accountEntryAccountId = useWatch({ name: `${accountEntries}.${index}.account_id` });
  const accountEntryIsProjectRequired = useWatch({ name: `${accountEntries}.${index}.is_project_required` });

  const showProject = useMemo(() => {
    if (modelName === "payment" && label === "Debit Accounts") {
      return false;
    } else if (modelName === "purchase_order" && currentUser.company.purchase_order?.expenses?.project?.is_hide) {
      return false;
    }
    return true;
  }, [currentUser.company.purchase_order?.expenses?.project?.is_hide, label, modelName]);

  const isProjectRequired = useMemo(() => {
    const company = currentUser.company;
    // TODO: add prper types here
    const modelData: any = getValues(modelDataFieldName);
    return (
      accountEntryIsProjectRequired ||
      company?.virtual_card?.require_project ||
      (modelName === "purchase_order" && company.purchase_order?.expenses?.project?.is_required) ||
      (modelName === "payment" &&
        modelData.payment_type === "CREDIT" &&
        company.credit_memo?.expenses?.project?.is_required)
    );
  }, [accountEntryIsProjectRequired, currentUser.company, getValues, modelDataFieldName, modelName]);

  const inheritLinkedDataByProject = useCallback(
    (project: AbstractListPickerTypes.TPickerValue<ProjectPickerTypes.TProjectPickerOption>) => {
      const lineObject = getValues(`${accountEntries}.${index}`);
      if (project && lineObject && _.isPlainObject(project) && _.isPlainObject(lineObject)) {
        //to check project has mapping of accounts or not
        if (project.has_accounts || lineObject?.project?.has_accounts) {
          lineObject.project_id = project.id;
          lineObject.project = project;
          lineObject.only_show_mapped_account = true;

          setValue(`${accountEntries}.${index}`, lineObject);
        }
      }
    },
    [accountEntries, getValues, index, setValue],
  );

  const getTotalDropDown = useMemo(() => {
    var total = 0;
    total += currentUser.company.has_locations ? 1 : 0;
    total += currentUser.company.has_departments ? 1 : 0;
    total += currentUser.company.has_business_units ? 1 : 0;
    total += currentUser.company.has_projects ? 1 : 0;
    total += currentUser.company.has_categories ? 1 : 0;
    total += currentUser.company.has_grants ? 1 : 0;
    return total;
  }, [
    currentUser.company.has_business_units,
    currentUser.company.has_categories,
    currentUser.company.has_departments,
    currentUser.company.has_grants,
    currentUser.company.has_locations,
    currentUser.company.has_projects,
  ]);

  const getDropdownColumnClass = useMemo(() => {
    let cls = "col-md-3";
    let c = getTotalDropDown;
    if (c === 4) {
      cls = "col-md-3";
    } else if (c === 3 || c === 5) {
      cls = "col-md-4";
    } else if (c === 2) {
      cls = "col-md-6";
    } else if (c === 1) {
      cls = "col-md-12";
    }
    return cls;
  }, [getTotalDropDown]);

  const isPaymentDebit = useMemo(() => {
    const modelData = getValues(modelDataFieldName);
    return modelData?.payment_type === "DEBIT";
  }, [getValues, modelDataFieldName]);

  return (
    <Panel
      header={
        <Row>
          <Col sm={11}>{label}</Col>
          <Col sm={1} className="px-pl-0">
            <Button
              // onClick={() => duplicateAccountEntry(index)}
              className="bg-transparent border-0 m-0 p-0 justify-content-end"
            >
              <i className="icon duplicate m-0" />
            </Button>
            <Button
              // onClick={() => deleteAccountEntry(index)}
              className="bg-transparent border-0 m-0 p-0 px-pl-15"
            >
              <i className="icon icon-delete m-0" />
            </Button>
          </Col>
        </Row>
      }
    >
      {/* ALl pickers will come here */}
      <Container fluid>
        <Row>
          {/* //TODO-SANKET : ADD VENDOR BY NAME */}
          {/* {    <div className="mb-3">
          <VendorPicker
            name={`${accountEntries}.${index}.vendor_name`}
            label={"Vendor"}
            excludeStatuses="INACTIVE"
            callBack={(selectedVendor) => handleVendorChange(selectedVendor)}
          />
        </div>} */}

          {showCategoryAtFirst && (
            <Col sm="3">
              <CategoryPicker
                name={`${accountEntries}.${index}.category_id`}
                required={currentUser?.company?.po_request?.expenses?.category?.is_required}
                label="Category"
                modelData={`${accountEntries}.${index}`}
                parentObj={modelDataFieldName}
              />
            </Col>
          )}
          {!showCategoryAtFirst && (
            <Col sm="3">
              <AccountPicker
                name={`${accountEntries}.${index}.category_id`}
                required
                label="Account"
                modelData={`${accountEntries}.${index}`}
                parentObj={modelDataFieldName}
              />
            </Col>
          )}
          <Col sm="2">
            <TaxCodePicker
              id="po_expense_tax_code_id"
              name={`${accountEntries}.${index}.tax_id`}
              modelData={`${accountEntries}.${index}`}
              label="Tax Code"
              // required={currentUser.company?.invoice?.is_tax_code_required_on_line_level}
              // callBack={(taxCode: TTaxCodeProps) => {
              //   calculateTax(index);
              // }}
            />
          </Col>
          <Col sm="1">
            <InputField
              name={`${accountEntries}.${index}.tax`}
              type="number"
              label="Tax"
              readOnly={!currentUser.company.enable_to_enter_tax_amount}
              // onChange={(e) => {
              //   onTaxAmountChange({ tax: Number(e.target.value), index });
              // }}
            />
          </Col>
          <Col sm="2">
            <InputField
              name={`${accountEntries}.${index}.sub_amount`}
              type="number"
              label="Sub Amount"
              required={accountEntryAccountId ? true : false}
              // onChange={(e) => {
              //   onTaxAmountChange({ tax: Number(e.target.value), index });
              // }}
            />
          </Col>
          <Col sm="2">
            <InputField label={"Amount"} name={`debit_entries_attributes.${index}.amount`} type="number" disabled />
          </Col>
        </Row>
        <Row>
          {showProject && currentUser.company.has_projects && !isHideProject && (
            <Col className={getDropdownColumnClass}>
              <ProjectPicker
                instanceId={`${accountEntries}.${index}.project`}
                label="Project"
                name={`${accountEntries}.${index}.project_id`}
                required={isProjectRequired}
                modelData={`${accountEntries}.${index}`}
                isPoRequest={isPoRequest}
                parentObj={modelDataFieldName}
                callBack={inheritLinkedDataByProject}
                // disabled={} TODO: add disabled code here
              />
            </Col>
          )}

          {currentUser.company.has_projects && currentUser.company.allow_for_project && !isPaymentDebit && (
            <>
              <Col className={getDropdownColumnClass}>
                <ProjectPicker
                  instanceId={`${accountEntries}.${index}.for_project`}
                  label="For Project"
                  name={`${accountEntries}.${index}.for_project_id`}
                  modelData={`${accountEntries}.${index}`}
                  placeholder="-- For Project --"
                  isForProject
                  // disabled={} TODO: add disabled code here
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
      {/* -------------------------- */}
    </Panel>
  );
};

export default memo(AccountEntry);
