import React, { Component, useState } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTypedSelector } from "reducers";
import "../../pages/pages.style.css";
import RestApi from "../../providers/restApi";
import { Can } from "../../services/authorization/authorization";
import "./navigation.style.css";

export default class TabNavigation extends Component {
  render() {
    let navigationTab = this.props.navigationTab;
    return (
      <div className={"tabNavigationContainer"}>
        <ul className="nav nav-tabs border-0 tabNavigation">
          {navigationTab.map((tab, index) => {
            return (
              !tab.hide &&
              (tab.authorization ? (
                <Can
                  key={tab.key ? tab.key : tab.pageName}
                  I={tab.authorization.I}
                  a={tab.authorization.a}
                  permissions={tab.authorization.permissions}
                  permissionExcept={tab.authorization.permissionExcept}
                >
                  <ListTabs key={tab.pageName} tab={tab} {...this} />
                </Can>
              ) : (
                <ListTabs key={tab.key ? tab.key : tab.pageName} tab={tab} {...this} />
              ))
            );
          })}
        </ul>
      </div>
    );
  }
}

const ListTabs = (props) => {
  const { tab } = props;
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = useTypedSelector((state) => state.user);

  const isTabActive = () => {
    let active = tab.isActive ? tab.isActive : false;
    // as multiple places isActive is used
    // in feature will not used it
    if (props.props.activePageName && tab.pageName === props.props.activePageName) {
      active = true;
    }
    return active;
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const renderNavTab = () => {
    if (!tab.subPages) {
      return true;
    }
    // if dropdown parent tab has subPages, check if any of the subPages are accessible by the user
    // if not, no reason to render parent tab
    return !tab.subPages.every(
      (subPage) =>
        !subPage.authorization.permissions.some((permission) => currentUser.perm_resources.includes(permission)),
    );
  };

  return (
    renderNavTab() && (
      <li key={tab.key ? tab.key : tab.pageName} className="nav-item mx-4" style={{ position: "relative" }}>
        {!tab.href ? (
          tab.subPages ? (
            <div>
              <div className="d-flex">
                <NavLink
                  to={tab.path}
                  onClick={toggleDropdown}
                  className={`routeContainer ${isTabActive() ? "active" : ""}`}
                >
                  {tab.pageName}
                  {tab.highlightLabel && <span className="">{tab.highlightLabel}</span>}
                </NavLink>
                <span className="dropdown-arrow" onClick={toggleDropdown}>
                  {isOpen ? "▲" : "▼"}
                </span>
              </div>
              {isOpen && (
                <div className="dropdown-content">
                  {tab.subPages.map((subPage) => (
                    <Can
                      key={subPage.key ? subPage.key : subPage.pageName}
                      I={subPage.authorization.I}
                      a={subPage.authorization.a}
                      permissions={subPage.authorization.permissions}
                      permissionExcept={subPage.authorization.permissionExcept}
                      requirePermissionsAndIa={requiresPermissionsAndIa(subPage.path)}
                    >
                      <NavLink
                        key={subPage.key ? subPage.key : subPage.pageName}
                        exact
                        to={subPage.path}
                        className={`dropdown-content-item ${isTabActive() ? "active" : ""}`}
                        replace={true}
                        data-toggle="tooltip"
                      >
                        <Nav.Item className={[props.sidemenuWidth]}>
                          {subPage.pageName}
                          {subPage.highlightLabel && <span className="">{subPage.highlightLabel}</span>}
                        </Nav.Item>
                      </NavLink>
                    </Can>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <NavLink
              key={tab.key ? tab.key : tab.pageName}
              exact
              to={tab.path}
              className={`routeContainer ${isTabActive() ? "active" : ""}`}
              replace={true}
              data-toggle="tooltip"
            >
              <Nav.Item className={[props.sidemenuWidth]}>
                {tab.pageName}
                {tab.highlightLabel !== undefined && (
                  <span className={`new-section-highlight-label`}>{tab.highlightLabel}</span>
                )}
              </Nav.Item>
            </NavLink>
          )
        ) : (
          <a
            key={tab.key ? tab.key : tab.pageName}
            className={`routeContainer ${isTabActive() ? "active" : ""}`}
            href={new RestApi().angularBaseURL() + tab.href}
          >
            {tab.pageName}
          </a>
        )}
      </li>
    )
  );
};

export const requiresPermissionsAndIa = (currentPagePath) => {
  // add pages that require both user permissions and Ia here
  switch (currentPagePath) {
    case "/reports/data_extracts/invoice":
      return true;
    default:
      return false;
  }
};
