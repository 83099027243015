import React, { Component } from "react";
import RestApi from "../../../../providers/restApi";
import { withTranslation } from "react-i18next";
import { PlaidLink } from "react-plaid-link";
import { Table, Row, Col } from "react-bootstrap";
import Loader from "../../../spinners/loader";

class PlaidForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showMicroDepositMessage: false,
    };
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.getParsedAccounts();
  }

  onSuccess = (public_token, metadata) => {
    // Send the public_token to your app server.
    // The metadata object contains info about the institution the
    // user selected and the account ID or IDs, if the
    // Select Account view is enabled.
    this.setState({ loaded: false });

    this.restApiService
      .post("plaid/exchange_link_token", null, {
        public_token: public_token,
        institution: metadata.institution,
        accounts: metadata.accounts,
      })
      .then((result) => {
        if (
          metadata.accounts &&
          metadata.accounts.length > 0 &&
          (metadata.accounts[0].verification_status == "pending_automatic_verification" ||
            metadata.accounts[0].verification_status == "pending_manual_verification")
        ) {
          this.setState({ loaded: true, showMicroDepositMessage: true });
        } else {
          this.getParsedAccounts();
        }
      })
      .catch((error) => {
        console.error(error);
      });

    this.restApiService
      .post("debug_logs", null, {
        debug_log: {
          application: "VENDOR_PORTAL",
          result: metadata,
          action: "Plaid Link",
          sub_action: "onSuccess",
        },
      })
      .catch((error) => {});
  };

  onExit = (err, metadata) => {
    // The user exited the Link flow.
    // metadata contains information about the institution
    // that the user selected and the most recent API request IDs.
    // Storing this information can be helpful for support.
    // if (metadata && metadata.status == "institution_not_found") {
    // }else if(metadata && metadata.status == "connected"){
    // }
    if (metadata) {
      this.restApiService
        .post("debug_logs", null, {
          debug_log: {
            application: "VENDOR_PORTAL",
            result: metadata,
            action: "Plaid Link",
            sub_action: "onExit",
          },
        })
        .catch((error) => {});
    }
  };

  onEvent(eventName, metadata) {
    // Optionally capture Link flow events, streamed through
    // this callback as your users connect an Item to Plaid.
    // For example:
    // eventName = "TRANSITION_VIEW"
    // metadata  = {
    //   link_session_id: "123-abc",
    //   mfa_type:        "questions",
    //   timestamp:       "2017-09-14T14:42:19.350Z",
    //   view_name:       "MFA",
    // }
  }

  getLinkToken = (accountId = null) => {
    this.restApiService
      .get("plaid/create_link_token", { transactions: false, account_id: accountId, vendor_portal: true })
      .then((result) => {
        this.setState({ linkToken: result.data.link_token, loaded: true });
      })
      .catch((error) => {
        console.error(error);
        this.props.emergencySwitch();
      });
  };

  getParsedAccounts = () => {
    this.restApiService
      .get("plaid/parsed_accounts")
      .then((result) => {
        this.setState({ accounts: result.data.accounts, loaded: true });
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error == "PRODUCT_NOT_READY") {
          if (error.response.data.status == "pending_manual_verification") {
            this.getLinkToken(error.response.data.account_id);
          } else {
            this.setState({ loaded: true, showMicroDepositMessage: true });
          }
        } else {
          //no accounts, get link token instead
          this.getLinkToken();
        }
      });
  };

  // Returns an account in the form that accrualify accepts
  parseAccountForAccrualify = (account) => {
    let address = this.getAddress(account);
    return {
      payment_type: "ach",
      routing: account.number.routing,
      account_name: account.account.official_name,
      account_number: account.number.account,
      paymentConfirmAccountNumber: account.number.account,
      account_type: account.account.subtype,
      bank_name: account.institution_name,
      address1: address.address1,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      plaid_account_id: account.account.account_id,
    };
  };

  //returns an address in the form that accrualify accepts
  getAddress(account) {
    let address = {};
    if (account.identity && account.identity.owners && account.identity.owners.length > 0) {
      if (account.identity.owners[0].addresses && account.identity.owners[0].addresses.length > 0) {
        address = {
          city: account.identity.owners[0].addresses[0].data.city,
          country: account.identity.owners[0].addresses[0].data.country,
          zipcode: account.identity.owners[0].addresses[0].data.postal_code,
          state: account.identity.owners[0].addresses[0].data.region,
          address1: account.identity.owners[0].addresses[0].data.street,
        };
      }
    }
    return address;
  }

  render() {
    return (
      <Loader className="mb-4 mt-4" loaded={this.state.loaded}>
        <Row>
          <Col>
            {this.state.linkToken && !this.state.accounts && !this.state.showMicroDepositMessage && (
              <Row>
                <Col>
                  <h5>You will be taken to a 3rd party to verify your account information.</h5>
                  <PlaidLink
                    token={this.state.linkToken}
                    onSuccess={this.onSuccess}
                    onExit={this.onExit}
                    onEvent={this.onEvent}
                    className="btn btn-primary"
                  >
                    <span style={{ color: "white", backgroundColor: "#468BBA", padding: "10px" }}>Verify Account</span>
                  </PlaidLink>
                </Col>
              </Row>
            )}

            {this.state.accounts && this.state.accounts.length > 0 && (
              <Row>
                <Col>
                  <h5>We found these bank accounts. Please choose the account you would like to link</h5>
                  <Table striped bordered hover className="mb-0 bg-white">
                    <thead>
                      <tr>
                        <th className="primaryFontColor">Name</th>
                        <th className="primaryFontColor">Official Name</th>
                        <th className="primaryFontColor">Account Number</th>
                        <th className="primaryFontColor">Routing Number</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.accounts.map((data) => {
                        return (
                          <tr key={data.account.account_id}>
                            <td>{data.account.name}</td>
                            <td>{data.account.official_name}</td>
                            <td>{data.number ? data.number.account : null}</td>
                            <td>{data.number ? data.number.routing : null}</td>
                            <td className="text-center">
                              <button
                                type="button"
                                onClick={() => {
                                  this.props.accountChose(this.parseAccountForAccrualify(data));
                                }}
                                className="btn btn-primary"
                              >
                                Choose
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}

            {this.state.showMicroDepositMessage && (
              <h4>Your account is being verified. Please check back in 1-2 days.</h4>
            )}
          </Col>
        </Row>
      </Loader>
    );
  }
}

export default withTranslation()(PlaidForm);
