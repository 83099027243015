import React from "react";

const ObjectStatus = (props) => {
  let color;
  let icon;
  let status = props.status ? props.status.toLowerCase() : ""; //initialize to prevent null exception
  switch (status) {
    case "pending":
      color = "text-warning";
      icon = "pendingStatusIcon";
      break;
    case "warning":
      color = "text-warning";
      icon = "pendingStatusIcon";
      break;
    case "rejected":
      color = "text-danger";
      icon = "rejectedStatusIcon";
      break;
    case "approved":
      color = "text-success";
      icon = "approvedStatusIcon";
      break;
    case "failed":
      color = "text-danger";
      icon = "rejectedStatusIcon";
      break;
    case "void":
      color = "text-danger";
      icon = "rejectedStatusIcon";
      break;
    default:
      color = "text-success";
      icon = "approvedStatusIcon";
  }
  return (
    <h5 className={"d-inline ml-3 d-flex justify-content-center align-items-center " + color} style={props.style}>
      <i className={`icon ${icon}`}></i>
      <span className={props.statusClass}>{status.toUpperCase()}</span>
    </h5>
  );
};

export default ObjectStatus;
