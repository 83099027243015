import React, { Component } from "react";
import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import { countries } from "components/app.svc.Lookup";
import { withTranslation } from "react-i18next";

const filterOption = (option, searchValue) => {
  const loweredSearch = searchValue.toLowerCase();
  return (
    option.data.name.toLowerCase().includes(loweredSearch) || option.data.value.toLowerCase().includes(loweredSearch)
  );
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */
class CountryPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: countries
        .map((item, i) => {
          let flag = null;
          if (item.flag) {
            //label key contains the name of the country
            flag = (
              <img
                src={require(`../../../../../assets/flags/${item.flag.toLowerCase()}.svg`)}
                height="30px"
                width="30px"
                style={{ padding: 5 }}
                alt="flag"
              />
            );
          }
          return {
            value: item.value,
            code: item.value,
            name: item.label,
            label: (
              <div>
                {flag}&nbsp;&nbsp;{item.label}
              </div>
            ),
          };
        })
        .filter((value) => Boolean(value) === true),
    };
  }

  findDefault = () => {
    let found = {};
    this.state.countries.forEach((country) => {
      if (country.code === this.props.country) {
        found = country;
      }
    });
    return found;
  };

  findSelected() {
    if (!this.state.countries || !this.props.selected) {
      return null;
    }

    return this.state.countries.find((option) => {
      if (option.code === this.props.selected.value) {
        return option;
      }
      return null;
    });
  }

  render() {
    return (
      <Form.Group as={Col} md={this.props.colSize || "4"}>
        <Form.Label style={{ textTransform: "capitalize" }}>
          {this.props.label || this.props.t("country")}:{this.props.required && <span className={"color-red"}>*</span>}
        </Form.Label>
        <Select
          value={this.findSelected()}
          defaultValue={this.findDefault()}
          styles={{
            valueContainer: (base) => ({
              ...base,
              height: 40,
              minHeight: 40,
            }),
          }}
          placeholder={"search/select"}
          options={this.state.countries}
          onChange={(e) => {
            if (this.props.countryPickerCallback) {
              this.props.countryPickerCallback({ value: e.code });
            }
          }}
          filterOption={filterOption}
        />
        <Form.Control.Feedback type="invalid">{this.props.t("validation.country")}</Form.Control.Feedback>
      </Form.Group>
    );
  }
}

export default withTranslation()(CountryPicker);
