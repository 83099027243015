import CustomModal from "components/modals/customModal";
import RestApi from "providers/restApi";
import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";

type TSgdBankCodeProps = {
  showModal: boolean;
  currencyCode: string;
  onClose: () => void;
};

type TBankCodeItem = {
  key: string;
  name: string;
};

type TField = {
  name: string;
  group: TGroupItem[];
};

type TGroupItem = {
  key: string;
  valuesAllowed: TBankCodeItem[];
};

type TSingaporeItem = {
  type: string;
  fields: TField[];
};

const SgdBankCode: React.FC<TSgdBankCodeProps> = ({ showModal, currencyCode, onClose }) => {
  const [bankCodeList, setBankCodeList] = useState<TBankCodeItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const restApi = new RestApi();

  const fetchBankCodes = async () => {
    setLoading(true);
    try {
      const response = await restApi.get(`payment_methods/required_fields`, {
        source_currency_code: "USD",
        target_currency_code: currencyCode,
        type: "transferwise",
      });

      if (Array.isArray(response.data)) {
        const singaporeItem = response.data.find((item: TSingaporeItem) => item.type === "singapore") || {};

        const bankNameField = singaporeItem.fields?.find((field: TField) => field.name === "Bank name");

        const bankCodeGroup = bankNameField?.group?.find((group: TGroupItem) => group.key === "bankCode") || {};

        const filteredBankCodes = Array.isArray(bankCodeGroup.valuesAllowed)
          ? bankCodeGroup.valuesAllowed.filter((item: TBankCodeItem) => item.key !== "")
          : [];

        setBankCodeList(filteredBankCodes);
      } else {
        setError("Invalid response format.");
      }
    } catch (err) {
      setError("Failed to fetch bank codes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      fetchBankCodes();
    }
  }, [showModal, currencyCode]);

  return (
    <CustomModal
      size="lg"
      header="Bank Code List"
      show={showModal}
      onHide={onClose}
      body={
        <div style={{ height: "550px", overflowY: "scroll" }}>
          <Table striped bordered hover style={{ textAlign: "left" }}>
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th>Sr. No</th>
                <th>Bank Name</th>
                <th>Bank Code</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={3} style={{ textAlign: "center" }}>
                    <Spinner animation="border" variant="secondary" size="sm" /> loading bank code list
                  </td>
                </tr>
              ) : bankCodeList.length > 0 ? (
                bankCodeList.map((code, index) => (
                  <tr key={code.key}>
                    <td>{index + 1}</td>
                    <td>{code.name}</td>
                    <td>{code.key}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} style={{ textAlign: "center" }}>
                    There is no bank code list
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      }
      footer={
        <div className="mx-auto">
          <button onClick={onClose}>Close</button>
        </div>
      }
    />
  );
};

export default SgdBankCode;
