import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { GridApi } from "ag-grid-community";

type QuickFilterProps = {
  gridApi?: GridApi;
};

const QuickFilter = ({ gridApi }: QuickFilterProps) => {
  const needsAction = () => {
    clearFilters();
    if (gridApi) {
      const filterInstance = gridApi.getFilterInstance("status");
      if (filterInstance) {
        filterInstance.setModel({
          filterType: "text",
          type: "equals",
          filter: "NEW",
        });
      }
      const hasViolationsFilter = gridApi.getFilterInstance("has_violations");
      if (hasViolationsFilter) {
        hasViolationsFilter.setModel({
          filterType: "text",
          type: "equals",
          filter: true,
        });
      }
      const unlinkedExpenses = gridApi.getFilterInstance("expense_report.number");
      if (unlinkedExpenses) {
        unlinkedExpenses.setModel({
          filterType: "text",
          type: "blank",
        });
      }
      gridApi.onFilterChanged();
    }
  };

  const notLinkedExpenseReport = () => {
    clearFilters();
    if (gridApi) {
      const filterInstance = gridApi.getFilterInstance("expense_report.number");
      if (filterInstance) {
        filterInstance.setModel({
          filterType: "text",
          type: "blank",
        });
      }
      gridApi.onFilterChanged();
    }
  };

  const hasViolation = () => {
    clearFilters();
    if (gridApi) {
      const hasViolationsFilter = gridApi.getFilterInstance("has_violations");
      if (hasViolationsFilter) {
        hasViolationsFilter.setModel({
          filterType: "text",
          type: "equals",
          filter: true,
        });
      }
      gridApi.onFilterChanged();
    }
  };

  const missingReceipts = () => {
    clearFilters();
    if (gridApi) {
      const hasAssetFilter = gridApi.getFilterInstance("has_assets");
      if (hasAssetFilter) {
        hasAssetFilter.setModel({
          filterType: "text",
          type: "equals",
          filter: "false",
        });
      }
      gridApi.onFilterChanged();
    }
  };

  const clearFilters = () => {
    if (gridApi) {
      gridApi.setFilterModel(null);
      gridApi.onFilterChanged();
    }
  };

  return (
    <>
      <Dropdown.Item onClick={() => needsAction()}>Needs Action</Dropdown.Item>
      <Dropdown.Item onClick={() => notLinkedExpenseReport()}>Not Linked to Expense Report</Dropdown.Item>
      <Dropdown.Item onClick={() => hasViolation()}>Has Violations</Dropdown.Item>
      <Dropdown.Item onClick={() => missingReceipts()}>Missing Receipt</Dropdown.Item>
    </>
  );
};

export default QuickFilter;
