import React from "react";
import VendorInfo from "./finalReviewSections/vendorInfo";
import VendorAdditionalInfo from "./finalReviewSections/vendorAdditionalInfo";
import { useTypedSelector } from "../../../../reducers";
import { Field, change, getFormValues } from "redux-form";
import styles from "../vendors.module.css";
import VendorContactInfo from "./finalReviewSections/vendorContactInfo";
import PaymentMethodInfo from "./finalReviewSections/paymentMethodInfo";
import DocumentsRequestedInfo from "./finalReviewSections/documentsRequestedInfo";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { renderTextareaField } from "../../../forms/inputFields";
import VendorFormCheckboxList from "./finalReviewSections/vendorFormCheckboxList";
import { useDispatch } from "react-redux";
import { CompanyType } from "services/common/user/userTypes";
import AdditionalCodingFieldInfo from "./finalReviewSections/additionalCodingFieldInfo";

type FinalReviewPropsType = {
  handleEditVendorInfo: (step: number) => void;
  handleCancel: () => void;
  currentUserCompany?: CompanyType;
  showAdditionalCodingField?: boolean;
};

type VendorType = {
  name?: string;
  legal_name?: string;
  vendor_id?: string | number;
  master_id?: string | number;
  status?: string;
  email?: string;
  website?: string;
  vendor_classification_id?: string;
  vendor_type?: string;
  phone?: string;
  assets_attributes?: {
    asset_file?: any;
    file_name?: any;
  };
  additional_currency_codes?: {
    label?: string;
    name?: string;
    symbol?: string;
    value?: string;
  }[];
  address?: {
    address_type?: string;
    attention?: string;
    label?: string;
    address1?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
  };
  contact?: {
    first_name?: string;
    last_name?: string;
    phone?: string;
    email?: string;
  };
  payment_methods_attributes?: {};
};

const FinalReview = ({
  showAdditionalCodingField,
  handleEditVendorInfo,
  handleCancel,
  currentUserCompany,
}: FinalReviewPropsType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const vendor: VendorType = useTypedSelector((state) => getFormValues("VendorForm")(state));

  const isNvpCustomer = currentUserCompany?.accepted_payment_methods?.corpay;
  return (
    <>
      <div className={`px-mb-25 ${styles.infoSectionContainer}`}>
        <VendorInfo vendor={vendor} handleEdit={handleEditVendorInfo} />
      </div>
      <div className={`px-mb-25 ${styles.infoSectionContainer}`}>
        <VendorAdditionalInfo vendor={vendor} handleEdit={handleEditVendorInfo} />
      </div>
      {showAdditionalCodingField && (
        <div className={`px-mb-25 ${styles.infoSectionContainer}`}>
          <AdditionalCodingFieldInfo vendor={vendor} handleEdit={handleEditVendorInfo} />
        </div>
      )}
      <div className={`px-mb-25 ${styles.infoSectionContainer}`}>
        <VendorContactInfo contact={vendor.contact} handleEdit={handleEditVendorInfo} />
      </div>
      {!isNvpCustomer && (
        <div className={`px-mb-25 ${styles.infoSectionContainer}`}>
          <PaymentMethodInfo
            paymentMethodsAttributes={vendor.payment_methods_attributes}
            handleEdit={handleEditVendorInfo}
          />
        </div>
      )}

      <div className={`px-mb-25 ${styles.infoSectionContainer}`}>
        <DocumentsRequestedInfo
          assetsAttributes={vendor.assets_attributes}
          handleEdit={handleEditVendorInfo}
          currentUserCompany={currentUserCompany}
        />
      </div>
      <Row className="px-mt-25">
        <Col sm="12">
          <Field
            name="comments"
            label={t("admin.pages.vendor.notes")}
            style={{ height: "150px" }}
            component={renderTextareaField}
            type="text"
          />
        </Col>
      </Row>
      <VendorFormCheckboxList vendor={vendor} />
      <Row className="px-mt-25">
        <Col md={5}>
          {/* <Button variant="secondary" className="px-mr-10" onClick={() => handleNext(4)}>
            {t("admin.pages.vendor.back")}
          </Button> */}
        </Col>
        <Col md={{ span: 4, offset: 8 }} className={styles.btnColumn}>
          <Button variant="secondary" className="px-mr-10" onClick={() => handleCancel()}>
            {t("admin.pages.vendor.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => dispatch(change("VendorForm", "isCompletedVendorForm", true))}
            className={styles.btnNext}
            type="submit"
          >
            {t("admin.pages.vendor.submit")}
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default FinalReview;
