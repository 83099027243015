// Payment Methods By Vendor Section
import React, { Fragment, useEffect, useMemo, useState } from "react";
import styles from "./dashboard.module.css";
import Panel from "components/common/panel/panel";
import { Table } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { formattedAmount } from "services/general/helpers";
import { restApiService } from "providers/restApi";
import { getUrlLocator } from "services/common/urlHelperSvc";
import PopoverRender from "components/common/popover/popoverRender";
import PaymentsApis from "services/admin/payments/paymentsApis";
import { PaymentTypes } from "services/admin/payments/paymentsType";
import _ from "lodash";

type AmountSplitPopoverPropsType = {
  splitAmounts: any;
  currencyCode?: string;
};
const AmountSplitPopover = ({ splitAmounts, currencyCode }: AmountSplitPopoverPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  return (
    <Table size="sm" bordered responsive>
      <thead>
        <tr>
          {!currentUser?.company?.is_nvp_credit_model && <th>Mastercard</th>}
          <th>ACH</th>
          <th>Check</th>
          <th>CorpayCard</th>
          <th>International</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {!currentUser?.company?.is_nvp_credit_model && (
            <td>{formattedAmount(splitAmounts?.credit_card?.toString(), currencyCode)}</td>
          )}
          <td>{formattedAmount(splitAmounts?.ach?.toString(), currencyCode)}</td>
          <td>{formattedAmount(splitAmounts?.check?.toString(), currencyCode)}</td>
          <td>{formattedAmount(splitAmounts?.corpay_card?.toString(), currencyCode)}</td>
          <td>{formattedAmount(splitAmounts?.international?.toString(), currencyCode)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const EstimatedPaymentDates = () => {
  const [estimateDebitDatesData, setEstimatedDebitDatesData] = useState<PaymentTypes.ListsEstimateDebitDates>();
  const [headerPayments, setHeaderPayments] = useState<string[]>([]);
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const calculateUniqueHeader = (response: PaymentTypes.ListsEstimateDebitDates) => {
    let obj: { [key: string]: number } = {};
    if (_.isPlainObject(response)) {
      for (let dates in response) {
        if (_.isPlainObject(response[dates])) {
          for (let payments in response[dates]) {
            if (_.isPlainObject(response[dates][payments]) && !obj[payments]) {
              obj[payments] = 1;
            }
          }
        }
      }

      setHeaderPayments(Object.keys(obj));
    }
  };
  const getEstimateDates = async () => {
    try {
      const response: PaymentTypes.ListsEstimateDebitDates = await PaymentsApis.getEstimatedDebitDateList();
      calculateUniqueHeader(response);
      setEstimatedDebitDatesData(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEstimateDates();
  }, []);

  const estimatedDates = useMemo(() => {
    return estimateDebitDatesData
      ? Object.keys(estimateDebitDatesData).filter((estimatedDate) =>
          _.isPlainObject(estimateDebitDatesData[estimatedDate]),
        )
      : [];
  }, [estimateDebitDatesData]);
  return (
    <Panel
      header={
        <div className={styles.panelTitle}>
          <i className={`icon icon-calender-blue px-mt-3 px-mr-10`}></i>Estimated Debit Dates
        </div>
      }
      cardHeaderClass={styles.noPadding}
    >
      <div className={styles.estimatedDateTable}>
        <Table bordered responsive>
          <thead>
            <tr className={styles.estimateDateHeader}>
              <th className="text-left">Estimated Debit Date</th>
              {headerPayments.map((headerPayment, index) => (
                <th key={index} className="text-left">
                  {headerPayment}
                </th>
              ))}
              <th className="text-right">Total Amount</th>
              <th className="text-left">Payment Run Number</th>
            </tr>
          </thead>
          <tbody>
            {(!estimatedDates || (_.isArray(estimatedDates) && estimatedDates.length == 0)) && (
              <tr>
                <td colSpan={4}>No Record Found</td>
              </tr>
            )}

            {_.isArray(estimatedDates) &&
              estimatedDates.map((date) => (
                <tr key={date}>
                  <td className="text-left">{companyDateFormat(date, currentUser, true)}</td>
                  {headerPayments.map((headerPayment, index) =>
                    estimateDebitDatesData?.[date]?.[headerPayment] ? (
                      <td key={index} className="text-right">
                        <PopoverRender
                          id="popover-contained"
                          popoverTitle="Amounts By Payment Method"
                          placement="auto"
                          popoverClass={styles.popover}
                          popoverHeaderClass={styles.popoverHeader}
                          popoverContent={
                            <AmountSplitPopover
                              splitAmounts={estimateDebitDatesData[date][headerPayment]}
                              currencyCode={estimateDebitDatesData?.currency_code}
                            />
                          }
                        >
                          <span className={styles.contentBlue}>
                            {formattedAmount(
                              estimateDebitDatesData?.[date]?.[headerPayment]?.["amount"]?.toString(),
                              estimateDebitDatesData?.currency_code,
                            )}
                          </span>
                        </PopoverRender>
                      </td>
                    ) : (
                      <td key={index}></td>
                    ),
                  )}
                  <td className="text-right">
                    {formattedAmount(
                      estimateDebitDatesData?.[date]?.["total"]?.toString(),
                      estimateDebitDatesData?.currency_code,
                    )}
                  </td>
                  <td className={styles.minWidth100}>
                    <div className={"d-flex flex-wrap text-left"}>
                      {estimateDebitDatesData &&
                        _.isArray(estimateDebitDatesData[date]?.["payment_runs"]) &&
                        estimateDebitDatesData[date]["payment_runs"].map(
                          (paymentRun: { id: number; label: string }, index: number) => (
                            <Fragment key={paymentRun.id}>
                              <a
                                href={restApiService.angularBaseURL() + getUrlLocator("paymentrun", paymentRun.id)}
                                className="link"
                              >
                                {paymentRun.label}
                              </a>
                              {index + 1 !== estimateDebitDatesData[date]["payment_runs"].length && <span> , </span>}
                            </Fragment>
                          ),
                        )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </Panel>
  );
};

export default EstimatedPaymentDates;
