import React, { useEffect, useState } from "react";
import Panel from "components/common/panel/panel";
import styles from "./dashboard.module.css";
import { Col, Row } from "react-bootstrap";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { useSelector } from "react-redux";
import { getFormValues } from "redux-form";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import _ from "lodash";
import moment from "moment";
import { useHistory } from "react-router";
import SpendChart from "./spendChart";
import { getStringFormattedAmount } from "services/general/helpers";
import { useTranslation } from "react-i18next";

type formDataType = {
  posted_date: { value: string; label: string };
  posted_filter_data?: { monthName?: string; startDate: string; endDate: string; selectedOption: string };
  posted_date_custom: { startDate: string; endDate: string; selectedOption: string };
};

type xAxisPlotsType = [string, string][];
type pointTootTipType = (string | [string, string])[];
type pointDataType = number[];

const monthOrder: Record<string, number> = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

type chartDataType = {
  xAxisPlots: xAxisPlotsType;
  pointTootTip: pointTootTipType;
  pointData: pointDataType;
};

type formType = { subsidiary_id?: number; expense_type?: { value: string; label: string }; subsidiaryName?: string };

type MonthlySpendsType = {
  dateRange: string;
};

const MonthlySpends = ({ dateRange }: MonthlySpendsType) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<chartDataType | null>();
  const [monthlySpend, setMonthlySpend] = useState<ExpensesTypes.MonthlySpendType[]>();
  const [monthlySpendGroup, setMonthlySpendGroup] = useState<Record<string, ExpensesTypes.MonthlySpendType[]> | null>();
  const expenseCategoryForm: formDataType = useSelector((state) =>
    getFormValues("expenseCategoryForm")(state),
  ) as formDataType;

  const expenseDashboardForm: formType = useSelector((state) => getFormValues("expenseDashboardForm")(state));
  const subsidiaryId = expenseDashboardForm?.subsidiary_id;
  const expenseType = expenseDashboardForm?.expense_type;
  const subsidiaryName = expenseDashboardForm?.subsidiaryName;

  const history = useHistory();

  const getPointToolTipLabel = (
    item: ExpensesTypes.MonthlySpendType,
    itemArray: ExpensesTypes.MonthlySpendType[],
  ): string | [string, string] => {
    if (itemArray.length > 12) {
      return [`${getRequireMonthLabel(item.month)} ${item.year}`, `${item.currency.symbol}${item.amount_spent}`];
    }

    return getStringFormattedAmount(`${item.amount_spent}`, item.currency.iso_code, 2, true);
  };

  const getRequireMonthLabel = (month: string) => {
    if (month) {
      return month.slice(0, 3).toUpperCase(); // make first letter uppercase
    }
    return "";
  };

  const getChartData = (dataArray: ExpensesTypes.MonthlySpendType[]) => {
    const xAxisPlots: xAxisPlotsType = [];
    const pointTootTip: pointTootTipType = [];
    const amountSpentArray: number[] = [];

    dataArray.forEach((item, index, array) => {
      const monthKey = getRequireMonthLabel(item.month);
      xAxisPlots.push([monthKey, item.year]);
      pointTootTip.push(getPointToolTipLabel(item, array));
      amountSpentArray.push(Number(item.amount_spent));
    });

    return { xAxisPlots: xAxisPlots, pointTootTip: pointTootTip, pointData: amountSpentArray };
  };

  const getApiFilters = () => {
    let filters: {
      date_before?: string;
      date_after?: string;
      subsidiary_ids?: number;
      reimbursable?: string;
    } = {};
    if (expenseCategoryForm?.posted_filter_data?.endDate) {
      filters.date_before = expenseCategoryForm?.posted_filter_data?.endDate;
    }

    if (expenseCategoryForm?.posted_filter_data?.startDate) {
      filters.date_after = expenseCategoryForm?.posted_filter_data?.startDate;
    }

    if (subsidiaryId) {
      filters.subsidiary_ids = subsidiaryId;
    }
    if (expenseType?.value && expenseType.value !== "all") {
      filters.reimbursable = expenseType.value;
    }

    return filters;
  };

  const getMonthlySpends = async () => {
    try {
      const result = await ExpensesApis.getMonthlySpends({ filter: getApiFilters() });
      const monthWiseResult = result.sort((a, b) => monthOrder[a.month] - monthOrder[b.month]);
      const yearWiseResult = _.orderBy(monthWiseResult, "year", "asc");
      const groupedByYear = _.groupBy(yearWiseResult, "year");
      const sortedGroupByYears = Object.keys(groupedByYear);

      const fullYearArray = sortedGroupByYears.flatMap((year) => groupedByYear[year]);

      setMonthlySpendGroup(groupedByYear);
      setMonthlySpend(fullYearArray);
      setChartData(getChartData(fullYearArray));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (expenseCategoryForm?.posted_filter_data !== undefined) {
      getMonthlySpends();
    }
  }, [expenseCategoryForm?.posted_filter_data, subsidiaryId, expenseType]);

  const getMonthDates = (year: number, monthName: string) => {
    const month = moment().month(monthName).month();
    const startDate = moment({ year, month: month }).startOf("month");

    // Create a moment object for the last day of the month
    const endDate = moment({ year, month: month }).endOf("month");

    return {
      startDate: startDate.format("MM/DD/YYYY"),
      endDate: endDate.format("MM/DD/YYYY"),
    };
  };
  const pointCallback = (index: number) => {
    if (monthlySpend) {
      const dates = getMonthDates(Number(monthlySpend[index].year), monthlySpend[index].month);
      if (expenseCategoryForm?.posted_date?.value === "custom") {
        if (expenseCategoryForm?.posted_date_custom?.startDate && index === 0) {
          dates.startDate = expenseCategoryForm.posted_date_custom.startDate;
        }
        if (expenseCategoryForm?.posted_date_custom?.endDate && monthlySpend && monthlySpend.length - 1 === index) {
          dates.endDate = expenseCategoryForm.posted_date_custom.endDate;
        }
      }
      const subsidiaryFilter = subsidiaryName ? "&subsidiaryName=" + subsidiaryName : "";
      const expenseTypeFilter = expenseType?.value ? "&expenseType=" + expenseType?.value : "";

      const startDate = dates?.startDate ? "&date_after=" + dates.startDate : "";
      const endDate = dates?.endDate ? "&date_before=" + dates.endDate : "";

      const searchQuery = `${subsidiaryFilter}${expenseTypeFilter}`;

      const searchParams = `${startDate}${endDate}&status=monthly_spend` + searchQuery;
      history.push({
        pathname: "/ap/expense_reports",
        search: searchParams, // query string
      });
    }
  };

  return (
    <Panel
      cardClass={styles.monthlySpendPanelWrapper}
      cardFooterClass={styles.panelFooter}
      header={
        <div>
          <i className={`icon ${styles.iconHeading} ${styles.bs24} icon-funds px-mt-3 px-mr-3`}></i>
          {t("admin.pages.expenseItem.labels.monthlySpend")}
        </div>
      }
      cardHeaderClass={styles.expensePanelHeader}
    >
      <Row className={`${styles.expenseCategoriesItems}`}>
        <Col className={`${styles.yearClass}`}>
          <span className={`${styles.fiscalYearText}`}>{`${dateRange ? dateRange : ""}`}</span>
        </Col>
      </Row>
      {chartData ? (
        <Row>
          <Col>
            <div className={`${styles.spendChartContainer}`}>
              <SpendChart pointCallback={pointCallback} chartData={chartData} />
            </div>
          </Col>
        </Row>
      ) : null}
    </Panel>
  );
};

export default MonthlySpends;
