import React from "react";
import DateFilter from "../reportFilters/date";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";
import DropdownFilter from "../reportFilters/dropdown";

const HistoricalFxRatesListing = ({ scheduled }: { scheduled?: boolean }) => {
  const currency_code_options = [
    { value: "USD", label: "USD", name: "US Dollar", symbol: "$", crypto: false },
    { value: "CAD", label: "CAD", name: "Canadian Dollar", symbol: "CA$", crypto: false },
    { value: "EUR", label: "EUR", name: "Euro", symbol: "€", crypto: false },
    { value: "AED", label: "AED", name: "United Arab Emirates Dirham", symbol: "د.إ.", crypto: false },
    { value: "ARS", label: "ARS", name: "Argentine Peso", symbol: "AR$", crypto: false },
    { value: "AUD", label: "AUD", name: "Australian Dollar", symbol: "AU$", crypto: false },
    { value: "BDT", label: "BDT", name: "Bangladeshi Taka", symbol: "৳", crypto: false },
    { value: "BRL", label: "BRL", name: "Brazilian Real", symbol: "R$", crypto: false },
    { value: "CDF", label: "CDF", name: "Congolese Franc", symbol: "CDF", crypto: false },
    { value: "CNY", label: "CNY", name: "Chinese Yuan", symbol: "CN¥", crypto: false },
    { value: "COP", label: "COP", name: "Colombian Peso", symbol: "CO$", crypto: false },
    { value: "CZK", label: "CZK", name: "Czech Republic Koruna", symbol: "Kč", crypto: false },
    { value: "DZD", label: "DZD", name: "Algerian Dinar", symbol: "د.ج.", crypto: false },
    { value: "EGP", label: "EGP", name: "Egyptian Pound", symbol: "ج.م.", crypto: false },
    { value: "GBP", label: "GBP", name: "British Pound Sterling", symbol: "£", crypto: false },
    { value: "HKD", label: "HKD", name: "Hong Kong Dollar", symbol: "HK$", crypto: false },
    { value: "IDR", label: "IDR", name: "Indonesian Rupiah", symbol: "Rp", crypto: false },
    { value: "ILS", label: "ILS", name: "Israeli New Sheqel", symbol: "₪", crypto: false },
    { value: "INR", label: "INR", name: "Indian Rupee", symbol: "₹", crypto: false },
    { value: "IQD", label: "IQD", name: "Iraqi Dinar", symbol: "د.ع.‏", crypto: false },
    { value: "IRR", label: "IRR", name: "Iranian Rial", symbol: "﷼", crypto: false },
    { value: "JPY", label: "JPY", name: "Japanese Yen", symbol: "¥", crypto: false },
    { value: "KRW", label: "KRW", name: "South Korean Won", symbol: "₩", crypto: false },
    { value: "KWD", label: "KWD", name: "Kuwaiti Dinar", symbol: "د.ك.", crypto: false },
    { value: "LKR", label: "LKR", name: "Sri Lankan Rupee", symbol: "SLRs", crypto: false },
    { value: "MXN", label: "MXN", name: "Mexican Peso", symbol: "MX$", crypto: false },
    { value: "NZD", label: "NZD", name: "New Zealand Dollar", symbol: "NZ$", crypto: false },
    { value: "RUB", label: "RUB", name: "Russian Ruble", symbol: "₽", crypto: false },
    { value: "SGD", label: "SGD", name: "Singapore Dollar", symbol: "S$", crypto: false },
    { value: "ZAR", label: "ZAR", name: "South African Rand", symbol: "R", crypto: false },
    { value: "SEK", label: "SEK", name: "Swedish kron", symbol: "kr", crypto: false },
    { value: "CHF", label: "CHF", name: "Swiss Franc", symbol: "CHF", crypto: false },
    { value: "PHP", label: "PHP", name: "Philippines Peso", symbol: "₱", crypto: false },
    { value: "DKK", label: "DKK", name: "Danish krone", symbol: "Kr.", crypto: false },
    { value: "NOK", label: "NOK", name: "Norwegian krone", symbol: "kr", crypto: false },
    { value: "AFN", label: "AFN", name: "Afghanistan afghani", symbol: "Af", crypto: false },
    { value: "ALL", label: "ALL", name: "Albanian lek", symbol: "L", crypto: false },
    { value: "DZD", label: "DZD", name: "Algerian dinar", symbol: "د.ج", crypto: false },
    { value: "HUF", label: "HUF", name: "Hungarian forint", symbol: "Ft", crypto: false },
    { value: "MYR", label: "MYR", name: "Malaysian ringgit", symbol: "RM", crypto: false },
    { value: "MMK", label: "MMK", name: "Myanmar kyat", symbol: "K", crypto: false },
    { value: "KPW", label: "KPW", name: "North Korean won", symbol: "₩", crypto: false },
    { value: "PKR", label: "PKR", name: "Pakistani rupee", symbol: "₨", crypto: false },
    { value: "SZL", label: "SZL", name: "Swaziland lilangeni", symbol: "L", crypto: false },
    { value: "ZWL", label: "ZWL", name: "Zimbabwe dollar", symbol: "Z$", crypto: false },
    { value: "TRY", label: "TRY", name: "Turkish lira", symbol: "₺", crypto: false },
    { value: "SAR", label: "SAR", name: "Saudi riyal", symbol: "ر.س", crypto: false },
    { value: "RON", label: "RON", name: "Romanian new leu", symbol: "lei", crypto: false },
    { value: "AOR", label: "AOR", name: "Angolan kwanza reajustado", symbol: "AOR", crypto: false },
    { value: "AMD", label: "AMD", name: "Armenian dram", symbol: "֏", crypto: false },
    { value: "AWG", label: "AWG", name: "Aruban florin", symbol: "ƒ", crypto: false },
    { value: "BSD", label: "BSD", name: "Bahamian dollar", symbol: "$", crypto: false },
    { value: "BHD", label: "BHD", name: "Bahraini dinar", symbol: "ب.د", crypto: false },
    { value: "BTN", label: "BTN", name: "Bhutan ngultrum", symbol: "Nu", crypto: false },
    { value: "BOB", label: "BOB", name: "Bolivian boliviano", symbol: "$b", crypto: false },
    { value: "BND", label: "BND", name: "Brunei dollar", symbol: "$", crypto: false },
    { value: "BGN", label: "BGN", name: "Bulgarian lev", symbol: "лв", crypto: false },
    { value: "KHR", label: "KHR", name: "Cambodian riel", symbol: "៛", crypto: false },
    { value: "GEL", label: "GEL", name: "Georgian lari", symbol: "ლ", crypto: false },
    { value: "JOD", label: "JOD", name: "Jordanian dinar", symbol: "د.ا", crypto: false },
    { value: "LBP", label: "LBP", name: "Lebanese pound", symbol: "ل.ل.", crypto: false },
    { value: "LRD", label: "LRD", name: "Liberian Dollar", symbol: "$", crypto: false },
    { value: "MNT", label: "MNT", name: "Mongolian tugrik", symbol: "₮", crypto: false },
    { value: "NPR", label: "NPR", name: "Nepalese rupee", symbol: "₨", crypto: false },
    { value: "MVR", label: "MVR", name: "Maldivian rufiyaa", symbol: "ރ.", crypto: false },
    { value: "BMD", label: "BMD", name: "Bermudian Dollar", symbol: "$", crypto: false },
    { value: "CLP", label: "CLP", name: "Chilean Peso", symbol: "CLP$", crypto: false },
    { value: "PEN", label: "PEN", name: "Peruvian Sol", symbol: "S/", crypto: false },
    { value: "PLN", label: "PLN", name: "Polish Zloty", symbol: "zł", crypto: false },
    { value: "MAD", label: "MAD", name: "Moroccan Dirham", symbol: "م.د.", crypto: false },
    { value: "THB", label: "THB", name: "Thai Baht", symbol: "฿", crypto: false },
    { value: "CRC", label: "CRC", name: "Costa Rican Colon", symbol: "₡", crypto: false },
    { value: "HRK", label: "HRK", name: "Croatian Kuna", symbol: "kn", crypto: false },
    { value: "TWD", label: "TWD", name: "Taiwan dollar", symbol: "NT$", crypto: false },
    { value: "RSD", label: "RSD", name: "Serbian dinar", symbol: "RSD ", crypto: false },
    { value: "KES", label: "KES", name: "Kenyan shilling", symbol: "Ksh", crypto: false },
    { value: "ZMW", label: "ZMW", name: "Zambian Kwacha", symbol: "K", crypto: false },
    { value: "NGN", label: "NGN", name: "Nigerian naira", symbol: "‎₦", crypto: false },
    { value: "GHS", label: "GHS", name: "Ghanaian Cedi", symbol: "GH₵", crypto: false },
    { value: "VND", label: "VND", name: "Vietnamese dong", symbol: "₫", crypto: false },
    { value: "KZT", label: "KZT", name: "Kazakhstani Tenge", symbol: "₸", crypto: false },
    { value: "XAF", label: "XAF", name: "Central African CFA franc", symbol: "FCFA", crypto: false },
    { value: "OMR", label: "OMR", name: "Omani rial", symbol: "ر.ع.", crypto: false },
    { value: "UGX", label: "UGX", name: "Uganda Shilling", symbol: "USh", crypto: false },
    { value: "NAD", label: "NAD", name: "Namibian Dollar", symbol: "N$", crypto: false },
    { value: "QAR", label: "QAR", name: "Qatari Riyal", symbol: "QR", crypto: false },
    { value: "BWP", label: "BWP", name: "Botswanan Pula", symbol: "P", crypto: false },
    { value: "KYD", label: "KYD", name: "Cayman Islands Dollar", symbol: "CI$", crypto: false },
    { value: "LAK", label: "LAK", name: "Laotian Kip", symbol: "₭", crypto: false },
    { value: "UAH", label: "UAH", name: "Ukrainian hryvnia", symbol: "₴", crypto: false },
    { value: "TND", label: "TND", name: "Tunisian Dinar", symbol: "د.ت", crypto: false },
    { value: "PYG", label: "PYG", name: "Paraguayan Guarani", symbol: "₲", crypto: false },
    { value: "UYU", label: "UYU", name: "Uruguayan Peso", symbol: "$U", crypto: false },
    { value: "ECS", label: "ECS", name: "Ecuadorian Sucre", symbol: "S/.", crypto: false },
    { value: "RWF", label: "RWF", name: "Rwandan franc", symbol: "R₣", crypto: false },
    { value: "GYD", label: "GYD", name: "Guyanese Dollar", symbol: "GY$", crypto: false },
    { value: "MUR", label: "MUR", name: "Mauritian rupee", symbol: "Rs", crypto: false },
    { value: "LSL", label: "LSL", name: "Lesotho Loti", symbol: "L", crypto: false },
    { value: "ISK", label: "ISK", name: "Icelandic Krona", symbol: "Íkr", crypto: false },
  ];

  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    } else {
      return (
        <>
          <DateFilter name={"Start Date"} code={"start_date"} options={[]} isRequired />
          <DateFilter name={"End Date"} code={"end_date"} options={[]} isRequired />
        </>
      );
    }
  };

  return (
    <div>
      {renderDateFilter()}
      <DropdownFilter name={"Currency Code"} code={"currency_code"} options={currency_code_options} />
    </div>
  );
};

export default HistoricalFxRatesListing;
