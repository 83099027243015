import { sortBy } from "lodash";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { setAllowedFilters } from "services/common/gridService";
import { translate } from "services/general/translation";

export const FIELD_NAME_MAP: FieldFilterMapType = {};

export const getCardPaymentMethodsHeaders = () => {
  let columnDefs = [
    {
      field: "account_number",
      headerName: "Account Number",
      sortable: false,
      headerValueGetter: function () {
        return translate("account_number");
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: "account_name",
      headerName: "Account Name",
      sortable: false,
      headerValueGetter: function () {
        return translate("account_name");
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: "institution_name",
      headerName: "Institution Name",
      sortable: false,
      headerValueGetter: function () {
        return translate("institution_name");
      },
      flex: 1,
      minWidth: 100,
    },
  ];
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return { columnDefs: sortBy(columnDefs, (col) => col.headerValueGetter()), defaultOrder };
};
