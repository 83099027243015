import React, { useEffect, useState } from "react";
import SubsidiaryApis from "../../../services/admin/subsidiaries/subsidiaryApis";
import { GridReadyEvent } from "ag-grid-community";
import { SubsidiaryListType } from "../../../services/admin/subsidiaries/types";
import { Button, Col, Container, Row } from "react-bootstrap";
import GridFilterDropdown from "../../datagrid/gridFilterDropdown";
import ToggleFilterButton from "../../datagrid/buttons/toggleFilterButton";
import { Link } from "react-router-dom";
import { Can } from "../../../services/authorization/authorization";
import ClientDataGrid from "../../common/dataGrid/clientDataGrid/clientDataGrid";
import subsidiariesGridHeader from "./subsidiariesGridHeader";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useConfirmModal from "../../modals/confirmModal/useConfirmModalHook";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import styles from "./subsidiaries.module.css";

const ListSubsidiary = () => {
  const gridStorageName = "subsidiaries";
  const [gridApi, setGridApi] = useState<GridReadyEvent["api"]>();
  const [gridColumnApi, setGridColumnApi] = useState<GridReadyEvent["columnApi"]>();
  const [subsidiaries, setSubsidiaries] = useState<SubsidiaryListType[]>([]);
  const [showFilter, setShowFitler] = useState<boolean>(false);
  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();

  const getSubsidiaries = async () => {
    try {
      const getResponse = await SubsidiaryApis.getSubsidiaryList();
      if (getResponse) {
        setSubsidiaries(getResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteConfirmCallBack = async (subsidiary: SubsidiaryListType) => {
    try {
      await SubsidiaryApis.deleteSubsidiary(subsidiary.id);
      getSubsidiaries();
      CreateNotification(
        t("success"),
        t("subsidiary.removeSuccess", { name: subsidiary.name }),
        NotificationType.success,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSubsidiary = (subsidiary: SubsidiaryListType) => {
    createConfirmModal({
      title: "Confirm",
      body: `Are you sure about deleting the "${subsidiary.name}" subsidiary?`,
      saveCallBack: deleteConfirmCallBack,
      cancelCallBack: null,
      callBackData: subsidiary,
    });
  };

  useEffect(() => {
    getSubsidiaries();
  }, []);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <Row>
        <Col>
          <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} options={{}} />
          <ToggleFilterButton
            classes="float-right"
            clickCallback={() => {
              setShowFitler((prev) => !prev);
              gridApi?.refreshHeader();
            }}
          />

          <Link to="subsidiaries/add">
            <Can I="add" a="Subsidiaries">
              <Button variant="primary ml-2">
                <i className="btn-icon icon-add-btn"></i>
                Add
              </Button>
            </Can>
          </Link>
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Row className={styles.gridHeight + " px-3"}>
        {_.isArray(subsidiaries) && subsidiaries.length > 0 && (
          <ClientDataGrid
            columnDefs={subsidiariesGridHeader((subsidiary: SubsidiaryListType) => deleteSubsidiary(subsidiary))}
            gridReady={gridReady}
            rowData={subsidiaries}
            defaultColDef={{ floatingFilter: showFilter }}
            gridStorageName={gridStorageName}
          />
        )}
      </Row>
    </Container>
  );
};

export default ListSubsidiary;
