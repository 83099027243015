import React from "react";
import { Form } from "react-bootstrap";
import {
  AccountHolder,
  AccountNumber,
  AccountType,
  BankName,
  BankRouting,
  ConfirmAccountNumber,
} from "../fieldComponents/fieldsComponent";

function selectField(fillteredPayment: any[], fieldName: string) {
  return fillteredPayment[0].validations.find((item: { field: any }) => item.field === fieldName);
}

export const FedWireUsd = (props: any) => {
  return (
    <>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        {props?.paymentMethodRed?.id ? null : <ConfirmAccountNumber {...props} />}
        <AccountType {...props} field_valid={selectField(props.fillteredPayment, "account_type")} />

        <BankRouting
          {...props}
          field_valid={selectField(props.fillteredPayment, "routing")}
          bankNameValidationObj={selectField(props.fillteredPayment, "bank_name")}
        />

        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
      </Form.Row>
    </>
  );
};
