import _ from "lodash";
import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { AbstractListPickerTypes } from "../abstractListPicker/abstractListPickerTypes";
import AbstractListPicker from "../abstractListPicker/index2";
import { AccountPickerTypes } from "./accountPickerTypes";
import useAccountPickerHook from "./useAccountPickerHook";

export const compare = (
  a: AccountPickerTypes.TAccountPickerOption,
  b: AccountPickerTypes.TAccountPickerOption,
): number => {
  if (_.isString(a.number) && _.isString(b.number)) {
    return a.number.toLowerCase() > b.number.toLowerCase() ? 1 : b.number.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
  } else {
    return 0;
  }
};

const AccountPicker = ({
  name,
  containerClassName,
  label,
  required,
  validate,
  disabled,
  instanceId = "single-account-picker",
  callBack,
  accountGroupName,
  modelData,
  parentObj,
  modelName,
  objectPropertyKey,
  labelClassName,
  menuPlacement,
  menuPosition,
  tooltip,
  placeholder,
}: AccountPickerTypes.TSingleAccountPickerProps) => {
  const [params, mergeInactive, getResponseData, formatOptionLabel] = useAccountPickerHook(
    name,
    modelData,
    parentObj,
    accountGroupName,
  );
  const { setValue } = useFormContext();

  const handlePickerSelection = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<AccountPickerTypes.TAccountPickerOption>) => {
      if (!_.isNil(modelData)) {
        setValue(modelData + "account_name", selected ? selected.name : "");
        setValue(modelData + "account_number", selected ? selected.number : "");
      }
      // this is done intensionally to add layer of abstraction
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack, modelData, setValue],
  );

  return (
    <AbstractListPicker<AccountPickerTypes.TAccountPickerOption>
      name={name}
      label={label}
      required={required}
      mergeInactive={mergeInactive}
      params={params}
      objectPropertyKey={objectPropertyKey ?? (!_.isNil(modelData) ? `${modelData}account` : null)}
      fetchUrl="accounts.lk"
      formatOptionLabel={formatOptionLabel}
      callBack={handlePickerSelection}
      validate={validate}
      instanceId={instanceId}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      labelClassName={labelClassName}
      disabled={disabled}
      containerClassName={containerClassName}
      placeholder={placeholder}
      tooltip={tooltip}
      getResponseData={getResponseData}
      sortCompareFn={compare}
    />
  );
};
export default AccountPicker;
