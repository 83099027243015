import { IUser } from "services/common/user/userTypes";
import { CommonTypes } from "services/common/types/commonTypes";
import { ExpenseReportTypes } from "./expenseReportType";
import gridService from "services/grid/gridSvc";
import { ColumnApi, GridApi } from "ag-grid-community";
import moment from "moment";

class ExpenseReportService {
  expenseReportStatus = [
    { value: "DRAFT", label: "DRAFT" },
    { value: "NEW", label: "NEW" },
    { value: "PENDING", label: "PENDING APPROVAL" },
    { value: "OPEN", label: "FULLY APPROVED" },
    { value: "REJECTED", label: "REJECTED" },
    { value: "PAID", label: "PAID" },
    { value: "UNPAID", label: "UNPAID" },
    { value: "CLOSED", label: "CLOSED" },
  ];

  expenseReportGridStatuses = [
    {
      value: 1,
      label: "ALL",
    },
    {
      value: 2,
      label: "NEW",
      cellValues: ["NEW"],
    },
    {
      value: 3,
      label: "PENDING APPROVAL",
      cellValues: ["PENDING"],
    },
    {
      value: 5,
      label: "APPROVED",
      cellValues: ["APPROVED", "OPEN", "OPEN (PENDING)", "OPEN (SCHEDULED)", "OPEN (APPROVED)"],
    },
    {
      value: 6,
      label: "PAID",
      cellValues: ["PAID", "PAID (PROCESSING)"],
    },
    {
      value: 7,
      label: "CLOSED",
      cellValues: ["CLOSED"],
    },
    {
      value: 8,
      label: "REJECTED",
      cellValues: ["REJECTED"],
    },
    {
      value: 12,
      label: "UNPAID",
      cellValues: ["OPEN", "APPROVED", "NEW", "PENDING", "OPEN (PENDING)", "OPEN (SCHEDULED)", "OPEN (APPROVED)"],
    },
  ];

  getStatusParams = ({ filters }: { filters: any }) => {
    if (filters && filters["status"]) {
      const status = expenseReportSvc.expenseReportGridStatuses.find(
        (status) => status.value === Number(filters["status"]),
      );
      filters["statuses"] = status?.cellValues;
      delete filters.status;
    }
  };

  disableEditingApprovedTransaction = (
    transactionable_status: string,
    transactionable_type: string,
    accessControl: CommonTypes.AccessControl,
  ) => {
    switch (transactionable_type) {
      case "payment":
        return (
          accessControl.hasUserPermission("disableapprovededitTransactions") &&
          ["APPROVED", "PROCESSING", "CLEARED"].includes(transactionable_status)
        );
      case "creditmemo":
        return (
          accessControl.hasUserPermission("disableapprovededitTransactions") &&
          ["APPROVED", "AVAILABLE", "APPLIED"].includes(transactionable_status)
        );
      case "invoice":
        return (
          accessControl.hasUserPermission("disableapprovededitTransactions") &&
          ["APPROVED", "OPEN", "PAID", "OPEN (PENDING)", "OPEN (SCHEDULED)", "OPEN (APPROVED)"].includes(
            transactionable_status,
          )
        );
      case "expensereport":
        return (
          accessControl.hasUserPermission("disableapprovededitTransactions") &&
          ["APPROVED", "OPEN", "PAID", "OPEN (PENDING)", "OPEN (SCHEDULED)", "OPEN (APPROVED)"].includes(
            transactionable_status,
          )
        );
      case "purchaseorder":
        return (
          accessControl.hasUserPermission("disableapprovededitTransactions") &&
          ["APPROVED", "OPEN"].includes(transactionable_status)
        );
      default:
        return null;
    }
  };

  isAdminUser = ({ currentUser }: { currentUser: IUser }) => {
    const filteredArray = currentUser.roles.filter(function (role) {
      return (
        ["expense_admin", "admin", "admin_expensereport", "universal_admin", "application_admin"].indexOf(role.name) !==
        -1
      );
    });
    return filteredArray.length > 0;
  };

  isManagerUser = ({ currentUser }: { currentUser: IUser }) => {
    const filteredArray = currentUser.roles.filter(function (role) {
      return (
        ["expense_manager", "po_manager_limited", "ap_role", "special_ap_role", "manager_expense"].indexOf(
          role.name,
        ) !== -1
      );
    });
    return filteredArray.length > 0;
  };

  isStaffUser = function ({ currentUser }: { currentUser: IUser }) {
    const filteredArray = currentUser.roles.filter(function (role) {
      return ["expense_staff", "staff_read_expense_report", "staff_submit_expense_report"].indexOf(role.name) !== -1;
    });
    return filteredArray.length > 0;
  };

  isAllowToShow = ({
    expenseReport,
    accessControl,
    currentUser,
  }: {
    expenseReport: ExpenseReportTypes.ListItem;
    accessControl: CommonTypes.AccessControl;
    currentUser: IUser;
  }) => {
    if (
      expenseReport.status &&
      accessControl.hasUserPermission("editExpenseReports") &&
      !this.disableEditingApprovedTransaction(expenseReport.status, "expensereport", accessControl)
    ) {
      if (this.isAdminUser({ currentUser }) || this.isManagerUser({ currentUser })) {
        return true;
      }
      let isAllowed = true;
      // STAFF can edit expense items that:
      // 1. Are in DRAFT or NEW status
      // 2. Are in PENDING status, when the Expense Report has NOT begun approval workflow
      if (this.isStaffUser({ currentUser }) && !["DRAFT", "NEW"].includes(expenseReport.status)) {
        if (expenseReport.status !== "PENDING") {
          isAllowed = false;
        } else if (expenseReport.approver_started) {
          isAllowed = false;
        }
      }
      return isAllowed;
    } else {
      return false;
    }
  };

  addStatus = ({ expenseReport }: { expenseReport: ExpenseReportTypes.ListItem }) => {
    if (!expenseReport.daysTilDue) {
      let dueDate = moment(expenseReport.due_date);
      let today = moment().startOf("day");
      expenseReport.daysTilDue = dueDate.diff(today, "days", true);
    }
    const diff = expenseReport.daysTilDue;
    if (
      (expenseReport.status === "OPEN" && expenseReport.payment_links_status === "PENDING") ||
      (expenseReport.status === "OPEN" && expenseReport.payment_links_status === "SCHEDULED") ||
      (expenseReport.status === "OPEN" && expenseReport.payment_links_status === "APPROVED") ||
      (expenseReport.status === "PAID" && expenseReport.payment_links_status === "PROCESSING")
    ) {
      expenseReport.status = expenseReport.status + " (" + expenseReport.payment_links_status + ")";
    }
    if (expenseReport.status === "PAID" || expenseReport.status === "CLOSED") {
      expenseReport.due_status = expenseReport.status;
      if (expenseReport.payment_links_status)
        expenseReport.due_status = expenseReport.due_status + " (" + expenseReport.payment_links_status + ")";
    } else {
      if (diff < 0) {
        expenseReport.due_status = "PAST DUE";
      } else if (diff === 0) {
        expenseReport.due_status = "DUE TODAY";
      } else if (1 <= diff && diff <= 7) {
        expenseReport.due_status = "DUE SOON";
      } else if (diff >= 8) {
        expenseReport.due_status = "NEW";
      }
    }
  };

  decorateExpenseReport = ({ response }: { response: ExpenseReportTypes.ListItem[] }) => {
    response.forEach((expenseReport) => {
      this.addStatus({ expenseReport });
    });
    return response;
  };
}
const expenseReportSvc = new ExpenseReportService();
export default expenseReportSvc;
