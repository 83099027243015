import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { ReportType, ReportDetailType, ReportCreateType } from "./reportTypes";

export class ReportApis {
  static getList = async (params: { [key: string]: any }, cache: boolean = true) => {
    try {
      const response: AxiosResponse<ReportType> = await restApiService.get("reports", params, null, true, null, cache);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  static getDetail = async (id: number) => {
    try {
      const response: AxiosResponse<ReportDetailType> = await restApiService.get(
        `reports/${id}`,
        null,
        null,
        true,
        null,
        true,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  static create = async (payload: ReportDetailType) => {
    try {
      const response: AxiosResponse<ReportDetailType> = await restApiService.post(`reports`, null, { report: payload });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static createReport = async (payload: ReportCreateType) => {
    try {
      const response: AxiosResponse<ReportDetailType> = await restApiService.post(`reports`, null, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  static delete = async (id: number) => {
    try {
      const response: AxiosResponse<ReportDetailType> = await restApiService.delete(`reports/${id}`, null, null, true);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
