import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io";
import AttachmentPreviewer from "../../common/attachmentPreview/AttachmentPreviewer";
import style from "../Widgets.module.css";
import defaultAttachmentIcon from "../../../assets/default_attachment.png";
const checkURL = (url) => {
  return url.match(/\.(jpeg|jpg|gif|png|pdf)$/) != null;
};

const PicturePreview = (props) => {
  const [showPreviewer, setShowPreviewer] = useState(false);
  let [failedToLoad, setFailedToLoad] = useState(false);
  let srcUrl =
    props.file.name && checkURL(props.file.name) && !failedToLoad
      ? props.file.url
        ? props.file.url
        : props.file.preview
      : defaultAttachmentIcon;

  return (
    <div className="mr-3 d-inline-block">
      {showPreviewer && (
        <AttachmentPreviewer
          file={props.file}
          isVisible={showPreviewer}
          closePreviewer={() => setShowPreviewer(false)}
        />
      )}
      <div className={style.AttachmentName + " text-center"} title={props.file.name}>
        {props.file.name && props.file.name.substring(0, 20)}
      </div>
      <div
        style={{ backgroundImage: `url('${srcUrl}')` }}
        className={style.thumbnail}
        onClick={() => setShowPreviewer(true)}
      ></div>
      <div className="d-flex justify-content-center">
        <button type="button" className={"border-0 fileUploadButtons secondaryFontColor"}>
          <a href={props.file.url || props.file.preview} download target="_blank" rel="noreferrer">
            <IoMdDownload />
          </a>
        </button>
        {props.deleteCallback && (
          <button
            type="button"
            className={"border-0 fileUploadButtons secondaryFontColor"}
            onClick={() => {
              props.deleteCallback(props.file);
            }}
          >
            <AiFillDelete />
          </button>
        )}
      </div>
    </div>
  );
};

export default PicturePreview;
