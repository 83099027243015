import OktaAuth, {
  TokenResponse,
  TokenManagerRenewEventHandler,
  TokenManagerEventHandler,
  AccessToken,
} from "@okta/okta-auth-js";
import { APP_EMITTER, APP_EVENTS, PAUSE_AXIOS, UNPAUSE_AXIOS } from "providers/appEmitter";

class OktaService {
  oktaAuth: OktaAuth;

  getValidSession = async () => {
    let session = null;
    const hasSession = await this.oktaAuth.session.exists();
    if (hasSession) {
      localStorage.setItem("USING_OKTA_LOGIN", "true");
      session = (await this.oktaAuth.session.get()) as any;
    } else {
      localStorage.removeItem("USING_OKTA_LOGIN");
    }
    return session && session.id && session;
  };

  getTokensFromSession = async () => {
    const session = await this.getValidSession();
    if (!session) {
      return false;
    }
    try {
      const response = await this.oktaAuth.token.getWithoutPrompt({});
      return this.processTokens(response);
    } catch (err) {
      console.log("Okta GETWithoutPrompt Error: ", err);
      await this.setTokensFromRedirect();
    }
  };

  setTokensFromRedirect = async () => {
    try {
      await this.oktaAuth.token.getWithRedirect({});
    } catch (err) {
      console.log("Okta getWithRedirect Error: ", err);
    }
  };

  getTokensFromRedirect = async () => {
    try {
      const response = await this.oktaAuth.token.parseFromUrl({});
      return this.processTokens(response);
    } catch (err) {
      console.log("Okta parseFromUrl Failed: ", err);
    }
  };

  processTokens = (response: TokenResponse) => {
    if (response && response.tokens && response.tokens.accessToken) {
      this.oktaAuth.tokenManager.setTokens(response.tokens);
      localStorage.setItem("Token", response.tokens.accessToken.accessToken);
      return true;
    } else {
      console.log("Okta Error: ", response);
      localStorage.removeItem("Token");
    }
  };

  shouldLogin = async (redirectCallback: () => void) => {
    if (!(await this.getTokensFromRedirect()) && !(await this.getTokensFromSession())) {
      redirectCallback();
    }
  };

  handleRenewal: TokenManagerRenewEventHandler = (key, newToken, oldToken) => {
    if (key === "accessToken") {
      newToken = newToken as AccessToken;
      localStorage.setItem("Token", newToken.accessToken);
      UNPAUSE_AXIOS();
      APP_EMITTER.emit(APP_EVENTS.AXIOS_RESOLVE); // emit that app is ready to proceed with axios requests
    }
  };

  handleExpired: TokenManagerEventHandler = (key, expiredToken) => {
    if (key === "accessToken") {
      PAUSE_AXIOS();
    }
  };
}

const oktaService = new OktaService();
export { oktaService };
