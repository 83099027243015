import _ from "lodash";
import { IUser } from "services/common/user/userTypes";
import { ExpensesTypes } from "./expensesType";
import { CreateNotification, NotificationType } from "services/general/notifications";
import ExpenseItem from "components/admin/expensese/expenseItem/expenseItem";
import currency_codes from "lookups/currencyCodeLookup";

export enum ExpenseConstants {
  LIST_EXPENSE_ITEM_SEARCH_API_FILTERS = "ai.listExpenseItems.searchAPIFilters",
  EXPENSE_ITEM_FORM = "EXPENSE_ITEM_FORM",
  SPLIT_EXPENSE_CODING_FORM = "SPLIT_EXPENSE_CODING_FORM",
}

class ExpenseService {
  expenseItemTypes = [
    { value: "SINGLE_EXPENSE", label: "Single Expense", icon: "icon-single-expense" },
    { value: "MILEAGE", label: "Mileage", icon: "icon-mileage" },
    { value: "PER_DIEM", label: "Per Diem", icon: "icon-per-diem" },
  ];

  isStaffUser = (currentUser: IUser) => {
    var filteredArray = currentUser.roles.filter(
      (role) => ["expense_staff", "staff_read_expense_report", "staff_submit_expense_report"].indexOf(role.name) !== -1,
    );
    return filteredArray.length > 0;
  };

  isManagerUser = (currentUser: IUser) => {
    var filteredArray = currentUser.roles.filter(
      (role) =>
        ["expense_manager", "po_manager_limited", "ap_role", "special_ap_role", "manager_expense"].indexOf(
          role.name,
        ) !== -1,
    );
    return filteredArray.length > 0;
  };

  isAdminUser = (currentUser: IUser) => {
    var filteredArray = currentUser.roles.filter(
      (role) =>
        ["expense_admin", "admin", "admin_expensereport", "universal_admin", "application_admin"].indexOf(role.name) !==
        -1,
    );
    return filteredArray.length > 0;
  };

  canEditOrDeleteExpenseItem = (expenseItem: ExpensesTypes.ListItem, currentUser: IUser) => {
    if (
      (_.isArray(currentUser.perm_resources) && currentUser.perm_resources.includes("editExpenseItems")) ||
      currentUser.perm_resources.includes("deleteExpenseItems")
    ) {
      if (this.isAdminUser(currentUser) || this.isManagerUser(currentUser)) {
        return true;
      }
      let is_allowed = true;
      // STAFF can edit expense items that:
      // 1. Are in DRAFT or NEW status
      // 2. Are in PENDING status, when the Expense Report has NOT begun approval workflow
      if (
        this.isStaffUser(currentUser) &&
        _.isPlainObject(expenseItem) &&
        expenseItem.status &&
        !["DRAFT", "NEW"].includes(expenseItem.status)
      ) {
        if (expenseItem.status != "PENDING") {
          is_allowed = false;
        } else if (_.isPlainObject(expenseItem.expense_report) && expenseItem.expense_report?.approver_started) {
          is_allowed = false;
        }
      }
      return is_allowed;
    }
  };

  isPolicyViolationExist = (selectedItems: ExpensesTypes.List, currentUser: IUser) => {
    let flag = false;
    if (_.isPlainObject(currentUser.company.expense) && currentUser.company.expense?.enforce_policy_violations) {
      let expenseItems = _.filter(selectedItems, { has_violations: true });
      if (expenseItems && expenseItems.length > 0) {
        flag = true;
        CreateNotification(
          "Expense Report Creation Failed",
          "Expense items with policy violations cannot be added to an expense report. Please fix the violations and try again.",
          NotificationType.danger,
        );
      }
    }
    return flag;
  };

  isAllowedConversion = (policy: ExpensesTypes.Policy) => {
    return (
      policy?.convert_to_base_currency || policy?.convert_to_subsidiary_currency || policy?.convert_to_employee_currency
    );
  };

  isConversionAvailable = (expenseItem: ExpensesTypes.Details) => {
    return (
      _.isPlainObject(expenseItem.policy) &&
      (expenseItem.item_type === "SINGLE_EXPENSE" ||
        (_.isPlainObject(expenseItem.selected_type) && expenseItem.selected_type.value === "SINGLE_EXPENSE")) &&
      ((expenseItem.policy && this.isAllowedConversion(expenseItem.policy)) ||
        (_.isPlainObject(expenseItem?.policy?.properties) && this.isAllowedConversion(expenseItem?.policy?.properties)))
    );
  };

  // Assist with assigning currency to expense items. If the user's currency is in the policy currency list, display the user currency.
  // If not, display the policy's functional currency.
  // defaultCurrency = (expenseItem: ExpensesTypes.Details) => {
  //   const globalCurrencyCodes = getAppConfigCurrencyCodes();

  // };

  getPolicyCurrencyCode = (policy: ExpensesTypes.Policy) => {
    /*
        PMP Copied from draftExpense.jsx-- ExpensesTypes.Policy does not have currency_attributes (does have currency_settings)

        if(policy && !policy.currency_attributes){
            policy.currency_attributes = (policy.currency_setting === 'allow') ? policy.allowed_currencies:policy.excluded_currencies;
        }
        if(policy && policy.currency_setting === 'allow' && policy.currency_attributes){
            return currency_codes.filter(currency => this.findCurrencies(policy, currency))
        }else if(policy && policy.currency_setting === 'exclude' && policy.currency_attributes){
            return currency_codes.filter(currency => this.findCurrencies(policy, currency))
        }else{
            return currency_codes;
        }
*/
  };

  canShowPolicyConversionMessage = (expenseItem: ExpensesTypes.Details) => {
    if (expenseItem.policy) {
      const baseCurrencyCode = expenseItem.base_currency_code;
      const currencyCodes = this.getPolicyCurrencyCode(expenseItem.policy);
    }
    return true;
  };

  shouldShowConversionMessage = (expenseItem: ExpensesTypes.Details) => {
    if (expenseItem.policy?.convert_to_base_currency) {
      return true;
    }
  };

  isConversionToSubsidiaryCurrencyAvailable = (expenseItem: ExpensesTypes.Details) => {
    return expenseItem.policy?.convert_to_subsidiary_currency && expenseItem.employee?.subsidiary?.currency_code;
  };
}

const expenseService = new ExpenseService();
export default expenseService;
