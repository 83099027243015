import React from "react";
import { Col, Row } from "react-bootstrap";
import { NotificationType } from "../../../services/general/notifications";
import styles from "./toast.module.css";

const getIcon = (type) => {
  let icon;
  switch (type) {
    case NotificationType.warning:
      icon = "icon-system-warning";
      break;
    case NotificationType.danger:
      icon = "icon-system-error";
      break;
    case NotificationType.success:
      icon = "icon-system-success";
      break;
    case NotificationType.info:
      icon = "icon-system-info";
      break;
    case NotificationType.default:
      icon = "icon-system-info";
      break;
    default:
      icon = "icon-system-warning";
  }
  return icon;
};

const ToastNotification = ({ notificationConfig, type }) => {
  return (
    <Row className={styles.toastContainer}>
      <Col>
        <Row className={styles.title}>
          <Col xs="2" xm="2" className={styles.icon}>
            <i className={`icon ${getIcon(type)}`} />
          </Col>
          <Col className={styles.text}>{notificationConfig.title}</Col>
          <Col xs="1" md="1" className={styles.cross}>
            <i className="icon icon-cross" />
          </Col>
        </Row>
        <Row className={styles.message}>
          <Col className="d-flex">{notificationConfig.message}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ToastNotification;
