import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import { RootState, useTypedSelector } from "reducers";
import { CardPaymentsApis } from "services/admin/cards/payments/cardPaymentsApis";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { formattedAmount } from "services/general/helpers";
import styles from "./cardPaymentDetails.module.css";

interface CardPaymentDetailsPropsType {
  close: () => void;
  paymentID: number;
}
export const CardPaymentDetails = ({ close, paymentID }: CardPaymentDetailsPropsType) => {
  const currentUser = useTypedSelector((state: RootState) => state.user, shallowEqual) as IUser | undefined;
  const [paymentData, setPaymentData] = useState<any>();
  const paymentMethodData = paymentData?.payment_method;
  const submitterContactData = paymentData?.submitter?.contact;
  const contactAddressData = submitterContactData?.default_address;

  const formattedCityState = () => {
    return contactAddressData?.city && contactAddressData?.state
      ? `${contactAddressData.city}, ${contactAddressData.state}`
      : "";
  };

  const getPaymentDetails = useCallback(async () => {
    const res = await CardPaymentsApis.detail(paymentID);
    setPaymentData(res.data);
  }, [paymentID]);

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (
    <Modal show={true} size="lg" centered className={styles.mainStyle} contentClassName={styles.modalRound}>
      <Modal.Header className={styles.modalHeader}>
        <Row className="mx-0 justify-content-between align-items-center w-100">
          <Col className="px-0">Payment Details</Col>
          <Col className="px-0" style={{ flex: 0 }}>
            <i onClick={close} className={`${styles.modalClose} icon-close`}></i>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className={`mx-0 font-weight-bold`}>
          <Col className="px-0 text-nowrap" md={2}>
            Reference ID:
          </Col>
          <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
            {(paymentData?.reference_number || paymentData?.payment_number) ?? ""}
          </Col>
        </Row>
        <Row className="mx-0 font-weight-bold">
          <Col className="px-0 text-nowrap" md={2}>
            Status:
          </Col>
          <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
            {paymentData?.status ?? ""}
          </Col>
        </Row>

        <Row className="mx-0 py-3">
          <Card className={`w-100 h-100 ${styles.section}`}>
            <Row className={`mx-0 px-3  py-2 ${styles.sectionHeader}`}>
              <span>Payment Transaction Information</span>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Payment Date:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {companyDateFormat(paymentData?.payment_date, currentUser)}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Creation Date:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {companyDateFormat(paymentData?.created_at, currentUser)}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Submitted By:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {paymentData?.submitter?.name}
              </Col>
            </Row>
          </Card>
        </Row>

        <Row className="mx-0 py-3">
          <Card className={`w-100 h-100 ${styles.section}`}>
            <Row className={`mx-0 px-3  py-2 ${styles.sectionHeader}`}>
              <span>Payment Information</span>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Account Code:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {paymentData?.properties?.comdata_account_code}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Payment Amount:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {formattedAmount(`${paymentData?.amount}`, paymentMethodData?.currency_code, 2, true)}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Payment Method:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {paymentMethodData?.nick_name}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Bank Account Type:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {paymentMethodData?.account_type}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Account Holder Name:</label>
              </Col>
              <Col
                className="px-0 text-nowrap pl-5"
                style={{ flex: 0 }}
              >{`${paymentMethodData?.billing_first_name ?? ""} ${paymentMethodData?.billing_last_name ?? ""}`}</Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Institution Name:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {paymentMethodData?.bank_name}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Routing Number:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {paymentMethodData?.routing}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Account Number:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {paymentMethodData?.account_number}
              </Col>
            </Row>
          </Card>
        </Row>

        <Row className="mx-0 py-3">
          <Card className={`w-100 h-100 ${styles.section}`}>
            <Row className={`mx-0 px-3  py-2 ${styles.sectionHeader}`}>
              <span>Contact Information</span>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>First Name:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {submitterContactData?.first_name}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Last Name:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {submitterContactData?.last_name}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Phone Number:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {submitterContactData?.phone}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Email Address:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {submitterContactData?.email}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Address:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {contactAddressData?.address1}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>City, State</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {formattedCityState()}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 border-bottom py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Zip Code:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {contactAddressData?.zipcode}
              </Col>
            </Row>
            <Row className={`mx-0 px-3 py-2`}>
              <Col className="px-0 text-nowrap" md={2}>
                <label>Country:</label>
              </Col>
              <Col className="px-0 text-nowrap pl-5" style={{ flex: 0 }}>
                {contactAddressData?.country}
              </Col>
            </Row>
          </Card>
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Row className="mx-0 justify-content-end">
          <Button variant="primary" className="px-5 py-1" onClick={close}>
            Close
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
