import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { RenderField } from "../../forms/bootstrapFields";
import { password, required } from "../../../services/validations/reduxFormValidation";
import i18next from "i18next";

export const UPDATE_PASSWORD_FORM = "UPDATE_PASSWORD_FORM";

export type UpdatePasswordFormPropsType = {
  onSubmit: (formData: UpdatePasswordFormDataType) => void;
};

export type UpdatePasswordFormDataType = {
  current_password: string;
  password: string;
  confirm_password: string;
};

const validate = (values: UpdatePasswordFormDataType) => {
  const errors: UpdatePasswordFormDataType = {
    current_password: "",
    password: "",
    confirm_password: "",
  };
  if (values.current_password === values.password) {
    errors.password = i18next.t("components.admin.updatePassword.errors.differentNewPassword");
  }
  if (values.password !== values.confirm_password) {
    errors.confirm_password = i18next.t("components.admin.updatePassword.errors.passwordDontMatch");
  }
  return errors;
};

interface UpdatePasswordReduxFormPropsType
  extends UpdatePasswordFormPropsType,
    InjectedFormProps<UpdatePasswordFormDataType, UpdatePasswordFormPropsType> {}

const UpdatePasswordForm = ({ handleSubmit }: UpdatePasswordReduxFormPropsType) => {
  const { t } = useTranslation();
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="6">
          <Row>
            <Col sm="12">
              <Field
                name="current_password"
                id="currentPassword"
                type="password"
                label={t("components.admin.updatePassword.currentPassword")}
                component={RenderField}
                validate={[required]}
              />
            </Col>
            <Col sm="12">
              <Field
                name="password"
                id="Password"
                type="password"
                label={t("components.admin.updatePassword.newPassword")}
                component={RenderField}
                validate={[required, password]}
              />
            </Col>
            <Col sm="12">
              <Field
                name="confirm_password"
                id="confirmPassword"
                type="password"
                label={t("components.admin.updatePassword.confirmPassword")}
                component={RenderField}
                validate={[required, password]}
              />
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <p className="blackColor"> {t("components.admin.updatePassword.instruction.minimumLength")}</p>
          <p className="blackColor">{t("components.admin.updatePassword.instruction.characterConstraint")} </p>
          <ul>
            <li>{t("components.admin.updatePassword.instruction.upperCase")}</li>
            <li>{t("components.admin.updatePassword.instruction.lowerCase")}</li>
            <li>{t("components.admin.updatePassword.instruction.numbers")}</li>
            <li>{t("components.admin.updatePassword.instruction.specialCharacters")}</li>
          </ul>
        </Col>
        <Col sm="6">
          <Button className="w-100" type="submit">
            {t("components.admin.updatePassword.updatePassword")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const UpdatePassword = reduxForm<UpdatePasswordFormDataType, UpdatePasswordFormPropsType>({
  form: UPDATE_PASSWORD_FORM,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
  validate: validate,
})(UpdatePasswordForm);

export default UpdatePassword;
