import React, { ReactNode } from "react";
import { Card } from "react-bootstrap";
import style from "./panel.module.css";

export type PanelPropsType = {
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  hideCardBody?: boolean;
  wrapperClass?: string;
  cardClass?: string;
  cardHeaderClass?: string;
  cardBodyClass?: string;
  cardFooterClass?: string;
};

//using wrapper div to prevent vertical collapsing of magrin b/w siblling
const Panel = ({
  header,
  footer,
  children,
  wrapperClass,
  cardClass,
  cardHeaderClass,
  cardBodyClass,
  cardFooterClass,
  hideCardBody,
}: PanelPropsType) => {
  return (
    <div className={wrapperClass + " h-100"}>
      <Card className={style.panelDefault + " " + cardClass}>
        {header && (
          <Card.Header
            className={style.panelHeader + " " + cardHeaderClass + " " + (hideCardBody ? style.bodyHideHeader : "")}
          >
            {header}
          </Card.Header>
        )}
        {children && (
          <Card.Body className={cardBodyClass + " " + style.body + " " + (hideCardBody ? style.hideBody : "")}>
            {children}
          </Card.Body>
        )}
        {footer && <Card.Footer className={cardFooterClass}>{footer}</Card.Footer>}
      </Card>
    </div>
  );
};

export default Panel;
