import React, { useEffect } from "react";
import Panel from "../../../common/panel/panel";
import { Col, Row } from "react-bootstrap";
import useCollapsible from "../../../common/hooks/useCollapsible";
import { useTypedSelector } from "../../../../reducers";
import { useDispatch } from "react-redux";
import { getPurchasers, getVendorDocumentLinks } from "../../../../reducers/vp/companyProfileReducer";
import PurchaserRow from "./purchaserRow";

const DocumentPanel = () => {
  const dispatch = useDispatch();
  const purchasers = useTypedSelector((state) => state.vpCompanyProfileReducer.purchasers);
  const { collapseHandlerUi, collapsed } = useCollapsible({ defaultCollapse: false });

  const initializeDocumentPanel = () => {
    dispatch(getPurchasers());
    dispatch(getVendorDocumentLinks());
  };

  useEffect(() => {
    initializeDocumentPanel();
  }, []);

  return (
    <Panel
      header={
        <>
          <Row>
            <Col className="d-flex justify-content-between">
              <div>
                <div>
                  <i className="icon icon-documents-blue"></i>
                  Document
                </div>
                <div>
                  <p>
                    Select the options below to upload documents. Once uploaded, select the document again to preview
                  </p>
                </div>
              </div>
              <div className="my-auto mx-3">{collapseHandlerUi}</div>
            </Col>
          </Row>
        </>
      }
      hideCardBody={collapsed}
    >
      {purchasers.map((purchaser) => {
        return <PurchaserRow key={purchaser.id} purchaser={purchaser} />;
      })}
    </Panel>
  );
};

export default DocumentPanel;
