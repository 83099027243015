import React, { useCallback, useState } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import { ApprovalType } from "../../../../../services/admin/approvals/approvalsType";
import approvalsService from "../../../../../services/admin/approvals/approvalsSvc";
import { useTypedSelector } from "../../../../../reducers";
import { useHistory } from "react-router";
import CustomModal from "../../../../modals/customModal";
import { PoChangeOrderType } from "../../../../../services/admin/changeOrders/poChangeOrderApprovalsType";
import { noWhiteSpaceOnly } from "../../../../../services/validations/reduxFormValidation";
import { CreateNotification, NotificationType } from "../../../../../services/general/notifications";

type RejectModelPropsType = {
  showRejectModel: boolean;
  closeShowReject: () => void;
  actionableApprovalDetails: ApprovalType.DetailsType;
  poChangeOrderDetail: PoChangeOrderType.DetailType;
};

const RejectModal = ({
  showRejectModel,
  closeShowReject,
  actionableApprovalDetails,
  poChangeOrderDetail,
}: RejectModelPropsType) => {
  const [validateError, setValidateError] = useState<string>("");
  const currentUser = useTypedSelector((state) => state.user);
  const [approverNote, setApproverNote] = useState("");
  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateError) {
      return;
    }
    const approval = { ...actionableApprovalDetails, approver_note: approverNote };
    try {
      const res = await approvalsService.submit(approval, "rejected", currentUser);
      if (res) {
        CreateNotification("Approval Rejected", `Rejected CO# ${poChangeOrderDetail.number}`, NotificationType.danger);
      }
      closeShowReject();
      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      CreateNotification(
        "Approval Error",
        `Could not reject CO# ${poChangeOrderDetail.number}`,
        NotificationType.danger,
      );
    }
  };

  const checkReasonValidation = useCallback((e) => {
    const error = noWhiteSpaceOnly(e.target.value);
    error && setValidateError(error);
    !error && setValidateError("");
    setApproverNote(e.target.value);
  }, []);

  return (
    <CustomModal
      show={showRejectModel}
      onHide={closeShowReject}
      header={<>Reject {poChangeOrderDetail?.number}</>}
      body={
        <>
          <Form role="form" method="post" onSubmit={onFormSubmit}>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Please provide your reason for rejection</label>
                  <span className="color-red">*</span>
                  <FormControl
                    as="textarea"
                    value={approverNote}
                    placeholder="Please provide your reason for rejection"
                    onChange={checkReasonValidation}
                    required
                  ></FormControl>
                  {validateError ? <div style={{ color: "red" }}>{validateError}</div> : null}
                </div>
              </Col>
            </Row>
            <div className="modal-footer">
              <Button type="submit">Reject</Button>
            </div>
          </Form>{" "}
        </>
      }
      size="lg"
    />
  );
};

export default RejectModal;
