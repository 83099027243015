import React, { useEffect, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  onHideManageAccountModal,
  setAccountsModalOpen,
  MANAGEACCOUNT_FORM_NAME,
  setManageAccountForm,
} from "../../../reducers/admin/manageAccountFormReducer";
import CustomModal from "../../modals/customModal";
import accountsRenderForm from "./accountsRenderForm";
import { reduxForm, FieldArray } from "redux-form";
import _ from "lodash";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { getAccountsData } from "../../../services/common/manageAccounts.svc";
import RestApi from "../../../providers/restApi";
const restApiService = new RestApi();

let ManageDefaultAccountsModal = (props) => {
  const { modelData, handleSubmit, modelName } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //opening Modal
  const initializeAccountForm = useCallback(() => {
    dispatch(setAccountsModalOpen(true));
  }, [dispatch]);

  //Initialise Accounts Array Data
  const intialiseAccountData = () => {
    const accountArray = getAccountsData(modelData);
    dispatch(setManageAccountForm(accountArray));
  };

  useEffect(() => {
    initializeAccountForm();
    intialiseAccountData();
  }, [modelData]);

  const isModalOpen = useSelector((state) => state.manageAccountFormRed.isModalOpen);

  const cancel = () => {
    dispatch(onHideManageAccountModal());
    if (props.hide) {
      props.hide();
    }
  };

  return (
    <CustomModal
      header=<span className="text-capitalize">
        {t("components.admin.manageDefaultAccount.editAccounts", { modelName })}
      </span>
      show={isModalOpen}
      onHide={cancel}
      body={
        <Form noValidate id={MANAGEACCOUNT_FORM_NAME} onSubmit={(value) => handleSubmit(value, dispatch, props)}>
          <FieldArray name="form.accounts" component={accountsRenderForm} />
        </Form>
      }
      footer={
        <>
          <Button variant="secondary" onClick={() => cancel()}>
            {t("components.admin.manageDefaultAccount.cancel")}
          </Button>
          <Button type="submit" form={MANAGEACCOUNT_FORM_NAME}>
            {t("components.admin.manageDefaultAccount.save")}
          </Button>
        </>
      }
      size={"lg"}
    />
  );
};

ManageDefaultAccountsModal = reduxForm({
  form: MANAGEACCOUNT_FORM_NAME, // a unique identifier for this form
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
  onSubmit: async (formData, dispatch, props) => {
    let obj = {};
    if (formData && formData.form && _.isArray(formData.form.accounts))
      formData.form.accounts.forEach((account) => {
        obj[`${account.account_group}`] = account.account_links;
      });
    var restObject;
    try {
      switch (props.modelName) {
        case "company":
          restObject = await restApiService.patch("company", null, {
            company: obj,
          });
          break;
        case "subsidiary":
          let id = props?.modelData.id;
          restObject = await restApiService.patch("subsidiaries/" + id, null, {
            subsidiary: obj,
          });
          break;
        default:
          var patchData = {};
          patchData[props.modelName] = obj;
          let apiEndPoint = props.apiModelName ? props.apiModelName : props.modelName + "s";
          restObject = await restApiService.patch(apiEndPoint, null, patchData);
      }
      if (restObject) {
        if (restObject.status == 200) {
          CreateNotification("Success", "Changes Saved Successfully", NotificationType.success);
          dispatch(onHideManageAccountModal());
          props.saveEditCallback();
        }
      }
    } catch (err) {
      const { response } = err;
      if (response.status === 422) {
        if (_.isPlainObject(response.data)) {
          for (var key in response.data) {
            if (response.data.hasOwnProperty(key) && key !== "base") {
              CreateNotification(
                "Validation Error Occured",
                key + " " + err.response.data[key],
                NotificationType.danger,
              );
            }
          }
        }
      }
    }
  },
})(ManageDefaultAccountsModal);

ManageDefaultAccountsModal = connect((state, props) => ({
  initialValues: state.manageAccountFormRed.manageAccountForm,
}))(ManageDefaultAccountsModal);

export default ManageDefaultAccountsModal;
