import { WrappedFieldProps } from "redux-form";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import Select from "react-select";
import { findSelectedSingle, onBlurSingle, onChangeSingle } from "services/general/helpers";
import _ from "lodash";

type VendorLocationPickerPropTypes = {
  label?: string;
  labelClassName?: string;
  tooltip?: string;
  modelData?: any;
} & WrappedFieldProps;

type AddressType = {
  id: number;
  address1: string;
  address2?: string | null;
  address3?: string | null;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  label?: string | null;
  address_type?: string;
  is_default?: boolean;
  status?: string;
};

type VendorLocationListType = {
  id?: number;
  number?: number;
  vendor_id?: number;
  vendor_name?: string;
  status?: string;
  name?: string;
  addresses?: AddressType[];
};

type VendorLocationOptionType = {
  value?: number;
  label?: string;
} & VendorLocationListType;

const parseForSelect = (vendorLocations: VendorLocationListType[]) => {
  return vendorLocations.map((vendorLocation) => ({
    value: vendorLocation?.id,
    label: vendorLocation?.name,
    ...vendorLocation,
  }));
};

const getDefaultOrFirstAddress = (locations: VendorLocationOptionType[]) => {
  if (_.isArray(locations)) {
    return locations
      .filter((location: VendorLocationOptionType) => location.addresses && location.addresses.length > 0)
      .map((location: VendorLocationOptionType) => {
        const defaultAddress = location?.addresses?.find((address: any) => address.is_default);
        let newLocation = {
          ...location,
          addresses: defaultAddress
            ? [defaultAddress]
            : location?.addresses && location?.addresses.length > 0
              ? [location.addresses[0]]
              : [],
        };
        return newLocation;
      });
  } else {
    return [];
  }
};

const VendorLocationPicker = ({ modelData, label, tooltip, input }: VendorLocationPickerPropTypes) => {
  const [vendorLocation, setVendorLocation] = useState<VendorLocationOptionType[]>([]);

  const getPurchaseOrders = async () => {
    try {
      const result: AxiosResponse<VendorLocationListType[]> = await restApiService.get(
        "vendors/" + modelData.vendor.id + "/vendor_locations",
      );
      let allLocations = parseForSelect(result.data);
      let activeLocations = allLocations.filter(function (location) {
        return location.status === "ACTIVE";
      });
      setVendorLocation(getDefaultOrFirstAddress(activeLocations));
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeValue = (selected: VendorLocationOptionType) => {
    onChangeSingle(input, selected);
  };

  useEffect(() => {
    if (modelData?.vendor?.id) {
      getPurchaseOrders();
    }
  }, [modelData.vendor]);

  return (
    <Form.Group>
      <Form.Label>
        {label ?? ""}
        {tooltip ?? ""}
      </Form.Label>
      <Select
        value={findSelectedSingle(input, vendorLocation)}
        onBlur={() => onBlurSingle(input, input.value)}
        placeholder="Select Location"
        options={vendorLocation}
        formatOptionLabel={(option, context) =>
          context.context == "menu" ? (
            <small className="selectChoices">
              <strong>
                {option.name} - {option.addresses[0].address1} - Address Type: {option.addresses[0].address_type}
              </strong>
              <br />
              <strong>{option.addresses[0].address1}</strong>
              <br />
              {option.addresses[0].address2 && (
                <>
                  <strong>{option.addresses[0].address2}</strong>
                  <br />{" "}
                </>
              )}
              {option.addresses[0].address3 && (
                <>
                  <strong>{option.addresses[0].address3}</strong>
                  <br />{" "}
                </>
              )}
              <strong>{option.addresses[0].city}</strong> <strong>{option.addresses[0].state}</strong>{" "}
              <strong>{option.addresses[0].zipcode}</strong> <strong>{option.addresses[0].country}</strong>
              <br />
            </small>
          ) : (
            <span>
              {option.addresses[0].address1} {option.addresses[0].city} {option.addresses[0].state}{" "}
              {option.addresses[0].zipcode} {option.addresses[0].country}
            </span>
          )
        }
        onChange={(selected: VendorLocationOptionType) => onChangeValue(selected)}
      />
    </Form.Group>
  );
};

export default VendorLocationPicker;
