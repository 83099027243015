import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Field, FieldArrayFieldsProps } from "redux-form";
import { RenderField } from "../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { SubsidiaryDepartmentType } from "../../../services/admin/subsidiaries/types";
import { IDType } from "../../../services/common/types/common.type";
import ContactPicker from "../pickers/reduxFormPickers/contactPicker";

type LinkManagerRendererType = {
  fields: FieldArrayFieldsProps<FieldPropsType>;
  isMultipleManagerAllowed: boolean;
  isDepartmentManagerLinker: boolean;
};

type FieldPropsType = {
  location_id?: IDType;
  department_id?: IDType;
  manager_id: IDType;
  level: number;
  _destroy?: IDType;
  existingLinks?: SubsidiaryDepartmentType[];
};

const LinkManagerRenderer = ({
  fields,
  isMultipleManagerAllowed,
  isDepartmentManagerLinker,
}: LinkManagerRendererType) => {
  const { t } = useTranslation();
  const [hiddenFields, setHiddenFields] = useState<number[]>([]);

  const handleAddField = (isDepartmentManagerLinker: boolean) => {
    const newField: FieldPropsType = {
      manager_id: "",
      level: fields.length + 1,
    };

    if (isDepartmentManagerLinker) {
      newField.department_id = "";
    } else {
      newField.location_id = "";
    }

    fields.push(newField);
  };

  // Hiding deleted managers fields
  const toggleHiddenField = (index: number) => {
    setHiddenFields((prevHiddenFields) =>
      prevHiddenFields.includes(index)
        ? prevHiddenFields.filter((item) => item !== index)
        : [...prevHiddenFields, index],
    );
  };

  const handleRemoveField = (index: number) => {
    const item = fields.get(index);
    const updatedItem = { ...item, _destroy: 1 };
    fields.remove(index);
    fields.insert(index, updatedItem);
  };

  const handleRemoveSingleManager = (removedManagerId: number, index: number) => {
    const item = fields.get(index);
    let removedManager =
      _.isArray(item?.existingLinks) &&
      item?.existingLinks?.find((link: SubsidiaryDepartmentType) => link.manager_id === removedManagerId);
    if (removedManager) {
      removedManager._destroy = 1;
    }
  };

  return (
    <>
      {/* FIELD RENDERS */}
      {fields &&
        fields.length > 0 &&
        fields.map((managerField, index) =>
          hiddenFields.includes(index) ? null : (
            <Row key={index}>
              <Col sm="8">
                <Field
                  name={`${managerField}.manager_id`}
                  component={ContactPicker}
                  meta={{ touched: false }}
                  contactType={"USERS"}
                  menuPosition="fixed"
                  isMulti={isMultipleManagerAllowed}
                  inputId="multiple-contact-picker"
                  callBack={(contactId: number) => handleRemoveSingleManager(contactId, index)}
                />
              </Col>
              <Col sm="3">
                <Field
                  name={`${managerField}.level`}
                  component={RenderField}
                  className="w-100"
                  type="number"
                  meta={{ touched: false }}
                />
              </Col>
              <Col sm="1">
                <span>
                  <i
                    onClick={() => {
                      handleRemoveField(index);
                      toggleHiddenField(index);
                    }}
                    className="icon icon-delete pointerHover background-position-center mt-1 py-3 ml-n2"
                  ></i>
                </span>
              </Col>
            </Row>
          ),
        )}
      <Button size="sm" onClick={() => handleAddField(isDepartmentManagerLinker)}>
        <i className="icon icon-plus" aria-hidden="true"></i> {t("subsidiary.manager")}
      </Button>
    </>
  );
};

export default LinkManagerRenderer;
