// BudgetPicker.tsx
import React, { memo, useCallback, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import AbstractListPicker from "../abstractListPicker";
import styles from "./budgetPicker.module.css";

type TRenderedOption = {
  code: string;
  label: string;
  balance?: number;
  startDate: string;
  endDate: string;
  currency: string;
};

type TBudgetOption = {
  id?: number;
  label?: string;
  amount?: number;
  code: string;
  budget_allocated: number;
  budget_balance?: number;
  balance?: number;
  status: string;
  start_date: Date;
  end_date: Date;
  currency?: TCurrency;
  value: any;
  country: string;
  effective_date: string;
  budget: TBudgetDetails;
};

type TCurrency = {
  iso_code: string;
  symbol: string;
};

type TBudgetDetails = {
  id: number;
  code: string;
  label: string;
  start_date: string;
  end_date: string;
  currency_code: string;
};

type TBudgetPickerProps = {
  name: string;
  objectName?: string;
  idPropertyKey?: string;
  required?: boolean;
  activeOnly?: boolean;
  items?: number;
  multiple?: boolean;
  label?: string;
  accountIdName?: string;
  departmentIdName?: string;
  currencyCodeName?: string;
  vendorIdName?: string;
};

const BudgetPicker = ({
  name,
  objectName = "budget",
  label,
  multiple = false,
  required = false,
  activeOnly = true,
  items = 150,
  accountIdName = "account_id",
  departmentIdName = "department_id",
  currencyCodeName = "currency_code",
  vendorIdName = "vendor_id",
}: TBudgetPickerProps) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const isMultiCurrencyAllowed = currentUser?.company?.budget?.allow_multi_currency_budget;
  const isBudgetFilterAllowed = currentUser?.company?.budget?.show_field_at === "line_level";
  const accountId = useWatch({ name: accountIdName });
  const departmentId = useWatch({ name: departmentIdName });
  const currencyCode = useWatch({ name: currencyCodeName });
  const vendorId = useWatch({ name: vendorIdName });

  const onMenuOpen = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  const getOptionToRender = useCallback(
    (option: TBudgetOption): TRenderedOption => {
      const startDate = multiple ? option.budget.start_date : option.start_date;
      const endDate = multiple ? option.budget.end_date : option.end_date;
      const currency = multiple ? option.budget.currency_code : option.currency?.iso_code;

      const simpleOption: TRenderedOption = {
        code: multiple ? option.budget.code : option.code,
        label: multiple ? option.budget.label : (option.label ?? ""),
        balance: multiple ? option.balance : option.budget_balance,
        startDate: companyDateFormat(startDate, currentUser),
        endDate: companyDateFormat(endDate, currentUser),
        currency: currencySymbolRenderer(currency),
      };
      return simpleOption;
    },
    [currentUser, multiple],
  );

  const getTooltip = useCallback((option: TRenderedOption) => {
    return `${option.label}; ${option.currency}${option.balance}; ${option.startDate} - ${option.endDate}`;
  }, []);

  const formatOptionLabel = useCallback(
    (option: TBudgetOption) => {
      if (!option.id) {
        return <div>{option.label}</div>;
      }

      const optionToRender = getOptionToRender(option);
      const balance = optionToRender.balance;
      const code = optionToRender.code;
      const label = optionToRender.label;
      const startDate = optionToRender.startDate;
      const endDate = optionToRender.endDate;
      const currencySymbol = optionToRender.currency;
      const isPositiveBalance = balance && balance > 0;

      return (
        <div className={styles.optionLabel} title={getTooltip(optionToRender)}>
          <span className={styles.optionLabelTitle}>{label}</span>
          {!isDropdownOpen && option.id && (
            <span className={isPositiveBalance ? styles.optionLabelPositiveBalance : styles.optionLabelNegativeBalance}>
              {`${t("components.common.pickers.budget.balance")}: ${currencySymbol}${balance}`}
            </span>
          )}
          {isDropdownOpen && option.id && (
            <div className={styles.optionLabelDetails}>
              <div className={styles.optionLabelRow}>{`${t("components.common.pickers.budget.code")}: ${code}`}</div>
              <div className={styles.optionLabelRow}>
                {`${t("components.common.pickers.budget.amount")}: ${currencySymbol}${option.amount}`} |{" "}
                <span
                  className={isPositiveBalance ? styles.optionLabelPositiveBalance : styles.optionLabelNegativeBalance}
                >
                  {`${t("components.common.pickers.budget.balance")}: ${currencySymbol}${balance}`}
                </span>
              </div>
              <div className={styles.optionLabelRow}>
                {option.budget_allocated &&
                  `${t("components.common.pickers.budget.allocated")}: ${currencySymbol}${option.budget_allocated} | `}
                {option.status && `${t("components.common.pickers.budget.status")}: ${option.status}`}
              </div>
              <div
                className={styles.optionLabelRow}
              >{`${t("components.common.pickers.budget.startDate")}: ${startDate}`}</div>
              <div
                className={styles.optionLabelRow}
              >{`${t("components.common.pickers.budget.endDate")} ${endDate}`}</div>
              <div className={styles.optionLabelRow}>{option.country}</div>
            </div>
          )}
        </div>
      );
    },
    [getOptionToRender, getTooltip, isDropdownOpen, t],
  );

  const params = useMemo(() => {
    const apiParams: any = { active: activeOnly, items };

    if (accountId && isBudgetFilterAllowed) {
      apiParams.account_id = accountId;
    }

    if (departmentId) {
      apiParams.department_id = departmentId;
    }

    if (currencyCode && !isMultiCurrencyAllowed) {
      apiParams.currency_code = currencyCode;
    }

    if (vendorId && isMultiCurrencyAllowed) {
      apiParams.vendor_id = vendorId;
    }

    return apiParams;
  }, [
    accountId,
    activeOnly,
    currencyCode,
    departmentId,
    isBudgetFilterAllowed,
    isMultiCurrencyAllowed,
    items,
    vendorId,
  ]);

  const fetchUrl = useMemo(() => {
    return multiple ? "budgets/budget_items" : "budgets.lk";
  }, [multiple]);

  return (
    <AbstractListPicker<TBudgetOption>
      fetchUrl={fetchUrl}
      name={name}
      objectName={objectName}
      label={label}
      labelPropertyName="label"
      params={params}
      required={required}
      multiple={multiple}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export default memo(BudgetPicker);
