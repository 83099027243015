import React, { useEffect, useState } from "react";
import { restApiService } from "../../../providers/restApi";
import { VendorInvoiceStats, DashboardSummary, ActionableItemsDataType, ProfileDataType } from "./types";
import { getGuidePurchasers, getSetupProgress } from "../../../reducers/vp/vendorSetUpGuideReducer";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Welcome from "../../../components/vp/dashboard/vendorSetupGuide/welcome";
import { Can } from "../../../services/authorization/authorization";
import VerifyDataTable from "../../../components/vp/contacts/verifyDataTable/verifyDataTable";
import {
  Profile,
  Upgrade,
  ActionableItems,
  Invoice,
  InvoiceAging,
  NeedHelp,
  AvgPayment,
} from "../../../components/vp/dashboard/content/dashboardSections";
import { useTypedSelector } from "../../../reducers";
import { isPaymentMethodLinkingRequired } from "../../../services/paymentMethods/paymentMethodLinking";

type DashboardProps = {
  myname: string;
  dashboard_profile_progress: any;
  isGuideEnabled: boolean;
  getSetupProgress: (onSuccess: () => void) => void;
  getGuidePurchasers: (onSuccess: () => void) => void;
};

type DashboardStatesSummary = {
  vendor_invoices_stats: VendorInvoiceStats;
  dashboard_summary: DashboardSummary;
  setupProgressLoaded: boolean;
  isPurchasersLoaded: boolean;
};

const Dashboard = (props: DashboardProps) => {
  const dispatch = useDispatch();
  const [dashboardState, setDashboardState] = useState<DashboardStatesSummary>({
    vendor_invoices_stats: {} as VendorInvoiceStats,
    dashboard_summary: {} as DashboardSummary,
    setupProgressLoaded: false,
    isPurchasersLoaded: false,
  });

  const purchasers = useTypedSelector((state) => state.vendorSetupGuideRed.purchasers);
  const linkingRequired = isPaymentMethodLinkingRequired(purchasers);
  const dashboardProfileProgress = useTypedSelector((state) => state.vendorSetupGuideRed.setupProgress);

  const profileData: ProfileDataType[] = [
    {
      name: "contactInfo",
      label: "dashboardPage.contactInfo",
      link: "ar/contacts",
      condition: dashboardProfileProgress.contact_info,
    },
    {
      name: "paymentMethods",
      label: "dashboardPage.paymentMethods",
      link: "/ar/banking_information",
      condition: dashboardProfileProgress.bank_info && !linkingRequired,
    },
    {
      name: "companyDetails",
      label: "dashboardPage.companyDetails",
      link: "ar/settings",
      condition: dashboardProfileProgress.company_detail,
    },
    {
      name: "requiredDocuments",
      label: "dashboardPage.requiredDocuments",
      link: "ar/settings",
      condition: dashboardProfileProgress.required_documents_completed,
    },
  ];

  const ActionableItemsData: ActionableItemsDataType[] = [
    {
      item: "Newly Approved Purchase Orders",
      weight: "10",
      background: "#e1f6f1",
      fontColor: "#2ED9C3",
    },
    {
      item: "Accrual Requests Requiring Attention",
      weight: "10",
      background: "#ffefe8",
      fontColor: "#FF5C35",
    },
    {
      item: "New Invoice Requests",
      weight: "10",
      background: "#eaf4fb",
      fontColor: "#00A0DF",
    },
    {
      item: "Pending Payments",
      weight: "10",
      background: "#e1f6f1",
      fontColor: "#2ED9C3",
    },
  ];

  const getVendorInvoicesStats = async () => {
    try {
      const statsResponse = await restApiService.get("vendor_invoices/stats");
      if (statsResponse.data) {
        setDashboardState((preState) => ({ ...preState, vendor_invoices_stats: statsResponse.data }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDashboardSummary = async () => {
    try {
      const summaryResponse = await restApiService.get("vendor_invoices/dashboard_summary");
      if (summaryResponse.data) {
        setDashboardState((prevState) => ({ ...prevState, dashboard_summary: summaryResponse.data }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSetupProgressLoadSuccess = () => {
    setDashboardState((prevState) => ({ ...prevState, setupProgressLoaded: true }));
  };

  const onGuidePurchaserLoadsSuccess = () => {
    setDashboardState((prevState) => ({ ...prevState, isPurchasersLoaded: true }));
  };

  useEffect(() => {
    getVendorInvoicesStats();
    getDashboardSummary();
    dispatch(getSetupProgress(onSetupProgressLoadSuccess));
    dispatch(getGuidePurchasers(onGuidePurchaserLoadsSuccess));
  }, []);

  return (
    <div>
      <Can I={"read"} a={"Company"}>
        {dashboardState.setupProgressLoaded && dashboardState.isPurchasersLoaded && <Welcome />}
      </Can>
      <Container fluid={true} className={"pt-4 pb-4"}>
        <Row>
          <Col className="my-4 zIndex-1">
            <VerifyDataTable />
          </Col>
        </Row>
        <Row className={"mb-4"}>
          <Can I={"read"} a={"Company"}>
            <Profile
              profileData={profileData}
              content={dashboardProfileProgress}
              setupProgressLoaded={dashboardState.setupProgressLoaded}
              isPurchasersLoaded={dashboardState.isPurchasersLoaded}
              isLinkingRequired={linkingRequired}
            />
          </Can>
          <Invoice />
        </Row>
        <Row className={"mt-4 mb-4"}>
          <InvoiceAging content={dashboardState.dashboard_summary} />
        </Row>
        <Row className={"mt-4 mb-4"}>
          {/* <Upgrade/> */}
          {/* <NeedHelp /> */}
          <AvgPayment content={dashboardState.dashboard_summary} />
          {/* <ActionableItems content={ActionableItemsData}/> */}
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
