import React, { Component } from "react";
import RestApi from "../../../providers/restApi";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import Panel from "../../../components/panel/panel";
import { Link, withRouter } from "react-router-dom";
import styles from "./vcardOnboarding.module.css";

class VCardOnboardingSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();

    this.backURL = "/ap/onboarding/vcards";
    this.item_id = "settings_defined";
  }

  componentDidMount() {
    if (!this.props.onboaringState.listTemplate) {
      //cant continue as the template is not defined, go to our back url
      this.props.history.push(this.backURL);
    }
  }

  submit = (event) => {
    let valid = event.currentTarget.checkValidity();
    // this.set_vcard_details().then(this.set_default_accounts()).then(this.set_details_complete())
    this.set_vcard_details().then(this.set_details_complete());

    event.preventDefault();
    event.stopPropagation();
    return;
  };

  set_details_complete = () => {
    //post the item_id to api
    this.restApiService
      .post("virtual_cards/onboarding/checklist/item", null, {
        item_id: this.item_id,
        value: true,
      })
      .then((result) => {
        if (result.status == 200) {
          //change the redux state to reflect this item is completed
          this.props.onboaringState.listTemplate.items.forEach((element) => {
            if (element.id == this.item_id) {
              element.completed = true;
            }
          });
          this.props.setListTemplateItems(this.props.onboaringState.listTemplate.items);
          //go back to main checklist page
          this.props.history.push(this.backURL);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async set_vcard_details() {
    var vcard_settings = {
      enable_auto_add_funds: false,
      amount_to_auto_fund: 0,
      minimum_balance_threshold: 0,
    };

    if (this.state.addFundsLowBalance == true) {
      vcard_settings.enable_auto_add_funds = true;
      if (typeof this.state.lowBalanceAmountToAdd != "undefined")
        vcard_settings.amount_to_auto_fund = parseFloat(this.state.lowBalanceAmountToAdd);
      if (typeof this.state.lowBalanceTriggerAmount != "undefined")
        vcard_settings.minimum_balance_threshold = parseFloat(this.state.lowBalanceTriggerAmount);
    }

    if (this.state.allowNonPO == true) {
      vcard_non_po_limit = 0;
      if (typeof this.state.nonPOAmount != "undefined") var vcard_non_po_limit = parseFloat(this.state.nonPOAmount);
    } else {
      var vcard_non_po_limit = 0;
    }
    var company_default = {
      virtual_card: vcard_settings,
      vcard_non_po_limit: vcard_non_po_limit,
    };

    this.restApiService
      .patch("company/default", null, {
        company_default: company_default,
      })
      .then((result) => {
        if (result.status == 200) {
          return;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <Container className={"pt-4 pb-4"}>
        <Row style={{ minHeight: "150px" }}>
          <Col>
            <Form noValidate validated={this.state.validated} onSubmit={this.submit}>
              <Panel
                cardClasses={"animated fadeIn"}
                icon={
                  <Link to={this.backURL}>
                    <h5 className="text-right">X cancel</h5>
                  </Link>
                }
                panelContent={{ heading: "Define Your Virtual Card Settings" }}
              >
                <Form.Row>
                  <Form.Group
                    as={Col}
                    sm="4"
                    controlId="nonPOCheckbox"
                    className={"d-flex justify-content-center flex-column mb-0"}
                  >
                    <Form.Check name="allowNonPO">
                      <Form.Check.Label className="secondaryFontColor">Allow Non-PO Virtual Cards</Form.Check.Label>
                      <Form.Check.Input
                        type={"checkbox"}
                        isValid
                        className={styles.settingsCheckmark}
                        onChange={(e) => {
                          this.setState({ allowNonPO: e.target.checked });
                        }}
                      />
                    </Form.Check>
                  </Form.Group>
                  <Form.Group as={Col} sm="8" controlId="nonPOAmount">
                    <Form.Label className="secondaryFontColor">{"Max balance for non-PO vCards:"}</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="number"
                        required
                        name="nonPOAmount"
                        onChange={this.onChange}
                        disabled={!this.state.allowNonPO}
                      />
                      <Form.Control.Feedback type="invalid">{this.props.t("validation.amount")}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group
                    as={Col}
                    sm="4"
                    controlId="fundsLowCheckmark"
                    className={"d-flex justify-content-center flex-column mb-0"}
                  >
                    <Form.Check name="allowNonPO">
                      <Form.Check.Label className="secondaryFontColor">Add Funds On Low Balance</Form.Check.Label>
                      <Form.Check.Input
                        type={"checkbox"}
                        isValid
                        className={styles.settingsCheckmark}
                        onChange={(e) => {
                          this.setState({ addFundsLowBalance: e.target.checked });
                        }}
                      />
                    </Form.Check>
                  </Form.Group>
                  <Form.Group as={Col} sm="4" controlId="triggerAmount">
                    <Form.Label className="secondaryFontColor">{"Trigger balance amount:"}</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="number"
                        required
                        name="lowBalanceTriggerAmount"
                        onChange={this.onChange}
                        disabled={!this.state.addFundsLowBalance}
                      />
                      <Form.Control.Feedback type="invalid">{this.props.t("validation.amount")}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} sm="4" controlId="autoAdd">
                    <Form.Label className="secondaryFontColor">{"Amount to auto add:"}</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="number"
                        required
                        name="lowBalanceAmountToAdd"
                        onChange={this.onChange}
                        disabled={!this.state.addFundsLowBalance}
                      />
                      <Form.Control.Feedback type="invalid">{this.props.t("validation.amount")}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <hr />
                <div className="text-right">
                  <button type="submit" className="btn btn-primary">
                    Save Settings
                  </button>
                </div>
              </Panel>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    onboaringState: state.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setListTemplateItems: (payload) => {
      dispatch({ type: actionType.SET_ONBOARDING_LIST_TEMPLATE_ITEMS, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(VCardOnboardingSettings)));
