import React, { useEffect, useState } from "react";
import FormSubsidiary from "./form";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import SubsidiaryApis from "../../../services/admin/subsidiaries/subsidiaryApis";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import _ from "lodash";
import {
  SubsidiaryPayloadType,
  SubsidiaryDetailType,
  SubsidiaryLocationType,
  ManagersLinkType,
  SubsidiaryDepartmentType,
  SubsidiaryLinksType,
} from "../../../services/admin/subsidiaries/types";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";
import {
  getSubsidiaryLinksAttributes,
  getSubsidiaryManagerLocationLink,
  transformAllSubsidiaryLinksToApi,
  transformAllSubsidiaryLinksFromApi,
} from "../../../services/admin/subsidiaries/subsidiarySvc";
import { IDType } from "../../../services/common/types/common.type";

export type ManagerType = {
  id?: IDType;
  level?: number;
  name?: string;
  location_id?: IDType;
  department_id?: number;
  manager_id?: IDType;
};

type LocationType = {
  id: IDType;
  name: string;
};

const EditSubsidiary = () => {
  type LocationStateType = {
    isFromDetailPage?: boolean;
  };

  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [subsidiary, setSubsidiary] = useState<SubsidiaryDetailType>();
  const [returnTo, setReturnTo] = useState<string>("");

  const manipulateLocationLink = async (response: SubsidiaryDetailType) => {
    let subsidiaryLocationLinksAttributes: SubsidiaryLocationType[] = response.subsidiary_manager_location_links || [];

    // Create an object to store locationManagersLink for each unique location_id
    let locationManagersLinkMap: {
      [locationId: IDType]: { id: IDType; managersLink: ManagerType[]; location: LocationType };
    } = {};

    // Create a map of location objects indexed by locationId
    let locationMap: { [locationId: string]: SubsidiaryLocationType } = {};
    subsidiaryLocationLinksAttributes.map((location: SubsidiaryLocationType) => {
      locationMap[location.id] = location;
    });

    // Iterate through the subsidiaryLocationLinksAttributes to populate the locationManagersLinkMap
    subsidiaryLocationLinksAttributes.map((locationLink: SubsidiaryLocationType) => {
      const { id, location, location_id, level, manager_id }: SubsidiaryLocationType = locationLink;
      if (!locationManagersLinkMap[location_id]) {
        locationManagersLinkMap[location_id] = {
          managersLink: [],
          id: locationLink.id,
          location: location,
        };
      }
      // Push the data to the corresponding managersLink array in the locationManagersLinkMap
      locationManagersLinkMap[location_id].managersLink.push({ id, location_id, level, manager_id });
    });

    // Convert locationManagersLinkMap to an array of objects
    const locationManagersLinkDataArray: any[] = Object.entries(locationManagersLinkMap).map(
      ([location_id, { managersLink, location }]) => ({
        location_id: parseInt(location_id),
        location: location,
        managersLink: managersLink,
      }),
    );
    response.subsidiary_manager_location_links_attributes = locationManagersLinkDataArray;
  };

  const transformSubsidiaryLinks = async (subsidiary_links: SubsidiaryDepartmentType[]) => {
    const result = subsidiary_links.reduce((acc: any, item: SubsidiaryDepartmentType) => {
      if (item._destroy === 1) {
        // If _destroy is 1, skip this item and continue to the next iteration.
        return acc;
      }

      const existingDepartment = acc.find(
        (department: ManagersLinkType) => department.department_id === item.department_id,
      );

      if (existingDepartment) {
        const existingLevel = existingDepartment.managersLink?.find(
          (link: ManagersLinkType) => link.level === item.level,
        );

        if (existingLevel) {
          existingLevel?.manager_id?.push(item.manager_id);
          existingLevel?.existingLinks?.push(item);
        } else {
          existingDepartment?.managersLink.push({
            department_id: item.department_id,
            level: item.level,
            manager_id: [item.manager_id],
            existingLinks: [item],
          });
        }
      } else {
        acc.push({
          department_id: item.department_id,
          managersLink: [
            {
              department_id: item.department_id,
              level: item.level,
              manager_id: [item.manager_id],
              existingLinks: [item],
            },
          ],
        });
      }
      return acc;
    }, []);

    return result;
  };

  const manipulateDepartmentLink = async (response: SubsidiaryDetailType, subsidiaryLinks: SubsidiaryLinksType[]) => {
    const subsidiaryLinksAttributes = await transformSubsidiaryLinks(subsidiaryLinks);
    response.subsidiary_links_attributes = subsidiaryLinksAttributes;
  };

  const getSubsidiary = async () => {
    try {
      const response = await SubsidiaryApis.getSubsidiary(id);
      await manipulateLocationLink(response);
      if (response.subsidiary_links) {
        await manipulateDepartmentLink(response, response.subsidiary_links);
      }
      transformAllSubsidiaryLinksFromApi(response);

      const isFromDetailPage: LocationStateType = history?.location?.state || false;
      setReturnTo(isFromDetailPage ? `/ap/subsidiaries/${id}` : "/ap/subsidiaries");
      setSubsidiary(response);
    } catch (error) {
      console.error("Error occurred while fetching subsidiary data:", error);
    }
  };

  useEffect(() => {
    getSubsidiary();
  }, [id]);

  const managePayload = async (subsidiary: SubsidiaryDetailType) => {
    subsidiary.subsidiary_manager_location_links_attributes = await getSubsidiaryManagerLocationLink(subsidiary);
    subsidiary.subsidiary_links_attributes = await getSubsidiaryLinksAttributes(subsidiary);
  };

  const handleSubmit = async (subsidiary: SubsidiaryDetailType) => {
    await managePayload(subsidiary);

    //will do all other subsidiary links besides location, department which are legacy
    transformAllSubsidiaryLinksToApi(subsidiary);
    const payload: SubsidiaryPayloadType = {
      subsidiary: {
        id: subsidiary.id,
        currency_code: subsidiary.currency_code,
        country: subsidiary.country,
        external_id: subsidiary.external_id,
        name: subsidiary.name,
        sub_type: subsidiary.sub_type,
        subsidiary_links: subsidiary.subsidiary_links,
        subsidiary_links_attributes: subsidiary.subsidiary_links_attributes,
        subsidiary_manager_location_links_attributes: subsidiary.subsidiary_manager_location_links_attributes,
        subsidiary_links_attributes_v2: subsidiary.subsidiary_links_attributes_v2,
        status: subsidiary.status,
      },
    };
    try {
      await SubsidiaryApis.editSubsidiary(id, payload);
      history.push(returnTo);
      CreateNotification(t("success"), t("subsidiary.updated"), NotificationType.success);
    } catch (error: any) {
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data) && response.data["name"]) {
          CreateNotification(
            "Name Conflict",
            subsidiary.name + " " + response.data["name"][0],
            NotificationType.warning,
          );
        } else {
          CreateNotification("Validation Error", "Please enter valid data", NotificationType.danger);
        }
      }
    }
  };

  return (
    <ErrorBoundary>
      <FormSubsidiary onSubmit={handleSubmit} isAddForm={false} returnTo={returnTo} initialValues={subsidiary} />
    </ErrorBoundary>
  );
};

export default EditSubsidiary;
