import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Panel from "./../../../components/panel/panel";
import VendorContactForm, {
  ContactForm,
  VendorAdmin,
  contactFormData,
} from "../../../components/vp/contacts/vendorContactForm";
import { useHistory, useParams } from "react-router";
import useIsMounted from "./../../../components/common/hooks/useIsMounted";
import RestApi from "./../../../providers/restApi";
import { useDispatch } from "react-redux";
import { resetVendorContactForm, setVendorContactForm } from "./../../../reducers/vp/vendorContactFormReducer";
import Loader from "./../../../components/spinners/loader";
import { addOrRemoveVendorAdmin } from "./../../../services/vp/contactSvc";
import { useTranslation } from "react-i18next";
import { CreateNotification, NotificationType } from "./../../../services/general/notifications";
import { createCompleteError } from "./../../../services/general/reduxFormSvc";
import { SubmissionError } from "redux-form";
import _ from "lodash";

const restApiService: RestApi = new RestApi();

const EditVendorContact = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isStartedAsVendorAdmin, setIsStartedAsVendorAdmin] = useState<boolean>(false);

  const getVendorContactDetails = async () => {
    try {
      const response = await restApiService.get("contacts/" + id);
      const vendorAdmin = response?.data?.system_roles_str?.includes("vendor_admin");
      setIsStartedAsVendorAdmin(vendorAdmin);
      if (isMounted) {
        dispatch(setVendorContactForm({ ...response.data, vendor_admin: vendorAdmin }));
        setLoaded(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (vendorContactFormData: contactFormData) => {
    try {
      const response = await restApiService.patch("contacts/" + vendorContactFormData.form.contact.id, null, {
        contact: vendorContactFormData.form.contact,
      });
      await addOrRemoveVendorAdmin(
        vendorContactFormData.form.contact.vendor_admin,
        isStartedAsVendorAdmin,
        response.data.id,
      );
      CreateNotification(t("success"), t("contactPage.saved"), NotificationType.success);
      onHide();
    } catch (error: any) {
      const { response } = error;
      if (response?.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          const formData: any = { contact: { ...completeErrorObj } };
          throw new SubmissionError({
            form: formData,
          });
        }
      }
    }
  };

  const onHide = () => {
    history.goBack();
  };

  useEffect(() => {
    if (isMounted) {
      getVendorContactDetails();
    }
    return () => {
      // Every time we leave vendor contact form shouls be reseted to initial state
      dispatch(resetVendorContactForm());
    };
  }, []);

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <Panel panelContent={{ heading: "EDIT USER" }} cardStyle={{ minHeight: "150px" }}>
        <Loader loaded={loaded}>
          <VendorContactForm
            contactForm={<ContactForm />}
            vendorAdmin={<VendorAdmin />}
            onSubmit={onSubmit}
            footerButtons={
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button id="vendor_contact_cancel_btn" variant="secondary" onClick={() => onHide()} className="mx-2">
                    {t("cancel")}
                  </Button>
                  <Button type="submit" id="vendor_contact_submit_btn">
                    {t("submit")}
                  </Button>
                </Col>
              </Row>
            }
          />
        </Loader>
      </Panel>
    </Container>
  );
};

export default EditVendorContact;
