import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import { restApiService } from "../../../../../providers/restApi";
import Select, { MenuPosition } from "react-select";
import Styles from "../../../pickers/reduxFormPickers/override.module.css";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
} from "../../../../../services/general/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "../../../../common/pickers/reduxFormPickers/select/helpers";
import { AxiosResponse } from "axios";
import { WrappedFieldProps } from "redux-form";
import { companyDateFormat } from "../../../../../services/general/dateSvc";
import { IUser } from "../../../../../services/common/user/userTypes";
import { useTypedSelector } from "../../../../../reducers";
import { currencySymbolRenderer } from "../../../../../services/common/currencySymbolRendererService";

interface BudgetPickerPropsType extends WrappedFieldProps {
  className?: string;
  labelClassName?: string;
  label?: string;
  isMulti: boolean;
  tooltip?: string;
  required: boolean;
  menuPosition?: MenuPosition;
  inputId?: string;
  placeholder?: string;
  disabled?: boolean;
  transparent?: boolean;
  account_id?: number;
  department_id?: number;
  currency_code?: number;
  originalValue?: any;
}

interface BudgetOptionsType {
  id: number;
  label: string;
  amount: number;
  code: string;
  budget_allocated: number;
  budget_balance: number;
  status: string;
  start_date: Date;
  end_date: Date;
  currency_code: string;
}

interface BudgetListedOptionsType {
  value: number;
  label: any;
}

const BudgetPicker = ({
  className,
  labelClassName,
  label,
  input,
  meta: { touched, error },
  isMulti,
  tooltip,
  required,
  menuPosition,
  inputId,
  placeholder = "search/select",
  disabled,
  transparent,
  account_id,
  department_id,
  currency_code,
  originalValue,
}: BudgetPickerPropsType) => {
  const [budgets, setBudgets] = useState<BudgetListedOptionsType[]>([]);
  const [originalLabel, setOriginalLabel] = useState(null);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const getCustomLabel = useCallback((budget: BudgetOptionsType) => {
    const currencySymbol = currencySymbolRenderer(budget.currency_code);
    return (
      <Container>
        <Row>{budget.label}</Row>
        <Row>{`Code: ${budget.code}`}</Row>
        <Row>
          {`Amount: ${currencySymbol}${budget.amount}`} | {`Balance: ${currencySymbol}${budget.budget_balance}`}
        </Row>
        <Row>{`Allocated: ${currencySymbol}${budget.budget_allocated}`}</Row>
        <Row>{`Start Date: ${companyDateFormat(budget.start_date, currentUser)}`}</Row>
        <Row>{`End Date: ${companyDateFormat(budget.end_date, currentUser)}`}</Row>
      </Container>
    );
  }, []);

  const parseForSelect = useCallback((budgets: BudgetOptionsType[]): BudgetListedOptionsType[] => {
    let parsedData = budgets.map((b: BudgetOptionsType) => ({
      value: b.id,
      label: getCustomLabel(b),
    }));
    return parsedData;
  }, []);

  const getBudgets = useCallback(async () => {
    let params: { [key: string]: any } = { active: true, items: 150 };
    if (account_id) {
      params.account_id = account_id;
    }
    if (department_id) {
      params.department_id = department_id;
    }
    if (currency_code) {
      params.currency_code = currency_code;
    }
    const result: AxiosResponse<BudgetOptionsType[]> = await restApiService.get(
      "budgets.lk",
      params,
      null,
      true,
      null,
      true,
    );
    if (result && result.data) {
      setBudgets(parseForSelect(result.data));
    }
  }, [account_id, department_id, currency_code]);

  const getCustomConfig = useMemo(() => {
    let componentConfig: { [key: string]: any } = {};
    if (originalValue !== undefined) {
      componentConfig.Input = (props: any) => <PickerComparisonField {...props} originalValue={originalLabel} />;
    }
    if (transparent) {
      componentConfig.DropdownIndicator = () => null;
    }
    return componentConfig;
  }, [!!originalValue, originalLabel, transparent]);

  useEffect(() => {
    getBudgets();
  }, [account_id, department_id, currency_code]);

  useEffect(() => {
    if (originalValue && budgets.length > 0) {
      setOriginalLabel(findSelectedSingle({ value: originalValue }, budgets)?.label);
    }
  }, [budgets, originalValue]);

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label className={labelClassName}>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        components={getCustomConfig}
        value={isMulti ? findSelectedMultiple(input, budgets) : findSelectedSingle(input, budgets)}
        placeholder={placeholder}
        onChange={isMulti ? (value) => onChange(input, value) : (value) => onChangeSingle(input, value)}
        onBlur={isMulti ? () => onBlur(input, input.value) : () => onBlurSingle(input, input.value)}
        options={budgets}
        isMulti={isMulti}
        isClearable
        classNamePrefix="select"
        menuPosition={menuPosition}
        isDisabled={disabled}
        inputId={inputId ? inputId : "budget-selector"}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
            ...(transparent ? { backgroundColor: "transparent", border: "none" } : {}),
          }),
        }}
      />
      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default BudgetPicker;
