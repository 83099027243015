const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ONBOARDING_LIST_TEMPLATE":
      return {
        ...state,
        listTemplate: action.payload,
      };
    case "SET_ONBOARDING_LIST_TEMPLATE_ITEMS":
      let newState = Object.assign({}, state);
      newState.listTemplate.items = action.payload;
      return newState;
    default:
      return state;
  }
};
