import useAdminCompanyCurrencyCode from "components/admin/hooks/useAdminCompanyCurrencyCode";
import CustomDataListPicker from "components/admin/pickers/reduxFormPickers/customDataListPicker";
import CustomFieldPicker from "components/admin/pickers/reduxFormPickers/customFieldPicker";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Field, change, getFormValues } from "redux-form";
import CurrencyCodePicker from "../../../../components/common/pickers/reduxFormPickers/currencyCodePicker";
import { useTypedSelector } from "../../../../reducers";
import { CurrencyType, VendorType } from "../../../../services/admin/vendors/vendorTypes";
import { IDType } from "../../../../services/common/types/common.type";
import { CompanyType } from "../../../../services/common/user/userTypes";
import { required } from "../../../../services/validations/reduxFormValidation";
import { RenderDatePicker, RenderField } from "../../../forms/bootstrapFields";
import ContactPicker from "../../pickers/reduxFormPickers/contactPicker";
import PaymentTermPicker from "../../pickers/reduxFormPickers/paymentTermPicker";
import SubsidiaryPicker from "../../pickers/reduxFormPickers/subsidiaryPicker";
import TaxForm1099 from "../../pickers/reduxFormPickers/taxForm1099/taxForm1099";
import TaxFormType1099, {
  TaxFormTypeSelectedType,
} from "../../pickers/reduxFormPickers/taxFormType1099/taxFormType1099";
import styles from "../vendors.module.css";

type AddVendorAdditionalDetailsPropsType = {
  handleNext: (step: number) => void;
  handlePrevious: (step: number) => void;
  handleCancel: () => void;
  currentUserCompany?: CompanyType;
  valid?: boolean;
};

const AddVendorAdditionalDetails = ({
  handleNext,
  handlePrevious,
  handleCancel,
  currentUserCompany,
  valid,
}: AddVendorAdditionalDetailsPropsType) => {
  const [additionalCurrencies, setAdditionalCurrencies] = useState<CurrencyType[]>([]);
  const [taxFormTypeId, setTaxFormTypeId] = useState<string>();
  const { companyCurrencies } = useAdminCompanyCurrencyCode();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const vendor: VendorType = useTypedSelector((state) => getFormValues("VendorForm")(state));

  const handleTaxFormTypeChange = (selected: TaxFormTypeSelectedType) => {
    const { value, label } = selected ?? {};
    setTaxFormTypeId(value ?? "");
    dispatch(change("VendorForm", "formType1099", label));
    dispatch(change("VendorForm", "tax_form_external_id", null));

    // This handles the case when an option from '1099 Default Box' is selected,
    // but the corresponding '1099 Form Type' has been removed, resulting in an undefined value,
    // means '1099 Default Box' get reset.
    if (vendor.tax_form_external_id && !selected) {
      dispatch(change("VendorForm", "taxFormName1099", label));
    }
  };

  // Check if a default subsidiary is unavailable.
  // If it is, we populate the options from the additional currency dropdown to select a Default Currency Code.
  useEffect(() => {
    if (
      _.isArray(vendor?.subsidiaries) &&
      !(vendor?.subsidiaries.length > 0) &&
      _.isArray(vendor?.additional_currency_codes)
    ) {
      setAdditionalCurrencies(vendor?.additional_currency_codes);
    }
  }, [vendor?.additional_currency_codes, vendor?.subsidiaries]);

  useEffect(() => {
    if (vendor.tax_form_type_external_id) {
      setTaxFormTypeId(vendor.tax_form_type_external_id);
    }
  }, [vendor?.tax_form_type_external_id]);

  return (
    <>
      <Row className="px-mb-25">
        <Col md="12">
          <span className={styles.vendorDetails}>
            <i className={`icon icon-vendors ${styles.iconVendor}`}></i>
            {t("admin.pages.vendor.additionalDetails")}
          </span>
        </Col>
      </Row>
      <Row>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="purchaser_account"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.purchaserAccount")}
            className={`w-100`}
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="external_id"
            component={RenderField}
            type="text"
            label={t("admin.pages.vendor.externalId")}
            className={`w-100`}
          />
        </Col>
        {currentUserCompany?.has_subsidiaries && (
          <>
            <Col md="6" className={styles.vendorFormField}>
              <Field
                name="subsidiary_id"
                required={currentUserCompany?.has_subsidiaries}
                validate={currentUserCompany?.has_subsidiaries ? [required] : []}
                component={SubsidiaryPicker}
                placeholder={"Search/Select"}
                label={t("admin.pages.vendor.primarySubsidiary")}
                callBack={(selected: { value: IDType; label: string }) =>
                  dispatch(change("VendorForm", "subsidiary", selected.label))
                }
              />
            </Col>
            <Col md="6" className={styles.vendorFormField}>
              <Field
                name="subsidiary_ids"
                component={SubsidiaryPicker}
                isMulti={true}
                label={t("admin.pages.vendor.additionalSubsidiary")}
                callBack={(selectedSubsidiaries: { value: IDType; label: string }[]) =>
                  dispatch(change("VendorForm", "subsidiaries", selectedSubsidiaries))
                }
              />
            </Col>
          </>
        )}

        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="requestor_id"
            component={ContactPicker}
            type="text"
            contactType={"USERS"}
            label={t("admin.pages.vendor.requestor")}
            className={`w-100`}
            required={currentUserCompany?.vendor_requestor_required}
            validate={currentUserCompany?.vendor_requestor_required ? [required] : []}
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <label className={styles.formLabel}>{t("admin.pages.vendor.receiver")}</label>
          <Field name="receiver_id" component={ContactPicker} type="text" className={`w-100`} />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="additional_currency_codes"
            component={CurrencyCodePicker}
            isMulti={true}
            currencyCodeOptions={_.isArray(companyCurrencies) ? companyCurrencies : []}
            callBack={(selectedCurrencies: CurrencyType[]) => setAdditionalCurrencies(selectedCurrencies)}
            label={t("admin.pages.vendor.currencyCodes")}
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="currency_code"
            component={CurrencyCodePicker}
            currencyCodeOptions={additionalCurrencies}
            label={t("admin.pages.vendor.defaultCurrency")}
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="contract_end_date"
            label={t("admin.pages.vendor.contractEndDate")}
            component={RenderDatePicker}
            type="date"
          />
        </Col>
        {currentUserCompany?.has_type_id && (
          <Col md="6" className={styles.vendorFormField}>
            <Field
              name="type_id"
              component={CustomDataListPicker}
              placeholder={"-- Type ID --"}
              label={t("admin.pages.vendor.typeId")}
              modelName={"TypeId"}
              required={currentUserCompany?.has_type_id && currentUserCompany?.vendor?.is_required_type_id}
              validate={
                currentUserCompany?.has_type_id && currentUserCompany?.vendor?.is_required_type_id ? [required] : []
              }
            />
          </Col>
        )}
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="term_id"
            component={PaymentTermPicker}
            placeholder={"Search/Select"}
            label={t("admin.pages.vendor.paymentTerm")}
            callBack={(selected: { value: number; label: string }) =>
              dispatch(change("VendorForm", "paymentTerm", selected.label))
            }
            required={currentUserCompany?.vendor?.payment_term?.is_required}
            validate={currentUserCompany?.vendor?.payment_term?.is_required ? [required] : []}
          />
        </Col>
        <Col md="6" className={styles.vendorFormField}>
          <Field
            name="ein"
            component={RenderField}
            type="text"
            tooltip={
              <span title={"The EIN format is XX-XXXXXXX and SSN format is xxx-xx-xxxx. "} className="float-right">
                <i className="icon-sm icon-questionmark"></i>{" "}
              </span>
            }
            label={t("admin.pages.vendor.vatId")}
            className={`w-100`}
            required={currentUserCompany?.vendor?.tax_id?.is_required}
            validate={currentUserCompany?.vendor?.tax_id?.is_required ? [required] : []}
          />
        </Col>
        {currentUserCompany?.vendor?.show_tax_form_type && (
          <>
            <Col md="6" className={styles.vendorFormField}>
              <Field
                name="tax_form_name"
                component={RenderField}
                type="text"
                label={t("admin.pages.vendor.1099VendorName")}
              />
            </Col>
            <Col md="6" className={styles.vendorFormField}>
              <Field
                name="tax_form_type_external_id"
                component={TaxFormType1099}
                callBack={(selected: TaxFormTypeSelectedType) => handleTaxFormTypeChange(selected)}
                label={t("admin.pages.vendor.1099FormType")}
              />
            </Col>
            <Col md="6" className={styles.vendorFormField}>
              <Field
                name="tax_form_external_id"
                component={TaxForm1099}
                taxFormTypeExternalId={taxFormTypeId}
                callBack={(selected: { value: IDType; label: string }) =>
                  dispatch(change("VendorForm", "taxFormName1099", selected.label))
                }
                label={t("admin.pages.vendor.1099DefaultBox")}
                required={taxFormTypeId ? true : false}
                validate={taxFormTypeId ? [required] : []}
              />
            </Col>
          </>
        )}
      </Row>
      <CustomFieldPicker
        parentObjData={vendor}
        modelData={vendor}
        modelDataFieldName=""
        modelName="Vendor"
        formFieldName="custom_fields"
        formName="VendorForm"
      />
      <Row className="px-mt-25">
        <Col md={4}>
          <Button variant="secondary" className="px-mr-10" onClick={() => handlePrevious(2)}>
            {t("admin.pages.vendor.back")}
          </Button>
        </Col>
        <Col md={{ span: 4, offset: 4 }} className={styles.btnColumn}>
          <Button variant="secondary" className="px-mr-10" onClick={() => handleCancel()}>
            {t("admin.pages.vendor.cancel")}
          </Button>
          <Button
            variant="primary"
            className={styles.btnNext}
            onClick={() => handleNext(2)}
            disabled={!valid}
            title={`${!valid ? "To continue, complete all required fields" : ""}`}
          >
            {t("admin.pages.vendor.next")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AddVendorAdditionalDetails;
