import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Form, Row, OverlayTrigger, Popover } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import { BsFillQuestionCircleFill } from "react-icons/bs";

class PayoneerFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedPMHash: null,
      fields: null,
      loading: false,
    };
    this.restApiService = new RestApi();
  }
  render() {
    return (
      <Row>
        <Col>
          <Row>
            <Form.Group as={Col} md="3">
              <Form.Label>
                {this.props.t("contact.firstName")}:<span className={"color-red"}>*</span>
              </Form.Label>
              <Form.Control
                required
                name="firstName"
                value={this.props.paymentMethodRed.contact_first_name}
                onChange={(event) => {
                  this.props.changeFirstName(event.target.value);
                }}
                type="text"
              />
              <Form.Control.Feedback type="invalid">{this.props.t("validation.firstName")}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>
                {this.props.t("contact.lastName")}:<span className={"color-red"}>*</span>
              </Form.Label>
              <Form.Control
                required
                name="lastName"
                value={this.props.paymentMethodRed.contact_last_name}
                onChange={(event) => {
                  this.props.changeLastName(event.target.value);
                }}
                type="text"
              />
              <Form.Control.Feedback type="invalid">{this.props.t("validation.lastName")}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>
                {this.props.t("address.email")}:<span className={"color-red"}>*</span>
              </Form.Label>
              <Form.Control
                required
                name="email"
                value={this.props.paymentMethodRed.contact_email}
                onChange={(event) => {
                  this.props.changeEmail(event.target.value);
                }}
                type="text"
              />
              <Form.Control.Feedback type="invalid">{this.props.t("validation.email")}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Label>
                {this.props.t("address.phone")}:<span className={"color-red"}>*</span>
              </Form.Label>
              <Form.Control
                required
                name="phone"
                value={this.props.paymentMethodRed.phoneNumber}
                onChange={(event) => {
                  this.props.changePhone(event.target.value);
                }}
                type="text"
              />
              <Form.Control.Feedback type="invalid">{this.props.t("validation.phone")}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            {this.state.loading && (
              <Col className="mt-2 mb-2">
                <strong>{this.props.t("loading.fields")}...</strong>
              </Col>
            )}
            {!this.state.loading &&
              this.state.fields &&
              this.state.fields.code === 0 &&
              Object.entries(this.state.fields.payout_method.details).map(([key, value]) => {
                return (
                  <Col key={key}>
                    <Form.Group>
                      <Form.Label style={{ textTransform: "capitalize" }}>
                        {value.label}: {value.required === "True" && <span className={"color-red"}>*</span>}{" "}
                        {this.getTooltip(value)}
                      </Form.Label>
                      <Form.Control
                        required={value.required === "True"}
                        name={this.mapField(key)}
                        value={this.props.paymentMethodRed[this.mapField(key)]}
                        onChange={this.handleInputChange}
                        type="text"
                        placeholder={value.watermark}
                        minLength={value.min_length}
                        maxLength={value.max_length}
                        pattern={value.regex}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.props.t("validation.input")}: {value.watermark}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                );
              })}
            {!this.state.loading && this.state.fields && this.state.fields.code !== 0 && (
              <Col>
                <div className="text-danger mt-2 mb-2">
                  <strong>
                    {this.props.t("loading.error")}: {this.state.fields.description}
                  </strong>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  }

  //If the input could be a list of inputs we show the tooltip
  getTooltip = (value) => {
    if (value.list && value.list.support_other === "False") {
      return (
        <OverlayTrigger
          trigger="click"
          rootClose={true}
          overlay={
            <Popover id="popover-basic">
              <Popover.Title as="h3">Hint</Popover.Title>
              <Popover.Content>
                <p>{value.description}</p>
                <h5 className="mt-2">Accepted Values:</h5>
                {value.list.list_items.map((item) => {
                  return (
                    <div key={item.value}>
                      {item.description} = {item.value}
                    </div>
                  );
                })}
              </Popover.Content>
            </Popover>
          }
        >
          <div className="d-inline">
            {" "}
            <BsFillQuestionCircleFill />
          </div>
        </OverlayTrigger>
      );
    }
    return false;
  };

  //Since we dont know which field this will be we use the name as the key for redux
  handleInputChange = (event) => {
    let temp = {};
    temp[event.target.name] = event.target.value;
    this.props.updateFields(temp);
  };

  componentDidMount() {
    this.fetchFields();
  }

  componentDidUpdate() {
    //since our redux state could be updated from other components we call fetchFields whenever something has updated
    this.fetchFields();
  }

  // Fetch the required fields from the API
  fetchFields = () => {
    if (!this.canFetchFields()) {
      return;
    }
    this.setState({ loading: true });
    let params = {
      type: "payoneer",
      country: this.props.country,
      currency: this.props.currency_code,
      purchaser_id: this.props.purchaser_id,
    };
    this.restApiService
      .get("payment_methods/required_fields", params)
      .then((res) => {
        let newState = { savedPMHash: this.getFieldHash() };
        if (res && res.data.response) {
          newState.fields = res.data.response;
        }
        newState.loading = false;
        this.setState(newState);
      })
      .catch((err) => {
        console.log("error: ", err);
        this.setState({ loading: false });
      });
  };

  // We want to fetch fields automatically but we do NOT want to keep fetching them everytime the component updates.
  // We should only update when we have valid data to send to the API AND when we haven't done this particular request yet
  canFetchFields() {
    return (
      !this.state.loading &&
      this.props.country &&
      this.props.currency_code &&
      this.props.purchaser_id &&
      (this.getFieldHash() !== this.state.savedPMHash || !this.state.savedPMHash)
    );
  }

  // A 'hash' function to have a unique value for fields we have already fetched
  getFieldHash() {
    return "" + this.props.country + this.props.currency_code + this.props.purchaser_id;
  }

  //Some names in payoneer are different than accrualify, map them so our logic becomes easier
  mapField(payoneer_field) {
    switch (payoneer_field) {
      case "bank_code":
        return "bankCode";
      case "swift":
        return "bic";
      case "branch_code":
        return "branchCode";
      case "routing_number":
        return "routing";
      case "bank_number":
        return "ifsc_code";
      case "bsb":
        return "bsb_code";
      default:
        return payoneer_field;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    paymentMethodRed: state.paymentMethodRed,
    payment_type: state.paymentMethodRed.payment_type,
    country: state.paymentMethodRed.country,
    currency_code: state.paymentMethodRed.currency_code,
    purchaser_id: state.paymentMethodRed.purchaser_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePaymentType: (payload) => {
      dispatch({ type: actionType.CHANGE_PAYMENT_TYPE, payload: payload });
    },
    updateFields: (payload) => {
      dispatch({ type: actionType.CHANGE_PAYMENT_METHOD, payload: payload });
    },
    changeFirstName: (payload) => {
      dispatch({ type: actionType.CHANGE_FIRST_NAME, payload: payload });
    },
    changeLastName: (payload) => {
      dispatch({ type: actionType.CHANGE_LAST_NAME, payload: payload });
    },
    changeEmail: (payload) => {
      dispatch({ type: actionType.CHANGE_EMAIL, payload: payload });
    },
    changePhone: (payload) => {
      dispatch({ type: actionType.CHANGE_PHONE_NUMBER, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PayoneerFields));
