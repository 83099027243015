import React from "react";
import TabNavigation from "components/navigation/tabNavigation";

function MetadataConfigurationNavbar() {
  return (
    <>
      <TabNavigation
        navigationTab={[
          {
            path: "/ap/metadata_configurations/edit",
            pageName: "Fields",
            isActive: "",
          },
          {
            path: "/ap/metadata_hierarchy/edit",
            pageName: "Hierarchies",
            isActive: "",
          },
          {
            path: "/ap/dynamic_coding",
            pageName: "Templates",
            isActive: "",
            authorization: {
              I: "metadata",
              a: "_visible",
            },
          },
          {
            path: "/ap/default_rules",
            pageName: "Default Rules",
            isActive: "",
            authorization: {
              I: "metadata",
              a: "_visible",
            },
          },
          {
            path: "/ap/custom_labels",
            pageName: "Custom Labels",
            isActive: "",
            authorization: {
              I: "metadata",
              a: "_visible",
            },
          },
        ]}
      />
    </>
  );
}

export default MetadataConfigurationNavbar;
