import React, { useEffect, useState } from "react";
import Panel from "../../common/panel/panel";
import styles from "./activityLog.module.css";
import { restApiService } from "../../../providers/restApi";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import { useTypedSelector } from "../../../reducers";
import { IUser } from "../../../services/common/user/userTypes";
import { companyDateTimeFormat } from "../../../services/general/dateSvc";
import moment from "moment";

type ActivityLogType = {
  modelMethod: string;
  modelActionId: string;
};

type DataLogType = {
  id: string;
  created_at: string;
  event: string;
  item_id: number;
  link_invoice_id: string;
  link_payment_id: string;
  link_purchase_order_id: string;
  item_type: string;
  approver: string;
  object_changes: {
    amount: string[];
    name: string[];
    email: string[];
    currency_code: string[];
    date: string[];
    due_date: string[];
    submit_date: string[];
    entry_type: string[];
    qty: string[];
    sub_amount: string[];
    tax: string[];
    number: string[];
    orig_amount: number[];
    status: [];
    updated_at: string[];
    created_at: string[];
    id: string[];
    company_id: string[];
    account_id: string[];
    account_link_id: string[];
    account_transaction_id: string[];
    subsidiary_id: string[];
    term_id: string[];
    item_line_id: string[];
    product_item_id: string[];
    project_id: string[];
    vendor_id: string[];
    department_id: string[];
    location_id: string[];
    business_unit_id: string[];
    inherit_po_item_id: string[];
    invoice_id: string[];
    asset_file_content_type: string[];
    asset_file_file_name: string[];
    asset_file_file_size: string[];
    asset_file_updated_at: string[];
    assetable_id: string[];
    assetable_type: string[];
  };
  user: {
    handle: string;
    handle_avatar: string;
    user_id: string;
  };
};

const ActivityLog = ({ modelMethod, modelActionId }: ActivityLogType) => {
  const [isCollapse, setCollapse] = useState<boolean>(false);
  const [dataLog, setDataLog] = useState<DataLogType[]>([]);
  const CurrentUser: IUser = useTypedSelector((state) => state.user);

  const getActivityLog = async () => {
    try {
      const response = await restApiService.get(modelMethod + "/" + modelActionId + "/versions");
      if (_.isArray(response?.data) && response?.data.length > 0) {
        setDataLog(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Sort dataLog by the 'created_at' field in descending order
  const sortedDataLog = dataLog.slice().sort((a, b) => {
    const dateA = moment(a.created_at);
    const dateB = moment(b.created_at);
    return dateB.diff(dateA);
  });

  const handleCollapse = () => {
    getActivityLog();
    setCollapse(!isCollapse);
  };

  return (
    <Panel
      header={
        <div className={styles.panelTitle}>
          <i className={`icon icon-activity-log ${styles.logIcon}`}></i> {"DATA AUDIT LOG"}
          <a className="float-right">
            <i
              className={`icon ${isCollapse ? "icon-arrow-up" : "icon-arrow-down"}`}
              onClick={() => handleCollapse()}
            ></i>
          </a>
        </div>
      }
      hideCardBody={!isCollapse}
    >
      {isCollapse && (
        <>
          {_.isArray(sortedDataLog) && sortedDataLog.length > 0 ? (
            <>
              {sortedDataLog.map((log) => {
                return (
                  <Row key={log.id} className={styles.logSection}>
                    <Col sm={4}>
                      <div className="dl-horizontal">
                        <div>{log?.user?.handle}</div>
                        <div className={styles.logDate}>
                          {companyDateTimeFormat(log?.created_at, CurrentUser, "h:mm a z")}
                        </div>
                      </div>
                    </Col>
                    <Col sm={8}>
                      <dl className="dl-horizontal">
                        {log?.event === "create" && (
                          <>
                            <span className={styles.capitalize}>
                              {log?.event}d {log.item_type} {log.item_id}
                            </span>

                            {log.item_type === "Asset" && <span>{log?.object_changes?.asset_file_file_name[1]}</span>}

                            {log.item_type === "Approval" && (
                              <span>
                                {log?.object_changes?.name[1]}
                                {log.object_changes.email[1] ? "<" + log.object_changes.email[1] + ">" : ""}
                              </span>
                            )}
                            {log.item_type === "AccountEntry" && (
                              <span>
                                Amount:{log?.object_changes?.amount[1]}, Entry Type:
                                {log?.object_changes?.entry_type[1]?.toUpperCase()}
                              </span>
                            )}
                            {log.link_invoice_id && ", Invoice ID " + log.link_invoice_id}
                            {log.link_payment_id && ", Payment ID " + log.link_payment_id}
                            {log.link_purchase_order_id && ", Purchase Order ID " + log.link_purchase_order_id}
                          </>
                        )}
                        {log?.event === "update" && log?.object_changes && (
                          <div>
                            {Object.entries(log.object_changes).map(([record, field]) => (
                              <div key={record + field.length}>
                                {record !== "updated_at" && (
                                  <div>
                                    <span className={styles.capitalize}>
                                      {log?.event}d {log.item_type}{" "}
                                    </span>
                                    {["AccountEntry", "Approval"].includes(log.item_type) && (
                                      <span> {log.item_id} </span>
                                    )}
                                    <span>
                                      {record} from {JSON.stringify(field[0] || "' '")} to{" "}
                                      {JSON.stringify(field[1] || "' '")}
                                    </span>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                        {log?.event === "destroy" && (
                          <>
                            <span className={styles.capitalize}>
                              {log?.event}ed {log.item_type} {log.item_id}
                            </span>
                            <span>{log.link_invoice_id && " Invoice ID " + log.link_invoice_id}</span>
                            {log.item_type === "Asset" && <span>{log?.object_changes?.asset_file_file_name[0]}</span>}
                            <span>{log.link_payment_id && " Payment ID " + log.link_payment_id}</span>
                            <span>
                              {log.link_purchase_order_id && " Purchase Order ID " + log.link_purchase_order_id}
                            </span>
                            <span>{log?.approver ? "Approver:" + log.approver : ""}</span>
                          </>
                        )}
                      </dl>
                    </Col>
                  </Row>
                );
              })}
            </>
          ) : (
            <div className={styles.noActivity}>No activities to show</div>
          )}
        </>
      )}
    </Panel>
  );
};

export default ActivityLog;
