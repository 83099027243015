import { MetadataSorter } from "pages/admin/administration/metadata/metadataConfiguration";
import React, { useCallback, useEffect, useState } from "react";
import MetadataConfigurationRow from "./metadataConfigurationUpsert";
// import {Col, Container, Row} from "react-bootstrap";
// import {change, Field, getFormValues, InjectedFormProps, reduxForm} from 'redux-form';
// import {connect, shallowEqual, useDispatch, useSelector} from 'react-redux';
// import {useHistory} from 'react-router-dom';
// import {useTypedSelector} from "reducers";
// import RestApi from "providers/restApi";

// import Panel from "components/common/panel/panel";
// import FileUploader from "components/common/fileUploader/fileUploader";
// import TabNavigation from "components/navigation/tabNavigation";
// import NavTabs from 'pages/admin/administration/nav';
// import {CreateNotification, NotificationType} from "services/general/notifications";

// import styles from "./metadataConfiguration.module.css";
// import { RenderSelect } from "components/forms/bootstrapFields";

interface MetadataConfigurationMultiEditProps {
  metadataSorter: MetadataSorter;
  refreshCallback: any;
}

const MetadataConfigurationMultiEdit: React.FC<MetadataConfigurationMultiEditProps> = ({
  metadataSorter,
  refreshCallback,
}) => {
  return (
    <div>
      {metadataSorter.configurations.map((configuration, index) => {
        return (
          <MetadataConfigurationRow
            form={`metadataConfigurationForm_${configuration.id}`}
            editing={true}
            hideLabels={index != 0}
            initialValues={configuration}
            refreshCallback={refreshCallback}
            key={configuration.id}
            metadataConfiguration={configuration}
          ></MetadataConfigurationRow>
        );
      })}
      <hr></hr>
    </div>
  );
};

export default MetadataConfigurationMultiEdit;
