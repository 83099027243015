import React, { createRef } from "react";
import "./datagrid.style.css";
class FloatingFilterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusFilter: "",
    };
    this.selectRef = createRef();
  }

  onParentModelChanged(parentModel) {
    // When the filter is empty we will receive a null value here
    if (!parentModel) {
      this.selectRef.current.value = "";
      this.setState({ currentValue: null });
    } else {
      this.selectRef.current.value = parentModel.filter + "";
      this.setState({ currentValue: parentModel.filter });
    }
  }

  render() {
    let options = this.props.column.colDef.filterParams ? this.props.column.colDef.filterParams.options : null;
    return (
      <div style={{ width: "100%", height: 22, position: "relative", marginTop: 8 }}>
        {!options ? (
          <select
            ref={this.selectRef}
            style={{
              width: "100%",
              height: "100%",
              minHeight: 1,
              padding: "0 0 0 5px",
              borderRadius: 0,
              position: "absolute",
              bottom: 0,
              borderColor: "#b2b2b2",
              backgroundColor: "#FFF",
              color: "#76777A",
            }}
            onChange={this.onStatusChange}
          >
            <option value=""></option>
            <option value="">ALL</option>
            <option value="DRAFT">DRAFT</option>
            <option value="PENDING">PENDING</option>
            <option value="APPROVED">APPROVED</option>
            <option value="OPEN">OPEN</option>
            <option value="CLOSED">CLOSED</option>
            <option value="REJECTED">REJECTED</option>
            <option value="ACTIVE">ACTIVE</option>
          </select>
        ) : (
          <select
            ref={this.selectRef}
            style={{
              width: "100%",
              height: "100%",
              minHeight: 1,
              padding: "0 0 0 5px",
              borderRadius: 0,
              position: "absolute",
              bottom: 0,
              borderColor: "#b2b2b2",
              backgroundColor: "#FFF",
              color: "#76777A",
            }}
            onChange={this.onStatusChange}
          >
            <option value=""></option>
            {options.map((option) => {
              return (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </select>
        )}
      </div>
    );
  }

  onStatusChange = (event) => {
    if (event.target.value === "") {
      // clear the filter
      this.props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }

    this.setState({ statusFilter: event.target.value });
    this.props.parentFilterInstance((instance) => {
      instance.onFloatingFilterChanged("contains", event.target.value);
    });
  };
}
export default FloatingFilterComponent;
