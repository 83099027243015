import React from "react";
import { Button, ButtonGroup, Dropdown, DropdownProps } from "react-bootstrap";
import styles from "./buttons.module.css";
import { DropdownItemProps } from "react-bootstrap/esm/DropdownItem";
import { DropdownMenuProps } from "react-bootstrap/esm/DropdownMenu";

interface SplitButtonPropsType extends DropdownProps, DropdownItemProps, DropdownMenuProps {
  title: string;
  onSelectCallback: (value: any) => void;
  options: { label: string; value: string | number }[];
  value: string | number | boolean;
  customClass?: string;
  customBtnClass?: string;
  customToggleBtnClass?: string;
}

const SplitButton = ({
  align = { sm: "left" },
  onSelectCallback,
  disabled = false,
  title,
  value,
  options,
  customClass,
  customBtnClass,
  customToggleBtnClass,
}: SplitButtonPropsType) => {
  return (
    <div className={"splitButton " + (customClass ? customClass : "")}>
      <Dropdown as={ButtonGroup} onSelect={onSelectCallback}>
        <Button
          variant="secondary"
          className={customBtnClass ? customBtnClass : styles.buttonGroup}
          disabled={disabled}
        >
          {title}
        </Button>
        <Dropdown.Toggle
          split={false}
          className={customToggleBtnClass ? customToggleBtnClass : styles.toggleButton}
          variant="secondary"
          id="dropdown-toggle"
          disabled={disabled}
        />
        <Dropdown.Menu align={align} className={styles.menuItem}>
          {options.map((option, index) => (
            <Dropdown.Item key={index} eventKey={option.value} active={value == option.value} as="button">
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SplitButton;
