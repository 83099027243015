import React, { memo, useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode, selectAppCurrencyCode } from "reducers/common/appCommonStatesSlice";
import { selectCurrentUser } from "reducers/userReducers";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import companyService from "services/common/company/companySvc";
import { IUser } from "services/common/user/userTypes";
import AbstractListPicker from "../abstractListPicker";
import styles from "./currencyCodePicker.module.css";

type TCurrencyCode = {
  value?: string;
  label?: string;
  name?: string;
  symbol?: string;
};

export type TCurrencyCodePickerProps = {
  id?: string;
  name: string;
  objectName?: string;
  required?: boolean;
  label?: string;
  showSymbol?: boolean;
};

const CURRENCIES_IDS_KEY = "_CURRENCIES_IDS"; //Used to force a react-query refetch if the currency list changes
const CURRENCIES_KEY = "_CURRENCIES_";
const VENDOR_KEY = "_VENDOR_";

const CurrencyCodePicker = ({
  id,
  name,
  objectName = "currency",
  label,
  required = false,
  showSymbol = false,
}: TCurrencyCodePickerProps) => {
  const { control } = useFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);
  const appCurrencies = useTypedSelector(selectAppCurrencyCode);
  const allowOnlyCompanyCurrencyCodes = currentUser.company?.global?.allow_only_company_currency_codes;
  const useVendorLevelCurrencies = currentUser?.company?.global?.use_vendor_level_currencies;
  const vendorId = useWatch({ control, name: "vendor_id" });

  const params = useMemo(() => {
    if (!appCurrencyCodes) {
      return null;
    }

    const currencyValues = appCurrencyCodes.map((item) => item.value);

    const apiParams = {
      [CURRENCIES_IDS_KEY]: JSON.stringify(currencyValues),
      ...(useVendorLevelCurrencies && vendorId && { [VENDOR_KEY]: vendorId }),
      ...(useVendorLevelCurrencies &&
        !vendorId &&
        allowOnlyCompanyCurrencyCodes && {
          [CURRENCIES_KEY]: appCurrencies,
        }),
    };

    return apiParams;
  }, [allowOnlyCompanyCurrencyCodes, appCurrencies, appCurrencyCodes, useVendorLevelCurrencies, vendorId]);

  const getCurrenciesAsync = useCallback(
    async (params: Record<string, any>, signal?: AbortSignal | undefined): Promise<TCurrencyCode[]> => {
      if (!appCurrencyCodes) {
        return [];
      }

      const pVendorId = params[VENDOR_KEY];
      const pCurrency = params[CURRENCIES_KEY];

      if (!pVendorId && !pCurrency) {
        return appCurrencyCodes;
      }

      const data = pVendorId
        ? await invoiceCommonSvc.getVendorCurrencies({ vendor_id: pVendorId }, appCurrencyCodes)
        : await companyService.getCompanyCurrencies(pCurrency);

      return data || [];
    },
    [appCurrencyCodes],
  );

  const formatOptionLabel = useCallback(
    (option: TCurrencyCode) => {
      return (
        <div className={styles.currencyOption}>
          <span className={styles.currencyValue}>{option.label}</span>
          {showSymbol && <span className={styles.currencySymbol}>{option.symbol}</span>}
        </div>
      );
    },
    [showSymbol],
  );

  return (
    <AbstractListPicker<TCurrencyCode>
      id={id}
      name={name}
      objectName={objectName}
      label={label}
      required={required}
      multiple={false}
      params={params}
      fetchUrl=""
      fetchInactive={false}
      valuePropertyName="value"
      labelPropertyName="label"
      fetchFunctionAsync={getCurrenciesAsync}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export default memo(CurrencyCodePicker);
