import React from "react";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import { getDefaultOrCurrentColumnState } from "services/common/gridService";
import _ from "lodash";

class GridFilterDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    let options = this.props.options ? this.props.options : {};
    return (
      <div className="gridDropdown d-inline-block">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-autoclose-true" className={this.props.expenseButton}>
            <i className="icon-g icon-bolt"></i>
            Quick Filters
          </Dropdown.Toggle>

          <Dropdown.Menu className={`${this.props?.menuClass || ""}`}>
            <Dropdown.Item onClick={() => this.onSelect("clear")}>Clear Filters</Dropdown.Item>
            <Dropdown.Item onClick={() => this.onSelect("reset")}>Reset Grid</Dropdown.Item>
            {options.month && <Dropdown.Item onClick={() => this.onSelect("month")}>This Month</Dropdown.Item>}
            {options.year && <Dropdown.Item onClick={() => this.year()}>This Year</Dropdown.Item>}
            {options.quarter && <Dropdown.Item onClick={() => this.quarter()}>This Quarter</Dropdown.Item>}
            {options.lastQuarter && <Dropdown.Item onClick={() => this.lastQuarter()}>Last Quarter</Dropdown.Item>}
            {_.isArray(options.nullFilters) &&
              options.nullFilters.map((nullFilter) => (
                <Dropdown.Item key={nullFilter.field} onClick={() => this.nullFilter(nullFilter.field)}>
                  {nullFilter.label}
                </Dropdown.Item>
              ))}
            {this.props.customFilter}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  onSelect = (event) => {
    switch (event) {
      case "clear":
        this.clear();
        break;
      case "noQuick":
        this.reset();
        break;
      case "reset":
        this.reset();
        break;
      case "month":
        this.month();
        break;
      default:
    }
  };

  clear = () => {
    if (this.props.clearExternalFilter) {
      this.props.clearExternalFilter();
    }
    this.props?.gridApi?.setFilterModel(null);
    this.props?.gridApi?.onFilterChanged();
  };

  reset = () => {
    if (this.props.clearExternalFilter) {
      this.props.clearExternalFilter();
    }
    this.props?.gridApi?.setFilterModel(null);
    this.props?.gridApi?.deselectAll();
    this.props?.gridApi?.sizeColumnsToFit();
    // this.props?.gridColumnApi?.resetColumnState();
    this.props?.gridApi?.resetColumnState();
    if (this.props?.gridColumnApi && this.props?.gridStorageName) {
      getDefaultOrCurrentColumnState(this.props?.gridApi, this.props.gridStorageName, true);
    }
  };

  month() {
    var today = new Date();
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.clear();
    var filterInstance = this.props.gridApi.getFilterInstance(this.props.options.month.dateName);
    filterInstance.setModel({
      filterType: "date",
      type: "inRange",
      dateFrom: moment(firstDay).format("YYYY-MM-DD"),
      dateTo: moment(lastDay).format("YYYY-MM-DD"),
    });
    this.props.gridApi.onFilterChanged();
  }

  // this year
  year() {
    const firstDay = new Date(moment().startOf("year"));
    const lastDay = new Date(moment().endOf("year"));
    const filterInstance = this.props.gridApi.getFilterInstance(this.props.options.month.dateName);
    filterInstance.setModel({
      filterType: "date",
      type: "inRange",
      dateFrom: moment(firstDay).format("YYYY-MM-DD"),
      dateTo: moment(lastDay).format("YYYY-MM-DD"),
    });
    this.props.gridApi.onFilterChanged();
  }

  // quarter
  quarter() {
    const firstDay = new Date(moment().startOf("quarter"));
    const lastDay = new Date(moment().endOf("quarter"));
    const filterInstance = this.props.gridApi.getFilterInstance(this.props.options.month.dateName);
    filterInstance.setModel({
      filterType: "date",
      type: "inRange",
      dateFrom: moment(firstDay).format("YYYY-MM-DD"),
      dateTo: moment(lastDay).format("YYYY-MM-DD"),
    });
    this.props.gridApi.onFilterChanged();
  }

  // last quarter
  lastQuarter() {
    const firstDay = new Date(moment(moment().subtract(1, "quarter")).startOf("quarter"));
    const lastDay = new Date(moment(moment().subtract(1, "quarter")).endOf("quarter"));
    const filterInstance = this.props.gridApi.getFilterInstance(this.props.options.month.dateName);
    filterInstance.setModel({
      filterType: "date",
      type: "inRange",
      dateFrom: moment(firstDay).format("YYYY-MM-DD"),
      dateTo: moment(lastDay).format("YYYY-MM-DD"),
    });
    this.props.gridApi.onFilterChanged();
  }

  // null filter
  nullFilter(field) {
    const filterInstance = this.props.gridApi.getFilterInstance(field);
    filterInstance.setModel({
      filterType: "text",
      type: "blank",
    });
    this.props.gridApi.onFilterChanged();
  }
}

export default GridFilterDropdown;
