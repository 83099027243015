import List from "./list";
import Detail from "./detail";
interface RouteAuthorization {
  I: string;
  a: string;
}

interface RouteConfig {
  path: string;
  exact: boolean;
  name: string;
  component: React.ComponentType<any>; // Adjust the any to specific props if needed
  authorization: RouteAuthorization;
}

export const adminDisputeRoutes: RouteConfig[] = [
  {
    path: "/ap/cards/disputes",
    exact: true,
    name: "Card Disputes List",
    component: List,
    authorization: {
      I: "",
      a: "",
    },
  },
  {
    path: "/ap/cards/disputes/new/purchases/:purchase_id",
    exact: true,
    name: "Card Disputes Detail",
    component: Detail,
    authorization: {
      I: "",
      a: "",
    },
  },
];
