import React, { Component } from "react";
import RestApi from "../../../providers/restApi";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import Panel from "../../../components/panel/panel";
import { Link, withRouter } from "react-router-dom";
import { BsQuestionSquare } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";

class VCardOnboardingStripe extends Component {
  constructor(props) {
    super(props);
    this.restApiService = new RestApi();
    this.state = {
      account_status_is_queried: false,
      account_is_verified: false,
    };
    this.backURL = "/ap/onboarding/vcards";
    this.item_id = "stripe_account_created";
  }

  componentDidMount() {
    this.check_stripe_is_verified();

    if (!this.props.onboaringState.listTemplate) {
      //cant continue as the template is not defined, go to our back url
      this.props.history.push(this.backURL);
    }
  }

  // checked.
  get_stripe_kyc_forms = () => {
    //post the item_id to api
    this.restApiService
      .get("virtual_cards/onboarding/onboarding_stripe_account_kyc", null, {})
      .then((result) => {
        if (result.status == 200) {
          if (result.data == null) return null;
          window.location = result.data;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  check_stripe_is_verified = () => {
    this.restApiService
      .get("virtual_cards/onboarding/account_is_activated", null, {})
      .then((result) => {
        if (result.status == 200) {
          this.setState({ account_is_verified: result.data, account_status_is_queried: true });
          this.props.onboaringState.listTemplate.items.forEach((element) => {
            if (element.id == this.item_id) {
              element.completed = result.data;
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  kyc_is_complete() {
    return (
      <Panel cardClasses={"animated fadeIn"}>
        <Row>
          <Col className="">
            <h1>Account Created!</h1>
          </Col>
        </Row>
        <Row>
          <Col className="">
            <p>You have completed our KYC forms!</p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={6}>
            <button
              className="btn btn-primary btn-block"
              type="button"
              onClick={() => {
                this.props.history.push(this.backURL);
              }}
            >
              Back to Checklist
            </button>
          </Col>
        </Row>
      </Panel>
    );
  }

  kyc_is_incomplete() {
    return (
      <Row>
        <Col md="6" className={"mt-sm-4 mt-md-0 mx-auto"}>
          <Panel cardClasses={"animated fadeIn"} cardBodyStyle={{ padding: "35px" }}>
            <button
              className={"border-0 bg-white p-0"}
              type="button"
              style={{ fontSize: 12 }}
              onClick={() => {
                this.props.history.push(this.backURL);
              }}
            >
              <span className={"pr-2"}>
                <BsQuestionSquare style={{ fontSize: 9, marginBottom: 3 }} />
              </span>
              Back to Checklist
            </button>
            <Row>
              <Col className={"mt-4"}>
                <h1>Account Creation</h1>
              </Col>
            </Row>

            {this.display_verified_status_msg()}

            <Row className="mt-4">
              <Col>
                <button className="btn btn-primary btn-block" type="button" onClick={this.get_stripe_kyc_forms}>
                  Add or Edit KYC Info
                </button>
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
    );
  }

  // checked
  display_verified_status_msg = () => {
    if (this.state.account_status_is_queried != true) {
      return;
    }
    if (this.state.account_is_verified == false) {
      return (
        <div className="">
          <Row className={"mt-2"}>
            <Col xs={12}>
              <p>
                To get started, we need a few company details from you. Please click the button below to be redirected
                to our account creation and activation form.
              </p>
            </Col>
          </Row>
          <Row className={"mt-2"}>
            <Col xs={6}>
              <p>
                Form Status: <span className={"text-orange"}>Incomplete</span>
              </p>
            </Col>
            <Col xs={6}>
              <button
                className={"border-0 bg-white p-0"}
                type="button"
                style={{ fontSize: 12 }}
                onClick={this.check_stripe_is_verified}
              >
                <span className={"pr-2"}>
                  <FiRefreshCcw style={{ fontSize: 9, marginBottom: 3 }} />
                </span>
                Refresh Status
              </button>
            </Col>
          </Row>
        </div>
      );
    }
    if (this.state.account_is_verified == true) {
      return (
        <Row className={"mt-2"}>
          <Col xs={12}>
            <p>Congratulations! You are all set</p>
          </Col>
          <Col xs={12} className={"mt-2"}>
            <p>
              Form Status: <span className={"text-success"}>Complete</span>
            </p>
          </Col>
        </Row>
      );
    }
    return;
  };

  account_creation() {
    if (this.props.match.params.id != null) {
      return this.props.match.params.id;
    }

    if (this.state.account_status_is_queried == false || this.state.account_is_verified == null) {
      return <Spinner className={"loaderFullScreen"} animation="border" variant="danger" style={{ zIndex: 1 }} />;
    }

    if (this.check_stripe_is_verified == true) {
      return this.kyc_is_complete();
    } else return this.kyc_is_incomplete();
  }

  render() {
    return (
      <Container className={"pt-4 pb-4"}>
        <Row style={{ minHeight: "150px" }}>
          <Col>{this.account_creation()}</Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    onboaringState: state.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setListTemplateItems: (payload) => {
      dispatch({ type: actionType.SET_ONBOARDING_LIST_TEMPLATE_ITEMS, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(VCardOnboardingStripe)));
