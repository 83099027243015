import { Col, Form } from "react-bootstrap";
import React, { useState } from "react";
import "../paymentMethods.style.css";
import {
  AccountHolder,
  AccountNumber,
  AccountType,
  BankCode,
  BankName,
  BranchCode,
  BikCode,
  VoCode,
  InnTaxId,
  IBAN,
  AccountHolder3,
  AccountNumber3,
  BusinessNumber3,
  TwIBAN,
  IFSC,
  TransitNumber,
  CardNumber,
  BICCode,
  TwBsbCode3,
  PhoneNumber,
  CPF,
  TwInstNumber,
  ConfirmAccountNumber,
  TwSwiftCode,
  TwBicCode,
  BankRouting,
  IntermediaryBankName,
  IntermediaryCountry,
  IntermediaryBankAccountNumber,
  IntermediaryBankBIC,
  OptionalRemitInstruction,
} from "../fieldComponents/fieldsComponent";
import { IntermediaryBankValidations } from "../validations/wirePaymentValidations";

function selectField(fillteredPayment, fieldName) {
  return fillteredPayment[0].validations.find((item) => item.field === fieldName);
}

function selectFieldIntermy(fillteredPayment, fieldName) {
  return fillteredPayment.find((item) => item.field === fieldName);
}

export const WireEuro = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireAud = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwBsbCode3 {...props} field_valid={selectField(props.fillteredPayment, "bsb_code")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BusinessNumber3 {...props} field_valid={selectField(props.fillteredPayment, "business_number")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireAmd = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder3 {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankName {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <AccountNumber3 {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <CPF {...props} field_valid={selectField(props.fillteredPayment, "cpf")} />
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
        {/* <BankCode {...props} span={"3"} field_valid={selectField(props.fillteredPayment, 'bankCode')} /> */}
      </Form.Row>
    </span>
  );
};
export const WireInr = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankName {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <IFSC {...props} field_valid={selectField(props.fillteredPayment, "ifsc_code")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireIls = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <IBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireDkk = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <IBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};

export const WireBrl = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <BranchCode {...props} field_valid={selectField(props.fillteredPayment, "branchCode")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
      </Form.Row>
      <Form.Row>
        <CPF {...props} field_valid={selectField(props.fillteredPayment, "cpf")} />
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireCad = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwInstNumber {...props} field_valid={selectField(props.fillteredPayment, "institution_number")} />
        <TransitNumber {...props} field_valid={selectField(props.fillteredPayment, "transit_number")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireCny = (props) => {
  return (
    <Form.Row>
      <CardNumber {...props} field_valid={selectField(props.fillteredPayment, "cardNumber")} />
    </Form.Row>
  );
};

export const WireJpy = (props) => {
  return (
    <span>
      <Form.Row>
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <BranchCode {...props} field_valid={selectField(props.fillteredPayment, "branchCode")} />
        <AccountType {...props} field_valid={selectField(props.fillteredPayment, "account_type")} />
      </Form.Row>

      <Form.Row>
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        {props.purchaser_id ? null : <ConfirmAccountNumber {...props} />}
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireMyr = (props) => {
  return (
    <span>
      <Form.Row>
        <TwSwiftCode {...props} field_valid={selectField(props.fillteredPayment, "swiftCode")} />
        {props.purchaser_id ? null : (
          <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        )}
      </Form.Row>
    </span>
  );
};
export const WireNzd = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
      </Form.Row>
    </span>
  );
};

export const WireNok = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <TwBicCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
      </Form.Row>
    </span>
  );
};
export const WirePhp = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};

export const WireSgd = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <TwBicCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};

export const WireSek = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <TwBicCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireChf = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <TwBicCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireAed = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <TwBicCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireGbp = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <TwIBAN {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "iban")} />
        <BICCode {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BankName {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "bank_name")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireKes = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const WireZmw = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <BranchCode {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "branchCode")} />
        <AccountNumber {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const WireNgn = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const WireGhs = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <BranchCode {...props} field_valid={selectField(props.fillteredPayment, "branchCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};

export const WirePkr = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <IBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};

export const WireIdr = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const WireZar = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <PhoneNumber {...props} field_valid={selectField(props.fillteredPayment, "phoneNumber")} />
      </Form.Row>
    </span>
  );
};
export const WireKrw = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BankCode {...props} field_valid={selectField(props.fillteredPayment, "bankCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};
export const WireLkr = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <BranchCode {...props} field_valid={selectField(props.fillteredPayment, "branchCode")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
      </Form.Row>
    </span>
  );
};

export const WireVnd = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BICCode {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "bic")} />
        <AccountNumber {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BankName {...props} span={"3"} field_valid={selectField(props.fillteredPayment, "bank_name")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};

export const WireRub = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <BikCode {...props} field_valid={selectField(props.fillteredPayment, "bik_code")} />
        <VoCode {...props} field_valid={selectField(props.fillteredPayment, "vo_code")} />
        <InnTaxId {...props} field_valid={selectField(props.fillteredPayment, "inn_tax_id")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};

export const WireUsd = (props) => {
  return (
    <>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        {props.purchaser_id ? null : <ConfirmAccountNumber {...props} />}
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <AccountType {...props} field_valid={selectField(props.fillteredPayment, "account_type")} />
        <BankRouting {...props} bankNameValidationObj={selectField(props.fillteredPayment, "bank_name")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </>
  );
};

export const WireClp = (props) => {
  return (
    <span>
      <Form.Row></Form.Row>
    </span>
  );
};
export const WireHkd = (props) => {
  return (
    <span>
      <Form.Row></Form.Row>
    </span>
  );
};
export const WireMxn = (props) => {
  return (
    <span>
      <Form.Row></Form.Row>
    </span>
  );
};
export const WirePen = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        {props.purchaser_id ? null : <ConfirmAccountNumber {...props} />}
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        <AccountType {...props} field_valid={selectField(props.fillteredPayment, "account_type")} />
        <BankRouting {...props} bankNameValidationObj={selectField(props.fillteredPayment, "bank_name")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};
export const WireThb = (props) => {
  return (
    <span>
      <Form.Row></Form.Row>
    </span>
  );
};
export const WireUah = (props) => {
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        <BankRouting {...props} bankNameValidationObj={selectField(props.fillteredPayment, "bank_name")} />
        <IBAN {...props} field_valid={selectField(props.fillteredPayment, "iban")} />
        <BICCode {...props} field_valid={selectField(props.fillteredPayment, "bic")} />
        {/* <AccountType {...props} field_valid={selectField(props.fillteredPayment, "account_type")} /> */}
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
      </Form.Row>
      <Form.Row>
        <OptionalRemitInstruction {...props} field_valid={selectField(props.fillteredPayment, "purpose_code")} />
      </Form.Row>
    </span>
  );
};

export const IntermediaryBank = (props) => {
  return (
    <>
      <Form.Row>
        <IntermediaryBankName field_valid={selectFieldIntermy(IntermediaryBankValidations, "intrmy_name")} {...props} />
        <IntermediaryCountry
          field_valid={selectFieldIntermy(IntermediaryBankValidations, "intrmy_country")}
          {...props}
        />
      </Form.Row>
      <Form.Row>
        <IntermediaryBankAccountNumber
          field_valid={selectFieldIntermy(IntermediaryBankValidations, "intrmy_number")}
          {...props}
        />
        <IntermediaryBankBIC field_valid={selectFieldIntermy(IntermediaryBankValidations, "intrmy_bic")} {...props} />
      </Form.Row>
    </>
  );
};
