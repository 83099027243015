export const CHANGEUSERNAME = "CHANGEUSERNAME";
export const CHANGESTOREVALUES = "CHANGESTOREVALUES";
export const ADDUSERDETAILS = "ADDUSERDETAILS";
export const CHANGE_USER_LANGUAGE = "CHANGE_USER_LANGUAGE";
export const CHANGE_USER_EMAIL_AGGREGATION = "CHANGE_USER_EMAIL_AGGREGATION";
export const REMOVEUSERDEATAILS = "REMOVEUSERDEATAILS";
export const FLOATFILTER = "FLOATFILTER";
export const ON_LOG_OUT = "ON_LOG_OUT";

////////////////////////////// actions related to company ///////////////////////////////////////////
export const SET_COMPANY = "SET_COMPANY";
export const SET_PURCHASERS = "SET_PURCHASERS";

export const REQUEST_GET = "REQUEST_GET";
export const POST_LOGIN_USER = "POST_LOGIN_USER";

//////////////////// actions related to Expense reimbursement component/////////////////////////////////

export const UPDATE_EXPENSE_DETAILS = "UPDATE_EXPENSE_DETAILS";
export const CHANGE_ITEM_TYPE = "CHANGE_ITEM_TYPE";
export const SET_EXPENSE_ITEM_ASSETS = "SET_EXPENSE_ITEM_ASSETS";
export const CALCULATE_EXCHANGE_TAX = "CALCULATE_EXCHANGE_TAX";
export const SET_EXPENSES_COUNT = "SET_EXPENSES_COUNT";
export const REMOVE_EXPENSES_COUNT = "REMOVE_EXPENSES_COUNT";

//////////////////// actions related to main payment component/////////////////////////////////

export const CLEAR_PAYMENT_METHOD = "CLEAR_PAYMENT_METHOD";
export const FULL_CLEAR_PAYMENT_METHOD = "FULL_CLEAR_PAYMENT_METHOD";
export const CHANGE_PAYMENT_TYPE = "CHANGE_PAYMENT_TYPE";
export const CHANGE_COUNTRY_CURRENCY_CODE = "CHANGE_COUNTRY_CURRENCY_CODE";
export const CHANGE_COUNTRY_CODE = "CHANGE_COUNTRY_CODE";
export const CHANGE_MAIL_TYPE = "CHANGE_MAIL_TYPE";
export const CHANGE_CURRENCY_CODE = "CHANGE_CURRENCY_CODE";
export const CHANGE_CAD_CURRENCY_CODE = "CHANGE_CAD_CURRENCY_CODE";
export const CHANGE_PURCHASER = "CHANGE_PURCHASER";
export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";
export const CHANGE_PAY_BY_SWIFT_CODE = "CHANGE_PAY_BY_SWIFT_CODE";

/////////////////////////////////////////////////////////////////////////////////////////////

//////////////////// actions related to common payment address component /////////////////////////////////

export const CHANGE_PAYMENT_METHOD_ADDRESSLINE = "CHANGE_PAYMENT_METHOD_ADDRESSLINE";
export const CHANGE_PAYMENT_METHOD_ADDRESS_2 = "CHANGE_PAYMENT_METHOD_ADDRESS_2";
export const CHANGE_PAYMENT_METHOD_ADDRESS_3 = "CHANGE_PAYMENT_METHOD_ADDRESS_3";
export const CHANGE_PAYMENT_BANK_ADDRESS = "CHANGE_PAYMENT_BANK_ADDRESS";
export const CHANGE_PAYMENT_METHOD_CITY = "CHANGE_PAYMENT_METHOD_CITY";
export const CHANGE_PAYMENT_METHOD_STATE = "CHANGE_PAYMENT_METHOD_STATE";
export const CHANGE_PAYMENT_METHOD_ZIPCODE = "CHANGE_PAYMENT_METHOD_ZIPCODE";
export const CHANGE_IS_PRIMARY = "CHANGE_IS_PRIMARY";

/////////////////////////////////////////////////////////////////////////////////////////////

//////////////////// actions related to related to fields component ////////////////////////

export const CHANGE_ROUTING = "CHANGE_ROUTING";
export const CHANGE_ACCOUNT_NAME = "CHANGE_ACCOUNT_NAME";
export const CHANGE_PAYEE_TYPE = "CHANGE_PAYEE_TYPE";
export const CHANGE_ACCOUNT_NUMBER = "CHANGE_ACCOUNT_NUMBER";
export const CHANGE_CONFIRM_ACCOUNT_NUMBER = "CHANGE_CONFIRM_ACCOUNT_NUMBER";
export const CHANGE_ACCOUNT_TYPE = "CHANGE_ACCOUNT_TYPE";
export const CHANGE_BANK_NAME = "CHANGE_BANK_NAME";
export const CHANGE_BANK_CODE = "CHANGE_BANK_CODE";
export const CHANGE_BIK_CODE = "CHANGE_BIK_CODE";
export const CHANGE_VO_CODE = "CHANGE_VO_CODE";
export const CHANGE_INN_TAX_ID = "CHANGE_INN_TAX_ID";
export const CHANGE_BRANCH_CODE = "CHANGE_BRANCH_CODE";
export const CHANGE_SWIFT_CODE = "CHANGE_SWIFT_CODE";
export const CHANGE_IBAN_CODE = "CHANGE_IBAN_CODE";
export const CHANGE_BSB_CODE = "CHANGE_BSB_CODE";
export const CHANGE_BUSINESS_NUMBER = "CHANGE_BUSINESS_NUMBER";
export const CHANGE_IFSC = "CHANGE_IFSC";
export const CHANGE_PHONE_NUMBER = "CHANGE_PHONE_NUMBER";
export const CHANGE_ACCOUNT_TAX_NUMBER = "CHANGE_ACCOUNT_TAX_NUMBER";
export const CHANGE_INSTITUTION_NUMBER = "CHANGE_INSTITUTION_NUMBER";
export const CHANGE_TRANSIT_NUMBER = "CHANGE_TRANSIT_NUMBER";
export const CHANGE_CARD_NUMBER = "CHANGE_CARD_NUMBER";
export const CHANGE_BIC_CODE = "CHANGE_BIC_CODE";
export const CHANGE_CNPJ = "CHANGE_CNPJ";
export const CHANGE_SORT_CODE = "CHANGE_SORT_CODE";
export const CHANGE_PAN = "CHANGE_PAN";
export const CHANGE_ACCOUNT_NAME_ENGLISH = "CHANGE_ACCOUNT_NAME_ENGLISH";
export const CHANGE_ID_NUMBER = "CHANGE_ID_NUMBER";
export const CHANGE_STOREFRONT_URL = "CHANGE_STOREFRONT_URL";
export const CHANGE_INCORPORATION_NUMBER = "CHANGE_INCORPORATION_NUMBER";
export const CHANGE_CPF = "CHANGE_CPF";
export const CHANGE_SUFFIX = "CHANGE_SUFFIX";
export const CHANGE_MERCHANT_NAME = "CHANGE_MERCHANT_NAME";
export const CHANGE_MERCHANT_ID = "CHANGE_MERCHANT_ID";
export const CHANGE_SUBSCRIBERS = "CHANGE_SUBSCRIBERS";
export const CHANGE_CAMBRIDGE_FIELD = "CHANGE_CAMBRIDGE_FIELD";
export const CHANGE_CAMBRIDGE_REGULATORY_FIELD = "CHANGE_CAMBRIDGE_REGULATORY_FIELD";
export const CHANGE_ID = "CHANGE_ID";
export const CHANGE_INTRMY_BIC = "CHANGE_INTRMY_BIC";
export const CHANGE_INTRMY_COUNTRY = "CHANGE_INTRMY_COUNTRY";
export const CHANGE_INTRMY_NAME = "CHANGE_INTRMY_NAME";
export const CHANGE_INTRMY_NUMBER = "CHANGE_INTRMY_NUMBER";
export const CHANGE_PURPOSE_CODE = "CHANGE_PURPOSE_CODE";
export const CHANGE_DELIVERY_METHOD = "CHANGE_DELIVERY_METHOD";
export const CHANGE_PROGRAM_ID = "CHANGE_PROGRAM_ID";
export const CHANGE_PAYMENT_STATUS = "CHANGE_PAYMENT_STATUS";
///////////////////////////EDIT PAYMENT METHOD/////////////////////////

export const EDIT_PAYMENT_METHOD = "EDIT_PAYMENT_METHOD";

/////////////////////////////////////////////////////////////////////////////////////////////

//////////////////// actions related to PyContactDetails fieldComponents ////////////////////////
export const CHANGE_FIRST_NAME = "CHANGE_FIRST_NAME";
export const CHANGE_LAST_NAME = "CHANGE_LAST_NAME";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_PHONE = "CHANGE_PHONE";

/////////////////////////////////////////////////////////////////////////////////////////////

export const CHECK_VALIDATIONS = "CHECK_VALIDATIONS";
export const CLEAR_VALIDATIONS = "CLEAR_VALIDATIONS";
export const CHANGE_IBAN_VALIDATIONS = "CHANGE_IBAN_VALIDATIONS";
export const CHANGE_BSB_VALIDATIONS = "CHANGE_BSB_VALIDATIONS";
export const CHANGE_INSTITUTE_VALIDATIONS = "CHANGE_INSTITUTE_VALIDATIONS";
export const CHANGE_TW_BIC_CODE = "CHANGE_TW_BIC_CODE";
export const CHANGE_ROUTING_MSG = "CHANGE_ROUTING_MSG";

/////////////////////////////// actions related to W9 Form//////////////////////////////////

export const UPDATE_SIGN_W9_FORM = "UPDATE_SIGN_W9_FORM";

/////////////////////////////// Add contact Form//////////////////////////////////

export const UPDATE_CONTACT_FORM = "UPDATE_CONTACT_FORM";

export const CHANGE_PAYMENT_ACCOUNT = "CHANGE_PAYMENT_ACCOUNT";

/////////////////////////////// PAYMENT //////////////////////////////////
export const SET_PAYMENT = "SET_PAYMENT";

/////////////////////////////// INVOICE //////////////////////////////////
export const SET_INVOICE = "SET_INVOICE";
export const CLEAR_INVOICE = "CLEAR_INVOICE";
export const SET_INVOICE_PAYMENT_METHOD = "SET_INVOICE_PAYMENT_METHOD";
export const SET_INVOICE_PURCHASER = "SET_INVOICE_PURCHASER";
export const SET_INVOICE_SERVICE_PERIOD_START = "SET_INVOICE_SERVICE_PERIOD_START";
export const SET_INVOICE_SERVICE_PERIOD_END = "SET_INVOICE_SERVICE_PERIOD_END";
export const SET_INVOICE_NUMBER = "SET_INVOICE_NUMBER";
export const SET_INVOICE_AMOUNT = "SET_INVOICE_AMOUNT";
export const SET_INVOICE_DATE = "SET_INVOICE_DATE";
export const SET_INVOICE_DESCRIPTION = "SET_INVOICE_DESCRIPTION";
export const SET_INVOICE_PO = "SET_INVOICE_PO";
export const SET_INVOICE_ASSETS = "SET_INVOICE_ASSETS";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_INVOICE_STATUS = "SET_INVOICE_STATUS";
export const UPDATE_INVOICE_DETAILS = "UPDATE_INVOICE_DETAILS";

/////////////////////////////// CREDIT MEMO //////////////////////////////////

export const SET_CREDIT_MEMO = "SET_CREDIT_MEMO";
export const CLEAR_CREDIT_MEMO = "CLEAR_CREDIT_MEMO";
export const SET_CREDIT_MEMO_PURCHASER = "SET_CREDIT_MEMO_PURCHASER";
export const SET_CREDIT_MEMO_SERVICE_PERIOD_START = "SET_CREDIT_MEMO_SERVICE_PERIOD_START";
export const SET_CREDIT_MEMO_SERVICE_PERIOD_END = "SET_CREDIT_MEMO_SERVICE_PERIOD_END";
export const SET_CREDIT_MEMO_NUMBER = "SET_CREDIT_MEMO_NUMBER";
export const SET_CREDIT_MEMO_AMOUNT = "SET_CREDIT_MEMO_AMOUNT";
export const SET_CREDIT_MEMO_PAYMENT_DATE = "SET_CREDIT_MEMO_PAYMENT_DATE";
export const SET_CREDIT_MEMO_DESCRIPTION = "SET_CREDIT_MEMO_DESCRIPTION";
export const SET_CREDIT_MEMO_INVOICES = "SET_CREDIT_MEMO_INVOICES";
export const SET_CREDIT_MEMO_ASSETS = "SET_CREDIT_MEMO_ASSETS";
export const SET_CREDIT_MEMO_CURRENCY = "SET_CREDIT_MEMO_CURRENCY";

//ONBOARDING//
export const SET_ONBOARDING_LIST_TEMPLATE = "SET_ONBOARDING_LIST_TEMPLATE";
export const SET_ONBOARDING_LIST_TEMPLATE_ITEMS = "SET_ONBOARDING_LIST_TEMPLATE_ITEMS";

// APPROVAL //
export const SET_APPROVAL_WORKFLOW_STEPS = "SET_APPROVAL_WORKFLOW_STEPS";
export const SET_VIEW = "SET_VIEW";
export const SET_WORKFLOW_TYPE = "SET_WORKFLOW_TYPE";
export const SET_ACTIVE_REQUIRED = "SET_ACTIVE_REQUIRED";
export const SET_CURRENT_FLOW_DATA = "SET_CURRENT_FLOW_DATA";
export const SET_ACTIVE_STEP_INDEX = "SET_ACTIVE_STEP_INDEX";
export const SET_APPROVAL_FORM_DATA = "SET_APPROVAL_FORM_DATA";
export const SET_ACTIVE_STEP_LABEL = "SET_ACTIVE_STEP_LABEL";
export const SET_ACTIVE_TIER_LABEL = "SET_ACTIVE_TIER_LABEL";
export const SET_ACTIVE_WORKFLOW_NAME = "SET_ACTIVE_WORKFLOW_NAME";
export const SET_LAST_STEP_SAVED_FLAG = "SET_LAST_STEP_SAVED_FLAG";
export const SET_SHOW_ADD_BUTTON_TOOLTIP = "SET_SHOW_ADD_BUTTON_TOOLTIP";
export const SET_IS_INACTIVE = "SET_IS_INACTIVE";
export const SET_STATIC_WORKFLOWS = "SET_STATIC_WORKFLOWS";
export const SET_STATIC_WORKFLOW_NAMES = "SET_STATIC_WORKFLOW_NAMES";
export const SET_STATIC_WORKFLOW_NAMES_ALL = "SET_STATIC_WORKFLOW_NAMES_ALL";
export const SET_ACTIVE_STATIC_WORKFLOW = "SET_ACTIVE_STATIC_WORKFLOW";
export const SET_STATIC_TIERS = "SET_STATIC_TIERS";
export const SET_ACTIVE_TIER_INDEX = "SET_ACTIVE_TIER_INDEX";
export const SET_STATIC = "SET_STATIC";
export const SET_ORIGINAL_WORKFLOW_NAME = "SET_ORIGINAL_WORKFLOW_NAME";
export const SET_WILL_TRIGGER = "SET_WILL_TRIGGER";

// COMPANY DEFAULT SETTING
export const SET_COMPANY_DEFAULT = "SET_COMPANY_DEFAULT";
export const SET_TAB = "SET_TAB";
export const GET_TERMS = "GET_TERMS";
// Integrations Settings
export const GET_COMPANY = "GET_COMPANY";
export const SET_IS_INTEGRATION_MODAL_OPEN = "SET_IS_INTEGRATION_MODAL_OPEN";

// Company Payment Methods
export const SET_COMPANY_PAYMENT_METHOD_FORM = "SET_COMPANY_PAYMENT_METHOD_FORM";
export const SET_COMPANY_PAYMENT_METHOD_MODAL_OPEN = "SET_COMPANY_PAYMENT_METHOD_MODAL_OPEN";
export const SET_COMPANY_PAYMENT_METHODS = "SET_COMPANY_PAYMENT_METHODS";
export const SET_COMPONENT_MOUNT_MODE = "SET_COMPONENT_MOUNT_MODE";
export const SET_IS_LOADING = "SET_IS_LOADING";

// address form
export const SET_ADDRESS_FORM = "SET_ADDRESS_FORM";
export const SET_ADDRESSES = "SET_ADDRESSES";
export const SET_ADDRESSABLE_TYPE = "SET_ADDRESSABLE_TYPE";
export const SET_ADDRESSABLE_ID = "SET_ADDRESSABLE_ID";

// important dates
export const SET_DATE_FORM = "SET_DATE_FORM";
export const SET_CALENDER_EVENTS = "SET_CALENDER_EVENTS";

//Manage Accounts
export const SET_MANAGEACCOUNT_FORM = "SET_MANAGEACCOUNT_FORM";
export const SET_MANAGEACCOUNT_MODAL_OPEN = "SET_MANAGEACCOUNT_MODAL_OPEN";
export const SET_MODAL_MOUNT = "SET_MODAL_MOUNT";

// Company Profile
export const SET_COMPANY_PROFILE_FORM = "SET_COMPANY_PROFILE_FORM";

// SAML configuration form
export const SET_SAML_CONFIGURATION_FORM = "SET_SAML_CONFIGURATION_FORM";
export const SET_SUBMITION_ERROR = "SET_SUBMITION_ERROR";
export const CHANGE_IS_SUBMITTED = "CHANGE_IS_SUBMITTED";

// vendor Contact form
export const SET_VENDOR_CONTACT_FORM = "SET_VENDOR_CONTACT_FORM";
export const RESET_VENDOR_CONTACT_FORM = "RESET_VENDOR_CONTACT_FORM";

// invoice email tab
export const SET_EMAIL_STATS = "SET_EMAIL_STATS";
export const SET_EMAIL_TAGS = "SET_EMAIL_TAGS";
export const SET_CURRENT_EMAIL_TAB = "SET_CURRENT_EMAIL_TAB";
export const SET_EMAIL_PAGINATION_DATA = "SET_EMAIL_PAGINATION_DATA";
export const SET_EMAIL_PAGINATION_SIZE = "SET_EMAIL_PAGINATION_SIZE";
export const SET_EMAIL_CURRENT_PAGE = "SET_EMAIL_CURRENT_PAGE";
export const SET_EMAIL_SEARCH_STATES = "SET_EMAIL_SEARCH_STATES";
export const SET_EMAIL_IS_SEARCHING = "SET_EMAIL_IS_SEARCHING";
export const SET_EMAIL_PARAMS_EMAIL_ID = "SET_EMAIL_PARAMS_EMAIL_ID";
export const SET_EMAIL_VIEW = "SET_EMAIL_VIEW";
export const SET_SHOW_ADVANCE_SEARCH = "SET_SHOW_ADVANCE_SEARCH";
export const SET_NEXT_PREVIOUS_INDEX = "SET_NEXT_PREVIOUS_INDEX";
export const SET_EMAIL_GRID_API = "SET_EMAIL_GRID_API";
export const SET_EMAILS_LOADING = "SET_EMAILS_LOADING";
export const SET_EMAIL_DETAIL_LOADING = "SET_EMAIL_DETAIL_LOADING";
export const SET_EMAIL_DETAILS = "SET_EMAIL_DETAILS";
export const SET_SHOW_DETAIL_PAGE = "SET_SHOW_DETAIL_PAGE";
export const RESET_COMPLETE_EMAIL_PAGE = "RESET_COMPLETE_EMAIL_PAGE";
export const SET_SELELCTED_EMAILS = "SET_SLELECTED_EMAILS";
export const SET_GRID_REFRESH_KEY = "SET_GRID_REFRESH_KEY";

// vendor initialSetup guide
export const SET_VENDOR_GUIDE_STEP = "SET_VENDOR_GUIDE_STEP";
export const SET_GUIDE_SETUP_PROGRESS = "SET_GUIDE_SETUP_PROGRESS";
export const SET_GUIDE_PURCHASERS = "SET_GUIDE_PURCHASERS";
export const SET_GUIDE_COMPANY_INFO = "SET_GUIDE_COMPANY_INFO";
export const SET_SHOW_WELCOME = "SET_SHOW_WELCOME";
export const SET_SHOW_GUIDE = "SET_SHOW_GUIDE";
export const RESET_VENDOR_SETUP_GUIDE = "RESET_VENDOR_SETUP_GUIDE";
export const SET_VENDOR_DOCUMENT_LINKS = "SET_VENDOR_DOCUMENT_LINKS";

// contact
export const SET_CONTACT = "SET_CONTACT";

//approval stats count
export const SET_APPROVALS_COUNT = "SET_APPROVALS_COUNT";
export const REMOVE_APPROVALS_COUNT = "REMOVE_APPROVALS_COUNT";

// Receipt Queue
export const SET_RECEIPT_DETAILS = "SET_RECEIPT_DETAILS";
