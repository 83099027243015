import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class VpAddInvoice extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <h1>{this.props.t("details.createInvoicePage")}</h1>
      </div>
    );
  }
}

export default withTranslation()(VpAddInvoice);
