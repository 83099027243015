import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { change, SubmissionError } from "redux-form";
import {
  onHideCompanyPaymentMethodModal,
  setCompanyPaymentMethodModalOpen,
  COMPANY_PAYMENT_METHOD_FORM_NAME,
} from "../../../reducers/admin/companyPaymentMethodFormReducer";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { createCompleteError } from "../../../services/general/reduxFormSvc";
import PaymentForm from "./form";
import RestApi from "../../../providers/restApi";
import { useSelector } from "react-redux";
import { paymentMethodCompanyLinkParseToAPI } from "../../../services/paymentMethods/paymentMethodCompanyLinks";

const restApiService = new RestApi();

const shouldUpdateCompanyData = (paymentType) => {
  // update array if any new payment Method will contian tranctionCode
  const formWithTransactionCode = ["ach", "can-eft", "aus-pay-net", "nz-eft", "sg-eft"];
  return formWithTransactionCode.includes(paymentType);
};

const Add = ({ onCreateCallBack }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const company = useSelector((state) => state.user.company);

  const onHide = useCallback(() => {
    dispatch(onHideCompanyPaymentMethodModal());
  }, []);

  const closeModal = () => {
    onHide();
  };

  const submit = async (paymentMethodFormData) => {
    try {
      let response;

      // in add mode for form, when form get submitted,
      // once payment method created, will not call post api
      if (paymentMethodFormData.form && !paymentMethodFormData.form.id) {
        paymentMethodCompanyLinkParseToAPI(paymentMethodFormData.form, company.id);
        response = await restApiService.post("payment_methods", null, {
          payment_method: {
            ...paymentMethodFormData.form,
            payment_methodable_type: "Company",
            payment_methodable_id: company.id,
          },
        });
      }

      // will prevent creation of multiple pm if once create and click multiple
      if (paymentMethodFormData.form && paymentMethodFormData.form.id) {
        response = await restApiService.patch(`payment_methods/${paymentMethodFormData.form.id}`, null, {
          payment_method: { ...paymentMethodFormData.form },
        });
      }

      if (response.data) {
        dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "form", response.data));

        // initialize form attribute
        if (response.data.payment_debit_accounts) {
          dispatch(
            change(
              COMPANY_PAYMENT_METHOD_FORM_NAME,
              "form.payment_debit_accounts_attributes",
              response.data.payment_debit_accounts,
            ),
          );
        }
        if (response.data.payment_credit_accounts) {
          dispatch(
            change(
              COMPANY_PAYMENT_METHOD_FORM_NAME,
              "form.payment_credit_accounts_attributes",
              response.data.payment_credit_accounts,
            ),
          );
        }

        CreateNotification(
          t("success"),
          t("companySettings.paymentMethodsSettings.paymentMethodCreated"),
          NotificationType.success,
        );

        CreateNotification(
          t("success"),
          t("companySettings.paymentMethodsSettings.paymentMethodContactSupport"),
          NotificationType.info,
        );

        // these callback will let update is_primary for other payment
        // when we set these paymentMethod as primay then others with related type
        // shold set to non primary
        if (onCreateCallBack) {
          onCreateCallBack({
            isPrimarySet: response.data.is_primary,
            paymentMethod: response.data,
          });
        }

        closeModal();
      }

      // also update company default
      if (
        paymentMethodFormData.company_default &&
        paymentMethodFormData.form &&
        shouldUpdateCompanyData(paymentMethodFormData.form.payment_type)
      ) {
        const response = await restApiService.patch("company/default", null, {
          company_default: paymentMethodFormData.company_default,
        });
        CreateNotification(
          t("success"),
          t("companySettings.paymentMethodsSettings.companyDefaultUpdated"),
          NotificationType.success,
        );
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          throw new SubmissionError({
            form: completeErrorObj,
          });
        }
      }
    }
  };

  const getCompanyDefalt = useCallback(async () => {
    const response = await restApiService.get("company/default");
    dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "company_default", response.data));
  }, []);

  const initializePaymentForm = useCallback(() => {
    // set intialform template
    dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "form.payment_type", "ach"));
    dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "form.status", "INACTIVE"));

    // opening Modal
    dispatch(setCompanyPaymentMethodModalOpen(true));
    getCompanyDefalt();
  }, []);

  useEffect(() => {
    initializePaymentForm();
  }, []);

  return <PaymentForm onSubmit={submit} onHide={onHide} />;
};

export default Add;
