import React, { memo } from "react";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import style from "./expenseItem.module.css";
import FileUploader from "components/common/fileUploader/fileUploader";
import { CreateNotification, NotificationType } from "services/general/notifications";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { GridApi } from "ag-grid-community";

type UploadReceiptPropsType = {
  expenseItem: ExpensesTypes.ListItem;
  gridApi: GridApi;
};

const UploadReceipt = ({ expenseItem, gridApi }: UploadReceiptPropsType) => {
  const uploadReciept = async (files: File[]) => {
    const formData = new FormData();
    formData.append("expense_item[assets_attributes][0][asset_file]", files[0]);
    formData.append("expense_item[assets_attributes][0][asset_file_file_name]", files[0].name);
    try {
      if (expenseItem.id) {
        const result = await ExpensesApis.patchAttachaments({
          id: expenseItem.id,
          formData: formData,
        });
        gridApi.refreshServerSide();
        CreateNotification("Updated", "Files Attached", NotificationType.success);
      }
    } catch (error) {
      CreateNotification("Error", "Unable to upload attachment", NotificationType.danger);
      console.log(error);
    }
  };

  /**
   * If a receipt is attached to the expense item, the Receipt Attached column should display “Yes”
   * If a receipt is not attached and is required per policy, the Receipt Attached column should display “No” with an upload icon
   * If a receipt is not attached and is not required per policy, the Receipt Attached column should only display “No”
   * */

  if (expenseItem.has_assets === true) return <>Yes</>;
  else
    return (
      <>
        <span className={style.uploadReceiptNo}>No</span>

        {expenseItem.receipt_required?.toLowerCase() === "yes" && (
          <FileUploader
            customButton={<button className="actionButtons upload-receipt-icon px-ml-4" />}
            showUploadBtn
            uploadAttachments={(files: File[]) => uploadReciept(files)}
            multiple={false}
          />
        )}
      </>
    );
};

export default memo(UploadReceipt);
