import { RenderField } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const ContactLastName = ({ label, isContactLastNameRequired, tooltip, validations }) => {
  return (
    <Col md="6">
      <Field
        id="form.billing_last_name"
        name="form.billing_last_name"
        component={RenderField}
        type="text"
        label={label}
        tooltip={tooltip}
        required={isContactLastNameRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default ContactLastName;
