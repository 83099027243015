import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { minLength, required } from "../../../../../services/validations/reduxFormValidation";
import { RenderField } from "../../../../forms/bootstrapFields";
import IbanValidator from "../../ibanValidator";

const TwPkr = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const min8 = minLength(8);

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <IbanValidator modelName={`${modelName}`} maxLength={42} noValidate={true} />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.bic`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bicCode")}
          className={`w-100`}
          maxLength={11}
          validate={[min8]}
        />
      </Col>
    </>
  );
};

export default TwPkr;
