import React from "react";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";
import ManageDefaultAccountsModal from "./accountsModal";
import { SubsidiaryDetailType } from "../../../services/admin/subsidiaries/types";
import { useTypedSelector } from "../../../reducers";

type SubsidiaryAccountsType = {
  modelData: SubsidiaryDetailType;
  modelName: string;
  saveEditCallback: () => void;
  isHide: boolean;
};

const ManageSubsidiaryAccounts = ({ modelData, modelName, saveEditCallback, isHide }: SubsidiaryAccountsType) => {
  const componentMountMode = useTypedSelector((state) => state.manageAccountFormRed.componentMountMode);
  return (
    <ErrorBoundary>
      {componentMountMode && (
        <ManageDefaultAccountsModal
          modelData={modelData}
          modelName={modelName}
          saveEditCallback={saveEditCallback}
          hide={saveEditCallback}
        />
      )}
    </ErrorBoundary>
  );
};

export default ManageSubsidiaryAccounts;
