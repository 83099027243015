import React, { useEffect, useRef } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import EmailTabs from "./emailTabs";
import emailStyles from "./email.module.css";
import { AgGridReact } from "ag-grid-react";
import { ColumnConfig } from "../../../../services/common/gridService";
import ChildMessageRenderer from "../../../datagrid/childMessageRenderer";
import ChildCellRouting from "../../../datagrid/childCellRouting";
import CustomStatsToolPanel from "../../../datagrid/customStatsToolPanel";
import FloatingFilterComponent from "../../../datagrid/floatingFilterComponent";
import RandomChildCellRouting from "../../../datagrid/randomCellChildRouting";
import { draftInboxListHeader } from "./emailDraftInboxHeaders";
import { GridReadyEvent } from "ag-grid-community";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import {
  currentPageSetter,
  gridItemClicked,
  onChangeCurrentEmailTab,
  onGridDataChange,
  onGridReady,
  onPaginationChange,
  onPaginationSizeChange,
  paginationSizeSetter,
  refreshGrid,
  selectedEmailSetter,
} from "../../../../reducers/admin/emailReducer";
import GridPagination from "../../../common/dataGrid/gridPagination";
import { getRowNodeId } from "../../../../services/admin/invoices/emailSvc";
import DraftInboxListCellRenderer from "../../../datagrid/draftInboxListCellRenderer";
import ListViewEmailTopNavButton from "./listViewEmailTopNavButton";
import EmailDetails from "./emailDetails";

export type emailListViewPropsTypes = {
  gridRef: React.RefObject<AgGridReact>;
};

const EmailListView = ({ gridRef }: emailListViewPropsTypes) => {
  const dispatch = useDispatch();
  const emailDetails = useSelector((state: RootState) => state.emailRed.emailDetails);
  const showDetailPage = useSelector((state: RootState) => state.emailRed.showDetailPage);
  const emailDetailLoading = useSelector((state: RootState) => state.emailRed.emailDetailLoading);

  const gridElementRef = useRef<HTMLDivElement>(null);

  const currentEmailTab = useSelector((state: RootState) => state.emailRed.currentEmailTab);
  const paginationData = useSelector((state: RootState) => state.emailRed.paginationData);
  const paginationSize = useSelector((state: RootState) => state.emailRed.paginationSize);
  const currentPage = useSelector((state: RootState) => state.emailRed.currentPage);

  const setPaginationSize = (paginationSize: number) => {
    dispatch(paginationSizeSetter(paginationSize));
  };

  const setCurrentPage = (pageNumber: number) => {
    dispatch(currentPageSetter(pageNumber));
  };

  const onPaginationChanged = () => {
    if (gridRef.current) {
      dispatch(onPaginationChange({ gridApi: gridRef.current.api }));
    }
  };

  const onGridDataChanged = () => {
    if (gridRef.current) {
      dispatch(onGridDataChange({ gridApi: gridRef.current.api }));
    }
  };

  const gridReady = (params: GridReadyEvent) => {
    dispatch(onGridReady(params));
  };

  const onPaginationSizeChanged = () => {
    if (gridRef.current) {
      dispatch(onPaginationSizeChange());
    }
  };

  const currentEmailTabChanged = () => {
    dispatch(onChangeCurrentEmailTab());
  };

  const onClickRow = (row: any) => {
    dispatch(gridItemClicked(row));
  };

  const setSelectedEmails = () => {
    dispatch(selectedEmailSetter());
  };

  useEffect(() => {
    currentEmailTabChanged();
  }, [currentEmailTab]);

  return (
    <Row className={"tabContainer w-100 emailTab bg-white"}>
      <Col xl={2} className={"pr-0 pl-0 h-100"}>
        {/*email side menu*/}
        <EmailTabs />
      </Col>
      <Col xl={10} className={"pr-0 pl-0 emailGrid " + emailStyles.flexContainerCol}>
        {/*email common buttons*/}
        <Row className={"m-0 " + emailStyles.listEmailHeaderButtons}>
          {gridRef.current?.api && <ListViewEmailTopNavButton />}
        </Row>

        {/*email list page*/}
        <Row className={`email-list m-0 ${showDetailPage ? "d-none" : ""}` + emailStyles.listEmailGridSection}>
          <div ref={gridElementRef} className={"ag-theme-fresh " + emailStyles.gridStyle}>
            <AgGridReact
              ref={gridRef}
              getRowId={getRowNodeId}
              columnDefs={draftInboxListHeader}
              rowModelType="serverSide"
              onGridReady={gridReady}
              onCellClicked={(row) => onClickRow(row)}
              onPaginationChanged={onPaginationChanged}
              onRowDataUpdated={onGridDataChanged}
              rowHeight={45}
              headerHeight={0}
              floatingFiltersHeight={ColumnConfig.floatingFiltersHeight}
              rowSelection={"multiple"}
              components={{
                childMessageRenderer: ChildMessageRenderer,
                childCellRouting: ChildCellRouting,
                randomChildCellRouting: RandomChildCellRouting,
                customStatsToolPanel: CustomStatsToolPanel,
                floatingFilterComponent: FloatingFilterComponent,
                draftInboxCellRenderer: DraftInboxListCellRenderer,
              }}
              suppressServerSideInfiniteScroll={false}
              cacheBlockSize={paginationSize}
              pagination={true}
              paginationPageSize={paginationSize}
              suppressPaginationPanel={true}
              onSelectionChanged={setSelectedEmails}
            />
          </div>
        </Row>

        {/* we don't need pagination for detail page */}
        {!showDetailPage && (
          <Row className="m-0">
            <Col>
              {gridRef.current?.api && (
                <GridPagination
                  gridApi={gridRef.current?.api}
                  currentPage={currentPage}
                  paginationData={paginationData}
                  paginationSizeChanged={onPaginationSizeChanged}
                  paginationSize={paginationSize}
                  setCurrentPage={setCurrentPage}
                  setPaginationSize={setPaginationSize}
                  paginationOptions={[10, 25, 50, 100]}
                  refreshGrid={() => dispatch(refreshGrid())}
                />
              )}
            </Col>
          </Row>
        )}

        {emailDetailLoading && <Spinner className={"loaderFullScreen"} animation="border" variant="info" />}

        {showDetailPage && emailDetails && Object.keys(emailDetails).length !== 0 && <EmailDetails />}
      </Col>
    </Row>
  );
};

export default EmailListView;
