import useIsMounted from "components/common/hooks/useIsMounted";
import CustomModal from "components/modals/customModal";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DocumentsApis from "services/admin/documents/documentApis";
import { DocumentsRequiredType } from "services/admin/documents/documentsType";
import { VendorDetailType } from "services/admin/vendors/vendorTypes";
import { VendorApis } from "services/admin/vendors/vensorApis";
import styles from "../vendors.module.css";

type UploadedDocumentsModalPropsType = {
  id: string;
  name: string;
  show: boolean;
  closeDocumentsModal: () => void;
};

type UploadedDocumentsPropsType = { id: string; name: string };

export const UploadedDocumentsModal = ({ id, name, show, closeDocumentsModal }: UploadedDocumentsModalPropsType) => {
  const [vendorDetail, setVendorDetail] = useState<VendorDetailType>();
  const [requiredDocuments, setRequiredDocuments] = useState<Array<DocumentsRequiredType>>([]);
  const [parsedRequiredDocuments, setParsedRequiredDocuments] = useState<
    Array<DocumentsRequiredType & { documentLinked?: boolean }>
  >([]);
  const isMounted = useIsMounted();

  const getVendorDetail = async () => {
    try {
      const response = await VendorApis.getVendor(id);
      if (isMounted.current) {
        setVendorDetail(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDocumentRequiredList = async () => {
    try {
      const response = await DocumentsApis.getRequiredDocumentsList({ cache: true });
      if (isMounted.current) {
        setRequiredDocuments(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getVendorDetail();
    getDocumentRequiredList();
  }, []);

  useEffect(() => {
    if (_.isArray(requiredDocuments) && vendorDetail) {
      let parseRequiredDocuments = requiredDocuments.map(function (requiredDocument) {
        let found = _.isArray(vendorDetail.document_vendor_links)
          ? Boolean(
              vendorDetail.document_vendor_links.find((link) => link.document_requirement_id == requiredDocument.id),
            )
          : false;
        return { ...requiredDocument, documentLinked: found };
      });
      setParsedRequiredDocuments(parseRequiredDocuments);
    }
  }, [vendorDetail, requiredDocuments]);

  return (
    <CustomModal
      show={show}
      size={"lg"}
      onHide={closeDocumentsModal}
      backdrop={true}
      header={<h5 className={styles.uploadDocumentHeader}>{`${name} Documents`}</h5>}
      body={
        <Row className="m-2">
          <Col sm="6" className="mb-2">
            <p className={styles.uploadDocumentType}>Required Documents (Not Uploaded)</p>
          </Col>
          <Col sm="6" className="mb-2">
            <p className={styles.uploadDocumentType}>Uploaded Documents</p>
          </Col>
          <Col sm="6" className="my-2 border-right">
            <Row>
              {parsedRequiredDocuments.map(
                (requiredDocument) =>
                  !requiredDocument.documentLinked && (
                    <Col xs="6" className="mb-1">
                      <div className={styles.bubbleInitial + " " + styles.documentName}>
                        {requiredDocument.document_type}
                      </div>
                    </Col>
                  ),
              )}
            </Row>
          </Col>
          <Col sm="6" className="my-2">
            <Row>
              {parsedRequiredDocuments.map(
                (requiredDocument) =>
                  requiredDocument.documentLinked && (
                    <Col xs="6" className="mb-2">
                      <div className={styles.bubbleInitial + " " + styles.documentName}>
                        {requiredDocument.document_type}
                      </div>
                    </Col>
                  ),
              )}
            </Row>
          </Col>
        </Row>
      }
    />
  );
};

const UploadedDocuments = ({ id, name }: UploadedDocumentsPropsType) => {
  const [showDocumentsModal, setShowDocumentsModal] = useState<boolean>(false);

  const closeUploadModal = () => {
    setShowDocumentsModal(false);
  };

  return (
    <>
      {showDocumentsModal && (
        <UploadedDocumentsModal show={showDocumentsModal} closeDocumentsModal={closeUploadModal} id={id} name={name} />
      )}
      <a className="link" onClick={() => setShowDocumentsModal((prev) => !prev)}>
        View
      </a>
    </>
  );
};

export default UploadedDocuments;
