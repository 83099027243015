import { GridApi } from "ag-grid-community";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { paginationDataType } from "../../../services/common/gridService";
import style from "./gridStyles.module.css";

export type gridPaginationPropsTypes = {
  gridApi: GridApi;
  currentPage: number;
  setCurrentPage: (pageNumber: number) => void;
  paginationData: paginationDataType | undefined;
  paginationSize: number;
  paginationSizeChanged: () => void;
  setPaginationSize: (paginationSize: number) => void;
  paginationOptions: number[];

  // we need these function for recreating ag-grid instance with new cache block size
  refreshGrid: () => void;
};

const GridPagination = ({
  currentPage,
  setCurrentPage,
  gridApi,
  paginationData,
  paginationSize,
  setPaginationSize,
  paginationSizeChanged,
  paginationOptions,
  refreshGrid,
}: gridPaginationPropsTypes) => {
  const onFirst = () => {
    gridApi?.paginationGoToFirstPage();
  };

  const onNext = () => {
    gridApi?.paginationGoToNextPage();
  };

  const onPrevious = () => {
    gridApi?.paginationGoToPreviousPage();
  };

  const onLast = () => {
    gridApi?.paginationGoToLastPage();
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPageNumber = parseInt(e.target.value);
    gridApi?.paginationGoToPage(newPageNumber - 1);
    setCurrentPage(newPageNumber);
  };

  const onPageSizeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const pageSize = Number(e.target.value);
    setPaginationSize(pageSize);
    paginationSizeChanged();
    refreshGrid();
  };

  return (
    <Row className="py-2">
      <Col className="d-flex justify-content-start align-items-center">
        <Row>
          <Col>
            <Button size="sm" variant="secondary" className={"mr-1 "} onClick={() => onFirst()}>
              <i className={"icon-arrow-left-start " + style.iconSm} />
            </Button>
            <Button size="sm" variant="secondary" className="mr-1" onClick={() => onPrevious()}>
              <i className={"icon-arrow-left " + style.iconSm} />
            </Button>
            <input
              type="number"
              className={"ml-3 mr-1 " + style.smallInput}
              value={currentPage}
              onChange={handlePageInputChange}
              min="1"
              max={paginationData?.totalPages}
            />
            / {paginationData?.totalPages}
            <Button size="sm" variant="secondary" className="mx-1 ml-3" onClick={() => onNext()}>
              <i className={"icon-arrow-right " + style.iconSm} />
            </Button>
            <Button size="sm" variant="secondary" className="mr-1" onClick={() => onLast()}>
              <i className={"icon-arrow-right-end " + style.iconSm} />
            </Button>
            <>
              <select
                value={paginationSize}
                onChange={(e) => onPageSizeChanged(e)}
                className={"mx-2 mr-2 " + style.smallSelect}
                name="pagination-size"
              >
                {paginationOptions.map((size) => {
                  return (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  );
                })}
              </select>
              items per page
            </>
          </Col>
        </Row>
      </Col>
      <Col lg="3" className="d-flex justify-content-end">
        <Row className="m-0 m-2">
          {paginationData?.firstRow} to {paginationData?.lastRow} from {paginationData?.totalRows}
        </Row>
      </Col>
    </Row>
  );
};

export default GridPagination;
