import TooltipRender from "components/toolTip/tooltipRender";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CreateNotification, NotificationType } from "services/general/notifications";

interface RefreshCacheButtonProps {
  onRefreshComplete: () => void;
  refreshCacheFunction: () => Promise<void>;
}

const RefreshCacheButton: React.FC<RefreshCacheButtonProps> = ({ onRefreshComplete, refreshCacheFunction }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const refreshCache = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await refreshCacheFunction();
      CreateNotification(
        t("components.common.refreshCache.successTitle"),
        t("components.common.refreshCache.successBody"),
        NotificationType.success,
      );
      onRefreshComplete();
    } catch (error) {
      console.error("Refresh cache error:", error);
      CreateNotification(
        t("components.common.refreshCache.warningTitle"),
        t("components.common.refreshCache.warningBody"),
        NotificationType.warning,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TooltipRender title={<strong>{`Refresh List Cache`}</strong>} placement="left">
      <button onClick={refreshCache} disabled={isLoading} className="icon-normal mx-2 icon-refresh border-none">
        <span className="sr-only">{isLoading ? t("Refreshing...") : t("Refresh Cache")}</span>
      </button>
    </TooltipRender>
  );
};

export default RefreshCacheButton;
