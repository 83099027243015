import React, { useCallback, useEffect, useRef, useState } from "react";
import { VendorPickerPropsType } from ".";
import AsyncSelect from "react-select/async";
import { onBlurSingle, parseForSelect } from "services/general/helpers";
import { Form } from "react-bootstrap";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import { restApiService } from "providers/restApi";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";

type SingleVendorPickerPropType = VendorPickerPropsType;

type ParamsPropsType = {
  VENDOR_NAME: string;
  VENDOR_LIMIT: number;
  PO_NOT_REQUIRED?: boolean | string;
  status?: string;
  exclude_statuses?: string;
  subsidiary_id?: number;
};

const SingleVendorPicker = ({
  label,
  input,
  meta: { touched, error },
  tooltip,
  params,
  required,
  instanceId = "vendor-picker",
  placeholder,
  disabled,
  originalValue,
  callBack,
  poNotRequired,
  modelData,
  modelName,
  excludeStatuses,
}: SingleVendorPickerPropType) => {
  const [selected, setSelected] = useState({});
  const mounted = useRef(false);
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const onChangeVendor = (input: any, value: any) => {
    if (!value) {
      input.onChange("");
      return;
    }
    input.onChange(value);
    setSelected(value);
    if (callBack) {
      callBack(value);
    }
  };

  const loadOptions = useCallback(
    async (inputValue: string) => {
      if (!inputValue) {
        return []; // Return empty array when inputValue is empty
      }

      const getParams: ParamsPropsType = {
        VENDOR_NAME: inputValue,
        VENDOR_LIMIT: 100,
      };

      if (modelName === "purchase_order" && currentUser?.company?.purchase_order?.show_po_required_vendors) {
        getParams.PO_NOT_REQUIRED = false;
      } else {
        getParams.PO_NOT_REQUIRED = "";
      }

      if (excludeStatuses === "INACTIVE") {
        getParams.status = "EXCLUDE_INACTIVE"; //excluding INACTIVE and REJECTED
      } else if (excludeStatuses) {
        getParams.exclude_statuses = excludeStatuses; //excluding all the statuses which are sending to directive
      } else {
        getParams.status = "ACTIVE"; //it will return only active vendors if there is no status mentioned to exclude
      }

      if (currentUser?.company?.po_request_allow_vendor_on_subsidiary && modelData) {
        getParams.subsidiary_id = modelData?.subsidiary_id;
      }

      try {
        const result = await restApiService.get("vendors.lk", getParams, null, true, null, false);
        let parseOptions = parseForSelect(result.data);
        return parseOptions;
      } catch (error) {
        CreateNotification("Error", "Service Error.", NotificationType.success);
      }
    },
    [modelData?.subsidiary_id],
  );

  useEffect(() => {
    mounted.current = true;
    const vendorObj = {
      value: input.value.id ? input.value.id : input.value.value,
      label: (
        <>
          {input.value.name ? input.value.name : input.value.label}
          {input.value.status === "INACTIVE" && <small style={{ fontSize: "11px" }}>- ({input.value.status})</small>}
        </>
      ),
    };
    setSelected(vendorObj);
    return () => {
      mounted.current = false;
    };
  }, [input]);

  return (
    <Form.Group>
      <Form.Label>
        Vendor
        <Mandatory required={required} />
        <span style={{ fontSize: "11px" }}>{poNotRequired ? " No PO Required" : " (PO Required)"}</span>
        {tooltip ?? ""}
      </Form.Label>
      <AsyncSelect
        value={selected}
        cacheOptions
        loadOptions={loadOptions}
        onChange={(value: any) => onChangeVendor(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        defaultOptions
        instanceId={instanceId} // don't remove or change used in automation testing
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default SingleVendorPicker;
