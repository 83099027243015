import { useEffect } from "react";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { sortBy } from "lodash";

const useAlphabeticallySortedToolPanel = (
  columnDefs: ColDef<any, any>[],
  gridRef: React.RefObject<AgGridReact<any>>,
) => {
  const obj: any = {};
  const sortedColumnDefs = sortBy(
    [...columnDefs],
    (col) => typeof col.headerValueGetter == "function" && col?.headerValueGetter(obj),
  );

  useEffect(() => {
    if (gridRef.current) {
      const columnToolPanel = gridRef.current?.api?.getToolPanelInstance("columns");

      if (columnToolPanel) {
        // Convert sorted column definitions to column state
        const sortedToolPanelColumnDefs = sortedColumnDefs.map((colDef) => ({
          colId: colDef.field,
          hide: false,
        }));

        // Set the sorted column layout to the column tool panel
        columnToolPanel.setColumnLayout(sortedToolPanelColumnDefs);
      }
    }
  }, [gridRef, sortedColumnDefs]);
};

export default useAlphabeticallySortedToolPanel;
