import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { required } from "../../../../forms/inputFields";
import IbanValidator from "../../ibanValidator";
import BicValidator from "../../bicValidator";
import { IDType } from "../../../../../services/common/types/common.type";
import { useTranslation } from "react-i18next";
import PurposeCode from "./purposeCode";

const WireSek = ({ modelName }: { modelName?: string }) => {
  const [ibanNumber, setIbanNumber] = useState<IDType>();
  const { t } = useTranslation();

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <IbanValidator modelName={`${modelName}`} callBack={(value) => setIbanNumber(value)} />
      </Col>
      <Col md="6">
        <BicValidator modelName={`${modelName}`} ibanNumber={ibanNumber} />
      </Col>
      <Col md="12">
        <PurposeCode />
      </Col>
    </>
  );
};

export default WireSek;
