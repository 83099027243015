import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import styles from "../../vendors.module.css";
import { useTranslation } from "react-i18next";
import { companyDateFormat } from "../../../../../services/general/dateSvc";
import { IUser } from "../../../../../services/common/user/userTypes";
import { useTypedSelector } from "../../../../../reducers";
import _ from "lodash";
import { formattedCustomFields } from "services/admin/vendors/vendorSvc";

type VendorAdditionalInfoType = {
  vendor: any;
  handleEdit: (step: number) => void;
};

const VendorAdditionalInfo = ({ vendor, handleEdit }: VendorAdditionalInfoType) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(true);
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const changeToCamalCase = (key: string) => {
    if (key) {
      return key.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => " " + chr.toUpperCase());
    }
  };

  const customFields = vendor.custom_fields ? formattedCustomFields(vendor.custom_fields) : null;

  return (
    <>
      <Row>
        <Col md="12">
          <span>
            <i className={`icon icon-vendors ${styles.detailPageIconWidth}`}></i>
            <span className={styles.title}>{t("admin.pages.vendor.additionalDetails")}</span>
            <div className="float-right">
              <Button variant="secondary" className={`px-mr-10 ${styles.btnEdit}`} onClick={() => handleEdit(2)}>
                <i className="icon icon-edit px-m-0"></i>
                {t("admin.pages.vendor.edit")}
              </Button>
              <span className={`float-right ${styles.iconToggle}`}>
                <i
                  className={toggle ? "icon icon-arrow-up" : "icon icon-arrow-down"}
                  onClick={() => setToggle(!toggle)}
                ></i>
              </span>
            </div>
          </span>
        </Col>
      </Row>
      {toggle && (
        <>
          <Row className={`px-mt-25 ${styles.vendorInfoSection}`}>
            <Col md="3">
              <label>{t("admin.pages.vendor.purchaserAccount")}</label>
              <span>{vendor.purchaser_account || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.externalId")}</label>
              <span>{vendor.external_id || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.primarySubsidiary")}</label>
              <span>{vendor.subsidiary || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.additionalSubsidiary")}</label>
              {_.isArray(vendor?.subsidiaries) && vendor?.subsidiaries.length > 0
                ? vendor.subsidiaries.map((subsidiary: { value: string; label: string }, index: number) => {
                    return (
                      <div key={index} className={styles.multipleElement}>
                        {subsidiary.label}
                        {index !== vendor.subsidiaries.length - 1 && ",\u00A0"}
                      </div>
                    );
                  })
                : "-"}
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.receiver")}</label>
              <span>{vendor.receiver_id || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.defaultCurrency")}</label>
              <span>{vendor.currency_code || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.currencyCodes")}</label>
              {_.isArray(vendor?.additional_currency_codes) && vendor?.additional_currency_codes.length > 0
                ? vendor?.additional_currency_codes?.map((code: any, index: number) => (
                    <div className={styles.multipleElement} key={index}>
                      {code.label}
                      {index !== vendor.additional_currency_codes.length - 1 && ",\u00A0"}
                    </div>
                  ))
                : "-"}
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.contractEndDate")}</label>
              <span>{companyDateFormat(vendor.contract_end_date, currentUser)}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.typeId")}</label>
              <span>{vendor.type_id || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.paymentTerm")}</label>
              <span>{vendor.paymentTerm || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.vatId")}</label>
              <span>{vendor.ein || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.1099VendorName")}</label>
              <span>{vendor.tax_form_name || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.1099FormType")}</label>
              <span>{vendor.formType1099 || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.1099DefaultBox")}</label>
              <span>{vendor.taxFormName1099 || "-"}</span>
            </Col>
            <Col md="4">
              <label>{t("admin.pages.vendor.requestor")}</label>
              <span>{vendor.requestor_id || "-"}</span>
            </Col>
          </Row>
          {customFields && (
            <>
              <Row className={styles.rowSeparator}>
                <Col>
                  <label className={styles.sectionTitle}>{t("admin.customField.customFieldHeader")}</label>
                </Col>
              </Row>
              <Row className={`px-mt-25 ${styles.vendorInfoSection}`}>
                {Object.keys(customFields).map((key) =>
                  typeof customFields[key].value !== "object" ? (
                    <Col md="4" key={key}>
                      <label>{changeToCamalCase(key)}</label>
                      <span className={styles.breakWord}>
                        {customFields[key].value === true ? "Yes" : customFields[key].value || "-"}
                      </span>
                    </Col>
                  ) : (
                    <Col md="4" key={key}>
                      <label>{changeToCamalCase(key)}</label>
                      <span>{customFields[key].value.label || "-"}</span>
                    </Col>
                  ),
                )}
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default VendorAdditionalInfo;
