import React from "react";
import style from "./approvalsNavigationBar.module.css";
import { Col, Row } from "react-bootstrap";
import { Can } from "../../../services/authorization/authorization";
import { useLocation } from "react-router-dom";
import { restApiService } from "../../../providers/restApi";
import { useTypedSelector } from "../../../reducers";

const ApprovalsNavigationBar = () => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const approvalCount = useTypedSelector((state) => state.approvalCount);

  return (
    <Row className={`${style.approvalsCardSection}`}>
      <Col md="12" sm="12">
        <ul>
          <Can I="purchase" a="_orders_active">
            <a
              id="purchase_orders_approval"
              className={`${style.approvalCard} ${currentUrl === "/ap/po_co_approvals" ? style.activeApprovalTab : ""}`}
              href={restApiService.angularBaseURL() + "po_approvals"}
            >
              <Row>
                <Col sm="12" md="12">
                  <span className={`${style.approvalSectionIcon}`}>
                    <i className={`icon icon-po-approval ${style.icon50}`}></i>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className={`${style.approvalCardCountDiv}`}>
                  <div className={`${style.approvalCardCount}`}>{approvalCount.po_pending_count} POs</div>
                </Col>
              </Row>
            </a>
          </Can>
          <Can I="invoices" a="_active">
            <a
              id="invoices_approval"
              className={`${style.approvalCard}`}
              href={restApiService.angularBaseURL() + "invoices_approval"}
            >
              <Row>
                <Col sm="12" md="12">
                  <span className={`${style.approvalSectionIcon}`}>
                    <i className={`icon icon-invoices-approval ${style.icon50}`}></i>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className={`${style.approvalCardCountDiv}`}>
                  <div className={`${style.approvalCardCount}`}>{approvalCount.invoice_pending_count} Invoices</div>
                </Col>
              </Row>
            </a>
          </Can>
          <Can I="expenses" a="_active">
            <a
              className={`${style.approvalCard}`}
              id="expenses_approval"
              href={restApiService.angularBaseURL() + "expense_approvals"}
            >
              <Row>
                <Col sm="12" md="12">
                  <span className={`${style.approvalSectionIcon}`}>
                    <i className={`icon icon-expenses-approval ${style.icon50}`}></i>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className={`${style.approvalCardCountDiv}`}>
                  <div className={`${style.approvalCardCount}`}>
                    {approvalCount.expense_report_pending_count} Expenses
                  </div>
                </Col>
              </Row>
            </a>
          </Can>
          <Can I="virtual" a="_cards_active">
            <a
              className={`${style.approvalCard}`}
              id="invoice_pay_bills"
              href={restApiService.angularBaseURL() + "vcard_approvals"}
            >
              <Row>
                <Col sm="12" md="12">
                  <span className={`${style.approvalSectionIcon}`}>
                    <i className={`icon icon-vcards-approval ${style.icon50}`}></i>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className={`${style.approvalCardCountDiv}`}>
                  <div className={`${style.approvalCardCount}`}>
                    {approvalCount.vcard_pending_count + approvalCount.purchase_pending_count} Cards
                  </div>
                </Col>
              </Row>
            </a>
          </Can>
          <Can I="payments" a="_active">
            <a
              className={`${style.approvalCard}`}
              id="payments_approval"
              href={restApiService.angularBaseURL() + "payments_approval"}
            >
              <Row>
                <Col sm="12" md="12">
                  <span className={`${style.approvalSectionIcon}`}>
                    <i className={`icon icon-payments-approval ${style.icon50}`}></i>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className={`${style.approvalCardCountDiv}`}>
                  <div className={`${style.approvalCardCount}`}>
                    {approvalCount.payment_pending_count + approvalCount.payment_run_approval_count} Payments
                  </div>
                </Col>
              </Row>
            </a>
          </Can>
          <Can I="credit" a="_memos_active">
            <a
              className={`${style.approvalCard}`}
              id="vendor_credits_approval"
              href={restApiService.angularBaseURL() + "credit_memos_approval/"}
            >
              <Row>
                <Col sm="12" md="12">
                  <span className={`${style.approvalSectionIcon}`}>
                    <i className={`icon icon-credit-memos-approval ${style.icon50}`}></i>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className={`${style.approvalCardCountDiv}`}>
                  <div className={`${style.approvalCardCount}`}>
                    {approvalCount.vendor_credit_pending_count} Credit Memos
                  </div>
                </Col>
              </Row>
            </a>
          </Can>

          <Can I="vendors" a="_active">
            <a
              className={`${style.approvalCard}`}
              id="vendors_approval"
              href={restApiService.angularBaseURL() + "vendor_approvals"}
            >
              <Row>
                <Col sm="12" md="12">
                  <span className={`${style.approvalSectionIcon}`}>
                    <i className={`icon icon-vendors-approval ${style.icon50}`}></i>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className={`${style.approvalCardCountDiv}`}>
                  <div className={`${style.approvalCardCount}`}>{approvalCount.vendor_pending_count} Vendors</div>
                </Col>
              </Row>
            </a>
          </Can>
        </ul>
      </Col>
    </Row>
  );
};

export default ApprovalsNavigationBar;
