import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { EditContactPayloadType, IContact, UpdateDelegatesPayloadType } from "./contactTypes";
import { getListOptionsType } from "../types/common.type";

class ContactApis {
  static async getContacts({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<Array<IContact>> = await restApiService.get(
        "contacts",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getContact(id: number) {
    try {
      const response: AxiosResponse<IContact> = await restApiService.get("contacts/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // edit contact
  static async editContact(id: number, data: EditContactPayloadType) {
    try {
      const response: AxiosResponse<IContact> = await restApiService.patch("contacts/" + id, null, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async addVendorAdminRole(id: number | string) {
    try {
      const response: AxiosResponse<{ contact_id: string }> = await restApiService.post(
        "contacts/" + id + "/system_roles/vendor_admin",
        null,
        {
          id: "vendor_admin",
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteVendorAdminRole(id: number | string) {
    try {
      const response: AxiosResponse<{ contact_id: string }> = await restApiService.delete(
        "contacts/" + id + "/system_roles/vendor_admin",
        null,
        {
          id: "vendor_admin",
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateDelegates(id: number, payload: { contact: UpdateDelegatesPayloadType }) {
    try {
      const response: AxiosResponse<{ contact_id: string }> = await restApiService.patch(
        `contacts/${id}/update_delegate_link`,
        null,
        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteDelegate(id: number, payload: { delegate_link_id: number }) {
    try {
      const response: AxiosResponse<{ contact_id: string }> = await restApiService.delete(
        `contacts/${id}/remove_delegate_link`,
        payload,
        null,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async bulkSendWelcome(payload: { contact_ids: number[] }) {
    try {
      const response: AxiosResponse = await restApiService.post("contacts/bulk_send_welcome", null, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default ContactApis;
