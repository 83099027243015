import React, { memo, useState } from "react";
import { ColDef, ColumnApi, GridApi, HeaderValueGetterParams } from "ag-grid-community";
import SelectAll from "components/common/dataGrid/selectAllFunctional";
import { IUser } from "services/common/user/userTypes";
import { translate } from "services/general/translation";
import { setAllowedFilters } from "services/common/gridService";
import _, { sortBy } from "lodash";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import AngularRedirect from "components/common/tabNavigator/angularRedirect";
import { companyDateFormat } from "services/general/dateSvc";
import commonService from "services/common/commonSvc";
import { twoDecimalFormattedAmount } from "services/general/helpers";
import TooltipRender from "components/toolTip/tooltipRender";
import usePermission from "components/common/hooks/usePermission";
import expenseReportSvc from "services/admin/expenses/expenseReport/expenseReportSvc";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "reducers/userReducers";
import EditExpenseReport from "./editExpenseReport";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import { useTranslation } from "react-i18next";
import { CreateNotification, NotificationType } from "services/general/notifications";
import gridService, { FilterType } from "services/grid/gridSvc";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import AssetPreview from "./.././../../common/attachmentPreview/assetsPreview";
import FileUploader from "components/common/fileUploader/fileUploader";
import { AssetType } from "services/common/types/common.type";
import useMessagePanel from "components/admin/messagePanel/useMessagePanel";
import PayExpense from "../components/payExpense";
import { Col, Row } from "react-bootstrap";
import PopoverRender from "components/popovers/popoverRender";
import style from "./expenseReports.module.css";

interface CellRendererParamsType {
  data: ExpenseReportTypes.ListItem;
}

export const FIELD_NAME_MAP = {
  // "purchase_order.number": {
  //   equals: "purchase_order_number",
  //   blank: "purchase_order_number_has_data",
  //   notBlank: "purchase_order_number_has_data"
  // },
  id: { equals: "id" },
  amount: {
    inRange: "",
    greaterThanOrEqual: "amount_greater_than",
    lessThanOrEqual: "amount_less_than",
  },
  purchase_order_list_str: {
    equals: "purchase_order_number",
    blank: "purchase_order_number_has_data",
    notBlank: "purchase_order_number_has_data",
  },
  date: {
    inRange: "",
    greaterThanOrEqual: "date_after",
    lessThanOrEqual: "date_before",
  },
  due_date: {
    inRange: "",
    greaterThanOrEqual: "due_date_after",
    lessThanOrEqual: "due_date_before",
  },
  submit_date: {
    inRange: "",
    greaterThanOrEqual: "submit_date_after",
    lessThanOrEqual: "submit_date_before",
  },
  "employee.name": {
    contains: "employee_name",
  },
  number: {
    contains: "number",
  },
  external_id: {
    contains: "external_id",
    blank: "external_id_has_data",
    notBlank: "external_id_has_data",
  },
  "policy.name": {
    contains: "policy_name",
  },
  "project.name": {
    contains: "project_name",
  },
  "subsidiary.name": {
    contains: "subsidiary_name",
  },
  "currency.iso_code": {
    contains: "currency_code",
  },
  balance_outstanding: {
    greaterThanOrEqual: "balance_outstanding",
  },
};

export const getExpenseReportsListHeaders = ({
  gridApi,
  currentUser,
}: {
  gridApi?: GridApi;
  currentUser: IUser;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs: ColDef[] = [
    {
      headerName: "0Select", // 0 is used to keep at first place even if sort fn is applied on coldefs
      field: "0select",
      filter: false,
      headerCheckboxSelection: true,
      headerValueGetter: function (params: HeaderValueGetterParams) {
        return "Select";
      },
      pinned: true,
      lockPinned: true,
      resizable: false,
      checkboxSelection: true,
      minWidth: 35,
      width: 35,
      maxWidth: 35,
    },
    {
      field: "number",
      headerName: "Number",
      headerValueGetter: () => translate("expenseReportNumber"),
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params: CellRendererParamsType) => (
        <AngularRedirect to={"expense_report/" + params?.data?.id}>{params?.data?.number}</AngularRedirect>
      ),
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      headerValueGetter: () => translate("expenseReportName"),
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params: CellRendererParamsType) => (
        <AngularRedirect to={"expense_report/" + params?.data?.id}>{params?.data?.name}</AngularRedirect>
      ),
      minWidth: 100,
    },
    {
      field: "employee.name",
      headerName: "Employee",
      headerValueGetter: () => translate("employee"),
      sortable: true,
      valueGetter: (params) => {
        return params?.data?.employee && params?.data?.employee?.first_name + " " + params?.data?.employee?.last_name;
      },
      filter: "agTextColumnFilter",
      cellRenderer: (params: CellRendererParamsType) => {
        return (
          <AngularRedirect to={"contacts/" + params?.data?.employee?.id}>
            {params?.data?.employee && params?.data?.employee?.first_name + " " + params?.data?.employee?.last_name}
          </AngularRedirect>
        );
      },
      minWidth: 100,
    },
    {
      field: "date",
      headerName: "Date",
      headerValueGetter: () => translate("date"),
      sortable: true,
      sort: "desc",
      filter: "agDateColumnFilter",
      cellRenderer: (params: CellRendererParamsType) => {
        return <>{companyDateFormat(params?.data?.date, currentUser)}</>;
      },
      minWidth: 100,
    },
    {
      field: "submit_date",
      headerName: "Submit Date",
      headerValueGetter: () => translate("submitDate"),
      sortable: true,
      filter: "agDateColumnFilter",
      cellRenderer: (params: CellRendererParamsType) => {
        return <>{companyDateFormat(params?.data?.submit_date, currentUser)}</>;
      },
      minWidth: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      headerValueGetter: () => translate("amountOnly"),
      sortable: true,
      filter: "agNumberColumnFilter",
      cellRenderer: (params: CellRendererParamsType) =>
        twoDecimalFormattedAmount({
          value: params.data.amount,
          currencyCode: params?.data?.currency?.iso_code,
        }),
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      headerValueGetter: () => translate("status"),
      sortable: true,
      headerClass: "agGridHeaderColor",
      cellRenderer: (params: any) => {
        switch (params.value) {
          case "PENDING":
            return <span>PENDING APPROVAL</span>;
          case "OPEN (PENDING)":
          case "OPEN (SCHEDULED)":
          case "OPEN (APPROVED)":
          case "OPEN":
            return <span>APPROVED</span>;
          default:
            return <span>{params.value}</span>;
        }
      },
      headerComponent: (params: any) => (
        <div draggable className="d-flex align-items-center">
          {translate("status")}
          <PopoverRender
            popoverClassName={"status-tooltip"}
            placement="auto"
            title="Status Definitions"
            content={
              <Row>
                <Col md="12" sm="12" xl="12">
                  {gridService.expenseReportStatusDefinition.map((status) => (
                    <Row key={status.label}>
                      <Col sm="3" className={style.statusTitle}>
                        {status.label}:
                      </Col>
                      <Col sm="9">{status.value}</Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            }
            className="icon icon-info-blue mt-3 mx-1"
          />
        </div>
      ),
      filter: "agTextColumnFilter",
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: expenseReportSvc.expenseReportGridStatuses,
      },
      minWidth: 100,
    },
    {
      field: "has_violations",
      headerName: translate("violations"),
      headerValueGetter: () => translate("violations"),
      headerClass: "agGridHeaderColor",
      cellRenderer: (params: CellRendererParamsType) =>
        _.isArray(params?.data?.violations_messages) && Boolean(params?.data?.violations_messages?.length) ? (
          <>
            <TooltipRender
              title={params?.data?.violations_messages?.join("\n") ?? ""}
              className="icon-20 icon-violation-warning bs-20"
            />
          </>
        ) : null,
      filter: false,
      sortable: false,
      headerComponent: (params: any) => {
        return (
          <div draggable className="d-flex">
            {params.displayName}
            <TooltipRender
              title="Expenses with violations are missing information required by company policy. For additional details on the violation reason, open the Expense Item Details or hover over the Violations icon(s) below."
              className="icon-20 icon-questionmark bs-20"
            />
          </div>
        );
      },
      cellClass: "justify-content-center",
      minWidth: 80,
    },
    {
      field: "next_actionable_approver.name",
      headerName: translate("approverPending"),
      headerValueGetter: () => translate("approverPending"),
      sortable: false,
      filter: false,
      minWidth: 150,
    },
    {
      field: "policy.name",
      headerName: "Policy",
      headerValueGetter: () => translate("policy"),
      filter: "agTextColumnFilter",
      cellRenderer: (params: CellRendererParamsType) => {
        return (
          <AngularRedirect to={"policies/" + params?.data?.policy?.id}>{params?.data?.policy?.name}</AngularRedirect>
        );
      },
      minWidth: 100,
    },
    {
      field: "category",
      headerName: "Category",
      headerValueGetter: () => translate("category"),
      sortable: false,
      filter: false,
      minWidth: 100,
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: function () {
        return translate("ID");
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "external_id",
      headerName: "External ID",
      sortable: true,
      filter: "agTextColumnFilter",
      headerValueGetter: function () {
        return translate("externalId");
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "no_push",
      headerName: "Flagged Not To Push",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params: CellRendererParamsType) => {
        return <>{Boolean(params?.data?.no_push) && "Yes"}</>;
      },
      headerValueGetter: function () {
        return translate("noPush");
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Yes", value: 1 },
          { label: "No", value: 0 },
        ],
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "is_on_hold",
      headerName: "Kept On Hold",
      headerValueGetter: function () {
        return translate("keptOnHold");
      },
      sortable: false,
      filter: false,
      minWidth: 100,
      hide: true,
    },
    {
      field: "version_seq",
      headerName: "Version Seq",
      headerValueGetter: function () {
        return translate("versionSeq");
      },
      sortable: false,
      filter: false,
      minWidth: 100,
      hide: true,
    },
    {
      field: "purchase_order_list_str",
      headerName: "PurchaseOrderNumbers",
      headerValueGetter: () => translate("purchaseOrderNumbers"),
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params: CellRendererParamsType) => {
        return (
          <>
            {_.isArray(params?.data?.purchase_orders) &&
              params?.data?.purchase_orders?.map((po) => (
                <AngularRedirect key={po.id} to={"purchase_orders/" + po.id}>
                  {po.number}
                </AngularRedirect>
              ))}
          </>
        );
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "project_name",
      headerName: "Project",
      headerValueGetter: () => translate("project"),
      filter: "agTextColumnFilter",
      minWidth: 100,
      hide: true,
    },
    {
      field: "due_date",
      headerName: "Due Date",
      headerValueGetter: () => translate("due_date"),
      sortable: true,
      filter: "agDateColumnFilter",
      cellRenderer: (params: CellRendererParamsType) => {
        return <>{companyDateFormat(params?.data?.due_date, currentUser)}</>;
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "final_approval_date",
      headerName: "Final Approval Date",
      headerValueGetter: () => translate("final_approval_date"),
      filter: false,
      sortable: false,
      cellRenderer: (params: CellRendererParamsType) => {
        return <>{companyDateFormat(params?.data?.final_approval_date, currentUser)}</>;
      },
      minWidth: 100,
      hide: true,
    },
    {
      field: "requestor.name",
      headerName: "Requestor",
      headerValueGetter: () => translate("requestor"),
      sortable: false,
      filter: false,
      minWidth: 100,
      hide: true,
    },
    {
      field: "payment_date",
      headerName: "Paid Date",
      headerValueGetter: () => translate("paidDate"),
      sortable: false,
      filter: false,
      cellRenderer: (params: CellRendererParamsType) => companyDateFormat(params?.data?.payment_date, currentUser),
      minWidth: 100,
      hide: true,
    },
    {
      field: "due_status",
      headerName: "Due Status",
      headerValueGetter: () => translate("dueStatus"),
      sortable: false,
      filter: false,
      cellRenderer: (params: CellRendererParamsType) => {
        return (
          <span className={commonService.getDueDateClass(params?.data?.due_status)}>{params?.data?.due_status}</span>
        );
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { value: "DUE TODAY", label: "DUE TODAY" },
          { value: "DUE SOON", label: "DUE SOON" },
          { value: "PAST DUE", label: "PAST DUE" },
          { value: "NEW", label: "NEW" },
          { value: "PAID", label: "PAID" },
          { value: "PAID (PROCESSING)", label: "PAID (PROCESSING)" },
        ],
      },
      minWidth: 80,
      hide: true,
    },
    {
      field: "amount_in_functioal",
      headerName: "Functional Amount",
      headerValueGetter: () => translate("func_amount"),
      filter: false,
      sortable: false,
      cellRenderer: (params: CellRendererParamsType) =>
        twoDecimalFormattedAmount({
          value: params?.data?.amount_in_functional,
          currencyCode: params?.data?.functional_currency?.iso_code,
        }),
      minWidth: 100,
      hide: true,
    },
    {
      field: "payment_list_str",
      headerName: "Payments",
      headerValueGetter: () => translate("payments"),
      filter: false,
      sortable: false,
      minWidth: 80,
      hide: true,
    },
    {
      field: "payments_sum",
      headerName: "Pyaments_sum",
      headerValueGetter: () => translate("paymentTotal"),
      filter: false,
      sortable: false,
      cellRenderer: (params: CellRendererParamsType) =>
        twoDecimalFormattedAmount({
          value: params?.data?.payments_sum,
          currencyCode: params?.data?.currency?.iso_code,
        }),
      minWidth: 100,
      hide: true,
    },
    {
      field: "outstanding_balance_pending",
      headerName: "outstanding_balance_pending",
      headerValueGetter: () => translate("outstandingBalancePending"),
      filter: false,
      sortable: false,
      cellRenderer: (params: CellRendererParamsType) =>
        twoDecimalFormattedAmount({
          value: params?.data?.outstanding_balance_pending,
          currencyCode: params?.data?.currency?.iso_code,
        }),
      minWidth: 200,
      hide: true,
    },
    {
      field: "balance_outstanding",
      headerName: "Outstanding Balance",
      headerValueGetter: () => translate("outstandingBalance"),
      filter: FilterType.AG_NUMBER_COLUMN_FILTER,
      sortable: false,
      cellRenderer: (params: CellRendererParamsType) =>
        twoDecimalFormattedAmount({
          value: params?.data?.balance_outstanding,
          currencyCode: params?.data?.currency?.iso_code,
        }),
      minWidth: 100,
      hide: true,
    },
    {
      field: "purchase_orders_gross_amount",
      headerName: "poAmount",
      headerValueGetter: () => translate("outstandingBalanceAmount"),
      filter: false,
      sortable: false,
      cellRenderer: (params: CellRendererParamsType) =>
        twoDecimalFormattedAmount({
          value: params?.data?.purchase_orders_gross_amount,
          currencyCode: params?.data?.currency?.iso_code,
        }),
      minWidth: 100,
      hide: true,
    },
    {
      field: "subsidiary.name",
      headerName: "Subsidiary",
      headerValueGetter: () => translate("subsidiary"),
      filter: "agTextColumnFilter",
      sortable: true,
      minWidth: 100,
      hide: true,
    },
    {
      field: "purchase_orders_open_balance",
      headerName: "poOpenBal",
      headerValueGetter: () => translate("opOpenBal"),
      cellRenderer: (params: CellRendererParamsType) =>
        twoDecimalFormattedAmount({
          value: params?.data?.purchase_orders_open_balance,
          currencyCode: params?.data?.currency?.iso_code,
        }),
      minWidth: 100,
      filter: false,
      sortable: false,
      hide: true,
    },
    {
      field: "vendor.term.code",
      headerName: "Vendor Terms",
      headerValueGetter: () => translate("vendorTerms"),
      filter: false,
      sortable: false,
      minWidth: 80,
      hide: true,
    },
    {
      field: "term.code",
      headerName: "Invoice Terms",
      headerValueGetter: () => "Invoice Terms",
      minWidth: 80,
      hide: true,
      sortable: false,
      filter: false,
    },
    {
      field: "approval_status",
      headerName: translate("approvalWorkflow"),
      headerValueGetter: () => translate("approvalWorkflow"),
      sortable: false,
      filter: false,
      minWidth: 100,
      hide: true,
    },
    {
      field: "next_actionable_approver.sent_at",
      headerName: translate("approverPendingDate"),
      headerValueGetter: () => translate("approverPendingDate"),
      cellRenderer: (params: CellRendererParamsType) =>
        companyDateFormat(params?.data.next_actionable_approver?.sent_at, currentUser),
      minWidth: 100,
      sortable: false,
      filter: false,
      hide: true,
    },
    {
      field: "description",
      headerName: translate("description"),
      headerValueGetter: () => translate("description"),
      hide: true,
      filter: "agTextColumnFilter",
      minWidth: 100,
    },
    {
      field: "debit_entries_str",
      headerName: translate("debitEntries"),
      headerValueGetter: () => translate("debitEntries"),
      sortable: false,
      filter: false,
      minWidth: 100,
      hide: true,
    },
    {
      field: "business_units_str",
      headerName: translate("businessUnits"),
      headerValueGetter: () => translate("businessUnits"),
      sortable: false,
      filter: false,
      minWidth: 100,
      hide: true,
    },
    {
      field: "external_payment_date",
      headerName: translate("externalPaymentDate"),
      headerValueGetter: () => translate("externalPaymentDate"),
      sortable: false,
      filter: false,
      cellRenderer: (params: CellRendererParamsType) =>
        companyDateFormat(params?.data.external_payment_date, currentUser),
      minWidth: 100,
      hide: true,
    },
    {
      field: "external_payment_memo",
      headerName: translate("externalPaymentMemo"),
      headerValueGetter: () => translate("externalPaymentMemo"),
      hide: true,
      sortable: false,
      filter: false,
      minWidth: 140,
    },
    {
      field: "payment_method.name",
      headerName: translate("paymentMethod"),
      headerValueGetter: () => translate("paymentMethod"),
      sortable: false,
      filter: false,
      minWidth: 100,
      hide: true,
    },
    {
      field: "payment_method.payment_type",
      headerName: translate("paymentType"),
      headerValueGetter: () => translate("paymentType"),
      minWidth: 100,
      filter: false,
      sortable: false,
      hide: true,
    },
    {
      field: "service_start_date",
      headerName: translate("serviceStartDate"),
      headerValueGetter: () => translate("serviceStartDate"),
      sortable: false,
      filter: false,
      cellRenderer: (params: CellRendererParamsType) => companyDateFormat(params?.data.service_start_date, currentUser),
      hide: true,
      minWidth: 100,
    },
    {
      field: "service_end_date",
      headerName: translate("serviceEndDate"),
      headerValueGetter: () => translate("serviceEndDate"),
      sortable: false,
      filter: false,
      cellRenderer: (params: CellRendererParamsType) => companyDateFormat(params?.data.service_end_date, currentUser),
      hide: true,
      minWidth: 100,
    },
    {
      field: "submitter.name",
      headerName: translate("submitter"),
      headerValueGetter: () => translate("submitter"),
      sortable: false,
      filter: false,
      minWidth: 100,
      hide: true,
    },
    {
      field: "currency.iso_code",
      headerName: translate("currencyCode"),
      headerValueGetter: () => translate("currencyCode"),
      sortable: true,
      filter: "agTextColumnFilter",
      minWidth: 100,
      hide: true,
    },
    {
      field: "submitter.submitter_type",
      headerName: translate("submitterType"),
      headerValueGetter: () => translate("submitterType"),
      sortable: false,
      filter: false,
      minWidth: 80,
      hide: true,
    },
    {
      field: "reimbursable",
      headerName: "For Reimbursement?",
      headerComponent: (params: any) => (
        <div draggable className="d-flex align-items-center">
          <div className="ag-header-cell-label" role="presentation">
            <span className="ag-header-cell-text">{translate("reimbursable")}</span>
          </div>
          <PopoverRender
            popoverClassName={"status-tooltip"}
            placement="auto"
            content={
              <Row>
                <Col md="12" sm="12" xl="12">
                  Expenses marked for reimbursement will be paid directly to the requestor - these include any expenses
                  using personal credit cards or cash. Corporate card purchases are not eligible for reimbursement.
                </Col>
              </Row>
            }
            className="icon icon-info-blue mt-3 mx-1"
          />
        </div>
      ),
      cellRenderer: (params: any) => <>{params?.value ? "Yes" : "No"}</>,
      cellClass: "justify-content-center",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ],
      },
      headerValueGetter: function () {
        return translate("reimbursable");
      },
      minWidth: 170,
      hide: true,
    },
    {
      field: "updated_at",
      headerName: translate("lastUpdateDate"),
      headerValueGetter: () => translate("lastUpdateDate"),
      sortable: false,
      filter: false,
      cellRenderer: (params: CellRendererParamsType) => companyDateFormat(params?.data.updated_at, currentUser),
      minWidth: 100,
      hide: true,
    },
    {
      field: "actions",
      headerName: translate("actions"),
      headerValueGetter: () => translate("actions"),
      filter: false,
      cellRenderer: (params: CellRendererParamsType) => gridApi && <Actions data={params.data} gridApi={gridApi} />,
      minWidth: 230,
    },
  ];
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  // while rendering in the grid passes the grid params
  return {
    columnDefs,
    defaultOrder,
  };
};

const Actions = memo(({ data, gridApi }: { data: ExpenseReportTypes.ListItem; gridApi: GridApi }) => {
  const { t } = useTranslation();
  const accessControl = usePermission();
  const currentUser = useSelector(selectCurrentUser);
  const [showEditForm, setShowEditFrom] = useState(false);
  const { createConfirmModal } = useConfirmModal();
  const messagePanel = useMessagePanel();

  const onDeleteConfirm = async () => {
    try {
      if (data.id && gridApi) {
        const result = await ExpenseReportApis.delete(data.id);
        gridService.removeRowFromGrid(gridApi, String(data.id));
        CreateNotification(
          t("success"),
          t("admin.pages.expenseReport.successfulDelete", { number: data.number }),
          NotificationType.success,
        );
      }
    } catch (error) {}
  };

  const createDeleteConfirmation = () => {
    createConfirmModal({
      title: t("confirm"),
      body: t("admin.pages.expenseReport.confirmDeleteMessage", { number: data.number }),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: onDeleteConfirm,
    });
  };

  const uploadReciept = async (files: File[]) => {
    const formData = new FormData();
    formData.append("expense_report[assets_attributes][0][asset_file]", files[0]);
    formData.append("expense_report[assets_attributes][0][file_name]", files[0].name);
    try {
      if (data.id) {
        const result = await ExpenseReportApis.patchAttachaments({
          id: data.id,
          formData: formData,
        });
        gridApi.refreshServerSide();
        CreateNotification("Updated", "Files Attached", NotificationType.success);
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  const getExpenseReportAssets = async (): Promise<AssetType[] | undefined> => {
    try {
      if (data.id) {
        const result = await ExpenseReportApis.getDetail(data.id);
        const erAssets = [];
        if (data.assets) {
          erAssets.push(...data.assets);
        }
        if (result.expense_items) {
          result.expense_items.forEach((expenseItem) => {
            _.isArray(expenseItem.assets) && erAssets.push(...expenseItem.assets);
          });
        }
        return erAssets;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {showEditForm && data.id && (
        <EditExpenseReport gridApi={gridApi} id={data.id} show={showEditForm} setShow={setShowEditFrom} />
      )}

      <AngularRedirect to={"expense_report/" + data.id}>
        <button type="button" className="actionButtons view" data-toggle="tooltip" title="View"></button>
      </AngularRedirect>

      {expenseReportSvc.isAllowToShow({
        expenseReport: data,
        accessControl: accessControl,
        currentUser,
      }) && (
        <button
          type="button"
          className="actionButtons editIcon"
          onClick={() => setShowEditFrom(true)}
          data-toggle="tooltip"
          title="Edit"
        ></button>
      )}
      {data?.assets && <AssetPreview getAssetFiles={() => getExpenseReportAssets()} />}
      {data.id && (
        <button
          className="actionButtons icon-message"
          onClick={() =>
            data.id && messagePanel.openMessagePanel({ messageObjectId: data.id, messageObjectName: "invoices" })
          }
          title="Message"
        ></button>
      )}

      {/* approval */}
      {currentUser.company?.has_payment_workflow &&
        data.is_validate_pay_now &&
        (accessControl.hasUserPermission("payExpenseReports") || accessControl.hasUserPermission("allowPayments")) && (
          <PayExpense title="Submit for Payment Approval" selectedExpenseReport={data} />
        )}

      {!currentUser.company?.has_payment_workflow &&
        data.is_validate_pay_now &&
        (accessControl.hasUserPermission("payExpenseReports") || accessControl.hasUserPermission("allowPayments")) && (
          <PayExpense title="Submit for Payment" selectedExpenseReport={data} />
        )}

      {/* for payment */}
      {accessControl.hasUserPermission("deleteExpenseReports") &&
        data.status !== "PAID" &&
        expenseReportSvc.isAllowToShow({ expenseReport: data, accessControl: accessControl, currentUser }) && (
          <button
            className="actionButtons icon-delete"
            title="Delete"
            onClick={() => createDeleteConfirmation()}
          ></button>
        )}

      {data.has_attachments ? null : (
        <FileUploader
          customButton={
            <button title="Upload Receipt" className="actionButtons upload-receipt-icon-light-blue px-ml-4" />
          }
          showUploadBtn
          uploadAttachments={(files: File[]) => uploadReciept(files)}
          multiple={false}
        />
      )}
    </>
  );
});
