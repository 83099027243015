import Panel from "components/common/panel/panel";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormState, InjectedFormProps, destroy, initialize } from "redux-form";
import NavTabs from "../nav";
import MetadataConfigurationNavbar from "../metadata/metadataConfigurationNavbar";
import styles from "./customLabels.module.css";
import CustomLabelsForm from "components/admin/customLabels/customLabelsUpsert";
import CustomLabelsApi from "services/admin/customLabels/customLabelsApi";
import { useTranslation } from "react-i18next";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { useDispatch } from "react-redux";
import { CustomLabelSvc } from "services/admin/customLabels/customLabelsSvc";
import { RootState, useTypedSelector } from "reducers";

export interface CustomLabel {
  id?: number;
  name?: string;
  modules?: string[];
  field_name: string;
  status?: string;
  required?: boolean;
}

const CustomLabelsPage = ({}: InjectedFormProps<FormData, {}>) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // holds the real response from the first api call. Need this reference when saving to make sure we handle deletes correctly
  const [customLabels, setCustomLabels] = useState<CustomLabel[]>([]);
  // holds the data we are working on
  const [customLabelsAttributes, setCustomLabelsAttributes] = useState<CustomLabel[]>([]);
  const allFormValues = useTypedSelector((state: RootState) => state.form);
  const uniqueFieldNames = new Set<string>();
  const [fieldChoices, setFieldChoices] = React.useState<{ value: string; label: string }[]>(
    CustomLabelSvc.listOfStandardFields,
  );

  // filter out chosen field from list of options
  const handleFieldSelect = (oldVal: string, newVal: string) => {
    switch (newVal) {
      // if clearing field, return it to the list
      case null:
        const clearedField = CustomLabelSvc.listOfStandardFields.find((f) => f.value === oldVal);
        if (clearedField) {
          reinsertFieldChoice(clearedField);
        }
        break;
      default:
        // if choosing another field, return previous choice to the list
        if (oldVal !== newVal) {
          reinsertFieldChoice({ value: oldVal, label: oldVal });
        }
        // then filter out newly chosen field from list
        removeFieldChoice(newVal);
    }
  };

  const getCustomLabels = useCallback(async () => {
    const apiResult = await CustomLabelsApi.index();
    // raw copy to use as reference when we save (to delete objects)
    setCustomLabels(apiResult);

    // sort displayed labels because it looks better
    const sortedLabels = [...apiResult].sort((a, b) => a.field_name!.localeCompare(b.field_name!));

    // set as our working data
    setCustomLabelsAttributes(sortedLabels);

    // filter out existing chosen field options from field choice options
    const selectedFields = sortedLabels.map((label) => label.field_name);
    const filteredFieldChoices = CustomLabelSvc.listOfStandardFields.filter(
      (field) => !selectedFields.includes(field.value),
    );
    setFieldChoices(filteredFieldChoices);
  }, []);

  useEffect(() => {
    getCustomLabels();
  }, [getCustomLabels]);

  // takes a label ID and returns the form values for that label
  const getLabelFormValues = (labelId: number) => {
    return allFormValues[`customLabelsForm_${labelId}`];
  };

  const upsertLabels = async () => {
    setIsLoading(true);
    const labelsToUpsert: CustomLabel[] = [];
    const failedLabelForms: FormState[] = [];

    // Iterate over all labels and save them
    for (const customLabel of customLabelsAttributes) {
      // Get the form values for the current label
      const labelFormValues = getLabelFormValues(customLabel.id!).values;

      // Add the field name to the unique set
      uniqueFieldNames.add(labelFormValues?.field_name);

      // If required fields are not filled out, provide error and do not save
      if (!labelFormValues?.field_name || !labelFormValues?.name || !labelFormValues?.status) {
        const failedLabelForm = allFormValues[`customLabelsForm_${customLabel.id!}`];
        failedLabelForms.push(failedLabelForm);
        switch (failedLabelForm?.values?.field_name) {
          case "":
            CreateNotification(
              t("admin.pages.customLabels.saveErrorHeader"),
              t("admin.pages.customLabels.saveErrorEmptyFieldChoice"),
              NotificationType.danger,
            );
            break;
          default:
            CreateNotification(
              t("admin.pages.customLabels.saveErrorHeaderFor") + `'${failedLabelForm?.values?.field_name}'`,
              t("admin.pages.customLabels.saveErrorEmptyCustomLabel") + `'${failedLabelForm?.values?.field_name}'`,
              NotificationType.danger,
            );
        }
        continue;
      }

      let newLabel: CustomLabel = JSON.parse(JSON.stringify(labelFormValues));
      // see if working label exists in 'real' backend data
      const existingLabel = customLabels.find((label: CustomLabel) => label.field_name === newLabel.field_name);

      if (newLabel.id! > 0 || !existingLabel) {
        labelsToUpsert.push(newLabel);
        reinsertFieldChoice({ value: newLabel.field_name, label: newLabel.field_name });
      } else {
        labelsToUpsert.push(existingLabel);
        reinsertFieldChoice({ value: existingLabel.field_name, label: existingLabel.field_name });
      }
    }

    // bulk upsert all collected labels
    try {
      await CustomLabelsApi.bulkUpsert(labelsToUpsert);
      // if labels saved successfully, create success notification and refresh data
      CreateNotification(
        t("admin.pages.customLabels.saveLabelsHeader"),
        t("admin.pages.customLabels.saveLabelsBody"),
        NotificationType.success,
      );
      getCustomLabels();
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const addLabel = () => {
    // real id will be created by backend upon save. -(id) is for unique differentaion
    // of our working data as to not interfere/collide with existing label ids
    const uniqueId = -(customLabelsAttributes.length + 1);
    let newLabel = { name: "", id: uniqueId, modules: ["All"], field_name: "", status: "ACTIVE", required: false };
    dispatch(initialize(`customLabelsForm_${uniqueId}`, {}));
    setCustomLabelsAttributes([...customLabelsAttributes, newLabel]);
  };

  const labelDeleted = async (id: number) => {
    // Find if desired label to delete exists
    const existingLabelAttributes = customLabelsAttributes.find((label: CustomLabel) => label.id === id);
    const existingLabel = customLabels.find((label: CustomLabel) => label.id === id);

    // reinsert deleted option into field choice options list
    const deletedField = CustomLabelSvc.listOfStandardFields.find(
      (f) => f.value === existingLabelAttributes?.field_name,
    );
    if (deletedField) {
      reinsertFieldChoice(deletedField);
    }

    // if label exists in backend && our current frontend working data, make API call to destroy from backend as well
    if (existingLabelAttributes && existingLabel) {
      await CustomLabelsApi.delete(existingLabel || {}).then(async (response) => {
        setCustomLabelsAttributes(customLabelsAttributes.filter((label: CustomLabel) => label.id !== id));
        CreateNotification("Label Deleted", "Label deleted successfully", NotificationType.success);
        dispatch(destroy(`customLabelsForm_${id}`));
        // update our refrerence of existing backend data w/o removing rendering of any floating (unsaved) labels
        const apiResult = await CustomLabelsApi.index();
        setCustomLabels(apiResult);
      });
      // elsif label only exists in frontend working data, no need to make backend API call
    } else if (existingLabelAttributes) {
      setCustomLabelsAttributes(customLabelsAttributes.filter((label: CustomLabel) => label.id !== id));
      CreateNotification("Label Deleted", "Label deleted successfully", NotificationType.success);
      dispatch(destroy(`customLabelsForm_${id}`));
      // safety net: if label is undefined simply remove from frontend working data
    } else {
      setCustomLabelsAttributes(customLabelsAttributes.filter((label: CustomLabel) => label.id !== undefined));
      dispatch(destroy(`customLabelsForm_${id}`));
    }
  };

  const removeFieldChoice = (fieldChoice: string) => {
    setFieldChoices((prevChoices) => prevChoices?.filter((choice) => choice.value !== fieldChoice));
  };

  const reinsertFieldChoice = (fieldChoice: { value: string; label: string }) => {
    setFieldChoices((prevChoices) => {
      const updatedChoices = [...prevChoices, fieldChoice];
      // make sure list is sorted for consistency
      return updatedChoices.sort((a, b) => {
        if (!a.label) return 1;
        if (!b.label) return -1;
        return a.label.localeCompare(b.label);
      });
    });
  };

  return (
    <Container fluid={true} style={{ paddingBottom: "4rem" }}>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"Form Configuration"} />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Panel>
        <MetadataConfigurationNavbar />
        <p className="mb-4">{t("admin.pages.customLabels.pageInfo")}</p>
        <hr></hr>

        <div className={"d-flex"} style={{ paddingBottom: "10px" }}>
          <hr></hr>
          <div className="flex-grow-1">
            <div className="flex-grow-1">
              {customLabelsAttributes &&
                customLabelsAttributes.map((label: CustomLabel) => {
                  return (
                    <CustomLabelsForm
                      key={`${label.id}`}
                      form={`customLabelsForm_${label.id}`}
                      initialValues={label}
                      deleteCallback={labelDeleted}
                      fieldChoices={fieldChoices}
                      onFieldSelect={handleFieldSelect}
                    ></CustomLabelsForm>
                  );
                })}
            </div>
            <button type="button" onClick={addLabel} className={`btn btn-primary mt-2 ${styles.btnStyle}`}>
              + {t("addLabel")}
            </button>
            {!isLoading ? (
              <button type="button" onClick={upsertLabels} className={`btn btn-primary mt-2 ml-3 ${styles.btnStyle}`}>
                {t("save")}
              </button>
            ) : (
              <button type="button" className={`btn btn-primary mt-2 ml-3 ${styles.btnStyle}`} disabled>
                {t("save")}
              </button>
            )}
          </div>
        </div>
      </Panel>
    </Container>
  );
};

export default CustomLabelsPage;
