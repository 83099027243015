import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Field } from "redux-form";
import AddChangeOrder from "./addForm";
import EditChangeOrder from "./editForm";
import { RenderDatePicker, RenderTextArea, ComparisonField } from "../../../forms/bootstrapFields";
import {
  afterDate,
  beforeDate,
  noWhiteSpaceOnly,
  required,
} from "../../../../services/validations/reduxFormValidation";
import { useTypedSelector } from "../../../../reducers";
import { ChangeOrdersPOFormService } from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrdersForm";
import { currencySymbolRenderer } from "../../../../services/common/currencySymbolRendererService";
import { MainFieldsPropsType } from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrders.types";
import changeOrderSvc from "../../../../services/admin/changeOrders/changeOrderSvc";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import AttachmentSection from "components/common/attachmentSection/attachmentSection";
import { shallowEqual, useDispatch } from "react-redux";
import { restApiService } from "services/admin/subsidiaries/subsidiaryUtils";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { isArray } from "lodash";
import { FileUploaderNonModal } from "components/common/fileUploader/fileUploaderNonModal";

export const MainFieldsForm = ({ mode }: MainFieldsPropsType) => {
  const dispatch = useDispatch();
  const purchaseOrdersFormRef = useRef(new ChangeOrdersPOFormService(mode, dispatch));
  const currentChangeOrderId = useTypedSelector((state) => purchaseOrdersFormRef.current.getCurrentCOId(state));
  const file_uris = useTypedSelector((state) => purchaseOrdersFormRef.current.getFileUris(state), shallowEqual);
  const assets = useTypedSelector((state) => purchaseOrdersFormRef.current.getAssets(state), shallowEqual);
  const poItemsTotal = useTypedSelector((state) => purchaseOrdersFormRef.current.getPOItemsTotal(state));
  const expensesTotal = useTypedSelector((state) => purchaseOrdersFormRef.current.getExpensesTotal(state));
  const currencyCode = useTypedSelector((state) => purchaseOrdersFormRef.current.getCurrencyCode(state));
  const start_date = useTypedSelector((state) => purchaseOrdersFormRef.current.getStartDate(state));
  const end_date = useTypedSelector((state) => purchaseOrdersFormRef.current.getEndDate(state));
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const startDateRef = useRef<Date>();
  const endDateRef = useRef<Date>();
  const status = useTypedSelector((state) => purchaseOrdersFormRef.current.getStatus(state));

  const beforeDateValidation = useMemo(() => beforeDate(endDateRef), []);
  const afterDateValidation = useMemo(() => afterDate(startDateRef), []);

  const updateLocalFiles = useCallback(async (file_attributes: { uri: string; name: string }[]) => {
    purchaseOrdersFormRef.current.updateFileUris(file_attributes);
  }, []);

  const handleUploadAttachment = useCallback(
    async (files: File[]) => {
      try {
        const formData = new FormData();
        if (isArray(files)) {
          files.forEach((file, index) => {
            formData.append(`change_request[assets_attributes][${index}][asset_file]`, file);
            formData.append(`change_request[assets_attributes][${index}][file_name]`, file.name);
          });
        }
        const res = await restApiService.patch(`change_requests/${currentChangeOrderId}`, null, formData);
        if (res.data) {
          purchaseOrdersFormRef.current.updateAssets(res.data.assets_attributes);
          CreateNotification("Attached", "Attachment Updated", NotificationType.success);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [currentChangeOrderId],
  );

  const deleteExistingAttachment = useCallback(
    async (assetId: number | string) => {
      const res = await restApiService.patch(`change_requests/${currentChangeOrderId}`, null, {
        change_request: { assets_attributes: { id: assetId, _destroy: 1 } },
      });
      if (res && res.data) {
        purchaseOrdersFormRef.current.updateAssets(res.data.assets_attributes);
        CreateNotification("Deleted Attachment", "", NotificationType.info);
      } else {
        CreateNotification("Error Deleting Attachment", "", NotificationType.danger);
      }
    },
    [currentChangeOrderId],
  );

  useEffect(() => {
    setCurrencySymbol(currencySymbolRenderer(currencyCode));
  }, [currencyCode]);

  useEffect(() => {
    if (start_date) {
      startDateRef.current = start_date;
    }
  }, [start_date]);

  useEffect(() => {
    if (end_date) {
      endDateRef.current = end_date;
    }
  }, [end_date]);

  return (
    <>
      <Row>
        <Col>
          <Field
            name="number"
            label="Change Order Number"
            labelClassName="font-weight-bold"
            placeholder="NEW"
            component={ComparisonField}
            disabled={true}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label className="font-weight-bold">Total Amount</Form.Label>
            <Form.Control
              as="input"
              type="text"
              disabled
              value={`${currencySymbol} ${(poItemsTotal + expensesTotal).toFixed(2)}`}
              style={{ backgroundColor: "transparent" }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name="object_changes.start_date"
            label="Start Date"
            labelClassName="font-weight-bold"
            component={RenderDatePicker}
            validate={[beforeDateValidation]}
            valueRef={startDateRef}
            disabled={Boolean(changeOrderSvc.disableEdits(status))}
          />
        </Col>
        <Col>
          <Field
            name="object_changes.end_date"
            label="End Date"
            labelClassName="font-weight-bold"
            component={RenderDatePicker}
            validate={[afterDateValidation]}
            valueRef={endDateRef}
            disabled={Boolean(changeOrderSvc.disableEdits(status))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name="object_changes.description"
            label="Description"
            placeholder="Type your description here..."
            labelClassName="font-weight-bold"
            required={true}
            component={RenderTextArea}
            maxLength={100}
            rows={3}
            validate={[required, noWhiteSpaceOnly]}
            disabled={Boolean(changeOrderSvc.disableEdits(status))}
          />
        </Col>
      </Row>
      <ErrorBoundary>
        {mode === "create" ? (
          <FileUploaderNonModal files={file_uris} onFilesUpdated={updateLocalFiles} />
        ) : (
          <AttachmentSection
            title={
              <>
                <i className="icon m-0 document" />
                {"documents".toUpperCase()}
              </>
            }
            attachments={assets}
            onAttachmentUpload={handleUploadAttachment}
            onAttachmentDelete={deleteExistingAttachment}
            allowDelete
            allowAdd
          />
        )}
      </ErrorBoundary>
    </>
  );
};

export const EditMainFieldsForm = () => {
  return (
    <EditChangeOrder>
      <MainFieldsForm mode="edit" />
    </EditChangeOrder>
  );
};

export const AddMainFieldsForm = () => {
  return (
    <AddChangeOrder>
      <MainFieldsForm mode="create" />
    </AddChangeOrder>
  );
};
