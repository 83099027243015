import React, { ChangeEventHandler, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_ACCOUNT_NAME } from "../../../../actions/actionTypes";
import { Field } from "redux-form";
import { PaymentFieldType } from "../types";
import { RenderField } from "../../../forms/bootstrapFields";

type AccountNamePropsType = PaymentFieldType & { tooltip?: ReactNode };

const AccountName = ({ validations, required, label, tooltip }: AccountNamePropsType) => {
  const dispatch = useDispatch();

  const changeAccountName: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({ type: CHANGE_ACCOUNT_NAME, payload: e.target.value });
  };

  return (
    <Field
      name="account_name"
      label={label}
      component={RenderField}
      required={required}
      tooltip={tooltip}
      validate={validations}
      onChange={changeAccountName}
    />
  );
};

export default AccountName;
