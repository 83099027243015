import { Form } from "react-bootstrap";
import Select from "react-select";
import React, { useCallback, useEffect, useRef, useState } from "react";
import restApi from "../../../../providers/restApi";
import { isArray, isPlainObject } from "lodash";
import Styles from "../override.module.css";
import {
  compare,
  onChange,
  onChangeSingle as onChangeSingleHelper,
  findSelectedMultiple,
} from "../../../../services/general/helpers";

import { Mandatory } from "../../../forms/bootstrapFields";
import { CreateNotification } from "../../../../services/general/notifications";

const restApiService = new restApi();

// help us to make filter where user, vendor, subsider contact required to fetch
export const getContactParams = (contactType) => {
  switch (contactType) {
    case "USERS":
      return { contact_type: "COMPANY", by_active: true };
    default:
      return {};
  }
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */
const ContactPicker = (props) => {
  let {
    className,
    label,
    input,
    meta: { touched, error, warning },
    contactType,
    isMulti,
    required,
    menuPosition,
    toBlur, //if true, set onBlur event handler which determines isError state.
    inputId,
    isClearable = false,
    useObject = false, // <- specify we want to use object as value instead id,
    callBack,
  } = props;

  const [contacts, setContacts] = useState([]);
  const mounted = useRef(false);

  const getAccounts = useCallback(async () => {
    try {
      const result = await restApiService.get("contacts.lk", getContactParams(contactType), null, true, null, true);
      const value = parseForSelect(result.data);
      if (mounted.current) setContacts(value);
    } catch (error) {
      console.log(error);
    }
  }, [contactType]);

  const parseForSelect = (contacts) => {
    return contacts
      .map((contact) => {
        return {
          value: contact.id,
          label: contact.name,
          ...contact,
        };
      })
      .sort(compare);
  };

  const findSelected = () => {
    let selectedValues = [];

    if (!input.value) {
      return { value: "", label: "search and select" };
    }
    if (typeof (input.value === String)) {
      selectedValues = contacts.find((contact) => input.value === contact.value);
    }

    if (isPlainObject(input.value)) {
      selectedValues = [input.value];
    }

    if (isArray(input.value)) {
      selectedValues = input.value;
    }
    return selectedValues;
  };

  useEffect(() => {
    getAccounts();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [getAccounts]);

  const onBlur = (option) => {
    if (!option) {
      input.onBlur(null);
      return;
    }
    input.onBlur(option);
  };
  const onChangeMulti = (input, newValue) => {
    if (input.value) {
      const previousValue = input.value.map((item) => item);
      const removedItem = previousValue.filter((prevId) => !newValue.some((newItem) => newItem.value === prevId));
      if (callBack) {
        callBack(removedItem[0]);
      }
    }
    if (!useObject) {
      onChange(input, newValue);
    } else {
      input.onChange(newValue);
    }
  };

  const onChangeSingle = (value) => {
    if (!useObject) {
      onChangeSingleHelper(input, value);
    } else {
      input.onChange(value);
    }
  };

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
        </Form.Label>
      )}
      <Select
        {...input}
        required={required ? true : false}
        value={isMulti ? findSelectedMultiple(input, contacts) : findSelected()}
        placeholder="search and select"
        onChange={isMulti ? (value) => onChangeMulti(input, value) : (value) => onChangeSingle(value)}
        onBlur={toBlur ? () => onBlur(input.value) : null}
        // if toBlur prop exists, set onBlur event handler
        options={contacts}
        isMulti={isMulti ? true : false}
        menuPosition={menuPosition ? menuPosition : null}
        inputId={inputId ? inputId : "contact-selector"}
        isClearable={isClearable}
      />
      {error && touched && <div style={{ fontSize: "80%", color: "red", padding: "2px" }}>{error}</div>}
    </Form.Group>
  );
};

export default ContactPicker;
