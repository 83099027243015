import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ApprovalsNavigationBar from "../../../approvalNavigationBar/approvalsNavigationBar";
import NavTabs from "../nav";
import { useLocation } from "react-router";
import GridFilterDropdown from "../../../../datagrid/gridFilterDropdown";
import ToggleFilterButton from "../../../../datagrid/buttons/toggleFilterButton";
import styles from "./../../purchaseOrders.module.css";
import { GridReadyEvent } from "ag-grid-community";
import ClientDataGrid from "../../../../common/dataGrid/clientDataGrid/clientDataGrid";
import useShowFilterState from "../../../../common/hooks/useShowFilterState";
import ApprovalsApis from "../../../../../services/admin/approvals/approvalsApis";
import useIsMounted from "../../../../common/hooks/useIsMounted";
import { PoChangeOrderType } from "../../../../../services/admin/changeOrders/poChangeOrderApprovalsType";
import { getPoChangeOrderApprovalHeaders } from "./listApprovalCOGridHeaders";

const ListApprovalChangeOrders = () => {
  const location = useLocation();
  const isMounted = useIsMounted();
  const queryParams = new URLSearchParams(location.search);
  // TODO: implement filter by filter id
  const filterID = queryParams.get("filterID");
  const gridStorageName = "coApprovals";
  const [gridApi, setGridApi] = useState<GridReadyEvent["api"]>();
  const [gridColumnApi, setGridColumnApi] = useState<GridReadyEvent["columnApi"]>();
  const { showFilters, updateShowFilters } = useShowFilterState("showCoApprovalFitlers");
  const [poChangeOrderApprovalList, setPoChangeOrderApprovalList] = useState<PoChangeOrderType.ApprovalListType[]>([]);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getPoChangeOrderApprovalList = async () => {
    try {
      const result = await ApprovalsApis.getPoChangeOrderApprovalList();
      if (isMounted.current) {
        setPoChangeOrderApprovalList(result);
      }
    } catch (error) {}
  };

  const poChangeOrderApprovalGridHeaders = useMemo(
    () => getPoChangeOrderApprovalHeaders({ getPoChangeOrderApprovalList }),
    [],
  );

  useEffect(() => {
    getPoChangeOrderApprovalList();
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <ApprovalsNavigationBar />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <NavTabs activePageName="Change Orders" />
        </Col>
      </Row>
      <Row>
        <Col>
          <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} options={{}} />
          <ToggleFilterButton
            classes="float-right"
            clickCallback={() => {
              updateShowFilters(!showFilters);
              gridApi?.refreshHeader();
            }}
          />
        </Col>
      </Row>

      <Row className={styles.gridHeight + " mt-3 px-3"}>
        <ClientDataGrid
          columnDefs={poChangeOrderApprovalGridHeaders}
          gridReady={gridReady}
          rowData={poChangeOrderApprovalList}
          defaultColDef={{ floatingFilter: showFilters }}
          gridStorageName={gridStorageName}
        />
      </Row>
    </Container>
  );
};

export default ListApprovalChangeOrders;
