import { PdfRenderingProvider } from "components/admin/invoices/uploadQueue/pdfRenderingContext";
import { QueueManagementProvider } from "components/admin/invoices/uploadQueue/queueManagementContext";
import { UiStateProvider } from "components/admin/invoices/uploadQueue/uiStateContext";
import UploadQueueManager from "components/admin/invoices/uploadQueue/uploadQueueManager";
import React from "react";

// An “Upload Queue” refers to a list or sequence of invoices waiting to be processed.
// When you upload pdf, they are placed in queue and processed one after the other.
const AddUploadQueueProcess = () => {
  return (
    <QueueManagementProvider>
      <PdfRenderingProvider>
        <UiStateProvider>
          <UploadQueueManager />
        </UiStateProvider>
      </PdfRenderingProvider>
    </QueueManagementProvider>
  );
};

export default AddUploadQueueProcess;
