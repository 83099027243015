import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { minLength, required } from "../../../../../services/validations/reduxFormValidation";
import { AccountNumberValidator } from "../../accountNumberValidator";
import BicValidator from "../../bicValidator";
import { PaymentMethodValidatorSvc } from "../../paymentMethodValidatorSvc";
import PurposeCode from "./purposeCode";
import IbanValidator from "../../ibanValidator";
import { IDType } from "../../../../../services/common/types/common.type";

const min4 = minLength(4);

const WireBrl = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState("");
  const [ibanNumber, setIbanNumber] = useState<IDType>();

  const validateBranchCode = async (value: number | string) => {
    try {
      const data = await PaymentMethodValidatorSvc.ValidateBranchCode(value);
      if (data?.validation) {
        setValidationMessage(data.validation);
      } else {
        setValidationMessage(data?.errors[0]?.message);
      }
    } catch (error) {
      throw error;
    }
  };

  const validateCpfNumber = async (value: number | string) => {
    try {
      const data = await PaymentMethodValidatorSvc.ValidateBrazilCpf(value);
      if (data?.validation) {
        setValidationMessage(data.validation);
      } else {
        setValidationMessage(data?.errors[0]?.message);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.branchCode`}
          component={RenderField}
          type="text"
          required
          maxLength={6}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => validateBranchCode(e.target.value)}
          validate={[required, min4]}
          label={t("common.paymentMethod.branchCode")}
        />
      </Col>
      <Col md="4">
        <IbanValidator modelName={`${modelName}`} callBack={(value) => setIbanNumber(value)} />
      </Col>
      <Col md="4">
        <BicValidator modelName={`${modelName}`} ibanNumber={ibanNumber} />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.cpf`}
          component={RenderField}
          type="text"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => validateCpfNumber(e.target.value)}
          label={t("common.paymentMethod.taxRegistrationNumber/tin")}
          className={`w-100`}
          required
          validate={[required]}
        />
        {validationMessage && (
          <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
        )}
      </Col>
      <Col md="12">
        <PurposeCode isPurposeOfPayment={true} />
      </Col>
    </>
  );
};

export default WireBrl;
