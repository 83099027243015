import {
  ChangeOrderOptionsType,
  POItemOptionsType,
  ExpenseOptionsType,
  ChangeOrderPOFieldServiceType,
  ChangeOrderPOServicesFactory,
  ChangeOrderObjectOptionsType,
} from "./purchaseOrders.types";
import { AppDispatch, RootState } from "../../../../reducers";
import { change, FieldArrayFieldsProps, getFormValues } from "redux-form";
import { cloneDeep, isEqual, orderBy, uniqueId } from "lodash";
import { number as numberValidation } from "../../../../services/validations/reduxFormValidation";
import { AssetType } from "services/common/types/common.type";

export class ChangeOrderExpenseService {
  formName: string;
  dispatch: AppDispatch;
  fields: FieldArrayFieldsProps<ExpenseOptionsType>;
  grandTotal: number = 0;
  currencySymbol: string = "";
  static FIELD_NAMES: Array<string> = [
    "id",
    "memo",
    "account_id",
    "amount",
    "location_id",
    "budget_id",
    "category_id",
    "secondary_category_id",
    "grant_id",
    "sponsorship_id",
    "for_subsidiary_id",
    "project_id",
    "received_amount",
    "billed_amount",
    "_destroy",
  ];

  constructor(formName: string, dispatch: AppDispatch) {
    this.formName = formName;
    this.dispatch = dispatch;
  }

  duplicateField = (itemState: ExpenseOptionsType): number => {
    let filteredState: { [key: string]: any } = {};
    ChangeOrderExpenseService.FIELD_NAMES.forEach((key) => {
      filteredState[key] = itemState[key];
    });
    let dupState = cloneDeep(filteredState) as ExpenseOptionsType;
    dupState.id = null;
    dupState.fieldId = uniqueId();
    dupState.billed_amount = 0;
    dupState.received_amount = 0;
    this.fields.push(dupState);
    return this.fields.length ? this.fields.length : 0;
  };

  handleAddField = (): number => {
    this.fields.push({
      id: null,
      memo: "",
      account_id: "",
      amount: "",
      location_id: "",
      budget_id: "",
      category_id: "",
      secondary_category_id: "",
      grant_id: "",
      sponsorship_id: "",
      for_subsidiary_id: "",
      project_id: "",
      fieldId: uniqueId(),
      _destroy: false,
    });
    return this.fields.length ? this.fields.length : 0;
  };

  handleDeleteField = (index: number) => {
    this.fields.remove(index);
  };

  updateDestroyField = (value: boolean, index: number): void => {
    this.dispatch(change(this.formName, `object_changes.invoice_debit_accounts_attributes[${index}]._destroy`, value));
  };

  setGrandTotal = (value: number) => {
    this.grandTotal = value;
  };

  setCurrencySymbol = (value: string) => {
    this.currencySymbol = value;
  };

  getFieldId = (index: number): string => {
    const { id, fieldId } = this.fields.get(index);
    return id ? id.toString() : fieldId ? fieldId : uniqueId();
  };
}

export class ChangeOrderPOItemService {
  formName: string;
  dispatch: AppDispatch;
  fields: FieldArrayFieldsProps<POItemOptionsType>;
  grandTotal: number = 0;
  currencySymbol: string = "";
  static FIELD_NAMES: Array<string> = [
    "id",
    "description",
    "memo",
    "amount",
    "qty",
    "unit_price",
    "tax",
    "total",
    "tax_id",
    "department_id",
    "location_id",
    "secondary_category_id",
    "grant_id",
    "project_id",
    "budget_id",
    "for_subsidiary_id",
    "product_item_id",
    "billed_item_qty",
    "receipt_item_qty",
    "_destroy",
  ];

  constructor(formName: string, dispatch: AppDispatch) {
    this.formName = formName;
    this.dispatch = dispatch;
  }

  recalculateItem = (itemState: POItemOptionsType, itemTaxRate: number, index: number) => {
    const unitPrice =
      itemState?.unit_price && !numberValidation(itemState.unit_price) ? Number(itemState.unit_price) : 0;
    const unitQuantity = itemState?.qty && !numberValidation(itemState.qty) ? Number(itemState.qty) : 0;
    const baseItemTotal = unitPrice * unitQuantity;
    const baseItemTax = itemTaxRate !== null ? (baseItemTotal * itemTaxRate) / 100.0 : 0;
    const newItemTotal = Math.round((baseItemTotal + baseItemTax) * 100) / 100;
    let newItemState = cloneDeep(itemState);
    if (newItemTotal >= 0) {
      newItemState.amount = baseItemTotal.toFixed(2);
      newItemState.total = newItemTotal.toFixed(2);
      newItemState.tax = baseItemTax.toFixed(2);
      this.updateItemState(newItemState, index);
    }
  };

  duplicateField = (itemState: POItemOptionsType): number => {
    let filteredState: { [key: string]: any } = {};
    ChangeOrderPOItemService.FIELD_NAMES.forEach((key) => {
      filteredState[key] = itemState[key];
    });
    let dupState = cloneDeep(filteredState) as POItemOptionsType;
    dupState.total = "";
    dupState.id = null;
    dupState.fieldId = uniqueId();
    dupState.qty = "1";
    dupState.billed_item_qty = 0;
    dupState.receipt_item_qty = 0;
    this.fields.push(dupState);
    return this.fields.length ? this.fields.length : 0;
  };

  handleAddField = (): number => {
    this.fields.push({
      id: null,
      description: "",
      memo: "",
      qty: "",
      unit_price: "",
      amount: "",
      tax: "",
      total: "",
      tax_id: "",
      department_id: "",
      location_id: "",
      secondary_category_id: "",
      grant_id: "",
      project_id: "",
      budget_id: "",
      for_subsidiary_id: "",
      product_item_id: "",
      fieldId: uniqueId(),
      _destroy: false,
    });
    return this.fields.length ? this.fields.length : 0;
  };

  handleDeleteField = (index: number) => {
    this.fields.remove(index);
  };

  updateDestroyField = (value: boolean, index: number): void => {
    this.dispatch(change(this.formName, `object_changes.po_items_attributes[${index}]._destroy`, value));
  };

  setGrandTotal = (value: number) => {
    this.grandTotal = value;
  };

  setCurrencySymbol = (value: string) => {
    this.currencySymbol = value;
  };

  updateItemState = (value: POItemOptionsType, index: number) => {
    this.dispatch(change(this.formName, `object_changes.po_items_attributes[${index}]`, value));
  };

  updateUnitPrice = (value: string, index: number): void => {
    this.dispatch(change(this.formName, `object_changes.po_items_attributes[${index}].unit_price`, value));
  };

  resetCalculation = (index: number): void => {
    this.dispatch(change(this.formName, `object_changes.po_items_attributes[${index}].unit_price`, ""));
    this.dispatch(change(this.formName, `object_changes.po_items_attributes[${index}].qty`, ""));
    this.dispatch(change(this.formName, `object_changes.po_items_attributes[${index}].tax`, ""));
    this.dispatch(change(this.formName, `object_changes.po_items_attributes[${index}].total`, ""));
  };

  getFieldId = (index: number): string => {
    const { id, fieldId } = this.fields.get(index);
    return id ? id.toString() : fieldId ? fieldId : uniqueId();
  };
}

export class ChangeOrdersPOFormService<FieldServiceType extends ChangeOrderPOFieldServiceType> {
  formName: string;
  fieldService: FieldServiceType;
  dispatch: AppDispatch;

  constructor(
    formType: string,
    dispatch?: AppDispatch,
    fieldServiceType?: ChangeOrderPOServicesFactory<FieldServiceType>,
  ) {
    if (formType === "edit") {
      this.formName = ChangeOrdersPOFormService.EDIT_CHANGE_ORDER_PO_FORM_NAME;
    } else if (formType === "create") {
      this.formName = ChangeOrdersPOFormService.CREATE_CHANGE_ORDER_PO_FORM_NAME;
    }
    if (dispatch && fieldServiceType) {
      this.fieldService = new fieldServiceType(this.formName, dispatch);
    } else if (dispatch) {
      this.dispatch = dispatch;
    }
  }

  static EDIT_CHANGE_ORDER_PO_FORM_NAME: string = "editChangeOrderPOForm";
  static CREATE_CHANGE_ORDER_PO_FORM_NAME: string = "createChangeOrderPOForm";
  static OBJECT_CHANGES_NO_COMPARE_FIELDS: { [key: string]: true } = {
    currency_code: true,
    subsidiary_id: true,
  };
  static OBJECT_CHANGES_BASE_FIELDS: Array<string> = [
    "id",
    "number",
    "start_date",
    "end_date",
    "description",
    "currency_code",
    "subsidiary_id",
  ];

  static prepareObjectChanges = (objectChanges: ChangeOrderObjectOptionsType) => {
    objectChanges.po_items_attributes.forEach((item: POItemOptionsType) => {
      item.fieldId = item.id ? item.id.toString() : uniqueId();
    });
    objectChanges.invoice_debit_accounts_attributes.forEach((item: ExpenseOptionsType) => {
      item.fieldId = item.id ? item.id.toString() : uniqueId();
    });
  };

  static filterPayload = (value: ChangeOrderObjectOptionsType) => {
    value.po_items_attributes &&
      value.po_items_attributes.forEach((po_item) => {
        delete po_item.billed_item_qty;
        delete po_item.receipt_item_qty;
        delete po_item.fieldId;
      });
    value.invoice_debit_accounts_attributes &&
      value.invoice_debit_accounts_attributes.forEach((link) => {
        delete link.billed_amount;
        delete link.received_amount;
        delete link.fieldId;
      });
  };

  static processComparisonData = (
    oldValues: ChangeOrderObjectOptionsType,
    newValues: ChangeOrderObjectOptionsType | { [key: string]: any },
  ) => {
    const originalPOItems: POItemOptionsType[] = oldValues.po_items_attributes ? oldValues.po_items_attributes : [];
    const originalExpenses: ExpenseOptionsType[] = oldValues.invoice_debit_accounts_attributes
      ? oldValues.invoice_debit_accounts_attributes
      : [];
    const newPOItems: POItemOptionsType[] = newValues.po_items_attributes ? newValues.po_items_attributes : [];
    const newExpenses: ExpenseOptionsType[] = newValues.invoice_debit_accounts_attributes
      ? newValues.invoice_debit_accounts_attributes
      : [];
    let filtered: ChangeOrderObjectOptionsType | { [key: string]: any } = this.assignBaseComparisonFields(
      newValues,
      oldValues,
    );
    filtered.po_items_attributes = [];
    filtered.invoice_debit_accounts_attributes = [];
    this.mergePOItemChanges(newPOItems, originalPOItems, filtered);
    this.mergeExpenseChanges(newExpenses, originalExpenses, filtered);
    return filtered as ChangeOrderObjectOptionsType;
  };

  static assignBaseComparisonFields = (
    newValues: ChangeOrderObjectOptionsType | { [key: string]: any },
    oldValues: ChangeOrderObjectOptionsType | { [key: string]: any },
    noCompare: boolean = false,
  ) => {
    let filterObj: POItemOptionsType | { [key: string]: any } = {};
    ChangeOrdersPOFormService.OBJECT_CHANGES_BASE_FIELDS.forEach((key: string) => {
      if (ChangeOrdersPOFormService.OBJECT_CHANGES_NO_COMPARE_FIELDS[key]) {
        filterObj[`${key}`] = oldValues[key];
        return;
      }
      filterObj[key] = newValues[key];
      if (
        noCompare ||
        newValues[key] === undefined ||
        (!newValues[key] && !oldValues[key]) ||
        newValues[key] == oldValues[key]
      ) {
        return;
      }
      filterObj[`${key}_original`] = oldValues[key];
    });
    return filterObj as ChangeOrderObjectOptionsType;
  };

  static assignPOItemComparisonFields = (
    newValues: POItemOptionsType,
    oldValues: POItemOptionsType | { [key: string]: any },
    noCompare: boolean = false,
  ) => {
    let filterObj: POItemOptionsType | { [key: string]: any } = {};
    ChangeOrderPOItemService.FIELD_NAMES.forEach((key: string) => {
      filterObj[key] = newValues.hasOwnProperty(key) ? newValues[key] : oldValues[key];
      if (noCompare) {
        return true;
      }
      if (newValues[key] === undefined || (!newValues[key] && !oldValues[key]) || newValues[key] == oldValues[key]) {
        return false;
      }
      filterObj[`${key}_original`] = oldValues[key];
    });
    return filterObj as POItemOptionsType;
  };

  static assignExpenseComparisonFields = (
    newValues: ExpenseOptionsType,
    oldValues: ExpenseOptionsType | { [key: string]: any },
    noCompare: boolean = false,
  ) => {
    let filterObj: ExpenseOptionsType | { [key: string]: any } = {};
    ChangeOrderExpenseService.FIELD_NAMES.forEach((key: string) => {
      filterObj[key] = newValues.hasOwnProperty(key) ? newValues[key] : oldValues[key];
      if (noCompare) {
        return true;
      }
      if (
        newValues[key] === undefined ||
        (!newValues[key] && !oldValues[key]) ||
        isEqual(newValues[key], oldValues[key])
      ) {
        return false;
      }
      filterObj[`${key}_original`] = oldValues[key];
    });
    return filterObj as ExpenseOptionsType;
  };

  static mergePOItemChanges = (
    newData: POItemOptionsType[],
    oldData: POItemOptionsType[],
    filteredObj: ChangeOrderObjectOptionsType | { [key: string]: any },
  ) => {
    let itemsToCompare: Array<{ oldItem: POItemOptionsType; newItem: POItemOptionsType }> = [];
    let itemsWithoutIds: POItemOptionsType[] = [];
    newData.forEach((newItem) => {
      const oldItem = oldData.find((oldItem) => oldItem.id === newItem.id);
      if (oldItem) {
        itemsToCompare.push({ oldItem, newItem });
      } else {
        itemsWithoutIds.push(newItem);
      }
    });
    itemsToCompare.forEach(({ oldItem, newItem }) => {
      filteredObj.po_items_attributes.push(
        ChangeOrdersPOFormService.assignPOItemComparisonFields(newItem, oldItem) as POItemOptionsType,
      );
    });
    filteredObj.po_items_attributes = orderBy(filteredObj.po_items_attributes, ["id"], ["asc"]);
    itemsWithoutIds.forEach((item: POItemOptionsType) => {
      filteredObj.po_items_attributes.push(
        ChangeOrdersPOFormService.assignPOItemComparisonFields(item, {}, true) as POItemOptionsType,
      );
    });
  };

  static mergeExpenseChanges = (
    newData: ExpenseOptionsType[],
    oldData: ExpenseOptionsType[],
    filteredObj: ChangeOrderObjectOptionsType | { [key: string]: any },
  ) => {
    let itemsToCompare: Array<{ oldItem: ExpenseOptionsType; newItem: ExpenseOptionsType }> = [];
    let itemsWithoutIds: ExpenseOptionsType[] = [];
    newData.forEach((newItem) => {
      const oldItem = oldData.find((oldItem) => oldItem.id === newItem.id);
      if (oldItem) {
        itemsToCompare.push({ oldItem, newItem });
      } else {
        itemsWithoutIds.push(newItem);
      }
    });
    itemsToCompare.forEach(({ oldItem, newItem }) => {
      filteredObj.invoice_debit_accounts_attributes.push(
        ChangeOrdersPOFormService.assignExpenseComparisonFields(newItem, oldItem) as ExpenseOptionsType,
      );
    });
    filteredObj.invoice_debit_accounts_attributes = orderBy(
      filteredObj.invoice_debit_accounts_attributes,
      ["id"],
      ["asc"],
    );
    itemsWithoutIds.forEach((item: any) => {
      filteredObj.invoice_debit_accounts_attributes.push(
        ChangeOrdersPOFormService.assignExpenseComparisonFields(item, {}, true) as ExpenseOptionsType,
      );
    });
  };

  updateFileUris = (files: { name: string; uri: string }[]) => {
    this.dispatch(change(this.formName, "file_uris", files));
  };

  updateAssets = (assets: AssetType[]) => {
    this.dispatch(change(this.formName, "assets_attributes", assets));
  };

  getStartDate = (state: RootState): Date | null => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    return changeOrderValues && changeOrderValues.object_changes && changeOrderValues.object_changes.start_date;
  };

  getEndDate = (state: RootState): Date | null => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    return changeOrderValues && changeOrderValues.object_changes && changeOrderValues.object_changes.end_date;
  };

  getCurrencyCode = (state: RootState): string => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    if (changeOrderValues && changeOrderValues.object_changes) {
      return changeOrderValues.object_changes.currency_code;
    }
    return "";
  };
  getSubsidiaryId = (state: RootState): number | null => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    if (changeOrderValues && changeOrderValues.object_changes) {
      return changeOrderValues.object_changes.subsidiary_id;
    }
    return null;
  };

  getPOItemsTotal = (state: RootState): number => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    let total = 0;
    if (changeOrderValues && changeOrderValues.object_changes && changeOrderValues.object_changes.po_items_attributes) {
      changeOrderValues.object_changes.po_items_attributes.forEach((item: POItemOptionsType) => {
        if (!item._destroy && item.total) {
          total += Number(item.total);
        }
      });
    }
    return total;
  };

  getExpensesTotal = (state: RootState): number => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    let total = 0;
    if (
      changeOrderValues &&
      changeOrderValues.object_changes &&
      changeOrderValues.object_changes.invoice_debit_accounts_attributes
    ) {
      changeOrderValues.object_changes.invoice_debit_accounts_attributes.forEach((item: ExpenseOptionsType) => {
        if (!item._destroy && item.amount) {
          total += Number(item.amount);
        }
      });
    }
    return total;
  };

  getPOItemState = (state: RootState, index: number): POItemOptionsType | null => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    if (changeOrderValues && changeOrderValues.object_changes && changeOrderValues.object_changes.po_items_attributes) {
      return changeOrderValues.object_changes.po_items_attributes[index];
    }
    return null;
  };

  getExpenseState = (state: RootState, index: number): ExpenseOptionsType | null => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    if (
      changeOrderValues &&
      changeOrderValues.object_changes &&
      changeOrderValues.object_changes.invoice_debit_accounts_attributes
    ) {
      return changeOrderValues.object_changes.invoice_debit_accounts_attributes[index];
    }
    return null;
  };

  getCurrentPOId = (state: RootState): number | null => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    if (changeOrderValues && changeOrderValues.object_changes) {
      return changeOrderValues.object_changes.id;
    }
    return null;
  };

  getRequestableId = (state: RootState): number | null => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    if (changeOrderValues && changeOrderValues.object_changes) {
      return changeOrderValues.change_requestable_id;
    }
    return null;
  };

  getCurrentCOId = (state: RootState): number | null => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    if (changeOrderValues) {
      return changeOrderValues.id;
    }
    return null;
  };

  getStatus = (state: RootState): string | null => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    return changeOrderValues && changeOrderValues.status;
  };

  getCanEdit = (state: RootState): boolean | undefined => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    return changeOrderValues && changeOrderValues.can_edit_change_request;
  };

  getFileUris = (state: RootState): { name: string; uri: string }[] | undefined => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    return changeOrderValues && changeOrderValues.file_uris;
  };

  getAssets = (state: RootState): AssetType[] | undefined => {
    const changeOrderValues: ChangeOrderOptionsType = getFormValues(this.formName)(state) as ChangeOrderOptionsType;
    return changeOrderValues && changeOrderValues.assets_attributes;
  };
}
