import React from "react";
import { ReportTemplateOptionType } from "../../../../../services/admin/reports/templates/reportTemplateType";
import CheckboxFilter from "../reportFilters/checkbox";
import ContactsOptionsFilter from "../reportFilters/contactsDropdown";
import DateFilter from "../reportFilters/date";
import DropdownFilter from "../reportFilters/dropdown";
import InputFilter from "../reportFilters/input";

type customFilterPropsType = {
  name: string;
  code: string;
  type: "dropdown" | "input" | "date" | "checkbox" | "contact";
  options: { value: string; label: string }[];
  required?: boolean;
  isMulti?: boolean;
};
const CustomFilters = ({ reportTemplateChosen }: { reportTemplateChosen: ReportTemplateOptionType }) => {
  const customFilterComponents = {
    dropdown: DropdownFilter,
    input: InputFilter,
    date: DateFilter,
    checkbox: CheckboxFilter,
    contact: ContactsOptionsFilter,
  };
  if (reportTemplateChosen.filters) {
    const listItems = reportTemplateChosen.filters.map((filter: customFilterPropsType | undefined) => {
      if (filter) {
        let TagName = customFilterComponents[filter.type];
        return (
          <TagName
            name={filter.name}
            code={filter.code}
            options={filter.options}
            isRequired={filter.required}
            isMulti={filter.isMulti}
          />
        );
      }
    });
    return <>{listItems}</>;
  }
};

export default CustomFilters;
