import { AxiosResponse } from "axios";
import useAdminCompanyCurrencyCode from "components/admin/hooks/useAdminCompanyCurrencyCode";
import AddressPicker from "components/admin/pickers/reduxFormPickers/addressPicker";
import ApPaymentTypePicker from "components/admin/pickers/reduxFormPickers/apPaymentTypePicker";
import BillDistributionSchedulePicker from "components/admin/pickers/reduxFormPickers/billDistributionSchedulePicker";
import BusinessUnitPicker from "components/admin/pickers/reduxFormPickers/businessUnitPicker";
import ContactPicker from "components/admin/pickers/reduxFormPickers/contactPicker";
import CurrencyCodePicker from "components/admin/pickers/reduxFormPickers/currencyCodePicker";
import CustomDataListPicker from "components/admin/pickers/reduxFormPickers/customDataListPicker";
import CustomFieldPicker from "components/admin/pickers/reduxFormPickers/customFieldPicker";
import CustomNotesPicker from "components/admin/pickers/reduxFormPickers/customNotesPicker";
import DepartmentPicker from "components/admin/pickers/reduxFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reduxFormPickers/locationPicker";
import PaymentTermPicker from "components/admin/pickers/reduxFormPickers/paymentTermPicker";
import PostingPeriodPicker from "components/admin/pickers/reduxFormPickers/postingPeriodPicker";
import StaticWorkflowPicker from "components/admin/pickers/reduxFormPickers/staticWorkflowPicker";
import SubsidiaryPicker, {
  SubsidiaryPickerPropsType,
} from "components/admin/pickers/reduxFormPickers/subsidiaryPicker";
import VendorPicker from "components/admin/pickers/reduxFormPickers/vendorPicker";
import { invoiceStatus } from "components/app.svc.Lookup";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import FileUploader from "components/common/fileUploader/fileUploader";
import usePermission from "components/common/hooks/usePermission";
import {
  Mandatory,
  RenderCheckBox,
  RenderField,
  RenderFieldNumber,
  RenderSelect,
  RenderTextArea,
} from "components/forms/bootstrapFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch } from "react-redux";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { useTypedSelector } from "reducers";
import { Field, change, getFormValues } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { CurrenciesPropsType, IDType } from "services/common/types/common.type";
import { CommonTypes } from "services/common/types/commonTypes";
import { IUser } from "services/common/user/userTypes";
import { required } from "services/validations/reduxFormValidation";
import styles from "./invoices.module.css";

import BudgetPicker from "components/admin/pickers/reduxFormPickers/budgetPicker";
import DatePicker from "components/admin/pickers/reduxFormPickers/datePicker/datePicker";
import FiscalPeriodPicker from "components/admin/pickers/reduxFormPickers/fiscalPeriodPicker";
import VendorLocationPicker from "components/admin/pickers/reduxFormPickers/vendorLocationPicker";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";
import { CustomLabelSvc } from "services/admin/customLabels/customLabelsSvc";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import { companyDateFormat } from "services/general/dateSvc";
import { compare, formattedAmount } from "services/general/helpers";
import { roundUpAmount } from "services/vp/services/roundUpAmount";
import { MetadataFieldSelector } from "wombatifier/components/metadata_field/selector";
import { MetadataTemplateApis } from "wombatifier/services/metadata/metadataTemplateApis";
import ManagePoPicker, { ManagePoPickerRefType } from "./managePoPicker";

type InvoiceHeaderLineSectionPropsType = {
  isAddForm?: boolean;
  wantToCopy?: boolean;
  valid?: boolean;
  savedInvoice?: InvoiceType.InvoiceDetailType;
  mergeCopiedInvoice?: (copiedInvoice: InvoiceType.InvoiceDetailType) => void;
  customLabels?: { [key: string]: string[] };
};

export type AssignVendorInfoPropsType = {
  vendorId: number;
  invoice: any;
  currentUser: IUser;
  dispatch?: any;
  formName: string;
  appCurrencyCodes?: SingleValue<CommonTypes.ItemCurrencyCode>[];
};

const formName = "InvoiceForm";

const InvoiceHeaderLineSection = ({
  isAddForm,
  savedInvoice,
  wantToCopy,
  mergeCopiedInvoice,
  valid,
  customLabels,
}: InvoiceHeaderLineSectionPropsType) => {
  const { t } = useTranslation();
  const { hasUserPermission } = usePermission();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const [vendorCurrencies, setVendorCurrencies] = useState<CurrenciesPropsType[]>();
  const { companyCurrencies } = useAdminCompanyCurrencyCode();
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);
  const dispatch = useDispatch();

  const invoice: any = useTypedSelector((state) => getFormValues("InvoiceForm")(state), shallowEqual);
  const [noPush, setFlagNoPush] = useState(invoice?.no_push ? invoice?.no_push : false);
  const [attachments, setAttachments] = useState<any>(invoice?.assets ? invoice?.assets : []);
  const [isCopyDropdownOpen, setIsCopyDropdownOpen] = useState(false);
  const [isInvoiceSearch, setInvoiceSearch] = useState(false);
  const [isEnableCopyInvoiceBtn, setEnbleCopyInvoiceBtn] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  const poPickerRef = useRef<ManagePoPickerRefType>(null);
  const [showAdditionalCodingSection, setShowAdditionalCodingSection] = useState(false);

  const checkIsUsingMetadata = useCallback(async () => {
    try {
      const res = await MetadataTemplateApis.list();
      setShowAdditionalCodingSection(res.length > 0);
    } catch (err) {
      setShowAdditionalCodingSection(false);
    }
  }, []);

  useEffect(() => {
    checkIsUsingMetadata();
  }, []);

  const firstRenderRef = useRef<{ [key: string]: boolean }>({
    amount: false,
    date: false,
  });

  const canUploadAttachments =
    hasUserPermission("editattachmentInvoices") || hasUserPermission("invoicesAddAttachment");

  const preventEditInitialCall = (callback: () => void, field: string) => {
    if (!isAddForm && invoice && !firstRenderRef?.current[field]) {
      // this is blocking first triggering of callback function change for reinitialize of field for edit form
      firstRenderRef.current[field] = true;
    } else {
      callback();
    }
  };

  const statusOptions = useMemo(() => {
    if (isAddForm) {
      return invoiceStatus.filter((status) => ["NEW", "PENDING", "OPEN"].includes(status.value));
    }

    const canEditStatus = hasUserPermission("editstatusInvoices") || hasUserPermission("allowChangeInvoiceStatus");
    return canEditStatus || savedInvoice?.status === "PAID"
      ? [...invoiceStatus, { value: "PAID", label: "PAID" }]
      : [...invoiceStatus];
  }, [hasUserPermission, isAddForm, savedInvoice?.status]);

  const allowEditInvoiceStatus = () => {
    const disabledInvoiceStatus = hasUserPermission("disabledInvoiceStatus");
    const allowChangeInvoiceStatus = hasUserPermission("allowChangeInvoiceStatus");
    const editstatusInvoices = hasUserPermission("editstatusInvoices");

    // legacy roles - users with disabledInvoiceStatus can't edit status
    if (disabledInvoiceStatus) {
      return false;
    }
    // legacy roles - user must have allowChangeInvoiceStatus
    // new roles - user must have editstatusInvoices
    if (allowChangeInvoiceStatus || editstatusInvoices) {
      return true;
    }
    return false;
  };

  /*
  const isAllowToFilterBySubsidiary = () => {
    return currentUser?.company?.po_request_allow_vendor_on_subsidiary && invoice?.subsidiary_id;
  };*/

  const getMatchAccrualList = async (vendorId: number) => {
    try {
      const response: AxiosResponse = await restApiService.get("accruals?vendor_id_with_nil=" + vendorId);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const onVendorChange = async (vendorId: number) => {
    try {
      invoice.vendor_id = vendorId;
      getCurrencies();
      const matchAccrualList = await getMatchAccrualList(invoice.vendor_id);

      // Params need to send to "invoiceCommonSvc.assignVendorInfo" to update selected vendor info
      const assignVendorInfoParams: AssignVendorInfoPropsType = {
        vendorId,
        invoice,
        currentUser,
        dispatch,
        formName,
        appCurrencyCodes,
      };
      await invoiceCommonSvc.assignVendorInfo(assignVendorInfoParams);
    } catch (error) {
      throw error;
    }
  };

  const getCurrencies = async () => {
    try {
      if (invoice?.vendor_id) {
        const currencyCodes = await invoiceCommonSvc.getVendorCurrencies(invoice, appCurrencyCodes);
        setVendorCurrencies(currencyCodes);
      }
    } catch (error) {
      throw error;
    }
  };

  const billDistributionSchedulesParams = () => {
    if (
      _.isArray(invoice?.subsidiary?.bill_distribution_schedules) &&
      invoice?.subsidiary?.bill_distribution_schedules?.length > 0
    ) {
      return { subsidiary_id: invoice.subsidiary.id };
    }
  };

  const onTermChange = (term: any) => {
    if (term) {
      invoice.term = term;
      invoice.term_id = term.id;
      dispatch(change(formName, "term", term));
      dispatch(change(formName, "term_id", term.id));

      if (invoice) {
        invoiceCommonSvc.updateDueDate(invoice, dispatch, formName);
        invoiceCommonSvc.updateDiscountDate(invoice, dispatch, formName, currentUser);
        invoiceCommonSvc.updateDiscountAmount(invoice, dispatch, formName, currentUser);
      }
    }
  };

  // Display vendor currencies if 'use_vendor_level_currencies' is enabled; otherwise, show all company currencies.
  // Display empty until a vendor is selected if 'use_vendor_level_currencies' is enabled.
  const getCurrenciesOption = () => {
    let currencies: CurrenciesPropsType[] = [];
    if (currentUser?.company?.global?.use_vendor_level_currencies) {
      currencies = vendorCurrencies || [];
    } else {
      currencies = companyCurrencies || [];
    }
    return currencies;
  };

  const updateInvoiceCurrency = (currency: SingleValue<CommonTypes.ItemCurrencyCode>) => {
    let currencyObj = { ...currency, iso_code: currency?.value };
    dispatch(change(formName, "currency", currencyObj));
    // if(poPickerRef?.current?.updatePoByCurrencyCodeRef){
    //   invoice.currency_code = currency?.value;
    //   poPickerRef.current.updatePoByCurrencyCodeRef();
    // }
  };

  const handleFlagNoPuch = (value: boolean) => {
    setFlagNoPush(value);
    if (invoice?.no_push) {
      dispatch(change(formName, "force_push", false));
    }
  };

  const handleChangeInvoice = (copiedInvoice: InvoiceType.InvoiceDetailType) => {
    invoice.invoice_copy = copiedInvoice;
    setEnbleCopyInvoiceBtn(true);
    setSelectedInvoice(copiedInvoice);
  };

  const makeTaxFieldDisable = (selected: any) => {
    if (
      invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser) &&
      invoiceCommonSvc.allowDisableTaxByAmountsAre(selected.external_id)
    ) {
      invoice.amounts_are = selected.external_id;
      dispatch(change(formName, "amounts_are", selected.external_id));
      invoiceCommonSvc.removeTax(invoice);
    }
  };

  const uploadAttachments = (files: File[]) => {
    files.forEach((file) => {
      invoice.attachments.push(file);
    });
    dispatch(change(formName, "attachments", invoice.attachments));
    setAttachments(invoice.attachments);
  };

  const handleRemoveAttachment = (index: number) => {
    setAttachments((prevAttachments: any) => {
      let updatedAttachments = [...prevAttachments];
      updatedAttachments.splice(index, 1); // Remove the attachment at the specified index
      dispatch(change(formName, "attachments", updatedAttachments));
      return updatedAttachments;
    });
  };

  const parseForSelect = (options: any) => {
    return options
      .map((option: any) => {
        return {
          value: option.id,
          label: option.number,
          ...option,
        };
      })
      .sort(compare);
  };

  const getInvoicesList = async (getParams: any) => {
    try {
      const result = await restApiService.get("invoices", getParams, null, true, null, true);
      let parseOptions = parseForSelect(result.data.data);
      setInvoiceSearch(true);
      return parseOptions;
    } catch (error) {
      console.log(error);
    }
  };

  const loadOptions = useCallback(
    async (inputValue: string, callback: (options: any) => void) => {
      let getParams = { vendor_id: invoice.vendor_id, invoice_number: inputValue };

      if (getParams.invoice_number && getParams.vendor_id) {
        return await getInvoicesList(getParams);
      }
    },
    [invoice?.vendor_id],
  );

  const copyGlAccounts = (response: InvoiceType.InvoiceDetailType) => {
    deleteinvoiceEntriesID(response.credit_entries);

    if (_.isArray(response.debit_entries)) {
      response.debit_entries.forEach((entry) => {
        delete entry.id;

        if (!currentUser?.company?.is_tax_to_invoice_expenses_line) {
          delete entry.sub_amount;
        }

        if (invoiceCommonSvc.isItemDebitLine(entry) || currentUser?.company?.invoice.hide_debit_account) {
          entry._destroy = 1;
        }
      });
    }
  };

  const getInvoiceItems = async (copiedInvoiceID: IDType) => {
    try {
      const response: AxiosResponse<InvoiceType.InvoiceDetailType> = await restApiService.get(
        `invoices/${copiedInvoiceID}/invoice_items`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const deleteinvoiceEntriesID = function (invoiceEntries: any) {
    if (_.isArray(invoiceEntries) && invoiceEntries.length > 0) {
      invoiceEntries.forEach((entry) => {
        delete entry.id;
      });
    }
  };

  const loadSubsidiaryDependantFields = async (selected: SubsidiaryPickerPropsType) => {
    dispatch(change(formName, "subsidiary", selected));

    await invoiceCommonSvc.loadSubsidiaryDependantFields(invoice, selected, currentUser);
    dispatch(change(formName, "vendor", invoice.vendor));
    dispatch(change(formName, "location_id", invoice.location_id));
  };

  const handleRequestorChange = async (requestor: any) => {
    dispatch(change(formName, "requestor", requestor));
    let inheritedRequestorDept = await invoiceCommonSvc.inheritFromRequestor(requestor, invoice, currentUser);

    if (inheritedRequestorDept?.changed_department) {
      //TODO: we are not using checkManagerExists feature so commenting out until receive request to implement this feature
      // await adminCommonSvc.checkManagerExists(invoice.subsidiary_id, inheritedRequestorDept.changed_department.id);
      dispatch(change(formName, "department_id", inheritedRequestorDept.changed_department.id));
      dispatch(change(formName, "department", inheritedRequestorDept.changed_department));
    } else if (inheritedRequestorDept?.department_removed) {
      // $scope.manager_not_exists=false;
      dispatch(change(formName, "department_id", null));
    }
  };

  const handleVendorChange = (vendor: any) => {
    invoice.vendor = vendor;
    onVendorChange(vendor.id);
  };

  /*
  const getVendorLocationOptions = async (vendor_id?: number) => {
    if (!vendor_id) {
      return [];
    }
    const vendor = await VendorApis.getVendor(`${vendor_id}`);
    const options: any[] = [];
    vendor.vendor_locations
      .filter((vl: any) => vl.status === "ACTIVE")
      .forEach(function (vl: any) {
        let option = { value: vl.id };
        let label;
        if (vl.addresses_attributes.length > 0) {
          vl.addresses_attributes
            .filter((addr: any) => addr.status === "ACTIVE")
            .forEach(function (addr: any) {
              const { address1, address2, address3, city, state, zipcode, country } = addr;
              label = `${vl.external_id} | ${address1 || address2 || address3}${city ? ` ${city},` : ""}${state ? ` ${state}` : ""}${zipcode ? ` ${zipcode}` : ""}${country ? ` ${country}` : ""}`;
              options.push({ ...option, label });
            });
        }
      });
    return options;
  };*/

  const handleCopyInvoice = async (copiedInvoiceID: IDType) => {
    let invoiceCopy: any = {};
    if (copiedInvoiceID && mergeCopiedInvoice) {
      let response = await InvoiceApis.getInvoice(copiedInvoiceID);

      delete response.id;
      delete response.external_id;
      delete response.version_seq;
      delete response.purchasing_txn_external_id;
      delete response.account_transaction;
      delete response.payment_links;
      delete response.accrual_credit_accounts;
      delete response.accrual_debit_accounts;

      if (response.payment_method) {
        delete response.payment_method.id;
      }

      if (response.address && response.address.id) {
        response.address.id = null;
      }

      response.submit_date = invoice.submit_date;
      response.number = invoice.number;
      response.vendor = invoice.vendor;
      response.status = invoice.status;
      response.date = invoice.date;
      response.service_start_date = invoice.service_start_date || new Date();
      response.service_end_date = invoice.service_end_date || new Date();

      deleteinvoiceEntriesID(response.purchase_order_links);
      copyGlAccounts(response);

      invoiceCopy = response;
      let invoiceItemsResponse = await getInvoiceItems(copiedInvoiceID);
      invoiceCopy.invoice_items = invoiceItemsResponse.invoice_items;
      deleteinvoiceEntriesID(invoiceItemsResponse.invoice_items);

      invoiceCopy.credit_entries.forEach((entry: any) => {
        if (invoiceCopy.amount != 0) {
          entry.percent = roundUpAmount((entry.amount / invoiceCopy.amount) * 100);
        }
      });

      invoiceCopy.submitWithAccounts = true;
      invoiceCopy.async = false;

      invoiceCopy.credit_entries_attributes = invoiceCopy.credit_entries;
      invoiceCopy.debit_entries_attributes = invoiceCopy.debit_entries;
      invoiceCopy.invoice_items_attributes = invoiceCopy.invoice_items;
      invoiceCopy.po_links = invoiceCopy.purchase_order_links;
      invoiceCopy.invoice_purchase_order_links_attributes = invoiceCopy.purchase_order_links;

      mergeCopiedInvoice(invoiceCopy);
    }
  };

  const updateVendorAddress = (address: any) => {
    delete address.id;
    let updatedAddress = address;
    dispatch(change(formName, address, updatedAddress));
  };

  const isItemExist = () => invoiceCommonSvc.isItemExist(invoice?.invoice_items_attributes);
  const isDebitExist = () => invoiceCommonSvc.isDebitExist(invoice?.debit_entries_attributes);

  useEffect(() => {
    if (invoice && invoice.id && invoice.vendor_id) {
      getCurrencies();
    }
  }, [invoice?.vendor_id]);

  useEffect(() => {
    preventEditInitialCall(() => {
      invoiceCommonSvc.updateDiscountAmount(invoice, dispatch, formName, currentUser);
    }, "amount");
  }, [invoice?.amount]);

  useEffect(() => {
    preventEditInitialCall(() => {
      invoiceCommonSvc.updateDueDate(invoice, dispatch, formName);
      invoiceCommonSvc.updateDiscountDate(invoice, dispatch, formName, currentUser);
    }, "date");
  }, [invoice?.date]);

  return (
    <div className="invoiceHeaderForm">
      <Row>
        <Col sm={6}>
          <Field
            id="invoice-number"
            name="number"
            component={RenderField}
            type="text"
            label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.number"))}
            className={`w-100`}
            required={true}
            validate={[required]}
          />
        </Col>
        <Col sm={6}>
          <Field
            id="invoice-status"
            name="status"
            component={RenderSelect}
            FieldClassName="formField"
            options={statusOptions}
            label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.status"))}
            disabled={!allowEditInvoiceStatus()}
          />
        </Col>
      </Row>
      <Row>
        {currentUser?.company?.has_subsidiaries && (
          <Col sm={6}>
            <Field
              instanceId="invoice-subsidiary"
              name="subsidiary_id"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.subsidiary"))}
              component={SubsidiaryPicker}
              required
              validate={[required]}
              callBack={(selected: SubsidiaryPickerPropsType) => loadSubsidiaryDependantFields(selected)}
              disabled={currentUser?.company?.allow_only_inherited_subsidiary_and_fields_from_vendor}
            />
          </Col>
        )}
        <Col sm={6}>
          <Field
            id="invoice-date"
            name="date"
            label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.invoiceDate"))}
            component={DatePicker}
            required
            validate={[required]}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Field
            instanceId="invoice-vendor"
            name="vendor"
            label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.vendor"))}
            component={VendorPicker}
            excludeStatuses={"INACTIVE"}
            modelData={invoice}
            callBack={(vendor: number) => handleVendorChange(vendor)}
            required
            validate={[required]}
          />
        </Col>
        {!invoice?.vendor?.has_vendor_locations &&
          invoice?.vendor_id &&
          !currentUser?.company?.invoice?.hide_vendor_address && (
            <Col sm={12}>
              <Field
                instanceId="invoices-address"
                name="address"
                label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.vendorAddress"))}
                params={{ vendor: invoice?.vendor_id }}
                component={AddressPicker}
                callBack={(address: any) => updateVendorAddress(address)}
                addressableType="'Vendor'"
              />
            </Col>
          )}
        {invoice?.vendor?.has_vendor_locations ? (
          <Col sm={12}>
            <Field
              instanceId="invoice-vendor_location_id"
              name="vendor_location_id"
              component={VendorLocationPicker}
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.vendorLocation"))}
              modelData={invoice}
            />
          </Col>
        ) : null}
      </Row>

      <Row>
        <Col sm={6}>
          {wantToCopy && (
            <>
              <Form.Label>Copy Invoice ?</Form.Label>
              <br />
              <InputGroup>
                <AsyncSelect
                  instanceId="invoice-copy-invoice"
                  placeholder="select/search the list or leave blank"
                  cacheOptions
                  loadOptions={loadOptions}
                  onChange={(invoice: any) => handleChangeInvoice(invoice)}
                  onMenuOpen={() => setIsCopyDropdownOpen(true)}
                  onMenuClose={() => setIsCopyDropdownOpen(false)}
                  formatOptionLabel={(option, context) => {
                    return (
                      <small>
                        {option.number}
                        <br />
                        {isCopyDropdownOpen && isInvoiceSearch && (
                          <span>
                            Amount: {formattedAmount(option.amount.toString(), invoice?.currency_code)} <br />
                            Date: {companyDateFormat(option.date, currentUser)} Status: {option.status}
                            <br />
                            Description: {option.description}
                          </span>
                        )}
                      </small>
                    );
                  }}
                  defaultOptions
                  className={styles.asyncSelect}
                />

                <Button
                  id="button-addon2"
                  className={styles.copyInvoiceBtn}
                  onClick={() => handleCopyInvoice(selectedInvoice.id)}
                  disabled={!isEnableCopyInvoiceBtn}
                >
                  <i className="icon icon-fa-copy"></i>
                </Button>
              </InputGroup>
            </>
          )}

          {!(isItemExist().length > 0) &&
            !(isDebitExist().length > 0) &&
            !invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser) && (
              <Field
                id="invoices-amount"
                name="amount"
                type="number"
                label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.amount"))}
                component={RenderFieldNumber}
                required
                validate={[required]}
              />
            )}

          {(isItemExist().length > 0 ||
            isDebitExist().length > 0 ||
            invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser)) && (
            <Field
              id="invoices-amount"
              name="amount"
              type="number"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.amount"))}
              component={RenderFieldNumber}
              required
              defaultValue={invoiceCommonSvc.getInvoiceTotalAmount(invoice, currentUser)}
              validate={[required]}
              disabled
            />
          )}

          {!currentUser.company.invoice_hide_discount && (
            <Field
              id="invoice-discount-amount"
              name="amount_disc"
              type="number"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.discountAmount"))}
              component={RenderFieldNumber}
            />
          )}

          {!currentUser?.company?.invoice_hide_service_start_date && (
            <Field
              id="invoice-service-start-date"
              name="service_start_date"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.serviceStartDate"))}
              component={DatePicker}
              required={currentUser?.company?.invoice_service_start_date_required}
              validate={currentUser?.company?.invoice_service_start_date_required ? [required] : []}
            />
          )}

          {!currentUser?.company?.invoice_hide_service_end_date && (
            <Field
              id="invoice-service-end-date"
              name="service_end_date"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.serviceEndDate"))}
              component={DatePicker}
              required={currentUser?.company?.invoice_service_end_date_required}
              validate={currentUser?.company?.invoice_service_end_date_required ? [required] : []}
            />
          )}
          {currentUser?.company?.has_posting_period && (
            <Field
              instanceId="invoices-posting-period"
              name="posting_period"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.postingPeriod"))}
              component={PostingPeriodPicker}
              disabled={currentUser?.company?.invoice?.readonly_posting_period}
              required={currentUser?.company?.invoice_posting_period_required}
              validate={currentUser?.company?.invoice_posting_period_required ? [required] : []}
            />
          )}
          {currentUser?.company?.invoice_show_prior_period && (
            <Field
              id="invoices-prior-period"
              name="prior_period"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.priorPeriod"))}
              component={DatePicker}
              minDate={invoice?.minPriorDate ? new Date(invoice.minPriorDate) : null}
              maxDate={invoice?.maxPriorDate ? new Date(invoice.maxPriorDate) : null}
              tooltip={
                invoiceCommonSvc.isPriorPeriodDateRangeAvailable(currentUser) && (
                  <span className={styles.priorPeriodNote}>
                    Ensure selected date falls within prior period's established date range.
                  </span>
                )
              }
              required={currentUser?.company?.invoice?.is_required_prior_period}
              validate={currentUser?.company?.invoice?.is_required_prior_period ? [required] : []}
            />
          )}
          {invoice?.showPriorPeriodDateRangeError && (
            <p className={styles.priorPeriodError}>
              The date selected for the prior period falls outside of the established date range. To proceed, please
              select a date within the current date range.
            </p>
          )}

          {currentUser?.company?.invoice?.show_posted_date && (
            <Field
              id="invoices-posted_date"
              name="posted_date"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.postedDate"))}
              component={DatePicker}
              required
              validate={[required]}
            />
          )}

          {currentUser?.company?.invoice?.show_fiscal_period && (
            <Field
              id="invoices-fiscal_period"
              name="fiscal_period"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.fiscalPeriod"))}
              component={FiscalPeriodPicker}
              required={currentUser?.company?.invoice?.is_required_fiscal_period}
              validate={currentUser?.company?.invoice?.is_required_fiscal_period ? [required] : []}
            />
          )}

          {currentUser?.company?.invoice?.show_customer_account_number && (
            <Field
              id="invoices-customer_account_number"
              name="customer_account_number"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.customerAccountNumber"))}
              type="text"
              className={`w-100`}
              component={RenderField}
            />
          )}

          {currentUser?.company?.has_ap_payment_type && (
            <Field
              instanceId="invoices-payment-type"
              name="ap_payment_type_external_id"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.apPaymentType"))}
              component={ApPaymentTypePicker}
              required={true}
              validate={[required]}
            />
          )}

          {currentUser?.company?.has_locations && !currentUser?.company?.invoice_hide_location && (
            <Field
              instanceId="invoice-location"
              name="location_id"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.location"))}
              component={LocationPicker}
              modelData={invoice}
              parentObjData={invoice}
              callBack={(location: any) => dispatch(change(formName, "location", location))}
              required={currentUser?.company?.invoice?.is_required_location}
              validate={currentUser?.company?.invoice?.is_required_location ? [required] : []}
            />
          )}

          {currentUser?.company?.has_business_units && !currentUser?.company?.invoice?.hide_business_unit && (
            <Field
              instanceId="invoice-business-unit"
              name="business_unit_id"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.businessUnit"))}
              parentObjData={invoice}
              modelData={invoice}
              component={BusinessUnitPicker}
              callBack={(businessUnit: any) => dispatch(change(formName, "business_unit", businessUnit))}
              bySubsidiary={currentUser?.company?.invoice?.show_business_unit_by_subsidiary}
              disabled={currentUser?.company?.readonly_business_unit_to_all}
            />
          )}

          {currentUser?.company?.has_bill_distribution_schedules && (
            <Field
              instanceId="invoice-bill-distribution-schedule"
              name="bill_distribution_schedule_id"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.billDistributionSchedule"))}
              params={billDistributionSchedulesParams}
              component={BillDistributionSchedulePicker}
            />
          )}
          <Field
            id="invoice-reference-number"
            name="reference_number"
            component={RenderField}
            type="text"
            label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.referenceNumber"))}
            required={currentUser?.company?.invoice?.is_required_reference_number}
            validate={currentUser?.company?.invoice?.is_required_reference_number ? [required] : []}
          />
          {!currentUser?.company?.invoice_hide_company_requestor && (
            <Field
              instanceId="invoice-requestor"
              name="requestor_id"
              component={ContactPicker}
              type="text"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.companyRequestor"))}
              callBackObj={(contact: any) => handleRequestorChange(contact)}
              required={currentUser?.company?.invoice_requestor_required}
              validate={currentUser?.company?.invoice_requestor_required ? [required] : []}
            />
          )}

          {invoice?.external_payment_date && (
            <>
              <Field
                id="external-payment-date"
                name="external_payment_date"
                label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.externalPaymentDate"))}
                component={DatePicker}
              />
              <Field
                id="external-payment-memo"
                name="external_payment_memo"
                label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.externalPaymentMemo"))}
                rows={2}
                component={RenderTextArea}
              />
            </>
          )}

          <ManagePoPicker
            formName={formName}
            invoice={invoice}
            isAddForm={isAddForm}
            ref={poPickerRef}
            customLabels={customLabels}
          />
        </Col>

        <Col sm={6}>
          <Field
            instanceId="invoice-payment-term"
            name="term_id"
            label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.paymentTerms"))}
            component={PaymentTermPicker}
            required
            validate={[required]}
            callBack={(term: InvoiceType.TermType) => {
              onTermChange(term);
            }}
          />

          {invoice?.is_currency_code_edit_allow && (
            <Field
              instanceId="invoice-currency-code"
              name="currency_code"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.currencyCode"))}
              currencyCodeOptions={getCurrenciesOption()}
              component={CurrencyCodePicker}
              callBack={(currency: SingleValue<CommonTypes.ItemCurrencyCode>) => updateInvoiceCurrency(currency)}
              showCurrencyWithSymbol="true"
            />
          )}
          <Field
            id="invoice-due-date"
            name="due_date"
            label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.dueDate"))}
            component={DatePicker}
            required
            validate={[required]}
          />

          <Field id="invoice-submit-date" name="submit_date" label="Submit Date" component={DatePicker} />

          {!currentUser.company.invoice_hide_discount && (
            <Field
              id="invoice-discount-expire-date"
              name="amount_disc_date"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.discountExpireDate"))}
              component={DatePicker}
            />
          )}

          {currentUser?.company?.has_departments && !currentUser?.company?.invoice_hide_department && (
            <Field
              instanceId="invoice-department"
              component={DepartmentPicker}
              name="department_id"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.department"))}
              modelData={invoice}
              parentObjData={invoice}
              required={currentUser?.company?.invoice_department_required}
              validate={currentUser?.company?.invoice_department_required ? [required] : []}
              menuPosition="fixed"
            />
          )}

          {adminCommonSvc.isBudgetVisibleAtHeaderLevel(currentUser) &&
            !currentUser?.company?.budget?.allow_to_select_budget_lines && (
              <Field
                instanceId="invoices-budget"
                name="budget_id"
                component={BudgetPicker}
                placeholder="Select..."
                label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.budget"))}
                parentObjData={invoice}
                modelData={invoice}
              />
            )}

          {adminCommonSvc.isBudgetVisibleAtHeaderLevel(currentUser) &&
            currentUser?.company?.budget?.allow_to_select_budget_lines && (
              <Field
                instanceId="invoices-budget"
                name="budget_item_links"
                component={BudgetPicker}
                placeholder="Select..."
                isMulti={true}
                label={CustomLabelSvc.setCustomLabel(
                  customLabels,
                  t("admin.pages.invoice.budget"),
                  t("admin.pages.invoice.budgets"),
                )}
                parentObjData={invoice}
                modelData={invoice}
                callBack={(selectedBudgets: any) =>
                  dispatch(change(formName, "budget_item_links_attributes", selectedBudgets))
                }
              />
            )}

          {currentUser?.company?.invoice_workflow_name_required && (
            <Field
              instanceId="invoice-static-workflow"
              name="workflow_name"
              component={StaticWorkflowPicker}
              type="text"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.workflowName"))}
              modelName="Invoice"
              required={currentUser?.company?.invoice_workflow_name_required}
              validate={currentUser?.company?.invoice_workflow_name_required ? [required] : []}
            />
          )}

          {currentUser?.company?.has_amounts_are && (
            <Field
              instanceId="invoice-amount-are"
              name="amounts_are"
              component={CustomDataListPicker}
              label="Amounts Are"
              modelName={"AmountsAre"}
              required={currentUser?.company?.has_amounts_are}
              validate={currentUser?.company?.has_amounts_are ? [required] : []}
              callBack={(selected: any) => makeTaxFieldDisable(selected)}
            />
          )}

          <Field
            id="invoice-description"
            name="description"
            label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.description"))}
            rows={2}
            component={RenderTextArea}
            required={currentUser?.company?.invoice?.is_required_description}
            validate={currentUser?.company?.invoice?.is_required_description ? [required] : []}
          />

          {currentUser?.company?.has_custom_notes && (
            <Field
              instanceId="invoice-custom-note"
              name="custom_note_id"
              label={CustomLabelSvc.setCustomLabel(customLabels, t("admin.pages.invoice.customNote"))}
              placeholder="Select..."
              component={CustomNotesPicker}
            />
          )}
          <>
            {currentUser?.company?.has_integration &&
              currentUser?.company?.integration?.sync_settings?.sync_invoice &&
              !noPush && (
                <Field
                  id="invoice-force-sync-with-ERP"
                  name="force_push"
                  component={RenderCheckBox}
                  type="checkbox"
                  label="Force Sync with ERP"
                />
              )}
            <Field
              id="invoice-is-on-hold"
              name="is_on_hold"
              component={RenderCheckBox}
              type="checkbox"
              label="Kept On Hold"
            />
            <Field
              id="invoice-flag-no-push"
              name="no_push"
              component={RenderCheckBox}
              type="checkbox"
              label="Do not push to ERP"
              onChange={(value: any) => handleFlagNoPuch(value)}
            />
          </>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <CustomFieldPicker
            formFieldName="custom_fields"
            formName={formName}
            modelDataFieldName=""
            modelData={invoice}
            parentObjData={invoice}
            modelName="Invoice"
          />
        </Col>
      </Row>

      {showAdditionalCodingSection && (
        <Row>
          <ErrorBoundary>
            <MetadataFieldSelector formName={formName} modules="Invoice" />
          </ErrorBoundary>
        </Row>
      )}

      {canUploadAttachments && !invoice?.id && (
        <ErrorBoundary>
          <span className={`${styles.LabelFontSize14} ${styles.lblAttachments}`}>
            Attachments:
            <Mandatory required={currentUser?.company?.invoice?.is_attachment_required}></Mandatory>
          </span>

          <div className={styles.documentsContainer}>
            <Row className="px-mb-10">
              <Col>
                <FileUploader
                  customButton={
                    <Button variant="btn btn-primary btn-addfile" className={`${styles.addAttachmentBtn} px-mt-5`}>
                      <i className="icon icon-addfile"></i>
                    </Button>
                  }
                  showUploadBtn
                  uploadAttachments={(files: File[]) => uploadAttachments(files)}
                  multiple={false}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                {_.isArray(invoice?.attachments) && invoice?.attachments.length > 0 && (
                  <>
                    <span className={`px-mt-15 ${styles.LabelFontSize14} ${styles.lblAttachments}`}>
                      Attached Files:
                    </span>
                    <br />
                    {attachments?.map((asset: any, index: number) => {
                      if (asset._destroy != 1) {
                        return (
                          <Row className="px-mt-5" key={index}>
                            <Col className={`${styles.LabelFontSize14}`}>
                              <i className="icon icon-document"></i>
                              {asset.asset_file_file_name ?? asset.name}
                              <i
                                className={`icon icon-close ${styles.removeAttachment}`}
                                onClick={() => handleRemoveAttachment(index)}
                              ></i>
                            </Col>
                          </Row>
                        );
                      }
                    })}
                  </>
                )}
                {invoice?.attachmentWarning && (
                  <span className={styles.attachmentWarning}>{invoice?.attachmentWarning}</span>
                )}
              </Col>
            </Row>
          </div>
        </ErrorBoundary>
      )}
      {invoiceCommonSvc.isActiveHeaderTax(invoice, currentUser) && (
        <>
          <Row>
            <Col sm={10} className="d-flex align-items-center justify-content-end px-pr-0 formField">
              <span>Subtotal Amount : </span>
            </Col>
            <Col sm={2} className="formField">
              <span>
                {formattedAmount(
                  (invoiceCommonSvc.getInvoiceSubTotalAmount(invoice, currentUser) || 0).toString(),
                  invoice?.currency_code,
                )}
              </span>
            </Col>
          </Row>
          <hr className="full-border" style={{ marginRight: "-25px" }} />
          {invoice?.tax_rate_amounts &&
            invoice.tax_rate_amounts.length > 0 &&
            invoice.tax_rate_amounts.map((taxRateAmount: any, index: number) => (
              <Row key={index}>
                <Col sm={10} className="d-flex align-items-center justify-content-end px-pr-0 formField">
                  <span className="px-mb-15">
                    {taxRateAmount?.tax_rate?.name}

                    {taxRateAmount?.tax_rate?.rate && <span> {taxRateAmount.tax_rate.rate}% </span>}

                    {taxRateAmount.sub_amount && (
                      <span>
                        on {formattedAmount((taxRateAmount.sub_amount || 0).toString(), invoice?.currency_code)} :
                      </span>
                    )}
                  </span>
                </Col>
                <Col sm={2}>
                  <Field
                    id="invoice-taxrate-amount"
                    name={taxRateAmount.amount}
                    type="number"
                    component={RenderFieldNumber}
                    defaultValue={taxRateAmount.amount}
                  />
                </Col>
              </Row>
            ))}

          <Row>
            <Col sm={10} className="d-flex align-items-center justify-content-end px-pr-0 formField">
              <span>Total Amount :</span>
            </Col>
            <Col sm={2} className="formField">
              <span>{formattedAmount((invoice?.amount || 0).toString(), invoice?.currency_code)}</span>
            </Col>
          </Row>
          <hr style={{ marginRight: "-25px", marginLeft: "-25px" }} />
        </>
      )}
    </div>
  );
};

export default InvoiceHeaderLineSection;
