import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import ClientDataGrid from "components/common/dataGrid/clientDataGrid/clientDataGrid";
import ExportDataButton from "components/common/dataGrid/exportDataButton";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import ToggleFilterButton from "components/datagrid/buttons/toggleFilterButton";
import GridFilterDropdown from "components/datagrid/gridFilterDropdown";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { RebatesApis } from "services/admin/cards/rebates/rebatesApis";
import { RebatesTypes } from "services/admin/cards/rebates/rebatesTypes";
import { saveDefaultOrder } from "services/common/gridService";
import { IUser } from "services/common/user/userTypes";
import { getRebatesHeaders } from "./rebatesHeaders";

const GRID_STORAGE_NAME = "ai.grid.listRebates";

const useGridData = () => {
  const [gridData, setGridData] = useState<RebatesTypes.RebateItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    setError(null);

    RebatesApis.getRebateList()
      .then((result) => {
        if (isMounted) {
          setGridData(result);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setError("An error occurred");
        }
      })
      .finally(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return { gridData, isLoading, error };
};

function ListRebates() {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const { showFilters, updateShowFilters } = useShowFilterState("listRebateItemsFilter", true);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { t } = useTranslation();

  const { gridData } = useGridData();

  const gridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }, []);

  const gridHeaders = useMemo(() => getRebatesHeaders({ gridApi, currentUser }), [currentUser, gridApi]);

  const defaultColDef = useMemo(() => {
    return { resizable: true, filter: true, floatingFilter: showFilters };
  }, [showFilters]);

  useEffect(() => {
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
  }, [gridHeaders.defaultOrder]);

  return (
    <Container fluid className="mt-4">
      <Row className="ml-1">
        <div className={"d-flex justify-content-start"}>
          <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} gridStorageName={GRID_STORAGE_NAME} />
        </div>

        <Col className="d-flex justify-content-end">
          {gridApi && (
            <div className="ml-auto mt-1">
              <ExportDataButton
                title={t("admin.pages.expenseItem.exportExpenses")}
                csvName="expense_items"
                gridApi={gridApi}
              />
            </div>
          )}
          <ToggleFilterButton
            classes="float-right"
            clickCallback={() => {
              updateShowFilters(!showFilters);
            }}
          />
        </Col>
      </Row>

      <Row className="px-3 mt-3" style={{ height: "68vh" }}>
        <ClientDataGrid
          rowData={gridData}
          columnDefs={gridHeaders.columnDefs}
          defaultColDef={defaultColDef}
          gridReady={gridReady}
          gridStorageName={GRID_STORAGE_NAME}
          domLayout="normal"
          rowSelection="multiple"
          paginationPageSize={20}
          pagination
        />
      </Row>
    </Container>
  );
}

export default ListRebates;
