import React from "react";
import { useTranslation } from "react-i18next";
import { contactType } from "./verifyDataTable";

type verifyContactDetailsProps = {
  contact: contactType;
};

const VerifyContactDetails = ({ contact }: verifyContactDetailsProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <h5 className="d-inline">{t("verifyDataTable.contact")}: </h5>
      <p className="d-inline">{contact.name},</p>
      <h5 className="d-inline">{t("address.email")}: </h5>
      <p className="d-inline">{contact.email}</p>
    </div>
  );
};

export default VerifyContactDetails;
