import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import RestApi from "../../../../providers/restApi";
import { setCompanyProfileForm } from "../../../../reducers/admin/companyProfileFormReducer";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import CompanyProfileForm from "./companyProfileform";
import SettingsTabs from "../settingsTabs";
import settingsStyles from "../../settings/settings.module.css";
import { Link } from "react-router-dom";
import { updateCurrentUser } from "../../../../reducers/userReducers";

const restApiService = new RestApi();

const EditCompanyProfile = () => {
  const dispatch = useDispatch();
  const getCompanyDetail = async () => {
    try {
      const response = await restApiService.get("company");
      dispatch(setCompanyProfileForm(response.data));
    } catch (error) {}
  };

  const submit = async (companyProfileForm) => {
    try {
      const response = await restApiService.patch("company", null, companyProfileForm?.form);

      dispatch(updateCurrentUser());
      CreateNotification("Success", "Company Profile updated successfully", NotificationType.success);
    } catch (error) {}
  };

  useEffect(() => {
    getCompanyDetail();
  }, []);

  return (
    <Container fluid>
      <Row className={settingsStyles.navBarMargin}>
        <SettingsTabs
          breadcrumb={
            <span className={settingsStyles.landingHeader}>
              <Link to={"/ap/settings"}> Settings</Link>{" "}
            </span>
          }
        />
      </Row>
      <CompanyProfileForm onSubmit={submit} />
    </Container>
  );
};

export default EditCompanyProfile;
