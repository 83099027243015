import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import AppConfigsTypes from "./appConfigsTypes";

class AppConfigApis {
  static async getCurrencyCodes() {
    try {
      const response: AxiosResponse<AppConfigsTypes.ConfigTypeCurrency[]> = await restApiService.get("app_configs", {
        config_type: "CURRENCY",
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
export default AppConfigApis;
