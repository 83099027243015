import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button, Col, Container, Form, Overlay, Row } from "react-bootstrap";
import Style from "../workFlow.module.css";
import { ReactComponent as EditListIcon } from "../../../../../assets/workflow/edit-list.svg";
import { ReactComponent as ViewListIcon } from "../../../../../assets/workflow/view-list.svg";
import { ReactComponent as IcApprovalWorkflowIcon } from "../../../../../assets/workflow/ic-approval-workflow.svg";
import { ReactComponent as AddIcon } from "../../../../../assets/workflow/plus.svg";
import DragAndDrop from "../../../../../components/workflow/dragAndDrop";
import DragAndDropTiers from "../../../../../components/workflow/dragAndDropTiers";
import DragAndDropStaticWorkflows from "../../../../../components/workflow/dragAndDropStaticWorkflows";
import { static_workflow_types } from "../../../../../lookups/approvalWorkFlowLookups";
import RestApi from "../../../../../providers/restApi";
import StaticWorkFlowListView from "../../../../../components/workflow/staticWorkFlowListView";
import ApprovalFormRedux from "../../../../../components/workflow/approvalReduxForm";
import TestTriggerForm from "../../../../../components/workflow/testTriggerForm";
import TabNavigation from "../../../../../components/navigation/tabNavigation";
import { NotificationType, CreateNotification } from "../../../../../services/general/notifications";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveStepIndex,
  setActiveTierIndex,
  setLastStepSaveFlag,
  setShowAddButtonTooltip,
  setView,
  setWorkFlowActiveStatus,
  setWorkFlowType,
  setStaticWorkFlows,
  setStaticWorkFlowNames,
  setAllStaticWorkFlowNames,
  setActiveStaticWorkFlow,
  setStatic,
  setStaticTiers,
  setWillTrigger,
} from "../../../../../reducers/approvalSettingsReducers";
import { destroy, initialize } from "redux-form";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import NavTabs from "../../nav";

const GRIDVIEW = "GRIDVIEW";
const LISTVIEW = "LISTVIEW";

const StaticWorkFlow = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const history = useHistory();

  const [paramId, setParamId] = useState(param.id);

  const addButtonRef = useRef();
  // const [showAddButtonTooltip, setShowAddButtonTooltip] = useState(false);
  const restApiService = new RestApi();
  const state = useSelector((state) => state.approvalSettingsReducer);

  const getStaticWorkFlows = async () => {
    const res = await restApiService.get("approval_workflows", {
      workflow_type: state.workFlowType,
      active_required: state.activeRequired,
      static: true,
    });
    if (res.data) {
      // set static workflows and static workflow names
      dispatch(setStaticWorkFlowNames(getWorkFlowNames(res.data)));
      dispatch(setStaticWorkFlows(res.data));
      dispatch(setStatic(true));
      // reset static tiers on every get request
      dispatch(setStaticTiers([]));
      return res;
    }
  };

  const getAllStaticWorkFlows = async () => {
    const res = await restApiService.get("approval_workflows", {
      workflow_type: state.workFlowType,
      active_required: false,
      static: true,
    });
    if (res.data) {
      return res;
    }
  };

  const getAllWorkFlowNames = async () => {
    const staticWorkFlows = await restApiService.get("approval_workflows", {
      workflow_type: state.workFlowType,
      active_required: false,
      static: true,
    });
    if (staticWorkFlows.data) {
      var workFlowNames = staticWorkFlows.data.map((a) => {
        return { workflow_name: a.workflow_name, workflow_name_label: a.value.workflow_name_label };
      });
      workFlowNames = workFlowNames.filter((v, i, a) => i === a.findIndex((x) => x.workflow_name === v.workflow_name));
      dispatch(setAllStaticWorkFlowNames(workFlowNames));
    }
  };

  useEffect(() => {
    getAllWorkFlowNames();
  }, [state.workFlowType]);

  const getWorkFlowNames = (staticWorkFlows) => {
    const sortAlphaNum = (a, b) => {
      if (a.workflow_name_label < b.workflow_name_label) {
        return -1;
      } else if (a.workflow_name_label > b.workflow_name_label) {
        return 1;
      }
      return 0;
    };
    var workflowNames = staticWorkFlows.map((a) => {
      return { workflow_name: a.workflow_name, workflow_name_label: a.value.workflow_name_label };
    });
    return workflowNames
      .filter((v, i, a) => i === a.findIndex((x) => x.workflow_name === v.workflow_name))
      .sort(sortAlphaNum);
  };

  const moveToStepUrl = (step, workFlows) => {
    // Set correct step and tier index given the step
    const names = getWorkFlowNames(workFlows);
    if (step) {
      setParamId(step.id);
    } else {
      step = workFlows.filter((a) => a.value.workflow_name_label == names[0].workflow_name_label)[0];
    }
    const stepIndex = names.findIndex((obj) => obj.workflow_name === step.workflow_name);
    const tierIndex = workFlows
      .filter((a) => a.workflow_name == names[stepIndex].workflow_name)
      .findIndex((x) => x.id === step.id);
    dispatch(setActiveStepIndex(stepIndex));
    dispatch(setActiveTierIndex(tierIndex));
    dispatch(setActiveStaticWorkFlow(names[stepIndex], true));
    dispatch(setStaticTiers(workFlows.filter((a) => a.workflow_name == names[stepIndex].workflow_name)));
    history.push(`/ap/static_workflows/${step.id}`);
    dispatch(destroy("workFlowApprovalForm"));
    dispatch(destroy("testTriggerForm"));
    setTimeout(() => {
      dispatch(initialize("workFlowApprovalForm", step));
      dispatch(initialize("testTriggerForm", step));
    }, 300);
  };

  const moveToFirstStepUrl = async () => {
    dispatch(setLastStepSaveFlag(true));
    const res = await getStaticWorkFlows();
    if (res.data && res.data[0]) moveToStepUrl(null, res.data);
  };

  const getInitialWorkFlowSteps = async () => {
    // if url contain id then use it for data fetching and showing
    // set paramId to falsy value to go else block
    if (paramId && paramId != "new_approval") {
      try {
        const res = await restApiService.get(`approval_workflows/${param.id}`);
        if (res.data) {
          const stepData = res.data;
          onChangeWorkFlowType(stepData.workflow_type);
          if (stepData.status === "ACTIVE") onChangeFlowActiveStatus(true);
          if (stepData.status === "INACTIVE") onChangeFlowActiveStatus(false);
          if (res.data) {
            const res = await getStaticWorkFlows();
            if (res.data) {
              moveToStepUrl(stepData, res.data);
            }
          }
        } else {
          // no data got from param id then again move to
          moveToFirstStepUrl();
        }
      } catch (error) {
        // if record not found or any other error move first step url
        console.log("error", error);
        moveToFirstStepUrl();
      }
    } else {
      moveToFirstStepUrl();
    }
  };

  const resetParamId = () => {
    setParamId(undefined);
  };

  useEffect(() => {
    getInitialWorkFlowSteps();
  }, [state.workFlowType, state.activeRequired]);

  const onChangeView = (view) => {
    dispatch(setView(view));
  };

  const onChangeStaticWorkFlows = (newSteps) => {
    dispatch(setStaticWorkFlows(newSteps));
  };

  const onChangeStaticWorkflowNamesAll = (newSteps) => {
    dispatch(setAllStaticWorkFlowNames(newSteps));
  };

  const onChangeStaticWorkFlowNames = (newSteps) => {
    dispatch(setStaticWorkFlowNames(newSteps));
  };

  const onChangeActiveStaticWorkflow = (name, type) => {
    dispatch(setActiveStaticWorkFlow(name, type));
  };

  const onChangeStaticTiers = (newTiers) => {
    dispatch(setStaticTiers(newTiers));
  };

  const onChangeActiveStepIndex = (step) => {
    dispatch(setActiveStepIndex(step));
  };

  const onChangeActiveTierIndex = (tier) => {
    dispatch(setActiveTierIndex(tier));
  };

  const onChangeFlowActiveStatus = (status) => {
    dispatch(setWorkFlowActiveStatus(status));
  };

  const onChangeWorkFlowType = (workFlowType) => {
    dispatch(setWorkFlowType(workFlowType));
  };

  const onChangeFlowActiveStatusSelect = (status) => {
    resetParamId();
    dispatch(setLastStepSaveFlag(true));
    dispatch(setWorkFlowActiveStatus(status));
  };

  const onChangeWorkFlowTypeSelect = (workFlowType) => {
    resetParamId();
    dispatch(setLastStepSaveFlag(true));
    dispatch(setWorkFlowType(workFlowType));
    dispatch(setStaticTiers([]));
    dispatch(setStaticWorkFlows([]));
  };

  const addWorkFlow = () => {
    // for tool tip to prevent adding of unnessary step
    if (!state.isLastStepSaved) {
      if (!state.showAddButtonTooltip) {
        dispatch(setShowAddButtonTooltip(true));
        setTimeout(() => {
          dispatch(setShowAddButtonTooltip(false));
        }, 2000);
      }
      return;
    }
    // disable new step adding
    dispatch(setLastStepSaveFlag(false));

    const newStep = {
      priority: 1,
      workflow_type: state.workFlowType,
      status: "ACTIVE",
      trigger: "STATIC",
      value: {
        label: `Tier 1`,
        approver_type: "USER",
        any_member: false,
        any_member_count: 0,
        team_member: [],
        triggers: [],
        workflow_name_label: `Workflow ${state.staticWorkFlowNamesAll.length + 1}`,
      },
      skip_duplicate_approver: false,
      ignore_approval_limit: false,
      email_notification: false,
      email_reminder: false,
      parallel_team: false,
    };

    onChangeStaticWorkFlows([...state.staticWorkFlows, { ...newStep }]);
    onChangeStaticWorkFlowNames([
      ...state.staticWorkFlowNames,
      { workflow_name_label: newStep.value.workflow_name_label },
    ]);
    onChangeStaticWorkflowNamesAll([
      ...state.staticWorkFlowNamesAll,
      { workflow_name_label: newStep.value.workflow_name_label },
    ]);
    onChangeActiveStaticWorkflow({ workflow_name_label: newStep.value.workflow_name_label }, true);
    onChangeStaticTiers([newStep]);
    onChangeActiveStepIndex(state.staticWorkFlowNames.length);
    onChangeActiveTierIndex(0);
    dispatch(destroy("workFlowApprovalForm"));
    dispatch(destroy("testTriggerForm"));
    setTimeout(() => {
      dispatch(initialize("workFlowApprovalForm", newStep));
      history.push(`/ap/static_workflows/new_approval`);
    }, 300);
  };

  const addTier = () => {
    // for tool tip to prevent adding of unnessary step
    if (!state.activeStaticWorkFlow || state.staticWorkFlows == 0) return;
    if (!state.isLastStepSaved) {
      if (!state.showAddButtonTooltip) {
        dispatch(setShowAddButtonTooltip(true));
        setTimeout(() => {
          dispatch(setShowAddButtonTooltip(false));
        }, 2000);
      }
      return;
    }
    // disable new step adding
    dispatch(setLastStepSaveFlag(false));
    const newTier = {
      priority: state.staticTiers.length + 1,
      workflow_type: state.workFlowType,
      status: "ACTIVE",
      trigger: "STATIC",
      skip_duplicate_approver: false,
      ignore_approval_limit: false,
      email_notification: false,
      email_reminder: false,
      parallel_team: false,
      value: {
        label: `Tier ${state.staticTiers.length + 1}`,
        approver_type: "USER",
        any_member: false,
        any_member_count: 0,
        team_member: [],
        triggers: [],
        workflow_name_label: state.activeStaticWorkFlow.workflow_name_label,
      },
    };
    onChangeStaticTiers([...state.staticTiers, { ...newTier }]);
    onChangeActiveTierIndex(state.staticTiers.length);
    dispatch(destroy("workFlowApprovalForm"));
    dispatch(destroy("testTriggerForm"));
    setTimeout(() => {
      dispatch(initialize("workFlowApprovalForm", newTier));
      history.push(`/ap/static_workflows/new_approval`);
    }, 300);
  };

  const resetWillTrigger = () => {
    dispatch(setWillTrigger(null));
  };

  const onSubmitTest = async (value) => {
    // Tests if the workflow will trigger given the ID of an approvable
    try {
      if (value.id && value.approvableID) {
        const result = await restApiService.post(
          `approval_workflows/${value.id}/will_trigger`,
          {},
          {
            approval_workflow: value,
            approvable_id: value.approvableID,
          },
        );
        if (result.data) {
          dispatch(setWillTrigger(true));
        } else {
          dispatch(setWillTrigger(false));
          return "false";
        }
      }
    } catch (error) {
      if ((error.response.status = 404)) {
        dispatch(setWillTrigger(404));
      }
    }
  };

  const onSubmit = async (value) => {
    // fix for any_member_count
    if (!value.value.any_member_count) {
      delete value.value.any_member_count;
      delete value.value.any_member;
    } else value.value.any_member_count = Number(value.value.any_member_count);

    if (value.value.designation == "ACTION") {
      delete value.value.approver_type;
      delete value.value.contact_approver_id;
      delete value.value.team_member;
      if (value.value.action == "APPROVE") {
        value.value.last = true;
      } else value.value.last = false;
    } else {
      delete value.value.action;
      delete value.value.last;
    }

    // fix for trigger invoice PO
    if (value.value.triggers) {
      const typeCastedTriggers = value.value.triggers.map((trigger) => {
        if (trigger.key === "INVOICE_PO") return { ...trigger, value: JSON.parse(trigger.value) };
        else return trigger;
      });
      value.value.triggers = typeCastedTriggers;
    }

    // Format Manager Level
    if (!value.value.department_manager_level) {
      delete value.value.department_manager_level;
    } else value.value.department_manager_level = Number(value.value.department_manager_level);
    if (!value.value.location_manager_level) {
      delete value.value.location_manager_level;
    } else value.value.location_manager_level = Number(value.value.location_manager_level);

    if (
      state.staticWorkFlowNamesAll.filter((x) => x.workflow_name_label === value.value.workflow_name_label).length > 1
    ) {
      CreateNotification("Static Workflow ", `Workflow name already exists`, NotificationType.danger);
      return;
    }

    try {
      // If the workflow name is changed, change for all tiers
      state.staticTiers.map((tier, index) => {
        if (value.value.workflow_name_label != tier.value.workflow_name_label) {
          tier.value.workflow_name_label = value.value.workflow_name_label;
          if (tier.id) {
            restApiService.patch(
              `approval_workflows/${tier.id}`,
              {},
              {
                approval_workflow: tier,
              },
            );
          }
        }
      });

      // Copy UUID from other static tiers or generate new one
      // If no UUID is found in tiers, generate new one for all tiers
      if (![...new Set(state.staticTiers.map((x) => x.workflow_name).filter((id) => id !== undefined))].length) {
        let uuid = uuidv4();

        state.staticTiers.map((tier, index) => {
          tier.workflow_name = uuid;
          if (tier.id) {
            restApiService.patch(
              `approval_workflows/${tier.id}`,
              {},
              {
                approval_workflow: tier,
              },
            );
          }
        });
        value.workflow_name = uuid;
      } else if (value.workflow_name === undefined) {
        // If only tier, generate new UUID
        if (state.staticTiers.length == 1) {
          let uuid = uuidv4();
          value.workflow_name = uuid;
        } else {
          // If not only tier, copy UUID from first tier with UUID
          value.workflow_name = state.staticTiers.find((tier) => tier.workflow_name !== undefined).workflow_name;
        }
      }

      // new static workflow
      if (!value.id) {
        try {
          const result = await restApiService.post(
            "approval_workflows",
            {},
            {
              approval_workflow: value,
            },
          );

          if (result.data) {
            CreateNotification(
              "Static Workflow ",
              `Static Workflow ${result.data.label} Added.`,
              NotificationType.success,
            );
            const res = await (value.status == "INACTIVE" ? getAllStaticWorkFlows() : getStaticWorkFlows());
            if (res.data) {
              moveToStepUrl(result.data, res.data);
              // enable new step adding
              dispatch(setLastStepSaveFlag(true));
              getAllWorkFlowNames();
            }
          }
        } catch (error) {
          if (error.response?.data?.value?.includes("Approver has admin_readonly role")) {
            console.log(error.message);
            CreateNotification(
              "Static Workflow ",
              "Approver has 'admin_readonly' role, and cannot approve or reject approvals. Choose an approver with appropriate permissions",
              NotificationType.danger,
            );
          } else if (error.response?.data?.value?.includes("No team selected")) {
            console.log(error.message);
            CreateNotification(
              "Static Workflow ",
              "No team selected -- you must select a team for this workflow step. If you do not have any teams, you can create one in the 'Users' page under 'Teams'",
              NotificationType.danger,
            );
          } else {
            console.log(error.message);
            CreateNotification("Static Workflow ", `${error.message}`, NotificationType.danger);
          }
        }
      }

      if (value.id) {
        try {
          const result = await restApiService.patch(
            `approval_workflows/${value.id}`,
            {},
            {
              approval_workflow: value,
            },
          );

          if (result.data) {
            CreateNotification(
              "Static Workflow ",
              `Static Workflow ${result.data.label} Updated.`,
              NotificationType.success,
            );
            const res = await (value.status == "INACTIVE" ? getAllStaticWorkFlows() : getStaticWorkFlows());
            if (res.data) {
              moveToStepUrl(result.data, res.data);
              // enable new step adding
              dispatch(setLastStepSaveFlag(true));
              getAllWorkFlowNames();
            }
          }
        } catch (error) {
          if (error.response?.data?.value?.includes("Approver has admin_readonly role")) {
            console.log(error.message);
            CreateNotification(
              "Static Workflow ",
              "Approver has 'admin_readonly' role, and cannot approve or reject approvals. Choose an approver with appropriate permissions",
              NotificationType.danger,
            );
          } else if (error.response?.data?.value?.includes("No team selected")) {
            console.log(error.message);
            CreateNotification(
              "Static Workflow ",
              "No team selected -- you must select a team for this workflow step. If you do not have any teams, you can create one in the 'Users' page under 'Teams'",
              NotificationType.danger,
            );
          } else {
            console.log(error.message);
            CreateNotification("Static Workflow ", `${error.message}`, NotificationType.danger);
          }
        }
      }
      if (value.status == "INACTIVE") {
        onChangeFlowActiveStatusSelect(false);
      }
    } catch (error) {
      console.log(error.message);
      CreateNotification("Static Workflow ", `${error.message}`, NotificationType.danger);
    }
  };

  return (
    <Container fluid>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"Approval Workflows"} />
        </Col>
      </Row>
      <Row className={`${Style.card} fontSizeNormal w-100`}>
        <Col xs="12">
          <Row
            className={`m-0 pt-2 pb-1 fontSizeNormal w-100 secondaryDarkBlueFontColor d-flex flex-wrap ${Style.workFlowRow} mb-3`}
          >
            <Col xs={12}>
              <TabNavigation
                navigationTab={[
                  {
                    path: "/ap/approval_workflows",
                    pageName: "Approval Workflows",
                    isActive: "",
                  },
                  {
                    path: "/ap/static_workflows",
                    pageName: "Static Workflows",
                    isActive: "active",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row
            className={`m-0 pt-2 pb-1 fontSizeNormal w-100 secondaryDarkBlueFontColor d-flex flex-wrap ${Style.workFlowRow} mb-3`}
          >
            <Col md={6}>
              <div className="d-flex justify-content-around align-items-center my-2 w-75">
                <label className="w-25">Workflow type</label>
                <Form.Control
                  as="select"
                  value={state.workFlowType}
                  onChange={(e) => onChangeWorkFlowTypeSelect(e.target.value)}
                  className={`${Style.Custom__Select} m-2 w-50`}
                >
                  {static_workflow_types.map((workFlowType, index) => {
                    return (
                      <option key={index} value={workFlowType.value}>
                        {workFlowType.label}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
            </Col>
            <Col md={2} />

            <Col md={4}>
              <div className="d-flex justify-content-around align-items-center flex-wrap">
                Status
                <Form.Control
                  as="select"
                  className={`${Style.Custom__Select} m-2 w-50`}
                  value={state.activeRequired}
                  onChange={(e) => onChangeFlowActiveStatusSelect(e.target.value)}
                >
                  <option value={true}>ACTIVE</option>
                  <option value={false}>ALL</option>
                </Form.Control>
                <i onClick={() => onChangeView(GRIDVIEW)}>
                  <EditListIcon style={{ cursor: "pointer" }} />
                </i>
                <i onClick={() => onChangeView(LISTVIEW)}>
                  <ViewListIcon style={{ cursor: "pointer" }} />
                </i>
              </div>
            </Col>
          </Row>

          {state.view === LISTVIEW && (
            <StaticWorkFlowListView workFlowSteps={state.staticWorkFlows} workFlowNames={state.staticWorkFlowNames} />
          )}
          {state.view === GRIDVIEW && (
            <Row className="m-0 mb-5">
              {/* work flow steps */}
              <Col md={3} className={`${Style.workflowStepContainer} p-0 m-0`}>
                <div className={`d-flex justify-content-around align-items-center ${Style.workflowHeader}`}>
                  <div>
                    <IcApprovalWorkflowIcon />
                  </div>
                  <div>
                    {state.workFlowType &&
                      static_workflow_types.find((type) => type.value === state.workFlowType).label}
                  </div>
                  <div>
                    <Button onClick={addWorkFlow} ref={addButtonRef} variant="outline-primary">
                      <AddIcon /> Workflow
                    </Button>
                    <Overlay target={addButtonRef.current} show={state.showAddButtonTooltip} placement="top">
                      {({ placement, arrowProps, show: _show, popper, ...props }) => (
                        <div
                          {...props}
                          style={{
                            position: "absolute",
                            backgroundColor: "#448aba",
                            padding: "10px 10px",
                            marginBottom: "5px",
                            color: "white",
                            borderRadius: 3,
                            ...props.style,
                          }}
                        >
                          Please save last step data or discard unsaved data.
                        </div>
                      )}
                    </Overlay>
                  </div>
                </div>

                <div>
                  {!state.staticWorkFlowNames.length && <div className="m-3">There are no workflows.</div>}
                  <DragAndDropStaticWorkflows />
                </div>
              </Col>
              <Col md={2} className={`${Style.workflowStepContainer} p-0 m-0`}>
                <div className={`d-flex justify-content-around align-items-center ${Style.workflowHeader}`}>
                  <div>
                    <IcApprovalWorkflowIcon />
                  </div>
                  <div>Tiers</div>
                  <div>
                    <Button onClick={addTier} ref={addButtonRef} variant="outline-primary">
                      <AddIcon /> Tier
                    </Button>
                    <Overlay target={addButtonRef.current} show={state.showAddButtonTooltip} placement="top">
                      {({ placement, arrowProps, show: _show, popper, ...props }) => (
                        <div
                          {...props}
                          style={{
                            position: "absolute",
                            backgroundColor: "#448aba",
                            padding: "10px 10px",
                            marginBottom: "5px",
                            color: "white",
                            borderRadius: 3,
                            ...props.style,
                          }}
                        >
                          Please save last step data or discard unsaved data.
                        </div>
                      )}
                    </Overlay>
                  </div>
                </div>

                <div>
                  {!state?.staticTiers?.length && <div className="m-3">There are no tiers.</div>}
                  <DragAndDropTiers />
                </div>
              </Col>
              <Col md={7} className="p-0 m-0">
                {_.isArray(state.staticWorkFlows) && state.staticWorkFlows.length > 0 && (
                  <ApprovalFormRedux onSubmit={onSubmit} />
                )}
                {_.isArray(state.staticWorkFlows) && state.staticWorkFlows.length > 0 && (
                  <hr style={{ width: "98%", marginLeft: "0px", marginRight: "15px" }} />
                )}
                {_.isArray(state.staticWorkFlows) && state.staticWorkFlows.length > 0 && (
                  <TestTriggerForm onSubmit={onSubmitTest} onChange={resetWillTrigger} />
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default StaticWorkFlow;
