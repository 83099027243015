import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { getListOptionsType } from "services/common/types/common.type";
import { MetadataField } from "./metadata.types";

export class MetadataFieldApis {
  static ENDPOINT = "metadata_fields";
  static async list({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<{ data: MetadataField[] } | MetadataField[]> = await restApiService.get(
        this.ENDPOINT,
        filter,
        null,
        true,
        null,
        cache,
      );
      // if paginated response, since it has { data: , meta: } structure
      if (typeof response.data === "object" && "data" in response.data) {
        return response.data?.data;
      }
      return response.data as MetadataField[];
    } catch (error) {
      throw error;
    }
  }

  static async get(id: string) {
    try {
      const response: AxiosResponse<MetadataField> = await restApiService.get(`${this.ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
