import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

/**
 * Renders a tooltip overlay around the given children elements.
 *
 * @param {Object} props - The properties passed to the TooltipRender component.
 * @param {React.ReactNode} [props.children] - The elements that the tooltip will be applied to.
 * @param {string|React.ReactNode} [props.title] - The text displayed inside the tooltip.
 * @param {string} [props.placement="top"] - The preferred position of the tooltip relative to the children.
 * @param {string} [props.className] - Additional CSS class names for styling the tooltip trigger element.
 * @param {string} [props.containerClassName] - Additional CSS class names for styling the tooltip container.
 * @param {boolean} [props.dangerouslySetInnerHTML] - allow HTML formatting inside the tooltip's title
 * @param {} [props.onEntering] - allow to change style for of tooltip
 */
function TooltipRender(props) {
  const { title, placement, className, containerClassName, children, dangerouslySetInnerHTML, onEntering } = props;
  return (
    <OverlayTrigger
      placement={placement}
      onEntering={onEntering}
      overlay={
        dangerouslySetInnerHTML ? (
          <Tooltip className={containerClassName} id="button-tooltip-2">
            <div dangerouslySetInnerHTML={{ __html: title }}></div>
          </Tooltip>
        ) : (
          <Tooltip className={containerClassName} id="button-tooltip-2">
            {title}
          </Tooltip>
        )
      }
    >
      {({ ref, ...triggerHandler }) => (
        <span {...triggerHandler}>
          {children ? <span ref={ref}>{children}</span> : <i className={className} ref={ref} />}
        </span>
      )}
    </OverlayTrigger>
  );
}

export default TooltipRender;
