import React, { useState } from "react";

type UseCollapsiblePropsType = {
  defaultCollapse?: boolean;
};

const useCollapsible = ({ defaultCollapse }: UseCollapsiblePropsType = { defaultCollapse: true }) => {
  const [collapsed, setCollaped] = useState(defaultCollapse);
  const collapseHandlerUi = (
    <span className="my-auto cursorPointer" onClick={() => setCollaped((prev) => !prev)}>
      {collapsed ? (
        <i className="icon-arrow-down-black m-0" style={{ display: "block", height: 10, width: 16 }} />
      ) : (
        <i className="icon-arrow-up-black m-0" style={{ display: "block", height: 10, width: 16 }} />
      )}
    </span>
  );

  return {
    collapsed,
    collapseHandlerUi,
  };
};

export default useCollapsible;
