/*eslint-disable eqeqeq*/
import React from "react";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
class CustomDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quickFIlter: props.quickFIlter,
    };
  }
  render() {
    return (
      <div className="gridDropdown d-flex">
        <div className={"d-flex"}>
          {this.props.gridHeaderButtons}
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="mySelectGrid">
              <i className="icon-g icon-bolt"></i>
              Quick Filters
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.onQuickFilterSelect("clear")}>Clear Filters</Dropdown.Item>
              <Dropdown.Item onClick={() => this.onQuickFilterSelect("reset")}>Reset Grid</Dropdown.Item>
              {this.props.dataType == "purchase_orders" ? (
                <Dropdown.Item onClick={() => this.onQuickFilterSelect("topOpenPOSort")}>
                  Top Open PO Balances Sort
                </Dropdown.Item>
              ) : null}
              {this.props.dataType == "purchase_orders" ? (
                <Dropdown.Item onClick={() => this.onQuickFilterSelect("negativePOSort")}>
                  Negative PO Balances Sort
                </Dropdown.Item>
              ) : null}
              {this.props.dataType == "purchase_orders" ||
              this.props.dataType == "invoices" ||
              this.props.dataType == "invoice_request" ||
              this.props.dataType == "payments" ? (
                <Dropdown.Item onClick={() => this.onQuickFilterSelect("noCustomer")}>
                  NO Customer Specified
                </Dropdown.Item>
              ) : null}
              {this.props.dataType == "invoices" ? (
                <Dropdown.Item onClick={() => this.onQuickFilterSelect("noPO")}>NO PO Specified</Dropdown.Item>
              ) : null}

              {this.props.dataType == "purchase_orders" || this.props.dataType == "payments" ? (
                <Dropdown.Item onClick={() => this.onQuickFilterSelect("noRequestor")}>
                  NO Requestor Specified
                </Dropdown.Item>
              ) : null}
              {this.props.dataType == "invoices" ? (
                <Dropdown.Item onClick={() => this.onQuickFilterSelect("outstanding")}>Outstanding</Dropdown.Item>
              ) : null}

              {this.props.dataType == "purchase_orders" ||
              this.props.dataType == "invoices" ||
              this.props.dataType == "invoice_request" ||
              this.props.dataType == "payments" ? (
                <Dropdown.Item onClick={() => this.onQuickFilterSelect("month")}>This Month</Dropdown.Item>
              ) : null}

              {this.props.dataType == "purchase_orders" ||
              this.props.dataType == "invoices" ||
              this.props.dataType == "invoice_request" ||
              this.props.dataType == "payments" ? (
                <Dropdown.Item onClick={() => this.onQuickFilterSelect("quarter")}>This Quarter</Dropdown.Item>
              ) : null}
              {this.props.dataType == "purchase_orders" ||
              this.props.dataType == "invoices" ||
              this.props.dataType == "invoice_request" ||
              this.props.dataType == "payments" ? (
                <Dropdown.Item onClick={() => this.onQuickFilterSelect("lastQuarter")}>Last Quarter</Dropdown.Item>
              ) : null}
              {this.props.dataType == "purchase_orders" ||
              this.props.dataType == "invoices" ||
              this.props.dataType == "invoice_request" ||
              this.props.dataType == "payments" ? (
                <Dropdown.Item onClick={() => this.onQuickFilterSelect("year")}>This Year</Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={"ml-auto"}>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id={"filter"}>
                <strong>Toggle Filter</strong>
              </Tooltip>
            }
          >
            <div style={{ float: "right" }}>
              <button className="gridFilter " onClick={this.onShowFloatfilter}>
                <span className="filter-funnel"></span>
              </button>
            </div>
          </OverlayTrigger>

          {this.props.addRow && (
            <OverlayTrigger
              placement="auto"
              overlay={
                <Tooltip id={"add"}>
                  <strong>{this.props.addRow.tooltip}</strong>
                </Tooltip>
              }
            >
              <button className="gridFilter" onClick={this.props.addRow.callback}>
                <span className="add-contact"></span>
              </button>
            </OverlayTrigger>
          )}
        </div>
      </div>
    );
  }
  onQuickFilterSelect = (event) => {
    this.props.onQuickFilterSelect(event);
  };
  onShowFloatfilter = (event) => {
    console.log(event);
    this.props.onShowFloatfilter();
  };
}

export default CustomDropdown;
