import React from "react";
import { VendorPickerPropsType } from ".";

type MultiVendorPickerPropType = VendorPickerPropsType;

const MultiVendorPicker = ({
  label,
  input,
  meta: { touched, error },
  tooltip,
  params,
  required,
  instanceId,
  placeholder,
  disabled,
  originalValue,
}: MultiVendorPickerPropType) => {
  return <></>;
};

export default MultiVendorPicker;
