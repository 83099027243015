import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../../../components/forms/bootstrapFields";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import IntegrationEdi from "../../components/integrationComponents/integrationEdi";
import IntegrationSftp from "../../components/integrationComponents/integrationSftp";

const IntegrationBOA_PAYMENT = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <Col>
          <label>
            Bank Provider Payment Info <small>(to generate sepa xml file)</small>
          </label>
        </Col>
      </Row>

      <Row className="m-0">
        <Col lg="6">
          <Field
            name="form.boa_creditor_identifier"
            placeholder="Creditor Identifier"
            label="Creditor Identifier"
            component={RenderField}
            type="text"
            id="boa_creditor_identifier"
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.boa_cash_pro_id"
            label="CashPro ID"
            placeholder="cash_pro_id"
            component={RenderField}
            type="text"
            id="boa_cash_pro_id"
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.ach_id"
            label="ACH ID "
            placeholder="boa_ach_id"
            component={RenderField}
            type="text"
            id="ach_id"
          />
        </Col>
      </Row>

      <Row className="m-0">
        <IntegrationSftp sftpSublabel="upload xml file to bank" />
      </Row>
      <Row className="m-0">
        <IntegrationEdi />
      </Row>
    </Col>
  );
};
export { IntegrationBOA_PAYMENT };
