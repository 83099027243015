import React, { useCallback, useEffect, useState } from "react";
import { change, Field } from "redux-form";
import { Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RenderFieldNumber } from "../../../forms/bootstrapFields";
import AccountPicker from "../../../../components/admin/pickers/reduxFormPickers/accountPicker/index";
import DepartmentPicker from "../../../admin/pickers/reduxFormPickers/departmentPicker";
import BusinessUnitPicker from "../../../pickers/reduxFormPicker/businessUnitPicker";
import LocationPicker from "../../../pickers/reduxFormPicker/locationPicker";
import InterCompaniesPicker from "../../../pickers/reduxFormPicker/interCompaniesPicker";
import { useSelector, useDispatch } from "react-redux";
import styles from "../manageDefaultAccount.module.css";
import _ from "lodash";
import { required, number } from "../../../../services/validations/reduxFormValidation";
import {
  getDepartmentparams,
  getInterCompanyParams,
  getTotalDropDown,
} from "../../../../services/common/manageAccounts.svc";
import { MANAGEACCOUNT_FORM_NAME } from "../../../../reducers/admin/manageAccountFormReducer";

const RenderForm = (props) => {
  return (
    <React.Fragment>
      <Col sm="12">
        <Row className="mt-2">
          <Col sm="6">
            <Field
              name={`${props.field}.account_id`}
              component={AccountPicker}
              label={props.t("components.admin.manageDefaultAccount.account")}
              isClearable={false}
              onChange={props.onAccountChange}
              accountGroupName={props.accountGroupName}
              required={props.fields.get(props.index).percent ? true : false}
              validate={props.fields.get(props.index).percent ? [required] : []}
            />
          </Col>
          <Col sm="4">
            <Field
              name={`${props.field}.percent`}
              type="text"
              component={RenderFieldNumber}
              label={props.t("components.admin.manageDefaultAccount.percentage")}
              required={props.fields.get(props.index).account_id ? true : false}
              validate={props.fields.get(props.index).account_id ? [required, number] : [number]}
            />
          </Col>

          {props.actualFieldLength > 1 && (
            <Col className="mt-10">
              <i
                className={`icon icon-delete ${styles["mt-40"]}`}
                onClick={() => {
                  props.removeAccount();
                }}
              />
            </Col>
          )}
        </Row>
        <Row>
          {props.currentUser && props.currentUser.company.has_departments && (
            <Col md={props.classCount}>
              <Field
                name={`${props.field}.department_id`}
                component={DepartmentPicker}
                label={props.t("components.admin.manageDefaultAccount.department")}
                params={props.departmentParams}
                modelData={props.fields.get(props.index)}
                required={false}
              />
            </Col>
          )}
          {props.currentUser && props.currentUser.company.has_business_units && (
            <Col md={props.classCount}>
              <Field
                name={`${props.field}.business_unit_id`}
                component={BusinessUnitPicker}
                label={props.t("components.admin.manageDefaultAccount.businessUnit")}
                required={false}
                disabled={
                  props.currentUser &&
                  props.currentUser.company &&
                  props.currentUser.company.readonly_business_unit_to_all
                }
              />
            </Col>
          )}
          {props.currentUser && props.currentUser.company.has_locations && (
            <Col md={props.classCount}>
              <Field
                name={`${props.field}.location_id`}
                component={LocationPicker}
                label={props.t("components.admin.manageDefaultAccount.location")}
                required={false}
              />
            </Col>
          )}
          {props.currentUser && props.currentUser.company.has_inter_companies && (
            <Col md={props.classCount}>
              <Field
                name={`${props.field}.inter_company_id`}
                component={InterCompaniesPicker}
                params={props.interCompanyParams}
                label={props.t("components.admin.manageDefaultAccount.interCompany")}
                required={props.fields.get(props.index).is_inter_company_required}
              />
            </Col>
          )}
        </Row>
      </Col>
    </React.Fragment>
  );
};

const SingleAccountForm = ({ fields, field, index, accountGroupName, actualFieldLength, accountRemoved }) => {
  const [classCount, setClassCount] = useState(3);
  const currentUser = useSelector((state) => state.user);
  const [departmentParams, setDepartmentParams] = useState(getDepartmentparams(fields.get(index), currentUser));
  const [interCompanyParams, setInterCompanyParams] = useState(getInterCompanyParams(fields.get(index), currentUser));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    getDropdownColumnCount();
  }, []);

  const onAccountChange = (account_id) => {
    const accountObj = fields.get(index) ? _.cloneDeep(fields.get(index)) : {};
    accountObj["account_id"] = account_id;
    const departmentParamsObj = getDepartmentparams(accountObj, currentUser);

    if (JSON.stringify(departmentParamsObj) !== JSON.stringify(departmentParams)) {
      setDepartmentParams(departmentParamsObj);
    }

    const interCompanyParamsObj = getInterCompanyParams(accountObj);
    if (JSON.stringify(interCompanyParamsObj) !== JSON.stringify(interCompanyParams)) {
      setInterCompanyParams(interCompanyParamsObj);
    }
  };

  const getDropdownColumnCount = () => {
    const totalDropdown = getTotalDropDown(currentUser);
    if (totalDropdown === 3) {
      setClassCount(4);
    } else if (totalDropdown === 2) {
      setClassCount(6);
    } else if (totalDropdown === 1) {
      setClassCount(12);
    }
  };

  const removeAccount = () => {
    dispatch(change(MANAGEACCOUNT_FORM_NAME, `${field}._destroy`, 1));
    accountRemoved();
  };

  return (
    <>
      <RenderForm
        fields={fields}
        field={field}
        index={index}
        accountGroupName={accountGroupName}
        t={t}
        actualFieldLength={actualFieldLength}
        accountRemoved={accountRemoved}
        currentUser={currentUser}
        removeAccount={removeAccount}
        classCount={classCount}
        interCompanyParams={interCompanyParams}
        onAccountChange={onAccountChange}
        departmentParams={departmentParams}
      />
    </>
  );
};

export default SingleAccountForm;
