import { GridApi } from "ag-grid-community";
import BulkActionDropdown from "components/common/dataGrid/bulkActionDropdown";
import usePermission from "components/common/hooks/usePermission";
import CustomModal from "components/modals/customModal";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import styles from "./payExpense.module.css";
import BulkApprovalForm from "./bulkApprovalForm";
import { payReimbursementType } from "../../../../services/admin/expenses/payReimbursementType";
import { useHistory } from "react-router";
import gridService from "services/grid/gridSvc";
type BulkActionPropsType = {
  gridApi?: GridApi;
  handelAddToPaymentRun: () => void;
};

const BulkAction = ({ gridApi, handelAddToPaymentRun }: BulkActionPropsType) => {
  const [showModal, setShowModal] = useState(false);
  const [invoices, setInvoices] = useState<payReimbursementType.bulkInvoice[]>([]);
  const [payout, setPayout] = useState<Record<string, payReimbursementType.payoutType>>({});
  const [errorMessage, setErrorMessage] = useState("");
  const currentUser = useTypedSelector((state) => state.user);
  const history = useHistory();
  const { hasUserPermission } = usePermission();
  const hasSendProfileReminderPermission =
    hasUserPermission("submitExpenseReports") || hasUserPermission("addExpenseReports");
  const { t } = useTranslation();
  const bulkPayHandler = () => {
    if (gridApi) {
      const selectedRows = gridService.getServerSideSelectedRows(gridApi);
      if (selectedRows.length < 1) {
        CreateNotification("No Invoice Selected", "Please select at least one Expense Report", NotificationType.danger);
        return;
      }

      let keepGoing = true;
      let newInvoices: payReimbursementType.bulkInvoice[] = [];
      let newPayout: Record<string, payReimbursementType.payoutType> = {};

      selectedRows.forEach((invoice) => {
        let is_pay_bill = true;
        if (keepGoing) {
          if (!invoice.is_validate_pay_now) {
            setErrorMessage(
              "All Selected Expenses Report(s): 1. status should be APPROVED or OPEN 2. has payment info 3. balance_outstanding > $0.00",
            );
            keepGoing = false;
            return;
          }

          if (!currentUser.company.has_payment_workflow && invoice.payment_method.payment_type === "international") {
            setErrorMessage("International Payment(s) can not be initiated without having payment workflow.");
            keepGoing = false;
            return;
          }

          const obj = {
            id: invoice.id,
            amount: is_pay_bill ? Number(invoice.pay_amount) : 0, // Assuming is_pay_bill is available in React
            payment_submission_window: is_pay_bill ? invoice.payment_submission_window : 0, // Assuming is_pay_bill is available in React
          };

          newInvoices.push(obj);

          const paymentType = invoice?.payment_method?.payment_type?.toLowerCase();
          const paymentTypeDisplay = `${invoice?.payment_method?.payment_type.toLowerCase()}_${invoice.currency_code}`;
          if (newPayout[paymentType]) {
            newPayout[paymentType].totalInvoice++;
            newPayout[paymentType].totalAmt += invoice.pay_amount;
            newPayout[paymentType].paymentTypeDisplay = paymentTypeDisplay;
            newPayout[paymentType].currencySymbol = invoice?.currency?.symbol;

            if (invoice.is_discount_applicable) {
              newPayout[paymentType].discountAmt += invoice.amount_disc;
            }
          } else {
            const discountAmt = invoice.is_discount_applicable ? invoice.amount_disc : 0;
            newPayout[paymentType] = {
              paymentMethod: "",
              totalInvoice: 1,
              successInvoiceCount: 0,
              totalAmt: invoice.pay_amount,
              successAmt: 0,
              discountAmt: discountAmt,
              paymentTypeDisplay: paymentTypeDisplay,
              currencySymbol: invoice?.currency?.symbol,
            };
          }
        }
      });

      if (!keepGoing) return;
      setInvoices(newInvoices);
      setPayout(newPayout);
      setShowModal(true);
    }
  };

  const modalCloseHandler = () => {
    if (gridApi) {
      gridApi.deselectAll();
      gridApi.refreshServerSide();
      gridApi.redrawRows();
    }
    setShowModal(false);
  };
  const bulkPayReview = () => {
    handelAddToPaymentRun();
    history.push("/ap/expenses_review_payment_run");
  };

  return (
    <>
      {showModal && (
        <CustomModal
          show={showModal}
          onHide={() => setShowModal(false)}
          header={"Bulk Expense Report Payment Approval"}
          headerClass={"px-4"}
          size="lg"
          modalBodyClass={`${styles.approvalModalContent}`}
          contentClassName={`${styles.approvalModalContent}`}
          body={<BulkApprovalForm payout={payout} onClose={modalCloseHandler} invoices={invoices} />}
          hideCloseButton={true}
        />
      )}
      <BulkActionDropdown topClassName="px-mr-16" menuClass={`${styles.menuClass}`}>
        {hasSendProfileReminderPermission && (
          <>
            <Dropdown.Item className="smallFont" onClick={bulkPayHandler}>
              <i className={`icon-dropdown-list icon-fa-dollar-grey ${styles.dollarIcon}`} />
              Submit Selected for Approval
            </Dropdown.Item>
            <Dropdown.Item className="smallFont" onClick={bulkPayReview}>
              <i className={`icon-dropdown-list icon-enevelope-o-grey`} />
              Submit Selected for Payment Run
            </Dropdown.Item>
          </>
        )}
      </BulkActionDropdown>
    </>
  );
};

export default BulkAction;
