import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { SingleValue } from "react-select";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { IUser } from "services/common/user/userTypes";
import { AbstractListPickerTypes } from "../abstractListPicker/abstractListPickerTypes";
import AsyncSingleListPicker from "../abstractListPicker/asyncSingleListPicker";
import { ProjectPickerTypes } from "./projectPickerTypes";

const SinglePicker = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  instanceId = "single-project-picker",
  callBack,
  modelData,
  parentObj,
  menuPosition = "fixed",
  menuPlacement = "auto",
  validate = {},
  isForProject,
  isPoRequest,
  containerClassName,
  objectPropertyKey,
  tooltip,
  labelClassName,
}: ProjectPickerTypes.TSingleProjectPickerProps) => {
  const { getValues, setValue } = useFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);

  const locationFieldName = useMemo(() => {
    let field = "location_id";
    if (!_.isNil(modelData)) {
      return modelData + field;
    } else {
      return "";
    }
  }, [modelData]);

  const [locationId] = useWatch({ name: [locationFieldName] });

  const newLocationId = locationId;
  const oldLocationIdRef = useRef();

  const callback = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<SingleValue<ProjectPickerTypes.TProjectPickerOption>>) => {
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack],
  );

  const setProjectOnModalData = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<SingleValue<ProjectPickerTypes.TProjectPickerOption>>) => {
      if (!_.isNil(modelData)) {
        if (isForProject) {
          setValue(modelData + "for_project_id", selected?.id ?? null);
          setValue(modelData + "for_project_name", selected?.name ?? null);
        } else {
          setValue(modelData + "project_id", selected?.id ?? null);
          setValue(modelData + "project_name", selected?.name ?? null);
          setValue(modelData + "project", selected ?? null);
        }

        if (!isForProject && currentUser.company.has_departments && selected?.department_id) {
          setValue(modelData + "department_id", selected?.department_id);
        }

        if (!isForProject && currentUser.company.has_locations && selected?.location_id) {
          setValue(modelData + "location_id", selected?.location_id);
        }

        if (currentUser.company.has_customers && selected?.customer_external_id) {
          setValue(modelData + "customer_external_id", selected.customer_external_id);
        }
      }
    },
    [
      currentUser.company.has_customers,
      currentUser.company.has_departments,
      currentUser.company.has_locations,
      isForProject,
      modelData,
      setValue,
    ],
  );

  const handleSelection = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<SingleValue<ProjectPickerTypes.TProjectPickerOption>>) => {
      setValue(name, selected?.id ?? null);
      setProjectOnModalData(selected);
      callback(selected);
    },
    [callback, name, setProjectOnModalData, setValue],
  );

  const allowFilterByLocation = useCallback(() => {
    const isLocationMapToProject = !_.isNil(modelData) && getValues(modelData + "is_location_map_to_project");
    return currentUser.company.global.allow_to_filter_projects_by_location && isLocationMapToProject && !isForProject;
  }, [currentUser.company.global.allow_to_filter_projects_by_location, getValues, isForProject, modelData]);

  const getModelDataParams = useCallback(
    (apiParams: Record<string, any>) => {
      const locationId = getValues(modelData + "location_id");
      if (allowFilterByLocation() && locationId) {
        apiParams.location_id = locationId;
      }
    },
    [allowFilterByLocation, getValues, modelData],
  );

  const getParentObjParams = useCallback(
    (apiParams: Record<string, any>) => {
      const isProjectsLinkedToSubsidiary = getValues(parentObj + "is_projects_linked_to_subsidiary");
      const subsidiaryId = getValues(parentObj + "subsidiary_id");

      if (isProjectsLinkedToSubsidiary && !isForProject && subsidiaryId) {
        apiParams.subsidiary_id = subsidiaryId;
      }
    },
    [getValues, isForProject, parentObj],
  );

  const params = useMemo(() => {
    const obj: Record<string, any> = {};
    if (!_.isNil(modelData)) {
      getModelDataParams(obj);
    }
    if (!_.isNil(parentObj)) {
      getParentObjParams(obj);
    }
    if (isPoRequest && currentUser?.contact?.has_projects) {
      obj.contact_id = currentUser.contact.id;
    }
    return obj;
  }, [
    currentUser.contact?.has_projects,
    currentUser.contact.id,
    getModelDataParams,
    getParentObjParams,
    isPoRequest,
    modelData,
    parentObj,
  ]);

  const onChangeModalLocation = useCallback(() => {
    if (!_.isNil(modelData)) {
      const project = getValues(modelData + "project");
      const projectId = project?.id;
      if (newLocationId && oldLocationIdRef.current && newLocationId !== oldLocationIdRef.current) {
        oldLocationIdRef.current = newLocationId;
        if (allowFilterByLocation() && projectId && project?.location_id !== newLocationId) {
          handleSelection(null);
        }
      } else if (newLocationId) {
        oldLocationIdRef.current = newLocationId;
      }
    }
  }, [allowFilterByLocation, getValues, handleSelection, modelData, newLocationId]);

  const onInitialRender = useCallback(() => {
    if (!_.isNil(modelData)) {
      const project = getValues(modelData + "project");
      const forProject = getValues(modelData + "for_project");
      const projectName = getValues(modelData + "project_name");
      const projectId = getValues(modelData + "project_name");

      const forProjectId = getValues(modelData + "for_project_id");
      const forProjectName = getValues(modelData + "for_project_name");

      if (!isForProject && project) {
        handleSelection(project);
      } else if (isForProject && forProject) {
        handleSelection(forProject);
      } else if (!isForProject && projectName && projectId) {
        const projectObj = {
          id: projectId,
          name: projectName,
        };
        handleSelection(projectObj);
      } else if (isForProject && forProjectId && forProjectName) {
        const forProjectObj = {
          id: forProjectId,
          name: forProjectName,
        };
        handleSelection(forProjectObj);
      }
    }
  }, [getValues, handleSelection, isForProject, modelData]);

  const getResponseData = useCallback((response) => {
    return response?.data?.data;
  }, []);

  useEffect(() => {
    onChangeModalLocation();
  }, [locationId, onChangeModalLocation]);

  useEffect(() => {
    onInitialRender();
  }, [onInitialRender]);

  return (
    <AsyncSingleListPicker
      instanceId={instanceId}
      name={name}
      required={required}
      objectPropertyKey={objectPropertyKey ?? (!_.isNil(modelData) ? modelData + "project" : "")}
      fetchUrl="projects.lk"
      searchParamName="PROJECT_NAME"
      cache
      callBack={handleSelection}
      containerClassName={containerClassName}
      disabled={disabled}
      label={label}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      tooltip={tooltip}
      validate={validate}
      getResponseData={getResponseData}
      placeholder={placeholder ?? "Select..."}
      params={params}
      labelClassName={labelClassName}
    />
  );
};

export default SinglePicker;
