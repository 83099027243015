import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { GrFormRefresh } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import {
  applyTagsToEmail,
  emailDetailSetter,
  markAsCompleted,
  refreshEamil,
  reprocessEmail,
  selectNextPreviousEmail,
  showDetailPageSetter,
  updateEmailDetailsToProcessed,
} from "../../../../reducers/admin/emailReducer";
import EmailTagSelector from "./emailTagsSelelctor";
import { FaArrowLeft } from "react-icons/fa";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

export const ListViewEmailTopNavButton = () => {
  const dispatch = useDispatch();
  const nextPreviousIndex = useSelector((state: RootState) => state.emailRed.nextPreviousIndex);
  const emailDetails = useSelector((state: RootState) => state.emailRed.emailDetails);

  const showDetailPage = useSelector((state: RootState) => state.emailRed.showDetailPage);
  const selectedEmail = useSelector((state: RootState) => state.emailRed.selectedEmails);
  const shouldButtonDisable = selectedEmail?.length > 0 ? false : true;

  const [showTagSelector, setShowTagSelector] = useState(false);

  const toggleTagSelector = () => {
    setShowTagSelector(!showTagSelector);
  };

  const applyEmailTags = async (tags: string[]) => {
    await dispatch(applyTagsToEmail(tags));
    toggleTagSelector();
  };

  const closeDetialPage = () => {
    dispatch(showDetailPageSetter(false));
    dispatch(emailDetailSetter(null));
  };

  const selectNextPrevious = (value: string) => {
    dispatch(selectNextPreviousEmail(value));
  };

  const reprocess = async () => {
    await dispatch(reprocessEmail());

    // update detail page of email on when showing details page
    if (showDetailPage) {
      await dispatch(updateEmailDetailsToProcessed(emailDetails?.id));
    }
  };

  const markComplete = async () => {
    await dispatch(markAsCompleted());

    // update detail page of email on when showing details page
    if (showDetailPage) {
      await dispatch(refreshEamil(emailDetails.id));
    }
  };

  return (
    <div className={"d-flex align-items-center "}>
      {showDetailPage && (
        <div onClick={closeDetialPage} className={"mx-2"} style={{ color: "#76777A" }}>
          <FaArrowLeft />
        </div>
      )}

      <Button
        disabled={shouldButtonDisable}
        className={"listViewEmailHeaderBtn"}
        onClick={reprocess}
        data-toggle="tooltip"
        title="Reprocess"
      >
        <GrFormRefresh className={"EmailHeaderTabIcon"} />
      </Button>

      <div className={"mr-2"}>
        <Button
          disabled={shouldButtonDisable}
          onClick={toggleTagSelector}
          className={"listViewEmailHeaderBtn mt-2"}
          data-toggle="tooltip"
          title="Add tags"
        >
          <i className="icon icon-tags" />
        </Button>
        {showTagSelector && (
          <div>
            <EmailTagSelector closeButton={() => toggleTagSelector()} applyEmailTags={applyEmailTags} />
          </div>
        )}
      </div>

      <Button
        disabled={shouldButtonDisable}
        onClick={markComplete}
        className={"listViewEmailHeaderBtn pb-1"}
        data-toggle="tooltip"
        title="Mark complete"
      >
        <i className="icon-sm icon-mark-complete-grey" />
      </Button>

      {showDetailPage && (
        <div className={"ml-auto mr-3"}>
          {nextPreviousIndex.previousRowIndex >= 0 && (
            <AiFillCaretLeft onClick={() => selectNextPrevious("previous")} className={"h-100 emailNextPreviousBtn"} />
          )}
          {!!nextPreviousIndex.nextRowIndex && (
            <AiFillCaretRight
              onClick={() => selectNextPrevious("next")}
              className={"h-100 mx-2 emailNextPreviousBtn"}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ListViewEmailTopNavButton;
