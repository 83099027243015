import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BankAddressLine from "../bankAddressLine";
import { Field } from "redux-form";
import { RenderField, RenderSelect } from "../../../forms/bootstrapFields";
import TransitNumber from "../transitNumber";
import { AccountNumberValidator } from "../accountNumberValidator";
import { required } from "../../../../services/validations/reduxFormValidation";
import InstitutionNumber from "../institutionNumber";
import { IDType } from "../../../../services/common/types/common.type";
import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import _ from "lodash";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

const CanEftPaymentMethod = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const [institutionNumber, setInstitutionNumber] = useState<IDType>();
  const [transitNumber, setTransitNumber] = useState<IDType>();
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);

  return (
    <>
      <Col md="4">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="4">
        <InstitutionNumber modelName={`${modelName}`} callBack={(value) => setInstitutionNumber(value)} />
      </Col>
      <Col md="4">
        <TransitNumber
          modelName={`${modelName}`}
          institutionNumber={institutionNumber}
          callBack={(value: IDType) => setTransitNumber(value)}
        />
      </Col>
      <Col sm="4">
        <Field
          name={`${modelName}.account_type`}
          component={RenderSelect}
          options={[
            { value: null, label: "" },
            { value: "checking", label: "Checking" },
            { value: "savings", label: "Savings" },
          ]}
          FieldClassName="formField"
          placeholder={"Search/Select"}
          label={t("common.paymentMethod.accountType")}
        />
      </Col>
      <Col md="4">
        <AccountNumberValidator
          modelName={`${modelName}`}
          accountNumberType="canadian-account-number"
          transitNumber={transitNumber}
          institutionNumber={institutionNumber}
          maxLength={12}
        />
      </Col>
      <Col sm="4">
        <Field
          name={`${modelName}.currency_code`}
          component={CurrencyCodePicker}
          FieldClassName="formField"
          placeholder={"Search/Select"}
          label={t("common.paymentMethod.currencyCode")}
          currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
          defaultValue="CAD"
        />
      </Col>
      <BankAddressLine modelName={`${modelName}`} defaultCountry="USA" isCountryRequired={true} />
    </>
  );
};

export default CanEftPaymentMethod;
