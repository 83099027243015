import { MetadataConfiguration } from "pages/admin/administration/metadata/metadataConfiguration";
import React, { useCallback, useEffect, useState } from "react";
import {
  change,
  Field,
  FieldArray,
  FormAction,
  formValueSelector,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import RestApi from "providers/restApi";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { setConfiguration } from "reducers/slices/metadataConfigurationSlice";
import styles from "./metadataHierarchy.module.css";
import MetadataFieldPicker from "components/admin/pickers/reduxFormPickers/metadataPicker/metadataPicker";

const restApi = new RestApi();

interface MetadataConfigurationEditProps {
  metadataConfiguration: MetadataConfiguration;
  refreshCallback: any;
  metadataChoices: MetadataConfiguration[];
  allMetadataConfigurations: MetadataConfiguration[];
  formName: string;
}

type Props = MetadataConfigurationEditProps & InjectedFormProps<{}, MetadataConfigurationEditProps>;

const MetadataConfigurationUpsert: React.FC<Props> = ({
  metadataConfiguration,
  refreshCallback,
  allMetadataConfigurations,
  formName,
  metadataChoices,
  handleSubmit,
}) => {
  //holds the currently selected dependent metadata configuration
  const [dependentMetadataConfiguration, setDependentMetadataConfiguration] = useState<string | null>(null);

  const selector = formValueSelector(formName);

  //we want to grab this as the parent field id is part of the key to render the correct select dropdown
  //for some reason we need this, if i delete this line the form doesnt change select dropdowns
  const parentFieldValue = useSelector((state) => selector(state, "selected_parent_field"));
  const initialDependent = useSelector((state) => selector(state, "dependent_metadata_configuration"));

  const selectedMetadataConfiguration = metadataChoices
    ? metadataChoices.find((choice: any) => choice.field_id === dependentMetadataConfiguration)
    : null;

  let dependentValueName = `dependent_${dependentMetadataConfiguration}_${parentFieldValue?.value}`;

  const dependentConfigurationChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedConfiguration = event.target.value;
    setDependentMetadataConfiguration(newSelectedConfiguration);
  };

  const transformDataForSave = (formValues: any) => {
    let finalState = {
      parent_field_type: "MetadataConfiguration",
      dependent_field_type: "MetadataConfiguration",
      parent_field_id: null,
      dependent_field_id: null,
      metadata_configuration_field_id: metadataConfiguration.field_id,
      dependent_metadata_configuration_field_id: dependentMetadataConfiguration,
      parent_hierarchies_attributes: [],
    };

    Object.entries(formValues).forEach(([key, value]) => {
      //we only grab the keys that are for the currently selected dependent configuration. The others need to be saved manually
      if (key.startsWith("dependent_" + dependentMetadataConfiguration)) {
        let parsedHierarchies = formValues[key].map((metadataField: any) => {
          return {
            //dependent_metadata_configuration_id: metadataField.metadata_configuration_id,
            /* @ts-ignore */
            parent_metadata_field_id: parseInt(key.split("_").pop()),
            dependent_metadata_field_id: metadataField.value,
            dependent_field_type: "MetadataConfiguration",
            parent_field_type: "MetadataConfiguration",
            id: metadataField.id,
          };
        });

        finalState.parent_hierarchies_attributes = finalState.parent_hierarchies_attributes.concat(parsedHierarchies);
      }
    });

    return finalState;
  };

  const onSubmit = async (formValues: any) => {
    let parsedHierarchies: any = transformDataForSave(formValues);

    await restApi
      .patch("metadata_configurations/upsert_metadata_hierarchies", null, parsedHierarchies)
      .then((results: any) => {
        CreateNotification("Success", "Metadata Hierarchies edited succesfully", NotificationType.success);
      })
      .catch((error) => {
        console.error(error);
        CreateNotification("Error", "Error updating hierarchies: " + error, NotificationType.danger);
        return null;
      });
  };

  useEffect(() => {
    if (initialDependent) {
      setDependentMetadataConfiguration(initialDependent);
    }
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className=" mb-2">
        <div>
          <h4>{metadataConfiguration.name}</h4>
        </div>

        <div className="mb-2">
          <p>Dependent Field</p>
          <Field name="dependent_metadata_configuration" component="select" onChange={dependentConfigurationChanged}>
            <option value="">Select Dependent Metdata...</option>
            {metadataChoices
              .filter((metadata) => metadata.field_id != metadataConfiguration.field_id)
              .map((choice: any) => (
                <option key={choice.field_id} value={choice.field_id}>
                  {choice.name}
                </option>
              ))}
          </Field>
        </div>

        <div className="d-flex">
          <div className={`${styles.selectWidth} mr-2`}>
            <p>{metadataConfiguration.name}</p>
            <Field
              label={metadataConfiguration.name}
              name={"selected_parent_field"}
              title={metadataConfiguration.name}
              model={"MetadataConfiguration"}
              component={MetadataFieldPicker}
              props={{ metadataConfiguration: metadataConfiguration, name: "", isMulti: false }}
            />
          </div>

          <div className={`mr-2`}>
            {dependentMetadataConfiguration && (
              <div className="d-flex">
                <div className={`${styles.selectWidth}`}>
                  <p>{dependentMetadataConfiguration}</p>
                  <Field
                    key={dependentMetadataConfiguration + "_" + parentFieldValue?.value}
                    name={dependentValueName}
                    component={MetadataFieldPicker}
                    props={{
                      metadataConfiguration: selectedMetadataConfiguration,
                      isMulti: true,
                    }}
                  />
                </div>

                <div className="d-flex ml-2" style={{ alignItems: "center" }}>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default reduxForm<{}, MetadataConfigurationEditProps>({
  form: "metadataConfigurationForm",
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  destroyOnUnmount: true,
})(MetadataConfigurationUpsert);
function dispatch(arg0: FormAction) {
  throw new Error("Function not implemented.");
}
