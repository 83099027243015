import { AxiosResponse } from "axios";
import { restApiService } from "../../../../providers/restApi";
import { SchedulesListType, SchedulesDetailType, ScheduleParamsType, SchedulesFormType } from "./schedulesType";

export class SchedulesApis {
  static getList = async (cache = true, params: ScheduleParamsType) => {
    try {
      const baseParams: ScheduleParamsType = {
        report_classification: "Standard",
        schedulable_type: "Company",
      };
      const response: AxiosResponse<SchedulesListType> = await restApiService.get(
        "schedules",
        { ...baseParams, ...params },
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  static getDetail = async (id: number) => {
    try {
      const response: AxiosResponse<SchedulesDetailType> = await restApiService.get(
        `schedules/${id}`,
        null,
        null,
        true,
        null,
        true,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  static create = async (payload: SchedulesFormType) => {
    try {
      const response: AxiosResponse<SchedulesDetailType> = await restApiService.post(`schedules`, null, {
        schedule: payload,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  static patch = async (payload: SchedulesFormType) => {
    try {
      const response: AxiosResponse<SchedulesDetailType> = await restApiService.patch(`schedules/${payload.id}`, null, {
        schedule: payload,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  static delete = async (id: number) => {
    try {
      const response: AxiosResponse<SchedulesDetailType> = await restApiService.delete(
        `schedules/${id}`,
        null,
        null,
        true,
      );
      return response.status === 204;
    } catch (error) {
      throw error;
    }
  };
}
