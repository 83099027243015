import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import PaymentCountryPicker from "../../pickers/paymentCountryPicker";
import CurrencyPicker from "../../vp/pickers/reduxPicker/currencyPicker/currencyPicker";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";

class PayoneerHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Row>
        <Form.Group as={Col} md="4" className={"color-red"}>
          <Form.Label style={{ textTransform: "capitalize" }}>
            {this.props.t("purchaser")}:<span className={"color-red"}>*</span>
          </Form.Label>
          <Form.Control
            required
            as="select"
            onChange={this.purchaserSelected.bind(this)}
            defaultValue={this.props.purchaser_id}
          >
            <option value={-1}>Select...</option>
            {this.props.purchasers.map((item, index) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <PaymentCountryPicker
          {...this.props}
          required={true}
          selected={this.props.paymentMethodRed && { value: this.props.paymentMethodRed.country }}
        />

        <CurrencyPicker
          {...this.props}
          selected={this.props.paymentMethodRed && this.props.paymentMethodRed.currency_code}
        />

        <Form.Group as={Col} md="4">
          <Form.Label>
            {this.props.t("payoneer.payeeType")}:<span className={"color-red"}>*</span>
          </Form.Label>
          <Form.Control
            required
            as="select"
            value={this.props.paymentMethodRed.payee_type}
            onChange={(e) => this.props.changePayeeType(e.target.value)}
          >
            <option value={"COMPANY"}>{this.props.t("company")}</option>
            <option value={"INDIVIDUAL"}>{this.props.t("individual")}</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">{this.props.t("validation.payeeType")}</Form.Control.Feedback>
        </Form.Group>
      </Row>
    );
  }

  purchaserSelected = (event) => {
    this.props.changePurchaser({ value: event.target.value > 0 ? event.target.value : null });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    purchaser_id: state.paymentMethodRed.purchaser_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePurchaser: (payload) => {
      dispatch({ type: actionType.CHANGE_PURCHASER, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PayoneerHeader));
