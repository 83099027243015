import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./attachmentCarousel.module.css";
import _ from "lodash";

type PreviewComponentPropType = {
  filePath: string;
  fileName: string;
  renderFullSize?: boolean;
  regularContainerHeight?: string;
  displayLoadingError?: boolean;
};

export const getFileType = (filename: string | undefined): string => {
  type fileExtensionType = [{ image: string[] }, { pdf: string[] }, { excel: string[] }, { word: string[] }];

  if (filename !== undefined) {
    const fileExtension = filename.slice(filename.lastIndexOf(".") + 1).toLowerCase();

    const fileExtensions: fileExtensionType = [
      { image: ["jpg", "jpeg", "png", "gif", "svg", "webp"] },
      { pdf: ["pdf"] },
      { excel: ["xls", "xlsx"] },
      { word: ["docx", "doc"] },
    ];

    for (const fileType of fileExtensions) {
      const [key, value] = Object.entries(fileType)[0];
      if (value.includes(fileExtension.toLowerCase())) {
        return key;
      }
    }
  }

  return "unsupported";
};

const AttachmentCarouselPreviewComponent = ({
  filePath,
  fileName,
  renderFullSize,
  regularContainerHeight = "300px",
  displayLoadingError = false,
}: PreviewComponentPropType) => {
  const fileType: string = getFileType(fileName);
  const { t } = useTranslation();

  if (displayLoadingError) {
    return (
      <div className={style.attachmentErrorTextContainer} style={{ height: regularContainerHeight }} key={filePath}>
        {t("serialAttachments.notFound", { name: fileName })}
      </div>
    );
  }

  return (
    <>
      {fileType === "image" && !renderFullSize && (
        <div style={{ marginLeft: "auto", marginRight: "auto", height: regularContainerHeight }}>
          <object
            key={filePath}
            style={{ maxHeight: regularContainerHeight, objectFit: "cover", width: "100%" }}
            data={filePath}
            type="image/png"
            aria-label="image"
          />
        </div>
      )}
      {fileType === "image" && renderFullSize && (
        <div style={{ height: "100%", overflow: "auto", margin: "auto" }}>
          <div
            style={{
              objectFit: "cover",
              height: regularContainerHeight,
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <object key={filePath} data={filePath} type="image/png" aria-label="image" />
          </div>
        </div>
      )}
      {fileType === "pdf" && (
        <div style={{ width: "100%", height: renderFullSize ? "100%" : regularContainerHeight }}>
          <object
            style={{
              width: "100%",
              outline: "solid 1px black",
              height: renderFullSize ? "100%" : regularContainerHeight,
            }}
            key={filePath}
            data={filePath}
            type="application/pdf"
            aria-label="pdf"
          />
        </div>
      )}
      {(fileType === "excel" || fileType === "word") && (
        <iframe
          key={filePath}
          style={{ width: "100%", height: renderFullSize ? "100%" : regularContainerHeight }}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(filePath)}`}
          title={`${_.startCase(fileType)} Preview`}
        />
      )}
      {fileType === "unsupported" && (
        <div
          style={{
            textAlign: "center",
            width: "100%",
            height: regularContainerHeight,
            fontWeight: "bold",
            paddingTop: "24px",
          }}
        >
          {t("serialAttachments.notSupported")}
        </div>
      )}
    </>
  );
};

export default AttachmentCarouselPreviewComponent;
