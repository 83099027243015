import React from "react";
import { ReactNode } from "react";
import { PolicyTypes } from "services/admin/policy/policyTypes";
import { IDType } from "services/common/types/common.type";
import MultiplePicker from "./multiplePicker";
import SinglePicker from "./singlePicker";

export type PolicyPickerProps = {
  className?: string;
  label?: string;
  input: {
    name: string;
    value: any;
    onChange: (value: any) => void;
  };
  meta: {
    touched: boolean;
    error: string | undefined;
    warning: string | undefined;
  };
  disabled?: boolean;
  isMulti?: boolean;
  modelData?: any;
  modelName?: "expense";
  callBackObj?: (policy: PolicyTypes.PolicyLKItem) => void;
  callBack?: (policyId: IDType) => void;
  required?: boolean;
  tooltip?: ReactNode;
};

const PolicyPicker = (props: PolicyPickerProps) => {
  const { isMulti } = props;
  return (
    <>
      {isMulti && <MultiplePicker {...props} />}
      {!isMulti && <SinglePicker {...props} />}
    </>
  );
};

export default PolicyPicker;
