import { AxiosResponse } from "axios";
import { Mandatory } from "components/forms/bootstrapFields";
import { restApiService } from "providers/restApi";
import React, { ReactNode, useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { onBlurSingle } from "services/general/helpers";
import useIsMounted from "components/common/hooks/useIsMounted";
import _ from "lodash";
import useShowSelectMenu from "components/admin/hooks/useShowSelectMenu";
import PickerErrorBlock from "../pickerErrorBlock";
import { WrappedFieldProps } from "redux-form";

type VendorSearchPickerPropsType = {
  label?: ReactNode;
  tooltip?: ReactNode;
  input?: any;
  placeholder?: string;
  disabled?: string;
  required?: boolean;
  instanceId?: string;
  parentDivId?: string;
  parentDivVariance?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  menuAutoFixed?: boolean;
} & WrappedFieldProps;

const VendorSearchPicker = ({
  label,
  tooltip,
  input,
  placeholder = "-- Search Vendor --",
  disabled,
  required,
  instanceId = "vendor-searcher",
  parentDivId,
  parentDivVariance,
  menuAutoFixed,
  meta: { touched, error },
}: VendorSearchPickerPropsType) => {
  const [vendors, setVendors] = useState();
  const mounted = useIsMounted();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);

  useShowSelectMenu({
    setIsMenuOpen,
    selectRef,
    parentDivId,
    variance: parentDivVariance,
  });

  const parseForSelect = (options: any) => {
    return options.map((option: any) => {
      return {
        label: option.name,
        value: option.id,
        ...option,
      };
    });
  };

  const getVendors = async () => {
    try {
      const response: AxiosResponse = await restApiService.get("vendors.lk", null, true, true);
      if (response.data && mounted.current) {
        let list = parseForSelect(response.data);
        if (!required) {
          list = [{ label: "-- Select --", value: "" }, ...list];
        }
        setVendors(list);
      }
    } catch (error) {
      throw error;
    }
  };

  const onChange = (option: any) => {
    if (!option) {
      input.onChange(null);
    }
    input.onChange(option);
  };

  const findSelectedSingle = () => {
    let selectedValues = null; //set to null because pickers will have blank default value instead of "select... placeholder"
    const obj = _.find(vendors, (option: any) => option.value === input.value.id);
    if (obj) selectedValues = obj;
    return selectedValues ? selectedValues : null;
  };

  useEffect(() => {
    getVendors();
  }, []);

  return (
    <Form.Group ref={selectRef}>
      <Select
        {...input}
        {...(menuAutoFixed ? { menuPlacement: "auto", menuPosition: "fixed" } : {})}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        required={required}
        value={findSelectedSingle()}
        placeholder={placeholder}
        onChange={(value) => onChange(value)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={vendors}
        classNamePrefix="select"
        isClearable={!required}
        isDisabled={disabled}
        instanceId={instanceId}
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default VendorSearchPicker;
