import React, { memo } from "react";
import commonService from "services/common/commonSvc";
import AccountPickerLookup from "./accountPickerLookup";
import { AccountPickerTypes } from "./accountPickerTypes";
import SingleAccountPicker from "./singlePicker";

const AccountPicker = (props: AccountPickerTypes.TAccountPickerProps) => {
  const { parentObj, modelData } = commonService.modifyParentModalDataRefStr(props);
  return props.templateStyle === "advance" ? (
    <AccountPickerLookup {...props} parentObj={parentObj} modelData={modelData} />
  ) : (
    <SingleAccountPicker {...props} parentObj={parentObj} modelData={modelData} />
  );
};

export default memo(AccountPicker);
