import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { IDType } from "services/common/types/common.type";
import { CommonType } from "./commonTypes";

export class CommonApis {
  static async getLocations(params: any) {
    try {
      const response: AxiosResponse = await restApiService.get("locations.lk", params, null, true, null, false);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getDepartmentList(filter: any) {
    try {
      const result: AxiosResponse<CommonType.DepartmentType[]> = await restApiService.get(
        "departments",
        filter,
        null,
        true,
        null,
        false,
      );
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  //TODO: It is recommended to move this method to its respective API service
  static async getContact(id: IDType) {
    try {
      const response: AxiosResponse = await restApiService.get("contacts/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //TODO: It is recommended to move this method to its respective API service
  static async getSubsidiaries(params: any) {
    try {
      const response: AxiosResponse = await restApiService.get("subsidiaries", params, null, true, null, false);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getContactsLk(params: any) {
    try {
      const response: AxiosResponse<CommonType.ContactLkType[]> = await restApiService.get(
        "contacts.lk",
        params,
        null,
        true,
        null,
        false,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getRebatesLk(params: any, cache: boolean = true) {
    try {
      const response: AxiosResponse<CommonType.RebateType[]> = await restApiService.get(
        "rebates.lk",
        params,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getRebate(id: IDType) {
    try {
      const response: AxiosResponse<CommonType.RebateType> = await restApiService.get("rebates/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getExchangeRate(params: any) {
    try {
      const response: AxiosResponse<CommonType.ExchangeRateType> = await restApiService.get(
        "company/exchange_rate",
        params,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getVendor(id: number) {
    try {
      const response: AxiosResponse = await restApiService.get("vendors/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async getDetails<T>(fetchUrl: string, id: IDType) {
    try {
      const response: AxiosResponse<T> = await restApiService.get(fetchUrl + "/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getAddress(id: number) {
    try {
      const response: AxiosResponse = await restApiService.get("addresses/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
