import React from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import DelegationFields from "../../delegationFields/delegationFields";
import { DelegationType } from "../../../../services/common/contact/contactTypes";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import i18next from "i18next";

export const USER_DELEGATION_FORM = "USER_DELEGATION_FORM";

export type UserDelegationFormDataType = {
  delegator_links_attributes: DelegationType[];
};

interface DelegationFormPropType extends InjectedFormProps<UserDelegationFormDataType> {}

const DelegationFormComponent = ({ handleSubmit }: DelegationFormPropType) => {
  const { t } = useTranslation();

  return (
    <Form name={USER_DELEGATION_FORM} noValidate onSubmit={handleSubmit}>
      <DelegationFields formName={USER_DELEGATION_FORM} formFieldName="delegator_links_attributes" />
      <Row>
        <Col md={{ offset: 10 }}>
          <Button variant="primary" type="submit" className="px-5">
            {t("update")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const DelegationForm = reduxForm<UserDelegationFormDataType>({
  form: USER_DELEGATION_FORM,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
  validate(formData: any) {
    const errors: any = {
      delegator_links_attributes: [],
    };
    const validateEndDate = (startDate: string, endDate: string) => {
      if (startDate && endDate && new Date(endDate) <= new Date(startDate)) {
        return false;
      }
      return true;
    };

    if (_.isArray(formData?.delegator_links_attributes)) {
      errors.delegator_links_attributes = formData.delegator_links_attributes.map((delegation: any) => {
        const isEndDateValid = validateEndDate(delegation.start_date, delegation.end_date);
        const delegationError: any = {};
        if (!isEndDateValid) {
          delegationError.end_date = i18next.t("admin.pages.userProfile.endDateValidation");
        }
        return delegationError;
      });
    }

    return errors;
  },
})(DelegationFormComponent);

export default DelegationForm;
