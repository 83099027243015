import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { required } from "../../../../../services/validations/reduxFormValidation";
import { AccountNumberValidator } from "../../accountNumberValidator";

const TwMyr = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <Col md="4">
        <Field
          name={`${modelName}.swiftCode`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.beneficiaryBankSwiftCode")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="4">
        <AccountNumberValidator modelName={`${modelName}`} noValidate={true} minLen={6} maxLength={20} />
      </Col>
    </>
  );
};

export default TwMyr;
