import React, { useEffect, useRef } from "react";

type HTMLRendererPropsType = {
  html: string;
};

const HTMLRenderer = ({ html }: HTMLRendererPropsType) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      let shadowRoot = containerRef.current.shadowRoot;
      if (!shadowRoot) {
        // If shadow root doesn't exist, create a new one
        shadowRoot = containerRef.current.attachShadow({ mode: "open" });
      }
      // Remove existing HTML content from the shadow root
      if (shadowRoot.firstChild) {
        shadowRoot.firstChild.remove();
      }
      // Create and append the new HTML content
      const div = document.createElement("div");
      div.id = "html-render";
      div.innerHTML = html;
      shadowRoot.appendChild(div);
    }
  }, [html]);

  return <div ref={containerRef} />;
};

export default HTMLRenderer;
