import React, { Component } from "react";

export default class CustomPinnedRowRenderer extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }
  render() {
    console.log(this.props.getValue());
    console.log(this.props);
    return <span> Total</span>;
  }
}
