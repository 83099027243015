import { RenderField } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const CambrideAccountId = ({ label, col, isAccountIdRequired, validations }) => {
  return (
    <Col md={col ? col : "6"}>
      <Field
        id="form.cambridge.account_id"
        name="form.cambridge.account_id"
        component={RenderField}
        type="text"
        label={label}
        required={isAccountIdRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default CambrideAccountId;
