import React, { useState } from "react";
import _ from "lodash";
import { GrAttachment } from "react-icons/gr";
import AttachmentPreviewer from "../../../common/attachmentPreview/AttachmentPreviewer";
import style from "./email.module.css";
import { AssetType, IAttachment } from "../../../../services/common/types/common.type";
import { Col, Row } from "react-bootstrap";
import { restApiService } from "../../../../providers/restApi";

export type getLinkedAttachmentsPropsType = {
  email: any;
};

const GetLinkedAttachments = ({ email }: getLinkedAttachmentsPropsType) => {
  const [showPreviewer, setShowPreviewer] = useState(false);
  const [selected, setSelected] = useState<AssetType>();

  const openPreview = (attachment: any) => {
    if (_.isArray(email.data) && email.data.length > 0) {
      let selectedRecord = _.find(email.data, (option) => option.filename == attachment.filename);
      if (!selectedRecord) {
        selectedRecord = {};
      }
      console.log("selectedRecord ", selectedRecord);
      selectedRecord.asset_expiring_url = attachment.asset_expiring_url;
      selectedRecord.linkLable = "Linked to Invoice: ";
      setSelected(selectedRecord);
      setShowPreviewer(true);
    } else {
      setSelected(attachment);
      setShowPreviewer(true);
    }
  };

  return (
    <>
      {showPreviewer && selected && (
        <AttachmentPreviewer file={selected} isVisible={showPreviewer} closePreviewer={() => setShowPreviewer(false)} />
      )}
      {_.isPlainObject(email) &&
        _.isArray(email.attachments) &&
        email.attachments.map((attachment: any) => (
          <div key={attachment.key}>
            <div role="button" onClick={() => openPreview(attachment)} className={style.link}>
              <GrAttachment className={"pr-1"} />
              {attachment.filename}
            </div>
          </div>
        ))}
    </>
  );
};

export default GetLinkedAttachments;

export type attachmentInvoiceLinkPropsType = {
  data: {
    number: string;
    id: number;
    filename: string;
    type: string;
    status: string;
    href: string;
    hasNoPermission?: boolean;
  }[];
  attachment: IAttachment | undefined;
};
export const AttachmentInvoiceLink = ({ data, attachment }: attachmentInvoiceLinkPropsType) => {
  if (!attachment) {
    return null;
  }

  const file = _.find(data, (option) => option.filename === attachment.filename);
  if (!file) {
    return null;
  }

  let fileWithUrl = {
    ...file,
    linkLable: "Linked to Invoice:",
    asset_expiring_url: attachment.asset_expiring_url,
  };

  return (
    <Row>
      <Col md="12">
        <div className="d-flex justify-content-end m-3">
          <span>{fileWithUrl.linkLable}</span>
          <a
            href={restApiService.angularBaseURL() + fileWithUrl.href}
            className={style.link + " " + (fileWithUrl.hasNoPermission ? style.disabledLink : "")}
            target="_blank" rel="noreferrer"
          >
            {fileWithUrl.number}
          </a>
        </div>
      </Col>
    </Row>
  );
};
