import _ from "lodash";
import React from "react";
import CeTokenBased from "./../fields/authenticationFields/ceTokenBased";
import CorpayTokenBased from "./../fields/authenticationFields/corpayTokenBased";
import ExpensifyTokenBased from "./../fields/authenticationFields/expensifyTokenBased";
import NsTokenBased from "./../fields/authenticationFields/nsTokenBased";
import TbTokenBased from "./../fields/authenticationFields/tbTokenBased";
import TokenBased from "./../fields/authenticationFields/tokenBased";
import TwTokenBased from "./../fields/authenticationFields/twTokenBased";
import XeroTokenBased from "./../fields/authenticationFields/xeroTokenBased";

import { Manual_authentication_systems } from "lookups/integrationsLookups";
import { useSelector } from "react-redux";
import { getFormValues } from "redux-form";
import { INTEGRATION_FORM_NAME } from "../../../../../../reducers/admin/integrationsReducer";
import ManualAuthentication from "../../templates/manualAuthentication";
import SVBMasterCardCredential from "../fields/authenticationFields/svbMasterCardCredential";
import UsBankCredential from "../fields/authenticationFields/usBankCredential";
import NonUsBankCredential from "./../fields/authenticationFields/nonUsBankCredential";
import NonUsBankNotPayoneerCredential from "./../fields/authenticationFields/nonUsBankNotPayoneerCredential";
import PayoneerCredential from "./../fields/authenticationFields/payoneerCredential";
import VirtualCardCredential from "./../fields/authenticationFields/virtualCardCredential";
import BlackBaudTokenBased from "./../fields/authenticationFields/xeroTokenBased";

const Credential = () => {
  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const isManualAuthentication =
    _.isArray(Manual_authentication_systems) &&
    Manual_authentication_systems.includes(form.system_name) &&
    form.application_type == "AUTHENTICATION" &&
    form.authentication_method == "CREDENTIAL";

  return (
    <>
      {form.system_name === "US Bank" && (
        <>
          <UsBankCredential />
        </>
      )}
      {form.system_name !== "US Bank" && !isManualAuthentication && <NonUsBankCredential />}

      {form.system_name !== "US Bank" && form.application_type !== "PAYONEER" && !isManualAuthentication && (
        <NonUsBankNotPayoneerCredential />
      )}
      {form.application_type === "PAYONEER" && <PayoneerCredential />}
      {form.application_type === "VIRTUAL_CARD" && <VirtualCardCredential />}
      {form.system_name === "SVB Master Card" && form.application_type === "VIRTUAL_CARD" && (
        <SVBMasterCardCredential />
      )}
      {isManualAuthentication && <ManualAuthentication />}
    </>
  );
};

const AuthenticationTemplate = {
  CE_TOKEN_BASED: CeTokenBased,
  EXPENSIFY_TOKEN_BASED: ExpensifyTokenBased,
  QB_TOKEN_BASED: TbTokenBased,
  XERO_TOKEN_BASED: XeroTokenBased,
  BLACK_BAUD_TOKEN_BASED: BlackBaudTokenBased,
  NS_TOKEN_BASED: NsTokenBased,
  TOKEN_BASED: TokenBased,
  TW_TOKEN_BASED: TwTokenBased,
  CREDENTIAL: Credential,
  CORPAY_TOKEN_BASED: CorpayTokenBased,
  ManualAuthentication,
};

const AuthenticationMethod = () => {
  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const AuthTemplate = AuthenticationTemplate[form.authentication_method];
  return <>{AuthTemplate ? <AuthTemplate /> : ""}</>;
};

export default AuthenticationMethod;
