import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import CardProgramPicker from "../../../../../../components/pickers/reduxFormPicker/cardProgramPicker";
import { required } from "../../../../../../services/validations/reduxFormValidation";

const CcPaymentIntegrationFields = () => {
  return (
    <>
      <Col lg="6">
        <Field
          name="form.card_program_id"
          label={"Card Program"}
          component={CardProgramPicker}
          validate={[required]}
          required={true}
        />
      </Col>
    </>
  );
};

export default CcPaymentIntegrationFields;
