import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Select from "react-select";
import {
  compare,
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../../services/general/helpers";
import _ from "lodash";
import { IDType } from "../../../../../services/common/types/common.type";
import useIsMounted from "components/common/hooks/useIsMounted";

type SelectedTypes = {
  value: IDType;
  label: string;
};

type InputType = {
  value: number;
  onChange: (values: number) => void;
  onBlur: (values: number) => void;
};

export type PaymentRunGroupingPickerPropsType = {
  className?: string | undefined;
  label?: string | undefined;
  isMulti?: boolean;
  tooltip?: string | undefined;
  required?: boolean;
  placeholder?: string | undefined;
  input: InputType;
  instanceId?: string;
  disabled?: boolean;
  callBack?: (selected: SelectedTypes) => void;
  isClearable?: boolean;
};

const PaymentRunGroupingPicker = ({
  label,
  input,
  required,
  isMulti,
  tooltip,
  callBack,
  disabled,
  isClearable,
  instanceId = "payment-run-grouping-picker",
}: PaymentRunGroupingPickerPropsType) => {
  const isMounted = useIsMounted();

  const paymentRunGroupingOptions = [
    { label: "Subsidiary", value: "subsidiary" },
    { label: "Department", value: "department" },
    { label: "Project", value: "project" },
    { label: "Business Unit", value: "business_unit" },
    { label: "Location", value: "location" },
    { label: "Vendor", value: "vendor" },
  ];

  const parseOptions = (options: any) => {
    return options
      .map((option: any) => {
        return {
          value: option.id,
          label: option.name,
          ...option,
        };
      })
      .sort(compare);
  };

  const onChangePaymentRunGroupings = (input: any, selected: any) => {
    onChange(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  const onChangePaymentRunGrouping = (input: InputType, selected: SelectedTypes) => {
    onChangeSingle(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          isMulti={isMulti ? true : false}
          required={required}
          options={paymentRunGroupingOptions}
          isDisabled={disabled}
          value={
            isMulti
              ? findSelectedMultiple(input, paymentRunGroupingOptions)
              : findSelectedSingle(input, paymentRunGroupingOptions)
          }
          onChange={
            isMulti
              ? (selected: SelectedTypes[]) => onChangePaymentRunGroupings(input, selected)
              : (selected: SelectedTypes) => onChangePaymentRunGrouping(input, selected)
          }
          onBlur={isMulti ? () => onBlur(input, input.value) : () => onBlurSingle(input, input.value)}
          isClearable={isClearable}
          instanceId={instanceId} // don't remove or change used in automation testing
        ></Select>
      </Form.Group>
    </>
  );
};
export default PaymentRunGroupingPicker;
