import moment from "moment";
import { translate } from "../../../services/general/translation";
import { formatDateSvc } from "../../../services/date/date";
import { useTypedSelector } from "reducers";
import { CompanyCurrencyCodesType } from "services/common/company/companyTypes";
import { companyDateFormat } from "services/general/dateSvc";

const draftExpenseItemHeader = ({ currentUser }) => {
  return [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      maxWidth: 80,
      headerValueGetter: function () {
        return translate("id");
      },
    },
    {
      field: "number",
      headerName: "Number",
      cellRenderer: "childMessageRenderer",
      cellStyle: {
        color: "#FF5C35",
        fontFamily: "acre-regular",
        fontSize: 14,
        fontWeight: "bold",
      },
      cellRendererParams: {
        editLink: "/ap/expenseItems/draft/",
        editFromLink: true,
      },
    },
    {
      field: "purchase_order.number",
      headerName: "PO Number",
      cellRendererParams: {
        viewLink: "/ar/purchase_orders/",
      },
      headerValueGetter: function () {
        return translate("poNumber");
      },
    },
    {
      field: "vendor_name",
      headerName: "Merchant",
      headerValueGetter: function () {
        return translate("merchant");
      },
    },
    {
      field: "date",
      headerName: "Date",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          return formatDateSvc(filterLocalDateAtMidnight, cellValue);
        },
        browserDatePicker: true,
      },
      cellRenderer: (params) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("date");
      },
    },
    {
      field: "policy.name",
      headerName: "Policy",
      headerValueGetter: function () {
        return translate("policy");
      },
    },
    {
      field: "category.name",
      headerName: "Category",
      headerValueGetter: function () {
        return translate("category");
      },
    },
    {
      field: "item_type",
      headerName: "Item Type",
      filter: "agTextColumnFilter",
      filterParams: {
        options: [
          {
            label: "SINGLE_EXPENSE",
            value: "SINGLE_EXPENSE",
          },
          {
            label: "MILEAGE",
            value: "MILEAGE",
          },
          {
            label: "PER DIEM",
            value: "PER_DIEM",
          },
        ],

        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "floatingFilterComponent",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      headerValueGetter: function () {
        return translate("item_type");
      },
    },
    {
      field: "submitter.name",
      headerName: "Submitter",
      headerValueGetter: function () {
        return translate("submitter");
      },
    },
    {
      field: "employee",
      headerName: "Employee",
      valueGetter: (params) => {
        if (params && params.data && params.data.employee) {
          let employee = params.data.employee;
          return employee.first_name + " " + employee.last_name;
        }
      },
      hide: true,
      headerValueGetter: function () {
        return translate("employee");
      },
    },
    {
      cellRenderer: "childMessageRenderer",
      sortable: false,
      resizable: false,
      width: 120,
      maxWidth: 120,
      cellRendererParams: {
        editLink: "/ap/expenseItems/draft/",
      },
      cellRendererSelector: function (params) {
        return;
      },
      headerValueGetter: function () {
        return translate("actions");
      },
    },
  ];
};

export default draftExpenseItemHeader;
