import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTypedSelector } from "../../../../reducers";
import { POItemsFooterProps } from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrders.types";
import Styles from "../../../../pages/admin/purchaseOrders/changeOrders/purchaseOrders.module.css";
import { currencySymbolRenderer } from "../../../../services/common/currencySymbolRendererService";
import changeOrderSvc from "../../../../services/admin/changeOrders/changeOrderSvc";

const { maxWidthPoItemIcons, addFieldPOButton } = Styles;

export const POItemsFooter = ({ formServiceRef, editIndexRef }: POItemsFooterProps) => {
  const formService = formServiceRef.current;
  const fieldService = formService.fieldService;
  const status = useTypedSelector((state) => formService.getStatus(state));
  const currencyCode = useTypedSelector((state) => formService.getCurrencyCode(state));
  const poItemsTotal = useTypedSelector((state) => formServiceRef.current.getPOItemsTotal(state));
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const handleAdd = useCallback(() => {
    editIndexRef.current = fieldService.handleAddField();
  }, []);

  useEffect(() => {
    const symbol = currencySymbolRenderer(currencyCode);
    setCurrencySymbol(symbol);
    fieldService.setCurrencySymbol(symbol);
  }, [currencyCode]);

  return (
    <Row className="m-0 pt-3 justify-content-between">
      <Col className="p-0">
        <Row className="m-0 justify-content-between align-items-center">
          <Col className="p-0">
            <Button
              className={addFieldPOButton}
              disabled={Boolean(changeOrderSvc.disableEdits(status))}
              onClick={handleAdd}
            >
              + Add Items
            </Button>
          </Col>
          <Col className="p-0">
            <Row className="m-0 justify-content-end">
              <Col className="text-nowrap font-weight-bold flex-grow-0">Items Total Amount</Col>
              <Col className="font-weight-bold flex-grow-0">
                {currencySymbol}
                {poItemsTotal.toFixed(2)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className={`py-2 ${maxWidthPoItemIcons}`}></Col>
    </Row>
  );
};
