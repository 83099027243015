import moment from "moment";
import { translate } from "../../../services/general/translation";
import { companyDateFormat } from "services/general/dateSvc";
const fraudAuditsHeader = (currentUser) => {
  var dataParse = function (input) {
    if (input.data) {
      return JSON.stringify(input.data.data);
    }
  };
  var resultParse = function (input) {
    if (input.data) {
      return JSON.stringify(input.data.result);
    }
  };
  return [
    {
      field: "id",
      headerName: "ID",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: "randomChildCellRouting",
      cellStyle: {
        color: "#FF5C35",
        fontFamily: "acre-regular",
        fontSize: 14,
        fontWeight: "bold",
      },
      cellRendererParams: {
        viewLink: "/ap/fraud_audits",
      },
      headerValueGetter: function () {
        return translate("id");
      },
    },
    {
      field: "action",
      headerName: "Action",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("action");
      },
    },
    {
      field: "object_id",
      headerName: "Object Id",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("object_id");
      },
    },
    {
      field: "object_type",
      headerName: "Object Type",
      filter: "agTextColumnFilter",
      filterParams: {
        // clearButton: true,
        // applyButton: true,
        // debounceMs: 200,
        options: [
          {
            label: "ALL",
            value: "",
          },
          {
            label: "Payment Method",
            value: "PaymentMethod",
          },
          {
            label: "WARNING",
            value: "WARNING",
          },
        ],

        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "floatingFilterComponent",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      headerValueGetter: function () {
        return translate("object_type");
      },
    },
    {
      field: "data",
      valueGetter: dataParse,
      headerName: "Data",
      headerValueGetter: function () {
        return translate("data");
      },
    },
    {
      field: "result",
      valueGetter: resultParse,
      headerName: "Result",
      headerValueGetter: function () {
        return translate("result");
      },
    },
    // {
    //   field: 'company.name',
    //   headerName: 'Company',
    //   headerValueGetter: function(){ return translate("company")}
    // },
    {
      field: "created_at",
      headerName: "Created At",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("created_at");
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellStyle: function (params) {
        if (params.value == "FAILED") {
          return { color: "red" };
        } else if (params.value == "SUCCESS") {
          return { color: "green" };
        } else if (params.value == "WARNING") {
          return { color: "orange" };
        }
      },
      filter: "agTextColumnFilter",
      filterParams: {
        // clearButton: true,
        // applyButton: true,
        // debounceMs: 200,
        options: [
          {
            label: "ALL",
            value: "",
          },
          {
            label: "SUCCESS",
            value: "SUCCESS",
          },
          {
            label: "WARNING",
            value: "WARNING",
          },
          {
            label: "FAILED",
            value: "FAILED",
          },
        ],

        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "floatingFilterComponent",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      headerValueGetter: function () {
        return translate("status");
      },
    },
  ];
};

export default fraudAuditsHeader;
