import React from "react";
import { shallowEqual } from "react-redux";
import { useTypedSelector } from "reducers";
import { TabNavigation } from "wombatifier/components/navigation/tabNavigator";

//NOTE: add only those route which we want to show on tabNavigations
const cardModuleRoutes = (currentUser: any) => [
  {
    href: "vcards_dashboard",
    pageName: "Dashboard",
    authorization: {
      permissions: ["listVCards"],
    },
  },
  {
    href: "vcards",
    pageName: "All Cards",
    authorization: {
      permissions: ["virtual_cards_active", "listVCards"],
    },
  },
  {
    href: "po_requiring_vcards",
    pageName: "POs Requiring Cards",
    authorization: {
      permissions: ["editVCards", "PORequiringVCards"],
    },
  },
  {
    href: "vcard_transactions",
    pageName: "Transactions",
    authorization: {
      permissions: ["virtual_cards_active", "listVCardTransactions"],
    },
  },
  {
    href: "statements?statementType=card",
    pageName: "Statements",
  },
  {
    path: "/ap/cards/rebates",
    pageName: "Rebates",
    authorization: {
      permissions: ["addVCards"],
    },
  },
  {
    href: "vcards/card_program_settings/",
    pageName: "Card Program Settings",
    authorization: {
      permissions: ["listVCards", "corporateCardsDashboard"],
    },
  },
  {
    path: "/ap/cards/payments",
    pageName: "Payments",
    hide: !currentUser?.company?.virtual_card?.show_card_admins_card_payment_tab,
    authorization: {
      permissions: ["addVCards"],
    },
  },
];

export const NavTabs = ({ activePageName }: { activePageName: string }) => {
  const currentUser = useTypedSelector((state) => state.user, shallowEqual);
  return <TabNavigation activePageName={activePageName} routes={cardModuleRoutes(currentUser)} />;
};
