import AddSubsidiary from "./add";
import DetailSubsidiary from "./details";
import EditSubsidiary from "./edit";
import ListSubsidiary from "./list";

import EditFormOld from "./editFormOld"; //TODO: Import for the legacy form, to be removed when the new subsidiary form gets stable.
import AddFormOld from "./addFormOld"; //TODO: Import for the legacy form, to be removed when the new subsidiary form gets stable.

const SubsidiaryRoutes = [
  {
    path: "/ap/subsidiaries",
    exact: true,
    name: "Subsidiaries List",
    component: ListSubsidiary,
    authorization: {
      I: "list",
      a: "Subsidiaries",
    },
  },
  {
    path: "/ap/subsidiaries/add",
    exact: true,
    name: "Add Subsidiary",
    component: AddSubsidiary,
    authorization: {
      I: "add",
      a: "Subsidiaries",
    },
  },
  {
    path: "/ap/subsidiaries/:id/edit",
    exact: true,
    name: "Edit Subsidiary",
    component: EditSubsidiary,
    authorization: {
      I: "edit",
      a: "Subsidiaries",
    },
  },
  {
    path: "/ap/subsidiaries/:id(\\d+)", //it indicates that the parameter must be a numeric value (digits)
    exact: true,
    name: "Subsidiaries Details",
    component: DetailSubsidiary,
    authorization: {
      I: "read",
      a: "Subsidiaries",
    },
  },
  // Creating separate routes for add and edit form for the legacy form.
  // to be removed when the new subsidiary form gets stable.
  {
    path: "/ap/subsidiaries/add/old",
    exact: true,
    name: "Add Subsidiary",
    component: AddFormOld,
    authorization: {
      I: "add",
      a: "Subsidiaries",
    },
  },
  {
    path: "/ap/subsidiaries/:id/edit/old",
    exact: true,
    name: "Edit Subsidiary",
    component: EditFormOld,
    authorization: {
      I: "edit",
      a: "Subsidiaries",
    },
  },
];

export default SubsidiaryRoutes;
