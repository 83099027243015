import { PDFDocument } from "pdf-lib";
import { restApiService } from "providers/restApi";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { QueueInvoiceDataType } from "./invoiceQueue.type";

export const queueInvoicesFilter = {
  sort: { id: { direction: "desc", priority: "0" } },
  submit_method: "USER_SUBMITTED",
  status: "NEW",
  include_filename: true,
  items: 100, // Hack until we have pagination
};

export const getFile = (bytes: Uint8Array, filename: string): File => {
  if (!(bytes instanceof Uint8Array)) {
    throw new Error("Invalid file data provided.");
  }

  const safeFilename = filename && filename.trim() !== "" ? filename : "invoice.pdf";
  return new File([bytes], safeFilename, { type: "application/pdf" });
};

export const generateRandomId = () => {
  return Math.floor(Math.random() * 10000000);
};

export const handleError = (error: any, t: any, message: string | undefined = undefined) => {
  console.error(error);
  const errorMsg = message || t("errors.genericSupport");
  const errorString = error.response?.data
    ? Object.entries(error.response.data)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n")
    : errorMsg;
  CreateNotification(t("error"), errorString, NotificationType.danger);
};

export const createQueueInvoiceWithDetails = (
  qInvoice: QueueInvoiceDataType,
  invoice: InvoiceType.InvoiceDetailType,
): QueueInvoiceDataType => {
  return {
    ...qInvoice,
    invoice: invoice,
    url: invoice.assets?.[0]?.asset_expiring_url,
    filename: invoice.assets?.[0]?.asset_file_file_name,
    noAssets: !invoice.assets || invoice.assets.length === 0,
  };
};

export const attachFileToFormData = (invoice: InvoiceType.InvoiceDetailType, file: File) => {
  if (invoice == null || file == null) return null;

  const formData = new FormData();

  let index = 0;
  invoice.assets?.forEach((asset) => {
    if (asset._destroy) {
      formData.append(`invoice[assets_attributes[${index}]id]`, asset.id);
      formData.append(`invoice[assets_attributes[${index}]_destroy]`, asset._destroy.toString());
      index++;
    }
  });

  formData.append(`invoice[assets_attributes[${index}]asset_file]`, file);
  formData.append(`invoice[assets_attributes[${index}]file_name]`, file.name);

  return formData;
};

export const deleteAndAttachFileToFormDataAsync = async (
  refetchCurrentInvoiceQuery: () => void,
  invalidateCurrentInvoicePdfCache: () => void,
  invoice: InvoiceType.InvoiceDetailType | null,
  bytes: Uint8Array,
  filename: string,
  t: any,
  ocr: boolean = true,
): Promise<void> => {
  if (!invoice || !invoice.id) {
    throw new Error("Invalid invoice data.");
  }

  const file = getFile(bytes, filename);
  if (!file) {
    throw new Error("Invalid file data.");
  }

  invoice.assets?.forEach((asset) => {
    asset._destroy = 1;
  });

  const formData = attachFileToFormData(invoice, file);

  try {
    const patchResponse = await restApiService.formWithImage(
      `invoices/${encodeURIComponent(invoice.id)}`,
      formData,
      "patch",
    );

    if (patchResponse?.status !== 200 || !patchResponse.data) {
      throw new Error("Failed to upload invoice file.");
    }

    invalidateCurrentInvoicePdfCache();
    refetchCurrentInvoiceQuery();

    if (ocr) {
      await extractDataForAssetAsync(patchResponse.data.id, patchResponse.data.number, file.name, t);
    }
  } catch (error) {
    handleError(error, t);
    throw new Error("An error occurred while uploading the file.");
  }
};

export const createExtractedFileAsync = async (bytes: Uint8Array, filename: string, t: any): Promise<void> => {
  const file = getFile(bytes, filename);
  if (!file) {
    throw new Error("Invalid file data.");
  }

  try {
    const invoice: QueueInvoiceDataType = {
      invoice: { id: generateRandomId() },
      label: file.name,
      file,
      filename: file.name,
      isLocal: true,
      isBeingOcred: false,
    };

    await createInvoiceFromFileAsync(invoice, t);
  } catch (error) {
    handleError(error, t);
    throw new Error("An error occurred while uploading the file.");
  }
};

export const extractDataForAssetAsync = async (
  id: string,
  invNumber: string,
  filename: string,
  t: any,
): Promise<void> => {
  try {
    const extractResponse = await restApiService.post(
      `invoices/${encodeURIComponent(id)}/extract_data_for_asset`,
      {},
      {},
    );

    if ([200, 201, 204].includes(extractResponse?.status)) {
      CreateNotification(
        t("success"),
        t("admin.pages.uploadQueue.uploadSuccess", { invNumber, filename }),
        NotificationType.success,
      );
    } else {
      CreateNotification(
        t("error"),
        t("admin.pages.uploadQueue.uploadError", { invNumber, filename }),
        NotificationType.danger,
      );
    }
  } catch (extractError) {
    handleError(extractError, t);
  }
};

export const createInvoiceFromFileAsync = async (
  invoiceQueue: QueueInvoiceDataType,
  t: any,
): Promise<QueueInvoiceDataType | undefined> => {
  const { invoice: localInvoiceFromFile, file } = invoiceQueue;

  if (!localInvoiceFromFile || !file) {
    handleError(new Error("Invoice data or file is missing"), t);
    return undefined;
  }

  try {
    const newInvoiceData = await InvoiceApis.getNewInvoice();
    if (!newInvoiceData) {
      throw new Error("Failed to create new invoice");
    }

    const requestedInvoice: InvoiceType.InvoiceDetailType = {
      process_pdf: true,
      number: newInvoiceData.number,
      submit_method: "USER_SUBMITTED",
      status: "NEW",
    };

    const data = await InvoiceApis.addInvoice({ invoice: requestedInvoice });
    const formData = attachFileToFormData(localInvoiceFromFile, file);
    const patchResponse = await restApiService.formWithImage(`invoices/${data.id}`, formData, "patch");

    if (patchResponse?.status !== 200 || !patchResponse.data) {
      throw new Error("Failed to upload invoice file");
    }

    await extractDataForAssetAsync(patchResponse.data.id, patchResponse.data.number, file.name, t);
    const finalInvoiceData = await InvoiceApis.getInvoice(patchResponse.data.id);

    return {
      invoice: finalInvoiceData,
      label: `${finalInvoiceData.number}`,
      isLocal: false,
    };
  } catch (error) {
    handleError(error, t);
  }
};

export async function getNewPdfFromSelectedPages(mappedCheckboxes: number[], pdfDocumentToRender: PDFDocument) {
  const pages = mappedCheckboxes.sort((a, b) => a - b);
  const extractedPdf = await PDFDocument.create();

  for (const pageNumber of pages) {
    const [extractedPage] = await extractedPdf.copyPages(pdfDocumentToRender, [pageNumber - 1]);
    extractedPdf.addPage(extractedPage);
  }

  const newPdfFromSelectedPages = await extractedPdf.save();
  return { newPdfFromSelectedPages, pages };
}

export const stopEventPropagation = (event: any) => {
  if (!event) return;
  event.preventDefault();
  event.stopPropagation();
};
