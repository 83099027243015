import React, { Component } from "react";
import RestApi from "../../../providers/restApi";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import Panel from "../../../components/panel/panel";
import { Link, withRouter } from "react-router-dom";

class VCardOnboardingTOS extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();

    this.backURL = "/ap/onboarding/vcards";
    this.item_id = "agreed_to_tos";
  }

  componentDidMount() {
    if (!this.props.onboaringState.listTemplate) {
      //cant continue as the template is not defined, go to our back url
      this.props.history.push(this.backURL);
    }
  }

  accepted = () => {
    //post the item_id to api
    this.restApiService
      .get("virtual_cards/onboarding/tos_accepted", null, {})
      .then((result) => {
        if (result.status == 200) {
          //change the redux state to reflect this item is completed
          this.props.onboaringState.listTemplate.items.forEach((element) => {
            if (element.id == this.item_id) {
              element.completed = true;
            }
          });
          this.props.setListTemplateItems(this.props.onboaringState.listTemplate.items);
          //go back to main checklist page
          this.props.history.push(this.backURL);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <Container className={"pt-4 pb-4"}>
        <Row>
          <Col>
            <Panel cardClasses={"animated fadeIn"}>
              <Row>
                <Col className="">
                  <Link to={this.backURL}>
                    <h5 className="text-right">X cancel</h5>
                  </Link>
                  <h1>Terms of Agreement</h1>
                </Col>
              </Row>
              <Row>
                <ul className={"pt-4 pb-4 ml-3 mr-3 list-unstyled"}>
                  <li className={"pb-4"}>
                    If you elect to participate in Accrualify, Inc.’s (“AI’s”) virtual card program, the following terms
                    shall also apply.
                  </li>
                  <li className={"pb-4"}>
                    As used herein, Virtual Card Solution means the virtual card payments solution offered by AI through
                    which payments by Clients to third parties are made through the Visa and or Mastercard network (the
                    “Network”) using single-use, virtual credit cards issued by Issuing Bank (“Virtual Cards”).
                  </li>
                  <ol className={"ml-4"}>
                    <li className={"pb-4"}>
                      General. If you select AI’s Virtual Card Solution, AI and partnering Banks will provide you with
                      access to one or more accounts through which it can request payments be made to third parties
                      through Virtual Cards (each a “Virtual Card Account”). All Virtual Cards used to process payments
                      shall remain the property of AI or the Issuing Bank, and must be returned or destroyed (with
                      certification of destruction) upon request. AI or the Issuing Bank may suspend, cancel, revoke, or
                      restrict the use of any or all Virtual Card Accounts or Virtual Cards at any time, and reserve the
                      right to decline to process any individual transactions.
                    </li>
                    <li className={"pb-4"}>
                      Credit Limit, if applicable. If you are approved to make payments through the Virtual Card
                      Solution, AI or Issuing Bank may establish a credit limit for your Virtual Card Account(s) and
                      allow you to make purchases on credit through Virtual Cards up to a certain amount. AI or Issuing
                      Bank, in their sole respective discretions, shall be responsible for determining the amount of any
                      such credit limit, according to their underwriting criteria and other relevant factors. Not all
                      Clients will be eligible, and AI and Issuing Bank reserve the right to reject a Client, or to
                      revoke, limit, reduce or increase a credit limit in their sole respective discretions. Any credit
                      limit established for a Client will be subject to periodic review and adjustment by AI or Issuing
                      Bank. AI shall communicate the initial amount of any approved credit limit to Client at the time
                      Client’s Virtual Card Account is approved or activated, subject to modification as noted above.
                    </li>
                    <li className={"pb-4"}>
                      Authorization. Client hereby authorizes AI, directly or through its affiliates and contractors, to
                      effect ACH Entries from the bank account(s) designated by Client (the “Virtual Card Bank
                      Accounts”) to cover the amounts due on any given day in connection with payments through Client’s
                      Virtual Card Account, including for payment of any amounts owed to AI in connection with such
                      services (the “Virtual Card Authorization”). For avoidance of doubt, the repayment term shall be a
                      daily bill, daily pay model that requires Client to pay in full the day after a payment, charge,
                      or fee is incurred or accrues. To the extent payments are made by AI by initiating an ACH debit,
                      AI reserves the right to effectuate such ACH debit to the Virtual Card Bank Accounts on the days
                      set forth above or any other subsequent business day. Client must ensure it has at all times
                      sufficient funds in its Virtual Card Bank Account to cover the amounts due on any given day in
                      connection with payments through Client’s Virtual Card Account. In all cases, Client will be
                      required to ensure its unpaid balance, including all pending or unbilled transactions, fees, and
                      other charges on the Virtual Card Account, does not exceed the established credit limit. AI may
                      require immediate payment of outstanding amounts, suspend further Virtual Card Account use, and/or
                      impose additional fees, if Client exceeds its credit limits or fails to make full or timely
                      payment on any amounts owed. This Virtual Card Authorization is to remain in full force and effect
                      until thirty (30) days after AI has received written notification from Client of termination of
                      this authorization, by email to the following address: accountmanagement@corpay, in such time and
                      manner as to afford AI a reasonable opportunity to act on the notification. Client agrees to pay
                      the reasonable costs of collection efforts undertaken with respect to any delinquent amounts
                      payable by Client or with respect to Virtual Card Services provided hereunder.
                    </li>
                    <li className={"pb-4"}>
                      Corporate Liability. Client acknowledges and agrees that Virtual Card Accounts do not follow an
                      individual liability model and Client shall be liable to AI for all payments made to Suppliers on
                      Virtual Cards.
                    </li>
                    <li className={"pb-4"}>
                      Non-Revolving. Credit extended through any Virtual Card Account is not revolving and the total
                      amount due on each periodic statement is due and payable in full by the date shown on the
                      statement. This amount includes transactions posted since the last statement date, applicable
                      fees, amounts past due, late payment charges, charges for returned checks and other applicable
                      charges.
                    </li>
                    <li className={"pb-4"}>
                      Policies governing use of the AI Payment Service by individuals authorized by Client to access or
                      use the AI Payment Service as well as unauthorized users or anyone else accessing the AI Payment
                      Service, Client audits and other general information governing how the administration of the AI
                      Payment Service (the “Policies”) are available on the AI website (www.accrualify.com) and may be
                      updated by AI from time to time in its sole discretion. Client will use AI Payment Services,
                      including the Virtual Card Accounts, only in accordance and compliance with this Agreement and the
                      Policies, and will ensure Company users are aware of this Agreement and the Policies and comply
                      with them.
                    </li>
                    <li className={"pb-4"}>
                      Disputed Items. Unless required by law, AI is not responsible for any problem Client may have with
                      any goods, services, or other items charged on the Virtual Card Account(s) or paid with using
                      other AI Payment Service. If Client has a dispute with a supplier, Client must pay AI in
                      accordance with these Priority Terms and attempt to resolve the dispute with the Supplier prior to
                      sending the dispute to Priority. If Client is unsuccessful in resolving the dispute directly with
                      the Supplier, Network partners will attempt to process the dispute to the extent it relates to a
                      Virtual Card Account subject to the Network rules, as they may be changed from time to time, but
                      does not guarantee resolution by the Network. AI is not responsible if any Supplier refuses to
                      honor any of the Network resolution services.
                    </li>
                    <li className={"pb-4"}>
                      Liability for Unauthorized Use. Except as expressly set forth in this section, Client understands
                      and agrees that Client is fully liable for the unauthorized use of the AI Payment Service,
                      including any Virtual Card Account, and all charges made and fees incurred with respect thereto.
                      Client agrees to notify AI immediately of any actual or suspected loss, theft or unauthorized use
                      of any of the Priority Services, including unauthorized or fraudulent use of any Virtual Card
                      Account or any passwords or other security codes or procedures relating to such Virtual Card
                      Account or AI Payment Service, by calling AI at 650-437-7225 or sending email to
                      accountmanagement@corpay. Client agrees to immediately inactivate any of its Virtual Card Accounts
                      that are or are suspected of being compromised or that may be or have been used without proper
                      authority or as a result of fraud. Client will not be liable for unauthorized charges on a Virtual
                      Card Account that occur after Client notifies AI and deactivates the Virtual Card Account as
                      required above. Client agrees that AI shall have the right to suspend or cancel provision of the
                      AI Payment Service, including any Virtual Card Account, after receiving notice of reported or
                      suspected unauthorized use or fraud. Unauthorized use does not include use by a person to whom
                      Client has given access or authorization to use the AI Payment Service or who is employed or
                      contracted by Client or an affiliate or who is using the systems, networks or computing devices of
                      Client or an affiliate, and Client will be liable for all use and charges by any such user or
                      person.
                    </li>
                    <li>
                      Confidential Information. The definition of Confidential Information includes Virtual Card
                      numbers. The BINS (Bank Identification Numbers) assigned to the Virtual Cards are the property of
                      the Issuing Bank. Client is solely responsible for ensuring the confidentiality of Virtual Cards,
                      account numbers, passwords, or other security codes or procedures applicable to Client’s and its
                      users’ access and use of the AI Payment Service.
                    </li>
                  </ol>
                </ul>
              </Row>
              <Row className="mt-4">
                <Col xs={6}>
                  <Link to={this.backURL}>
                    <button className="btn btn-secondary btn-block">I Do Not Accept</button>
                  </Link>
                </Col>
                <Col xs={6}>
                  <button className="btn btn-primary btn-block" type="button" onClick={this.accepted}>
                    I Accept
                  </button>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    onboaringState: state.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setListTemplateItems: (payload) => {
      dispatch({ type: actionType.SET_ONBOARDING_LIST_TEMPLATE_ITEMS, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(VCardOnboardingTOS)));
