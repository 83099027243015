import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { required } from "../../../../services/validations/reduxFormValidation";
import { paymentTypes } from "../../../app.svc.Lookup";
import { RenderSelect } from "../../../forms/bootstrapFields";

const PaymentTypeSelect = () => {
  const { t } = useTranslation();
  return (
    <Col md="6">
      <Field
        id="payment_method.payment_type"
        component={RenderSelect}
        options={paymentTypes}
        name="form.payment_type"
        type="select"
        label={t("companySettings.paymentMethodsSettings.paymentType")}
        required
        validate={[required]}
      />
    </Col>
  );
};

export default PaymentTypeSelect;
