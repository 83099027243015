import { NavTabs } from "components/admin/cards/navTabs";
import ListRebates from "components/admin/cards/rebates/listRebates";
import React from "react";
import { Row } from "react-bootstrap";

const Rebates = () => {
  return (
    <>
      <Row style={{ margin: "10px 25px" }}>
        <NavTabs activePageName="Rebates" />
      </Row>
      <ListRebates />
    </>
  );
};

export default Rebates;
