import { GridApi } from "ag-grid-community";
import CustomModal from "components/modals/customModal";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import style from "./expenseItem.module.css";
import { companyDateFormat } from "services/general/dateSvc";
import { useTypedSelector } from "reducers";
import { SecondaryTable } from "components/common/wrapperComponents/Table/secondaryTable";
import { formattedAmount } from "services/general/helpers";
import { DataTable } from "components/common/wrapperComponents";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import { IDType } from "services/common/types/common.type";
import _ from "lodash";
import useIsMounted from "components/common/hooks/useIsMounted";
import gridService from "services/grid/gridSvc";
import { Link } from "react-router-dom";

type AddToExistingERPropsType = {
  gridApi: GridApi;
  closeCallback: () => void;
  createBulkExpenseReport: () => false | undefined;
};

const AddToExistingER = ({ gridApi, closeCallback, createBulkExpenseReport }: AddToExistingERPropsType) => {
  const currentUser = useTypedSelector((state) => state.user);
  const isMounted = useIsMounted();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExpenseItems, setSelectedExpenseItems] = useState<ExpensesTypes.List>([]);
  const [expenseReports, setExpenseReports] = useState<ExpenseReportTypes.List>([]);
  const [selectedExpenseReport, setSelectedExpenseReport] = useState<ExpenseReportTypes.ListItem>();
  const selectedExpenseReportId = selectedExpenseReport?.id;
  const [isLoadingErDetails, setLoadingErDetails] = useState<boolean>(false);
  const [selectedERDetail, setSelectedERDetail] = useState<ExpenseReportTypes.Details | null>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const failedItemsNumber = selectedExpenseItems
    .filter((expenseItem) => expenseItem.status !== "NEW" || expenseItem.expense_report_id)
    .map((item) => item.number);

  const getExpenseReports = async (expenseItem: ExpensesTypes.ListItem) => {
    try {
      const result = await ExpenseReportApis.getList({
        filter: {
          status: "NEW",
          policy_id: expenseItem.policy_id,
          employee_id: expenseItem.employee_id,
          subsidiary_id: expenseItem.subsidiary_id,
          location_id: expenseItem.location_id,
          currency_code: expenseItem.currency_code,
          reimbursable: expenseItem.reimbursable,
        },
      });
      if (isMounted.current) {
        setExpenseReports(result);
      }
    } catch (error) {}
  };

  const getExpenseReportDetails = async (id: IDType) => {
    try {
      setLoadingErDetails(true);
      const result = await ExpenseReportApis.getDetail(id);
      if (isMounted.current) {
        setSelectedERDetail(result);
        setLoadingErDetails(false);
      }
    } catch (e) {
      setLoadingErDetails(false);
    }
  };

  useEffect(() => {
    setSelectedERDetail(null);
    if (selectedExpenseReportId) getExpenseReportDetails(selectedExpenseReportId);
  }, [selectedExpenseReportId]);

  //checking validation of selected expense items should have same
  // policy, employee, subsidiary, currency, location, reimbursable, non-reimbursable
  const validateSelectedExpenseItems = (selectedRows: ExpensesTypes.List): boolean => {
    let isSamePolicy: boolean = true;
    let isSameEmployee: boolean = true;
    let isSameSubsidiary: boolean = true;
    let isSameCurrency: boolean = true;
    let isSameLocation: boolean = true;
    let isSameReimbursable: boolean = true;

    for (let i = 0; i < selectedRows.length - 1; i++) {
      if (selectedRows[i].policy_id !== selectedRows[i + 1].policy_id) {
        isSamePolicy = false;
      }
      if (selectedRows[i].employee_id !== selectedRows[i + 1].employee_id) {
        isSameEmployee = false;
      }
      if (selectedRows[i].subsidiary_id !== selectedRows[i + 1].subsidiary_id) {
        isSameSubsidiary = false;
      }
      if (selectedRows[i].currency_code !== selectedRows[i + 1].currency_code) {
        isSameCurrency = false;
      }
      if (selectedRows[i].location_id !== selectedRows[i + 1].location_id) {
        isSameLocation = false;
      }
      if (selectedRows[i].reimbursable !== selectedRows[i + 1].reimbursable) {
        isSameReimbursable = false;
      }
    }

    if (!isSameEmployee) {
      CreateNotification(
        "Expense Items Not Valid",
        "Selected Expense Item(s) are linked to different users, and can’t be added to the same Expense Report.",
        NotificationType.danger,
      );
      return false;
    } else if (!isSamePolicy) {
      CreateNotification(
        "Expense Items Not Valid",
        "Selected Expense Item(s) are linked to different company policies, and can’t be added to the same Expense Report.",
        NotificationType.danger,
      );
      return false;
    } else if (!isSameCurrency) {
      CreateNotification(
        "Expense Items Not Valid",
        "Selected Expense Item(s) are linked to different currencies, and can’t be added to the same Expense Report.",
        NotificationType.danger,
      );
      return false;
    } else if (!isSameReimbursable) {
      CreateNotification(
        "Expense Items Not Valid",
        "Selected Expense Item(s) contain both Reimbursable and Non-Reimbursable items, and can’t be added to the same Expense Report.",
        NotificationType.danger,
      );
      return false;
    } else if (!isSameSubsidiary) {
      CreateNotification(
        "Expense Items Not Valid",
        "Selected Expense Item(s) are linked to different companies, and can’t be added to the same Expense Report.",
        NotificationType.danger,
      );
      return false;
    } else if (!isSameLocation) {
      CreateNotification(
        "Expense Items Not Valid",
        "Selected Expense Item(s) are linked to different locations, and can’t be added to the same Expense Report.",
        NotificationType.danger,
      );
      return false;
    }

    return true;
  };

  const initAddToExpenseModal = async () => {
    const selectedRows: ExpensesTypes.List = gridService.getServerSideSelectedRows(gridApi) ?? [];

    if (_.isArray(selectedRows) && selectedRows.length < 1) {
      CreateNotification(
        "No Expense Item Selected",
        "Please select at least one expense item.",
        NotificationType.danger,
      );
    }

    const isSelectedExpenseItemsValid = validateSelectedExpenseItems(selectedRows);
    if ((_.isArray(selectedRows) && selectedRows.length < 1) || !isSelectedExpenseItemsValid) {
      return closeCallback && closeCallback();
    }

    if (isMounted.current) {
      setSelectedExpenseItems(selectedRows);
      getExpenseReports(selectedRows[0]);
      setIsModalOpen(true);
    }
  };

  const onAddToExistingER = async () => {
    try {
      if (failedItemsNumber.length > 0) {
        CreateNotification(
          "Error while Generating Report",
          "Selected Expense Items " +
            failedItemsNumber.join(",") +
            " should be NEW and not linked to any Expense Report",
          NotificationType.danger,
        );
        return closeAddToExistingModal();
      }

      setIsSubmitting(true);
      if (selectedExpenseReportId) {
        const result = await ExpenseReportApis.linkExpenseItem(selectedExpenseReportId, {
          item_ids: selectedExpenseItems.map((item) => item.id!),
        });

        gridApi.refreshServerSide();
        setIsSubmitting(false);
        CreateNotification(
          "Success",
          `Selected Expense Item(s) successfully added to ${selectedERDetail?.number}`,
          NotificationType.success,
        );
        closeAddToExistingModal();
      }
    } catch (error: any) {
      if (error?.response?.data?.errors?.expense_item) {
        CreateNotification("Error", error?.response?.data?.errors?.expense_item, NotificationType.danger);
      }
      closeAddToExistingModal();
      setIsSubmitting(false);
    }
  };

  const closeAddToExistingModal = () => {
    setIsModalOpen(false);
    closeCallback && closeCallback();
  };

  useEffect(() => {
    initAddToExpenseModal();
  }, []);

  return (
    <>
      {isModalOpen && (
        <CustomModal
          show={isModalOpen}
          onHide={() => closeAddToExistingModal()}
          contentClassName={style.addToExistingEr}
          header={<span className={`${style.modalHeader}`}>Add to Existing Expense Report </span>}
          body={
            <Container fluid className={`${style.modalBody}`}>
              <Row>
                <Col md="12" className="px-0">
                  <Row className={`${style.selectExpenseItems}`}>
                    <Col>
                      <Row>
                        <Col md={12}>
                          <div className={`${style.heading}`}>Selected Expense Items</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <SecondaryTable bordered>
                            <thead>
                              <tr>
                                <th>Number</th>
                                <th>Merchant</th>
                                <th>Status</th>
                                <th>Total Amount</th>
                                <th>Transaction Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedExpenseItems.map((expenseItem) => (
                                <tr key={expenseItem.id}>
                                  <td>
                                    <Link to={"/ap/expenses/" + expenseItem.id} className="link">
                                      {expenseItem.number}
                                    </Link>
                                  </td>
                                  <td>{expenseItem.vendor_name}</td>
                                  <td>{expenseItem.status}</td>
                                  <td>{formattedAmount(String(expenseItem.amount), expenseItem.currency_code)}</td>
                                  <td>{companyDateFormat(expenseItem.date, currentUser)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </SecondaryTable>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <div className={`${style.line}`}></div>
                  <Row className={`${style.selectExistingEr}`}>
                    <Col md="12">
                      <Row className={`${style.heading2}`}>
                        <Col>Select An Existing Expense Report</Col>
                      </Row>
                      <Row>
                        <Col md="12" className={`${style.existingErList}`}>
                          {expenseReports.map((expenseReport) => (
                            <div
                              key={expenseReport.id}
                              onClick={() => setSelectedExpenseReport(expenseReport)}
                              role="button"
                              className={`${style.expenseReport}`}
                            >
                              <div className={`${style.expenseReportDetails}`}>
                                <div>
                                  <input
                                    type="radio"
                                    name="selectedER"
                                    checked={selectedExpenseReportId === expenseReport.id}
                                    className={`${style.radioInput}`}
                                  />
                                </div>
                                <div>
                                  {expenseReport?.name && (
                                    <div>
                                      <div className={`${style.erName}`}>{expenseReport.name}</div>
                                    </div>
                                  )}
                                  <div className={`${style.expenseReportDetails}`}>
                                    <div className={`${style.erNumber}`}>{expenseReport.number}</div>
                                    <div>NEW</div>
                                    <div>{companyDateFormat(expenseReport.date, currentUser)}</div>
                                    <div>
                                      {formattedAmount(String(expenseReport.amount), expenseReport?.currency?.iso_code)}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className={`${style.ERLinkedExpenses} ${
                                  selectedExpenseReportId === expenseReport.id && style.show
                                }`}
                              >
                                <DataTable bordered className={style.expenseItemTable}>
                                  <thead>
                                    <tr>
                                      <th>Number</th>
                                      <th>Transaction Date</th>
                                      <th>Merchant</th>
                                      <th>Total Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!isLoadingErDetails &&
                                      _.isArray(selectedERDetail?.expense_items) &&
                                      selectedERDetail?.expense_items.length! > 0 &&
                                      selectedERDetail?.expense_items?.map((expeneItem) => (
                                        <tr key={expeneItem.id}>
                                          <td>{expeneItem.number}</td>
                                          <td>{companyDateFormat(expeneItem.date, currentUser)}</td>
                                          <td>{expeneItem.vendor_name}</td>
                                          <td>
                                            {formattedAmount(String(expeneItem.amount), expeneItem.currency_code)}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </DataTable>
                              </div>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={`${style.modalFooter}`}>
                    <Col md="6">
                      <Button onClick={createBulkExpenseReport} disabled={isSubmitting} variant="secondary">
                        Create New Expense Report
                      </Button>
                    </Col>
                    <Col md="6" className="d-flex justify-content-end">
                      <Button variant="secondary" className={`${style.mr16}`} onClick={() => closeAddToExistingModal()}>
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        disabled={!Boolean(selectedExpenseReportId) || isSubmitting}
                        className={`${(!Boolean(selectedExpenseReportId) || isSubmitting) && "btn-disable"}`}
                        onClick={onAddToExistingER}
                      >
                        {isSubmitting && <Spinner size="sm" animation="border" />} Add
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          }
          size="lg"
        />
      )}
    </>
  );
};

export default AddToExistingER;
