import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import style from "./objectChanges.module.css";
import changeOrderService from "../../../services/admin/changeOrders/changeOrderSvc";
import { PurchaseOrderType } from "../../../services/admin/purchaseOrders/purchaseOrderType";
import { PoChangeOrderType } from "../../../services/admin/changeOrders/poChangeOrderApprovalsType";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import ChangedPropertyId from "./changedPropertyId";
import ChangedProperty from "./changedProperty";
import { getStringFormattedAmount } from "services/general/helpers";
import adminCommonSvc from "services/admin/commonSvc";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";

type PoLineItemsTablePropsType = {
  poItems: PurchaseOrderType.PoItemType[];
  poChangeOrderItems: PoChangeOrderType.PoItemsAttributeType[];
};

const RenderPOItemRow = (
  newItem: PoChangeOrderType.PoItemsAttributeType,
  oldItem: PoChangeOrderType.PoItemsAttributeType | undefined,
  index: number,
  className: string = "",
) => {
  const { t } = useTranslation();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const unitPriceDecimalLimit: number = useMemo(() => adminCommonSvc.unitPriceDecimalLimit(currentUser), [currentUser]);

  const formatterUnitPrice = (value: any): string => {
    return getStringFormattedAmount((value || 0).toString(), undefined, unitPriceDecimalLimit, true);
  };

  const getTaxesInfo = (item: any): string => {
    if (!item) return "";

    const code = item.tax_code?.name ?? item.code;
    const rate = item.tax_code?.rate ?? item.rate;

    if (!code && !rate) return "";

    return t("admin.pages.poCoApproval.taxes", { code, rate });
  };

  const fullOldItem = oldItem as any;

  return (
    <tr key={index} className={className}>
      <td>{index + 1}</td>
      <ChangedPropertyId
        endpoint="product_items"
        formatter={(obj) => obj.name}
        newItem={newItem}
        oldItem={oldItem}
        property="product_item_id"
        value={fullOldItem?.product_item?.name}
      />
      <ChangedProperty newItem={newItem} oldItem={oldItem} property="qty" />
      <ChangedProperty newItem={newItem} oldItem={oldItem} property="unit_price" formatter={formatterUnitPrice} />
      <ChangedProperty newItem={newItem} oldItem={oldItem} property="tax" />
      <ChangedPropertyId
        endpoint="taxes"
        formatter={(obj) => getTaxesInfo(obj)}
        newItem={newItem}
        oldItem={oldItem}
        property="tax_id"
        value={getTaxesInfo(fullOldItem)}
      />
      <ChangedProperty newItem={newItem} oldItem={oldItem} property="memo" />
      <ChangedPropertyId
        endpoint="locations"
        formatter={(obj) => obj.name}
        newItem={newItem}
        oldItem={oldItem}
        property="location_id"
        value={fullOldItem?.location?.name}
      />

      <ChangedPropertyId
        endpoint="departments"
        formatter={(obj) => obj.name}
        newItem={newItem}
        oldItem={oldItem}
        property="department_id"
        value={fullOldItem?.department?.name}
      />

      <ChangedPropertyId
        endpoint="secondary_categories"
        formatter={(obj) => obj.name}
        newItem={newItem}
        oldItem={oldItem}
        property="secondary_category_id"
        value={fullOldItem?.secondary_category?.name}
      />

      <ChangedPropertyId
        endpoint="grants"
        formatter={(obj) => obj.name}
        newItem={newItem}
        oldItem={oldItem}
        property="grant_id"
        value={fullOldItem?.grant?.name}
      />

      <ChangedPropertyId
        endpoint="projects"
        formatter={(obj) => obj.name}
        newItem={newItem}
        oldItem={oldItem}
        property="project_id"
        value={fullOldItem?.project?.name}
      />

      <ChangedPropertyId
        endpoint="budgets"
        formatter={(obj) => obj.label}
        newItem={newItem}
        oldItem={oldItem}
        property="budget_id"
        value={fullOldItem?.budget?.name}
      />

      <ChangedPropertyId
        endpoint="for_subsidiaries"
        formatter={(obj) => obj.name}
        newItem={newItem}
        oldItem={oldItem}
        property="for_subsidiary_id"
        value={fullOldItem?.for_subsidiary?.name}
      />
    </tr>
  );
};

const PoLineItemsTable = ({ poChangeOrderItems, poItems }: PoLineItemsTablePropsType) => {
  const { t } = useTranslation();
  const differentiatedPoLineItems = changeOrderService.getDifferentiatedCOObjects<
    PoChangeOrderType.PoItemsAttributeType,
    PurchaseOrderType.PoItemType[],
    PoChangeOrderType.PoItemsAttributeType,
    PoChangeOrderType.PoItemsAttributeType[]
  >(poItems, poChangeOrderItems);

  return (
    <Table bordered responsive className={`text-left m-0 ${style.thBgColor}`}>
      <thead>
        <tr>
          <th>#</th>
          <th> {t("admin.pages.poCoApproval.itemName")} </th>
          <th> {t("admin.pages.poCoApproval.qty")} </th>
          <th> {t("admin.pages.poCoApproval.unitPrice")}</th>
          <th> {t("admin.pages.poCoApproval.tax")} </th>
          <th> {t("admin.pages.poCoApproval.taxCode")} </th>
          <th> {t("admin.pages.poCoApproval.description")} </th>
          <th> {t("admin.pages.poCoApproval.location")} </th>
          <th> {t("admin.pages.poCoApproval.department")} </th>
          <th> {t("admin.pages.poCoApproval.secondaryCategory")} </th>
          <th> {t("admin.pages.poCoApproval.grant")} </th>
          <th> {t("admin.pages.poCoApproval.project")}</th>
          <th> {t("admin.pages.poCoApproval.budget")} </th>
          <th> {t("admin.pages.poCoApproval.forSubsidiary")}</th>
        </tr>
      </thead>
      <tbody>
        {differentiatedPoLineItems &&
          _.isArray(differentiatedPoLineItems) &&
          differentiatedPoLineItems?.map((differentiatedPoLineItem, index) => {
            const { change_type, newItem, oldItem } = differentiatedPoLineItem;
            switch (change_type) {
              case "ADDED":
                return RenderPOItemRow(newItem, oldItem, index, style.updatedItem);
              case "DELETED":
                return RenderPOItemRow(newItem, oldItem, index, style.deletedItem);
              default:
                return RenderPOItemRow(newItem, oldItem, index);
            }
          })}
      </tbody>
    </Table>
  );
};

export default PoLineItemsTable;
