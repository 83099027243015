import React, { Component, Fragment } from "react";
import { Table } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RestApi from "../../../providers/restApi";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { LinkPaymentMethod, UnlinkPaymentMethod } from "../../../services/paymentMethods/paymentMethodLinking";
import PickPaymentMethodModal from "../../modals/pickPaymentMethodModal";
import CollapsePanel from "../../panel/collapsePanel";

class PaymentMethodLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccountPicker: false,
      isNvpOnlyPurchaser:
        Object.values(this.props.purchaser.accepted_payment_methods).filter((value) => value === true).length == 1 &&
        this.props.purchaser.accepted_payment_methods.corpay_nvp,
    };
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.getPaymentMethodLinks();
  }

  getPaymentMethodLinks = () => {
    this.restApiService
      .get("vendors/" + this.props.purchaser.vendor_detail.id + "/vendor_linked_payment_methods")
      .then((result) => {
        this.setState({
          linkedPaymentMethods: result.data,
        });
        this.setState({
          hasPaymentMethod:
            (this.state?.isNvpOnlyPurchaser && this.props.purchaser?.vendor_default_payment_method !== undefined) ||
            (!this.state?.isNvpOnlyPurchaser &&
              this.state?.linkedPaymentMethods &&
              this.state?.linkedPaymentMethods.length > 0),
        });
        this.linkablePaymentMethods(this.props.purchaser);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  paymentMethodPicked = (paymentMethod) => {
    let valid = true;
    this.state.linkedPaymentMethods.forEach((pm) => {
      if (paymentMethod && paymentMethod.id === pm.id) {
        valid = false;
        CreateNotification("Error", "Payment account already linked", NotificationType.warning);
      }
    });

    if (valid && paymentMethod && paymentMethod.id) {
      this.linkPaymentMethod(paymentMethod);
    }
  };

  linkPaymentMethod = (paymentMethod) => {
    LinkPaymentMethod(this.props.purchaser.vendor_detail.id, paymentMethod.id)
      .then((result) => {
        CreateNotification("Success", "Payment Account Linked Succesfully", NotificationType.success);
        this.getPaymentMethodLinks();
        this.setState({ showAccountPicker: false });
      })
      .catch((err) => {
        console.log(err);
        CreateNotification("Error", "Please reach out to support", NotificationType.warning);
      });
  };

  unlinkPaymentMethod = (paymentMethod) => {
    UnlinkPaymentMethod(this.props.purchaser.vendor_detail.id, paymentMethod.id)
      .then((result) => {
        CreateNotification("Success", "Payment Account Un-Linked Succesfully", NotificationType.success);
        this.getPaymentMethodLinks();
      })
      .catch((err) => {
        console.log(err);
        CreateNotification("Error", "Please reach out to support", NotificationType.warning);
      });
  };

  linkablePaymentMethods = (purchaser) => {
    let options = [];
    purchaser.payment_methods.forEach((pm) => {
      if (this.props.purchaser.accepted_payment_methods) {
        if (
          (pm.payment_type === "ach" || pm.payment_type === "corpay_card") &&
          !purchaser.accepted_payment_methods.ach
        ) {
          return;
        } else if (pm.payment_type === "international" && !purchaser.accepted_payment_methods.transferwise) {
          return;
        } else if (pm.payment_type === "credit_card" && !purchaser.accepted_payment_methods.credit_card) {
          return;
        } else if (pm.payment_type === "payoneer" && !purchaser.accepted_payment_methods.payoneer) {
          return;
        } else if (pm.payment_type === "wire" && !purchaser.accepted_payment_methods.wire) {
          return;
        } else if (pm.payment_type === "fed-wire" && !purchaser.accepted_payment_methods.fed_wire) {
          return;
        } else if (pm.payment_type === "can-eft" && !purchaser.accepted_payment_methods.can_eft) {
          return;
        } else if (pm.payment_type === "uk-eft" && !purchaser.accepted_payment_methods.uk_eft) {
          return;
        } else if (pm.payment_type === "aus-pay-net" && !purchaser.accepted_payment_methods.aus_pay_net) {
          return;
        } else if (pm.payment_type === "nz-eft" && !purchaser.accepted_payment_methods.nz_eft) {
          return;
        } else if (pm.payment_type === "japan-eft" && !purchaser.accepted_payment_methods.japan_eft) {
          return;
        } else if (pm.payment_type === "nz-eft" && !purchaser.accepted_payment_methods.sg_eft) {
          return;
        } else if (pm.payment_type === "sepa" && !purchaser.accepted_payment_methods.sepa) {
          return;
        } else if (pm.payment_type === "cambridge" && !purchaser.accepted_payment_methods.cambridge) {
          return;
        } else if (pm.payment_type === "swift-wire" && !purchaser.accepted_payment_methods.swift_wire) {
          return;
        } else if (pm.payment_type === "external" && !purchaser.accepted_payment_methods.external) {
          return;
        }
      }
      if (this.state.linkedPaymentMethods.find((linkedMethod) => linkedMethod.id === pm.id)) {
        pm.linked = true;
      } else {
        pm.linked = false;
      }
      options.push(pm);
    });
    this.setState({ linkablePaymentMethods: options });
  };

  render() {
    return (
      <Fragment>
        <CollapsePanel
          open={true}
          heading={"DEFAULT PAY-TO ACCOUNT"}
          cardBodyStyle={{ padding: "0px" }}
          icon={<i className="icon-plus"></i>}
          leftBtn={
            !this.props.user.company.has_corpay_nvp_purchasers && this.state?.linkablePaymentMethods?.length > 0
              ? this.props.t("paymentMethod.addAccount")
              : false
          }
          onClick={() => {
            this.setState({ showAccountPicker: true });
          }}
        >
          {this.state.hasPaymentMethod && (
            <Table striped bordered hover className="mb-0">
              <thead>
                <tr>
                  <th className="primaryFontColor">{this.props.t("paymentMethod.bankName")}</th>
                  <th className="primaryFontColor">{this.props.t("paymentMethod.accountType")}</th>
                  <th className="primaryFontColor">{this.props.t("paymentMethod.paymentType")}</th>
                  <th className="primaryFontColor">{this.props.t("paymentMethod.accountNumber")}</th>
                  <th className="primaryFontColor" style={{ textTransform: "capitalize" }}>
                    {this.props.t("currency")}
                  </th>
                  {!this.props.user.company.has_corpay_nvp_purchasers && (
                    <th>{this.props.t("paymentMethod.actions")}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {this.props.user.company.has_corpay_nvp_purchasers &&
                  this.props.purchaser?.vendor_default_payment_method && (
                    <tr>
                      <td>{this.props.purchaser.vendor_default_payment_method.bank_name}</td>
                      <td>{this.props.purchaser.vendor_default_payment_method.account_type}</td>
                      <td>
                        {this.props.purchaser.vendor_default_payment_method.payment_type == "credit_card"
                          ? "Mastercard"
                          : "credit_card"}
                      </td>
                      <td>{this.props.purchaser.vendor_default_payment_method.account_number}</td>
                      <td>{this.props.purchaser.vendor_default_payment_method.currency_code}</td>
                    </tr>
                  )}
                {!this.isNvpOnlyPurchaser && !this.props.purchaser?.vendor_default_payment_method && (
                  <>
                    {this.state?.linkedPaymentMethods.map((pm) => {
                      return (
                        <tr key={pm.id}>
                          <td>{pm.payment_type}</td>
                          <td>{pm.currency_code}</td>
                          <td>{pm.bank_name}</td>
                          <td>{pm.account_number}</td>
                          <td>{pm.account_type}</td>
                          {!this.props.user.company.has_corpay_nvp_purchasers && (
                            <td>
                              <div className={"d-flex align-items-center justify-content-center"}>
                                <Link
                                  className="iconCss ml-1"
                                  variant="primary"
                                  to={{ pathname: "/ar/banking_information", state: { pm: pm } }}
                                  data-toggle="tooltip"
                                  title="Edit"
                                >
                                  <img
                                    className=""
                                    src={require("../../../assets/setting/editicon.svg").default}
                                    alt="Edit"
                                  />
                                </Link>
                                <button
                                  className={"iconCss ml-1 bg-transparent border-0"}
                                  onClick={() => {
                                    this.unlinkPaymentMethod(pm);
                                  }}
                                >
                                  <img src={require("../../../assets/setting/deleteicon.svg").default} alt="Delete" />
                                </button>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </Table>
          )}
          {!this.state.hasPaymentMethod && (
            <h5 className="text-center mt-3 mb-3">- {this.props.t("warnings.noLinkedPaymentMethods")} -</h5>
          )}
        </CollapsePanel>
        <PickPaymentMethodModal
          title="Pick Payment Account"
          show={this.state.showAccountPicker}
          limitPaymentMethods={this.props.purchaser.accepted_payment_methods}
          saveCallback={this.paymentMethodPicked}
          closeCallback={() => {
            this.setState({ showAccountPicker: false });
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  };
};

export default withTranslation()(connect(mapStateToProps)(PaymentMethodLinks));
