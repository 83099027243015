import React, { useEffect, useState } from "react";
import { VendorDocumentLinkTypes } from "../../../services/common/documentVendorLinks/vendorDocumentLinkTypes";
import AttachmentPreviewer from "../attachmentPreview/AttachmentPreviewer";
import DocumentApis from "../../../services/common/documents/documentApis";
import CustomModal from "../../modals/customModal";
import useDiversityTrackigForm from "../hooks/useDiversityTrackingForm/useDiversityTrackigForm";
import styles from "./vendorDocumentPreview.module.css";

type VendorDocumentPreviewPropsType = {
  vendorDocumentLink: VendorDocumentLinkTypes.ListItem;
  onHide: () => void;
};

const VendorDocumentPreview = ({ vendorDocumentLink, onHide }: VendorDocumentPreviewPropsType) => {
  const [previewFile, setPreviewFile] = useState<any>();
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [w8BenForm, setW8BenForm] = useState("");
  const [htmlForms, setHtmlForms] = useState("");
  const [diversityFormData, setDiversityFormData] = useState<Record<string, any>>();
  const { diversityTrackingForm } = useDiversityTrackigForm({ initialState: diversityFormData, isDisabled: true });

  const closePreviewerModal = () => {
    setShowPreviewModal(false);
    onHide();
  };

  const previewDocument = async () => {
    if (vendorDocumentLink.document_id) {
      if (
        vendorDocumentLink.document?.name?.toLocaleLowerCase().includes("w9") ||
        (vendorDocumentLink.document?.name?.toLocaleLowerCase().includes("w8") &&
          !vendorDocumentLink.document?.name?.toLocaleLowerCase().includes("ben"))
      ) {
        // for this case attachment are not present in document array
        // have to fetch from document detail api
        const documentDetail = await DocumentApis.getOne(vendorDocumentLink.document_id);
        if (documentDetail?.page_data) {
          setHtmlForms(documentDetail?.page_data);
          setShowPreviewModal(true);
        }
      } else if (vendorDocumentLink.document?.name?.toLocaleLowerCase().includes("w8")) {
        // for this case attachment are not present in document array
        // have to fetch from document detail api
        const documentDetail = await DocumentApis.getOne(vendorDocumentLink.document_id);
        if (documentDetail?.page_data) {
          setW8BenForm(documentDetail?.page_data);
          setShowPreviewModal(true);
        }
      } else if (vendorDocumentLink.document?.name?.toLocaleLowerCase().includes("diversity")) {
        // diversity tracking
        const documentDetail = await DocumentApis.getOne(vendorDocumentLink.document_id);
        if (documentDetail?.form_data) {
          setDiversityFormData(documentDetail?.form_data);
          setShowPreviewModal(true);
        }
      } else {
        setPreviewFile(vendorDocumentLink?.document?.assets?.[0]);
        setShowPreviewModal(true);
      }
    }
  };

  useEffect(() => {
    previewDocument();
  }, []);

  return (
    <>
      {showPreviewModal && previewFile && (
        <AttachmentPreviewer
          contentClassName={styles.modalContentClass}
          closePreviewer={() => closePreviewerModal()}
          isVisible={showPreviewModal}
          file={previewFile}
        />
      )}

      {/* for w8 w9 */}
      {showPreviewModal && htmlForms && (
        <CustomModal
          show={showPreviewModal}
          contentClassName={styles.modalContentClass}
          header={<>{vendorDocumentLink.document?.name}</>}
          body={<div dangerouslySetInnerHTML={{ __html: htmlForms }} />}
          onHide={() => closePreviewerModal()}
          size="xl"
        />
      )}

      {/* for w8ben */}
      {showPreviewModal && w8BenForm && (
        <CustomModal
          show={showPreviewModal}
          contentClassName={styles.modalContentClass}
          header={<>{vendorDocumentLink.document?.name}</>}
          body={<div style={{ position: "relative" }} dangerouslySetInnerHTML={{ __html: w8BenForm }} />}
          onHide={() => closePreviewerModal()}
          size="xl"
        />
      )}

      {showPreviewModal && diversityFormData && (
        <CustomModal
          header={<>{vendorDocumentLink.document?.name}</>}
          contentClassName={styles.modalContentClass}
          show={showPreviewModal}
          onHide={() => closePreviewerModal()}
          body={<>{diversityTrackingForm}</>}
          size="lg"
        />
      )}
    </>
  );
};

export default VendorDocumentPreview;
