import React from "react";
import styles from "./tabNavigator.module.css";

type BudgeTabTemplateTypes = {
  activeTab: string;
  handleTabChange: (tab: string) => void;
  tabData: {
    activeTab: string;
    label: string;
    hide?: boolean;
  }[];
};

const BudgeTabTemplate = ({ activeTab, handleTabChange, tabData }: BudgeTabTemplateTypes) => {
  return (
    <ul className={styles.badgeNavbar}>
      {tabData.map((tab) => {
        if (tab.hide) {
          return null;
        }

        return (
          <li key={tab.activeTab} className={styles.badgeNavTab}>
            <button
              className={`${styles.badgeTab} ${
                activeTab === tab.activeTab ? styles.badgeTabActive : styles.badgeInactive
              }`}
              onClick={() => handleTabChange(tab.activeTab)}
            >
              {tab.label}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default BudgeTabTemplate;
