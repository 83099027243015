import { RenderField } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const BankCode = ({ label, pattern, validations, isBankCodeRequired, maxLength }) => {
  return (
    <Col md="6">
      <Field
        id="form.bankCode"
        name="form.bankCode"
        component={RenderField}
        type="text"
        pattern={pattern}
        label={label}
        required={isBankCodeRequired}
        validate={validations ? validations : []}
        maxLength={maxLength}
      />
    </Col>
  );
};

export default BankCode;
