import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Select from "react-select";
import { onBlurSingle, onChangeSingle, parseForSelect } from "../../../../../services/general/helpers";
import { change, WrappedFieldProps } from "redux-form";
import useIsMounted from "components/common/hooks/useIsMounted";
import _ from "lodash";
import { CommonApis } from "services/admin/commonApis";
import { useDispatch } from "react-redux";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { useTranslation } from "react-i18next";

interface ReabatePickerPropsType extends WrappedFieldProps {
  label?: ReactNode;
  required?: boolean;
  floating?: boolean;
  tooltip?: ReactNode;
  callBack?: (arg: any) => void;
  modelData?: { [key: string]: any };
  parentObjData?: { [key: string]: any };
  disabled?: boolean;
  formName?: string;
  modelDataFormField?: string;
  instanceId?: string;
}

const RebatePicker = ({
  label,
  tooltip,
  input,
  floating = false,
  required,
  callBack,
  meta: { error, warning },
  formName,
  modelDataFormField,
  modelData,
  parentObjData,
  disabled,
  instanceId = "rebate-picker",
}: ReabatePickerPropsType) => {
  const [rebates, setRebates] = useState([]);
  const [selected, setSelected] = useState<any | null>();
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const resetRebate = (obj: { location_id?: any }, rebates: any[]): void => {
    if (_.isPlainObject(modelData) && obj.location_id) {
      const rebate = rebates
        .filter((rebate: any) => rebate.id === modelData?.rebate_id)
        .filter((rebate) => rebate.value);
      if (formName && modelDataFormField && modelData && rebate && _.isArray(rebate) && !(rebate?.length > 0)) {
        const modelDataClone = _.cloneDeep(modelData);
        modelDataClone.rebate = null;
        modelDataClone.rebate_id = "";
        modelDataClone.rebate_amount = null;

        dispatch(change(formName, modelDataFormField + ".rebate", null));
        dispatch(change(formName, modelDataFormField + ".rebate_id", null));
        dispatch(change(formName, modelDataFormField + ".rebate_amount", 0));
        input.onChange(null);
        setSelected(null);
      }
    }
  };

  const getRebates = async () => {
    let filter: { [key: string]: any; location_id?: any } = { status: "ACTIVE" };
    if (formName && _.isPlainObject(modelData) && modelData?.location && modelData?.location?.has_rebates) {
      filter.location_id = modelData.location_id;
      dispatch(change(formName, modelDataFormField + "only_show_mapped_rebate", true));
    }

    // Doing cache: true stores this for all future requests
    const result = await CommonApis.getRebatesLk(filter, true);
    if (result) {
      let list = parseForSelect(result);
      list = !required ? [{ label: "-- Select Rebate --", value: "", name: "select..." }, ...list] : list;
      if (isMounted.current) {
        setRebates(list);
      }
      resetRebate(filter, list);
      return list;
    }
  };

  const onChangeRebate = (input: any, selected: any) => {
    onChangeSingle(input, selected);
    setSelected(selected);
    if (modelData) {
      modelData.rebate = selected;
      modelData.rebate_id = selected?.id;
    }
    if (callBack) {
      callBack({ a: selected, b: modelData });
    }
  };

  const findSelected = async (options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        const result = await CommonApis.getRebate(input.value);
        const inactiveSecondaryCategories = {
          value: result.id,
          label: (
            <>
              {result.name} - <small style={{ fontSize: "10px" }}>({result.status})</small>
            </>
          ),
        };
        selectedValues = inactiveSecondaryCategories;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    getRebates().then((res) => {
      findSelected(res).then((response) => {
        if (isMounted.current) {
          setSelected(response);
        }
      });
    });
  }, []);

  useEffect(() => {
    // this function should run even if location_id get null or undefined as
    // we have to show all rebates if it got null
    // TODO: find solution for mulitple api fetch calls
    getRebates();
  }, [modelData?.location_id]);

  const customFilter = (option: FilterOptionOption<any>, inputValue: string) => {
    if (
      option?.data?.name?.toLowerCase().includes(inputValue) ||
      option.data.rate?.toLowerCase().includes(inputValue)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
          {tooltip}
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        options={rebates}
        value={selected}
        filterOption={customFilter}
        onChange={(selected) => onChangeRebate(input, selected)}
        onBlur={() => onBlurSingle(input, input.value)}
        isClearable={!required}
        formatOptionLabel={(option, context) => {
          return context.context == "value" ? (
            <small className="selectChoices pl-0">
              {!option.value && <span style={{ opacity: 0.6 }}>Select...</span>}
              {option.value && (
                <>
                  {option.name} {option.rate && <span>({option.rate}%)</span>}{" "}
                  {option.is_included && <span>(included)</span>}
                </>
              )}
            </small>
          ) : (
            <small className="selectChoices pl-0">
              {!option.value && <span style={{ opacity: 0.6 }}>Select...</span>}
              {option.value && (
                <>
                  <span className="boldText">{t("rebate.name")}: </span>
                  {option.name}{" "}
                  {option.rate && (
                    <span>
                      <span className="boldText">{t("rebate.rate")}:</span> {option.rate}%
                    </span>
                  )}{" "}
                  {option.is_included && <span>(included)</span>}
                </>
              )}
            </small>
          );
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
          }),
          ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
        }}
        {...(floating ? { menuPortalTarget: document.body } : {})}
        isDisabled={disabled}
        instanceId={instanceId}
      />
      <PickerErrorBlock error={error} />
    </Form.Group>
  );
};

export default RebatePicker;
