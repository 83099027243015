import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../common/hooks/useIsMounted";
import { useParams } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CollapsePanel from "../../panel/collapsePanel";
import { BreadcrumbTrail } from "../../navigation/navigationLinks";
import PageTitle from "../../page/pageTitle";
import InvoicePaymentLinks from "../../widgets/linkedInvoices/linkedInvoices";
import PicturePreview from "../../widgets/fileUpload/picturePreview";
import { normalizeDates } from "../../../services/common/commonSvc";
import { formattedAmount } from "../../../services/general/helpers";
import { AssetType } from "../../../services/common/types/common.type";
import CreditMemoApis from "../../../services/vp/creditMemo/creditMemoApis";
import {
  CreditMemoDetailType,
  VendorCreditSourceInvoiceLinksType,
} from "../../../services/vp/creditMemo/creditMemoTypes";
import style from "./creditMemo.module.css";
import _ from "lodash";
import ChatBox from "components/vp/chatBox/chatBox";

const DetailCreditMemo = () => {
  const [creditMemo, setCreditMemo] = useState<CreditMemoDetailType>();

  const { id } = useParams<{ id: string }>();
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const getCreditMemo = async () => {
    try {
      const result = await CreditMemoApis.getCreditMemo(id);
      if (isMounted.current) {
        setCreditMemo(result);
      }
    } catch (error) {
      // CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning)
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      getCreditMemo();
    }
  }, []);

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <BreadcrumbTrail to={"/ar/credit_memos"} pageName={"Credit Memos"} title={"Credit Memo Details"} />
      <Row className="mt-3">
        <Col md={10} className="d-flex align-items-center">
          <PageTitle title={creditMemo?.number} status={creditMemo?.status ? creditMemo.status : ""} />
        </Col>
        <Col md={2} className="d-flex align-items-center justify-content-end">
          <ChatBox modelDataNumber={creditMemo?.number} modelDataId={creditMemo?.id} modelDataType="Vendor_Credit" />
        </Col>
      </Row>
      <hr className="full-border" />

      <Row className="mt-3">
        <Col>
          <Row>
            <Col sm className="pr-sm-0">
              <dl className="row">
                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.customer")}</dt>
                <dd className="col-6 col-sm-7 detailValue">
                  <Link className="primaryLinkColor" to={"/ar/purchasers/" + creditMemo?.purchaser?.id}>
                    {creditMemo?.purchaser?.name}
                  </Link>
                </dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.sourceInvoice(s)")}</dt>
                <dd className="col-6 col-sm-7 detailValue">
                  {creditMemo &&
                  _.isArray(creditMemo.vendor_credit_source_invoice_links) &&
                  creditMemo.vendor_credit_source_invoice_links.length > 0
                    ? creditMemo.vendor_credit_source_invoice_links.map(
                        (vendor_credit_source_invoice_link: VendorCreditSourceInvoiceLinksType, index: number) => {
                          return (
                            <Link
                              key={index}
                              className="primaryLinkColor pr-2"
                              to={"/ar/invoices/" + vendor_credit_source_invoice_link.invoice_id}
                            >
                              {vendor_credit_source_invoice_link.invoice.number}{" "}
                              {index < creditMemo.vendor_credit_source_invoice_links.length - 1 ? " ," : ""}
                            </Link>
                          );
                        },
                      )
                    : ""}
                </dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.submitDate")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{normalizeDates(creditMemo?.created_at)}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.currencyCode")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{creditMemo?.currency_code}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">Ref. Number</dt>
                <dd className="col-6 col-sm-7 detailValue">{creditMemo?.reference_number}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">Credit Memo Date</dt>
                <dd className="col-6 col-sm-7 detailValue">{creditMemo?.payment_date}</dd>
              </dl>
            </Col>
            <Col sm className="pr-sm-0 pl-sm-0">
              <dl className="row">
                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.amount")}</dt>
                <dd className="col-6 col-sm-7 detailValue">
                  {formattedAmount(creditMemo?.amount.toString(), creditMemo?.currency_code)}
                </dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.balance")}</dt>
                <dd className="col-6 col-sm-7 detailValue">
                  {formattedAmount(creditMemo?.balance.toString(), creditMemo?.currency_code)}
                </dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.description")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{creditMemo?.description}</dd>
              </dl>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <CollapsePanel
            open={true}
            iconClass={`icon invoices mt-0 ${style["bs-25"]}`}
            heading={t("details.invoicesCreditAppliedTo").toUpperCase()}
          >
            <InvoicePaymentLinks invoicePaymentLinks={creditMemo?.invoice_payment_links} />
          </CollapsePanel>
        </Col>
      </Row>

      <Row>
        <Col>
          <CollapsePanel open={true} iconClass="icon icon-documents" heading={t("details.documents").toUpperCase()}>
            {creditMemo &&
              _.isArray(creditMemo.assets) &&
              creditMemo.assets.length > 0 &&
              creditMemo.assets.map((asset: AssetType) => (
                <PicturePreview
                  key={asset.id}
                  file={{
                    name: asset.asset_file_file_name,
                    preview: asset.asset_expiring_thumb_url,
                    id: asset.id,
                    url: asset.asset_expiring_url,
                  }}
                />
              ))}
            {(!creditMemo || !_.isArray(creditMemo.assets) || creditMemo.assets.length < 1) && (
              <h5 className="text-center mb-0">- {t("warnings.noAttachments")} -</h5>
            )}
          </CollapsePanel>
        </Col>
      </Row>
    </Container>
  );
};

export default DetailCreditMemo;
