import moment from "moment-timezone";
import timeZome from "moment-timezone";

export const format = (dateString, tz, format) => {
  if (!dateString) {
    return "";
  }
  return timeZome.tz(dateString, tz).format(format);
};

export const formatToTz = (d, tz, currentUser) => {
  if (!d) {
    d = new Date().toISOString();
  }

  let date;
  if (currentUser.company.company_date_format === "dd/MM/yyyy") {
    date = moment(d, moment.ISO_8601).tz(tz).format("DD/MM/YYYY h:mm A z");
  } else {
    date = moment(d, moment.ISO_8601).tz(tz).format("MM/DD/YYYY h:mm A z");
  }
  return date;
};

export const monthFirst = "MM/DD/YYYY";
export const dateFirst = "DD/MM/YYYY";

export const checkNonUsFormat = (dateFormat) => {
  return dateFormat === "dd/MM/yyyy";
};

export const getDateFormat = (currentUser, isLowerCase) => {
  if (currentUser.company.company_date_format == "dd/MM/yyyy") {
    return isLowerCase ? "dd/mm/yyyy" : "dd/MM/yyyy";
  } else {
    return isLowerCase ? "mm/dd/yyyy" : "MM/dd/yyyy";
  }
};

export const getCompanyDateFormat = (currentUser) => {
  let defaultDateFormat = monthFirst;
  if (checkNonUsFormat(currentUser.company.company_date_format)) {
    defaultDateFormat = dateFirst;
  }
  return defaultDateFormat;
};

export const companyDateFormat = function (dateString, currentUser, transitionDate) {
  if (!dateString) {
    return "";
  }
  let defaultDateFormat = getCompanyDateFormat(currentUser);
  const formatTransitionFeild = !transitionDate ? currentUser.company.tz : null;
  return format(dateString, formatTransitionFeild, defaultDateFormat);
};

export const companyDateTimeFormat = function (dateString, currentUser, timeFormat) {
  const dateTimeFormat = timeFormat ? timeFormat : "h:mm a";
  if (!dateString) {
    return "";
  }
  let defaultDateTimeFormat = monthFirst + dateTimeFormat;
  if (checkNonUsFormat(currentUser.company.company_date_format)) {
    defaultDateTimeFormat = dateFirst + " " + dateTimeFormat;
  } else {
    defaultDateTimeFormat = monthFirst + " " + dateTimeFormat;
  }
  return format(dateString, currentUser.company.tz, defaultDateTimeFormat);
};

// returns date string withouttimezone
export const dateStringWithoutTimezone = (dateString, companyDateFormat) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // don't update date format
  // js date format is 'DD/MM/YYYY' <- is not validate date range for js
  // js date format is 'MM/DD/YYYY' <- is validate date range for js saving date in the formate and displaying is according to company date selected format
  return `${month}/${day}/${year}`;
};

export const getCurrentMonth = () => {
  const currentDate = moment();
  const currentMonthName = currentDate.format("MMMM");
  const monthStartDate = moment().startOf("month");
  const monthEndDate = moment().endOf("month");

  return {
    monthName: currentMonthName,
    startDate: monthStartDate.format("YYYY-MM-DD"),
    endDate: monthEndDate.format("YYYY-MM-DD"),
  };
};

export const getLastMonth = () => {
  const currentDate = moment();
  const lastMonthName = currentDate.clone().subtract(1, "month").format("MMMM");
  const lastMonthStartDate = currentDate.clone().subtract(1, "month").startOf("month");
  const lastMonthEndDate = currentDate.clone().subtract(1, "month").endOf("month");

  return {
    monthName: lastMonthName,
    startDate: lastMonthStartDate.format("YYYY-MM-DD"),
    endDate: lastMonthEndDate.format("YYYY-MM-DD"),
  };
};

export const getCurrentQuarter = () => {
  const currentDate = moment();
  const currentQuarter = Math.floor((currentDate.month() + 3) / 3);
  const quarterStartDate = moment().startOf("quarter");
  const quarterEndDate = moment().endOf("quarter");

  return {
    quarter: currentQuarter,
    startDate: quarterStartDate.format("YYYY-MM-DD"),
    endDate: quarterEndDate.format("YYYY-MM-DD"),
  };
};

export const getPreviousQuarter = () => {
  const currentDate = moment();
  const quarterStartDate = currentDate.clone().startOf("quarter");
  const previousQuarterStartDate = quarterStartDate.clone().subtract(3, "months");
  const previousQuarterEndDate = quarterStartDate.clone().subtract(1, "day");
  const firstMonthOfPreviousQuarter = previousQuarterStartDate.clone().format("MMMM");
  const lastMonthOfPreviousQuarter = previousQuarterEndDate.clone().format("MMMM");

  return {
    startDate: previousQuarterStartDate.format("YYYY-MM-DD"),
    endDate: previousQuarterEndDate.format("YYYY-MM-DD"),
    firstMonth: firstMonthOfPreviousQuarter,
    lastMonth: lastMonthOfPreviousQuarter,
  };
};

export const getYTD = () => {
  const currentDate = moment();
  const yearStartDate = moment().startOf("year");
  const ytdEndDate = currentDate.clone();

  return {
    startDate: yearStartDate.format("YYYY-MM-DD"),
    endDate: ytdEndDate.format("YYYY-MM-DD"),
  };
};

export const getPreviousYear = () => {
  const currentDate = moment();
  const previousYearStartDate = currentDate.clone().subtract(1, "year").startOf("year");
  const previousYearEndDate = currentDate.clone().subtract(1, "year").endOf("year");

  return {
    startDate: previousYearStartDate.format("YYYY-MM-DD"),
    endDate: previousYearEndDate.format("YYYY-MM-DD"),
  };
};

export const calculateFiscalYear = (startDate) => {
  const fiscalYearStart = moment().month(3).date(1).startOf("day");
  const start = moment(startDate, "YYYY-MM-DD"); // Parse start date

  let fiscalYear = start.isBefore(fiscalYearStart) ? start.clone().subtract(1, "year") : start.clone();
  let fiscalYearStartYear = fiscalYear.month() >= 3 ? fiscalYear.year() : fiscalYear.year() - 1;
  let fiscalYearEndYear = fiscalYearStartYear + 1;

  // Adjust fiscal year for last quarter if the start date is in the first three months of the year
  if (start.month() <= 2 && start.month() >= 0) {
    fiscalYearStartYear++;
    fiscalYearEndYear++;
  }

  return {
    startYear: fiscalYearStartYear,
    endYear: fiscalYearEndYear,
  };
};
