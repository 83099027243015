import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";

const CheckNumberSeq = () => {
  return (
    <Col lg="6">
      <Field
        name="form.check_number_seq"
        label={
          <span
            dangerouslySetInnerHTML={{
              __html:
                "<label>Starting Check Number</label><small> (Optional: once first check is printed, contact accountmanagement@corpay.com for changes) </small>",
            }}
          />
        }
        component={RenderField}
        placeholder="check start number"
        type="text"
        id="check_number_seq"
      />
    </Col>
  );
};

export default CheckNumberSeq;
