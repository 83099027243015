import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col, Form, Row, OverlayTrigger, Popover } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import PaymentCountryPicker from "../../pickers/paymentCountryPicker";
import CurrencyPicker from "../../vp/pickers/reduxPicker/currencyPicker/currencyPicker";
import { getCurrencyCodeFromCountryCode } from "../../app.svc.Lookup";

class PayoneerBranded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parsedPurchasers: [],
      showWaitingMessage: false,
    };
    this.restApiService = new RestApi();
  }
  render() {
    return (
      <Fragment>
        {this.state.showWaitingMessage ? (
          <h5>
            Your payment method has been created. You will receive confirmation emails from Payoneer when your account
            has been created and is ready to accept payments
          </h5>
        ) : (
          <Fragment>
            <Row>
              <Form.Group as={Col} md="4" className={"color-red"}>
                <Form.Label style={{ textTransform: "capitalize" }}>
                  {this.props.t("purchaser")}:<span className={"color-red"}>*</span>
                </Form.Label>
                <Form.Control
                  required
                  as="select"
                  disabled={this.props.paymentMethodRed.id ? true : false}
                  onChange={this.purchaserSelected.bind(this)}
                  value={this.props.paymentMethodRed.purchaser_id || ""}
                >
                  <option value={-1}>Select...</option>
                  {this.state.parsedPurchasers.map((item, index) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <CurrencyPicker
                {...this.props}
                selected={this.props.paymentMethodRed && this.props.paymentMethodRed.currency_code}
                disabled={this.props.paymentMethodRed.id ? true : false}
              />
            </Row>
            <Row>
              <Col>
                {this.props.paymentMethodRed.payoneer_response_desc && (
                  <h4 className="text-secondary">
                    Status: <span className="text-primary">{this.props.paymentMethodRed.payoneer_response_desc}</span>
                  </h4>
                )}
                {this.props.paymentMethodRed.purchaser_id && this.props.paymentMethodRed.currency_code && (
                  <button className={"btn btn-primary"} onClick={this.getSetupUrl} type="button">
                    Login or Signup to Payoneer
                  </button>
                )}
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <h5 className="text-danger">
                  If you wish to be paid in multiple currencies you must create a payment method for each
                  purchaser-currency combination. All accounts are managed by Payoneer
                </h5>
              </Col>
            </Row>
          </Fragment>
        )}
      </Fragment>
    );
  }

  componentDidMount() {
    let parsedPurchasers = [];
    this.props.purchasers.forEach((purchaser) => {
      if (purchaser.accepted_payment_methods.payoneer_branded) {
        parsedPurchasers.push(purchaser);
      }
    });

    this.setState({ parsedPurchasers: parsedPurchasers });
  }

  getSetupUrl = () => {
    this.restApiService
      .get("payment_methods/setup_url", {
        currency_code: this.props.paymentMethodRed.currency_code,
        purchaser_id: this.props.paymentMethodRed.purchaser_id,
      })
      .then((result) => {
        window.open(result.data.url, "_blank");
        this.setState({ showWaitingMessage: true });
      });
  };

  purchaserSelected = (event) => {
    this.props.changePurchaser({ value: event.target.value > 0 ? event.target.value : null });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    paymentMethodRed: state.paymentMethodRed,
    payment_type: state.paymentMethodRed.payment_type,
    country: state.paymentMethodRed.country,
    currency_code: state.paymentMethodRed.currency_code,
    purchaser_id: state.paymentMethodRed.purchaser_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    countryPickerCallback: (payload) => {
      dispatch({ type: actionType.CHANGE_COUNTRY_CODE, payload: payload });
    },
    changePaymentType: (payload) => {
      dispatch({ type: actionType.CHANGE_PAYMENT_TYPE, payload: payload });
    },
    updateFields: (payload) => {
      dispatch({ type: actionType.CHANGE_PAYMENT_METHOD, payload: payload });
    },
    changeFirstName: (payload) => {
      dispatch({ type: actionType.CHANGE_FIRST_NAME, payload: payload });
    },
    changeLastName: (payload) => {
      dispatch({ type: actionType.CHANGE_LAST_NAME, payload: payload });
    },
    changeEmail: (payload) => {
      dispatch({ type: actionType.CHANGE_EMAIL, payload: payload });
    },
    changePhone: (payload) => {
      dispatch({ type: actionType.CHANGE_PHONE_NUMBER, payload: payload });
    },
    changePurchaser: (payload) => {
      dispatch({ type: actionType.CHANGE_PURCHASER, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PayoneerBranded));
