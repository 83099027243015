import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";

type modalContentPropsType = {
  header: ReactNode | null;
  headerIcon: ReactNode | null;
  body: ReactNode | null;
  footer: ReactNode | null;
  headerClass: string | undefined;
  headerTitleClass: string | undefined;
  modalBodyClass: string | undefined;
  hideCloseButton: boolean;
};
const ModalContent = ({
  header,
  headerIcon,
  body,
  footer,
  headerClass,
  headerTitleClass,
  modalBodyClass,
  hideCloseButton = false,
}: modalContentPropsType) => {
  return (
    <>
      {header && (
        <Modal.Header className={`${headerClass}`} closeButton={!hideCloseButton}>
          <Modal.Title className={`${headerTitleClass}`}>
            {header}
            {headerIcon && <i className={`icon ${headerIcon}`}></i>}
          </Modal.Title>
        </Modal.Header>
      )}
      {<Modal.Body className={`${modalBodyClass}`}>{body}</Modal.Body>}
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </>
  );
};

type customModalPropsType = {
  show?: boolean;
  onHide?: () => void;
  header?: ReactNode | null;
  headerIcon?: ReactNode | null;
  body?: ReactNode | null;
  footer?: ReactNode | null;
  size?: "sm" | "lg" | "xl" | undefined;
  dailog?: boolean;
  backdrop?: true | false | "static";
  dialogClass?: string | undefined;
  modalClass?: string | undefined;
  headerClass?: string | undefined;
  headerTitleClass?: string | undefined;
  contentClassName?: string | undefined;
  modalBodyClass?: string | string;
  dialogClassName?: string;
  centered?: boolean;
  hideCloseButton?: boolean;
};
const CustomModal = ({
  show = false,
  onHide = () => {},
  header = null,
  headerIcon = null,
  body = null,
  footer = null,
  size = undefined,
  dailog = false,
  dialogClass = "",
  modalClass = "",
  headerClass = "",
  headerTitleClass = "",
  backdrop = "static",
  contentClassName = "",
  modalBodyClass = "",
  dialogClassName = "",
  centered = false,
  hideCloseButton = false,
}: customModalPropsType) => {
  if (dailog) {
    return (
      <Modal
        size={size}
        className={`${modalClass}`}
        contentClassName={`${contentClassName}`}
        show={show}
        onHide={() => onHide()}
        backdrop={backdrop}
        centered={centered}
        dialogClassName={dialogClassName}
      >
        <Modal.Dialog className={`${dialogClass} p-0`}>
          <ModalContent
            header={header}
            headerIcon={headerIcon}
            headerClass={headerClass}
            headerTitleClass={headerTitleClass}
            modalBodyClass={modalBodyClass}
            body={body}
            footer={footer}
            hideCloseButton={hideCloseButton}
          />
        </Modal.Dialog>
      </Modal>
    );
  }

  return (
    <Modal
      size={size}
      className={`${modalClass}`}
      contentClassName={`${contentClassName}`}
      show={show}
      onHide={() => onHide()}
      backdrop={backdrop}
      centered={centered}
      dialogClassName={dialogClassName}
    >
      <ModalContent
        headerClass={headerClass}
        headerTitleClass={headerTitleClass}
        header={header}
        headerIcon={headerIcon}
        body={body}
        modalBodyClass={modalBodyClass}
        footer={footer}
        hideCloseButton={hideCloseButton}
      />
    </Modal>
  );
};

export default CustomModal;
