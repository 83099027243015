import { AxiosResponse } from "axios";
import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Control, Controller, RegisterOptions, useFormContext } from "react-hook-form";
import Select from "react-select";
import commonService from "services/common/commonSvc";
import { IDType } from "services/common/types/common.type";

type TBusinessUnitPicker = {
  name: string;
  business_unit_id?: number;
  control?: Control;
  rules?: RegisterOptions;
  required?: boolean;
  disabled?: boolean;
  label?: ReactNode;
  callBack?: (selected: TSelected) => void;
  modelData?: string;
  containerClassName?: string;
};

type TSelected = {
  value: IDType;
  label: string | number;
  id: string;
};

type TBusinessUnitPickerProps = {
  id?: number | string;
  value?: number | string;
  name?: string;
  label?: string;
  has_departments?: boolean;
  has_locations?: boolean;
  has_subsidiaries?: boolean;
};

const BusinessUnitPicker = ({
  name,
  required,
  disabled,
  modelData: modelDataStr,
  containerClassName,
  label,
  ...otherProps
}: TBusinessUnitPicker) => {
  const [options, setOptions] = useState<TBusinessUnitPickerProps[]>([]);
  const isMounted = useIsMounted();
  const [inactivePopulated, setInactivePopulated] = useState(false);
  const { control, getValues, setValue } = useFormContext();

  //Temp solution until we migrate picker as per id
  const [modelData] = useState(modelDataStr ? modelDataStr + "." : "");
  const businessUnitId = useGetValue(modelData + "business_unit_id");

  const sortedOptions = useMemo(() => {
    if (!options || options.length === 0) return [];
    const sorted = _.sortBy(options, ["name"]);
    return required ? sorted : [{ name: "-- Select Business Unit --", id: "" }, ...sorted];
  }, [options, required]);

  const initBusinessUnitObj = (obj: TBusinessUnitPickerProps | null) => {
    if (_.isPlainObject(obj)) {
      setValue(name, obj);
    }
  };

  const isInactive = (status: string) => {
    return status === "INACTIVE";
  };

  const getInactivebusinessUnit = async () => {
    try {
      const response: AxiosResponse = await restApiService.get("business_units/" + businessUnitId, {
        status: "ACTIVE",
      });
      if (_.isPlainObject(response?.data)) {
        const inactiveObj = response?.data;
        if (inactiveObj) {
          let obj = {
            ...inactiveObj,
            value: inactiveObj.id,
            label: (
              <span key={inactiveObj.id}>
                {inactiveObj.name}{" "}
                {isInactive(inactiveObj.status) && (
                  <span key={inactiveObj.id} className="inactiveOption">
                    {" "}
                    (INACTIVE)
                  </span>
                )}
              </span>
            ),
          };
          setInactivePopulated(true);
          initBusinessUnitObj(obj);
        }
      } else {
        initBusinessUnitObj(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mergeInactive = async (options: TBusinessUnitPickerProps[]) => {
    // Filter options to find the one with the specified businessUnitId
    const matchingOption = options?.find((option) => option.id === businessUnitId);

    if (matchingOption) {
      // Initialize business unit object if a matching option is found
      initBusinessUnitObj(matchingOption);
    } else if (!inactivePopulated && businessUnitId) {
      // If no match is found and the specified business unit is not already inactive, retrieve the inactive business unit
      await getInactivebusinessUnit();
    }
  };

  const getBusinessUnits = useCallback(async () => {
    const params: Record<string, any> = { status: "ACTIVE" };

    try {
      const result = await restApiService.get("business_units.lk", params, null, true, null, true);
      if (Array.isArray(result.data)) {
        const options = [...result.data];
        mergeInactive(options);
        if (isMounted.current) {
          setOptions(options);
        }
      }
    } catch {}
  }, [isMounted, businessUnitId]);

  useEffect(() => {
    getBusinessUnits();
  }, [getBusinessUnits]);

  return (
    <Form.Group className={`${containerClassName ?? ""}`}>
      {label && (
        <label className="pickerLabel">
          {label} <Mandatory required={required} />
        </label>
      )}
      <Controller
        control={control}
        name={name}
        rules={required ? { required: { value: true, message: "This field is required" } } : undefined}
        render={({ field: { value, onChange, ...field }, fieldState: { error, invalid } }) => (
          <>
            <Select
              {...field}
              isClearable={!required}
              value={value ?? null}
              getOptionLabel={(option) => option.name || ""}
              getOptionValue={(option) => option.id?.toLocaleString() || ""}
              className="pickerSelectedOption"
              options={sortedOptions}
              isDisabled={disabled}
              menuPlacement="auto"
              menuPosition="fixed"
              onChange={(selected) => {
                onChange(selected);
                setValue(modelData + "business_unit_id", selected?.id || null);
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  ...commonService.getInvalidStyle(Boolean(error?.message)),
                }),
              }}
            />
            {error?.message && <PickerErrorBlock error={error.message} />}
          </>
        )}
      />
    </Form.Group>
  );
};

export default BusinessUnitPicker;
