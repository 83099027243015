import axios from "axios";
import _ from "lodash";
import paymentTypeService from "./paymentMethod/paymentTypeSvc";

// NOTE: This service is deprecated and will be removed in a future release
// TODO: Remove this service and create a new service in typescript with class pattern

/** International */
export const isInternationalType = function (obj) {
  switch (obj.payment_type) {
    case "international":
      return true;
    case "payoneer":
      return true;
    case "cambridge":
      return true;
    case "wire":
      return true;
    case "fed-wire":
      return true;
    case "sepa":
      return true;
    case "uk-eft":
      return true;
    default:
      return false;
  }
};

/** check pay out */
export const isCheckPayout = (obj) => {
  return obj.is_check_payout;
};

/** Acount payout */
export const isAchType = function (obj) {
  return obj.payment_type === "ach";
};
export const isCanEftType = function (obj) {
  return obj.payment_type === "can-eft";
};
export const isJapanEftType = function (obj) {
  return obj.payment_type === "japan-eft";
};
export const isInternationalPayout = (obj) => {
  return obj.is_international_payout;
};

export const isAccountPayout = (obj) => {
  return (
    obj &&
    (obj.is_ach_payout ||
      (!obj.is_check_payout &&
        !obj.is_international_payout &&
        !obj.is_payoneer_account &&
        obj.payment_type != "credit_card"))
  );
};

export const MANAGE_PAYMENT_METHOD_FORM = "MANAGE_PAYMENT_METHOD_FORM";

export const mapReduxFormToPaymentMethodPayload = (paymentMethodRed) => {
  const paymentDetail = paymentMethodRed;
  let currency_code = paymentDetail.currency_code;
  if (paymentDetail.currency_code.value) {
    currency_code = paymentDetail.currency_code.value;
  }

  let formData = {};
  formData = {
    company: {
      is_error: false,
      payment_methods_attributes: [
        {
          confirm_account_number: paymentDetail.account_number,
          is_primary: paymentDetail.is_primary,
          account_type: paymentDetail.account_type,
          payee_type: paymentTypeService.isPayoneer(paymentDetail.payment_type) ? paymentDetail.payee_type : null,
          currency_code: currency_code,
          // saving using company api
          address_attributes: {
            country: paymentDetail.country ? paymentDetail.country : "USA",
            address1: paymentDetail.address1,
            address2: paymentDetail.address2,
            address3: paymentDetail.address3,
            city: paymentDetail.city,
            zipcode: paymentDetail.zipcode,
            state: paymentDetail.state,
          },
          // saving using payment_method api
          address: {
            country: paymentDetail.country ? paymentDetail.country : "USA",
            address1: paymentDetail.address1,
            address2: paymentDetail.address2,
            address3: paymentDetail.address3,
            city: paymentDetail.city,
            zipcode: paymentDetail.zipcode,
            state: paymentDetail.state,
          },
          account_name: paymentDetail.account_name,
          account_number: paymentDetail.account_number,
          payment_type: paymentDetail.payment_type,
          bank_name: paymentTypeService.isCreditCard(paymentMethodRed.payment_type)
            ? "Credit Card"
            : paymentDetail.bank_name,
          bank_address: paymentDetail.bank_address,
          bankCode: paymentDetail.bankCode,
          bik_code: paymentDetail.bik_code,
          vo_code: paymentDetail.vo_code,
          inn_tax_id: paymentDetail.inn_tax_id,
          branchCode: paymentDetail.branchCode,
          purchaser_id: paymentDetail.purchaser_id,
          account_tax_number: paymentDetail.account_tax_number,
          account_name_english: paymentDetail.account_name_english,
          bsb_code: paymentDetail.bsb_code,
          bic: paymentDetail.bic,
          routing: paymentDetail.routing,
          ifsc_code: paymentDetail.ifsc_code,
          phoneNumber: paymentDetail.phoneNumber,
          cardNumber: paymentDetail.cardNumber,
          institution_number: paymentDetail.institution_number,
          incorporation_number: paymentDetail.incorporation_number,
          id_number: paymentDetail.id_number,
          transit_number: paymentDetail.transit_number,
          sort_code: paymentDetail.sort_code,
          swiftCode: paymentDetail.swiftCode,
          iban: paymentDetail.iban,
          storefront_url: paymentDetail.storefront_url,
          pay_by_swift_code: paymentDetail.pay_by_swift_code,
          cpf: paymentDetail.cpf,
          suffix: paymentDetail.suffix,
          business_number: paymentDetail.paymentBusinessNumber,
          id: paymentDetail.id,
          contact_first_name: paymentDetail.contact_first_name,
          contact_last_name: paymentDetail.contact_last_name,
          contact_email: paymentDetail.contact_email,
          plaid_account_id: paymentDetail.plaid_account_id ? paymentDetail.plaid_account_id : null,
          merchant_name: paymentDetail.merchant_name ? paymentDetail.merchant_name : null,
          merchant_id: paymentDetail.merchant_id ? paymentDetail.merchant_id : null,
          mail_type: paymentDetail.mail_type,
          cambridge: paymentDetail.cambridge ? paymentDetail.cambridge : null,
          purpose_code: paymentDetail.purpose_code ? paymentDetail.purpose_code : null,
          program_id: paymentDetail.program_id,
          delivery_method: paymentDetail.delivery_method ? paymentDetail.delivery_method : null,
          subscribers_attributes: paymentDetail.subscribers,
          status: paymentDetail.status,
        },
      ],
    },
  };

  let intrmy = {};
  if (paymentDetail.intrmyBic) intrmy["bic"] = paymentDetail.intrmyBic;
  if (paymentDetail.intrmyCountry) intrmy["country"] = paymentDetail.intrmyCountry;
  if (paymentDetail.intrmyName) intrmy["name"] = paymentDetail.intrmyName;
  if (paymentDetail.intrmyNumber) intrmy["number"] = paymentDetail.intrmyNumber;
  if (!_.isEmpty(intrmy)) formData.company.payment_methods_attributes[0]["intrmy"] = { ...intrmy };

  if (paymentDetail.payment_type === "cambridge") {
    mapCambridgeField(formData.company.payment_methods_attributes[0]);
  }

  const paymentMethodFormData = _.pickBy(formData, _.identity);
  return paymentMethodFormData;
};

export const mapPaymentMethodPayloadForm = (details) => {
  const addressDetails = details.address ?? details.address_attributes;
  return {
    payment_type: details.payment_type,
    address1: addressDetails?.address1,
    address2: addressDetails?.address2,
    address3: addressDetails?.address3,
    city: addressDetails?.city,
    state: addressDetails?.state,
    zipcode: addressDetails?.zipcode,
    account_type: details.account_type,
    country: addressDetails?.country,
    payee_type: details.payee_type,
    currency_code: details.currency_code,
    id: details.id,
    account_name: details.account_name,
    account_number: details.account_number,
    bank_name: details.bank_name,
    bankCode: details.bankCode,
    branchCode: details.branchCode,
    account_tax_number: details.account_tax_number,
    account_name_english: details.account_name_english,
    bsb_code: details.bsb_code,
    bic: details.bic,
    routing: details.routing,
    ifsc_code: details.ifsc_code,
    phoneNumber: details.phoneNumber,
    cardNumber: details.cardNumber,
    institution_number: details.institution_number,
    incorporation_number: details.incorporation_number,
    id_number: details.id_number,
    is_primary: details.is_primary,
    transit_number: details.transit_number,
    sort_code: details.sort_code,
    swiftCode: details.swiftCode,
    iban: details.iban,
    storefront_url: details.storefront_url,
    cpf: details.cpf,
    suffix: details.suffix,
    purchaser_id: details.purchaser_id,
    contact_first_name: details.contact_first_name,
    contact_last_name: details.contact_last_name,
    contact_email: details.contact_email,
    plaid_account_id: details.plaid_account_id,
    merchant_name: details.merchant_name,
    merchant_id: details.merchant_id,
    mail_type: details.mail_type,
    subscribers: details.subscribers,
    pay_by_swift_code: details.pay_by_swift_code,
    cambridge: details.cambridge,
    properties: details.properties,
    payoneer_response_desc: details.payoneer_response_desc,
    program_id: details.program_id,
    purpose_code: details.purpose_code,
    intrmyBic: details.intrmy ? details.intrmy.bic : null,
    intrmyCountry: details.intrmy ? details.intrmy.country : null,
    intrmyName: details.intrmy ? details.intrmy.name : null,
    intrmyNumber: details.intrmy ? details.intrmy.number : null,
    // account_name_english:details.bank_address,
    bank_address: details.bank_address,
    delivery_method: details.delivery_method ? details.delivery_method : null,
    paymentBusinessNumber: details.business_number,
    status: details.status,
  };
};

//map all fields from cambridge to accrualify specific. Doing it here as this file has the raw payment_method data
export const mapCambridgeField = (payment_method) => {
  for (var key in payment_method.cambridge) {
    switch (key) {
      case "bankName":
        payment_method.bank_name = payment_method.cambridge[key];
        continue;
      case "routingCode":
        payment_method.routing = payment_method.cambridge[key];
        continue;
      case "accountNumber":
        payment_method.account_number = payment_method.cambridge[key];
        continue;
      case "accountHolderName":
        payment_method.account_name = payment_method.cambridge[key];
        continue;
      case "bankCountry":
        payment_method.address_attributes.country = payment_method.cambridge[key];
        continue;
      // case 'bankCurrency':
      //   return 'currency_code';
      case "ibanDigits":
        payment_method.iban = payment_method.cambridge[key];
        continue;
      case "swiftBicCode":
        payment_method.bic = payment_method.cambridge[key];
        continue;
      case "bankRegion":
        payment_method.address_attributes.state = payment_method.cambridge[key];
        continue;
      case "bankCity":
        payment_method.address_attributes.city = payment_method.cambridge[key];
        continue;
      case "bankAddressLine1":
        payment_method.address_attributes.address1 = payment_method.cambridge[key];
        continue;
      case "bankPostal":
        payment_method.address_attributes.zipcode = payment_method.cambridge[key];
        continue;
      default:
        continue;
    }
  }
};

// Validations //to validate UK Sort code
export const validateSortCode = async (sortCode) => {
  const url = `https://api.transferwise.com/v1/validators/sort-code?sortCode=${sortCode}`;
  try {
    const response = await axios.get(url);
    //     "errors": [
    //         {
    //             "code": "VALIDATION_NOT_SUCCESSFUL",
    //             "message": "sortCode has not passed validation.",
    //             "path": "sortCode",
    //             "arguments": [
    //                 "0892869"
    //             ]
    //         }
    //     ]
    // }

    // {
    //     "validation": "success"
    // }
    return response.data;
  } catch (error) {
    console.log(error);
    const { response } = error;
    console.log(response.data.errors[0]);
    throw new Error(response.data.errors[0].massage);
  }
};
