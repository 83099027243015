import React from "react";
import { Row } from "react-bootstrap";
import { ListWombat } from "wombatifier/components/wombatifier/listWombat";
import { NavTabs } from "wombatifier/components/wombatifier/navTabs";

export const List = () => {
  return (
    <>
      <Row style={{ margin: "10px 25px" }}>
        <NavTabs activePageName="Wombatifier" />
      </Row>
      <ListWombat />
    </>
  );
};
