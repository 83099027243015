import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field, change } from "redux-form";
import { RenderCheckBox } from "../../../../forms/bootstrapFields";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../reducers";

const VendorFormCheckboxList = ({ vendor }: any) => {
  const dispatch = useDispatch();
  const currentUserCompany = useTypedSelector((state) => state.user)?.company;

  const onChangeObj: any = {
    formName: "VendorForm",
    dispatch: dispatch,
  };

  const vendorChecklist = [
    {
      name: "is_1099_eligible",
      label: "1099 Eligible?",
      type: "checkbox",
      component: RenderCheckBox,
      require: undefined,
      onChangeCallBack: () => {},
      isShow: () => {
        return true;
      },
    },
    {
      name: "enforce_ein_validation",
      label: "Override EIN Check?",
      type: "checkbox",
      component: RenderCheckBox,
      require: undefined,
      onChangeCallBack: () => {},
      isShow: () => {
        return true;
      },
    },
    {
      name: "no_push",
      label: "Do not push to ERP",
      type: "checkbox",
      component: RenderCheckBox,
      require: undefined,
      onChangeCallBack: () => {},
      isShow: () => {
        return true;
      },
    },
    {
      name: "paid_outside",
      label: "Paid Outside?",
      type: "checkbox",
      component: RenderCheckBox,
      require: undefined,
      onChangeCallBack: () => {},
      isShow: () => {
        return true;
      },
    },
    {
      name: "po_not_required",
      label: "PO Not Required",
      type: "checkbox",
      component: RenderCheckBox,
      require: undefined,
      onChangeCallBack: () => {},
      isShow: () => {
        return true;
      },
    },
    {
      name: "portal_disabled",
      label: "Portal Disabled",
      type: "checkbox",
      component: RenderCheckBox,
      require: undefined,
      onChangeCallBack: () => {},
      isShow: () => {
        return true;
      },
    },
    {
      name: "has_w9",
      label: "Has W-9?",
      type: "checkbox",
      component: RenderCheckBox,
      require: undefined,
      onChangeCallBack: () => {},
      isShow: () => {
        return true;
      },
    },
    {
      name: "allow_contact_addresses_on_purchase_orders",
      label: "Contact Address on PO",
      type: "checkbox",
      component: RenderCheckBox,
      require: undefined,
      onChangeCallBack: () => {},
      isShow: () => {
        return true;
      },
    },
    {
      name: "force_push",
      label: "Force Sync with ERP",
      type: "checkbox",
      component: RenderCheckBox,
      require: undefined,
      onChangeCallBack: (onChangeObj: any) => {
        const { dispatch, formName } = onChangeObj;
        if (vendor?.no_push) {
          dispatch(change(formName, "force_push", false));
        }
      },
      isShow: () => {
        const isIntegrationEnabled = currentUserCompany?.has_integration;
        const isVendorCompany = vendor?.vendor_type === "COMPANY";
        const isVendorEmployee = vendor?.vendor_type === "EMPLOYEE";
        if (!vendor?.no_push && isIntegrationEnabled) {
          const syncSettings = currentUserCompany?.integration?.sync_settings;
          if (
            (isVendorCompany && syncSettings?.sync_vendor) ||
            (isVendorEmployee && syncSettings?.sync_vendor_employee)
          ) {
            return true;
          }
        }
      },
    },
  ];
  return (
    <Row>
      {vendorChecklist &&
        vendorChecklist.map(
          (list, index) =>
            list.isShow() && (
              <Col md="4" key={index}>
                <Field
                  component={list.component}
                  type={list.type}
                  name={list.name}
                  label={list.label}
                  required={list.require}
                  onChange={(e: any) => list.onChangeCallBack(onChangeObj)}
                />
              </Col>
            ),
        )}
    </Row>
  );
};

export default VendorFormCheckboxList;
