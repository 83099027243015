import React, { useCallback } from "react";
import { Field } from "redux-form";
import style from "../../../../../pages/vp/invoices/Invoice.module.css";
import { roundUpAmount } from "../../../../../services/vp/services/roundUpAmount";
import { RenderCheckBox, RenderFieldNumber } from "../../../../forms/bootstrapFields";
import { currencySymbolRenderer } from "../../../../../services/common/currencySymbolRendererService";
import _ from "lodash";
const calculateAmount = (item) => {
  let amount = parseFloat(item.qty > 0 ? item.unit_price * item.qty : item.unit_price);
  return roundUpAmount(amount, 2);
};

const showItemLineTotal = (item) => {
  let tax = item.tax ? parseFloat(item.tax) : 0;
  item.qty = parseFloat(item.qty ? item.qty : 0);
  item.amount = parseFloat(calculateAmount(item));
  item.tax = parseFloat(tax);
  item.total = roundUpAmount(parseFloat(tax + item.amount), 2);
  return item.total;
};

const changeItemQty = (qty, item) => {
  item.qty = qty ? parseFloat(qty) : 0;
  if (item.qty <= item.qty_balance) {
    showItemLineTotal(item);
    item.warning = undefined;
  } else {
    item.warning = `Can't exceed max limit ${item.qty_balance}`;
    item.qty = roundUpAmount(parseFloat(item.qty_balance), 5);
  }
};

const changeItemTax = (tax, item) => {
  item.tax = parseFloat(tax ? tax : 0);
  item.total = roundUpAmount(parseFloat(item.tax) + item.amount, 2);
};

const isItemFullyBilled = (item) => {
  if (_.isObject(item)) {
    return item.qty_balance > 0;
  }
};

const ItemLinesRenderer = ({ fields, currencyCode }) => {
  return (
    <>
      {fields.map((item, index) => {
        return isItemFullyBilled(fields.get(index)) ? (
          <tr key={index}>
            <td>
              <Field
                name={`${item}.isAllow`}
                component={RenderCheckBox}
                checked={fields.get(index) && fields.get(index).isAllow}
              />
            </td>
            <td>
              {fields.get(index) &&
                (fields.get(index).product_name
                  ? fields.get(index).product_name
                  : fields.get(index).product_item && fields.get(index).product_item.name)}
            </td>
            <td>
              <Field
                name={`${item}.qty`}
                type="number"
                max={fields.get(index).qty_balance}
                component={RenderFieldNumber}
                className={style.itemQty}
                disabled={!fields.get(index) || !fields.get(index).isAllow}
                onChange={(e) => changeItemQty(e.target.value, fields.get(index))}
              />
              <span className="custom-error-msg">{fields.get(index) && fields.get(index).warning}</span>
            </td>
            <td className="p-1">{fields.get(index) && fields.get(index).receipt_item_qty}</td>
            <td>
              {fields.get(index) && fields.get(index).unit_price && (
                <>
                  {currencySymbolRenderer(currencyCode)}
                  {fields.get(index) && fields.get(index).unit_price}
                </>
              )}
            </td>
            <td>
              <Field
                name={`${item}.tax`}
                type="number"
                component={RenderFieldNumber}
                className={style.itemQty}
                disabled={!fields.get(index) || !fields.get(index).isAllow}
                onChange={(e) => changeItemTax(e.target.value, fields.get(index))}
              />
            </td>
            <td>{fields.get(index) && fields.get(index).billed_item_qty}</td>
            <td>
              {fields.get(index) && fields.get(index).total && (
                <>
                  {currencySymbolRenderer(currencyCode)}
                  {showItemLineTotal(fields.get(index))}
                </>
              )}
            </td>
          </tr>
        ) : null;
      })}
    </>
  );
};

export default ItemLinesRenderer;
