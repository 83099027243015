import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import SubsidiaryApis from "services/admin/subsidiaries/subsidiaryApis";
import {
  manipulateLinks,
  manipulateSubsidiaryLinks,
  subsidiaryV2Links,
} from "services/admin/subsidiaries/subsidiarySvc";
import { SubsidiaryDetailType } from "services/admin/subsidiaries/types";
import { CreateNotification, NotificationType } from "services/general/notifications";
import FormSubsidiary from "./form";

type LocationStateType = {
  isFromDetailPage?: boolean;
};

const EditSubsidiary = () => {
  const { id } = useParams<{ id: string }>();
  const [subsidiary, setSubsidiary] = useState<SubsidiaryDetailType>();
  const [returnTo, setReturnTo] = useState<string>("");
  const history = useHistory();
  const { t } = useTranslation();

  const getSubsidiary = async () => {
    try {
      const response = await SubsidiaryApis.getSubsidiary(id);
      await manipulateSubsidiaryLinks(response);
      const isFromDetailPage: LocationStateType = history?.location?.state || false;
      setReturnTo(isFromDetailPage ? `/ap/subsidiaries/${id}` : "/ap/subsidiaries");
      response.isEditForm = true;
      response.subsidiary_location_links_attributes = response.subsidiary_manager_location_links;
      response.subsidiary_links_attributes = response.subsidiary_manager_department_links;
      response.subsidiary_business_unit_attributes = response.subsidiary_manager_business_unit_links;
      response.subsidiary_project_attributes = response.subsidiary_manager_project_links;
      response.subsidiary_metadata_attributes = response.subsidiary_manager_metadata_links;

      if (response.subsidiary_metadata_attributes && response.subsidiary_metadata_attributes.length > 0) {
        response.subsidiary_metadata_attributes.map((metadata: any) => {
          metadata.metadata_field_type = metadata.metadata_field_id?.replace(/\s/g, "").toLowerCase();
        });
      }

      setSubsidiary(response);
    } catch (error) {
      console.error("Error occurred while fetching subsidiary data:", error);
    }
  };

  useEffect(() => {
    getSubsidiary();
  }, [id]);

  const manipulateSubsidiaryLinksV2Data = (formValue: SubsidiaryDetailType) => {
    const { subsidiary_business_unit_attributes, subsidiary_project_attributes, subsidiary_metadata_attributes } =
      formValue;
    const businessUnitLinks = subsidiaryV2Links(subsidiary_business_unit_attributes, "BusinessUnit");
    const projectLinks = subsidiaryV2Links(subsidiary_project_attributes, "Project");
    const metadataLinks = subsidiaryV2Links(subsidiary_metadata_attributes, "MetadataField");

    // Combine the results from both business units and projects
    return [...businessUnitLinks, ...projectLinks, ...metadataLinks];
  };

  const submitSubsidiary = async (formValue: SubsidiaryDetailType) => {
    let subsidiary = {
      id: formValue.id,
      name: formValue.name,
      status: formValue.status,
      external_id: formValue.external_id,
      country: formValue.country,
      currency_code: formValue.currency_code,
      sub_type: formValue.sub_type,
      subsidiary_links_attributes: manipulateLinks(formValue.subsidiary_links_attributes),
      subsidiary_manager_location_links_attributes: manipulateLinks(formValue.subsidiary_location_links_attributes),
      subsidiary_links_attributes_v2: manipulateSubsidiaryLinksV2Data(formValue),
    };
    const payload: any = {
      subsidiary: subsidiary,
    };
    try {
      await SubsidiaryApis.editSubsidiary(id, payload);
      history.push(returnTo);
      CreateNotification(t("success"), t("subsidiary.saved"), NotificationType.success);
    } catch (error: any) {
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data) && response.data["name"]) {
          CreateNotification(
            "Name Conflict",
            subsidiary.name + " " + response.data["name"][0],
            NotificationType.warning,
          );
        } else {
          CreateNotification("Validation Error", "Please enter valid data", NotificationType.danger);
        }
      }
    }
  };

  return (
    <>
      {subsidiary && (
        <ErrorBoundary>
          <FormSubsidiary initialValues={subsidiary} onSubmit={submitSubsidiary} />
        </ErrorBoundary>
      )}
    </>
  );
};

export default EditSubsidiary;
