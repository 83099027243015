import moment from "moment";
import { formattedAmount } from "../../../services/general/helpers";
import { translate } from "../../../services/general/translation";

const purchase_orders = [
  {
    field: "selectAll",
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    maxWidth: 40,
    minWidth: 40,
    menuTabs: [],

    // sortable: true ,
    // filter: true,
  },
  {
    field: "id",
    headerName: "ID",
    hide: true,
    headerValueGetter: function () {
      return translate("id");
    },

    // sortable: true ,
    // filter: true,
  },
  {
    field: "number",
    headerName: "PO #",

    filter: "agTextColumnFilter",
    minWidth: 119,
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      filterOptions: ["contains"],
      suppressAndOrCondition: true,
    },
    cellRenderer: "childCellRouting",
    cellRendererSelector: function (params) {
      return;
    },
    cellStyle: {
      color: "#FF5C35",
      fontFamily: "acre-regular",
      fontSize: 14,
      fontWeight: "bold",
    },
    headerValueGetter: function () {
      return translate("po_number");
    },
  },
  {
    field: "purchaser.name",
    headerName: "Customer",
    filter: "agTextColumnFilter",
    cellRenderer: "childCellRouting",
    cellRendererSelector: function (params) {
      return;
    },
    width: 120,
    minWidth: 120,
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      filterOptions: [
        "contains",
        {
          displayKey: "No data Specified",
          displayName: "No data Specified",
          test: function (filterValue, cellValue) {
            return cellValue == null;
          },
          hideFilterInput: true,
        },
      ],
      suppressAndOrCondition: true,

      includeBlanksInEquals: true,
      includeBlanksInLessThan: false,
      includeBlanksInGreaterThan: false,
    },
    headerValueGetter: function () {
      return translate("customer");
    },
    // cellStyle: {
    //     color: '#FF5C35',
    //     fontFamily: 'Lato',
    //     fontSize: 14,
    //     fontWeight: 'bold'
    // }
  },
  {
    field: "date",
    headerName: "Date",
    filter: "agDateColumnFilter",
    cellRenderer: (data) => {
      return moment(data.value).format(data.dateFormat);
    },
    width: 189,
    minWidth: 189,
    filterParams: {
      suppressAndOrCondition: true,
      filterOptions: ["equals", "greaterThan", "lessThan", "inRange"],
      browserDatePicker: true,
    },
    cellRendererSelector: function (params) {
      return params.data && !params.data.id;
    },
    headerValueGetter: function () {
      return translate("date");
    },
  },
  {
    field: "start_date",
    headerName: "Start Date",
    hide: true,
    headerValueGetter: function () {
      return translate("start_date");
    },
  },
  {
    field: "end_date",
    headerName: "End Date",
    hide: true,
    headerValueGetter: function () {
      return translate("end_date");
    },
  },
  {
    field: "amount",
    headerName: "Gross Amount",
    minWidth: 120,
    cellRenderer: (params) => formattedAmount(params.data?.amount, params.data?.currency?.iso_code),
    // cellRenderer:'agGroupCellRenderer',
    // cellRendererParams: {
    //     // footerValueGetter: (params) => console.log(params)
    //     footerValueGetter: (params) => params.value,
    // },

    // aggFunc: "sum",

    filter: "agNumberColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
      filterOptions: ["equals", "greaterThan", "lessThan", "inRange"],
    },
    // cellRendererSelector: 'customPinnedRowRenderer',
    headerValueGetter: function () {
      return translate("amount");
    },
  },
  {
    field: "currency.iso_code",
    headerName: "Currency Code",
    hide: true,
    headerValueGetter: function () {
      return translate("currency_code");
    },
  },
  {
    field: "po_type",
    headerName: "Type",
    hide: true,
    headerValueGetter: function () {
      return translate("type");
    },
  },
  {
    field: "open_balance",
    headerName: "Open PO Balance",
    cellRenderer: (params) => formattedAmount(params.data?.open_balance, params.data?.currency?.iso_code),
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "greaterThan", "lessThan", "inRange"],
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    minWidth: 120,
    // hide:true,
    headerValueGetter: function () {
      return translate("openPoBal");
    },
  },
  {
    field: "invoice_balance",
    headerName: "Invoice Balance",
    cellRenderer: (params) => formattedAmount(params.data?.invoice_balance, params.data?.currency?.iso_code),
    minWidth: 120,
    hide: true,
    filter: "agNumberColumnFilter",
    filterParams: {
      filterOptions: ["equals", "greaterThan", "lessThan", "inRange"],
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    // cellRendererSelector: 'customPinnedRowRenderer',
    // cellRenderer:'agGroupCellRenderer',
    // cellRendererParams: {
    //     // footerValueGetter: (params) => console.log(params)
    //     footerValueGetter: (params) => 'Total (' + params.value + ')'
    // },
    // aggFunc: "sum",
    headerValueGetter: function () {
      return translate("invoice_balance");
    },
  },
  {
    field: "requestor.name",
    headerName: "Requestor",
    // filterParams: {
    //     // clearButton: true,
    //     // applyButton: true,
    //     // debounceMs: 200,
    //     suppressAndOrCondition: true
    // }
    minWidth: 120,
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      filterOptions: [
        "contains",

        {
          displayKey: "No data Specified",
          displayName: "No data Specified",
          test: function (filterValue, cellValue) {
            return cellValue == null;
          },
          hideFilterInput: true,
        },
      ],
      suppressAndOrCondition: true,

      includeBlanksInEquals: true,
      includeBlanksInLessThan: false,
      includeBlanksInGreaterThan: false,
    },
    headerValueGetter: function () {
      return translate("requestor");
    },
  },
  {
    field: "department.name",
    headerName: "Department",
    hide: true,
    headerValueGetter: function () {
      return translate("department");
    },
  },
  {
    // displayName: 'Project',
    headerName: "Project",
    field: "project_name",
    hide: true,
    headerValueGetter: function () {
      return translate("project");
    },
  },
  {
    field: "description",
    // displayName: 'grid.purchase_order.description',
    hide: true,
    headerValueGetter: function () {
      return translate("description");
    },
  },
  {
    field: "status",
    headerName: "Status",
    // displayName: 'grid.purchase_order.status',
    filter: "agTextColumnFilter",
    // filter:"status",
    minWidth: 126,
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    floatingFilterComponent: "floatingFilterComponent",
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    headerValueGetter: function () {
      return translate("status");
    },
  },
  {
    // field: '',
    field: "Actions",
    cellRenderer: "childMessageRenderer",
    sortable: false,
    resizable: false,
    width: 100,
    maxWidth: 100,
    cellRendererParams: {
      viewLink: "/ar/purchase_orders/",
      submitInvoiceLink: "/ar/submit_invoice",
    },
    cellRendererSelector: function (params) {
      return params.data && !params.data.id;
    },
    headerValueGetter: function () {
      return translate("actions");
    },
  },
];

export default purchase_orders;
