import React, { Component } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import "./panel.style.css";
export default class CollapsePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleImg: true,
    };
  }
  render() {
    return (
      <Accordion className={"mt-4 collapseCntr w-100"} defaultActiveKey={this.props.open ? "0" : null}>
        <Card style={this.props.cardStyle} className="panel-section">
          <Card.Header className={"collapseHeader"} style={{ backgroundColor: "#fff" }}>
            <div className={"d-flex flex-row"}>
              <p className={"mb-0 align-self-center mr-auto"} style={this.props.cardHeadingStyle}>
                {this.props.iconClass ? <i className={this.props.iconClass}></i> : null}
                {this.props.heading}
              </p>
              {this.props.leftBtn ? (
                <button className="btn btn-primary btn-sm" onClick={this.props.onClick}>
                  {this.props.icon && this.props.icon}
                  {this.props.leftBtn}
                </button>
              ) : null}
              <Accordion.Toggle
                onClick={this.toggleImg}
                as={Button}
                variant="link"
                eventKey="0"
                className={[this.state.toggleImg]}
              >
                {this.state.toggleImg ? (
                  <img className="" src={require("../../assets/icons/actions/icon_arrow_up.svg").default} alt="menu" />
                ) : (
                  <img
                    className=""
                    src={require("../../assets/icons/actions/icon_arrow_down.svg").default}
                    alt="menu"
                  />
                )}
              </Accordion.Toggle>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body style={this.props.cardBodyStyle}>
              {this.props.bodyContent}
              {this.props.children}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }

  toggleImg = () => {
    this.setState({
      toggleImg: !this.state.toggleImg,
    });
  };
}
// heading
// subHeading
// headingStyle
// cardHeaderStyle
//
