import moment from "moment";
import { payReimbursementType } from "../../../../services/admin/expenses/payReimbursementType";

class PayExpenseService {
  addStatus = ({ expenseReport }: { expenseReport: payReimbursementType.payObject }) => {
    if (!expenseReport.daysTilDue) {
      let dueDate = moment(expenseReport.due_date);
      let today = moment().startOf("day");
      expenseReport.daysTilDue = dueDate.diff(today, "days", true);
    }
    const diff = expenseReport.daysTilDue;
    if (
      (expenseReport.status === "OPEN" && expenseReport.payment_links_status === "PENDING") ||
      (expenseReport.status === "OPEN" && expenseReport.payment_links_status === "SCHEDULED") ||
      (expenseReport.status === "OPEN" && expenseReport.payment_links_status === "APPROVED") ||
      (expenseReport.status === "PAID" && expenseReport.payment_links_status === "PROCESSING")
    ) {
      expenseReport.status = expenseReport.status + " (" + expenseReport.payment_links_status + ")";
    }
    if (expenseReport.status === "PAID" || expenseReport.status === "CLOSED") {
      expenseReport.due_status = expenseReport.status;
      if (expenseReport.payment_links_status)
        expenseReport.due_status = expenseReport.due_status + " (" + expenseReport.payment_links_status + ")";
    } else {
      if (diff < 0) {
        expenseReport.due_status = "PAST DUE";
      } else if (diff === 0) {
        expenseReport.due_status = "DUE TODAY";
      } else if (1 <= diff && diff <= 7) {
        expenseReport.due_status = "DUE SOON";
      } else if (diff >= 8) {
        expenseReport.due_status = "NEW";
      }
    }
  };

  decorateExpenseReport = ({ response }: { response: payReimbursementType.payObject[] }) => {
    response.forEach((expenseReport) => {
      this.addStatus({ expenseReport });
    });
    return response;
  };
}

const payReimbursementSvc = new PayExpenseService();
export default payReimbursementSvc;
