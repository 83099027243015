import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RestApi from "../../../providers/restApi";
const LinkedPurchaseOrders = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      {props.purchase_orders && props.purchase_orders.length > 0 ? (
        <Table striped bordered hover className="mb-0">
          <thead>
            <tr>
              <th className="primaryFontColor"> PO #</th>
              <th className="primaryFontColor">{t("details.status")}</th>
              <th className="primaryFontColor">{t("details.amount")}</th>
              <th className="primaryFontColor">{t("details.invoiceDate")}</th>
              <th className="primaryFontColor">{t("gridColumns.openPoBal")}</th>
            </tr>
          </thead>
          <tbody>
            {props.purchase_orders.map((purchase_order) => {
              return (
                <tr key={purchase_order.id}>
                  <td>
                    <a
                      className="primaryLinkColor"
                      variant="primary"
                      href={new RestApi().makeAngularURLWithId("purchase_orders/", purchase_order.id)}
                    >
                      {purchase_order.number}
                    </a>
                  </td>
                  <td>{purchase_order.status}</td>
                  <td>{purchase_order.amount}</td>
                  <td>{purchase_order.date}</td>
                  <td>{purchase_order.open_balance}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <h5 className="text-center mt-3 mb-3">- {t("warnings.noLinkedPurchaseOrders")} -</h5>
      )}
    </div>
  );
};

export default LinkedPurchaseOrders;
