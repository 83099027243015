import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  InvoiceSettingsHeaderLevel,
  InvoiceSettingsItemLine,
  InvoiceSettingsExpenseLine,
  InvoiceSettingApproval,
} from "./invoiceSettings";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import companySettingStyle from "../../companySettingTab.module.css";
import CommonSettingField from "../../components/commonSettingField";

const InvoiceSetting = () => {
  const formName = "companyDefaultForm";
  const dispatch = useDispatch();

  const isShowObj = {};
  const company_default = useSelector((state) => state.form.companyDefaultForm.values.company_default);
  const company = useSelector((state) => state.form.companyDefaultForm.values.company);
  const current_user = useSelector((state) => state.user);
  isShowObj.company_default = company_default;
  isShowObj.company = company;
  isShowObj.current_user = current_user;

  const onChangeObj = {};
  onChangeObj.formName = formName;
  onChangeObj.dispatch = dispatch;
  onChangeObj.company_default = company_default;
  onChangeObj.company = company;

  return (
    <>
      <Col md="12" className="px-4">
        <Row>
          <Col>
            <div className={`${companySettingStyle.settingsTitle}`}>Header Level</div>
            <hr className="mt-0" />
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {InvoiceSettingsHeaderLevel.filter((setting) => setting.col === 1).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
              <Col md="4">
                {/* coloum no. 2 */}
                {InvoiceSettingsHeaderLevel.filter((setting) => setting.col === 2).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
              <Col md="4">
                {/* coloum no. 3 */}
                {InvoiceSettingsHeaderLevel.filter((setting) => setting.col === 3).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={`${companySettingStyle.settingsTitle}`}>Item Line</div>
            <hr className="mt-0" />
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {InvoiceSettingsItemLine.filter((setting) => setting.col === 1).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
              <Col md="4">
                {/* coloum no. 2 */}
                {InvoiceSettingsItemLine.filter((setting) => setting.col === 2).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
              <Col md="4">
                {/* coloum no. 3 */}
                {InvoiceSettingsItemLine.filter((setting) => setting.col === 3).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={`${companySettingStyle.settingsTitle}`}>
              Expense Line
              <hr className="mt-0" />
            </div>
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {InvoiceSettingsExpenseLine.filter((setting) => setting.col === 1).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
              <Col md="4">
                {/* coloum no. 2 */}
                {InvoiceSettingsExpenseLine.filter((setting) => setting.col === 2).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
              <Col md="4">
                {/* coloum no. 3 */}
                {InvoiceSettingsExpenseLine.filter((setting) => setting.col === 3).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>

        {InvoiceSettingApproval.length > 0 && (
          <>
            <Row>
              <Col>
                <div className={`${companySettingStyle.settingsTitle}`}>Approval</div>
                <hr className="mt-0" />
                <Row>
                  {/* To show diffent columns we will filter json accronding to col value */}
                  <Col md="4">
                    {/* coloum no. 1 */}
                    {InvoiceSettingApproval.filter((setting) => setting.col === 1).map((setting, index) => (
                      <CommonSettingField
                        key={index}
                        setting={setting}
                        isShowObj={isShowObj}
                        onChangeObj={onChangeObj}
                      />
                    ))}
                  </Col>
                  <Col md="4">
                    {/* coloum no. 2 */}
                    {InvoiceSettingApproval.filter((setting) => setting.col === 2).map((setting, index) => (
                      <CommonSettingField
                        key={index}
                        setting={setting}
                        isShowObj={isShowObj}
                        onChangeObj={onChangeObj}
                      />
                    ))}
                  </Col>
                  <Col md="4">
                    {/* coloum no. 3 */}
                    {InvoiceSettingApproval.filter((setting) => setting.col === 3).map((setting, index) => (
                      <CommonSettingField
                        key={index}
                        setting={setting}
                        isShowObj={isShowObj}
                        onChangeObj={onChangeObj}
                      />
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </>
  );
};

export default InvoiceSetting;
