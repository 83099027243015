import React from "react";
import style from "./objectChanges.module.css";
import { Table } from "react-bootstrap";
import changeOrderService from "../../../services/admin/changeOrders/changeOrderSvc";
import { PurchaseOrderType } from "../../../services/admin/purchaseOrders/purchaseOrderType";
import { PoChangeOrderType } from "../../../services/admin/changeOrders/poChangeOrderApprovalsType";
import ChangedPropertyId from "./changedPropertyId";
import ChangedProperty from "./changedProperty";
import _ from "lodash";
import { useTypedSelector } from "reducers";

type LineType = "Item" | "Expenses";

type GLImpactTableTableProps = {
  invoiceDebitAccounts: PurchaseOrderType.InvoiceDebitAccountsType[];
  changeOrderInvoiceDebitAccounts: PoChangeOrderType.InvoiceDebitAccountsAttributeType[];
  poItems: PurchaseOrderType.PoItemType[];
  poChangeOrderItems: PoChangeOrderType.PoItemsAttributeType[];
};

type RenderGLRowPropType = {
  newItem: PoChangeOrderType.InvoiceDebitAccountsAttributeType | PoChangeOrderType.PoItemsAttributeType;
  oldItem?: PurchaseOrderType.InvoiceDebitAccountsType | PurchaseOrderType.PoItemType;
  index: number;
  className: string;
  lineType: LineType;
};

const isExpenseLine = (linetype: LineType): boolean => {
  return linetype === "Expenses";
};

const getAccountInfo = (item: any, lineType: LineType): string | undefined => {
  if (!item || !lineType) return undefined;

  const [first, second] = isExpenseLine(lineType)
    ? [item.account_number, item.account_name]
    : [item.account?.number, item.account?.name];

  return [first, second].filter(Boolean).join(" - ");
};

const RenderGLRow = ({ className, index, lineType, newItem, oldItem }: RenderGLRowPropType) => {
  const currentUser = useTypedSelector((state) => state.user);
  const fullOldItem = oldItem as any;

  return (
    <tr key={index} className={className}>
      <td>{index + 1}</td>

      <td>{lineType}</td>

      <ChangedPropertyId
        endpoint="accounts"
        newItem={newItem}
        oldItem={oldItem}
        property="account_id"
        formatter={(obj) => `${obj.number} - ${obj.name}`}
        value={getAccountInfo(fullOldItem, lineType)}
      />
      <ChangedProperty newItem={newItem} oldItem={oldItem} property="amount" />

      <ChangedPropertyId
        endpoint="departments"
        newItem={newItem}
        oldItem={oldItem}
        property="department_id"
        formatter={(obj) => obj.name}
        value={isExpenseLine(lineType) ? fullOldItem?.department_name : fullOldItem?.department?.name}
      />

      <ChangedPropertyId
        endpoint="units"
        newItem={newItem}
        oldItem={oldItem}
        property="unit_id"
        formatter={(obj) => obj.name}
        value={isExpenseLine(lineType) ? fullOldItem?.unit_name : fullOldItem?.unit?.name}
      />

      <ChangedPropertyId
        endpoint="locations"
        newItem={newItem}
        oldItem={oldItem}
        property="location_id"
        formatter={(obj) => obj.name}
        value={isExpenseLine(lineType) ? fullOldItem?.location_name : fullOldItem?.location?.name}
      />

      {currentUser.company.has_projects && (
        <ChangedPropertyId
          endpoint="projects"
          newItem={newItem}
          oldItem={oldItem}
          property="project_id"
          formatter={(obj) => obj.name}
          value={isExpenseLine(lineType) ? fullOldItem?.project_name : fullOldItem?.project?.name}
        />
      )}

      {currentUser.company.has_categories && (
        <ChangedPropertyId
          endpoint="categories"
          newItem={newItem}
          oldItem={oldItem}
          property="category_id"
          formatter={(obj) => obj.name}
          value={isExpenseLine(lineType) ? fullOldItem?.category_name : fullOldItem?.category?.name}
        />
      )}

      {currentUser.company.has_event_codes && (
        <td>
          {(oldItem && "event_code" in oldItem && oldItem.event_code?.name) ??
            (oldItem && "event_code_name" in oldItem && oldItem.event_code_name)}
        </td>
      )}

      {currentUser.company.has_sponsorships && (
        <ChangedPropertyId
          endpoint="sponsorships"
          newItem={newItem}
          oldItem={oldItem}
          property="sponsorship_id"
          formatter={(obj) => obj.name}
          value={isExpenseLine(lineType) ? fullOldItem?.sponsorship_name : fullOldItem?.sponsorship?.name}
        />
      )}

      {currentUser.company.allow_for_business_unit && (
        <ChangedPropertyId
          endpoint="business_units"
          newItem={newItem}
          oldItem={oldItem}
          property="for_business_unit_id"
          formatter={(obj) => obj.name}
          value={isExpenseLine(lineType) ? fullOldItem?.for_business_unit_name : fullOldItem?.for_business_unit?.name}
        />
      )}

      {currentUser.company.allow_for_location && (
        <ChangedPropertyId
          endpoint="locations"
          newItem={newItem}
          oldItem={oldItem}
          property="for_location_id"
          formatter={(obj) => obj.name}
          value={isExpenseLine(lineType) ? fullOldItem?.for_location_name : fullOldItem?.for_location?.name}
        />
      )}

      {currentUser.company.allow_for_project && (
        <ChangedPropertyId
          endpoint="projects"
          newItem={newItem}
          oldItem={oldItem}
          property="for_project_id"
          formatter={(obj) => obj.name}
          value={isExpenseLine(lineType) ? fullOldItem?.for_project_name : fullOldItem?.for_project?.name}
        />
      )}

      {currentUser.company.has_for_subsidiaries && (
        <ChangedPropertyId
          endpoint="for_subsidiaries"
          newItem={newItem}
          oldItem={oldItem}
          property="for_subsidiary_id"
          formatter={(obj) => obj.name}
          value={isExpenseLine(lineType) ? fullOldItem?.for_subsidiary_name : fullOldItem?.for_subsidiary?.name}
        />
      )}

      {currentUser.company.has_secondary_categories && (
        <ChangedPropertyId
          endpoint="secondary_categories"
          newItem={newItem}
          oldItem={oldItem}
          property="secondary_category_id"
          formatter={(obj) => obj.name}
          value={isExpenseLine(lineType) ? fullOldItem?.secondary_category_name : fullOldItem?.secondary_category?.name}
        />
      )}

      {currentUser.company.has_vineyard_codes && <td>{oldItem?.vineyard_code_name}</td>}

      <ChangedProperty newItem={newItem} oldItem={oldItem} property="memo" />
    </tr>
  );
};

const GLImpactTable = ({
  invoiceDebitAccounts,
  changeOrderInvoiceDebitAccounts,
  poChangeOrderItems,
  poItems,
}: GLImpactTableTableProps) => {
  const differentiatedPoDebitLines = changeOrderService.getDifferentiatedCOObjects<
    PurchaseOrderType.InvoiceDebitAccountsType,
    PurchaseOrderType.InvoiceDebitAccountsType[],
    PoChangeOrderType.InvoiceDebitAccountsAttributeType,
    PoChangeOrderType.InvoiceDebitAccountsAttributeType[]
  >(invoiceDebitAccounts, changeOrderInvoiceDebitAccounts);

  const differentiatedPoLineItems = changeOrderService.getDifferentiatedCOObjects<
    PurchaseOrderType.PoItemType,
    PurchaseOrderType.PoItemType[],
    PoChangeOrderType.PoItemsAttributeType,
    PoChangeOrderType.PoItemsAttributeType[]
  >(poItems, poChangeOrderItems);

  const currentUser = useTypedSelector((state) => state.user);

  return (
    <Table bordered responsive className={`text-left m-0 ${style.thBgColor}`}>
      <thead>
        <tr>
          <th>#</th>
          <th>Line Type</th>
          <th>Account</th>
          <th>Amount</th>
          <th>Department</th>
          <th>Unit</th>
          <th>Location</th>
          {currentUser.company.has_projects && <th>Project</th>}
          {currentUser.company.has_categories && <th>Category</th>}
          {currentUser.company.has_event_codes && <th>Event Code</th>}
          {currentUser.company.has_sponsorships && <th>Sponsorship</th>}
          {currentUser.company.allow_for_business_unit && <th>For Businees Unit </th>}
          {currentUser.company.allow_for_location && <th>For Location</th>}
          {currentUser.company.allow_for_project && <th>For Project</th>}
          {currentUser.company.has_for_subsidiaries && <th>For Subsidiary</th>}
          {currentUser.company.has_secondary_categories && <th>Secondary Category</th>}
          {currentUser.company.has_vineyard_codes && <th>Vineyard Code</th>}
          <th>Memo</th>
        </tr>
      </thead>

      <tbody>
        {differentiatedPoLineItems &&
          _.isArray(differentiatedPoLineItems) &&
          differentiatedPoLineItems?.map((differentiatedPoLineItem, index) => {
            const { change_type, newItem, oldItem } = differentiatedPoLineItem;
            switch (change_type) {
              case "ADDED":
                return (
                  <RenderGLRow
                    newItem={newItem}
                    oldItem={oldItem}
                    index={index}
                    className={style.updatedItem}
                    lineType="Item"
                  />
                );
              case "DELETED":
                return (
                  <RenderGLRow
                    newItem={newItem}
                    oldItem={oldItem}
                    index={index}
                    className={style.deletedItem}
                    lineType="Item"
                  />
                );
              default:
                return <RenderGLRow newItem={newItem} oldItem={oldItem} index={index} className={""} lineType="Item" />;
            }
          })}

        {differentiatedPoLineItems &&
          differentiatedPoDebitLines &&
          _.isArray(differentiatedPoDebitLines) &&
          differentiatedPoDebitLines?.map((differentiatedPoDebitLine, index) => {
            const { change_type, newItem, oldItem } = differentiatedPoDebitLine;
            switch (change_type) {
              /* differentiatedPoLineItems.length + index to show correct index  to user */
              case "ADDED":
                return (
                  <RenderGLRow
                    newItem={newItem}
                    oldItem={oldItem}
                    index={differentiatedPoLineItems.length + index}
                    className={style.updatedItem}
                    lineType="Expenses"
                  />
                );
              case "DELETED":
                return (
                  <RenderGLRow
                    newItem={newItem}
                    oldItem={oldItem}
                    index={differentiatedPoLineItems.length + index}
                    className={style.deletedItem}
                    lineType="Expenses"
                  />
                );
              default:
                return (
                  <RenderGLRow
                    newItem={newItem}
                    oldItem={oldItem}
                    index={differentiatedPoLineItems.length + index}
                    className={""}
                    lineType="Expenses"
                  />
                );
            }
          })}
      </tbody>
    </Table>
  );
};

export default GLImpactTable;
