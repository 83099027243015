import React from "react";
import ExpenseDashboard from "../../../../components/admin/expensese/dashbord/dashboard";
const ExpenseDashboardPage = () => {
  return (
    <>
      <ExpenseDashboard />
    </>
  );
};
export default ExpenseDashboardPage;
