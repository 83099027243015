import React from "react";
import ListExpenseReports from "../../../../components/admin/expensese/expenseReports/listExpenseReports";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";

const List = () => {
  return (
    <ErrorBoundary>
      <ListExpenseReports />
    </ErrorBoundary>
  );
};

export default List;
