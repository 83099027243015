import ListCreditMemo from "./list";
import DetailCreditMemo from "./details";
import AddCreditMemo from "./add";

const vpCreditMemoRoutes = [
  {
    path: "/ar/credit_memos",
    exact: true,
    name: "Credit Memos",
    component: ListCreditMemo,
  },
  {
    path: "/ar/credit_memos/:id",
    exact: true,
    name: "Credit Memos",
    component: DetailCreditMemo,
  },
  {
    path: "/ar/submit_credit_memo",
    exact: true,
    name: "Credit Memos",
    component: AddCreditMemo,
  },
];

export { vpCreditMemoRoutes };
