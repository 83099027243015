import styles from "./buttons.module.css";

const TextButton = ({ onClick, children }) => {
  return (
    <button onClick={() => onClick()} className={styles.textButton}>
      {children}
    </button>
  );
};

export default TextButton;
