import React from "react";
import { Row } from "react-bootstrap";
import { components } from "react-select";

export const pickerComparisonFieldStyle = {
  backgroundColor: "rgba(230,255,230,1)",
  outline: "none",
  border: "solid rgba(120,210,120,1) 2px",
  boxShadow: "none",
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
export const PickerComparisonField = ({ originalValue, ...props }: any) => {
  return (
    <>
      <components.Input {...props} />
      {originalValue !== undefined ? <Row className="m-0 strikeThroughText">{originalValue}</Row> : null}
    </>
  );
};
