import React from "react";
import { translate } from "../../../../services/general/translation";
import { ReceiptsTypes } from "../../../../services/admin/expenses/receipts/receiptsType";
import useConfirmModal from "../../../modals/confirmModal/useConfirmModalHook";
import ReceiptsApis from "../../../../services/admin/expenses/receipts/receiptsApis";
import gridService from "../../../../services/grid/gridSvc";
import { ColDef, GridApi } from "ag-grid-community";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import receiptService from "../../../../services/admin/expenses/receipts/receiptsSvc";
import { useTranslation } from "react-i18next";
import { companyDateFormat } from "../../../../services/general/dateSvc";
import { formattedAmount } from "services/general/helpers";
import { Link } from "react-router-dom";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { setAllowedFilters } from "services/common/gridService";
import _, { sortBy } from "lodash";
import { IUser } from "services/common/user/userTypes";

export const FIELD_NAME_MAP: FieldFilterMapType = {
  "documentable.name": {
    contains: "employee_name",
  },
  "form_data.date": {
    inRange: "",
    lessThanOrEqual: "ocr_transaction_date_before",
    greaterThanOrEqual: "ocr_transaction_date_after",
  },
  created_at: {
    inRange: "",
    lessThanOrEqual: "created_at_before",
    greaterThanOrEqual: "created_at_after",
  },
  "form_data.merchant": {
    contains: "merchant",
    blank: "ocr_merchant_has_data",
    notBlank: "ocr_merchant_has_data",
  },
  "form_data.total": {
    inRange: "",
    lessThanOrEqual: "ocr_total_less_than",
    greaterThanOrEqual: "ocr_total_greater_than",
  },
};

const Actions = ({ data, gridApi }: { data: ReceiptsTypes.ListItem; gridApi?: GridApi }) => {
  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();

  const onDeleteConfirm = async () => {
    try {
      if (data.id && gridApi) {
        const result = await ReceiptsApis.deleteReceipt(data.id);
        gridService.removeRowFromGrid(gridApi, String(data.id));
        CreateNotification(
          t("success"),
          t("admin.pages.receipts.successfulDelete", { id: data.id, name: data.name }),
          NotificationType.success,
        );
      }
    } catch (error) {}
  };

  const createDeleteConfirmation = () => {
    createConfirmModal({
      title: t("admin.pages.receipts.deleteDialog.header"),
      body: t("admin.pages.receipts.deleteDialog.content"),
      callBackData: null,
      cancelCallBack: null,
      confirmButtonLabel: t("admin.pages.expenseItem.deleteButtonLabel"),
      saveCallBack: onDeleteConfirm,
    });
  };

  return (
    <>
      <Link to={"/ap/inbound_receipts/" + data?.id}>
        {/* <a href={restApiService.angularBaseURL() + "inbound_receipts/" + data?.id}> */}
        <button type="button" className="actionButtons view" data-toggle="tooltip" title="View"></button>
        {/* </a> */}
      </Link>
      <button
        type="button"
        className="actionButtons icon-delete"
        data-toggle="tooltip"
        title="Delete"
        onClick={createDeleteConfirmation}
      ></button>
    </>
  );
};

export const getPendingReceiptsHeaders = ({
  gridApi,
  currentUser,
  currencyCode,
}: {
  gridApi?: GridApi;
  currentUser: IUser;
  currencyCode: string;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs = [
    {
      field: "select",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      filter: false,
      maxWidth: 30,
    },
    {
      field: "status",
      headerName: "Status",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          { label: "Processing", value: "PENDING_OCR" },
          { label: "Match in Progress", value: "PENDING_MATCH" },
          { label: "Action Required", value: "ACTION_REQUIRED" },
        ],
      },
      cellRenderer: (param: any) => {
        return <>{receiptService.getLegibleRecieptStatus(param.data)}</>;
      },
      headerValueGetter: function () {
        return translate("status");
      },
    },
    {
      field: "id",
      headerName: "ID",
      cellRenderer: (params: any) => {
        return (
          <Link className="link" to={"/ap/inbound_receipts/" + params?.data?.id}>
            {params?.data?.id}
          </Link>
        );
      },
      filter: false,
      headerValueGetter: function () {
        return translate("ID");
      },
    },
    {
      field: "created_at",
      headerName: "Submitted Date",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        browserDatePicker: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("submittedDate");
      },
    },
    {
      field: "form_data.date",
      headerName: "Transaction Date",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        browserDatePicker: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("transactionDate");
      },
    },
    {
      field: "form_data.merchant",
      headerName: "Merchant",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("merchant");
      },
    },
    {
      field: "form_data.total",
      headerName: "Amount",
      filter: "agNumberColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return formattedAmount(String(params?.value), currencyCode, 2, true);
      },
      headerValueGetter: function () {
        return translate("rAmount");
      },
    },
    {
      field: "documentable.name",
      headerName: "Employee",
      sortable: false,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("employee");
      },
    },
    {
      field: "note",
      headerName: "Note",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("note");
      },
    },
    {
      field: "ZActions",
      cellRenderer: (params: any) => {
        return <Actions data={params?.data} gridApi={gridApi} />;
      },
      sortable: false,
      resizable: false,
      maxWidth: 200,
      filter: false,
      headerValueGetter: function () {
        return translate("actions");
      },
    },
  ];
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return {
    columnDefs: [
      ...sortBy(columnDefs, (col) => typeof col.headerValueGetter == "function" && col?.headerValueGetter()),
    ],
    defaultOrder,
  };
};
