import { appUrls, appListPageUrls } from "../admin/routes.constants";
import _ from "lodash";

export function getUrlLocator(objectType, objectId, params) {
  if (objectType) {
    let objType = objectType.toLowerCase();
    let detailPageUrl = _.isObject(appUrls[objType]) ? appUrls[objType].url : null;
    let listPageUrl = _.isObject(appListPageUrls[objType]) ? appListPageUrls[objType].url : null;
    if (listPageUrl && params) {
      listPageUrl = `${listPageUrl}?${params}`;
    }
    return objectId ? detailPageUrl + objectId : listPageUrl;
  }
}
