import moment from "moment";
import { IUser } from "services/common/user/userTypes";
import { VendorApis } from "./vensorApis";
import _ from "lodash";

export const isRequiredFieldsValidated = (vendor: any, stepRequiredFields: any) => {
  const requiredFields = stepRequiredFields;
  if (!requiredFields) {
    return false; // No required fields for this step, validation is not required
  }

  // Helper function to check if a nested property exists
  const hasNestedProperty = (obj: any, keys: string[]) => {
    for (const key of keys) {
      if (!(key in obj)) {
        return false;
      }
      obj = obj[key];
    }
    return true;
  };

  // Check if any of the required fields are missing
  for (const fieldName of requiredFields) {
    if (!vendor || !hasNestedProperty(vendor, fieldName.split("."))) {
      return true; // Return true if any required field is missing
    }
  }

  return false; // All required fields for this step are present
};

export const formattedCustomFields = (customFields: any) => {
  const formattedCustomFields: any = {};
  if (customFields) {
    Object.keys(customFields).forEach((key) => {
      const value = customFields[key].value;

      // Determine if the value corresponds to a date. If it is a date, format it; otherwise, retain the original value.
      if (moment(value, moment.ISO_8601, true).isValid()) {
        const originalDate = moment(value);
        const formattedDate = originalDate.format("YYYY-MM-DD");
        formattedCustomFields[key] = { value: formattedDate };
      } else {
        formattedCustomFields[key] = { value };
      }
    });
  }
  return formattedCustomFields;
};

export const removeVendor = async (invoice: any, subsidiary: any, currentUser: IUser) => {
  if (currentUser?.company?.po_request_allow_vendor_on_subsidiary && invoice?.vendor_id) {
    let id = invoice?.vendor_id;
    try {
      const response = await VendorApis.getVendor(id);
      let subsidiaryObj = _.find(response.subsidiaries, (option) => option.id === subsidiary.id);
      if (!subsidiaryObj) {
        invoice.vendor = null;
        invoice.vendor_id = null;
      }
    } catch (error) {
      console.error("Error occurred while fetching vendor data:", error);
    }
  }
  return invoice;
};
