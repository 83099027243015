import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Field, change } from "redux-form";
import { RenderField, RenderSelect } from "../../../../forms/bootstrapFields";
import { required } from "../../../../forms/inputFields";
import BankAccountNumbers from "../../bankAccountNumbers";
import BicValidator from "../../bicValidator";
import BankRoutingNumber from "../../../pickers/reduxPickers/bankRoutingNumber";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PurposeCode from "./purposeCode";

const WireUsd = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const [defaultBankName, setDefaultBankName] = useState("");
  const dispatch = useDispatch();

  const updateBankName = (bankName: string) => {
    dispatch(change("VendorForm", `${modelName}.bank_name`, bankName));
    setDefaultBankName(bankName);
  };

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <BankAccountNumbers modelName={`${modelName}`} /> {/* Render Bank Account and Confirm Account Number fields */}
      <Col md="6">
        <BicValidator modelName={`${modelName}`} />
      </Col>
      <Col sm="6">
        <Field
          name={`${modelName}.account_type`}
          component={RenderSelect}
          options={[
            { value: null, label: "" },
            { value: "checking", label: "Checking" },
            { value: "savings", label: "Savings" },
          ]}
          FieldClassName="formField"
          placeholder={"Search/Select"}
          label={t("common.paymentMethod.accountType")}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.routing`}
          component={BankRoutingNumber}
          type="text"
          label={t("common.paymentMethod.bankRoutingNumber")}
          className={`w-100`}
          bankNameCallBack={updateBankName}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
          className={`w-100`}
          required
          validate={[required]}
          readOnly={defaultBankName}
        />
      </Col>
      <Col md="12">
        <PurposeCode />
      </Col>
    </>
  );
};

export default WireUsd;
