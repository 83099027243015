/*eslint-disable eqeqeq*/
/*eslint-disable no-mixed-operators*/
import React, { Component } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";

import "../../pages/pages.style.css";
import "./paymentMethods.style.css";
import { MainPaymentMethod } from "./fieldComponents/mainPaymentComponents";
import CommonPaymentAddress from "./fieldComponents/addressComponents";

import { connect } from "react-redux";
import * as actionType from "../../actions/actionTypes";
import * as _ from "lodash";
import PayoneerHeader from "./payoneer/payoneerHeader";
import RestApi from "../../providers/restApi";

import PayoneerFields from "./payoneer/payoneerFields";
import TransferwiseForm from "./transferwise/transferwiseForm";
import WireForm from "./wire/wireForm";
import { CreateNotification, NotificationType } from "../../services/general/notifications";
import { withTranslation } from "react-i18next";
import MainACHForm from "./ach/mainACHForm";
import CreditCardFields from "./creditCard/creditCardFields";
import EftCanadaFormFields from "./eftCanada/eftCanadaFormFields";
import EftNewZeaLandFormFields from "./eftNewZealand/eftNewZealandFormFields";
import EftAusPayNetFormFields from "./EftAustralianPayNet/eftAustralianPayNet";
import PickCustomersForPaymentMethodModal from "../modals/pickCustomersForPaymentMethodModal";
import CambridgeFields from "./cambridge/cambridgeFields";
import PayoneerBranded from "./payoneer/payoneerBranded";
import { IntermediaryBank } from "./wire/wireCurrencyFields";
import Loader from "../spinners/loader";
import FedWireForm from "./fedWire/fedWireForm";
import { mapReduxFormToPaymentMethodPayload } from "../../services/common/paymentMethod.svc";
import PaymentMethodBootstrapForm from "../admin/managePaymentMethod/paymentMethodBootstrapForm";
import paymentTypeService from "../../services/common/paymentMethod/paymentTypeSvc";

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      companyDetail: {},
      routing: "",
      validated: false,
      is_primary: true,
      accountStatus: "",
      showAddress: false,
      showPurchaserPicker: false,
      finalPaymentMethod: null,
      defaultCurrency: "USD",
      isSubmitDisabled: false,
    };
    this.restApiService = new RestApi();
  }

  onPaymentTypeChangeCallback = () => {
    // should not show previous validation when payment_type is changed
    this.setState({ validated: false });
  };

  componentDidMount() {
    if (this.props.editAccountDetail) {
      this.getEditData();
    }
  }

  componentWillUnmount() {
    this.props.clearPaymentMethod();
    this.props.clearValidations();
    this.props.fullClearPaymentMethod();
    this.onPaymentTypeChangeCallback();
  }

  render() {
    return (
      <Row className={"customModal"}>
        <Loader loaded={this.state.loaded}>
          <Modal.Body className="w-100">
            <Form noValidate validated={this.state.validated} id="payment_form" onSubmit={this.saveAccountDetail}>
              {/* admin payment method  */}
              {!this.props.user.is_vendor && (
                <PaymentMethodBootstrapForm {...this.props} {...this.state} showSubmitButton={this.showSubmitButton} />
              )}

              {/* vendor payment method  */}
              {this.props.user.is_vendor && (
                <>
                  <MainPaymentMethod
                    {...this.props}
                    {...this.state}
                    onPaymentTypeChangeCallback={this.onPaymentTypeChangeCallback}
                  />

                  {paymentTypeService.isAch(this.props.payment_type) && (
                    <MainACHForm {...this.props} parentProps={this.props} />
                  )}

                  {paymentTypeService.isTransfterwise(this.props.payment_type) && (
                    <TransferwiseForm {...this.props} defaultCurrency={this.state.defaultCurrency} />
                  )}

                  {paymentTypeService.isWire(this.props.payment_type) && (
                    <WireForm {...this.props} defaultCurrency={this.state.defaultCurrency} />
                  )}

                  {paymentTypeService.isPayoneer(this.props.payment_type) &&
                    !(this.props.paymentMethodRed.properties && this.props.paymentMethodRed.properties.branded) && (
                      <Row>
                        <Col>
                          <PayoneerHeader {...this.props} />
                          <PayoneerFields />
                        </Col>
                      </Row>
                    )}

                  {(paymentTypeService.isPayoneerBranched(this.props.payment_type) ||
                    (paymentTypeService.isPayoneer(this.props.paymentMethodRed.payment_type) &&
                      this.props.paymentMethodRed.properties &&
                      this.props.paymentMethodRed.properties.branded)) && <PayoneerBranded {...this.props} />}

                  {paymentTypeService.isCambridge(this.props.payment_type) && <CambridgeFields {...this.props} />}

                  {paymentTypeService.isCreditCard(this.props.payment_type) && <CreditCardFields />}

                  {paymentTypeService.isFedWire(this.props.payment_type) && <FedWireForm {...this.props} />}

                  {paymentTypeService.isCanEft(this.props.payment_type) && <EftCanadaFormFields {...this.props} />}

                  {paymentTypeService.isNzEft(this.props.payment_type) && <EftNewZeaLandFormFields {...this.props} />}

                  {paymentTypeService.isAusPayNet(this.props.payment_type) && (
                    <EftAusPayNetFormFields {...this.props} />
                  )}

                  {this.showOrHideAddress(this.props.payment_type) && (
                    <CommonPaymentAddress {...this.props} {...this.state} />
                  )}

                  {paymentTypeService.isWire(this.props.payment_type) && <IntermediaryBank {...this.props} />}

                  {this.state.registerPaymentMethodError && (
                    <Row>
                      <Col>
                        <h5 className="text-danger text-right">{this.state.registerPaymentMethodError}</h5>
                      </Col>
                    </Row>
                  )}

                  <Form.Group as={Col} md="12" controlId="defaultCheckbox" className={"text-right"}>
                    <Form.Check
                      type="checkbox"
                      label=" Default for future invoice payment method"
                      checked={this.props.paymentMethodRed.is_primary}
                      onChange={(e) => {
                        this.props.changeIsPrimary(e.target.checked);
                      }}
                    />
                  </Form.Group>

                  <Modal.Footer>
                    {this.props.closeModel && <Button onClick={this.closeModel}>{this.props.t("cancel")}</Button>}
                    {this.showSubmitButton() && (
                      <button disabled={this.state.isSubmitDisabled} type="submit" className={"btn btn-primary"}>
                        {this.props.t("submit")}
                      </button>
                    )}
                  </Modal.Footer>
                </>
              )}
            </Form>
          </Modal.Body>
        </Loader>
        {this.state.showPurchaserPicker && (
          <PickCustomersForPaymentMethodModal
            show={true}
            paymentMethod={this.state.finalPaymentMethod}
            closeCallback={this.purchasersPicked}
          />
        )}
      </Row>
    );
  }

  validateRouting = (number) => {
    this.setState({ routing: number.target.value });
    if (number.target.value.length == 9) {
      this.restApiService
        .get("bank_routing_numbers/" + number.target.value)
        .then((res) => {
          this.setState({ bank_name: res.data.name });
        })
        .catch((err) => {});
    } else {
      if (number.target.value.length == 9) {
        this.setState({
          routingWarnMsg: "Warning: routing number not found in our database",
        });
      }
    }
  };

  //to find if submitted payment detail uniquely matches the element(repsonse from submit api) so we can find its id
  //to uniquely match we are checking if account name & routing matches
  isFedWireMatched = (paymentDetail, element) => {
    return (
      paymentDetail.payment_type === "fed-wire" &&
      element.payment_type === "fed-wire" &&
      element.currency_code == "USD" &&
      paymentDetail.currency_code == "USD" &&
      element.routing == paymentDetail.routing &&
      element.account_name == paymentDetail.account_name
    );
  };

  // add new account
  saveAccountDetail = (event) => {
    const form = event.currentTarget;
    let valid = form.checkValidity();
    this.setState({ validated: true });
    event.preventDefault();
    event.stopPropagation();
    if (!valid) {
      return;
    }
    const paymentMethodFormData = mapReduxFormToPaymentMethodPayload(this.props.paymentMethodRed);
    const paymentDetail = this.props.paymentMethodRed;

    if (this.props.isManagePaymentMethod) {
      this.props.onPaymentSubmitCallback(paymentDetail);
      return;
    }

    this.setState({ isSubmitDisabled: true });
    this.restApiService
      .patch("company", null, paymentMethodFormData)
      .then((result) => {
        this.setState({ isSubmitDisabled: false });

        if (this.props.isGuide) {
          this.props.guideCallback && this.props?.guideCallback(paymentMethodFormData);

          // just calling back on guide and return other excuition is not required for guide
          return;
        }

        //need to find what id this new payment method has so we can link our purchasers
        let id = 0;
        result.data.payment_methods.forEach((element) => {
          if (
            paymentDetail.payment_type == "ach" &&
            element.payment_type == "ach" &&
            paymentDetail.routing == element.routing &&
            paymentDetail.account_name == element.account_name
          ) {
            id = element.id;
          } else if (
            paymentDetail.payment_type == "credit_card" &&
            paymentDetail.merchant_name == element.merchant_name
          ) {
            id = element.id;
          } else if (
            ["can-eft", "nz-eft", "aus-pay-net", "sg-eft"].includes(paymentDetail.payment_type) &&
            paymentDetail.payment_type === element.payment_type
          ) {
            id = element.id;
          } else if (
            paymentDetail.payment_type === "cambridge" &&
            element.payment_type == "cambridge" &&
            element.cambridge.accountNumber == paymentDetail.cambridge.accountNumber
          ) {
            id = element.id;
          } else if (
            paymentDetail.payment_type === "wire" &&
            element.payment_type == "wire" &&
            element.bic == paymentDetail.bic
          ) {
            id = element.id;
          } else if (this.isFedWireMatched(paymentDetail, element)) {
            id = element.id;
          }
        });
        if (paymentDetail.payment_type == "cambridge") {
          this.props.changeID(id);
          this.syncWithSource(id, paymentDetail);
        } else {
          //if this is an update or if the type is payoneer or international we continue on without picking purchasers
          if (
            paymentDetail.id ||
            paymentDetail.payment_type == "payoneer" ||
            paymentDetail.payment_type == "international"
          ) {
            this.purchasersPicked();
          } else {
            paymentDetail.id = id;
            this.setState({ showPurchaserPicker: true, finalPaymentMethod: paymentDetail });
          }
        }
      })
      .catch((err) => {
        this.setState({ isSubmitDisabled: false });
        console.log(err);
        if (err.response) {
          CreateNotification("Error", "There was an error creating bank information", NotificationType.danger);
          let errorString = "";
          for (var key in err.data) {
            if (err.data.hasOwnProperty(key)) {
              errorString += " " + err.data[key];
            }
          }
          this.setState({ registerPaymentMethodError: errorString });
        } else {
          CreateNotification("Error", "There was an error creating bank information", NotificationType.danger);
        }
      });
  };

  syncWithSource = (pmId, pm) => {
    this.restApiService
      .post("payment_methods/" + pmId + "/sync_with_source")
      .then((result) => {
        pm.id = pmId;
        this.setState({ showPurchaserPicker: true, finalPaymentMethod: pm });
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.setState({ registerPaymentMethodError: error.response?.data?.base?.toString() });
        }
        CreateNotification("Error", "There was an error creating payment method", NotificationType.danger);
      });
  };

  purchasersPicked = () => {
    this.props.closeCreateAndEdit();
    this.props.clearPaymentMethod();
    this.props.clearValidations();
    this.props.refreshPage(true);
    this.setState({ showPurchaserPicker: false, finalPaymentMethod: null });
  };

  newAcctDetail() {
    let payment_method = {};
    payment_method = {
      bank_name: this.state.bank_name, //
      routing: this.state.routing, //
      account_number: this.state.account_number, //
      account_name: this.state.account_name, //
      account_type: this.state.account_type, //
      payment_type: "ach", //
      confirm_account_number: this.state.confirm_account_number, //
      is_primary: this.state.is_primary,
      address_attributes: {
        address1: this.state.address1,
        city: this.state.city,
        state: this.state.state,
        zipcode: this.state.zipcode,
        country: "",
      },
    };
    if ("payment_methods" in this.props.companyDetail) {
      let paymentList = this.props.companyDetail.payment_methods;
      paymentList.push(payment_method);
      let data = {
        company: { payment_methods_attributes: paymentList },
      };

      this.updateBankEntryApi(data);
    } else {
      let UserBankDetail = {
        company: { payment_methods: [payment_method] },
      };
      this.updateBankEntryApi(UserBankDetail);
    }
  }

  editAcctDetail() {
    let payment_method = {};

    payment_method = this.props.editAccountDetail;
    payment_method.bank_name = this.state.bank_name;
    payment_method.routing = this.state.routing;
    payment_method.account_number = this.state.account_number;
    payment_method.account_name = this.state.account_name;
    payment_method.account_type = this.state.account_type;
    payment_method.payment_type = "ach";
    payment_method.confirm_account_number = this.state.confirm_account_number;
    payment_method.is_primary = this.state.is_primary;
    payment_method.address_attributes.address1 = this.state.address1;
    payment_method.address_attributes.city = this.state.city;
    payment_method.address_attributes.state = this.state.state;
    payment_method.address_attributes.zipcode = this.state.zipcode;
    payment_method.address_attributes.country = "";

    if (this.props.editAccountDetail.id) {
      // let paymentList = this.props.companyDetail;
      // paymentList.payment_methods[this.props.paymentIndex] = payment_method;
      let data = {
        company: { payment_methods_attributes: payment_method },
      };
      this.updateBankEntryApi(data);
    }
  }

  closeModel = () => {
    this.props?.closeModel && this.props.closeModel(true);
  };

  getEditData = () => {
    // alert(" edit page ")
    let companyDetail = this.props.editAccountDetail;

    if (this.props.editAccountDetail) {
      this.setState({
        account_name: companyDetail.account_name,
        account_number: companyDetail.account_number,
        confirm_account_number: companyDetail.account_number,
        account_type: companyDetail.account_type,
        routing: companyDetail.routing,
        bank_name: companyDetail.bank_name,
        address1: companyDetail.address1,
        city: companyDetail.city,
        state: companyDetail.state,
        zipcode: companyDetail.zipcode,
        is_primary: companyDetail.is_primary,
        payment_type: companyDetail.payment_type,
        pay_by_swift_code: companyDetail.pay_by_swift_code,
        bik_code: companyDetail.bik_code,
        vo_code: companyDetail.vo_code,
        inn_tax_id: companyDetail.inn_tax_id,
      });

      this.setState({ accountStatus: "Edit Account" });
      // this.showOrHideAddress(companyDetail.payment_type)
    }
  };

  updateBankEntryApi(bankList) {
    // this.props.activateLoader();
    this.setState({
      loaded: false,
    });
    this.restApiService
      .patch("company", null, bankList)
      .then((res) => {
        // this.props.refreshPage()
        // if(this.props.editAccountDetail){
        //     this.props.editSuccessfulMsg()
        // }else {
        //     this.props.AddSuccessfulMsg()
        // }
        // this.cancel()
        this.setState({
          loaded: true,
        });
        this.props.refreshPage();
      })
      .catch((err) => {});
  }

  showSubmitButton = () => {
    if (
      this.props.payment_type === "payoneer_branded" ||
      (this.props.paymentMethodRed.payment_type == "payoneer" &&
        this.props.paymentMethodRed.properties &&
        this.props.paymentMethodRed.properties.branded)
    ) {
      return false;
    }

    return true;
  };

  showOrHideAddress = (type, force = false) => {
    if (
      type === "payoneer_branded" ||
      (this.props.paymentMethodRed.properties && this.props.paymentMethodRed.properties.branded) ||
      type === "cambridge" ||
      type === "credit_card" ||
      (type === "ach" && !force)
    ) {
      return false;
    } else {
      return true;
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    paymentMethodRed: state.paymentMethodRed,
    payment_type: state.paymentMethodRed.payment_type,
    country: state.paymentMethodRed.country,
    currency_code: state.paymentMethodRed.currency_code,
    pay_by_swift_code: state.paymentMethodRed.pay_by_swift_code,
    paymentCountryLabel: state.paymentMethodRed.paymentCountryLabel,
    paymentValidationsReducers: state.paymentValidationsReducers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ////////////////////methods related to main payment component/////////////////////////////////
    changePaymentType: (payload) => {
      dispatch({ type: actionType.CHANGE_PAYMENT_TYPE, payload: payload });
    },
    changeCountryCurrencyCode: (payload) => {
      dispatch({ type: actionType.CHANGE_COUNTRY_CURRENCY_CODE, payload: payload });
    },
    countryPickerCallback: (payload) => {
      dispatch({ type: actionType.CHANGE_COUNTRY_CODE, payload: payload });
    },
    currencyPickerCallback: (payload) => {
      dispatch({ type: actionType.CHANGE_CURRENCY_CODE, payload: payload });
    },
    currencyPickerCADCallback: (payload) => {
      dispatch({ type: actionType.CHANGE_CAD_CURRENCY_CODE, payload: payload });
    },
    changePayeeType: (payload) => {
      dispatch({ type: actionType.CHANGE_PAYEE_TYPE, payload: payload });
    },
    changePayBySwiftCode: (payload) => {
      dispatch({ type: actionType.CHANGE_PAY_BY_SWIFT_CODE, payload: payload });
    },

    //////////////////// methods related to fields component //////////////////////////////////////
    changeAccountName: (payload) => {
      dispatch({ type: actionType.CHANGE_ACCOUNT_NAME, payload: payload });
    },
    changeAccountNumber: (payload) => {
      dispatch({ type: actionType.CHANGE_ACCOUNT_NUMBER, payload: payload });
    },
    changeConfirmAccountNumber: (payload, account_number) => {
      dispatch({
        type: actionType.CHANGE_CONFIRM_ACCOUNT_NUMBER,
        payload: payload,
        account_number: account_number,
      });
    },
    changeAccountType: (payload) => {
      dispatch({ type: actionType.CHANGE_ACCOUNT_TYPE, payload: payload });
    },
    changeRouting: (payload) => {
      dispatch({ type: actionType.CHANGE_ROUTING, payload: payload });
    },
    changeBankName: (payload) => {
      dispatch({ type: actionType.CHANGE_BANK_NAME, payload: payload });
    },
    changeTransitNumber: (payload) => {
      dispatch({ type: actionType.CHANGE_TRANSIT_NUMBER, payload: payload });
    },
    changeInstitutionNo: (payload) => {
      dispatch({ type: actionType.CHANGE_INSTITUTION_NUMBER, payload: payload });
    },
    changeCardNumber: (payload) => {
      dispatch({ type: actionType.CHANGE_CARD_NUMBER, payload: payload });
    },
    changeBicCode: (payload) => {
      dispatch({ type: actionType.CHANGE_BIC_CODE, payload: payload });
    },
    changePurchaser: (payload) => {
      dispatch({ type: actionType.CHANGE_PURCHASER, payload: payload });
    },
    changeDeliveryMethod: (payload) => {
      dispatch({ type: actionType.CHANGE_DELIVERY_METHOD, payload: payload });
    },

    ////////////////////methods related to common payment address component ////////////////////////
    changeAddressLine: (payload) => {
      dispatch({
        type: actionType.CHANGE_PAYMENT_METHOD_ADDRESSLINE,
        payload: payload,
      });
    },
    changeCity: (payload) => {
      dispatch({
        type: actionType.CHANGE_PAYMENT_METHOD_CITY,
        payload: payload,
      });
    },
    changeState: (payload) => {
      dispatch({
        type: actionType.CHANGE_PAYMENT_METHOD_STATE,
        payload: payload,
      });
    },
    changeZipcode: (payload) => {
      dispatch({
        type: actionType.CHANGE_PAYMENT_METHOD_ZIPCODE,
        payload: payload,
      });
    },
    changeBankCode: (payload) => {
      dispatch({ type: actionType.CHANGE_BANK_CODE, payload: payload });
    },
    changeBikCode: (payload) => {
      dispatch({ type: actionType.CHANGE_BIK_CODE, payload: payload });
    },
    changeVoCode: (payload) => {
      dispatch({ type: actionType.CHANGE_VO_CODE, payload: payload });
    },
    changeInnTaxId: (payload) => {
      dispatch({ type: actionType.CHANGE_INN_TAX_ID, payload: payload });
    },
    changeBranchCode: (payload) => {
      dispatch({ type: actionType.CHANGE_BRANCH_CODE, payload: payload });
    },
    changeSwiftCode: (payload) => {
      dispatch({ type: actionType.CHANGE_SWIFT_CODE, payload: payload });
    },
    changeIBAN: (payload) => {
      dispatch({ type: actionType.CHANGE_IBAN_CODE, payload: payload });
    },
    changeBSB: (payload) => {
      dispatch({ type: actionType.CHANGE_BSB_CODE, payload: payload });
    },
    changeBusinessNumber: (payload) => {
      dispatch({ type: actionType.CHANGE_BUSINESS_NUMBER, payload: payload });
    },
    changeStoreFrontUrl: (payload) => {
      dispatch({ type: actionType.CHANGE_STOREFRONT_URL, payload: payload });
    },
    changeIFSC: (payload) => {
      dispatch({ type: actionType.CHANGE_IFSC, payload: payload });
    },
    changeID: (payload) => {
      dispatch({ type: actionType.CHANGE_ID, payload: payload });
    },
    changePhoneNumber: (payload) => {
      dispatch({ type: actionType.CHANGE_PHONE_NUMBER, payload: payload });
    },
    changeAccountTaxNumber: (payload) => {
      dispatch({
        type: actionType.CHANGE_ACCOUNT_TAX_NUMBER,
        payload: payload,
      });
    },
    changeIncorporationNumber: (payload) => {
      dispatch({
        type: actionType.CHANGE_INCORPORATION_NUMBER,
        payload: payload,
      });
    },
    changeSortCode: (payload) => {
      dispatch({ type: actionType.CHANGE_SORT_CODE, payload: payload });
    },
    changePan: (payload) => {
      dispatch({ type: actionType.CHANGE_PAN, payload: payload });
    },
    changeAccountNameEnglish: (payload) => {
      dispatch({
        type: actionType.CHANGE_ACCOUNT_NAME_ENGLISH,
        payload: payload,
      });
    },
    changeIdNumber: (payload) => {
      dispatch({ type: actionType.CHANGE_ID_NUMBER, payload: payload });
    },
    changeInstitution: (payload) => {
      dispatch({
        type: actionType.CHANGE_INSTITUTION_NUMBER,
        payload: payload,
      });
    },
    changeCNPJ: (payload) => {
      dispatch({
        type: actionType.CHANGE_CNPJ,
        payload: payload,
      });
    },
    changeCpf: (payload) => {
      dispatch({
        type: actionType.CHANGE_CPF,
        payload: payload,
      });
    },
    changeSuffix: (payload) => {
      dispatch({
        type: actionType.CHANGE_SUFFIX,
        payload: payload,
      });
    },
    changeIntrmyCountry: (payload) => {
      dispatch({ type: actionType.CHANGE_INTRMY_COUNTRY, payload: payload });
    },
    changeIntrmyBic: (payload) => {
      dispatch({ type: actionType.CHANGE_INTRMY_BIC, payload: payload });
    },
    changeIntrmyName: (payload) => {
      dispatch({ type: actionType.CHANGE_INTRMY_NAME, payload: payload });
    },
    changeIntrmyNumber: (payload) => {
      dispatch({ type: actionType.CHANGE_INTRMY_NUMBER, payload: payload });
    },
    changePurposeCode: (payload) => {
      dispatch({ type: actionType.CHANGE_PURPOSE_CODE, payload: payload });
    },

    //////////////////// methods related to PyContactDetails fieldComponents ////////////////////////

    changeIsPrimary: (payload) => {
      dispatch({ type: actionType.CHANGE_IS_PRIMARY, payload: payload });
    },
    changeFirstName: (payload) => {
      dispatch({ type: actionType.CHANGE_FIRST_NAME, payload: payload });
    },
    changeLastName: (payload) => {
      dispatch({ type: actionType.CHANGE_LAST_NAME, payload: payload });
    },
    changeEmail: (payload) => {
      dispatch({ type: actionType.CHANGE_EMAIL, payload: payload });
    },
    changePhone: (payload) => {
      dispatch({ type: actionType.CHANGE_PHONE, payload: payload });
    },
    checkValidations: (payload, allValidations, fieldName) => {
      dispatch({
        type: actionType.CHECK_VALIDATIONS,
        payload: payload,
        allValidations: allValidations,
        fieldName: fieldName,
      });
    },
    clearPaymentMethod: (payload) => {
      dispatch({ type: actionType.CLEAR_PAYMENT_METHOD, payload: payload });
    },
    clearValidations: (payload) => {
      dispatch({ type: actionType.CLEAR_VALIDATIONS, payload: payload });
    },
    fullClearPaymentMethod: (payload) => {
      dispatch({ type: actionType.FULL_CLEAR_PAYMENT_METHOD });
    },
    changeTwIbanValidation: (payload) => {
      dispatch({ type: actionType.CHANGE_IBAN_VALIDATIONS, payload: payload });
    },
    changeTwBSBValidation: (payload) => {
      dispatch({ type: actionType.CHANGE_BSB_VALIDATIONS, payload: payload });
    },
    changeTwIntituteValidation: (payload) => {
      dispatch({ type: actionType.CHANGE_INSTITUTE_VALIDATIONS, payload: payload });
    },
    changeTwBicCode: (payload) => {
      dispatch({ type: actionType.CHANGE_TW_BIC_CODE, payload: payload });
    },
    changeRoutingMsg: (payload) => {
      dispatch({ type: actionType.CHANGE_ROUTING_MSG, payload: payload });
    },
    changeProgramId: (payload) => {
      dispatch({ type: actionType.CHANGE_PROGRAM_ID, payload: payload });
    },
    /////////////////////////////////////////////////////////////////////////////////////////////
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PaymentMethods));
