import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col, Form, Row, OverlayTrigger, Popover } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import PaymentCountryPicker from "../../pickers/paymentCountryPicker";
import CurrencyPicker from "../../vp/pickers/reduxPicker/currencyPicker/currencyPicker";
import GenericSelectPicker from "../../vp/pickers/reduxPicker/genericSelectPicker/genericSelectPicker";
import CustomerPicker from "../../vp/pickers/reduxPicker/customerPicker/customerPicker";
import CambridgeRuleInput from "./cambridgeRuleInput";
import { getCurrencyCodeFromCountryCode } from "../../app.svc.Lookup";

class CambridgeFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedPMHash: null,
      fields: null,
      loading: false,
    };
    this.restApiService = new RestApi();
  }
  render() {
    return (
      <Row>
        <Col sm={12}>
          <h4>
            Synced Id:{" "}
            {this.props.cambridge && this.props.cambridge.beneficiaryId ? (
              <span className={"App-link"}>{this.props.cambridge.beneficiaryId}</span>
            ) : (
              "not synced"
            )}
          </h4>
        </Col>
        <Col>
          <Row>
            <PaymentCountryPicker
              required={true}
              countryPickerCallback={this.countryCallback}
              label={"Destination Country"}
              selected={{ value: this.props.country }}
            />
            <PaymentCountryPicker
              required={true}
              countryPickerCallback={this.bankCountryCallback}
              label={"Bank Country"}
              selected={{ value: this.props.cambridge ? this.props.cambridge.bank_country : null }}
            />
            <CurrencyPicker {...this.props} selected={{ value: this.props.currency_code }} />
          </Row>
          <Row>
            <Form.Group as={Col} md="4">
              <GenericSelectPicker
                callback={this.classificationChanged}
                id={this.props.cambridge ? this.props.cambridge.classification : null}
                required={true}
                label={"Classification"}
                options={[
                  { id: "business", label: "Business" },
                  { id: "individual", label: "Individual" },
                ]}
              />
            </Form.Group>
            <Form.Group as={Col} md="4">
              <GenericSelectPicker
                callback={this.paymentMethodChanged}
                id={this.props.cambridge ? this.props.cambridge.payment_method : null}
                required={true}
                label={"Payment Method"}
                options={[
                  { id: "W", label: "Wire" },
                  { id: "E", label: "iACH" },
                ]}
              />
              {this.state.fields && this.state.fields.payment_method_error && (
                <h4 className="text-danger">Payment Method Type Not Supported</h4>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4">
              <CustomerPicker
                paymentType={"cambridge"}
                callback={this.customerPicked}
                id={this.props.cambridge ? this.props.cambridge.purchaser_id : null}
              />
            </Form.Group>
          </Row>
          <Row>
            {this.state.loading && (
              <Col className="mt-2 mb-2">
                <strong>{this.props.t("loading.fields")}...</strong>
              </Col>
            )}
            {!this.state.loading &&
              this.state.fields &&
              this.state.fields.rules &&
              this.state.fields.rules.length > 0 &&
              !this.state.fields.payment_method_error &&
              this.state.fields.rules.map((rule) => {
                return (
                  <CambridgeRuleInput
                    rule={rule}
                    callback={this.genericCallback}
                    key={rule.id}
                    id={this.props.cambridge[rule.id]}
                  />
                );
              })}
            {/* Regulatory rules */}
            {!this.state.loading &&
              this.state.fields &&
              this.state.fields.regulatoryRules &&
              this.state.fields.regulatoryRules.length > 0 && (
                <Fragment>
                  <Col sm={12}>
                    <h4>Regulatory Rules:</h4>
                  </Col>

                  {this.state.fields.regulatoryRules.map((rule) => {
                    return (
                      <CambridgeRuleInput
                        rule={rule}
                        callback={this.genericCallbackRegulatory}
                        key={rule.id}
                        id={this.props.cambridge[rule.id]}
                      />
                    );
                  })}
                </Fragment>
              )}
          </Row>
        </Col>
      </Row>
    );
  }

  genericCallback = (rule, event, value) => {
    if (event) {
      this.props.updateCambridgeField(rule.id, event.target.value);
    } else {
      this.props.updateCambridgeField(rule.id, value);
    }
  };

  genericCallbackRegulatory = (rule, event, value) => {
    if (event) {
      this.props.updateCambridgeRegulatoryField(rule.id, event.target.value);
    } else {
      this.props.updateCambridgeRegulatoryField(rule.id, value);
    }
  };

  countryCallback = (value) => {
    this.props.countryPickerCallback(value);
    this.autoPopulate(value);
  };

  //when someone selects destination country we should auto select the bank country and currency
  autoPopulate = (value) => {
    //auto select bank country
    this.bankCountryCallback(value);
    let currencyCode = getCurrencyCodeFromCountryCode(value.value);
    if (currencyCode) {
      this.props.currencyPickerCallback({ value: currencyCode });
    }
  };

  bankCountryCallback = (value) => {
    this.props.updateCambridgeField("bank_country", value.value);
  };

  classificationChanged = (value) => {
    this.props.updateCambridgeField("classification", value.value);
  };

  paymentMethodChanged = (value) => {
    this.props.updateCambridgeField("payment_method", value.value);
  };

  customerPicked = (value) => {
    this.props.updateCambridgeField("purchaser_id", value.value);
  };

  componentDidMount() {
    this.fetchFields();
    //autopopulate on start
    if (!this.props.cambridge || !this.props.cambridge.bank_country) {
      this.autoPopulate({ value: this.props.country });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //since our redux state could be updated from other components we call fetchFields whenever something has updated
    if (prevProps !== this.props) {
      this.fetchFields();
    }
  }

  // Fetch the required fields from the API
  fetchFields = () => {
    if (!this.canFetchFields()) {
      return;
    }
    this.setState({ loading: true });
    let params = {
      destination_country: this.props.country,
      bank_country: this.props.cambridge.bank_country,
      currency: this.props.currency_code,
      payment_method: this.props.cambridge.payment_method,
      classification: this.props.cambridge.classification,
      type: "cambridge",
      purchaser_id: this.props.cambridge.purchaser_id,
    };
    this.restApiService
      .get("payment_methods/required_fields", params)
      .then((res) => {
        let newState = { savedPMHash: this.getFieldHash() };
        if (res && res.data.response) {
          newState.fields = res.data.response;
          //set all redux fields equal to the default values we get from cambridge
          newState.fields.rules.forEach((rule) => {
            if (rule.defaultValue && rule.id == "classification") {
              this.props.updateCambridgeField(
                rule.id,
                rule.defaultValue.charAt(0).toUpperCase() + rule.defaultValue.slice(1),
              );
            } else if (rule.defaultValue) {
              if (rule.id == "preferredMethod" || rule.id == "paymentMethods") {
                this.props.updateCambridgeField(rule.id, this.props.cambridge.payment_method);
              } else if (!this.props.cambridge[rule.id]) {
                //normal default rule
                this.props.updateCambridgeField(rule.id, rule.defaultValue);
              }
            }
          });
        }
        newState.loading = false;
        this.setState(newState);
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  // We want to fetch fields automatically but we do NOT want to keep fetching them everytime the component updates.
  // We should only update when we have valid data to send to the API AND when we haven't done this particular request yet
  canFetchFields() {
    return (
      !this.state.loading &&
      this.props.country &&
      this.props.currency_code &&
      this.props.cambridge &&
      this.props.cambridge.purchaser_id &&
      this.props.cambridge.bank_country &&
      this.props.cambridge.payment_method &&
      this.props.cambridge.classification &&
      (this.getFieldHash() !== this.state.savedPMHash || !this.state.savedPMHash)
    );
  }

  // A 'hash' function to have a unique value for fields we have already fetched
  getFieldHash() {
    return (
      "" +
      this.props.country +
      this.props.currency_code +
      this.props.cambridge.bank_country +
      this.props.cambridge.payment_method +
      this.props.cambridge.classification +
      this.props.cambridge.purchaser_id
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    paymentMethodRed: state.paymentMethodRed,
    payment_type: state.paymentMethodRed.payment_type,
    country: state.paymentMethodRed.country,
    currency_code: state.paymentMethodRed.currency_code,
    purchaser_id: state.paymentMethodRed.purchaser_id,
    cambridge: state.paymentMethodRed.cambridge,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    countryPickerCallback: (payload) => {
      dispatch({ type: actionType.CHANGE_COUNTRY_CODE, payload: payload });
    },
    updateCambridgeField: (id, value) => {
      dispatch({ type: actionType.CHANGE_CAMBRIDGE_FIELD, payload: { id: id, value: value } });
    },
    updateCambridgeRegulatoryField: (id, value) => {
      dispatch({ type: actionType.CHANGE_CAMBRIDGE_REGULATORY_FIELD, payload: { id: id, value: value } });
    },
    changePaymentType: (payload) => {
      dispatch({ type: actionType.CHANGE_PAYMENT_TYPE, payload: payload });
    },
    updateFields: (payload) => {
      dispatch({ type: actionType.CHANGE_PAYMENT_METHOD, payload: payload });
    },
    changeFirstName: (payload) => {
      dispatch({ type: actionType.CHANGE_FIRST_NAME, payload: payload });
    },
    changeLastName: (payload) => {
      dispatch({ type: actionType.CHANGE_LAST_NAME, payload: payload });
    },
    changeEmail: (payload) => {
      dispatch({ type: actionType.CHANGE_EMAIL, payload: payload });
    },
    changePhone: (payload) => {
      dispatch({ type: actionType.CHANGE_PHONE_NUMBER, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CambridgeFields));
