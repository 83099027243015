import React, { ReactNode, useCallback, useMemo } from "react";
import { IDType } from "services/common/types/common.type";
import { AbstractListPickerTypes } from "./abstractListPickerTypes";

type TUseAbstractPickerLogic<T> = {
  valuePropertyName: string;
  labelPropertyName: string;
  formatOptionLabel?: (data: T) => ReactNode;
  currentId: IDType;
  selected?: AbstractListPickerTypes.TPickerValue<T>;
};

const useAbstractPickerLogic = <T extends { id: IDType; name?: string; customComponent?: ReactNode }>({
  formatOptionLabel,
  valuePropertyName,
  labelPropertyName,
  currentId,
  selected,
}: TUseAbstractPickerLogic<T>) => {
  const getOptionValue = useCallback((option) => option?.[valuePropertyName], [valuePropertyName]);
  const getOptionLabel = useCallback((option) => option?.[labelPropertyName], [labelPropertyName]);

  const getFormatOptionLabel = useCallback(
    (data) => {
      return formatOptionLabel ? (
        formatOptionLabel(data)
      ) : (
        <>
          {getOptionLabel(data)}
          {typeof data?.status === "string" && data.status.toLowerCase() === "inactive" && (
            <small className="inactiveOption">({data?.status})</small>
          )}
        </>
      );
    },
    [formatOptionLabel, getOptionLabel],
  );

  const isSelectedIsSteal = useMemo(() => {
    let selectedIsSteal = false;
    if ((selected && !currentId) || (!selected && currentId)) {
      // if id removed then selected is steal or
      // if selected empty and currentId value is present
      selectedIsSteal = true;
    } else if (selected && currentId && currentId !== selected?.id) {
      // if selected and currentId both are present but id don't matched to selected.id
      selectedIsSteal = true;
    }
    return selectedIsSteal;
  }, [currentId, selected]);

  return {
    getFormatOptionLabel,
    getOptionValue,
    getOptionLabel,
    isSelectedIsSteal,
  };
};

export default useAbstractPickerLogic;
