import React, { useEffect, useState, useRef, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import _ from "lodash";
import GridFilterDropdown from "../../../components/datagrid/gridFilterDropdown";
import ToggleFilterButton from "../../../components/datagrid/buttons/toggleFilterButton";
import ServerSideDataGrid from "../../../components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import vendorPurchaseOrdersHeader from "../../../components/vp/purchaseOrders/vendorPurchaseOrdersHeader";
import { GridApi, ColumnApi, GridReadyEvent } from "ag-grid-community";
import { IPurchaseOrderList } from "../../../services/vp/purchaseOrder/purchaserOrders.type";
import style from "./purchaseOrder.module.css";
import { DateFormatter, lessThanGreaterThanFormatter } from "../../../services/common/gridService";

type responseArray = {
  data: Array<IPurchaseOrderList>;
};

const restApiService: RestApi = new RestApi();

const PurchaseOrderList = () => {
  const [showingFilter, setShowingFilter] = useState<boolean>(true);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const firstUpdate = useRef(true);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: getRows });
  };
  const getParams = (params: any) => {
    let paramsObj: any = {
      //grid will pass in the first index and last index that needs to be fetched
      items: params.request.endRow - params.request.startRow,
      //default page size is 100 items
      page: 1 + params?.request?.startRow / params.api.paginationGetPageSize(),
    };
    for (const param in params.request.filterModel) {
      switch (param) {
        case "date": {
          DateFormatter(params.request, paramsObj, "date", "date_after", "date_before");
          break;
        }
        case "amount": {
          lessThanGreaterThanFormatter(
            params.request.filterModel["amount"],
            paramsObj,
            "amount_greater_than",
            "amount_less_than",
          );
          break;
        }
        case "open_balance": {
          lessThanGreaterThanFormatter(
            params.request.filterModel["open_balance"],
            paramsObj,
            "open_balance_greater_than",
            "open_balance_less_than",
          );
          break;
        }
        case "invoice_balance": {
          lessThanGreaterThanFormatter(
            params.request.filterModel["invoice_balance"],
            paramsObj,
            "invoice_balance_greater_than",
            "invoice_balance_less_than",
          );
          break;
        }
        case "purchaser.name": {
          paramsObj["purchaser_name"] = params.request.filterModel["purchaser.name"]
            ? params.request.filterModel["purchaser.name"].filter
            : "";
          break;
        }
        case "requestor.name": {
          if (
            params.request.filterModel["requestor.name"] &&
            params.request.filterModel["requestor.name"].type === "No data Specified"
          ) {
            paramsObj["requestor_name_has_data"] = 2;
          } else {
            paramsObj["requestor_name"] = params.request.filterModel["requestor.name"]
              ? params.request.filterModel["requestor.name"].filter
              : "";
          }
          break;
        }
        default:
          paramsObj[param] = params.request.filterModel[param] ? params.request.filterModel[param].filter : "";
      }
    }
    return paramsObj;
  };
  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = useCallback((params: any) => {
    //parameters to send to the api

    restApiService
      .get("vendor_purchase_orders", getParams(params))
      .then((result: responseArray) => {
        if (_.isObject(result) && result.data) {
          //we pass in the data we got from the API and the total row count so the grid knows how big to make the scrollbar
          // params.success({rowData: result.data, rowCount: result.data.meta.count});
          params.success({
            rowData: result.data,
            rowCount: result.data.length,
          });
        }
      })
      .catch((err) => {
        params.fail();
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!_.isEmpty(gridApi)) {
      gridApi.refreshHeader();
    }
  }, [showingFilter]);

  return (
    <Container fluid={true} className={"pt-4 pb-4 full-size"}>
      <Row>
        <Col className={style.grid}>
          <GridFilterDropdown
            gridApi={gridApi}
            gridColumnApi={gridColumnApi}
            options={{
              month: { dateName: "date" },
            }}
          />
          <ToggleFilterButton
            classes="float-right"
            clickCallback={() => {
              setShowingFilter((prev) => !prev);
            }}
          />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />
      <Row className={style.gridHeight + " px-3"}>
        <ServerSideDataGrid
          columnDefs={vendorPurchaseOrdersHeader}
          defaultColDef={{
            resizable: true,
            floatingFilter: showingFilter,
          }}
          gridReady={gridReady}
          gridStorageName="vendor_purchase_order"
          domLayout="normal"
        />
      </Row>
    </Container>
  );
};
export default PurchaseOrderList;
