import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Form, Row, OverlayTrigger, Popover } from "react-bootstrap";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { restApiService } from "../../../../../providers/restApi";
import { useTypedSelector } from "../../../../../reducers";
import {
  CHANGE_EMAIL,
  CHANGE_FIRST_NAME,
  CHANGE_LAST_NAME,
  CHANGE_PAYMENT_METHOD,
  CHANGE_PHONE_NUMBER,
} from "../../../../../actions/actionTypes";
import { useTranslation } from "react-i18next";
import { PayonnerRequiredFieldsType, PayoneerDetailsFeildsType } from "../../../../common/managePaymentMethod/types";
import { AxiosResponse } from "axios";

//If the input could be a list of inputs we show the tooltip
const getTooltip = ({ list, description }: PayoneerDetailsFeildsType) => {
  if (list && list.support_other === "False") {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose={true}
        overlay={
          <Popover id="popover-basic">
            <Popover.Title as="h3">Hint</Popover.Title>
            <Popover.Content>
              <p>{description}</p>
              <h5 className="mt-2">Accepted Values:</h5>
              {list.list_items.map((item: any) => {
                return (
                  <div key={item.props}>
                    {item.description} = {item.value}
                  </div>
                );
              })}
            </Popover.Content>
          </Popover>
        }
      >
        <div className="d-inline">
          <BsFillQuestionCircleFill />
        </div>
      </OverlayTrigger>
    );
  }
  return null;
};

const PayoneerFields = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [savedPMHash, setSavePMHash] = useState("");
  const [fields, setFields] = useState<PayonnerRequiredFieldsType["response"] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const country = useTypedSelector((state) => state.paymentMethodRed.country);
  const currencyCode = useTypedSelector((state) => state.paymentMethodRed.currency_code);
  const programId = useTypedSelector((state) => state.paymentMethodRed.program_id);
  const purchaser_id = useTypedSelector((state) => state.user.company.id);
  const paymentMethodRed = useTypedSelector((state) => state.paymentMethodRed);

  const updateFields = (payload: string) => {
    dispatch({ type: CHANGE_PAYMENT_METHOD, payload: payload });
  };

  const changeFirstName = (payload: string) => {
    dispatch({ type: CHANGE_FIRST_NAME, payload: payload });
  };

  const changeLastName = (payload: string) => {
    dispatch({ type: CHANGE_LAST_NAME, payload: payload });
  };

  const changeEmail = (payload: string) => {
    dispatch({ type: CHANGE_EMAIL, payload: payload });
  };

  const changePhone = (payload: string) => {
    dispatch({ type: CHANGE_PHONE_NUMBER, payload: payload });
  };

  //Since we dont know which field will be we use the name as the key for redux
  const handleInputChange = (event: any) => {
    let temp: any = {};
    temp[event.target.name] = event.target.value;
    updateFields(temp);
  };

  useEffect(() => {
    fetchFields();
  }, [country, currencyCode, programId]);

  const fetchFields = async () => {
    if (!canFetchFields()) {
      return;
    }
    setLoading(true);
    const params = {
      type: "payoneer",
      country: paymentMethodRed.country,
      currency: paymentMethodRed.currency_code,
      program: paymentMethodRed.program_id,
      purchaser_id: purchaser_id,
    };

    try {
      const response: AxiosResponse<PayonnerRequiredFieldsType> = await restApiService.get(
        "payment_methods/required_fields",
        params,
      );
      setSavePMHash(getFieldHash());
      if (response?.data?.response) {
        setFields(response.data.response);
      }
      setLoading(false);
    } catch (err) {
      console.log("error: ", err);
      setLoading(false);
    }
  };

  // We want to fetch fields automatically but we do NOT want to keep fetching them everytime the component updates.
  // We should only update when we have valid data to send to the API AND when we haven't done particular request yet
  const canFetchFields = () => {
    return !loading && country && currencyCode && purchaser_id && (getFieldHash() !== savedPMHash || !savedPMHash);
  };

  // A 'hash' function to have a unique value for fields we have already fetched
  const getFieldHash = () => {
    return "" + country + currencyCode + purchaser_id + programId;
  };

  //Some names in payoneer are different than accrualify, map them so our logic becomes easier
  const mapField = (payoneer_field: string) => {
    switch (payoneer_field) {
      case "bank_code":
        return "bankCode";
      case "swift":
        return "bic";
      case "branch_code":
        return "branchCode";
      case "routing_number":
        return "routing";
      case "bank_number":
        return "ifsc_code";
      case "bsb":
        return "bsb_code";
      default:
        return payoneer_field;
    }
  };

  return (
    <Row>
      <Col>
        <Row>
          <Form.Group as={Col} md="3">
            <Form.Label>
              {t("contact.firstName")}:<span className={"color-red"}>*</span>
            </Form.Label>
            <Form.Control
              required
              name="firstName"
              value={paymentMethodRed?.contact_first_name}
              onChange={(event) => {
                changeFirstName(event.target.value);
              }}
              type="text"
            />
            <Form.Control.Feedback type="invalid">{t("validation.firstName")}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>
              {t("contact.lastName")}:<span className={"color-red"}>*</span>
            </Form.Label>
            <Form.Control
              required
              name="lastName"
              value={paymentMethodRed?.contact_last_name}
              onChange={(event) => {
                changeLastName(event.target.value);
              }}
              type="text"
            />
            <Form.Control.Feedback type="invalid">{t("validation.lastName")}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>
              {t("address.email")}:<span className={"color-red"}>*</span>
            </Form.Label>
            <Form.Control
              required
              name="email"
              value={paymentMethodRed?.contact_email}
              onChange={(event) => {
                changeEmail(event.target.value);
              }}
              type="text"
            />
            <Form.Control.Feedback type="invalid">{t("validation.email")}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3">
            <Form.Label>
              {t("address.phone")}:<span className={"color-red"}>*</span>
            </Form.Label>
            <Form.Control
              required
              name="phone"
              value={paymentMethodRed?.phoneNumber}
              onChange={(event) => {
                changePhone(event.target.value);
              }}
              type="text"
            />
            <Form.Control.Feedback type="invalid">{t("validation.phone")}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          {loading && (
            <Col className="mt-2 mb-2">
              <strong>{t("loading.fields")}...</strong>
            </Col>
          )}
          {!loading &&
            fields &&
            fields.code === 0 &&
            Object.entries(fields.payout_method.details).map(([key, value]) => {
              return (
                <Col key={key}>
                  <Form.Group>
                    <Form.Label style={{ textTransform: "capitalize" }}>
                      {value.label}: {value.required === "True" && <span className={"color-red"}>*</span>}{" "}
                      {getTooltip(value)}
                    </Form.Label>
                    {paymentMethodRed?.id && key === "account_number" ? (
                      <Form.Control
                        required={value.required === "True"}
                        name={mapField(key)}
                        value={paymentMethodRed && paymentMethodRed[mapField(key)]}
                        onChange={handleInputChange}
                        type="text"
                        placeholder={value.watermark}
                        minLength={Number(value.min_length)}
                        maxLength={Number(value.max_length)}
                        pattern={
                          paymentMethodRed &&
                          paymentMethodRed[mapField(key)] &&
                          paymentMethodRed[mapField(key)].includes("*")
                            ? ".*"
                            : value.regex
                        }
                      />
                    ) : (
                      <Form.Control
                        required={value.required === "True"}
                        name={mapField(key)}
                        value={paymentMethodRed && paymentMethodRed[mapField(key)]}
                        onChange={handleInputChange}
                        type="text"
                        placeholder={value.watermark}
                        minLength={Number(value.min_length)}
                        maxLength={Number(value.max_length)}
                        pattern={value.regex}
                      />
                    )}

                    <Form.Control.Feedback type="invalid">
                      {t("validation.input")}: {value.watermark}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              );
            })}

          {!loading && fields && fields.code !== 0 && (
            <Col>
              <div className="text-danger mt-2 mb-2">
                <strong>
                  {t("loading.error")}: {fields.description}
                </strong>
              </div>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default PayoneerFields;
