import { GetCookie, DeleteCookie, SetCookie } from "../general/cookies";

const get = function () {
  // A temp_token cookie is available when the user authenticates through the API.
  // We use it then delete it
  let token = GetCookie("temp_token");
  if (token) {
    localStorage.setItem("token", token);
    DeleteCookie("temp_token");
  }

  return localStorage.getItem("Token");
};

const set = function (token) {
  //set the access token in a cookie so vendor portal can use it if need be
  this.set_cookie(token);
  return (localStorage.token = token);
};

const remove = function () {
  //remove the vendor portal cookie also
  DeleteCookie("domainAccessToken");
  return localStorage.removeItem("Token");
};

const set_cookie = function (token) {
  let cvalue = token ? token : this.get();
  let expiration = new Date();
  expiration.setMinutes(expiration.getMinutes() + 10);
  SetCookie("domainAccessToken", cvalue);
};

const accessToken = { get, set, remove, set_cookie };
export default accessToken;
