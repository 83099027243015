import React, { Component } from "react";
import RestApi from "../../../providers/restApi";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import Panel from "../../../components/panel/panel";
import { Link, withRouter } from "react-router-dom";

class VCardOnboardingAddFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();

    this.backURL = "/ap/onboarding/vcards";
    this.item_id = "funds_added";
  }

  componentDidMount() {
    if (!this.props.onboaringState.listTemplate) {
      //cant continue as the template is not defined, go to our back url
      this.props.history.push(this.backURL);
    }
  }

  fund_account = () => {
    //post the item_id to api
    this.restApiService
      .post("virtual_cards/onboarding/add_funds_and_activate_program", null, {
        fund_amount: this.state.fundingAmount,
      })
      .then((result) => {
        if (result.status == 200) {
          //change the redux state to reflect this item is completed
          this.props.onboaringState.listTemplate.items.forEach((element) => {
            if (element.id == this.item_id) {
              element.completed = true;
            }
          });
          this.props.setListTemplateItems(this.props.onboaringState.listTemplate.items);
          //go back to main checklist page
          this.props.history.push(this.backURL);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <Container className={"pt-4 pb-4"}>
        <Row>
          <Col>
            <Panel cardClasses={"animated fadeIn"}>
              <Row>
                <Col className="">
                  <Link to={this.backURL}>
                    <h5 className="text-right">X cancel</h5>
                  </Link>
                  <h1>Congrats!</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    Your vCard account has been created. Before you can begin issuing cards, you need to top off your
                    card balance. Please enter the balance you want to start with. Transfers may take up to 2 business
                    days.
                  </p>
                </Col>
              </Row>
              <Form noValidate validated={this.state.validated} onSubmit={this.submit}>
                <Form.Group as={Col} sm="8" controlId="fundAmount">
                  <Form.Label className="secondaryFontColor">{"Amount to Fund:"}</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control type="number" required name="fundingAmount" onChange={this.onChange} />
                    <Form.Control.Feedback type="invalid">{this.props.t("validation.amount")}</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form>

              <Row className="mt-4">
                <Col xs={6}>
                  <button className="btn btn-primary btn-block" type="button" onClick={this.fund_account}>
                    Complete Onboarding
                  </button>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    onboaringState: state.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setListTemplateItems: (payload) => {
      dispatch({ type: actionType.SET_ONBOARDING_LIST_TEMPLATE_ITEMS, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(VCardOnboardingAddFunds)));
