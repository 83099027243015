import usePermission from "components/common/hooks/usePermission";
import AngularRedirect from "components/common/tabNavigator/angularRedirect";
import React from "react";
import { Card, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import { RootState, useTypedSelector } from "reducers";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { Can } from "services/authorization/authorization";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { formattedAmount } from "services/general/helpers";
import style from "./expenseItem.module.css";

type ExpenseStatusPanelPropType = {
  expenseDetails: ExpensesTypes.Details;
};

const ExpenseStatusPanel = ({ expenseDetails }: ExpenseStatusPanelPropType) => {
  const { t } = useTranslation();
  const tLabel = (id: string) => t(`admin.pages.expenseItem.labels.${id}`);
  const { hasUserModule, hasUserPermission } = usePermission();
  const hasTransactionsPermission = hasUserPermission("listVCardTransactions") || hasUserModule("virtual_cards_active");

  const currentUser = useTypedSelector((state: RootState) => state.user, shallowEqual) as IUser | undefined;
  const glAccounts = expenseDetails.accounts_number_and_name_str?.split(",").map((e) => e.trim()) || [];

  // Got tired of messy "if value exists then display row" over and over.
  const optRow = (labelId: string, paramValue?: any, forceVisible: any = true) => {
    return paramValue && forceVisible ? (
      <tr>
        <td className={style.expenseItemTableFirstCell}>{tLabel(labelId)}</td>
        <td className={style.expenseItemTableSecondCell}>
          <div style={{ wordBreak: "break-word" }}>
            {" "}
            {/* Evil CSS stuff: Applying this css style to the <td> parent doesn't work. Has to be on a child div. */}
            {paramValue}
          </div>
        </td>
      </tr>
    ) : null;
  };

  return (
    <Col md="12">
      <Card className={style.expenseItemCard}>
        <table className={style.expenseItemTable}>
          <tbody>
            {optRow("merchant", expenseDetails.vendor_name)}
            {optRow("policy", expenseDetails.policy?.name)}
            {optRow("currencyCode", expenseDetails.currency_code)}
            {optRow("itemType", expenseDetails.item_type)}
            {optRow("submitter", expenseDetails.submitter?.name)}
            {optRow("submittedOnDate", companyDateFormat(expenseDetails.submit_date, currentUser))}
            {optRow("forReimbursement", expenseDetails.reimbursable ? tLabel("yes") : tLabel("no"))}
            {expenseDetails.purchase?.number && (
              <tr>
                <td className={style.expenseItemTableFirstCell}>{t("Purchase")}</td>
                <td className={style.expenseItemTableSecondCell}>
                  {hasTransactionsPermission ? (
                    <AngularRedirect to={`purchase/${expenseDetails.purchase?.id}`}>
                      {expenseDetails.purchase?.number}
                    </AngularRedirect>
                  ) : (
                    expenseDetails.purchase?.number
                  )}
                </td>
              </tr>
            )}
            {expenseDetails.expense_report?.id && (
              <tr>
                <td className={style.expenseItemTableFirstCell}>{tLabel("expenseReportNo")}</td>
                <td className={style.expenseItemTableSecondCell}>
                  <AngularRedirect to={`expense_report/${expenseDetails.expense_report?.id}`}>
                    {expenseDetails.expense_report?.number}
                  </AngularRedirect>
                </td>
              </tr>
            )}
            {optRow("transactionDate", companyDateFormat(expenseDetails.date, currentUser))}
            {optRow(
              "expenseAmount",
              formattedAmount(expenseDetails.amount?.toString(), expenseDetails.currency_code, 2, true),
            )}
            {optRow("tax", formattedAmount(expenseDetails.tax?.toString(), expenseDetails.currency_code, 2, true))}
            {expenseDetails.tax_code?.id && (
              <tr>
                <td className={style.expenseItemTableFirstCell}>{tLabel("taxCode")}</td>
                <td className={style.expenseItemTableSecondCell}>
                  {expenseDetails.tax_code?.name} {expenseDetails.tax_code.rate}%
                </td>
              </tr>
            )}
            {optRow("total", formattedAmount(expenseDetails.total?.toString(), expenseDetails.currency_code, 2, true))}
            <Can I="edit" a="ExpenseItems">
              {expenseDetails.purchase_order && (
                <tr>
                  <td className={style.expenseItemTableFirstCell}>{tLabel("poNos")}</td>
                  <td className={style.expenseItemTableSecondCell}>
                    <AngularRedirect to={`purchase_orders/${expenseDetails.purchase_order?.id}`}>
                      {expenseDetails.purchase_order?.number}
                    </AngularRedirect>
                  </td>
                </tr>
              )}
            </Can>
            {optRow("description", expenseDetails.description)}
            {optRow(
              "employee",
              `${expenseDetails.employee?.first_name || ""} ${expenseDetails.employee?.last_name || ""}`,
            )}
            {optRow("subsidiary", expenseDetails.subsidiary?.name)}
            {glAccounts.length > 0 && (
              <tr>
                <td className={style.expenseItemTableFirstCell} style={{ verticalAlign: "top" }}>
                  {tLabel("glImpact")}
                </td>
                <td className={style.expenseItemTableSecondCell}>
                  {glAccounts.map((e, index) => (
                    <React.Fragment key={index}>
                      {e}
                      <br />
                    </React.Fragment>
                  ))}
                </td>
              </tr>
            )}
            {optRow("externalID", expenseDetails.external_id)}
            {expenseDetails.billable && (
              <tr>
                <td className={style.expenseItemTableFirstCell}>{tLabel("billableAmt")}</td>
                <td className={style.expenseItemTableSecondCell}>
                  {formattedAmount(expenseDetails.total?.toString(), expenseDetails.currency_code, 2, true)}
                </td>
              </tr>
            )}
            {!currentUser?.company?.expense_item?.show_department &&
              optRow("submitterDept", expenseDetails.submitter?.department_name)}
            {expenseDetails?.rejection_comment &&
              !expenseDetails?.expense_report?.id &&
              optRow("rejectionComment", expenseDetails.rejection_comment)}
          </tbody>
        </table>
      </Card>
    </Col>
  );
};

export default ExpenseStatusPanel;
