import React from "react";
import { useDispatch } from "react-redux";
import { Field } from "redux-form";
import { ValidationType } from "../types";
import CurrencyCodePicker from "../../../common/pickers/reduxFormPickers/currencyCodePicker";
import { CHANGE_CURRENCY_CODE, CHANGE_PAY_BY_SWIFT_CODE } from "../../../../actions/actionTypes";
import { CommonTypes } from "services/common/types/commonTypes";
import _ from "lodash";

type PaymentTypePropsType = {
  validations?: ValidationType[];
  required?: boolean;
  label?: string;
  isMulti?: boolean;
  currencyCodeOptions: CommonTypes.ListCurrencyCodeItem;
};

const CurrencyCode = ({ validations, required, label, isMulti, currencyCodeOptions = [] }: PaymentTypePropsType) => {
  const dispatch = useDispatch();

  const changePayBySwiftCode = (payload: boolean) => {
    dispatch({ type: CHANGE_PAY_BY_SWIFT_CODE, payload: payload });
  };

  const onChangeCurrencyCode = (currencyCode: string) => {
    // as payby swift is only used with USD currency
    if (currencyCode !== "USD") {
      changePayBySwiftCode(false);
      return;
    }
    dispatch({ type: CHANGE_CURRENCY_CODE, payload: currencyCode });
  };

  const onCurrecyChange: any = (currencyCode: string) => {
    onChangeCurrencyCode(currencyCode);
  };

  return (
    <Field
      name="currency_code"
      label={label}
      component={CurrencyCodePicker}
      required={required}
      validate={validations}
      onChange={onCurrecyChange}
      isMulti={isMulti}
      currencyCodeOptions={_.isArray(currencyCodeOptions) ? currencyCodeOptions : []}
    />
  );
};

export default CurrencyCode;
