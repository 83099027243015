export function GetSpacedCardNumber(cardNumber) {
  if (cardNumber) {
    return (
      cardNumber.slice(0, 4) +
      " " +
      cardNumber.slice(4, 8) +
      " " +
      cardNumber.slice(8, 12) +
      " " +
      cardNumber.slice(12, 16)
    );
  } else {
    return "**** **** **** ****";
  }
}

export function GetFormattedExpirationDate(expireDate) {
  if (expireDate && expireDate.includes("-") && expireDate.length == 7) {
    // for "YYYY-MM"
    let split = expireDate.split("-");
    return split[1] + "/" + split[0].slice(2, 4);
  } else if (expireDate) {
    const dateObject = new Date(expireDate);
    const month = dateObject.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index (0 for January)
    const year = dateObject.getFullYear() % 100; // Getting last two digits of the year
    const formattedMonth = month < 10 ? "0" + month : month; // Formatting month to have leading zero if necessary
    return formattedMonth + "/" + year;
  } else {
    return "-/-";
  }
}
