import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field, change } from "redux-form";
import CountryCurrencyCodePicker, {
  CountryCurrencyCodeOptionType,
} from "../../pickers/reduxFormPickers/countryCurrencyCodePicker";
import { required } from "../../../../services/validations/reduxFormValidation";
import { useDispatch } from "react-redux";
import { ibanCurrencies, international_wire_payment_countries } from "../../../app.svc.Lookup";
import WireIban from "./wire/iban";
import WireAmd from "./wire/amd";
import BankAddressLine from "../bankAddressLine";
import WireEur from "./wire/eur";
import IntermediaryFields from "./wire/intermediary";
import WireAud from "./wire/aud";
import WireBrl from "./wire/brl";
import WireUsd from "./wire/usd";
import WireCad from "./wire/cad";
import WireGbp from "./wire/gbp";
import WireInr from "./wire/inr";
import WireJpy from "./wire/jpy";
import WireRub from "./wire/rub";
import WireSek from "./wire/sek";
import WireSgd from "./wire/sgd";
import WireUah from "./wire/uah";
import WireVnd from "./wire/vnd";
import WireNzd from "./wire/nzd";

const AiWirePaymentMethod = ({ modelName, modelData }: { modelName?: string; modelData?: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currencyCode, setCurrencyCode] = useState<string>();
  const [country, setCountry] = useState<string>();

  const componentMapping: any = {
    iban: WireIban,
    amd: WireAmd,
    eur: WireEur,
    aud: WireAud,
    brl: WireBrl,
    usd: WireUsd,
    cad: WireCad,
    gbp: WireGbp,
    inr: WireInr,
    jpy: WireJpy,
    rub: WireRub,
    sek: WireSek,
    sgd: WireSgd,
    uah: WireUah,
    vnd: WireVnd,
    nzd: WireNzd,
  };

  const renderCurrencyComponent = (currencyCode: string) => {
    let isIbanForm = ibanCurrencies.includes(currencyCode);
    let formName = isIbanForm ? "iban" : currencyCode.toLocaleLowerCase();
    const ComponentToRender = componentMapping[formName];
    return ComponentToRender && <ComponentToRender modelName={`${modelName}`} />;
  };

  const changeCurrencyCode = (selected: CountryCurrencyCodeOptionType) => {
    const filteredCountry = international_wire_payment_countries.find((value) => {
      return value.code2 === selected.country_code;
    });

    if (filteredCountry) {
      setCurrencyCode(filteredCountry.currency_code);
      setCountry(filteredCountry.code); //TODO: Show state by selected country
      dispatch(change("VendorForm", `${modelName}.country`, filteredCountry.code));
      dispatch(change("VendorForm", `${modelName}.address_attributes.country`, filteredCountry.code));
      dispatch(change("VendorForm", `${modelName}.currency_code`, filteredCountry.currency_code));
    }
  };

  useEffect(() => {
    if (modelData.currency_code) {
      setCurrencyCode(modelData.currency_code.toLowerCase());
    }
  }, [modelData, modelData.currency_code]);

  return (
    <>
      <Col md={6}>
        <Field
          name={`${modelName}.address_attributes.currencyObj`}
          component={CountryCurrencyCodePicker}
          returnObject
          FieldClassName="formField"
          placeholder={"Search/Select"}
          required
          paymentType="wire"
          hideCurrencycode={true}
          onChange={(selected: any) => changeCurrencyCode(selected)}
          validate={[required]}
          label={t("common.paymentMethod.countryAccountResides")}
        />
      </Col>
      {/* Load a component dynamically based on the selected currency. */}
      {currencyCode && renderCurrencyComponent(currencyCode)}
      <BankAddressLine modelName={`${modelName}`} hideCountry={true} defaultCountry={country} />
      <IntermediaryFields modelName={`${modelName}`} />
    </>
  );
};

export default AiWirePaymentMethod;
