import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { MapAPIToLanguage } from "./mapping";
import i18n from "../../i18n";

//This component lives at the top level of the application. It will set the correct language preference based on the saved userDetails on load / reload
const TranslationService = (props) => {
  const { i18n } = useTranslation();
  let [savedLanguage, setSavedLanguage] = useState("");
  if (
    props.user &&
    props.user.contact &&
    props.user.contact.language &&
    props.user.contact.language !== savedLanguage
  ) {
    setSavedLanguage(props.user.contact.language);
    //need a timeout to avoid react warning
    setTimeout(() => {
      i18n.changeLanguage(MapAPIToLanguage(props.user.contact.language));
    }, 100);
  }
  //this component is acting more as a service so no html render
  return false;
};
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TranslationService);

export function translate(columName) {
  return i18n.t("gridColumns." + columName);
}
