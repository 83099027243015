import { Col, Form, Row } from "react-bootstrap";
import {
  AccountHolder,
  AccountNumber,
  AccountType,
  BankName,
  BankRouting,
  ConfirmAccountNumber,
  DeliveryMethod,
} from "../fieldComponents/fieldsComponent";
import React, { useState } from "react";
import { AchValidation } from "../validations/achPaymentValidations";

function selectField(fillteredPayment, fieldName) {
  return fillteredPayment[0].validations.find((item) => item.field === fieldName);
}

export const AchPaymentDetail = (props) => {
  const [data] = useState({ validations: [] });
  data.validations = AchValidation[0].validations;

  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(props.fillteredPayment, "account_name")} />
        <AccountNumber {...props} field_valid={selectField(props.fillteredPayment, "account_number")} />
        {props?.editAccountDetail?.id ? null : <ConfirmAccountNumber {...props} />}
      </Form.Row>

      <Form.Row>
        <AccountType {...props} field_valid={selectField(props.fillteredPayment, "account_type")} />

        <BankRouting {...props} bankNameValidationObj={selectField(props.fillteredPayment, "bank_name")} />
        <BankName {...props} field_valid={selectField(props.fillteredPayment, "bank_name")} />
      </Form.Row>
      <Form.Row>
        <DeliveryMethod {...props} field_valid={selectField(props.fillteredPayment, "delivery_method")} />
        <Col md="8" />
      </Form.Row>
    </span>
  );
};
