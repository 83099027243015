import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import NavTabs from "../nav";
import VendorPortalStats from "./VendorPortalStats";
import GridFilterDropdown from "components/datagrid/gridFilterDropdown";
import ToggleFilterButton from "components/datagrid/buttons/toggleFilterButton";
import { ColumnApi, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import { VendorApis } from "services/admin/vendors/vensorApis";
import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import { FIELD_NAME_MAP, getVendorsHeaders } from "./vendorTrackersGridHeader";
import { processFilterModel, saveDefaultOrder } from "services/common/gridService";
import { useTypedSelector } from "reducers";
import BulkAction from "../bulkAction";
import ExportDataButton from "components/common/dataGrid/exportDataButton";
import { useTranslation } from "react-i18next";
import _ from "lodash";
const GRID_STORAGE_NAME = "listVendorTracker";

const ListVendorTracker = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const { showFilters, updateShowFilters } = useShowFilterState("listVendorTrackerFilter", true);
  const currentUser = useTypedSelector((state) => state.user);
  const checkBoxRef: any = useRef(null);
  const externalFilterRef: { current: { [key: string]: any } } = useRef({});
  const { t } = useTranslation();

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: getRows });
  };

  const getParams = (params: IServerSideGetRowsParams) => {
    let sortObject: any = {};
    if (params?.request?.sortModel?.length) {
      params.request.sortModel.forEach(({ colId, sort }: { colId: string; sort: string }, index: number) => {
        sortObject[colId] = { direction: sort, priority: index };
      });
    } else {
      sortObject = { id: { direction: "desc", priority: 0 } };
    }
    let paramsObj: any = {
      sort: sortObject,
    };
    if (params?.request?.endRow !== undefined && params?.request?.startRow !== undefined) {
      paramsObj.items = params?.request?.endRow - params?.request?.startRow; //grid will pass in the first index and last index that needs to be fetched
      paramsObj.page = 1 + params?.request?.startRow / params.api.paginationGetPageSize();
    }
    if (_.isPlainObject(externalFilterRef.current)) {
      paramsObj = { ...paramsObj, ...externalFilterRef.current };
    }
    if (params?.request?.filterModel) {
      let filters = processFilterModel(params.request.filterModel, FIELD_NAME_MAP);
      // Filters need to send with params we are passign to vnedor list API
      paramsObj = { ...paramsObj, ...filters };
    }
    return paramsObj;
  };

  const getRows = async (params: any) => {
    try {
      const result = await VendorApis.getVendorTrackerList({ filter: getParams(params) });

      params.success({
        rowData: result.data,
        rowCount: result.meta.count,
      });
    } catch (error) {
      params.fail();
    }
  };

  const setExternalFilterParams = useCallback(
    (params: { [key: string]: any }) => {
      if (!_.isEqual(params, externalFilterRef.current)) {
        if (params) {
          externalFilterRef.current = params;
        } else {
          externalFilterRef.current = {};
        }
        if (gridApi) {
          gridApi.refreshServerSide();
        }
      }
    },
    [gridApi],
  );

  useEffect(() => {
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
  }, []);

  const gridHeaders = getVendorsHeaders({ gridApi, gridColumnApi, checkBoxRef, currentUser });

  return (
    <>
      <Row style={{ margin: "10px 25px" }}>
        <Col sm={12} className="p-0 mb-2">
          <NavTabs activePageName="Vendor_tracker" />
          <Col className="px-p-0">
            <VendorPortalStats setExternalFilter={setExternalFilterParams} externalFilter={externalFilterRef.current} />
          </Col>
        </Col>
        <Col className="px-p-0 d-flex">
          <div className="px-mr-16">
            <BulkAction gridApi={gridApi} modelName="vendorTracker" />
          </div>

          <GridFilterDropdown
            gridApi={gridApi}
            gridColumnApi={gridColumnApi}
            gridStorageName={GRID_STORAGE_NAME}
            clearExternalFilter={() => (externalFilterRef.current = {})}
          />
          {gridApi && (
            <div className="ml-auto px-pt-8">
              <ExportDataButton
                title={t("admin.pages.vendor.exportVendors")}
                csvName="vendor_tracker"
                gridApi={gridApi}
              />
            </div>
          )}
          <ToggleFilterButton
            classes="float-right"
            clickCallback={() => {
              updateShowFilters(!showFilters);
            }}
          />
        </Col>
      </Row>
      <Row style={{ height: "70vh", margin: "25px" }}>
        <ServerSideDataGrid
          columnDefs={gridHeaders.columnDefs}
          defaultColDef={{
            resizable: true,
            filter: true,
            floatingFilter: showFilters,
          }}
          headerHeight={30}
          onPaginationChanged={() => checkBoxRef?.current?.onPaginationChange()}
          gridReady={gridReady}
          rowSelection="multiple"
          gridStorageName={GRID_STORAGE_NAME}
          domLayout="normal"
          paginationSize={25}
          paginationOptions={[25, 50, 100]}
          pagination
        />
      </Row>
    </>
  );
};

export default ListVendorTracker;
