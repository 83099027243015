import { Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState } from "react";
import Select from "react-select";
import { international_payment_countries } from "../../app.svc.Lookup";
import { international_wire_payment_countries } from "../../app.svc.Lookup";
import { useTranslation } from "react-i18next";

import CurrencyPicker from "../../admin/pickers/reduxPicker/currencyPicker/currencyPicker";

export const MainPaymentMethod = (props) => {
  const { t } = useTranslation();
  const [data] = useState({ purchasers: props.purchasers, changed_payment_contries: [] });
  const payment_countries =
    props.payment_type === "wire" ? international_wire_payment_countries : international_payment_countries;

  data.changed_payment_contries = payment_countries
    .map((item, i) => ({
      currency_code: item.currency_code,
      country_code: item.code,
      value: item.code + item.currency_code,
      label: (
        <div>
          <img
            src={require("../../../assets/flags/" + item.flag)}
            height="30px"
            width="30px"
            style={{ padding: 5 }}
            alt="flag"
          />
          {!item.is_wire_type && <span>{item.currency_code}</span>} {item.name}
        </div>
      ),
    }))
    .filter((value) => Boolean(value));

  const swiftCallBack = () => {
    // if other Currency than dollart is selected than close pay by swift options
    props.changePayBySwiftCode(false);
  };

  const findSelected = () => {
    return data.changed_payment_contries.find(
      (o) =>
        o.currency_code === (props.currency_code ? props.currency_code : props.defaultCurrency) &&
        o.country_code === props.country,
    );
    // return {
    //   value: props.country + props.currency_code ,
    //   label: data.changed_payment_contries.find(o =>{
    //     return o.currency_code === (props.currency_code ? props.currency_code : props.defaultCurrency ) && o.country_code === props.country;
    //   }).label
    // }
  };

  let showACH = false;
  let showTransferwise = false;
  let showPayoneer = false;
  let showPayoneerBranded = false;
  let showCreditCard = false;
  let showWire = false;
  let showEFTCanada = false;
  let showCambridge = false;
  let showEFTNz = false;
  let showEFTAusPayNet = false;
  let showFedWire = false;
  let showEFTSg = false;
  //check whether any of our purchasers have the payment methods
  props.purchasers.forEach((purchaser) => {
    showACH = showACH || purchaser.accepted_payment_methods.ach;
    showTransferwise = showTransferwise || purchaser.accepted_payment_methods.transferwise;
    showPayoneer = showPayoneer || purchaser.accepted_payment_methods.payoneer;
    showPayoneerBranded = showPayoneerBranded || purchaser.accepted_payment_methods.payoneer_branded;
    showCreditCard = showCreditCard || purchaser.accepted_payment_methods.credit_card;
    showWire = showWire || purchaser.accepted_payment_methods.wire;
    showEFTCanada = showEFTCanada || purchaser.accepted_payment_methods.can_eft;
    showEFTNz = showEFTNz || purchaser.accepted_payment_methods.nz_eft;
    showEFTAusPayNet = showEFTAusPayNet || purchaser.accepted_payment_methods.aus_pay_net;
    showCambridge = showCambridge || purchaser.accepted_payment_methods.cambridge;
    showFedWire = showFedWire || purchaser?.accepted_payment_methods?.fed_wire;
    showEFTSg = showEFTSg || purchaser?.accepted_payment_methods?.sg_eft;
  });

  return (
    <Row>
      <Form.Group as={Col} md="4">
        <Form.Label>
          {t("paymentMethod.paymentType")}:<span className={"color-red"}>*</span>
        </Form.Label>
        <Form.Control
          required
          as="select"
          value={props.payment_type}
          onChange={(e) => {
            props.changePaymentType(e.target.value);
            props.clearPaymentMethod(false);
            props.clearValidations();
            props.onPaymentTypeChangeCallback();
          }}
        >
          <option value={""}>Select...</option>
          {showACH && <option value={"ach"}>{t("paymentMethod.achOption")}</option>}
          {showTransferwise && <option value={"international"}>{t("paymentMethod.transferWiseOption")}</option>}
          {showCambridge && <option value={"cambridge"}>International - Cambridge</option>}
          {showPayoneer && <option value={"payoneer"}>{t("paymentMethod.payoneerOption")}</option>}
          {showPayoneerBranded && <option value={"payoneer_branded"}>{"Payoneer"}</option>}
          {showWire && <option value={"wire"}>{t("paymentMethod.wireOption")}</option>}

          {showCreditCard && <option value={"credit_card"}>Credit Card</option>}
          {showEFTCanada && <option value={"can-eft"}>EFT - Bank Account(CANADA)</option>}
          {showEFTNz && <option value={"nz-eft"}>EFT - Bank Account(New Zealand)</option>}
          {showEFTAusPayNet && <option value={"aus-pay-net"}>EFT - Australian PayNet</option>}
          {showFedWire && <option value={"fed-wire"}>Domestic/Fedwire - Wire</option>}
        </Form.Control>
      </Form.Group>
      {/* TODO remove this and put in individual payment forms */}
      {(props.payment_type === "international" || props.payment_type === "wire") &&
        (!props.pay_by_swift_code ? (
          <Form.Group as={Col} md="4" className={"color-red"}>
            <Form.Label>
              {t("paymentMethod.countryResides")}:<span className={"color-red"}>*</span>
            </Form.Label>
            <Select
              // className={"selectCurrency"}
              placeholder={"select/search the list"}
              // styles={{
              //   valueContainer: base => ({
              //     ...base,
              //     height: 40,
              //     minHeight: 40
              //   })
              // }}
              value={findSelected()}
              options={data.changed_payment_contries}
              onChange={(e) => {
                props.changeCountryCurrencyCode(e);
              }}
            />
          </Form.Group>
        ) : (
          <CurrencyPicker {...props} selected={{ value: "USD" }} currencyPickerCallback={swiftCallBack} />
        ))}
      {props.payment_type === "fed-wire" && <CurrencyPicker {...props} selected={{ value: props.currency_code }} />}
    </Row>
  );
};
