import { AgGridReact } from "ag-grid-react";
import React, { memo, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import childCellRouting from "../../../datagrid/childCellRouting";
import ChildMessageRenderer from "../../../datagrid/childMessageRenderer";
// import CustomStatsToolPanel from "../../../datagrid/customStatsToolPanel";
import { GetContextMenuItemsParams, GridOptions, GridReadyEvent, RowClassRules } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import RandomChildCellRouting from "../../../../components/datagrid/randomCellChildRouting";
import {
  ColumnConfig,
  getContextMenuItems,
  getPreviousFilterStates,
  saveState,
  SideBarConfig,
} from "../../../../services/common/gridService";
import FloatingFilterComponent from "../../../datagrid/floatingFilterComponent";
import CustomSelectFilter from "../customSelectFilter";
import DateFilter from "../gridDateFilter";
import CustomStatToolPanel from "./../customStatToolPanel";

type propsType = {
  rowHeight?: number;
  columnDefs: Array<any>;
  gridReady?: (params: GridReadyEvent) => void;
  headerHeight?: number;
  rowData: Array<any>;
  defaultColDef?: object;
  gridStorageName: string;
  pagination?: boolean;
  cacheBlockSize?: number;
  maxBlocksInCache?: number;
  paginationPageSize?: number;
  domLayout?: "normal" | "autoHeight" | "print" | undefined;
  rowClassRules?: RowClassRules;
  skipContextMenuCols?: string[];
  hideSideBar?: boolean;
  suppressMenuHide?: boolean;
  suppressMovableColumns?: boolean;
  rowSelection?: GridOptions["rowSelection"];
};

const ClientDataGrid = ({
  rowHeight,
  columnDefs = [],
  gridReady = (params: GridReadyEvent) => {},
  rowData,
  defaultColDef,
  gridStorageName,
  headerHeight,
  pagination,
  cacheBlockSize,
  domLayout,
  maxBlocksInCache,
  paginationPageSize,
  rowClassRules,
  skipContextMenuCols,
  hideSideBar = false,
  suppressMenuHide = false,
  suppressMovableColumns = false,
  rowSelection,
}: propsType) => {
  const gridElementRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<AgGridReact>(null);

  const onGridReady = (params: GridReadyEvent) => {
    gridReady(params);
    getPreviousFilterStates({ gridRef, gridStorageName });
  };

  // when press hard reload use effect clean up event didn't get trigger
  // these use effect is only of adding and removing event listeners for grid state saving
  useEffect(() => {
    window.addEventListener("beforeunload", () => saveState({ gridRef, gridStorageName }));
    return () => {
      window.removeEventListener("beforeunload", () => saveState({ gridRef, gridStorageName }));
    };
  }, []);

  // for every render grid colState is getting reset
  // so setting grid previous state for every render
  // expensive but required
  useEffect(() => {
    getPreviousFilterStates({ gridRef, gridStorageName });
  });

  // using use layout effect
  // as it will save the function call synchrounously
  useLayoutEffect(() => {
    // define cleanup function to save state before component unmounts
    return () => {
      saveState({ gridRef, gridStorageName });
    };
  }, []);

  const resizeColumnsToFit = () => {
    gridRef?.current?.api?.sizeColumnsToFit();
  };

  // keeping useEffect separate for every fix, that keeping consern code separate
  // fixing: columns are not covering all space in table
  useEffect(() => {
    // Resize the columns to fit the container size when the component mounts
    resizeColumnsToFit();
    // Resize the columns to fit the container size when the window is resized
    window.addEventListener("resize", resizeColumnsToFit);
    return () => {
      window.removeEventListener("resize", resizeColumnsToFit);
    };
  }, []);

  const contextMenu = useCallback((params: GetContextMenuItemsParams) => {
    return getContextMenuItems(params, skipContextMenuCols);
  }, []);

  return (
    <div ref={gridElementRef} style={{ height: "auto", width: "100%" }} className="ag-theme-fresh animated fadeIn">
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={{
          resizable: true,
          suppressMenu: true,
          filterParams: {
            suppressAndOrCondtion: true,
          },
          ...defaultColDef,
        }}
        rowData={rowData}
        onGridReady={onGridReady}
        rowSelection={rowSelection}
        rowHeight={rowHeight ? rowHeight : ColumnConfig.rowHeight}
        headerHeight={headerHeight ?? ColumnConfig.headerHeight}
        rowClassRules={rowClassRules}
        floatingFiltersHeight={ColumnConfig.floatingFiltersHeight}
        getContextMenuItems={contextMenu}
        suppressMenuHide={suppressMenuHide}
        suppressMovableColumns={suppressMovableColumns}
        components={{
          childMessageRenderer: ChildMessageRenderer,
          childCellRouting: childCellRouting,
          randomChildCellRouting: RandomChildCellRouting,
          customStatsToolPanel: CustomStatToolPanel,
          floatingFilterComponent: FloatingFilterComponent,
          agDateInput: DateFilter,
          agCustomSelectFilter: CustomSelectFilter,
        }}
        sideBar={hideSideBar ? null : SideBarConfig}
        pagination={pagination}
        cacheBlockSize={cacheBlockSize}
        maxBlocksInCache={maxBlocksInCache}
        paginationPageSize={paginationPageSize}
        // now height of the grid table must be deside form parent Row for ServiceSideDataGrid component.
        domLayout={domLayout}
        // to Save grid when filter change or column moved
        onSortChanged={() => saveState({ gridRef, gridStorageName })}
        onFilterChanged={() => saveState({ gridRef, gridStorageName })}
        onColumnMoved={() => saveState({ gridRef, gridStorageName })}
        onColumnResized={() => saveState({ gridRef, gridStorageName })}
        onColumnPinned={() => {
          saveState({ gridRef, gridStorageName });
          resizeColumnsToFit();
        }}
        onColumnValueChanged={() => saveState({ gridRef, gridStorageName })}
        onColumnVisible={() => {
          saveState({ gridRef, gridStorageName });
          resizeColumnsToFit();
        }}
        onToolPanelVisibleChanged={() => {
          saveState({ gridRef, gridStorageName });
          resizeColumnsToFit();
        }}
      />
    </div>
  );
};

export default memo(ClientDataGrid);
