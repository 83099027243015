import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { change, Field, getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTypedSelector } from "reducers";
import RestApi from "providers/restApi";

import Panel from "components/common/panel/panel";
import FileUploader from "components/common/fileUploader/fileUploader";
import TabNavigation from "components/navigation/tabNavigation";
import NavTabs from "pages/admin/administration/nav";
import { CreateNotification, NotificationType } from "services/general/notifications";

import styles from "./metadataConfiguration.module.css";
import { RenderSelect } from "components/forms/bootstrapFields";
import MetadataConfigurationEdit from "components/metadata/metadataConfigurationEdit/metadataConfigurationMultiEdit";
import MetadataConfigurationRow from "components/metadata/metadataConfigurationEdit/metadataConfigurationUpsert";
import MetadataConfigurationNavbar from "./metadataConfigurationNavbar";

export interface MetadataConfiguration {
  id?: number | null;
  field_id?: string | null;
  name?: string;
  object_type?: string | null;
  input_type?: string | null;
  required?: boolean | null;
  created_at?: Date | null;
  updated_at?: Date | null;
}

export interface MetadataSorter {
  field_id?: string | null;
  name?: string | null;
  configurations: MetadataConfiguration[];
}

const formName = "metadataConfigurationForm";

let MetadataConfigurationPage = ({ handleSubmit }: InjectedFormProps<FormData, {}>) => {
  const dispatch = useDispatch();

  const form: any = useSelector((state) => getFormValues(formName)(state), shallowEqual);
  const currentUser = useTypedSelector((state) => state.user, shallowEqual);
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [metadataConfigurations, setMetadataConfigurations] = useState({});

  const getConfigurations = useCallback(async () => {
    const restApi = new RestApi();
    const apiResult = await restApi.get("metadata_configurations");

    const configurations: MetadataConfiguration[] = apiResult.data;

    let parsedConfigurations: any = {};
    configurations.forEach((configuration) => {
      if (configuration.field_id && parsedConfigurations[configuration.field_id]) {
        parsedConfigurations[configuration.field_id].configurations.push(configuration);
      } else if (configuration.field_id) {
        let newConfiguration: MetadataSorter = {
          field_id: configuration.field_id,
          name: configuration.name,
          configurations: [configuration],
        };
        parsedConfigurations[configuration.field_id] = newConfiguration;
      }
    });

    setMetadataConfigurations(parsedConfigurations);
  }, []);

  useEffect(() => {
    getConfigurations();
  }, []);

  return (
    <Container fluid={true}>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"Form Configuration"} />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Panel>
        <MetadataConfigurationNavbar />
        <div className={"d-flex"}>
          <div className="flex-grow-1">
            <p className="mb-2">
              Once a field is created you can import the possible values for that field in the{" "}
              <Link to={`/ap/bulk_uploads/add`}>bulk uploader</Link>. Please note your field id to properly link the
              values to the configuration.
            </p>
            <MetadataConfigurationRow
              form={`metadataConfigurationForm`}
              editing={false}
              initialValues={{ object_type: "Invoice", input_type: "select", required: true }}
              refreshCallback={getConfigurations}
              metadataConfiguration={{}}
            ></MetadataConfigurationRow>

            <hr></hr>

            {Object.entries(metadataConfigurations).length > 0 && (
              <div className="mt-4">
                <h3>Edit Fields</h3>
                {Object.entries(metadataConfigurations).map(([key, value]) => (
                  <MetadataConfigurationEdit
                    refreshCallback={getConfigurations}
                    key={key}
                    metadataSorter={value as MetadataSorter}
                  ></MetadataConfigurationEdit>
                ))}
              </div>
            )}
          </div>
        </div>
      </Panel>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({ initialValues: state.metadataConfigurationForm });

const MetadataConfigurationForm = connect(mapStateToProps)(
  reduxForm<FormData>({
    form: formName,
    // onSubmit,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true,
    destroyOnUnmount: true,
    // updateUnregisteredFields: true,
  })(MetadataConfigurationPage),
);

export default MetadataConfigurationForm;
