import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { restApiService } from "providers/restApi";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { WrappedFieldInputProps } from "redux-form";
import PurchaseOrdersApis from "services/admin/purchaseOrders/purchaseOrderApi";
import { PurchaseOrderType } from "services/admin/purchaseOrders/purchaseOrderType";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { formattedAmount, parseForSelect } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { Mandatory } from "wombatifier/components/pickers/mandatory";
import { PurchaseOrderPickerPropsType } from ".";

// testing deployment

export interface SinglePOPickerPropsType extends PurchaseOrderPickerPropsType {}

// this component is specific to expense item form only
const SinglePOPicker = ({
  input,
  meta: { error, touched },
  modelData,
  callBack,
  instanceId,
  label,
  placeholder,
  required,
  tooltip,
  contactId,
  useParentCurrencyCode,
  searchPo,
  isClearable = false,
}: SinglePOPickerPropsType) => {
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const [selected, setSelected] = useState<
    (PurchaseOrderType.DetailType & { label?: string; value?: IDType }) | null
  >();
  const contactRef = useRef<IDType | undefined>(contactId ? contactId : currentUser.contact.id);
  const currencyCodeRef = useRef<string>();

  const getPurchaseOrders = (inputValue: string, callback: any): void => {
    const poNumber = inputValue;
    let obj: Record<string, any> = { by_open: true, number: poNumber, record_limit: 50 };

    if (currentUser.company.expense?.show_subsidiary && modelData?.subsidiary_id) {
      obj.subsidiary_id = modelData.subsidiary_id;
    }

    if (modelData?.is_expense_call_from_po_detail_page) {
      obj.id = modelData?.purchase_order_id;
    } else {
      obj.requestor_id = contactRef.current;
      if (useParentCurrencyCode) {
        obj.currency_code = currencyCodeRef.current ? currencyCodeRef.current : modelData?.currency_code;
      }
    }
    if (searchPo) {
      obj = { by_open: true, number: poNumber, record_limit: 50 };
    }

    try {
      const poPromise = restApiService.get("purchase_orders", obj);
      poPromise.then((response) => {
        const data = response?.data?.data;
        if (Array.isArray(data)) {
          const parsedData = parseForSelect(data);
          callback(parsedData);
        }
      });
    } catch {
      CreateNotification("Error", "Service Error ", NotificationType.danger);
    }
  };

  const findSelected = async () => {
    if (input.value) {
      const po = await PurchaseOrdersApis.getPurchaseOrder(input.value);
      setSelected({ ...po, label: po.number, value: po.id });
    } else {
      setSelected(null);
    }
  };

  const setValue = (selected: any) => {
    if (selected) {
      input.onChange(selected.value);
      setSelected(selected);
      if (callBack) {
        callBack(selected);
      }
    } else {
      input.onChange(null);
      setSelected(null);
      if (callBack) {
        callBack(null);
      }
    }
  };

  const onChange = (selected: any) => {
    setValue(selected);
  };

  useEffect(() => {
    if (contactId && contactId !== contactRef.current) {
      if (contactRef.current) {
        // if previous defined then remove current selected po
        setValue(null);
      }
      contactRef.current = contactId;
      // find selected will run automatically
    }
  }, [contactId]);

  const onBlurSingle = (input: WrappedFieldInputProps, option: any) => {
    if (!option) {
      input.onBlur(null);
      return;
    }
    input.onBlur(option);
  };

  useEffect(() => {
    if (modelData?.currency_code && modelData?.currency_code !== currencyCodeRef.current) {
      if (currencyCodeRef.current) {
        // if previous currency code defined then remove current selected po
        setValue(null);
      }
      currencyCodeRef.current = modelData?.currency_code;
    }
    // find selected will run automatically
  }, [modelData?.currency_code]);

  useEffect(() => {
    findSelected();
  }, [input.value]);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
          {tooltip}
        </Form.Label>
      )}
      <AsyncSelect
        value={selected}
        loadOptions={getPurchaseOrders}
        formatOptionLabel={(purchaseOrder, context) =>
          context.context === "menu" ? (
            <small className="selectChoices">
              <strong>
                {purchaseOrder.number} - {purchaseOrder.vendor_name || "(Type: " + purchaseOrder.po_type + ")"}
              </strong>
              <br />
              <strong>Amount:</strong>{" "}
              {formattedAmount(purchaseOrder.amount?.toString(), purchaseOrder.currency?.iso_code)} /{" "}
              <strong>Balance:</strong>{" "}
              {formattedAmount(purchaseOrder.open_balance?.toString(), purchaseOrder.currency?.iso_code)} <br />
              <strong>Date:</strong> {companyDateFormat(purchaseOrder.date, currentUser, true)} <strong>Status:</strong>{" "}
              {purchaseOrder.status} <br />
              <strong>Description:</strong> {purchaseOrder.description}
            </small>
          ) : (
            purchaseOrder.label
          )
        }
        onChange={onChange}
        onBlur={() => onBlurSingle(input, input.value)}
        isClearable={isClearable}
        placeholder={placeholder}
        instanceId={instanceId || "single-po-picker"}
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default SinglePOPicker;
