import PicturePreview from "../widgets/fileUpload/picturePreview";
import Dropzone from "react-dropzone";
import { Button } from "react-bootstrap";
import React, { Component } from "react";

class AttachmentLoader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onDrop, onFileDelete } = this.props;
    return (
      <div className="col-md-6">
        {this.props.state.files[0] && (
          <PicturePreview
            className="filePreview"
            key={this.props.state.files[0].name}
            file={this.props.state.files[0]}
            deleteCallback={onFileDelete}
          />
        )}
        {!this.props.state.files[0] && (
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section
                className={
                  "container " +
                  (this.props.state.assetsValid
                    ? "uploadFileContainer FileUploadSection"
                    : "uploadFileContainerNotValid FileUploadSection")
                }
              >
                <div {...getRootProps({ className: "dropzone" })} className="text-center">
                  <input {...getInputProps()} />
                  <h4 className="text-center">{this.props.t("forms.invoiceUpload")}</h4>
                  <i className={"uploadReceiptIcon uploadIcon"}> </i>
                  <button type="button" className="btn btn-primary mt-2">
                    {this.props.t("forms.uploadExpenseAttachment")}
                  </button>
                  {!this.props.state.assetsValid && (
                    <div style={{ fontSize: "80%", color: "#dc3545", display: "block" }} className="mt-2">
                      {this.props.t("validation.attachment")}
                    </div>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}
export default AttachmentLoader;
