import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SettingsTabs from "../settingsTabs";
import { useDispatch, useSelector } from "react-redux";
import Addresses from "../../../../components/admin/addresses";
import ErrorBoundary from "../../../../components/common/errorBoundary/errorBoundary";
import AttachmentSection from "../../../../components/common/attachmentSection/attachmentSection";
import useIsMounted from "../../../../components/common/hooks/useIsMounted";
import RestApi from "../../../../providers/restApi";
import _ from "lodash";
import usePermission from "../../../../components/common/hooks/usePermission";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import SamlConfigurationForm from "../../../../components/admin/samlConfiguration/samlConfiguration";
import { Can } from "../../../../services/authorization/authorization";
import {
  getSamlConfiguarionForm,
  onSubmitConfigurationForm,
} from "../../../../reducers/admin/samlConfigurationReducer";
import settingsStyles from "./../settings.module.css";
import ImportnatDates from "../../../../components/admin/importantDates/importantDates";
import { useTranslation } from "react-i18next";
import useConfirmModal from "../../../../components/modals/confirmModal/useConfirmModalHook";
import Footer from "components/common/footer/footer";

const restApiService = new RestApi();

const Landing = () => {
  const currentUser = useSelector((state) => state.user);
  const { hasUserPermission } = usePermission();
  const [company, setCompany] = useState(null);
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();

  const getCompany = useCallback(async () => {
    try {
      const response = await restApiService.get("company");
      if (isMounted) {
        setCompany(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getCompany();
    if (hasUserPermission("editSettings")) {
      dispatch(getSamlConfiguarionForm());
    }
  }, []);

  const onUploadCompanyAttachment = async (files) => {
    try {
      const formData = new FormData();
      if (_.isArray(files)) {
        files.forEach((file, index) => {
          formData.append(`company[assets_attributes][${index}][asset_file]`, file);
          formData.append(`company[assets_attributes][${index}][file_name]`, file.name);
        });
      }

      await restApiService.formWithImage("company", formData, "patch");
      CreateNotification(
        t("components.common.attachmentSections.attached"),
        t("components.common.attachmentSections.fileAttached"),
        NotificationType.success,
      );
      getCompany();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAttachementConfirm = async (id) => {
    const patchData = { assets_attributes: { id: id, _destroy: 1 } };
    const response = await restApiService.patch("company", null, {
      company: patchData,
    });
    CreateNotification(
      t("components.common.attachmentSections.removed"),
      t("components.common.attachmentSections.fileRemoved"),
      NotificationType.info,
    );
    // update local company state
    setCompany(response.data);
  };

  const onDeleteCompanyAttachment = async (id) => {
    createConfirmModal({
      title: t("components.common.attachmentSections.confirmDeleteTitle"),
      body: t("components.common.attachmentSections.confirmDeleteBody", { id: id }),
      callBackData: id,
      saveCallBack: deleteAttachementConfirm,
    });
  };

  return (
    <Container fluid={true}>
      <Row className={settingsStyles.navBarMargin}>
        <SettingsTabs breadcrumb={<span className={settingsStyles.landingHeader}>Settings</span>} />
      </Row>
      <Row>
        <Col md="12">
          <ErrorBoundary>
            <Addresses
              addressableType={"Company"}
              addressableId={currentUser.company.id}
              allowEdit={hasUserPermission("readSettings") || hasUserPermission("readcompanyProfile")}
              allowDelete={hasUserPermission("readSettings") || hasUserPermission("readcompanyProfile")}
            />
          </ErrorBoundary>
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="mt-5">
          <ErrorBoundary>
            <AttachmentSection
              attachments={company?.assets}
              onAttachmentUpload={(files) => onUploadCompanyAttachment(files)}
              onAttachmentDelete={(id) => onDeleteCompanyAttachment(id)}
              allowDelete={hasUserPermission("editattachmentSettings") || hasUserPermission("companyDeleteAttachment")}
              allowAdd={hasUserPermission("editattachmentSettings") || hasUserPermission("companyAddAttachment")}
            />
          </ErrorBoundary>
        </Col>
        <Col sm="6" className="mt-5">
          <ErrorBoundary>
            <ImportnatDates />
          </ErrorBoundary>
        </Col>
      </Row>
      <Row>
        <Col sm="8">
          <ErrorBoundary>
            <Can I="edit" a="Settings">
              <SamlConfigurationForm onSubmit={(formValues) => dispatch(onSubmitConfigurationForm(formValues))} />
            </Can>
          </ErrorBoundary>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} className="mt-5">
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default Landing;
