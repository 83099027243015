import { AppDispatch, RootState } from "reducers";
import { change, getFormValues } from "redux-form";
import { getMatchedNumber } from "services/general/helpers";
import {
  CardPaymentsType,
  CardPayMyAccountStepsType,
  PaymentStatementAccountType,
  StatementAccountType,
} from "./payments/cardPayments.types";

export class CardPaymentsSvc {
  static FORM_NAME = "CardPaymentsForm";

  static parseNumberToString = (value: string | number, decimals = 2) => {
    const numbersOnlyString = getMatchedNumber(value); // will strip negatives
    return Number(numbersOnlyString).toFixed(decimals);
  };

  static getCurrentPaymentType = (state: RootState): CardPaymentsType | undefined => {
    const values = getFormValues(this.FORM_NAME)(state) as { [key: string]: any };
    return values?.payMyAccount?.form?.payment_type;
  };

  static getCurrentStep = (state: RootState): CardPayMyAccountStepsType | undefined => {
    const values = getFormValues(this.FORM_NAME)(state) as { [key: string]: any };
    return values?.payMyAccount?.step;
  };

  static getCurrentPaymentAmount = (state: RootState): number => {
    const values = getFormValues(this.FORM_NAME)(state) as { [key: string]: any };
    return Number(this.parseNumberToString(values?.payMyAccount?.form?.amount)) ?? 0;
  };

  static getCurrentPaymentSource = (state: RootState): string => {
    const paymentOptions = this.getCurrentPaymentAccountOptions(state);
    const values = getFormValues(this.FORM_NAME)(state) as { [key: string]: any };
    return paymentOptions.find((option) => option.value === values?.payMyAccount?.form?.payment_source)?.label ?? "";
  };

  static getCurrentPaymentDate = (state: RootState): Date | undefined => {
    const values = getFormValues(this.FORM_NAME)(state) as { [key: string]: any };
    return values?.payMyAccount?.form?.payment_date;
  };

  static getCurrentPaymentAccountOptions = (state: RootState): { label: string; value: string }[] => {
    const values = getFormValues(this.FORM_NAME)(state) as { [key: string]: any };
    const paymentAccounts = values?.payMyAccount?.paymentAccounts as PaymentStatementAccountType[];
    const options =
      paymentAccounts?.map((paymentAccount) => ({
        label: `${paymentAccount.institution_name} - ${paymentAccount.account_name}`,
        value: JSON.stringify(paymentAccount),
      })) ?? [];
    return options;
  };

  static getCurrentStatement = (state: RootState): StatementAccountType => {
    const values = getFormValues(this.FORM_NAME)(state) as { [key: string]: any };
    return (
      values?.payMyAccount?.statement || {
        account_code: "",
        statement_balance: undefined,
        credit_limit: undefined,
        due_date: "",
        current_balance: undefined,
        currency_code: "",
      }
    );
  };

  static getCreatedCardProgramTransaction = (state: RootState): any => {
    const values = getFormValues(this.FORM_NAME)(state) as { [key: string]: any };
    return values?.payMyAccount?.createdCardProgramTransaction;
  };

  static setNextStep = (dispatch: AppDispatch, value: CardPayMyAccountStepsType): void => {
    dispatch(change(this.FORM_NAME, "payMyAccount.step", value));
    dispatch(change(this.FORM_NAME, "payMyAccount.form.payment_date", new Date()));
  };

  static setCreatedCardProgramTransaction = (dispatch: AppDispatch, value: any): void => {
    dispatch(change(this.FORM_NAME, "payMyAccount.createdCardProgramTransaction", value));
  };

  static setCurrentPaymentAccounts = (dispatch: AppDispatch, value: PaymentStatementAccountType[]): void => {
    dispatch(change(this.FORM_NAME, "payMyAccount.paymentAccounts", value));
  };

  static setCurrentPaymentType = (dispatch: AppDispatch, value: CardPaymentsType): void => {
    dispatch(change(this.FORM_NAME, "payMyAccount.form.payment_type", value));
  };

  static setCurrentAmountToPay = (dispatch: AppDispatch, value: string): void => {
    dispatch(change(this.FORM_NAME, "payMyAccount.form.amount", value));
  };
}
