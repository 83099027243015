import React, { useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import PaymentCountryPicker from "../../../../pickers/paymentCountryPicker";
import GenericSelectPicker from "../../../pickers/reduxPicker/genericSelectPicker/genericSelectPicker";

type CambridgeRuleInputType = {
  rule: any;
  id: any;
  callback: (rule: any, event: any, value?: any) => void;
};

const CambridgeRuleInput = ({ rule, id, callback }: CambridgeRuleInputType) => {
  const getOptions = () => {
    if (rule && rule.valueSet) {
      return rule.valueSet.map((value: { id: any; text: any }) => {
        return { id: value.id, label: value.text };
      });
    }
  };

  const checkIfCountry = (rule: { id: string }) => {
    if (!rule) {
      return;
    }
    if (rule.id === "accountHolderCountry" || rule.id === "bankCountry" || rule.id === "destinationCountry") {
      return true;
    } else {
      return false;
    }
  };

  const checkIfDisabled = (rule: { id: string }) => {
    if (!rule) {
      return;
    }
    if (rule.id === "preferredMethod" || rule.id === "paymentMethods") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // componentDidMount logic goes here
  }, []);

  let isCountry = checkIfCountry(rule);

  //Notice we have 3 seperate render conditions.
  //1. render country picker if it matches checkIfCountry
  //2. render select dropdown
  //3. render normal text input
  return (
    <>
      {isCountry ? (
        <PaymentCountryPicker
          code2={true}
          colSize="3"
          countryPickerCallback={(value: { value: any }) => {
            callback(rule, null, value.value);
          }}
          label={rule.label || rule.id}
          selected={{ value: id }}
        />
      ) : (
        <>
          {rule.valueSet && rule.valueSet.length > 0 ? (
            <Form.Group as={Col} md="3">
              <GenericSelectPicker
                disabled={checkIfDisabled(rule)}
                callback={(value: { value: any }) => {
                  callback(rule, null, value.value);
                }}
                id={id}
                required={rule.isRequired === "true"}
                label={rule.label || rule.id}
                options={getOptions()}
              />
            </Form.Group>
          ) : (
            <Col md={3}>
              <Form.Group>
                <Form.Label style={{ textTransform: "capitalize" }}>
                  {rule.label || rule.id}: {rule.isRequired === "true" && <span className={"color-red"}>*</span>}
                </Form.Label>
                <Form.Control
                  disabled={checkIfDisabled(rule)}
                  required={rule.isRequired === "true"}
                  name={rule.id}
                  value={id}
                  onChange={(event) => {
                    callback(rule, event);
                  }}
                  type="text"
                  placeholder={rule.defaultValue}
                  pattern={rule.regEx && rule.regEx.length > 0 ? rule.regEx : null}
                />
                <Form.Control.Feedback type="invalid">{rule.errorMessage}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          )}
        </>
      )}
    </>
  );
};

export default CambridgeRuleInput;
