import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Styles from "../override.module.css";
import { findSelectedMultiple, onBlurSingle, onChangeSingle, parseForSelect } from "services/general/helpers";
import Select from "react-select";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { restApiService } from "providers/restApi";
import useIsMounted from "components/common/hooks/useIsMounted";
import { PolicyPickerProps } from "./index";
import { PolicyTypes } from "services/admin/policy/policyTypes";

const MultiplePicker = (props: PolicyPickerProps) => {
  let {
    className,
    label,
    input,
    meta: { touched, error, warning },
    disabled,
  } = props;

  const mounted = useIsMounted();
  const [policies, setPolicies] = useState<PolicyTypes.PolicyLKList>([]);

  const getPolicies = useCallback(async () => {
    const result = await restApiService.get(
      "policies.lk",
      {
        status: "ACTIVE",
      },
      null,
      true,
      null,
      true,
    );
    const value = parseForSelect(result.data);
    if (mounted.current) setPolicies(value);
  }, [mounted]);

  useEffect(() => {
    getPolicies();
  }, []);

  return (
    <Form.Group className={Styles.select}>
      {label && <Form.Label>{label}</Form.Label>}
      <Select
        {...input}
        required
        value={findSelectedMultiple(input, policies)}
        placeholder="search/select"
        onChange={(value) => onChangeSingle(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={policies}
        isDisabled={disabled}
        isMulti
        isClearable
        classNamePrefix="select"
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default MultiplePicker;
