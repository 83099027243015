import React from "react";
import { Col, Row } from "react-bootstrap";
import CheckNumberSeq from "../../components/fields/checkPaymentFields/checkNumberSeq";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import IntegrationEdi from "../../components/integrationComponents/integrationEdi";
import IntegrationSftp from "../../components/integrationComponents/integrationSftp";
import MessageOnCheck from "../../components/integrationComponents/messageOnCheck";

const PostGridCHECK_PAYMENT = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <Col lg="12">Check Payment Info</Col>
      </Row>
      <Row className="m-0">
        <CheckNumberSeq />
      </Row>
      <Row className="m-0">
        <IntegrationSftp />
      </Row>
      <Row className="m-0">
        <MessageOnCheck />
      </Row>
      <Row className="m-0">
        <IntegrationEdi />
      </Row>
    </Col>
  );
};

export { PostGridCHECK_PAYMENT };
