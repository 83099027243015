import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  draftInvoiceSettingsHeaderLevel,
  draftInvoiceSettingsItemLine,
  draftInvoiceSettingsExpenseLine,
  draftInvoiceSettingsRecommendations,
} from "./draftInvoiceSettings";
import { shallowEqual, useDispatch } from "react-redux";
import companySettingStyle from "../../companySettingTab.module.css";
import CommonSettingField from "../../components/commonSettingField";
import { useTypedSelector } from "../../../../../../reducers";
import { getFormValues } from "redux-form";
import { useState } from "react";
import { cloneDeep } from "lodash";
import _ from "lodash";

type InvoiceDraftRecommendationsType =
  | {
      vendor_default?: string;
      item_default?: string;
      expense_default?: string;
      [key: string]: string | undefined;
    }
  | undefined;

const PRIORITY_OPTIONS = [
  { label: "Select", value: "0" },
  { label: "Highest", value: "1" },
  { label: "Middle", value: "2" },
  { label: "Lowest", value: "3" },
];

const DraftInvoiceSetting = () => {
  const formName = "companyDefaultForm";
  const dispatch = useDispatch();

  const [currentOptions, setCurrentOptions] = useState<{ value: any; label: string }[]>(PRIORITY_OPTIONS);
  const isShowObj: any = {};
  const company_default = useTypedSelector(
    (state) => getFormValues(formName)(state) as any,
    shallowEqual,
  ).company_default;
  const company = useTypedSelector((state) => getFormValues(formName)(state) as any, shallowEqual).company;
  const current_user = useTypedSelector((state) => state.user, shallowEqual);
  isShowObj.company_default = company_default;
  isShowObj.company = company;
  isShowObj.current_user = current_user;

  const onChangeObj: any = {};
  onChangeObj.formName = formName;
  onChangeObj.dispatch = dispatch;
  onChangeObj.company_default = company_default;
  onChangeObj.company = company;

  const chosenRecommendations: InvoiceDraftRecommendationsType = company_default?.draft_invoice?.recommendations;

  function updateFilteredOptions(chosenRecommendations: InvoiceDraftRecommendationsType) {
    let newOpts = _.cloneDeep(PRIORITY_OPTIONS);
    const chosenValues = chosenRecommendations ? Object.values(chosenRecommendations) : [];
    newOpts = newOpts.map((option) => {
      const { value, label } = option;
      if (chosenValues.includes(value) && value != "0") {
        return { value, label, disabled: true };
      } else {
        return { value, label };
      }
    });
    setCurrentOptions(newOpts);
  }

  const chosenRecommendationsComparatorFn = (chosenRecommendations: InvoiceDraftRecommendationsType) => {
    if (!chosenRecommendations) {
      return [undefined, undefined, undefined];
    } else {
      const baseRecommendations: InvoiceDraftRecommendationsType = {
        vendor_default: undefined,
        expense_default: undefined,
        item_default: undefined,
      };
      return Object.keys(baseRecommendations).map((key: string, index: number) => chosenRecommendations[key]);
    }
  };

  useEffect(() => {
    if (chosenRecommendations) {
      updateFilteredOptions(chosenRecommendations);
    }
  }, chosenRecommendationsComparatorFn(chosenRecommendations));

  return (
    <>
      <Col md="12" className="px-4">
        <Row>
          <Col>
            <div className={`${companySettingStyle.settingsTitle}`}>Header Level</div>
            <hr className="mt-0" />
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {draftInvoiceSettingsHeaderLevel
                  .filter((setting) => setting.col === 1)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 2 */}
                {draftInvoiceSettingsHeaderLevel
                  .filter((setting) => setting.col === 2)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 3 */}
                {draftInvoiceSettingsHeaderLevel
                  .filter((setting) => setting.col === 3)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={`${companySettingStyle.settingsTitle}`}>Item Line</div>
            <hr className="mt-0" />
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {draftInvoiceSettingsItemLine
                  .filter((setting) => setting.col === 1)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 2 */}
                {draftInvoiceSettingsItemLine
                  .filter((setting) => setting.col === 2)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 3 */}
                {draftInvoiceSettingsItemLine
                  .filter((setting) => setting.col === 3)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={`${companySettingStyle.settingsTitle}`}>
              Expense Line
              <hr className="mt-0" />
            </div>
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {draftInvoiceSettingsExpenseLine
                  .filter((setting) => setting.col === 1)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 2 */}
                {draftInvoiceSettingsExpenseLine
                  .filter((setting) => setting.col === 2)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 3 */}
                {draftInvoiceSettingsExpenseLine
                  .filter((setting) => setting.col === 3)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={`${companySettingStyle.settingsTitle}`}>Recommendations</div>
            <hr className="mt-0" />
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {draftInvoiceSettingsRecommendations(currentOptions)
                  .filter((setting) => setting.col === 1)
                  .map((setting, index) => (
                    <CommonSettingField
                      key={"rec" + index}
                      setting={setting}
                      isShowObj={isShowObj}
                      onChangeObj={onChangeObj}
                    />
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default DraftInvoiceSetting;
