import React from "react";
import { IUser } from "services/common/user/userTypes";
import usePermission from "components/common/hooks/usePermission";
import { useTypedSelector } from "reducers";
import { Col, Row } from "react-bootstrap";
import TabNavigation from "components/navigation/tabNavigation";
import adminCommonSvc from "services/admin/commonSvc";

const PaymentsTabs = () => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const currentUserCompany = currentUser?.company;
  const { hasUserPermission } = usePermission();
  const hasPaymentsLinkedToInvoicesPermission =
    hasUserPermission("resetworkflowPayments") || hasUserPermission("paymentLinksToInvoices");
  const hasNachaPaymentsPermission =
    currentUserCompany?.has_nacha_integration &&
    (hasUserPermission("listPayments") || hasUserPermission("listNachaPayments"));
  const hasPaymentTransferPermission =
    hasUserPermission("resetworkflowPayments") || hasUserPermission("listEDIPayments");
  const hasPaymentRunPermission =
    currentUserCompany?.payment_run_required &&
    (hasUserPermission("listPayments") || hasUserPermission("listPaymentRun"));
  const hasPrePaymentsPermission = hasUserPermission("listPayments") || hasUserPermission("listPrePayments");
  const hasStatementPermission = adminCommonSvc.isNvpCustomer(currentUser) && hasPrePaymentsPermission;
  const showPaymentDashboard = !!currentUser?.company?.accepted_payment_methods?.corpay;

  const paymentsTabsRoutes = [
    {
      isActive: "",
      pageName: "Dashboard",
      path: "/ap/payments/dashboard",
      hide: !showPaymentDashboard,
      authorization: { permissions: ["listPayments"] },
    },
    { isActive: "", pageName: "Payments", href: "payments" },
    {
      isActive: "",
      pageName: "Payments Linked to Invoices",
      hide: !hasPaymentsLinkedToInvoicesPermission,
      href: "/invoice_payment_links",
    },
    { isActive: "", pageName: "NACHA Payments", hide: !hasNachaPaymentsPermission, href: "nacha_payments" },
    { isActive: "", pageName: "EFT/EDI Payments Transfer", hide: !hasPaymentTransferPermission, href: "edi_payments" },
    { isActive: "", pageName: "Payment Runs", hide: !hasPaymentRunPermission, href: "payment_runs" },
    { isActive: "", pageName: "PrePayments", hide: !hasPrePaymentsPermission, path: "/ap/payments/pre_payments" },
    { isActive: "", pageName: "Statements", hide: !hasStatementPermission, href: "statements?statementType=payment" },
  ];

  return (
    <Row>
      <Col>
        <TabNavigation navigationTab={paymentsTabsRoutes} />
      </Col>
    </Row>
  );
};

export default PaymentsTabs;
