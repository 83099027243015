import React from "react";
import AccountsModal from "./accountsModal";
import { useSelector } from "react-redux";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";

export default function ManageDefaultAccounts(props) {
  const componentMountMode = useSelector((state) => state.manageAccountFormRed.componentMountMode);

  return (
    <>
      <ErrorBoundary>
        {componentMountMode && (
          <AccountsModal
            modelData={props.modelData}
            modelName={props.modelName}
            saveEditCallback={props.saveEditCallback}
            hide={props.hide}
          />
        )}
      </ErrorBoundary>
    </>
  );
}
