import EditExpenses from "./editExpenses";
import EditMain from "./editMain";
import EditPOItems from "./editPOItems";
const ChangeOrderRoutes = [
  {
    path: "/ap/change_orders/:id/edit",
    exact: true,
    name: "Change Orders Edit",
    component: EditMain,
    authorization: {
      I: "list",
      a: "ChangeOrders",
    },
  },
  {
    path: "/ap/change_orders/:id/edit/po_items",
    exact: true,
    name: "Change Orders Edit PO Items",
    component: EditPOItems,
    authorization: {
      I: "list",
      a: "ChangeOrders",
    },
  },
  {
    path: "/ap/change_orders/:id/edit/expenses",
    exact: true,
    name: "Change Orders Edit Expenses",
    component: EditExpenses,
    authorization: {
      I: "list",
      a: "ChangeOrders",
    },
  },
];

export default ChangeOrderRoutes;
