import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Spinner } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import FileUploader from "../../../components/common/fileUploader/fileUploader";
import { renderDatePicker, renderField, renderTextareaField } from "../../../components/forms/fields";
import { BreadcrumbTrail } from "../../../components/navigation/navigationLinks";
import Panel from "../../../components/panel/panel";
import CustomerPicker from "../../../components/vp/pickers/reduxFormPicker/customerPicker";
import PurchaseOrderPicker from "../../../components/vp/pickers/reduxFormPicker/purchaseOrderPicker";
import styles from "./accrual.module.css";
import { useTranslation } from "react-i18next";
import RestApi from "../../../providers/restApi";
const restApiService = new RestApi();

const formName = "AccrualForm";

let AccrualForm = (props) => {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const [attachments, setAttachments] = useState([]);
  const { t } = useTranslation();
  const uploadAttachments = (files) => {
    const parsedFiles = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    setAttachments(parsedFiles);
    dispatch(change(formName, "attachments", parsedFiles));
  };

  const addFromAccrualRequest = () => {
    if (props.location?.state?.accrualRequest?.id) {
      const accrualRequestId = props.location.state.accrualRequest.id;
      let purchaserID = props.location.state.accrualRequest?.purchaser?.id
        ? props.location.state.accrualRequest.purchaser.id
        : null;
      dispatch(change(formName, "accrual_request_id", accrualRequestId));
      dispatch(change(formName, "purchaser_id", purchaserID));
    }
  };

  const getPoCurrency = useCallback(async (selected) => {
    if (selected && selected.value) {
      const result = await restApiService.get("vendor_purchase_orders/" + selected.value);
      if (result?.data) {
        dispatch(change(formName, "currency_code", result.data.currency_code));
      }
    }
  }, []);

  useEffect(() => {
    addFromAccrualRequest();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <div className={styles.pageHeaderSection}>
        <Container fluid={true} className={"pt-4 pb-4 "}>
          <BreadcrumbTrail to={"/ar/accruals"} pageName={t("breadcrumbs.accruals")} />
          <Row>
            <Col sm="6">
              <h1>{t("forms.newAccrual")}</h1>
            </Col>
            <Col sm="6 text-right">
              <Button
                variant=" btn-secondary"
                disabled={props.isDisableFormBtn}
                onClick={() => props.history.push("/ar/accrual_requests")}
              >
                {t("cancel")}
              </Button>
              <Button variant="btn btn-primary ml-1" type="submit" disabled={props.isDisableFormBtn}>
                {props.isDisableFormBtn && (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                )}{" "}
                {t("submit")}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid={true} className={"mt-5"}>
        <Row>
          <Col sm="4">
            <Field
              name="purchaser_id"
              label={t("details.customer")}
              component={CustomerPicker}
              callback={() => dispatch(change(formName, "purchase_order_id"))}
              isRequired={true}
            />
          </Col>
          <Col sm="4">
            <Field
              name="purchase_order_id"
              label={t("details.po")}
              component={PurchaseOrderPicker}
              callback={(po) => getPoCurrency(po)}
              purchaserId={props.accrual && props.accrual.purchaser_id}
            />
          </Col>
          <Col sm="4">
            <Field name="date" label={t("details.accrualDate")} component={renderDatePicker} type="date" />
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <Field name="amount" type="number" label={t("details.amount")} component={renderField} isRequired={true} />
          </Col>
          <Col sm="4">
            <Field
              name="service_start_date"
              label={t("details.serviceStartDate")}
              component={renderDatePicker}
              type="date"
              isRequired={props.accrual.service_end_date}
            />
          </Col>
          <Col sm="4">
            <Field
              name="service_end_date"
              label={t("details.serviceEndDate")}
              component={renderDatePicker}
              type="date"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm="12">
            <Field
              name="description"
              label={t("details.description")}
              style={{ height: "150px" }}
              component={renderTextareaField}
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mt-4">
            <div className="attachmentPanel">
              <Panel
                panelContent={{
                  heading: t("details.documents"),
                  iconClass: "icon icon-documents",
                }}
                cardBodyStyle={{ padding: "0px" }}
                headerComponent={<FileUploader showUploadBtn="true" uploadAttachments={uploadAttachments} />}
                cardHeaderClass={"flex-row-reverse"}
              >
                <div className="mx-4 my-4">
                  {_.isArray(attachments) &&
                    attachments.length > 0 &&
                    attachments.map((attachment, index) => (
                      <span key={index} className={styles.fileName}>
                        {attachment.name}
                      </span>
                    ))}
                </div>
              </Panel>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

AccrualForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AccrualForm);

AccrualForm = connect((state) => ({}))(AccrualForm);
export default AccrualForm;
