import useFormValue from "components/admin/hooks/useFormValue";
import TabNavigator from "components/common/tabNavigator/tabNavigator";
import CustomModal from "components/modals/customModal";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import { change, InjectedFormProps, reduxForm, SubmitHandler } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { ExpenseConstants } from "services/admin/expenses/expenseSvc";
import commonService from "services/common/commonSvc";
import { formattedAmount } from "services/general/helpers";
import ManageExpenseDebitAccountsSplits from "./manageExpenseDebitAccountsSplits";
import style from "./splitExpenseCoding.module.css";

const SplitExpenseDebitAccountsDashboard = ({
  expenseReportDebitAccountsAttributes,
}: {
  expenseReportDebitAccountsAttributes: ExpensesTypes.ExpenseDebitAccounts | undefined;
}) => {
  const currentUser = useTypedSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const validExpenseDebitAccountsAttributes =
    expenseReportDebitAccountsAttributes &&
    expenseItemCommonSvc.getValidAttributes(expenseReportDebitAccountsAttributes);
  const isCurrencyConversionActive = useFormValue<boolean>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    "currency_conversion_active",
  );
  const total = useFormValue<string | number>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "total");
  const baseCurrencyCode = useFormValue<string>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "base_currency_code");
  const baseCurrencyTotal = useFormValue<number | string>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    "base_currency_total",
  );
  const currencyCode = useFormValue<string>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "currency_code");
  const applicableCurrencyCode = isCurrencyConversionActive ? baseCurrencyCode : currencyCode;
  const applicableTotal = isCurrencyConversionActive ? baseCurrencyTotal : total;

  const numberOfSplits =
    (Array.isArray(validExpenseDebitAccountsAttributes) && validExpenseDebitAccountsAttributes.length) ?? 0;

  const totalAllocatedAmount = Array.isArray(validExpenseDebitAccountsAttributes)
    ? validExpenseDebitAccountsAttributes.reduce((a, c) => Number(a) + Number(c?.amount ?? 0), 0)
    : 0;

  const totalAllocatedBaseCurrencyAmount = Array.isArray(validExpenseDebitAccountsAttributes)
    ? validExpenseDebitAccountsAttributes.reduce((a, c) => Number(a) + Number(c?.base_currency_amount ?? 0), 0)
    : 0;

  const baseCurrencyRemainingAmount = Number(baseCurrencyTotal) - Number(totalAllocatedBaseCurrencyAmount);
  const targetCurrencyRemainingAmount = Number(total) - Number(totalAllocatedAmount);

  let remainingAmount = isCurrencyConversionActive ? baseCurrencyRemainingAmount : targetCurrencyRemainingAmount;
  remainingAmount = adminCommonSvc.roundUpAmount(Number(remainingAmount), null, currentUser);

  let remainingAmountPercentage = remainingAmount ? (Number(remainingAmount) / Number(applicableTotal)) * 100 : 0;
  remainingAmountPercentage = commonService.roundOfPercentage({ percentage: remainingAmountPercentage });

  const updateRemainingAmountAndPercentageOnForm = () => {
    dispatch(change(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "remaining_amount", remainingAmount));
    dispatch(change(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "remaining_percentage", remainingAmountPercentage));
    dispatch(
      change(
        ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
        "target_currency_remaining_amount",
        targetCurrencyRemainingAmount,
      ),
    );
  };

  useEffect(() => {
    updateRemainingAmountAndPercentageOnForm();
  }, [remainingAmount, remainingAmountPercentage]);

  return (
    <Row className={style.expenseHeader}>
      <Col lg="4" className={style.frame}>
        <Row>
          <Col className={style.text}>Expense Amount</Col>
        </Row>
        {isCurrencyConversionActive ? (
          <>
            <Row>
              <Col className={style.formattedAmount} md="4">
                {" "}
                Base Total:
              </Col>
              <Col className={style.formattedAmount}>
                {formattedAmount(String(baseCurrencyTotal), baseCurrencyCode, 2, true)}
              </Col>
            </Row>
            <Row>
              <Col className={style.formattedAmount} md="4">
                {" "}
                Total:
              </Col>
              <Col className={style.formattedAmount + " text-left"}>
                {formattedAmount(String(total), currencyCode, 2, true)}
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col className={style.formattedAmount}>{formattedAmount(String(total), currencyCode, 2, true)}</Col>
          </Row>
        )}
      </Col>
      <Col lg="4" className={style.frame}>
        <Row>
          <Col className={style.text}>Number of Splits</Col>
        </Row>
        <Row>
          <Col className={style.formattedAmount}>{numberOfSplits}</Col>
        </Row>
      </Col>
      <Col lg="4">
        <Row>
          <Col className={style.text}>Remaining Amount</Col>
        </Row>
        <Row>
          <Col className={style.formattedAmount}>
            {formattedAmount(String(remainingAmount), applicableCurrencyCode, 2, true)} (
            {remainingAmountPercentage.toFixed(2)})%
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

type SplitExpenseDebitAccountsModalBodyProps = {
  handleSubmit: SubmitHandler<ExpensesTypes.SplitExpenseCodingFormData, SplitExpenseCodingExtraPropsType, string>;
  cancelCallback: () => void;
};

const SplitExpenseDebitAccountsModalBody = ({
  cancelCallback,
  handleSubmit,
}: SplitExpenseDebitAccountsModalBodyProps) => {
  const [activeTab, setActiveTab] = useState("AddAllocation");
  const changeTab = (tabName: string) => setActiveTab(tabName);
  const expenseReportDebitAccountsAttributes = useFormValue<ExpensesTypes.ExpenseDebitAccounts>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    "expense_report_debit_accounts_attributes",
  );

  const tabData = [
    {
      activeTab: "AddAllocation",
      label: "Add Allocation",
    },
    // {
    //   activeTab: "FavAllocation",
    //   label: "Favorate Allocation"
    // }
  ];

  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(e);
        }}
      >
        <Row>
          <Col lg="12">
            <SplitExpenseDebitAccountsDashboard
              expenseReportDebitAccountsAttributes={expenseReportDebitAccountsAttributes}
            />
            <Row className={style.marginTop24}>
              <Col lg="12">
                <TabNavigator
                  activeTab={activeTab}
                  handleTabChange={changeTab}
                  tabData={tabData}
                  template="BudgeTabTemplate"
                />
              </Col>
            </Row>
            <hr className={style.separatorBetween + " mt-0"} />
            <Row className={style.marginTop24}>
              <Col lg="12">
                <ManageExpenseDebitAccountsSplits />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="px-mt-24">
          <Col>
            <SplitExpenseDebitAccountsModalFooter
              expenseReportDebitAccountsAttributes={expenseReportDebitAccountsAttributes}
              onHide={cancelCallback}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

const SplitExpenseDebitAccountsModalFooter = ({
  // total,
  onHide,
  expenseReportDebitAccountsAttributes,
}: {
  onHide: () => void;
  // total: number | string | null;
  expenseReportDebitAccountsAttributes: ExpensesTypes.ExpenseDebitAccounts | undefined;
}) => {
  const currentUser = useTypedSelector(selectCurrentUser);
  const isCurrencyConversionActive = useFormValue<boolean>(
    ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
    "currency_conversion_active",
  );
  const total = useFormValue<boolean>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "total");
  const baseCurrencyTotal = useFormValue<boolean>(ExpenseConstants.SPLIT_EXPENSE_CODING_FORM, "base_currency_total");

  let sumOfAmount =
    expenseReportDebitAccountsAttributes && expenseItemCommonSvc.getSumOfAmount(expenseReportDebitAccountsAttributes);
  sumOfAmount = adminCommonSvc.roundUpAmount(Number(sumOfAmount), null, currentUser);

  let sumOfBaseCurrencyAmount =
    expenseReportDebitAccountsAttributes &&
    expenseItemCommonSvc.getSumOfBaseCurrencyAmount(expenseReportDebitAccountsAttributes);
  sumOfBaseCurrencyAmount = adminCommonSvc.roundUpAmount(Number(sumOfBaseCurrencyAmount), null, currentUser);

  const isBaseCurrencyAmountTotalMatched = Number(sumOfBaseCurrencyAmount) === Number(baseCurrencyTotal);
  const isAmountTotalMatched = Number(sumOfAmount) === Number(total);
  const isSumsEqual = isCurrencyConversionActive ? isBaseCurrencyAmountTotalMatched : isAmountTotalMatched;
  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <>
      <Row>
        <Col className={style.errorMessage}>
          {!isSumsEqual && submitClicked && (
            <div>
              Expense splits do not equal 100%. Please ensure your Percentage and Amount allocations are correct to save
              your selections.
            </div>
          )}
        </Col>
      </Row>
      <Row className={"splitCodingFooter"}>
        <Col lg="12" className="d-flex justify-content-end">
          <Button variant="secondary" onClick={onHide} className={style.mr16}>
            Cancel
          </Button>

          {isSumsEqual && (
            <Button variant="primary" disabled={!isSumsEqual} type="submit">
              Save
            </Button>
          )}

          {!isSumsEqual && (
            <OverlayTrigger
              placement={"top"}
              onEntering={(e: any) => commonService.entering(e)}
              overlay={
                <Tooltip className={style.expenseBarToolTip} id="button-tooltip-3">
                  Splits must equal to the expense amount.
                </Tooltip>
              }
            >
              <Button
                variant="primary"
                type="button"
                className={`${!isSumsEqual ? `disabled` : ""}`}
                aria-describedby="button-tooltip-3"
                onClick={() => setSubmitClicked(true)}
              >
                Save
              </Button>
            </OverlayTrigger>
          )}
        </Col>
      </Row>
    </>
  );
};

// this expects parent component to provide the expenseReportDebitAccountsAttributes
// and will return updatedExpenseDebitAccountsAttributes with savecallback
// will close modal with cancel callback

// also will expect subsidiary_id and policy_id as this are required for filtering option of other pickers
// total:
// currency_code:

type SplitExpenseCodingExtraPropsType = {
  // total?: number | string | null;
  // currencyCode?: string;
  showSplitExpense: boolean;
  cancelCallback: () => void;
};

type SplitExpenseCodingFormPropsType = InjectedFormProps<
  ExpensesTypes.SplitExpenseCodingFormData,
  SplitExpenseCodingExtraPropsType
> &
  SplitExpenseCodingExtraPropsType;

const SplitExpenseCodingComponent = ({
  cancelCallback,
  showSplitExpense,
  handleSubmit,
}: SplitExpenseCodingFormPropsType) => {
  return (
    <CustomModal
      size="xl"
      show={showSplitExpense}
      onHide={cancelCallback}
      header={
        <>
          <div className={style.modalTitle}>Split Expense</div>
        </>
      }
      body={<SplitExpenseDebitAccountsModalBody handleSubmit={handleSubmit} cancelCallback={cancelCallback} />}
      dialogClassName={style.modalDialog}
      modalBodyClass={style.modalBody}
      contentClassName={style.splitModalContent}
    />
  );
};

// export default SplitExpenseCoding;
const SplitExpenseCoding = reduxForm<ExpensesTypes.SplitExpenseCodingFormData, SplitExpenseCodingExtraPropsType>({
  form: ExpenseConstants.SPLIT_EXPENSE_CODING_FORM,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(SplitExpenseCodingComponent);

export default SplitExpenseCoding;
