import React from "react";
import { CurrencyCodeOptionType, CurrencyCodePickerPropsType } from ".";
import { Form } from "react-bootstrap";
import { Mandatory } from "components/forms/bootstrapFields";
import Select, { SingleValue } from "react-select";
import _ from "lodash";
import { CommonTypes } from "services/common/types/commonTypes";
import adminCommonSvc from "services/admin/commonSvc";
import useAdminCompanyCurrencyCode from "components/admin/hooks/useAdminCompanyCurrencyCode";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { FieldRenderProps } from "react-final-form";

type SingleCurrencyCodePickerPropsType = CurrencyCodePickerPropsType &
  FieldRenderProps<any, HTMLElement, any> & {
    currencyCodeOptions: CurrencyCodeOptionType[];
  };

const SingleCurrencyCodePicker = ({
  input,
  meta: { touched, error },
  label,
  className,
  callBack,
  currencyCodeOptions = [],
  tooltip,
  disabled,
  required,
  instanceId = "currency-selector",
  placeHolder,
  defaultValue,
  showCurrencyWithSymbol,
  notClearAble,
}: SingleCurrencyCodePickerPropsType) => {
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);

  const showCurrenciesWithSymbol = (currencies: any) => {
    return currencies.map((currency: any) => {
      return {
        ...currency,
        label: `${currency.label} ${currency.symbol}`,
      };
    });
  };
  currencyCodeOptions = showCurrencyWithSymbol ? showCurrenciesWithSymbol(currencyCodeOptions) : currencyCodeOptions;

  const findSelectedSingle = () => {
    let selectedValues = null;
    const obj = _.find(currencyCodeOptions, (option) => option.value === input.value);
    if (obj) selectedValues = obj;
    return selectedValues ? selectedValues : null;
  };

  const onBlurSingle = (value: React.FocusEvent<HTMLElement>) => {
    if (!value) {
      input.onBlur();
      return;
    }
    input.onBlur(value);
  };

  const onChangeSingle = (currencyObj: SingleValue<CurrencyCodeOptionType>) => {
    if (!currencyObj) {
      input.onChange(null);
      return;
    }
    input.onChange(currencyObj.value);
    if (callBack) {
      callBack({ ...currencyObj, iso_code: currencyObj.value });
    }
  };

  if (defaultValue) {
    currencyCodeOptions = currencyCodeOptions.filter((option) => option.value === defaultValue);
    if (currencyCodeOptions[0]) {
      input.onChange(currencyCodeOptions[0].value);
    }
  }

  return (
    <Form.Group>
      {label && (
        <>
          <Form.Label>{label}</Form.Label>
          <Mandatory required={required} />
        </>
      )}
      {tooltip}
      <Select
        {...input}
        required={required}
        instanceId={instanceId}
        isDisabled={disabled}
        value={findSelectedSingle()}
        placeholder={placeHolder ? placeHolder : "Search/Select"}
        onChange={(value) => onChangeSingle(value)}
        onBlur={() => onBlurSingle(input.value)}
        options={
          !required ? [{ value: "", label: "-- Select Currency --" }, ...currencyCodeOptions] : currencyCodeOptions
        }
        isMulti={false}
        isClearable={notClearAble ? false : true}
        classNamePrefix="select"
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default SingleCurrencyCodePicker;
