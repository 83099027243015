// This component is employed to rigorously validate an account number by cross-referencing it
// with both the associated transit number and institution number to ensure their correctness and compatibility.

import React, { useState } from "react";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import { minLength, required } from "../../../../services/validations/reduxFormValidation";
import { PaymentMethodValidatorSvc } from "../paymentMethodValidatorSvc";
import { IDType } from "../../../../services/common/types/common.type";

const min1 = minLength(1);

type AccountNumberValidatorPropTypes = {
  modelName?: IDType;
  accountNumberType?: string;
  transitNumber?: IDType;
  institutionNumber?: IDType;
  minLen?: number;
  maxLength?: number;
  numOnly?: boolean;
  noValidate?: boolean;
  label?: string;
  notRequired?: boolean;
};

export const AccountNumberValidator = ({
  modelName,
  accountNumberType,
  transitNumber,
  institutionNumber,
  minLen,
  maxLength,
  numOnly,
  noValidate,
  label,
  notRequired,
}: AccountNumberValidatorPropTypes) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState("");
  const [inputLength, setInputLength] = useState(0);
  const [istouch, setIsTouch] = useState<boolean>(false);

  const inputMinLength = minLen ? minLen : 1;
  const isfieldRequired = notRequired ? false : true;
  const validateAccountNumber = async (value: IDType) => {
    if (!noValidate) {
      const stringValue = String(value);
      setInputLength(stringValue.length);
      setIsTouch(stringValue.length < 1 ? false : true);

      if (stringValue.length >= inputMinLength && accountNumberType) {
        const baseParams = `?accountNumber=${value}`;
        const additionalParams =
          accountNumberType === "canadian-account-number"
            ? `&transitNumber=${transitNumber}&institutionNumber=${institutionNumber}`
            : "";
        const urlParams = baseParams + additionalParams;

        try {
          const data = await PaymentMethodValidatorSvc.ValidateAccountNumber(urlParams, accountNumberType);
          if (data?.validation) {
            setValidationMessage(data.validation);
          } else {
            setValidationMessage(data?.errors[0]?.message);
          }
        } catch (error) {
          throw error;
        }
      }
    }
  };

  return (
    <>
      <Field
        name={`${modelName}.account_number`}
        component={RenderField}
        type="text"
        maxLength={maxLength}
        label={label ? label : t("common.paymentMethod.bankAccount")}
        className={`w-100`}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => validateAccountNumber(e.target.value)}
        required={isfieldRequired}
        validate={isfieldRequired ? [required, min1] : [min1]}
      />
      {validationMessage && inputMinLength && inputMinLength <= inputLength && (
        <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
      )}
      {istouch && minLength && inputMinLength > inputLength && (
        <p className={`errorTxtColor`}>Please enter at least {minLength} characters</p>
      )}
    </>
  );
};
