import React from "react";
import { Col, Row } from "react-bootstrap";
import AccountName from "../../../../common/managePaymentMethod/fields/accountName";
import { minLength, maxLength, required } from "../../../../../services/validations/reduxFormValidation";
import PaymentType from "../../fields/paymentType";
import TaxAccountNumber from "../../../../common/managePaymentMethod/fields/taxAccountNumber";
import AccountNumber from "../../../../common/managePaymentMethod/fields/accountNumber";
import BankName from "../../../../common/managePaymentMethod/fields/bankName";
import CurrencyCode from "../../../../common/managePaymentMethod/fields/currencyCode";
import Address1 from "../../../../common/managePaymentMethod/fields/address1";
import City from "../../../../common/managePaymentMethod/fields/city";
import State from "../../../../common/managePaymentMethod/fields/state";
import ZipCode from "../../../../common/managePaymentMethod/fields/zipCode";
import Status from "../../../../common/managePaymentMethod/fields/status";
import Primary from "../../../../common/managePaymentMethod/fields/primary";
import _ from "lodash";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

const min6 = minLength(6);
const min8 = minLength(8);

const max6 = maxLength(6);
const max8 = maxLength(8);

const UkEftBankAccount = () => {
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);
  return (
    <>
      <Row>
        <Col md="4">
          <PaymentType label="Payment Type:" required validations={[required]} />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <AccountName label={"Account Holder:(Full Name/Corp Name)"} required validations={[required]} />
        </Col>
        <Col md="4">
          <TaxAccountNumber label={"Sort Code:"} required validations={[required, min6, max6]} />
        </Col>
        <Col md="4">
          <AccountNumber
            label="Account Number:(account number or iban)"
            required
            validations={[required, min8, max8]}
          />
        </Col>

        <Col md="4">
          <BankName label="Bank Name:" required validations={[required]} />
        </Col>
        <Col md="4">
          <CurrencyCode
            label="Currency Code:"
            required
            validations={[required]}
            currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
          />
        </Col>
        <Col md="12">
          <Address1 label={"Bank Address line:"} />
        </Col>
        <Col md="4">
          <City label="City" />
        </Col>
        <Col md="4">
          <State label="State/Province" />
        </Col>
        <Col md="4">
          <ZipCode label="Zip Code/Postal Code: " />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Status label={"Status:"} />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex justify-content-end">
          <Primary label="Default for future invoice payment method" />
        </Col>
      </Row>
    </>
  );
};

export default UkEftBankAccount;
