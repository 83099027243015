import React, { useCallback, useEffect, useState } from "react";
import { ComparisonField } from "components/forms/bootstrapFields";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { minValueCleaned, required } from "services/validations/reduxFormValidation";
import { WombatSelect } from "wombatifier/components/pickers/wombatSelect";
import styles from "./cardPayMyAccount.module.css";
import { shallowEqual, useDispatch } from "react-redux";
import { RootState, useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";
import { CardPaymentsSvc } from "services/admin/cards/cardPaymentsSvc";
import { formattedAmount, getNeutralDate } from "services/general/helpers";
import moment from "moment";
import { CardPaymentsType } from "services/admin/cards/payments/cardPayments.types";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";
import { LastCardPaymentable } from "./lastCardPaymentable";
import { isNumber } from "lodash";
import { CardPaymentsApis } from "services/admin/cards/payments/cardPaymentsApis";

interface CardPayMyAccountSetupPropsType {
  valid: boolean;
  close: () => void;
}

interface StandardPaymentOptionPropsType {
  currentPaymentType?: CardPaymentsType;
  label: CardPaymentsType;
  value?: number;
  currencyCode?: string;
  disabled?: boolean;
  handlePaymentTypeChange: (option: { label: CardPaymentsType; value: number }) => void;
}

const StandardPaymentOption = ({
  currentPaymentType,
  label,
  value,
  disabled,
  currencyCode,
  handlePaymentTypeChange,
}: StandardPaymentOptionPropsType) => {
  const isChecked = currentPaymentType ? currentPaymentType === label : false;
  return (
    <Card className="px-1 py-3 mb-3">
      <Row className="mx-0">
        <Col className="px-3 d-flex align-items-center">
          <input
            type="radio"
            checked={isChecked}
            onChange={() => value && handlePaymentTypeChange({ label, value })}
            className={styles.checkedRadio}
            disabled={disabled}
          />
          <div className="pl-2">{label}</div>
        </Col>
        <Col className="px-3" style={{ flex: 0 }}>
          <span>{formattedAmount(`${value}`, currencyCode, 2, true) ?? ""}</span>
        </Col>
      </Row>
    </Card>
  );
};

const ChoosePaymentAmount = ({ sumCardPaymentsByPeriod }: { sumCardPaymentsByPeriod?: number }) => {
  const dispatch = useDispatch();
  const currentStatement = useTypedSelector(
    (state: RootState) => CardPaymentsSvc.getCurrentStatement(state),
    shallowEqual,
  );
  const currentUser = useTypedSelector((state: RootState) => state.user, shallowEqual) as IUser | undefined;
  const currentPaymentType = useTypedSelector((state: RootState) => CardPaymentsSvc.getCurrentPaymentType(state));
  const [currentBalance, setCurrentBalance] = useState<number | undefined>();
  const { id, currency_code } = currentStatement;
  const currencyCode = currency_code || currentUser?.contact?.currency_code;
  const statementBalance = currentStatement?.statement_balance;

  const getCurrentBalance = async () => {
    try {
      const res = await CardPaymentsApis.balanceSummary(id);
      setCurrentBalance(
        res.available_credit_inquiry_response.array_of_available_credit_inquiry_records.available_credit_inquiry_records
          .account_balance,
      );
    } catch (err) {}
  };

  const handlePaymentTypeChange = useCallback(
    ({ value, label }: { value?: number; label: CardPaymentsType | "Other Balance" }) => {
      CardPaymentsSvc.setCurrentPaymentType(dispatch, label);
      const numericalValue = CardPaymentsSvc.parseNumberToString(value ? value : 0.0);
      CardPaymentsSvc.setCurrentAmountToPay(dispatch, `${currencySymbolRenderer(currencyCode)}${numericalValue}`);
    },
    [dispatch],
  );

  const handleOtherBalanceBlur = (input: any) => {
    let value = CardPaymentsSvc.parseNumberToString(input.value ?? "");
    const numericalValue = Number(value);
    if (currentBalance) {
      if (numericalValue >= 0.0 && numericalValue <= currentBalance) {
        input.onChange(`${currencySymbolRenderer(currencyCode)}${CardPaymentsSvc.parseNumberToString(value)}`);
      } else if (currentBalance && numericalValue > currentBalance) {
        input.onChange(`${currencySymbolRenderer(currencyCode)}${CardPaymentsSvc.parseNumberToString(currentBalance)}`);
      }
    } else if (statementBalance) {
      if (numericalValue >= 0.0 && numericalValue <= statementBalance) {
        input.onChange(`${currencySymbolRenderer(currencyCode)}${CardPaymentsSvc.parseNumberToString(value)}`);
      } else if (numericalValue > statementBalance) {
        input.onChange(
          `${currencySymbolRenderer(currencyCode)}${CardPaymentsSvc.parseNumberToString(statementBalance)}`,
        );
      }
    }
  };

  const currentOptionDisabled =
    !isNumber(sumCardPaymentsByPeriod) || !isNumber(currentBalance) || sumCardPaymentsByPeriod > currentBalance;
  const statementOptionDisabled =
    !isNumber(sumCardPaymentsByPeriod) || !isNumber(statementBalance) || sumCardPaymentsByPeriod > statementBalance;
  const otherOptionDisabled = currentOptionDisabled && statementOptionDisabled;

  useEffect(() => {
    getCurrentBalance();
  }, []);

  return (
    <>
      <StandardPaymentOption
        currentPaymentType={currentPaymentType}
        label="Current Balance"
        value={currentBalance}
        currencyCode={currencyCode}
        handlePaymentTypeChange={handlePaymentTypeChange}
        disabled={currentOptionDisabled}
      />
      <StandardPaymentOption
        currentPaymentType={currentPaymentType}
        label="Statement Balance"
        value={statementBalance}
        currencyCode={currencyCode}
        handlePaymentTypeChange={handlePaymentTypeChange}
        disabled={statementOptionDisabled}
      />
      <Card className="px-1 py-3 mb-3">
        <Row className="mx-0">
          <Col className="px-3 d-flex align-items-center">
            <input
              type="radio"
              checked={(currentPaymentType && currentPaymentType === "Other Balance") ?? false}
              onChange={() => handlePaymentTypeChange({ value: 0.0, label: "Other Balance" })}
              className={styles.checkedRadio}
              disabled={otherOptionDisabled}
            />
            <div className="pl-2">{"Other Balance"}</div>
          </Col>
          <Col className="px-3" md={3}>
            <Field
              name={`payMyAccount.form.${currentPaymentType === "Other Balance" ? "amount" : "other_amount"}`}
              // filler field to show input as empty when not using "Other Balance" option
              component={ComparisonField}
              placeholder={`${currencySymbolRenderer(currencyCode)}0.00`}
              disabled={currentPaymentType !== "Other Balance" || otherOptionDisabled}
              transparentDisabled={false}
              className={`p-0 ${styles.overrideFormControl}`}
              inputClassName={`text-right w-100 ${styles.otherBalance}`}
              handleOnBlur={handleOtherBalanceBlur}
              pattern={/\d+/}
              validate={[isNumber]}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export const CardPayMyAccountSetup = ({ valid, close }: CardPayMyAccountSetupPropsType) => {
  const dispatch = useDispatch();
  const currentPaymentType = useTypedSelector((state: RootState) => CardPaymentsSvc.getCurrentPaymentType(state));
  const currentPaymentAccounts = useTypedSelector(
    (state: RootState) => CardPaymentsSvc.getCurrentPaymentAccountOptions(state),
    shallowEqual,
  );
  const currentStatement = useTypedSelector(
    (state: RootState) => CardPaymentsSvc.getCurrentStatement(state),
    shallowEqual,
  );
  const currentUser = useTypedSelector((state: RootState) => state.user, shallowEqual) as IUser | undefined;
  const { account_code, due_date, currency_code, statement_date } = currentStatement;
  const { lastCardPayment, sumCardPaymentsByPeriod } = LastCardPaymentable({
    accountCode: account_code,
    paymentDateAfter: getNeutralDate(new Date(statement_date)),
  });
  const currencyCode = currency_code || currentUser?.contact?.currency_code;
  const formattedDueDate = due_date && moment(getNeutralDate(new Date(due_date))).format("MMM DD, YYYY");

  return (
    <>
      <Modal.Header>
        <Container className="px-2">
          <Row className={`mx-0 justify-content-between align-items-center w-100 ${styles.modalHeader}`}>
            <Col className="px-0">Pay My Account</Col>
            <Col className="px-0" style={{ flex: 0 }}>
              <i onClick={close} className={`${styles.modalClose} icon-close`}></i>
            </Col>
          </Row>
          <Row className={`mx-0 ${styles.modalSubheader}`}>
            <Col className="px-0 pr-2 text-nowrap" style={{ flex: 0 }}>
              <label>Account Code:</label>
            </Col>
            <Col className="px-0">
              <label>{account_code ?? "N/A"}</label>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container className={`px-4 ${styles.section}`}>
          <Row className={`mx-0 font-weight-bold`}>
            <label>Amount You Would Like to Pay</label>
          </Row>

          {lastCardPayment ? (
            <Row className="mx-0 border-bottom pb-2 mb-3">
              <span>
                Your last payment was {formattedAmount(lastCardPayment.amount, currencyCode, 2, true)} on{" "}
                {moment(getNeutralDate(new Date(lastCardPayment.payment_date))).format("MMM DD, YYYY")}
              </span>
            </Row>
          ) : null}
          <Container className="px-0">
            <ChoosePaymentAmount sumCardPaymentsByPeriod={sumCardPaymentsByPeriod} />
          </Container>
          <Row className={`mx-0 font-weight-bold`}>
            <label>Payment Method and Date</label>
          </Row>
          <Row className="mx-0 border-bottom pb-2 mb-3 font-weight-normal">
            <span>Your payment is due on {formattedDueDate ?? "N/A"}</span>
          </Row>

          <Row className="mx-0">
            <Col className="px-0">
              <Field
                name="payMyAccount.form.payment_source"
                label="Payment Source"
                labelClassName={`${styles.setUpPayment}`}
                component={WombatSelect}
                validate={[required]}
                options={currentPaymentAccounts}
              />
            </Col>
          </Row>
          {/*
                <Row className = "mx-0">
                <Col className = "px-0">
                    <Field
                        name = 'payMyAccount.form.payment_date'
                        label = "Payment Date"
                        labelClassName = {`${styles.setUpPayment}`}
                        component = {RenderDatePicker}
                        validate = {[required]}
                    />
                </Col>
                </Row>
                */}
        </Container>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Row className="mx-0 justify-content-end">
          <Button
            variant="primary"
            className={`py-1 ${styles.footerButton}`}
            disabled={!valid || !currentPaymentType}
            onClick={() => CardPaymentsSvc.setNextStep(dispatch, "review")}
          >
            Next
          </Button>
        </Row>
      </Modal.Footer>
    </>
  );
};
