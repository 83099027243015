import { CommonTypes } from "services/common/types/commonTypes";
import { PolicyTypes } from "./policyTypes";
import _ from "lodash";

class PolicyCommonSvc {
  checkCurrencyCode = (policy: PolicyTypes.Details, currencyCode: CommonTypes.ItemCurrencyCode) => {
    return _.find(policy.currency_attributes, function (currency) {
      return currency.label === currencyCode.value;
    });
  };

  getPolicyCurrencyCodes(
    policy: PolicyTypes.Details | undefined,
    currencyCodes: CommonTypes.ListCurrencyCodeItem,
  ): CommonTypes.ListCurrencyCodeItem {
    if (policy && !Array.isArray(policy.currency_attributes)) {
      policy.currency_attributes =
        policy.currency_setting === "allow" ? policy.allowed_currencies : policy.excluded_currencies;
    }
    if (policy && policy.currency_setting === "allow" && Array.isArray(policy.currency_attributes)) {
      return currencyCodes.filter((currencyCode) => this.checkCurrencyCode(policy, currencyCode));
    } else if (policy && policy.currency_setting === "exclude" && Array.isArray(policy.currency_attributes)) {
      return currencyCodes.filter((currencyCode) => !this.checkCurrencyCode(policy, currencyCode));
    } else {
      return currencyCodes;
    }
  }
}
const policyCommonSvc = new PolicyCommonSvc();

export default policyCommonSvc;
