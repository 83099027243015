import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import StepsBar from "./stepsBar";
import VendorContactForm, { ContactForm, contactFormData } from "../../contacts/vendorContactForm";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { useTranslation } from "react-i18next";
import { restApiService } from "../../../../providers/restApi";
import _ from "lodash";
import { SubmissionError, reset } from "redux-form";
import { createCompleteError } from "../../../../services/general/reduxFormSvc";
import { VENDOR_CONTACT_FORM, resetVendorContactForm } from "../../../../reducers/vp/vendorContactFormReducer";
import { useDispatch } from "react-redux";
import { setShowGuide } from "../../../../reducers/vp/vendorSetUpGuideReducer";
import style from "./dashboard.module.css";
import { Link } from "react-router-dom";
import LoadingBox from "../../../common/loaders/loadingBox";
import { IContact } from "../../../../services/common/contact/contactTypes";
import ContactApis from "../../../../services/common/contact/contactApis";

const AddContactStep5 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contacts, setContact] = useState<Array<IContact>>([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const closeGuide = () => {
    dispatch(setShowGuide(false));
  };

  const onSubmit = async (vendorContactFormData: contactFormData) => {
    try {
      setIsSubmitDisabled(true);
      const response = await restApiService.post("contacts", null, {
        contact: vendorContactFormData.form.contact,
      });
      // Every time we submit vendor contact form shouls be reseted to initial state
      dispatch(resetVendorContactForm());
      dispatch(reset(VENDOR_CONTACT_FORM));

      setIsSubmitDisabled(false);

      CreateNotification(t("success"), t("contactPage.saved"), NotificationType.success);

      // on successful submission for the contact
      await fetchContacts();
    } catch (error: any) {
      setIsSubmitDisabled(false);
      const { response } = error;
      if (response?.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          const formData: any = { contact: { ...completeErrorObj } };
          throw new SubmissionError({
            form: formData,
          });
        }
      }
    }
  };

  const fetchContacts = async () => {
    try {
      setLoading(true);
      const contacts = await ContactApis.getContacts({
        filter: { limit: 5, sort: { id: { priority: 1, direction: "desc" } } },
      });
      setContact(contacts);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const initContactsStep5 = async () => {
    await fetchContacts();
  };

  useEffect(() => {
    initContactsStep5();

    return () => {
      // Every time we leave vendor contact form shouls be reseted to initial state
      dispatch(resetVendorContactForm());
    };
  }, []);

  const onSkipAndFinish = () => {
    // just close guide
    closeGuide();
  };

  return (
    <Row>
      <Col className="d-flex flex-column justify-content-center align-items-center px-5">
        <Row>
          <Col className="my-4">
            <StepsBar step1 step2 step3 step4 step5 />
          </Col>
        </Row>
        <Row>
          <h3>{t("vp.dashboard.vendorGuide.addContacts")}</h3>
        </Row>
        <Row className="my-3 mx-0">
          <p>{t("vp.dashboard.vendorGuide.editCompanyInformation")}</p>
        </Row>
        <Row>
          <VendorContactForm
            contactForm={<ContactForm />}
            onSubmit={onSubmit}
            footerButtons={
              <>
                <LoadingBox isLoading={loading}>
                  {_.isArray(contacts) && contacts.length > 0 && (
                    <Col md="12">
                      <Row>
                        <Form.Label>{t("vp.dashboard.vendorGuide.addedContacts")}</Form.Label>
                      </Row>
                      {/* table of contacts */}
                      <Row>
                        <Col md="12" className="p-0">
                          <div className={style.tableWrapper + " w-75"}>
                            <Table responsive bordered className={"mb-0 text-left"}>
                              <thead>
                                <tr>
                                  <th>{t("vp.dashboard.vendorGuide.contactId")}</th>
                                  <th>{t("vp.dashboard.vendorGuide.firstName")}</th>
                                  <th>{t("vp.dashboard.vendorGuide.lastName")}</th>
                                  <th>{t("vp.dashboard.vendorGuide.email")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {contacts.map((contact) => {
                                  return (
                                    <tr key={contact.id}>
                                      <td>
                                        <Link to={`/ar/contacts/${contact.id}`} className="link">
                                          {contact.id}
                                        </Link>
                                      </td>
                                      <td>{contact.first_name}</td>
                                      <td>{contact.last_name}</td>
                                      <td>{contact.email}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </LoadingBox>
                <Row className="mt-3 d-flex justify-content-center align-items-center">
                  <Button disabled={isSubmitDisabled} type="submit" className="mr-3">
                    {t("submit")}
                  </Button>
                  <Button onClick={onSkipAndFinish}>
                    {_.isArray(contacts) && contacts.length < 1 && t("vp.dashboard.vendorGuide.skipAndFinish")}
                    {_.isArray(contacts) && contacts.length > 0 && t("vp.dashboard.vendorGuide.finish")}
                  </Button>
                </Row>
              </>
            }
          />
        </Row>
      </Col>
    </Row>
  );
};

export default AddContactStep5;
