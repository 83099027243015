import React, { Component } from "react";
import { Table } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import { withTranslation } from "react-i18next";
import Panel from "../../panel/panel";
import { Link } from "react-router-dom";
import styles from "./unlinkedPurchasers.module.css";
import _ from "lodash";
import PaymentMethodLinkUnlinkRow from "./paymentMethodLinkUnlinkRow";
class UnlinkedPurchasers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      vendorCreatedLinkedPaymentMethod: [],
    };
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.getPurchasers();
  }

  getPurchasers = () => {
    this.restApiService
      .get("purchasers")
      .then((result) => {
        if (result.data) {
          // Sort the record in ascending order by its name
          result.data.sort((firstEl, secondEl) =>
            firstEl.name.localeCompare(secondEl.name, "en", { sensitivity: "base" }),
          );

          //only want to show purchasers that do NOT have a pm linked
          let validPurchasers = [];
          result.data.forEach((purchaser) => {
            if (
              this.props.showAll ||
              (!purchaser.has_linked_payment_method && this.purchaserHasAnyAcceptedPaymentMethods(purchaser))
            ) {
              validPurchasers.push(purchaser);
            }
          });
          this.setState({
            purchasers: validPurchasers,
            loaded: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (!this.state.loaded || !this.state.purchasers || this.state.purchasers.length < 1) {
      return false;
    }
    return (
      <Panel
        panelContent={{ heading: this.props.heading || "Customers Requiring Payment Method" }}
        cardBodyStyle={{ padding: 0 }}
        cardClasses={"animated fadeIn"}
        headerComponent={
          !this.props.hideHeaderText ? (
            <p className={`${styles.headerParagraph}`}>
              Don't see a valid payment method? Add it in the{" "}
              <Link className="primaryLinkColor" to={"/ar/banking_information"}>
                Bank Settings
              </Link>
            </p>
          ) : null
        }
      >
        <Table striped bordered hover className="mb-0">
          <thead>
            <tr>
              <th className="primaryFontColor">{"Name"}</th>
              <th className="primaryFontColor">{"Accepted Payment Types"}</th>
              <th className="primaryFontColor">{"Select Account"}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {_.isArray(this.state.purchasers) &&
              this.state.purchasers.map((purchaser) => {
                return (
                  <PaymentMethodLinkUnlinkRow
                    key={purchaser.id}
                    purchaser={purchaser}
                    companyPaymentMethods={this.props.companyPaymentMethods}
                    getPurchasers={this.getPurchasers}
                  />
                );
              })}
          </tbody>
        </Table>
      </Panel>
    );
  }
}

export default withTranslation()(UnlinkedPurchasers);
