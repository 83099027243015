import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { change, stopSubmit } from "redux-form";
import BankCardTransactionApis from "services/admin/bankCardTransactions/bankCardTransactionsApis";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { ExpenseConstants } from "services/admin/expenses/expenseSvc";
import ReceiptsApis from "services/admin/expenses/receipts/receiptsApis";
import commonService from "services/common/commonSvc";
import { IAttachment, IDType } from "services/common/types/common.type";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { createCompleteError } from "services/general/reduxFormSvc";
import FormExpenseItem from "./formExpenseItem";

type SubmissionManagementType = {
  submitCount: number;
  totalCount: number;
  anyFailed: boolean;
};

type SumbitOneFinishedFunType = (param: {
  expenseItemsFormData: ExpensesTypes.ExpenseFormDataType;
  item: ExpensesTypes.ExpenseItemFormDataType;
  submissionManagement: SubmissionManagementType;
  failed: boolean;
  expenses: ExpensesTypes.ExpenseItemFormDataType[];
}) => void;

type submitOneFunType = (params: {
  expenseItemsFormData: ExpensesTypes.ExpenseFormDataType;
  expenseItem: ExpensesTypes.ExpenseItemFormDataType;
  index: number;
  finishCallback: SumbitOneFinishedFunType;
  submissionManagement: SubmissionManagementType;
}) => void;

const AddExpenseItem = () => {
  const params: Record<string, any> = {};
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  params.receiptID = searchParams.get("receiptID");
  params.poID = searchParams.get("poID");
  const dispatch = useDispatch();
  const history = useHistory();
  const expenseReportId = searchParams.get("expense_report_id");
  const [expenseReport, setExpenseReport] = useState<ExpenseReportTypes.Details>();

  const checkTaxAmount = (expenseItem: ExpensesTypes.ExpenseItemFormDataType, index: number) => {
    if (expenseItem.tax && expenseItem?.total && expenseItem.tax > expenseItem?.total) {
      dispatch(
        change(ExpenseConstants.EXPENSE_ITEM_FORM, `expenses[${index}].warning`, "Tax can't be greater than total."),
      );
      return false;
    }
    return true;
  };

  const linkReceiptExpense = async (expense: ExpensesTypes.ExpenseItemFormDataType) => {
    try {
      let updatePayload = {
        documentable_type: "InvoiceItem",
        documentable_id: expense.id,
        status: "MATCHED",
      };
      const receipt = await ReceiptsApis.editReceipt(params.receiptID, updatePayload);
      history.push("/ap/inbound_receipts/" + params.receiptID);
    } catch (error) {
      console.log(error);
    }
  };

  const linkReceipt = async (expense: ExpensesTypes.ExpenseItemFormDataType, attachments: IAttachment[]) => {
    // remove attachment which have id
    const attachmentsFiltered = attachments.filter(function (attachment) {
      return !attachment.id;
    });
    if (Array.isArray(attachmentsFiltered) && attachmentsFiltered.length > 0) {
      if (expense?.id) {
        const updateExpense = await expenseItemCommonSvc.uploadAttachments({
          expenseId: expense?.id,
          attachments: attachmentsFiltered,
        });
        // now link expense it to the receipt and redirect to receipt details page,
        if (updateExpense) {
          linkReceiptExpense(expense);
        }
      }
    } else {
      linkReceiptExpense(expense);
    }
  };

  const importTransaction = async (expenseItem: ExpensesTypes.ExpenseItemFormDataType, transaction: any) => {
    // TODO: fix type of transaction
    //copy so if it fails the grid doesnt have bad data
    let copy = Object.assign({}, transaction);
    copy.import_status = "IMPORTED";
    copy.expense_item_id = expenseItem.finalPrimaryKey;
    try {
      const response = await BankCardTransactionApis.createBankCardTransactions({
        bank_card_transaction: copy,
        contact_transactions: true,
      });
      // $scope.$emit("transaction:updated", result); // TODO: cross verfiy what it does
      CreateNotification("Imported", "Transaction Imported Successfully", NotificationType.success);
    } catch (error) {
      console.log(error);
    }
  };

  const submitOne: submitOneFunType = async ({
    expenseItemsFormData,
    expenseItem,
    index,
    finishCallback,
    submissionManagement,
  }) => {
    if (!checkTaxAmount(expenseItem, index)) {
      return false;
    }

    if (expenseItem.finalId) {
      finishCallback({
        expenseItemsFormData,
        expenses: expenseItemsFormData.expenses,
        failed: submissionManagement.anyFailed,
        item: expenseItem,
        submissionManagement: submissionManagement,
      });
      return;
    }

    if (!expenseItem?.department_id) {
      expenseItem.department_id = "";
    }

    expenseItem.is_expense_item = true;
    if (!expenseItem.description || params?.receiptID) {
      expenseItem.description = "";
    }
    if (!expenseItemsFormData?.is_draft) {
      expenseItem.status = "NEW";
    } else {
      expenseItem.status = "DRAFT";
    }
    if (_.isPlainObject(expenseItem.project) && !expenseItem.project_id) {
      expenseItem.project_id = expenseItem?.project?.id;
    }

    if (expenseItem && expenseItem.amount === 0) {
      CreateNotification("Not Allowed", "Total Amount should not be zero", NotificationType.danger);
      return;
    }
    // expenseItem.is_submit_disabled = true;
    dispatch(change(ExpenseConstants.EXPENSE_ITEM_FORM, "is_submit_disabled", true));
    // This ensures that attachment is not getting submitted with the POST request, making it more efficient.
    const attachments = expenseItemCommonSvc.moveAssetsAttributes(expenseItem);

    //tell our metadata directive to serialize the metadata into metadata_link_attributes
    // $scope.$emit("metadata_serialize", null); // TODO: cross check it
    try {
      const response = await ExpensesApis.createExpenseItem({ expense_item: expenseItem });
      CreateNotification("Added", "Expense Number " + response.number + " added", "success");
      if (params.receiptID) {
        const receiptAttachment = attachments ? attachments : [];
        linkReceipt(response, receiptAttachment);
        return;
      }

      if (attachments && Array.isArray(attachments) && attachments.length > 0 && response.id) {
        try {
          const attachmentUpdatedRes = await expenseItemCommonSvc.uploadAttachments({
            attachments: attachments,
            expenseId: response.id,
          });
          if (attachmentUpdatedRes) {
            expenseItem.finalId = response.number;
            expenseItem.finalPrimaryKey = response.id;
            finishCallback({
              expenseItemsFormData,
              expenses: expenseItemsFormData.expenses,
              submissionManagement,
              item: expenseItem,
              failed: false,
            });
          }
        } catch (error) {
          throw error;
        }
      } else {
        expenseItem.finalId = response.number;
        expenseItem.finalPrimaryKey = response.id;
        finishCallback({
          expenseItemsFormData,
          expenses: expenseItemsFormData.expenses,
          submissionManagement,
          item: expenseItem,
          failed: false,
        });
      }
    } catch (error) {
      // ON FAILURE
      dispatch(change(ExpenseConstants.EXPENSE_ITEM_FORM, "is_submit_disabled", false));

      if (
        typeof error === "object" &&
        error !== null &&
        "response" in error &&
        typeof error.response === "object" &&
        error.response !== null &&
        "status" in error.response &&
        error?.response?.status
      ) {
        const { response } = error;
        if (response.status === 422) {
          if (
            typeof response === "object" &&
            response != null &&
            "data" in response &&
            _.isPlainObject(response.data)
          ) {
            const completeErrorObj = createCompleteError(response.data);
            if (completeErrorObj["currency_code"]) {
              const errorStr = Array.isArray(completeErrorObj["currency_code"]) && completeErrorObj["currency_code"][0];
              dispatch(change(ExpenseConstants.EXPENSE_ITEM_FORM, `expenses[${index}].currency_code_error`, errorStr));
            }
            const formError: any = {};
            formError[`expenses[${index}]`] = completeErrorObj;

            if (typeof response.data === "object") {
              const responseData: any = response.data;
              for (const key in responseData) {
                CreateNotification("Invalid Data", `${key} ${responseData[key][0]}`, NotificationType.danger);
              }
            }

            dispatch(stopSubmit(ExpenseConstants.EXPENSE_ITEM_FORM, { _error: formError }));
          }
          if ("data" in response && typeof response.data === "object" && response.data !== null) {
            if ("assets.asset_file_file_name" in response.data && response.data["assets.asset_file_file_name"]) {
              expenseItem.error = "Invalid Attachment";
              if (Array.isArray(response.data["assets.asset_file_file_name"])) {
                CreateNotification(
                  "Invalid Attachment",
                  response.data["assets.asset_file_file_name"][0],
                  NotificationType.danger,
                );
              }
            }

            if ("base_currency_total" in response.data && response.data["base_currency_total"]) {
              dispatch(
                change(ExpenseConstants.EXPENSE_ITEM_FORM, `expenses[${index}].error`, "Currency Conversion error"),
              );
              if (Array.isArray(response.data["base_currency_total"])) {
                CreateNotification("Invalid Data", response.data["base_currency_total"][0], NotificationType.danger);
              }
            }

            if ("currency_code" in response.data && response.data["currency_code"]) {
              dispatch(change(ExpenseConstants.EXPENSE_ITEM_FORM, `expenses[${index}].error`, "Currency Code error"));
              if (Array.isArray(response.data["currency_code"])) {
                CreateNotification("Invalid Data", response.data["currency_code"][0], NotificationType.danger);
              }
            }
          }
        } else {
          dispatch(
            change(
              ExpenseConstants.EXPENSE_ITEM_FORM,
              `expenses[${index}].error`,
              "Error Saving Item. Please try again later or reach out to support",
            ),
          );
        }
        finishCallback({
          expenseItemsFormData,
          expenses: expenseItemsFormData.expenses,
          submissionManagement,
          item: expenseItem,
          failed: true,
        });
      }
    }
  };

  // when an expense has submitted it will callback to this function, call the next one in the line
  // if there are any remaining
  const submitOneFinished: SumbitOneFinishedFunType = ({
    item,
    expenseItemsFormData,
    submissionManagement,
    failed,
    expenses,
  }) => {
    try {
      submissionManagement.totalCount++;
      if (failed) {
        submissionManagement.anyFailed = true;
      }
      if (submissionManagement.totalCount >= submissionManagement.submitCount) {
        //done
        if (!submissionManagement.anyFailed) {
          //since this controller can also be used on employee bank transaction grid we check if we need to update the transaction as well
          //note we use [0] because we are only ever doing 1 expense/transaction at a time on the grid
          if (expenses[0].view_transaction) {
            importTransaction(expenseItemsFormData.expenses[0], expenseItemsFormData.expenses[0].view_transaction);
          } else {
            // text cross verify for expense report functionality
            if (params?.expense_report) {
              // 'add expense item' modal form on expense report details page
              // $uibModalInstance.close();
              // $rootScope.$emit("expense:updated");
            } else {
              //normal flow
              history.push("/ap/expenses");
            }
          }
        }

        // After all expenses have been processed, then renable the submit button.
        // This allows the user to continue editing expenses that failed to create.
        dispatch(change(ExpenseConstants.EXPENSE_ITEM_FORM, "is_submit_disabled", false));
      } else {
        submitOne({
          expenseItemsFormData,
          expenseItem: expenseItemsFormData.expenses[submissionManagement.totalCount],
          index: submissionManagement.totalCount,
          finishCallback: submitOneFinished,
          submissionManagement,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const onSubmit = (expenseItemsFormData: ExpensesTypes.ExpenseFormDataType) => {
    try {
      const submissionManagement = {
        submitCount: expenseItemsFormData.expenses.length,
        totalCount: 0,
        anyFailed: false,
      };

      submitOne({
        expenseItemsFormData,
        expenseItem: expenseItemsFormData.expenses[submissionManagement.totalCount],
        index: submissionManagement.totalCount,
        finishCallback: submitOneFinished,
        submissionManagement,
      });
    } catch (error) {
      throw error;
    }
  };

  const onCancel = () => {
    if (expenseReportId) {
      history.goBack();
    } else if (params.receiptID) {
      history.goBack();
    } else {
      history.push("/ap/expenses");
    }
  };

  const fetchExpenseReport = async () => {
    try {
      const expenseReport = await ExpenseReportApis.getDetail(expenseReportId as IDType);
      setExpenseReport(expenseReport);
    } catch (error) {
      commonService.handleError(error);
    }
  };

  useEffect(() => {
    if (expenseReportId) {
      fetchExpenseReport();
    }
  }, []);

  if (expenseReportId && expenseReport) {
    return <FormExpenseItem onSubmit={onSubmit} onCancel={onCancel} expenseReport={expenseReport} />;
  } else if (expenseReportId && !expenseReport) {
    return null;
  }
  return <FormExpenseItem onSubmit={onSubmit} onCancel={onCancel} />;
};

export default AddExpenseItem;
