import RestApi from "../../providers/restApi";

export function LinkPaymentMethod(vendorId, paymentMethodId) {
  return new RestApi().post("vendors/" + vendorId + "/link_payment_method/" + paymentMethodId);
}

export function UnlinkPaymentMethod(vendorId, paymentMethodId) {
  return new RestApi().delete("vendors/" + vendorId + "/unlink_payment_method/" + paymentMethodId);
}

//Returns true if the purchaser has ANY accepted payment methods
export const purchaserHasAnyAcceptedPaymentMethods = (purchaser) => {
  let hasAny = false;
  for (const [key, value] of Object.entries(purchaser.accepted_payment_methods)) {
    if (value) {
      hasAny = true;
    }
  }
  return hasAny;
};

// check if linking is required ro not
export const isPaymentMethodLinkingRequired = (purchasers) => {
  const onlyCorpayNVPPurchasers = purchasers.every((purchaser) => purchaser.has_only_corpay_nvp_integration === true);
  const paymentMethodsLinkedFlags = purchasers.map((purchaser) => {
    // iterator over all purchasers
    // check if any of them have accepted payment methods and have no payment methods linked
    const anyAcceptedPm = purchaserHasAnyAcceptedPaymentMethods(purchaser);

    // Company does not need to link payment methods if all of it's purchasers are NVP only. If not, iterate through all purchasers to make sure they have an acceptable payment method
    return onlyCorpayNVPPurchasers ? false : !purchaser.has_linked_payment_method && anyAcceptedPm;
  });

  const linkingRequired = paymentMethodsLinkedFlags.reduce((acc, curr) => {
    return acc || curr;
  }, paymentMethodsLinkedFlags[0]);

  return linkingRequired;
};
