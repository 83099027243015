import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { restApiService } from "../../../../../providers/restApi";
import { Form, Spinner } from "react-bootstrap";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Select from "react-select";
import {
  findSelectedSingle,
  onBlurSingle,
  onChangeSingle,
  parseForSelect,
} from "../../../../../services/general/helpers";
import { IDType } from "../../../../../services/common/types/common.type";

type VendorClassificationType = {
  id?: IDType;
  category?: string;
  name?: string;
  required_po?: string;
  status?: string;
  vendor_type?: string;
};

type InputType = {
  value: number;
  onChange: (values: number) => void;
  onBlur: (values: number) => void;
};

type VendorClassificationPropsType = {
  label?: string;
  tooltip?: string;
  required?: boolean;
  placeholder?: string;
  input: InputType;
  meta: {
    touched?: boolean;
    error?: string;
  };
  callBack: (selected: { value: number; label: string }) => void;
};

const VendorClassification = ({
  label,
  input,
  meta: { touched, error },
  tooltip,
  required,
  callBack,
  placeholder = "search/select",
}: VendorClassificationPropsType) => {
  const [classifications, setClassifications] = useState([]);
  const [loading, setLoading] = useState(true);

  const getVendorClassification = useCallback(async () => {
    try {
      const response: AxiosResponse<VendorClassificationType> = await restApiService.get("vendor_classifications", {
        vendor_type: "COMPANY",
        status: "ACTIVE",
      });
      setClassifications(parseForSelect(response.data));
      setLoading(false);
    } catch (error) {
      throw error;
    }
  }, []);

  const onChange = (input: InputType, selected: { value: number; label: string }) => {
    onChangeSingle(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  useEffect(() => {
    getVendorClassification();
  }, []);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      {loading ? (
        <>
          <br /> <Spinner size="sm" animation="border" variant="info" />
        </>
      ) : (
        <Select
          {...input}
          required={required}
          value={findSelectedSingle(input, classifications)}
          placeholder={placeholder}
          onChange={(selected) => onChange(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={classifications}
        />
      )}
      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default VendorClassification;
