import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import React, { useEffect, useMemo, useState } from "react";
import { Control, Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getYearAndMonthOptions } from "services/date/date";

type TOption = {
  value: string;
  label: string;
};

type TFiscalPeriodPickerProps = {
  name: string;
  control?: Control;
  rules?: RegisterOptions;
  required?: boolean;
  hideLabel?: boolean;
  label: string;
  disabled?: boolean;
  callBack?: (selected: TOption) => void;
  instanceId?: string;
};

const FiscalPeriodPicker: React.FC<TFiscalPeriodPickerProps> = ({
  name,
  required = false,
  label,
  hideLabel = false,
  callBack,
  disabled,
  instanceId,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const [options, setOptions] = useState<TOption[]>([]);

  const fiscalPeriodOptions = useMemo(
    () =>
      getYearAndMonthOptions(0, 5, "YYYYMM", required, t("components.common.pickers.emptyOption", { label: label })),
    [label, required, t],
  );

  useEffect(() => {
    setOptions(fiscalPeriodOptions);
  }, [fiscalPeriodOptions]);

  const handleChange = (selected: TOption | null) => {
    setValue(name, selected?.value || null);
    if (callBack) {
      callBack(selected!);
    }
  };

  return (
    <>
      {!hideLabel && (
        <label className="pickerLabel">
          {label} <Mandatory required={required} />
        </label>
      )}

      <Controller
        control={control}
        name={name}
        rules={required ? { required: { value: true, message: t("validations.required") } } : undefined}
        render={({ field: { value, onChange, ...field }, fieldState: { error, isTouched } }) => (
          <>
            <Select
              instanceId={instanceId}
              {...field}
              isClearable={!required}
              value={options.find((option) => option.value === value) || null}
              className="pickerSelectedOption"
              options={options}
              menuPlacement="auto"
              menuPosition="fixed"
              isDisabled={disabled}
              required={required}
              onChange={(selected) => {
                onChange(selected);
                handleChange(selected as TOption | null);
              }}
            />
            {isTouched && error?.message && <PickerErrorBlock error={error?.message} />}
          </>
        )}
      />
    </>
  );
};

export default FiscalPeriodPicker;
