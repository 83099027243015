import CustomModal from "components/modals/customModal";
import React from "react";
import { Col, Row } from "react-bootstrap";
import style from "./consolidateError.module.css";
import { ConsolidateErrorModalTypes } from "./consolidateErrorModalTypes";

// const testData: ConsolidateErrorModalTypes.TConsolidateErrorContainer[] = [
//   {
//     container_name: "Test Container 1",
//     container_id: "12345",
//     errors: [
//       {
//         container_id: "12345",
//         label: "Error Label 1",
//         errorMessage: "This is a test error message 1",
//       },
//       {
//         container_id: "12345",
//         label: "Error Label 2",
//         errorMessage: "This is a test error message 2",
//       },
//       {
//         container_id: "12345",
//         label: "Error Label 3",
//         errorMessage: "This is a test error message 3",
//       },
//     ],
//   },
//   {
//     container_name: "Test Container 2",
//     container_id: "67890",
//     errors: [
//       {
//         container_id: "67890",
//         label: "Error Label 4",
//         errorMessage: "This is a test error message 4",
//       },
//       {
//         container_id: "67890",
//         label: "Error Label 5",
//         errorMessage: "This is a test error message 5",
//       },
//     ],
//   },
// ];

const ConsolidateErrorModal = ({
  errorContainers = [],
  modalTitle,
  modalTitleMessage,
  showConsolidateErrorModal,
  cancel,
}: ConsolidateErrorModalTypes.TConsolidateErrorModalProps) => {
  return (
    <CustomModal
      size="lg"
      dialogClassName={style.modalDialog}
      contentClassName={style.modalContent}
      show={showConsolidateErrorModal}
      onHide={cancel}
      header={
        <>
          <i className="icon icon-system-error inline mt-2 mr-2" />
          {modalTitle}
        </>
      }
      body={
        <Row className={style.main}>
          <Col md="12">
            <Row>{modalTitleMessage}</Row>
            <Row>
              <div className={style.table}>
                <div className={`${style.head}`}>
                  <div className={`${style.tr}`}>
                    <div className={style.th}></div>
                    <div className={style.th}>Field</div>
                    <div className={style.th}>Error Type</div>
                  </div>
                </div>
                <div className={style.body}>
                  {errorContainers
                    .filter((errorContainer) => errorContainer?.errors?.length && errorContainer?.errors?.length > 0)
                    .map((errorContainer) => (
                      <div className={style.tr} key={errorContainer.container_name}>
                        <div className={style.td}>{errorContainer.container_name}</div>
                        <div>
                          {errorContainer.errors?.map((error) => (
                            <div className={style.subRow} key={error.label}>
                              <div className={style.td}>{error.label}</div>
                              <div className={style.td}>{error.errorMessage}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      }
    />
  );
};

export default ConsolidateErrorModal;
