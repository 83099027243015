import React from "react";
import "../../pages/pages.style.css";
import "./form.style.css";
import { Form } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";

const renderInputField = ({
  required = true,
  className,
  value,
  placeholder,
  type,
  label,
  input,
  meta: { touched, error, warning },
}) => (
  <div className={className ? className : "col-sm-4"}>
    <Form.Group>
      <Form.Label>
        {label} {required && <span className={"color-red"}>*</span>}
      </Form.Label>
      <Form.Control
        {...input}
        type={type}
        placeholder={placeholder}
        onChange={(event) => input.onChange(event.target.value)}
      />
      {touched &&
        ((error && <span style={{ color: "#a94442" }}>{error}</span>) ||
          (warning && <span style={{ color: "#a94442" }}>{warning}</span>))}
    </Form.Group>
  </div>
);

const renderDatePicker = ({ className, label, input, meta: { touched, error, warning } }) => (
  <div className={className ? className : "col-sm-4"}>
    <Form.Label className={"w-100"} style={{ textTransform: "capitalize" }}>
      {label}
      <span className={"color-red"}>*</span>
    </Form.Label>
    <DatePicker
      selected={input.value}
      onChange={(value) => input.onChange(value)}
      dateFormat="MM/dd/yyyy"
      placeholderText={"MM/dd/yyyy"}
      className={"w-100 datePickerImage form-control"}
      style={{ width: "100%" }}
    />
    <div>
      {touched &&
        ((error && <span style={{ color: "#a94442" }}>{error}</span>) ||
          (warning && <span style={{ color: "#a94442" }}>{warning}</span>))}
    </div>
  </div>
);

const renderTextareaField = ({ className, required, label, input, meta: { touched, error, warning } }) => (
  <div className={className}>
    <Form.Group>
      <Form.Label>
        {label}
        {required && <span className={"color-red"}>*</span>}
      </Form.Label>
      <Form.Control as="textarea" {...input} />
    </Form.Group>
    {touched &&
      ((error && <span style={{ color: "#a94442" }}>{error}</span>) ||
        (warning && <span style={{ color: "#a94442" }}>{warning}</span>))}
  </div>
);

const renderSelectField = ({
  defaultValue,
  options,
  placeholder,
  value,
  className,
  type,
  label,
  input,
  meta: { touched, error, warning },
}) => (
  <div className={className}>
    <Form.Group>
      <Form.Label style={{ textTransform: "capitalize" }}>
        {label}
        <span className={"color-red"}>*</span>
      </Form.Label>
      <Select
        {...input}
        onChange={(value) => input.onChange(value)}
        options={options}
        placeholder={placeholder}
        onBlur={() => input.onBlur(input.value)}
      />
      {touched &&
        ((error && <span style={{ color: "#a94442" }}>{error}</span>) ||
          (warning && <span style={{ color: "#a94442" }}>{warning}</span>))}
    </Form.Group>
  </div>
);

const required = (value) => (value ? undefined : "This field is required");

export { renderInputField, renderDatePicker, renderTextareaField, required, renderSelectField };
