import React from "react";
import { CardsDisputesFormState } from "../../../../../../services/admin/cards/disputes/cardDisputes.types";
import { Field } from "redux-form";
import { formattedAmount } from "../../../../../../services/general/helpers";
import { getFormValues } from "redux-form";
import { ComparisonField } from "../../../../../forms/bootstrapFields";
import { required, number } from "services/validations/reduxFormValidation";
import { RequiredFieldIcon } from "./requiredFieldIcon";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../../../reducers";

export const IncorrectAmountDispute = () => {
  const purchaseDescription = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue;
  });

  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.option.incorrect_amount.${key}`);
  };

  return (
    <>
      <Col>
        <Row>
          {purchaseDescription?.purchase && (
            <div style={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
              {t2("description_start")}
              &nbsp;
              <b>
                {formattedAmount(
                  (purchaseDescription.purchase.amount || "").toString(),
                  purchaseDescription.purchase.currency_code,
                )}
              </b>
              {t2("description_end")}
            </div>
          )}
          <Field
            name="form.options.CORRECT_AMOUNT_AUTHORIZED"
            component={ComparisonField}
            validate={[required, number]}
            required
            prefix="$"
          ></Field>
          <RequiredFieldIcon></RequiredFieldIcon>
        </Row>
        <br />
        <Row>
          <div dangerouslySetInnerHTML={{ __html: t2("note") }}></div>
        </Row>
      </Col>
    </>
  );
};
