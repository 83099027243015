import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { WrappedFieldProps } from "redux-form";
import MultipleQuestionPicker from "./multipleQuestionPicker";
import SingleQuestionPicker from "./singleQuestionPicker";
import { restApiService } from "providers/restApi";
import { parseForLabelSelect } from "services/general/helpers";

export interface QuestionPickerPropsType extends WrappedFieldProps {
  className?: string;
  tooltip?: ReactNode;
  label?: ReactNode;
  isMulti?: boolean;
  approvalType: string;
}

const QuestionPicker = ({ isMulti, approvalType, ...rest }: QuestionPickerPropsType) => {
  const [questions, setQuestions] = useState([]);
  const mounted = useRef(false);

  const getQuestions = useCallback(async () => {
    const result = await restApiService.get(
      "custom_field_metadata",
      {
        model: approvalType,
      },
      null,
      true,
      null,
      true,
    );
    if (mounted.current) setQuestions(parseForLabelSelect(result.data));
  }, [restApiService]);

  useEffect(() => {
    getQuestions();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return isMulti ? (
    <MultipleQuestionPicker {...rest} questions={questions} />
  ) : (
    <SingleQuestionPicker {...rest} questions={questions} />
  );
};

export default QuestionPicker;
