import React, { ChangeEvent, useEffect } from "react";
import { Field, change, getFormValues } from "redux-form";
import { ManagePaymentMethodFormDataType, PaymentFieldType } from "../types";
import { RenderCheckBox } from "../../../forms/bootstrapFields";
import { useDispatch } from "react-redux";
import { CHANGE_IS_PRIMARY } from "../../../../actions/actionTypes";
import { useTypedSelector } from "../../../../reducers";
import { MANAGE_PAYMENT_METHOD_FORM } from "../../../../services/common/paymentMethod.svc";

type PrimaryPropsTypes = PaymentFieldType;

const Primary = ({ validations, required, label }: PrimaryPropsTypes) => {
  const formData = useTypedSelector(
    (state) => getFormValues(MANAGE_PAYMENT_METHOD_FORM)(state) as ManagePaymentMethodFormDataType | null,
  );

  const isStatusValid = formData?.status === "ACTIVE";
  const dispatch = useDispatch();
  const dispatchChangeIsPrimary = (payload: boolean) => {
    dispatch({ type: CHANGE_IS_PRIMARY, payload: payload });
  };
  const changeIsPrimary = (value: any) => {
    dispatchChangeIsPrimary(value);
  };

  const onChangeStatus = () => {
    if (!isStatusValid) {
      dispatch(change(MANAGE_PAYMENT_METHOD_FORM, "is_primary", false));
    }
  };

  useEffect(() => {
    onChangeStatus();
  }, [formData?.status]);

  return (
    <Field
      label={label}
      component={RenderCheckBox}
      name="is_primary"
      type="checkbox"
      required={required}
      validate={validations}
      onChange={changeIsPrimary}
      disabled={!isStatusValid}
    />
  );
};

export default Primary;
