import React, { useEffect, useState } from "react";
import RestApi from "../../../../providers/restApi";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { formattedAmount } from "../../../../services/general/helpers";
import useIsMounted from "../../../common/hooks/useIsMounted";

const restApiService: RestApi = new RestApi();
interface ICreditMemo {
  id: number;
  status: string;
  payment_number: string;
  payment_date: string;
  currency_code: string;
  amount: string;
  reference_number: string | null;
}

type propsType = {
  filter: any;
};

const CreditMemoTable = ({ filter }: propsType) => {
  const [creditMemos, setCreditMemos] = useState<Array<ICreditMemo>>();
  const isMounted = useIsMounted();

  const fetchCrediMemos = async () => {
    try {
      const response = await restApiService.get("vendor_vendor_credits", filter);
      if (isMounted) {
        setCreditMemos(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted) {
      fetchCrediMemos();
    }
  }, []);

  return (
    <div>
      {creditMemos ? (
        <Table striped bordered hover className="mb-0">
          <thead>
            <tr>
              <th className="primaryFontColor">Number</th>
              <th className="primaryFontColor">Status</th>
              <th className="primaryFontColor">Date</th>
              <th className="primaryFontColor">Amount</th>
              <th className="primaryFontColor">Reference Number</th>
            </tr>
          </thead>
          <tbody>
            {creditMemos.map((memo) => {
              return (
                <tr key={memo.id}>
                  <td>
                    <Link
                      className="primaryLinkColor"
                      to={"/ar/credit_memos/" + memo.id}
                      data-toggle="tooltip"
                      title="Credit Memo"
                    >
                      {memo.payment_number}
                    </Link>
                  </td>
                  <td>{memo.status}</td>
                  <td>{memo.payment_date}</td>
                  <td>{formattedAmount(memo.amount, memo.currency_code)}</td>
                  <td>{memo.reference_number}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <h5 className="text-center mt-3 mb-3">Loading...</h5>
      )}
    </div>
  );
};

export default CreditMemoTable;
