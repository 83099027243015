import React from "react";
import { Row } from "react-bootstrap";
import style from "./dashboard.module.css";
import { useTypedSelector } from "reducers";
import { IPurchaser } from "services/vp/types/purchasers.types";

type ellipsePropsType = {
  children: React.ReactNode;
  isActive: boolean;
};
const Ellipse = ({ children, isActive }: ellipsePropsType) => {
  return (
    <div className={` ${style.ellipse} ${isActive ? style.ellipseActive : style.ellipseDisabled}`}>{children}</div>
  );
};

type stepLinkerPropType = {
  isActive: boolean;
};
const StepLinker = ({ isActive }: stepLinkerPropType) => {
  return <div className={`${style.stepLinker} ${isActive && style.stepLinkerActive}`} />;
};

type stepBarPropType = {
  step1?: boolean;
  step2?: boolean;
  step3?: boolean;
  step4?: boolean;
  step5?: boolean;
};

const StepsBar = ({ step1 = true, step2 = false, step3 = false, step4 = false, step5 = false }: stepBarPropType) => {
  const purchasers: IPurchaser[] = useTypedSelector((state) => state.vendorSetupGuideRed.purchasers);
  const onlyCorpayNVPPurchasers = purchasers.every((purchaser) => purchaser.has_only_corpay_nvp_integration === true);
  return (
    <Row className={`d-flex justify-content-center align-items-center`}>
      {onlyCorpayNVPPurchasers ? (
        <>
          <Ellipse isActive={step3}>1</Ellipse>
          <StepLinker isActive={step3 && step4} />

          <Ellipse isActive={step4}>2</Ellipse>
          <StepLinker isActive={step4 && step5} />

          <Ellipse isActive={step5}>3</Ellipse>
        </>
      ) : (
        <>
          <Ellipse isActive={step1}>1</Ellipse>
          <StepLinker isActive={step1 && step2} />

          <Ellipse isActive={step2}>2</Ellipse>
          <StepLinker isActive={step2 && step3} />

          <Ellipse isActive={step3}>3</Ellipse>
          <StepLinker isActive={step3 && step4} />

          <Ellipse isActive={step4}>4</Ellipse>
          <StepLinker isActive={step4 && step5} />

          <Ellipse isActive={step5}>5</Ellipse>
        </>
      )}
    </Row>
  );
};

export default StepsBar;
