import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import {
  AccountHolder,
  AccountNumber,
  AccountType,
  TransitNumber,
  InstitutionNo,
} from "../fieldComponents/fieldsComponent";
import { EftCanadaValidation } from "../validations/eftCanadaPaymentValidation";
import PaymentCountryPicker from "../../pickers/paymentCountryPicker";
import CurrencyPicker from "../../admin/pickers/reduxPicker/currencyPicker/currencyPicker";

class EftCanadaFormFields extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.props.currencyPickerCADCallback({ value: "CAD", label: "CA$ Canadian Dollar" });
  }

  //Applying validations for all fields
  selectField = (fillteredPayment, fieldName) => {
    return fillteredPayment[0].validations.find((item) => item.field === fieldName);
  };

  render() {
    return (
      <span>
        <Form.Row>
          <AccountHolder {...this.props} field_valid={this.selectField(EftCanadaValidation, "account_name")} />
          <InstitutionNo {...this.props} field_valid={this.selectField(EftCanadaValidation, "institution_number")} />
          <TransitNumber {...this.props} field_valid={this.selectField(EftCanadaValidation, "transit_number")} />
        </Form.Row>
        <Form.Row>
          <AccountType {...this.props} field_valid={this.selectField(EftCanadaValidation, "account_type")} />
          <AccountNumber {...this.props} field_valid={this.selectField(EftCanadaValidation, "account_number")} />
          <CurrencyPicker
            {...this.props}
            selected={this.props.paymentMethodRed.currency_code}
            hide_required_sign={true}
            showDropdownOptions={false}
          />
        </Form.Row>
      </span>
    );
  }
}
export default withTranslation()(EftCanadaFormFields);
