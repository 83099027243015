import _ from "lodash";

const createErrorObject = (errorString, errorValue) => {
  function createError(obj, names) {
    if (names.length === 0) return errorValue;
    const newObj = {};
    newObj[names[0]] = createError(obj, names.slice(1));
    return newObj;
  }

  const names = errorString.split(".");
  const errorObj = createError({}, names);
  return errorObj;
};

export const createCompleteError = (serverErrorObj) => {
  const errorObjArray = [];
  if (_.isPlainObject(serverErrorObj)) {
    for (const errorString in serverErrorObj) {
      if (Object.hasOwnProperty.call(serverErrorObj, errorString)) {
        const errorValue = serverErrorObj[errorString];
        const errorObj = createErrorObject(errorString, errorValue);
        errorObjArray.push(errorObj);
      }
    }

    const commonObj = _.merge({}, ...errorObjArray);
    return commonObj;
  } else {
    return {};
  }
};

/*
  Get only true difference between two objects
  params:
    newValues: newest Form Values
    oldValues: initial Form Values
    trimObjectsWithoutId: 
      true -> filter thru attributes of objects with falsey ids {id: null/undefined, ...}
      false -> skip filtering thru objects with falsey  ids {id: null/defined, ...}
*/
export const getFormDifference = (newValues, oldValues, trimObjectsWithoutId = true, ignoreWith = "_original") => {
  if (_.isEqual(newValues, oldValues)) {
    return {};
  }
  let modifiedValue = _.cloneDeep(newValues);
  let parentObjects = [];
  let objectQueue = [[0, null, modifiedValue, oldValues]];
  let [targetIndex, targetKey, firstObject, secondObject] = [null, null, null, null];
  let [queueIndex, parentIndex] = [0, 0]; //total iterations
  while (queueIndex < objectQueue.length) {
    [targetIndex, targetKey, firstObject, secondObject] = objectQueue[queueIndex];
    if (!_.isPlainObject(firstObject) && !_.isArray(firstObject) && targetKey !== "id" && firstObject == secondObject) {
      delete parentObjects[targetIndex][targetKey];
      queueIndex++;
      continue;
    } else if (_.isPlainObject(firstObject) && !Object.keys(firstObject).length) {
      // delete hash if empty {}
      delete parentObjects[targetIndex][targetKey];
      queueIndex++;
      continue;
    } else if (_.isArray(firstObject) && !firstObject.length) {
      // delete array if empty []
      delete parentObjects[targetIndex][targetKey];
      queueIndex++;
      continue;
    } else if (!_.isPlainObject(firstObject) && !_.isArray(firstObject)) {
      // if primitive values (firstObject, secondObject) are not equal, go to next queue item
      queueIndex++;
      continue;
    }
    parentObjects.push(firstObject);
    if (_.isPlainObject(firstObject)) {
      delete firstObject.fieldId;
      Object.keys(firstObject).forEach((key) => {
        // remove all attributes with keys we do not want i.e. ending with _original
        if (key.endsWith(ignoreWith)) {
          delete firstObject[key];
          return;
        }
      });
      if (trimObjectsWithoutId || firstObject.id) {
        Object.keys(firstObject).forEach((key) => {
          objectQueue.push([parentIndex, key, firstObject[key], secondObject[key]]);
        });
      }
    } else if (_.isArray(firstObject)) {
      for (let i = 0; i < firstObject.length; i++) {
        objectQueue.push([parentIndex, i, firstObject[i], secondObject[i]]);
      }
    }
    parentIndex++;
    queueIndex++;
  }
  return modifiedValue;
};
