import AttachmentCarousel from "components/common/attachmentCarousel/attachmentCarousel";
import useIsMounted from "components/common/hooks/useIsMounted";
import { PolicyViolationSummary } from "components/common/policyViolationSummary/policyViolationSummary";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import CustomModal from "components/modals/customModal";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";
import { selectCurrentUser } from "reducers/userReducers";
import { arrayPush, change } from "redux-form";
import { CategoryTypes } from "services/admin/category/CategoryTypes";
import adminCommonSvc from "services/admin/commonSvc";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import expenseService from "services/admin/expenses/expenseSvc";
import { Can } from "services/authorization/authorization";
import commonService from "services/common/commonSvc";
import { IAttachment } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import ExpenseAllocations from "./expenseAllocations";
import style from "./expenseItem.module.css";
import { ExpenseItemFormContext } from "./expenseItemFormSectionContext";
import ExpenseReceiptList from "./expenseReceiptList";
import { formExpenseItemFormData, useFormValue } from "./formExpenseItem";
import MileageForm from "./mileageForm";
import PerDiemForm from "./perDiemForm";
import PolicyAndItemTypeSelection from "./policyAndItemTypeSelection";
import SingleExpenseForm from "./singleExpenseForm";

type EditExpenseItemFormSectionType = {
  form: string;
  sectionPrefix?: string;
  index: number;
};

const EditExpenseItemFormSection = ({ form, sectionPrefix, index }: EditExpenseItemFormSectionType) => {
  const currentExpenseItemFormLocationString = sectionPrefix ?? ""; //<- referring to current expenseItem
  const expenseFormData = useTypedSelector(formExpenseItemFormData(form));
  const expenses = expenseFormData?.expenses;
  let expenseItem = expenses && expenses[index];
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const appCurrencies = useTypedSelector(selectAppCurrecyCode);
  const mounted = useIsMounted();
  const categories = useFormValue<CategoryTypes.ListItems>(form, "list_categories");
  const { id: expenseItemId } = useParams<{ id: string }>();
  const { createConfirmModal } = useConfirmModal();
  const [showReceiptList, setShowReceiptList] = useState(false);
  const { t } = useTranslation();
  const translate = (key: string) => t(`admin.pages.expenseItemDetails.${key}`);

  function onDeleteConfirm(selectedAttachment: IAttachment): void {
    const updatedAssetsAttributes =
      Array.isArray(expenseItem?.assets_attributes) &&
      expenseItem?.assets_attributes?.map((assetsAttributes) => {
        if (assetsAttributes.id && assetsAttributes.id === selectedAttachment.id) {
          // if files are already uploaded
          assetsAttributes._destroy = 1;
        } else if (assetsAttributes.url && selectedAttachment.url && assetsAttributes.url === selectedAttachment.url) {
          // if files are on local
          assetsAttributes._destroy = 1;
        }
        return assetsAttributes;
      });

    if (updatedAssetsAttributes) {
      dispatch(change(form, currentExpenseItemFormLocationString + ".assets_attributes", updatedAssetsAttributes));
    }
  }

  function onAttachmentUpload(files: File[]): void {
    if (Array.isArray(files) && files.length > 0) {
      const assetsAttributes = files.map((file) => {
        const assetFile = {
          asset_file: file,
          asset_file_file_name: file.name,
          url: URL.createObjectURL(file),
          asset_expiring_url: URL.createObjectURL(file),
        };
        dispatch(arrayPush(form, currentExpenseItemFormLocationString + ".assets_attributes", assetFile));
        return assetFile;
      });
    }
  }

  const getCategories = async () => {
    try {
      const result = await restApiService.get("categories");
      if (result.data && mounted.current) {
        // adding categories to redux form so that can be accessed from multiple place
        dispatch(change(form, "list_categories", result.data));
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  const getExpenseItem = async () => {
    try {
      if (expenseItemId) {
        let result: ExpensesTypes.ExpenseItemFormDataType = await ExpensesApis.getExpense(expenseItemId);
        expenseItem = expenseItem && Object.assign(expenseItem, result);
        if (expenseItem && expenses) {
          expenses[index] = expenseItem;

          if (expenseItem.total) {
            expenseItem.total = adminCommonSvc.roundUpAmount(expenseItem.total, null, currentUser);
          }

          if (
            expenseItem.category_id &&
            categories &&
            !expenseItemCommonSvc.isCategoryExist(expenseItem.category_id, categories)
          ) {
            expenseItem.category_id = "";
          }

          //instead of using show file function assigning assets to assets_attributes
          expenseItem.assets_attributes = expenseItem.assets;
          //we must loop over our options for expense types to find the matching one
          expenseService.expenseItemTypes.forEach((value) => {
            if (expenseItem && expenseItem.item_type === value.value) {
              expenseItem.itemTypes = [value];
              expenseItem.selected_type = value;
            }
          });

          if (expenseItem.base_currency_code && expenses) {
            await expenseItemCommonSvc.getCurrencyRate(
              expenses[index].base_currency_code!,
              index,
              expenses,
              appCurrencies,
              currentUser,
            );
          }

          if (expenseItem.item_type === "MILEAGE" && expenseItem?.currency_code) {
            expenseItemCommonSvc.setDefaultDistanceUnit({
              currencyCode: expenseItem?.currency_code!,
              index,
              expenses,
              currentUser,
            });
          }

          // check if using split expense
          if (
            expenseItemCommonSvc.isSplitExpenseItem({ expenseItem, currentUser }) &&
            expenseItem.expense_report_debit_accounts
          ) {
            expenseItem.expense_report_debit_accounts_attributes =
              expenseItemCommonSvc.updatePercentageOnExpenseReportDebitAccounts({
                expenseReportDebitAccounts: expenseItem.expense_report_debit_accounts,
                expenseItem: expenseItem as any,
              });
            expenseItem.using_split = true;
          }

          if (currentUser.company.expense_item?.enable_split_expenses) {
            // it will valid by default will update to false as user action on split and total
            expenseItem.is_split_100_percent = true;
          }

          // apply metadata to attribute
          expenseItem.metadata_links_attributes = result.metadata_links;

          if (_.isPlainObject(expenseItem)) {
            dispatch(change(form, currentExpenseItemFormLocationString, { ...expenseItem, is_loaded: true }));
          }
        }
      }
    } catch (error) {
      console.log(error);
      commonService.handleError(error);
    }
  };

  const onDeleteAttachment = (attachment: IAttachment) => {
    createConfirmModal({
      title: translate("notification.deleteConfirmationDialog.title"),
      body: translate("notification.deleteConfirmationDialog.body"),
      confirmButtonLabel: translate("notification.deleteConfirmationDialog.deleteButton"),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: () => onDeleteConfirm(attachment),
    });
  };

  const showReceiptListDialog = () => {
    setShowReceiptList(true);
  };

  const receiptAttachedCallback = () => {
    getExpenseItem();
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (categories) {
      getExpenseItem();
    }
  }, [categories]);

  if (!expenseItem?.is_loaded) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="info" />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <>
      <ExpenseItemFormContext.Provider
        value={{ index, formName: form, sectionPrefix: currentExpenseItemFormLocationString }}
      >
        {expenseItem && _.isArray(expenseItem.violations_messages) && expenseItem.violations_messages.length > 0 && (
          <Row className="mb-3">
            <Col>
              <PolicyViolationSummary expenseDetails={expenseItem as ExpensesTypes.Details}></PolicyViolationSummary>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="12">
            <Row>
              <Can I={"editattachment"} a={"ExpenseItems"}>
                <Col md="4">
                  <div className={style.expenseAttachmentParent}>
                    <AttachmentCarousel
                      attachments={expenseItem?.assets_attributes || []}
                      onDeleteAttachmentCallback={onDeleteAttachment}
                      onUploadRequestedCallback={onAttachmentUpload}
                    />
                  </div>
                  {/* AC-17238: hiding "attach receipt" functionality via feature flag.                  
                  <Button
                    className={style.attachFromReceiptQueueButton}
                    variant="secondary"
                    onClick={() => showReceiptListDialog()}
                  >
                    {translate("attach")}
                  </Button> */}
                </Col>
              </Can>
              <Col md="8">
                <PolicyAndItemTypeSelection />
                {expenseItem?.selected_type?.value === "SINGLE_EXPENSE" && <SingleExpenseForm />}
                {expenseItem?.selected_type?.value === "MILEAGE" && <MileageForm />}
                {expenseItem?.selected_type?.value === "PER_DIEM" && <PerDiemForm />}
              </Col>
            </Row>
            <ExpenseAllocations />
          </Col>
        </Row>
      </ExpenseItemFormContext.Provider>
      {expenseItem && (
        <CustomModal
          size="lg"
          show={showReceiptList}
          onHide={() => setShowReceiptList(false)}
          header={translate("attachReceiptDialogHeader")}
          body={
            <ExpenseReceiptList
              expenseItem={expenseItem}
              setShowReceiptList={setShowReceiptList}
              receiptAttachedCallback={receiptAttachedCallback}
            />
          }
        />
      )}
    </>
  );
};

export default EditExpenseItemFormSection;
