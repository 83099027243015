import React from "react";
import ContactsOptionsFilter from "../reportFilters/contactsDropdown";
import DropdownFilter from "../reportFilters/dropdown";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";

const expenseUsersFilter = new URLSearchParams({ by_expense_role: "true" });

const ExpenseUserInfo = ({ scheduled }: { scheduled?: boolean }) => {
  const status_options = [
    { value: "", label: "All" },
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
  ];

  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    }
  };

  return (
    <div>
      {renderDateFilter()}
      <ContactsOptionsFilter
        name={"User Name(s)"}
        code={"id"}
        options={[]}
        isMulti={true}
        customFilters={expenseUsersFilter}
      />
      <DropdownFilter name={"User Status"} code={"status"} options={status_options} />
    </div>
  );
};

export default ExpenseUserInfo;
