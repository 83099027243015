import React from "react";
import { useTranslation } from "react-i18next";
import { Field, WrappedFieldArrayProps, change, getFormValues } from "redux-form";
import { useTypedSelector } from "../../../../reducers";
import { USER_FORM, UserFormDataType } from "../formUser";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Button, Col, Form, Row } from "react-bootstrap";
import DepartmentPicker from "components/admin/pickers/reduxFormPickers/departmentPicker";

interface RenderDepartmentLinksProps extends WrappedFieldArrayProps<any> {}

const RenderDepartmentLinks = ({ fields }: RenderDepartmentLinksProps) => {
  const { t } = useTranslation();
  const formData = useTypedSelector((state) => getFormValues(USER_FORM)(state) as UserFormDataType | null);
  const dispatch = useDispatch();

  const onDeleteDepartment = (deleteIndex: number) => {
    if (_.isArray(formData?.user?.contact?.department_links)) {
      const destoyedDapartments = formData?.user?.contact?.department_links.map((departmentLink, index) => {
        if (index === deleteIndex) {
          departmentLink._destroy = 1;
        }
        return departmentLink;
      });
      dispatch(change(USER_FORM, "user.contact.department_links", destoyedDapartments));
    }
  };

  return (
    <>
      <Row>
        <Col sm="12">
          <Form.Label>{t("admin.pages.users.department")}</Form.Label>
        </Col>
      </Row>
      {fields.map((field, index) => {
        const fieldValues = fields.get(index);
        if (fieldValues._destroy) return null;

        return (
          <Row key={field}>
            <Col sm="10">
              <Field
                name={`${field}.department_id`}
                id={`${field}.department_id-${index}`}
                component={DepartmentPicker}
                type="text"
              />
            </Col>
            <Col>
              <Button
                size="sm"
                id={`${field}.delete-${index}`}
                className="bg-transparent border-0 m-0"
                onClick={() => onDeleteDepartment(index)}
              >
                <i className="icon icon-delete m-0" />
              </Button>
            </Col>
          </Row>
        );
      })}

      <Row>
        <Col sm="12">
          <Button size="sm" id="add-department" className="px-5" onClick={() => fields.push({})}>
            <i className="icon icon-plus mt-1" /> {t("admin.pages.users.addDepartment")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default RenderDepartmentLinks;
