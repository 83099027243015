import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { RootState } from "../../../reducers";
import { renderField } from "../../forms/fields";
import styles from "./chatBox.module.css";
import RestApi from "../../../providers/restApi";
import _ from "lodash";
import moment from "moment";
const restApiService = new RestApi();

export type chatboxPropsType = {
  modelDataNumber: any;
  modelDataId?: number;
  modelDataType: string;
};

export type contentPropsType = {
  content: string;
};

export type MessagePropsType = {
  handle_avatar: string;
  created_at: string;
  description: string;
  data_number: string;
  handle: string;
  activity_type: string;
  user_id: number;
};

interface UserState {
  id: number;
  name: string;
  avatar: any;
}

const ChatBox = ({ modelDataNumber, modelDataId, modelDataType }: chatboxPropsType) => {
  const currentUser = useSelector<RootState, UserState>((state) => state.user);
  const [openChatBox, setOpenChatBox] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>("internalChat");
  const [reply, setReply] = useState<string>("");
  const [chatBoxMessages, setChatBoxMessages] = useState<MessagePropsType[]>([]);
  let message: any = {};
  let newChatBoxMessage: any = {};

  const handleOnChange = ({ content }: contentPropsType) => {
    setReply(content);
  };

  const getMessages = useCallback(async () => {
    let resource = null;
    switch (modelDataType) {
      case "Invoice":
        resource = "vendor_invoices";
        break;
      case "PurchaseOrder":
        resource = "vendor_purchase_orders";
        break;
      case "Accrual":
        resource = "vendor_accruals";
        break;
      case "Vendor_Credit":
        resource = "vendor_vendor_credits";
        break;
      case "recurrings":
        resource = "recurrings";
    }

    const response = await restApiService.get(resource + "/" + modelDataId + "/activities");
    if (_.isArray(response.data) && response.data.length > 0) {
      setChatBoxMessages(response.data);
    }
  }, [modelDataId]);

  const checkActivable_Type = (modelDataType: string) => {
    switch (modelDataType) {
      case "Vendor_Credit":
        return "Payment";
      default:
        return modelDataType;
    }
  };
  const handleSubmit = async () => {
    message.activity_type = "COMMENT";
    message.activitable_id = modelDataId;
    message.activitable_type = checkActivable_Type(modelDataType);
    message.description = reply;
    message.notify_contacts = [];
    message.public = true;

    await restApiService
      .post("activities", null, { activity: message })
      .then((response) => {
        let newChatBoxMessage = response.data;
        newChatBoxMessage.user_id = currentUser.id;
        newChatBoxMessage.handle = currentUser.name;
        newChatBoxMessage.public = true;
        newChatBoxMessage.handle_avatar = currentUser.avatar
          ? currentUser.avatar.asset_expiring_url || undefined
          : undefined;

        setChatBoxMessages([...chatBoxMessages, newChatBoxMessage]);
        setReply("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChatOpen = () => {
    getMessages();
    setOpenChatBox(true);
  };

  return (
    <>
      <button type="button" className={"btn btn-secondary no-print"} onClick={handleChatOpen}>
        <i className="icon icon-open-chat"></i> Message
      </button>
      {openChatBox && (
        <div className={styles.messagePanel}>
          <div className={styles.messagePanelTitle}>
            <Row>
              <Col sm={11} className="pl-0">
                {modelDataNumber} Message
              </Col>
              <Col sm={1} className={styles.btnClosePanel}>
                <i className="icon icon-close" onClick={() => setOpenChatBox(false)}></i>
              </Col>
            </Row>
          </div>
          <div className={styles.messagePanelContent}>
            {_.isArray(chatBoxMessages) &&
              chatBoxMessages.length > 0 &&
              chatBoxMessages.map((activity, index) => {
                let avatar =
                  activity && activity.handle_avatar ? activity.handle_avatar : "../../../assets/images/common.png";
                return (
                  activity.activity_type === "COMMENT" && (
                    <div key={index}>
                      <Row>
                        {activity.user_id !== currentUser.id && (
                          <Col sm={1} className={styles.colMaxWidth}>
                            <img alt="img" className={styles.chatBoxUserAvatar} src={avatar} />
                          </Col>
                        )}
                        <Col
                          sm={10}
                          className={`${activity.user_id !== currentUser.id ? styles.paddingLeft0 : styles.paddingRight0}`}
                        >
                          <div className={styles.messagerName}>
                            {activity.user_id === currentUser.id ? "You" : activity.handle}
                          </div>
                          <div
                            className={`${activity.user_id !== currentUser.id ? styles.receivedMessage : styles.sentMessage}`}
                          >
                            {activity.description}
                          </div>
                          <div className={styles.createdAt}>{moment(activity.created_at).format("lll")}</div>
                        </Col>
                        {activity.user_id === currentUser.id && (
                          <Col sm={1} className={styles.colMaxWidth}>
                            <img alt="img" className={styles.chatBoxUserAvatar} src={avatar} />
                          </Col>
                        )}
                      </Row>
                    </div>
                  )
                );
              })}
          </div>
          <div className={styles.messagePanelFooter}>
            <input
              type="text"
              className={styles.replyBox}
              placeholder="Write a reply"
              value={reply}
              onChange={(event) => handleOnChange({ content: event.target.value })}
            />
            <button type="button" className={`btn btn-secondary ${styles.btnSendReply}`} onClick={handleSubmit}>
              Send
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default ChatBox;
