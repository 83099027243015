import React, { useCallback, useEffect, useRef, useState } from "react";
import { BudgetPickerPropsTypes } from ".";
import { Form } from "react-bootstrap";
import { Mandatory } from "components/forms/bootstrapFields";
import Select from "react-select";
import { findSelectedSingle, onBlurSingle, onChangeSingle, parseForSelect } from "services/general/helpers";
import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";
import { companyDateFormat } from "services/general/dateSvc";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";

interface SingleBudgetPickerPropTypes extends BudgetPickerPropsTypes {
  input: any;
}

export interface BudgetListOptionsType {
  id?: number;
  label?: string | null;
  amount?: number | null;
  balance?: number | null;
  effective_date?: string;
  end_date?: string;
  status?: string;
  _destroy?: number;
  budget_item_id?: number;
  currency: { symbol: string; iso_code: string };
  budget?: {
    id?: number;
    code?: string;
    currency_code?: string;
    start_date?: string;
    end_date?: string;
    label?: string;
  };
}

const SingleBudgetPicker = ({
  label,
  required,
  meta: { touched, error },
  tooltip,
  menuPosition,
  placeholder,
  instanceId = "single-budget-picker",
  input,
  modelData,
  parentObjData,
}: SingleBudgetPickerPropTypes) => {
  const [budgets, setBudgets] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const allowBudgetMultiCurrency = () => {
    return currentUser?.company?.budget?.allow_multi_currency_budget;
  };

  const allowBudgetFilter = () => {
    return currentUser?.company?.budget?.show_field_at === "line_level";
  };

  const getBudgets = useCallback(async () => {
    let apiParams: any = { active: true, items: 150 };

    if (modelData?.account_id && allowBudgetFilter()) {
      apiParams.account_id = modelData?.account_id;
    }

    if (modelData?.department_id) {
      apiParams.department_id = modelData?.department_id;
    }

    if (parentObjData?.currency_code && !allowBudgetMultiCurrency()) {
      apiParams.currency_code = parentObjData?.currency_code;
    }

    if (parentObjData?.vendor_id && allowBudgetMultiCurrency()) {
      apiParams.vendor_id = parentObjData?.vendor_id;
    }

    const result: AxiosResponse<BudgetListOptionsType[]> = await restApiService.get(
      "budgets.lk",
      apiParams,
      null,
      true,
      null,
      true,
    );
    if (result && result.data) {
      let list = parseForSelect(result.data);
      if (!required) {
        list = [
          {
            label: "-- Search and Select --",
            value: "",
          },
          ...list,
        ];
      }
      setBudgets(list);
    }
  }, [modelData?.account_id, modelData?.department_id, parentObjData?.currency_code, parentObjData?.vendor_id]);

  useEffect(() => {
    if (modelData?.department_id) {
      getBudgets();
    }
  }, [modelData?.account_id, modelData?.department_id, parentObjData?.currency_code, parentObjData?.vendor_id]);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        value={findSelectedSingle(input, budgets)}
        placeholder={placeholder}
        onChange={(value) => onChangeSingle(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={budgets}
        isClearable={!required}
        classNamePrefix="select"
        menuPosition={menuPosition}
        onMenuOpen={() => setIsDropdownOpen(true)}
        onMenuClose={() => setIsDropdownOpen(false)}
        instanceId={instanceId}
        getOptionLabel={(option: any) => {
          const currencySymbol = currencySymbolRenderer(option.currency_code);
          return (
            <small>
              <>
                <span>{option.label} </span>
                {!isDropdownOpen && option.budget_balance && (
                  <span>{`Balance: ${currencySymbol}${option.budget_balance}`}</span>
                )}
                <br />
                {isDropdownOpen && option.value !== "" && (
                  <>
                    <span>{`Code: ${option.code}`}</span>
                    <br />
                    <span>
                      {`Amount: ${currencySymbol}${option.amount}`} |{" "}
                      {`Balance: ${currencySymbol}${option.budget_balance}`}
                    </span>
                    <br />
                    <span>{`Allocated: ${currencySymbol}${option.budget_allocated} / Status: ${option.status}`}</span>
                    <br />
                    <span>{`Start Date: ${companyDateFormat(option.start_date, currentUser)}`}</span>
                    <br />
                    <span>{`End Date: ${companyDateFormat(option.end_date, currentUser)}`}</span>
                    <br />
                    <span>{option.country}</span>
                  </>
                )}
              </>
            </small>
          );
        }}
      />

      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default SingleBudgetPicker;
