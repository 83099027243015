import { DocumentRequirementsType } from "../../vp/purchaser/purchaserTypes";
import { DocumentTypes } from "../documents/documentTypes";
import { IDType } from "../types/common.type";
import VendorDocumentLinkApis from "./vendorDocumentLinkApis";

class VendorDocumentLinkService {
  linkDocumentWithVendorDocumentRequirement = async (
    {
      document,
      documentRequirement,
      vendorId,
    }: {
      document: DocumentTypes.AddResponse;
      documentRequirement: DocumentRequirementsType;
      vendorId: IDType;
    },
    endPoint: string,
  ) => {
    try {
      await VendorDocumentLinkApis.createVendorDocumentLink(
        {
          document_vendor_link: {
            document_id: document.id,
            document_requirement_id: documentRequirement.id,
            document_type: documentRequirement.document_type,
            vendor_id: vendorId,
          },
        },
        endPoint,
      );
    } catch (error) {
      throw error;
    }
  };
}
const vendorDocumentLinkService = new VendorDocumentLinkService();
export default vendorDocumentLinkService;
