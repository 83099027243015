import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { international_payment_countries, international_wire_payment_countries } from "../../../../app.svc.Lookup";
import Select from "react-select";
import { MultiValue, SingleValue } from "react-select";
import _, { isPlainObject } from "lodash";

export type CountryCurrencyCodePickerProps = {
  label?: string;
  // TODO: Add reduxForm input Type
  input: any;
  filter: any;
  required: boolean;
  tooltip: ReactNode;
  placeholder: string;
  isMulti: boolean;
  code: "code" | "code2";
  returnObject: boolean;
  defaultCountryOption: any;
  paymentType: string;
  className: string;
  isClearable: boolean;
  menuPosition?: any;
  inputId?: string;
  error?: string;
  hideCurrencycode?: boolean;
};

export type CountryCurrencyCodeOptionType = {
  value?: string;
  label?: string;
  currency_code?: string;
  country_code?: string;
  isChangeFromOwn?: boolean;
  flagImage?: string;
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const CountryCurrencyCodePicker = ({
  label,
  input,
  className,
  paymentType,
  code,
  returnObject,
  defaultCountryOption,
  required,
  placeholder,
  tooltip,
  isMulti,
  isClearable,
  menuPosition,
  hideCurrencycode,
  inputId,
  error,
}: CountryCurrencyCodePickerProps) => {
  const [paymentCountryOptions, setPaymentCountryOptions] = useState<Array<CountryCurrencyCodeOptionType>>([]);

  const internationalPaymentCountriesOptions = useMemo(() => {
    const filteredCountries = international_payment_countries
      .filter((value) => {
        if (paymentType) {
          // Check if paymentType is found in value.payment_types
          return _.isArray(value?.payment_types) && value?.payment_types.includes(paymentType);
        }
        // If paymentType is not defined, include all items
        return true;
      })
      .map((item, i) => ({
        code: item.code,
        code2: item.code2,
        currency_code: item.currency_code,
        country_code: code === "code" ? item.code : item.code2,
        value: (code === "code" ? item.code : item.code2) + item.name,
        label: `${item?.currency_code} - ${item.name}`,
        flagImage: require(`../../../../../assets/flags/${item.flag}`),
      }));

    return filteredCountries;
  }, [paymentType, code]);

  const internationalWirePaymentCountriesOptions = useMemo(
    () =>
      international_wire_payment_countries
        .filter((value) => Boolean(value))
        .map((item) => ({
          currency_code: item?.currency_code,
          country_code: code === "code" ? item.code : item.code2,
          value: code === "code" ? item.code : item.code2,
          label: `${!hideCurrencycode ? item?.currency_code + " - " + item.name : item.name}`,
          flagImage: require(`../../../../../assets/flags/${item.flag}`),
        })),
    [],
  );

  const getPaymentCountryOptions = () => {
    if (paymentType === "wire") {
      setPaymentCountryOptions(internationalWirePaymentCountriesOptions);
    } else {
      setPaymentCountryOptions(internationalPaymentCountriesOptions);
    }
  };

  useEffect(() => {
    getPaymentCountryOptions();
  }, []);

  const findSelectedSingle = (): SingleValue<CountryCurrencyCodeOptionType> | void => {
    if (returnObject) {
      const selectedOption = paymentCountryOptions.find(
        (paymentCountryOption) =>
          paymentCountryOption.country_code === input?.value?.country_code &&
          paymentCountryOption.currency_code === input?.value?.currency_code,
      );

      // This code manages the behavior of the Cambridge payment method:
      // * When user selects a destination country, the bank country is auto-selected.
      // * If the option is manually changed from the dropdown, it reflects the selected option.
      if (defaultCountryOption) {
        return input?.value ? input?.value : defaultCountryOption;
      }

      return selectedOption;
    } else {
      const selectedOption = paymentCountryOptions.find(
        (paymentCountryOption) => paymentCountryOption.country_code === input.value,
      );

      return selectedOption;
    }
  };

  const findSelectedMultiple = (): MultiValue<CountryCurrencyCodeOptionType> | void => {
    if (returnObject) {
      const selectedValues: CountryCurrencyCodeOptionType[] = [];
      if (_.isArray(input.value)) {
        input.value.forEach((element: Array<Omit<CountryCurrencyCodeOptionType, "value" | "label">>) => {
          let obj;
          if (_.isPlainObject(element)) {
            obj = _.find(
              paymentCountryOptions,
              (paymentCountryOption) =>
                paymentCountryOption.country_code === input?.value?.country_code &&
                paymentCountryOption.currency_code === input?.value?.currency_code,
            );
          }
          if (obj) selectedValues.push(obj);
        });
      }
      return selectedValues;
    } else {
      let selectedValues: CountryCurrencyCodeOptionType[] = [];
      if (typeof (input.value === String)) {
        paymentCountryOptions.forEach((option) => {
          if (input.value === option.value) {
            selectedValues.push(option);
          }
        });
      }

      if (isPlainObject(input.value)) {
        selectedValues = [input.value];
      }

      if (_.isArray(input.value)) {
        input.value.forEach((element: string) => {
          let obj;
          if (_.isPlainObject(element)) {
            obj = _.find(paymentCountryOptions, (option) => option.value === element);
          }
          if (obj) selectedValues.push(obj);
        });
      }

      return selectedValues;
    }
  };

  const onChangeSingle = (selectedOption: SingleValue<CountryCurrencyCodeOptionType>) => {
    if (returnObject) {
      input.onChange(selectedOption);
    } else {
      input.onChange(selectedOption?.country_code);
    }
  };

  const onChangeMultiple = (selectedOptions: MultiValue<CountryCurrencyCodeOptionType>) => {
    if (returnObject) {
      input.onChange(selectedOptions);
    } else {
      const countryCodes = selectedOptions.map((selectedOption) => selectedOption.country_code);
      input.onChange(countryCodes);
    }
  };

  const formatOptionLabel = (selectedOption: CountryCurrencyCodeOptionType) => (
    <div>
      <img src={selectedOption.flagImage} height="30px" width="30px" style={{ padding: 5 }} alt="flag" />{" "}
      {selectedOption.label}
    </div>
  );

  if (isMulti) {
    return (
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? null}
            <Mandatory required={required} />
            {tooltip ?? null}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={findSelectedMultiple()}
          onChange={(selectedOption: MultiValue<CountryCurrencyCodeOptionType>) => {
            onChangeMultiple(selectedOption);
          }}
          onBlur={() => {
            input.onBlur(input.value);
          }}
          isMulti={true}
          placeholder={placeholder ?? ""}
          options={paymentCountryOptions}
          isClearable={isClearable}
          menuPosition={menuPosition ? menuPosition : null}
          inputId={inputId ? inputId : "payment-country-selector"}
        />
        {error && <div className="error">{error}</div>}
      </Form.Group>
    );
  }

  if (!isMulti) {
    return (
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? null}
            <Mandatory required={required} />
            {tooltip ?? null}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={findSelectedSingle()}
          onChange={(selectedOption: CountryCurrencyCodeOptionType) => {
            onChangeSingle(selectedOption);
          }}
          onBlur={() => {
            input.onBlur(input.value);
          }}
          isMulti={false}
          placeholder={placeholder ?? ""}
          options={paymentCountryOptions}
          isClearable={isClearable}
          menuPosition={menuPosition ? menuPosition : null}
          inputId={inputId ? inputId : "payment-country-selector"}
          formatOptionLabel={formatOptionLabel}
        />
        {error && <div className="error">{error}</div>}
      </Form.Group>
    );
  }
};

export default CountryCurrencyCodePicker;
