import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import BankCodeValidator from "../../bankCodeValidator";
import { RenderField } from "../../../../forms/bootstrapFields";
import { required } from "../../../../../services/validations/reduxFormValidation";
import AccountType from "../../../managePaymentMethod/fields/accountType";
import BankAccountNumbers from "../../bankAccountNumbers";

const TwJpy = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col md="4">
        <BankCodeValidator modelName={`${modelName}`} label={t("common.paymentMethod.bankCode")} />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.branchCode`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.branchCode")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="4">
        <AccountType
          label="Account Type:"
          required
          validations={[required]}
          options={[
            { label: "Checking", value: "checking" },
            { label: "Saving", value: "saving" },
          ]}
        />
      </Col>
      <BankAccountNumbers col={4} modelName={`${modelName}`} />{" "}
    </>
  );
};

export default TwJpy;
