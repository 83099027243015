import React from "react";
import { useTranslation } from "react-i18next";
import style from "./statusObjectWithIcon.module.css";

// Generates large cssClassed text string with appropriate icon next to it

const ObjectStatusWithIcon: React.FC<{ statusText?: string; size?: string }> = ({ statusText, size = "large" }) => {
  const { t } = useTranslation();

  let cssClass: string;

  switch (statusText?.toLowerCase()) {
    case "draft":
      cssClass = "status_draft";
      break;
    case "pending approval":
    case "pending":
      cssClass = "status_new";
      break;
    case "active":
    case "approved":
    case "open":
    case "processing":
    case "cleared":
    case "new":
    case "paid":
      cssClass = "status_success";
      break;
    case "inactive":
    case "rejected":
    case "failed":
    case "void":
      cssClass = "status_rejected";
      break;
    default:
      cssClass = "status_success";
  }

  const statusCssClassName: string = style[cssClass];
  const iconCssClassName: string = style[`page_header_icon_${size.toLowerCase()}`];
  const labelCssClassName: string = style[`page_header_label_${size.toLowerCase()}`];

  return (
    <>
      <div className={`${style.page_header} ${statusCssClassName}`}>
        <i className={iconCssClassName}></i>
        <span className={labelCssClassName}>{t(`status.${statusText?.toLowerCase()}`).toLocaleUpperCase()}</span>
      </div>
    </>
  );
};

export default ObjectStatusWithIcon;
