import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";

const AddressExternalIdField = ({ label, isAddressExternalIdRequired, validations }) => {
  return (
    <Col md="6">
      <Field
        id="companyPaymentMethod.address_external_id"
        name="form.address_external_id"
        component={RenderField}
        type="text"
        label={label}
        required={isAddressExternalIdRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default AddressExternalIdField;
