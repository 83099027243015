import { Field } from "redux-form";
import { renderField } from "../forms/fields";
import React, { Component } from "react";
import styles from "../../pages/expenseItems/admin/ExpenseItem.module.css";

class PerDiem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let per_diems = this.props.state.per_diems;
    let expense_item = this.props.ExpenseItem;
    return (
      <div>
        <div className="row mt-15">
          <div className="col-md-4 prt-5">
            <Field
              value="qty"
              name="qty"
              label="Quantity"
              customStyle={{ padding: "5px" }}
              component={renderField}
              type="number"
            />
          </div>
          <div className="col-md-4 plr-5">
            <label>Per Diem Item</label>
            <br />
            <Field className={"w-100"} name="per_diem_id" component="select" style={{ padding: "8px" }} required={true}>
              <option value="">-- Select Per Diem --</option>
              {per_diems.map((per_diem) => (
                <option value={per_diem.id} key={per_diem.id}>
                  {per_diem.name}
                </option>
              ))}
            </Field>
          </div>
          <div className="col-md-4 plt-5" style={{ paddingTop: 35, fontSize: 14 }}>
            {expense_item && (
              <label className={styles.functionalCurrencyWarning}>
                at {expense_item.currency_code} {expense_item.unit_price} each
              </label>
            )}
          </div>
        </div>
        <div className="row mt-15">
          <div className="col-md-4 prt-5">
            <label>Date</label>
            <br />
            <Field className="dateField" name="date" component="input" type="date" />
          </div>
          <div className="col-md-4 plt-5">
            <Field value="amount" name="amount" label="Total" component={renderField} type="number" required={true} />
          </div>
        </div>
      </div>
    );
  }
}
export default PerDiem;
