import { GetRowIdParams, GridApi, GridOptions, TreeDataDisplayType } from "ag-grid-community";
import _ from "lodash";
import moment from "moment";
import RestApi from "../../../providers/restApi";
import { CreateNotification, NotificationType } from "../../general/notifications";

/** inbound types start here */
export interface IEmail {
  date: string;
  cc: string[];
  bcc: string[];
  attachments: [
    {
      filename: string;
      size: string;
      content_type: string;
      key: string;
    },
  ];
  data: [
    {
      number: string;
      filename: string;
      id: number;
      type: string;
    },
  ];
  company_id: number;
  subject_downcase: string;
  subject: string;
  created_at: string;
  company_id_status: string;
  message_id: string;
  source: string;
  mailing_list: string;
  updated_at: string;
  date_formatted: string;
  profile_id: number;
  received_at: string;
  charsets: string;
  from: {
    host: string;
    name: string;
    email: string;
    token: string;
    full: string;
  };
  id: string;
  to: [
    {
      host: string;
      name: string;
      email: string;
      token: string;
      full: string;
    },
  ];
  logs: [
    {
      filename: string;
      created_at: string;
      type: string;
      message: string;
    },
  ];
  document_type: string;
  status: string;
}

export type searchStateType = {
  [key: string]: any;
  end_date: string;
  start_date: string;
  status: string;
  subject: string;
  body: string;
  from: string;
  message_id: string;
};

export type nextPreviousIndexType = {
  nextRowIndex: number | null;
  previousRowIndex: number | null;
  activeRowIndex: number | null;
};

/** Email inbound types end here */

// common grid Api for both list and grid view.
export const GRID_VIEW = "gridView";
export const LIST_VIEW = "listView";

export const getRowNodeId = (params: GetRowIdParams) => params?.data?.id;

// export const gridOptions: GridOptions<any> = { // not in use any where
//   getRowNodeId: getRowNodeId
// };

export const gridApi: GridApi = new GridApi();

export const getStatusParams = (payload: string) => {
  switch (payload) {
    case "Inbox":
      return {};
    case "New":
      return { status: payload };
    case "Failed":
      return { status: payload };
    case "Processed":
      return { status: payload };
    case "Completed":
      return { status: payload };
    case "Sent":
      return { status: payload };
    case "On Hold":
      return { tags: payload.toLowerCase() };
    case "Communication":
      return { tags: payload.toLowerCase() };
    case "No Attachment":
      return { tags: "No-Attachment" };
    default:
      return { tags: payload };
  }
};

export const emailStatus = ["Processed", "New", "Failed", "Completed", "Sent", "Deleted"];

export const parseSearchParams = (searchData: searchStateType) => {
  const searchKeys: Partial<searchStateType> = _.cloneDeep(searchData);
  if (searchKeys.dateRanges) {
    if (searchKeys.dateRanges !== "ALL") {
      const splitValue = searchKeys.dateRanges.split("-");
      searchKeys.end_date = moment().add(1, "days").format("YYYY-MM-DD"); //end date will always be today
      searchKeys.start_date = moment()
        .subtract(splitValue[0], splitValue[1] as moment.unitOfTime.DurationConstructor)
        .format("YYYY-MM-DD");
    }

    //remove date range from our request
    delete searchKeys.dateRanges;
  }

  Object.keys(searchKeys).forEach((key) => {
    if (!searchKeys[key]) delete searchKeys[key];
  });

  return searchKeys;
};

// send search params for inbound_email as per the page provided
export const getSearchParams = (payload: string) => {
  switch (payload) {
    case "Inbox":
      return "";
    case "New":
      return { status: payload };
    case "Failed":
      return { status: payload };
    case "Processed":
      return { status: payload };
    case "Completed":
      return { status: payload };
    case "Sent":
      return { status: payload };
    case "On Hold":
      return { tags: payload.toLowerCase() };
    case "Communication":
      return { tags: payload.toLowerCase() };
    case "No Attachment":
      return { tags: "No-Attachment" };
    default:
      return { tags: payload };
  }
};

export type emailTagsOptionType = { option: string; label?: string; highlightRow?: boolean };

export const emailTagsOptions = [
  { option: "communication" },
  { option: "SPAM" },
  { option: "on hold" },
  { option: "Duplicate" },
] as Array<emailTagsOptionType>;

export const selectFirstEmail = async ({ gridApi }: { gridApi: GridApi }) => {
  selectEmailAtIndex({ gridApi: gridApi, indexToBeSelected: 0 });
};

export const selectEmailAtIndex = async ({
  gridApi,
  indexToBeSelected,
}: {
  gridApi: GridApi;
  indexToBeSelected: number;
}) => {
  gridApi.forEachNode(function (node, index) {
    if (index === indexToBeSelected) {
      node.setSelected(true);
    }
  });
};

export const checkNameWithEmail = (from: any) => {
  if (from && from.full) {
    return from.full;
  } else if (from && from.email) {
    let userName = from.email.substring(0, from.email.lastIndexOf("@"));
    return _.upperFirst(userName) + " <" + from.email + ">";
  } else return null;
};

export const checkName = (from: any) => {
  if (from && from.name) {
    return from.name;
  } else if (from && from.email) {
    let userName = from.email.substring(0, from.email.lastIndexOf("@"));
    return _.upperFirst(userName);
  } else return null;
};

export const checkHostName = (from: any) => {
  if (from && from.host) {
    return from.host;
  } else if (from && from.email) {
    let domain = from.email.substring(from.email.lastIndexOf("@") + 1);
    return domain;
  } else return null;
};

const restApiService = new RestApi();

export const reprocessEmailPromise = (id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await restApiService.post("inbound_emails/" + id + "/reprocess");
      CreateNotification("Success", `${id} Email reprocess successfully`, NotificationType.success);
      resolve(response);
    } catch (error: any) {
      reject(error);
      CreateNotification("Error", error.message, NotificationType.danger);
    }
  });
};

export const markAsCompletedPromise = (id: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = {
        inbound_email: {
          status: "Completed",
        },
      };
      const response = await restApiService.patch("inbound_emails/" + id, null, data);
      CreateNotification("Success", `${id} Email stutus updated successfully`, NotificationType.success);
      resolve(response);
    } catch (error: any) {
      reject(error);
      CreateNotification("Error", error.message, NotificationType.danger);
    }
  });
};
