import BankingInfo from "./details";

const vpBankingInfoRoutes = [
  {
    path: "/ar/banking_information",
    exact: true,
    name: "bankingInfo",
    component: BankingInfo,
    authorization: {
      I: "edit",
      a: "Company",
    },
  },
];

export { vpBankingInfoRoutes };
