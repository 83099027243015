import React from "react";
import { STANDARD_REPORT_OPTIONS } from "services/admin/reports/reportSvc";
import ListDataExtracts from "../../../../../components/admin/reports/standardReports/listReports";

const List = () => (
  <ListDataExtracts
    classification="Standard"
    createLabel="Report"
    filterName="showReportFilters"
    options={STANDARD_REPORT_OPTIONS}
  />
);
export default List;
