import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RestApi from "../../../providers/restApi";

const linkedAccountLine = (entry, type) => {
  // entry.account_type === ''
  return (
    <tr key={entry.id}>
      <td>{type}</td>
      <td>{entry.account_name}</td>
      <td>{entry.amount}</td>
      <td>{entry.department_name}</td>
      <td>{entry.business_unit_name}</td>
      <td>{entry.location_name}</td>
      <td>{entry.for_project_name}</td>
      <td>{entry.for_business_unit_name}</td>
      <td>{entry.for_subsidiary_name}</td>
      <td>{entry.secondary_category_name}</td>
      <td>{entry.memo}</td>
    </tr>
  );
};

const LinkedAccounts = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      {(props.debit_entries && props.debit_entries.length > 0) ||
      (props.credit_entries && props.credit_entries.length > 0) ? (
        <Table striped bordered hover className="mb-0">
          <thead>
            <tr>
              <th className="primaryFontColor">{t("details.accountType")}</th>
              <th className="primaryFontColor">{t("details.account")}</th>
              <th className="primaryFontColor">{t("details.amount")}</th>
              <th className="primaryFontColor">{t("details.department")}</th>
              <th className="primaryFontColor">{t("details.businessUnit")}</th>
              <th className="primaryFontColor">{t("details.location")}</th>
              <th className="primaryFontColor">{t("details.project")}</th>
              <th className="primaryFontColor">{t("details.forBusinessUnit")}</th>
              <th className="primaryFontColor">{t("details.forSubsidiary")}</th>
              <th className="primaryFontColor">{t("details.secondaryCategory")}</th>
              <th className="primaryFontColor">{t("details.memo")}</th>
            </tr>
          </thead>
          <tbody>
            {props.debit_entries.map((debit_entry) => {
              return linkedAccountLine(debit_entry, "Debit Account");
            })}
            {props.credit_entries.map((credit_entry) => {
              return linkedAccountLine(credit_entry, "Credit Account");
            })}
          </tbody>
        </Table>
      ) : (
        <h5 className="text-center mt-3 mb-3">- {t("warnings.noLinkedInvoices")} -</h5>
      )}
    </div>
  );
};

export default LinkedAccounts;
