import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { FormSubmitHandler } from "redux-form";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import commonService from "services/common/commonSvc";
import { CreateNotification, NotificationType } from "services/general/notifications";
import SplitExpenseCoding from "../components/splitExpenseCoding";

// this coding

const EditExpenseSplitCoding = () => {
  const history = useHistory();
  const [expenseItem, setExpenseItem] = useState<ExpensesTypes.Details>();
  const { id: expenseItemId } = useParams<{ id: string }>();
  const isConversionActive = expenseItem && expenseItemCommonSvc.isConversionActive(expenseItem);

  const getExpenseItem = async () => {
    try {
      if (expenseItemId) {
        const result = await ExpensesApis.getExpense(expenseItemId);
        // check if using split expense
        if (
          Array.isArray(result.expense_report_debit_accounts) &&
          result.expense_report_debit_accounts.length > 0 &&
          result.total
        ) {
          result.expense_report_debit_accounts_attributes = result.expense_report_debit_accounts;
        } else {
          const debitAccount = expenseItemCommonSvc.inheritAllocationFromExpenseItemHeader({ expenseItem: result });
          result.expense_report_debit_accounts_attributes = [debitAccount];
        }
        setExpenseItem(result);
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  const onSaveSplitExpense: FormSubmitHandler<ExpensesTypes.SplitExpenseCodingFormData, any, any> = async ({
    expense_report_debit_accounts_attributes,
  }) => {
    try {
      if (expenseItem?.id && expenseItem?.expense_report_id) {
        expenseItem.expense_report_debit_accounts_attributes = expense_report_debit_accounts_attributes;
        const result = await ExpenseReportApis.editExpenseReport(expenseItem?.expense_report_id, {
          expense_report: {
            expense_items: [expenseItem],
          },
        });
        CreateNotification("Updated", "Expense Item Split Coding updated", NotificationType.success);
        history.goBack();
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  const closeSplitCoding = () => {
    history.goBack();
  };

  useEffect(() => {
    getExpenseItem();
  }, []);

  if (!expenseItem) return null;

  return (
    <>
      {expenseItem?.expense_report_debit_accounts_attributes && (
        <SplitExpenseCoding
          initialValues={{
            expenseItem,
            total: expenseItem?.total,
            subsidiary_id: expenseItem?.subsidiary_id,
            policy_id: expenseItem?.policy_id,
            currency_code: expenseItem.currency_code,
            base_currency_code: expenseItem.base_currency_code,
            base_currency_total: expenseItem.base_currency_total,
            currency_rate: expenseItem?.currency_rate,
            expense_report_debit_accounts_attributes:
              expenseItemCommonSvc.prepareExpenseReportDebitAccountsForSplitCodingForm({
                modalDebitAccountsAttributes: expenseItem.expense_report_debit_accounts_attributes,
                expenseItem,
              }),
            currency_conversion_active: isConversionActive,
            subsidiary: expenseItem.subsidiary,
            policy: expenseItem.policy,
          }}
          showSplitExpense={true}
          onSubmit={onSaveSplitExpense}
          cancelCallback={closeSplitCoding}
        />
      )}
    </>
  );
};

export default EditExpenseSplitCoding;
