import React from "react";
import { Field, FieldArray, FieldArrayFieldsProps, change, getFormValues } from "redux-form";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RenderDatePicker, RenderSelect } from "../../forms/bootstrapFields";
import ContactPicker from "../../pickers/reduxFormPicker/contactPicker";
import { required } from "../../forms/inputFields";
import { useTypedSelector } from "../../../reducers";
import { useDispatch } from "react-redux";
import { DelegationType } from "../../../services/common/contact/contactTypes";
import _ from "lodash";

type DelegationRowType = {
  fields: FieldArrayFieldsProps<DelegationType>;
  formFieldName: string;
  formName: string;
};

const DelegationRow = ({ fields, formFieldName, formName }: DelegationRowType) => {
  const delegates = _.isArray(fields.getAll()) ? fields.getAll() : [];
  const notDistroyedDelegates = delegates.filter((delegate) => !delegate._destroy);
  const { t } = useTranslation();
  const formData: any = useTypedSelector((state) => getFormValues(formName)(state));
  const delegateFields: DelegationType[] = _.get(formData, formFieldName);
  const dispatch = useDispatch();
  const onDeleteDelegate = (deleteIndex: number) => {
    if (_.isArray(delegateFields)) {
      let destroyedDelegate = delegateFields
        .map((delegateLink: DelegationType, index: number) => {
          if (index === deleteIndex && delegateLink.id) {
            delegateLink._destroy = 1;
          } else if (index === deleteIndex && !delegateLink.id) {
            return null;
          }
          return delegateLink;
        })
        .filter((value) => value);

      dispatch(change(formName, formFieldName, destroyedDelegate));
    }
  };

  const getDelegateTypeOptions = () => {
    const defaultDelegateTypeOptions = [
      { label: t("components.admin.userDelegation.selectOne"), value: "" },
      {
        label: t("components.admin.userDelegation.all"),
        value: "All",
        tooltip: "",
        disabled: false,
      },
      {
        label: t("components.admin.userDelegation.approval"),
        value: "Approval",
        tooltip: "",
        disabled: false,
      },
      {
        label: t("components.admin.userDelegation.card"),
        value: "Card",
        tooltip: "",
        disabled: false,
      },
      {
        label: t("components.admin.userDelegation.changeRequests"),
        value: "ChangeRequests",
        tooltip: "",
        disabled: false,
      },
      {
        label: t("components.admin.userDelegation.expense"),
        value: "Expense",
        tooltip: "",
        disabled: false,
      },
    ];

    const usedDelegateTypeOptions = notDistroyedDelegates.map((delegate) => delegate.delegate_type);

    const delegateTypeOptions = defaultDelegateTypeOptions.map((delegate) => {
      const filteredRecords = _.filter(usedDelegateTypeOptions, (record) => record === delegate.value);
      return delegate;
    });
    return delegateTypeOptions;
  };

  return (
    <>
      <Row className="mb-2">
        <Col sm="11">
          <Row>
            <Col md="3">{t("components.admin.userDelegation.assignedDelegate")}</Col>
            <Col md="3">{t("components.admin.userDelegation.delegateType")}</Col>
            <Col md="3">{t("components.admin.userDelegation.startDate")}</Col>
            <Col md="3">{t("components.admin.userDelegation.endDate")}</Col>
          </Row>
        </Col>
      </Row>

      {fields.map((delegtion, index) => {
        const currentDelegate = fields.get(index);

        if (currentDelegate._destroy) {
          return null;
        }
        return (
          <Row key={delegtion}>
            <Col sm="11">
              <Row>
                <Col md="3">
                  <Field
                    id={"delegate_id"}
                    name={`${delegtion}.delegate_id`}
                    component={ContactPicker}
                    contactType="USERS"
                    required
                    validate={[required]}
                    isClearable
                  />
                </Col>
                <Col md="3">
                  <Field
                    id={"delegate_type"}
                    name={`${delegtion}.delegate_type`}
                    component={RenderSelect}
                    options={getDelegateTypeOptions()}
                    required
                    validate={[required]}
                    isClearable
                  />
                </Col>

                <Col md="3">
                  <Field
                    id={"delegate_start_date"}
                    name={`${delegtion}.start_date`}
                    component={RenderDatePicker}
                    placeholder={t("components.admin.userDelegation.startDataPlaceholder")}
                  />
                </Col>
                <Col md="3">
                  <Field
                    id={"delegate_end_date"}
                    name={`${delegtion}.end_date`}
                    placeholder={t("components.admin.userDelegation.endDataPlaceholder")}
                    component={RenderDatePicker}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col md="1">
                  <Button onClick={() => onDeleteDelegate(index)} className="bg-transparent border-0 m-0">
                    <i className="icon icon-delete m-0" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      })}
      <Col>
        <Row>
          {
            <Button
              variant="secondary"
              size="sm"
              className="px-5"
              onClick={() =>
                fields.push({
                  delegate_id: 0,
                  delegator_id: 0,
                  delegate_type: "",
                  start_date: "",
                  end_date: "",
                })
              }
            >
              <i className="icon icon-plus-black m-0 mx-2" /> {t("components.admin.userDelegation.delegate")}
            </Button>
          }
        </Row>
      </Col>
    </>
  );
};

const DelegationFields = ({ formFieldName, formName }: { formFieldName: string; formName: string }) => {
  return (
    <Col>
      <FieldArray
        name={`${formFieldName}`}
        formFieldName={formFieldName}
        formName={formName}
        component={DelegationRow}
      />
    </Col>
  );
};

export default DelegationFields;
