import React from "react";
import "./datagrid.style.css";
import { withRouter } from "react-router-dom";

class ContactArrayRenderer extends React.Component {
  render() {
    let needsComma = this.props.value && this.props.value.length > 1;
    return (
      <div>
        {this.props.value &&
          this.props.value.map((contact, index) => {
            return (
              <a key={contact.id}>
                {contact.name}
                {needsComma && index != this.props.value.length - 1 ? ", " : ""}
              </a>
            );
          })}
      </div>
    );
  }
}

export default withRouter(ContactArrayRenderer);
