import _ from "lodash";
import { SET_COMPANY_DEFAULT, SET_TAB, GET_TERMS } from "../../actions/actionTypes";
import RestApi from "../../providers/restApi";

const GENERAL = "general";
const restApiService = new RestApi();

const initialState = {
  // keeping it undefined intensionally
  // it will update from reducer SET_COMPANY_DEFAULT
  // form components will render only after setting from api
  companyDefault: undefined,
  terms: [],
  tab: GENERAL,
};

const companyDefaultReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_DEFAULT:
      return { ...state, companyDefault: action.payload };
    case GET_TERMS:
      return { ...state, terms: action.payload };
    default:
      return state;
  }
};

export default companyDefaultReducer;

// ACTIOINS CREATOR //

export const setCompanyDefault = (companyDefault) => (dispatch) => {
  dispatch({ type: SET_COMPANY_DEFAULT, payload: companyDefault });
};

//  $scope.get_terms = function () {
//                                     Restangular.all('terms.lk').withHttpConfig({ cache: true }).getList({ACTIVE:'YES'}).then(function (response) {
//                                         $scope.terms = response.plain();
//                                     });
//                                 }
export const getTerms = () => async (dispatch) => {
  try {
    const { data } = await restApiService.get("terms.lk", { ACTIVE: "YES" }, null, true, null, true);
    dispatch({ type: GET_TERMS, payload: data });
  } catch (error) {
    console.log("🚀 ~ file: companyDefaultReducer.js ~ line 46 ~ getTerms ~ error", error);
  }
};
