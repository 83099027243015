import React, { Fragment, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import style from "./../vendorDocumentUploadModal.module.css";
import _ from "lodash";
import { VDocumentUploadContext } from "../vendorDocumentUploadContext";

type TemplatePropsType = {};
const Template = ({}: TemplatePropsType) => {
  const context = useContext(VDocumentUploadContext);
  return (
    <Row>
      <Col>
        <Row>
          <Col className="d-flex justify-content-center">
            <div className={style.tempalteNote}>
              <p>
                Please choose one of the following templates to download, fill out the necessary details, and upload the
                completed document
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {_.isArray(context?.documentRequirement?.documents) && context?.documentRequirement?.documents.length ? (
              context?.documentRequirement?.documents?.map((document) => (
                <Fragment key={document.id}>
                  <div className={`d-flex justify-content-start`}>
                    <div className="d-flex align-items-center">
                      <i className={`${style.icon40} icon-template`} />
                      {document.name}
                      {_.isArray(document?.assets) &&
                        document?.assets[0] &&
                        document?.assets[0].asset_file_file_name && (
                          // eslint-disable-next-line react/jsx-no-target-blank
                          <a
                            href={document?.assets[0].asset_expiring_url}
                            download={document?.assets[0].asset_file_file_name}
                            target="_blank"
                          >
                            <i className="icon icon-download-blue-14 float-right m-0 mt-2 mx-2 border-0" />
                          </a>
                        )}
                    </div>
                  </div>
                </Fragment>
              ))
            ) : (
              <div>
                <p> Template Not Available</p>
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Template;
