import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField, RenderSelect } from "../../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import { countries } from "../../../../app.svc.Lookup";

const IntermediaryFields = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const bankCountries = [{ value: " ", label: " " }, ...countries];

  const showLabel = () => {
    return (
      <>
        <span>{t("common.paymentMethod.intermediaryBankName")}</span>{" "}
        <span style={{ fontSize: "12px" }}>{t("common.paymentMethod.ifApplicable")}</span>
      </>
    );
  };

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.intrmy.name`}
          component={RenderField}
          type="text"
          label={showLabel()}
          className={`w-100`}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.intrmy.country`}
          component={RenderSelect}
          options={bankCountries}
          label={t("common.paymentMethod.intermediaryBankCountry")}
          className={`w-100`}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.intrmy.number`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.intermediaryBankAccount")}
          tooltip={t("common.paymentMethod.ibanTooltip")}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.intrmy.bic`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.intermediaryBankBic")}
          tooltip={t("common.paymentMethod.bankCodeTooltip")}
        />
      </Col>
    </>
  );
};

export default IntermediaryFields;
