import React, { useEffect, useState } from "react";
import RestApi from "../../../../providers/restApi";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { formattedAmount } from "../../../../services/general/helpers";
import useIsMounted from "../../../common/hooks/useIsMounted";

const restApiService: RestApi = new RestApi();
interface IAccrauls {
  id: number;
  amount: number;
  status: string;
  date: string;
  purchase_order: { number: string } | null;
  currency: { iso_code: string } | null;
  number: number;
}

type propsType = {
  filter: { purchaser_id: string; limit: number; active: boolean };
};

const AccrualTable = ({ filter }: propsType) => {
  const [accruals, setAccruals] = useState<Array<IAccrauls>>([]);
  const isMounted = useIsMounted();

  const fetchAccrauls = async () => {
    try {
      const response = await restApiService.get("vendor_accruals", filter);
      if (isMounted) {
        setAccruals(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted) {
      fetchAccrauls();
    }
  }, []);

  return (
    <div>
      {accruals ? (
        <Table striped bordered hover className="mb-0">
          <thead>
            <tr>
              <th className="primaryFontColor">Number</th>
              <th className="primaryFontColor">Status</th>
              <th className="primaryFontColor">Date</th>
              <th className="primaryFontColor">PO Number</th>
              <th className="primaryFontColor">Amount</th>
            </tr>
          </thead>
          <tbody>
            {accruals.map((accrual) => {
              return (
                <tr key={accrual.id}>
                  <td>
                    <Link
                      className="primaryLinkColor"
                      to={"/ar/accruals/" + accrual.id}
                      data-toggle="tooltip"
                      title="Accrual"
                    >
                      {accrual.number}
                    </Link>
                  </td>
                  <td>{accrual.status}</td>
                  <td>{accrual.date}</td>
                  <td>{accrual.purchase_order?.number}</td>
                  <td>{formattedAmount(accrual.amount?.toString(), accrual.currency?.iso_code)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <h5 className="text-center mt-3 mb-3">Loading...</h5>
      )}
    </div>
  );
};

export default AccrualTable;
