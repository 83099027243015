import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Panel from "./../../../components/panel/panel";
import VendorContactForm, { ContactForm, contactFormData } from "../../../components/vp/contacts/vendorContactForm";
import useIsMounted from "./../../../components/common/hooks/useIsMounted";
import RestApi from "./../../../providers/restApi";
import { useDispatch } from "react-redux";
import { CreateNotification, NotificationType } from "./../../../services/general/notifications";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import _ from "lodash";
import { createCompleteError } from "./../../../services/general/reduxFormSvc";
import { SubmissionError } from "redux-form";
import { addOrRemoveVendorAdmin } from "./../../../services/vp/contactSvc";
import { resetVendorContactForm } from "../../../reducers/vp/vendorContactFormReducer";

const restApiService: RestApi = new RestApi();

const AddVendorContact = () => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const history = useHistory();
  const onHide = () => {
    history.goBack();
  };
  const { t } = useTranslation();

  const onSubmit = async (vendorContactFormData: contactFormData) => {
    try {
      const response = await restApiService.post("contacts", null, {
        contact: vendorContactFormData.form.contact,
      });
      await addOrRemoveVendorAdmin(vendorContactFormData.form.contact.vendor_admin, false, response.data.id);
      CreateNotification(t("success"), t("contactPage.saved"), NotificationType.success);
      history.push("/ar/contacts/" + response.data.id);
    } catch (error: any) {
      const { response } = error;
      if (response?.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          const formData: any = { contact: { ...completeErrorObj } };
          throw new SubmissionError({
            form: formData,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (isMounted) {
    }
    return () => {
      // Every time we leave vendor contact form shouls be reseted to initial state
      dispatch(resetVendorContactForm());
    };
  }, []);

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <Panel panelContent={{ heading: "ADD USER" }}>
        <VendorContactForm
          contactForm={<ContactForm />}
          onSubmit={onSubmit}
          footerButtons={
            <Row>
              <Col className="d-flex justify-content-end">
                <Button id="vendor_contact_cancel_btn" variant="secondary" onClick={() => onHide()} className="mx-2">
                  {t("cancel")}
                </Button>
                <Button type="submit" id="vendor_contact_submit_btn">
                  {t("submit")}
                </Button>
              </Col>
            </Row>
          }
        />
      </Panel>
    </Container>
  );
};

export default AddVendorContact;
