import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AccountNumberValidator } from "../../accountNumberValidator";

const TwNzd = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col md="6">
        <AccountNumberValidator
          label={t("common.paymentMethod.accountNumberLabel")}
          modelName={`${modelName}`}
          noValidate={true}
        />
      </Col>
    </>
  );
};

export default TwNzd;
