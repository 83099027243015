import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./login.css";
import RestApi from "../../providers/restApi";
import { withTranslation } from "react-i18next";
import { CreateNotification, NotificationType } from "../../services/general/notifications";
import Footer from "../../components/common/footer/footer";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      enabled: true,
      email: "",
    };
    this.restApiService = new RestApi();
  }

  handleSubmit = (event) => {
    let valid = event.currentTarget.checkValidity();
    event.preventDefault();
    event.stopPropagation();
    this.setState({ validated: true });
    if (!valid) {
      return;
    }

    this.restApiService
      .post("users/forgot_password", null, { email: this.state.email }, false)
      .then((result) => {
        CreateNotification(
          this.props.t("success"),
          this.props.t("resetPasswordPage.success"),
          NotificationType.success,
        );
        setTimeout(() => {
          this.props.history.push("/login");
        }, 2000);
      })
      .catch((error) => {
        console.error("error resetting password: ", error);
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning);
      });
  };

  checkEmail() {
    if (this.state.email.match("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}") != null) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <Container fluid={true} className="h-100 d-flex flex-column">
        <Row className="mt-4">
          <Col xs={12}>
            <div className="text-center">
              <img
                className="ForgotPasswordLoginLogo"
                src={require("../../assets/corpay_logo_complete.svg").default}
                alt="login"
              />
            </div>
          </Col>

          <Col className="m-auto" style={{ maxWidth: "500px" }}>
            <div className="text-center loginContainer">
              <div className="panel-title pt-2">
                <h3 className="mb-2">{this.props.t("resetPasswordPage.heading")}</h3>
              </div>
              <div className="p-4">
                <Form
                  noValidate
                  validated={this.state.validated}
                  className="formContainer"
                  onSubmit={this.handleSubmit}
                >
                  <Form.Group controlId="email">
                    <Form.Control
                      required
                      className="loginInput"
                      type="email"
                      placeholder="E-mail"
                      onChange={(event) => {
                        this.setState({ email: event.target.value });
                      }}
                      isInvalid={this.state.email && this.checkEmail() === false}
                      isValid={this.checkEmail() === true}
                      value={this.state.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.props.t("validation.validEmail")}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    className="btn btn-lg  btn-primary submitButton"
                    // variant="primary"
                    type="submit"
                    disabled={!this.state.enabled}
                  >
                    {this.props.t("submit")}
                  </Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row className="mt-auto">
          <Col md="12">
            <Footer/>
          </Col>
        </Row> */}
      </Container>
    );
  }
}

export default withTranslation()(ForgotPassword);
