import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "reducers";
import { IDType } from "services/common/types/common.type";

type MessagePanelSliceType = {
  isMessagePanelOpen: boolean;
  messageObjectId: IDType | null;
  messageObjectName: string | null;
};

const initialState: MessagePanelSliceType = {
  isMessagePanelOpen: false,
  messageObjectId: null,
  messageObjectName: null,
};

const messagePanelStatesSlice = createSlice({
  name: "adminMessagePanelSlice",
  initialState,
  reducers: {
    setMessagePanelOpen: (state, action: PayloadAction<boolean>) => {
      state.isMessagePanelOpen = action.payload;
    },
    setMessageObjectId: (state, action: PayloadAction<IDType | null>) => {
      state.messageObjectId = action.payload;
    },
    setMessageObjectName: (state, action: PayloadAction<string | null>) => {
      state.messageObjectName = action.payload;
    },
  },
});

// selectors
export const selectMessagePanelOpen = (state: RootState) => state.adminMessagePanelRed.isMessagePanelOpen;
export const selectmessageObjectName = (state: RootState) => state.adminMessagePanelRed.messageObjectName;
export const selectMessageObjectId = (state: RootState) => state.adminMessagePanelRed.messageObjectId;

// actions
export const { setMessageObjectId, setMessageObjectName, setMessagePanelOpen } = messagePanelStatesSlice.actions;

// reducer
export const messagePanelStatesReducers = messagePanelStatesSlice.reducer;
