import React, { useState } from "react";
import { Field } from "redux-form";
import { RenderRadioGroup, RenderTextArea } from "../../../../../forms/bootstrapFields";
import { required } from "services/validations/reduxFormValidation";
import { RequiredFieldText } from "./requiredFieldIcon";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Style from "../dispute.module.css";

export const QualityDispute = () => {
  const [hideParticipateError, setHideParticipateError] = useState(true);
  const [hideFaceToFaceError, setHideFaceToFaceError] = useState(true);

  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.option.quality.${key}`);
  };

  const yes_no = [
    { label: t2("yes"), value: "Y" },
    { label: t2("no"), value: "N" },
  ];
  const yes_no_face = [
    { label: t2("face"), value: "Y" },
    { label: t2("no_face"), value: "N" },
  ];

  return (
    <Col>
      <Row>
        <RequiredFieldText text={t2("participate_question")}></RequiredFieldText>
        <div style={{ width: "40px" }}></div>
        <Field
          required={true}
          name="form.options.CARDHOLDER_NOT_PARTICIPATE_ON_TRANSACTION"
          orientation="horizontal"
          component={RenderRadioGroup}
          group={yes_no}
          hideError={hideParticipateError}
          validate={[required]}
        />
      </Row>
      <Row>
        <RequiredFieldText text={t2("transaction_type")}></RequiredFieldText>
        <div style={{ width: "40px" }}></div>
        <Field
          required={true}
          name="form.options.TYPE_OF_TRANSACTION"
          orientation="horizontal"
          component={RenderRadioGroup}
          group={yes_no_face}
          hideError={hideFaceToFaceError}
          validate={[required]}
        />
      </Row>
      <Row>{t2("note_1")}</Row>
      <Row style={{ marginBottom: "24px" }}>
        <div style={{ fontWeight: "bold" }}>{t2("note_2")}</div>
      </Row>
      <Row>
        <Col md={10} className="px-0">
          <Field
            label={t2("line_1") + " "}
            required={true}
            name="form.options.DETAILS_OF_PURCHASE"
            component={RenderTextArea}
            className={`${Style.fixed_textarea}`}
            validate={[required]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={10} className="px-0">
          <Field
            label={t2("line_2") + " "}
            required={true}
            name="form.options.ATTEMPT_TO_RESOLVE"
            component={RenderTextArea}
            className={`${Style.fixed_textarea}`}
            validate={[required]}
          ></Field>
        </Col>
      </Row>
      <Row>
        <Col md={10} className="px-0">
          <Field
            label={t2("line_3") + " "}
            required={true}
            name="form.options.ATTEMPT_TO_RETURN"
            component={RenderTextArea}
            className={`${Style.fixed_textarea}`}
            validate={[required]}
          ></Field>
        </Col>
      </Row>
      <Row>{t2("line_4")}</Row>
    </Col>
  );
};
