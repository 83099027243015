import { RenderField } from "components/forms/bootstrapFields";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Field, InjectedFormProps, change, reduxForm } from "redux-form";
import { isEmpty, minLength, required } from "services/validations/reduxFormValidation";
import AsyncMultiContactPicker, { ContactOptionType } from "../pickers/reduxFormPickers/contactPicker/asyncMultiPicker";

export const MESSAGE_REPLAY_FORM_NAME = "messageReplayFormName";
export interface MessageReplayFormDataPropsType {
  activity?: {
    notify_contacts?: ContactOptionType[];
    description?: string;
  };
}
export interface MessageReplayFormExtraPropsType {
  notifyRequired?: boolean;
}

interface MessageReplayFormPropsType
  extends MessageReplayFormExtraPropsType,
    InjectedFormProps<MessageReplayFormDataPropsType, MessageReplayFormExtraPropsType> {
  notifyRequired?: boolean;
}
const minLength1 = minLength(1);

const MessageReplayFormComponent = ({ handleSubmit, notifyRequired }: MessageReplayFormPropsType) => {
  const dispatch = useDispatch();
  return (
    <Form name={MESSAGE_REPLAY_FORM_NAME} noValidate onSubmit={handleSubmit}>
      <Row>
        <Col md="10">
          <Field
            name="activity.description"
            id="activity.description"
            placeholder="Write a reply"
            component={RenderField}
            validate={[required]}
            required
          />
        </Col>
        <Col className="pl-0" md="2">
          <div className="d-flex justify-content-start">
            <Button variant="secondary" type="submit">
              Send
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Field
            name="activity.notify_contacts"
            id="activity.notify_contacts_ids"
            label="Notify User(s):"
            component={AsyncMultiContactPicker}
            menuPlacement={"top"}
            validate={notifyRequired ? [required, isEmpty] : null}
            onChangeCallBack={(contacts: ContactOptionType[]) => {
              dispatch(change(MESSAGE_REPLAY_FORM_NAME, "activity.notify_contacts", contacts));
            }}
            enableTeamMerge
          />
        </Col>
      </Row>
    </Form>
  );
};
const MessageReplayForm = reduxForm<MessageReplayFormDataPropsType, MessageReplayFormExtraPropsType>({
  form: MESSAGE_REPLAY_FORM_NAME,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  // touchOnChange: false,
  // touchOnBlur: false
})(MessageReplayFormComponent);
export default MessageReplayForm;
