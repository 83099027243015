import TooltipRender from "components/toolTip/tooltipRender";
import { restApiService } from "providers/restApi";
import React from "react";

type ImportDataButtonPropType = {
  title: string;
};

const ImportDataButton = ({ title }: ImportDataButtonPropType) => {
  return (
    <TooltipRender title={<strong>{title}</strong>} placement="left">
      <a href={restApiService.angularBaseURL() + "imports"}>
        <button className="icon-normal icon-import mx-2 border-none"></button>
      </a>
    </TooltipRender>
  );
};

export default ImportDataButton;
