import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import RestApi from "../../providers/restApi";
import { CreateNotification, NotificationType } from "../../services/general/notifications";
import { withTranslation } from "react-i18next";
import ChatMessage from "./chatMessage";
import ChatBubble from "./chatBubble";
import styles from "./chat.module.css";
import { connect } from "react-redux";
import { GiExpand } from "react-icons/gi";
class ChatWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      messages: [],
      userInput: "",
      expanded: false,
    };
    this.restApiService = new RestApi();
    this.windowDOMReference = React.createRef();
  }

  componentDidMount() {
    // let m = new ChatMessage("Hello there", "luke", new Date(),1)
    // this.setState({ messages: [new ChatMessage("Hello there", "luke", new Date(),1,false),
    // new ChatMessage("Hello there this is ioajsod fjoasdjf oajsd ofjoasd jfoaisdj foajds ofjaosd jfoiajsd fojadofi jaosdj foiajsdfo jaosd fj", "Joe", new Date(),2,false),
    // new ChatMessage("Ok thanks", "Musk", new Date(),4,true),
    // new ChatMessage("This is another message, wow it looks so good!", "Kaya", new Date(),5,false),] })

    //this.getMessages();

    if (this.props.expanded) {
      this.setState({
        expanded: this.props.expanded,
      });
    }
  }

  getMessages() {
    let resource = null;
    let activityId = this.props.activityId;
    switch (this.props.activityType) {
      case "Invoice":
        resource = "vendor_invoices";
        break;
      case "PurchaseOrder":
        resource = "vendor_purchase_orders";
        break;
      case "Vendor":
        resource = "purchasers";
        activityId = this.props.overrideGetId;
        break;
      case "Accrual":
        resource = "vendor_accruals";
        break;
    }
    this.restApiService
      .get(resource + "/" + activityId + "/activities")
      .then((result) => {
        result.data.reverse();
        let parsedMessages = result.data.map((activity) => {
          return new ChatMessage(
            activity.description,
            activity.handle,
            Date.parse(activity.created_at),
            activity.id,
            this.props.user.id == activity.user_id,
          );
        });
        this.setState({ messages: parsedMessages });
        this.scrollToBottom();
      })
      .catch((error) => {});
  }

  sendMessage = () => {
    if (!this.state.userInput) {
      return;
    }
    this.restApiService
      .post("activities", null, {
        activity: {
          notify_contacts: [],
          description: this.state.userInput,
          activity_type: "COMMENT",
          activitable_id: this.props.activityId,
          activitable_type: this.props.activityType,
          public: true,
        },
      })
      .then((result) => {
        this.getMessages();
        this.setState({ userInput: "" });
      });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.sendMessage();
    }
  };

  scrollToBottom = () => {
    this.windowDOMReference.current.scrollTop = this.windowDOMReference.current.scrollHeight;
  };

  barClicked = async () => {
    if (!this.state.expanded) {
      this.getMessages();
      await this.setState({ expanded: !this.state.expanded });
      this.scrollToBottom();
    } else {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  render() {
    return (
      <div id="window" className={styles.window}>
        <div className={`${styles.header} pointer purpleColor d-flex`} onClick={this.barClicked}>
          <h6 className="mb-0">{this.props.title}</h6>
          <div className="d-flex align-items-center justify-content-end flex-grow-1 pr-3">
            <GiExpand style={{ color: "white" }} />
          </div>
        </div>
        {this.state.expanded && (
          <Fragment>
            <div className={styles.messageList} ref={this.windowDOMReference}>
              {this.state.messages.map((message) => {
                return <ChatBubble key={message.id} message={message}></ChatBubble>;
              })}
            </div>
            <hr />
            <div className={"d-flex mb-3 pr-3 pl-3"}>
              <input
                type="text"
                className="flex-grow-1"
                placeholder={this.props.inputHint || "Message"}
                value={this.state.userInput}
                onKeyDown={this.handleKeyDown}
                onChange={(event) => {
                  this.setState({ userInput: event.target.value });
                }}
              />
              <button type="button" onClick={this.sendMessage} className={"ml-2 btn btn-primary"}>
                Send
              </button>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChatWindow));
