import { Field } from "redux-form";
import { renderField } from "../forms/fields";
import React, { Component } from "react";

class Mileage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="row" style={{ paddingTop: 10 }}>
          <div className="col-md-3 prt-5">
            <label>Date</label>
            <br />
            <Field className="dateField" name="date" component="input" type="date" />
          </div>
          <div className="col-md-3 plr-5">
            <Field
              value="total"
              name="distance"
              label="Distance"
              customStyle={{ padding: "5px" }}
              component={renderField}
              type="number"
              isRequired={true}
            />
          </div>
          <div className="col-md-3 plr-5">
            <label>Unit</label>
            <br />
            <Field className={"w-100"} name="distance_unit" component="select" style={{ padding: "8px" }}>
              <option value="mi">mile</option>
              <option value="km">km</option>
            </Field>
          </div>
          <div className="col-md-3 plt-5">
            <Field
              value="amount"
              name="amount"
              label="Total Amount"
              customStyle={{ padding: "5px" }}
              component={renderField}
              type="number"
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Mileage;
