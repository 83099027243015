import RestApi from "../../providers/restApi";
import { CreateNotification, NotificationType } from "../../services/general/notifications";
import i18next from "i18next";
import { getAccountsData } from "../../services/common/manageAccounts.svc";
const restApiService = new RestApi();
// ACTION TYPES
const SET_COMPANY = "SET_COMPANY";
const SET_ACCOUNTS = "SET_ACCOUNTS";

// INITIAL_STATE
const initialState = {
  company: {},
  accounts: [],
};

const companyAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY:
      return { ...state, company: action.payload };
    case SET_ACCOUNTS:
      return { ...state, accounts: action.payload };
    default:
      return state;
  }
};

export const setCompanyData = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_COMPANY, payload: payload });
};

export const setAccounts = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_ACCOUNTS, payload: payload });
};
export const getCompany = () => async (dispatch) => {
  try {
    const res = await restApiService.get("company");
    dispatch(setCompanyData(res.data));
    dispatch(setAccounts(getAccountsData(res.data)));
  } catch (err) {
    CreateNotification(i18next.t("error"), err.message, NotificationType.danger);
  }
};

export default companyAccountsReducer;
