import { REMOVE_APPROVALS_COUNT, SET_APPROVALS_COUNT } from "../../actions/actionTypes";
import { approvalCountType } from "../../services/admin/approvals/approvalsType";
import { AppDispatch, RootState } from "..";
import ApprovalsApis from "../../services/admin/approvals/approvalsApis";

const initialState: approvalCountType = {
  expense_report_pending_count: 0,
  invoice_pending_count: 0,
  new_card_request_count: 0,
  payment_approval_count: 0,
  payment_pending_count: 0,
  payment_run_approval_count: 0,
  po_pending_count: 0,
  purchase_pending_count: 0,
  vcard_pending_count: 0,
  vendor_credit_pending_count: 0,
  vendor_pending_count: 0,
};

const approvalCountReducder = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): typeof initialState => {
  switch (type) {
    case SET_APPROVALS_COUNT:
      return payload;
    case REMOVE_APPROVALS_COUNT:
      return initialState;
    default:
      return state;
  }
};

// action dispatchers
export const setApprovalsCount = (stats: approvalCountType) => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch({ type: SET_APPROVALS_COUNT, payload: stats });
};

export const getApprovalsCount = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const response = await ApprovalsApis.getApprovalCount();
    if (response) {
      dispatch(setApprovalsCount(response));
    }
  } catch (error) {}
};

export default approvalCountReducder;
