import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import "react-datepicker/dist/react-datepicker.css";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import TranslationService from "./services/general/translation";
import config from "./config";
import { BrowserRouter } from "react-router-dom";

import { AbilityContext, userAbility } from "./services/authorization/authorization";
import AdminMessagePanel from "./components/admin/messagePanel/messagePanel";
import { LicenseManager } from "ag-grid-enterprise";
import ConfirmModal from "./components/modals/confirmModal/confirmModal";
import { PAUSE_AXIOS } from "./providers/appEmitter";
import "./styles/ag-theme-fresh.css";

PAUSE_AXIOS(); // set global flags on load of app

LicenseManager.setLicenseKey(config.agGridLicense);

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ReactNotifications />
    <TranslationService />
    <ConfirmModal />
    <AdminMessagePanel />
    <AbilityContext.Provider value={userAbility}>
      {/* Note we use browser router here so the App component can use history */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AbilityContext.Provider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
