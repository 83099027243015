import React from "react";
import { useDispatch } from "react-redux";
import { CHANGE_COUNTRY_CODE } from "../../../../actions/actionTypes";
import { Field } from "redux-form";
import { PaymentFieldType } from "../types";
import CountryPicker from "../../../common/pickers/reduxPickers/countryPicker";

type CountryCodePropsType = PaymentFieldType & { disabled?: boolean };

const CountryCode = ({ validations, required, label, disabled }: CountryCodePropsType) => {
  const dispatch = useDispatch();

  const onChangeCountryCode: any = (countryCode: string) => {
    dispatch({ type: CHANGE_COUNTRY_CODE, payload: { value: countryCode } });
  };

  return (
    <Field
      name="country"
      label={label}
      component={CountryPicker}
      required={required}
      validate={validations}
      onChange={onChangeCountryCode}
      disabled={disabled}
    />
  );
};

export default CountryCode;
