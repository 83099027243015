import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import Panel from "components/common/panel/panel";
import { useTranslation } from "react-i18next";
import { Utility } from "../../../../../services/admin/cards/cardDisputesSvc";
import { useTypedSelector } from "../../../../../reducers";
import { Field, getFormValues, change } from "redux-form";
import { CardsDisputesFormState, Image } from "../../../../../services/admin/cards/disputes/cardDisputes.types";
import Style from "./dispute.module.css";
import PreviewComponent from "components/common/attachmentPreview/previewComponent";
import { RenderRadioGroup } from "components/forms/bootstrapFields";
import { shallowEqual, useDispatch } from "react-redux";

export const DisputeSummary = () => {
  const dispatch = useDispatch();
  const [haveMoreThanOneSuccessfulDisputes, setHaveMoreThanOneSuccessfulDisputes] = useState(false);
  const [haveFailedDisputes, setHaveFailedDisputes] = useState(false);
  const [defaultInitialIndex, setDefaultInitialIndex] = useState<number | null>(null);

  const initialImage: Image = {
    id: "",
    asset_file_file_name: "",
    asset_expiring_url: "",
    asset_expiring_thumb_url: null,
  };

  const [image, setImage] = useState(initialImage);

  const setSelectedSuccessId = (id: number) => {
    dispatch(change("CardDisputes", "responseSummary.transactionIDToShowPDF", id));
  };

  const currentState = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue;
  }, shallowEqual);

  const PDFId = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue?.responseSummary?.transactionIDToShowPDF;
  });

  const responseSummary = currentState?.responseSummary;

  const presentImage = (id: number) => {
    const transaction = responseSummary?.responseSummary?.find((e) => e?.id === id);
    if (transaction && transaction.query_response) {
      setImage({
        id: transaction.id.toString(),
        asset_file_file_name: "pdf",
        asset_expiring_url: transaction.query_response.disputes.pdf_url,
        asset_expiring_thumb_url: null,
      });
    }
  };

  useEffect(() => {
    if (responseSummary && responseSummary.responseSummary) {
      const transaction = responseSummary.responseSummary.find((e) => e?.id === responseSummary.transactionIDToShowPDF);
      if (transaction && transaction.query_response) {
        presentImage(transaction.id);
      }
    }
  }, [PDFId]);

  const successfulTransactionsGroup = useTypedSelector((state) => {
    return (
      responseSummary?.responseSummary
        ?.filter((e) => e.dispute_successfully_created)
        .map((e) => ({
          label: `Transaction ID: ${e.id}`,
          value: e.id,
        })) || []
    ).sort((a, b) => a.value - b.value);
  }, shallowEqual);

  const failedTransactionsGroup = useTypedSelector((state) => {
    return (
      responseSummary?.responseSummary
        ?.filter((e) => !e.dispute_successfully_created)
        .map((e) => ({
          label: `Transaction ID: ${e.id}`,
          value: e.id,
          msg: e.details,
        })) || []
    ).sort((a, b) => a.value - b.value);
  }, shallowEqual);

  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.${key}`);
  };

  const returnToAngular = () => {
    Utility.returnToAngular(currentState);
  };

  useEffect(() => {
    const s = responseSummary?.responseSummary;
    const haveMoreThanOneSuccessfulDisputes =
      (s?.reduce((total, x) => (x.dispute_successfully_created ? total + 1 : total), 0) || 0) > 1 || false;

    setHaveMoreThanOneSuccessfulDisputes(haveMoreThanOneSuccessfulDisputes);
    setHaveFailedDisputes(s?.some((e) => !e.dispute_successfully_created) || false);
    setSelectedSuccessId(responseSummary?.transactionIDToShowPDF || 0);

    if (responseSummary?.transactionIDToShowPDF) {
      presentImage(responseSummary.transactionIDToShowPDF);
    }

    if (haveMoreThanOneSuccessfulDisputes && responseSummary?.transactionIDToShowPDF) {
      const defaultRadioIndexToCheck = successfulTransactionsGroup.findIndex(
        (e) => e.value === responseSummary.transactionIDToShowPDF,
      );
      if (defaultRadioIndexToCheck > -1) {
        setDefaultInitialIndex(defaultRadioIndexToCheck);
      }
    }
  }, []);

  return (
    <div>
      <Panel wrapperClass={`${Style.summary_page_main_panel}`}>
        <Row>
          {(haveMoreThanOneSuccessfulDisputes || haveFailedDisputes) && (
            <Col sm={3} className={`${Style.left_column}`}>
              {haveMoreThanOneSuccessfulDisputes && (
                <Row>
                  <Panel
                    header={t2("successful_disputes")}
                    cardHeaderClass={`${Style.panel_header}`}
                    wrapperClass={`${Style.full_width_panel_body}`}
                    cardBodyClass={`${Style.half_height_panel_body}`}
                  >
                    <Field
                      style={{ fontWeight: "normal" }}
                      name="responseSummary.transactionIDToShowPDF"
                      component={RenderRadioGroup}
                      group={successfulTransactionsGroup}
                      initialCheckedIndex={defaultInitialIndex}
                    />
                  </Panel>
                </Row>
              )}
              {haveFailedDisputes && (
                <Row>
                  <Panel
                    header={t2("failed_disputes")}
                    cardHeaderClass={`${Style.panel_header}`}
                    wrapperClass={`${Style.full_width_panel_body}`}
                    cardBodyClass={`${Style.half_height_panel_body}`}
                  >
                    {failedTransactionsGroup.map((e, index) => (
                      <div key={index}>
                        {e.label}
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: e.msg || "" }} />
                        <hr />
                      </div>
                    ))}
                  </Panel>
                </Row>
              )}
            </Col>
          )}

          <Col>
            <div className={`${Style.pdf_container}`}>
              {image.asset_expiring_url && <PreviewComponent fileName="pdf" filePath={image.asset_expiring_url} />}
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "16px" }} className="justify-content-end">
          <Button className="btn" style={{ marginRight: "10px" }} type="button" onClick={returnToAngular}>
            {t2(`return_to_purchase_details`)}
          </Button>
        </Row>
      </Panel>
    </div>
  );
};
