import { ColumnApi, GridApi } from "ag-grid-community";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";

type SelectAllPropsType = {
  gridApi: GridApi;
  columnApi: ColumnApi;
};

const SelectAll = forwardRef(({ gridApi, columnApi }: SelectAllPropsType, ref) => {
  const checkBoxRef = useRef(null);
  const selectedRows = gridApi?.getSelectedRows();
  const [allSelected, setAllSelected] = useState(selectedRows && selectedRows.length > 0 ? true : false);

  const toggleSelectAll = () => {
    if (!allSelected) {
      selectAll();
      setAllSelected(true);
    } else {
      selectNone();
      setAllSelected(false);
    }
  };

  const onPaginationChange = () => {
    selectNone();
    setAllSelected(false);
  };

  useImperativeHandle(ref, () => {
    return { onPaginationChange };
  }, []);

  const selectAll = () => {
    gridApi.forEachNode((node) => {
      node.setSelected(true);
    });
  };

  const selectNone = () => {
    gridApi.forEachNode((node) => node.setSelected(false));
  };

  return (
    <div className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${allSelected && "ag-checked"}`}>
      {/* currently this button will select all the rows in table loaded from all pages 
            implementation for select only current page rows is current not possible */}
      <input
        ref={checkBoxRef}
        type="checkbox"
        checked={allSelected}
        onChange={() => toggleSelectAll()}
        className="ag-input-field-input ag-checkbox-input"
      />
    </div>
  );
});

export default SelectAll;
