import React, { useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import {
  selectLinkedPurchaseDetail,
  selectReceiptDetail,
  setLinkedPurchaseDetails,
} from "reducers/admin/receiptsReducer";
import PurchasesApis from "services/admin/purchases/purchasesApis";
import style from "./receipts.module.css";
import { companyDateFormat } from "services/general/dateSvc";
import _ from "lodash";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";

const PurchaseReceiptInfo = () => {
  const dispatch = useDispatch();
  const currentUser = useTypedSelector((state) => state.user);
  const receiptDetail = useTypedSelector(selectReceiptDetail);
  const linkedPurchaseDetail = useTypedSelector(selectLinkedPurchaseDetail);

  const getReceiptLinkedPurchaseDetails = useCallback(async () => {
    try {
      if (receiptDetail?.documentable_id) {
        const result = await PurchasesApis.getPurchase(receiptDetail.documentable_id);
        dispatch(setLinkedPurchaseDetails(result));
      }
    } catch (error) {}
  }, [dispatch, receiptDetail?.documentable_id]);

  useEffect(() => {
    getReceiptLinkedPurchaseDetails();
  }, [getReceiptLinkedPurchaseDetails]);

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className={style.generalInfo}>
              <dl className="dl-horizontal">
                <div className={style.di}>
                  <dt>Merchant</dt>
                  <dd>{linkedPurchaseDetail?.merchant ?? "N/A"}</dd>
                </div>

                <div className={style.di}>
                  <dt>Transaction Date</dt>
                  <dd>{companyDateFormat(linkedPurchaseDetail?.transaction_date, currentUser) ?? "N/A"}</dd>
                </div>

                <div className={style.di}>
                  <dt>Purchase Amount</dt>
                  <dd>
                    {(linkedPurchaseDetail?.currency_code &&
                    currencySymbolRenderer(linkedPurchaseDetail?.currency_code) + linkedPurchaseDetail?.amount
                      ? linkedPurchaseDetail?.amount?.toFixed(2)
                      : null) ?? "N/A"}
                  </dd>
                </div>

                <div className={style.di}>
                  <dt>File Name</dt>
                  <dd>
                    {(_.isArray(linkedPurchaseDetail?.assets) &&
                      linkedPurchaseDetail?.assets[0] &&
                      linkedPurchaseDetail?.assets[0]?.asset_file_file_name) ??
                      "N/A"}
                  </dd>
                </div>
              </dl>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PurchaseReceiptInfo;
