import _ from "lodash";
import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SubmissionError } from "redux-form";
import RestApi from "../../../providers/restApi";
import {
  CALENDAR_EVENT_FORM,
  editCalenderEvents,
  onCalendarEventFormUnmount,
  setEventDateForm,
} from "../../../reducers/admin/calendarEventerReducer";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { createCompleteError } from "../../../services/general/reduxFormSvc";
import CustomModal from "../../modals/customModal";
import CalendarEventForm from "./form";

const restApiService = new RestApi();

const Edit = ({ dateEventData, editModalOpen, setEditModalOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModal = () => {
    setEditModalOpen(false);
  };

  useEffect(() => {
    dispatch(setEventDateForm({ calendar_event: dateEventData }));

    return () => {
      dispatch(onCalendarEventFormUnmount());
    };
  }, []);

  const onSubmit = async (calendarEventData) => {
    try {
      const response = await restApiService.patch(
        "calendar_events/" + calendarEventData?.form?.calendar_event?.id,
        null,
        calendarEventData.form,
      );
      // updating redux state instead fetching list agian
      dispatch(editCalenderEvents(response.data));

      CreateNotification(
        t("success"),
        t("components.admin.calendarEvent.successUpdatedCalendarEvent", { id: response.data.id }),
        NotificationType.success,
      );
      closeModal();
    } catch (error) {
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          throw new SubmissionError({
            form: completeErrorObj,
          });
        }
      }
    }
  };

  return (
    <CustomModal
      show={editModalOpen}
      onHide={closeModal}
      header={t("components.admin.calendarEvent.editCalendarEvent")}
      body={<CalendarEventForm onSubmit={(value) => onSubmit(value)} />}
      footer={
        <Row>
          <Col xs="12">
            <Button className="mr-3" variant="secondary" onClick={closeModal}>
              {t("cancel")}
            </Button>
            <Button type="submit" form={CALENDAR_EVENT_FORM}>
              {t("components.admin.calendarEvent.editCalendarEvent")}
            </Button>
          </Col>
        </Row>
      }
    ></CustomModal>
  );
};

export default Edit;
