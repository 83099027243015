import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import useIsMounted from "components/common/hooks/useIsMounted";
import CustomModal from "components/modals/customModal";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { change, SubmissionError } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { IUser } from "services/common/user/userTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";
import FormInvoice from "./form";
import styles from "./invoices.module.css";

const AddInvoice = () => {
  //   const formName = "InvoiceForm";
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const [showModal, setShowModal] = useState(true);
  const [initialFormValues, setInitialFormValues] = useState<InvoiceType.InvoiceDetailType | undefined>(undefined);
  const [wantToCopy, setWantToCopy] = useState<boolean>();
  const dispatch = useDispatch();

  const currentUser: IUser = useTypedSelector((state) => state.user);

  const closeModal = () => {
    setShowModal(false);
  };

  const getNewInvoice = async () => {
    try {
      const response = await InvoiceApis.getNewInvoice();
      invoiceCommonSvc.setPriorPeriodRange(response, currentUser);

      if (response?.term) {
        response.term_id = response?.term?.id;
      }

      if (currentUser.company.enable_used_tax) {
        response.tax_debit_entries_attributes = [];
      }
      response.submitWithAccounts = invoiceCommonSvc.hideAccountTab("debit accounts", currentUser);
      response.invoiceItemUseTaxTotal = 0;
      response.invoiceAccountUseTaxTotal = 0;

      response.attachments = [];

      setInitialFormValues(response);
    } catch (error) {
      CreateNotification("Error", "Add Service Error", NotificationType.danger);
      console.error(error);
    }
  };

  const mergeCopiedInvoice = async (respense: any) => {
    setInitialFormValues(respense);
    dispatch(change("InvoiceForm", `submitWithAccounts`, true));

    setWantToCopy(false);
  };

  useEffect(() => {
    if (isMounted.current) {
      getNewInvoice();
    }
  }, []);

  const cancel = () => {
    closeModal();
    window.location.href = `${restApiService.angularBaseURL()}invoices`;
  };

  const mergeSubtotalUsedTax = (invoicePayload: InvoiceType.InvoiceDetailType) => {
    let debitTaxObj: any = invoiceCommonSvc.getTaxDebit(
      invoicePayload.tax_debit_entries_attributes,
      currentUser,
      invoicePayload,
    );
    if (debitTaxObj.account_id) {
      debitTaxObj.account_id = debitTaxObj?.tax_code?.credit_account_id
        ? debitTaxObj?.tax_code?.credit_account_id
        : currentUser?.company?.used_tax_credit_account_id;
      invoicePayload.tax_credit_entries_attributes = [debitTaxObj];
    }
  };

  const manageAccountLevelUseTax = function (invoicePayload: InvoiceType.InvoiceDetailType) {
    if (_.isArray(invoicePayload.debit_entries_attributes) && invoicePayload.debit_entries_attributes.length > 0) {
      invoicePayload.debit_entries_attributes.forEach((debitEntry: any) => {
        if (debitEntry._destroy != 1 && !invoiceCommonSvc.isItemDebitLine(debitEntry)) {
          invoiceCommonSvc.mergeUsedTax(invoicePayload, debitEntry, currentUser, invoicePayload.is_account_used_tax);
        }
      });
    }
  };

  const isAttachmentRequired = (invoicePayload: InvoiceType.InvoiceDetailType, attachments: any) => {
    if (currentUser?.company?.invoice?.is_attachment_required && attachments.length < 1) {
      dispatch(change("InvoiceForm", "attachmentWarning", "Attachments is required field, kindly add attachments."));
      return false;
    }
    return true;
  };

  const handleSubmit = async (invoicePayload: InvoiceType.InvoiceDetailType) => {
    invoiceCommonSvc.normalizeFieldValues(invoicePayload.debit_entries_attributes);
    invoiceCommonSvc.normalizeFieldValues(invoicePayload.credit_entries_attributes);

    if (
      currentUser?.company?.has_subtotal_templates &&
      invoiceCommonSvc.validateSubtotalTemplate(invoicePayload.debit_entries_attributes)
    ) {
      CreateNotification("Warning", `Duplicate subtotal description not allowed.`, NotificationType.warning);
      return false;
    }

    //TODO: validate service start date and service end date
    // if(invoiceCommonSvc.validateServiceDate(invoicePayload.service_start_date, invoicePayload.service_end_date)){
    //   return false;
    // }

    if (!invoicePayload.requestor || (invoicePayload.requestor && !(invoicePayload.requestor.id > 0))) {
      invoicePayload.requestor_id = "";
    }

    if (invoicePayload.is_used_tax || invoicePayload.is_account_used_tax) {
      mergeSubtotalUsedTax(invoicePayload);
    }

    invoiceCommonSvc.updateFieldsBeforeSubmit(invoicePayload, currentUser);

    if (_.isArray(invoicePayload.invoice_items_attributes && invoicePayload.invoice_items_attributes.length > 0)) {
      invoicePayload.invoice_items_attributes?.forEach((invoiceItem) => {
        if (
          invoiceItem._destroy != 1 &&
          invoiceItem.account_id &&
          invoiceItem.product_item_id &&
          invoicePayload.is_used_tax
        ) {
          invoiceCommonSvc.mergeUsedTax(invoicePayload, invoiceItem, currentUser, invoicePayload.is_used_tax);
        }
      });
    }

    if (invoicePayload.submitWithAccounts) {
      invoiceCommonSvc.assignInvoiceAmountToCredit(invoicePayload, currentUser);

      if (invoicePayload.is_account_used_tax) {
        manageAccountLevelUseTax(invoicePayload);
      }
      //TODO: uncomment after implementation of rebate feature
      //  invoicePayload.debit_entries_attributes = invoiceCommonSvc.updateDebitLineSubAmount(invoicePayload.debit_entries_attributes);

      //to update/add debit entry for tax amount
      invoiceCommonSvc.setHeaderTaxDebitEntry(invoicePayload, currentUser);

      // use async flag to indicate if the invoice has more than 10 items or po links
      // the backend will process the invoice in background if async is true
      if (
        _.isArray(invoicePayload.invoice_purchase_order_links_attributes) &&
        invoicePayload.invoice_purchase_order_links_attributes.length > invoiceCommonSvc.async_po_count
      ) {
        invoicePayload.async = true;
      } else if (
        _.isArray(invoicePayload.invoice_items_attributes) &&
        invoicePayload.invoice_items_attributes.length > invoiceCommonSvc.async_items_count
      ) {
        invoicePayload.async = true;
      }

      invoicePayload.account_transaction_attributes = {};
      invoicePayload.account_transaction_attributes.date = invoicePayload.date;
      invoicePayload.account_transaction_attributes.amount = invoicePayload.amount;
      invoicePayload.account_transaction_attributes.debit_entries_attributes = invoicePayload.debit_entries_attributes;
      invoicePayload.account_transaction_attributes.credit_entries_attributes =
        invoicePayload.credit_entries_attributes;
    } else {
      invoicePayload.account_transaction_attributes = undefined;
    }

    //set the po links amount at the last minute to gurantee they are correct
    invoiceCommonSvc.setPoLinksAmount(invoicePayload);

    if (_.isArray(invoicePayload.invoice_items_attributes) && invoicePayload.invoice_items_attributes.length > 0) {
      invoicePayload.invoice_items_attributes.forEach((invoiceItem) => {
        adminCommonSvc.removeIdFromBudgetItemLinks(invoiceItem);
      });
    }

    if (_.isArray(invoicePayload.debit_entries_attributes) && invoicePayload.debit_entries_attributes.length > 0) {
      invoicePayload.debit_entries_attributes.forEach((debitEntry) => {
        adminCommonSvc.removeIdFromBudgetItemLinks(debitEntry);
      });
    }

    let attachments = [];

    if (_.isArray(invoicePayload.attachments) && invoicePayload.attachments.length > 0) {
      attachments = invoicePayload.attachments;
      delete invoicePayload.attachments;
    }
    if (!isAttachmentRequired(invoicePayload, attachments)) {
      return false;
    }

    if (invoicePayload.metadata_links_attributes?.length > 0) {
      invoicePayload.metadata_links_attributes = _.uniqBy(
        invoicePayload.metadata_links_attributes,
        "metadata_configuration_id",
      );
      invoicePayload.metadata_links = invoicePayload.metadata_links_attributes;
    }

    try {
      const response = await InvoiceApis.addInvoice({ invoice: invoicePayload });
      if (response?.id) {
        if (_.isArray(attachments) && attachments.length > 0) {
          await invoiceCommonSvc.uploadAttachments(attachments, response.id);
        }
        CreateNotification("Success", `Invoice Number: ${response.number} added`, NotificationType.success);
        if (response.status_message) {
          CreateNotification("Sync", response.status_message, NotificationType.info);
        }
        setTimeout(() => {
          window.location.href = `${restApiService.angularBaseURL()}invoices`;
        }, 1500);
      }
    } catch (error: any) {
      const { response } = error;
      invoicePayload.attachments = attachments;
      console.log("response: ", response);

      throw new SubmissionError(response.data);
    }
  };

  return (
    <ErrorBoundary>
      <CustomModal
        size="xl"
        headerClass={styles.modelSpacing}
        modalBodyClass={styles.modelSpacing}
        headerTitleClass={styles.modalHeader}
        contentClassName={styles.modelStyle}
        header={
          <span>
            {t("admin.pages.invoice.addInvoice")}{" "}
            <i
              className={`icon icon-copy ${styles.iconSize}`}
              id="invoice_copy_icon"
              title="Invoice copy setting"
              onClick={() => setWantToCopy((prev) => !prev)}
            ></i>
          </span>
        }
        body={
          <FormInvoice
            onSubmit={handleSubmit}
            mergeCopiedInvoice={mergeCopiedInvoice}
            onCancel={cancel}
            isAddForm={true}
            wantToCopy={wantToCopy}
            initialValues={initialFormValues}
          />
        }
        show={showModal}
        onHide={cancel}
      />
    </ErrorBoundary>
  );
};

export default AddInvoice;
