import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Styles from "../../../../pickers/reduxFormPicker/override.module.css";
import Select from "react-select";
import restApi from "../../../../../providers/restApi";
import { compare } from "../../../../../services/general/helpers";
import { isArray, isPlainObject } from "lodash";

const restApiService = new restApi();

const CustomerPicker = (props) => {
  let {
    className,
    label,
    input,
    isRequired,
    callback,
    meta: { touched, error, warning },
    filter = null,
  } = props;

  const [customers, setCustomer] = useState([]);
  const mounted = useRef(false);

  const getCustomers = useCallback(async () => {
    const result = await restApiService.get("purchasers.lk", filter);
    const value = parseForSelect(result.data);
    if (mounted.current) setCustomer(value);
  }, []);

  const parseForSelect = (customers) => {
    return customers
      .map((customer) => {
        return {
          value: customer.id,
          label: customer.name,
        };
      })
      .sort(compare);
  };

  useEffect(() => {
    getCustomers();
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const findSelected = () => {
    let selectedValues = [];
    if (typeof (input.value === String) && isArray(customers) && customers.length > 0) {
      selectedValues = customers.find((customer) => input.value === customer.value);
    }

    if (isPlainObject(input.value)) {
      selectedValues = [input.value];
    }

    if (isArray(input.value)) {
      selectedValues = input.value;
    }
    return selectedValues;
  };

  const onBlur = (option) => {
    if (!option) {
      input.onBlur(null);
      return;
    }
    input.onBlur(option);
  };

  const setCustomerObj = (value) => {
    input.onChange(value.value);
    if (callback) {
      callback(value);
    }
  };

  return (
    <Form.Group className={Styles.select}>
      <label>{label}</label>
      {isRequired && <span className={"color-red"}>*</span>}
      <Select
        {...input}
        required={isRequired}
        value={findSelected()}
        placeholder="search and select"
        onChange={(value) => setCustomerObj(value)}
        onBlur={() => onBlur(input.value)}
        options={customers}
      />
      {touched && error && (
        <Form.Control.Feedback type="invalid" className="d-block">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default CustomerPicker;
