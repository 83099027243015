import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import ContactPicker from "../../common/pickers/reduxPickers/contactPicker";
import _ from "lodash";

class CreditCardFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousSubscribers: [],
      previousContactIds: [],
      subscribersIds: [],
    };
    this.restApiService = new RestApi();
  }

  setPreviousSubscribers = () => {
    this.setState({ previousSubscribers: this.props.subscribers });
    const previousContactIds = _.isArray(this.props.subscribers)
      ? this.props.subscribers.map((subscriber) => subscriber.contact_id)
      : [];

    // for keeping track of which contactIds are present db
    this.setState({ previousContactIds });

    // for showing in contactPicker as default selected
    this.setState({ subscribersIds: previousContactIds });
  };

  onSubscribersChange = (newContactIds) => {
    // setting local state show update in contactsPicker
    this.setState({ subscribersIds: newContactIds });

    // iterate over the previousSubscribers,
    // for each previousSubscriber chack if contact_is present in newContactIds
    // if yes return previousSubscriber object as it is
    // else add _destroy: 1 in previousSubscriber object and return
    const newSubscribersPayload = _.isArray(this.state.previousSubscribers)
      ? this.state.previousSubscribers.map((previousSubscriber) => {
          if (newContactIds.includes(previousSubscriber.contact_id)) {
            return previousSubscriber;
          } else {
            return { ...previousSubscriber, _destroy: 1 };
          }
        })
      : [];

    // now check for new added contactIds
    // for newContactIds, push object {contact_id: contactId}
    const newAddedContactIds = newContactIds.filter((contactId) => !this.state.previousContactIds.includes(contactId));
    newAddedContactIds.forEach((contactId) => {
      newSubscribersPayload.push({ contact_id: contactId });
    });

    // finally set subscribers in redux
    this.props.subscriberChange(newSubscribersPayload);
  };

  componentDidMount() {
    this.setPreviousSubscribers();
  }

  render() {
    return (
      <Row>
        <Col>
          <Row>
            <Form.Group as={Col} md="4">
              <Form.Label>
                {this.props.t("paymentMethod.merchantName")}:<span className={"color-red"}>*</span>
              </Form.Label>
              <Form.Control
                required
                name="merchantName"
                value={this.props.merchant_name}
                onChange={(event) => {
                  this.props.changeMerchantName(event.target.value);
                }}
                type="text"
              />
              <Form.Control.Feedback type="invalid">{this.props.t("validation.merchantName")}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Label>{this.props.t("paymentMethod.merchantId")}:</Form.Label>
              <Form.Control
                name="merchantId"
                value={this.props.merchant_id}
                onChange={(event) => {
                  this.props.changeMerchantId(event.target.value);
                }}
                type="text"
              />
            </Form.Group>
          </Row>
          <Row>
            <Col md="12">
              <Form.Label>
                <strong>{this.props.t("paymentMethod.cardReceiver")}</strong>
              </Form.Label>
              <br />
              {this.props.t("paymentMethod.cardReceiverDes")}
            </Col>
            <Form.Group as={Col} md="4">
              <ContactPicker
                name="contactId"
                inputId="credit-card-subscribers"
                isMulti={true}
                callback={this.onSubscribersChange}
                filter={{ status: "ACTIVE" }}
                selectedContact={this.state.subscribersIds}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <h6>
                This payment method indicates you accept credit cards for invoice payments and are able to run a
                card-not-present transaction. Selecting this option will activate the Corporate Cards section of this
                portal so purchasers may pass card payment info to you securely.
              </h6>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    merchant_name: state.paymentMethodRed.merchant_name,
    merchant_id: state.paymentMethodRed.merchant_id,
    subscribers: state.paymentMethodRed.subscribers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeMerchantName: (payload) => {
      dispatch({ type: actionType.CHANGE_MERCHANT_NAME, payload: payload });
    },
    changeMerchantId: (payload) => {
      dispatch({ type: actionType.CHANGE_MERCHANT_ID, payload: payload });
    },
    subscriberChange: (payload) => {
      dispatch({ type: actionType.CHANGE_SUBSCRIBERS, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreditCardFields));
