import Panel from "components/common/panel/panel";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Col, Form, ListGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { FaInfoCircle, FaSearch, FaSyncAlt } from "react-icons/fa";
import styles from "./invoiceQueue.module.css";
import { QueueInvoiceDataType } from "./invoiceQueue.type";
import { usePdfRenderingContext } from "./pdfRenderingContext";
import { useQueueManagementContext } from "./queueManagementContext";
import { useUiStateContext } from "./uiStateContext";

import { createInvoiceFromFileAsync, generateRandomId, handleError, stopEventPropagation } from "./uploadQueueService";

type InvoiceListItemProps = {
  itQueueInvoice: QueueInvoiceDataType;
  isCurrentInvoice: boolean;
  disabled: boolean;
  onClick: (qInvoice: QueueInvoiceDataType) => void;
};

const InvoiceQueue: React.FC = () => {
  const { t } = useTranslation();
  const {
    filteredInvoices,
    selectedQueueInvoice,
    setSelectedQueueInvoice,
    addNewQueueInvoices,
    refetchInvoiceList,
    isFetching,
    isFetchingInvoices,
    searchTerm,
    setSearchTerm,
  } = useQueueManagementContext();
  const { resetPdfContext } = usePdfRenderingContext();
  const { isOpenUploader, setOpenUploader, setUploadBtnDisable } = useUiStateContext();

  const InvoiceListItem = React.memo(
    ({ itQueueInvoice, isCurrentInvoice, disabled, onClick }: InvoiceListItemProps) => {
      const title = itQueueInvoice.filename ?? "";
      const className = "border-0 p-2 text-truncate";
      const spinner = itQueueInvoice.isBeingOcred ? (
        <span className={styles.spinnerContainerSelectedInvoice}>
          <Spinner animation="border" size="sm" variant="light" />
        </span>
      ) : null;

      return (
        <ListGroup.Item
          action={!isCurrentInvoice && !itQueueInvoice.isBeingOcred}
          disabled={disabled}
          className={isCurrentInvoice || itQueueInvoice.isBeingOcred ? styles.customListGroupItemActive : className}
          title={title}
          active={isCurrentInvoice || itQueueInvoice.isBeingOcred}
          onClick={!disabled && !isCurrentInvoice ? () => onClick(itQueueInvoice) : undefined}
        >
          {spinner}
          {itQueueInvoice.label}
        </ListGroup.Item>
      );
    },
  );

  useEffect(() => {
    setUploadBtnDisable(isFetching);
  }, [isFetching, setUploadBtnDisable]);

  const loadingSpinner = useMemo(
    () => (
      <div className={styles.spinnerContainer}>
        <Spinner animation="grow" className={styles.customSpinner} />
      </div>
    ),
    [],
  );

  const handleCreateInvoiceAsync = useCallback(
    async (localInvoice: QueueInvoiceDataType) => {
      if (localInvoice && localInvoice.file) {
        const invoice = await createInvoiceFromFileAsync(localInvoice, t);
        if (invoice) setSelectedQueueInvoice(invoice);
      }
    },
    [setSelectedQueueInvoice, t],
  );

  const handleCreateInvoicesInParallel = useCallback(
    async (newInvoices: QueueInvoiceDataType[]) => {
      if (!newInvoices || newInvoices.length === 0) return;
      await Promise.all(newInvoices.map(handleCreateInvoiceAsync));
    },
    [handleCreateInvoiceAsync],
  );

  const handleInvoiceClick = useCallback(
    (qInvoice: QueueInvoiceDataType) => {
      if (!qInvoice || !qInvoice.invoice || isFetching) return;
      resetPdfContext();
      setSelectedQueueInvoice(qInvoice);
    },
    [isFetching, resetPdfContext, setSelectedQueueInvoice],
  );

  const dropzoneRef = useRef(null);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploadBtnDisable(true);
      const newInvoices: QueueInvoiceDataType[] = acceptedFiles.map((file) => ({
        invoice: { id: generateRandomId() },
        label: file.name,
        file,
        filename: file.name,
        isLocal: true,
        isBeingOcred: true,
      }));

      addNewQueueInvoices(newInvoices);

      try {
        await handleCreateInvoicesInParallel(newInvoices);
      } catch (error) {
        handleError(error, t);
      } finally {
        refetchInvoiceList();
      }
    },
    [addNewQueueInvoices, handleCreateInvoicesInParallel, refetchInvoiceList, setUploadBtnDisable, t],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    noDrag: true,
    onDrop,
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleRefresh = useCallback(
    (event: any) => {
      if (!event) return;
      event.preventDefault();
      event.stopPropagation();

      setSearchTerm("");
      refetchInvoiceList();
    },
    [refetchInvoiceList, setSearchTerm],
  );

  useEffect(() => {
    if (isOpenUploader) {
      open();
      setOpenUploader(false);
    }
  }, [open, isOpenUploader, setOpenUploader]);

  const renderInfoTooltip = useMemo(
    () => <Tooltip id="button-tooltip">{t("admin.pages.uploadQueue.tooltip")}</Tooltip>,
    [t],
  );

  const PanelHeader = useMemo(
    () => (
      <Row className={styles.panelHeader} noGutters>
        <Col className={styles.leftSection}>
          <div className={styles.textContainer}>
            <span className={styles.label}>{t("admin.pages.uploadQueue.invoiceQueue")}</span>
            <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderInfoTooltip}>
              <FaInfoCircle size={14} className={styles.infoButton} onClick={stopEventPropagation} />
            </OverlayTrigger>
          </div>
        </Col>
        <Col className={styles.rightSection}>
          <FaSyncAlt size={18} onClick={(e) => handleRefresh(e)} title="Refresh" className={styles.refreshButton} />
        </Col>
      </Row>
    ),
    [handleRefresh, renderInfoTooltip, t],
  );

  useEffect(() => {
    refetchInvoiceList();
  }, [refetchInvoiceList]);

  const invoiceListItems = useMemo(() => {
    return filteredInvoices.map((itQueueInvoice) => {
      const id = itQueueInvoice.invoice.id;
      const disabled = !!(isFetching || itQueueInvoice.isBeingOcred);
      const isCurrentInvoice = selectedQueueInvoice?.invoice?.id === id;

      return (
        <InvoiceListItem
          key={id}
          itQueueInvoice={itQueueInvoice}
          isCurrentInvoice={isCurrentInvoice}
          disabled={disabled}
          onClick={disabled || isCurrentInvoice ? () => {} : handleInvoiceClick}
        />
      );
    });
  }, [InvoiceListItem, filteredInvoices, handleInvoiceClick, isFetching, selectedQueueInvoice?.invoice?.id]);

  return (
    <Panel header={PanelHeader} cardBodyClass={styles.cardBody}>
      <div {...getRootProps({ style: { display: "none" } })} ref={dropzoneRef}>
        <input {...getInputProps()} />
      </div>
      <Row>
        <Col sm="12">
          <div className={styles.inputWrapper}>
            <FaSearch className={styles.searchIcon} />
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              className={styles.input}
            />
          </div>
        </Col>
      </Row>
      <Row noGutters className={styles.list}>
        <Col className="p-0">
          {isFetchingInvoices && loadingSpinner}
          <ListGroup variant="flush">{invoiceListItems}</ListGroup>
        </Col>
      </Row>
    </Panel>
  );
};

export default InvoiceQueue;
