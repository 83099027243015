import React, { useState, useEffect } from "react";
import InputDropdownFilter from "./inputDropdown";
import { restApiService } from "providers/restApi";
import { AxiosResponse } from "axios";
import { Spinner } from "react-bootstrap";

const VendorsDropdown = ({
  name,
  code,
  options,
  isRequired,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  isRequired?: boolean;
}) => {
  const [vendorOptions, setVendorOptions] = useState<{ label: string; value: string }[]>([{ label: "ALL", value: "" }]);

  const loadVendorsOptions = async () => {
    let response: AxiosResponse<any>;
    let page = 1;
    const vendors: { label: string; value: string }[] = [];
    try {
      response = await restApiService.get("vendors.lk", { page: page++, items: 100 });
      vendors.push({ label: "ALL", value: "" });
      response.data.forEach((vendor: { name: string; id: string }) => {
        vendors.push({ label: vendor.name, value: vendor.id }); // Push initial data
      });
      // Keep fetching paginated results until the number of vendors returned is no longer 100
      while (response?.data?.length === 100) {
        response = await restApiService.get("vendors.lk", { page: page++, items: 100 });
        response.data.forEach((vendor: { name: string; id: string }) => {
          vendors.push({ label: vendor.name, value: vendor.id });
        });
      }
      setVendorOptions(vendors);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    loadVendorsOptions();
  }, []);

  const renderDropdown = () => {
    if (vendorOptions.length > 1) {
      return <InputDropdownFilter name={name} code={code} options={vendorOptions} isRequired={isRequired} />;
    } else {
      return (
        <div>
          <h5>Loading Vendors Filter...</h5>
          <Spinner animation="border" variant="info"></Spinner>
        </div>
      );
    }
  };

  return <>{renderDropdown()}</>;
};

export default VendorsDropdown;
