import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import style from "./dashboard.module.css";
import CustomModal from "../../../modals/customModal";
import { useTypedSelector } from "../../../../reducers";
import { useDispatch } from "react-redux";
import { setShowGuide, setShowWelcome } from "../../../../reducers/vp/vendorSetUpGuideReducer";
import { useTranslation } from "react-i18next";

const WelcomeModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showWelcome = useTypedSelector((state) => state.vendorSetupGuideRed.showWelcome);

  const onGettingStart = () => {
    dispatch(setShowWelcome(false));
    dispatch(setShowGuide(true));
  };

  const onCloseWelcomeModal = () => {
    dispatch(setShowWelcome(false));
  };

  if (!showWelcome) {
    return null;
  }

  return (
    <CustomModal
      header={" "}
      show={showWelcome}
      body={
        <Row>
          <Col className="d-flex flex-column justify-content-center align-items-center px-5">
            <Row>
              <h1>{t("vp.dashboard.vendorGuide.welcomeToPortal")}</h1>
            </Row>
            <Row className="mt-3 mx-0">
              <p className={style.fontSize24}>{t("vp.dashboard.vendorGuide.welcomeGreeting")}</p>
            </Row>
            <Row className="mt-3">
              <Button onClick={() => onGettingStart()}>{t("vp.dashboard.vendorGuide.getStarted")}</Button>
            </Row>
          </Col>
        </Row>
      }
      contentClassName={`${style.modalContent}`}
      modalBodyClass={`${style.welcomeModal}`}
      onHide={() => onCloseWelcomeModal()}
      size={"lg"}
      centered
    />
  );
};

export default WelcomeModal;
