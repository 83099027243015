import { RenderCheckBox, RenderField } from "../../../../../../components/forms/bootstrapFields";
import { email } from "../../../../../../services/validations/reduxFormValidation";
import { CompanyDefaultsTypes } from "../../settingsTypes";

export const VendorSettings: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  /* ------------------------------ col 1 starts---------------------------------------- */
  {
    name: "company_default.vendor_requestor_required",
    tooltip: {
      show: true,
      title: "Requestor/Account Manager will be required when creating a vendor",
      placement: "top",
    },
    label: "Requestor/Account Manager Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vendor.vendor_type.is_required",
    tooltip: {
      show: true,
      title: "Vendor type field will be required while creating a new vendor",
      placement: "top",
    },
    label: "Vendor type field required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vendor.email.is_required",
    tooltip: {
      show: true,
      title: "Vendor email field will be required while creating a new vendor",
      placement: "top",
    },
    label: "Vendor email required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },

  {
    name: "company_default.notify_vendor_approver",
    tooltip: {
      show: true,
      title: "If a vendor updates their information, send an email to notify the vendor's approver",
      placement: "top",
    },
    label: "Notify vendor approver of vendor updates",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  /* ------------------------------ col 1 ends---------------------------------------- */
  /* ------------------------------ col 2 starts---------------------------------------- */
  {
    name: "company_default.vendor.tax_id.is_required",
    tooltip: {
      show: true,
      title: "Tax Id fields will be required while creating a new vendor",
      placement: "top",
    },
    label: "Tax ID Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vendor.payment_term.is_required",
    tooltip: {
      show: true,
      title: "Vendor payment term fields will be required while creating a new vendor",
      placement: "top",
    },
    label: " Vendor payment term required ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vendor.show_tax_form_type",
    tooltip: {
      show: true,
      title: "Tax form, Tax form name and tax form type all these fields will be visible on vendor form",
      placement: "top",
    },
    label: "Show tax form fields",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vendor.subtype.is_required",
    tooltip: {
      show: true,
      title:
        "Vendor Classification/SubType field will be required on vendor forms and on PO request form, while creating a new vendor. ",
      placement: "top",
    },
    label: "Vendor Classification/SubType field required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },

  /* ------------------------------ col 2 ends---------------------------------------- */
  /* ------------------------------ col 3 starts---------------------------------------- */
  {
    name: "company_default.vendor.address.is_required",
    tooltip: {
      show: true,
      title:
        "Vendor address fields will be required while creating a new vendor from Request PO form, on add and edit vendor form",
      placement: "top",
    },
    label: "Vendor address required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vendor_portal_disabled",
    tooltip: {
      show: true,
      title: "Vendors will not be allowed to see or submit data through the Vendor Portal.",
      placement: "top",
    },
    label: "Disable Portal",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vendor.is_required_type_id",
    tooltip: {
      show: true,
      title: "Vendor type id fields will be required field.",
      placement: "top",
    },
    label: "Vendor type id required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vendor.po_not_required.show_selected",
    tooltip: {
      show: true,
      title: "PO Not Required flag will be selected while creating a vendor only.",
      placement: "top",
    },
    label: "PO Not Required flag show selected.",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vendor.skip_vendor_contact_for_add_vendor",
    tooltip: {
      show: true,
      title: "Allow to skip vendor contact for Add Vendor",
      placement: "top",
    },
    label: "Allow to skip vendor contact for Add Vendor",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vendor.notify_payment_method_updates_email",
    tooltip: {
      show: true,
      title:
        "(optional): Enter an email to send an email notification to if any changes are made to any Vendor Payment Methods",
      placement: "top",
    },
    label: "Notify of any changes to Vendor Payment Methods:",
    type: "email",
    component: RenderField,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [email],
    require: undefined,
    class: "",
  },
  /* ------------------------------ col 3 ends---------------------------------------- */
];
