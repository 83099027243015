import { shallowEqual, useSelector } from "react-redux";
import TabNavigation from "../../../components/navigation/tabNavigation";

//NOTE: add only those route which we want to show on tabNavigations
export const administrationModulesRoutes = (currentUser) => [
  {
    path: "/ap/approval_workflows",
    pageName: "Approval Settings",
    authorization: {
      I: "allow",
      a: "ApprovalSetting",
    },
  },
  {
    path: "",
    pageName: "Categories",
    href: "categories",
  },
  {
    path: "/ap/mcc_defaults",
    pageName: "MCC Defaults",
  },
  {
    path: "",
    pageName: "Activities",
    href: "activities",
    authorization: {
      I: "activities",
      a: "Administrations",
    },
  },
  {
    path: "",
    pageName: "Catalogue Items",
    href: "catalogue_items",
    authorization: {
      I: "list",
      a: "CatalogueItems",
    },
  },
  {
    path: "",
    pageName: "Bulk Operations",
    href: "imports",
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/bulk_uploads/add",
    pageName: "Bulk Operations 2.0",
    authorization: {
      I: "import",
      a: "BulkOperations2",
    },
  },
  {
    path: "/ap/wombatifier",
    pageName: "Bulk Operations Mapper",
    authorization: {
      I: "import",
      a: "BulkOperations2Mapper",
    },
  },
  {
    path: "/ap/metadata_configurations/edit",
    pageName: "Form Configuration",
    authorization: {
      I: "metadata",
      a: "_visible",
    },
  },
  {
    path: "",
    pageName: "Transactions",
    href: "journal",
    authorization: {
      I: "list",
      a: "Transactions",
    },
  },
  {
    path: "/ap/fraud_audits",
    pageName: "Fraud Audit",
    authorization: {
      I: "allow",
      a: "SystemRole",
    },
  },
  {
    path: "",
    pageName: "Company Profile",
    href: "company",
    authorization: {
      I: "read",
      a: "companyProfile",
    },
  },
  {
    path: "/ap/settings",
    pageName: "Settings",
    authorization: {
      I: "allow",
      a: "CompanySettings",
    },
  },
];

const NavTabs = ({ activePageName }) => {
  const currentUser = useSelector((state) => state.user, shallowEqual);
  return <TabNavigation activePageName={activePageName} navigationTab={administrationModulesRoutes(currentUser)} />;
};

export default NavTabs;
