import React, { ReactNode, memo } from "react";
import { WrappedFieldProps } from "redux-form";
import SingleBudgetPicker from "./singleBudgetPicker";
import MultipleBudgetPicker from "./multipleBudgetPicker";

export interface BudgetPickerPropsTypes extends WrappedFieldProps {
  modelData: any;
  parentObjData?: any;
  isMulti?: boolean;
  label?: string;
  required?: boolean;
  tooltip?: ReactNode;
  placeholder?: string;
  menuPosition?: string;
  callBack?: (budget: any) => void;
  instanceId?: string;
}

const BudgetPicker = ({
  input,
  isMulti,
  parentObjData,
  modelData,
  label,
  tooltip,
  placeholder,
  menuPosition,
  callBack,
  ...rest // storing remain props in rest
}: BudgetPickerPropsTypes) => {
  return (
    <>
      {isMulti ? (
        <MultipleBudgetPicker
          label={label}
          modelData={modelData}
          parentObjData={parentObjData}
          input={input}
          callBack={callBack}
          {...rest}
        />
      ) : (
        <SingleBudgetPicker label={label} modelData={modelData} parentObjData={parentObjData} input={input} {...rest} />
      )}
    </>
  );
};

export default BudgetPicker;
