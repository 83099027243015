import { Field } from "redux-form";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FileUploaderNonModal } from "components/common/fileUploader/fileUploaderNonModal";
import { fileNameSafe, noWhiteSpaceOnly, required } from "services/validations/reduxFormValidation";

import { WombatInput } from "wombatifier/components/pickers/wombatInput";
import { WombatSelect } from "wombatifier/components/pickers/wombatSelect";

import { WombatSvc, WOMBAT_WORKFLOW_TYPES } from "wombatifier/services/wombatSvc";
import { FileType, WombatWorkflowType } from "wombatifier/services/wombat.types";
import { WombatApis } from "wombatifier/services/wombatApis";
import { sortBy } from "lodash";
import { RootState, useTypedSelector } from "reducers";
import { useDispatch } from "react-redux";

const MAX_FILE_SIZE = Math.pow(10, 6) * 25.0;

interface MapperHeadersPropsType {
  id?: string;
  submit: any;
  valid: boolean;
  updateLocalFiles: (file_attributes: FileType[]) => void;
  addColumn: () => void;
}

export const MapperHeaders = ({ id, submit, valid, updateLocalFiles, addColumn }: MapperHeadersPropsType) => {
  const [destinationTypeOptions, setDestinationTypeOptions] = useState<{ label: string; value: string }[]>([]);
  const hasWombatFields = useTypedSelector((state: RootState) => WombatSvc.hasWombatFields(state));
  const chosenWorkflowType = useTypedSelector((state: RootState) => WombatSvc.getWombatWorkflowType(state));
  const chosenDestinationType = useTypedSelector((state: RootState) => WombatSvc.getWombatDestinationType(state));
  const dispatch = useDispatch();

  const onWombatWorkflowTypeChange = useCallback(async (workflowType: WombatWorkflowType) => {
    let destinationTypes: string[] = [];
    switch (workflowType) {
      case "file_integration_ingest_csv":
        destinationTypes = await WombatApis.inboundDestinationTypes({});
        break;
      case "file_integration_outbound":
        destinationTypes = await WombatApis.outboundDestinationTypes({});
        break;
    }
    const destinationOptions = sortBy(
      destinationTypes.map((h) => ({ label: h, value: h })),
      (item) => item.label,
    );
    setDestinationTypeOptions(destinationOptions);
  }, []);

  const onWombatDestinationTypeChange = useCallback(
    async (destinationType: string, workflowType: WombatWorkflowType) => {
      try {
        switch (workflowType) {
          case "file_integration_ingest_csv":
            const inboundSchema = await WombatApis.inboundsSchema({
              filter: { destination_type: destinationType.replace("nvp_", "") },
            });
            WombatSvc.updateMappingToListOutbound(inboundSchema, dispatch);
            break;
          case "file_integration_outbound":
            const outboundSchema = await WombatApis.outboundsSchema({
              filter: { destination_type: destinationType.replace("nvp_", "") },
            });
            WombatSvc.updateMappingToListOutbound(outboundSchema, dispatch);
            break;
        }
      } catch (err) {
        console.error(err);
      }
    },
    [],
  );

  useEffect(() => {
    if (chosenWorkflowType) {
      onWombatWorkflowTypeChange(chosenWorkflowType);
    }
  }, [chosenWorkflowType]);

  useEffect(() => {
    if (chosenDestinationType && chosenWorkflowType) {
      onWombatDestinationTypeChange(chosenDestinationType, chosenWorkflowType);
    }
  }, [chosenDestinationType, chosenWorkflowType]);

  return (
    <>
      <Row className="mx-0 d-flex justify-content-between">
        <Col className="px-0" md={3}>
          <FileUploaderNonModal
            onFilesUpdated={updateLocalFiles}
            maxFiles={1}
            maxSize={MAX_FILE_SIZE}
            containerClassName="h-100"
            className="h-100"
          />
        </Col>
        <Col className="">
          <Row className="mx-0">
            <Col>
              <Field
                name="form.source_type"
                component={WombatInput}
                label="Mapping Name / Source Type"
                validate={[required, fileNameSafe, noWhiteSpaceOnly]}
                labelClassName="font-weight-bold"
                isRequired={true}
                maxLength={100}
              />
            </Col>

            <Col md={2}>
              <Field
                name="form.schema_version"
                component={WombatInput}
                label="Version"
                labelClassName="font-weight-bold"
                maxLength={10}
              />
            </Col>
            <Col>
              <Field
                name="form.workflow_type"
                component={WombatSelect}
                label="File Type"
                labelClassName="font-weight-bold"
                options={WOMBAT_WORKFLOW_TYPES}
                validate={[required]}
                isRequired={true}
                isSearchable={true}
              />
            </Col>
          </Row>
          {destinationTypeOptions.length ? (
            <Row className="mx-0">
              <Col>
                <Field
                  name="form.destination_type"
                  component={WombatSelect}
                  label="Destination Type"
                  labelClassName="font-weight-bold"
                  options={destinationTypeOptions}
                  validate={[required]}
                  isRequired={true}
                  isSearchable={true}
                />
              </Col>
            </Row>
          ) : null}

          <Row className="mx-0">
            <Col>
              <Field
                name="form.description"
                component={WombatInput}
                label="Description"
                labelClassName="font-weight-bold"
                numberOfLines={4}
                maxLength={255}
              />
            </Col>
          </Row>
          <Row className="mx-0">
            <Col className="d-flex align-items-center justify-content-end">
              <Button type="button" variant="primary ml-2" onClick={submit} disabled={!hasWombatFields || !valid}>
                {id ? "Update " : "Save "}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mx-0 mt-5 overflow-scroll">
        <Col className="px-0">
          <Button onClick={addColumn}>Add Column</Button>
        </Col>
      </Row>
    </>
  );
};
