import FileUploader from "components/common/fileUploader/fileUploader";
import Panel from "components/common/panel/panel";
import { RenderSelect } from "components/forms/bootstrapFields";
import TabNavigation from "components/navigation/tabNavigation";
import { sortBy } from "lodash";
import NavTabs from "pages/admin/administration/nav";
import RestApi from "providers/restApi";
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect, shallowEqual, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTypedSelector } from "reducers";
import { change, Field, getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import { capitalizeEachWord } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { WombatType } from "wombatifier/services/wombat.types";
import { WombatApis } from "wombatifier/services/wombatApis";
import { WombatSvc } from "wombatifier/services/wombatSvc";
import styles from "./bulkUpload.module.css";

const DownloadTemplateLink = ({
  url,
  fileName,
  displayName,
  onClick,
}: {
  url?: string;
  fileName: string;
  displayName: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}) => {
  const { t } = useTranslation();

  return (
    <span>
      {t("admin.pages.bulkUploads.importTemplate")}&nbsp;
      <a
        target="_blank"
        href={url}
        download={fileName}
        className="link-primary text-decoration-underline text-info"
        rel="noreferrer"
        onClick={onClick}
      >
        {displayName}
      </a>
    </span>
  );
};

const CSVTemplateLink = ({
  columnNames,
  filename,
  displayName,
}: {
  columnNames: string[];
  filename: string;
  displayName: string;
}) => {
  const csvBlob = new Blob([columnNames.join(",")], { type: "text/csv" });
  const url = window.URL.createObjectURL(csvBlob);
  return (
    <DownloadTemplateLink
      url={url}
      fileName={filename}
      displayName={displayName}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

const TemplateLink = ({ currentWombat }: { currentWombat: WombatType }) => {
  const [wombatTemplate, setWombatTemplate] = useState<{ hasTemplate: boolean | null; path: string }>({
    hasTemplate: null,
    path: "",
  });

  useEffect(() => {
    setWombatTemplate({ hasTemplate: null, path: "" });

    if (!currentWombat) {
      return;
    }

    import(`assets/wombatTemplates/${currentWombat.name}.xlsx`)
      .then((file) => {
        setWombatTemplate({ hasTemplate: true, path: file.default });
      })
      .catch(() => {
        setWombatTemplate({ hasTemplate: false, path: "" });
      });
  }, [currentWombat?.name]);

  // Currently no way of getting customer files for Rosetta to dynamically generate
  if (currentWombat?.workflow_type === "file_integration_ingest_pmg_rosetta") {
    return <></>;
  }

  if (wombatTemplate.hasTemplate) {
    return (
      <DownloadTemplateLink
        url={wombatTemplate.path}
        fileName={`${currentWombat?.name}.xlsx`}
        displayName={currentWombat?.name}
      />
    );
  }

  if (wombatTemplate.hasTemplate === false && currentWombat?.config.wombat_fields.length) {
    return (
      <CSVTemplateLink
        columnNames={WombatSvc.flattenNestedValueMappedFroms(currentWombat.config.wombat_fields)}
        filename={`${currentWombat.source_type}_template.csv`}
        displayName={currentWombat.source_type}
      />
    );
  }

  return <></>;
};

interface FormData {
  wombat_id: string;
  uploadedFile: File | null;
}

const FORM_NAME = "bulkUploadForm";

let BulkUploadFilesPage = ({ handleSubmit }: InjectedFormProps<FormData, {}>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [documentTypeOptions, setDocumentTypeOptions] = useState<{ [key: string]: string }[]>([]);
  const [isShowDefaultWombatChecked, setIsShowDefaultWombatChecked] = useState(false);

  const form: any = useTypedSelector((state) => getFormValues(FORM_NAME)(state), shallowEqual);
  const currentUser = useTypedSelector((state) => state.user, shallowEqual);
  const wombatsHashRef = useRef<{ [key: string]: WombatType }>({});
  const currentWombat: WombatType | undefined = wombatsHashRef.current[form?.wombat_id];

  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const uploadAttachments = (files: File[]) => dispatch(change(FORM_NAME, "uploadedFile", files[0]));
  const removeAttachment = () => dispatch(change(FORM_NAME, "uploadedFile", null));

  const setupDocumentTypeOptions = useCallback(
    async (showDefaultWombats: boolean) => {
      let wombats: WombatType[] = [];
      try {
        const wombatResults = await WombatApis.inbounds({
          filter: {},
          cache: false,
        });
        if (wombatResults) {
          wombats = wombatResults;
        } else {
          wombats = [];
        }
      } catch (err) {
        CreateNotification(
          t("admin.pages.bulkUploads.errorFileTypeFetch"),
          t("admin.pages.bulkUploads.errorCausedBy", { error: err }),
          NotificationType.danger,
        );
      }
      wombatsHashRef.current = wombats.reduce(
        (hash, wombat) => {
          hash[wombat.id.toString()] = wombat;
          return hash;
        },
        {} as { [key: string]: WombatType },
      );

      const optionList: { [key: string]: string }[] = [];
      wombats.forEach((wombat) => {
        const isGlobal = !wombat.company_id;
        let label: string;
        if (isGlobal && showDefaultWombats) {
          label = `STANDARD ${capitalizeEachWord(wombat.destination_type)}`;
          optionList.push({ value: wombat.id.toString(), label: label });
        } else if (!isGlobal) {
          label = wombat.name;
          optionList.push({ value: wombat.id.toString(), label: label });
        }
      });

      setDocumentTypeOptions([
        { value: "", label: "No file type selected" },
        ...sortBy(optionList, (item) => item.label),
      ]);
    },
    [t],
  );

  const handleShowDefaultWombatsCheckboxChange = useCallback(() => {
    setIsShowDefaultWombatChecked((prev) => !prev);
  }, []);

  const onSubmit = useCallback(
    async (values: FormData) => {
      if (!values.uploadedFile) {
        return;
      }

      const restApiService = new RestApi();
      try {
        setLoading(true);
        const wombat = wombatsHashRef.current[values.wombat_id];
        const newDocument = await restApiService.post("/documents", null, {
          document: {
            name: values.uploadedFile.name,
            documentable_type: "Company",
            documentable_id: currentUser.company.id,
            document_type: wombat.source_type,
            status: "NEW",
          },
        });

        if (newDocument) {
          const formData = new FormData();
          formData.append("document[assets_attributes[0]asset_file]", values.uploadedFile);
          formData.append("document[assets_attributes[0]file_name]", values.uploadedFile.name);

          const uploadRes = await restApiService.formWithImage("documents/" + newDocument.data.id, formData, "patch");
          if (uploadRes.data) {
            CreateNotification(
              t("admin.pages.bulkUploads.saved"),
              t("admin.pages.bulkUploads.savedMsg"),
              NotificationType.success,
            );
          }
          history.push("/ap/bulk_uploads");
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        CreateNotification(
          t("admin.pages.bulkUploads.error"),
          t("admin.pages.bulkUploads.errorMsg", { error }),
          NotificationType.danger,
        );
      }
    },
    [currentUser.company.id, history, t],
  );

  useEffect(() => {
    setupDocumentTypeOptions(isShowDefaultWombatChecked);
  }, [isShowDefaultWombatChecked, setupDocumentTypeOptions]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    // If the destination_type is in the query params (e.g. ?destination_type=user) and the documentTypeOptions are loaded
    // then select the first option that matches the destination_type and it's a standard templates (company_id is null)
    // also check the "Show Standard Templates" checkbox to show the standard templates in the options
    // and remove the destination_type from the query params to avoid selecting the same option again
    if (queryParams.has("destination_type") && documentTypeOptions.length) {
      const option = Object.values(wombatsHashRef.current).find(
        (option) => option.destination_type === queryParams.get("destination_type") && option.company_id == null,
      );

      setIsShowDefaultWombatChecked((prev) => !prev);
      dispatch(change(FORM_NAME, "wombat_id", `${option?.id}`));

      queryParams.delete("destination_type");
      history.replace({ search: queryParams.toString() });
    }
  }, [dispatch, documentTypeOptions.length, history, search]);

  return (
    <Container fluid={true}>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"Bulk Operations 2.0"} />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Panel>
        <TabNavigation
          navigationTab={[
            {
              path: "/ap/bulk_uploads/add",
              pageName: "Upload",
              isActive: "active",
            },
            {
              path: "/ap/bulk_uploads",
              pageName: "All Uploads",
              isActive: "",
            },
          ]}
        />
        <p className={"fontSizeNormal mb-3"}>{t("admin.pages.bulkUploads.description")}</p>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="m-0 d-flex align-items-end pr-3">
              <Col className="pl-0 mr-4">
                <label>
                  <input
                    type="checkbox"
                    checked={isShowDefaultWombatChecked}
                    onChange={handleShowDefaultWombatsCheckboxChange}
                  />{" "}
                  {t("admin.pages.bulkUploads.showStandardTemplates")}
                </label>
              </Col>
            </Row>
            <Row className="m-0 d-flex align-items-end pr-3">
              {documentTypeOptions.length ? (
                <>
                  <Col className="px-0">
                    <Row className="mx-0">
                      <Col className="px-0 font-weight-bold" style={{ maxWidth: "fit-content" }}>
                        <Form.Label>{t("admin.pages.bulkUploads.fileType")}</Form.Label>
                      </Col>
                      <Col className="px-2" style={{ maxWidth: "fit-content" }}>
                        <Link to={`/ap/wombatifier/add`}>{t("admin.pages.bulkUploads.addFileType")}</Link>
                      </Col>
                    </Row>
                    <Field name="wombat_id" className="m-0" component={RenderSelect} options={documentTypeOptions} />
                  </Col>
                  <Col className="pl-2 pr-0" style={{ maxWidth: "fit-content" }}>
                    <FileUploader
                      showUploadBtn
                      uploadAttachments={uploadAttachments}
                      buttonText={t("admin.pages.bulkUploads.attachFile")}
                    />
                  </Col>
                </>
              ) : null}
            </Row>
            <Row>
              <Col className="pr-0 mt-2" style={{ maxWidth: "fit-content" }}>
                <TemplateLink currentWombat={currentWombat} />
              </Col>
            </Row>

            {form?.uploadedFile && (
              <div className="mt-2">
                <label>{t("admin.pages.bulkUploads.attachedFile")}</label>
                <div>
                  {form.uploadedFile?.name}
                  <button type="button" onClick={removeAttachment} className={`text-danger ${styles.removeFileButton}`}>
                    X
                  </button>
                </div>
              </div>
            )}

            <div className="mt-4">
              <button type="submit" disabled={loading || !form?.uploadedFile}>
                {t("admin.pages.bulkUploads.submit")}
              </button>
            </div>
          </form>
        </div>
      </Panel>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({ initialValues: state.bulkUploadForm });

const BulkUploadForm = connect(mapStateToProps)(
  reduxForm<FormData>({
    form: FORM_NAME,
    // onSubmit,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true,
    destroyOnUnmount: true,
    // updateUnregisteredFields: true,
  })(BulkUploadFilesPage),
);

export default BulkUploadForm;
