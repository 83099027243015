import React, { useState } from "react";
import { useTypedSelector } from "reducers";
import { ApprovalType } from "services/admin/approvals/approvalsType";
import { companyDateFormat } from "services/general/dateSvc";
import style from "./approval.module.css";
import { CreateNotification, NotificationType } from "services/general/notifications";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import CustomModal from "components/modals/customModal";
import ApproverDetails from "./approverDetails";
import ApprovalsApis from "services/admin/approvals/approvalsApis";
import { IDType } from "services/common/types/common.type";

type ApprovalRowPropsType = {
  endpoint: string;
  modalData: object;
  modelName: string;
  approval: ApprovalType.ApprovalType;
  allowAccess: boolean;
  refreshApprovable: () => void;
};

const ApprovalRow = ({
  endpoint,
  modalData,
  approval,
  allowAccess,
  modelName,
  refreshApprovable,
}: ApprovalRowPropsType) => {
  const currentUser = useTypedSelector((state) => state.user);
  const { createConfirmModal } = useConfirmModal();
  const [isApproverDetailModalOpen, setIsApproverDetailModalOpen] = useState(false);

  const isAllowResend = function (approval: ApprovalType.ApprovalType) {
    return approval.aasm_state === "sent" || approval.aasm_state === "viewed";
  };

  const deleteApprover = async () => {
    try {
      if ("id" in modalData && approval.id) {
        const response = await ApprovalsApis.deleteApproval(approval.id);
        refreshApprovable();
        CreateNotification("Deleted", "Approvals Deleted", NotificationType.success);
      }
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        if (typeof error.response === "object" && error.response !== null && "status" in error.response) {
          CreateNotification(
            "Failed with error code " + error.response.status,
            "Could not start approval workflow. Please double check the data and try again.",
            "warning",
          );
        }
      }
    }
  };

  const confirmDeleteApprover = (approval: ApprovalType.ApprovalType) => {
    createConfirmModal({
      title: "Confirm",
      body: "Are you sure you want to delete this approver?",
      callBackData: null,
      saveCallBack: deleteApprover,
      cancelCallBack: null,
    });
  };

  const getStatusClassName = (approval: ApprovalType.ApprovalType) => {
    if (approval.aasm_state === "viewed") {
      return style.labelWarning;
    } else if (approval.aasm_state === "approved") {
      return style.labelSuccess;
    } else if (approval.aasm_state === "rejected") {
      return style.labelDanger;
    } else {
      return style.labelDefault;
    }
  };

  const send = async () => {
    try {
      const result = await ApprovalsApis.makeMessage(approval.id as IDType);
      refreshApprovable();
      CreateNotification("Send", "Approval sent", "success");
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        if (typeof error.response === "object" && error.response !== null && "status" in error.response) {
          CreateNotification(
            "Failed with error code " + error.response.status,
            "Could not start approval workflow. Please double check the data and try again.",
            "warning",
          );
        }
      }
    }
  };

  const showApproverDetails = () => {
    setIsApproverDetailModalOpen(true);
  };

  const hideApproverDetails = () => {
    setIsApproverDetailModalOpen(false);
  };

  const claimApproval = async () => {
    try {
      if (approval?.id) {
        await ApprovalsApis.claimApproval(approval?.id);
        CreateNotification("Claim", "Approval Claim", NotificationType.success);
        refreshApprovable();
      }
    } catch (error) {}
  };

  const getApprovalStatusLabel = (aasmState: string): string => {
    const expenseModelLabels: { [key: string]: string } = {
      viewed: "Pending Approval",
      sent: "Pending Approval",
      new: "Next to Approve",
    };
    if (modelName === "expense" && aasmState && expenseModelLabels[aasmState]) {
      return expenseModelLabels[aasmState];
    } else {
      return aasmState;
    }
  };

  return (
    <>
      {isApproverDetailModalOpen && (
        <CustomModal
          show={isApproverDetailModalOpen}
          onHide={hideApproverDetails}
          header={<>Approval Details</>}
          body={<ApproverDetails approval={approval} getApprovalStatusLabel={getApprovalStatusLabel} />}
          size="lg"
        ></CustomModal>
      )}

      <tr>
        <td>{approval.name}</td>
        <td>{companyDateFormat(approval.updated_at, currentUser)}</td>
        <td>{companyDateFormat(approval.approved_at, currentUser)}</td>
        <td>{companyDateFormat(approval.rejected_at, currentUser)}</td>
        <td>
          {approval.name} <br />
          {approval.email ? "<" + approval.email + ">" : ""}
          {approval.delegate_name && (
            <span>
              <br />
              {approval.delegate_name} (Delegate) <br />
              {approval.delegate_email ? "<" + approval.delegate_email + ">" : ""}
            </span>
          )}
        </td>

        <td>
          <span className={style.approvalState + " " + getStatusClassName(approval)}>
            {approval.aasm_state && getApprovalStatusLabel(approval.aasm_state)}
          </span>
        </td>

        <td>
          {isAllowResend(approval) && !approval.is_action && (
            <button
              className="btn"
              onClick={() => send()}
              id="start_approval_resend"
              data-toggle="tooltip"
              title="Send Request"
            >
              <i className="icon icon-envelope-primary"></i>
            </button>
          )}

          <button
            className="btn"
            onClick={showApproverDetails}
            id="request_approval_info"
            data-toggle="tooltip"
            title="View"
          >
            <i className="icon icon-view"></i>
          </button>

          {approval.email === currentUser.email &&
            currentUser?.company?.allow_claim_po &&
            isAllowResend(approval) &&
            !approval.is_claimed && (
              <button
                className="btn"
                onClick={() => claimApproval()}
                id="claim_approval"
                data-toggle="tooltip"
                title="Claim Approval"
              >
                <i className="icon icon-approval-claimed"></i>
              </button>
            )}

          {approval.is_claimed && (
            <button
              className="btn"
              id="claimed_approval"
              data-toggle="tooltip"
              disabled={approval.is_claimed}
              title="Approval Claimed"
            >
              <i className="icon icon-approval-claimed"></i>
            </button>
          )}

          {allowAccess && (
            <button
              className="btn"
              onClick={() => confirmDeleteApprover(approval)}
              id="request_approval_delete"
              data-toggle="tooltip"
              title="Delete"
            >
              <i className="icon icon-delete"></i>
            </button>
          )}
        </td>
      </tr>
    </>
  );
};

export default ApprovalRow;
