import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { onBlurSingle, onChangeSingle, parseForSelect } from "services/general/helpers";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "components/common/pickers/reduxFormPickers/select/helpers";
import { restApiService } from "providers/restApi";
import _ from "lodash";
import { Mandatory } from "components/forms/input/mandatory";
import { VendorType } from "services/admin/vendors/vendorTypes";
import { useTypedSelector } from "reducers";
import { getFormValues } from "redux-form";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";

type OptionsType = {
  label: string;
  value: number;
};

type VendorContactPropsType = {
  label?: string;
  input: {
    value: string;
    onChange: (value: string) => void;
  };
  meta: {
    touched: boolean;
    error?: string;
    warning?: string;
  };
  tooltip?: string;
  labelClassName?: string;
  params?: Record<string, any>;
  required?: boolean;
  inputId?: string;
  placeholder?: string;
  disabled?: boolean;
  originalValue?: ReactNode;
  callBack?: (value: any) => void;
  floating?: boolean;
};

type VendorPickerPropType = VendorContactPropsType;

const VendorContactPicker = ({
  label,
  input,
  meta: { touched, error },
  tooltip,
  params,
  required,
  inputId,
  placeholder,
  disabled,
  originalValue,
  floating,
  callBack,
}: VendorPickerPropType) => {
  const vendor: VendorType = useTypedSelector((state) => getFormValues("VendorForm")(state));
  const [options, setOptions] = useState<OptionsType[]>();
  const [selected, setSelected] = useState<any>();
  const mounted = useRef(false);

  const pickerProps = {
    ...input,
    required,
    placeholder,
    options: options,
    isClearable: true,
    isDisabled: disabled,
    inputId: inputId || "options-selector",
  };

  const getOptions = useCallback(async () => {
    if (vendor.master_id) {
      try {
        const result = await restApiService.get(`companies/${vendor.master_id}/vendor_contacts`);
        const value = parseForSelect(result.data);
        if (mounted.current) setOptions(value);
        return value;
      } catch (error) {}
    }
  }, [params]);

  const findSelected = async (input: { value: number | string }, options: { value: number; label: string }[]) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    getOptions().then((res) => {
      findSelected(input, res).then((response) => {
        setSelected(response);
      });
    });
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleChange = (value: any) => {
    setSelected(value);
    onChangeSingle(input, value);
    if (callBack) {
      callBack(value);
    }
  };

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...pickerProps}
          menuPlacement="auto"
          menuPosition="fixed"
          value={selected}
          onChange={(value) => handleChange(value)}
          onBlur={() => onBlurSingle(input, input.value)}
          classNamePrefix="select"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
            }),
            ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
          }}
          {...(floating ? { menuPortalTarget: document.body } : {})}
        />
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default VendorContactPicker;
