import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import CommonSettingField from "../../components/commonSettingField";
import VCardSettings from "./vCardSettings";

const VCardSetting = () => {
  const formName = "companyDefaultForm";
  const dispatch = useDispatch();

  const isShowObj = {};
  const company_default = useSelector((state) => state.form.companyDefaultForm.values.company_default);
  const company = useSelector((state) => state.form.companyDefaultForm.values.company);

  const current_user = useSelector((state) => state.user);
  isShowObj.company_default = company_default;
  isShowObj.company = company;
  isShowObj.current_user = current_user;
  const onChangeObj = {};
  onChangeObj.formName = formName;
  onChangeObj.dispatch = dispatch;
  onChangeObj.company_default = company_default;
  onChangeObj.company = company;

  return (
    <Container fluid={true} className="mt-3">
      <Row>
        <Col md="4">
          {/* coloum no. 1 */}
          {VCardSettings.filter((setting) => setting.col === 1).map((setting, index) => (
            <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
          ))}
        </Col>
        <Col md="4">
          {/* coloum no. 2 */}
          {VCardSettings.filter((setting) => setting.col === 2).map((setting, index) => (
            <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
          ))}
        </Col>
        <Col md="4">
          {/* coloum no. 3 */}
          {VCardSettings.filter((setting) => setting.col === 3).map((setting, index) => (
            <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default VCardSetting;
