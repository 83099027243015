import { ColDef } from "ag-grid-community";
import { sortBy } from "lodash";
import { setAllowedFilters } from "services/common/gridService";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { companyDateFormat } from "services/general/dateSvc";
import { translate } from "services/general/translation";

export const FIELD_NAME_MAP: FieldFilterMapType = {
  name: {
    contains: "name",
  },
  created_at: {
    inRange: "",
    lessThanOrEqual: "created_at_before",
    greaterThanOrEqual: "created_at_after",
  },
};

export const getBulkUploadsHeaders = ({
  currentUser,
}: {
  currentUser: any;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs = [
    {
      field: "created_at",
      headerName: "Date",
      sortable: true,
      filter: "agDateColumnFilter",
      hide: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      cellRenderer: (params: any) => {
        return companyDateFormat(params.value, currentUser);
      },
      headerValueGetter: function () {
        return translate("date");
      },
      minWidth: 120,
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: "agGroupCellRenderer",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      floatingFilterComponent: "agCustomSelectFilter",
      floatingFilterComponentParams: {
        suppressFilterButton: true,
        options: [
          {
            label: "ALL",
            value: "",
          },
          {
            label: "COMPLETED",
            value: "COMPLETED",
          },
          {
            label: "NEW",
            value: "NEW",
          },
          {
            label: "COMPLETED_WITH_ERRORS",
            value: "COMPLETED_WITH_ERRORS",
          },
        ],
      },
      headerValueGetter: function () {
        return translate("status");
      },
    },
    {
      field: "document_type",
      headerName: "Document Type",
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return "Document Type";
      },
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      filter: "agTextColumnFilter",
      cellClass: "routingCellButtonImportant",
      filterParams: {
        suppressAndOrCondition: true,
      },
      onCellClicked: (event: any) => {
        if (event && event.data && event.data.assets && event.data.assets.length > 0) {
          window.open(event.data.assets[0].asset_expiring_url, "_blank");
        }
      },
      headerValueGetter: function () {
        return translate("name");
      },
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      filter: false,
      headerValueGetter: function () {
        return translate("id");
      },
    },
  ];
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return { columnDefs: sortBy(columnDefs, (col) => col.headerValueGetter()), defaultOrder };
};
