import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { Form } from "react-bootstrap";
import restApi from "../../../../providers/restApi";
import Select from "react-select";
import Styles from "../override.module.css";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../services/general/helpers";
import { Mandatory } from "../../../forms/bootstrapFields";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "../../../common/pickers/reduxFormPickers/select/helpers";

const LocationPicker = (props) => {
  let {
    className,
    labelClassName,
    label,
    input,
    meta: { touched, error, warning },
    isMulti,
    tooltip,
    templateUrl,
    required,
    menuPosition,
    inputId,
    placeholder = "search/select",
    disabled = false,
    originalValue,
  } = props;

  const [locations, setLocations] = useState([]);
  const [originalLabel, setOriginalLabel] = useState(null);
  const mounted = useRef(false);

  const restApiService = useMemo(() => new restApi(), []);

  const getLocations = useCallback(async () => {
    const result = await restApiService.get("locations", null, null, true, null, true);
    const value = parseForSelect(result.data);
    if (mounted.current) setLocations(value);
  }, [restApiService]);

  useEffect(() => {
    getLocations();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [getLocations]);

  useEffect(() => {
    if (originalValue && locations.length > 0) {
      setOriginalLabel(findSelectedSingle({ value: originalValue }, locations)?.label);
    }
  }, [locations, originalValue]);

  if (locations.length) {
    return (
      <Form.Group className={Styles.select}>
        {label && (
          <Form.Label className={labelClassName}>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          {...(originalValue !== undefined
            ? { components: { Input: (props) => <PickerComparisonField {...props} originalValue={originalLabel} /> } }
            : {})}
          value={isMulti ? findSelectedMultiple(input, locations) : findSelectedSingle(input, locations)}
          placeholder={placeholder}
          onChange={isMulti ? (value) => onChange(input, value) : (value) => onChangeSingle(input, value)}
          onBlur={isMulti ? () => onBlur(input, input.value) : () => onBlurSingle(input, input.value)}
          options={locations}
          isMulti={isMulti ? true : false}
          isClearable
          classNamePrefix="select"
          menuPosition={menuPosition ? menuPosition : null}
          isDisabled={disabled}
          inputId={inputId ? inputId : "location-selector"}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
            }),
          }}
        />
        {error && touched && (
          <div
            style={{
              fontSize: "80%",
              color: "red",
              padding: "2px",
            }}
          >
            {" "}
            Location Required...{" "}
          </div>
        )}
      </Form.Group>
    );
  } else {
    return null;
  }
};

export default LocationPicker;
