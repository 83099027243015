import _ from "lodash";
import React, { useEffect, useState } from "react";
import { emailTagsOptionType, emailTagsOptions } from "../../../../services/admin/invoices/emailSvc";
import emailStyles from "./email.module.css";
import CustomModal from "../../../modals/customModal";
import { Button, Col, Form, ListGroup } from "react-bootstrap";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import RestApi from "../../../../providers/restApi";
import useConfirmModal from "../../../modals/confirmModal/useConfirmModalHook";
import { getEmailTags } from "../../../../reducers/admin/emailReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";

const restApiService = new RestApi();

export type emailTagSelectorProps = {
  closeButton: () => void;
  applyEmailTags: (tags: string[]) => Promise<void>;
};

const EmailTagSelector = ({ closeButton, applyEmailTags }: emailTagSelectorProps) => {
  const dispatch = useDispatch();
  const emailTags = useSelector((state: RootState) => state.emailRed.tags);
  const emailDetails = useSelector((state: RootState) => state.emailRed.emailDetails);

  const [options, setOptions] = useState(_.cloneDeep(emailTagsOptions));
  const [validated, setValidated] = useState(false);
  const [newTagName, setNewTagName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { createConfirmModal } = useConfirmModal();

  // keep our local copy of the email tags synked with the global state
  useEffect(() => {
    const deepClonedOptions = _.cloneDeep(emailTagsOptions);
    if (_.isArray(emailTags)) {
      setOptions([...deepClonedOptions, ...emailTags]);
    }
  }, [emailTags]);

  const toUpperCase = (obj: emailTagsOptionType) => {
    if (obj) {
      if (obj.option) {
        return obj.option.toUpperCase();
      } else if (obj.label) {
        return obj.label.toUpperCase();
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const checkDeletable = (option: emailTagsOptionType) => {
    switch (option.option ? option.option : option.label) {
      case "communication":
        return null;
      case "SPAM":
        return null;
      case "on hold":
        return null;
      case "Duplicate":
        return null;
      default:
        return (
          <div style={{ flex: 2 }}>
            <i role="button" className="ml-5 icon icon-x-close" onClick={() => confirmDeleteTag(option)} />
          </div>
        );
    }
  };

  const deleteTag = async ({ options, deletingTag }: { options: emailTagsOptionType[]; deletingTag: string }) => {
    if (options && options.length !== 0) {
      const filteredTags = options.filter((item) => item.label && item.label !== deletingTag);

      const company_default = {
        inbound_email: { tags: filteredTags },
      };

      try {
        const response = await restApiService.patch("company/default", null, {
          company_default: company_default,
        });
        if (response.status === 200) {
          await getTags();
          CreateNotification("Success", "tag deleted successfully", "success");
        }
      } catch (error) {
        CreateNotification("Error", "Error occurred while deleting tag", NotificationType.danger);
      }
    }
  };

  const confirmDeleteTag = (option: emailTagsOptionType) => {
    const deletingTag = option.option ? option.option : option.label;
    createConfirmModal({
      title: "REMOVE TAG",
      body: `Are you sure want to delete the tag '${deletingTag ? deletingTag.toUpperCase() : ""}' ?`,
      callBackData: { options, deletingTag },
      cancelCallBack: null,
      saveCallBack: deleteTag,
    });
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const addTags = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    setValidated(true);

    if (form.checkValidity() === true && _.isString(newTagName) && newTagName.trim()) {
      const newTag = { label: newTagName.trim() };
      const mergeArray = emailTags && emailTags.length != 0 ? [...emailTags, newTag] : [newTag];
      const company_default = {
        inbound_email: {
          tags: _.uniqBy(mergeArray, "label"),
        },
      };

      try {
        const response = await restApiService.patch("company/default", null, {
          company_default: company_default,
        });
        console.log("respose", response);
        if (response.status === 200) {
          await getTags();
          setNewTagName("");
          CreateNotification("Success", "tag added successfully", "success");
          closeModal();
        }
      } catch (error) {
        CreateNotification("Error", "Error occurred while creating tag", NotificationType.danger);
      }
    }
  };

  const getTags = async () => {
    dispatch(getEmailTags());
  };

  const highlightRow = (selectedOption: emailTagsOptionType) => {
    setOptions((prevOptions) =>
      prevOptions.map((op) => {
        if ((op.option && op.option === selectedOption.option) || (op.label && op.label === selectedOption.label)) {
          if (op.highlightRow) {
            op.highlightRow = false;
          } else {
            op.highlightRow = true;
          }
          return op;
        }
        return op;
      }),
    );
  };

  const selectTags = async () => {
    const selectedTags: string[] = options
      .filter((option) => option.highlightRow)
      .map((option) => {
        if (option.option) {
          return option.option;
        } else if (option.label) {
          return option.label;
        } else {
          return "";
        }
      });

    await applyEmailTags(selectedTags);
  };

  return (
    <>
      <div className="overlay" onClick={() => closeButton()}></div>
      <div className={emailStyles.postionAbsoluteZi2}>
        <div className={emailStyles.createLabelDailog}>
          {/* modal ask to create a new tags*/}
          <CustomModal
            dailog
            modalClass={"createTagsModal"}
            dialogClass={"messageBox"}
            header={"Create Tag"}
            body={
              <Form id="tag-form" noValidate validated={validated} onSubmit={addTags}>
                <Form.Group as={Col} md="12">
                  <Form.Label>Please enter a new tag name</Form.Label>
                  <Form.Control
                    type="text"
                    pattern="[A-Za-z0-9_@./#&+-]+[ A-Za-z0-9_@./#&+-]*$"
                    value={newTagName}
                    required={true}
                    onChange={(e) => {
                      setNewTagName(e.target.value.trimStart());
                    }}
                    maxLength={15}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a new tag name</Form.Control.Feedback>
                </Form.Group>
              </Form>
            }
            footer={
              <>
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
                <Button type="submit" form="tag-form">
                  Create
                </Button>
              </>
            }
            show={showModal}
            onHide={closeModal}
          />

          <ListGroup className={"border"}>
            <div className={emailStyles.tagsContainer}>
              {options.map((option, i) => (
                <ListGroup.Item
                  key={i}
                  role="button"
                  onClick={() => highlightRow(option)}
                  className={`d-flex border-0 pb-0 w-100 ${option?.highlightRow && emailStyles.tagSelected}`}
                >
                  <h5 className={`align-self-center ${emailStyles.flex10}`}>{toUpperCase(option)}</h5>
                  {checkDeletable(option)}
                </ListGroup.Item>
              ))}
            </div>

            <div className={"bg-white d-flex justify-content-between px-2 pt-2"}>
              <Button variant="secondary" onClick={() => closeButton()}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => selectTags()}>
                Apply
              </Button>
            </div>

            <div className={"bg-white p-2 d-flex justify-content-center align-items-center "}>
              <Button variant="primary" className="w-100" onClick={() => openModal()}>
                Create
              </Button>
            </div>
          </ListGroup>
        </div>
      </div>
    </>
  );
};

export default EmailTagSelector;
