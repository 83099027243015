import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";

class CustomStatsToolPanel extends Component {
  componentDidMount = () => {
    this.onGridReady();
  };

  render() {
    return (
      <div>
        <ListGroup style={{ fontSize: "14px", fontFamily: "Acre" }}>
          <ListGroup.Item action onClick={this.onClearFilter.bind(this)}>
            Clear all filters
          </ListGroup.Item>
          <ListGroup.Item action onClick={this.onSelectedExport.bind(this)}>
            Export selected data as csv
          </ListGroup.Item>
          <ListGroup.Item action onClick={this.onAllExport.bind(this)}>
            Export all data as csv
          </ListGroup.Item>
          <ListGroup.Item action onClick={this.onBtExport.bind(this)}>
            Export visible data as csv{" "}
          </ListGroup.Item>
          <ListGroup.Item action onClick={this.onExcelExport.bind(this)}>
            Export to Excel
          </ListGroup.Item>
        </ListGroup>
      </div>
    );
  }

  onGridReady = (params) => {
    this.gridApi = this.props.api;
    this.gridColumnApi = this.props.columnApi;
    this.gridApi.sizeColumnsToFit();
    // this.gridApi.sizeColumnsToFit();
    // this.gridColumnApi.sizeColumnsToFit();
  };

  onBtExport = () => {
    let firstRow = this.gridApi.getFirstDisplayedRow();
    let lastRow = this.gridApi.getLastDisplayedRow();
    let shouldRowBeSkipped = (params) => {
      // return true if index is less than first row or more than last row
      return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
    };
    let params = {
      skipHeader: false,
      columnGroups: true,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: true,
      skipPinnedBottom: true,
      allColumns: false,
      onlySelected: false,
      suppressQuotes: false,
      shouldRowBeSkipped: shouldRowBeSkipped,

      // fileName: document.querySelector("#fileName").value,
      fileName: this.getDefaultFileName(),
      // columnSeparator: document.querySelector("#columnSeparator").value
    };
    console.log(params);
    console.log(this.gridApi);
    this.gridApi.exportDataAsCsv(params);
  };

  onAllExport = () => {
    var params = {
      skipHeader: false,
      columnGroups: true,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: true,
      skipPinnedBottom: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: false,
      // fileName: document.querySelector("#fileName").value,
      fileName: this.getDefaultFileName(),
      // columnSeparator: document.querySelector("#columnSeparator").value
    };
    console.log(params);

    this.gridApi.exportDataAsCsv(params);
  };

  onExcelExport = () => {
    var params = {
      skipHeader: false,
      columnGroups: true,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: true,
      skipPinnedBottom: true,
      allColumns: false,
      onlySelected: false,
      suppressQuotes: false,
      // fileName: document.querySelector("#fileName").value,
      fileName: this.getDefaultFileName(),
      // columnSeparator: document.querySelector("#columnSeparator").value
      exportMode: "xlsx",
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSelectedExport = () => {
    let selectedRowsArray = this.gridApi.getSelectedRows();
    this.gridApi.exportDataAsCsv({ onlySelected: true });
  };

  onClearFilter = () => {
    this.gridApi.setFilterModel();
  };

  getDefaultFileName = () => {
    let now = new Date();
    return "dataExport-" + now.toLocaleTimeString();
  };

  // updateTotals() {
  //     var numGold = 0, numSilver = 0, numBronze = 0;
  //
  //     this.props.api.forEachNode(function (rowNode) {
  //         let data = rowNode.data;
  //
  //         if (data.gold) numGold += data.gold;
  //         if (data.silver) numSilver += data.silver;
  //         if (data.bronze) numBronze += data.bronze;
  //     });
  //
  //     let numMedals = numGold + numSilver + numBronze;
  //     this.setState({numMedals: numMedals, numGold: numGold, numSilver: numSilver, numBronze: numBronze});
  // }
}
export default CustomStatsToolPanel;
