import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  resetConfirmModal,
  setBody,
  setCallBackData,
  setCancelCallBack,
  setConfirmModal,
  setSaveCallBack,
  setTitle,
  setCancelButtonLabel,
  setConfirmButtonLabel,
} from "../../../reducers/confirmModalReducer";

const useConfirmModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /**
   * Creates a confirm modal with the given properties.
   *
   * @param {Object} params - The parameters for creating the confirm modal.
   * @param { ReactNode } [params.title] - The title of the confirm modal.
   * @param { ReactNode } [params.body] - The body of the confirm modal.
   * @param {Function | null} [params.saveCallBack] - The callback function to be called when the confirm button is clicked.
   * @param {Function | null} [params.cancelCallBack] - The callback function to be called when the cancel button is clicked.
   * @param {any} [params.callBackData] - The data to be passed to the callback functions.
   * @param {string} [params.cancelButtonLabel] - The label of the cancel button.
   * @param {string} [params.confirmButtonLabel] - The label of the confirm button.
   */

  const createConfirmModal = ({
    title,
    body,
    saveCallBack,
    cancelCallBack,
    callBackData,
    cancelButtonLabel = t("cancel"),
    confirmButtonLabel = t("ok"),
  }) => {
    dispatch(setConfirmModal(true));
    dispatch(setSaveCallBack(saveCallBack));
    dispatch(setCancelCallBack(cancelCallBack));
    dispatch(setCallBackData(callBackData));
    dispatch(setTitle(title));
    dispatch(setBody(body));
    dispatch(setCancelButtonLabel(cancelButtonLabel));
    dispatch(setConfirmButtonLabel(confirmButtonLabel));
  };

  // mustCall on close or save call back
  const removeConfirmModal = () => {
    dispatch(resetConfirmModal());
  };

  return { createConfirmModal, removeConfirmModal };
};

export default useConfirmModal;
