import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import { WrappedFieldProps } from "redux-form";
import useIsMounted from "components/common/hooks/useIsMounted";

interface WareHousePickerPropsType extends WrappedFieldProps {
  label?: ReactNode;
  input: any;
  required?: boolean;
  tooltip?: string;
  disabled?: boolean;
  floating?: boolean;
  instanceId?: string;
}

type WareHousesObjType = {
  id?: number | string;
  location_id?: number;
  name?: string;
  status?: string;
  label?: string;
  external_id?: string;
  created_at?: string;
  updated_at?: string;
};

const WareHousePicker = ({
  label,
  input,
  required,
  meta: { touched, error, warning },
  tooltip,
  disabled,
  floating = false,
  instanceId,
}: WareHousePickerPropsType) => {
  const [wareHouses, setWareHouses] = useState<WareHousesObjType[]>([]);
  const isMounted = useIsMounted();
  const [selected, setSelected] = useState<WareHousesObjType>();

  const parseForSelect = (options: WareHousesObjType[]) => {
    return options.map((option: WareHousesObjType) => {
      return {
        value: option.id,
        label: option.name,
        status: option.status,
      };
    });
  };

  const getWarehouses = useCallback(async () => {
    const result: AxiosResponse = await restApiService.get(`warehouses`, { status: "ACTIVE" }, null, true, null, true);
    if (result?.data) {
      let list = parseForSelect(result?.data);
      if (isMounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Warehouse --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setWareHouses(list);
      }
      return list;
    }
  }, []);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        const result = await restApiService.get("warehouses/" + input.value);
        const inactiveGrant = {
          value: result.data.id,
          label: (
            <>
              {result.data.name} - <small style={{ fontSize: "10px" }}>({result.data.status})</small>
            </>
          ),
        };
        selectedValues = inactiveGrant;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    getWarehouses().then((res) => {
      findSelected(input, res).then((response: WareHousesObjType) => {
        setSelected(response);
      });
    });
  }, []);

  const changeWarehouse = (input: any, selected: any) => {
    onChangeSingle(input, selected);
    setSelected(selected);
  };

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={selected}
          onChange={(selected) => changeWarehouse(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={wareHouses}
          disable={disabled}
          isClearable={!required}
          getOptionLabel={(option: WareHousesObjType) => {
            return (
              <>
                <span className="formField">{option.label}</span>
                {option.status === "INACTIVE" && <small style={{ fontSize: "10px" }}> -({option.status})</small>}
              </>
            );
          }}
          filterOption={(option: any, searchText) =>
            option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
          }
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
            }),
            ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
          }}
          {...(floating ? { menuPortalTarget: document.body } : {})}
          instanceId={instanceId || "warehouse-picker"}
        />
        {error && touched && <div className="pickerError">{error}</div>}
      </Form.Group>
    </>
  );
};

export default WareHousePicker;
