import React, { memo, useCallback, useMemo } from "react";
import { useTypedSelector } from "reducers";
import AbstractListPicker from "../abstractListPicker";

type TCustomDataListPickerProps = {
  id?: string;
  name: string;
  objectName: string;
  model: string;
  required?: boolean;
  multiple?: boolean;
  activeOnly?: boolean;
  showInactiveStatus?: boolean;
  label?: string;
};

const CustomDataListPicker = ({
  id,
  name,
  objectName,
  model,
  label,
  required = false,
  multiple = false,
  showInactiveStatus = false,
  activeOnly = true,
}: TCustomDataListPickerProps) => {
  const currentUser = useTypedSelector((state) => state.user);
  const companyId = currentUser?.company?.id;
  const params = { active: activeOnly, model: model };

  const fetchUrl = useMemo(() => {
    return `companies/${companyId}/custom_data_list`;
  }, [companyId]);

  const getFilterByExternalId = useCallback((options) => {
    return options.filter((option: any) => option?.external_id);
  }, []);

  const getResponseData = useCallback((response) => {
    return response?.data?.properties ? response?.data?.properties.data_list : [];
  }, []);

  return (
    <AbstractListPicker<any>
      name={name}
      objectName={objectName}
      label={label}
      valuePropertyName="external_id"
      required={required}
      params={params}
      multiple={multiple}
      fetchUrl={fetchUrl}
      filterRecords={getFilterByExternalId}
      getResponseData={getResponseData}
      showInactiveStatus={showInactiveStatus}
    />
  );
};

export default memo(CustomDataListPicker);
