import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import RestApi from "../../../../providers/restApi";
import { useDispatch } from "react-redux";
import CompanyDefaultForm from "./form";
import { setCompanyDefault } from "../../../../reducers/admin/companyDefaultReducer";
import SettingsTabs from "../settingsTabs";
import settingsStyles from "./../settings.module.css";
import { Link } from "react-router-dom";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";

const CompanyDefault = () => {
  const dispatch = useDispatch();
  const restApiService = new RestApi();

  const getInitialCompanyDefault = async () => {
    try {
      const companyDefaultPromise = restApiService.get(`company/default`);
      const companyPromise = restApiService.get(`company`);
      Promise.all([companyDefaultPromise, companyPromise]).then((values) => {
        const companyDefaultFormData = {
          company_default: values[0].data,
          company: values[1].data,
        };
        dispatch(setCompanyDefault(companyDefaultFormData));
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getInitialCompanyDefault();
  }, []);

  return (
    <Container fluid className={`fontSizeNormal`}>
      <Row className={settingsStyles.navBarMargin}>
        <SettingsTabs
          breadcrumb={
            <span className={settingsStyles.landingHeader}>
              <Link to={"/ap/settings"}> Settings</Link>{" "}
            </span>
          }
        />
      </Row>
      <ErrorBoundary>
        <CompanyDefaultForm />
      </ErrorBoundary>
    </Container>
  );
};
export default CompanyDefault;
