import React, { useState } from "react";
import { CardsDisputesFormState } from "../../../../../services/admin/cards/disputes/cardDisputes.types";
import { Col, Row, Button, Container } from "react-bootstrap";
import { DisputeSingleItemTable } from "./disputeSingleItemTable";
import { Field, getFormValues, touch, isValid } from "redux-form";
import { renderField } from "../../../../forms/fields";
import { required } from "../../../../../services/validations/reduxFormValidation";
import { shallowEqual, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../../reducers";
import { Utility, DISPUTE_STATE } from "services/admin/cards/cardDisputesSvc";
import CountryPicker from "components/admin/pickers/reduxFormPickers/countryPicker";
import Style from "./dispute.module.css";

type DisputeSelectionPropsType = {
  goToNextStep: (nextStep: string) => void;
  handleSubmit: any;
};

export const DisputeContactAdd = ({ goToNextStep, handleSubmit }: DisputeSelectionPropsType) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const dispatch = useDispatch();
  const state = useTypedSelector((state) => state);

  const currentState = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue;
  });

  const purchaseDetail = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue.purchase || ({} as { [key: string]: any });
  }, shallowEqual);

  // As the fields are pre-populated, special validation logic needs to execute in the
  // case where a user clears any required text field and then attempts to continue on.
  const validationButtonClick = () => {
    const fieldNamesToValidate: string[] = [
      "form.contact_name",
      "form.company_name",
      "form.email_address",
      "form.phone_number",
      "form.address",
      "form.city",
      "form.state",
      "form.zip_code",
    ];

    dispatch(touch("CardDisputes", ...fieldNamesToValidate));

    const isFormValid = isValid("CardDisputes")(state);

    if (isFormValid) {
      setFormIsValid(true);
      goToNextStep(DISPUTE_STATE.ENTER_VALUES);
    } else {
      setFormIsValid(false);
    }
  };

  const { t } = useTranslation();

  const t2 = (key: string) => {
    return t(`components.admin.disputes.contact_page.${key}`);
  };

  const returnToAngular = () => {
    Utility.returnToAngular(currentState);
  };

  return (
    <Container>
      <Row className={`py-4 mt-4 mx-1 bg-white panel-section ${Style.panel_padded}`}>
        <Col>
          <Row>
            <i
              className={`icon icon-arrow-left ${Style.back_icon_repositioning}`}
              onClick={() => goToNextStep(DISPUTE_STATE.SELECT_DISPUTE)}
            ></i>
            <h4 style={{ paddingLeft: 15 }}>{t("components.admin.disputes.header")}</h4>
          </Row>
          <hr className={`${Style.bold_hr}`}></hr>
          <Row style={{ marginLeft: "8px" }}>
            <span className={`${Style.secondary_header}`}>
              {t2("account_code")} {purchaseDetail.number}
            </span>
          </Row>
          <Row
            className={`py-4 mt-4 mx-1 bg-white panel-section ${Style.panel_padded} ${Style.panel_padded_smaller_bottom}`}
          >
            <Col>
              <Row className={`${Style.title}`}>{t2("transaction_details")}</Row>
              <br></br>
              <Row>
                <Col style={{ paddingLeft: "0" }} sm="3">
                  {t2("card_number")}
                </Col>
                <Col style={{ paddingLeft: "0" }} sm="3">
                  {t2("customer_id")}
                </Col>
              </Row>
              <Row>
                <Col sm="3" style={{ fontSize: "18px", paddingLeft: "0" }}>
                  {purchaseDetail.card_number_masked || ""}
                </Col>
                <Col sm="3" style={{ fontSize: "18px", paddingLeft: "0" }}>
                  {purchaseDetail.contact_id}
                </Col>
              </Row>
              <br></br>
              <Row>
                <DisputeSingleItemTable></DisputeSingleItemTable>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="6">
              <Field
                name="form.contact_name"
                label={t("components.admin.disputes.fullName")}
                component={renderField}
                isRequired
                validate={[required]}
              ></Field>
            </Col>
            <Col sm="6">
              <Field
                name="form.company_name"
                label={t("address.companyName")} // Not currently defined in
                component={renderField}
                isRequired
                validate={[required]}
              ></Field>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="6">
              <Field
                name="form.email_address"
                label={t("address.email")}
                component={renderField}
                isRequired
                validate={[required]}
              ></Field>
            </Col>
            <Col sm="6">
              <Field
                name="form.phone_number"
                label={t("address.phone")}
                component={renderField}
                isRequired
                validate={[required]}
              ></Field>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="12">
              <Field
                name="form.address"
                label={t("address.address")}
                component={renderField}
                isRequired
                validate={[required]}
              ></Field>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="3">
              <Field
                name="form.city"
                label={t("address.city")}
                component={renderField}
                isRequired
                validate={[required]}
              ></Field>
            </Col>
            <Col sm="3">
              <Field
                name="form.state"
                label={t("components.admin.disputes.state")}
                component={renderField}
                isRequired
                validate={[required]}
              ></Field>
            </Col>
            <Col sm="3">
              <Field
                name="form.zip_code"
                label={t("components.admin.disputes.zip_code")}
                component={renderField}
                isRequired
                validate={[required]}
              ></Field>
            </Col>
            <Col sm="3">
              <Field
                name="form.country"
                label={t("address.country")}
                isRequired
                validate={[required]}
                component={CountryPicker}
              ></Field>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="d-flex justify-content-end">
              <Button className="btn" style={{ marginRight: "10px" }} type="button" onClick={returnToAngular}>
                {t(`components.admin.disputes.cancel`)}
              </Button>
              <Button onClick={() => validationButtonClick()}>Next</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
