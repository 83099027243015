import React, { Fragment, Component } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RestApi from "../../providers/restApi";
import { CreateNotification, NotificationType } from "../../services/general/notifications";

/**
 * Note that this file has duplicate code that the customer picker, pick payment method modal, and payment account picker have.
 * Ive put it into its own file because none of those other files do exactly what we need
 */
class PickCustomersForPaymentMethodModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPurchasers: [],
      submitDisabled: false,
    };
    this.restApiService = new RestApi();
    this.successCount = 0;
  }

  componentDidMount() {
    this.getPurchasers();
  }

  getPurchasers() {
    this.restApiService
      .get("purchasers")
      .then((result) => {
        if (result.data) {
          this.parsePurchasers(result.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  parsePurchasers(result) {
    //accepted_payment_methods
    let parsedPurchasers = [];
    let intialSelectedPurchasers = [];
    result.forEach((purchaser) => {
      if (this.props.paymentMethod.payment_type === "ach" && !purchaser.accepted_payment_methods.ach) {
        return;
      } else if (
        this.props.paymentMethod.payment_type === "international" &&
        !purchaser.accepted_payment_methods.transferwise
      ) {
        return;
      } else if (
        this.props.paymentMethod.payment_type === "credit_card" &&
        !purchaser.accepted_payment_methods.credit_card
      ) {
        return;
      } else if (this.props.paymentMethod.payment_type === "payoneer" && !purchaser.accepted_payment_methods.payoneer) {
        return;
      } else if (this.props.paymentMethod.payment_type === "wire" && !purchaser.accepted_payment_methods.wire) {
        return;
      }

      let purchaserObject = {
        value: purchaser.id,
        label: purchaser.name,
        raw: purchaser,
      };
      //auto select all purchasers for generic payment method types
      if (this.props.paymentMethod.payment_type === "ach" || this.props.paymentMethod.payment_type === "credit_card") {
        intialSelectedPurchasers.push(purchaserObject);
      }

      parsedPurchasers.push(purchaserObject);
    });
    //parsedPurchasers.unshift({ value: null, label: "Select one or more..." })
    this.setState({
      selectOptions: parsedPurchasers,
      selectedPurchasers: intialSelectedPurchasers,
    });
  }

  linkPaymentMethod = (paymentMethodId, vendorId) => {
    this.restApiService
      .post("vendors/" + vendorId + "/link_payment_method/" + paymentMethodId)
      .then((result) => {
        this.successCount++;
        if (this.successCount >= this.state.selectedPurchasers.length) {
          //finished
          CreateNotification("Success", "Payment Account Linked Succesfully", NotificationType.success);
          this.props.closeCallback();
        }
      })
      .catch((err) => {
        console.log(err);
        this.successCount++;
        CreateNotification("Error", "Please reach out to support", NotificationType.warning);
      });
  };

  handleChange = (selectedOptions) => {
    this.setState({ selectedPurchasers: selectedOptions });
  };

  submitAll = () => {
    this.setState({ submitDisabled: true });
    this.successCount = 0;
    this.state.selectedPurchasers.forEach((purchaserOption) => {
      if (purchaserOption.value) {
        this.linkPaymentMethod(this.props.paymentMethod.id, purchaserOption.raw.vendor_detail.id);
      }
    });
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.closeCallback}>
        <Modal.Header closeButton>
          <Modal.Title>Link Payment Methods</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="mb-3">
            Please select the customers you wish this payment method to be linked to. A payment method must be linked to
            a customer before any payments can be made.
          </p>
          <Select
            required={true}
            value={this.state.selectedPurchasers}
            placeholder={this.props.t("forms.selectPaymentAccount")}
            options={this.state.selectOptions}
            isMulti={true}
            onChange={this.handleChange}
          />
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={this.submitAll}
            disabled={
              !this.state.selectedPurchasers || this.state.selectedPurchasers.length < 1 || this.state.submitDisabled
            }
          >
            Link
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(PickCustomersForPaymentMethodModal);
