import React from "react";
import styles from "./status.module.css";

const show_status_class = (props) => {
  let statusClass;
  switch (props.status) {
    case "DRAFT":
      statusClass = styles.status_draft;
      break;
    case "PENDING":
      statusClass = styles.status_pending;
      break;
    case "REJECTED":
      statusClass = styles.status_rejected;
      break;
    case "INACTIVE":
      statusClass = styles.status_rejected;
      break;
    default:
      statusClass = styles.status_approved;
  }
  return "mx-3 " + statusClass;
};

const ShowStatus = (props) => {
  return (
    <span className={show_status_class(props)}>
      <i className={`${styles.icon}`}></i>
      {props.status}
    </span>
  );
};

export default ShowStatus;
