import { Mandatory } from "components/forms/bootstrapFields";
import _ from "lodash";
import React, { ReactNode, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { WrappedFieldProps, change, getFormValues } from "redux-form";

interface OtherInputFieldProps extends WrappedFieldProps {
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  formName: string;
  formFieldName: string;
  item: string;
  className: string;
  labelClassName: string;
  fieldClassName: string;
  id?: string;
}

const OtherInputField = ({
  input,
  label,
  required,
  disabled,
  item,
  formName,
  formFieldName,
  className,
  fieldClassName,
  labelClassName,
  meta,
  id,
}: OtherInputFieldProps) => {
  const formData: any | null = useTypedSelector((state) => getFormValues(formName)(state));
  const checkBoxesValue: { value: any[] } | null = _.get(formData, formFieldName);
  const dispatch = useDispatch();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (checkBoxesValue) {
      const valueObject = checkBoxesValue.value.find((v: any) => typeof v === "object");
      if (!valueObject) {
        dispatch(
          change(formName, formFieldName, {
            ...checkBoxesValue,
            value: [...checkBoxesValue.value, { Other: event.target.value }],
          }),
        );
      } else {
        const stringValues = checkBoxesValue.value.filter((v: any) => typeof v !== "object");
        dispatch(
          change(formName, formFieldName, {
            ...checkBoxesValue,
            value: [...stringValues, { Other: event.target.value }],
          }),
        );
      }

      if (!event.target.value) {
        const stringValues = checkBoxesValue.value.filter((v: any) => typeof v !== "object");
        dispatch(
          change(formName, formFieldName, {
            ...checkBoxesValue,
            value: [...stringValues],
          }),
        );
      }
    }
    input.onChange(event.target.value);
  };

  const findValue = () => {
    if (checkBoxesValue && _.isArray(checkBoxesValue.value)) {
      const valueObject = checkBoxesValue.value.find((v) => typeof v === "object");
      return valueObject ? valueObject.Other : "";
    }
    return "";
  };

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label className={labelClassName}>
          {label}
          <Mandatory required={required} />
        </Form.Label>
      )}

      <Form.Control
        id={id}
        {...input}
        as="input"
        type={"text"}
        className={fieldClassName}
        value={findValue()}
        disabled={disabled}
        onChange={handleInputChange}
      />
    </Form.Group>
  );
};

export default OtherInputField;
