import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import {
  AccountHolder,
  AccountNumber,
  BankCode,
  BankName,
  BranchCode,
  Suffix,
} from "../fieldComponents/fieldsComponent";
import { EftNewZealandPaymentValidation } from "../validations/eftNewZealandPaymentValidation";
import { withTranslation } from "react-i18next";
import CurrencyPicker from "../../admin/pickers/reduxPicker/currencyPicker/currencyPicker";

const selectField = (fillteredPayment, fieldName) => {
  return fillteredPayment[0].validations.find((item) => item.field === fieldName);
};

const EftNewZeaLandFormFields = (props) => {
  const setNzEftInitialState = () => {
    if (!props.paymentMethodRed.id) {
      props.countryPickerCallback({ value: "NZL" });
      props.currencyPickerCallback({ value: "NZD" });
    }
  };

  useEffect(() => {
    setNzEftInitialState();
  }, []);
  return (
    <span>
      <Form.Row>
        <AccountHolder {...props} field_valid={selectField(EftNewZealandPaymentValidation, "account_name")} />
        <AccountNumber {...props} field_valid={selectField(EftNewZealandPaymentValidation, "account_number")} />
        <BankName {...props} field_valid={selectField(EftNewZealandPaymentValidation, "bank_name")} />
      </Form.Row>
      <Form.Row>
        <BankCode {...props} field_valid={selectField(EftNewZealandPaymentValidation, "bankCode")} />
        <BranchCode {...props} field_valid={selectField(EftNewZealandPaymentValidation, "branchCode")} />
        <Suffix {...props} field_valid={selectField(EftNewZealandPaymentValidation, "suffix")} />
        <CurrencyPicker {...props} selected={{ value: "NZD" }} showDropdownOptions={false} />
      </Form.Row>
    </span>
  );
};
export default withTranslation()(EftNewZeaLandFormFields);
