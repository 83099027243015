import { AxiosResponse } from "axios";
import CustomFields from "components/admin/customFields/customFields";
import Panel from "components/common/panel/panel";
import MetaDataShow from "components/metadata/metadataShow/metadataShow";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import { formattedAmount } from "services/general/helpers";
import styles from "./expenseSplitTable.module.css";

/*
  Notes on this component: The table should only show columns that the user is privy to view.
  1. Permissions are checked to see if the user can view the department, location, business unit, and project categories.
  2. The total amount and individual percentages are calculated.
  3. The table is generated but only with the columns that the user is allowed to view.
  4. The "Category" and "Amount" columns are always displayed.

  The table may be rendered inside a collapsible accordion panel by means of an optional showInAccordion prop.
*/

type ExpenseSplitTablePropType = {
  expenseItem: ExpensesTypes.Details | ExpensesTypes.ExpenseItemFormDataType | undefined;
  showInAccordion?: boolean;
  accordionStartsOpen?: boolean;
  isForm?: boolean;
};

const ExpenseSplitTable = ({
  expenseItem,
  showInAccordion = false,
  accordionStartsOpen = false,
  isForm = false,
}: ExpenseSplitTablePropType) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [hasCustomFields, setHasCustomFields] = useState<boolean>(false);

  const { t } = useTranslation();
  const t2 = (key: string) => t("admin.pages.expenseItem.labels." + key);
  const currentUser = useTypedSelector<IUser>(selectCurrentUser);
  const currentUserExpenseItem = currentUser.company.expense_item;
  const currentUserCompany = currentUser.company;

  const isConversionActive = expenseItem && expenseItemCommonSvc.isConversionActive(expenseItem);
  const showBaseValues = isConversionActive && isForm;
  const applicableCurrencyCode = showBaseValues ? expenseItem.base_currency_code : expenseItem?.currency_code;
  const applicableTotal = showBaseValues ? expenseItem.base_currency_total : expenseItem?.total;
  const sumOfAmount =
    expenseItem?.expense_report_debit_accounts &&
    expenseItemCommonSvc.getSumOfAmount(expenseItem.expense_report_debit_accounts);
  const sumOfBaseCurrencyAmount =
    expenseItem?.expense_report_debit_accounts &&
    expenseItemCommonSvc.getSumOfBaseCurrencyAmount(expenseItem.expense_report_debit_accounts);
  const applicableAmount = showBaseValues ? sumOfBaseCurrencyAmount : sumOfAmount;
  const outOfPercent =
    applicableAmount &&
    applicableTotal &&
    commonService.calculatePercentageFromAmount({ amount: applicableAmount, total: applicableTotal });

  const checkHasCustomFields = async () => {
    try {
      const response: AxiosResponse = await restApiService.get(
        "custom_field_metadata",
        { model: "ExpenseItem" },
        null,
        true,
        null,
        true,
      );
      if (response.data) {
        const hasCustomFields = Array.isArray(response.data) && response.data.length > 0;
        setHasCustomFields(hasCustomFields);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayable_split_categories: ExpensesTypes.SplitExpenseCategoryType = {
    category_name: { displayable: true, header_label: t2("category"), values: [] },
    department_name: {
      displayable: !!(currentUserExpenseItem?.show_department && currentUserCompany.has_departments),
      header_label: t2("department"),
      values: [],
    },
    location_name: {
      displayable: !!(currentUserExpenseItem?.show_location && currentUserCompany.has_locations),
      header_label: t2("location"),
      values: [],
    },
    business_unit_name: {
      displayable: !!(currentUserExpenseItem?.show_business_unit && currentUserCompany.has_business_units),
      header_label: t2("businessUnit"),
      values: [],
    },
    project_name: {
      displayable: !!(currentUserExpenseItem?.show_project && currentUserCompany.has_projects),
      header_label: t2("project"),
      values: [],
    },
    metadata: {
      displayable: hasCustomFields || currentUserCompany.has_expense_item_metadata,
      header_label: t2("metadata"),
      values: [],
    },
    amount: { displayable: true, header_label: t2("amount"), values: [] },
    percentage: { displayable: true, header_label: t2("percentage"), values: [] },
  };

  const rows =
    (Array.isArray(expenseItem?.expense_report_debit_accounts) && expenseItem?.expense_report_debit_accounts) || [];

  const displayableColumnCount = Object.values(displayable_split_categories).filter(
    (category) => category.displayable,
  ).length;

  Array.isArray(rows) &&
    rows?.forEach((row) => {
      (
        Object.entries(displayable_split_categories) as [
          keyof ExpensesTypes.SplitExpenseCategoryType,
          ExpensesTypes.ExpenseSplitMetaType,
        ][]
      ).forEach(([key, property]) => {
        if (property.displayable) {
          let value = row[key as keyof ExpensesTypes.ExpenseReportDebitAccountType] || "";
          value = value.toString() || "";
          if (value !== undefined) {
            property.values.push(value);
          }
        }
      });
    });

  if (
    Array.isArray(expenseItem?.expense_report_debit_accounts) &&
    expenseItem?.expense_report_debit_accounts.length &&
    expenseItem?.expense_report_debit_accounts.length > 0 &&
    expenseItem?.expense_report_debit_accounts[0]?.id
  ) {
    // sort in ascending order
    expenseItem.expense_report_debit_accounts = _.sortBy(expenseItem?.expense_report_debit_accounts, ["id"], ["asc"]);
  }

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (currentUserCompany.has_custom_field_metadata) {
      checkHasCustomFields();
    }
    setIsCollapsed(!accordionStartsOpen);
  }, [accordionStartsOpen]);

  const splitItemTable = () => {
    return (
      <>
        {rows.length === 0 && <div className={styles.noSplitItemsLabel}>{t2("noSplitItems")}</div>}
        {expenseItem && rows.length > 0 && (
          <Card style={{ width: "100%" }}>
            <Card.Body className={styles.splitCardBodyTable}>
              <table className={`table ${styles.table}`}>
                <thead>
                  <tr>
                    <th>{displayable_split_categories.category_name.header_label}</th>
                    {displayable_split_categories.department_name.displayable && (
                      <th>{displayable_split_categories.department_name.header_label}</th>
                    )}
                    {displayable_split_categories.location_name.displayable && (
                      <th>{displayable_split_categories.location_name.header_label}</th>
                    )}
                    {displayable_split_categories.business_unit_name.displayable && (
                      <th>{displayable_split_categories.business_unit_name.header_label}</th>
                    )}
                    {displayable_split_categories.project_name.displayable && (
                      <th>{displayable_split_categories.project_name.header_label}</th>
                    )}
                    {displayable_split_categories.metadata.displayable && (
                      <th>{displayable_split_categories.metadata.header_label}</th>
                    )}
                    <th>{t2("allocation")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(expenseItem?.expense_report_debit_accounts) &&
                    expenseItem?.expense_report_debit_accounts?.map(
                      (splitItem: ExpensesTypes.ExpenseDebitAccount, index) => (
                        <tr key={index}>
                          <td>{splitItem.category_name ?? splitItem.category?.name}</td>
                          {displayable_split_categories.department_name.displayable && (
                            <td>{splitItem.department_name ?? splitItem.department?.name}</td>
                          )}
                          {displayable_split_categories.location_name.displayable && (
                            <td>{splitItem.location_name ?? splitItem.location?.name}</td>
                          )}
                          {displayable_split_categories.business_unit_name.displayable && (
                            <td>{splitItem.business_unit_name ?? splitItem.business_unit?.name}</td>
                          )}
                          {displayable_split_categories.project_name.displayable && (
                            <td>{splitItem.project_name ?? splitItem.project?.name}</td>
                          )}
                          {displayable_split_categories.metadata.displayable && (
                            <td style={{ paddingLeft: "0" }}>
                              <CustomFields model={splitItem} noPanel />
                              <MetaDataShow model={splitItem} noPanel />
                            </td>
                          )}
                          <td>
                            {formattedAmount(
                              `${showBaseValues ? splitItem.base_currency_amount : splitItem.amount}`,
                              applicableCurrencyCode ?? "",
                              2,
                              true,
                            )}{" "}
                            / {Number(isForm ? splitItem.percentage : splitItem.percent).toFixed(2)}%
                          </td>
                        </tr>
                      ),
                    )}
                  <tr>
                    <td colSpan={displayableColumnCount - 2} className={styles.allocationTotal + " " + styles.textEnd}>
                      {t2("total")}
                    </td>
                    <td className={styles.allocationTotal}>
                      {formattedAmount(`${applicableTotal}`, applicableCurrencyCode ?? "", 2, true)} /{" "}
                      {outOfPercent?.toFixed(2)}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card.Body>
          </Card>
        )}
      </>
    );
  };

  return (
    <>
      {!showInAccordion && (
        <>
          <div className={styles.expenseAllocationHeader}>{t2("expenseAllocation")}</div>
          {splitItemTable()}
        </>
      )}
      {showInAccordion && (
        <Panel
          header={
            <div className={styles.panelTitle}>
              <i className={`icon icon-split ${styles.splitIcon}`}></i>
              {t2("expenseAllocation").toLocaleUpperCase()}
              <div style={{ float: "right" }}>
                <button
                  className={`icon ${isCollapsed ? "icon-arrow-up" : "icon-arrow-down"}`}
                  onClick={handleCollapse}
                  aria-expanded={!isCollapsed}
                  style={{ background: "none", border: "none", padding: 0, cursor: "pointer" }}
                >
                  <i className={`icon ${isCollapsed ? "icon-arrow-down" : "icon-arrow-up"}`} />
                </button>
              </div>
            </div>
          }
        >
          {!isCollapsed && splitItemTable()}
        </Panel>
      )}
    </>
  );
};

export default ExpenseSplitTable;
