import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderCheckBox, RenderField } from "../../../../../../../components/forms/bootstrapFields";
import TooltipRender from "../../../../../../../components/toolTip/tooltipRender";

const SVBMasterCardCredential = () => {
  return (
    <>
      <Col lg="12" className="p-0 mt-3">
        <Row className="m-0">
          <hr />
          <Col lg="12">
            <label>
              Master Card Meta Data{" "}
              <TooltipRender
                className={"icon-mask"}
                title="Select following options to send as a Mastercard Data to SVB while creating Virtual Card."
                placement="top"
              />
            </label>
          </Col>

          <Col lg="12" className="p-0">
            <Row className="m-0">
              <Col md="2">
                <Field
                  component={RenderCheckBox}
                  name="form.mastercard_data_options.po_number"
                  label="PO Number"
                  id="po_number"
                  type="checkbox"
                />
              </Col>
              <Col md="2">
                <Field
                  component={RenderCheckBox}
                  name="form.mastercard_data_options.amount"
                  label="Amount"
                  id="amount"
                  type="checkbox"
                />
              </Col>
              <Col md="2">
                <Field
                  component={RenderCheckBox}
                  name="form.mastercard_data_options.description"
                  label="Description"
                  id="description"
                  type="checkbox"
                />
              </Col>
              <Col md="2">
                <Field
                  name="form.mastercard_data_options.vendor"
                  component={RenderCheckBox}
                  label="Vendor"
                  id="vendor"
                  type="checkbox"
                />
              </Col>
              <Col md="2">
                <Field
                  name="form.mastercard_data_options.department"
                  component={RenderCheckBox}
                  label="Department"
                  id="department"
                  type="checkbox"
                />{" "}
              </Col>
              <Col md="2">
                <Field
                  component={RenderCheckBox}
                  name="form.mastercard_data_options.requestor"
                  label="Requestor"
                  id="requestor"
                  type="checkbox"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col lg="12" className="mb-2">
        <Field
          id="master_card_number"
          type="text"
          label="Master Card Account Number"
          name="form.master_card_number"
          placeholder="Master Card Account Number"
          component={RenderField}
        />
      </Col>
    </>
  );
};

export default SVBMasterCardCredential;
