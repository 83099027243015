import React, { useEffect, useState } from "react";
import FormSubsidiary from "./form";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import SubsidiaryApis from "../../../services/admin/subsidiaries/subsidiaryApis";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { SubsidiaryPayloadType, SubsidiaryDetailType } from "../../../services/admin/subsidiaries/types";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";
import {
  getSubsidiaryLinksAttributes,
  getSubsidiaryManagerLocationLink,
  transformAllSubsidiaryLinksToApi,
} from "../../../services/admin/subsidiaries/subsidiarySvc";
import _ from "lodash";
import { restApiService } from "providers/restApi";

const AddSubsidiary = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [subsidiary, setSubsidiary] = useState<SubsidiaryDetailType>();

  const managePayload = async (subsidiary: SubsidiaryDetailType) => {
    subsidiary.subsidiary_manager_location_links_attributes = await getSubsidiaryManagerLocationLink(subsidiary);
    subsidiary.subsidiary_links_attributes = await getSubsidiaryLinksAttributes(subsidiary);
  };

  const handleSubmit = async (subsidiary: SubsidiaryDetailType) => {
    await managePayload(subsidiary);
    transformAllSubsidiaryLinksToApi(subsidiary);
    const payload: SubsidiaryPayloadType = {
      subsidiary: subsidiary,
    };
    try {
      await SubsidiaryApis.addSubsidiary(payload);
      history.push("/ap/subsidiaries");
      CreateNotification(t("success"), t("subsidiary.saved"), NotificationType.success);
    } catch (error: any) {
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data) && response.data["name"]) {
          CreateNotification(
            "Name Conflict",
            subsidiary.name + " " + response.data["name"][0],
            NotificationType.warning,
          );
        } else {
          CreateNotification("Validation Error", "Please enter valid data", NotificationType.danger);
        }
      }
    }
  };

  useEffect(() => {
    restApiService.get("subsidiaries/new").then((result) => {
      setSubsidiary(result.data);
    });
  }, []);

  return (
    <ErrorBoundary>
      <FormSubsidiary onSubmit={handleSubmit} isAddForm={true} initialValues={subsidiary} />
    </ErrorBoundary>
  );
};

export default AddSubsidiary;
