import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import currency_codes from "lookups/currencyCodeLookup";
import { RootState } from "reducers";
import { CommonTypes } from "services/common/types/commonTypes";

type AppCommonStatesSliceType = {
  appCurrencyCodes: CommonTypes.ListCurrencyCodeItem;
  companyDefault: Record<string, any> | null;
};

const initialState: AppCommonStatesSliceType = {
  appCurrencyCodes: [...currency_codes], // store unique currency only
  companyDefault: null,
};

const appCommonStatesSlice = createSlice({
  name: "appCommonStatesSlice",
  initialState,
  reducers: {
    setAppCurrencyCodes: (state, action: PayloadAction<CommonTypes.ListCurrencyCodeItem>) => {
      state.appCurrencyCodes = action.payload;
    },
    setCompanyDefault: (state, action: PayloadAction<any>) => {
      state.companyDefault = action.payload;
    },
  },
});

// selectors
export const selectAppCurrecyCode = (state: RootState) => state.appCommonStates.appCurrencyCodes;
export const selectCompanyDefault = (state: RootState) => state.appCommonStates.companyDefault;
export const selectAppCurrencyCode = (state: RootState) => state.appCommonStates.appCurrencyCodes;

// actions
export const { setAppCurrencyCodes, setCompanyDefault } = appCommonStatesSlice.actions;

// reducer
export const appCommonStatesReducers = appCommonStatesSlice.reducer;
