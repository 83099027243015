import React, { useEffect } from "react";
import { cloneDeep } from "lodash";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { selectReceiptDetail } from "reducers/admin/receiptsReducer";
import { getPrimaryCurrencyOfUser } from "reducers/userReducers";
import style from "./receipts.module.css";
import moment from "moment";
import "moment-timezone";
import ReceiptsApis from "services/admin/expenses/receipts/receiptsApis";
import { CreateNotification, NotificationType } from "services/general/notifications";
import DatePicker from "react-datepicker";
import { getDateFormat } from "services/general/dateSvc";

type ReceiptDetailForm = {
  merchant: string;
  date: string; // aka transaction_date on form_data object
  sub_total: string;
  tax: string;
  total: string;
  note: string;
};
export const EditReceiptDetail = ({ closeEditAndRefresh }: { closeEditAndRefresh: () => void }) => {
  const receiptDetail = useTypedSelector(selectReceiptDetail);
  const currencyCode = useTypedSelector(getPrimaryCurrencyOfUser);
  const currentUser = useTypedSelector((state) => state.user);
  const { register, handleSubmit, control, watch, setValue } = useForm<ReceiptDetailForm>({
    defaultValues: {
      merchant: receiptDetail?.form_data?.merchant,
      date: moment(receiptDetail?.form_data?.date).format("YYYY-MM-DD"),
      sub_total: `${receiptDetail?.form_data?.sub_total}`,
      tax: `${receiptDetail?.form_data?.tax}`,
      total: `${receiptDetail?.form_data?.total}`,
      note: receiptDetail?.note,
    },
  });

  const onSubmit: SubmitHandler<ReceiptDetailForm> = async (values, e) => {
    e?.preventDefault();
    const cloneValues = cloneDeep(values);
    const { total, sub_total, tax } = cloneValues;
    if (receiptDetail?.id) {
      const formValues = {
        ...cloneValues,
        total: Number(total),
        sub_total: Number(sub_total),
        tax: Number(tax),
      };
      const payload = { ...formValues };
      const response = await ReceiptsApis.editReceipt(receiptDetail?.id, { form_data: payload });
      if (response) {
        closeEditAndRefresh();
        CreateNotification(
          "success",
          "Changes successfully saved. Auto-matching in progress.",
          NotificationType.success,
        );
      } else {
        CreateNotification("Error", "Failed to save changes. Please try again later", NotificationType.danger);
      }
    }
  };

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name === "sub_total" || name === "tax") {
        const subTotal = Number(values.sub_total);
        const tax = Number(values.tax);
        const total = isNaN(subTotal + tax) ? 0.0 : subTotal + tax;
        setValue("total", total.toFixed(2));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Row>
      <Col>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="my-2">
            <Col className={style.detailKey}>Merchant</Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>
              {" "}
              <input className={style.formInputField} {...register("merchant")} />{" "}
            </Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>Transaction Date</Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>
              <Controller
                name="date"
                control={control}
                render={({ field }: any) => {
                  return (
                    <>
                      <DatePicker
                        selected={field.value && field.value !== "Invalid date" ? new Date(field.value) : null}
                        onChange={(date) => date && field.onChange(moment(date).format("YYYY/MM/DD"))}
                        dateFormat={getDateFormat(currentUser)}
                      />
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>Subtotal Amount</Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>
              <div className={style.currencyInputField}>
                <input className={style.currencyCodeField} value={currencyCode} readOnly />
                <input
                  className={`${style.formInputField} ${style.amountField}`}
                  {...register("sub_total")}
                  onBlur={(e) => setValue("sub_total", Number(e.target.value).toFixed(2))}
                />
              </div>
            </Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>Tax Amount</Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>
              <div className={style.currencyInputField}>
                <input className={style.currencyCodeField} value={currencyCode} readOnly />
                <input
                  className={`${style.formInputField} ${style.amountField}`}
                  {...register("tax")}
                  onBlur={(e) => setValue("tax", Number(e.target.value).toFixed(2))}
                />
              </div>
            </Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>Purchase Amount</Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>
              <div className={style.currencyInputField}>
                <input className={style.currencyCodeField} value={currencyCode} readOnly />
                <input className={`${style.formInputField} ${style.amountField}`} {...register("total")} />
              </div>
            </Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>File Name</Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>
              <input className={style.formInputField} value={receiptDetail?.name} disabled />
            </Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>Notes</Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>
              <textarea className={style.formInputField} {...register("note")}>
                {receiptDetail?.note}
              </textarea>
            </Col>
          </Row>
          <Row className="my-2">
            <Col className={style.detailKey}>
              <input className={style.submitBtn} type="Submit" value="Save" />
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};
