import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import {
  change,
  Field,
  FieldArray,
  FieldArrayFieldsProps,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import RestApi from "providers/restApi";

import styles from "./defaultRulesUpsert.module.css";
import GenericSelect from "../pickers/inputs/genericSelect";
import { DefaultCondition, DefaultRule } from "pages/admin/administration/defaultRules/defaultRules";

import MetadataFieldPicker from "../pickers/reduxFormPickers/metadataPicker/metadataPicker";
import { MetadataConfiguration } from "pages/admin/administration/metadata/metadataConfiguration";
import VendorPicker from "components/pickers/reduxFormPicker/vendorPicker";
import { Mandatory } from "components/forms/input/mandatory";
import { HiMiniTrash } from "react-icons/hi2";
import locationPicker from "../pickers/reduxFormPickers/locationPicker";
import ProjectPicker from "../pickers/reduxFormPickers/projectPicker";
import BusinessUnitPicker from "../pickers/reduxFormPickers/businessUnitPicker";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "components/common/modals/confirmation";

const formName = "defaultRulesForm";

const listOfStandardFields = [
  { value: "Amount", label: "Amount" },
  { value: "BusinessUnit", label: "Business Unit" },
  { value: "Location", label: "Location" },
  { value: "Project", label: "Project" },
  { value: "Vendor", label: "Vendor" },
];

interface DefaultRulesUpsertProps {
  deleteCallback?: any;
}

type Props = DefaultRulesUpsertProps & InjectedFormProps<{}, DefaultRulesUpsertProps>;

const DefaultRulesUpsert: React.FC<Props> = ({ handleSubmit, form, deleteCallback }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const [metadataConfigurations, setMetadataConfigurations] = useState<any[]>([]);
  const [fieldChoices, setFieldChoices] = useState<any[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const getMetadataConfigurations = useCallback(async () => {
    setLoading(true);

    const restApi = new RestApi();
    const apiResult = await restApi.get("metadata_configurations");

    setMetadataConfigurations(apiResult.data);

    let fields = listOfStandardFields;

    fields = fields.concat(
      apiResult.data.map((config: MetadataConfiguration) => {
        return {
          value: `MetadataConfiguration_${config.id}`,
          label: config.name,
        };
      }),
    );

    //sort fields by label
    fields.sort((a, b) => a.label.localeCompare(b.label));
    setFieldChoices(fields);

    setLoading(false);
  }, []);

  useEffect(() => {
    getMetadataConfigurations();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex">
        <Form.Group className="ml-3">
          <Form.Label>
            {t("subsidiary.name")}
            <Mandatory required={true} />
          </Form.Label>
          <div>
            <Field className="mr-2" name="name" component="input" type="text" label={"Name"} />
          </div>
        </Form.Group>

        <Field
          name="status"
          options={[
            { value: "ACTIVE", label: "Active" },
            { value: "INACTIVE", label: "Inactive" },
          ]}
          component={GenericSelect}
          isMulti={false}
          placeholder="Status"
          className="mr-2"
          isRequired={true}
          label={"Status"}
        />

        <Field
          name="modules"
          options={[
            { value: "Invoice", label: "Invoice" },
            { value: "PurchaseOrder", label: "Purchase Order" },
          ]}
          component={GenericSelect}
          isMulti={true}
          placeholder="Modules"
          isRequired={true}
          label={"Modules"}
        />
      </div>

      <hr></hr>

      <div className="d-flex flex-row">
        <div className={`text-center mr-2 ${styles.ifThen}`}>
          <h4 className="pt-3">{t("if")}</h4>
        </div>

        <div>
          <p className="blackColor">{t("addCondition")}</p>
          <FieldArray
            rerenderOnEveryChange={true}
            name="default_conditions"
            props={{
              formName: form,
              objectType: "condition",
              metadataConfigurations: metadataConfigurations,
              fieldChoices: fieldChoices,
            }}
            component={RenderDefaultConditionsOrActions}
          />
        </div>
      </div>

      <div className="d-flex flex-row mt-4">
        <div className={`text-center mr-2 ${styles.ifThen}`}>
          <h4 className="pt-3">{t("then")}</h4>
        </div>

        <div>
          <p className="blackColor">{t("addAction")}</p>
          <FieldArray
            rerenderOnEveryChange={true}
            name="default_actions"
            props={{
              formName: form,
              objectType: "action",
              metadataConfigurations: metadataConfigurations,
              fieldChoices: fieldChoices,
            }}
            component={RenderDefaultConditionsOrActions}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-secondary mr-2"
          onClick={() => {
            setShowDeleteModal(true);
          }}
        >
          Delete
        </button>
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </div>

      <ConfirmationModal
        title={t("admin.pages.defaultRules.delete")}
        show={showDeleteModal}
        saveCallback={() => {
          setShowDeleteModal(false);
          deleteCallback();
        }}
        closeCallback={() => {
          setShowDeleteModal(false);
        }}
        confirmText={t("yes")}
        cancelText={t("no")}
        body={t("admin.pages.defaultRules.deleteConfirm")}
      />
    </form>
  );
};

interface RenderDefaultConditionsProps {
  fields: FieldArrayFieldsProps<DefaultCondition>;
  formName: string;
  objectType: string;
  metadataConfigurations: MetadataConfiguration[];
  fieldChoices: any[];
}
const RenderDefaultConditionsOrActions: React.FC<RenderDefaultConditionsProps> = ({
  fields,
  formName,
  objectType,
  metadataConfigurations,
  fieldChoices,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const changeFieldId = (value: any, index: number) => {
    if (value.includes("MetadataConfiguration")) {
      //change the field_id to the given value
      dispatch(
        change(
          formName,
          `default_${objectType}s[${index}].field_id`,
          parseInt(value.split("MetadataConfiguration_")[1]),
        ),
      );
    }
    dispatch(change(formName, `default_${objectType}s[${index}].metadata_field_id`, null));
  };

  return (
    <div>
      {fields.map((rule, index) => {
        let selectedMetadataConfiguration: MetadataConfiguration | undefined = undefined;

        if (fields.get(index).field_type?.includes("MetadataConfiguration")) {
          selectedMetadataConfiguration = metadataConfigurations.find(
            (config: MetadataConfiguration) => config.id === fields.get(index).field_id,
          );
        }

        return (
          <div key={index}>
            <div className="d-flex">
              <div>
                <Field
                  name={`${rule}.field_type`}
                  options={fieldChoices}
                  component={GenericSelect}
                  isMulti={false}
                  placeholder="Field Type"
                  onChange={(value: any) => {
                    changeFieldId(value, index);
                  }}
                  className={`mr-2 ${styles.selectSize}`}
                />
              </div>

              {objectType === "condition" && (
                <div>
                  <Field
                    name={`${rule}.value_operator`}
                    options={[
                      {
                        value: "=",
                        label: "=",
                      },
                      {
                        value: ">",
                        label: ">",
                      },
                      {
                        value: "<",
                        label: "<",
                      },
                      {
                        value: ">=",
                        label: ">=",
                      },
                      {
                        value: "<=",
                        label: "<=",
                      },
                    ]}
                    isMulti={false}
                    component={GenericSelect}
                    placeholder="Value Operator"
                    className="mr-2"
                  />
                </div>
              )}

              {objectType === "action" && <p className="mr-3 ml-2 mt-2">=</p>}

              <div style={{}}>
                {fields.get(index).field_type === "Amount" && (
                  <Field
                    name={`${rule}.value`}
                    type="number"
                    component="input"
                    placeholder="Value"
                    className={`${styles.selectSize}`}
                  />
                )}

                {fields.get(index).field_type === "Vendor" && (
                  <Field
                    name={`${rule}.field_id`}
                    type="text"
                    component={VendorPicker}
                    hideLabel={true}
                    placeholder="Vendor"
                    className={`${styles.selectSize}`}
                  />
                )}

                {fields.get(index).field_type === "Location" && (
                  <Field
                    name={`${rule}.field_id`}
                    type="text"
                    component={locationPicker}
                    hideLabel={true}
                    placeholder="Location"
                    className={`${styles.selectSize}`}
                  />
                )}

                {fields.get(index).field_type === "Project" && (
                  <Field
                    name={`${rule}.field_id`}
                    type="text"
                    component={ProjectPicker}
                    hideLabel={true}
                    placeholder="Project"
                    className={`${styles.selectSize}`}
                  />
                )}

                {fields.get(index).field_type === "BusinessUnit" && (
                  <Field
                    name={`${rule}.field_id`}
                    type="text"
                    component={BusinessUnitPicker}
                    hideLabel={true}
                    placeholder="Business Unit"
                    className={`${styles.selectSize}`}
                  />
                )}

                {fields.get(index).field_type?.includes("MetadataConfiguration") &&
                  metadataConfigurations.length > 0 &&
                  metadataConfigurations.map((config: MetadataConfiguration) => {
                    if (!selectedMetadataConfiguration || selectedMetadataConfiguration.id != config.id) {
                      return null;
                    }

                    if (config.input_type === "select") {
                      return (
                        <Field
                          key={config.id}
                          name={`${rule}.metadata_field_id`}
                          model={"MetadataField"}
                          component={MetadataFieldPicker}
                          className={`${styles.selectSize}`}
                          props={{ metadataConfiguration: config, name: "", isMulti: false }}
                        />
                      );
                    } else if (config.input_type === "number") {
                      return (
                        <Field
                          key={config.id}
                          name={`${rule}.value`}
                          type="number"
                          component="input"
                          placeholder="Value"
                          className={`${styles.selectSize}`}
                        />
                      );
                    } else if (config.input_type === "text") {
                      return (
                        <Field
                          key={config.id}
                          name={`${rule}.value`}
                          type="text"
                          component="input"
                          placeholder="Value"
                          className={`${styles.selectSize}`}
                        />
                      );
                    } else if (config.input_type === "date") {
                      return (
                        <Field
                          key={config.id}
                          name={`${rule}.value`}
                          type="date"
                          component="input"
                          placeholder="Value"
                          className={`${styles.selectSize}`}
                        />
                      );
                    }
                  })}
              </div>

              <button
                type="button"
                onClick={() => fields.remove(index)}
                className={`ml-2 primaryLinkColor ${styles.trash}`}
              >
                <HiMiniTrash />
              </button>
            </div>
          </div>
        );
      })}

      <button
        type="button"
        onClick={() => fields.push({ value_operator: "=" })}
        className={`ml-0 pl-0 pt-0 primaryLinkColor ${styles.addButton}`}
      >
        Add another {objectType}
      </button>
    </div>
  );
};

const mapStateToProps = (state: any) => ({ initialValues: state.metadataConfigurationForm });

export default reduxForm<{}, DefaultRulesUpsertProps>({
  form: formName,
  keepDirtyOnReinitialize: false,
  enableReinitialize: false,
  destroyOnUnmount: false,
})(DefaultRulesUpsert);
