import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";

type PostingPeriodPickerPropsType = {
  label?: string;
  input: any;
  required?: boolean;
  tooltip?: string;
  instanceId?: string;
};

type PostingPeriodObjType = {
  name?: string;
  status?: string;
  external_id?: string;
  value?: number | string;
  label?: string;
};

const PostingPeriodPicker = ({
  label,
  input,
  required,
  tooltip,
  instanceId = "posting-period-picker",
}: PostingPeriodPickerPropsType) => {
  const [postingPeriods, setPostingPeriods] = useState<PostingPeriodObjType[]>([]);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const currentUserCompanyId = currentUser?.company?.id;
  const mounted = useRef(false);
  const [selected, setSelected] = useState<PostingPeriodObjType>();
  const [dataList, setDataList] = useState<PostingPeriodObjType[]>([]);

  const parseForSelect = (options: PostingPeriodObjType[]) => {
    return options.map((option: PostingPeriodObjType) => {
      return {
        value: option.external_id,
        label: option.name,
        status: option.status,
      };
    });
  };

  const filterActiveRecord = (dateList: PostingPeriodObjType[]) => {
    return dateList.filter((list) => list.status === "ACTIVE");
  };

  const getPostingPeriods = useCallback(async () => {
    const result: AxiosResponse = await restApiService.get(
      `companies/${currentUserCompanyId}/custom_data_list?model=PostingPeriod`,
      null,
      null,
      true,
      null,
      true,
    );
    if (result?.data?.properties?.data_list) {
      const propertiesDateList = result.data.properties.data_list;
      setDataList(propertiesDateList);
      const postingPeriodsList = filterActiveRecord(propertiesDateList);
      let list = parseForSelect(postingPeriodsList);
      if (mounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Posting Period --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setPostingPeriods(list);
      }
      return list;
    }
  }, []);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;

    if (input.value) {
      const postingPeriod = await options.filter((option: any) => option.value === input.value);

      if (_.isArray(postingPeriod)) {
        selectedValues = postingPeriod[0];
      } else {
        const inactivePostingPeriod = _.find(dataList, (option) => option.value === input.value);
        selectedValues = inactivePostingPeriod;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    getPostingPeriods().then((res) => {
      findSelected(input, res).then((response: PostingPeriodObjType) => {
        setSelected(response);
      });
    });
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [input]);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={false}
          value={selected}
          inputId={instanceId} // don't remove or change used for automation testing
          isClearable={!required}
          onChange={(selected) => onChangeSingle(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={postingPeriods}
          getOptionLabel={(option: PostingPeriodObjType) => {
            return (
              <>
                <span className="formField">{option.label}</span>
                {option.status === "INACTIVE" && <small>{option.status}</small>}
              </>
            );
          }}
        ></Select>
      </Form.Group>
    </>
  );
};

export default PostingPeriodPicker;
