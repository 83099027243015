import { RenderCheckBox, RenderSelect } from "../../../../../../components/forms/bootstrapFields";
import { CompanyDefaultsTypes } from "../../settingsTypes";

const BudgetSettings: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.budget_include_pending_po",
    tooltip: {
      show: true,
      title: "Pending Pos will be applied to calculate budget remaining balance",
      placement: "top",
    },
    label: "Apply Pending POs To Budget Balance",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.budget.is_stop_auto_closed",
    tooltip: {
      show: true,
      title: "Budget will not get auto closed when budget balance goes negative, once select.",
      placement: "top",
    },
    label: "Do not auto-close the budget",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.budget.allow_to_select_budget_lines",
    tooltip: {
      show: true,
      title: "When checked, this setting will allow users to select multiple Budgets Lines",
      placement: "top",
    },
    label: "Allow users to select multiple Budgets Lines",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.budget.allow_multi_currency_budget",
    tooltip: {
      show: true,
      title: "It will allow to select budget as per selected vendor linked currencies.",
      placement: "top",
    },
    label: "Allow multi-currency budget",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.budget_limit_variance",
    tooltip: {
      show: false,
    },
    label: "Budget Limit Variance:",
    type: "drop-down",
    component: RenderSelect,
    isShow: (isShowObj) => {
      return true;
    },
    options: [
      { label: "--- Select budget limit ---", value: "" },
      { label: "0%", value: "zero_percent" },
      { label: "5%", value: "five_percent" },
      { label: "10%", value: "ten_percent" },
      { label: "15%", value: "fifteen_percent" },
      { label: "20%", value: "twenty_percent" },
      { label: "25%", value: "twenty_five_percent" },
    ],
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.budget.show_field_at",
    tooltip: {
      show: true,
      title:
        "Budget field by default appears at the header level. If chosen, the budget field will be appear at PO and Invoice line levels and hidden from the header level.",
      placement: "top",
    },
    label: "Show budget at:",
    type: "drop-down",
    component: RenderSelect,
    isShow: (isShowObj) => {
      return true;
    },
    options: [
      { label: "Header level", value: "header_level" },
      { label: "Line level", value: "line_level" },
    ],
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
];
export default BudgetSettings;
