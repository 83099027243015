import _ from "lodash";
const { useSelector } = require("react-redux");

const usePermission = () => {
  const user = useSelector((state) => state.user);
  const hasUserPermission = (resource) => {
    return _.isArray(user.perm_resources) && user.perm_resources.includes(resource);
  };

  const hasUserModule = (module) => {
    return _.isArray(user.module_settings) && user.module_settings.includes(module);
  };

  const hasUserRole = (roleName) => {
    return Boolean(_.isArray(user.roles) && user.roles.find((roles) => roles.name == roleName));
  };

  // take json of routes and return first validate(Permited router of login user)
  const getValidRedirectUrl = (tabRoutes) => {
    const firstValidRoute = tabRoutes.find(
      (route) =>
        (route.authorization && hasUserPermission(route.authorization.I + route.authorization.a)) ||
        !route.authorization,
    );

    if (firstValidRoute && firstValidRoute.path) {
      firstValidRoute["link"] = firstValidRoute.path;
      return firstValidRoute;
    } else if (firstValidRoute && firstValidRoute.href) {
      return firstValidRoute;
    } else {
      return {
        link: "/",
      };
    }
  };

  return {
    hasUserPermission,
    hasUserModule,
    hasUserRole,
    getValidRedirectUrl,
  };
};

export default usePermission;
