import React from "react";
import TabNavigation from "../../../components/navigation/tabNavigation";

//NOTE: add only those route which we want to show on tabNavigations
const purchaseOrderNavigations = [
  {
    pageName: "Purchase Orders",
    href: "purchase_orders",
    authorization: {
      I: "list",
      a: "PurchaseOrders",
    },
  },
  {
    path: "/ap/change_orders",
    pageName: "Change Orders",
    authorization: {
      I: "list",
      a: "PurchaseOrders",
    },
  },
];

export type purchaseOrdersNavsProps = {
  activePageName: string;
};

const PurchaseOrdersNavsTabs = ({ activePageName }: purchaseOrdersNavsProps) => {
  return <TabNavigation activePageName={activePageName} navigationTab={purchaseOrderNavigations} />;
};

export default PurchaseOrdersNavsTabs;
