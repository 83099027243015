import { file_types } from "../../components/app.svc.Lookup";
import {
  application_types,
  authentication_methods,
  punchout_providers,
  system_names,
  status,
} from "../../lookups/integrationsLookups";
import RestApi from "../../providers/restApi";
import { change } from "redux-form";
import _ from "lodash";
import { CreateNotification, NotificationType } from "../../services/general/notifications";

const restApiService = new RestApi();

// Contstants
export const INTEGRATION_FORM_NAME = "credentialsForm";
// form Mode i.e. ADD/EDIT/CLOSE
export const ADD_INTEGRATION = "ADD_INTEGRATION";
export const EDIT_INTEGRATION = "EDIT_INTEGRATION";
export const CLOSED_INTEGRATION = "CLOSED_INTEGRATION";

// ACTION TYPES
const SET_COMPANY = "SET_COMPANY";
const SET_APPLICATION_TYPES = "SET_APPLICATION_TYPES";
const SET_AUTHENTICATION_METHODS = "SET_AUTHENTICATION_METHODS";
const SET_IS_INTEGRATION_MODAL_OPEN = "SET_IS_INTEGRATION_MODAL_OPEN";
const SET_CAMBRIDGE_ACCOUNTS = "SET_CAMBRIDGE_ACCOUNTS";
const SET_O_AUTH_PROCESSING = "SET_O_AUTH_PROCESSING";
const SET_INTEGRATION_FORM_MODE = "SET_INTEGRATION_FORM_MODE";
const SET_INTEGRATION_FORM = "SET_INTEGRATION_FORM";
const SET_PUNCHOUT_TEST_LOADING = "SET_PUNCHOUT_TEST_LOADING";
const SET_PUNCHOUT_TEST_RESPONSE = "SET_PUNCHOUT_TEST_RESPONSE";
const SET_SFTP_VALIDATION_LAODING = "SET_SFTP_VALIDATION_LAODING";
const SET_SFTP_VALIDATION_RESPONSE = "SET_SFTP_VALIDATION_RESPONSE";
const SET_INTEGRATION_SETTINGS = "SET_INTEGRATION_SETTINGS";

// INITIAL_STATE
const initialState = {
  // form
  credentialsForm: {
    form: {
      system_name: "",
      application_type: "",
      cc_from_contact_id: "",
      card_program_id: "",
      authentication_method: "",
      status: "",
      description: "",
      rcn_ids: [],
    },

    company: {
      payoneer_programs: [],
    },
  },

  // application states
  isIntegrationModalOpen: false,
  applicationTypes: [{ label: "", value: "" }],
  authenticationMethods: [{ label: "", value: "" }],
  cambridgeAccounts: [],
  oAuthProcessing: false,
  integrationFormMode: CLOSED_INTEGRATION,

  // business logic states
  company: {},
  integrationSettings: [],

  system_names: [{ label: "", value: "" }, ...system_names],
  status: [{ label: "", value: "" }, ...status],
  file_types: [...file_types],
  punchout_providers: [{ label: "", value: "" }, ...punchout_providers],
  punchoutTestLoading: false,
  punchoutTestResponse: null,
  sftpValidationTestLoading: false,
  sftpValidationTestResponse: { status: null, message: "" },
};

const integrationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COMPANY:
      return { ...state, company: payload };
    case SET_INTEGRATION_SETTINGS:
      return { ...state, integrationSettings: payload };
    case SET_IS_INTEGRATION_MODAL_OPEN:
      return { ...state, isIntegrationModalOpen: payload };
    case SET_AUTHENTICATION_METHODS:
      return { ...state, authenticationMethods: payload };
    case SET_APPLICATION_TYPES:
      return { ...state, applicationTypes: payload };
    case SET_CAMBRIDGE_ACCOUNTS:
      return { ...state, cambridgeAccounts: payload };
    case SET_O_AUTH_PROCESSING:
      return { ...state, oAuthProcessing: payload };
    case SET_INTEGRATION_FORM_MODE:
      return { ...state, integrationFormMode: payload };
    case SET_INTEGRATION_FORM:
      return { ...state, credentialsForm: payload };
    case SET_PUNCHOUT_TEST_LOADING:
      return { ...state, punchoutTestLoading: payload };
    case SET_PUNCHOUT_TEST_RESPONSE:
      return { ...state, punchoutTestResponse: payload };
    case SET_SFTP_VALIDATION_LAODING:
      return { ...state, sftpValidationTestLoading: payload };
    case SET_SFTP_VALIDATION_RESPONSE:
      return { ...state, sftpValidationTestResponse: payload };
    default:
      return state;
  }
};

export default integrationsReducer;

// 1) direct state setters
export const setCompany = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_COMPANY, payload });
};

export const setIntegrationSettings = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_INTEGRATION_SETTINGS, payload });
};

export const setAuthenticationMethods = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_AUTHENTICATION_METHODS, payload: [{ label: "", value: "" }, ...payload] });
};

export const setApplicationTypes = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_APPLICATION_TYPES, payload: [{ label: "", value: "" }, ...payload] });
};

export const setIsIntegrationModalOpen = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_IS_INTEGRATION_MODAL_OPEN, payload });
};

export const setCambridgeAccounts = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_CAMBRIDGE_ACCOUNTS, payload });
};

export const setOAuthProcessing = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_O_AUTH_PROCESSING, payload });
};

export const setPunchoutTestLoading = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_PUNCHOUT_TEST_LOADING, payload });
};

export const setPunchoutTestResponse = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_PUNCHOUT_TEST_RESPONSE, payload });
};

export const setSftpValidationLoading = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_SFTP_VALIDATION_LAODING, payload });
};

export const setSftpValidationResponse = (payload) => (dispatch, getState) => {
  dispatch({ type: SET_SFTP_VALIDATION_RESPONSE, payload });
};

// 2) ACTIOINS CREATOR function that may utilize restApi or state
export const getCompany = () => async (dispatch) => {
  try {
    const res = await restApiService.get("company");
    dispatch(setCompany(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getIntegrationSettings = () => async (dispatch, getState) => {
  try {
    const res = await restApiService.get("integration_settings");
    dispatch(setIntegrationSettings(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getApplicationType = (system_name) => (dispatch, getState) => {
  // first add application types
  dispatch(setApplicationTypes(application_types));

  // then sort accordingly
  const app_types = [];
  application_types.forEach((val, index) => {
    if (val && _.isArray(val.system_names) && val.system_names.includes(system_name)) {
      app_types.push(val);
      dispatch(setApplicationTypes(app_types));
    }
  });
};

export const onSystemNameChange = (system_name) => (dispatch, getState) => {
  dispatch(getApplicationType(system_name));
  dispatch(change(INTEGRATION_FORM_NAME, "form.application_type", ""));
  dispatch(setAuthenticationMethods([]));
};

export const getAuthenticationMethod = (system_name, application_type) => (dispatch, getState) => {
  const app_type = [];
  dispatch(setAuthenticationMethods(authentication_methods));
  authentication_methods.forEach((obj, index) => {
    if (
      _.isArray(obj.system_names) &&
      _.isArray(obj.App_types) &&
      obj.system_names.includes(system_name) &&
      obj.App_types.includes(application_type)
    ) {
      app_type.push(obj);
      dispatch(setAuthenticationMethods(app_type));
      dispatch(change(INTEGRATION_FORM_NAME, "form.authentication_method", app_type[0].label));
    }
  });
};

export const onApplicationTypeChange = (system_name, application_type) => (dispatch, getState) => {
  dispatch(getAuthenticationMethod(system_name, application_type));
};

export const setIntegrationFormMode = (formMode) => (dispatch, getState) => {
  if (formMode !== ADD_INTEGRATION && formMode !== EDIT_INTEGRATION && formMode !== CLOSED_INTEGRATION) {
    return dispatch({
      type: SET_INTEGRATION_FORM_MODE,
      payload: CLOSED_INTEGRATION,
    });
  }

  dispatch({
    type: SET_INTEGRATION_FORM_MODE,
    payload: formMode,
  });
};

export const setIntegrationForm = (formData) => (dispatch, getState) => {
  dispatch({ type: SET_INTEGRATION_FORM, payload: formData });
};

export const closeIntegrationModal = () => (dispatch, getState) => {
  dispatch(setIsIntegrationModalOpen(false));
  dispatch(setIntegrationFormMode(CLOSED_INTEGRATION));
  dispatch(setApplicationTypes(initialState.applicationTypes));
  dispatch(setAuthenticationMethods(initialState.authenticationMethods));
  dispatch(setIntegrationForm(initialState.credentialsForm));
  dispatch(setCambridgeAccounts(initialState.cambridgeAccounts));
};

export const checkPunchoutIntegration = (providerName) => async (dispatch, getState) => {
  dispatch(setPunchoutTestResponse(null));
  dispatch(setPunchoutTestLoading(true));
  try {
    const response = await restApiService.get("punchout/generate_url", {
      provider: providerName,
    });
    if (response && response.data && response.data.url) {
      dispatch(setPunchoutTestResponse("Punchout url generated succesfully!"));
    }
    dispatch(setPunchoutTestLoading(false));
  } catch (error) {
    dispatch(setPunchoutTestLoading(false));
    CreateNotification("Data Issue", error.response.data.base, NotificationType.warning);
  }
};
