import React, { useState } from "react";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";
import { RenderField } from "../../../forms/bootstrapFields";
import { required } from "../../../../services/validations/reduxFormValidation";
import { PaymentMethodValidatorSvc } from "../paymentMethodValidatorSvc";
import { IDType } from "../../../../services/common/types/common.type";

type InstitutionNumberPropsType = {
  modelName?: string;
  callBack: (arg: IDType) => void;
};

const InstitutionNumber = ({ modelName, callBack }: InstitutionNumberPropsType) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState("");
  const [inputLength, setInputLength] = useState(0);

  const validateInstitutionNumber = async (value: IDType) => {
    const stringValue = String(value);
    setInputLength(stringValue.length);

    if (stringValue.length === 3) {
      try {
        const data = await PaymentMethodValidatorSvc.ValidateInstitutionNumber(value);
        if (data?.validation) {
          setValidationMessage(data.validation);
        } else {
          setValidationMessage(data?.errors[0]?.message);
        }
      } catch (error) {
        throw error;
      }
      if (callBack) {
        callBack(value);
      }
    }
  };

  return (
    <>
      <Field
        name={`${modelName}.institution_number`}
        component={RenderField}
        type="text"
        maxLength={3}
        tooltip="The institution number is a 3-digit number identifying the financial institution to which a payment item is directed. Along with the account number, it is essential for delivering payments through the clearing system."
        label={t("common.paymentMethod.institutionNumber")}
        className={`w-100`}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => validateInstitutionNumber(e.target.value)}
        required
        validate={[required]}
      />
      {validationMessage && inputLength === 3 && (
        <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
      )}
    </>
  );
};

export default InstitutionNumber;
