import React, { useEffect, useState } from "react";
import Panel from "../../../components/panel/panel";
import { Col, Container, Row, Table } from "react-bootstrap";
import useIsMounted from "../../../components/common/hooks/useIsMounted";
import "../../pages.style.css";
import RestApi from "../../../providers/restApi";
import { useParams } from "react-router";
import { BreadcrumbTrail } from "../../../components/navigation/navigationLinks";
import PageTitle from "../../../components/page/pageTitle";
import PrintButton from "../../../components/buttons/printButton";
import ChatBox from "../../../components/vp/chatBox/chatBox";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { formattedAmount } from "../../../services/general/helpers";
import styles from "./purchaseOrder.module.css";
import { IPurchaseOrderDetail, IPoItem, IExpense } from "../../../services/vp/purchaseOrder/purchaserOrders.type";
import { RiPrinterLine } from "react-icons/ri";
const restApiService = new RestApi();

const PurchaseOrderDetails = () => {
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrderDetail>();
  const [poCompanyAddressLine, setPoCompanyAddress] = useState<Array<string>>([]);
  const { t } = useTranslation();

  const isMounted = useIsMounted();
  const { id } = useParams<{ id: string | undefined }>();

  const downloadBlobFile = async (blob: Blob | MediaSource, fileName: string) => {
    try {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading blob file:", error);
    }
  };

  const downloadPdf = async () => {
    try {
      if (purchaseOrder?.number) {
        const response = await restApiService.get("vendor_purchase_orders/" + id + ".pdf", false, false, true, "blob");
        downloadBlobFile(response.data, purchaseOrder?.number + ".pdf");
      }
    } catch (error) {}
  };
  const getPurchaseOrder = async () => {
    try {
      const response = await restApiService.get("vendor_purchase_orders/" + id);
      if (isMounted) {
        setPurchaseOrder(response.data);
        const addressLines = response.data?.company?.formatted_address.split("\\n");
        setPoCompanyAddress(addressLines);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted) {
      getPurchaseOrder();
    }
  }, []);

  const poCompanyAddress = _.isArray(poCompanyAddressLine) && poCompanyAddressLine.length > 0 && (
    <div className="text-right">
      {poCompanyAddressLine.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </div>
  );

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <BreadcrumbTrail to={"/ar/purchase_orders"} pageName={"Purchase Order"} title={"Purchase Order Details"} />
      <Row className="mt-3 mb-4">
        <Col md={9} className="d-flex align-items-center">
          <PageTitle title={purchaseOrder?.number} status={purchaseOrder?.status ? purchaseOrder.status : ""} />
        </Col>
        <Col md={3} className="d-flex align-items-center justify-content-end">
          <div className="d-flex justify-content-end">
            {purchaseOrder && (
              <div className="ml-1">
                <ChatBox
                  modelDataNumber={purchaseOrder?.number}
                  modelDataId={purchaseOrder.id}
                  modelDataType="PurchaseOrder"
                />
              </div>
            )}
            <div className="ml-1">
              <button type="button" className={"btn btn-secondary no-print"} onClick={() => downloadPdf()}>
                <RiPrinterLine className="mr-2" style={{ fontSize: "20px" }} />
                {t("Download")}
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <hr className="full-border" />
      <Row className="mt-3 mb-4">
        <Col>
          <Panel
            subHeadingStyle={{ color: "#76777A", fontSize: "16px" }}
            cardStyle={{ padding: "0 35px" }}
            cardClasses="mt-3"
          >
            {purchaseOrder && _.isPlainObject(purchaseOrder) && _.isPlainObject(purchaseOrder.company) && (
              <Row>
                <Col sm="10" className="d-flex align-items-center">
                  <img
                    className={styles["maxWidth-300"]}
                    alt={purchaseOrder.company && purchaseOrder.company.name}
                    src={purchaseOrder.company.logo_url}
                  />
                </Col>
                <Col sm="2">
                  <h5 className="text-right font-weight-bold darkGrayFontColor">{purchaseOrder.company.name}</h5>
                  {poCompanyAddress}
                  {purchaseOrder.company.url && <p className="text-right">{purchaseOrder.company.url}</p>}
                </Col>
              </Row>
            )}
            <hr />
            <Row>
              <Col>
                <h2 className={"purchase-order pb-4 darkGrayFontColor"}>{t("purchaseOrder")}</h2>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <dl className="row">
                  <dt className="col-6 col-sm-4 darkGrayFontColor text-sm-right">{t("vendor")}</dt>
                  <dd className="col-6 col-sm-8 detailValue">{purchaseOrder?.vendor && purchaseOrder.vendor.name}</dd>
                </dl>
              </Col>
              <Col md={4}>
                <dl className="row">
                  <dt className="col-6 col-sm-4 darkGrayFontColor text-sm-right">{t("details.shipTo")}</dt>
                  <dd className="col-6 col-sm-8 detailValue">
                    {purchaseOrder?.address?.address1} {purchaseOrder?.address?.address2}{" "}
                    {purchaseOrder?.address?.address3} {purchaseOrder?.address?.city} {purchaseOrder?.address?.state}{" "}
                    {purchaseOrder?.address?.zipcode} {purchaseOrder?.address?.country}
                  </dd>
                </dl>
              </Col>
              <Col md={4}>
                <dl className="row">
                  <dt className="col-6 col-sm-4 darkGrayFontColor text-sm-right">{t("details.number")}</dt>
                  <dd className="col-6 col-sm-8 detailValue">{purchaseOrder?.number}</dd>

                  <dt className="col-6 col-sm-4 darkGrayFontColor text-sm-right">{t("details.date")}</dt>
                  <dd className="col-6 col-sm-8 detailValue">{purchaseOrder?.date}</dd>
                </dl>
              </Col>
            </Row>
            <Row className={"border border-dark mr-sm-3 ml-sm-3 pt-3"}>
              <Col>
                <Row>
                  <Col sm>
                    <dl className="row">
                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.amount")}</dt>
                      <dd className="col-6 detailValue">
                        {formattedAmount(purchaseOrder?.amount.toString(), purchaseOrder?.currency_code)}{" "}
                      </dd>
                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.status")}</dt>
                      <dd className="col-6 detailValue">{purchaseOrder?.status}</dd>

                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.paymentTerms")}</dt>
                      <dd className="col-6 detailValue">{purchaseOrder?.term?.name}</dd>

                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.requestor")}</dt>
                      <dd className="col-6 detailValue">{purchaseOrder?.requestor?.name}</dd>
                    </dl>
                  </Col>
                  <Col sm>
                    <dl className="row">
                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.subsidiary")}</dt>
                      <dd className="col-6 detailValue">{purchaseOrder?.subsidiary?.name}</dd>

                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.department")}</dt>
                      <dd className="col-6 detailValue">{purchaseOrder?.department?.name}</dd>

                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.incoterms")}</dt>
                      <dd className="col-6 detailValue">{purchaseOrder?.inco_terms}</dd>

                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.carrier")}</dt>
                      <dd className="col-6 detailValue">{purchaseOrder?.carrier_name}</dd>
                    </dl>
                  </Col>
                  <Col sm>
                    <dl className="row">
                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.carrierAccount")}</dt>
                      <dd className="col-6 detailValue">{purchaseOrder?.carrier_account}</dd>

                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.shippingMethod")}</dt>
                      <dd className="col-6 detailValue">{purchaseOrder?.shipping_method}</dd>

                      <dt className="col-6 darkGrayFontColor text-sm-right">{t("details.currencyCode")}</dt>
                      <dd className="col-6 detailValue">{purchaseOrder?.currency_code}</dd>
                    </dl>
                  </Col>
                </Row>
                <Row className={`px-4 ${styles.poLines}`}>
                  {purchaseOrder && purchaseOrder.po_items?.length > 0 && (
                    <>
                      <div className={styles.sectionTitle}>Items</div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>{t("items.item")}</th>
                            <th>{t("items.qty")}</th>
                            <th>{t("items.unitPrice")}</th>
                            <th>{t("details.amount")}</th>
                            <th>{t("details.tax")}</th>
                            <th>{t("details.total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchaseOrder.po_items.map((poitem: IPoItem, index: number) => (
                            <tr key={poitem.id}>
                              <td className={"detailValue"}>{poitem.product_name}</td>
                              <td className={"detailValue"}>{poitem.qty}</td>
                              <td className={"detailValue"}>
                                {formattedAmount(poitem.unit_price.toString(), purchaseOrder.currency_code)}
                              </td>
                              <td className={"detailValue"}>
                                {formattedAmount(poitem.amount.toString(), purchaseOrder.currency_code)}
                              </td>
                              <td className={"detailValue"}>
                                {formattedAmount(poitem.tax.toString(), purchaseOrder.currency_code)}
                              </td>
                              <td className={"detailValue"}>
                                {formattedAmount(poitem.total.toString(), purchaseOrder.currency_code)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </Row>
                <Row className={`px-4 ${styles.poLines}`}>
                  {purchaseOrder && purchaseOrder.invoice_debit_accounts?.length > 0 && (
                    <>
                      <div className={styles.sectionTitle}>Expenses</div>
                      <Table>
                        <thead>
                          <tr>
                            <th>{t("details.account")}</th>
                            <th>{t("details.department")}</th>
                            <th>{t("details.project")}</th>
                            <th>{t("details.subAmount")}</th>
                            <th>{t("details.tax")}</th>
                            <th>{t("details.amount")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {purchaseOrder.invoice_debit_accounts.map((expense: IExpense, index: number) => (
                            <tr key={expense.id}>
                              <td className={"detailValue"}>
                                {expense.account?.number} {expense.account?.name}
                              </td>
                              <td className={"detailValue"}>{expense.department?.name}</td>
                              <td className={"detailValue"}>{expense.project?.name}</td>
                              <td className={"detailValue"}>
                                {formattedAmount(expense.sub_amount?.toString(), purchaseOrder.currency_code)}
                              </td>
                              <td className={"detailValue"}>
                                {formattedAmount(expense.tax?.toString(), purchaseOrder.currency_code)}
                              </td>
                              <td className={"detailValue"}>
                                {formattedAmount(expense.amount?.toString(), purchaseOrder.currency_code)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
            <Row className={"pt-3 mr-sm-3 ml-sm-3"}>
              <Col sm={7}>
                <dl className="row">
                  <dt className="darkGrayFontColor text-sm-right mr-2">{t("details.notes")}</dt>
                  <dd className="detailValue">{purchaseOrder?.notes}</dd>
                </dl>
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
    </Container>
  );
};

export default PurchaseOrderDetails;
