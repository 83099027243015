import "react-tagsinput/react-tagsinput.css";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { Component, useEffect } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { useState } from "react";
import RestApi from "../../../../providers/restApi";
import _ from "lodash";
import ReactDOMServer from "react-dom/server";
import { Editor } from "react-draft-wysiwyg";
import moment from "moment";
import { checkNameWithEmail } from "../../../../services/admin/invoices/emailSvc";
import { stateFromHTML } from "draft-js-import-html";
import draftToHtml from "draftjs-to-html";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import DropButton from "../../../common/buttons/dropButton";
import { GrAttachment } from "react-icons/gr";
import Autosuggest from "react-autosuggest";
import { useDispatch } from "react-redux";

const restApiService = new RestApi();

// To resolve the issue that convertFromHTML is not showing images
const customContentStateConverter = (contentState: any) => {
  // changes block type of images to 'atomic'
  const newBlockMap = contentState.getBlockMap().map((block: any) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey !== null) {
      const entityBlock = contentState.getEntity(entityKey);
      const entityType = entityBlock.getType();
      switch (entityType) {
        case "IMAGE": {
          const newBlock = block.merge({
            type: "atomic",
            text: "img",
          });
          return newBlock;
        }
        default:
          return block;
      }
    }
    return block;
  });
  const newContentState = contentState.set("blockMap", newBlockMap);
  return newContentState;
};

const ForwordEmailDetail = ({ email, mailType }: { email: any; mailType: string }) => {
  return (
    <div>
      {mailType == "reply" ? (
        <div>
          <p className={"w-25"}>
            On {moment(email.received_at).calendar()}{" "}
            <span style={{ fontWeight: "bold" }}>{checkNameWithEmail(email.from)} </span>
            wrote:
          </p>
        </div>
      ) : (
        <div>
          <p>----------Forwarded message---------</p>
          <p className={"w-25"}>
            From: <span style={{ fontWeight: "bold" }}>{checkNameWithEmail(email.from)}</span>
          </p>
          <p className={"w-25"}>
            Date: <span> {moment(email.received_at).format("MMMM Do YYYY, h:mm:ss a")}</span>
          </p>
          <p className={"w-25"}>
            Subject: <span>{email.subject}</span>
          </p>
          <p className={"w-25"}>
            To:
            <span>
              {_.isArray(email?.to) &&
                email.to.map((emailTo: any, index: number) => <span key={index.toString()}>{emailTo.email}</span>)}
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

// In some cases the html comes up with head and body, this consider the head code as text that's why need to remove the head from html
const filterHtml = (html: any) => {
  return html?.replace(/(<head.*?head>)|(<head[\S\s]+?head>)|(<!--[\S\s]*?$)/g, "");
};

export type forwardEmailPropsType = {
  email: any;
  mailType: string;
  isReplyAll: boolean;
  closeForwordEmail: () => void;
  height: number;
};

const ForwardEmail = ({ email, mailType, isReplyAll, closeForwordEmail, height }: forwardEmailPropsType) => {
  const dispatch = useDispatch();
  const [to, setTo] = useState<Array<any>>([]);
  const [files, setFiles] = useState<Array<File>>([]);
  const [subject, setSubject] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [loader, setLoader] = useState(false);
  const [setting, setSetting] = useState({ showCc: false });
  const [Cc, setCc] = useState<Array<any>>([]);
  const [contacts, setContacts] = useState([{ name: "No results found" }]);
  const [attachments, setAttachments] = useState([]);
  const [toInput, setToInput] = useState("");
  const [ccInput, setCcInput] = useState("");
  const [error, setError] = useState({ toRequired: "" });

  const initializeEmail = () => {
    let newToArray = [];
    let newCCArray: any[] = [];
    if (email && email.cc) {
      if (mailType === "reply" && isReplyAll) {
        email.cc.map((item: any, index: number) => {
          newCCArray.push(item.email);
        });
      } else if ((mailType === "reply" && !isReplyAll) || mailType === "forward") {
        newCCArray = [];
        setCc([]);
      }
    }
    if (mailType === "reply" && !isReplyAll) {
      newToArray.push(email?.reply_to ?? email?.from?.email);
    } else if (mailType === "reply" && isReplyAll) {
      setSetting({ showCc: true });
      if (email && email.to) {
        email.to.map((item: any, index: number) => {
          newToArray.push(item.email);
        });
      }

      if (email && email.from) {
        newToArray.unshift(email.from.email);
      }
    }

    if (email) {
      setAttachments(email.attachments);
      setSubject(email.subject);
      setTo(_.uniq(newToArray));
      setCc(_.uniq(newCCArray));
    }
    let html = ReactDOMServer.renderToString(<ForwordEmailDetail email={email} mailType={mailType} />);
    // stateFromHTML is used because when we used the convertFromHtml the html trim's off the <p> tags
    const blocksFromHtml = stateFromHTML(html + filterHtml(email.html));
    setEditorState(EditorState.createWithContent(customContentStateConverter(blocksFromHtml)));
  };

  useEffect(() => {
    initializeEmail();
  }, []);

  const checkDefaultSubject = (mailType: string) => {
    if (mailType == "forward") {
      return "Fwd:";
    } else {
      return "Re:";
    }
  };

  const forwordEmail = async (event: any) => {
    event.preventDefault();
    const recipients = [...to];
    const ccRecipients = [...Cc];

    if (!to.length && !toInput) {
      setError((prev) => {
        return { ...prev, toRequired: "Minimum one recipient is required" };
      });
      return;
    } else if (!to.length && toInput) {
      setTo((prev) => [toInput]);
      recipients.push(toInput);
    }

    if (!Cc.length && ccInput) {
      setCc((prev) => [ccInput]);
      ccRecipients.push(ccInput);
    }

    setLoader(true);
    const msg = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const formdata = new FormData();
    if (files.length) {
      let index = 0;
      files.forEach((file) => {
        formdata.append("[inbound_email][attachments_attributes][" + index + "][filename]", file.name);
        formdata.append("[inbound_email][attachments_attributes][" + index + "][asset_file]", file);
        index++;
      });
    }

    formdata.append("inbound_email[to]", JSON.stringify(recipients));
    formdata.append("inbound_email[subject]", subject ? subject : checkDefaultSubject(mailType));
    formdata.append("inbound_email[message]", msg);
    formdata.append("inbound_email[attachments]", JSON.stringify(attachments));
    formdata.append("inbound_email[mime_type]", "text/html");
    if (Cc) {
      formdata.append("inbound_email[cc]", JSON.stringify(ccRecipients));
    }
    if (mailType === "forward") {
      formdata.append("inbound_email[forwards]", "true");
    }

    try {
      const resposne = await restApiService.formWithImage(
        "inbound_emails/" + email.id + "/send_reply",
        formdata,
        "post",
      );
      setLoader(false);
      closeForwordEmail();
      CreateNotification("Success", "Email send successfully", NotificationType.success);
    } catch (error: any) {
      setLoader(false);
      if (error?.response?.data) {
        var errormsg = "";
        for (const key in error.response.data) {
          if (key === "base") {
            errormsg += error.response.data[key] + " ,";
            CreateNotification("Data Issue:", errormsg, NotificationType.danger);
          }
        }
      }
    }
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const handleChangeTo = (to: any) => {
    console.log("to", to);
    setTo(to);
  };

  const handleChangeCc = (Cc: any) => {
    setCc(Cc);
  };

  const parseUploadFiles = (eventFiles: Array<File>) => {
    setFiles([...files, ...eventFiles]);
  };

  const addCc = () => {
    setting.showCc = !setting.showCc;
    setSetting({ ...setting });
  };

  const removeAttachment = (attachments: any, attachment: any, i: number, isS3Attachment: any) => {
    attachments.splice(i, 1);
    if (isS3Attachment) {
      setAttachments(attachments);
    } else {
      setFiles(attachments);
    }
  };

  const autocompleteRenderInput = (props: any): Component => {
    const { addTag } = props;
    const handleOnChange = (e: any, { newValue, method }: any) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const inputValue = (props.value && props.value.trim().toLowerCase()) || "";
    const inputLength = inputValue.length;
    let suggestions;
    if (inputLength) {
      restApiService
        .get("contacts.lk", {
          contact_type: "COMPANY",
          name_or_email: inputValue,
        })
        .then((res) => {
          if (res.data) {
            if (!_.isEqual(contacts, res.data)) {
              setContacts(res.data);
            }
          }
        });
    }
    suggestions = contacts.filter((state) => {
      return state.name.toLowerCase().slice(0, inputLength) === inputValue;
    });
    return (
      <Autosuggest
        ref={props.ref}
        suggestions={suggestions}
        // shouldRenderSuggestions={(value) => value && value.trim().length > 0}
        getSuggestionValue={(suggestion) => suggestion.name}
        renderSuggestion={(suggestion: any) => (
          <div className="autoSuggestions">
            <b className="suggestName">{suggestion.name}</b> {suggestion.email}
          </div>
        )}
        // render
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }: any) => {
          addTag(suggestion.email);
        }}
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={() => {}}
      />
    ) as unknown as Component;
  };

  return (
    <Container fluid className={"emailSendBox"}>
      {loader && <Spinner className={"loaderFullScreen"} animation="border" variant="info" />}
      <form onSubmit={forwordEmail}>
        <Row className={"text-white"}>
          <Col xs={6} className={"bg-blue"}>
            {mailType === "reply" ? "Reply" : "Forward"} Email
          </Col>
          <Col xs={6} className={"bg-blue text-right"}>
            <button onClick={closeForwordEmail} className={"px-2 bg-transparent border-0 text-white"}>
              <strong>x</strong>
            </button>
          </Col>
          <Col xs={11} className={"border-y-secondary py-1"}>
            <div>
              <p className={"float-left"}>To :</p>
              <TagsInput
                className={"toInput"}
                value={to ? to : []}
                inputProps={{ placeholder: "" }}
                onChange={handleChangeTo}
                renderInput={autocompleteRenderInput}
                inputValue={toInput}
                onChangeInput={(value) => setToInput(value)}
              />
              {error.toRequired && !toInput && <div className="color-error">{error.toRequired}</div>}
            </div>
            {setting.showCc && (
              <div>
                <p className={"float-left"}>Cc :</p>
                <TagsInput
                  className={"toInput"}
                  value={Cc ? Cc : []}
                  inputProps={{ placeholder: "" }}
                  onChange={handleChangeCc}
                  renderInput={autocompleteRenderInput}
                  inputValue={ccInput}
                  onChangeInput={(value) => setCcInput(value)}
                />
              </div>
            )}
          </Col>
          <Col xs={1} className={"border-y-secondary"}>
            <button onClick={addCc} type={"button"} className={"p-0 mr-4 float-right bg-none border-0 px-2 "}>
              <p>Cc</p>
            </button>
          </Col>

          <Col className={"bg-white d-flex py-1"}>
            <p>Subject:</p>
            <p> {mailType === "reply" ? "Re:" : "Fwd:"}</p>
            <input
              className={"w-100 border-0 emailInput hideFocus pl-2"}
              type={"text"}
              value={subject}
              onChange={(event) => {
                setSubject(event.target.value);
              }}
            />
          </Col>
        </Row>
        <div>
          <Row className={"bg-white pb-1"} style={{ height: height, overflow: "scroll" }}>
            <Col xs={12} className={"p-0"}>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="h-100"
                editorClassName="editorSection"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: ["inline", "blockType", "fontSize", "fontFamily", "textAlign"],
                  inline: {
                    options: ["bold", "italic", "underline", "strikethrough", "monospace"],
                    bold: { className: "bordered-option-classname" },
                    italic: { className: "bordered-option-classname" },
                    underline: {
                      className: "bordered-option-classname d-none",
                    },
                    strikethrough: {
                      className: "bordered-option-classname d-none",
                    },
                    code: { className: "bordered-option-classname d-none" },
                  },
                  blockType: {
                    className: "bordered-option-classname",
                  },
                  fontSize: {
                    className: "bordered-option-classname",
                  },
                  fontFamily: {
                    className: "bordered-option-classname",
                  },
                  textAlign: {
                    inDropdown: true,
                  },
                }}
                toolbarCustomButtons={[<DropButton hideIcon={false} onDrop={parseUploadFiles} />]}
              />
            </Col>

            {mailType == "forward" && (
              <Col xs={12} className={"py-1 attachment d-flex flex-wrap"}>
                {(_.isArray(attachments) && !!attachments.length) || (_.isArray(files) && !!files.length) ? (
                  !!attachments.map((attachment: any, i) => (
                    <p className={"pl-2"} key={i}>
                      <GrAttachment className={"mr-1"} />
                      {attachment.filename}
                      <button
                        onClick={() => removeAttachment(attachments, attachment, i, true)}
                        className={"bg-transparent border-0 font-weight-bold"}
                        type={"button"}
                        style={{ color: "rgb(255, 92, 53)" }}
                      >
                        x
                      </button>
                    </p>
                  ))
                ) : (
                  <p className={"pl-2"}>No attachments</p>
                )}
                {!!files.length &&
                  files.map((file, i) => (
                    <p className={"pl-2"} key={i}>
                      <GrAttachment className={"mr-1"} />
                      {file.name}
                      <button
                        onClick={() => removeAttachment(files, file, i, false)}
                        className={"bg-transparent border-0 font-weight-bold"}
                        type={"button"}
                        style={{ color: "rgb(255, 92, 53)" }}
                      >
                        x
                      </button>
                    </p>
                  ))}
              </Col>
            )}
          </Row>

          <Row className={"bg-light py-1"}>
            <Col>
              <button type="submit" className={"border-0 btn-primary px-3 float-right mr-2"}>
                send
              </button>
            </Col>
          </Row>
        </div>
      </form>
    </Container>
  );
};

export default ForwardEmail;
