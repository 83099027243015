import Panel from "components/common/panel/panel";
import { RenderField } from "components/forms/bootstrapFields";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useTranslation } from "react-i18next";
import ContactPicker from "../pickers/reduxFormPickers/contactPicker";
import SubsidiaryLinksRenderer from "./subsidiaryLinksRenderer";

type ManageSubsidiaryLinksType = {
  name: string;
  uniqueKey: string;
  modelData: any;
  modelName: string;
  picker?: any;
  pickerName?: string;
  push: any;
  pickerId: string;
  metadataFieldName?: string;
  fieldId?: string | null;
  form?: any;
  linkableType?: string;
  pickerProps?: any;
};

const selectManagers = (fields: any, contacts: any, index: number) => {
  if (_.isArray(contacts)) {
    let selectedManagers = contacts?.map((contact: any) => {
      return { id: contact?.id, name: contact?.name };
    });

    fields.update(index, {
      ...fields.value[index],
      managers: selectedManagers,
    });
  }
};

const getSectionArrayName = (modelName: string, arrayMap: any, linkableType?: string) => {
  let key = modelName === "Location" ? "location_id" : modelName === "Department" ? "department_id" : null;
  let arrayName = arrayMap.find((obj: any) => (key ? obj.key === key : obj.linkable_type === linkableType))?.arrayName;
  return arrayName;
};

const fieldName = (name: string, pickerId: string) => {
  if (pickerId !== "project_id") {
    return `${name}.${pickerId}`;
  } else {
    return `${name}`;
  }
};

const ManageSubsidiaryLinks = ({
  name,
  uniqueKey,
  modelData,
  modelName,
  picker,
  pickerName,
  push,
  pickerId,
  fieldId,
  metadataFieldName,
  form,
  linkableType,
  pickerProps,
}: ManageSubsidiaryLinksType) => {
  const [isCollapse, setCollapse] = useState<boolean>(false);
  const [subsidiaryLinks, setSubsidiaryLinks] = useState<any>([]);
  const [formData, setFormData] = useState<any>([]);
  const [nextLevel, setNextLevel] = useState<number>(1);
  const [updatedModelData, setUpdatedModelData] = useState<any>();
  const { t } = useTranslation();

  const arrayMap = [
    { key: "location_id", arrayName: "subsidiary_location_links_attributes" },
    { key: "department_id", arrayName: "subsidiary_links_attributes" },
    { linkable_type: "BusinessUnit", arrayName: "subsidiary_business_unit_attributes" },
    { linkable_type: "Project", arrayName: "subsidiary_project_attributes" },
    { linkable_type: "Metadata", arrayName: "subsidiary_metadata_attributes" },
  ];

  const handleCollapse = () => {
    setCollapse(!isCollapse);
  };

  /**
   * Get the array name based on the fields and their values.
   * This function iterates over `arrayMap` to check if the `linkableType` or the `pickerId`
   * matches with any object in `fields.value`. If a match is found, it returns the corresponding `arrayName`. If no match is found, it returns `null`.
   */
  const getArrayName = (fields: any) => {
    let matchedArrayName: any = null; // Initialize with null to handle no match scenario

    if (fields && _.isArray(fields.value) && fields.value.length > 0) {
      arrayMap.forEach(({ key, linkable_type, arrayName }) => {
        if (linkable_type && linkableType === linkable_type) {
          matchedArrayName = arrayName;
        } else {
          const isKeyAvailable = fields.value.some((value: any) => {
            const arrKey = Object.keys(fields.value[0]).find((k) => k === pickerId);
            return arrKey === key;
          });

          if (isKeyAvailable) {
            matchedArrayName = arrayName;
          }
        }
      });
    }
    return matchedArrayName;
  };

  const handleAddToGrid = (fields: any) => {
    let selected = fields?.value;
    if (Array.isArray(selected)) {
      selected = selected.map((item: any) => {
        if (item.metadata_field_id) {
          item.linkable_type = "MetadataField";
          item.metadata_field_type = metadataFieldName?.replace(/\s/g, "").toLowerCase();
          item.linkable_id = item.field?.id;
        }
        return item;
      });
    }
    selected = selected.filter((link: any) => link[pickerId] !== null);

    const arrayName = getArrayName(fields);
    let subsidiary = form.getState().values;
    setSubsidiaryLinks(() => {
      let data = [];
      if (subsidiary[arrayName]?.length > 0) {
        data = [...subsidiary[arrayName], ...selected];
      } else {
        data = [...selected];
      }
      form.change(arrayName, data);
      return data;
    });

    for (let i = fields.length - 1; i >= 0; i--) {
      fields?.remove(i);
    }
    setFormData([]);
    setCollapse(true);
  };

  useEffect(() => {
    if (formData?.length === 0) {
      push(name, { [pickerId]: null, manager_id: null, level: nextLevel });
    }
  }, [formData]);

  const handleAddNew = () => {
    push(name, { [pickerId]: null, manager_id: null, level: 1 });
  };

  useEffect(() => {
    setUpdatedModelData(modelData);
  }, []);

  useEffect(() => {
    if (isCollapse) {
      let subsidiary = form.getState().values;
      let arrayName = getSectionArrayName(modelName, arrayMap, linkableType);
      setSubsidiaryLinks([...(subsidiary[arrayName] || [])]);
    }
  }, [isCollapse]);

  return (
    <Panel
      header={
        <div>
          <i className="icon icon-drilldown m-0 px-mt-5" />
          {linkableType !== "Metadata" && t("title.link")} {modelName?.toLocaleUpperCase()}
          <a className="float-right">
            <i
              className={`icon ${isCollapse ? "icon-arrow-down" : "icon-arrow-up"}`}
              onClick={() => handleCollapse()}
            ></i>
          </a>
        </div>
      }
    >
      <Row>
        <Col>
          <Row md={8}>
            <Col md={3}>
              <label>{modelName}</label>
            </Col>
            <Col md={3}>
              <label>Manager</label>
            </Col>
            <Col md={3}>
              <label>Level</label>
            </Col>
            <Col md={1}></Col>
          </Row>
          <FieldArray name={name} key={uniqueKey}>
            {({ fields }) => {
              return fields.map((name, index) => (
                <Row key={index}>
                  {linkableType !== "Metadata" && (
                    <Col>
                      <Field
                        name={fieldName(name, pickerId)}
                        component={picker}
                        pickerName={pickerName || ""}
                        callBack={(obj: any) => {
                          if (obj) {
                            fields.update(index, {
                              ...fields.value[index],
                              [pickerId]: obj?.id,
                              field: { id: obj?.id, name: obj?.name },
                              label: obj?.name,
                            });
                          }
                        }}
                        menuPosition="fixed"
                      />
                    </Col>
                  )}

                  {linkableType === "Metadata" && (
                    <Col>
                      <Field
                        name={`${name}.linkable_id`}
                        component={picker}
                        pickerName={pickerName || ""}
                        metadataConfiguration={{ ...pickerProps }}
                        callBack={(obj: any) => {
                          if (obj) {
                            fields.update(index, {
                              ...fields.value[index],
                              [pickerId]: obj?.label,
                              field: { id: obj?.value, name: obj?.label },
                              label: obj?.label,
                            });
                          }
                        }}
                        menuPosition="fixed"
                      />
                    </Col>
                  )}
                  <Col>
                    <Field
                      name={`${name}.manager_id`}
                      component={ContactPicker}
                      contactType={"USERS"}
                      isMulti={true}
                      needSelectedManagers={true}
                      callBackObj={(contacts: any) => selectManagers(fields, contacts, index)}
                      menuPosition="fixed"
                    />
                  </Col>
                  <Col>
                    <Field name={`${name}.level`} component={RenderField} />
                  </Col>
                  <Col>
                    <a className="btn btn-xs" onClick={() => fields.remove(index)}>
                      <i className="icon icon-delete"></i>
                    </a>
                  </Col>
                </Row>
              ));
            }}
          </FieldArray>
          <Button variant="secondary" className="mr-2" onClick={handleAddNew}>
            <i style={{ height: 12, width: 12 }} className="d-block float-left icon-add-black mr-2 mt-1" /> Add New
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldArray name={name}>
            {({ fields }) => (
              <Button variant="primary" className="mr-2 px-mb-10 pullRight" onClick={() => handleAddToGrid(fields)}>
                <i className="btn-icon icon-add" style={{ backgroundSize: "12px", backgroundPosition: "left" }} /> Add
                To Grid
              </Button>
            )}
          </FieldArray>
        </Col>
      </Row>
      {isCollapse && (
        <Row>
          <Col>
            <SubsidiaryLinksRenderer
              links={subsidiaryLinks}
              title={modelName}
              gridStorageName={`link_${modelName?.toLocaleLowerCase()}`}
              isCollapse={isCollapse}
              form={form}
              fieldId={fieldId}
              linkableType={linkableType}
            />
          </Col>
        </Row>
      )}
    </Panel>
  );
};

export default ManageSubsidiaryLinks;
