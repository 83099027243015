import React from "react";
import { Col, Row } from "react-bootstrap";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import CcPaymentIntegrationFields from "../../components/integrationComponents/ccPaymentIntegrationsFields";

const IntegrationServiceCcPayment = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <CcPaymentIntegrationFields />
      </Row>
    </Col>
  );
};

export { IntegrationServiceCcPayment };
