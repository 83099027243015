import i18next from "i18next";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import RestApi from "../../../../providers/restApi";
import {
  onApplicationTypeChange,
  onSystemNameChange,
  setIntegrationForm,
  setIsIntegrationModalOpen,
  closeIntegrationModal,
  setCompany,
} from "../../../../reducers/admin/integrationsReducer";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { createCompleteError } from "../../../../services/general/reduxFormSvc";
import {
  checkSFTPValidation,
  fetchIntegrationDetail,
} from "../../../../services/integrationCommonSvc/integrationCommonSvc";
import IntegrationModalForm from "./integrationModalForm";
import IntegrationApis from "services/admin/integration/integrationApis";

const restApiService = new RestApi();

const EditIntegration = ({ integrationId, onEditCallBack }) => {
  const dispatch = useDispatch();
  const isIntegrationModalOpen = useSelector((state) => state.integrationsRed.isIntegrationModalOpen);
  const company = useSelector((state) => state.integrationsRed.company);

  const checkCredintialDetails = useCallback((integrationDetail) => {
    if (integrationDetail.system_name) {
      dispatch(onSystemNameChange(integrationDetail.system_name));
      dispatch(onApplicationTypeChange(integrationDetail.system_name, integrationDetail.application_type));
    }
  }, []);

  const setEditIntegrationData = useCallback(async () => {
    const integrationDetail = await fetchIntegrationDetail(integrationId);
    if (integrationDetail) {
      dispatch(setIntegrationForm({ form: integrationDetail, company: company }));
      checkCredintialDetails(integrationDetail);
      dispatch(setIsIntegrationModalOpen(true));
    }
  }, []);

  useEffect(() => {
    setEditIntegrationData();
  }, []);

  //  using only for submit call back
  const closeModal = () => {
    dispatch(closeIntegrationModal());
    onEditCallBack();
  };

  const submit = async (integrationFormData) => {
    try {
      if (integrationFormData.form && integrationFormData.form.id) {
        const requestData = {
          action_refresh_metadata: integrationFormData.form.action_refresh_metadata,
          payoneer_programs_attributes: integrationFormData.company && integrationFormData.company.payoneer_programs,
        };

        const response = await restApiService.patch("integration_settings/" + integrationFormData.form.id, null, {
          integration_setting: { ...integrationFormData.form },
        });

        // some field that need to store at company level
        if (
          integrationFormData.form.application_type === "PAYONEER" ||
          integrationFormData.form.action_refresh_metadata
        ) {
          const companyResponse = await restApiService.patch("company", null, {
            company: requestData,
          });
          dispatch(setCompany(companyResponse.data));
        }

        if (response) {
          CreateNotification("Saved", "Credentials Saved ", "success");
          try {
            if (
              integrationFormData.jobsForm &&
              _.isArray(integrationFormData.jobsForm.jobs) &&
              integrationFormData.jobsForm.jobs.length > 0
            ) {
              const response = await IntegrationApis.editIntegrationJobs(integrationFormData.form.id, {
                ...integrationFormData.jobsForm,
                profile_id: integrationFormData.form.application_id,
              });
              if (response) {
                CreateNotification("Saved", "Jobs Saved Successfully", "success");
              }
            }
          } catch (error) {
            console.log(error);
          }

          if (response.data) {
            if (
              response.data &&
              integrationFormData.formTempState &&
              integrationFormData.formTempState.check_to_test_ftp
            ) {
              try {
                const sftpValidationRes = await checkSFTPValidation(response.data, dispatch);
                // successfull validation of sftp
                closeModal();
              } catch (error) {
                // don't close modal let user see validations
                CreateNotification(i18next.t("error"), error.message, NotificationType.danger);
              }
            } else {
              closeModal();
            }
          }
        }
      }
    } catch (error) {
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          throw new SubmissionError({
            form: completeErrorObj,
          });
        }
      }
    }
  };

  return (
    <>
      {isIntegrationModalOpen && (
        <Modal size="lg" show={isIntegrationModalOpen} onHide={() => dispatch(closeIntegrationModal())}>
          <IntegrationModalForm onSubmit={submit} onHide={() => dispatch(closeIntegrationModal())} />
        </Modal>
      )}
    </>
  );
};

export default EditIntegration;
