import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { DefaultRule } from "pages/admin/administration/defaultRules/defaultRules";

class DefaultRulesApi {
  static async index() {
    const response: AxiosResponse<DefaultRule[]> = await restApiService.get("default_rules");
    return response.data;
  }

  static async upsert(defaultRule: DefaultRule) {
    if (defaultRule.id && defaultRule.id > 0) {
      const response: AxiosResponse<DefaultRule> = await restApiService.patch(
        `default_rules/${defaultRule.id}`,
        null,
        defaultRule,
      );
      return response.data;
    } else {
      const response: AxiosResponse<DefaultRule> = await restApiService.post("default_rules", null, defaultRule);
      return response.data;
    }
  }

  static async delete(defaultRule: DefaultRule) {
    const response: AxiosResponse<DefaultRule> = await restApiService.delete(`default_rules/${defaultRule.id}`);
    return response.data;
  }
}

export default DefaultRulesApi;
