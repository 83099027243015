import { AxiosResponse } from "axios";
import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, RegisterOptions, useFormContext, useWatch } from "react-hook-form";
import Select from "react-select";
import { useTypedSelector } from "reducers";
import { IUser } from "services/common/user/userTypes";

type TTaxCodePicker = {
  id?: number | string | null;
  name: string;
  label?: string;
  rules?: RegisterOptions;
  required?: boolean;
  modelData?: string;
  containerClassName?: string;
  callBack?: (selected: TTaxCodeProps) => void;
};

export type TTaxCodeProps = {
  id?: number | string | null;
  name?: string;
  code?: string;
  rate?: number;
  credit_account_id?: number | null;
  external_id?: string;
  tax_code?: string;
  tax_rates?: {
    id?: number | string | null;
    code?: string;
    group_link_rate?: number;
    rate?: number;
    name?: string;
  }[];
};

const TaxCodePicker = ({ id, name, label, required, modelData, callBack, containerClassName }: TTaxCodePicker) => {
  const [options, setOptions] = useState<TTaxCodeProps[]>([]);
  const isMounted = useIsMounted();
  const { control, getValues, setValue } = useFormContext();
  const [inactivePopulated, setInactivePopulated] = useState(false);
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const [taxId] = useWatch({
    control,
    name: [modelData + "tax_id"],
  });

  const sortedOptions = useMemo(() => {
    if (!options || options.length === 0) return [];
    return _.sortBy(options, ["code"]);
  }, [options]);

  const isInactive = (status: string) => {
    return status === "INACTIVE";
  };

  const getInactiveTaxes = async () => {
    try {
      const response: AxiosResponse = await restApiService.get("taxes/" + taxId);
      if (_.isPlainObject(response?.data)) {
        const inactiveObj = response?.data;
        if (inactiveObj) {
          if (isMounted.current) {
            //TODO: remove, not in use
            const obj = {
              ...inactiveObj,
              label: (
                <span key={inactiveObj.id}>
                  {inactiveObj.name}
                  {isInactive(inactiveObj.status) && (
                    <span key={inactiveObj.id + "-inactive"} className="inactiveOption">
                      (INACTIVE)
                    </span>
                  )}
                </span>
              ),
              value: inactiveObj.id,
            };
            setInactivePopulated(true);
            setValue(name, inactiveObj.id);
          }
        }
      } else {
        setValue(name, null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mergeInactive = async (options: TTaxCodeProps[]) => {
    const matchingOption = options?.find((option) => option.id === taxId);

    if (matchingOption) {
      setValue(name, matchingOption.id);
      setValue(`${modelData}.tax_code`, matchingOption);
    } else if (!inactivePopulated && taxId) {
      await getInactiveTaxes();
    }
  };

  const resetTax = (params: Record<string, string>, options: TTaxCodeProps[]) => {
    let modelDataObj = getValues(modelData as string);
    if (_.isPlainObject(modelDataObj) && (params.location_id || params.subsidiary_id)) {
      let tax = options.find((option) => option.id === modelDataObj.tax_id);

      //TODO: Check this logic is working correct or not.
      if (!tax || Object.keys(tax).length === 0) {
        modelDataObj.tax_code = "";
        modelDataObj.tax_id = "";
        modelDataObj.tax = "";
        setValue(modelData as string, modelDataObj);
      }
    }
  };

  const getTaxes = useCallback(async () => {
    const params: Record<string, string> = { ACTIVE: "YES" };
    const subsidiaryId = getValues("subsidiary_id");
    const subsidiary = getValues("subsidiary");

    if (currentUser.company.show_subsidiary_mapped_taxes && subsidiaryId > 0) {
      params.subsidiary_id = subsidiaryId;
      if (subsidiary.country) {
        params.country_code = subsidiary.country;
      }
    }

    try {
      const result: AxiosResponse = await restApiService.get(`taxes.lk`, params, null, true, null, true);
      if (Array.isArray(result.data)) {
        const options = [...result.data];
        resetTax(params, options);
        mergeInactive(options);
        if (isMounted.current) {
          setOptions(options);
        }
      }
    } catch (error) {
      console.error("Error fetching tax codes:", error);
    }
  }, [isMounted]);

  useEffect(() => {
    getTaxes();
  }, [getTaxes]);

  return (
    <Form.Group className={` ${containerClassName ?? ""}`}>
      {label && (
        <label className="pickerLabel">
          {label} <Mandatory required={required} />
        </label>
      )}

      <Controller
        control={control}
        name={name}
        rules={{ required: required ? "This field is required" : false }}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <>
            <Select
              {...field}
              isClearable={!required}
              value={sortedOptions.find((option) => option.id === value) || null}
              getOptionLabel={(option) => option.code || ""}
              formatOptionLabel={(option: TTaxCodeProps, context: any) =>
                context.context == "menu" ? (
                  <>
                    {option.code
                      ? `${option.id !== "" ? "Code:" : ""} ${option.code} ${option.rate ? `Rate: ${option.rate}% ` : ""}`
                      : ""}
                  </>
                ) : (
                  <span>
                    {option.code} {option.rate ? `(${option.rate}%)` : ""}
                  </span>
                )
              }
              getOptionValue={(option) => option.id?.toString() || ""}
              options={sortedOptions}
              required={required}
              className="pickerSelectedOption"
              menuPosition="fixed"
              onChange={(selected) => {
                onChange(selected?.id || null);
                if (modelData) {
                  setValue(`${modelData}.tax_code`, selected);
                }
                if (callBack) {
                  // Ensure callBack is only called with a valid object, not null
                  callBack(selected || ({} as TTaxCodeProps));
                }
              }}
            />
            {fieldState.error && fieldState.error.message && <PickerErrorBlock error={fieldState.error.message} />}
          </>
        )}
      />
    </Form.Group>
  );
};

export default TaxCodePicker;
