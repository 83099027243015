import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.css";
import Panel from "components/common/panel/panel";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { useSelector } from "react-redux";
import { getFormValues } from "redux-form";
import { useTranslation } from "react-i18next";

type formType = { subsidiary_id?: number; expense_type?: { value: string; label: string }; subsidiaryName?: "string" };

type filterType = { subsidiary_ids?: number; reimbursable?: string };

interface ExpenseReport {
  title: string;
  count: number | string;
  status: string | null;
}
const ExpenseReportSummary = () => {
  const { t } = useTranslation();
  const [summaryStatus, setSummaryStatus] = useState<ExpenseReport[] | []>([]);
  const expenseDashboardForm: formType = useSelector((state) => getFormValues("expenseDashboardForm")(state));
  const subsidiaryId = expenseDashboardForm?.subsidiary_id;
  const expenseType = expenseDashboardForm?.expense_type;
  const subsidiaryName = expenseDashboardForm?.subsidiaryName;

  const getList = async (
    subsidiaryId: number | undefined = undefined,
    expenseType: { value: string } | undefined = undefined,
  ) => {
    try {
      let filters: filterType = {};
      if (subsidiaryId) {
        filters.subsidiary_ids = subsidiaryId;
      }
      if (expenseType?.value && expenseType.value !== "all") {
        filters.reimbursable = expenseType.value;
      }
      const result = await ExpensesApis.getExpensesReportSummary({ filter: filters });

      if (result) {
        const data: ExpenseReport[] = [
          { title: "New", count: result?.new_expense_reports || 0, status: "new" },
          { title: "Pending Approval", count: result?.pending_expense_reports || 0, status: "pending" },
          { title: "Approved", count: result?.open_expense_reports || 0, status: "open" },
          { title: "Paid", count: result?.paid_expense_reports || 0, status: "paid" },
          { title: "Closed", count: result?.closed_expense_reports || 0, status: "closed" },
        ];
        setSummaryStatus(data);
      }
    } catch (error) {
      console.error("Error fetching expenses report summary:", error);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  useEffect(() => {
    if (subsidiaryId || expenseType) {
      getList(subsidiaryId, expenseType);
    }
  }, [subsidiaryId, expenseType]);

  return (
    <div>
      <Panel
        cardClass={styles.panelWrapper}
        cardBodyClass={styles.reportSummaryBody}
        cardFooterClass={styles.panelFooter}
        header={
          <div>
            <i className={`icon ${styles.iconHeading} icon-expenses-approval-blue-panel px-mt-3 px-mr-3`}></i>
            {t("admin.pages.expenseItem.labels.expenseReportsSummary")}
          </div>
        }
        cardHeaderClass={styles.expensePanelHeader}
      >
        <>
          <Row className={`${styles.actionTitle}`}>
            {summaryStatus.map((data, index) => {
              const title = data.title.toUpperCase();
              return (
                <Col key={index} md={4} className={`row ${styles.statusPanels}`}>
                  <Link
                    className="expenseRouteLink"
                    to={{
                      pathname: `/ap/expense_reports`,
                      search: `?${subsidiaryName ? "subsidiaryName=" + subsidiaryName : ""}${expenseType?.value ? "&expenseType=" + expenseType?.value : ""}${data?.status ? "&status=" + data.status : ""}`,
                    }}
                  >
                    <Panel cardClass={styles.statusWrapper} cardBodyClass={styles.reportSummaryBody}>
                      <div
                        className={`${styles.statusContainer} ${data.title === "Pending Approval" ? styles.summaryPendingApproval : ""}`}
                      >
                        <p className={`${styles.selectCreditLineLabel} ${styles.erStatus}`}>{title}</p>
                        <span
                          className={`mb-0 ml-auto mr-auto badge  text-light actionableItemsbadgePill ${styles.badgeBlue} ${styles.reportCountBadge} print-badge`}
                        >
                          {data.count}
                        </span>
                      </div>
                    </Panel>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </>
      </Panel>
    </div>
  );
};

export default ExpenseReportSummary;
