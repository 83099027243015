import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import companySettingStyle from "../../companySettingTab.module.css";
import CommonSettingField from "../../components/commonSettingField";
import { AutomatedExpensesSettings, ExpenseReportSettings, ExpenseSettings } from "./expenseSettings";

const ExpenseSetting = () => {
  const formName = "companyDefaultForm";
  const dispatch = useDispatch();

  const company_default = useSelector((state) => state.form.companyDefaultForm.values.company_default);
  const company = useSelector((state) => state.form.companyDefaultForm.values.company);
  const current_user = useSelector((state) => state.user);
  const isShowObj = useMemo(
    () => ({
      company_default: company_default,
      company: company,
      current_user: current_user,
    }),
    [company, company_default, current_user],
  );

  const onChangeObj = useMemo(
    () => ({
      formName: formName,
      dispatch: dispatch,
      company_default: company_default,
      company: company,
    }),
    [company, company_default, dispatch, formName],
  );

  const customPropsObj = useMemo(
    () => ({
      policy_id: company_default?.expense_item?.purchase_expense_item_default_policy_id,
    }),
    [company_default],
  );

  return (
    <Container fluid={true} className="mt-3">
      <div className={`${companySettingStyle.settingsTitle}`}>Expense Items</div>
      <hr className="mt-0" />
      <Row>
        <Col md="6">
          {/* coloum no. 1 */}
          {ExpenseSettings.filter((setting) => setting.col === 1).map((setting, index) => (
            <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
          ))}
        </Col>
        <Col md="6">
          {/* coloum no. 2 */}
          {ExpenseSettings.filter((setting) => setting.col === 2).map((setting, index) => (
            <CommonSettingField
              key={index}
              setting={setting}
              isShowObj={isShowObj}
              onChangeObj={onChangeObj}
              customPropsObj={customPropsObj}
            />
          ))}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={`${companySettingStyle.settingsTitle}`}>Automated Expenses</div>
          <hr className="mt-0" />
          <Row>
            <Col md="6">
              {/* coloum no. 1 */}
              {AutomatedExpensesSettings.filter((setting) => setting.col === 1).map((setting, index) => (
                <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
              ))}
            </Col>
            <Col md="6">
              {/* coloum no. 2 */}
              {AutomatedExpensesSettings.filter((setting) => setting.col === 2).map((setting, index) => (
                <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={`${companySettingStyle.settingsTitle}`}>Expense Report</div>
          <hr className="mt-0" />
          <Row>
            <Col md="6">
              {/* coloum no. 1 */}
              {ExpenseReportSettings.filter((setting) => setting.col === 1).map((setting, index) => (
                <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
              ))}
            </Col>
            <Col md="6">
              {/* coloum no. 2 */}
              {ExpenseReportSettings.filter((setting) => setting.col === 2).map((setting, index) => (
                <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ExpenseSetting;
