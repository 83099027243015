import { Col, Row } from "react-bootstrap";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import IntegrationEdi from "../../components/integrationComponents/integrationEdi";
import IntegrationSftp from "../../components/integrationComponents/integrationSftp";
import AddendaRecords from "../../components/integrationComponents/addendaRecords";

const IntegrationServiceNachaPayment = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <IntegrationSftp sftpSublabel={"upload nacha file to bank"} />
      </Row>
      <Row className="m-0 mb-3">
        <AddendaRecords />
      </Row>
      <Row className="m-0">
        <IntegrationEdi />
      </Row>
    </Col>
  );
};

export { IntegrationServiceNachaPayment };
