import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Field, getFormValues } from "redux-form";
import { RenderCheckBox } from "../../../../../../components/forms/bootstrapFields";
import { INTEGRATION_FORM_NAME } from "../../../../../../reducers/admin/integrationsReducer";
import { showPullPushSetting } from "../../../../../../services/integrationCommonSvc/integrationCommonSvc";
import IntegrationJobs from "./integrationJobs";

const nonTransactionSettingsPush = [
  {
    name: "form.sync_product_item_push",
    label: "Product Item",
    system_names: ["Netsuite", "Quickbook", "Accrualify Integration", "Sage300C"],
    application_types: ["AI", "AI2"],
  },
];

const NonTransactionPushSettings = () => {
  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));

  return (
    <Col lg="12">
      {nonTransactionSettingsPush.length > 0 && (
        <Row className="m-0">
          <Col lg="12" className="p-0 d-flex">
            <Form.Label className="mr-2">AI Push to ERP Non-transactional Settings:</Form.Label>
          </Col>
        </Row>
      )}

      <Row className="m-0">
        {nonTransactionSettingsPush.map((setting, index) => {
          if (
            showPullPushSetting(
              form.system_name,
              form.application_type,
              setting.system_names,
              setting.application_types,
            )
          ) {
            return (
              <Col md="4" key={index}>
                <Field name={setting.name} component={RenderCheckBox} label={setting.label} type="checkbox" />
              </Col>
            );
          } else {
            return null;
          }
        })}
      </Row>
    </Col>
  );
};

export default NonTransactionPushSettings;
