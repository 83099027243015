import { useCallback, useEffect } from "react";
import { Alert, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Field, getFormValues } from "redux-form";
import { RenderCheckBox, RenderField } from "../../../../../../components/forms/bootstrapFields";
import {
  INTEGRATION_FORM_NAME,
  setSftpValidationLoading,
  setSftpValidationResponse,
} from "../../../../../../reducers/admin/integrationsReducer";

const IntegrationSftp = ({ sftpSublabel }) => {
  const { t } = useTranslation();
  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const sftpValidationTestLoading = useSelector((state) => state.integrationsRed.sftpValidationTestLoading);
  const sftpValidationTestResponse = useSelector((state) => state.integrationsRed.sftpValidationTestResponse);
  const dispatch = useDispatch();

  const resetSftpValidations = useCallback(() => {
    dispatch(setSftpValidationLoading(false));
    dispatch(setSftpValidationResponse({ status: "", message: "" }));
  }, [dispatch]);

  useEffect(() => {
    // for clean up required here on unmout as we need cleared validation
    return () => {
      resetSftpValidations();
    };
  }, [resetSftpValidations]);

  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <Col lg="12">
          <label>SFTP Credential Info </label>
          {sftpSublabel && <small>({sftpSublabel})</small>}
        </Col>
      </Row>
      <Row className="m-0">
        <Col lg="6">
          <Field
            name="form.sftp_remote_server"
            component={RenderField}
            label="SFTP Remote Server"
            placeholder="SFTP Remote Server"
            type={"text"}
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.sftp_port"
            component={RenderField}
            label="Port (default: 22)"
            placeholder="SFTP Port"
            type={"text"}
            size="5"
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.sftp_filepath"
            component={RenderField}
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    "SFTP Filepath w/filename <small> (/incoming/, /incoming/file.dat, file.dat, file_%Y%m%d%H%M%S%L.dat if date formatting => file_20210115172527568.dat )</small>",
                }}
              />
            }
            placeholder="SFTP filepath"
            type={"text"}
          />
        </Col>

        <Col lg="6">
          <Field
            component={RenderField}
            name="form.sftp_user"
            label="SFTP User"
            placeholder="SFTP User"
            type={"text"}
          />
        </Col>

        <Col lg="6">
          <Field
            component={RenderField}
            name="form.sftp_password"
            label="SFTP Password"
            type="password"
            placeholder="SFTP Password"
            autoComplete="true"
          />
        </Col>

        <Col lg="12">
          <Row className="m-0">
            <Field
              component={RenderCheckBox}
              name="form.proxy_on"
              placeholder="Turn on Proxy"
              label="Use Proxy (for Static IP) &nbsp;"
              id="proxy_on"
              type="checkbox"
            />
            <Field
              component={RenderCheckBox}
              name="form.private_key_on"
              placeholder="Use SSL Private Key"
              label="Use AI Private Key &nbsp;"
              id="private_key_on"
              type="checkbox"
            />
            <Field
              component={RenderCheckBox}
              name="form.gpg_signed_on"
              placeholder="Sign/Encrypt File"
              label="Sign/Encrypt File&nbsp;"
              id="gpg_signed_on"
              type="checkbox"
            />
            <Field
              component={RenderCheckBox}
              name="formTempState.check_to_test_ftp"
              id="formTempState.check_to_test"
              label={t("settings.integrations.ftp.checkFtp")}
              type="checkbox"
            />
            <Field
              component={RenderCheckBox}
              name="form.is_document_ftp"
              placeholder="Document FTP"
              label="Document FTP"
              id="is_document_ftp"
              type="checkbox"
            />
          </Row>
        </Col>

        <Col lg="6" className="form-group col-lg-6">
          {form.application_type === "NACHA_PAYMENT" && (
            <Field
              component={RenderCheckBox}
              name="form.balanced_file"
              placeholder="Balanced File"
              id="balanced_file"
              label="Balanced File &nbsp;"
              type="checkbox"
            />
          )}
        </Col>

        <Col lg="12" className="form-group col-lg-6">
          {sftpValidationTestLoading && (
            <Spinner animation="border" variant="info" id="refresh_cache_spin" className="ml-1 mt-1" />
          )}
          {sftpValidationTestResponse.message && (
            <Alert variant={sftpValidationTestResponse.status === "success" ? "success" : "danger"}>
              {sftpValidationTestResponse.message}
            </Alert>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default IntegrationSftp;
