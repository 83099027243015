import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Select from "react-select";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../../services/general/helpers";
import _ from "lodash";
import { IDType } from "../../../../../services/common/types/common.type";

type SubsidiaryOptionsType = {
  id?: IDType;
  name?: string;
};

type SelectedTypes = {
  value: IDType;
  label: string;
};

type InputType = {
  value: number;
  onChange: (values: number) => void;
  onBlur: (values: number) => void;
};

type ForSubsidiaryPickerPropsType = {
  className?: string | undefined;
  label?: string | undefined;
  isMulti?: boolean;
  tooltip?: string | undefined;
  required?: boolean;
  placeholder?: string | undefined;
  input: InputType;
  callBack?: (selected: SelectedTypes) => void;
  instanceId?: string;
};

const ForSubsidiaryPicker = ({
  label,
  input,
  required,
  isMulti,
  tooltip,
  callBack,
  instanceId = "for-subsidiary-picker",
}: ForSubsidiaryPickerPropsType) => {
  const [forSubsidiaries, setForSubsidiaries] = useState([]);

  const getForSubsidiaries = useCallback(async () => {
    const result: AxiosResponse<SubsidiaryOptionsType[]> = await restApiService.get(
      "for_subsidiaries",
      { active: true },
      null,
      true,
      null,
      true,
    );
    if (result && result.data) {
      let list = parseForSelect(result.data);
      if (!required) {
        list = [
          {
            label: "-- Select Subsidiary --",
            value: "",
            status: undefined,
          },
          ...list,
        ];
      }
      setForSubsidiaries(list);
    }
  }, []);

  const onChangeForSubsidiaries = (input: any, selected: any) => {
    onChange(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  const onChangeForSubsidiary = (input: InputType, selected: SelectedTypes) => {
    onChangeSingle(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  useEffect(() => {
    getForSubsidiaries();
  }, []);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label}
            <Mandatory required={required} />
            {tooltip}
          </Form.Label>
        )}
        <Select
          {...input}
          isMulti={isMulti ? true : false}
          required={required}
          options={forSubsidiaries}
          isClearable={!required}
          value={isMulti ? findSelectedMultiple(input, forSubsidiaries) : findSelectedSingle(input, forSubsidiaries)}
          onChange={
            isMulti
              ? (selected: SelectedTypes[]) => onChangeForSubsidiaries(input, selected)
              : (selected: SelectedTypes) => onChangeForSubsidiary(input, selected)
          }
          onBlur={isMulti ? () => onBlur(input, input.value) : () => onBlurSingle(input, input.value)}
          instanceId={instanceId}
        ></Select>
      </Form.Group>
    </>
  );
};
export default ForSubsidiaryPicker;
