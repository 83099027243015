import { SubsidiaryListType } from "../../../services/admin/subsidiaries/types";
import { translate } from "../../../services/general/translation";

type DeleteSubsidiaryFunction = (subsidiary: SubsidiaryListType) => void;
const subsidiariesGridHeader = (deleteSubsidiary: DeleteSubsidiaryFunction) => [
  {
    field: "id",
    headerName: "ID",
    filter: "agTextColumnFilter",
    hide: true,
    headerValueGetter: function () {
      return translate("id");
    },
  },
  {
    field: "external_id",
    headerName: "External ID",
    filter: "agTextColumnFilter",
    hide: true,
    headerValueGetter: function () {
      return translate("externalId");
    },
  },
  {
    field: "name",
    headerName: "Name",
    filter: "agTextColumnFilter",
    // Look here
    cellRenderer: "childCellRouting",
    cellRendererParams: {
      viewLink: "/ap/subsidiaries",
      authorization: {
        I: "read",
        a: "Subsidiaries",
      },
    },
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("subsidiary_name");
    },
  },
  {
    field: "sub_type",
    headerName: "Sub Type",
    filter: "agTextColumnFilter",
    hide: true,
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("sub_type");
    },
  },
  {
    field: "country",
    headerName: "Country",
    filter: "agTextColumnFilter",
    hide: true,
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("country");
    },
  },
  {
    field: "currency_code",
    headerName: "Currency Code",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("currency_code");
    },
  },
  {
    field: "exchange_rate",
    headerName: "Exchange Rate",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("exchange_rate");
    },
  },
  {
    field: "manager_list_str",
    headerName: "Managers",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("managers");
    },
  },
  {
    field: "department_str",
    headerName: "Departments",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("department");
    },
  },
  {
    field: "location_list_str",
    headerName: "Location",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("location");
    },
  },
  {
    field: "location_manager_list_str",
    headerName: "Location Managers",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("location_managers");
    },
  },
  {
    field: "status",
    headerName: "Status",
    filter: "agTextColumnFilter",
    filterParams: {
      suppressAndOrCondition: true,
    },
    headerValueGetter: function () {
      return translate("status");
    },
  },
  {
    field: "Actions",
    cellRenderer: "childMessageRenderer",
    sortable: false,
    resizable: false,
    maxWidth: 200,
    cellRendererParams: {
      viewLink: "/ap/subsidiaries/",
      viewAuthorization: { I: "read", a: "Subsidiaries" },
      editLink: "/ap/subsidiaries/",
      editAuthorization: { I: "edit", a: "Subsidiaries" },
      deleteCallback: deleteSubsidiary,
      deleteAuthorization: { I: "delete", a: "Subsidiaries" },
    },
    cellRendererSelector: function (params: { data: { id: number } }) {
      return params.data && !params.data.id;
    },
    headerValueGetter: function () {
      return translate("actions");
    },
  },
];

export default subsidiariesGridHeader;
