import { AxiosResponse } from "axios";
import { getListOptionsType } from "services/common/types/common.type";
import { restApiService } from "providers/restApi";
import { StatementAccountType, StatementBalanceSummaryType } from "./cardPayments.types";

export class CardPaymentsApis {
  static STATEMENTS_ENDPOINT = "bank_card_statements";
  static PAYMENTS_ENDPOINT = "payments";
  static CARD_PROGRAMS_ENDPOINT = "card_programs";

  static async list({ filter, cache }: getListOptionsType = {}): Promise<{ data: any; meta: any }> {
    try {
      const response: AxiosResponse = await restApiService.get(
        `${this.PAYMENTS_ENDPOINT}`,
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async detail(id: number): Promise<any> {
    try {
      const response: AxiosResponse = await restApiService.get(
        `${this.PAYMENTS_ENDPOINT}/${id}`,
        null,
        null,
        true,
        null,
        false,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async addFunds(id: number, payload: any): Promise<any> {
    try {
      const response: AxiosResponse = await restApiService.post(
        `${this.CARD_PROGRAMS_ENDPOINT}/${id}/add_funds`,
        null,
        payload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async statementAccounts({ filter, cache }: getListOptionsType = {}): Promise<{
    statement_accounts: StatementAccountType[];
  }> {
    try {
      const response: AxiosResponse = await restApiService.get(
        `${this.STATEMENTS_ENDPOINT}/statement_accounts`,
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async balanceSummary(id: number): Promise<StatementBalanceSummaryType> {
    try {
      const response: AxiosResponse = await restApiService.get(
        `${this.STATEMENTS_ENDPOINT}/${id}/balance_summary`,
        null,
        null,
        true,
        null,
        false,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
