import ProductItemPicker from "components/admin/pickers/reactHookFormPickers/productItemPicker";
import { ProductItemPickerType } from "components/admin/pickers/reactHookFormPickers/productItemPicker/productItemPickerTypes";
import ProjectPicker from "components/admin/pickers/reactHookFormPickers/projectPicker";
import Panel from "components/common/panel/panel";
import { InputField } from "components/forms/hookFormFields";
import _ from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import adminCommonSvc from "services/admin/commonSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import PoCommonSvc from "services/admin/purchaseOrders/poCommonSvc";
import { PurchaseOrderType } from "services/admin/purchaseOrders/purchaseOrderType";
import { IUser } from "services/common/user/userTypes";
import styles from "../../purchaseOrders.module.css";

type TPOLineItem = {
  index: number;
};

const POLineItem = ({ index }: TPOLineItem) => {
  const { getValues, setValue } = useFormContext();
  const currentUser: IUser = useTypedSelector(selectCurrentUser);
  const item = useWatch({ name: `purchase_order.po_items.${index}` });
  const poCommonSvc = PoCommonSvc.getInstance(currentUser);

  // use to pass condition for project exists
  const isProjectExist = useMemo(() => {
    return currentUser.company.has_projects && !currentUser.company.po_request_hide_item_line_project;
  }, [currentUser.company.has_projects, currentUser.company.po_request_hide_item_line_project]);

  const poItemIsProjectHide = useWatch({ name: `purchase_order.po_items.${index}.is_hide_project` });
  const poItemIsProjectRequired = useWatch({ name: `purchase_order.po_items.${index}.is_project_required` });

  const isProjectRequired = useMemo(() => {
    return poItemIsProjectRequired || currentUser?.company.purchase_order?.items?.project?.is_required;
  }, [currentUser?.company.purchase_order?.items?.project?.is_required, poItemIsProjectRequired]);

  const defaultInit = useCallback(
    (poItemObj: PurchaseOrderType.PoItemType) => {
      const purchaseOrder = getValues("purchase_order");
      poItemObj.country = purchaseOrder?.vendor?.country
        ? purchaseOrder.vendor.country
        : currentUser.company.address
          ? currentUser.company.address.country
          : "";
      poItemObj.currency_code = purchaseOrder.currency_code;
    },
    [currentUser.company.address, getValues],
  );

  const calculateAmount = useCallback(
    (poItem: PurchaseOrderType.PoItemType) => {
      poItem.unit_price = poItem.unit_price ? poItem.unit_price : 0;
      let amt = poItem.qty && poItem.qty > 0 ? poItem.unit_price * poItem.qty : poItem.unit_price;
      return adminCommonSvc.roundUpAmount(Number(amt), null, currentUser);
    },
    [currentUser],
  );

  const allowToCreateNewItem = useCallback(
    (poItemObj: any) => {
      if (poItemObj.product_item_id === -1) {
        defaultInit(poItemObj);
        poItemObj.is_request_new_item = true;
      }
    },
    [defaultInit],
  );

  const setItemPrice = useCallback(
    (poItemObj) => {
      allowToCreateNewItem(poItemObj);
      if (poItemObj.is_request_new_item) {
        poItemObj.item_type = "Non-Inventory";
      }
      if (poItemObj.product_item_id !== -1 && poItemObj?.product_item?.id) {
        poItemObj.unit_price = poItemObj.product_item.price;
        poItemObj.memo = poItemObj.product_item.description;
        poItemObj.qty = 1;
        poItemObj.product_item_id = poItemObj.product_item.id;
        poItemObj.description = "";
        poItemObj.amount = calculateAmount(poItemObj); // TODO: add amount calculation

        if (!poItemObj.product_item.taxable) {
          poItemObj.tax_id = null;
          poItemObj.tax_code = undefined;
          poItemObj.tax = null;
        }

        if (poItemObj.amount && poItemObj.tax_code) {
          poItemObj.tax = adminCommonSvc.roundUpAmount(
            Number((poItemObj.amount / 100) * poItemObj.tax_code.rate),
            null,
            currentUser,
          );
        } else {
          poItemObj.tax = null;
        }

        poItemObj.enable_account_dir = true;

        if (
          Array.isArray(poItemObj.product_item.invoice_debit_accounts) &&
          poItemObj.product_item.invoice_debit_accounts["0"]
        ) {
          poItemObj.account_id = poItemObj.product_item.invoice_debit_accounts[0].account_id;
        }
      }
    },
    [allowToCreateNewItem, calculateAmount, currentUser],
  );

  const selectProductItem = useCallback(
    (selected: ProductItemPickerType.TProductItemOption | undefined | null) => {
      const punchoutId = getValues("purchase_order.punchout_id");
      if (punchoutId) {
        setValue(`purchase_order.po_items.${index}.product_item_id`, selected?.id ?? null);
      } else {
        const poItem: PurchaseOrderType.PoItemType = getValues(`purchase_order.po_items.${index}`);
        if (selected) {
          invoiceCommonSvc.setProductItemMapping(selected, poItem, currentUser);
        }
        setItemPrice(poItem);

        const purchaseOrder = getValues("purchase_order");
        poCommonSvc.getVendorPartNum({ item: poItem, modalData: purchaseOrder });
        setValue(`purchase_order.po_items.${index}`, poItem);
      }
    },
    [currentUser, getValues, index, poCommonSvc, setItemPrice, setValue],
  );

  if (_.isPlainObject(item) && item._destroy === 1) return null;
  return (
    <Row key={item._id}>
      <Col>
        <Panel
          header={
            <Row>
              <Col sm={11}>ITEM {index + 1}</Col>
              <Col sm={1} className={`px-pl-0 ${styles.alignRight}`}>
                <Button
                  // onClick={() => copyPOLineItem(index)}
                  className="bg-transparent border-0 m-0 p-0 justify-content-end"
                >
                  <i className={`icon duplicate m-0 ${styles.iconSize}`} />
                </Button>
                <Button
                  // onClick={() => deleteItem(index)}
                  className="bg-transparent border-0 m-0 p-0 px-pl-15"
                >
                  <i className={`icon icon-delete m-0 ${styles.iconSize}`} />
                </Button>
              </Col>
            </Row>
          }
        >
          <>
            <Row>
              <Col>
                <ProductItemPicker
                  label={"Item Name"}
                  name={`purchase_order.po_items.${index}.product_item_id`}
                  modelName="purchase_order_line"
                  modelData={`purchase_order.po_items.${index}`}
                  parentObj={"purchase_order"}
                  callBack={selectProductItem}
                  required={!currentUser?.company?.po_request_hide_po_items}
                  // TODO: mohit add free text item feature
                  // TODO: mohit add funtionality to add external component.
                  // externalOptionComponent={
                  //   <Button style={{ width: "100%" }} onClick={() => console.log("some action")}>
                  //     Add new
                  //   </Button>
                  // }
                />
              </Col>

              <Col>
                <InputField label="Qty" name={`purchase_order.po_items.${index}.qty`} />
              </Col>
              <Col>
                <InputField
                  label="Unit Price"
                  name={`purchase_order.po_items.${index}.unit_price`}
                  disabled={currentUser.company.purchase_order?.items?.disable_unit_price}
                />
              </Col>
              {isProjectExist && !poItemIsProjectHide && (
                <Col>
                  <ProjectPicker
                    name={`purchase_order.po_items.${index}.project_id`}
                    instanceId={`purchase_order.po_items.${index}.project`}
                    label={"Project"}
                    modelData={`purchase_order.po_items.${index}`}
                    required={isProjectRequired}
                    isPoRequest
                  />
                </Col>
              )}

              {currentUser.company.has_projects && currentUser.company.allow_for_project && (
                <Col>
                  <ProjectPicker
                    label={"For Project"}
                    name={`purchase_order.po_items.${index}.for_project_id`}
                    instanceId={`purchase_order.po_items.${index}.for_project`}
                    placeholder="-- For Project --"
                    modelData={`purchase_order.po_items.${index}`}
                    objectPropertyKey={`purchase_order.po_items.${index}.for_project`}
                    isForProject
                  />
                </Col>
              )}
            </Row>
          </>
        </Panel>
      </Col>
    </Row>
  );
};

export default memo(POLineItem);
