export const EftNewZealandPaymentValidation = [
  {
    country_currency: "NZD",
    validations: [
      {
        field: "account_name",
        validate: {
          required: true,
          maxlength: 20,
          pattern: /^[A-Za-z0-9\s]*$/,
        },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "account_number",
        validate: {
          required: true,
          maxlength: 8,
          pattern: /^[A-Za-z0-9\b]+$/,
        },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
      {
        field: "bankCode",
        validate: {
          required: true,
          maxlength: 2,
          pattern: /^[0-9\b]+$/,
        },
        label: "Bank Code",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Code  is required",
      },
      {
        field: "branchCode",
        validate: {
          required: true,
          maxlength: 4,
          pattern: /^[0-9\b]+$/,
        },
        label: "Branch Code",
        validMessage: "Success",
        errorMessage: "Sorry! Branch Code  is required",
      },
      {
        field: "bank_name",
        validate: { required: false },
        label: "Bank Name",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Name is required",
      },
      {
        field: "suffix",
        validate: {
          required: true,
          maxlength: 4,
          pattern: /^[0-9\b]+$/,
        },
        label: "Suffix",
        validMessage: "Success",
        errorMessage: "Sorry! Suffix is required",
      },
    ],
  },
];
