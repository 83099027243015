import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { useQueueManagementContext } from "./queueManagementContext";

type UiStateContextType = {
  checkboxValues: Map<number, boolean>;
  setCheckboxValues: Dispatch<SetStateAction<Map<number, boolean>>>;
  isOperationRunning: boolean;
  setIsOperationRunning: Dispatch<SetStateAction<boolean>>;
  isOpenUploader: boolean;
  setOpenUploader: Dispatch<SetStateAction<boolean>>;
  uploadBtnDisable: boolean;
  setUploadBtnDisable: Dispatch<SetStateAction<boolean>>;
};

const UiStateContext = createContext<UiStateContextType | undefined>(undefined);

export const UiStateProvider: React.FC = ({ children }) => {
  const { selectedQueueInvoice } = useQueueManagementContext();
  const [checkboxValues, setCheckboxValues] = useState<Map<number, boolean>>(new Map());
  const [isOperationRunning, setIsOperationRunning] = useState<boolean>(false);
  const [isOpenUploader, setOpenUploader] = useState<boolean>(false);
  const [uploadBtnDisable, setUploadBtnDisable] = useState<boolean>(false);

  useEffect(() => {
    setCheckboxValues(new Map());
  }, [selectedQueueInvoice]);

  const contextValue = useMemo(
    () => ({
      checkboxValues,
      setCheckboxValues,
      isOperationRunning,
      setIsOperationRunning,
      isOpenUploader,
      setOpenUploader,
      uploadBtnDisable,
      setUploadBtnDisable,
    }),
    [checkboxValues, isOpenUploader, isOperationRunning, uploadBtnDisable],
  );

  return <UiStateContext.Provider value={contextValue}>{children}</UiStateContext.Provider>;
};

export const useUiStateContext = (): UiStateContextType => {
  const context = useContext(UiStateContext);
  if (!context) {
    throw new Error("useUiStateContext must be used within a UiStateProvider");
  }
  return context;
};
