import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import NachaPayments from "../fields/nachaPayments";
import UsBankCompanyId from "../fields/usBankCompanyId";
import AccountType from "../fields/accountType";
import PaymentTypeSelect from "../fields/paymentTypeSelect";
import AccountPayoutCheckBoxes from "../fields/accountPayoutCheckBoxes";
import ContactFirstName from "../fields/contactFirstName";
import AccountHolderName from "../fields/accountHolderName";
import BankName from "../fields/bankName";
import CurrencyCode from "../fields/currencyCode";
import BankAccountNumber from "../fields/bankAccountNumber";
import ContactLastName from "../fields/contactLastName";
import AccountNickName from "../fields/accountNickName";
import BankAddress from "../fields/bankAddress";
import BankRouting from "../fields/bankRouting";
import Memo from "../fields/memo";
import CompanyIdentification from "../fields/companyIdentification";
import AchId from "../fields/achId";
import DebitCreditFields from "../fields/debitCreditFields";
import BankRoutingSample from "../../../common/bankingRoutingSample/bankRoutingSample";
import { length9, noWhiteSpaceOnly, required } from "../../../../services/validations/reduxFormValidation";
import { useDispatch } from "react-redux";
import { change } from "redux-form";
import { COMPANY_PAYMENT_METHOD_FORM_NAME } from "../../../../reducers/admin/companyPaymentMethodFormReducer";
import Status from "../fields/status";
import { useSelector } from "react-redux";

const VirtualCard = () => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const dispatch = useDispatch();
  const current_user = useSelector((state) => state.user);

  const bankAddressCallBack = (bankAdd) => {
    dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "form.bank_address", bankAdd));
  };

  return (
    <>
      <PaymentTypeSelect />
      <AccountType />
      {current_user.is_root ? <Status label={t("companySettings.paymentMethodsSettings.status")} /> : null}
      <AccountPayoutCheckBoxes />

      <Col md={12}>
        <hr />
      </Col>

      <ContactFirstName
        label={t("companySettings.paymentMethodsSettings.contactFirstName")}
        tooltip={<BankRoutingSample />}
      />

      <ContactLastName
        label={t("companySettings.paymentMethodsSettings.contactLastName")}
        tooltip={<BankRoutingSample />}
      />

      <AccountHolderName
        label={t("companySettings.paymentMethodsSettings.accountHolderName")}
        isAccountHolderRequired
        validations={[required, noWhiteSpaceOnly]}
        tooltip={<BankRoutingSample />}
      />
      <AccountNickName label={t("companySettings.paymentMethodsSettings.accountNickName")} />
      <Col md="12">
        <Row>
          <Col>
            <Row>
              <BankName col={"12"} label={t("companySettings.paymentMethodsSettings.bankName")} />
            </Row>
            <Row>
              <CurrencyCode label={t("companySettings.paymentMethodsSettings.currencyCode")} col={"12"} />
            </Row>
          </Col>
          <Col>
            <Row>
              <BankAddress label={t("companySettings.paymentMethodsSettings.bankAddress")} col={"12"} />
            </Row>
          </Col>
        </Row>
      </Col>
      <BankAccountNumber
        label={t("companySettings.paymentMethodsSettings.bankAccountNumber")}
        isBankAccountRequired={true}
        validations={[required, noWhiteSpaceOnly]}
        tooltip={<BankRoutingSample />}
      />
      <BankRouting
        isBankRountingRequired
        label={t("companySettings.paymentMethodsSettings.bankRoutingNumber")}
        validations={[required, length9, noWhiteSpaceOnly]}
        bankAddressCallBack={bankAddressCallBack}
        tooltip={<BankRoutingSample />}
      />
      <DebitCreditFields />

      {!showMore && (
        <Col md="12" className="d-flex justify-content-center align-items-center">
          <span role={"button"} onClick={() => setShowMore(true)} className="primaryLinkColor">
            {t("companySettings.paymentMethodsSettings.showMore")}
          </span>
        </Col>
      )}

      {showMore && (
        <>
          <Col md="12">
            <hr />
          </Col>
          <NachaPayments />
          <Memo label={t("companySettings.paymentMethodsSettings.memo")} />
          <CompanyIdentification label={t("companySettings.paymentMethodsSettings.companyIdentification")} />
          <AchId label={t("companySettings.paymentMethodsSettings.achId")} />
          <UsBankCompanyId label={t("companySettings.paymentMethodsSettings.usBankCompanyId")} />
        </>
      )}

      {showMore && (
        <Col md="12" className="d-flex justify-content-center align-items-center">
          <span role={"button"} onClick={() => setShowMore(false)} className="primaryLinkColor">
            {t("companySettings.paymentMethodsSettings.showLess")}
          </span>
        </Col>
      )}
    </>
  );
};

export default VirtualCard;
