import React, { Component } from "react";
import { Field } from "redux-form";
import { renderField } from "../forms/fields";
import styles from "../../pages/expenseItems/admin/ExpenseItem.module.css";

class SingleExpense extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let expense_item = this.props.ExpenseItem;
    let currency_codes = this.props.state.currency_codes;
    let taxes = this.props.state.taxes;
    let lbl_tax_amount = (
      <label>
        Tax Amount{" "}
        {expense_item.base_currency_code && <span className="subTitle">({expense_item.base_currency_code})</span>}
      </label>
    );
    let lbl_total_amount = (
      <label>
        Total Amount{" "}
        {expense_item.base_currency_code && <span className="subTitle">({expense_item.base_currency_code})</span>}
      </label>
    );
    let lbl_total = (
      <label>
        Total <span className="subTitle">(Including tax)</span>
      </label>
    );
    const { getCurrencyRate } = this.props;

    return (
      <div>
        <div className="row mt-15">
          <Field
            className="col-md-8 prt-5"
            name="vendor_name"
            label="Merchant"
            component={renderField}
            customStyle={{ padding: "5px" }}
            type="text"
          />
          <div className="col-md-4 plt-5">
            <label>Date</label>
            <br />
            <Field className="dateField" name="date" component="input" type="date" />
          </div>
        </div>
        {expense_item.policy && expense_item.policy.convert_to_base_currency && (
          <div className="row mt-15">
            <div className="col-md-3 prt-5 ">
              <label>Currency</label>
              <br />
              <Field
                className={"w-100"}
                name="base_currency_code"
                component="select"
                style={{ padding: "8px" }}
                onChange={(e) => getCurrencyRate(e.target.value)}
              >
                {currency_codes.map((currency) => (
                  <option value={currency.value} key={currency.value}>
                    {currency.label}
                  </option>
                ))}
              </Field>
            </div>

            <div className="col-md-3 plr-5">
              <Field
                name="base_currency_tax"
                label={lbl_tax_amount}
                customStyle={{ padding: "5px" }}
                component={renderField}
                type="number"
              />
            </div>

            <div className={"col-md-3 plr-5"}>
              <Field
                name="base_currency_total"
                label={lbl_total_amount}
                customStyle={{ padding: "5px" }}
                component={renderField}
                type="number"
              />
            </div>

            {expense_item.currency_exchange && expense_item.currency_exchange.rate && (
              <div className={"col-md-3 plt-5 styles.alignVerticallyCenter"}>
                <label className={styles.functionalCurrencyWarning}>
                  Approx. rate {expense_item.currency_exchange.pair} is {expense_item.currency_exchange.rate}
                </label>
              </div>
            )}
          </div>
        )}

        <div className="row mt-15">
          {expense_item.policy && expense_item.policy.taxes_enabled && (
            <div className="col-md-3 prt-5">
              <label>
                Tax Code <span className={styles.subTitle}>(Optional)</span>
              </label>
              <Field className={"w-100"} name="tax_id" component="select" style={{ padding: "8px" }}>
                <option value="">--Select Tax code --</option>
                {taxes.map((tax) => (
                  <option value={tax.id} key={tax.id}>
                    {tax.code} - {tax.rate}
                  </option>
                ))}
              </Field>
            </div>
          )}

          <div className={"col-md-3 " + (expense_item.policy && expense_item.policy.taxes_enabled ? "plr-5" : "prt-5")}>
            <Field
              name="tax"
              label="Tax Amount"
              customStyle={{ padding: "5px" }}
              component={renderField}
              type="number"
              isDisabled={expense_item.policy && expense_item.policy.convert_to_base_currency}
            />
          </div>

          <div className="col-md-3 plr-5">
            <label>Currency</label>
            <br />
            <Field
              className={"w-100"}
              name="currency_code"
              component="select"
              style={{ padding: "8px" }}
              disabled={expense_item.policy && expense_item.policy.convert_to_base_currency}
            >
              {currency_codes.map((currency) => (
                <option value={currency.value} key={currency.value}>
                  {currency.label}
                </option>
              ))}
            </Field>
          </div>

          <div className="col-md-3 plt-5">
            <Field
              name="total"
              label={lbl_total}
              customStyle={{ padding: "5px" }}
              component={renderField}
              type="number"
              isDisabled={expense_item.policy && expense_item.policy.convert_to_base_currency}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default SingleExpense;
