import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { components, MultiValue, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { WrappedFieldProps } from "redux-form";
import { isDefined } from "services/general/helpers";
import { restApiService } from "../../../../../providers/restApi";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Styles from "../override.module.css";
import PickerErrorBlock from "../pickerErrorBlock";

export interface LocationPickerPropsType extends WrappedFieldProps {
  label?: string;
  filter?: any;
  required?: boolean;
  tooltip?: ReactNode;
  placeholder?: string;
  isMulti?: boolean;
  returnObject?: boolean;
  className?: string;
  isClearable?: boolean;
  menuPosition?: any;
  instanceId?: string;
  error?: string;
  callBack?: (selected: SingleValue<void | LocationOptionsType>) => void;
  modelData?: any;
  isForLocation?: boolean;
}

export type LocationOptionsType = {
  id?: number;
  name?: string;
  status?: string;
  value: number;
  label: ReactNode;
  destroy?: 1;
};

const CustomMultiValue = (props: any) => {
  const { data } = props;
  if (data && data._destroy === 1) {
    return null; // Exclude options with _destroy: 1 from being rendered as selected
  }
  return <components.MultiValue {...props} />;
};

const LocationPicker = ({
  label,
  input,
  meta: { touched, error: error2 },
  filter = { LIMIT: 100, status: "ACTIVE" },
  className,
  required,
  placeholder,
  tooltip,
  isMulti,
  isClearable,
  menuPosition,
  instanceId,
  error,
  callBack,
  modelData,
  isForLocation,
}: 
LocationPickerPropsType) => {
  const [selectedOptions, setSelectedOptions] = useState(input.value);
  const parseForLocationOptions = (locations: any) => {
    const parsedData = locations.map((location: any) => {
      return {
        ...location,
        location_id: location.id,
        value: location.id,
        label: location.name,
      };
    });
    return parsedData;
  };

  const getLocationOptions = async (inputValue: string) => {
    const result = await restApiService.get(
      "locations.lk",
      { LOCATION_NAME: inputValue, ...filter },
      null,
      true,
      null,
      true,
    );
    const value = parseForLocationOptions(result.data);
    return value;
  };

  const loadOptions = async (inputValue: string, callback: any) => {
    const options = await getLocationOptions(inputValue);
    callback(options);
  };

  const onChangeSingle = (selectedOption: SingleValue<void | LocationOptionsType>) => {
    input.onChange(selectedOption);
    if (callBack) {
      callBack(selectedOption);
    }
  };

  const onChangeMultiple = (selectedOptions: MultiValue<LocationOptionsType>) => {
    setSelectedOptions(selectedOptions);
    input.onChange(selectedOptions);
  };

  // custom logic to handle _destroyed
  const handleOptionRemove = (data: any) => {
    if (_.isArray(selectedOptions)) {
      const newValues = selectedOptions.map((val: any) => {
        if (val.value === data.value) {
          val._destroy = 1;
        }
        return val;
      });
      input.onChange(newValues);
      setSelectedOptions(newValues);
    }
  };

  useEffect(() => {
    if (typeof input.value === "object" && !input.value.label) {
      input.value.label = input.value.name;
    }

    setSelectedOptions(input.value);
  }, [input.value]);

  //this is to handle case when we dont get for_location object but there is for_location_id available
  const initLocation = () => {
    if (input.value || selectedOptions) {
      return false;
    }

    if (_.isPlainObject(modelData) && !modelData.for_location && modelData.for_location_id && isForLocation) {
      let obj: any = {};
      obj.id = obj.value = obj.location_id = modelData.for_location_id;
      obj.name = obj.label = modelData.for_location_name;
      if (!isMulti) {
        onChangeSingle(obj);
      }
    }
  };

  const checkIsClearable = useCallback(() => {
    if (required) {
      return false;
    } else if (isDefined(isClearable)) {
      return isClearable;
    } else if (_.isUndefined(isClearable)) {
      return false;
    }
  }, [isClearable, required]);

  useEffect(() => {
    initLocation();
  }, [modelData?.for_location_id]);

  if (isMulti) {
    return (
      <Form.Group className={className}>
        {label && (
          <Form.Label>
            {label ?? null}
            <Mandatory required={required} />
            {tooltip ?? null}
          </Form.Label>
        )}
        <AsyncSelect
          {...input}
          value={selectedOptions}
          required={required}
          loadOptions={(inputValue, callback): any => loadOptions(inputValue, callback)}
          onChange={(selectedOption) => {
            onChangeMultiple(selectedOption);
          }}
          onBlur={() => {
            input.onBlur(input.value);
          }}
          isMulti
          components={{
            MultiValue: CustomMultiValue,
            MultiValueRemove: ({ innerProps, data }) => (
              <span {...innerProps} onClick={() => handleOptionRemove(data)}>
                {"  "}&times;{"  "}
              </span>
            ),
          }}
          defaultOptions
          cacheOptions={true}
          placeholder={placeholder ?? ""}
          isClearable={checkIsClearable()}
          menuPosition={menuPosition ? menuPosition : null}
          instanceId={instanceId ? instanceId : "multi-location-picker"}
        />
        {error && <div className="error">{error}</div>}
      </Form.Group>
    );
  }

  if (!isMulti) {
    return (
      <Form.Group className={`${Styles.select} ` + className}>
        {label && (
          <Form.Label>
            {label ?? null}
            <Mandatory required={required} />
            {tooltip ?? null}
          </Form.Label>
        )}
        <AsyncSelect
          {...input}
          required={false}
          loadOptions={(inputValue, callback): any => loadOptions(inputValue, callback)}
          onChange={(selectedOption) => {
            onChangeSingle(selectedOption);
          }}
          onBlur={() => {
            input.onBlur(input.value);
          }}
          defaultOptions
          cacheOptions
          placeholder={placeholder ?? ""}
          isClearable={checkIsClearable()}
          menuPosition={menuPosition ? menuPosition : null}
          menuPlacement="auto"
          instanceId={instanceId ? instanceId : "single-location-picker"}
        />
        {(error || error2) && touched && <PickerErrorBlock error={error || error2} />}
      </Form.Group>
    );
  }
  return <></>;
};

export default LocationPicker;