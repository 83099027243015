import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import useIsMounted from "components/common/hooks/useIsMounted";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import CustomModal from "components/modals/customModal";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useTypedSelector } from "reducers";
import { SubmissionError, change } from "redux-form";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { IUser } from "services/common/user/userTypes";
import { formattedAmount } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { roundUpAmount } from "services/vp/services/roundUpAmount";
import FormInvoice, { formName } from "./form";
import styles from "./invoices.module.css";

const EditInvoice = () => {
  const { id } = useParams<{ id: string }>();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [invoiceDetails, setInvoicedetails] = useState<InvoiceType.InvoiceDetailType>();
  const [showModal, setShowModal] = useState(true);
  const [savedInvoice, setSavedInvoice] = useState<InvoiceType.InvoiceDetailType>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const dispatch = useDispatch();

  const { createConfirmModal } = useConfirmModal();

  const updateAccountLinks = (invoice: any) => {
    invoice.debit_entries_attributes.forEach((debitEntry: any) => {
      debitEntry.percent = roundUpAmount((debitEntry.amount / invoice.amount) * 100);
    });

    invoice.credit_entries_attributes.forEach((creditEntry: any) => {
      creditEntry.percent = roundUpAmount((creditEntry.amount / invoice.amount) * 100);
    });
  };

  const getInvoice = async () => {
    try {
      let response = await InvoiceApis.getInvoice(id);
      response.submitWithAccounts = true;
      setSavedInvoice(response);

      if (isMounted.current) {
        await getInvoiceItems(response);
        response.debit_entries?.forEach((debitEntry: any) => {
          debitEntry.metadata_links_attributes = debitEntry.metadata_links;
        });
        response.debit_entries_attributes = response.debit_entries;
        response.credit_entries_attributes = response.credit_entries;
        response.tax_debit_entries_attributes = response.tax_debit_entries;
        response.tax_credit_entries_attributes = response.tax_credit_entries;
        response.invoice_purchase_order_links_attributes = response.purchase_order_links;
        response.metadata_links_attributes = response.metadata_links;
        invoiceCommonSvc.setPriorPeriodRange(response, currentUser);

        updateAccountLinks(response);
        setInvoicedetails(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoiceItems = async (invoice: any) => {
    try {
      let response = await InvoiceApis.getInvoiceItems(id);
      response.invoice_items?.forEach((invoiceItem: any) => {
        invoiceItem.metadata_links_attributes = invoiceItem.metadata_links;
      });
      invoice.invoice_items_attributes = response.invoice_items;
      invoiceCommonSvc.getInvoiceItemsTotal(invoice, currentUser);
    } catch (error) {
      console.log(error);
    }
  };

  const deleleTaxEntries = (invoicePayload: InvoiceType.InvoiceDetailType) => {
    if (
      _.isArray(invoicePayload.tax_debit_entries_attributes) &&
      invoicePayload.tax_debit_entries_attributes.length > 0
    ) {
      invoicePayload.tax_debit_entries_attributes.forEach((taxDebitEntry: any) => {
        taxDebitEntry._destroy = 1;
      });
    }

    if (
      _.isArray(invoicePayload.tax_credit_entries_attributes) &&
      invoicePayload.tax_credit_entries_attributes.length > 0
    ) {
      invoicePayload.tax_credit_entries_attributes.forEach((taxCreditEntry: any) => {
        taxCreditEntry._destroy = 1;
      });
    }
  };

  const mergeSubtotalUsedTax = (invoicePayload: InvoiceType.InvoiceDetailType) => {
    if (!invoicePayload.is_used_tax && !invoicePayload.is_account_used_tax) {
      deleleTaxEntries(invoicePayload);
    } else if (
      (_.isArray(invoicePayload.tax_debit_entries_attributes) &&
        invoicePayload.tax_debit_entries_attributes.length > 0) ||
      (_.isArray(invoicePayload.tax_credit_entries_attributes) &&
        invoicePayload.tax_credit_entries_attributes.length > 0)
    ) {
      let debitTaxObj: any = invoiceCommonSvc.getTaxDebit(
        invoicePayload.tax_debit_entries_attributes,
        currentUser,
        invoicePayload,
      );
      let creditTaxObj: any =
        _.isArray(invoicePayload.tax_credit_entries_attributes) && invoicePayload.tax_credit_entries_attributes[0]
          ? invoicePayload.tax_credit_entries_attributes[0]
          : {};

      if (creditTaxObj.id && !(debitTaxObj.id > 0)) {
        debitTaxObj._destroy = 1;
      }
      debitTaxObj.id = creditTaxObj.id;
      if (
        (invoicePayload.is_used_tax || invoicePayload.is_account_used_tax) &&
        invoiceCommonSvc.getSubtotalUsedTaxTotal(invoicePayload) > 0
      ) {
        debitTaxObj.account_id = debitTaxObj.tax_code?.credit_account_id
          ? debitTaxObj.tax_code?.credit_account_id
          : currentUser.company.used_tax_credit_account_id;
      }
      invoicePayload.tax_credit_entries_attributes = [debitTaxObj];
    }
  };

  const manageAccountLevelUseTax = function (invoicePayload: InvoiceType.InvoiceDetailType) {
    if (_.isArray(invoicePayload.debit_entries_attributes) && invoicePayload.debit_entries_attributes.length > 0) {
      invoicePayload.debit_entries_attributes.forEach((debitEntry: any) => {
        if (debitEntry._destroy != 1 && !invoiceCommonSvc.isItemDebitLine(debitEntry)) {
          invoiceCommonSvc.mergeUsedTax(invoicePayload, debitEntry, currentUser, invoicePayload.is_account_used_tax);
        }
      });
    }
  };

  const compareWithInvoiceOriginalAmount = (invoicePayload: any) => {
    createConfirmModal({
      title: t("admin.pages.invoice.confirm"),
      body: (
        <>
          <span>The invoice amount has changed </span>
          {formattedAmount(invoicePayload.amount.toString(), invoicePayload.currency_code)}
          <span> and original amount is </span>
          {formattedAmount(invoicePayload.orig_amount.toString(), invoicePayload.currency_code)},
          <span> do you want to proceed?</span>{" "}
        </>
      ),
      callBackData: invoicePayload,
      cancelCallBack: null,
      confirmButtonLabel: t("ok"),
      saveCallBack: submit,
    });
  };

  const handleSubmit = async (invoicePayload: InvoiceType.InvoiceDetailType) => {
    if (invoicePayload.amount != savedInvoice?.amount && invoicePayload?.amount != savedInvoice?.orig_amount) {
      compareWithInvoiceOriginalAmount(invoicePayload);
    } else {
      return submit(invoicePayload);
    }
  };

  const submit = async (invoicePayload: InvoiceType.InvoiceDetailType) => {
    invoiceCommonSvc.normalizeFieldValues(invoicePayload.debit_entries_attributes);
    invoiceCommonSvc.normalizeFieldValues(invoicePayload.credit_entries_attributes);

    if (
      currentUser?.company?.has_subtotal_templates &&
      invoiceCommonSvc.validateSubtotalTemplate(invoicePayload.debit_entries_attributes)
    ) {
      CreateNotification("Warning", `Duplicate subtotal description not allowed.`, NotificationType.warning);
      return false;
    }

    if (invoiceCommonSvc.validatePriorPeriodWithinRange(invoicePayload, currentUser)) {
      dispatch(change(formName, "showPriorPeriodDateRangeError", true));
      return false;
    }

    //TODO: validate service start date and service end date
    // if(invoiceCommonSvc.validateServiceDate(invoicePayload.service_start_date, invoicePayload.service_end_date)){
    //   return false;
    // }

    if (!invoicePayload.requestor || (invoicePayload.requestor && !(invoicePayload.requestor.id > 0))) {
      invoicePayload.requestor_id = "";
    }

    if (currentUser?.company?.enable_used_tax) {
      mergeSubtotalUsedTax(invoicePayload);
    }

    invoiceCommonSvc.updateFieldsBeforeSubmit(invoicePayload, currentUser);

    if (_.isArray(invoicePayload.invoice_items_attributes && invoicePayload.invoice_items_attributes.length > 0)) {
      invoicePayload.invoice_items_attributes?.forEach((invoiceItem) => {
        if (currentUser.company.enable_used_tax) {
          invoiceCommonSvc.mergeUsedTax(invoicePayload, invoiceItem, currentUser, invoicePayload.is_used_tax);
        }
      });
    }

    if (invoicePayload.payment_run) {
      invoicePayload.payment_run_id = invoicePayload?.payment_run?.id;
    }

    if (invoicePayload.submitWithAccounts) {
      invoiceCommonSvc.assignInvoiceAmountToCredit(invoicePayload, currentUser);

      if (currentUser.company.enable_used_tax) {
        manageAccountLevelUseTax(invoicePayload);
      }

      //TODO: uncomment after implementation of rebate feature
      //TODO: invoicePayload.debit_entries_attributes = invoiceCommonSvc.updateDebitLineSubAmount(invoicePayload.debit_entries_attributes);

      invoiceCommonSvc.setHeaderTaxDebitEntry(invoicePayload, currentUser);

      invoicePayload.account_transaction_attributes = {};
      invoicePayload.account_transaction_attributes.date = invoicePayload.date;
      invoicePayload.account_transaction_attributes.id = invoicePayload?.account_transaction
        ? invoicePayload?.account_transaction?.id
        : undefined;
      invoicePayload.account_transaction_attributes.amount = invoicePayload.amount;
      invoicePayload.account_transaction_attributes.debit_entries_attributes = invoicePayload.debit_entries_attributes;
      invoicePayload.account_transaction_attributes.credit_entries_attributes =
        invoicePayload.credit_entries_attributes;
    } else {
      invoicePayload.account_transaction_attributes = undefined;
    }

    //set the po links amount at the last minute to gurantee they are correct
    invoiceCommonSvc.setPoLinksAmount(invoicePayload);

    //allow user to change from PAID to other status
    if (invoicePayload.status !== "PAID") {
      invoicePayload.external_payment_date = null;
    }

    //force status to be PAID if external payment date exists
    if (invoicePayload?.external_payment_date) {
      invoicePayload.status = "PAID";
    }

    // use async flag to indicate if the invoice has more than 10 items or po links
    // the backend will process the invoice in background if async is true
    if (
      _.isArray(invoicePayload.invoice_purchase_order_links_attributes) &&
      invoicePayload.invoice_purchase_order_links_attributes.length > invoiceCommonSvc.async_po_count
    ) {
      invoicePayload.async = true;
    } else if (
      _.isArray(invoicePayload.invoice_items_attributes) &&
      invoicePayload.invoice_items_attributes.length > invoiceCommonSvc.async_items_count
    ) {
      invoicePayload.async = true;
    }

    if (invoicePayload.metadata_links_attributes && invoicePayload.metadata_links_attributes.length > 0) {
      invoicePayload.metadata_links_attributes = _.uniqBy(
        invoicePayload.metadata_links_attributes,
        "metadata_configuration_id",
      );
      invoicePayload.metadata_links = invoicePayload.metadata_links_attributes;
    }

    try {
      const response = await InvoiceApis.editInvoice(id, { invoice: invoicePayload });
      if (response?.id) {
        CreateNotification("Updated", `Invoice successfully updated`, NotificationType.success);
        if (response.status_message) {
          CreateNotification("Sync", response.status_message, NotificationType.info);
        }
        setTimeout(() => {
          window.location.href = `${restApiService.angularBaseURL()}invoices`;
        }, 1000);
      }
    } catch (error: any) {
      const { response } = error;
      console.log("response: ", response);

      throw new SubmissionError(response.data);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      getInvoice();
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const cancel = () => {
    closeModal();
    window.location.href = `${restApiService.angularBaseURL()}invoices`;
  };

  return (
    <>
      {invoiceDetails?.id && (
        <ErrorBoundary>
          <CustomModal
            size="xl"
            headerClass={styles.modelSpacing}
            modalBodyClass={styles.modelSpacing}
            headerTitleClass={styles.modalHeader}
            contentClassName={styles.modelStyle}
            header={t("admin.pages.invoice.editInvoice")}
            body={
              <FormInvoice
                onSubmit={handleSubmit}
                onCancel={cancel}
                initialValues={invoiceDetails}
                savedInvoice={savedInvoice}
              />
            }
            show={showModal}
            onHide={cancel}
          />
        </ErrorBoundary>
      )}
    </>
  );
};

export default EditInvoice;
