import { Mandatory } from "components/forms/bootstrapFields";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { WrappedFieldProps } from "redux-form";
import { getYearAndMonthOptions } from "services/date/date";
import PickerErrorBlock from "../pickerErrorBlock";

type TOption = {
  value: string;
  label: string;
};

interface TFiscalPeriodPickerProps extends WrappedFieldProps {
  id?: string;
  required?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  label: string;
  callBack?: (selected: TOption) => void;
  instanceId?: string;
}

const FiscalPeriodPicker: React.FC<TFiscalPeriodPickerProps> = ({
  id = "react-fiscal-period-picker",
  input,
  meta: { touched, error },
  required = false,
  label,
  hideLabel = false,
  callBack,
  disabled,
  instanceId,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<TOption[]>([]);
  const [selected, setSelected] = useState<TOption>();

  const fiscalPeriodOptions = useMemo(
    () =>
      getYearAndMonthOptions(0, 5, "YYYYMM", required, t("components.common.pickers.emptyOption", { label: label })),
    [label, required, t],
  );

  const selectedOption = useMemo(() => {
    return (value: string | null) => {
      if (!value || !options || options.length === 0) return undefined;
      return options.find((option) => option.value === value);
    };
  }, [options]);

  useEffect(() => {
    setOptions(fiscalPeriodOptions);
  }, [fiscalPeriodOptions]);

  useEffect(() => {
    setSelected(selectedOption(input.value));
  }, [selectedOption, fiscalPeriodOptions, input.value]);

  const handleChange = (input: any, selected: TOption | null) => {
    const value = selected?.value || "";
    setSelected(selected || undefined);
    input.onChange(value);

    if (callBack) {
      callBack(selected!);
    }
  };

  const handleBlur = (input: any) => {
    const value = input?.value || "";
    input.onBlur(value);
  };

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
        </Form.Label>
      )}
      <Select
        {...input}
        id={id}
        instanceId={instanceId}
        isClearable={!required}
        value={selected}
        className="pickerSelectedOption"
        options={options}
        menuPlacement="auto"
        menuPosition="fixed"
        isDisabled={disabled}
        onChange={(selected) => handleChange(input, selected as TOption | null)}
        onBlur={() => handleBlur(input)}
      />
      {touched && error && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default FiscalPeriodPicker;
