import React, { Component } from "react";
import RestApi from "../../../providers/restApi";
import DataGrid from "../../../components/datagrid/datagrid";
import TabNavigation from "../../../components/navigation/tabNavigation";
import vendorInvoicesRequestHeader from "../../../components/datagrid/girdHeaders/vendorInvoicesRequestHeader";
import { Container } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class InvoiceRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice_request: [],
      gridStyle: {
        width: "100%",
      },
    };
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.restApiService
      .get("vendor_invoices/requests")
      .then((result) => {
        this.setState({
          invoice_request: result.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const tabNavigation = [
      { path: "/ar/invoices", pageName: this.props.t("breadcrumbs.allInvoices") },
      { path: "/ar/invoice_requests", pageName: this.props.t("breadcrumbs.requests") },
    ];
    return (
      <div>
        <Container fluid={true} className={"pt-4 pb-4"}>
          <TabNavigation navigationTab={tabNavigation} {...this.props} />
          <DataGrid
            gridHeight="500px"
            gridStyle={this.state.gridStyle}
            rowData={this.state.invoice_request}
            columnDefs={vendorInvoicesRequestHeader}
            dataType={"invoice_request"}
            autoHeight={true}
            {...this.props}
          />
        </Container>
      </div>
    );
  }
}

export default withTranslation()(InvoiceRequests);
