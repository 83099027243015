import React, { useState, useCallback, useEffect, useRef } from "react";
import { restApiService } from "providers/restApi";
import { Container } from "react-bootstrap";
import Panel from "components/common/panel/panel";
import NavTabs from "pages/admin/administration/nav";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { is } from "immutable";

export default function MccDefaultsForm() {
  const [formData, setFormData] = useState<{ groupCode: string; categoryId: number }[]>([]);
  const [mccGroups, setMccGroups] = useState<
    { group_description: string; group_code: string; category_id: number; category_name: string }[]
  >([]);
  const [categories, setCategories] = useState<{ id: any; name: string }[]>([]);

  const isMounted = useRef(true);

  const getCategories = useCallback(async () => {
    try {
      const result = await restApiService.get("categories", { status: "ACTIVE" });

      if (isMounted.current) {
        setCategories(result.data || []);
        if (result.data?.length) {
          setMccGroups(result.data[0].mcc_groups || []);
          const newFormData = (result.data[0]?.mcc_groups || []).map(
            (mccGroup: { group_code: string; category_id: number }) => {
              return {
                groupCode: mccGroup.group_code,
                categoryId: mccGroup.category_id,
              };
            },
          );
          setFormData(newFormData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleCategoryChange = (groupCode: string, categoryId: number) => {
    setFormData((prevData) =>
      prevData.map((data) => ({ ...data, categoryId: data.groupCode === groupCode ? categoryId : data.categoryId })),
    );
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    try {
      const updatePromises = categories.map(async (category: { id: number }) => {
        const categoryId = category.id;
        const matchingFormData = formData.filter((data) => data.categoryId === categoryId);
        const groupCodes = Array.from(new Set(matchingFormData.map((data) => data.groupCode)));

        const response = await restApiService.patch(`categories/${categoryId}`, null, {
          category: {
            mcc_codes: groupCodes,
          },
        });

        return response.status;
      });

      const statuses = await Promise.all(updatePromises);

      if (statuses.every((status) => status === 200)) {
        CreateNotification("Saved", `MCC Default Categories Mapping Updated Successfully`, NotificationType.success);
      }
    } catch (error) {
      CreateNotification("Saved", `MCC Default Categories Mapping Update Error:` + error, NotificationType.danger);
    }
  };

  return (
    <Container fluid={true}>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"MCC Categories"} />
        </Col>
      </Row>
      <hr className="mt-4 mb-4" />
      <Panel
        cardHeaderClass="px-p-10"
        header={
          <div className="d-flex justify-content-between">
            <div className="flex-center" style={{ padding: "10px" }}>
              <i className="icon icon-global-settings"></i> {"MCC DEFAULT CATEGORIES MAPPING"}
            </div>
          </div>
        }
      >
        <div>
          <form onSubmit={handleSubmit}>
            <Container>
              <Row style={{ fontWeight: "bold" }} className="mb-2">
                <Col md={2}></Col>
                <Col md={4}>MCC GROUP</Col>
                <Col>CATEGORIES</Col>
              </Row>
              {mccGroups.map((mccGroup) => (
                <div key={mccGroup.group_code} className="mb-2">
                  <Row>
                    <Col md={2}></Col>
                    <Col md={4}>
                      <div
                        style={{ padding: "7px 0 0 0" }}
                      >{`${mccGroup.group_code} - ${mccGroup.group_description}`}</div>
                    </Col>
                    <Col className="p-0 pl-2" md={3}>
                      <Select
                        options={[
                          ...categories.map((category) => ({
                            label: category.name,
                            value: category.id,
                          })),
                          { label: "No Mapping", value: null }, // Null option
                        ]}
                        defaultValue={{ label: mccGroup.category_name, value: mccGroup.category_id }}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            handleCategoryChange(mccGroup.group_code, selectedOption.value);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </Container>
            <Row className="mt-4" style={{ margin: "0 0 60px 0" }}>
              <Col md={8}></Col>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </Row>
          </form>
        </div>
      </Panel>
    </Container>
  );
}
