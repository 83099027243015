import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./prompts.module.css";
import { PromptsPropsType } from "./managePrompts";

type ListPromptsPropsTypes = {
  promptsList: PromptsPropsType[];
  editPrompt: (prompt: PromptsPropsType) => void;
  deletePrompt: (id?: string | number) => void;
};

const ListPrompts = ({ promptsList, editPrompt, deletePrompt }: ListPromptsPropsTypes) => {
  return (
    <>
      <Row className={styles.listRow}>
        <Col sm="3">Name</Col>
        <Col sm="6">Content</Col>
        <Col sm="2"></Col>
      </Row>
      {promptsList &&
        promptsList.length > 0 &&
        promptsList.map((prompt: PromptsPropsType, index: number) => (
          <Row key={index} className={styles.promptsRow}>
            <Col sm="3">{prompt.name}</Col>
            <Col sm="8">{prompt.content}</Col>
            <Col sm="1" className="float-right">
              <div className="float-right">
                <button
                  type="button"
                  className="actionButtons editIcon"
                  data-toggle="tooltip"
                  title="Edit"
                  onClick={() => editPrompt(prompt)}
                ></button>
                <button
                  type="button"
                  className="actionButtons delete"
                  data-toggle="tooltip"
                  title="Delete"
                  onClick={() => deletePrompt(prompt.id)}
                ></button>
              </div>
            </Col>
          </Row>
        ))}
    </>
  );
};

export default ListPrompts;
