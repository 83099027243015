import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ServerSideDataGrid from "../../../common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import GridFilterDropdown from "../../../datagrid/gridFilterDropdown";
import ToggleFilterButton from "../../../datagrid/buttons/toggleFilterButton";
import { ColumnApi, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import ReceiptsApis from "../../../../services/admin/expenses/receipts/receiptsApis";
import useShowFilterState from "../../../common/hooks/useShowFilterState";
import _ from "lodash";
import { getPendingReceiptsHeaders, FIELD_NAME_MAP } from "./listPendingReceiptsHeaders";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../reducers";
import { getPrimaryCurrencyOfUser } from "reducers/userReducers";
import AddReceipt from "./addReceipt";
import { getParams, saveDefaultOrder } from "services/common/gridService";
import { useEffect } from "react";

const BASE_PARAMS = {
  document_type: "RECEIPT",
  status: ["PENDING_OCR", "PENDING_MATCH", "ACTION_REQUIRED"],
  documentable_type: "Contact",
};

const GRID_STORAGE_NAME = "ai.grid.listPendingReceipts";
const ListPendingReceipts = () => {
  const { t } = useTranslation();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const [showAddReceiptFormModal, setShowAddReceiptFormModal] = useState(false);
  const { showFilters, updateShowFilters } = useShowFilterState("listPendingReceiptFilter");
  const currentUser = useTypedSelector((state) => state.user);
  const currencyCode = useTypedSelector(getPrimaryCurrencyOfUser);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: getRows });
  };

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = async (params: IServerSideGetRowsParams) => {
    try {
      let filterParams = {
        ...BASE_PARAMS,
        ...getParams(params, FIELD_NAME_MAP),
      };
      const result = await ReceiptsApis.getReceiptList({ filter: filterParams });
      if (_.isObject(result) && result.data) {
        params.success({
          rowData: result.data,
          rowCount: result.meta.count,
        });
      }
    } catch (error) {
      console.log("error", error);
      params.fail();
    }
  };

  const addReceiptSuccessfullCallback = () => {
    // refresh serverside data grid
    if (gridApi) {
      gridApi.refreshServerSide();
    }
  };
  const gridHeaders = getPendingReceiptsHeaders({
    gridApi,
    currentUser,
    currencyCode,
  });

  useEffect(() => {
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
  }, []);

  return (
    <>
      {showAddReceiptFormModal && (
        <AddReceipt
          show={showAddReceiptFormModal}
          onHide={() => setShowAddReceiptFormModal(false)}
          addReceiptSuccessfullCallback={addReceiptSuccessfullCallback}
        />
      )}

      <Row>
        <Col>
          <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} gridStorageName={GRID_STORAGE_NAME} />
          <Button
            title="Add New Receipt"
            className="mx-2"
            onClick={() => setShowAddReceiptFormModal(true)}
            id="add_inbound_add"
          >
            <i className="d-block float-left icon-add mr-2" style={{ height: 18, width: 18 }} aria-hidden="true" />{" "}
            {t("admin.pages.receipts.addNewReceipt")}
          </Button>
          <ToggleFilterButton
            classes="float-right"
            clickCallback={() => {
              updateShowFilters(!showFilters);
            }}
          />
        </Col>
      </Row>

      <Row className="px-3 mt-3" style={{ height: "60vh" }}>
        <ServerSideDataGrid
          columnDefs={gridHeaders.columnDefs}
          defaultColDef={{
            resizable: true,
            filter: true,
            floatingFilter: showFilters,
          }}
          rowSelection="multiple"
          gridReady={gridReady}
          gridStorageName={GRID_STORAGE_NAME}
          domLayout="normal"
          paginationSize={25}
          pagination
        />
      </Row>
    </>
  );
};

export default ListPendingReceipts;
