import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import Tag from "../admin/invoices/email/tags";

class DraftInboxListCellRenderer extends React.Component {
  render() {
    if (!this.props.data) {
      return <h1>empty</h1>;
    }
    // let data = this.props.data;
    return (
      <Container fluid={true}>
        <Row className="w-100">
          <Col xs={10} className={"p-0"}>
            <div className={"d-flex justify-content-start email-message"}>
              <div>
                <Tag tags={this.props.data.tags} />
              </div>
              <div>
                <p className={"email-message"}>{this.props.data.subject}</p>
              </div>
            </div>
          </Col>
          <Col xs={2} className={"emailDate "}>
            <p
              className={"text-right"}
              data-toggle="tooltip"
              title={moment(this.props.data.received_at).format("MMMM Do YYYY, h:mm:ss a")}
            >
              {moment().diff(this.props.data.received_at, "hours") < 24
                ? moment(this.props.data.received_at).format("LT")
                : moment(this.props.data.received_at).format("ll")}
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(DraftInboxListCellRenderer);
