import { AxiosResponse } from "axios";
import useIsMounted from "components/common/hooks/useIsMounted";
import { Mandatory } from "components/forms/bootstrapFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { WrappedFieldProps } from "redux-form";
import { onBlurSingle, onChangeSingle } from "services/general/helpers";

interface StaticWorkflowPickerPropsType extends WrappedFieldProps {
  label?: string;
  input: any;
  tooltip?: string;
  required?: boolean;
  placeholder?: string;
  modelName?: string;
  instanceId?: string;
}

type WorkflowObjPropType = {
  workflow_name: string;
  workflow_name_label: string;
};

const StaticWorkflowPicker = ({
  label,
  input,
  tooltip,
  required,
  placeholder = "search/select",
  modelName,
  instanceId = "invoice-static-workflow-picker",
}: StaticWorkflowPickerPropsType) => {
  const [staticWorkflow, setStaticWorkflow] = useState({});
  const isMounted = useIsMounted();

  const parseForSelect = (options: WorkflowObjPropType[]) => {
    return options.map((option: WorkflowObjPropType) => {
      return {
        value: option.workflow_name,
        label: option.workflow_name_label,
      };
    });
  };

  const getStaticWorkflowOptions = async () => {
    try {
      const result: AxiosResponse = await restApiService.get(
        `approval_workflows/static_workflow_names`,
        { workflow_type: modelName },
        null,
        true,
        null,
        true,
      );
      let list = parseForSelect(result.data);
      if (isMounted.current) {
        if (!required) {
          list = [{ label: "-- Select Static Workflow --", value: "" }, ...list];
        }
        setStaticWorkflow(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const findSelected = (input: any, staticWorkflow: any) => {
    let found = _.filter(staticWorkflow, { label: input.value });
    if (found) {
      return found[0];
    }
  };

  useEffect(() => {
    getStaticWorkflowOptions();
  }, []);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}

      <Select
        {...input}
        required={false}
        value={findSelected(input, staticWorkflow)}
        placeholder={placeholder}
        isClearable={!required}
        onChange={(selected) => onChangeSingle(input, selected)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={staticWorkflow}
        instanceId={instanceId}
      />
    </Form.Group>
  );
};

export default StaticWorkflowPicker;
