import React from "react";
import DateFilter from "../reportFilters/date";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";

const VirtualCardTransactionsListing = ({ scheduled }: { scheduled?: boolean }) => {
  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    } else {
      return (
        <>
          <DateFilter name={"Start Date"} code={"start_date"} options={[]} isRequired />
          <DateFilter name={"End Date"} code={"end_date"} options={[]} isRequired />
        </>
      );
    }
  };

  return <div>{renderDateFilter()}</div>;
};

export default VirtualCardTransactionsListing;
