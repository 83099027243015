import React, { useState, useEffect } from "react";
import InputDropdownFilter from "./inputDropdown";
import { restApiService } from "providers/restApi";
import { AxiosResponse } from "axios";
import { Spinner } from "react-bootstrap";

const LocationsOptionsFilter = ({
  name,
  code,
  options,
  isRequired,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  isRequired?: boolean;
}) => {
  const [locationOptions, setLocationOptions] = useState<{ label: string; value: string }[]>([
    { label: "ALL", value: "" },
  ]);

  const loadContactsOptions = async () => {
    try {
      const response: AxiosResponse<any> = await restApiService.get("locations");
      const locations: { label: string; value: string }[] = [];
      locations.push({ label: "ALL", value: "" });
      response.data.forEach((location: { name: string; id: string }) => {
        locations.push({ label: location.name, value: location.id });
      });
      setLocationOptions(locations);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    loadContactsOptions();
  }, []);

  const renderDropdown = () => {
    if (locationOptions.length > 1) {
      return <InputDropdownFilter name={name} code={code} options={locationOptions} isRequired={isRequired} />;
    } else {
      return (
        <div>
          <h5>Loading Locations Filter...</h5>
          <Spinner animation="border" variant="info"></Spinner>
        </div>
      );
    }
  };

  return <>{renderDropdown()}</>;
};

export default LocationsOptionsFilter;
