import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { getListOptionsType } from "services/common/types/common.type";

export class MetadataTemplateApis {
  static ENDPOINT = "metadata_templates";

  static async list({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<any[]> = await restApiService.get(this.ENDPOINT, filter, null, true, null, cache);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async get(id: string) {
    try {
      const response: AxiosResponse<any> = await restApiService.get(`${this.ENDPOINT}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
