import Panel from "components/common/panel/panel";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import adminCommonSvc from "services/admin/commonSvc";
import styles from "./metadataShow.module.css";

interface MetadataConfiguration {
  metadata_configuration_id: string;
  name: string;
}

const MetaDataShow = ({ model, noPanel, inline }: any) => {
  const [customFieldCollapse, setCustomFieldCollapse] = useState(true);
  const currentUser = useTypedSelector(selectCurrentUser);
  const { t } = useTranslation();

  function formatMetaValue(value: any, type: string) {
    if (!value || value.length === 0) {
      return t("metadata.none");
    } else if (type === "date") {
      return adminCommonSvc.companyFormatedDate(value, currentUser);
    }
    return value;
  }

  function getFieldNamesAndValues(model: any) {
    const metaCategoryName: string[] = [];
    const metaCategoryValue: string[] = [];

    // If the model.metadataForm is present, it should always display data from it, as it contains the most updated information.
    // The metadata should be shown from the model.metadata until it is edited.
    if (model?.metadata && !model?.metadataForm) {
      for (const name in model.metadata) {
        if (model.metadata.hasOwnProperty(name)) {
          const metadataField = model.metadata[name];
          metaCategoryName.push(metadataField.name);
          metaCategoryValue.push(formatMetaValue(metadataField.value, metadataField.input_type));
        }
      }
    } else if (model?.metadata_links) {
      // These are metadata rows that have been created but not yet saved.
      // Labels are stored in metadata_configurations, values are in metadata_links.
      // metadata_links has the correct order, left to right, of the name/value pairs as defined in the template

      // This will be an array of {metadata_configuration_id, name} objects
      const configurations: MetadataConfiguration[] = Object.values(model?.metadataForm?.metadata_configurations || {});

      // Loop through all links
      model.metadata_links.forEach((metadataField: any) => {
        // Store the value in the 'value' array
        metaCategoryValue.push(formatMetaValue(metadataField.value, metadataField.input_type));

        // Obtain the ID of the value was just stored
        const fieldId = metadataField.metadata_configuration_id;

        // Find a configuration element with the same id as the 'value' that was just stored
        const matchedName = configurations.find(
          (config: MetadataConfiguration) => config.metadata_configuration_id === fieldId,
        );

        // Save the label name associated with the value
        metaCategoryName.push(matchedName ? matchedName.name : "");
      });
    }

    return {
      metaCategoryNames: metaCategoryName,
      metaCategoryValues: metaCategoryValue,
    };
  }

  const MetaDataTable: React.FC<{ metadataNames: string[]; metadataValues: string[] }> = ({
    metadataNames,
    metadataValues,
  }) => {
    if (metadataNames.length === 0 || metadataValues.length === 0) {
      return null;
    }

    if (metadataNames.length !== metadataValues.length) {
      throw new Error("Metadata names and values arrays must have the same length");
    }

    return (
      <table className={styles.metaDataTable}>
        <thead>
          <tr>
            {metadataNames.map((name: string, index: number) => (
              <th key={index}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {metadataValues.map((value: string, index: number) => (
              <td key={index}>{value}</td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  const { metaCategoryNames, metaCategoryValues } = getFieldNamesAndValues(model);

  if (noPanel) {
    return (
      <>
        <div className={`${inline ? "d-flex" : ""} ${styles.px12}`}>
          <MetaDataTable metadataNames={metaCategoryNames} metadataValues={metaCategoryValues} />
        </div>
      </>
    );
  }

  if (!noPanel) {
    return (
      <Panel
        header={
          <div className={""}>
            <i className={`icon icon-activity-log mr-2`}></i> {t("metadata.additionalCodingFields")}
            <button
              type="button"
              className={styles.chevronButton}
              onClick={() => setCustomFieldCollapse(!customFieldCollapse)}
            >
              <i className={`icon ${customFieldCollapse ? "icon-arrow-up" : "icon-arrow-down"}`}></i>
            </button>
          </div>
        }
        hideCardBody={!customFieldCollapse}
      >
        {customFieldCollapse && <MetaDataTable metadataNames={metaCategoryNames} metadataValues={metaCategoryValues} />}
      </Panel>
    );
  }
  return <></>;
};

export default MetaDataShow;
