import React, { useState } from "react";
import { Container, Modal, ListGroup, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { refreshEamil, unlinkEmailInvoice } from "../../../../reducers/admin/emailReducer";

export type unlinkEmailProps = {
  emailDetail: any;
  handleClose: () => void;
  handleShow: () => void;
};

const UnlinkEmail = (props: unlinkEmailProps) => {
  const dispatch = useDispatch();
  const [invoiceList, setInvoiceList] = useState(props.emailDetail.data || []);

  const highlightRow = (invoice: any) => {
    invoice.highlightRow = !invoice.highlightRow;
    setInvoiceList([...invoiceList]);
  };

  const getSelectedRow = () => {
    let selectedRow: any[] = [];
    invoiceList.forEach((invoice: any) => {
      if (invoice.highlightRow) {
        invoice._destroy = 1;
        selectedRow.push(invoice);
      }
    });

    if (selectedRow.length) {
      unlinkEmail(selectedRow);
    } else {
      alert("Please select an invoice.");
    }
  };

  const unlinkEmail = async (selectedRow: any) => {
    const data = { data_attributes: selectedRow };
    try {
      // both of these await have effect in system
      // ignore if editor/linter  say has no effect
      await dispatch(unlinkEmailInvoice({ emailID: props.emailDetail.id, data }));
      await dispatch(refreshEamil(props.emailDetail.id));
      props.handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container className="bg-white p-0 m-0">
      <Modal.Header closeButton style={{ borderBottom: "1px solid #76777A" }}>
        <Modal.Title className="primaryFontColor">Unlink Invoice(s) From Email</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <ListGroup>
          <ListGroup>
            {invoiceList.map((invoice: any, i: number) => (
              <ListGroup.Item
                key={i}
                style={{ background: invoice.highlightRow ? "#D7EBF8" : "" }}
                onClick={() => highlightRow(invoice)}
                className="d-flex"
              >
                <h5 className="align-self-center">{invoice.number}</h5>
                <Button variant="link" className="ml-auto d-flex">
                  <i className="icon icon-unlink-secondary" />
                  <h5>unlink</h5>
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "1px solid #76777A" }}>
        <button type="button" className="btn btn-secondary" onClick={props.handleClose}>
          Cancel
        </button>
        <button type="button" className="btn btn-primary ml-2" onClick={() => getSelectedRow()}>
          Unlink Invoice(s)
        </button>
      </Modal.Footer>
    </Container>
  );
};

export default UnlinkEmail;
