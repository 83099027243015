import useShowSelectMenu from "components/admin/hooks/useShowSelectMenu";
import { Mandatory } from "components/forms/bootstrapFields";
import _ from "lodash";
import React, { memo, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useTypedSelector } from "reducers";
import { IDType } from "services/common/types/common.type";
import { CommonTypes } from "services/common/types/commonTypes";
import { IUser } from "services/common/user/userTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";
import restApi from "../../../../../providers/restApi";
import { isDefined, parseForSelect } from "../../../../../services/general/helpers";
import Styles from "../override.module.css";

type LocationPickerPropsTypes = {
  className?: string;
  labelClassName?: string;
  label?: string;
  input: any;
  meta: {
    touched: boolean;
    error?: string;
    warning?: string;
  };
  tooltip?: string;
  required?: boolean;
  inputId?: string;
  placeholder?: string;
  disabled?: boolean;
  onSelectCallback?: (value: string) => void;
  originalValue?: ReactNode;
  modelData?: any;
  parentObjData?: any;
  callBack?: (location: any) => void;
  instanceId?: string;
  isClearable?: boolean;
  showAsteriskWithoutLabel?: boolean;
} & CommonTypes.TSelectMenu;

type LocationsListOptionsType = {
  id?: IDType;
  is_location_map_to_department?: boolean;
  is_location_map_to_project?: boolean;
  is_tax_map_to_location?: boolean;
  name?: string;
  label?: string;
  status?: string;
  po_number?: string;
};

type ParamsType = {
  status: string;
  subsidiary_id?: IDType;
  business_unit_id?: IDType;
};

const restApiService = new restApi();

const LocationPicker = ({
  className,
  labelClassName,
  label,
  input,
  meta: { touched, error },
  tooltip,
  modelData,
  parentObjData,
  required,
  inputId,
  placeholder = "Select...",
  disabled = false,
  onSelectCallback,
  originalValue,
  callBack,
  instanceId = "location-picker",
  isClearable,
  showAsteriskWithoutLabel,
  parentDivId,
  parentDivVariance,
}: LocationPickerPropsTypes) => {
  const [locations, setLocations] = useState<LocationsListOptionsType[]>();
  const [selected, setSelected] = useState<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const isMounted = useRef(false);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useShowSelectMenu({
    setIsMenuOpen,
    selectRef,
    parentDivId,
    variance: parentDivVariance,
  });

  const isRequestPO = () =>
    currentUser?.company?.po_request_data_restrict_by_subsidiary && parentObjData?.is_request_PO;

  const isSubsidiaryHasLocations = () => {
    return currentUser?.company?.global?.show_locations_by_subsidiary && parentObjData?.subsidiary?.has_locations;
  };

  const getLocations = useCallback(async () => {
    let params: ParamsType = { status: "ACTIVE" };

    if (isRequestPO() || isSubsidiaryHasLocations()) {
      params.subsidiary_id = parentObjData.subsidiary_id;
    }

    if (modelData?.business_unit?.has_locations) {
      params.business_unit_id = modelData.business_unit_id;
      modelData.only_show_mapped_location = true;
    }

    try {
      const result = await restApiService.get("locations.lk", params, null, true, null, true);
      let list = parseForSelect(result.data);
      if (isMounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Location --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setLocations(list);
      }
      return list;
    } catch (error) {}
  }, [parentObjData?.subsidiary_id, modelData?.business_unit_id]);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const location = await options.filter((option: any) => option.value === input.value);

      if (location.length === 0 && modelData?.only_show_mapped_location) {
        modelData.only_show_mapped_location = false;
        selectedValues = null;
      }
      //If existing location is not mapped with selected subsidiary, location will not display in dropdown.
      else if (location.length === 0 && !isSubsidiaryHasLocations()) {
        // Fetch inactive locations from the API and enable them to be displayed in the location dropdown.
        try {
          const result = await restApiService.get("locations/" + input.value);
          const inactiveLocation = {
            value: result.data.id,
            label: (
              <>
                {result.data.name} - <small style={{ fontSize: "10px" }}>({result.data.status})</small>
              </>
            ),
          };
          selectedValues = inactiveLocation;
        } catch (error) {
          console.log(error);
          CreateNotification("Error", "Problem loading inactive location.", NotificationType.danger);
        }
      } else {
        selectedValues = location[0];
      }
      return selectedValues ? selectedValues : null;
    }
  };

  const onChangeLocation = (value: Record<string, any> | null) => {
    if (value?.value || value?.id) {
      input.onChange(value?.value || value?.id);
      setSelected(value);
      if (callBack) {
        callBack(value);
      }
    } else {
      input.onChange(null);
      setSelected(null);
      if (callBack) {
        callBack(null);
      }
    }
  };

  useEffect(() => {
    getLocations().then((res) => {
      findSelected(input, res).then((response) => {
        setSelected(response);
      });
    });
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, [parentObjData?.subsidiary_id, modelData?.business_unit_id, input?.value]);

  useEffect(() => {
    if (onSelectCallback) {
      onSelectCallback(input.value);
    }
  }, [input.value]);

  const checkIsClearable = useCallback(() => {
    if (required) {
      return false;
    } else if (isDefined(isClearable)) {
      return isClearable;
    } else if (_.isUndefined(isClearable)) {
      return false;
    }
  }, [isClearable, required]);

  const pickerProps = {
    ...input,
    required,
    placeholder,
    options: locations,
    isClearable: true,
    isDisabled: disabled,
  };

  return (
    <Form.Group className={Styles.select} ref={selectRef}>
      {label && (
        <Form.Label className={labelClassName}>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      {!label && showAsteriskWithoutLabel && required && (
        <div className="d-flex justify-content-end">
          <Mandatory required={required} />
        </div>
      )}

      <Select
        {...pickerProps}
        menuPlacement="auto"
        menuPosition="fixed"
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        className={className}
        value={selected}
        required={false}
        isClearable={checkIsClearable()}
        onChange={(value) => onChangeLocation(value as any)}
        onBlur={null}
        getOptionLabel={(option: LocationsListOptionsType) => {
          return (
            <>
              <span className="formField">{option.label}</span>
              {option.status === "INACTIVE" && <small style={{ fontSize: "10px" }}> -({option.status})</small>}
            </>
          );
        }}
        filterOption={(option: any, searchText) =>
          option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
        }
        classNamePrefix="select"
        instanceId={instanceId} // don't remove or change used in automation testing
      />
      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default memo(LocationPicker);
