import { DataTable } from "components/common/wrapperComponents";
import React, { useEffect, useState } from "react";
import { IDType } from "services/common/types/common.type";
import style from "./approval.module.css";
import _ from "lodash";
import { restApiService } from "providers/restApi";

type PreviewApprovalStepsPropsType = {
  endpoint: string;
  approvableId: IDType;
};

const PreviewApprovalSteps = ({ endpoint, approvableId }: PreviewApprovalStepsPropsType) => {
  const [workflows, setWorkflows] = useState<any[]>();

  const getWorkflows = async () => {
    try {
      const result = await restApiService.get(`${endpoint}/${approvableId}/preview_approvals`);
      setWorkflows(result.data);
    } catch (error) {}
  };

  useEffect(() => {
    getWorkflows();
  }, []);

  return (
    <div>
      <DataTable striped={false} hover={false} className={"text-center " + style.approvalTable}>
        <thead>
          <tr>
            <th>Approval Steps</th>
            <th>Approvers</th>
          </tr>
        </thead>
        <tbody>
          {_.isArray(workflows) &&
            workflows.map((workflow) => (
              <tr>
                <td>
                  {workflow?.approval_workflow_label && (
                    <div>
                      {workflow?.approval_workflow_label}
                      {workflow?.approval_workflow_any_member && (
                        <span>
                          <br />
                          <small>(Any Member)</small>
                        </span>
                      )}
                    </div>
                  )}
                </td>
                <td>
                  {workflow?.name} <br />
                  {workflow?.email ? "<" + workflow?.email + ">" : ""}
                  {workflow.delegate_name && (
                    <span>
                      <br />
                      {workflow.delegate_name} (Delegate) <br />
                      {workflow.delegate_email ? "<" + workflow.delegate_email + ">" : ""}
                    </span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </DataTable>
    </div>
  );
};

export default PreviewApprovalSteps;
