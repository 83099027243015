const INITIAL = {
  name: "Mehul",
};

export default (state = INITIAL, action) => {
  switch (action.type) {
    case "CHANGESTOREVALUES":
      return {
        ...state,
        name: action.payload,
      };

    default:
      return state;
  }
};
