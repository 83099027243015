import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import React from "react";

const BreadcrumbTrail = (props) => {
  return (
    <div
      className={
        "no-print d-flex justify-content-start align-content-center  pr-1 breadcrumb-section  " + props.classes
      }
    >
      <Link className={"navigation"} to={props.to}>
        <Nav.Item className={"breadcrumb-title pr-1 mr-1"}>{props.pageName}</Nav.Item>
      </Link>

      {props.title && <p className="breadcrumb-label">/ {props.title}</p>}
    </div>
  );
};
export { BreadcrumbTrail };
