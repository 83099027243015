import React from "react";
import { Field, InjectedFormProps, formValueSelector, reduxForm } from "redux-form";
import styles from "./customLabelsUpsert.module.css";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "components/common/modals/confirmation";
import { CustomLabel } from "pages/admin/administration/customLabels/customLabels";
import GenericSwitch from "../pickers/inputs/genericSwitch";
import { RootState, useTypedSelector } from "reducers";
import { required } from "services/validations/reduxFormValidation";
import { RenderField } from "components/forms/bootstrapFields";
import GenericSelect from "../pickers/inputs/genericSelect";

const formName = "customLabelsForm";

interface CustomLabelsUpsertProps {
  form?: string;
  deleteCallback?: any;
  fieldChoices?: { value: string; label: string }[];
  initialValues?: CustomLabel;
  onFieldSelect: (oldVal: string, newVal: string) => void;
}

type Props = CustomLabelsUpsertProps & InjectedFormProps<{}, CustomLabelsUpsertProps>;

const CustomLabelsUpsert: React.FC<Props> = ({
  form,
  handleSubmit,
  fieldChoices,
  initialValues,
  deleteCallback,
  onFieldSelect,
}) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  let statusValue = useTypedSelector((state: RootState) => formValueSelector(form)(state, "status"));

  const handleFieldSelect = (oldVal: string, newVal: string) => {
    onFieldSelect(oldVal, newVal);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex">
        <Field
          className={styles.fieldStyle}
          name="field_name"
          component={GenericSelect}
          options={fieldChoices}
          placeholder="Field Name"
          label={t("fieldName")}
          isRequired={true}
          onChange={(...e: any) => {
            const oldVal = e[2];
            const newVal = e[0];
            handleFieldSelect(oldVal, newVal);
          }}
          validate={[required]}
        />

        <Field
          name="name"
          className={styles.fieldStyle}
          component={RenderField}
          required={true}
          placeholder="Custom Label"
          label={t("admin.pages.customLabels.customLabel")}
          validate={[required]}
        />

        {/* *****---feature-flag: disabled for now----******
                    *****---discussion to be had in future to include or not---*****
                    <Field
                    name="required"
                    options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                    ]}
                    component={GenericSelect}
                    isMulti={false}
                    placeholder="Required"
                    className={styles.fieldStyle}
                    isRequired={true}
                    label={t("required")}
                /> 
                */}

        <Field
          name="status"
          options={[{ value: "ACTIVE" }, { value: "INACTIVE" }]}
          component={GenericSwitch}
          className={styles.switchStyle}
          label={
            statusValue && statusValue === "ACTIVE"
              ? t("admin.pages.customLabels.enabled")
              : t("admin.pages.customLabels.disabled")
          }
        />

        <div className="d-flex justify-content-end" style={{ marginTop: "40px" }}>
          <i className={`icon icon-delete ${styles.deleteIconStyle}`} onClick={() => setShowDeleteModal(true)} />
        </div>

        <ConfirmationModal
          title={t("admin.pages.customLabels.delete")}
          show={showDeleteModal}
          saveCallback={() => {
            setShowDeleteModal(false);
            deleteCallback(initialValues.id);
          }}
          closeCallback={() => {
            setShowDeleteModal(false);
          }}
          confirmText={t("yes")}
          cancelText={t("no")}
          body={t("admin.pages.customLabels.deleteConfirm")}
        />
      </div>

      <hr></hr>
    </form>
  );
};

export default reduxForm<{}, CustomLabelsUpsertProps>({
  form: formName,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(CustomLabelsUpsert);
