import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import RestApi from "../../../../providers/restApi";
import { useTranslation } from "react-i18next";
import Panel from "../../../panel/panel";
import VerifyContactDetails from "./verifyContactDetails";
import RejectContactDetailModal from "./rejectContactDetailModal";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { ApprovePendingContact } from "../../../../services/contacts/contactService";
import useIsMounted from "../../../common/hooks/useIsMounted";
import TooltipRender from "../../../toolTip/tooltipRender";
import SplitButton from "../../../common/buttons/splitButton";
import styles from "./verifyData.module.css";
import SuccessContactModal from "./successContactModal";
import _ from "lodash";
import ContactApis from "../../../../services/common/contact/contactApis";

const restApiService = new RestApi();

type propTypes = {
  callback?: () => void;
};

export type contactType = {
  id?: number;
  company: {
    email: string;
    id: number;
    name: string;
  };
  contact_id: string;
  vendor_id: number;
  status: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  contact_type: string;
  department: string;
  properties: number;
  user_id: number;
  has_vendor_login: boolean;
  vendor_admin?: boolean;
};

const VerifyDataTable = ({ callback }: propTypes) => {
  const [loaded, setLoaded] = useState(false);
  const [verifyData, setVerifyData] = useState<Array<contactType>>([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedContact, setSelelctedContact] = useState<contactType>();
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted.current) {
      getContactsToVerify();
    }
  }, []);

  const getContactsToVerify = async () => {
    try {
      const response = await restApiService.get("vendor_contacts", {
        status: "PENDING",
      });
      setVerifyData(response.data);
      setLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const approve = (data: contactType, rejectedContact: null, sendWelcomeEmail: any = null) => {
    ApprovePendingContact(data, rejectedContact).then(async (result) => {
      setShowSuccessModal(false);
      setShowRejectModal(false);

      let isSetToAdmin = false;
      if (result.status === 200 && data.vendor_admin && result.data.linked_contact_id) {
        try {
          const response = await ContactApis.addVendorAdminRole(result.data.linked_contact_id);
          if (response) {
            isSetToAdmin = true;
          }
        } catch (err) {
          console.log(err);
        }
      }

      if (callback) {
        callback();
      }
      if (result.status === 200) {
        CreateNotification(
          "Success",
          "Contact Verified" + (isSetToAdmin ? " and set to Admin" : ""),
          NotificationType.success,
        );
        if (sendWelcomeEmail) {
          restApiService
            .post("contacts/" + result.data.linked_contact_id + "/vendor_portal_send_welcome")
            .then((result) => {
              if (result.status === 200) {
                setShowSuccessModal(true);
              }
            });
        } else {
          getContactsToVerify();
        }
      } else {
        console.log(result);
        CreateNotification("Error", "Unknown error occured. Please contact support", NotificationType.danger);
      }
    });
  };

  const deny = (contact: React.SetStateAction<contactType | undefined>) => {
    setShowRejectModal(true);
    setSelelctedContact(contact);
  };

  const accept = (contact: contactType) => {
    if (contact?.has_vendor_login) {
      approve(contact, null, null);
    } else {
      setSelelctedContact(contact);
      approve(contact, null, true);
    }
  };

  const updateRole = (value: string, data: contactType) => {
    let isTrue = value == "true";
    setVerifyData((prevState) => prevState.map((obj) => (obj.id === data.id ? { ...obj, vendor_admin: isTrue } : obj)));
  };

  const handleSuccessCallback = () => {
    getContactsToVerify();
    setShowSuccessModal(false);
  };

  if (!loaded || !verifyData || verifyData.length < 1) {
    return null;
  }

  return (
    <Panel
      panelContent={{ heading: t("verifyDataTable.heading") }}
      cardBodyStyle={{ padding: 0 }}
      cardClasses={"animated fadeIn"}
    >
      <Table striped bordered hover className="mb-0">
        <thead>
          <tr>
            <th className="primaryFontColor">{t("verifyDataTable.dataType")}</th>
            <th className="primaryFontColor">{t("details.customer")}</th>
            <th className="primaryFontColor">{t("verifyDataTable.details")}</th>
            <th className="d-flex justify-content-center">
              {t("verifyDataTable.role")}
              <TooltipRender
                title="Admin users have full control, while staff have limited access and permissions."
                className="icon icon-info-blue"
                placement="top"
              />
            </th>
            <th className="primaryFontColor"></th>
          </tr>
        </thead>
        <tbody>
          {_.isArray(verifyData) &&
            verifyData.map((data) => {
              return (
                <tr key={data.id}>
                  <td>Contact</td>
                  <td>{data.company && data.company.name}</td>
                  <td>
                    <VerifyContactDetails contact={data} />
                  </td>
                  <td>
                    <SplitButton
                      customBtnClass={styles.splitButton}
                      customToggleBtnClass={styles.toggleButton}
                      title={data.vendor_admin ? "Admin" : "Staff"}
                      value={data.vendor_admin ? "true" : "false"}
                      options={[
                        { value: "false", label: "Staff" },
                        { value: "true", label: "Admin" },
                      ]}
                      onSelectCallback={(value) => updateRole(value, data)}
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      className="circleIconButtons approveCircleIcon"
                      onClick={() => {
                        accept(data);
                      }}
                    ></button>{" "}
                    <button
                      type="button"
                      className="circleIconButtons rejectCircleIcon"
                      onClick={() => {
                        deny(data);
                      }}
                    ></button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      {showRejectModal && (
        <RejectContactDetailModal
          contact={selectedContact!}
          saveCallback={(contact: { raw: any }) => {
            approve(selectedContact!, contact.raw);
          }}
          show={showRejectModal}
          closeCallback={() => {
            setShowRejectModal(false);
          }}
        />
      )}

      {showSuccessModal && (
        <SuccessContactModal
          selectedContact={selectedContact}
          show={showSuccessModal}
          closeCallback={() => {
            setShowSuccessModal(false);
          }}
          successCallback={handleSuccessCallback}
        />
      )}
    </Panel>
  );
};

export default VerifyDataTable;
