import React, { useState } from "react";
import { IDType } from "../../../../../services/common/types/common.type";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import { AccountNumberValidator } from "../../accountNumberValidator";
import BankRoutingNumber from "../../../pickers/reduxPickers/bankRoutingNumber";
import IbanValidator from "../../ibanValidator";
import BicValidator from "../../bicValidator";
import { required } from "../../../../forms/inputFields";
import PurposeCode from "./purposeCode";

const WireUah = ({ modelName }: { modelName?: string }) => {
  const [ibanNumber, setIbanNumber] = useState<IDType>();
  const { t } = useTranslation();

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
        />
      </Col>
      <Col md="6">
        <AccountNumberValidator
          modelName={`${modelName}`}
          maxLength={20}
          noValidate={true}
          label={t("common.paymentMethod.accountNumberIfNoIban")}
        />
      </Col>
      <Col md="6">
        <IbanValidator
          modelName={`${modelName}`}
          callBack={(value) => setIbanNumber(value)}
          isNotRequired={true}
          label={t("common.paymentMethod.iBanIfNoAcct")}
        />
      </Col>
      <Col md="4">
        <BicValidator modelName={`${modelName}`} ibanNumber={ibanNumber} />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.routing`}
          component={BankRoutingNumber}
          type="text"
          label={t("common.paymentMethod.bankRoutingNumber")}
          className={`w-100`}
        />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="12">
        <PurposeCode />
      </Col>
    </>
  );
};

export default WireUah;
