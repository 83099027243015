import React, { Component } from "react";
import { Container } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import draftExpenseItemHeader from "../../../components/datagrid/girdHeaders/draftExpenseItemsGridHeader";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-theme-balham.css";
import { calculateGridHeight } from "../../../services/grid/gridHeight";
import { SideBarConfig, ColumnConfig, DateFormatter } from "../../../services/common/gridService";
import ChildCellRouting from "../../../components/datagrid/childCellRouting.jsx";
import RandomChildCellRouting from "../../../components/datagrid/randomCellChildRouting";
import ChildMessageRenderer from "../../../components/datagrid/childMessageRenderer.jsx";
// import CustomStatsToolPanel from "../../../components/datagrid/customStatsToolPanel.jsx";
import CustomStatToolPanel from "../../../components/common/dataGrid/customStatToolPanel";
import FloatingFilterComponent from "../../../components/datagrid/floatingFilterComponent.jsx";
import { Button, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useTypedSelector } from "../../../reducers";
import { IUser } from "services/common/user/userTypes";
import { connect } from "react-redux";
import NavTabs from "./../../../components/admin/expensese/nav";

class DraftExpenseItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    //since we are using dynamic grid height we need to set it when the component is done loading
    this.setState({ gridHeight: calculateGridHeight(this.gridElement.getBoundingClientRect().y) + "px" });
  }

  gridReady = (params) => {
    this.setState({ gridApi: params.api });
    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: this.getRows });
  };

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  getRows = (params) => {
    //parameters to send to the api
    let getParams = {
      //grid will pass in the first index and last index that needs to be fetched
      items: params.request.endRow - params.request.startRow,
      //default page size is 100 items
      page: 1 + params.request.startRow / 100,
    };

    this.restApiService
      .get("expense_items/draft_inbox", getParams)
      .then((result) => {
        if (result.data) {
          //we pass in the data we got from the API and the total row count so the grid knows how big to make the scrollbar
          params.success({ rowData: result.data, rowCount: result.data.length });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { currentUser } = this.props;
    return (
      <Container fluid={true} className={"pt-4 pb-4"}>
        <Row>
          <Col xs={10}>
            <NavTabs activePageName="Draft" />
          </Col>
        </Row>
        <div
          ref={(gridElement) => {
            this.gridElement = gridElement;
          }}
          style={{ height: this.state.gridHeight, width: "100%" }}
          className="ag-theme-fresh animated fadeIn"
        >
          <AgGridReact
            columnDefs={draftExpenseItemHeader({ currentUser })}
            defaultColDef={{
              floatingFilter: this.state.showingFilter,
            }}
            rowModelType="serverSide"
            onGridReady={this.gridReady}
            resizable={true}
            rowHeight={ColumnConfig.rowHeight}
            floatingFiltersHeight={ColumnConfig.floatingFiltersHeight}
            components={{
              childMessageRenderer: ChildMessageRenderer,
              childCellRouting: ChildCellRouting,
              randomChildCellRouting: RandomChildCellRouting,
              customStatsToolPanel: CustomStatToolPanel,
              floatingFilterComponent: FloatingFilterComponent,
            }}
            sideBar={SideBarConfig}
          />
        </div>
      </Container>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.user,
  };
};

export default withTranslation()(connect(mapStateToProps)(DraftExpenseItem));
