import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import styles from "../../vendors.module.css";
import { useTranslation } from "react-i18next";
import { companyDateFormat } from "services/general/dateSvc";
import { useTypedSelector } from "reducers";

type AdditionalCodingFieldInfoType = {
  vendor: any;
  handleEdit: (step: number) => void;
};

type MetadataConfiguration = {
  id: number;
  name: string;
};

type MetadataLink = {
  metadata_configuration_id: number;
  value: string;
};

type TransformedMetadata = {
  metadata_configuration_id: number;
  name: string;
  value: string;
};

const AdditionalCodingFieldInfo = ({ vendor, handleEdit }: AdditionalCodingFieldInfoType) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const currentUser = useTypedSelector((state) => state.user);

  const metadataConfigurations = vendor?.metadataForm?.metadata_configurations;
  const metadataLinks = vendor?.metadata_links_attributes;

  const metadataArray: TransformedMetadata[] = metadataLinks?.map((link: MetadataLink) => {
    let configuration = Object.values(metadataConfigurations).find(
      (config: any): config is MetadataConfiguration => config?.id === link?.metadata_configuration_id,
    );

    return {
      metadata_configuration_id: link?.metadata_configuration_id,
      name: configuration?.name,
      value: link?.value === "Select One" ? "-" : link?.value,
    };
  });

  const capitalizeInitials = (string: string) => {
    return string?.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const nameExists = metadataArray?.some((item) => item.name);

  return (
    <>
      <Row>
        <Col md="12">
          <i className={`icon icon-vendors ${styles.additionalCodingIcon}`}></i>
          <span className={styles.title}>{t("admin.pages.vendor.additionalCodingFields")}</span>
          <div className="float-right">
            <Button variant="secondary" className={`px-mr-10 ${styles.btnEdit}`} onClick={() => handleEdit(3)}>
              <i className="icon icon-edit px-m-0"></i>
              {t("admin.pages.vendor.edit")}
            </Button>
            <span className={`float-right ${styles.iconToggle}`}>
              <i
                className={toggle ? "icon icon-arrow-up" : "icon icon-arrow-down"}
                onClick={() => setToggle(!toggle)}
              ></i>
            </span>
          </div>
        </Col>
      </Row>
      {toggle && (
        <Row className={`px-mt-25 ${styles.vendorInfoSection}`}>
          {nameExists &&
            metadataArray.map((metadata: TransformedMetadata, index: number) => (
              <Col md="3" key={index}>
                <label>{capitalizeInitials(metadata.name)}</label>
                <span>{metadata.value || "-"}</span>
              </Col>
            ))}
        </Row>
      )}
    </>
  );
};

export default AdditionalCodingFieldInfo;
