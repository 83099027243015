import React from "react";
import { TabNavigation } from "wombatifier/components/navigation/tabNavigator";

//NOTE: add only those route which we want to show on tabNavigations
export const administrationModulesRoutes = [
  {
    path: "/ap/approval_workflows",
    pageName: "Approval Settings",
    authorization: {
      I: "allow",
      a: "ApprovalSetting",
    },
  },
  {
    path: "",
    pageName: "Categories",
    href: "categories",
  },
  {
    path: "/ap/mcc_defaults",
    pageName: "MCC Defaults",
  },
  {
    path: "",
    pageName: "Activities",
    href: "activities",
    authorization: {
      I: "activities",
      a: "Administrations",
    },
  },
  {
    path: "",
    pageName: "Catalogue Items",
    href: "catalogue_items",
    authorization: {
      I: "list",
      a: "CatalogueItems",
    },
  },
  {
    path: "",
    pageName: "Bulk Operations",
    href: "imports",
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/bulk_uploads/add",
    pageName: "Bulk Operations 2.0",
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/wombatifier",
    pageName: "Bulk Operations Mapper",
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/metadata_configurations/edit",
    pageName: "Form Configuration",
    authorization: {
      I: "metadata",
      a: "_visible",
    },
  },
  {
    path: "",
    pageName: "Transactions",
    href: "journal",
    authorization: {
      I: "list",
      a: "Transactions",
    },
  },
  {
    path: "/ap/fraud_audits",
    pageName: "Fraud Audit",
    authorization: {
      I: "allow",
      a: "SystemRole",
    },
  },
  {
    path: "",
    pageName: "Company Profile",
    href: "company",
    authorization: {
      I: "read",
      a: "companyProfile",
    },
  },
  {
    path: "/ap/settings",
    pageName: "Settings",
    authorization: {
      I: "allow",
      a: "CompanySettings",
    },
  },
];

export const NavTabs = ({ activePageName }: { activePageName: string }) => {
  return <TabNavigation activePageName={activePageName} routes={administrationModulesRoutes} />;
};
