const INITIAL_STATE = {
  float: "Khan",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FLOATFILTER":
      return {
        ...state,
        float: action.payload,
      };

    default:
      return state;
  }
};
