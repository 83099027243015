import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.css";
import Panel from "components/common/panel/panel";
import { Col, Row } from "react-bootstrap";
import ExpenseCategoryTable from "./expenseCategoryTable";
import ExpenseStatusBar from "./expenseStatusBar";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { getFormValues } from "redux-form";
import { useSelector } from "react-redux";
import dashboardColorMap from "./expenseDashboardColorMap";
import { useTranslation } from "react-i18next";

type formType = { subsidiary_id?: number; expense_type?: { value: string; label: string }; subsidiaryName?: string };

type formDataType = {
  posted_date: { value: string; label: string };
  posted_filter_data?: { monthName?: string; startDate: string; endDate: string; selectedOption: string };
  posted_date_custom: { startDate: string; endDate: string; selectedOption: string };
};

type ExpenseCategoryType = {
  dateRange: string;
};
const ExpenseCategory = ({ dateRange }: ExpenseCategoryType) => {
  const { t } = useTranslation();
  const [categoryData, setCategoryData] = useState<ExpensesTypes.ExpenseCategoryType[]>([]);
  const [totalSpend, setTotalSpend] = useState<number>(0);
  const [totalExpense, setTotalExpense] = useState<number>(0);
  const [selectedBarIndex, setSelectedBarIndex] = useState<number | undefined>();
  const expenseCategoryForm: formDataType = useSelector((state) =>
    getFormValues("expenseCategoryForm")(state),
  ) as formDataType;
  const expenseDashboardForm: formType = useSelector((state) => getFormValues("expenseDashboardForm")(state));
  const subsidiaryId = expenseDashboardForm?.subsidiary_id;
  const expenseType = expenseDashboardForm?.expense_type;
  const colorMap = new dashboardColorMap();
  const colorsArray = colorMap.getDashboardColorScheme().colorsArray;

  useEffect(() => {
    if (expenseCategoryForm?.posted_filter_data !== undefined) {
      getCategorizeSpendsRecord();
    }
  }, [expenseCategoryForm?.posted_filter_data, subsidiaryId, expenseType]);

  const getCategorizeSpendsRecord = async () => {
    try {
      let totalSpend = 0;
      let totalExpense = 0;
      let filter: { date_before?: string; date_after?: string; subsidiary_ids?: number; reimbursable?: string } = {};
      if (expenseCategoryForm?.posted_filter_data?.endDate) {
        filter.date_before = expenseCategoryForm?.posted_filter_data?.endDate;
      }
      if (expenseCategoryForm?.posted_filter_data?.startDate) {
        filter.date_after = expenseCategoryForm?.posted_filter_data?.startDate;
      }
      if (subsidiaryId) {
        filter.subsidiary_ids = subsidiaryId;
      }
      if (expenseType?.value && expenseType.value !== "all") {
        filter.reimbursable = expenseType.value;
      }
      const result = await ExpensesApis.getCategorizeSpends({ filter: filter });
      if (result) {
        result.map((item, index) => {
          item.refId = item?.category_name ? item.category_name.trim() : `expenseCategory-${index}`;
          return item;
        });
        result.sort((a, b) => a.total_spent - b.total_spent);
        result.reverse();
        setCategoryData(result);
        result.forEach((item) => {
          totalSpend += item.total_spent;
          totalExpense += item.expenses_count;
        });
        setTotalSpend(totalSpend);
        setTotalExpense(totalExpense);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectedBarIndexHandler = (value: number | undefined) => {
    setSelectedBarIndex(value);
  };

  const categorySortHandler = (data: ExpensesTypes.ExpenseCategoryType[]) => {
    setCategoryData(data);
  };

  return (
    <div>
      <Panel
        cardClass={styles.categoryContainerPanelWrapper}
        cardBodyClass={styles.categoryContainerBodyPanel}
        cardFooterClass={styles.panelFooter}
        header={
          <div>
            <i className={`icon ${styles.iconHeading} icon-green-spend px-mt-3 px-mr-3`}></i>
            {t("admin.pages.expenseItem.labels.expensebyCategory")}
          </div>
        }
        cardHeaderClass={styles.expensePanelHeader}
      >
        {categoryData.length ? (
          <>
            <Row className={`${styles.expenseCategoriesItems}`}>
              <Col className={`${styles.yearClass}`}>
                <span className={`${styles.categoryHeaderText}`}>{`${dateRange ? dateRange : ""}`}</span>
              </Col>
            </Row>
            <Row className={`${styles.expenseCategoriesItems} ${styles.expenseBarContainer}`}>
              <ExpenseStatusBar
                setHighlightRow={selectedBarIndexHandler}
                totalSpend={totalSpend}
                categoryData={categoryData}
                highLightRow={selectedBarIndex}
                colorArray={colorsArray}
              />
            </Row>
            <Row className={`${styles.expenseCategoriesItems}`}>
              <ExpenseCategoryTable
                categorySortHandler={categorySortHandler}
                setHighlightRow={selectedBarIndexHandler}
                highLightRow={selectedBarIndex}
                categoryData={categoryData}
                totalSpend={totalSpend}
                totalExpense={totalExpense}
                colorArray={colorsArray}
              />
            </Row>
          </>
        ) : (
          <Row className="justify-content-center">
            {/* TODO:  Not confirmed for empty record */}
            <span className={`${styles.categoryHeaderText} text-center w-100`}>No Expense Category Found </span>
          </Row>
        )}
      </Panel>
    </div>
  );
};

export default ExpenseCategory;
