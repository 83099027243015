import React from "react";
import CustomModal from "../../../modals/customModal";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../contact.module.css";
import { contactType } from "./verifyDataTable";

type SuccessContactModalPropsType = {
  show: boolean;
  selectedContact?: contactType;
  closeCallback: () => void;
  successCallback: () => void;
};

const SuccessContactModal = ({
  show,
  closeCallback,
  successCallback,
  selectedContact,
}: SuccessContactModalPropsType) => {
  const { t } = useTranslation();
  return (
    <CustomModal
      show={show}
      backdrop={true}
      size="lg"
      onHide={closeCallback}
      header={"Vendor Contact Added"}
      modalBodyClass="pb-0 mb-0"
      modalClass={`mt-2 ${styles.acceptModal}`}
      body={
        <Row>
          <Col>
            <p className={`text-body ${styles.acceptModalBody}`}>
              {selectedContact?.name} ({selectedContact?.email}) has been added as a contact.
            </p>
            <p className={`mt-3 ${styles.acceptModalBody}`}>
              They have received an email with instructions on how to access the portal. Based on the permissions, they
              will be able to track customer PO, submit invoices, submit W-9 and other documents and track payment
              details.
            </p>
          </Col>
        </Row>
      }
      footer={
        <Row>
          <Col xs="12">
            <Button className="mr-3 px-4 py-2" variant="secondary" onClick={successCallback}>
              {t("ok")}
            </Button>
          </Col>
        </Row>
      }
    ></CustomModal>
  );
};

export default SuccessContactModal;
