import { InputField } from "components/forms/hookFormFields";
import React, { ChangeEvent, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import { isDefined } from "services/general/helpers";

const UploadQueueAmount = () => {
  const invoiceCommonHookSvc = useInvoiceCommonSvc();
  const { getCustomLabel } = useCustomLabel();
  const { getValues, setValue } = useFormContext<InvoiceType.InvoiceDetailType>();

  const isTotalAmountDisable =
    invoiceCommonHookSvc.isItemsExist().length > 0 || invoiceCommonHookSvc.isExpenseLineExist().length > 0;

  // Handle manual input for amount
  const onAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const invoice = getValues();
    const newAmount = Number(e.target.value);
    invoice.amount = newAmount;
    setValue("amount", newAmount);
    invoiceCommonHookSvc.calculateDiscountAmt();
    invoiceCommonHookSvc.updateCreditEntries();
  };

  const getInvoiceTotalAmount = () => {
    let amount = invoiceCommonHookSvc.calculateItemsTotal();
    amount = amount + invoiceCommonHookSvc.getAccountsTotal();
    amount = invoiceCommonHookSvc.roundUpAmount(amount);
    return amount;
  };

  useEffect(() => {
    if (isTotalAmountDisable) {
      const totalAmount = invoiceCommonHookSvc.getInvoiceTotalAmount();
      if (isDefined(totalAmount) && totalAmount >= 0) {
        setValue("amount", totalAmount); // Set the total amount to the form field
        invoiceCommonHookSvc.calculateDiscountAmt();
      }
      invoiceCommonHookSvc.updateCreditEntries();
    }
  }, [getInvoiceTotalAmount]);

  return (
    <>
      {/* Amount field, read-only if items exist */}
      <Col md="6" className="mb-3">
        <InputField
          id="invoice_amount"
          name="amount"
          label={getCustomLabel("admin.pages.invoice.amount")}
          type="number"
          onChange={onAmountChange}
          value={isTotalAmountDisable ? getInvoiceTotalAmount() : undefined}
          disabled={isTotalAmountDisable}
          required
        />
      </Col>
    </>
  );
};

export default UploadQueueAmount;
