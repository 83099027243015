export const SCHEDULE_INTERVALS_OPTIONS = [
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
  //{ label: 'Quarterly', value: "QUARTERLY" }
];

export const DAY_OF_WEEK_OPTIONS = [
  { label: "Every Day", value: 7 },
  { label: "Weekdays", value: 12345 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 0 },
];

const HOURS = [12, ...Array.from({ length: 11 }).map((value, index) => index + 1)];

export const HOURS_OF_DAY_OPTIONS = [
  ...HOURS.map((i, index) => ({ label: `${i < 10 ? `0${i}` : i}:00 AM PST`, value: index })),
  ...HOURS.map((i, index) => ({ label: `${i < 10 ? `0${i}` : i}:00 PM PST`, value: index + 12 })),
];

export class SchedulesSvc {}
