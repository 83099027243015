import React from "react";
import DropdownFilter from "../reportFilters/dropdown";

const ScheduledDateRange = ({
  name,
  code,
  options,
  isRequired,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  isRequired?: boolean;
}) => {
  const scheduleDateRange = [
    { value: "last_1_days", label: "Past 1 Day" },
    { value: "last_7_days", label: "Past 7 Days" },
    { value: "last_30_days", label: "Past 30 Days" },
    { value: "last_90_days", label: "Past 90 Days" },
    { value: "last_180_days", label: "Past 180 Days" },
    { value: "last_365_days", label: "Past 365 Days" },
    { value: "current_week", label: "Current Week" },
    { value: "last_1_days_and_future", label: "Past 1 Day + All Future Dates" },
    { value: "last_7_days_and_future", label: "Past 7 Days + All Future Dates" },
    { value: "last_30_days_and_future", label: "Past 30 Days + All Future Dates" },
    { value: "last_90_days_and_future", label: "Past 90 Days + All Future Dates" },
    { value: "last_365_days_and_future", label: "Past 365 Days + All Future Dates" },
    { value: "current_month", label: "Current Month" },
    { value: "current_quarter", label: "Current Quarter" },
    { value: "current_year", label: "Current Year" },
    { value: "previous_week", label: "Previous Week" },
    { value: "previous_month", label: "Previous Month" },
    { value: "previous_quarter", label: "Previous Quarter" },
    { value: "previous_year", label: "Previous Year" },
  ];

  return <DropdownFilter name={name} code={code} options={scheduleDateRange} isRequired={isRequired} />;
};

export default ScheduledDateRange;
