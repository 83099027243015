import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import { WrappedFieldProps } from "redux-form";
import useIsMounted from "components/common/hooks/useIsMounted";

interface EventCodePickerPropsType extends WrappedFieldProps {
  label?: string;
  input: any;
  required?: boolean;
  tooltip?: string;
  disabled?: boolean;
  floating?: boolean;
  instanceId?: string;
}

type EventCodeObjType = {
  id?: number | string;
  location_id?: number;
  name?: string;
  status?: string;
  label?: string;
  external_id?: string;
  created_at?: string;
  updated_at?: string;
};

const EventCodePicker = ({
  label,
  input,
  required,
  tooltip,
  disabled,
  floating = false,
  instanceId = "event-code-picker",
}: EventCodePickerPropsType) => {
  const [eventCodes, setEventCodes] = useState<EventCodeObjType[]>([]);
  const isMounted = useIsMounted();
  const [selected, setSelected] = useState<EventCodeObjType>();

  const parseForSelect = (options: EventCodeObjType[]) => {
    return options.map((option: EventCodeObjType) => {
      return {
        value: option.id,
        label: option.name,
        status: option.status,
      };
    });
  };

  const getEventCodes = useCallback(async () => {
    const result: AxiosResponse = await restApiService.get(`event_codes`, { status: "ACTIVE" }, null, true, null, true);
    if (result?.data) {
      let list = parseForSelect(result?.data);
      if (isMounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Event Code --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setEventCodes(list);
      }
      return list;
    }
  }, []);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        const result = await restApiService.get("event_codes/" + input.value);
        const inactiveEventCodes = {
          value: result.data.id,
          label: (
            <>
              {result.data.name}
              {result.data.status === "INACTIVE" && (
                <small style={{ fontSize: "10px" }}> -({result.data.status})</small>
              )}
            </>
          ),
        };
        selectedValues = inactiveEventCodes;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    getEventCodes().then((res) => {
      findSelected(input, res).then((response: EventCodeObjType) => {
        setSelected(response);
      });
    });
  }, []);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={selected}
          onChange={(selected) => onChangeSingle(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={eventCodes}
          disable={disabled}
          isClearable={!required}
          getOptionLabel={(option: EventCodeObjType) => {
            return (
              <>
                <span className="formField">{option.label}</span>
                {option.status === "INACTIVE" && <small style={{ fontSize: "10px" }}> -({option.status})</small>}
              </>
            );
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
            }),
            ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
          }}
          {...(floating ? { menuPortalTarget: document.body } : {})}
          instanceId={instanceId}
        />
      </Form.Group>
    </>
  );
};

export default EventCodePicker;
