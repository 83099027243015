import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PurchaserApis from "../../../services/vp/purchaser/purchaserApis";
import { CompanyDetailType } from "../../../services/common/company/companyTypes";
import { PurchaserListType } from "../../../services/vp/purchaser/purchaserTypes";
import companyApis from "../../../services/common/company/companyApis";
import { useLocation } from "react-router";
import * as actionType from "../../../actions/actionTypes";
import ConfirmationModal from "../../common/modals/confirmation";
import { useTranslation } from "react-i18next";
import BankInfo from "./bankInfo";
import SuccessModal from "../../common/modals/success";
import { PaymentMethodType } from "../../../services/common/paymentMethod/paymentMethodsTypes";
import { useDispatch } from "react-redux";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import ErrorBoundary from "../../common/errorBoundary/errorBoundary";

type DetailBankingPropsType = {};

const DetailBanking = (props: DetailBankingPropsType) => {
  const [companyDetail, setCompanyDetail] = useState<CompanyDetailType>();
  const [purchasers, setPurchasers] = useState<PurchaserListType[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSucessModal] = useState<boolean>(false);
  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  const [showPaymentFormNew, setShowPaymentFormNew] = useState<boolean>(false);
  const [paymentMethodId, setPaymentMethodId] = useState<number>();
  const [deletepaymentMethodId, setDeletePaymentMethodId] = useState<number>();

  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const toggleShowPaymentForm = (paymentMethod: PaymentMethodType) => {
    dispatch({ type: actionType.CLEAR_PAYMENT_METHOD });
    dispatch({ type: actionType.CLEAR_VALIDATIONS });
    dispatch({ type: actionType.EDIT_PAYMENT_METHOD, payload: paymentMethod });
    setShowPaymentForm((prev) => !prev);
    setShowPaymentFormNew(false);
    setPaymentMethodId(paymentMethod.id);
  };

  const openPaymentMethodEdit = () => {
    const { pm }: any = location.state;
    toggleShowPaymentForm(pm);
  };

  const getPurchaser = async () => {
    try {
      const result = await PurchaserApis.getPurchaserList();
      setPurchasers(result);
    } catch (error) {
      console.error("Error fetching purchaser:", error);
    }
  };

  const getCompany = async () => {
    try {
      const result = await companyApis.getCompanyDetail();
      if (result) {
        setCompanyDetail(result);
        if (location.state) {
          const { pm, addPaymentFormOpen }: any = location.state;
          if (addPaymentFormOpen) {
            addPaymentMethod();
          }
          if (pm) {
            openPaymentMethodEdit();
            window.location.href = "#payment_form";
          }
        }
      }
    } catch (error) {
      console.error("Error fetching company:", error);
    }
  };

  useEffect(() => {
    getCompany();
    getPurchaser();
  }, []);

  const openDeleteModal = (paymentMethodId: number) => {
    setShowConfirmModal(true);
    setDeletePaymentMethodId(paymentMethodId);
  };

  const refreshPage = (showSuccessModal?: boolean) => {
    if (showSuccessModal) {
      setShowSucessModal(true);
    }
    getCompany();
  };

  const addPaymentMethod = () => {
    dispatch({ type: actionType.CLEAR_PAYMENT_METHOD });
    setShowPaymentFormNew((prev) => !prev);
    setShowPaymentForm(false);
  };

  const closePayment = (fullClear: boolean) => {
    if (fullClear) {
      dispatch({ type: actionType.FULL_CLEAR_PAYMENT_METHOD });
    } else {
      dispatch({ type: actionType.CLEAR_PAYMENT_METHOD });
    }
    closeCreateAndEdit();
  };

  const closeCreateAndEdit = () => {
    setShowPaymentForm(false);
    setShowPaymentFormNew(false);
  };

  const editStatusAndDelete = async (data: any) => {
    try {
      const response = await companyApis.editCompanyDetail(data);
      if (response) {
        refreshPage();
        dispatch({ type: actionType.CLEAR_PAYMENT_METHOD });
        dispatch({ type: actionType.CLEAR_VALIDATIONS });
        CreateNotification("Success", "Payment Method Deleted Succesfully", NotificationType.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deletePaymentMethod = (id?: number) => {
    if (id) {
      let payment_method = {
        id: id,
        _destroy: true,
      };

      let data = {
        company: { payment_methods_attributes: payment_method },
      };
      editStatusAndDelete(data);
    }
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const saveButton = () => {
    deletePaymentMethod(deletepaymentMethodId);
    setShowConfirmModal(false);
  };

  return (
    <ErrorBoundary>
      <Container fluid={true} className={"pt-4 pb-4"}>
        <BankInfo
          paymentMethods={companyDetail?.payment_methods || []}
          refreshPage={refreshPage}
          toggleShowPaymentForm={toggleShowPaymentForm}
          openDeleteModal={openDeleteModal}
          showPaymentForm={showPaymentForm}
          companyDetail={companyDetail}
          showPaymentFormNew={showPaymentFormNew}
          addPaymentMethod={addPaymentMethod}
          purchasers={purchasers}
          closePayment={closePayment}
          closeCreateAndEdit={closeCreateAndEdit}
          paymentMethodId={paymentMethodId}
        />
      </Container>
      <ConfirmationModal
        show={showConfirmModal}
        title={t("confirm")}
        body={t("settingsPage.confirmDeleteBank")}
        closeCallback={closeConfirmModal}
        saveCallback={saveButton}
      />
      <SuccessModal
        show={showSuccessModal}
        body={t("settingsPage.bankInfoSaved")}
        closeCallback={() => {
          setShowSucessModal(false);
        }}
      />
    </ErrorBoundary>
  );
};

export default DetailBanking;
