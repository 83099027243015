import React, { useEffect, useMemo, useState } from "react";
import {
  CardDisputeService,
  CardDisputeServiceSubmit,
  DISPUTE_KEYS_INDEX,
  DISPUTE_STATE,
  Utility,
} from "../../../../../services/admin/cards/cardDisputesSvc";
import { CardsDisputesFormState } from "../../../../../services/admin/cards/disputes/cardDisputes.types";
import { Col, Row, Button, Spinner, Container } from "react-bootstrap";
import { DisputeSingleItemTable } from "./disputeSingleItemTable";
import { DuplicateDispute } from "./categories/duplicate";
import { getFormValues, Field } from "redux-form";
import { IncorrectAmountDispute } from "./categories/incorrectAmount";
import { NotAuthorizedDispute } from "./categories/notAuthorized";
import { NotProcessedDispute } from "./categories/notProcessed";
import { NotReceivedDispute } from "./categories/notReceived";
import { PaidByOtherDispute } from "./categories/paidByOther";
import { QualityDispute } from "./categories/quality";
import { RenderSelect } from "../../../../forms/bootstrapFields";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../../reducers";
import Style from "./dispute.module.css";

interface DisputeDetailsProps {
  handleSubmit: any;
  goToNextStep: (nextStep: string) => void;
}

export const DisputeDetailsAdd: React.FC<DisputeDetailsProps> = ({ goToNextStep, handleSubmit }) => {
  const currentDispute = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue.form.dispute;
  });

  const currentState = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue;
  });

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    CardDisputeService.clearOptions(dispatch);
    CardDisputeService.untouchDynamicFormValues(dispatch);
  }, [currentDispute, dispatch]);

  // AC-15058: On duplicate page if there are no duplicate purchases, disable the submit button
  useEffect(() => {
    if (
      currentDispute === DISPUTE_KEYS_INDEX.DUPLICATE &&
      (currentState?.purchase?.duplicate_purchases?.length || 0) === 0
    ) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  }, [currentDispute, currentState?.purchase?.duplicate_purchases?.length]);

  const { t } = useTranslation();

  const tFooter = (key: string) => {
    return t(`components.admin.disputes.footer.${key}`);
  };

  const renderDisputeCategory = useMemo(() => {
    switch (currentDispute) {
      case CardDisputeService.getDisputeOption(DISPUTE_KEYS_INDEX.DUPLICATE).key:
        return <DuplicateDispute></DuplicateDispute>;
      case CardDisputeService.getDisputeOption(DISPUTE_KEYS_INDEX.PAID_BY_OTHER).key:
        return <PaidByOtherDispute></PaidByOtherDispute>;
      case CardDisputeService.getDisputeOption(DISPUTE_KEYS_INDEX.NOT_RECEIVED).key:
        return <NotReceivedDispute></NotReceivedDispute>;
      case CardDisputeService.getDisputeOption(DISPUTE_KEYS_INDEX.NOT_AUTHORIZED).key:
        return <NotAuthorizedDispute></NotAuthorizedDispute>;
      case CardDisputeService.getDisputeOption(DISPUTE_KEYS_INDEX.INCORRECT_AMOUNT).key:
        return <IncorrectAmountDispute></IncorrectAmountDispute>;
      case CardDisputeService.getDisputeOption(DISPUTE_KEYS_INDEX.QUALITY).key:
        return <QualityDispute></QualityDispute>;
      case CardDisputeService.getDisputeOption(DISPUTE_KEYS_INDEX.NOT_PROCESSED).key:
        return <NotProcessedDispute></NotProcessedDispute>;
      case CardDisputeService.getDisputeOption(DISPUTE_KEYS_INDEX.OTHER).key:
        return (
          <>
            <Col>
              <Row dangerouslySetInnerHTML={{ __html: t(`components.admin.disputes.option.other.description`) }}></Row>
            </Col>
          </>
        );
      default:
        return <></>;
    }
  }, [currentDispute]);

  const returnToAngular = () => {
    Utility.returnToAngular(currentState);
  };

  const handleCustomSubmit = () => {
    CardDisputeServiceSubmit.submit(currentState, dispatch, goToNextStep);
  };

  return (
    <Container>
      <div className={`bg-white panel-section ${Style.panel_padded}`} style={{ width: "100%", marginTop: "24px" }}>
        <Col className={`${Style.black_text}`}>
          {currentState.processing && (
            <div className={`${Style.overlay}`}>
              <Col style={{ width: "100%" }}>
                <Row>
                  <h1 style={{ color: "white" }}>{t("components.admin.disputes.processing")}</h1>
                </Row>
                <Row style={{ marginTop: "16px" }}>
                  <Spinner animation="border" variant="light"></Spinner>
                </Row>
              </Col>
            </div>
          )}
          <Row>
            <i
              className={`icon icon-arrow-left ${Style.back_icon_repositioning}`}
              onClick={() => goToNextStep(DISPUTE_STATE.EDIT_CONTACT)}
            ></i>
            <h4 style={{ paddingLeft: 15 }}>{t("components.admin.disputes.header")}</h4>
          </Row>
          <hr className={`${Style.bold_hr}`}></hr>
          <Row>
            <Col style={{ marginLeft: "20px" }}>
              <Row className={`${Style.subtitle}`}>{t("components.admin.disputes.tell_us")}</Row>
              <Row>{t("components.admin.disputes.provide")}</Row>
              <br />
              <Row>
                <Col className={`${Style.dispute_reason_column}`}>
                  <b>{t("components.admin.disputes.reason")}:</b>
                </Col>
                <Col md="6">
                  <Field
                    id="form.dispute"
                    name="form.dispute"
                    component={RenderSelect}
                    options={CardDisputeService.DISPUTE_OPTIONS_GROUP}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ padding: "0 16px" }}>
            <DisputeSingleItemTable />
          </Row>
          <hr className={`${Style.dark_hr}`}></hr>
          <Row>
            <Col style={{ marginLeft: "20px" }}>
              <br />
              <Row>{renderDisputeCategory}</Row>
              <br />
              <Row>
                <b>{tFooter("line_1")}</b>
              </Row>
              <br />
              <Row>{tFooter("line_2")}</Row>
              <br />
              <Row style={{ display: "flex", justifyContent: "flex-start" }}>
                {/* using <divs> as the flex styles on <Col> elements screws up the desired visual presentation */}
                <div
                  style={{ textOverflow: "nowrap", flexShrink: "1" }}
                  dangerouslySetInnerHTML={{ __html: tFooter("corpay_address") }}
                ></div>
                <div className={`${Style.or_line}`}>{tFooter("or")}</div>
                <div
                  style={{ textOverflow: "nowrap", flexShrink: "1" }}
                  dangerouslySetInnerHTML={{ __html: tFooter("corpay_email") }}
                ></div>
              </Row>
            </Col>
          </Row>
          {currentDispute !== DISPUTE_KEYS_INDEX.OTHER && (
            <Row className="justify-content-end">
              <Button className="btn" style={{ marginRight: "10px" }} type="button" onClick={returnToAngular}>
                {t(`components.admin.disputes.cancel`)}
              </Button>
              <Button
                className="btn"
                type="button"
                disabled={disableSubmitButton}
                onClick={handleSubmit(handleCustomSubmit)}
              >
                {tFooter("submit")}
              </Button>
            </Row>
          )}
        </Col>
      </div>
    </Container>
  );
};
