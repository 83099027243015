import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RestApi from "../../../providers/restApi";
import { formattedAmount } from "../../../services/general/helpers";
import _ from "lodash";

const InvoicePaymentLinks = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      {_.isArray(props.invoicePaymentLinks) && props.invoicePaymentLinks.length > 0 ? (
        <Table striped bordered hover className="mb-0">
          <thead>
            <tr>
              <th className="primaryFontColor">INV #</th>
              <th className="primaryFontColor">{t("details.status")}</th>
              <th className="primaryFontColor">{t("details.amountApplied")}</th>
              <th className="primaryFontColor">{t("details.amountDiscounted")}</th>
              <th className="primaryFontColor">{t("details.invoiceDate")}</th>
            </tr>
          </thead>
          <tbody>
            {props.invoicePaymentLinks.map((invoicePaymentLink) => {
              return (
                <tr key={invoicePaymentLink?.invoice?.id}>
                  <td>
                    {/* role is used redirect to correct page, if the role is admin need to redirect to admin portal */}
                    {props.role === "admin" ? (
                      <a
                        className="primaryLinkColor"
                        variant="primary"
                        href={new RestApi().makeAngularURLWithId("invoices/", invoicePaymentLink?.invoice?.id)}
                      >
                        {invoicePaymentLink?.invoice?.number}
                      </a>
                    ) : (
                      <Link
                        className="primaryLinkColor"
                        variant="primary"
                        to={"/ar/invoices/" + invoicePaymentLink?.invoice?.id}
                        data-toggle="tooltip"
                        title="supports"
                      >
                        {invoicePaymentLink?.invoice?.number}
                      </Link>
                    )}
                  </td>
                  <td>{invoicePaymentLink?.invoice?.status}</td>
                  <td>
                    {formattedAmount(
                      invoicePaymentLink?.amount ? invoicePaymentLink?.amount : invoicePaymentLink?.invoice?.amount,
                      invoicePaymentLink?.invoice.currency?.iso_code,
                    )}
                  </td>
                  <td>-</td>
                  <td>{invoicePaymentLink?.invoice?.date}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <h5 className="text-center mt-3 mb-3">- {t("warnings.noLinkedInvoices")} -</h5>
      )}
    </div>
  );
};

export default InvoicePaymentLinks;
