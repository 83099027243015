const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CREDIT_MEMO":
      return action.payload;
    case "CLEAR_CREDIT_MEMO":
      return {};
    case "SET_CREDIT_MEMO_PURCHASER":
      return {
        ...state,
        purchaser: action.payload,
      };
    case "SET_CREDIT_MEMO_SERVICE_PERIOD_START":
      return {
        ...state,
        service_start_date: action.payload,
      };
    case "SET_CREDIT_MEMO_SERVICE_PERIOD_END":
      return {
        ...state,
        service_end_date: action.payload,
      };
    case "SET_CREDIT_MEMO_NUMBER":
      return {
        ...state,
        number: action.payload,
      };
    case "SET_CREDIT_MEMO_AMOUNT":
      return {
        ...state,
        amount: action.payload,
      };
    case "SET_CREDIT_MEMO_PAYMENT_DATE":
      return {
        ...state,
        payment_date: action.payload,
      };
    case "SET_CREDIT_MEMO_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "SET_CREDIT_MEMO_INVOICES":
      return {
        ...state,
        vendor_credit_source_invoice_links: action.payload,
      };
    case "SET_CREDIT_MEMO_ASSETS":
      return {
        ...state,
        assets: action.payload,
      };
    case "SET_CREDIT_MEMO_CURRENCY":
      return {
        ...state,
        currency: action.payload,
      };
    default:
      return state;
  }
};
