import React, { useCallback, useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import Styles from "../override.module.css";
import { onBlurSingle } from "services/general/helpers";
import RestApi from "providers/restApi";
import _ from "lodash";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";

type AddressPickerPropsType = {
  className?: string;
  label?: string;
  tooltip?: string;
  input: any;
  meta: {
    touched: boolean;
    error?: string;
    warning?: string;
  };
  params?: Record<string, any>;
  callBack?: (address: any) => void;
  instanceId?: string;
};

const AddressPicker = ({
  label,
  tooltip,
  input,
  meta: { touched, error, warning },
  params,
  callBack,
  instanceId = "address-picker",
}: AddressPickerPropsType) => {
  const [addresses, setAddresses] = useState<any>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selected, setSelected] = useState<any>();

  const mounted = useRef(false);
  const restApiService = new RestApi();

  const getAddresses = useCallback(async () => {
    const apiParams = { status: "ACTIVE", ...params };
    try {
      const result = await restApiService.get("addresses", apiParams, null, true, null, true);
      const options = parseForSelect(result.data);
      if (mounted.current) {
        setAddresses(options);
      }
      return options;
    } catch (error) {
      console.log(error);
    }
  }, [input?.value, params]);

  useEffect(() => {
    getAddresses();
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const parseForSelect = (response: any) => {
    const options = response.map((option: any) => {
      return { value: option.id, ...option };
    });
    return options;
  };

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        selectedValues = input.value;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  const onChangeAddress = (input: any, option: any) => {
    if (!option) {
      input.onChange("");
      return;
    }
    input.onChange(option);
    if (callBack) {
      callBack(option);
    }
  };

  useEffect(() => {
    getAddresses().then((res) => {
      findSelected(input, res).then((response: any) => {
        setSelected(response);
      });
    });
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [input]);

  return (
    <>
      <Form.Group className={Styles.select}>
        {label && (
          <Form.Label>
            {label ?? ""}
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          value={selected}
          placeholder="search/select"
          onChange={(value: any) => onChangeAddress(input, value)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={addresses}
          onMenuOpen={() => setIsDropdownOpen(true)}
          onMenuClose={() => setIsDropdownOpen(false)}
          instanceId={instanceId} // don't remove or change used for automation testing
          getOptionLabel={(option: any) => {
            return (
              <small>
                {isDropdownOpen &&
                  (option.address_type ? (
                    <span>
                      Address Type: {option.address_type}
                      <br />
                    </span>
                  ) : (
                    ""
                  ))}
                <span>
                  {option.address1 ? option.address1 + ", " : ""}
                  {option.city ? option.city + ", " : ""}
                  {option.state ? option.state + ", " : ""}
                  {option.zipcode ? option.zipcode + ", " : ""}
                  {option.country}
                </span>
              </small>
            );
          }}
        />
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default withTranslation()(AddressPicker);
