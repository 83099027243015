import React, { useCallback, useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import restApi from "../../../../providers/restApi";
import Select from "react-select";
import Styles from "../override.module.css";
import {
  findSelectedMultiple,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../services/general/helpers";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal components/admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal components/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
export default function PolicyPicker(props) {
  let {
    className,
    label,
    input,
    meta: { touched, error, warning },
    disabled,
    isMulti,
  } = props;
  const [policies, setPolicies] = useState([]);
  const mounted = useRef(false);

  const restApiService = new restApi();

  const getLocations = useCallback(async () => {
    const result = await restApiService.get(
      "policies.lk",
      {
        status: "ACTIVE",
      },
      null,
      true,
      null,
      true,
    );
    const value = parseForSelect(result.data);
    if (mounted.current) setPolicies(value);
  }, [restApiService]);

  useEffect(() => {
    getLocations();
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return (
    <>
      {isMulti && (
        <Form.Group className={Styles.select}>
          {label && <Form.Label>{label}</Form.Label>}
          <Select
            {...input}
            required
            value={findSelectedMultiple(input, policies)}
            placeholder="search/select"
            onChange={(value) => onChange(input, value)}
            onBlur={() => onBlur(input, input.value)}
            options={policies}
            isMulti={isMulti}
            isDisabled={disabled}
            isClearable
            classNamePrefix="select"
          />
          {error && touched && (
            <div style={{ fontSize: "80%", color: "red", padding: "2px" }}> Policy Required... </div>
          )}
        </Form.Group>
      )}

      {!isMulti && (
        <Form.Group className={Styles.select}>
          {label && <Form.Label>{label}</Form.Label>}
          <Select
            {...input}
            required
            value={findSelectedMultiple(input, policies)}
            placeholder="search/select"
            onChange={(value) => onChangeSingle(input, value)}
            onBlur={() => onBlurSingle(input, input.value)}
            options={policies}
            isDisabled={disabled}
            isMulti={isMulti}
            isClearable
            classNamePrefix="select"
          />
          {error && touched && (
            <div style={{ fontSize: "80%", color: "red", padding: "2px" }}> Policy Required... </div>
          )}
        </Form.Group>
      )}
    </>
  );
}
