import { RenderField } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const BankAccountNumber = ({ label, isBankAccountRequired, validations }) => {
  return (
    <Col md="6">
      <Field
        id="form.account_number"
        name="form.account_number"
        component={RenderField}
        label={label}
        type="text"
        required={isBankAccountRequired}
        validate={validations ? validations : []}
        autoComplete={"off"}
      />
    </Col>
  );
};

export default BankAccountNumber;
