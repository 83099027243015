import React, { Component } from "react";
import { Container, Modal, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Panel from "../../panel/panel";
import { Link } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import styles from "./checklist.module.css";

class ChecklistItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    let item = this.props.item || {};
    return (
      <Row>
        {/* <Col xs={1} className="text-center" >
          {item.completed && (
            <AiFillCheckCircle style={{fontSize:"32px",color:"green"}} />
          )}
          {!item.completed && (
            <h1 className="text-center">{this.props.index + 1 + "."}</h1>
          )}
        </Col> */}
        <Col>
          <Link to={item.link} className={styles.linkText}>
            <button
              type="button"
              disabled={item.disabled || item.completed}
              className={`${styles.itemButton} ${styles.itemText} btn btn-block text-left darkGrayFontColor`}
            >
              {!item.completed && (
                <div className={`${styles.itemNumber} d-inline-block mr-3 ml-2`}>{this.props.index + 1}</div>
              )}
              {item.completed && <AiFillCheckCircle className={`${styles.checkMark} mr-3 ml-1`} />}
              {item.title} →
            </button>
            {/* <button type="button" className={"checklistItemBorder pl-3 pt-2 pb-2 btn btn-secondary btn-block"} disabled={item.disabled || item.completed}>{item.title}</button> */}
          </Link>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(ChecklistItem);
