// to make it easy on us on the front end we take only the subsidiary id
export const paymentMethodCompanyLinkParseFromAPI = (paymentMethod) => {
  if (
    paymentMethod &&
    paymentMethod.payment_method_company_links &&
    paymentMethod.payment_method_company_links.length > 0
  ) {
    let subsidiary_ids = [];
    paymentMethod.payment_method_company_links.forEach((link) => {
      if (link.payment_method_company_linkable_type == "Subsidiary") {
        subsidiary_ids.push(link.payment_method_company_linkable_id);
      }
    });

    paymentMethod.subsidiary_ids = subsidiary_ids;
  }
};

export const paymentMethodCompanyLinkParseToAPI = (paymentMethod, company_id) => {
  let updatedAttributes = [];
  //first go through our paymentMethodCompanyLinks and delte any that are not in our subsidiary_ids set by the picker
  if (paymentMethod.payment_method_company_links) {
    paymentMethod.payment_method_company_links.forEach((link) => {
      if (
        link.payment_method_company_linkable_type == "Subsidiary" &&
        !paymentMethod.subsidiary_ids.includes(link.payment_method_company_linkable_id)
      ) {
        link._destroy = true;
      }
      updatedAttributes.push(link);
    });
  }
  //now loop over our subsidiary ids and create a new paymentMethodCompanyLink if one does not exist
  if (paymentMethod.subsidiary_ids && paymentMethod.subsidiary_ids.length > 0) {
    if (!paymentMethod.payment_method_company_links) {
      paymentMethod.payment_method_company_links = [];
    }
    paymentMethod.subsidiary_ids.forEach((id) => {
      if (!paymentMethod.payment_method_company_links.some((obj) => obj.payment_method_company_linkable_id == id)) {
        //create new one
        updatedAttributes.push({
          payment_method_company_linkable_type: "Subsidiary",
          payment_method_company_linkable_id: id,
          company_id: company_id,
        });
      }
    });
  }

  if (updatedAttributes.length > 0) {
    paymentMethod.payment_method_company_links_attributes = updatedAttributes;
  }
};
