import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaAngleLeft, FaAngleRight, FaEye } from "react-icons/fa6";
import { Document, Thumbnail, pdfjs } from "react-pdf";
import styles from "./pdfThumbnailCarousel.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
  cMapUrl: `//unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

interface PDFThumbnailCarouselProps {
  thumbnailsPerPage?: number;
  isMainDocumentLoaded: boolean;
  onClickCurrentPage: (page: number) => void;
  handleOpen: (e: any) => void;
  onHandleSplitClick: (e: any) => void;
  onHandleDeleteClick: (e: any) => void;
  disabledAdd: boolean;
  disabledSplit: boolean;
  disabledDelete: boolean;
  pdfFileToRender: File | undefined;
  checkboxValues: Map<number, boolean>;
  setCheckboxValues: React.Dispatch<React.SetStateAction<Map<number, boolean>>>;
  isSubmiting: boolean;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
}

interface ThumbnailProps {
  checked: boolean;
  pageNumber: number;
  selectedPage: number;
  onClickCheckBox: (idx: number, checked: boolean) => void;
  onPageSelect: (pageNumber: number) => void;
}

const usePDFNavigation = (numPages: number | null, thumbnailsPerPage: number) => {
  const [currentPage, setCurrentPage] = useState(1);

  const goToPrevious = useCallback(() => {
    setCurrentPage((current) => Math.max(current - thumbnailsPerPage, 1));
  }, [thumbnailsPerPage]);

  const goToNext = useCallback(() => {
    setCurrentPage((current) => Math.min(current + thumbnailsPerPage, numPages || 0));
  }, [thumbnailsPerPage, numPages]);

  const canGoPrevious = currentPage > 1;
  const canGoNext = numPages !== null && currentPage + thumbnailsPerPage - 1 < numPages;

  return { currentPage, goToPrevious, goToNext, canGoPrevious, canGoNext, setCurrentPage };
};

const CheckedThumbnail: React.FC<ThumbnailProps> = memo(
  ({ checked, pageNumber, onClickCheckBox, onPageSelect, selectedPage }) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onClickCheckBox(pageNumber, e.target.checked);
      },
      [onClickCheckBox, pageNumber],
    );

    const handleThumbnailClick = useCallback(() => {
      onPageSelect(pageNumber);
    }, [onPageSelect, pageNumber]);

    const eyeStyle = selectedPage === pageNumber ? styles.eyeVisibleStyle : styles.eyeHiddenStyle;

    return (
      <div className={styles.thumbnailContainer}>
        <div className={styles.thumbnailHeader}>
          <div className={styles.pageNumberCheckboxContainer}>
            <h5 className={styles.thumbnailHeaderText}>
              <FaEye className={eyeStyle} /> {pageNumber}
            </h5>
            <input
              type="checkbox"
              className={styles.checkbox}
              name={`page_${pageNumber}`}
              checked={checked}
              onChange={handleChange}
            />
          </div>
        </div>
        <Thumbnail pageNumber={pageNumber} width={80} onClick={handleThumbnailClick} />
      </div>
    );
  },
);

const PDFThumbnailCarousel: React.FC<PDFThumbnailCarouselProps> = ({
  isMainDocumentLoaded,
  thumbnailsPerPage = 4,
  onClickCurrentPage,
  handleOpen,
  onHandleSplitClick,
  onHandleDeleteClick,
  disabledAdd,
  disabledSplit,
  disabledDelete,
  pdfFileToRender,
  checkboxValues,
  setCheckboxValues,
  isSubmiting,
  pageNumber,
  setPageNumber,
}) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number | null>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const btnAddStyle = disabledAdd || isSubmiting ? styles.aBtnStyleInactive : styles.aBtnStyle;
  const btnDeleteStyle = disabledDelete || isSubmiting ? styles.aBtnStyleInactive : styles.aBtnStyle;
  const btnSplitStyle = disabledSplit || isSubmiting ? styles.aBtnStyleInactive : styles.aBtnStyle;

  const onClickCheckBox = useCallback(
    (idx: number, checked: boolean) => {
      setCheckboxValues((prev) => new Map(prev.set(idx, checked)));
    },
    [setCheckboxValues],
  );

  const { currentPage, goToPrevious, goToNext, canGoPrevious, canGoNext, setCurrentPage } = usePDFNavigation(
    numPages,
    thumbnailsPerPage,
  );

  const thumbnailRange = useMemo(() => {
    return [...Array(thumbnailsPerPage)].map((_, index) => currentPage + index);
  }, [currentPage, thumbnailsPerPage]);

  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setIsLoaded(true);
  }, []);

  const onHandleClickCurrentPage = useCallback(
    (pageNbr: number) => {
      onClickCurrentPage(pageNbr);
    },
    [onClickCurrentPage],
  );

  useEffect(() => {
    const currentPageIndex = Math.ceil(pageNumber / thumbnailsPerPage);
    setCurrentPage(currentPageIndex);
  }, [pageNumber, setCurrentPage, thumbnailsPerPage]);

  if (!pdfFileToRender || !isMainDocumentLoaded) return <></>;

  const arrowLeftVisability = canGoPrevious ? styles.arrowVisible : styles.arrowHidden;
  const arrowRightVisability = canGoNext ? styles.arrowVisible : styles.arrowHidden;

  const componentMainClassName = isSubmiting ? `${styles.document} ${styles.blurred}` : styles.document;

  return (
    <>
      <Document
        file={pdfFileToRender}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
        noData={<></>}
        loading={<></>}
        error={<></>}
        className={componentMainClassName}
        onItemClick={({ pageNumber }) => onHandleClickCurrentPage(pageNumber)}
      >
        <div className={styles.navigatorContainer}>
          <div className={styles.arrowWrapper}>
            <Button
              onClick={goToPrevious}
              disabled={!canGoPrevious}
              className={`${styles.arrow}  ${arrowLeftVisability} ${styles.arrowPrevious}`}
            >
              <FaAngleLeft />
            </Button>
          </div>
          <div className={styles.thumbnailsContainer}>
            {thumbnailRange.map((page) =>
              page <= (numPages || 0) ? (
                <CheckedThumbnail
                  key={page}
                  pageNumber={page}
                  onClickCheckBox={onClickCheckBox}
                  checked={!!checkboxValues.get(page)}
                  selectedPage={pageNumber}
                  onPageSelect={setPageNumber}
                />
              ) : null,
            )}
          </div>
          <div className={styles.arrowWrapper}>
            <Button onClick={goToNext} className={`${styles.arrow} ${arrowRightVisability} ${styles.arrowNext}`}>
              <FaAngleRight />
            </Button>
          </div>
        </div>
      </Document>
      {isLoaded && (
        <div className={styles.pagesActiveStyle}>
          <button className={btnAddStyle} onClick={handleOpen} disabled={disabledAdd}>
            {t("admin.pages.uploadQueue.addPage")}
          </button>
          |
          <button className={btnSplitStyle} onClick={onHandleSplitClick} disabled={disabledSplit}>
            {t("admin.pages.uploadQueue.splitPage")}{" "}
          </button>
          |
          <button className={btnDeleteStyle} onClick={onHandleDeleteClick} disabled={disabledDelete}>
            {t("admin.pages.uploadQueue.deletePage")}
          </button>
        </div>
      )}
    </>
  );
};

export default memo(PDFThumbnailCarousel);
