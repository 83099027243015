import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import {
  applyTagsToEmail,
  markAsCompleted,
  refreshEamil,
  reprocessEmail,
  updateEmailDetailsToProcessed,
} from "../../../../reducers/admin/emailReducer";
import usePermission from "../../../common/hooks/usePermission";
import styles from "./email.module.css";
import EmailTagSelector from "./emailTagsSelelctor";
import _ from "lodash";

const EmailGridTopNavButtons = () => {
  const dispatch = useDispatch();
  const { hasUserPermission } = usePermission();
  const selectedEmail = useSelector((state: RootState) => state.emailRed.selectedEmails);
  const buttonDisabled = hasUserPermission("editInvoiceEmails") && selectedEmail?.length > 0 ? false : true;

  const emailDetails = useSelector((state: RootState) => state.emailRed.emailDetails);

  const shouldMarkAsCompletedButtonDisabled = emailDetails?.status === "Completed";

  const [showTagSelector, setShowTagSelector] = useState(false);

  const toggleTagSelector = () => {
    setShowTagSelector(!showTagSelector);
  };

  const applyEmailTags = async (tags: string[]) => {
    const newTags = _.union(tags, emailDetails.tags);
    toggleTagSelector();
    await dispatch(applyTagsToEmail(newTags));
    if (emailDetails) {
      await dispatch(refreshEamil(emailDetails?.id));
    }
  };

  const reprocess = async () => {
    await dispatch(reprocessEmail());
    await dispatch(updateEmailDetailsToProcessed(emailDetails?.id));
  };

  const onMarkCompleted = async () => {
    await dispatch(markAsCompleted());
    if (emailDetails) {
      await dispatch(refreshEamil(emailDetails?.id));
    }
  };

  return (
    <Row className="my-2">
      <Col md="12">
        <Button disabled={buttonDisabled} onClick={reprocess} className="ml-2">
          <i className="icon-sm icon-redo" />
          Reprocess Email
        </Button>
        <Button className="ml-2" disabled={buttonDisabled} onClick={() => toggleTagSelector()}>
          Tag
        </Button>

        {showTagSelector && (
          <div className={styles.postionAbsolute}>
            <EmailTagSelector closeButton={() => toggleTagSelector()} applyEmailTags={applyEmailTags} />
          </div>
        )}

        <Button
          disabled={buttonDisabled || shouldMarkAsCompletedButtonDisabled}
          onClick={onMarkCompleted}
          className="ml-2"
        >
          <i className="icon-sm icon-mark-complete-white" />
          Mark Complete
        </Button>
      </Col>
    </Row>
  );
};

export default EmailGridTopNavButtons;
