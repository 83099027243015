import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import { Field, FieldArray } from "redux-form";
import { ComparisonField, RenderTextArea } from "../../../forms/bootstrapFields";
import {
  POItemFieldPropsType,
  POItemFieldsPropsType,
} from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrders.types";
import { AppDispatch, useTypedSelector } from "../../../../reducers/index";
import {
  wholeNumber as wholeNumberValidation,
  minValue,
  required,
  noWhiteSpaceOnly,
} from "../../../../services/validations/reduxFormValidation";
import LocationPicker from "../../../pickers/reduxFormPicker/locationPicker";
import BusinessUnitPicker from "../../../pickers/reduxFormPicker/businessUnitPicker";
import departmentPicker from "components/admin/pickers/reduxFormPickers/departmentPicker";
import { restApiService } from "../../../../providers/restApi";
import TaxCodePicker from "../../pickers/reduxFormPickers/taxCodePicker";
import GrantPicker from "../../pickers/reduxFormPickers/grantPicker";
import ProjectPicker from "../../pickers/reduxFormPickers/projectPicker";
import BudgetPicker from "components/admin/purchaseOrders/changeOrders/budgetPicker";
import Styles from "../../../../pages/admin/purchaseOrders/changeOrders/purchaseOrders.module.css";
import {
  ChangeOrderPOItemService,
  ChangeOrdersPOFormService,
} from "../../../../services/admin/purchaseOrders/changeOrders/purchaseOrdersForm";
import { POFieldItemIcons } from "./fieldItemIcons";
import { POItemsFooter } from "./poItemsFooter";
import AddChangeOrder from "./addForm";
import EditChangeOrder from "./editForm";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import ProductItemPicker from "../../pickers/reduxFormPickers/productItemPickeer";
import changeOrderSvc from "../../../../services/admin/changeOrders/changeOrderSvc";
import { CompanyType } from "../../../../services/common/user/userTypes";
import { ShowFields } from "../../../common/showFields/showFields";
import SecondaryCategoryPicker from "components/admin/pickers/reduxFormPickers/secondaryCategoryPicker";
import ForSubsidiaryPicker from "components/admin/pickers/reduxFormPickers/forSubsidiaryPicker";
import adminCommonSvc from "services/admin/commonSvc";
import { getStringFormattedAmount } from "services/general/helpers";

const { cardContainer } = Styles;

const maxUnitPriceDigits = 16;
const isGreaterThanZero = minValue(0);
const getLocaleDecimalSeparator = () => {
  const numberWithDecimalSeparator = 1.1;
  return Intl.NumberFormat().format(numberWithDecimalSeparator).charAt(1);
};

const decimalSeparator = getLocaleDecimalSeparator();

const POItemField = ({ index, item, formServiceRef, editIndexRef, comparison }: POItemFieldPropsType) => {
  const [editMode, setEditMode] = useState(false);
  const [itemTaxRate, setItemTaxRate] = useState<number>(0);
  const [itemDebitAccountId, setItemDebitAccountId] = useState<number | null>();
  const [itemDepartmentId, setItemDepartmentId] = useState<number | null>();
  const company: CompanyType = useTypedSelector((state) => state.user?.company, shallowEqual);
  const itemState = useTypedSelector((state) => formServiceRef.current.getPOItemState(state, index), shallowEqual);
  const status = useTypedSelector((state) => formServiceRef.current.getStatus(state));
  const currencyCode = useTypedSelector((state) => formServiceRef.current.getCurrencyCode(state));
  const subsidiaryId = useTypedSelector((state) => formServiceRef.current.getSubsidiaryId(state));
  const { fieldService } = formServiceRef.current;
  const [locked, setLocked] = useState<boolean>(false);
  const currentUser = useTypedSelector((state) => state.user);
  const unitPriceDecimalLimit: number = useMemo(() => adminCommonSvc.unitPriceDecimalLimit(currentUser), [currentUser]);

  const formatterUnitPrice = (value: any): string => {
    return getStringFormattedAmount((value || 0).toString(), undefined, unitPriceDecimalLimit, true);
  };

  const handleUnitPriceRestrictions = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  ) => {
    let value = e.target.value;

    if (value.length > maxUnitPriceDigits) {
      value = value.slice(0, maxUnitPriceDigits);
    }

    const decimalIndex = value.indexOf(decimalSeparator);
    if (decimalIndex !== -1 && value.length - decimalIndex > unitPriceDecimalLimit + 1) {
      value = parseFloat(value).toFixed(unitPriceDecimalLimit);
    }

    e.target.value = value;
    onChange(e);
  };

  const handleEnableEdit = (): void => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  const handleDeleteItem = (): void => {
    if (itemState && itemState.id) {
      fieldService.updateDestroyField(true, index);
    } else {
      fieldService.handleDeleteField(index);
    }
  };

  const handleRecoverItem = (): void => {
    fieldService.updateDestroyField(false, index);
  };

  const handleDuplicateItem = (): void => {
    if (itemState) {
      editIndexRef.current = fieldService.duplicateField(itemState);
    }
  };

  const getTaxRate = useCallback(async (id: string): Promise<void> => {
    const response = await restApiService.get(`taxes/${id}`);
    if (response && response.data) {
      const { rate } = response.data;
      setItemTaxRate(rate);
    } else {
      setItemTaxRate(0);
      CreateNotification("Tax Rate", "Error getting tax rate. Please notify admins.", NotificationType.danger);
    }
  }, []);

  const onDepartmentChange = useCallback((department_id) => {
    if (department_id) {
      setItemDepartmentId(department_id);
    } else {
      setItemDepartmentId(null);
    }
  }, []);

  const onProductItemChange = useCallback(
    async (product: { name: string; price: number; invoice_debit_accounts: { account_id?: number }[] }) => {
      if (itemState?.id) {
        if (!itemState?.unit_price && product.price) {
          fieldService.updateUnitPrice(product.price.toFixed(2), index);
        }
      } else {
        if (product.price) {
          fieldService.updateUnitPrice(product.price.toFixed(2), index);
        }
      }
      if (product.invoice_debit_accounts.length && product.invoice_debit_accounts[0].account_id) {
        setItemDebitAccountId(product.invoice_debit_accounts[0].account_id);
      } else {
        setItemDebitAccountId(null);
      }
    },
    [index, itemState?.id, itemState?.unit_price],
  );

  useEffect(() => {
    if (itemState?.unit_price && itemState?.qty) {
      fieldService.recalculateItem(itemState, itemTaxRate, index);
    }
  }, [itemState?.unit_price, itemState?.qty, itemTaxRate]);

  useEffect(() => {
    if (!itemState?.tax_id) {
      return;
    }
    getTaxRate(itemState.tax_id);
  }, [itemState?.tax_id]);

  useEffect(() => {
    if (itemState?.billed_item_qty || itemState?.receipt_item_qty) {
      setLocked(true);
    } else if (editIndexRef?.current === index) {
      setEditMode(true);
    }
  }, []);

  return (
    <Card className={`shadow-sm ${cardContainer}`}>
      <Row className="m-0 justify-content-between">
        <Col>
          <Row className="m-0 py-3">
            <Col className="p-0 d-flex flex-row align-items-center">ITEM {index + 1}</Col>
            {editMode && (
              <POFieldItemIcons
                isDestroyed={itemState?._destroy}
                editMode={editMode}
                handleRecoverItem={handleRecoverItem}
                handleEnableEdit={handleEnableEdit}
                handleDeleteItem={handleDeleteItem}
                handleDuplicateItem={handleDuplicateItem}
                readOnly={Boolean(changeOrderSvc.disableEdits(status))}
              />
            )}
          </Row>
          {!editMode ? (
            <Row className="m-0 justify-content-between">
              <Col className="p-0" md={4}>
                <Field
                  disabled={true}
                  name={`${item}.product_item_id`}
                  label="Item Name"
                  labelClassName="font-weight-bold"
                  component={ProductItemPicker}
                  validate={locked ? [] : [required]}
                  placeholder=""
                  onSelectCallback={onProductItemChange}
                  transparent={true}
                />
              </Col>
              <Col className="p-0" md={2}>
                <Field
                  inputClassName="border-0"
                  disabled={true}
                  name={`${item}.qty`}
                  label="Quantity"
                  labelClassName="font-weight-bold"
                  type="number"
                  component={ComparisonField}
                  min={0}
                  validate={locked ? [] : [required, wholeNumberValidation, isGreaterThanZero]}
                />
              </Col>
              <Col className="p-0" md={2}>
                <Field
                  inputClassName="border-0"
                  disabled={true}
                  name={`${item}.unit_price`}
                  label="Unit Price"
                  labelClassName="font-weight-bold"
                  type="text"
                  component={ComparisonField}
                  validate={locked ? [] : [required, isGreaterThanZero]}
                  min={0}
                  prefix={fieldService.currencySymbol}
                  format={formatterUnitPrice}
                />
              </Col>
              <Col className="p-0" md={2}>
                <Field
                  inputClassName="border-0"
                  disabled={true}
                  name={`${item}.total`}
                  label="Total"
                  labelClassName="font-weight-bold"
                  type="number"
                  component={ComparisonField}
                  validate={locked ? [] : [required]}
                  prefix={fieldService.currencySymbol}
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="m-0">
                <Col className="p-0" md={3}>
                  <Field
                    name={`${item}.product_item_id`}
                    label="Item Name"
                    labelClassName="font-weight-bold"
                    required={true}
                    component={ProductItemPicker}
                    originalValue={comparison && itemState ? itemState.product_item_id_original : undefined}
                    validate={locked ? [] : [required]}
                    placeholder="-- Select Product --"
                    disabled={locked || (itemState?.id && itemState?.product_item_id ? true : false)}
                    onSelectCallback={onProductItemChange}
                  />
                </Col>
                <Col className="p-0 px-2" md={2}>
                  <Field
                    name={`${item}.qty`}
                    label="Qty"
                    labelClassName="font-weight-bold"
                    type="number"
                    component={ComparisonField}
                    originalValue={comparison && itemState ? itemState.qty_original : undefined}
                    min={0}
                    validate={locked ? [] : [required, wholeNumberValidation, isGreaterThanZero]}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
                <Col className="p-0" md={2}>
                  <Field
                    name={`${item}.unit_price`}
                    label="Unit Price"
                    labelClassName="font-weight-bold"
                    type="number"
                    component={ComparisonField}
                    originalValue={comparison && itemState ? itemState.unit_price_original : undefined}
                    validate={locked ? [] : [required, isGreaterThanZero]}
                    min={0}
                    prefix={fieldService.currencySymbol}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                    handleOnChange={handleUnitPriceRestrictions}
                  />
                </Col>
                <Col className="p-0 px-2" md={2}>
                  {!company?.po_item_line_hide_tax && (
                    <Field
                      name={`${item}.tax`}
                      label="Tax"
                      labelClassName="font-weight-bold"
                      placeholder="Tax Amount"
                      type="number"
                      component={ComparisonField}
                      originalValue={comparison && itemState ? itemState.tax_original : undefined}
                      disabled={true}
                      validate={locked ? [] : [isGreaterThanZero]}
                      prefix={itemState?.tax ? fieldService.currencySymbol : ""}
                    />
                  )}
                </Col>
                <Col className="p-0" md={2}>
                  <Field
                    name={`${item}.total`}
                    label="Total"
                    labelClassName="font-weight-bold"
                    type="number"
                    component={ComparisonField}
                    originalValue={comparison && itemState ? itemState.total_original : undefined}
                    disabled={true}
                    validate={locked ? [] : [required]}
                    prefix={fieldService.currencySymbol}
                  />
                </Col>
              </Row>
              <Row className="m-0 justify-content-between">
                {itemDebitAccountId && (
                  <Col className="p-0 pr-2" md={4}>
                    <ShowFields
                      label="Account"
                      endpoint="accounts"
                      labelClassName="font-weight-bold"
                      className={`${Styles.productItemAccountContainer} pl-2 d-flex flex-row align-items-center`}
                      id={itemDebitAccountId}
                      fields={["number", "name"]}
                    />
                  </Col>
                )}
                {!company?.po_item_line_hide_tax && (
                  <Col className="p-0" md={4}>
                    <Field
                      name={`${item}.tax_id`}
                      label="Tax Code"
                      labelClassName="font-weight-bold"
                      component={TaxCodePicker}
                      originalValue={comparison && itemState ? itemState.tax_id_original : undefined}
                      subsidiary_id={subsidiaryId}
                      disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                    />
                  </Col>
                )}
                {!company?.po_request_disabled_itemline_department && (
                  <Col className="p-0 px-2" md={4}>
                    <Field
                      name={`${item}.department_id`}
                      label="Department"
                      labelClassName="font-weight-bold"
                      component={departmentPicker}
                      originalValue={comparison && itemState ? itemState.department_id_original : undefined}
                      placeholder="-- Select Department --"
                      onSelectCallback={onDepartmentChange}
                      params={{ subsidiary_id: subsidiaryId }}
                      disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                    />
                  </Col>
                )}
                {!company.po_request_disabled_itemline_location && (
                  <Col className="p-0" md={4}>
                    <Field
                      name={`${item}.location_id`}
                      label="Location"
                      labelClassName="font-weight-bold"
                      component={LocationPicker}
                      originalValue={comparison && itemState ? itemState.location_id_original : undefined}
                      placeholder="-- Select Location --"
                      disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                    />
                  </Col>
                )}
                {!company?.purchase_order?.items?.business_unit?.is_hide && (
                  <Col className="p-0" md={4}>
                    <Field
                      name={`${item}.business_unit_id`}
                      label="Business Unit"
                      labelClassName="font-weight-bold"
                      component={BusinessUnitPicker}
                      originalValue={comparison && itemState ? itemState.business_unit_original : undefined}
                      placeholder="-- Select Business Unit --"
                      disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                    />
                  </Col>
                )}
              </Row>
              <Row className="m-0 justify-content-between">
                {company.has_secondary_categories && (
                  <Col className="p-0" md={4}>
                    <Field
                      name={`${item}.secondary_category_id`}
                      label="Secondary Category"
                      labelClassName="font-weight-bold"
                      component={SecondaryCategoryPicker}
                      originalValue={comparison && itemState ? itemState.secondary_category_id_original : undefined}
                      placeholder="-- Select Category --"
                      disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                    />
                  </Col>
                )}
                {company.has_grants && (
                  <Col className="p-0 px-2" md={4}>
                    <Field
                      name={`${item}.grant_id`}
                      label="Grant"
                      labelClassName="font-weight-bold"
                      component={GrantPicker}
                      originalValue={comparison && itemState ? itemState.grant_id_original : undefined}
                      placeholder="-- Select Grant --"
                      disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                    />
                  </Col>
                )}
                {!company?.po_request_hide_item_line_project && (
                  <Col className="p-0" md={4}>
                    <Field
                      name={`${item}.project_id`}
                      label="Project"
                      labelClassName="font-weight-bold"
                      component={ProjectPicker}
                      originalValue={comparison && itemState ? itemState.project_id_original : undefined}
                      placeholder="-- Select Project --"
                      disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                    />
                  </Col>
                )}
              </Row>
              <Row className="m-0">
                <Col className="p-0" md={4}>
                  <Field
                    name={`${item}.budget_id`}
                    label="Budget"
                    labelClassName="font-weight-bold"
                    component={BudgetPicker}
                    account_id={itemDebitAccountId}
                    department_id={itemDepartmentId}
                    currency_code={currencyCode}
                    originalValue={comparison && itemState ? itemState.budget_id_original : undefined}
                    placeholder="-- Select Budget --"
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
                <Col className="p-0 mx-3" md={4}>
                  {company?.has_for_subsidiaries && (
                    <Field
                      name={`${item}.for_subsidiary_id`}
                      label="For Subsidiary"
                      labelClassName="font-weight-bold"
                      component={ForSubsidiaryPicker}
                      originalValue={comparison && itemState ? itemState.for_subsidiary_id_original : undefined}
                      placeholder="-- Select For Subsidiary --"
                      disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                    />
                  )}
                </Col>
              </Row>
              <Row className="m-0 justify-content-between">
                <Col className="p-0">
                  <Field
                    name={`${item}.description`}
                    label="Description"
                    labelClassName="font-weight-bold"
                    required={true}
                    rows={3}
                    component={RenderTextArea}
                    originalValue={comparison && itemState ? itemState.description_original : undefined}
                    validate={locked ? [] : [required, noWhiteSpaceOnly]}
                    disabled={locked || Boolean(changeOrderSvc.disableEdits(status))}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
        {!editMode && (
          <POFieldItemIcons
            isDestroyed={itemState?._destroy}
            editMode={editMode}
            handleRecoverItem={handleRecoverItem}
            handleEnableEdit={handleEnableEdit}
            handleDeleteItem={handleDeleteItem}
            handleDuplicateItem={handleDuplicateItem}
            readOnly={Boolean(changeOrderSvc.disableEdits(status))}
          />
        )}
      </Row>
    </Card>
  );
};

export const POItemFields = ({ fields, mode }: POItemFieldsPropsType) => {
  const dispatch: AppDispatch = useDispatch();
  const formServiceRef = useRef<ChangeOrdersPOFormService<ChangeOrderPOItemService>>(
    new ChangeOrdersPOFormService(mode, dispatch, ChangeOrderPOItemService),
  );
  const editIndexRef = useRef<number | undefined>();
  const fieldService = formServiceRef.current.fieldService;
  fieldService.fields = fields;

  return (
    <Container fluid>
      {fields.map((item, index) => {
        return (
          <POItemField
            item={item}
            index={index}
            key={fieldService.getFieldId(index)}
            editIndexRef={editIndexRef}
            formServiceRef={formServiceRef}
            comparison={mode === "edit"}
          />
        );
      })}
      <POItemsFooter formServiceRef={formServiceRef} editIndexRef={editIndexRef} />
    </Container>
  );
};

export const EditPOItemFieldsForm = () => {
  return (
    <EditChangeOrder>
      <FieldArray name="object_changes.po_items_attributes" component={POItemFields} mode="edit" />
    </EditChangeOrder>
  );
};

export const AddPOItemFieldsForm = () => {
  return (
    <AddChangeOrder>
      <FieldArray name="object_changes.po_items_attributes" component={POItemFields} mode="create" />
    </AddChangeOrder>
  );
};
