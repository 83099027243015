import Dashboard from "./payments/dashboard";
import Rebates from "./rebates/rebates";

export const cardRoutes = [
  {
    path: "/ap/cards/payments",
    exact: true,
    component: Dashboard,
    authorization: {
      permissions: ["addVCards"],
    },
  },
  {
    path: "/ap/cards/rebates",
    exact: true,
    component: Rebates,
    authorization: {
      permissions: ["addVCards"],
    },
  },
];
