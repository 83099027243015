import React from "react";
import { Col, Row } from "react-bootstrap";
import { required } from "../../../../../services/validations/reduxFormValidation";
import { useTypedSelector } from "../../../../../reducers";
import { getFormValues } from "redux-form";
import ZipCode from "../../../../common/managePaymentMethod/fields/zipCode";
import PaymentType from "../../fields/paymentType";
import CurrencyCode from "../../../../common/managePaymentMethod/fields/currencyCode";
import AccountName from "../../../../common/managePaymentMethod/fields/accountName";
import AccountType from "../../../../common/managePaymentMethod/fields/accountType";
import AccountNumber from "../../../../common/managePaymentMethod/fields/accountNumber";
import ConfirmAccountNumber from "../../../../common/managePaymentMethod/fields/confirmAccountNumber";
import BankRouting from "../../../../common/managePaymentMethod/fields/bankRouting";
import BankName from "../../../../common/managePaymentMethod/fields/bankName";
import DeliveryMethod from "../../../../common/managePaymentMethod/fields/deliveryMethod";
import Address1 from "../../../../common/managePaymentMethod/fields/address1";
import City from "../../../../common/managePaymentMethod/fields/city";
import State from "../../../../common/managePaymentMethod/fields/state";
import { MANAGE_PAYMENT_METHOD_FORM } from "../../../../../services/common/paymentMethod.svc";
import Status from "../../../../common/managePaymentMethod/fields/status";
import { ManagePaymentMethodFormDataType } from "../../../../common/managePaymentMethod/types";
import Primary from "../../../../common/managePaymentMethod/fields/primary";
import _ from "lodash";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

const DomaticFedWire = () => {
  const formData = useTypedSelector(
    (state) => getFormValues(MANAGE_PAYMENT_METHOD_FORM)(state) as ManagePaymentMethodFormDataType | null,
  );
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);
  return (
    <>
      <Row>
        <Col md="4">
          <PaymentType label="Payment Type:" required validations={[required]} />
        </Col>
        <Col md="4">
          <CurrencyCode
            label="Currency Code:"
            required
            validations={[required]}
            currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
          />
        </Col>
      </Row>
      {formData?.currency_code === "USD" && (
        // TODO: make saparate tempalte for usdFedWire
        <Row>
          <Col md="4">
            <AccountName label={"Account Holder:(Full Name/Corp Name)"} required validations={[required]} />
          </Col>
          <Col md="4">
            <AccountNumber label="Account Number:" required validations={[required]} />
          </Col>

          <Col md="4">
            {!formData?.id && (
              <ConfirmAccountNumber label={"Confirm Account Number:"} required validations={[required]} />
            )}
          </Col>
          <Col md="4">
            <AccountType
              label="Account Type:"
              required
              validations={[required]}
              options={[
                { label: "-- select --", value: "" },
                { label: "Checking", value: "checking" },
                { label: "Saving", value: "saving" },
              ]}
            />
          </Col>
          <Col md="4">
            <BankRouting label="Fedwire Routing #:" required validations={[required]} />
          </Col>
          <Col md="4">
            <BankName label="Bank Name:" required validations={[required]} />
          </Col>
          <Col md="4">
            <DeliveryMethod label={"Delivery Method"} />
          </Col>
        </Row>
      )}
      <Row>
        <Col md="12">
          <Address1 label={"Bank Address line:"} />
        </Col>
        <Col md="4">
          <City label="City" />
        </Col>
        <Col md="4">
          <State label="State/Province" />
        </Col>
        <Col md="4">
          <ZipCode label="Zip Code/Postal Code: " />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Status label={"Status:"} />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex justify-content-end">
          <Primary label="Default for future invoice payment method" />
        </Col>
      </Row>
    </>
  );
};

export default DomaticFedWire;
