import React from "react";
import InputFilter from "../reportFilters/input";

const VendorContactListing = ({ scheduled }: { scheduled?: boolean }) => {
  return (
    <div>
      <InputFilter name={"Vendor Name"} code={"vendor_name"} options={[]} />
      <InputFilter name={"Subsidiary Name"} code={"subsidiary_name"} options={[]} />
    </div>
  );
};

export default VendorContactListing;
