import React from "react";
import { checkNameWithEmail } from "../../../../services/admin/invoices/emailSvc";
import { checkDateFromNow } from "../../../../services/date/date";

export type emailHeadingPropsType = {
  email: any;
};

const EmailHeading = ({ email }: emailHeadingPropsType) => {
  return (
    <div className={"px-0 col-12 d-flex"}>
      <p className={"flex-grow-1 text-dark"}>
        <b>{checkNameWithEmail(email.from)}</b>
      </p>
      <p className={"pr-3 text-dark float-right"}>{checkDateFromNow(email.received_at)}</p>
    </div>
  );
};
export default EmailHeading;
