import React from "react";
import { DropdownButton } from "react-bootstrap";
import RestApi from "../../../../providers/restApi";
import style from "./email.module.css";
import usePermission from "../../../common/hooks/usePermission";
import { Link } from "react-router-dom";

const restApiService = new RestApi();

export type emailDataLinkPropsType = {
  data: Array<any>;
  email: any;
};

const EmailDataLinks = ({ data, email }: emailDataLinkPropsType) => {
  const { hasUserPermission } = usePermission();
  const hasDraftPermission = hasUserPermission("editInvoiceInbox") || hasUserPermission("editDraftInbox");
  const hasNewInboxPermission = hasUserPermission("addInvoiceInbox") || hasUserPermission("addNewInbox");
  const hasInvoicesPermission = hasUserPermission("readInvoices");
  const hasInboundReceiptPermission = hasUserPermission("listExpenseReports");

  let title = data[0].number || data[0].filename;
  data.forEach((element) => {
    if (element.type === "invoice" && element.hasOwnProperty("status") && element.status === "DRAFT") {
      element.href = "invoices/draft_inbox/" + element.id + "/edit";
      element.hasNoPermission = !hasDraftPermission;
    } else if (element.type === "invoice" && element.hasOwnProperty("status") && element.status === "NEW") {
      element.href = "invoices/new_inbox/" + element.id + "/edit";
      element.hasNoPermission = !hasNewInboxPermission;
    } else if (element.type === "invoice") {
      element.href = "invoices/" + element.id;
      element.hasNoPermission = !hasInvoicesPermission;
    } else if (element.type === "receipt") {
      element.href = "/ap/inbound_receipts/" + element.id;
      element.hasNoPermission = !hasInboundReceiptPermission;
    }
  });

  if (data && data.length) {
    return (
      <DropdownButton title={title} size="sm" variant="" className={"invoiceDatalinks border-white"}>
        {data.map((dataObj, index) => {
          return (
            <>
              {dataObj.type === "invoice" && (
                <a
                  key={index}
                  href={restApiService.angularBaseURL() + dataObj.href}
                  target={"_blank"}
                  className={
                    "ml-2 d-block mb-2 " + style.invoiceLink + " " + (dataObj.hasNoPermission ? style.disabledLink : "")
                  }
                  style={{ color: "#FF5C35", wordBreak: "break-word" }}
                  rel="noreferrer"
                >
                  {dataObj.number}
                </a>
              )}
              {dataObj.type === "receipt" && (
                <>
                  <Link
                    to={dataObj.href}
                    className={
                      "ml-2 d-block mb-2 " +
                      style.invoiceLink +
                      " " +
                      (dataObj.hasNoPermission ? style.disabledLink : "")
                    }
                  >
                    {dataObj.filename}
                  </Link>
                </>
              )}
              {dataObj.type !== "receipt" && dataObj.type !== "invoice" && (
                <a
                  key={index}
                  href={restApiService.angularBaseURL() + "#/credit_memo_inbox/" + dataObj.id}
                  target={"_blank"}
                  className={
                    "pl-2 d-block mb-2 " + style.invoiceLink + " " + (dataObj.hasNoPermission ? style.disabledLink : "")
                  }
                  rel="noreferrer"
                >
                  {dataObj.filename}
                </a>
              )}
            </>
          );
        })}
      </DropdownButton>
    );
  } else {
    return <h5 className={"ml-2 " + style.invoiceLink}>invoice not found</h5>;
  }
};

export default EmailDataLinks;
