import { Col, Row } from "react-bootstrap";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import { IntegrationVirtualCard as IntegrationVirtual } from "../../components/integrationComponents/integrationVirtualCard";

const IntegrationVirtualCard = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <IntegrationVirtual />
      </Row>
    </Col>
  );
};

export { IntegrationVirtualCard };
