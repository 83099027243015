import React, { useRef, useState } from "react";
import { ReactComponent as DeleteIcon } from "./../../assets/workflow/ic-delete.svg";
import Style from "./workFlow.module.css";
import {
  setActiveStepIndex,
  setIsInactive,
  setLastStepSaveFlag,
  setWorkFlowSteps,
} from "../../reducers/approvalSettingsReducers";
import { useDispatch, useSelector } from "react-redux";
import { CreateNotification, NotificationType } from "../../services/general/notifications";
import RestApi from "../../providers/restApi";
import { destroy, initialize } from "redux-form";
import _ from "lodash";
import ConfirmationModal from "../common/modals/confirmation";
import { Link, useHistory } from "react-router-dom";

const DragAndDrop = () => {
  const restApiService = new RestApi();
  const draggingItem = useRef();
  const dragOverItem = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state.approvalSettingsReducer);

  const changeWorkFlowSteps = (newSteps) => {
    dispatch(setWorkFlowSteps(newSteps));
  };

  // const onChangeApprovalFormData = (formData) => {
  //   dispatch(setApprovalFormData(formData));
  // };

  // delete confirmation logic
  const [deletingStepIndex, setDeletingStepIndex] = useState(null);
  const [deletingStepData, setDetetingStep] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = (index, workflowStep) => {
    setDeletingStepIndex(index);
    setDetetingStep(workflowStep);
    setDeleteModalOpen(true);
  };

  const onCloseCallback = () => {
    setDeletingStepIndex(null);
    setDetetingStep(null);
    setDeleteModalOpen(false);
  };

  const onSaveCallback = () => {
    removeStep(deletingStepIndex, deletingStepData);
  };

  const onChangeActiveStepIndex = (step) => {
    dispatch(setActiveStepIndex(step));
  };

  const removeStep = async (index, workflowStep) => {
    // delete from server
    if (workflowStep.id) {
      try {
        setIsDeleting(true);
        const result = await restApiService.delete(`approval_workflows/${workflowStep.id}`);

        if (result) {
          CreateNotification(
            "Approval Workflow ",
            `Approval Workflow ${workflowStep.label} Deleted`,
            NotificationType.success,
          );
          if (index > -1) {
            state.workFlowSteps.splice(index, 1);
            // onChangeApprovalFormData({...state.workFlowSteps[index - 1]});
            dispatch(destroy("workFlowApprovalForm"));
            dispatch(destroy("testTriggerForm"));
            setTimeout(() => {
              dispatch(initialize("workFlowApprovalForm", { ...state.workFlowSteps[index - 1] }));
              dispatch(initialize("testTriggerForm", { ...state.workFlowSteps[index - 1] }));
              onChangeActiveStepIndex(index - 1);
              history.push(`/ap/approval_workflows/${state.workFlowSteps[index - 1].id}`);
            }, 500);
          }

          const reArrengedList = state.workFlowSteps.map((listItem, index) => {
            listItem.priority = index + 1;
            return listItem;
          });

          changeWorkFlowSteps(reArrengedList);
          onCloseCallback();
          setIsDeleting(false);
        }
      } catch (error) {
        CreateNotification(
          "Approval Workflow ",
          ` Error in Deleting Approval Workflow ${workflowStep.label}`,
          NotificationType.danger,
        );
        setIsDeleting(false);
        onCloseCallback();
      }
    }

    if (!workflowStep.id) {
      if (index > -1) {
        state.workFlowSteps.splice(index, 1);
        // onChangeApprovalFormData({...state.workFlowSteps[index - 1]});
        dispatch(destroy("workFlowApprovalForm"));
        dispatch(destroy("testTriggerForm"));
        setTimeout(() => {
          dispatch(initialize("workFlowApprovalForm", { ...state.workFlowSteps[index - 1] }));
          dispatch(initialize("testTriggerForm", { ...state.workFlowSteps[index - 1] }));
          onChangeActiveStepIndex(index - 1);
          history.push(`/ap/approval_workflows/${state.workFlowSteps[index - 1].id}`);
        }, 500);
      }

      const reArrengedList = state.workFlowSteps.map((listItem, index) => {
        listItem.priority = index + 1;
        return listItem;
      });
      changeWorkFlowSteps(reArrengedList);
      onCloseCallback();
      // emable new step adding
      dispatch(setLastStepSaveFlag(true));
    }
  };

  const handleDragStart = (e, position) => {
    draggingItem.current = position;
  };

  const handleDragEnter = (e, position) => {
    if (!state.isLastStepSaved) return;
    dragOverItem.current = position;
    const listCopy = [...state.workFlowSteps];
    const draggingItemContent = listCopy[draggingItem.current];
    listCopy.splice(draggingItem.current, 1);
    listCopy.splice(dragOverItem.current, 0, draggingItemContent);

    draggingItem.current = dragOverItem.current;
    dragOverItem.current = null;

    const reArrengedList = listCopy.map((listItem, index) => {
      listItem.priority = index + 1;
      return listItem;
    });

    changeWorkFlowSteps(reArrengedList);
    onActiveChange(draggingItemContent, position);
  };

  const onActiveChange = (item, activeStepIndex) => {
    // NOTE: here i used settime out which literally hate use
    // due state updating issue, if i ran initialize form without
    // settimeout, it overlapps to previous state gives wrong out put.
    dispatch(destroy("workFlowApprovalForm"));
    dispatch(destroy("testTriggerForm"));
    setTimeout(() => {
      dispatch(initialize("workFlowApprovalForm", item));
      dispatch(initialize("testTriggerForm", item));
    }, 300);
    // onChangeApprovalFormData(item);
    item.status == "INACTIVE" ? dispatch(setIsInactive(true)) : dispatch(setIsInactive(false));
    onChangeActiveStepIndex(activeStepIndex);
  };

  const onDragEnd = async () => {
    /**
     *  Update Backend priority for all workflow
     */
    var priorities = [];
    state.workFlowSteps.forEach((workFlow) => {
      priorities.push({ id: workFlow.id, priority: workFlow.priority });
    });
    try {
      restApiService.patch(
        `approval_workflows/reorder`,
        {},
        {
          priorities: priorities,
          workflow_type: state.workFlowType,
        },
      );
    } catch (error) {
      console.log(error.message);
      CreateNotification("Approval Workflow ", `${error.message}`, NotificationType.danger);
    }
  };

  return (
    <div>
      {/* showing overlay until deleting */}
      {isDeleting && <div className={Style.overlay} />}

      {Boolean(state.workFlowSteps.length) &&
        state.workFlowSteps.map((item, index) => (
          <div
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => handleDragEnter(e, index)}
            onDragEnd={onDragEnd}
            key={item.id}
            draggable={state.isLastStepSaved ? true : false}
            className={`
            ${item.status === "INACTIVE" ? Style.inactiveWorkflow : ""}
            ${Style.workflowStep} d-flex align-content-center  ${
              state.activeStepIndex === index ? Style.activeWorkFlowStep : null
            } `}
            onClick={() => onActiveChange(item, index)}
          >
            <div
              className={`${Style.dragHandle} py-4`}
              style={{
                visibility: state.activeStepIndex === index ? "visible" : "hidden",
              }}
            >
              <div className={Style.dot}></div>
              <div className={Style.dot}></div>
              <div className={Style.dot}></div>
              <div className={Style.dot}></div>
              <div className={Style.dot}></div>
              <div className={Style.dot}></div>
            </div>

            <div className={Style.gridContainer}>
              <div className="font-weight-bold m-3">{item.priority}.</div>
              <Link to={`/ap/approval_workflows/${item.id ? item.id : "new_approval"}`}>
                <div className="m-3">{item.value.label}</div>
              </Link>
              <div className="m-3">
                {/* TODO: Uncomment delete icon after backend is fixed. */}
                {/* <DeleteIcon
                  onClick={() => onDelete(index, item)}
                  style={{ cursor: "pointer" }}
                /> */}
              </div>
            </div>
          </div>
        ))}
      {deleteModalOpen && (
        <ConfirmationModal
          show={deleteModalOpen}
          title={"Delete workflow step"}
          body={"Do really want to delete these workflow step?"}
          saveCallback={onSaveCallback}
          closeCallback={onCloseCallback}
        />
      )}
    </div>
  );
};

export default DragAndDrop;
