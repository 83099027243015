import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import { WrappedFieldProps } from "redux-form";
import useIsMounted from "components/common/hooks/useIsMounted";

interface WareHousePickerPropsType extends WrappedFieldProps {
  label?: ReactNode;
  input: any;
  required?: boolean;
  tooltip?: string;
  disabled?: boolean;
  floating?: boolean;
}

type AmortizationTemplateObjType = {
  description?: string;
  external_id?: string;
  id?: number;
  name?: string;
  status?: string;
  label?: string;
};

const AmortizationTemplate = ({
  label,
  input,
  required,
  meta: { touched, error, warning },
  tooltip,
  disabled,
  floating = false,
}: WareHousePickerPropsType) => {
  const [amortizationsData, setAmortizationsData] = useState<AmortizationTemplateObjType[]>([]);
  const isMounted = useIsMounted();
  const [selected, setSelected] = useState<AmortizationTemplateObjType>();

  const parseForSelect = (options: AmortizationTemplateObjType[]) => {
    return options.map((option: AmortizationTemplateObjType) => {
      return {
        value: option.id,
        label: option.name,
        status: option.status,
      };
    });
  };

  const getGrants = useCallback(async () => {
    const result: AxiosResponse = await restApiService.get(`amortizations`, { ACTIVE: true }, null, true, null, true);
    if (result?.data) {
      let list = parseForSelect(result?.data);
      if (isMounted.current) {
        setAmortizationsData(list);
      }
      return list;
    }
  }, []);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        const result = await restApiService.get("warehouses/" + input.value);
        const inactiveGrant = {
          value: result.data.id,
          label: (
            <>
              {result.data.name}
              {result.data.status === "INACTIVE" && (
                <small style={{ fontSize: "10px" }}> -({result.data.status})</small>
              )}
            </>
          ),
        };
        selectedValues = inactiveGrant;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  useEffect(() => {
    getGrants().then((res) => {
      findSelected(input, res).then((response: AmortizationTemplateObjType) => {
        setSelected(response);
      });
    });
  }, []);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={selected}
          onChange={(selected) => onChangeSingle(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={amortizationsData}
          disable={disabled}
          getOptionLabel={(option: AmortizationTemplateObjType) => {
            return (
              <>
                {option.label}
                {option.status === "INACTIVE" && <small>{option.status}</small>}
              </>
            );
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
            }),
            ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
          }}
          {...(floating ? { menuPortalTarget: document.body } : {})}
        />
        {error && touched && <div className="pickerError">{error}</div>}
      </Form.Group>
    </>
  );
};

export default AmortizationTemplate;
