import { companyInfoType } from "../types/company.types";

//only copy over the properties we need. Address is its own variable so it needs to map seperately
export const mapCompanyInfoToPayload = (formData: companyInfoType) => {
  return {
    name: formData.name,
    email: formData.email,
    phone: formData.phone,
    address: {
      country: formData.country,
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      state: formData.state,
      zipcode: formData.zipcode,
      id: formData.addressId,
    },
    ein: formData.ein,
    ssn: formData.masked_ssn,
    is_1099_eligible: formData.is_1099_eligible,
  };
};
