import React, { useEffect, useState } from "react";
import useIsMounted from "components/common/hooks/useIsMounted";
import Panel from "components/common/panel/panel";
import styles from "../vendors.module.css";
import { VendorApis } from "services/admin/vendors/vensorApis";
import { VendorPortalStatsType } from "services/admin/vendors/vendorTypes";
import { Col, ProgressBar, Row } from "react-bootstrap";
import TooltipRender from "components/toolTip/tooltipRender";

type VendorPortalStatsPropsType = {
  setExternalFilter: (obj: { [key: string]: any }) => void;
  externalFilter: { [key: string]: any };
};

const VendorPortalStats = ({ setExternalFilter, externalFilter }: VendorPortalStatsPropsType) => {
  const [vpStats, setVpStats] = useState<VendorPortalStatsType>();
  const isMounted = useIsMounted();

  const getVendorPortalStats = async () => {
    try {
      const response = await VendorApis.getVendorPortalStats();
      if (isMounted.current) {
        setVpStats(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVendorPortalStats();
  }, []);
  return (
    <Panel
      header={
        <div className={styles.vpStatsHeading}>
          <i className={`icon icon-stats px-mt-3 mr-2`}></i>Vendor Portal Stats
        </div>
      }
    >
      <Row>
        <Col md="12" className="text-center">
          <h4 className={styles.totalVendorHeading}>
            Total Vendors: {vpStats?.total_vendors_count ? vpStats.total_vendors_count : 0}
            <span className="d-inline-block">
              <TooltipRender
                title="This represents the overall amount of vendors you currently have, including both active and inactive vendors"
                className="icon icon-info-blue"
                placement="top"
              />
            </span>
          </h4>
        </Col>
      </Row>
      <Row className={styles.progressInfo}>
        <Col md="12">
          {vpStats && (
            <ProgressBar className={styles.progressBar} max={vpStats.total_vendors_count}>
              <ProgressBar
                className={styles.nonVp + (vpStats.total_vp_count > 0 && vpStats.total_non_vp_count > 0 && " px-mr-2")}
                now={vpStats.total_non_vp_count}
                max={vpStats.total_vendors_count}
                key={1}
              />
              <ProgressBar
                className={
                  styles.nonCompletedVp +
                  " " +
                  (vpStats.total_vp_count - vpStats.total_unfinished_profile_count > 0 &&
                    vpStats.total_unfinished_profile_count > 0 &&
                    "px-mr-2")
                }
                now={vpStats.total_unfinished_profile_count}
                max={vpStats.total_vendors_count}
                key={2}
              />
              <ProgressBar
                className={styles.completedVp}
                now={vpStats.total_vp_count - vpStats.total_unfinished_profile_count}
                max={vpStats.total_vendors_count}
                key={3}
              />
            </ProgressBar>
          )}
        </Col>
      </Row>
      <Row className="mx-2 mt-5 d-flex justify-content-around">
        <Col md="4">
          <Row>
            <Col sm="1" className="mr-2">
              <div className={styles.nonVp + " " + styles.colorTypeSize}></div>
            </Col>
            <Col sm="10" className={styles.colorDetail}>
              <p>Vendors that have not signed up for Vendor Portal</p>
            </Col>
            <Col sm="11" className={"text-center mt-1 " + styles.colorInfo}>
              <h5>
                {vpStats?.total_non_vp_count ? vpStats.total_non_vp_count : 0} /{" "}
                {vpStats && vpStats.total_vendors_count > 0
                  ? ((vpStats.total_non_vp_count / vpStats.total_vendors_count) * 100).toFixed()
                  : 0}
                %
              </h5>
              <button onClick={() => setExternalFilter({ non_vendor_portal_vendors: true })}>Apply Filter</button>
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Row>
            <Col sm="1" className="mr-2">
              <div className={styles.nonCompletedVp + " " + styles.colorTypeSize}></div>
            </Col>
            <Col sm="10" className={styles.colorDetail}>
              <p>Vendors that have signed up but have not completed their profile</p>
            </Col>
            <Col sm="11" className={"text-center mt-1 " + styles.colorInfo}>
              <h5 className="text-center mt-1">
                {vpStats?.total_unfinished_profile_count ? vpStats.total_unfinished_profile_count : 0} /{" "}
                {vpStats && vpStats.total_vendors_count > 0
                  ? ((vpStats.total_unfinished_profile_count / vpStats.total_vendors_count) * 100).toFixed()
                  : 0}
                %
              </h5>
              <button onClick={() => setExternalFilter({ unfinished_vendor_profiles: true })}>Apply Filter</button>
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Row>
            <Col sm="1" className="mr-2">
              <div className={styles.completedVp + " " + styles.colorTypeSize}></div>
            </Col>
            <Col sm="10" className={styles.colorDetail}>
              <p>Vendors that have signed up and have completed their profile</p>
            </Col>
            <Col sm="11" className={"text-center mt-1 " + styles.colorInfo}>
              <h5 className="text-center mt-1">
                {vpStats?.total_vp_count ? vpStats.total_vp_count - vpStats.total_unfinished_profile_count : 0} /{" "}
                {vpStats && vpStats.total_vendors_count > 0
                  ? (
                      ((vpStats.total_vp_count - vpStats.total_unfinished_profile_count) /
                        vpStats.total_vendors_count) *
                      100
                    ).toFixed()
                  : 0}
                %
              </h5>
              <button
                onClick={() =>
                  setExternalFilter({
                    signed_up_and_completed_profile: true,
                  })
                }
              >
                Apply Filter
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Panel>
  );
};

export default VendorPortalStats;
