import React from "react";
import DropdownFilter from "../reportFilters/dropdown";
import InputFilter from "../reportFilters/input";

const AlertsAndNotificationfilters = ({}) => {
  const card_status_options = [
    { value: "", label: "All" },
    { value: "PENDING", label: "PENDING" },
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
    { value: "APPROVED", label: "APPROVED" },
    { value: "REJECTED", label: "REJECTED" },
    { value: "BLOCKED", label: "BLOCKED" },
    { value: "TEMPORARY BLOCK", label: "TMP-BLOCKED" },
  ];
  return (
    <div>
      <DropdownFilter name={"Card Status"} code={"status"} options={card_status_options} />
      <InputFilter name={"Account Code"} code={"account_code"} options={[]} />
      <InputFilter name={"Customer ID"} code={"customer_id"} options={[]} />
    </div>
  );
};

export default AlertsAndNotificationfilters;
