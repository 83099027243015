import React from "react";
import { useTranslation } from "react-i18next";
import { length9, noWhiteSpaceOnly, required } from "../../../../services/validations/reduxFormValidation";
import BankRoutingSample from "../../../common/bankingRoutingSample/bankRoutingSample";
import AccountHolderName from "../fields/accountHolderName";
import ContactFirstName from "../fields/contactFirstName";
import ContactLastName from "../fields/contactLastName";
import PaymentTypeSelect from "../fields/paymentTypeSelect";
import AccountExternalIdField from "../fields/accountExternalIdField";
import AddressExternalIdField from "../fields/addressExternalIdField ";
import CurrencyCode from "../fields/currencyCode";
import BankAccountNumber from "../fields/bankAccountNumber";
import BankName from "../fields/bankName";
import BankRouting from "../fields/bankRouting";
import Memo from "../fields/memo";
import DebitCreditFields from "../fields/debitCreditFields";
import CheckPayoutCheckBoxes from "../fields/checkPayoutCheckBoxes";
import { useDispatch, useSelector } from "react-redux";
import { change, getFormValues } from "redux-form";
import { COMPANY_PAYMENT_METHOD_FORM_NAME } from "../../../../reducers/admin/companyPaymentMethodFormReducer";
import Status from "../fields/status";

const Check = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const current_user = useSelector((state) => state.user);

  const bankAddressCallBack = (bankAdd) => {
    dispatch(change(COMPANY_PAYMENT_METHOD_FORM_NAME, "form.bank_address", bankAdd));
  };
  const formState = useSelector((state) => getFormValues(COMPANY_PAYMENT_METHOD_FORM_NAME)(state));
  const form = formState?.form;
  return (
    <>
      <PaymentTypeSelect />
      {current_user.is_root ? <Status label={t("companySettings.paymentMethodsSettings.status")} /> : null}
      <CheckPayoutCheckBoxes />
      <ContactFirstName
        label={t("companySettings.paymentMethodsSettings.contactFirstName")}
        tooltip={<BankRoutingSample />}
      />
      <ContactLastName
        label={t("companySettings.paymentMethodsSettings.contactLastName")}
        tooltip={<BankRoutingSample />}
      />
      <AccountHolderName
        label={t("companySettings.paymentMethodsSettings.accountHolderName")}
        isAccountHolderRequired
        validations={[required, noWhiteSpaceOnly]}
        tooltip={<BankRoutingSample />}
      />
      {!form?.is_corpay_nvp && (
        <AccountExternalIdField label={t("companySettings.paymentMethodsSettings.bankAccountExternalId")} />
      )}
      <AddressExternalIdField label={t("companySettings.paymentMethodsSettings.addressExternalId")} />
      <CurrencyCode label={t("companySettings.paymentMethodsSettings.currencyCode")} />
      <BankAccountNumber
        label={t("companySettings.paymentMethodsSettings.bankAccountNumber")}
        isBankAccountRequired={true}
        validations={[required, noWhiteSpaceOnly]}
        tooltip={<BankRoutingSample />}
      />
      <BankName label={t("companySettings.paymentMethodsSettings.bankName")} />
      <BankRouting
        isBankRountingRequired
        label={t("companySettings.paymentMethodsSettings.bankRoutingNumber")}
        validations={[required, length9, noWhiteSpaceOnly]}
        tooltip={<BankRoutingSample />}
        bankAddressCallBack={bankAddressCallBack}
      />
      <Memo label={t("companySettings.paymentMethodsSettings.memo")} />
      <DebitCreditFields />
    </>
  );
};

export default Check;
