import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";

export default class MyFooterValueGetter extends Component {
  render() {
    return (
      <div>
        <ListGroup style={{ fontSize: "14px", fontFamily: "Acre" }}>
          <ListGroup.Item action onClick={this.onClearFilter.bind(this)}>
            Clear all filters
          </ListGroup.Item>
          <ListGroup.Item action onClick={this.onAllExport.bind(this)}>
            Export all data as csv
          </ListGroup.Item>
          <ListGroup.Item action onClick={this.onBtExport.bind(this)}>
            Export visible data as csv{" "}
          </ListGroup.Item>
          <ListGroup.Item action onClick={this.onExcelExport.bind(this)}>
            Export to Excel
          </ListGroup.Item>
        </ListGroup>
      </div>
    );
  }
}
