import useCollapsible from "components/common/hooks/useCollapsible";
import Panel from "components/common/panel/panel";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./customFields.module.css";

type CustomFieldsPropType<T> = {
  model: T & {
    custom_fields?: any;
  };
  noPanel?: boolean;
  inline?: boolean;
};

const CustomFields = <T,>({ model, noPanel, inline }: CustomFieldsPropType<T>) => {
  const { collapseHandlerUi, collapsed } = useCollapsible({ defaultCollapse: true });
  const { t } = useTranslation();
  // this function is helper on the top of translation function (t) so don't have write components.admin.activities. multiple times
  const translateByLabel = (key: string) => t(`components.admin.customFields.${key}`);

  const isUrl = (str: string) => {
    const regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(str);
  };

  const getLink = (link: string) => {
    return link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`;
  };

  if (noPanel) {
    return (
      <div className={inline ? "d-flex" : ""}>
        {Object.entries(model.custom_fields || {}).map(([key, customField]: [string, any]) => {
          if (customField.type !== "label" && customField.value) {
            return (
              <div className={style.px12}>
                <div>{typeof key === "object" ? JSON.stringify(key) : key}:</div>
                <div className="text-primary">
                  {!isUrl(customField.value) &&
                    (typeof customField.value === "object" ? JSON.stringify(customField.value) : customField.value)}
                  {isUrl(customField.value) && (
                    <a href={getLink(customField.value)} target="_blank" rel="noopener noreferrer">
                      Link
                    </a>
                  )}
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }

  return (
    <Panel
      header={
        <div className={" d-flex justify-content-between"}>
          <div>
            <i className={`icon icon-data ${style.mr4}`}></i> {translateByLabel("customFields").toUpperCase()}
          </div>
          {collapseHandlerUi}
        </div>
      }
      hideCardBody={collapsed}
    >
      <Row>
        <Col xs="12">
          <dl>
            {Object.entries(model.custom_fields || {}).map(
              ([key, customField]: [string, any]) =>
                customField.type !== "label" &&
                customField.label &&
                customField.value && (
                  <React.Fragment key={key}>
                    <dt>{customField.label}:</dt>
                    <dd className="text-primary">
                      {!isUrl(customField.value) && (
                        <>{customField.value.value ? customField.value.value : customField.value}</>
                      )}
                      {isUrl(customField.value) && (
                        <a href={getLink(customField.value)} target="_blank" rel="noopener noreferrer">
                          Link
                        </a>
                      )}
                    </dd>
                  </React.Fragment>
                ),
            )}
          </dl>
        </Col>
      </Row>
    </Panel>
  );
};

export default CustomFields;
