import { AxiosResponse } from "axios";
import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/hookFormFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import Select from "react-select";

type TUnitPicker = {
  id?: number | string | null;
  name: string;
  label?: string;
  rules?: RegisterOptions;
  required?: boolean;
  modelData?: string;
  containerClassName?: string;
  callBack?: (selected: TUnitProps) => void;
};

export type TUnitProps = {
  id?: number | string | null;
  name?: string;
  display_name?: string;
  external_id?: string;
  conversion_rate?: number;
  base_unit?: boolean;
  status?: string;
  unit_type_id?: number;
  company_id?: number;
  created_at?: string;
  updated_at?: string;
};

const UnitPicker = ({ id, name, label, required, modelData, callBack, containerClassName }: TUnitPicker) => {
  const [options, setOptions] = useState<TUnitProps[]>([]);
  const isMounted = useIsMounted();
  const { control, getValues, setValue } = useFormContext();
  const [inactivePopulated, setInactivePopulated] = useState(false);
  const unitId = getValues("unit_id");

  const sortedOptions = useMemo(() => {
    if (!options || options.length === 0) return [];

    const defaultOption: TUnitProps = { display_name: "-- Select Unit--", id: "" };
    const sorted = _.sortBy(options, ["code"]);
    return required ? sorted : [defaultOption, ...sorted];
  }, [options, required]);

  const isInactive = (status: string) => {
    return status === "INACTIVE";
  };

  const getInactiveUnits = async () => {
    try {
      const response: AxiosResponse = await restApiService.get("units/" + unitId);
      if (_.isPlainObject(response?.data)) {
        const inactiveObj = response?.data;
        if (inactiveObj) {
          if (isMounted.current) {
            const obj = {
              ...inactiveObj,
              label: (
                <span key={inactiveObj.id}>
                  {inactiveObj.name}
                  {isInactive(inactiveObj.status) && (
                    <span key={inactiveObj.id + "-inactive"} className="inactiveOption">
                      (INACTIVE)
                    </span>
                  )}
                </span>
              ),
              value: inactiveObj.id,
            };
            setInactivePopulated(true);
            setValue(name, inactiveObj.id); // Make sure the component is still mounted here.
          }
        }
      } else {
        setValue(name, null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const mergeInactive = async (options: TUnitProps[]) => {
    const matchingOption = options?.find((option) => option.id === unitId);

    if (matchingOption) {
      setValue(name, matchingOption.id);
    } else if (!inactivePopulated && unitId) {
      await getInactiveUnits();
    }
  };

  const getUnits = useCallback(async () => {
    const params: Record<string, string> = { status: "ACTIVE" };
    let item = getValues(modelData as string);
    if (item.product_item?.unit_type_id) {
      params.unit_type_id = item.product_item.unit_type_id;
    }

    try {
      const result: AxiosResponse = await restApiService.get(`units.lk`, params, null, true, null, true);
      if (Array.isArray(result.data)) {
        const options = [...result.data];
        mergeInactive(options);
        if (isMounted.current) {
          setOptions(options);
        }
      }
    } catch (error) {
      console.error("Error fetching tax codes:", error);
    }
  }, [isMounted]);

  useEffect(() => {
    getUnits();
  }, [getUnits]);

  return (
    <Form.Group className={` ${containerClassName ?? ""}`}>
      {label && (
        <label className="pickerLabel">
          {label} <Mandatory required={required} />
        </label>
      )}

      <Controller
        control={control}
        name={name}
        rules={{ required: required ? "This field is required" : false }}
        render={({ field: { value, onChange, ...field }, fieldState }) => (
          <>
            <Select
              {...field}
              isClearable={!required}
              value={sortedOptions.find((option) => option.id === value) || null}
              getOptionLabel={(option) => option.display_name || ""}
              getOptionValue={(option) => option.id?.toString() || ""}
              options={sortedOptions}
              required={required}
              className="pickerSelectedOption"
              menuPosition="fixed"
              onChange={(selected) => {
                onChange(selected?.id || null);
                if (modelData) {
                  setValue(`${modelData}.unit`, selected);
                }
                if (callBack) {
                  // Ensure callBack is only called with a valid object, not null
                  callBack(selected || ({} as TUnitProps));
                }
              }}
            />
            {fieldState.error && <PickerErrorBlock error={fieldState.error.message || ""} />}
          </>
        )}
      />
    </Form.Group>
  );
};

export default UnitPicker;
