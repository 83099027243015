import { restApiService } from "providers/restApi";
import { AxiosResponse } from "axios";

interface RequestOptions {
  endpoint: string;
  getParams?: Record<string, any> | null;
  body?: Record<string, any> | null;
  authorized?: boolean;
  responseType?: string | null;
  cache?: boolean;
}

export default class ConcurrentRequests {
  async get(requests: RequestOptions[] = []): Promise<({} | AxiosResponse<any>)[]> {
    try {
      const responses = await Promise.all(requests.map((req) => this.fetchData(req)));
      return responses;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // PRIVATE METHODS
  private async fetchData({
    endpoint,
    getParams = null,
    body = null,
    authorized = true,
    responseType = null,
    cache = false,
  }: RequestOptions): Promise<AxiosResponse | { error: any }> {
    try {
      const response = await restApiService.get(endpoint, getParams, body, authorized, responseType, cache);
      return response;
    } catch (error) {
      return { error: error };
    }
  }
}

export const concurrentRequestsService = new ConcurrentRequests();
