import React, { useState } from "react";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { minLength, required } from "../../../../services/validations/reduxFormValidation";
import { useTranslation } from "react-i18next";
import { PaymentMethodValidatorSvc } from "../paymentMethodValidatorSvc";
import { IDType } from "../../../../services/common/types/common.type";

type IbanValidatorPropsType = {
  modelName?: string;
  maxLength?: number;
  callBack?: (arg: IDType) => void;
  noValidate?: boolean;
  isNotRequired?: boolean;
  label?: string;
};
const min2 = minLength(2);

const IbanValidator = ({
  modelName,
  maxLength,
  callBack,
  noValidate,
  isNotRequired,
  label,
}: IbanValidatorPropsType) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState("");
  const validateIban = async (value: string | number) => {
    if (value && !noValidate) {
      try {
        const result = await PaymentMethodValidatorSvc.ValidateIban(value);
        if (result.validation) {
          setValidationMessage(result.validation);
        } else {
          setValidationMessage(result.errors[0]?.message);
        }
      } catch (error) {
        console.log(error);
      }
      if (callBack) {
        callBack(value);
      }
    }
  };

  return (
    <>
      <Field
        name={`${modelName}.iban`}
        component={RenderField}
        type="text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateIban(e.target.value)}
        minLength={2}
        maxLength={maxLength}
        required={isNotRequired ? false : true}
        validate={isNotRequired ? [required, min2] : []}
        label={label ? label : t("common.paymentMethod.iban")}
        tooltip={t("common.paymentMethod.ibanTooltip")}
      />
      {validationMessage && (
        <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
      )}
    </>
  );
};

export default IbanValidator;
