import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import StepsBar from "./stepsBar";
import CompanyInfoForm from "../../company/companyInfoForm";
import { useDispatch } from "react-redux";
import { restApiService } from "../../../../providers/restApi";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { setGuideCompanyInfo, setVendorGuideStep } from "../../../../reducers/vp/vendorSetUpGuideReducer";
import { useTypedSelector } from "../../../../reducers";
import { useTranslation } from "react-i18next";
import { mapCompanyInfoToPayload } from "../../../../services/vp/company/companyInfoSvc";
import { companyInfoType } from "../../../../services/vp/types/company.types";
import LoadingBox from "../../../common/loaders/loadingBox";

const CompanyDetailsStep4 = () => {
  const { t } = useTranslation();
  const companyDetailAdded: boolean = useTypedSelector(
    (state) => state.vendorSetupGuideRed.setupProgress.company_detail,
  );
  const companyInfo: companyInfoType = useTypedSelector((state) => state.vendorSetupGuideRed.companyInfo);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const toStep5 = () => {
    dispatch(setVendorGuideStep(5));
  };

  const getCompanyDetails = async () => {
    try {
      setLoading(true);
      const response = await restApiService.get("company");
      // maping response to company Info form
      dispatch(
        setGuideCompanyInfo({
          name: response.data.name,
          email: response.data.email,
          phone: response.data.phone,
          country: response.data?.address?.country,
          address1: response.data?.address?.address1,
          address2: response.data?.address?.address2,
          addressId: response.data?.address?.id,
          city: response.data?.address?.city,
          state: response.data?.address?.state,
          zipcode: response.data?.address?.zipcode,
          ein: response.data.ein,
          masked_ssn: response.data.masked_ssn,
          is_1099_eligible: response.data.is_1099_eligible,
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      CreateNotification("Error", "Please reach out to support", NotificationType.warning);
    }
  };

  const initCompanyDetailsStep = async () => {
    // show company detial form only if company detials are not added
    if (!companyDetailAdded) {
      await getCompanyDetails();
    } else {
      // other wise move to next step
      toStep5();
    }
  };

  const onSubmit = async (formData: companyInfoType) => {
    const body = mapCompanyInfoToPayload(formData);
    setIsSubmitDisabled(true);
    try {
      const response = await restApiService.patch("company", null, { company: body });
      setIsSubmitDisabled(false);
      if (response) {
        toStep5();
      }
    } catch (error) {
      console.log(error);
      setIsSubmitDisabled(false);
      CreateNotification(t("error"), t("errors.genericSupport"), NotificationType.danger);
    }
  };

  useEffect(() => {
    initCompanyDetailsStep();
  }, []);

  return (
    <LoadingBox isLoading={loading}>
      <Row>
        <Col className="d-flex flex-column justify-content-center align-items-center px-5">
          <Row>
            <Col className="my-4">
              <StepsBar step1 step2 step3 step4 />
            </Col>
          </Row>
          <Row>
            <h3>{t("vp.dashboard.vendorGuide.companyDetails")}</h3>
          </Row>
          <Row className="my-3 mx-0">
            <p>{t("vp.dashboard.vendorGuide.editCompanyInformation")}</p>
          </Row>
          <CompanyInfoForm formData={companyInfo} formSubmit={onSubmit} />

          <Row className="mt-3">
            <Button disabled={isSubmitDisabled} type="submit" form="companyInfoForm">
              {t("next")}
            </Button>
          </Row>
        </Col>
      </Row>
    </LoadingBox>
  );
};

export default CompanyDetailsStep4;
