class UserSvc {
  static getStoredUserDetails = (): { [key: string]: any } | null => {
    const userDetailsString: string | null = localStorage.getItem("userDetails");
    try {
      return userDetailsString ? JSON.parse(userDetailsString) : null;
    } catch (err) {
      return null;
    }
  };

  static isAdminOrInternalUser = (currentUser: any) => {
    const filteredArray = currentUser.roles.filter(function (role: any) {
      return (
        ["universal_admin", "admin", "application_admin", "admin_users", "super_admin", "system_admin", "root"].indexOf(
          role.name,
        ) !== -1
      );
    });
    return filteredArray.length > 0;
  };
}

export default UserSvc;
