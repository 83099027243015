import { Field, getFormValues } from "redux-form";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { RenderField } from "../../../../../../components/forms/bootstrapFields";
import { useSelector } from "react-redux";
import { companyDateFormat } from "../../../../../../services/general/dateSvc";
import _ from "lodash";
import { INTEGRATION_FORM_NAME } from "../../../../../../reducers/admin/integrationsReducer";

const IntegrationEdi = () => {
  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const currentUser = useSelector((state) => state.user);

  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <Col lg="12">
          <label>Transfer Info</label>
        </Col>
      </Row>
      <Row className="m-0">
        <Col lg="6">
          <Field
            name="form.edi_email_notification"
            component={RenderField}
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    "<label>Notification Emails</label><small>(banking@abc.com, accountmanagement@corpay.com)</small>",
                }}
              />
            }
            placeholder="banking@accrualify.com"
            type={"text"}
            id="edi_email_notification"
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.edi_email_notification_template"
            component={RenderField}
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: "<label>Notification Template</label><small>(Sendgrid Temlate ID)</small>",
                }}
              />
            }
            placeholder="banking@accrualify.com"
            type={"text"}
            id="edi_email_notification_template"
          />
        </Col>
      </Row>
      <Row className="m-0">
        <Col lg="12">
          <Form.Group className="mb-0">
            <Form.Label>Schedule</Form.Label>
            <table className="table table-striped table-hover">
              <thead>
                {_.isArray(form.schedules) && form.schedules.length > 0 && (
                  <tr>
                    <th>Service Name</th>
                    <th>Interval</th>
                    <th>24-hr Time</th>
                    <th>Last Runtime</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {_.isArray(form.schedules) &&
                  form.schedules.length > 0 &&
                  form.schedules.map((schedule, index) => (
                    <tr key={index} className="text-left">
                      <td>{schedule.service_name}</td>
                      <td>
                        {schedule.interval}
                        {schedule.day_of_week_word && (
                          <small>
                            <br /> ({schedule.day_of_week_word})
                          </small>
                        )}
                      </td>
                      <td align="center">
                        {schedule.hour_of_day}
                        <br />
                        <small>({schedule.hour_of_day_word})</small>
                      </td>
                      <td>{schedule.last_runtime && companyDateFormat(schedule.last_runtime, currentUser)}</td>
                    </tr>
                  ))}

                {_.isArray(form.schedules) && form.schedules.length < 1 && (
                  <tr>
                    <td colSpan="4" className="text-left">
                      There are no schedule created.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Form.Group>
        </Col>
      </Row>
    </Col>
  );
};

export default IntegrationEdi;
