import React from "react";

type ThumbnailPropType = {
  imageUrl: string;
  onClickThumbnail?: () => void;
  previewSize?: {
    height?: number | string;
    width?: number | string;
  };
};

const Thumbnail = ({ imageUrl, onClickThumbnail, previewSize }: ThumbnailPropType) => {
  return (
    <div
      className="show-image"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundClip: "content-box",
        backgroundSize: "100% 100%",
        border: "1px solid #000",
        width: previewSize?.width ?? 145,
        height: previewSize?.height ?? 145,
      }}
      onClick={(e) => {
        e.preventDefault();
        if (typeof onClickThumbnail === "function") {
          onClickThumbnail();
        }
      }}
    ></div>
  );
};

export default Thumbnail;
