import React, { useEffect, useState } from "react";
import BankAddressLine from "../bankAddressLine";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";
import CountryCurrencyCodePicker from "../../pickers/reduxFormPickers/countryCurrencyCodePicker";
import { required } from "../../../../services/validations/reduxFormValidation";
import { RenderField } from "../../../forms/bootstrapFields";
import IbanValidator from "../ibanValidator";
import { IDType } from "../../../../services/common/types/common.type";
import BicValidator from "../bicValidator";

const SepaPaymentMethod = ({ modelName, modelData }: { modelName?: string; modelData?: any }) => {
  const { t } = useTranslation();
  const [showSepaPaymentFields, setShowSepaPaymentField] = useState(false);
  const [ibanNumber, setIbanNumber] = useState<IDType>();

  useEffect(() => {
    if (modelData.iban) {
      setShowSepaPaymentField(true);
    }
  }, [modelData, modelData.iban]);

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.address_attributes.currencyObj`}
          component={CountryCurrencyCodePicker}
          paymentType="sepa"
          FieldClassName="formField"
          placeholder={"Search/Select"}
          required
          onChange={(value: React.ChangeEvent<HTMLInputElement>) => setShowSepaPaymentField(true)}
          validate={[required]}
          label={t("common.paymentMethod.countryAccountResides")}
        />
      </Col>
      {showSepaPaymentFields && (
        <>
          <Col md="6">
            <Field
              name={`${modelName}.account_name`}
              component={RenderField}
              type="text"
              label={t("common.paymentMethod.accountHolderName")}
              className={`w-100`}
              required
              validate={[required]}
            />
          </Col>
          <Col md="4">
            <IbanValidator modelName={`${modelName}`} callBack={(value) => setIbanNumber(value)} />
          </Col>
          <Col md="4">
            <BicValidator modelName={`${modelName}`} ibanNumber={ibanNumber} />
          </Col>
          <Col md="4">
            <Field
              name={`${modelName}.bank_name`}
              component={RenderField}
              type="text"
              label={t("common.paymentMethod.bankName")}
            />
          </Col>
        </>
      )}

      <BankAddressLine modelName={`${modelName}`} hideCountry={true} defaultCountry="AND" />
    </>
  );
};

export default SepaPaymentMethod;
