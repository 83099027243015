import _, { forEach } from "lodash";
import { ApprovalType } from "./approvalsType";
import ApprovalsApis from "./approvalsApis";
import { IDType } from "services/common/types/common.type";
import { restApiService } from "providers/restApi";
import { GridApi } from "ag-grid-community";
import { CreateNotification, NotificationType } from "services/general/notifications";

class ApprovalsService {
  getPreviousActionApprovable = <T extends ApprovalType.CommonApprovalListTypes>(
    approvalList: T[],
    id: string,
  ): T | void => {
    if (_.isArray(approvalList)) {
      const index = approvalList.findIndex((approvalItem) => approvalItem?.actionable_approval?.id === Number(id));
      const previouseIndex = index - 1;
      const lastIndex = approvalList.length - 1;
      if (previouseIndex >= 0) {
        return approvalList[previouseIndex];
      } else {
        return approvalList[lastIndex];
      }
    }
  };

  getNextActionApprovable = <T extends ApprovalType.CommonApprovalListTypes>(
    approvalList: T[],
    id: string,
  ): T | void => {
    if (_.isArray(approvalList)) {
      const index = approvalList.findIndex((approvalItem) => approvalItem?.actionable_approval?.id === Number(id));
      const lastIndex = approvalList.length - 1;
      const nextIndex = index + 1;
      if (nextIndex <= lastIndex) {
        return approvalList[nextIndex];
      } else {
        return approvalList[0];
      }
    }
  };

  isAllowClaim = function (approval: ApprovalType.ApprovalType, currentUser: any) {
    if (
      approval.email === currentUser.email &&
      currentUser?.company?.allow_claim_po &&
      !approval?.is_claimed &&
      (approval?.aasm_state === "sent" || approval.aasm_state === "viewed")
    ) {
      return true;
    }
    return false;
  };

  submit = async (approval: any, state: string, currentUser: any) => {
    approval.submitter = null;
    approval.submitter_attributes = {};
    approval.submitter_attributes.name = currentUser.name;
    approval.submitter_attributes.email = currentUser.email;
    approval.aasm_state = state;

    try {
      return await ApprovalsApis.updateApproval(Number(approval.id), { approval: approval });
    } catch (error) {}
  };

  resetApprovals = async ({ endpoint, id }: { endpoint: string; id: IDType }) => {
    try {
      const result = await restApiService.post("reset_approvals");
      return result;
    } catch (error) {
      throw error;
    }
  };

  // reset approval process
  resetBulkApprovals = async ({ gridApi, state, modal }: { gridApi: GridApi; state: string; modal: string }) => {
    const promise: Promise<{
      success: any[];
      fail: any[];
      count: number;
    }> = new Promise((resolve, reject) => {
      const result: { success: any[]; fail: any[]; count: number } = { success: [], fail: [], count: 0 };
      const rows = gridApi.getSelectedRows();
      if (_.isArray(rows) && rows.length < 1) {
        CreateNotification(`Please select  at least one ${modal}.`, `No ${modal} selected`, NotificationType.danger);
        reject(false);
      }

      rows.forEach(async (row) => {
        try {
          const response = await restApiService.post(`${state}/${row.id}/reset_approvals`);
          const obj: any = {};
          obj.number = row.number || row.payment_number || row.batch_number;
          result.success.push(obj);
          result.count++;
          if (rows.length === result.count) {
            resolve(result);
          }
        } catch (error: any) {
          const obj: any = {};
          obj.number = row.number || row.payment_number || row.batch_number;
          for (const key in error.response.data) {
            if (key === "base") {
              obj.msg = error.response.data[key];
            }
          }
          result.count++;
          if (rows.length === result.count) {
            resolve(result);
          }
          result.fail.push(obj);
        }
      });
    });
    return promise;
  };
}

const approvalsService = new ApprovalsService();
export default approvalsService;
