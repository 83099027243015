import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./prompts.module.css";
import InvoicesNavsTabs from "pages/admin/invoice/nav";
import Panel from "components/common/panel/panel";
import ListPrompts from "./listPrompts";
import PromptApis from "services/admin/promptMessages/promptApis";
import { CreateNotification, NotificationType } from "services/general/notifications";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import FormPrompt from "./form";

export type PromptsPropsType = {
  id?: number | string;
  name?: string;
  prompt_type?: string;
  content?: string;
};

const ManagePrompts = () => {
  const [prompts, setPrompts] = useState([]);
  const [formLabel, setFormLabel] = useState("Add");
  const formName = formLabel === "Add" ? "Add Prompt" : "Update Prompt";
  const [initialFormValues, setInitialFormValues] = useState({});

  const handleSubmit = async (formValues: PromptsPropsType) => {
    if (!formValues.prompt_type) {
      formValues.prompt_type = "invoice_automation";
    }

    const payload = {
      prompt: formValues,
    };

    try {
      let response;

      if (formValues.id) {
        response = await PromptApis.editPrompt(formValues.id, payload);
      } else {
        response = await PromptApis.addPrompt(payload);
      }
      if (response.id) {
        getPromptsList();
        CreateNotification(
          "Success",
          `Prompt message ${formValues.id ? " updated " : " added "} successfully.`,
          NotificationType.success,
        );
        setFormLabel("Add");
        setInitialFormValues({ name: undefined, content: undefined });
      }
    } catch (error: any) {
      const { response } = error;
      console.log(response);
    }
  };

  const getPromptsList = async () => {
    try {
      const response = await PromptApis.getPromptList();
      setPrompts(response);
    } catch (error: any) {
      const { response } = error;
      console.log(response);
    }
  };

  useEffect(() => {
    getPromptsList();
  }, []);

  const deletePrompt = async (id?: number | string) => {
    try {
      await PromptApis.deletePrompt(id);
      setPrompts((prevPrompts) => prevPrompts.filter((prompt: PromptsPropsType) => prompt?.id !== id));
      CreateNotification("Success", "Prompt message deleted successfully. ", NotificationType.success);
    } catch (error: any) {
      const { response } = error;
      console.log(response);
    }
  };

  const editPrompt = (prompt: PromptsPropsType) => {
    setFormLabel("Edit");
    setInitialFormValues(prompt);
  };

  return (
    <Container fluid className={styles.backgroundColor}>
      <Row>
        <Col md="12" className="removeHPseudoClass">
          <InvoicesNavsTabs activePageName="Prompts" />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Panel cardHeaderClass={styles.header} header={<div>{formName}</div>}>
            <ErrorBoundary>
              <Row>
                <Col>
                  <FormPrompt onSubmit={handleSubmit} initialValues={initialFormValues} />
                </Col>
              </Row>
            </ErrorBoundary>

            {prompts && prompts.length > 0 && (
              <Row className="px-mt-10">
                <Col>
                  <ListPrompts promptsList={prompts} editPrompt={editPrompt} deletePrompt={deletePrompt} />
                </Col>
              </Row>
            )}
          </Panel>
        </Col>
      </Row>
    </Container>
  );
};
export default ManagePrompts;
