export interface dashboardColorSettings {
  color: string;
  tooltipTextColor: string;
}

export interface DashboardColorScheme {
  colorsArray: dashboardColorSettings[];
}

class dashboardColorMap {
  getDashboardColorScheme = (): DashboardColorScheme => {
    const toHex = (value: number) => {
      const hex = value.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    const rgbToHex = (r: number, g: number, b: number): string => {
      return "#" + toHex(r) + toHex(g) + toHex(b);
    };
    const COLOR_WHITE = "#FFFFFF";
    const COLOR_BLACK = "#000000";

    // Colors definitions obtained from figma document
    // https://www.figma.com/design/7CbxRYJq7gslfgrZWoA6aO/Accrualify-Corpay-Web?t=neJAkkclysoDwTC6-0

    const colorsArray: dashboardColorSettings[] = [
      { color: rgbToHex(152, 18, 57), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(0, 181, 226), tooltipTextColor: COLOR_BLACK },
      { color: rgbToHex(255, 177, 0), tooltipTextColor: COLOR_BLACK },
      { color: rgbToHex(101, 101, 121), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(2, 137, 1), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(82, 34, 57), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(23, 136, 0), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(190, 21, 73), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(205, 220, 57), tooltipTextColor: COLOR_BLACK },
      { color: rgbToHex(255, 177, 0), tooltipTextColor: COLOR_BLACK },
      { color: rgbToHex(255, 87, 34), tooltipTextColor: COLOR_BLACK },
      { color: rgbToHex(152, 18, 57), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(121, 85, 72), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(158, 158, 158), tooltipTextColor: COLOR_BLACK },
      { color: rgbToHex(96, 125, 139), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(63, 81, 181), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(23, 136, 0), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(0, 188, 212), tooltipTextColor: COLOR_BLACK },
      { color: rgbToHex(232, 30, 99), tooltipTextColor: COLOR_BLACK },
      { color: rgbToHex(33, 150, 243), tooltipTextColor: COLOR_BLACK },
      { color: rgbToHex(139, 195, 74), tooltipTextColor: COLOR_BLACK },
      { color: rgbToHex(156, 39, 176), tooltipTextColor: COLOR_WHITE },
      { color: rgbToHex(0, 0, 0), tooltipTextColor: COLOR_WHITE },
    ];

    return { colorsArray };
  };
}

export default dashboardColorMap;
