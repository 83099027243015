import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import BankRoutingNumber from "../../../common/pickers/reduxPickers/bankRoutingNumber";

const BankRouting = ({
  label,
  isBankRountingRequired,
  validations,
  tooltip,
  bankAddressCallBack,
  bankNameCallBack,
}) => {
  return (
    <Col md="6">
      <Field
        id="form.routing"
        name="form.routing"
        component={BankRoutingNumber}
        type="text"
        label={label}
        required={isBankRountingRequired}
        autoComplete={"off"}
        tooltip={tooltip}
        validate={validations ? validations : []}
        bankAddressCallBack={bankAddressCallBack}
      />
    </Col>
  );
};

export default BankRouting;
