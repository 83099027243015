import { useState } from "react";

const useShowFilterState = (filterStoreName: string, onByDefault: boolean = false) => {
  const [showFilters, setShowFitlers] = useState(onByDefault || localStorage.getItem(filterStoreName) === "true");
  const updateShowFilters = (value: boolean) => {
    if (value) {
      setShowFitlers((prev) => value);
      localStorage.setItem(filterStoreName, "true");
    } else {
      setShowFitlers((prev) => value);
      localStorage.setItem(filterStoreName, "false");
    }
  };
  return { showFilters, updateShowFilters };
};

export default useShowFilterState;
