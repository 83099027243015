import { ON_LOG_OUT } from "../actions/actionTypes";
import RestApi, { restApiService } from "../providers/restApi";
import { defineAbilitiesFor } from "../services/authorization/authorization";
import { GetCookie, SetCookie } from "../services/general/cookies";
import currency_codes from "lookups/currencyCodeLookup";
import UserSvc from "services/common/user/userSvc";

const INITIAL_STATE = GetCookie("domainAccessToken") ? null : UserSvc.getStoredUserDetails();

if (INITIAL_STATE) {
  defineAbilitiesFor(INITIAL_STATE);
}

export default (state = INITIAL_STATE, action) => {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case "ADDUSERDETAILS":
      saveDetailsToDisk(action.payload);
      defineAbilitiesFor(action.payload);
      return action.payload;

    case "REMOVEUSERDEATAILS":
      localStorage.removeItem("userDetails");
      localStorage.removeItem("Token");
      defineAbilitiesFor(null);
      return null;
    case "CHANGE_USER_LANGUAGE":
      newState.contact.language = action.payload;
      saveDetailsToDisk(newState);
      return newState;
    case "CHANGE_USER_EMAIL_AGGREGATION":
      newState.contact.email_aggregation = action.payload;
      saveDetailsToDisk(newState);
      return newState;
    default:
      return state;
  }
};

// ACTIONS
export const addUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: "ADDUSERDETAILS", payload: userDetails });
};

export const removeUserDetails = () => (dispatch) => {
  dispatch({ type: "REMOVEUSERDEATAILS" });
  dispatch({ type: ON_LOG_OUT });
};

export const updateCurrentUser = () => async (dispatch) => {
  try {
    const response = await restApiService.get("user");
    dispatch(addUserDetails(response.data));
  } catch (error) {
    console.log(error);
  }
};

const saveDetailsToDisk = (details) => {
  RestApi.companyId = details?.company.id;
  localStorage.setItem("userDetails", JSON.stringify(details));
};

export const getPrimaryCurrencyOfUser = (state) =>
  currency_codes.find((code) => code.label === state.user.contact.currency_code)?.symbol ?? "$";
export const getPrimaryCurrencyCodeOfUser = (state) =>
  currency_codes.find((code) => code.label === state.user.contact.currency_code)?.value ?? "USD";
export const selectCurrentUser = (state) => state.user;
