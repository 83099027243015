import ListExpenseItem from "components/admin/expensese/expenseItem/listExpenseItem";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import React from "react";

const List = () => {
  return (
    <ErrorBoundary>
      <ListExpenseItem />
    </ErrorBoundary>
  );
};

export default List;
