import _ from "lodash";
import React, { ReactNode } from "react";
import { Form } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { WrappedFieldProps, getFormValues } from "redux-form";

interface CustomFieldCheckboxPropsType extends WrappedFieldProps {
  id?: string;
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  formName: string;
  formFieldName: string;
  item: string;
}

const CustomFieldCheckbox = ({
  id,
  input,
  label,
  required,
  disabled,
  item,
  formName,
  formFieldName,
}: CustomFieldCheckboxPropsType) => {
  const formData: any | null = useTypedSelector((state) => getFormValues(formName)(state));
  const customFieldAttr: any | null = _.get(formData, formFieldName);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    input.onChange(isChecked);
  };

  return (
    <Form.Check
      {...input}
      label={label}
      className={"mr-1"}
      required={required}
      checked={customFieldAttr && _.isArray(customFieldAttr.value) && customFieldAttr.value.includes(item)}
      disabled={disabled}
      onChange={handleCheckboxChange}
      type="checkbox"
      id={id}
    />
  );
};

export default CustomFieldCheckbox;
