import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { GetFormattedExpirationDate } from "../../services/virtualCards/virtualCardsService";

class SingleVirtualCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { card_number_shown } = this.props;
    const { expiry, contact } = this.props.card;
    let img = this.props.card && this.props.card.card_type === "visa" ? "visa-card-bg.png" : "corpay-master-card.png";
    return (
      <Row>
        <Col>
          <img alt={"Credit Card"} style={{ height: "215px" }} src={require(`../../assets/virtualCards/${img}`)} />
          <span className="singleVCardCardNumber">{card_number_shown}</span>
          <span className="validThru">VALID THRU</span>{" "}
          <span className="singleVCardExpirationDate">{GetFormattedExpirationDate(expiry ?? "")}</span>
          <span className="singleVCardName">{contact && contact?.name}</span>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(SingleVirtualCard);
