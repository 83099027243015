import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Col, Row } from "react-bootstrap";
import styles from "./doughnutPicker.module.css";

type DoughnutChartPropsType = {
  heading?: string;
  countValue?: number;
  data?: any;
};

type ChartType = {
  width: number;
  height: number;
  ctx: CanvasRenderingContext2D;
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const DoughnutChart = ({ heading, countValue, data }: DoughnutChartPropsType) => {
  const chartRef: any = useRef(null);
  const chartInstance: any = useRef(null);

  const plugin = {
    id: "doughnutPickerCount",
    beforeDraw: function (chart: ChartType) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 260).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      let chartHeading = heading ?? "",
        textX = Math.round((width - ctx.measureText(chartHeading).width) / 2),
        textY = height / 2.3; //help to adjustment Doughnut chart count heading vertical alignment
      ctx.fillText(chartHeading, textX, textY);

      let count = countValue ?? "";
      let numberText = count.toString();
      let numberTextX = Math.round((width - ctx.measureText(numberText).width) / 2);
      let numberTextY = textY + parseInt(fontSize) + 35; //help to adjustment Doughnut chart count vertical alignment
      ctx.fillText(numberText, numberTextX, numberTextY);
      ctx.save();
    },
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels: data.tooltipLabels,
        datasets: [
          {
            data: data.arcData.map((item: Record<string, number>) => item.count),
            backgroundColor: data.backgroundColor,
            borderColor: data.backgroundColor,
            borderRadius: 9,
            borderWidth: 0,
            spacing: 3,
          },
        ],
      },
      plugins: [plugin],
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 10,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: any) => {
                return data.arcData[context.dataIndex].label;
              },
            },
          },
          legend: {
            display: false,
          },
        },
        animation: {
          animateRotate: true,
        },
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return (
    <div className={styles.chartContainer} style={{ height: "35vh" }}>
      <Row>
        <Col sm={12} className="mx-auto doughnutCanvas">
          <canvas ref={chartRef} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {data.legands.map((legand: string, index: number) => (
            <Col sm={12} key={index} className="d-flex align-items-center">
              <div className={styles.chartLegands} style={{ backgroundColor: data.backgroundColor[index] }} />
              <span>{legand}</span>
            </Col>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default DoughnutChart;
