import { createSlice } from "@reduxjs/toolkit";
import { payReimbursementType } from "services/admin/expenses/payReimbursementType";

interface SelectedPayExpenseState {
  selectedRows: payReimbursementType.payObject[];
}

const initialState: SelectedPayExpenseState = {
  selectedRows: [],
};

const selectedPayExpense = createSlice({
  name: "payExpenseReviewForm",
  initialState,
  reducers: {
    setExpenseForReview: (state, action) => {
      state.selectedRows = action.payload;
    },
  },
});

export const { setExpenseForReview } = selectedPayExpense.actions;

export default selectedPayExpense.reducer;
