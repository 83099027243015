import { Component } from "react";

const withSelectAll = (WrappedComponent) => {
  return class WithSelectAll extends Component {
    state = { selectAll: false };

    toggleSelectAll = () => {
      if (!this.state.selectAll) {
        this.selectAll();
      } else {
        this.selectNone();
      }
      this.setState({ selectAll: !this.state.selectAll });
    };

    getGridApi = (params) => {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    };

    onSelectionChanged = () => {
      const selected = this.gridApi.getSelectedNodes().length;
      const total = this.gridApi.getDisplayedRowCount();
      if (selected === total) {
        this.setState({ selectAll: true });
      } else {
        this.setState({ selectAll: false });
      }
    };

    onRowSelected = (event) => {
      if (!event.node.selected && this.state.selectAll) {
        this.setState({ selectAll: false });
      }
    };

    selectAll = () => {
      this.gridApi.forEachNode((node) => node.setSelected(true));
    };

    selectNone = () => {
      this.gridApi.forEachNode((node) => node.setSelected(false));
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          selectAll={this.state.selectAll}
          toggleSelectAll={this.toggleSelectAll}
          getGridApi={this.getGridApi}
          onSelectionChanged={this.onSelectionChanged}
          onRowSelected={this.onRowSelected}
        />
      );
    }
  };
};

export default withSelectAll;
