import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTypedSelector } from "reducers";
import { PoChangeOrderType } from "services/admin/changeOrders/poChangeOrderApprovalsType";
import { currencySymbolRenderer } from "services/common/currencySymbolRendererService";
import { companyDateFormat } from "services/general/dateSvc";

type GeneralInfoPropType = {
  poChangeOrderDetail: PoChangeOrderType.DetailType;
};

const GeneralInfo = ({ poChangeOrderDetail }: GeneralInfoPropType) => {
  const currentUser = useTypedSelector((state) => state.user);
  return (
    <div className="generalInfo">
      <Row>
        <Col md="4">
          <dl>
            <dt>Total Amount:</dt>
            <dd>
              {currencySymbolRenderer(poChangeOrderDetail.change_requestable?.currency_code) +
                poChangeOrderDetail?.change_requestable?.amount}
            </dd>

            {poChangeOrderDetail?.object_changes?.start_date && (
              <>
                <dt>Start Date: </dt>
                <dd>
                  {poChangeOrderDetail?.object_changes?.start_date
                    ? companyDateFormat(poChangeOrderDetail.object_changes.start_date, currentUser)
                    : "N/A"}
                </dd>
              </>
            )}

            {poChangeOrderDetail?.object_changes?.end_date && (
              <>
                <dt>End Date:</dt>
                <dd>
                  {poChangeOrderDetail?.object_changes?.end_date
                    ? companyDateFormat(poChangeOrderDetail.object_changes.end_date, currentUser)
                    : "N/A"}
                </dd>
              </>
            )}
          </dl>
        </Col>
        <Col>
          <dl>
            <dt>Submission Date:</dt>
            <dd>
              {poChangeOrderDetail?.submitter?.date
                ? companyDateFormat(poChangeOrderDetail.submitter.date, currentUser)
                : "N/A"}
            </dd>

            <dt>Submitted By:</dt>
            <dd>{poChangeOrderDetail?.submitter?.name ?? "N/A"}</dd>
          </dl>
        </Col>
        <Col>
          <dl>
            <dt>Description:</dt>
            <dd>{poChangeOrderDetail?.object_changes?.description ?? "N/A"}</dd>
          </dl>
        </Col>
      </Row>
    </div>
  );
};

export default GeneralInfo;
