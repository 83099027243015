export const EftAusPayNetValidation = [
  {
    country_currency: "AUD",
    validations: [
      {
        field: "account_name",
        validate: {
          required: true,
          maxlength: 32,
          pattern: /^[A-Za-z0-9\s]*$/,
        },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "account_number",
        validate: {
          required: true,
          minlength: 4,
          maxlength: 9,
          pattern: /^[A-Za-z0-9\b]+$/,
        },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },
      {
        field: "bsb_code",
        validate: {
          required: true,
          minlength: 6,
          maxlength: 6,
          pattern: /^[0-9]{6}$/,
        },
        label: "BSB Code",
        validMessage: "Success",
        errorMessage: "Sorry! BSB Code  is required",
      },
      {
        field: "bank_name",
        validate: { required: true },
        label: "Bank Name",
        validMessage: "Success",
        errorMessage: "Sorry! Bank Name is required",
      },
      {
        field: "business_number",
        label: "Business Number",
        validate: {
          required: false,
          minlength: 9,
          maxlength: 14,
          pattern: /^[0-9]*$/,
          validMessage: "Success",
          errorMessage: "Sorry! Business Number is required",
        },
      },
    ],
  },
];
