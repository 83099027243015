import React from "react";
import { CurrencyCodeOptionType, CurrencyCodePickerPropsType } from ".";
import { Form } from "react-bootstrap";
import { Mandatory } from "components/forms/bootstrapFields";
import Select, { MultiValue } from "react-select";
import _ from "lodash";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";

type MultipleCurrencyCodePickerPropsType = CurrencyCodePickerPropsType & {
  currencyCodeOptions: CurrencyCodeOptionType[];
};

const MultipleCurrencyCodePicker = ({
  input,
  meta: { touched, error },
  label,
  callBack,
  className,
  currencyCodeOptions = [],
  tooltip,
  disabled,
  required,
  instanceId = "currency-selector",
  placeHolder,
  defaultValue,
}: MultipleCurrencyCodePickerPropsType) => {
  const findSelectedMultiple = () => {
    let selectedValues: CurrencyCodeOptionType[] = [];
    if (typeof (input.value === String)) {
      currencyCodeOptions.forEach((option) => {
        if (input.value === option.value) {
          selectedValues.push(option);
        }
      });
    }

    if (_.isPlainObject(input.value)) {
      selectedValues = [input.value];
    }

    if (_.isArray(input.value)) {
      input.value.forEach((element) => {
        let obj;
        if (_.isPlainObject(element)) {
          obj = _.find(currencyCodeOptions, (option) => option.value === element.value);
        } else {
          obj = _.find(currencyCodeOptions, (option) => option.value === element);
        }
        if (obj) selectedValues.push(obj);
      });
    }
    return selectedValues;
  };

  const onChangeMultiple = (options: MultiValue<CurrencyCodeOptionType>) => {
    if (!options) {
      input.onChange([]);
      return;
    }
    if (callBack) {
      callBack(options);
    }

    input.onChange(options);
  };

  const onBlurMultiple = () => {
    if (!input.value || !input.value.length) {
      input.onBlur();
      return;
    }
    input.onBlur(input.value);
  };

  if (defaultValue) {
    currencyCodeOptions = currencyCodeOptions.filter((option) => option.value === defaultValue);
    if (currencyCodeOptions[0]) {
      input.onChange(currencyCodeOptions[0]);
    }
  }

  return (
    <Form.Group>
      {label && (
        <>
          <Form.Label>{label}</Form.Label>
          <Mandatory required={required} />
        </>
      )}
      {tooltip}
      <Select
        {...input}
        required={required}
        instanceId={instanceId}
        isDisabled={disabled}
        value={findSelectedMultiple()}
        placeholder={placeHolder ? placeHolder : "Search/Select"}
        onChange={(value) => onChangeMultiple(value)}
        onBlur={() => onBlurMultiple()}
        options={currencyCodeOptions}
        isMulti={true}
        isClearable
        classNamePrefix="select"
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default MultipleCurrencyCodePicker;
