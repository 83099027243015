import { Form } from "react-bootstrap";
import { useState } from "react";
import { Mandatory } from "../../../../forms/bootstrapFields";
import RestApi from "../../../../../providers/restApi";
import { useTranslation } from "react-i18next";
const restApiService = new RestApi();

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const BankRoutingNumber = ({
  label,
  input,
  meta: { touched, error },
  tooltip,
  className,
  autoComplete,
  placeholder,
  required,
  bankNameCallBack, // we can use it to set bank name
  bankAddressCallBack, // we can use it to set bank address
  bankCompleteAddressCallBack,
}) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState();
  const [warning, setWarning] = useState();

  const resetRoutingStates = () => {
    setSuccess(null);
    setWarning(null);
  };

  const onRoutingChange = async (routing) => {
    resetRoutingStates();

    // setting value in component
    input.onChange(routing);
    try {
      if (routing && routing.length === 9) {
        const response = await restApiService.get("bank_routing_numbers/" + routing);
        const bankRountingNumber = response.data;

        if (bankRountingNumber.new_routing_number) {
          const bankRoutingName = t("components.common.bankRoutingNumber.newRoutingWarning", {
            newRoutingNumber: bankRountingNumber.new_routing_number,
            routing: routing,
          });
          setWarning(bankRoutingName);
          return;
        }

        let bankRoutingName = bankRountingNumber.name + " " + bankRountingNumber.city + " " + bankRountingNumber.state;

        setSuccess(bankRoutingName);
        if (bankAddressCallBack) {
          bankAddressCallBack(bankRountingNumber.address);
        }
        if (bankCompleteAddressCallBack) {
          const bankAddress = {
            address: bankRountingNumber.address,
            city: bankRountingNumber.city,
            state: bankRountingNumber.state,
            zipcode: bankRountingNumber.zipcode,
          };
          bankCompleteAddressCallBack(bankAddress);
        }
        if (bankNameCallBack) {
          bankNameCallBack(bankRountingNumber.name);
        }
      }
    } catch (error) {
      setWarning(t("components.common.bankRoutingNumber.routingNotFound", { routing: routing }));
    }
  };

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
        </Form.Label>
      )}
      {tooltip ?? null}
      <Form.Control
        as="input"
        type={"text"}
        autoComplete={autoComplete}
        placeholder={placeholder}
        {...input}
        onChange={(e) => onRoutingChange(e.target.value)}
        isInvalid={touched && !!error}
        isValid={touched && !error}
        className="mb-1"
        required={required}
      />
      {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
      {warning && !success && <span className="status-color-warning">{warning}</span>}
      {success && <Form.Control.Feedback type="valid">{success}</Form.Control.Feedback>}
    </Form.Group>
  );
};

export default BankRoutingNumber;
