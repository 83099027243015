import administrationRoutes from "./pages/admin/administration";
import NotificationsDetailPage from "./pages/admin/notifications/show";
import { settingsRoutes } from "./pages/admin/settings";
import draftExpense from "./pages/expenseItems/admin/draftExpense.jsx";
import DraftExpensesList from "./pages/expenseItems/admin/index.jsx";
import ForgotPassword from "./pages/login/forgotPassword";
import VCardOnboarding from "./pages/programs/vcards/vcardOnboarding";
import VCardOnboardingAddFunds from "./pages/programs/vcards/vcardOnboardingAddFunds";
import VCardOnboardingIntegrations from "./pages/programs/vcards/vcardOnboardingIntegrations";
import VCardOnboardingPlaid from "./pages/programs/vcards/vcardOnboardingPlaid";
import VCardOnboardingSettings from "./pages/programs/vcards/vcardOnboardingSettings";
import VCardOnboardingStripe from "./pages/programs/vcards/vcardOnboardingStripe";
import VCardOnboardingTOS from "./pages/programs/vcards/vcardOnboardingTOS";
import UserProfile from "./pages/user_profile/userProfile";
import AccrualRequest from "./pages/vp/accruals/accrualRequestsList";
import SubmitAccrual from "./pages/vp/accruals/add";
import AccrualsDetail from "./pages/vp/accruals/details";
import Accruals from "./pages/vp/accruals/list";
import { vpBankingInfoRoutes } from "./pages/vp/bankingInfo";
import { vpCreditMemoRoutes } from "./pages/vp/creditMemos";
import { customersRoutes } from "./pages/vp/customers";
import Dashboard from "./pages/vp/dashboard";
import VPCreateInvoice from "./pages/vp/invoices/addInvoice";
import VPInvoiceDetail from "./pages/vp/invoices/invoiceDetail";
import VPInvoices from "./pages/vp/invoices/invoices";
import InvoiceRequests from "./pages/vp/invoices/invoice_request";
import InvoiceSubmit from "./pages/vp/invoices/submitInvoice";
import VPPaymentDetail from "./pages/vp/payments/paymentDetail";
import VPPayments from "./pages/vp/payments/payments";
import { vpPurchaseOrderRoutes } from "./pages/vp/purchaseOrder";
import VirtualCardDetails from "./pages/vp/virtualCards/details";
import VPVirtualCards from "./pages/vp/virtualCards/list";
// Payments
import { cardRoutes } from "pages/admin/cards";
import wombatRoutes from "wombatifier/pages";
import { adminDisputeRoutes } from "./pages/admin/cards/disputes";
import AdminDashboard from "./pages/admin/dashboard";
import expensesRoutes from "./pages/admin/expenses";
import { invoiceRoutes } from "./pages/admin/invoice";
import PaymentsDashboard from "./pages/admin/payments/dashboard";
import PrePaymentDetail from "./pages/admin/payments/prePayments/prePaymentDetail";
import PrePaymentsPage from "./pages/admin/payments/prePayments/prePayments";
import { adminPurchaseOrderRoutes } from "./pages/admin/purchaseOrders";
import addPOChangeOrderRoutes from "./pages/admin/purchaseOrders/changeOrders/add";
import editPOChangeOrderRoutes from "./pages/admin/purchaseOrders/changeOrders/edit";
import { dataExtractRoutes } from "./pages/admin/reports";
import SubsidiaryRoutes from "./pages/admin/subsidiaries";
import { adminUserProfileRoute } from "./pages/admin/userProfile";
import { adminUsersRoute } from "./pages/admin/users";
import VendorRoutes from "./pages/admin/vendors";
import vpCompanyProfileRoutes from "./pages/vp/companyProfile";
import vendorContactRoutes from "./pages/vp/contacts";

// Configure all the routes here

const commonRoutes = [
  { path: "/reset-password", exact: true, name: "Reset Password", component: ForgotPassword }, //modify
];

//NOTE: here, we are going load only module level routes, all subroute will reside index.jsx for module
// Same patter will be followed in vendor portal
// TODO: refator adminRoutes to only show module level routes
const adminRoutes = [
  { path: ["/ap/dashboard"], exact: true, name: "Dashboard", component: AdminDashboard },
  ...invoiceRoutes,
  { path: "/ap/expenseItems/draft", exact: true, name: "Draft Expense Item", component: DraftExpensesList },
  { path: "/ap/expenseItems/draft/:id/edit", exact: true, name: "Draft Expense Item", component: draftExpense },
  // Payments routes
  {
    path: "/ap/payments/pre_payments",
    exact: true,
    name: "PrePayments",
    component: PrePaymentsPage,
    authorization: {
      I: "list",
      a: "PrePayments",
    },
  },

  {
    path: "/ap/payments/pre_payments/:id",
    exact: true,
    name: "PrePayments",
    component: PrePaymentDetail,
    authorization: {
      I: "read",
      a: "PrePayments",
    },
  },
  //program onboarding
  { path: "/ap/onboarding/vcards", exact: true, name: "VCard Onboarding", component: VCardOnboarding },
  { path: "/ap/onboarding/vcards/tos", exact: true, name: "VCard Onboarding TOS", component: VCardOnboardingTOS },
  {
    path: "/ap/onboarding/vcards/bank_account",
    exact: true,
    name: "VCard Onboarding Bank",
    component: VCardOnboardingPlaid,
  },
  {
    path: "/ap/onboarding/vcards/account",
    exact: true,
    name: "VCard Onboarding Account",
    component: VCardOnboardingStripe,
  },
  {
    path: "/ap/onboarding/vcards/:id/:status",
    exact: true,
    name: "VCard Onboarding Account",
    component: VCardOnboarding,
  },
  {
    path: "/ap/onboarding/vcards/connect_accounting_software",
    exact: true,
    name: "VCard Onboarding Integrations",
    component: VCardOnboardingIntegrations,
  },
  {
    path: "/ap/onboarding/vcards/settings",
    exact: true,
    name: "VCard Onboarding Settings",
    component: VCardOnboardingSettings,
  },
  {
    path: "/ap/onboarding/vcards/add_funds",
    exact: true,
    name: "VCard Onboarding Settings",
    component: VCardOnboardingAddFunds,
  },
  { path: "/ap/payments/dashboard", exact: true, name: "Payments Dashboard", component: PaymentsDashboard },
  { path: "/dashboard/payments", exact: true, name: "Payments Dashboard", component: PaymentsDashboard },
  ...settingsRoutes,
  ...SubsidiaryRoutes,
  ...VendorRoutes,
  ...addPOChangeOrderRoutes,
  ...editPOChangeOrderRoutes,
  ...expensesRoutes,
  { path: "/ap/notifications", exact: true, name: "Notifications", component: NotificationsDetailPage },
  // all route that belongs to administration
  ...administrationRoutes,
  ...adminUsersRoute,
  ...adminUserProfileRoute,
  ...adminPurchaseOrderRoutes,
  ...dataExtractRoutes,
  ...adminDisputeRoutes,
  ...cardRoutes,
  ...wombatRoutes,
];

const vendorPortalRoutes = [
  { path: "/", exact: true, name: "Dashboard", component: Dashboard },
  ...customersRoutes,
  ...vendorContactRoutes,
  ...vpCreditMemoRoutes,
  ...vpPurchaseOrderRoutes,
  ...vpBankingInfoRoutes,
  {
    path: "/ar/accruals",
    exact: true,
    name: "Accruals",
    component: Accruals,
  },
  {
    path: "/ar/invoices",
    exact: true,
    name: "Invoices",
    component: VPInvoices,
  },
  {
    path: "/ar/invoice_requests",
    exact: true,
    name: "invoiceRequests",
    component: InvoiceRequests,
  },
  {
    path: "/ar/payments",
    exact: true,
    name: "Payments",
    component: VPPayments,
    authorization: {
      I: "list",
      a: "Payments",
    },
  },
  {
    path: "/ar/payments/:id",
    exact: true,
    name: "Payments",
    component: VPPaymentDetail,
    authorization: {
      I: "read",
      a: "Payments",
    },
  },
  {
    path: "/ar/user_profile",
    exact: true,
    name: "userProfile",
    component: UserProfile,
  },
  {
    path: "/ar/submit_invoice",
    exact: true,
    name: "submitInvoice",
    component: InvoiceSubmit,
  },
  {
    path: "/ar/invoices/:id/edit",
    exact: true,
    name: "editSubmittedInvoice",
    // component: EditUploadedInvoicePage,
  },
  {
    path: "/ar/create_invoice",
    exact: true,
    name: "createInvoice",
    component: VPCreateInvoice,
  },
  {
    path: "/ar/submit_invoice/:id",
    exact: true,
    name: "submitInvoice",
    component: InvoiceSubmit,
  },

  {
    path: "/ar/invoices/:id",
    exact: true,
    name: "invoiceDetails",
    component: VPInvoiceDetail,
  },
  {
    path: "/ar/accruals/:id",
    exact: true,
    name: "AccrualsDetail",
    component: AccrualsDetail,
  },
  {
    path: "/ar/submit_accrual",
    exact: true,
    name: "SubmitAccrual",
    component: SubmitAccrual,
  },
  ...vpCompanyProfileRoutes,
  {
    path: "/ar/accrual_requests",
    exact: true,
    name: "AccrualRequests",
    component: AccrualRequest,
  },
  {
    path: "/ar/virtual_cards/:id",
    exact: true,
    name: "Virtual Cards",
    component: VirtualCardDetails,
    authorization: {
      I: "read",
      a: "VCards",
    },
  },
  {
    path: "/ar/virtual_cards",
    exact: true,
    name: "Virtual Cards",
    component: VPVirtualCards,
    authorization: {
      I: "list",
      a: "VCards",
    },
  },
];

export { commonRoutes, adminRoutes, vendorPortalRoutes };
