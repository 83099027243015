import React, { useEffect, useRef } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import EmailGridTopNavButtons from "./emailGridTopNavButtons";
import EmailTabs from "./emailTabs";
import emailStyles from "./email.module.css";
import { AgGridReact } from "ag-grid-react";
import { ColumnConfig } from "../../../../services/common/gridService";
import ChildMessageRenderer from "../../../datagrid/childMessageRenderer";
import ChildCellRouting from "../../../datagrid/childCellRouting";
import CustomStatsToolPanel from "../../../datagrid/customStatsToolPanel";
import FloatingFilterComponent from "../../../datagrid/floatingFilterComponent";
import DraftInboxCellRenderer from "../../../datagrid/draftInboxCellRenderer";
import { draftInboxGridHeader } from "./emailDraftInboxHeaders";
import { GridReadyEvent } from "ag-grid-community";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import {
  currentPageSetter,
  gridItemClicked,
  onChangeCurrentEmailTab,
  onGridDataChange,
  onGridReady,
  onPaginationChange,
  onPaginationSizeChange,
  paginationSizeSetter,
  refreshGrid,
  selectedEmailSetter,
} from "../../../../reducers/admin/emailReducer";
import GridPagination from "../../../common/dataGrid/gridPagination";
import { getRowNodeId } from "../../../../services/admin/invoices/emailSvc";
import EmailDetails from "./emailDetails";

export type emaiGridViewPropsTypes = {
  gridRef: React.RefObject<AgGridReact>;
};

const EmailGridView = ({ gridRef }: emaiGridViewPropsTypes) => {
  const dispatch = useDispatch();
  const emailDetails = useSelector((state: RootState) => state.emailRed.emailDetails);
  const gridElementRef = useRef<HTMLDivElement>(null);

  const emailDetailLoading = useSelector((state: RootState) => state.emailRed.emailDetailLoading);

  const currentEmailTab = useSelector((state: RootState) => state.emailRed.currentEmailTab);
  const paginationData = useSelector((state: RootState) => state.emailRed.paginationData);
  const paginationSize = useSelector((state: RootState) => state.emailRed.paginationSize);
  const currentPage = useSelector((state: RootState) => state.emailRed.currentPage);

  const setPaginationSize = (paginationSize: number) => {
    dispatch(paginationSizeSetter(paginationSize));
  };

  const setCurrentPage = (pageNumber: number) => {
    dispatch(currentPageSetter(pageNumber));
  };

  const onPaginationChanged = () => {
    if (gridRef.current) {
      dispatch(onPaginationChange({ gridApi: gridRef.current.api }));
    }
  };

  const onGridDataChanged = () => {
    if (gridRef.current) {
      dispatch(onGridDataChange({ gridApi: gridRef.current.api }));
    }
  };

  const gridReady = (params: GridReadyEvent) => {
    dispatch(onGridReady(params));
  };

  const onPaginationSizeChanged = () => {
    if (gridRef.current) {
      dispatch(onPaginationSizeChange());
    }
  };

  const currentEmailTabChanged = () => {
    dispatch(onChangeCurrentEmailTab());
  };

  const setSelectedEmails = () => {
    dispatch(selectedEmailSetter());
  };

  useEffect(() => {
    currentEmailTabChanged();
  }, [currentEmailTab]);

  return (
    <Row className="bg-white m-0">
      <Col md="12">
        <EmailGridTopNavButtons />
        <Row className={"emailContainerTopBorder " + emailStyles.gridViewEmailContainer}>
          <Col className={"pr-0 pl-0"}>
            <EmailTabs />
          </Col>
          <Col xl={4} className={"pr-0 pl-0 bg-white " + emailStyles.flexContainerGridCol}>
            <Row className={"email-list m-0 " + emailStyles.gridEmailGridSection}>
              <div ref={gridElementRef} className={"ag-theme-fresh animated fadeIn " + emailStyles.gridStyle}>
                <AgGridReact
                  ref={gridRef}
                  getRowId={getRowNodeId}
                  columnDefs={draftInboxGridHeader}
                  rowModelType="serverSide"
                  onGridReady={gridReady}
                  onCellClicked={(row) => dispatch(gridItemClicked(row))}
                  onPaginationChanged={onPaginationChanged}
                  onRowDataUpdated={onGridDataChanged}
                  rowHeight={87.5}
                  headerHeight={0}
                  floatingFiltersHeight={ColumnConfig.floatingFiltersHeight}
                  rowSelection={"single"}
                  components={{
                    childMessageRenderer: ChildMessageRenderer,
                    childCellRouting: ChildCellRouting,
                    customStatsToolPanel: CustomStatsToolPanel,
                    floatingFilterComponent: FloatingFilterComponent,
                    draftInboxCellRenderer: DraftInboxCellRenderer,
                  }}
                  suppressServerSideInfiniteScroll={false}
                  cacheBlockSize={paginationSize}
                  pagination={true}
                  paginationPageSize={paginationSize}
                  suppressPaginationPanel={true}
                  onSelectionChanged={setSelectedEmails}
                />
              </div>
            </Row>
          </Col>
          <Col xl={6} className={"d-none d-block pl-0 pr-0 h-100 " + emailStyles.gridViewDetailView}>
            {emailDetailLoading && <Spinner className={"loaderFullScreen"} animation="border" variant="info" />}
            {emailDetails && Object.keys(emailDetails).length !== 0 && <EmailDetails />}
          </Col>
        </Row>
        <Row>
          <Col>
            {gridRef.current?.api && (
              <GridPagination
                gridApi={gridRef.current?.api}
                currentPage={currentPage}
                paginationData={paginationData}
                paginationSizeChanged={onPaginationSizeChanged}
                paginationSize={paginationSize}
                setCurrentPage={setCurrentPage}
                setPaginationSize={setPaginationSize}
                paginationOptions={[10, 25, 50, 100]}
                refreshGrid={() => dispatch(refreshGrid())}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EmailGridView;
