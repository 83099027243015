import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { SubsidiaryDetailType, SubsidiaryListType, SubsidiaryPayloadType } from "./types";

class SubsidiaryApis {
  static async getNewSubsidiary() {
    try {
      const response: AxiosResponse<SubsidiaryDetailType> = await restApiService.get("subsidiaries/new");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getSubsidiaryList() {
    try {
      const response: AxiosResponse<SubsidiaryListType[]> = await restApiService.get("subsidiaries");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getSubsidiary(id: string) {
    try {
      const response: AxiosResponse<SubsidiaryDetailType> = await restApiService.get("subsidiaries/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async deleteSubsidiary(id: number) {
    try {
      const response: AxiosResponse<SubsidiaryDetailType> = await restApiService.delete("subsidiaries/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async addSubsidiary(subsidiaryPayload: SubsidiaryPayloadType) {
    try {
      const response: AxiosResponse<SubsidiaryDetailType> = await restApiService.post(
        "subsidiaries",
        null,
        subsidiaryPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async editSubsidiary(id: string, subsidiaryPayload: SubsidiaryPayloadType) {
    try {
      const response: AxiosResponse<SubsidiaryDetailType> = await restApiService.patch(
        "subsidiaries/" + id,
        null,
        subsidiaryPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default SubsidiaryApis;
