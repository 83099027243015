import React, { Component, Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import CollapsePanel from "../../../components/panel/collapsePanel";
import { BreadcrumbTrail } from "../../../components/navigation/navigationLinks";
import PageTitle from "../../../components/page/pageTitle";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import InvoicePaymentLinks from "../../../components/widgets/linkedInvoices/linkedInvoices";
import { Link } from "react-router-dom";
import Loader from "../../../components/spinners/loader";
import { formattedAmount } from "../../../services/general/helpers";

class VPPaymentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      purchaser: null,
    };
    this.initialPurchaserRender = React.createRef();
    this.initialPurchaserRender.current = true;
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.fetchPayment();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props?.payment?.purchaser?.id !== prevProps?.payment?.purchaser?.id ||
      (this.props?.payment?.purchaser?.id && !this.state.purchaser && this.initialPurchaserRender.current)
    ) {
      this.fetchPaymentPurchaser();
    }
  }

  fetchPaymentPurchaser = async () => {
    this.initialPurchaserRender.current = false;
    try {
      const response = await this.restApiService.get("purchasers/" + this.props.payment?.purchaser?.id);
      this.setState({ purchaser: response.data });
    } catch (error) {}
  };

  fetchPayment = (linkedInvoiceLimit = null) => {
    let url = "vendor_payments/" + this.props.match.params.id;
    if (linkedInvoiceLimit) {
      url += "?invoice_payment_links_limit=" + linkedInvoiceLimit;
    }
    this.restApiService
      .get(url)
      .then((result) => {
        if (result.data) {
          this.props.setPayment(result.data);
        }
        this.setState({ loaded: true });
      })
      .catch((error) => {
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning);
        this.setState({ loaded: true });
      });
  };

  fetchAllLinkedInvoices = () => {
    this.props.history.push("/ar/invoices", { payment_number: this.props.payment.payment_number });
  };

  renderVcard = (payment) => {
    return (
      <Fragment>
        <dt className="col-6 col-sm-5 detailKey text-sm-right">Card Number</dt>
        <dd className="col-6 col-sm-7 detailValue">
          <Link className="primaryLinkColor" variant="primary" to={"/ar/virtual_cards/" + payment.virtual_card.id}>
            {payment.virtual_card.card_number_masked}
          </Link>
        </dd>
      </Fragment>
    );
  };

  showVendorRemittance = () => {
    // download payment remittance button will be only visible payment
    // who customer have corpay_nvp integration turned on
    // Payment should be either cleared or processing
    // Payment transaction status should be Approved
    return (
      this.state.purchaser?.accepted_payment_methods?.corpay_nvp &&
      ["CLEARED", "PROCESSING"].includes(this.props?.payment.status) &&
      this.props?.payment.properties.transaction_status == "Approved"
    );
  };

  downloadVendorRemittance = async () => {
    try {
      const result = await this.restApiService.get(
        "vendor_payments/" + this.props.payment.id + "/remittance",
        null,
        null,
        true,
        "blob",
      );
      const fileOutput = result.data;
      const blob = new Blob([fileOutput], { type: "application/pdf" });

      const url = URL.createObjectURL(blob);
      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "vendor_" + this.props?.payment?.number + "_remittance.pdf";
      link.rel = "noopener";

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the URL
      URL.revokeObjectURL(url);
    } catch (error) {
      CreateNotification("Error", "Could not download", NotificationType.danger);
    }
  };

  render() {
    let payment = this.props.payment ? this.props.payment : {};
    return (
      <Container fluid={true} className={"pt-4 pb-4"}>
        <BreadcrumbTrail
          to={"/ar/payments"}
          pageName={this.props.t("breadcrumbs.payments")}
          title={this.props.t("breadcrumbs.paymentDetails")}
        />
        <Loader loaded={this.state.loaded}>
          <Row className="mt-3">
            <Col>
              <PageTitle title={payment && payment.number} status={payment && payment.status} />
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              {this.showVendorRemittance() && (
                <Button id="download_vendor_remittance" onClick={this.downloadVendorRemittance}>
                  <i className="icon icon-cloud-download-white mr-1"></i> Download Remittance
                </Button>
              )}
            </Col>
          </Row>
          <hr className="full-border" />

          <Row className="mt-3">
            <Col>
              <Row>
                <Col sm className="pr-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.customer")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      <Link
                        className="primaryLinkColor"
                        variant="primary"
                        to={"/ar/purchasers/" + (payment.purchaser && payment.purchaser.id)}
                      >
                        {payment.purchaser && payment.purchaser.name}
                      </Link>
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.paymentDate")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.payment_date}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">
                      {this.props.t("details.referenceNumber")}
                    </dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.reference_number}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.paymentType")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.payment_type}</dd>

                    {payment.virtual_card && payment.virtual_card.id && this.renderVcard(payment)}
                  </dl>
                </Col>
                <Col sm className="pr-sm-0 pl-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.amount")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {formattedAmount(payment.amount, payment.currency_code)}
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.currencyCode")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.currency_code}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.subsidiary")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.subsidiary && payment.subsidiary.name}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.description")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.description}</dd>
                  </dl>
                </Col>
                <Col sm className="pl-sm-0">
                  <dl className="row mb-0">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.remitAcct")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {payment.payment_method && payment.payment_method.account_number}
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.remitBank")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {payment.payment_method && payment.payment_method.bank_name}
                    </dd>
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.submitType")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {payment.payment_method && payment.payment_method.payment_type}
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.payTransId")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.payment_transaction_id}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.payTransDate")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.transaction_date}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.responseDesc")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.response_desc}</dd>
                  </dl>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <CollapsePanel
                leftBtn={"See All Invoices"}
                onClick={this.fetchAllLinkedInvoices}
                open={true}
                heading={this.props.t("details.linkedInvoices").toUpperCase()}
                cardBodyStyle={{ padding: "0px" }}
              >
                <InvoicePaymentLinks invoicePaymentLinks={payment?.invoice_payment_links} />
              </CollapsePanel>
            </Col>
          </Row>
        </Loader>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    payment: state.payment,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPayment: (payload) => {
      dispatch({ type: actionType.SET_PAYMENT, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VPPaymentDetail));
