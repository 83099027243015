import React, { ReactNode } from "react";
import MultiContactPicker from "./multiPicker";
import SingleContactPicker from "./singlePicker";

export type ContactPickerPropsType = {
  isMulti?: boolean;
  label?: string;
  input?: {
    value: string;
    onChange: (value: string) => void; // OnChange should accept any type of value
  };
  meta?: {
    touched?: boolean;
    error?: string;
    warning?: string;
  };
  labelClassName?: string;
  tooltip?: string;
  params?: Record<string, any>;
  required?: boolean;
  instanceId?: string;
  placeholder?: string;
  disabled?: boolean;
  originalValue?: ReactNode;
  callBack?: (contactId: number) => void; // To return contactId using callback
  callBackObj?: (contact: any) => void; // To return entire contact object using callback
  floating?: boolean;
  isExpenseForm?: boolean;
  needSelectedManagers?: boolean;
};

const ContactPicker = (props: ContactPickerPropsType) => {
  const {
    isMulti,
    label,
    input = { value: "", onChange: () => {} },
    meta = { touched: false, error: "", warning: "" },
    labelClassName,
    tooltip,
    params,
    required,
    instanceId,
    placeholder = "search/select",
    disabled = false,
    originalValue,
    callBack,
    callBackObj,
    floating,
    isExpenseForm,
    needSelectedManagers,
  } = props;

  const { touched, error, warning } = meta;

  const commonProps = {
    label,
    input: {
      ...input,
      onChange: (value: any) => input.onChange(value),
    },
    meta,
    tooltip,
    params,
    labelClassName,
    required,
    instanceId,
    placeholder,
    disabled,
    originalValue,
    callBack,
    callBackObj,
    floating,
    isExpenseForm,
    needSelectedManagers,
  };

  return <>{isMulti ? <MultiContactPicker {...commonProps} /> : <SingleContactPicker {...commonProps} />}</>;
};

export default ContactPicker;
