import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import useConfirmModal from "./useConfirmModalHook";
import { useTranslation } from "react-i18next";

const ConfirmModal = (props) => {
  const {
    title,
    body,
    confirmModalOpen,
    saveCallBack,
    cancelCallBack,
    callBackData,
    confirmButtonLabel,
    cancelButtonLabel,
  } = useSelector((state) => state.confirmModalReducer);

  const { removeConfirmModal } = useConfirmModal();
  const { t } = useTranslation();

  return (
    <Modal
      show={confirmModalOpen}
      onHide={() => {
        if (cancelCallBack) cancelCallBack(callBackData);
        removeConfirmModal();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>

      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => {
            if (cancelCallBack) cancelCallBack(callBackData);
            removeConfirmModal();
          }}
        >
          {cancelButtonLabel || t("cancel")}
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            if (saveCallBack) saveCallBack(callBackData);
            removeConfirmModal();
          }}
        >
          {confirmButtonLabel || t("ok")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
