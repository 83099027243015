import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Field, WrappedFieldInputProps, change, getFormValues } from "redux-form";
import styles from "./dashboard.module.css";
import Select from "react-select";
import { onBlurSingle } from "services/general/helpers";
import { useDispatch } from "react-redux";
import DatePicker from "components/admin/pickers/reduxFormPickers/datePicker/datePicker";
import { useTypedSelector } from "reducers";
import CardProgramPicker from "components/admin/pickers/reduxFormPickers/cardProgramPicker";
import { useTranslation } from "react-i18next";

type formType = {
  subsidiary_id?: number;
  expense_type?: { value: string; label: string };
  subsidiaryName?: string;
  card_program_id?: number;
};

type PostedDateFilterType = {
  handlePostedDate: ({ value, label }: { value: string; label: string }) => void;
  submitCustomDate: () => void;
  showCustom: boolean;
};

const PostedDateCardProgramFilter = ({ handlePostedDate, submitCustomDate, showCustom }: PostedDateFilterType) => {
  const { t } = useTranslation();
  const currentUser = useTypedSelector((state) => state.user);
  const expenseDashboardForm: formType = useTypedSelector((state) => getFormValues("expenseDashboardForm")(state));
  const dispatch = useDispatch();

  const postedDateFilterOption: { value: string; label: string; postedDate: string }[] = [
    {
      value: "current_month",
      label: t("admin.pages.expenseItem.labels.currentMonth"),
      postedDate: "admin.pages.expenseItem.labels.currentMonth",
    },
    {
      value: "last_month",
      label: t("admin.pages.expenseItem.labels.lastMonth"),
      postedDate: "admin.pages.expenseItem.labels.lastMonth",
    },
    {
      value: "current_quarter",
      label: t("admin.pages.expenseItem.labels.currentQuarter"),
      postedDate: "admin.pages.expenseItem.labels.currentQuarter",
    },
    {
      value: "last_quarter",
      label: t("admin.pages.expenseItem.labels.lastQuarter"),
      postedDate: "admin.pages.expenseItem.labels.lastQuarter",
    },
    { value: "ytd", label: t("admin.pages.expenseItem.labels.ytd"), postedDate: "admin.pages.expenseItem.labels.ytd" },
    {
      value: "last_year",
      label: t("admin.pages.expenseItem.labels.lastYear"),
      postedDate: "admin.pages.expenseItem.labels.lastYear",
    },
    {
      value: "custom",
      label: t("admin.pages.expenseItem.labels.custom"),
      postedDate: "admin.pages.expenseItem.labels.custom",
    },
  ];
  const defaultOption = postedDateFilterOption[1];

  useEffect(() => {
    dispatch(change("expenseCategoryForm", "posted_date", defaultOption));
    handlePostedDate(defaultOption);
  }, []);
  return (
    <>
      <Row className={styles.customRow}>
        <div className={`${styles.mainFilterContainer}`}>
          {currentUser?.is_admin && (
            <div className={styles.cardProgramFilterContainer}>
              <div className={`${styles.cardProgramFilterField}`}>
                <div className={`${styles.cardProgramFilterLabel} mb-0`}>
                  <span className={`${styles.filterLabel} ${styles.subsidiaryView}`}>
                    {t("admin.pages.expenseItem.labels.cardProgram")}{" "}
                  </span>
                </div>
                <div className={`${styles.cardProgramDateFilterSelect}`}>
                  <Field
                    name="card_program_id"
                    component={CardProgramPicker}
                    required
                    placeholder={t("admin.pages.expenseItem.labels.all")}
                    disabled={expenseDashboardForm?.expense_type?.value == "false"}
                    filterByActive={true}
                    className={`${styles.expenseTypeFilter}`}
                  />
                </div>
              </div>
            </div>
          )}

          <div className={`${styles.postedDateTypeFilterContainer}`}>
            <div className={styles.postedDateFilterField + (currentUser?.is_admin ? " justify-content-end" : "")}>
              <div className={`${styles.cardProgramFilterLabel}`}>
                <div className={`${styles.filterLabel}`}>{t("admin.pages.expenseItem.labels.filterByPostedDate")}</div>
              </div>
              <div className={`${styles.subsidiaryFilterSelect}`}>
                <Field
                  name="posted_date"
                  id="expense.posted_date"
                  component={({ input }: { input: WrappedFieldInputProps }) => {
                    return (
                      <Select
                        {...input}
                        onBlur={() => onBlurSingle(input, input.value)}
                        options={postedDateFilterOption}
                        formatOptionLabel={(opiton) => <>{t(opiton.postedDate)}</>}
                        onChange={(value) => {
                          input.onChange(value);
                          handlePostedDate(value);
                        }}
                      />
                    );
                  }}
                  required
                  className={`${styles.expenseTypeFilter}`}
                />
              </div>
            </div>
            {showCustom && (
              <Row className={`${styles.customFilterRow} ${currentUser?.is_admin && "justify-content-lg-end"}`}>
                <Col lg={1}></Col>
                <Col lg={4}>
                  <Field
                    name="posted_date_custom.startDate"
                    id="expense.posted_date.date"
                    label={t("admin.pages.expenseItem.labels.startDate")}
                    component={DatePicker}
                    inCompanyDateFormat
                  />
                </Col>
                <Col lg={4}>
                  <Field
                    name="posted_date_custom.endDate"
                    id="expense.posted_date.date"
                    label={t("admin.pages.expenseItem.labels.endDate")}
                    component={DatePicker}
                    inCompanyDateFormat
                  />
                </Col>
                <Col className={`${styles.submitButtonCol}`} lg={2}>
                  <Button
                    onClick={submitCustomDate}
                    className={`${styles.customSubmit}`}
                    variant="primary"
                  >{`Submit`}</Button>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Row>
    </>
  );
};

export default PostedDateCardProgramFilter;
