import { Field } from "redux-form";
import React from "react";
import { Col } from "react-bootstrap";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";

const UsBankCredential = () => {
  return (
    <>
      <Col lg="6">
        <Field
          id="us_bank_url"
          label="URL"
          name="form.us_bank.url"
          placeholder="URL"
          component={RenderField}
          autocomplete="off"
        />
      </Col>

      <Col lg="6">
        <Field
          id="us_bank_org_short_name"
          label="Org. Short Name"
          name="form.us_bank.org_short_name"
          placeholder="Org Short"
          component={RenderField}
          autocomplete="off"
        />
      </Col>

      <Col lg="6">
        <Field
          id="us_bank_consumer_key"
          label="Consumer Key"
          name="form.us_bank.consumer_key"
          placeholder="Consumer Key"
          component={RenderField}
          autocomplete="off"
        />
      </Col>

      <Col lg="6">
        <Field
          id="password"
          label="Consumer Secret"
          name="form.password"
          placeholder="Consumer Secret"
          component={RenderField}
          autocomplete="off"
        />
      </Col>
    </>
  );
};

export default UsBankCredential;
