import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import { TwValidations } from "../validations/twPaymentValidations";
import {
  TwAed,
  TwAud,
  TwBrl,
  TwCad,
  TwChf,
  TwCny,
  TwDkk,
  TwEuro,
  TwGbp,
  TwInr,
  TwIls,
  TwJpy,
  TwMyr,
  TwNok,
  TwNzd,
  TwPhp,
  TwSek,
  TwSgd,
  TwKes,
  TwZmw,
  TwNgn,
  TwGhs,
  TwPkr,
  TwZar,
  TwIdr,
  TwKrw,
  TwLkr,
  TwUsd,
  TwHkd,
  TwThb,
} from "./transferwiseCurrencyFields";

class TransferwiseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Row>
        <Col>
          {this.getCurrencyForm(this.props.currency_code ? this.props.currency_code : this.props.defaultCurrency)}
        </Col>
      </Row>
    );
  }

  getCurrencyForm(currencyCode) {
    switch (currencyCode) {
      case "EUR":
        return <TwEuro {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "AUD":
        return <TwAud {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "BRL":
        return <TwBrl {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "CAD":
        return <TwCad {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "CNY":
        return <TwCny {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "DKK":
        return <TwDkk {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "INR":
        return <TwInr {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "ILS":
        return <TwIls {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "JPY":
        return <TwJpy {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "MYR":
        return <TwMyr {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "GBP":
        return <TwGbp {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "AED":
        return <TwAed {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "CHF":
        return <TwChf {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "SEK":
        return <TwSek {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "SGD":
        return <TwSgd {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "PHP":
        return <TwPhp {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "NOK":
        return <TwNok {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "NZD":
        return <TwNzd {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "KES":
        return <TwKes {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "ZMW":
        return <TwZmw {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "NGN":
        return <TwNgn {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "GHS":
        return <TwGhs {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "PKR":
        return <TwPkr {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "ZAR":
        return <TwZar {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "IDR":
        return <TwIdr {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "KRW":
        return <TwKrw {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "THB":
        return <TwThb {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "LKR":
        return <TwLkr {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "USD":
        return <TwUsd {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      case "HKD":
        return <TwHkd {...this.props} fillteredPayment={this.filterFieldTW(currencyCode)} />;
      default:
        return null;
    }
  }

  filterFieldTW(fieldName) {
    return TwValidations.filter(function (item) {
      return item.currency === fieldName;
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    purchaser_id: state.paymentMethodRed.purchaser_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePurchaser: (payload) => {
      dispatch({ type: actionType.CHANGE_PURCHASER, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TransferwiseForm));
