import _ from "lodash";
import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SubmissionError } from "redux-form";
import RestApi from "../../../providers/restApi";
import {
  addCalenderEvents,
  CALENDAR_EVENT_FORM,
  onCalendarEventFormUnmount,
} from "../../../reducers/admin/calendarEventerReducer";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { createCompleteError } from "../../../services/general/reduxFormSvc";
import CustomModal from "../../modals/customModal";
import CalendarEventerForm from "./form";

const restApiService = new RestApi();

const AddDate = ({ addModalOpen, setAddModalOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModal = () => {
    setAddModalOpen(false);
  };

  useEffect(() => {
    return () => {
      // cleaning form while leaving
      dispatch(onCalendarEventFormUnmount());
    };
  }, []);

  const onSubmit = async (calendarEventData) => {
    try {
      const response = await restApiService.post("calendar_events", null, calendarEventData.form);
      // updating redux state instead fetching list agian
      dispatch(addCalenderEvents(response.data));
      CreateNotification(
        t("success"),
        t("components.admin.calendarEvent.successCreateCalendarEvent", { id: response.data.id }),
        NotificationType.success,
      );
      closeModal();
    } catch (error) {
      const { response } = error;
      if (response.status === 422 && _.isPlainObject(response.data)) {
        const completeErrorObj = createCompleteError(response.data);
        throw new SubmissionError({
          form: completeErrorObj,
        });
      }
    }
  };

  return (
    <CustomModal
      show={addModalOpen}
      onHide={closeModal}
      header={t("components.admin.calendarEvent.addCalendarEvent")}
      body={<CalendarEventerForm onSubmit={(value) => onSubmit(value)} />}
      footer={
        <Row>
          <Col xs="12">
            <Button className="mr-3" variant="secondary" onClick={closeModal}>
              {t("cancel")}
            </Button>
            <Button type="submit" form={CALENDAR_EVENT_FORM}>
              {t("components.admin.calendarEvent.createCalendarEvent")}
            </Button>
          </Col>
        </Row>
      }
    ></CustomModal>
  );
};

export default AddDate;
