import React from "react";
import _ from "lodash";
import { ReactI18NextChild } from "react-i18next";

export const draftInboxListHeader = [
  {
    headerName: "email",
    field: "from.email",
    minWidth: 225,
    maxWidth: 225,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    cellClass: "overflow-hidden px-2",
  },
  {
    headerName: "attachments",
    field: "attachments",
    minWidth: 30,
    maxWidth: 30,
    cellRenderer: attachmentsCountRender,
    cellClass: "px-2",
  },
  {
    headerName: "status",
    field: "status",
    minWidth: 85,
    maxWidth: 85,
    cellRenderer: ragRenderer,
    cellClass: "px-2",
  },
  {
    cellRenderer: "draftInboxCellRenderer",
    cellStyle: {
      fontFamily: "acre-regular",
    },
    flex: 1,
  },
];

export const draftInboxGridHeader = [
  {
    cellRenderer: "draftInboxCellRenderer",
    cellStyle: {
      fontFamily: "acre-regular",
    },
    flex: 1,
  },
];

function attachmentsCountRender(params: { value: string | any[] }) {
  return <span className="emailAttachment">{_.isArray(params.value) ? params.value.length : 0}</span>;
}

function ragRenderer(params: {
  value:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | Iterable<ReactI18NextChild>
    | null
    | undefined;
}) {
  return (
    <span className="processedTag px-1 py-1" style={{ fontSize: "12px" }}>
      {params?.value}
    </span>
  );
}
