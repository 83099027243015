import { Col, Row } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Can } from "../../../services/authorization/authorization";

const DetailsCompany = ({ company }) => {
  const { t } = useTranslation();
  let address = company?.address || {};
  return (
    <Row className={"py-4 mt-4 mx-1 bg-white panel-section"}>
      <Col>
        <Row>
          <Can I={"edit"} a={"Company"}>
            <Col className="text-right">
              <Link to="/ar/company/edit" className="btn btn-primary mr-2">
                <i className="icon-g icon-edit-forword"></i>
                {t("edit.edit")}
              </Link>
            </Col>
          </Can>
        </Row>
        <Row>
          <Col sm className="pr-sm-0">
            <dl className="row">
              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.companyName")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{company?.name}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.email")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{company?.email}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.phone")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{company?.phone}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{"EIN/Tax ID/VAT"}</dt>
              <dd className="col-6 col-sm-7 detailValue">{company?.ein}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{"SSN"}</dt>
              <dd className="col-6 col-sm-7 detailValue">{company?.masked_ssn}</dd>
            </dl>
          </Col>
          <Col sm className="pr-sm-0 pl-sm-0">
            <dl className="row">
              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.country")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{address?.country}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.address1")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{address?.address1}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.address2")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{address?.address2}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.city")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{address?.city}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.state")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{address?.state}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.zipcode")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{address?.zipcode}</dd>
            </dl>
          </Col>
          <Col sm className="pl-sm-0">
            <dl className="row mb-0">
              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.1099Eligible")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{company?.is_1099_eligible ? "true" : "false"}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.w9Form")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{company?.has_w9 ? "true" : "false"}</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.w8Ben")}</dt>
              <dd className="col-6 col-sm-7 detailValue">required</dd>

              <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.logo")}</dt>
              <dd className="col-6 col-sm-7 detailValue">{company?.logo_url}</dd>
            </dl>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default DetailsCompany;
