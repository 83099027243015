import React, { useState } from "react";
import Panel from "../../common/panel/panel";
import styles from "./subsidiaries.module.css";
import { Button, Table } from "react-bootstrap";
import { useTypedSelector } from "../../../reducers";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useDispatch } from "react-redux";
import ManageSubsidiaryAccounts from "../manageDefaultAccounts/manageSubsidiaryAccounts";
import { setComponentMountMode } from "../../../reducers/admin/manageAccountFormReducer";
import { CompanyType } from "../../../services/common/user/userTypes";
import { SubsidiaryDetailType, SubsidiaryLinkAccountType } from "../../../services/admin/subsidiaries/types";

type AccountsTypes = {
  accounts: {
    label: string;
    account_links: SubsidiaryLinkAccountType[];
  }[];
  handleClose: () => void;
  modelData: SubsidiaryDetailType;
};

const LinkAccounts = ({ accounts, modelData, handleClose }: AccountsTypes) => {
  const { t } = useTranslation();
  const company: CompanyType = useTypedSelector((state) => state.user?.company);
  const [showAccounts, setShowAccounts] = useState<boolean>(false);
  const dispatch = useDispatch();

  const editAccounts = () => {
    setShowAccounts(true);
    dispatch(setComponentMountMode(true));
  };

  const closeModel = () => {
    handleClose();
    setShowAccounts(false);
  };

  return (
    <>
      {showAccounts && (
        <>
          <ManageSubsidiaryAccounts
            modelData={modelData}
            modelName="subsidiary"
            isHide={showAccounts}
            saveEditCallback={() => closeModel()}
          />
        </>
      )}
      <Panel
        cardHeaderClass="px-p-10"
        header={
          <div className="d-flex justify-content-between">
            <div className="flex-center">
              <i className="icon icon-gl"></i> {"ACCOUNTS"}
            </div>
            <div>
              <Button
                variant="secondary"
                size="sm"
                className={`d-flex justify-content-center align-items-center font-weight-bold ${styles.editBtn}`}
                onClick={() => editAccounts()}
              >
                <i className="icon icon-edit"></i>Edit
              </Button>
            </div>
          </div>
        }
      >
        <div className={styles.tableWrapper}>
          <Table responsive className={styles.table + " " + styles.tableStriped + " text-left"}>
            <thead>
              <tr>
                <th>{t("companySettings.accountSettings.accountType")}</th>
                <th>{t("companySettings.accountSettings.account")}</th>
                <th>{t("companySettings.accountSettings.percentage")}</th>
                {company?.has_departments && <th>{t("companySettings.accountSettings.department")}</th>}
                {company?.has_business_units && <th>{t("companySettings.accountSettings.businessUnit")}</th>}
                {company?.has_locations && <th>{t("companySettings.accountSettings.location")}</th>}
                {company?.has_inter_companies && <th>{t("companySettings.accountSettings.interCompany")}</th>}
              </tr>
            </thead>
            <tbody>
              {_.isArray(accounts) &&
                accounts.length > 0 &&
                accounts.map((account, index) => {
                  return (
                    _.isArray(account.account_links) &&
                    account.account_links.length > 0 &&
                    account.account_links.map((accountData) => (
                      <tr key={accountData.id}>
                        <td>{account.label}</td>
                        <td> {accountData.account_name ? accountData.account_name : null}</td>
                        <td>{accountData.percent} %</td>
                        <td>{accountData.department_name}</td>
                        <td>{accountData.business_unit_name}</td>
                        <td>{accountData.location_name}</td>
                        {company?.has_inter_companies && <td>{accountData.inter_company_name}</td>}
                      </tr>
                    ))
                  );
                })}
            </tbody>
          </Table>
        </div>
      </Panel>
    </>
  );
};

export default LinkAccounts;
