import { ColumnApi, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import RefreshCacheButton from "components/buttons/refreshCacheButton";
import ExportDataButton from "components/common/dataGrid/exportDataButton";
import ImportDataButton from "components/common/dataGrid/importDataButton";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import usePermission from "components/common/hooks/usePermission";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { ReportApis } from "services/admin/reports/reportApis";
import { ReportCreateType } from "services/admin/reports/reportTypes";
import { getFilterSortParams, getParams, saveDefaultOrder } from "services/common/gridService";
import { useTypedSelector } from "../../../reducers";
import { VendoListType } from "../../../services/admin/vendors/vendorTypes";
import { VendorApis } from "../../../services/admin/vendors/vensorApis";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import gridService from "../../../services/grid/gridSvc";
import ServerSideDataGrid from "../../common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import useShowFilterState from "../../common/hooks/useShowFilterState";
import ToggleFilterButton from "../../datagrid/buttons/toggleFilterButton";
import GridFilterDropdown from "../../datagrid/gridFilterDropdown";
import useConfirmModal from "../../modals/confirmModal/useConfirmModalHook";
import BulkAction from "./bulkAction";
import NavTabs from "./nav";
import VendorReportCreatedModal from "./vendorReportCreatedModal";
import { FIELD_NAME_MAP, getVendorsHeaders } from "./vendorsGridHeader";

const GRID_STORAGE_NAME = "listVendor";
const skipContextMenuCols = ["name"];

const ListVendors = () => {
  const location = useLocation();
  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const { showFilters, updateShowFilters } = useShowFilterState("listVendorFilter", true);
  const currentUser = useTypedSelector((state) => state.user);
  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();
  const checkBoxRef: any = useRef(null);
  const [reportCreatedModalOpen, setReportCreatedModalOpen] = useState<boolean>(false);
  const { hasUserPermission } = usePermission();
  const showAddVendorButton = hasUserPermission("requestVendors") || hasUserPermission("addVendors");
  const showRefreshCacheButton = hasUserPermission("addVendors") || hasUserPermission("importVendors");

  const refreshGrid = useCallback(() => {
    if (gridApi) {
      gridApi.refreshServerSide({ purge: true });
    }
  }, [gridApi]);

  const gridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    //tell the grid that we are using a custom data source
    params.api.setGridOption("serverSideDatasource", { getRows: getRows });
  }, []);

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = async (params: IServerSideGetRowsParams) => {
    try {
      const result = await VendorApis.getVendorList({ filter: getParams(params, FIELD_NAME_MAP) });
      params.success({
        rowData: result.data,
        rowCount: result.meta.count,
      });
    } catch (error) {
      params.fail();
    }
  };

  const deleteConfirmCallBack = async (vendor: VendoListType) => {
    try {
      await VendorApis.deleteVendor(vendor.id);
      if (gridApi) {
        gridService.removeRowFromGrid(gridApi, String(vendor.id));
      }
      CreateNotification(t("success"), t("admin.pages.vendor.deleteVendor"), NotificationType.success);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteVendor = useCallback((vendor: VendoListType) => {
    createConfirmModal({
      title: "Confirm",
      body: `Are you sure about deleting the "${vendor.name}" vendor?`,
      saveCallBack: deleteConfirmCallBack,
      cancelCallBack: null,
      callBackData: vendor,
    });
  }, []);

  const gridHeaders = useMemo(
    () => getVendorsHeaders({ gridApi, gridColumnApi, currentUser, deleteVendor, checkBoxRef }),
    [currentUser, deleteVendor, gridApi, gridColumnApi],
  );

  const setFiltersFromUrl = useCallback(
    (params: GridReadyEvent) => {
      const source_document_id = searchParams.get("source_document_id");
      if (source_document_id) {
        params.api.setFilterModel({
          "source_document.id": {
            filter: source_document_id,
            filterType: "text",
            type: "contains",
          },
        });
      }
    },
    [searchParams],
  );

  const exportDataAsCsv = async () => {
    try {
      const listOfSortModel = gridColumnApi?.getColumnState().filter((s: any) => s.sort !== null) ?? [];
      const filters = getFilterSortParams({
        FIELD_NAME_MAP,
        filterModel: gridApi?.getFilterModel() ?? [],
        sortModel: listOfSortModel,
      });

      const payload: ReportCreateType = {
        // report_template_id: 6 is for vendor list on Production
        report_template_id: 6,
        properties: {
          filters,
        },
        exclude_header_description: false,
        export_destination: "download",
      };

      const result = await ReportApis.createReport(payload);
      openReportedCreateModal();
    } catch (error) {}
  };

  const closeReportedCreateModal = () => {
    setReportCreatedModalOpen(false);
  };

  const openReportedCreateModal = () => {
    setReportCreatedModalOpen(true);
  };

  useEffect(() => {
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      filter: true,
      floatingFilter: showFilters,
    };
  }, [showFilters]);

  const paginationSizes = useMemo(() => [25, 50, 100], []);

  return (
    <>
      {reportCreatedModalOpen && (
        <VendorReportCreatedModal show={reportCreatedModalOpen} onHide={closeReportedCreateModal} />
      )}
      <Row style={{ margin: "10px 25px" }}>
        <Col sm={12} className="p-0 mb-2">
          <NavTabs activePageName="Vendors" />
        </Col>
        <Col className="px-0">
          <Row>
            <Col md="12" className="d-flex">
              <BulkAction gridApi={gridApi} />
              <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} gridStorageName={GRID_STORAGE_NAME} />
              {showAddVendorButton && (
                <div>
                  <Link to="vendors/add">
                    <Button variant="primary ml-2">
                      <i className="btn-icon icon-add-btn"></i>Add
                    </Button>
                  </Link>
                </div>
              )}
              {gridApi && (
                <div className="ml-auto d-flex align-items-center">
                  {showRefreshCacheButton && (
                    <RefreshCacheButton
                      onRefreshComplete={refreshGrid}
                      refreshCacheFunction={VendorApis.refreshCache}
                    />
                  )}
                  <ExportDataButton
                    title={t("admin.pages.vendor.exportVendors")}
                    gridApi={gridApi}
                    onClick={() => exportDataAsCsv()}
                  />
                  <ImportDataButton title={t("admin.pages.vendor.importVendors")} />
                  <ToggleFilterButton
                    clickCallback={() => {
                      updateShowFilters(!showFilters);
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <ErrorBoundary>
        <Row style={{ height: "70vh", margin: "25px" }}>
          <ServerSideDataGrid
            onFirstDataRendered={setFiltersFromUrl}
            columnDefs={gridHeaders.columnDefs}
            defaultColDef={defaultColDef}
            // onPaginationChanged={() => checkBoxRef?.current?.onPaginationChange()}
            gridReady={gridReady}
            rowSelection="multiple"
            gridStorageName={GRID_STORAGE_NAME}
            domLayout="normal"
            paginationSize={25}
            paginationOptions={paginationSizes}
            pagination
            skipContextMenuCols={skipContextMenuCols}
          />
        </Row>
      </ErrorBoundary>
    </>
  );
};

export default ListVendors;
