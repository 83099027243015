import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Overlay, Row } from "react-bootstrap";
import Style from "../workFlow.module.css";
import { ReactComponent as EditListIcon } from "../../../../../assets/workflow/edit-list.svg";
import { ReactComponent as ViewListIcon } from "../../../../../assets/workflow/view-list.svg";
import { ReactComponent as IcApprovalWorkflowIcon } from "../../../../../assets/workflow/ic-approval-workflow.svg";
import { ReactComponent as AddIcon } from "../../../../../assets/workflow/plus.svg";
import DragAndDrop from "../../../../../components/workflow/dragAndDrop";
import { workflow_types } from "../../../../../lookups/approvalWorkFlowLookups";
import RestApi from "../../../../../providers/restApi";
import WorkFlowListView from "../../../../../components/workflow/workFlowListView";
import ApprovalFormRedux from "../../../../../components/workflow/approvalReduxForm";
import TestTriggerForm from "../../../../../components/workflow/testTriggerForm";
import TabNavigation from "../../../../../components/navigation/tabNavigation";
import { NotificationType, CreateNotification } from "../../../../../services/general/notifications";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveStepById,
  setActiveStepIndex,
  setIsInactive,
  setLastStepSaveFlag,
  setShowAddButtonTooltip,
  setStatic,
  setView,
  setWorkFlowActiveStatus,
  setWorkFlowSteps,
  setWorkFlowType,
  setWillTrigger,
} from "../../../../../reducers/approvalSettingsReducers";
import { destroy, initialize } from "redux-form";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import NavTabs from "../../nav";

const GRIDVIEW = "GRIDVIEW";
const LISTVIEW = "LISTVIEW";

const WorkFlow = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const history = useHistory();

  const [paramId, setParamId] = useState(param.id);

  const addButtonRef = useRef();
  // const [showAddButtonTooltip, setShowAddButtonTooltip] = useState(false);
  const restApiService = new RestApi();
  const state = useSelector((state) => state.approvalSettingsReducer);

  const getWorkFlowSteps = async () => {
    const res = await restApiService.get("approval_workflows", {
      workflow_type: state.workFlowType,
      active_required: state.activeRequired,
      static: false,
    });
    if (res.data) {
      dispatch(setWorkFlowSteps(res.data.filter((aw) => !aw.trigger.includes("STATIC"))));
      dispatch(setStatic(false));
      return res;
    }
  };

  const moveToStepUrl = (step) => {
    setParamId(step.id);
    history.push(`/ap/approval_workflows/${step.id}`);
    dispatch(destroy("workFlowApprovalForm"));
    dispatch(destroy("testTriggerForm"));
    setTimeout(() => {
      dispatch(initialize("workFlowApprovalForm", step));
      dispatch(initialize("testTriggerForm", step));
      dispatch(setActiveStepById(step.id));
    }, 300);
  };

  const moveToFirstStepUrl = async () => {
    dispatch(setLastStepSaveFlag(true));
    const res = await getWorkFlowSteps();
    if (res.data && res.data[0]) moveToStepUrl(res.data[0]);
  };

  const getInitialWorkFlowSteps = async () => {
    // if url contain id then use it for data fetching and showing
    // set paramId to falsy value to go else block
    if (paramId) {
      try {
        const res = await restApiService.get(`approval_workflows/${param.id}`);
        if (res.data) {
          const stepData = res.data;
          onChangeWorkFlowType(stepData.workflow_type);
          if (stepData.status === "ACTIVE") onChangeFlowActiveStatus(true);
          if (stepData.status === "INACTIVE") onChangeFlowActiveStatus(false);
          if (res.data) {
            const res = await getWorkFlowSteps();
            if (res.data) moveToStepUrl(stepData);
          }
        } else {
          // no data got from param id then again move to
          moveToFirstStepUrl();
        }
      } catch (error) {
        // if record not found or any other error move first step url
        console.log("error", error);
        moveToFirstStepUrl();
      }
    } else {
      moveToFirstStepUrl();
    }
  };

  const resetParamId = () => {
    setParamId(undefined);
  };

  useEffect(() => {
    getInitialWorkFlowSteps();
  }, [state.workFlowType, state.activeRequired]);

  const onChangeView = (view) => {
    dispatch(setView(view));
  };

  const onChangeWorkFlowSteps = (newSteps) => {
    dispatch(setWorkFlowSteps(newSteps));
  };

  //   const onChangeApprovalFormData = (formData) => {
  //     dispatch(setApprovalFormData(formData));
  //   };

  const onChangeActiveStepIndex = (step) => {
    dispatch(setActiveStepIndex(step));
  };

  const onChangeFlowActiveStatus = (status) => {
    dispatch(setWorkFlowActiveStatus(status));
  };

  const onChangeWorkFlowType = (workFlowType) => {
    dispatch(setWorkFlowType(workFlowType));
  };

  const onChangeFlowActiveStatusSelect = (status) => {
    resetParamId();
    dispatch(setLastStepSaveFlag(true));
    dispatch(setWorkFlowActiveStatus(status));
  };

  const onChangeWorkFlowTypeSelect = (workFlowType) => {
    resetParamId();
    dispatch(setLastStepSaveFlag(true));
    dispatch(setWorkFlowType(workFlowType));
  };

  const addStep = () => {
    // for tool tip to prevent adding of unnessary step
    if (!state.isLastStepSaved) {
      if (!state.showAddButtonTooltip) {
        dispatch(setShowAddButtonTooltip(true));
        setTimeout(() => {
          dispatch(setShowAddButtonTooltip(false));
        }, 2000);
      }
      return;
    }
    // disable new step adding
    dispatch(setLastStepSaveFlag(false));

    const newStep = {
      priority: state.workFlowSteps.length > 0 ? state.workFlowSteps[state.workFlowSteps.length - 1].priority + 1 : 1,
      workflow_type: state.workFlowType,
      status: "ACTIVE",
      value: {
        label: `Step ${state.workFlowSteps.length + 1}`,
        approver_type: "USER",
        any_member: false,
        any_member_count: 0,
        team_member: [],
        triggers: [],
      },
      skip_duplicate_approver: false,
      ignore_approval_limit: false,
      email_notification: false,
      email_reminder: false,
      parallel_team: false,
    };

    onChangeWorkFlowSteps([...state.workFlowSteps, { ...newStep }]);
    onChangeActiveStepIndex(state.workFlowSteps.length);
    dispatch(destroy("workFlowApprovalForm"));
    dispatch(destroy("testTriggerForm"));
    setTimeout(() => {
      dispatch(initialize("workFlowApprovalForm", newStep));
      history.push(`/ap/approval_workflows/new_approval`);
    }, 300);
  };

  const resetWillTrigger = () => {
    dispatch(setWillTrigger(null));
  };

  const onSubmitTest = async (value) => {
    // Tests if the workflow will trigger given the ID of an approvable
    try {
      if (value.id && value.approvableID) {
        const result = await restApiService.post(
          `approval_workflows/${value.id}/will_trigger`,
          {},
          {
            approval_workflow: value,
            approvable_id: value.approvableID,
          },
        );
        if (result.data) {
          dispatch(setWillTrigger(true));
        } else {
          dispatch(setWillTrigger(false));
          return "false";
        }
      }
    } catch (error) {
      if ((error.response.status = 404)) {
        dispatch(setWillTrigger(404));
      }
    }
  };

  const onSubmit = async (value) => {
    // fix for any_member_count
    if (!value.value.any_member_count) {
      delete value.value.any_member_count;
      delete value.value.any_member;
    } else value.value.any_member_count = Number(value.value.any_member_count);

    if (value.value.designation == "ACTION") {
      delete value.value.approver_type;
      delete value.value.contact_approver_id;
      delete value.value.team_member;
      if (!value.value.action) {
        value.value.action = "APPROVE";
      }
      if (value.value.action == "APPROVE") {
        value.value.last = true;
      } else value.value.last = false;
    } else {
      delete value.value.action;
      delete value.value.last;
    }

    // fix for trigger invoice PO
    if (value.value.triggers) {
      const typeCastedTriggers = value.value.triggers.map((trigger) => {
        if (trigger.key === "INVOICE_PO") return { ...trigger, value: JSON.parse(trigger.value) };
        else return trigger;
      });
      value.value.triggers = typeCastedTriggers;
    }

    // Format Manager Level
    if (!value.value.department_manager_level) {
      delete value.value.department_manager_level;
    } else value.value.department_manager_level = Number(value.value.department_manager_level);
    if (!value.value.location_manager_level) {
      delete value.value.location_manager_level;
    } else value.value.location_manager_level = Number(value.value.location_manager_level);

    try {
      if (!value.id) {
        try {
          const result = await restApiService.post(
            "approval_workflows",
            {},
            {
              approval_workflow: value,
            },
          );
          if (result.data) {
            CreateNotification(
              "Approval Workflow ",
              `Approval Workflow ${result.data.label} Added.`,
              NotificationType.success,
            );
            const res = await getWorkFlowSteps();
            if (res.data) {
              moveToStepUrl(result.data);
              // emable new step adding
              dispatch(setLastStepSaveFlag(true));
            }
          }
          if (value.status == "INACTIVE") {
            onChangeFlowActiveStatusSelect(false);
          }
        } catch (error) {
          if (error.response?.data?.value?.includes("Approver has admin_readonly role")) {
            console.log(error.message);
            CreateNotification(
              "Approval Workflow ",
              "Approver has 'admin_readonly' role, and cannot approve or reject approvals. Choose an approver with appropriate permissions",
              NotificationType.danger,
            );
          } else if (error.response?.data?.value?.includes("No team selected")) {
            console.log(error.message);
            CreateNotification(
              "Approval Workflow ",
              "No team selected -- you must select a team for this workflow step. If you do not have any teams, you can create one in the 'Users' page under 'Teams'",
              NotificationType.danger,
            );
          } else {
            console.log(error.message);
            CreateNotification("Approval Workflow ", `${error.message}`, NotificationType.danger);
          }
        }
      }

      try {
        // edit
        if (value.id) {
          // If inactive workflow is made active, reorder and save priorities to avoid duplicates
          if (state.isInactive) {
            var priorities = [];
            state.workFlowSteps.map((workFlow, index) => {
              priorities.push({ id: workFlow.id, priority: index + 1 });
              if (value.id == workFlow.id) value.priority = index + 1;
            });
            restApiService.patch(
              `approval_workflows/reorder`,
              {},
              {
                priorities: priorities,
                workflow_type: state.workFlowType,
              },
            );

            dispatch(setIsInactive(false));
          }
          const result = await restApiService.patch(
            `approval_workflows/${value.id}`,
            {},
            {
              approval_workflow: value,
            },
          );

          if (result.data) {
            CreateNotification(
              "Approval Workflow ",
              `Approval Workflow ${result.data.label} Updated.`,
              NotificationType.success,
            );

            getWorkFlowSteps();
          }
        }
        if (value.status == "INACTIVE") {
          onChangeFlowActiveStatusSelect(false);
        }
      } catch (error) {
        if (error.response?.data?.value?.includes("Approver has admin_readonly role")) {
          console.log(error.message);
          CreateNotification(
            "Approval Workflow ",
            "Approver has 'admin_readonly' role, and cannot approve or reject approvals. Choose an approver with appropriate permissions",
            NotificationType.danger,
          );
        } else if (error.response?.data?.value?.includes("No team selected")) {
          console.log(error.message);
          CreateNotification(
            "Approval Workflow ",
            "No team selected -- you must select a team for this workflow step. If you do not have any teams, you can create one in the 'Users' page under 'Teams'",
            NotificationType.danger,
          );
        } else {
          console.log(error.message);
          CreateNotification("Approval Workflow ", `${error.message}`, NotificationType.danger);
        }
      }
    } catch (error) {
      console.log(error.message);
      CreateNotification("Approval Workflow ", `${error.message}`, NotificationType.danger);
    }
  };

  return (
    <Container fluid>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"Approval Settings"} />
        </Col>
      </Row>
      <Row className={`${Style.card} fontSizeNormal w-100`}>
        <Col>
          <Row
            className={`m-0 pt-2 pb-1 fontSizeNormal w-100 secondaryDarkBlueFontColor d-flex flex-wrap ${Style.workFlowRow} mb-3`}
          >
            <Col xs={12}>
              <TabNavigation
                navigationTab={[
                  {
                    path: "/ap/approval_workflows",
                    pageName: "Approval Workflows",
                    isActive: "active",
                  },
                  {
                    path: "/ap/static_workflows",
                    pageName: "Static Workflows",
                    isActive: "",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row
            className={` m-0 pt-2 pb-1 fontSizeNormal w-100 secondaryDarkBlueFontColor d-flex flex-wrap ${Style.workFlowRow} mb-3`}
          >
            <Col md={6}>
              <div className="d-flex justify-content-around align-items-center my-2 w-75">
                <label className="w-25">Workflow type</label>
                <Form.Control
                  as="select"
                  value={state.workFlowType}
                  onChange={(e) => onChangeWorkFlowTypeSelect(e.target.value)}
                  className={`${Style.Custom__Select} m-2 w-50`}
                >
                  {workflow_types.map((workFlowType, index) => {
                    return (
                      <option key={index} value={workFlowType.value}>
                        {workFlowType.label}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
            </Col>
            <Col md={2} />

            <Col md={4}>
              <div className="d-flex justify-content-around align-items-center flex-wrap">
                Status
                <Form.Control
                  as="select"
                  className={`${Style.Custom__Select} m-2 w-50`}
                  value={state.activeRequired}
                  onChange={(e) => onChangeFlowActiveStatusSelect(e.target.value)}
                >
                  <option value={true}>ACTIVE</option>
                  <option value={false}>ALL</option>
                </Form.Control>
                <i onClick={() => onChangeView(GRIDVIEW)}>
                  <EditListIcon style={{ cursor: "pointer" }} />
                </i>
                <i onClick={() => onChangeView(LISTVIEW)}>
                  <ViewListIcon style={{ cursor: "pointer" }} />
                </i>
              </div>
            </Col>
          </Row>

          {state.view === LISTVIEW && <WorkFlowListView workFlowSteps={state.workFlowSteps} />}
          {state.view === GRIDVIEW && (
            <Row className="m-0 mb-5">
              {/* work flow steps */}
              <Col md={4} className={`${Style.workflowStepContainer} p-0 m-0`}>
                <div className={`d-flex justify-content-around align-items-center ${Style.workflowHeader}`}>
                  <div>
                    <IcApprovalWorkflowIcon />
                  </div>
                  <div>
                    {state.workFlowType && workflow_types.find((type) => type.value === state.workFlowType).label}
                  </div>
                  <div>
                    <Button onClick={addStep} ref={addButtonRef} variant="outline-primary">
                      <AddIcon /> Step
                    </Button>
                    <Overlay target={addButtonRef.current} show={state.showAddButtonTooltip} placement="top">
                      {({ placement, arrowProps, show: _show, popper, ...props }) => (
                        <div
                          {...props}
                          style={{
                            position: "absolute",
                            backgroundColor: "#448aba",
                            padding: "10px 10px",
                            marginBottom: "5px",
                            color: "white",
                            borderRadius: 3,
                            ...props.style,
                          }}
                        >
                          Please save last step data or discard unsaved data.
                        </div>
                      )}
                    </Overlay>
                  </div>
                </div>

                <div>
                  {!state.workFlowSteps.length && <div className="m-3">There are no steps.</div>}
                  <DragAndDrop />
                </div>
              </Col>
              <Col md={8} className="p-0 m-0">
                {_.isArray(state.workFlowSteps) && state.workFlowSteps.length > 0 && (
                  <ApprovalFormRedux onSubmit={onSubmit} />
                )}
                <hr style={{ width: "98%", marginLeft: "0px", marginRight: "15px" }} />
                {_.isArray(state.workFlowSteps) && state.workFlowSteps.length > 0 && (
                  <TestTriggerForm onSubmit={onSubmitTest} onChange={resetWillTrigger} />
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default WorkFlow;
