import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PaymentAccountPicker from "../pickers/paymentAccountPicker";
import { Link } from "react-router-dom";

const PickPaymentMethodModal = (props) => {
  let [paymentMethod, setPaymentMethod] = useState();
  return (
    <Modal show={props.show} onHide={props.closeCallback}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <PaymentAccountPicker
          limitPaymentMethods={props.limitPaymentMethods}
          callback={setPaymentMethod}
          hideLabel={true}
        />
        <p>
          If you do not see a valid payment method go to your{" "}
          <Link to="/ar/banking_information" className={"primaryLinkColor"}>
            bank settings
          </Link>{" "}
          and add one
        </p>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-secondary" onClick={props.closeCallback}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={!paymentMethod}
          onClick={() => {
            props.saveCallback(paymentMethod.raw);
          }}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PickPaymentMethodModal;
