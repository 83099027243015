import ChangeOrderApprovalDetail from "./approval/changeOrders/details";
import ChangeOrderApprovalList from "./approval/changeOrders/list";
import POChangeOrderDetails from "./changeOrders/details";
import ChangeOrderList from "./changeOrders/list";
import RequestPOForm from "./poRequest";

export const adminPurchaseOrderRoutes = [
  {
    path: "/ap/po_co_approvals",
    exact: true,
    name: "Change Order Approval List",
    component: ChangeOrderApprovalList,
    authorization: {
      I: "",
      a: "",
    },
  },
  {
    path: "/ap/po_co_approvals/:id",
    exact: true,
    name: "Change Order Approval Detail",
    component: ChangeOrderApprovalDetail,
    authorization: {
      I: "",
      a: "",
    },
  },
  {
    path: "/ap/change_orders",
    exact: true,
    name: "Change Order List",
    component: ChangeOrderList,
    authorization: {
      I: "",
      a: "",
    },
  },
  {
    path: "/ap/po_co_detail/:id",
    exact: true,
    name: "Change Order Details",
    component: POChangeOrderDetails,
    // authorization: {
    //   I: "",
    //   a: ""
    // }
  },
  {
    path: "/ap/purchase_orders/requests/new",
    exact: true,
    name: "Request PO",
    component: RequestPOForm,
    authorization: {
      I: "request",
      a: "PurchaseOrders",
    },
  },
  {
    path: "/ap/purchase_orders/requests/:id/edit",
    exact: true,
    name: "Edit Request PO",
    component: RequestPOForm,
    authorization: { permissions: ["requestPurchaseOrders", "editPurchaseOrders", "po_approval_edit"] },
  },
];
