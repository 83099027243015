import React, { useState, useCallback } from "react";
import { translate } from "../../../../services/general/translation";
import { ReportSvc } from "../../../../services/admin/reports/reportSvc";
import styles from "./reports.module.css";
import { Col, Row } from "react-bootstrap";
import DownloadModal from "../dataExtracts/downloadModal";
import { DataExtractModuleTypes, ReportDetailType } from "../../../../services/admin/reports/reportTypes";
import { ReportApis } from "../../../../services/admin/reports/reportApis";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { companyDateFormat } from "services/general/dateSvc";
import { formatDateSvc } from "services/date/date";
import { normalizeAmountValue, twoDecimalFormattedAmount } from "services/general/helpers";

type ActionsType = {
  data: ReportDetailType;
  callback: () => Promise<void>;
};

const Actions = ({ data, callback }: ActionsType) => {
  const [downloadVisible, setDownloadVisible] = useState<boolean>(false);
  const showDownload = useCallback(() => {
    setDownloadVisible(true);
  }, []);

  const hideDownload = useCallback(() => {
    setDownloadVisible(false);
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      const res = await ReportApis.delete(data.id);
      if (res) {
        await callback();
        CreateNotification("Data Extract", `Data Extract - ${data.name} Deleted.`, NotificationType.success);
      }
    } catch (err) {}
  }, []);

  return (
    <>
      <Row className="m-0 align-items-center position-relative">
        <Col className="p-0">
          <button
            disabled={data.status !== "COMPLETED"}
            onClick={showDownload}
            className={`${styles.downloadIcon} ${data.status !== "COMPLETED" && styles.downloadIconDisabled}`}
          >
            <i className="icon-sm icon-ic-download" />
          </button>
        </Col>
        <Col className="p-0 mx-1">
          <button type="button" onClick={handleDelete} data-toggle="tooltip" className={styles.deleteIcon}>
            <i className="icon-sm icon-delete" />
          </button>
        </Col>
      </Row>
      {downloadVisible ? (
        <DownloadModal
          id={data.id}
          close={hideDownload}
          classification={data?.report_template?.classification}
          module_type={data?.report_template?.module_type}
        />
      ) : null}
    </>
  );
};

export const getReportsHeaders = (
  callback: () => Promise<void>,
  currentUser: any,
  dataExtractModule?: DataExtractModuleTypes,
) => {
  switch (dataExtractModule) {
    case "Invoice":
      return [
        {
          field: "name",
          headerName: "Report",
          filter: "agTextColumnFilter",
          sortable: true,
          headerValueGetter: function () {
            return translate("name");
          },
        },
        {
          field: "id",
          headerName: "ID",
          filter: "agTextColumnFilter",
          sortable: true,
          headerValueGetter: function () {
            return translate("id");
          },
        },
        {
          field: "properties.number_of_invoices",
          headerName: "Number of Invoices",
          filter: "agTextColumnFilter",
          sortable: true,
          headerValueGetter: function () {
            return translate("num_invoices");
          },
        },
        {
          field: "properties.total_amount",
          headerName: "Total Amount",
          filter: "agTextColumnFilter",
          sortable: true,
          headerValueGetter: function () {
            return translate("totalAmount");
          },
          cellRenderer: function (params: any) {
            if (params.value) {
              return twoDecimalFormattedAmount({
                value: params.value,
                currencyCode: params.data.properties.currency_code,
              });
            } else {
              return null;
            }
          },
          comparator: function (valueA: string, valueB: string) {
            // if no amount (usually from failed report), set as -Infinity for proper grid sort
            const a = valueA ? normalizeAmountValue(valueA) : -Infinity;
            const b = valueB ? normalizeAmountValue(valueB) : -Infinity;

            // compare
            if (a < b) return -1;
            if (a > b) return 1;
            if (a === b) return 0;
          },
        },
        {
          field: "properties.currency_code",
          headerName: "Currency Code",
          filter: "agTextColumnFilter",
          sortable: true,
          headerValueGetter: function () {
            return translate("currency_code");
          },
        },
        {
          field: "properties.created_by",
          headerName: "Created By",
          filter: "agTextColumnFilter",
          sortable: true,
          headerValueGetter: function () {
            return translate("created_by");
          },
        },
        {
          field: "started",
          headerName: "Creation Date",
          cellRenderer: (data: any) => companyDateFormat(data.value, currentUser),
          filter: "agDateColumnFilter",
          sortable: true,
          sort: "desc",
          filterParams: {
            comparator: function (filterLocalDateAtMidnight: Date, cellValue: string) {
              return formatDateSvc(filterLocalDateAtMidnight, cellValue);
            },
            suppressAndOrCondition: true,
            filterOptions: ["equals", "inRange"],
          },
          headerValueGetter: function () {
            return translate("creation_date");
          },
        },
        {
          field: "status",
          headerName: "Status",
          filter: "agTextColumnFilter",
          filterParams: {
            suppressAndOrCondition: true,
          },
          headerValueGetter: function () {
            return translate("status");
          },
        },
        {
          field: "Actions",
          cellRenderer: (params: any) => {
            return <Actions data={params?.data} callback={callback} />;
          },
          sortable: false,
          resizable: false,
          maxWidth: 200,
          cellRendererParams: {
            viewLink: "/ap/po_co_approvals/",
          },
          cellRendererSelector: function (params: { data: { id: number } }) {
            return params.data && !params.data.id;
          },
          headerValueGetter: function () {
            return translate("actions");
          },
        },
      ];
    default:
      return [
        {
          field: "id",
          headerName: "ID",
          filter: "agTextColumnFilter",
          sortable: true,
          headerValueGetter: function () {
            return translate("id");
          },
        },
        {
          field: "name",
          headerName: "Report",
          filter: "agTextColumnFilter",
          sortable: true,
          headerValueGetter: function () {
            return translate("name");
          },
        },
        {
          field: "report_template.name",
          headerName: "Template Name",
          filter: "agTextColumnFilter",
          sortable: true,
          headerValueGetter: function () {
            return translate("template_name");
          },
        },
        {
          field: "created_at",
          headerName: "Creation Date",
          filter: "agTextColumnFilter",
          sortable: true,
          sort: "desc",
          filterParams: {
            suppressAndOrCondition: true,
          },
          cellRenderer: (params: any) => {
            return companyDateFormat(params.value, currentUser);
          },
          headerValueGetter: function () {
            return translate("creation_date");
          },
        },
        {
          field: "properties.filters",
          headerName: "Filtered Used",
          filter: "agTextColumnFilter",
          filterParams: {
            suppressAndOrCondition: true,
          },
          headerValueGetter: function () {
            return translate("filters_used");
          },
          valueGetter: ReportSvc.usedFilterFormat(currentUser),
        },
        {
          field: "status",
          headerName: "Status",
          filter: "agTextColumnFilter",
          filterParams: {
            suppressAndOrCondition: true,
          },
          headerValueGetter: function () {
            return translate("status");
          },
        },
        {
          field: "Actions",
          cellRenderer: (params: any) => {
            return <Actions data={params?.data} callback={callback} />;
          },
          sortable: false,
          resizable: false,
          maxWidth: 200,
          cellRendererParams: {
            viewLink: "/ap/po_co_approvals/",
          },
          cellRendererSelector: function (params: { data: { id: number } }) {
            return params.data && !params.data.id;
          },
          headerValueGetter: function () {
            return translate("actions");
          },
        },
      ];
  }
};

export const getInvoiceDataExtractDownloadModalHeaders = (currentUser: any) => {
  return [
    {
      field: "number",
      headerName: "ID",
      filter: "agTextColumnFilter",
      minWidth: 200,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return "ID";
      },
    },
    {
      field: "vendor_name",
      headerName: "Vendor Name",
      filter: "agTextColumnFilter",
      minWidth: 220,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return "Vendor Name";
      },
    },
    {
      field: "date",
      headerName: "Date",
      filter: "agTextColumnFilter",
      minWidth: 140,
      maxWidth: 140,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return "Date";
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      maxWidth: 140,
      minWidth: 140,
      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return "Amount";
      },
      comparator: function (valueA: string, valueB: string) {
        const a = valueA ? normalizeAmountValue(valueA) : -Infinity;
        const b = valueB ? normalizeAmountValue(valueB) : -Infinity;

        // compare
        if (a < b) return -1;
        if (a > b) return 1;
        if (a === b) return 0;
      },
    },
    {
      field: "currency_code",
      headerName: "Currency Code",
      filter: "agTextColumnFilter",
      minWidth: 110,
      maxWidth: 110,
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return "Currency Code";
      },
    },
    {
      field: "gl_account",
      headerName: "GL Account",
      minWidth: 300,
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return "GL Account";
      },
    },
  ];
};
