import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import React, { useEffect, useState } from "react";
import FormSubsidiary from "./form";
import _ from "lodash";
import useIsMounted from "components/common/hooks/useIsMounted";
import SubsidiaryApis from "services/admin/subsidiaries/subsidiaryApis";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { SubsidiaryDetailType } from "services/admin/subsidiaries/types";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { manipulateLinks, subsidiaryV2Links } from "services/admin/subsidiaries/subsidiarySvc";

const AddSubsidiary = () => {
  const isMounted = useIsMounted();
  const [initialFormValues, setInitialFormValues] = useState<SubsidiaryDetailType>();
  const [removedFields, setRemovedFields] = useState<any>([]);
  const history = useHistory();
  const { t } = useTranslation();

  const getNewSubsidiary = async () => {
    try {
      const response = await SubsidiaryApis.getNewSubsidiary();
      if (!response.currency_code) {
        response.currency_code = response?.currency?.iso_code;
      }
      setInitialFormValues(response);
    } catch (error) {
      CreateNotification("Error", "Add Service Error", NotificationType.danger);
      console.error(error);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      getNewSubsidiary();
    }
  }, []);

  const manipulateSubsidiaryLinksV2Data = (formValue: SubsidiaryDetailType) => {
    const { subsidiary_business_unit_attributes, subsidiary_project_attributes, subsidiary_metadata_attributes } =
      formValue;

    const businessUnitLinks = subsidiaryV2Links(subsidiary_business_unit_attributes, "BusinessUnit");
    const projectLinks = subsidiaryV2Links(subsidiary_project_attributes, "Project");
    const metadataLinks = subsidiaryV2Links(subsidiary_metadata_attributes, "MetadataField");

    // Combine the results from both business units and projects
    return [...businessUnitLinks, ...projectLinks, ...metadataLinks];
  };

  const submitSubsidiary = async (formValue: SubsidiaryDetailType) => {
    let subsidiary_links_v2 = manipulateSubsidiaryLinksV2Data(formValue);

    let subsidiary = {
      name: formValue.name,
      status: formValue.status,
      external_id: formValue.external_id,
      country: formValue.country,
      currency_code: formValue.currency_code,
      sub_type: formValue.sub_type,
      subsidiary_links_attributes: manipulateLinks(formValue.subsidiary_links_attributes),
      subsidiary_manager_location_links_attributes: manipulateLinks(formValue.subsidiary_location_links_attributes),
      subsidiary_links_attributes_v2: subsidiary_links_v2,
    };
    const payload: any = {
      subsidiary: subsidiary,
    };
    try {
      await SubsidiaryApis.addSubsidiary(payload);
      history.push("/ap/subsidiaries");
      CreateNotification(t("success"), t("subsidiary.saved"), NotificationType.success);
    } catch (error: any) {
      const { response } = error;
      if (response?.status === 422) {
        if (_.isPlainObject(response.data) && response.data["name"]) {
          CreateNotification(
            "Name Conflict",
            subsidiary.name + " " + response.data["name"][0],
            NotificationType.warning,
          );
        } else {
          CreateNotification("Validation Error", "Please enter valid data", NotificationType.danger);
        }
      }
    }
  };

  return (
    <>
      {initialFormValues && (
        <ErrorBoundary>
          <FormSubsidiary isAddForm={true} initialValues={initialFormValues} onSubmit={submitSubsidiary} />
        </ErrorBoundary>
      )}
    </>
  );
};

export default AddSubsidiary;
