import React from "react";
import { NavLink } from "react-router-dom";
import { Col, Nav, Row } from "react-bootstrap";
import { Can } from "../../../services/authorization/authorization";
import settingsStyles from "./settings.module.css";

const SettingsTabs = ({ breadcrumb }) => {
  return (
    <Col xs={"12"} className={"pl-0 " + settingsStyles.settingHeaderSection}>
      <Row className="m-0">
        <Col xs={"2"}>
          <Row className={settingsStyles.settingName}>{breadcrumb}</Row>
        </Col>

        <Col xs={"10"}>
          <Row className="m-0 d-flex justify-content-end">
            <Can I={"readcompany"} a={"Profile"}>
              <NavLink
                exact
                to={"/ap/company"}
                activeClassName={settingsStyles.active}
                data-toggle="tooltip"
                title="Company Profile"
                className={"routecontainer " + settingsStyles.nav + " " + settingsStyles.tab}
              >
                <Nav.Item>
                  {" "}
                  <i className="icon icon-company mr-1" /> Company Profile
                </Nav.Item>
              </NavLink>
            </Can>

            <Can I={"edit"} a={"Settings"}>
              <NavLink
                exact
                to={"/ap/settings/company_default"}
                activeClassName={settingsStyles.active}
                data-toggle="tooltip"
                title="Global Default"
                className={"routecontainer " + settingsStyles.nav + " " + settingsStyles.tab}
              >
                <Nav.Item>
                  {" "}
                  <i className="icon icon-global-settings mr-1" /> Company Default
                </Nav.Item>
              </NavLink>
            </Can>

            <Can I={"edit"} a={"Settings"}>
              <NavLink
                exact
                to={"/ap/settings/integrations"}
                activeClassName={settingsStyles.active}
                data-toggle="tooltip"
                title="Global Default"
                className={"routecontainer " + settingsStyles.nav + " " + settingsStyles.tab}
              >
                <Nav.Item>
                  <i className="icon icon-integration mr-1" />
                  Integrations
                </Nav.Item>
              </NavLink>
            </Can>

            <Can I={"edit"} a={"Settings"}>
              <NavLink
                exact
                to={"/ap/settings/payment_methods"}
                activeClassName={settingsStyles.active}
                data-toggle="tooltip"
                title="Company Payment Methods"
                className={"routecontainer " + settingsStyles.nav + " " + settingsStyles.tab}
              >
                <Nav.Item>
                  <i className="icon icon-bank mr-1" />
                  Payment Methods
                </Nav.Item>
              </NavLink>
            </Can>

            <Can I={"edit"} a={"Settings"}>
              <NavLink
                exact
                to={"/ap/settings/accounts"}
                activeClassName={settingsStyles.active}
                data-toggle="tooltip"
                title="Company Accounts"
                className={"routecontainer " + settingsStyles.nav + " " + settingsStyles.tab}
              >
                <Nav.Item>
                  <i className="icon icon-gl-black mr-1" />
                  Accounts
                </Nav.Item>
              </NavLink>
            </Can>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default SettingsTabs;
