import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Panel from "../../../../components/panel/panel";
import CollapsePanel from "../../../../components/panel/collapsePanel";
import { BreadcrumbTrail } from "../../../../components/navigation/navigationLinks";
import PageTitle from "../../../../components/page/pageTitle";
import InvoicePaymentLinks from "../../../../components/widgets/linkedInvoices/linkedInvoices";
import LinkedAccounts from "../../../../components/widgets/linkedAccounts/linkedAccounts";
import { Link } from "react-router-dom";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import RestApi from "../../../../providers/restApi";
import { withTranslation } from "react-i18next";
import LinkedPurchaseOrders from "../../../../components/widgets/linkedPurchaseOrders/purchaseOrderLinks";
import Loader from "../../../../components/spinners/loader";

const PrePaymentDetail = (props) => {
  const [payment, setPayment] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getDetailPayment();
  }, []);

  const restApiService = new RestApi();

  const getDetailPayment = () => {
    let paymentEndpoint = `payments/${props.match.params.id}`;
    restApiService
      .get(paymentEndpoint)
      .then((result) => {
        if (result && result.data) {
          let paymentInvoiceLinksEndpoint = `payments/${props.match.params.id}/invoice_links`;
          let payment = result.data;
          restApiService
            .get(paymentInvoiceLinksEndpoint)
            .then((result) => {
              if (result && result.data) {
                payment.invoice_payment_links = result.data.invoice_payment_links;
              }
            })
            .catch((error) => {
              setLoaded(true);
              CreateNotification(props.t("error"), props.t("errors.genericSupport"), NotificationType.warning);
            })
            .finally(() => {
              setPayment(payment);
              setLoaded(true);
            });
        }
      })
      .catch((error) => {
        setLoaded(true);
        CreateNotification(props.t("error"), props.t("errors.genericSupport"), NotificationType.warning);
      });
  };
  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <BreadcrumbTrail
        to={"/ap/payments/pre_payments"}
        pageName={props.t("breadcrumbs.payments")}
        title={props.t("breadcrumbs.paymentDetails")}
      />
      <Loader loaded={loaded}>
        <Row className="mt-3 border-bottom">
          <Col>
            <PageTitle title={payment && payment.number} status={payment.status} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Panel>
              <Row>
                <Col sm className="pr-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.customer")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      <Link
                        className="primaryLinkColor"
                        variant="primary"
                        to={"/ar/purchasers/" + (payment.purchaser && payment.purchaser.id)}
                      >
                        {payment.purchaser && payment.purchaser.name}
                      </Link>
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.paymentDate")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.payment_date}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.scheduled")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.is_scheduled}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.referenceNumber")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.reference_number}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.externalId")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.external_id}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.paymentType")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.payment_type}</dd>
                  </dl>
                </Col>
                <Col sm className="pr-sm-0 pl-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.amount")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.amount}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.balance")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.balance}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.currencyCode")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.currency_code}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.vendor")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.vendor && payment.vendor.name}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.subsidiary")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.subsidiary && payment.subsidiary.name}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.submitter")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.submitter && payment.submitter.name}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.department")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.department && payment.department.name}</dd>

                    {payment.description && (
                      <>
                        <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.description")}</dt>
                        <dd className="col-6 col-sm-7 detailValue">{payment.description}</dd>
                      </>
                    )}

                    {payment.memo && (
                      <>
                        <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.memo")}</dt>
                        <dd className="col-6 col-sm-7 detailValue">{payment.memo}</dd>
                      </>
                    )}
                  </dl>
                </Col>
                <Col sm className="pl-sm-0">
                  <dl className="row mb-0">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.remitAcct")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {payment.payment_method && payment.payment_method.account_number}
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.remitBank")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {payment.payment_method && payment.payment_method.bank_name}
                    </dd>

                    {payment.virtual_card && payment.virtual_card.id && (
                      <Fragment>
                        <dt className="col-6 col-sm-5 detailKey text-sm-right">Virtual Card</dt>
                        <dd className="col-6 col-sm-7 detailValue">
                          <Link
                            className="primaryLinkColor"
                            variant="primary"
                            to={"/ar/virtual_cards/" + payment.virtual_card.id}
                          >
                            {payment.virtual_card.card_number_masked}
                          </Link>
                        </dd>
                      </Fragment>
                    )}

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.submitType")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {payment.payment_method && payment.payment_method.payment_type}
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.integrationId")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.integration_id}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.batchId")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.batch_id}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.payTransId")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.payment_transaction_id}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.payTransDate")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.transaction_date}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.authCode")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.authorization_code}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{props.t("details.responseDesc")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{payment.response_desc}</dd>
                  </dl>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>

        <Row>
          <Col>
            <CollapsePanel
              open={true}
              heading={props.t("details.linkedAccounts").toUpperCase()}
              cardBodyStyle={{ padding: "0px" }}
            >
              <LinkedAccounts debit_entries={payment.debit_entries} credit_entries={payment.credit_entries} />
            </CollapsePanel>
          </Col>
        </Row>
        <Row>
          <Col>
            <CollapsePanel
              open={true}
              heading={props.t("details.linkedInvoices").toUpperCase()}
              cardBodyStyle={{ padding: "0px" }}
            >
              <InvoicePaymentLinks invoicePaymentLinks={payment?.invoice_payment_links} />
            </CollapsePanel>
          </Col>
        </Row>
        <Row>
          <Col>
            <CollapsePanel
              open={true}
              heading={props.t("details.linkedPurchaseOrders").toUpperCase()}
              cardBodyStyle={{ padding: "0px" }}
            >
              <LinkedPurchaseOrders purchase_orders={payment.prepaid_purchase_orders} />
            </CollapsePanel>
          </Col>
        </Row>
      </Loader>
    </Container>
  );
};

export default withTranslation()(PrePaymentDetail);
