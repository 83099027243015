import React, { useCallback, useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_INTEGRATION,
  EDIT_INTEGRATION,
  getCompany,
  getIntegrationSettings,
  setIntegrationFormMode,
} from "../../../../reducers/admin/integrationsReducer";

import styles from "./integrations.module.css";
import { Can } from "../../../../services/authorization/authorization";
import AddIntegration from "./addIntegration";
import EditIntegration from "./editIntegration";
import { useState } from "react";
import Panel from "../../../../components/panel/panel";
import ObjectStatus from "../../../../components/common/objects/newStatusObject";
import _ from "lodash";
import RestApi from "../../../../providers/restApi";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import { useTranslation } from "react-i18next";
import { addUserDetails } from "../../../../reducers/userReducers";
import SettingsTabs from "../settingsTabs";
import settingsStyles from "./../settings.module.css";
import { Link } from "react-router-dom";
const restApiService = new RestApi();

const Integrations = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const integrationSettings = useSelector((state) => state.integrationsRed.integrationSettings);
  const integrationFormMode = useSelector((state) => state.integrationsRed.integrationFormMode);
  const [integrationId, setIntegrationId] = useState("");

  useEffect(() => {
    dispatch(getCompany());
    dispatch(getIntegrationSettings());
  }, []);

  const getCustomerFacingSystemName = function (name) {
    switch (name) {
      case "Stripe":
        return "VISA_S";
      case "Quickbook":
        return "QuickBooks";
      case "Quickbooks Enterprise":
        return "QuickBooks Enterprise";
      default:
        return name;
    }
  };

  const addIntegration = () => {
    dispatch(setIntegrationFormMode(ADD_INTEGRATION)); //for laoding addIntegration component
  };

  const editIntegration = (integrationId) => {
    setIntegrationId(integrationId);
    dispatch(setIntegrationFormMode(EDIT_INTEGRATION));
  };

  const refetchIntegrationSettings = () => {
    dispatch(getIntegrationSettings());
  };

  const updateCurrentUser = async () => {
    try {
      const response = await restApiService.get("user");
      if (response.data) {
        dispatch(addUserDetails(response.data));
      }
    } catch (error) {
      CreateNotification(t("error"), error.message, NotificationType.danger);
    }
  };

  const onEditSaveCallBack = useCallback(() => {
    refetchIntegrationSettings();
    updateCurrentUser();
  }, []);

  return (
    <>
      <Container fluid={true}>
        <Row className={settingsStyles.navBarMargin}>
          <SettingsTabs
            breadcrumb={
              <span className={settingsStyles.landingHeader}>
                <Link to={"/ap/settings"}> Settings</Link>
              </span>
            }
          />
        </Row>
        <Row className="row">
          {/* for adding integration */}
          {integrationFormMode === ADD_INTEGRATION && <AddIntegration onSaveCallBack={onEditSaveCallBack} />}

          {/* for updating/editing integration */}
          {integrationFormMode === EDIT_INTEGRATION && (
            <EditIntegration integrationId={integrationId} onEditCallBack={onEditSaveCallBack} />
          )}

          <Col lg="12" className="mt-3">
            <Panel>
              <Card.Header
                className={
                  styles.cardHeader + " collapseHeader d-flex justify-content-center align-items-center pt-0 pb-2"
                }
              >
                <p className={"mb-0 align-self-center mr-auto fw-800"}>
                  <i className="icon icon-integration-red"></i> INTEGRATIONS
                </p>
                <Can I="edit" a="Settings">
                  <Button
                    variant="secondary"
                    className="d-flex justify-content-center align-items-center font-weight-bold"
                    onClick={() => addIntegration()}
                  >
                    {" "}
                    <i className="icon icon-plus-black"></i> Integrations
                  </Button>
                </Can>
              </Card.Header>
              <div className={styles.tableWrapper}>
                <table className={styles.table + " " + styles.tableStriped + " text-left"}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>SYSTEM</th>
                      <th>TYPE</th>
                      <th>EMAIL</th>
                      <th>DESCRIPTION</th>
                      <th>STATUS</th>
                      <Can I="edit" a="Settings">
                        <th>EDIT</th>
                      </Can>
                    </tr>
                  </thead>

                  <Can I="edit" a="Settings">
                    <tbody>
                      {/* TODO: improvement replace inline png with svg from app_icons.css */}
                      {_.isArray(integrationSettings) &&
                        _.orderBy(integrationSettings, ["id"], ["desc"]).map((attribute) => (
                          <tr key={attribute.id}>
                            <td>{attribute.id}</td>
                            <td className="">
                              {(attribute.system_name === "Accrualify Integration" ||
                                attribute.system_name === "Accrualify Internal Service") && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-accrualify.png")}
                                  alt="accrualify"
                                />
                              )}
                              {attribute.system_name === "Netsuite" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-netsuite.png")}
                                  alt="netsuite"
                                />
                              )}
                              {attribute.system_name === "LOB Check" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-lob.png")}
                                  alt="lob.com"
                                />
                              )}
                              {attribute.system_name === "Transferwise" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-transferwise.png")}
                                  alt="transferwise.com"
                                />
                              )}
                              {attribute.system_name === "Xero" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-xero.png")}
                                  alt="xero.com"
                                />
                              )}
                              {(attribute.system_name === "SVB" ||
                                attribute.system_name === "SVB ACH" ||
                                attribute.system_name === "SVB Master Card") && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-svb.png")}
                                  alt="svb.com"
                                />
                              )}{" "}
                              {attribute.system_name === "Forte" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-forte.png")}
                                  alt="forte.com"
                                />
                              )}
                              {attribute.system_name === "Intacct" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/sageintacct.png")}
                                  alt="sageintacct.com"
                                />
                              )}
                              {attribute.system_name === "VISA" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-visa.png")}
                                  alt="visa.com"
                                />
                              )}
                              {attribute.system_name === "Expensify" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-expensify.png")}
                                  alt="expensify.com"
                                />
                              )}
                              {attribute.system_name === "Payoneer" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-payoneer.png")}
                                  alt="payoneer.com"
                                />
                              )}
                              {attribute.system_name === "US Bank" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-usbank.png")}
                                  alt="usbank.com"
                                />
                              )}
                              {(attribute.system_name === "Quickbooks" ||
                                attribute.system_name === "Quickbook" ||
                                attribute.system_name === "Quickbooks Enterprise") && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-quickbook.png")}
                                  alt="Quickbooks.com"
                                />
                              )}
                              {attribute.system_name === "Punchout" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/amazon-punchout.png")}
                                  alt="Punchout"
                                />
                              )}
                              {attribute.system_name === "Cambridge" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/cambridge.png")}
                                  alt="Cambridge.com"
                                />
                              )}
                              {attribute.system_name === "Stripe" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/spectrum.png")}
                                  alt="Spectrum"
                                />
                              )}
                              {attribute.system_name === "Corpay" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/corpay.png")}
                                  alt="Corpay"
                                />
                              )}
                              {(attribute.system_name === "SAP Cloud" ||
                                attribute.system_name === "SAP On Premise") && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/SAP.png")}
                                  alt="SAP"
                                />
                              )}
                              {attribute.system_name === "PostGrid Check" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-postGrid.png")}
                                  alt="PostGrid"
                                />
                              )}
                              {attribute.system_name === "Sage300C" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-sage300C.png")}
                                  alt="Sage300C"
                                />
                              )}
                              {attribute.system_name === "Deltek Vantagepoint" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/integration-deltekVantagepoint.png")}
                                  alt="Deltek Vantagepoint"
                                />
                              )}
                              {attribute.system_name === "Acumatica" && (
                                <img
                                  className={`${styles.imgIntegration}`}
                                  src={require("./../../../../assets/images/acumaticaLogo.png")}
                                  alt="Acumatica"
                                />
                              )}
                              {getCustomerFacingSystemName(attribute.system_name)}
                            </td>
                            <td>{attribute.application_type}</td>
                            <td>{attribute.email}</td>
                            <td>{attribute.description}</td>
                            <td>
                              <ObjectStatus status={attribute.status} />
                            </td>
                            <Can I="edit" a="Settings">
                              <td>
                                <i
                                  style={{ cursor: "pointer" }}
                                  onClick={() => editIntegration(attribute.id)}
                                  className="ml-2 icon icon-edit"
                                />
                              </td>
                            </Can>
                          </tr>
                        ))}
                      {_.isArray(integrationSettings) && integrationSettings.length < 1 && (
                        <tr>
                          <td colSpan="6">There are no system integrations.</td>
                        </tr>
                      )}
                    </tbody>
                  </Can>
                </table>
              </div>
            </Panel>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default Integrations;
