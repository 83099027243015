import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../pages/pages.style.css";
import { useTranslation } from "react-i18next";
const InvoiceSubmit = (props) => {
  const { t } = useTranslation();
  return (
    <button type="button" className="btn btn-primary">
      <Link
        className={"m-auto rounded-0"}
        variant="primary"
        to="/ar/submit_invoice/ "
        data-toggle="tooltip"
        title="supports"
      >
        <Nav.Item>{t("breadcrumbs.invoiceSubmit")}</Nav.Item>
      </Link>
    </button>
  );
};

export default InvoiceSubmit;
