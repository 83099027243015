import React from "react";
import ContactsOptionsFilter from "../reportFilters/contactsDropdown";
import DateFilter from "../reportFilters/date";
import InputFilter from "../reportFilters/input";
import LocationsOptionsFilter from "../reportFilters/locationsDropdown";

const ReceiptImage = ({}) => {
  return (
    <div>
      <DateFilter name={"Start Date"} code={"between_created_at.start_date"} options={[]} isRequired />
      <DateFilter name={"End Date"} code={"between_created_at.end_date"} options={[]} isRequired />
      <ContactsOptionsFilter name={"Employee Name"} code={"employee_id"} options={[]} />
      <InputFilter name={"GL Account"} code={"gl_account"} options={[]} />
      <LocationsOptionsFilter name={"Location"} code={"location_id"} options={[]} />
      <InputFilter name={"Company"} code={"company"} options={[]} />
      <InputFilter name={"GL Account"} code={"gl_account"} options={[]} />
      <InputFilter name={"Job"} code={"job"} options={[]} />
      <InputFilter name={"Phase"} code={"phase"} options={[]} />
      <InputFilter name={"Cost Type"} code={"cost_type"} options={[]} />
    </div>
  );
};

export default ReceiptImage;
