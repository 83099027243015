import { createContext } from "react";
import { useContext } from "react";

export type ExpenseItemFormContextType = {
  formName: string;
  sectionPrefix: string;
  index: number;
  expenseReport?: any; // TODO: fix expensereport type
  params?: any;
};

export const ExpenseItemFormContext = createContext<ExpenseItemFormContextType | undefined>(undefined);

export const useExpenseItemFormContext = () => {
  const context = useContext(ExpenseItemFormContext);
  return context;
};
