import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { PoChangeOrderType } from "services/admin/changeOrders/poChangeOrderApprovalsType";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import { PurchaseOrderType } from "services/admin/purchaseOrders/purchaseOrderType";
import { Can } from "services/authorization/authorization";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ObjectStatus from "components/objects/objectStatus";
import useIsMounted from "components/common/hooks/useIsMounted";
import styles from "./../purchaseOrders.module.css";
import Breadcrumb from "components/common/navigations/breadcrumb";
import TabNavigator from "components/common/tabNavigator/tabNavigator";
import PurchaseOrdersApis from "services/admin/purchaseOrders/purchaseOrderApi";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import GeneralInfo from "./generalInfo";
import PoLineItemsTable from "components/admin/objectChanges/poLineItemsTable";
import ExpenseLineTable from "components/admin/objectChanges/expenseLineTable";
import ChangeOrderApis from "services/admin/changeOrders/changeOrderApis";
import Panel from "components/common/panel/panel";
import AttachmentSection from "components/common/attachmentSection/attachmentSection";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import { CreateNotification, NotificationType } from "services/general/notifications";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import ActivityLog from "components/admin/activityLog/activityLog";
import GLImpactTable from "components/admin/objectChanges/glImpactTable ";
import Approval from "components/approval/approval";
import usePermission from "components/common/hooks/usePermission";
import { AssetType } from "services/common/types/common.type";

const DetailChangeOrder = () => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const { id: poChangeOrderId } = useParams<{ id: string }>();
  const { createConfirmModal } = useConfirmModal();
  const [currentTab, setCurrentTab] = useState("coInfo");
  const [poChangeOrderDetail, setPoChangeOrderDetail] = useState<PoChangeOrderType.DetailType>();
  const [assets, setAssets] = useState<AssetType[] | undefined>();
  const [purchaseOrderDetail, setPurchaseOrderDetail] = useState<PurchaseOrderType.DetailType>();
  const { hasUserPermission } = usePermission();
  const history = useHistory();

  const getPoChangeOrderDetail = useCallback(async () => {
    try {
      const result = await ChangeOrderApis.getChangeOrderDetail<PoChangeOrderType.DetailType>(poChangeOrderId);
      if (isMounted.current) {
        setPoChangeOrderDetail(result);
        setAssets(result?.assets_attributes);
      }
    } catch (error) {}
  }, [isMounted, poChangeOrderId]);

  const getChangeRequestablePODetail = useCallback(async () => {
    try {
      if (poChangeOrderDetail?.change_requestable_id) {
        const result = await PurchaseOrdersApis.getPurchaseOrder(poChangeOrderDetail.change_requestable_id);
        if (isMounted.current) {
          setPurchaseOrderDetail(result);
        }
      }
    } catch (error) {}
  }, [isMounted, poChangeOrderDetail?.change_requestable_id]);

  const deleteAttachementConfirm = async (assetId: number | string) => {
    const patchData = { assets_attributes: { id: assetId, _destroy: 1 } };
    const response = await restApiService.patch(`change_requests/${poChangeOrderDetail?.id}`, null, patchData);
    getPoChangeOrderDetail();
    CreateNotification("Deleted Attachment", "Deleted Attachment", NotificationType.info);
  };

  const onUploadAttachment = async (files: File) => {
    try {
      const formData = new FormData();
      if (_.isArray(files)) {
        files.forEach((file, index) => {
          formData.append(`change_request[assets_attributes][${index}][asset_file]`, file);
          formData.append(`change_request[assets_attributes][${index}][file_name]`, file.name);
        });
      }
      const res = await restApiService.patch(`change_requests/${poChangeOrderDetail?.id}`, null, formData);
      if (res.data) {
        setAssets(res.data.assets_attributes);
        CreateNotification(t("Attached"), t("Attachment Updated"), NotificationType.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteAttachment = async (assetId: string | number) => {
    createConfirmModal({
      title: "Confirm",
      body: `Do you really want delete Attachment with ${assetId}`,
      callBackData: assetId,
      saveCallBack: deleteAttachementConfirm,
      cancelCallBack: null,
    });
  };

  const directToCoList = () => {
    history.push("/ap/change_orders");
  };

  // change order status is open or rejected, redirect user to po_co list page
  // as for this status we can not show proper data
  const checkRedirectalble = () => {
    return poChangeOrderDetail?.status && ["OPEN", "REJECTED"].includes(poChangeOrderDetail.status);
  };

  useEffect(() => {
    getPoChangeOrderDetail();
  }, [getPoChangeOrderDetail]);

  useEffect(() => {
    if (checkRedirectalble()) {
      directToCoList();
    }
    getChangeRequestablePODetail();
  }, [getChangeRequestablePODetail]);

  return (
    <Container fluid>
      <Row className="mt-2">
        <Col>
          <Breadcrumb
            breadcrumbItems={[
              { name: "Purchase Orders", href: "purchase_orders" },
              {
                name: `${purchaseOrderDetail?.number ?? "Purchase Order Detail"}`,
                href: `purchase_orders/${poChangeOrderDetail?.change_requestable_id}`,
              },
            ]}
            trailLabel="Change Order Details"
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex">
          <h2>{poChangeOrderDetail?.number}</h2>
          <ObjectStatus status={poChangeOrderDetail?.status} statusClass={styles.coStatus} />
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          {/* 
          expose when asked
          <Button variant="secondary" className="mr-2">
            Message
          </Button>
          <Button variant="secondary" className="mr-2">
            Download
          </Button> */}
          <Can I="add" a="PurchaseOrders">
            <Link
              to={{
                pathname: `/ap/change_orders/${poChangeOrderDetail?.id}/edit`,
                state: { isFromDetailPage: true },
              }}
            >
              <Button>
                <Nav.Item>
                  <i className="icon icon-edit-white"></i>
                  Edit CO
                </Nav.Item>
              </Button>
            </Link>
          </Can>
        </Col>
      </Row>

      <hr className={"full-border " + styles.fullBorder} />

      <Row>
        <Col>
          <TabNavigator
            tabData={[
              { activeTab: "coInfo", label: "CO Info" },
              { activeTab: "lineItems", label: "Line Items" },
              { activeTab: "expenseLines", label: "Line Expenses" },
              { activeTab: "glImpact", label: "GL Impact" },
            ]}
            activeTab={currentTab}
            handleTabChange={(tab: string) => setCurrentTab(tab)}
          />
        </Col>
      </Row>

      {currentTab === "coInfo" && poChangeOrderDetail && <GeneralInfo poChangeOrderDetail={poChangeOrderDetail} />}

      {currentTab === "lineItems" && (
        <Panel header={<>PO Line Items</>}>
          <ErrorBoundary>
            <Row>
              <Col xs="12">
                {poChangeOrderDetail?.object_changes?.po_items_attributes && purchaseOrderDetail?.po_items && (
                  <PoLineItemsTable
                    poChangeOrderItems={poChangeOrderDetail?.object_changes?.po_items_attributes}
                    poItems={purchaseOrderDetail?.po_items}
                  />
                )}
              </Col>
            </Row>
          </ErrorBoundary>
        </Panel>
      )}

      {currentTab === "expenseLines" && (
        <Panel header={<>Expenses</>}>
          <ErrorBoundary>
            <Row>
              <Col xs="12">
                {purchaseOrderDetail?.invoice_debit_accounts &&
                  poChangeOrderDetail?.object_changes?.invoice_debit_accounts_attributes && (
                    <ExpenseLineTable
                      invoiceDebitAccounts={purchaseOrderDetail?.invoice_debit_accounts}
                      changeOrderInvoiceDebitAccounts={
                        poChangeOrderDetail?.object_changes?.invoice_debit_accounts_attributes
                      }
                    />
                  )}
              </Col>
            </Row>
          </ErrorBoundary>
        </Panel>
      )}

      {currentTab === "glImpact" && (
        <Panel header={<>GL IMPACT</>}>
          <ErrorBoundary>
            <Row>
              <Col xs="12">
                {purchaseOrderDetail?.invoice_debit_accounts &&
                  poChangeOrderDetail?.object_changes?.invoice_debit_accounts_attributes &&
                  poChangeOrderDetail.object_changes?.po_items_attributes &&
                  purchaseOrderDetail.po_items && (
                    <GLImpactTable
                      invoiceDebitAccounts={purchaseOrderDetail?.invoice_debit_accounts}
                      changeOrderInvoiceDebitAccounts={
                        poChangeOrderDetail?.object_changes?.invoice_debit_accounts_attributes
                      }
                      poChangeOrderItems={poChangeOrderDetail.object_changes?.po_items_attributes}
                      poItems={purchaseOrderDetail.po_items}
                    />
                  )}
              </Col>
            </Row>
          </ErrorBoundary>
        </Panel>
      )}

      <ErrorBoundary>
        <Row>
          <Col>
            {poChangeOrderDetail?.approvals && poChangeOrderDetail?.id && (
              <Approval
                endpoint="change_requests"
                modalData={poChangeOrderDetail}
                modelName="purchase_order"
                approvableType="ChangeRequest"
                allowAccess={
                  hasUserPermission("approvalWorkflowExpenses") || hasUserPermission("resetworkflowExpenseReports")
                }
                refreshApprovable={() => getPoChangeOrderDetail()}
              />
            )}
          </Col>
        </Row>
      </ErrorBoundary>

      <ErrorBoundary>
        <Row>
          <Col>
            <AttachmentSection
              allowAdd
              allowDelete
              collapsible
              title={
                <>
                  <i className="icon m-0 document" />
                  {t("documents").toUpperCase()}
                </>
              }
              attachments={assets}
              onAttachmentDelete={onDeleteAttachment}
              onAttachmentUpload={onUploadAttachment}
            />
          </Col>
        </Row>
      </ErrorBoundary>

      <ErrorBoundary>
        <ActivityLog modelMethod="change_requests" modelActionId={poChangeOrderId} />
      </ErrorBoundary>
    </Container>
  );
};

export default DetailChangeOrder;
