import { AxiosResponse } from "axios";
import useGetValue from "components/admin/hooks/hookFormHooks/useGetValue";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { Mandatory } from "components/forms/bootstrapFields";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { components, GroupBase, OnChangeValue, OptionsOrGroups } from "react-select";
import AsyncSelect from "react-select/async";
import { IDType } from "services/common/types/common.type";
import { AbstractListPickerTypes } from "./abstractListPickerTypes";
import { compare } from "./index2";
import useAbstractPickerLogic from "./useAbstractPickerLogic";

const MenuList = (props: any) => {
  const { externalOptionComponent } = props;
  return (
    <>
      <components.MenuList {...props}>
        {externalOptionComponent}
        {props.children}
      </components.MenuList>
    </>
  );
};

const AsyncSingleListPicker = <T extends { id: IDType; name?: string }>({
  name,
  fetchUrl,
  objectPropertyKey,
  valuePropertyName = "id",
  labelPropertyName = "name",
  label,
  labelClassName,
  sortCompareFn = compare,
  required = false,
  disabled = false,
  placeholder = "",
  containerClassName = "",
  getResponseData,
  filterRecords,
  cache,
  formatOptionLabel,
  callBack,
  menuPosition = "fixed",
  menuPlacement = "bottom",
  validate,
  instanceId,
  params,
  searchParamName,
  externalOptionComponent,
}: AbstractListPickerTypes.IAsyncSingleListPickerProps<T>) => {
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext();
  const [selected, setSelected] = useState<AbstractListPickerTypes.TPickerValue<T>>();
  const currentId: IDType = useGetValue(name);
  const currentObjectValue: AbstractListPickerTypes.TPickerValue<T> = useGetValue(objectPropertyKey);
  const abortControllerRef = useRef<AbortController>();

  const [loading, setLoading] = useState<boolean>(false);
  const { getFormatOptionLabel, getOptionLabel, getOptionValue } = useAbstractPickerLogic({
    formatOptionLabel,
    valuePropertyName,
    labelPropertyName,
    currentId,
    selected,
    // currentObjectValueId,
  });

  // this function will act as single source of update for picker value
  const setPickerValue = useCallback(
    (selected: AbstractListPickerTypes.TPickerValue<T>) => {
      setSelected(selected);
      setValue(name, selected ? selected.id : null);
      if (objectPropertyKey) setValue(objectPropertyKey, selected);
      if (callBack) {
        callBack(selected);
      }
    },
    [callBack, name, objectPropertyKey, setValue],
  );

  const handleSelectChange = useCallback(
    (selected: OnChangeValue<T, false>) => {
      setPickerValue(selected);
    },
    [setPickerValue],
  );

  const loadOptions = useCallback(
    async (inputValue: string) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
      }
      let optionsData: T[] = [];
      try {
        setLoading(true);
        const signal = abortControllerRef.current?.signal;
        const apiParams = { ...params, [searchParamName]: inputValue };
        const response: AxiosResponse<T[]> = await restApiService.get(
          fetchUrl,
          apiParams,
          null,
          true,
          null,
          cache,
          signal,
        );
        let data = getResponseData ? getResponseData(response) : response.data;
        optionsData = Array.isArray(data) ? data : [];
      } catch (error) {
      } finally {
        setLoading(false);
      }

      if (Array.isArray(optionsData)) {
        optionsData = optionsData.sort(sortCompareFn);
      }

      if (filterRecords) {
        optionsData = filterRecords(optionsData);
      }

      let options: OptionsOrGroups<T, GroupBase<T>> = optionsData;
      return options;
    },
    [cache, fetchUrl, filterRecords, getResponseData, params, searchParamName, sortCompareFn],
  );

  const setDefaultValue = useCallback(async () => {
    // check where object for id is stored and update it use selected
    const currentObjectValue = getValues(objectPropertyKey);
    if (currentObjectValue) {
      setSelected(currentObjectValue);
    }
  }, [getValues, objectPropertyKey]);

  useEffect(() => {
    setDefaultValue();
  }, [currentId, setDefaultValue]);

  return (
    <Form.Group className={`px-mw-200 ` + containerClassName}>
      {label && (
        <Form.Label className={"pickerLabel " + labelClassName}>
          {label} <Mandatory required={required} />
        </Form.Label>
      )}
      <Controller
        name={name}
        rules={{ required: required ? t("validations.required") : undefined, validate }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <>
              <AsyncSelect
                id={instanceId} // The id to set on the SelectContainer component.
                instanceId={instanceId} // Define an id prefix for the select components
                isMulti={false}
                components={{
                  MenuList: (props) =>
                    (<MenuList {...props} externalOptionComponent={externalOptionComponent} />) as any,
                }}
                value={selected}
                isLoading={loading}
                isClearable
                cacheOptions={false}
                loadOptions={loadOptions}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                onChange={(selected) => handleSelectChange(selected)}
                isDisabled={disabled}
                placeholder={placeholder}
                formatOptionLabel={getFormatOptionLabel}
                menuPosition={menuPosition}
                menuPlacement={menuPlacement}
              />
              {error?.message && <PickerErrorBlock error={error.message} />}
            </>
          );
        }}
      />
    </Form.Group>
  );
};

export default AsyncSingleListPicker;
