import ListSchedules from "components/admin/reports/schedules/listSchedules";
import React from "react";
import { DATA_EXTRACT_OPTIONS } from "services/admin/reports/reportSvc";
import { DataExtractModuleTypes } from "services/admin/reports/reportTypes";

const Schedules = (module?: DataExtractModuleTypes) => (
  <ListSchedules
    options={DATA_EXTRACT_OPTIONS}
    classification="DataExtract"
    createLabel="Data Extract"
    scheduleServiceName="DATA_EXTRACT"
    dataExtractModule={module}
  />
);
export default Schedules;
