import {
  ActiveElement,
  CategoryScale,
  Chart as ChartJS,
  ChartEvent,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Title,
} from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
import commonService from "services/common/commonSvc";

type xAxisPlotsType = [string, string][];
type pointTootTipType = (string | [string, string])[];
type pointDataType = number[];

type chartDataType = {
  xAxisPlots: xAxisPlotsType;
  pointTootTip: pointTootTipType;
  pointData: pointDataType;
};

type SpendChartType = {
  chartData: chartDataType;
  pointCallback: (index: number) => void;
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);
const SpendChart = ({ chartData, pointCallback }: SpendChartType) => {
  const drawConnectingLinePlugin = {
    id: "drawConnectingLine",
    beforeDraw: (chart: any) => {
      const {
        ctx,
        chartArea: { top, bottom, left, right },
        scales: { x, y },
      } = chart;
      const data = chart.data.datasets[0].data;
      if (data.length < 2) return;

      const firstPoint = {
        x: x.getPixelForValue(0),
        y: y.getPixelForValue(data[0]),
      };
      const lastPoint = {
        x: x.getPixelForValue(data.length - 1),
        y: y.getPixelForValue(data[data.length - 1]),
      };

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(firstPoint.x, firstPoint.y);
      ctx.lineTo(lastPoint.x, lastPoint.y);
      ctx.strokeStyle = "#00B5E2"; // Set the color of the line
      ctx.lineWidth = 1; // Set the width of the line
      ctx.setLineDash([5, 5]);
      ctx.stroke();
      ctx.restore();
    },
  };

  const data = {
    labels: chartData.xAxisPlots,
    datasets: [
      {
        label: "",
        data: chartData.pointData,
        borderColor: "#0289AA",
        borderWidth: 1,

        backgroundColor: "rgba(2, 137, 170, 0.2)",
        fill: {
          target: "origin",
          above: "rgba(2, 137, 170, 0.2)",
        },
        fillOpacity: 0.3,
      },
    ],
  };

  const options = {
    onClick: function (evt: ChartEvent, element: ActiveElement[]) {
      if (element[0]?.index >= 0) {
        pointCallback(element[0]?.index);
      }
    },

    animation: {
      duration: 0,
    },
    responsiveAnimationDuration: 0,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value: number | string) {
            return commonService.getFormatCurrencyAmount(value as number, 0);
          },
          font: {
            family: "GT-America",
            size: 13,
            weight: "bold" as "bold",
          },
          color: "#656579",
          maxTicksLimit: 6,
        },
      },
      x: {
        offset: chartData.xAxisPlots.length === 1 ? true : false,
        ticks: {
          maxRotation: 0,
          maxTicksLimit: 13,
          font: {
            family: "GT-America",
            size: 12,
            weight: "bold" as "bold",
          },
          color: "#656579",
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend,
      },

      tooltip: {
        padding: 10,
        caretPadding: 15,
        caretSize: 0,
        cornerRadius: 20,
        xAlign: "center" as "center",
        yAlign: "bottom" as "bottom",
        displayColors: false,
        backgroundColor: "#00617C",
        bodyColor: "#FFFFFF",
        bodyFont: {
          family: "GT-America",
          size: 12,
          weight: "bold" as "bold",
        },
        callbacks: {
          label: function ({ dataIndex }: { dataIndex: number }) {
            return chartData.pointTootTip[dataIndex];
          },
          title: function () {
            return "";
          },
          beforeLabel: function () {
            return "";
          },
        },
      },
    },
    tension: 0.5,
    maintainAspectRatio: false, // Disable aspect ratio
    responsive: true, // Make chart responsive
  };

  return (
    <div className="h-100">
      {ChartJS && <Line options={options} data={data} plugins={[drawConnectingLinePlugin]} />}
    </div>
  );
};

export default SpendChart;
