import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { ReportApis } from "../../../../services/admin/reports/reportApis";
import {
  DataExtractModuleTypes,
  ReportClassificationType,
  ReportDetailType,
} from "../../../../services/admin/reports/reportTypes";
import { AssetType } from "../../../../services/common/types/common.type";
import styles from "./dataExtracts.module.css";
import ClientDataGrid from "components/common/dataGrid/clientDataGrid/clientDataGrid";
import { getInvoiceDataExtractDownloadModalHeaders } from "../standardReports/listReportsHeaders";
import { useTypedSelector } from "reducers";

type DownloadModalType = {
  id: number;
  close: () => void;
  classification?: ReportClassificationType | undefined;
  module_type?: DataExtractModuleTypes;
};

const DownloadModal = ({ id, close, classification, module_type }: DownloadModalType) => {
  const [dataExtractDetail, setDataExtractDetail] = useState<ReportDetailType>();
  const downloadLinks = useRef<HTMLAnchorElement[]>([]);
  const modalSize = classification === "DataExtract" && module_type === "Invoice" ? "xl" : "lg";

  const getDataExtractDetail = useCallback(async () => {
    const data = await ReportApis.getDetail(id);
    downloadLinks.current = [];
    if (data) {
      setDataExtractDetail(data);
    }
  }, []);

  const setDownloadRef = useCallback(
    (index: number) => (ref: HTMLAnchorElement) => {
      if (ref) {
        downloadLinks.current[index] = ref;
      }
    },
    [],
  );

  const handleDownloadAll = useCallback(() => {
    downloadLinks.current.forEach((link) => {
      link && link.click();
    });
    close();
  }, []);

  const getReportTypeByClassification = () => {
    switch (classification) {
      case "DataExtract":
      case "FileOutbound":
        return "Data Extracts";
      default:
        return "Report";
    }
  };

  useEffect(() => {
    getDataExtractDetail();
  }, []);

  return (
    <Modal show={true} size={modalSize} centered contentClassName={styles.addModalContent}>
      <Modal.Header>
        <Container fluid>
          <Row>
            <Col className={`font-weight-bold ${styles.downloadModalTitle}`}>
              {dataExtractDetail ? (
                classification === "DataExtract" && module_type === "Invoice" ? (
                  <h2 className="mt-4">Data Extract Download</h2>
                ) : (
                  `${getReportTypeByClassification()} #${dataExtractDetail.id}: ${dataExtractDetail.name}`
                )
              ) : null}
            </Col>
            <Col style={{ flex: 0 }} className={`${styles.addHeaderClose} px-0`}>
              <i className={`icon icon-close ${styles.addHeaderCloseIcon}`} onClick={close}></i>
            </Col>
          </Row>
        </Container>
      </Modal.Header>

      <Modal.Body>
        <Container fluid>
          <Col>
            {dataExtractDetail && dataExtractDetail.assets && dataExtractDetail.assets.length ? (
              <>
                {classification === "DataExtract" && module_type === "Invoice" ? (
                  <div>
                    {dataExtractDetail.assets.map((asset: AssetType, index: number) => {
                      return (
                        <tr key={asset.id}>
                          <td className={styles.downloadExtract}>
                            <a
                              href={asset.asset_expiring_url}
                              target="_blank"
                              download
                              ref={setDownloadRef(index)}
                              rel="noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            ></a>
                          </td>
                        </tr>
                      );
                    })}
                    <InvoiceDataExtractDownloadModalBody dataExtractDetail={dataExtractDetail} />
                  </div>
                ) : (
                  <Row>
                    <Table bordered responsive className={`table-striped ${styles.dataExtractRow}`}>
                      <thead>
                        <tr>
                          <th>File Name</th>
                          <th>Creation Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataExtractDetail.assets.map((asset: AssetType, index: number) => {
                          return (
                            <tr key={asset.id}>
                              <td className={styles.downloadExtract}>
                                <a
                                  href={asset.asset_expiring_url}
                                  target="_blank"
                                  download
                                  ref={setDownloadRef(index)}
                                  rel="noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {asset.asset_file_file_name}
                                </a>
                              </td>
                              <td>{moment(asset.created_at).format("L")}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Row>
                )}
                <Row className="justify-content-end pb-3">
                  <Button type="button" variant="secondary" onClick={close} className="mx-2 px-4">
                    Cancel
                  </Button>
                  <Button type="button" className={`px-5 ${styles.downloadButton}`} onClick={handleDownloadAll}>
                    Download
                  </Button>
                </Row>
              </>
            ) : (
              <>
                <Row className="m-0">No attachments to download</Row>
                <Row className="justify-content-end pb-3">
                  <Button type="button" variant="secondary" className="px-4" onClick={close}>
                    Cancel
                  </Button>
                </Row>
              </>
            )}
          </Col>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

type InvoiceDataExtractDownloadModalBodyProps = {
  dataExtractDetail: ReportDetailType;
};

const InvoiceDataExtractDownloadModalBody: React.FC<InvoiceDataExtractDownloadModalBodyProps> = ({
  dataExtractDetail,
}) => {
  const currentUser = useTypedSelector((state) => state.user);
  const gridHeaders = getInvoiceDataExtractDownloadModalHeaders(currentUser);

  return (
    <Row className={styles.gridHeight + " my-3 px-3"}>
      <ClientDataGrid
        columnDefs={gridHeaders}
        rowData={Object.values(dataExtractDetail.properties?.invoice_properties!)}
        defaultColDef={{ floatingFilter: false }}
        gridStorageName={"Data Extracts - Invoices Download Modal"}
        hideSideBar={true}
        suppressMovableColumns={true}
        suppressMenuHide={true}
        pagination={true}
        paginationPageSize={50}
        maxBlocksInCache={1}
      />
    </Row>
  );
};

export default DownloadModal;
