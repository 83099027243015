import _ from "lodash";
import { useCallback, useEffect, useState, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import currency_codes from "../../../../../../../lookups/currencyCodeLookup";
import restApi from "../../../../../../../providers/restApi";

const FunctionalCurrencyPicker = (props) => {
  const {
    input,
    label,
    isMulti,
    tooltip,
    meta: { touched, error },
  } = props;
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);
  const [currencyCodes, setCurrencyCodes] = useState([]);

  const restApiService = useMemo(() => new restApi(), []);
  const company_default = useSelector((state) => state.form.companyDefaultForm.values.company_default);

  const getCurrencyCodes = useCallback(async () => {
    var currencies = [...currency_codes];
    try {
      if (company_default.crypto_currency_support) {
        const result = await restApiService.get("app_configs", {
          config_type: "CURRENCY",
        });
        if (result.data && _.isArray(result.data) && result.data[0]) {
          currencies.push(...result.data[0].config_data?.currencies);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setCurrencyCodes(currencies);
  }, [company_default.crypto_currency_support, restApiService]);

  const getAllowedCurrencyCodes = useCallback(() => {
    const getAllowedCurrencyCodes = [];
    currencyCodes.forEach((code) => {
      if (company_default && _.isArray(company_default.company_currency_codes)) {
        company_default.company_currency_codes.forEach((c) => {
          if (code.value === c.value) getAllowedCurrencyCodes.push(code);
        });
      }
    });
    return getAllowedCurrencyCodes;
  }, [company_default.company_currency_codes, currencyCodes]);

  const current_user = useSelector((state) => state.user);

  const setAllowedCurrencyCode = useCallback(() => {
    setOptions(
      company_default.global && company_default.global.allow_only_company_currency_codes
        ? getAllowedCurrencyCodes()
        : currencyCodes,
    );
  }, [
    company_default.global && company_default.global.allow_only_company_currency_codes,
    getAllowedCurrencyCodes,
    currencyCodes,
  ]);

  useEffect(() => {
    getCurrencyCodes();
  }, [
    company_default.company_currency_codes,
    company_default.crypto_currency_support,
    company_default?.global?.allow_only_company_currency_codes,
  ]);

  useEffect(() => {
    setAllowedCurrencyCode();
  }, [currencyCodes]);

  return (
    <Form.Group className="w-100">
      {label && <Form.Label>{label}</Form.Label>}
      {tooltip ?? ""}
      <Form.Control
        as="select"
        multiple={isMulti}
        disabled={!current_user.is_root}
        {...input}
        isInvalid={touched && !!error}
        isValid={touched && !error}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {t(option.label)}
          </option>
        ))}{" "}
      </Form.Control>
      {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};

export default FunctionalCurrencyPicker;
