import React from "react";
import { withRouter } from "react-router-dom";
import { ExpenseConstants } from "services/admin/expenses/expenseSvc";
import { restApiService } from "../../providers/restApi";
import { Can } from "../../services/authorization/authorization";
import "./datagrid.style.css";

class RandomChildCellRouting extends React.Component {
  render() {
    if (this.props.colDef.cellRendererParams && this.props.colDef.cellRendererParams.authorization) {
      return (
        //authenticated buttons
        <Can
          I={this.props.colDef.cellRendererParams.authorization.I}
          a={this.props.colDef.cellRendererParams.authorization.a}
          passThrough
        >
          {(allowed) => {
            if (allowed) {
              return (
                <button className="routingCellButton" onClick={() => this.onView()}>
                  {this.props.value}
                </button>
              );
            } else {
              return <span>{this.props.value}</span>;
            }
          }}
        </Can>
      );
    } else {
      //non authenticated buttons
      return (
        <button className="routingCellButton" onClick={() => this.onView()}>
          {this.props.value}
        </button>
      );
    }
  }
  onView = () => {
    let gridName = this.props.colDef.cellRendererParams.viewLink;
    switch (gridName) {
      case "/ar/purchase_orders/":
        return this.navigateTo("/ar/purchase_orders", this.props.data.purchase_order.id);
      case "/ar/purchasers/":
        return this.navigateTo("/ar/purchasers", this.props.data.company.id);
      case "/ar/invoices/":
        return this.navigateTo("/ar/invoices", this.props.data.invoice.id);
      case "vendors":
        return window.open(restApiService.angularBaseURL() + "vendors/" + this.props.data.id, "_self");
      // case 'vendors': return window.location = restApiService.angularBaseURL() + "vendors/"+this.props.data.id

      case "expenseItems":
        const searchAPIFilters = localStorage.getItem(ExpenseConstants.LIST_EXPENSE_ITEM_SEARCH_API_FILTERS);
        return this.navigateTo("/ap/expenses", this.props.data.id, "searchAPIFilters", searchAPIFilters);

      // Feature flag / commented out code
      // This line goes to the now-obsolete Angular expense item details page
      // case "expenseItems": return window.open(restApiService.angularBaseURL() + "expenses/" + this.props.data.id, '_self');

      default:
        this.navigateTo(gridName, this.props.data.id);
    }
  };

  navigateTo = (endpoint, id, queryStringKeyName, queryStringValues) => {
    if (id) {
      const queryString = queryStringKeyName && queryStringValues ? `?${queryStringKeyName}=${queryStringValues}` : "";
      const link = endpoint + "/" + id + queryString;
      this.props.history.push(link);
    }
  };
}

export default withRouter(RandomChildCellRouting);
