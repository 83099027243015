import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { required } from "../../../../../services/validations/reduxFormValidation";
import InstitutionNumber from "../../institutionNumber";
import TransitNumber from "../../transitNumber";
import { IDType } from "../../../../../services/common/types/common.type";
import { AccountNumberValidator } from "../../accountNumberValidator";
import BicValidator from "../../bicValidator";
import PurposeCode from "./purposeCode";

const WireCad = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const [institutionNumber, setInstitutionNumber] = useState<IDType>();
  const [transitNumber, setTransitNumber] = useState<IDType>();
  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <InstitutionNumber modelName={`${modelName}`} callBack={(value) => setInstitutionNumber(value)} />
      </Col>
      <Col md="6">
        <TransitNumber
          modelName={`${modelName}`}
          institutionNumber={institutionNumber}
          callBack={(value: IDType) => setTransitNumber(value)}
        />
      </Col>
      <Col md="4">
        <AccountNumberValidator
          modelName={`${modelName}`}
          accountNumberType="canadian-account-number"
          transitNumber={transitNumber}
          institutionNumber={institutionNumber}
          minLen={7}
          maxLength={12}
          label={t("common.paymentMethod.accountNumberLabel")}
        />
      </Col>
      <Col md="4">
        <BicValidator modelName={`${modelName}`} noValidate={true} />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="12">
        <PurposeCode />
      </Col>
    </>
  );
};

export default WireCad;
