import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderCheckBox, RenderField } from "../../../../forms/bootstrapFields";
import { required } from "../../../../../services/validations/reduxFormValidation";
import { AccountNumberValidator } from "../../accountNumberValidator";

const TwUsd = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const [isPayBySwiftCodeEnable, setPayBySwiftCodeEnable] = useState(false);

  return (
    <>
      <Col md="6" className="px-mt-40">
        <Field
          label={t("common.paymentMethod.payBySwiftCode")}
          component={RenderCheckBox}
          type="checkbox"
          name={`${modelName}.pay_by_swift_code`}
          onChange={() => setPayBySwiftCodeEnable((prevProp) => !prevProp)}
        />
      </Col>
      {isPayBySwiftCodeEnable && (
        <>
          <Col md="4">
            <Field
              name={`${modelName}.account_name`}
              component={RenderField}
              type="text"
              required
              validate={[required]}
              label={t("common.paymentMethod.accountHolderName")}
              className={`w-100`}
            />
          </Col>
          <Col md="4">
            <Field
              name={`${modelName}.swiftCode`}
              component={RenderField}
              type="text"
              required
              validate={[required]}
              label={t("common.paymentMethod.beneficiaryBankSwiftCode")}
              className={`w-100`}
            />
          </Col>
          <Col md="4">
            <AccountNumberValidator modelName={`${modelName}`} noValidate={true} />
          </Col>
        </>
      )}
    </>
  );
};

export default TwUsd;
