import React, { memo } from "react";
import commonService from "services/common/commonSvc";
import { ProjectPickerTypes } from "./projectPickerTypes";
import SingleProjectPicker from "./singlePicker";

const ProjectPicker = (props: ProjectPickerTypes.TProjectPickerProps) => {
  const { parentObj, modelData } = commonService.modifyParentModalDataRefStr(props);
  return (
    <>
      {props.isMulti ? (
        // TODO: Implement Multi Project Picker
        <></>
      ) : (
        <SingleProjectPicker {...props} parentObj={parentObj} modelData={modelData} />
      )}
    </>
  );
};

export default memo(ProjectPicker);
