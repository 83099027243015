import React, { Component } from "react";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import { Fragment } from "react";
import GenericSelectPicker from "../../vp/pickers/reduxPicker/genericSelectPicker/genericSelectPicker";
import PaymentCountryPicker from "../../pickers/paymentCountryPicker";

class CambridgeRuleInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  getOptions = () => {
    if (this.props.rule && this.props.rule.valueSet) {
      return this.props.rule.valueSet.map((value) => {
        return { id: value.id, label: value.text };
      });
    }
  };

  //For the country rules we should show our country picker
  checkIfCountry = (rule) => {
    if (!rule) {
      return;
    }
    if (rule.id == "accountHolderCountry" || rule.id == "bankCountry" || rule.id == "destinationCountry") {
      return true;
    } else {
      return false;
    }
  };

  //some fields we dont want the user to mess with
  checkIfDisabled = (rule) => {
    if (!rule) {
      return;
    }
    if (rule.id == "preferredMethod" || rule.id == "paymentMethods") {
      return true;
    } else {
      return false;
    }
  };

  //Notice we have 3 seperate render conditions.
  //1. render country picker if it matches checkIfCountry
  //2. render select dropdown
  //3. render normal text input
  render() {
    let rule = this.props.rule;
    let isCountry = this.checkIfCountry(this.props.rule);
    return (
      <Fragment>
        {isCountry ? (
          <PaymentCountryPicker
            code2={true}
            colSize="3"
            countryPickerCallback={(value) => {
              this.props.callback(rule, null, value.value);
            }}
            label={rule.label || rule.id}
            selected={{ value: this.props.id }}
          />
        ) : (
          <Fragment>
            {rule.valueSet && rule.valueSet.length > 0 ? (
              <Form.Group as={Col} md="3">
                <GenericSelectPicker
                  disabled={this.checkIfDisabled(rule)}
                  callback={(value) => {
                    this.props.callback(rule, null, value.value);
                  }}
                  id={this.props.id}
                  required={rule.isRequired === "true"}
                  label={rule.label || rule.id}
                  options={this.getOptions()}
                />
              </Form.Group>
            ) : (
              <Col md={3}>
                <Form.Group>
                  <Form.Label style={{ textTransform: "capitalize" }}>
                    {rule.label || rule.id}: {rule.isRequired === "true" && <span className={"color-red"}>*</span>}
                  </Form.Label>
                  <Form.Control
                    disabled={this.checkIfDisabled(rule)}
                    required={rule.isRequired === "true"}
                    name={rule.id}
                    value={this.props.id}
                    onChange={(event) => {
                      this.props.callback(rule, event);
                    }}
                    type="text"
                    placeholder={rule.defaultValue}
                    pattern={rule.regEx && rule.regEx.length > 0 ? rule.regEx : null}
                  />
                  <Form.Control.Feedback type="invalid">{rule.errorMessage}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(CambridgeRuleInput);
