import React from "react";
import { Collapse } from "react-bootstrap";
import { checkHostName, checkName, checkNameWithEmail } from "../../../../services/admin/invoices/emailSvc";
import moment from "moment";
import _ from "lodash";

export type collapseEmailPropsType = {
  email: any;
  moreDetail: boolean;
};

const CollapseEmail = ({ email, moreDetail }: collapseEmailPropsType) => {
  return (
    <Collapse in={moreDetail}>
      <div>
        <div className={"d-flex align-items-start"}>
          <h5 className={"w-25"}>Subject:</h5>
          <h5>{email?.subject}</h5>
        </div>
        <div className={"d-flex align-items-start"}>
          <h5 className={"w-25"}>From:</h5>
          <h5>{checkNameWithEmail(email?.from)}</h5>
        </div>
        <div className={"d-flex align-items-start"}>
          <h5 className={"w-25"}>To:</h5>
          <div>
            {_.isArray(email?.to) &&
              email.to.map((emailTo: any, index: number) => <h5 key={index}>{emailTo?.email}</h5>)}
          </div>
        </div>
        {_.isArray(email?.cc) && email.cc.length ? (
          <div className={"d-flex align-items-start"}>
            <h5 className={"w-25"}>Cc:</h5>
            <div>{email?.cc?.map((cc: any, index: number) => <h5 key={cc?.email}>{cc?.email}</h5>)}</div>
          </div>
        ) : (
          ""
        )}
        <div className={"d-flex align-items-start"}>
          <h5 className={"w-25"}>Date:</h5>
          <h5>{moment(email?.date).format("llll")}</h5>
        </div>
        <div className={"d-flex align-items-start"}>
          <h5 className={"w-25"}>Status:</h5>
          <h5>{email?.status}</h5>
        </div>
        <div className={"d-flex align-items-start"}>
          <h5 className={"w-25"}>Host:</h5>
          <h5>{checkHostName(email?.from)}</h5>
        </div>
        <div className={"d-flex align-items-start"}>
          <h5 className={"w-25"}>Name:</h5>
          <h5> {checkName(email?.from)}</h5>
        </div>
        <div className={"d-flex align-items-start"}>
          <h5 className={"w-25"}>Id:</h5>
          <h5>{email?.id}</h5>
        </div>
      </div>
    </Collapse>
  );
};
export default CollapseEmail;
