import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import RestApi from "../../../../providers/restApi";
import {
  onAddressUnmount,
  removeDefaultAddress,
  setAddresses,
} from "../../../../reducers/common/addressSectionReducer";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import CustomModal from "../../../modals/customModal";
import AddressForm from "./form";

const restApiService = new RestApi();

const AddAddress = ({ addModalOpen, setAddModalOpen, closeCallback }) => {
  const addresses = useSelector((state) => state.addressSectionRed.addresses);
  const dispatch = useDispatch();
  const addressableId = useSelector((state) => state.addressSectionRed.addressableId);
  const addressableType = useSelector((state) => state.addressSectionRed.addressableType);
  const { t } = useTranslation();

  const onSubmit = async (formData) => {
    const address = formData?.form ?? {};
    address.addressable_type = addressableType;
    address.addressable_id = addressableId;
    try {
      const response = await restApiService.post("addresses", null, { address });

      //  updating local list rather than fetching new data from network
      dispatch(setAddresses([response.data, ...addresses]));
      CreateNotification(
        t("success"),
        t("components.admin.addressable.addressAddedSuccessfully", { id: response.data.id }),
        NotificationType.success,
      );

      // remove other is_default flags
      if (response?.data?.is_default) {
        dispatch(removeDefaultAddress(response.data));
      }

      dispatch(onAddressUnmount());
      onHide();
    } catch (error) {
      console.log(error);
    }
  };

  const onHide = () => {
    setAddModalOpen(false);
    closeCallback();
  };

  useEffect(() => {
    return () => {
      dispatch(onAddressUnmount());
    };
  }, []);

  return (
    <CustomModal
      size={"lg"}
      show={addModalOpen}
      onHide={onHide}
      header={t("components.admin.addressable.addAddress")}
      headerClass={"px-4"}
      body={<AddressForm onSubmit={onSubmit} onHide={onHide} />}
    />
  );
};
export default AddAddress;
