const SET_CONFIRM_MODAL_OPEN = "SET_CONFIRM_MODAL_OPEN";
const SET_SAVE_CALL_BACK = "SET_SAVE_CALL_BACK";
const SET_CANCEL_CALL_BACK = "SET_CANCEL_CALL_BACK";
const SET_CALL_BACK_DATA = "CALL_BACK_DATA";
const SET_TITLE = "SET_TITLE";
const SET_BODY = "SET_BODY";
const RESET_MODAL = "RESET_MODAL";
const SET_CONFIRM_BUTTON_LABEL = "SET_CONFIRM_BUTTON_LABEL";
const SET_CANCEL_BUTTON_LABEL = "SET_CANCEL_BUTTON_LABEL";

const initialState = {
  confirmModalOpen: false,
  saveCallBack: null,
  cancelCallBack: null,
  callBackData: null,
  title: "ModalTitle",
  body: "ModalBody",
};

const confirmModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIRM_MODAL_OPEN:
      return { ...state, confirmModalOpen: action.payload };
    case SET_SAVE_CALL_BACK:
      return { ...state, saveCallBack: action.payload };
    case SET_CANCEL_CALL_BACK:
      return { ...state, cancelCallBack: action.payload };
    case SET_CALL_BACK_DATA:
      return { ...state, callBackData: action.payload };
    case SET_TITLE:
      return { ...state, title: action.payload };
    case SET_BODY:
      return { ...state, body: action.payload };
    case SET_CONFIRM_BUTTON_LABEL:
      return { ...state, confirmButtonLabel: action.payload };
    case SET_CANCEL_BUTTON_LABEL:
      return { ...state, cancelButtonLabel: action.payload };
    case RESET_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default confirmModalReducer;

// ACTIONS //
export const setConfirmModal = (value) => (dispatch) => {
  dispatch({ type: SET_CONFIRM_MODAL_OPEN, payload: value });
};

export const setSaveCallBack = (value) => (dispatch) => {
  dispatch({ type: SET_SAVE_CALL_BACK, payload: value });
};

export const setCancelCallBack = (value) => (dispatch) => {
  dispatch({ type: SET_CANCEL_CALL_BACK, payload: value });
};

export const setCallBackData = (value) => (dispatch) => {
  dispatch({ type: SET_CALL_BACK_DATA, payload: value });
};

export const setTitle = (value) => (dispatch) => {
  dispatch({ type: SET_TITLE, payload: value });
};

export const setBody = (value) => (dispatch) => {
  dispatch({ type: SET_BODY, payload: value });
};

export const setConfirmButtonLabel = (value) => (dispatch) => {
  dispatch({ type: SET_CONFIRM_BUTTON_LABEL, payload: value });
};

export const setCancelButtonLabel = (value) => (dispatch) => {
  dispatch({ type: SET_CANCEL_BUTTON_LABEL, payload: value });
};

export const resetConfirmModal = (value) => (dispatch) => {
  dispatch({ type: RESET_MODAL });
};

// for class Component
export const createConfirmModal =
  ({ title, body, saveCallBack, cancelCallBack, callBackData, confirmButtonLabel, cancelButtonLabel }) =>
  (dispatch) => {
    dispatch(setConfirmModal(true));
    dispatch(setSaveCallBack(saveCallBack));
    dispatch(setCancelCallBack(cancelCallBack));
    dispatch(setCallBackData(callBackData));
    dispatch(setTitle(title));
    dispatch(setBody(body));
    dispatch(setConfirmButtonLabel(confirmButtonLabel));
    dispatch(setCancelButtonLabel(cancelButtonLabel));
  };
