import { GridApi } from "ag-grid-community";
import BulkActionDropdown from "components/common/dataGrid/bulkActionDropdown";
import usePermission from "components/common/hooks/usePermission";
import _ from "lodash";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { VendorTrackerListType } from "services/admin/vendors/vendorTypes";
import { VendorApis } from "services/admin/vendors/vensorApis";
import ContactApis from "services/common/contact/contactApis";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";
import { restApiService } from "../../../providers/restApi";
import gridService from "services/grid/gridSvc";

type BulkActionPropsType = {
  gridApi?: GridApi;
  modelName?: string;
};

const BulkAction = ({ gridApi, modelName }: BulkActionPropsType) => {
  const { hasUserPermission, hasUserRole } = usePermission();
  const hasSendProfileReminderPermission = hasUserPermission("editVendors") || hasUserPermission("editVendorAccount");
  const hasSendWelcomePermission = hasUserPermission("editContacts") || hasUserPermission("contactsResendWelcome");
  const hasRootRole = hasUserRole("root");
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const bulkResendCompleteProfileMessage = async () => {
    if (gridApi) {
      const selectedRows: VendorTrackerListType[] = gridService.getServerSideSelectedRows(gridApi);
      if (_.isArray(selectedRows) && selectedRows.length > 0) {
        const selectedIds = selectedRows.map((selectedRow: VendorTrackerListType) => ({ id: selectedRow.id }));
        try {
          const response = await VendorApis.resendCompleteProfileMessage({ messages: selectedIds });
          response.forEach(function (vendor: { name: string; sent: boolean }) {
            if (vendor.sent) {
              CreateNotification(
                "Success",
                "Vendor Reminder Email to " + vendor.name + " Sent Successfully",
                NotificationType.success,
              );
            } else {
              CreateNotification(
                "Error: Vendor " + vendor.name,
                "Email not sent, please make sure the vendor has an associated contact configured",
                NotificationType.danger,
              );
            }
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        CreateNotification("No Rows Selected:", "Please select atleast one vendor", NotificationType.danger);
      }
    }
  };

  const sendInvitation = async (selectedContactIds: number[]) => {
    if (selectedContactIds.length > 0) {
      try {
        const response = await ContactApis.bulkSendWelcome({ contact_ids: selectedContactIds });
        if (gridApi) {
          gridApi.refreshServerSide();
        }
        if (!_.isPlainObject(response)) {
          CreateNotification("Success", "Attempted to send Welcome Messages.", NotificationType.success);
        } else {
          CreateNotification(
            "Error: Could not send welcome email(s)",
            "Could not send welcome emails.",
            NotificationType.danger,
          );
        }
      } catch (error) {
        CreateNotification("Error", "There was an error sending Welcome Emails", NotificationType.danger);
      }
    } else {
      CreateNotification("Error", "No contact found in selected vendors", NotificationType.danger);
    }
  };

  const bulkSendInvite = async () => {
    if (gridApi) {
      const selectedRows: VendorTrackerListType[] = gridService.getServerSideSelectedRows(gridApi);
      if (_.isArray(selectedRows) && selectedRows.length > 0) {
        let selectedContactIds: number[] = [];

        selectedRows.forEach((selectedRow) => {
          if (_.isPlainObject(selectedRow.contact) && selectedRow.contact.id) {
            selectedContactIds.push(selectedRow.contact.id);
          }
        });

        sendInvitation(selectedContactIds);
      } else {
        CreateNotification("No Rows Selected:", "Please select atleast one vendor", NotificationType.danger);
      }
    }
  };

  const refreshNvpPaymentMethods = async () => {
    try {
      const response = await restApiService.post("vendors/refresh_nvp_payment_methods");
      if (response) {
        CreateNotification("Success", "Payment Methods Refreshing", NotificationType.success);
      } else {
        CreateNotification(
          "Error",
          "Error refreshing payment methods. Please contact support",
          NotificationType.danger,
        );
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <BulkActionDropdown topClassName="mr-2">
        <>
          {hasSendProfileReminderPermission && (
            <Dropdown.Item className="smallFont" onClick={bulkResendCompleteProfileMessage}>
              <i className="icon-dropdown-list icon-enevelope-o-grey" /> Send Complete Profile Reminder
            </Dropdown.Item>
          )}
          {modelName === "vendorTracker" && hasSendWelcomePermission && (
            <Dropdown.Item className="smallFont" onClick={bulkSendInvite}>
              <i className="icon-dropdown-list icon-enevelope-o-grey" /> Send Welcome
            </Dropdown.Item>
          )}
          {hasRootRole && currentUser?.company?.is_nvp_customer && (
            <Dropdown.Item className="smallFont" onClick={refreshNvpPaymentMethods}>
              <i className="icon-dropdown-list icon-enevelope-o-grey" /> Refresh Payment Methods
            </Dropdown.Item>
          )}
        </>
      </BulkActionDropdown>
    </>
  );
};

export default BulkAction;
