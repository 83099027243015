import { RenderField } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const AccountNickName = ({ label, isNeckNameRequired, validations, tooltip }) => {
  return (
    <Col md="6">
      <Field
        id="form.nick_name"
        name="form.nick_name"
        component={RenderField}
        type="text"
        label={label}
        required={isNeckNameRequired}
        validate={validations ? validations : []}
        tooltip={tooltip}
      />
    </Col>
  );
};

export default AccountNickName;
