/////////////////////////////////////////////////////////////
//USE THE GENERIC SUBSIDIARY LINK COMPONENT TO ADD NEW FIELDS
/////////////////////////////////////////////////////////////

import React, { useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Field, FieldArray, FieldArrayFieldsProps } from "redux-form";
import LocationPicker from "../pickers/reduxFormPickers/locationPicker";
import { required } from "../../forms/inputFields";
import styles from "./subsidiaries.module.css";
import { useTranslation } from "react-i18next";
import Panel from "../../common/panel/panel";
import LinkManagerRenderer from "./linkManagerRenderer";
import { FieldType } from "../../../services/admin/subsidiaries/types";
import LazyLoad from "react-lazy-load";

type SubsidiaryLocationRendererType = {
  fields: FieldArrayFieldsProps<FieldType>;
  dataCached: boolean;
};

const SubsidiaryLocationRenderer = ({ fields, dataCached }: SubsidiaryLocationRendererType) => {
  const { t } = useTranslation();
  const [isSelectedIndex, setIsSelectedIndex] = useState(0);
  const [hiddenFields, setHiddenFields] = useState<number[]>([]);

  const getInitialLocationLink = (length: number) => {
    if (length > 0) {
      setIsSelectedIndex(length);
    }
    return {
      location_id: "",
      managersLink: [{ location_id: "", manager_id: "", level: 1 }],
    };
  };

  const handleRowSelection = (index: number) => {
    setIsSelectedIndex(index);
  };

  // Hiding deleted managers fields
  const toggleHiddenField = (index: number) => {
    setHiddenFields((prevHiddenFields) =>
      prevHiddenFields.includes(index)
        ? prevHiddenFields.filter((item) => item !== index)
        : [...prevHiddenFields, index],
    );
  };

  const handleRemoveField = (index: number) => {
    const item = fields.get(index);
    const updatedItem = { ...item, _destroy: 1 };
    fields.remove(index);
    fields.insert(index, updatedItem);

    const firstAvailableIndex = fields.getAll().findIndex((item, i) => i > index && item._destroy !== 1);
    if (firstAvailableIndex !== -1) {
      setIsSelectedIndex(firstAvailableIndex);
    }
  };

  return (
    <Panel
      cardHeaderClass="px-p-10"
      header={
        <div className="d-flex justify-content-between">
          <div className="flex-center">
            <i className="icon icon-drilldown m-0 px-mt-5" /> {t("subsidiary.linkLocation")}
          </div>
          {fields && fields?.length === 0 && (
            <div>
              <Button size="sm" onClick={() => fields?.push(getInitialLocationLink(fields?.length))}>
                <i className="icon icon-plus" aria-hidden="true"></i> {t("subsidiary.location")}
              </Button>
            </div>
          )}
        </div>
      }
      cardBodyClass="px-0"
    >
      {/* LOADING STATE */}
      {!dataCached && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}

      {dataCached && fields && fields.length > 0 && (
        <div>
          <Row className="px-mb-25 mx-0 px-1">
            <Col sm="6">
              <label>{t("subsidiary.location")}</label>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="8" className={styles.pickerHeading}>
                  {t("subsidiary.manager")}
                </Col>
                <Col sm="4" className={styles.pickerHeading}>
                  {t("subsidiary.level")}
                </Col>
              </Row>
            </Col>
          </Row>

          {/* FIELD RENDERS */}
          {fields.map(
            (field, index) =>
              !hiddenFields.includes(index) && (
                <Row key={index} className="mx-0">
                  <Col sm="6">
                    <Row className={`${isSelectedIndex === index ? styles.activeRow : ""}`}>
                      <Col sm="1" className="px-mt-10">
                        <Field
                          name={`${field}.isSelected`}
                          component="input"
                          type="radio"
                          value="true"
                          checked={isSelectedIndex === index}
                          onChange={() => handleRowSelection(index)}
                        />
                      </Col>
                      <Col sm="10">
                        <LazyLoad>
                          <Field
                            component={LocationPicker}
                            name={`${field}.location_id`}
                            validate={[required]}
                            required
                            menuPosition="fixed"
                            className="colorSubsidiaryBlue"
                            inputId={`location_id_${field}`}
                          />
                        </LazyLoad>
                      </Col>
                      <Col sm="1">
                        <span>
                          <i
                            onClick={() => {
                              handleRemoveField(index);
                              toggleHiddenField(index);
                            }}
                            className="icon icon-delete pointerHover background-position-center mt-1 py-3"
                          />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  {isSelectedIndex === index && (
                    <Col sm="6">
                      <FieldArray
                        key={`subsidiaryLocationManagerLink_${index}`}
                        name={`${field}.managersLink`}
                        component={LinkManagerRenderer}
                        isDepartmentManagerLinker={false}
                      />
                    </Col>
                  )}
                </Row>
              ),
          )}
        </div>
      )}

      {dataCached && fields && fields?.length === 0 && (
        <div className={`flex-center ${styles.noEntryLable}`}>{t("subsidiary.noLocation")}</div>
      )}

      {dataCached && fields && fields?.length > 0 && (
        <div className="px-mt-10">
          <Button size="sm" className="px-ml-20" onClick={() => fields?.push(getInitialLocationLink(fields?.length))}>
            <i className="icon icon-plus" aria-hidden="true"></i> {t("subsidiary.location")}
          </Button>
        </div>
      )}
    </Panel>
  );
};

export default SubsidiaryLocationRenderer;
