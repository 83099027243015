import React, { useCallback, useMemo, useState } from "react";

import ConsolidateErrorModal from "components/admin/consolidateError/consolidateErrorModal";
import { ConsolidateErrorModalTypes } from "components/admin/consolidateError/consolidateErrorModalTypes";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import InvoicesNavsTabs from "pages/admin/invoice/nav";
import { restApiService } from "providers/restApi";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import adminCommonSvc from "services/admin/commonSvc";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import InvoiceApis from "services/admin/invoices/invoiceApis";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import commonService from "services/common/commonSvc";
import { IUser } from "services/common/user/userTypes";
import { getStringFormattedAmount } from "services/general/helpers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import FileUploadSection from "./fileUploadSection";
import InvoiceHeaderLineSection from "./InvoiceHeaderLineSection";
import InvoiceQueue from "./invoiceQueue";
import { useQueueManagementContext } from "./queueManagementContext";
import { useUiStateContext } from "./uiStateContext";
import UploadQueueExpenseLineSection from "./uploadQueueExpenseLineSection";
import UploadQueueItemLineSection from "./uploadQueueItemLineSection";
import styles from "./uploadQueueManager.module.css";
import { handleError, stopEventPropagation } from "./uploadQueueService";

const UploadQueueManager = () => {
  const { t } = useTranslation();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { createConfirmModal } = useConfirmModal();
  const { selectedQueueInvoice, isFetching, refetchInvoiceList, refetchCurrentInvoiceQuery } =
    useQueueManagementContext();
  const { setIsOperationRunning, setOpenUploader, isOperationRunning } = useUiStateContext();
  const decimal = adminCommonSvc.getDefaultDecimal(currentUser);
  const [showConsolidateErrorModal, setShowConsolidateErrorModal] = useState<boolean>(false);
  const [consolidateErrorContainers, setConsolidateErrorContainers] = useState<
    ConsolidateErrorModalTypes.TConsolidateErrorContainer[] | null
  >(null);
  const { isFetchedCustomLabels } = useCustomLabel();

  const parsedFormValues = useMemo(() => {
    return invoiceCommonSvc.parseInvoiceForUploadQueue(selectedQueueInvoice?.invoice);
  }, [selectedQueueInvoice]);

  const methods = useForm<InvoiceType.InvoiceDetailType>({
    values: parsedFormValues,
  });

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isSubmitting, isLoading },
  } = methods;

  const assignAccountTransactionAttributes = (formValues: InvoiceType.InvoiceDetailType) => {
    if (
      (formValues?.debit_entries_attributes && formValues?.debit_entries_attributes?.length > 0) ||
      (formValues?.credit_entries_attributes && formValues?.credit_entries_attributes.length > 0)
    ) {
      invoiceCommonSvc.assignInvoiceAmountToCredit(formValues, currentUser);
      formValues.account_transaction_attributes = {};
      formValues.account_transaction_attributes.date = formValues.date;
      formValues.account_transaction_attributes.id = formValues?.account_transaction
        ? formValues?.account_transaction?.id
        : undefined;
      formValues.account_transaction_attributes.amount = formValues.amount;
      formValues.account_transaction_attributes.debit_entries_attributes = formValues.debit_entries_attributes;
      formValues.account_transaction_attributes.credit_entries_attributes = formValues.credit_entries_attributes;
    } else {
      formValues.account_transaction_attributes = undefined;
    }
  };

  const onSubmit = async (formValues: InvoiceType.InvoiceDetailType) => {
    //   if (invoice.requestor || invoice.requestor && invoice.requestor.id > 0)) {
    //   invoice.requestor_id = '';
    // }
    if (formValues.id) {
      formValues.status = "PENDING";
      //TODO-Temp assign debit entry and credit entry
      if (
        (formValues?.debit_entries_attributes && formValues?.debit_entries_attributes?.length > 0) ||
        (formValues?.credit_entries_attributes && formValues?.credit_entries_attributes.length > 0)
      ) {
        invoiceCommonSvc.assignInvoiceAmountToCredit(formValues, currentUser);

        formValues.account_transaction_attributes = {};
        formValues.account_transaction_attributes.date = formValues.date;
        formValues.account_transaction_attributes.id = formValues?.account_transaction
          ? formValues?.account_transaction?.id
          : undefined;
        formValues.account_transaction_attributes.amount = formValues.amount;
        formValues.account_transaction_attributes.debit_entries_attributes = formValues.debit_entries_attributes;
        formValues.account_transaction_attributes.credit_entries_attributes = formValues.credit_entries_attributes;
      } else {
        formValues.account_transaction_attributes = undefined;
      }

      try {
        // According to the requirement, invoices should initially be submitted with a PENDING status, similar to the invoice inbox process.
        // If the invoice is in PENDING status and there is an available workflow, it will then be submitted for approval.
        const response = await restApiService.post(`invoices/${formValues.id}/submit_for_approval`, null, {
          invoice: formValues,
        });
        if (response?.data?.id) {
          CreateNotification("Updated", `Invoice successfully updated`, NotificationType.success);
          if (response?.data?.status_message) {
            CreateNotification("Sync", response.status_message, NotificationType.info);
          }
        }
      } catch (error) {
        CreateNotification("Error", `Invoice not able to Submit. please try again.`, NotificationType.danger);
        console.log("error: ", error);
      } finally {
        refetchInvoiceList();
      }
    } else {
      CreateNotification("Error", `No Id Found, Please upload document.`, NotificationType.danger);
    }
  };

  const getConfirmationMessage = (formValue: InvoiceType.InvoiceDetailType) => {
    const currencyCode = formValue.currency_code;
    const amount = formValue.amount;
    const originalCurrencyCode = formValue.original_currency_code;
    const originalAmount = formValue.original_amount;

    const originalAmountNotChanged = selectedQueueInvoice ? (
      <>
        {t("admin.pages.uploadQueue.originalAmountNotChanged", { invoiceNumber: selectedQueueInvoice.invoice.number })}
      </>
    ) : (
      <></>
    );

    const originalAmountChanged = selectedQueueInvoice ? (
      <>
        {t("admin.pages.uploadQueue.originalAmountChanged", {
          to: getStringFormattedAmount(String(amount), currencyCode, decimal),
          from: getStringFormattedAmount(String(originalAmount), originalCurrencyCode, decimal),
        })}
      </>
    ) : (
      <></>
    );

    return amount === originalAmount ? originalAmountNotChanged : originalAmountChanged;
  };

  const onSubmitInvoiceClick = (formValue: any) => {
    if (!selectedQueueInvoice || !selectedQueueInvoice.invoice) {
      return;
    }
    createConfirmModal({
      title: t("confirm"),
      body: getConfirmationMessage(formValue),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: () => onSubmit(formValue),
    });
    // stopEventPropagation(event);
  };

  const onDeleteConfirm = async () => {
    if (!selectedQueueInvoice || !selectedQueueInvoice.invoice || !selectedQueueInvoice.invoice.id) {
      return;
    }

    try {
      setIsOperationRunning(true);
      await InvoiceApis.deleteInvoice(selectedQueueInvoice.invoice.id);
      CreateNotification(
        t("admin.pages.uploadQueue.deleted"),
        t("admin.pages.uploadQueue.confirm", { invNumber: selectedQueueInvoice.invoice.number }),
        NotificationType.success,
      );
    } finally {
      setIsOperationRunning(false);
      refetchInvoiceList();
    }
  };

  const onDeleteInvoiceClick = (event: any) => {
    stopEventPropagation(event);
    if (!selectedQueueInvoice || !selectedQueueInvoice.invoice) {
      return;
    }
    createConfirmModal({
      title: t("admin.pages.uploadQueue.delete"),
      body: t("admin.pages.uploadQueue.deleteConfirmBody", { invNumber: selectedQueueInvoice.invoice.number }),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: onDeleteConfirm,
    });
  };

  const onSaveInvoiceClick = (event: any) => {
    stopEventPropagation(event);
    if (!selectedQueueInvoice || !selectedQueueInvoice.invoice) {
      return;
    }

    const formValues = getValues();
    createConfirmModal({
      title: t("admin.pages.uploadQueue.save"),
      body: getConfirmationMessage(formValues),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: onSaveInvoiceConfirm,
    });
  };

  const onSaveInvoiceConfirm = async (event: any) => {
    if (!selectedQueueInvoice || !selectedQueueInvoice.invoice) {
      return;
    }

    try {
      setIsOperationRunning(true);
      await saveInvoiceAsync(true);
    } finally {
      setIsOperationRunning(false);
      refetchCurrentInvoiceQuery();
    }
  };

  const saveInvoiceAsync = async (notify: boolean = true) => {
    if (!selectedQueueInvoice || !selectedQueueInvoice.invoice || !selectedQueueInvoice.invoice.id) {
      return;
    }
    try {
      const formValues = getValues();
      assignAccountTransactionAttributes(formValues);
      await InvoiceApis.editInvoice(selectedQueueInvoice.invoice.id.toString(), {
        invoice: formValues,
      });
      if (notify) {
        CreateNotification(
          t("admin.pages.uploadQueue.saved"),
          t("admin.pages.uploadQueue.confirm", { invNumber: selectedQueueInvoice.invoice.number }),
          NotificationType.success,
        );
      }
    } catch (error) {
      console.log("error: ", error);
      handleError(error, t);
    }
  };

  const onCancelInvoiceClick = (event: any) => {
    stopEventPropagation(event);
    if (!selectedQueueInvoice || !selectedQueueInvoice.invoice) {
      return;
    }
    createConfirmModal({
      title: t("admin.pages.uploadQueue.cancel"),
      body: t("admin.pages.uploadQueue.cancelConfirmBody", { invNumber: selectedQueueInvoice.invoice.number }),
      callBackData: null,
      cancelCallBack: null,
      saveCallBack: () => reset(parsedFormValues),
    });
  };

  const btnsDisabled = useMemo(() => {
    return (
      isLoading ||
      isSubmitting ||
      isFetching ||
      isOperationRunning ||
      !selectedQueueInvoice ||
      !selectedQueueInvoice.invoice
    );
  }, [isFetching, isLoading, isOperationRunning, isSubmitting, selectedQueueInvoice]);

  const btnUploadDisabled = useMemo(() => {
    return isLoading || isSubmitting || isFetching || isOperationRunning;
  }, [isFetching, isLoading, isOperationRunning, isSubmitting]);

  const getErrorsContainers = useCallback((formStateErrors: any) => {
    return commonService.getReactHookFormConsolidateErrors({
      formErrors: formStateErrors,
      containers: [
        {
          container_name: "Main",
          container_id: "main",
          errors: [],
          isMain: true,
        },
        {
          container_name: "Line Items",
          container_id: "line_items",
          errors: [],
        },
        {
          container_name: "Debit Entries",
          container_id: "debit_entries",
          errors: [],
        },
      ],
      fieldContainerMap: {
        invoice_items_attributes: "line_items",
        debit_entries_attributes: "debit_entries",
      },
      nameLabelMapOverride: {
        date: "Invoice Date",
      },
    });
  }, []);

  const consolidateErrors = (errors: any) => {
    if (Object.keys(errors).length > 0) {
      setShowConsolidateErrorModal(true);
      setConsolidateErrorContainers(getErrorsContainers(errors));
    }
  };

  const onConsolidateErrorModalClose = () => {
    setShowConsolidateErrorModal(false);
    setConsolidateErrorContainers(null);
  };

  return (
    <>
      {showConsolidateErrorModal && consolidateErrorContainers && (
        <ConsolidateErrorModal
          errorContainers={consolidateErrorContainers}
          modalTitle="Your invoice could not be submitted"
          modalTitleMessage="Review the following list of incorrect or missing information and their respective screens:"
          showConsolidateErrorModal={showConsolidateErrorModal}
          cancel={() => onConsolidateErrorModalClose()}
        />
      )}
      <Container fluid>
        <Row>
          <Col md="12">
            <ErrorBoundary>
              <InvoicesNavsTabs activePageName={t("admin.pages.uploadQueue.invoiceQueue")} />
            </ErrorBoundary>
          </Col>
        </Row>
        {/*
          Used 'FormProvider' because...
          1. It provides access to the methods and state of the form.
          2. It allows you to manage form state and share it across components without manually passing props down the component tree.
          3. Simplifies prop drilling and provides a centralized place for managing form state.
      */}
        <FormProvider {...methods}>
          <form
            id="upload_queue_invoice_form"
            noValidate
            onSubmit={handleSubmit(onSubmitInvoiceClick, consolidateErrors)}
          >
            <Row>
              <Col md="1">
                <Button
                  className={`${styles.btnCustomHeight}`}
                  variant={`btn btn-primary`}
                  type="button"
                  onClick={() => setOpenUploader(true)}
                  disabled={btnUploadDisabled}
                >
                  {t("admin.pages.uploadQueue.upload")}
                </Button>
              </Col>
              <Col md="6" className={styles.messageText}>
                {t("admin.pages.uploadQueue.messageTop")}
                <br />
                {t("admin.pages.uploadQueue.messageBottom")}
              </Col>
              <Col md="5" className="d-flex justify-content-end">
                <Button
                  className={`${styles.btnCustomHeight}`}
                  variant="btn btn-secondary"
                  id="invoice_delete_btn"
                  disabled={btnsDisabled}
                  onClick={onDeleteInvoiceClick}
                >
                  {t("admin.pages.uploadQueue.delete")}
                </Button>
                <Button
                  className={`${styles.btnCustomHeight} ml-1`}
                  variant="btn btn-secondary"
                  id="invoice_cancel_btn"
                  disabled={btnsDisabled}
                  onClick={onCancelInvoiceClick}
                >
                  {t("admin.pages.uploadQueue.cancel")}
                </Button>
                <Button
                  className={`${styles.btnCustomHeight} ml-1`}
                  variant="btn btn-secondary"
                  id="invoice_save_btn"
                  disabled={btnsDisabled}
                  onClick={onSaveInvoiceClick}
                >
                  {t("admin.pages.uploadQueue.save")}
                </Button>
                <Button
                  className={`${styles.btnCustomHeight} ml-1`}
                  variant={`btn btn-primary`}
                  type="submit"
                  id="invoice_submit_btn"
                  disabled={btnsDisabled}
                >
                  {t("admin.pages.invoice.submit")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Row>
                  <Col md="2">
                    <InvoiceQueue />
                  </Col>
                  <Col md="5" className="px-p-0">
                    <FileUploadSection saveAsyncCallback={saveInvoiceAsync} />
                  </Col>
                  <Col md="5">{isFetchedCustomLabels && <InvoiceHeaderLineSection />}</Col>
                </Row>
              </Col>
            </Row>
            {currentUser.company.allow_invoice_items && <UploadQueueItemLineSection />}
            <UploadQueueExpenseLineSection />
          </form>
        </FormProvider>
      </Container>
    </>
  );
};

export default UploadQueueManager;
