import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderSelect } from "../../../forms/bootstrapFields";
import { required } from "../../../../services/validations/reduxFormValidation";
import { useTranslation } from "react-i18next";
import BankAddressLine from "../bankAddressLine";

const ExternalPaymentMethod = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col sm="6">
        <Field
          name={`${modelName}.account_type`}
          component={RenderSelect}
          options={[
            { value: null, label: "" },
            { value: "checking", label: "Checking" },
            { value: "saving", label: "Saving" },
            { value: "Wire", label: "Wire" },
            { value: "credit_card", label: "Credit Card" },
            { value: "other", label: "Other" },
          ]}
          required
          validate={[required]}
          label={t("common.paymentMethod.accountType")}
        />
      </Col>
      <BankAddressLine modelName={`${modelName}`} defaultCountry="USA" />
    </>
  );
};

export default ExternalPaymentMethod;
