// system_names + application_types

import { Col, Row } from "react-bootstrap";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import NonTransactionSettings from "../../components/integrationComponents/nonTransactionSettings";
import TransactionSettings from "../../components/integrationComponents/transactionSettings";
import NonTransactionPushSettings from "../../components/integrationComponents/nonTransactionPushSettings";
import IntegrationJobs from "../../components/integrationComponents/integrationJobs";

// these is braking our name convention rule but required for dynamic import
const IntegrationAI2 = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <TransactionSettings />
      </Row>
      <Row className="m-0">
        <NonTransactionSettings />
      </Row>
      <Row className="m-0">
        <NonTransactionPushSettings />
      </Row>
      <Row className="m-0">
        <IntegrationJobs />
      </Row>
    </Col>
  );
};

// using named export intensionally
export { IntegrationAI2 };
