import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  poSettingsApproval,
  poSettingsExpenseLine,
  poSettingsHeaderLevel,
  poSettingsItemLine,
  poSettingsPrint,
} from "./poSettings";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import companySettingStyle from "../../companySettingTab.module.css";
import CommonSettingField from "../../components/commonSettingField";

const PoSetting = () => {
  const formName = "companyDefaultForm";
  const dispatch = useDispatch();

  const isShowObj = {};
  const company_default = useSelector((state) => state.form.companyDefaultForm.values.company_default);
  const company = useSelector((state) => state.form.companyDefaultForm.values.company);
  const current_user = useSelector((state) => state.user);
  isShowObj.company_default = company_default;
  isShowObj.company = company;
  isShowObj.current_user = current_user;

  const onChangeObj = {};
  onChangeObj.formName = formName;
  onChangeObj.dispatch = dispatch;
  onChangeObj.company_default = company_default;
  onChangeObj.company = company;

  return (
    <>
      <Col md="12" className="px-4">
        <Row>
          <Col>
            <div className={`${companySettingStyle.settingsTitle}`}>Header Level</div>
            <hr className="mt-0" />
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {poSettingsHeaderLevel
                  .filter((setting) => setting.col === 1)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 2 */}
                {poSettingsHeaderLevel
                  .filter((setting) => setting.col === 2)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 3 */}
                {poSettingsHeaderLevel
                  .filter((setting) => setting.col === 3)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={`${companySettingStyle.settingsTitle}`}>Item Line</div>
            <hr className="mt-0" />
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {poSettingsItemLine
                  .filter((setting) => setting.col === 1)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 2 */}
                {poSettingsItemLine
                  .filter((setting) => setting.col === 2)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 3 */}
                {poSettingsItemLine
                  .filter((setting) => setting.col === 3)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={`${companySettingStyle.settingsTitle}`}>
              Expense Line
              <hr className="mt-0" />
            </div>
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {poSettingsExpenseLine
                  .filter((setting) => setting.col === 1)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 2 */}
                {poSettingsExpenseLine
                  .filter((setting) => setting.col === 2)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
              <Col md="4">
                {/* coloum no. 3 */}
                {poSettingsExpenseLine
                  .filter((setting) => setting.col === 3)
                  .map((setting, index) => (
                    <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>

        {poSettingsPrint.length > 0 && (
          <>
            <Row>
              <Col>
                <div className={`${companySettingStyle.settingsTitle}`}>PO Print</div>
                <hr className="mt-0" />
                <Row>
                  {/* To show diffent columns we will filter json accronding to col value */}
                  <Col md="4">
                    {/* coloum no. 1 */}
                    {poSettingsPrint
                      .filter((setting) => setting.col === 1)
                      .map((setting, index) => (
                        <CommonSettingField
                          key={index}
                          setting={setting}
                          isShowObj={isShowObj}
                          onChangeObj={onChangeObj}
                        />
                      ))}
                  </Col>
                  <Col md="4">
                    {/* coloum no. 2 */}
                    {poSettingsPrint
                      .filter((setting) => setting.col === 2)
                      .map((setting, index) => (
                        <CommonSettingField
                          key={index}
                          setting={setting}
                          isShowObj={isShowObj}
                          onChangeObj={onChangeObj}
                        />
                      ))}
                  </Col>
                  <Col md="4">
                    {/* coloum no. 3 */}
                    {poSettingsPrint
                      .filter((setting) => setting.col === 3)
                      .map((setting, index) => (
                        <CommonSettingField
                          key={index}
                          setting={setting}
                          isShowObj={isShowObj}
                          onChangeObj={onChangeObj}
                        />
                      ))}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}

        {poSettingsApproval.length > 0 && (
          <>
            <Row>
              <Col>
                <div className={`${companySettingStyle.settingsTitle}`}>Approval</div>
                <hr className="mt-0" />
                <Row>
                  {/* To show diffent columns we will filter json accronding to col value */}
                  <Col md="4">
                    {/* coloum no. 1 */}
                    {poSettingsApproval
                      .filter((setting) => setting.col === 1)
                      .map((setting, index) => (
                        <CommonSettingField
                          key={index}
                          setting={setting}
                          isShowObj={isShowObj}
                          onChangeObj={onChangeObj}
                        />
                      ))}
                  </Col>
                  <Col md="4">
                    {/* coloum no. 2 */}
                    {poSettingsApproval
                      .filter((setting) => setting.col === 2)
                      .map((setting, index) => (
                        <CommonSettingField
                          key={index}
                          setting={setting}
                          isShowObj={isShowObj}
                          onChangeObj={onChangeObj}
                        />
                      ))}
                  </Col>
                  <Col md="4">
                    {/* coloum no. 3 */}
                    {poSettingsApproval
                      .filter((setting) => setting.col === 3)
                      .map((setting, index) => (
                        <CommonSettingField
                          key={index}
                          setting={setting}
                          isShowObj={isShowObj}
                          onChangeObj={onChangeObj}
                        />
                      ))}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </>
  );
};

export default PoSetting;
