import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ToggleFilterButton = (props) => {
  const { t } = useTranslation();
  return (
    <OverlayTrigger
      placement="auto"
      overlay={
        <Tooltip id={"filter"}>
          <strong>{t("grid.toggleFilter")}</strong>
        </Tooltip>
      }
    >
      <button type="button" className={"gridTopButton " + props.classes} onClick={props.clickCallback}>
        <span className="filter-funnel"></span>
      </button>
    </OverlayTrigger>
  );
};

export default ToggleFilterButton;
