import _ from "lodash";
import React, { memo, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import SubsidiaryApis from "services/admin/subsidiaries/subsidiaryApis";
import commonService from "services/common/commonSvc";
import { IDType } from "services/common/types/common.type";
import { IUser } from "services/common/user/userTypes";
import AbstractListPicker from "../abstractListPicker/index2";
import { SubsidiaryPickerTypes } from "./subsidiaryPickerTypes";

export type TSubsidiaryPickerProps = {
  name: string;
  objectName?: string;
  required?: boolean;
  disabled?: boolean;
  activeOnly?: boolean;
  multiple?: boolean;
  label?: string;
  sectionName?: string;
  showInactiveStatus?: boolean;
  instanceId?: string;
  modelData?: string;
  modelName?: string;
};

type TApiParams = {
  active: boolean;
  lk?: boolean;
  contact_id?: IDType;
};

const SubsidiaryPicker = ({
  name,
  objectName = "subsidiary",
  label,
  disabled = false,
  required = false,
  multiple = false,
  activeOnly = true,
  sectionName,
  instanceId,
  modelData,
  modelName,
  showInactiveStatus = true,
}: TSubsidiaryPickerProps) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { setValue } = useFormContext();

  const params = useMemo(() => {
    const newParams: TApiParams = { active: activeOnly };

    if (sectionName === "poRequest") {
      newParams.lk = true;

      if (currentUser?.company?.purchase_order?.use_user_level_subsidiaries) {
        newParams.contact_id = currentUser.contact.id;
      }
    }

    return newParams;
  }, [activeOnly, sectionName, currentUser]);

  const fetchUrl = sectionName === "poRequest" ? "subsidiaries.lk" : "subsidiaries";

  const mergeInactive = useCallback(
    async (currentId, subsidiaries) => {
      let inactiveOption = null;
      if (currentId) {
        const subsidiary = Array.isArray(subsidiaries)
          ? subsidiaries.filter((subsidiary) => subsidiary?.id === currentId)
          : [];

        if (subsidiary.length === 0 && _.isPlainObject(modelData) && modelName === "expense_item") {
          setValue("modelData.subsidiary_id", null);
          inactiveOption = null;
        } else if (subsidiary.length === 0) {
          try {
            const response = await SubsidiaryApis.getSubsidiary(currentId);
            inactiveOption = response;
          } catch (error) {
            commonService.handleError(error);
          }
        } else {
          inactiveOption = subsidiary[0];
        }
      }
      return { inactiveOption };
    },
    [modelData, modelName, setValue],
  );

  return (
    <AbstractListPicker<SubsidiaryPickerTypes.TSubsidiaryPickerOption>
      name={name}
      instanceId={instanceId}
      label={label}
      required={required}
      disabled={disabled}
      mergeInactive={mergeInactive}
      params={params}
      containerClassName="pickerLabel"
      fetchUrl={fetchUrl}
    />
  );
};

export default memo(SubsidiaryPicker);
