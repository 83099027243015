import { AppDispatch, RootState } from "..";
import { SET_VENDOR_CONTACT_FORM, RESET_VENDOR_CONTACT_FORM } from "../../actions/actionTypes";

export const VENDOR_CONTACT_FORM = "vendorContactForm";

// INITIAL_STATE
const initialState = {
  // address form
  vendorContactFrom: {
    form: {
      contact: {},
    },
  },
};

const vendorContactFormReducer = (state = initialState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case SET_VENDOR_CONTACT_FORM:
      return { ...state, vendorContactFrom: { form: { contact: payload } } };
    case RESET_VENDOR_CONTACT_FORM:
      return initialState;
    default:
      return state;
  }
};

export default vendorContactFormReducer;

// action dispatchers
export const setVendorContactForm = (formData: any) => (dispatch: AppDispatch, getState: any) => {
  dispatch({ type: SET_VENDOR_CONTACT_FORM, payload: formData });
};

export const resetVendorContactForm = () => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch({ type: RESET_VENDOR_CONTACT_FORM });
};
