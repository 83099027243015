// @ts-nocheck
// TODO: remove this no check for typeScript after implementing paymentAccountPicker in TS
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import RestApi from "../../../providers/restApi";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { Link } from "react-router-dom";
import {
  LinkPaymentMethod,
  purchaserHasAnyAcceptedPaymentMethods,
} from "../../../services/paymentMethods/paymentMethodLinking";
import PaymentAccountPicker from "../../pickers/paymentAccountPicker";
import _ from "lodash";
import style from "./unlinkedPurchasers.module.css";

const restApiService = new RestApi();

type paymentMethodLinkingPropsType = {
  successCallback: () => void;
};

const PaymentMethodLinker = ({ successCallback }: paymentMethodLinkingPropsType) => {
  const [purchasers, setPurchasers] = useState<any>([]);
  const [loaded, setLoaded] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);

  const checkAllowedSubmit = () => {
    // allow submit only if all purchasers have at lease one payment method selected
    const selectedPaymentStatus = purchasers.map((purchaser) => {
      return purchaser?.selectedPaymentMethods?.length;
    });

    const allowStatus = selectedPaymentStatus.reduce((a, c) => {
      return a && c;
    }, selectedPaymentStatus[0]);

    return allowStatus;
  };

  useEffect(() => {
    getPurchasers();
  }, []);

  useEffect(() => {
    const isAllowedSubmit = checkAllowedSubmit();
    setAllowSubmit(isAllowedSubmit);
  }, [purchasers]);

  const getPurchasers = async () => {
    try {
      const response = await restApiService.get("purchasers");
      response.data.sort((firstPurchaser: any, secondPuchaser: any) =>
        firstPurchaser.name.localeCompare(secondPuchaser.name, "en", { sensitivity: "base" }),
      );

      //only want to show purchasers that do NOT have a pm linked
      let validPurchasers: any[] = [];
      response.data.forEach((purchaser: any) => {
        if (!purchaser.has_linked_payment_method && purchaserHasAnyAcceptedPaymentMethods(purchaser)) {
          if (purchaser.linked_payment_methods && purchaser.linked_payment_methods.length > 0) {
            purchaser.selectedPaymentMethods = purchaser.linked_payment_methods;
          }
          validPurchasers.push(purchaser);
        }
      });
      setPurchasers(validPurchasers);
      setLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const linkPaymentMethod = async (e: any) => {
    e.preventDefault();

    let promisePaymentLinking: any[] = [];
    purchasers.map((purchaser: any) => {
      if (!purchaser.selectedPaymentMethods) {
        return;
      }

      if (_.isArray(purchaser?.selectedPaymentMethods)) {
        promisePaymentLinking = purchaser.selectedPaymentMethods.map((selectedPaymentMethod: any) => {
          return LinkPaymentMethod(purchaser.vendor_detail.id, selectedPaymentMethod.id);
        });
      }
    });

    try {
      Promise.all(promisePaymentLinking).then((response) => {
        CreateNotification("Success", "Payment Account Linked Succesfully", NotificationType.success);
        if (successCallback) {
          successCallback();
        }
      });
    } catch (error) {
      console.log(error);
      CreateNotification("Error", "Please reach out to support", NotificationType.warning);
    }
  };

  //since the user has to do 2 actions (pick payment method, click the checkmark) we need to
  //save this payment method for this purchaser on the purchaser
  const pmPicked = (paymentMethods: any, purchaser: any) => {
    setPurchasers((prev: any) =>
      prev.map((purch: any) => {
        if (purch.id === purchaser.id) {
          purch.selectedPaymentMethods = paymentMethods
            ? paymentMethods.map((paymentMethod: any) => {
                return paymentMethod.raw;
              })
            : null;
        }
        return purch;
      }),
    );
  };

  if (!loaded || (purchasers && purchasers.length < 1)) {
    return null;
  }

  return (
    <Col>
      <Form onSubmit={linkPaymentMethod}>
        <Row className={"d-flex flex-column justify-content-center align-items-center " + style.paymentLinkerStyle}>
          <h4> Customer Payment Method </h4>
          <Table striped bordered hover className="mb-0">
            <thead>
              <tr>
                <th className="primaryFontColor">{"Name"}</th>
                <th className="primaryFontColor">{"Select Account"}</th>
              </tr>
            </thead>
            <tbody>
              {_.isArray(purchasers) &&
                purchasers.map((data) => {
                  return (
                    <tr key={data.id}>
                      <td>
                        <Link className="primaryLinkColor" to={"/ar/purchasers/" + data.id}>
                          {data.name}
                        </Link>
                      </td>
                      {purchaserHasAnyAcceptedPaymentMethods(data) ? (
                        <td className={style.selectAccount}>
                          <PaymentAccountPicker
                            fixed
                            menuPosition={"fixed"}
                            hideAddNewButton={true}
                            isMulti={true}
                            id={
                              _.isArray(data?.selectedPaymentMethods)
                                ? data.selectedPaymentMethods.map(
                                    (selectedPaymentMethod: any) => selectedPaymentMethod.id,
                                  )
                                : null
                            }
                            limitPaymentMethods={data.accepted_payment_methods}
                            callback={(pms: any) => pmPicked(pms, data)}
                            hideLabel={true}
                          />
                        </td>
                      ) : (
                        <td className={style.selectAccount}>Not accepting payment methods at this time</td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Row>
        <Row className="mt-3 d-flex justify-content-center">
          <Button disabled={!allowSubmit} type="submit">
            Next
          </Button>
        </Row>
      </Form>
    </Col>
  );
};

export default PaymentMethodLinker;
