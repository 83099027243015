import React, { useEffect, useState } from "react";
import AttachmentPreviewer from "../attachmentPreview/AttachmentPreviewer";
import CustomModal from "../../modals/customModal";
import useDiversityTrackigForm from "../hooks/useDiversityTrackingForm/useDiversityTrackigForm";
import styles from "./vendorDocumentPreview.module.css";
import { DocumentRequirementsType } from "services/vp/purchaser/purchaserTypes";
import { IDType } from "services/common/types/common.type";

type PreviewableDocumentType = {
  asset_file?: File;
  file_name?: string;
  documentRequested: DocumentRequirementsType;
  id?: IDType;
  company_id?: IDType;
  created_at?: string;
  document_type?: string;
  documents?: Document[];
  isUpdated?: boolean;
  required?: boolean;
  signatureValue?: string;
  signReference?: string;
  updated_at?: string;
  file?: File;
  w8PageData?: string;
  w8BenPageData?: string;
  w9PageData?: string;
  diversityFormData?: Record<string, any>;
};

type VendorDocumentPreviewPropsType = {
  previewableDocument: PreviewableDocumentType;
  onHide: () => void;
};

const VendorDocumentLocalPreview = ({ previewableDocument, onHide }: VendorDocumentPreviewPropsType) => {
  const [previewFile, setPreviewFile] = useState<{ name: string; url: string }>();
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [w8BenForm, setW8BenForm] = useState("");
  const [htmlForms, setHtmlForms] = useState("");
  const [diversityFormData, setDiversityFormData] = useState<Record<string, any>>();
  const { diversityTrackingForm } = useDiversityTrackigForm({ initialState: diversityFormData, isDisabled: true });

  const closePreviewerModal = () => {
    setShowPreviewModal(false);
    onHide();
  };

  const handlePreview = (file: File) => {
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setPreviewFile({ name: file.name, url: reader.result });
          setShowPreviewModal(true);
        }
      };

      reader.readAsDataURL(file); // Read file as data URL for images and text files
      // For PDF or other formats, adjust reading methods accordingly
    }
  };

  const previewDocument = async () => {
    if (previewableDocument.w9PageData) {
      setHtmlForms(previewableDocument.w9PageData);
      setShowPreviewModal(true);
    } else if (previewableDocument.w8PageData) {
      setHtmlForms(previewableDocument.w8PageData);
      setShowPreviewModal(true);
    } else if (previewableDocument.w8BenPageData) {
      setW8BenForm(previewableDocument.w8BenPageData);
      setShowPreviewModal(true);
    } else if (previewableDocument.diversityFormData) {
      setDiversityFormData(previewableDocument.diversityFormData);
      setShowPreviewModal(true);
    } else {
      if (previewableDocument.asset_file) {
        handlePreview(previewableDocument.asset_file);
      }
    }
  };

  useEffect(() => {
    previewDocument();
  }, []);

  return (
    <>
      {showPreviewModal && previewFile && (
        <AttachmentPreviewer
          contentClassName={styles.modalContentClass}
          closePreviewer={() => closePreviewerModal()}
          isVisible={showPreviewModal}
          file={previewFile}
        />
      )}

      {/* for w8 w9 */}
      {showPreviewModal && htmlForms && (
        <CustomModal
          show={showPreviewModal}
          contentClassName={styles.modalContentClass}
          header={<>{"File Attachment Preview"}</>}
          body={<div dangerouslySetInnerHTML={{ __html: htmlForms }} />}
          onHide={() => closePreviewerModal()}
          size="xl"
        />
      )}

      {/* for w8ben */}
      {showPreviewModal && w8BenForm && (
        <CustomModal
          show={showPreviewModal}
          contentClassName={styles.modalContentClass}
          header={<>{"File Attachment Preview"}</>}
          body={<div style={{ position: "relative" }} dangerouslySetInnerHTML={{ __html: w8BenForm }} />}
          onHide={() => closePreviewerModal()}
          size="xl"
        />
      )}

      {showPreviewModal && diversityFormData && (
        <CustomModal
          header={<>{"File Attachment Preview"}</>}
          contentClassName={styles.modalContentClass}
          show={showPreviewModal}
          onHide={() => closePreviewerModal()}
          body={<>{diversityTrackingForm}</>}
          size="lg"
        />
      )}
    </>
  );
};

export default VendorDocumentLocalPreview;
