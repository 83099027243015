import React, { ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { CHANGE_BIC_CODE } from "../../../../actions/actionTypes";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { PaymentFieldType } from "../types";

type BicCodePropsType = PaymentFieldType;

const BicCode = ({ validations, required, label }: BicCodePropsType) => {
  const dispatch = useDispatch();

  const bicCodeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({ type: CHANGE_BIC_CODE, payload: e.target.value });
  };

  return (
    <Field
      name="bic"
      label={label}
      component={RenderField}
      required={required}
      validate={validations}
      onChange={bicCodeChange}
    />
  );
};

export default BicCode;
