import React, { useContext } from "react";
import CustomModal from "../../modals/customModal";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import TabNavigator from "../tabNavigator/tabNavigator";
import style from "./vendorDocumentUploadModal.module.css";
import Upload from "./uploadModalTabs/upload";
import Template from "./uploadModalTabs/template";
import DocumentRequirementForms from "./uploadModalTabs/forms";
import { VDocumentUploadContext } from "./vendorDocumentUploadContext";

const UploadModal = () => {
  const context = useContext(VDocumentUploadContext);

  return (
    <CustomModal
      show={context?.showUploadModal}
      onHide={context?.closeUploadModal}
      contentClassName={style.modalContentClass}
      backdrop={true}
      size={context?.selectedFrom ? "xl" : "lg"}
      header={
        <>
          {context?.documentRequirement?.document_type && (
            <h3>
              Document Requirement:{" "}
              <span className={style.uploadModalHeading}>{context?.documentRequirement.document_type}</span>
            </h3>
          )}
          {!context?.documentRequirement?.document_type && <h3>Upload Document</h3>}
        </>
      }
      body={
        <Form
          id="vendorDocumentModalForm"
          noValidate
          validated={context?.isValidated}
          onSubmit={(e) => {
            context?.submitUploadModal(e);
          }}
        >
          <Row>
            <Col className="d-flex justify-content-center">
              {context?.activeTab && (
                <TabNavigator
                  activeTab={context.activeTab}
                  handleTabChange={(value) => context?.setActiveTab(value)}
                  tabData={[
                    { label: "Uploads", activeTab: "upload" },
                    { label: "Template", activeTab: "template", hide: !context.showTemplateSection },
                    { label: "Forms", activeTab: "forms" },
                  ]}
                />
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              {context?.activeTab === "upload" && <Upload />}
              {context?.activeTab === "template" && <Template />}
              {context?.activeTab === "forms" && <DocumentRequirementForms />}
            </Col>
          </Row>
        </Form>
      }
      footer={
        <>
          <Button variant="secondary" className="mx-1" onClick={context?.closeUploadModal}>
            Cancel
          </Button>
          <Button
            form="vendorDocumentModalForm"
            className="d-flex align-items-center"
            type="submit"
            disabled={context?.isSubmitting}
          >
            Submit
            {context?.isSubmitting && <Spinner animation="border" size="sm" className="ml-2" />}
          </Button>
        </>
      }
      centered
    />
  );
};

export default UploadModal;
