import { RenderDatePicker } from "components/forms/bootstrapFields";
import _, { find } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { shallowEqual, useDispatch } from "react-redux";
import { RootState, useTypedSelector } from "reducers";
import { Field, change } from "redux-form";
import adminCommonSvc from "services/admin/commonSvc";
import expenseItemCommonSvc from "services/admin/expenses/expenseItems/expenseItemCommonSvc";
import { IUser } from "services/common/user/userTypes";
import { companyDateFormat } from "services/general/dateSvc";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { required } from "services/validations/reduxFormValidation";
import { MetadataFieldApis } from "wombatifier/services/metadata/metadataFieldApis";
import { MetadataSelectorSvc } from "wombatifier/services/metadata/metadataSelectorSvc";
import { MetadataTemplateApis } from "wombatifier/services/metadata/metadataTemplateApis";
import style from "../../../components/admin/vendors/vendors.module.css";
import { MetadataLinkAttributes, MetadataTemplate } from "../../services/metadata/metadata.types";
import { WombatInput } from "../pickers/wombatInput";
import { WombatSelect } from "../pickers/wombatSelect";
import styles from "./selector.module.css";

interface MetadataConfigurationFieldPropsType {
  formName: string;
  field_id: string;
  index: number;
  metadataSelectorServiceRef: { current: MetadataSelectorSvc };
  formSlice?: string;
  moduleName: string | string[];
  inline?: boolean;
  parentDivId?: string;
  parentDivVariance?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
}

const MetadataConfigurationField = ({
  formName,
  field_id,
  index,
  metadataSelectorServiceRef,
  formSlice,
  moduleName,
  inline = false,
  parentDivId,
  parentDivVariance,
}: MetadataConfigurationFieldPropsType) => {
  const metadataSelectorService = metadataSelectorServiceRef.current;
  const mc = useTypedSelector(
    (state: RootState) => metadataSelectorService.getCurrentConfiguration(state, field_id, formSlice),
    shallowEqual,
  );
  const chosenParentMetadataFieldId = useTypedSelector((state: RootState) =>
    metadataSelectorService.getChosenParentMetadataFieldId(state, mc.parent_hierarchy, formSlice),
  );
  const mcOptions = useTypedSelector(
    (state: RootState) => metadataSelectorService.getCurrentConfigurationOptions(state, field_id, formSlice),
    shallowEqual,
  );
  const isExpenseItemForm = expenseItemCommonSvc.isExpenseItemForm({ modules: moduleName, formName });
  const mcohName = `${formSlice ? `${formSlice}.` : ``}metadataForm.metadata_configurations_options_hash.${mc.field_id}`;
  const dispatch = useDispatch();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const getHierarchyOptions = useCallback(async (parentMetadataFieldId: number | undefined) => {
    if (!mc.parent_hierarchy) {
      return;
    }
    let res: any;
    if (mc.parent_hierarchy.parent_field_type === "MetadataConfiguration") {
      res = await MetadataFieldApis.list({
        filter: {
          field_id: mc.field_id,
          dependent_field_id: mc.id,
          dependent_field_type: "MetadataConfiguration",
          parent_field_id: mc.parent_hierarchy.parent_field_id,
          parent_field_type: mc.parent_hierarchy.parent_field_type,
          parent_metadata_field_id: parentMetadataFieldId,
        },
      });
    } else {
      res = await MetadataFieldApis.list({
        filter: {
          field_id: mc.field_id,
          dependent_field_id: mc.id,
          dependent_field_type: "MetadataConfiguration",
          parent_field_id: parentMetadataFieldId,
          parent_field_type: mc.parent_hierarchy.parent_field_type,
        },
      });
    }
    metadataSelectorService.updateForm(mcohName, MetadataSelectorSvc.createFieldOptions(res));
  }, []);

  const getOptions = useCallback(async () => {
    const res = await MetadataFieldApis.list({ filter: { field_id: mc.field_id } });
    metadataSelectorService.updateForm(mcohName, MetadataSelectorSvc.createFieldOptions(res));
  }, []);

  const resetOptions = useCallback(async () => {
    metadataSelectorService.updateForm(mcohName, []);
    if (mc.input_type === "select") {
      metadataSelectorService.updateForm(`${name}.metadata_field_id`, null);
    } else {
      metadataSelectorService.updateForm(`${name}.value`, null);
    }
    await getOptions();
  }, []);

  useEffect(() => {
    if (chosenParentMetadataFieldId) {
      getHierarchyOptions(chosenParentMetadataFieldId);
    } else if (mc.parent_hierarchy && !chosenParentMetadataFieldId) {
      resetOptions();
    } else if (!mc.parent_hierarchy) {
      getOptions();
    }
  }, [chosenParentMetadataFieldId]);

  const baseFieldOptions = {
    label: mc.name,
    isRequired: mc.required,
    validate: mc.required ? [required] : [],
  };

  let name = `metadata_links_attributes[${index}]`;
  const [formNameForExpenseItem] = useState([formSlice, name].join("."));
  if (formSlice && !isExpenseItemForm) {
    name = [formSlice, name].join(".");
  }

  let handleCallBack = function (value: { name: string }, mcOptions: any) {
    let selectedOption = mcOptions.find((option: any) => option.value === value);
    if (!isExpenseItemForm) {
      dispatch(change(formName, `${name}.value`, selectedOption?.label));
    } else {
      // at expenseitem using form section feature of redux form due which this logic is needed
      dispatch(change(formName, `${formNameForExpenseItem}.value`, selectedOption?.label));
    }
  };

  const handleDateCallback = (value: string | Date) => {
    if (value instanceof Date) {
      value = moment(value).format("YYYY-MM-DD");
    }
    if (!isExpenseItemForm) {
      dispatch(change(formName, `${name}.value`, adminCommonSvc.companyFormatedDate(value, currentUser)));
    } else {
      // at expense item using form section feature of redux form due which this logic is needed
      dispatch(
        change(formName, `${formNameForExpenseItem}.value`, adminCommonSvc.companyFormatedDate(value, currentUser)),
      );
    }
  };

  const disabled = !!(mc.parent_hierarchy && !chosenParentMetadataFieldId);

  switch (mc.input_type) {
    case "select":
      return (
        <Field
          name={`${name}.metadata_field_id`}
          component={WombatSelect}
          {...(inline ? { menuAutoFixed: true } : {})}
          options={mcOptions}
          onChangeCallback={handleCallBack}
          isDisabled={disabled}
          {...baseFieldOptions}
          parentDivId={parentDivId}
          parentDivVariance={parentDivVariance}
        />
      );
    case "text":
      return <Field name={`${name}.value`} component={WombatInput} isDisabled={disabled} {...baseFieldOptions} />;
    case "date":
      return (
        <Field
          name={`${name}.value`}
          component={RenderDatePicker}
          required={!!mc.required}
          disabled={disabled}
          callBack={handleDateCallback}
          isClearable={true}
          {...baseFieldOptions}
        />
      );
    case "number":
      return (
        <Field
          name={`${name}.value`}
          component={WombatInput}
          type="number"
          isDisabled={disabled}
          {...baseFieldOptions}
        />
      );
    default:
      return null;
  }
};

export const MetadataFieldSelector = ({
  formName,
  formSlice,
  modules,
  className,
  inline,
  noContainer,
  parentDivId,
  parentDivVariance,
}: {
  formName: string;
  formSlice?: string;
  modules: string | string[];
  className?: string;
  inline?: boolean;
  noContainer?: boolean;
  parentDivId?: string;
  parentDivVariance?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
}) => {
  const dispatch = useDispatch();
  const metadataSelectorServiceRef = useRef<MetadataSelectorSvc>(new MetadataSelectorSvc(formName, dispatch));
  const metadataSelectorService = metadataSelectorServiceRef.current;
  const [metadataTemplateOptions, setMetadataTemplateOptions] = useState<
    { label?: string; value?: number | string | null }[]
  >([]);
  const metadata = useTypedSelector(
    (state: RootState) => metadataSelectorService.getCurrentMetadata(state, formSlice),
    shallowEqual,
  );
  const metadataTemplateId = useTypedSelector((state: RootState) =>
    metadataSelectorService.getCurrentTemplateId(state, formSlice),
  );
  const metadataConfigurationFields = useTypedSelector(
    (state: RootState) => metadataSelectorService.getCurrentConfigurationFields(state, formSlice),
    shallowEqual,
  );
  const metadataTemplate = useRef<MetadataTemplate>();
  const metadataTemplates = useRef<MetadataTemplate[]>([]);
  const metadataLinksRef = useRef<MetadataLinkAttributes[]>([]);
  const defaultMetadataTemplate = useTypedSelector((state: RootState) =>
    metadataSelectorService.setDefaultTemplate(state, metadataTemplates, formSlice),
  );
  useTypedSelector((state) =>
    metadataSelectorServiceRef.current.setReferenceMetadataLinksAttributes(state, metadataLinksRef, formSlice),
  );
  let name = "metadata_template_id";
  let metadataConfigurationName = "metadataForm.metadata_configurations";
  let metadataLinksName = "metadata_links_attributes";
  let metadataFormName = "metadataForm";
  const isExpenseItemForm = expenseItemCommonSvc.isExpenseItemForm({ modules, formName });

  if (formSlice) {
    if (!isExpenseItemForm) {
      name = [formSlice, name].join(".");
    }
    metadataConfigurationName = [formSlice, metadataConfigurationName].join(".");
    metadataLinksName = [formSlice, metadataLinksName].join(".");
    metadataFormName = [formSlice, metadataFormName].join(".");
  }

  const getTemplates = useCallback(async () => {
    try {
      const res = await MetadataTemplateApis.list({ filter: { status: "ACTIVE", module: modules } });
      metadataTemplates.current = res;
      setMetadataTemplateOptions(MetadataSelectorSvc.createTemplateOptions(res));
      if (res.length === 1) {
        if (defaultMetadataTemplate != "") {
          if (!isExpenseItemForm) {
            metadataSelectorService.updateForm(name, defaultMetadataTemplate);
          } else {
            metadataSelectorService.updateForm(formSlice + "." + name, defaultMetadataTemplate);
          }
        } else {
          if (!isExpenseItemForm) {
            metadataSelectorService.updateForm(name, res[0].id);
          } else {
            metadataSelectorService.updateForm(formSlice + "." + name, res[0].id);
          }
        }
      }
    } catch (err) {
      CreateNotification("Metadata Templates", `Error getting templates due to ${err}`, NotificationType.danger);
    }
  }, [dispatch]);

  const setFields = useCallback(async () => {
    if (
      !metadataTemplate.current ||
      !metadataTemplate.current.metadata_configurations ||
      !metadataTemplate.current.metadata_configuration_template_links_attributes
    ) {
      return;
    }
    const metadataConfigurations = metadataTemplate.current.metadata_configurations;
    const metadataConfigurationTemplateLinks =
      metadataTemplate.current.metadata_configuration_template_links_attributes;
    const mergedMetadataConfigurations = MetadataSelectorSvc.mergeConfigurationsWithTemplateLinks(
      metadataConfigurations,
      metadataConfigurationTemplateLinks,
    );
    const orderedMetadataConfigurations = MetadataSelectorSvc.orderByPriority(mergedMetadataConfigurations);
    try {
      metadataSelectorService.updateForm(
        metadataConfigurationName,
        orderedMetadataConfigurations.reduce(
          (result, mc) => {
            result[mc.field_id] = mc;
            return result;
          },
          {} as { [key: string]: any },
        ),
      );
      metadataSelectorService.updateForm(
        metadataLinksName,
        orderedMetadataConfigurations.reduce((result, mc) => {
          mc.id &&
            result.push(
              find(metadataLinksRef.current, (link) => link.metadata_configuration_id == mc.id) || {
                metadata_configuration_id: mc.id,
              },
            );
          return result;
        }, [] as MetadataLinkAttributes[]),
      );
    } catch (err) {
      CreateNotification("Metadata Fields", `Error getting fields due to ${err}`, NotificationType.danger);
    }
  }, [dispatch]);

  const resetFields = useCallback(() => {
    metadataSelectorService.updateForm(metadataFormName, {
      metadata_configurations: {},
      metadata_configurations_options_hash: {},
    });
  }, []);

  const handleMetadataTemplateId = useCallback(async (metadataTemplateId?: number | "") => {
    if (metadataTemplateId) {
      metadataTemplates.current.length === 0 && (await getTemplates());
      metadataTemplate.current = metadataTemplates.current.find((item) => item.id === metadataTemplateId);
      setFields();
    } else {
      metadataTemplates.current.length === 0 && getTemplates();
      metadataTemplate.current = undefined;
      resetFields();
    }
  }, []);

  useEffect(() => {
    handleMetadataTemplateId(metadataTemplateId);
  }, [metadataTemplateId, metadata]);

  const ParentElement = noContainer ? Col : Container;

  return metadataTemplateOptions.length > 1 ? (
    <ParentElement className={className + ` ${inline ? styles.inline : ""}`}>
      <div className={styles.aMetaDataField}>
        <Field
          name={name}
          label="Additional Coding Fields"
          inline={inline}
          component={WombatSelect}
          options={metadataTemplateOptions}
          menuAutoFixed={inline}
          parentDivId={parentDivId}
          parentDivVariance={parentDivVariance}
        />
      </div>
      {metadataConfigurationFields.map((field_id, index) => {
        return (
          <div className={styles.aMetaDataField}>
            <MetadataConfigurationField
              field_id={field_id}
              index={index}
              key={`${field_id}-${index}`}
              metadataSelectorServiceRef={metadataSelectorServiceRef}
              formSlice={formSlice}
              formName={formName}
              moduleName={modules}
              inline={inline}
              parentDivId={parentDivId}
              parentDivVariance={parentDivVariance}
            />
          </div>
        );
      })}
    </ParentElement>
  ) : null;
};
