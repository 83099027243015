import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { restApiService } from "../../../../../providers/restApi";
import Select, { MenuPosition } from "react-select";
import Styles from "../override.module.css";
import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../../services/general/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "../../../../common/pickers/reduxFormPickers/select/helpers";
import { AxiosResponse } from "axios";
import { WrappedFieldProps } from "redux-form";

interface SponsorPropsType extends WrappedFieldProps {
  className?: string;
  labelClassName?: string;
  label?: string;
  isMulti: boolean;
  tooltip?: string;
  required: boolean;
  menuPosition?: MenuPosition;
  inputId?: string;
  placeholder?: string;
  disabled?: boolean;
  transparent?: boolean;
  originalValue: any;
  instanceId?: string;
}

interface SponsorshipOptionsType {
  id: number;
  name: string | null;
  external_id: string | null;
}

const SponsorshipPicker = ({
  className,
  labelClassName,
  label,
  input,
  meta: { touched, error },
  isMulti,
  tooltip,
  required,
  menuPosition,
  inputId,
  placeholder = "search/select",
  disabled,
  transparent,
  originalValue,
  instanceId = "sponsorship-picker",
}: SponsorPropsType) => {
  const [sponsors, setSponsors] = useState([]);
  const [originalLabel, setOriginalLabel] = useState(null);

  const getSponsors = useCallback(async () => {
    const result: AxiosResponse<SponsorshipOptionsType[]> = await restApiService.get(
      "sponsorships",
      { status: "ACTIVE" },
      null,
      true,
      null,
      true,
    );
    if (result && result.data) {
      setSponsors(parseForSelect(result.data));
    }
  }, []);

  const getCustomConfig = useMemo(() => {
    let componentConfig: { [key: string]: any } = {};
    if (originalValue !== undefined) {
      componentConfig.Input = (props: any) => <PickerComparisonField {...props} originalValue={originalLabel} />;
    }
    if (transparent) {
      componentConfig.DropdownIndicator = () => null;
    }
    return componentConfig;
  }, [!!originalValue, originalLabel, transparent]);

  useEffect(() => {
    getSponsors();
  }, []);
  useEffect(() => {
    if (originalValue && sponsors.length > 0) {
      setOriginalLabel(findSelectedSingle({ value: originalValue }, sponsors)?.label);
    }
  }, [sponsors, originalValue]);

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label className={labelClassName}>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        components={getCustomConfig}
        value={isMulti ? findSelectedMultiple(input, sponsors) : findSelectedSingle(input, sponsors)}
        placeholder={placeholder}
        onChange={isMulti ? (value) => onChange(input, value) : (value) => onChangeSingle(input, value)}
        onBlur={isMulti ? () => onBlur(input, input.value) : () => onBlurSingle(input, input.value)}
        options={sponsors}
        isMulti={isMulti ? true : false}
        isClearable
        classNamePrefix="select"
        menuPosition={menuPosition}
        isDisabled={disabled}
        instanceId={instanceId}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
            ...(transparent ? { backgroundColor: "transparent", border: "none" } : {}),
          }),
        }}
      />
      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default SponsorshipPicker;
