import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderCheckBox } from "../../../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";

export const NotAuthorizedReason52 = () => {
  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.option.not_authorized.${key}`);
  };

  return (
    <>
      <Row>
        <Col sm={{ offset: 2 }}>
          <Row>
            <Field
              name="form.options.OPTION_2_NEITHER_AUTHORIZED_USED"
              component={RenderCheckBox}
              type="checkbox"
              label={t2("never_used")}
            />
          </Row>
        </Col>
      </Row>
      <br />
    </>
  );
};
