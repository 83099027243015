import { RenderField } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const Memo = ({ label, col, isMemoRequired, validations }) => {
  return (
    <Col md={col ? col : "6"}>
      <Field
        id="form.memo"
        name="form.memo"
        component={RenderField}
        type="text"
        label={label}
        required={isMemoRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default Memo;
