import React, { Component, Fragment } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
//import '../invoices.style.css'
import RestApi from "../../../providers/restApi";
import CollapsePanel from "../../../components/panel/collapsePanel";
import PageTitle from "../../../components/page/pageTitle";
import { BreadcrumbTrail } from "../../../components/navigation/navigationLinks";
import * as actionType from "../../../actions/actionTypes";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PaymentLinks from "../../../components/widgets/paymentLinks/paymentLinks";
import { Link } from "react-router-dom";
import Loader from "../../../components/spinners/loader";
import PicturePreview from "../../../components/widgets/fileUpload/picturePreview";
import { Can } from "../../../services/authorization/authorization";
import ChatWindow from "../../../components/chat/chatWindow";
import Panel from "../../../components/panel/panel";
import _ from "lodash";
import { companyDateFormat } from "../../../services/general/dateSvc";
import style from "./Invoice.module.css";
import { formattedAmount } from "../../../services/general/helpers";
import PaymentTable from "../../../components/vp/payments/paymentTable/paymentTable";
import TabSwitcher from "../../../components/widgets/tabSwitch/tabSwitcher";
import CreditMemoTable from "../../../components/vp/creditMemos/creditMemoTable/creditMemoTable";
import ChatBox from "../../../components/vp/chatBox/chatBox";

class VPInvoiceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      payment_links: [],
      assets: [],
      activeTab: "Payments",
    };
    this.restApiService = new RestApi();
  }

  seeAllClicked = () => {
    let url = "";
    // eslint-disable-next-line default-case
    switch (this.state.activeTab) {
      case "Payments":
        url = "/ar/payments";
        break;
      case "Credit Memos":
        url = "/ar/credit_memos";
        break;
    }
    this.props.history.push(url);
  };

  setActiveTab = (activeTab) => {
    this.setState({ activeTab: activeTab });
  };

  tabSwitched = (tab) => {
    this.setActiveTab(tab.name);
  };

  tabSwitches = [
    { name: "Payments", callback: this.tabSwitched },
    { name: "Credit Memos", callback: this.tabSwitched },
  ];

  componentDidMount() {
    this.restApiService
      .get("vendor_invoices/" + this.props.match.params.id)
      .then((result) => {
        if (result.data) {
          this.props.setInvoice(result.data);
          this.setState({
            assets: result.data.assets,
            payment_links: result.data.payment_links,
            loaded: true,
          });
        }
      })
      .catch((error) => {
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning);
        this.setState({ loaded: true });
      });
  }

  getServicePeriodString = () => {
    let returnString = this.props.invoice && this.props.invoice.service_start_date;
    if (!returnString) {
      return "";
    }
    return returnString + " -> " + this.props.invoice.service_end_date;
  };

  isAllowToShow = (poExpenses, field) => {
    if (_.isArray(poExpenses) && poExpenses.length > 0) {
      return poExpenses.some((exp) => exp[field] !== null);
    }
  };

  render() {
    let invoice = this.props.invoice ? this.props.invoice : {};
    let currentUser = this.props.currentUser ? this.props.currentUser : {};
    return (
      <Container fluid={true} className={"pt-4 pb-4"}>
        <BreadcrumbTrail to={"/ar/invoices"} pageName={"Invoices"} title={"Invoice Details"} />
        <Loader loaded={this.state.loaded}>
          <Row className="mt-3">
            <Col md={10} className="d-flex align-items-center">
              <PageTitle title={invoice && invoice.number} status={invoice && invoice.status} />
              {/* <EditButton buttonClasses={"ml-auto"} text={this.props.t("edit.invoice")} link={"/ar/invoices/" + invoice.id + "/edit"} /> */}
            </Col>
            <Col md={2} className="d-flex align-items-center justify-content-end">
              <ChatBox modelDataNumber={invoice.number} modelDataId={invoice.id} modelDataType="Invoice" />
            </Col>
          </Row>
          <Row>
            <Col>
              <hr className="full-border" />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Row>
                <Col sm className="pr-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.customer")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      <Link
                        className="primaryLinkColor"
                        variant="primary"
                        to={"/ar/purchasers/" + (invoice.purchaser && invoice.purchaser.id)}
                      >
                        {invoice.purchaser && invoice.purchaser.name}
                      </Link>
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.invoiceDate")}</dt>
                    {/* companyDateFormat(schedule.last_runtime, currentUser) */}
                    <dd className="col-6 col-sm-7 detailValue">{companyDateFormat(invoice.date, currentUser)}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.dueDate")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{companyDateFormat(invoice.due_date, currentUser)}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.servicePeriod")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {companyDateFormat(invoice.service_start_date, currentUser)}
                      {invoice.service_start_date ? " - " : ""}
                      {companyDateFormat(invoice.service_end_date, currentUser)}
                    </dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.poNumbers")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {invoice.purchase_order && invoice.purchase_order.number}
                    </dd>
                  </dl>
                </Col>
                <Col sm className="pr-sm-0 pl-sm-0">
                  <dl className="row">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.amount")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {formattedAmount(invoice.amount, invoice.currency_code)}
                    </dd>
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.outstanding")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {formattedAmount(invoice.balance_outstanding, invoice.currency_code)}
                    </dd>
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.currencyCode")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{invoice.currency_code}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.submitDate")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">
                      {companyDateFormat(invoice.submit_date, currentUser)}
                    </dd>
                  </dl>
                </Col>
                <Col sm className="pl-sm-0">
                  <dl className="row mb-0">
                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.description")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{invoice.description}</dd>

                    <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.submitter")}</dt>
                    <dd className="col-6 col-sm-7 detailValue">{invoice.submitter && invoice.submitter.name}</dd>

                    {invoice.virtual_card && invoice.virtual_card.id && (
                      <Fragment>
                        <dt className="col-6 col-sm-5 detailKey text-sm-right">Virtual Card</dt>
                        <dd className="col-6 col-sm-7 detailValue">
                          <Can I={"read"} a={"VCards"} passThrough>
                            {(allowed) => {
                              return allowed ? (
                                <Link
                                  className="primaryLinkColor"
                                  variant="primary"
                                  to={"/ar/virtual_cards/" + invoice.virtual_card.id}
                                >
                                  {invoice.virtual_card.card_number_masked}
                                </Link>
                              ) : (
                                <span>{invoice.virtual_card.card_number_masked}</span>
                              );
                            }}
                          </Can>
                        </dd>
                      </Fragment>
                    )}
                  </dl>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={"mt-4"}>
            <Col>
              <Panel
                panelContent={{
                  heading: this.props.t("details.lineItems"),
                  iconClass: "icon icon-line-items",
                }}
                cardBodyStyle={{ padding: "0px" }}
              >
                <Table hover size="sm" className={style.listTable}>
                  <thead>
                    <tr>
                      <th className={style.pl24}>{this.props.t("details.itemName")}</th>
                      <th>{this.props.t("items.qty")}</th>
                      <th>{this.props.t("items.unitPrice")}</th>
                      <th>{this.props.t("details.tax")}</th>
                      <th>{this.props.t("details.totalAmount")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {invoice &&
                      _.isArray(invoice.invoice_items) &&
                      invoice.invoice_items.length > 0 &&
                      invoice.invoice_items.map((item, index) => {
                        return (
                          <tr key={"itemLine_" + index}>
                            <td className={style.pl24}>{item.product_item && item.product_item.name}</td>
                            <td>{item.qty}</td>
                            <td>{item.unit_price && <>{formattedAmount(item.unit_price, invoice.currency_code)}</>}</td>
                            <td>{item.tax && <>{formattedAmount(item.tax, invoice.currency_code)}</>}</td>
                            <td>{item.total && <>{formattedAmount(item.total, invoice.currency_code)}</>}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Panel>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <Panel
                panelContent={{
                  heading: this.props.t("details.expenses"),
                  iconClass: "icon icon-gl",
                }}
                cardBodyStyle={{ padding: "0px" }}
              >
                <Table hover size="sm" className={style.listTable}>
                  <thead>
                    <tr>
                      <th>{this.props.t("details.account")}</th>
                      <th>{this.props.t("details.memo")}</th>
                      <th>
                        {this.isAllowToShow(invoice.debit_entries, "sub_amount") ? (
                          <>{this.props.t("details.subAmount")}</>
                        ) : null}
                      </th>
                      <th>
                        {this.isAllowToShow(invoice.debit_entries, "tax") ? <>{this.props.t("details.tax")}</> : null}
                      </th>
                      <th>{this.props.t("details.totalAmount")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isArray(invoice.debit_entries) &&
                      invoice.debit_entries.length > 0 &&
                      invoice.debit_entries.map((expense, index) => {
                        return !expense.product_item_id ? (
                          <tr key={"debitEntry_" + index}>
                            <td>{expense.account && expense.account.number + " - " + expense.account.name}</td>
                            <td>{expense.memo}</td>
                            <td>
                              {expense.sub_amount ? (
                                <>{formattedAmount(expense.sub_amount, invoice.currency_code)}</>
                              ) : null}
                            </td>
                            <td>{expense.tax ? <>{formattedAmount(expense.tax, invoice.currency_code)}</> : null}</td>

                            {expense.amount ? <td>{formattedAmount(expense.amount, invoice.currency_code)}</td> : null}
                          </tr>
                        ) : null;
                      })}
                  </tbody>
                </Table>
              </Panel>
            </Col>
          </Row>

          <Row>
            <Col>
              <CollapsePanel
                open={true}
                iconClass="icon icon-documents"
                heading={this.props.t("details.documents").toUpperCase()}
              >
                {this.state.assets &&
                  this.state.assets.length > 0 &&
                  this.state.assets.map((image) => (
                    <PicturePreview
                      key={image.id}
                      file={{
                        name: image.asset_file_file_name,
                        preview: image.asset_expiring_thumb_url,
                        id: image.id,
                        url: image.asset_expiring_url,
                      }}
                    />
                  ))}
                {(!this.state.assets || this.state.assets.length < 1) && (
                  <h5 className="text-center mb-0">- {this.props.t("warnings.noAttachments")} -</h5>
                )}
              </CollapsePanel>
            </Col>
          </Row>

          <Row>
            <Col>
              <TabSwitcher tabs={this.tabSwitches}></TabSwitcher>
            </Col>
          </Row>

          <Row>
            <Col>
              <CollapsePanel
                open={true}
                heading={this.props.t("vp.recentlyUpdated")}
                cardBodyStyle={{ padding: "0px" }}
                leftBtn={"All " + this.state.activeTab}
                onClick={this.seeAllClicked}
              >
                {this.state.activeTab === "Payments" && (
                  <PaymentTable filter={{ invoice_id: invoice.id, limit: 5 }}></PaymentTable>
                )}
                {this.state.activeTab === "Credit Memos" && (
                  <CreditMemoTable filter={{ invoice_id: invoice.id, limit: 5 }}></CreditMemoTable>
                )}
              </CollapsePanel>
            </Col>
          </Row>
        </Loader>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    invoice: state.invoice,
    currentUser: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setInvoice: (payload) => {
      dispatch({ type: actionType.SET_INVOICE, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VPInvoiceDetail));
