import React, { ReactNode } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import styles from "./customModal.module.css";

type modalContentPropsType = {
  header: ReactNode | null;
  body: ReactNode | null;
  footer: ReactNode | null;
  headerClass: string | undefined;
  headerTitleClass: string | undefined;
  modalBodyClass: string | undefined;
};

const ModalContent = ({
  header,
  body,
  footer,
  headerClass,
  headerTitleClass,
  modalBodyClass,
}: modalContentPropsType) => {
  return (
    <>
      {header && (
        <Modal.Header className={`${headerClass}`} closeButton>
          <Modal.Title className={`${headerTitleClass}`}>{header}</Modal.Title>
        </Modal.Header>
      )}
      {<Modal.Body className={`${modalBodyClass}`}>{body}</Modal.Body>}
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </>
  );
};

type customModalPropsType = {
  show?: boolean;
  onHide?: () => void;
  header?: ReactNode | null;
  modalSidebar?: ReactNode | null;
  body?: ReactNode | null;
  footer?: ReactNode | null;
  size?: "sm" | "lg" | "xl" | undefined;
  dailog?: boolean;
  backdrop?: "static";
  dialogClass?: string | undefined;
  modalClass?: string | undefined;
  headerClass?: string | undefined;
  headerTitleClass?: string | undefined;
  contentClassName?: string | undefined;
  modalBodyClass?: string | string;
  centered?: boolean;
};
const CustomModalWithSidebar = ({
  show = false,
  onHide = () => {},
  header = null,
  body = null,
  modalSidebar = null,
  footer = null,
  size = "sm",
  dailog = false,
  dialogClass = "",
  modalClass = "",
  headerClass = "",
  headerTitleClass = "",
  contentClassName = "",
  modalBodyClass = "",
  centered = false,
}: customModalPropsType) => {
  if (dailog) {
    return (
      <Modal
        size={size}
        className={`${modalClass}`}
        contentClassName={`${contentClassName}`}
        show={show}
        onHide={() => onHide()}
        backdrop="static"
        centered={centered}
      >
        <Modal.Dialog className={`${dialogClass} p-0`}>
          <ModalContent
            header={header}
            headerClass={headerClass}
            headerTitleClass={headerTitleClass}
            modalBodyClass={modalBodyClass}
            body={body}
            footer={footer}
          />
        </Modal.Dialog>
      </Modal>
    );
  }

  return (
    <Modal
      size={size}
      className={`${modalClass}`}
      contentClassName={`${contentClassName}`}
      show={show}
      onHide={() => onHide()}
      backdrop="static"
      centered={centered}
    >
      <Row>
        <Col md={3} className={styles.modalSideBar}>
          {modalSidebar}
        </Col>
        <Col md={9} className={styles.modalBody}>
          <ModalContent
            headerClass={headerClass}
            headerTitleClass={headerTitleClass}
            header={header}
            body={body}
            modalBodyClass={modalBodyClass}
            footer={footer}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default CustomModalWithSidebar;
