import React, { useEffect, useState, useCallback } from "react";
import PurchasesApis from "../../../../../services/admin/purchases/purchasesApis";
import { Form, Container } from "react-bootstrap";
import { CardsDisputesFormState } from "../../../../../services/admin/cards/disputes/cardDisputes.types";
import { DisputeContactAdd } from "./disputeContactAdd";
import { DisputeDetailsAdd } from "./disputeDetailsAdd";
import { DisputeSelection } from "./disputeSelection";
import { DisputeSummary } from "./disputeSummary";
import { reduxForm, InjectedFormProps, change } from "redux-form";
import { shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "reducers";
import { DISPUTE_STATE } from "services/admin/cards/cardDisputesSvc";
import Style from "./dispute.module.css";

const DisputeForm = ({ initialize, handleSubmit, submitFailed }: InjectedFormProps<CardsDisputesFormState, {}>) => {
  const params = useParams<{ purchase_id: string }>();
  const purchase_id = params.purchase_id;
  const dispatch = useDispatch();
  const currentUser: any = useTypedSelector((state) => state.user, shallowEqual);
  const [step, setStep] = useState<string>(DISPUTE_STATE.SELECT_DISPUTE);

  async function getPurchase() {
    const data = await PurchasesApis.getPurchase(purchase_id);

    if (data) {
      const filterPurchase = {
        id: data.id,
        arn: data.bank_card_transaction?.acquirer_reference_number,
        contact_id: data.contact?.id,
        number: data.number,
        status: data.status,
        merchant: data.merchant,
        amount: data.amount,
        memo: data.memo,
        mcc_description: data.mcc_description,
        first_name: data.contact?.first_name,
        last_name: data.contact?.last_name,
        posted_date: data.transaction_date,
        currency_code: data.currency_code,
        approval_code: data.approval_code,
        card_number_masked: data.virtual_card?.card_number_masked,
        transaction_date: data.bank_card_transaction?.payment_request_date?.substring(0, 10),
        payment_gross_amount: data.bank_card_transaction?.payment_gross_amount,
        duplicate_purchases: data.duplicate_purchases,
      };

      dispatch(change("CardDisputes", "purchase", filterPurchase));
    }
  }

  useEffect(() => {
    initialize({
      processing: false,
      form: {
        dispute: "",
        status: "NEW",
        contact_name: currentUser?.name,
        company_name: currentUser?.company.name,
        email_address: currentUser?.email,
        phone_number: currentUser?.company?.address?.phone,
        address: (
          currentUser?.company?.address?.address1 +
          " " +
          (currentUser?.company?.address?.address2 || "") + // Remove unwanted effect when the value is null then the string "null" is concatenated
          " " +
          (currentUser?.company?.address?.address3 || "")
        ).trim(),
        city: currentUser?.company?.address?.city,
        state: currentUser?.company?.address?.state,
        zip_code: currentUser?.company?.address?.zipcode,
        country: currentUser?.company?.address?.country,
      },
    });
    getPurchase();
  }, []);

  const goToNextStep = useCallback((nextStep: string) => {
    setStep(nextStep);
  }, []);

  return (
    <Form>
      {(() => {
        switch (step) {
          case DISPUTE_STATE.SELECT_DISPUTE:
            return <DisputeSelection goToNextStep={goToNextStep} handleSubmit={handleSubmit}></DisputeSelection>;
          case DISPUTE_STATE.EDIT_CONTACT:
            return <DisputeContactAdd goToNextStep={goToNextStep} handleSubmit={handleSubmit}></DisputeContactAdd>;
          case DISPUTE_STATE.ENTER_VALUES:
            return <DisputeDetailsAdd goToNextStep={goToNextStep} handleSubmit={handleSubmit}></DisputeDetailsAdd>;
          case DISPUTE_STATE.SUMMARY:
            return <DisputeSummary></DisputeSummary>;
          default:
            return <></>;
        }
      })()}
    </Form>
  );
};

const DisputesForm = reduxForm<CardsDisputesFormState, {}>({
  touchOnChange: false,
  touchOnBlur: false,
  destroyOnUnmount: true,
  form: "CardDisputes",
})(DisputeForm);

export { DisputesForm };
