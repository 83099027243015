import React, { memo, useMemo } from "react";
import { useTypedSelector } from "reducers";
import { IDType } from "services/common/types/common.type";
import { IUser, SubsidiaryType } from "services/common/user/userTypes";
import AbstractListPicker from "../abstractListPicker";

export type TSubsidiaryPickerProps = {
  name: string;
  objectName?: string;
  required?: boolean;
  disabled?: boolean;
  activeOnly?: boolean;
  multiple?: boolean;
  label?: string;
  sectionName?: string;
  showInactiveStatus?: boolean;
};

type TApiParams = {
  active: boolean;
  lk?: boolean;
  contact_id?: IDType;
};

const SubsidiaryPicker2 = ({
  name,
  objectName = "subsidiary",
  label,
  disabled = false,
  required = false,
  multiple = false,
  activeOnly = true,
  sectionName,
  showInactiveStatus = true,
}: TSubsidiaryPickerProps) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const apiName = useMemo(() => {
    if (sectionName === "poRequest") {
      return "subsidiaries.lk";
    }
    return "subsidiaries";
  }, [sectionName]);

  const params = useMemo(() => {
    const newParams: TApiParams = { active: activeOnly };

    if (sectionName === "poRequest") {
      newParams.lk = true;

      if (currentUser?.company?.purchase_order?.use_user_level_subsidiaries) {
        newParams.contact_id = currentUser.contact.id;
      }
    }

    return newParams;
  }, [sectionName, currentUser, activeOnly]);

  return (
    <AbstractListPicker<SubsidiaryType>
      name={name}
      objectName={objectName}
      label={label}
      required={required}
      disabled={disabled}
      multiple={multiple}
      params={params}
      fetchUrl={apiName}
      showInactiveStatus={showInactiveStatus}
    />
  );
};

export default memo(SubsidiaryPicker2);
