import { Field, getFormValues } from "redux-form";
import { Button, Col, Row } from "react-bootstrap";
import { RenderField } from "../../../../../../components/forms/bootstrapFields";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import RestApi from "../../../../../../providers/restApi";
import { useDispatch, useSelector } from "react-redux";
import { setCambridgeAccounts, INTEGRATION_FORM_NAME } from "../../../../../../reducers/admin/integrationsReducer";
import _ from "lodash";

const CambridgeCAMBRIDGE = () => {
  const restApiServerice = new RestApi();
  const dispatch = useDispatch();

  const cambridgeAccounts = useSelector((state) => state.integrationsRed.cambridgeAccounts);

  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));

  const getCambridgeAccounts = async (integration_setting_id) => {
    try {
      const response = await restApiServerice.get("cambridge/accounts", {
        integration_setting_id: integration_setting_id,
      });

      if (response.data) dispatch(setCambridgeAccounts(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <Col lg="6">
          <Field name="form.cambridge.user_id" label="User Id" component={RenderField} type="text" id="User" />
        </Col>
        <Col lg="6">
          <Field name="form.cambridge.id" label="Id" component={RenderField} type="text" id="Id" />
        </Col>
        <Col lg="6">
          <Field name="form.key" label="Key" component={RenderField} type="text" id="Key" />
        </Col>
        <Col lg="6">
          <Field
            name="form.cambridge.fee_account_id"
            label="Fee Account Id:"
            title="Fee Account Id"
            autocomplete="off"
            component={RenderField}
            type="text"
            id="fee_account_id"
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.cambridge.fee_account_method"
            label="Fee Account Method (W=Wire, E=EFT):"
            title="Fee Account Method"
            autocomplete="off"
            component={RenderField}
            type="text"
            id="fee_account_id"
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.cambridge.fee_account_currency"
            label="Fee Account Currency (3 letters):"
            title="Fee Account Currency (3 letters)"
            autocomplete="off"
            component={RenderField}
            type="text"
            id="fee_account_id"
          />
        </Col>

        {form.id && (
          <Col lg="12">
            <Row>
              <Col lg="12" className="mb-3">
                <Button type="button" onClick={() => getCambridgeAccounts(form.id)}>
                  Verify Credentials and Get Accounts
                </Button>
                <br />
                <span className="text-secondary">Must save first</span>
              </Col>
            </Row>
            <Row>
              {_.isArray(cambridgeAccounts) &&
                cambridgeAccounts.length > 0 &&
                cambridgeAccounts.map((field, index) => (
                  <Col sm="12" key={index}>
                    <h4>{field.items.text}:</h4>
                    <hr />
                    <Row>
                      {field &&
                        field.items &&
                        _.isArray(field.items.children) &&
                        field.items.children.length > 0 &&
                        field.items.children.map((account, index) => (
                          <Col sm="3" key={index}>
                            <p class="mb-0">
                              Id: <span class="text-primary">{account.id}</span>
                            </p>
                            <p class="mb-0">
                              Bank Name: <span class="text-primary">{account.bankName}</span>
                            </p>
                            <p class="mb-0">
                              Currency: <span class="text-primary">{account.currency}</span>
                            </p>
                            <p class="mb-0">
                              Method:{" "}
                              <span class="text-primary">
                                {account.method.text}({account.method.id})
                              </span>
                            </p>
                            <p class="mb-0">
                              Preferred: <span class="text-primary">{account.preferred}</span>
                            </p>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                ))}
            </Row>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export { CambridgeCAMBRIDGE };
