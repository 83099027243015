import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Mandatory } from "../../../../forms/bootstrapFields";
import Select from "react-select";
import { findSelectedSingle, onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";

import { countries } from "../../../../app.svc.Lookup";
import _ from "lodash";

export type CountryOptionType = {
  flag?: string;
  label?: string;
  value?: string;
};

type InputType = {
  value: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
};

type CountryPickerPropsType = {
  label?: string;
  tooltip?: string;
  required?: boolean;
  placeholder?: string;
  callBack?: (selectedCountry: string) => void;
  input: InputType;
  meta: {
    touched?: boolean;
    error?: string;
  };
  defaultValue?: string;
  disabled?: boolean;
  countriesList?: CountryOptionType[];
  isCheckPaymentMethod?: boolean;
};

type ValueProps = {
  label: string;
  name: string;
  value: any;
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const CountryPicker = ({
  label,
  input,
  meta: { touched, error },
  tooltip,
  required,
  placeholder,
  defaultValue,
  disabled,
  callBack,
  countriesList,
  isCheckPaymentMethod,
}: CountryPickerPropsType) => {
  const getCountiesWithFlag = () => {
    const countriesData = _.isArray(countriesList) && countriesList.length > 0 ? countriesList : countries;

    return countriesData.map((country: CountryOptionType) => {
      let flag = null;
      if (country.flag) {
        flag = (
          <img
            src={require(`../../../../../assets/flags/${country.flag.toLowerCase()}.svg`)}
            height="30px"
            width="30px"
            style={{ padding: 5 }}
            alt="flag"
          />
        );
      }
      return {
        value: country.value, // using item.label to search country by name
        name: country.label,
        label: (
          <div className="text-nowrap">
            {flag}
            {country.label}
          </div>
        ),
      };
    });
  };

  const onChangeCountry = (input: InputType, value: ValueProps) => {
    onChangeSingle(input, value);
    if (callBack) {
      callBack(value.value);
    }
  };

  const getDefaultCountry = () => {
    if (defaultValue !== undefined) {
      input.onChange(defaultValue);
    }
  };

  useEffect(() => {
    getDefaultCountry();
  }, [defaultValue]);

  const countriesData = getCountiesWithFlag();
  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        value={findSelectedSingle(input, countriesData)}
        placeholder={placeholder}
        onChange={(value) => onChangeCountry(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={countriesData}
        required={required ? required : false}
        isDisabled={disabled}
        classNamePrefix="select"
      />
    </Form.Group>
  );
};

export default CountryPicker;
