import { AxiosResponse } from "axios";
import useIsMounted from "components/common/hooks/useIsMounted";
import _ from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { SubsidiaryDetailType } from "services/admin/subsidiaries/types";
import { restApiService } from "../../../../../providers/restApi";
import { IDType } from "../../../../../services/common/types/common.type";
import {
  compare,
  findSelectedMultiple,
  findSelectedSingle,
  isDefined,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
} from "../../../../../services/general/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";

type SubsidiaryOptionsType = {
  id?: IDType;
  name?: string;
  currency_code?: string;
  exchange_rate?: number;
  status?: string;
};

type SelectedTypes = {
  value: IDType;
  label: string;
};

type InputType = {
  value: number;
  onChange: (values: IDType | null) => void;
  onBlur: (values: number) => void;
};

export type SubsidiaryPickerPropsType = {
  className?: string | undefined;
  label?: string | undefined;
  isMulti?: boolean;
  tooltip?: string | undefined;
  required?: boolean;
  placeholder?: string | undefined;
  input: InputType;
  instanceId?: string;
  disabled?: boolean;
  callBack?: (selected: SelectedTypes) => void;
  isClearable?: boolean;
  modelData?: any;
  modelName?: "expense_item";
  formName?: string;
  formLocationStr?: string;
};

const SubsidiaryPicker = ({
  label,
  input,
  required,
  isMulti,
  tooltip,
  callBack,
  disabled,
  isClearable,
  instanceId = "subsidiary-picker",
  modelData,
  modelName,
  formName,
  formLocationStr,
}: SubsidiaryPickerPropsType) => {
  const [subsidiaries, setSubsidiaries] = useState([]);
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  const parseOptions = (options: any) => {
    return options
      .map((option: any) => {
        return {
          value: option.id,
          label: option.name,
          ...option,
        };
      })
      .sort(compare);
  };

  const initSubsidiaryObj = (subsidiaryObj: any | null) => {
    if (isDefined(modelData) && isDefined(subsidiaryObj)) {
      // formName && dispatch(change(formName, formLocationStr + ".subsidiary", subsidiaryObj));
      modelData.subsidiary = subsidiaryObj;
    }
  };

  const setValue = (selected: any | null) => {
    if (selected && selected.value) {
      input.onChange(selected.value);
      initSubsidiaryObj(selected);
      callBack && callBack(selected);
    } else {
      input.onChange(null);
      initSubsidiaryObj(selected);
    }
  };

  const mergeInactivesubsidiary = async (subsidiaries: any[]) => {
    if (input.value > 0) {
      var subsidiary = subsidiaries.filter((sub: any) => sub.id === input.value);
      if (subsidiary.length === 0 && _.isPlainObject(modelData) && modelName === "expense_item") {
        setValue(null);
      } else if (subsidiary.length === 0) {
        const response: AxiosResponse<SubsidiaryDetailType> = await restApiService.get("subsidiaries/" + input.value, {
          active: true,
        });
        if (response?.data) {
          if (Array.isArray(subsidiaries)) {
            const resSubsidiary = { ...response.data, label: response?.data.name, value: response.data.id };
            subsidiaries.push(resSubsidiary);
            initSubsidiaryObj(resSubsidiary);
          }
        }
      } else {
        initSubsidiaryObj(subsidiary[0]);
      }
    }
    return subsidiaries;
  };

  const getSubsidiaries = useCallback(async () => {
    const params: Record<string, any> = { active: true };

    let apiName = "subsidiaries";

    if (modelName === "expense_item" && modelData?.employee_id) {
      params.contact_id = modelData.employee_id;
    }

    if (modelName === "expense_item") {
      params.lk = true;
      apiName = "subsidiaries.lk";
    }

    const result: AxiosResponse<SubsidiaryOptionsType[]> = await restApiService.get(
      apiName,
      params,
      null,
      true,
      null,
      true,
    );
    if (result && result.data && isMounted.current) {
      let parsedOptions = parseOptions(result.data);
      parsedOptions = await mergeInactivesubsidiary(parsedOptions);
      if (isMounted.current) {
        setSubsidiaries(parsedOptions);
      }
    }
  }, []);

  const onChangeSubsidiaries = (input: any, selected: any) => {
    onChange(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  const onChangeSubsidiary = (input: InputType, selected: SelectedTypes) => {
    onChangeSingle(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  useEffect(() => {
    getSubsidiaries();
  }, []);

  useEffect(() => {
    if (modelName === "expense_item") {
      getSubsidiaries();
    }
  }, [modelData?.employee_id]);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          isMulti={isMulti ? true : false}
          required={false}
          options={subsidiaries}
          isDisabled={disabled}
          value={isMulti ? findSelectedMultiple(input, subsidiaries) : findSelectedSingle(input, subsidiaries)}
          onChange={
            isMulti
              ? (selected: SelectedTypes[]) => onChangeSubsidiaries(input, selected)
              : (selected: SelectedTypes) => onChangeSubsidiary(input, selected)
          }
          onBlur={isMulti ? () => onBlur(input, input.value) : () => onBlurSingle(input, input.value)}
          isClearable={isClearable}
          instanceId={instanceId} // don't remove or change used in automation testing
        ></Select>
      </Form.Group>
    </>
  );
};
export default memo(SubsidiaryPicker);
