import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import BankAddressLine from "../bankAddressLine";
import AchPaymentMethod from "./ach";
import { required } from "../../../../services/validations/reduxFormValidation";
import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import _ from "lodash";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

const FedWirePaymentMethod = ({ modelName, modelData }: { modelName?: string; modelData?: any }) => {
  const { t } = useTranslation();
  const [currencyCode, setCurrencyCode] = useState("");
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);

  useEffect(() => {
    if (modelData.currency_code === "USD") {
      setCurrencyCode(modelData.currency_code);
    }
  }, [modelData, modelData.currency_code]);

  return (
    <>
      <Col sm="6">
        <Field
          name={`${modelName}.currency_code`}
          component={CurrencyCodePicker}
          FieldClassName="formField"
          placeholder={"Search/Select"}
          label={t("admin.pages.vendor.currencyCode")}
          callBack={({ value }: { value: string }) => setCurrencyCode(value)}
          required
          validate={[required]}
          currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
        />
      </Col>
      {currencyCode === "USD" ? (
        <AchPaymentMethod modelName={`${modelName}`} />
      ) : (
        <BankAddressLine modelName={`${modelName}`} />
      )}
    </>
  );
};
export default FedWirePaymentMethod;
