import React, { ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { Field } from "redux-form";
import { PaymentFieldType } from "../types";
import { RenderField } from "../../../forms/bootstrapFields";
import { CHANGE_PAYMENT_METHOD_CITY } from "../../../../actions/actionTypes";

type CityPropsType = PaymentFieldType;

const City = ({ validations, required, label }: CityPropsType) => {
  const dispatch = useDispatch();

  const changeCity: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({ type: CHANGE_PAYMENT_METHOD_CITY, payload: e.target.value });
  };

  return (
    <Field
      name="city"
      label={label}
      component={RenderField}
      required={required}
      validate={validations}
      onChange={changeCity}
    />
  );
};

export default City;
