import React from "react";
import DropdownFilter from "../reportFilters/dropdown";

const TransactionsSyncError = ({ scheduled }: { scheduled?: boolean }) => {
  const activitable_type_filter_options = [
    { value: "", label: "ALL" },
    { value: "ExpenseReport", label: "EXPENSE REPORT" },
    { value: "Invoice", label: "INVOICE" },
    { value: "PurchaseOrder", label: "PURCHASE ORDER" },
    { value: "Payment", label: "PAYMENT" },
    { value: "CreditMemo", label: "CREDIT MEMO" },
    { value: "Purchase", label: "PURCHASE" },
    { value: "Vendor", label: "VENDOR" },
    { value: "Receipt", label: "RECEIPT" },
  ];

  return (
    <div>
      <DropdownFilter name={"Sync Object Type"} code={"activitable_type"} options={activitable_type_filter_options} />
    </div>
  );
};

export default TransactionsSyncError;
