import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./../../pages.style.css";
import RestApi from "./../../../providers/restApi";
import { BreadcrumbTrail } from "./../../../components/navigation/navigationLinks";
import PageTitle from "./../../../components/page/pageTitle";
import { Can } from "./../../../services/authorization/authorization";
import PaymentMethodLinks from "./../../../components/widgets/paymentMethodLinks/paymentMethodLinks";
import ChatWindow from "./../../../components/chat/chatWindow";
import CollapsePanel from "./../../../components/panel/collapsePanel";
import TabSwitcher from "./../../../components/widgets/tabSwitch/tabSwitcher";
import InvoiceTable from "./../../../components/vp/invoices/invoiceTable/invoiceTable";
import PaymentTable from "./../../../components/vp/payments/paymentTable/paymentTable";
import AccrualTable from "../../../components/vp/accruals/accrualTable/accrualTable";
import CreditMemoTable from "../../../components/vp/creditMemos/creditMemoTable/creditMemoTable";
import PurchaseOrderTable from "../../../components/vp/purchaseOrders/purchaseOrderTable/purchaseOrderTable";
import Loader from "./../../../components/spinners/loader";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../../components/common/hooks/useIsMounted";

const restApiService: RestApi = new RestApi();

const CustomerDetails = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isMounted = useIsMounted();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("Invoices");
  const [purchaser, setPurchaser] = useState<any>({});

  const tabSwitched = (tab: { name: string }) => {
    setActiveTab(tab.name);
  };

  const tabSwitches = [
    { name: "Invoices", callback: tabSwitched },
    { name: "Payments", callback: tabSwitched },
    { name: "Credit Memos", callback: tabSwitched },
    { name: "Purchase Orders", callback: tabSwitched },
    { name: "Accruals", callback: tabSwitched },
  ];

  const seeAllClicked = () => {
    let url = "";
    switch (activeTab) {
      case "Invoices":
        url = "/ar/invoices";
        break;
      case "Payments":
        url = "/ar/payments";
        break;
      case "Credit Memos":
        url = "/ar/credit_memos";
        break;
      case "Purchase Orders":
        url = "/ar/purchase_orders";
        break;
      case "Accruals":
        url = "/ar/accrual_requests";
        break;
    }
    history.push(url);
  };

  const getPurcheser = async () => {
    try {
      const response = await restApiService.get("purchasers/" + id);
      if (isMounted) {
        setPurchaser(response.data);
        setLoaded(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted) {
      getPurcheser();
    }
  }, []);

  return (
    <>
      <Container fluid={true} className={"pt-4 pb-4"}>
        <BreadcrumbTrail
          to={"/ar/purchasers"}
          pageName={t("breadcrumbs.customers")}
          title={t("breadcrumbs.customerDetails")}
        />
        <Loader loaded={loaded}>
          <Row className="mt-3">
            <Col className="d-flex align-items-center">
              <PageTitle title={purchaser.name} status={purchaser.vendor_detail && purchaser.vendor_detail.status} />
            </Col>
          </Row>
          <hr className="full-border" />

          <Row className="mt-3">
            <Col sm className="pr-sm-0">
              <dl className="row">
                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.customerId")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{purchaser.id}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.ein")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{purchaser.ein}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("details.terms")}</dt>
                <dd className="col-6 col-sm-7 detailValue">
                  {purchaser.vendor_detail && purchaser.vendor_detail.terms}
                </dd>
              </dl>
            </Col>

            <Col sm className="pr-sm-0 pl-sm-0">
              <dl className="row">
                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.address")}</dt>
                <dd className="col-6 col-sm-7 detailValue">
                  {purchaser.address1}
                  {purchaser.address2 && <br />}
                  {purchaser.address2}
                  <br />
                  {purchaser.city} {purchaser.state} {purchaser.zipcode}
                </dd>
              </dl>
            </Col>

            <Col sm className="pl-sm-0">
              <dl className="row mb-0">
                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.phone")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{purchaser.phone}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{t("address.email")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{purchaser.email}</dd>
              </dl>
            </Col>
          </Row>

          <Can I={"edit"} a={"Company"}>
            <Row>
              <Col>
                <PaymentMethodLinks purchaser={purchaser} />
              </Col>
            </Row>
          </Can>

          <TabSwitcher tabs={tabSwitches}></TabSwitcher>

          <CollapsePanel
            open={true}
            heading={"RECENTLY UPDATED"}
            cardBodyStyle={{ padding: "0px" }}
            leftBtn={"All " + activeTab}
            onClick={seeAllClicked}
          >
            {activeTab === "Invoices" && <InvoiceTable filter={{ purchaser_id: id, limit: 5 }}></InvoiceTable>}
            {activeTab === "Payments" && <PaymentTable filter={{ purchaser_id: id, limit: 5 }}></PaymentTable>}
            {activeTab === "Accruals" && (
              <AccrualTable
                filter={{
                  purchaser_id: id,
                  limit: 5,
                  active: true,
                }}
              ></AccrualTable>
            )}
            {activeTab === "Credit Memos" && (
              <CreditMemoTable filter={{ purchaser_id: id, limit: 5 }}></CreditMemoTable>
            )}
            {activeTab === "Purchase Orders" && (
              <PurchaseOrderTable filter={{ purchaser_id: id, limit: 5 }}></PurchaseOrderTable>
            )}
          </CollapsePanel>

          <ChatWindow
            title={"Messages"}
            inputHint={"Message " + purchaser.name}
            activityId={purchaser && purchaser.vendor_detail && purchaser.vendor_detail.id}
            activityType="Vendor"
            overrideGetId={purchaser.id}
          />
        </Loader>
      </Container>
    </>
  );
};

export default CustomerDetails;
