import { Field } from "redux-form";
import { Col, Row } from "react-bootstrap";
import { RenderCheckBox, RenderField } from "../../../../../../components/forms/bootstrapFields";

const IntegrationCanEft = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <Col lg="12">
          <label>EFT Payment Info</label>
          <small className="mt-1">(to generate etf file)</small>
        </Col>
      </Row>
      <Row className="m-0">
        <Col lg="6">
          <Field
            name="form.service_agreement"
            label={"Svc Agrt/Client Number"}
            component={RenderField}
            placeholder="Service Agreement"
            type="text"
            id="service_agreement"
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.destination_data_center"
            label={"DESTINATION DATA CENTER"}
            component={RenderField}
            placeholder="destination_data_center"
            type="text"
            id="destination_data_center"
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.direct_clearer_id"
            label={"Direct Clearer ID</label> (10 digits, RBC required)"}
            component={RenderField}
            placeholder="Direct Clearer ID"
            type="text"
            id="direct_clearer_id"
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.edi_email_notification"
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    "<label>Email Notification</label> <small>Group Alias notification when file generated</small>",
                }}
              />
            }
            component={RenderField}
            placeholder="edi_email_notification"
            type="text"
            id="edi_email_notification"
          />
        </Col>
        <Col lg="6">
          <Field
            name="form.include_file_header"
            label={"Include File Header Record</label> (RBC required, default to false)"}
            component={RenderCheckBox}
            placeholder="edi_email_notification"
            type="checkbox"
            id="include_file_header"
          />
        </Col>
      </Row>
    </Col>
  );
};

export default IntegrationCanEft;
