import { Col, Row } from "react-bootstrap";
import CheckNumberSeq from "../../components/fields/checkPaymentFields/checkNumberSeq";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";

const IntegrationServiceCheckPrint = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>
      <Row className="m-0">
        <Col className="12">Check Payment Info</Col>
      </Row>
      <Row className="m-0">
        <CheckNumberSeq />
      </Row>
    </Col>
  );
};

export { IntegrationServiceCheckPrint };
