import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField, RenderSelect } from "../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import BankAddressLine from "../bankAddressLine";
import { required } from "../../../../services/validations/reduxFormValidation";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";
import { countries, postGridCheckCountries } from "components/app.svc.Lookup";

const CheckPaymentMethod = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const hasPostGridCheck = currentUser?.company?.has_post_grid_check;

  const mailTypeOptions = [
    { value: null, label: "" },
    { value: "usps_first_class", label: "Normal" },
  ];

  if (!hasPostGridCheck) {
    mailTypeOptions.push({ value: "ups_next_day_air", label: "Expedited - extra fee will apply" });
  }

  return (
    <>
      <Col sm="6">
        <Field
          name={`${modelName}.mail_type`}
          component={RenderSelect}
          options={mailTypeOptions}
          FieldClassName="formField"
          placeholder={"Search/Select"}
          required
          validate={[required]}
          label={t("common.paymentMethod.mailType")}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          required
          validate={[required]}
          maxLength={40}
          label={t("common.paymentMethod.makeCheckOutTo")}
          className={`w-100`}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.address_attributes.address1`}
          component={RenderField}
          type="text"
          required
          validate={[required]}
          label={t("common.paymentMethod.address1")}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.address_attributes.address2`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.address2")}
        />
      </Col>
      <BankAddressLine
        modelName={`${modelName}`}
        isRequired={true}
        hideAddressLine={true}
        isStateRequired={true}
        defaultCountry="USA"
        countriesList={hasPostGridCheck ? postGridCheckCountries : countries}
        isCountryDisable={!hasPostGridCheck}
      />
    </>
  );
};

export default CheckPaymentMethod;
