import _, { isArray } from "lodash";
import {
  DepartmentLinkType,
  DeptManagersLinkType,
  ManagerFieldLink,
  ManagersLinkType,
  SubsidiaryDepartmentType,
  SubsidiaryDetailType,
  SubsidiaryLink,
  SubsidiaryManagersLinkType
} from "./types";

export const transformAllSubsidiaryLinksFromApi = (subsidiary: any) => {
  if (!subsidiary || !subsidiary.subsidiary_links_v2) {
    return;
  }

  //we want to save the old links in a seperate field to reference when we go to SAVE
  subsidiary.old_subsidiary_links_v2 = JSON.parse(JSON.stringify(subsidiary.subsidiary_links_v2));

  subsidiary.subsidiary_links_v2.forEach((subsidiary_link: SubsidiaryLink) => {
    let newKey = "subsidiary_link_";
    //@ts-ignore
    newKey +=
      subsidiary_link.linkable_type == "MetadataField"
        ? subsidiary_link.metadata_field_id
        : camelCaseToSnakeCase(subsidiary_link.linkable_type);
    //there can be multiple links for each object type
    if (!subsidiary[newKey]) {
      subsidiary[newKey] = [];
    }
    unSpreadManagerFieldLinks(subsidiary_link);
    if (subsidiary_link.linkable_type == "MetadataField") {
      //@ts-ignore
      subsidiary_link.linkable_id = {
        value: subsidiary_link.linkable_id,
      };
    }
    subsidiary[newKey].push(subsidiary_link);
  });
};

const unSpreadManagerFieldLinks = (subsidiary_link: any) => {
  // Use a temporary storage to group by 'level'
  let finalManagerFieldLinks = [];
  let tempStorage: any = {};

  subsidiary_link.manager_field_links.forEach((link: ManagerFieldLink) => {
    // Check if we already have an entry for this level
    if (!tempStorage[link.level]) {
      // If not, create an entry with an empty array for manager_ids
      tempStorage[link.level] = {
        level: link.level,
        manager_id: [],
      };
    }
    // Add the manager_id to the corresponding level's array
    tempStorage[link.level].manager_id.push(link.manager_id);
  });

  for (const key in tempStorage) {
    finalManagerFieldLinks.push(tempStorage[key]);
  }

  subsidiary_link.manager_field_links = finalManagerFieldLinks;
};

/**
 * All of our subsidiary links will be prefixed with subsidiary_link_, take those fields and put them in the final subsidiary_links_attributes_v2 array.
 * This allows us to seperate all the different types of links (projects, metadata, etc) in the front end but combine them back to 1 array for the backend.
 * @param subsidiary
 */
export const transformAllSubsidiaryLinksToApi = (subsidiary: any) => {
  if (!subsidiary) {
    return;
  }

  subsidiary.subsidiary_links_attributes_v2 = [];
  for (const [key, value] of Object.entries(subsidiary)) {
    if (key.startsWith("subsidiary_link_")) {
      let copiedLinks: SubsidiaryLink[] = Object.assign([], subsidiary[key]);

      copiedLinks.forEach((link: any) => {
        if (link.linkable_type == "MetadataField") {
          //for metadataConfiguration the linkable_id field is an object, we need to transform it back to what the api wants
          // {
          //   linkable_type: MetadataField
          //   linkable_id:{
          //     label: "Green",
          //     value: 13,
          //     metadata_configuration_id: 21
          //   }
          // }
          // becomes:
          // {
          //   linkable_type: MetadataField,
          //   linkable_id: 13,
          // }
          //link.metadata_field_id = link.linkable_id.value;
          //link.linkable_id = link.linkable_id.metadata_configuration_id
          link.linkable_id = link.linkable_id.value;
        }
      });
      //also fix manager links
      spreadManagerFieldLinks(copiedLinks, subsidiary.old_subsidiary_links_v2);
      subsidiary.subsidiary_links_attributes_v2.push(...copiedLinks);
    }
  }
};

/**
 * The redux multi picker puts manager_ids as an array of ids called manager_id. We need to have an individual manager_field_link for each id in this array on the final object
 * This will also handle the deletion of manager links
 *
 * @param objectLinks
 */
// BEFORE
// {
//   linkable_id: 5,
//   linkable_type: "Project",
//   manager_field_links: [{
//     level: 1,
//     manager_id : [2,44,66],
//   }]
// }
// AFTER:
// {
//   linkable_id: 5,
//   linkable_type: "Project",
//   manager_field_links: [{
//     level: 1,
//     manager_id : 2,
//   },{
//     level: 1,
//     manager_id : 44,
//   },{
//     level: 1,
//     manager_id : 66,
//   }]
// }
const spreadManagerFieldLinks = (objectLinks: SubsidiaryLink[], oldSubsidiaryLinks: any): void => {
  objectLinks.forEach((link) => {
    // if we already have manager links we need to update or delete them
    let oldLink = oldSubsidiaryLinks ? oldSubsidiaryLinks.find((item: any) => item.id === link.id) : null;
    //we stringify as object.assign was still maintaining references
    let newManagerFieldLinks: ManagerFieldLink[] = oldLink
      ? JSON.parse(JSON.stringify(oldLink.manager_field_links))
      : [];
    //for our old manager_field_links we set them all to be deleted. This makes the logic after this easier
    newManagerFieldLinks = newManagerFieldLinks.map((manager_field_link: any) => {
      manager_field_link._destroy = 1;
      return manager_field_link;
    });

    if (link.manager_field_links) {
      //loop over our field links that are currently in memory (on the page)
      link.manager_field_links.forEach((managerFieldLink) => {
        if (managerFieldLink.manager_id) {
          //@ts-ignore
          managerFieldLink.manager_id.forEach((managerId: any) => {
            //find this manager in the newManagerFieldLinks, this is to handle deletion of old links
            let matchedField = newManagerFieldLinks.find((item: any) => item.manager_id === managerId);
            if (matchedField) {
              //if this field is not marked for destruction AND we have an old field, we delete the _destory that we set above
              if (!managerFieldLink._destroy) {
                delete matchedField._destroy;
              }
              //if the level was updated
              matchedField.level = managerFieldLink.level;
            } else {
              newManagerFieldLinks.push({ manager_id: managerId, level: managerFieldLink.level });
            }
          });
        }
      });

      // Replace the original manager_field_links with the flattened one
      link.manager_field_links_attributes = newManagerFieldLinks;
    }
  });
};

/**
 * converts a rails model type to snake_case
 * PurchaseOrder becomes purchase_order
 * @param str
 * @returns
 */
const camelCaseToSnakeCase = (str: string) => {
  // Insert a space before capital letters
  const spaced = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  // Use Lodash to convert to snake_case
  return _.snakeCase(spaced);
};

// Link location manager with subsidiary
export const getSubsidiaryManagerLocationLink = (subsidiary: SubsidiaryDetailType) => {
  let manipulatedLocationLinks: any = [];

  subsidiary?.subsidiary_manager_location_links_attributes?.map((locationLink) => {
    const managersLinks = locationLink?.managersLink;
    if (isArray(managersLinks) && managersLinks.length > 0) {
      managersLinks.map(({ id, level, manager_id, _destroy }) => {
        const obj: ManagersLinkType = {
          id: id,
          location_id: locationLink.location_id,
          level,
          manager_id,
        };

        if (_destroy) {
          obj._destroy = _destroy;
        }

        if (locationLink._destroy) {
          obj._destroy = locationLink._destroy;
        }
        manipulatedLocationLinks.push(obj);
      });
    }
  });

  return manipulatedLocationLinks;
};

// Function to transform a department link and its managers into a desired structure
const transformDepartmentLink = (departmentLink: DepartmentLinkType) => {
  const manipulatedLinks: DeptManagersLinkType[] = [];
  departmentLink.managersLink?.forEach(({ existingLinks, level, manager_id, _destroy }: ManagersLinkType) => {
    if (existingLinks) {
      // Iterate through a list of existing department links and managers.
      existingLinks.forEach((link: SubsidiaryDepartmentType) => {
        // Create a simplified object representation of the department link and manager
        // to delete manager or entire department (with managers) by manupilating critical json to simple objects
        const deptManagerObj = {
          id: link.id,
          department_id: departmentLink.department_id,
          manager_id: link.manager_id,
          level: level,
          // Determine whether this object should be marked for deletion based on conditions:
          // 1. If the entire department object is being deleted with its managers.
          // 2. If the entire manager row is being deleted with its associated level.
          // 3. If a single manager is being deleted.
          _destroy: departmentLink._destroy || _destroy || link._destroy,
        };
        // Add the simplified object to the manipulatedLinks array
        manipulatedLinks.push(deptManagerObj);
      });

      // Add new managers to an existing department if specified.
      if (Array.isArray(manager_id) && manager_id.length > 0) {
        // Iterate through the array of new manager IDs.
        manager_id.forEach((managerId) => {
          // Check if the manager with the given ID doesn't already exist in the existing links.
          if (!existingLinks.some((link: SubsidiaryDepartmentType) => link.manager_id === managerId)) {
            // If the manager does not exist in the existing links, it is considered a new entry.

            // Create a new department link object for the manager being added.
            const deptManagerObj = {
              department_id: departmentLink.department_id,
              manager_id: managerId,
              level: level,
            };

            // Add the new link object to the manipulatedLinks array.
            manipulatedLinks.push(deptManagerObj);
          }
        });
      }
    } else {
      //Add new department with managers
      if (Array.isArray(manager_id) && manager_id.length > 0) {
        // Iterate through the array of manager IDs to create separate entry of new department
        manager_id.forEach((managerId) => {
          // Create a new department link object for the manager being added to the department.
          const deptManagerObj = {
            department_id: departmentLink.department_id,
            manager_id: managerId,
            level: level,
            // Determine whether this object should be marked for deletion based on conditions:
            // 1. If the entire department object is being deleted with its managers.
            // 2. If the entire manager row is being deleted with its associated level.
            _destroy: departmentLink._destroy || _destroy,
          };

          // Add the new department link object to the manipulatedLinks array.
          manipulatedLinks.push(deptManagerObj);
        });
      }
    }
  });

  return manipulatedLinks;
};

// Main function to process subsidiary links and attributes
export const getSubsidiaryLinksAttributes = (subsidiary: SubsidiaryDetailType) => {
  let manipulatedDepartmentLinks: any = [];

  subsidiary?.subsidiary_links_attributes?.forEach((departmentLink) => {
    const departmentLinks = transformDepartmentLink(departmentLink);
    manipulatedDepartmentLinks = [...manipulatedDepartmentLinks, ...departmentLinks];
  });
  return manipulatedDepartmentLinks;
};

// ---------------------------------------------------------------------* New subsidiary section common logic *-------------------------------------------------------------------------

export const manipulateLinks = (links: any) => {
  let manipulatedLocationLinks: any = [];
  if (Array.isArray(links) && links.length > 0) {
    links.map((link: any) => {
      if (link.managers && link.managers.length > 0) {
        link.managers?.map(({ line_id, id, _destroy }: any) => {
          const obj: SubsidiaryManagersLinkType = {
            ...(link.location_id ? { location_id: link.location_id } : null),
            ...(link.department_id ? { department_id: link.department_id } : null),
            level: link.level,
            manager_id: id,
            id: line_id,
          };

          if (_destroy) {
            obj._destroy = _destroy;
          }

          if (link._destroy) {
            obj._destroy = link._destroy;
          }
          manipulatedLocationLinks.push(obj);
        });
      } else {
              const obj: SubsidiaryManagersLinkType = {
              ...(link.location_id ? { location_id: link.location_id } : null),
              ...(link.department_id ? { department_id: link.department_id } : null),
              level: link.level,
              id: link.id || null
              };

              if (link._destroy) {
                 obj._destroy = link._destroy;
              }
            manipulatedLocationLinks.push(obj);
       }
  }
  );
  }
  return manipulatedLocationLinks;
};

export const subsidiaryV2Links = (links: any, linkable_type: string) => {
  let manipulatedLocationLinks: any = [];
  if (Array.isArray(links) && links.length > 0) {
    links.map((link: any) => {
      const obj: SubsidiaryManagersLinkType = {
        id: link.line_id,
        linkable_id: link.field.id,
        linkable_type: linkable_type,
        manager_field_links: [{ level: link.level, manager_id: link.managers?.map(({ id }: { id: number }) => id) }],
        manager_field_links_attributes: link.managers?.map(
          ({ id, line_id, _destroy }: { id: number; line_id: number; _destroy: number }) => ({
            id: line_id,
            level: link.level,
            manager_id: id,
            ...(_destroy === 1 ? { _destroy } : {}),
          }),
        ),
      };
      if (link._destroy) {
        obj._destroy = link._destroy;
      }
      manipulatedLocationLinks.push(obj);
    });
  }

  return manipulatedLocationLinks;
};

// This function is responsible for mutating the Subsidiary Links to extract the required data for Locations and Departments.
const manipulateSubsidiaryChildLinks = (links: any) => {
  if (Array.isArray(links) && links.length > 0) {
    let result = links.reduce((acc: any, link: any) => {
      const existingLink = acc.find(
        (item: any) =>
          (link.location_id && item.location_id === link.location_id && item.level === link.level) ||
          (link.department_id && item.department_id === link.department_id && item.level === link.level),
      );
      if (existingLink) {
        existingLink.managers?.push({ line_id: link.id, id: link.manager_id, name: link.manager.name });
      } else {
        acc.push({
          ...(link.location_id ? { location_id: link.location_id } : null),
          ...(link.location ? { field: link.location } : null),

          ...(link.department_id ? { department_id: link.department_id } : null),
          ...(link.department ? { field: link.department } : null),
          id: link.id,
          level: link.level,
          field: link.location || link.department,
          managers: [{ line_id: link.id, id: link.manager_id, name: link.manager?.name }],
        });
      }
      return acc;
    }, []);
    return result;
  }
};

// This function is responsible for mutating the Subsidiary Links to extract the required data for Business Units, Projects and Metadata Templates.
const manipulateSubsidiaryChildV2Links = (linkableData: any) => {
  let result: any = [];
  if (Array.isArray(linkableData) && linkableData.length > 0) {
    linkableData.forEach((link: any) => {
      result.push({
        ...(link.linkable_type === "BusinessUnit" ? { business_unit_id: link.linkable_id } : null),
        ...(link.linkable_type === "Project" ? { project_id: link.linkable_id } : null),
        ...(link.linkable_type === "MetadataField" ? { metadata_field_id: link.metadata_field_id } : null),
        linkable_id: link.linkable_id,
        linkable_type: link.linkable_type,
        line_id : link.id,
        field: { id: link.linkable_id, name: link.name },
        level: link.manager_field_links?.[0]?.level || null, // all manager_field_links should have the same level
        managers: link.manager_field_links?.map((managerLink: any) => ({
          field_id: managerLink.field_id,
          field_type: managerLink.field_type,
          line_id: managerLink.id,
          level: managerLink.level,
          id: managerLink.manager_id,
          name: managerLink.name,
        })),
      });
    });
  }
  return result;
};

/**
 * The `subsidiary_links_v2` array in the response contains combined data from different array like
 * (Project, Business Unit, and Metadata templates). This function filters the array based on the `linkable_type`
 * and then manipulates the subsidiary links of each `linkable_type` to display them in their respective sections.
 */
const manipulateSubsidiaryLinksByLinkableType = (subsidiary_links_v2: any, linkableType: string) => {
  let linkableData = subsidiary_links_v2?.filter((link: any) => link.linkable_type === linkableType);
  if (linkableData.length > 0) {
    linkableData = manipulateSubsidiaryChildV2Links(linkableData);
    return linkableData;
  }
};

export const manipulateSubsidiaryLinks = (response: any) => {
  if (
    Array.isArray(response.subsidiary_manager_location_links) && response.subsidiary_manager_location_links.length > 0) {
    response.subsidiary_manager_location_links = manipulateSubsidiaryChildLinks(response.subsidiary_manager_location_links);
  }
  if (Array.isArray(response.subsidiary_links) && response.subsidiary_links.length > 0) {
    response.subsidiary_manager_department_links = manipulateSubsidiaryChildLinks(response.subsidiary_links);
  }

  if (Array.isArray(response.subsidiary_links_v2) && response.subsidiary_links_v2.length > 0) {
    response.subsidiary_manager_project_links = manipulateSubsidiaryLinksByLinkableType(
      response.subsidiary_links_v2,
      "Project",
    );
    response.subsidiary_manager_business_unit_links = manipulateSubsidiaryLinksByLinkableType(
      response.subsidiary_links_v2,
      "BusinessUnit",
    );
    response.subsidiary_manager_metadata_links = manipulateSubsidiaryLinksByLinkableType(
      response.subsidiary_links_v2,
      "MetadataField",
    );
  }
};
