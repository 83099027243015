import React, { useState, useEffect } from "react";
import { CardDisputeService, DISPUTE_KEYS_INDEX } from "services/admin/cards/cardDisputesSvc";
import { CardsDisputesFormState } from "../../../../../../services/admin/cards/disputes/cardDisputes.types";
import { Col, Row, Table } from "react-bootstrap";
import { getFormValues, change } from "redux-form";
import { NotAuthorizedReason51 } from "./notAuthorized_reason_51";
import { NotAuthorizedReason52 } from "./notAuthorized_reason_52";
import { NotAuthorizedReason53 } from "./notAuthorized_reason_53";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../../../reducers";
import { Utility } from "services/admin/cards/cardDisputesSvc";
import Style from "../dispute.module.css";
import PurchasesApis from "services/admin/purchases/purchasesApis";

export const NotAuthorizedDispute = () => {
  const currentDisputeForm = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue;
  });

  const dispatch = useDispatch();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<boolean[]>([]);
  const [selectedPurchaseIDs, setSelectedPurchaseIDs] = useState<number[]>([]);
  const [selectedFraudCategory, setSelectedFraudCategory] = useState<string>();
  const [purchaseList, setPurchaseList] = useState<any[]>();

  useEffect(() => {
    const getPurchaseList = async () => {
      let fetchedPurchaseList = await PurchasesApis.getList({});
      fetchedPurchaseList = fetchedPurchaseList.filter((e) => e.id !== currentDisputeForm.purchase?.id);

      // Convert string date object (ex. "2023-07-18T17:00:00.000-07:00") to mm/dd/yyyy
      fetchedPurchaseList.forEach((el) => {
        var posted_date = el.bank_card_transaction?.payment_request_date?.substring(0, 10) || "";
        el.posted_date = Utility.formatDate(posted_date);
      });

      setPurchaseList(fetchedPurchaseList);
      return purchaseList;
    };
    getPurchaseList();

    return () => {
      // Just being tidy: No reason to have fraud meta data in the main form
      // when moving on to another page / component.
      dispatch(change("CardDisputes", "form.fraud_dispute_additional_info", {}));
    };
  }, []);

  useEffect(() => {
    if (purchaseList?.length) {
      const initialCheckboxSelectionArray = new Array(purchaseList.length).fill(false);
      setSelectedCheckboxes(initialCheckboxSelectionArray);
    }
  }, [purchaseList]);

  const handleOptionChange = (value: string) => {
    CardDisputeService.clearOptions(dispatch);
    setSelectedFraudCategory(value); // Clears out all option values

    dispatch(change("CardDisputes", "form.fraud_dispute_additional_info.reasonCodeSubcategory", value));
    // Copy checked purchase objects from local state back into the form
    dispatch(
      change("CardDisputes", "form.fraud_dispute_additional_info.additionalPurchaseObjectIDs", selectedPurchaseIDs),
    );
  };

  const handleCheckboxChange = (index: number, transactionId: number) => {
    const updatedCheckboxes = [...selectedCheckboxes];
    const hasBeenAdded = !updatedCheckboxes[index];

    // store the array of booleans indicating each row's checkbox state
    updatedCheckboxes[index] = hasBeenAdded;
    setSelectedCheckboxes(updatedCheckboxes);

    // update the data that the form needs: an array of transaction ID#'s where each ID maps to a checked row
    var selectedIDs = [...selectedPurchaseIDs];

    if (hasBeenAdded) {
      selectedIDs.push(transactionId);
    } else {
      selectedIDs = selectedIDs.filter((x) => x !== transactionId);
    }

    selectedIDs = selectedIDs.sort((a, b) => a - b);

    setSelectedPurchaseIDs(selectedIDs);

    dispatch(change("CardDisputes", "form.fraud_dispute_additional_info.additionalPurchaseObjectIDs", selectedIDs));
  };

  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.option.not_authorized.${key}`);
  };
  const t3 = (key: string) => {
    return t(`components.admin.disputes.common_table_headers.${key}`);
  };

  return (
    <>
      <Col>
        <Row>
          <b>{t2("choose")}</b>
        </Row>
        <br></br>
        <Row style={{ display: "flex" }}>
          <div>{t2("card_number")}</div>
          <div style={{ marginLeft: "8px" }}>
            <span style={{ fontWeight: "bold" }}>( {currentDisputeForm.purchase?.card_number_masked} )</span>
          </div>
          <div style={{ marginLeft: "16px" }}>
            {t2("issued")}
            <span style={{ fontWeight: "bold", marginLeft: "8px" }}>
              {currentDisputeForm?.purchase?.first_name}&nbsp;{currentDisputeForm?.purchase?.last_name}
            </span>
          </div>
        </Row>
        <br></br>
        <Col>
          <Row>
            <div className={`${Style.table_container}`}>
              <Table bordered responsive striped size="lg">
                <thead>
                  <tr className={`${Style.table_header}`}>
                    <th></th>
                    <th>{t3("name")}</th>
                    <th>{t3("date")}</th>
                    <th>{t3("posted")}</th>
                    <th>{t3("purchase_no")}</th>
                    <th>{t3("posted_amount")}</th>
                    <th>{t3("approval")}</th>
                    <th>{t3("mcc")}</th>
                  </tr>
                </thead>
                <tbody>
                  {(purchaseList || []).map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedCheckboxes[index] || false}
                          onChange={() => handleCheckboxChange(index, item.id)}
                        />
                      </td>
                      <td>{item.merchant}</td>
                      <td>{Utility.formatDate(item.transaction_date)}</td>
                      <td>{item.posted_date}</td>
                      <td>{item.number}</td>
                      <td>{(item.amount || 0).toFixed(2)}</td>
                      <td>{item.approval_code}</td>
                      <td>{item.mcc_description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Row>
        </Col>
        <hr className={`${Style.bold_hr}`}></hr>
        <Row>
          <Col sm="2">
            <label>
              <input
                type="radio"
                name="optionGroup"
                value={DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_STOLEN}
                checked={selectedFraudCategory === DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_STOLEN}
                onChange={() => handleOptionChange(DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_STOLEN)}
              ></input>
              <b>&nbsp;{t2("option_1.title")}</b>
            </label>
          </Col>
          <Col sm="8" style={{ paddingLeft: 0 }}>
            {t2("option_1.description")}
          </Col>
        </Row>
        {selectedFraudCategory === DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_STOLEN && (
          <NotAuthorizedReason51></NotAuthorizedReason51>
        )}
        <Row>
          <Col sm="2">
            <label>
              <input
                type="radio"
                name="optionGroup"
                value={DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_CUSTOMER_HAD_POSSESSION}
                checked={selectedFraudCategory === DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_CUSTOMER_HAD_POSSESSION}
                onChange={() => handleOptionChange(DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_CUSTOMER_HAD_POSSESSION)}
              ></input>
              <b>&nbsp;{t2("option_2.title")}</b>
            </label>
          </Col>
          <Col sm="8" style={{ paddingLeft: 0 }}>
            {t2("option_2.description")}
          </Col>
        </Row>
        {selectedFraudCategory === DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_CUSTOMER_HAD_POSSESSION && (
          <NotAuthorizedReason52></NotAuthorizedReason52>
        )}
        <Row>
          <Col sm="2">
            <label>
              <input
                type="radio"
                name="optionGroup"
                value={DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_CUSTOMER_MISUSED}
                checked={selectedFraudCategory === DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_CUSTOMER_MISUSED}
                onChange={() => handleOptionChange(DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_CUSTOMER_MISUSED)}
              ></input>
              <b>&nbsp;{t2("option_3.title")}</b>
            </label>
          </Col>
          <Col sm="8" style={{ paddingLeft: 0 }}>
            {t2("option_3.description")}
          </Col>
        </Row>
        {selectedFraudCategory === DISPUTE_KEYS_INDEX.NOT_AUTHORIZED_CUSTOMER_MISUSED && (
          <NotAuthorizedReason53></NotAuthorizedReason53>
        )}
      </Col>
    </>
  );
};
