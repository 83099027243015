import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { minLength, required } from "../../../../../services/validations/reduxFormValidation";
import { PaymentMethodValidatorSvc } from "../../paymentMethodValidatorSvc";
import { RenderField } from "../../../../forms/bootstrapFields";
import BankCodeValidator from "../../bankCodeValidator";
import { AccountNumberValidator } from "../../accountNumberValidator";
import AccountType from "../../../managePaymentMethod/fields/accountType";

const min4 = minLength(4);
const min7 = minLength(7);

const TwBrl = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState("");

  const validateCpfNumber = async (value: string) => {
    if (value) {
      try {
        const result = await PaymentMethodValidatorSvc.ValidateBrazilCpf(value);
        if (result.validation) {
          setValidationMessage(result.validation);
        } else {
          setValidationMessage(result.errors[0]?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="4">
        <BankCodeValidator modelName={`${modelName}`} label={t("common.paymentMethod.bankCode")} noValidate={true} />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.branchCode`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.branchCode")}
          className={`w-100`}
          required
          maxLength={6}
          validate={[required, min4]}
        />
      </Col>
      <Col md="4">
        <AccountNumberValidator
          modelName={`${modelName}`}
          minLen={2}
          maxLength={12}
          accountNumberType="brazil-account-number"
          label={t("common.paymentMethod.accountNumberLabel")}
        />
      </Col>
      <Col md="4">
        <AccountType
          label="Account Type:"
          required
          validations={[required]}
          options={[
            { label: "Checking", value: "checking" },
            { label: "Saving", value: "saving" },
          ]}
        />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.phoneNumber`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.recipientPhoneNumber")}
          className={`w-100`}
          required
          maxLength={20}
          validate={[required, min7]}
        />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.cpf`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.taxRegistrationNumber")}
          onChange={(e: any) => validateCpfNumber(e.target.value)}
          className={`w-100`}
          required
          validate={[required]}
        />
        {validationMessage && (
          <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
        )}
      </Col>
    </>
  );
};

export default TwBrl;
