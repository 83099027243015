import React from "react";
import Style from "../dispute.module.css";

export const RequiredFieldIcon = () => {
  return <span className={`${Style.required_icon}`}>&nbsp;*</span>;
};

// In rare cases, a text string was *just* long enough that its
// required icon "wrapped" around so it was the only symbol on its
// own line under the text, which looked very odd.
// Wrapping the two in a <div> should force the text to scroll on
// the last word instead of just the symbol.
interface RequiredFieldIconProps {
  text: string;
}

export const RequiredFieldText: React.FC<RequiredFieldIconProps> = ({ text }) => {
  return (
    <div>
      {text}
      <span className={`${Style.required_icon}`}>&nbsp;*</span>
    </div>
  );
};
