import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../../../pages/admin/notifications/notification.module.css";
import { useLocation } from "react-router";
import _ from "lodash";

const NotificationsTabs = ({ handleTabChange, count }) => {
  const [currentTab, setCurrentTab] = useState("notificationList");
  const location = useLocation();

  const changeTab = (tabName) => {
    setCurrentTab(tabName);
    handleTabChange(tabName);
  };

  const setLocationTab = (currentLocation) => {
    if (!_.isPlainObject(currentLocation.state) && !_.isNil(currentLocation.search)) {
      const urlStateTab = currentLocation.search.split("?tab=")[1];
      const tab = urlStateTab === "notifications" ? "notificationList" : "messageList";
      setCurrentTab(tab);
      handleTabChange(tab);
    } else if (_.isPlainObject(currentLocation.state)) {
      setCurrentTab(currentLocation.state.currentTab);
      handleTabChange(currentLocation.state.currentTab);
    }
  };

  useEffect(() => {
    setLocationTab(location);
  }, [location]);

  return (
    <Row className="m-0 justify-content-end">
      <Col xs={12} className="p-0">
        <div className={`${styles.navTabsSubtabs} mb-2`}>
          <ul className={`nav ${styles.navTabs}`}>
            <li role="presentation" onClick={() => changeTab("notificationList")} className="pl-0">
              <a className={`${currentTab === "notificationList" && styles.active}`}>
                Notifications {count.new_notifications > 0 && `(${count.new_notifications})`}
              </a>
            </li>
            <li role="presentation" onClick={() => changeTab("messageList")}>
              <a className={`${currentTab === "messageList" && styles.active}`}>
                Messages {count.new_messages > 0 && `(${count.new_messages})`}
              </a>
            </li>
            {/* <li role="presentation" onClick={() => changeTab("newsList")}>
                                  <a className={`${currentTab === "newsList" && styles.active}`}>
                                    News {count.news_count > 0 && (`(${count.news_count})`)}
                                  </a>
                              </li>*/}
          </ul>
        </div>
      </Col>
    </Row>
  );
};

export default NotificationsTabs;
