import React from "react";
import { AiFillWarning } from "react-icons/ai";
import ShowStatus from "../status/status";
import { ReactI18NextChild } from "react-i18next";

type propTypes = {
  style?: React.CSSProperties | undefined;
  title: React.ReactChild | React.ReactFragment | React.ReactPortal | Iterable<ReactI18NextChild> | null | undefined;
  warningMessage?: string;
  status?: string;
  iconClass?: string;
  headerClass?: string;
};

const PageTitle = (props: propTypes) => {
  return (
    <h1 className="mb-3 d-inline-block" style={props.style}>
      {props.iconClass && <i className={props.iconClass} />}
      <span style={{ fontSize: "32px", fontFamily: "GT-America" }} className={props.headerClass}>
        {props.title}
      </span>
      {props.warningMessage && <AiFillWarning className={"primaryLinkColor"} style={{ fontSize: "26px" }} />}
      {props.status && <ShowStatus status={props.status}></ShowStatus>}
    </h1>
  );
};

export default PageTitle;
