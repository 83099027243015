import React, { useState } from "react";
import { ExpenseReportTypes } from "services/admin/expenses/expenseReport/expenseReportType";
import PayExpenseModal from "./payExpenseModal";

type PayExpensesPropsType = {
  selectedExpenseReport: ExpenseReportTypes.ListItem;
  title: string;
};

const PayExpense = ({ selectedExpenseReport, title }: PayExpensesPropsType) => {
  const [showPayExpenseModal, setShowPayExpenseModal] = useState(false);

  return (
    <>
      {showPayExpenseModal && (
        <PayExpenseModal
          onHidePayExpenseModal={() => setShowPayExpenseModal(false)}
          selectedExpenseReport={selectedExpenseReport}
          showPayExpenseModal={showPayExpenseModal}
        />
      )}
      <button
        title={title}
        className={`actionButtons icon-pay-${selectedExpenseReport?.payment_method?.payment_type}`}
        onClick={() => setShowPayExpenseModal(true)}
      ></button>
    </>
  );
};

export default PayExpense;
