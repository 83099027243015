import { AxiosResponse } from "axios";
import useIsMounted from "components/common/hooks/useIsMounted";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { WrappedFieldProps } from "redux-form";
import commonService from "services/common/commonSvc";
import { IDType } from "services/common/types/common.type";
import { compare, onBlurSingle } from "services/general/helpers";
import { Mandatory } from "wombatifier/components/pickers/mandatory";

export type PerDiemItem = {
  id?: IDType;
  name?: string;
  status?: string;
  is_active?: boolean;
  currency_code?: string;
  unit_price?: number;
  description?: string | null;
  per_diem_type?: string;
  company_id?: IDType;
  department_id?: IDType;
  category_id?: IDType;
  value: IDType;
};

export interface ExpenseItemPerDiemPickerPropsType extends WrappedFieldProps {
  modelData: Record<string, any>;
  parentObjData?: any;
  className?: string;
  labelClassName?: string;
  label?: string;
  tooltip?: string;
  required?: boolean;
  instanceId?: string;
  placeholder?: string;
  disabled?: boolean;
  callBack?: (value: any) => void;
  callBackObj?: (value: any) => void;
  formName: string;
  formLocation: string; // local of js object where gone save changes
}

// this component is specific to expense item form only
const ExpenseItemPerDiemPicker = ({
  input,
  meta: { error, touched },
  instanceId,
  label,
  labelClassName,
  modelData,
  callBack,
  required,
  tooltip,
  callBackObj,
}: ExpenseItemPerDiemPickerPropsType) => {
  const isMounted = useIsMounted();
  const [options, setOptions] = useState<PerDiemItem[]>([]);

  const findSelected = () => {
    let selectedValues = null; //set to null because pickers will have blank default value instead of "select... placeholder"
    const obj = _.find(options, (option: any) => option?.value === input.value);
    if (obj) selectedValues = obj;
    return selectedValues ? selectedValues : null;
  };

  const parseForSelect = (options: any[]) => {
    return options
      .map((option) => {
        return {
          value: option.id,
          label: option.code,
          raw: option,
          ...option,
        };
      })
      .sort(compare);
  };

  const initPerDiemObj = (perDiemItem: PerDiemItem) => {
    input.onChange(perDiemItem.id);
  };

  const clearModelDataPerDiem = () => {
    input.onChange("");
  };

  const mergeInactivePerDiems = async (perDiems: PerDiemItem[]) => {
    if (input.value > 0) {
      const perDiem = perDiems.filter((perDiem) => {
        return perDiem.id === input.value;
      });

      if (perDiem.length === 0) {
        try {
          const response: AxiosResponse<any> = await restApiService.get("per_diems/" + input.value);
          if (response.data) {
            initPerDiemObj(response.data);
            return [...perDiems, response.data];
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        initPerDiemObj(perDiem[0]);
      }
    }
    return perDiems;
  };

  //set selected value in model
  const onChange = (selected: SingleValue<PerDiemItem>) => {
    if (selected && selected.id) {
      input.onChange(selected.value);
      if (_.isPlainObject(modelData)) {
        modelData.per_diem = selected;
        if (selected.category_id) {
          modelData.category_id = selected.category_id;
        } else {
          modelData.category_id = "";
        }
      }
    } else {
      // scope.per_diem_name = "-- Select Expense Per Diem --";
      modelData.per_diem = null;
      input.onChange("");
    }
    callBack && callBack(selected?.value);
    callBackObj && callBackObj(selected);
  };

  // filter per_diem items to only include items in the same category as the one chosen by the user
  const filterPerDiems = (perDiems: PerDiemItem[]) => {
    const filteredPerDiems = perDiems.filter(
      (perDiem) => !modelData?.category_id || perDiem.category_id === modelData?.category_id || perDiem.id === "",
    );

    // check if there is a per diem selected, and if there is, check its category id. If it does not match the
    // chosen category, throw it out. If it matches, or if an item has not been selected yet, do nothing.
    if (input.value > 0) {
      var selected = perDiems.filter((perDiem) => {
        return perDiem.id === input.value;
      });
      if (selected.length === 0) {
        clearModelDataPerDiem();
      } else {
        onChange(selected[0]);
      }
    }
    return filteredPerDiems;
  };

  const getPerDiems = async () => {
    try {
      const result = await restApiService.get("per_diems", { policy_id: modelData?.policy_id }, null, true, null, true);
      if (Array.isArray(result.data)) {
        const parsedOptions = parseForSelect(result.data);
        const filteredOptions = filterPerDiems(parsedOptions);
        const mergedOptions: any[] = await mergeInactivePerDiems(filteredOptions);
        if (isMounted.current) {
          if (!required) {
            mergedOptions.unshift({
              id: "",
              value: "",
              name: "-- Select Expense Per Diem --",
              label: "-- Select Expense Per Diem --",
            });
          }
          setOptions(mergedOptions);
        }
      }
    } catch (error) {
      commonService.handleError(error);
    }
  };

  useEffect(() => {
    getPerDiems();
  }, [modelData?.policy_id, modelData?.category_id]);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label className={labelClassName}>
            {label}
            <Mandatory required={required} />
            {tooltip}
          </Form.Label>
        )}
        <Select
          {...input}
          value={findSelected()}
          onChange={(selected) => onChange(selected)}
          formatOptionLabel={(option) => {
            return <>{option.name}</>;
          }}
          onBlur={() => onBlurSingle(input, input.value)}
          instanceId={instanceId}
          options={options}
        />
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default ExpenseItemPerDiemPicker;
