import Panel from "components/common/panel/panel";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SubsidiaryLinksRenderer from "./subsidiaryLinksRenderer";

type SubsidiaryLinksRenderPanelPropsType = {
  modelData: any;
  modelName: string;
  title: string;
  isDetailPage?: boolean;
};

const SubsidiaryLinksRenderPanel = ({
  modelData,
  title,
  isDetailPage,
  modelName,
}: SubsidiaryLinksRenderPanelPropsType) => {
  const [isCollapse, setCollapse] = useState<boolean>(false);
  const [updatedModelData, setUpdatedModelData] = useState<any | null>(modelData);
  const { t } = useTranslation();
  const handleCollapse = () => {
    setCollapse(!isCollapse);
  };

  return (
    <Panel
      header={
        <div>
          <i className="icon icon-drilldown m-0 px-mt-5" /> {t("title.link")} {title?.toLocaleUpperCase()}
          <a className="float-right">
            <i
              className={`icon ${isCollapse ? "icon-arrow-down" : "icon-arrow-up"}`}
              onClick={() => handleCollapse()}
            ></i>
          </a>
        </div>
      }
      hideCardBody={!isCollapse}
    >
      {updatedModelData !== null && (
        <Row>
          <Col>
            <SubsidiaryLinksRenderer
              sectionModelData={updatedModelData?.length > 0 ? updatedModelData : []}
              title={title}
              gridStorageName={`${modelName}Links`}
              isCollapse={isCollapse}
              isDetailPage={isDetailPage}
            />
          </Col>
        </Row>
      )}
    </Panel>
  );
};

export default SubsidiaryLinksRenderPanel;
