import React from "react";
import { useTranslation } from "react-i18next";
import { RenderField } from "../../../../forms/bootstrapFields";
import { Field } from "redux-form";

const PurposeCode = ({ modelName, isPurposeOfPayment }: { modelName?: string; isPurposeOfPayment?: boolean }) => {
  const { t } = useTranslation();

  const optionalRemitInstructionLabel = (
    <>
      <span>{t("common.paymentMethod.optionalRemitInstructionForPayment")}</span> <br />
      <span style={{ fontSize: "12px" }}>{t("common.paymentMethod.optionalRemitInstructionForPaymentNote")}</span>
    </>
  );

  const purposeOfPaymentLabel = (
    <>
      <span>{t("common.paymentMethod.purposeOfPayment")}</span>
      <br />
      <span style={{ fontSize: "12px" }}>{t("common.paymentMethod.purposeOfPaymentNote")}</span>
    </>
  );

  return (
    <Field
      name={`${modelName}.purpose_code`}
      component={RenderField}
      type="text"
      label={isPurposeOfPayment ? purposeOfPaymentLabel : optionalRemitInstructionLabel}
      className={`w-100`}
      maxlength="136"
    />
  );
};

export default PurposeCode;
