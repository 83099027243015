import React from "react";
import DateFilter from "../reportFilters/date";
import DropdownFilter from "../reportFilters/dropdown";

const SvbListing = ({ scheduled }: { scheduled?: boolean }) => {
  const svb_status_filters = [
    { value: "", label: "ALL" },
    { value: "succeeded", label: "succeeded" },
    { value: "processing", label: "processing" },
    { value: "canceled", label: "canceled" },
    { value: "failed", label: "failed" },
    { value: "testing", label: "testing" },
  ];

  return (
    <div>
      <DateFilter name={"As of Date"} code={"end_date"} options={[]} isRequired />
      <DropdownFilter name={"Status"} code={"svb_status"} options={svb_status_filters} />
    </div>
  );
};

export default SvbListing;
