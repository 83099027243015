import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import { RenderField, RenderSelect } from "components/forms/bootstrapFields";
import arrayMutators from "final-form-arrays";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useTypedSelector } from "reducers";
import { SubsidiaryDetailType } from "services/admin/subsidiaries/types";
import { required } from "services/validations/reduxFormValidation";
import useAdminCompanyCurrencyCode from "../hooks/useAdminCompanyCurrencyCode";
import BusinessUnitPicker from "../pickers/reduxFormPickers/businessUnitPicker";
import CountryPicker from "../pickers/reduxFormPickers/countryPicker";
import CurrencyCodePicker from "../pickers/reduxFormPickers/currencyCodePicker";
import DepartmentPicker from "../pickers/reduxFormPickers/departmentPicker";
import LocationPicker from "../pickers/reduxFormPickers/locationPicker";
import MetadataFieldPicker from "../pickers/reduxFormPickers/metadataPicker/metadataPicker";
import ProjectPicker from "../pickers/reduxFormPickers/projectPicker/projectPicker";
import ManageSubsidiaryLinks from "./manageSubsidiaryLinks";
import styles from "./subsidiaries.module.css";

type SubsidiaryFormPropsTypes = {
  isAddForm?: boolean;
  returnTo?: string;
  initialValues?: any;
  onSubmit: (subsidiary: SubsidiaryDetailType) => void;
};
interface MetadataConfiguration {
  id?: number | null;
  field_id?: string | null;
  name?: string;
  object_type?: string | null;
  input_type?: string | null;
  required?: boolean | null;
  created_at?: Date | null;
  updated_at?: Date | null;
}

const FormSubsidiary = ({ isAddForm, returnTo, initialValues, onSubmit }: SubsidiaryFormPropsTypes) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentUser = useTypedSelector((state) => state.user);
  const { companyCurrencies } = useAdminCompanyCurrencyCode();

  const [subsidiary, setSubsidiary] = useState<any>(initialValues);
  const [metadataConfigurations, setMetadataConfigurations] = useState<any>([]);

  useEffect(() => {
    if (Array.isArray(initialValues?.metadata_configurations) && initialValues?.metadata_configurations.length > 0) {
      let metadata_configurations = initialValues?.metadata_configurations;
      const filteredMetadataConfigurations = metadata_configurations.filter(
        (metadataConfiguration: MetadataConfiguration) => {
          return metadataConfiguration.input_type == "multi-select" || metadataConfiguration.input_type == "select";
        },
      );
      setMetadataConfigurations(filteredMetadataConfigurations);
    }
  }, [initialValues?.metadata_configurations]);

  return (
    <Form
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={(values) => onSubmit(values)}
      validate={(values) => {
        const errors: { name?: string } = {};
        if (values.name && values.name.startsWith(" ")) {
          errors.name = "Subsidiary name should not contain white space at start or only white spaces.";
        }
        return errors;
      }}
      render={({
        handleSubmit,
        submitError,
        form: {
          mutators: { push, pop },
        },
        form,
      }) => (
        <form onSubmit={handleSubmit}>
          <Container fluid={true} className="mt-4 px-pl-10">
            <Row className={styles.subsidiaryFormLabel}>
              <Col sm="6" className="px-p-0">
                <div>{isAddForm ? t("subsidiary.add") : t("subsidiary.edit")}</div>
              </Col>
              <Col sm="6" className="text-right px-pr-0">
                <Button
                  variant="btn btn-secondary"
                  onClick={() => history?.push(returnTo ? returnTo : "/ap/subsidiaries")}
                >
                  {t("cancel")}
                </Button>
                <Button variant="btn btn-primary ml-1" type="submit">
                  {t("submit")}
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm="4">
                <Field
                  name="name"
                  component={RenderField}
                  type="text"
                  placeholder={t("subsidiary.name")}
                  label={t("subsidiary.name")}
                  validate={required}
                  required={true}
                />
                {submitError && <div className="error">{submitError}</div>}
              </Col>
              <Col sm="4">
                <Field
                  name="currency_code"
                  component={CurrencyCodePicker}
                  currencyCodeOptions={companyCurrencies}
                  label={t("subsidiary.currencyCode")}
                  validate={required}
                  required={true}
                />
              </Col>
              <Col sm="4">
                <Field
                  name="status"
                  component={RenderSelect}
                  options={[
                    { label: "Select", value: "" },
                    { label: "Active", value: "ACTIVE" },
                    { label: "Closed", value: "CLOSED" },
                  ]}
                  label={t("subsidiary.status")}
                  validate={required}
                  required={true}
                />
              </Col>
              <Col sm="4">
                <Field
                  name="sub_type"
                  component={RenderField}
                  type="text"
                  placeholder={t("subsidiary.subType")}
                  label={t("subsidiary.subType")}
                />
              </Col>
              <Col sm="4">
                <Field name="country" component={CountryPicker} label={t("subsidiary.country")} />
              </Col>
              <Col sm="4">
                <Field name="external_id" component={RenderField} label={t("subsidiary.externalId")} />
              </Col>
            </Row>
            {currentUser?.company?.has_locations && (
              <ErrorBoundary>
                <Row className="px-mt-25">
                  <Col>
                    <ManageSubsidiaryLinks
                      uniqueKey={`subsidiaryLocationLink`}
                      name="subsidiary_link_location"
                      modelData={subsidiary?.subsidiary_manager_location_links}
                      modelName="Location"
                      picker={LocationPicker}
                      pickerId="location_id"
                      push={push}
                      form={form}
                    />
                  </Col>
                </Row>
              </ErrorBoundary>
            )}

            {currentUser?.company?.has_departments && (
              <ErrorBoundary>
                <Row>
                  <Col>
                    <ManageSubsidiaryLinks
                      uniqueKey={`subsidiaryDepartmentLink`}
                      name="subsidiary_link_department"
                      modelData={subsidiary?.subsidiary_manager_department_links}
                      modelName="Department"
                      picker={DepartmentPicker}
                      pickerId="department_id"
                      push={push}
                      form={form}
                    />
                  </Col>
                </Row>
              </ErrorBoundary>
            )}

            {currentUser?.company?.has_projects && (
              <ErrorBoundary>
                <Row>
                  <Col>
                    <ManageSubsidiaryLinks
                      uniqueKey={`subsidiaryProjectLink`}
                      name="subsidiary_link_project"
                      modelData={subsidiary?.subsidiary_manager_project_links}
                      modelName="Project"
                      linkableType="Project"
                      picker={ProjectPicker}
                      pickerId="project_id"
                      push={push}
                      form={form}
                    />
                  </Col>
                </Row>
              </ErrorBoundary>
            )}

            {currentUser?.company?.has_business_units && (
              <ErrorBoundary>
                <Row>
                  <Col>
                    <ManageSubsidiaryLinks
                      uniqueKey={`subsidiaryBusinessUnitLink`}
                      name="subsidiary_link_business_unit"
                      modelData={subsidiary?.subsidiary_manager_business_unit_links}
                      modelName="Business Unit"
                      linkableType="BusinessUnit"
                      picker={BusinessUnitPicker}
                      pickerId="business_unit_id"
                      push={push}
                      form={form}
                    />
                  </Col>
                </Row>
              </ErrorBoundary>
            )}

            {metadataConfigurations && metadataConfigurations.length > 0 && (
              <div className="mt-3">
                <p className={styles.metadataTitle}>Link Metadata</p>
                {metadataConfigurations.map((metadataConfiguration: MetadataConfiguration, index: number) => {
                  return (
                    <ErrorBoundary key={index}>
                      <Row>
                        <Col>
                          <ManageSubsidiaryLinks
                            uniqueKey={`subsidiaryMetadataTemplatesLink${metadataConfiguration.field_id}`}
                            name={"subsidiary_link_" + metadataConfiguration.field_id}
                            modelData={subsidiary?.subsidiary_manager_metadata_links}
                            modelName={metadataConfiguration.name || ""}
                            linkableType="Metadata"
                            fieldId={metadataConfiguration.field_id}
                            metadataFieldName={metadataConfiguration.field_id || metadataConfiguration.name}
                            picker={MetadataFieldPicker}
                            pickerName={metadataConfiguration.name}
                            pickerProps={metadataConfiguration}
                            pickerId="metadata_field_id"
                            push={push}
                            form={form}
                          />
                        </Col>
                      </Row>
                    </ErrorBoundary>
                  );
                })}
              </div>
            )}
          </Container>
        </form>
      )}
    />
  );
};

export default FormSubsidiary;
