import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { SubmissionError } from "redux-form";
import UserApis from "services/common/user/userApis";
import UserSvc from "services/common/user/userSvc";
import { SecurityDetailsType } from "services/common/user/userTypes";
import { restApiService } from "../../../providers/restApi";
import { useTypedSelector } from "../../../reducers";
import ContactApis from "../../../services/common/contact/contactApis";
import { EditContactPayloadType, IContact } from "../../../services/common/contact/contactTypes";
import { dateStringWithoutTimezone } from "../../../services/general/dateSvc";
import { createCompleteError } from "../../../services/general/reduxFormSvc";
import usePermission from "../../common/hooks/usePermission";
import Panel from "../../common/panel/panel";
import DelegationFields from "../delegationFields/delegationFields";
import AccountDisalbledField from "./formComponents/accountDisabledField";
import ForceSyncField from "./formComponents/forceSyncField";
import UserForm, { USER_FORM, UserFormDataType } from "./formUser";
import style from "./users.module.css";

const EditUser = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [contact, setContact] = useState<IContact>();
  const [initialValues, setInitialValues] = useState<UserFormDataType>();
  const { hasUserPermission } = usePermission();
  const currentUser = useTypedSelector((state) => state.user);
  const allowSelfEdit =
    currentUser.id === contact?.user?.id ? hasUserPermission("addUsers") : hasUserPermission("editUsers");

  // this funciton is formatting payload as per form
  const getUserEditInitialValue = async (contact: IContact) => {
    let securityDetails: SecurityDetailsType = {};
    if (UserSvc.isAdminOrInternalUser(currentUser) && contact.user?.id) {
      securityDetails = (await handleGetSecurity(`${contact.user.id}`)) || {};
    }

    const editUserData: UserFormDataType = {
      user: {
        email: contact?.email,
        no_push: contact?.no_push,
        is_disabled: contact?.profile_user?.is_disabled,
        force_push: contact?.user?.force_push,
        contact: {
          delegator_links_attributes: contact.delegator_links_attributes,
          first_name: contact.first_name,
          last_name: contact.last_name,
          phone: contact.phone,
          title: contact.title,
          manager_id: contact.manager_id,
          amount_limit: contact.amount_limit,
          business_unit_id: contact.business_unit_id,
          contact_location_links_attributes: contact.contact_location_links,
          policy_id: contact.policy_id,
          contact_id: contact.contact_id,
          department_id: contact.department_id,
          external_id: contact.external_id,
          contact_type: contact.contact_type,
          department_links: contact.contact_department_links,
        },
        ...securityDetails,
      },
      securityVisible: !!contact?.user?.id,
    };

    if (_.isArray(contact?.contact_subsidiary_links)) {
      const primarySubsidiaryLink = contact?.contact_subsidiary_links.find((subsidiaryLink) => subsidiaryLink.default);
      const nonPrimarySubsidiaryLink = contact?.contact_subsidiary_links.filter(
        (subsidiaryLink) => !subsidiaryLink.default,
      );
      editUserData.user.contact.subsidiary_id = primarySubsidiaryLink?.subsidiary_id;
      editUserData.user.contact.subsidiary_links = nonPrimarySubsidiaryLink;
    }

    if (_.isArray(contact?.currency_codes)) {
      editUserData.user.contact.currency_code = contact?.currency_codes.find(
        (currencyCode) => currencyCode.primary === true,
      )?.code;
      editUserData.user.contact.additional_currency_codes = contact?.currency_codes.map((currencyCode) => ({
        value: currencyCode.code,
        label: currencyCode.code,
        code: currencyCode.code,
        primary: currencyCode.primary,
      }));
    }

    if (_.isArray(contact?.contact_project_links)) {
      const projectLinks = contact?.contact_project_links.map((projectLink) => ({
        id: projectLink.id,
        project_id: projectLink.project_id,
        is_default: projectLink.is_default,
        value: projectLink.project_id,
        label: projectLink?.project?.name,
      }));
      editUserData.user.contact.project_links = projectLinks;
      const defaultProject = contact?.contact_project_links.find((projectLink) => projectLink.is_default);
      editUserData.user.contact.project = defaultProject?.project_id;
    }

    if (_.isArray(contact?.contact_location_links)) {
      const locationLinks = contact?.contact_location_links.map((locationLink) => (  
      {
        id: locationLink.id,
        location_id: locationLink.location_id,
        is_primary: locationLink.is_primary,
        value: locationLink.location_id,
        label: locationLink?.location?.name
      }));
      editUserData.user.contact.contact_location_links_attributes = locationLinks;
    }

    setInitialValues(editUserData);
  };

  const getContact = async () => {
    try {
      const contact = await ContactApis.getContact(Number(id));
      setContact(contact);
    } catch (error) {}
  };

  const onSubmit = async (userFormData: UserFormDataType) => {
    const editContactPayload: EditContactPayloadType = {
      contact: {
        delegator_links_attributes: userFormData.user.contact.delegator_links_attributes,
        first_name: userFormData.user.contact.first_name,
        last_name: userFormData.user.contact.last_name,
        email: userFormData.user.email,
        phone: userFormData.user.contact.phone,
        title: userFormData.user.contact.title,
        manager_id: userFormData.user.contact.manager_id,
        amount_limit: userFormData.user.contact.amount_limit,
        business_unit_id: userFormData.user.contact.business_unit_id,
        contact_location_links_attributes: userFormData.user.contact.contact_location_links_attributes,
        policy_id: userFormData.user.contact.policy_id,
        contact_id: userFormData.user.contact.contact_id,
        external_id: userFormData.user.contact.external_id,
        department_id: userFormData.user.contact.department_id,
        contact_department_links_attributes: userFormData.user.contact.department_links,
        subsidiary_id: userFormData.user.contact.subsidiary_id,
        contact_subsidiary_links_attributes: userFormData.user.contact.subsidiary_links,
        currency_codes: userFormData.user.contact.additional_currency_codes,
        no_push: userFormData.user.no_push,
        user: {
          is_disabled: userFormData.user.is_disabled,
          force_push: userFormData.user.force_push,
          ...(userFormData.user.security_1 ? { security_1: userFormData.user.security_1 } : {}),
          ...(userFormData.user.security_2 ? { security_2: userFormData.user.security_2 } : {}),
        },
      },
    };

    if (!_.isArray(editContactPayload.contact.contact_subsidiary_links_attributes)) {
      editContactPayload.contact.contact_subsidiary_links_attributes = [];
    }

    const previousPrimarySubsidiary =
      _.isArray(contact?.contact_subsidiary_links) &&
      contact?.contact_subsidiary_links?.find((subsidiaryLink) => subsidiaryLink.default);

    // previous primary subsidiary is changed
    if (
      previousPrimarySubsidiary &&
      userFormData.user.contact.subsidiary_id &&
      previousPrimarySubsidiary?.subsidiary_id !== userFormData.user.contact.subsidiary_id
    ) {
      // add new default
      editContactPayload.contact.contact_subsidiary_links_attributes?.push({
        subsidiary_id: userFormData.user.contact.subsidiary_id,
        default: true,
      });

      //remove previous default
      editContactPayload.contact.contact_subsidiary_links_attributes?.push({
        ...previousPrimarySubsidiary,
        _destroy: 1,
      });
    }

    // previous primary subsidiary is removed
    if (previousPrimarySubsidiary && !userFormData.user.contact.subsidiary_id) {
      editContactPayload.contact.contact_subsidiary_links_attributes?.push({
        ...previousPrimarySubsidiary,
        _destroy: 1,
      });
    }

    // no previos primary subsidary present
    if (!previousPrimarySubsidiary && userFormData.user.contact.subsidiary_id) {
      editContactPayload.contact.contact_subsidiary_links_attributes?.push({
        subsidiary_id: userFormData.user.contact.subsidiary_id,
        default: true,
      });
    }

    // update project
    if (_.isArray(userFormData?.user?.contact?.project_links)) {
      editContactPayload.contact.contact_project_links_attributes = userFormData?.user?.contact?.project_links.map(
        (projectLink) => ({
          id: projectLink.id,
          project_id: projectLink.project_id,
          is_default: projectLink.is_default,
          _destroy: projectLink._destroy,
        }),
      );
    }
    // update location links
    if (_.isArray(userFormData?.user?.contact?.contact_location_links_attributes)) {
      editContactPayload.contact.contact_location_links_attributes = userFormData?.user?.contact?.contact_location_links_attributes.map(
        (locationLink) => ({
          id: locationLink.id,
          location_id: locationLink.location_id,
          is_primary: locationLink.is_primary,
          _destroy: locationLink._destroy,
        }),
      );
    }
    if (_.isArray(userFormData?.user?.contact?.delegator_links_attributes)) {
      editContactPayload.contact.delegator_links_attributes =
        userFormData?.user?.contact?.delegator_links_attributes.map((delegateLink) => ({
          ...delegateLink,
          id: delegateLink.id,
          delegator_id: Number(id),
          // removing timezone character from datestring
          start_date:
            delegateLink.start_date &&
            dateStringWithoutTimezone(delegateLink.start_date, currentUser.company.company_date_format),
          end_date:
            delegateLink.start_date &&
            dateStringWithoutTimezone(delegateLink.end_date, currentUser.company.company_date_format),
        }));
    }

    try {
      await ContactApis.editContact(Number(id), editContactPayload);
      window.location.href = restApiService.angularBaseURL() + "/contacts/" + id;
    } catch (error: any) {
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          const contactError: any = { contact: completeErrorObj };
          //fix to show email validation
          if (response?.data["email"]) {
            contactError.email = response?.data["email"];
          }
          throw new SubmissionError({
            user: contactError,
          });
        }
      }
    }
  };

  const handleGetSecurity = async (id: string) => {
    try {
      return await UserApis.getSecurity(id);
    } catch (err) {}
  };

  useEffect(() => {
    getContact();
  }, []);

  useEffect(() => {
    if (contact) {
      getUserEditInitialValue(contact);
    }
  }, [contact]);

  return (
    <Container fluid>
      {allowSelfEdit ? (
        <>
          <Row className="mt-4">
            <Col md="12">
              <h2 className={style.userTitle}>{t("admin.pages.users.editUser")}</h2>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <UserForm
                onSubmit={onSubmit}
                initialValues={initialValues}
                delegationsFields={
                  <>
                    <Row>
                      <Col>
                        <h3>{t("admin.pages.users.delegates")}</h3>
                      </Col>
                    </Row>
                    <Row>
                      <DelegationFields formName={USER_FORM} formFieldName="user.contact.delegator_links_attributes" />
                    </Row>
                  </>
                }
                accountDisalbledField={<AccountDisalbledField />}
                forceSyncField={<ForceSyncField />}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row className="mt-4">
          <Col md="12">
            <Panel
              header={
                <div>
                  c <h2>{t("admin.pages.users.editRestricted")}</h2>
                </div>
              }
            >
              <div>
                <h3>{t("admin.pages.users.noEditTitle")}</h3>
                <ul>
                  <li>{t("admin.pages.users.noEditReason1")}</li>
                  <li>
                    {t("admin.pages.users.noEditReason2part1")}
                    <strong>{t("admin.pages.users.noEditReason2part2")}</strong>.
                    {t("admin.pages.users.noEditReason2part3")}
                  </li>
                </ul>
              </div>
            </Panel>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default EditUser;
