import { SET_COMPANY_PROFILE_FORM } from "../../actions/actionTypes";

// Contstants
export const COMPANY_PROFILE_FORM_NAME = "companyProfileForm";

// INITIAL_STATE
const initialState = {
  // companyProfileForm
  companyProfileForm: {
    form: {},
  },
};

const companyProfileFormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COMPANY_PROFILE_FORM:
      return { ...state, companyProfileForm: { form: payload } };
    default:
      return state;
  }
};

export default companyProfileFormReducer;

// action dispatchers
export const setCompanyProfileForm = (value) => (dispatch, getState) => {
  dispatch({ type: SET_COMPANY_PROFILE_FORM, payload: value });
};
