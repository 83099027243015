import { Col, Row } from "react-bootstrap";
import AuthenticationMethod from "../../components/integrationComponents/authenticationMethod";
import NonTransactionSettings from "../../components/integrationComponents/nonTransactionSettings";
import TransactionSettings from "../../components/integrationComponents/transactionSettings";

export const SapOnPremiseAI = () => {
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <AuthenticationMethod />
      </Row>

      <Row className="m-0">
        <TransactionSettings />
      </Row>

      <Row className="m-0">
        <NonTransactionSettings />
      </Row>
    </Col>
  );
};
