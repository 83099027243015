import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../../forms/bootstrapFields";
import { onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { restApiService } from "../../../../../providers/restApi";
import { WrappedFieldProps } from "redux-form";
import useIsMounted from "components/common/hooks/useIsMounted";
import { IDType } from "services/common/types/common.type";

interface WareHousePickerPropsType extends WrappedFieldProps {
  label?: ReactNode;
  input: any;
  required?: boolean;
  tooltip?: string;
  disabled?: boolean;
  floating?: boolean;
  callBack?: (whTaxCode: WhTaxObjType) => void;
  instanceId?: string;
}

type WhTaxObjType = {
  id?: IDType;
  credit_account_id?: number;
  name?: string;
  status?: string;
  label?: string;
  rate?: string | number;
  tax_rates?: any;
  code?: string;
};

const WhTaxPicker = ({
  label,
  input,
  required,
  tooltip,
  disabled,
  floating = false,
  callBack,
  instanceId = "wh-tax-picker",
}: WareHousePickerPropsType) => {
  const [whTaxes, setWhTaxes] = useState<WhTaxObjType[]>([]);
  const isMounted = useIsMounted();
  const [selected, setSelected] = useState<WhTaxObjType>();

  const parseForSelect = (options: WhTaxObjType[]) => {
    return options.map((option: WhTaxObjType) => {
      return {
        ...option,
        value: option.id,
        label: option.code,
        rate: option.rate,
        status: option.status,
      };
    });
  };

  const getTaxes = useCallback(async () => {
    const result: AxiosResponse = await restApiService.get(
      `taxes.lk`,
      { TYPES: "WH_TAX", ACTIVE: "YES" },
      null,
      true,
      null,
      true,
    );
    if (result?.data) {
      let list = parseForSelect(result?.data);
      if (isMounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Tax --",
              value: "",
              rate: undefined,
              status: undefined,
            },
            ...list,
          ];
        }
        setWhTaxes(list);
      }
      return list;
    }
  }, []);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const obj = _.find(options, (option) => option.value === input.value);
      if (obj) {
        selectedValues = obj;
      } else {
        const result = await restApiService.get("taxes/" + input.value);
        const inactiveTax = {
          value: result.data.id,
          label: (
            <>
              {result.data.name} - <small style={{ fontSize: "10px" }}>({result.data.status})</small>
            </>
          ),
        };
        selectedValues = inactiveTax;
      }
      return selectedValues ? selectedValues : null;
    }
  };

  const onChangeWhTaxCode = (input: any, selected: any) => {
    onChangeSingle(input, selected);
    setSelected(selected);
    if (callBack) {
      callBack(selected);
    }
  };

  useEffect(() => {
    getTaxes().then((res) => {
      findSelected(input, res).then((response: WhTaxObjType) => {
        setSelected(response);
      });
    });
  }, []);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={selected}
          onChange={(selected) => onChangeWhTaxCode(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={whTaxes}
          disable={disabled}
          getOptionLabel={(option: WhTaxObjType) => {
            return (
              <>
                {!option.id && <span className="formField">{option.label}</span>}
                {option.id && (
                  <small>
                    Code: {option.label} Rate: {option.rate}%
                    {option.status === "INACTIVE" && <span>{option.status}</span>}
                  </small>
                )}
              </>
            );
          }}
          filterOption={(option: any, searchText) =>
            option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())
          }
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
            }),
            ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
          }}
          {...(floating ? { menuPortalTarget: document.body } : {})}
          instanceId={instanceId}
        />
      </Form.Group>
    </>
  );
};

export default WhTaxPicker;
