import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { IDType } from "services/common/types/common.type";
import { IntegrationTypes } from "./integrationTypes";

class IntegrationApis {
  static async getIntegrationJobs(id: IDType) {
    try {
      const response: AxiosResponse<IntegrationTypes.IntegrationJobsType> = await restApiService.get(
        "integration_settings/" + id + "/jobs_detail",
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async editIntegrationJobs(id: IDType, jobsPayload: IntegrationTypes.EditIntegrationJobsPayloadType) {
    try {
      const response: AxiosResponse<IntegrationTypes.EditIntegrationJobsPayloadType> = await restApiService.patch(
        "integration_settings/" + id + "/update_jobs_detail",
        null,
        jobsPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
export default IntegrationApis;
