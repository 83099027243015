import React, { ChangeEvent } from "react";
import { Field } from "redux-form";
import { PaymentFieldType } from "../types";
import { RenderSelect } from "../../../forms/bootstrapFields";
import { useDispatch } from "react-redux";
import { CHANGE_PAYMENT_STATUS } from "../../../../actions/actionTypes";

type StatusTypes = PaymentFieldType;

const Status = ({ validations, required, label }: StatusTypes) => {
  const dispatch = useDispatch();
  const changePaymentStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: CHANGE_PAYMENT_STATUS, payload: e.target.value });
  };

  return (
    <Field
      name="status"
      label={label}
      component={RenderSelect}
      options={[
        { label: "", value: "" },
        { label: "ACTIVE", value: "ACTIVE" },
        { label: "INACTIVE", value: "INACTIVE" },
      ]}
      required={required}
      validate={validations}
      onChange={changePaymentStatus}
    />
  );
};

export default Status;
