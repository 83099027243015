import React, { useCallback, useEffect, useState } from "react";
import { useTypedSelector } from "reducers";
import { selectCurrentUser } from "reducers/userReducers";
import companyService from "services/common/company/companySvc";
import { CommonTypes } from "services/common/types/commonTypes";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

// custom Hook will return company only currency code if setting is TURNED ON else all currencies that can be use by app
const useAdminCompanyCurrencyCode = () => {
  const [companyCurrencies, setCompnayCurrencies] = useState<CommonTypes.ListCurrencyCodeItem>([]);
  const currentUser = useTypedSelector(selectCurrentUser);
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);

  const getCompanyCurrencyCodes = useCallback(async () => {
    try {
      if (currentUser.company?.global?.allow_only_company_currency_codes && appCurrencyCodes) {
        const currencyCodes = await companyService.getCompanyCurrencies(appCurrencyCodes);
        setCompnayCurrencies(currencyCodes);
      } else if (appCurrencyCodes) {
        setCompnayCurrencies(appCurrencyCodes);
      }
    } catch (error) {}
  }, [appCurrencyCodes, currentUser.company?.global?.allow_only_company_currency_codes]);

  useEffect(() => {
    getCompanyCurrencyCodes();
  }, [appCurrencyCodes, getCompanyCurrencyCodes]);

  return {
    companyCurrencies,
  };
};

export default useAdminCompanyCurrencyCode;
