import React, { memo } from "react";
import Panel from "../../../panel/panel";
import { Table } from "react-bootstrap";
import { change, Field, FieldArray } from "redux-form";
import { RenderCheckBox } from "../../../forms/bootstrapFields";
import ExpenseLinesRenderer from "./expenseLinesRenderer/expenseLinesRenderer";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
const InvoiceFormName = "InvoiceForm";

const isAllowToShow = (poExpenses, field) => {
  if (_.isArray(poExpenses) && poExpenses.length > 0) {
    return poExpenses.some((exp) => exp[field] !== null);
  }
};

const ExpenseLine = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectAll = (value) => {
    props.poExpenses.map((item) => {
      item.isAllow = value;
    });
    dispatch(change(InvoiceFormName, "poExpenses", props.poExpenses));
  };
  return (
    <Panel
      panelContent={{
        heading: t("details.expenses"),
        iconClass: "icon icon-gl",
      }}
      cardBodyStyle={{ padding: "0px" }}
    >
      <Table hover size="sm" className="list-table">
        <thead>
          <tr>
            <th>
              <Field name="selectAllExpenses" component={RenderCheckBox} onChange={(e) => selectAll(e)} />
            </th>
            <th>{t("details.account")}</th>
            <th>{t("details.project")}</th>
            <th>{t("details.memo")}</th>
            {isAllowToShow(props.poExpenses, "sub_amount") ? <th>{t("details.subAmount")}</th> : null}
            {isAllowToShow(props.poExpenses, "tax") ? <th>{t("details.tax")}</th> : null}
            <th>{t("details.totalAmount")}</th>
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="poExpenses"
            component={ExpenseLinesRenderer}
            isSubAmountAvailable={isAllowToShow(props.poExpenses, "sub_amount")}
            isTaxAvailable={isAllowToShow(props.poExpenses, "tax")}
          />
        </tbody>
      </Table>
    </Panel>
  );
};

export default memo(ExpenseLine);
