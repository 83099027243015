import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Field, getFormValues } from "redux-form";
import { COMPANY_PAYMENT_METHOD_FORM_NAME } from "../../../../reducers/admin/companyPaymentMethodFormReducer";
import CountryPicker from "../../../common/pickers/reduxPickers/countryPicker";
import StatePicker from "../../../common/pickers/reduxPickers/statePicker";
import { RenderField } from "../../../forms/bootstrapFields";

const AddressDetails = ({
  isAddress1Required,
  isAddress2Required,
  isCityRequired,
  isStateRequired,
  isCountryRequired,
  isZipRequired,
  address1Validaitions,
  address2Validaitions,
  cityValidaitions,
  stateValidations,
  countryValidations,
  zipCodeValidations,
}) => {
  const { t } = useTranslation();
  const { form } = useSelector((state) => getFormValues(COMPANY_PAYMENT_METHOD_FORM_NAME)(state));

  return (
    <Col md="12">
      <Row>
        <Col md="6">
          <Field
            component={RenderField}
            id="companyPaymentMethod.address_attributes.address1"
            label={t("companySettings.paymentMethodsSettings.address1")}
            name="form.address_attributes.address1"
            required={isAddress1Required}
            validate={address1Validaitions ? address1Validaitions : []}
          />
        </Col>
        <Col md="6">
          <Field
            component={RenderField}
            id="companyPaymentMethod.address_attributes.address1"
            label={t("companySettings.paymentMethodsSettings.address2")}
            name="form.address_attributes.address2"
            required={isAddress2Required}
            validate={address2Validaitions ? address2Validaitions : []}
          />
        </Col>
        <Col md="6">
          <Field
            component={RenderField}
            id="companyPaymentMethod.address_attributes.city"
            label={t("companySettings.paymentMethodsSettings.city")}
            name="form.address_attributes.city"
            required={isCityRequired}
            validate={cityValidaitions ? cityValidaitions : []}
          />
        </Col>
        <Col md="6">
          <Field
            component={CountryPicker}
            id="companyPaymentMethod.address_attributes.country"
            label={t("companySettings.paymentMethodsSettings.country")}
            name="form.address_attributes.country"
            required={isCountryRequired}
            validate={countryValidations ? countryValidations : []}
          />
        </Col>

        <Col md="6">
          <Field
            component={StatePicker}
            id="companyPaymentMethod.address_attributes.state"
            label={t("companySettings.paymentMethodsSettings.state")}
            name="form.address_attributes.state"
            countryCode={form && form.address_attributes && form.address_attributes.country}
            required={isStateRequired}
            validate={stateValidations ? stateValidations : []}
          />
        </Col>

        <Col md="6">
          <Field
            component={RenderField}
            id="companyPaymentMethod.address_attributes.zipcode"
            label={t("companySettings.paymentMethodsSettings.zipcode")}
            name="form.address_attributes.zipcode"
            required={isZipRequired}
            validate={zipCodeValidations ? zipCodeValidations : []}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default AddressDetails;
