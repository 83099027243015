import { ColumnApi, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import ToggleFilterButton from "components/datagrid/buttons/toggleFilterButton";
import GridFilterDropdown from "components/datagrid/gridFilterDropdown";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import { useTypedSelector } from "reducers";

import { Can } from "services/authorization/authorization";
import { getParams, saveDefaultOrder } from "services/common/gridService";
import { CreateNotification, NotificationType } from "services/general/notifications";
import gridService from "services/grid/gridSvc";

import { FIELD_NAME_MAP, getWombatHeaders } from "wombatifier/components/wombatifier/listWombatHeaders";
import { WombatType } from "wombatifier/services/wombat.types";
import { WombatApis } from "wombatifier/services/wombatApis";

const GRID_STORAGE_NAME = "listWombat";

export const ListWombat = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const { showFilters, updateShowFilters } = useShowFilterState("listWombatFilter", true);
  const currentUser = useTypedSelector((state) => state.user);
  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();
  const checkBoxRef: any = useRef(null);

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: getRows });
  };

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = async (params: IServerSideGetRowsParams) => {
    try {
      let filterParams = getParams(params, FIELD_NAME_MAP);
      filterParams["sort"] = { ...{ created_at: { priority: 1, direction: "desc" }, ...filterParams["sort"] } };
      const wombats = await WombatApis.list({
        filter: { ...filterParams, by_paymerang_outbound_integrated: 1 },
      });
      params.success({
        rowData: wombats,
        rowCount: wombats.length,
      });
    } catch (error) {
      params.fail();
    }
  };

  const deleteConfirmCallBack = async (wombat: WombatType) => {
    try {
      await WombatApis.delete(wombat.id);
      if (gridApi) {
        gridService.removeRowFromGrid(gridApi, String(wombat.id));
      }
      CreateNotification(t("success"), t("admin.pages.wombat.deleteWombat"), NotificationType.success);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteWombat = (wombat: WombatType) => {
    createConfirmModal({
      title: "Confirm",
      body: `Are you sure about deleting Wombat #${wombat.id} ["${wombat.name}"]?`,
      saveCallBack: deleteConfirmCallBack,
      cancelCallBack: null,
      callBackData: wombat,
    });
  };

  const gridHeaders = getWombatHeaders({ gridApi, gridColumnApi, currentUser, deleteWombat, checkBoxRef });

  useEffect(() => {
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
  }, []);

  return (
    <>
      <Row style={{ margin: "10px 25px" }}>
        <Col className="px-0">
          <GridFilterDropdown gridApi={gridApi} gridColumnApi={gridColumnApi} gridStorageName={GRID_STORAGE_NAME} />
          <Link to="/ap/wombatifier/add">
            <Can I="add" a="Vendors">
              <Button variant="primary ml-2">
                <i className="btn-icon icon-add-btn"></i>
                Add
              </Button>
            </Can>
          </Link>
        </Col>
        <Col className="px-0" style={{ flex: 0 }}>
          <ToggleFilterButton
            clickCallback={() => {
              updateShowFilters(!showFilters);
            }}
          />
        </Col>
      </Row>
      <Row style={{ height: "70vh", margin: "25px" }}>
        <ServerSideDataGrid
          columnDefs={gridHeaders.columnDefs}
          defaultColDef={{
            resizable: true,
            filter: true,
            floatingFilter: showFilters,
          }}
          onPaginationChanged={() => checkBoxRef?.current?.onPaginationChange()}
          gridReady={gridReady}
          rowSelection="multiple"
          gridStorageName={GRID_STORAGE_NAME}
          domLayout="normal"
          paginationSize={25}
          paginationOptions={[25, 50, 100]}
          pagination
        />
      </Row>
    </>
  );
};
