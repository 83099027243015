export enum DocumentableTypes {
  Company = "Company",
}

export enum DocumentFields {
  DocumentableType = "documentable_type",
}

export interface ObjectCreationResultsLineObjects {
  entity_type: string;
  entity_number: string;
  is_success: boolean;
  message: string;
  entity_id: number;
  row_number: number;
}

export interface ObjectCreationResults {
  results: any;
  total_count: number;
  created_count: number;
  success_count: number;
  error_count: number;
  found_count: number;
  linked_count: number;
}

export interface ParsingResults {
  total_records: number;
  success_records: number;
  failed_records: number;
  status: string;
  job_id: string;
}

export interface Wombat {
  id: number;
  name: string;
  description: string;
  workflow_type: string;
  schema_version: number;
  source_type: string;
  destination_type: string;
  created_at: string;
  updated_at: string;
  company_id: number;
}
