import React from "react";
import { ObjectPropertiesType } from "../../../../../services/admin/invoices/recurringInvoice/recurringInvoiceTypes";
import Panel from "../../../../common/panel/panel";
import { Table } from "react-bootstrap";
import _ from "lodash";
import { formattedAmount } from "../../../../../services/general/helpers";

type LineItemsPropsType = {
  invoice?: ObjectPropertiesType;
};

const LineItems = ({ invoice }: LineItemsPropsType) => {
  return (
    <Panel>
      <Table className={`mb-1 text-left`} responsive striped bordered>
        <thead>
          <tr>
            <th>Item #</th>
            <th>Unit Price</th>
            <th>Qty</th>
            <th>Amount</th>
            <th>Tax</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {(!invoice ||
            !invoice.invoice_items_attributes ||
            (invoice &&
              _.isArray(invoice.invoice_items_attributes) &&
              invoice.invoice_items_attributes.length < 1)) && (
            <tr>
              <td colSpan={6} className="text-center">
                There are no line items.
              </td>
            </tr>
          )}
          {invoice &&
            _.isArray(invoice.invoice_items_attributes) &&
            invoice.invoice_items_attributes.map((invoice_item) => (
              <tr key={invoice_item.id}>
                <td>{invoice_item.product_name ? invoice_item.product_name : invoice_item.product_item.name}</td>
                <td>{formattedAmount(invoice_item.unit_price?.toString(), invoice.currency_code)}</td>
                <td>{invoice_item.qty}</td>
                <td>{formattedAmount(invoice_item.amount?.toString(), invoice.currency_code)}</td>
                <td>{formattedAmount(invoice_item.tax?.toString(), invoice.currency_code)}</td>
                <td>{formattedAmount(invoice_item.total?.toString(), invoice.currency_code)}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Panel>
  );
};

export default LineItems;
