import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Styles from "../override.module.css";
import { findSelectedSingle, onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import { QuestionPickerPropsType } from ".";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";

export interface SingleQuestionPickerPropsType extends Omit<QuestionPickerPropsType, "approvalType"> {
  questions: { value: string; label: string }[];
}

const SingleQuestionPicker = ({
  className,
  tooltip,
  label,
  input,
  meta: { touched, error, warning },
  questions,
}: SingleQuestionPickerPropsType) => {
  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label>
          {label}
          {tooltip}
        </Form.Label>
      )}
      <Select
        {...input}
        required
        value={findSelectedSingle(input, questions)}
        placeholder="search/select"
        onChange={(value) => onChangeSingle(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={questions}
        isMulti={false}
        isClearable
        classNamePrefix="select"
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

export default SingleQuestionPicker;
