import React from "react";
import { Spinner } from "react-bootstrap";
import style from "./spinners.module.css";

const Loader = ({ loaded, children }) => {
  if (!loaded)
    return (
      <div className={style.pageLoader}>
        <Spinner animation="border" variant="info" />
      </div>
    );
  return <>{children}</>;
};

export default Loader;
