import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";

const AccountExternalIdField = ({ label, isExternalIdRequired, validations }) => {
  return (
    <Col md="6">
      <Field
        id="companyPaymentMethod.account_external_id"
        name="form.account_external_id"
        component={RenderField}
        type="text"
        label={label}
        required={isExternalIdRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default AccountExternalIdField;
