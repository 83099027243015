import React from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";

type acceptContactDetailModalProps = {
  show: boolean;
  closeCallback: () => void;
  saveCallback: (
    contact: {
      raw: any;
    },
    sendWelcomeEmail: boolean,
  ) => void;
  contact: any;
};

const AcceptContactDetailModal = ({ show, closeCallback, contact, saveCallback }: acceptContactDetailModalProps) => {
  return (
    <Modal show={show} onHide={closeCallback} dialogClassName="bigModal">
      <Modal.Header closeButton>
        <Modal.Title>Accept Contact Details</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col>
            <p>
              Now that you've confirmed {contact.name}, give them access to the portal to help manage invoices,
              customers, and track payments and messages. You can manage their role settings in your AR contacts
              section.
            </p>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            saveCallback(contact, false);
          }}
        >
          No Thanks
        </Button>
        <Button
          onClick={() => {
            saveCallback(contact, true);
          }}
        >
          Send Invite
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptContactDetailModal;
