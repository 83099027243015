import React, { Component } from "react";
import "./chart.style.scss";
import "../../pages/pages.style.css";
import { withTranslation } from "react-i18next";

class InvoiceProgressBar extends Component {
  render() {
    let invoiceProgressObj = [
      {
        name: this.props.t("dashboardPage.current"),
        amount: this.props.ProgressBarObj.current_amount,
        customer: this.props.ProgressBarObj.current_amount_customer,
        bgColor: "#00A0DF",
      },
      {
        name: "0 - 30 " + this.props.t("dashboardPage.days"),
        amount: this.props.ProgressBarObj.overdue_30_days,
        customer: this.props.ProgressBarObj.overdue_30_days_customer,
        bgColor: "#2ed9c3",
      },
      {
        name: "31 - 60 " + this.props.t("dashboardPage.days"),
        amount: this.props.ProgressBarObj.overdue_60_days,
        customer: this.props.ProgressBarObj.overdue_60_days_customer,
        bgColor: "#0033a1",
      },
      {
        name: "60+ " + this.props.t("dashboardPage.days"),
        amount: this.props.ProgressBarObj.overdue_60_plus_days,
        customer: this.props.ProgressBarObj.overdue_60_plus_days_customer,
        bgColor: "#ff5c35",
      },
    ];
    return (
      <div className="progress progressContainer progressContainer" style={{ borderRadius: 0 }}>
        {invoiceProgressObj.map((data, index) => (
          <div
            key={index}
            className="progress-bar text-left pl-4 progressList"
            role="progressbar"
            style={{ width: "25%", position: "relative", background: data.bgColor }}
          >
            <p className={"h5 m-0 pl-3 pb-3"}>{data.name}</p>
            <h2 className={"h3 m-0 pl-3 text-white"}>{data.amount}</h2>
            <p className={"h6 m-0 pl-3"}>
              <small>
                {data.customer} {this.props.t("dashboardPage.customer")}
              </small>
            </p>
            <div
              className="triangle-right d-none d-md-block"
              style={{
                width: "5%",
                height: 0,
                borderTop: "62px solid transparent",
                borderLeft: "30px solid " + data.bgColor,
                borderBottom: "62px solid transparent",
                position: "absolute",
                right: "-30px",
                zIndex: 1000,
              }}
            ></div>
          </div>
        ))}
      </div>
    );
  }
}

export default withTranslation()(InvoiceProgressBar);
