import { RenderField } from "components/forms/bootstrapFields";
import useConfirmModal from "components/modals/confirmModal/useConfirmModalHook";
import TooltipRender from "components/toolTip/tooltipRender";
import _ from "lodash";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "reducers";
import { Field, change, getFormValues } from "redux-form";
import { IDType } from "services/common/types/common.type";

type RenderExternalIdFieldType = {
  modalObjFieldName: string; // on which object external id is preset
  formName: string; // for which redux from we are using this
  showSyncOn?: boolean; // parent will be responsible for whether to show hide this button
  showSyncOff?: boolean;
  showFetch?: boolean;
  onFetchClick?: () => void; // what function to execute on cloud download button is clicke
};

const RenderExternalIdField = ({
  modalObjFieldName,
  formName,
  showSyncOff,
  showSyncOn,
  onFetchClick,
  showFetch,
}: RenderExternalIdFieldType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disableExternalId, setDisableExternalId] = useState(true);
  const { createConfirmModal } = useConfirmModal();
  const formData: unknown = useTypedSelector((state) => getFormValues(formName)(state));
  const fieldData: (unknown & { external_id?: IDType }) | undefined = _.get(formData, modalObjFieldName);

  const onExternalIdEdit = () => {
    createConfirmModal({
      title: t("components.admin.renderExternalId.confirmEditExternalId"),
      body: t("components.admin.renderExternalId.confirmText"),
      saveCallBack: () => {
        setDisableExternalId(false);
        dispatch(change(formName, `${modalObjFieldName}.external_id_changed_flag`, true));
      },
      callBackData: null,
      cancelCallBack: null,
    });
  };

  return (
    <Field
      name={`${modalObjFieldName}.external_id`}
      label={
        <div className="d-flex justify-content-between">
          <div>
            <span className="px-mr-4"> {t("components.admin.renderExternalId.externalID")} </span>
            {showSyncOn && fieldData?.external_id && (
              <Button size="sm" className="px-mr-16">
                <TooltipRender
                  title={t("components.admin.renderExternalId.syncOn")}
                  className="icon-sm icon-link-white"
                />
              </Button>
            )}

            {showSyncOff && fieldData?.external_id && (
              <Button size="sm">
                <TooltipRender
                  title={t("components.admin.renderExternalId.syncOff")}
                  className="icon-sm icon-link-white"
                />
              </Button>
            )}

            {disableExternalId && (
              <Button variant="secondary" size="sm" className="mx-2" onClick={() => onExternalIdEdit()}>
                <i className="icon icon-edit-black m-0" />
              </Button>
            )}
          </div>
          <div>
            {showFetch && fieldData?.external_id && (
              <Button variant="secondary" onClick={() => onFetchClick && onFetchClick()}>
                <i className="icon icon-cloud-download-black"></i>
              </Button>
            )}
          </div>
        </div>
      }
      labelClassName="w-100"
      component={RenderField}
      disabled={disableExternalId}
      id={`${modalObjFieldName}_external_id`}
    />
  );
};

export default RenderExternalIdField;
