import usePermission from "components/common/hooks/usePermission";
import TabNavigation from "components/navigation/tabNavigation";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "reducers";
import adminCommonSvc from "services/admin/commonSvc";
import { IUser } from "services/common/user/userTypes";

const DashboardTabs = () => {
  const [hideMainDashboard, setHideMainDashboard] = useState<boolean>(false);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const { hasUserModule, hasUserPermission, hasUserRole } = usePermission();
  const { t } = useTranslation();

  // TODO: refator dashboard routes
  //hide main dashboard if user is not allowed to see all panels of dashboard
  const showHideDashboard = () => {
    const isAdminUser = currentUser.role == "admin" || currentUser.role == "admin_readonly";

    const isPoManagerUser = currentUser.role == "po_manager" || currentUser.role == "po_admin";

    //to check if user has permission to see any of the panel in dashboard for user as per role
    const hasAdminDashboardPanelAccess =
      hasUserPermission("readActionableItemsDashboard") ||
      hasUserPermission("readSpendByVendorDashboard") ||
      hasUserPermission("readTopFivePurchaseOrderBalancesDashboard") ||
      hasUserPermission("readStatsDashboard") ||
      (hasUserPermission("listPayments") && currentUser.company.show_action_required);

    const hasPoAdminDashboardPanelAccess =
      hasUserPermission("readTopFiveOpenPOsDashboard") ||
      hasUserPermission("readActionableItemsDashboard") ||
      hasUserPermission("readTopFiveOpenInvoicesDashboard") ||
      hasUserPermission("readLastFiveRecentlyProcessedPaymentsDashboard");

    const hasStaffDashboardPanelAccess =
      hasUserPermission("readTopFiveOpenPOsDashboard") ||
      hasUserPermission("readActionableItemsDashboard") ||
      hasUserPermission("readTopFiveOpenInvoicesDashboard") ||
      hasUserPermission("readLastFiveRecentlyProcessedPaymentsDashboard");

    if (isAdminUser) {
      setHideMainDashboard(!hasAdminDashboardPanelAccess);
    } else if (isPoManagerUser) {
      setHideMainDashboard(!hasPoAdminDashboardPanelAccess);
    } else {
      setHideMainDashboard(!hasStaffDashboardPanelAccess);
    }
  };

  useEffect(() => {
    showHideDashboard();
  }, []);

  const dashboardTabsRoutes = [
    {
      key: "mainDashboard",
      hide: hideMainDashboard,
      pageName: (
        <>
          <i className="icon icon-dash"></i>
          {t("admin.mainDashboard")}
        </>
      ),
      href: "dashboard",
    },
    {
      key: "accrualDashboard",
      hide: !hasUserModule("accruals_visible"),
      pageName: (
        <>
          <i className="icon icon-accruals"></i>
          {t("menu.accruals")}
        </>
      ),
      href: "dashboard/accruals",
      authorization: {
        permissions: ["listAccruals", "dashboardAccruals"],
      },
    },
    {
      key: "paymentDashboard",
      hide: !(hasUserModule("payments_visible") && adminCommonSvc.isNvpIntegratedCustomer(currentUser)),
      pageName: (
        <>
          <i className="icon icon-payments-tab"></i>
          {t("menu.payments")}
        </>
      ),
      path: "/dashboard/payments",
      authorization: {
        permissions: ["listPayments"],
      },
    },
    {
      key: "receivingDashboard",
      hide: !hasUserModule("receiving_visible"),
      pageName: (
        <>
          <i className="icon icon-receiving"></i>
          {t("menu.receiving")}
        </>
      ),
      href: "dashboard/receiving",
      authorization: {
        permissions: ["listReceipts", "dashboardReceiving"],
      },
    },
    {
      key: "cardDashboard",
      hide: !hasUserModule("virtual_cards_visible"),
      pageName: (
        <>
          <i className="icon icon-vcards"></i>
          {t("menu.corporateCards")}
        </>
      ),
      href: "dashboard/corporate_cards",
      authorization: {
        permissions: ["listVCards", "requestVCards"],
      },
    },
    {
      key: "expenseDashboard",
      hide: !hasUserModule("expenses_visible"),
      pageName: (
        <>
          <i className="icon expenses"></i>
          {t("menu.expenseDashboard")}
        </>
      ),
      path: "/ap/dashboard/expense",
      authorization: {
        permissions: ["listExpenseReports"],
      },
    },
  ];

  return (
    <Row className="dashboard-tabs-header">
      <Col className="p-0">
        <TabNavigation navigationTab={dashboardTabsRoutes} />
      </Col>
    </Row>
  );
};

export default DashboardTabs;
