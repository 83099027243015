import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { restApiService } from "../../../../../providers/restApi";
import Select, { MenuPosition } from "react-select";
import Styles from "../override.module.css";
import {
  compare,
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelectCustom,
} from "../../../../../services/general/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";
import {
  PickerComparisonField,
  pickerComparisonFieldStyle,
} from "../../../../common/pickers/reduxFormPickers/select/helpers";
import { AxiosResponse } from "axios";
import _ from "lodash";
import { WrappedFieldProps } from "redux-form";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";
import useIsMounted from "components/common/hooks/useIsMounted";

interface TaxCodePickerPropsType extends WrappedFieldProps {
  className?: string;
  labelClassName?: string;
  label?: string;
  isMulti: boolean;
  tooltip?: string;
  required: boolean;
  menuPosition?: MenuPosition;
  instanceId?: string;
  callBack?: (taxCode: any) => void;
  placeholder?: string;
  disabled?: boolean;
  transparent?: boolean;
  subsidiary_id?: number;
  originalValue?: any;
  parentObj?: any;
  isUseTax?: boolean;
  modelData?: { [key: string]: any };
  isClearableDisable?: boolean;
}

interface TaxCodeOptionsType {
  id: number;
  code: string | null;
  credit_account_id: number | null;
  rate: number;
  tax_rates: Array<any>;
}

const TaxCodePicker = ({
  labelClassName,
  label,
  input,
  meta: { touched, error },
  isMulti,
  tooltip,
  required,
  parentObj,
  menuPosition,
  instanceId = "taxCode-selector",
  callBack,
  placeholder = "search/select",
  disabled,
  transparent,
  subsidiary_id,
  originalValue,
  isUseTax,
  modelData,
  isClearableDisable,
}: TaxCodePickerPropsType) => {
  const [taxCodes, setTaxCodes] = useState<any[]>([]);
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const [originalLabel, setOriginalLabel] = useState(null);
  const isMounted = useIsMounted();

  const createSelectOptions = (options: any) => {
    return options
      .map((option: any) => {
        const label = (
          <span className="formField">
            {option.rate > 0 ? `Code: ${option.code} Rate: ${option.rate}%` : `Code: ${option.code}`}
          </span>
        );
        return {
          value: option.id,
          label,
          rate: option.rate,
          id: option.id,
          tax_rates: option.tax_rates ? option.tax_rates : null,
        };
      })
      .sort(compare);
  };

  const getTaxIds = (fieldName: string) => {
    return _.isPlainObject(parentObj) &&
      parentObj?.custom_fields &&
      parentObj?.custom_fields[fieldName] &&
      parentObj.custom_fields[fieldName]["value"] &&
      parentObj.custom_fields[fieldName]["value"]["mapping"] &&
      parentObj.custom_fields[fieldName]["value"]["mapping"]["items"]
      ? parentObj.custom_fields[fieldName]["value"]["mapping"]["items"]
      : null;
  };

  const getTaxCodes = useCallback(async () => {
    let params: { [key: string]: any } = { ACTIVE: "YES" };
    if (isUseTax) {
      params.TYPES = "USED_TAX";
      if (modelData?.is_tax_map_to_location) {
        params.location_id = modelData.location_id;
      }
    }
    if (currentUser?.company?.show_subsidiary_mapped_taxes && subsidiary_id && subsidiary_id > 0) {
      params.subsidiary_id = subsidiary_id;
      if (parentObj?.subsidiary?.country) {
        params.country_code = parentObj?.subsidiary?.country;
      }
    }

    if (parentObj && parentObj.filter_data_field_name && parentObj.filter_data_to === "tax_id") {
      let taxIds = getTaxIds(parentObj.filter_data_field_name);
      if (_.isArray(taxIds) && taxIds.length > 0) {
        if (modelData) {
          modelData.only_show_mapped_tax = true;
        }
        params.tax_ids = taxIds.toString();
      }
    }

    const result: AxiosResponse<TaxCodeOptionsType[]> = await restApiService.get(
      "taxes.lk",
      params,
      null,
      true,
      null,
      true,
    );
    if (result && result.data && isMounted.current) {
      let list = createSelectOptions(result.data);
      if (_.isArray(list)) {
        setTaxCodes([{ label: "-- Select Tax --", value: "" }, ...list]);
      }
    }
  }, [subsidiary_id, getTaxIds(parentObj?.filter_data_field_name)]);

  const getCustomConfig = useMemo(() => {
    let componentConfig: { [key: string]: any } = {};
    if (originalValue !== undefined) {
      componentConfig.Input = (props: any) => <PickerComparisonField {...props} originalValue={originalLabel} />;
    }
    if (transparent) {
      componentConfig.DropdownIndicator = () => null;
    }
    return componentConfig;
  }, [!!originalValue, originalLabel, transparent]);

  const onChangeTaxCode = (input: any, value: any) => {
    onChangeSingle(input, value);
    if (callBack) {
      callBack(value);
    }
  };

  useEffect(() => {
    getTaxCodes();
  }, [subsidiary_id, isUseTax, getTaxIds(parentObj?.filter_data_field_name)]);

  useEffect(() => {
    if (originalValue && taxCodes.length > 0 && isMounted.current) {
      setOriginalLabel(findSelectedSingle({ value: originalValue }, taxCodes)?.label);
    }
  }, [taxCodes, originalValue]);

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label className={labelClassName}>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        components={getCustomConfig}
        value={isMulti ? findSelectedMultiple(input, taxCodes) : findSelectedSingle(input, taxCodes)}
        placeholder={placeholder ? placeholder : "Select..."}
        onChange={isMulti ? (value) => onChange(input, value) : (value) => onChangeTaxCode(input, value)}
        onBlur={isMulti ? () => onBlur(input, input.value) : () => onBlurSingle(input, input.value)}
        options={taxCodes}
        isMulti={isMulti ? true : false}
        isClearable={false}
        classNamePrefix="select"
        menuPosition={menuPosition}
        isDisabled={disabled}
        instanceId={instanceId}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...(originalValue !== undefined ? pickerComparisonFieldStyle : {}),
            ...(transparent ? { backgroundColor: "transparent", border: "none" } : {}),
          }),
        }}
      />
      {error && touched && <div className="pickerError">{error}</div>}
    </Form.Group>
  );
};

export default TaxCodePicker;
