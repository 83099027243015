import React from "react";
import CheckboxFilter from "../reportFilters/checkbox";
import InputFilter from "../reportFilters/input";
import DateFilter from "../reportFilters/date";
import { useTypedSelector } from "reducers";
import { CompanyType } from "services/common/user/userTypes";
import { shallowEqual } from "react-redux";

const InvoiceApAging = ({ scheduled }: { scheduled?: boolean }) => {
  const renderDateFilter = () => {
    if (scheduled) {
      return <h5>Records in scheduled Invoice AP Aging Reports are relative to the report generation date.</h5>;
    } else {
      return <DateFilter name={"As of Date"} code={"end_date"} options={[]} isRequired />;
    }
  };

  return (
    <div>
      {renderDateFilter()}
      <InputFilter name={"Cost Category Name"} code={"cost_category_name"} options={[]} />
      <CheckboxFilter name={"Summarize by Vendors"} code={"summarize_by_vendors"} options={[]} />
    </div>
  );
};

export default InvoiceApAging;
