import AddVendorContact from "./add";
import VendorContactDetails from "./details";
import EditVendorContact from "./edit";
import VendorContactsList from "./list";

const vendorContactRoutes = [
  {
    path: "/ar/contacts",
    exact: true,
    name: "contact",
    component: VendorContactsList,
  },
  {
    path: "/ar/contacts/add",
    exact: true,
    name: "addContacts",
    component: AddVendorContact,
    authorization: {
      I: "add",
      a: "Contacts",
    },
  },
  {
    path: "/ar/contacts/:id(\\d+)",
    exact: true,
    name: "contactDetails",
    component: VendorContactDetails,
    authorization: {
      I: "read",
      a: "Contacts",
    },
  },
  {
    path: "/ar/contacts/:id/edit",
    exact: true,
    name: "editContacts",
    component: EditVendorContact,
    authorization: {
      I: "edit",
      a: "Contacts",
    },
  },
];

export default vendorContactRoutes;
