import React, { ReactNode, useEffect, useState } from "react";
import useIsMounted from "../../../common/hooks/useIsMounted";
import { restApiService } from "../../../../providers/restApi";
import { findSelectedSingle, onBlurSingle, onChangeSingle, parseForSelect } from "../../../../services/general/helpers";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { Mandatory } from "../../../forms/bootstrapFields";
import { AxiosResponse } from "axios";

type TaxScheduleType = {
  id: number;
  external_id?: string | number;
  name: string;
  status: string;
  description?: string;
};

type TaxSchedulePickerPropsType = {
  label?: string;
  required: boolean;
  isClearable?: boolean;
  inputId?: string;
  input: {
    value: number;
    onChange: (values: number) => void;
    onBlur: (values: number) => void;
  };
  tooltip: ReactNode;
  meta: {
    touched: boolean;
    error: string;
  };
  filter: any;
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const TaxSchedulePicker = ({
  label,
  input,
  required,
  inputId = "taxScheduleSelector",
  isClearable = false,
  filter = { status: "ACTIVE" },
  tooltip,
  meta: { touched, error },
}: TaxSchedulePickerPropsType) => {
  const [taxSchedules, setTaxSchedules] = useState<{ value: number; label: string }[]>([]);
  const isMounted = useIsMounted();

  const getTaxSchedule = async () => {
    const result: AxiosResponse<TaxScheduleType[]> = await restApiService.get(
      "tax_schedules",
      filter,
      null,
      true,
      null,
      true,
    );
    if (isMounted.current) {
      setTaxSchedules(parseForSelect(result.data));
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      getTaxSchedule();
    }
  }, []);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          <Mandatory required={required} />
        </Form.Label>
      )}
      {tooltip && tooltip}
      <Select
        options={taxSchedules}
        inputId={inputId}
        onChange={(value) => onChangeSingle(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        required={required}
        classNamePrefix="select"
        value={findSelectedSingle(input, taxSchedules)}
        isClearable={isClearable}
      />
      {error && touched && <span className="color-error">{error}</span>}
    </Form.Group>
  );
};

export default TaxSchedulePicker;
