import { Field, getFormValues } from "redux-form";
import { useSelector } from "react-redux";
import { Col, Row, Button } from "react-bootstrap";
import { RenderCheckBox } from "../../../../../../components/forms/bootstrapFields";
import VendorPicker from "../../../../../../components/pickers/reduxFormPicker/vendorPicker";
import { INTEGRATION_FORM_NAME } from "reducers/admin/integrationsReducer";
import RestApi from "providers/restApi";
import { CreateNotification, NotificationType } from "services/general/notifications";

const IntegrationVirtualCard = () => {
  const restApiService = new RestApi();
  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));

  const handleUpdateCardProfiles = async () => {
    const res = await restApiService.post(`integration_settings/${form.id}/refresh_card_programs_profiles`, null, {
      integration_setting: { ...form },
    });
    CreateNotification(
      res?.data?.status,
      res?.data?.base,
      res?.data?.status == "error" ? NotificationType.danger : NotificationType.success,
    );
  };
  return (
    <Col lg="12" className="p-0">
      <Row className="m-0">
        <Col lg="12">Activate Bank Integration</Col>
      </Row>

      <Row className="m-0">
        <Col lg="6">
          <Field
            name="form.sync_virtual_card"
            component={RenderCheckBox}
            label="Virtual Card"
            type="checkbox"
            id="sync_virtual_card"
            defaultValue={true}
          />
        </Col>
        {form.id && form.system_name == "Comdata Web" && (
          <Col lg="6">
            <Button className="m-2" variant="primary" onClick={handleUpdateCardProfiles}>
              Update Card Programs Profiles
            </Button>
          </Col>
        )}
        <Col lg="12">
          <Field name="form.vendor_id" component={VendorPicker} label={"Vcard Payments Vendor"} />
        </Col>
      </Row>
    </Col>
  );
};

export { IntegrationVirtualCard };
