import React from "react";
import { Col, Row } from "react-bootstrap";
import { required } from "../../../../../services/validations/reduxFormValidation";
import PaymentType from "../../fields/paymentType";
import CountryPaymentCurrencyCode from "../../../../common/managePaymentMethod/fields/countryCurrencyCode";

const InternationalTw = () => {
  return (
    <Row>
      <Col md="4">
        <PaymentType label="Payment Type:" required validations={[required]} />
      </Col>
      <Col md="4">
        <CountryPaymentCurrencyCode label="Country Resides:" required validations={[required]} />
      </Col>
    </Row>
  );
};

export default InternationalTw;
