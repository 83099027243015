export const EftCanadaValidation = [
  {
    country_currency: "CAD", // Canada currency
    validations: [
      {
        field: "account_name",
        validate: {
          required: true,
          minlength: "0",
        },
        label: "Account Holder",
        validMessage: "Success",
        errorMessage: "Sorry! Account Holder  is required",
      },
      {
        field: "account_number",
        validate: {
          required: true,
          minlength: "0",
        },
        label: "Account Number",
        validMessage: "Success",
        errorMessage: "Sorry! Account Number  is required",
      },

      {
        field: "account_type",
        validate: {
          required: true,
          minlength: "1",
        },
        label: "Account Type",
        validMessage: "Success",
        errorMessage: "Sorry! Select any one Account Type  is required",
      },
      {
        field: "institution_number",
        validate: {
          required: true,
          minlength: "3",
        },
        label: "Institution Number",
        validMessage: "Success",
        errorMessage: "Sorry! Institution Number is required and it required minimum 3 digits",
      },
      {
        field: "transit_number",
        validate: {
          required: true,
          minlength: "4",
        },
        label: "Transit Number",
        validMessage: "Success",
        errorMessage: "Sorry! Transit Number is required and it required minimum 5 digits",
      },
    ],
  },
];
