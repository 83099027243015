import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import PaymentTypeSelect from "./../fields/paymentTypeSelect";
import AccountType from "./../fields/accountType";
import AccountPayoutCheckBoxes from "./../fields/accountPayoutCheckBoxes";
import ContactFirstName from "../fields/contactFirstName";
import ContactLastName from "../fields/contactLastName";
import AccountHolderName from "../fields/accountHolderName";
import AccountNickName from "../fields/accountNickName";
import BankName from "../fields/bankName";
import CurrencyCode from "../fields/currencyCode";
import BankAccountNumber from "../fields/bankAccountNumber";
import DebitCreditFields from "../fields/debitCreditFields";
import NachaPayments from "../fields/nachaPayments";
import Memo from "../fields/memo";
import CompanyIdentification from "../fields/companyIdentification";
import AchId from "../fields/achId";
import UsBankCompanyId from "../fields/usBankCompanyId";
import { useTranslation } from "react-i18next";
import BsbCode from "../fields/bsbCode";
import BankAddress from "../fields/bankAddress";
import BankRoutingSample from "../../../common/bankingRoutingSample/bankRoutingSample";
import {
  length6,
  maxLength6,
  noWhiteSpaceOnly,
  number,
  required,
} from "../../../../services/validations/reduxFormValidation";
import Status from "../fields/status";
import { useSelector } from "react-redux";

const AusPayNet = () => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const current_user = useSelector((state) => state.user);

  return (
    <>
      <PaymentTypeSelect />
      <AccountType />
      {current_user.is_root ? <Status label={t("companySettings.paymentMethodsSettings.status")} /> : null}
      <AccountPayoutCheckBoxes />

      <Col md={12}>
        <hr />
      </Col>

      <ContactFirstName
        label={t("companySettings.paymentMethodsSettings.contactFirstName")}
        tooltip={<BankRoutingSample />}
      />

      <ContactLastName
        label={t("companySettings.paymentMethodsSettings.contactLastName")}
        tooltip={<BankRoutingSample />}
      />

      <AccountHolderName
        label={t("companySettings.paymentMethodsSettings.accountHolderName")}
        isAccountHolderRequired
        validations={[required, noWhiteSpaceOnly]}
        tooltip={<BankRoutingSample />}
      />
      <BankAccountNumber
        label={t("companySettings.paymentMethodsSettings.bankAccountNumber")}
        isBankAccountRequired={true}
        validations={[required, noWhiteSpaceOnly]}
        tooltip={<BankRoutingSample />}
      />
      <CurrencyCode label={t("companySettings.paymentMethodsSettings.currencyCode")} />
      <AccountNickName label={t("companySettings.paymentMethodsSettings.accountNickName")} />
      <BankName label={t("companySettings.paymentMethodsSettings.bankName")} />
      <BsbCode label={t("companySettings.paymentMethodsSettings.bsbCode")} validations={[length6]} />
      <DebitCreditFields />

      <Memo label={t("companySettings.paymentMethodsSettings.memo")} />
      <CompanyIdentification
        label={t("companySettings.paymentMethodsSettings.APCAIdentification")}
        isCompanyIdRequired
        validations={[required, number, maxLength6]}
      />

      <AchId label={t("companySettings.paymentMethodsSettings.achId")} />
      <BankAddress label={t("companySettings.paymentMethodsSettings.bankAddress")} />

      {!showMore && (
        <Col md="12" className="d-flex justify-content-center align-items-center">
          <span role={"button"} onClick={() => setShowMore(true)} className="primaryLinkColor">
            {t("companySettings.paymentMethodsSettings.showMore")}
          </span>
        </Col>
      )}

      {/* not using conditional rednering, if fields are not mounted then 
            fields not validating i want them to be validated
         */}
      <Col md="12" className={`${showMore ? "d-block" : "d-none"}`}>
        <Row>
          <Col md="12">
            <hr />
          </Col>
          <NachaPayments />
          <UsBankCompanyId label={t("companySettings.paymentMethodsSettings.usBankCompanyId")} />
        </Row>
      </Col>

      {showMore && (
        <Col md="12" className="d-flex justify-content-center align-items-center">
          <span role={"button"} onClick={() => setShowMore(false)} className="primaryLinkColor">
            {t("companySettings.paymentMethodsSettings.showLess")}
          </span>
        </Col>
      )}
    </>
  );
};

export default AusPayNet;
