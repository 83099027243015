import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import { required } from "../../../../forms/inputFields";
import { AccountNumberValidator } from "../../accountNumberValidator";
import { minLength } from "../../../../../services/validations/reduxFormValidation";
import BsbCode from "../../bsbCode";

const TwAud = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const min9 = minLength(9);
  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>

      <Col md="4">
        <BsbCode modelName={`${modelName}`} />
      </Col>

      <Col md="4">
        <AccountNumberValidator
          modelName={`${modelName}`}
          minLen={4}
          maxLength={9}
          label={t("common.paymentMethod.accountNumberLabel")}
          accountNumberType="australian-account-number"
        />
      </Col>

      <Col md="4">
        <Field
          name={`${modelName}.business_number`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.businessNumber")}
          tooltip="Australian business identification number issued by Australian Business Register, could be one of ACN, ABN or ARBN."
          maxLength={14}
          required
          validate={[required, min9]}
        />
      </Col>
    </>
  );
};

export default TwAud;
