import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { findSelectedSingle, onBlurSingle, onChangeSingle } from "services/general/helpers";

type InputType = {
  value: number;
  onChange: (values: number) => void;
  onBlur: (values: number) => void;
};

type CardProgramPickerPropsType = {
  filterByComdataWeb?: boolean;
  filterByActive?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  callBack?: (selected?: number) => void;
  input: InputType;
};

type CardProgramOptionsType = {
  add_funds_vendor_id?: string | number;
  card_type?: string;
  default?: string;
  external_id?: string;
  funding_type?: string;
  id?: number;
  label?: string;
  name?: string;
  provider_name?: string;
  status?: string;
  value?: number;
};

const CardProgramPicker = ({
  filterByComdataWeb,
  filterByActive,
  required,
  disabled,
  placeholder,
  callBack,
  input,
}: CardProgramPickerPropsType) => {
  const [cardProgram, setCardProgram] = useState<CardProgramOptionsType[]>([]);
  const getCardProgram = useCallback(async () => {
    try {
      const params = {
        by_active: filterByActive || undefined,
        by_comdata_web: filterByComdataWeb || undefined,
      };

      const result: AxiosResponse<CardProgramOptionsType[]> = await restApiService.get(
        "card_programs",
        params,
        null,
        true,
        null,
        true,
      );
      if (result?.data) {
        const modifiedOptions = result.data.map((option: CardProgramOptionsType) => ({
          value: option.id,
          label: `${option.external_id ? option.external_id + " - " + option.name : option.name}`,
          ...option,
        }));
        setCardProgram(modifiedOptions);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    getCardProgram();
  }, []);

  const handleOnChange = (selected: SingleValue<void | CardProgramOptionsType>) => {
    onChangeSingle(input, selected);
    if (callBack && selected) {
      callBack(selected?.id);
    }
  };

  return (
    <Select
      placeholder={placeholder}
      onChange={(selected) => handleOnChange(selected)}
      onBlur={() => onBlurSingle(input, input.value)}
      options={cardProgram}
      isDisabled={disabled}
      isClearable
      value={findSelectedSingle(input, cardProgram)}
      classNamePrefix="select"
    />
  );
};
export default CardProgramPicker;
