import React, { useEffect, useState } from "react";
import { currencySymbolRenderer } from "../../../../../services/common/currencySymbolRendererService";
import { translate } from "../../../../../services/general/translation";
import { Link } from "react-router-dom";
import { ApprovalType } from "../../../../../services/admin/approvals/approvalsType";
import { CreateNotification, NotificationType } from "../../../../../services/general/notifications";
import approvalsService from "../../../../../services/admin/approvals/approvalsSvc";
import useConfirmModal from "../../../../modals/confirmModal/useConfirmModalHook";
import { useTypedSelector } from "../../../../../reducers";
import RejectModal from "./rejectModal";
import { PoChangeOrderType } from "../../../../../services/admin/changeOrders/poChangeOrderApprovalsType";
import useIsMounted from "../../../../common/hooks/useIsMounted";
import ApprovalsApis from "../../../../../services/admin/approvals/approvalsApis";
import ChangeOrderApis from "../../../../../services/admin/changeOrders/changeOrderApis";
import { ColDef } from "ag-grid-community";

const getGrossAmount = (params: any) => {
  return currencySymbolRenderer(params?.data?.currency_code) + params?.data?.amount;
};

const Actions = ({
  data,
  getPoChangeOrderApprovalList,
}: {
  data: PoChangeOrderType.ApprovalListType;
  getPoChangeOrderApprovalList: () => void;
}) => {
  const isMounted = useIsMounted();
  const { createConfirmModal } = useConfirmModal();
  const currentUser = useTypedSelector((state) => state.user);
  const [actionableApprovalDetails, setActionableApprovalDetails] = useState<ApprovalType.DetailsType>();
  const [poChangeOrderDetail, setPoChangeOrderDetial] = useState<PoChangeOrderType.DetailType>();
  const [showRejectModel, setShowRejectModel] = useState(false);
  const getActionableApprovalDetails = async (currentActionableApprovalId: string | number) => {
    try {
      const result = await ApprovalsApis.getActionApprovalDetails(currentActionableApprovalId);
      if (isMounted.current) {
        setActionableApprovalDetails(result);
      }
    } catch (error) {}
  };

  const getPoChangeOrderDetail = async () => {
    try {
      if (actionableApprovalDetails?.approvable_id) {
        const result = await ChangeOrderApis.getChangeOrderDetail<PoChangeOrderType.DetailType>(
          actionableApprovalDetails.approvable_id,
        );
        if (isMounted.current) {
          setPoChangeOrderDetial(result);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (actionableApprovalDetails?.approvable_id) {
      getPoChangeOrderDetail();
    }
  }, [actionableApprovalDetails?.approvable_id]);

  const submit = async ({ approval, state }: { approval: ApprovalType.DetailsType; state: string }) => {
    await approvalsService.submit(approval, state, currentUser);
    CreateNotification("Approved", `Approved CO# ${poChangeOrderDetail?.number}`, NotificationType.success);
    setTimeout(() => window.location.reload(), 1000);
  };

  const onApprove = async (state: string) => {
    createConfirmModal({
      title: "Confirm",
      body: `Are you sure you want to Approve ${data?.number}?`,
      callBackData: { approval: actionableApprovalDetails, state },
      saveCallBack: submit,
      cancelCallBack: null,
    });
  };

  useEffect(() => {
    if (data?.actionable_approval?.id) {
      getActionableApprovalDetails(data?.actionable_approval?.id);
    }
  }, [data?.actionable_approval?.id]);

  return (
    <>
      {showRejectModel && poChangeOrderDetail && actionableApprovalDetails && (
        <RejectModal
          poChangeOrderDetail={poChangeOrderDetail}
          closeShowReject={() => setShowRejectModel(false)}
          showRejectModel={showRejectModel}
          actionableApprovalDetails={actionableApprovalDetails}
        />
      )}

      <Link to={"/ap/po_co_approvals/" + data?.actionable_approval?.id}>
        <button type="button" className="actionButtons view" data-toggle="tooltip" title="View"></button>
      </Link>
      <button
        type="button"
        onClick={() => onApprove("approved")}
        className="actionButtons icon-approve"
        data-toggle="tooltip"
        title="Approve"
      ></button>
      <button
        type="button"
        onClick={() => setShowRejectModel(true)}
        className="actionButtons icon-reject"
        data-toggle="tooltip"
        title="Reject"
      ></button>
    </>
  );
};

export const getPoChangeOrderApprovalHeaders = ({
  getPoChangeOrderApprovalList,
}: {
  getPoChangeOrderApprovalList: () => void;
}): ColDef[] => {
  return [
    {
      field: "id",
      headerName: "ID",
      filter: "agTextColumnFilter",
      headerValueGetter: function () {
        return translate("id");
      },
    },
    {
      field: "number",
      headerName: "Number",
      filter: "agTextColumnFilter",
      headerValueGetter: function () {
        return translate("number");
      },
    },
    {
      field: "vendor.name",
      headerName: "Vendor",
      filter: "agTextColumnFilter",
      // filterParams: {
      //   suppressAndOrCondition: true
      // },
      headerValueGetter: function () {
        return translate("vendor");
      },
    },
    {
      field: "change_requestable.date",
      headerName: "Date",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        browserDatePicker: true,
      },
      headerValueGetter: function () {
        return translate("date");
      },
    },
    {
      headerName: "Gross Amount",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("gross_amount");
      },
      valueGetter: getGrossAmount,
    },
    {
      field: "requestor.name",
      headerName: "Requestor",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("requestor");
      },
    },
    {
      field: "budget_label",
      headerName: "Budget",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("budget");
      },
    },
    {
      field: "status",
      headerName: "Status",
      filter: "agTextColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
      },
      headerValueGetter: function () {
        return translate("status");
      },
    },
    {
      field: "Actions",
      cellRenderer: (params: any) => {
        return <Actions data={params?.data} getPoChangeOrderApprovalList={getPoChangeOrderApprovalList} />;
      },
      sortable: false,
      resizable: false,
      maxWidth: 200,
      cellRendererParams: {
        viewLink: "/ap/po_co_approvals/",
      },
      headerValueGetter: function () {
        return translate("actions");
      },
    },
  ];
};
