import { IDateParams } from "ag-grid-community";
import React, { useImperativeHandle, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTypedSelector } from "../../../reducers";
import { IUser } from "../../../services/common/user/userTypes";

const GridDatePickerFilter = React.forwardRef<unknown, IDateParams>((props, ref) => {
  const [date, setDate] = useState<Date | null>(null);
  const refDatePicker = useRef<any>(null);
  const dateRef = useRef<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const onDateChanged = (selectedDate: Date | null) => {
    setDate(selectedDate);
    dateRef.current = selectedDate;
    props.onDateChanged();
  };
  useImperativeHandle(ref, () => ({
    getDate() {
      return dateRef.current;
    },
    setDate(date: Date | null) {
      dateRef.current = date;
      setDate(date);
    },
  }));
  return (
    <ReactDatePicker
      portalId="root"
      ref={refDatePicker}
      placeholderText={
        currentUser?.company?.company_date_format
          ? currentUser.company.company_date_format.toLocaleLowerCase()
          : "mm-dd-yyyy"
      }
      dateFormat={currentUser?.company?.company_date_format ? currentUser.company.company_date_format : "MM-dd-yyyy"}
      popperClassName="ag-custom-component-popup datepicker-filter-popper"
      selected={date}
      onChange={onDateChanged}
      todayButton="Today"
      strictParsing
      showMonthDropdown
      showYearDropdown
    >
      <button
        className="dateClearButton"
        onClick={() => {
          refDatePicker.current.setOpen(false);
          return onDateChanged(null);
        }}
      >
        Clear
      </button>
    </ReactDatePicker>
  );
});
export default GridDatePickerFilter;
