import { translate } from "../../../services/general/translation";
const virtualCardHeader = [
  {
    field: "id",
    sortable: true,
    headerName: "ID",
    hide: true,
    headerValueGetter: function () {
      return translate("id");
    },
  },
  {
    field: "external_id",
    sortable: true,
    headerName: "External ID",
    hide: true,
    headerValueGetter: function () {
      return translate("externalId");
    },
  },
  {
    field: "invoice.number",
    headerName: "Invoice #",
    // headerCheckboxSelection: true,
    // headerCheckboxSelectionFilteredOnly: true,
    // checkboxSelection: true,
    filter: "agTextColumnFilter",
    sortable: true,
    cellRenderer: "randomChildCellRouting",
    cellRendererParams: {
      viewLink: "/ar/invoices/",
    },
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,

      suppressAndOrCondition: true,
    },
    cellStyle: {
      color: "#FF5C35",
      fontFamily: "acre-regular",
      fontSize: 14,
      fontWeight: "bold",
    },
    headerValueGetter: function () {
      return translate("invoice_number");
    },
  },
  {
    field: "company.name",
    headerName: "Customer",
    filter: "agTextColumnFilter",
    sortable: true,
    cellRenderer: "randomChildCellRouting",
    cellRendererParams: {
      viewLink: "/ar/purchasers/",
    },
    cellStyle: {
      color: "#FF5C35",
      fontFamily: "acre-regular",
      fontSize: 14,
      fontWeight: "bold",
    },
    headerValueGetter: function () {
      return translate("customer");
    },
  },
  {
    field: "card_number_masked",
    headerName: "vCard Number",
    filter: "agTextColumnFilter",
    sortable: true,
    cellRenderer: "VirtualCardCellRenderer",
    cellRendererParams: {
      cardNumber: true,
    },
    headerValueGetter: function () {
      return translate("vCard_number");
    },
  },
  {
    field: "expiry",
    headerName: "Exp Date",
    cellRenderer: "VirtualCardCellRenderer",
    sortable: true,
    cellRendererParams: {
      cardExpiry: true,
    },
    headerValueGetter: function () {
      return translate("exp_date");
    },
  },
  {
    field: "cvc",
    filter: "agTextColumnFilter",
    sortable: true,
    headerName: "CVC",
    headerValueGetter: function () {
      return translate("cvc");
    },
  },
  {
    field: "remaining_amount",
    filter: "agNumberColumnFilter",
    headerName: "Remaining Balance",
    sortable: true,
    cellRenderer: (params) => {
      let remaining_amount =
        params.data?.properties?.nvp_vcard_available_balance != null
          ? params.data.properties.nvp_vcard_available_balance
          : params.data.remaining_amount;
      return <div>{params.data.currency.symbol + remaining_amount}</div>;
    },
    headerValueGetter: function () {
      return translate("remaining_bal");
    },
  },
  {
    field: "amount",
    filter: "agNumberColumnFilter",
    headerName: "Issued Balance",
    sortable: true,
    cellRenderer: "CurrencyCellRenderer",
    headerValueGetter: function () {
      return translate("issue_bal");
    },
  },
  {
    // field: '',
    field: "Actions",
    cellRenderer: "childMessageRenderer",
    sortable: false,
    resizable: false,
    width: 250,
    maxWidth: 250,
    cellRendererParams: {
      viewLink: "/ar/virtual_cards/",
      //editLink: '/ar/invoices/'
    },
    // pinned: 'right',
    cellRendererSelector: function (params) {
      return;
    },
    headerValueGetter: function () {
      return translate("actions");
    },
  },
];

export default virtualCardHeader;
