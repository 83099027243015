import { change, Field, getFormValues } from "redux-form";
import React, { memo, useCallback, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";
import { FieldArray } from "redux-form";
import { required } from "../../../../../../../services/validations/reduxFormValidation";
import { useSelector, useDispatch } from "react-redux";
import _, { isArray } from "lodash";
import { INTEGRATION_FORM_NAME } from "../../../../../../../reducers/admin/integrationsReducer";
import CurrencyCodePicker from "../../../../../../../components/common/pickers/reduxFormPickers/currencyCodePicker";
import useAdminCompanyCurrencyCode from "../../../../../../../components/admin/hooks/useAdminCompanyCurrencyCode";

const PayoneerPrograms = ({ fields, meta }) => {
  const { company } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const dispatch = useDispatch();
  const { companyCurrencies } = useAdminCompanyCurrencyCode();

  const onChangeDefault = (value, targetIndex) => {
    if (company && isArray(company.payoneer_programs)) {
      company.payoneer_programs.map((program, programIndex) => {
        if (targetIndex !== programIndex) {
          dispatch(change(INTEGRATION_FORM_NAME, `company.payoneer_programs[${programIndex}].default`, null));
        }
      });
    }

    dispatch(change(INTEGRATION_FORM_NAME, `company.payoneer_programs[${targetIndex}].default`, true));
  };

  const onRemove = (targetIndex) => {
    dispatch(change(INTEGRATION_FORM_NAME, `company.payoneer_programs[${targetIndex}]._destory`, 1));
  };

  return (
    <>
      {fields.map((payoneer_program, index) => {
        return (
          <span key={index}>
            {fields.get(index)._destory !== 1 && (
              <Row className="m-0">
                <Col lg="6">
                  <Field
                    id="progrmaster_card_numbeoam_id"
                    name={`${payoneer_program}.program_id`}
                    placeholder="Application ID"
                    component={RenderField}
                  />
                </Col>
                <Col lg="3">
                  <Field
                    id="payoneer.application_id"
                    name={`${payoneer_program}.currency_code`}
                    placeholder="Application ID"
                    component={CurrencyCodePicker}
                    validate={[required]}
                    required={true}
                    currencyCodeOptions={_.isArray(companyCurrencies) ? companyCurrencies : []}
                  />
                </Col>
                <Col lg="2">
                  <Form.Group className="mb-0">
                    <Form.Label>
                      Default
                      <Field
                        component="input"
                        type="checkbox"
                        name={`${payoneer_program}.default`}
                        onChange={(e) => onChangeDefault(e.target.value, index)}
                        className="m-2"
                      />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col className="mt-2">
                  <i
                    className="icon icon-delete"
                    role="button"
                    onClick={() => {
                      onRemove(index);
                    }}
                  />
                </Col>
              </Row>
            )}
          </span>
        );
      })}
      <Row className="m-0">
        <Col lg="3">
          <Button onClick={() => fields.push({ application_id: "", currency_code: "" })}>+ Add More</Button>
        </Col>
      </Row>
    </>
  );
};

const PayoneerCredential = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));

  const assignPayoneerProgram = useCallback(
    (payoneerProg) => {
      if (!_.isArray(payoneerProg) || (_.isArray(payoneerProg) && !(payoneerProg.length > 0))) {
        // update form data with dispatch change from redux form
        dispatch(change(INTEGRATION_FORM_NAME, "company.payoneer_programs", []));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    assignPayoneerProgram(company.payoneer_programs);
  }, []);

  return (
    <>
      <Col lg="12" className="p-0">
        <Row className="m-0">
          <Col lg="6">
            <Form.Label>Program ID</Form.Label>
          </Col>
          <Col lg="6">
            <Form.Label>Currency</Form.Label>
          </Col>
        </Row>
        {_.isArray(company.payoneer_programs) && (
          <FieldArray name="company.payoneer_programs" component={PayoneerPrograms} rerenderOnEveryChange />
        )}
      </Col>
    </>
  );
};

export default memo(PayoneerCredential);
