import React from "react";
import Panel from "../../../common/panel/panel";
import { Table } from "react-bootstrap";
import styles from "./recurringInvoice.module.css";
import { InvoicesType } from "../../../../services/admin/invoices/recurringInvoice/recurringInvoiceTypes";
import _ from "lodash";
import { restApiService } from "../../../../providers/restApi";
import { getUrlLocator } from "../../../../services/common/urlHelperSvc";
import { formattedAmount } from "../../../../services/general/helpers";
import { companyDateFormat } from "../../../../services/general/dateSvc";
import { IUser } from "../../../../services/common/user/userTypes";
import { useTypedSelector } from "../../../../reducers";

type Props = {
  invoices: InvoicesType[];
};

const ListInvoices = ({ invoices }: Props) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  return (
    <Panel
      header={
        <>
          <i className={`icon icon-invoices-blue mr-2`} />
          INVOICES HISTORY
        </>
      }
    >
      <Table className={`mb-1 border ${styles.table}`} size="sm" responsive>
        <thead>
          <tr>
            <th>Number</th>
            <th>Status</th>
            <th>PO Numbers</th>
            <th>Date</th>
            <th>Amount</th>
            {/* <th>Outstanding Balance</th>
          <th>Payment Method</th> */}
          </tr>
        </thead>
        <tbody>
          {(!invoices || (invoices && _.isArray(invoices) && invoices.length < 1)) && (
            <tr>
              <td colSpan={10} className="text-center">
                There are no Invoices.
              </td>
            </tr>
          )}

          {invoices &&
            _.isArray(invoices) &&
            invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>
                  <a href={restApiService.angularBaseURL() + getUrlLocator("invoice", invoice.id)} className="link">
                    {invoice.number}
                  </a>
                </td>
                <td className="d-flex justify-content-center">
                  <div className="detail-page-model-status">{invoice.status}</div>
                </td>
                <td>
                  {invoice.purchase_orders.length
                    ? invoice.purchase_orders.map((po) => (
                        <a
                          key={po.id}
                          href={restApiService.angularBaseURL() + getUrlLocator("purchaseorder", po.id)}
                          className="link"
                        >
                          {po.number}
                        </a>
                      ))
                    : null}
                </td>
                <td>{companyDateFormat(invoice.date, currentUser)}</td>
                <td>{formattedAmount(invoice.amount?.toString(), invoice.currency_code)}</td>
                {/* <td>{formattedAmount(invoice.balance_outstanding?.toString(), invoice.currency_code)}</td>
                <td>{invoice.payment_method?.account_name}</td> */}
              </tr>
            ))}
        </tbody>
      </Table>
    </Panel>
  );
};

export default ListInvoices;
