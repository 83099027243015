import React, { ReactNode } from "react";
import { WrappedFieldProps } from "redux-form";
import { CommonTypes } from "services/common/types/commonTypes";
import MultipleCurrencyCodePicker from "./multipleCurrencyCodePicker";
import SingleCurrencyCodePicker from "./singleCurrencyCodePicker";

export type ItemCurrencyCodeType = {
  value?: string;
  label?: string;
  name?: string;
  symbol?: string;
  iso_code?: string;
};
export interface CurrencyCodePickerPropsType extends WrappedFieldProps {
  label?: ReactNode;
  tooltip?: ReactNode;
  disabled?: boolean;
  placeHolder?: string;
  isMulti?: boolean;
  currencyCodeOptions: CommonTypes.ListCurrencyCodeItem;
  required?: boolean;
  instanceId?: string;
  callBack?: (selectedCurrency: ItemCurrencyCodeType | readonly ItemCurrencyCodeType[]) => void;
  className?: string;
  defaultValue: string; // whenever we get default value it only show that as option in list
  showCurrencyWithSymbol: boolean;
  parentDataObj?: any;
  notClearAble?: boolean;
}

// NOTE: making this component Generic JOB: is to get currency codes from parent component to and display in dropdown
// please everyone follow component open/close here, should be only modified when component level change is required
// please don't put any module specific logic in this
/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const CurrencyCodePicker = (props: CurrencyCodePickerPropsType) => {
  return props.isMulti ? (
    // as we already know type props type using spreading props is of no harm
    <MultipleCurrencyCodePicker {...props} />
  ) : (
    <SingleCurrencyCodePicker {...props} />
  );
};

export default CurrencyCodePicker;
