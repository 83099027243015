import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { change, Field, getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";

import Panel from "components/common/panel/panel";
import NavTabs from "pages/admin/administration/nav";
import { CreateNotification, NotificationType } from "services/general/notifications";

import MetadataConfigurationNavbar from "../metadata/metadataConfigurationNavbar";
import styles from "./defaultRules.module.css";
import DefaultRulesForm from "components/admin/defaultRules/defaultRulesUpsert";
import DefaultRulesApi from "services/admin/defaultRules/defaultRulesApi";
import { useTranslation } from "react-i18next";

export interface DefaultRule {
  name?: string;
  modules?: string[];
  id?: number;
  status?: string;
  default_conditions?: DefaultCondition[];
  default_conditions_attributes?: DefaultCondition[];
  default_actions?: DefaultAction[];
  default_actions_attributes?: DefaultAction[];
}

export interface DefaultCondition {
  id?: number;
  field_type?: string;
  field_id?: number | any;
  metadata_field_id?: number | any;
  value_operator?: string;
  value?: string;
  default_rule_id?: number;
  updated_at?: Date;
  _destroy?: number;
}

export interface DefaultAction {
  id?: number;
  field_type?: string;
  field_id?: number | any;
  metadata_field_id?: number | any;
  value?: string;
  default_rule_id?: number;
  updated_at?: Date;
  _destroy?: number;
}

let DefaultRulesPage = ({ handleSubmit }: InjectedFormProps<FormData, {}>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  //holds the real response from the first api call. We need this reference when saving to make sure we handle deletes correctly
  const [defaultRules, setDefaultRules] = useState<DefaultRule[]>([]);
  //holds the data we are working on.
  const [defaultRulesAttributes, setDefaultRulesAttributes] = useState<DefaultRule[]>([]);
  const [selectedRule, setSelectedRule] = useState<DefaultRule | null>(null);

  const getDefaultRules = useCallback(async (id?: number) => {
    const apiResult = await DefaultRulesApi.index();
    //raw copy to use as reference when we save (to delete objects)
    setDefaultRules(apiResult);

    //transform the data a bit to make the form easier
    let defaultRuleAttr: DefaultRule[] = apiResult.map((rule: DefaultRule) => {
      let newRule = JSON.parse(JSON.stringify(rule));
      if (newRule.default_conditions) {
        newRule.default_conditions.forEach((condition: DefaultCondition) => {
          if (condition.field_type === "MetadataConfiguration") {
            condition.field_type = "MetadataConfiguration_" + condition.field_id;
          }
        });

        newRule.default_actions.forEach((condition: DefaultCondition) => {
          if (condition.field_type === "MetadataConfiguration") {
            condition.field_type = "MetadataConfiguration_" + condition.field_id;
          }
        });
      }
      return newRule;
    });

    //set as our defaults
    setDefaultRulesAttributes(defaultRuleAttr);

    //auto select what we need
    if (id) {
      let rule = defaultRuleAttr.find((rule: DefaultRule) => rule.id === id);
      if (rule) {
        ruleSelected(rule);
      }
    } else if (defaultRuleAttr && defaultRuleAttr.length > 0) {
      ruleSelected(defaultRuleAttr[0]);
    }
  }, []);

  useEffect(() => {
    getDefaultRules();
  }, []);

  const ruleSelected = (rule: DefaultRule) => {
    setSelectedRule(rule);
  };

  const ruleDeleted = async () => {
    if (selectedRule?.id === undefined) {
      setDefaultRulesAttributes(defaultRulesAttributes.filter((rule: DefaultRule) => rule.id !== undefined));
    } else {
      await DefaultRulesApi.delete(selectedRule || {}).then((response) => {
        CreateNotification("Rule Deleted", "Rule deleted successfully", NotificationType.success);
        getDefaultRules();
      });
    }
  };

  const addRule = () => {
    let newRule = {
      name: "New Rule " + (defaultRulesAttributes.length + 1),
      id: -(defaultRulesAttributes.length + 1),
      default_conditions: [],
      default_actions: [],
      status: "ACTIVE",
    };
    setDefaultRulesAttributes([...defaultRulesAttributes, newRule]);
    setSelectedRule(newRule);
  };

  // call the api to save the rule. Will also transform the object to the expecected api format
  const upsertRule = async (defaultRule: DefaultRule) => {
    //we find the old rule so we can add any conditions that were deleted
    let oldRule = defaultRules.find((rule: DefaultRule) => rule.id === defaultRule.id);
    //copy so we dont mess up the redux form
    let newRule: DefaultRule = JSON.parse(JSON.stringify(defaultRule));

    oldRule?.default_conditions?.forEach((oldCondition: DefaultCondition) => {
      if (
        newRule.default_conditions?.find((newCondition: DefaultCondition) => newCondition.id === oldCondition.id) ===
        undefined
      ) {
        newRule.default_conditions?.push({ ...oldCondition, _destroy: 1 });
      }
    });

    //same thing for actions
    oldRule?.default_actions?.forEach((oldAction: DefaultAction) => {
      if (newRule.default_actions?.find((newAction: DefaultAction) => newAction.id === oldAction.id) === undefined) {
        newRule.default_actions?.push({ ...oldAction, _destroy: 1 });
      }
    });

    //change the field_types on our actions and conditions back to MetadataConfiguration
    newRule.default_conditions?.forEach((condition: DefaultCondition) => {
      if (condition.field_type?.includes("MetadataConfiguration")) {
        condition.field_type = "MetadataConfiguration";
      }
      //the picker sets the entire metadata field, we only need the id
      if (condition.metadata_field_id && condition.metadata_field_id.value) {
        condition.metadata_field_id = condition.metadata_field_id.value;
      }

      if (condition.field_id && condition.field_id.value) {
        condition.field_id = condition.field_id.value;
      }
    });

    newRule.default_actions?.forEach((action: DefaultAction) => {
      if (action.field_type?.includes("MetadataConfiguration")) {
        action.field_type = "MetadataConfiguration";
      }
      //the picker sets the entire metadata field, we only need the id
      if (action.metadata_field_id && action.metadata_field_id.value) {
        action.metadata_field_id = action.metadata_field_id.value;
      }

      if (action.field_id && action.field_id.value) {
        action.field_id = action.field_id.value;
      }
    });

    newRule.default_conditions_attributes = newRule.default_conditions;
    newRule.default_actions_attributes = newRule.default_actions;

    await DefaultRulesApi.upsert(newRule).then((response) => {
      CreateNotification("Rule Saved", "Rule saved successfully", NotificationType.success);
      getDefaultRules(response.id);
    });
  };

  return (
    <Container fluid={true}>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"Default Rules"} />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Panel>
        <MetadataConfigurationNavbar />

        <div className={"d-flex"}>
          <div className="flex-grow-1">
            <button type="button" onClick={addRule} className="btn btn-primary">
              + {t("addRule")}
            </button>

            <hr></hr>

            <div className="d-flex">
              <div className={` ${styles.ruleSidebar}`}>
                <DefaultRuleSideBar
                  defaultRules={defaultRulesAttributes}
                  selectedRule={selectedRule}
                  selectCallback={ruleSelected}
                ></DefaultRuleSideBar>
              </div>

              <div className={`flex-grow-1 ml-3 ${styles.form}`}>
                {defaultRulesAttributes &&
                  defaultRulesAttributes.map((rule: DefaultRule) => {
                    if (rule.id === selectedRule?.id) {
                      return (
                        <DefaultRulesForm
                          onSubmit={upsertRule}
                          form={`defaultRulesForm_${rule.id}`}
                          key={rule.id}
                          initialValues={rule}
                          deleteCallback={ruleDeleted}
                        ></DefaultRulesForm>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </Container>
  );
};

interface DefaultRuleSideBarProps {
  defaultRules: DefaultRule[];
  selectedRule: DefaultRule | null;
  selectCallback: any;
}

const DefaultRuleSideBar: React.FC<DefaultRuleSideBarProps> = ({ defaultRules, selectCallback, selectedRule }) => {
  return (
    <div className="mr-3">
      {defaultRules &&
        defaultRules.map((rule: any, index: number) => {
          const isSelected = selectedRule && selectedRule.id == rule.id;
          return (
            <div
              key={rule.id}
              className={`pl-4 pr-4 pt-1 pb-1 mb-1 ${isSelected ? styles.ruleSidebarRowSelected : ""} ${styles.ruleSidebarRow}`}
              onClick={() => {
                selectCallback(rule);
              }}
            >
              <p className={`fontSizeNormal ${isSelected ? styles.ruleSelectedFont : "blackColor"}`}>
                {index + 1}. {rule.name}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default DefaultRulesPage;
