import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import RestApi from "../../../../../providers/restApi";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * reduxform and reduxformpickers.
 */
class GenericSelectPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.parseOptions(this.props.options);
  }

  parseOptions(options) {
    let returnObj = options.map((option) => {
      return {
        value: option.id,
        label: option.label,
        raw: option,
      };
    });
    returnObj.unshift({ value: null, label: "Select..." });
    this.setState({
      selectOptions: returnObj,
    });
  }

  //Find the option that matches the passed in props.id
  findSelected() {
    if (!this.state.selectOptions) {
      return null;
    }
    return this.state.selectOptions.find((option) => {
      if (option.value === this.props.id) {
        return option;
      }
      return null;
    });
  }

  render() {
    return (
      <Form.Group>
        {this.props.hideLabel !== true && (
          <Form.Label style={{ textTransform: "capitalize" }}>
            {this.props.label} {this.props.required && <span className={"color-red"}>*</span>}
          </Form.Label>
        )}
        <Select
          required={this.props.required}
          isDisabled={this.props.disabled}
          value={this.findSelected()}
          placeholder={this.props.placeholder || "Choose one"}
          options={this.state.selectOptions}
          onChange={(e) => {
            if (this.props.callback) {
              this.props.callback(e);
            }
          }}
        />
        {this.props.valid === false && <div style={{ fontSize: "80%", color: "#dc3545" }}>Invalid</div>}
      </Form.Group>
    );
  }
}

export default withTranslation()(GenericSelectPicker);
