import React, { ReactNode } from "react";
import { file_types } from "../../../../app.svc.Lookup";
import { WrappedFieldProps } from "redux-form";
import SingleFileTypePicker from "./singleFileTypePicker";
import MultipleFileTypePicker from "./multipleFileTypePicker";

export interface FileTypePickerPropsType extends WrappedFieldProps {
  className?: string;
  isMulti?: boolean;
  label?: ReactNode;
  tooltip?: ReactNode;
  required?: boolean;
  inputId?: string;
}

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const FileTypePicker = ({ isMulti, ...rest }: FileTypePickerPropsType) => {
  return isMulti ? (
    <MultipleFileTypePicker {...rest} fileTypes={file_types} />
  ) : (
    <SingleFileTypePicker {...rest} fileTypes={file_types} />
  );
};

export default FileTypePicker;
