import { createContext } from "react";
import { createContextualCan, useAbility } from "@casl/react";
import { AbilityBuilder, Ability } from "@casl/ability";

export const AbilityContext = createContext();
export const Able = createContextualCan(AbilityContext.Consumer);

export const Can = ({ I, a, children, permissions = [], permissionExcept = [], requirePermissionsAndIa = false }) => {
  const ability = useAbility(AbilityContext);

  // check if the user has permissions
  if (permissions && permissions.length > 0) {
    let permitted = permissions.some((current_permission) => {
      const [verb, resource] = getAbilityFromPermission(current_permission);
      return verb && resource && ability.can(verb, resource);
    });

    //if the user has both permissions and permissionExcept then check if the user dont have the permissionExcept permissions
    if (permitted && permissionExcept && permissionExcept.length > 0) {
      permissionExcept.forEach((current_permission) => {
        //We are taking our permission string and breaking it down by verb and object
        const [verb, resource] = getAbilityFromPermission(current_permission);
        // if the user has permissionExcept permission dont allow them to show
        if (verb && resource && ability.can(verb, resource)) {
          permitted = false;
        }
      });
    }
    if (permitted && !requirePermissionsAndIa) {
      return children;
    }
    // if user has permissions && I: a: auth, usually from module_setting/feature flags
    else if (permitted && requirePermissionsAndIa && I && a) {
      return (
        <Able I={I} a={a}>
          {children}
        </Able>
      );
    }
    return null;
  } else if (permissionExcept && permissionExcept.length > 0) {
    //if the user has only permissionExcept not permissions

    //by default permission will be true
    let permitted = true;
    permissionExcept.forEach((current_permission) => {
      //We are taking our permission string and breaking it down by verb and object
      const [verb, resource] = getAbilityFromPermission(current_permission);
      // if the user has permissionExcept permission dont allow them to show
      if (verb && resource && ability.can(verb, resource)) {
        permitted = false;
      }
    });
    if (permitted && !requirePermissionsAndIa) {
      return children;
    } else if (permitted && requirePermissionsAndIa && I && a) {
      return (
        <Able I={I} a={a}>
          {children}
        </Able>
      );
    }
    return null;
  } else {
    return (
      <Able I={I} a={a}>
        {children}
      </Able>
    );
  }
};

export var userAbility = new Ability();

// take our permission string and breaking it down by verb and resource
// space is added between camelCase phrases
// returns [verb, resource] after splitting permission string with space delimiter
// i.e. importBulkOperations => ['import', 'Bulk', 'Operations'] => ['import', 'BulkOperations']
export function getAbilityFromPermission(permission) {
  const splitString = permission.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");
  return [splitString[0], splitString.slice(1).join("")];
}

export function defineAbilitiesFor(user) {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  if (user && user.perm_resources) {
    user.perm_resources.forEach((permission) => {
      let splitString = getAbilityFromPermission(permission);
      let verb = splitString[0];
      //remove the verb from the original string to get the resource
      let object = permission.replace(verb, "");
      //add the permission to current context
      //ex: can('add','invoices')
      can(verb, object);
    });
  }
  if (user && user.module_settings) {
    user.module_settings.forEach((moduleSetting) => {
      //We are taking our permission string and breaking it down by verb and object
      //EX: list_Invoices becomes list _Invoices
      //split string by snack case
      //the verb should be the first entry in the split string
      let indexOfUnderscore = moduleSetting.search("_");
      let verb = moduleSetting.substring(0, indexOfUnderscore);
      //remove the verb from the original string
      let object = moduleSetting.substring(indexOfUnderscore);
      //setup the permission
      //ex: can('add','invoices')
      can(verb, object);
    });
    // cannot('virtual','_cards_active')
  }
  //I dont like this but cant figure out another way. Forces a wait so we dont error out on redux
  setTimeout(() => {
    userAbility.update(rules);
  }, 100);
}
