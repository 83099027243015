import { AxiosResponse } from "axios";
import { Mandatory } from "components/forms/bootstrapFields";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useTypedSelector } from "reducers";
import { IDType } from "services/common/types/common.type";
import { compare, findSelectedSingle, onBlurSingle, onChangeSingle } from "services/general/helpers";

type InputType = {
  value: number | string;
  onChange: (values: number | string) => void;
  onBlur: (values: number | string) => void;
};

type SelectedTypes = {
  value: IDType;
  label: string;
};

type CustomDataListPickerPropsType = {
  modelName?: string;
  label?: ReactNode;
  required?: boolean;
  tooltip?: ReactNode;
  input: any;
  placeholder?: string;
  status?: string;
  floating?: boolean;
  instanceId?: string;
  callBack?: (selected: SelectedTypes) => void;
};

const CustomDataListPicker = ({
  modelName,
  label,
  required,
  tooltip,
  input,
  placeholder = "Select...",
  floating = false,
  instanceId = "custom-data-list-picker",
  callBack,
}: CustomDataListPickerPropsType) => {
  const [customDataList, setCustomDataList] = useState();
  const currentUser = useTypedSelector((state) => state.user);
  const companyId = currentUser?.company?.id;

  const parseForSelect = (options: any) => {
    return options
      .map((option: any) => ({
        value: option.external_id || null,
        label: option.name || "",
        order: option.order || null,
        status: option.status || "",
      }))
      .sort(compare);
  };

  const filterActiveRecord = (dataList: unknown) => {
    if (_.isArray(dataList) && dataList.length > 0) {
      return dataList.filter((list) => list.status === "ACTIVE");
    }
  };

  const getDataList = useCallback(async () => {
    try {
      const response: AxiosResponse = await restApiService.get(
        "companies/" + companyId + "/custom_data_list",
        { model: modelName },
        null,
        true,
        null,
        true,
      );
      const dataListResponse = response?.data?.properties ? response?.data?.properties : {};
      const options = filterActiveRecord(dataListResponse?.data_list);
      if (_.isArray(options) && options.length > 0) {
        let list = parseForSelect(options);
        if (!required) {
          list = [
            {
              label: `-- Select ${label} --`,
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setCustomDataList(list);
      }
    } catch (error) {
      throw error;
    }
  }, [companyId, label, modelName, required]);

  const changeCustomDataList = (input: any, selected: SelectedTypes) => {
    onChangeSingle(input, selected);
    if (callBack) {
      callBack(selected);
    }
  };

  useEffect(() => {
    getDataList();
  }, [getDataList]);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        required={required}
        placeholder={placeholder}
        options={customDataList}
        isClearable={!required}
        value={findSelectedSingle(input, customDataList)}
        onChange={(selected: SelectedTypes) => changeCustomDataList(input, selected)}
        instanceId={instanceId}
        onBlur={() => onBlurSingle(input, input.value)}
        getOptionLabel={(option: SelectedTypes) => option.label}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
          }),
          ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
        }}
        {...(floating ? { menuPortalTarget: document.body } : {})}
      ></Select>
    </Form.Group>
  );
};

export default CustomDataListPicker;
