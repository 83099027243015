import RestApi from "../../../providers/restApi";
import { NotificationType, CreateNotification } from "../../general/notifications";
import _, { cloneDeep, isObject, isPlainObject } from "lodash";

/*
Input data is data stored on the front-end from redux form/field array.
When we receive data from back-end that is flattened, we will use nester function
to organize data back to this format.

It looks as below:

let input_data = 
{
    'subsidiary':
    [
        {
            'locationID' : 0,
            'managers': 
            [
                {
                    'level': 1,
                    'manager_id':[111,1112,1113,55640]
                },
                {
                    'level': 1,
                    'manager_id':[111,1112,1113,55640]
                },
                {
                    'level': 2,
                    'manager_id':[111,1112,1113,55640]
                },
                {

                    'level': 3,
                    'manager_id':[111,1112,1113,55640]
                },
            ]
        },  
        {
            'locationID' : 1,
            'managers': 
            [
                {
                    'level': 1,
                    'manager_id':[111,1112,1113,55640]
                },
                {
                    'level': 1,
                    'manager_id':[111,1112,1113,55640]
                },
                {
                    'level': 2,
                    'manager_id':[111,1112,1113,55640]
                },
                {

                    'level': 3,
                    'manager_id':[111,1112,1113,55640]
                },
            ]
        },  
    ]
}

Data when sending post or put will need to be transformed to below.
We will use flattener function for this.

let output_data = 
{
    'subsidiary':
    [
        {locationID: 0, level: 1, manager_id: 111},
        {locationID: 0, level: 1, manager_id: 1112},
        {locationID: 0, level: 1, manager_id: 1113},
        {locationID: 0, level: 1, manager_id: 55640},
        {locationID: 0, level: 2, manager_id: 111},
        {locationID: 0, level: 2, manager_id: 1112},
        {locationID: 0, level: 2, manager_id: 1113},
        {locationID: 0, level: 2, manager_id: 55640},
        {locationID: 0, level: 3, manager_id: 111},
        {locationID: 0, level: 3, manager_id: 1112},
        {locationID: 0, level: 3, manager_id: 1113},
        {locationID: 0, level: 3, manager_id: 55640},

        {locationID: 1, level: 1, manager_id: 111},
        {locationID: 1, level: 1, manager_id: 1112},
        {locationID: 1, level: 1, manager_id: 1113},
        {locationID: 1, level: 1, manager_id: 55640},
        {locationID: 1, level: 2, manager_id: 111},
        {locationID: 1, level: 2, manager_id: 1112},
        {locationID: 1, level: 2, manager_id: 1113},
        {locationID: 1, level: 2, manager_id: 55640},
        {locationID: 1, level: 3, manager_id: 111},
        {locationID: 1, level: 3, manager_id: 1112},
        {locationID: 1, level: 3, manager_id: 1113},
        {locationID: 1, level: 3, manager_id: 55640},
    ]
}

*/

export const restApiService = new RestApi();

export const vendorPortalRedirect = restApiService.angularBaseURL() + "subsidiaries";

// Upper cases strings
export const toProper = function (str = "") {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

//flattens nestedData while throwing errors if duplicate entries exist on existing form data (newPayload)
// Modifies form value by reference
export const flattenerUnique = (value, paramMap) => {
  let data = value; // reference to modify
  let linkID, managerID, link, allowPlaceholder;
  let flattenedResults = [];
  const { paramKey, assocName } = paramMap;
  const assocIdKey = [`${assocName}_id`];
  if (!data[paramKey]) {
    return;
  }
  data[paramKey].forEach((entry) => {
    allowPlaceholder = false;
    if (entry.managers.length === 0) {
      throw new Error(`One of your ${assocName}s is missing associated managers.`);
    }
    if (entry.managers.length === 1) {
      allowPlaceholder = true; //set nil entry if location/department entry has only one level
    }
    entry.managers.forEach((manager) => {
      if (!allowPlaceholder && manager.manager_id.length === 0) {
        //if location/department entry has more than one level, raise error if id's missing
        throw new Error(`One of your ${assocName}'s entries is missing associated managers in a level.`);
      } else if (
        !allowPlaceholder &&
        manager.manager_id.length === 1 &&
        isPlainObject(manager.manager_id[0]) &&
        isNaN(manager.manager_id[0].value)
      ) {
        throw new Error(`One of your ${assocName}'s entries is missing associated managers in a level.`);
      }
      if (allowPlaceholder && manager.manager_id.length === 0) {
        link = {
          [assocIdKey]: parseInt(entry[assocIdKey]),
          level: 0,
          manager_id: null,
        };
        if (!_.find(flattenedResults, link)) {
          link.id = linkID;
          flattenedResults.push(link);
        }
        return;
      }
      manager.manager_id.forEach((valueEntry) => {
        linkID = null;
        managerID = null;
        if (isPlainObject(valueEntry)) {
          if (valueEntry.id) {
            linkID = parseInt(valueEntry.id);
          }
          if (valueEntry.value) {
            managerID = parseInt(valueEntry.value);
          }
        } else {
          managerID = parseInt(valueEntry);
        }
        link = {
          [assocIdKey]: parseInt(entry[assocIdKey]),
          level: isNaN(manager.level) || !manager.level ? 0 : parseInt(manager.level),
          manager_id: managerID,
        };
        if (!_.find(flattenedResults, link)) {
          link.id = linkID;
          flattenedResults.push(link);
        }
      });
    });
  });
  data[paramKey] = flattenedResults;
};

// Checks if id exists from api_data
// Add id to flattened data for put operations to work
function checkDatabaseEntry(link, entryID) {
  let value = { value: entryID };
  if (link.id) {
    value.id = link.id;
  }
  return value;
}

// Builds nested hash when nested keys being in order of targets parameter
// Used for excluding duplicate entries per location per level
function nestGetUnique(uniques, link, targets) {
  let n = 0;
  let currentUniques = uniques;
  let currentTarget;
  while (n < targets.length - 1) {
    currentTarget = targets[n];

    if (!currentUniques[currentTarget]) {
      currentUniques[currentTarget] = {};
    }
    currentUniques = currentUniques[currentTarget];
    n += 1;
  }
  currentTarget = targets[n];
  currentUniques[currentTarget] = checkDatabaseEntry(link, currentTarget);
}

// This takes a hash without duplicates and maps to the structure needed on the front-end redux form.
function nestBuild(uniques, assocName) {
  return Object.keys(uniques).map((categ_id) => {
    return {
      [assocName]: parseInt(categ_id),
      managers: Object.keys(uniques[categ_id].managers).map((level) => {
        return {
          level: isNaN(level) || !level ? 0 : parseInt(level),
          manager_id: Object.keys(uniques[categ_id].managers[level]).map((id_entry) => {
            let entry = uniques[categ_id].managers[level][id_entry];
            if (isObject(entry)) {
              return { value: parseInt(entry.value), id: entry.id ? parseInt(entry.id) : null };
            }
            return parseInt(id_entry);
          }),
        };
      }),
    };
  });
}

// Nests data received from api GET request
// Data we need comes in keys of subsidiary_location_manager_links and subsidiary_links (notice no "_attributes" at the end)
function nester(data, paramMaps) {
  let filtered = {};
  const value = cloneDeep(data);
  ["name", "currency_code", "status", "sub_type", "country", "external_id"].forEach((field) => {
    if (value[field]) {
      filtered[field] = value[field];
    }
  });
  paramMaps.forEach((catMap) => {
    let uniques = {};
    const assocIdKey = [`${catMap.assocName}_id`];
    const newParamKey = `${catMap.paramKey}_attributes`;
    filtered[newParamKey] = value[catMap.paramKey];
    filtered[newParamKey].forEach((link) => {
      nestGetUnique(uniques, link, [link[assocIdKey], "managers", link.level, link.manager_id]);
    });
    filtered[newParamKey] = nestBuild(uniques, assocIdKey);
  });
  return filtered;
}

// Compares new and initial values after flattening (flattener)
// Uses lodash to compare the new vs initial.
// If not found in initial, add a _destroy key for back-end
// categoryKeys parameter is array of controller param keys required for associated table creation
// i.e. subsidiary_links_attributes or subsidiary_manager_location_links_attributes
export const getDifference = (newValues, initialValues, paramKeys) => {
  let payloadCategory, initialCategory, newLink;
  let destroyCategory;
  paramKeys.forEach((key) => {
    destroyCategory = [];
    payloadCategory = newValues[key]; //add to location/department array in payload object by reference
    initialCategory = initialValues[key];
    initialCategory.forEach((linkItem) => {
      newLink = cloneDeep(linkItem);
      newLink._destroy = true;
      destroyCategory.push(newLink);
    });
    payloadCategory.forEach((linkItem) => {
      delete linkItem.id;
    });
    payloadCategory.push(...destroyCategory);
  });
  return newValues;
};

export const getSubsidiaryByID = async (id, paramMaps) => {
  let response = await restApiService
    .get(`subsidiaries/${id}`, {})
    .then((result) => {
      const displayData = nester(result.data, paramMaps);
      return [result.data, displayData];
    })
    .catch((error) => {
      CreateNotification("Subsidiary Information Error ", `${error.message}`, NotificationType.danger);
      return false;
    });
  return response;
};
