import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { required } from "../../../forms/inputFields";
import { useTranslation } from "react-i18next";
import { minLength } from "../../../../services/validations/reduxFormValidation";
import { PaymentMethodValidatorSvc } from "../paymentMethodValidatorSvc";
import { AccountNumberValidator } from "../accountNumberValidator";
import BankAddressLine from "../bankAddressLine";
import CurrencyCodePicker from "components/common/pickers/reduxFormPickers/currencyCodePicker";
import _ from "lodash";
import { useTypedSelector } from "reducers";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

const min6 = minLength(6);

const UkEftPaymentMethod = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState("");
  const appCurrencyCodes = useTypedSelector(selectAppCurrecyCode);

  const validateAccountTaxNumber = async (value: any) => {
    // Restricting to call api until SortCodeAccountNumber should be 6 digit/character
    if (value.length === 6) {
      try {
        const data = await PaymentMethodValidatorSvc.ValidateSortCodeAccountNumber(value);
        if (data?.validation) {
          setValidationMessage(data.validation);
        } else {
          setValidationMessage(data?.errors[0]?.message);
        }
      } catch (error) {
        throw error;
      }
    }
  };

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <Field
          name={`${modelName}.account_tax_number`}
          component={RenderField}
          type="text"
          maxLength={6}
          label={t("common.paymentMethod.accountTaxNumber")}
          className={`w-100`}
          tooltip={t("common.paymentMethod.sortCodeNote")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => validateAccountTaxNumber(e.target.value)}
          required
          validate={[required, min6]}
        />
        {validationMessage && (
          <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
        )}
      </Col>
      <Col md="4">
        <AccountNumberValidator
          modelName={`${modelName}`}
          accountNumberType="canadian-account-number"
          label={t("common.paymentMethod.accountNumberLabel")}
          maxLength={8}
          noValidate={true}
        />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
        />
      </Col>
      <Col sm="4">
        <Field
          name={`${modelName}.currency_code`}
          component={CurrencyCodePicker}
          FieldClassName="formField"
          placeholder={"Search/Select"}
          label={t("common.paymentMethod.currencyCode")}
          currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
          defaultValue="GBP"
        />
      </Col>
      <BankAddressLine modelName={`${modelName}`} isCountryRequired={true} />
    </>
  );
};

export default UkEftPaymentMethod;
