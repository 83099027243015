import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { IDType, getListOptionsType } from "services/common/types/common.type";
import { BankCardTransactionsType } from "./bankCardTransactionsType";

class BankCardTransactionApis {
  static async getList({ filter, cache }: getListOptionsType = {}) {
    try {
      const response: AxiosResponse<BankCardTransactionsType.List> = await restApiService.get(
        "bank_card_transactions",
        filter,
        null,
        true,
        null,
        cache,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getDetail(id: IDType) {
    try {
      const response: AxiosResponse<BankCardTransactionsType.Details> = await restApiService.get(
        "bank_card_transactions/" + id,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static createBankCardTransactions = async (createPayload: BankCardTransactionsType.CreatePayload) => {
    try {
      const response: AxiosResponse<BankCardTransactionsType.Details> = await restApiService.post(
        "bank_card_transactions",
        null,
        createPayload,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export default BankCardTransactionApis;
