import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Form, Row, Button, Col, Navbar } from "react-bootstrap";
import { renderField, renderTextareaField } from "../../../components/forms/fields";
import { connect } from "react-redux";
import { BreadcrumbTrail } from "../../../components/navigation/navigationLinks";
import { withTranslation } from "react-i18next";
import SingleExpense from "../../../components/expenseItems/singleExpense";
import Mileage from "../../../components/expenseItems/mileage";
import PerDiem from "../../../components/expenseItems/perDiem";
import AttachmentLoader from "../../../components/expenseItems/attachmentLoader";
import styles from "./ExpenseItem.module.css";

let EditForm = (props) => {
  let expense_item = props.ExpenseItem;
  let policies = props.state.policies;
  let employees = props.state.employees;
  let categories = props.state.categories;
  let pos = props.state.pos;

  const { handleSubmit, changePolicyDependentFields } = props;
  return (
    <Row>
      <Col xs={12}>
        <Form onSubmit={handleSubmit} className={styles.draftExpenseForm}>
          <div className="row">
            <div className="col-md-6">
              <BreadcrumbTrail
                to={"/ap/expenseItems/draft"}
                pageName={props.t("breadcrumbs.draftInbox")}
                title={
                  <span className="hilight">{expense_item.id ? expense_item.number : props.t("loading.loading")}</span>
                }
                classes={"mb-3"}
              />
            </div>
            <div className="col-md-6 content-right">
              {/*<Button className={'btnDraft btn btn-draft-outline'} onClick={() => {props.handleSubmit.isDraft = true}}>Save</Button>*/}
              <button
                className={"btnDraft btn btn-secondary"}
                onClick={() => props.history.push("/ap/expenseItems/draft")}
              >
                Cancel
              </button>
              <button className={"btnDraft btn btn-primary"} type="submit">
                Submit
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr className={styles.fullBorder} />
            </div>
          </div>
          <div className="row">
            <AttachmentLoader {...props} />
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <Navbar expand="md" style={{ padding: "0 0 15px 0" }}>
                    <Navbar.Brand className="navBarItem">Expense Item</Navbar.Brand>
                  </Navbar>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 prt-5">
                  <label>Employee</label>
                  <br />
                  <Field className={"w-100"} name="employee_id" component="select" style={{ padding: "8px" }}>
                    <option value="">Select Employee</option>
                    {employees.map((employee) => (
                      <option value={employee.id} key={employee.id}>
                        {employee.name}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="col-md-6 prt-5">
                  <label>Policy</label> <span className={"color-red"}>*</span>
                  <br />
                  <Field
                    className={"w-100"}
                    name="policy_id"
                    component="select"
                    style={{ padding: "8px" }}
                    required={true}
                    onChange={(e) => changePolicyDependentFields(e.target.value)}
                  >
                    {policies.map((policy) => (
                      <option value={policy.id} key={policy.id}>
                        {policy.name}
                      </option>
                    ))}
                  </Field>
                  {expense_item.policy && expense_item.policy.convert_to_base_currency && (
                    <label className={styles.functionalCurrencyWarning}>
                      This policy uses {expense_item.policy.currency_code} your amount will be converted.
                    </label>
                  )}
                </div>
              </div>
              <div className="row mt-15">
                <div className="col-md-6 prt-5">
                  <label>Expense Item Type</label> <span className={"color-red"}>*</span>
                  <br />
                  <Field
                    className={"w-100"}
                    name="item_type"
                    component="select"
                    style={{ padding: "8px" }}
                    disabled={!true}
                    required={true}
                  >
                    <option value="">Select Item Type</option>
                    <option value="SINGLE_EXPENSE">SINGLE EXPENSE</option>
                    <option value="MILEAGE">MILEAGE</option>
                    <option value="PER_DIEM">PER DIEM</option>
                  </Field>
                </div>
              </div>
              <div className="row mt-15">
                <div className="col-md-6 prt-5">
                  <label>Category</label> <span className={"color-red"}>*</span>
                  <br />
                  <Field
                    className={"w-100"}
                    name="category_id"
                    component="select"
                    style={{ padding: "8px" }}
                    required={true}
                  >
                    {categories.map((category) => (
                      <option value={category.id} key={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="col-md-6 plt-5">
                  {pos && pos.length > 0 && (
                    <>
                      <label>PO Number</label>
                      <Field
                        className={"w-100"}
                        name="purchase_order_id"
                        component="select"
                        style={{ padding: "8px" }}
                        required={true}
                      >
                        {pos.map((po) => (
                          <option value={po.id} key={po.id}>
                            {po.number}
                          </option>
                        ))}
                      </Field>
                    </>
                  )}
                </div>
              </div>

              {expense_item.item_type === "SINGLE_EXPENSE" && <SingleExpense {...props} />}

              {expense_item.item_type === "MILEAGE" && <Mileage {...props} />}

              {expense_item.item_type === "PER_DIEM" && <PerDiem {...props} />}
              <div className="row mt-15">
                <Field
                  className="col-md-12"
                  name="description"
                  label="Description"
                  component={renderTextareaField}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr className="full-border" />
            </div>
          </div>
          <div className="row mt-15">
            <div className="col-md-12 content-right">
              {/*<Button variant="outline-primary" className={'btnDraft'} onClick={() => {props.handleSubmit.isDraft = true}}>Save</Button>*/}
              <button
                className={"btnDraft btn btn-secondary"}
                onClick={() => props.history.push("/ap/expenseItems/draft")}
              >
                Cancel
              </button>
              <button className={"btnDraft btn btn-primary"} type="submit">
                Submit
              </button>
            </div>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

EditForm = reduxForm({
  form: "EditForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EditForm);
EditForm = connect((state) => ({
  initialValues: state.editDraftExpenseItemRed.EditForm,
}))(EditForm);

export default withTranslation()(EditForm);
