import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.css";
import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { formattedAmount } from "services/general/helpers";
import { dashboardColorSettings } from "./expenseDashboardColorMap";

type DataType = {
  category_name: string;
  currency: { symbol: string; iso_code: string };
  expenses_count: number;
  total_spent: number;
  refId?: string;
};

type ExpenseCategoryStatusType = {
  categoryData: DataType[];
  colorArray: dashboardColorSettings[];
  totalSpend: number;
  setHighlightRow: (value: number | undefined) => void;
  highLightRow: number | undefined;
};

const ExpenseStatusBar = ({
  categoryData,
  colorArray,
  totalSpend,
  setHighlightRow,
  highLightRow,
}: ExpenseCategoryStatusType) => {
  const [percentArray, setPercentArray] = useState([]);
  const greyOut = "grey";
  const getPercentage = () => {
    let array: any = [];
    categoryData.forEach((category: any) => {
      array.push((category?.total_spent / totalSpend) * 100);
    });
    setPercentArray(array);
  };

  useEffect(() => {
    getPercentage();
  }, [categoryData, totalSpend]);

  const getRowColor = (index: number): dashboardColorSettings => {
    const rowIsHighlighted = highLightRow !== undefined;
    const selectedCellIsNotHighlighted = rowIsHighlighted && highLightRow !== index;
    const greyCell = { color: greyOut, tooltipTextColor: greyOut };

    if (rowIsHighlighted && selectedCellIsNotHighlighted) {
      return greyCell;
    }

    return colorArray[index % colorArray.length];
  };

  const GetRequiredBar = ({
    index,
    color,
    percentage,
  }: {
    index: number;
    color: dashboardColorSettings;
    percentage: number;
  }) => {
    if (color.color === greyOut) {
      return <BarWithoutToolTip index={index} color={color} percentage={percentage} />;
    } else {
      return <BarWithToolTip index={index} color={color} percentage={percentage} />;
    }
  };

  const BarWithoutToolTip = ({
    index,
    color,
    percentage,
  }: {
    index: number;
    color: dashboardColorSettings;
    percentage: number;
  }) => {
    return (
      <span
        onClick={() => setHighlightRow(highLightRow !== undefined ? undefined : index)}
        className={`${styles.categoryBarItems}`}
        style={{
          backgroundColor: `${color.color}`,
          width: `${percentage}%`,
        }}
      >
        {`${" "}`}
      </span>
    );
  };

  const BarWithToolTip = ({
    index,
    color,
    percentage,
  }: {
    index: number;
    color: dashboardColorSettings;
    percentage: number;
  }) => {
    return (
      <OverlayTrigger
        key={`${index}`}
        placement={"top"}
        onEntering={(e) => entering(e, index)}
        overlay={
          <Tooltip className={`expense-bar-tool-tip`} key={`${index}`} id="button-tooltip-2">
            <span className={`${styles.toolTipText}`} style={{ color: color.tooltipTextColor }}>
              {`${categoryData[index]?.category_name}: `}
              {formattedAmount(`${categoryData[index]?.total_spent}`, categoryData[index]?.currency?.symbol, 2, true)}
            </span>
          </Tooltip>
        }
      >
        <span
          onClick={() => {
            if (highLightRow !== undefined) {
              setHighlightRow(undefined);
            } else {
              const element = document.getElementById(`${categoryData[index].refId}`);
              setHighlightRow(index);
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }
          }}
          className={`${styles.categoryBarItems}`}
          style={{
            backgroundColor: `${color.color}`,
            width: `${percentage}%`,
          }}
        >
          {`${" "}`}
        </span>
      </OverlayTrigger>
    );
  };

  const entering = (e: any, index: number) => {
    e.children[0].style.borderTopColor = `${getRowColor(index).color}`;
    e.children[1].style.backgroundColor = `${getRowColor(index).color}`;
  };

  return (
    <>
      <Row className={`${styles.spendBarRow}`}>
        {percentArray.map((percentage, index) => {
          const color = getRowColor(index);
          return <GetRequiredBar key={index} index={index} color={color} percentage={percentage} />;
        })}
      </Row>
    </>
  );
};

export default ExpenseStatusBar;
