import React from "react";
import DateFilter from "../reportFilters/date";
import DropdownFilter from "../reportFilters/dropdown";

const VendorListing = ({}) => {
  const vendor_status_options = [
    { value: "", label: "ALL" },
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
    { value: "PENDING", label: "PENDING" },
    { value: "NEW", label: "NEW" },
    { value: "REJECTED", label: "REJECTED" },
  ];
  return (
    <div>
      <DateFilter name={"As of Date"} code={"end_date"} options={[]} isRequired />
      <DropdownFilter name={"Status"} code={"vendor_status"} options={vendor_status_options} />
    </div>
  );
};

export default VendorListing;
