import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavTabs from "../nav";
import GridFilterDropdown from "components/datagrid/gridFilterDropdown";
import { ColumnApi, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import useShowFilterState from "components/common/hooks/useShowFilterState";
import { useTypedSelector } from "reducers";
import ToggleFilterButton from "components/datagrid/buttons/toggleFilterButton";
import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import { processFilterModel, processSortModel, saveDefaultOrder } from "services/common/gridService";
import ExportDataButton from "components/common/dataGrid/exportDataButton";
import ImportDataButton from "components/common/dataGrid/importDataButton";
import { useTranslation } from "react-i18next";
import { FIELD_NAME_MAP, getPayExpenseItemHeader } from "./listpayexpenseItemHeader";
import ExpenseReportApis from "services/admin/expenses/expenseReport/expenseReportApis";
import expenseReportSvc from "services/admin/expenses/expenseReport/expenseReportSvc";
import BulkAction from "./bulkAction";
import AccountCashBalance from "./accountCashBalance";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { change, getFormValues } from "redux-form";
import styles from "./payExpense.module.css";
import { payReimbursementType } from "../../../../services/admin/expenses/payReimbursementType";
import _ from "lodash";
import gridService from "services/grid/gridSvc";
const GRID_STORAGE_NAME = "ai.grid.payExpenseItem";

function PayExpenseList() {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [showReviewButton, setShowReviewButton] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<payReimbursementType.payObject[]>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const { showFilters, updateShowFilters } = useShowFilterState("listExpensesItemsFilter", true);
  const currentUser = useTypedSelector((state) => state.user);
  const checkBoxRef: any = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const payExpenseReviewForm: payReimbursementType.payExpenseReviewFormType = useSelector((state) =>
    getFormValues("payExpenseReviewForm")(state),
  );
  const selectedPayItems: payReimbursementType.payObject[] = payExpenseReviewForm?.selectedRows
    ? payExpenseReviewForm?.selectedRows
    : [];

  const gridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.setFilterModel(null);
    const filterInstance = params.api.getFilterInstance("expense_report.number");
    if (filterInstance) {
      filterInstance.setModel({
        filterType: "text",
        type: "blank",
      });
    }
    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: getRows });
  };

  const getParams = (params: IServerSideGetRowsParams) => {
    let expenseItemFieldMap = {
      "policy.name": "policy_name",
      currency_code: "currency_iso_code",
      "expense_report.number": "expense_report_number",
      "submitter.name": "submitter_name",
      "employee.name": "employee_name",
      "subsidiary.name": "subsidiary_name",
    };

    let sortObject: any = {};
    if (params?.request?.sortModel?.length > 0) {
      sortObject = processSortModel(params.request.sortModel, expenseItemFieldMap);
    } else {
      sortObject = { id: { direction: "desc", priority: 0 }, number: { direction: "desc", priority: 1 } };
    }
    let paramsObj: any = {
      sort: sortObject,
    };
    if (params?.request?.endRow !== undefined && params?.request?.startRow !== undefined) {
      paramsObj.items = params?.request?.endRow - params?.request?.startRow; //grid will pass in the first index and last index that needs to be fetched
      paramsObj.page = 1 + params?.request?.startRow / params.api.paginationGetPageSize();
    }

    if (params?.request?.filterModel) {
      let filters: any = processFilterModel(params.request.filterModel, FIELD_NAME_MAP);

      if (filters && filters["statuses"]) {
        filters["statuses"] = filters["statuses"].split(",");
      }
      // Filters need to send with params we are passign to expense list API
      paramsObj = { ...paramsObj, ...filters };
    }

    return paramsObj;
  };

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = async (params: IServerSideGetRowsParams) => {
    try {
      const result = await ExpenseReportApis.getPayExpenseList({ filter: getParams(params) });

      params.success({
        rowData: expenseReportSvc.decorateExpenseReport({ response: result.data }),
        rowCount: result.meta.count,
      });
    } catch (error) {
      params.fail();
    }
  };

  const gridHeaders = getPayExpenseItemHeader({
    gridApi,
    gridColumnApi,
    currentUser,
    checkBoxRef,
  });

  useEffect(() => {
    saveDefaultOrder(GRID_STORAGE_NAME, gridHeaders.defaultOrder);
  }, []);

  const handelAddToPaymentRun = () => {
    if (gridApi) {
      const selectedRows: payReimbursementType.payObject[] = gridService.getServerSideSelectedRows(gridApi);
      if (selectedRows.length < 1) {
        CreateNotification(
          "No Expense Report Selected",
          "Please select at least one Expense Report",
          NotificationType.danger,
        );
        return;
      }
      setShowReviewButton(true);
      setSelectedRows(selectedRows);
      const result = _.unionBy(selectedRows, selectedPayItems, "id");
      dispatch(change("payExpenseReviewForm", "selectedRows", result));
      const employeeSet = new Set();
      const expenseReportSet = new Set();
      selectedRows.forEach((item) => {
        employeeSet.add(item?.employee?.id);
        expenseReportSet.add(item?.id);
      });
      CreateNotification(
        `Success!`,
        `${employeeSet.size} Employee and ${expenseReportSet.size} Expense Report added to your payment run.`,
        NotificationType.success,
      );
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="mt-4">
          <Col>
            <NavTabs activePageName="Pay Reimbursements" />
          </Col>
        </Row>
        <Row className="w-100">
          <Col md={12}>
            <AccountCashBalance />
          </Col>
        </Row>
        <Row className="ml-1">
          <div className="d-flex justify-content-start">
            <BulkAction gridApi={gridApi} handelAddToPaymentRun={handelAddToPaymentRun} />
            <GridFilterDropdown
              gridApi={gridApi}
              gridColumnApi={gridColumnApi}
              gridStorageName={GRID_STORAGE_NAME}
              menuClass={`${styles.quickFilterMenu}`}
            />
            <span
              role="button"
              className={`btn btn-primary ml-2 ${styles.fortyPxHeight}`}
              onClick={handelAddToPaymentRun}
            >
              Add to Payment Run
            </span>
            {selectedPayItems?.length ? (
              <Link
                role="button"
                className={`btn btn-primary ml-2  ${styles.fortyPxHeight}`}
                to={{ pathname: "/ap/expenses_review_payment_run", state: selectedRows ? selectedRows : [] }}
              >
                Review Payment Run
              </Link>
            ) : null}
          </div>
          <Col className="d-flex justify-content-end">
            {gridApi && (
              <div className="ml-auto mt-1">
                <ExportDataButton
                  title={t("admin.pages.expenseReport.exportExpensesReport")}
                  csvName="expense_items"
                  gridApi={gridApi}
                />
              </div>
            )}
            <ToggleFilterButton
              classes="float-right"
              clickCallback={() => {
                updateShowFilters(!showFilters);
              }}
            />
          </Col>
        </Row>

        <Row className={`px-3 mt-3 ${styles.gridContainer}`}>
          <ServerSideDataGrid
            columnDefs={gridHeaders.columnDefs}
            defaultColDef={{
              resizable: true,
              filter: true,
              floatingFilter: showFilters,
            }}
            gridReady={gridReady}
            rowSelection="multiple"
            gridStorageName={GRID_STORAGE_NAME}
            domLayout="normal"
            paginationSize={25}
            paginationOptions={[25, 50, 100]}
            pagination
          />
        </Row>
      </Container>
    </>
  );
}

export default PayExpenseList;
