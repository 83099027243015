import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { Form } from "react-bootstrap";
import restApi from "../../../../providers/restApi";
import Select from "react-select";
import Styles from "../override.module.css";

import {
  findSelectedMultiple,
  findSelectedSingle,
  onBlur,
  onBlurSingle,
  onChange,
  onChangeSingle,
  parseForSelect,
} from "../../../../services/general/helpers";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx
 */
export default function BusinessUnitPicker(props) {
  let {
    label,
    input,
    meta: { touched, error },
    isMulti,
    tooltip,
    templateUrl,
    labelClassName,
    disabled,
    required,
    notPortal,
  } = props;
  const [options, setOptions] = useState([]);
  const mounted = useRef(false);

  const restApiService = useMemo(() => new restApi(), []);

  const getOptions = useCallback(async () => {
    const result = await restApiService.get(
      "business_units",
      {
        status: "ACTIVE",
      },
      null,
      true,
      null,
      true,
    );
    const value = parseForSelect(result.data);
    if (mounted.current) setOptions(value);
  }, [restApiService]);

  useEffect(() => {
    getOptions();
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [getOptions]);

  const multiPicker = (
    <>
      <Form.Group className={Styles.select}>
        {label && (
          <Form.Label>
            {label ?? ""}
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={findSelectedMultiple(input, options)}
          placeholder="Select..."
          onChange={(value) => onChange(input, value)}
          onBlur={() => onBlur(input, input.value)}
          options={options}
          isMulti={isMulti}
          isClearable
          isDisabled={disabled}
          classNamePrefix="select"
          menuPortalTarget={document.querySelector("body")}
        />
        {error && touched && (
          <div
            style={{
              fontSize: "80%",
              color: "red",
              padding: "2px",
            }}
          >
            {error}
          </div>
        )}
      </Form.Group>
    </>
  );

  const singlePicker = (
    <>
      <Form.Group className={Styles.select}>
        {label && (
          <Form.Label className={labelClassName}>
            {label ?? ""}
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          required={required}
          value={findSelectedSingle(input, options)}
          placeholder="search/select"
          onChange={(value) => onChangeSingle(input, value)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={options}
          menuPosition="fixed"
          menuPlacement="auto"
          isMulti={isMulti}
          isClearable
          isDisabled={disabled}
          classNamePrefix="select"
        />
        {error && touched && (
          <div
            style={{
              fontSize: "80%",
              color: "red",
              padding: "2px",
            }}
          >
            {error}
          </div>
        )}
      </Form.Group>
    </>
  );

  const getPicker = (templateUrl) => {
    switch (templateUrl) {
      case "single":
        return singlePicker;
      case "multiple":
        return multiPicker;
      default:
        if (isMulti) return multiPicker;
        else return singlePicker;
    }
  };

  return <>{getPicker(templateUrl)}</>;
}
