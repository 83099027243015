import React from "react";
import CategoriesOptionsFilter from "../reportFilters/categoriesDropdown";
import ContactsOptionsFilter from "../reportFilters/contactsDropdown";
import DateFilter from "../reportFilters/date";
import DropdownFilter from "../reportFilters/dropdown";
import LocationsOptionsFilter from "../reportFilters/locationsDropdown";

const ExpenseTransactionDetail = ({}) => {
  const transaction_type = [
    { value: "", label: "ALL" },
    { value: true, label: "Reimbursable" },
    { value: false, label: "Non-Reimbursable" },
  ];

  const expense_status_options = [
    { value: "", label: "ALL" },
    { value: "DRAFT", label: "DRAFT" },
    { value: "NEW", label: "NEW" },
    { value: "PENDING", label: "PENDING APPROVAL" },
    { value: "APPROVED", label: "APPROVED" },
    { value: "REJECTED", label: "REJECTED" },
    { value: "PAID", label: "PAID" },
  ];

  return (
    <div>
      <DateFilter name={"Start Date"} code={"between_created_at.start_date"} options={[]} isRequired />
      <DateFilter name={"End Date"} code={"between_created_at.end_date"} options={[]} isRequired />
      <ContactsOptionsFilter name={"Employee Name"} code={"employee_id"} options={[]} />
      <DropdownFilter name={"Transaction Type"} code={"reimbursable"} options={transaction_type} />
      <DropdownFilter name={"Expense Status"} code={"expense_status"} options={expense_status_options} />
      <CategoriesOptionsFilter name={"Category"} code={"category_id"} options={[]} />
      <LocationsOptionsFilter name={"Location"} code={"location_id"} options={[]} />
    </div>
  );
};

export default ExpenseTransactionDetail;
