import { datadogRum } from "@datadog/browser-rum";
import { LoginCallback, SecureRoute, useOktaAuth } from "@okta/okta-react";
import _ from "lodash";
import currency_codes from "lookups/currencyCodeLookup";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import { selectCompanyDefault, setAppCurrencyCodes, setCompanyDefault } from "reducers/common/appCommonStatesSlice";
import AppConfigsApis from "../src/services/common/appConfigs/appConfigsApis";
import "./App.css";
import "./app_icons.css";
import config from "./config";
import "./layout/layout.style.css";
import { APP_EMITTER, APP_EVENTS, UNPAUSE_AXIOS } from "./providers/appEmitter";
import RestApi from "./providers/restApi";
import { addUserDetails } from "./reducers/userReducers.js";
import { adminRoutes, commonRoutes, vendorPortalRoutes } from "./routes";
import { Can } from "./services/authorization/authorization";
import { DeleteCookie, GetCookie } from "./services/general/cookies";
import { CreateNotification, NotificationType } from "./services/general/notifications";
import { oktaService } from "./services/okta/oktaSvc";

const Page404 = React.lazy(() => import("./pages/common/Page404/Page404"));
const Page500 = React.lazy(() => import("./pages/common/Page500/Page500"));
const Login = React.lazy(() => import("./pages/login/login"));
const Logout = React.lazy(() => import("./pages/common/logout/logout.jsx"));
const ForgotPassword = React.lazy(() => import("./pages/login/forgotPassword"));
const VirtualCardViewTokenized = React.lazy(() => import("./pages/virtualCards/virtualCardViewTokenized"));
const PurchaseOrderViewTokenized = React.lazy(() => import("./pages/purchase_orders/purchase_order_view_tokenized.js"));
const AuthProcess = React.lazy(() => import("./pages/admin/oauthProcess/booksOAuth"));
const SideBar = React.lazy(() => import("./layout/sideBar"));
const Header = React.lazy(() => import("./layout/header"));
const Unsubscribe = React.lazy(() => import("./pages/common/unsubscribe"));

const restApiService = new RestApi();

const oktaEnabled = config.oktaEnabled;

// Call it right away to prevent removal
datadogRum.init({
  clientToken: "pubf362f87671a90f15d0cc410ff541e53d",
  applicationId: "a47514a9-1b99-4d04-badf-e2fbdeff71c5",
  site: "datadoghq.com",
  service: "corpay-complete",
  env: process.env.REACT_APP_ENV,
  // version: moment().format("HH:mm:ss"),
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  defaultPrivacyLevel: "allow",
});

datadogRum.startSessionReplayRecording();

const MainPage = (props) => {
  const { authState } = useOktaAuth();
  const [isSidebarCollapse, setIsSidebarCollapse] = useState(false);
  const user = useSelector((state) => state.user);
  const [usingOkta, setUsingOkta] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const companyDefault = useSelector(selectCompanyDefault);

  useEffect(() => {
    // Track page views on route change
    datadogRum.startView(location.pathname);
  }, [location]);

  // getting app config currency codes
  const getAppConfigCurrencyCodes = async () => {
    try {
      const result = await AppConfigsApis.getCurrencyCodes();
      if (result[0]?.config_data?.currencies) {
        return result[0].config_data.currencies;
      }
      return [];
    } catch (error) {}
  };

  const getCompanyDefault = useCallback(async () => {
    try {
      const response = await restApiService.get(`company/default`);
      if (response.data) {
        dispatch(setCompanyDefault(response.data));
      }
    } catch (error) {}
  }, [dispatch]);

  // merge crypto currency codes from app config and lookups base on company setting
  const getAppCurrencyCode = useCallback(async () => {
    if (companyDefault?.crypto_currency_support) {
      const appConfigsCurrencyCodes = await getAppConfigCurrencyCodes();
      const combinedCurrencyCodes = [...currency_codes, ...appConfigsCurrencyCodes];
      const uniqueCurrencyCodes = _.uniqBy(combinedCurrencyCodes, (currencyCode) => currencyCode.value);
      dispatch(setAppCurrencyCodes(uniqueCurrencyCodes));
    } else {
      dispatch(setAppCurrencyCodes(currency_codes));
    }
  }, [companyDefault?.crypto_currency_support, dispatch]);

  const getUserDetails = useCallback(async () => {
    if (localStorage.getItem("Token")) {
      const result = await restApiService.get("user");
      if (result && result.data) {
        dispatch(addUserDetails(result.data));
        datadogRum.setUser({
          id: result.data.id,
          email: result.data.email,
          name: result.data.name,
        });
        if (result.data.role !== "vendor") {
          await getCompanyDefault();
        }
      } else {
        CreateNotification(
          "Error",
          "There was an error logging in. Please refresh the page or contact support",
          NotificationType.danger,
        );
      }
    }
  }, []);

  // getUserDetails upon login and every time accessToken changes from renewal by tokenManager
  useEffect(() => {
    if (authState && authState.isAuthenticated && authState.accessToken) {
      getUserDetails();
    }
  }, [authState]);

  const checkAuthentication = async () => {
    // If there is a domain cookie that means a NON okta user is coming from accrualify. We grab the token and get the user object
    // Note this logic is duplicated in the login.js file, this is temporary until everyone is moved over to okta
    let accessTokenFromAccrualify = GetCookie("domainAccessToken");
    if (accessTokenFromAccrualify) {
      // if legacy token from angular app
      //delete the cookie to avoid any page refresh inconsistencies
      DeleteCookie("domainAccessToken");
      localStorage.setItem("Token", accessTokenFromAccrualify);
    } else {
      (await oktaService.getTokensFromSession()) && setUsingOkta(true);
    }
    UNPAUSE_AXIOS(); //set window.axiosProceed to true
    APP_EMITTER.emit(APP_EVENTS.AXIOS_RESOLVE); // emit that app is ready to proceed with axios requests
    if (!localStorage.getItem("Token")) {
      return RestApi.handleUnauthorized();
    }
    !localStorage.getItem("USING_OKTA_LOGIN") && (await getUserDetails());
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  useEffect(() => {
    getAppCurrencyCode();
  }, [companyDefault?.crypto_currency_support, getAppCurrencyCode]);

  return (
    <div className="theme">
      {((authState && authState.isAuthenticated && user) || (!usingOkta && user)) && (
        <header className={"d-block header-border headerHeight bg-white no-print"}>
          <Header {...props} isSidebarCollapse={isSidebarCollapse} />
        </header>
      )}

      <div className="d-md-flex justify-content-start">
        <section className="rightSidelayout d-flex overflow-hidden">
          {((authState && authState.isAuthenticated && user) || (!usingOkta && user)) && (
            <SideBar collapseSidebar={(value) => setIsSidebarCollapse(value)} />
          )}

          <div
            className={`themeBackground ${(authState && authState.isAuthenticated && user) || (!usingOkta && user) ? "heightWithHeader" : "heightWithoutHeader"}`}
          >
            {/* Okta own route, do not modify */}
            <Route path="/login/callback" component={LoginCallback} />

            <Route
              exact
              path="/login"
              // name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/logout"
              // name="Logout"
              render={(props) => <Logout />}
            />
            {/*<Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />*/}
            <Route
              exact
              path="/404"
              // name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              // name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route exact path="/unsubscribe" render={(props) => <Unsubscribe {...props} />} />
            <Route
              exact
              path="/reset-password"
              // name="Reset Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/ar/virtual-card-view/:token"
              // name="Virtual Card View"
              render={(props) => <VirtualCardViewTokenized {...props} />}
            />
            <Route
              exact
              path="/ar/purchase-order-view/:token"
              // name="Purchase Order View"
              render={(props) => <PurchaseOrderViewTokenized {...props} />}
            />
            <Route
              exact
              path={["/portal/oauth_process", "/portal/xero_oauth_process"]}
              // name="Portal oAuthProcess"
              render={(props) => <AuthProcess />}
            />

            {/* This is weird logic but it works. We need to render the routes when we arent logged in, but dont render them when we are logged in and havent gotten our user object yet. */}
            {/* Since the routes need the user object and will blow up without it, we have to not load the routes until its fetched */}
            {(!authState || !authState.isAuthenticated || (authState && authState.isAuthenticated && user)) &&
              usingOkta && (
                <div className="h-100 d-flex flex-column">
                  <div>
                    {commonRoutes.map((route) => (
                      // use these routing pattern where we need to call routes
                      <SecureRoute key={route.path} path={route.path} exact={route.exact} component={route.component} />
                    ))}
                    {adminRoutes.map((route) => (
                      <SecureRoute key={route.path} path={route.path} exact={route.exact} component={route.component} />
                    ))}
                    {vendorPortalRoutes.map((route) => {
                      //If the route has authorization we need to manually render the component protected by CAN
                      return route.authorization ? (
                        <SecureRoute
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          render={(props) => (
                            <Can
                              I={route.authorization.I}
                              a={route.authorization.a}
                              permissions={route.authorization.permissions}
                            >
                              {() => <route.component {...props} />}
                            </Can>
                          )}
                        />
                      ) : (
                        <SecureRoute
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          component={route.component}
                        />
                      );
                    })}
                    {/* <div className="mt-auto pt-2">
                    <Footer/>
                  </div> */}
                  </div>
                </div>
              )}
            {/* END OKTA AUTH */}

            {/* NON OKTA ROUTES*/}
            {/* These are the same exact routes as above but do not have the SecureRoute componenet */}
            {/*  */}
            {!usingOkta && user && (
              <div className="h-100 d-flex flex-column">
                <div>
                  {commonRoutes.map((route) => (
                    // use these routing pattern where we need to call routes
                    <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                  ))}
                  {adminRoutes.map((route) => (
                    <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                  ))}
                  {vendorPortalRoutes.map((route) => {
                    //If the route has authorization we need to manually render the component protected by CAN
                    return route.authorization ? (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                          <Can
                            I={route.authorization.I}
                            a={route.authorization.a}
                            permissions={route.authorization.permissions}
                          >
                            {() => <route.component {...props} />}
                          </Can>
                        )}
                      />
                    ) : (
                      <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                    );
                  })}
                </div>
                {/* {location.pathname == "/ap/settings" && (
                  <div className="mt-auto pt-2">
                    <Footer />
                  </div>
                )} */}
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default MainPage;
