import { DatePickerField } from "components/forms/hookFormFields";
import React from "react";
import { Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import useCustomLabel from "services/admin/customLabels/useCustomLabel";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";

const UploadQueueDate = () => {
  const { setValue } = useFormContext<InvoiceType.InvoiceDetailType>();
  const { getCustomLabel } = useCustomLabel();
  const invoiceCommonHookSvc = useInvoiceCommonSvc();

  const onDateChange = (date: string | null) => {
    if (date) {
      setValue("date", date);
      invoiceCommonHookSvc.updateDueDate();
    }
  };

  return (
    <Col md="6" className="mb-3">
      <DatePickerField
        name="date"
        label={getCustomLabel("admin.pages.invoice.invoiceDate")}
        required
        onChange={onDateChange}
      />
    </Col>
  );
};

export default UploadQueueDate;
