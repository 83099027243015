import React from "react";
import TabNavigation from "../../navigation/tabNavigation";

//NOTE: add only those route which we want to show on tabNavigations
// href is used for angular
// path is used for react

export const vendorsNavTabRoutes = [
  {
    path: "/ap/vendors",
    pageName: "Vendors",
    authorization: {
      I: "list",
      a: "Vendors",
    },
  },
  {
    pageName: "Contacts",
    href: "contacts",
    authorization: {
      I: "list",
      a: "Contacts",
    },
  },
  {
    path: "/ap/vendor_tracker",
    pageName: "Vendor Tracker",
    authorization: {
      I: "list",
      a: "Vendors",
    },
  },
];

const NavTabs = ({ activePageName }: { activePageName: string }) => {
  return <TabNavigation activePageName={activePageName} navigationTab={vendorsNavTabRoutes} />;
};

export default NavTabs;
