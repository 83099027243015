import React, { useEffect, useRef, useState, useCallback } from "react";
import restApi from "../../../../providers/restApi";
import AsyncSelect from "react-select/async";
import Styles from "../override.module.css";
import { Form } from "react-bootstrap";
import {
  onChange,
  onBlur,
  parseForSelect,
  onChangeSingle,
  onBlurSingle,
  findSelectedMultiple,
} from "../../../../services/general/helpers";
import _ from "lodash";
import { Mandatory } from "../../../forms/bootstrapFields";

const restApiService = new restApi();

const optimizedLoadOptions = async (inputValue) => {
  // 1.using chunking to load options
  // 2. break inputValue array in to chunk of 100
  // cashing will work only if data is added remove from last for other case it will make api calls

  if (_.isArray(inputValue)) {
    const chunks = [];
    for (let i = 0; i < inputValue.length; i += 100) {
      chunks.push(inputValue.slice(i, i + 100));
    }

    const promises = chunks.map((chunk) => {
      return restApiService.get("vendors.lk", { id: chunk, items: 100 }, null, true, null, true);
    });

    const vendorChunks = await Promise.all(promises);

    // reduce all vendorChunks in on array
    const results = vendorChunks.reduce((acc, curr) => {
      return [...acc, ...curr.data];
    }, []);

    const options = parseForSelect(results);
    return options;
  }
  return [];
};

const MultiPicker = ({ loadOptions, input, isMulti }) => {
  const [selected, setSelected] = useState([]);
  const selectedVendorIds = input.value ?? [];
  const [loading, setLoading] = useState(false);
  const mounted = useRef(false);

  // initialilly it will load all the label for ids (input.values)
  const findSelected = useCallback(async () => {
    try {
      if (_.isArray(input.value) && !input.value.length) return setSelected([]);
      setLoading(true);
      const vendors = await optimizedLoadOptions(input.value);
      if (mounted.current) setSelected(vendors);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // select default values
    mounted.current = true;
    findSelected();

    return () => {
      mounted.current = false;
    };
  }, []);

  const onAddVendor = (vendor) => {
    setSelected([...selected, vendor]);
  };

  const removeVendor = (removed) => {
    if (_.isArray(removed) && removed.length === 1) {
      // remove one
      setSelected(selected.filter((selectedVendor) => selectedVendor.value !== removed[0].value));
    } else if (_.isArray(removed) && removed.length > 1) {
      // remove all
      setSelected([]);
    }
  };

  const onChangeVendors = (vendors) => {
    // added
    const added = vendors.filter((vendor) =>
      _.isArray(selectedVendorIds) ? !selectedVendorIds.includes(vendor.value) : true,
    );
    if (_.isArray(added) && added.length > 0) {
      onAddVendor(added[0]);
    }

    // removed
    const removed = selected.filter((selectedVendor) => {
      const vendorIds = vendors.map((vendor) => vendor.value);
      return !vendorIds.includes(selectedVendor.value);
    });
    if (_.isArray(removed) && removed.length > 0) {
      removeVendor(removed);
    }

    // this is updating the value in redux form
    onChange(input, vendors);
  };

  return (
    <AsyncSelect
      value={selected}
      placeholder="Search/select"
      cacheOptions
      loadOptions={loadOptions}
      onChange={(value) => onChangeVendors(value)}
      onBlur={() => onBlur(input, input.value)}
      defaultOptions
      isMulti={isMulti}
      isLoading={loading}
    />
  );
};

const SinglePicker = ({ loadOptions, input, isMulti, className }) => {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const mounted = useRef(false);
  const findSelected = useCallback(async () => {
    if (!input.value) return setSelected(null);
    try {
      let result;
      let vendor;

      if (_.isString(input.value) || _.isNumber(input.value)) {
        setLoading(true);
        result = await restApiService.get("vendors/" + input.value);
      }

      if (result && _.isPlainObject(result.data) && result.data.id) {
        vendor = { label: result.data.name, value: result.data.id };
      }
      if (mounted.current) setSelected(vendor);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [input.value]);

  useEffect(() => {
    // select default values
    mounted.current = true;
    findSelected();

    return () => {
      mounted.current = false;
    };
  }, [findSelected, input.value]);

  return (
    <AsyncSelect
      className={className}
      value={selected}
      placeholder="Search/select"
      cacheOptions
      loadOptions={loadOptions}
      onChange={(value) => onChangeSingle(input, value)}
      onBlur={() => onBlurSingle(input, input.value)}
      defaultOptions
      isMulti={isMulti}
      isLoading={loading}
    />
  );
};

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const VendorPicker = (props) => {
  let {
    className,
    label,
    input,
    meta: { touched, error, warning },
    isMulti,
    require,
    templateUrl,
    tooltip,
    required,
  } = props;

  const getVendors = async (getParams) => {
    try {
      const result = await restApiService.get("vendors.lk", getParams, null, true, null, true);
      const value = parseForSelect(result.data);
      return value;
    } catch (error) {}
  };

  // used to find option when user search
  const loadOptions = async (inputValue, callback) => {
    const getParams = {
      VENDOR_NAME: inputValue,
      VENDOR_LIMIT: 100,
      PO_NOT_REQUIRED: "",
      status: "ACTIVE",
    };
    callback(await getVendors(getParams));
  };

  const getPicker = (templateUrl) => {
    switch (templateUrl) {
      case "single":
        return <SinglePicker input={input} isMulti={isMulti} loadOptions={loadOptions} className={className} />;
      case "multiple":
        return <MultiPicker input={input} isMulti={isMulti} loadOptions={loadOptions} />;
      default:
        if (isMulti) return <MultiPicker input={input} isMulti={isMulti} loadOptions={loadOptions} />;
        else return <SinglePicker input={input} isMulti={isMulti} loadOptions={loadOptions} className={className} />;
    }
  };

  return (
    <Form.Group className={Styles.select}>
      {label && (
        <Form.Label className={Styles.label}>
          {label}
          <Mandatory required={required} />
          {tooltip ?? null}
        </Form.Label>
      )}
      {getPicker(templateUrl)}
      {error && touched && <div style={{ fontSize: "80%", color: "red", padding: "2px" }}>{error}</div>}
    </Form.Group>
  );
};

export default VendorPicker;
