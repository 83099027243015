import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { required } from "../../../../../services/validations/reduxFormValidation";
import BankCodeValidator from "../../bankCodeValidator";
import { AccountNumberValidator } from "../../accountNumberValidator";

const TwNgn = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="6">
        <BankCodeValidator modelName={`${modelName}`} label={t("common.paymentMethod.bankCode")} noValidate={true} />
      </Col>
      <Col md="6">
        <AccountNumberValidator
          modelName={`${modelName}`}
          noValidate={true}
          label={t("common.paymentMethod.accountNumberLabel")}
        />
      </Col>
    </>
  );
};

export default TwNgn;
