import { useHistory, useParams } from "react-router";
import { InjectedFormProps, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ColumnApi, DragStoppedEvent, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { RootState, useTypedSelector } from "reducers";

import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import { CreateNotification, NotificationType } from "services/general/notifications";

import { WombatMappingTool } from "wombatifier/components/wombatifier/mapperTool";
import { WombatFunctionsMenu } from "wombatifier/components/wombatifier/wombatFunctionsMenu";

import { FileType, WombatColDef, WombatFieldType } from "wombatifier/services/wombat.types";
import { WombatFormStateType, WombatFormType, WombatType } from "wombatifier/services/wombat.types";
import { WombatSvc } from "wombatifier/services/wombatSvc";
import { WombatApis } from "wombatifier/services/wombatApis";
import { FromHeader } from "./fromHeader";
import { cloneDeep, debounce } from "lodash";
import { MapperHeaders } from "./mapperHeaders";

const FROM_HEADER_HEIGHT = 80;
const TO_HEADER_HEIGHT = 160;

type RenderedRowsType = Array<{ [key: string]: string }>;

/*
Important Pieces for This Logic
1) We use a mapToFromHashRef (key => to_header, value => from_header} to render the file column headers
   mapToFromHashRef[to_header] => from_header
2) When ADDING a new wombat, mapToFromHashRef is built from the file column headers with key and value the same i.e.
   {column_1: column_1, column_2: column_2}
3a) When EDITING an existing wombat, mapToFromHashRef is built from the wombatDetail.wombat_fields
   {name_1, value_mapped_from_1, name_2: value_mapped_from_2}
3b) When updating function field or MAP_TO's, WOMBATIFY endpoint is called and the mapToFromHashRef is OVERWRITTEN with the form data / payload
    original MapToFromHash => {output_1: column_1, output_2: column_2} 
    after assigning MAP_TO => {NEW_1: column_1, NEW_2: column_2}

    This is done to ensure that no columns (mapped or unmapped) are not lost after wombatifying the sample data
*/

const createHeaders = (
  mapToHeaders: string[],
  mapToFromHashRef: { current: { [key: string]: string } },
  mapFromToHashRef: { current: { [key: string]: string } },
  mapToOrderRef: { current: string[] },
  handleDelete: (index: number) => void,
): WombatColDef[] => {
  const mapToFromHash = mapToFromHashRef.current;
  return mapToHeaders
    .filter((h) => h !== "row_number")
    .map((h, index) => {
      return {
        field: h,
        index: index.toString(),
        colId: index.toString(),
        mapToFromHashRef: mapToFromHashRef,
        mapFromToHashRef: mapFromToHashRef,
        mapToOrderRef: mapToOrderRef,
        headerClass: "p-0",
        headerComponent: () => <FromHeader index={index} handleDelete={handleDelete} />,
        cellRenderer: (params: { data: { [key: string]: any } }) => {
          const rowValue = params.data[mapToFromHash[h]] || params.data[h];
          return <span>{rowValue ? `${rowValue}` : null}</span>;
        },
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: WombatMappingTool,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        lockVisible: true,
        minWidth: 225,
      };
    });
};

const MAX_HEADERS = 1000;

const Grid = ({ handleSubmit, valid }: InjectedFormProps<WombatFormStateType, {}>) => {
  const { id } = useParams<{ id: string | undefined }>();
  const [mapToHeaders, setMapToHeaders] = useState<string[]>([]);
  const gridApi = useRef<GridApi>();
  const gridColumnApi = useRef<ColumnApi>();
  const history = useHistory();
  const mapToOrderRef = useRef<string[]>([]);
  const mapToFromHashRef = useRef<{ [key: string]: string }>({});
  const mapFromToHashRef = useRef<{ [key: string]: string }>({});
  const originalRowsRef = useRef<RenderedRowsType>([]);
  const wombatifiedRows = useRef<RenderedRowsType>([]);
  const wombatFormDataRef = useRef<WombatFormType>();
  const gridOrdered = useRef<boolean>(false);
  const lastVisibleColumnId = useRef<string>();
  useTypedSelector((state: RootState) => WombatSvc.setReferenceWombatFormData(state, wombatFormDataRef));

  const dispatch = useDispatch();

  const setMapFromToHash = useCallback(() => {
    mapFromToHashRef.current = Object.entries(mapToFromHashRef.current).reduce(
      (result, [to, from]) => {
        result[from] = to;
        return result;
      },
      {} as { [key: string]: string },
    );
  }, []);

  const setMapToFromHash = useCallback((wombatFields: WombatFieldType[]) => {
    mapToFromHashRef.current = wombatFields.reduce(
      (result, wf) => {
        result[wf.name] = wf.value_mapped_from;
        return result;
      },
      {} as { [key: string]: string },
    );
    setMapFromToHash();
  }, []);

  const getRows = useCallback(async (params: IServerSideGetRowsParams) => {
    if (wombatifiedRows.current.length > 0) {
      return params.success({
        rowCount: wombatifiedRows.current.length,
        rowData: wombatifiedRows.current,
      });
    } else {
      params.success({
        rowCount: originalRowsRef.current.length,
        rowData: originalRowsRef.current,
      });
    }
    handleWombatify();
  }, []);

  const handleWombatify = useCallback(async () => {
    const formValues = wombatFormDataRef.current;
    if (!formValues?.config?.wombat_fields?.some((wf) => wf.value_functions?.length)) {
      return;
    }
    setLastColumnVisibleId();
    const finalWombatFormValues = WombatSvc.prepareWombatPayload(formValues);
    setMapToFromHash(finalWombatFormValues.config.wombat_fields);
    try {
      let newRows = await WombatApis.wombatify(finalWombatFormValues, originalRowsRef.current);
      const newHeaders = [...mapToOrderRef.current];
      newRows = newRows.map((row, index) => ({ ...row, row_number: index.toString() }));
      wombatifiedRows.current = newRows;
      setMapToHeaders(newHeaders);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const setLastColumnVisibleId = () => {
    if (!gridColumnApi.current || !gridApi.current) {
      return;
    }
    const horizontalPixelRange = gridApi.current.getHorizontalPixelRange();
    const visibleColumns = gridColumnApi.current.getAllDisplayedColumns();
    if (!visibleColumns || !horizontalPixelRange) {
      return;
    }
    for (const curCol of visibleColumns) {
      // @ts-ignore
      if (horizontalPixelRange.left <= curCol.getLeft()) {
        lastVisibleColumnId.current = curCol.getColId();
        break;
      }
    }
  };

  const gridReady = useCallback((params: GridReadyEvent) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
    //tell the grid that we are using a custom data source
  }, []);

  const updateLocalFiles = useCallback(
    async (file_attributes: FileType[]) => {
      const { headers, mappedRows } = await WombatSvc.extractDataFromFile(file_attributes);
      let newHeaders: string[] = headers;
      originalRowsRef.current = mappedRows;
      WombatSvc.updateMappingFromList([...headers], dispatch);
      wombatifiedRows.current = [];
      newHeaders = WombatSvc.getConformedHeaders(mapToOrderRef.current, headers, mapFromToHashRef.current);
      mapToOrderRef.current = [...newHeaders];
      const currentWombatFields = wombatFormDataRef.current?.config?.wombat_fields || [];
      const wombatFields = WombatSvc.renewWombatFields(currentWombatFields, newHeaders) || [];
      setMapToFromHash(wombatFields);
      WombatSvc.updateForm(
        { ...(wombatFormDataRef.current || {}), id, config: { wombat_fields: wombatFields } },
        dispatch,
      );
      setMapToHeaders(newHeaders);
    },
    [dispatch],
  );

  const headerComparisonFn = useCallback((headers) => {
    let slottedHeaders = Array(MAX_HEADERS);
    slottedHeaders.splice(0, headers.length, ...headers);
    return slottedHeaders;
  }, []);

  const onWombatFunctionChange = debounce(() => {
    wombatifiedRows.current = [];
    gridApi.current?.refreshServerSide();
  }, 250);

  const handleMoved = useCallback((event: DragStoppedEvent) => {
    if (!event.target.querySelector("#WOMBAT_HEADER")) {
      return;
    }
    setLastColumnVisibleId();
    setMapToOrder(event.columnApi);
  }, []);

  const loadWombatById = useCallback(
    async (id: string) => {
      try {
        const wombatData = await WombatApis.get(id);
        const preparedWombatData = WombatSvc.prepareWombatPayload(wombatData, true) as WombatType;
        if (preparedWombatData?.sample_data?.length) {
          originalRowsRef.current = preparedWombatData.sample_data;
          WombatSvc.updateMappingFromList([...Object.keys(preparedWombatData.sample_data[0])], dispatch);
        }
        WombatSvc.updateForm(preparedWombatData, dispatch);
        const wombatFields = preparedWombatData.config.wombat_fields;
        const newMappedToHeaders = wombatFields.map((wombat_field) => wombat_field.name);
        setMapToFromHash(wombatFields);
        setMapToHeaders([...newMappedToHeaders]);
      } catch (err) {
        CreateNotification(
          `Wombat Fetch Error`,
          `Wombat #${id} failed to load due to ${err}.`,
          NotificationType.danger,
        );
      }
    },
    [dispatch],
  );

  const submitWombat = useCallback(
    async (values: WombatFormStateType) => {
      let finalWombatFormValues = WombatSvc.prepareWombatPayload(values.form);
      finalWombatFormValues.sample_data = originalRowsRef.current;

      const actionLabel = id ? "Wombat Edit" : "Wombat Submission";
      const actionVerb = id ? "Updated" : "Submitted";
      try {
        const res = id
          ? await WombatApis.patch(id, finalWombatFormValues)
          : await WombatApis.create(finalWombatFormValues);
        CreateNotification(actionLabel, `Wombat - ${res.name} Successfully ${actionVerb}.`, NotificationType.success);
        setTimeout(() => history.push("/ap/wombatifier"));
      } catch (err) {
        CreateNotification(
          `${actionLabel} Error`,
          `Wombat - ${finalWombatFormValues.name} failed due to ${err}.`,
          NotificationType.danger,
        );
      }
    },
    [id],
  );

  const handleColumnPositions = useCallback((wombatFields: WombatFieldType[], newMapToHeaders: string[]) => {
    setMapToFromHash(wombatFields);
    setMapToHeaders([...newMapToHeaders]);
    WombatSvc.updateWombatFields([...wombatFields], dispatch);
    WombatSvc.setSelectedIndex(undefined, dispatch);
  }, []);

  const setMapToOrder = useCallback(
    (columnApi: ColumnApi) => {
      const wombatFields = [...(wombatFormDataRef.current?.config?.wombat_fields || [])];
      const columnState = columnApi.getColumnState();
      const newOrder = columnState.map((item) => item.colId);
      const reorderedWombatFields = newOrder.map((index) => wombatFields[parseInt(index)]);
      const newMappedToHeaders = reorderedWombatFields.map((wf) => wf.name);
      mapToOrderRef.current = [...newMappedToHeaders];
      handleColumnPositions(reorderedWombatFields, newMappedToHeaders);
      gridApi?.current?.resetColumnState();
    },
    [dispatch],
  );

  const addColumn = useCallback(() => {
    setLastColumnVisibleId();
    const wombatFields = cloneDeep(wombatFormDataRef.current?.config?.wombat_fields) || [];
    const newColumnName = `COLUMN ${wombatFields.length}`;
    wombatFields.push({ name: newColumnName, value_mapped_from: newColumnName, value_functions: [] });
    const newMappedToHeaders = wombatFields.map((wombat_field) => wombat_field.name);
    mapToOrderRef.current = [...newMappedToHeaders];
    handleColumnPositions(wombatFields, newMappedToHeaders);
  }, []);

  const deleteColumn = useCallback((index: number) => {
    setLastColumnVisibleId();
    const wombatFields = cloneDeep(wombatFormDataRef.current?.config?.wombat_fields) || [];
    wombatFields.splice(index, 1);
    mapToOrderRef.current.splice(index, 1);
    const newMappedToHeaders = wombatFields.map((wombat_field) => wombat_field.name);
    handleColumnPositions(wombatFields, newMappedToHeaders);
  }, []);

  const setColumnVisible = () => {
    if (!gridColumnApi.current || !gridApi.current) {
      return;
    }
    const columns = gridColumnApi.current.getColumns() || [];
    const lastVisibleColumn = columns.find((item) => item.getColId() === lastVisibleColumnId.current);
    if (lastVisibleColumn) {
      gridApi.current.ensureColumnVisible(lastVisibleColumn, "start");
    }
  };

  useEffect(() => {
    if (mapToHeaders.length && gridColumnApi.current && !gridOrdered.current) {
      setMapToOrder(gridColumnApi.current);
      gridOrdered.current = true;
    }
    if (gridApi.current && gridColumnApi.current && mapToHeaders.length) {
      gridApi.current.refreshHeader();
      gridApi.current.refreshServerSide();
      lastVisibleColumnId.current && setColumnVisible();
    }
  }, headerComparisonFn(mapToHeaders));

  useEffect(() => {
    if (id) {
      loadWombatById(id);
    }
  }, [id]);

  return (
    <Container fluid className="px-0 mx-">
      <MapperHeaders
        id={id}
        submit={handleSubmit(submitWombat)}
        valid={valid}
        updateLocalFiles={updateLocalFiles}
        addColumn={addColumn}
      />
      <Row className="mx-0 overflow-scroll pb-5" style={{ height: "80vh" }}>
        <Col className="px-0 pt-2">
          <Row className="mx-0 h-100">
            <ServerSideDataGrid
              columnDefs={createHeaders(mapToHeaders, mapToFromHashRef, mapFromToHashRef, mapToOrderRef, deleteColumn)}
              gridReady={gridReady}
              rowSelection="multiple"
              domLayout="normal"
              getRowId={(params) => params?.data?.row_number}
              hideSideBar
              floatingFiltersHeight={TO_HEADER_HEIGHT}
              headerHeight={FROM_HEADER_HEIGHT}
              gridOptions={{
                suppressPropertyNamesCheck: true,
                onDragStopped: handleMoved,
                serverSideDatasource: { getRows },
              }}
            />
          </Row>
        </Col>
        <WombatFunctionsMenu onWombatFunctionChange={onWombatFunctionChange} />
      </Row>
    </Container>
  );
};

export const Mapper = reduxForm<WombatFormStateType, {}>({
  touchOnChange: false,
  touchOnBlur: false,
  destroyOnUnmount: true,
  enableReinitialize: true,
  form: WombatSvc.FORM_NAME,
})(Grid);
