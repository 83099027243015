/*eslint-disable eqeqeq*/
import { AddressValidations } from "../validations/miscValidations";
import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import PaymentCountryPicker from "../../pickers/paymentCountryPicker";
import StatePicker from "../../pickers/statePicker";

function filterAddress(fieldName) {
  var filteredField = AddressValidations.find(function (item) {
    return item.paymentType == fieldName;
  });
  return filteredField;
}

function selectField(fillteredPayment, fieldName) {
  return fillteredPayment.find((item) => item.field === fieldName);
}
const CommonPaymentAddress = (props) => {
  const [data] = useState({
    required: props,
    allValidations: "",
    address1: "",
    city: "",
    state: "",
    zipcode: "",
  });

  data.allValidations = props.payment_type ? filterAddress(props.payment_type) : filterAddress("ach");
  data.address1 = selectField(data.allValidations.validations, "address1");
  data.city = selectField(data.allValidations.validations, "city");
  data.state = selectField(data.allValidations.validations, "state");
  data.zipcode = selectField(data.allValidations.validations, "zipcode");

  return (
    <div>
      <Form.Row>
        <Form.Group as={Col} md="12">
          <Form.Label>
            {data.address1.label}:{data.address1.validate.required ? <span className={"color-red"}>*</span> : null}
          </Form.Label>
          <Form.Control
            required={data.address1.validate.required}
            type="text"
            value={props.paymentMethodRed.address1}
            onChange={(e) => {
              props.changeAddressLine(e.target.value, data.allValidations);
              props.checkValidations(e.target.value, data.address1, "address1");
            }}
            isInvalid={props.paymentValidationsReducers.validateAddressLine === false}
            isValid={props.paymentValidationsReducers.validateAddressLine === true}
            disabled={props.disabled}
            pattern={
              props.payment_type == "international"
                ? "^((?!po box|p.o. box|PO BOX|PO Box|po Box|P.O. Box|P.O. BOX).)*$"
                : null
            }
          />
          {props.payment_type == "international" && <p className="text-danger">Do not enter in "PO Box"</p>}
          <Form.Control.Feedback type="valid">{data.address1.validMessage}</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{data.address1.errorMessage}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row>
        {(props.payment_type == "international" &&
          props.paymentMethodRed.pay_by_swift_code &&
          props.paymentMethodRed.currency_code == "USD") ||
        ["can-eft", "nz-eft", "aus-pay-net", "sg-eft"].includes(props.payment_type) ? (
          <PaymentCountryPicker
            {...props}
            selected={props.paymentMethodRed && { value: props.paymentMethodRed.country }}
          />
        ) : null}
        <Form.Group as={Col} md="4">
          <Form.Label>
            {data.city.label}:{data.city.validate.required ? <span className={"color-red"}>*</span> : null}
          </Form.Label>
          <Form.Control
            type="text"
            value={props.paymentMethodRed.city}
            required={data.city.validate.required}
            onChange={(e) => {
              props.changeCity(e.target.value, data.allValidations);
              props.checkValidations(e.target.value, data.city, "city");
            }}
            isInvalid={props.paymentValidationsReducers.validateCity === false}
            isValid={props.paymentValidationsReducers.validateCity === true}
            disabled={props.disabled}
          />
          <Form.Control.Feedback type="valid">{data.city.validMessage}</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{data.city.errorMessage}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="4">
          <StatePicker
            label={data.state.label}
            required={data.state.validate.required}
            countryCode={props.paymentMethodRed.country}
            value={props.paymentMethodRed.state}
            callback={(value) => {
              props.changeState(value, data.allValidations);
              props.checkValidations(value, data.state, "state");
            }}
            errorMessage={data.state.errorMessage}
            validMessage={data.state.validMessage}
            valid={props.paymentValidationsReducers.validateState}
          />
        </Form.Group>

        <Form.Group as={Col} md="4">
          <Form.Label>
            {data.zipcode.label}:{data.zipcode.validate.required ? <span className={"color-red"}>*</span> : null}
          </Form.Label>
          <Form.Control
            type="text"
            value={props.paymentMethodRed.zipcode}
            required={data.zipcode.validate.required}
            onChange={(e) => {
              props.changeZipcode(e.target.value, data.allValidations);
              props.checkValidations(e.target.value, data.zipcode, "zipcode");
            }}
            isInvalid={props.paymentValidationsReducers.validateZipcode === false}
            isValid={props.paymentValidationsReducers.validateZipcode === true}
            disabled={props.disabled}
          />
          <Form.Control.Feedback type="valid">{data.zipcode.validMessage}</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{data.zipcode.errorMessage}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    </div>
  );
};
export default CommonPaymentAddress;
