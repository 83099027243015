import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { PaymentMethodValidatorSvc } from "../paymentMethodValidatorSvc";
import { IDType } from "../../../../services/common/types/common.type";

type TransitNumberPropsType = {
  modelName?: string;
  institutionNumber?: number | string;
  callBack: (arg: IDType) => void;
};

const TransitNumber = ({ modelName, institutionNumber, callBack }: TransitNumberPropsType) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState("");
  const [inputLength, setInputLength] = useState(0);

  const validateTransitionNumber = async (value: IDType) => {
    const stringValue = String(value);
    setInputLength(stringValue.length);
    if (stringValue.length > 4) {
      try {
        const data = await PaymentMethodValidatorSvc.ValidateTransitionNumber(value, institutionNumber);
        if (data?.validation) {
          setValidationMessage(data.validation);
        } else {
          setValidationMessage(data?.errors[0]?.message);
        }
      } catch (error) {
        throw error;
      }

      if (callBack) {
        callBack(value);
      }
    }
  };

  return (
    <>
      <Field
        name={`${modelName}.transite_number`}
        component={RenderField}
        label={t("common.paymentMethod.transitNumber")}
        className={`w-100`}
        required
        maxLength={5}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => validateTransitionNumber(e.target.value)}
      />
      {validationMessage && inputLength === 5 && (
        <p className={validationMessage === "success" ? "successTxtColor" : "errorTxtColor"}>{validationMessage}</p>
      )}
    </>
  );
};

export default TransitNumber;
