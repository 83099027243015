import { Component } from "react";
import { Alert } from "react-bootstrap";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Alert variant="danger" className="d-flex flex-column justify-content-center align-items-center m-5">
          <Alert.Link href="https://accrualify.zendesk.com/hc/en-us/requests/new">
            Oops, Something went wrong please contact <strong>Support.</strong>
          </Alert.Link>
          <p className="mt-3">{this.state.error && JSON.stringify(this.state.error)}</p>
        </Alert>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
