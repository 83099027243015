import React from "react";
import {
  ObjectPropertiesType,
  InvoiceDebitEntriesType,
} from "../../../../../services/admin/invoices/recurringInvoice/recurringInvoiceTypes";
import Panel from "../../../../common/panel/panel";
import { Table } from "react-bootstrap";
import _ from "lodash";
import { formattedAmount } from "../../../../../services/general/helpers";
import { useTypedSelector } from "../../../../../reducers";
import { IUser } from "../../../../../services/common/user/userTypes";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";

type ExpensesPropsType = {
  invoice?: ObjectPropertiesType;
};

const Expenses = ({ invoice }: ExpensesPropsType) => {
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const showDebitEntry = (account_entry: InvoiceDebitEntriesType): boolean =>
    invoiceCommonSvc.showDebitEntry(account_entry);

  return (
    <Panel>
      <Table className="mb-1 text-left" responsive striped bordered>
        <thead>
          <tr>
            <th>Account Type</th>
            <th>Account</th>
            {currentUser?.company?.is_tax_to_invoice_expenses_line && <th>Tax</th>}
            <th>Amount</th>
            <th>Department</th>
            <th>Business Unit</th>
            <th>Location</th>
            <th>memo</th>
          </tr>
        </thead>
        <tbody>
          {(!invoice ||
            !invoice.account_transaction_attributes ||
            !invoice.account_transaction_attributes.debit_entries_attributes ||
            (invoice &&
              invoice.account_transaction_attributes &&
              _.isArray(invoice.account_transaction_attributes.debit_entries_attributes) &&
              invoice.account_transaction_attributes.debit_entries_attributes.length < 1)) && (
            <tr>
              <td colSpan={10} className="text-center">
                There are no expenses.
              </td>
            </tr>
          )}
          {invoice &&
            invoice.account_transaction_attributes &&
            _.isArray(invoice.account_transaction_attributes.debit_entries_attributes) &&
            invoice.account_transaction_attributes.debit_entries_attributes.map(
              (invoice_debit) =>
                showDebitEntry(invoice_debit) && (
                  <tr key={invoice_debit.id}>
                    <td>Debit Account</td>
                    <td>
                      {invoice_debit.account_number ? invoice_debit.account_number + "-" : ""}
                      {invoice_debit.account_name}
                    </td>
                    {currentUser?.company?.is_tax_to_invoice_expenses_line && (
                      <td>{formattedAmount(invoice_debit.tax?.toString(), invoice.currency_code)}</td>
                    )}
                    <td>{formattedAmount(invoice_debit.amount?.toString(), invoice.currency_code)}</td>
                    <td>{invoice_debit.department_name}</td>
                    <td>{invoice_debit.business_unit_name}</td>
                    <td>{invoice_debit.location_name}</td>
                    <td>{invoice_debit.memo}</td>
                  </tr>
                ),
            )}
        </tbody>
      </Table>
    </Panel>
  );
};

export default Expenses;
