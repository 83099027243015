import React from "react";
import InputFilter from "../reportFilters/input";

const UserListing = ({ scheduled }: { scheduled?: boolean }) => {
  return (
    <div>
      <InputFilter name={"Vendor Master ID"} code={"master_id"} options={[]} />
      <InputFilter name={"Min Number Signed in Count"} code={"sign_in_count"} options={[]} />
    </div>
  );
};

export default UserListing;
