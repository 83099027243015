import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../../vendors.module.css";

type VendorInfoType = {
  vendor: any;
  handleEdit: (step: number) => void;
};

const VendorInfo = ({ vendor, handleEdit }: VendorInfoType) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(true);

  return (
    <>
      <Row>
        <Col md="12">
          <span>
            <i className={`icon icon-vendors ${styles.detailPageIconWidth}`}></i>
            <span className={styles.title}>{t("admin.pages.vendor.vendorDetails")}</span>
            <div className="float-right">
              <Button variant="secondary" className={`px-mr-10 ${styles.btnEdit}`} onClick={() => handleEdit(1)}>
                <i className="icon icon-edit px-m-0"></i>
                {t("admin.pages.vendor.edit")}
              </Button>
              <span className={`float-right ${styles.iconToggle}`}>
                <i
                  className={toggle ? "icon icon-arrow-up" : "icon icon-arrow-down"}
                  onClick={() => setToggle(!toggle)}
                ></i>
              </span>
            </div>
          </span>
        </Col>
      </Row>
      {toggle && (
        <>
          <Row className={`px-mt-25 ${styles.vendorInfoSection}`}>
            <Col md="3">
              <label>{t("admin.pages.vendor.vendorDetails")}</label>
              <span>{vendor.name || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.legalName")}</label>
              <span>{vendor.legal_name || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.vendorId")}</label>
              <span>{vendor.vendor_id || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.masterId")}</label>
              <span>{vendor.master_id || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.status")}</label>
              <span>{vendor.status || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.email")}</label>
              <span>{vendor.email || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.website")}</label>
              <span>{vendor.url || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.phone")}</label>
              <span>{vendor.phone || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.vendorType")}</label>
              <span>{vendor.vendor_type || "-"}</span>
            </Col>
            <Col md="4">
              <label>{t("admin.pages.vendor.vendorSubType")}</label>
              <span>{vendor.vendorClassification || "-"}</span>
            </Col>
          </Row>
          <Row className={styles.vendorInfoSection}>
            <Col md="12" className="px-mb-10">
              <p className={styles.subTitle}>{t("admin.pages.vendor.address")}</p>
              <br />
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.addressType")}</label>
              <span>{vendor.address_type || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.attention")}</label>
              <span>{vendor.attention || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.addressLabel")}</label>
              <span>{vendor.label || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.address1")}</label>
              <span>{vendor.address1 || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.city")}</label>
              <span>{vendor.city || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.state")}</label>
              <span>{vendor.state || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.zipCode")}</label>
              <span>{vendor.zipcode || "-"}</span>
            </Col>
            <Col md="3">
              <label>{t("admin.pages.vendor.country")}</label>
              <span>{vendor.country || "-"}</span>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default VendorInfo;
