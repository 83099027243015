import React, { ComponentProps } from "react";
import { change } from "redux-form";
import { RenderCheckBox, RenderField, RenderSelect } from "../../../../../../components/forms/bootstrapFields";
import AccountPicker from "../../../../../../components/pickers/reduxFormPicker/accountPicker";
import { CreateNotification, NotificationType } from "../../../../../../services/general/notifications";
import { maxValue, minValue } from "../../../../../../services/validations/reduxFormValidation";
import { CompanyDefaultsTypes } from "../../settingsTypes";

const AccountPickerCustom = (props: ComponentProps<typeof AccountPicker>) => (
  <AccountPicker {...props} accountGroupName="PO_DEBIT_GROUPS" />
);

export const poRequestSettingHeaderLevel: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.po_request.hide_expense_and_item_lines",
    tooltip: {
      show: true,
      title: "To Hide Item and Expense Line",
      placement: "top",
    },
    label: "Hide Item and Expense Line ",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request.default_expense_line_account_id",
    tooltip: {
      show: true,
      title: "To use default expense account if item and expense line hidden.",
      placement: "top",
    },
    label: "Default Expense Account",
    type: "react-select",
    component: AccountPickerCustom,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.po_request?.hide_expense_and_item_lines;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: true,
    class: "",
    isClearable: true,
  },
  {
    name: "company_default.po_request_shipping_address",
    tooltip: {
      show: true,
      title: "Display Shipping Information at the PO Request Form.",
      placement: "top",
    },
    label: "Shipping Information",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.auto_send_po_link",
    tooltip: {
      show: true,
      title: "PO link will be automatically sent to vendor once PO is APPROVED and moved to an OPEN status.",
      placement: "top",
    },
    label: "PO Link Auto Send",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_auto_closed_end_date",
    tooltip: {
      show: true,
      title: "PO will be auto closed on end date if PO has and end date.",
      placement: "top",
    },
    label: "PO Auto Closed On End Date",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_post_set_number",
    tooltip: {
      show: true,
      title: "PO Number generated after PO created.",
      placement: "top",
    },
    label: "Post PO Number Generated",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.use_user_level_subsidiaries",
    tooltip: {
      show: true,
      title: "We will show only requestor level subsidiaries while Request PO and Edit Request PO.",
      placement: "top",
    },
    label: "Use user level subsidiaries",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_number_custom_seq",
    tooltip: {
      show: true,
      title: "PO Number Custom Sequence Numbering.",
      placement: "top",
    },
    label: "PO Number Custom Sequence",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_number_custom_start_seq",
    tooltip: {
      show: true,
      title: "Starting Number. Once 1st PO is created, contact accountmanagement@corpay.com to reset",
      placement: "top",
    },
    label: "Starting Number",
    type: "text",
    component: RenderField,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.po_number_custom_seq;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_number",
    tooltip: {
      show: true,
      title:
        "PO Format Number: 'PO-%010d', 'PO-%s', 'PO%s' .. respective sample output PO-0000012345, PO-12345, PO12345",
      placement: "top",
    },
    label: "Format Numbering",
    type: "text",
    component: RenderField,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.po_number_custom_seq;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.check_number_custom_seq",
    tooltip: {
      show: true,
      title: "Check Number Custom Sequence Numbering.",
      placement: "top",
    },
    label: "Check Number Custom Sequence",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.check_number_custom_start_seq",
    tooltip: {
      show: true,
      title: "Starting Number. Once 1st Check is created, contact accountmanagement@corpay.com to reset",
      placement: "top",
    },
    label: "Starting Number",
    type: "text",
    component: RenderField,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.check_number_custom_seq;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.allow_change_requestor",
    tooltip: {
      show: true,
      title: "PO Requestor is allowed to change",
      placement: "top",
    },
    label: "Allow To Change PO Requestor",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_is_service_end_date_month",
    tooltip: {
      show: true,
      title: "Service start date will be calculated by using entered month from start date.",
      placement: "top",
    },
    label: "Manage Service End Date by Month",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_service_end_date_month",
    tooltip: {
      show: false,
    },
    label: "Manage Service End Date by Month",
    type: "number",
    component: RenderField,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.po_request_is_service_end_date_month;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [minValue(0), maxValue(1000)],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_auto_set_department",
    tooltip: {
      show: true,
      title: "Department will inherit from user and not changeable for PO Request form.",
      placement: "top",
    },
    label: "Auto Set Department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_vendor_contact",
    tooltip: {
      show: true,
      title: "PO link will be sent to this vendor contact ",
      placement: "top",
    },
    label: "Allow to link vendor contact to PO",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_inherit_location_from_requestor",
    tooltip: {
      show: true,
      title: "Location will be inherited from requestor ",
      placement: "top",
    },
    label: "Inherit location from requestor",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_disabled_header_location",
    tooltip: {
      show: true,
      title: "Hide location field from PO request header level form once selected",
      placement: "top",
    },
    label: "Hide Header Location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_freeze_date",
    tooltip: {
      show: true,
      title: "Date field will be disabled in PO request form",
      placement: "top",
    },
    label: "Disable date field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_readonly_po_number",
    tooltip: {
      show: true,
      title: "Date field will be disabled in PO request form",
      placement: "top",
    },
    label: "Disable PO number field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_department",
    tooltip: {
      show: true,
      title: "Department field at the header level will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide header level department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.department_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.department_required",
    tooltip: {
      show: true,
      title: "Department field at the header level will be required for PO request form",
      placement: "top",
    },
    label: "Department required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.po_request_hide_department;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_show_all_department",
    tooltip: {
      show: true,
      title: "Display all the department in the list once selected",
      placement: "top",
    },
    label: "Show all Department in list",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.show_instruction",
    tooltip: {
      show: true,
      title: "Instruction field will be visible on Request PO once selected",
      placement: "top",
    },
    label: "Show Instructions",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_shipping_address_dropdown",
    tooltip: {
      show: true,
      title: "Shipping address field will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide shipping address",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.po_request?.is_required_shipping_address;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request.is_required_shipping_address",
    tooltip: {
      show: true,
      title: "Shipping address will be required field at the PO Request Form",
      placement: "top",
    },
    label: "Shipping address Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.po_request_shipping_address_dropdown;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_free_form_shipping_address",
    tooltip: {
      show: true,
      title: "Shipping address can be created from PO request form",
      placement: "top",
    },
    label: "Allow to create shipping address",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.purchase_order.hide_billing_address",
    tooltip: {
      show: true,
      title:
        "Billing address field will be hidden from PO request form. Show subsidiary level addresses if Subsidiary is selected on a PO.",
      placement: "top",
    },
    label: " Hide billing address",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_disable_payment_term",
    tooltip: {
      show: true,
      title: "Payment term field will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide payment term",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_expense_reimbursement_option",
    tooltip: {
      show: true,
      title:
        "Expense reimbursement option will be hidden from 'My Purchase Order Is Going To Be For' dropdown of PO request form",
      placement: "top",
    },
    label: "Hide expense reimbursement option",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_corporate_card_option",
    tooltip: {
      show: true,
      title:
        "Corporate card option will be hidden from 'My Purchase Order Is Going To Be For' dropdown of PO request form",
      placement: "top",
    },
    label: "Hide corporate card option",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_currency_code",
    tooltip: {
      show: true,
      title: "Currency code will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide currency code",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_po_type",
    tooltip: {
      show: true,
      title: "PO type field will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide PO type field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_start_date",
    tooltip: {
      show: true,
      title: "Start date field will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide start date field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_end_date",
    tooltip: {
      show: true,
      title: "End date field will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide end date field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_add_vendor",

    tooltip: {
      show: true,
      title: "Add vendor button will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide add vendor button",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_request_by",
    tooltip: {
      show: true,
      title: "Receive by field will be hidden from PO request form",
      placement: "top",
    },
    label: " Hide Receive by field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_budgets",
    tooltip: {
      show: true,
      title: "Budget field will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide budget field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return isShowObj?.company_default?.po_request_budget_required ? false : true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.po_request_budget_required", false));
    },
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_header_description",
    tooltip: {
      show: true,
      title: "Description field will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide header level description field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.po_request_is_optional_description", false));
    },
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_is_optional_description",
    tooltip: {
      show: true,
      title: "Description will be an optional field on Request as well as admin PO once selected.",
      placement: "top",
    },
    label: "Make description field optional",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.po_request_hide_header_description;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_custom_fields_show",
    tooltip: {
      show: true,
      title: "Show custom fields",
      placement: "top",
    },
    label: "Show custom fields",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_default_status",
    tooltip: {
      show: true,
      title: "Default status will consider for Request PO if there is no one workflow step active for Purchase Order.",
      placement: "top",
    },
    label: "Default Status if no workflow step active",
    type: "select",
    component: RenderSelect,
    options: [
      { label: "--- Select ---", value: "" },
      { label: "PENDING", value: "PENDING" },
      { label: "APPROVED", value: "APPROVED" },
      { label: "OPEN", value: "OPEN" },
    ],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_w9_form_required",
    tooltip: {
      show: true,
      title: "W9 form will be required at the time of vendor create. ",
      placement: "top",
    },
    label: "W9 Form Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_start_end_date_required",
    tooltip: {
      show: true,
      title: "Start date and End date will be required field at the PO Request Form",
      placement: "top",
    },
    label: "Start & End Date Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !(company_default.po_request_hide_start_date && company_default.po_request_hide_end_date);
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_budget_required",
    tooltip: {
      show: true,
      title: "Budget will be required field at the PO Request Form",
      placement: "top",
    },
    label: "Budget Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return isShowObj?.company_default?.po_request_hide_budgets ? false : true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { dispatch, formName } = onChangeObj;
      dispatch(change(formName, "company_default.po_request_hide_budgets", false));
    },
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_is_location_required",
    tooltip: {
      show: true,
      title: "Header level location will be required field at the PO Request Form",
      placement: "top",
    },
    label: "Location Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_tax_id_required",
    tooltip: {
      show: true,
      title: "Tax ID will be required field at the time of vendor create",
      placement: "top",
    },
    label: "Tax ID Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_gl_account_required",
    tooltip: {
      show: true,
      title: "GL Account will be required field at the PO Request Form",
      placement: "top",
    },
    label: "GL Account Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_required_additional_details",
    tooltip: {
      show: true,
      title: "Additional Details will be required field on PO request form",
      placement: "top",
    },
    label: "Additional Details required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.show_product_item_po_header_level",
    tooltip: {
      show: true,
      title: "Header level Item and propagation to the detail line item level, while po linking to invoice",
      placement: "top",
    },
    label: "Show Product Item On Header Level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_allow_inventory_item_for_inventory_type",
    tooltip: {
      show: true,
      title:
        "Allowing only Inventory Item to search if PO Sub Type is Inventory from PO Request as well as Admin section",
      placement: "top",
    },
    label: "Allow Inventory item to subtype Invt..",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_allow_location_project_shipping_address",
    tooltip: {
      show: true,
      title: "Allow to select only Location or Project level shipping address",
      placement: "top",
    },
    label: "Allow Location/Project level address",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_data_restrict_by_subsidiary",
    tooltip: {
      show: true,
      title: "User able to search only those Item, Location, BU and Department whose belong to selected subsidiary.",
      placement: "top",
    },
    label: "Allow Item, BU... from subsidiary",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request.inherit_project_from_requestor",
    tooltip: {
      show: true,
      title: "Project field will be inherit from requestor while creating new PO.",
      placement: "top",
    },
    label: "Inherit project from requestor",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request.leave_vendor_requestor_blank",
    tooltip: {
      show: true,
      title:
        "Checking this box will PREVENT staff-level users that request a PO they added a New Vendor for from seeing POs, Payments, and Invoices associated with that New Vendor. The Vendor Requestor/Account Manager field will be left blank.",
      placement: "top",
    },
    label: "Leave Vendor Requestor blank",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.inherit_po_header_department_to_vcard_header",
    tooltip: {
      show: true,
      title: "Inherit header level department to Card header level",
      placement: "top",
    },
    label: " Inherit header level department to Card",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.inherit_po_header_description_to_vcard_header",
    tooltip: {
      show: true,
      title: "Inherit header level description to Card header level",
      placement: "top",
    },
    label: "Inherit header level description to Card",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request.auto_select_shipping_address",
    tooltip: {
      show: true,
      title: "Auto select shipping address while requesting Purchase Order.",
      placement: "top",
    },
    label: "Auto select shipping address",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
];

export const poRequestSettingItemLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.allow_create_new_item",
    tooltip: {
      show: true,
      title: "Users can create new PO Items in the PO Request form if selected",
      placement: "top",
    },
    label: "Allow creating new PO Items",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_po_item_account",
    tooltip: {
      show: true,
      title: "PO item line account field will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide po item line account",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_additional_detail",
    tooltip: {
      show: true,
      title: "PO item line level additional details field will be hidden from PO request form",
      placement: "top",
    },
    label: "Hide Additional details field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_department_required_line_item_level",
    tooltip: {
      show: true,
      title: "Department will be the required field at line item level",
      placement: "top",
    },
    label: "Department required line item level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_display_name_new_item",
    tooltip: {
      show: true,
      title: "Display name field will be hidden while adding new item from PO request form",
      placement: "top",
    },
    label: "Hide display name field from new item section",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_description_new_item",
    tooltip: {
      show: true,
      title: "Description field will be hidden while adding new item from PO request form",
      placement: "top",
    },
    label: "Hide description field from new item section",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_item_type_new_item",
    tooltip: {
      show: true,
      title: "Item type field will be hidden while adding new item from PO request form",
      placement: "top",
    },
    label: "Hide item type field from new item section",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_expense_account_new_item",
    tooltip: {
      show: true,
      title: "Assets/Expense account field will be hidden while adding new item from PO request form",
      placement: "top",
    },
    label: "Hide assets/expense account field from new item section",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
];

export const poRequestSettingExpenseLine: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.po_request_allow_expenses",
    tooltip: {
      show: true,
      title: "Allow to add Expenses line on PO request form if selected",
      placement: "top",
    },
    label: "Allow Expenses Line",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { company_default, dispatch, formName } = onChangeObj;
      if (company_default.po_request_hide_po_items && !company_default.po_request_allow_expenses) {
        CreateNotification(
          "Warning",
          "PO Require items or expense or both. Please select one of them.",
          NotificationType.warning,
        );
        dispatch(change(formName, "company_default.po_request_allow_expenses", true));
      }
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_expense_line_department",
    tooltip: {
      show: true,
      title: "Hide department field from PO request expense line level once selected",
      placement: "top",
    },
    label: "Hide expense line department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.po_request.expenses.department.is_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request.expenses.department.is_required",
    tooltip: {
      show: true,
      title: "Department will be required field at PO request expense line level.",
      placement: "top",
    },
    label: "Make department required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !company_default.po_request_hide_expense_line_department;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_expense_line_memo",
    tooltip: {
      show: true,
      title: "Hide memo from PO request expense line level once selected",
      placement: "top",
    },
    label: "Hide expense line memo",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },

  {
    name: "company_default.po_request.expenses.category.is_required",
    tooltip: {
      show: true,
      title: "Category will be required field at Expense line on the PO Request Form",
      placement: "top",
    },
    label: "Category required on expense line level",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.po_request_hide_expense_line_location",
    tooltip: {
      show: true,
      title: "Hide location field from PO request expense line level once selected",
      placement: "top",
    },
    label: "Hide expense line location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
];
export const poRequestSettingApproval = [];
