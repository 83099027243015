import { AxiosResponse } from "axios";
import { IDType } from "services/common/types/common.type";
import { restApiService } from "../../../providers/restApi";
import { CategoryTypes } from "./CategoryTypes";

class CategoryApis {
  static async getCategory(id: IDType) {
    try {
      const response: AxiosResponse<CategoryTypes.Category> = await restApiService.get("categories/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default CategoryApis;
