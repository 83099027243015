import React, { useCallback, useEffect, useState } from "react";
import styles from "./dashboard.module.css";
import Panel from "components/common/panel/panel";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { useSelector } from "react-redux";
import { getFormValues } from "redux-form";
import { restApiService } from "providers/restApi";
import { useTranslation } from "react-i18next";

type formType = { subsidiary_id?: number; expense_type?: { value: string; label: string }; subsidiaryName?: string };

type filterType = { subsidiary_ids?: number; reimbursable?: string };
interface ExpenseData {
  title: string;
  count: number | string;
  icon: string;
  link?: string;
  href?: string;
  param: string;
}

const ExpenseSummary = () => {
  const { t } = useTranslation();
  const [actionData, setActionData] = useState<ExpenseData[] | []>([]);
  const expenseDashboardForm: formType = useSelector((state) => getFormValues("expenseDashboardForm")(state));
  const subsidiaryId = expenseDashboardForm?.subsidiary_id;
  const expenseType = expenseDashboardForm?.expense_type;
  const subsidiaryName = expenseDashboardForm?.subsidiaryName;
  const subsidiary_id = expenseDashboardForm?.subsidiary_id;
  const getList = useCallback(
    async (subsidiaryId = null, expenseType = null) => {
      try {
        let filters: filterType = {};
        if (subsidiaryId) {
          filters.subsidiary_ids = subsidiaryId;
        }
        if (expenseType?.value && expenseType.value !== "all") {
          filters.reimbursable = expenseType.value;
        }
        const result = await ExpensesApis.getExpensesActionRequired({ filter: filters });
        if (result) {
          const data: ExpenseData[] = [
            {
              title: t("admin.pages.expenseItem.labels.unsubmittedItems"),
              count: result?.unsubmitted_expense_item_count || 0,
              icon: "icon-unpin",
              link: "/ap/expenses",
              param: "unSubmitted",
            },
            {
              title: t("admin.pages.expenseItem.labels.itemswithPolicyViolations"),
              count: result?.expense_items_with_violations_count || 0,
              icon: "icon-blue-violation",
              link: "/ap/expenses",
              param: "withPolicyViolation",
            },
            {
              title: t("admin.pages.expenseItem.labels.unsubmittedReports"),
              count: result?.unsubmitted_expense_report_count || 0,
              link: "/ap/expense_reports",
              icon: "icon-expenses-approval-blue",
              param: "unSubmitted",
            },
            {
              title: t("admin.pages.expenseItem.labels.expenseReportRequiringyourApproval"),
              count: result?.expense_report_pending_count || 0,
              href: "expense_approvals",
              icon: "icon-expense-req-approve",
              param: "requireApproval",
            },
          ];
          setActionData(data);
        }
      } catch (error) {
        console.error("Error fetching expenses action required:", error);
        // Handle the error appropriately, e.g., show an error message to the user.
      }
    },
    [subsidiaryId, expenseType],
  );

  useEffect(() => {
    if (subsidiaryId || expenseType) {
      getList(subsidiaryId, expenseType);
    }
  }, [subsidiaryId, expenseType]);

  const RowActionItems = ({ data, index }: { data: ExpenseData; index: number }) => {
    return (
      <Row key={index} className={`${styles.actionTitle} ${styles.expenseSummaryItems}`}>
        <Col sm={9} className={`row`}>
          <i className={`icon ${data.icon} ${styles.syncBtnIcon} ${styles.syncBtn}`}></i>
          <p className={`${styles.selectCreditLineLabel}`}>{data.title}</p>
        </Col>
        <Col sm={3}>
          <span
            className={`mb-0 ml-auto badge  text-light actionableItemsbadgePill ${styles.badgeBlue} ${styles.countAlign}`}
          >
            {data.count}
          </span>
        </Col>
      </Row>
    );
  };

  const LinkActionItem = ({ data, index }: { data: ExpenseData; index: number }) => {
    const subsidiaryFilter = subsidiaryName ? "subsidiaryName=" + subsidiaryName : "";
    const expenseTypeFilter = expenseType?.value ? "&expenseType=" + expenseType?.value : "";
    const statusFilter = data?.param ? "&status=" + data.param : "";
    const searchQuery = `?${subsidiaryFilter}${expenseTypeFilter}${statusFilter}`;
    return (
      <Link className={`expenseRouteLink`} key={`${index}`} to={{ pathname: `${data.link}`, search: `${searchQuery}` }}>
        <RowActionItems key={`${index}`} data={data} index={index} />
      </Link>
    );
  };
  const AnchorActionItem = ({ data, index }: { data: ExpenseData; index: number }) => {
    const subsidiaryIdFilter = subsidiary_id ? "&subsidiaryId=" + subsidiary_id : "";
    const expenseTypeFilter = expenseType?.value ? "&expenseType=" + expenseType?.value : "";
    const filterId = `&filterID=clear`;
    const searchQuery = `?${subsidiaryIdFilter}${expenseTypeFilter}${filterId}`;
    return (
      <a
        key={`${index}`}
        id="exp_report_approval_link"
        href={restApiService.angularBaseURL() + `${data.href}?${searchQuery}`}
        className={`expenseRouteLink`}
      >
        <RowActionItems key={`${index}`} data={data} index={index} />
      </a>
    );
  };

  return (
    <div>
      <Panel
        cardClass={styles.panelWrapper}
        cardBodyClass={styles.expenseSummaryBody}
        cardFooterClass={styles.panelFooter}
        header={
          <div>
            <i className={`icon ${styles.iconHeading} icon-todo px-mt-3 px-mr-3`}></i>
            {t("admin.pages.expenseItem.labels.actionRequired")}
          </div>
        }
        cardHeaderClass={styles.expensePanelHeader}
      >
        <>
          {actionData.map((data, index) => {
            return data?.link ? (
              <LinkActionItem key={`${index}`} data={data} index={index} />
            ) : (
              <AnchorActionItem key={`${index}`} data={data} index={index} />
            );
          })}
        </>
      </Panel>
    </div>
  );
};

export default ExpenseSummary;
