import React from "react";
import ListDataExtracts from "../../../../../components/admin/reports/dataExtracts/listDataExtracts";
import { DataExtractModuleTypes } from "services/admin/reports/reportTypes";
import { Can } from "services/authorization/authorization";
import { useLocation } from "react-router";
import { requiresPermissionsAndIa } from "components/navigation/tabNavigation";

const List = (module?: DataExtractModuleTypes, authorization?: any) => {
  const currentPath = useLocation().pathname;

  if (authorization) {
    return (
      <Can
        key={currentPath}
        I={authorization.I}
        a={authorization.a}
        permissions={authorization.permissions}
        requirePermissionsAndIa={requiresPermissionsAndIa(currentPath)}
      >
        <ListDataExtracts module={module} />
      </Can>
    );
  } else {
    return <ListDataExtracts module={module} />;
  }
};
export default List;
