import { Add } from "wombatifier/pages/add";
import { Edit } from "wombatifier/pages/edit";
import { List } from "wombatifier/pages/list";

const wombatRoutes = [
  {
    path: "/ap/wombatifier",
    exact: true,
    component: List,
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/wombatifier/add",
    exact: true,
    component: Add,
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
  {
    path: "/ap/wombatifier/:id/edit",
    exact: true,
    component: Edit,
    authorization: {
      I: "import",
      a: "BulkOperations",
    },
  },
];

export default wombatRoutes;
