import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import StepsBar from "./stepsBar";
import style from "./dashboard.module.css";
import { useDispatch } from "react-redux";
import {
  getSetupProgress,
  setVendorDocumentLinks,
  setVendorGuideStep,
} from "../../../../reducers/vp/vendorSetUpGuideReducer";
import { useTypedSelector } from "../../../../reducers";
import { IPurchaser } from "../../../../services/vp/types/purchasers.types";
import _ from "lodash";
import { Mandatory } from "../../../forms/bootstrapFields";
import { restApiService } from "../../../../providers/restApi";
import { vendorDocumentRequirementType } from "../../../../services/vp/types/documents.type";
import { useTranslation } from "react-i18next";
import { CompanyDetailType } from "../../../../services/common/company/companyTypes";
import { getCompanyDetail } from "../../../../services/common/company/companySvc";
import LoadingBox from "../../../common/loaders/loadingBox";
import VendorDocumentUploadModal from "../../../common/vendorDocumentUploadModal/vendorDocumentUploadModal";
import { DocumentTypes } from "../../../../services/common/documents/documentTypes";
import documentService from "../../../../services/common/documents/documentSvc";
import vendorDocumentLinkService from "../../../../services/common/documentVendorLinks/vendorDocumentLinkSvc";
import { CreateNotification, NotificationType } from "../../../../services/general/notifications";
import VendorDocumentPreview from "../../../common/vendorDocumentPreview/vendorDocumentPreview";

type docReqWithVendorDocumentLinkType = {
  purchaser: IPurchaser;
  documentLink: vendorDocumentRequirementType | undefined;
  company_id: number;
  created_at: string;
  document_type: string;
  documents: any[];
  id: number;
  required: boolean;
  updated_at: string;
};

const DocumentButton = ({
  children,
  required,
  onClickCallBack,
}: {
  children: ReactNode;
  required?: boolean;
  onClickCallBack: () => void;
}) => {
  return (
    <div role="button" onClick={() => onClickCallBack()} className={style.documentButton}>
      {children} <Mandatory required={required} />
    </div>
  );
};

const DocumentRequireStep3 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const documentRequirementCompleted: boolean = useTypedSelector(
    (state) => state.vendorSetupGuideRed.setupProgress.required_documents_completed,
  );
  const purchasers: IPurchaser[] = useTypedSelector((state) => state.vendorSetupGuideRed.purchasers);
  const vendorDocumentLinks: vendorDocumentRequirementType[] = useTypedSelector(
    (state) => state.vendorSetupGuideRed.vendorDocumentLinks,
  );
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<CompanyDetailType>();

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [modalDocumentRequirement, setModalDocumentRequirement] = useState<docReqWithVendorDocumentLinkType>();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const modalPurchaser = modalDocumentRequirement?.purchaser ?? null;
  const modalDocumentLink = modalDocumentRequirement?.documentLink ?? null;

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);
  };

  // filtering those purchasers having doucment requirements
  // taking out only document requirements
  const docReqWithVendorDocumentLink = useMemo(() => {
    return purchasers.flatMap((purchaser) => {
      if (Array.isArray(purchaser.document_requirements)) {
        return purchaser.document_requirements
          .map((documentRequirement) => {
            const documentLink = vendorDocumentLinks.find(
              (link) => link.document_requirement_id === documentRequirement.id,
            );
            return {
              ...documentRequirement,
              documentLink,
            };
          })
          .filter(Boolean)
          .map((documentRequirement) => ({
            ...documentRequirement,
            purchaser,
          }));
      }

      return [];
    });
  }, [purchasers, vendorDocumentLinks]);

  const docRequiredLinked = docReqWithVendorDocumentLink.filter((docReq) => docReq?.required && docReq?.documentLink);

  const docRequiredNotLinked = docReqWithVendorDocumentLink.filter(
    (docReq) => docReq?.required && !docReq?.documentLink,
  );

  const docNotRequiredLinked = docReqWithVendorDocumentLink.filter(
    (docReq) => !docReq?.required && docReq?.documentLink,
  );

  const docNotRequiredNotLinked = docReqWithVendorDocumentLink.filter(
    (docReq) => !docReq?.required && !docReq?.documentLink,
  );

  const toStep4 = () => {
    dispatch(setVendorGuideStep(4));
  };

  const checkDocumentUploadRequired = () => {
    if (documentRequirementCompleted) {
      // if doucment requirement comopleted move to next step
      toStep4();
    }
  };

  const onSkipUploadingAndNext = () => {
    toStep4();
  };

  const openPreviewModal = () => {
    setShowPreviewModal((prev) => true);
  };
  const closePreviewModal = () => {
    setShowPreviewModal((prev) => false);
  };

  const onDocumentRequirmentClick = async (documentRequirement: docReqWithVendorDocumentLinkType) => {
    setModalDocumentRequirement(documentRequirement);
    toggleUploadModal();
  };

  const onLinkedDocumentRequirementClick = async (documentRequirement: docReqWithVendorDocumentLinkType) => {
    setModalDocumentRequirement(documentRequirement);
    openPreviewModal();
  };

  const getGuideSetupProgress = async () => {
    dispatch(getSetupProgress());
  };

  // Fetchings
  const fetchAllDocumentLinks = async () => {
    try {
      const response = await restApiService.get("vendor_document_links");
      dispatch(setVendorDocumentLinks(response.data));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchCompany = async () => {
    try {
      const response = await getCompanyDetail();
      setCompany(response.data);
    } catch (error) {
      setLoading(false);
    }
  };

  const intiDocumentRequireStep = async () => {
    setLoading(true);
    await fetchCompany();
    await fetchAllDocumentLinks();
    setLoading(false);
    checkDocumentUploadRequired();
  };

  const onCloseUploadModal = () => {
    setUploadModalOpen(false);
  };

  const onSubmitDocumentUploadModal = async (data: {
    file?: File | undefined;
    w8PageData?: string | undefined;
    w8BenPageData?: string | undefined;
    w9PageData?: string | undefined;
    diversityFormData?: Record<string, any> | undefined;
    signReference?: string;
    signatureValue?: string;
  }) => {
    try {
      setIsSubmitting(true);
      if (company?.id) {
        let document: DocumentTypes.AddResponse | undefined = await documentService.onSubmitDocumentUploadModal({
          ...data,
          documentableId: company.id,
          documentableType: "Company",
        });

        if (document && document.id && modalPurchaser?.vendor_detail?.id && modalDocumentRequirement) {
          await vendorDocumentLinkService.linkDocumentWithVendorDocumentRequirement(
            {
              document,
              documentRequirement: modalDocumentRequirement,
              vendorId: modalPurchaser.vendor_detail.id,
            },
            "vendor_document_links",
          );

          await fetchAllDocumentLinks();
          await getGuideSetupProgress();
        }
        onCloseUploadModal();
        setIsSubmitting(false);
      }
    } catch (error) {
      let errorObj: any = error;
      if (_.isArray(errorObj.response?.data["assets.asset_file"])) {
        CreateNotification("Error", errorObj.response?.data["assets.asset_file"].toString(), NotificationType.danger);
      } else {
        CreateNotification("Error", "Document not uploaded", NotificationType.danger);
      }
      setIsSubmitting(false);
      onCloseUploadModal();
    }
  };

  useEffect(() => {
    intiDocumentRequireStep();
  }, []);

  return (
    <LoadingBox isLoading={loading}>
      {uploadModalOpen && (
        <VendorDocumentUploadModal
          showUploadModal={uploadModalOpen}
          closeUploadModal={onCloseUploadModal}
          documentRequirement={modalDocumentRequirement}
          submitCallback={onSubmitDocumentUploadModal}
          isSubmitting={isSubmitting}
          showTemplateSection
        />
      )}

      {showPreviewModal && modalDocumentLink && (
        <VendorDocumentPreview onHide={closePreviewModal} vendorDocumentLink={modalDocumentLink} />
      )}

      <Row>
        <Col>
          <Row>
            <Col className="d-flex flex-column justify-content-center align-items-center px-5 mb-4">
              <Row>
                <Col className="my-4">
                  <StepsBar step1 step2 step3 />
                </Col>
              </Row>
              <Row>
                <h3>{t("vp.dashboard.vendorGuide.requiredDocuments")}</h3>
              </Row>
              <Row className="my-3 mx-0">
                <p>{t("vp.dashboard.vendorGuide.requiredDocumentText")}</p>
              </Row>
            </Col>
          </Row>

          <Row className="mb-4">
            {/* Not linked side */}
            <Col className="m-0 px-5">
              <Row className="my-3">
                <Col>{t("vp.dashboard.vendorGuide.requiredDocuments")}</Col>
              </Row>
              <Row>
                {/* doc required by vendor */}
                {_.isArray(docRequiredNotLinked) &&
                  docRequiredNotLinked.map((documentRequirement) => {
                    return (
                      <Col md="6" key={documentRequirement.id}>
                        <DocumentButton required onClickCallBack={() => onDocumentRequirmentClick(documentRequirement)}>
                          {documentRequirement.document_type}
                        </DocumentButton>
                      </Col>
                    );
                  })}
              </Row>

              <Row className="my-3">
                <Col>{t("vp.dashboard.vendorGuide.otherDocuments")}</Col>
              </Row>
              <Row>
                {_.isArray(docNotRequiredNotLinked) &&
                  docNotRequiredNotLinked.map((documentRequirement) => {
                    return (
                      <Col md="6" key={documentRequirement.id}>
                        <DocumentButton onClickCallBack={() => onDocumentRequirmentClick(documentRequirement)}>
                          {documentRequirement.document_type}
                        </DocumentButton>
                      </Col>
                    );
                  })}
              </Row>
            </Col>

            <span className={style.verticalSeparator}></span>

            {/* Linked side */}
            <Col className="m-0 px-5">
              <Row className="my-3">
                <Col>{t("vp.dashboard.vendorGuide.uploadedDocument")}</Col>
              </Row>
              <Row>
                {_.isArray(docRequiredLinked) &&
                  docRequiredLinked.map((documentRequirement) => {
                    return (
                      <Col md="12" key={documentRequirement.id}>
                        <DocumentButton onClickCallBack={() => onLinkedDocumentRequirementClick(documentRequirement)}>
                          {documentRequirement.document_type} - {documentRequirement?.documentLink?.document?.name}
                        </DocumentButton>
                      </Col>
                    );
                  })}
              </Row>

              <Row>
                {_.isArray(docNotRequiredLinked) &&
                  docNotRequiredLinked.map((documentRequirement) => {
                    return (
                      <Col md="12" key={documentRequirement.id}>
                        <DocumentButton onClickCallBack={() => onLinkedDocumentRequirementClick(documentRequirement)}>
                          {documentRequirement.document_type} - {documentRequirement?.documentLink?.document?.name}
                        </DocumentButton>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex flex-column justify-content-center align-items-center px-5">
              {!documentRequirementCompleted && (
                <Row className="mt-3">
                  <span role="button" className={style.underline} onClick={() => onSkipUploadingAndNext()}>
                    {t("vp.dashboard.vendorGuide.skipAllRequiredDoc")}
                  </span>
                </Row>
              )}

              {documentRequirementCompleted && (
                <Row className="mt-3">
                  <Button onClick={() => toStep4()}>{t("next")}</Button>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </LoadingBox>
  );
};

export default DocumentRequireStep3;
