import React from "react";
import { Col, Row } from "react-bootstrap";
import { RootState, useTypedSelector } from "reducers";
import { Field } from "redux-form";
import { WombatSvc } from "wombatifier/services/wombatSvc";
import { WombatInput } from "../pickers/wombatInput";
export const FromHeader = ({ index, handleDelete }: { index: number; handleDelete: (index: number) => void }) => {
  const chosenWorkflowType = useTypedSelector((state: RootState) => WombatSvc.getWombatWorkflowType(state));
  const dropdownField = WombatSvc.getDropdownField(chosenWorkflowType, true);
  return (
    <Row className="m-0 w-100 pr-2" id={WombatSvc.HEADER_ID}>
      <Col className="px-0">
        <Field
          name={`form.config.wombat_fields[${index}].${dropdownField}`}
          component={WombatInput}
          className="m-0 p-0 w-100 h-100"
          inputClassName="p-0 pl-3 m-0 w-100 font-weight-bold border-0 text-center"
          numberOfLines={2}
        />
      </Col>
      <Col className="px-0 pl-1 d-flex align-items-center" style={{ flex: 0, maxWidth: "fit-content" }}>
        <button
          type="button"
          className="actionButtons delete m-0"
          data-toggle="tooltip"
          title="Delete"
          onClick={() => handleDelete(index)}
        />
      </Col>
    </Row>
  );
};
