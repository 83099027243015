import RcnPicker from "../../../../../../components/admin/pickers/reduxFormPickers/rcnPicker";
import { RenderCheckBox, RenderField, RenderSelect } from "../../../../../../components/forms/bootstrapFields";
import { CompanyDefaultsTypes } from "../../settingsTypes";

const vCardSettings: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.vcard_default_rcn_account",
    tooltip: {
      show: true,
      title: "Default RCN account.",
      placement: "top",
    },
    label: "Default RCN Account",
    type: "select",
    component: RcnPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.receipt_required_threshold",
    tooltip: {
      show: true,
      title: "Require a receipt for Card transactions above this threshold.",
      placement: "top",
    },
    label: "Require a receipt for Card transactions above amount",
    type: "number",
    component: RenderField,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vcard_default_billing_end_date",
    tooltip: {
      show: true,
      title: "Billing Cycle End Date",
      placement: "top",
    },
    label: "Billing Cycle End Date",
    type: "select",
    component: RenderSelect,
    options: [
      { label: "1st of each mont", value: "first" },
      { label: "15th of each month", value: "fifteenth" },
      { label: "End of month", value: "end" },
      { label: "Custom", value: "custom" },
    ],
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vcard_custom_billing_end_day",
    tooltip: {
      show: false,
    },
    label: "",
    type: "number",
    component: RenderField,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.vcard_default_billing_end_date === "custom";
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.vcard_non_po_limit",
    tooltip: {
      show: true,
      title: "Sets a limit in dollars to the amount of funds user can ask for in a Non-PO corporate card request.",
      placement: "top",
    },
    label: "Maximum requestable amount without purchase order",
    type: "number",
    component: RenderField,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.hide_categories",
    tooltip: {
      show: true,
      title: "Hide categories from Corporate Cards",
      placement: "top",
    },
    label: "Hide Categories",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.vcard_deduct_amount_from_po",
    tooltip: {
      show: true,
      title:
        "Deduct Amount Issued from PO Balance. If unchecked, the PO balance will not be affected by the amount issued to an associated corporate card.",
      placement: "top",
    },
    label: "Deduct Amount Issued from PO Balance",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.require_location",
    tooltip: {
      show: true,
      title: "Require Location when requesting or adding a V-Card",
      placement: "top",
    },
    label: "Require Location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.require_department",
    tooltip: {
      show: true,
      title: "Require Department when requesting or adding a V-Card",
      placement: "top",
    },
    label: "Require Department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.require_business_unit",
    tooltip: {
      show: true,
      title: "Require Business Unit when requesting or adding a V-Card",
      placement: "top",
    },
    label: "Require Business Unit",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.require_project",
    tooltip: {
      show: true,
      title: "Require Project when requesting or adding a V-Card",
      placement: "top",
    },
    label: "Require Project",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.show_card_numbers_for_admins",
    tooltip: {
      show: true,
      title: "Show the full card numbers for Card Admins",
      placement: "top",
    },
    label: "Show Card Numbers for Card Admins",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.show_billing_address",
    tooltip: {
      show: true,
      title: "Enable ability to edit card billing address for Card Admins",
      placement: "top",
    },
    label: "Enable billing address for Card Admins",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  // {
  //   name: "company_default.virtual_card.show_card_admins_card_payment_tab",
  //   tooltip: {
  //     show: true,
  //     title:
  //       "When activated, Card Admins will be able to add account information and pay card statements directly through the Payments tab in the Cards module.",
  //     placement: "top",
  //   },
  //   label: "Show Card Payments tab for Card Admins",
  //   type: "checkbox",
  //   component: RenderCheckBox,
  //   isShow: (isShowObj) => {
  //     const { company } = isShowObj;
  //     return !company.is_prefund_card_customer;
  //   },
  //   onChangeCallBack: (onChangeObj) => {},
  //   col: 1,
  //   validation: [],
  //   require: undefined,
  //   class: "",
  // },
  {
    name: "company_default.virtual_card.allow_edits_to_purchase_accounts_by_staff",
    tooltip: {
      show: true,
      title:
        "When enabled, staff-level card users will be able to edit the GL coding on their card purchases while the purchase is still in Pending status.",
      placement: "top",
    },
    label: "Allow staff users to edit GL coding on card purchases",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.virtual_card.mcc_defaults_for_purchases",
    tooltip: {
      show: true,
      title:
        "When enabled, GL Accounts associated with Categories mapped in the MCC Defaults page will be automatically assigned to card purchases within that MCC group.",
      placement: "top",
    },
    label: "Use MCC Default Coding for Card Purchases",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
];

export default vCardSettings;
