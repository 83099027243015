import React, { useEffect, useState } from "react";
import RestApi from "../../../../providers/restApi";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { formattedAmount } from "../../../../services/general/helpers";
import useIsMounted from "../../../common/hooks/useIsMounted";

const restApiService: RestApi = new RestApi();
interface IPayments {
  currency_code: string;
  amount: number;
  invoices: number;
  status: string;
  payment_type: string;
  payment_number: number;
  payment_date: string;
  id: number;
}

type propsType = {
  filter: any;
};

const PaymentTable = ({ filter }: propsType) => {
  const [payments, setPayments] = useState<Array<IPayments>>([]);
  const isMounted = useIsMounted();

  const fetchPayments = async () => {
    try {
      const response = await restApiService.get("vendor_payments", filter);
      if (isMounted) {
        setPayments(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted) {
      fetchPayments();
    }
  }, []);

  return (
    <div>
      {payments ? (
        <Table striped bordered hover className="mb-0">
          <thead>
            <tr>
              <th className="primaryFontColor">Payment Number</th>
              <th className="primaryFontColor">Payment Type</th>
              <th className="primaryFontColor">Status</th>
              <th className="primaryFontColor">Invoice Number</th>
              <th className="primaryFontColor">Date</th>
              <th className="primaryFontColor">Amount</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => {
              return (
                <tr key={payment.id}>
                  <td>
                    <Link
                      className="primaryLinkColor"
                      to={"/ar/payments/" + payment.id}
                      data-toggle="tooltip"
                      title="Payment"
                    >
                      {payment.payment_number}
                    </Link>
                  </td>
                  <td>{payment.payment_type}</td>
                  <td>{payment.status}</td>
                  <td>{payment.invoices}</td>
                  <td>{payment.payment_date}</td>
                  <td>{formattedAmount(payment.amount?.toString(), payment.currency_code)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <h5 className="text-center mt-3 mb-3">Loading...</h5>
      )}
    </div>
  );
};

export default PaymentTable;
