import EventEmitter from "eventemitter3";
declare global {
  interface Window {
    axiosProceed: boolean;
  }
}

export const APP_EMITTER: EventEmitter = new EventEmitter();

export const APP_EVENTS: { [key: string]: string } = {
  AXIOS_RESOLVE: "AXIOS_RESOLVE",
};

export const PAUSE_AXIOS = () => {
  window.axiosProceed = false;
};

export const UNPAUSE_AXIOS = () => {
  window.axiosProceed = true;
};

export const AXIOS_PROCEED = () => window.axiosProceed; // we make this a function so that we are always getting current global value
