import { RenderCheckBox } from "../../../../../../components/forms/bootstrapFields";
import AccountPicker from "../../../../../../components/pickers/reduxFormPicker/accountPicker";
import TaxSchedulePicker from "../../../../../../components/pickers/reduxFormPicker/taxSchedulePicker/taxSchedulePicker";
import { CompanyDefaultsTypes } from "../../settingsTypes";

const ProductItemSettingData: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.product_item_optional_tax_schedule",
    tooltip: {
      show: true,
      title: "Tax schedule will be an optional field for product item",
      placement: "top",
    },
    label: "Tax schedule as an optional field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.product_item.default_tax_schedule",
    tooltip: {
      show: false,
    },
    label: "Default Tax schedule for Product Item:",
    type: "select",
    component: TaxSchedulePicker,
    isShow: (isShowObj) => {
      return true;
    },
    isClearable: true,
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.product_item_optional_subsidiary",
    tooltip: {
      show: true,
      title: "Subsidiary will be an optional field for product item",
      placement: "top",
    },
    label: "Subsidiary as an optional field",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.default_product_item_expense_account",
    tooltip: {
      show: false,
    },
    label: "Default product item expense account",
    type: "select",
    component: AccountPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 3,
    validation: [],
    require: undefined,
    class: "",
    isClearable: true,
  },
];

export default ProductItemSettingData;
