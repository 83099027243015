import React, { ChangeEventHandler } from "react";
import { useDispatch } from "react-redux";
import { Field } from "redux-form";
import { PaymentFieldType } from "../types";
import { RenderSelect } from "../../../forms/bootstrapFields";
import { CHANGE_DELIVERY_METHOD } from "../../../../actions/actionTypes";

type DeliveryMethodPropsType = PaymentFieldType;

const DeliveryMethod = ({ validations, required, label }: DeliveryMethodPropsType) => {
  const dispatch = useDispatch();
  const changeDeliveryMethod: ChangeEventHandler<HTMLSelectElement> = (e) => {
    dispatch({ type: CHANGE_DELIVERY_METHOD, payload: e.target.value });
  };

  return (
    <Field
      name="delivery_method"
      label={label}
      component={RenderSelect}
      required={required}
      validate={validations}
      options={[
        { label: "-- select --", value: "" },
        { label: "Same Day", value: "same-day" },
      ]}
      onChange={changeDeliveryMethod}
    />
  );
};

export default DeliveryMethod;
