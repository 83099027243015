import React from "react";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { GrAttachment } from "react-icons/gr";

export type dropButtonPropsType = {
  onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
  hideIcon: boolean;
  disabled?: boolean;
};

const DropButton = ({ onDrop, hideIcon, disabled }: dropButtonPropsType) => {
  return (
    <Dropzone onDrop={onDrop} disabled={disabled}>
      {({ getRootProps, getInputProps }) => (
        <div>
          {!hideIcon ? (
            <div {...getRootProps()} className={"text-center float-left"} style={{ width: 30 }}>
              <input {...getInputProps()} />
              <GrAttachment />
            </div>
          ) : (
            <div {...getRootProps()} className={"text-center float-left"}>
              <input {...getInputProps()} />
              <button className="btnEmailAttachment">Add Attachment</button>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
};

export default DropButton;
