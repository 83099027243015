import React from "react";
import { Col, Row } from "react-bootstrap";
import { required } from "../../../../../services/validations/reduxFormValidation";
import PaymentType from "../../fields/paymentType";
import Status from "../../../../common/managePaymentMethod/fields/status";

const CreditCard = () => {
  return (
    <>
      <Row>
        <Col md="4">
          <PaymentType label="Payment Type:" required validations={[required]} />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Status label="Status:" />
        </Col>
      </Row>
    </>
  );
};

export default CreditCard;
