import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import RestApi from "providers/restApi";

/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * reduxform and reduxformpickers.
 */
class CustomerPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    //fetch payment methods
    this.restApiService
      .get("purchasers")
      .then((result) => {
        if (result.data) {
          let newValue = result.data;
          if (this.props.paymentType == "cambridge") {
            newValue = result.data.filter((el, index) => el.accepted_payment_methods.cambridge === true);
          }
          let options = this.parsePurchasers(newValue);
          this.setState({
            selectOptions: options,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  parsePurchasers(result) {
    let returnObj = result.map((purchaser) => {
      return {
        value: purchaser.id,
        label: purchaser.name,
        raw: purchaser,
        vendor_id: purchaser.vendor_detail.id,
      };
    });

    if (!this.props.isMulti) {
      returnObj.unshift({ value: null, label: "Select..." });
    }

    return returnObj;
  }

  //Find the option that matches the passed in props.id
  findSelected() {
    if (!this.state.selectOptions) {
      return null;
    }

    if (this.props.isMulti) {
      let options = [];
      this.state.selectOptions.forEach((option) => {
        //todo, add logic for non vendor_id parsing
        if (this.props.vendor_ids && this.props.vendor_ids.includes(option.vendor_id)) {
          options.push(option);
        }
      });
      return options.length > 0 ? options : null;
    } else {
      return this.state.selectOptions.find((option) => {
        if (option.value === this.props.id) {
          return option;
        }
        return null;
      });
    }
  }

  render() {
    return (
      <Form.Group>
        {this.props.hideLabel !== true && (
          <Form.Label style={{ textTransform: "capitalize" }}>
            {this.props.t("details.customer")} <span className={"color-red"}>*</span>
          </Form.Label>
        )}
        <Select
          isDisabled={this.props.isDisabled}
          required={true}
          value={this.findSelected()}
          placeholder={this.props.t("forms.selectCustomer")}
          options={this.state.selectOptions}
          isMulti={this.props.isMulti}
          onChange={(e) => {
            if (this.props.callback) {
              this.props.callback(e);
            }
          }}
          // Z-index fix
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
        {this.props.valid === false && (
          <div style={{ fontSize: "80%", color: "#dc3545" }}>{this.props.t("validation.customer")}</div>
        )}
      </Form.Group>
    );
  }
}

export default withTranslation()(CustomerPicker);
