import CategoryPicker from "components/admin/pickers/reduxFormPickers/categoryPicker";
import { change } from "redux-form";
import { RenderCheckBox, RenderSelect } from "../../../../../../components/forms/bootstrapFields";
import CardProgramPicker from "../../../../../../components/pickers/reduxFormPicker/cardProgramPicker";
import PolicyPicker from "../../../../../../components/pickers/reduxFormPicker/policyPicker";
import { CompanyDefaultsTypes } from "../../settingsTypes";

export const ExpenseSettings: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.expense.enforce_policy_violations",
    tooltip: {
      show: true,
      title:
        "If selected, expense items will not allow to be created or linked to an expense report if they contain any policy violations.",
      placement: "top",
    },
    label: "Enforce Policy Violations",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.hide_tax_fields",
    tooltip: {
      show: true,
      title: "Tax code and Tax amount fields will be hidden from expense item form.",
      placement: "top",
    },
    label: "Hide tax fields",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.expense_item.tax_code_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.tax_code_required",
    tooltip: {
      show: true,
      title: "Tax code will be required field on expense item form.",
      placement: "top",
    },
    label: "Tax code required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return !(company_default.expense_item && company_default.expense_item.hide_tax_fields);
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.show_department",
    tooltip: {
      show: true,
      title: "Department will be populated on expense item once selected.",
      placement: "top",
    },
    label: "Show Department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.expense_item.required_department", false));
      dispatch(change(formName, "company_default.expense_item.inherit_employee_department", false));
    },
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.expense_item.required_department",
    tooltip: {
      show: true,
      title: "If enabled, the department field will become a mandatory requirement for all expense items.",
      placement: "top",
    },
    label: "Department Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.expense_item && company_default.expense_item.show_department;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.inherit_employee_department",
    tooltip: {
      show: true,
      title: "Default to Employee level Department for all Expense Items",
      placement: "top",
    },
    label: "Inherit Employees Department",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.expense_item && company_default.expense_item.show_department;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.expense.show_subsidiary",
    tooltip: {
      show: true,
      title: "If selected, the Subsidiary field will be visible on the expense item form",
      placement: "top",
    },
    label: "Show Subsidiary",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.required_description",
    tooltip: {
      show: true,
      title: "If selected, the description will be mandatory on the expense item form",
      placement: "top",
    },
    label: "Description required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.expense_item.require_per_diem_item",
    tooltip: {
      show: true,
      title:
        "If selected, employees submitting Expense Items using Per Diem will be required to select a Per Diem Item",
      placement: "top",
    },
    label: "Per Diem item required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.expense_item.show_billable",
    tooltip: {
      show: true,
      title: "If selected, employees submitting Expense Items will be able to mark the expense as billable",
      placement: "top",
    },
    label: "Show Billable",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.expense_item.show_reimbursable",
    tooltip: {
      show: true,
      title: "If selected, employees submitting Expense Items will be able to mark the expense as reimbursable",
      placement: "top",
    },
    label: "Show Reimbursable",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.expense_item.show_location",
    tooltip: {
      show: true,
      title: "If selected, employees submitting Expense Items will be able to add a Location.",
      placement: "top",
    },
    label: "Show Location",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.expense_item.location_required", false));
    },
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.location_required",
    tooltip: {
      show: true,
      title: "If enabled, the location field will become a mandatory requirement for all expense items.",
      placement: "top",
    },
    label: "Location Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.expense_item && company_default.expense_item.show_location;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.enable_split_expenses",
    tooltip: {
      show: true,
      title:
        "If enabled, the Split Expense button will display in the Expense Allocation section of the 'Create a New Expense Item' and 'Edit Expense Item' forms, as well as the 'Edit Expense' modal on the Expense Report Details page",
      placement: "top",
    },
    label: "Enable Split Expenses",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { current_user } = isShowObj;
      return current_user?.is_root;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.show_business_unit",
    tooltip: {
      show: true,
      title: "If selected, the expense item will show the business unit field.",
      placement: "top",
    },
    label: "Show business unit for expense item",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.expense_item.required_business_unit", false));
    },
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.required_business_unit",
    tooltip: {
      show: true,
      title: "If enabled, the business unit field will become a mandatory requirement for all expense items.",
      placement: "top",
    },
    label: "Business Unit Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.expense_item && company_default.expense_item.show_business_unit;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.show_project",
    tooltip: {
      show: true,
      title: "If selected, the expense item will show the project field.",
      placement: "top",
    },
    label: "Show projects for expense items",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.project_required",
    tooltip: {
      show: true,
      title: "If enabled, the project field will become a mandatory requirement for all expense items.",
      placement: "top",
    },
    label: "Project Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default.expense_item && company_default.expense_item.show_project;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.hide_new_expense_report",
    tooltip: {
      show: true,
      title: "If selected, the expense item form will not show the option to create or link to an expense report.",
      placement: "top",
    },
    label: "Hide expense report dropdown in the expense items form",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.default_new_expense_report",
    tooltip: {
      show: true,
      title: "If selected, the expense item form will default to creating a new expense report for the expense item.",
      placement: "top",
    },
    label: "Default expense report dropdown in the expense items form to New Expense Report",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.expense_item_default_to_contact_policy",
    tooltip: {
      show: true,
      title:
        "If selected, the expense policy assigned at the user level will be used as the default on expense items. If no user-level policy is found, then the company-level policy default will apply.",
      placement: "top",
    },
    label: "Default to User-level Policy for all Expense Items",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.auto_create_expenses_from_purchases",
    tooltip: {
      show: true,
      title: "If selected, card purchases will automatically generate expense items on a daily basis.",
      placement: "top",
    },
    label: "Automatically Create Expense Items from Purchases",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.purchase_expense_item_default_policy_id",
    tooltip: {
      show: true,
      title:
        "Choose a default policy for Expense Items created from Purchases. You must choose a policy to create Expense Items from Purchases.",
      placement: "top",
    },
    label: "Default Policy for Expense Items from Purchases:",
    type: "react-select",
    component: PolicyPicker,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default?.expense_item?.auto_create_expenses_from_purchases;
    },
    onChangeCallBack: (onChangeObj, e) => {
      const { formName, dispatch } = onChangeObj;
      dispatch(change(formName, "company_default.expense_item.purchase_expense_item_default_category_id", null));
    },
    col: 2,
    validation: [],
    require: true,
    class: "",
  },
  {
    name: "company_default.expense_item.purchase_expense_item_default_category_id",
    tooltip: {
      show: true,
      title: "Choose a default category for Expense Items created from Purchases.",
      placement: "top",
    },
    label: "Default Category for Expense Items from Purchases:",
    type: "react-select",
    customProps: (customPropsObj: any) => ({
      modelData: {
        policy_id: customPropsObj?.policy_id,
      },
    }),
    component: CategoryPicker,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default?.expense_item?.auto_create_expenses_from_purchases;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.purchase_expense_item_default_card_program_ids",
    tooltip: {
      show: true,
      title: "Choose a default ghost card program for Expense Items created from Purchases.",
      placement: "top",
    },
    label: "Automatically Create Expense Items from Selected Ghost Card Programs",
    type: "react-select",
    customProps: (customPropsObj: any) => ({
      filterByGhost: true,
      modelData: {
        card_program_id: customPropsObj?.card_program_ids,
      },
    }),
    component: CardProgramPicker,
    isShow: (isShowObj) => {
      const { company_default } = isShowObj;
      return company_default?.expense_item?.auto_create_expenses_from_purchases;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: undefined,
    isMulti: true,
    class: "",
  },
  {
    name: "company_default.expense_item.show_po_number",
    tooltip: {
      show: true,
      title: "If selected, the PO Number field will be visible on the Create and Edit Expense Item forms.",
      placement: "top",
    },
    label: "Show PO Number",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_item.hide_create_expense_button",
    tooltip: {
      show: true,
      title: "If selected, the Create Expense Item button will no longer be visible throughout the app.",
      placement: "top",
    },
    label: "Hide Create Expense Button",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
];
export const AutomatedExpensesSettings: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.expense.concierge_default_policy_id",
    tooltip: {
      show: false,
    },
    label: "Default Policy for Automated Expense Processing",
    type: "react-select",
    component: PolicyPicker,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 1,
    validation: [],
    require: true,
    class: "",
  },
  {
    name: "company_default.expense.concierge_auto_start_workflow_date",
    tooltip: {
      show: true,
      title: "If turned off, after automated expense has finished processing will directly go for workflow",
      placement: "top",
    },
    label: "Auto start workflow for Automated Expenses:",
    type: "drop-down",
    component: RenderSelect,
    isShow: (isShowObj) => {
      return true;
    },
    options: [
      { label: "-- Select an option --", value: "" },
      { label: "Same Day", value: "current_day" },
      { label: "1 Business Days", value: "second_day" },
      { label: "Do Not Start", value: "no_start" },
    ],
    onChangeCallBack: (onChangeObj) => {},
    col: 2,
    validation: [],
    require: undefined,
    class: "",
  },
];
export const ExpenseReportSettings: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.expense.hide_business_unit",
    tooltip: {
      show: true,
      title: "If selected, the expense report will hide the business unit field.",
      placement: "top",
    },
    label: "Hide business unit for expense report",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.expense_workflow_name_required",
    tooltip: {
      show: true,
      title: "Workflow Name is required",
      placement: "top",
    },
    label: " Workflow Name Required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
];
