import React, { useState } from "react";
import { IDType } from "../../../../../services/common/types/common.type";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import { AccountNumberValidator } from "../../accountNumberValidator";
import BicValidator from "../../bicValidator";
import { required } from "../../../../forms/inputFields";
import PurposeCode from "./purposeCode";

const WireVnd = ({ modelName }: { modelName?: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col md="6">
        <Field
          name={`${modelName}.account_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.accountHolderName")}
          className={`w-100`}
        />
      </Col>
      <Col md="4">
        <BicValidator modelName={`${modelName}`} />
      </Col>
      <Col md="4">
        <AccountNumberValidator
          modelName={`${modelName}`}
          accountNumberType="australian-account-number"
          maxLength={9}
        />
      </Col>
      <Col md="4">
        <Field
          name={`${modelName}.bank_name`}
          component={RenderField}
          type="text"
          label={t("common.paymentMethod.bankName")}
          className={`w-100`}
          required
          validate={[required]}
        />
      </Col>
      <Col md="12">
        <PurposeCode />
      </Col>
    </>
  );
};

export default WireVnd;
