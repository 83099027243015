import { CsvExportParams, IToolPanelComp, IToolPanelParams } from "ag-grid-community";

export interface CustomStatsToolPanelParams extends IToolPanelParams {
  title: string;
}

class CustomStatsToolPanel implements IToolPanelComp {
  eGui!: HTMLDivElement;
  init(params: CustomStatsToolPanelParams) {
    this.eGui = document.createElement("div");
    this.eGui.style.textAlign = "center";

    // calculate stats when new rows loaded, i.e., onModelUpdated
    const renderStats = () => {
      this.eGui.innerHTML = this.calculateStats(params);
      this.attachEventListeners(params);
    };

    params.api.addEventListener("modelUpdated", renderStats);

    // Initial render
    renderStats();
  }

  getGui() {
    return this.eGui;
  }

  refresh(): void {}

  calculateStats(params: CustomStatsToolPanelParams) {
    return `
      <div class="list-group" style="font-size: 14px; font-family: Acre;  text-align: left;">
        <button id="clearAll" class="list-group-item list-group-item-action">Clear all filters</button>
        <button id="exportSelected" class="list-group-item list-group-item-action">Export selected data as csv</button>
        <button id="exportAllData" class="list-group-item list-group-item-action">Export all data as csv</button>
        <button id="exportVisible" class="list-group-item list-group-item-action">Export visible data as csv</button>
        <button id="exportToExcel" class="list-group-item list-group-item-action">Export to Excel</button>
      </div>`;
  }

  attachEventListeners(params: CustomStatsToolPanelParams) {
    const api = params.api;

    const getDefaultFileName = () => {
      let now = new Date();
      return "dataExport-" + now.toLocaleTimeString();
    };

    const onBtExport = () => {
      const firstRow = api.getFirstDisplayedRow();
      const lastRow = api.getLastDisplayedRow();

      const params: CsvExportParams = {
        skipColumnHeaders: false,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        allColumns: false,
        onlySelected: false,
        suppressQuotes: false,
        fileName: getDefaultFileName(),
      };

      api.exportDataAsCsv(params);
    };

    const onAllExport = () => {
      const params: CsvExportParams = {
        skipColumnHeaders: false,
        skipRowGroups: false,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        allColumns: false,
        onlySelected: false,
        suppressQuotes: false,
        //   shouldRowBeSkipped: shouldRowBeSkipped,
        fileName: getDefaultFileName(),
      };

      api.exportDataAsCsv(params);
    };

    const onExcelExport = () => {
      const params = {
        skipHeader: false,
        columnGroups: true,
        skipFooters: false,
        skipGroups: false,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        allColumns: false,
        onlySelected: false,
        suppressQuotes: false,
        fileName: getDefaultFileName(),
        exportMode: "xlsx",
      };

      api.exportDataAsExcel(params);
    };

    const onSelectedExport = () => {
      api.exportDataAsCsv({ onlySelected: true });
    };

    const onClearFilter = () => {
      api.setFilterModel(null);
    };

    // Attach event listeners to the buttons
    document.getElementById("clearAll")?.addEventListener("click", onClearFilter);
    document.getElementById("exportSelected")?.addEventListener("click", onSelectedExport);
    document.getElementById("exportAllData")?.addEventListener("click", onAllExport);
    document.getElementById("exportVisible")?.addEventListener("click", onBtExport);
    document.getElementById("exportToExcel")?.addEventListener("click", onExcelExport);
  }
}

export default CustomStatsToolPanel;
