import { AxiosResponse } from "axios";
import { restApiService } from "../../../providers/restApi";
import { IDType } from "../types/common.type";
import { AddUserPayloadType, SecurityDetailsType } from "./userTypes";

class UserApis {
  static async addUser(data: AddUserPayloadType) {
    try {
      const response: AxiosResponse<any> = await restApiService.post("users", null, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static getSecurity = async (id: IDType) => {
    try {
      const response: AxiosResponse<SecurityDetailsType> = await restApiService.get(`users/${id}/security`);
      return response.data;
    } catch (err) {
      throw err;
    }
  };
}

export default UserApis;
