import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Form, Container, Button, Spinner } from "react-bootstrap";
import { change, Field, reduxForm } from "redux-form";
import CustomerPicker from "../../../components/vp/pickers/reduxFormPicker/customerPicker";
import { renderDatePicker, renderField, renderTextareaField } from "../../../components/forms/fields";
import ItemLine from "../../../components/vp/invoices/itemLine/ItemLine";
import ExpenseLine from "../../../components/vp/invoices/expenseLine/expenseLine";
import style from "./Invoice.module.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { BreadcrumbTrail } from "../../../components/navigation/navigationLinks";
import CurrencyCodePicker from "./../../../components/common/pickers/reduxFormPickers/currencyCodePicker";
import PurchaseOrderPicker from "../../../components/vp/pickers/reduxFormPicker/purchaseOrderPicker";
import restApi from "../../../providers/restApi";
import Panel from "../../../components/panel/panel";
import FileUploader from "../../../components/common/fileUploader/fileUploader";
import { roundUpAmount } from "../../../services/vp/services/roundUpAmount";
import { currencySymbolRenderer } from "../../../services/common/currencySymbolRendererService";
import _ from "lodash";
import useConfirmModal from "../../../components/modals/confirmModal/useConfirmModalHook";
import { required } from "services/validations/reduxFormValidation";
import { RenderFieldNumber } from "components/forms/bootstrapFields";
import { selectAppCurrecyCode } from "reducers/common/appCommonStatesSlice";

const restApiService = new restApi();
const InvoiceFormName = "InvoiceForm";

let InvoiceForm = (props) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const { createConfirmModal } = useConfirmModal();

  const [poCurrency, setPoCurrency] = useState("");
  const [attachments, setAttachments] = useState([]);
  const appCurrencyCodes = useSelector(selectAppCurrecyCode);

  const addMaxValues = useCallback((po) => {
    po.po_items.map((item) => {
      item.qty = item.qty_balance;
    });
  }, []);

  const customerPicked = useCallback(
    (customer) => {
      dispatch(change(InvoiceFormName, "purchase_order_id", null));
      dispatchPoDependentValues({});
    },
    [dispatch],
  );

  // Set only those field values which are using for item line
  const setOnlyRequiredPoItemsFields = useCallback((poItems) => {
    return poItems.map((poItem) => {
      return {
        inherit_po_item_id: poItem.id,
        amount: poItem.amount,
        billed_item_qty: poItem.billed_item_qty,
        product_name: poItem.product_name,
        qty_balance: poItem.qty_balance,
        receipt_item_qty: poItem.receipt_item_qty,
        product_item: _.isPlainObject(poItem.product_item)
          ? {
              name: poItem.product_item.name,
              item_type: poItem.product_item.item_type,
              display_name: poItem.product_item.display_name,
              status: poItem.product_item.status,
            }
          : null,
        product_item_id: _.isPlainObject(poItem.product_item) ? poItem.product_item.id : null,
        tax: poItem.tax,
        tax_id: poItem.tax_id,
        qty: poItem.qty,
        total: poItem.total,
        unit_price: poItem.unit_price,
      };
    });
  }, []);

  // Set only those field values which are using for expense line
  const setOnlyRequiredPoExpenseFields = useCallback((debitAccounts) => {
    return debitAccounts.map((expense) => {
      return {
        account: _.isPlainObject(expense.account)
          ? { id: expense.account.id, name: expense.account.name, number: expense.account.number }
          : null,
        account_id: _.isPlainObject(expense.account) ? expense.account.id : null,
        project: _.isPlainObject(expense.project) ? expense.project : null,
        project_id: _.isPlainObject(expense.project) ? expense.project.id : null,
        amount: expense.amount,
        sub_amount: expense.sub_amount,
        tax: expense.tax,
        memo: expense.memo,
      };
    });
  }, []);

  // This block is use to allow only those field values which are using to generate invoice.
  const setHeaderLevelPoFieldValues = useCallback((data) => {
    dispatch(change(InvoiceFormName, "requestor_id", _.isPlainObject(data.requestor) ? data.requestor.id : null));
    return {
      currency_code: data.currency_code,
      po_items:
        _.isArray(data.po_items) && data.po_items.length > 0 ? setOnlyRequiredPoItemsFields(data.po_items) : null,
      invoice_debit_accounts:
        _.isArray(data.invoice_debit_accounts) && data.invoice_debit_accounts.length > 0
          ? setOnlyRequiredPoExpenseFields(data.invoice_debit_accounts)
          : null,
    };
  }, []);

  const dispatchPoDependentValues = useCallback(
    (data) => {
      if (_.isPlainObject(data)) {
        let po = setHeaderLevelPoFieldValues(data);
        setPoCurrency(po.currency_code);
        dispatch(change(InvoiceFormName, "currency_code", po.currency_code));
        dispatch(change(InvoiceFormName, "poItems", po.po_items));
        dispatch(change(InvoiceFormName, "poExpenses", po.invoice_debit_accounts));
      }
    },
    [dispatch],
  );

  const getPoDetails = useCallback(
    async (selected) => {
      if (selected && selected.value) {
        const result = await restApiService.get("vendor_purchase_orders/" + selected.value);
        addMaxValues(result.data);
        dispatchPoDependentValues(result.data);
      } else {
        dispatchPoDependentValues({});
      }
    },
    [addMaxValues, dispatch],
  );

  const uploadAttachments = (files) => {
    let parsedFiles = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    setAttachments(parsedFiles);
    dispatch(change(InvoiceFormName, "attachments", parsedFiles));
  };

  const getSelectedRecords = () => {
    let selected = [];
    if (props.invoice.poItems && props.invoice.poItems.length > 0) {
      props.invoice.poItems.map((item) => {
        if (item.isAllow) {
          selected.push(item);
        }
      });
    }
    if (props.invoice.poExpenses && props.invoice.poExpenses.length > 0) {
      props.invoice.poExpenses.map((expense) => {
        if (expense.isAllow) {
          selected.push(expense);
        }
      });
    }
    return selected;
  };

  const setInvoiceFromPo = () => {
    if (_.isObject(props.location) && _.isObject(props.location.state) && props.location.state.po) {
      let po = props.location.state.po;
      let obj = { value: po.id, label: po.number };
      dispatch(change(InvoiceFormName, "purchaser_id", po.purchaser.id));
      dispatch(change(InvoiceFormName, "purchase_order_id", po.id));
      getPoDetails(obj);
    }
  };

  useEffect(() => {
    setInvoiceFromPo();
  }, []);

  useEffect(() => {
    let selected = getSelectedRecords();
    if (selected && selected.length > 0) {
      getTax(selected);
      getTotal(selected);
    } else {
      dispatch(change(InvoiceFormName, "totalTax", undefined));
      dispatch(change(InvoiceFormName, "isAmountDisable", false));
    }
  });

  useEffect(() => {
    if (!props.invoice.amount > 0) {
      dispatch(change(InvoiceFormName, "amount", undefined));
    }
  }, [props.invoice.amount]);

  const getTax = useCallback((selected) => {
    let totalTax = 0;
    selected.map((obj) => {
      if (_.isObject(obj) && obj.tax) {
        totalTax += parseFloat(obj.tax);
      }
    });
    dispatch(change(InvoiceFormName, "totalTax", roundUpAmount(totalTax, 2)));
  });

  const getTotal = useCallback((selected) => {
    let total = 0;
    dispatch(change(InvoiceFormName, "isAmountDisable", true));
    selected.map((obj) => {
      total += parseFloat(obj.product_item_id ? parseFloat(obj.total) : parseFloat(obj.amount));
    });
    dispatch(change(InvoiceFormName, "amount", roundUpAmount(total, 2)));
  });

  const submitForm = () => {
    // using programitcal submit as error thrown by handleSubmit function
    // must be catched by form otherwise form will not show serverThrown erros
    formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const confirmBeforeSubmit = () => {
    createConfirmModal({
      title: "Confirm",
      body: "Do you want to proceed?",
      saveCallBack: submitForm,
    });
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <div className={style.pageHeaderSection}>
        <Container fluid={true} className={"pt-4 pb-4 "}>
          <BreadcrumbTrail to={"/ar/invoices"} pageName={props.t("breadcrumbs.invoices")} />
          <Row>
            <Col sm="6">
              <h1>{props.t("forms.newInvoice")}</h1>
            </Col>
            <Col sm="6 text-right">
              <Button
                variant=" btn-secondary"
                disabled={props.isDisableFormBtn}
                onClick={() => props.history.push("/ar/invoices")}
              >
                {props.t("cancel")}
              </Button>
              <Button variant="btn btn-primary ml-1" onClick={confirmBeforeSubmit} disabled={props.isDisableFormBtn}>
                {props.isDisableFormBtn ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : null}{" "}
                {props.t("submit")}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid={true} className="mt-4">
        <Row>
          <Col sm="4">
            <Field
              name="purchaser_id"
              label={props.t("details.customer")}
              component={CustomerPicker}
              callback={(customer) => customerPicked(customer)}
              isRequired={true}
              filter={{ allowed_invoice_submission: true }} //for vendor invoice submission, only purchasers who allow invoice summison from vp fetched
              validate={[required]}
            />
          </Col>
          <Col sm="4">
            <Field
              name="purchase_order_id"
              label={props.t("details.po")}
              component={PurchaseOrderPicker}
              callback={(po) => getPoDetails(po)}
              purchaserId={props.invoice && props.invoice.purchaser_id}
            />
          </Col>
          <Col sm="4">
            <Field
              name="service_start_date"
              label={props.t("details.serviceDate")}
              component={renderDatePicker}
              type="date"
              isRequired={props.invoice.service_end_date}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <Field
              name="amount"
              type="number"
              label={props.t("details.amount")}
              component={RenderFieldNumber}
              required
              validate={[required]}
              disabled={props.invoice.isAmountDisable}
            />
          </Col>
          <Col sm="4">
            <Field
              name="currency_code"
              colSize="12 px-0"
              component={CurrencyCodePicker}
              disabled={poCurrency}
              label={props.t("details.currencyCode")}
              currencyCodeOptions={_.isArray(appCurrencyCodes) ? appCurrencyCodes : []}
            />
          </Col>
          <Col sm="4">
            <Field name="service_end_date" label={props.t("details.to")} component={renderDatePicker} type="date" />
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <Field name="number" label={props.t("details.invoice_number")} component={renderField} type="text" />
            <div className={style.invoiceDate}>
              <Field name="date" label={props.t("details.invoiceDate")} component={renderDatePicker} type="date" />
            </div>
          </Col>
          <Col sm="8">
            <Field
              name="description"
              label={props.t("details.description")}
              style={{ height: "150px" }}
              component={renderTextareaField}
              type="text"
            />
          </Col>
        </Row>
        {props.invoice && (
          <>
            {props.invoice.poItems && props.invoice.poItems.length > 0 && (
              <Row>
                <Col sm="12" className="mt-5">
                  <ItemLine poItems={props.invoice.poItems} currencyCode={props.invoice.currency_code} />
                </Col>
              </Row>
            )}
            {props.invoice.poExpenses && props.invoice.poExpenses.length > 0 && (
              <Row>
                <Col sm="12" className="mt-4">
                  <ExpenseLine poExpenses={props.invoice.poExpenses} currencyCode={props.invoice.currency_code} />
                </Col>
              </Row>
            )}

            {((props.invoice.poExpenses && props.invoice.poExpenses.length > 0) ||
              (props.invoice.poItems && props.invoice.poItems.length > 0)) && (
              <>
                <Row className="mt-4">
                  <Col md={{ span: 2, offset: 9 }} className="text-right">
                    <label>{props.t("details.tax")}</label>
                  </Col>
                  <Col md={{ span: 1 }}>
                    <label>
                      {props.invoice.totalTax && currencySymbolRenderer(props.invoice.currency_code)}
                      {props.invoice.totalTax}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 2, offset: 9 }} className="text-right">
                    <label>{props.t("details.totalAmount")}</label>
                  </Col>
                  <Col md={{ span: 1 }}>
                    <label>
                      {props.invoice.amount && currencySymbolRenderer(props.invoice.currency_code)}
                      {props.invoice.amount}{" "}
                    </label>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}

        <Row>
          <Col sm="12" className="mt-4">
            <div className="attachmentPanel">
              <Panel
                panelContent={{
                  heading: props.t("details.documents"),
                  iconClass: "icon icon-documents",
                }}
                cardBodyStyle={{ padding: "0px" }}
                headerComponent={<FileUploader showUploadBtn="true" uploadAttachments={uploadAttachments} />}
                cardHeaderClass={"flex-row-reverse"}
              >
                <div className="mx-4 my-4">
                  {_.isArray(attachments) &&
                    attachments.length > 0 &&
                    attachments.map((attachment, index) => (
                      <span key={index} className={style.fileName}>
                        {attachment.name}
                      </span>
                    ))}
                </div>
              </Panel>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
InvoiceForm = reduxForm({
  form: InvoiceFormName,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(InvoiceForm);
InvoiceForm = connect((state) => ({
  initialValues: state.submitInvoicesReducers.InvoiceForm,
}))(InvoiceForm);
export default InvoiceForm;
