import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";

const ExpensifyTokenBased = () => {
  return (
    <>
      <Col lg="12">
        <Field
          name="form.token_id"
          label="Partner User Id"
          placeholder="Partner User Id"
          component={RenderField}
          type="text"
        />
      </Col>
      <Col lg="12">
        <Field
          name="form.token_secret"
          label="Partner Secret"
          placeholder="Partner Secret"
          readOnly={true}
          component={RenderField}
          type="password"
        />
      </Col>
    </>
  );
};

export default ExpensifyTokenBased;
