// Todo: Add language support
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import RestApi from "../../../../../../../providers/restApi";
import { CreateNotification, NotificationType } from "../../../../../../../services/general/notifications";
import { useTranslation } from "react-i18next";
import useConfirmModal from "../../../../../../../components/modals/confirmModal/useConfirmModalHook";
import FileUploader from "../../../../../../../components/common/fileUploader/fileUploader";

const DocumentRequirement = () => {
  const [documentRequirements, setDocumentRequirements] = useState([]);
  const restApiService = useMemo(() => new RestApi(), []);
  const isMounted = useRef();

  const { t } = useTranslation();
  const { createConfirmModal } = useConfirmModal();

  const fetchRequirements = useCallback(async () => {
    try {
      const res = await restApiService.get("document_requirements");
      if (res.data) {
        if (isMounted.current) {
          setDocumentRequirements(res.data);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [restApiService]);

  useEffect(() => {
    // on mount call to load data
    fetchRequirements();
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, [fetchRequirements]);

  const uploadAttachment = async (requirement, requirementId) => {
    // create the document first , then create asset against it
    try {
      const result = await restApiService.post("/documents", null, {
        document: {
          name: requirement.asset.file_name,
          documentable_id: requirementId,
          documentable_type: "DocumentRequirement",
        },
      });
      if (result) {
        const formData = new FormData();
        formData.append("document[assets_attributes[0]asset_file]", requirement.asset.asset_file);
        formData.append("document[assets_attributes[0]file_name]", requirement.asset.file_name);
        const uploadRes = await restApiService.formWithImage("documents/" + result.data.id, formData, "patch");

        if (uploadRes.data) {
          CreateNotification(
            "Saved",
            `Document Requirement ${requirementId} Saved successfully`,
            NotificationType.success,
          );

          // update all rows
          fetchRequirements();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const save = async (documentRequirement, index) => {
    let promise = null;
    if (documentRequirement.id) {
      promise = restApiService.patch("document_requirements/" + documentRequirement.id, null, {
        document_requirement: documentRequirement,
      });
    } else {
      promise = restApiService.post("document_requirements", null, {
        document_requirement: documentRequirement,
      });
    }

    try {
      const result = await promise;
      if (result.data) {
        if (documentRequirement.asset) {
          uploadAttachment(documentRequirement, result.data.id);
        } else {
          // update all rows
          fetchRequirements();
          CreateNotification(
            "Saved",
            `Document Requirement ${documentRequirement.id} Saved successfully`,
            NotificationType.success,
          );
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // from client
  const removeRequirement = (index) => {
    const filteredDocumentRequirement = documentRequirements.filter((requirement, i) => index !== i);
    setDocumentRequirements(filteredDocumentRequirement);
  };

  // delete from server
  const deleteRequirement = async (documentRequirement) => {
    try {
      const deleteResponse = await restApiService.delete("document_requirements/" + documentRequirement.id);
      fetchRequirements();
      if (deleteResponse) {
        CreateNotification(
          "Delete",
          `Document Requirement ${documentRequirement.id} Deleted successfully`,
          NotificationType.success,
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const confirmDeleteRequirement = (documentRequirement, index) => {
    // delete from server
    if (documentRequirement.id) {
      createConfirmModal({
        title: "Delete Requirement",
        body: "Are you sure, you really want to delete Requirement",
        saveCallBack: deleteRequirement,
        cancelCallBack: null,
        callBackData: documentRequirement,
      });
    } else {
      // delete from client
      createConfirmModal({
        title: "Remove Requirement",
        body: "Are you sure, you really want to remove Requirement",
        saveCallBack: removeRequirement,
        cancelCallBack: null,
        callBackData: index,
      });
    }
  };

  const removeRequirementDocument = async (document) => {
    const deleteRes = await restApiService.delete("documents/" + document.id);
    if (deleteRes) {
      CreateNotification("Delete", `Document ${document.id} Deleted successfully`, NotificationType.success);
      fetchRequirements();
    }
  };

  const confirmDeleteRequirementDocument = (document, index) => {
    if (document.id) {
      createConfirmModal({
        title: "Delete Requirement Document",
        body: "Are you sure, you really want to delete Requirement Document",
        saveCallBack: removeRequirementDocument,
        cancelCallBack: null,
        callBackData: document,
      });
    } else {
      setDocumentRequirements((prevState) =>
        prevState.map((requirement, i) => {
          if (i === index) {
            requirement.asset = null;
            requirement.documents = [];
            return requirement;
          }
          return requirement;
        }),
      );
    }
  };

  const onChangeDocumentTypeChange = (value, index) => {
    setDocumentRequirements((prevState) =>
      prevState.map((requirement, i) => {
        if (i === index) {
          requirement.document_type = value;
          return requirement;
        }
        return requirement;
      }),
    );
  };

  const onChangeDocumentRequired = (value, index) => {
    const updatedDocumentRequirement = documentRequirements.map((requirement, i) => {
      if (i === index) {
        requirement.required = value;
        return requirement;
      }
      return requirement;
    });
    setDocumentRequirements(updatedDocumentRequirement);
  };

  const addAttachment = (files, index) => {
    const file = files[0];
    if (file) {
      setDocumentRequirements((prevState) =>
        prevState.map((requirement, i) => {
          if (i === index) {
            requirement.asset = {
              asset_file: file,
              file_name: file.name,
            };
            requirement.documents = [{ temp_name: file.name }];
            return requirement;
          }
          return requirement;
        }),
      );
    }
  };

  const addRequirement = () => {
    setDocumentRequirements([...documentRequirements, { document_type: "", required: true }]);
  };

  return (
    <>
      <Col>
        <Row className="m-0">
          <Form.Label>Vendor Documents Requested</Form.Label>
        </Row>
        <Row className="m-0 d-flex align-items-center">
          <Col md={9} className="m-0 p-0">
            <Table striped bordered size="sm">
              <thead>
                <tr>
                  <th>Require</th>
                  <th>Name</th>
                  <th>Template</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {documentRequirements.map((requirement, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={requirement.required}
                        onChange={(e) => onChangeDocumentRequired(e.target.checked, index)}
                      />
                    </td>
                    <td>
                      <Col md="12">
                        <Form.Control
                          type="text"
                          value={requirement.document_type}
                          onChange={(e) => onChangeDocumentTypeChange(e.target.value, index)}
                          placeholder="Document Type"
                          required
                        />
                      </Col>
                    </td>
                    <td>
                      {requirement.documents && requirement.documents.length > 0 && (
                        <Row className="m-0 align-items-center">
                          <Col md={10} className="m-0 p-0 overflow-hidden">
                            {requirement.documents[0].name || requirement.documents[0].temp_name}
                          </Col>
                          <Col md={2} className="m-0 p-0">
                            <i
                              className="mt-3 pointer icon icon-cross"
                              onClick={() => confirmDeleteRequirementDocument(requirement.documents[0], index)}
                            />
                          </Col>
                        </Row>
                      )}
                      {(!requirement.documents || requirement.documents.length === 0) && (
                        <>
                          <FileUploader
                            showUploadBtn={true}
                            uploadAttachments={(event) => addAttachment(event, index)}
                          />
                        </>
                      )}
                    </td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center">
                        <i
                          className="mt-1 icon icon-delete"
                          role={"button"}
                          onClick={() => confirmDeleteRequirement(requirement, index)}
                        />
                        <i
                          role={"button"}
                          className="icon icon-system-success"
                          onClick={() => save(requirement, index)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="m-0 mt-3">
          <Col className="m-0 p-0">
            <Button className="btn-secondary" onClick={() => addRequirement()}>
              + Add Requirement
            </Button>
          </Col>
        </Row>
        {/* </Form> */}
      </Col>
    </>
  );
};

export default DocumentRequirement;
