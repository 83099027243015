import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.css";
import Panel from "components/common/panel/panel";
import { Col, Row } from "react-bootstrap";
import PolicyViolationTable from "./policyViolationTable";
import PolicyViolationStatusBar from "./policyViolationStatusBar";
import ExpensesApis from "services/admin/expenses/expensesApis";
import { ExpensesTypes } from "services/admin/expenses/expensesType";
import { getFormValues } from "redux-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dashboardColorMap from "./expenseDashboardColorMap";

type formType = { subsidiary_id?: number; expense_type?: { value: string; label: string }; subsidiaryName?: string };

type formDataType = {
  posted_date: { value: string; label: string };
  posted_filter_data?: { monthName?: string; startDate: string; endDate: string; selectedOption: string };
  posted_date_custom: { startDate: string; endDate: string; selectedOption: string };
};

type ExpenseCategoryType = {
  dateRange: string;
};

const PolicyViolation = ({ dateRange }: ExpenseCategoryType) => {
  const [categoryData, setCategoryData] = useState<ExpensesTypes.ViolationsCategoryType[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedBarIndex, setSelectedBarIndex] = useState<number | undefined>();
  const violationCategoryForm: formDataType = useSelector((state) =>
    getFormValues("expenseCategoryForm")(state),
  ) as formDataType;
  const expenseDashboardForm: formType = useSelector((state) => getFormValues("expenseDashboardForm")(state));
  const subsidiaryId = expenseDashboardForm?.subsidiary_id;
  const expenseType = expenseDashboardForm?.expense_type;
  const colorMap = new dashboardColorMap();
  const colorsArray = colorMap.getDashboardColorScheme().colorsArray;

  const { t } = useTranslation();
  const t2 = (key: string) => t(`expenseCategory.policyViolation.${key}`);

  useEffect(() => {
    if (violationCategoryForm?.posted_filter_data !== undefined) {
      getCategorizeViolationsRecord();
    }
  }, [violationCategoryForm?.posted_filter_data, subsidiaryId, expenseType]);

  const getCategorizeViolationsRecord = async () => {
    try {
      let totalCount = 0;

      let filter: { date_before?: string; date_after?: string; subsidiary_ids?: number; reimbursable?: string } = {};

      if (violationCategoryForm?.posted_filter_data?.endDate) {
        filter.date_before = violationCategoryForm?.posted_filter_data?.endDate;
      }

      if (violationCategoryForm?.posted_filter_data?.startDate) {
        filter.date_after = violationCategoryForm?.posted_filter_data?.startDate;
      }

      if (subsidiaryId) {
        filter.subsidiary_ids = subsidiaryId;
      }

      const result = await ExpensesApis.getCategorizeViolations({ filter: filter });

      if (result) {
        const violationCount = result.violation_counts;

        var tableData: ExpensesTypes.ViolationsCategoryType[] = [];
        var refId: number = 0;

        Object.keys(violationCount).forEach((key) => {
          const localizedCategoryText = t(`policyViolationSummaryComponent.violation.${key}.tableText`);
          tableData.push({
            category_name: localizedCategoryText,
            violations_count: violationCount[key],
            refId: `violationRefId-${refId++}`,
          });
          totalCount += violationCount[key];
        });

        tableData.sort((a, b) => b.violations_count - a.violations_count);

        setCategoryData(tableData);

        setTotalCount(totalCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectedBarIndexHandler = (value: number | undefined) => {
    setSelectedBarIndex(value);
  };

  const categorySortHandler = (data: ExpensesTypes.ViolationsCategoryType[]) => {
    setCategoryData(data);
  };

  return (
    <div>
      <Panel
        cardClass={styles.categoryContainerPanelWrapper}
        cardBodyClass={styles.categoryContainerBodyPanel}
        cardFooterClass={styles.panelFooter}
        header={
          <div>
            <i className={`icon icon-policy-violation ${styles.iconHeading}`}></i>
            {t2("title")}
          </div>
        }
        cardHeaderClass={styles.expensePanelHeader}
      >
        {categoryData.length ? (
          <>
            <Row className={`${styles.expenseCategoriesItems}`}>
              <Col className={`${styles.yearClass}`}>
                <span className={`${styles.categoryHeaderText}`}>{`${dateRange ? dateRange : ""}`}</span>
              </Col>
            </Row>
            <Row className={`${styles.expenseCategoriesItems} ${styles.expenseBarContainer}`}>
              <PolicyViolationStatusBar
                setHighlightRow={selectedBarIndexHandler}
                totalCount={totalCount}
                categoryData={categoryData}
                highLightRow={selectedBarIndex}
                colorArray={colorsArray}
              />
            </Row>
            <Row className={`${styles.expenseCategoriesItems}`}>
              <PolicyViolationTable
                categorySortHandler={categorySortHandler}
                setHighlightRow={selectedBarIndexHandler}
                highLightRow={selectedBarIndex}
                categoryData={categoryData}
                totalCount={totalCount}
                colorArray={colorsArray}
              />
            </Row>
          </>
        ) : (
          <Row className="justify-content-center">
            <span className={`${styles.categoryHeaderText}`}>{t2("none")}</span>
          </Row>
        )}
      </Panel>
    </div>
  );
};

export default PolicyViolation;
