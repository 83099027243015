import React, { Component } from "react";
import vendorPaymentsHeader from "../../../components/datagrid/girdHeaders/vendorPaymentsHeader";
import RestApi from "../../../providers/restApi";
import DataGrid from "../../../components/datagrid/datagrid";

export default class VPPayments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vendor_payments: [],
      gridStyle: {
        width: "100%",
      },
      loading: true,
    };
    this.restApiService = new RestApi();
  }
  componentDidMount() {
    this.restApiService
      .get("vendor_payments")
      .then((result) => {
        this.setState({
          vendor_payments: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    return (
      <div style={{ padding: "1%" }}>
        {/*<h1>Contacts Page</h1>*/}
        <DataGrid
          gridHeight="500px"
          gridStyle={this.state.gridStyle}
          rowData={this.state.vendor_payments}
          columnDefs={vendorPaymentsHeader}
          dataType={"payments"}
          dateFormat={this.state.dateFormat}
          autoHeight={true}
          loading={this.state.loading}
          {...this.props}
        />
      </div>
    );
  }
}
