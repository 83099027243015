import React, { Component, Fragment } from "react";
import RestApi from "../../../providers/restApi";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import PlaidForm from "./plaid/plaidForm";
import { AchValidation } from "../validations/achPaymentValidations";
import { AchPaymentDetail } from "../ach/achPaymentMethod";
import * as actionType from "../../../actions/actionTypes";
import { connect } from "react-redux";
import CommonPaymentAddress from "../fieldComponents/addressComponents";

class MainACHForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      automated: false,
      manual: false,
      disabled: false,
    };
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    if (this.props.paymentMethodRed.id) {
      if (this.props.paymentMethodRed.plaid_account_id) {
        this.setState({ manual: true, disabled: true });
      } else {
        this.setState({ manual: true, disabled: false });
      }
    }
  }

  filterFieldACH(fieldName) {
    var filteredField = AchValidation.filter(function (item) {
      return item.country_currency === fieldName;
    });
    return filteredField;
  }

  //set default currency_code to usd
  setDefaultCurrency = () => {
    this.props.currencyPickerCallback({ value: "USD" });
  };

  plaidAccountChose = (parsedAccount) => {
    this.props.updateFields(parsedAccount);
    this.setState({ manual: true, automated: false, disabled: true });
    this.setDefaultCurrency();
  };

  //if something bad happens we should always default back to manual ach entry
  emergencyManualSwitch = () => {
    this.setState({ manual: true, automated: false, disabled: false });
    this.setDefaultCurrency();
  };

  onManualSwitch = () => {
    this.setState({ manual: true, disabled: false });
    this.setDefaultCurrency();
  };

  render() {
    return (
      <Row>
        <Col>
          {!this.state.automated && !this.state.manual && (
            <Row>
              <Col>
                <h5>How would you like to upload your ACH information?</h5>
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={() => {
                    this.setState({ automated: true });
                  }}
                >
                  Automatic (preferred)
                </button>
                <button type="button" className="btn btn-primary ml-2" onClick={this.onManualSwitch}>
                  Manual
                </button>
              </Col>
            </Row>
          )}

          {this.state.automated && (
            <PlaidForm accountChose={this.plaidAccountChose} emergencySwitch={this.emergencyManualSwitch} />
          )}

          {this.state.manual && (
            <Fragment>
              <AchPaymentDetail
                {...this.props.parentProps}
                {...this.state}
                selectedAccount={this.state.selectedAccount}
                disabled={this.state.disabled}
                fillteredPayment={this.filterFieldACH("USA-USD")}
              />
              <CommonPaymentAddress {...this.props.parentProps} {...this.state} disabled={this.state.disabled} />
            </Fragment>
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    paymentMethodRed: state.paymentMethodRed,
    payment_type: state.paymentMethodRed.payment_type,
    paymentValidationsReducers: state.paymentValidationsReducers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePaymentType: (payload) => {
      dispatch({ type: actionType.CHANGE_PAYMENT_TYPE, payload: payload });
    },
    updateFields: (payload) => {
      dispatch({ type: actionType.CHANGE_PAYMENT_METHOD, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MainACHForm));
