import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Field, getFormValues } from "redux-form";
import { RenderCheckBox, RenderField, RenderSelect } from "../../../../../../components/forms/bootstrapFields";
import AccountPicker from "../../../../../../components/pickers/reduxFormPicker/accountPicker";
import DepartmentPicker from "../../../../../../components/admin/pickers/reduxFormPickers/departmentPicker";
import VendorPicker from "../../../../../../components/pickers/reduxFormPicker/vendorPicker";
import { checkPunchoutIntegration, INTEGRATION_FORM_NAME } from "../../../../../../reducers/admin/integrationsReducer";
import { canShowPunchoutTestButton } from "../../../../../../services/integrationCommonSvc/integrationCommonSvc";
import { required } from "../../../../../../services/validations/reduxFormValidation";

// system_names + application_types
// these is braking our name convention rule but required for dynamic import
const PunchoutPUNCHOUT = () => {
  const dispatch = useDispatch();

  const { form } = useSelector((state) => getFormValues(INTEGRATION_FORM_NAME)(state));
  const punchoutProviders = useSelector((state) => state.integrationsRed.punchout_providers);
  const punchoutTestLoading = useSelector((state) => state.integrationsRed.punchoutTestLoading);
  const punchoutTestResponse = useSelector((state) => state.integrationsRed.punchoutTestResponse);
  const currentUser = useSelector((state) => state.user);

  return (
    <>
      <Row className="m-0">
        <Col lg={"6"}>
          <Field
            name="form.punchout.provider_name"
            component={RenderSelect}
            label={"Provider"}
            options={punchoutProviders}
            require={true}
            validate={[required]}
            required
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.network_id"
            component={RenderField}
            label={"Network ID"}
            required={true}
            type={"text"}
            validate={[required]}
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.duns"
            component={RenderField}
            label={"To Network ID (DUNS)"}
            required={true}
            type={"text"}
            validate={[required]}
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.secret"
            component={RenderField}
            label={"Secret (optional)"}
            required={true}
            type={"text"}
            validate={[required]}
          />
        </Col>
        {form.punchout && form.punchout.provider_name === "Office Depot" && (
          <Col lg={"6"}>
            <Field
              name="form.punchout.customer_acc"
              component={RenderField}
              label={"Customer Acc (Office Depot Only)"}
              required={true}
              type={"text"}
              validate={[required]}
            />
          </Col>
        )}
        <Col lg={"6"}>
          <Field
            name="form.punchout.setup_url"
            component={RenderField}
            label={"Setup URL"}
            required={true}
            type={"text"}
            validate={[required]}
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.order_request_url"
            component={RenderField}
            label={"Order Request Url"}
            required={false}
            type={"text"}
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.environment"
            component={RenderField}
            label={"Environment (test or production)"}
            required={true}
            type={"text"}
            validate={[required]}
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.default_tax_account_id"
            component={AccountPicker}
            label={"Default Tax Account"}
            isClearable={true}
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.default_shipping_account_id"
            component={AccountPicker}
            label={"Default Shipping+Misc Account"}
            isClearable={true}
          />
        </Col>
        {currentUser.company.has_departments && (
          <Col lg={"6"}>
            <Field
              name="form.punchout.default_po_department_id"
              component={DepartmentPicker}
              label={"Default PO Department"}
              required={false}
            />
          </Col>
        )}
        <Col lg={"6"}>
          <Field name="form.punchout.vendor_id" component={VendorPicker} label={"Vendor"} />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.discount_product_item_id"
            component={RenderField}
            label={"Discount Catalog Item Id"}
            type="text"
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.single_product_item_id"
            component={RenderField}
            label={"Single Product Item Id:leave empty if using normal product items"}
            type="text"
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.skip_po_workflow_amount"
            component={RenderField}
            label={"Skip PO Workflow If Amount Less Than"}
            type="text"
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.pre_mapped_addresses"
            component={RenderCheckBox}
            label={"Use Pre-mapped Addresses"}
            type="text"
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.invoice_no_items"
            component={RenderCheckBox}
            label={"No Items on Invoice"}
            type="checkbox"
          />
          <Field
            name="form.punchout.purchase_order_no_items"
            component={RenderCheckBox}
            label={"No Items on Purchase Order"}
            type="checkbox"
          />
          <Field
            name="form.punchout.purchase_order_require_phone"
            component={RenderCheckBox}
            label={"Require Contact Phone Number"}
            type="checkbox"
          />
          <Field
            name="form.punchout.default_product_item_to_new"
            component={RenderCheckBox}
            label={"Product Items Default to NEW"}
            type="checkbox"
          />
        </Col>
        <Col lg={"6"}>
          <Field
            name="form.punchout.shipping_product_item_id"
            component={RenderField}
            label={"Shipping Product Item Id:(leave empty if shipping should expense)"}
            type="text"
          />
        </Col>
        {canShowPunchoutTestButton(form) && (
          <Col lg={"12"}>
            {punchoutTestResponse && <p className="text-success">{punchoutTestResponse}</p>}
            <Button type="button" onClick={() => dispatch(checkPunchoutIntegration(form.punchout.provider_name))}>
              Test Integration
            </Button>
            {punchoutTestLoading && (
              <Spinner animation="border" variant="info" id="refresh_cache_spin" className="ml-1 mt-1" />
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

// using named export intensionally
export { PunchoutPUNCHOUT };
