import { cloneDeep, sortBy, uniq } from "lodash";
import { AppDispatch, RootState } from "reducers";
import { change, getFormValues } from "redux-form";
import {
  FileRowDataType,
  FileType,
  SelectedWombatType,
  WombatFieldType,
  WombatFormStateType,
  WombatFormType,
  WombatFunctionNamesType,
  WombatFunctionsGroupType,
  WombatFunctionSourceType,
  WombatFunctionType,
  WombatWorkflowType,
} from "wombatifier/services/wombat.types";
import * as XLSX from "xlsx";
import { WombatApis } from "./wombatApis";

export const WOMBAT_FUNCTION_OPTIONS = sortBy(
  [
    { label: "REGEX MATCH", value: "regex_match" },
    { label: "REGEX EXTRACT", value: "regex_extract" },
    { label: "IS URL", value: "is_url" },
    { label: "IS EMAIL", value: "is_email" },
    { label: "CONCAT", value: "concat" },
    //{label: 'SUBSTRING', value: "substring"},
    { label: "INDEX OF", value: "index_of" },
    { label: "REPLACE", value: "replace" },
    { label: "ROUND", value: "round" },
    { label: "SET VALUE", value: "set_value" },
    { label: "GET VALUE", value: "get_value" },
    { label: "BOOLEAN", value: "boolean_result" },
    { label: "EXISTS", value: "exists" },
    { label: "EQUALS", value: "equals" },
    { label: "EQUALS ANY", value: "equals_any" },
    { label: "NOT EQUALS", value: "not_equals" },
  ],
  (item) => item.label,
);

export const WOMBAT_WORKFLOW_TYPES = [
  { label: "XLSX/CSV INGEST", value: "file_integration_ingest_csv" },
  { label: "XLSX/CSV OUTBOUND", value: "file_integration_outbound" },
  { label: "ROSETTA INGEST", value: "file_integration_ingest_pmg_rosetta" },
];

export const WOMBAT_FUNCTION_NAME_MAP = WOMBAT_FUNCTION_OPTIONS.reduce((hash: { [key: string]: string }, option) => {
  hash[option.value] = option.label;
  return hash;
}, {});

type FunctionsQueueType = { functions?: WombatFunctionType[]; groups?: WombatFunctionsGroupType[] }[];

export class WombatSvc {
  static FORM_NAME = "WOMBAT_MAPPER";
  static HEADER_ID = "WOMBAT_HEADER";
  static LINE_LIMIT = 20;

  static extractDataFromFile = async (
    file_attributes: FileType[],
  ): Promise<{ headers: string[]; mappedRows: FileRowDataType }> => {
    const currentFile: FileType = file_attributes[0];
    const splitterRegex = /\,(?![^,]+\")/;
    const res = await fetch(currentFile.uri);
    const file = await res.blob();
    let lines: string[] | string[][] = [];
    let headers: string[] = [];
    let rows: string[][] = [];
    const ext = currentFile.name.split(".").pop();
    switch (ext) {
      case "csv":
        lines = await this.extractLinesFromCSV(file);
        headers = this.getOnlyExistingHeaders(lines[0].split(splitterRegex));
        rows = lines.slice(1).map((l) => l.split(splitterRegex));
        break;
      case "xls":
      case "xlsx":
        lines = await this.extractLinesFromExcel(await file.arrayBuffer());
        headers = lines[0];
        rows = lines.slice(1);
        break;
      case "xml":
        const csv_data = await WombatApis.csvify_xml(currentFile.uri);
        if (csv_data && csv_data.length > 0) {
          lines = csv_data;
          headers = lines[0];
          rows = lines.slice(1, this.LINE_LIMIT);
        } else {
          return { headers: [], mappedRows: [] };
        }
    }
    headers = headers.map((h) => h.trim());
    const mappedRows: FileRowDataType = rows.map((row, row_number) => ({
      row_number: row_number.toString(),
      ...headers.reduce((hash: { [key: string]: string }, header, index) => {
        hash[header.trim()] = row[index];
        return hash;
      }, {}),
    }));
    return { headers, mappedRows };
  };

  static getDropdownField = (workflowType?: WombatWorkflowType, reverse = false) => {
    switch (workflowType) {
      case "file_integration_outbound":
        return reverse ? "name" : "value_mapped_from";
      default:
        return reverse ? "value_mapped_from" : "name";
    }
  };

  static getOnlyExistingHeaders = (headers: string[]) => {
    let lastExistingHeaderIndex: number = 0;
    headers.forEach((header, index) => {
      if (header) {
        lastExistingHeaderIndex = index;
      }
    });
    return headers.slice(0, lastExistingHeaderIndex + 1);
  };

  static extractLinesFromCSV = async (file: Blob, limit = this.LINE_LIMIT): Promise<string[]> => {
    const chunkSize = 1024;
    const fileSize = file.size;
    let texts: any[] = [];
    let lines: string[] = [];
    let slicedFile: Blob;
    let chunkStart: number = -chunkSize;
    let chunkEnd = 0;
    let currentText: string;
    while (lines.length < limit && chunkEnd <= fileSize) {
      chunkStart += chunkSize;
      chunkEnd += chunkSize;
      slicedFile = file.slice(chunkStart, chunkEnd);
      currentText = await slicedFile.text();
      if (!currentText) {
        continue;
      }
      texts.push(currentText);
      lines = texts.join("").split(/\r?\n/);
    }
    return lines.slice(0, limit);
  };

  static extractLinesFromExcel = async (data: any, limit = this.LINE_LIMIT): Promise<string[][]> => {
    let workbook = XLSX.read(data, { sheetRows: limit });
    return XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
  };

  static renewWombatFields = (wombatFields: WombatFieldType[], toHeaders: string[]): WombatFieldType[] => {
    const nameFieldHash = wombatFields.reduce(
      (result, wf) => {
        result[wf.name] = wf;
        return result;
      },
      {} as { [key: string]: WombatFieldType },
    );
    toHeaders.map((header, index) => {
      if (nameFieldHash[header]) {
        wombatFields[index] = nameFieldHash[header];
      } else {
        wombatFields[index] = { name: header, value_mapped_from: header, value_functions: [] };
      }
    });
    return wombatFields;
  };

  static setSelectedIndex = (index: number | undefined, dispatch: AppDispatch) => {
    dispatch(change(this.FORM_NAME, "current_index", index));
  };

  static updateMapTo = (w_index: number, value: string, dispatch: AppDispatch) => {
    dispatch(change(this.FORM_NAME, `form.config.wombat_fields[${w_index}].name`, value));
  };

  static updateForm = (data: WombatFormType, dispatch: AppDispatch) => {
    dispatch(change(this.FORM_NAME, "form", data));
  };

  static updateWombatFields = (data: WombatFieldType[], dispatch: AppDispatch) => {
    dispatch(change(this.FORM_NAME, "form.config.wombat_fields", data));
  };

  static updateMappingToListInbound = (data: WombatFormType, dispatch: AppDispatch) => {
    const selectionOptions = sortBy(
      data.config.wombat_fields.map((wf) => ({ label: wf.name, value: wf.name })),
      (item) => item.label,
    );
    dispatch(change(this.FORM_NAME, "mapping_to_list", selectionOptions));
  };

  static updateMappingToListOutbound = (data: string[], dispatch: AppDispatch) => {
    const selectionOptions = sortBy(
      data.map((h) => ({ label: h, value: h })),
      (item) => item.label,
    );
    dispatch(change(this.FORM_NAME, "mapping_to_list", selectionOptions));
  };

  static updateMappingFromList = (headers: string[], dispatch: AppDispatch) => {
    const selectionOptions = sortBy(
      headers.map((h) => ({ label: h, value: h })),
      (item) => item.label,
    );
    dispatch(change(this.FORM_NAME, "mapping_from_list", selectionOptions));
  };

  static getFormIndices = (field_name: string): { token: string; index: number }[] => {
    const splitTokens: string[] = field_name.split(".");
    const indexRegex = /\[(\d+)\]/;
    let hasIndex: RegExpMatchArray | null;
    let tokenKey: string;
    const indiceHashes: ({ token: string; index: number } | undefined)[] = splitTokens.map((key: string) => {
      hasIndex = key.match(indexRegex);
      if (hasIndex && hasIndex.length > 0) {
        tokenKey = key.replace(indexRegex, "");
        return { token: tokenKey, index: parseInt(hasIndex[1]) };
      }
    });
    return indiceHashes.filter((hash) => !!hash) as { token: string; index: number }[];
  };

  static assignParameterTypes = (valueFunction: WombatFunctionType, functionsQueue: FunctionsQueueType) => {
    valueFunction.parameters.forEach((parameter) => {
      if (parameter.parameter_type) {
        return;
      }
      if (parameter.value) {
        parameter.parameter_type = "value";
      } else if (parameter.value_mapped_from) {
        parameter.parameter_type = "value_mapped_from";
      }
    });
    delete valueFunction.source_types;
    if (valueFunction.precondition_functions) {
      functionsQueue.push({ groups: valueFunction.precondition_functions });
    }
  };

  static assignSourceTypes = (valueFunction: WombatFunctionType, functionsQueue: FunctionsQueueType) => {
    valueFunction.source_types = valueFunction.parameters.map((parameter) => {
      if (parameter.value) {
        return "value";
      } else if (parameter.value_mapped_from) {
        return "value_mapped_from";
      }
    });
    if (valueFunction.precondition_functions) {
      functionsQueue.push({ groups: valueFunction.precondition_functions });
    }
  };

  static getConformedHeaders = (
    originalMapToHeaders: string[],
    newMapFromHeaders: string[],
    mapFromToHash: { [key: string]: string },
  ): string[] => {
    const newMapToHeaders = [...originalMapToHeaders];
    const newMapToExistsHash = originalMapToHeaders.reduce(
      (result, h) => {
        result[h] = true;
        return result;
      },
      {} as { [key: string]: boolean },
    );
    newMapFromHeaders.forEach((h) => {
      if (!newMapToExistsHash[h] && !mapFromToHash[h]) {
        newMapToHeaders.push(h);
      }
    });
    return newMapToHeaders;
  };

  static prepareWombatPayload = (wombatFormData: WombatFormType, isNewData = false) => {
    let wombatFormValues = cloneDeep(wombatFormData);
    wombatFormValues.name = wombatFormValues.source_type;
    let wombatFields = wombatFormValues?.config?.wombat_fields;
    const parametersIterator: (valueFunction: WombatFunctionType, functionsQueue: FunctionsQueueType) => void =
      isNewData ? this.assignSourceTypes : this.assignParameterTypes;

    wombatFields &&
      wombatFields.forEach((wombat_field) => {
        if (!wombat_field.value_functions) {
          return;
        }
        let wombatFunctionsQueue: FunctionsQueueType = [{ functions: wombat_field.value_functions }];
        let queueIndex: number = 0;
        let queueFunctions: WombatFunctionType[] | undefined;
        let queueGroups: WombatFunctionsGroupType[] | undefined;
        while (queueIndex < wombatFunctionsQueue.length) {
          queueFunctions = wombatFunctionsQueue[queueIndex]["functions"];
          queueGroups = wombatFunctionsQueue[queueIndex]["groups"];
          queueFunctions &&
            queueFunctions.forEach((value_function: WombatFunctionType) => {
              parametersIterator(value_function, wombatFunctionsQueue);
            });
          queueGroups &&
            queueGroups.forEach((group) => {
              group["functions"].forEach((value_function) => {
                parametersIterator(value_function, wombatFunctionsQueue);
              });
            });
          queueIndex++;
        }
      });
    return wombatFormValues;
  };

  static getWombatFunctiontByIndices = (
    indiceHashes: { token: string; index: number }[],
    config: any,
  ): WombatFunctionType | undefined => {
    let currentObject: any = config;
    if (currentObject) {
      indiceHashes.forEach((hash) => {
        const { token, index } = hash;
        currentObject = currentObject[token];
        if (currentObject) {
          currentObject = currentObject[index];
        } else {
          return undefined;
        }
      });
    }
    return currentObject as WombatFunctionType;
  };

  static getSelectedWombatField = (state: RootState): SelectedWombatType => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    const index = wombatFormState?.current_index;
    let selectedWombat: SelectedWombatType = { index: undefined, wombat_field: undefined };
    if (index) {
      const wombat_field = wombatFormState?.form?.config?.wombat_fields[index];
      selectedWombat = { index, wombat_field };
    }
    return selectedWombat;
  };

  static getMappingToOptions = (state: RootState): { [key: string]: string }[] => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    return wombatFormState?.mapping_to_list || [];
  };

  static getAvailableMappingToOptions = (state: RootState): { [key: string]: string }[] => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    const wombatFields = wombatFormState?.form?.config?.wombat_fields || [];
    const mapToExistsHash = wombatFields.reduce(
      (result, wf) => {
        result[wf.name] = true;
        return result;
      },
      {} as { [key: string]: boolean },
    );
    return wombatFormState?.mapping_to_list?.filter((option) => !mapToExistsHash[option.value]) || [];
  };

  static getWombatWorkflowType = (state: RootState): WombatWorkflowType | undefined => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    return wombatFormState?.form?.workflow_type;
  };

  static getWombatMappingFromOptions = (state: RootState): { [key: string]: string }[] => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    return wombatFormState?.mapping_from_list || [];
  };

  static getWombatDestinationType = (state: RootState): string | undefined => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    return wombatFormState?.form?.destination_type;
  };

  static getWombatFunctionName = (state: RootState, field_name: string): WombatFunctionNamesType => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    const formIndices = this.getFormIndices(field_name);
    const wombatConfig = wombatFormState?.form?.config;
    const wombatFunction = this.getWombatFunctiontByIndices(formIndices, wombatConfig);
    return wombatFunction?.function_type ?? "";
  };

  static getWombatFunctionSourceType = (
    state: RootState,
    field_name: string,
    p_index: number,
  ): WombatFunctionSourceType | undefined => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    const formIndices = this.getFormIndices(field_name);
    const wombatConfig = wombatFormState?.form?.config;
    const wombatFunction = this.getWombatFunctiontByIndices(formIndices, wombatConfig);
    const wombatSourceTypes = wombatFunction?.source_types;
    return wombatSourceTypes && wombatSourceTypes[p_index];
  };

  static getWombatChosenFunctionNames = (state: RootState, w_index: number): WombatFunctionNamesType[] | [] => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    const wombatField = wombatFormState?.form?.config?.wombat_fields[w_index];
    return (
      wombatField?.value_functions?.map((wombat_function) => wombat_function.function_type).filter((name) => !!name) ||
      []
    );
  };

  static getWombatMapTo = (state: RootState, w_index: number): string => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    const wombatField = wombatFormState?.form?.config?.wombat_fields[w_index];
    return wombatField.name;
  };

  static getWombatMapFrom = (state: RootState, w_index: number): string => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    const wombatField = wombatFormState?.form?.config?.wombat_fields[w_index];
    return wombatField.value_mapped_from;
  };

  static hasWombatFields = (state: RootState): boolean => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    return !!wombatFormState?.form?.config?.wombat_fields;
  };

  static getWombatFunctionParameterValues = (
    state: RootState,
    field_name: string,
    p_index: number,
  ): [string | undefined, string | undefined] => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    const formIndices = this.getFormIndices(field_name);
    const wombatConfig = wombatFormState?.form?.config;
    const wombatFunction = this.getWombatFunctiontByIndices(formIndices, wombatConfig);
    const wombatParameter = wombatFunction?.parameters && wombatFunction.parameters[p_index];
    return (wombatParameter && [wombatParameter.value_mapped_from, wombatParameter.value]) || [undefined, undefined];
  };

  static getWombatFunctionHasAllParameterValues = (state: RootState, field_name: string): boolean => {
    const wombatFormState: WombatFormStateType = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    const formIndices = this.getFormIndices(field_name);
    const wombatConfig = wombatFormState?.form?.config;
    const wombatFunction = this.getWombatFunctiontByIndices(formIndices, wombatConfig);
    const wombatParameters = wombatFunction?.parameters;
    return !!(
      wombatParameters && wombatParameters.every((parameter) => parameter.value || parameter.value_mapped_from)
    );
  };

  static setReferenceWombatFormData = (state: RootState, ref: { current: WombatFormType | undefined }): true => {
    const wombatFormState = getFormValues(this.FORM_NAME)(state) as WombatFormStateType;
    ref.current = wombatFormState?.form;
    return true;
  };

  static flattenNestedValueMappedFroms = (wombatFields: WombatFieldType[]) => {
    let results: string[] = [];
    wombatFields.forEach((wf) => {
      wf.value_mapped_from && results.push(wf.value_mapped_from || "");
      wf.value_functions &&
        wf.value_functions.forEach((fn) => {
          fn.parameters.forEach((p) => {
            p.value_mapped_from && results.push(p.value_mapped_from);
          });
        });
    });
    return uniq(results).filter((item) => !!item);
  };
}
