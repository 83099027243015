import React, { useState } from "react";
import style from "./../companyProfile.module.css";
import useConfirmModal from "../../../modals/confirmModal/useConfirmModalHook";
import DocumentApis from "../../../../services/common/documents/documentApis";
import VendorDocumentLinkApis from "../../../../services/common/documentVendorLinks/vendorDocumentLinkApis";
import { VendorDocumentLinkTypes } from "../../../../services/common/documentVendorLinks/vendorDocumentLinkTypes";
import { getVendorDocumentLinks } from "../../../../reducers/vp/companyProfileReducer";
import { useDispatch } from "react-redux";
import VendorDocumentPreview from "../../../common/vendorDocumentPreview/vendorDocumentPreview";

type AdditionalDocumentType = {
  documentLink: VendorDocumentLinkTypes.ListItem;
};
const AdditionalDocument = ({ documentLink }: AdditionalDocumentType) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { createConfirmModal } = useConfirmModal();
  const dispatch = useDispatch();

  const openPreviewModal = () => {
    setShowPreviewModal(true);
  };

  const closePreviewModal = () => {
    setShowPreviewModal(false);
  };

  const confirmDeleteVendorDocumentLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    createConfirmModal({
      title: "Confirm",
      body: `You really want to delete Document Vendor Link ${documentLink.id} with file ${documentLink.document?.name}`,
      callBackData: documentLink,
      cancelCallBack: null,
      saveCallBack: deleteVendorDocumentLink,
    });
  };

  const deleteVendorDocumentLink = async (vendorDocumentLink: VendorDocumentLinkTypes.ListItem) => {
    try {
      await VendorDocumentLinkApis.deleteVendorDocumentLink(vendorDocumentLink.id);

      const documentId = vendorDocumentLink.document_id;
      //also deleted linked document
      if (documentId) {
        await DocumentApis.delete(documentId);
      }

      // refresh link to update document bubbles
      dispatch(getVendorDocumentLinks());
    } catch (error) {}
  };

  if (documentLink.document_requirement_id) {
    return null;
  } //we dont render "other" documents if they are attached to a requirement

  return (
    <>
      {showPreviewModal && documentLink && (
        <VendorDocumentPreview onHide={closePreviewModal} vendorDocumentLink={documentLink} />
      )}
      <div onClick={openPreviewModal} className={style.bubbleInitial + " d-flex align-items-center"}>
        <span className={style.documentName} title={documentLink.document?.name}>
          {documentLink.document?.name}
        </span>
        <button onClick={(e) => confirmDeleteVendorDocumentLink(e)} className="bg-transparent border-0">
          <i className={style.icon8 + " icon-cross-centered ml-2"}></i>
        </button>
      </div>
    </>
  );
};

export default AdditionalDocument;
