import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BreadcrumbTrail } from "../../../components/navigation/navigationLinks";
import PageTitle from "../../../components/page/pageTitle";
import CollapsePanel from "../../../components/panel/collapsePanel";
import Panel from "../../../components/panel/panel";
import Loader from "../../../components/spinners/loader";
import PicturePreview from "../../../components/widgets/fileUpload/picturePreview";
import RestApi from "../../../providers/restApi";
import { currencySymbolRenderer } from "../../../services/common/currencySymbolRendererService";
import { companyDateFormat } from "../../../services/general/dateSvc";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";

const restApiService = new RestApi();

const AccrualsDetail = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [accrual, setAccrual] = useState({});

  const getAccrual = () => {
    setLoaded(true);
    let accrualId = _.isPlainObject(props.match) && _.isPlainObject(props.match.params) && props.match.params.id;
    restApiService
      .get("vendor_accruals/" + accrualId)
      .then((result) => {
        setAccrual(result.data);
        setLoaded(false);
      })
      .catch((err) => {
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning);
        setLoaded(false);
      });
  };

  useEffect(() => {
    getAccrual();
  }, [props.match.params.id]);

  return (
    <Container fluid={true} className={"pt-4 pb-4"}>
      <BreadcrumbTrail to={"/ar/accruals"} pageName={"Accruals"} title={"Accrual Details"} />
      <Row className="mt-3">
        <Col className="d-flex align-items-center">
          <PageTitle title={accrual && accrual.number} status={accrual && accrual.status} />
        </Col>
      </Row>
      <Row>
        <Col>
          <hr className="full-border" />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm className="pr-sm-0">
          <dl className="row">
            <dt className="col-4 col-sm-4 detailKey text-sm-right">{props.t("details.customer")}</dt>
            <dd className="col-4 col-sm-8 detailValue">
              <Link
                className="primaryLinkColor"
                variant="primary"
                to={"/ar/purchasers/" + (accrual.purchaser && accrual.purchaser.id)}
              >
                {accrual.purchaser && accrual.purchaser.name}
              </Link>
            </dd>
            <dt className="col-4 col-sm-4 detailKey text-sm-right">{props.t("details.accrualType")}</dt>
            <dd className="col-4 col-sm-8 detailValue">{accrual.accrual_type}</dd>
            <dt className="col-4 col-sm-4 detailKey text-sm-right">{props.t("details.accrualDate")}</dt>
            <dd className="col-4 col-sm-8 detailValue">{companyDateFormat(accrual.date, props.currentUser)}</dd>
            <dt className="col-4 col-sm-4 detailKey text-sm-right">{props.t("details.servicePeriod")}</dt>
            <dd className="col-4 col-sm-8 detailValue">
              {companyDateFormat(accrual.service_start_date, props.currentUser)}
              {accrual.service_start_date ? " - " : ""}
              {companyDateFormat(accrual.service_end_date, props.currentUser)}
            </dd>
          </dl>
        </Col>
        <Col sm className="pr-sm-0">
          <dl className="row">
            <dt className="col-4 col-sm-4 detailKey text-sm-right">{props.t("details.amount")}</dt>
            <dd className="col-4 col-sm-8 detailValue">
              {currencySymbolRenderer(accrual.currency_code)}
              {accrual.amount}
            </dd>
            {_.isPlainObject(accrual.accrual_blast) && (
              <>
                <dt className="col-4 col-sm-4 detailKey text-sm-right">{props.t("details.accrualBlast")}</dt>
                <dd className="col-4 col-sm-8 detailValue">{accrual.accrual_blast.number}</dd>
              </>
            )}
            {_.isPlainObject(accrual.accrual_request) && (
              <>
                <dt className="col-4 col-sm-4 detailKey text-sm-right">{props.t("details.accrualRequest")}</dt>
                <dd className="col-4 col-sm-8 detailValue">{accrual.accrual_request.number}</dd>
              </>
            )}
            {_.isPlainObject(accrual.purchase_order) && (
              <>
                <dt className="col-4 col-sm-4 detailKey text-sm-right">{props.t("details.poNumbers")}</dt>
                <dd className="col-4 col-sm-8 detailValue">{accrual.purchase_order.number}</dd>
              </>
            )}
          </dl>
        </Col>
        <Col sm className="pr-sm-0">
          <dl className="row">
            <dt className="col-4 col-sm-4 detailKey text-sm-right">{props.t("details.description")}</dt>
            <dd className="col-4 col-sm-8 detailValue">{accrual.description}</dd>
          </dl>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Panel
            panelContent={{
              heading: props.t("details.invoices"),
              iconClass: "icon icon-invoice",
            }}
            iconStyle={{ marginTop: "0" }}
            cardBodyStyle={{ padding: "0px" }}
          >
            <Table hover size="sm">
              <thead>
                <tr>
                  <th>{props.t("details.number")}</th>
                  <th>{props.t("details.date")}</th>
                  <th>{props.t("details.amount")}</th>
                  <th>{props.t("details.amountApplied")}</th>
                </tr>
              </thead>
              <tbody>
                {_.isArray(accrual.accrual_matches) &&
                  accrual.accrual_matches.length > 0 &&
                  accrual.accrual_matches.map((accrualMatch, index) => {
                    return _.isPlainObject(accrualMatch.invoice) ? (
                      <tr key={index}>
                        <td>{accrualMatch.invoice.number}</td>
                        <td>{companyDateFormat(accrualMatch.invoice.date, props.currentUser)}</td>
                        <td>
                          {currencySymbolRenderer(accrualMatch.invoice.currency_code)}
                          {accrualMatch.invoice.amount}
                        </td>
                        <td>
                          {currencySymbolRenderer(accrual.currency_code)}
                          {accrualMatch.amount}
                        </td>
                      </tr>
                    ) : null;
                  })}
              </tbody>
            </Table>
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col>
          <CollapsePanel
            open={true}
            iconClass="icon icon-documents"
            heading={props.t("details.documents").toUpperCase()}
          >
            {accrual.assets &&
              accrual.assets.length > 0 &&
              accrual.assets.map((image) => (
                <PicturePreview
                  key={image.id}
                  file={{
                    name: image.asset_file_file_name,
                    preview: image.asset_expiring_thumb_url,
                    id: image.id,
                    url: image.asset_expiring_url,
                  }}
                />
              ))}
            {(!accrual.assets || accrual.assets.length < 1) && (
              <h5 className="text-center mb-0">- {props.t("warnings.noAttachments")} -</h5>
            )}
          </CollapsePanel>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.user,
  };
};

export default withTranslation()(connect(mapStateToProps)(AccrualsDetail));
