import { GridApi } from "ag-grid-community";
import TooltipRender from "components/toolTip/tooltipRender";
import moment from "moment";
import React from "react";

type ExportDataButtonPropType = {
  gridApi: GridApi;
  title: string;
  onClick?: () => void;
  csvName?: string;
};

const ExportDataButton = ({ gridApi, title, onClick, csvName }: ExportDataButtonPropType) => {
  const getDefaultFileName = () => {
    let now = moment(new Date());
    if (csvName) {
      return csvName;
    }
    return "dataExport-" + now.toLocaleString();
  };

  const onAllExport = () => {
    gridApi.exportDataAsCsv({
      skipColumnHeaders: false,
      skipRowGroups: false,
      skipPinnedTop: true,
      skipPinnedBottom: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: false,
      fileName: getDefaultFileName(),
    });
  };

  return (
    <TooltipRender title={<strong>{title}</strong>} placement="left">
      <button className="icon-normal icon-export mx-2 border-none" onClick={onClick ?? onAllExport}></button>
    </TooltipRender>
  );
};

export default ExportDataButton;
