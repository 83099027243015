import CurrencySymbolRenderer from "components/admin/commonUsed/currencySymbolRenderer";
import AccountPicker from "components/admin/pickers/reactHookFormPickers/accountPicker";
import BussinessUnitPicker from "components/admin/pickers/reactHookFormPickers/businessUnitPicker";
import DepartmentPicker from "components/admin/pickers/reactHookFormPickers/departmentPicker";
import LocationPicker from "components/admin/pickers/reactHookFormPickers/locationPicker";
import ProjectPicker from "components/admin/pickers/reactHookFormPickers/projectPicker";
import TaxCodePicker from "components/admin/pickers/reactHookFormPickers/taxCodePicker";
import Panel from "components/common/panel/panel";
import { InputField, TextAreaField } from "components/forms/hookFormFields";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTypedSelector } from "reducers";
import adminCommonSvc from "services/admin/commonSvc";
import invoiceCommonSvc from "services/admin/invoices/invoiceCommonSvc";
import { InvoiceType } from "services/admin/invoices/invoiceType";
import { useInvoiceCommonSvc } from "services/admin/invoices/useInvoiceCommonSvc";
import { IUser } from "services/common/user/userTypes";
import styles from "../inlineFieldsSection.module.css";

const InvoiceDebitLineSection = () => {
  const { control, getValues, setValue, trigger } = useFormContext<any>();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const { fields, append, update } = useFieldArray<any>({
    control,
    name: "debit_entries_attributes",
    keyName: "_id" as "id",
  });

  const debitEntries = fields as unknown as InvoiceType.TInvoiceExpenseLineEntry[];
  const invoiceCommonSvcHook = useInvoiceCommonSvc();

  // Calculate the number of columns dynamically
  const dynamicColumnCount = useMemo(() => {
    let columnCount = 5; // Actions, Account, Sub Amount, Memo, Amount (static columns)
    if (currentUser.company.has_taxes) columnCount += 2; // Tax Code, Tax
    if (invoiceCommonSvcHook.enabledExpenseTaxOrRebate()) columnCount += 1; // Sub Amount
    if (currentUser.company.has_departments && !currentUser.company.invoice?.expenses?.department?.is_hide)
      columnCount += 1; // Department
    if (currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location) columnCount += 1; // Location
    if (currentUser.company.has_business_units && !currentUser.company.invoice_account_hide_business_unit)
      columnCount += 1; // Business Unit
    if (currentUser.company.has_projects) {
      columnCount += 1; // Project
    }
    return columnCount;
  }, [currentUser, invoiceCommonSvcHook]);

  const addNewRow = () => {
    let obj = { allowEdit: true };
    append(obj);
  };

  const allowEditRow = async (entry: InvoiceType.TInvoiceExpenseLineEntry, index: number) => {
    let isValidate = true;
    if (entry.allowEdit) {
      isValidate = await trigger(`debit_entries_attributes.${index}`);
    }
    if (isValidate) {
      const updatedItem = {
        ...getValues(`debit_entries_attributes[${index}]`),
        id: entry.id,
        allowEdit: !entry.allowEdit,
      }; // setValue(`debit_entries_attributes[${index}]`, updatedItem);
      update(index, updatedItem);
    }
  };

  const destroyExpense = (item: any, index: number) => {
    let updatedEntry = { ...getValues(`debit_entries_attributes[${index}]`), _destroy: 1 };

    // updateInvoiceDebitEntriesIfDestroy(updatedItem);
    // setValue(`invoice_items_attributes.${index}`, updatedItem);
    update(index, updatedEntry);
  };

  const isItemDebitLine = (index: number) => {
    let debitEntry = getValues(`debit_entries_attributes.${index}`);
    return debitEntry?.item_line_id || debitEntry?.product_item_id;
  };

  const calculateExpenseLinemAmount = (accountEntry: InvoiceType.TInvoiceExpenseLineEntry) => {
    accountEntry.tax = _.isNumber(Number(accountEntry.tax)) ? Number(accountEntry.tax) : 0;
    accountEntry.sub_amount = _.isNumber(Number(accountEntry.sub_amount)) ? Number(accountEntry.sub_amount) : 0;
    let tax = accountEntry.tax;
    let subAmount = accountEntry.sub_amount;
    accountEntry.amount = adminCommonSvc.roundUpAmount(subAmount + tax, null, currentUser);
    return accountEntry;
  };

  const onTaxAmountChange = ({ tax, index }: { tax: number; index: number }) => {
    const debitEntry: InvoiceType.TInvoiceExpenseLineEntry = getValues(`debit_entries_attributes.${index}`);
    debitEntry.tax = tax;
    if (debitEntry) {
      let updatedEntry = calculateExpenseLinemAmount(debitEntry);
      setValue(`debit_entries_attributes.${index}.amount`, updatedEntry.amount);
      setValue(`debit_entries_attributes.${index}`, updatedEntry);
    }
  };

  // TODO: this should be done form amount component
  // const updateDiscountAmount = () => {
  //   const invoice = getValues();
  //   const discAmount = adminCommonSvc.calculateDiscountAmt(invoice, currentUser);
  //   setValue("amount_disc", discAmount);
  // };

  // const updateDiscountFromAccount = () => {
  //   updateDiscountAmount();
  // };

  const calculateExpensesTax = ({ index }: { index: number }) => {
    const debitEntry: InvoiceType.TInvoiceExpenseLineEntry = getValues(`debit_entries_attributes.${index}`);
    if (debitEntry) {
      let amount = 0; // tax amount
      if (debitEntry?.tax_code && debitEntry?.tax_code?.rate && debitEntry?.tax_code?.rate > 0) {
        amount = ((debitEntry.sub_amount ? debitEntry.sub_amount : 0) * debitEntry.tax_code.rate) / 100;
      } else {
        amount = 0;
      }
      debitEntry.tax = Number(adminCommonSvc.roundUpAmount(amount, null, currentUser));
      let updatedEntry = calculateExpenseLinemAmount(debitEntry);
      setValue(`debit_entries_attributes.${index}`, updatedEntry);
    }
  };

  const manageSubAmount = ({ subAmount, index }: { subAmount: number; index: number }) => {
    const debitEntry: InvoiceType.TInvoiceExpenseLineEntry = getValues(`debit_entries_attributes.${index}`);
    debitEntry.sub_amount = subAmount;
    debitEntry.actual_sub_amount = debitEntry.sub_amount;
    calculateExpensesTax({ index });
  };

  const removeAmortizationSchedule = (data: InvoiceType.TInvoiceExpenseLineEntry) => {
    delete data.amortization;
    delete data.amortization_id;
    delete data.amortization_name;
    delete data.amortization_schedule_name;
    delete data.start_date;
    delete data.end_date;
  };

  const addDuplicateRow = (item: InvoiceType.TInvoiceExpenseLineEntry, index: number) => {
    const debitEntry: InvoiceType.TInvoiceExpenseLineEntry = { ...getValues(`debit_entries_attributes.${index}`) };
    delete debitEntry.id;
    debitEntry.rebate_id = undefined;
    debitEntry.is_expense_received = undefined;
    debitEntry.is_matched = undefined;
    debitEntry.isDuplicateItem = true;
    debitEntry.allowEdit = true;
    removeAmortizationSchedule(debitEntry);
    append(debitEntry);
  };

  const isProjectRequired = useCallback(
    ({ index }: { index: number }) => {
      const debitEntry: InvoiceType.TInvoiceExpenseLineEntry = getValues(`debit_entries_attributes.${index}`);
      return invoiceCommonSvc.isProjectRequired(debitEntry, currentUser);
    },
    [currentUser, getValues],
  );

  return (
    <Panel
      header={
        <span>
          <i className="icon px-m-0 icon-bank"></i>Debit Entries
        </span>
      }
    >
      <Row>
        <Col sm="12" className="overflow-auto custom-overflow">
          <Table bordered size="sm">
            <thead>
              <tr className={styles.tableHead}>
                <td>Actions</td>
                <td>Account</td>
                {currentUser.company.has_taxes && <td>Tax Code</td>}
                {currentUser.company.has_taxes && <td>Tax</td>}
                {invoiceCommonSvcHook.enabledExpenseTaxOrRebate() && <td>Sub Amount</td>}

                <td>Memo</td>
                {currentUser.company.has_departments && !currentUser.company.invoice?.expenses?.department?.is_hide && (
                  <td>Department</td>
                )}
                {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                  <td>Location</td>
                )}
                {currentUser.company.has_business_units && !currentUser.company.invoice_account_hide_business_unit && (
                  <td>Business Unit</td>
                )}
                {currentUser.company.has_projects && <td>Project</td>}
                <td>Amount</td>
              </tr>
            </thead>
            <tbody>
              {debitEntries.map((entry, index) => {
                return (
                  _.isPlainObject(entry) &&
                  entry._destroy !== 1 &&
                  !isItemDebitLine(index) && (
                    <tr key={entry._id || index} className={styles.tableBody + " uploadQueueItemLineRow"}>
                      <td className={styles.actions}>
                        <div className={styles.actions}>
                          <i
                            className={`icon ${entry.allowEdit ? "icon-true-tick" : "editIcon"} `}
                            onClick={() => {
                              allowEditRow(entry, index);
                            }}
                          ></i>

                          <i className="icon icon-add-duplicate" onClick={() => addDuplicateRow(entry, index)}></i>
                          <i className="icon delete" onClick={() => destroyExpense(entry, index)}></i>
                        </div>
                      </td>
                      <td className="px-pt-10">
                        {entry.allowEdit ? (
                          <>
                            <AccountPicker
                              name={`debit_entries_attributes.${index}.account_id`}
                              accountGroupName="INVOICE_DEBIT_GROUPS"
                              modelData={`debit_entries_attributes.${index}`}
                              parentObj={""} //header
                              required
                              containerClassName={styles.minWidth175}
                            />
                          </>
                        ) : (
                          <>{`${entry.account_number ? entry.account_number : (entry?.account?.number ?? "")} - ${entry.account_name ? entry.account_name : (entry?.account?.name ?? "")}`}</>
                        )}
                      </td>
                      {currentUser.company.has_taxes && (
                        <td className="px-pt-10">
                          {entry.allowEdit ? (
                            <>
                              <TaxCodePicker
                                name={`debit_entries_attributes.${index}.tax_id`}
                                modelData={`debit_entries_attributes.${index}`}
                                callBack={(tax) => calculateExpensesTax({ index })}
                                required={currentUser?.company?.invoice?.is_tax_code_required_on_line_level}
                                containerClassName={styles.minWidth175}
                              />
                            </>
                          ) : (
                            entry.tax_code?.code
                          )}
                        </td>
                      )}

                      {currentUser.company.has_taxes && (
                        <td className="px-pt-10">
                          {entry.allowEdit ? (
                            <>
                              <InputField
                                name={`debit_entries_attributes.${index}.tax`}
                                type="number"
                                readOnly={!currentUser.company.enable_to_enter_tax_amount}
                                onChange={(e) => {
                                  onTaxAmountChange({ tax: Number(e.target.value), index });
                                }}
                                containerClassName={styles.minWidth100}
                              />
                            </>
                          ) : (
                            <CurrencySymbolRenderer name="currency_code" amount={Number(entry.tax) || 0} />
                          )}
                        </td>
                      )}

                      {invoiceCommonSvcHook.enabledExpenseTaxOrRebate() && (
                        <td className="px-pt-10">
                          {entry.allowEdit ? (
                            <>
                              <InputField
                                name={`debit_entries_attributes.${index}.sub_amount`}
                                type="number"
                                onChange={(e) => {
                                  manageSubAmount({ subAmount: Number(e.target.value), index });
                                }}
                                required
                                containerClassName={styles.minWidth100}
                              />
                            </>
                          ) : (
                            <CurrencySymbolRenderer name="currency_code" amount={entry.sub_amount || 0} />
                          )}
                        </td>
                      )}

                      <td className="px-pt-10">
                        {entry.allowEdit ? (
                          <>
                            <TextAreaField
                              name={`debit_entries_attributes.${index}.memo`}
                              containerClassName={styles.minWidth175}
                              required={currentUser.company?.invoice?.expenses?.memo?.is_required}
                            />
                          </>
                        ) : (
                          entry.memo
                        )}
                      </td>

                      {currentUser.company.has_departments &&
                        !currentUser.company.invoice?.expenses?.department?.is_hide && (
                          <td>
                            {" "}
                            {entry.allowEdit ? (
                              <>
                                <DepartmentPicker
                                  name={`debit_entries_attributes.${index}.department_id`}
                                  modelData={`debit_entries_attributes.${index}`}
                                  // parentObj={""}
                                  containerClassName={styles.minWidth175}
                                  required={currentUser?.company?.invoice?.expenses?.department?.is_required}
                                />
                              </>
                            ) : (
                              (entry.department?.name ?? entry.department_name)
                            )}
                          </td>
                        )}

                      {currentUser.company.has_locations && !currentUser.company.invoice_account_hide_location && (
                        <td>
                          {entry.allowEdit ? (
                            <>
                              <LocationPicker
                                name={`debit_entries_attributes.${index}.location`}
                                containerClassName={styles.minWidth175}
                                modelData={`debit_entries_attributes.${index}`}
                                required={currentUser?.company?.invoice_expense_location_required}
                              />
                            </>
                          ) : (
                            (entry.location?.name ?? entry.location_name)
                          )}
                        </td>
                      )}
                      {currentUser.company.has_business_units &&
                        !currentUser.company.invoice_account_hide_business_unit && (
                          <td>
                            {entry.allowEdit ? (
                              <>
                                <BussinessUnitPicker
                                  name={`debit_entries_attributes.${index}.business_unit`}
                                  containerClassName={styles.minWidth175}
                                  modelData={`debit_entries_attributes.${index}`}
                                  required={currentUser?.company?.invoice?.expenses?.business_unit?.is_required}
                                />
                              </>
                            ) : (
                              (entry.business_unit?.name ?? entry.business_unit_name)
                            )}
                          </td>
                        )}
                      {currentUser.company.has_projects && (
                        <td>
                          {entry.allowEdit ? (
                            <>
                              <ProjectPicker
                                name={`debit_entries_attributes.${index}.project_id`}
                                modelData={`debit_entries_attributes.${index}`}
                                parentObj={""}
                                containerClassName={styles.minWidth175}
                                required={isProjectRequired({ index })}
                              />
                            </>
                          ) : (
                            (entry.project?.name ?? entry.project_name)
                          )}
                        </td>
                      )}

                      <td>
                        {entry.allowEdit ? (
                          <InputField
                            id="debit_entry_amount"
                            name={`debit_entries_attributes.${index}.amount`}
                            type="number"
                            disabled
                            containerClassName={styles.minWidth175}
                          />
                        ) : (
                          <CurrencySymbolRenderer name="currency_code" amount={Number(entry.amount) || 0} />
                        )}
                      </td>
                    </tr>
                  )
                );
              })}
              {debitEntries.length < 1 && (
                <tr>
                  <td colSpan={dynamicColumnCount} className={styles.noRecords}>
                    Record not found!
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr className={styles.tableFooter}>
                <td colSpan={dynamicColumnCount - 2}>Total</td>
                <td className="text-right">
                  <CurrencySymbolRenderer name="currency_code" amount={invoiceCommonSvcHook.getAccountsTotal() || 0} />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="light" className={styles.btnAddNewEntry} onClick={() => addNewRow()}>
            <i className="icon icon-add-black"></i>Add Debit Line
          </Button>
        </Col>
      </Row>
    </Panel>
  );
};

export default InvoiceDebitLineSection;
