// imports
import { SET_CALENDER_EVENTS, SET_DATE_FORM } from "../../actions/actionTypes";

export const CALENDAR_EVENT_FORM = "calendarEventForm";

// initial state
const initialState = {
  calendarEvents: [],
  calendarEventForm: {
    form: {},
  },
};

// reducer
const calendarEventerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CALENDER_EVENTS:
      return { ...state, calendarEvents: payload };
    case SET_DATE_FORM:
      return { ...state, calendarEventForm: { form: payload } };
    // Do something here based on the different types of actions
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
};
export default calendarEventerReducer;
// action creators

// setCalenderEvents
export const setCalenderEvents = (value) => (dispatch, getState) => {
  dispatch({ type: SET_CALENDER_EVENTS, payload: value });
};

// setDataform
export const setEventDateForm = (dateFormData) => (dispatch, getState) => {
  dispatch({ type: SET_DATE_FORM, payload: dateFormData });
};

export const onCalendarEventFormUnmount = () => (dispatch, getState) => {
  dispatch(setEventDateForm(initialState.calendarEventForm.form));
};

export const addCalenderEvents = (newCalendarEvent) => (dispatch, getState) => {
  const calendarEvents = getState().calendarEventerRed.calendarEvents;
  dispatch(setCalenderEvents([...calendarEvents, newCalendarEvent]));
};

export const editCalenderEvents = (editedCalendarEvent) => (dispatch, getState) => {
  const calendarEvents = getState().calendarEventerRed.calendarEvents;
  dispatch(
    setCalenderEvents(
      calendarEvents.map((calEvent) => {
        if (calEvent.id === editedCalendarEvent.id) {
          return editedCalendarEvent;
        }
        return calEvent;
      }),
    ),
  );
};

export const removeCalenderEvents = (id) => (dispatch, getState) => {
  const calendarEvents = getState().calendarEventerRed.calendarEvents;
  dispatch(setCalenderEvents(calendarEvents.filter((calEvent) => calEvent.id !== id)));
};
