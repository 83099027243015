import React, { useState, useCallback, useEffect, useRef } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import style from "./fileUploaderNonModal.module.css";
import Dropzone from "react-dropzone";
import { uniqBy } from "lodash";

type FileUploaderNonModalPropsType = {
  files?: { uri: string; name: string }[];
  onFilesUpdated: (filesAttributes: any) => Promise<void> | void;
  maxFiles?: number;
  maxSize?: number; // in bytes
  className?: string;
  containerClassName?: string;
};

type LocalFileAttributesType = { uri: string; name: string };

export const FileUploaderNonModal = ({
  files,
  onFilesUpdated,
  maxFiles,
  maxSize,
  className,
  containerClassName,
}: FileUploaderNonModalPropsType) => {
  const [filesAttributes, setFilesAttributes] = useState<LocalFileAttributesType[]>([]); //file
  const filesAttributesRef = useRef<LocalFileAttributesType[]>([]);

  const setFiles = useCallback(
    (acceptedFiles: File[]) => {
      const newFileAttributes = acceptedFiles.map((f) => ({ uri: URL.createObjectURL(f as Blob), name: f.name }));
      filesAttributesRef.current = uniqBy([...filesAttributesRef.current, ...newFileAttributes], "name");
      if (maxFiles) {
        filesAttributesRef.current = filesAttributesRef.current.slice(-maxFiles);
      }
      onFilesUpdated(filesAttributesRef.current);
      setFilesAttributes([...filesAttributesRef.current]);
    },
    [maxFiles],
  );

  const removeFile = (file: LocalFileAttributesType) => {
    filesAttributesRef.current = filesAttributesRef.current.filter((item: any) => item.name !== file.name);
    onFilesUpdated(filesAttributesRef.current);
    setFilesAttributes([...filesAttributesRef.current]);
  };

  useEffect(() => {
    if (files?.length) {
      filesAttributesRef.current = files;
      setFilesAttributes([...files]);
    }
  }, []);

  return (
    <Container className={`px-0 ${containerClassName ?? ""}`}>
      <Row className={`mx-0 py-3 ${className ?? ""} ${style.dropZone}`} style={{ border: "dashed" }}>
        <Col className="px-0 d-flex justify-content-center align-items-center">
          <Dropzone onDrop={setFiles} maxFiles={maxFiles} maxSize={maxSize}>
            {({ getRootProps, getInputProps }) => (
              <Col className="p-0 h-100 d-flex flex-column justify-content-center" {...getRootProps()}>
                <Row>
                  <Col sm className="d-flex justify-content-center">
                    <p className={`${style.dropFileLabel} font-weight-bold`}>Drag and drop files here</p>
                  </Col>
                </Row>
                <Row>
                  <Col sm className="d-flex justify-content-center">
                    <p className={`${style.dropFileLabel} font-weight-bold`}>or</p>
                  </Col>
                </Row>
                <Row>
                  <Col sm className="d-flex justify-content-center mt-2">
                    <input {...getInputProps()} />
                    <Button className={`btn px-4 ${style.bgButton}`} type="button">
                      Browse Files
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
          </Dropzone>
        </Col>
      </Row>
      <Row className="mx-0">
        <Col>
          {filesAttributes.length
            ? filesAttributes.map((file, index) => (
                <Row className="mt-2" key={index}>
                  <Col sm={11}>{file.name}</Col>
                  <Col sm={1} className={style.btnRemoveFile}>
                    <a onClick={() => removeFile(file)}>X</a>
                  </Col>
                </Row>
              ))
            : null}
        </Col>
      </Row>
    </Container>
  );
};
