const INITIAL_STATE = {
  allValidations: {},
  validatePaymentType: "ach",
  validateAddressLine: "",
  validateCity: "",
  validateState: "",
  validateZipcode: "",
  validateCountryCode: {},
  validateRouting: "",
  validateAccountName: "",
  validateAccountNumber: "",
  validateAccountType: "",
  validateBankName: "",
  validateBSB: "",
  validateIFSC: "",
  validatePhoneNumber: "",
  validateAccountTaxNumber: "",
  validateInstitution: "",
  validateTransit: "",
  validateCardNumber: "",
  validateBicCode: "",
  validateCNPJ: "",
  validateSortCode: "",
  validatePAN: "",
  validateAccountNameEnglish: "",
  validateIDNumber: "",
  validateStoreFront: "",
  validateIncorporationNumber: "",
  validateBankCode: "",
  validateBikCode: "",
  validateVoCode: "",
  validateInnTaxCode: "",
  validateSwiftCode: "",
  validateIbanCode: "",
  validateCountryLabel: "",
  validateCpf: "",
  validatePayeetype: "",
  validateSuffix: "",
  validateId: "",
  validateBranchCode: "",
  validateContactFirstName: "",
  validateContactLastName: "",
  validateContactEmail: "",
  validateContactPhone: "",
  validationMessage: "",
  validateTwBSBCode: "",
  validateTwIbanCode: "",
  validationTwBSBMessage: "",
  validationTwIBANMessage: "",
  validateBusinessNumber: "",
  validateTwBicCode: "",
  validationTwBicCodeMessage: "",
  validateConfirmAccountNumber: "",
  validateIntrmyName: "",
  validateIntrmyCountry: "",
  validateIntrmyNumber: "",
  validateIntrmyBic: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHECK_VALIDATIONS":
      let selectedFieldObj = action.allValidations;

      switch (selectedFieldObj.field) {
        case "account_name":
          if (checkIsInputValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateAccountName: true,
            };
          } else {
            return {
              ...state,
              validateAccountName: false,
            };
          }
        case "bank_name":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateBankName: true,
            };
          } else {
            return {
              ...state,
              validateBankName: false,
            };
          }
        case "account_type":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateAccountType: true,
            };
          } else {
            return {
              ...state,
              validateAccountType: false,
            };
          }
        case "account_number":
          if (checkIsInputValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateAccountNumber: true,
            };
          } else {
            return {
              ...state,
              validateAccountNumber: false,
            };
          }
        case "bic":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateBicCode: true,
            };
          } else {
            return {
              ...state,
              validateBicCode: false,
            };
          }
        case "iban":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateIbanCode: true,
            };
          } else {
            return {
              ...state,
              validateIbanCode: false,
            };
          }
        case "bsb_code":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateBSB: true,
            };
          } else {
            return {
              ...state,
              validateBSB: false,
            };
          }
        case "branchCode":
          if (checkIsInputValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateBranchCode: true,
            };
          } else {
            return {
              ...state,
              validateBranchCode: false,
            };
          }
        case "bankCode":
          if (checkIsInputValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateBankCode: true,
            };
          } else {
            return {
              ...state,
              validateBankCode: false,
            };
          }
        case "account_tax_number":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateAccountTaxNumber: true,
            };
          } else {
            return {
              ...state,
              validateAccountTaxNumber: false,
            };
          }
        case "sort_code":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateSortCode: true,
            };
          } else {
            return {
              ...state,
              validateSortCode: false,
            };
          }
        case "ifsc_code":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateIFSC: true,
            };
          } else {
            return {
              ...state,
              validateIFSC: false,
            };
          }
        case "account_name_english":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateAccountNameEnglish: true,
            };
          } else {
            return {
              ...state,
              validateAccountNameEnglish: false,
            };
          }

        case "storefront_url":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateStoreFront: true,
            };
          } else {
            return {
              ...state,
              validateStoreFront: false,
            };
          }
        case "id_number":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateIDNumber: true,
            };
          } else {
            return {
              ...state,
              validateIDNumber: false,
            };
          }
        case "suffix":
          if (checkIsInputValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateSuffix: true,
            };
          } else {
            return {
              ...state,
              validateSuffix: false,
            };
          }

        case "incorporation_number":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateIncorporationNumber: true,
            };
          } else {
            return {
              ...state,
              validateIncorporationNumber: false,
            };
          }

        case "contact_first_name":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateContactFirstName: true,
            };
          } else {
            return {
              ...state,
              validateContactFirstName: false,
            };
          }
        case "contact_last_name":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateContactLastName: true,
            };
          } else {
            return {
              ...state,
              validateContactLastName: false,
            };
          }
        case "business_number":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateBusinessNumber: true,
            };
          } else {
            return {
              ...state,
              validateBusinessNumber: false,
            };
          }
        case "contact_email":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateContactEmail: true,
            };
          } else {
            return {
              ...state,
              validateContactEmail: false,
            };
          }
        case "phoneNumber":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validatePhoneNumber: true,
            };
          } else {
            return {
              ...state,
              validatePhoneNumber: false,
            };
          }
        case "cpf":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateCpf: true,
            };
          } else {
            return {
              ...state,
              validateCpf: false,
            };
          }
        case "address1":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateAddressLine: true,
            };
          } else {
            return {
              ...state,
              validateAddressLine: false,
            };
          }
        case "city":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateCity: true,
            };
          } else {
            return {
              ...state,
              validateCity: false,
            };
          }
        case "state":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateState: true,
            };
          } else {
            return {
              ...state,
              validateState: false,
            };
          }
        case "zipcode":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateZipcode: true,
            };
          } else {
            return {
              ...state,
              validateZipcode: false,
            };
          }
        case "transit_number":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateTransit: true,
            };
          } else {
            return {
              ...state,
              validateTransit: false,
            };
          }
        case "cardNumber":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateCardNumber: true,
            };
          } else {
            return {
              ...state,
              validateCardNumber: false,
            };
          }
        case "swiftCode":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateSwiftCode: true,
            };
          } else {
            return {
              ...state,
              validateSwiftCode: false,
            };
          }
        case "intrmy_name":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateIntrmyName: true,
            };
          } else {
            return {
              ...state,
              validateIntrmyName: false,
            };
          }
        case "intrmy_country":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateIntrmyCountry: true,
            };
          } else {
            return {
              ...state,
              validateIntrmyCountry: false,
            };
          }
        case "intrmy_number":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateIntrmyNumber: true,
            };
          } else {
            return {
              ...state,
              validateIntrmNuber: false,
            };
          }
        case "intrmy_bic":
          if (checkIsValid(action, selectedFieldObj)) {
            return {
              ...state,
              validateIntrmyBic: true,
            };
          } else {
            return {
              ...state,
              validateIntrmBic: false,
            };
          }

        default:
          return false;
      }

    case "CLEAR_VALIDATIONS":
      return {
        ...state,
        validateAddressLine: "",
        validateCity: "",
        validateState: "",
        validateZipcode: "",
        validateCountryCode: {},
        validateRouting: "",
        validateAccountName: "",
        validateAccountNumber: "",
        validateAccountType: "",
        validateBankName: "",
        validateBSB: "",
        validateIFSC: "",
        validatePhoneNumber: "",
        validateAccountTaxNumber: "",
        validateInstitution: "",
        validateTransit: "",
        validateCardNumber: "",
        validateBicCode: "",
        validateCNPJ: "",
        validateSortCode: "",
        validatePAN: "",
        validateAccountNameEnglish: "",
        validateIDNumber: "",
        validateStoreFront: "",
        validateIncorporationNumber: "",
        validateBankCode: "",
        validateSwiftCode: "",
        validateIbanCode: "",
        validateCountryLabel: "",
        validateCpf: "",
        validatePayeetype: "",
        validateSuffix: "",
        validateId: "",
        validateBranchCode: "",
        validateContactFirstName: "",
        validateContactLastName: "",
        validateContactEmail: "",
        validateContactPhone: "",
        validateTwIbanCode: "",
        validationTwIBANMessage: "",
        validateTwBSBCode: "",
        validationTwBSBMessage: "",
        validateBusinessNumber: "",
        validateTwBicCode: "",
        validationTwBicCodeMessage: "",
        validateConfirmAccountNumber: "",
      };
    case "CHANGE_IBAN_VALIDATIONS":
      return {
        ...state,
        validateTwIbanCode: action.payload.status,
        validationTwIBANMessage: action.payload.message,
      };
    case "CHANGE_BSB_VALIDATIONS":
      return {
        ...state,
        validateTwBSBCode: action.payload.status,
        validationTwBSBMessage: action.payload.message,
      };
    case "CHANGE_INSTITUTE_VALIDATIONS":
      return {
        ...state,
        validateTwBicCode: action.payload.status,
        validationTwBicCodeMessage: action.payload.message,
      };
    case "CHANGE_TW_BIC_CODE":
      return {
        ...state,
        validateTwBicCode: action.payload.status,
        validationTwBicCodeMessage: action.payload.message,
      };
    case "CHANGE_ROUTING_MSG":
      return {
        ...state,
        validateRouting: action.payload.status,
        validationRoutingMsg: action.payload.message,
      };
    case "CHANGE_CONFIRM_ACCOUNT_NUMBER":
      return {
        ...state,
        validateConfirmAccountNumber: checkMatch(action.payload, action.account_number),
      };
    default:
      return state;
  }
};

function checkIsValid(action, selectedFieldObj) {
  // do not validate field if field is not required and has no value
  // if required field is presnt then it should be false
  if (
    action.payload.length === 0 &&
    (!selectedFieldObj.validate.hasOwnProperty("required") ||
      (selectedFieldObj.validate.hasOwnProperty("required") && selectedFieldObj.validate.required !== true))
  ) {
    return true;
  }

  return (
    (selectedFieldObj.validate.hasOwnProperty("required") ? isRequired(action, selectedFieldObj) : true) &&
    (selectedFieldObj.validate.hasOwnProperty("minlength") ? isMinlength(action, selectedFieldObj) : true) &&
    (selectedFieldObj.validate.hasOwnProperty("maxlength") ? isMaxlength(action, selectedFieldObj) : true) &&
    (selectedFieldObj.validate.hasOwnProperty("pattern") ? isMatch(action, selectedFieldObj) : true)
  );
}

function checkIsInputValid(action, selectedFieldObj) {
  return (
    (selectedFieldObj.validate.hasOwnProperty("required") ? isRequired(action, selectedFieldObj) : true) &&
    (selectedFieldObj.validate.hasOwnProperty("minlength") ? isMinlength(action, selectedFieldObj) : true) &&
    (selectedFieldObj.validate.hasOwnProperty("maxlength") ? isMaxlength(action, selectedFieldObj) : true) &&
    (selectedFieldObj.validate.hasOwnProperty("pattern") ? isValidate(action, selectedFieldObj) : true)
  );
}

function isRequired(action, selectedFieldObj) {
  if (action.payload.length > 0) {
    return true;
  } else {
    generateError(selectedFieldObj);
    return false;
  }
}
function isMinlength(action, selectedFieldObj) {
  if (action.payload.length >= selectedFieldObj.validate.minlength) {
    return true;
  } else {
    generateError(selectedFieldObj);
    return false;
  }
}
function isMaxlength(action, selectedFieldObj) {
  if (action.payload.length <= selectedFieldObj.validate.maxlength) {
    return true;
  } else {
    generateError(selectedFieldObj);
    return false;
  }
}
function isMatch(action, selectedFieldObj) {
  if (action.payload.match(selectedFieldObj.validate.pattern)) {
    return true;
  } else {
    generateError(selectedFieldObj);
    return false;
  }
}

function isValidate(action, selectedFieldObj) {
  let regEx = new RegExp(selectedFieldObj.validate.pattern);
  if (regEx.test(action.payload)) {
    return action.payload.replace(/^\s+|\s+$/gm, "");
  } else {
    generateError(selectedFieldObj);
    return false;
  }
}

function requiredError(selectedFieldObj) {
  if (selectedFieldObj.validate.required) {
    let Error = selectedFieldObj.field + " is required,";
    return Error;
  } else {
    return "";
  }
}

function minlengthError(selectedFieldObj) {
  if (selectedFieldObj.validate.minlength) {
    let Error = "Minimum length should be " + selectedFieldObj.validate.minlength + ",";
    return Error;
  } else {
    return "";
  }
}

function maxlengthError(selectedFieldObj) {
  if (selectedFieldObj.validate.maxlength) {
    let Error = "Maximum length should be " + selectedFieldObj.validate.maxlength + ",";
    return Error;
  } else {
    return "";
  }
}
function patternError(selectedFieldObj) {
  if (selectedFieldObj.validate.pattern) {
    let Error = "Pattern should be " + new RegExp(selectedFieldObj.validate.pattern);
    return Error;
  } else {
    return "";
  }
}
function generateError(selectedFieldObj) {
  console.log(
    minlengthError(selectedFieldObj) +
      maxlengthError(selectedFieldObj) +
      patternError(selectedFieldObj) +
      requiredError(selectedFieldObj),
  );
}

function checkMatch(payload, account_number) {
  if (payload) {
    if (payload === account_number) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
