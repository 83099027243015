import _ from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SubmissionError } from "redux-form";
import { restApiService } from "../../../providers/restApi";
import UserApis from "../../../services/common/user/userApis";
import { AddUserPayloadType } from "../../../services/common/user/userTypes";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { createCompleteError } from "../../../services/general/reduxFormSvc";
import UserForm, { UserFormDataType } from "./formUser";
import style from "./users.module.css";

const AddUser = () => {
  const submit = async (userFormData: UserFormDataType) => {
    const addUserPayload: AddUserPayloadType = { user: userFormData.user };
    addUserPayload.user.contact_attributes = {
      ...userFormData?.user?.contact,
      contact_type: "COMPANY",
      email: userFormData.user.email,
      no_push: userFormData.user.no_push,
      contact_department_links_attributes: userFormData.user.contact.department_links,
      contact_subsidiary_links_attributes: userFormData.user.contact.subsidiary_links,
      currency_codes: userFormData.user.contact.additional_currency_codes,
    };

    if (userFormData?.user?.contact?.subsidiary_id) {
      if (!_.isArray(addUserPayload.user?.contact_attributes?.contact_subsidiary_links_attributes)) {
        addUserPayload.user.contact_attributes.contact_subsidiary_links_attributes = [];
      }
      addUserPayload.user?.contact_attributes?.contact_subsidiary_links_attributes?.push({
        subsidiary_id: userFormData.user.contact.subsidiary_id,
        default: true,
      });
    }

    if (_.isArray(userFormData?.user?.contact?.project_links)) {
      addUserPayload.user.contact_attributes.contact_project_links_attributes =
        userFormData?.user?.contact?.project_links.map((projectLink) => ({
          project_id: projectLink.project_id,
          is_default: projectLink.is_default,
          _destroy: projectLink._destroy,
        }));
    }
        // add contact location links
    if (_.isArray(userFormData?.user?.contact?.contact_location_links_attributes)) {
      addUserPayload.user.contact_attributes.contact_location_links_attributes = userFormData?.user?.contact?.contact_location_links_attributes.map(
        (locationLink) => ({
          location_id: locationLink.location_id,
          _destroy: locationLink._destroy,
        }),
      );
    }

    try {
      const response = await UserApis.addUser(addUserPayload);
      CreateNotification("Added", "User Added", NotificationType.success);
      if (response.status_message) {
        CreateNotification(response.status_message, response.status, NotificationType.success);
      }

      // redirecting to angular app after showing success message
      setTimeout(() => {
        window.location.href = restApiService.angularBaseURL() + "users";
      }, 2000);
    } catch (error: any) {
      const { response } = error;
      if (response.status === 422) {
        if (_.isPlainObject(response.data)) {
          const completeErrorObj = createCompleteError(response.data);
          //fix to show email validation
          if (response?.data["contact.email"]) {
            completeErrorObj.email = response?.data["contact.email"];
          }
          throw new SubmissionError({
            user: completeErrorObj,
          });
        }
      }
    }
  };

  return (
    <Container fluid>
      <Row className="mt-4">
        <Col md="12">
          <h2 className={style.userTitle}>Add User</h2>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <UserForm onSubmit={submit} initialValues={{ user: { contact: {} }, securityVisible: true }} />
        </Col>
      </Row>
    </Container>
  );
};

export default AddUser;
