import _ from "lodash";
import React from "react";

const ObjectStatus = ({ status }) => {
  let color;
  const lowerCasedStatus = status ? status.toLowerCase() : ""; //initialize to prevent null exception
  switch (lowerCasedStatus) {
    case "pending":
      color = "status-color-warning";
      break;
    case "active":
      color = "status-color-sucess";
      break;
    case "inactive":
      color = "status-color-danger";
      break;
    case "rejected":
      color = "status-color-danger";
      break;
    case "approved":
      color = "status-color-sucess";
      break;
    case "failed":
      color = "status-color-danger";
      break;
    case "new":
      color = "status-color-new";
      break;
    case "void":
      color = "status-color-danger";
      break;
    default:
      color = "status-color-sucess";
  }
  return <span className={"obj-status " + color}>{_.startCase(_.toLower(status))}</span>;
};

export default ObjectStatus;
