import React from "react";
import { CardDisputeService, DISPUTE_KEYS_INDEX } from "../../../../../services/admin/cards/cardDisputesSvc";
import { CardsDisputesFormState } from "../../../../../services/admin/cards/disputes/cardDisputes.types";
import { Col, Row, Button, Container } from "react-bootstrap";
import { Field, getFormValues } from "redux-form";
import { RenderRadioGroup } from "../../../../forms/bootstrapFields";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../../reducers";
import { Utility, DISPUTE_STATE } from "../../../../../services/admin/cards/cardDisputesSvc";
import Style from "./dispute.module.css";

type DisputeSelectionPropsType = {
  goToNextStep: (nextStep: string) => void;
  handleSubmit: any;
};

export const DisputeSelection = ({ goToNextStep, handleSubmit }: DisputeSelectionPropsType) => {
  const purchaseDescription = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue;
  });

  const currentState = useTypedSelector((state) => {
    const currentValue = getFormValues("CardDisputes")(state) as CardsDisputesFormState;
    return currentValue;
  });

  const { t } = useTranslation();
  const t2 = (key: string) => {
    return t(`components.admin.disputes.${key}`);
  };

  const returnToAngular = () => {
    Utility.returnToAngular(currentState);
  };

  return (
    <Container>
      <div className={"py-4 mt-4 mx-1 bg-white panel-section panel-padded"} style={{ padding: "32px" }}>
        <Col>
          <Row>
            <h4>{t2("header")}</h4>
          </Row>
          <hr className={`${Style.bold_hr}`}></hr>
          <Row>
            <h5>{t2("select_reason")}</h5>
          </Row>
          <Row className={`${Style.boldish} ${Style.title}`}>{t2("select_subheader")}</Row>
          <br />
          <Row>
            <div>
              <Field
                style={{ fontWeight: "normal" }}
                name="form.dispute"
                component={RenderRadioGroup}
                group={CardDisputeService.DISPUTE_OPTIONS_GROUP}
              />
            </div>
          </Row>
          {purchaseDescription?.form.dispute === DISPUTE_KEYS_INDEX.OTHER && (
            <>
              <Row dangerouslySetInnerHTML={{ __html: t2("option.other.description") }}></Row>
              <br />
            </>
          )}
          <Row className="justify-content-end">
            <Button className="btn" style={{ marginRight: "10px" }} type="button" onClick={returnToAngular}>
              {t(`components.admin.disputes.cancel`)}
            </Button>
            <Button
              onClick={handleSubmit(() => goToNextStep(DISPUTE_STATE.EDIT_CONTACT))}
              disabled={
                !purchaseDescription?.form.dispute || purchaseDescription.form.dispute === DISPUTE_KEYS_INDEX.OTHER
              }
            >
              {t2("next")}
            </Button>
          </Row>
        </Col>
      </div>
    </Container>
  );
};
