const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_INVOICE":
      return action.payload;
    case "CLEAR_INVOICE":
      return {};
    case "SET_INVOICE_PAYMENT_METHOD":
      return {
        ...state,
        payment_method: action.payload,
      };
    case "SET_INVOICE_PURCHASER":
      return {
        ...state,
        purchaser: action.payload,
      };
    case "SET_INVOICE_SERVICE_PERIOD_START":
      return {
        ...state,
        service_start_date: action.payload,
      };
    case "SET_INVOICE_SERVICE_PERIOD_END":
      return {
        ...state,
        service_end_date: action.payload,
      };
    case "SET_INVOICE_NUMBER":
      return {
        ...state,
        number: action.payload,
      };
    case "SET_INVOICE_AMOUNT":
      return {
        ...state,
        amount: action.payload,
      };
    case "SET_INVOICE_DATE":
      return {
        ...state,
        date: action.payload,
      };
    case "SET_INVOICE_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "SET_INVOICE_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "SET_INVOICE_PO":
      return {
        ...state,
        purchase_order: action.payload,
      };
    case "SET_INVOICE_ASSETS":
      return {
        ...state,
        assets: action.payload,
      };
    case "SET_CURRENCY":
      return {
        ...state,
        currency: action.payload,
      };
    default:
      return state;
  }
};
