import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { RenderField } from "../../../../../../../components/forms/bootstrapFields";

const NsTokenBased = () => {
  return (
    <>
      <Col lg="6">
        <Field name="form.account" label="Account" placeholder="Account" component={RenderField} type="text" />
      </Col>
      <Col lg="6">
        <Field
          name="form.application_id"
          label="Application ID"
          placeholder="Application ID"
          component={RenderField}
          type="text"
        />
      </Col>

      <Col lg="12">
        <Field
          name="form.consumer_key"
          label="Consumer Key"
          placeholder="Consumer Key"
          component={RenderField}
          type="text"
        />
      </Col>

      <Col lg="12">
        <Field
          name="form.consumer_secret"
          label="Consumer Secret Key"
          placeholder="Consumer Secret Key"
          component={RenderField}
          type="password"
        />
      </Col>
      <Col lg="12">
        <Field name="form.token_id" label="Token Id" placeholder="Token Id" component={RenderField} type="text" />
      </Col>
      <Col lg="12">
        <Field
          name="form.token_secret"
          label="Toke Secret Key"
          placeholder="Toke Secret Key"
          component={RenderField}
          type="password"
        />
      </Col>
    </>
  );
};

export default NsTokenBased;
