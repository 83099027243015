import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { WrappedFieldProps } from "redux-form";
import { onBlurSingle } from "services/general/helpers";
import { Mandatory } from "wombatifier/components/pickers/mandatory";
import _ from "lodash";
import { ExpensesTypes } from "services/admin/expenses/expensesType";

export interface ItemTypePickerPropsType extends WrappedFieldProps {
  modelData?: any;
  parentObjData?: any;
  className?: string;
  labelClassName?: string;
  label?: string;
  tooltip?: string;
  required?: boolean;
  instanceId?: string;
  placeholder?: string;
  disabled?: boolean;
  onSelectCallback?: (value: string) => void;
  callBackObj?: (value: ExpensesTypes.SelectedTypeItem) => void;
  options?: any;
}

// this component is specific to expense item form only
const ItemTypePicker = (props: ItemTypePickerPropsType) => {
  // const [options, setOptions] = useState([]);
  const {
    input,
    meta: { error, touched },
    className,
    disabled,
    instanceId,
    label,
    labelClassName,
    modelData,
    onSelectCallback,
    parentObjData,
    placeholder,
    required,
    tooltip,
    callBackObj,
    options,
  } = props;

  const findSelected = () => {
    let selectedValues = null; //set to null because pickers will have blank default value instead of "select... placeholder"
    const obj = _.find(options, (option: any) => option?.value === input.value.value);
    if (obj) selectedValues = obj;
    return selectedValues ? selectedValues : null;
  };

  const changeItem = (selected: any) => {
    input.onChange(selected);
    if (callBackObj) {
      callBackObj(selected);
    }
  };

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label className={labelClassName}>
            {label}
            <Mandatory required={required} />
            {tooltip}
          </Form.Label>
        )}
        <Select
          {...input}
          value={findSelected()}
          onChange={(value) => changeItem(value)}
          onBlur={() => onBlurSingle(input, input.value)}
          getOptionLabel={(option: ExpensesTypes.SelectedTypeItem): any => {
            return (
              <>
                <i className={`icon ${option.icon} mt-1`} /> {option.label}
              </>
            );
          }}
          instanceId={instanceId}
          options={options}
          isDisabled={disabled}
        />
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default ItemTypePicker;
