import React from "react";
import VendorSearchPicker from "../vendorSearchPicker";
import { Field } from "redux-form";
import { required } from "services/validations/reduxFormValidation";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import CustomTemplatePicker from "../customTemplatePicker";
import { Mandatory } from "components/forms/bootstrapFields";

type ItemPickerTemplatePropsType = {
  customField?: any;
  modelDataName?: string;
  formFieldName: string;
  absPath?: string; // absolute path where we want to update data with respect to  form
  parentDivId?: string;
  parentDivVariance?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  menuAutoFixed?: boolean;
};

export const ItemPickerTemplate = ({
  customField,
  modelDataName,
  formFieldName,
  parentDivId,
  parentDivVariance,
  menuAutoFixed,
}: ItemPickerTemplatePropsType) => {
  return (
    <>
      {customField?.inline && customField.label && (
        <span>
          {customField.label}:
          <Mandatory required={customField.required} />
        </span>
      )}
      <ErrorBoundary>
        {customField.items_picker === "vendorsearcher" && (
          <Field
            key={customField.items_picker}
            instanceId={customField.items_picker}
            // name={`custom_fields[${customField.name}].value`}
            name={`${formFieldName}.${customField.name}.value`}
            component={VendorSearchPicker}
            required={customField?.required}
            validate={customField?.required ? [required] : []}
            parentDivId={parentDivId}
            parentDivVariance={parentDivVariance}
            menuAutoFixed={menuAutoFixed}
          />
        )}

        {customField.items_picker !== "vendorsearcher" && (
          <Field
            key={customField.items_picker + "-" + customField.is_real_time_search}
            instanceId={customField.items_picker + "-" + customField.is_real_time_search}
            // name={`custom_fields[${customField.name}].value`}
            name={`${formFieldName}.${customField.name}.value`}
            component={CustomTemplatePicker}
            modelName={customField.items_picker}
            modelDataName={modelDataName}
            isRealTimeSearch={customField.is_real_time_search}
            required={customField?.required}
            placeholder={"-- Type and Search --"}
            validate={customField?.required ? [required] : []}
            parentDivId={parentDivId}
            parentDivVariance={parentDivVariance}
            menuAutoFixed={menuAutoFixed}
          />
        )}
      </ErrorBoundary>
    </>
  );
};
