import { RenderField } from "../../../forms/bootstrapFields";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const AchId = ({ label, isAchRequired, validations }) => {
  return (
    <Col md="6">
      <Field
        id="form.ach_id"
        name="form.ach_id"
        component={RenderField}
        type="text"
        label={label}
        required={isAchRequired}
        validate={validations ? validations : []}
      />
    </Col>
  );
};

export default AchId;
