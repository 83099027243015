import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import { ReportFormType } from "../../../../../services/admin/reports/reportTypes";
import { ReportSvc } from "../../../../../services/admin/reports/reportSvc";
import { RenderField } from "../../../../forms/bootstrapFields";
import { Row, Col } from "react-bootstrap";

const InputFilter = ({
  name,
  code,
  options,
  required,
}: {
  name: string;
  code: string;
  options: { [key: string]: any }[];
  required?: boolean;
}) => {
  const isRequired = () => {
    if (required) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Row className="mx-0">
      <Col className="px-0">
        <Field
          name={`properties.filters.${code}`}
          type="text"
          component={RenderField}
          label={name}
          required={isRequired()}
        />
      </Col>
    </Row>
  );
};

export default InputFilter;
