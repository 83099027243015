import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import english from "./localization/translations/english";
import chinese from "./localization/translations/chinese";
import spanish from "./localization/translations/spanish";

// the translations
const resources = {
  en: { translation: english },
  zh: { translation: chinese },
  es: { translation: spanish },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // automatically detect language
  .init({
    resources,
    lng: "en", //default language
    fallbackLng: "en", //when there is not a translation for a given language it will default to english
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
