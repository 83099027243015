/*eslint-disable eqeqeq*/
import React, { Component } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";

import Panel from "../../components/panel/panel";
import "./userProfile.style.css";
import RestApi from "../../providers/restApi";

import { connect } from "react-redux";
import * as actionType from "../../actions/actionTypes";

import UpdatePasswordForm from "../../components/forms/updatePassword";
import { withTranslation } from "react-i18next";
import { CreateNotification, NotificationType } from "../../services/general/notifications";
import { MapLanguageToAPI, MapAPIToLanguage } from "../../services/general/mapping";
import Loader from "../../components/spinners/loader";
import ProfilePhotoPicker from "../../components/common/profilePhotoPicker/profilePhotoPicker";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.uploadFile = React.createRef();
    this.state = {
      changePasswordLoading: true,
      loading: true,
      email_aggregation: false, //
      real_time_notification_email: false, //
    };
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    this.setState({ loading: true });
  }
  profileFormData = new FormData();
  render() {
    let user = this.props.user;

    return (
      <Container fluid={true}>
        <Panel cardClasses="mt-3">
          <Row className=" py-4">
            <Col sm="2">
              <div className={"avatar"}>
                <ProfilePhotoPicker
                  sourceUrl={user?.avatar?.asset_expiring_url}
                  onSaveImageCallBack={this.onSaveProfilePhoto}
                  onCancelImageCallBack={this.onCancelProfilePhoto}
                  onChangeImageCallBack={this.onChangeProfilePhoto}
                />
              </div>
            </Col>
            <Col sm="5" className="pr-sm-0 pt-4">
              <dl className="row">
                <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("contact.name")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{user.name}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("address.email")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{user.email}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("details.status")}</dt>
                <dd className="col-6 col-sm-7 detailValue">Active</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("address.phone")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{user.company.address && user.company.address.phone}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("address.address")}</dt>
                <dd className="col-6 col-sm-7 detailValue">
                  {user.company.address && (
                    <span>
                      {user.company.address.address1} {user.company.address.address2} {user.company.address.address3}{" "}
                      {user.company.address.city} {user.company.address.country} {user.company.address.state}{" "}
                      {user.company.address.zipcode}
                    </span>
                  )}
                </dd>
              </dl>
            </Col>
            <Col sm="5" className="pl-sm-0 pt-4">
              <dl className="row">
                <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("contact.departmentHead")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{user.contact.last_name}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("contact.departments")}</dt>
                <dd className="col-6 col-sm-7 detailValue">{user.contact.department_list_str}</dd>

                <dt className="col-6 col-sm-5 detailKey text-sm-right">{this.props.t("contact.loginDisabled")}</dt>
                <dd className="col-6 col-sm-7 detailValue">No</dd>
              </dl>
            </Col>
          </Row>
        </Panel>
        {/*<CollapsePanel heading="Assigned Customers" bodyContent="Assigned Customers content" leftBtn={"add assigned customer"} onClick={this.testClick}/>*/}
        {/*<CollapsePanel heading="Documents" bodyContent="Documents content" leftBtn={"Upload"} onClick={this.testClick}/>*/}
        {/*<CollapsePanel heading="Data Audit Log" bodyContent="Data Audit Log content"/>*/}
        {/*<CollapsePanel heading="Integration Activities" bodyContent="Integration Activities content"/>*/}
        <Row className={"mt-4 mb-4"}>
          <Col md="6">
            <Panel
              panelContent={{
                heading: this.props.t("profilePage.managePreference"),
                iconClass: "icon-preferences",
              }}
            >
              <Row>
                <Col sm>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Label>{this.props.t("contact.emailPreference")}</Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="Daily Summary Email"
                      onClick={this.dailySummaryEmail}
                      defaultChecked={user.contact.email_aggregation}
                    />
                    {/*<Form.Check type="checkbox" label="Real-time Notification" checked={this.state.real_time_notification_email} onClick={() => this.realTimeNotification("real_time_notification_email")}/>*/}
                  </Form.Group>
                </Col>
                <Col sm>
                  <Form.Group controlId="formGridState">
                    <Form.Label>{this.props.t("profilePage.languagePreference")}</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue={MapAPIToLanguage(user.contact.language)}
                      onChange={this.changeLanguage}
                    >
                      <option value="en">English</option>
                      <option value="zh">Chinese</option>
                      <option value="es">Spanish</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Panel>
          </Col>

          <Col md="6" className="mt-4 mt-sm-0">
            <Panel
              panelContent={{
                heading: this.props.t("profilePage.updatePassword"),
                iconClass: "icon-password",
              }}
            >
              <Loader loaded={this.state.changePasswordLoading}>
                <UpdatePasswordForm onSubmit={this.changePassword} />
              </Loader>
            </Panel>
          </Col>
        </Row>
      </Container>
    );
  }

  changeAvatar = (avatarInfo) => {
    const endpoint = "users/" + this.props.user.id;
    this.restApiService
      .formWithImage(endpoint, avatarInfo, "Patch")
      .then((response) => {
        this.restApiService
          .get("user")
          .then((res) => {
            if (res) {
              this.props.addUserDetails(res.data);
              window.location.reload();
            }
          })
          .catch((err) => {});
      })
      .catch((error) => {
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning);
      });
  };

  onChangeProfilePhoto = (file) => {
    this.profileFormData.append("user[avatar][asset_file]", file);
    this.profileFormData.append("user[avatar][file_name]", file.name);
  };

  onCancelProfilePhoto = () => {
    this.profileFormData.delete("user[avatar][asset_file]", "user[avatar][file_name]");
  };

  onSaveProfilePhoto = () => {
    this.changeAvatar(this.profileFormData);
  };

  changeLanguage = (event) => {
    let language = event.target.value;
    let apiLanguage = MapLanguageToAPI(language);
    this.restApiService
      .patch("contacts/" + this.props.user.contact.id, null, { language: apiLanguage })
      .then((response) => {
        this.restApiService
          .get("user")
          .then((res) => {
            if (res) this.props.addUserDetails(res.data);
          })
          .catch((err) => {});
      })
      .catch((error) => {
        console.error("Error setting language: ", error);
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning);
      });
  };

  dailySummaryEmail = (event) => {
    let value = event.target.checked;
    this.restApiService
      .patch("users/" + this.props.user.id, null, {
        user: {
          contact: {
            email_aggregation: value,
          },
        },
      })
      .then((result) => {
        CreateNotification(
          this.props.t("success"),
          this.props.t("profilePage.emailPreferencesSaved"),
          NotificationType.success,
        );
        this.props.changeDailyEmail(value);
      })
      .catch((error) => {
        console.error("Error setting email preference: ", error);
        CreateNotification(this.props.t("error"), this.props.t("errors.genericSupport"), NotificationType.warning);
      });
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addUserDetails: (userDetails) => {
      dispatch({ type: actionType.ADDUSERDETAILS, payload: userDetails });
    },
    changeLanguage: (language) => {
      dispatch({ type: actionType.CHANGE_USER_LANGUAGE, payload: language });
    },
    changeDailyEmail: (value) => {
      dispatch({ type: actionType.CHANGE_USER_EMAIL_AGGREGATION, payload: value });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UserProfile));
