import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Mandatory } from "../../../forms/input/mandatory";
import Styles from "../reduxFormPickers/override.module.css";

type InputSelectPropsType = {
  className?: string;
  options: { [key: string]: any }[];
  label?: string;
  labelClassName?: string;
  placeholder?: string;
  isRequired?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  defaultValue?: any;
  value?: any;
  onChange?: (value: any) => void;
  floating?: boolean;
};

export const InputSelect = ({
  className = "",
  options,
  label = "",
  labelClassName = "",
  placeholder = "Select one",
  isRequired = false,
  isMulti = false,
  isDisabled = false,
  tooltip,
  defaultValue,
  value,
  onChange = (value) => {},
  floating = false,
}: InputSelectPropsType) => {
  const handleOnChange = useCallback(
    (value: any) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <Form.Group className={`${Styles.select} ${className}`}>
      {label && (
        <Form.Label className={labelClassName}>
          {label ?? ""}
          <Mandatory required={isRequired} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        required={isRequired}
        placeholder={placeholder}
        onChange={handleOnChange}
        options={options}
        isMulti={isMulti}
        defaultValue={defaultValue}
        value={value}
        isClearable
        classNamePrefix="select"
        isDisabled={isDisabled}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
          }),
          ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
        }}
        {...(floating ? { menuPortalTarget: document.body } : {})}
      />
    </Form.Group>
  );
};
