import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import styles from "../../../pages/admin/notifications/notification.module.css";
import _ from "lodash";
import RestApi from "../../../providers/restApi";
const restApiService = new RestApi();

const isBelongsToCurrentMonth = (item, monthGroup) => {
  let month = monthGroup && monthGroup.length > 0 ? monthGroup.substring(0, 3) : "";
  return month === item.month;
};

const NotificationsRenderer = (props) => {
  const { monthGroup, items, refreshNotifications } = props;

  const markMessageAsRead = (row) => {
    restApiService.patch("messages/" + row.id, null, { message: { viewed_status: "READ" } }).then(async (response) => {
      refreshNotifications();
      if (_.isPlainObject(response.data)) {
        if (row.urlLocator.startsWith("expenses")) {
          await (window.location = row.urlLocator);
        } else {
          await (window.location = restApiService.angularBaseURL() + row.urlLocator);
        }
      }
    });
  };

  return (
    <Row className="m-0 d-flex justify-content-end">
      <Col xs={"12"}>
        <div className={styles.monthGrp + " mb-4"}>{monthGroup}</div>
        {_.isArray(items) &&
          items.length > 0 &&
          items.map((item, index) => {
            return isBelongsToCurrentMonth(item, monthGroup) ? (
              <Row key={index}>
                <Col xs={12}>
                  <Card className={styles.notificationCard} key={index}>
                    <Card.Body>
                      <Card.Title className={styles.weekGrp}>{item.weekGroup}</Card.Title>
                      <Card.Text>
                        {_.isArray(item.rows) &&
                          item.rows.length > 0 &&
                          item.rows.map((row, index) => {
                            return (
                              <Row key={index}>
                                <Col xs={"12"} className="mb-2">
                                  {/* if value.properties.app.object is defined, decorate the data: */}
                                  {_.isPlainObject(row.properties) &&
                                    _.isPlainObject(row.properties.app) &&
                                    _.isPlainObject(row.properties.app.object) && (
                                      <a
                                        className={
                                          "py-2 flex-row " +
                                          (row.viewed_status === "UNREAD" ? styles.unread : styles.read)
                                        }
                                        onClick={() => {
                                          markMessageAsRead(row);
                                        }}
                                      >
                                        <Row>
                                          <Col xs={"2"}>
                                            <span className="mr-3">{row.formatted_created_at}</span>
                                          </Col>
                                          <Col xs={"10"}>
                                            <span>
                                              {row.subject} : {row.properties.app.body}
                                            </span>
                                          </Col>
                                        </Row>
                                      </a>
                                    )}
                                </Col>
                              </Row>
                            );
                          })}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : null;
          })}
      </Col>
    </Row>
  );
};

export default NotificationsRenderer;
