import React, { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { Mandatory } from "components/forms/bootstrapFields";
import { onBlurSingle, onChangeSingle } from "services/general/helpers";
import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import _ from "lodash";
import { WrappedFieldProps } from "redux-form";
import { CreateNotification, NotificationType } from "services/general/notifications";

interface UnitPickerPropsType extends WrappedFieldProps {
  label?: string;
  input: any;
  required?: boolean;
  tooltip?: string;
  placeholder?: string;
  disabled?: boolean;
  params?: Record<string, any>;
  modelData?: any;
  callBack?: (selected: UnitObjType) => void;
  instanceId?: string;
}

type UnitObjType = {
  id?: number | string;
  display_name?: string;
  status?: string;
  label?: string;
  company_id?: number;
  description?: string;
  external_id?: string;
};

const UnitPicker = ({
  label,
  input,
  required,
  tooltip,
  placeholder,
  disabled,
  modelData,
  callBack,
  instanceId,
}: UnitPickerPropsType) => {
  const [units, setUnits] = useState<UnitObjType[]>([]);
  const mounted = useRef(false);
  const [selected, setSelected] = useState<UnitObjType>();

  const parseForSelect = (options: UnitObjType[]) => {
    return options
      .filter((option) => option.display_name !== undefined) // Filter out options with undefined display_name
      .sort((a, b) => (a.display_name || "").localeCompare(b.display_name || "")) // Sort options by display_name
      .map((option: UnitObjType) => ({
        ...option,
        value: option.id,
        label: option.display_name || "", // Ensure display_name is not undefined
      }));
  };

  const getUnits = useCallback(async () => {
    const apiParams: any = { status: "ACTIVE" };
    if (modelData?.product_item?.unit_type_id) {
      apiParams.unit_type_id = modelData?.product_item?.unit_type_id;
    }

    const result: AxiosResponse = await restApiService.get(`units.lk`, apiParams, null, true, null, true);
    if (result?.data) {
      let list = parseForSelect(result.data);
      if (mounted.current) {
        if (!required) {
          list = [
            {
              label: "-- Select Units --",
              value: "",
              status: undefined,
            },
            ...list,
          ];
        }
        setUnits(list);
      }
      return list;
    }
  }, [modelData?.product_item?.unit_type_id]);

  const findSelected = async (input: { value: number | string }, options: any) => {
    let selectedValues = null;
    if (input.value) {
      const unit = await options.filter((option: any) => option.value === input.value);

      if (unit.length == 0) {
        try {
          const result = await restApiService.get("units/" + input.value);
          if (
            modelData?.product_item?.unit_type_id &&
            modelData?.product_item?.unit_type_id === result.data.unit_type_id
          ) {
            const inactiveUnit = {
              value: result.data.unit_type_id,
              label: (
                <>
                  {result.data.display_name} - <small style={{ fontSize: "10px" }}>({result.data.status})</small>
                </>
              ),
            };
            selectedValues = inactiveUnit;
          } else {
            selectedValues = setDefaultUnit();
          }
          return selectedValues ? selectedValues : null;
        } catch (error) {
          console.log(error);
          CreateNotification("Error", "Problem loading inactive units.", NotificationType.danger);
        }
      } else {
        selectedValues = unit[0];
      }
    } else if (
      modelData?.product_item?.unit_type_id &&
      !modelData?.do_not_set_default_unit &&
      modelData?.product_item_id &&
      !modelData?.id
    ) {
      selectedValues = setDefaultUnit();
    } else if (modelData?.do_not_set_default_unit) {
      modelData.do_not_set_default_unit = false;
    }
    return selectedValues ? selectedValues : null;
  };

  const getDefaultUnit = () => {
    let record = null;
    let purchase_unit_id = modelData?.product_item?.purchase_unit_id;

    if (purchase_unit_id) {
      record = _.find(units, { id: purchase_unit_id });
    }

    if (!record) {
      record = _.find(units, { unit_type_id: modelData?.product_item?.unit_type_id, base_unit: true });
    }

    return record;
  };

  const setDefaultUnit = () => {
    let record = getDefaultUnit();
    if (record) {
      return record;
    }
  };

  const onChangeUnit = (input: any, selected: UnitObjType) => {
    onChangeSingle(input, selected);
    setSelected(selected);
    if (callBack) {
      callBack(selected);
    }
  };

  useEffect(() => {
    getUnits().then((res) => {
      findSelected(input, res).then((response: any) => {
        setSelected(response);
      });
    });
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [modelData?.product_item?.unit_type_id]);

  return (
    <>
      <Form.Group>
        {label && (
          <Form.Label>
            {label ?? ""}
            <Mandatory required={required} />
            {tooltip ?? ""}
          </Form.Label>
        )}
        <Select
          {...input}
          placeholder={placeholder}
          required={required}
          value={selected}
          onChange={(selected: UnitObjType) => onChangeUnit(input, selected)}
          onBlur={() => onBlurSingle(input, input.value)}
          options={units}
          disable={disabled}
          isClearable={false}
          getOptionLabel={(option: UnitObjType) => {
            return <small>{option.label}</small>;
          }}
          instanceId={instanceId || "unit-picker"}
        ></Select>
      </Form.Group>
    </>
  );
};

export default UnitPicker;
