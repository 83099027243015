import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  maxLength,
  minLength,
  noWhiteSpaceOnly,
  number,
  required,
} from "../../../../services/validations/reduxFormValidation";
import BankRoutingSample from "../../../common/bankingRoutingSample/bankRoutingSample";
import AccountHolderName from "../fields/accountHolderName";
import AccountNickName from "../fields/accountNickName";
import AccountPayoutCheckBoxes from "../fields/accountPayoutCheckBoxes";
import AccountType from "../fields/accountType";
import AchId from "../fields/achId";
import BranchCode from "../fields/banchCode";
import BankAccountNumber from "../fields/bankAccountNumber";
import BankAddress from "../fields/bankAddress";
import BankCode from "../fields/bankCode";
import BankName from "../fields/bankName";
import CompanyIdentification from "../fields/companyIdentification";
import ContactFirstName from "../fields/contactFirstName";
import ContactLastName from "../fields/contactLastName";
import CurrencyCode from "../fields/currencyCode";
import DebitCreditFields from "../fields/debitCreditFields";
import Memo from "../fields/memo";
import NachaPayments from "../fields/nachaPayments";
import PaymentTypeSelect from "../fields/paymentTypeSelect";
import Status from "../fields/status";
import Suffix from "../fields/suffix";
import UsBankCompanyId from "../fields/usBankCompanyId";
import { useSelector } from "react-redux";

const maxLength4 = maxLength(4);
const maxLength2 = maxLength(2);
const minLength1 = minLength(1);

const NzEft = () => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const current_user = useSelector((state) => state.user);

  return (
    <>
      <PaymentTypeSelect />
      <AccountType />
      {current_user.is_root ? <Status label={t("companySettings.paymentMethodsSettings.status")} /> : null}
      <AccountPayoutCheckBoxes />

      <Col md={12}>
        <hr />
      </Col>

      <ContactFirstName
        label={t("companySettings.paymentMethodsSettings.contactFirstName")}
        tooltip={<BankRoutingSample />}
      />

      <ContactLastName
        label={t("companySettings.paymentMethodsSettings.contactLastName")}
        tooltip={<BankRoutingSample />}
      />

      <AccountHolderName
        label={t("companySettings.paymentMethodsSettings.accountHolderName")}
        isAccountHolderRequired
        validations={[required, noWhiteSpaceOnly]}
        tooltip={<BankRoutingSample />}
      />
      <BankAccountNumber
        label={t("companySettings.paymentMethodsSettings.bankAccountNumber")}
        isBankAccountRequired={true}
        validations={[required, noWhiteSpaceOnly]}
        tooltip={<BankRoutingSample />}
      />
      <CurrencyCode label={t("companySettings.paymentMethodsSettings.currencyCode")} />
      <AccountNickName label={t("companySettings.paymentMethodsSettings.accountNickName")} />
      <BankName label={t("companySettings.paymentMethodsSettings.bankName")} />
      <BranchCode
        label={t("companySettings.paymentMethodsSettings.branchCode")}
        validations={[maxLength4, number]}
        pattern={"[0-9]"}
      />
      <BankCode
        label={t("companySettings.paymentMethodsSettings.bankCode")}
        validations={[number, maxLength2]}
        pattern={"[0-9]"}
        makLength={2}
      />
      <DebitCreditFields />

      <Memo label={t("companySettings.paymentMethodsSettings.memo")} />
      <CompanyIdentification label={t("companySettings.paymentMethodsSettings.companyIdentification")} />
      <Suffix
        label={t("companySettings.paymentMethodsSettings.suffix")}
        isSuffixRequired
        validations={[required, maxLength4, minLength1, number]}
      />
      <AchId label={t("companySettings.paymentMethodsSettings.achId")} />
      <BankAddress label={t("companySettings.paymentMethodsSettings.bankAddress")} />

      {!showMore && (
        <Col md="12" className="d-flex justify-content-center align-items-center">
          <span role={"button"} onClick={() => setShowMore(true)} className="primaryLinkColor">
            {t("companySettings.paymentMethodsSettings.showMore")}
          </span>
        </Col>
      )}

      {showMore && (
        <>
          <Col md="12">
            <hr />
          </Col>
          <NachaPayments />
          <UsBankCompanyId label={t("companySettings.paymentMethodsSettings.usBankCompanyId")} />
        </>
      )}

      {showMore && (
        <Col md="12" className="d-flex justify-content-center align-items-center">
          <span role={"button"} onClick={() => setShowMore(false)} className="primaryLinkColor">
            {t("companySettings.paymentMethodsSettings.showLess")}
          </span>
        </Col>
      )}
    </>
  );
};

export default NzEft;
