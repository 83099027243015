import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { change, Field, getFormValues, InjectedFormProps, reduxForm } from "redux-form";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTypedSelector } from "reducers";
import RestApi from "providers/restApi";

import Panel from "components/common/panel/panel";
import FileUploader from "components/common/fileUploader/fileUploader";
import TabNavigation from "components/navigation/tabNavigation";
import NavTabs from "pages/admin/administration/nav";
import { CreateNotification, NotificationType } from "services/general/notifications";

import styles from "./metadataConfiguration.module.css";
import { RenderSelect } from "components/forms/bootstrapFields";
import MetadataHierarchyUpsert from "components/metadata/metadataHierarchyEdit/metadataHierarchyUpsert";
import MetadataConfigurationNavbar from "./metadataConfigurationNavbar";

export interface MetadataConfiguration {
  id?: number | null;
  field_id?: string | null;
  name?: string;
  object_type?: string | null;
  input_type?: string | null;
  required?: boolean | null;
  parent_hierarchies?: any;
  created_at?: Date | null;
  updated_at?: Date | null;
}

export interface MetadataSorter {
  field_id?: string | null;
  name?: string | null;
  configurations: MetadataConfiguration[];
}

const formName = "metadataHierarchyForm";

let MetadataHierarchyPage = ({ handleSubmit }: InjectedFormProps<FormData, {}>) => {
  const dispatch = useDispatch();

  const form: any = useSelector((state) => getFormValues(formName)(state), shallowEqual);
  const currentUser = useTypedSelector((state) => state.user, shallowEqual);
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [flatMetadataConfigurations, setFlatMetadataConfigurations]: any = useState({});
  const [allMetadataConfigurations, setAllMetadataConfigurations]: any = useState({});

  const transformFromApi = (configuration: any) => {
    let parsedObject = { dependent_metadata_configuration: null };
    configuration.parent_hierarchies.forEach((hierarchy: any) => {
      //yes we set this a bunch of times to the same thing but its ok
      parsedObject.dependent_metadata_configuration = hierarchy.dependent_metadata_configuration_field_id;

      //used in the upsert component
      let fieldKey: string =
        "dependent_" + hierarchy.dependent_metadata_configuration_field_id + "_" + hierarchy.parent_metadata_field_id;
      /* @ts-ignore */
      if (!parsedObject[fieldKey]) {
        /* @ts-ignore */
        parsedObject[fieldKey] = [];
      }
      /* @ts-ignore */
      parsedObject[fieldKey].push({
        id: hierarchy.id,
        value: hierarchy.dependent_metadata_field_id,
        metadata_configuration_id: hierarchy.dependent_metadata_configuration_id,
      });
    });

    return parsedObject;
  };

  const getConfigurations = useCallback(async () => {
    const restApi = new RestApi();
    const apiResult = await restApi.get("metadata_configurations");

    const configurations: MetadataConfiguration[] = apiResult.data;

    let parsedConfigurations: any = {};
    //parse the valid configurations into an object easier to work with
    configurations.forEach((configuration) => {
      if (configuration.field_id && configuration.input_type == "select") {
        parsedConfigurations[configuration.field_id] = {
          ...configuration,
          initialValue: transformFromApi(configuration),
        };
      }
    });

    let test = Object.entries(parsedConfigurations).map(([key, value]) => {
      return value;
    });

    setAllMetadataConfigurations(apiResult.data);
    setFlatMetadataConfigurations(test);
  }, []);

  useEffect(() => {
    getConfigurations();
  }, []);

  return (
    <Container fluid={true}>
      <Row className="m-0">
        <Col md="12" className="mt-4">
          <NavTabs activePageName={"Form Configuration"} />
        </Col>
      </Row>

      <hr className="mt-4 mb-4" />

      <Panel>
        <MetadataConfigurationNavbar />
        <div className={"d-flex"}>
          <div className="flex-grow-1">
            <p className="mb-2">
              Each configuration can have 1 dependent configuration. If you add new configurations after the hierarchies
              are set you should re-save that field
            </p>

            <hr></hr>

            {flatMetadataConfigurations.length > 0 && (
              <div className="mt-4">
                {flatMetadataConfigurations.map((configuration: any) => (
                  <MetadataHierarchyUpsert
                    refreshCallback={getConfigurations}
                    key={configuration.field_id}
                    metadataConfiguration={configuration}
                    form={`metadata_hierarchy_${configuration.field_id}`}
                    formName={`metadata_hierarchy_${configuration.field_id}`}
                    metadataChoices={flatMetadataConfigurations}
                    allMetadataConfigurations={allMetadataConfigurations}
                    initialValues={configuration.initialValue}
                  ></MetadataHierarchyUpsert>
                ))}
              </div>
            )}
          </div>
        </div>
      </Panel>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({ initialValues: state.metadataConfigurationForm });

const MetadataHierarchyForm = connect(mapStateToProps)(
  reduxForm<FormData>({
    form: formName,
    // onSubmit,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true,
    destroyOnUnmount: true,
    // updateUnregisteredFields: true,
  })(MetadataHierarchyPage),
);

export default MetadataHierarchyForm;
