import React, { useCallback, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import styles from "./cardPayMyAccount.module.css";
import { shallowEqual, useDispatch } from "react-redux";
import { CardPaymentsSvc } from "services/admin/cards/cardPaymentsSvc";
import { RootState, useTypedSelector } from "reducers";
import moment from "moment";
import { formattedAmount, getDateModified, getNeutralDate } from "services/general/helpers";
import { IUser } from "services/common/user/userTypes";
import { cloneDeep } from "lodash";
import { CardPaymentsApis } from "services/admin/cards/payments/cardPaymentsApis";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { PaymentStatementAccountType } from "services/admin/cards/payments/cardPayments.types";
import { LastCardPaymentable } from "./lastCardPaymentable";

interface CardPayMyAccountReviewPropsType {
  close: () => void;
  handleSubmit: any;
}

export const CardPayMyAccountReview = ({ close, handleSubmit }: CardPayMyAccountReviewPropsType) => {
  const dispatch = useDispatch();
  const currentUser = useTypedSelector((state: RootState) => state.user, shallowEqual) as IUser | undefined;
  const currentStatement = useTypedSelector(
    (state: RootState) => CardPaymentsSvc.getCurrentStatement(state),
    shallowEqual,
  );
  const { account_code, due_date, currency_code } = currentStatement;
  const formattedDueDate = due_date && moment(new Date(due_date)).format("MMM DD, YYYY");
  const currentPaymentType = useTypedSelector((state: RootState) => CardPaymentsSvc.getCurrentPaymentType(state));
  const currentPaymentAmount = useTypedSelector((state: RootState) => CardPaymentsSvc.getCurrentPaymentAmount(state));
  const currentPaymentSource = useTypedSelector((state: RootState) => CardPaymentsSvc.getCurrentPaymentSource(state));
  const currentPaymentDate = useTypedSelector((state: RootState) => CardPaymentsSvc.getCurrentPaymentDate(state));
  const todayDate = new Date();
  const { lastCardPayment } = LastCardPaymentable({
    accountCode: account_code,
    amount: currentPaymentAmount,
    paymentDateAfter: getDateModified(todayDate, -7),
  });
  const currencyCode = currency_code || currentUser?.contact?.currency_code;
  const [submitting, setSubmitting] = useState<boolean>(false);

  const submitPayment = useCallback(
    async (values: any) => {
      setSubmitting(true);
      const formValues = cloneDeep(values?.payMyAccount?.form) || {};
      const { payment_source, amount, payment_date, card_program_id } = formValues;
      const { institution_name, account_name, account_number, bank_account_number, bank_routing_number } = JSON.parse(
        payment_source,
      ) as PaymentStatementAccountType;
      try {
        const params = {
          add_funds: {
            amount: Number(CardPaymentsSvc.parseNumberToString(amount)),
            rcn_id: account_number,
            institution_name,
            account_name,
            bank_account_number,
            bank_routing_number,
            payment_date,
            comdata_account_code: account_code,
          },
        };
        const res = await CardPaymentsApis.addFunds(card_program_id, params);
        CreateNotification("Bank Card Statement", `Payment successfully submitted.`, NotificationType.success);
        CardPaymentsSvc.setCreatedCardProgramTransaction(dispatch, res);
        setSubmitting(false);
        CardPaymentsSvc.setNextStep(dispatch, "submitted");
      } catch (err) {
        setSubmitting(false);
        CreateNotification(
          "Payment Submission Failed",
          `This payment was not processed successfully. Please confirm all payment details were entered correctly and resubmit your payment.`,
          NotificationType.danger,
        );
      }
    },
    [dispatch, account_code],
  );

  return (
    <>
      <Modal.Header>
        <Container className="px-0">
          <Row className={`mx-0 justify-content-between align-items-center w-100 ${styles.modalHeader}`}>
            <Col className="px-0">Payment Request Submitted</Col>
            <Col className="px-0" style={{ flex: 0 }}>
              <i onClick={close} className={`${styles.modalClose} icon-close`}></i>
            </Col>
          </Row>
          <Row className={`mx-0 ${styles.modalSubheader}`}>
            <Col className="px-0 pr-2 text-nowrap" style={{ flex: 0 }}>
              <label>Account Code:</label>
            </Col>
            <Col className="px-0">
              <label>{account_code ?? "N/A"}</label>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container className={`px-0 ${styles.section} border-bottom pb-4`}>
          <Row className={`mx-0 font-weight-bold justify-content-center align-items-center`}>
            <label>Review Payment Details</label>
          </Row>
          {currentPaymentType === "Other Balance" ? (
            <Row className={`mx-0 justify-content-center`}>
              <span>Custom Amount</span>
            </Row>
          ) : (
            <Row className={`mx-0 justify-content-center`}>
              <span>Amount</span>
            </Row>
          )}
          <Row className={`mx-0 px-2 py-1 justify-content-center ${styles.reviewAmount}`}>
            {formattedAmount(`${currentPaymentAmount}`, currency_code, 2, true)}
          </Row>
        </Container>

        <Container className={`px-0 py-3 ${styles.section} border-bottom`}>
          <Row className="mx-0 pb-3">
            <Col className={`mx-0 px-0 text-nowrap d-flex flex-column`} md={3}>
              <span>Payment Date</span>
              <span className={styles.subSection}> Due {formattedDueDate ?? "N/A"}</span>
            </Col>
            <Col className={`mx-0 px-0 justify-content-center`}>
              {currentPaymentDate && moment(getNeutralDate(new Date(currentPaymentDate))).format("MMM DD, YYYY")}
            </Col>
          </Row>
          <Row className="mx-0">
            <Col className={`mx-0 px-0 text-nowrap`} md={3}>
              <span>Payment Source</span>
            </Col>
            <Col className={`mx-0 px-0 justify-content-center`}>{currentPaymentSource}</Col>
          </Row>
        </Container>
        {lastCardPayment && currentPaymentAmount === lastCardPayment.amount ? (
          <Row className="mx-0 pt-2">
            <span className={`text-danger ${styles.subSection}`}>
              Duplicate Payment Warning: A payment in the amount of{" "}
              {formattedAmount(lastCardPayment.amount, currencyCode, 2, true)} was recently made for this account.
            </span>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="border-0 pt-5">
        <Row className="mx-0 justify-content-end">
          <Button
            variant="secondary"
            className="px-4 py-1"
            onClick={() => CardPaymentsSvc.setNextStep(dispatch, "setup")}
          >
            Back
          </Button>
          <Button
            variant="primary"
            className={`ml-2 py-1 ${styles.footerButton}`}
            disabled={submitting}
            onClick={handleSubmit(submitPayment)}
          >
            {submitting ? (
              <Spinner animation="border" variant="info" style={{ width: 25, height: 25 }} />
            ) : (
              "Submit Payment"
            )}
          </Button>
        </Row>
      </Modal.Footer>
    </>
  );
};
