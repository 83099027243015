import React from "react";
import { Table, Form, Row, Col, Button } from "react-bootstrap";
import Panel from "../../../common/panel/panel";
import { Field, InjectedFormProps, getFormValues, reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";
import { RenderCheckBox, RenderField, RenderSelect } from "../../../forms/bootstrapFields";
import { useTypedSelector } from "../../../../reducers";
import { required } from "../../../../services/validations/reduxFormValidation";
import _ from "lodash";
import i18next from "i18next";
import style from "./notificationSettings.module.css";
import { NotificationSettingType } from "./notificationSettings.type";

const webhookUrlPatter = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
const webhookUrl = (value: string) => {
  return value && !value.match(webhookUrlPatter) ? i18next.t("validations.webhookUrl") : undefined;
};
export const NOTIFICATION_SETTINGS_FORM = "NOTIFICATION_SETTINGS_FORM";

export type NoficationSettingFormDataType = {
  real_time_notification_email: boolean;
  email_aggregation: boolean;
  notification_settings: Record<string, NotificationSettingType>;
};

interface NotificationSettingsReduxFormPropsType extends InjectedFormProps<NoficationSettingFormDataType> {}

const NoficationSettingsForm = ({ handleSubmit }: NotificationSettingsReduxFormPropsType) => {
  const { t } = useTranslation();
  const hasAdvanceTwoWayMatching = useTypedSelector((state) => state?.user?.company?.has_advance_two_way_matching);
  const formData = useTypedSelector(
    (state) => getFormValues(NOTIFICATION_SETTINGS_FORM)(state) as NoficationSettingFormDataType | null,
  );
  let groups: { [key: string]: NotificationSettingType[] } = {};
  if (formData?.notification_settings) {
    groups = _.groupBy(formData.notification_settings, "group");
  }

  return (
    <Form name="notificationSettingsForm" onSubmit={handleSubmit}>
      <Panel
        header={
          <Row>
            <Col className="mt-2">
              <i className="icon icon-notifications m-0 mx-2"></i> {t("components.admin.notificationSettingForm.title")}
            </Col>
            <Col>
              <Row className="d-flex justify-content-end align-items-center">
                <Field
                  name="email_aggregation"
                  type="checkbox"
                  label={t("components.admin.notificationSettingForm.dailySummaryEmail")}
                  component={RenderCheckBox}
                  className="mt-2 mx-2"
                />
                <Field
                  name="real_time_notification_email"
                  type="checkbox"
                  label={t("components.admin.notificationSettingForm.realTimeNotification")}
                  component={RenderCheckBox}
                  className="mt-2 mx-2"
                />
                <Button size="sm" className="mr-4" type="submit">
                  {t("save")}
                </Button>
              </Row>
            </Col>
          </Row>
        }
      >
        {groups &&
          Object.keys(groups).map((group_name) => {
            const group: NotificationSettingType[] = groups[group_name];
            return (
              <Table striped responsive key={group_name}>
                <thead>
                  <tr className="bg-light">
                    <th colSpan={7} className="font-weight-bold">
                      {group_name}
                    </th>
                  </tr>
                  <tr>
                    <th className={`${style.table} ${style.header} ${style.label}`}>
                      {t("components.admin.notificationSettingForm.categories")}
                    </th>
                    <th className={`${style.table} ${style.header} ${style.other}`}>
                      {t("components.admin.notificationSettingForm.inApp")}
                    </th>
                    <th className={`${style.table} ${style.header} ${style.other}`}>
                      {t("components.admin.notificationSettingForm.email")}
                    </th>
                    <th className={`${style.table} ${style.header} ${style.other}`}>
                      {t("components.admin.notificationSettingForm.mobile")}
                    </th>
                    <th className={`${style.table} ${style.header} ${style.other}`}>
                      {t("components.admin.notificationSettingForm.webhook")}
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Cannot use feildArray as notification_settings is object */}
                  {group.map((notification_setting: NotificationSettingType) => {
                    // options to show in  choose webhook service
                    const key = notification_setting.id;
                    let services: { label: string; value: string }[] = [];
                    if (_.isArray(notification_setting?.webhook_services)) {
                      services = notification_setting?.webhook_services!.map((service: string) => ({
                        label: service,
                        value: service,
                      }));
                    }

                    return (
                      <tr key={key}>
                        <td>{notification_setting.label}</td>
                        <td className="text-center">
                          <Field
                            id={`notification_settings.${key}.preference.app.enabled`}
                            name={`notification_settings.${key}.preference.app.enabled`}
                            type="checkbox"
                            component={RenderCheckBox}
                            disabled={
                              _.isArray(notification_setting.notification_method_banlist) &&
                              notification_setting?.notification_method_banlist?.includes("app")
                            }
                            className="justify-content-center"
                          />
                        </td>
                        <td>
                          <Field
                            id={`notification_settings.${key}.preference.email.enabled`}
                            name={`notification_settings.${key}.preference.email.enabled`}
                            type="checkbox"
                            component={RenderCheckBox}
                            disabled={
                              _.isArray(notification_setting.notification_method_banlist) &&
                              notification_setting?.notification_method_banlist?.includes("email")
                            }
                            className="justify-content-center"
                          />
                        </td>
                        <td>
                          <Field
                            id={`notification_settings.${key}.preference.mobile.enabled`}
                            name={`notification_settings.${key}.preference.mobile.enabled`}
                            type="checkbox"
                            component={RenderCheckBox}
                            disabled={
                              _.isArray(notification_setting?.notification_method_banlist) &&
                              notification_setting?.notification_method_banlist?.includes("mobile")
                            }
                            className="justify-content-center"
                          />
                        </td>
                        <td>
                          <Field
                            id={`notification_settings.${key}.preference.webhook.enabled`}
                            name={`notification_settings.${key}.preference.webhook.enabled`}
                            type="checkbox"
                            component={RenderCheckBox}
                            disabled={
                              _.isArray(notification_setting?.webhook_services) &&
                              notification_setting.webhook_services!.length <= 0
                            }
                            className="justify-content-center"
                          />
                        </td>
                        <td>
                          <Field
                            id={`notification_settings.${key}.preference.webhook.service`}
                            name={`notification_settings.${key}.preference.webhook.service`}
                            type="checkbox"
                            component={RenderSelect}
                            disabled={!notification_setting?.preference?.webhook?.enabled}
                            options={[{ label: "Choose Service", value: "" }, ...services]}
                            validate={notification_setting?.preference?.webhook?.enabled ? [required] : []}
                          />
                        </td>
                        <td>
                          <Field
                            className="notification-url-textbox"
                            id={`notification_settings.${key}.id+'url'`}
                            type="text"
                            name={`notification_settings.${key}.preference.webhook.url`}
                            placeholder="Webhook Url"
                            component={RenderField}
                            disabled={!notification_setting?.preference?.webhook?.enabled}
                            validate={notification_setting?.preference?.webhook?.enabled ? [required, webhookUrl] : []}
                          />
                        </td>
                      </tr>
                    );
                  })}

                  {!formData?.notification_settings && (
                    <tr>
                      <td colSpan={12} className="text-center">
                        {t("components.admin.notificationSettingForm.noRecords")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            );
          })}
      </Panel>
    </Form>
  );
};

const NoficationSettingsRedux = reduxForm<NoficationSettingFormDataType>({
  form: NOTIFICATION_SETTINGS_FORM,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(NoficationSettingsForm);

export default NoficationSettingsRedux;
