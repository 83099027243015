import { RenderCheckBox } from "../../../../../../components/forms/bootstrapFields";
import { CompanyDefaultsTypes } from "../../settingsTypes";

const receiptSettings: CompanyDefaultsTypes.CommonSettingFieldType[] = [
  {
    name: "company_default.receipt.disabled_unit_price",
    tooltip: {
      show: true,
      title: "Unit price field will be disabled from receipt form .",
      placement: "top",
    },
    label: "Disable Unit Price",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj) => {},
    col: 1,
    validation: [],
    require: undefined,
    class: "",
  },
  {
    name: "company_default.receipt.is_required_description",
    tooltip: {
      show: true,
      title: "Description will be required field at receipt form",
      placement: "top",
    },
    label: "Description required",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 2,
    validation: [],
    require: false,
    class: "",
  },
  {
    name: "company_default.receipt.create_bulk_receipts_with_source_document",
    tooltip: {
      show: true,
      title: "Source document will be added to each receipt created through bulk receipt upload.",
      placement: "top",
    },
    label: "Create bulk receipts with source document",
    type: "checkbox",
    component: RenderCheckBox,
    isShow: (isShowObj) => {
      return true;
    },
    onChangeCallBack: (onChangeObj, e) => {},
    col: 3,
    validation: [],
    require: false,
    class: "",
  },
];

export default receiptSettings;
