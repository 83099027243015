import React from "react";
import { withRouter } from "react-router-dom";
import { GetFormattedExpirationDate } from "../../services/virtualCards/virtualCardsService";

class VirtualCardCellRenderer extends React.Component {
  render() {
    if (this.props.colDef.cellRendererParams && this.props.colDef.cellRendererParams.cardNumber) {
      return <div>{this.props.value}</div>;
    } else if (this.props.colDef.cellRendererParams && this.props.colDef.cellRendererParams.cardExpiry) {
      return <div>{GetFormattedExpirationDate(this.props.value)}</div>;
    } else {
      return <div>{this.props.value}</div>;
    }
  }
}

export default withRouter(VirtualCardCellRenderer);
