import { ColumnApi, GetRowIdParams, GridApi, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import ServerSideDataGrid from "components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import { flatten } from "lodash";
import React, { useCallback, useRef } from "react";
import { Row } from "react-bootstrap";
import { CardPaymentsApis } from "services/admin/cards/payments/cardPaymentsApis";
import { getCardPaymentMethodsHeaders } from "./listCardPaymentMethodsHeaders.tsx";

export const ListCardPaymentMethods = () => {
  const gridApiRef = useRef<GridApi>();
  const columnApiRef = useRef<ColumnApi>();
  const gridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    columnApiRef.current = params.columnApi;
  }, []);

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  const getRows = useCallback(async (params: IServerSideGetRowsParams) => {
    try {
      const res = await CardPaymentsApis.statementAccounts();
      const statementsData = res.statement_accounts.filter((item) => !!item);
      const paymentAccountsData = flatten(statementsData.map((item) => item.statement_payment_accounts));
      params.success({
        rowData: paymentAccountsData,
      });
    } catch (error) {
      params.fail();
    }
  }, []);

  const gridHeaders = getCardPaymentMethodsHeaders();

  return (
    <>
      <Row className="mx-0 w-100" style={{ height: "200px" }}>
        <ServerSideDataGrid
          columnDefs={gridHeaders.columnDefs}
          defaultColDef={{
            resizable: true,
            filter: true,
            floatingFilter: false,
            suppressMovable: true,
          }}
          hideSideBar
          gridReady={gridReady}
          rowSelection="multiple"
          domLayout="normal"
          gridOptions={{
            serverSideDatasource: { getRows },
          }}
          getRowId={(params: GetRowIdParams) => params?.data?.account_number}
        />
      </Row>
    </>
  );
};
