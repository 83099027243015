import React from "react";
import DateFilter from "../reportFilters/date";
import DropdownFilter from "../reportFilters/dropdown";
import ScheduledDateRange from "../reportFilters/scheduledDateRange";
import UserDropdown from "../reportFilters/usersDropdown";

const AuditReport = ({ scheduled }: { scheduled?: boolean }) => {
  const auditEvents = [
    { value: "", label: "ALL" },
    { value: "create", label: "CREATE" },
    { value: "update", label: "UPDATE" },
    { value: "destroy", label: "DESTROY" },
  ];

  const auditEventType = [
    { value: "", label: "ALL" },
    { value: "Asset", label: "ASSET" },
    { value: "Contact", label: "CONTACT" },
    { value: "PoItem", label: "PURCHASE ORDER ITEM" },
    { value: "PurchaseOrder", label: "PURCHASE ORDER" },
    { value: "Schedule", label: "SCHEDULE" },
    { value: "Invoice", label: "INVOICE" },
    { value: "Vendor", label: "VENDOR" },
    { value: "PaymentMethod", label: "PAYMENT METHOD" },
  ];

  const renderDateFilter = () => {
    if (scheduled) {
      return <ScheduledDateRange name={"Schedule Date Range"} code={"schedule_date_range"} options={[]} isRequired />;
    } else {
      return (
        <>
          <DateFilter name={"Start Date"} code={"start_date"} options={[]} isRequired />
          <DateFilter name={"End Date"} code={"end_date"} options={[]} isRequired />
        </>
      );
    }
  };

  return (
    <div>
      <p>
        * The maximum date range is 1 year for this report. The date range will be automatically adjusted to insure
        this.
      </p>
      {renderDateFilter()}
      <UserDropdown name={"User"} code={"user_id"} options={[]} />
      <DropdownFilter name={"Event"} code={"event"} options={auditEvents} />
      <DropdownFilter name={"Item Type"} code={"item_type"} options={auditEventType} />
    </div>
  );
};

export default AuditReport;
