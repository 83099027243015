import _ from "lodash";
import { Button, Col, Row } from "react-bootstrap";
import { GrAttachment } from "react-icons/gr";
import { IAttachment } from "../../../services/common/types/common.type";
import { useTranslation } from "react-i18next";
import CustomModal from "../../modals/customModal";
import PreviewComponent, { PreviewSize } from "../attachmentPreview/previewComponent";
import React, { ReactNode, useEffect, useState } from "react";
import style from "./serialAttachments.module.css";

export type serialAttachmentsPropsTypes = {
  attachments: IAttachment[];
  onCloseAttachmentCallback?: () => void;
  onAttachmentChangeCallback?: (selectedAttachment: IAttachment) => void;
  customComponent?: ReactNode;
  useModal?: boolean;
  previewAccessor?: boolean;
  carouselPreviewAccessor?: boolean;
  previewSize?: PreviewSize;
};

// Job of this component is to show attachments and attachment preview nothing else
const SerialAttachments = ({
  attachments,
  onAttachmentChangeCallback,
  onCloseAttachmentCallback,
  customComponent,
  useModal,
  previewAccessor,
  carouselPreviewAccessor,
  previewSize,
}: serialAttachmentsPropsTypes) => {
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const { t } = useTranslation();

  // here will change only selectAttachmentIndex other changes will reflect automatically
  const [selectAttachmentIndex, setSelectAttachmentIndex] = useState<number>(0);
  const selectedAttachment = attachments[selectAttachmentIndex];
  const assetUrl =
    _.isPlainObject(selectedAttachment) &&
    (selectedAttachment.url ? selectedAttachment.url : selectedAttachment.asset_expiring_url);
  const assetName =
    _.isPlainObject(selectedAttachment) &&
    (selectedAttachment.filename ? selectedAttachment.filename : selectedAttachment.asset_file_file_name);

  const isPrevDisabled = selectAttachmentIndex === 0;
  const isNextDisable = _.isArray(attachments) && selectAttachmentIndex === attachments.length - 1;

  const assetUrlAtIndex = (index: number) => {
    if (index < 0 || index >= attachments.length) {
      return "";
    }
    const attachment = attachments[index];
    return attachment.url ? attachment.url : attachment.asset_expiring_url;
  };

  const onHide = () => {
    setIsAttachmentModalOpen(false);
    if (onCloseAttachmentCallback) {
      onCloseAttachmentCallback();
    }
    performCleanUp();
  };

  const onClickAttachment = (index: number) => {
    setSelectAttachmentIndex((prev) => index);
    setIsAttachmentModalOpen(true);
  };

  const onClickNext = () => {
    const thumbnail = document.getElementById(`thumbnail_image_${selectAttachmentIndex + 1}`);
    if (thumbnail) {
      thumbnail.scrollIntoView();
    }
    setSelectAttachmentIndex((prev) => prev + 1);
  };

  const onClickPrev = () => {
    const thumbnail = document.getElementById(`thumbnail_image_${selectAttachmentIndex - 1}`);
    if (thumbnail) {
      thumbnail.scrollIntoView();
    }
    setSelectAttachmentIndex((prev) => prev - 1);
  };

  // on close preview model
  const performCleanUp = () => {
    setSelectAttachmentIndex(0);
  };

  useEffect(() => {
    if (onAttachmentChangeCallback && selectedAttachment) {
      onAttachmentChangeCallback(selectedAttachment);
    }
    // if current attachment changes then update on parent
    // so that parent can perform any action required
  }, [selectedAttachment]);

  const PreviewTemplate = (
    <>
      {/* don't need next and prev button is there is only one attachment */}
      {_.isArray(attachments) && attachments.length > 1 && (
        <Row>
          <Col className="my-1">
            <button
              disabled={isPrevDisabled}
              className={`btn primaryLinkColor ${isPrevDisabled ? "not-allowed" : ""}`}
              onClick={() => onClickPrev()}
            >
              <i className="icon m-0 icon-arrow-left-blue" /> previous
            </button>
          </Col>
          <Col className="my-1">
            <button
              disabled={isNextDisable}
              className={`btn bg-transparent float-right primaryLinkColor ${isNextDisable ? "not-allowed" : ""}`}
              onClick={() => onClickNext()}
            >
              next <i className="icon m-0 icon-arrow-right-blue float-right " />
            </button>
          </Col>
        </Row>
      )}

      {customComponent && <>{customComponent}</>}
      {assetUrl && (
        <Row key={assetUrl}>
          <Col>
            {assetName && <PreviewComponent previewSize={previewSize} fileName={assetName} filePath={assetUrl} />}
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <>
      {useModal && (
        <CustomModal
          show={isAttachmentModalOpen}
          onHide={() => onHide()}
          size={"xl"}
          header={"File Attachment Preview"}
          body={<>{PreviewTemplate}</>}
          footer={
            <>
              <Button variant="secondary" onClick={onHide}>
                Close
              </Button>
              {/* @ts-ignore */}
              <Button variant="primary" download href={assetUrl} target="_blank">
                Download
              </Button>
            </>
          }
        />
      )}

      {!useModal && (
        <Row>
          <Col>{PreviewTemplate}</Col>
        </Row>
      )}

      {carouselPreviewAccessor && (
        <>
          <Row className={style.attachmentCounterContainer}>
            {selectAttachmentIndex + 1} {t("serialAttachments.of")} {attachments.length}{" "}
            {t("serialAttachments.attachments")}
          </Row>
          <Row
            className={style.attachmentCounterContainer}
            title={`${t("serialAttachments.current")} ${selectedAttachment.filename ?? selectedAttachment.asset_file_file_name}`}
          >
            {t("serialAttachments.current")} {selectedAttachment.filename ?? selectedAttachment.asset_file_file_name}
          </Row>
          <Row>
            <div className={style.carouselContainer}>
              <table>
                <tr>
                  {attachments.map((attachment: IAttachment, index: number) => (
                    <td className={style.carouselCell}>
                      <img
                        alt={attachment.filename ?? attachment.asset_file_file_name}
                        src={assetUrlAtIndex(index)}
                        className={style.carouselImage}
                        onClick={() => onClickAttachment(index)}
                        key={index}
                        id={`thumbnail_image_${index}`}
                      ></img>
                    </td>
                  ))}
                </tr>
              </table>
            </div>
          </Row>
        </>
      )}

      {previewAccessor &&
        attachments.map((attachment: IAttachment, index: number) => (
          <div className="ml-2" key={attachment.key || attachment.id}>
            <div role="button" onClick={() => onClickAttachment(index)} className={style.link + " ml-2"}>
              <GrAttachment className={"pr-1"} />
              {attachment.filename ?? attachment.asset_file_file_name}
            </div>
          </div>
        ))}
    </>
  );
};

export default SerialAttachments;
