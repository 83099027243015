import { AxiosResponse } from "axios";
import _ from "lodash";
import { restApiService } from "providers/restApi";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Field, change, getFormValues } from "redux-form";
import styles from "./customFieldPicker.module.css";
import { RenderDatePicker, RenderField } from "components/forms/bootstrapFields";
import { required } from "services/validations/reduxFormValidation";
import { ItemPickerTemplate } from "./itemPickerTemplate";
import SelectItemPicker from "./selectItemPicker";
import { Mandatory } from "components/forms/input/mandatory";
import CustomFieldCheckBox from "./customFieldCheckbox";
import { useTypedSelector } from "reducers";
import OtherInputField from "./otherInputField";

type CustomFieldPickerPropsType = {
  modelData: any; // value of object where custom_fields is sitting
  modelDataFieldName: string; // object where custom_fields are stored, it will be empty string if stored root of the form
  parentObjData: any;
  modelName: string;
  formFieldName: string; // object where we want to store custom fields
  formName: string;
  inline?: boolean;
  absPath?: string; // absolute path where we want to update data with respect to MAIN form
  parentDivId?: string;
  parentDivVariance?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  menuAutoFixed?: boolean;
};

const CustomFieldPicker = ({
  modelData,
  modelName,
  formFieldName,
  formName,
  modelDataFieldName,
  inline,
  absPath,
  parentDivId,
  parentDivVariance,
  menuAutoFixed,
}: CustomFieldPickerPropsType) => {
  const [formattedFields, setFormattedFields] = useState<any[]>();
  const formData: any | null = useTypedSelector((state) => getFormValues(formName)(state));

  const getValueFromForm = (fieldName: string) => {
    return _.get(formData, fieldName);
  };
  const DateRef = useRef<Date>();

  const uuid = Math.random().toString(36).substr(2, 5);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Create a unique name for input fields so they don't interfere with each other
  const generateUniqueName = () => {
    return "custom_field_" + uuid;
  };

  const [uniqueName, setUniqueName] = useState<string>(generateUniqueName());

  const formatCustomFieldsAttributes = (data: any) => {
    setFormattedFields(data);
    data.forEach((customField: any, key: number) => {
      if (customField.name && modelData && modelData.custom_fields && modelData.custom_fields[customField.name]) {
        /* Make sure all the modelData.custom_fields have a name attribute
         * so there won't be any issues accessing it later
         */
        dispatch(change(formName, `${absPath ? absPath : formFieldName}.${customField.name}.name`, customField.name));
      }
    });
  };

  const getCustomFields = async () => {
    try {
      const response: AxiosResponse = await restApiService.get(
        "custom_field_metadata",
        { model: modelName },
        null,
        true,
        null,
        true,
      );
      if (_.isArray(response.data) && response.data.length > 0) {
        formatCustomFieldsAttributes(response.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const checkIsOtherCheckBoxDisable = (fieldName: string, item: string): boolean => {
    const value = getValueFromForm(fieldName);
    return _.isArray(value) && value.includes(item);
  };

  const onCheckboxChange = (checked: any, customField: any, item: string) => {
    const checkboxes = getValueFromForm(`${absPath ? absPath : formFieldName}.${customField.name}.value`);

    if (checked) {
      if (_.isArray(checkboxes)) {
        dispatch(
          change(formName, `${absPath ? absPath : formFieldName}.${customField.name}.value`, [...checkboxes, item]),
        );
      } else {
        dispatch(change(formName, `${absPath ? absPath : formFieldName}.${customField.name}.value`, [item]));
      }
    } else {
      if (_.isArray(checkboxes)) {
        const updatedCheckboxes = checkboxes.filter((checkValue: string) => checkValue !== item);
        dispatch(change(formName, `${absPath ? absPath : formFieldName}.${customField.name}.value`, updatedCheckboxes));
      } else {
        dispatch(change(formName, `${absPath ? absPath : formFieldName}.${customField.name}.value`, []));
      }
    }
  };

  useEffect(() => {
    getCustomFields();
  }, []);

  return (
    <>
      {formattedFields && _.isArray(formattedFields) && formattedFields.length > 0 && (
        <Row id="custom_questions">
          <Col>
            {!inline && (
              <Row className={styles.rowSeparator}>
                <Col>
                  <label className={styles.sectionTitle}>{t("admin.customField.customFieldHeader")}</label>
                </Col>
              </Row>
            )}
            <Row>
              <Col className={`${inline ? styles.inline : ""}`}>
                {formattedFields.map((customField, key) => {
                  return (
                    <div key={customField.name + "-" + key} className={styles.aCustomField}>
                      {/* TODO: implement database handled logic for customField.hidden */}
                      {customField?.status !== "INACTIVE" && !customField.hidden && (
                        <div key={customField.name}>
                          {customField.type === "boolean" && !customField.items && (
                            <div key={customField.name + key + customField.type}>
                              <label className="">{customField.label}</label>
                              <Mandatory required={customField.required} />
                              <br />
                              <Field
                                key={`${uniqueName}_${key}_true`}
                                id={`${uniqueName}_${key}_true`}
                                name={`${formFieldName}.${customField.name}.value`}
                                component="input"
                                type="radio"
                                value="Yes"
                                required={customField.required}
                                validate={customField.required ? [required] : []}
                                // onChange={() => eval(customField.action_code)}
                                /* TODO: implement database handled logic for onchange */
                              />

                              <label className={styles.radioButtonLbl}>YES</label>
                              <br />
                              <Field
                                key={`${uniqueName}_${key}_false`}
                                id={`${uniqueName}_${key}_false`}
                                name={`${formFieldName}.${customField.name}.value`}
                                component="input"
                                type="radio"
                                value="No"
                                required={customField.required}
                                validate={customField.required ? [required] : []}
                                // onChange={() => eval(customField.action_code)}
                                /* TODO: implement database handled logic for onchange */
                              />

                              <label id="no" className={styles.radioButtonLbl}>
                                NO
                              </label>
                            </div>
                          )}

                          {customField.type === "boolean" &&
                            _.isArray(customField.items) &&
                            customField.items.length > 0 && (
                              <>
                                <label id={key.toString()} className="">
                                  {customField.label}
                                </label>
                                <Mandatory required={customField.required} />
                                <br />
                                {customField?.items.map((item: string) => (
                                  <div key={item}>
                                    <Field
                                      key={`${uniqueName}_${key}_${customField.name}`}
                                      id={`${uniqueName}_${key}_${customField.name}`}
                                      name={`${formFieldName}.${customField.name}.value`}
                                      component="input"
                                      type="radio"
                                      value={item}
                                      required={customField.required}
                                      validate={customField.required ? [required] : []}
                                    />
                                    <label className={styles.radioButtonLbl}>{item}</label>
                                    <br />
                                  </div>
                                ))}
                              </>
                            )}
                          {customField.type === "checkbox" && (
                            <>
                              {customField?.label && (
                                <>
                                  <label key={key.toString()}>{customField.label}</label>
                                  <Mandatory required={customField.required} />
                                </>
                              )}
                              {customField?.items.map((item: string, index: number) => (
                                <div key={`${customField.type}-${index}-${item}`}>
                                  {item !== "Other:" && (
                                    <div key={`${index}-${item}`}>
                                      <Field
                                        key={`${uniqueName}_${key}_checkbox_${key}`}
                                        id={`${uniqueName}_${key}_checkbox_${key}`}
                                        name={`${formFieldName}.${customField.name}.check`}
                                        // due requirement to store the checkboxes value in array using this check
                                        // it keep track of value on invoice form, and we will update array
                                        item={item}
                                        label={item}
                                        component={CustomFieldCheckBox}
                                        onChange={(check: any) => onCheckboxChange(check, customField, item)}
                                        type="checkbox"
                                        required={customField.required}
                                        validate={customField?.required ? [required] : []}
                                        formName={formName}
                                        formFieldName={`${formFieldName}.${customField.name}`}
                                      />
                                    </div>
                                  )}
                                  {item === "Other:" && (
                                    <div key={`${index}-${item}-other`} className="d-flex">
                                      <Field
                                        key={`${uniqueName}_${key}_checkbox_Other_${key}`}
                                        id={`${uniqueName}_${key}_checkbox_Other_${key}`}
                                        name={`${formFieldName}.${customField.name}.check`}
                                        label={item}
                                        item={item}
                                        component={CustomFieldCheckBox}
                                        onChange={(check: any) => onCheckboxChange(check, customField, item)}
                                        type="checkbox"
                                        formName={formName}
                                        formFieldName={`${formFieldName}.${customField.name}`}
                                      />
                                      <Field
                                        id={`${uniqueName}_${key}_checkbox_Other_input_${key}`}
                                        name={`${formFieldName}.${customField.name}.otherCheckbox`}
                                        formName={formName}
                                        formFieldName={`${formFieldName}.${customField.name}`}
                                        component={OtherInputField}
                                        disabled={
                                          !checkIsOtherCheckBoxDisable(
                                            `${formFieldName}.${customField.name}.value`,
                                            "Other:",
                                          )
                                        }
                                      ></Field>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </>
                          )}
                          {customField.type === "text" && (
                            <Field
                              // name={`custom_fields[${customField.name}].value`}
                              name={`${formFieldName}.${customField.name}.value`}
                              key={`${uniqueName}_${key}_text_${customField.name}_${key}`}
                              id={`${uniqueName}_${key}_text_${customField.name}_${key}`}
                              component={RenderField}
                              type="text"
                              label={customField.label}
                              className={`w-100`}
                              maxLength={customField.maxlength}
                              disabled={customField.readonly}
                              required={customField?.required}
                              validate={customField?.required ? [required] : []}
                            />
                          )}
                          {customField.type === "date" && (
                            <Field
                              key={`${uniqueName}_${key}_date_${customField.name}_${key}`}
                              id={`${uniqueName}_${key}_date_${customField.name}_${key}`}
                              // name={`custom_fields[${customField.name}].value`}
                              name={`${formFieldName}.${customField.name}.value`}
                              label={customField.label}
                              component={RenderDatePicker}
                              required={customField?.required}
                              valueRef={DateRef}
                              formatDateOutput={true}
                              validate={customField?.required ? [required] : []}
                            />
                          )}
                          {customField.type === "number" && (
                            <Field
                              key={`${uniqueName}_${key}_number_${customField.name}_${key}`}
                              id={`${uniqueName}_${key}_number_${customField.name}_${key}`}
                              name={`${formFieldName}.${customField.name}.value`}
                              type="number"
                              component={RenderField}
                              label={customField.label}
                              required={customField?.required}
                              validate={customField?.required ? [required] : []}
                            />
                          )}
                          {customField.type === "select" && (
                            <>
                              {customField?.label && (
                                <label>
                                  {customField.label}
                                  {customField.required === true && <Mandatory required={customField.required} />}
                                </label>
                              )}

                              {customField?.items_picker && (
                                <ItemPickerTemplate
                                  absPath={absPath}
                                  formFieldName={formFieldName}
                                  customField={customField}
                                  modelDataName={modelName}
                                  parentDivId={parentDivId}
                                  parentDivVariance={parentDivVariance}
                                  menuAutoFixed={menuAutoFixed}
                                />
                              )}

                              {customField.items && (
                                <SelectItemPicker
                                  modelData={modelData}
                                  modelDataFieldName={modelDataFieldName}
                                  formName={formName}
                                  formFieldName={formFieldName}
                                  absPath={absPath}
                                  customField={customField}
                                  parentDivId={parentDivId}
                                  parentDivVariance={parentDivVariance}
                                  menuAutoFixed={menuAutoFixed}
                                />
                              )}
                            </>
                          )}

                          {customField.type === "label" && customField.label}
                        </div>
                      )}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CustomFieldPicker;
