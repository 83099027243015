import React, { createContext } from "react";
import { DocumentRequirementsType } from "../../../services/vp/purchaser/purchaserTypes";

export type VDocumentUploadType = {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  isValidated: boolean;
  setIsValidated: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFrom: "w9" | "w8" | "w8bene" | "diversity" | undefined;
  setSelectedFrom: React.Dispatch<React.SetStateAction<"w9" | "w8" | "w8bene" | "diversity" | undefined>>;
  showUploadModal: boolean;
  closeUploadModal: () => void;
  documentRequirement?: DocumentRequirementsType;
  W8Form: JSX.Element;
  getW8FormData: () => string | undefined;
  W8BenEForm: JSX.Element;
  getW8BenEFormData: () => string | undefined;
  W9Form: JSX.Element;
  getW9FormData: () => string | undefined;
  diversityTrackingForm: JSX.Element;
  getDiversityTrackingFormData: () => void;
  file: File | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  submitUploadModal: (e: React.FormEvent<HTMLFormElement>) => void;
  isSubmitting: boolean;
  showTemplateSection: boolean | undefined;
};

export const VDocumentUploadContext = createContext<VDocumentUploadType | undefined>(undefined);
