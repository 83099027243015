import Details from "./details";
import Edit from "./edit";

const vpCompanyProfileRoutes = [
  {
    path: "/ar/settings",
    exact: true,
    name: "CompanyProfile",
    component: Details,
    authorization: {
      I: "read",
      a: "Company",
    },
  },
  {
    path: "/ar/company/edit",
    exact: true,
    name: "EditCompanyInfo",
    component: Edit,
    authorization: {
      I: "edit",
      a: "Company",
    },
  },
];

export default vpCompanyProfileRoutes;
