import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Styles from "../override.module.css";
import { findSelectedMultiple, onBlur, onChange } from "../../../../../services/general/helpers";
import PickerErrorBlock from "components/common/pickers/pickerErrorBlock";
import { QuestionPickerPropsType } from ".";

interface MultipleQuestionPickerPropsType extends Omit<QuestionPickerPropsType, "approvalType"> {
  questions: { label: string; value: string }[];
}

const MultipleQuestionPicker = ({
  className,
  tooltip,
  label,
  input,
  meta: { touched, error, warning },
  questions,
}: MultipleQuestionPickerPropsType) => {
  return (
    <>
      <Form.Group className={Styles.select}>
        {label && (
          <Form.Label>
            {label}
            {tooltip}
          </Form.Label>
        )}
        <Select
          {...input}
          required
          value={findSelectedMultiple(input, questions)}
          placeholder="search/select"
          onChange={(value) => onChange(input, value)}
          onBlur={() => onBlur(input, input.value)}
          options={questions}
          isMulti
          isClearable
          classNamePrefix="select"
        />
        {error && touched && <PickerErrorBlock error={error} />}
      </Form.Group>
    </>
  );
};

export default MultipleQuestionPicker;
