import React from "react";
import { Link } from "react-router-dom";
import { GoPencil } from "react-icons/go";
import { restApiService } from "../../providers/restApi";
import { Button } from "react-bootstrap";

type EditButtonPropsType = {
  href?: string;
  buttonClasses?: string;
  link?: string;
  text: string;
  callBack?: () => void;
};

const EditButton = ({ href, buttonClasses, link, text, callBack }: EditButtonPropsType) => {
  return (
    <>
      {href ? (
        <a href={restApiService.angularBaseURL() + href} className={buttonClasses}>
          <Button type="button" variant="primary">
            <i className="icon-g icon-edit-forword"></i>
            {text}
          </Button>
        </a>
      ) : link ? (
        <Link to={link} className={buttonClasses}>
          <Button type="button" variant="primary">
            <i className="icon-g icon-edit-forword"></i>
            {text}
          </Button>
        </Link>
      ) : (
        <Button type="button" onClick={callBack} variant="primary">
          <i className="icon-g icon-edit-forword"></i>
          {text}
        </Button>
      )}
    </>
  );
};

export default EditButton;
