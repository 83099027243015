import CompanyDefault from "./companyDefaults";
import Integrations from "./integrations";
import CompanyAccounts from "./companyAccounts";
import CompanyPaymentMethod from "./paymentMethods";
import Landing from "./landing";

// subTabs should be created in index of main companySettings page
// add new tabs here
export const settingsRoutes = [
  {
    path: "/ap/settings",
    exact: true,
    name: "Addresses",
    component: Landing,
    authorization: {
      I: "list",
      a: "Settings",
    },
  },
  {
    path: "/ap/settings/company_default",
    exact: true,
    name: "Company Defaults",
    component: CompanyDefault,
    authorization: {
      I: "list",
      a: "Settings",
    },
  },
  {
    path: "/ap/settings/integrations",
    exact: true,
    name: "Integrations",
    component: Integrations,
    authorization: {
      I: "list",
      a: "Settings",
    },
  },
  {
    path: "/ap/settings/payment_methods",
    exact: true,
    name: "Integrations",
    component: CompanyPaymentMethod,
    authorization: {
      I: "list",
      a: "Settings",
    },
  },
  {
    path: "/ap/settings/accounts",
    exact: true,
    name: "Company Accounts",
    component: CompanyAccounts,
    authorization: {
      I: "list",
      a: "Settings",
    },
  },
];
