import moment from "moment";
import { translate } from "../../../services/general/translation";
const vendorInvoicesRequestHeader = [
  {
    field: "selectAll",
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    maxWidth: 40,
    minWidth: 40,
    menuTabs: [],

    // sortable: true ,
    // filter: true,
  },
  {
    field: "id",
    headerName: "ID",
    hide: true,
    headerValueGetter: function () {
      return translate("id");
    },
  },

  {
    field: "purchaser.name",
    headerName: "Customer",
    cellRenderer: "childCellRouting",
    filter: "agTextColumnFilter",
    filterParams: {
      // clearButton: true,
      // applyButton: true,
      // debounceMs: 200,
      suppressAndOrCondition: true,
    },
    cellStyle: {
      color: "#FF5C35",
      fontFamily: "acre-regular",
      fontSize: 14,
      fontWeight: "bold",
    },
    headerValueGetter: function () {
      return translate("customer");
    },
  },
  {
    field: "sent_date",
    headerName: "Date",
    filter: "agDateColumnFilter",
    // cellRenderer: (data) => {
    //     return moment(data.value).format('DD/MM/YYYY');
    // },
    filterParams: {
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        console.log(filterLocalDateAtMidnight);
        console.log(cellValue);

        // var dateForm = new Date(cellValue)
        // console.log(dateForm)

        // var dateAsString = cellValue.replace(/-/g, "/"); //changing date format /g for global
        //
        // console.log(dateAsString)
        var dateAsString = moment(cellValue).format("DD/MM/YYYY");
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        console.log(dateParts);
        var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
        console.log(cellDate);
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
      headerValueGetter: function () {
        return translate("date");
      },
    },
  },
  {
    field: "",
    headerName: "Accrual",
    // 'field':'Actions',
    cellRenderer: "childMessageRenderer",
    sortable: false,
    resizable: false,
    width: 250,
    maxWidth: 250,
    // pinned: 'right',
    cellRendererSelector: function (params) {
      return;
    },
  },
];

export default vendorInvoicesRequestHeader;
