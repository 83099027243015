import { RenderTextArea } from "components/forms/bootstrapFields";
import ContactPicker from "components/pickers/reduxFormPicker/contactPicker";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import ApprovalsApis from "services/admin/approvals/approvalsApis";
import { IDType } from "services/common/types/common.type";
import { CreateNotification } from "services/general/notifications";
import { required } from "services/validations/reduxFormValidation";

const REQUEST_APPROVAL_FORM = "REQUEST_APPROVAL_FORM";

type RequestApprovalFormDataType = {
  approval: {
    contact_approver: {
      value: IDType;
      label: string;
      id: IDType;
      name: string;
      email: string;
      external_id: IDType;
    };
  };
  requestor_note: string;
};

type RequestApprovalFormComponentCustomPropType = {
  closeRequestApproval: () => void;
  refreshApprovable: () => void;
  approvableType: string;
  approvableId: IDType;
};

type RequestApprovalFormComponentPropType = InjectedFormProps<
  RequestApprovalFormDataType,
  RequestApprovalFormComponentCustomPropType
> &
  RequestApprovalFormComponentCustomPropType;

const RequestApprovalFormComponent = ({
  handleSubmit,
  closeRequestApproval,
  approvableId,
  approvableType,
  refreshApprovable,
}: RequestApprovalFormComponentPropType) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (value: RequestApprovalFormDataType) => {
    const approval = {
      approvable_id: approvableId,
      approvable_type: approvableType,
      name: value.approval.contact_approver.name,
      email: value.approval.contact_approver.email,
      contact_approver_id: value.approval.contact_approver.id,
    };

    try {
      setIsSubmitting(true);
      const result = await ApprovalsApis.requestApproval({ approval });
      CreateNotification("Approval Request", "Approval Request sent!", "success");
      setIsSubmitting(false);
      closeRequestApproval();
      refreshApprovable();
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <Form noValidate name={REQUEST_APPROVAL_FORM} onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md="12">
          <Field
            name="approval.contact_approver"
            label="Approver"
            component={ContactPicker}
            contactType="USERS"
            validate={[required]}
            useObject
            required
          />
        </Col>
        <Col md="12">
          <Field
            label="Note to Approver"
            id="request_approval_note_to_approver"
            rows="5"
            name="approval.requestor_note"
            component={RenderTextArea}
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button variant="secondary" className="mr-2" onClick={closeRequestApproval}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit">
            Send Approval Request
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const RequestApprovalForm = reduxForm<RequestApprovalFormDataType, RequestApprovalFormComponentCustomPropType>({
  form: REQUEST_APPROVAL_FORM,
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  touchOnChange: false,
  touchOnBlur: false,
})(RequestApprovalFormComponent);

export default RequestApprovalForm;
