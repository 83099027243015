import React from "react";
import { Col, Row } from "react-bootstrap";
import { getFormValues } from "redux-form";
import InternationalTw from "./internationalTw/internationalTw";
import AchBankAccount from "./ach/achBankAccount";
import CheckBankAccount from "./check/checkBankAccount";
import DomaticFedWire from "./domasticFedwire/domesticFedwire";
import UkEftBankAccount from "./ukEft/ukEftBankAccount";
import JapanEft from "./japanEft/japanEft";
import SepaInternational from "./sepa/sepaInternaltional";
import ExternalPayment from "./external/externalPayament";
import SwiftWire from "./swiftWire/swiftWire";
import WirePayment from "./internationalTw/internationalTw";
import { useTypedSelector } from "../../../../reducers";
import PaymentType from "../fields/paymentType";
import { required } from "../../../forms/inputFields";
import { MANAGE_PAYMENT_METHOD_FORM } from "../../../../services/common/paymentMethod.svc";
import { ManagePaymentMethodFormDataType } from "../../../common/managePaymentMethod/types";
import paymentTypeService from "../../../../services/common/paymentMethod/paymentTypeSvc";
import CreditCard from "./creditCard/creditCard";

const PaymentFormTemplate = () => {
  const formData = useTypedSelector(
    (state) => getFormValues(MANAGE_PAYMENT_METHOD_FORM)(state) as ManagePaymentMethodFormDataType | null,
  );

  return (
    <>
      {formData &&
        (formData.payment_type === "" ||
          paymentTypeService.isCanEft(formData.payment_type) ||
          paymentTypeService.isNzEft(formData.payment_type) ||
          paymentTypeService.isCambridge(formData.payment_type) ||
          paymentTypeService.isPayoneer(formData.payment_type) ||
          paymentTypeService.isAusPayNet(formData.payment_type)) && (
          <Row>
            <Col md="4">
              <PaymentType label="Payment Type:" required validations={[required]} />
            </Col>
          </Row>
        )}

      {formData && paymentTypeService.isTransfterwise(formData.payment_type) && (
        <Row>
          <Col md="12">
            <InternationalTw />
          </Col>
        </Row>
      )}

      {formData && paymentTypeService.isWire(formData.payment_type) && (
        <Row>
          <Col md="12">
            <WirePayment />
          </Col>
        </Row>
      )}

      {formData && paymentTypeService.isAch(formData.payment_type) && (
        <Row>
          <Col md="12">
            <AchBankAccount />
          </Col>
        </Row>
      )}

      {formData && paymentTypeService.isCheck(formData.payment_type) && (
        <Row>
          <Col md="12">
            <CheckBankAccount />
          </Col>
        </Row>
      )}

      {formData && paymentTypeService.isCreditCard(formData.payment_type) && (
        <Row>
          <Col md="12">
            <CreditCard />
          </Col>
        </Row>
      )}

      {formData && paymentTypeService.isFedWire(formData.payment_type) && (
        <Row>
          <Col md="12">
            <DomaticFedWire />
          </Col>
        </Row>
      )}
      {formData && paymentTypeService.isUkEft(formData.payment_type) && (
        <Row>
          <Col md="12">
            <UkEftBankAccount />
          </Col>
        </Row>
      )}
      {formData && paymentTypeService.isJanpanEft(formData.payment_type) && (
        <Row>
          <Col md="12">
            <JapanEft />
          </Col>
        </Row>
      )}
      {formData && paymentTypeService.isSepa(formData.payment_type) && (
        <Row>
          <Col md="12">
            <SepaInternational />
          </Col>
        </Row>
      )}
      {formData && paymentTypeService.isExteranl(formData.payment_type) && (
        <Row>
          <Col md="12">
            <ExternalPayment />
          </Col>
        </Row>
      )}
      {formData && paymentTypeService.isSwiftWire(formData.payment_type) && (
        <Row>
          <Col md="12">
            <SwiftWire />
          </Col>
        </Row>
      )}
    </>
  );
};

export default PaymentFormTemplate;
