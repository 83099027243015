import { useTranslation } from "react-i18next";
import { noWhiteSpaceOnly, required } from "../../../../services/validations/reduxFormValidation";
import BankRoutingSample from "../../../common/bankingRoutingSample/bankRoutingSample";
import ContactFirstName from "../fields/contactFirstName";
import ContactLastName from "../fields/contactLastName";
import CurrencyCode from "../fields/currencyCode";
import DebitCreditFields from "../fields/debitCreditFields";
import InternationalPayoutCheckBoxes from "../fields/internationalPayoutCheckBoxes";
import Memo from "../fields/memo";
import PaymentTypeSelect from "../fields/paymentTypeSelect";
import Status from "../fields/status";
import { useSelector } from "react-redux";

const Sepa = () => {
  const { t } = useTranslation();
  const current_user = useSelector((state) => state.user);

  return (
    <>
      <PaymentTypeSelect />
      <CurrencyCode
        label={t("companySettings.paymentMethodsSettings.currencyCode")}
        isCurrencyRequired
        validations={[required]}
      />
      {current_user.is_root ? <Status label={t("companySettings.paymentMethodsSettings.status")} /> : null}

      <InternationalPayoutCheckBoxes international />
      <ContactFirstName
        label={t("companySettings.paymentMethodsSettings.contactFirstName")}
        tooltip={<BankRoutingSample />}
        isContactFNRequired
        validations={[required, noWhiteSpaceOnly]}
      />
      <ContactLastName
        label={t("companySettings.paymentMethodsSettings.contactLastName")}
        tooltip={<BankRoutingSample />}
        isContactLastNameRequired
        validations={[required, noWhiteSpaceOnly]}
      />
      <Memo col={"12"} label={t("companySettings.paymentMethodsSettings.memo")} />
      <DebitCreditFields />
    </>
  );
};

export default Sepa;
