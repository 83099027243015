import React, { useState } from "react";
import Panel from "components/common/panel/panel";
import styles from "./dashboard.module.css";
import CardProgramPicker from "components/common/pickers/reduxFormPickers/cardProgramPicker";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import { IUser } from "services/common/user/userTypes";
import { useTypedSelector } from "reducers";
import { CreateNotification, NotificationType } from "services/general/notifications";
import moment from "moment";
import { formattedAmount } from "services/general/helpers";

type CreditSummaryDataType = {
  available?: string;
  credit_limit?: string;
  funding_type?: string;
  last_payment_amount?: string;
  last_payment_date?: string;
  outstanding_amount?: string;
  outstanding_balance?: string;
  pending?: string;
  pending_authorizations?: string;
  remaining_balance?: string;
  unbilled_amount?: string;
  lastUpdatedDate?: string;
  isLoaded?: boolean;
};

const CreditSummary = () => {
  const [creditSummaryData, setCreditSummaryData] = useState<CreditSummaryDataType>();
  const [cardProgramId, setCardProgramId] = useState<number>();
  const currentUser: IUser = useTypedSelector((state) => state.user);
  const currentUserCompany = currentUser?.company;

  /**
   * Sets the last updated date and time according to the requirement https://accrualify.atlassian.net/browse/AC-14365,
   * displaying the local date and time when the API is called or refreshed.
   */
  const calculateLastUpdatedDateTimeOnApiCall = () => {
    let local_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Obtain and format the timezone abbreviation (e.g., IST) for the local timezone.
    let localTimezoneAbbrivation = moment().tz(local_timezone).format("z");
    let companyDateFormat = currentUserCompany.company_date_format;
    let dateFormat = companyDateFormat === "MM/dd/yyyy" ? "MM/DD/YYYY" : "DD/MM/YYYY";
    return moment().format(dateFormat + " HH:mm:ss") + " " + localTimezoneAbbrivation;
  };

  const getCreditSummaryData = async (programId?: number) => {
    try {
      setCardProgramId(programId);
      const result: AxiosResponse<any> = await restApiService.get(`card_programs/${programId}/get_balance`);
      if (result?.data) {
        const lastUpdatedDate = calculateLastUpdatedDateTimeOnApiCall();
        const CreditSummaryData = { lastUpdatedDate, isLoaded: true, ...result?.data };
        setCreditSummaryData(CreditSummaryData);
      }
    } catch (error) {
      CreateNotification(
        "Invalid Program Id",
        "Oops, Something went wrong please contact Support.",
        NotificationType.danger,
      );
    }
  };

  const handleRefreshCreditSummaryData = async () => {
    try {
      if (cardProgramId !== undefined && !Number.isNaN(cardProgramId)) {
        await getCreditSummaryData(cardProgramId);
      } else {
        CreateNotification(
          "Invalid Program Id",
          "Please select a valid option from the Credit Line dropdown.",
          NotificationType.warning,
        );
      }
    } catch (error) {
      CreateNotification("Invalid", "Oops, Something went wrong please contact Support.", NotificationType.danger);
    }
  };

  return (
    <div>
      <Panel
        cardClass={styles.panelWrapper}
        cardBodyClass={styles.panelBottom}
        cardFooterClass={styles.panelFooter}
        header={
          <div className={styles.panelTitle}>
            <i className={`icon ${styles.iconHeading} icon-bank-blue px-mt-3 px-mr-3`}></i>Credit Summary
          </div>
        }
        cardHeaderClass={styles.noPadding}
        footer={
          <>
            {creditSummaryData?.lastUpdatedDate && (
              <Row>
                <Col>
                  <p className={styles.lastUpdatedDate}>Last Updated: {creditSummaryData?.lastUpdatedDate} </p>
                </Col>
              </Row>
            )}
          </>
        }
      >
        <Row className="CreditSummaryPanel">
          <Col sm={3}>
            <p className={`${styles.selectCreditLineLabel}`}>Select Credit Line</p>
          </Col>
          <Col sm={6} className="px-pl-0">
            <CardProgramPicker callBack={getCreditSummaryData} placeholder=" -- Select Card Program --" />
          </Col>
          {creditSummaryData?.isLoaded && (
            <Col sm={3} className={`px-mt-10 ${styles.syncBtn}`}>
              <a onClick={() => handleRefreshCreditSummaryData()}>
                <i className={`icon icon-sync ${styles.syncBtnIcon}`}></i>
              </a>
            </Col>
          )}
        </Row>
        {creditSummaryData?.isLoaded && (
          <Row
            className={`px-mt-25 ${creditSummaryData.outstanding_balance && creditSummaryData.remaining_balance ? "px-mb-15" : "px-mb-40"}`}
          >
            <Col sm={8}>
              <Row>
                <Col>
                  {creditSummaryData.outstanding_balance && (
                    <div className={`${styles.balance} ${styles.displayFlexCenter}`}>
                      {formattedAmount(creditSummaryData.outstanding_balance, currentUserCompany?.currency?.iso_code)}
                    </div>
                  )}
                  <div className={`${styles.displayFlexCenter} ${styles.currentBalance}`}>
                    Current Balance{" "}
                    <i
                      className="icon icon-tooltips"
                      title="The net balance due of any invoices, minus any payments or credits received, as of yesterday. A positive value indicates a balance due on the account, while a negative value indicates overpayment on the account. "
                    ></i>
                  </div>
                </Col>
                <Col>
                  {creditSummaryData.remaining_balance && (
                    <div className={`${styles.balance} ${styles.displayFlexCenter}`}>
                      {formattedAmount(creditSummaryData.remaining_balance, currentUserCompany?.currency?.iso_code)}
                    </div>
                  )}
                  <div className={`${styles.displayFlexCenter} ${styles.remainingBalance}`}>
                    Available Balance{" "}
                    <i
                      className="icon icon-tooltips"
                      title="The amount available to spend (after transactions that are unbilled or pending authorization). If the account is overdrawn, the amount over the account credit limit is shown in red instead. "
                    ></i>
                  </div>
                </Col>
              </Row>
              <Row className="px-mt-25 creditSummary">
                <Col>
                  <ProgressBar
                    now={(Number(creditSummaryData.outstanding_balance) / Number(creditSummaryData.credit_limit)) * 100}
                  />
                </Col>
              </Row>
              <Row className="px-mt-25">
                <Col>
                  <p className={`${styles.selectCreditLineLabel} ${styles.displayFlexCenter}`}>
                    <span className={styles.whiteSpace}>Total Account Credit Limit:</span>
                    {creditSummaryData.credit_limit &&
                      formattedAmount(creditSummaryData.credit_limit, currentUserCompany?.currency?.iso_code)}
                    <i className="icon icon-tooltips" title="The approved credit limit for your account."></i>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col sm={4} className={`${styles.rightCol}`}>
              <p className={`${styles.rightSectionHeader} ${styles.displayFlexCenter}`}>
                Most Recent Payment
                <i className="icon icon-tooltips" title="The amount of the last payment posted to your account. "></i>
              </p>
              <small className={`${styles.rightSectionContent} ${styles.displayFlexCenter}`}>
                <Row>
                  <Col>
                    Posted on {creditSummaryData.last_payment_date && creditSummaryData.last_payment_date} for{" "}
                    {creditSummaryData.last_payment_amount &&
                      formattedAmount(creditSummaryData.last_payment_amount, currentUserCompany?.currency?.iso_code)}
                  </Col>
                </Row>
              </small>
              <p className={` ${styles.rightSectionHeader} ${styles.displayFlexCenter}`}>
                Pending Authorizations{" "}
                <i
                  className="icon icon-tooltips"
                  title="Transactions that are in pending or authorized status (not settled or posted by the merchant). This amount is reflected in the Available Credit Limit. "
                ></i>
              </p>
              <small className={`${styles.rightSectionContent} ${styles.displayFlexCenter}`}>
                {creditSummaryData.pending_authorizations
                  ? formattedAmount(creditSummaryData.pending_authorizations, currentUserCompany?.currency?.iso_code)
                  : " "}
              </small>
              <p className={`${styles.rightSectionHeader} ${styles.displayFlexCenter}`}>
                Transactions Unbilled{" "}
                <i
                  className="icon icon-tooltips"
                  title="Transactions that have posted but have not yet been billed. This amount is reflected in the Available Credit Limit. "
                ></i>
              </p>
              <small className={`${styles.rightSectionContent} ${styles.displayFlexCenter}`}>
                {creditSummaryData.pending_authorizations
                  ? formattedAmount(creditSummaryData.unbilled_amount, currentUserCompany?.currency?.iso_code)
                  : " "}
              </small>
              <p className={` ${styles.rightSectionHeader} ${styles.displayFlexCenter}`}>
                Today’s Transaction Activity{" "}
                <i className="icon icon-tooltips" title="Transactions posted to your account today.  "></i>
              </p>
              <small className={`${styles.rightSectionContent} ${styles.displayFlexCenter}`}>
                {creditSummaryData.pending_authorizations
                  ? formattedAmount(creditSummaryData.last_payment_amount, currentUserCompany?.currency?.iso_code)
                  : " "}
              </small>
            </Col>
          </Row>
        )}
      </Panel>
    </div>
  );
};

export default CreditSummary;
