import React, { Component } from "react";
import RestApi from "../../../providers/restApi";
import { Container, Modal, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import * as actionType from "../../../actions/actionTypes";
import { withTranslation } from "react-i18next";
import Panel from "../../panel/panel";
import ChecklistItem from "./checklistItem";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import styles from "./checklist.module.css";

class ChecklistList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.restApiService = new RestApi();
  }

  componentDidMount() {
    if (
      this.props.onboaringState.listTemplate &&
      this.props.onboaringState.listTemplate.name == this.props.listTemplate.name
    ) {
      //list template is already loaded, use whats in the redux state
      this.parseDependsOn();
    } else {
      this.fetchChecklist();
    }
  }

  async fetchChecklist() {
    await this.props.setListTemplate(this.props.listTemplate);

    this.restApiService
      .get(this.props.listTemplate.url)
      .then((result) => {
        this.parseChecklistFromAPI(result.data);
      })
      .catch((error) => {
        console.error(error);
        CreateNotification("Error", "Unknown error occured. Please contact support", NotificationType.danger);
      });

    // this.parseChecklistFromAPI({
    //   agreed_to_tos: false,
    //   stripe_account_created: false,
    //   plaid_account_verified: false,
    //   connected_accounting_software: false,
    //   settings_defined: false,
    //   funds_added: false
    // })
  }

  //map the results from the api to our redux state
  parseChecklistFromAPI(results) {
    this.props.onboaringState.listTemplate.items.forEach((item) => {
      if (results[item.id]) {
        item.completed = true;
      } else {
        item.completed = false;
      }
    });

    this.parseDependsOn();
  }

  //loop over again and consider any items that have dependsOn flag.
  parseDependsOn() {
    this.props.onboaringState.listTemplate.items.forEach((item) => {
      if (item.dependsOn) {
        if (item.dependsOn == "all") {
          item.disabled = this.dependsOnAllCheck(item.id);
        } else {
          this.props.onboaringState.listTemplate.items.forEach((target) => {
            if (target.id == item.dependsOn) {
              item.disabled = !target.completed;
            }
          });
        }
      } else {
        item.disabled = false;
      }
    });

    this.props.setListTemplateItems(this.props.onboaringState.listTemplate.items);
  }

  dependsOnAllCheck(itemId) {
    let anyNotCompleted = false;
    this.props.onboaringState.listTemplate.items.forEach((item) => {
      if (!item.completed && item.id != itemId) {
        anyNotCompleted = true;
      }
    });
    return anyNotCompleted;
  }

  render() {
    return (
      <Row className="h-100">
        <Col>
          <Panel
            panelContent={{ heading: "Virtual Card Setup Checklist" }}
            cardClasses={"animated fadeIn"}
            cardBodyStyle={{ paddingTop: "0px", paddingLeft: "0px", paddingRight: "0px" }}
          >
            <Row>
              <Col>
                {this.props.onboaringState.listTemplate &&
                  this.props.onboaringState.listTemplate.items.map((item, index) => {
                    return <ChecklistItem key={item.id} item={item} index={index}></ChecklistItem>;
                  })}
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    onboaringState: state.onboarding,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setListTemplate: (payload) => {
      dispatch({ type: actionType.SET_ONBOARDING_LIST_TEMPLATE, payload: payload });
    },
    setListTemplateItems: (payload) => {
      dispatch({ type: actionType.SET_ONBOARDING_LIST_TEMPLATE_ITEMS, payload: payload });
    },
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChecklistList));
