import React from "react";
import styles from "./stepper.module.css";

export type StepType = { value: string; label: string; completed?: boolean; stepCount?: number };

type StepperPropsType = {
  activeStep?: string;
  steps: StepType[]; // Include 'completed' prop in steps
  handleStepChange: (step?: number) => void;
};

const showStepperIcon = (step: StepType, activeStep?: string, isActiveStep?: boolean) => {
  // If it's the active step, show a filled circle icon with styles applied
  // If the step is completed but not the active step, show a tick icon
  // If none of the above conditions are met, return empty class
  return !isActiveStep && step.completed
    ? "icon icon-tick"
    : isActiveStep
      ? `icon icon-filled-circle ${styles.circle}`
      : "";
};

const Stepper = ({ activeStep, steps, handleStepChange }: StepperPropsType) => {
  // Function to check if a step is completed and clickable
  const isStepCompleted = (step: StepType) => {
    // If the step is the final step (ex. Final Review step in vendor form), check if all preceding steps are completed
    if (step.value === "finalReview") {
      let allPrecedingCompleted = true;

      // Iterate through steps 1 to 5 steps
      for (let i = 1; i <= 5; i++) {
        const precedingStep = steps.find((stepObj) => stepObj.stepCount === i);

        // If a preceding step is not found or not completed, set allPrecedingCompleted to false
        if (!precedingStep || !precedingStep.completed) {
          allPrecedingCompleted = false;
          break; // No need to check further if one step is incomplete
        }
      }

      return allPrecedingCompleted;
    } else {
      // For steps other than the final step, simply check if the step itself is completed
      return step.completed;
    }
  };

  return (
    <div className={styles.stepperContainer}>
      {steps.map((step, index) => {
        const isActiveStep = step.value === activeStep;
        const backgroundColor = isActiveStep ? "#1DA1F2" : "#FFFFFF";
        return (
          <div key={index}>
            <div className={styles.stepper}>
              <div className={styles.connector}></div>
              <div className={styles.step}>
                <div
                  key={index}
                  className={`${isActiveStep ? "icon icon-filled-circle" : "icon icon-blank-circle"} ${styles.circle}`}
                  style={{ backgroundColor }}
                >
                  <i className={showStepperIcon(step, activeStep, isActiveStep)}></i>
                </div>
                {isStepCompleted(step) ? (
                  <a className={styles.stepLink} onClick={() => handleStepChange(step.stepCount)}>
                    {step.label}
                  </a>
                ) : (
                  <div className={styles.stepText}>{step.label}</div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
