import React, { Component } from "react";
import { Container } from "react-bootstrap";
import RestApi from "../../../../providers/restApi";
import fraudAuditsHeader from "../../../../components/datagrid/girdHeaders/fraudAuditsHeader";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-theme-balham.css";
import { DateFormatter } from "../../../../services/common/gridService";
import { Row, Col } from "react-bootstrap";
import ToggleFilterButton from "../../../../components/datagrid/buttons/toggleFilterButton";
import JSONModal from "../../../../components/modals/jsonModal";
import NavTabs from "../nav";
import withSelectAll from "../../../../components/common/hoc/withSelectAll";
import SelectAll from "../../../../components/common/dataGrid/selectAll";
import ServerSideDataGrid from "../../../../components/common/dataGrid/serverSideDataGrid/serverSideDataGrid";
import GridFilterDropdown from "../../../../components/datagrid/gridFilterDropdown";
import { connect } from "react-redux";

class FraudAudits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingFilter: true,
      gridApi: null,
      gridColumnApi: null,
    };
    this.restApiService = new RestApi();
  }

  gridReady = (params) => {
    this.setState({ gridApi: params.api, gridColumnApi: params.columnApi });

    // hoc is taking access of gridApi
    this.props.getGridApi(params);

    //tell the grid that we are using a custom data source
    params.api.setServerSideDatasource({ getRows: this.getRows });
  };

  // Everytime the grid needs new rows (such as first load or scrolling) this function will fire
  // We make the API call and then call the success function on the object the grid passed in
  getRows = (params) => {
    //parameters to send to the api
    let getParams = {
      //grid will pass in the first index and last index that needs to be fetched
      items: params.request.endRow - params.request.startRow,
      //default page size is 100 items
      page: 1 + params.request.startRow / 100,
    };

    this.attachFilters(params.request, getParams);

    this.restApiService
      .get("fraud_audits", getParams)
      .then((result) => {
        if (result.data) {
          //we pass in the data we got from the API and the total row count so the grid knows how big to make the scrollbar
          params.success({ rowData: result.data.data, rowCount: result.data.meta.count });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // The API is expecting column names that are different than the ones it sends on fetch
  attachFilters = (request, params) => {
    if (request.filterModel) {
      for (let key in request.filterModel) {
        switch (key) {
          case "action":
            params["action_type"] = request.filterModel[key].filter;
            break;
          case "purchase_order.number":
            params["purchase_order_number"] = request.filterModel[key].filter;
            break;
          case "date":
            DateFormatter(request, params, key, "date_after", "date_before");
            break;
          case "created_at":
            params["created_at"] = request.filterModel[key].dateFrom;
            break;
          default:
            params[key] = request.filterModel[key].filter;
            break;
        }
      }
    }
  };

  cellClicked = (data) => {
    if (data.colDef.field === "result") {
      this.setState({ showJSONModal: true, modalTitle: "Result", modalData: data.data.result });
    } else if (data.colDef.field === "data") {
      this.setState({ showJSONModal: true, modalTitle: "Data", modalData: data.data.data });
    }
  };

  jsonModalClose = () => {
    this.setState({ showJSONModal: false });
  };

  render() {
    return (
      <Container fluid={true}>
        <Row className="m-0">
          <Col md="12" className="mt-4">
            <NavTabs activeTabName={"Fraud Audit"} />
          </Col>
        </Row>

        <Row className="my-2">
          <Col>
            <GridFilterDropdown gridApi={this.state.gridApi} gridColumnApi={this.state.gridColumnApi} />
            <ToggleFilterButton
              classes="float-right"
              clickCallback={() => {
                this.setState({ showingFilter: !this.state.showingFilter }, () => {
                  this.state.gridApi.refreshHeader();
                });
              }}
            />
          </Col>
        </Row>

        <Row className="px-3" style={{ height: "70vh" }}>
          <ServerSideDataGrid
            onSelectionChanged={this.props.onSelectionChanged}
            onCellClicked={this.cellClicked}
            columnDefs={[
              {
                headerName: "",
                field: "select",
                headerComponent: SelectAll,
                headerComponentParams: {
                  selectAll: this.props.selectAll,
                  toggleSelectAll: this.props.toggleSelectAll,
                },
                checkboxSelection: true,
                width: 50,
              },
              ...fraudAuditsHeader(this.props.currentUser),
            ]}
            defaultColDef={{
              floatingFilter: this.state.showingFilter,
            }}
            rowSelection={"multiple"}
            gridReady={this.gridReady}
            pagination
            paginationSize={50}
          />
        </Row>

        <JSONModal
          show={this.state.showJSONModal}
          title={this.state.modalTitle}
          body={this.state.modalData}
          closeCallback={this.jsonModalClose}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.user,
  };
};
FraudAudits = withSelectAll(FraudAudits);
export default connect(mapStateToProps)(FraudAudits);
