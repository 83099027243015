import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import CustomModal from "components/modals/customModal";
import ErrorBoundary from "components/common/errorBoundary/errorBoundary";
import { Field, Form } from "react-final-form";
import { RenderField } from "components/forms/bootstrapFields";
import ContactPicker from "../pickers/reduxFormPickers/contactPicker";
import _ from "lodash";

type EditSubsidiaryLinkPropType = {
  title: string;
  showModal: boolean;
  picker: any;
  closeModal: () => void;
  modelData: any;
  arraName?: string;
  modelName: string;
  pickerId: any;
  updateModelData: (updatedData: any, arraName?: string) => void; // New prop to update modelData
};

type ManagerLinkType = {
  id?: number | string;
  level?: number;
  email?: string;
  external_id?: number | string;
  value?: number;
  _destroy?: number;
  name?: string;
  raw?: {
    email?: string;
    external_id?: number | string;
    id?: number | string;
    name?: string;
  };
};

const EditSubsidiaryLink = ({
  title,
  showModal,
  closeModal,
  modelName,
  picker,
  modelData,
  arraName,
  pickerId,
  updateModelData, // prop to update modelData
}: EditSubsidiaryLinkPropType) => {
  const [modelObj, setModelObj] = useState<any>({});

  useEffect(() => {
    if (modelData) {
      modelData.managers?.map((manager: any) => {
        if (manager._destroy === 1 && modelData.manager_id?.includes(manager.id)) {
          const index = modelData.manager_id?.indexOf(manager.id);
          if (index > -1) {
            modelData.manager_id.splice(index, 1);
          }
        }
      });
    }
    setModelObj(modelData);
  }, [modelData]);

  const fieldName = () => {
    if (pickerId !== "project_id") {
      return pickerId; // Return the field key or the pickerId key
    } else if (pickerId == "project_id") {
      return "field"; // Adjusted to match initialValues structure
    } else {
      return modelData.field; // Adjusted to match initialValues structure
    }
  };

  const handleRemoveSingleManager = (contactId: number, form: any) => {
    const selectedLink = { ...modelObj };
    if (contactId) {
      const deletedManager = selectedLink?.managers.find(({ id }: { id: string | number }) => id === contactId);
      deletedManager._destroy = 1;
      const index = selectedLink.managers.findIndex(({ id }: { id: string | number }) => id === contactId);
      selectedLink.managers[index] = deletedManager;
      selectedLink.manager_id = selectedLink.manager_id.filter((id: number | string) => id !== contactId);
      setModelObj(selectedLink);
      form.change("field", selectedLink);
    }
  };

  const selectManagers = (contacts: ManagerLinkType[], form: any) => {
    if (Array.isArray(contacts) && contacts.length > 0) {
      const selectedLink = { ...modelObj };
      const existingManagers = selectedLink.managers
        ?.filter(({ id, _destroy }: { id: string | number; _destroy: number }) => id && _destroy !== 1)
        ?.map(({ id }: { id: string | number }) => id);
      const newManagers = contacts
        ?.filter((contact) => contact.id && !existingManagers?.includes(contact.id))
        ?.map((contact) => ({ id: contact.id, name: contact.name }));

      selectedLink.managers = [...(selectedLink.managers || []), ...newManagers];
      const existingManagerIds = selectedLink.manager_id?.filter((id: number | string) => id !== undefined) || [];
      const newManagerIds = newManagers
        ?.map((manager) => manager.id)
        ?.filter((id) => id !== undefined && !existingManagers?.includes(id));

      selectedLink.manager_id = [...existingManagerIds, ...newManagerIds];
      form.change("field", selectedLink);
      setModelObj(selectedLink);
    }
  };
  const getValidator = (value: any) => {
    return value ? (value: any) => (value ? undefined : "This field is required") : () => undefined;
  };

  const Error = ({ name }: { name: string }) => (
    <Field name={name} subscription={{ error: true }}>
      {({ meta: { error } }) => (error ? <span>{error}</span> : null)}
    </Field>
  );

  return (
    <CustomModal
      size="lg"
      header={`Edit ${title}`}
      body={
        <>
          <ErrorBoundary>
            <Row>
              <Col>
                <label>{modelName}</label>
              </Col>
              <Col>
                <label>Manager</label>
              </Col>
              <Col>
                <label>Level</label>
              </Col>
            </Row>
            <Form
              onSubmit={(data) => {
                updateModelData(data, arraName);
                closeModal();
              }}
              initialValues={modelObj}
              render={({ handleSubmit, form, values }) => (
                <>
                  {!_.isEmpty(modelObj) && (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Field
                            name={fieldName()}
                            component={picker}
                            parentObjData={modelObj}
                            metadataConfiguration={{ field_id: modelObj.metadata_field_type }}
                            modelData={modelObj}
                            appendOnParent={true}
                            validate={getValidator(values)}
                            /**
                             * The `obj` parameter is a callback object, which is dynamic and can vary based on the picker we are using.
                             * It is typed as `any` because the type of the callback object returned by the picker is not known at compile time.
                             * This allows us to handle different types of callback objects without having to explicitly define the type for each picker.
                             *
                             * @param {any} obj - The callback object returned by the picker.
                             */
                            callBack={(obj: any) => {
                              if (obj) {
                                let field = { id: obj.id || obj.value, name: obj.name || obj.label };
                                obj[pickerId] = obj.id || obj.value; // monitor this change
                                if (modelObj.linkable_type === "Project") {
                                  modelObj.project_id = field.id;
                                  modelObj.label = field.name;
                                  setModelObj({ ...modelObj, field });
                                }
                                if (modelObj.linkable_type === "MetadataField") {
                                  setModelObj((prevModelObj: any) => ({
                                    ...prevModelObj,
                                    label: field.name,
                                    metadata_field_id: field.name,
                                    metadata_field_Linkable_id: obj.metadata_field_Linkable_id,
                                    linkable_id: obj.metadata_field_Linkable_id,
                                    field,
                                  }));
                                }
                                form.change("field", field);
                              }
                            }}
                            menuPosition="fixed"
                          />
                          <Error name="linkable" />
                        </Col>
                        <Col>
                          <Field
                            name="manager_id"
                            component={ContactPicker}
                            contactType={"USERS"}
                            isMulti={true}
                            menuPosition="fixed"
                            needSelectedManagers={true}
                            callBackObj={(contacts: ManagerLinkType[]) => selectManagers(contacts, form)}
                            callBack={(contactId: number) => handleRemoveSingleManager(contactId, form)}
                          />
                        </Col>
                        <Col>
                          <Field
                            name="level"
                            component="input"
                            type="number"
                            onChange={(e: any) => {
                              const value = e.target.value;
                              if (!isNaN(parseInt(value))) {
                                setModelObj({ ...modelObj, level: parseInt(value) });
                              }
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Button type="submit" variant="primary" className="mr-2 px-mb-10 pullRight">
                            <i
                              className="btn-icon icon-add"
                              style={{ backgroundSize: "12px", backgroundPosition: "left" }}
                            />{" "}
                            Add To Grid
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  )}
                </>
              )}
            />
          </ErrorBoundary>
        </>
      }
      show={showModal}
      onHide={closeModal}
    />
  );
};

export default EditSubsidiaryLink;
