import { AxiosResponse } from "axios";
import { restApiService } from "providers/restApi";
import React, { useCallback, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";

type CardProgramPickerPropsType = {
  filterByComdataWeb?: boolean;
  filterByActive?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  callBack?: (selected?: number) => void;
};

type CardProgramOptionsType = {
  add_funds_vendor_id?: string | number;
  card_type?: string;
  default?: string;
  external_id?: string;
  funding_type?: string;
  id?: number;
  label?: string;
  name?: string;
  provider_name?: string;
  status?: string;
  value?: number;
};
/**
 * @deprecated The component should not be used
 * if you want to use this component please use from
 * if you want to use in admin portal component /admin/picker/reduxFormPicker/x.tsx if not available then create it
 * if you want to use  in vendor portal component/vendor/picker/reduxFormPicker/x.tsx if not available then create it
 */
const CardProgramPicker = ({
  filterByComdataWeb,
  filterByActive,
  required,
  disabled,
  placeholder,
  callBack,
}: CardProgramPickerPropsType) => {
  const [cardProgram, setCardProgram] = useState<CardProgramOptionsType[]>([]);
  const getCardProgram = useCallback(async () => {
    try {
      const params = {
        by_active: filterByActive || undefined,
        by_comdata_web: filterByComdataWeb || undefined,
      };

      const result: AxiosResponse<CardProgramOptionsType[]> = await restApiService.get(
        "card_programs",
        params,
        null,
        true,
        null,
        true,
      );
      if (result?.data) {
        const modifiedOptions = result.data.map((option: CardProgramOptionsType) => ({
          value: option.id,
          label: `${option.external_id ? option.external_id + " - " + option.name : option.name}`,
          ...option,
        }));
        setCardProgram(modifiedOptions);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    getCardProgram();
  }, []);

  const handleOnChange = (selected: SingleValue<void | CardProgramOptionsType>) => {
    if (callBack && selected) {
      callBack(selected?.id);
    }
  };

  return (
    <Select
      placeholder={placeholder}
      onChange={(selected) => handleOnChange(selected)}
      options={cardProgram}
      isClearable
      classNamePrefix="select"
    />
  );
};
export default CardProgramPicker;
