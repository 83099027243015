import React from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

export type emailLogPropsType = {
  logs: any;
};

const EmailLog = ({ logs }: emailLogPropsType) => {
  return (
    <div>
      {logs.map((log: any, i: number) => (
        <Row key={i} className={"border-bottom  py-2"}>
          <Col xs={3}>
            <h6 className={log.type === "error" ? "color-red" : undefined}>{log.type}</h6>
            <h6 className={log.type === "error" ? "color-red" : undefined}>{moment(log.created_at).format("L")}</h6>
            <h6 className={log.type === "error" ? "color-red" : undefined}>{moment(log.created_at).format("LTS")}</h6>
          </Col>
          <Col xs={9}>
            <h6 className={log.type === "error" ? "color-red" : undefined}>
              <strong>File Name :</strong> {log.filename}
            </h6>
            <p className={log.type === "error" ? "color-red" : undefined}>{log.message}</p>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default EmailLog;
