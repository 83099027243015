import { RenderSelect } from "components/forms/bootstrapFields";
import React from "react";
import { Col } from "react-bootstrap";
import { Field } from "redux-form";

const MessageOnCheck = () => {
  return (
    <Col lg="6">
      <Field
        component={RenderSelect}
        name="form.provider_settings.message_on_cheque"
        label="Show Message on the Cheque"
        id="cheque_message"
        options={[
          { value: null, label: "Select" },
          { value: "invoice_description", label: "Invoice Description" },
          { value: "invoice_number", label: "Invoice Number" },
          { value: "payment_reference_number", label: "Payment Reference Number" },
          { value: "payment_memo", label: "Payment Memo" },
        ]}
      />
    </Col>
  );
};

export default MessageOnCheck;
