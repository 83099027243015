import React, { ReactNode, useEffect, useMemo, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { change, Field, getFormValues, WrappedFieldProps } from "redux-form";
import Select from "react-select";
import { onBlurSingle } from "services/general/helpers";
import _ from "lodash";
import { required } from "services/validations/reduxFormValidation";
import { useTypedSelector } from "reducers";
import { useDispatch } from "react-redux";
import useShowSelectMenu from "components/admin/hooks/useShowSelectMenu";
import PickerErrorBlock from "../pickerErrorBlock";

type SelectItemPickerPropsType = {
  customField?: any;
  pickerKey?: string | number;
  formFieldName: string;
  formName: string;
  modelData: { [key: string]: any } | null;
  modelDataFieldName: string;
  absPath?: string; // absolute path where we want to update data with respect to  form
  parentDivId?: string;
  parentDivVariance?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  menuAutoFixed?: boolean;
};

type CustomItemPickerPropsType = {
  placeholder?: ReactNode;
  disabled?: boolean;
  input?: any;
  options?: unknown;
  required?: boolean;
  instanceId?: string;
  parentDivId?: string;
  parentDivVariance?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  menuAutoFixed?: boolean;
} & WrappedFieldProps;

const CustomItemPicker = ({
  options,
  input,
  placeholder,
  disabled,
  required,
  instanceId,
  menuAutoFixed,
  parentDivId,
  parentDivVariance,
  meta: { touched, error },
}: CustomItemPickerPropsType) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useShowSelectMenu({
    setIsMenuOpen,
    selectRef,
    parentDivId,
    variance: parentDivVariance,
  });
  const selectOption = (input: any, options: any) => {
    const obj = _.find(options, (option) => option?.value === input?.value);
    return obj || {}; // Return an empty object if the input is not found
  };

  const initialInputValue = input.value ? selectOption(input, options) : null;

  const onChangeOption = (input: any, value: any) => {
    if (!value) {
      input.onChange("");
      return;
    }
    input.onChange(value.value);
  };

  return (
    <Form.Group ref={selectRef}>
      <Select
        {...input}
        {...(menuAutoFixed ? { menuPlacement: "auto", menuPosition: "fixed" } : {})}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        required={required}
        value={initialInputValue} // Use initialInputValue as the default value
        onChange={(value) => onChangeOption(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={options}
        isClearable={!required}
        classNamePrefix="select"
        isDisabled={disabled}
        instanceId={instanceId}
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

const CustomItemPickerObject = ({
  options,
  input,
  placeholder,
  disabled,
  required,
  instanceId,
  menuAutoFixed,
  parentDivId,
  parentDivVariance,
  meta: { error, touched },
}: CustomItemPickerPropsType) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useShowSelectMenu({
    setIsMenuOpen,
    selectRef,
    parentDivId,
    variance: parentDivVariance,
  });

  const selectOption = (input: any, options: any) => {
    const obj = _.find(options, (option) => {
      if (input.value.external_id) return option.value === input.value.external_id;
      if (input.value.name) return option.value === input.value.name;
    });
    return obj || {}; // Return an empty object if the input is not found
  };

  const initialInputValue = input.value ? selectOption(input, options) : null;

  const onChangeOption = (input: any, value: any) => {
    if (!value) {
      input.onChange("");
      return;
    }
    const val = _.cloneDeep(value); // making sure not removing value and label from option
    delete val.value;
    delete val.label;
    input.onChange(val);
  };

  return (
    <Form.Group ref={selectRef}>
      <Select
        {...input}
        {...(menuAutoFixed ? { menuPlacement: "auto", menuPosition: "fixed" } : {})}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        required={required}
        value={initialInputValue} // Use initialInputValue as the default value
        onChange={(value) => onChangeOption(input, value)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={options}
        classNamePrefix="select"
        isDisabled={disabled}
        isClearable={!required}
        instanceId={instanceId}
      />
      {error && touched && <PickerErrorBlock error={error} />}
    </Form.Group>
  );
};

const SelectItemPicker = ({
  customField,
  pickerKey,
  formFieldName,
  formName,
  modelData,
  modelDataFieldName,
  absPath,
  menuAutoFixed,
  parentDivId,
  parentDivVariance,
}: SelectItemPickerPropsType) => {
  const [customFieldItems, setCustomFieldItems] = useState([]);
  const formData: { [key: string]: any } | null = useTypedSelector((state) => getFormValues(formName)(state));
  const dispatch = useDispatch();
  const getValueFromForm = (fieldName: string): { [key: string]: any } | null => {
    return _.get(formData, fieldName);
  };

  const customFieldValue = getValueFromForm(`${formFieldName}.${customField.name}`);

  //sample data to create mapping with custom field
  //{"custom_fields": [
  // {"name": "cost_type", "type": "select",
  // "items": [{"name": "B", "status": "ACTIVE","mapping": {"name": "account_id", "items": [6428]}}, {"name": "E", "status": "ACTIVE","mapping": {"name": "account_id", "items": [6427, 6428]}}]
  //"label": "Cost Type", "status": "ACTIVE", "array_type": "object"}
  // ]}
  const mappingFilter = (items: { [key: string]: any }[]) => {
    // we don't need logic for searchtext as it will be handled by react select just want to handle mapping
    // this which we need in angular
    const obj: { [key: string]: any } = { is_mapped_values: [] };

    //it will check mapping object exists on the record or not
    obj.is_mapping_exists = function (item: any) {
      return item && _.isPlainObject(item?.mapping) && !item.mapping.filter_to;
    };

    //it will check the mapping value with the selected fields
    obj.check_mapping_value = function (item: any) {
      return (
        modelData &&
        _.isPlainObject(modelData) &&
        modelData[item.mapping.name] &&
        item.mapping.items.indexOf(modelData[item.mapping.name]) !== -1
      );
    };

    _.isArray(items) &&
      items.forEach((item: any) => {
        if (obj.is_mapping_exists(item) && obj.check_mapping_value(item)) {
          obj.is_mapped_values.push(item);
        }
      });

    const filtered = obj.is_mapped_values.length > 0 ? obj.is_mapped_values : items;
    return filtered;
  };

  const validFilterModel = (fieldName: string) => {
    return ["tax_id", "location_id", "department_id", "business_unit_id"].includes(fieldName);
  };

  // this function is call by database
  const filterData = (selected: any, fieldName: string) => {
    if (
      selected &&
      _.isPlainObject(selected.mapping) &&
      selected.mapping.filter_to &&
      _.isArray(selected.mapping.items)
    ) {
      if (validFilterModel(selected.mapping.filter_to)) {
        dispatch(
          change(
            formName,
            `${modelDataFieldName ? modelDataFieldName + ".filter_data_to" : "filter_data_to"}`,
            selected.mapping.filter_to,
          ),
        );
        dispatch(
          change(
            formName,
            `${modelDataFieldName ? modelDataFieldName + ".filter_data_field_name" : "filter_data_field_name"}`,
            fieldName,
          ),
        );
      }
    }
  };

  useEffect(() => {
    const items = customField?.items?.map((option: any) => ({
      label: option.name,
      value: option.external_id ? option.external_id : customField.type === "select" ? option.name : null,
      ...option,
    }));
    setCustomFieldItems(items);
  }, []);

  const getArrayTypeNotObjectOptions = (): { label: string; value: string }[] => {
    const options: { label: string; value: string }[] = [];
    if (!customField.required) {
      options.push({ label: "-- Select --", value: "" });
    }
    _.isArray(customField.items) &&
      customField.items.forEach((item: string) => {
        options.push({
          label: item,
          value: item,
        });
      });
    return options;
  };

  return (
    <>
      {customField.array_type !== "object" && (
        <>
          <Field
            key="customField_array_type_non_object"
            instanceId="customField_array_type_non_object"
            name={`${formFieldName}.${customField.name}.value`}
            component={CustomItemPicker}
            modelName={customField.items_picker}
            options={getArrayTypeNotObjectOptions()}
            required={customField?.required}
            validate={customField?.required ? [required] : []}
            parentDivId={parentDivId}
            parentDivVariance={parentDivVariance}
            menuAutoFixed={menuAutoFixed}
          />
        </>
      )}

      {customField.array_type === "object" && (
        <Field
          key="customField_array_type_object"
          instanceId="customField_array_type_object"
          name={`${formFieldName}.${customField.name}.value`}
          component={CustomItemPickerObject}
          modelName={customField.items_picker}
          onChange={(item: any) => filterData(item, customField.name)} // previously this function is getting called from data base
          options={mappingFilter(customFieldItems)}
          required={customField?.required}
          validate={customField?.required ? [required] : []}
          parentDivId={parentDivId}
          parentDivVariance={parentDivVariance}
          menuAutoFixed={menuAutoFixed}
        />
      )}
    </>
  );
};

export default SelectItemPicker;
