import React from "react";
import { ColDef } from "ag-grid-community";
import { sortBy } from "lodash";
import { FieldFilterMapType } from "services/common/types/grid.type";
import { setAllowedFilters } from "services/common/gridService";
import { translate } from "services/general/translation";
import { formattedAmount } from "services/general/helpers";
import { companyDateFormat } from "services/general/dateSvc";
import { IUser } from "services/common/user/userTypes";

export const FIELD_NAME_MAP: FieldFilterMapType = {};

export const getCardPaymentActivitiesHeaders = ({
  currentUser,
  setShowPaymentID,
}: {
  currentUser?: IUser;
  setShowPaymentID: (arg: number) => void;
}): { columnDefs: ColDef[]; defaultOrder: string[] } => {
  let columnDefs = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      hide: true,
      cellRenderer: (params: any) => {
        return <span>{params.data?.id}</span>;
      },
      headerValueGetter: function () {
        return translate("ID");
      },
      minWidth: 100,
    },
    {
      field: "reference_number",
      headerName: "Reference ID",
      sortable: false,
      headerValueGetter: function () {
        return translate("reference_id");
      },
      cellRenderer: (params: any) => {
        return (
          <a onClick={() => setShowPaymentID(params.data?.id)} className="text-info text-decoration-none">
            {params.value ?? params.data?.payment_number}
          </a>
        );
      },
      minWidth: 100,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      sortable: true,
      headerValueGetter: function () {
        return translate("payment_date");
      },
      cellRenderer: (params: any) => {
        return currentUser ? companyDateFormat(params.value, currentUser) : null;
      },
      minWidth: 100,
    },
    {
      field: "comdata_account_code",
      headerName: "Account Code",
      sortable: false,
      headerValueGetter: function () {
        return translate("account_code");
      },
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      headerValueGetter: function () {
        return translate("status");
      },
      minWidth: 100,
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      sortable: false,
      headerValueGetter: function () {
        return translate("payment_method_name");
      },
      cellRenderer: (params: any) => {
        const payment_method_from = params.data?.payment_method_from;
        return `${payment_method_from?.bank_name ?? ""} - ${payment_method_from?.account_name ?? ""}`;
      },
      minWidth: 100,
    },
    {
      field: "amount",
      headerName: "Payment Amount",
      sortable: false,
      headerValueGetter: function () {
        return translate("payment_amount");
      },
      cellRenderer: (param: any) => {
        const amount = param.value;
        const currencyCode = "USD";
        return amount && currencyCode ? (formattedAmount(`${amount}`, currencyCode, 2, true) ?? "") : "";
      },
      minWidth: 100,
    },
  ];
  setAllowedFilters(columnDefs, FIELD_NAME_MAP);
  const defaultOrder = columnDefs.map((col: any) => col.field);
  return { columnDefs: sortBy(columnDefs, (col) => col.headerValueGetter()), defaultOrder };
};
