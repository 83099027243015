import React from "react";
import DropdownFilter from "../reportFilters/dropdown";

const CardListing = ({}) => {
  const card_type_options = [
    { value: "", label: "ALL" },
    { value: "PHYSICAL", label: "PHYSICAL" },
    { value: "VIRTUAL", label: "VIRTUAL" },
  ];
  return (
    <div>
      <DropdownFilter name={"Card Type"} code={"card_type"} options={card_type_options} />
    </div>
  );
};

export default CardListing;
