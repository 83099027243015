import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { FedWireUsd } from "./fedWireCurrencyFields";
import { fedWireValidation } from "../validations/fedWirePaymentValidations";

class FedWireForm extends Component<any> {
  render() {
    return (
      <Row>
        <Col>
          {this.getCurrencyForm(this.props.currency_code ? this.props.currency_code : this.props.defaultCurrency)}
        </Col>
      </Row>
    );
  }

  getCurrencyForm(currencyCode: any) {
    switch (currencyCode) {
      case "USD":
        return <FedWireUsd {...this.props} fillteredPayment={this.filterFieldFedWire(currencyCode)} />;
      default:
        return null;
    }
  }

  filterFieldFedWire(currenyCode: string) {
    return fedWireValidation.filter(function (item) {
      return item.currency === currenyCode;
    });
  }
}

export default withTranslation()(FedWireForm);
