import _ from "lodash";
import React from "react";
import { Badge } from "react-bootstrap";

type tagPropType = {
  tags?: Array<string>;
  status?: string;
  removeTag?: (tag: string) => void;
};

const Tag = ({ tags = [], status, removeTag }: tagPropType) => {
  return (
    <div>
      {_.isArray(tags) &&
        tags.map((tag: string, i: number) => (
          <Badge key={i} className={status ? "processedTag" : "tag"}>
            {tag}
            {removeTag && (
              <button onClick={() => removeTag(tag)} className={"bg-transparent border-0"}>
                x
              </button>
            )}
          </Badge>
        ))}
    </div>
  );
};

export default Tag;
