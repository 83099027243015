import i18next from "i18next";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { RenderField } from "../../../forms/bootstrapFields";
import { length, number } from "../../../../services/validations/reduxFormValidation";
import classes from "../companyPaymentMethod.module.css";

const length20 = length(20);

const validateStandardEntryClassCode = (value) => {
  if (!value) {
    return undefined;
  } else if (_.isString(value) && /^[A-Za-z0-9]{3}$/.test(value)) {
    return undefined;
  } else {
    return i18next.t("companySettings.paymentMethodsSettings.matchPattern") + " /^[A-Za-z0-9]{3}$/";
  }
};

const validateOriginatingDfi = (value) => {
  if (!value) {
    return undefined;
  } else if (_.isString(value) && /^\d{8}$/.test(value)) {
    return undefined;
  } else {
    return i18next.t("companySettings.paymentMethodsSettings.matchPattern") + " /^d{8}$/";
  }
};

const validateTranactionCode = (value) => {
  if (!value) {
    return undefined;
  } else if (_.isString(value) && /^\d{2}$/.test(value)) {
    return undefined;
  } else {
    return i18next.t("companySettings.paymentMethodsSettings.matchPattern") + " /^d{2}$/";
  }
};

const NachaPayments = () => {
  const { t } = useTranslation();
  return (
    <Col md="12">
      <h3>{t("companySettings.paymentMethodsSettings.nachaPayments")}</h3>
      <Row>
        <Col md="6">
          <Field
            id="form.immediate_destination"
            name="form.immediate_destination"
            component={RenderField}
            type="text"
            label={t("companySettings.paymentMethodsSettings.immediateDestination")}
          />

          <Field
            id="form.immediate_origin"
            name="form.immediate_origin"
            component={RenderField}
            type="text"
            label={t("companySettings.paymentMethodsSettings.immediateOrigin")}
          />

          <Field
            id="form.standard_entry_class_code"
            name="form.standard_entry_class_code"
            component={RenderField}
            type="text"
            label={t("companySettings.paymentMethodsSettings.standardEntryClassCode")}
            validate={[validateStandardEntryClassCode]}
          />

          <Field
            id="company_default.transaction_code"
            name="company_default.transaction_code"
            component={RenderField}
            type="text"
            label={t("companySettings.paymentMethodsSettings.transactionCode")}
            validate={[validateTranactionCode]}
          />
          <Field
            id="form.company_discretionary_data"
            name="form.company_discretionary_data"
            component={RenderField}
            type="text"
            className={classes.alignInputRight}
            validate={[number, length20]}
            label={t("companySettings.paymentMethodsSettings.companyDiscretionaryData")}
          />
        </Col>
        <Col md="6">
          <Field
            id="form.immediate_destination_name"
            name="form.immediate_destination_name"
            component={RenderField}
            type="text"
            label={t("companySettings.paymentMethodsSettings.immediateDestinationName")}
          />

          <Field
            id="form.immediate_origin_name"
            name="form.immediate_origin_name"
            component={RenderField}
            type="text"
            label={t("companySettings.paymentMethodsSettings.immediateOriginName")}
          />

          <Field
            id="form.originating_dfi_identification"
            name="form.originating_dfi_identification"
            component={RenderField}
            type="text"
            label={t("companySettings.paymentMethodsSettings.originatingDfiIdentification")}
            validate={[validateOriginatingDfi]}
          />

          <Field
            id="form.nacha_company_name"
            name="form.nacha_company_name"
            component={RenderField}
            type="text"
            label={t("companySettings.paymentMethodsSettings.nachaCompanyName")}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default NachaPayments;
