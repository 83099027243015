import React, { JSXElementConstructor, ReactElement, ReactNode } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";
import { OverlayTriggerType } from "react-bootstrap/esm/OverlayTrigger";

type PopoverRenderPropsType = {
  id: string;
  popoverTitle?: string;
  popoverContent: ReactNode;
  trigger?: OverlayTriggerType;
  rootClose?: boolean;
  placement?: Placement;
  children: ReactNode;
  popoverClass?: string;
  popoverHeaderClass?: string;
};

const PopoverRender = ({
  id,
  popoverTitle,
  popoverContent,
  placement,
  trigger,
  popoverClass,
  popoverHeaderClass,
  children,
}: PopoverRenderPropsType) => {
  return (
    <>
      <OverlayTrigger
        trigger={trigger ? trigger : ["hover", "focus"]}
        placement={placement ? placement : "auto"}
        overlay={
          <Popover id="popover-contained" className={popoverClass}>
            <Popover.Title className={popoverHeaderClass}>{popoverTitle}</Popover.Title>
            <Popover.Content>{popoverContent}</Popover.Content>
          </Popover>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <span {...triggerHandler}>
            <span ref={ref}>{children}</span>
          </span>
        )}
      </OverlayTrigger>
    </>
  );
};

export default PopoverRender;
