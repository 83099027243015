import React from "react";
import { Table } from "react-bootstrap";
import style from "./table.module.css";

type DataTablePropType = React.ComponentProps<typeof Table> & {
  bordered?: boolean;
  striped?: boolean;
  hover?: boolean;
};

export const DataTable = (
  { bordered, hover, striped, className, ...rest }: DataTablePropType = { bordered: true, hover: true, striped: true },
) => {
  return (
    <Table
      {...rest}
      bordered={bordered}
      striped={striped}
      hover={hover}
      responsive
      className={" text-left " + style.dataTable + " " + className}
    />
  );
};
