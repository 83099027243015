import { MetadataConfiguration } from "pages/admin/administration/metadata/metadataConfiguration";
import React, { useCallback } from "react";
// import {Col, Container, Row} from "react-bootstrap";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
// import {useHistory} from 'react-router-dom';
// import {useTypedSelector} from "reducers";
import { RenderField, RenderSelect } from "components/forms/bootstrapFields";
import RestApi from "providers/restApi";
import { CreateNotification, NotificationType } from "services/general/notifications";

// import Panel from "components/common/panel/panel";
// import FileUploader from "components/common/fileUploader/fileUploader";
// import TabNavigation from "components/navigation/tabNavigation";
// import NavTabs from 'pages/admin/administration/nav';
// import {CreateNotification, NotificationType} from "services/general/notifications";

import TooltipRender from "components/toolTip/tooltipRender";
import { noWhiteSpaceAllowed, required } from "services/validations/reduxFormValidation";
import styles from "./metadataConfigurationUpsert.module.css";
// import { RenderSelect } from "components/forms/bootstrapFields";
import style from "./metadataConfigurationUpsert.module.css";

const restApi = new RestApi();

export const MetadataConfigurationTypes = [
  { value: "AccountEntry", label: "Account Entry (expense line)" },
  { value: "AccountLink", label: "Account Link (PO expense line)" },
  { value: "CreditMemoExpenses", label: "Credit Memo Expense line" },
  { value: "CreditMemo", label: "Credit Memo" },
  { value: "CreditMemoItem", label: "Credit Memo Item" },
  { value: "Invoice", label: "Invoice" },
  { value: "InvoiceItem", label: "Invoice Item" },
  { value: "ExpenseReport", label: "Expense Report" },
  { value: "ExpenseItem", label: "Expense Item" },
  { value: "Payment", label: "Payment" },
  { value: "Purchase", label: "Purchase" },
  { value: "PurchaseOrder", label: "Purchase Order" },
  { value: "PoItem", label: "Purchase Order Item" },
  { value: "Vendor", label: "Vendor" },
  { value: "VirtualCard", label: "Virtual Card" },
  { value: "PurchaseExpenses", label: "Purchase Expense line" },
];

const inputTypeOptions = [
  { value: "select", label: "Dropdown Select" },
  // { value: "multi-select", label: "Multi Dropdown Select" },
  { value: "text", label: "Free Text" },
  { value: "date", label: "Date" },
  { value: "number", label: "Number" },
];

interface MetadataConfigurationEditProps {
  metadataConfiguration: MetadataConfiguration;
  refreshCallback: any;
  editing: boolean;
  hideLabels?: boolean;
}

type Props = MetadataConfigurationEditProps & InjectedFormProps<{}, MetadataConfigurationEditProps>;

const MetadataConfigurationUpsert: React.FC<Props> = ({
  metadataConfiguration,
  refreshCallback,
  handleSubmit,
  editing,
  hideLabels,
}) => {
  // const dispatch = useDispatch();

  const onSubmit = async (formValues: any) => {
    //dispatch(setMetadataConfigurationState(formValues));

    if (editing) {
      await restApi
        .patch("metadata_configurations/" + formValues.id, null, { metadata_configuration: formValues })
        .then((result) => {
          CreateNotification("Success", "Metadata Configuration edited succesfully", NotificationType.success);
        })
        .catch((error) => {
          console.error(error);
          CreateNotification("Error", "Error updating metadata: " + error, NotificationType.danger);
          return null;
        });
    } else {
      await restApi
        .post("metadata_configurations", null, { metadata_configuration: formValues })
        .then((result) => {
          CreateNotification("Success", "Metadata Configuration created succesfully", NotificationType.success);
        })
        .catch((error) => {
          console.error(error);
          CreateNotification("Error", "Error creating metadata: " + error, NotificationType.danger);
          return null;
        });
    }

    if (!editing) {
      refreshCallback();
    }
  };

  const deleteConfiguration = useCallback(async () => {
    const result = await restApi
      .delete("metadata_configurations/" + metadataConfiguration.id)
      .then((result) => {
        CreateNotification("Success", "Metadata Configuration deleted succesfully", NotificationType.success);
      })
      .catch((error) => {
        console.error(error);
        CreateNotification("Error", "Error deleting metadata: " + error, NotificationType.danger);
        return null;
      });

    refreshCallback();
  }, []);

  const getLabel = (label: string) => {
    if (hideLabels) {
      return null;
    }
    return label;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="d-flex mb-2">
        <Field
          name="name"
          placeholder="Visible Name"
          component={RenderField}
          label={getLabel("Name")}
          type="text"
          required={true}
          validate={[required]}
          className={`${styles.fieldRow} mr-2 mb-0`}
        />
        {/* <Field name="object_type" component={RenderSelect} label={getLabel("Object Type")} type="text" required={true} options={objectTypeOptions} className={`${styles.fieldRow} mr-2 mb-0`} /> */}

        <Field
          name="field_id"
          placeholder="Internal Id"
          tooltip={
            <TooltipRender title={"This will be the id that matches to metadata fields"}>
              <i className="icon-sm icon-questionmark float-right"></i>
            </TooltipRender>
          }
          component={RenderField}
          label={getLabel("Field Id")}
          type="text"
          disabled={editing}
          required={true}
          validate={[required, noWhiteSpaceAllowed]}
          className={`${styles.fieldRow} mr-2 mb-0`}
        />

        <Field
          name="input_type"
          component={RenderSelect}
          label={getLabel("Input Type")}
          type="text"
          options={inputTypeOptions}
          disabled={editing}
          required={true}
          validate={[required]}
          className={`${styles.fieldRow} mr-2 mb-0`}
        />
        {/* <Field name="required" component={RenderSelect} label={getLabel("Required")} type="text" options={[{value: true, label: "Yes"},{ value: false, label: "No"}]} required={true} className={`${styles.fieldRow} mr-2 mb-0`} /> */}
        <div className={style.marginTop28}>
          <button type="submit" className="btn btn-primary">
            {!editing ? "Add" : "Save"}
          </button>
          {editing && (
            <button type="button" className="btn btn-default ml-1" onClick={deleteConfiguration}>
              Delete
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default reduxForm<{}, MetadataConfigurationEditProps>({
  form: "metadataConfigurationForm",
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
  destroyOnUnmount: true,
})(MetadataConfigurationUpsert);
