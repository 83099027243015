const currency_codes = [
  { value: "USD", label: "USD", name: "US Dollar", symbol: "$" },
  { value: "CAD", label: "CAD", name: "Canadian Dollar", symbol: "CA$" },
  { value: "EUR", label: "EUR", name: "Euro", symbol: "€" },
  { value: "AED", label: "AED", name: "United Arab Emirates Dirham", symbol: "د.إ." },
  { value: "ARS", label: "ARS", name: "Argentine Peso", symbol: "AR$" },
  { value: "AUD", label: "AUD", name: "Australian Dollar", symbol: "AU$" },
  { value: "BDT", label: "BDT", name: "Bangladeshi Taka", symbol: "৳" },
  { value: "BRL", label: "BRL", name: "Brazilian Real", symbol: "R$" },
  { value: "CDF", label: "CDF", name: "Congolese Franc", symbol: "CDF" },
  { value: "CNY", label: "CNY", name: "Chinese Yuan", symbol: "CN¥" },
  { value: "COP", label: "COP", name: "Colombian Peso", symbol: "CO$" },
  { value: "CZK", label: "CZK", name: "Czech Republic Koruna", symbol: "Kč" },
  { value: "DZD", label: "DZD", name: "Algerian Dinar", symbol: "د.ج." },
  { value: "EGP", label: "EGP", name: "Egyptian Pound", symbol: "ج.م." },
  { value: "GBP", label: "GBP", name: "British Pound Sterling", symbol: "£" },
  { value: "HKD", label: "HKD", name: "Hong Kong Dollar", symbol: "HK$" },
  { value: "IDR", label: "IDR", name: "Indonesian Rupiah", symbol: "Rp" },
  { value: "ILS", label: "ILS", name: "Israeli New Sheqel", symbol: "₪" },
  { value: "INR", label: "INR", name: "Indian Rupee", symbol: "₹" },
  { value: "IQD", label: "IQD", name: "Iraqi Dinar", symbol: "د.ع.‏" },
  { value: "IRR", label: "IRR", name: "Iranian Rial", symbol: "﷼" },
  { value: "JPY", label: "JPY", name: "Japanese Yen", symbol: "¥" },
  { value: "KRW", label: "KRW", name: "South Korean Won", symbol: "₩" },
  { value: "KWD", label: "KWD", name: "Kuwaiti Dinar", symbol: "د.ك." },
  { value: "LKR", label: "LKR", name: "Sri Lankan Rupee", symbol: "SLRs" },
  { value: "MXN", label: "MXN", name: "Mexican Peso", symbol: "MX$" },
  { value: "NZD", label: "NZD", name: "New Zealand Dollar", symbol: "NZ$" },
  { value: "RUB", label: "RUB", name: "Russian Ruble", symbol: "₽" },
  { value: "SGD", label: "SGD", name: "Singapore Dollar", symbol: "S$" },
  { value: "ZAR", label: "ZAR", name: "South African Rand", symbol: "R" },
  { value: "SEK", label: "SEK", name: "Swedish kron", symbol: "kr" },
  { value: "CHF", label: "CHF", name: "Swiss Franc", symbol: "CHF" },
  { value: "PHP", label: "PHP", name: "Philippines Peso", symbol: "₱" },
  { value: "DKK", label: "DKK", name: "Danish krone", symbol: "Kr." },
  { value: "NOK", label: "NOK", name: "Norwegian krone", symbol: "kr" },
  { value: "AFN", label: "AFN", name: "Afghanistan afghani", symbol: "Af" },
  { value: "ALL", label: "ALL", name: "Albanian lek", symbol: "L" },
  { value: "HUF", label: "HUF", name: "Hungarian forint", symbol: "Ft" },
  { value: "MYR", label: "MYR", name: "Malaysian ringgit", symbol: "RM" },
  { value: "MMK", label: "MMK", name: "Myanmar kyat", symbol: "K" },
  { value: "KPW", label: "KPW", name: "North Korean won", symbol: "₩" },
  { value: "PKR", label: "PKR", name: "Pakistani rupee", symbol: "₨" },
  { value: "SZL", label: "SZL", name: "Swaziland lilangeni", symbol: "L" },
  { value: "ZWL", label: "ZWL", name: "Zimbabwe dollar", symbol: "Z$" },
  { value: "TRY", label: "TRY", name: "Turkish lira", symbol: "₺" },
  { value: "SAR", label: "SAR", name: "Saudi riyal", symbol: "ر.س" },
  { value: "RON", label: "RON", name: "Romanian new leu", symbol: "lei" },
  { value: "AOR", label: "AOR", name: "Angolan kwanza reajustado", symbol: "AOR" },
  { value: "AMD", label: "AMD", name: "Armenian dram", symbol: "֏" },
  { value: "AWG", label: "AWG", name: "Aruban florin", symbol: "ƒ" },
  { value: "BSD", label: "BSD", name: "Bahamian dollar", symbol: "$" },
  { value: "BHD", label: "BHD", name: "Bahraini dinar", symbol: "ب.د" },
  { value: "BTN", label: "BTN", name: "Bhutan ngultrum", symbol: "Nu" },
  { value: "BOB", label: "BOB", name: "Bolivian boliviano", symbol: "$b" },
  { value: "BND", label: "BND", name: "Brunei dollar", symbol: "$" },
  { value: "BGN", label: "BGN", name: "Bulgarian lev", symbol: "лв" },
  { value: "KHR", label: "KHR", name: "Cambodian riel", symbol: "៛" },
  { value: "GEL", label: "GEL", name: "Georgian lari", symbol: "ლ" },
  { value: "JOD", label: "JOD", name: "Jordanian dinar", symbol: "د.ا" },
  { value: "LBP", label: "LBP", name: "Lebanese pound", symbol: "ل.ل." },
  { value: "LRD", label: "LRD", name: "Liberian Dollar", symbol: "$" },
  { value: "MNT", label: "MNT", name: "Mongolian tugrik", symbol: "₮" },
  { value: "NPR", label: "NPR", name: "Nepalese rupee", symbol: "₨" },
  { value: "MVR", label: "MVR", name: "Maldivian rufiyaa", symbol: "ރ." },
  { value: "BMD", label: "BMD", name: "Bermudian Dollar", symbol: "$" },
  { value: "CLP", label: "CLP", name: "Chilean Peso", symbol: "CLP$" },
  { value: "PEN", label: "PEN", name: "Peruvian Sol", symbol: "S/" },
  { value: "PLN", label: "PLN", name: "Polish Zloty", symbol: "zł" },
  { value: "MAD", label: "MAD", name: "Moroccan Dirham", symbol: "م.د." },
  { value: "THB", label: "THB", name: "Thai Baht", symbol: "฿" },
  { value: "CRC", label: "CRC", name: "Costa Rican Colon", symbol: "₡" },
  { value: "HRK", label: "HRK", name: "Croatian Kuna", symbol: "kn" },
  { value: "TWD", label: "TWD", name: "Taiwan dollar", symbol: "NT$" },
  { value: "RSD", label: "RSD", name: "Serbian dinar", symbol: "RSD " },
  { value: "KES", label: "KES", name: "Kenyan shilling", symbol: "Ksh" },
  { value: "ZMW", label: "ZMW", name: "Zambian Kwacha", symbol: "K" },
  { value: "NGN", label: "NGN", name: "Nigerian naira", symbol: "‎₦" },
  { value: "GHS", label: "GHS", name: "Ghanaian Cedi", symbol: "GH₵" },
  { value: "VND", label: "VND", name: "Vietnamese dong", symbol: "₫" },
  { value: "KZT", label: "KZT", name: "Kazakhstani Tenge", symbol: "₸" },
  { value: "XAF", label: "XAF", name: "Central African CFA franc", symbol: "FCFA" },
  { value: "OMR", label: "OMR", name: "Omani rial", symbol: "ر.ع." },
  { value: "NAD", label: "NAD", name: "Namibian Dollar", symbol: "N$" },
  { value: "QAR", label: "QAR", name: "Qatari Riyal", symbol: "QR" },
  { value: "BWP", label: "BWP", name: "Botswanan Pula", symbol: "P" },
  { value: "KYD", label: "KYD", name: "Cayman Islands Dollar", symbol: "CI$" },
  { value: "LAK", label: "LAK", name: "Laotian Kip", symbol: "₭", crypto: false },
  { value: "UAH", label: "UAH", name: "Ukrainian hryvnia", symbol: "₴", crypto: false },
  { value: "TND", label: "TND", name: "Tunisian Dinar", symbol: "د.ت", crypto: false },
  { value: "PYG", label: "PYG", name: "Paraguayan Guarani", symbol: "₲", crypto: false },
  { value: "UYU", label: "UYU", name: "Uruguayan Peso", symbol: "$U", crypto: false },
  { value: "ECS", label: "ECS", name: "Ecuadorian Sucre", symbol: "S/.", crypto: false },
  { value: "RWF", label: "RWF", name: "Rwandan franc", symbol: "R₣" },
  { value: "GYD", label: "GYD", name: "Guyanese Dollar", symbol: "GY$" },
  { value: "ISK", label: "ISK", name: "Icelandic Krona", symbol: "Íkr" },
];
export default currency_codes;
