import { mapPaymentMethodPayloadForm } from "../services/common/paymentMethod.svc";

export const INITIAL_STATE = {
  payment_type: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  state: "",
  zipcode: "",
  country: "USA",
  currency_code: "",
  routing: "",
  account_name: "",
  account_number: "",
  account_type: "",
  bank_name: "",
  bsb_code: "",
  ifsc_code: "",
  phoneNumber: "",
  account_tax_number: "",
  institution_number: "",
  transit_number: "",
  cardNumber: "",
  bic: "",
  paymentCNPJ: "",
  sort_code: "",
  paymentPAN: "",
  account_name_english: "",
  id_number: "",
  storefront_url: "",
  incorporation_number: "",
  bankCode: "",
  swiftCode: "",
  iban: "",
  bik_code: "",
  vo_code: "",
  inn_tax_id: "",
  paymentCountryLabel: "",
  cpf: "",
  payee_type: "COMPANY",
  suffix: "",
  id: "",
  paymentConfirmAccountNumber: "",
  purchaser_id: null,
  branchCode: null,
  is_primary: true,
  contact_first_name: "",
  contact_last_name: "",
  contact_email: "",
  pay_by_swift_code: false,
  properties: null,
  payoneer_response_desc: null,
  intrmyBic: "",
  intrmyCountry: "",
  intrmyName: "",
  intrmyNumber: "",
  purpose_code: "",
  delivery_method: "",
  subscribers: [],
  mail_type: "",
  program_id: "",
  status: null,
};

const paymentMethodReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_PAYMENT_TYPE":
      return {
        ...state,
        payment_type: action.payload,
      };
    case "CHANGE_PAYMENT_METHOD_ADDRESSLINE":
      return {
        ...state,
        address1: action.payload,
      };
    case "CHANGE_PAYEE_TYPE":
      return {
        ...state,
        payee_type: action.payload,
      };
    case "CHANGE_PAYMENT_METHOD_CITY":
      return {
        ...state,
        city: action.payload,
      };
    case "CHANGE_PAYMENT_METHOD_STATE":
      return {
        ...state,
        state: action.payload,
      };
    case "CHANGE_PAYMENT_METHOD_ZIPCODE":
      return {
        ...state,
        zipcode: action.payload,
      };
    case "CHANGE_IS_PRIMARY":
      return {
        ...state,
        is_primary: action.payload,
      };
    case "CHANGE_COUNTRY_CURRENCY_CODE":
      return {
        ...state,
        country: action.payload.country_code,
        currency_code: action.payload.currency_code,
      };
    case "CHANGE_COUNTRY_CODE":
      return {
        ...state,
        country: action.payload.value,
      };
    case "CHANGE_CURRENCY_CODE":
      return {
        ...state,
        currency_code: action.payload.value,
      };
    case "CHANGE_CAD_CURRENCY_CODE":
      return {
        ...state,
        currency_code: action.payload,
      };
    case "CHANGE_PURCHASER":
      return {
        ...state,
        purchaser_id: action.payload.value,
      };
    case "CHANGE_ROUTING":
      return {
        ...state,
        routing: action.payload,
      };
    case "CHANGE_ACCOUNT_NAME":
      return {
        ...state,
        account_name: action.payload,
      };
    case "CHANGE_ACCOUNT_NUMBER":
      return {
        ...state,
        account_number: action.payload,
      };
    case "CHANGE_CONFIRM_ACCOUNT_NUMBER":
      return {
        ...state,
        paymentConfirmAccountNumber: action.payload,
      };
    case "CHANGE_ACCOUNT_TYPE":
      return {
        ...state,
        account_type: action.payload,
      };
    case "CHANGE_BANK_CODE":
      return {
        ...state,
        bankCode: action.payload,
      };
    case "CHANGE_BRANCH_CODE":
      return {
        ...state,
        branchCode: action.payload,
      };
    case "CHANGE_SWIFT_CODE":
      return {
        ...state,
        swiftCode: action.payload,
      };
    case "CHANGE_IBAN_CODE":
      return {
        ...state,
        iban: action.payload,
      };
    case "CHANGE_BANK_NAME":
      return {
        ...state,
        bank_name: action.payload,
      };
    case "CHANGE_PAYMENT_BANK_ADDRESS":
      return {
        ...state,
        bank_address: action.payload,
      };
    case "CHANGE_FIRST_NAME":
      return {
        ...state,
        contact_first_name: action.payload,
      };
    case "CHANGE_LAST_NAME":
      return {
        ...state,
        contact_last_name: action.payload,
      };
    case "CHANGE_EMAIL":
      return {
        ...state,
        contact_email: action.payload,
      };
    case "CHANGE_PHONE":
      return {
        ...state,
        paymentPhone: action.payload,
      };
    case "CHANGE_DELIVERY_METHOD":
      return {
        ...state,
        delivery_method: action.payload,
      };
    case "CHANGE_BSB_CODE":
      return {
        ...state,
        bsb_code: action.payload,
      };
    case "CHANGE_BUSINESS_NUMBER":
      return {
        ...state,
        paymentBusinessNumber: action.payload,
      };
    case "CHANGE_IFSC":
      return {
        ...state,
        ifsc_code: action.payload,
      };
    case "CHANGE_PHONE_NUMBER":
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case "CHANGE_ACCOUNT_TAX_NUMBER":
      return {
        ...state,
        account_tax_number: action.payload,
      };
    case "CHANGE_INSTITUTION_NUMBER":
      return {
        ...state,
        institution_number: action.payload,
      };
    case "CHANGE_TRANSIT_NUMBER":
      return {
        ...state,
        transit_number: action.payload,
      };
    case "CHANGE_CARD_NUMBER":
      return {
        ...state,
        cardNumber: action.payload,
      };
    case "CHANGE_BIC_CODE":
      return {
        ...state,
        bic: action.payload,
      };
    case "CHANGE_CNPJ":
      return {
        ...state,
        account_tax_number: action.payload,
      };
    case "CHANGE_SORT_CODE":
      return {
        ...state,
        sort_code: action.payload,
      };
    case "CHANGE_PAN":
      return {
        ...state,
        account_tax_number: action.payload,
      };
    case "CHANGE_ACCOUNT_NAME_ENGLISH":
      return {
        ...state,
        account_name_english: action.payload,
      };
    case "CHANGE_ID_NUMBER":
      return {
        ...state,
        id_number: action.payload,
      };
    case "CHANGE_ID":
      return {
        ...state,
        id: action.payload,
      };
    case "CHANGE_STOREFRONT_URL":
      return {
        ...state,
        storefront_url: action.payload,
      };
    case "CHANGE_INCORPORATION_NUMBER":
      return {
        ...state,
        incorporation_number: action.payload,
      };
    case "CHANGE_CPF":
      return {
        ...state,
        cpf: action.payload,
      };
    case "CHANGE_SUFFIX":
      return {
        ...state,
        suffix: action.payload,
      };
    case "CHANGE_MERCHANT_NAME":
      return {
        ...state,
        merchant_name: action.payload,
      };
    case "CHANGE_BIK_CODE":
      return {
        ...state,
        bik_code: action.payload,
      };
    case "CHANGE_VO_CODE":
      return {
        ...state,
        vo_code: action.payload,
      };
    case "CHANGE_INN_TAX_ID":
      return {
        ...state,
        inn_tax_id: action.payload,
      };
    case "CHANGE_MERCHANT_ID":
      return {
        ...state,
        merchant_id: action.payload,
      };
    case "CHANGE_SUBSCRIBERS":
      return {
        ...state,
        subscribers: action.payload,
      };
    case "CHANGE_PAY_BY_SWIFT_CODE":
      return {
        ...state,
        pay_by_swift_code: action.payload,
      };
    case "CHANGE_INTRMY_BIC":
      return {
        ...state,
        intrmyBic: action.payload,
      };
    case "CHANGE_INTRMY_COUNTRY":
      return {
        ...state,
        intrmyCountry: action.payload,
      };
    case "CHANGE_INTRMY_NAME":
      return {
        ...state,
        intrmyName: action.payload,
      };
    case "CHANGE_INTRMY_NUMBER":
      return {
        ...state,
        intrmyNumber: action.payload,
      };
    case "CHANGE_PURPOSE_CODE":
      return {
        ...state,
        purpose_code: action.payload,
      };
    case "CHANGE_MAIL_TYPE":
      return {
        ...state,
        mail_type: action.payload,
      };
    case "CHANGE_PAYMENT_METHOD_ADDRESS_2":
      return {
        ...state,
        address2: action.payload,
      };
    case "CHANGE_PAYMENT_METHOD_ADDRESS_3":
      return {
        ...state,
        address3: action.payload,
      };
    case "CHANGE_PROGRAM_ID":
      return {
        ...state,
        program_id: action.payload,
      };
    case "CHANGE_PAYMENT_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "CHANGE_CAMBRIDGE_FIELD":
      return {
        ...state,
        cambridge: {
          ...state.cambridge,
          [action.payload.id]: action.payload.value,
        },
      };
    case "CHANGE_CAMBRIDGE_REGULATORY_FIELD":
      return {
        ...state,
        cambridge: {
          ...state.cambridge,
          regulatoryRules: {
            ...state.cambridge.regulatoryRules,
            [action.payload.id]: action.payload.value,
          },
        },
      };
    //For cases when we dont know exactly we are updating. Such as payoneer dynamic field names
    case "CHANGE_PAYMENT_METHOD":
      return Object.assign({}, state, action.payload);
    case "CLEAR_PAYMENT_METHOD":
      return {
        ...state,
        address1: "",
        city: "",
        state: "",
        zipcode: "",
        country: "USA",
        routing: "",
        account_name: "",
        account_number: "",
        account_type: "",
        bank_name: "",
        bsb_code: "",
        ifsc_code: "",
        phoneNumber: "",
        account_tax_number: "",
        institution_number: "",
        transit_number: "",
        cardNumber: "",
        bic: "",
        paymentCNPJ: "",
        sort_code: "",
        paymentPAN: "",
        account_name_english: "",
        id_number: "",
        storefront_url: "",
        incorporation_number: "",
        bankCode: "",
        swiftCode: "",
        iban: "",
        paymentCountryLabel: "",
        cpf: "",
        payee_type: "COMPANY",
        suffix: "",
        id: "",
        paymentConfirmAccountNumber: "",
        currency_code: "",
        purchaser_id: action.payload ? null : state.purchaser_id,
        contact_first_name: "",
        contact_last_name: "",
        contact_email: "",
        plaid_account_id: "",
        merchant_name: "",
        merchant_id: "",
        subscribers: [],
        pay_by_swift_code: false,
        properties: null,
        payoneer_response_desc: null,
        intrmyBic: "",
        intrmyCountry: "",
        intrmyName: "",
        intrmyNumber: "",
        purpose_code: "",
      };

    case "FULL_CLEAR_PAYMENT_METHOD":
      return INITIAL_STATE;

    case "EDIT_PAYMENT_METHOD":
      const details = action.payload;
      const mapedToForm = mapPaymentMethodPayloadForm(details);
      return {
        ...state,
        ...mapedToForm,
      };

    default:
      return state;
  }
};
export default paymentMethodReducer;
