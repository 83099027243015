import { AxiosResponse } from "axios";
import useIsMounted from "components/common/hooks/useIsMounted";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useTypedSelector } from "reducers";
import { WrappedFieldProps } from "redux-form";
import { IUser } from "services/common/user/userTypes";
import { CreateNotification, NotificationType } from "services/general/notifications";
import { restApiService } from "../../../../../providers/restApi";
import { onBlurSingle, onChangeSingle } from "../../../../../services/general/helpers";
import { Mandatory } from "../../../../forms/bootstrapFields";

interface CategoryPickerPropsType extends WrappedFieldProps {
  label?: ReactNode;
  input: any;
  required?: boolean;
  tooltip?: string;
  disabled?: boolean;
  floating?: boolean;
  modelData?: any;
  parentObjData?: any;
  instanceId?: string;
  callBack?: (category: CategoriesObjType) => void;
}

export type CategoriesObjType = {
  id?: number | string;
  is_category_map_to_account?: boolean;
  name?: string;
  status?: string;
  label?: string;
  value?: number | string;
};

const CategoryPicker = ({
  label,
  input,
  required,
  tooltip,
  disabled,
  floating = false,
  modelData,
  parentObjData,
  instanceId = "category-picker",
  callBack,
}: CategoryPickerPropsType) => {
  const [categories, setCategories] = useState<CategoriesObjType[] | null>(null);
  const [selected, setSelected] = useState<CategoriesObjType | null>();
  const isMounted = useIsMounted();
  const currentUser: IUser = useTypedSelector((state) => state.user);

  const parseForSelect = (options: CategoriesObjType[]) => {
    return options.map((option: CategoriesObjType) => {
      return {
        value: option.id,
        label: option.name,
        status: option.status,
        ...option,
      };
    });
  };

  const getCategories = useCallback(async () => {
    let params: any = {
      status: "ACTIVE",
    };

    if (modelData?.department?.has_categories) {
      params.department_id = modelData.department.id;
    }

    if (currentUser?.company?.po_request_hide_expense_line_department && parentObjData?.department?.has_categories) {
      params.department_id = parentObjData.department.id;
    }

    if (modelData?.policy_id > 0) {
      params.policy_id = modelData.policy_id;
    }

    const result: AxiosResponse = await restApiService.get(`categories.lk`, params, null, true, null, true);
    if (result?.data) {
      let list = parseForSelect(result?.data);

      if (!required) {
        list = [
          {
            label: "-- Select Category --",
            value: "",
            status: undefined,
          },
          ...list,
        ];
      }
      if (isMounted.current) {
        setCategories(list);
      }
    }
  }, [
    currentUser?.company?.po_request_hide_expense_line_department,
    isMounted,
    modelData,
    parentObjData?.department?.has_categories,
    parentObjData?.department?.id,
    required,
  ]);

  const findSelected = useCallback(async () => {
    if (!input.value || categories === null) {
      setSelected(null);
      return;
    }

    const category = categories.find((option: any) => option.value === input.value);
    if (category) {
      setSelected(category);
    } else {
      // Fetch inactive categories from the API and enable them to be displayed in the category dropdown.
      try {
        const result = await restApiService.get("categories/" + input.value);
        const resCat: CategoriesObjType = {
          value: result.data.id,
          label: result.data.name,
          status: result.data.status,
        };
        setSelected(resCat);
      } catch (error) {
        console.log(error);
        CreateNotification("Error", "Problem loading inactive category.", NotificationType.danger);
      }
    }
  }, [categories, input.value]);

  const onChangeCategory = (input: any, selected: any) => {
    onChangeSingle(input, selected);
    setSelected(selected);
    if (callBack) {
      callBack(selected);
    }
  };

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    findSelected();
  }, [findSelected]);

  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label ?? ""}
          <Mandatory required={required} />
          {tooltip ?? ""}
        </Form.Label>
      )}
      <Select
        {...input}
        required={false}
        value={selected}
        onChange={(selected) => onChangeCategory(input, selected)}
        onBlur={() => onBlurSingle(input, input.value)}
        options={categories}
        isClearable={!required}
        disable={disabled}
        instanceId={instanceId}
        getOptionLabel={(option: CategoriesObjType) => {
          return (
            <>
              <span className="formField">{option.label}</span>
              {option.status === "INACTIVE" && <small style={{ fontSize: "10px" }}> -({option.status})</small>}
            </>
          );
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
          }),
          ...(floating ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : {}),
        }}
        {...(floating ? { menuPortalTarget: document.body } : {})}
      />
    </Form.Group>
  );
};

export default CategoryPicker;
