import CustomModal from "components/modals/customModal";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { PiWarningCircleBold } from "react-icons/pi";
import { setSubmitSucceeded } from "redux-form";

interface FormSubmissionErrorModalProps {
  fieldPageMapping?: any;
  formErrors?: any;
  submitErrors?: any;
  formName: string;
  show: boolean;
  dispatch?: any;
}

type errorsType = {
  fieldName?: "string";
  error?: string | [metadataErrorType | null];
};

type metadataErrorType = {
  value?: string;
  metadata_field_id?: string;
};

/**
 * Represents a modal component that displays form submission errors.
 *
 * @component
 * @param {FormSubmissionErrorModalProps} props - The component props.
 * @param {object} props.formErrors - The errors object containing form submission errors.
 * @param {object} props.submitErrors - The errors from the api.
 * @param {object[]} props.fieldPageMapping - The mapping of fields to their respective pages.
 * @param {string} props.formName - The name of the form.
 * @param {function} props.dispatch - The dispatch function for updating form state.
 * @param {boolean} props.show - Determines whether the modal is visible or hidden.
 * @returns {JSX.Element} The rendered component.
 */
const FormSubmissionErrorModal: React.FC<FormSubmissionErrorModalProps> = ({
  formErrors,
  submitErrors,
  fieldPageMapping,
  formName,
  dispatch,
  show,
}) => {
  const { t } = useTranslation();

  //this is experimental, it lets us close the modal without tracking anything else.
  const errorModalClosed = () => {
    dispatch(setSubmitSucceeded(formName, "submitSucceeded"));
  };

  // parses the passed in errors into their respective pages
  const parseFormErrors = () => {
    if (formErrors && Object.entries(formErrors).length > 0) {
      //copy as we are adding errors to the object
      let copiedFieldMapping = JSON.parse(JSON.stringify(fieldPageMapping));

      //loop over the erros and attach them to the correct page based on the fieldPageMapping
      Object.keys(formErrors).forEach((error: any) => {
        let foundHome = false;
        copiedFieldMapping.forEach((page: any) => {
          if (page.fields && page.fields.includes(error)) {
            setErrorFromField(page, formErrors[error], error);
            foundHome = true;
          }
        });

        if (!foundHome) {
          setErrorFromField(copiedFieldMapping[0], formErrors[error], error);
        }
      });

      return copiedFieldMapping.filter((page: any) => page.errors && page.errors.length > 0);
    } else {
      return [];
    }
  };

  //given an error from redux form, parse it and attach it to the passed in page
  const setErrorFromField = (page: any, field: any, fieldName: string) => {
    if (!page.errors) {
      page.errors = [];
    }

    let allErrors: any[] = [];

    //Some errors are nested such as invoice_item_attributes so we need to unpack them and append as individual errors
    if (Array.isArray(field)) {
      field.forEach((fieldError: any) => {
        if (fieldError) {
          Object.keys(fieldError).forEach((key: any) => {
            allErrors.push({
              fieldName: key,
              error: _.isPlainObject(fieldError[key]) ? JSON.stringify(fieldError[key]) : fieldError[key],
            });
          });
        }
      });
    } else {
      allErrors = [{ fieldName: fieldName, error: _.isPlainObject(field) ? JSON.stringify(field) : field }];
    }

    page.errors = [...page.errors, ...allErrors];
  };

  // removes underscores and capitalizes the first letter
  const formatFieldName = (str: string) => {
    if (!str) return str;
    // Remove all underscores
    let formattedStr = str.replace(/_/g, " ");
    // Capitalize the first word
    formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
    return formattedStr;
  };

  //formats the given api error field name into a more readable format
  const formatApiError = (errorKey: string) => {
    if (errorKey == "base") {
      return t("general");
    }

    let dotSplit = errorKey.split(".");
    // join all the splits, if one of the splits has an _, split that and join
    return dotSplit
      .map((split: string) => {
        let underscoreSplit = split.split("_");
        return underscoreSplit
          .map((underscore: string) => {
            return underscore;
          })
          .join(" ");
      })
      .join(" ");
  };

  const parsedErrors = parseFormErrors();

  const errorMessage = (errors: errorsType[]) => {
    return (
      <>
        {errors?.map((error: errorsType) => {
          if (typeof error?.error === "string") {
            return <p key={error.fieldName}>{error.error}</p>;
          }

          if (Array.isArray(error?.error)) {
            const foundError = error?.error.find((el) => el?.value || el?.metadata_field_id);

            if (foundError) {
              const value = foundError.value;
              const metadataFieldId = foundError.metadata_field_id;

              return (
                <p key={error.fieldName}>
                  {typeof value === "string" ? value : JSON.stringify(value) || metadataFieldId || null}
                </p>
              );
            }
          }

          return null;
        })}
      </>
    );
  };

  return (
    <CustomModal
      header={
        <div>
          <PiWarningCircleBold className={"text-danger"} /> {t("invoiceEditPage.failedToSave")}
        </div>
      }
      body={
        <div className="mb-4">
          <div className="mb-2 mb-3">
            <p>{t("validation.reviewInformation")}</p>
          </div>

          {parsedErrors && parsedErrors.length > 0 && (
            <div className="d-flex justify-content-center">
              <table className="table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th className="">{t("Field")}</th>
                    <th className="">{t("Error Type")}</th>
                  </tr>
                </thead>
                <tbody>
                  {parsedErrors &&
                    parsedErrors.map((page: any) => {
                      return (
                        <tr key={page.label}>
                          <td className="pl-2 pr-4">{page.label}</td>
                          <td className="p-2">
                            {page.errors.map((error: any) => {
                              return <p key={error.fieldName}>{formatFieldName(error.fieldName)}</p>;
                            })}
                          </td>

                          <td className="p-2">{errorMessage(page.errors)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}

          {submitErrors && Object.keys(submitErrors).length > 0 && (
            <div className="mt-4">
              <p className="text-danger">{t("validation.submissionErrors")}</p>

              {Object.keys(submitErrors).map((key: any) => {
                return (
                  <p key={key}>
                    <span className="text-capitalize">{formatApiError(key)}: </span> {submitErrors[key].join(", ")}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      }
      show={show}
      onHide={errorModalClosed}
    />
  );
};

export default FormSubmissionErrorModal;
