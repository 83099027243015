import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { VendorSettings } from "./vendorSettings";
import DocumentRequirement from "./component/documentRequirement";
import CommonSettingField from "../../components/commonSettingField";

const VendorSetting = () => {
  const formName = "companyDefaultForm";
  const dispatch = useDispatch();

  const isShowObj = {};
  const company_default = useSelector((state) => state.form.companyDefaultForm.values.company_default);
  const company = useSelector((state) => state.form.companyDefaultForm.values.company);

  const current_user = useSelector((state) => state.user);
  isShowObj.company_default = company_default;
  isShowObj.company = company;
  isShowObj.current_user = current_user;

  const onChangeObj = {};
  onChangeObj.formName = formName;
  onChangeObj.dispatch = dispatch;
  onChangeObj.company_default = company_default;
  onChangeObj.company = company;

  return (
    <>
      <Col>
        <Row className="m-0 pt-4 ">
          <Col>
            <Row>
              {/* To show diffent columns we will filter json accronding to col value */}
              <Col md="4">
                {/* coloum no. 1 */}
                {VendorSettings.filter((setting) => setting.col === 1).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
              <Col md="4">
                {/* coloum no. 2 */}
                {VendorSettings.filter((setting) => setting.col === 2).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
              <Col md="4">
                {/* coloum no. 3 */}
                {VendorSettings.filter((setting) => setting.col === 3).map((setting, index) => (
                  <CommonSettingField key={index} setting={setting} isShowObj={isShowObj} onChangeObj={onChangeObj} />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="m-0">
          <DocumentRequirement />
        </Row>
      </Col>
    </>
  );
};

export default VendorSetting;
