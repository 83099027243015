import React from "react";
import _ from "lodash";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { restApiService } from "providers/restApi";

type BreadcrumbPropsType = {
  breadcrumbItems: {
    name: string;
    link?: string;
    href?: string;
  }[];
  trailLabel?: string;
};

const Breadcrumb = ({ breadcrumbItems, trailLabel }: BreadcrumbPropsType) => {
  return (
    <div className={"no-print d-flex justify-content-start align-content-center  breadcrumb-section"}>
      {_.isArray(breadcrumbItems) &&
        breadcrumbItems.map((breadcrumItem, index) => (
          <React.Fragment key={index}>
            {breadcrumItem.link ? (
              <React.Fragment key={breadcrumItem.name}>
                {index !== 0 && <>/ </>}
                <Link className={`${index === 0 ? "mr-2" : "mx-2"}`} to={breadcrumItem.link}>
                  <Nav.Item className={"breadcrumb-title pr-1 mr-1"}>{breadcrumItem.name}</Nav.Item>
                </Link>
              </React.Fragment>
            ) : (
              <React.Fragment key={breadcrumItem.name}>
                {index !== 0 && <>/</>}
                <a
                  className={`link ${index === 0 ? "mr-2" : "mx-2"}`}
                  href={restApiService.angularBaseURL() + breadcrumItem.href}
                >
                  {breadcrumItem.name}
                </a>
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      {trailLabel && <p className="breadcrumb-label">/ {trailLabel}</p>}
    </div>
  );
};
export default Breadcrumb;
