import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTypedSelector } from "../../../reducers";
import { IUser } from "../../../services/common/user/userTypes";
import ProfilePhotoPicker from "../../../components/common/profilePhotoPicker/profilePhotoPicker";
import { useTranslation } from "react-i18next";
import { companyDateTimeFormat } from "../../../services/general/dateSvc";
import { restApiService } from "../../../providers/restApi";
import { shallowEqual, useDispatch } from "react-redux";
import { updateCurrentUser } from "../../../reducers/userReducers";
import UpdatePassword, {
  UpdatePasswordFormDataType,
} from "../../../components/admin/updatePasswordForm/updatePasswordForm";
import Panel from "../../../components/common/panel/panel";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import styles from "./userProfile.module.css";
import CustomModal from "../../../components/modals/customModal";
import Addresses from "../../../components/admin/addresses";
import ErrorBoundary from "../../../components/common/errorBoundary/errorBoundary";
import NotificationSettings from "../../../components/admin/userProfile/notificationSettings/noficationSettings";
import PerferencesForm, {
  PreferenceFormDataType,
} from "../../../components/admin/userProfile/preferences/preferencesForm";
import { getContact } from "../../../reducers/admin/userProfileReducer";
import ManagePaymentMethod from "../../../components/admin/managePaymentMethod/managePaymentMethod";
import UserDelegation from "./userDelegation/userDelegation";

const DetialsUserProfile = () => {
  const currentUser = useTypedSelector((state) => state.user as IUser, shallowEqual);
  const currentContact = useTypedSelector((state) => state.userProfileRed.contact, shallowEqual);
  const [otpSecret, setOtpSecret] = useState<string>("");
  const [showOtpSecretModal, setShowOtpSecretModal] = useState(false);
  const [showDisable2FAuthModal, setShowDisable2FAuthModal] = useState(false);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const isOktaUser = currentUser.okta_id;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initialStatePreferenceForm: PreferenceFormDataType = {
    contact: {
      delegate_id: currentContact?.delegate_id,
      language: currentContact?.language,
      delegate_start_date: currentContact?.delegate_start_date,
      delegate_end_date: currentContact?.delegate_end_date,
      phone: currentContact?.phone,
    },
  };

  useEffect(() => {
    // updating current user
    dispatch(updateCurrentUser());
    dispatch(getContact());
  }, []);

  const profileFormData = new FormData();
  const onChangeProfilePhoto = (file: File) => {
    profileFormData.append("user[avatar][asset_file]", file);
    profileFormData.append("user[avatar][file_name]", file.name);
  };

  const onCancelProfilePhoto = () => {
    profileFormData.delete("user[avatar][asset_file]");
    profileFormData.delete("user[avatar][file_name]");
  };

  const onSaveProfilePhoto = async (onSaveSuccessCallback: () => void) => {
    try {
      setImageLoading(true);
      const response = await restApiService.formWithImage("users/" + currentUser.id, profileFormData, "patch");
      // no reload is required just updating currentUser
      await dispatch(updateCurrentUser());
      setImageLoading(false);
      onSaveSuccessCallback();
    } catch (error) {
      setImageLoading(false);
      console.log(error);
    }
  };

  const onPasswordSubmit = async (updatePasswordFormData: UpdatePasswordFormDataType) => {
    try {
      const response = await restApiService.patch("users/" + currentUser.id, null, {
        user: updatePasswordFormData,
      });
      dispatch(updateCurrentUser());
      CreateNotification(t("success"), t("profilePage.passwordChanged"), NotificationType.success);
    } catch (error) {
      CreateNotification(t("error"), t("errors.genericSupport"), NotificationType.warning);
    }
  };

  const toggleOtpSecretModal = () => {
    setShowOtpSecretModal((prev) => !prev);
  };

  const enableTwoFactor = async () => {
    try {
      const response = await restApiService.post("users/enable_two_factor");
      setOtpSecret(response.data.otp_secret);
      setShowOtpSecretModal(true);
      dispatch(updateCurrentUser());
    } catch (error) {}
  };

  const toggleDisabled2fModal = () => {
    setShowDisable2FAuthModal((prev) => !prev);
  };

  const disableTwoFactor = async () => {
    try {
      const response = await restApiService.post("users/disable_two_factor");
      setShowDisable2FAuthModal(true);
      dispatch(updateCurrentUser());
    } catch (error) {}
  };

  // save preference
  const onSavePreference = async (preferenceFormData: PreferenceFormDataType) => {
    try {
      const userObj = {
        user: {
          app: currentUser.app_region,
          contact_attributes: {
            ...currentContact,
            ...preferenceFormData.contact,
          },
          ...preferenceFormData.security,
        },
      };
      const response = await restApiService.patch("users/" + currentUser.id, null, userObj);

      CreateNotification("success", t("admin.pages.userProfile.preferenceUpdated"), NotificationType.success);

      // update redux stroage of user not reloading
      dispatch(updateCurrentUser());
    } catch (error) {}
  };

  return (
    <>
      {showOtpSecretModal && (
        <CustomModal
          show={showOtpSecretModal}
          onHide={() => toggleOtpSecretModal()}
          size="xl"
          header={<>{t("admin.pages.userProfile.enable2fAuthHeader")}</>}
          body={
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex flex-column align-items-center">
                <p className="blackColor">{t("admin.pages.userProfile.enable2fAuthbody")}</p>
                <h4 className="blackColor">{t("admin.pages.userProfile.otpSecret", { otpSecret })} </h4>
              </div>
            </div>
          }
          footer={
            <>
              <Button variant="secondary" onClick={() => toggleOtpSecretModal()}>
                {t("close")}
              </Button>
            </>
          }
        />
      )}
      {showDisable2FAuthModal && (
        <CustomModal
          show={showDisable2FAuthModal}
          onHide={() => toggleDisabled2fModal()}
          size="xl"
          header={<>{t("admin.pages.userProfile.disable2fAuthHeader")}</>}
          body={
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex flex-column align-items-center">
                <p className="blackColor">{t("admin.pages.userProfile.disable2fAuthBody")}</p>
              </div>
            </div>
          }
          footer={
            <>
              <Button variant="secondary" onClick={() => toggleDisabled2fModal()}>
                {t("close")}
              </Button>
            </>
          }
        />
      )}

      <Container fluid className="mt-4">
        <Row className={styles.profileSection}>
          <Col md="12" className={styles.positionRelative}>
            <ErrorBoundary>
              <Row className={styles.profilePickerRow}>
                <Col>
                  <ProfilePhotoPicker
                    sourceUrl={currentUser.avatar?.asset_expiring_url}
                    onChangeImageCallBack={(file: File) => onChangeProfilePhoto(file)}
                    onCancelImageCallBack={() => onCancelProfilePhoto()}
                    onSaveImageCallBack={(onSaveSuccessCallback) => {
                      if (onSaveSuccessCallback !== undefined) {
                        onSaveProfilePhoto(onSaveSuccessCallback);
                      }
                    }}
                    loading={imageLoading}
                  />
                </Col>
              </Row>
            </ErrorBoundary>
            <Row className="mt-4">
              <Col md="12">
                <Row>
                  <Col md={{ span: 9, offset: 3 }} className="mt-5">
                    <Row>
                      <Col>
                        <h2>{currentUser.name}</h2>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <label>{t("admin.pages.userProfile.lastLogInAt")}</label>{" "}
                        {companyDateTimeFormat(currentUser.last_sign_in_at, currentUser, "hh:mm:ss")}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr className={"full-border " + styles.fullBorder} />
                <Row>
                  <Col md="12">
                    <Row>
                      <Col md={{ span: 9, offset: 3 }}>
                        <Row>
                          <Col md="4">
                            <label>{t("admin.pages.userProfile.email")}</label>
                            <div>{currentUser.email}</div>
                          </Col>
                          <Col md="4">
                            <label>{t("admin.pages.userProfile.department")}</label>
                            <div>{currentUser.contact?.department_list_str}</div>
                          </Col>
                          <Col md="4">
                            <label>{t("admin.pages.userProfile.departmentHead")}</label>
                            <div>{currentUser.contact?.manager?.name}</div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <ErrorBoundary>
              <PerferencesForm
                onSubmit={onSavePreference}
                initialValues={initialStatePreferenceForm}
                cardBodyClass={isOktaUser ? "" : styles.preferenceForm}
              />
            </ErrorBoundary>
          </Col>
          {!isOktaUser && (
            <Col md="6">
              <ErrorBoundary>
                <Panel
                  header={
                    <>
                      <i className="icon icon-password m-0 mx-2" /> {t("admin.pages.userProfile.updatePassword")}
                    </>
                  }
                >
                  <UpdatePassword
                    onSubmit={(updatePasswordData: UpdatePasswordFormDataType) => onPasswordSubmit(updatePasswordData)}
                  />
                  <Row className="mt-2">
                    <Col md="6">
                      {!currentUser.otp_required_for_login && (
                        <Button className="w-100" onClick={() => enableTwoFactor()}>
                          {t("admin.pages.userProfile.enable2fAuth")}
                        </Button>
                      )}
                      {currentUser.otp_required_for_login && (
                        <Button className="w-100" onClick={() => disableTwoFactor()}>
                          {t("admin.pages.userProfile.disable2fAuth")}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Panel>
              </ErrorBoundary>
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <UserDelegation />
          </Col>
        </Row>

        <Row>
          <Col>
            {currentContact && currentContact?.id && (
              <ManagePaymentMethod
                methodableType="Contact"
                methodableId={currentContact?.id}
                // as not permission required
                allowAdd
                allowEdit
                allowDelete
                section="userProfile"
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <ErrorBoundary>
              <Addresses
                addressableId={currentUser.contact.id}
                addressableType={"Contact"}
                // as not permission required
                allowDelete
                allowEdit
              />
            </ErrorBoundary>
          </Col>
        </Row>

        <Row>
          <Col>
            <ErrorBoundary>
              <NotificationSettings />
            </ErrorBoundary>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetialsUserProfile;
