import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { Can } from "../../../services/authorization/authorization";
import Panel from "../../panel/panel";
import RestApi from "../../../providers/restApi";
import { useDispatch, useSelector } from "react-redux";
import { removeCalenderEvents, setCalenderEvents } from "../../../reducers/admin/calendarEventerReducer";
import _ from "lodash";
import AddDate from "./add";
import EditDate from "./edit";
import useConfirmModal from "../../modals/confirmModal/useConfirmModalHook";
import { CreateNotification, NotificationType } from "../../../services/general/notifications";
import { useTranslation } from "react-i18next";

const restApiService = new RestApi();

const ImportnatDates = () => {
  const { t } = useTranslation();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editEventData, setEditEventData] = useState(null);
  const { createConfirmModal } = useConfirmModal();
  const calendarEvents = useSelector((state) => state.calendarEventerRed.calendarEvents);
  const dispatch = useDispatch();

  const getImportantDates = useCallback(async () => {
    try {
      const response = await restApiService.get("calendar_events");
      dispatch(setCalenderEvents(response.data));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getImportantDates();
  }, [getImportantDates]);

  const addEventDate = () => {
    setAddModalOpen(true);
  };

  const editEventDate = (calendarEvent) => {
    setEditEventData(calendarEvent);
    setEditModalOpen(true);
  };

  const removeEventDate = (id) => {
    createConfirmModal({
      title: t("components.admin.calendarEvent.removeCalendarEvent"),
      body: t("components.admin.calendarEvent.confirmDeleteEvent", { id: id }),
      callBackData: id,
      saveCallBack: removeConfirmCallback,
    });
  };

  const removeConfirmCallback = async (id) => {
    try {
      await restApiService.delete("calendar_events/" + id);
      dispatch(removeCalenderEvents(id));
      CreateNotification(
        t("success"),
        t("components.admin.calendarEvent.removeSuccess", { id: id }),
        NotificationType.success,
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {addModalOpen && <AddDate addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />}
      {editModalOpen && (
        <EditDate editModalOpen={editModalOpen} setEditModalOpen={setEditModalOpen} dateEventData={editEventData} />
      )}
      <Can I={"manage"} a={"ClosingDates"}>
        <Row>
          <Col sm="12">
            <Panel cardBodyStyle={{ padding: 0 }}>
              <Card.Header className="d-flex justify-content-between">
                <div className="align-self-center">{t("components.admin.calendarEvent.importantDates")}</div>
                <Button size="sm" onClick={() => addEventDate()}>
                  {t("components.admin.calendarEvent.addDate")}
                </Button>
              </Card.Header>
              <Card.Body>
                <Table bordered>
                  <thead>
                    <tr>
                      <td>{t("components.admin.calendarEvent.type")}</td>
                      <td>{t("components.admin.calendarEvent.date")}</td>
                      <td>{t("components.admin.calendarEvent.title")}</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {_.isArray(calendarEvents) &&
                      calendarEvents.length > 0 &&
                      _.orderBy(calendarEvents, ["id"], ["desc"]).map((calendarEvent) => {
                        return (
                          <tr key={calendarEvent.id}>
                            <td>{calendarEvent.calendar_event_type}</td>
                            <td>{calendarEvent.date}</td>
                            <td>{calendarEvent.title}</td>
                            <td>
                              <div className="d-flex justify-content-around flex-wrap">
                                <Can I={"settings"} a={"ImportanceDateAdd"}>
                                  <i
                                    role={"button"}
                                    className="icon icon-edit"
                                    onClick={() => editEventDate(calendarEvent)}
                                  />
                                </Can>
                                <Can I={"settings"} a={"ImportanceDateDelete"}>
                                  <i
                                    onClick={() => removeEventDate(calendarEvent.id)}
                                    role={"button"}
                                    className="icon icon-delete"
                                  />
                                </Can>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
            </Panel>
          </Col>
        </Row>
      </Can>
    </>
  );
};
export default ImportnatDates;
