import { RenderDatePicker } from "components/forms/bootstrapFields";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { required } from "../../../../../services/validations/reduxFormValidation";

const DateFilter = ({
  name,
  code,
  validations = [],
  isRequired,
  options = [],
  valueRef = undefined,
}: {
  name: string;
  code: string;
  options?: { [key: string]: any }[];
  validations?: any[];
  isRequired?: boolean;
  valueRef?: { current: any };
}) => {
  return (
    <Row className="mx-0">
      <Col className="px-0">
        <Field
          name={`properties.filters.${code}`}
          label={name}
          labelClassName="font-weight-bold"
          component={RenderDatePicker}
          transparentDisabled={false}
          valueRef={valueRef}
          validate={[...(isRequired ? [required] : []), ...validations]}
        />
      </Col>
    </Row>
  );
};

export default DateFilter;
