import React, { useState } from "react";
import { Field } from "redux-form";
import { RenderCheckBox, RenderFieldNumber } from "../../../../forms/bootstrapFields";
import style from "../../../../../pages/vp/invoices/Invoice.module.css";
import { roundUpAmount } from "../../../../../services/vp/services/roundUpAmount";
import { currencySymbolRenderer } from "../../../../../services/common/currencySymbolRendererService";

const changeExpenseTax = (tax, item) => {
  item.tax = parseFloat(tax);
  showExpenseLineTotal(item);
};

const showExpenseLineTotal = (item) => {
  item.tax = item.tax ? parseFloat(item.tax) : 0;
  item.sub_amount = item.sub_amount ? parseFloat(item.sub_amount) : 0;
  item.amount = roundUpAmount(parseFloat(item.tax) + parseFloat(item.sub_amount), 2);
  return item.amount;
};

const changeExpenseSubAmount = (subAmount, item) => {
  item.sub_amount = parseFloat(subAmount);
  showExpenseLineTotal(item);
};

const ExpenseLinesRenderer = ({ fields, currencyCode, isSubAmountAvailable, isTaxAvailable }) => {
  return (
    <>
      {fields.map((expense, index) => (
        <tr key={"expense" + index}>
          <td>
            <Field
              name={`${expense}.isAllow`}
              component={RenderCheckBox}
              checked={fields.get(index) && fields.get(index).isAllow}
            />
          </td>
          <td>
            {fields.get(index) &&
              fields.get(index).account &&
              fields.get(index).account.number + " - " + fields.get(index).account.name}
          </td>
          <td>{fields.get(index) && fields.get(index).project && fields.get(index).project.name}</td>
          <td>{fields.get(index) && fields.get(index).memo}</td>
          {isSubAmountAvailable ? (
            <td>
              <Field
                name={`${expense}.sub_amount`}
                type="number"
                className={style.itemQty}
                onChange={(e) => changeExpenseSubAmount(e.target.value, fields.get(index))}
                component={RenderFieldNumber}
                disabled={!fields.get(index) || !fields.get(index).isAllow}
              />
            </td>
          ) : null}
          {isTaxAvailable ? (
            <td>
              <Field
                name={`${expense}.tax`}
                type="number"
                component={RenderFieldNumber}
                className={style.itemQty}
                onChange={(e) => changeExpenseTax(e.target.value, fields.get(index))}
                disabled={!fields.get(index) || !fields.get(index).isAllow}
              />
            </td>
          ) : null}
          {isTaxAvailable && isSubAmountAvailable ? (
            <td>
              {fields.get(index) && fields.get(index).amount && (
                <>
                  {currencySymbolRenderer(currencyCode)}
                  {showExpenseLineTotal(fields.get(index))}
                </>
              )}
            </td>
          ) : null}

          {!isTaxAvailable && !isSubAmountAvailable ? (
            <td>
              <Field
                name={`${expense}.amount`}
                type="number"
                component={RenderFieldNumber}
                className={style.itemQty}
                disabled={!fields.get(index) || !fields.get(index).isAllow}
              />
            </td>
          ) : null}
        </tr>
      ))}
    </>
  );
};

export default ExpenseLinesRenderer;
